import {createStyles} from 'common-styles';
import moment from 'moment';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {dimensions, getValue, isBigScreen} from 'common/constants';
import TestContext from 'common/TestContext';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxText from './NitroxText';
import Popup, {PopupAction} from './Popup';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  title: {
    marginBottom: getValue({mobile: dimensions.margins.small, defaultValue: dimensions.margins.large})
  },
  modalText: {
    color: colors.popup.text,
    alignSelf: 'center',
    marginBottom: getValue({mobile: dimensions.margins.small, defaultValue: dimensions.margins.large})
  },
  container: {
    ...isBigScreen && {alignSelf: 'stretch'},
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  menu: {
    justifyContent: 'center'
  },
  menuButtons: {
    margin: dimensions.margins.small
  }
}));

const actions: PopupAction[] = [PopupAction.POSITIVE, PopupAction.NEGATIVE];

type Props = {
  visible: boolean;
  bookmark: number;
  onPositive: () => void;
  onNegative: () => void;
  onClose: () => void;
}

const testIdContext = {
  Modal: 'modal_continue_watching'
};

const ContinueWatchingPopup: React.FunctionComponent<Props> = props => {
  const {t} = useTranslation();
  const time = useMemo(() => moment.duration(props.bookmark, 'seconds').format('hh:mm:ss', {stopTrim: 'm'}), [props.bookmark]);

  const styles = stylesUpdater.getStyles();
  return (
    <TestContext.Provider value={testIdContext}>
      <Popup
        visible={props.visible}
        title={t('common.continueWatchingPopupTitle')}
        actions={actions}
        titleStyle={styles.title}
        containerStyle={styles.container}
        menuStyle={styles.menu}
        menuButtonStyle={styles.menuButtons}
        menuHasPreferredFocus={true}
        negativeLabel={t('common.no')}
        positiveLabel={t('common.yes')}
        onClose={props.onClose}
        onNegative={props.onNegative}
        onPositive={props.onPositive}
      >
        <NitroxText style={styles.modalText} textType='dialog-message'>{t('common.continueWatchingPopupMessage', {time: time})}</NitroxText>
      </Popup>
    </TestContext.Provider>
  );
};

export default React.memo(ContinueWatchingPopup);
