import {Payment} from 'mw/api/Metadata';

export class PaymentObject implements Payment {
  public readonly id: string;
  public readonly orderId: string;
  public readonly status: string;
  public readonly message: string;
  public readonly creationDate: Date;
  public readonly endDate: Date;
  public customProperties = {};

  public constructor(id: string, orderId: string, status: string, message: string, creationDate: Date, endDate: Date) {
    this.id = id;
    this.orderId = orderId;
    this.status = status;
    this.message = message;
    this.creationDate = creationDate;
    this.endDate = endDate;
  }
}
