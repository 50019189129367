import {Track, PlayerEvent, TracksList, AudioLanguagesChanged as AudioLanguagesChangedInterface} from 'mw/api/PlayerEvent';
import {PlayerType} from 'mw/playback/types/PlayerType';

import {WebPlaybackEvent, normalizeString} from './WebPlaybackEvent';

export class AudioLanguagesChanged extends WebPlaybackEvent {

  public audioLanguagesChanged: AudioLanguagesChangedInterface;

  public constructor(playerType: PlayerType, availableTracks: Track[], trackIndex: number, streamId: number) {
    super(PlayerEvent.AudioLanguagesChanged, playerType, streamId);

    const audioLanguageList: TracksList = {
      currentTrack: trackIndex,
      tracks: availableTracks.map(audioTrack => {
        const track: Track = {
          language: normalizeString(audioTrack.language),
          type: normalizeString(audioTrack.type),
          codec: normalizeString(audioTrack.codec)
        };
        return track;
      })
    };

    this.audioLanguagesChanged = {
      audioLanguageList: audioLanguageList
    };
  }
}
