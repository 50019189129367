import {createStyles} from 'common-styles';
import React, {useState, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {dimensions, isBigScreen} from 'common/constants';
import {Log} from 'common/Log';

import {Error as MWError, ErrorType} from 'mw/api/Error';
import {PCRatingsMap, PinState} from 'mw/api/Profile';
import {mw, PCType} from 'mw/MW';

import ErrorPopup, {useErrorPopup} from 'components/ErrorPopup';
import {IconType} from 'components/Icon';
import {useCurrentProfile, useDisposableCallback, useFunction, useSynchronizedState} from 'hooks/Hooks';
import {validateYearOfBirth} from 'screens/settings/profileValidation';
import WizardParentalControlRatingsPage from 'screens/settings/profileWizard/WizardParentalControlRatingsPage';
import {SettingsDetailsHeader} from 'screens/settings/SettingsDetailsHeader';

import SettingsProfileEdit, {ProfileEditSubmitResponse} from './SettingsProfileEdit';

const TAG = 'SettingsParentalControlScreen';

const styles = createStyles({
  header: {
    marginBottom: dimensions.margins.xxLarge
  },
  container: {
    paddingHorizontal: dimensions.screen.container.paddingHorizontal
  },
  content: {
    justifyContent: 'flex-start'
  }
});

function onPinChanged(pin: string) {
  throw new MWError(ErrorType.NotSupported, 'User should not be able to enter parental control settings without creating PIN');
}

const SettingsParentalControlScreen: React.FC = () => {
  const {t} = useTranslation();
  const profile = useCurrentProfile();
  const [{state: parentalControl, getStateSync: parentalControlSync}, setParentalControl] = useSynchronizedState<boolean>(profile ? profile.isPCEnabled : false);
  const [pcRatings, setPCRatings] = useState<PCRatingsMap>({...profile?.pcRatings});
  const [blockUnratedEvents, setBlockUnratedEvents] = useState(!!profile?.blockUnratedEvents);
  const [blockUnratedMovies, setBlockUnratedMovies] = useState(!!profile?.blockUnratedMovies);
  const [showAdultContent, setShowAdultContent] = useState(!!profile?.showAdultContent);
  const {error, showError, onCloseErrorPopup} = useErrorPopup();
  const [{state: yearOfBirth, getStateSync: yearOfBirthSync}, setYearOfBirth] = useSynchronizedState<number | undefined>(profile ? profile.yearOfBirth : undefined);
  const [buttonsVisible, setButtonsVisible] = useState(false);

  const displayPopupError = useCallback((message: string) => {
    showError({title: t('settings.errors.error'), message});
  }, [showError, t]);

  const showUnexpectedError = useCallback(() => {
    displayPopupError(t('common.unexpectedError'));
  }, [displayPopupError, t]);

  const onSetIsPCEnabledError = useDisposableCallback(() => {
    setParentalControl(!!profile?.isPCEnabled);
    showUnexpectedError();
  }, [profile, showUnexpectedError]);

  const onSubmit = useCallback(async (): Promise<ProfileEditSubmitResponse> => {
    try {
      if (!profile) {
        return {ok: false, error: new MWError(ErrorType.ProfileNotFound, t('settings.profileNotFound'))};
      }

      const yearOfBirthError = validateYearOfBirth(yearOfBirthSync(), parentalControlSync());
      if (yearOfBirthError && mw.configuration.pcPolicy.type === PCType.AgeBased) {
        return {ok: false, error: yearOfBirthError};
      }

      const promises = [];
      if (yearOfBirthSync() !== profile.yearOfBirth) {
        promises.push(profile.setYearOfBirth(yearOfBirthSync() ?? -1));
      }
      if (profile.isPCEnabled !== parentalControlSync()) {
        promises.push(profile.setIsPCEnabled(parentalControlSync()).catch(onSetIsPCEnabledError));
      }

      await Promise.all(promises);

      return {ok: true};
    } catch (error) {
      return {ok: false, error};
    }
  }, [onSetIsPCEnabledError, parentalControlSync, profile, t, yearOfBirthSync]);

  const onParentalChanged = useCallback((enabled: boolean) => {
    setParentalControl(enabled);
    onSubmit();
  }, [setParentalControl, onSubmit]);

  const onSetPCRatingError = useDisposableCallback(() => {
    setPCRatings({...profile?.pcRatings});
    showUnexpectedError();
    Log.error(TAG, 'onSetPCRatingError: could not save settings');
  }, [profile, showUnexpectedError]);

  const onPCRatingChanged = useFunction((authority: string, rating: string) => {
    if (profile) {
      setPCRatings((pcRatings: PCRatingsMap) => ({...pcRatings, [authority]: rating}));
      profile.setPCRating(authority, rating)
        .catch(onSetPCRatingError);
    } else {
      Log.error(TAG, 'onPCRatingChanged: no profile, no changes will be made');
    }
  });

  const onSetBlockUnratedEventsError = useDisposableCallback(() => {
    setBlockUnratedEvents(!!profile?.blockUnratedEvents);
    showUnexpectedError();
    Log.error(TAG, 'onSetBlockUnratedEventsError: could not save settings');
  }, [profile, showUnexpectedError]);

  const onBlockUnratedEventsChanged = useFunction((block: boolean) => {
    if (profile) {
      setBlockUnratedEvents(block);
      profile.setBlockUnratedEvents(block)
        .catch(onSetBlockUnratedEventsError);
    } else {
      Log.error(TAG, 'onBlockUnratedEventsChanged: no profile, no changes will be made');
    }
  });

  const onSetBlockUnratedMoviesError = useDisposableCallback(() => {
    setBlockUnratedMovies(!!profile?.blockUnratedMovies);
    showUnexpectedError();
    Log.error(TAG, 'onSetBlockUnratedMoviesError: could not save settings');
  }, [profile, showUnexpectedError]);

  const onBlockUnratedMoviesChanged = useFunction((block: boolean) => {
    if (profile) {
      setBlockUnratedMovies(block);
      profile?.setBlockUnratedMovies(block)
        .catch(onSetBlockUnratedMoviesError);
    } else {
      Log.error(TAG, 'onBlockUnratedMoviesChanged: no profile, no changes will be made');
    }
  });

  const onSetShowAdultContentError = useDisposableCallback(() => {
    setBlockUnratedMovies(!!profile?.showAdultContent);
    showUnexpectedError();
    Log.error(TAG, 'onSetShowAdultContentError: could not save settings');
  }, [profile, showUnexpectedError]);

  const onShowAdultContentChanged = useFunction((show: boolean) => {
    if (profile) {
      setShowAdultContent(show);
      profile?.setShowAdultContent(show)
        .catch(onSetShowAdultContentError);
    } else {
      Log.error(TAG, 'onShowAdultContentChanged: no profile, no changes will be made');
    }
  });

  const onBirthDateSubmit = useCallback(async () => {
    const response = await onSubmit();
    // on mobile devices, validation is launched on pressing of a save button
    if (!response.ok && isBigScreen) {
      displayPopupError(response.error.message);
    }
  }, [displayPopupError, onSubmit]);

  return (
    <SettingsProfileEdit
      title={t('settings.parentalControl.parentalControl')}
      header={(
        <SettingsDetailsHeader
          icon={IconType.ParentalControlSettingsBig}
          style={styles.header}
        />
      )}
      buttonsVisible={buttonsVisible}
      onSubmit={onSubmit}
    >
      <WizardParentalControlRatingsPage
        parentalControl={parentalControl}
        pcRatings={pcRatings}
        blockUnratedEvents={blockUnratedEvents}
        blockUnratedMovies={blockUnratedMovies}
        showAdultContent={showAdultContent}
        onParentalChanged={onParentalChanged}
        onPCRatingChanged={onPCRatingChanged}
        onBlockUnratedEventsChanged={onBlockUnratedEventsChanged}
        onBlockUnratedMoviesChanged={onBlockUnratedMoviesChanged}
        onShowAdultContentChanged={onShowAdultContentChanged}
        contentStyle={styles.content}
        embedded
        onBirthDateChanged={setYearOfBirth}
        onBirthDateSubmit={onBirthDateSubmit}
        yearOfBirth={yearOfBirth}
        onPinChanged={onPinChanged}
        pinState={profile?.pinState ?? PinState.ProfilePinNotSet}
        onInputFocusChange={setButtonsVisible}
      />
      <ErrorPopup error={error} onClose={onCloseErrorPopup} />
    </SettingsProfileEdit>
  );
};

export default SettingsParentalControlScreen;
