import React, {useEffect, useState} from 'react';

import {AudioLanguagesChanged, PlayerEvent, SubtitlesChanged, Track, TracksList} from 'mw/api/PlayerEvent';
import {Player} from 'mw/playback/Player';

import {useDisposableCallback, useEventListener} from 'hooks/Hooks';

class TracksInfo {
  public tracks: Track[];
  public currentTrack?: Track;

  public constructor(tracksList: TracksList) {
    this.tracks = tracksList.tracks;

    if (tracksList.currentTrack >= 0 && tracksList.currentTrack < this.tracks.length) {
      this.currentTrack = this.tracks[tracksList.currentTrack];
    }
  }
}

//TODO: This should be a part of mw CL-1476
export class PlayerTracksInfo {
  private audioTracks: TracksInfo;
  private subtitleTracks: TracksInfo;

  public constructor(audioTracks: TracksInfo, subtitleTracks: TracksInfo) {
    this.audioTracks = audioTracks;
    this.subtitleTracks = subtitleTracks;
  }

  public hasAudioOrSubtitleTracks() {
    return this.getAudioTracks().length > 0 || this.getSubtitleTracks().length > 0;
  }

  public getAudioTracks() {
    return this.audioTracks.tracks;
  }

  public getCurrentAudioTrack() {
    return this.audioTracks.currentTrack;
  }

  public getSubtitleTracks() {
    return this.subtitleTracks.tracks;
  }

  public getCurrentSubtitleTrack() {
    return this.subtitleTracks.currentTrack;
  }
}

type Props = {
  player: Player;
  onTracksChanged: (playerTracksInfo: PlayerTracksInfo) => void;
}

const PlayerLanguageEventHandler: React.FC<Props> = props => {
  const {player, onTracksChanged} = props;
  const [audioLanguages, setAudioLanguages] = useState<TracksInfo>({tracks: []});
  const [subtitles, setSubtitles] = useState<TracksInfo>({tracks: []});

  const onAudioLanguagesChanged = useDisposableCallback((audioLanguagesChanged: AudioLanguagesChanged) => {
    setAudioLanguages(new TracksInfo(audioLanguagesChanged.audioLanguageList));
  });

  useEventListener(PlayerEvent.AudioLanguagesChanged, onAudioLanguagesChanged, player);

  const onSubtitlesChanged = useDisposableCallback((subtitlesChanged: SubtitlesChanged) => {
    setSubtitles(new TracksInfo(subtitlesChanged.subtitleLanguageList));
  });

  useEventListener(PlayerEvent.SubtitlesChanged, onSubtitlesChanged, player);

  useEffect(() => {
    setAudioLanguages(new TracksInfo(player.getAudioLanguages()));
    setSubtitles((new TracksInfo(player.getSubtitleLanguages())));
  }, [player]);

  useEffect(() => {
    if (onTracksChanged) {
      onTracksChanged(new PlayerTracksInfo(audioLanguages, subtitles));
    }
  }, [onTracksChanged, audioLanguages, subtitles]);

  return null;
};

export default React.memo(PlayerLanguageEventHandler);
