export type PlaybackRequestParameters = {
  playRate?: number;
  mute?: boolean;
  hideVideo?: boolean;
  forcePCUnlock?: boolean;
} & ({
  skip?: number;
  position?: never;
} | {
  skip?: never;
  position?: number;
});

export function isPlaybackParametersEqual(a: PlaybackRequestParameters, b: PlaybackRequestParameters): boolean {
  return a.playRate === b.playRate
    && a.position === b.position
    && a.skip === b.skip;
}

export interface PlaybackResponse {
  streamId: number;
}

export interface PlaybackParametersProvider {
  getPosition(): number;
  getBeginPosition(): number;
  getEndPosition(): number;
}

export interface PlaybackParameters {
  playRate?: number;
  beginPosition: number;
  endPosition: number;
  position?: number;
  audioMuted: boolean;
}
