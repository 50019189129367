import {useRef} from 'react';

import {Direction, isHorizontal} from 'common/constants';
import {doNothing} from 'common/HelperFunctions';
import {maxByAbs} from 'common/utils';

import {useEffectOnce, useFunction, useThrottle} from 'hooks/Hooks';

const resetTimeout = 500;
const shortEventThreshold = 2;

export function useDiscreteWheelHandler(
  handler: (direction: Direction) => void,
  {
    // These values should work quite ok across browsers.
    // Unfortunately 'wheel' event is nowhere near being standardarized.
    // Even Chromium ignores issue completely.
    // https://bugs.chromium.org/p/chromium/issues/detail?id=245328
    //// "I'm not sure who might even be interested in working on mousewheel related code these dates"
    //// ~Chromium Project Member
    thresholdX = 40,
    thresholdY = 4,
    directions = 'all',
    onWheel: propagateWheel,
    active = true
  }: {
    thresholdX?: number;
    thresholdY?: number;
    directions?: Direction[] | 'all';
    onWheel?: () => void;
    active?: boolean;
  } = {}
): {
    onWheel: (event: React.WheelEvent) => void;
  }
{
  const distanceX = useRef<number>(0);
  const timeoutX = useRef<number>(0);

  const distanceY = useRef<number>(0);
  const timeoutY = useRef<number>(0);

  const clearTimeouts = useFunction(() => {
    clearTimeout(timeoutX.current);
    clearTimeout(timeoutY.current);
    timeoutX.current = 0;
    timeoutX.current = 0;
  });

  const scheduleReset = useFunction(() => {
    clearTimeouts();
    timeoutX.current = setTimeout(() => distanceX.current = 0, resetTimeout);
    timeoutY.current = setTimeout(() => distanceY.current = 0, resetTimeout);
  });

  const throttledHandler = useThrottle(handler, 250);
  const shortEventsThrottledHandler = useThrottle(throttledHandler, 500);
  const resetDistance = useFunction((direction: Direction) => {
    if (isHorizontal(direction)) {
      distanceX.current = 0;
    } else {
      distanceY.current = 0;
    }
  });

  const handleEvent = useFunction((delta: number, direction: Direction) => {
    if (directions === 'all' || directions.includes(direction)) {
      const handler = Math.abs(delta) > shortEventThreshold ? throttledHandler : shortEventsThrottledHandler;
      handler(direction);
      resetDistance(direction);
    }
  });

  const onWheel = useFunction((event: React.WheelEvent) => {
    propagateWheel?.();

    if (event.shiftKey) {
      const factor = maxByAbs(event.deltaX, event.deltaY);
      const direction = factor > 0
        ? Direction.Right
        : Direction.Left;

      handleEvent(factor, direction);
      return;
    }

    if (Math.sign(distanceX.current) === Math.sign(event.deltaX)) {
      distanceX.current += event.deltaX;
    } else {
      distanceX.current = event.deltaX;
    }

    if (Math.sign(distanceY.current) === Math.sign(event.deltaY)) {
      distanceY.current += event.deltaY;
    } else {
      distanceY.current = event.deltaY;
    }

    if (Math.abs(distanceX.current) > thresholdX) {
      const direction = distanceX.current > 0 ? Direction.Right : Direction.Left;
      handleEvent(event.deltaX, direction);
    }
    if (Math.abs(distanceY.current) > thresholdY) {
      const direction = distanceY.current > 0 ? Direction.Down : Direction.Up;
      handleEvent(event.deltaY, direction);
    }

    scheduleReset();
  });

  useEffectOnce(() => () => clearTimeouts(), []);

  return {
    onWheel: active ? onWheel : doNothing
  };
}
