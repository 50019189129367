import {Emitter} from 'mitt';

import {debugFeatures, isTizen, isWebOS, isFullScreenModeSupported} from 'common/constants';
import {Log} from 'common/Log';

import {SupportedKeys, KeyEventSource} from './KeyEventManager';

const TAG = 'NativeKeyEventSource.web';

function mapWebKeyCode(key: number): SupportedKeys | undefined {
  switch (key) {
    case 48: return SupportedKeys.Num0;
    case 49: return SupportedKeys.Num1;
    case 50: return SupportedKeys.Num2;
    case 51: return SupportedKeys.Num3;
    case 52: return SupportedKeys.Num4;
    case 53: return SupportedKeys.Num5;
    case 54: return SupportedKeys.Num6;
    case 55: return SupportedKeys.Num7;
    case 56: return SupportedKeys.Num8;
    case 57: return SupportedKeys.Num9;
    case 37: return SupportedKeys.Left;
    case 39: return SupportedKeys.Right;
    case 38: return SupportedKeys.Up;
    case 40: return SupportedKeys.Down;
    case 32: return SupportedKeys.PlayPause;
    case 27: return SupportedKeys.Back; // Escape
    case 13: return SupportedKeys.Ok; // Enter
    case 8: return SupportedKeys.Backspace;
    case 219: return SupportedKeys.Rewind;
    case 221: return SupportedKeys.FastForward;
    // F
    case 70:
      if (isFullScreenModeSupported) {
        return SupportedKeys.FullScreen;
      }
      return;
    // I
    case 73: {
      if (debugFeatures.channelKeys) {
        return SupportedKeys.ChannelUp;
      }
      return;
    }
    // K
    case 75: {
      if (debugFeatures.channelKeys) {
        return SupportedKeys.ChannelDown;
      }
      return;
    }
    default: return;
  }
}

function mapTizenKeyCode(key: number): SupportedKeys | undefined {
  switch (key) {
    case 19: return SupportedKeys.Pause;
    case 412: return SupportedKeys.Rewind;
    case 413: return SupportedKeys.Stop;
    case 415: return SupportedKeys.Play;
    case 416: return SupportedKeys.Record;
    case 417: return SupportedKeys.FastForward;
    case 427: return SupportedKeys.ChannelUp;
    case 428: return SupportedKeys.ChannelDown;
    case 457: return SupportedKeys.Info;
    case 10009: return SupportedKeys.Back;
    case 10225: return SupportedKeys.Search;
    case 10190: return SupportedKeys.LastChannel;
    case 10252: return SupportedKeys.PlayPause;
    case 65385: return SupportedKeys.CloseKeyboard;
    default: return mapWebKeyCode(key);
  }
}

function mapWebOSKeyCode(key: number): SupportedKeys | undefined {
  switch (key) {
    case 461: // WebOS specific
      return SupportedKeys.Back; // Escape
    default: return mapWebKeyCode(key);
  }
}

export class NativeKeyEventSource implements KeyEventSource {

  public constructor() {
    if (isTizen) {
      const supportedKeys = [
        'ChannelUp',
        'ChannelDown',
        'Info',
        'MediaFastForward',
        'MediaPause',
        'MediaPlay',
        'MediaPlayPause',
        'MediaRecord',
        'MediaRewind',
        'MediaStop',
        'PreviousChannel',
        'Search'
      ];

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const tizenApi = (window as any).tizen;
      const tizenSupportedKeys: string[] = tizenApi?.tvinputdevice.getSupportedKeys().map((keyInput: {name: string, code: number}) => keyInput.name) ?? [];
      tizenApi?.tvinputdevice.registerKeyBatch(supportedKeys.filter(key => tizenSupportedKeys.includes(key)),
        () => Log.info(TAG, 'tizen tvinputdevice registerKeyBatch success'),
        (error: Error) => Log.error(TAG, 'tizen tvinputdevice registerKeyBatch failed', error));
    }
  }

  public bind(emitter: Emitter): void {
    if (!!window && !!window.addEventListener) {
      window.addEventListener('keyup', (data: {keyCode: number}) => emitter.emit('keyup', {event: data, key: this.mapWebKeyCode(data.keyCode)}));
      window.addEventListener('keydown', (data: {keyCode: number}) => emitter.emit('keydown', {event: data, key: this.mapWebKeyCode(data.keyCode)}));
      window.addEventListener('keypress', (data: {keyCode: number}) => emitter.emit('web:keypress', {event: data, key: this.mapWebKeyCode(data.keyCode)}));
    }
  }

  private mapWebKeyCode: (key: number) => SupportedKeys | undefined = isTizen ? mapTizenKeyCode : (isWebOS ? mapWebOSKeyCode : mapWebKeyCode);
}
