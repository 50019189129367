import {isBigScreen} from 'common/constants';

import CalendarModalGrosso from 'components/CalendarModal.grosso';
import CalendarModalPiccolo from 'components/CalendarModal.piccolo';

export type CalendarProps = {
  date?: Date;
  maxDate?: Date;
  minDate?: Date;
};

export default isBigScreen ? CalendarModalGrosso : CalendarModalPiccolo;
