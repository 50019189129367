import {createStyles} from 'common-styles';
import React from 'react';
import {View} from 'react-native';

import {dimensions} from 'common/constants';
import {doNothing} from 'common/HelperFunctions';

import {Series, Media} from 'mw/api/Metadata';

import EpisodesList from './EpisodesList';
import {mediaDetailHorizontalMargin} from './MediaDetailUtils';
import MobileSeasonPicker from './MobileSeasonPicker';

const TAG = 'MobileEpisodeSection';
const styles = createStyles({
  container: {
    paddingTop: dimensions.margins.medium
  },
  seasonPicker: {
    marginBottom: dimensions.margins.medium,
    marginHorizontal: mediaDetailHorizontalMargin
  }
});

type Props = {
  seasons: Series[];
  selectedSeason?: Series;
  episodes: Media[];
  onSeasonSelected?: (season: Series) => void;
};

const MobileEpisodeSection: React.FunctionComponent<Props> = props => {
  const {seasons, selectedSeason, episodes, onSeasonSelected = doNothing} = props;

  return (
    <View style={styles.container}>
      {seasons.length > 1 && selectedSeason && (
        <MobileSeasonPicker
          seasons={seasons}
          selectedSeason={selectedSeason}
          onSelected={onSeasonSelected}
          style={styles.seasonPicker}
        />
      )}
      {episodes.length > 0 && <EpisodesList episodes={episodes} />}
    </View>
  );
};

export default MobileEpisodeSection;
