import moment from 'moment';

export class DateUtils {
  public static readonly msInSec = 1000;
  public static readonly sInMin = 60;
  public static readonly minInHour = 60;
  public static readonly hoursInDay = 24;
  public static readonly daysInWeek = 7;
  public static readonly daysInYear = 365;
  public static readonly msInMin = DateUtils.sInMin * DateUtils.msInSec;
  public static readonly sInHour = DateUtils.sInMin * DateUtils.minInHour;
  public static readonly msInHour = DateUtils.sInHour * DateUtils.msInSec;
  public static readonly msInDay = DateUtils.hoursInDay * DateUtils.msInHour;
  public static readonly msInWeek = DateUtils.msInDay * DateUtils.daysInWeek;
  public static readonly msInYear = DateUtils.daysInYear * DateUtils.hoursInDay * DateUtils.msInHour;

  public static addHours(oldDate: Date, hours: number): Date {
    return new Date(oldDate.getTime() + hours * DateUtils.msInHour);
  }

  public static addMinutes(oldDate: Date, min: number): Date {
    return new Date(oldDate.getTime() + min * DateUtils.msInMin);
  }

  public static addSeconds(oldDate: Date, seconds: number) {
    return new Date(oldDate.getTime() + seconds * DateUtils.msInSec);
  }

  public static addMilliseconds(oldDate: Date, milliseconds: number) {
    return new Date(oldDate.getTime() + milliseconds);
  }

  public static getSeconds(date: Date): number {
    return date.getTime() / DateUtils.msInSec;
  }

  public static isValidDate(date: Date) {
    return date instanceof Date && !isNaN(date.getTime());
  }

  public static isDateBetween(date: Date, from: Date, to: Date) {
    return date >= from && date < to;
  }

  public static addYears(oldDate: Date, years: number): Date {
    return new Date(oldDate.getTime() + years * DateUtils.msInYear);
  }

  /**
   * Compares two dates ignoring time part.
   * @param first First date to compare
   * @param second Second date to compare
   * @return true if dates are equal (time part is ignored) or false otherwise
   */
  public static isSameDay(first: Date, second: Date): boolean {
    // Don't use getUTC* methods because conversion to UTC can change date part, for example:
    // 2020.05.06 00:00:00 UTC+02:00 => 2020.05.05 22:00:00 UTC+00:00
    // 2020.05.06 10:00:00 UTC+02:00 => 2020.05.06 08:00:00 UTC+00:00
    return first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate();
  }

  public static startOfHalfHour(date: Date) {
    const start = new Date(date);
    start.setMinutes(start.getMinutes() >= 30 ? 30 : 0, 0, 0);
    return start;
  }

  public static startOfDay(date: Date): Date {
    const start = new Date(date);
    start.setHours(0, 0, 0, 0);
    return start;
  }

  public static previousDay(date: Date): Date {
    return new Date(DateUtils.startOfDay(date).getTime() - 24 * 60 * DateUtils.msInMin);
  }

  public static nextDay(date: Date): Date {
    return new Date(DateUtils.startOfDay(date).getTime() + 24 * 60 * DateUtils.msInMin);
  }

  public static formatDate(date: Date, format: string) {
    return moment(date).format(format);
  }
}
