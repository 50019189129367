import {createStyles, defaultStyles} from 'common-styles';
import React, {createRef} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {ActivityIndicator, Animated, Dimensions, ScrollView, View, StyleSheet} from 'react-native';
import {NavigationScreenProps, withNavigation} from 'react-navigation';

import {disposable, switchCalls} from 'common/Async';
import {dimensions, isBigScreen, isMobile, isTablet, isTVOS, getValue, isSmartTV, showButtonAnimationLength, hideButtonAnimationLength} from 'common/constants';
import {addToWatchList, removeFromWatchList, getBuyProducts, getRentProducts, getMediaSubtypes, flatten, compactMap, unique, getMediaCredits, isSeriesEpisode} from 'common/HelperFunctions';
import {ChangeEvent, MediaOpenableProps} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {CustomerEvent, CustomerEventPayload} from 'mw/api/Customer';
import {Media, MediaType, PictureMode, PictureType, Series, Title, Event, isTitle, isEvent, isRecording, isChannel, ExternalLink, Recording, RecordingType, Product, isSeries, isSingleRecording, PVREvent, Order, UpdateMediaParams} from 'mw/api/Metadata';
import {Profile, ProfileEvent} from 'mw/api/Profile';
import {RecommendationsSource} from 'mw/common/ContentCache';
import {mw} from 'mw/MW';

import BackButton from 'components/BackButton';
import {WithChromecastExtraBottomPadding, withChromecastExtraBottomPadding} from 'components/chromecast/ChromecastExtraBottomPadding';
import {withDeepLinking, WithDeepLinking} from 'components/DeepLinking';
import DeleteConfirmationPopup from 'components/DeleteConfirmationPopup';
import ErrorPopup, {ErrorPopupProps} from 'components/ErrorPopup';
import FocusParent from 'components/FocusParent';
import {IconType} from 'components/Icon';
import {KeyEventManager, NativeKeyEvent, SupportedKeys} from 'components/KeyEventManager';
import BackgroundPoster from 'components/mediadetails/BackgroundPoster';
import BigScreenEpisodesSection from 'components/mediadetails/BigScreenEpisodesSection';
import BigScreenSeasonPicker from 'components/mediadetails/BigScreenSeasonPicker';
import CastSection from 'components/mediadetails/CastSection';
import EntitlementStateChangeNotifier, {EntitlementStateChangeEvent} from 'components/mediadetails/EntitlementStateChangeNotifier';
import MediaDetailButtons from 'components/mediadetails/MediaDetailButtons';
import {Action, getMediaActions, isSameMedia, mediaDetailHorizontalMargin, MediaDetailButtonsAction, CustomActionKeys} from 'components/mediadetails/MediaDetailUtils';
import MediaInfoView from 'components/mediadetails/MediaInfoView';
import {RecordingLimitationsProps} from 'components/mediadetails/MediaInfoView.shared';
import MobileMediaDetailTabs from 'components/mediadetails/MobileMediaDetailTabs';
import RelatedSeeMore from 'components/mediadetails/relatedSeeMore/RelatedSeeMore';
import TrailersSection from 'components/mediadetails/TrailersSection';
import {MobileBackButtonManager} from 'components/navigation/MobileHeaderBackButton';
import {STBMenuState} from 'components/navigation/NavigationHelperTypes';
import {NitroxInteractiveController} from 'components/NitroxInteractiveControllerContext';
import {withParentalControl, ParentalControlContextType} from 'components/parentalControl/ParentalControlProvider';
import PaymentFlow from 'components/payments/PaymentFlow';
import {withPlayerLauncher} from 'components/playerLauncher/PlayerLauncher';
import {WithPlayerLauncher} from 'components/playerLauncher/PlayerLauncher.shared';
import {WithRecord, withRecord} from 'components/pvr/Record';
import {RecordActionType} from 'components/pvr/RecordingUtils';
import {UnlockModalRejection, UnlockModalRejectionReason} from 'components/unlockmodal/UnlockModal';
import {UseFocusOnAppear} from 'hooks/HookComponents';
import {getScreenInfo} from 'hooks/Hooks';

import NitroxScreen from './NitroxScreen';
import {ViewMode} from './tv/TvScreen';

const TAG = 'MediaDetailsScreen';
const relatedRecommendationsCount = 10;
const seasonPickerAnimationDuration = 250;
const seasonPickerHeight = 115;

const screenStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  bigScreenView: {
    backgroundColor: colors.mediaDetailsScreen.background
  },
  backButton: {
    left: mediaDetailHorizontalMargin
  },
  contentTopContainer: {
    marginTop: isBigScreen ? dimensions.mediaInfoView.view.marginTop : 0
  },
  buttonsView: {
    marginBottom: isBigScreen ? dimensions.margins.xxLarge : dimensions.margins.small,
    paddingLeft: getValue({tablet: dimensions.margins.small, mobile: 0, defaultValue: mediaDetailHorizontalMargin}),
    paddingRight: isTablet ? dimensions.margins.small : 0
  },
  activityIndicator: {
    ...StyleSheet.absoluteFillObject
  },
  poster: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%'
  },
  mainContainer: {
    paddingLeft: 0
  },
  seasonPickerContainer: {
    backgroundColor: colors.button.secondary.background.focused
  },
  spaceMediaDetails: {justifyContent: 'space-between'}
}));

type Props = NavigationScreenProps<MediaOpenableProps> & WithDeepLinking & WithTranslation & WithRecord & WithChromecastExtraBottomPadding & WithPlayerLauncher & ParentalControlContextType;

type State = {
  isMediaInitialized: boolean;
  isLoading: boolean;
  playbackProgress: number;
  isSeasonPickerVisible: boolean;
  isDeleteConfirmationPopupVisible: boolean;
  isPaymentPopupVisible: boolean;
  episodes?: Media[];
  media?: Media;
  eventRecordings?: Recording[];
  seasonRecordings?: Recording[];
  seriesRecording?: Recording;
  selectedSeason?: Series;
  selectedEpisode: Media | null;
  actions: MediaDetailButtonsAction[];
  parentalControlEnabled: boolean;
  unlockedMediaList: Media[];
  error: ErrorPopupProps;
  episodeSectionKey: number;
  products: Product[];
  showMoreButton: boolean;
  anyModalVisible: boolean;
  recommendations?: Media[] | null;
  isScreenFocused: boolean;
}

class MediaDetailScreen extends React.Component<Props, State> {
  public state: State = {
    isMediaInitialized: false,
    isSeasonPickerVisible: false,
    isDeleteConfirmationPopupVisible: false,
    isPaymentPopupVisible: false,
    isLoading: true,
    playbackProgress: 0,
    selectedEpisode: null,
    actions: [],
    parentalControlEnabled: true,
    unlockedMediaList: [],
    error: {error: null},
    episodeSectionKey: 0,
    products: [],
    showMoreButton: true,
    isScreenFocused: true,
    anyModalVisible: false
  };

  private getTitleById = disposable(mw.catalog.getTitleById.bind(mw.catalog));
  private getEventById = disposable(mw.catalog.getEventById.bind(mw.catalog));
  private getRecordings = disposable(mw.pvr.getRecordings.bind(mw.pvr));
  private getSeriesById = disposable(mw.catalog.getSeriesById.bind(mw.catalog));
  private getSeriesSeasonsById = disposable(mw.catalog.getSeriesSeasonsById.bind(mw.catalog));
  private getSeasonEpisodesById = disposable(switchCalls(mw.catalog.getSeasonEpisodesById.bind(mw.catalog)));
  private getRecommendedEpisode = disposable(mw.catalog.getRecommendedEpisode.bind(mw.catalog));
  private getMediaRecommendations = disposable(mw.catalog.getRecommendations.bind(mw.catalog));
  private scrollViewRef = createRef<ScrollView>();
  private seasonPickerHeight = new Animated.Value(0);
  private enableParentalControlOnBlur = true;
  private seeMoreButtonAnimatedOpacity = new Animated.Value(1);
  private contentHeight = 0;
  private entitlementStateChangeNotifier = new EntitlementStateChangeNotifier(title => mw.catalog.updateMedia(title, UpdateMediaParams.EntitlementState));

  private updateActions(media?: Media) {
    if (!media) {
      return;
    }
    Log.debug(TAG, `Updating available actions for media ${media}`);
    const additionalActions = [];
    this.isEpisodeLocked(media) && additionalActions.push(Action.UNLOCK);
    const actions = getMediaActions(media, this.getRecordingForMedia(media), this.state.seriesRecording, additionalActions);
    Log.debug(TAG, `Loaded ${actions.length} actions for media ${media}`);
    this.setState({actions, isLoading: false});
  }

  private scrollToTop = () => {
    if (!isSmartTV) {
      return;
    }
    this.scrollViewRef.current?.scrollTo({y: 0, animated: true});
  }

  private scrollToHeaderBottom = () => {
    if (!isSmartTV) {
      return;
    }
    const screenHeight = Dimensions.get('window').height;
    const y = this.contentHeight - (!!this.state.recommendations?.length ? 2 * screenHeight : screenHeight);
    this.scrollViewRef.current?.scrollTo({y: Math.max(0 , y), animated: true});
  }

  private scrollToBottom = () => {
    if (!isSmartTV) {
      return;
    }
    this.scrollViewRef.current?.scrollToEnd({animated: true});
  }

  private setSelectedEpisode = (media: Media) => {
    const isEvent = media.getType() === MediaType.Event;
    if (media.getType() === MediaType.Title || isEvent) {
      this.hideSeasonPicker();
      this.setState({selectedEpisode: media});
      isEvent ? this.updateProgressStateForEvent(media as Event) : this.updateProgressStateForTitle(media as Title);
      this.updateActions(media);
    } else {
      Log.error(TAG, 'Unexpected media type of episode: ', media.getType());
    }
  };

  private clearSelectedEpisode() {
    this.setState({selectedEpisode: null});
    this.updateActions(this.state.media);
  }

  private onEpisodeTileFocus = (media: Media) => {
    this.setSelectedEpisode(media);
  };

  private setSelectedSeason = (selectedSeason: Series) => {
    this.hideSeasonPicker();
    this.fetchEpisodes(selectedSeason);
    this.setState({selectedSeason});
  };

  private showSeasonPicker = () => {
    this.setState({isSeasonPickerVisible: true});
    Animated.timing(this.seasonPickerHeight, {
      duration: seasonPickerAnimationDuration,
      toValue: seasonPickerHeight
    }).start();
  };

  private hideSeasonPicker = () => {
    Animated.timing(this.seasonPickerHeight, {
      duration: seasonPickerAnimationDuration,
      toValue: 0
    }).start(() => this.setState({isSeasonPickerVisible: false}));
  };

  private onScreenFocused = () => {
    MobileBackButtonManager.show();
    this.setState({isScreenFocused: true});
  };

  private onScreenBlurred = () => {
    if (this.enableParentalControlOnBlur) {
      this.setParentalControlEnabled(true);
    } else {
      this.enableParentalControlOnBlur = true;
    }
    this.setState({isScreenFocused: false});
  };

  private backToPreviousScreen = () => {
    this.props.navigation.goBack();
  };

  private onBackPressed = () => {
    if (this.state.selectedEpisode !== null) {
      this.clearSelectedEpisode();
      return true;
    }
    return false;
  };

  private onCustomButtonPress = async (key: CustomActionKeys, value: any) => {
    if (key === CustomActionKeys.DEEPLINKING) {
      this.props.openDeepLink(value as ExternalLink);
    }
  }

  private popAndNavigateToZapper = (params: {channelId?: string; eventId?: string}) => {
    const {pop} = this.props.navigation;
    pop();
    this.props.startPlayback({
      tvScreenParams: {...params, viewMode: ViewMode.OneChannelEpg}
    });
  }

  private startPlayback = (media: Media) => {
    if (isTitle(media)) {
      const title = media as Title;
      if (title.isEntitled) {
        if (title.episode && isSeriesEpisode(title) && this.isEpisodeLocked(title)) {
          this.props.unlockMedia(title)
            .then(() => this.startPlaybackByMediaLauncher(title));
        } else {
          this.startPlaybackByMediaLauncher(title);
        }
      } else {
        Log.error(TAG, 'Unable to start playback - title is not entitled');
      }
    } else if (isChannel(media)) {
      this.popAndNavigateToZapper({channelId: media.id});
    } else if (isEvent(media)) {
      if (media.hasTstv) {
        this.startPlaybackByMediaLauncher(media);
      } else {
        this.popAndNavigateToZapper({eventId: media.id});
      }
    } else if (isRecording(media)) {
      this.startPlaybackByMediaLauncher(media);
    } else {
      Log.error(TAG, 'Unable to start playback - unexpected type of media: ', media.getType());
    }
  };

  private onWatchOnPress = (media: Media) => {
    if (isTitle(media)) {
      this.props.showDeepLinkingModal(media, this.startPlayback);
    }
  }

  private startPlaybackByMediaLauncher(media: Media) {
    this.enableParentalControlOnBlur = false;
    this.setParentalControlEnabled(false);
    this.props.startPlayback({media});
  }

  private onPlaybackStopped = () => {
    if (this.state.media) {
      this.fetchMedia();
    }
  };

  private onWatchListChange = () => {
    this.updateActions(this.getFocusedMedia());
  };

  private onRecordingsChanged = () => {
    this.fetchMedia();
  }

  private onEntitlementStateChanged = () => {
    this.updateActions(this.state.media);
  }

  private onProfileChange = (payload?: CustomerEventPayload) => {
    if (!payload) {
      return;
    }
    const {to, from} = payload as ChangeEvent<Profile | null>;
    from?.off(ProfileEvent.WatchListChange, this.onWatchListChange);
    to?.on(ProfileEvent.WatchListChange, this.onWatchListChange);
    this.updateActions(this.state.media);
  };

  private keyEventHandler = async (event?: NativeKeyEvent) => {
    if (this.state.anyModalVisible) {
      return;
    }
    if (event?.key === SupportedKeys.Record && this.state.media) {
      const action = await this.props.getRecordActionTypeForEvent(this.state.media);
      if (action !== RecordActionType.none) {
        this.props.scheduleRecording(this.state.media);
      }
    }
  }

  public componentDidMount(): void {
    MobileBackButtonManager.show();
    // listen for actions that can be performed e.g. on MediaPlayer after which we have to do some updates
    mw.customer.on(CustomerEvent.ProfileChange, this.onProfileChange);
    mw.customer.currentProfile?.on(ProfileEvent.WatchListChange, this.onWatchListChange);
    mw.pvr.on(PVREvent.PVRRecordingsChanged, this.onRecordingsChanged);
    this.entitlementStateChangeNotifier.on(EntitlementStateChangeEvent.StateChanged, this.onEntitlementStateChanged);
    this.fetchMedia();
    KeyEventManager.getInstance().addEventListener('keyup', this.keyEventHandler);
  }

  public componentDidUpdate(_: Props, {media: prevMedia}: State) {
    const {media, parentalControlEnabled} = this.state;
    const isMediaBlocked = this.props.shouldBeCheckedForPC(media) && this.props.isMediaBlocked(media);
    if (parentalControlEnabled !== isMediaBlocked) {
      this.setParentalControlEnabled(isMediaBlocked);
    }
    if (!isSameMedia(media, prevMedia)) {
      this.unlockMedia();
    }
  }

  private getRecommendations() {
    const {media} = this.state;
    if (!media) {
      return;
    }
    const sources: RecommendationsSource[] = [media.getType() === MediaType.Event ? 'epg' : 'vod'];
    this.getMediaRecommendations(relatedRecommendationsCount, sources, media).then((recommendations) => {
      recommendations.length > 0 && this.setState({recommendations});
    });
  }

  public componentWillUnmount() {
    mw.customer.off(CustomerEvent.ProfileChange, this.onProfileChange);
    mw.customer.currentProfile?.off(ProfileEvent.WatchListChange, this.onWatchListChange);
    mw.pvr.off(PVREvent.PVRRecordingsChanged, this.onRecordingsChanged);
    this.entitlementStateChangeNotifier.off(EntitlementStateChangeEvent.StateChanged, this.onEntitlementStateChanged);
    this.entitlementStateChangeNotifier.stop();
    this.getTitleById.dispose();
    this.getEventById.dispose();
    this.getSeriesById.dispose();
    this.getSeriesSeasonsById.dispose();
    this.getSeasonEpisodesById.dispose();
    this.getRecommendedEpisode.dispose();
    this.getRecordings.dispose();
    this.getMediaRecommendations.dispose();
    KeyEventManager.getInstance().removeEventListener('keyup', this.keyEventHandler);
  }

  private showErrorPopup(errorMessage: string, backOnClose = true, errorTitle?: string, errorIcon?: IconType) {
    this.setState({
      error: {
        error: {message: errorMessage, title: errorTitle, icon: errorIcon},
        onClose: () => {
          this.hideErrorPopup();
          backOnClose && this.backToPreviousScreen();
        }
      }
    });
  }

  private hideErrorPopup() {
    this.setState({error: {error: null}});
  }

  private fetchMedia() {
    const mediaId = this.props.navigation.getParam('mediaId');
    const type = this.props.navigation.getParam('mediaType');

    if (!mediaId) {
      Log.error(TAG, 'Unable fetch media - There is no media id');
      return;
    }

    switch (type) {
      case MediaType.Title:
        this.fetchTitle(mediaId);
        break;
      case MediaType.Event:
        this.fetchEvent(mediaId);
        break;
      case MediaType.Recording:
        this.fetchRecording(mediaId);
        break;
      case MediaType.Series:
        this.fetchSeries(mediaId);
        break;
      default:
        Log.error(TAG, 'Unable fetch media - Unsupported media type: ', type);
        break;
    }
  }

  private fetchTitle(titleId: string) {
    Log.debug(TAG, 'Loading title with id ', titleId);
    this.getTitleById(titleId)
      .then((title: Title) => {
        Log.debug(TAG, 'Loaded title with id ', titleId);
        this.setMedia(title);
        this.updateActions(title);
        this.updateProgressStateForTitle(title);
        this.startEntitlementStateChecking(title);
        this.setMediaInitialized();
        this.getRecommendations();
      })
      .catch(error => Log.error(TAG, 'Error loading title ', error));
  }

  private startEntitlementStateChecking(title: Title) {
    this.entitlementStateChangeNotifier.start(title);
  }

  private fetchEvent(eventId: string) {
    this.loadEvent(eventId)
      .catch(error => Log.error(TAG, 'Error loading event', eventId, error));
  }

  private loadRecording(event: Event): Promise<void> {
    return !mw.configuration.isNPVREnabled
      ? Promise.resolve()
      : this.getRecordings({media: event})
        .then(recordings => {
          this.setState({
            eventRecordings: [recordings.find(recording => recording.recordingType === RecordingType.Single)].filter(isRecording),
            seriesRecording: recordings.find(recording => recording.recordingType === RecordingType.Series)
          });
        })
        .catch(error => Log.error(TAG, 'Error fetching recordings for event', event.id, error));
  }

  private loadEvent(eventId: string): Promise<void> {
    Log.debug(TAG, 'Loading event with id ', eventId);
    return this.getEventById(eventId)
      .then(async event => {
        await this.loadRecording(event);
        return event;
      })
      .then(event => {
        Log.debug(TAG, 'Loaded event with id ', eventId);
        this.setMedia(event);
        this.updateActions(event);
        this.updateProgressStateForEvent(event);
        this.setMediaInitialized();
        this.getRecommendations();
      });
  }

  private fetchRecording(eventId: string) {
    this.loadEvent(eventId)
      .catch(error => {
        Log.error(TAG, 'Error loading event', eventId, error);
        this.showErrorPopup(this.props.t('mediaDetails.recordingLoadingError'));
      });
  }

  private fetchSeries(seriesId: string) {
    Log.debug(TAG, 'Loading series with id ', seriesId);
    this.getSeriesById(seriesId)
      .then(async (series: Series) => {
        const seasons = await this.getSeriesSeasonsById(seriesId);
        const [seriesRecording] = mw.configuration.isNPVREnabled ? await this.getRecordings({media: series}) : [];
        const seasonRecordings = seriesRecording ? await this.getRecordings({parentRecording: seriesRecording, type: RecordingType.Series}) : [];
        series.seasons = unique([
          ...seasons,
          ...compactMap(seasonRecordings, recording => recording.series)
        ], 'id');
        Log.debug(TAG, 'Loaded series with id ', seriesId);
        this.setState({seasonRecordings, seriesRecording});
        this.setMedia(series);
        this.updateActions(this.state.selectedEpisode || series);
        this.setMediaInitialized();
        this.getRecommendations();
        if (series.seasons.length === 0) {
          this.setSelectedSeason(series);
        } else {
          this.setSelectedSeason(await this.fetchRecommendedSeriesSeason(series));
        }
      })
      .catch(error => Log.error(TAG, 'Error loading series ', error));
  }

  private async fetchRecommendedSeriesSeason(series: Series): Promise<Series> {
    let selectedSeason = null;
    if (series.seasons.length > 1) {
      try {
        const recommendedEpisode = await this.getRecommendedEpisode(series);
        const episode = recommendedEpisode && recommendedEpisode.episode;
        if (episode) {
          selectedSeason = series.seasons.find(series => series.id === episode.seasonId);
        }
      } catch (error) {
        Log.error(TAG, 'Error loading recommended episode ', error);
      }
    }
    return selectedSeason || series.seasons[0];
  }

  private fetchEpisodes(season: Series) {
    Log.debug(TAG, 'Loading episodes of season with id ', season.id);
    const {selectedSeason, episodeSectionKey: oldKey} = this.state;
    const seasonRecording = this.state.seasonRecordings?.find(recording => recording.series?.id === season.id);
    const episodeSectionKey = season.id !== selectedSeason?.id ? oldKey + 1 : oldKey;
    Promise.all<Media[], Recording[]>([
      this.getSeasonEpisodesById(season.id, true),
      seasonRecording ? this.getRecordings({parentRecording: seasonRecording, type: RecordingType.Single}) : []
    ]).then(async values => {
      const [episodes, seriesEventRecordings] = values;
      let singleEventRecordings: Recording[] = [];
      const eventsInEpisodes = episodes.filter(isEvent);
      if (eventsInEpisodes.length) {
        singleEventRecordings = await this.getRecordings({media: eventsInEpisodes});
      }

      const combinedEpisodes = unique([
        ...episodes,
        ...seriesEventRecordings
          .filter(isSingleRecording)
          .map(singleRecording => singleRecording.event)
      ], 'id');

      const combinedRecordings = unique([
        ...seriesEventRecordings,
        ...singleEventRecordings
      ], 'id');

      Log.debug(TAG, 'Loaded episodes of season with id ', season.id);
      this.setState({episodes: combinedEpisodes, episodeSectionKey, eventRecordings: combinedRecordings});
    }).catch(error => Log.error(TAG, 'Error loading episodes ', error));
  }

  private setMedia(media: Media) {
    this.setState({media});
  }

  private setParentalControlEnabled(enabled: boolean) {
    this.setState({parentalControlEnabled: enabled});
  }

  private setMediaInitialized() {
    this.setState({isMediaInitialized: true});
  }

  private updateProgressStateForTitle(title: Title) {
    this.setPlaybackProgress(title.progress);
  }

  private updateProgressStateForEvent(event: Event) {
    this.setPlaybackProgress(event.hasTstv ? event.viewedProgress : event.progress);
  }

  private setPlaybackProgress(playbackProgress: number) {
    this.setState({playbackProgress});
  }

  private getRecordingForMedia(media?: Media): Recording | undefined {
    if (isEvent(media)) {
      return this.state.eventRecordings?.find(recording => recording.event?.id === media.id);
    }

    if (isSeries(media)) {
      return this.state.seriesRecording;
    }
  }

  private deleteRecordingConfirmed = async () => {
    this.closeConfirmationPopup();
    const recording = this.getRecordingForMedia(this.getFocusedMedia());
    if (!recording) {
      return;
    }
    await mw.pvr.deleteRecordings([recording]);
    await this.loadRecording(this.state.media as Event);
    this.updateActions(this.state.media);
  }

  private openConfirmationPopup = () => {
    this.setState({isDeleteConfirmationPopupVisible: true});
  }

  private deleteRecordingPopupStrings() {
    const isPartOfSeries = !!this.getRecordingForMedia(this.getFocusedMedia())?.parentRecordingId;
    const keyPrefix = `mediaDetails.confirmDeleteRecording.${isPartOfSeries ? 'partOfSeries' : 'notPartOfSeries'}.`;
    return {
      title: this.props.t(keyPrefix + 'title'),
      message: this.props.t(keyPrefix + 'message')
    };
  }

  private openBuyPopup = () => {
    this.setState((state) => ({
      isPaymentPopupVisible: true,
      products: getBuyProducts((state.media as Title))
    }));
  }

  private openRentPopup = () => {
    this.setState((state) => ({
      isPaymentPopupVisible: true,
      products: getRentProducts((state.media as Title))
    }));
  }

  private closePaymentPopup = (purchasedMedia?: Media) => {
    if (purchasedMedia) {
      this.setMedia(purchasedMedia);
      this.updateActions(purchasedMedia);
    }
    this.setState({
      isPaymentPopupVisible: false,
      products: []
    });
  }

  private onWatchOnSummaryPurchase = (purchasedMedia?: Media) => {
    this.setState({isPaymentPopupVisible: false});
    const media = purchasedMedia ?? this.state.media;
    if (media) {
      this.setMedia(media);
      this.startPlayback(media);
    }
  }

  private onPaymentSuccess = (order?: Order, productId?: string, offerId?: string, purchasedMedia?: Media) => {
    this.setState({isPaymentPopupVisible: false});
    const media = purchasedMedia ?? this.getFocusedMedia();
    if (!media) {
      Log.warn(TAG, 'onPaymentSucces no media');
      return;
    }
    this.setMedia(media);
    this.updateActions(media);
    if (isTitle(media)) {
      this.startEntitlementStateChecking(media);
    }
  }

  private closeConfirmationPopup = () => {
    this.setState({isDeleteConfirmationPopupVisible: false});
  }

  private unlockMedia = () => {
    if (this.props.shouldBeCheckedForPC(this.state.media)) {
      this.props.unlockMedia(this.state.media)
        .catch((error: UnlockModalRejection) => {
          if (error.reason === UnlockModalRejectionReason.Cancel) {
            this.backToPreviousScreen();
          }
        });
    }
  };

  private onModalVisibilityChange = (visible: boolean) => {
    this.setState({anyModalVisible: visible});
  }

  public render() {
    const {isLoading, isScreenFocused} = this.state;

    const screenStyles = screenStylesUpdater.getStyles();

    const backButton = isBigScreen && isScreenFocused && (
      <BackButton
        // we need to always use the same component instance
        // to avoid remounting and focusing when state changes
        key={this.props.navigation.getParam('mediaId')}
        navigation={this.props.navigation}
        style={screenStyles.backButton}
        onPress={this.onBackPressed}
        onFocus={this.scrollToTop}
        hasTVPreferredFocus={true}
      />
    );

    const {title, message} = this.deleteRecordingPopupStrings();

    return (
      <NitroxScreen
        navigation={this.props.navigation}
        style={[defaultStyles.view, isBigScreen && screenStyles.bigScreenView]}
        onBackButtonPressed={this.onBackPressed}
        onPlaybackStopped={this.onPlaybackStopped}
        popStackOnBack
        menuState={STBMenuState.Hidden}
        onScreenFocused={this.onScreenFocused}
        onScreenBlurred={this.onScreenBlurred}
        onModalVisibilityChange={this.onModalVisibilityChange}
      >
        <FocusParent trapFocus style={StyleSheet.absoluteFill} enterStrategy={isTVOS ? 'topLeft' : 'byPriority'} rememberLastFocused debugName={MediaDetailScreen.name}>
          <>
            {this.state.isMediaInitialized && !this.state.error.error && <UseFocusOnAppear />}
            <NitroxInteractiveController omitGeometryCaching>
              <ScrollView
                ref={this.scrollViewRef}
                style={screenStyles.mainContainer}
                contentContainerStyle={this.props.chromecastExtraBottomPadding}
                alwaysBounceVertical={false}
                onContentSizeChange={this.onContentSizeChange}
              >
                {this.renderContent()}
                {backButton}
              </ScrollView>
            </NitroxInteractiveController>
            {isLoading &&
              <ActivityIndicator size='large' animating={isLoading} style={screenStyles.activityIndicator} />
            }
          </>
          <DeleteConfirmationPopup
            visible={this.state.isDeleteConfirmationPopupVisible}
            title={title}
            info={message}
            onCancel={this.closeConfirmationPopup}
            onConfirmation={this.deleteRecordingConfirmed}
          />
        </FocusParent>
      </NitroxScreen>
    );
  }

  private onRecordPress = async (media: Media) => {
    try {
      await this.props.scheduleRecording(media);
    } catch (error) {
      Log.error(TAG, 'Error scheduling recording:', error);
    }
    await this.loadRecording(media as Event);
    this.updateActions(media);
  };

  private onCancelRecordingPress = async (media: Media, type: RecordingType) => {
    await this.props.cancelRecordingForEvent(media, type);
    await this.loadRecording(media as Event);
    this.updateActions(media);
  };

  private onAddToWatchListPress = async (media: Media) => {
    await addToWatchList(media);
    this.updateActions(media);
  }

  private onRemoveWatchListPress = async (media: Media) => {
    await removeFromWatchList([media]);
    this.updateActions(media);
  }

  private showMoreButton = () => {
    if (!isSmartTV) return;
    Animated.timing(this.seeMoreButtonAnimatedOpacity, {toValue: 1, duration: showButtonAnimationLength}).start();
  }

  private hideMoreButton = () => {
    if (!isSmartTV) return;
    Animated.timing(this.seeMoreButtonAnimatedOpacity, {toValue: 0, duration: hideButtonAnimationLength}).start();
  }

  private onMediaInfoFocus = () => {
    if (!isSmartTV) return;
    this.scrollToTop();
  }

  private onContentSizeChange = (width: number, height: number) => {
    this.contentHeight = height;
  }

  private renderContent() {
    const {isScreenFocused, media, parentalControlEnabled} = this.state;
    const focusedMedia = this.getFocusedMedia();
    if (!media || !focusedMedia || !isScreenFocused || parentalControlEnabled) {
      return null;
    }
    const {orientation} = getScreenInfo();
    const screenSize = Dimensions.get('window');
    const posterSize = isTablet && orientation.isLandscape
      ? dimensions.pictures.detailPage.tabletLandscape
      : dimensions.pictures.detailPage.default;
    const contentMinHeight = isSmartTV && !!this.state.recommendations?.length ? 2 * screenSize.height : posterSize.height;
    const posterUrl = mw.catalog.getPictureUrl(media, PictureType.Background, posterSize.width, posterSize.height, PictureMode.BOX);
    const selectedSeason = this.state.selectedSeason;
    const {channelId, seedSeriesNumber, status} = this.state.seriesRecording ?? {channelId: undefined, seedSeriesNumber: undefined, status: undefined};
    const recordingLimitations: RecordingLimitationsProps = {channel: channelId, startingSeason: seedSeriesNumber, status};
    const screenStyles = screenStylesUpdater.getStyles();
    return (
      <>
        {this.props.renderPlayerLauncherComponent()}
        <View style={[isBigScreen && {minHeight: contentMinHeight}, screenStyles.spaceMediaDetails]} testID='screen_media_details'>
          <BackgroundPoster imageUri={posterUrl} style={[screenStyles.poster, {height: posterSize.height}]} imageResizeMode='contain' />
          <FocusParent style={screenStyles.contentTopContainer} focusPriority={1} onFocusEnter={this.showMoreButton} onFocusEscape={this.hideMoreButton}>
            <FocusParent enterStrategy='byPriority' onFocusEnter={this.onMediaInfoFocus} rememberLastFocused>
              <MediaInfoView
                media={focusedMedia}
                eventRecordings={this.state.eventRecordings}
                recordingLimitations={recordingLimitations}
                playbackProgress={this.state.playbackProgress}
                onStartPlayback={this.startPlayback}
                isBlocked={this.isEpisodeLocked(focusedMedia)}
                hasTVPreferredFocus={!this.state.isLoading && !this.state.selectedEpisode && !this.state.actions.length && !this.state.error.error}
              />
              <MediaDetailButtons
                media={focusedMedia}
                actions={this.state.actions}
                selectedSeason={selectedSeason}
                onSelectSeasonPress={this.showSeasonPicker}
                onRecordPress={this.onRecordPress}
                onDeleteRecordingPress={this.openConfirmationPopup}
                onCancelRecordingPress={this.onCancelRecordingPress}
                style={screenStyles.buttonsView}
                onStartPlayback={this.startPlayback}
                onAddToWatchListPress={this.onAddToWatchListPress}
                onRemoveFromWatchListPress={this.onRemoveWatchListPress}
                onAdultContentUnlock={this.unlockMedia}
                onCustomActionPress={this.onCustomButtonPress}
                onWatchOnPress={this.onWatchOnPress}
                hasTVPreferredFocus={!this.state.selectedEpisode && !this.state.error.error}
                onBuyPress={this.openBuyPopup}
                onRentPress={this.openRentPopup}
              />
            </FocusParent>
            {isBigScreen && this.renderBigScreenMiddleContent()}
          </FocusParent>
          {isBigScreen && !!this.state.recommendations?.length && (
            <RelatedSeeMore
              buttonAnimatedOpacity={this.seeMoreButtonAnimatedOpacity}
              recommendations={this.state.recommendations}
              onFocusEnter={this.scrollToBottom}
            />
          )}
        </View>
        {isMobile && (
          <MobileMediaDetailTabs
            media={media}
            trailers={this.getTrailers()}
            castAndMoreTitle={this.getTitleForCast()}
            selectedSeason={selectedSeason}
            episodes={this.state.episodes}
            onSeasonSelected={this.setSelectedSeason}
            onStartPlayback={this.startPlayback}
            recommendations={this.state.recommendations}
          />
        )}
        {this.props.renderDeepLinkingModal()}
        {this.props.renderRecordingsComponents()}
        <ErrorPopup {...this.state.error} />
        {!!this.state.products.length && this.state.media && (
          <PaymentFlow
            products={this.state.products}
            visible={this.state.isPaymentPopupVisible}
            media={this.state.media}
            onClose={this.closePaymentPopup}
            onPaymentSuccess={this.onPaymentSuccess}
            onWatch={this.onWatchOnSummaryPurchase}
          />
        )}
      </>
    );
  }

  private getFocusedMedia(): Media | undefined {
    return this.state.selectedEpisode ?? this.state.media;
  }

  private renderBigScreenMiddleContent() {
    const {episodeSectionKey, episodes, isSeasonPickerVisible, selectedEpisode, selectedSeason, unlockedMediaList} = this.state;
    const trailers = this.getTrailers();
    const seasons = this.getSeasons();
    const credits = getMediaCredits(this.getTitleForCast());
    const shouldDisplayCastSection = credits.actors.length > 0 || credits.directors.length > 0;
    const shouldDisplayTrailersSection = trailers.length > 0;

    const screenStyles = screenStylesUpdater.getStyles();
    return (
      <>
        {seasons && episodes && (
          <>
            {isSeasonPickerVisible && (
              <Animated.View style={[screenStyles.seasonPickerContainer, {height: this.seasonPickerHeight}]}>
                <BigScreenSeasonPicker
                  seasons={seasons}
                  initialSeason={selectedSeason}
                  onSelected={this.setSelectedSeason}
                />
              </Animated.View>
            )}
            <BigScreenEpisodesSection
              key={episodeSectionKey}
              episodes={episodes}
              selectedEpisode={selectedEpisode}
              onEpisodeTileFocus={this.onEpisodeTileFocus}
              onFocusEnter={this.scrollToTop}
              unlockedMediaList={unlockedMediaList}
            />
          </>
        )}
        {shouldDisplayCastSection && <CastSection {...credits} />}
        {shouldDisplayTrailersSection && (
          <TrailersSection items={trailers} onStartPlayback={this.startPlayback} onFocusEnter={this.scrollToHeaderBottom} />
        )}
      </>
    );
  }

  private getSeasons() {
    const media = this.state.media;

    if (media) {
      const series = media.getType() === MediaType.Series ? media as Series : null;
      if (series) {
        return series.seasons;
      }
    }

    return null;
  }

  private getMediaTitle(media?: Media): Title | null {
    if (!media) {
      return null;
    }

    switch (media.getType()) {
      case MediaType.Title:
        return media as Title;
      case MediaType.Event:
        return (media as Event).title;
      default:
        return null;
    }
  }

  private getTitleForCast(): Title | null {
    const media = this.state.selectedEpisode ||
      this.state.episodes?.[0] ||
      this.state.media;
    return this.getMediaTitle(media);
  }

  private getTrailers(): Title[] {
    const titles: Array<Title | null> = [this.getMediaTitle(this.state.media)];

    if (isSeries(this.state.media) && this.state.selectedSeason) {
      titles.push(
        ...this.state.media.titles,
        ...this.state.selectedSeason.titles
      );
    }

    if (isMobile && this.state.episodes) {
      titles.push(
        ...this.state.episodes
          .map(episode => getMediaSubtypes(episode).title)
      );
    } else {
      if (this.state.selectedEpisode) {
        titles.push(getMediaSubtypes(this.state.selectedEpisode).title);
      }
    }

    return flatten(compactMap(titles, title => title?.trailers));
  }

  private isEpisodeLocked = (media: Media): boolean => {
    return !!((media as Title)?.episode && this.props.shouldBeCheckedForPC(media) && this.props.isMediaBlocked(media) && !this.state.unlockedMediaList.find((unlockedMedia) => unlockedMedia.id === media.id));
  };
}

export default withTranslation()(withNavigation(withDeepLinking(withRecord(withChromecastExtraBottomPadding(withParentalControl(withPlayerLauncher(MediaDetailScreen)))))));
