/* eslint-disable schange-rules/local-alternative */
import React, {useCallback, useMemo} from 'react';
import {Text, View} from 'react-native';

import {doNothing} from 'common/HelperFunctions';
import {useAsyncUpdater} from 'common/hooks/Hooks';

import {useFunction} from 'hooks/Hooks';

import {NodeGeometry} from './focusManager/FocusManagerTypes';
import {FocusParentNode} from './focusManager/FocusParentNode';
import {getGeometry} from './focusManager/geometry';

// consider serializing to more readable text
function geometryToString(geometry?: NodeGeometry | null): string {
  return geometry == null
    ? ''
    : JSON.stringify(geometry);
}

function FocusParentDebugger(
  {
    node,
    geometry,
    debugName
  }: {
    node: FocusParentNode;
    geometry?: NodeGeometry | null;
    debugName?: string;
  }
) {
  const geometryString = useMemo(() => geometryToString(geometry), [geometry]);

  const text = (
    <>
      <Text>{`[${node.id}]`}</Text>
      <Text>{debugName}</Text>
      {!!geometryString && <Text style={{marginLeft: 10}}>{geometryString}</Text>}
    </>
  );
  return (
    <View
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        // eslint-disable-next-line schange-rules/no-literal-color
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        flexDirection: 'row'
      }}
    >
      {text}
    </View>
  );
}

export function useFocusParentDebugger(
  {
    node,
    debug,
    debugName
  }: {
    node: FocusParentNode;
    debug: boolean;
    debugName?: string;
  }
): {
    updateDebugGeometry: () => void;
    renderDebugger: () => React.ReactNode
  } {
  const {state: geometry, update: updateGeometry} = useAsyncUpdater<NodeGeometry | null | undefined>(
    // setState(previousState) does not cause rerender
    useFunction(() => debug ? getGeometry(node) : Promise.resolve(undefined))
  );

  const renderDebugger = useCallback(() => debug && <FocusParentDebugger node={node} debugName={debugName} geometry={geometry} />, [debug, debugName, geometry, node]);

  return {
    updateDebugGeometry: debug ? updateGeometry : doNothing,
    renderDebugger
  };
}
