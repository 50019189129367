import {cancelablePipeline, CancelableFunction} from 'common/Async';
import {isATV} from 'common/constants';
import {EventEmitter} from 'common/EventEmitter';
import {Log} from 'common/Log';

import {Error, Errors, ErrorType, mapNativeError} from 'mw/api/Error';
import {ContentType, Media, Playable, PlaybackLimitations, PlaybackStopReason} from 'mw/api/Metadata';
import {nxffConfig} from 'mw/api/NXFF';
import {AudioLanguagesChanged, MediaEvent, PlayerEvent, PositionChanged, StopParams, StoppedEvent, SubtitlesChanged, Track, TracksList, ErrorEvent} from 'mw/api/PlayerEvent';
import {PlaybackAsset} from 'mw/bo-proxy/BOInterface';
import {boProxy} from 'mw/bo-proxy/BOProxy';
import {DrmSession} from 'mw/bo-proxy/SSOInterface';
import {getPlayerModule} from 'mw/playback/PlayerModule';
import {CDNSession, CDNSessionManagerFactory} from 'mw/playback/sessions/CDNSessionManager';
import {PlaybackSession, SessionTerminationReason, PlaybackSessionEvent} from 'mw/playback/sessions/PlaybackSessionManager';
import {NativePlaybackParameters} from 'mw/playback/types/NativePlaybackParameters';
import {NativePositionChanged} from 'mw/playback/types/PlaybackEvent';
import {PlaybackParameters, PlaybackRequestParameters, PlaybackParametersProvider, PlaybackResponse} from 'mw/playback/types/PlaybackParameters';
import {PlayerType} from 'mw/playback/types/PlayerType';

import {PlaybackRewinder, RewindDirection, PlaybackRewinderEvent, RewindingPositionChangedEvent, RewindingEvent} from './rewinders/PlaybackRewinder';
import {SimplePlaybackRewinder} from './rewinders/SimplePlaybackRewinder';
import {SkipBasedPlaybackRewinder} from './rewinders/SkipBasedPlaybackRewinder';
import {getDefaultAudioTrack, getDefaultSubtitlesTrack} from './utils';

const TAG = 'PlaybackController';

export {RewindDirection} from './rewinders/PlaybackRewinder';

export interface NotifierInterface {
  (playerEvent: PlayerEvent, params?: any): void;
}

const invalidNotifier: NotifierInterface = () => {};

export interface StartPlaybackParameters {
  url: string;
  playable: Playable;
  playbackParameters: PlaybackRequestParameters;
}

export abstract class PlaybackController extends EventEmitter<PlayerEvent> implements PlaybackParametersProvider {

  private viewId?: number;
  protected playerType: PlayerType;
  protected contentType: ContentType;
  protected playbackParameters: PlaybackParameters;
  protected playbackRewinder: PlaybackRewinder;
  private session: PlaybackSession | null = null;
  private cdnSession: CDNSession | null = null;
  private url = '';
  private drmSession: DrmSession | null = null;
  protected currentMedia: Media | null = null;
  protected playable: Playable | null = null;
  protected notifier: NotifierInterface = invalidNotifier;
  private audioLanguages: TracksList = {tracks: [], currentTrack: -1};
  private subtitleLanguages: TracksList = {tracks: [], currentTrack: -1};
  private stopReason?: PlaybackStopReason;

  private waitingForAudioTracks = true;
  private waitingForSubtitleTracks = true;
  protected initialized = false;
  private pcBlocked = false;

  protected constructor(windowType: PlayerType, contentType: ContentType) {
    super();
    this.contentType = contentType;
    this.playerType = windowType;
    this.playbackParameters = {
      beginPosition: 0,
      endPosition: 0,
      playRate: 1,
      position: 0,
      audioMuted: false
    };
    this.playbackRewinder = new (isATV ? SimplePlaybackRewinder : SkipBasedPlaybackRewinder)(
      this,
      // do not call setParameters here because it updates rewinder's parameters, instead directly set player module's parameters
      (parameters: PlaybackRequestParameters) => this.setPlayerModuleParameters(this.convertParameters(parameters))
    );
    this.playbackRewinder.on(PlaybackRewinderEvent.Rewinding, this.onRewinding.bind(this));
    this.playbackRewinder.on(PlaybackRewinderEvent.PositionChanged, this.onRewindingPositionChanged.bind(this));
    this.playbackRewinder.on(PlaybackRewinderEvent.Stopped, this.onRewindingStopped.bind(this));
  }

  public initialize(viewId: number, source: Media, playbackParameters: PlaybackRequestParameters): Promise<PlaybackResponse> {
    this.viewId = viewId;
    this.currentMedia = source;
    this.playable = source.getPlayable();
    this.notify(PlayerEvent.Initialize, {position: playbackParameters.position});
    if (!this.playable) {
      throw new Error(ErrorType.PlaybackSourceNotPlayable);
    }
    return this.cancelableInitialize(source, this.playable, playbackParameters);
  }

  // decorated methods become properties, making it impossible to call super.decoratedMethod()
  public cancelableInitialize:
  CancelableFunction<(source: Media, playable: Playable, playbackParameters: PlaybackRequestParameters, isFallback?: boolean) => Promise<PlaybackResponse>> =
  cancelablePipeline(
    async (source: Media, playable: Playable, playbackParameters: PlaybackRequestParameters, isFallback = false) => {
      Log.debug(TAG, 'cancelableInitialize', source, playbackParameters, isFallback);

      this.stopReason = undefined;

      this.session = await (isFallback ? this.createFallbackPlaybackSession(playable, source) : this.createPlaybackSession(playable, source));

      return {source, playbackParameters, playable: playable, isFallback};
    },
    async (
      {source, playbackParameters, playable, isFallback}:
      {source: Media; playbackParameters: PlaybackRequestParameters; playable: Playable; isFallback: boolean}
    ) => {
      if (!this.session) {
        throw Errors.PlaybackSessionMissing;
      }

      const asset = this.session.getAsset(this.getAsset(playbackParameters.position || 0));

      if (boProxy.drmSessionManager) {
        this.drmSession = await boProxy.drmSessionManager.createSession({
          session: this.session,
          media: source,
          playable
        }).catch(error => {
          if (error.type === ErrorType.DRMNotRequired) {
            Log.debug(TAG, 'DRM not required');
            return null;
          }
          throw error;
        });
      }
      return {source, playbackParameters, playable, asset, isFallback};
    },
    async (
      {source, playbackParameters, playable, asset, isFallback}:
      {source: Media; playbackParameters: PlaybackRequestParameters; playable: Playable; asset: PlaybackAsset; isFallback: boolean}
    ) => {
      this.cdnSession = await CDNSessionManagerFactory.getCDNSessionManager(source, asset).createSession(asset.url, {});

      return {source, playbackParameters, playable, isFallback};
    },
    async (
      {source, playbackParameters, playable, isFallback}:
      {source: Media; playbackParameters: PlaybackRequestParameters; playable: Playable; isFallback: boolean}
    ) => {
      if (!this.cdnSession || !this.cdnSession.url) {
        throw Errors.PlaybackSessionMissing;
      }

      this.url = this.cdnSession.url;
      this.currentMedia = source;

      let response;
      try {
        response = await this.startPlayback({
          url: this.cdnSession.url,
          playable,
          playbackParameters: playbackParameters
        });
      } catch (e) {
        switch (e.errorCode) {
          case ErrorType.NativePlayerDrmDvbUnsupported:
          case ErrorType.NativePlayerDrmDvbCasInternalError:
          case ErrorType.NativePlayerDrmDvbKeyError:
            throw mapNativeError(e.errorCode, e.message, e.extra);
        }

        if (isFallback) {
          throw e;
        }
        if (playable.hasFallbackPlayback()) {
          Log.warn(TAG, 'Start playback failed, creating fallback session', e);
          await this.stopPlaybackSession(PlaybackStopReason.Error);
          return this.cancelableInitialize(source, playable, playbackParameters, true);
        }
        if (e.errorCode === ErrorType.NativePlayerNoDVBSignal) {
          throw Errors.NativePlayerNoSignal;
        }
        throw e;
      }

      this.session?.on(PlaybackSessionEvent.SessionError, this.onSessionError);

      this.initialized = true;

      return response;
    }
  )

  public changeParameters(playbackParameters: PlaybackRequestParameters): Promise<PlaybackResponse> {
    Log.info(TAG, 'changeParameters', playbackParameters);
    if (!this.initialized) {
      return Promise.reject(Errors.PlayerNotStarted);
    }
    return this.setParameters(this.convertParameters(playbackParameters));
  }

  public getPlaybackParameters = (): PlaybackParameters => {
    return this.playbackParameters;
  }

  public startPlayback(params: StartPlaybackParameters): Promise<PlaybackResponse> {
    Log.info(TAG, 'startPlayback', params);

    const parameters: NativePlaybackParameters = this.convertParameters(params.playbackParameters);

    this.url = params.url;
    parameters.url = params.url;

    if (this.drmSession) {
      Object.assign(parameters, this.drmSession);
      parameters.drmProxyType = nxffConfig.getConfig().DRM.DRMProxyType;
    }

    return this.setParameters(parameters);
  }

  public async stop(params: StopParams): Promise<PlaybackResponse> {
    const {reason} = params;
    Log.info(TAG, 'stop', reason);

    this.stopReason = reason;

    const nativePlaybackParameters: NativePlaybackParameters = {
      playerType: this.playerType
    };

    try {
      await this.playbackRewinder.stop();
      return await this.setParameters(nativePlaybackParameters);
    } finally {
      this.url = '';
      await Promise.all([
        this.stopCDNSession(),
        this.stopPlaybackSession(reason)
      ]).finally(() => this.notify(PlayerEvent.StopRequested, params));
    }
  }

  public switchPlayerView(viewId: number, playerType: PlayerType): Promise<PlaybackResponse> {
    const oldViewId = this.viewId;
    if (!oldViewId) {
      return Promise.reject(new Error(ErrorType.InvalidParameter));
    }
    this.viewId = viewId;
    return new Promise((resolve, reject) => {
      getPlayerModule()
        .switchPlayerView(oldViewId, viewId, playerType)
        .then((response: PlaybackResponse) => {
          Log.info(TAG, 'switchPlayerView', 'resolved', response);
          resolve(response);
        })
        .catch((error: any) => {
          const reason = error && error.userInfo;
          Log.error(TAG, 'switchPlayerView', 'rejected', reason);
          reject(reason);
        });
    });
  }

  public getPlayerViewId(): number | undefined {
    return this.viewId;
  }

  public startRewind(direction: RewindDirection): Promise<void> {
    return this.playbackRewinder.start(direction);
  }

  public stopRewind(): Promise<void> {
    return this.playbackRewinder.stop();
  }

  public isRewinding(): boolean {
    return this.playbackRewinder.isRewinding();
  }

  public getRewindDirection(): RewindDirection | undefined {
    return this.playbackRewinder.getRewindDirection();
  }

  protected onRewinding(event: RewindingEvent): void {
    Log.debug(TAG, 'onRewinding', event.delta);
    this.notify(PlayerEvent.Rewinding, event);
  }

  protected onRewindingPositionChanged(event: RewindingPositionChangedEvent): void {
    Log.debug(TAG, 'onRewindingPositionChanged', event.position);
    this.notify(PlayerEvent.PositionChanged, {
      beginPosition: this.playbackParameters.beginPosition,
      endPosition: this.playbackParameters.endPosition,
      position: event.position
    });
  }

  protected onRewindingStopped(): void {
    Log.debug(TAG, 'onRewindingStopped');
    this.notify(PlayerEvent.RewindStopped);
  }

  public onBeginOfContent(mediaEvent: MediaEvent): void {
    Log.debug(TAG, 'onBeginOfContent');
    this.playbackRewinder.stop();
    this.notify(PlayerEvent.BeginOfContent, mediaEvent);
  }

  public onBuffering(mediaEvent: MediaEvent): void {
    Log.debug(TAG, 'onBuffering');
    this.notify(PlayerEvent.Buffering, mediaEvent);
  }

  public onEndOfContent(mediaEvent: MediaEvent): void {
    Log.debug(TAG, 'onEndOfContent');
    this.playbackParameters.playRate = 0;
    this.playbackRewinder.stop();
    this.notify(PlayerEvent.EndOfContent, mediaEvent);
  }

  public onFirstFrame(mediaEvent: MediaEvent): void {
    Log.ta(TAG, 'onFirstFrame', this.url, mediaEvent.url);
    this.cdnSession && this.cdnSession.onFirstFrame();
    this.notify(PlayerEvent.FirstFrame, mediaEvent);
  }

  public onReady(mediaEvent: MediaEvent): void {
    Log.debug(TAG, 'onReady');
    this.notify(PlayerEvent.Ready, mediaEvent);
  }

  public onStopped(mediaEvent: MediaEvent): void {
    Log.debug(TAG, 'onStopped', this.url, mediaEvent.url);
    const stoppedEvent: StoppedEvent = {
      ...mediaEvent,
      reason: this.stopReason
    };
    this.playbackParameters.playRate = 0;
    this.stopReason = undefined;
    this.playbackRewinder.stop();
    this.notify(PlayerEvent.Stopped, stoppedEvent);
  }

  public onError(errorEvent: ErrorEvent) {
    Log.error(TAG, 'onError: ', JSON.stringify(errorEvent));
    this.notify(PlayerEvent.Error, mapNativeError(errorEvent.error, errorEvent.message, errorEvent.extra));
  }

  private onSessionError = (error: Error) => {
    Log.error(TAG, 'onSessionError: ', error);
    this.notify(PlayerEvent.Error, error);
  }

  public validateUrl(url: string): boolean {
    return url === this.url;
  }

  public getPlaybackLimitations(): PlaybackLimitations {
    return this.session?.getCurrentAsset()?.playbackLimitations ?? {};
  }

  protected applyChangedParameters(playbackParameters: NativePlaybackParameters): void {
    if (playbackParameters.playRate != null) {
      this.playbackParameters.playRate = playbackParameters.playRate;
    }
  }

  protected convertParameters(playbackParameters: PlaybackRequestParameters): NativePlaybackParameters {
    Log.debug(TAG, 'convertParameters', playbackParameters, this.playerType, this.contentType);

    const nativePlaybackParameters: NativePlaybackParameters = {
      ...playbackParameters,
      playerType: this.playerType,
      contentType: this.contentType
    };

    return nativePlaybackParameters;
  }

  protected beforeSetParameters(parameters: NativePlaybackParameters): Promise<void> {
    if (typeof parameters.hideVideo !== 'undefined') {
      Log.debug(TAG, 'setParameters: Setting video to', parameters.hideVideo ? 'hidden' : 'visible');
      this.notify(PlayerEvent.VideoHidden, parameters.hideVideo);
    }
    return Promise.resolve();
  }

  protected afterSetParameters(parameters: NativePlaybackParameters): void {}

  protected async setParameters(parameters: NativePlaybackParameters): Promise<PlaybackResponse> {
    await this.playbackRewinder.updateParameters(parameters);
    return this.setPlayerModuleParameters(parameters);
  }

  private setPlayerModuleParameters(parameters: NativePlaybackParameters): Promise<PlaybackResponse> {
    if (!this.viewId) {
      Log.warn(TAG, 'Cannot apply parameters, missing viewId');
      return Promise.reject(Errors.PlayerNotStarted);
    }

    return this.beforeSetParameters(parameters)
      .then(() => new Promise((resolve, reject) => {
        getPlayerModule()
          .setParameters(this.viewId, parameters)
          .then((response: PlaybackResponse) => {
            Log.info(TAG, 'setParameters', 'resolved', response);
            this.applyChangedParameters(parameters);
            resolve(response);
            this.notify(PlayerEvent.ParametersChanged, parameters);
          })
          .catch((error: any) => {
            const reason = error && error.userInfo;
            Log.error(TAG, 'setParameters', 'rejected', reason);
            reject(reason);
          })
          .finally(() => this.afterSetParameters(parameters));
      }));
  }

  protected stopPlaybackSession(reason: PlaybackStopReason): Promise<void> {
    if (!this.session) {
      return Promise.resolve();
    }
    this.session.off(PlaybackSessionEvent.SessionError, this.onSessionError);
    return boProxy.playbackSessionManager.destroySession(
      this.session, PlaybackController.getSessionTerminationReason(reason)
    )
      .catch((error: Error) => Log.warn(TAG, 'Error terminating playback session', error))
      .finally(() => this.session = null);
  }

  private static getSessionTerminationReason(reason: PlaybackStopReason): SessionTerminationReason {
    switch (reason) {
      case PlaybackStopReason.UserAction:
        return SessionTerminationReason.UserAction;
      default:
        return SessionTerminationReason.Other;
    }
  }

  protected stopCDNSession(): Promise<void> {
    return !this.cdnSession ? Promise.resolve() : this.cdnSession.terminate()
      .catch((error: Error) => Log.warn(TAG, 'Error terminating cdn session', error))
      .finally(() => this.cdnSession = null);
  }

  protected getAsset(position: number): number {
    // TODO: implement switch over assets to position
    return 0;
  }

  protected createPlaybackSession(playable: Playable, media: Media): Promise<PlaybackSession> {
    return boProxy.playbackSessionManager.createSession({
      playbackParametersProvider: this,
      playable: playable,
      media: media
    });
  }

  protected createFallbackPlaybackSession(playable: Playable, media: Media): Promise<PlaybackSession> {
    return boProxy.playbackSessionManager.createFallbackSession({
      playbackParametersProvider: this,
      playable: playable,
      media: media
    });
  }

  public getPosition(): number {
    return this.playbackParameters.position || 0;
  }

  public getBeginPosition(): number {
    return this.playbackParameters.beginPosition;
  }

  public getEndPosition(): number {
    return this.playbackParameters.endPosition;
  }

  public getCurrentMedia(): Media | null {
    return this.currentMedia;
  }

  public positionChanged(nativePositionChanged: NativePositionChanged): void {
    const positionChanged: PositionChanged = {
      ...nativePositionChanged,
      position: nativePositionChanged.position || 0
    };
    this.playbackParameters.beginPosition = positionChanged.beginPosition;
    this.playbackParameters.endPosition = positionChanged.endPosition;
    this.playbackParameters.position = positionChanged.position;
    if (!this.playbackRewinder.isRewinding()) {
      // onRewindingPositionChanged callback will handle sending notification about position change
      this.notify(PlayerEvent.PositionChanged, positionChanged);
    }
    Log.ta(TAG, 'onPositionChanged', positionChanged.position);
  }

  public connect(notify: NotifierInterface): void {
    this.notifier = notify;
  }

  public disconnect(): void {
    this.notifier = invalidNotifier;
  }

  public getAudioLanguages(): TracksList {
    return this.audioLanguages;
  }

  public onAudioLanguagesChanged(audioLanguagesChanged: AudioLanguagesChanged): void {
    Log.debug(TAG, 'onAudioLanguagesChanged');
    this.audioLanguages = audioLanguagesChanged.audioLanguageList;
    this.notify(PlayerEvent.AudioLanguagesChanged, audioLanguagesChanged);

    if (this.waitingForAudioTracks) {
      this.waitingForAudioTracks = false;
      const defaultTrack = getDefaultAudioTrack(this.audioLanguages.tracks);
      if (defaultTrack) {
        this.setCurrentAudioLanguageTrack(this.playerType, defaultTrack)
          .catch(error => Log.error(TAG, 'Unable to set current audio track', error));
      }
    }
  }

  public setCurrentAudioLanguageTrack(playerType: PlayerType, track: Track): Promise<void> {
    return getPlayerModule().setCurrentAudioTrack(playerType, track.language, track.type, track.codec);
  }

  public getSubtitleLanguages(): TracksList {
    return this.subtitleLanguages;
  }

  public onSubtitlesChanged(subtitlesChanged: SubtitlesChanged): void {
    Log.debug(TAG, 'onSubtitlesChanged');
    this.subtitleLanguages = subtitlesChanged.subtitleLanguageList;
    this.notify(PlayerEvent.SubtitlesChanged, subtitlesChanged);

    if (this.waitingForSubtitleTracks) {
      this.waitingForSubtitleTracks = false;
      const defaultTrack = getDefaultSubtitlesTrack(this.subtitleLanguages.tracks);
      if (defaultTrack) {
        this.setCurrentSubtitleTrack(this.playerType, defaultTrack)
          .catch(error => Log.error(TAG, 'Unable to set current subtitle track', error));
      }
    }
  }

  public setCurrentSubtitleTrack(playerType: PlayerType, track: Track): Promise<void> {
    return getPlayerModule().setCurrentSubtitleTrack(playerType, track.language, track.type);
  }

  public getContentType(): ContentType {
    return this.contentType;
  }

  public notify(event: PlayerEvent, params?: any): void {
    super.notify(event, params);
    this.notifier(event, params);
    this.currentMedia &&
      boProxy.reportingManager?.handleEvent(event, params, {
        playable: this.playable || undefined,
        contentType: this.contentType,
        media: this.currentMedia
      });
  }

  public getPlaybackStartTime(): Date | null {
    return null;
  }

  public setPlaybackBoundaries(lowestPosition: number, highestPosition: number): Promise<PlaybackResponse> {
    return getPlayerModule()
      .setPlaybackBoundaries(this.playerType, lowestPosition, highestPosition)
      .then(() => Log.info(TAG, 'setPlaybackBoundaries succeeded'))
      .catch((error: any) => {
        Log.error(TAG, 'setPlaybackBoundaries failed', error && error.userInfo);
        throw error;
      });
  }

  public setAudioMuted(muted: boolean): Promise<PlaybackResponse> {
    if (this.isPCBlocked()) {
      Log.info(TAG, `setAudioMuted:${muted} skipped, audio muted by PC`);
      this.playbackParameters.audioMuted = muted;
      return Promise.resolve({streamId: 0}); // empty response
    }

    return this.changeParameters({mute: muted})
      .then(playbackResponse => {
        Log.info(TAG, 'setAudioMuted succeeded', muted);
        this.playbackParameters.audioMuted = muted;
        return playbackResponse;
      })
      .catch((error: any) => {
        Log.error(TAG, 'setAudioMuted failed', error && error.userInfo);
        throw error;
      });
  }

  public isAudioMuted(): boolean {
    return this.playbackParameters.audioMuted;
  }

  public setPCBlocked(block: boolean): Promise<void> {
    const params: NativePlaybackParameters = {
      playerType: this.playerType,
      hideVideo: block
    };
    if (!this.isAudioMuted()) {
      params.mute = block;
    }
    return this.setParameters(params)
      .then(() => {
        Log.info(TAG, 'setPCBlocked succeeded', block);
        this.pcBlocked = block;
      })
      .catch((error: any) => {
        Log.error(TAG, 'setPCBlocked failed', block, (error && error.userInfo) || error);
        throw error;
      });
  }

  public isPCBlocked(): boolean {
    return this.pcBlocked;
  }
}
