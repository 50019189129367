import React from 'react';
import {useTranslation} from 'react-i18next';

import {Credits} from 'mw/api/Metadata';

import CreditsView from './CreditsView';
import SectionView from './SectionView';

type Props = {
  actors: Credits[];
  directors: Credits[];
}

const CastSection: React.FunctionComponent<Props> = props => {
  const {t} = useTranslation();

  return (
    <SectionView title={t('mediaDetails.cast')} focusable={false}>
      <CreditsView {...props} />
    </SectionView>
  );
};

export default React.memo(CastSection);
