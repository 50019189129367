import React from 'react';
import {useTranslation} from 'react-i18next';
import {NavigationScreenProps} from 'react-navigation';

import {isBigScreen} from 'common/constants';
import {environment} from 'common/Environment';

import {IconType} from 'components/Icon';

import SettingsDetails from './SettingsDetails';
import {SettingsDetailsHeader} from './SettingsDetailsHeader';

const buildTag = environment.getString('BUILD_TAG');
const buildSha = environment.getString('BUILD_SHA');
const buildDate = environment.getString('BUILD_DATE');
const ciJobId = environment.getString('CI_JOB_ID');
const ciPipelineId = environment.getString('CI_PIPELINE_ID');

const ciInfo = `${ciJobId ? 'Job: ' + ciJobId : ''} ${ciPipelineId ? 'Pipeline: ' + ciPipelineId : ''}`.trim();
const buildInfo = `${buildTag || buildSha}\n${buildDate}\n${ciInfo}`.trim().replace(/  +/g, ' ');

const threeLineTextBarHeight = isBigScreen ? 113 : 75;
const oneLineTextBarHeight = isBigScreen ? 75 : 50;

const SettingsAppVersionScreen: React.FC<NavigationScreenProps<{}>> = props => {
  const {t} = useTranslation();
  return (
    <SettingsDetails
      title={t('settings.appVersion')}
      barText={buildInfo || t('settings.localDevBuild')}
      barHeight={ciInfo ? threeLineTextBarHeight : oneLineTextBarHeight}
      header={<SettingsDetailsHeader icon={IconType.AppVersionBig} />}
      navigation={props.navigation}
      testID='screen_settings_app_version'
    />
  );
};

export default SettingsAppVersionScreen;
