import React from 'react';
import {useTranslation} from 'react-i18next';

import {RecordingErrorCode} from 'mw/api/Metadata';

import ErrorLabel from 'components/ErrorLabel';

type Props = {
  errorCode: RecordingErrorCode;
};

const RecordingErrorLabel: React.FC<Props> = props => {
  const {errorCode} = props;
  const {t} = useTranslation();

  if (errorCode !== RecordingErrorCode.QuotaExceeded) {
    return null;
  }

  return (
    <ErrorLabel>{t('mediaDetails.recordingError.quotaExceeded')}</ErrorLabel>
  );
};

export default React.memo(RecordingErrorLabel);
