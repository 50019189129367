import Color from 'color';
import {createStyles} from 'common-styles';
import React, {useRef, useMemo, useEffect, useCallback} from 'react';
import {Animated, StyleSheet, Easing, ViewStyle, StyleProp} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import {dimensions} from 'common/constants';
import {DateUtils} from 'common/DateUtils';

import {StylesUpdater, useStyles} from 'common-styles/StylesUpdater';

import {PictureType, PictureMode} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import AnimatedGradient from 'components/AnimatedGradient';
import FocusParent from 'components/FocusParent';
import {useDisposableState, useLazyEffect, useToggle, useScreenInfo} from 'hooks/Hooks';
import {useFixedHeightImage} from 'hooks/useFixedHeightImage';
import {useImageFadeAnimation} from 'hooks/useImageFadeAnimation';

import ProgressIndicator from './ProgressIndicator';
import {PromotionalBannerComponentProps, promotionalBannerScrollDuration, fullWidthPromotionalBannerHeightFocused, framedPromotionalBannerHeight, PromotionalBannerMode} from './PromotionalBanner.shared';
import PromotionalBannerDetails from './PromotionalBannerDetails';
import {PromotionalBannerDetailsMargins} from './PromotionalBannerDetails.shared';

type ModeBasedConfig<T> = {
  [key in keyof typeof PromotionalBannerMode]: T;
};

// Default image resolution: 1920x1080
const fullHDWidth = 1920;
const fullWidthPromotionalBannerHeight = 720;
const frameRadius = 20;
const detailsMargin = 100;
const defaultImageSize = {
  width: fullHDWidth,
  height: fullWidthPromotionalBannerHeightFocused
};

const animationDuration = 300;
const opacityDelay = animationDuration * 0.4;
const opacityDuration = animationDuration - opacityDelay;
const gradientAnimationShrinkDuration = animationDuration * 0.7;
const gradientAnimationExpandDuration = animationDuration * 1.4;

const horizontalGradientLocationsFullWidth = [0, 0.4, 0.5, 0.6, 0.8, 0.9, 1];
const horizontalGradientLocationsFramed = [0, 0.45, 0.5, 0.56, 0.6, 0.8, 1];
const verticalGradientLocations = [0, 0.6, 1];
const alphaValuesFramed = [1, 1, 1, 1, 0.78, 0, 0];
const alphaValuesFullWidth = [1, 1, 0.7, 0.2, 0, 0, 0];
const alphaValuesFocused = [1, 0.4, 0.3, 0, 0, 0, 0];
const alphaValuesTop = [1, 0.21, 0];
const alphaValuesBottom = [0, 0.31, 1];
const horizontalGradientStart = {x: 0, y: 0};
const horizontalGradientEnd = {x: 1, y: 0};
const verticalGradientStart = {x: 0, y: 0};
const verticalGradientEnd = {x: 0, y: 1};

const progressIndicatorOffsetFramed = 30;
const progressIndicatorOffsetFullWidth = 130;

const styles = new StylesUpdater(colors => createStyles({
  gradientFullWidth: {
    color: colors.vodScreen.promotionalBanner.background.fullWidth
  },
  gradientFramed: {
    color: colors.vodScreen.promotionalBanner.background.framed
  }
}));

const staticStyles = createStyles({
  progressIndicator: {
    flexDirection: 'row',
    alignSelf: 'center'
  },
  progressSegmentStyle: {
    height: 6,
    borderRadius: 3,
    width: 50
  },
  banner: {
    justifyContent: 'flex-end',
    alignItems: 'stretch'
  },
  detailsContainer: {
    position: 'absolute'
  },
  frame: {
    height: framedPromotionalBannerHeight,
    overflow: 'hidden',
    borderRadius: frameRadius
  },
  framedDetailsContainer: {
    justifyContent: 'flex-start',
    paddingTop: 50,
    paddingLeft: 60
  },
  horizontalGradient: {
    ...StyleSheet.absoluteFillObject
  },
  verticalGradientTop: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 300
  },
  verticalGradientBottom: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 300
  },
  imageContainerDesktopFullScreen: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%'
  }
});

const PromotionalBannerGrosso: React.FC<PromotionalBannerComponentProps> = ({
  style,
  detailsStyle,
  mode,
  componentHeight: componentHeightProps,
  componentHeightFocused = fullWidthPromotionalBannerHeightFocused,
  staticallyFocused,
  hasTvPreferredFocus,
  gradientColor,
  data,
  progressIndicatorOffset,
  imageSize = defaultImageSize,
  visible = true,
  expanded = false,
  onFocus: propsOnFocus
}) => {
  const {size} = useScreenInfo();
  const {gradientFullWidth, gradientFramed} = useStyles(styles);
  const [focused, {on: onFocus, off: onBlur}] = useToggle(!!hasTvPreferredFocus);
  const usingFocusedStyle = focused || expanded || mode === 'desktopFullScreen';
  const [currentIndex, setCurrentIndex] = useDisposableState(0);

  const getModeValue: <T>(config: ModeBasedConfig<T>) => T = useCallback(config => {
    switch (mode) {
      case PromotionalBannerMode.framed:
        return config.framed;
      case PromotionalBannerMode.fullWidth:
        return config.fullWidth;
      case PromotionalBannerMode.desktopFullScreen:
        return config.desktopFullScreen;
    }
  }, [mode]);

  const componentHeight = componentHeightProps ?? getModeValue<number>({
    framed: framedPromotionalBannerHeight,
    fullWidth: fullWidthPromotionalBannerHeight,
    desktopFullScreen: size.height
  });

  const progressAnimation = useRef<Animated.CompositeAnimation | null>(null);
  const progress = useRef(new Animated.Value(0));
  const expansionAnimation = useRef<Animated.CompositeAnimation | null>(null);
  const animatedHeight = useRef(new Animated.Value(componentHeight));
  const animatedDetailsMargin = useRef(new Animated.Value(getModeValue<number>({
    framed: 0,
    desktopFullScreen: 0,
    fullWidth: detailsMargin
  })));
  const animatedOpacity = useRef(new Animated.Value(getModeValue<number>({
    framed: 1,
    desktopFullScreen: 1,
    fullWidth: 0
  })));

  const visibilityAnimation = useRef<Animated.CompositeAnimation | null>(null);
  const visibilty = useRef(new Animated.Value(1));

  const horizontalGradientLocations = useMemo(() => getModeValue<number[]>({
    framed: horizontalGradientLocationsFramed,
    desktopFullScreen: horizontalGradientLocationsFullWidth,
    fullWidth: horizontalGradientLocationsFullWidth
  }), [getModeValue]);
  const alphaValues = useMemo(() => getModeValue<number[]>({
    framed: alphaValuesFramed,
    desktopFullScreen: alphaValuesFullWidth,
    fullWidth: alphaValuesFullWidth
  }), [getModeValue]);
  const gradient = useMemo(() => ({
    color: getModeValue<string>({
      framed: gradientFramed.color,
      desktopFullScreen: gradientFullWidth.color,
      fullWidth: gradientFullWidth.color
    })
  }), [getModeValue, gradientFullWidth, gradientFramed]);
  const horizontalGradientColors = useMemo(() => alphaValues.map(value => (gradientColor ?? Color(gradient.color)).alpha(value).toString()), [gradient, gradientColor, alphaValues]);
  const horizontalGradientColorsFocused = useMemo(() => alphaValuesFocused.map(value => (gradientColor ?? Color(gradient.color)).alpha(value).toString()), [gradient, gradientColor]);
  const verticalGradientColorsTop = useMemo(() => alphaValuesTop.map(value => (gradientColor ?? Color(gradient.color)).alpha(value).toString()), [gradient, gradientColor]);
  const verticalGradientColorsBottom = useMemo(() => alphaValuesBottom.map(value => (gradientColor ?? Color(gradient.color)).alpha(value).toString()), [gradient, gradientColor]);

  useLazyEffect(() => {
    setCurrentIndex(0);
  }, [data], [setCurrentIndex]);

  useLazyEffect(() => {
    if (data.media.length < 2) {
      return;
    }
    progressAnimation.current = Animated.timing(progress.current, {
      toValue: 1,
      duration: data.rotation * DateUtils.msInSec,
      useNativeDriver: true
    });
    progressAnimation.current?.start(() => {
      progress.current.setValue(0);
      setCurrentIndex(index => (index + 1) % data.media.length);
    });

    return () => {
      progressAnimation.current?.stop();
      progress.current.setValue(0);
    };
  }, [currentIndex, data], [setCurrentIndex, data]);

  useEffect(() => {
    visibilityAnimation.current = Animated.timing(visibilty.current, {
      toValue: visible ? 1 : 0,
      duration: visible ? promotionalBannerScrollDuration * 3 : promotionalBannerScrollDuration,
      useNativeDriver: true
    });
    visibilityAnimation.current?.start();

    return () => {
      visibilityAnimation.current?.stop();
    };
  }, [visible]);

  useLazyEffect(() => {
    if (mode !== 'fullWidth') {
      return;
    }
    expansionAnimation.current = Animated.parallel([
      Animated.timing(animatedHeight.current, {
        toValue: usingFocusedStyle ? componentHeightFocused : componentHeight,
        duration: animationDuration,
        easing: Easing.inOut(Easing.quad)
      }),
      Animated.timing(animatedOpacity.current, {
        toValue: usingFocusedStyle ? 1 : 0,
        duration: opacityDuration,
        delay: usingFocusedStyle ? opacityDelay : 0,
        easing: Easing.inOut(Easing.quad)
      }),
      Animated.timing(animatedDetailsMargin.current, {
        toValue: usingFocusedStyle ? 0 : detailsMargin,
        duration: animationDuration,
        easing: Easing.inOut(Easing.quad)
      })
    ]);
    expansionAnimation.current?.start();
    return () => {
      expansionAnimation.current?.stop();
    };
  }, [usingFocusedStyle], [componentHeight, componentHeightFocused, mode]);

  const media = data.media[currentIndex % data.media.length];
  const currentPictureUri = media && mw.catalog.getPictureUrl(media, PictureType.PromotionalBanner, imageSize.width, imageSize.height, PictureMode.BOX);

  const {ratio} = useFixedHeightImage(componentHeight, currentPictureUri);

  const {source, onLoadEnd: onImageLoadEnd, opacity} = useImageFadeAnimation(currentPictureUri);

  const containerStyle = useMemo(() => [
    staticStyles.banner,
    {backgroundColor: gradientColor?.toString() ?? gradient.color},
    getModeValue<Animated.WithAnimatedValue<StyleProp<ViewStyle>>>({
      framed: staticStyles.frame,
      desktopFullScreen: {height: size.height},
      fullWidth: {height: animatedHeight.current}
    })
  ], [gradient, gradientColor, getModeValue, size]);

  const visibilityContainer = useMemo(() => ({opacity: visibilty.current}), []);

  const detailsContainerStyle = useMemo(() => [
    staticStyles.detailsContainer,
    detailsStyle,
    getModeValue<Animated.WithAnimatedValue<StyleProp<ViewStyle>>>({
      framed: staticStyles.framedDetailsContainer,
      desktopFullScreen: {paddingTop: 0},
      fullWidth: {paddingTop: animatedDetailsMargin.current}
    })
  ], [detailsStyle, getModeValue]);

  const animatedContainerStyle = useMemo(() => ({opacity: animatedOpacity.current}), []);
  const progressIndicatorStyle = useMemo(() => [staticStyles.progressIndicator, {
    marginBottom: progressIndicatorOffset ?? getModeValue<number>({
      framed: progressIndicatorOffsetFramed,
      desktopFullScreen: progressIndicatorOffsetFullWidth,
      fullWidth: progressIndicatorOffsetFullWidth
    })
  }], [getModeValue, progressIndicatorOffset]);
  const detailsMargins = useMemo(() => (
    getModeValue<PromotionalBannerDetailsMargins>({
      framed: {
        title: dimensions.margins.small,
        tags: dimensions.margins.xLarge,
        metadata: dimensions.margins.medium,
        synopsis: dimensions.margins.xxLarge
      },
      desktopFullScreen: {},
      fullWidth: {}
    })
  ), [getModeValue]);

  const imageContainerStyle = useMemo<Animated.WithAnimatedValue<StyleProp<ViewStyle>>>(() => ({
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: Animated.multiply(animatedHeight.current, ratio),
    maxWidth: fullHDWidth,
    height: animatedHeight.current
  }), [ratio]);

  const imageContainer = useMemo(() => getModeValue<Animated.WithAnimatedValue<StyleProp<ViewStyle>>>({
    framed: imageContainerStyle,
    desktopFullScreen: staticStyles.imageContainerDesktopFullScreen,
    fullWidth: imageContainerStyle
  }), [getModeValue, imageContainerStyle]);

  const imageStyle = useMemo(() => ({
    height: '100%',
    width: '100%',
    opacity
  }), [opacity]);

  const onFocusEnter = useCallback(() => {
    onFocus();
    propsOnFocus?.();
  }, [onFocus, propsOnFocus]);

  return (
    <FocusParent
      onFocusEnter={onFocusEnter}
      onFocusEscape={onBlur}
      style={style}
    >
      <Animated.View style={visibilityContainer}>
        <Animated.View style={containerStyle}>
          <Animated.View style={imageContainer}>
            <Animated.Image
              onLoadEnd={onImageLoadEnd}
              style={imageStyle}
              resizeMode={getModeValue<string>({
                framed: 'contain',
                fullWidth: 'contain',
                desktopFullScreen: 'cover'
              })}
              source={source}
            />
          </Animated.View>
          <AnimatedGradient
            colors={mode !== 'framed' && usingFocusedStyle ? horizontalGradientColorsFocused : horizontalGradientColors}
            animationDuration={usingFocusedStyle ? gradientAnimationExpandDuration : gradientAnimationShrinkDuration}
            locations={horizontalGradientLocations}
            start={horizontalGradientStart}
            end={horizontalGradientEnd}
            style={staticStyles.horizontalGradient}
          />
          {mode !== 'framed' && (
            <>
              <LinearGradient
                colors={verticalGradientColorsTop}
                start={verticalGradientStart}
                end={verticalGradientEnd}
                locations={verticalGradientLocations}
                style={staticStyles.verticalGradientTop}
              />
              <LinearGradient
                colors={verticalGradientColorsBottom}
                start={verticalGradientStart}
                end={verticalGradientEnd}
                locations={verticalGradientLocations}
                style={staticStyles.verticalGradientBottom}
              />
            </>
          )}
          {data.media.length >= 2 && (
            <ProgressIndicator
              style={progressIndicatorStyle}
              segmentStyle={staticStyles.progressSegmentStyle}
              currentPage={currentIndex}
              numberOfPages={data.media.length}
              progress={progress.current}
            />
          )}
          <PromotionalBannerDetails
            media={media}
            style={detailsContainerStyle}
            animatedContainerStyle={animatedContainerStyle}
            titleNumberOfLines={getModeValue<number>({
              framed: 1,
              fullWidth: 2,
              desktopFullScreen: 2
            })}
            synopsisNumberOfLines={getModeValue<number>({
              framed: 2,
              fullWidth: 3,
              desktopFullScreen: 3
            })}
            margins={detailsMargins}
            staticallyFocused={staticallyFocused}
            hasTvPreferredFocus={hasTvPreferredFocus}
          />
        </Animated.View>
      </Animated.View>
    </FocusParent>
  );
};

export default PromotionalBannerGrosso;
