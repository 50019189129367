import React from 'react';

import {isMobile} from 'common/constants';

import {OptionPickerGrosso} from './OptionPicker.grosso';
import {OptionPickerPiccolo} from './OptionPicker.piccolo';
import {OptionPickerProps} from './OptionPicker.shared';

const OptionPicker: React.FC<OptionPickerProps> = props => {
  return isMobile ? <OptionPickerPiccolo {...props} /> : <OptionPickerGrosso {...props} />;
};

export default OptionPicker;
