import {createStyles} from 'common-styles';
import React, {useState, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {NavigationScreenProps} from 'react-navigation';

import {dimensions, isPhone, isBigScreen} from 'common/constants';

import {Error, ErrorType} from 'mw/api/Error';

import {IconType} from 'components/Icon';
import {useCurrentProfile, useNavigationParam, useNavigation} from 'hooks/Hooks';
import {validateProfileName} from 'screens/settings/profileValidation';
import WizardNamePage from 'screens/settings/profileWizard/WizardNamePage';
import {SettingsDetailsHeader} from 'screens/settings/SettingsDetailsHeader';

import SettingsProfileEdit, {ProfileEditSubmitResponse} from './SettingsProfileEdit';

const styles = createStyles({
  pageContainer: {
    justifyContent: 'flex-start'
  },
  header: {
    marginBottom: dimensions.margins.xxxxLarge
  }
});

type Props = {
  pin: string;
};

type PropsOrParams<T> = NavigationScreenProps<T> | T;

function isInline(props: PropsOrParams<Props>): props is Props {
  return typeof (props as Props).pin !== 'undefined';
}

const SettingsNameScreen: React.FC<PropsOrParams<Props>> = props => {
  const profile = useCurrentProfile();

  const propsPin = isInline(props) ? props.pin : '';
  const paramPin = useNavigationParam<Partial<Props>>('pin') || '';
  const pin = propsPin || paramPin;

  const [name, setName] = useState<string>(profile ? profile.name : '');
  const [buttonsVisible, setButtonsVisible] = useState(false);

  const {t} = useTranslation();
  const navigation = useNavigation();

  const onSubmit = useCallback(async (): Promise<ProfileEditSubmitResponse> => {
    try {
      const newName = name.trim();
      if (!profile) {
        return {ok: false, error: new Error(ErrorType.ProfileNotFound, t('settings.profileNotFound'))};
      }
      if (newName === profile.name) {
        return {ok: true};
      }

      const nameError = validateProfileName(newName);
      if (nameError) {
        return {ok: false, error: nameError};
      }

      await profile.setName(newName, pin);

      return {ok: true};
    } catch (error) {
      return {ok: false, error};
    }
  }, [name, pin, profile, t]);

  const onSubmitFromKeyboard = useCallback(() => {
    onSubmit()
      .then(({ok}) => {
        if (ok && isPhone) {
          navigation?.pop();
        }
      });
  }, [onSubmit, navigation]);

  return (
    <SettingsProfileEdit
      title={t('settings.profileName')}
      header={(
        <SettingsDetailsHeader
          icon={IconType.ProfileBig}
          style={styles.header}
        />
      )}
      onSubmit={onSubmit}
      buttonsVisible={buttonsVisible}
    >
      <WizardNamePage
        onNameChanged={setName}
        name={name}
        showTitle={false}
        containerStyle={styles.pageContainer}
        embedded
        onSubmitEditting={onSubmitFromKeyboard}
        onInputFocusChange={setButtonsVisible}
        deleteProfileVisible={!profile?.isMain && isBigScreen}
        pin={pin}
      />
    </SettingsProfileEdit>
  );
};

export default SettingsNameScreen;
