import {createStyles} from 'common-styles';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {dimensions} from 'common/constants';

import DoubleBackground from 'components/DoubleBackground';
import {ListItemProps} from 'components/ListView';
import ListView from 'components/ListView';
import {useNavigation} from 'hooks/Hooks';
import NitroxScreen from 'screens/NitroxScreen';

const styles = createStyles({
  phoneListView: {
    flexGrow: 1,
    flexShrink: 1,
    paddingHorizontal: dimensions.margins.xxxLarge,
    paddingTop: dimensions.margins.medium
  }
});

const SettingsAccountScreen: React.FC = () => {
  const {t} = useTranslation();
  const navigation = useNavigation();

  const list: ListItemProps[] = navigation.getParam('accountSettingsItems');

  return (
    <NitroxScreen
      navigation={navigation}
      mobileHeaderProps={{
        title: t('settings.myAccount')
      }}
    >
      <DoubleBackground
        overlap={dimensions.margins.medium}
        bottomChild={(
          <ListView items={list} containerStyle={styles.phoneListView} />
        )}
      />
    </NitroxScreen>
  );
};

export default SettingsAccountScreen;

