import {ContentType, Playable, Content, isEvent, Media, PlaybackLimitations} from 'mw/api/Metadata';
import {PlaybackSession} from 'mw/playback/sessions/PlaybackSessionManager';
import {PlaybackResponse} from 'mw/playback/types/PlaybackParameters';
import {PlayerType} from 'mw/playback/types/PlayerType';

import {BookmarkedPlayer} from './BookmarkedPlayer';
import {StartPlaybackParameters} from './PlaybackController';
import {addRegion} from './utils';

export class AssetBasedTstvPlayer extends BookmarkedPlayer {

  public constructor(playerType: PlayerType) {
    super(playerType, ContentType.TSTV);
  }

  public startPlayback(params: StartPlaybackParameters): Promise<PlaybackResponse> {
    if (params.playbackParameters.position == null) {
      const content = params.playable as Content;
      params.playbackParameters.position = content.recommendedPlaybackOffset;
    }

    return super.startPlayback(params);
  }

  protected createPlaybackSession(playable: Playable, media: Media): Promise<PlaybackSession> {
    return super.createPlaybackSession(playable, media)
      .then(addRegion);
  }

  public getPlaybackLimitations(): PlaybackLimitations {
    const event = this.currentMedia;
    if (isEvent(event)) {
      const playbackLimitations = super.getPlaybackLimitations();

      return {
        ...playbackLimitations,
        allowRestart: true,
        allowGoToLive: true
      };
    }

    return super.getPlaybackLimitations();
  }
}
