import {createStyles} from 'common-styles';
import React, {useState, useCallback, useMemo} from 'react';
import {View} from 'react-native';
import {withNavigation} from 'react-navigation';

import {dimensions, getValue} from 'common/constants';
import {combineDateAndTime} from 'common/HelperFunctions';
import {NavigationFocusState} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors, constColors} from 'common-styles/variables/base-colors';

import {Channel, Event} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import EpgPiccolo from 'components/epg/animated/Epg.piccolo';
import ChannelsListsBarItem from 'components/epg/ChannelsListsBarItem';
import DateBarItem from 'components/epg/DateBarItem';
import EpgMenuPopup from 'components/epg/EpgMenuPopup';
import {usePlayerLauncher} from 'components/playerLauncher/PlayerLauncher';
import {useLazyEffect} from 'hooks/Hooks';
import {ViewMode} from 'screens/tv/TvScreen';

import {getEpgBoundaries} from './EpgHelperFunctions';
import {EpgScreenProps} from './EpgHelperTypes';

const TAG = 'EpgScreenPiccolo';

const barContainerPadding = getValue({mobile: dimensions.margins.large, defaultValue: 75});
const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    backgroundColor: colors.epgScreen.background
  },
  barContainer: {
    height: 2 * dimensions.margins.large + 30,
    paddingHorizontal: barContainerPadding,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: colors.epgScreen.columnEpg.background
  },
  channelsListsBarItem: {
    position: 'absolute',
    right: barContainerPadding
  },
  mobileContainer: {
    flex: 1
  },
  gestureRecognizer: {
    flex: 1,
    backgroundColor: constColors.transparent
  }
}));

type CurrentChannel = {
  channel: Channel;
  authorized: boolean;
}

const EpgScreenPiccolo: React.FC<EpgScreenProps> = props => {
  const {
    epgRef,
    channels,
    focusState,
    focusedEvent,
    epgGridVisibleTime,
    menuPopupVisible,
    menuPopupLoading,
    menuActions,
    onEventPress: onEventPressFromProps,
    onChannelPress,
    onEpgGridVisibleTime,
    openMenuPopup,
    closeMenuPopup
  } = props;

  const {startPlayback} = usePlayerLauncher();

  const onMoreActionsPress = useCallback((event: Event) => {
    if (!event) {
      return;
    }
    if (event.isNow) {
      return startPlayback({
        tvScreenParams: {
          channelId: event.channelId,
          viewMode: ViewMode.OneChannelEpg
        }
      });
    }
    onEventPressFromProps(event);
    openMenuPopup(event, true);
  }, [onEventPressFromProps, openMenuPopup, startPlayback]);

  const scrollToDate = useCallback((date: Date) => {
    // scroll to the given date but keep time the same
    epgRef.current?.scrollToDate(combineDateAndTime(date, epgGridVisibleTime ?? new Date()));
  }, [epgRef, epgGridVisibleTime]);

  const scrollToNow = useCallback(() => {
    epgRef.current?.scrollToNow();
  }, [epgRef]);

  const [lastPlayedChannel, setLastPlayedChannel] = useState<Channel>();

  useLazyEffect(() => {
    if (focusState === NavigationFocusState.IsFocused) {
      mw.catalog.getLastPlayedChannel()
        .then(channel => setLastPlayedChannel(channel))
        .catch(error => {
          Log.error(TAG, 'Error while getting last played channel:', error);
          setLastPlayedChannel(channels[0]);
        });
    }
  }, [focusState], [channels]);

  const styles = stylesUpdater.getStyles();

  const [minDate, maxDate] = useMemo(getEpgBoundaries, []);
  Log.debug(TAG, 'render');
  return channels.length <= 0 ? null : (
    <View style={styles.container} testID='screen_epg'>
      <View style={styles.barContainer}>
        {epgGridVisibleTime && <DateBarItem date={epgGridVisibleTime} onJumpToDatePress={scrollToDate} minDate={minDate} maxDate={maxDate}></DateBarItem>}
        <ChannelsListsBarItem activatable showJumpToNowButton style={styles.channelsListsBarItem} onJumpToNowPress={scrollToNow} />
      </View>
      {lastPlayedChannel && (
        <EpgPiccolo
          ref={epgRef}
          channels={channels}
          currentChannel={lastPlayedChannel}
          onEventPress={onMoreActionsPress}
          onChannelPress={onChannelPress}
          onEpgGridVisibleTime={onEpgGridVisibleTime}
        />
      )}
      {focusedEvent && <EpgMenuPopup visible={menuPopupVisible} actions={menuActions} loading={menuPopupLoading} event={focusedEvent} onClose={closeMenuPopup} />}
    </View>
  );
};

export default withNavigation(React.memo(EpgScreenPiccolo));
