import {createStyles} from 'common-styles';
import React, {useCallback, useMemo, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import {NavigationContext} from 'react-navigation';

import {dimensions} from 'common/constants';
import {openMediaDetails} from 'common/HelperFunctions';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {ChannelIcon, ChannelIconType, channelIconConstants} from 'components/ChannelIcon';
import {EventSelectorContentProps, EventSelectorItem} from 'components/EventSelectorContent.shared';
import {ModalSelect, ModalSelectOption} from 'components/ModalSelect';
import {minTagWidth} from 'components/NitroxTag';
import NitroxText from 'components/NitroxText';
import TileIconsRow from 'components/TileIconsRow';

const styles = createStyles({
  optionStyle: {
    height: channelIconConstants.eventSelector.size,
    marginVertical: dimensions.margins.xsmall
  },
  optionIconsContainer: {
    flexDirection: 'row',
    height: '100%'
  },
  optionIconsRow: {
    marginLeft: dimensions.margins.small,
    minWidth: minTagWidth
  },
  optionButton: {
    paddingRight: 0,
    marginRight: dimensions.margins.small,
    height: '100%'
  }
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  infoTextStyle: {
    alignSelf: 'flex-start',
    color: colors.tile.description
  }
}));

const EventSelectorContentPiccolo: React.FC<EventSelectorContentProps> = ({items, onClose}) => {
  const {t} = useTranslation();
  const navigation = useContext(NavigationContext);
  const dynamicStyles = stylesUpdater.getStyles();

  const options: ModalSelectOption<EventSelectorItem>[] = useMemo(() => items.map(item => ({
    value: item,
    label: item.hours,
    key: item.event.id,
    style: styles.optionStyle,
    buttonStyle: styles.optionButton,
    buttonTextType: 'event-selector-title'
  })), [items]);

  const onChange = useCallback((item: EventSelectorItem) => {
    openMediaDetails(navigation, item.event.id, item.event.getType());
    onClose();
  }, [navigation, onClose]);

  const renderOptionIcon = useCallback((item: EventSelectorItem) => {
    return (
      <View style={styles.optionIconsContainer}>
        <ChannelIcon
          type={ChannelIconType.EventSelector}
          channelId={item.event.channelId}
        />
        <TileIconsRow mediaIcons={item.mediaIcons} style={styles.optionIconsRow} />
      </View>
    );
  }, []);

  const renderButtonChild = useCallback((item: EventSelectorItem) => {
    return <NitroxText style={dynamicStyles.infoTextStyle} textType={'event-selector-subtitle'}>{item.date}</NitroxText>;
  }, [dynamicStyles]);

  return (
    <ModalSelect
      options={options}
      visible
      fullscreen={false}
      swipeable
      onChange={onChange}
      onClose={onClose}
      renderOptionIcon={renderOptionIcon}
      renderButtonChild={renderButtonChild}
      title={t('common.selectEvent')}
    />
  );
};

export default EventSelectorContentPiccolo;
