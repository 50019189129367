import {createStyles} from 'common-styles';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions} from 'common/constants';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import ConfirmablePinKeyboard from 'components/ConfirmablePinKeyboard';
import NitroxText from 'components/NitroxText';
import Popup, {PopupAction} from 'components/Popup';
import {useChangeEffect} from 'hooks/Hooks';

import ErrorPopup from './ErrorPopup';
import FocusParent, {useFocusParentUtility} from './FocusParent';

const TAG = 'CreatePinPopup';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  messageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: dimensions.margins.xxLarge
  },
  message: {
    color: colors.popup.text
  }
}));

enum State {
  None,
  QuestionPopup,
  PinKeyboardPopup,
  ErrorPopup
}

type Props = {
  visible: boolean;
  onCreatePin: (pin: string) => Promise<void>;
  onCancel: () => void;
}

const popupActions: PopupAction[] = [PopupAction.POSITIVE];

const CreatePinPopup: React.FC<Props> = props => {
  const {t} = useTranslation();
  const {visible, onCancel, onCreatePin} = props;
  const [state, setState] = useState<State>(visible ? State.QuestionPopup : State.None);
  const styles = stylesUpdater.getStyles();
  const {focus: focusNearestParent} = useFocusParentUtility();

  const onSubmitPin = useCallback(async (pin: string) => {
    try {
      await onCreatePin(pin);
    } catch (e) {
      Log.error(TAG, 'Unable to create profile pin', e);
      setState(State.ErrorPopup);
    }
  }, [onCreatePin]);

  useChangeEffect(() => {
    if (visible) {
      setState(State.QuestionPopup);
    } else {
      setState(State.None);
      focusNearestParent?.();
    }
  }, [visible]);

  const onCreatePinActionPressed = useCallback(() => {
    setState(State.PinKeyboardPopup);
  }, []);

  return (
    <FocusParent>
      <Popup
        onModalClose={onCancel}
        visible={state === State.QuestionPopup}
        actions={popupActions}
        onPositive={onCreatePinActionPressed}
        positiveLabel={t('common.ok')}
        title={t('settings.pinNotSet')}
      >
        <View style={styles.messageContainer}>
          <NitroxText style={styles.message} textType='dialog-message'>{t('settings.setPin')}</NitroxText>
        </View>
      </Popup>
      <ConfirmablePinKeyboard visible={state === State.PinKeyboardPopup} onSubmit={onSubmitPin} onCancel={onCancel} />
      <ErrorPopup error={state === State.ErrorPopup ? {message: t('common.unexpectedError'), title: t('settings.errors.error')} : null} onClose={onCancel} />
    </FocusParent>
  );
};

export default React.memo(CreatePinPopup);
