import {Errors} from 'mw/api/Error';

import * as SmartLib from '@broadpeak/smartlib';

import {CDNSessionManager, CDNSession} from './CDNSessionManager';

export class BPKSessionManager implements CDNSessionManager {
  private static libInitialized = false;
  private sessionStarted = false;

  public async createSession(url: string, params: any): Promise<CDNSession> {
    if (!BPKSessionManager.libInitialized) {
      BPKSessionManager.libInitialized = true;
      if (!window.navigator.appVersion) {
        /* Workaround for smartlib bug */
        Object.defineProperty(window.navigator, 'appVersion', {value: 'NA', writable: false});
      }
      SmartLib.init('', '', '*');
    }
    const redirectedUrl = await this.getBPKUrl(url);
    if (!redirectedUrl) {
      throw Errors.BpkNoUrl;
    }
    return new CDNSession('', redirectedUrl, this);
  }

  public async destroySession(session: CDNSession): Promise<void> {
    SmartLib.stopStreamingSession();
    this.sessionStarted = false;
  }

  public onFirstFrame() {
    if (!this.sessionStarted) {
      this.sessionStarted = true;
      SmartLib.PlayerEventListener.onSessionStart();
    }
  }

  private getBPKUrl(url: string): Promise<string> {
    return new Promise(resolve => {
      SmartLib.getURL(url, (redirectedUrl: string) => {
        resolve(redirectedUrl);
      });
    });
  }
}
