import {PaymentMethod, PaymentMethodId} from 'mw/api/Metadata';

interface PaymentMethodJSON {
  id: string;
  displayName: string;
}

export class PaymentMethodMapper {
  public static paymentMethodsFromJson(paymentMethods: any[]): PaymentMethod[] {
    return paymentMethods.map((paymentMethod: PaymentMethodJSON): PaymentMethod => {
      return {
        id: paymentMethod.id as PaymentMethodId,
        displayName: `payments.method.${paymentMethod.id}`
      };
    });
  }
}
