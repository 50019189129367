import {DateUtils} from 'common/DateUtils';
import {makeArray} from 'common/utils';

import {Episode, Event, Metadata, TitleType} from 'mw/api/Metadata';
import {convertJSONDateToJSDate} from 'mw/common/utils';
import {EventObject} from 'mw/metadata/EventObject';
import {TitleObject} from 'mw/metadata/TitleObject';
import {mw} from 'mw/MW';

import {PictureMapper} from './PictureMapper';

export class EventMapper {
  public static convertAiringToEvent(channelId: string, airing: any): Event {
    const metadata = new Metadata();
    const metadataSource = airing.program || airing || {};
    metadata.shortSynopsis = metadataSource.short_description;
    metadata.longSynopsis = metadataSource.long_description;
    metadata.productionYear = metadataSource.release_year;
    const isEpochTimeFormat = !isNaN(airing.start_time);
    const airingStartTime: Date = isEpochTimeFormat ? new Date(airing.start_time * DateUtils.msInSec) : convertJSONDateToJSDate(airing.start_time);
    const airingEndTime: Date = isEpochTimeFormat ? new Date(airing.end_time * DateUtils.msInSec) : convertJSONDateToJSDate(airing.end_time);
    metadata.duration = (airingEndTime.getTime() - airingStartTime.getTime()) / DateUtils.msInSec;
    // TODO: CL-4422 - convert the rest of available fields here
    let episode: Episode | undefined;
    if (metadataSource.entity_type === 'Episode') {
      episode = {
        seriesId: '',
        seasonId: '',
        title: metadataSource.episode_title,
        seriesName: metadataSource.title
      };
    }
    const title = new TitleObject(metadataSource.id, metadataSource.title, TitleType.EPG, metadata, episode);
    const eventId = channelId + '_' + metadataSource.id;
    const event = mw.catalog.getEpgCacheEventById(eventId, channelId) ||
      new EventObject(eventId, metadataSource.title, title, airingStartTime, airingEndTime, channelId);
    // event objects are shared within the entire app therefore be sure not to override existing props with invalid values
    if (metadataSource.preferred_image) {
      event.pictures = makeArray(metadataSource.preferred_image).map(PictureMapper.pictureFromJson);
    }
    title.events.push(event);
    return event;
  }
}
