import {createStyles} from 'common-styles';
import React, {useEffect, useRef, useState, useMemo, useCallback, useImperativeHandle} from 'react';
import {View, Animated, InteractionManager, ActivityIndicator, StyleSheet} from 'react-native';

import {Direction, epgItemHeight, isTVOS} from 'common/constants';
import {DateUtils} from 'common/DateUtils';
import {flatten, unique} from 'common/HelperFunctions';
import {Size} from 'common/HelperTypes';
import {useDelayedFocusTVOS} from 'common/hooks/useDelayedFocusTVOS';
import {Log} from 'common/Log';
import {withinBoundaries} from 'common/utils';

import {CatalogEvent} from 'mw/api/CatalogInterface';
import {Channel, Event} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import {ScrollDirection} from 'components/epg/EpgNitroxScrollView';
import FocusPrison from 'components/focusManager/FocusPrison';
import {NativeKeyEvent, SupportedKeys} from 'components/KeyEventManager';
import MouseAwareView from 'components/MouseAwareView';
import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';
import {useKeyEventHandler, useFunction, useLazyEffect, useThrottle, useSynchronizedState, useDisposableCallback, useChangeEffect, useEventListener, useNearestLiveEvent} from 'hooks/Hooks';
import {useTVOSPanGesture} from 'hooks/rcuHooks';
import {getEpgBoundaries} from 'screens/epg/EpgHelperFunctions';

import AnimatedScrollView from './AnimatedScrollView';
import ChannelListView, {channelsContainerWidth} from './ChannelListView.grosso';
import {Chunk} from './Chunk';
import {initialXOffset, pointsPerMillisecond, gridWidth} from './EpgConstants';
import {offsetX, offsetY, initialScrollOffset, channelIndex, scrollOffsetY, findEvent, findPreviousEventForRow, findNextEventForRow, clampedStartDate} from './EpgHelpers';
import {useNowDate, useCurrentEvent, useGridDimensions, useFetchChunks} from './EpgHooks';
import NowLine from './NowLine';
import EpgTile, {TileIcons as EpgTileIcons} from './Tile';
import {timebarItemDuration} from './TimeBarHooks';
import TimeBarView, {timebarHeight} from './TimeBarView.grosso';

export {channelNameMarginLeft} from './ChannelListView.grosso';

const TAG = 'Epg.grosso';

const staticStyles = createStyles({
  container: {
    flex: 1
  },
  timebarContainer: {
    position: 'absolute',
    top: 0,
    left: channelsContainerWidth,
    right: 0,
    height: timebarHeight
  },
  channelsListContainer: {
    position: 'absolute',
    top: timebarHeight,
    left: 0,
    bottom: 0,
    width: channelsContainerWidth
  },
  gridContainer: {
    position: 'absolute',
    top: timebarHeight,
    left: channelsContainerWidth,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center'
  }
});

function EpgTileCellComponent({
  event,
  forcedStartDate,
  channels,
  originDate,
  focused = false,
  selected = false,
  onClick: propsOnClick
}: {
  event: Event;
  forcedStartDate?: Date;
  channels: Channel[];
  originDate: Date;
  focused?: boolean;
  selected?: boolean;
  onClick?: (event: Event) => void;
}) {
  const left = offsetX(forcedStartDate ?? event.start, originDate);
  const width = offsetX(event.end, originDate) - left;
  const [hovered, setHovered] = useState(false);
  const onClick = useFunction(() => {
    propsOnClick?.(event);
  });
  return (
    <MouseAwareView
      key={event.id}
      style={{
        position: 'absolute',
        top: offsetY(event.channelId, channels),
        left,
        width,
        height: epgItemHeight
      }}
      onHoverChange={setHovered}
      onClick={onClick}
    >
      <EpgTile
        width={width}
        event={event}
        focused={focused || hovered}
        selected={selected}
      />
    </MouseAwareView>
  );
}
const EpgTileCell = React.memo(EpgTileCellComponent);

function renderEpgTileIcons(event: Event, channels: Channel[], originDate: Date) {
  const start = offsetX(event.start, originDate);
  const end = offsetX(event.end, originDate);
  return (
    <EpgTileIcons
      key={event.id}
      event={event}
      tilePosition={{
        x: start,
        y: offsetY(event.channelId, channels)
      }}
      tileSize={{
        width: end - start,
        height: epgItemHeight
      }}
    />
  );
}

type EpgProps = {
  channels: Channel[];
  currentChannel?: Channel;
  navigationDisabled: boolean;
  // TODO: change this prop name
  onEpgGridVisibleTime?: (date: Date) => void;
  onEventFocus?: (event: Event) => void;
  onEventPress?: (event: Event) => void;
  onRecordButtonPressed?: () => void;
  onInfoButtonPressed?: () => void;
};

const Epg: React.FC<EpgProps> = React.memo(React.forwardRef(({
  channels,
  currentChannel,
  navigationDisabled: navigationDisabledProp,
  onEpgGridVisibleTime,
  onEventFocus,
  onEventPress,
  onRecordButtonPressed,
  onInfoButtonPressed
}, ref) => {
  const originDate = useRef(DateUtils.startOfHalfHour(new Date()));
  const {unlockedMedia} = useParentalControl();

  const nowDate = useNowDate();
  const selectedEvent = useCurrentEvent(currentChannel, nowDate);

  const gridScrollOffset = useRef(new Animated.ValueXY(initialScrollOffset(channels, currentChannel)));

  const [{state: focusedDate, getStateSync: getFocusedDateSync}, setFocusedDate] = useSynchronizedState(new Date());
  /**
   * Actual date for current grid x offset. It's focusedDate rounded down to full half hours.
   */
  const gridOffsetDate = useMemo(() => DateUtils.startOfHalfHour(focusedDate), [focusedDate]);

  const [
    {state: focusedChannel, getStateSync: getFocusedChannelSync},
    setFocusedChannel
  ] = useSynchronizedState<Channel | undefined>(currentChannel ?? channels[0]);

  const [chunks, setChunks] = useState<{[id: string]: Chunk}>({});
  const navigationDirection = useRef<NativeKeyEvent['key']>();

  const [focusPrisonActive, setFocusPrisonActive] = useState(false);

  const {windowSize, scrollingPageSize, onGridLayout} = useGridDimensions();

  const {fetchChunks, fetching} = useFetchChunks({channels, focusedChannel, gridOffsetDate});

  const navigationDisabled = useMemo<boolean>(() => !focusPrisonActive || navigationDisabledProp, [focusPrisonActive, navigationDisabledProp]);

  const longPressTimerId = useRef(0);

  const clearTimer = useFunction(() => {
    if (longPressTimerId.current) {
      clearTimeout(longPressTimerId.current);
      longPressTimerId.current = 0;
    }
  });

  /**
   * Trigger fetching missing chunks on every move
   * that changes either row or focusedDate.
   */
  useLazyEffect(() => {
    onEpgGridVisibleTime?.(gridOffsetDate);
    if (windowSize.width > 0 && !longPressTimerId.current) {
      fetchChunks({navigationDirection: navigationDirection.current, originDate: originDate.current, windowSize: windowSize, onChunkLoad: setChunks});
    }
  }, [gridOffsetDate, focusedChannel, windowSize], [channels, onEpgGridVisibleTime, navigationDirection.current, originDate.current, fetchChunks]);

  /**
   * Clear out epg on changing channels' array.
   */
  useChangeEffect(() => {
    setChunks({});
    setFocusedDate(new Date());
  }, [channels]);

  const focusedEvent = useMemo(() => {
    return findEvent(
      Object.values(chunks),
      focusedDate,
      channelIndex(focusedChannel?.id, channels),
      channels
    );
  }, [chunks, channels, focusedDate, focusedChannel]);

  /**
   * This lets parent trigger epg menu.
   */
  const onPressOK = useFunction(() => {
    focusedEvent && onEventPress?.(focusedEvent);
  });

  /**
   * This triggers parent to update mini detail above grid.
   */
  useLazyEffect(() => {
    focusedEvent && onEventFocus?.(focusedEvent);
  }, [focusedEvent], [onEventFocus]);

  const scrollToDate = useFunction((date: Date) => {
    const [epgBeginTime, epgEndTime] = getEpgBoundaries();

    const clampedDate = new Date(withinBoundaries(epgBeginTime.getTime(), epgEndTime.getTime(), date.getTime()));
    const targetDate = DateUtils.startOfHalfHour(clampedDate);

    gridScrollOffset.current.x.setValue(offsetX(targetDate, originDate.current));
    setFocusedDate(clampedDate);
  });

  const scrollToChannel = useFunction((channel: Channel) => {
    if (!channels.length) {
      return;
    }
    const offset = scrollOffsetY(channel.id, channels, windowSize);
    gridScrollOffset.current.y.setValue(offset);
    setFocusedChannel(channel);
  });

  useChangeEffect(() => {
    currentChannel && scrollToChannel(currentChannel);
  }, [currentChannel, channels]);

  const [exitEdges, setExitEdges] = useState<Direction[]>([]);

  useEffect(() => {
    if (focusedChannel && channelIndex(focusedChannel.id, channels) === 0) {
      setExitEdges([Direction.Up]);
    } else {
      setExitEdges([]);
    }
  }, [focusedChannel, channels]);

  const scrollToNow = useFunction(() => {
    scrollToDate(new Date());
  });

  const scrollByPage = useFunction((axis: ScrollDirection, offset: number) => {
    if (axis === ScrollDirection.Horizontal) {
      scrollToDate(new Date(gridOffsetDate.getTime() + offset * scrollingPageSize.current.hours * DateUtils.msInHour));
    } else {
      const row = channelIndex(focusedChannel?.id, channels) + offset * scrollingPageSize.current.rows;
      const channel = channels[Math.max(0, Math.min(row, channels.length - 1))];
      channel && scrollToChannel(channel);
    }
  });

  const scrollToEvent = useFunction((event: Event) => {
    const channel = mw.catalog.getChannelById(event.channelId);
    if (channel) {
      scrollToDate(event.start);
      scrollToChannel(channel);
    }
  });

  useImperativeHandle(ref, () => ({
    scrollToDate,
    scrollToNow,
    scrollByPage,
    scrollToEvent
  }), [scrollToDate, scrollToNow, scrollByPage, scrollToEvent]);

  const {obtainedFocus} = useDelayedFocusTVOS(focusPrisonActive);

  const onPressEventHandler = useFunction(pressEvent => {
    if (pressEvent?.type === 'mouseup') {
      return;
    }

    onPressOK();
  });

  const handleKeyPress = useDisposableCallback(({key}: NativeKeyEvent, fromLongPress = false) => {
    Log.debug(TAG, 'handleKeyPress', key);
    if (!fromLongPress && longPressTimerId.current) {
      Log.info(TAG, 'onKeyUp, clearing long press interval', key);
      clearTimer();
      fetchChunks({navigationDirection: navigationDirection.current, originDate: originDate.current, windowSize: windowSize, onChunkLoad: setChunks});
    }
    if (navigationDisabled) {
      return;
    }
    if (isTVOS && !obtainedFocus) {
      Log.info(TAG, `handleKeyPress: key event ${key} ignored, focus not obtained yet.`);
      return;
    }
    const handle = InteractionManager.createInteractionHandle();
    switch (key) {
      case SupportedKeys.Ok:
        if (isTVOS) {
          // tvOS FocusPrison doesn't send onPress event
          onPressOK();
        }
        break;
      case SupportedKeys.Left:
        if (focusedEvent && focusedEvent.start < DateUtils.startOfHalfHour(getFocusedDateSync())) {
          scrollToDate(focusedEvent.start);
          break;
        }
      case SupportedKeys.Right:
        const findNextFocusedEvent = key === SupportedKeys.Left ? findPreviousEventForRow : findNextEventForRow;
        const timeDeltaSign = key === SupportedKeys.Left ? -1 : 1;
        const event = findNextFocusedEvent(
          channelIndex(getFocusedChannelSync()?.id, channels),
          getFocusedDateSync(),
          Object.values(chunks),
          channels
        );
        scrollToDate(event
          ? event.start
          : new Date(getFocusedDateSync().getTime() + timeDeltaSign * timebarItemDuration * DateUtils.msInMin)
        );
        break;
      case SupportedKeys.Up:
      case SupportedKeys.Down:
        const currentIndex = channelIndex(getFocusedChannelSync()?.id, channels);
        const delta = key === SupportedKeys.Up ? -1 : 1;
        const newIndex = Math.max(0, Math.min(currentIndex + delta, channels.length - 1));
        scrollToChannel(channels[newIndex]);
        break;
      case SupportedKeys.Record:
        onRecordButtonPressed?.();
        break;
      case SupportedKeys.Info:
        onInfoButtonPressed?.();
        break;
    }
    navigationDirection.current = key;
    InteractionManager.clearInteractionHandle(handle);
  }, [navigationDisabled, getFocusedChannelSync, channels, getFocusedDateSync, chunks, scrollToDate, scrollToChannel, onPressOK, onRecordButtonPressed, onInfoButtonPressed, windowSize, fetchChunks, setChunks]);

  const keyHandler = useThrottle(handleKeyPress, isTVOS ? 0 : 100);
  useKeyEventHandler('keyup', keyHandler);

  const onLongPress = useFunction((event: NativeKeyEvent) => {
    Log.info(TAG, 'Long press started', event.key);
    clearTimer();
    handleKeyPress(event, true);
    const createTimer = () => {
      longPressTimerId.current = setTimeout(() => {
        Log.debug(TAG, 'Long press timeout sending event', event.key);
        handleKeyPress(event, true);
        createTimer();
      }, 100);
    };
    createTimer();
  });
  useKeyEventHandler('longpress', onLongPress);

  useEffect(() => {
    return () => {
      longPressTimerId.current && clearTimeout(longPressTimerId.current);
      longPressTimerId.current = 0;
    };
  }, []);

  useKeyEventHandler('ios:pan', useTVOSPanGesture(keyHandler));

  /**
   * Helper functions needed by timebar for calculations.
   */
  const offsetForDate = useCallback((date: Date) => {
    return offsetX(date, originDate.current);
  }, []);
  const dateForOffset = useCallback((offset: number) => {
    return new Date(originDate.current.getTime() + (offset - initialXOffset()) / pointsPerMillisecond);
  }, []);

  /**
   * Chunks' content, all events that are available in cache.
   */
  const renderedEvents = useMemo(() => {
    const events = flatten(Object.values(chunks).map(({events}) => events));
    // We need to eliminate duplicates which happen because events can span multiple chunks.
    return unique(events, 'id');
  }, [chunks]);

  /**
   * Rendered live event that is going to finish first, required to rerender the icons that change over time.
   */
  const nearestLiveEvent = useNearestLiveEvent(useCallback(() => renderedEvents, [renderedEvents]));

  const isOnLeftEdge = useCallback((event: Event): boolean => {
    return event.start < gridOffsetDate && event.end > gridOffsetDate;
  }, [gridOffsetDate]);

  /**
   * Grid content, tiles for almost* all events from cache.
   * * edge tiles that are going to be rendered in tilesOnLeftEdge are filtered out
   */
  const epgTiles = useMemo(() => {
    const events = renderedEvents.filter(event => !isOnLeftEdge(event));
    return events.map(event => <EpgTileCell event={event} key={event.id} channels={channels} originDate={originDate.current} onClick={onEventPress} />);
  }, [renderedEvents, channels, onEventPress, isOnLeftEdge]);

  /**
   * For events that are spanning current and previous chunk,
   * render a shorter version aligned to gridOffsetDate to make the title visible.
   */
  const tilesOnLeftEdge = useMemo(() => {
    const events = renderedEvents.filter(isOnLeftEdge);
    return events.map(event => <EpgTileCell event={event} forcedStartDate={gridOffsetDate} channels={channels} originDate={originDate.current} key={event.id} onClick={onEventPress} />);
  }, [renderedEvents, gridOffsetDate, channels, onEventPress, isOnLeftEdge]);

  /**
   * Highlighted tile for a currently playing event.
   */
  const selectedTile = useMemo(() => {
    if (!selectedEvent || selectedEvent.id === focusedEvent?.id) {
      return null;
    }
    return <EpgTileCell event={selectedEvent} forcedStartDate={clampedStartDate(selectedEvent, gridOffsetDate)} channels={channels} originDate={originDate.current} selected onClick={onEventPress} />;
  }, [selectedEvent, focusedEvent?.id, gridOffsetDate, channels, onEventPress]);

  /**
   * Highlighted tile for an event from focusedChannel at focusedDate.
   */
  const focusedTile = useMemo(() => {
    if (!focusedEvent || navigationDisabled) {
      return null;
    }

    return <EpgTileCell event={focusedEvent} forcedStartDate={clampedStartDate(focusedEvent, gridOffsetDate)} channels={channels} originDate={originDate.current} focused onClick={onEventPress} />;
  }, [focusedEvent, navigationDisabled, gridOffsetDate, channels, onEventPress]);

  /**
   * Render event icons for tiles separately,
   * so that refreshing recording status
   * doesn't trigger whole grid rerender.
   */
  const [tileIcons, setTileIcons] = useState<React.ReactNode>(null);
  const updateTileIcons = useFunction(() => {
    setTileIcons(
      renderedEvents.map(event => renderEpgTileIcons(event, channels, originDate.current))
    );
  });

  useLazyEffect(() => {
    updateTileIcons();
  }, [renderedEvents, nearestLiveEvent, unlockedMedia], [updateTileIcons]);

  useEventListener(CatalogEvent.EventsIsRecordedUpdated, updateTileIcons, mw.catalog);

  const gridSize: Size = useMemo(() => ({
    width: gridWidth(),
    height: channels.length * epgItemHeight
  }), [channels.length]);

  return (
    <FocusPrison
      style={staticStyles.container}
      focusOnAppear
      onPress={onPressEventHandler}
      focusPrisonStateChanged={setFocusPrisonActive}
      exitEdges={exitEdges}
    >
      <View style={staticStyles.timebarContainer}>
        <TimeBarView
          scrollOffset={gridScrollOffset.current.x}
          gridWidth={gridSize.width}
          timeBarWidth={windowSize.width}
          originDate={originDate.current}
          offsetForDate={offsetForDate}
          dateForOffset={dateForOffset}
          nowDate={nowDate}
        />
      </View>
      <View style={staticStyles.channelsListContainer}>
        <ChannelListView
          channels={channels}
          currentChannel={currentChannel}
          scrollOffset={gridScrollOffset.current.y}
        />
      </View>
      <View
        style={staticStyles.gridContainer}
        onLayout={onGridLayout}
        testID='epg_grid'
      >
        <AnimatedScrollView
          offsetX={gridScrollOffset.current.x}
          offsetY={gridScrollOffset.current.y}
          size={gridSize}
          style={StyleSheet.absoluteFill}
        >
          {epgTiles}
          {tilesOnLeftEdge}
          {selectedTile}
          {focusedTile}
          {tileIcons}
          <NowLine
            offsetForDate={offsetForDate}
            nowDate={nowDate}
          />
        </AnimatedScrollView>
        <ActivityIndicator animating={fetching} />
      </View>
    </FocusPrison>
  );
}));
Epg.displayName = 'Epg';

export default Epg;
