import {createStyles} from 'common-styles';
import React, {useRef, useCallback} from 'react';
import {View, StyleSheet} from 'react-native';

import {isBigScreen, dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {FocusManager} from 'components/focusManager/FocusManager';
import {FocusableComponent} from 'components/focusManager/FocusManagerTypes';
import ProgressTracker from 'components/ProgressTracker';
import {useNavigation} from 'hooks/Hooks';
import NitroxScreen from 'screens/NitroxScreen';

import ErrorPopup from './ErrorPopup';
import FocusParent from './FocusParent';
import ForwardButton from './navigation/ForwardButton';
import {STBMenuState} from './navigation/NavigationHelperTypes';
import NitroxText from './NitroxText';
import {WizardContentProps} from './Wizard';

const headerStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    height: dimensions.wizard.title.height,
    alignItems: 'center',
    marginTop: dimensions.margins.xxxLarge,
    marginBottom: dimensions.margins.xxxxLarge
  },
  text: {
    color: colors.settingsScreen.title
  }
}));

const Header: React.FC<{title?: string}> = ({title}) => {
  if (!isBigScreen) {
    return null;
  }
  const headerStyles = headerStylesUpdater.getStyles();
  return (
    <View style={headerStyles.container}>
      <NitroxText textType='headline' style={headerStyles.text}>{title || ''}</NitroxText>
    </View>
  );
};

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flex: 1,
    backgroundColor: colors.settingsScreen.background
  },
  progress: {
    marginBottom: dimensions.margins.xxLarge
  },
  safeArea: {
    backgroundColor: colors.settingsScreen.background,
    flex: 1
  },
  actions: {
    alignItems: 'center',
    marginTop: dimensions.margins.xxxxLarge
  }
}));

const Wizard: React.FC<WizardContentProps> = ({
  currentStep,
  title,
  error,
  onForwardPress,
  embedded,
  numberOfSteps,
  stepIndex,
  onBackPress,
  children,
  onErrorPopupClose
}) => {
  const forwardButtonRef = useRef<FocusableComponent>(null);
  const focusForwardButton = useCallback(() => {
    FocusManager.getInstance().forceFocus(forwardButtonRef);
  }, []);

  const handleBackPress = useCallback(() => {
    onBackPress();
    return true;
  }, [onBackPress]);

  const styles = stylesUpdater.getStyles();
  const header = <Header title={title} />;
  const tracker = (
    <ProgressTracker
      currentStep={stepIndex}
      numberOfSteps={numberOfSteps}
      containerStyle={styles.progress}
    />
  );
  const forwardButton = (
    <ForwardButton
      onPress={onForwardPress}
      text={currentStep && currentStep.forwardButtonText}
      ref={forwardButtonRef}
    />
  );

  return (
    <NitroxScreen
      navigation={useNavigation()}
      embedded={embedded}
      style={styles.safeArea}
      menuState={STBMenuState.Hidden}
      onBackButtonPressed={handleBackPress}
    >
      <FocusParent trapFocus style={StyleSheet.absoluteFill}>
        <View style={styles.container}>
          {!currentStep?.embedElements && header}
          {!currentStep?.embedElements && currentStep?.showStepper && tracker}
          {currentStep?.renderContent?.(...currentStep?.embedElements ? [{forwardButton, focusForwardButton, header, tracker}] : [])}
          {isBigScreen && !currentStep?.embedElements && (
            <FocusParent style={styles.actions}>
              {forwardButton}
            </FocusParent>
          )}
        </View>
        <ErrorPopup error={error} onClose={onErrorPopupClose} />
      </FocusParent>
      {children}
    </NitroxScreen>
  );
};

export default Wizard;
