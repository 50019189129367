import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {LayoutChangeEvent, StyleSheet, View} from 'react-native';

import {isWeb, dimensions} from 'common/constants';

const styles = createStyles({
  containerWeb: {
    overflow: 'hidden'
  }
});

export enum Ratio {
  RATIO_16_11 = 16 / 11,
  RATIO_16_9 = 16 / 9,
  RATIO_4_3 = 4 / 3,
  RATIO_2_3 = 2 / 3,
  RATIO_1_1 = 1,

  LoginLogo = dimensions.logo.credentials.width / dimensions.logo.credentials.height
}

type Props = {
  children: JSX.Element;
  ratio: Ratio;
  onLayout?: (event: LayoutChangeEvent) => void;
}

export const AspectRatio: React.FunctionComponent<Props> = props => {
  const {children, ratio, onLayout} = props;

  const style = useMemo(() => isWeb ? {width: '100%', paddingTop: `${100 / ratio}%`} : {width: '100%', aspectRatio: ratio}, [ratio]);
  return (
    isWeb ? (
      <View onLayout={onLayout} style={styles.containerWeb}>
        {/* padding in percentages is based on width */}
        <View style={style} />
        <View style={StyleSheet.absoluteFillObject}>
          {children}
        </View>
      </View>
    ) : (
      <View onLayout={onLayout} style={style}>
        {children}
      </View>
    )
  );
};

export default React.memo(AspectRatio);
