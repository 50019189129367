import {Log} from 'common/Log';

import {PictureMode} from 'mw/api/Metadata';

const TAG = 'DynamicImageService';
/**
 * Dynamic image service is used to resize and crop images.
 * The service is based on Thumbor (https://thumbor.readthedocs.io/en/latest/index.html).
 * Thumbor API is described here: https://thumbor.readthedocs.io/en/latest/usage.html
 */

enum FitInApi {
  Default = 'fit-in'
}

export class DynamicImageService {
  private serverUrl: string;
  public constructor(url = '') {
    this.serverUrl = url;
  }

  public setServerUrl(url: string): void {
    this.serverUrl = url;
  }

  private mapPictureModeToFitInApi(mode: PictureMode) {
    switch (mode) {
      case PictureMode.BOX:
        return FitInApi.Default;
      case PictureMode.CROP:
        return undefined;
      default:
        Log.error(TAG, `Picture mode: ${mode} is not supported`);
        return undefined;
    }
  }

  private removeProtocolFromUrl(url: string) {
    return url.replace(/(^\w+:)\/\//, '');
  }

  public getPictureUrl(url: string, width: number, height: number, mode: PictureMode): string {
    if (!this.serverUrl) {
      Log.error(TAG, 'serverUrl is not defined');
      return url;
    }
    const fitIn = this.mapPictureModeToFitInApi(mode);

    return `${this.serverUrl}/unsafe/${fitIn ? `${fitIn}/` : ''}${width}x${height}/${this.removeProtocolFromUrl(url)}`;
  }
}
