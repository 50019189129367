import i18next from 'i18next';

import {Error, ErrorType} from 'mw/api/Error';
import {mw} from 'mw/MW';

const profileNameLimit = 30;

export function validateProfileName(name: string): Error | undefined {
  if (!name.length) {
    return new Error(ErrorType.ProfileNameEmpty, i18next.t('settings.errors.profileNameEmpty'));
  }
  if (name.length > profileNameLimit) {
    return new Error(ErrorType.ProfileNameTooLong, i18next.t('settings.errors.profileNameTooLong', {profileNameLimit}));
  }
  if (mw.customer.profiles.find(p => p.name === name)) {
    return new Error(ErrorType.ProfileNameAlreadyExists, i18next.t('settings.errors.profileNameAlreadyExists'));
  }
}

export function validateYearOfBirth(year: number | undefined, parentalControl: boolean | undefined): Error | undefined {
  if (typeof year === 'undefined') {
    if (parentalControl) {
      return new Error(ErrorType.ProfileYearOfBirthMandatory, i18next.t('settings.errors.profileYearOfBirthMandatory'));
    }
    return;
  }
  if (Number.isNaN(year)) {
    return new Error(ErrorType.ProfileYearOfBirthNotANumber, i18next.t('settings.errors.profileYearOfBirthNotANumber'));
  }
  if (year < 1900) {
    return new Error(ErrorType.ProfileBirthDateTooOld, i18next.t('settings.errors.profileBirthDateTooOld', {minimalYear: 1900}));
  }
  if (year > (new Date()).getFullYear()) {
    return new Error(ErrorType.ProfileBirthDateTooLate, i18next.t('settings.errors.profileBirthDateTooLate'));
  }
}
