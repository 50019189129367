import {isBigScreen} from 'common/constants';

import RecordingsFolderContentGrosso from './RecordingsFolderContent.grosso';
import RecordingsFolderContentPiccolo from './RecordingsFolderContent.piccolo';
import {RecordingsFolderContentProps} from './RecordingsFolderHelperTypes';

export const RecordingsFolderContent: React.ComponentType<RecordingsFolderContentProps>
  = isBigScreen
    ? RecordingsFolderContentGrosso
    : RecordingsFolderContentPiccolo;
