import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {View, StyleProp, ViewStyle} from 'react-native';

import {dimensions, featureFlags, isMobile, isBigScreen} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Event, isSeries, Media, Recording, RecordingStatus, RecordingType} from 'mw/api/Metadata';
import {nxffConfig} from 'mw/api/NXFF';
import {mw} from 'mw/MW';

import {IconType, Icon} from './Icon';
import IconRound from './IconRound';
import NitroxTag, {TagTypes, Tag, Tags} from './NitroxTag';

const styles = createStyles({
  iconContainer: {
    width: dimensions.icon.xxsmall,
    height: isMobile ? dimensions.icon.xxsmall : dimensions.icon.small,
    justifyContent: 'center',
    alignItems: 'center'
  },
  iconsRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: isMobile ? 0 : dimensions.margins.small
  }
});

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  listIconColorFocused: colors.columnTile.commonIcons.focused,
  listIconColorUnfocused: colors.columnTile.commonIcons.unfocused,
  recordingColor: colors.epgScreen.grid.tile.recIcon,
  failedRecordingColor: colors.epgScreen.grid.tile.failedRecIcon,
  completedRecordingColor: colors.epgScreen.grid.tile.completedRecordingIcon
}));

export const tileIconSize = dimensions.icon.xxsmall;
export type TileIconType = IconType | TagTypes;

type PrepareMediaIconsParams = {
  includeAll?: boolean;
  includeTags?: boolean;
  excludeIcons?: TileIconType[];
  isBlocked?: boolean;
}

export type TileIcon = {
  type: IconType;
  color?: string;
}

export type TileTag = {
  type: TagTypes;
}

function pushIcon(icons: TileIcon[], params: PrepareMediaIconsParams, icon: IconType, predicate: () => boolean, iconColor?: string) {
  // execute predicate at the end because they can be more computationally-heavy than other checks
  const {includeAll = false, excludeIcons = []} = params;
  if ((includeAll || !icons.length) && excludeIcons.indexOf(icon) === -1 && predicate()) {
    icons.push({
      type: icon,
      color: iconColor
    });
  }
}

function pushTag(tags: TileTag[], params: PrepareMediaIconsParams, tag: Tag, predicate: () => boolean) {
  // execute predicate at the end because they can be more computationally-heavy than other checks
  const {includeAll = false, excludeIcons = []} = params;
  if ((includeAll || !tags.length) && excludeIcons.indexOf(tag.type) === -1 && predicate()) {
    tags.push(tag);
  }
}

export function prepareEventIcons(event: Event, params: PrepareMediaIconsParams = {}): MediaIcons {
  const dynamicStyles = dynamicStylesUpdater.getStyles();
  const {includeTags = true} = params;

  const tags: TileTag[] = [];
  if (includeTags) {
    pushTag(tags, params, Tags.ppvTag, () => featureFlags.ppvEntitlementTag); // TODO: CL-1571, CL-1470 - to be replaced with valid condition
    pushTag(tags, params, Tags.liveTag, () => event.isNow);
  }

  const icons: TileIcon[] = [];
  pushIcon(icons, params, IconType.ParentalControl, () => !!params.isBlocked);
  pushIcon(icons, params, IconType.Check, () => event.isOnWatchList);
  if (nxffConfig.getConfig().PVR.KeepStatesOfRecordings) {
    const state = mw.pvr.statusCache.get(event.id);
    switch (state) {
      case RecordingStatus.Scheduled:
        pushIcon(icons, params, IconType.ScheduledRecordDot, () => true, dynamicStyles.recordingColor);
        break;
      case RecordingStatus.Recording:
        pushIcon(icons, params, IconType.RecordingDot, () => true, dynamicStyles.recordingColor);
        break;
      case RecordingStatus.Recorded:
        pushIcon(icons, params, IconType.RecordedDot, () => true, dynamicStyles.completedRecordingColor);
        break;
      case RecordingStatus.Failed:
        pushIcon(icons, params, IconType.RecordedDot, () => true, dynamicStyles.failedRecordingColor);
        break;
    }
  } else {
    pushIcon(icons, params, IconType.RecordingDot, () => event.isRecorded && event.isNow, dynamicStyles.recordingColor);
    pushIcon(icons, params, IconType.RecordedDot, () => event.isRecorded && event.isPast, dynamicStyles.completedRecordingColor);
    pushIcon(icons, params, IconType.ScheduledRecordDot, () => event.isRecorded && event.isFuture, dynamicStyles.recordingColor);
  }
  pushIcon(icons, params, IconType.Restart, () => event.hasTstv);

  return {tags, icons};
}

export function prepareRecordingIcons(recording: Recording, params: PrepareMediaIconsParams = {}): MediaIcons {
  const dynamicStyles = dynamicStylesUpdater.getStyles();
  const icons: TileIcon[] = [];
  pushIcon(icons, params, IconType.Series, () => isBigScreen && recording?.recordingType === RecordingType.Series, dynamicStyles.completedRecordingColor);
  return {icons};
}

export function prepareMediaIcons(media: Media, params: PrepareMediaIconsParams = {}): MediaIcons {
  const icons: TileIcon[] = [];
  pushIcon(icons, params, IconType.Series, () => isSeries(media));
  pushIcon(icons, params, IconType.Check, () => media.isOnWatchList);
  return {icons};
}

export type MediaIcons = {
  tags?: TileTag[];
  icons?: TileIcon[];
}

type IconProps = {
  type: IconType;
  style?: StyleProp<ViewStyle>;
  focused?: boolean;
  color?: string;
}
const ListIconComponent: React.FC<IconProps> = props =>
  (
    <View style={[styles.iconContainer, props.style]}>
      <Icon
        size={tileIconSize}
        color={props.color}
        type={props.type}
      />
    </View>
  );

const ListIcon = React.memo(ListIconComponent);

type Props = {
  mediaIcons: MediaIcons;
  style?: StyleProp<ViewStyle>;
  displayAllIcons?: boolean;
  focused?: boolean;
  iconColor?: string;
};

const prepareTag = (tag: Tag) => {
  return (
    <NitroxTag
      key={'Tag' + tag.type}
      tag={tag}
    />
  );
};

const prepareIcon = (icon: IconType, focused?: boolean, iconColor?: string, marginLeft = 0) => {
  const dynamicStyles = dynamicStylesUpdater.getStyles();
  const color = iconColor ||
    (focused ? dynamicStyles.listIconColorFocused : dynamicStyles.listIconColorUnfocused);

  return (
    icon === IconType.RecordedDot || icon === IconType.ScheduledRecordDot
      ? (
        <IconRound
          key={'Icon' + icon}
          type={icon}
          color={dynamicStyles.listIconColorUnfocused}
          size={tileIconSize}
          iconContainerRatio={1}
          style={{backgroundColor: color, marginLeft}}
        />
      ) : (
        <ListIcon
          key={'Icon' + icon}
          type={icon}
          color={color}
          style={{marginLeft}}
        />
      )
  );
};

const TileIconsRow: React.FC<Props> = props => {
  const tags = props.mediaIcons.tags;
  const icons = props.mediaIcons.icons;
  const focused = props.focused;

  const tagComponents = useMemo(() => {
    return tags?.map(({type}) => prepareTag({type}));
  }, [tags]);

  const hasTags = !!tags?.length;
  const iconComponents = useMemo(() => {
    return icons?.map((icon, index) => {
      const marginLeft = !hasTags && index === 0 ? 0 : dimensions.margins.small;
      return prepareIcon(icon.type, focused, icon?.color, marginLeft);
    });
  }, [icons, focused, hasTags]);

  return (
    <View style={[styles.iconsRow, props.style]}>
      {tagComponents}
      {iconComponents}
    </View>
  );
};
export default React.memo(TileIconsRow);
