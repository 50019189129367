import {EventEmitter} from 'common/EventEmitter';
import {Log} from 'common/Log';

import {CustomerEvent} from 'mw/api/Customer';
import {ErrorType} from 'mw/api/Error';
import {Channel, Location} from 'mw/api/Metadata';
import {nxffConfig} from 'mw/api/NXFF';
import {ChannelUrls} from 'mw/bo-proxy/BOInterface';
import {boProxy} from 'mw/bo-proxy/BOProxy';
import {mw} from 'mw/MW';

const TAG = 'ChannelsCache';

export enum ChannelsCacheEvent {
  Refreshed = 'Refreshed'
}

export class ChannelsCache extends EventEmitter<ChannelsCacheEvent> {
  private channels: Channel[] = [];
  private channelsById: {[id: string]: Channel} = {};
  private sortedChannelsIds: string[] = [];
  private refreshTimer: number | null = null;
  private lastRefreshDate: Date | null = null;
  private lastRefreshError: any;
  private refreshInProgress: Promise<void> | null = null;

  public clear(): void {
    this.channels = [];
    this.channelsById = {};
    this.sortedChannelsIds = [];
    this.lastRefreshDate = null;
    if (this.refreshTimer != null) {
      clearTimeout(this.refreshTimer);
      this.refreshTimer = null;
    }
  }

  public async getChannels(): Promise<Channel[]> {
    if (this.refreshInProgress) {
      await this.refreshInProgress;
    } else if (!this.refreshTimer) {
      await this.refresh();
    }
    if (!this.lastRefreshDate) {
      Log.error(TAG, 'Channels list cache is empty and we failed to refresh one', this.lastRefreshError);
      throw this.lastRefreshError;
    }
    Log.debug(TAG, 'Found cached channels list with ' + this.channels.length + ' channels from ' + this.lastRefreshDate.toISOString() + ' - returning it');
    return this.channels;
  }

  public getChannelById(id: string): Channel | undefined {
    return this.channelsById[id];
  }

  public getSortedChannelsIds(): string[] {
    return this.sortedChannelsIds;
  }

  private scheduleNextRefresh(): void {
    const refreshTimeout = nxffConfig.getConfig().EPG.ChannelRefreshInterval * 60000;
    Log.debug(TAG, 'Scheduling next channels list refresh in ' + refreshTimeout + ' ms');
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
    }
    this.refreshTimer = setTimeout(() => {
      this.refresh()
        .catch((error) => {
          Log.error(TAG, 'Failed to refresh channels cache, got error', error);
        });
    }, refreshTimeout);
  }

  private onDeviceRegistered = () => {
    this.refresh()
      .catch((error) => {
        Log.error(TAG, 'Failed to refresh channels cache, got error', error);
      });
  };

  private async refresh(): Promise<void> {
    const now = new Date();
    Log.debug(TAG, 'Refreshing channels list at ' + now.toUTCString() + ' previous refresh was ' + (this.lastRefreshDate ? 'at ' + this.lastRefreshDate.toISOString() : 'never'));
    this.refreshInProgress = new Promise(async (resolve, reject) => {
      try {
        await mw.catalog.entitledProducts.refresh();

        this.channels = await boProxy.bo.getChannels();
        let channelUrls: ChannelUrls = {};
        if (mw.configuration.isDVBCapable || nxffConfig.getConfig().Playback.EnabledChannelLocationsForAllApps) {
          channelUrls = await boProxy.bo.getChannelUrls();
        }
        this.channelsById = {};
        this.sortedChannelsIds = [];
        this.channels.forEach((channel: Channel) => {
          channel.location = channelUrls[channel.id] || new Location();
          this.channelsById[channel.id] = channel;
          this.sortedChannelsIds.push(channel.id);
        });
        this.sortedChannelsIds.sort((a, b) => a.localeCompare(b));
        await mw.customer.getProfile().refreshChannelLists();
        this.lastRefreshDate = now;
        this.lastRefreshError = null;
        resolve();
        Log.debug(TAG, 'Refreshed channels list with ' + this.channels.length + ' channels at ' + this.lastRefreshDate.toISOString());
        this.scheduleNextRefresh();
        this.notify(ChannelsCacheEvent.Refreshed);
      } catch (refreshError) {
        Log.error(TAG, 'Failed to refresh channels list', refreshError);
        if (refreshError.type === ErrorType.DeviceUnregistered) {
          // Retry refresh after the device is re-registered
          mw.customer.once(CustomerEvent.DeviceRegistered, this.onDeviceRegistered);
        } else {
          // We need to schedule next refresh even if this one failed
          this.scheduleNextRefresh();
        }
        this.lastRefreshError = refreshError;
        reject();
        throw refreshError;
      } finally {
      }
    });
    await this.refreshInProgress.finally(() => this.refreshInProgress = null);
  }
}
