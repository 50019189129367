import {Error} from 'mw/api/Error';
import {Track} from 'mw/api/PlayerEvent';

import {NitroxPlayerEventHandler} from './NitroxPlayerEventHandler';

export class NitroxPlayerEventNotifier implements NitroxPlayerEventHandler {

  private handlers: Set<NitroxPlayerEventHandler> = new Set<NitroxPlayerEventHandler>();

  public addEventHandler = (handler: NitroxPlayerEventHandler): void => {
    this.handlers.add(handler);
  }

  public removeEventHandler = (handler: NitroxPlayerEventHandler): void => {
    this.handlers.delete(handler);
  }

  public onStart = (): void => {
    this.handlers.forEach((handler: NitroxPlayerEventHandler) => {
      handler.onStart();
    });
  }

  public onStop = (): void => {
    this.handlers.forEach((handler: NitroxPlayerEventHandler) => {
      handler.onStop();
    });
  }

  public onBos = (): void => {
    this.handlers.forEach((handler: NitroxPlayerEventHandler) => {
      handler.onBos();
    });
  }

  public onEos = (): void => {
    this.handlers.forEach((handler: NitroxPlayerEventHandler) => {
      handler.onEos();
    });
  }

  public onError = (error: Error): void => {
    this.handlers.forEach((handler: NitroxPlayerEventHandler) => {
      handler.onError(error);
    });
  }

  public onFirstFrame = (): void => {
    this.handlers.forEach((handler: NitroxPlayerEventHandler) => {
      handler.onFirstFrame();
    });
  }

  public onBuffering = (): void => {
    this.handlers.forEach((handler: NitroxPlayerEventHandler) => {
      handler.onBuffering();
    });
  }

  public onReady = (): void => {
    this.handlers.forEach((handler: NitroxPlayerEventHandler) => {
      handler.onReady();
    });
  }

  public onAudioTracksChanged = (tracks: Track[], current: number): void => {
    this.handlers.forEach((handler: NitroxPlayerEventHandler) => {
      handler.onAudioTracksChanged(tracks, current);
    });
  }

  public onSubtitleTracksChanged = (tracks: Track[], current: number): void => {
    this.handlers.forEach((handler: NitroxPlayerEventHandler) => {
      handler.onSubtitleTracksChanged(tracks, current);
    });
  }

  public onVideoTracksChanged = (tracks: string[], current: number): void => {
    this.handlers.forEach((handler: NitroxPlayerEventHandler) => {
      handler.onVideoTracksChanged(tracks, current);
    });
  }

  public clear() {
    this.handlers.clear();
  }
}
