import {createStyles} from 'common-styles';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions, isBigScreen} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxText from 'components/NitroxText';
import Popup, {PopupAction} from 'components/Popup';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  messageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: dimensions.margins.xxLarge
  },
  menu: {
    justifyContent: 'center'
  },
  message: {
    color: colors.popup.text
  },
  button: {
    width: isBigScreen ? 'auto' : dimensions.popup.button.width,
    marginHorizontal: dimensions.margins.small
  }
}));

type Props = {
  visible?: boolean;
  onConfirm: () => void;
};

const UidUsageAgreementPopup: React.FC<Props> = ({visible, onConfirm}) => {
  const {t} = useTranslation();
  const styles = stylesUpdater.getStyles();
  return (
    <Popup
      visible={visible}
      actions={[PopupAction.POSITIVE]}
      menuStyle={styles.menu}
      menuButtonStyle={styles.button}
      menuHasPreferredFocus
      positiveLabel={t('common.ok')}
      onPositive={onConfirm}
    >
      <View style={styles.messageContainer}>
        <NitroxText style={styles.message} textType='dialog-message'>{t('common.uidUsageAgreementMessage')}</NitroxText>
      </View>
    </Popup>
  );
};

export default UidUsageAgreementPopup;
