import React, {useState, useEffect, useCallback, useMemo, RefObject, useRef} from 'react';
import {useTranslation} from 'react-i18next';

import {awaitEvent} from 'common/Async';
import {playerOverlayConfig, isBigScreen} from 'common/constants';
import {AppRoutes} from 'common/constants';
import {DateUtils} from 'common/DateUtils';
import {openMediaDetails, getRewindTooltipsProps, doNothing} from 'common/HelperFunctions';
import {NavigationFocusState} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {EPGParams, CatalogEvent} from 'mw/api/CatalogInterface';
import {Error as MWError, ErrorType as MWErrorType} from 'mw/api/Error';
import {Channel, Event, isChannel, isEvent, Media, MediaType, ContentType, PlaybackLimitations, BlockedByPCEventState} from 'mw/api/Metadata';
import {PlayerEvent, PositionChanged, RewindingEvent} from 'mw/api/PlayerEvent';
import {mw} from 'mw/MW';
import {PlaybackParameters, PlaybackRequestParameters, RewindDirection} from 'mw/playback/Player';

import {useFullScreenControl} from 'components/fullscreen/FullScreenControlProvider';
import {KeyEventManager, NativeKeyEvent, SupportedKeys} from 'components/KeyEventManager';
import {STBMenuState} from 'components/navigation/NavigationHelperTypes';
import {useSTBMenu} from 'components/navigation/STBNavigationView';
import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';
import {AppStatePlaybackRetuneScheduler} from 'components/player/PlaybackRetuneScheduler';
import {PlaybackControlsVisibility, PlayerControlsViewButtonsVisibility, PlayerNavigationControlsVisibility, PlayerControlsHandlers, PlayerControlsRecordDotState, TooltipsProps} from 'components/player/PlayerControlsView';
import PlayerLanguageController from 'components/player/PlayerLanguageController';
import {PlayerTracksInfo} from 'components/player/PlayerLanguageEventHandler';
import {PlayerManager, TuneRequestState, TuneRequest} from 'components/player/PlayerManager';
import {PlayerViews} from 'components/player/PlayerView';
import {useRecord} from 'components/pvr/Record';
import {useDisposable, useEventListener, useNavigationParam, useFunction, useProperty, useNavigationFocusState, useNavigation, useLateBinding, useLazyEffect, useChangeEffect, useToggle, useDebounce} from 'hooks/Hooks';

import {tvScreenConstants} from './TvScreenConstants';
import {isLivePosition, getRecordingDotState} from './TvScreenHelperFunctions';
import {TuneParams, TvScreenParams, EventParentalControl} from './TvScreenHelperTypes';

const TAG = 'TvScreen';

const epgRange = 7200000; // 2 hours

export function useChannels() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Channel[]>();
  const [error, setError] = useState<MWError>();

  const getChannels = useDisposable(() => mw.catalog.getChannels());

  const fetchData = useCallback(async () => {
    Log.debug(TAG, 'Loading new channels list');
    try {
      setLoading(true);
      const channels: Channel[] = await getChannels();
      setData(channels);
    } catch (e) {
      Log.error(TAG, `Error fetching channels data: ${e}`);
      setError(e instanceof MWError ? e : new MWError(MWErrorType.UnknownError));
    } finally {
      setLoading(false);
    }
  }, [getChannels]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEventListener(CatalogEvent.ChannelsListRefreshed, fetchData, mw.catalog);

  return {channels: data, loading, error};
}

type TvScreenParamsUpdate = {
  updateMedia: (media: Media) => void;
  updatePosition: (position?: number) => void;
  updateRequestedByRoute: (requestedByRoute?: AppRoutes) => void;
}

export function useTvScreenParams(): TvScreenParams {
  const channelId = useNavigationParam('channelId');
  const eventId = useNavigationParam('eventId');
  const position = useNavigationParam('position');
  const requestedByRoute = useNavigationParam('requestedByRoute');

  return useMemo(() => ({
    channelId, eventId, position, requestedByRoute
  }), [channelId, eventId, position, requestedByRoute]);
}

export function useUpdateTvScreenParams(): TvScreenParamsUpdate {
  const navigation = useNavigation();
  const params = useTvScreenParams();
  const updateMedia = useFunction((media: Media) => {
    if (isEvent(media)) {
      if (params.eventId !== media.id || params.position != null) {
        navigation.setParams({'channelId': undefined, 'eventId': media.id, position: undefined});
      }
    } else if (isChannel(media)) {
      if (params.channelId !== media.id || params.position != null) {
        navigation.setParams({'channelId': media.id, 'eventId': undefined, position: undefined});
      }
    } else {
      Log.warn(TAG, 'Unsupported type of media passed to updateNavigationParams: ' + media.getType());
    }
  });

  const updatePosition = useFunction((position?: number) => {
    if (params.position !== position) {
      navigation.setParams({position});
    }
  });

  const updateRequestedByRoute = useFunction((requestedByRoute?: AppRoutes) => {
    if (params.requestedByRoute !== requestedByRoute) {
      navigation.setParams({requestedByRoute});
    }
  });

  return {updateMedia, updatePosition, updateRequestedByRoute};
}

type UseCurrentMedia = {
  /**
   * It's equal either to currentChannel or currentEvent.
   * Event type indicates TSTV, while Channel live tv (or PLTV).
   * This should be used for tune related logic.
   */
  playedMedia?: Channel | Event;

  /**
   * Currently played channel.
   * Note: This is NOT necessarily tuned media, player may be tuned to TSTV event that belongs to this channel.
   * Use playedMedia for tune related logic.
   */
  currentChannel?: Channel;

  /**
   * Currently played event.
   * Note: This is NOT necessarily tuned media, player may be tuned to Live TV (or PLTV) channel, of which current event is this one.
   * Use playedMedia for tune related logic.
   */
  currentEvent?: Event;

  /**
   * Changes currentChannel and playedMedia to given channel (tunes live tv point of channel).
   */
  updateCurrentChannel: (channel: Channel) => Promise<void>;

  /**
   * Changes currentEvent to given event and if @param tune is true also playedMedia (tunes tstv event).
   * @param tune Controls whether tune action is performed. Defaults to true.
   */
  updateCurrentEvent: (event: Event, tune?: boolean) => void;
};

export function useCurrentMedia(channels?: Channel[]): UseCurrentMedia {
  const params = useTvScreenParams();
  const {updateMedia} = useUpdateTvScreenParams();
  const [currentEvent, setCurrentEvent] = useState<Event | undefined>();
  const [currentChannel, setCurrentChannel] = useState<Channel | undefined>();
  const [playedMedia, setPlayedMedia] = useState<Event | Channel | undefined>(currentEvent || currentChannel);
  const getEventById = useDisposable((id: string) => mw.catalog
    .getEventById(id)
    .catch(error => {
      Log.error(TAG, `Error getting event by id ${id}`, error);
      return null;
    })
  );

  // consider renaming this to tuneChannel
  // on the other hand this is not "tune" action but rather some kind of scheduling for tune
  const updateCurrentChannel = useFunction(async (channel: Channel) => {
    if (channel.id !== params.channelId) {
      Log.info(TAG, `Ignoring outdated current channel update, params.channelId has already changed. Channel id: ${channel.id}, params.channelId: ${params.channelId}.`);
      return;
    }
    setCurrentChannel(channel);
    setPlayedMedia(channel);

    try {
      const event = await mw.catalog.getCurrentEvent(channel);
      setCurrentEvent(event);
    } catch (error) {
      Log.warn(TAG, `Failed to find current event on channel ${channel} - got error:`, error);
    }
  });

  const updateCurrentEvent = useFunction((event: Event, tune = true) => {
    if (tune) {
      if (event.id !== params.eventId) {
        Log.info(TAG, `Ignoring outdated current event update, params.eventId has already changed. Event id: ${event.id}, params.eventId: ${params.eventId}.`);
        return;
      }
      setCurrentEvent(event);
      setPlayedMedia(event);
      const channel = channels?.find(({id}: Channel) => id === event.channelId);
      setCurrentChannel(channel);
    } else {
      setCurrentEvent(event);
    }
  });

  useEffect(() => {
    async function initializeState() {
      if (!channels) {
        return;
      }

      // If media is passed through params, then it's already handled by params change flow below
      if (params.channelId || params.eventId) {
        return;
      }

      // If no media is passed, then figure initial state and update params
      // Preference:
      // 1. Currently played channel/event
      // 2. Last played channel
      const currentMedia = mw.players.main.getCurrentMedia();
      const initialMedia = isEvent(currentMedia) || isChannel(currentMedia)
        ? currentMedia
        : await mw.catalog.getLastPlayedChannel();
      updateMedia(initialMedia);
    }

    initializeState();
  }, [channels, params.eventId, params.channelId, updateMedia]);

  useEffect(() => {
    async function channelParamChanged() {
      if (channels && params.channelId) {
        const channel = channels?.find(({id}: Channel) => id === params.channelId);
        if (channel) {
          updateCurrentChannel(channel);
        } else {
          Log.error(TAG, `Could not find channel ${params.channelId}`);
        }
      }
    }

    Log.debug(TAG, `Params.channelId: ${params.channelId}`);
    channelParamChanged();
  }, [channels, params.channelId, updateCurrentChannel]);

  useEffect(() => {
    async function eventParamChanged() {
      const event = params.eventId && await getEventById(params.eventId);
      if (event) {
        updateCurrentEvent(event);
      }
    }

    Log.debug(TAG, `Params.eventId: ${params.eventId}`);

    // channels must be loaded to correctly update current event/channel
    if (channels) {
      eventParamChanged();
    }
  }, [params.eventId, getEventById, channels, updateCurrentEvent]);

  // returns false right after param change, but before state (asynchronous) update
  // omits unnecessary tune in some cases
  // e.g. navigation into screen with params.channelId other than current
  // would cause tune to current channel (playedMedia), and immediately retune to new one, causing poor UX
  const isParamsCompliant = (media?: Media) => media && [params.channelId, params.eventId].includes(media.id);

  return {
    currentChannel,
    currentEvent,
    updateCurrentChannel,
    updateCurrentEvent,
    playedMedia: isParamsCompliant(playedMedia) ? playedMedia : undefined
  };
}

export function useEPG(channels?: Channel[]) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<MWError>();

  Log.debug(TAG, 'Loading new channels list');
  const getEPG = useDisposable((params: EPGParams) => mw.catalog.getEPG(params));

  useEffect(() => {
    async function fetchData() {
      if (!channels?.length) {
        return;
      }
      try {
        setLoading(true);
        const params: EPGParams = {
          channels: channels,
          startTime: new Date(Date.now() - epgRange),
          endTime: new Date(Date.now() + epgRange)
        };
        await getEPG(params);
      } catch (e) {
        Log.error(TAG, `Error fetching epg data: ${e}`);
        setError(e instanceof MWError ? e : new MWError(MWErrorType.UnknownError));
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [getEPG, channels]);

  return {loading, error};
}

const initialPlaybackControls = {
  restart: false,
  fastBackwards: false,
  skipBack: false,
  pausePlay: false,
  skipForward: false,
  fastForward: false,
  goToLive: false,
  subtitles: false
};
const initialNavigationControls = {
  collapse: false,
  episodes: false, // TODO: show when current event is a part of series
  recordDot: null,
  channelList: false,
  chromecast: false, // TODO: show if chromecast available
  options: false,
  prevEpisode: false, // TODO: show for vods that are part of series
  nextEpisode: false, // TODO: show for vods that are part of series
  fullscreen: false,
  backFromFullscreen: false,
  showEPG: false,
  playback: false,
  moreInfo: true
};

export function usePlayerControlsViewButtonsVisibility(event?: Event) {
  const [playbackControls, setPlaybackControls] = useState<PlaybackControlsVisibility>(initialPlaybackControls);
  const [navigationControls, setNavigationControls] = useState<PlayerNavigationControlsVisibility>(initialNavigationControls);
  const [error, setError] = useState<MWError>();
  const [loading, setLoading] = useState(false);
  const {getRecordActionTypeForEvent} = useRecord();

  const getRecordDotState = useDisposable((event: Event) => getRecordingDotState(event, getRecordActionTypeForEvent));

  const updatePlaybackControls = useFunction((playbackLimitations: PlaybackLimitations) => {
    setPlaybackControls(prevState => ({
      restart: playbackLimitations.allowRestart || false,
      fastBackwards: playbackLimitations.allowSkipBackward || false,
      skipBack: !isBigScreen && playbackLimitations.allowSkipBackward || false,
      pausePlay: playbackLimitations.allowPause || false,
      skipForward: !isBigScreen && playbackLimitations.allowSkipForward || false,
      fastForward: playbackLimitations.allowSkipForward || false,
      goToLive: playbackLimitations.allowGoToLive || false,
      subtitles: prevState.subtitles
    }));
  });

  const refreshPlaybackControlsVisibility = useFunction(async () => {
    if (!event) {
      return;
    }
    try {
      setLoading(true);
      const recordingDotState = await getRecordDotState(event);
      const channel = mw.catalog.getChannelById(event.channelId);
      const playbackLimitations = mw.players.main.getPlaybackLimitations();
      updatePlaybackControls(playbackLimitations);
      if (channel?.isAvailableByPolicy) {
        setNavigationControls({...initialNavigationControls, recordDot: recordingDotState});
      } else {
        setNavigationControls({...initialNavigationControls, recordDot: recordingDotState === PlayerControlsRecordDotState.Record ? null : recordingDotState});
      }
    } catch (e) {
      Log.error(TAG, `Error fetching epg data: ${e}`);
      setError(e instanceof MWError ? e : new MWError(MWErrorType.UnknownError));
    } finally {
      setLoading(false);
    }
  });

  useEventListener(PlayerEvent.FirstFrame, refreshPlaybackControlsVisibility, mw.players.main);
  useEventListener(PlayerEvent.PlaybackLimitationsChanged, updatePlaybackControls, mw.players.main);

  const {enabled: fullScreenControlEnabled, fullScreenModeActive} = useFullScreenControl();
  const playerControlsViewButtonsVisibility = useMemo<PlayerControlsViewButtonsVisibility>(() => ({
    ...navigationControls,
    playback: playbackControls,
    fullscreen: fullScreenControlEnabled && !fullScreenModeActive,
    backFromFullscreen: fullScreenControlEnabled && fullScreenModeActive
  }), [navigationControls, playbackControls, fullScreenControlEnabled, fullScreenModeActive]);
  const onPlayerTracksChanged = useCallback((playerTracksInfo: PlayerTracksInfo) => {
    setPlaybackControls(prevState => ({...prevState, subtitles: playerTracksInfo.hasAudioOrSubtitleTracks()}));
  }, []);

  return {
    playerControlsViewButtonsVisibility,
    error,
    loading,
    refresh: refreshPlaybackControlsVisibility,
    onPlayerTracksChanged
  };
}

const defaultTvPlayerControlsHandlers: PlayerControlsHandlers = {
  restartHandler: () => Log.debug(TAG, 'restartHandler: fallback'),
  skipBackHandler: () => Log.debug(TAG, 'skipBackHandler: Unhandled action'),
  fastBackwardsHandler: () => Log.debug(TAG, 'fastBackwardsHandler: Unhandled action'),
  skipForwardHandler: () => Log.debug(TAG, 'skipForwardHandler: Unhandled action'),
  fastForwardHandler: () => Log.debug(TAG, 'fastForwardHandler: Unhandled action'),
  playPauseHandler: () => Log.debug(TAG, 'playPauseHandler: Unhandled action'),
  goToLiveHandler: () => Log.debug(TAG, 'goToLiveHandler: not implemented'),
  subtitleHandler: () => Log.debug(TAG, 'subtitleHandler: not implemented'),
  recordDotHandler: () => Log.debug(TAG, 'recordDotHandler: not implemented'),
  channelListHandler: () => Log.debug(TAG, 'toggleHandler: not implemented'),
  moreInfoHandler: () => Log.debug(TAG, 'moreInfoHandler: not implemented')
};

const getCurrentEvent = async () => {
  const media = mw.players.main.getCurrentMedia();
  if (isEvent(media)) {
    return media;
  }
  if (isChannel(media)) {
    try {
      return await mw.catalog.getCurrentEvent(media);
    } catch (error) {
      Log.warn(TAG, `Failed to find current event on channel ${media} - got error:`, error);
    }
  }
};

export function useTvPlayerControlsHandlers(
  tune: (params: TuneParams) => void,
  playerLanguageController: RefObject<PlayerLanguageController>,
  playerControlsViewButtonsVisibility: PlayerControlsViewButtonsVisibility,
  refreshPlaybackControlsVisibility: () => void,
  scheduleRecording: (media: Media) => void,
  currentEvent?: Event
): PlayerControlsHandlers {
  const {updateMedia} = useUpdateTvScreenParams();
  const navigation = useNavigation();

  const restartHandler = useCallback(async () => {
    const media = mw.players.main.getCurrentMedia();
    if (!media) {
      Log.warn(TAG, 'Could not restart - Current media is null');
      return;
    }
    // if currently tuned media is event, we just retune to it
    if (isEvent(media)) {
      tune({playerView: PlayerViews.Zapper, media});
      // if channel is played, we need to update media param (from channel to event)
      // this is consistent with restarting event from outside tv screen
      // lets keep one flow then
    } else if (isChannel(media)) {
      try {
        const event = await mw.catalog.getCurrentEvent(media);
        updateMedia(event);
      } catch (error) {
        Log.error(TAG, `Error getting current event of channel ${media}`, error);
      }
    }
  }, [updateMedia, tune]);

  const goToLiveHandler = useCallback(() => {
    const player = mw.players.main;
    const contentType = player.contentType;
    const parameters = mw.players.main.getParameters();

    switch (contentType) {
      case ContentType.NPLTV:
        if (!parameters.position) {
          Log.warn(TAG, 'Cannot go to live - no position to set');
          return;
        }
        player.changeParameters({position: parameters.endPosition, playRate: 1})
          .then((response: object) => Log.debug(TAG, 'goToLiveHandler: changeParameters succeed', response, contentType))
          .catch((error: object) => Log.error(TAG, 'goToLiveHandler: changeParameters promise rejected', error, contentType));
        break;
      case ContentType.TSTV:
        const media = player.getCurrentMedia();
        if (!isEvent(media)) {
          Log.warn(TAG, 'Cannot go to live - Current media is not an event');
          return;
        }
        const channel = mw.catalog.getChannelById(media.channelId);
        if (!channel) {
          Log.warn(TAG, 'Cannot go to live - Failed to find a channel for event', media);
          return;
        }
        updateMedia(channel);
        break;
    }
  }, [updateMedia]);

  const skip = useCallback((delta: number): void => {
    const player = mw.players.main;
    const contentType = player.contentType;
    const isSkippingForward = (delta > 0);

    const playbackParameters = player.getParameters();
    const playerPosition = playbackParameters.position;
    if (typeof playerPosition !== 'number') {
      Log.warn(TAG, 'skipHandler: Cannot skip - no player position', delta);
      return;
    }

    if (isSkippingForward && contentType === ContentType.TSTV) {
      const position = playerPosition + delta;
      const media: Media | null = player.getCurrentMedia();
      const goToLive = isEvent(media) && isLivePosition(media, position);

      if (goToLive) {
        Log.debug(TAG, `skipHandler(${delta}): time is near live - tune to live`);
        goToLiveHandler();
        return;
      }
    }

    player.changeParameters({skip: delta})
      .then((response: object) => Log.debug(TAG, `skipHandler(${delta}): promise result:`, response, contentType))
      .catch((error: object) => Log.error(TAG, `skipHandler(${delta}): changeParameters promise reject: `, error, contentType));
  }, [goToLiveHandler]);

  const skipBackHandler = useCallback(() => skip(-mw.configuration.playbackRewindSkips[0]), [skip]);
  const skipForwardHandler = useCallback(() => skip(mw.configuration.playbackRewindSkips[0]), [skip]);

  const startRewind = useCallback((direction: RewindDirection): void => {
    const player = mw.players.main;
    const contentType = player.contentType;

    player.startRewind(direction)
      .then(() => Log.debug(TAG, `startRewind(${direction}): done`, contentType))
      .catch((error: object) => Log.error(TAG, `startRewind(${direction}): promise reject: `, error, contentType));
  }, []);

  const fastBackwardsHandler = useCallback(() => startRewind(RewindDirection.FastBackwards), [startRewind]);
  const fastForwardHandler = useCallback(() => startRewind(RewindDirection.FastForward), [startRewind]);

  const playPauseHandler = useCallback(() => {
    if (!playerControlsViewButtonsVisibility.playback.pausePlay) {
      return;
    }
    const player = mw.players.main;
    const playbackParameters: PlaybackParameters = player.getParameters();
    const playRateState = !!playbackParameters.playRate;
    const rewinding = player.isRewinding();
    const playRate = playRateState && !rewinding ? 0 : 1;

    player.changeParameters({playRate: playRate})
      .then((response: object) => Log.debug(TAG, 'playPauseHandler: promise result:', response))
      .catch((error: object) => Log.debug(TAG, 'playPauseHandler: promise reject: ', error));
  }, [playerControlsViewButtonsVisibility.playback.pausePlay]);

  const subtitleHandler = useCallback(() => {
    playerLanguageController.current?.showLanguageSelectionPopup();
  }, [playerLanguageController]);

  const recordDotHandler = useCallback(async (recordDotState: PlayerControlsRecordDotState) => {
    switch (recordDotState) {
      case PlayerControlsRecordDotState.Record:
        // TODO CL-5178 Using getCurrentEvent() in recordDotHandler()
        // could cause problems near end of the event due to shift between real time and stream time.
        const event = await getCurrentEvent();

        if (event) {
          try {
            await scheduleRecording(event);
            refreshPlaybackControlsVisibility();
          } catch (error) {
            Log.error(TAG, 'Failed to schedule a new recording for event:', event);
          }
        }
        break;
      case PlayerControlsRecordDotState.IsNowRecorded:
        // Currently IsNowRecorded is only used as recording indicator.
        // Soon there will be new design and every record state will be handled
        break;
    }
  }, [refreshPlaybackControlsVisibility, scheduleRecording]);

  const moreInfoHandler = useCallback(async () => {
    const event = currentEvent ?? await getCurrentEvent();
    if (event) {
      openMediaDetails(navigation, event.id, event.getType());
    }
  }, [navigation, currentEvent]);

  const {enterFullScreenMode, exitFullScreenMode} = useFullScreenControl();
  const playerControlsHandlers: PlayerControlsHandlers = useMemo(() => ({
    ...defaultTvPlayerControlsHandlers,
    skipBackHandler,
    fastBackwardsHandler,
    skipForwardHandler,
    fastForwardHandler,
    restartHandler,
    playPauseHandler,
    goToLiveHandler,
    subtitleHandler,
    recordDotHandler,
    moreInfoHandler,
    fullScreenHandler: enterFullScreenMode,
    fullScreenBackHandler: exitFullScreenMode
  }), [skipBackHandler, fastBackwardsHandler, skipForwardHandler, fastForwardHandler, restartHandler, playPauseHandler, goToLiveHandler, recordDotHandler, subtitleHandler, moreInfoHandler, enterFullScreenMode, exitFullScreenMode]);

  return playerControlsHandlers;
}

export function useTune(
  onTuneAuthorized: () => void,
  onTuneFinished: () => void = doNothing
) {
  const navigationFocusState = useNavigationFocusState(useNavigation());
  const {updatePosition, updateRequestedByRoute} = useUpdateTvScreenParams();
  const params = useTvScreenParams();
  const [getTunedByRoute, setTunedByRoute] = useProperty<AppRoutes | undefined>(undefined);
  const [tune, bindTune] = useLateBinding<(params: TuneParams) => void>();
  const {isMediaBlocked, shouldBeCheckedForPC} = useParentalControl();

  const scheduleTune = useDebounce(tune, tvScreenConstants.tuneDelay);

  const onParentalControlChanged = useFunction(({blocked, event}: BlockedByPCEventState) => {
    const shouldUnlock = !(event && shouldBeCheckedForPC(event) && isMediaBlocked(event));
    if (blocked && shouldUnlock) {
      mw.players.main.unblockPC();
    }

    if (!blocked) {
      onTuneAuthorized();
    }
  });

  useEventListener(PlayerEvent.BlockedByParentalControl, onParentalControlChanged, mw.players.main);

  const onTuneRequestStateChange = useFunction((state: TuneRequestState, tuneRequest: TuneRequest) => {
    if (navigationFocusState !== NavigationFocusState.IsFocused || ![MediaType.Channel, MediaType.Event].includes(tuneRequest.media.getType())) {
      return;
    }
    switch (state) {
      case TuneRequestState.Completed: {
        updatePosition(undefined);
        if (isEvent(mw.players.main.getCurrentMedia())) {
          // TSTV/RSTV playback in MW does not support PlayerEvent.Loading or parental control
          onTuneFinished();
        }
        break;
      }
      case TuneRequestState.Rejected: {
        Log.error('useTune', `Tune request for media ${tuneRequest.media.id} rejected`);
        onTuneFinished();
        break;
      }
      case TuneRequestState.UnavailableByPolicy: {
        onTuneFinished();
      }
    }
  });
  useEventListener(PlayerEvent.Loading, loading => !loading && onTuneFinished(), mw.players.main);

  bindTune(async (tuneParams: TuneParams) => {
    scheduleTune.abort();
    const requestedMedia = tuneParams.media;

    if (!requestedMedia) {
      Log.error(TAG, 'Tune failed due to lack of playable media');
      return;
    }

    Log.debug(TAG, `Attempting to tune media ${requestedMedia}`);
    setTunedByRoute(params.requestedByRoute);
    if (params.requestedByRoute) {
      updateRequestedByRoute(undefined);
    }

    const parameters: PlaybackRequestParameters = {
      playRate: 1,
      ...tuneParams.params
    };

    PlayerManager
      .getInstance()
      .tune(
        requestedMedia,
        tuneParams.playerView,
        parameters,
        tuneParams.forceTuneAttempt,
        async (state: TuneRequestState, tuneRequest: TuneRequest) => {
          if (navigationFocusState === NavigationFocusState.IsFocused) {
            onTuneRequestStateChange(state, tuneRequest);
          }
        });
  });

  return {tune, scheduleTune, onTuneRequestStateChange, getTunedByRoute};
}

/**
 * Returns a callback to be passed to PlayerView.onReady.
 * Makes sure that switchPlayerView is called when PlayerView is ready.
 */
export function usePlayerViewReadyCallback(viewId: PlayerViews): () => void {
  const screenFocused = useNavigationFocusState(useNavigation()) === NavigationFocusState.IsFocused;
  return useFunction(() => {
    if (!screenFocused) {
      return;
    }

    mw.players.main.switchPlayerView(viewId)
      .catch(reason => {
        Log.error(TAG, 'onPlayerViewReady', reason);
      });
  });
}

export function useMenuIntegration(overlayVisible: boolean) {
  const screenFocused = useNavigationFocusState(useNavigation()) === NavigationFocusState.IsFocused;
  const menuContext = useSTBMenu();

  useLazyEffect(() => {
    if (screenFocused) {
      menuContext?.setMenuState(STBMenuState.Hidden);
    }
  }, [screenFocused], [menuContext]);

  const backHandler = useCallback(() => {
    if (menuContext?.hasFocus) {
      menuContext.setMenuState(STBMenuState.Hidden);
    } else if (!overlayVisible) {
      menuContext?.focusMenu();
    }

    return true;
  }, [menuContext, overlayVisible]);

  return {backHandler};
}

export function usePlaybackParameters() {
  const [parameters, setParameters] = useState(mw.players.main.getParameters());
  const [paused, setPaused] = useState(parameters.playRate === 0);
  const [rewindDirection, setRewindDirection] = useState(mw.players.main.getRewindDirection());

  useChangeEffect(() => {
    parameters.playRate !== undefined && setPaused(parameters.playRate === 0);
  }, [parameters]);

  useEventListener(PlayerEvent.ParametersChanged, setParameters, mw.players.main);

  const onPlayerRewinding = useCallback((event: RewindingEvent) => {
    setRewindDirection(event.delta > 0 ? RewindDirection.FastForward : RewindDirection.FastBackwards);
  }, []);

  const onPlayerRewindingStopped = useCallback(() => {
    setRewindDirection(undefined);
  }, []);

  useEventListener(PlayerEvent.Rewinding, onPlayerRewinding, mw.players.main);
  useEventListener(PlayerEvent.RewindStopped, onPlayerRewindingStopped, mw.players.main);

  return {parameters, paused, rewindDirection};
}

export function usePlayerPosition(event?: Event) {
  const calculateProgress = useFunction((position?: number) => {
    if (position == null) {
      return new Date();
    }
    switch (mw.players.main.contentType) {
      case ContentType.LIVE:
      case ContentType.NPLTV:
        return new Date(position * DateUtils.msInSec);
      case ContentType.TSTV:
        return event ? DateUtils.addSeconds(event.start, position) : new Date();
      default:
        return new Date();
    }
  });

  const calculateAvailableProgressStart = useFunction((beginPosition: number) => {
    return calculateProgress(beginPosition);
  });

  const calculateAvailableProgressEnd = useFunction((endPosition: number) => {
    if (mw.players.main.contentType === ContentType.TSTV && event) {
      return event.isNow ? DateUtils.addSeconds(event.start, endPosition) : event.end;
    }
    return calculateProgress(endPosition);
  });

  const [currentProgress, setCurrentProgress] = useState(() => calculateProgress(mw.players.main.getParameters().position));
  const [availableProgressStart, setAvailableProgressStart] = useState(() => calculateAvailableProgressStart(mw.players.main.getParameters().beginPosition));
  const [availableProgressEnd, setAvailableProgressEnd] = useState(() => calculateAvailableProgressEnd(mw.players.main.getParameters().endPosition));

  const onPositionChanged = useFunction((params: PositionChanged) => {
    setCurrentProgress(calculateProgress(params.position));
    setAvailableProgressStart(calculateAvailableProgressStart(params.beginPosition));
    setAvailableProgressEnd(calculateAvailableProgressEnd(params.endPosition));
  });

  useEventListener(PlayerEvent.PositionChanged, onPositionChanged, mw.players.main);

  return [currentProgress, availableProgressStart, availableProgressEnd];
}

// Few things to consider
// - do we need overlay to be toggleable when timer is stopped (e.g. player is paused)?
// - if so, we need to differ persistent overlay and nonhideable state for PC handling
export function useOverlayVisibility({modalVisible}: {modalVisible: boolean}) {
  const [overlayVisible, {on: setOverlayVisibleOn, off: setOverlayVisibleOff}] = useToggle(false);
  const timerId = useRef<number>();
  const [hidePrevented, setHidePrevented] = useState(false);
  const {paused, rewindDirection} = usePlaybackParameters();

  useChangeEffect(() => {
    if (paused || modalVisible || rewindDirection != null) {
      setHidePrevented(true);
    } else {
      setHidePrevented(false);
    }
  }, [paused, modalVisible, rewindDirection]);

  const hideOverlay = useFunction(() => {
    setHidePrevented(false);
    setOverlayVisibleOff();
  });

  const onHideTimerDone = useFunction(() => {
    if (!hidePrevented) {
      setOverlayVisibleOff();
    }
  });

  const scheduleHideOverlay = useFunction(() => {
    clearTimeout(timerId.current);
    timerId.current = setTimeout(onHideTimerDone, playerOverlayConfig.hideTimeout * DateUtils.msInSec);
  });

  const showOverlay = useFunction(() => {
    setOverlayVisibleOn();
    scheduleHideOverlay();
  });

  useEffect(() => {
    if (overlayVisible && !hidePrevented) {
      scheduleHideOverlay();
    }
    if (overlayVisible && hidePrevented) {
      clearTimeout(timerId.current);
    }
    if (!overlayVisible && hidePrevented) {
      showOverlay();
    }
  }, [overlayVisible, scheduleHideOverlay, hidePrevented, showOverlay]);

  return {overlayVisible, showOverlay, hideOverlay, setHidePrevented};
}

export function useEventParentalControl(currentEvent?: Event): EventParentalControl {
  const {isMediaBlocked, shouldBeCheckedForPC, unlockMedia} = useParentalControl();

  const onUnlockRequest = useCallback(() => {
    unlockMedia(currentEvent)
      .then(() => mw.players.main.unblockPC());
  }, [currentEvent, unlockMedia]);

  const playbackRetuneScheduler = (shouldBeCheckedForPC(currentEvent) && isMediaBlocked(currentEvent)) ? <AppStatePlaybackRetuneScheduler /> : <React.Fragment />;

  return {playbackRetuneScheduler, onUnlockRequest};
}

// TODO: CL-3092 wrap this in focus parent and pass info whether screen is focused?
// ...but what if there's no focus at all?
// there's race on 'ok' click between menu item click and handling inside overlay
export function useTvFocusParent({showOverlay}: {showOverlay: () => void}) {
  const stbMenu = useSTBMenu();

  const onFocusEnter = useFunction(() => {
    stbMenu?.setMenuState(STBMenuState.Hidden);
  });

  return {onFocusEnter, onInternalFocusUpdate: showOverlay};
}

export function useLogTvScreenParams() {
  const params = useTvScreenParams();

  const logParams = useCallback(() => {
    const entries = Object.entries(params);
    const mappedEntries = entries
      .map(([key, value]) => `${key}: ${value}`)
      .join(', ');
    Log.info(TAG, `Params: { ${mappedEntries} }`);
  }, [params]);

  useEffect(() => {
    logParams();
  }, [logParams]);

  return logParams;
}

export function useEndOfContentHandler(handlers: PlayerControlsHandlers, getTunedByRoute: () => AppRoutes | undefined) {
  const navigation = useNavigation();
  const screenFocused = useNavigationFocusState(useNavigation()) === NavigationFocusState.IsFocused;
  const params = useTvScreenParams();

  const exitTstv = useFunction(() => {
    const tunedByRoute = getTunedByRoute();
    if (tunedByRoute) {
      Log.debug(TAG, `Going back to the route ${tunedByRoute} from which the tune request originated,`, navigation.state);
      navigation.navigate(tunedByRoute, {
        mediaId: params.eventId,
        mediaType: MediaType.Event
      });
      return;
    }

    Log.debug(TAG, `Tuning LiveTV playback`);
    handlers.goToLiveHandler?.();
  });

  const onEndOfContent = useFunction(() => {
    if (!screenFocused || mw.players.main.contentType !== ContentType.TSTV) {
      return;
    }

    Log.debug(TAG, 'End of TSTV playback deteced');
    exitTstv();
  });

  return {onEndOfContent, exitTstv};
}

type UseRemoteControl = {
  channelNumberString: string;
  cancelInput: () => void;
}

export function useRemoteControl(tune: (channelNumber: number) => Promise<void>): UseRemoteControl {
  const screenFocused = useNavigationFocusState(useNavigation()) === NavigationFocusState.IsFocused;
  const {unlockModalVisible} = useParentalControl();
  const [listening, setListening] = useState(true);
  const [channelNumberString, setChannelNumberString] = useState('');

  const handleKeyPress = useFunction(({key}: NativeKeyEvent) => {
    if (!listening || !screenFocused || unlockModalVisible || key == null) {
      return;
    }
    if (key >= SupportedKeys.Num0 && key <= SupportedKeys.Num9) {
      setChannelNumberString(s => s + key);
    }
  });

  const tuneChannel = useFunction(() => {
    setListening(false);
    tune(+channelNumberString)
      .then(() => {
        setChannelNumberString('');
        setListening(true);
      });
  });

  const debounceTuneChannel = useDebounce(tuneChannel, 3000);

  useChangeEffect(() => {
    if (channelNumberString.length === 3) {
      tuneChannel();
    } else if (channelNumberString.length > 0) {
      debounceTuneChannel();
    }
  }, [channelNumberString], []);

  useEventListener(
    'keyup',
    handleKeyPress,
    KeyEventManager.getInstance()
  );

  const cancelInput = useCallback(() => {
    setChannelNumberString('');
    setListening(true);
  }, []);

  return {
    channelNumberString,
    cancelInput
  };
}

export function useRewindTooltips() {
  const {t} = useTranslation();
  const [tooltipsProps, setTooltipsProps] = useState<TooltipsProps>({});

  const onPlayerRewinding = useCallback((event: RewindingEvent) => {
    setTooltipsProps(getRewindTooltipsProps(t, event.delta));
  }, [t]);
  const onPlayerRewindStopped = useCallback(() => {
    setTooltipsProps(getRewindTooltipsProps(t, 0));
  }, [t]);

  useEventListener(PlayerEvent.Rewinding, onPlayerRewinding, mw.players.main);
  useEventListener(PlayerEvent.RewindStopped, onPlayerRewindStopped, mw.players.main);

  return {tooltipsProps};
}

export function useRewindListener(tune: (params: TuneParams) => void, onRewinding?: () => void): void {
  const player = mw.players.main;
  const onPlayerRewinding = useCallback(async (event: RewindingEvent) => {
    onRewinding?.();
    if (player.contentType !== ContentType.TSTV) {
      return;
    }
    const media = player.getCurrentMedia();
    if (isEvent(media) && isLivePosition(media, event.position)) {
      const rewindStoppedPromise = awaitEvent(PlayerEvent.RewindStopped, player);
      await player.stopRewind();
      await rewindStoppedPromise;
      const channel = mw.catalog.getChannelById(media.channelId);
      if (!channel) {
        Log.error(TAG, `Cannot go to live, unable to find channel with id ${media.channelId}`);
        return;
      }
      tune({media: channel, playerView: PlayerViews.Zapper, forceTuneAttempt: true});
    }
  }, [onRewinding, player, tune]);

  useEventListener(PlayerEvent.Rewinding, onPlayerRewinding, player);
}
