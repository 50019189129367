import {createStyles} from 'common-styles';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {isMobile, isTablet, getValue} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NoResultsSvg from 'brand/current/commonResources/svgs/NoResults.svg';
import NitroxImage from 'components/NitroxImage';
import NitroxText from 'components/NitroxText';

const TAG = 'NoResults';

const styles = createStyles({
  container: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  },
  noResultsWrapper: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  noResults: isMobile ? {
    width: isTablet ? 418 : 298,
    height: isTablet ? 316 : 224
  } : {
    width: 596,
    height: 451
  }
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  noResultsLabel: {
    position: 'absolute',
    bottom: getValue({tablet: -16, mobile: -10, defaultValue: -20}),
    color: colors.searchScreen.header
  }
}));

const NoResults = () => {
  const {t} = useTranslation();
  const dynamicStyles = stylesUpdater.getStyles();
  return (
    <View style={styles.container}>
      <View style={styles.noResultsWrapper}>
        <NitroxImage
          style={styles.noResults}
          svg={NoResultsSvg}
        />
        <NitroxText
          textType='title'
          style={dynamicStyles.noResultsLabel}
        >
          {t('search.noResults')}
        </NitroxText>
      </View>
    </View>
  );
};

export default React.memo(NoResults);
