import React, {useMemo} from 'react';
import {ViewStyle, View, StyleProp} from 'react-native';
// eslint-disable-next-line no-restricted-imports
import {TapGestureHandler, TapGestureHandlerGestureEvent, State} from 'react-native-gesture-handler';
import Animated from 'react-native-reanimated';

import {commonAnimationTransforms} from 'common/constants';
import {Size} from 'common/HelperTypes';

import ConditionalWrapper from 'components/ConditionalWrapper';
import {useFunction, useLazyRef} from 'hooks/Hooks';

const containerStyle: ViewStyle = {
  flex: 1,
  overflow: 'hidden'
};

export type AnimatedScrollViewTapEvent = {
  position: {
    x: number;
    y: number;
  };
  /**
   * Negated `position`. Scroll offset.
   * Displacement of top-left window point in relation to top-left point of whole content.
   */
  offset: {
    x: number;
    y: number;
  };
}

type AnimatedScrollViewProps = {
  positionX?: Animated.Adaptable<number>;
  positionY?: Animated.Adaptable<number>;
  size: Size;
  style?: StyleProp<ViewStyle>;

  onTap?: (event: AnimatedScrollViewTapEvent) => void;

  testID?: string;
};

/**
 * A component that allows to control its translation point using a pair of Animated.Values.
 * Note: Don't mistake `position` for `offset` (offset - standard ScrollView's scrollOffset). Position is component's (in relation to top-left point) translation.
 * E.g. We move container "up", visible content goes up.
 */
const AnimatedScrollView: React.FC<AnimatedScrollViewProps> = ({
  positionX,
  positionY,
  size,
  style,
  children,
  onTap: propagateTap,
  testID = 'reanimated_scroll_view'
}) => {
  // Reanimated.View ignores transform if non-animated-node is passed to any property, so we can't simply use '0' (web-specific)
  // TODO: CL-6315 PR in reanimated repo
  const animatedZero = useLazyRef(() => new Animated.Value(0)).current;
  // https://github.com/software-mansion/react-native-reanimated/pull/722
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const animatedStyle: Animated.AnimateStyle<ViewStyle> = useMemo(() => ({
    position: 'absolute',
    top: 0,
    left: 0,
    ...size,
    transform: [
      {translateX: positionX ?? animatedZero},
      {translateY: positionY ?? animatedZero},
      ...commonAnimationTransforms
    ]
  }), [size, positionX, animatedZero, positionY]);

  const onTap = useFunction(({nativeEvent: {x, y, state}}: TapGestureHandlerGestureEvent) => {
    if (state === State.END) {
      propagateTap?.({
        position: {
          x: -x,
          y: -y
        },
        offset: {
          x,
          y
        }
      });
    }
  });

  return (
    <View style={[containerStyle, style]} testID={`${testID}_viewport`}>
      <ConditionalWrapper
        condition={!!propagateTap}
        wrapper={children => (
          <TapGestureHandler onHandlerStateChange={onTap}>
            {children}
          </TapGestureHandler>
        )}
      >
        <Animated.View style={animatedStyle} collapsable={false} testID={`${testID}_content`}>
          {children}
        </Animated.View>
      </ConditionalWrapper>
    </View>
  );
};

export default AnimatedScrollView;
