import {createStyles} from 'common-styles';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {ScrollView, ScrollViewProps, LayoutChangeEvent, View} from 'react-native';

import {isBigScreen, isDesktopBrowser} from 'common/constants';
import {indexKeyExtractor} from 'common/HelperFunctions';
import {Size} from 'common/HelperTypes';

import FocusParent from 'components/FocusParent';
import NitroxInteractive from 'components/NitroxInteractive';
import {NitroxInteractiveController} from 'components/NitroxInteractiveControllerContext';
import {useScrollViewChunksLayout, useProperty, useChangeEffect} from 'hooks/Hooks';

export const defaultScrollStep = 200;
const focusHolderSize = 10;

const staticStyles = createStyles({
  interactiveWrapper: {
    width: '100%',
    height: '100%'
  }
});

type Props = {
  scrollStep?: number;
  hasTVPreferredFocus?: boolean;
  /**
   * Called when number of focus holders that allow scrolling to the overflowed content changes.
   */
  onFocusHoldersCount?: (count: number) => void;
} & ScrollViewProps;

/**
 * 'InteractiveScrollView' is designed to enable remote/keyboard navigation on non-interactive ScrollView's content.
 * It renders appropriate number of focus holders to allow scrolling to overflowed content.
 */
const InteractiveScrollView: React.FC<Props> = props => {
  const {
    scrollStep = defaultScrollStep,
    hasTVPreferredFocus = false,
    onFocusHoldersCount,
    ...scrollViewProps
  } = props;

  const {
    horizontal = false,
    onLayout: propsOnLayout,
    onContentSizeChange: propsOnContentSizeChange,
    ...restScrollViewProps
  } = scrollViewProps;

  const scrollViewRef = useRef<ScrollView>(null);

  const {
    chunksLayout: focusHoldersLayout,
    chunksCount: focusHoldersCount,
    updateContentSize,
    updateChunksCount
  } = useScrollViewChunksLayout(horizontal || false);

  const [getViewportSize, setViewportSize] = useProperty<Size | null>(null);
  const [getContentSize, setContentSize] = useProperty<Size | null>(null);

  const updateFocusHoldersCount = useCallback(() => {
    const viewportSize = getViewportSize();
    const contentSize = getContentSize();
    if (!viewportSize || !contentSize) {
      return;
    }

    const overflowSize = Math.max(0, (horizontal
      ? contentSize.width - viewportSize.width
      : contentSize.height - viewportSize.height));
    if (!overflowSize) {
      updateChunksCount(0);
      return;
    }

    const newFocusHoldersCount = Math.ceil(overflowSize / scrollStep) + 1; //one more because the first one won't perform scroll
    updateChunksCount(newFocusHoldersCount);
  }, [getViewportSize, getContentSize, horizontal, scrollStep, updateChunksCount]);

  useChangeEffect(() => onFocusHoldersCount?.(focusHoldersCount), [focusHoldersCount], [onFocusHoldersCount]);

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    const {nativeEvent: {layout: {width, height}}} = event;
    setViewportSize({width, height});
    updateFocusHoldersCount();
    propsOnLayout?.(event);
  }, [setViewportSize, updateFocusHoldersCount, propsOnLayout]);

  const onContentSizeChange = useCallback((width: number, height: number) => {
    const contentSize = {width, height};
    setContentSize(contentSize);
    updateContentSize(contentSize);
    updateFocusHoldersCount();
    propsOnContentSizeChange?.(width, height);
  }, [setContentSize, updateContentSize, updateFocusHoldersCount, propsOnContentSizeChange]);

  const [enableScroll, setEnableScroll] = useState(true);

  const onFocusEscape = useCallback(() => {
    setEnableScroll(false);
  }, []);

  const scrollToIndex = useCallback((index: number) => {
    if (!enableScroll) {
      setEnableScroll(true);
      return;
    }
    const offset = index * scrollStep;
    scrollViewRef.current?.scrollTo({
      animated: true,
      ...horizontal
        ? {x: offset}
        : {y: offset}
    });
  }, [horizontal, enableScroll, scrollStep]);

  const focusHolders = useMemo(() => focusHoldersLayout.map((layout, index) => (
    <NitroxInteractive
      key={indexKeyExtractor(layout, index)}
      hasTVPreferredFocus={hasTVPreferredFocus && index === 0}
      onFocus={() => scrollToIndex(index)}
      style={{
        position: 'absolute',
        top: layout.y,
        left: layout.x,
        width: horizontal ? focusHolderSize : '100%',
        height: horizontal ? '100%' : focusHolderSize
      }}
    />
  )), [focusHoldersLayout, hasTVPreferredFocus, horizontal, scrollToIndex]);

  const BigScreenContainer = hasTVPreferredFocus ? FocusParent : View;

  return isBigScreen
    ? (
      <NitroxInteractiveController omitGeometryCaching>
        <BigScreenContainer style={staticStyles.interactiveWrapper} onFocusEscape={onFocusEscape}>
          <ScrollView
            ref={scrollViewRef}
            horizontal={horizontal}
            onLayout={onLayout}
            onContentSizeChange={onContentSizeChange}
            scrollEnabled={isDesktopBrowser}
            {...restScrollViewProps}
          >
            {props.children}
            {focusHolders}
          </ScrollView>
        </BigScreenContainer>
      </NitroxInteractiveController>
    ) : (
      <ScrollView
        {...scrollViewProps}
      >
        <NitroxInteractive activeOpacity={1} style={staticStyles.interactiveWrapper}>
          {props.children}
        </NitroxInteractive>
      </ScrollView>
    );
};

export default InteractiveScrollView;
