import {Media, Picture, Playable, MediaType, ParentalControlRating} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

export abstract class MediaObject implements Media {
  public readonly id: string;
  public name: string;
  public ordinal = -1;
  public customProperties: any = {};
  public pictures: Picture[] = [];
  public pcRatings: ParentalControlRating[] = [];
  public bookmark = 0;
  public isViewedCompletely = false;
  public isAdult = false;

  public constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }

  public abstract getPlayable(): Playable | null;
  public abstract getType(): MediaType;

  public get isOnWatchList(): boolean {
    return !!mw.customer.currentProfile?.isOnWatchList(this);
  }

  public isPartiallyViewed(): boolean {
    return !this.isViewedCompletely && this.bookmark > 0;
  }

  public isPlayAllowed(): boolean {
    return !!this.getPlayable();
  }

  public isAllowedOnWatchList(): boolean {
    return mw.configuration.enabledWatchlist;
  }

  public equals(media?: Media | null): boolean {
    return media && this.id === media.id && this.getType() === media.getType() || false;
  }

  public hasRatings(): boolean {
    return !!this.pcRatings.length;
  }

  public isBlocked(): boolean {
    const profile = mw.customer.currentProfile;
    if (!profile) {
      return true;
    }
    if (!profile.isPCEnabled) {
      return false;
    }
    if (this.isAdult) {
      return true;
    }
    return mw.configuration.pcPolicy.isBlocked(profile, this.pcRatings);
  }

  public toString(): string {
    return `Media(id: "${this.id}", name: "${this.name}", type: "${this.getType()}")`;
  }
}
