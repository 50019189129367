import React, {useRef, useMemo, useCallback, useState, useImperativeHandle, Ref, forwardRef} from 'react';
import {Animated, View, LayoutChangeEvent} from 'react-native';

import {isMobile, defaultPageSize, isBigScreen} from 'common/constants';

import {Filter} from 'mw/api/Filter';
import {Media} from 'mw/api/Metadata';
import {isDataSourceFilterBased} from 'mw/cms/Component';
import {Menu} from 'mw/cms/Menu';
import {mw} from 'mw/MW';

import ConditionalWrapper from 'components/ConditionalWrapper';
import AnimatedScrollView from 'components/epg/animated/AnimatedScrollView';
import {Hotspot} from 'components/uxm-components/UMXComponentStack.shared';
import {useDisposableState, useLazyEffect, useScreenInfo} from 'hooks/Hooks';

import PromotionalBannerGrosso from './PromotionalBanner.grosso';
import PromotionalBannerPiccolo from './PromotionalBanner.piccolo';
import {PromotionalBannerProps, PromotionalBannerComponentProps, defaultBannerRotation} from './PromotionalBanner.shared';

const PromotionalBanner: React.FC<PromotionalBannerProps> = props => {
  const {dataSource} = props;
  const [data, setData] = useDisposableState<{media: Media[]; rotation: number;}>({media: [], rotation: defaultBannerRotation});
  const fetchInProgress = useRef<string>();

  useLazyEffect(() => {
    let filters: Filter[];
    let rotation = defaultBannerRotation;
    let limit = defaultPageSize;
    if (dataSource instanceof Menu) {
      filters = dataSource.filter ? [dataSource.filter] : [];
      rotation = dataSource.bannerRotation ?? rotation;
    } else {
      filters = isDataSourceFilterBased(dataSource.dataSource)
        ? dataSource.dataSource.filters
        : [];
      limit = dataSource.settings?.itemsLimit ?? limit;
      rotation = dataSource.settings?.bannerRotation ?? rotation;
    }

    const dataSourceId = filters.map(({value}) => value).join('_');
    if (fetchInProgress.current === dataSourceId) {
      return;
    }
    fetchInProgress.current = dataSourceId;
    setData({media: [], rotation});
    if (!filters.length) {
      return;
    }
    mw.catalog.getContent(filters, {pageSize: limit})
      .next()
      .then(({value}) => value ?? [])
      .then(media => fetchInProgress.current === dataSourceId && setData({media, rotation}));
  }, [dataSource], [setData]);

  const componentProps = useMemo<PromotionalBannerComponentProps>(() => ({
    ...props,
    data
  }), [props, data]);

  return isMobile ? <PromotionalBannerPiccolo {...componentProps} /> : <PromotionalBannerGrosso {...componentProps} />;
};

export default PromotionalBanner;

const promotionalBannerScrollDuration = 280;

export type PromotionalBannerAnimatedScrollViewProps = {
  promoBanner: Hotspot | Menu | null | undefined
}

export type PromotionalBannerAnimatedScrollViewInterface = {
  hide: (offset?: number) => void;
  show: () => void;
}

// This component is created as a workaround for problems with ScrollView on ATV.
// Setting enableScroll={false} does not prevent ATV from scrolling content.
// https://github.com/facebook/react-native/issues/29774

const PromotionalBannerAnimatedScrollViewComponent: React.FC<PromotionalBannerAnimatedScrollViewProps> = (props, ref: Ref<PromotionalBannerAnimatedScrollViewInterface>) => {
  const {promoBanner} = props;
  const animationProgress = useRef<Animated.CompositeAnimation | null>(null);
  const animatedProgress = useRef(new Animated.Value(0));
  const [scrollViewContentHeight, setScrollViewContentHeight] = useState<number>(0);
  const {size} = useScreenInfo();

  const hide = useCallback((offset = 0) => {
    animationProgress.current = Animated.timing(animatedProgress.current, {
      toValue: scrollViewContentHeight - size.height - offset,
      duration: promotionalBannerScrollDuration,
      useNativeDriver: true
    });
    animationProgress.current?.start();
  }, [scrollViewContentHeight, size]);

  const show = useCallback(() => {
    animationProgress.current = Animated.timing(animatedProgress.current, {
      toValue: 0,
      duration: promotionalBannerScrollDuration,
      useNativeDriver: true
    });
    animationProgress.current?.start();
  }, []);

  const onScrollViewContentSizeChange = useCallback((layout: LayoutChangeEvent) => {
    // set only once to prevent resizing on promotional banner height change
    if (!scrollViewContentHeight) {
      setScrollViewContentHeight(layout.nativeEvent.layout.height);
    }
  }, [scrollViewContentHeight]);

  /* eslint-disable schange-rules/no-use-imperative-handle-hook */
  useImperativeHandle(ref, () => {
    return {hide, show};
  }, [hide, show]);
  /* eslint-enable schange-rules/no-use-imperative-handle-hook */

  return (
    <ConditionalWrapper
      condition={!!promoBanner && isBigScreen}
      wrapper={children => (
        <AnimatedScrollView
          offsetY={animatedProgress.current}
          size={size}
          style={size}
        >
          <View onLayout={onScrollViewContentSizeChange}>
            {children}
          </View>
        </AnimatedScrollView>
      )}
    >
      {props.children}
    </ConditionalWrapper>
  );
};

export const PromotionalBannerAnimatedScrollView = React.memo(forwardRef(PromotionalBannerAnimatedScrollViewComponent)) as typeof PromotionalBannerAnimatedScrollViewComponent;

