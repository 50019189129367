import {createStyles} from 'common-styles';
import moment from 'moment';
import React, {PureComponent} from 'react';
import {View, ViewStyle, LayoutChangeEvent} from 'react-native';

import {RADIUS} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {mw} from 'mw/MW';

import NitroxText from 'components/NitroxText';

const timelineSyncTimeout = 30000;
const lineWidth = 2;
const labelWidth = 70;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    backgroundColor: colors.epgScreen.grid.verticalTimeIndicator.line,
    flexDirection: 'row',
    position: 'relative',
    width: lineWidth
  },
  label: {
    alignSelf: 'center',
    backgroundColor: colors.epgScreen.grid.verticalTimeIndicator.line,
    borderRadius: RADIUS,
    width: labelWidth,
    height: 30,
    left: -(labelWidth / 2),
    position: 'absolute'
  },
  title: {
    color: colors.epgScreen.grid.verticalTimeIndicator.text,
    lineHeight: 30,
    textAlign: 'center'
  }
}));

type Props = {
  style?: ViewStyle;
  onLayout?: (event: LayoutChangeEvent) => void;
  label?: boolean;
}

type State = {
  labelValue: string;
  positionX: number;
};

class EpgLine extends PureComponent<Props, State> {
  private styles = stylesUpdater.getStyles();
  private intervalId = 0;

  private getFormatedTime = () => moment(new Date()).format(mw.configuration.timeFormat);

  public constructor(props: Props) {
    super(props);
    this.state = {
      labelValue: props.label ? this.getFormatedTime() : '',
      positionX: 0
    };
  }

  public setX(positionX: number) {
    this.setState({positionX: positionX - lineWidth});
  }

  public componentDidMount = () => {
    if (this.props.label) {
      this.intervalId = setInterval(() => {
        this.setState({labelValue: this.getFormatedTime()});
      }, timelineSyncTimeout);
    }
  }

  public componentWillUnmount = () => {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  public render() {
    return (
      <View
        onLayout={this.props.onLayout}
        style={[this.styles.container, this.props.style, {left: this.state.positionX}]}
        pointerEvents='none'
      >
        {this.props.label && (
          <View style={this.styles.label}>
            <NitroxText
              textType='subhead-bold'
              style={this.styles.title}
            >
              {this.state.labelValue}
            </NitroxText>
          </View>
        )}
      </View>
    );
  }
}

export default EpgLine;
