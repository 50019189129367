import {StyleProp, ViewStyle} from 'react-native';

import {getValue} from 'common/constants';

export type ArrowSelectorProps = {
  style?: StyleProp<ViewStyle>;
  textFieldWidth?: number;
  availableValues: number[],
  initialValue?: number,
  suffix?: string,
  onValueChange: (value: number) => void,
  disabled?: boolean
};

export type ArrowSelectorComponentProps = {
  currentValue: number,
  currentIndex: number,
  displayValue: string,
  arrowLeftDisabled: boolean,
  arrowRightDisabled: boolean,
  onArrowPressLeft: () => void,
  onArrowPressRight: () => void,
} & ArrowSelectorProps;

export const arrowSelector = {
  height: getValue({mobile: 30, defaultValue: 50}),
  arrowSize: 20
};
