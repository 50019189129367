import {createStyles} from 'common-styles';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {dimensions, isBigScreen} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxText from 'components/NitroxText';
import Popup, {PopupAction} from 'components/Popup';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  description: {
    marginBottom: dimensions.margins.medium,
    color: colors.popup.text
  },
  menuButton: isBigScreen ? {width: 250} : {maxWidth: '80%', minWidth: 120}
}));

type OfflinePopupProps = {
  visible: boolean;
  onTryAgain: () => void;
  onClose: () => void;
}

const actions = [PopupAction.POSITIVE, PopupAction.NEGATIVE];

const OfflinePopup: React.FunctionComponent<OfflinePopupProps> = props => {
  const {t} = useTranslation();
  const {onTryAgain, visible, onClose} = props;
  const styles = stylesUpdater.getStyles();

  return (
    <Popup
      menuButtonStyle={styles.menuButton}
      actions={actions}
      visible={visible}
      title={t('credentials.connectionProblem')}
      positiveLabel={t('credentials.tryAgain')}
      negativeLabel={t('credentials.cancel')}
      onPositive={onTryAgain}
      onNegative={onClose}
      menuHasPreferredFocus
    >
      <NitroxText style={styles.description} textType='dialog-message'>
        {t('credentials.backOfficeOffline')}
      </NitroxText>
    </Popup>
  );
};

export default React.memo(OfflinePopup);
