import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {dimensions} from 'common/constants';

import {Media} from 'mw/api/Metadata';

import MediaTile from 'components/mediaTiles/MediaTile';
import {Swimlane, SwimlaneTileProps} from 'components/Swimlane';

import {mediaDetailSwimlaneInsets} from './MediaDetailUtils';
import SectionView from './SectionView';

async function* trailersFetcher(trailers: Media[]) {
  return trailers;
}

type Props = {
  items: Media[];
  onStartPlayback: (media: Media) => void;
  onFocusEnter?: () => void;
}

const generateKey = (items: Media[]) => {
  return items.reduce<string>((acc, current) => acc + current.id + current.bookmark, '');
};

const TrailersSection: React.FunctionComponent<Props> = props => {
  const {items, onStartPlayback, onFocusEnter} = props;
  const {t} = useTranslation();

  const renderMediaTile = useCallback((props: SwimlaneTileProps<Media>) => {
    return (
      <MediaTile
        media={props.data}
        onPress={onStartPlayback}
      />
    );
  }, [onStartPlayback]);

  return (
    <SectionView title={t('mediaDetails.trailers')} onFocusEnter={onFocusEnter}>
      <Swimlane<Media>
        dataFetcher={trailersFetcher(items)}
        row={0}
        itemWidth={dimensions.tile.width + 2 * dimensions.margins.small}
        itemHeight={dimensions.tile.height + dimensions.margins.small}
        insets={mediaDetailSwimlaneInsets}
        createTile={renderMediaTile}
        //TODO CL-4028 Dirty hack for swimlane not updating on dataFetcher change
        //please check if still necessary after swimlane refactor
        //please check if bookmarks are updating after creating
        key={generateKey(items)}
        testID='swimlane_trailers'
      />
    </SectionView>
  );
};

export default React.memo(TrailersSection);
