import {isWebOS, isTizen} from 'common/constants';

import {Content} from 'mw/api/Metadata';

import {ContentObject} from './ContentObject';

export class ExternalContentObject extends ContentObject implements Content {
  public isAllowed(): boolean {
    return super.isAllowed() && !(isTizen || isWebOS);
  }
}
