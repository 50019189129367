import {ViewStyle, TextStyle, ImageStyle} from 'react-native';

import {dimensions, isMobile} from 'common/constants';

type Color = string;
export type Styles = {[style: string]: ViewStyle | TextStyle | ImageStyle | Color | Color[]};
export function createStyles<T extends Styles>(object: T) {
  return object;
}

const defaultStyles = createStyles({
  view: {
    flex: 1,
    justifyContent: 'center'
  },
  popup: {
    borderRadius: dimensions.popup.radius,
    padding: dimensions.popup.padding,
    paddingVertical: isMobile ? 0 : dimensions.popup.padding
  }
});

export {defaultStyles};
