import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {View, ScrollView, ViewStyle, StyleProp, TextStyle, Animated} from 'react-native';

import {dimensions, isBigScreen} from 'common/constants';
import {humanCaseToSnakeCase} from 'common/HelperFunctions';
import {TestProps} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Icon, IconType} from 'components/Icon';
import NitroxButton, {NitroxButtonTheme} from 'components/NitroxButton';
import NitroxInteractive from 'components/NitroxInteractive';
import NitroxText, {TextType} from 'components/NitroxText';
import {useTestID} from 'hooks/Hooks';

export const listViewHorizontalSpace = isBigScreen ? dimensions.margins.xxxLarge : dimensions.margins.xxLarge;
const rightIconSize = isBigScreen ? 15 : 10;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    justifyContent: 'center'
  },
  itemContainer: {
    paddingTop: dimensions.margins.medium,
    paddingBottom: dimensions.margins.medium,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  leftIconContainer: {
    justifyContent: 'center',
    marginRight: isBigScreen ? dimensions.margins.xLarge : dimensions.margins.large
  },
  labelContainer: {
    color: colors.listView.item.label
  },
  rightIconContainer: {
    justifyContent: 'center'
  },
  listItemLeftContent: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  sectionTitle: {
    color: colors.settingsScreen.mobile.list.tabletSubtitle,
    marginLeft: listViewHorizontalSpace,
    marginBottom: isBigScreen ? dimensions.margins.medium : undefined
  },
  sectionListItem: {
    paddingHorizontal: listViewHorizontalSpace
  },
  leftIconFocused: colors.listView.item.selected.leftIcon,
  leftIconUnfocused: colors.listView.item.leftIcon,
  rightIcon: colors.listView.item.rightIcon
}));

export type ListItemProps = {
  key: string;
  component?: React.ReactNode;
  leftIcon?: IconType;
  label?: string;
  rightIcon?: IconType;
  onPress?: () => void;
  selected?: boolean;
  hasTVPreferredFocus?: boolean;
  textContainerOpacity?: Animated.Value;
  iconOpacity?: Animated.Value;
  textType?: TextType;
} & TestProps;

export type ListViewProps = {
  containerStyle?: StyleProp<ViewStyle>;
  itemContainerStyle?: StyleProp<ViewStyle>;
  items: ListItemProps[];
  textContainerOpacity?: Animated.Value;
  iconOpacity?: Animated.Value;
};

export const ListItem = (props: ListItemProps & {defaultContainerStyle?: StyleProp<ViewStyle>}): JSX.Element => {
  const {
    key,
    leftIcon,
    label,
    rightIcon,
    onPress,
    defaultContainerStyle,
    selected,
    textType = 'subhead'
  } = props;
  const testID = useTestID(props, 'ListItem') || `list_item_${humanCaseToSnakeCase(label || key)}`;
  const styles = stylesUpdater.getStyles();
  const iconProps = useMemo(() => leftIcon && {
    type: leftIcon,
    size: dimensions.icon.xxsmall,
    color: selected ? styles.leftIconFocused : styles.leftIconUnfocused
  }, [leftIcon, selected, styles.leftIconFocused, styles.leftIconUnfocused]);

  return (
    <>
      {isBigScreen ? (
        <>
          {(leftIcon || label) && (
            <NitroxButton
              theme={NitroxButtonTheme.Tertiary}
              border
              specialIcon
              icon={iconProps}
              onPress={onPress}
              testID={testID}
              isSelected={selected}
              text={label}
            />
          )}
        </>
      ) : (
        <NitroxInteractive
          activeOpacity={isBigScreen ? 1 : undefined}
          onPress={onPress}
          testID={testID}
        >
          <View style={[
            styles.itemContainer,
            // this is defined globally per list, so it has lower precedence that containerStyle defined for item exclusively
            defaultContainerStyle
          ]}
          >
            {(leftIcon || label) && (
              <View style={styles.listItemLeftContent}>
                {leftIcon && (
                  <Animated.View style={[styles.leftIconContainer, {opacity: props.iconOpacity}]}>
                    <Icon
                      type={leftIcon}
                      size={dimensions.icon.xxsmall}
                      color={styles.leftIconUnfocused}
                    />
                  </Animated.View>
                )}
                {label && (
                  <Animated.View style={{opacity: props.textContainerOpacity}}>
                    <NitroxText
                      textType={textType}
                      style={styles.labelContainer}
                      // can't trim line height here, as it breaks centering text for larger fonts
                      trimLine={false}
                    >
                      {label}
                    </NitroxText>
                  </Animated.View>
                )}
              </View>
            )}
            {rightIcon && (
              <View style={styles.rightIconContainer}>
                <Icon type={rightIcon} color={styles.rightIcon} size={rightIconSize} />
              </View>
            )}
          </View>
        </NitroxInteractive>
      )}
    </>
  );
};

const ListView = (props: ListViewProps): JSX.Element => {
  const {items, containerStyle, itemContainerStyle, iconOpacity, textContainerOpacity} = props;
  const styles = stylesUpdater.getStyles();

  const listItems = useMemo(() => (
    items.map(item => {
      // item has already mandatory 'key'
      // eslint-disable-next-line react/jsx-key
      return (item.component || (
        <ListItem
          {...item}
          defaultContainerStyle={itemContainerStyle}
          iconOpacity={iconOpacity}
          textContainerOpacity={textContainerOpacity}
        />
      ));
    })
  ), [items, iconOpacity, itemContainerStyle, textContainerOpacity]);

  return (
    <View style={[styles.container, containerStyle]}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        alwaysBounceVertical={false}
      >
        {listItems}
      </ScrollView>
    </View>
  );
};

export default ListView;

export type ListViewSectionProps = {
  title: string;
  titleStyle?: StyleProp<TextStyle>;
  textType?: TextType;
} & ListViewProps;

export const ListViewSection: React.FC<ListViewSectionProps> = props => {
  const styles = stylesUpdater.getStyles();
  return (
    <View style={props.containerStyle}>
      <Animated.View style={{opacity: props.textContainerOpacity}}>
        <NitroxText
          textType={props.textType ?? 'title2'}
          style={[styles.sectionTitle, props.titleStyle]}
          upperCase={isBigScreen}
        >
          {props.title}
        </NitroxText>
      </Animated.View>
      <ListView {...props} itemContainerStyle={[styles.sectionListItem, props.itemContainerStyle]} />
    </View>
  );
};
