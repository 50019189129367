import {DrmType} from 'mw/Constants';
import {DrmRequest, DrmResponse, DrmDomain} from 'mw/playback/web-native-player/nxpal/DrmInterceptor';

import {DefaultDrmInterceptor} from './DefaultDrmInterceptor';

export class WidevineDrmInterceptor extends DefaultDrmInterceptor {
  public constructor() {
    super(DrmType.Widevine);
  }

  public onDrmRequest(): DrmRequest {
    return {
      keySystemOptions: [{
        name: DrmDomain.Widevine,
        options: {
          serverURL: this.drmProperties?.widevineLicenseUrl,
          videoRobustness: 'SW_SECURE_CRYPTO',
          audioRobustness: 'SW_SECURE_CRYPTO',
          httpRequestHeaders: this.drmHeaders ?? null
        }
      }]
    };
  }

  public onDrmResponse(response: DrmResponse): void {}
}
