import {copyMap} from 'common/HelperFunctions';

export type SelectionItemType = {
  id: string;
};

export class Selection<ItemType extends SelectionItemType> {
  private allSelected = false;
  private selectedItemsMap: Map<string, ItemType> = new Map<string, ItemType>();
  private deselectedItemsMap: Map<string, ItemType> = new Map<string, ItemType>();

  /** Manually marks the given item as selected or deselected depending if all items have been virtually selected. */
  public select(item: ItemType, selected: boolean) {
    const itemsMap = this.allSelected ? this.deselectedItemsMap : this.selectedItemsMap;
    if ((!this.allSelected && selected) || (this.allSelected && !selected)) {
      itemsMap.set(item.id, item);
    } else {
      itemsMap.delete(item.id);
    }
    return this;
  }

  /** Virtually selects all items. All items manully selected should be excluded from the final set. */
  public selectAll(selected: boolean) {
    const itemsMap = this.allSelected ? this.deselectedItemsMap : this.selectedItemsMap;
    this.allSelected = selected;
    itemsMap.clear();
    return this;
  }

  /** Returns true if all items are virtually selected. */
  public areAllSelected() {
    return this.allSelected;
  }

  /** Clear the state of the selection object. */
  public clear() {
    this.allSelected = false;
    this.selectedItemsMap.clear();
    this.deselectedItemsMap.clear();
    return this;
  }

  /** Returns the number of selected items. When all items are virtually selected the returned size is Infinity because at the time the selection is taking place there is no way of knowing this. */
  public getSelectedSize() {
    return this.allSelected ? Infinity : this.selectedItemsMap.size;
  }

  public hasSelected() {
    return this.getSelectedSize() > 0;
  }

  public getDeselectedSize() {
    return this.deselectedItemsMap.size;
  }

  /** Checks if the given item is selected. */
  public isSelected(item: ItemType) {
    return this.allSelected
      ? !this.deselectedItemsMap.has(item.id)
      : this.selectedItemsMap.has(item.id);
  }

  /** Removes items that have been manully deselected from the given array of items. */
  public filterDeselectedItems(items: ItemType[]): ItemType[] {
    return items.filter((item) => !this.deselectedItemsMap.has(item.id));
  }

  /** Returns manually selected items. */
  public getSelectedItems() {
    const items: ItemType[] = [];
    this.selectedItemsMap.forEach((item: ItemType, id: string) => {
      items.push(item);
    });
    return items;
  }

  public clone() {
    const cloned = new Selection<ItemType>();
    cloned.allSelected = this.allSelected;
    copyMap(cloned.selectedItemsMap, this.selectedItemsMap);
    copyMap(cloned.deselectedItemsMap, this.deselectedItemsMap);
    return cloned;
  }
}

/** Determines whether all of the items are selected given the incoming collection of possible infinite number of items and a selection object. */
export function hasAll<ItemType extends SelectionItemType>(selection: Selection<ItemType>, items: ItemType[], hasMoreItems = false) {
  return (selection.areAllSelected() && selection.getDeselectedSize() === 0)
    || (!selection.areAllSelected() && !hasMoreItems && items.length === selection.getSelectedSize());
}

/** Determines whether none of the items are selected given the incoming collection of possible infinite number of items and a selection object. */
export function hasNone<ItemType extends SelectionItemType>(selection: Selection<ItemType>, items: ItemType[], hasMoreItems = false) {
  return (selection.areAllSelected() && !hasMoreItems && selection.getDeselectedSize() === items.length)
    || (!selection.areAllSelected() && selection.getSelectedSize() === 0);
}

/** Determines if there is at least one item selected given the incoming collection of possible infinite number of items and a selection object. */
export function hasAny<ItemType extends SelectionItemType>(selection: Selection<ItemType>, items: ItemType[], hasMoreItems = false) {
  return !hasNone(selection, items, hasMoreItems);
}
