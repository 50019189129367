import i18next from 'i18next';
import React, {useState, useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {isMobile, isBigScreen, dimensions} from 'common/constants';
import {getLanguageDictionaryKey} from 'common/LanguageHelperFunctions';

import {mw} from 'mw/MW';

import {IconType} from 'components/Icon';
import {ModalSelect} from 'components/ModalSelect';
import SelectionPopup from 'components/SelectionPopup';
import {useChangeEffect, useToggle} from 'hooks/Hooks';
import i18n from 'locales/i18n';
import {useLocalized} from 'locales/i18nUtils';

import SettingsClickableOption from './SettingsClickableOption';

type LanguageType = 'uiLanguages' | 'audioLanguages' | 'subtitlesLanguages'

function getUITranslations(mwLanguages: string[]) {
  const translations = Object.keys(i18n.options.resources || {});
  return mwLanguages.filter(l => translations.includes(l));
}

function getLanguagesData(t: i18next.TFunction, type: LanguageType) {
  let title: string;
  let description: string;
  let languages: string[];
  switch (type) {
    case 'audioLanguages':
      title = t('settings.audio'),
      description = t('settings.chooseAudioLanguage'),
      languages = ['auto', ...mw.configuration.audioLanguages];
      break;
    case 'subtitlesLanguages':
      title = t('settings.subtitles'),
      description = t('settings.chooseSubtitlesLanguage'),
      languages = ['off', ...mw.configuration.subtitlesLanguages];
      break;
    default:
      title = t('settings.interface'),
      description = t('settings.chooseUILanguage'),
      languages = getUITranslations(mw.configuration.uiLanguages);
  }
  return {title, description, languages};
}

type SettingsChangeLanguage = {
  initialValue: string;
  type: LanguageType;
  onLanguageChanged: (language: string) => void;
}

const SettingsChangeLanguage: React.FC<SettingsChangeLanguage> = props => {
  const {
    initialValue,
    type,
    onLanguageChanged: propsOnLanguageChanged
  } = props;
  const {t} = useTranslation();
  const {toUpperCase} = useLocalized();
  const [isPickerVisible, {on: showPicker, off: hidePicker}] = useToggle(false);

  const [currentLanguage, setLanguage] = useState(initialValue);
  const {title, description, languages} = useMemo(() => getLanguagesData(t, type), [t, type]);
  const currentLabel = useMemo(
    () => {
      const label = getLanguageDictionaryKey(currentLanguage);
      return t(label, {defaultValue: label});
    },
    [currentLanguage, t]
  );

  useChangeEffect(() => {
    propsOnLanguageChanged(currentLanguage);
  }, [currentLanguage], [propsOnLanguageChanged]);

  const onLanguageChanged = useCallback((language: string) => {
    setLanguage(language);
    hidePicker();
  }, [hidePicker]);

  const options = useMemo(
    () => languages.map(language => {
      const label = getLanguageDictionaryKey(language);
      const translatedLabel = t(label, {defaultValue: label});
      return {
        value: language,
        key: language,
        label: translatedLabel, // INFO: label is used in modalselect (mobile)
        text: toUpperCase(translatedLabel), // INFO: text is used in selectionpopup (bigscreen)
        onPress: () => onLanguageChanged(language)
      };
    }), [languages, onLanguageChanged, toUpperCase, t]);

  return (
    <>
      <SettingsClickableOption
        title={title}
        description={description}
        type={props.type}
        buttonLabel={currentLabel}
        onPress={showPicker}
      />
      {isMobile && (
        <ModalSelect
          title={title}
          options={options}
          defaultValue={currentLanguage}
          visible={isPickerVisible}
          onChange={onLanguageChanged}
          onClose={hidePicker}
          selectedOptionIcon={IconType.Check}
        />
      )}
      {isBigScreen && (
        <SelectionPopup
          title={title}
          sections={[{options}]}
          visible={isPickerVisible}
          onClose={hidePicker}
          focusedKey={currentLanguage}
          buttonWidth={isBigScreen ? dimensions.popup.button.language.minWidth : undefined}
        />
      )}
    </>
  );
};

export default SettingsChangeLanguage;
