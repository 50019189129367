import {createStyles} from 'common-styles';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {isBigScreen} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import PurchaseSuccess from 'components/payments/WizardSteps/PurchaseSuccess';
import Popup, {PopupAction} from 'components/Popup';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    backgroundColor: colors.payments.wizard.background
  },
  menu: {
    ...isBigScreen && {backgroundColor: colors.payments.wizard.menuBackground},
    justifyContent: 'space-evenly'
  }
}));

type Props = {
  productTitle: string;
  onPositive: () => void;
  onClose: () => void;
  visible: boolean;
};

const actions = [PopupAction.NEGATIVE, PopupAction.POSITIVE];

const SummaryPurchasePopup: React.FunctionComponent<Props> = props => {
  const {
    productTitle,
    onPositive,
    onClose,
    visible
  } = props;

  const {t} = useTranslation();
  const dynamicStyle = stylesUpdater.getStyles();

  return (
    <Popup
      key='SummaryPopup'
      visible={visible}
      actions={actions}
      containerStyle={dynamicStyle.container}
      menuStyle={dynamicStyle.menu}
      menuHasPreferredFocus
      positiveLabel={t('common.watch')}
      onPositive={onPositive}
      negativeLabel={t('common.close')}
      onNegative={onClose}
      onModalClose={onClose}
    >
      <PurchaseSuccess
        productTitle={productTitle}
      />
    </Popup>
  );
};

export default SummaryPurchasePopup;
