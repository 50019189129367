import moment from 'moment';

import {isWeb} from 'common/constants';
import {DateUtils} from 'common/DateUtils';
import {NavigationFocusState} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {Channel, Event} from 'mw/api/Metadata';
import {nxffConfig} from 'mw/api/NXFF';
import {mw} from 'mw/MW';

import {PlayerViews} from 'components/player/PlayerView';
import {TopLevelPortalType} from 'components/TopLevelPortalManager';

import {EpgScreenPlayerViewLocation, PlayerView} from './EpgHelperTypes';

export const channelForEvent = (channels: Channel[], event?: Event): Channel | undefined => {
  const currentMedia = mw.players.main.getCurrentMedia();
  const channelId = (event && event.channelId || currentMedia && currentMedia.id) || '';
  return channels.find(({id}: Channel )=> id === channelId) || channels[0];
};

export const getPlayerView = (playerLocation: EpgScreenPlayerViewLocation): PlayerView => {
  switch (playerLocation) {
    case 'epgEmbedded':
    case 'none':
      return PlayerViews.Epg;
    case TopLevelPortalType.Fullscreen:
      return PlayerViews.Fullscreen;
    case 'channelDetails':
      return PlayerViews.ChannelDetails;
  }
};

export const onPipPlayerViewReady = (playerLocation: EpgScreenPlayerViewLocation, focusState: NavigationFocusState, TAG: string) => {
  Log.debug(TAG, 'PIP player view is ready');
  // (CL-2473) - On web player view is being changed by tuning, as switchPlayerView is not yet implemented
  // TODO (CL-1433) - Remove isWeb after switchPlayerView is implemented
  if (isWeb || focusState !== NavigationFocusState.IsFocused) {
    return;
  }
  mw.players.main.switchPlayerView(getPlayerView(playerLocation))
    .catch((error: any) => {
      Log.error(TAG, 'onPipPlayerViewReady', 'Failed to switchPlayerView', error);
    });
};

export function getEpgDates() {
  const {EPGFutureRange, EPGPastRange} = nxffConfig.getConfig().EPG;
  const epgDates: Date[] = [];
  const date = new Date();

  [...new Array(EPGPastRange)].forEach((_, index) => {
    return epgDates.push(new Date(date.getFullYear(), date.getMonth(), index + date.getDate() - EPGPastRange, 0, 0, 0, 0));
  });

  epgDates.push(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));

  [...new Array(EPGFutureRange)].map((_, index) => {
    return epgDates.push(new Date(date.getFullYear(), date.getMonth(), index + date.getDate() + 1, 0, 0, 0, 0));
  });

  return epgDates;
}

export const getEpgBoundaries = (): [Date, Date] => {
  const pickerDates = getEpgDates();
  const epgBeginTime = DateUtils.startOfHalfHour(pickerDates[0]);
  const epgEndOfDay = moment(pickerDates[pickerDates.length - 1]).endOf('day').toDate();
  const epgEndTime = DateUtils.startOfHalfHour(epgEndOfDay);

  return [epgBeginTime, epgEndTime];
};
