import {createStyles} from 'common-styles';
import i18next from 'i18next';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View, ViewStyle, StyleProp} from 'react-native';

import {dimensions} from 'common/constants';
import {formatCurrency} from 'common/utils';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxText from 'components/NitroxText';
import NumberedList from 'components/payments/NumberedList';
import {formatRentalDuration} from 'components/payments/PaymentHelperFunctions';
import Separator from 'components/Separator';

const numOfLines = {
  title: 1,
  description: 5,
  duration: 1,
  consent: 5
};

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  titleContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  text: {
    color: colors.popup.text,
    marginTop: dimensions.margins.medium,
    textAlign: 'left'
  },
  separator: {
    width: '100%',
    height: 2,
    marginVertical: dimensions.margins.large,
    backgroundColor: colors.payments.order.numberedList.background
  }
}));

type Props = {
  style: StyleProp<ViewStyle>;
  title: string;
  price: number;
  currency: string;
  description?: string;
  duration?: number;
  assets: string[];
};

const MakeOrder: React.FunctionComponent<Props> = props => {
  const {
    style,
    title,
    price,
    currency,
    description = '',
    duration = 0,
    assets
  } = props;

  const {t, i18n} = useTranslation();
  const dynamicStyle = stylesUpdater.getStyles();
  const formatDuration = (duration: number, t: i18next.TFunction) => `• ${formatRentalDuration(duration, t)}`;

  return (
    <View style={style}>
      <View style={dynamicStyle.titleContainer}>
        <NitroxText
          textType={'title1'}
          style={dynamicStyle.text}
          numberOfLines={numOfLines.title}
        >
          {title}
        </NitroxText>
        <NitroxText
          textType={'title1'}
          style={dynamicStyle.text}
          numberOfLines={numOfLines.title}
        >
          {formatCurrency(price, currency, i18n.language)}
        </NitroxText>
      </View>
      <NitroxText
        textType={'callout'}
        style={dynamicStyle.text}
        numberOfLines={numOfLines.description}
      >
        {description}
      </NitroxText>
      {duration && (
        <NitroxText
          textType={'callout'}
          style={dynamicStyle.text}
          numberOfLines={numOfLines.duration}
        >
          {formatDuration(duration, t)}
        </NitroxText>
      )}
      <Separator horizontal style={dynamicStyle.separator} />
      <NumberedList
        items={assets}
        header={t('payments.order.assetsList.header')}
      />
      <Separator horizontal style={dynamicStyle.separator} />
      {/* credit card content */}
      {props.children}
      <Separator horizontal style={dynamicStyle.separator} />
      <NitroxText
        textType={'callout'}
        style={dynamicStyle.text}
        numberOfLines={numOfLines.consent}
      >
        {t('payments.dialog.consent')}
      </NitroxText>
    </View>
  );
};

export default MakeOrder;
