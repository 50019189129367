import {createStyles} from 'common-styles';
import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ViewStyle, StyleProp} from 'react-native';

import {dimensions, isBigScreen, isMobile, RADIUS} from 'common/constants';
import {formatCurrency} from 'common/utils';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxInteractive from 'components/NitroxInteractive';
import NitroxText from 'components/NitroxText';

import RadioButton from './RadioButton';

const titleHeight = 62;
const descriptionHeight = 140;
const styles = createStyles({
  titleText: {
    height: titleHeight
  },
  descriptionText: {
    height: descriptionHeight,
    marginTop: dimensions.margins.medium
  },
  marginTop: {
    marginTop: dimensions.margins.medium
  }
});

const numberOfLines = {
  title: 2,
  description: 5,
  price: 1
};

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    width: dimensions.payments.product.tile.width,
    height: dimensions.payments.product.tile.height,
    backgroundColor: colors.payments.product.tile.background,
    paddingHorizontal: dimensions.margins.xxLarge,
    paddingVertical: dimensions.margins.xLarge,
    borderColor: isMobile ? colors.payments.product.border.mobile : colors.payments.product.border.unfocused,
    borderWidth: dimensions.payments.product.tile.borderWidth,
    borderRadius: RADIUS
  },
  containerFocused: {
    borderColor: colors.payments.product.border.focused
  },
  text: {
    color: colors.popup.text,
    textAlign: 'center'
  }
}));

type Props = {
  style: StyleProp<ViewStyle>;
  id: string;
  title: string;
  description: string;
  price: number;
  currency: string;
  disabled?: boolean;
  selected?: boolean;
  focusPriority?: number;
  onPress?: (productId: string) => void;
};

const ProductTile: React.FunctionComponent<Props> = props => {
  const {
    style,
    id,
    title,
    description,
    price,
    currency,
    selected = false,
    disabled = false,
    focusPriority = 0,
    onPress
  } = props;
  const {i18n} = useTranslation();
  const dynamicStyle = stylesUpdater.getStyles();

  const onPressCallback = useCallback(() => {
    onPress?.(id);
  }, [id, onPress]);

  const containerStyle = useMemo(() => {
    if (isMobile && selected) {
      return [dynamicStyle.container, dynamicStyle.containerFocused, style];
    }
    return [dynamicStyle.container, style];
  }, [dynamicStyle.container, dynamicStyle.containerFocused, selected, style]);

  return (
    <NitroxInteractive
      style={containerStyle}
      styleFocused={dynamicStyle.containerFocused}
      disabled={disabled}
      focusPriority={focusPriority}
      onPress={onPressCallback}
      activeOpacity={isBigScreen ? 1 : undefined}
    >
      <NitroxText
        style={[dynamicStyle.text, styles.titleText]}
        textType='paymentProductTitle'
        numberOfLines={numberOfLines.title}
      >
        {title}
      </NitroxText>
      <NitroxText
        style={[dynamicStyle.text, styles.descriptionText]}
        textType='paymentProductDescription'
        numberOfLines={numberOfLines.description}
      >
        {description}
      </NitroxText>
      <NitroxText
        style={[dynamicStyle.text, styles.marginTop]}
        textType='paymentProductPrice'
        numberOfLines={numberOfLines.price}
      >
        {formatCurrency(price, currency, i18n.language)}
      </NitroxText>
      <RadioButton
        style={styles.marginTop}
        selected={selected}
      />
    </NitroxInteractive>
  );
};

export default ProductTile;
