import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {isMobile} from 'common/constants';

import {SelectableBO, nxffConfig} from 'mw/api/NXFF';
import {mw} from 'mw/MW';

import {useDisposableCallback} from 'hooks/Hooks';
import {PostProcessors} from 'locales/i18nPostProcessors';
import {useLocalized} from 'locales/i18nUtils';

import {IconType} from './Icon';
import {ModalSelect} from './ModalSelect';
import SelectionPopup from './SelectionPopup';

export type BackOfficeSelectProps = {
  visible?: boolean;
  onClose?: () => void;
  onBoChange?: () => void;
  logoutOnChange?: boolean;
}

type BackOfficeSelectData = {
  backOffices: SelectableBO[];
  currentBackOfficeCode: string;
  onPress: (backOfficeCode: string) => void;
}

const BackOfficeSelectMobile: React.FC<BackOfficeSelectProps & BackOfficeSelectData> = props => {
  const {backOffices, onPress, visible, onClose, currentBackOfficeCode} = props;
  const {t} = useTranslation();

  const options = useMemo(
    () => backOffices.map(backOffice => ({
      value: backOffice.code,
      key: backOffice.code,
      label: backOffice.name
    })),
    [backOffices]
  );

  const onChange = useCallback(
    (backOfficeCode: string) => backOfficeCode && onPress(backOfficeCode),
    [onPress]
  );

  return (
    <ModalSelect
      options={options}
      defaultValue={currentBackOfficeCode}
      visible={visible}
      onChange={onChange}
      onClose={onClose}
      selectedOptionIcon={IconType.Check}
      title={t('settings.backoffice')}
    />
  );
};

const BackOfficeSelectBigScreen: React.FunctionComponent<BackOfficeSelectProps & BackOfficeSelectData> = props => {
  const {onPress, visible, onClose, backOffices, currentBackOfficeCode} = props;
  const {t} = useTranslation();
  const {toUpperCase} = useLocalized();
  const sections = useMemo(
    () => [{
      options: backOffices.map((backOffice) => ({
        key: backOffice.code,
        text: toUpperCase(backOffice.name),
        onPress: () => onPress(backOffice.code)
      })),
      separator: true
    }],
    [backOffices, onPress, toUpperCase]
  );

  return (
    <SelectionPopup
      sections={sections}
      focusedKey={currentBackOfficeCode}
      title={t('common.backoffice')}
      onClose={onClose}
      visible={visible}
      columnCount={3}
      popupProps={{negativeLabel: t('common.close', {postProcess: PostProcessors.ToUpperCase})}}
    />
  );
};

const SelectComponent = isMobile ? BackOfficeSelectMobile : BackOfficeSelectBigScreen;
const BackOfficeSelect: React.FunctionComponent<BackOfficeSelectProps> = props => {
  const {onClose, onBoChange, ...otherProps} = props;
  const backOffices: SelectableBO[] = useMemo(() => nxffConfig.getSelectableBackOffices(), []);
  const currentBackOfficeCode = mw.bo.getBackOfficeCode();

  const closeHandler = useDisposableCallback(() => onClose?.());
  const onBackOfficeChange = useCallback(async (backOfficeCode: string) => {
    if (backOfficeCode !== currentBackOfficeCode) {
      if (props.logoutOnChange) {
        await mw.bo.logoutAndSaveBackOffice(backOfficeCode);
      } else {
        closeHandler();
        await mw.bo.setBackOffice(backOfficeCode);
      }

      onBoChange && onBoChange();
    } else {
      closeHandler();
    }
  }, [props.logoutOnChange, currentBackOfficeCode, closeHandler, onBoChange]);

  return (
    <SelectComponent
      {...otherProps}
      backOffices={backOffices}
      currentBackOfficeCode={currentBackOfficeCode}
      onPress={onBackOfficeChange}
      onClose={closeHandler}
    />
  );
};

export default BackOfficeSelect;
