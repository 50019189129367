import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Log} from 'common/Log';

import {PurchaseAuthorizationMethod} from 'mw/api/Configuration';
import {Error, ErrorType} from 'mw/api/Error';
import {mw} from 'mw/MW';

import ErrorPopup, {useErrorPopup} from 'components/ErrorPopup';
import PinKeyboard from 'components/pinKeyboard/PinKeyboard';
import {useChangeEffect, useDisposable} from 'hooks/Hooks';

const TAG = 'PurchaseModal';

type PurchaseModalProps = {
  visible?: boolean;
  onClose: () => void;
  onFail?: () => void;
  onSuccess: (pin: string) => void;
}

const PurchaseModal: React.FC<PurchaseModalProps> = ({
  visible,
  onClose,
  onFail,
  onSuccess
}) => {
  const {t} = useTranslation();
  const [title, setTitle] = useState('');
  const {error, showError, onCloseErrorPopup} = useErrorPopup();

  const checkPin = useDisposable((pin: string) => {
    const purchaseAuthorizationMethod = mw.configuration.purchaseAuthorizationMethod;
    switch (purchaseAuthorizationMethod) {
      case PurchaseAuthorizationMethod.MasterPin:
        return mw.customer.mainProfile?.checkPin(pin) ?? Promise.reject(new Error(ErrorType.UnknownError, 'Main profile not found'));
      case PurchaseAuthorizationMethod.PurchasePin:
        return mw.customer.checkPurchasePin(pin);
      default:
        return Promise.reject(new Error(ErrorType.NotSupported, `Unsupported purchase authorization method: ${purchaseAuthorizationMethod}`));
    }
  });

  const validatePurchasePin = useCallback(async (pin: string) => {
    try {
      await checkPin(pin);
      setTitle('');
      onSuccess(pin);
    } catch (error) {
      switch (error.type) {
        case ErrorType.IncorrectPin:
          setTitle(t('purchase.wrongPin'));
          break;
        case ErrorType.NetworkNoConnection:
          Log.error(TAG, 'Unable to validate pin due to lack of network connection', error);
          showError({
            title: t('payments.error.network.title'),
            message: t('payments.error.network.message')
          });
          break;
        default:
          Log.error(TAG, 'Error validating purchase pin', error);
          showError(t('common.unexpectedError'));
          onFail?.();
          break;
      }
    }
  }, [checkPin, onSuccess, t, showError, onFail]);

  useChangeEffect(() => {
    if (visible) {
      setTitle('');
    }
  }, [visible]);

  if (!visible) {
    return null;
  }
  return (
    <>
      {error ? (
        <ErrorPopup
          error={error}
          onClose={onCloseErrorPopup}
        />
      ) : (
        <PinKeyboard
          visible={visible}
          title={title || t('purchase.title')}
          onClose={onClose}
          onSubmit={validatePurchasePin}
        />
      )}
    </>
  );
};

export default PurchaseModal;
