import {createStyles} from 'common-styles';
import React, {useRef, useEffect, createRef, RefObject, useMemo} from 'react';
import {ViewStyle, StyleProp} from 'react-native';

import {dimensions} from 'common/constants';

import {StylesUpdater, useStyles} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {FocusableComponent} from 'components/focusManager/FocusManagerTypes';
import {IconType} from 'components/Icon';
import {ModalSelect} from 'components/ModalSelect';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  icon: {
    color: colors.popup.icon
  }
}));

export type MoreActionsAction = {
  key: string;
  text: string;
  icon?: IconType;
  onPress: () => void;
}

type Props = {
  actions: MoreActionsAction[];
  width?: string | number; // percentage of the screen's width or dips
  height?: string | number;
  visible?: boolean;
  loading?: boolean;
  style?: ViewStyle;
  activityIndicatorStyle?: StyleProp<ViewStyle>;
  onClose?: () => void;
}

const MoreActionsPopup: React.FunctionComponent<Props> = props => {
  const {loading, visible = false, onClose, actions} = props;
  const buttonsRefs = useRef<Map<string, RefObject<FocusableComponent>>>(new Map<string, RefObject<FocusableComponent>>());
  const styles = useStyles(stylesUpdater);

  useEffect(() => {
    for (const action of actions) {
      if (buttonsRefs.current.has(action.key)) {
        continue;
      }
      buttonsRefs.current.set(action.key, createRef());
    }
  }, [actions]);

  const options = useMemo(() => actions.map(action => ({
    value: action.onPress,
    key: action.key,
    label: action.text,
    buttonIcon: action.icon && {
      type: action.icon,
      color: styles.icon.color,
      size: dimensions.icon.xxsmall
    }
  })), [actions, styles.icon.color]);

  return (
    <ModalSelect
      maxRows={8}
      loading={loading}
      options={options}
      onClose={onClose}
      onChange={onPress => onPress()}
      visible={visible}
    />
  );
};

export default React.memo(MoreActionsPopup);
