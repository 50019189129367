import {compactMap} from 'common/HelperFunctions';
import {Log} from 'common/Log';
import {makeArray} from 'common/utils';

import {ErrorType, Error} from 'mw/api/Error';
import {Profile, ProfileConfigurableProperties, PinState} from 'mw/api/Profile';
import {getAlias} from 'mw/bo-proxy/bo/traxis/mappers/MappersHelper';
import {InterfaceMapper, ResultType, Result} from 'mw/common/utils';

const TAG = 'ProfileMapper';

const externalID = 'ExternalId';

interface TraxisNamedProperties {
  IsChildProfile?: string;
  YearOfBirth?: string;
  ProfileAuthentication?: string;
  DateFormat?: string;
  TimeFormat?: string;
  UIColor?: string;
  CCEnabled?: boolean;
  ShowAdultContent?: boolean;
  BlockUnratedEvents?: boolean;
  BlockUnratedMovies?: boolean;
  PCRatings?: string;
}

// Profile class instance properties for those keys are stored in NamedProperties of Profile in Adrenalin
const namedPropertiesKeys = ['isPCEnabled', 'yearOfBirth', 'pinState', 'dateFormat', 'timeFormat', 'uiColor', 'ccEnabled', 'showAdultContent', 'blockUnratedEvents', 'blockUnratedMovies', 'pcRatings'] as const;
type NamedPropertiesKeys = typeof namedPropertiesKeys[number];

export function isStoredInNamedProperties(key: keyof ProfileConfigurableProperties): key is NamedPropertiesKeys {
  return namedPropertiesKeys.includes(key as NamedPropertiesKeys);
}

// type representing part of ProfileConfigurableProperties interface that is stored in NamedProperties in Adrenalin
export type ProfileNamedProperties = Pick<ProfileConfigurableProperties, NamedPropertiesKeys>;

const mappers: InterfaceMapper<TraxisNamedProperties, ProfileNamedProperties> = {
  isPCEnabled: {
    fromPropertyName: 'IsChildProfile',
    map: (IsChildProfile?: string) => !!IsChildProfile && IsChildProfile.toLowerCase() === 'true',
    reverseMap: isPCEnabled => typeof isPCEnabled !== 'undefined' ? `${isPCEnabled}` : undefined
  },
  yearOfBirth: {
    fromPropertyName: 'YearOfBirth',
    map: (YearOfBirth?: string) => YearOfBirth ? Number.parseInt(YearOfBirth, 10) : -1,
    reverseMap: yearOfBirth => yearOfBirth && yearOfBirth > 0 ? `${yearOfBirth}` : undefined
  },
  pinState: {
    fromPropertyName: 'ProfileAuthentication',
    map: (ProfileAuthentication?: PinState, defaultPinState?: PinState) => {
      switch (ProfileAuthentication) {
        case PinState.ProfilePinRequired:
          return PinState.ProfilePinRequired;
        case PinState.ProfilePinNotRequired:
          return PinState.ProfilePinNotRequired;
        default:
          return defaultPinState ?? PinState.ProfilePinNotSet;
      }
    },
    reverseMap: pinState => pinState
  },
  dateFormat: {
    fromPropertyName: 'DateFormat',
    map: (DateFormat?: string) => DateFormat || '',
    reverseMap: dateFormat => dateFormat || undefined
  },
  timeFormat: {
    fromPropertyName: 'TimeFormat',
    map: (TimeFormat?: string) => TimeFormat || '',
    reverseMap: timeFormat => timeFormat || undefined
  },
  uiColor: {
    fromPropertyName: 'UIColor',
    map: (UIColor?: string) => UIColor || '',
    reverseMap: uiColor => uiColor || undefined
  },
  ccEnabled: {
    fromPropertyName: 'CCEnabled',
    map: (ccEnabled?: string) => !!ccEnabled && ccEnabled.toLowerCase() === 'true',
    reverseMap: ccEnabled => typeof ccEnabled !== 'undefined' ? `${ccEnabled}` : undefined
  },
  showAdultContent: {
    fromPropertyName: 'ShowAdultContent',
    map: (showAdultContent?: string) => !!showAdultContent && showAdultContent.toLowerCase() === 'true',
    reverseMap: showAdultContent => typeof showAdultContent !== 'undefined' ? `${showAdultContent}` : undefined
  },
  blockUnratedEvents: {
    fromPropertyName: 'BlockUnratedEvents',
    map: (blockUnratedEvents?: string) => !!blockUnratedEvents && blockUnratedEvents.toLowerCase() === 'true',
    reverseMap: blockUnratedEvents => typeof blockUnratedEvents !== 'undefined' ? `${blockUnratedEvents}` : undefined
  },
  blockUnratedMovies: {
    fromPropertyName: 'BlockUnratedMovies',
    map: (blockUnratedMovies?: string) => !!blockUnratedMovies && blockUnratedMovies.toLowerCase() === 'true',
    reverseMap: blockUnratedMovies => typeof blockUnratedMovies !== 'undefined' ? `${blockUnratedMovies}` : undefined
  },
  pcRatings: {
    fromPropertyName: 'PCRatings',
    map: (pcRatings?: string) => {
      if (pcRatings) {
        try {
          return JSON.parse(pcRatings);
        } catch (e) {
          Log.warn(TAG, 'Invalid PCRatings format:', pcRatings);
        }
      }
      return {};
    },
    reverseMap: pcRatings => typeof pcRatings !== 'undefined' ? JSON.stringify(pcRatings) : undefined
  }
};

export class ProfileMapper {
  public static mapProfilePropertiesToTraxisNamedProperties(properties: Partial<ProfileNamedProperties>): TraxisNamedProperties {
    const result: TraxisNamedProperties = {};
    return Object.entries(mappers).reduce((result, [propName, mapper]) => {
      const mappedPropName = mapper.fromPropertyName;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const mappedValue = mapper.reverseMap(properties[propName]);
      if (typeof mappedValue !== 'undefined') {
        result[mappedPropName] = mappedValue;
      }
      return result;
    }, result);
  }

  public static namedPropertiesFromJson(json: any, defaultPinState?: PinState): ProfileNamedProperties {
    const traxisNamedProperties: TraxisNamedProperties = makeArray(json).reduce((result, prop) => {
      const {name: key, Value: value} = prop;
      if (typeof key !== 'undefined' && typeof value !== 'undefined') {
        result[key] = value;
      }
      return result;
    }, {});
    const {IsChildProfile, YearOfBirth, ProfileAuthentication, DateFormat, TimeFormat, UIColor, CCEnabled, ShowAdultContent, BlockUnratedEvents, BlockUnratedMovies, PCRatings} = traxisNamedProperties;
    return {
      isPCEnabled: mappers.isPCEnabled.map(IsChildProfile),
      yearOfBirth: mappers.yearOfBirth.map(YearOfBirth),
      pinState: mappers.pinState.map(ProfileAuthentication, defaultPinState),
      dateFormat: mappers.dateFormat.map(DateFormat),
      timeFormat: mappers.timeFormat.map(TimeFormat),
      uiColor: mappers.uiColor.map(UIColor),
      ccEnabled: mappers.ccEnabled.map(CCEnabled),
      showAdultContent: mappers.showAdultContent.map(ShowAdultContent),
      blockUnratedEvents: mappers.blockUnratedEvents.map(BlockUnratedEvents),
      blockUnratedMovies: mappers.blockUnratedMovies.map(BlockUnratedMovies),
      pcRatings: mappers.pcRatings.map(PCRatings)
    };
  }

  public static mapProfileConfiguration(config: Partial<ProfileConfigurableProperties>) {
    const namedProperties: {[key: string]: any} = {};
    const profileProperties: {[key: string]: any} = {};
    const languagePreferences: {Audio?: string; Subtitle?: string} = {};
    Object.entries(config).forEach(([key, value]) => {
      if (isStoredInNamedProperties(key as keyof ProfileConfigurableProperties)) {
        namedProperties[key] = value;
      } else if (key === 'name') {
        profileProperties['Name'] = value;
      } else if (key === 'pin') {
        profileProperties['Pin'] = value;
      } else if (key === 'uiLanguage') {
        profileProperties['Language'] = value;
      } else if ((key === 'audioLanguage' || key === 'subtitleLanguage') && typeof value === 'string') {
        languagePreferences[key === 'audioLanguage' ? 'Audio' : 'Subtitle'] = value;
      } else {
        Log.error(TAG, `Setting ${key} for profile not implemented!`);
      }
    });
    return {
      namedProperties,
      profileProperties,
      languagePreferences
    };
  }

  public static toProfile(json: any, defaultPinState?: PinState): ResultType<Profile> {
    if (!json || !json.id) {
      Log.error(TAG, 'Could not parse profile from json: ', json);
      return Result.failure(new Error(ErrorType.ParsingError));
    }
    const namedProperties = ProfileMapper.namedPropertiesFromJson(json.NamedProperties && json.NamedProperties.Property, defaultPinState);

    return Result.success(new Profile({
      id: json.id || '',
      externalId: getAlias(json, externalID),
      name: json.Name || '',
      uiLanguage: json.Language || '',
      audioLanguage: makeArray(json.AudioLanguagePreferences ? json.AudioLanguagePreferences.AudioLanguagePreference : [])[0] || '',
      subtitleLanguage: makeArray(json.SubtitleLanguagePreferences ? json.SubtitleLanguagePreferences.SubtitleLanguagePreference : [])[0] || '',
      ...namedProperties
    }));
  }

  public static toProfiles(jsonArray: any[], mainProfileId?: string, mainProfileDefaultPinState?: PinState): ResultType<Profile[]> {
    if (!jsonArray || !Array.isArray(jsonArray)) {
      Log.error(TAG, 'Could not parse profiles from json: ', jsonArray);
      return Result.failure(new Error(ErrorType.ParsingError));
    }
    return Result.success(compactMap(jsonArray, p => {
      const defaultPinState = p.id === mainProfileId ? mainProfileDefaultPinState : undefined;
      return Result.unwrap(ProfileMapper.toProfile(p, defaultPinState));
    }));
  }

  public static profilePinFromJson(json: any): ResultType<string> {
    if (!json || !json.Pin) {
      Log.error(TAG, 'Could not parse profile pin from json: ', json);
      return Result.failure(new Error(ErrorType.ParsingError));
    }
    return Result.success(json.Pin);
  }
}
