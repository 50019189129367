import {createStyles} from 'common-styles';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {dimensions, isBigScreen} from 'common/constants';

import {Media} from 'mw/api/Metadata';

import MediaTile, {MediaTileType} from 'components/mediaTiles/MediaTile';
import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';
import {Swimlane, SwimlaneTileProps} from 'components/Swimlane';

import {mediaDetailSwimlaneInsets} from './MediaDetailUtils';
import SectionView from './SectionView';

const styles = createStyles({
  section: {
    paddingTop: dimensions.margins.xxLarge
  }
});

const preventDefaultOnPressAction = () => {};
const mediaTileWidth = dimensions.tile.width + 2 * dimensions.margins.small;
const mediaTileHeight = dimensions.tile.height + dimensions.margins.small;

async function* fetchEpisodes(episodes: Media[]) {
  return episodes;
}

type Props = {
  episodes: Media[];
  unlockedMediaList: Media[];
  selectedEpisode: Media | null;
  onEpisodeTileFocus: (media: Media) => void;
  onFocusEnter: () => void;
};

const BigScreenEpisodesSection: React.FunctionComponent<Props> = props => {
  const {episodes, onEpisodeTileFocus, selectedEpisode, unlockedMediaList, onFocusEnter} = props;
  const {t} = useTranslation();
  const episodesFetcher = fetchEpisodes(episodes);
  const {isMediaBlocked, shouldBeCheckedForPC} = useParentalControl();

  const renderMediaTile = useCallback((props: SwimlaneTileProps<Media>) => {
    const config = {isBlocked: shouldBeCheckedForPC(props.data) && isMediaBlocked(props.data) && !unlockedMediaList.find((unlockedMedia) => (unlockedMedia.id === props.data.id))};
    const onFocus = () => {
      onEpisodeTileFocus(props.data);
    };

    return (
      <MediaTile
        media={props.data}
        tileType={MediaTileType.Episode}
        onFocus={onFocus}
        onPress={preventDefaultOnPressAction}
        selected={props.data === selectedEpisode}
        config={config}
      />
    );
  }, [isMediaBlocked, onEpisodeTileFocus, selectedEpisode, shouldBeCheckedForPC, unlockedMediaList]);

  return (
    <SectionView title={t('mediaDetails.episodes')} style={styles.section} onFocusEnter={onFocusEnter}>
      <Swimlane<Media>
        itemWidth={mediaTileWidth}
        itemHeight={mediaTileHeight}
        row={0}
        // (CL-4143) This is a workaround for losing focus in the app and flickering swimlane on big screens after invalidating NitroxContentView layout.
        // extraData={selectedEpisode}
        dataFetcher={episodesFetcher}
        createTile={renderMediaTile}
        wrapAround={false}
        insets={mediaDetailSwimlaneInsets}
        fixedFocusPosition={isBigScreen}
        testID='swimlane_episodes'
      />
    </SectionView>
  );
};

export default React.memo(BigScreenEpisodesSection);
