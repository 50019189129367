import Color from 'color';
import {StyleProp, ViewStyle} from 'react-native';

import {Size} from 'common/HelperTypes';

import {Media} from 'mw/api/Metadata';
import {Menu} from 'mw/cms/Menu';

import {Hotspot} from 'components/uxm-components/UMXComponentStack.shared';

export const defaultBannerRotation = 10; // seconds
export const promotionalBannerScrollDuration = 280;
export const fullWidthPromotionalBannerHeightFocused = 1080;
export const framedPromotionalBannerHeight = 470;

export enum PromotionalBannerMode {
  framed = 'framed',
  fullWidth = 'fullWidth',
  desktopFullScreen = 'desktopFullScreen'
}

export type PromotionalBannerProps = {
  dataSource: Menu | Hotspot;
  mode: PromotionalBannerMode;
  style?: StyleProp<ViewStyle>;
  detailsStyle?: StyleProp<ViewStyle>;
  componentHeight?: number;
  componentHeightFocused?: number;
  staticallyFocused?: boolean;
  hasTvPreferredFocus?: boolean;
  gradientColor?: Color;
  progressIndicatorOffset?: number
  imageSize?: Size;
  visible?: boolean;
  expanded?: boolean;
  onFocus?: () => void;
}

export type PromotionalBannerComponentProps = {
  data: {
    media: Media[];
    rotation: number;
  };
} & PromotionalBannerProps;
