import {isDesktopBrowser, isMobileBrowser, isPhone, isSTBBrowser, isTablet, isTizen, isWebOS} from 'common/constants';
import {Log} from 'common/Log';

import {Error, ErrorType} from 'mw/api/Error';
import {asyncStorage} from 'mw/platform/async-storage/AsyncStorage';

import {DeviceInfoInterface} from './DeviceInfoInterface';

const TAG = 'DeviceInfo.web';

const UNIQUE_ID_ASYNC_STORAGE_KEY = `DeviceInfo-uniqueId`;

const unknown = 'unknown';

const isLaunchedInBrowser = isDesktopBrowser || isMobileBrowser || isSTBBrowser;

const isPhoneBrowser = isMobileBrowser && isPhone;
const isTabletBrowser = isMobileBrowser && isTablet;

interface TizenDeviceInfo {
  getModelCode(): string;
  getFirmware(): string;
  getDuid(): string;
}

interface WebOSSystemInfo {
  returnValue: boolean;
  modelName: string;
  sdkVersion: string;
}

interface WebOSDeviceIdInfo {
  idValue: string;
  idType: string;
}

interface WebOSDeviceInfo {
  returnValue: boolean;
  idList: WebOSDeviceIdInfo[];
}

function getWebDeviceType(): string {
  switch (true) {
    case isTizen:
      return 'tizen';
    case isWebOS:
      return 'lgwebos';
    case isPhoneBrowser:
      return 'iphone';
    case isTabletBrowser:
      return 'ipad';
    default:
      return 'web';
  }
}

class DeviceInfo implements DeviceInfoInterface {
  public static instance = new DeviceInfo();

  public readonly deviceType = getWebDeviceType();

  private manufacturer = unknown;
  private platform = unknown;
  private appName = unknown;
  private model = unknown;
  private uniqueId = unknown;

  private version = unknown; // tizen specific
  private sdkVersion = unknown; // webOS specific

  public async initialize() {
    try {
      if (isLaunchedInBrowser) {
        this.manufacturer = navigator.vendor;
        this.platform = navigator.platform;
        this.appName = navigator.appName;
        this.model = `${this.appName}-${this.platform}`;
        await this.initBrowserUniqueId();
      } else if (isTizen) {
        await this.injectScript('$WEBAPIS/webapis/webapis.js', this.setTizenInfo);
      } else if (isWebOS) {
        await this.injectScript('webOSTV.js', this.setWebOSInfo);
      }
    } catch (error) {
      throw new Error(ErrorType.DeviceInfoInitializationError, 'Error initializing deviceInfo: ' + JSON.stringify(error));
    }
  }

  private async initBrowserUniqueId() {
    const uniqueId = await asyncStorage.getSecureItem(UNIQUE_ID_ASYNC_STORAGE_KEY);
    if (uniqueId) {
      this.uniqueId = uniqueId;
    } else {
      this.uniqueId = DeviceInfo.generateBrowserUniqueId();

      await asyncStorage.setSecureItem(UNIQUE_ID_ASYNC_STORAGE_KEY, this.uniqueId);
    }
  }

  private static generateBrowserUniqueId() {
    const hex = (data: number, length: number) => ('0'.repeat(length) + data.toString(16)).substr(-length);
    const r = crypto.getRandomValues(new Uint32Array(6));
    const t = new Date().getTime();

    return [hex(t, 16), hex(r[0], 8), hex(r[1], 4), hex(r[2], 4), hex(r[3], 4), hex(r[4], 8) + hex(r[5], 4)].join('-');
  }

  private injectScript = (name: string, onLoad: () => Promise<void>): Promise<void> => {
    return new Promise((resolve, reject) => {
      const head = document.getElementsByTagName('head').item(0);
      if (!head) {
        reject('Head tag not found - unable to inject script ' + name);
        return;
      }
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = name;
      script.onload = () => {
        onLoad()
          .then(resolve)
          .catch(error => reject('Inject script onLoad failed: ' + JSON.stringify(error)));
      };
      script.onerror = () => reject('Error loading script: ' + name);
      head.appendChild(script);
    });
  };

  private setTizenInfo = (): Promise<void> => {
    // these constants are taken from cordova implementation of tizen device info
    // https://github.com/Samsung/cordova-sectv-tizen/blob/master/cordova-js-src/plugin/device/proxy.js
    this.platform = 'sectv-tizen';
    this.manufacturer = 'Samsung Tizen TV';
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const tvInfo: TizenDeviceInfo = window.webapis && window.webapis.productinfo;
    if (tvInfo) {
      this.model = typeof tvInfo.getModelCode === 'function' ? tvInfo.getModelCode() : unknown;
      this.version = typeof tvInfo.getFirmware === 'function' ? tvInfo.getFirmware() : unknown;
      this.uniqueId = typeof tvInfo.getDuid === 'function' ? tvInfo.getDuid() : unknown;
    }
    return Promise.resolve();
  };

  private setWebOSInfo = (): Promise<void> => {
    this.manufacturer = 'LG';
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const webOS = window.webOS;
      if (!webOS) {
        return Promise.reject('Error getting webOS info object');
      }

      const systemInfoPromise = webOS.service.request('luna://com.webos.service.tv.systemproperty', {
        method: 'getSystemInfo',
        parameters: {
          'keys': ['modelName', 'sdkVersion']
        },
        onComplete: (response: WebOSSystemInfo) => {
          const success = response.returnValue;
          if (success) {
            this.model = response.modelName;
            this.sdkVersion = response.sdkVersion;
          } else {
            Log.error(TAG, 'Error getting webOS system info');
          }
        }
      });

      const deviceInfoPromise = webOS.service.request('luna://com.webos.service.sm', {
        method: 'deviceid/getIDs',
        parameters: {
          'idType': ['LGUDID']
        },
        onComplete: (response: WebOSDeviceInfo) => {
          const success = response.returnValue;
          if (success && response.idList[0]) {
            this.uniqueId = response.idList[0].idValue;
          } else {
            Log.error(TAG, 'Error getting webOS unique device id');
          }
        }
      });

      return new Promise((resolve, reject) => {
        Promise.all([systemInfoPromise, deviceInfoPromise])
          .then(() => resolve())
          .catch(reject);
      });

    } catch (error) {
      return Promise.reject('Error setting webOS device info: ' + JSON.stringify(error));
    }
  };

  public getManufacturer(): string {
    return this.manufacturer;
  }

  public getModel(): string {
    return this.model;
  }

  public getSystemName(): string {
    return this.platform;
  }

  public getSystemVersion(): string {
    return '';
  }

  public isTablet(): boolean {
    return false;
  }

  public isLauncher(): boolean {
    return false;
  }

  public isDVBCapable(): boolean {
    return false;
  }

  public getDeviceName(): string {
    if (isLaunchedInBrowser) return `${this.manufacturer} ${this.appName}-${this.platform}`;
    if (isTizen) return `${this.platform} ${this.model} ${this.version}`;
    if (isWebOS) return `${this.manufacturer} ${this.model} ${this.sdkVersion}`;
    return '';
  }

  public getUniqueId(): string {
    return this.uniqueId;
  }

  public getSerialNumber(): string {
    return '';
  }
}

export const deviceInfo: DeviceInfoInterface = DeviceInfo.instance;
export const populateDeviceInfo = async () => {
  await DeviceInfo.instance.initialize();
};
