import React, {useMemo, useCallback} from 'react';
import {Insets} from 'react-native';

import {isDesktopBrowser} from 'common/constants';
import {Log} from 'common/Log';

import {ComponentType as UXMComponentType, ComponentDataSourceType as UXMComponentDataSourceType} from 'mw/api/CMSInterface';
import {Component as UXMComponent, isDataSourceFilterBased, FilterBasedDataSource} from 'mw/cms/Component';
import {Menu} from 'mw/cms/Menu';

import {AnimatedVerticalStackRowProps} from 'components/AnimatedVerticalStack';
import SwimlaneWithHighlightedBanner from 'components/SwimlaneWithHighlightedBanner';
import VodCategoryNavigator from 'components/vod/categoryNavigator/VodCategoryNavigator';
import PromotionalBanner from 'components/vod/promotionalBanner/PromotionalBanner';
import {PromotionalBannerMode, PromotionalBannerProps} from 'components/vod/promotionalBanner/PromotionalBanner.shared';
import {VodScreenQueryParameters} from 'screens/VodScreenQueryParameters';

import MenuSwimlane from './MenuSwimlane';
import SwimlaneBasedStackRow from './SwimlaneBasedStackRow';
import {Hotspot, UMXStackComponent, CustomComponentType} from './UMXComponentStack.shared';

function isComponentDataSourceFilterBased(component: UXMComponent): component is UXMComponent & {dataSource: FilterBasedDataSource} {
  return isDataSourceFilterBased(component.dataSource);
}

function isComponentDataSourceMenuBased(component: UXMComponent): component is UXMComponent & {dataSource: {type: UXMComponentDataSourceType.Menu}} {
  return component.dataSource.type === UXMComponentDataSourceType.Menu;
}

export type UXMComponentStackRowInterface = {
  onPress: () => void;
  onNavigateLeft: () => void;
  onNavigateRight: () => void;
  onNavigateUp: () => {shouldStay: boolean};
  onNavigateDown: () => {shouldStay: boolean};
};

type Props = {
  debugName: string;
  component: UMXStackComponent;
  onMenuPress?: (menu: Menu) => void;
  insets?: Insets;
  promotionalBannerProps?: Partial<PromotionalBannerProps>;
} & AnimatedVerticalStackRowProps;

const UXMComponentStackRow = React.memo(React.forwardRef<UXMComponentStackRowInterface, Props>(({
  debugName,
  component,
  onLoaded,
  promotionalBannerProps,
  ...stackRowProps
}: Props, ref) => {
  const tag = useMemo(() => `UXMComponentStackRow - ${debugName}`, [debugName]);

  const renderSwimlane = useCallback((component: UXMComponent) => {
    if (!isComponentDataSourceFilterBased(component)) {
      Log.info(tag, 'Incorrect configuration, component type is Swimlane but dataSource is not based on filters.');
      onLoaded(true);
      return null;
    }
    return (
      <SwimlaneBasedStackRow
        key={stackRowProps.index}
        ref={ref}
        component={component}
        onLoaded={onLoaded}
        {...stackRowProps}
      />
    );
  }, [onLoaded, ref, stackRowProps, tag]);

  switch (component.type) {
    case UXMComponentType.AdrenalinNode:
    case UXMComponentType.Custom:
    case UXMComponentType.Filterng:
    case UXMComponentType.Swimlane:
      return renderSwimlane(component);

    case UXMComponentType.SwimlaneWithHighlightedBanner:
      if (isDesktopBrowser) {
        return renderSwimlane(component);
      }
      if (!isComponentDataSourceFilterBased(component)) {
        Log.info(tag, 'Incorrect configuration, component type is SwimlaneWithHighlightedBanner but dataSource is not based on filters.');
        onLoaded(true);
        return null;
      }
      return (
        <SwimlaneWithHighlightedBanner
          key={stackRowProps.index}
          ref={ref}
          component={component}
          onLoaded={onLoaded}
          {...stackRowProps}
        />
      );

    case UXMComponentType.SwimlaneMenu:
      if (!isComponentDataSourceMenuBased(component)) {
        Log.info(tag, 'Incorrect configuration, component type is SwimlaneMenu but dataSource is not a menuSlug.');
        onLoaded(true);
        return null;
      }
      return (
        <MenuSwimlane
          key={stackRowProps.index}
          ref={ref}
          component={component}
          onLoaded={onLoaded}
          {...stackRowProps}
        />
      );

    case UXMComponentType.Hotspot:
      return (
        <PromotionalBanner
          mode={PromotionalBannerMode.framed}
          {...promotionalBannerProps}
          style={[promotionalBannerProps?.style, stackRowProps.layout]}
          dataSource={component as Hotspot}
        />
      );

    case CustomComponentType.breadcrumbs:
      return (
        <VodCategoryNavigator
          {...component.props}
          style={[component.props.style, stackRowProps.layout]}
        />
      );

    case CustomComponentType.queryParameters:
      return (
        <VodScreenQueryParameters
          {...component.props}
          style={[component.props.style, stackRowProps.layout]}
        />
      );

    default:
      Log.info(tag, `Displaying UXM component of type ${component.type} is not supported yet.`);
      onLoaded(true);
      return null;
  }
}));
UXMComponentStackRow.displayName = 'UXMComponentStackRow';

export default UXMComponentStackRow;
