import React, {useState, useRef, useCallback, useMemo, RefObject} from 'react';
import {ViewStyle, Insets} from 'react-native';

import {disposableCallback, DisposableCallback} from 'common/Async';
import {dimensions, getValue, isMobile, isPhone, defaultPageSize} from 'common/constants';
import {asyncIterator} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {ContentQueryParameters} from 'mw/api/CatalogInterface';
import {Filter} from 'mw/api/Filter';
import {Media} from 'mw/api/Metadata';
import {mw} from 'mw/MW';
import {MetaEventsEmitter} from 'mw/utils/MetaEventsEmitter';

import {Grid, GridElementProps} from 'components/Grid';
import GridMediaTile from 'components/GridMediaTile';
import {useDisposableCallback, useForceUpdate, useLazyEffect, useChangeEffect, useLazyRef, getScreenInfo} from 'hooks/Hooks';

import {FocusableComponent} from './focusManager/FocusManagerTypes';
import MediaMoreActionsPopup from './MediaMoreActionsPopup';
import NitroxFlatList from './NitroxFlatList';

export {gridMediaTileMarginHorizontal} from 'components/GridMediaTile';

const TAG = 'MediaGrid';

const columnWrapperStyle = {paddingBottom: getValue({tv: 51, desktopBrowser: 51, defaultValue: 0})};
const appendingResultsActivityIndicatorStyle = isMobile ? {marginVertical: dimensions.margins.large} : {marginTop: -columnWrapperStyle.paddingBottom};
const fetchingResultsActivityIndicatorStyle: ViewStyle = {position: 'absolute', top: isMobile ? -dimensions.margins.xLarge : -dimensions.margins.xxLarge};
const itemWidth = isPhone ? getScreenInfo().size.width - dimensions.margins.small : dimensions.tile.width;

export type MediaGridProps = {
  contentInset?: Insets;
  dataSource: Filter[] | Media[];
  queryOptions?: ContentQueryParameters;
  style?: ViewStyle;
  minimumSpacing?: number;
  maximumSpacing?: number;
  onTileFocus?: (tileIndex: number, data: Media) => void;
  onChangedLastFocusTile?: (reference: RefObject<FocusableComponent | undefined>) => void;
}

function isFilterArray(dataSource: Filter[] | Media[]): dataSource is Filter[] {
  return typeof (dataSource[0] as Filter)?.isSpecial === 'boolean'
    && typeof (dataSource[0] as Filter)?.isPersonal === 'boolean'
    && typeof (dataSource[0] as Filter)?.value === 'string';
}

function createDataFetcher(dataSource: Filter[] | Media[], queryOptions: ContentQueryParameters): AsyncIterableIterator<Media[]> {
  if (isFilterArray(dataSource)) {
    return mw.catalog.getContent(dataSource, queryOptions);
  }
  return asyncIterator(dataSource);
}

const defaultQueryOptions = {pageSize: defaultPageSize};

const MediaGrid: React.FC<MediaGridProps> = ({
  queryOptions = defaultQueryOptions,
  contentInset,
  dataSource,
  style,
  onTileFocus,
  onChangedLastFocusTile,
  minimumSpacing = 0,
  maximumSpacing = isPhone ? 0 : dimensions.margins.large
}) => {
  const [data, setData] = useState<Media[]>([]);
  const [fetching, setFetching] = useState(false);
  const [appending, setAppending] = useState(false);
  const {forceUpdate, forceUpdateState} = useForceUpdate();
  const finishedFetching = useRef(false);
  const [dataFetcher, setDataFetcher] = useState(() => createDataFetcher(dataSource, queryOptions));
  const [selectedMedia, setSelectedMedia] = useState<Media>();
  const flatListRef = useRef<NitroxFlatList<Media>>(null);
  const metaEventsEmitterRef = useLazyRef(() => new MetaEventsEmitter());

  const dataEvents = useMemo(() => {
    const filter = isFilterArray(dataSource) ? dataSource[0] : undefined;
    return metaEventsEmitterRef.current.getDataEventsForFilter(filter);
  }, [dataSource, metaEventsEmitterRef]);

  const onDataChange = useCallback(() => {
    setDataFetcher(createDataFetcher(dataSource, queryOptions));
  }, [dataSource, queryOptions]);

  useLazyEffect(() => {
    const {dataChangeEvent, dataRefreshEvent, dataEventsEmitter} = dataEvents;
    metaEventsEmitterRef.current.addListeners();
    dataEventsEmitter.on(dataChangeEvent, onDataChange);
    dataEventsEmitter.on(dataRefreshEvent, forceUpdate);
    return () => {
      metaEventsEmitterRef.current.removeListeners();
      dataEventsEmitter.off(dataChangeEvent, onDataChange);
      dataEventsEmitter.off(dataRefreshEvent, forceUpdate);
    };
  }, [dataEvents], [onDataChange, forceUpdate]);

  useChangeEffect(() => {
    onDataChange();
  }, [dataSource, queryOptions], [onDataChange]);

  const handlePromise = useDisposableCallback((newData: Media[], appendResults: boolean, finished: boolean, scrollToTop: boolean) => {
    setData(data => appendResults ? data.concat(newData) : newData);
    finishedFetching.current = finished;
    setFetching(false);
    setAppending(false);
    if (scrollToTop && flatListRef.current) {
      flatListRef.current.scrollToOffset({animated: true, offset: 0});
    }
  });

  const handlePromiseRef = useRef<DisposableCallback<(result: IteratorResult<Media[], any>) => void> | undefined>();

  const handleError = useDisposableCallback((error: any) => {
    Log.error(TAG, error);
    setFetching(false);
    setAppending(false);
  });

  const fetchPage = useCallback((appendResults = true, scrollToTop = false) => {
    if (!dataFetcher || fetching || appending || finishedFetching.current) {
      return;
    }
    if (appendResults) {
      setAppending(true);
    } else {
      setFetching(true);
    }
    handlePromiseRef.current = disposableCallback((result: IteratorResult<Media[]>) => handlePromise(result.value || [], appendResults, !!result.done, scrollToTop));
    dataFetcher.next()
      .then(handlePromiseRef.current)
      .catch(handleError);
  }, [dataFetcher, fetching, appending, handleError, handlePromise]);

  const fetchFirstPage = useCallback(() => {
    fetchPage(false, true);
  }, [fetchPage]);

  const fetchNextPage = useCallback(() => {
    fetchPage();
  }, [fetchPage]);

  const openMoreActionsPopup = useCallback((media: Media) => {
    setSelectedMedia(media);
  }, []);

  const closeMoreActionPopup = useCallback(() => {
    setSelectedMedia(undefined);
  }, []);

  useLazyEffect(() => {
    handlePromiseRef.current?.dispose();
    finishedFetching.current = false;
    setData([]);
    setFetching(false);
    setAppending(false);
    fetchFirstPage();
  }, [dataFetcher], [fetchFirstPage]);

  const renderGridTile = useCallback((props: GridElementProps<Media & {id: string}>) => {
    return (
      <GridMediaTile
        {...props}
        onTileFocus={onTileFocus}
        onLastFocusedTileChanged={onChangedLastFocusTile}
        extraData={forceUpdateState}
        onMoreActionPress={openMoreActionsPopup}
      />
    );
  }, [onTileFocus, onChangedLastFocusTile, forceUpdateState, openMoreActionsPopup]);

  return (
    <>
      <Grid<Media>
        flatListRef={flatListRef}
        style={style}
        columnWrapperStyle={columnWrapperStyle}
        data={data}
        extraData={forceUpdateState}
        animatingFetchingActivityIndicator={fetching}
        animatingAppendingActivityIndicator={appending}
        fetchingActivityIndicatorStyle={fetchingResultsActivityIndicatorStyle}
        appendingActivityIndicatorStyle={appendingResultsActivityIndicatorStyle}
        onEndReached={fetchNextPage}
        contentInset={contentInset}
        itemWidth={itemWidth}
        minimumSpacing={minimumSpacing} // MediaTile has margins on its own
        maximumSpacing={maximumSpacing}
        createElement={renderGridTile}
      />
      {isMobile && <MediaMoreActionsPopup media={selectedMedia} onClose={closeMoreActionPopup} />}
    </>
  );
};

export default MediaGrid;
