import {createStyles} from 'common-styles';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Media} from 'mw/api/Metadata';

import RelatedRecommendationsView from './RelatedRecommendationsView';
import SectionView from './SectionView';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  recommendationsContainer: {
    backgroundColor: colors.recommendationsScreen.background
  },
  relatedRecommendations: {
    height: dimensions.tile.height + 2 * dimensions.margins.small
  }
}));

type Props = {
  recommendations: Media[];
};

const BigScreenMediaRecommendationsView: React.FunctionComponent<Props> = props => {
  const {t} = useTranslation();
  const bigScreenStyles = stylesUpdater.getStyles();

  return (
    <SectionView title={t('mediaDetails.relatedMovies')} style={bigScreenStyles.recommendationsContainer}>
      <RelatedRecommendationsView
        style={bigScreenStyles.relatedRecommendations}
        recommendations={props.recommendations}
      />
    </SectionView>
  );
};

export default BigScreenMediaRecommendationsView;
