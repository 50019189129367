import React from 'react';

import {isWeb} from 'common/constants';

import NavigationFocusGuard from 'components/NavigationFocusGuard';
import {useEffectOnce} from 'hooks/Hooks';

let renderedOverrides = 0;
// restore default onwheel whenever possible
// we don't need to override default back if nothing currently uses horizontal swipe gesture
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let defaultWindowOnWheel: any;
if (isWeb) {
  defaultWindowOnWheel = window.onwheel;
}

export function usePreventDefaultWebScroll(): void {
  useEffectOnce(() => {
    const preventDefault = (e?: {preventDefault: () => void}) => e?.preventDefault();
    if (isWeb) {
      // Chrome, Firefox
      // If native back gesture should be disabled only for specific area, then use class '.preventBack` and recursively check for its existence here
      document.body.addEventListener('wheel', preventDefault, {passive: false});
      // Safari
      window.onwheel = function() { return false; };
      renderedOverrides++;
    }

    return () => {
      if (isWeb) {
        document.body.removeEventListener('wheel', preventDefault);

        renderedOverrides--;
        if (renderedOverrides === 0) {
          window.onwheel = defaultWindowOnWheel;
        }
      }
    };
  }, []);
}

const PreventDefaultWebScrollInternal = () => {
  usePreventDefaultWebScroll();
  return null;
};

/**
 * Example usage:
 * <MouseAwareView onHover={setHoveredOn}>
 *   {hovered && <PreventDefaultWebScroll />
 * </MouseAwareView>
 */
export const PreventDefaultWebScroll: React.FC = () => {
  return (
    <NavigationFocusGuard>
      <PreventDefaultWebScrollInternal />
    </NavigationFocusGuard>
  );
};
