export enum NetInfoConnectionType {
  Unknown = 'Unknown',
  None = 'None',
  Cellular = 'Cellular',
  Wifi = 'Wifi',
  Bluetooth = 'Bluetooth',
  Ethernet = 'Ethernet',
  Wimax = 'Wimax',
  Vpn = 'Vpn',
  Other = 'Other'
}

export interface NetInfoState {
  isConnected: boolean;
  type: NetInfoConnectionType;
}

export type Subscription = () => void;
export type NetInfoChangeHandler = (state: NetInfoState) => void;

export interface NetInfoInterface {
  fetch(): Promise<NetInfoState>;
  addEventListener(listener: NetInfoChangeHandler): Subscription;
}
