import Color from 'color';
import dot from 'dot-object';

import {dimensions, isBigScreen, isMobile} from 'common/constants';

import {ColorPalette} from 'brand/ColorTypes';
import colorPalette from 'brand/current/color-palette';

const TAG = 'base-colors';

export const paletteVersion = '4';

export function transparent(color: string, opacity: number) {
  return Color(color).alpha(opacity)
    .toString();
}

export const constColors = {
  transparent: 'transparent'
};

export function createBaseColors(palette: ColorPalette) {
  const popup = isBigScreen ? palette.newUI.popup : palette.popup;
  const defaultColors = {
    screenBackground: isBigScreen ? constColors.transparent : palette.background1,
    button: {
      background: palette.button.secondary.background.focused
    },
    icon: palette.white,
    iconShadow: palette.black,
    spinner: palette.white
  };
  return {
    defaultColors: defaultColors,
    transparent: constColors.transparent,
    backgroundGradient: {
      start: palette.newUI.gradient2.start,
      end: palette.newUI.gradient2.stop
    },
    playerMaskGradient: {
      background: palette.backgroundBlack
    },
    newUi: {
      channelIcon: {
        background: {
          default: palette.newUI.channelIcon.background.default,
          selected: palette.newUI.channelIcon.background.selected,
          focused: palette.newUI.channelIcon.background.focused
        }
      }
    },
    channelIcon: {
      background: {
        default: palette.channelIcon.background.default,
        selected: palette.channelIcon.background.selected,
        focused: palette.channelIcon.background.focused
      }
    },
    channelShortDetails: {
      background: {
        default: constColors.transparent,
        focused: transparent(palette.channelTile.background, dimensions.opacity.xxhigh)
      },
      iconContainer: {
        background: {
          default: constColors.transparent,
          focused: palette.channelTile.iconContainerBackground
        }
      },
      text: palette.channelTile.text,
      channelIcon: {
        background: {
          default: palette.channelTile.channelIcon.background,
          focused: constColors.transparent
        },
        lcn: {
          background: {
            default: palette.channelTile.channelIcon.lcn.default,
            selected: palette.channelTile.channelIcon.lcn.selected,
            focused: constColors.transparent
          },
          text: palette.channelTile.text
        }
      }
    },
    channelNumber: {
      background: palette.epg.active, //FIXME: CL-6989 channel number shouldn't be dependent on epg
      text: palette.label1
    },
    channelList: {
      separator: transparent(palette.white, dimensions.opacity.xlow)
    },
    iconRound: {
      background: palette.background3
    },
    input: {
      background: {
        focused: palette.input.background.focused,
        unfocused: isBigScreen ? transparent(palette.input.background.unfocused, dimensions.opacity.xxlow) : transparent(palette.input.background.unfocused, dimensions.opacity.xlow)
      },
      text: {
        focused: palette.input.text.focused,
        unfocused: palette.input.text.unfocused
      },
      placeholder: {
        focused: transparent(palette.black, dimensions.opacity.xlow),
        unfocused: transparent(palette.white, dimensions.opacity.xlow)
      },
      label: palette.label1
    },
    focusableTextInput: {
      border: {
        focused: palette.label1,
        unfocused: transparent(palette.label1, dimensions.opacity.xlow),
        error: palette.error
      },
      text: {
        focused: palette.label1,
        unfocused: palette.label1
      }
    },
    toggleSwitch: {
      active: isBigScreen ? palette.newUI.toggleSwitch.active : palette.base3,
      notActive: isBigScreen ? palette.newUI.toggleSwitch.notActive : palette.background3,
      knob: palette.button.primary.background.focused,
      icon: palette.button.primary.text.focused,
      focused: palette.button.primary.background.focused
    },
    checkbox: {
      icon: {
        focused: palette.black,
        unfocused: palette.white
      },
      background: defaultColors.screenBackground
    },
    loginScreen: {
      background: defaultColors.screenBackground
    },
    mainMenu: {
      background: palette.menu.background,
      button: {
        background: {
          focused: palette.menu.base
        },
        selectionIndicator: palette.menu.base,
        text: {
          active: palette.menu.text,
          disabled: transparent(palette.menu.text, dimensions.opacity.xxlow)
        },
        icon: {
          focused: palette.menu.text,
          unfocused: palette.menu.text
        },
        notifications: {
          indicator: palette.red
        }
      },
      notificationsPanel: {
        backgroundOverlay: transparent(palette.black, dimensions.opacity.medium),
        text: palette.label1,
        box: {
          title: {
            text: palette.label1
          },
          body: {
            text: {
              focused: transparent(palette.label1, dimensions.opacity.xlow),
              unfocused: transparent(palette.label1, dimensions.opacity.medium)
            }
          },
          icon: {
            background: palette.white
          },
          background: {
            focused: transparent(palette.tile.placeholder.background, dimensions.opacity.medium),
            unfocused: palette.tile.placeholder.background
          }
        }
      }
    },
    registration: {
      accountActivation: {
        title: palette.label1
      }
    },
    searchScreen: {
      background: defaultColors.screenBackground,
      header: palette.label1,
      subcategory: palette.label1,
      icon: palette.label1,
      iconBackground: constColors.transparent,
      history: palette.label1,
      phrase: {
        text: palette.label1
      },
      bigScreen: {
        button: {
          background: constColors.transparent,
          text: palette.label1
        }
      },
      mobile: {
        button: {
          background: palette.background3
        }
      }
    },
    settingsScreen: {
      background: defaultColors.screenBackground,
      transparentBackground: transparent(palette.backgroundBlack, dimensions.opacity.xxlow),
      headerBackground: isMobile ? palette.background2 : constColors.transparent,
      underlineBackground: palette.white,
      title: palette.label1,
      contentText: palette.label1,
      profile: {
        wizard: {
          label: transparent(palette.label1, dimensions.opacity.xhigh),
          sublabel: transparent(palette.label1, dimensions.opacity.medium)
        }
      },
      settingsTextBar: {
        text: palette.white,
        border: palette.white
      },
      bigScreen: {
        profileSelect: {
          name: palette.label1,
          item: {
            name: palette.label1,
            background: palette.background2
          },
          bottomClearance: transparent(palette.black, dimensions.opacity.medium),
          navArrow: {
            background: {
              focused: transparent(palette.white, dimensions.opacity.xxlow)
            }
          },
          background: {
            gradient: palette.newUI.gradient1
          }
        }
      },
      mobile: {
        list: {
          tabletSubtitle: palette.label1
        },
        logout: {
          border: palette.label1,
          text: palette.label1
        },
        profile: {
          name: palette.label1,
          tile: {
            borderColor: palette.white
          },
          switch: {
            text: transparent(palette.button.secondary.text.focused, dimensions.opacity.xhigh)
          },
          edit: {
            background: palette.background1
          },
          colorPicker: {
            background: palette.background3,
            label: palette.label1,
            selectedItem: {
              border: palette.label1,
              icon: palette.icons
            }
          },
          clickableOption: {
            title: palette.label1,
            description: transparent(palette.label1, dimensions.opacity.xlow)
          }
        },
        device: {
          listItem: {
            label: palette.label1,
            icon: palette.icons,
            background: palette.background3,
            operator: palette.base3,
            current: palette.base4
          },
          buttonDescription: transparent(palette.label1, dimensions.opacity.xlow)
        }
      }
    },
    parallelNavigator: {
      column: {
        left: isMobile ? palette.background2 : constColors.transparent,
        right: isMobile ? palette.background1 : constColors.transparent
      }
    },
    progressTracker: {
      step: {
        visited: {
          label: palette.button.primary.text.focused,
          background: palette.button.primary.background.focused
        },
        notVisited: {
          label: palette.button.secondary.text.focused,
          background: transparent(palette.button.primary.background.focused, dimensions.opacity.xlow)
        }
      }
    },
    listView: {
      item: {
        label: transparent(palette.label1, dimensions.opacity.xhigh),
        leftIcon: palette.icons,
        rightIcon: transparent(palette.label1, dimensions.opacity.xlow),
        selected: {
          leftIcon: palette.icons
        },
        focused: {
          background: palette.menu.base
        },
        ...isBigScreen && {
          label: transparent(palette.label1, dimensions.opacity.medium),
          leftIcon: transparent(palette.label1, dimensions.opacity.medium),
          selected: {
            background: palette.background1,
            label: palette.label1,
            leftIcon: palette.label1
          }
        }
      }
    },
    tabBarSelector: {
      bar: {
        title: palette.label1,
        underscore: palette.label1
      }
    },
    tags: {
      live: {
        background: palette.red,
        text: palette.white
      },
      ppv: {
        background: palette.base4,
        text: palette.white
      },
      newRecording: {
        background: palette.base4,
        text: palette.white
      },
      dateTime: {
        background: palette.background5,
        text: palette.white
      },
      pcRating: {
        background: palette.white,
        text: palette.black
      }
    },
    tile: {
      contentText: {
        focused: palette.tile.text.focused,
        unfocused: palette.tile.text.unfocused
      },
      background: {
        selected: palette.tile.background.selected,
        default: palette.tile.background.default
      },
      channelIcon: {
        background: palette.white
      },
      liveLabel: {
        background: palette.red,
        text: palette.white
      },
      poster: {
        placeholder: {
          background: palette.tile.placeholder.background,
          icon: transparent(palette.tile.text.unfocused, dimensions.opacity.xxxlow)
        }
      },
      description: transparent(palette.tile.text.unfocused, dimensions.opacity.xhigh),
      separator: {
        focused: transparent(palette.tile.text.focused, dimensions.opacity.xlow),
        unfocused: transparent(palette.tile.text.unfocused, dimensions.opacity.xlow)
      }
    },
    swimlane: {
      header: palette.label1,
      navArrow: {
        background: palette.backgroundBlack
      }
    },
    tvScreen: {
      background: defaultColors.screenBackground,
      foldableEpg: {
        channelListBackground: palette.background2
      },
      channelNumberInput: palette.channelIcon.text.numberInput,
      clock: palette.white,
      datepicker: {
        background: palette.background2,
        border: transparent(palette.white, dimensions.opacity.xxxlow),
        arrow: palette.label1,
        text: {
          focused: palette.label1,
          unfocused: transparent(palette.label1, dimensions.opacity.xlow)
        }
      },
      navArrow: {
        background: {
          focused: transparent(palette.white, dimensions.opacity.xxxlow)
        }
      },
      overlay: {
        shade: transparent(palette.black, dimensions.opacity.xhigh),
        channelList: {
          gradient: [palette.black, transparent(palette.black, 0)]
        }
      }
    },
    progressBar: {
      default: {
        text: palette.label1,
        progress: {
          current: palette.progress,
          available: transparent(palette.white, 0.6),
          remaining: transparent(palette.white, 0.3)
        },
        dot: {
          background: palette.white,
          border: palette.black
        },
        bubble: {
          text: palette.white,
          background: palette.progress
        }
      },
      chromecast: {
        dot: {
          background: palette.progress
        }
      }
    },
    overlayMediaDetails: {
      text: {
        focused: palette.white,
        unfocused: transparent(palette.white, dimensions.opacity.medium),
        mobile: palette.white
      },
      subtitle: {
        focused: transparent(palette.white, dimensions.opacity.xxhigh),
        unfocused: transparent(palette.white, dimensions.opacity.xlow),
        mobile: transparent(palette.white, dimensions.opacity.xhigh)
      },
      separator: transparent(palette.white, dimensions.opacity.xlow),
      series: {
        separator: palette.base1
      }
    },
    recordingLimitations: {
      text: palette.white,
      background: transparent(palette.white, dimensions.opacity.medium)
    },
    label: {
      error: {
        background: palette.red,
        text: palette.white
      }
    },
    popup: {
      background: popup.background.top,
      backgroundBottom: popup.background.bottom,
      menuBackground: constColors.transparent,
      gradient: [popup.background.top, popup.background.bottom],
      overlay: {
        background: palette.overlayShade,
        backgroundTransparent: transparent(palette.black, 0)
      },
      text: palette.label1,
      icon: palette.label1,
      closeIcon: transparent(palette.label1, dimensions.opacity.xlow),
      buttonSeparator: transparent(palette.label1, dimensions.opacity.xlow),
      subtitle: palette.epg.active //FIXME: CL-6989 popup shouldn't base on epg colors,
    },
    errorPopup: {
      background: palette.error,
      text: palette.label1
    },
    elipseSwitch: {
      background: palette.background1,
      text: palette.label1,
      border: palette.label1,
      separator: transparent(palette.label1, dimensions.opacity.xxxlow)
    },
    epgScreen: {
      background: defaultColors.screenBackground,
      label: palette.label1,
      icon: palette.icons,
      grid: {
        background: palette.background1,
        verticalTimeIndicator: {
          line: palette.red,
          text: palette.white,
          shadeGradient: {
            start: transparent(palette.white, dimensions.opacity.xlow),
            end: constColors.transparent
          }
        },
        bar: {
          background: palette.backgroundBlack,
          text: palette.label1
        },
        tile: {
          active: isBigScreen ? transparent(palette.newUI.epg.unfocused, 0.25) : palette.epg.active,
          focused: isMobile ? palette.epg.active : palette.newUI.epg.focused,
          unfocused: isBigScreen ? transparent(palette.newUI.epg.unfocused, 0.08) : palette.epg.default,
          border: palette.epg.default,
          text: {
            focused: palette.label1,
            unfocused: transparent(palette.label1, dimensions.opacity.high)
          },
          icon: palette.icons,
          recIcon: palette.red,
          failedRecIcon: palette.base5,
          completedRecordingIcon: palette.base4
        },
        channelIcon: {
          active: isBigScreen ? transparent(palette.newUI.epg.unfocused, 0.25) : palette.epg.active,
          unfocused: isBigScreen ? transparent(palette.newUI.epg.unfocused, 0.08) : palette.epg.default
        },
        detailsPopup: {
          background: popup.background.bottom,
          text: palette.label1,
          subtitle: transparent(palette.label1, dimensions.opacity.medium),
          icon: palette.icons
        }
      },
      timeBar: {
        background: {
          past: isBigScreen ? transparent(palette.newUI.epg.timebar.past, 0.5) : palette.epg.past,
          future: isBigScreen ? palette.newUI.epg.timebar.future : palette.epg.default,
          inactive: palette.background2
        },
        border: transparent(palette.white, dimensions.opacity.xlow),
        text: palette.label1
      },
      epgGridTimeBarBubble: {
        background: palette.red,
        text: palette.white
      },
      columnEpg: {
        background: palette.backgroundBlack,
        timeBubble: {
          background: palette.button.primary.background.focused,
          text: palette.button.primary.text.focused
        },
        separator: {
          small: palette.background3,
          big: palette.background3
        }
      },
      topInfoContainer: {
        background: defaultColors.screenBackground
      },
      player: {
        blockedBackground: palette.background3
      }
    },
    recordingsScreen: {
      title: palette.label1,
      background: defaultColors.screenBackground,
      sideMenu: palette.background2,
      quota: {
        descriptionText: transparent(palette.label1, dimensions.opacity.xhigh),
        valueText: palette.label1,
        bar: {
          used: palette.base4,
          remaining: palette.background3,
          low: palette.red
        }
      }
    },
    recommendationsScreen: {
      background: defaultColors.screenBackground
    },
    mediaInfoView: {
      background: defaultColors.screenBackground
    },
    notEntitledLabel: {
      background: palette.background5,
      text: palette.white
    },
    doubleBackground: {
      top: isMobile ? palette.background2 : constColors.transparent,
      bottom: constColors.transparent
    },
    columnTile: { //on mobiles: similar to EpgColumnTile, SearchTile, recording tile
      background: palette.background3,
      time: palette.tile.text.unfocused,
      title: palette.tile.text.unfocused,
      subtitle: transparent(palette.tile.text.unfocused, dimensions.opacity.xhigh),
      moreActionsIcon: transparent(palette.tile.text.unfocused, dimensions.opacity.xlow),
      border: transparent(palette.tile.text.unfocused, dimensions.opacity.xxxlow),
      userIcon: palette.tile.text.unfocused,
      seriesIcon: palette.tile.text.unfocused,
      commonIcons: {
        focused: palette.black,
        unfocused: palette.icons
      },
      liveLabel: {
        background: palette.red,
        text: palette.white
      },
      poster: {
        playIcon: palette.tile.text.unfocused,
        placeholder: {
          icon: transparent(palette.tile.text.unfocused, dimensions.opacity.xxxlow),
          background: palette.tile.placeholder.background
        }
      }
    },
    mediaDetailsScreen: {
      background: isBigScreen ? constColors.transparent : palette.backgroundBlack,
      synopsis: {
        background: transparent(palette.backgroundBlack, dimensions.opacity.xhigh),
        text: palette.label1
      },
      label: palette.label1,
      icon: palette.label1,
      poster: {
        gradientCore: isBigScreen ? palette.backgroundBlack : palette.background1
      },
      buttonsRow: {
        background: isBigScreen ? constColors.transparent : palette.background1,
        separator: transparent(palette.label1, dimensions.opacity.xxxlow)
      },
      content: {
        background: palette.background2
      }
    },
    button: {
      primary: {
        background: {
          focused: palette.button.primary.background.focused,
          unfocused: palette.button.primary.background.unfocused
        },
        text: {
          focused: palette.button.primary.text.focused,
          unfocused: palette.button.primary.text.unfocused
        }
      },
      secondary: {
        background: {
          focused: palette.button.secondary.background.focused,
          unfocused: palette.button.secondary.background.unfocused
        },
        text: {
          focused: palette.button.secondary.text.focused,
          unfocused: palette.button.secondary.text.unfocused
        }
      },
      tertiary: {
        background: {
          focused: palette.button.tertiary.background.focused,
          unfocused: palette.button.tertiary.background.unfocused
        },
        text: {
          focused: palette.button.tertiary.text.focused,
          unfocused: palette.button.tertiary.text.unfocused
        }
      },
      destructive: {
        background: {
          focused: palette.button.destructive.background.focused,
          unfocused: palette.button.destructive.background.unfocused
        },
        text: {
          focused: palette.button.destructive.text.focused,
          unfocused: palette.button.destructive.text.unfocused
        }
      }
    },
    newButton: {
      primary: palette.newUI.button.primary,
      secondary: palette.newUI.button.secondary,
      tertiary: palette.newUI.button.tertiary,
      quaternary: palette.newUI.button.quaternary
    },
    backButton: {
      icon: isBigScreen ? transparent(palette.white, dimensions.opacity.medium) : palette.white
    },
    screenHeader: {
      background: palette.background2,
      title: palette.label1,
      icon: palette.label1
    },
    playerScreen: {
      background: palette.backgroundBlack,
      overlay: palette.overlayShade,
      closeIcon: palette.white,
      playerButton: {
        background: {
          focused: palette.white,
          unfocused: constColors.transparent
        },
        icon: {
          focused: palette.base1,
          unfocused: palette.white,
          disabled: transparent(palette.white, dimensions.opacity.medium)
        },
        border: palette.white,
        tooltip: {
          background: palette.white,
          textColor: palette.base1
        }
      }
    },
    floater: {
      border: palette.white
    },
    vodScreen: {
      background: defaultColors.screenBackground,
      label: palette.label1,
      navigator: {
        background: {
          default: constColors.transparent,
          mobile: palette.background2
        },
        text: {
          focused: palette.label1,
          unfocused: transparent(palette.label1, dimensions.opacity.medium)
        },
        selector: {
          text: palette.label1
        },
        separator: transparent(palette.label1, dimensions.opacity.medium)
      },
      promotionalBanner: {
        background: {
          framed: palette.background6,
          fullWidth: palette.background7
        },
        progressIndicator: {
          active: palette.base3,
          inactive: palette.label1
        },
        details: {
          text: palette.label1
        }
      }
    },
    editButtons: {
      delete: {
        text: palette.button.destructive.text.focused,
        icon: palette.button.destructive.text.focused,
        background: palette.button.destructive.background.focused
      },
      selectAll: {
        text: palette.button.primary.text.focused,
        background: palette.button.primary.background.focused
      },
      cancel: {
        text: palette.button.secondary.text.focused,
        background: palette.button.secondary.background.focused
      }
    },
    selectionMenu: {
      text: {
        active: palette.button.primary.text.unfocused,
        selected: palette.button.primary.text.focused
      }
    },
    pinKeyboard: {
      headerBackground: popup.background.bottom,
      message: transparent(palette.label1, dimensions.opacity.xlow),
      pinPadBackground: popup.background.top,
      cancel: {
        text: palette.label1
      },
      button: {
        text: palette.label1,
        focused: {
          text: palette.button.primary.text.focused,
          background: palette.button.primary.background.focused
        }
      }
    },
    forgotCredentials: {
      text: palette.menu.text,
      background: palette.background1,
      textBackground: palette.background3
    },
    channelDetailsScreen: {
      background: palette.background2,
      events: {
        background: defaultColors.screenBackground
      },
      date: {
        text: palette.label1
      }
    },
    userAvatar: {
      placeholder: {
        background: palette.userAvatar.background.placeholder
      }
    },
    easAlertModal: {
      background: palette.red,
      text: palette.white
    },
    payments: {
      product: {
        tile: {
          background: palette.background2
        },
        radio: {
          outerBackground: transparent(palette.input.background.unfocused, dimensions.opacity.xlow),
          innerBackground: palette.input.background.focused
        },
        border: {
          mobile: transparent(palette.white, dimensions.opacity.xlow),
          selected: palette.white,
          focused: palette.white,
          unfocused: palette.background2
        }
      },
      order: {
        numberedList: {
          background: palette.button.secondary.background.focused
        }
      },
      orderDetails: {
        subtitle: {
          text: transparent(palette.white, 0.8)
        },
        background: palette.background2,
        price: {
          background: palette.background3
        }
      },
      wizard: {
        background: palette.base1,
        menuBackground: palette.background3
      }
    },
    table: {
      backgroundOdd: palette.background2,
      backgroundEven: palette.background3,
      backgroundSuccess: palette.paymentLabels.success,
      backgroundFailure: palette.paymentLabels.failed,
      backgroundPending: palette.paymentLabels.pending,
      backgroundCanceled: palette.paymentLabels.canceled,
      backgroundSeparator: palette.paymentLabels.canceled,
      backgroundPaymentFailed: palette.paymentLabels.paymentFailed,
      backgroundUnpaid: palette.paymentLabels.unpaid,
      text: palette.white
    },
    chromecast: {
      collapseIcon: palette.icons,
      connectedIcon: palette.base4,
      remoteController: {
        container: {
          background: palette.backgroundBlack // use blur when low-end devices KPI will be known
        }
      },
      volumeBar: {
        current: palette.white,
        available: palette.input.background.unfocused
      }
    },
    calendar: {
      backgroundColor: constColors.transparent,
      textColor: palette.button.primary.text.unfocused,
      textColorFocused: palette.button.primary.text.focused,
      separator: palette.background3
    },
    arrowSelector: {
      mobile: {
        background: palette.background3
      },
      bigScreen: {
        icon: {
          default: palette.white,
          focused: palette.black,
          disabled: transparent(palette.white, dimensions.opacity.xlow)
        },
        border: palette.white,
        iconBackground: {
          default: constColors.transparent,
          focused: palette.white
        },
        textBackground: palette.background2
      },
      text: palette.white
    }
  };
}

/**
 * Below code is needed to generate BaseColors type, without necessity of maintaining both:
 * {@link createBaseColors} return object and type itself.
 */
const baseColorsObject = createBaseColors(colorPalette);
export type BaseColors = typeof baseColorsObject;

/**
 * Returns ColorPalette created from object, any missing keys are filled with defaults.
 * If none of the key was supplied by argument object, second value of return array,
 * is set to true.
 *
 * @returns [newColorPalette, isBuiltIn]
 */
export function getColorPaletteFromObject(arg: object): [ColorPalette, boolean] {
  const keys = Object.keys(dot.dot(colorPalette));

  const newColorPalette = {};
  let providedKeysCount = 0;
  keys.forEach((key) => {
    const value = dot.pick(key, arg);
    if (typeof value !== 'string') {
      dot.copy(key, key, colorPalette, newColorPalette);
    } else {
      providedKeysCount++;
      dot.copy(key, key, arg, newColorPalette);
    }
  });

  return [newColorPalette as ColorPalette, !providedKeysCount];
}
