import React from 'react';

import {Event} from 'mw/api/Metadata';

import ContinueWatchingPopup from 'components/ContinueWatchingPopup';

const TAG = 'TuneEventHandler';

type Props = {
};

type State = {
  popupVisible: boolean;
  bookmark: number;
};

class TuneEventHandler extends React.PureComponent<Props, State> {

  private tune?: (position: number) => void;

  public constructor(props: Props) {
    super(props);

    this.state = {
      popupVisible: false,
      bookmark: 0
    };
  }

  public handleTune(event: Event, tune: (position: number) => void) {
    this.tune = tune;
    this.setState({bookmark: event.bookmark});

    if (event.isPartiallyViewed()) {
      this.showPopup();
    } else {
      this.startPlaybackFromBeginning();
    }
  }

  private startPlaybackFromBookmark = () => {
    this.tune?.(this.state.bookmark);
  };

  private startPlaybackFromBeginning = () => {
    this.tune?.(0);
  };

  private showPopup = () => {
    this.setState({popupVisible: true});
  };

  private closePopup = () => {
    this.setState({popupVisible: false});
  };

  public render() {
    return (
      <ContinueWatchingPopup
        visible={this.state.popupVisible}
        bookmark={this.state.bookmark}
        onPositive={this.startPlaybackFromBookmark}
        onNegative={this.startPlaybackFromBeginning}
        onClose={this.closePopup}
      />
    );
  }
}

export default TuneEventHandler;
