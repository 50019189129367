import {encode} from 'base-64';

import {Log} from 'common/Log';

import {ErrorType, Error} from 'mw/api/Error';
import {PlaybackAsset} from 'mw/bo-proxy/BOInterface';
import {ManagedBy} from 'mw/bo-proxy/SSOInterface';

import {selectCustomParameter, Content, CustomParameterType, RemoteAsset, parseString} from './MappersHelper';

const TAG = 'ViuRemoteAssetMapper';

enum ViuCustomParameterType {
  LicenseServer = 'license-server',
  LicenseRequestCustomHeaderName = 'license-request-custom-header-name',
  LicenseRequestCustomHeaderValue = 'license-request-custom-header-data',
  ProviderName = 'provider-name',
  DeviceId = 'device-id',
  ProductId = 'product-id',
  ManagedBy = 'managed-by'
}

function selectCustomParameterValue(content: Content, typeValue: ViuCustomParameterType): string {
  return content.CustomParameters?.CustomParameter.find(customParameter => selectCustomParameter(
    customParameter,
    CustomParameterType.Type,
    typeValue
  ))?.Value ?? '';
}

export function mapViuRemoteAsset(remoteAsset: RemoteAsset): PlaybackAsset | null {
  // Content selection happens on BO side, there should be at most one content
  const content = remoteAsset.Content?.[0];

  if (!content) {
    Log.error(TAG, 'mapViuRemoteAsset', 'missing content for remote asset', remoteAsset);
    throw new Error(ErrorType.PlaybackSessionNoContent);
  }

  return {
    playbackLimitations: {
      allowPause: parseString(remoteAsset.allowPause),
      allowSkipForward: parseString(remoteAsset.allowSkipForward),
      allowSkipBackward: parseString(remoteAsset.allowSkipBackward),
      allowFastForward: parseString(remoteAsset.allowFastForward),
      allowRewind: parseString(remoteAsset.allowRewind)
    },
    url: content.PlaybackUrl,
    ordinal: remoteAsset.ordinal,
    managedBy: ManagedBy.Viu,
    drmAsset: {
      headers: {
        'drm-attributes': createViuDrmAttributes(content)
      }
    }
  };
}

function createViuDrmAttributes(content: Content): string {
  return encode(JSON.stringify({CustomParameters: {
    'license-server': selectCustomParameterValue(content, ViuCustomParameterType.LicenseServer),
    'license-request-custom-header-name': selectCustomParameterValue(content, ViuCustomParameterType.LicenseRequestCustomHeaderName),
    'license-request-custom-header-value': selectCustomParameterValue(content, ViuCustomParameterType.LicenseRequestCustomHeaderValue),
    'provider-name': selectCustomParameterValue(content, ViuCustomParameterType.ProviderName),
    'product-id': selectCustomParameterValue(content, ViuCustomParameterType.ProductId),
    'device-id': selectCustomParameterValue(content, ViuCustomParameterType.DeviceId)
  }}));
}

