import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {StyleProp, ViewStyle} from 'react-native';

import {VodSorting, SortVodBy, VodFilter} from 'mw/api/Metadata';

import FilterSelect, {FilterOption} from 'components/FilterSelect';
import {EnterStrategy} from 'components/focusManager/FocusManagerTypes';
import FocusParent from 'components/FocusParent';
import VodSortOrderSelect from 'components/vod/VodSortOrderSelect';

import {vodCategoryScreenMargin} from './VodScreen.shared';

const staticStyles = createStyles({
  queryParametersContainer: {
    width: '100%',
    flexDirection: 'row'
  },
  sortingContainer: {
    marginLeft: vodCategoryScreenMargin
  },
  filterContainer: {
    marginHorizontal: vodCategoryScreenMargin
  }
});

export type VodScreenQueryParametersProps = {
  shouldDisplayQueryParameters: boolean;
  shouldDisplaySortOptions: boolean,
  isSeenFilterAvailable: boolean,
  supportedSortOptions: SortVodBy[],
  filterOptions: FilterOption<VodFilter>[],
  currentSortOrder?: VodSorting,
  currentFilter?: string,
  enterStrategy?: EnterStrategy,
  style?: StyleProp<ViewStyle>;
  filterContainerStyle?: StyleProp<ViewStyle>;
  onFocusEnter: () => void,
  onSortOrderSelected: (vodSorting?: VodSorting) => void,
  onFilterSelected: (value: VodFilter) => void
}

const VodScreenQueryParametersComponent: React.FC<VodScreenQueryParametersProps> = ({
  shouldDisplayQueryParameters,
  shouldDisplaySortOptions,
  isSeenFilterAvailable,
  supportedSortOptions,
  filterOptions,
  currentSortOrder,
  currentFilter,
  enterStrategy,
  style,
  filterContainerStyle,
  onFocusEnter,
  onSortOrderSelected,
  onFilterSelected
}) => {
  const containerStyle = useMemo(() => [staticStyles.queryParametersContainer, style], [style]);
  const filterStyle = useMemo(() => [staticStyles.filterContainer, filterContainerStyle], [filterContainerStyle]);

  if (!shouldDisplayQueryParameters) {
    return null;
  }
  return (
    <FocusParent
      enterStrategy={enterStrategy}
      debugName='SortNFilter'
      onFocusEnter={onFocusEnter}
      style={containerStyle}
    >
      {shouldDisplaySortOptions && (
        <VodSortOrderSelect
          supportedSortOptions={supportedSortOptions}
          currentSortOrder={currentSortOrder}
          onSortOrderSelected={onSortOrderSelected}
          style={staticStyles.sortingContainer}
        />
      )}
      {isSeenFilterAvailable && (
        <FilterSelect<VodFilter>
          filterOptions={filterOptions}
          currentFilter={currentFilter}
          onFilterSelected={onFilterSelected}
          style={filterStyle}
        />
      )}
    </FocusParent>
  );
};

export const VodScreenQueryParameters = React.memo(VodScreenQueryParametersComponent);
