import React, {useCallback, useMemo, ReactNode} from 'react';
import {ViewStyle, Insets} from 'react-native';

import {dimensions, isMobile} from 'common/constants';

import {Media} from 'mw/api/Metadata';

import {SwimlaneDataProps, swimlaneHeaderHeight, SwimlaneTileProps, AnimatedTileProps, SwimlaneProps, SwimlaneDataFetcherState} from 'components/Swimlane';
import {VisibilityLimit, createLimitedDataFetcher, NamedAction} from 'components/utils/SwimlaneVisibilityLimit';
import {useFunction, useLazyRef} from 'hooks/Hooks';

import {AnimatedSwimlaneInterface, AnimatedSwimlane} from './AnimatedSwimlane';
import {TileRenderer} from './AnimatedSwimlaneTile';
import AnimatedVerticalStack, {AnimatedVerticalStackRowProps} from './AnimatedVerticalStack';

const TAG = 'AnimatedSwimlaneStackBase';

const defaultSwimlaneInsets = {
  top: dimensions.margins.medium,
  right: dimensions.margins.large,
  bottom: isMobile ? dimensions.margins.medium : dimensions.margins.xLarge,
  left: dimensions.margins.small
};

export type SwimlaneComponent<TileData> = {
  title: string;
} & SwimlaneDataProps<TileData>;

export type SetupSwimlaneType<TileData> = Omit<SwimlaneProps<TileData>, 'createTile' | 'createHeaderTile' | 'emptySwimlaneComponent'>

type BaseProps<TileData> = {
  swimlaneItemHeight: number;
  swimlaneComponents: SwimlaneComponent<TileData>[];
  setupSwimlane: (row: number) => SetupSwimlaneType<TileData>;
  createTile: (props: SwimlaneTileProps<TileData>, animatedTileProps?: AnimatedTileProps) => TileRenderer;
  renderFocusedTileFrame?: () => ReactNode;
  createHeaderTile?: (props: SwimlaneTileProps<NamedAction>, animatedTileProps?: AnimatedTileProps) => TileRenderer;
}

type Props<TileData> = {
  onTileFocus?: (tileIndex: number, row: number, data: TileData, options: FocusOptions | undefined, wrapAroundActive: () => boolean) => void;
  onTilePress?: (tileIndex: number, data: Media) => void;
  onHeaderTileFocus?: (headerTileIndex: number, row: number, data: NamedAction) => void;
  fixedFocusPosition?: boolean;
  topInset?: number;
  style?: ViewStyle;
  swimlaneInsets?: Insets;
  visibilityLimits?: VisibilityLimit<number>[];
  onFirstTileMount?: () => void;
  onReady?: () => void;
} & BaseProps<TileData>;

function AnimatedSwimlaneStackBase<TileData>(props: Props<TileData>) {
  const {
    swimlaneInsets: propsSwimlaneInsets,
    onTileFocus,
    onHeaderTileFocus,
    onFirstTileMount,
    topInset = 0,
    swimlaneComponents,
    setupSwimlane,
    createTile,
    renderFocusedTileFrame,
    createHeaderTile,
    swimlaneItemHeight,
    visibilityLimits,
    onReady
  } = props;

  const swimlaneInsets = useFunction(() => {
    return {
      ...defaultSwimlaneInsets,
      ...propsSwimlaneInsets
    };
  });

  const swimlaneHeight = useFunction((): number => {
    const insets = swimlaneInsets();
    return swimlaneHeaderHeight + swimlaneItemHeight + insets.top + insets.bottom;
  });

  const swimlaneRefMap = useLazyRef(() => new Map<number, AnimatedSwimlaneInterface | null>()).current;

  const onPressOK = useFunction((row: number) => {
    swimlaneRefMap.get(row)?.onPress();
  });

  const onNavigateLeft: (row: number) => void = useCallback(row => {
    swimlaneRefMap.get(row)?.scrollLeft?.();
  }, [swimlaneRefMap]);

  const onNavigateRight: (row: number) => void = useCallback(row => {
    swimlaneRefMap.get(row)?.scrollRight?.();
  }, [swimlaneRefMap]);

  const onNavigateUp: (row: number) => {shouldStay: boolean} = useCallback(row => {
    return {
      shouldStay: swimlaneRefMap.get(row)?.navigateUp?.() ?? false
    };
  }, [swimlaneRefMap]);

  const onNavigateDown: (row: number) => {shouldStay: boolean} = useCallback(row => {
    return {
      shouldStay: swimlaneRefMap.get(row)?.navigateDown?.() ?? false
    };
  }, [swimlaneRefMap]);

  const rows = useMemo(() => {
    return swimlaneComponents.map((s, index) => ({
      id: `swimlane_${index}_${s.title}`,
      height: swimlaneHeight()
    }));
  }, [swimlaneComponents, swimlaneHeight]);

  const renderSwimlane = useFunction(({
    index,
    layout,
    focused,
    onLoaded,
    onHover,
    onElementFocus
  }: AnimatedVerticalStackRowProps) => {
    const {
      dataFetcher: swimlaneDataFetcher,
      createDataFetcher: swimlaneCreateDataFetcher,
      onDataFetcherStateChanged: swimlaneOnDataFetcherStateChanged,
      ...swimlaneProps
    } = setupSwimlane(index);
    const dataFetcher = swimlaneDataFetcher && createLimitedDataFetcher(swimlaneDataFetcher, visibilityLimits, index);
    const createDataFetcher = swimlaneCreateDataFetcher && (() => createLimitedDataFetcher(swimlaneCreateDataFetcher(), visibilityLimits, index));
    return (
      /* eslint-disable @typescript-eslint/ban-ts-comment */
      <AnimatedSwimlane<TileData>
        //FIXME: CL-4028 casting component to AnimatedSwimlaneComponent results with missing ref property
        // @ts-ignore
        ref={(ref: AnimatedSwimlaneInterface) => {
          swimlaneRefMap.set(index, ref);
        }}
        key={index}
        style={layout}
        onTileFocus={(tileIndex, row, data, headerActionsVisible) => {
          onTileFocus?.(tileIndex, row, data, undefined, () => false);
          onElementFocus(tileIndex, !headerActionsVisible);
        }}
        onHeaderTileFocus={(tileIndex, row, data) => {
          onHeaderTileFocus?.(tileIndex, row, data);
          onElementFocus(tileIndex, true);
        }}
        onHoverChange={onHover}
        focused={focused}
        onFirstTileMount={onFirstTileMount}
        onSwimlaneEmpty={() => onLoaded(true)}
        createTile={createTile}
        renderFocusedTileFrame={renderFocusedTileFrame}
        createHeaderTile={createHeaderTile}
        dataFetcher={dataFetcher}
        createDataFetcher={createDataFetcher}
        onDataFetcherStateChanged={(state, row) => {
          if (state === SwimlaneDataFetcherState.HasData) {
            onLoaded(false);
          }
          swimlaneOnDataFetcherStateChanged?.(state, row);
        }}
        {...swimlaneProps}
      />
      /* eslint-enable @typescript-eslint/ban-ts-comment */
    );
  });

  return (
    <AnimatedVerticalStack
      topInset={topInset}
      onPressOK={onPressOK}
      onNavigateLeft={onNavigateLeft}
      onNavigateRight={onNavigateRight}
      onNavigateDown={onNavigateDown}
      onNavigateUp={onNavigateUp}
      rows={rows}
      renderRow={renderSwimlane}
      onReady={onReady}
    />
  );
}

export default React.memo(React.forwardRef(AnimatedSwimlaneStackBase)) as typeof AnimatedSwimlaneStackBase;
