import {compactMap} from 'common/HelperFunctions';

import {ParentalControlRating} from 'mw/api/Metadata';
import {filterPCRatings} from 'mw/common/ParentalControl';
import {mw, PCType} from 'mw/MW';

export class RatingsMapper {
  public static fromJSON(json: any): ParentalControlRating[] {
    if (!json?.Rating) {
      return [];
    }

    const parsedRatings: ParentalControlRating[] = compactMap(json.Rating, (json: any) => this.pcRatingFromJson(json));

    switch (mw.configuration.pcPolicy.type) {
      case PCType.RatingsBased:
        return filterPCRatings(parsedRatings);
      default:
      case PCType.AgeBased:
        const highestRating: ParentalControlRating = parsedRatings
          .reduce((prev, current) => prev.minimumAge > current.minimumAge ? prev : current);
        return [highestRating];
    }
  }

  private static pcRatingFromJson(json: any): ParentalControlRating | null {
    let authority: string | null = null;
    if (json.authority) {
      authority = json.authority;
    }

    let minimumAge: number | null = null;
    if (!isNaN(json.inferredMinimumAge)) {
      minimumAge = json.inferredMinimumAge;
    }

    let value: string | null = null;
    if (json.value) {
      value = json.value;
    }

    if (authority && minimumAge != null && value) {
      return {
        authority,
        minimumAge,
        value
      };
    }

    return null;
  }
}
