import {createStyles} from 'common-styles';
import React, {useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {View, ScrollView, ViewStyle, StyleProp} from 'react-native';

import {AppRoutes, featureFlags, isBigScreen, isPhone, isMobile, isTablet} from 'common/constants';
import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {nxffConfig} from 'mw/api/NXFF';
import {PinProtectionLevel} from 'mw/api/PinProtection';
import {mw, PCType} from 'mw/MW';

import {useChromecastExtraBottomPadding} from 'components/chromecast/ChromecastExtraBottomPadding';
import {IconType} from 'components/Icon';
import ListView, {ListViewSection} from 'components/ListView';
import {ListItemProps} from 'components/ListView';
import {useLoggedIn} from 'components/navigation/NavigationHelperFunctions';
import NitroxButton, {NitroxButtonTheme} from 'components/NitroxButton';
import {useCurrentProfile} from 'hooks/Hooks';
import {PostProcessors} from 'locales/i18nPostProcessors';

export {listViewHorizontalSpace as settingsListHorizontalSpace} from 'components/ListView';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flexShrink: 1
  },
  tabBarStyle: {
    alignSelf: 'center'
  },
  mobileList: {
    flex: 1
  },
  mobileListView: {
    flexGrow: 1,
    flexShrink: 1
  },
  logoutButton: {
    alignSelf: isTablet ? 'center' : 'stretch',
    borderColor: colors.settingsScreen.mobile.logout.border,
    height: dimensions.buttons.large,
    marginTop: isPhone ? dimensions.margins.xsmall : dimensions.margins.large,
    marginBottom: dimensions.margins.xxxLarge,
    minWidth: isTablet ? 300 : undefined
  },
  listViewContainer: {
    marginBottom: dimensions.margins.large
  }
}));

type SettingsListProps = {
  activeRoute?: AppRoutes;
  onCustomerIDPress: () => void;
  onAndroidSettingsPress?: () => void;
  onRegisteredDevicesPress: () => void;
  onApplicationVersionPress: () => void;
  onBackOfficeSelectorPress: () => void;
  onInfoPress: () => void;
  onChannelListsPress: () => void;
  onLanguagePress: () => void;
  onTimeAndDatePress: () => void;
  onParentalControlPress: () => void;
  onMyPinPress: () => void;
  onDeleteProfilePress: () => void;
  onLogoutPress: () => void;
  onShareLogsPress?: () => void;
  onMyAccountPress?: (accountSettingsItems: ListItemProps[]) => void;
  onSettingsOrdersPress?: () => void;
  onHelpPress: () => void;
}

type SettingsTabProps = {
  profileSettingsItems: ListItemProps[];
  accountsSettingsItems: ListItemProps[];
  systemSettingsItems?: ListItemProps[];
  logoutSettingsItem: SettingsLogoutButtonProps;
}

const MobileList: React.FC<SettingsTabProps> = ({profileSettingsItems, logoutSettingsItem}) => {
  const styles = stylesUpdater.getStyles();
  const mobileListStyles = useChromecastExtraBottomPadding(styles.mobileList);

  return (
    <View style={mobileListStyles}>
      <ListView items={profileSettingsItems} containerStyle={styles.mobileListView} />
      <SettingsLogoutButton {...logoutSettingsItem} />
    </View>
  );
};

const BigScreenList: React.FC<SettingsTabProps> = props => {
  const {profileSettingsItems, accountsSettingsItems, systemSettingsItems, logoutSettingsItem} = props;
  const {t} = useTranslation();
  const styles = stylesUpdater.getStyles();
  const loggedIn = useLoggedIn();
  const launcherContainerStyling: ViewStyle = mw.configuration.isLauncher ?
    {
      paddingTop: dimensions.margins.xsmall,
      paddingBottom: dimensions.margins.xsmall
    } : {};
  return (
    <View style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false} alwaysBounceVertical={false}>
        {loggedIn && (
          <ListViewSection
            title={t('settings.profile')}
            items={profileSettingsItems}
            itemContainerStyle={launcherContainerStyling}
            containerStyle={styles.listViewContainer}
            textType='side-menu-headline'
          />
        )}
        {loggedIn && (
          <ListViewSection
            title={t('settings.account')}
            items={accountsSettingsItems}
            itemContainerStyle={launcherContainerStyling}
            containerStyle={styles.listViewContainer}
            textType='side-menu-headline'
          />
        )}
        {mw.configuration.isLauncher && systemSettingsItems && (
          <ListViewSection
            title={t('settings.system')}
            items={systemSettingsItems}
            containerStyle={launcherContainerStyling}
          />
        )}
        {loggedIn && <SettingsLogoutButton {...logoutSettingsItem} />}
      </ScrollView>
    </View>
  );
};

type SettingsLogoutButtonProps = {
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
}

export const SettingsLogoutButton: React.FC<SettingsLogoutButtonProps> = ({onPress, style: propStyle}) => {
  const {t} = useTranslation();
  const styles = stylesUpdater.getStyles();
  const style = useMemo(
    () => [styles.logoutButton, propStyle],
    [propStyle, styles.logoutButton]
  );
  return (
    <NitroxButton
      border
      style={style}
      text={t('settings.logout', isBigScreen ? {postProcess: PostProcessors.ToUpperCase} : undefined)}
      theme={NitroxButtonTheme[isBigScreen ? 'Primary' : 'Tertiary']}
      onPress={onPress}
    />
  );
};

const SettingsList: React.FC<SettingsListProps> = props => {
  const {t} = useTranslation();
  const currentProfile = useCurrentProfile();
  const isProtectionLevelProfile = mw.configuration.pinProtectionLevel === PinProtectionLevel.Profile;
  const enabledBOSelection = mw.configuration.enabledBOSelection;
  const {
    activeRoute,
    onCustomerIDPress,
    onAndroidSettingsPress,
    onRegisteredDevicesPress,
    onApplicationVersionPress,
    onBackOfficeSelectorPress,
    onInfoPress,
    onChannelListsPress,
    onLanguagePress,
    onTimeAndDatePress,
    onParentalControlPress,
    onMyPinPress,
    onDeleteProfilePress,
    onLogoutPress,
    onShareLogsPress,
    onMyAccountPress,
    onSettingsOrdersPress,
    onHelpPress
  } = props;

  const systemSettingsItems: ListItemProps[] = useMemo(
    () => [
      {
        key: 'AndroidSettings',
        leftIcon: IconType.SettingsAndroid,
        label: t('settings.androidSettings'),
        rightIcon: IconType.ArrowRight,
        onPress: onAndroidSettingsPress
      }
    ],
    [t, onAndroidSettingsPress]
  );

  const accountsSettingsItems: ListItemProps[] = useMemo(
    () => [
      {
        key: 'Customer',
        leftIcon: IconType.Customer,
        label: t('settings.customer'),
        rightIcon: IconType.ArrowRight,
        onPress: onCustomerIDPress,
        selected: activeRoute === AppRoutes.SettingsCustomer
      },
      ...(isBigScreen && nxffConfig.getConfig().Purchases.VodPurchasesEnabled ?
        [{
          key: 'Orders',
          leftIcon: IconType.Orders,
          label: t('settings.orders.title'),
          rightIcon: IconType.ArrowRight,
          onPress: onSettingsOrdersPress,
          selected: activeRoute === AppRoutes.SettingsOrders || activeRoute === AppRoutes.OrderDetails
        }]
        : []),
      {
        key: 'Devices',
        leftIcon: IconType.Device,
        label: t('settings.registeredDevices'),
        rightIcon: IconType.ArrowRight,
        onPress: onRegisteredDevicesPress,
        selected: activeRoute === AppRoutes.SettingsRegisteredDevices
      },
      {
        key: 'Version',
        leftIcon: IconType.AppVersion,
        label: t('settings.appVersion'),
        rightIcon: IconType.ArrowRight,
        onPress: onApplicationVersionPress,
        selected: activeRoute === AppRoutes.SettingsAppVersion
      },
      ...(enabledBOSelection) ? [{
        key: 'Backoffice',
        leftIcon: IconType.Backoffice,
        label: t('settings.backoffice'),
        rightIcon: IconType.ArrowRight,
        onPress: onBackOfficeSelectorPress,
        selected: activeRoute === AppRoutes.SettingsBackOffice
      }] : [],
      ...(nxffConfig.getConfig().UI.HelpURL ? [{
        key: 'Help',
        leftIcon: IconType.Help,
        label: t('settings.help'),
        rightIcon: IconType.ArrowRight,
        onPress: onHelpPress,
        selected: activeRoute === AppRoutes.SettingsHelp
      }] : [])
    ],
    [t, onCustomerIDPress, activeRoute, onSettingsOrdersPress, onRegisteredDevicesPress, onApplicationVersionPress, enabledBOSelection, onBackOfficeSelectorPress, onHelpPress]
  );

  const openMyAccount = useCallback(() => {
    onMyAccountPress?.(accountsSettingsItems);
  }, [accountsSettingsItems, onMyAccountPress]);

  const shouldRenderLanguageTab = mw.configuration.uiLanguages.length > 1
    || mw.configuration.audioLanguages.length > 1
    || mw.configuration.subtitlesLanguages.length > 1;

  const shouldRenderTimeAndDateTab = mw.configuration.availableTimeFormats.length > 1 || mw.configuration.availableDateFormats.length > 1;

  const profileSettingsItems: ListItemProps[] = useMemo(
    () => [
      {
        key: 'Info',
        leftIcon: IconType.Profile,
        label: t('settings.profileName'),
        rightIcon: IconType.ArrowRight,
        onPress: onInfoPress,
        selected: activeRoute === AppRoutes.SettingsName
      },
      ...(featureFlags.channelLists) ? [{
        key: 'Channel lists',
        leftIcon: IconType.ChannelListSettings,
        label: t('settings.channelLists'),
        rightIcon: IconType.ArrowRight,
        onPress: onChannelListsPress
      }] : [],
      ...(shouldRenderLanguageTab) ? [{
        key: 'Language',
        leftIcon: IconType.Language,
        label: t('settings.language'),
        rightIcon: IconType.ArrowRight,
        onPress: onLanguagePress,
        selected: activeRoute === AppRoutes.SettingsLanguage
      }] : [],
      ...(shouldRenderTimeAndDateTab) ? [{
        key: 'Time & Date',
        leftIcon: IconType.Time,
        label: t('settings.timeAndDate'),
        rightIcon: IconType.ArrowRight,
        onPress: onTimeAndDatePress
      }] : [],
      ...(mw.configuration.pcPolicy.type !== PCType.RatingsBased || mw.configuration.supportedPCRatings.length > 0) ? [{
        key: 'Parental Control',
        leftIcon: IconType.ParentalControlSettings,
        label: t('settings.parentalControl.parentalControl'),
        rightIcon: IconType.ArrowRight,
        onPress: onParentalControlPress,
        selected: activeRoute === AppRoutes.SettingsParentalControl
      }] : [],
      ...(isProtectionLevelProfile || currentProfile?.isMain || mw.configuration.pinProtectionLevel === PinProtectionLevel.Account) ? [{
        key: 'My PIN',
        leftIcon: IconType.Pin,
        label: t('settings.myPin'),
        rightIcon: IconType.ArrowRight,
        onPress: onMyPinPress,
        selected: activeRoute === AppRoutes.SettingsPin
      }] : [],
      ...(currentProfile && !currentProfile.isMain && isMobile) ? [{
        key: 'deleteProfile',
        leftIcon: IconType.Trash,
        label: t('settings.deleteProfile'),
        rightIcon: IconType.ArrowRight,
        onPress: onDeleteProfilePress,
        selected: activeRoute === AppRoutes.SettingsDeleteProfile
      }] : [],
      ...(mw.configuration.isEnabledSharingLogs) ? [{
        key: 'shareLogs',
        leftIcon: IconType.MoreInfo,
        label: t('settings.shareLogs'),
        rightIcon: IconType.ArrowRight,
        onPress: onShareLogsPress
      }] : [],
      ...isMobile ? [{
        key: 'myAccount',
        leftIcon: IconType.Customer,
        label: t('settings.myAccount'),
        rightIcon: IconType.ArrowRight,
        onPress: openMyAccount
      }] : []
    ],
    [t, onInfoPress, activeRoute, onChannelListsPress, shouldRenderLanguageTab, onLanguagePress, shouldRenderTimeAndDateTab, onTimeAndDatePress, onParentalControlPress, isProtectionLevelProfile, currentProfile, onMyPinPress, onDeleteProfilePress, onShareLogsPress, openMyAccount]
  );

  return isMobile ? (
    <MobileList
      profileSettingsItems={profileSettingsItems}
      accountsSettingsItems={accountsSettingsItems}
      logoutSettingsItem={{onPress: onLogoutPress}}
    />
  ) : (
    <BigScreenList
      profileSettingsItems={profileSettingsItems}
      accountsSettingsItems={accountsSettingsItems}
      systemSettingsItems={systemSettingsItems}
      logoutSettingsItem={{onPress: onLogoutPress}}
    />
  );
};

export default SettingsList;
