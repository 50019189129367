export type Color = string;
export type Gradient = {start: Color; stop: Color};

/**
 * As we have to check manually if {@link ColorPalette} object fetched from BO matches the type,
 * when updating {@link ColorPalette} type please update {@link isColorPalette} method as well.
 */
export type ConstColors = {
  /**
   * Used in elements that are going to be white regardless brand. For example:
   * player overlay texts, player icons, channel icon backgrounds, `LIVE` labels
   */
  white: Color;
  /**
   * FIXME: CL-1556
   * Used in ellipse switch only. Hopefully going to be removed.
   * Ellipse switch will use button.primary theme
   */
  black: Color;
};

export const constColors: ConstColors = {
  white: '#FFF',
  black: '#000'
};

export type ColorPalette = {
  /**
   * Used in live labels, recording dot.
   */
  red: Color;
  /**
   * Used in all main texts of application excluding:
   * main menu, buttons, tiles, player overlays.
   */
  label1: Color;
  /**
   * Used for most of icons in application. Excluding:
   * main menu, player overlay
   */
  icons: Color;
  /**
   * Used as main background color in components like:
   * mobile header, big screens background, various screen component backgrounds
   */
  background1: Color;
  /**
   * Used as secondary background color in components like:
   * small screens background, various screen component backgrounds
   */
  background2: Color;
  /**
   * Used as components background, mostly in:
   * mini components like separators, epg tiles, epg time indicator,
   */
  background3: Color;
  /**
   * Used as tertiary background for mobile components only
   */
  background4: Color;
  /**
   * Used as background for components, for example:
   * data label
   */
  background5: Color;
  /**
   * Used as background in:
   * promotional banner (gradient at VOD page).
   */
  background6: Color;
  /**
   * Used as background in:
   * promotional banner (gradient at Home page).
   */
  background7: Color;
  /**
   * Used as background in:
   * epg grid, player screen (under video), and other that are designed to be always black.
   */
  backgroundBlack: Color;
  /**
   * Used in small components like:
   * player icons inner icon, separator of player overlay media details
   */
  base1: Color;
  /**
   * Used in:
   * - old implementation of popup which is going to be replaced //FIXME: CL-1556
   * - shadow around profile icon
   */
  base2: Color;
  /**
   * Used in components like:
   * - toggle switch
  */
  base3: Color;
  /**
   * Used in components like:
   * quota bar, settings current device indicator
   */
  base4: Color;
  /**
   * used for failed recordings
   */
  base5: Color;
  /**
   * Used for various error popups and messages
   */
  error: Color;
  menu: {
    base: Color;
    background: Color;
    text: Color;
  };
  epg: {
    active: Color;
    focused: Color;
    past: Color; //Used in timebar only
    default: Color;
  };
  overlayShade: Color;
  tile: {
    text: {
      focused: Color;
      unfocused: Color;
    };
    background: {
      selected: Color;
      default: Color;
    };
    placeholder: {
      background: Color;
    };
  };
  /**
   * Used for inputs across whole application
   */
  input: {
    background: {
      focused: Color;
      unfocused: Color;
    };
    text: {
      focused: Color;
      unfocused: Color;
    };
  };
  /**
   * Unfocused colors are used mainly on devices with focus. Color focused is used on mobiles by default
   */
  button: {
    primary: {
      background: {
        focused: Color;
        unfocused: Color;
      };
      text: {
        focused: Color;
        unfocused: Color;
      };
    };
    secondary: {
      background: {
        focused: Color;
        unfocused: Color;
      };
      text: {
        focused: Color;
        unfocused: Color;
      };
    };
    tertiary: { //button with border of text color
      background: {
        focused: Color;
        unfocused: Color;
      };
      text: {
        focused: Color;
        unfocused: Color;
      };
    };
    destructive: {
      background: {
        focused: Color;
        unfocused: Color;
      };
      text: {
        focused: Color;
        unfocused: Color;
      };
    };
  };
  popup: {
    background: {
      top: Color;
      bottom: Color;
    };
  };
  progress: Color;
  channelIcon: {
    background: {
      default: Color;
      focused: Color;
      selected: Color;
    };
    text: {
      numberInput: Color;
    };
  };
  userAvatar: {
    background: {
      placeholder: Color;
    };
  };
  paymentLabels: {
    canceled: Color;
    failed: Color;
    paymentFailed: Color;
    pending: Color;
    success: Color;
    unpaid: Color;
  };
  channelTile: {
    background: Color;
    iconContainerBackground: Color;
    text: Color;
    channelIcon: {
      background: Color;
      lcn: {
        default: Color;
        selected: Color;
      }
    }
  },
  newUI: Pick<ColorPalette, 'button' | 'channelIcon' | 'popup' | 'background1'> & {
    button: {
      primary: {
        background: {
          focused: Color,
          unfocused: Color,
          selected: Color
        },
        text: {
          focused: Color,
          unfocused: Color,
          selected: Color
        }
      },
      quaternary: {
        background: {
          focused: {
            start: Color;
            stop: Color;
          },
          unfocused: Color;
        },
        text: {
          focused: Color;
          unfocused: Color;
        }
      }
    },
    /**
     * Used in profile switcher.
     */
    gradient1: Gradient;
    /**
     * Used as an app background.
     */
    gradient2: Gradient;
    epg: {
      unfocused: Color,
      focused: Color,
      timebar: {
        past: Color,
        future: Color
      }
    },
    toggleSwitch: {
      active: Color,
      notActive: Color
    }
  }
} & ConstColors;
