import {createStyles} from 'common-styles';
import moment from 'moment';
import React, {useMemo} from 'react';
import {LayoutChangeEvent, View} from 'react-native';

import {RADIUS, dimensions, eventTileDimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {mw} from 'mw/MW';

import {ChannelIcon, ChannelIconType} from 'components/ChannelIcon';
import {Icon, IconType} from 'components/Icon';
import NitroxInteractive from 'components/NitroxInteractive';
import NitroxText from 'components/NitroxText';
import TileIconsRow, {MediaIcons} from 'components/TileIconsRow';
import {useChannelAvailability} from 'hooks/Hooks';

const eventTileOffset = 10;

const defaultHeight = dimensions.eventTile.height;
const defaultTotalHeight = defaultHeight + eventTileOffset;

const smallHeight = eventTileDimensions.height.mobile;
const smallTotalHeight = smallHeight + eventTileOffset;

const moreActionsSize = dimensions.icon.xxsmall;

const staticStyles = createStyles({
  container: {
    width: '100%',
    flexDirection: 'row',
    paddingHorizontal: dimensions.margins.large,
    justifyContent: 'space-between',
    paddingVertical: dimensions.margins.xsmall
  },
  timeBox: {
    marginRight: dimensions.margins.small,
    borderRadius: RADIUS,
    justifyContent: 'center',
    alignItems: 'center'
  },
  mainBox: {
    flex: 1,
    flexDirection: 'column',
    borderRadius: RADIUS,
    justifyContent: 'space-between',
    overflow: 'hidden'
  },
  mainContainer: {
    flex: 1,
    flexDirection: 'row',
    marginLeft: dimensions.margins.small,
    marginRight: dimensions.margins.small,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  textContainer: {
    flex: 1,
    marginRight: dimensions.margins.small
  },
  progressContainer: {
    flexDirection: 'row',
    height: 3
  },
  tileRow: {
    marginRight: dimensions.margins.small
  },
  moreActionsContainer: {
    width: moreActionsSize,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  unavailableChannel: {
    opacity: 0.2
  }
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  timeText: {
    includeFontPadding: false,
    justifyContent: 'center',
    textAlign: 'center',
    color: colors.columnTile.time
  },
  titleText: {
    includeFontPadding: false,
    color: colors.columnTile.title
  },
  subtitleText: {
    includeFontPadding: false,
    color: colors.columnTile.subtitle
  },
  progressBarActive: {
    backgroundColor: colors.progressBar.default.progress.current
  },
  progressBarPossible: {
    backgroundColor: colors.progressBar.default.progress.remaining
  },
  backgroundStyleColor: colors.columnTile.background,
  moreActionIconColor: colors.columnTile.moreActionsIcon,
  focused: {
    backgroundColor: colors.epgScreen.grid.tile.focused
  },
  notFocused: {
    backgroundColor: colors.epgScreen.grid.tile.unfocused
  }
}));

export type EventTileType = 'default' | 'small';
export type EventTileSize = {
  height: number;
  totalHeight: number;
}

export function useEventTileSize(eventTileType?: EventTileType): EventTileSize {
  return useMemo(() => {
    if (eventTileType === 'small') {
      return {height: smallHeight, totalHeight: smallTotalHeight};
    }
    return {height: defaultHeight, totalHeight: defaultTotalHeight};
  }, [eventTileType]);
}

type Props = {
  mediaId: string;
  use: 'time' | 'picture';
  progress: number;
  title: string;
  subtitle: string;
  showProgress: boolean;
  isSelected: boolean;
  time?: Date;
  channelId: string;
  onActionsPress?: () => void;
  onMoreActionsPress?: () => void;
  mediaIcons: MediaIcons;
  onLayout?: (event: LayoutChangeEvent) => void;
  type?: EventTileType;
};

const EventTile: React.FunctionComponent<Props> = props => {
  const {type = 'default'} = props;
  const isSmall = type === 'small';
  const timeString = useMemo<string>(() => moment(props.time).format(mw.configuration.timeFormat) || '--:--', [props.time]);
  const progressPercent = props.showProgress ? props.progress * 100 + '%' : 0;
  const tileSize = useEventTileSize(type);
  const styles = stylesUpdater.getStyles();
  const backgroundStyle = props.isSelected ? styles.focused : styles.notFocused;
  const viewSizeStyle = {height: tileSize.totalHeight};
  const mainBoxSizeStyle = {height: tileSize.height};
  const timeBoxSizeStyle = {height: tileSize.height, width: tileSize.height};
  const eventTileTestID = props.showProgress ? `live_event_tile` : `event_tile`;

  // touch area extends beyond an existing dimensions of the container but never extends past the parent view bounds
  const moreActionsTouchableArea = useMemo(() => {
    const moreActionsVerticalOffset = Math.floor((tileSize.totalHeight - moreActionsSize) / 2);
    return {
      top: moreActionsVerticalOffset,
      bottom: moreActionsVerticalOffset,
      left: dimensions.margins.small,
      right: dimensions.margins.medium
    };
  }, [tileSize]);

  const isChannelAvailable = useChannelAvailability(props.channelId);
  const channelStyle = useMemo(() => isChannelAvailable ? {} : staticStyles.unavailableChannel, [isChannelAvailable]);

  return (
    <NitroxInteractive onPress={props.onActionsPress} testID={eventTileTestID}>
      <View style={[staticStyles.container, viewSizeStyle]} onLayout={props.onLayout}>
        <View style={[staticStyles.timeBox, backgroundStyle, timeBoxSizeStyle]}>
          {props.use === 'picture' && props.channelId &&
            <ChannelIcon type={ChannelIconType.Epg} channelId={props.channelId} isSelected={props.isSelected} />
          }
          {props.use === 'time' && props.time &&
            <NitroxText textType={props.isSelected ? 'subhead-bold' : 'body'} style={[styles.timeText, channelStyle]}>{timeString}</NitroxText>
          }
        </View>
        <View style={[staticStyles.mainBox, backgroundStyle, mainBoxSizeStyle]}>
          <View style={[staticStyles.mainContainer, channelStyle]}>
            <View style={staticStyles.textContainer}>
              <NitroxText
                testID={'event_tile_title'}
                textType={isSmall ? 'subhead-bold' : 'title1'}
                style={styles.titleText}
                numberOfLines={1}
                ellipsizeMode='tail'
              >
                {props.title}
              </NitroxText>
              {!!props.subtitle && (
                <NitroxText
                  textType={isSmall ? 'callout-small' : 'callout-small-regular'}
                  style={[styles.subtitleText]}
                  numberOfLines={1}
                  ellipsizeMode='tail'
                >
                  {props.subtitle}
                </NitroxText>
              )}
            </View>
            <TileIconsRow mediaIcons={props.mediaIcons} style={staticStyles.tileRow} />
            <NitroxInteractive
              style={staticStyles.moreActionsContainer}
              onPress={props.onMoreActionsPress}
              hitSlop={moreActionsTouchableArea}
              testID='button_more_actions'
            >
              <Icon type={IconType.MoreActions} size={moreActionsSize} color={styles.moreActionIconColor} />
            </NitroxInteractive>
          </View>
          {props.showProgress && (
            <View style={staticStyles.progressContainer}>
              <View style={{...styles.progressBarActive, width: progressPercent}} />
              <View style={{...styles.progressBarPossible, width: '100%'}} />
            </View>
          )}
        </View>
      </View>
    </NitroxInteractive>
  );
};

export default React.memo(EventTile);
