import {createStyles} from 'common-styles';
import i18next from 'i18next';
import React, {useState, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View, ActivityIndicator} from 'react-native';

import {dimensions} from 'common/constants';
import {Log} from 'common/Log';

import {StylesUpdater, useStyles} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Customer} from 'mw/api/Customer';
import {Error as MWError, ErrorType as MWErrorType} from 'mw/api/Error';
import {mw} from 'mw/MW';

import AccountActivatedBigScreenSvg from 'brand/current/web/AccountActivation/AccountActivatedBigScreen.svg';
import AccountActivatedPhoneSvg from 'brand/current/web/AccountActivation/AccountActivatedPhone.svg';
import AccountActivatedTabletSvg from 'brand/current/web/AccountActivation/AccountActivatedTablet.svg';
import {assets} from 'brand/Resources';
import AppBackground from 'components/AppBackground';
import {AspectRatio, Ratio} from 'components/AspectRatio';
import NitroxLogo from 'components/navigation/NitroxLogo';
import NitroxImage from 'components/NitroxImage';
import NitroxText from 'components/NitroxText';
import {useDisposable, useEffectOnce, useScreenInfo} from 'hooks/Hooks';
import {getQueryParams} from 'web/src/router/routerUtils';

const successAssets = {
  phone: AccountActivatedPhoneSvg,
  tablet: AccountActivatedTabletSvg,
  bigScreen: AccountActivatedBigScreenSvg
};

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    color: colors.registration.accountActivation.title,
    marginTop: dimensions.margins.medium,
    textAlign: 'center'
  }
}));

const staticStyles = createStyles({
  statusSection: {
    justifyContent: 'center',
    marginTop: dimensions.margins.xxxxLarge,
    // prevent past screen overflow
    width: '100%',
    paddingHorizontal: '5%'
  },
  successSection: {
    alignItems: 'center'
  },
  successImageContainer: {
    maxWidth: '100%',
    width: 900
  },
  successImage: {
    width: '100%',
    height: '100%'
  },
  logoRow: {
    alignItems: 'center',
    paddingHorizontal: '5%',
    width: '100%'
  },
  logoContainer: {
    width: 400,
    maxWidth: '100%'
  },
  logo: {
    width: '100%',
    height: '100%'
  }
});

const TAG = 'AccountActivationScreen';

class MalformedUrlError extends Error {}

const register = async () => {
  const {accountId, token} = getQueryParams();
  if (!accountId || !token) {
    throw new MalformedUrlError('URL does not contain necessary parameters');
  }
  await Customer.activateAccount(accountId, token);
};

// TODO: CL-5100 refactor MWError to extend standard Error
const getErrorMessage = (error: Error | MWError) => {
  const prefix = 'registration.accountActivation.error';

  if (error instanceof MWError) {
    switch (error.type) {
      case MWErrorType.AccountActivationTokenInvalid:
      case MWErrorType.ConfirmationCodeExpired:
      case MWErrorType.ConfirmationCodeInvalid: {
        return `${prefix}.tokenInvalid`;
      }
      case MWErrorType.AccountAlreadyConfirmed: {
        return `${prefix}.accountAlreadyConfirmed`;
      }
      case MWErrorType.AccountDoesntExist: {
        return `${prefix}.accountDoesntExist`;
      }
      case MWErrorType.HttpTimeout: {
        return 'common.httpTimeoutError';
      }
    }
  }

  return `${prefix}.linkMalformed`;
};

const Title: React.FC<{children: string}> = ({children}) => {
  const styles = useStyles(stylesUpdater);
  const {webSubtype} = useScreenInfo();

  return (
    <NitroxText
      textType={'headline'}
      webSubtype={webSubtype}
      style={styles.title}
    >
      {children}
    </NitroxText>
  );
};

const ActivationSuccess: React.FC = () => {
  const {t} = useTranslation();
  const {webSubtype} = useScreenInfo();

  return (
    <View style={staticStyles.successSection}>
      <View style={staticStyles.successImageContainer}>
        <NitroxImage
          svg={successAssets[webSubtype]}
          style={staticStyles.successImage}
        />
      </View>
      <Title>{t('registration.accountActivation.success.title')}</Title>
    </View>
  );
};

const ActivationError: React.FC<{error: Error | MWError}> = ({error}) => {
  const errorKey = useMemo(() => getErrorMessage(error), [error]);
  const {t} = useTranslation();

  return (
    <Title>{t(errorKey)}</Title>
  );
};

const AccountActivationScreen: React.FC<{}> = () => {
  const styles = useStyles(stylesUpdater);

  const [pending, setPending] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | MWError>();

  const getStyling = useDisposable(() => mw.cms.getStyling());
  const initializeMW = useDisposable(() => mw.initializeBackOffice());
  const registerDisposable = useDisposable(register);

  useEffectOnce(() => {
    const params = getQueryParams();
    const mountTime = Date.now();
    async function initialize() {
      const styling = await getStyling()
        .catch((error: Error) => {
          Log.error(TAG, 'Error occurred while retrieving cached styling:', error);
        });
      if (styling) {
        StylesUpdater.update(styling);
      }

      if (params.language) {
        i18next.use(params.language);
      }

      await initializeMW();

      Log.info(TAG, `mw initialized in ${Date.now() - mountTime}ms`);

      registerDisposable()
        .then(() => setSuccess(true))
        .catch(error => setError(error))
        .finally(() => setPending(false));
    }

    initialize();
  }, [getStyling, initializeMW, registerDisposable]);

  const logo = (
    <View style={staticStyles.logoRow}>
      <View style={staticStyles.logoContainer}>
        <AspectRatio ratio={Ratio.LoginLogo}>
          <NitroxLogo
            pictureUrl={assets.common.loginScreenLogo}
            style={staticStyles.logo}
          />
        </AspectRatio>
      </View>
    </View>
  );

  return (
    <View style={styles.container}>
      <AppBackground />
      {!pending && logo}
      <View style={staticStyles.statusSection}>
        {pending && <ActivityIndicator size='large' />}
        {success && <ActivationSuccess />}
        {error && <ActivationError error={error} />}
      </View>
    </View>
  );
};

export default AccountActivationScreen;
