import {createStyles} from 'common-styles';
import React from 'react';

import {isBigScreen, dimensions} from 'common/constants';

import NitroxButton, {NitroxButtonProps, NitroxButtonTheme} from 'components/NitroxButton';

const styles = createStyles({
  button: {
    minWidth: dimensions.toggleSwitch.width
  }
});

const SettingsButton: React.FC<NitroxButtonProps> = ({style, ...props}) => (
  <NitroxButton
    border={isBigScreen}
    textType='buttons-settings'
    theme={isBigScreen ? undefined : NitroxButtonTheme.Secondary}
    style={[styles.button, style]}
    {...props}
  />
);

export default SettingsButton;
