import {createStyles} from 'common-styles';
import React, {useCallback, useMemo, forwardRef} from 'react';
import {Image, StyleProp, TextStyle, View, ViewStyle} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import {dimensions, isWeb} from 'common/constants';
import {humanCaseToSnakeCase} from 'common/HelperFunctions';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors, constColors, transparent} from 'common-styles/variables/base-colors';

import {Gradient} from 'brand/ColorTypes';
import NitroxText from 'components/NitroxText';
import {useChangeEffect, useStaticallyFocused} from 'hooks/Hooks';

import {Icon, IconProps, IconType} from './Icon';
import {IconPosition, NitroxButtonProps, NitroxButtonTheme} from './NitroxButton';
import NitroxInteractive from './NitroxInteractive';

const quaternaryFocusedHeight = 100;
const quaternaryHeight = 90;
const quaternaryBorderWidth = 8;
const quaternarySelectedHeight = quaternaryHeight + quaternaryBorderWidth;

export const styles = createStyles({
  textWithLeftIcon: {
    paddingLeft: dimensions.margins.small
  },
  textWithRightIcon: {
    paddingRight: dimensions.margins.small
  },
  cornerIcon: {
    position: 'absolute'
  },
  disabled: {
    opacity: dimensions.opacity.xlow
  }
});

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  textIconPairContainerBase: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    ...isWeb ? {width: '100%'} : {}
  },
  primaryBase: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    borderRadius: 30,
    padding: 0,
    paddingHorizontal: dimensions.margins.xxLarge
  },
  primaryTextBase: {
    fontSize: 24,
    lineHeight: 29,
    letterSpacing: 2,
    alignSelf: 'center',
    flexShrink: 1
  },
  primaryFocused: {
    borderWidth: 4, //added to sustain same width of focused and unfocused button
    borderColor: colors.newButton.primary.background.focused,
    backgroundColor: colors.newButton.primary.background.focused
  },
  primaryFocusedText: {
    color: colors.newButton.primary.text.focused
  },
  primary: {
    borderWidth: 4,
    borderColor: colors.newButton.primary.background.unfocused
  },
  primarySelected: {
    borderWidth: 6,
    borderColor: colors.newButton.primary.background.selected
  },
  primaryText: {
    color: colors.newButton.primary.text.unfocused
  },
  primaryTextSelected: {
    color: colors.newButton.primary.text.selected
  },
  secondaryBase: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    borderRadius: 20,
    padding: 0,
    paddingHorizontal: dimensions.margins.large
  },
  secondaryTextBase: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 2,
    alignSelf: 'center',
    flexShrink: 1
  },
  secondaryFocused: {
    backgroundColor: colors.newButton.secondary.background.focused
  },
  secondaryFocusedText: {
    color: colors.newButton.secondary.text.focused
  },
  secondary: {
    backgroundColor: transparent(colors.newButton.secondary.background.unfocused, 0.1)
  },
  secondaryText: {
    color: colors.newButton.secondary.text.unfocused
  },
  tertiaryBase: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 60,
    borderWidth: 2,
    borderColor: constColors.transparent,
    borderRadius: 30,
    padding: 0,
    paddingLeft: 33
  },
  tertiaryTextBase: {
    fontSize: 26,
    lineHeight: 56,
    alignSelf: 'center',
    flexShrink: 1
  },
  tertiaryFocused: {
    backgroundColor: colors.newButton.tertiary.background.focused
  },
  tertiarySelected: {
    borderColor: colors.newButton.tertiary.background.unfocused
  },
  tertiaryText: {
    color: colors.newButton.tertiary.text.unfocused
  },
  tertiaryTextFocused: {
    color: colors.newButton.tertiary.text.focused
  },
  quaternaryBase: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: quaternaryHeight,
    marginVertical: (quaternaryFocusedHeight - quaternaryHeight) / 2,
    borderRadius: 999,
    padding: 0,
    backgroundColor: transparent(colors.newButton.quaternary.background.unfocused, 0.1)
  },
  quaternaryTextBase: {
    fontSize: 28,
    lineHeight: 31,
    alignSelf: 'center',
    flexShrink: 1,
    opacity: 1,
    color: colors.newButton.quaternary.text.unfocused
  },
  quaternaryTextFocused: {
    color: colors.newButton.quaternary.text.focused
  },
  quaternaryFocused: {
    marginVertical: 0,
    height: quaternaryFocusedHeight
  },
  quaternaryGradientStart: {
    backgroundColor: colors.newButton.quaternary.background.focused.start
  },
  quaternaryGradientStop: {
    backgroundColor: colors.newButton.quaternary.background.focused.stop
  },
  quaternaryFocusedGradient: {
    position: 'absolute',
    justifyContent: 'center',
    alignContent: 'center',
    width: '100%',
    height: '100%',
    borderRadius: 999
  },
  quaternary: {
    borderColor: constColors.transparent
  },
  quaternarySelected: {
    height: quaternarySelectedHeight,
    borderWidth: quaternaryBorderWidth,
    marginVertical: (quaternaryFocusedHeight - quaternarySelectedHeight) / 2,
    backgroundColor: constColors.transparent
  },
  iconBase: {
    justifyContent: 'center',
    alignContent: 'center',
    width: 60,
    height: 60,
    backgroundColor: constColors.transparent,
    borderWidth: 4,
    borderColor: colors.newButton.primary.background.unfocused,
    borderRadius: 30
  },
  iconFocused: {
    backgroundColor: colors.newButton.primary.background.focused
  }
}));

const iconView = (icon: IconProps, defaultColor: string) => {
  return <Icon type={icon.type} size={icon.size} color={icon.color || defaultColor} />;
};

const NitroxButtonGrosso: React.FunctionComponent<NitroxButtonProps> = (props, ref) => {
  const {
    onFocus,
    onBlur,
    numberOfLines = 1,
    staticallyFocused,
    isSelected,
    theme: propsTheme = NitroxButtonTheme.Primary,
    themeFocused,
    specialIcon,
    onLayout: propsOnLayout,
    isCornerIcon,
    gradient: propsGradient,
    focusable: propsFocusable = true
  } = props;
  const {onFocusStateChanged, focusable, focused} = useStaticallyFocused(propsFocusable, staticallyFocused);
  const theme = !!themeFocused && focused ? themeFocused : propsTheme;

  useChangeEffect(() => {
    if (staticallyFocused != null) {
      onFocusStateChanged(staticallyFocused);
    }
  }, [staticallyFocused]);

  const onFocusHandler = useCallback(() => {
    onFocusStateChanged(true);
    if (onFocus) {
      onFocus();
    }
  }, [onFocus, onFocusStateChanged]);

  const onBlurHandler = useCallback(() => {
    onFocusStateChanged(false);
    if (onBlur) {
      onBlur();
    }
  }, [onBlur, onFocusStateChanged]);

  const source = props.source || (props.uri ? {uri: props.uri} : null);
  const iconPosition = props.icon && props.icon.position ? props.icon.position : IconPosition.LEFT;

  const testID = useMemo(() => {
    let suffix;
    if (props.testID) {
      return props.testID;
    } else if (props.text) {
      suffix = humanCaseToSnakeCase(props.text);
    } else if (props.icon?.type) {
      suffix = humanCaseToSnakeCase(props.icon.type);
    }
    return suffix ? `button_${suffix}` : 'button';
  }, [props.icon, props.testID, props.text]);

  const dynamicStyles = dynamicStylesUpdater.getStyles();
  const themeProps = useMemo(() => {
    let textStyle: StyleProp<TextStyle>[] = [];
    let buttonStyle: StyleProp<ViewStyle>[] = [];
    let iconColor: string = constColors.transparent;
    let iconSize = 0;
    let cornerIconMargin: number | undefined;
    let supportCornerIcon = isCornerIcon;
    let textIconPairContainerOverride: StyleProp<ViewStyle>;
    let gradient: Gradient | undefined = propsGradient;
    switch (theme) {
      case NitroxButtonTheme.Primary:
        iconSize = dynamicStyles.primaryTextBase.fontSize;
        cornerIconMargin = 11;
        if (focused) {
          textStyle = [dynamicStyles.primaryTextBase, dynamicStyles.primaryFocusedText];
          buttonStyle = [dynamicStyles.primaryBase, dynamicStyles.primaryFocused];
          iconColor = dynamicStyles.primaryFocusedText.color;
        } else if (isSelected) {
          textStyle = [dynamicStyles.primaryTextBase, dynamicStyles.primaryTextSelected];
          buttonStyle = [dynamicStyles.primaryBase, dynamicStyles.primarySelected];
          iconColor = dynamicStyles.primaryTextSelected.color;
        } else {
          textStyle = [dynamicStyles.primaryTextBase, dynamicStyles.primaryText];
          buttonStyle = [dynamicStyles.primaryBase, dynamicStyles.primary];
          iconColor = dynamicStyles.primaryText.color;
        }
        break;
      case NitroxButtonTheme.Secondary:
        iconSize = 24;
        cornerIconMargin = 7;
        if (focused) {
          textStyle = [dynamicStyles.secondaryTextBase, dynamicStyles.secondaryFocusedText];
          buttonStyle = [dynamicStyles.secondaryBase, dynamicStyles.secondaryFocused];
          iconColor = dynamicStyles.secondaryFocusedText.color;
        } else {
          textStyle = [dynamicStyles.secondaryTextBase, dynamicStyles.secondaryText];
          buttonStyle = [dynamicStyles.secondaryBase, dynamicStyles.secondary];
          iconColor = dynamicStyles.secondaryText.color;
        }

        // support special case where text is not centered when cornerIcon is defined
        if (isCornerIcon && props.icon) {
          textStyle.push({marginLeft: iconSize + cornerIconMargin - dimensions.margins.small});
        }
        break;
      case NitroxButtonTheme.Tertiary:
        iconSize = 20;
        supportCornerIcon = false;
        cornerIconMargin = 25;
        textIconPairContainerOverride = {
          justifyContent: 'flex-start',
          paddingRight: 34
        };
        if (focused) {
          textStyle = [dynamicStyles.tertiaryTextBase, dynamicStyles.tertiaryTextFocused];
          buttonStyle = [dynamicStyles.tertiaryBase, dynamicStyles.tertiaryFocused];
          iconColor = dynamicStyles.tertiaryTextFocused.color;
        } else if (isSelected) {
          buttonStyle = [dynamicStyles.tertiaryBase, dynamicStyles.tertiarySelected];
          textStyle = [dynamicStyles.tertiaryTextBase, dynamicStyles.tertiaryText];
          iconColor = dynamicStyles.tertiaryText.color;
        } else {
          textStyle = [dynamicStyles.tertiaryTextBase, dynamicStyles.tertiaryText];
          buttonStyle = [dynamicStyles.tertiaryBase];
          iconColor = dynamicStyles.tertiaryText.color;
          textIconPairContainerOverride.opacity = dimensions.opacity.medium;
        }
        break;
      case NitroxButtonTheme.Quaternary:
        cornerIconMargin = 16;
        textIconPairContainerOverride = {
          paddingHorizontal: dimensions.margins.xxLarge
        };
        textStyle = [dynamicStyles.quaternaryTextBase];
        iconColor = dynamicStyles.quaternaryTextBase.color;
        if (gradient == null) {
          gradient = {start: dynamicStyles.quaternaryGradientStart.backgroundColor, stop: dynamicStyles.quaternaryGradientStop.backgroundColor};
        }

        if (focused) {
          buttonStyle = [dynamicStyles.quaternaryBase, dynamicStyles.quaternaryFocused];
          textStyle.push(dynamicStyles.quaternaryTextFocused);
        } else if (isSelected) {
          buttonStyle = [dynamicStyles.quaternaryBase, dynamicStyles.quaternarySelected, {borderColor: gradient.start}];
        } else {
          buttonStyle = [dynamicStyles.quaternaryBase, dynamicStyles.quaternary];
        }
        break;
      case NitroxButtonTheme.Icon:
        iconSize = 21;
        supportCornerIcon = false;
        if (focused) {
          textStyle = [dynamicStyles.primaryTextBase, dynamicStyles.primaryFocusedText];
          buttonStyle = [dynamicStyles.iconBase, dynamicStyles.iconFocused];
          iconColor = dynamicStyles.primaryFocusedText.color;
        } else {
          textStyle = [dynamicStyles.primaryTextBase, dynamicStyles.primaryText];
          buttonStyle = [dynamicStyles.iconBase];
          iconColor = dynamicStyles.primaryText.color;
        }
        break;
      case NitroxButtonTheme.Destructive:
        break;
    }

    let icon: IconProps | undefined;
    if (props.icon) {
      icon = {
        size: iconSize,
        ...props.icon,
        color: iconColor,
        focused: focused || isSelected
      };
      textStyle.push(iconPosition === IconPosition.LEFT ? styles.textWithLeftIcon : styles.textWithRightIcon);
    }

    if (props.disabled) {
      buttonStyle = [styles.disabled, buttonStyle];
    }

    buttonStyle = [props.style, buttonStyle];

    return {
      textStyle,
      buttonStyle,
      iconColor,
      iconSize,
      cornerIconMargin,
      supportCornerIcon,
      textIconPairContainerOverride,
      gradient,
      icon
    };
  }, [dynamicStyles, theme, focused, isSelected, isCornerIcon, propsGradient, props.icon, iconPosition, props.style, props.disabled]);

  const gradientComponent = theme === NitroxButtonTheme.Quaternary && focused && (
    <LinearGradient
      start={{x: 0, y: 0}}
      end={{x: 1, y: 1}}
      style={dynamicStyles.quaternaryFocusedGradient}
      colors={themeProps.gradient ? [themeProps.gradient.start, themeProps.gradient.stop] : []}
    />
  );

  const cornerIconComponent = themeProps.supportCornerIcon && themeProps.icon && (
    <View style={[styles.cornerIcon, iconPosition === IconPosition.LEFT ? {left: themeProps.cornerIconMargin} : {right: themeProps.cornerIconMargin}]}>
      {iconView(themeProps.icon, themeProps.iconColor)}
    </View>
  );

  const iconComponent = !themeProps.supportCornerIcon && themeProps.icon && (iconView(themeProps.icon, themeProps.iconColor));

  const buttonBody = (
    <>
      {gradientComponent}
      {iconPosition === IconPosition.LEFT && cornerIconComponent}
      <View style={[dynamicStyles.textIconPairContainerBase, themeProps.textIconPairContainerOverride]}>
        {iconPosition === IconPosition.LEFT && iconComponent}
        {source && <Image style={props.imageStyle} source={source} />}
        {!!props.text && theme !== NitroxButtonTheme.Icon && (
          <NitroxText
            textType={'buttons'}
            numberOfLines={numberOfLines}
            upperCase
            style={themeProps.textStyle}
            overrideFontStyle
            {...props.textProps}
          >
            {props.text}
          </NitroxText>
        )}
        {props.children}
        {iconPosition === IconPosition.RIGHT && iconComponent}
      </View>
      {iconPosition === IconPosition.RIGHT && cornerIconComponent}
      {
      //to be rethinked if icon should be customizable
        theme === NitroxButtonTheme.Tertiary && specialIcon && (
          <View style={{right: themeProps.cornerIconMargin}}>
            {iconView({
              type: IconType.ArrowRightThin,
              size: 20,
              color: themeProps.iconColor,
              focused: isSelected
            }, themeProps.iconColor)}
          </View>
        )}
    </>
  );

  const sharedProps = {
    ref: ref,
    style: themeProps.buttonStyle
  };

  if (!focusable) {
    return (
      <View {...sharedProps}>
        {buttonBody}
      </View>
    );
  }

  return (
    <NitroxInteractive
      {...props}
      {...sharedProps}
      hasTVPreferredFocus={props.hasTvPreferredFocus}
      testID={testID}
      activeOpacity={1}
      disabled={props.disabled}
      onPress={props.onPress}
      onFocus={onFocusHandler}
      onBlur={onBlurHandler}
      onLayout={propsOnLayout}
      focusPriority={props.focusPriority}
    >
      {buttonBody}
    </NitroxInteractive>
  );
};

export default forwardRef(NitroxButtonGrosso);
