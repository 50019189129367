import {createStyles} from 'common-styles';
import React, {useState, useContext, useCallback} from 'react';
import {StyleSheet, View, ModalProps as NativeModalProps, ViewStyle, StyleProp, Falsy, Keyboard} from 'react-native';
import DeviceInfo from 'react-native-device-info';

import {isWeb, isBigScreen, isMobile, dimensions, highestFocusPriority} from 'common/constants';
import {AnimatedStyle, TestProps} from 'common/HelperTypes';
import {Log} from 'common/Log';
import TestContext from 'common/TestContext';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {EnterStrategy} from 'components/focusManager/FocusManagerTypes';
import FocusParent, {useFocusParentUtility, useFocusParent} from 'components/FocusParent';
import {NativeKeyEvent, SupportedKeys} from 'components/KeyEventManager';
import NitroxBlackPortal from 'components/NitroxBlackPortal';
import {useKeyEventHandler, useTestID, useChangeEffect, useLazyEffect, useIsScreenFocused, useEffectOnce, usePropsDependentRef, useFunction} from 'hooks/Hooks';

import ConditionalWrapper from './ConditionalWrapper';
import {FocusParentContext} from './FocusParent';
import {Icon, IconType} from './Icon';
import {modalPortalName} from './ModalPortal';
import {Overlay} from './Overlay';

const TAG = 'Modal';

export const ModalVisibilityContext = React.createContext((visible: boolean) => {});

/**
 * Reports status of Modal visibility in a component subtree.
 */
export const ModalVisibilityReporter: React.FC<{onModalVisibilityChange: (visible: boolean) => void}> = props => {
  const parentContext = useContext(ModalVisibilityContext);

  const onModalVisibilityChange = useFunction((visible: boolean) => {
    parentContext?.(visible);
    props.onModalVisibilityChange(visible);
  });

  return (
    <ModalVisibilityContext.Provider value={onModalVisibilityChange}>
      {props.children}
    </ModalVisibilityContext.Provider>
  );
};

function overlayContentWidth(): number {
  if (isBigScreen) {
    return 590;
  }

  return DeviceInfo.isTablet() ? 793 : 335;
}

const styles = createStyles({
  modal: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: isBigScreen ? 'center' : 'flex-end'
  },
  overlayContent: {
    flexDirection: 'column',
    justifyContent: isBigScreen ? 'center' : 'flex-end',
    alignItems: 'center',
    width: overlayContentWidth()
  },
  closeBar: {
    paddingVertical: dimensions.margins.medium,
    alignItems: 'center'
  },
  contentContainer: StyleSheet.absoluteFillObject
});

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  modalCloseIconColor: colors.popup.closeIcon
}));

export const ModalCloseBar: React.FC<{}> = () => {
  const dynamicStyles = dynamicStylesUpdater.getStyles();
  return (
    <View style={styles.closeBar}>
      <Icon type={IconType.Collapse} size={dimensions.popup.icon.close.size} color={dynamicStyles.modalCloseIconColor} />
    </View>
  );
};

export type ModalProps = {
  onClose?: (...args: any[]) => any;
  visible: boolean | Falsy;
  style?: StyleProp<AnimatedStyle<ViewStyle>>;
  contentStyle?: StyleProp<ViewStyle>;
  /** override default portal, usable for "portal stacks" like unlock modal over epg menu */
  portal?: string;
  /**
   * Pass overlay key if problem with React reusing Overlay instance between diffrent components occurs.
   * TODO CL-9905 Find proper solution
   */
  overlayKey?: string;
  preventClosingOnBack?: boolean;
  focusEnterStrategy?: EnterStrategy;
  /**
   * Defaults to true. Set to false for popups without any focusable elements.
   */
  focusNearestParentOnClose?: boolean;
} & TestProps;

type NitroxModalProps = Pick<NativeModalProps, 'onRequestClose' | 'transparent' | 'visible' | 'supportedOrientations'> & {preventClosingOnBack?: boolean}

const NitroxModal: React.FunctionComponent<NitroxModalProps> = props => {
  const [visible, setVisible] = useState(props.visible);
  useKeyEventHandler('keyup', (event: NativeKeyEvent) => {
    if (event.key === SupportedKeys.Back && visible && !props.preventClosingOnBack) {
      setVisible(false);
      props.onRequestClose?.();
    }
  });

  const [onReady, focus] = useFocusParent();
  useLazyEffect(() => {
    if (visible) {
      focus();
    }
  }, [visible], [focus]);

  return (
    <FocusParent trapFocus onReady={onReady} style={StyleSheet.absoluteFill}>
      <View style={[
        StyleSheet.absoluteFill,
        isWeb && {position: 'fixed'} as any as ViewStyle // 'fixed' is available only on web
      ]}
      >
        {props.children}
      </View>
    </FocusParent>
  );
};

export const Modal: React.FunctionComponent<ModalProps> = props => {
  const {
    onClose = () => {},
    preventClosingOnBack,
    visible,
    children,
    style,
    contentStyle,
    portal = modalPortalName,
    focusEnterStrategy,
    focusNearestParentOnClose = true
  } = props;
  const testID = useTestID(props, 'Modal') || 'modal';

  const {focus: focusNearestParent} = useFocusParentUtility();

  const synchronizedVisible = usePropsDependentRef(visible);

  const reportModalVisibility = useContext(ModalVisibilityContext);
  useEffectOnce(() => {
    if (visible) {
      reportModalVisibility(true);
    }

    return () => {
      const visible = synchronizedVisible.current;
      if (visible) {
        reportModalVisibility(false);
      }
    };
  }, [visible, synchronizedVisible]);
  useChangeEffect(() => {
    reportModalVisibility(!!visible);
  }, [visible], [reportModalVisibility]);

  const {isScreenFocused, inNavigationContext} = useIsScreenFocused();
  const navigationBlurred = inNavigationContext && !isScreenFocused;

  useChangeEffect(() => {
    // this does not actually hide keyboard, but unfocuses inputs, making it possible to show keyboard again on input press
    if (visible && isMobile) {
      setImmediate(Keyboard.dismiss);
    }
    if (!visible && isBigScreen && !navigationBlurred && focusNearestParentOnClose) {
      Log.debug('Modal', `Focusing nearest parent, focusNearestParent=${typeof focusNearestParent}`);
      if (focusNearestParent) {
        focusNearestParent();
      } else {
        Log.warn(TAG, 'Cannot focus nearest parent');
      }
    }
  }, [visible], [focusNearestParent, navigationBlurred, focusNearestParentOnClose]);

  const onClickAway = useCallback(() => {
    if (preventClosingOnBack) {
      return;
    }
    onClose();
  }, [onClose, preventClosingOnBack]);

  useChangeEffect(() => {
    if (navigationBlurred && visible) {
      onClickAway();
    }
  }, [navigationBlurred], [visible]);

  /// e.g. Android does not handle 'visible' prop correctly
  if (!visible) {
    return null;
  }

  return (
    <FocusParent focusPriority={highestFocusPriority}>
      <NitroxBlackPortal name={portal} contexts={[TestContext, FocusParentContext]}>
        <NitroxModal preventClosingOnBack={preventClosingOnBack} onRequestClose={onClose} transparent visible supportedOrientations={['portrait', 'landscape', 'portrait-upside-down']}>
          <Overlay style={[styles.modal, style]} onClickAway={onClickAway} key={props.overlayKey}>
            <View style={[styles.overlayContent, contentStyle]} testID={testID}>
              <ConditionalWrapper condition={isBigScreen && visible} wrapper={children => <FocusParent style={[styles.overlayContent, contentStyle]} trapFocus enterStrategy={focusEnterStrategy}>{children}</FocusParent>}>
                {children}
              </ConditionalWrapper>
            </View>
          </Overlay>
        </NitroxModal>
      </NitroxBlackPortal>
    </FocusParent>
  );
};
