import {Log} from 'common/Log';

import Language3To2LetterCodesData from './language3To2LettersCodes.json';

const TAG = 'LanguageHelperFunctions';

function convertLanguageCodeTo2Letters(code: string): string {
  if (code.length !== 3) {
    Log.debug(TAG, `Cannot convert code ${code}, length not 3`);
    return code;
  }
  const languageCodes = Language3To2LetterCodesData as {[key: string]: string};
  const language2LettersCode = languageCodes[code];
  if (!language2LettersCode) {
    Log.warn(TAG, `Failed to convert language code ${code} to ISO6391 format`);
    return code;
  }

  return language2LettersCode;
}

export function getLanguageDictionaryKey(code: string): string {
  if (code === 'auto') {
    return 'settings.originalLanguage';
  }
  if (code === 'off') {
    return 'settings.noSubtitles';
  }
  if (code === '') {
    return 'settings.dvs';
  }
  const languageCode = convertLanguageCodeTo2Letters(code);
  return `languages.${languageCode}`;
}

export function areLangaugesEqual(code1: string, code2: string): boolean {
  const languageCode1 = convertLanguageCodeTo2Letters(code1);
  const languageCode2 = convertLanguageCodeTo2Letters(code2);
  return languageCode1 === languageCode2;
}
