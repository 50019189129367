import {createStyles} from 'common-styles';
import React, {forwardRef} from 'react';
import {View, StyleSheet} from 'react-native';

import {dimensions} from 'common/constants';
import {humanCaseToSnakeCase} from 'common/HelperFunctions';
import {TestProps} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {AspectRatio, Ratio} from 'components/AspectRatio';
import TileContainer from 'components/TileContainer';
import {useStaticallyFocused} from 'hooks/Hooks';

const styles = createStyles({
  tileContainer: {
    width: dimensions.swimlaneWithHighlightedBanner.tileWidth,
    height: dimensions.swimlaneWithHighlightedBanner.tileHeight,
    marginTop: dimensions.margins.xLarge + dimensions.swimlaneWithHighlightedBanner.tileBorderWidth,
    marginRight: 0,
    marginLeft: dimensions.margins.xsmall + dimensions.swimlaneWithHighlightedBanner.tileBorderWidth
  }
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  iconContainer: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundColor: colors.tile.poster.placeholder.background,
    flexDirection: 'column',
    overflow: 'hidden',
    borderRadius: 20
  }
}));

type Props = {
  label?: string;
  focusable?: boolean;
  onPress: () => void;
  onFocus?: (event: any, options?: FocusOptions) => void;
  scrollOnFocus?: boolean;
  staticallyFocused?: boolean;
} & TestProps;

const HighlightedBannerPlaceholder: React.FunctionComponent<Props> = (props, ref) => {
  const {onFocus, scrollOnFocus = true, focusable: propsFocusable = true, staticallyFocused, onPress} = props;

  const dynamicStyles = stylesUpdater.getStyles();
  const {onFocusStateChanged, focusable} = useStaticallyFocused(propsFocusable, staticallyFocused);

  const testID = 'tile_' + humanCaseToSnakeCase(props.label || 'empty');

  return (
    <TileContainer
      ref={ref}
      style={styles.tileContainer}
      onFocus={onFocus}
      scrollOnFocus={scrollOnFocus}
      focusable={focusable}
      onPress={onPress}
      onFocusStateChanged={onFocusStateChanged}
      testID={testID}
    >
      <AspectRatio ratio={Ratio.RATIO_2_3}>
        <View style={dynamicStyles.iconContainer}></View>
      </AspectRatio>
    </TileContainer>
  );
};

export default React.memo(forwardRef(HighlightedBannerPlaceholder));
