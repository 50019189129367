import {createStyles} from 'common-styles';
import React, {useState, useCallback, forwardRef} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions, isBigScreen, isMobile, RADIUS} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors, constColors} from 'common-styles/variables/base-colors';

import {SelectableBO} from 'mw/api/NXFF';
import {mw} from 'mw/MW';

import BackOfficeSelect from 'components/BackOfficeSelect';
import NitroxButton from 'components/NitroxButton';
import {PostProcessors} from 'locales/i18nPostProcessors';
import {useLocalized} from 'locales/i18nUtils';

const borderWidth = isBigScreen ? 2 : 1;
const borderRadius = isBigScreen ? RADIUS * 2 : RADIUS;
const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  border: {
    borderWidth: borderWidth,
    borderColor: colors.focusableTextInput.border.unfocused,
    borderRadius: borderRadius,
    marginVertical: isBigScreen ? dimensions.margins.medium : dimensions.margins.small
  },
  borderFocused: {
    borderColor: colors.focusableTextInput.border.focused
  },
  button: {
    height: isMobile ? 50 : 90,
    backgroundColor: constColors.transparent,
    marginLeft: 0,
    marginRight: 0,
    justifyContent: 'flex-start'
  },
  buttonText: {
    color: colors.focusableTextInput.text.unfocused
  },
  buttonTextFocused: {
    color: colors.focusableTextInput.text.focused
  }
}));

type Props = {
  backOffices: SelectableBO[];
  initialBackOfficeCode: string;
  onBoChange?: () => void;
  onFocus?: () => void;
  hasTVPreferredFocus?: boolean;
};

const BackOfficeSelector: React.FunctionComponent<Props> = (props, ref) => {
  const {backOffices, onBoChange, onFocus, hasTVPreferredFocus} = props;
  const {t} = useTranslation();
  const {toUpperCase} = useLocalized();
  const [selectVisible, setSelectVisible] = useState(false);
  const showBackOfficeSelect = useCallback(() => setSelectVisible(true), []);
  const hideBackOfficeSelect = useCallback(() => setSelectVisible(false), []);

  const currentBackOfficeCode = mw.bo.getBackOfficeCode();
  const backOfficeName = (backOffices.find(backOffice => backOffice.code === currentBackOfficeCode) || {}).name;
  const styles = stylesUpdater.getStyles();

  return (
    <View>
      <NitroxButton
        ref={ref}
        style={[styles.button, styles.border]}
        styleFocused={styles.borderFocused}
        text={backOfficeName ? toUpperCase(backOfficeName) : t('common.chooseBackend', {postProcess: PostProcessors.ToUpperCase})}
        testID='button_backoffice_selection'
        textStyleFocused={styles.buttonTextFocused}
        textStyle={styles.buttonText}
        onFocus={onFocus}
        onPress={showBackOfficeSelect}
        hasTvPreferredFocus={hasTVPreferredFocus}
      />
      <BackOfficeSelect visible={selectVisible} onClose={hideBackOfficeSelect} logoutOnChange={false} onBoChange={onBoChange} />
    </View>
  );
};

export default forwardRef(BackOfficeSelector);
