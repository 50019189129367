import {useRef, useCallback} from 'react';
import {InteractionManager} from 'react-native';

import {useDebounce, useFunction, useLazyEffect} from 'hooks/Hooks';

export type InteractionHandleInterface = {
  /**
   * Creates interaction handle (overrides previous). Cancels scheduled clear.
   */
  create: () => void;
  /**
   * Clears interaction handle.
   */
  clear: () => void;
  /**
   * Creates 'temporary' handle, that is automatically scheduled for clear within given delay.
   */
  createTemporary: () => void;
  /**
   * Schedules clearing interaction handle in given delay.
   */
  scheduleClear: () => void;
};

/**
 * Remember to clear interactions, unresolved interaction will break application!
 */
export function useInteractionHandle(delay = 0): InteractionHandleInterface {
  const handle = useRef<number>();

  const clearHandle = useCallback(() => {
    if (handle.current != null) {
      InteractionManager.clearInteractionHandle(handle.current);
    }
  }, []);

  const scheduleClearHandle = useDebounce(clearHandle, delay);

  const create = useFunction(() => {
    scheduleClearHandle.abort();
    clearHandle();
    handle.current = InteractionManager.createInteractionHandle();
  });

  const createTemporaryHandle = useFunction(() => {
    create();
    scheduleClearHandle();
  });

  // Interactions handles must be cleared on unmount - enqueued tasks will never be fired otherwise.
  useLazyEffect(() => () => {
    scheduleClearHandle.abort();
    clearHandle();
  }, [], [scheduleClearHandle, clearHandle]);

  return {
    create,
    clear: clearHandle,
    scheduleClear: scheduleClearHandle,
    createTemporary: createTemporaryHandle
  };
}

export function awaitInteractions(): Promise<void> {
  return new Promise(resolve => InteractionManager.runAfterInteractions(resolve));
}
