import {EventEmitter} from 'common/EventEmitter';
import {EventEmitterCallback} from 'common/EventEmitterInterface';
import {ChangeEvent} from 'common/HelperTypes';

import {SpecialFilter} from 'mw/api/CatalogInterface';
import {CustomerEvent, CustomerEventPayload} from 'mw/api/Customer';
import {Filter} from 'mw/api/Filter';
import {PlayerEvent} from 'mw/api/PlayerEvent';
import {Profile, ProfileEvent} from 'mw/api/Profile';
import {mw} from 'mw/MW';

export enum MetaEvent {
  WatchListChanged = 'WatchListChanged',
  ContinueWatchingChanged = 'ContinueWatchingChanged',
  /** Dummy event that is never triggered. It exists only to allow to use useEventListener hook in any possible cases. */
  None = 'None'
}

export type DataEvents<ParamsType = any> = {
  dataRefreshEvent: MetaEvent;
  dataChangeEvent: MetaEvent;
  dataEventsEmitter: EventEmitter<MetaEvent, ParamsType>;
};

function getDataChangeEventForComponent(filter?: Filter): MetaEvent {
  switch (filter?.value) {
    case SpecialFilter.WatchList:
      return MetaEvent.WatchListChanged;
    case SpecialFilter.ContinueWatching:
      return MetaEvent.ContinueWatchingChanged;
    default:
      return MetaEvent.None;
  }
}

export class MetaEventsEmitter<ParamsType = any> extends EventEmitter<MetaEvent, ParamsType> {
  public on(event: MetaEvent, callback: EventEmitterCallback<ParamsType>): void {
    if (event !== MetaEvent.None) {
      super.on(event, callback);
    }
  }

  public once(event: MetaEvent, callback: EventEmitterCallback<ParamsType>): void {
    if (event !== MetaEvent.None) {
      super.once(event, callback);
    }
  }

  /**
   * Returns meta events related to the provided CMS component changes. Even when the CMS Component changes are reflected by several
   * MW events the returned meta events should cover all of the underlaying MW events.
   * @param component CMS component
   */
  public getDataEventsForFilter(filter?: Filter): DataEvents<ParamsType> {
    return {
      dataRefreshEvent: MetaEvent.WatchListChanged, // WatchList icon is present on all Tiles therefore always refresh them when it changes,
      dataChangeEvent: getDataChangeEventForComponent(filter),
      dataEventsEmitter: this
    };
  }

  /**
   * Adds internal listeners used by this emitter. Call this on mount.
   */
  public addListeners(): void {
    mw.customer.on(CustomerEvent.ProfileChange, this.onProfileChange);
    mw.customer.currentProfile?.on(ProfileEvent.WatchListChange, this.onWatchListChange);
    mw.players.main.on(PlayerEvent.BookmarkUpdate, this.onBookmarkUpdate);
  }

  /**
   * Removes internal listeners used by this emitter. Call this on unmount.
   */
  public removeListeners(): void {
    mw.customer.off(CustomerEvent.ProfileChange, this.onProfileChange);
    mw.customer.currentProfile?.off(ProfileEvent.WatchListChange, this.onWatchListChange);
    mw.players.main.off(PlayerEvent.BookmarkUpdate, this.onBookmarkUpdate);
  }

  private onProfileChange = (eventPayload?: CustomerEventPayload) => {
    if (!eventPayload) {
      return;
    }
    const {to, from} = eventPayload as ChangeEvent<Profile | null>;
    from?.off(ProfileEvent.WatchListChange, this.onWatchListChange);
    to?.on(ProfileEvent.WatchListChange, this.onWatchListChange);
    this.notify(MetaEvent.WatchListChanged);
  };

  private onWatchListChange = () => {
    this.notify(MetaEvent.WatchListChanged);
  };

  private onBookmarkUpdate = () => {
    this.notify(MetaEvent.ContinueWatchingChanged);
  }
}
