import {createStyles, defaultStyles} from 'common-styles';
import {RefObject} from 'react';
import {ViewStyle, StyleProp, NativeMethodsMixinStatic} from 'react-native';

import {isBigScreen, dimensions, getValue, isPhone, isMobile} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {FocusableComponent} from './focusManager/FocusManagerTypes';
import {PopupProps} from './Popup';
import PopupContentGrosso from './PopupContent.grosso';
import PopupContentPiccolo from './PopupContent.piccolo';

type PopupAction = {
  key: string;
  ref: React.MutableRefObject<(React.Component<{}, {}> & NativeMethodsMixinStatic) | null> | null;
  hasTvPreferredFocus: boolean;
  text?: string;
  style: StyleProp<ViewStyle>;
  onPress: () => void;
  onFocus: () => void;
  highlighted?: boolean;
  disabled?: boolean;
}

export type PopupContentProps = {
  actions: PopupAction[];
  width: number;
  menuRef: RefObject<FocusableComponent>;
} &
Pick<PopupProps,
| 'title'
| 'titleType'
| 'subtitle'
| 'menuStyle'
| 'onModalClose'
| 'containerStyle'
| 'titleStyle'
| 'subtitleStyle'
| 'subtitleType'
| 'visible'
| 'icon'
| 'actionsSeparator'
| 'portal'
| 'focusNearestParentOnClose'
| 'onLayout'
>

export const popupContentStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    ...defaultStyles.popup,
    backgroundColor: colors.popup.background,
    paddingBottom: dimensions.popup.padding
  },
  title: {
    color: colors.popup.text,
    marginBottom: dimensions.margins.small,
    textAlign: 'center'
  },
  subtitle: {
    color: colors.popup.subtitle,
    textAlign: 'center'
  },
  menu: {
    bottom: 0,
    alignItems: 'center',
    backgroundColor: getValue({tv: colors.popup.backgroundBottom, desktopBrowser: colors.popup.backgroundBottom, defaultValue: colors.popup.menuBackground})
  },
  menuFlexParams: {
    flexDirection: isPhone ? 'column' : 'row',
    justifyContent: isMobile ? 'center' : 'space-between',
    alignItems: 'center'
  },
  menuCentered: {
    justifyContent: 'center'
  }
}));

export default isBigScreen ? PopupContentGrosso : PopupContentPiccolo;
