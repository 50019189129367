import {createStyles} from 'common-styles';
import React, {useState, useCallback} from 'react';

import {getValue, isWeb} from 'common/constants';
import {DateUtils} from 'common/DateUtils';
import {Log} from 'common/Log';

import {EASAlert} from 'mw/api/EASMetadata';
import {mw} from 'mw/MW';

import EASAlertModalContent from 'components/EASAlertModalContent';
import {Modal} from 'components/Modal';
import {easAlertPopupPortalName} from 'components/ModalPortal';
import {PlayerView, PlayerViews} from 'components/player/PlayerView';
import EASPlayerManager from 'components/utils/EASPlayerManager';
import {useFunction, useTimer, useLateBinding} from 'hooks/Hooks';

const TAG = 'EASAlertModal';

const styles = createStyles({
  modal: {
    paddingTop: getValue({tv: 167, tablet: 30, mobile: 53, defaultValue: 30}),
    paddingRight: getValue({tv: 434, tablet: 30, mobile: 20, defaultValue: 30}),
    paddingBottom: getValue({tv: 151, tablet: 30, mobile: 23, defaultValue: 30}),
    paddingLeft: getValue({tv: 434, tablet: 30, mobile: 20, defaultValue: 30})
  },
  content: {
    flex: 1,
    width: '100%',
    height: '100%'
  }
});

export type EASAlertModalProps = {
  visible: boolean;
  easAlert?: EASAlert;
  preventClosing?: boolean;
  onClose?: () => void;
}

const EASAlertModal: React.FC<EASAlertModalProps> = ({visible, easAlert, preventClosing, onClose}) => {
  const onCloseCallback = useCallback(() => {
    if (!preventClosing && onClose) {
      onClose();
    }
  }, [onClose, preventClosing]);

  return (
    <Modal
      onClose={onClose}
      visible={visible}
      contentStyle={styles.content}
      portal={easAlertPopupPortalName}
      style={styles.modal}
      preventClosingOnBack={preventClosing}
    >
      {easAlert && (
        <>
          <EASAlertModalContent
            easAlert={easAlert}
            preventClosing={preventClosing}
            onClose={onCloseCallback}
          />
          {isWeb && (
            // Fake playerView is required for audio playback on web based platforms
            <PlayerView debugName={TAG} type={PlayerViews.None} style={{display: 'none'}} />
          )}
        </>
      )}
    </Modal>
  );
};

export default React.memo(EASAlertModal);

export function useEASAlertModal(onClose?: () => void) {
  const [easAlert, setEASAlert] = useState<EASAlert | undefined>(undefined);
  const [preventClosing, setPreventClosing] = useState(false);
  const {setTimer: setPreventClosingTimer, clearTimer: clearPreventClosingTimer} = useTimer();
  const {setTimer: setCloseTimer, clearTimer: clearCloseTimer} = useTimer();

  const [resetTimers, bindResetTimers] = useLateBinding<() => void>();

  const clearTimers = useCallback(() => {
    clearPreventClosingTimer();
    clearCloseTimer();
  }, [clearCloseTimer, clearPreventClosingTimer]);

  const onPreventClosingHandler = useCallback(() => {
    Log.debug(TAG, `Closing alert is now allowed`);
    setPreventClosing(false);
  }, []);

  const onCloseHandler = useFunction(() => {
    if (!easAlert) {
      return;
    }
    clearTimers();
    EASPlayerManager.getInstance().stop();
    Log.debug(TAG, `Confirming that EAS alert ${easAlert.id} was viewed`);
    const queuedEASAlert = mw.eas.confirmViewed(easAlert);
    if (queuedEASAlert) {
      Log.debug(TAG, `Got queued EAS alert ${queuedEASAlert.id} - showing it`);
      setEASAlert(queuedEASAlert);
      resetTimers();
      EASPlayerManager.getInstance().start(queuedEASAlert);
    } else {
      Log.debug(TAG, `There are no more queued EAS alerts - closing the modal`);
      setEASAlert(undefined);
      onClose?.();
    }
  });

  bindResetTimers(useCallback(() => {
    // prevent closing the popup
    const preventClosingTimeout = mw.configuration.getAlertPreventClosingTimeout();
    if (preventClosingTimeout > 0) {
      Log.debug(TAG, `Closing the EAS alert popup is disabled for the next ${preventClosingTimeout} seconds`);
      setPreventClosing(true);
      setPreventClosingTimer(onPreventClosingHandler, preventClosingTimeout * DateUtils.msInSec);
    }
    // schedule closing the popup
    const closeTimeout = mw.configuration.getAlertAutoCloseTimeout();
    if (closeTimeout > 0) {
      Log.debug(TAG, `EAS alert popup will be closed automatically in ${closeTimeout} seconds`);
      setCloseTimer(onCloseHandler, closeTimeout * DateUtils.msInSec);
    }
  }, [setPreventClosingTimer, onPreventClosingHandler, setCloseTimer, onCloseHandler]));

  const onEASAlertReceived = useFunction((newEASAlert?: EASAlert) => {
    if (!newEASAlert) {
      return;
    }
    if (easAlert) {
      Log.debug(TAG, `Previous EAS alert ${easAlert.id} is still visible - ignoring the new one ${newEASAlert.id}`);
      return;
    }
    Log.debug(TAG, `Showing new EAS alert ${newEASAlert.id}`);
    setEASAlert(newEASAlert);
    resetTimers();
    EASPlayerManager.getInstance().start(newEASAlert);
  });

  const onEASAlertUpdated = useFunction((updatedEASAlert?: EASAlert) => {
    if (!easAlert || easAlert.id !== updatedEASAlert?.id) {
      return;
    }
    Log.debug(TAG, `EAS alert ${easAlert.id} has been updated - reseting closing timer`);
    resetTimers();
  });

  const renderEASAlertModal = useCallback(() => (
    <EASAlertModal
      visible={!!easAlert}
      easAlert={easAlert}
      preventClosing={preventClosing}
      onClose={onCloseHandler}
    />
  ), [easAlert, preventClosing, onCloseHandler]);

  return {onEASAlertReceived, onEASAlertUpdated, renderEASAlertModal};
}
