import {createStyles} from 'common-styles';
import React, {useCallback, forwardRef, useState, useMemo, useEffect, RefObject} from 'react';
import {View} from 'react-native';

import {dimensions, isMobile} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors, transparent} from 'common-styles/variables/base-colors';

import {Event} from 'mw/api/Metadata';

import {minTagWidth} from 'components/NitroxTag';
import NitroxText from 'components/NitroxText';
import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';
import InteractionSuspense from 'components/performance/InteractionSuspense';
import TileIconsRow, {prepareEventIcons, TileIconType, tileIconSize} from 'components/TileIconsRow';
import {useForceUpdate, useMounted} from 'hooks/Hooks';

import RoundBorder from './RoundBorder';

export interface EpgTileInterface {
  onFocus: () => void;
  onBlur: () => void;
  onPress: () => void;
  isMounted: () => boolean;
}

export type EpgTileProps = {
  event: Event;
  showIcons?: boolean;
  excludeIcons?: TileIconType[];
  width?: number;
  onFocus?: (event: Event) => void;
  onPress?: (event: Event) => void;
  selected?: boolean;
  focused?: boolean;
};

const containerPaddingHorizontal = isMobile ? dimensions.margins.medium : dimensions.margins.xxLarge;
const showTextThreshold = 2 * containerPaddingHorizontal;
const showIconsMinWidthThreshold = showTextThreshold + tileIconSize;
const showTagsMinWidthThreshold = showIconsMinWidthThreshold + minTagWidth;

const gradientWidth = 56;

const staticStyles = createStyles({
  container: {
    flex: 1,
    flexDirection: 'row',
    overflow: 'hidden',
    alignItems: 'center'
  },
  borderContainer: {
    marginVertical: dimensions.margins.xsmall,
    marginRight: dimensions.margins.small
  },
  textContainer: {
    flex: 1,
    paddingLeft: containerPaddingHorizontal
  },
  shadowContainer: {
    position: 'absolute',
    right: 0,
    width: gradientWidth,
    height: '100%'
  },
  epgIcons: {
    marginRight: dimensions.margins.large,
    marginLeft: dimensions.margins.xsmall
  }
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  active: {
    backgroundColor: colors.epgScreen.grid.tile.active
  },
  focused: {
    backgroundColor: colors.epgScreen.grid.tile.focused
  },
  notFocused: {
    backgroundColor: colors.epgScreen.grid.tile.unfocused
  },
  focusedText: {
    color: colors.epgScreen.grid.tile.text.focused
  },
  unfocusedText: {
    color: colors.epgScreen.grid.tile.text.unfocused
  },
  notFocusedGradientColors: [transparent(colors.epgScreen.grid.tile.unfocused, 0), colors.epgScreen.grid.tile.unfocused],
  focusedGradientColors: [transparent(colors.epgScreen.grid.tile.focused, 0), colors.epgScreen.grid.tile.focused]
}));

const EpgTile: React.FC<EpgTileProps> = (props: EpgTileProps, ref: RefObject<EpgTileInterface>) => {
  const {event, showIcons, excludeIcons, width = 0, onFocus, onPress, selected = false} = props;
  const [focused, setFocused] = useState(!!props.focused);
  const {forceUpdateState} = useForceUpdate();
  const mounted = useMounted();
  const {isMediaBlocked} = useParentalControl();
  const isBlocked = isMediaBlocked(event);

  const onFocusHandler = useCallback(() => {
    onFocus?.(event);
    setFocused(true);
  }, [onFocus, event]);

  const onPressHandler = useCallback(() => {
    onPress?.(event);
  }, [onPress, event]);

  const mediaIcons = useMemo(() => {
    if (!showIcons || width < showIconsMinWidthThreshold) {
      return null;
    }
    return prepareEventIcons(event, {
      includeTags: (width >= showTagsMinWidthThreshold),
      excludeIcons,
      isBlocked
    });
  }, [showIcons, excludeIcons, width, event, event.isNow, forceUpdateState]); // eslint-disable-line react-hooks/exhaustive-deps

  const dynamicStyles = stylesUpdater.getStyles();

  const borderContentStyle = useMemo(() => [
    focused ? dynamicStyles.focused : (selected ? dynamicStyles.active : dynamicStyles.notFocused)
  ], [focused, selected, dynamicStyles]);

  // TODO: CL-3438 Verify gradients real impact on performance. Try to optimize if possible. According to react-devtools profiler, multiple gradients take major render time.
  // const gradientColors = useMemo(() => focused || selected ? styles.focusedGradientColors : styles.notFocusedGradientColors, [focused, selected, styles]);

  const [showText, setShowText] = useState(false);
  useEffect(() => {
    if (width >= showTextThreshold) {
      setShowText(true);
    }
  }, [width]);

  //TODO: CL-3550
  const imperativeHandles = useMemo(() => ({
    onFocus: () => onFocusHandler(),
    onBlur: () => setFocused(false),
    onPress: () => onPressHandler(),
    isMounted: () => mounted.current
  }), [mounted, onFocusHandler, onPressHandler]);
  if (ref) {
    // @ts-ignore this is dirty stuff, but there are big issues due to refs' asynchronous nature
    ref.current = imperativeHandles;
  }

  return (
    <RoundBorder
      containerStyle={staticStyles.borderContainer}
      contentStyle={borderContentStyle}
    >
      <View style={staticStyles.container}>
        <InteractionSuspense>
          <View style={staticStyles.textContainer}>
            {showText && (
              <NitroxText
                textType={'epg-tile'}
                style={dynamicStyles.focusedText}
                numberOfLines={1}
                ellipsizeMode='clip'
              >
                {event.name}
              </NitroxText>
            )}
            {/* Removing until performance issues in EPG are resolved, gradient painting is resource heavy operation */}
            {/* <LinearGradient
              start={gradientParams.start}
              end={gradientParams.end}
              colors={gradientColors}
              style={styles.shadowContainer}
              pointerEvents='none'
            /> */}
          </View>
          {mediaIcons && <TileIconsRow style={staticStyles.epgIcons} mediaIcons={mediaIcons} />}
        </InteractionSuspense>
      </View>
    </RoundBorder>
  );
};

export default React.memo(forwardRef<EpgTileInterface, EpgTileProps>(EpgTile));
