import {createStyles} from 'common-styles';
import React, {useCallback, useState, useRef, useEffect, memo} from 'react';
import {View, GestureResponderEvent, Animated} from 'react-native';

import {dimensions, defaultThrottle} from 'common/constants';
import {interpolateToPercentage} from 'common/HelperFunctions';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Icon, IconType} from 'components/Icon';
import {useDisposableCallback, useThrottle} from 'hooks/Hooks';

const progressDotSize = 15;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  currentVolume: {
    backgroundColor: colors.chromecast.volumeBar.current
  },
  availableVolume: {
    backgroundColor: colors.chromecast.volumeBar.available
  },
  currentVolumeDot: {
    position: 'absolute',
    alignSelf: 'center',
    width: progressDotSize,
    height: progressDotSize,
    borderRadius: progressDotSize / 2,
    marginLeft: -progressDotSize / 2,
    backgroundColor: colors.progressBar.default.dot.background
  }
}));

const staticStyles = createStyles({
  container: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center'
  },
  sliderContainer: {
    marginStart: dimensions.margins.large,
    justifyContent: 'center',
    flex: 1,
    height: dimensions.player.progressBar.default.expandedHeight
  },
  volumeContainer: {
    flexDirection: 'row',
    height: dimensions.chromecast.volumeBar.height,
    overflow: 'hidden'
  },
  volume: {
    alignSelf: 'center',
    height: dimensions.chromecast.volumeBar.barHeight
  }
});

type Props = {
  changingVolume?: boolean;
  currentVolume: number;
  availableVolumeStart: number;
  availableVolumeEnd: number;
  isDraggable?: boolean;
  // similar to flatlist onScroll - fires on every change (also during drag phase)
  onVolumeChange?: (position: number) => void;
  onGestureStateChange?: (value: boolean) => void;
}

const VolumeSlider: React.FC<Props> = props => {
  const {
    currentVolume: propsCurrentVolume,
    changingVolume,
    onVolumeChange,
    availableVolumeEnd,
    availableVolumeStart,
    onGestureStateChange,
    isDraggable = true
  } = props;
  const [volumeContainerPageX, setVolumeContainerPageX] = useState<number>(0);
  const [volumeContainerWidth, setVolumeContainerWidth] = useState<number>(0);
  const animatedCurrentVolume = useRef<Animated.Value>(new Animated.Value(0));
  const animatedAvailableVolume = useRef<Animated.Value>(new Animated.Value(0));
  const animatedDotVolume = useRef<Animated.Value>(new Animated.Value(0));
  const volumeContainerRef = useRef<View | null>(null);
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const currentVolume = useRef<number>(0);

  const trueCallback = useCallback(() => true, []);
  const falseCallback = useCallback(() => false, []);

  const setCurrentVolume = useCallback((volume: number, saveValue = true) => {
    if (saveValue) {
      currentVolume.current = volume;
    }
    // camp this value in case user tries to jump beyond available range
    const calculatedAvailableVolume = Math.max(Math.min(availableVolumeEnd - volume, 1), 0);
    Animated.parallel([
      Animated.timing(animatedCurrentVolume.current, {
        toValue: volume - availableVolumeStart,
        duration: 0
      }),
      Animated.timing(animatedDotVolume.current, {
        toValue: volume,
        duration: 0
      }),
      Animated.timing(animatedAvailableVolume.current, {
        toValue: calculatedAvailableVolume,
        duration: 0
      })
    ]).start();
  }, [availableVolumeEnd, availableVolumeStart]);

  useEffect(() => {
    !isTouched && !changingVolume && setCurrentVolume(propsCurrentVolume);
  }, [propsCurrentVolume, isTouched, setCurrentVolume, changingVolume]);

  const onContainerMeasure = useDisposableCallback((x, y, width, height, pageX, pageY) => {
    setVolumeContainerPageX(pageX);
    setVolumeContainerWidth(width);
  });

  // We need position in global coordinate system of the container in order to compute current position in the gesture hanlders
  // We attach to the progress container here is order to include any margins or offset defined in the styles.
  const onVolumeContainerLayout = useCallback(() => {
    volumeContainerRef.current?.measure(onContainerMeasure);
  }, [onContainerMeasure]);

  const dynamicStyles = stylesUpdater.getStyles();

  const computeCurrentVolume = useCallback((pageX: number): number => {
    if (!volumeContainerWidth) { // wait for the volume container's measurements
      return 0;
    }
    return Math.max(Math.min((pageX - volumeContainerPageX) / volumeContainerWidth, availableVolumeEnd), availableVolumeStart);
  }, [volumeContainerWidth, volumeContainerPageX, availableVolumeEnd, availableVolumeStart]);

  const onVolumeChangeThrottled = useThrottle((currentPosition: number) => {
    onVolumeChange?.(currentPosition);
  }, defaultThrottle);

  const onResponderMove = useCallback((event: GestureResponderEvent): void => {
    if (!isDraggable) {
      return;
    }
    const newVolume = computeCurrentVolume(event.nativeEvent.pageX);
    setCurrentVolume(newVolume, false);
    onVolumeChangeThrottled(newVolume);
  }, [isDraggable, computeCurrentVolume, setCurrentVolume, onVolumeChangeThrottled]);

  const onResponderStart = useCallback((event: GestureResponderEvent): void => {
    if (!isDraggable) {
      return;
    }
    onGestureStateChange?.(true);
    setIsTouched(true);
    onResponderMove(event);
  }, [isDraggable, onGestureStateChange, onResponderMove]);

  const onResponderRelease = useCallback((event: GestureResponderEvent): void => {
    if (!isDraggable) {
      return;
    }
    onGestureStateChange?.(false);
    setIsTouched(false);
    const newVolume = computeCurrentVolume(event.nativeEvent.pageX);
    setCurrentVolume(newVolume);
    onVolumeChange?.(newVolume);
  }, [onGestureStateChange, isDraggable, computeCurrentVolume, setCurrentVolume, onVolumeChange]);

  return (
    <View style={staticStyles.container}>
      <Icon type={IconType.Volume} size={dimensions.icon.small} />
      <View
        style={staticStyles.sliderContainer}
        onStartShouldSetResponder={trueCallback}
        onMoveShouldSetResponder={trueCallback}
        onStartShouldSetResponderCapture={trueCallback}
        onMoveShouldSetResponderCapture={trueCallback}
        onResponderMove={onResponderMove}
        onResponderStart={onResponderStart}
        onResponderRelease={onResponderRelease}
        onResponderTerminate={onResponderRelease}
        onResponderTerminationRequest={falseCallback}
      >
        <View
          ref={volumeContainerRef}
          style={staticStyles.volumeContainer}
          onLayout={onVolumeContainerLayout}
        >
          <Animated.View style={[staticStyles.volume, dynamicStyles.currentVolume, {width: interpolateToPercentage(animatedCurrentVolume.current)}]} />
          <Animated.View style={[staticStyles.volume, dynamicStyles.availableVolume, {width: interpolateToPercentage(animatedAvailableVolume.current)}]} />
        </View>
        <Animated.View
          style={[
            dynamicStyles.currentVolumeDot,
            {left: interpolateToPercentage(animatedDotVolume.current)}]}
        />
      </View>
    </View>
  );
};

export default memo(VolumeSlider);
