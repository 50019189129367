import {createStyles} from 'common-styles';
import React from 'react';
import {ViewStyle} from 'react-native';

import {dimensions, isBigScreen, getValue, isMobile} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';

import NitroxButton, {NitroxButtonProps, NitroxButtonTheme} from 'components/NitroxButton';
import {PostProcessors} from 'locales/i18nPostProcessors';

const buttonStyle: ViewStyle = {
  borderWidth: getValue({mobile: 1, defaultValue: 2}),
  width: '100%',
  height: getValue({mobile: dimensions.buttons.large, defaultValue: dimensions.buttons.xLarge}),
  marginTop: getValue({mobile: dimensions.margins.large, defaultValue: dimensions.margins.xxxLarge}),
  paddingHorizontal: getValue({mobile: dimensions.margins.xxxLarge, defaultValue: 2 * dimensions.margins.xxLarge})
};

const stylesUpdater = new StylesUpdater(colors => createStyles({
  buttonFocused: {
    backgroundColor: colors.button.primary.background.focused
  },
  buttonPositive: {
    ...isMobile && {backgroundColor: colors.button.primary.background.focused}
  },
  textFocused: {
    color: colors.button.primary.text.focused
  },
  textPositive: {
    ...isMobile && {color: colors.button.primary.text.focused}
  }
}));

type Props = {
  positive?: boolean;
} & NitroxButtonProps;

const FormButton: React.FC<Props> = ({positive, style, ...props}) => {
  const styles = stylesUpdater.getStyles();
  return (
    <NitroxButton
      border
      style={[buttonStyle, positive && styles.buttonPositive, style]}
      styleFocused={styles.buttonFocused}
      textStyle={positive && styles.textPositive}
      textStyleFocused={styles.textFocused}
      theme={NitroxButtonTheme.Tertiary}
      {...props}
      useOldImplementation
    />
  );
};

export default FormButton;

export const buttonTextPostProcessor = isBigScreen ? PostProcessors.ToUpperCase : PostProcessors.Capitalize;
