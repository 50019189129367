import React from 'react';
import {Falsy} from 'react-native';

type Props = {
  condition: boolean | Falsy;
  wrapper: (children: React.ReactNode) => JSX.Element;
};

const ConditionalWrapper: React.FunctionComponent<Props> = props => {
  return (
    <>
      {props.condition
        ? props.wrapper(props.children)
        : props.children
      }
    </>
  );
};

export default ConditionalWrapper;
