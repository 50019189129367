import {createStyles} from 'common-styles';
import React from 'react';
import {View} from 'react-native';

import {dimensions, getValue} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxText from 'components/NitroxText';
import Separator from 'components/Separator';

const textMargin = getValue({mobile: 18, tablet: 23, defaultValue: 43});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    marginTop: dimensions.margins.small,
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center'
  },
  line: {
    borderBottomColor: colors.button.primary.text.unfocused,
    borderBottomWidth: getValue({mobile: 1, defaultValue: 2}),
    flex: 1
  },
  text: {
    color: colors.button.primary.text.unfocused,
    marginLeft: textMargin,
    marginRight: textMargin,
    marginBottom: 2
  }
}));

type Props = {
  label: string;
};

const Divider: React.FC<Props> = ({label}) => {
  const styles = stylesUpdater.getStyles();
  return (
    <View style={styles.container}>
      <Separator style={styles.line} />
      <NitroxText textType='body' style={styles.text}>{label}</NitroxText>
      <Separator style={styles.line} />
    </View>
  );
};

export default React.memo(Divider);
