/* eslint-disable schange-rules/no-relative-imports */
import firaSansBold from '../../assets/fonts/FiraSans-Bold.ttf';
import firaSansLight from '../../assets/fonts/FiraSans-Light.ttf';
import firaSansMedium from '../../assets/fonts/FiraSans-Medium.ttf';
import firaSansRegular from '../../assets/fonts/FiraSans-Regular.ttf';
import firaSansSemiBold from '../../assets/fonts/FiraSans-SemiBold.ttf';
import firaSansExtraCondensedRegular from '../../assets/fonts/FiraSansExtraCondensed-Regular.ttf';
import iconFont from '../../assets/fonts/nitro.ttf';

const createFontFace = (src: string, fontFamily: string) => `@font-face {
  src: url(${src});
  font-family: ${fontFamily};
}`;

const applyFontFaces = (...fontFaces: string[]) => {
  const style = document.createElement('style');
  style.type = 'text/css';
  style.appendChild(document.createTextNode(fontFaces.join('\n')));

  document.head.appendChild(style);
};

const iconFontFace = createFontFace(iconFont, 'nitro');
applyFontFaces(
  iconFontFace,
  createFontFace(firaSansLight, 'FiraSans-Light'),
  createFontFace(firaSansRegular, 'FiraSans-Regular'),
  createFontFace(firaSansMedium, 'FiraSans-Medium'),
  createFontFace(firaSansSemiBold, 'FiraSans-SemiBold'),
  createFontFace(firaSansBold, 'FiraSans-Bold'),
  createFontFace(firaSansExtraCondensedRegular, 'FiraSansExtraCondensed-Regular')
);
