import {createStyles} from 'common-styles';
import React from 'react';
import {View} from 'react-native';

import {dimensions} from 'common/constants';
import {TestProps} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxText from 'components/NitroxText';
import {useTestID} from 'hooks/Hooks';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  notification: {
    backgroundColor: colors.errorPopup.background,
    alignItems: 'center',
    justifyContent: 'center',
    padding: dimensions.margins.small
  },
  text: {
    textAlign: 'center',
    color: colors.errorPopup.text
  }
}));

type Props = {
  message: string;
} & TestProps;

const LoginErrorNotification: React.FunctionComponent<Props> = props => {
  const testID = useTestID(props, 'ErrorNotificationPopup') || 'popup_errornotification';
  const testIDMessage = useTestID(props, 'ErrorMessage') || 'text_errormessage';
  const styles = stylesUpdater.getStyles();

  return (
    <View style={styles.notification} testID={testID}>
      <NitroxText style={styles.text} testID={testIDMessage}>{props.message}</NitroxText>
    </View>
  );
};

export default React.memo(LoginErrorNotification);
