import {createStyles} from 'common-styles';
import React, {useMemo, useRef, useCallback, useState} from 'react';
import {Animated, Image, View} from 'react-native';

import {dimensions, RADIUS} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxButton, {NitroxButtonTheme} from 'components/NitroxButton';
import NitroxInteractive from 'components/NitroxInteractive';
import NitroxText from 'components/NitroxText';

const borderRadius = RADIUS * 2;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flexDirection: 'row',
    borderRadius: borderRadius,
    width: dimensions.notificationBox.width,
    height: dimensions.notificationBox.height
  },
  leftContainerView: {
    width: dimensions.notificationBox.height,
    height: dimensions.notificationBox.height,
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageContainerView: {
    width: dimensions.notificationBox.icon.size,
    height: dimensions.notificationBox.icon.size,
    backgroundColor: colors.mainMenu.notificationsPanel.box.icon.background,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: dimensions.notificationBox.icon.size / 2
  },
  imageView: {
    width: dimensions.notificationBox.icon.size,
    height: dimensions.notificationBox.icon.size
  },
  titleStyle: {
    color: colors.mainMenu.notificationsPanel.box.title.text,
    marginTop: dimensions.margins.medium
  },
  descriptionView: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center'
  },
  animatedBox: {
    width: 500,
    height: 85,
    marginTop: dimensions.margins.xsmall,
    marginBottom: dimensions.margins.xsmall,
    flexDirection: 'row'
  },
  dismissButton: {
    marginLeft: dimensions.margins.small,
    width: 85,
    height: 85,
    paddingLeft: 0,
    paddingRight: 0
  },
  bodyTextFocused: colors.mainMenu.notificationsPanel.box.body.text.focused,
  bodyTextUnFocused: colors.mainMenu.notificationsPanel.box.body.text.unfocused,
  bodyBackgroundFocused: colors.mainMenu.notificationsPanel.box.background.focused,
  bodyBackgroundUnFocused: colors.mainMenu.notificationsPanel.box.background.unfocused
}));

type NotificationIconProps = {
  icon: string;
};

const NotificationIcon: React.FunctionComponent<NotificationIconProps> = (props) => {
  const styles = stylesUpdater.getStyles();
  return (
    <View style={styles.leftContainerView}>
      <View style={styles.imageContainerView}>
        <Image style={styles.imageView} source={{uri: props.icon}} />
      </View>
    </View>
  );
};

type NotificationBoxProps = {
  icon: string;
  title: string;
  text: string;
  hasTVPreferredFocus?: boolean;
  disabled?: boolean;
  dismissText?: string;
  isDismissable?: boolean;
  onPress: () => void;
  onDismissPress?: () => void;
}

const NotificationBox: React.FunctionComponent<NotificationBoxProps> = (props) => {
  const [focused, onFocusStateChanged] = useState(false);
  const animation = useRef(new Animated.Value(30));
  const dismissBoxAnimation = useRef(new Animated.Value(100));

  const styles = stylesUpdater.getStyles();
  const boxStyle = useMemo(() => ({
    color: focused ? styles.bodyTextFocused : styles.bodyTextUnFocused,
    backgroundColor: focused ? styles.bodyBackgroundFocused : styles.bodyBackgroundUnFocused
  }), [focused, styles.bodyTextFocused, styles.bodyTextUnFocused, styles.bodyBackgroundFocused, styles.bodyBackgroundUnFocused]);

  const onDismissButtonFocused = useCallback(() => {
    Animated.parallel([
      Animated.timing(animation.current, {
        toValue: -65,
        duration: 250
      }),
      Animated.timing(dismissBoxAnimation.current, {
        toValue: 0,
        duration: 250
      })
    ]).start();
  }, []);

  const onDismissButtonBlured = useCallback(() => {
    Animated.parallel([
      Animated.timing(animation.current, {
        toValue: 30,
        duration: 250
      }),
      Animated.timing(dismissBoxAnimation.current, {
        toValue: 100,
        duration: 250
      })
    ]).start();
  }, []);

  return (
    <Animated.View style={[styles.animatedBox, {left: animation.current}]}>
      <NitroxInteractive
        activeOpacity={1}
        style={[styles.container, {backgroundColor: boxStyle.backgroundColor}]}
        onFocusStateChanged={onFocusStateChanged}
        onPress={props.onPress}
        disabled={props.disabled}
        hasTVPreferredFocus={props.hasTVPreferredFocus}
      >
        <NotificationIcon icon={props.icon} />
        <View style={styles.descriptionView}>
          {!!props.title && <NitroxText textType='callout-small-bold' style={styles.titleStyle} numberOfLines={1} ellipsizeMode='tail'>{props.title}</NitroxText>}
          {!!props.text && <NitroxText textType='callout-small' style={{color: boxStyle.color}} numberOfLines={2} ellipsizeMode='tail'>{props.text}</NitroxText>}
        </View>
      </NitroxInteractive>
      <Animated.View style={{left: dismissBoxAnimation.current}}>
        <NitroxButton
          disabled={!props.isDismissable}
          onPress={props.onDismissPress}
          onFocus={onDismissButtonFocused}
          onBlur={onDismissButtonBlured}
          theme={NitroxButtonTheme.Destructive}
          style={styles.dismissButton}
          border={true}
          text={props.dismissText}
        />
      </Animated.View>
    </Animated.View>
  );
};

export default React.memo(NotificationBox);
