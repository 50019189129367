import {EventEmitter} from 'common/EventEmitter';
import {Log} from 'common/Log';

import {Errors} from 'mw/api/Error';
import {Media, Playable, PlayableType} from 'mw/api/Metadata';
import {nxffConfig} from 'mw/api/NXFF';
import {PlaybackAsset} from 'mw/bo-proxy/BOInterface';
import {PlaybackParametersProvider} from 'mw/playback/types/PlaybackParameters';

export interface CreateSessionParams {
  playbackParametersProvider: PlaybackParametersProvider;
  playable: Playable;
  media: Media;
}

export interface SessionParams {
  readonly [key: string]: string;
}

export enum SessionState {
  Created = 'Created',
  Unauthorized = 'Unauthorized',
  EntitlementTimedOut = 'EntitlementTimedOut',
  Deleted = 'Deleted'
}

export enum SessionTerminationReason {
  UserAction = 'UserAction',
  Other = 'Other'
}

export enum PlaybackSessionEvent {
  SessionError
}

export class PlaybackSession extends EventEmitter<PlaybackSessionEvent> {
  public readonly id: string;
  public readonly assets: PlaybackAsset[];
  private currentAsset = 0;

  public constructor(id: string, assets: PlaybackAsset[]) {
    super();
    this.id = id;
    this.assets = assets;
  }

  public setCurrentAsset(asset: number): void {
    this.currentAsset = asset;
  }

  public getCurrentAssetNumber(): number {
    return this.currentAsset;
  }

  public getAsset(asset: number): PlaybackAsset {
    const assets = this.assets;
    if (!this.assets.length || asset >= assets.length) {
      throw Errors.PlaybackSessionNoAsset;
    }

    return this.assets[asset];
  }

  public getCurrentAsset(): PlaybackAsset {
    return this.assets[this.currentAsset];
  }

  public terminate(reason: SessionTerminationReason): Promise<void> {
    return Promise.resolve();
  }
}

const TAG = 'PlaybackSessionManager';

export abstract class PlaybackSessionManager {

  public createSession(params: CreateSessionParams): Promise<PlaybackSession> {
    const playableUrl = params.playable.getUrl();
    if (playableUrl) {
      Log.debug(TAG, 'There is no need to create new playback session - found playable URL', playableUrl);

      if (this.isInformativeSessionsNeeded(params.playable.getPlayableType())) {
        return this.createInformativeSessions(params);
      }

      return this.createFakePlaybackSession(params);
    }

    Log.debug(TAG, 'Creating new playback session');
    return this.createPlaybackSession(params);
  }

  public createFallbackSession(params: CreateSessionParams): Promise<PlaybackSession> {
    Log.debug(TAG, 'Creating new playback session');
    return this.createPlaybackSession(params);
  }

  public abstract destroySession(session: PlaybackSession, reason: SessionTerminationReason): Promise<void>;

  protected abstract createPlaybackSession(params: CreateSessionParams): Promise<PlaybackSession>;

  protected createFakePlaybackSession(params: CreateSessionParams): Promise<PlaybackSession> {
    const asset: PlaybackAsset = {
      url: params.playable.getUrl()
    };
    const assets = [asset];
    return Promise.resolve(new PlaybackSession(params.playable.getUrl(), assets));
  }

  protected createInformativeSessions(params: CreateSessionParams): Promise<PlaybackSession> {
    return this.createFakePlaybackSession(params);
  }

  private isInformativeSessionsNeeded(playableType: PlayableType): boolean {
    const enable5jInformativeSessions = nxffConfig.getConfig().Playback.Enable5jInformativeSessions;
    Log.debug(TAG, 'isInformativeSessionsNeeded enable5jInformativeSessions: ', enable5jInformativeSessions);
    return enable5jInformativeSessions && playableType === PlayableType.Channel;
  }
}

export class PlaybackStream {
  public readonly uid: string;
  public readonly type: string;
  public readonly duration: number;
  public readonly drmType?: string;

  public constructor(uid: string, type: string, duration: number, drmType?: string) {
    this.uid = uid;
    this.type = type;
    this.duration = duration;
    this.drmType = drmType;
  }
}
