import {createStyles} from 'common-styles';
import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import {NavigationScreenProps} from 'react-navigation';

import {dimensions, isMobile} from 'common/constants';

import {StylesUpdater, useStyles} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {nxff} from 'mw/api/NXFF';
import {NativeSystemModule} from 'mw/platform/native-system/NativeSystemModule.ios';

import {IconType} from 'components/Icon';
import NitroxText from 'components/NitroxText';
import SettingsDetails from 'screens/settings/SettingsDetails';
import {SettingsDetailsHeader} from 'screens/settings/SettingsDetailsHeader';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  text: {
    color: colors.settingsScreen.mobile.profile.clickableOption.title
  }
}));

const styles = createStyles({
  container: {
    paddingTop: dimensions.screen.settings.container.paddingTop,
    paddingHorizontal: dimensions.screen.container.paddingHorizontal,
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'center'
  },
  textContainer: {
    ...isMobile && {alignSelf: 'center'}
  }
});

const SettingsHelpScreen: React.FC<NavigationScreenProps<{}>> = props => {
  const {t} = useTranslation();
  const dynamicStyle = useStyles(stylesUpdater);

  const helpUrl = useMemo(() => nxff.getConfig().UI.HelpURL, []);

  const onLinkPress = useCallback(() => {
    if (!helpUrl) {
      return;
    }
    NativeSystemModule.openNativeApplicationLink(helpUrl);
  }, [helpUrl]);

  return (
    <SettingsDetails
      title={t('settings.help')}
      header={<SettingsDetailsHeader icon={IconType.HelpBig} />}
      navigation={props.navigation}
      testID='screen_settings_help'
    >
      <View style={styles.container}>
        <NitroxText textType={'buttons-settings'} style={[dynamicStyle.text, styles.textContainer]}>{t('settings.helpMessage')}</NitroxText>
        <NitroxText textType={'buttons-settings'} style={[dynamicStyle.text, styles.textContainer]} onPress={onLinkPress}>{` ${helpUrl}`}</NitroxText>
      </View>
    </SettingsDetails>
  );
};

export default SettingsHelpScreen;
