import {EventEmitter} from 'common/EventEmitter';
import {flatten} from 'common/HelperFunctions';
import {Log} from 'common/Log';

const TAG = 'Pager';

export enum PagerEvent {
  loadingStarted = 'loadingStarted', // loading started - receives a single boolean argument which indicates whether the first page is being loaded or not
  dataChanged = 'dataChanged', // existing data changed somehow - receives a PagerDataChangedEvent event object as an argument
  errorOccured = 'errorOccured' // something went wrong - receives an error object as an argument
}

export enum PagerDataChangeType {
  added = 'added',
  removed = 'removed'
}

export type PagerDataChangedEvent<DataType> = {
  data: DataType[]; // all currently available items
  hasMoreData: boolean; // are there any more items available
  changeType: PagerDataChangeType; // what happened to the data
  changedItemsStartIndex: number; // where the changed started in the data
  numChangedItems: number; // how many items were changed
};

export type PagerRequestHandler<DataType> = (offset: number, limit: number) => Promise<DataType[]>;

export class Pager<DataType> extends EventEmitter<PagerEvent> {
  private requestHandler: PagerRequestHandler<DataType>;
  private pageSize: number;
  private data: DataType[] = [];
  private hasMoreData = true;
  private pendingPromise: Promise<void> | null = null;

  public constructor(requestHandler: PagerRequestHandler<DataType>, pageSize: number) {
    super();
    this.requestHandler = requestHandler;
    this.pageSize = pageSize;
  }

  public clear = () => {
    const length = this.data.length;
    this.data = [];
    this.hasMoreData = true;
    this.pendingPromise = null;
    this.notify(PagerEvent.dataChanged, {
      data: this.data,
      hasMoreData: this.hasMoreData,
      changeType: PagerDataChangeType.removed,
      changedItemsStartIndex: 0,
      numChangedItems: length
    });
  }

  // requestes pages based on numerical ranges of required items - useful for integration with the NitroxScrollView
  public requestRange = async (startIndex: number, endIndex: number): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      Log.trace(TAG, 'Getting items between indicies ' + startIndex + ' and ' + endIndex);
      if (!this.hasMoreData) {
        Log.debug(TAG, 'There are no more items available - ignoring request');
        resolve();
        return;
      }
      this.notify(PagerEvent.loadingStarted, (this.data.length === 0));
      const alignedStartIndex = Math.max(this.data.length, startIndex);
      const pageStartIndex = Math.floor(alignedStartIndex / this.pageSize);
      const pageEndIndex = pageStartIndex + Math.ceil((endIndex - alignedStartIndex) / this.pageSize);
      const pendingRequests: Promise<DataType[]>[] = [];
      for (let pageIndex = pageStartIndex; pageIndex < pageEndIndex; ++pageIndex) {
        Log.trace(TAG, 'Requesting items for page ' + pageIndex);
        pendingRequests.push(this.requestHandler(pageIndex * this.pageSize, this.pageSize));
      }
      Promise.all(pendingRequests)
        .then(newPages => {
          const newData = flatten(newPages);
          this.hasMoreData = newData.length >= this.pageSize * newPages.length;
          Log.debug(TAG, 'Got ' + newData.length + '  items for ' + newPages.length + ' pages' + (this.hasMoreData ? '' : ' - there are no more items available'));
          const index = pageStartIndex * this.pageSize;
          const data = Array.from(this.data);
          Array.prototype.splice.apply(data, ([index, data.length] as any).concat(newData));
          this.notify(PagerEvent.dataChanged, {
            data: this.data,
            hasMoreData: this.hasMoreData,
            changeType: PagerDataChangeType.added,
            changedItemsStartIndex: index,
            numChangedItems: newData.length
          });
          resolve();
        })
        .catch(error => {
          Log.error(TAG, 'Failed to get the requested items - got error ' + error);
          this.hasMoreData = false;
          this.notify(PagerEvent.errorOccured, error);
          reject(error);
        });
    });
  }

  // requests additional pages - useful for integration with the FlatList
  public requestNextPage = (): Promise<void> => {
    if (this.pendingPromise) {
      Log.debug(TAG, 'Fetching data in progress, returning pending promise...');
      return this.pendingPromise;
    }

    this.pendingPromise = new Promise<void>((resolve, reject) => {
      const index = this.data.length;
      Log.trace(TAG, 'Getting ' + this.pageSize + ' items starting from index ' + index);
      if (!this.hasMoreData) {
        Log.debug(TAG, 'There are no more items available - ignoring request');
        resolve();
        return;
      }
      this.notify(PagerEvent.loadingStarted, (this.data.length === 0));
      this.requestHandler(this.data.length, this.pageSize)
        .then(newData => {
          this.hasMoreData = newData.length >= this.pageSize;
          Log.debug(TAG, 'Got ' + newData.length + ' items starting from index ' + index + (this.hasMoreData ? '' : ' - there are no more items available'));
          this.data = Array.from(this.data).concat(newData);
          this.notify(PagerEvent.dataChanged, {
            data: this.data,
            hasMoreData: this.hasMoreData,
            changeType: PagerDataChangeType.added,
            changedItemsStartIndex: index,
            numChangedItems: newData.length
          });
          resolve();
        })
        .catch(error => {
          Log.error(TAG, 'Failed to get the requested items - got error ' + error);
          this.hasMoreData = false;
          this.notify(PagerEvent.errorOccured, error);
          reject(error);
        });
    }).finally(() => this.pendingPromise = null);
    return this.pendingPromise;
  }
}
