import {createStyles} from 'common-styles';
import Moment from 'moment';
import {extendMoment} from 'moment-range';
import React, {useState, useCallback, useRef, useEffect, useMemo, useImperativeHandle, Ref} from 'react';
import {ViewStyle, LayoutChangeEvent, View, Animated, ActivityIndicator, StyleSheet} from 'react-native';
import {NavigationEventPayload} from 'react-navigation';

import {dimensions, isMobile, isBigScreen, isWeb, isTablet, epgItemHeight} from 'common/constants';
import {zeroPoint} from 'common/HelperFunctions';
import {Rect, Size, Point, Hashmap} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Channel, Event, isChannel} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import FloatingBubble, {FloatingBubbleType} from 'components/FloatingBubble';
import {IconType} from 'components/Icon';
import {ListShadow, ListShadowPosition} from 'components/ListShadow';
import {FAR_FAR_AWAY} from 'components/navigation/ResourceSavingScene';
import {TagTypes} from 'components/NitroxTag';
import {useChangeEffect, useDisposableCallback, useWillAppear, useCurrentTime} from 'hooks/Hooks';

import EpgChannelList, {channelsContainerWidth} from './EpgChannelList';
import {EpgFocusDriver} from './EpgFocusDriver';
import EpgLine from './EpgLine';
import {RenderedItem, ItemPosition} from './EpgNitroxContentView';
import {EpgNitroxScrollView, LayoutItem, ScrollDirection, ScrollEvent, EpgNitroxScrollViewInterface, forcedScrollParams} from './EpgNitroxScrollView';
import EpgTile, {EpgTileInterface} from './EpgTile';
import EpgTimebar from './EpgTimebar';
import EpgTimebarTile, {EpgTimebarRef} from './EpgTimebarTile';
import TouchableEpgTile from './TouchableEpgTile';

const TAG = 'EpgGrid';
const moment = extendMoment(Moment as any);

const staticStyles = createStyles({
  spinnerContainer: {
    position: 'absolute',
    alignSelf: 'center'
  }
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flex: 1,
    backgroundColor: colors.epgScreen.grid.background,
    justifyContent: 'center'
  },
  floatingBubbleText: {
    color: colors.epgScreen.epgGridTimeBarBubble.text
  },
  floatingBubbleBackground: colors.epgScreen.epgGridTimeBarBubble.background,
  gridContainer: {
    flex: 1,
    flexDirection: 'row'
  },
  epgGrid: {
    flex: 1
  },
  epgLine: {
    height: '100%'
  },
  epgTimebarTileFuture: {
    marginLeft: dimensions.margins.medium,
    backgroundColor: colors.epgScreen.timeBar.background.future
  },
  epgTimebarTilePast: {
    backgroundColor: colors.epgScreen.timeBar.background.past
  },
  spinnerColor: colors.defaultColors.spinner,
  timebarBorder: {
    backgroundColor: colors.epgScreen.timeBar.border,
    height: 48,
    width: StyleSheet.hairlineWidth,
    position: 'absolute',
    top: dimensions.margins.small,
    left: channelsContainerWidth - StyleSheet.hairlineWidth
  },
  listShadow: {
    bottom: 0
  }
}));

export interface EpgGridProps {
  hourWidth: number;
  originTime: Moment.Moment;
  style?: ViewStyle;
  channels: Channel[];
  currentChannel: Channel;
  onEventFocus: (event?: Event) => void;
  onEventPress?: (event: Event) => void;
  onScrollHorizontal?: (position: number) => void;
  onChannelPress?: (channelId: string) => void;
  onSetContentStartOffset?: (point: Point) => void;
  onLayout?: (size: Size) => void;
  scrollPositionRef: React.RefObject<Animated.ValueXY>;
  useFocusDriver?: boolean;
  showIcons?: boolean;
  focusedEvent?: Event;
}

export interface EpgGridInterface {
  scrollByPage: (axis: ScrollDirection, offset: number) => void;
  scrollToEvent: (event: Event) => void;
  scrollToDate: (date: Date) => void;
  scrollToNow: () => void;
  updateEventView: (event: Event) => void;
}

const epgTimebarItemHeight = isMobile ? 30 : 68;
const epgTimebarItemY = 0;
export const epgTimebarHeight = epgTimebarItemHeight;
const epgTimebarItemDuration = 30; // in minutes
const epgTimebarAndEpgLineSyncTimeout = 30000; // in milliseconds
const epgGridScrollDirection = ScrollDirection.Both;

export enum ScrollAxis {
  Vertical,
  Horizontal
}

const keyExtractor = (event: Event): string => event.channelId + event.start;

const EpgGridComponent: React.FunctionComponent<EpgGridProps> = (props, ref: Ref<EpgGridInterface>) => {
  const currentTime = useCurrentTime();
  const [viewSize, setViewSize] = useState<Size>({width: 0, height: 0});
  const [contentStartOffset, setContentStartOffset] = useState<Point>({x: -1, y: -1});
  const timebarRef = useRef<EpgNitroxScrollViewInterface<Moment.Moment>>(null);
  const focusDriverRef = useRef<EpgFocusDriver>(null);
  const {hourWidth, originTime, style, channels, currentChannel, onEventFocus, onEventPress, onChannelPress} = props;
  const timebarLineRef = useRef<EpgLine>(null);
  const gridLineRef = useRef<EpgLine>(null);
  const [nowTimeStart, setNowTimeStart] = useState<Moment.Moment>();
  const activeTimebarRef = useRef<any>();
  const startTimeOfActiveTimebarRef = useRef<Moment.Moment>();
  const epgItemsViewRef = useRef<EpgNitroxScrollViewInterface<Event>>(null);
  const floatingBubbleRef = useRef<FloatingBubble>(null);
  const channelListRef = useRef<EpgNitroxScrollViewInterface<Channel>>(null);
  const [entranceItemPoint, setEntranceItemPoint] = useState({x: 0, y: 0});
  const [fetchingData, setFetchingData] = useState(true);

  const {onLayout: propsOnLayout, onSetContentStartOffset: propsOnSetContentStartOffset, onScrollHorizontal: propsOnScrollHorizontal, focusedEvent} = props;

  const styles = stylesUpdater.getStyles();

  const epgTimebarItemWidth = Math.floor(hourWidth / 2);

  const syncTimebarAndEpgLine = useDisposableCallback(() => {
    // set new position for vertical line
    const positionX = moment.duration(moment().diff(originTime)).asHours() * hourWidth + contentStartOffset.x;
    const timeBarPositionX = positionX;
    timebarLineRef.current?.setX(timeBarPositionX);
    gridLineRef.current?.setX(positionX);
    floatingBubbleRef.current?.setX(timeBarPositionX);

    // set past time bar
    if (!startTimeOfActiveTimebarRef.current) {
      return;
    }
    const timeEnd = moment(startTimeOfActiveTimebarRef.current).add(epgTimebarItemDuration, 'minutes');
    const isBetween = (moment(startTimeOfActiveTimebarRef.current) < moment() && moment() < timeEnd);
    if (isBetween) {
      activeTimebarRef && activeTimebarRef.current && activeTimebarRef.current.updateProgress();
    } else {
      activeTimebarRef.current = undefined;
      setNowTimeStart(timeEnd);
    }
  }, [contentStartOffset.x, hourWidth, originTime]);

  useChangeEffect(() => {
    if (contentStartOffset.x < 0 || contentStartOffset.y < 0) {
      return;
    }
    const timer = setInterval(syncTimebarAndEpgLine, epgTimebarAndEpgLineSyncTimeout);
    requestAnimationFrame(syncTimebarAndEpgLine);
    return () => clearInterval(timer);
  }, [contentStartOffset.x, contentStartOffset.y, startTimeOfActiveTimebarRef.current], [syncTimebarAndEpgLine]);

  const renderItemForTimebar = (time: Moment.Moment, ref: EpgTimebarRef): React.ReactElement => {
    const timeEnd = moment(time).add(epgTimebarItemDuration, 'minutes');
    const isBetween = (moment(time) < moment() && moment() < timeEnd);
    if (isBetween) {
      startTimeOfActiveTimebarRef.current = time;
      return <EpgTimebarTile dynamicProgress textStyle={{marginLeft: dimensions.margins.medium}} ref={ref} time={time} timeEnd={timeEnd} />;
    } else if (timeEnd < moment()) {
      // Past time
      return <EpgTimebarTile style={styles.epgTimebarTilePast} textStyle={{marginLeft: dimensions.margins.medium}} time={time} />;
    } else {
      // Future time
      return <EpgTimebarTile textStyle={styles.epgTimebarTileFuture} time={time} />;
    }
  };

  const channelIdToIndex: {[id: string]: number} = useMemo(() => {
    const retVal: {[id: string]: number} = {};
    channels.forEach((channel, index) => retVal[channel.id] = index);
    return retVal;
  }, [channels]);

  const computeFrameForEvent = useCallback((event: Event): Rect => {
    return ({
      x: Math.round(moment.duration(moment(event.start).diff(originTime)).asHours() * hourWidth),
      y: Math.floor(channelIdToIndex[event.channelId] * epgItemHeight),
      width: Math.floor(moment.duration(moment(event.end).diff(event.start)).asHours() * hourWidth),
      height: epgItemHeight
    });
  }, [originTime, hourWidth, channelIdToIndex]);

  const layoutItemsForRect = useCallback(async (rect: Rect): Promise<LayoutItem<Event>[]> => {
    const channelsStartIndex = Math.max(0, Math.floor(rect.y / epgItemHeight));
    const channelsEndIndex = Math.floor((rect.y + rect.height) / epgItemHeight);
    if (channelsEndIndex < 0) {
      return [];
    }
    setFetchingData(true);
    const numChannels = Math.max(1, 1 + channelsEndIndex - channelsStartIndex); // channelsStartIndex and channelsEndIndex are both including endpoints
    const visibleChannels = channels.slice(channelsStartIndex, channelsStartIndex + numChannels);

    const startTime = moment(originTime)
      .add(rect.x / hourWidth, 'hour')
      .toDate();
    const endTime = moment(originTime)
      .add((rect.x + rect.width) / hourWidth, 'hour')
      .toDate();
    await mw.catalog.unblockIdleActions();
    const epg = await mw.catalog.getEPG({channels: visibleChannels, startTime, endTime, includeIsRecorded: props.showIcons});

    // TODO: remove uniqueEventsMap when EPGCache wound't return doubled items
    const uniqueEventsMap: Hashmap<boolean> = {};
    let layoutItems: LayoutItem<Event>[] = [];
    epg.forEach(events => {
      const uniqueEvents = events.filter(event => {
        if (uniqueEventsMap[event.id]) {
          return false;
        }
        uniqueEventsMap[event.id] = true;
        return true;
      });
      layoutItems = layoutItems.concat(uniqueEvents.map(event => ({
        ...computeFrameForEvent(event),
        data: event
      })));
    });
    return layoutItems;
  }, [channels, originTime, hourWidth, props.showIcons, computeFrameForEvent]);

  const eventFocus = useCallback(event => {
    onEventFocus(event);
    // Can't store focusDriverRef.current in local value due to eventFocus is used in child component which doesn't
    // know about focusDriverRef updates
    const frame = focusDriverRef.current && focusDriverRef.current.frameForFocusedItem();

    isWeb
      && frame
      && focusDriverRef.current
      && epgItemsViewRef.current
      && epgItemsViewRef.current.scrollToFrame(frame, true);
  }, [onEventFocus, epgItemsViewRef, focusDriverRef]);

  const eventPress = useCallback(event => {
    if (onEventPress) {
      onEventPress(event);
    }
  }, [onEventPress]);

  const renderItem = useCallback((event: Event, ref: Ref<EpgTileInterface>, layout: ItemPosition) => {
    const epgTileProps = {ref, event, width: layout.width, onFocus: eventFocus, onPress: eventPress, showIcons: props.showIcons, excludeIcons: [TagTypes.LIVE, IconType.Check]};
    return props.useFocusDriver
      ? <EpgTile {...epgTileProps} />
      : <TouchableEpgTile {...epgTileProps} />;
  }, [eventFocus, eventPress, props.showIcons, props.useFocusDriver]);

  const layoutItemsForTimebar = useCallback(async (rect: Rect): Promise<LayoutItem<Moment.Moment>[]> => {
    const startTime = moment(originTime)
      .add(rect.x / hourWidth, 'hour')
      .startOf('hour');
    const endTime = moment(originTime)
      .add((rect.x + rect.width) / hourWidth, 'hour')
      // using 'endOf' here to prevent gaps in timebar
      // using 'startOf' in both start and end time, sometimes causes slots omitting
      // while both can introduce duplicates, there's higher proabability in case of eager 'endOf' matching
      // on the other side - duplicates are handled and filtered out by NitroxScrollView
      .endOf('hour');
    const timeSlots = Array.from(moment.range(startTime, endTime).by('minutes', {step: epgTimebarItemDuration}));
    return timeSlots.map(item => ({
      x: moment.duration(moment(item).diff(originTime)).asHours() * hourWidth,
      y: epgTimebarItemY,
      width: epgTimebarItemWidth,
      height: epgTimebarItemHeight,
      data: item
    }));
  }, [epgTimebarItemWidth, hourWidth, originTime]);

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    const size: Size = {width: event.nativeEvent.layout.width, height: event.nativeEvent.layout.height};
    setViewSize(size);
    propsOnLayout && propsOnLayout(size);
  }, [propsOnLayout]);

  useChangeEffect(() => {
    focusDriverRef.current?.onVisibleFrameSizeChanged(viewSize);
  }, [focusDriverRef.current, viewSize]);

  const onViewPress = useCallback(async (position: Point) => {
    const item = epgItemsViewRef.current?.getItemByPoint(position);
    item?.data && eventPress?.(item.data);
  }, [eventPress]);

  const scrollHorizontal = useCallback(({offset: {x}}: ScrollEvent) => {
    x > 0 && timebarRef.current?.scrollToXPosition(x, forcedScrollParams);
    focusDriverRef.current?.onVisibleFramePositionChanged({x});
    propsOnScrollHorizontal && propsOnScrollHorizontal(x);
  }, [propsOnScrollHorizontal]);

  const scrollVertical = useCallback(({offset: {y}}: ScrollEvent) => {
    focusDriverRef.current?.onVisibleFramePositionChanged({y});
    channelListRef.current?.scrollToYPosition(y, forcedScrollParams);
  }, []);

  const onTimebarContentLayout = useCallback((items: Hashmap<RenderedItem<Moment.Moment>>) => {
    Object.values(items).forEach(item => {
      if (item.ref.current !== null) {
        activeTimebarRef.current = item.ref.current;
      }
    });
  }, []);

  const onContentLayout = useCallback((items: Hashmap<RenderedItem<Event>>) => {
    focusDriverRef.current?.newLayout(items);
    setFetchingData(false);
  }, [focusDriverRef]);

  const scrollToEvent = useCallback((event: Event): void => {
    const frame = computeFrameForEvent(event);
    epgItemsViewRef.current?.scrollToFrame(frame);
  }, [epgItemsViewRef, computeFrameForEvent]);

  const scrollByPage = useCallback((axis: ScrollDirection, offset: number): void => {
    if (!viewSize.width || !viewSize.height) {
      Log.info(TAG, `Can't perform scrollByPage due to invalid viewSize`);
      return;
    }
    const size = axis === ScrollDirection.Horizontal ? viewSize.width : viewSize.height;
    epgItemsViewRef.current?.scrollByOffset(axis, offset * size);
  }, [viewSize.width, viewSize.height, epgItemsViewRef]);

  const getInitiallyFocusedEvent = useCallback(async (): Promise<Event | null> => {
    let channel = mw.players.main.getCurrentMedia();
    if (!isChannel(channel)) {
      channel = await mw.catalog.getLastPlayedChannel();
    }
    const event = await mw.catalog.getCurrentEvent(channel as Channel);
    if (event && typeof channelIdToIndex[event.channelId] !== 'undefined') {
      return event;
    }
    return null;
  }, [channelIdToIndex]);

  const restoreFocus = useCallback(() => {
    const focusDriverFrame = focusDriverRef.current?.frameForFocusedItem();
    const focusedEventFrame = focusedEvent && computeFrameForEvent(focusedEvent);
    const position = focusedEventFrame || focusDriverFrame;
    if (position) {
      setEntranceItemPoint(position);
    } else {
      Log.warn(TAG, `Unable to restore focus - focused position is missing`);
    }
  }, [focusedEvent, computeFrameForEvent]);

  const resetScrollPositionAndFocus = useCallback(() => {
    if (contentStartOffset.x < 0 || contentStartOffset.y < 0 || hourWidth <= 0) {
      return;
    }
    getInitiallyFocusedEvent()
      .then(event => {
        const eventOrigin: Point = event ? computeFrameForEvent(event) : zeroPoint();
        const startTime = moment().startOf('hour');
        const delta = moment.duration(startTime.diff(moment(originTime))).asHours();
        const position: Point = {
          x: delta * hourWidth + contentStartOffset.x,
          y: eventOrigin.y
        };
        // do not use scrollToFrame here because of the need to force scroll to the given position without any additional adjustments based on current offset
        epgItemsViewRef.current?.scrollToPosition(position, forcedScrollParams);
        timebarRef.current?.scrollToPosition(position, forcedScrollParams);
        setEntranceItemPoint({x: Math.max(contentStartOffset.x + eventOrigin.x, position.x), y: eventOrigin.y});
      });
  }, [originTime, hourWidth, contentStartOffset.x, contentStartOffset.y, getInitiallyFocusedEvent, computeFrameForEvent]);

  useChangeEffect(() => {
    resetScrollPositionAndFocus();
  }, [contentStartOffset, hourWidth], [resetScrollPositionAndFocus]);

  const scrollToDate = useCallback((date: Date): void => {
    const focusDriverFrame = focusDriverRef.current?.frameForFocusedItem();
    const focusedEventFrame = focusedEvent && computeFrameForEvent(focusedEvent);
    const startTime = moment(date);
    const position: Point = {
      x: moment.duration(startTime.diff(moment(originTime))).asHours() * hourWidth + contentStartOffset.x,
      y: focusedEventFrame?.y ?? focusDriverFrame?.y ?? 0
    };
    epgItemsViewRef.current?.scrollToXPosition(position.x, forcedScrollParams);
    timebarRef.current?.scrollToXPosition(position.x, forcedScrollParams);
    setEntranceItemPoint({x: position.x + moment.duration(moment().diff(startTime)).asHours() * hourWidth, y: position.y});
  }, [originTime, hourWidth, contentStartOffset.x, focusedEvent, computeFrameForEvent]);

  const scrollToNow = useCallback((): void => {
    scrollToDate(moment().startOf('hour')
      .toDate());
  }, [scrollToDate]);

  const onSetContentStartOffset = useCallback((point: Point) => {
    setContentStartOffset(point);
    propsOnSetContentStartOffset && propsOnSetContentStartOffset(point);
  }, [propsOnSetContentStartOffset]);

  const updateEventView = useCallback((event: Event) => {
    const frame = computeFrameForEvent(event);
    // epg views are overlaped, so we need to add 1dp to exclude preceding views.
    const item = epgItemsViewRef.current?.getItemByPoint({x: frame.x + 1, y: frame.y + 1});
    item?.ref.current?.forceUpdate();
  }, [computeFrameForEvent]);

  useImperativeHandle(ref, () => ({
    scrollToEvent,
    scrollByPage,
    scrollToDate,
    scrollToNow,
    updateEventView
  }), [scrollToEvent, scrollByPage, scrollToDate, scrollToNow, updateEventView]);

  useEffect(() => {
    timebarRef.current?.invalidateLayout();
  }, [hourWidth, nowTimeStart]);

  useWillAppear(
    useCallback((payload: NavigationEventPayload) => {
      if (payload.action.type === 'Navigation/BACK' || payload.action.type === 'Navigation/POP') {
        restoreFocus();
      } else {
        resetScrollPositionAndFocus();
      }
    }, [resetScrollPositionAndFocus, restoreFocus])
  );

  const timeBarStyle = useMemo(() => ({
    height: epgTimebarHeight,
    left: channelsContainerWidth,
    paddingRight: channelsContainerWidth,
    marginBottom: isTablet ? dimensions.margins.small : 0
  }), []);

  return (
    <View style={[style, styles.container]} testID='epg_grid'>
      {isBigScreen && <View style={styles.timebarBorder} />}
      <EpgTimebar
        ref={timebarRef}
        style={timeBarStyle}
        layoutItemsForRect={layoutItemsForTimebar}
        renderItem={renderItemForTimebar}
        scrollEnabled={false}
        contentHeight={epgTimebarHeight}
        itemWidth={epgTimebarItemWidth}
        scrollPosition={props.scrollPositionRef.current}
        controlled={isMobile}
        onContentLayout={onTimebarContentLayout}
      >
        {isBigScreen && <EpgLine ref={timebarLineRef} style={styles.epgLine} label />}
        {isTablet && (
          <FloatingBubble
            ref={floatingBubbleRef}
            label={currentTime}
            textStyle={styles.floatingBubbleText}
            type={FloatingBubbleType.Secondary}
            backgroundColor={styles.floatingBubbleBackground}
            pointerLineHeight={epgTimebarHeight}
            initialPositionX={-FAR_FAR_AWAY}
          />
        )}
      </EpgTimebar>
      <View style={styles.gridContainer}>
        <EpgChannelList
          ref={channelListRef}
          scrollPositionRef={props.scrollPositionRef}
          channels={channels}
          currentChannel={currentChannel}
          onChannelPress={onChannelPress}
        />
        <EpgNitroxScrollView<Event>
          debugName={'EpgGrid'}
          ref={epgItemsViewRef}
          // this is a workaround to ensure that EpgFocusDriver uses always valid props values
          extraData={entranceItemPoint}
          onLayout={onLayout}
          onSetContentStartOffset={onSetContentStartOffset}
          viewSize={viewSize}
          style={styles.epgGrid}
          layoutItemsForRect={layoutItemsForRect}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          contentHeight={channels.length * epgItemHeight}
          itemHeight={epgItemHeight}
          direction={epgGridScrollDirection}
          onScrollHorizontal={scrollHorizontal}
          scrollPosition={props.scrollPositionRef.current}
          onScrollVertical={scrollVertical}
          showIndicators={false}
          onPress={onViewPress}
          onContentLayout={onContentLayout}
        >
          {props.useFocusDriver && <EpgFocusDriver ref={focusDriverRef} entranceItemPoint={entranceItemPoint} />}
          {isBigScreen && <EpgLine onLayout={syncTimebarAndEpgLine} ref={gridLineRef} style={styles.epgLine} />}
        </EpgNitroxScrollView>
      </View>
      {fetchingData && (
        <View style={staticStyles.spinnerContainer}>
          <ActivityIndicator size={dimensions.icon.xlarge} color={styles.spinnerColor} animating={fetchingData} />
        </View>
      )}
      {isTablet && (
        <ListShadow direction={ListShadowPosition.Bottom} style={styles.listShadow} />
      )}
    </View>
  );
};

export const EpgGrid = React.memo(React.forwardRef<EpgGridInterface, EpgGridProps>(EpgGridComponent));
