import {StringKeyed} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {Link} from 'mw/cms/Menu';

import i18n from 'locales/i18n';

const TAG = 'CMSData';

let cms: CMSData;
try {
  cms = require('brand/current/cms.json');
} catch {
  Log.warn(TAG, 'cms configuration missing! Using default');
  cms = require('brand/default/cms.json');
}

export type CMSData = {
  [slug: string]: CMSMenu;
}

interface CMSMenu extends StringKeyed {
  title: string;
  screen: string;
  items: string[];
  link?: Link;
  platforms?: string[];
  tag?: string[];
}

function translateMenu(menu: CMSMenu): CMSMenu {
  const {title} = menu;
  return {
    ...menu,
    get title() {
      return i18n.t(title);
    }
  };
}

function translateCMSData(data: CMSData): CMSData {
  return Object.entries(data).reduce(
    (translatedData, [slug, menu]) => ({
      ...translatedData,
      [slug]: translateMenu(menu)
    }),
    {} as CMSData
  );
}

const cmsData = translateCMSData(cms);
export default cmsData;
