import {createStyles} from 'common-styles';
import React, {PureComponent} from 'react';
import {NavigationScreenProps} from 'react-navigation';

import {disposable} from 'common/Async';
import {isMobile} from 'common/constants';
import {doNothing} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Error, ErrorType} from 'mw/api/Error';
import {PinState} from 'mw/api/Profile';
import {mw} from 'mw/MW';

import CreatePinPopup from 'components/CreatePinPopup';
import LogoutOverlay from 'components/LogoutOverlay';
import {WithUnlockModal, withUnlockModal} from 'components/unlockmodal/UnlockModal';
import SettingsScreenGrosso from 'screens/settings/SettingsScreen.grosso';
import SettingsScreenPiccolo from 'screens/settings/SettingsScreen.piccolo';

const TAG = 'SettingsScreen';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    alignItems: 'stretch',
    backgroundColor: colors.settingsScreen.background,
    flex: 1,
    flexDirection: 'column'
  }
}));

function shouldSetProfilePin() {
  return mw.customer.getProfile().pinState === PinState.ProfilePinNotSet;
}

function shouldAuthorizeWithAdultProfile() {
  return mw.customer.getProfile().isPCEnabled && !mw.customer.getProfile().isMain;
}

type Props = NavigationScreenProps<{}> & WithUnlockModal;

type State = {
  isLogoutModalVisible: boolean;
  createPinPopupVisible: boolean;
}

class SettingsScreen extends PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      isLogoutModalVisible: false,
      createPinPopupVisible: false
    };
  }

  public componentWillUnmount() {
    this.setProfilePin.dispose();
  }

  private showLogoutModal = () => {
    this.setState({isLogoutModalVisible: true});
  }

  private hideLogoutModal = () => {
    this.setState({isLogoutModalVisible: false});
  }

  private async onLogout() {
    await mw.bo.logout();
  }

  private async onAndroidSettingsPress() {
    try {
      await mw.system.openSystemSettings();
    } catch (error) {
      Log.error(TAG, 'openSystemSettings() error', error);
    }
  }

  private onSuccess = doNothing;
  private onCancel = doNothing;

  private closeCreatePinPopup() {
    this.onSuccess = doNothing;
    this.onCancel = doNothing;
    this.setState({createPinPopupVisible: false});
  }

  private createProfilePin(): Promise<string> {
    this.setState({createPinPopupVisible: true});
    return new Promise((resolve, reject) => {
      this.onSuccess = () => {
        resolve();
        this.closeCreatePinPopup();
      };
      this.onCancel = () => {
        reject();
        this.closeCreatePinPopup();
      };
    });
  }

  private authorizeWithPin = async (parentalControl?: boolean): Promise<string> => {
    if (parentalControl) {
      if (shouldSetProfilePin()) {
        return this.createProfilePin()
          .then((value) => (
            shouldAuthorizeWithAdultProfile()
              ? this.props.authorizeWithAdultProfile()
              : value
          ));
      }
      if (shouldAuthorizeWithAdultProfile()) {
        return this.props.authorizeWithAdultProfile();
      }
    }
    return this.props.authorizeWithCurrentProfile();
  }

  // Setting property value does not cause rerender, thus updating this.onSuccess could not have any effect at all if passed directly
  private onCreatePinCancel = () => this.onCancel?.();

  private setProfilePin = disposable((pin: string) => {
    const profile = mw.customer.currentProfile;
    if (!profile) {
      return Promise.reject(new Error(ErrorType.ProfileNotFound, 'Current profile not found'));
    }

    return profile.setPin(pin)
      .then(() => profile.setPinState(PinState.ProfilePinNotRequired));
  });

  private onCreateProfilePin = (pin: string) => {
    return this.setProfilePin(pin)
      .then(() => this.onSuccess?.());
  }

  public render() {
    const styles = stylesUpdater.getStyles();
    const commonProps = {
      style: styles.container,
      navigation: this.props.navigation,
      showLogoutModal: this.showLogoutModal,
      onLogout: this.onLogout,
      authorizeWithPin: this.authorizeWithPin,
      renderModals: () => (
        <>
          <LogoutOverlay
            onClose={this.hideLogoutModal}
            visible={this.state.isLogoutModalVisible}
            onLogout={this.onLogout}
          />
          <CreatePinPopup visible={this.state.createPinPopupVisible} onCreatePin={this.onCreateProfilePin} onCancel={this.onCreatePinCancel} />
          {this.props.renderUnlockModal()}
        </>
      )
    };

    const nativeExtraProps = {
      onAndroidSettingsPress: this.onAndroidSettingsPress
    };

    return (
      <React.Fragment>
        {isMobile ? (
          <SettingsScreenPiccolo
            {...commonProps}
          />
        ) : (
          <SettingsScreenGrosso
            {...commonProps}
            {...nativeExtraProps}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withUnlockModal(SettingsScreen);
