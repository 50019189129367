import {createStyles} from 'common-styles';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {ScrollView} from 'react-native';

import {dimensions} from 'common/constants';

import {DateFormatDescription, TimeFormatDescription} from 'mw/api/NXFF';
import {mw} from 'mw/MW';

import ErrorPopup, {useErrorPopup} from 'components/ErrorPopup';
import {IconType} from 'components/Icon';
import {useDisposable, useNavigation, useCurrentProfile} from 'hooks/Hooks';
import SettingsChangeFormat from 'screens/settings/SettingsChangeFormat';
import SettingsDetails from 'screens/settings/SettingsDetails';
import {SettingsDetailsHeader} from 'screens/settings/SettingsDetailsHeader';

enum FormatsType {
  TimeFormat = 'TimeFormat',
  DateFormat = 'DateFormat'
}

const styles = createStyles({
  content: {
    paddingTop: dimensions.screen.settings.container.paddingTop,
    paddingHorizontal: dimensions.screen.container.paddingHorizontal
  }
});

const SettingsTimeAndDateScreen: React.FC<{}> = () => {
  const {t} = useTranslation();
  const profile = useCurrentProfile();
  const {error, showError, onCloseErrorPopup} = useErrorPopup();
  const setProfileDateFormat = useDisposable(async (format: DateFormatDescription) => await profile?.setDateFormat(format));
  const setProfileTimeFormat = useDisposable(async (format: TimeFormatDescription) => await profile?.setTimeFormat(format));

  const getTimeData = () => [...mw.configuration.availableTimeFormats];
  const getDateData = () => [...mw.configuration.availableDateFormats];
  const getData = (type: FormatsType) => type === FormatsType.DateFormat ? getDateData() : getTimeData();

  const onDateChanged = useCallback(async (format: DateFormatDescription) => {
    try {
      await setProfileDateFormat(format);
    } catch (e) {
      showError(t('settings.errors.formatTimeChangeError'));
    }
  }, [setProfileDateFormat, showError, t]);

  const onTimeChanged = useCallback(async (format: TimeFormatDescription) => {
    try {
      await setProfileTimeFormat(format);
    } catch (e) {
      showError(t('settings.errors.formatTimeChangeError'));
    }
  }, [setProfileTimeFormat, showError, t]);

  const settingsChangeFormat = (type: FormatsType) => {
    let formats, initialValue, defaultFormat, formatHandler, title, getDefaultLabel;

    switch (type) {
      case FormatsType.DateFormat:
        if (mw.configuration.availableDateFormats.length <= 1) return;
        formats = getData(FormatsType.DateFormat);
        initialValue = formats.find(format => format.value === profile?.dateFormat);
        defaultFormat = formats.find(format => format.value === mw.configuration.getDefaultDateFormat()) || formats[0];
        mw.configuration.getDefaultDateFormat();
        formatHandler = onDateChanged;
        title = t('settings.dateFormat');
        getDefaultLabel = (format: string) => t('common.dateFormats.default', {format});
        break;
      case FormatsType.TimeFormat:
        if (mw.configuration.availableTimeFormats.length <= 1) return;
        formats = getData(FormatsType.TimeFormat);
        initialValue = formats.find(format => format.value === profile?.timeFormat);
        defaultFormat = formats.find(format => format.value === mw.configuration.getDefaultTimeFormat()) || formats[0];
        formatHandler = onTimeChanged;
        title = t('settings.timeFormat');
        getDefaultLabel = (format: string) => t('common.timeFormats.default', {format});
    }

    return (
      <SettingsChangeFormat
        formats={formats}
        onFormatChanged={formatHandler}
        initialValue={initialValue || defaultFormat}
        title={title}
        getDefaultLabel={getDefaultLabel}
      />
    );
  };

  return (
    <SettingsDetails
      title={t('settings.timeAndDate')}
      barText={t('settings.setTimeAndDate')}
      header={<SettingsDetailsHeader icon={IconType.TimeSettings} />}
      navigation={useNavigation()}
      testID='screen_settings_time_and_date'
    >
      <ScrollView contentContainerStyle={styles.content} alwaysBounceVertical={false}>
        {settingsChangeFormat(FormatsType.DateFormat)}
        {settingsChangeFormat(FormatsType.TimeFormat)}
      </ScrollView>
      <ErrorPopup
        error={error}
        onClose={onCloseErrorPopup}
      />
    </SettingsDetails>
  );
};

export default SettingsTimeAndDateScreen;
