import {isRemoteAsset} from 'common/HelperFunctions';
import {Log} from 'common/Log';

const TAG = 'Assets';

export function getAssetURL(url: string): string {
  if (!url) {
    return url;
  }
  if (isRemoteAsset(url)) {
    return url;
  }
  try {
    return require('../../../assets/' + url);
  } catch {
    Log.error(TAG, 'A required file was not found:', url);
    return url;
  }
}
