import {createStyles} from 'common-styles';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {ScrollView} from 'react-native';

import {dimensions, isBigScreen} from 'common/constants';

import {mw} from 'mw/MW';

import ErrorPopup, {useErrorPopup} from 'components/ErrorPopup';
import {IconType} from 'components/Icon';
import {useNavigation, useCurrentProfile} from 'hooks/Hooks';
import SettingsChangeLanguage from 'screens/settings/SettingsChangeLanguage';
import SettingsDetails from 'screens/settings/SettingsDetails';
import {SettingsDetailsHeader} from 'screens/settings/SettingsDetailsHeader';

const styles = createStyles({
  content: {
    paddingTop: isBigScreen ? dimensions.margins.xxxxLarge : dimensions.margins.xLarge,
    paddingHorizontal: dimensions.screen.container.paddingHorizontal
  }
});

const SettingsLanguageScreen: React.FC<{}> = () => {
  const {t} = useTranslation();
  const profile = useCurrentProfile();

  const {error, showError, onCloseErrorPopup} = useErrorPopup();

  const onUILanguageChanged = useCallback(async (uiLanguage: string) => {
    try {
      await (profile && profile.setUILanguage(uiLanguage));
    } catch (e) {
      showError(t('settings.errors.languageChangeError'));
    }
  }, [profile, showError, t]);
  const onAudioLanguageChanged = useCallback(async (audioLanguage: string) => {
    try {
      await (profile && profile.setAudioLanguage(audioLanguage));
    } catch (e) {
      showError(t('settings.errors.languageChangeError'));
    }
  }, [profile, showError, t]);
  const onSubtitlesLanguageChanged = useCallback(async (subtitleLanguage: string) => {
    try {
      await (profile && profile.setSubtitleLanguage(subtitleLanguage));
    } catch (e) {
      showError(t('settings.errors.languageChangeError'));
    }
  }, [profile, showError, t]);

  return (
    <SettingsDetails
      title={t('settings.language')}
      barText={t('settings.setDefaultLanguage')}
      header={<SettingsDetailsHeader icon={IconType.LanguageBig} />}
      navigation={useNavigation()}
      testID='screen_settings_language'
    >
      <ScrollView contentContainerStyle={styles.content} alwaysBounceVertical={false}>
        {mw.configuration.uiLanguages.length > 1
          && <SettingsChangeLanguage type='uiLanguages' onLanguageChanged={onUILanguageChanged} initialValue={profile && profile.uiLanguage || mw.configuration.defaultUILanguage} />}
        {mw.configuration.audioLanguages.length > 1
          && <SettingsChangeLanguage type='audioLanguages' onLanguageChanged={onAudioLanguageChanged} initialValue={profile && profile.audioLanguage || mw.configuration.defaultAudioLanguage} />}
        {mw.configuration.subtitlesLanguages.length > 1
          && <SettingsChangeLanguage type='subtitlesLanguages' onLanguageChanged={onSubtitlesLanguageChanged} initialValue={profile && profile.subtitleLanguage || mw.configuration.defaultSubtitlesLanguage} />}
      </ScrollView>
      <ErrorPopup
        error={error}
        onClose={onCloseErrorPopup}
      />
    </SettingsDetails>
  );
};

export default SettingsLanguageScreen;
