import {EventEmitter} from 'common/EventEmitter';
import {appendOptionsToUrl, compactMap, flatten, isTruthy} from 'common/HelperFunctions';
import {Hashmap} from 'common/HelperTypes';
import {Log} from 'common/Log';
import {Profiler} from 'common/Profiler';
import {makeArray} from 'common/utils';

import {EPGParams, EPGResponse, SearchParameters, SearchResult, SearchSource, PurchaseMethodParams, PurchaseResult, ContentQueryParameters, RecommendationsQueryParameters, SpecialFilter, StartPaymentParams} from 'mw/api/CatalogInterface';
import {ComponentType, ComponentDataSourceType} from 'mw/api/CMSInterface';
import {PurchaseAuthorizationMethod} from 'mw/api/Configuration';
import {Device} from 'mw/api/Device';
import {DeviceManager} from 'mw/api/DeviceManager';
import {CAPQueryParameters, CAPMessage} from 'mw/api/EASMetadata';
import {Error, Error as MWError, ErrorType} from 'mw/api/Error';
import {Filter} from 'mw/api/Filter';
import {
  Channel,
  ChannelList,
  Consent,
  Event,
  isEvent,
  Media,
  MediaType,
  Payment,
  PaymentMethod,
  Picture,
  PictureMode,
  PurchaseMethod,
  PVRQueryParameters,
  PVRQuota,
  PVRScheduleParameters,
  PVRUpdateParameters,
  Recording,
  Series,
  searchEpgFilterPast,
  searchEpgFilterNow,
  searchEpgFilterFuture,
  SortVodBy,
  Styling,
  Title,
  TitleType,
  UpdateMediaParams,
  Order,
  Product,
  PaymentMethodId,
  RecordingStatus,
  isTitle
} from 'mw/api/Metadata';
import {nxffConfig} from 'mw/api/NXFF';
import {PinProtectionLevel} from 'mw/api/PinProtection';
import {PinState, Profile, ProfileConfigurableProperties, ProfileStoredProperties, ProfileType} from 'mw/api/Profile';
import {ADR8Requester} from 'mw/bo-proxy/bo/adr8/ADR8Requester';
import {CMSMapper} from 'mw/bo-proxy/bo/cms/CMSMapper';
import {RecommendationsEngine} from 'mw/bo-proxy/bo/RecommendationsEngine';
import {createRecommendationsEngine} from 'mw/bo-proxy/bo/RecommendationsEngineFactory';
import {SearchEngine} from 'mw/bo-proxy/bo/SearchEngine';
import {Utils} from 'mw/bo-proxy/bo/Utils';
import {BOEvent, BOInterface, ChannelUrls, DeletePlaybackSessionParams, PlaybackSessionData, ProfileCreationResult, SortOrdersQueryParameters, OwnedQueryParameters} from 'mw/bo-proxy/BOInterface';
import {boProxy} from 'mw/bo-proxy/BOProxy';
import {NotificationsManager5q} from 'mw/bo-proxy/notifications/NotificationsManager5q';
import {Manager5j} from 'mw/bo-proxy/session-manager/Manager5j';
import {Utils as SSOUtils} from 'mw/bo-proxy/sso/Utils';
import {Customer} from 'mw/bo-proxy/types';
import {UXManager, Translations} from 'mw/bo-proxy/uxmanager/UXManager';
import {UXMInterface} from 'mw/bo-proxy/UXMInterface';
import {Component} from 'mw/cms/Component';
import {ComponentGroup} from 'mw/cms/ComponentGroup';
import {ConsentSlug} from 'mw/cms/ConsentInfo';
import {Menu, Link, LinkType} from 'mw/cms/Menu';
import {Page} from 'mw/cms/Page';
import {EntitledProduct} from 'mw/common/entitled-products/EntitledProductInterface';
import {shortId, httpRequestLogPrefix} from 'mw/common/utils';
import {mw} from 'mw/MW';
import {NotificationsManager} from 'mw/notifications/NotificationsManager';
import {nativeLocationModule} from 'mw/platform/native-location/NativeLocation';
import UserAgent from 'mw/platform/user-agent/UserAgent';
import {PlaybackSession, PlaybackSessionManager, SessionParams} from 'mw/playback/sessions/PlaybackSessionManager';
import {HttpMethods, httpFetch} from 'mw/utils/HttpUtils';
import {RetryManager} from 'mw/utils/RetryManager';

import i18n from 'locales/i18n';

import {
  validateAuthorizationError,
  validateCreateSession,
  validateCustomer,
  validateDeviceRegistration,
  validateDeviceUnregistration,
  validateHttpStatus,
  validateKeepAliveSession,
  validateNotSupportedPropertyError,
  validateProfilePin,
  validateResponse,
  validateQuotaExceededError
} from './errors/ErrorHandlers';
import {CAPMessageMapper} from './mappers/CAPMessageMapper';
import {CategoryMapper} from './mappers/CategoryMapper';
import {ChannelListMapper} from './mappers/ChannelListMapper';
import {ChannelMapper} from './mappers/ChannelMapper';
import {CpeMapper} from './mappers/CpeMapper';
import {CustomerMapper} from './mappers/CustomerMapper';
import {EntitledProductMapper} from './mappers/EntitledProductMapper';
import {EventMapper} from './mappers/EventMapper';
import {ProductMapper} from './mappers/ProductMapper';
import {ProfileMapper, ProfileNamedProperties} from './mappers/profileMapper';
import {RecordingMapper} from './mappers/RecordingMapper';
import {SearchMapper} from './mappers/SearchMapper';
import {SeasonMapper} from './mappers/SeasonMapper';
import {SeriesMapper} from './mappers/SeriesMapper';
import {SessionMapper} from './mappers/SessionMapper';
import {TitleMapper} from './mappers/TitleMapper';
import {WatchListMapper} from './mappers/WatchListMapper';
import {queryProps} from './queryProps';
import {queryUtils, RequestQuery, ResourcesParams} from './queryUtils';
import {resources} from './resources';
import {TraxisQueries} from './TraxisQueries';
import {LanguageType, Props, Relations, Resources, ResponseJson} from './TraxisTypes';

const TAG = 'TraxisAdapter';

const vodRootMenuId = 'vod-root';
const homePageId = 'nitrox-home-page';
const homePageSwimlanesSoftlinkId = 'SwimLanesForHome';
const recommendationsSoftlinkId = 'OperatorRecommendationForHome';
const maxRetries = 3;
const updateResourcesTimeOffset = 30000;
const minDurationMarkedAsTooLong = 500;
const continueWatchingLimits = {
  vods: 5,
  events: 5,
  series: 10,
  pvr: 5
};
const continueWatchingSeriesTitlesLimit = 50;
const numberOfGeneralRecommendations = 20;

interface RequestQueryProvider {
  (): RequestQuery;
}

export class TraxisAdapter extends EventEmitter<BOEvent> implements BOInterface {
  private url?: string;
  private epgRecommendationsEngine: RecommendationsEngine | null = null;
  private vodRecommendationsEngine: RecommendationsEngine | null = null;
  private searchEngine: SearchEngine | null = null;
  private static readonly availableVODSortOptions: SortVodBy[] = [];
  private uxManager?: UXMInterface;
  private loadResourcesLock: Promise<void> | null = null;

  public constructor() {
    super();
    const environment = nxffConfig.getConfig().Environment;
    this.epgRecommendationsEngine = createRecommendationsEngine('epg', this);
    this.vodRecommendationsEngine = createRecommendationsEngine('vod', this);
    const uxmUrl = environment.UXMURL;
    const uxmTenant = environment.UXMTenant;
    if (uxmUrl && uxmTenant) {
      this.uxManager = new UXManager(new ADR8Requester({
        url: uxmUrl,
        tenant: uxmTenant
      }));
    }
  }

  public async setParameters(): Promise<void> {
    Log.debug(TAG, 'setParameters');
    this.url = await boProxy.sso.getBoUrl();
    resources.initializeStorage();
    if (!await resources.loadFromCache()) {
      await this.loadResources();
      return;
    }

    Log.debug(TAG, 'resources loaded from cache');
    queryProps.initialize();

    setTimeout(() => {
      this.loadResources()
        .catch(error => {
          Log.error(TAG, 'failed to reload Resources', error);
        });
    }, updateResourcesTimeOffset);
  }

  private loadResources(): Promise<void> {
    if (this.loadResourcesLock) {
      return this.loadResourcesLock;
    }

    Log.debug(TAG, 'loadResources');
    this.loadResourcesLock = new Promise((resolve, reject) => {
      this.getResources()
        .then(resourceJSON => {
          resources.initialize(resourceJSON);
          queryProps.initialize();
          Log.debug(TAG, 'resources initialized');
          resolve();
        })
        .catch(error => {
          Log.error(TAG, 'failed to load resources', error);
          reject(error);
        })
        .finally(() => this.loadResourcesLock = null);
    });
    return this.loadResourcesLock;
  }

  private getProfilesImpl(mainProfileId?: string): Promise<Profile[]> {
    return this.sendRequest('', () => TraxisQueries.getProfiles())
      .then(response => {
        const masterProfileDefaultPinState = boProxy.sso.isMasterPinSupported() ? PinState.ProfilePinNotRequired : undefined;
        return ProfileMapper.toProfiles(response.Profiles.Profile, mainProfileId, masterProfileDefaultPinState);
      })
      .then(result => {
        if (result.ok) {
          const mainProfile = result.value.find((profile) => mainProfileId === profile.id);
          if (mainProfile) {
            mainProfile.setType(ProfileType.Main);
          }
          return result.value;
        }
        throw result.error;
      });
  }

  public getProfiles(): Promise<Profile[]> {
    return this.getProfilesImpl(mw.customer.defaultProfileId);
  }

  public async createProfile(config: Partial<ProfileConfigurableProperties>, masterPin: string): Promise<ProfileCreationResult> {
    if (!config.name) {
      Log.error(TAG, 'Cannot create profile without a name!');
      return {profileCreated: false, error: new MWError(ErrorType.ProfileCreationFailure)};
    }
    const configName = config.name;
    const response = await this.sendRequest('', () => TraxisQueries.createProfile(configName));
    const result = ProfileMapper.toProfile(response ? response.Profile : {});
    if (!result.ok) {
      return {profileCreated: false, error: new MWError(ErrorType.ProfileCreationFailure)};
    }
    const profile = result.value;
    try {
      await this.configureProfile(profile, config);
    } catch (error) {
      return {profileCreated: true, error};
    }
    return {profileCreated: true};
  }

  public deleteProfile(profile: Profile, pin: string): Promise<void> {
    return this.sendRequest('', () => TraxisQueries.deleteProfile(profile.id))
      .then(validateResponse)
      .catch((error: Error) => {
        Log.error(TAG, `Error deleting profile`, error);
        throw new MWError(ErrorType.ProfileDeletionFailure);
      });
  }

  public getPinProtectionLevel() {
    return PinProtectionLevel.Profile;
  }

  private setPropertiesForProfile(profile: Profile, props: {[key: string]: any}): Promise<void> {
    if (!Object.values(props).length) {
      return Promise.resolve();
    }
    return this.sendRequest('', () => TraxisQueries.updateProfile(profile.id, props))
      .then(validateResponse)
      .catch((error: Error) => {
        Log.error(TAG, 'Error setting properties', error, props, 'for profile', profile.id);
        throw new MWError(ErrorType.ProfileUpdateFailure);
      });
  }

  private setNamedPropertiesForProfile(profile: Profile, props: Partial<ProfileNamedProperties>): Promise<void> {
    if (!Object.values(props).length) {
      return Promise.resolve();
    }
    return this.sendRequest('', () => TraxisQueries.updateProfileNamedProperties(profile.id, ProfileMapper.mapProfilePropertiesToTraxisNamedProperties(props)))
      .then(validateResponse)
      .catch((error: Error) => {
        Log.error(TAG, 'Error setting named properties', error, props, 'for profile', profile.id);
        throw new MWError(ErrorType.ProfileUpdateFailure);
      });
  }

  private setLanguagePreferencesForProfile(profile: Profile, type: LanguageType, language: string): Promise<void> {
    return this.sendRequest('', () => TraxisQueries.updateProfileLanguagePreferences(profile.id, type, language))
      .then(validateResponse)
      .catch((error: Error) => {
        Log.error(TAG, 'Error setting language preferences', error, type, language, 'for profile', profile.id);
        throw new MWError(ErrorType.ProfileUpdateFailure);
      });
  }

  public setNameForProfile(profile: Profile, name: string, pin: string) {
    return this.configureProfile(profile, {name});
  }

  public setPinForProfile(profile: Profile, pin: string): Promise<void> {
    return (profile.isMain && boProxy.sso.isMasterPinSupported()) ? boProxy.sso.setMasterPin(pin) : this.configureProfile(profile, {pin});
  }

  private async configureProfile(profile: Profile, config: Partial<ProfileConfigurableProperties>) {
    const {namedProperties, profileProperties, languagePreferences} = ProfileMapper.mapProfileConfiguration(config);
    const languagePreferencesSetters = compactMap(Object.entries(languagePreferences), (([languageType, language]) => {
      return language ? this.setLanguagePreferencesForProfile(profile, languageType as LanguageType, language) : null;
    }));
    await Promise.all([
      this.setNamedPropertiesForProfile(profile, namedProperties),
      this.setPropertiesForProfile(profile, profileProperties),
      ...languagePreferencesSetters
    ]);
  }

  public setDataForProfile<Property extends keyof ProfileStoredProperties>(profile: Profile, key: Property, value: ProfileStoredProperties[Property]): Promise<void> {
    return this.configureProfile(profile, {[key]: value});
  }

  public addTitleToWatchList(profile: Profile, titleId: string): Promise<void> {
    return this.sendRequest('', () => TraxisQueries.addTitleToWatchList(profile.id, titleId))
      .then(validateResponse);
  }

  public addSeriesToWatchList(profile: Profile, seriesId: string): Promise<void> {
    return this.sendRequest('', () => TraxisQueries.addSeriesToWatchList(profile.id, seriesId))
      .then(validateResponse);
  }

  public removeTitlesFromWatchList(profile: Profile, titlesIds: string[]): Promise<void> {
    return this.sendRequest('', () => TraxisQueries.removeTitlesFromWatchList(profile.id, titlesIds))
      .then(validateResponse);
  }

  public removeSeriesFromWatchList(profile: Profile, seriesIds: string[]): Promise<void> {
    return this.sendRequest('', () => TraxisQueries.removeSeriesFromWatchList(profile.id, seriesIds))
      .then(validateResponse);
  }

  public getWatchList(profile: Profile): Promise<Media[]> {
    return Promise.all([
      this.sendRequest('', () => TraxisQueries.getWatchList(profile.id)).then(validateResponse),
      this.sendRequest('', () => TraxisQueries.getSeriesFromWatchList(profile.id)).then(validateResponse)
    ]).then((response) => {
      return WatchListMapper.fromJSONArray(response);
    });
  }

  public verifyProfilePin(profile: Profile, pin: string): Promise<void> {
    if (profile.isMain && boProxy.sso.isMasterPinSupported()) {
      if (boProxy.sso.getMasterPin() !== pin) {
        return Promise.reject(new MWError(ErrorType.IncorrectPin));
      }
    } else {
      return this.sendRequest('', () => TraxisQueries.getProfilePin(profile.id)).
        then((response) => validateProfilePin(response, pin));
    }

    return Promise.resolve();
  }

  public isPurchasePinSupported() {
    return boProxy.sso.isPurchasePinSupported();
  }

  public setPurchasePin(pin: string): Promise<void> {
    return boProxy.sso.setPurchasePin(pin);
  }

  public verifyPurchasePin(pin: string): Promise<void> {
    return boProxy.sso.verifyPurchasePin(pin);
  }

  public getPurchaseAuthorizationMethod(): PurchaseAuthorizationMethod {
    return this.isPurchasePinSupported() ? PurchaseAuthorizationMethod.PurchasePin : PurchaseAuthorizationMethod.MasterPin;
  }

  public getChannels(): Promise<Channel[]> {
    return this.sendRequest('', () => TraxisQueries.getChannels())
      .then(response => ChannelMapper.toChannels(response.Channels.Channel));
  }

  public getEPG(params: EPGParams): Promise<EPGResponse> {
    return this.sendRequest('', () => TraxisQueries.getEPG(params))
      .then(response => ChannelMapper.toEvents(response.Channels.Channel));
  }

  public getEventById(id: string): Promise<Event> {
    return this.sendRequest('', () => TraxisQueries.getEventById(id))
      .then(validateResponse)
      .then(response => EventMapper.fromJSON(response.Event));
  }

  public getPictureUrl(picture: Picture, width: number, height: number, mode: PictureMode): string {
    if (!picture.url) {
      return '';
    }
    return appendOptionsToUrl(picture.url, {
      mode,
      ...width > 0 && {w: width},
      ...height > 0 && {h: height}
    });
  }

  private getMoreLikeThis(media: Media): Promise<Media[]> {
    let id = '';
    switch (media.getType()) {
      case MediaType.Title:
        id = (media as Title).id;
        break;
      case MediaType.Event:
        id = (media as Event).title.id;
        break;
    }
    if (id) {
      return this.sendRequest('', () => TraxisQueries.getRecommendations(id, numberOfGeneralRecommendations))
        .then(response => TitleMapper.eventsOrTitlesFromJsonArray(response?.Results?.Result?.map((result: any) => result.Title)))
        .catch(error => {
          Log.error(TAG, 'Error fetching recommendations: ', error);
          return [];
        });
    }

    return Promise.resolve([]);
  }

  public getRecommendations(params: RecommendationsQueryParameters): Promise<Media[]> {
    const requests: Promise<Media[]>[] = [];
    if (nxffConfig.getConfig().Recommendations.EnableMoreLikeThisFromBO
      && resources.hasRelation(Resources.Title, Relations.Recommendations)
      && params.media
    ) {
      requests.push(this.getMoreLikeThis(params.media)
        .catch((error: Error) => {
          Log.error(TAG, 'getRecommendations():', error);
          return Promise.resolve([]);
        }));
    } else {
      if (params.sources.includes('epg')) {
        if (!this.epgRecommendationsEngine) {
          Log.error(TAG, 'EPG recommendations engine not created!');
        } else {
          requests.push(this.epgRecommendationsEngine.getRecommendations(params)
            .catch((error: Error) => {
              Log.error(TAG, 'epgRecommendationsEngine.getRecommendations', error);
              return Promise.resolve([]);
            }));
        }
      }
      if (params.sources.includes('vod')) {
        if (!this.vodRecommendationsEngine) {
          Log.error(TAG, 'VOD recommendations engine not created!');
        } else {
          requests.push(this.vodRecommendationsEngine.getRecommendations(params)
            .catch((error: Error) => {
              Log.error(TAG, 'vodRecommendationsEngine.getRecommendations', error);
              return Promise.resolve([]);
            }));
        }
      }
    }
    return Promise.all(requests)
      .then(flatten);
  }

  public getContinueWatching(): Promise<Media[]> {
    const promises: Promise<Media[]>[] = [
      this.getViewedVodTitles(continueWatchingLimits.vods),
      this.getViewedSeriesTitles(continueWatchingLimits.series),
      this.getViewedEvents(continueWatchingLimits.events)
    ];

    if (resources.hasProperties(Resources.Recording, Props.LastViewDate)) {
      promises.push(this.getViewedRecordings(continueWatchingLimits.pvr));
      promises.push(this.getViewedSeriesRecordings(continueWatchingLimits.pvr));
    }

    return Promise.all(promises)
      .then(flatten)
      .catch(error => {
        Log.error(TAG, 'Error when fetching continue watching swimlane:', error);
        return [];
      });
  }

  private getViewedVodTitles(limit: number): Promise<Title[]> {
    return this.sendRequest('', () => TraxisQueries.getLastViewedVodTitles(limit))
      .then(response => TitleMapper.toTitles(response.Titles.Title));
  }

  private async getViewedSeriesTitles(limit: number): Promise<Title[]> {
    const response = await this.sendRequest('', () => TraxisQueries.getLastViewedSeriesTitles(continueWatchingSeriesTitlesLimit));
    const seriesEpisodes: Hashmap<Title> = SeriesMapper.extractViewedSeriesTitlesMap(response, limit);
    if (!seriesEpisodes) {
      return [];
    }
    const episodesInProgress: Title[] = [];
    const nextEpisodesPromises: Promise<Title | null>[] = [];
    Object.entries(seriesEpisodes).forEach(([seriesId, title]) => {
      if (!title.isViewedCompletely) {
        episodesInProgress.push(title);
        return;
      }
      nextEpisodesPromises.push(this.getSeriesEpisodesById(seriesId).then(SeriesMapper.findLastNotViewedCompletelyEpisode));
    });
    const nextEpisodes = (await Promise.all(nextEpisodesPromises)).filter(isTruthy) as Title[];
    return [...episodesInProgress, ...nextEpisodes];
  }

  private getViewedRecordings(limit: number): Promise<Recording[]> {
    return this.sendRequest('', () => TraxisQueries.getLastViewedRecordings(limit))
      .then(TraxisQueries.mapRecordings);
  }

  private async getViewedSeriesRecordings(limit: number): Promise<Recording[]> {
    const viewedRecordings = await this.sendRequest('', () => TraxisQueries.getLastViewedSeriesRecordings(continueWatchingSeriesTitlesLimit))
      .then(TraxisQueries.mapRecordings);

    const recordingSeriesMap = RecordingMapper.extractViewedSeriesRecordingsMap(viewedRecordings, limit);
    if (!recordingSeriesMap.size) {
      return [];
    }

    const recordingsInProgress: Recording[] = [];
    const nextRecordingsPromises: Promise<Recording | null>[] = [];
    recordingSeriesMap.forEach(recording => {
      if (!recording.isViewedCompletely) {
        recordingsInProgress.push(recording);
        return;
      }

      const seriesId = recording.event?.title.episode?.seriesId || recording.event?.title.episode?.seasonId;
      if (!seriesId) {
        return;
      }
      nextRecordingsPromises.push(this.getRecordingSeries(seriesId)
        .then(recordings => RecordingMapper.findLastNotViewedCompletelyEpisode(recordings, recording)));
    });

    const nextRecordings = (await Promise.all(nextRecordingsPromises)).filter(isTruthy) as Recording[];

    return [...recordingsInProgress, ...nextRecordings];
  }

  private getRecordingSeries(seriesId: string): Promise<Recording[]> {
    return this.sendRequest('', () => TraxisQueries.getRecordingSeries(seriesId))
      .then(TraxisQueries.mapRecordings);
  }

  private getViewedEvents(limit: number): Promise<Event[]> {
    if (!resources.hasRelation(Resources.Root, Relations.ViewedEvents)) {
      return Promise.resolve([]);
    }
    return this.sendRequest('', () => TraxisQueries.getLastViewedEvents(limit))
      .then(validateResponse)
      .then(response => EventMapper.fromJSONArray(response.Events.Event));
  }

  private async sendRequest(requestUrl: string, requestQueryProvider: RequestQueryProvider): Promise<any> {
    let authorizationToken: string | null = null;
    const retryManager = new RetryManager({
      maxRetries,
      try: () => {
        return Utils.getToken(() => this.notify(BOEvent.BOUnauthorized))
          .then(authToken => {
            authorizationToken = authToken;
            return this.sendRequestImpl(requestUrl, requestQueryProvider, authorizationToken);
          })
          .catch((error: Error) => {
            Log.error(TAG, 'get authorizationToken fails', error);
            return Promise.reject(error);
          });
      },
      beforeRetry: (error: Error) => {
        Log.error(TAG, 'retry sendRequest failed');
        switch (error?.type) {
          case ErrorType.BOInvalidAuthentication:
            boProxy.sso.invalidateToken(authorizationToken);
            break;

          case ErrorType.BONotSupportedProperty:
            return this.loadResources();
        }

        return Promise.resolve();
      },
      onMaxRetryExceeded: (error: Error) => {
        Log.error(TAG, 'retry sendRequest undone: max retries exceeded');
        if (error?.type === ErrorType.BOInvalidAuthentication) {
          this.notify(BOEvent.BOUnauthorized);
        }
      },
      isRetryNeeded: (error: Error) => {
        switch (error?.type) {
          case ErrorType.BOInvalidAuthentication:
            return true;

          case ErrorType.BONotSupportedProperty:
            return resources.shouldUpdate();

          default:
            return false;
        }
      }
    });

    return retryManager.try()
      .catch((error: Error) => {
        Log.error(TAG, 'sendRequest: httpFetch failed', error);
        switch (error?.type) {
          case ErrorType.HttpTimeout:
          case ErrorType.NetworkNoConnection:
          case ErrorType.NetworkRequestFailed:
            this.notify(BOEvent.BOUnavailable, error);
            break;
        }
        throw error;
      });
  }

  private async sendRequestImpl(requestUrl: string, requestQueryProvider: RequestQueryProvider, authorizationToken: string | null): Promise<any> {
    const url = `${this.url}/${requestUrl}`;
    const headers: {[key: string]: string} = {
      'content-type': 'text/xml; charset=utf-8'
    };

    if (UserAgent.value) {
      headers['user-agent'] = UserAgent.value;
    }

    if (authorizationToken) {
      headers.Authorization = SSOUtils.getSeaChangeAuthorizationHeader(authorizationToken, 'personal');
    }

    if (nativeLocationModule.isReportingAllowed()) {
      const gpsInfo = nativeLocationModule.getLastLocation();
      if (gpsInfo) {
        headers['Adr-Gps-Info'] = `lat_${gpsInfo.latitude}#lon_${gpsInfo.longitude}`;
      }
    }

    const requestQuery: RequestQuery = requestQueryProvider();
    const id = shortId();
    let networkTime = Date.now();
    Log.info(TAG, `${httpRequestLogPrefix(id)} Send request url: ${url}, method: ${requestQuery.method}, headers: ${JSON.stringify(headers)}, body: ${requestQuery.body}`);
    const response = await httpFetch(url, {
      method: requestQuery.method,
      body: requestQuery.body,
      headers: headers
    });
    networkTime = Date.now() - networkTime;

    const duration = response.headers.get('duration');
    if (duration && parseInt(duration) > minDurationMarkedAsTooLong) {
      Log.warn(TAG, `${httpRequestLogPrefix(id)} sendRequest: duration:`, duration);
      Profiler.entry(TAG, `${httpRequestLogPrefix(id)} long request duration`, duration);
    }

    let responseJson;
    let parsingTime = Date.now();
    if (response.headers && 'application/json' === response.headers.get('content-type')) {
      responseJson = await response.json();
    } else {
      const responseTxt = await response.text();
      responseJson = await queryUtils.parseXMLOrJSON(responseTxt);
    }
    parsingTime = Date.now() - parsingTime;

    if (!response.ok) {
      Log.error(TAG, `${httpRequestLogPrefix(id, {networkTime, parsingTime})} Error sending query: ${JSON.stringify(responseJson)}`);
    } else {
      Log.info(TAG, `${httpRequestLogPrefix(id, {networkTime, parsingTime})} Got response: ${JSON.stringify(responseJson)}`);
    }

    return await validateAuthorizationError(response, responseJson)
      .then(responseJson => validateHttpStatus(response, responseJson))
      .then(validateNotSupportedPropertyError)
      .catch((error: Error) => {
        switch (error?.type) {
          case ErrorType.BOInvalidCustomerID:
            this.notify(BOEvent.BOUnauthorized);
            return Promise.reject(error);
          case ErrorType.DeviceUnregistered:
            this.notify(BOEvent.DeviceUnregistered);
            return Promise.reject(error);
          default:
            return Promise.reject(error);
        }
      });
  }

  public async getMenu(id: string, depth: number): Promise<Menu> {
    if (this.uxManager && nxffConfig.getConfig().UI.MainMenuSlug) {
      const menu = await this.uxManager.getMenu(id, depth);
      if (nxffConfig.getConfig().UI.MainMenuSlug && menu.filter) {
        menu.filter.aliasType = UXManager.defaultAlias;
      }
      if (!mw.customer.getProfile().isPCEnabled || mw.customer.getProfile().showAdultContent) {
        return menu;
      }
      const menuItemsParams: ResourcesParams[] = [];
      menu.items.forEach(item => item.filter && menuItemsParams.push({id: item.filter.value, attributes: {aliasType: item.filter?.aliasType}}));
      if (!menuItemsParams.length) {
        return menu;
      }
      const response = await this.sendRequest('', () => TraxisQueries.getCategoriesWithIsAdult(menuItemsParams));
      if (response.Categories) {
        return CategoryMapper.filterAdultCategories(response.Categories, menu);
      }
    }

    if (id === vodRootMenuId) {
      const response = await this.sendRequest('', () => TraxisQueries.getRootCategories());
      if (!response.Categories) {
        return Menu.empty;
      }
      const rootCategories = makeArray(response.Categories.Category);
      return CategoryMapper.menuFromJson(rootCategories[0]);
    }

    const response = await this.sendRequest('', () => TraxisQueries.getCategory(id));
    return response ? CategoryMapper.menuFromJson(response.Category) : Menu.empty;
  }

  /**
   * Functions removes vod screen using Traxis vod-root hack, based on a condition.
   * Condition is if MainMenuSlug is configured that means that MainMenu is expected
   * to come from UXM so do not use vod-root hack.
   * If MainMenuSlug is not configured, menu is expected to come from Traxis,
   * in that case do not filter out.
   * The function does not check if UXM is configured. It is not checking
   * sanity of whole nxfd backoffice configuration.
   * @param menu - menu to filter
   */
  private static filterMainMenuBasedOnNXFDConfiguration(menu: Menu): Menu {
    if (!nxffConfig.getConfig().UI.MainMenuSlug) {
      return menu;
    }
    const menuItems = menu.items.filter(item => item.link?.slug !== 'vod-root');
    return new Menu({...menu, items: menuItems});
  }

  private shouldFilterMainMenuBasedOnNXFDConfiguration(uxmanager: UXMInterface | undefined): uxmanager is UXMInterface {
    return !!uxmanager;
  }

  public getMainMenu(): Promise<Menu> {
    return this.shouldFilterMainMenuBasedOnNXFDConfiguration(this.uxManager)
      ? this.uxManager.getMainMenu().then(TraxisAdapter.filterMainMenuBasedOnNXFDConfiguration)
      : Promise.resolve(CMSMapper.getMainMenu());
  }

  public getUnauthenticatedMainMenu(): Promise<Menu> {
    return this.shouldFilterMainMenuBasedOnNXFDConfiguration(this.uxManager)
      ? this.uxManager.getDefaultMainMenu().then(TraxisAdapter.filterMainMenuBasedOnNXFDConfiguration)
      : Promise.resolve(CMSMapper.getMainMenu());
  }

  private getHomePageFallback(): Promise<Page> {
    const swimlaneProps = {isPersonal: false, isSpecial: true, cacheTimeInMinutes: 0};
    const createSwimlane = (title: string, filter: SpecialFilter): Component => {
      return new Component({
        title,
        type: ComponentType.Swimlane,
        dataSource: {
          type: ComponentDataSourceType.SpecialFilter,
          filters: [{...swimlaneProps, value: filter}]
        }
      });
    };
    return Promise.resolve(new Page('', '', '', '', [new ComponentGroup('Swim-lanes', [
      createSwimlane(i18n.t('recommendations.vod'), SpecialFilter.VODRecommendations),
      createSwimlane(i18n.t('recommendations.epg'), SpecialFilter.EPGRecommendations),
      createSwimlane(i18n.t('recommendations.watchList'), SpecialFilter.WatchList),
      createSwimlane(i18n.t('recommendations.continueWatching'), SpecialFilter.ContinueWatching)
    ])]));
  }

  private async getHomePage(): Promise<Page> {
    const response = await this.sendRequest('', () => TraxisQueries.getRootCategories());
    if (!response.Categories) {
      Log.debug(TAG, 'getHomePage(): Failed to parse root categories. Using fallback...');
      return await this.getHomePageFallback();
    }
    const rootCategory = makeArray(response.Categories.Category)[0];
    if (!rootCategory) {
      Log.debug(TAG, 'getHomePage(): Failed to find first root category. Using fallback...');
      return await this.getHomePageFallback();
    }
    const swimlanesSoftlink = makeArray(rootCategory.SoftLinks && rootCategory.SoftLinks.SoftLink).find(s => s.qualifier === homePageSwimlanesSoftlinkId);
    if (!swimlanesSoftlink) {
      Log.debug(TAG, `getHomePage(): Failed to find ${homePageSwimlanesSoftlinkId} softlink. Using fallback...`);
      return await this.getHomePageFallback();
    }
    return this.getPage({type: LinkType.PAGE, slug: swimlanesSoftlink.id, idType: swimlanesSoftlink.idType});
  }

  public async getPage(link: Link): Promise<Page> {
    if (nxffConfig.getConfig().UI.MainMenuSlug && this.uxManager) {
      return this.uxManager.getPage(link);
    }

    if (link.slug === homePageId) {
      return this.getHomePage();
    }

    const {slug: softlinkId, idType} = link;
    if (!softlinkId || !idType) {
      return Promise.reject('Softlink id or idType not specified!');
    }
    const json = await this.sendRequest('', () => TraxisQueries.getSoftlinkContent(softlinkId, idType));
    const linkedCategory = CategoryMapper.menuFromJson(json.Category, true);
    const swimLanes = compactMap(linkedCategory.items, category => {
      return category.filter
        ? new Component({
          title: category.title,
          type: ComponentType.Swimlane,
          dataSource: {
            type: ComponentDataSourceType.Node,
            filters: [category.filter]
          }
        })
        : null;
    });
    return new Page('', '', '', '', [new ComponentGroup('Swim-lanes', swimLanes)]);
  }

  public createPlaybackSession(params: SessionParams): Promise<PlaybackSessionData> {
    const deviceId = DeviceManager.getInstance().getId();
    const profileId = encodeURIComponent(mw.customer.getProfile().id);
    // TODO: CL-5385 Remove workaround
    return this.sendRequest(`Session/props/Playlist,State?CpeId=${deviceId}&ProfileId=${profileId}`, () => queryUtils.create5jSession(params))
      .then(validateCreateSession)
      .then(response => SessionMapper.toPlaybackSessionData(response.Session))
      .catch(error => {
        if (error.type === ErrorType.PlaybackLocationForbidden) {
          this.notify(BOEvent.PlaybackLocationForbidden);
        }
        return Promise.reject(error);
      });
  }

  public keepAlivePlaybackSession(session: PlaybackSession, params: any): Promise<void> {
    return this.sendRequest(`Session/${session.id}/KeepAlive/props/State`, () => TraxisQueries.keepAlivePlaybackSession(session, params))
      .then(validateKeepAliveSession)
      .then(response => SessionMapper.validateSession(response.Session));
  }

  public deletePlaybackSession(session: PlaybackSession, params: DeletePlaybackSessionParams): Promise<void> {
    return this.sendRequest(`Session/${session.id}`, () => TraxisQueries.deletePlaybackSession(session, {
      position: params.position,
      sessionEndCode: TraxisQueries.getEndCodeForSessionTerminationReason(params.reason)
    })).then(response => Log.debug(TAG, 'deletePlaybackSession', response));
  }

  public geoLocationPlaybackCheck(): Promise<void> {
    //  NOTE: ADRs < 8 do not support GeoLocation
    return Promise.resolve();
  }

  public getContent(filters: Filter[], parameters: ContentQueryParameters): AsyncIterableIterator<Media[]> {
    return filters.length > 1
      ? this.getMultipleCategoriesContent(filters, parameters)
      : this.getSingleCategoryContent(filters[0], parameters);
  }

  public async *getMultipleCategoriesContent(filters: Filter[], parameters: ContentQueryParameters): AsyncIterableIterator<Media[]> {
    const {pageNumber, pageSize, ...options} = parameters;
    let offset = Math.max(pageNumber || 0, 0) * pageSize;
    while (true) {
      const oldOffset = offset;
      offset += pageSize;
      Log.debug(TAG, `Fetching page of size ${pageSize} at offset ${oldOffset} for categories`, filters);
      let response: ResponseJson;
      try {
        response = await this.sendRequest('', () => TraxisQueries.getMultipleCategoriesQuery(filters, {offset: oldOffset, pageSize}, options));
      } catch (error) {
        Log.error(TAG, `Error sending request for page of size ${pageSize} at offset ${oldOffset} for categories:`, filters, error);
        return [];
      }
      const results = TitleMapper.eventsOrTitlesFromJsonArray(makeArray(response?.Titles?.Title));
      if (!results.length) {
        Log.info(TAG, `Response empty for page of size ${pageSize} at offset ${oldOffset} for categories:`, filters);
        return [];
      }
      Log.info(TAG, `Downloaded ${results.length} results for page of size ${pageSize} at offset ${oldOffset} for categories:`, filters);
      if (results.length < pageSize) {
        return results;
      }
      yield results;
    }
  }

  public async *getSingleCategoryContent(filter: Filter, parameters: ContentQueryParameters): AsyncIterableIterator<Media[]> {
    const {pageNumber, pageSize, ...options} = parameters;
    let offset = Math.max(pageNumber || 0, 0) * pageSize;
    while (true) {
      const oldOffset = offset;
      offset += pageSize;
      Log.debug(TAG, `Fetching page of size ${pageSize} at offset ${oldOffset} for category "${filter.value}."`);
      // use bind to function because error with async generator when try to use () =>
      let response: ResponseJson;
      try {
        response = await this.sendRequest('', TraxisQueries.getCategoryRelationsQuery.bind(null, filter, {offset: oldOffset, pageSize}, options));
      } catch (error) {
        Log.error(TAG, `Error sending request for page of size ${pageSize} at offset ${oldOffset} for category "${filter.value}":`, error);
        return [];
      }

      const results = response?.Results?.Result;
      if (!results || !results.length) {
        Log.info(TAG, `Response empty for page of size ${pageSize} at offset ${oldOffset} for category "${filter.value}". Finished fetching.`);
        return [];
      }
      Log.info(TAG, `Downloaded ${results.length} results for page of size ${pageSize} at offset ${oldOffset} for category "${filter.value}". ${results.length < pageSize ? 'Finished fetching.' : ''}`);
      const medias: Media[] = [];
      for (const prop in results) {
        const result = results[prop];
        if (result.Title) {
          const title = TitleMapper.fromJSON(result.Title, result.Title?.Events?.Event ? TitleType.EPG : TitleType.VOD);
          // Backward compatibility for ADR5
          medias.push(title?.events?.length ? EventMapper.fromTitle(title) : title);
        } else if (result.Series) {
          medias.push(SeriesMapper.fromJSON(result.Series));
        } else if (result.Event) {
          medias.push(EventMapper.fromJSON(result.Event));
        } else if (result.Channel) {
          medias.push(ChannelMapper.toChannel(result.Channel));
        } else if (result.Recording) {
          medias.push(RecordingMapper.fromJSON(result.Recording));
        }
      }
      if (results.length < pageSize) {
        return medias;
      }
      yield medias;
    }
  }

  public async getFakeVodRecommendationsSource(): Promise<Filter | null> {
    const response = await this.sendRequest('', () => TraxisQueries.getRootCategorySoftLinks());
    if (!response.Categories) {
      Log.debug(TAG, 'getFakeVodRecommendationsSource(): Failed to parse root categories.');
      return null;
    }
    const rootCategory = makeArray(response.Categories.Category)[0];
    if (!rootCategory) {
      Log.debug(TAG, 'getFakeVodRecommendationsSource(): Failed to find first root category.');
      return null;
    }
    const recommendationsSoftlink = rootCategory.SoftLinks ? makeArray(rootCategory.SoftLinks.SoftLink).find(s => s.qualifier === recommendationsSoftlinkId) : (undefined);
    if (!recommendationsSoftlink) {
      Log.debug(TAG, `getFakeVodRecommendationsSource(): Failed to find ${recommendationsSoftlinkId} softlink.`);
      return null;
    }
    if (typeof recommendationsSoftlink.id !== 'string' || typeof recommendationsSoftlink.idType !== 'string') {
      Log.debug(TAG, `getFakeVodRecommendationsSource(): Failed to parse ${recommendationsSoftlinkId} softlink id/idType.`);
      return null;
    }
    const json = await this.sendRequest('', () => TraxisQueries.getSoftlinkContent(recommendationsSoftlink.id, recommendationsSoftlink.idType));
    if (!json) {
      Log.debug(TAG, `getFakeVodRecommendationsSource(): ${recommendationsSoftlinkId} softlink empty.`);
      return null;
    }
    const linkedCategory = CategoryMapper.menuFromJson(json.Category);
    if (!linkedCategory.slug) {
      Log.debug(TAG, `getFakeVodRecommendationsSource(): ${recommendationsSoftlinkId} softlink empty.`);
      return null;
    }
    return {isPersonal: false, isSpecial: false, value: linkedCategory.slug, cacheTimeInMinutes: 0};
  }

  public getPlaybackSessionManager(): PlaybackSessionManager {
    return new Manager5j();
  }

  public getNotificationsManager(): NotificationsManager | null {
    return new NotificationsManager5q();
  }

  public getTitleById(id: string): Promise<Title> {
    return this.sendRequest('', () => TraxisQueries.getTitleById(id))
      .then(response => TitleMapper.fromJSON(response.Title, TitleType.VOD));
  }

  public getSeriesById(seriesId: string): Promise<Series> {
    return this.sendRequest('', () => TraxisQueries.getSeriesById(seriesId))
      .then(response => SeriesMapper.fromJSON(response.Series));
  }

  public getSeriesSeasonsById(seriesId: string): Promise<Series[]> {
    return this.sendRequest('', () => TraxisQueries.getSeriesSeasonsById(seriesId))
      .then(response => SeasonMapper.fromSeries(response.Series));
  }

  public getSeriesEpisodesById(seriesId: string): Promise<Title[]> {
    return this.sendRequest('', () => TraxisQueries.getSeriesEpisodesById(seriesId))
      .then(response => SeriesMapper.getTitlesFromJSON(response.Series));
  }

  public getSeasonEpisodesById(seasonId: string, includeTvEvents: boolean): Promise<Media[]> {
    return this.sendRequest('', () => TraxisQueries.getSeriesEpisodesById(seasonId, includeTvEvents))
      .then(response => SeasonMapper.getEpisodesFromJson(response.Series));
  }

  public getRecommendedEpisode(series: Series): Promise<Title | null> {
    return this.getSeriesEpisodesById(series.id)
      .then(episodes => (!!episodes.length) ? (SeriesMapper.findLastNotViewedCompletelyEpisode(episodes) || episodes[0]) : null);
  }

  private getResources(): Promise<any> {
    const query = {
      method: HttpMethods.GET
    };
    return this.sendRequest('Resources', () => query);
  }

  public getChannelUrls(): Promise<ChannelUrls> {
    return this.sendRequest('', () => TraxisQueries.getChannelLocations())
      .then(response => ChannelMapper.toChannelUrls(response.ChannelLocations.ChannelLocation));
  }

  public updateMedia(media: Media, params: UpdateMediaParams): Promise<void> {
    switch (params) {
      case UpdateMediaParams.Bookmarks:
        if (!isEvent(media)) {
          return Promise.reject(new Error(ErrorType.NotImplemented));
        }
        return this.updateEventBookmark(media);
      case UpdateMediaParams.EntitlementState:
        return this.updateMediaEntitlementState(media);
      default:
        return Promise.reject(new Error(ErrorType.NotImplemented));
    }
  }

  private updateEventBookmark(event: Event): Promise<void> {
    return this.sendRequest('', () => TraxisQueries.getBookmarkForEvent(event.id))
      .then(validateResponse)
      .then(response => EventMapper.applyBookmarkData(event, response.Event));
  }

  private updateMediaEntitlementState(media: Media): Promise<void> {
    if (!isTitle(media)) {
      return Promise.reject(new Error(ErrorType.NotSupported, `Updating entitlement state is not supported for ${media.getType()} media type`));
    }
    // Since ADR 5/7 currently doesn't support any third party payment providers, this method probably will not be
    // called too often. If this will change in the future, it should be replaced with more optimal query.
    return this.getTitleById(media.id)
      .then(updatedTitle => {
        media.entitlementState = updatedTitle.entitlementState;
      });
  }

  public deleteBookmark(media: Media): Promise<void> {
    return this.setBookmark(media, 0);
  }

  public setBookmark(media: Media, position: number): Promise<void> {
    const bookmark = Math.floor(position);
    return this.sendRequest('', () => TraxisQueries.setBookmark(media, bookmark));
  }

  public search(params: SearchParameters): AsyncIterableIterator<SearchResult> {
    if (this.searchEngine) {
      return this.searchEngine.search(params);
    }
    return this.publisherSearch(params);
  }

  public getSearchMinimumLength(): number {
    return nxffConfig.getConfig().Search.SearchMinimumLength;
  }

  private searchRecordings(params: SearchParameters): Promise<Media[]> {
    if (resources.hasRelation(Resources.Root, Relations.RecordedTitles)) {
      return this.sendRequest('', () => TraxisQueries.searchPvrQuery(params))
        .then(json => SearchMapper.eventsFromRecordedTitlesJSON(json));
    }
    return this.sendRequest('', () => TraxisQueries.searchPvrAlternativeQuery(params))
      .then(json => SearchMapper.eventsFromRecordingsJSON(json));
  }

  private async *publisherSearch(params: SearchParameters): AsyncIterableIterator<SearchResult> {
    const query = encodeURI(params.term);
    const queries: Promise<void>[] = [];
    const result: SearchResult = {};

    const onError = (error: any, type: string) => Log.error(TAG, `Error while searching for ${query} in ${type}. Error: ${error}`);
    if (params.sources.includes(SearchSource.Channel)) {
      queries.push(this.sendRequest('', () => TraxisQueries.searchChannelsQuery(params))
        .then(response => {
          result.channel = ChannelMapper.toChannels(response.Channels.Channel);
          Log.debug(TAG, `Channel search query for phrase: ${query} returned ${result.channel.length} results.`);
        })
        .catch(error => onError(error, 'Channel')));
    }

    if (params.sources.includes(SearchSource.Epg)) {
      queries.push(this.sendRequest('', () => TraxisQueries.searchEpgQuery(params, searchEpgFilterPast))
        .then(json => {
          result.epgPast = SearchMapper.eventsFromJSON(json);
          Log.debug(TAG, `Epg past search query for phrase: ${query} returned ${result.epgPast.length} results.`);
        })
        .catch(error => onError(error, 'Epg past')));

      queries.push(this.sendRequest('', () => TraxisQueries.searchEpgQuery(params, searchEpgFilterNow))
        .then(json => {
          result.epgNow = SearchMapper.eventsFromJSON(json);
          Log.debug(TAG, `Epg now search query for phrase: ${query} returned ${result.epgNow.length} results.`);
        })
        .catch(error => onError(error, 'Epg now')));

      queries.push(this.sendRequest('', () => TraxisQueries.searchEpgQuery(params, searchEpgFilterFuture))
        .then(json => {
          result.epgFuture = SearchMapper.eventsFromJSON(json);
          Log.debug(TAG, `Epg future search query for phrase: ${query} returned ${result.epgFuture.length} results.`);
        })
        .catch(error => onError(error, 'Epg future')));

      queries.push(this.searchRecordings(params)
        .then(events => {
          result.epgPvr = events;
          Log.debug(TAG, `Epg recorded search query for phrase: ${query} returned ${result.epgPvr.length} results.`);
        })
        .catch(error => onError(error, 'Epg recorded')));
    }

    if (params.sources.includes(SearchSource.Vod)) {
      queries.push(this.sendRequest('', () => TraxisQueries.searchVodQuery(params))
        .then(json => {
          result.vod = SearchMapper.titlesFromJSON(json);
          Log.debug(TAG, `Vod search query for phrase: ${query} returned ${result.vod.length} results.`);
        })
        .catch(error => onError(error, 'Vod')));
    }

    return Promise.all(queries).then(() => result);
  }

  public getNPVRQuota(): Promise<PVRQuota> {
    return this.sendRequest('', () => TraxisQueries.getNPVRQuota())
      .then(response => CustomerMapper.pvrQuotaFromJSON(response.Customers.Customer[0]));
  }

  public scheduleRecording(params: PVRScheduleParameters): Promise<Recording> {
    Log.debug(TAG, `Scheduling new recording for event ${params.event}`);
    return this.sendRequest('', () => TraxisQueries.scheduleRecording(params))
      .then(validateQuotaExceededError)
      .then(validateResponse)
      .then((response) => {
        Log.debug(TAG, `New recording for event ${params.event} scheduled successfully`);
        return RecordingMapper.fromJSON(response.Recording);
      })
      .catch((error: Error) => {
        if (error.type === ErrorType.BOPvrQuotaExceeded) {
          throw error;
        }
        throw new Error(ErrorType.UnknownError, `Failed to schedule new recording for event with id ${params.event.id}. ${error.message || ''}`);
      });
  }

  public deleteRecordings(recordings: Recording[]): Promise<void> {
    Log.debug(TAG, `Deleting ${recordings.length} recordings`);
    return this.sendRequest('', () => TraxisQueries.deleteRecordings(recordings))
      .then(validateResponse)
      .then(() => Log.debug(TAG, `Successfully deleted ${recordings.length} recordings`))
      .catch((error: Error) => {
        throw new Error(ErrorType.UnknownError, `Failed to delete ${recordings.length} recordings. ${error.message || ''}`);
      });
  }

  public cancelRecordings(recordings: Recording[]): Promise<void> {
    Log.debug(TAG, `Canceling ${recordings.length} recordings`);
    return this.sendRequest('', () => TraxisQueries.cancelRecordings(recordings))
      .then(validateResponse)
      .then(() => Log.debug(TAG, `Successfully canceled ${recordings.length} recordings`))
      .catch((error: Error) => {
        throw new Error(ErrorType.UnknownError, `Failed to cancel ${recordings.length} recordings. ${error.message || ''}`);
      });
  }

  public resumeRecordings(recordings: Recording[], params?: PVRUpdateParameters): Promise<void> {
    Log.debug(TAG, `Resuming ${recordings.length} recordings`);

    return (params ? this.updateRecordings(recordings, params) : Promise.resolve())
      .then(() => this.sendRequest('', () => TraxisQueries.resumeRecordings(recordings)))
      .then(validateResponse)
      .then(() => Log.debug(TAG, `Successfully resumed ${recordings.length} recordings`))
      .catch((error: Error) => {
        throw new Error(ErrorType.UnknownError, `Failed to resume ${recordings.length} recordings. ${error.message || ''}`);
      });
  }

  public updateRecordings(recordings: Recording[], params: PVRUpdateParameters): Promise<void> {
    Log.debug(TAG, `Update ${recordings.length} recordings`);
    return this.sendRequest('', () => TraxisQueries.updateRecordings(recordings, params))
      .then(validateResponse)
      .then(() => Log.debug(TAG, `Successfully updated ${recordings.length} recordings`, params))
      .catch((error: Error) => {
        throw new Error(ErrorType.UnknownError, `Failed to update ${recordings.length} recordings. ${error.message || ''}`);
      });
  }

  public getAllRecordingsStatuses(): Promise<Map<Event['id'], RecordingStatus>> {
    const recordingsRequest = TraxisQueries.getAllRecordingsStatuses();
    return this.sendRequest('', () => recordingsRequest.requestFunction({}))
      .then(response => RecordingMapper.getRecordingsStatus(response));
  }

  public getRecordings(params: PVRQueryParameters): Promise<Recording[]> {
    const recordingsRequest = TraxisQueries.getRecordings(params);
    return this.sendRequest('', () => recordingsRequest.requestFunction(params))
      .then(recordingsRequest.responseMapper);
  }

  public getRecordingsStatus(events: Event[]): Promise<Map<Event['id'], RecordingStatus>> {
    return this.sendRequest('', () => TraxisQueries.getRecordingStatus(events))
      .then(validateResponse)
      .then(response => RecordingMapper.getRecordingsStatus(response));
  }

  public getIsRecorded(events: Event[]): Promise<Map<Event['id'], boolean>> {
    return this.sendRequest('', () => TraxisQueries.getIsRecorded(events))
      .then(validateResponse)
      .then(response => EventMapper.getIsRecorded(response.Events));
  }

  public isSeenFilterAvailable(): boolean {
    return true;
  }

  public getAvailableVODSortOptions(): SortVodBy[] {
    return TraxisAdapter.availableVODSortOptions;
  }

  public async getCustomer(): Promise<Customer> {
    const customer = await this.sendRequest('', () => TraxisQueries.getCustomer())
      .then(validateCustomer)
      .then(response => CustomerMapper.fromJSON(response.Customers.Customer[0]));
    const profiles = await this.getProfilesImpl(customer.defaultProfileId);
    const mainProfile = profiles.find(p => p.isMain);
    if (mainProfile) {
      customer.mainProfile = mainProfile;
    }
    customer.profiles = profiles;
    return customer;
  }

  public getDevices(): Promise<Device[]> {
    return this.sendRequest('', () => TraxisQueries.getDevices())
      .then(response => CpeMapper.toDevices(response.Cpes.Cpe));
  }

  public registerDevice(device: Device): Promise<void> {
    return this.sendRequest('', () => TraxisQueries.registerDevice(device))
      .then(validateDeviceRegistration);
  }

  public unregisterDevices(devices: Device[], masterPin: string): Promise<void> {
    return this.sendRequest('', () => TraxisQueries.unregisterDevices(devices))
      .then(validateDeviceUnregistration);
  }

  public generateCpeId(): string {
    try {
      return boProxy.sso.getDevicePublicId();
    } catch (error) {
      if (error instanceof Error && error.type === ErrorType.NotSupported) {
        return Utils.generateCpeId();
      }

      throw error;
    }
  }

  public getEntitledProducts(): Promise<EntitledProduct[]> {
    return this.sendRequest('', () => TraxisQueries.getEntitledProducts())
      .then(response => ProductMapper.fromJSONArray(response.Products.Product))
      .then(EntitledProductMapper.fromProducts);
  }

  public getStyling(name: string, version: string): Promise<Styling> {
    return this.uxManager ? this.uxManager.getStyling(name, version) : Promise.reject(new Error(ErrorType.NotConfigured));
  }

  public areChannelListsSupported(): boolean {
    return true;
  }

  public getChannelLists(profile: Profile): Promise<ChannelList[]> {
    return this.sendRequest('', () => TraxisQueries.getChannelLists(profile.id))
      .then(validateResponse)
      .then(response => ChannelListMapper.toChannelLists(response.ChannelLists.ChannelList));
  }

  public createChannelList(profile: Profile, name: string, channels: Channel[]): Promise<ChannelList> {
    return this.sendRequest('', () => TraxisQueries.createChannelList(profile.id, name))
      .then(validateResponse)
      .then(response => {
        const channelList = ChannelListMapper.fromJSON(response.ChannelList);
        channelList.name = name;
        if (!channels.length) {
          return channelList;
        }
        return this.sendRequest('', () => TraxisQueries.addChannelsToChannelList(profile.id, channelList, channels))
          .then(validateResponse)
          .then(() => {
            channelList.channels = channels;
            return channelList;
          });
      });
  }

  public deleteChannelList(profile: Profile, channelList: ChannelList): Promise<void> {
    return this.sendRequest('', () => TraxisQueries.deleteChannelList(profile.id, channelList))
      .then(validateResponse);
  }

  public updateChannelList(profile: Profile, channelList: ChannelList, name: string, channels: Channel[]): Promise<void> {
    const channelMap: Map<string, Channel> = new Map(channels.map(channel => [channel.id, channel]));
    const channelsToRemove: Channel[] = [];
    channelList.channels.forEach(channel => {
      if (channelMap.has(channel.id)) {
        channelMap.delete(channel.id);
      } else {
        channelsToRemove.push(channel);
      }
    });
    const channelsToAdd: Channel[] = Array.from(channelMap.values());

    const promises: Promise<void>[] = [];
    if (channelsToRemove.length) {
      promises.push(
        this.sendRequest('', () => TraxisQueries.removeChannelsFromChannelList(profile.id, channelList, channelsToRemove))
          .then(validateResponse)
      );
    }

    if (channelsToAdd.length) {
      promises.push(
        this.sendRequest('', () => TraxisQueries.addChannelsToChannelList(profile.id, channelList, channelsToAdd))
          .then(validateResponse)
      );
    }

    if (channelList.name !== name) {
      promises.push(
        this.sendRequest('', () => TraxisQueries.updateChannelListName(profile.id, channelList, name))
          .then(validateResponse)
      );
    }

    if (promises.length === 0) {
      return Promise.resolve();
    }

    return Promise.all(promises)
      .then(() => {
        channelList.name = name;
        channelList.channels = channels;
      });
  }

  public getConsentData(name: string): Promise<Consent> {
    if (!this.uxManager) {
      return Promise.reject(new Error(ErrorType.NotConfigured));
    }
    return this.uxManager.getConsentData(name);
  }

  public setConsent(slug: string, version: string, accepted: boolean): Promise<void> {
    switch (slug) {
      case ConsentSlug.NitroxEula:
        return this.setNitroxEULASlug(slug, version, accepted);
      default:
        return Promise.reject(new Error(ErrorType.NotSupported, 'Not supported slug: ' + slug));
    }
  }

  private setNitroxEULASlug(slug: string, version: string, accepted: boolean): Promise<void> {
    return this.sendRequest('', () => TraxisQueries.updateCustomerNamedProperties({AcceptedEULAVersion: accepted ? version : ''}))
      .then(validateResponse)
      .catch((error: Error) => {
        Log.error(TAG, 'Error setting customer\'s named property AcceptedEULAVersion', error);
        throw error?.type !== ErrorType.BOBadResponse ? error : new Error(ErrorType.CustomerUpdateFailure, error?.message);
      });
  }

  public getAvailableUILanguages(): Promise<string[]> {
    return this.uxManager ? this.uxManager.getAvailableUILanguages() : Promise.reject(new Error(ErrorType.NotConfigured));
  }

  public getDefaultUILanguage(): Promise<string> {
    return this.uxManager ? this.uxManager.getDefaultUILanguage() : Promise.reject(new Error(ErrorType.NotConfigured));
  }

  public getTranslations(version: string): Promise<Translations> {
    return this.uxManager ? this.uxManager.getTranslations(version) : Promise.reject(new Error(ErrorType.NotConfigured));
  }

  public getCAPMessage(params: CAPQueryParameters): Promise<CAPMessage> {
    return this.sendRequest('', () => TraxisQueries.getCAPMessage(params))
      .then(validateResponse)
      .then(response => CAPMessageMapper.fromJSON(response.Message));
  }

  public sendLocation(): Promise<void> {
    return this.sendRequest('', () => TraxisQueries.sendLocation());
  }

  public purchase(purchaseMethod: PurchaseMethod, params: PurchaseMethodParams): Promise<PurchaseResult> {
    if (purchaseMethod !== PurchaseMethod.Billing) {
      return Promise.reject(new Error(ErrorType.InvalidParameter, `Purchase method ${purchaseMethod} is not supported`));
    }
    const {productId, offerId, load, media} = params;
    if (!productId) {
      return Promise.reject(new Error(ErrorType.InvalidParameter, 'Missing productId in parameters'));
    }
    if (!offerId) {
      return Promise.reject(new Error(ErrorType.InvalidParameter, 'Missing offerId in parameters'));
    }
    if (load && !media) {
      return Promise.reject(new Error(ErrorType.InvalidParameter, 'Missing media in parameters'));
    }

    return this.sendRequest('', () => TraxisQueries.purchase(productId, offerId))
      .then(validateResponse)
      .then(() => {
        if (!load || !media) {
          return Promise.resolve({});
        }

        return this.getTitleById(media.id)
          .then(title => {
            return {result: title};
          });
      });
  }

  public registerAccount(): never {
    throw new Error(ErrorType.NotSupported);
  }

  public activateAccount(): Promise<never> {
    return Promise.reject(new Error(ErrorType.NotImplemented));
  }

  public validateAccount(): Promise<never> {
    return Promise.reject(new Error(ErrorType.NotImplemented));
  }

  public sendAccountActivationNotification(): Promise<never> {
    return Promise.reject(new Error(ErrorType.NotImplemented));
  }

  public getPaymentMethods(): Promise<PaymentMethod[]> {
    return Promise.resolve([{
      id: PaymentMethodId.Billing,
      displayName: `payments.method.${PaymentMethodId.Billing}`
    }]);
  }

  public startPayment(params: StartPaymentParams): Promise<Payment> {
    return Promise.reject(new Error(ErrorType.NotSupported));
  }

  public startRepayment(order: Order): Promise<Payment> {
    return Promise.reject(new Error(ErrorType.NotSupported));
  }

  public getOrders(params?: SortOrdersQueryParameters): Promise<{
    content: never[];
    totalElements: number;
  }> {
    return Promise.resolve({content: [], totalElements: 0});
  }

  public getMediaByIds(ids: string[]): Promise<[]> {
    return Promise.resolve([]);
  }

  public getProductById(productId: string): Promise<Product> {
    return Promise.reject(new Error(ErrorType.NotSupported));
  }

  public getAssetById(id: string): Promise<Media> {
    return Promise.reject(new Error(ErrorType.NotSupported));
  }

  public isEPGAvailable(): boolean {
    return true;
  }

  public async *getOwned(params: OwnedQueryParameters): AsyncIterableIterator<Media[]> {
    return Promise.reject(new Error(ErrorType.NotSupported));
  }

  public cancelOrder(orderId: string): Promise<void> {
    return Promise.reject(new Error(ErrorType.NotSupported));
  }
}
