export enum SortByOptions {
  CreateTime = 'CreateTime',
  Name = 'Name',
  NextScheduledStartTime = 'NextScheduledStartTime',
  PreviouslyScheduledStartTime = 'PreviouslyScheduledStartTime',
  StartTime = 'StartTime',
  LastViewDate = 'LastViewDate'
}

export enum Relations {
  ChannelLists = 'ChannelLists',
  ChannelLocations = 'ChannelLocations',
  Channels = 'Channels',
  ChildCategories = 'ChildCategories',
  ChildSeriesCollection = 'ChildSeriesCollection',
  Contents = 'Contents',
  Cpes = 'Cpes',
  Customers = 'Customers',
  Events = 'Events',
  Policies = 'Policies',
  Profiles = 'Profiles',
  PurchasedProducts = 'PurchasedProducts',
  RecordedTitles = 'RecordedTitles',
  Recommendations = 'Recommendations',
  Recordings = 'Recordings',
  RootCategories = 'RootCategories',
  SeriesCollection = 'SeriesCollection',
  Titles = 'Titles',
  ViewedTitles = 'ViewedTitles',
  ViewedEvents = 'ViewedEvents'
}

export enum Resources {
  Category = 'Category',
  Channel = 'Channel',
  ChannelList = 'ChannelList',
  Cpe = 'Cpe',
  Customer = 'Customer',
  Event = 'Event',
  Message = 'Message',
  Product = 'Product',
  Profile = 'Profile',
  Recording = 'Recording',
  Root = 'Root',
  Series = 'Series',
  Title = 'Title'
}

export enum Props {
  AvailabilityEnd = 'AvailabilityEnd',
  AvailabilityStart = 'AvailabilityStart',
  FirstAvailability = 'FirstAvailability',
  Genres = 'Genres',
  LastAvailability = 'LastAvailability',
  LastViewDate = 'LastViewDate',
  ListPrice = 'ListPrice',
  Locations = 'Locations',
  LogicalChannelNumber = 'LogicalChannelNumber',
  Name = 'Name',
  Ordinal = 'Ordinal',
  ProductionDate = 'ProductionDate',
  SoftLinks = 'SoftLinks'
}

export enum RootActions {
  AddCpeToCustomer = 'AddCpeToCustomer'
}

export enum CustomerAction {
  AddNamedProperties = 'AddNamedProperties'
}

export enum CpeAction {
  ForceUpdateLocation = 'ForceUpdateLocation'
}

export enum ProductAction {
  Purchase = 'Purchase'
}

export enum ProfileAction {
  AddNamedProperties = 'AddNamedProperties',
  Create = 'AddProfileToCustomer',
  Delete = 'Delete',
  Update = 'Update'
}

export enum RecordingsAction {
  AddToPersonalRecordings = 'AddToPersonalRecordings',
  Cancel = 'Cancel',
  Delete = 'Delete',
  DeleteFromPersonalRecordings = 'DeleteFromPersonalRecordings',
  Resume = 'Resume',
  Update = 'Update'
}

export enum WatchListAction {
  AddToWishList = 'AddToWishList',
  DeleteFromWishList = 'DeleteFromWishList',
  AddToWishListSeriesCollection = 'AddToWishListSeriesCollection',
  DeleteFromWishListSeriesCollection = 'DeleteFromWishListSeriesCollection'
}

export enum ChannelListAction {
  AddChannelList = 'AddChannelList',
  AddToPersonalChannelList = 'AddToPersonalChannelList',
  DeleteFromPersonalChannelList = 'DeleteFromPersonalChannelList',
  Update = 'Update',
  DeleteChannelList = 'DeleteChannelList'
}

export type LanguageType = 'Audio' | 'Subtitle';

export enum BookmarkActions {
  SetPersonalBookmark = 'SetPersonalBookmark',
  SetBookmark = 'SetBookmark'
}

export interface ResponseJson {
  [prop: string]: any;
}
