import {DateUtils} from 'common/DateUtils';
import {Log} from 'common/Log';

import {VideojsNitroxPlayer} from 'mw/playback/web-native-player/adapters/videojs/VideojsNitroxPlayer';

const TAG = 'VideojsNitroxPlayerHls';

export class VideojsNitroxPlayerHls extends VideojsNitroxPlayer {

  public getPosition(): number {
    if (this.isLiveStream()) {

      const playlistStartDate = this.getHlsLivePlaylistStartDate();
      if (playlistStartDate) {
        const player = this.getPlayer();
        const currentPlayTime = player.currentTime();
        const currentLiveTime = player.liveTracker.liveCurrentTime();

        // The difference between reference point
        // at which the currentPlayTime/liveCurrentTime is counted and playlistStartDate.
        let timeDiff;
        // liveCurrentTime() can, in some cases, return Infinity e.x. on Android
        if (currentLiveTime && (currentLiveTime !== Infinity)) {
          timeDiff = currentLiveTime - this.getLiveWindow();
        } else {
          Log.warn(TAG, 'getPosition: wrong currentLiveTime', currentLiveTime);
          timeDiff = 0;
        }

        return Math.round(playlistStartDate + currentPlayTime - timeDiff);
      }
    }

    return super.getPosition();
  }

  protected getHlsLivePlaylistStartDate(): number | null {
    const player = this.getPlayer();

    // HLS library - videojs-http-streaming (VHS) is included in videojs by default
    // we can access it either through player.vhs property
    // or thru player.tech()
    // (player.tech({IWontUseItForPlugins: true}) is a proper way)
    // according to https://github.com/videojs/http-streaming#hlsplaylistsmedia
    const hlsHandler = player.vhs || this.playerTech()?.hls;
    if (!hlsHandler) {
      return null;
    }
    const actualPlaylist = hlsHandler.playlists.media();
    if (!actualPlaylist) {
      return null;
    }

    const playlistStartDate = (() => {
      const {dateTimeString, dateTimeObject} = actualPlaylist;
      if (dateTimeObject && DateUtils.isValidDate(dateTimeObject)) {
        return new Date(dateTimeObject.getTime());
      }
      if (dateTimeString) {
        const date = new Date(dateTimeString);
        if (DateUtils.isValidDate(date)) {
          return date;
        }
      }
      return null;
    })();

    if (playlistStartDate) {
      return Math.round(DateUtils.getSeconds(playlistStartDate));
    }
    Log.warn(TAG, 'getHlsLivePlaylistStartDate: wrong playlists start time', actualPlaylist?.dateTimeString);
    return null;
  }

  protected getLiveStreamPlaylistEnd(): number | null {
    const playlistStartDate = this.getHlsLivePlaylistStartDate();
    if (playlistStartDate) {
      const liveWindow = this.getLiveWindow();
      if (!isNaN(liveWindow)) {
        return Math.round(playlistStartDate + liveWindow);
      }
    }

    return super.getLiveStreamPlaylistEnd();
  }

  protected getLiveWindow(): number {
    return this.getPlayer().liveTracker?.liveWindow();
  }

  protected drmInit(): void {}
}
