import {createStyles} from 'common-styles';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {LayoutChangeEvent, ListRenderItemInfo, View, ViewStyle} from 'react-native';

import {isMobileBrowser} from 'common/constants';
import {idKeyExtractor} from 'common/HelperFunctions';

import {Channel} from 'mw/api/Metadata';

import {ChannelIconType} from 'components/ChannelIcon';
import ChannelsListItem from 'components/channelsList/ChannelsListItem.piccolo';
import NitroxFlatList from 'components/NitroxFlatList';
import {useFunction, useLazyEffect} from 'hooks/Hooks';

import {channelsListItemDimensions, channelsListSeparatorDimensions} from './ChannelsListDimensions';
import {getChannelsListItemLayout} from './ChannelsListHelperFunctions';
import ChannelsListItemSeparator from './ChannelsListItemSeparator';
import {ChannelsListProps} from './ChannelsListProps';

const itemHeight = channelsListItemDimensions.size + channelsListSeparatorDimensions.verticalContainerSize;

const styles = createStyles({
  listContainer: {
    flex: 1,
    width: channelsListItemDimensions.size
  }
});

type Props = ChannelsListProps & {
  visible?: boolean;
  showChannelNumber?: boolean;
  iconType?: ChannelIconType;
};

const VerticalChannelsListPiccolo: React.FC<Props> = props => {
  const {channels: propsChannels, onPress, onScroll: propsOnScroll, selectedChannelId, visible = true, showChannelNumber = false, iconType} = props;
  const list = useRef<NitroxFlatList<Channel>>(null);
  const [height, setHeight] = useState(0);
  const onLayout = useCallback((event: LayoutChangeEvent) => setHeight(event.nativeEvent.layout.height), []);
  const [channels, setChannels] = useState<Channel[]>([]);
  const selectedChannelIndex = useMemo(() => propsChannels.findIndex(channel => channel.id === selectedChannelId), [propsChannels, selectedChannelId]);

  const onChannelPress = useCallback((channelId?: string) => {
    const channel = channelId ? channels.find(c => c.id === channelId) : undefined;
    channel && onPress(channel);
  }, [channels, onPress]);

  const renderItem = useCallback((item: ListRenderItemInfo<Channel>) => {
    return (
      <ChannelsListItem
        channel={item.item}
        horizontal={false}
        isSelected={item.index === selectedChannelIndex}
        onPress={onChannelPress}
        showChannelNumber={showChannelNumber}
        iconType={iconType}
      />
    );
  }, [selectedChannelIndex, onChannelPress, showChannelNumber, iconType]);

  const renderSeparator = useCallback(() => <ChannelsListItemSeparator horizontal={false} />, []);

  // duringScrollDrag flag is needed to differentiate manual scroll and programmatic scroll - props is called only when user is dragging the list manually
  const duringScrollDrag = useRef(false);
  const onScrollBeginDrag = useCallback(() => {
    duringScrollDrag.current = true;
  }, []);
  const onScrollEndDrag = useCallback(() => {
    duringScrollDrag.current = false;
  }, []);
  const onScroll = useFunction(() => {
    if (visible && duringScrollDrag.current) {
      propsOnScroll?.();
    }
  });

  // Content container size must be set before passing data to FlatList otherwise the initial scrolling may
  // fail or it will be visible for the user.
  const contentContainerStyle = useMemo<ViewStyle>(() => {
    const paddingTop = channelsListItemDimensions.offset / 2;
    const paddingBottom = height - channelsListItemDimensions.size - channelsListItemDimensions.offset / 2;
    return {
      paddingTop,
      paddingBottom,
      height: paddingTop + paddingBottom + itemHeight * propsChannels.length,
      alignItems: 'center'
    };
  }, [height, propsChannels.length]);

  const [initialScrollPerformed, setInitialScrollPerformed] = useState(false);

  useLazyEffect(() => {
    if (height > 0 && selectedChannelIndex >= 0 && contentContainerStyle.height) {
      const offset = itemHeight * selectedChannelIndex;
      if (offset < contentContainerStyle.height) {
        list.current?.scrollToOffset({offset, animated: visible && initialScrollPerformed});
        if (!initialScrollPerformed) {
          setInitialScrollPerformed(true);
        }
      }
    }
  }, [height, selectedChannelIndex, contentContainerStyle.height, visible], [initialScrollPerformed]);

  // Data must be passed to FlatList after initial scroll has been performed otherwise scrolling may be visible for
  // user and resources will be wasted for rendering items which are not in current viewport.
  useEffect(() => {
    if (initialScrollPerformed) {
      setChannels(propsChannels);
    }
  }, [propsChannels, initialScrollPerformed]);

  return (
    <View
      style={styles.listContainer}
      testID='channels_list_vertical'
      onLayout={onLayout}
    >
      {height > 0 && (
        <NitroxFlatList<Channel>
          ref={list}
          horizontal={false}
          data={channels}
          // Required to force updating selected item in web browser
          extraData={isMobileBrowser ? selectedChannelId : undefined}
          contentContainerStyle={contentContainerStyle}
          renderItem={renderItem}
          ItemSeparatorComponent={renderSeparator}
          onScroll={onScroll}
          onScrollBeginDrag={onScrollBeginDrag}
          onScrollEndDrag={onScrollEndDrag}
          initialNumToRender={channels.length}
          keyExtractor={idKeyExtractor}
          getItemLayout={getChannelsListItemLayout}
          decelerationRate='fast'
          scrollEventThrottle={3}
          overScrollMode='never'
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
        />
      )}
    </View>
  );
};

export default React.memo(VerticalChannelsListPiccolo);
