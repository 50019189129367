import {EventEmitter} from 'common/EventEmitter';
import {ChangeEvent} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {Error, ErrorType} from 'mw/api/Error';
import {Media, ChannelList, Channel} from 'mw/api/Metadata';
import {boProxy} from 'mw/bo-proxy/BOProxy';
import {createLocalSettings} from 'mw/common/utils';
import {WatchListManager, WatchListEvent, createWatchListManager} from 'mw/common/watchlist/WatchListManager';
import {mw} from 'mw/MW';
import {asyncStorage} from 'mw/platform/async-storage/AsyncStorage';

import {CacheType} from './CatalogInterface';
import {DateFormatDescription, TimeFormatDescription} from './NXFF';

const TAG = 'Profile';
const SELECTED_CHANNEL_LIST_STORAGE_KEY = `Profile-selectedChannelList-`;

export enum ProfileType {
  Main = 'main',
  Normal = 'normal'
}

export enum ProfileEvent {
  PropertiesChange = 'PropertiesChange',
  WatchListChange = 'WatchListChange'
}

export enum PinState {
  ProfilePinRequired = 'ProfilePinRequired',
  ProfilePinNotRequired = 'ProfilePinNotRequired',
  ProfilePinNotSet = 'ProfilePinNotSet'
}

export type ProfilePropertiesChangePayload = ChangeEvent<ProfileStoredProperties> & {key: keyof ProfileStoredProperties};
export type ProfileEventPayload = ProfilePropertiesChangePayload;
export type PCRatingsMap = {[authority: string]: string};

export interface ProfileStoredProperties {
  name: string;
  isPCEnabled: boolean;
  avatarUri: string;
  uiLanguage: string;
  audioLanguage: string;
  subtitleLanguage: string;
  yearOfBirth: number;
  pinState: PinState;
  dateFormat: string;
  timeFormat: string;
  uiColor: string;
  ccEnabled: boolean;
  showAdultContent: boolean;
  blockUnratedEvents: boolean;
  blockUnratedMovies: boolean;
  pcRatings: PCRatingsMap;
}

export interface ProfileConfigurableProperties extends ProfileStoredProperties {
  pin: string;
}

export function isPCPropertyChanged(oldProfile: Profile, newProfile: Profile): boolean {
  return newProfile.isPCEnabled !== oldProfile.isPCEnabled
    || newProfile.blockUnratedEvents !== oldProfile.blockUnratedEvents
    || newProfile.blockUnratedMovies !== oldProfile.blockUnratedMovies
    || newProfile.showAdultContent !== oldProfile.showAdultContent;
}

export class Profile extends EventEmitter<ProfileEvent, ProfileEventPayload> implements Readonly<ProfileStoredProperties> {
  public readonly id: string;
  public readonly externalId?: string;
  private type: ProfileType;
  private properties: ProfileStoredProperties;
  private watchListManager: WatchListManager;
  public channelLists: ChannelList[] = [];

  public get isMain(): boolean { return this.type === ProfileType.Main; }
  public get name(): string { return this.properties.name; }
  public get avatarUri(): string { return this.properties.avatarUri; }
  public get uiLanguage(): string { return this.properties.uiLanguage; }
  public get audioLanguage(): string { return this.properties.audioLanguage; }
  public get subtitleLanguage(): string { return this.properties.subtitleLanguage; }
  public get yearOfBirth(): number { return this.properties.yearOfBirth; }
  public get age(): number { return new Date().getFullYear() - this.properties.yearOfBirth; }
  public get isPinRequired(): boolean { return this.properties.pinState === PinState.ProfilePinRequired; }
  public get pinState(): PinState { return this.properties.pinState; }
  public get dateFormat(): string { return this.properties.dateFormat; }
  public get timeFormat(): string { return this.properties.timeFormat; }
  public get uiColor(): string { return this.properties.uiColor; }
  public get ccEnabled(): boolean { return this.properties.ccEnabled; }
  public get isPCEnabled(): boolean { return this.properties.isPCEnabled; }
  public get showAdultContent(): boolean { return this.properties.showAdultContent; }
  public get blockUnratedEvents(): boolean { return this.properties.blockUnratedEvents; }
  public get blockUnratedMovies(): boolean { return this.properties.blockUnratedMovies; }
  public get pcRatings(): PCRatingsMap { return this.properties.pcRatings; }
  public get watchList(): Media[] { return this.watchListManager.watchList; }

  public readonly localSettings = createLocalSettings(() => `PROFILE_${this.id}_`);

  public constructor(params: {
    id: string;
    externalId?: string;
    name: string;
    type?: ProfileType;
    avatarUri?: string;
    uiLanguage?: string;
    audioLanguage?: string;
    subtitleLanguage?: string;
    yearOfBirth?: number;
    pinState?: PinState;
    dateFormat?: string;
    timeFormat?: string;
    uiColor?: string;
    ccEnabled?: boolean;
    isPCEnabled?: boolean;
    showAdultContent?: boolean;
    blockUnratedEvents?: boolean;
    blockUnratedMovies?: boolean;
    pcRatings?: {[key: string]: string};
  }) {
    super();
    const defaultDateFormat = mw.configuration.getDefaultDateFormat();
    const defaultTimeFormat = mw.configuration.getDefaultTimeFormat();
    const uiLanguage = params.uiLanguage || mw.configuration.defaultUILanguage;
    const audioLanguage = params.audioLanguage || mw.configuration.defaultAudioLanguage;
    const subtitleLanguage = params.subtitleLanguage || mw.configuration.defaultSubtitlesLanguage;

    const {
      id,
      externalId,
      name,
      type = ProfileType.Normal,
      avatarUri = '',
      yearOfBirth = -1,
      pinState = PinState.ProfilePinNotRequired,
      uiColor = '',
      ccEnabled = false,
      isPCEnabled = false,
      showAdultContent = false,
      blockUnratedEvents = false,
      blockUnratedMovies = false,
      pcRatings = {}
    } = params;
    let {dateFormat = defaultDateFormat, timeFormat = defaultTimeFormat} = params;
    this.id = id;
    this.externalId = externalId;
    this.type = type;
    if (![...mw.configuration.availableDateFormats].find(format => format.value === dateFormat)) {
      dateFormat = defaultDateFormat;
    }
    if (![...mw.configuration.availableTimeFormats].find(format => format.value === timeFormat)) {
      timeFormat = defaultTimeFormat;
    }
    this.properties = {
      name,
      avatarUri,
      uiLanguage,
      audioLanguage,
      subtitleLanguage,
      yearOfBirth,
      pinState,
      dateFormat,
      timeFormat,
      uiColor,
      ccEnabled,
      isPCEnabled,
      showAdultContent,
      blockUnratedEvents,
      blockUnratedMovies,
      pcRatings
    };
    this.watchListManager = createWatchListManager(this);
    this.watchListManager.on(WatchListEvent.WatchListChange, this.onWatchListChange);
  }

  public refresh(): Promise<void> {
    // NOTE: Any type of refresh and/or initialization type of operations for profile should be put here.
    return Promise.all([
      mw.catalog.refreshCache(CacheType.Channels, CacheType.EPG, CacheType.Content),
      this.watchListManager.refresh()
    ])
      .then(() => {})
      .catch(error => {
        Log.error(TAG, 'Error refreshing profile data:', error);
      });
  }

  // properties map should be accessible publicly as readonly
  // shallow copy is enough in this case
  public getProperties(): ProfileStoredProperties {
    return {...this.properties};
  }

  public setType(type: ProfileType): void {
    this.type = type;
  }

  public async setName(name: string, masterPin: string): Promise<void> {
    try {
      await boProxy.bo.setNameForProfile(this, name, masterPin);
      this.setProperty('name', name);
      Log.debug(TAG, `Successfully set name "${name}" for profile ${this}`);
    } catch (error) {
      Log.error(TAG, `Error setting name for profile ${this}`, error);
      throw error;
    }
  }

  public async setPin(pin: string): Promise<void> {
    try {
      await boProxy.bo.setPinForProfile(this, pin);
      Log.debug(TAG, `Successfully set pin for profile ${this}`);
    } catch (error) {
      Log.error(TAG, `Error setting pin for profile ${this}`, error);
      throw error;
    }
  }

  public async checkPin(pin: string): Promise<void> {
    Log.debug(TAG, 'Verifying pin...');
    if (this.pinState === PinState.ProfilePinNotSet) {
      Log.error(TAG, 'Unable to verify pin - profile pin is not set');
      throw new Error(ErrorType.IncorrectPin);
    }
    try {
      await boProxy.bo.verifyProfilePin(this, pin);
      Log.debug(TAG, 'Pin verification successful');
    } catch (error) {
      Log.error(TAG, 'Error verifying pin: ', error);
      throw error;
    }
  }

  public setIsPCEnabled(isPCEnabled: boolean): Promise<void> {
    return this.setModifiableProperty('isPCEnabled', isPCEnabled);
  }

  private setProperty<Property extends keyof ProfileStoredProperties>(key: Property, value: ProfileConfigurableProperties[Property]) {
    if (this.properties[key] === value) {
      return;
    }
    const snapshot = {...this.properties};
    this.properties[key] = value;
    this.notify(ProfileEvent.PropertiesChange, {from: snapshot, to: {...this.properties}, key});
  }

  private async setModifiableProperty<Property extends keyof ProfileStoredProperties>(key: Property, value: ProfileConfigurableProperties[Property]) {
    try {
      await boProxy.bo.setDataForProfile(this, key, value);
      this.setProperty(key, value);
      Log.debug(TAG, `Successfully set ${key} to "${value}" for profile ${this}`);
    } catch (error) {
      Log.error(TAG, `Error setting ${key} for profile ${this}`, error);
      throw error;
    }
  }

  public setYearOfBirth(year: number): Promise<void> {
    return this.setModifiableProperty('yearOfBirth', year);
  }

  public setUILanguage(language: string): Promise<void> {
    return this.setModifiableProperty('uiLanguage', language);
  }

  public setPinState(pinState: PinState): Promise<void> {
    return this.setModifiableProperty('pinState', pinState);
  }

  public setDateFormat(format: DateFormatDescription): Promise<void> {
    if (![...mw.configuration.availableDateFormats].find(f => f.value === format.value)) {
      const error = `${format} is not one of allowed Date Formats`;

      Log.error(TAG, error);
      throw new Error(ErrorType.ProfileUpdateFailure, error);
    }

    return this.setModifiableProperty('dateFormat', format.value);
  }

  public setTimeFormat(format: TimeFormatDescription): Promise<void> {
    if (![...mw.configuration.availableTimeFormats].find(f => f.value === format.value)) {
      const error = `${format} is not one of allowed Time Formats`;

      Log.error(TAG, error);
      throw new Error(ErrorType.ProfileUpdateFailure, error);
    }

    return this.setModifiableProperty('timeFormat', format.value);
  }

  public setAudioLanguage(language: string): Promise<void> {
    return this.setModifiableProperty('audioLanguage', language);
  }

  public setSubtitleLanguage(language: string): Promise<void> {
    return this.setModifiableProperty('subtitleLanguage', language);
  }

  public setUIColor(color: string): Promise<void> {
    return this.setModifiableProperty('uiColor', color);
  }

  public setCCEnabled(ccEnabled: boolean): Promise<void> {
    return this.setModifiableProperty('ccEnabled', ccEnabled);
  }

  public setShowAdultContent(showAdultContent: boolean): Promise<void> {
    return this.setModifiableProperty('showAdultContent', showAdultContent);
  }

  public setBlockUnratedEvents(blockUnratedEvents: boolean): Promise<void> {
    return this.setModifiableProperty('blockUnratedEvents', blockUnratedEvents);
  }

  public setBlockUnratedMovies(blockUnratedMovies: boolean): Promise<void> {
    return this.setModifiableProperty('blockUnratedMovies', blockUnratedMovies);
  }

  public setPCRating(authority: string, rating: string): Promise<void> {
    const pcRatings = this.pcRatings;
    pcRatings[authority] = rating;
    return this.setModifiableProperty('pcRatings', pcRatings);
  }

  public addToWatchList(media: Media): Promise<void> {
    return this.watchListManager.add(media);
  }

  public removeFromWatchList(mediaArray: Media[]): Promise<void> {
    return this.watchListManager.remove(mediaArray);
  }

  public isOnWatchList(media: Media): boolean {
    return this.watchListManager.isOnWatchList(media);
  }

  private onWatchListChange = () => {
    this.notify(ProfileEvent.WatchListChange);
  }

  public refreshChannelLists(): Promise<void> {
    return !boProxy.bo.areChannelListsSupported() ?
      Promise.resolve() :
      boProxy.bo.getChannelLists(this)
        .then(channelLists => {
          this.channelLists = channelLists;
        })
        .catch(error => {
          Log.error(TAG, `Failed to refresh channel lists on profile ${this}`, error);
          throw error;
        });
  }

  public async createChannelList(name: string, channels: Channel[]): Promise<void> {
    try {
      this.channelLists.push(await boProxy.bo.createChannelList(this, name, channels));
    } catch (error) {
      Log.error(TAG, `Failed to create channel list ${name} on profile ${this}`, error);
      throw error;
    }
  }

  public async deleteChannelList(channelList: ChannelList): Promise<void> {
    try {
      await boProxy.bo.deleteChannelList(this, channelList);
      this.channelLists = this.channelLists.filter(c => c.id !== channelList.id);
    } catch (error) {
      Log.error(TAG, `Failed to delete channel list ${channelList.name} on profile ${this}`, error);
      throw error;
    }
  }

  public async updateChannelList(channelList: ChannelList, name: string, channels: Channel[]): Promise<void> {
    try {
      await boProxy.bo.updateChannelList(this, channelList, name, channels);
    } catch (error) {
      Log.error(TAG, `Failed to update channel list ${channelList.name} on profile ${this}`, error);
      throw error;
    }
  }

  public selectChannelList(channelList: ChannelList): Promise<void> {
    return asyncStorage.setSecureItem(this.createSelectedChannelListStorageKey(), channelList.id)
      .catch(error => {
        Log.error(TAG, `Failed to select channel list ${channelList.name} on profile ${this}`, error);
        throw error;
      });
  }

  private createSelectedChannelListStorageKey(): string {
    return SELECTED_CHANNEL_LIST_STORAGE_KEY + this.id;
  }

  public getSelectedChannelList(): Promise<ChannelList | undefined> {
    return asyncStorage.getSecureItem(this.createSelectedChannelListStorageKey())
      .then(channelListId => {
        return channelListId ? this.channelLists.find(channelList => channelList.id === channelListId) : undefined;
      })
      .catch(error => {
        Log.error(TAG, `Failed to get selected channel list on profile ${this}`, error);
        throw error;
      });
  }

  public toString(): string {
    return `Profile(id: "${this.id}", name: "${this.name}")`;
  }
}
