import {useMemo, useRef} from 'react';
import {PanEvent} from 'react-native-tvos-controller';

import {queued, delay} from 'common/Async';
import {Direction} from 'common/constants';
import {Point} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {NativeKeyEvent, SupportedKeys, supportedDirections, supportedChannelKeys} from 'components/KeyEventManager';
import {useSTBMenu} from 'components/navigation/STBNavigationView';
import {useKeyEventHandler, useFunction, useIsScreenFocused, useLazyRef} from 'hooks/Hooks';

export function useArrowsListener(
  handler: (direction: Direction) => void,
  directions: Direction[] | 'all' = 'all',
  {
    handleTVOSPanGesture = false,
    active = true
  }: {
    handleTVOSPanGesture?: boolean;
    active?: boolean;
  } = {}
): void {
  const {inNavigationContext, isScreenFocused} = useIsScreenFocused();
  const isScreenBlurred = inNavigationContext && !isScreenFocused;
  const menuHasFocus = useSTBMenu()?.hasFocus;
  const listener = (event: NativeKeyEvent) => {
    const key = event.key as number;
    if (isScreenBlurred || menuHasFocus || !active || key == null) {
      return;
    }
    if (supportedDirections.includes(key) && (directions === 'all' || directions.includes(key))) {
      handler(key);
    }
  };
  useKeyEventHandler('keyup', listener);

  const panGestureHandler = useTVOSPanGesture(event => listener(event));

  useKeyEventHandler(handleTVOSPanGesture ? 'ios:pan' : 'ios:swipe', handleTVOSPanGesture ? panGestureHandler : listener);
}
type KeysListenerParams = {
  active?: boolean;
}
export function useKeysListener(
  supportedKeys: readonly SupportedKeys[],
  handler: (key: number) => void,
  {
    active = true
  }: KeysListenerParams = {}
): void {
  const {isScreenFocused, inNavigationContext} = useIsScreenFocused();
  const isScreenBlurred = inNavigationContext && !isScreenFocused;

  const listener = useFunction((event: NativeKeyEvent) => {
    const key = event.key;
    if (isScreenBlurred || !active || typeof key !== 'number' || !supportedKeys.includes(key)) {
      return;
    }
    handler(key);
  });
  useKeyEventHandler('keyup', listener);
}

export function useChannelKeysListener(handler: (direction: Direction) => void): void {
  useKeysListener(supportedChannelKeys, useFunction((key: number) => {
    switch (key) {
      case SupportedKeys.ChannelUp:
        handler(Direction.Up);
        break;
      case SupportedKeys.ChannelDown:
        handler(Direction.Down);
        break;
    }
  }));
}

export function useKeyListener(
  key: SupportedKeys,
  handler: () => void,
  params?: KeysListenerParams
): void {
  useKeysListener(useMemo(() => [key], [key]), handler, params);
}

const thresholds = {
  distance: {
    x: 600,
    y: 600,
    minimum: 300
  },
  velocity: {
    horizontal: 6500,
    vertical: 5000
  },
  fastScroll: {
    minimumDistance: 800,
    minimumVelocity: 7500
  }
};

const multipleEventsTimeout = 20;
const eventQueueDeadline = 500;

/**
 * Returns a handler to tvOS pan gesture recognizer.
 * @param handleArrowKeys
 * tvOS pan gesture events will be mapped to simple discrete movement events
 * and passed to handleArrowKeys function, events are sent in intervals, so
 * make sure this callback is disposed of on unmount,
 * e.g. a pan gesture triggered by swiping down will result in 5 calls to this
 * handler with an event payload: {key: 'down'}
 */
export function useTVOSPanGesture(handleArrowKeys: (event: NativeKeyEvent) => void): (event: NativeKeyEvent) => void {
  const panEventState = useRef<PanEvent | null>(null);
  /**
   * Remembers gesture distance of last enqueued keyevent.
   */
  const distanceMark = useRef<{distance: Point} | null>(null);
  const firstEvent = useRef(true);

  const handleKeys = useFunction((event: NativeKeyEvent) => {
    return new Promise((resolve) => {
      handleArrowKeys(event);
      return delay(multipleEventsTimeout).then(resolve);
    });
  });

  const enqueue = useLazyRef(() => queued(handleKeys, {deadlineAfter: eventQueueDeadline})).current;

  const enqueueEvents = useFunction((event: NativeKeyEvent, count: number) => {
    for (let i = 0; i < count; ++i) {
      enqueue(event);
    }
  });

  return useFunction((event: NativeKeyEvent) => {
    const panEvent = event?.event as PanEvent;
    if (!panEvent) {
      return;
    }
    const {velocityX, velocityY, x, y} = panEvent;
    if (velocityX == null || velocityY == null || x == null || y == null) {
      return;
    }
    switch (panEvent.state) {
      case 'Began':
        panEventState.current = panEvent;
        firstEvent.current = true;
        break;
      case 'Ended': {
        panEventState.current = null;
        distanceMark.current = null;
        firstEvent.current = true;

        const isHorizontal = Math.abs(velocityX) > Math.abs(velocityY);
        const velocity = isHorizontal ? velocityX : velocityY;
        const velocityThreshold = thresholds.velocity[isHorizontal ? 'horizontal' : 'vertical'];
        const distance = isHorizontal ? x : y;

        const count = Math.abs(velocity) > thresholds.fastScroll.minimumVelocity && Math.abs(distance) > thresholds.fastScroll.minimumDistance
          ? Math.floor(Math.abs(velocity) / velocityThreshold)
          : 0;

        if (count) {
          const event: NativeKeyEvent = isHorizontal
            ? {key: velocity > 0 ? SupportedKeys.Right : SupportedKeys.Left}
            : {key: velocity > 0 ? SupportedKeys.Down : SupportedKeys.Up};
          Log.debug(useTVOSPanGesture.name, `${isHorizontal ? 'Horizontal' : 'Vertical'} swipe mapped to ${count} events ${event.key}`);
          enqueueEvents(event, count);
        }
        break;
      }
      case 'Changed':
        if (!panEventState.current) {
          break;
        }
        const isHorizontal = Math.abs(velocityX) > Math.abs(velocityY);
        const velocity = isHorizontal ? velocityX : velocityY;
        const distance = isHorizontal ? x : y;
        const previousDistance = distanceMark.current?.distance[isHorizontal ? 'x' : 'y'] ?? 0;

        const distanceDiff = Math.abs(distance - previousDistance);
        const distanceThreshold = thresholds.distance[isHorizontal ? 'x' : 'y'];

        const count = distanceDiff >= thresholds.distance.minimum && firstEvent.current === true
          ? 1
          : Math.floor(distanceDiff / distanceThreshold);

        if (count) {
          const event: NativeKeyEvent = isHorizontal
            ? {key: velocity > 0 ? SupportedKeys.Right : SupportedKeys.Left}
            : {key: velocity > 0 ? SupportedKeys.Down : SupportedKeys.Up};
          Log.debug('useTVOSPanGesture', `${isHorizontal ? 'Horizontal' : 'Vertical'} swipe mapped to ${count} events ${event.key}`);
          enqueueEvents(event, count);
          distanceMark.current = {distance: {x, y}};
          firstEvent.current = false;
        }
        panEventState.current = panEvent;
        break;
    }
  });
}
