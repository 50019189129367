import {createStyles} from 'common-styles';
import React, {useCallback, useMemo} from 'react';
import {StyleSheet, View} from 'react-native';

import {dimensions, RADIUS} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {isRecording, Media, RecordingStatus, PictureType, PictureMode} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import {ChannelIcon, ChannelIconType} from 'components/ChannelIcon';
import Checkbox from 'components/Checkbox';
import {Icon, IconType} from 'components/Icon';
import MediaPicture from 'components/MediaPicture';
import {channelIconOffset, MediaTileConfig} from 'components/mediaTiles/MediaTileHooks';
import MiniProgressBar from 'components/MiniProgressBar';
import NitroxInteractive from 'components/NitroxInteractive';
import NitroxText from 'components/NitroxText';

const maxNumberOfLinesForTitle = 1;
const maxNumberOfLinesForMetadata = 2;
const posterSize = dimensions.pictures.mobileTile.default;
const posterIconSize = Math.floor(posterSize.height / 2);

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: dimensions.margins.medium,
    width: '100%',
    minHeight: posterSize.height
  },
  interactiveContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexGrow: 1,
    minHeight: posterSize.height,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: colors.columnTile.border,
    paddingBottom: dimensions.margins.medium,
    flex: 1
  },
  posterOuterContainer: {
    marginLeft: channelIconOffset,
    ...posterSize
  },
  posterContainer: {
    borderRadius: RADIUS,
    overflow: 'hidden',
    backgroundColor: colors.tile.poster.placeholder.background,
    ...posterSize
  },
  channelIcon: {
    position: 'absolute',
    top: -channelIconOffset,
    left: -channelIconOffset
  },
  contentContainer: {
    flex: 1,
    paddingLeft: dimensions.margins.small
  },
  labelsContainer: {
    marginBottom: dimensions.margins.xsmall
  },
  titleLabel: {
    color: colors.columnTile.title,
    textAlign: 'left',
    flexShrink: 1
  },
  subtitleLabel: {
    color: colors.columnTile.subtitle,
    textAlign: 'left'
  },
  metadataRowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: dimensions.margins.xsmall
  },
  progressBar: {
    width: '100%',
    marginTop: dimensions.margins.small
  },
  startTimeText: {
    color: colors.columnTile.time,
    marginRight: dimensions.margins.small
  },
  moreActions: {
    width: dimensions.icon.small,
    justifyContent: 'center',
    alignItems: 'flex-end'
  },
  checkboxContainer: {
    width: dimensions.icon.small,
    marginRight: dimensions.margins.large,
    justifyContent: 'center',
    alignItems: 'flex-start',
    minHeight: posterSize.height
  },
  moreActionsIconColor: colors.columnTile.moreActionsIcon,
  additionalIconRenderer: {
    marginRight: dimensions.margins.small
  },
  noPosterIcon: {
    ...StyleSheet.absoluteFillObject,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.tile.poster.placeholder.background
  }
}));

type MobileMediaTileWideProps = {
  media: Media;
  config?: MediaTileConfig;
  progress?: number;
  startTime?: string;
  channelId?: string;
  selectable?: boolean;
  selected?: boolean;
  isBlocked?: boolean;
  showMoreActionsButton?: boolean;
  onMoreActionsPress?: () => void;
  onSelectionCheckboxPress?: (selected: boolean) => void;
  onPress?: () => void;
  firstRowItemsRenderer?: () => React.ReactNode;
  secondRowItemsRenderer?: () => React.ReactNode;
  additionalIconRenderer?: () => React.ReactNode;
};

const MobileMediaTileWide: React.FC<MobileMediaTileWideProps> = props => {
  const {media, onMoreActionsPress, onSelectionCheckboxPress, onPress, channelId, config, progress, startTime, firstRowItemsRenderer, secondRowItemsRenderer, selectable, selected, showMoreActionsButton, isBlocked, additionalIconRenderer} = props;
  const shouldRenderFailedRecordingIcon = useMemo(() => isRecording(media) && media.status === RecordingStatus.Failed, [media]);

  const onPressAction = useCallback(() => onPress?.(), [onPress]);

  const moreActionsHandler = useCallback(() => {
    onMoreActionsPress && onMoreActionsPress();
  }, [onMoreActionsPress]);

  const selectionCheckboxHandler = useCallback((selected: boolean) => {
    onSelectionCheckboxPress && onSelectionCheckboxPress(selected);
  }, [onSelectionCheckboxPress]);

  const posterSource = useMemo(() => {
    const uri = mw.catalog.getPictureUrl(config?.posterMedia ?? media, PictureType.Tile, posterSize.width, posterSize.height, PictureMode.BOX);
    return uri ? {uri} : null;
  }, [config, media]);
  const styles = stylesUpdater.getStyles();
  const poster = useMemo(() => (
    <View style={styles.posterOuterContainer}>
      <View style={styles.posterContainer}>
        {shouldRenderFailedRecordingIcon ? (
          <View style={styles.noPosterIcon}>
            <Icon type={IconType.FailedRecording} size={dimensions.icon.small} />
          </View>
        ) : (
          <MediaPicture
            source={posterSource}
            mediaType={(config?.posterMedia ?? media).getType()}
            isBlocked={isBlocked}
            iconSize={posterIconSize}
            customPlaceholderIcon={config?.customPlaceholderIcon}
          />
        )}
      </View>
      {typeof channelId === 'string' && <ChannelIcon type={ChannelIconType.Static} channelId={channelId} style={styles.channelIcon} />}
    </View>
  ), [styles.posterOuterContainer, styles.posterContainer, styles.channelIcon, posterSource, config, media, isBlocked, channelId]);

  const titles = useMemo(() => (
    <View style={styles.labelsContainer}>
      <NitroxText textType='tile-title' numberOfLines={maxNumberOfLinesForTitle} style={styles.titleLabel}>{config?.title}</NitroxText>
      {!!config?.infoSection &&
        <NitroxText textType='pin-pad-message' numberOfLines={maxNumberOfLinesForMetadata} style={styles.subtitleLabel}>{config?.infoSection }</NitroxText>
      }
    </View>
  ), [config, styles.labelsContainer, styles.subtitleLabel, styles.titleLabel]);

  const firstRowMetadata = useMemo(() => {
    if (!startTime && !firstRowItemsRenderer) {
      return null;
    }
    return (
      <View style={styles.metadataRowContainer}>
        {startTime && <NitroxText textType='tile-title' style={styles.startTimeText}>{startTime}</NitroxText>}
        {firstRowItemsRenderer && firstRowItemsRenderer()}
        {additionalIconRenderer && (
          <View style={styles.additionalIconRenderer}>
            {additionalIconRenderer()}
          </View>
        )}
      </View>
    );
  }, [firstRowItemsRenderer, styles.metadataRowContainer, styles.startTimeText, styles.additionalIconRenderer, startTime, additionalIconRenderer]);

  const secondRowMetadata = useMemo(() => {
    if (!secondRowItemsRenderer) {
      return null;
    }
    return (
      <View style={styles.metadataRowContainer}>
        {secondRowItemsRenderer()}
      </View>
    );
  }, [secondRowItemsRenderer, styles.metadataRowContainer]);

  const moreButton = useMemo(() => {
    if (!showMoreActionsButton) {
      return null;
    }
    return (
      <NitroxInteractive style={[styles.moreActions, {height: posterSize.height}]} onPress={moreActionsHandler} testID='button_more_actions'>
        <Icon type={IconType.MoreActions} size={dimensions.icon.xxsmall} color={styles.moreActionsIconColor} />
      </NitroxInteractive>
    );
  }, [moreActionsHandler, showMoreActionsButton, styles.moreActions, styles.moreActionsIconColor]);

  const selectionCheckbox = useMemo(() => {
    if (!selectable) {
      return null;
    }
    return (
      <View style={[styles.checkboxContainer, {height: posterSize.height}]}>
        <Checkbox selected={selected} onPress={selectionCheckboxHandler}></Checkbox>
      </View>
    );
  }, [selectable, selected, selectionCheckboxHandler, styles.checkboxContainer]);

  return (
    <View style={styles.container}>
      {selectionCheckbox}
      <NitroxInteractive style={styles.interactiveContainer} onPress={onPressAction} testID='poster'>
        {poster}
        <View style={styles.contentContainer}>
          {titles}
          {firstRowMetadata}
          {secondRowMetadata}
          {progress && progress >= 0 && <MiniProgressBar style={styles.progressBar} progress={progress} />}
        </View>
        {moreButton}
      </NitroxInteractive>
    </View>
  );
};

export default React.memo(MobileMediaTileWide);
