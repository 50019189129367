import {createStyles} from 'common-styles';
import React, {useCallback, useMemo} from 'react';
import {View} from 'react-native';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Channel, Event} from 'mw/api/Metadata';

import {ChannelIcon, ChannelIconType} from 'components/ChannelIcon';
import NitroxText from 'components/NitroxText';
import {getFormattedStartEndTime} from 'screens/tv/TvScreenHelperFunctions';

const maxNumberOfLinesForTitle = 1;
const maxNumberOfLinesForMetadata = 1;
const borderRadius = 10;
const containerHeight = 130;
const iconWidth = 100;

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    backgroundColor: colors.channelShortDetails.background.default
  },
  containerFocused: {
    backgroundColor: colors.channelShortDetails.background.focused
  },
  iconContainer: {
    backgroundColor: colors.channelShortDetails.iconContainer.background.default
  },
  iconContainerFocused: {
    backgroundColor: colors.channelShortDetails.iconContainer.background.focused
  },
  text: {
    color: colors.channelShortDetails.text
  },
  icon: {
    backgroundColor: colors.channelShortDetails.channelIcon.background.default
  },
  iconFocused: {
    backgroundColor: colors.channelShortDetails.channelIcon.background.focused
  },
  lcnContainer: {
    backgroundColor: colors.channelShortDetails.channelIcon.lcn.background.default
  },
  lcnContainerFocused: {
    backgroundColor: colors.channelShortDetails.channelIcon.lcn.background.focused
  },
  lcnContainerSelected: {
    backgroundColor: colors.channelShortDetails.channelIcon.lcn.background.selected
  }
}));

const staticStyles = createStyles({
  container: {
    flexDirection: 'row',
    height: containerHeight,
    width: '100%',
    marginVertical: dimensions.margins.small,
    overflow: 'hidden',
    borderRadius
  },
  iconContainer: {
    height: containerHeight,
    width: containerHeight,
    justifyContent: 'center',
    alignItems: 'center'
  },
  eventInfo: {
    justifyContent: 'center',
    flex: 1
  },
  lcnContainer: {
    width: iconWidth,
    justifyContent: 'center',
    alignItems: 'center'
  },
  lcnContainerRadiusStyle: {
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10
  }
});

type Props = {
  channel: Channel;
  event?: Event;
  isSelected?: boolean;
  isFocused?: boolean;
};

const ChannelShortDetails: React.FC<Props> = props => {
  const {channel, event, isSelected, isFocused} = props;
  const dynamicStyles = dynamicStylesUpdater.getStyles();
  const backgroundStyle = useMemo(() => isFocused ? dynamicStyles.containerFocused : dynamicStyles.container, [isFocused, dynamicStyles]);
  const iconContainerBackgroundStyle = useMemo(() => isFocused ? dynamicStyles.iconContainerFocused : dynamicStyles.iconContainer, [isFocused, dynamicStyles]);
  const title = useMemo(() => event?.name || '', [event]);
  const metadata = useMemo(() => event ? getFormattedStartEndTime(event) : '', [event]);

  const renderIcon = useCallback(() => {
    return (
      <>
        <ChannelIcon
          type={ChannelIconType.ChannelShortDetails}
          channelId={channel.id}
          isSelected={isFocused}
          iconBackgroundStyle={isFocused ? dynamicStyles.iconFocused : dynamicStyles.icon}
        >
        </ChannelIcon>
        <View style={[
          staticStyles.lcnContainer,
          dynamicStyles.lcnContainer,
          isFocused ? {
            width: '100%',
            flex: 1,
            ...dynamicStyles.lcnContainerFocused
          } : staticStyles.lcnContainerRadiusStyle,
          isSelected && dynamicStyles.lcnContainerSelected
        ]}
        >
          <NitroxText textType={'lcn'} style={dynamicStyles.text} testID='channel_number'>{channel.lcn}</NitroxText>
        </View>
      </>
    );
  }, [channel, isFocused, dynamicStyles, isSelected]);

  const renderEventInfo = useCallback(() => {
    return (
      <View
        style={[
          staticStyles.eventInfo,
          {paddingHorizontal: isFocused ? dimensions.margins.xxLarge : dimensions.margins.medium}
        ]}
      >
        <NitroxText
          textType={isFocused ? 'channelShortDetailsTitleFocused' : 'channelShortDetailsTitle'}
          style={dynamicStyles.text}
          numberOfLines={maxNumberOfLinesForTitle}
          testID='title'
        >
          {title}
        </NitroxText>
        <NitroxText
          textType={isFocused ? 'channelShortDetailsMetadataFocused' : 'channelShortDetailsMetadata'}
          style={dynamicStyles.text}
          numberOfLines={maxNumberOfLinesForMetadata}
        >
          {metadata}
        </NitroxText>
      </View>
    );
  }, [isFocused, dynamicStyles, title, metadata]);

  return (
    <View
      style={[staticStyles.container, backgroundStyle]}
      testID={`channel_short_details${isFocused ? '_focused' : ''}`}
    >
      <View style={[staticStyles.iconContainer, iconContainerBackgroundStyle]}>
        {renderIcon()}
      </View>
      {event && renderEventInfo()}
    </View>
  );
};

export default React.memo(ChannelShortDetails);
