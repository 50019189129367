import {ErrorType, Error} from './Error';
import {PlaybackStopReason, Media} from './Metadata';

export enum PlayerEvent {
  // Native events
  AudioLanguagesChanged = 'audioLanguagesChanged',
  VideoResolutionsChanged = 'videoResolutionsChanged',
  Error = 'error',
  Ready = 'ready',
  FirstFrame = 'firstFrame',
  BeginOfContent = 'beginOfContent',
  EndOfContent = 'endOfContent',
  Buffering = 'buffering',
  Stopped = 'stopped',
  PositionChanged = 'positionChanged',
  SubtitlesChanged = 'subtitlesChanged',

  // MW events
  MediaUpdate = 'MediaUpdate',
  BookmarkUpdate = 'BookmarkUpdate',
  BlockedByParentalControl = 'BlockedByParentalControl',
  PlaybackLimitationsChanged = 'PlaybackLimitationsChanged',
  ParametersChanged = 'parametersChanged',
  VideoHidden = 'VideoHidden',
  Loading = 'Loading',
  Rewinding = 'Rewinding',
  RewindStopped = 'RewindStopped',
  Initialize = 'Initialize',
  StopRequested = 'StopRequested'
}

export {RewindingPositionChangedEvent, RewindingEvent} from 'mw/playback/controllers/rewinders/PlaybackRewinder';

export interface Track {
  language: string;
  type: string;
  codec?: string;
  id?: number;
}

export interface TracksList {
  currentTrack: number;
  tracks: Track[];
}

export interface AudioLanguagesChanged {
  audioLanguageList: TracksList;
}

export interface AvailableVideoResolutionList {
  currentResolution?: number;
  videoResolutions: string[];
}

export interface VideoResolutionsChanged {
  availableVideoResolutionList: AvailableVideoResolutionList;
}

export interface ErrorEvent {
  error: ErrorType;
  message?: string;
  extra?: string;
}

export interface MediaEvent {
  url: string;
}

export interface PositionChanged {
  beginPosition: number;
  position: number;
  endPosition: number;
}

export interface SubtitlesChanged {
  subtitleLanguageList: TracksList;
}

export interface StoppedEvent extends MediaEvent {
  reason?: PlaybackStopReason;
}

export interface BookmarkUpdate {
  media: Media;
  position: number;
}

export interface StopParams {
  reason: PlaybackStopReason;
  error?: Error;
}
