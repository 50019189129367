import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {View, StyleSheet} from 'react-native';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Icon, IconType} from 'components/Icon';

import NitroxInteractive from './NitroxInteractive';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  playIconContainer: {
    flex: 1
  },
  playIcon: {
    ...StyleSheet.absoluteFillObject,
    alignItems: 'center',
    justifyContent: 'center'
  },
  iconPlayColor: colors.columnTile.poster.playIcon
}));

type PlayIconProps = {
  onPress?: () => void;
  iconSize: number;
}

const PlayIcon: React.FC<PlayIconProps> = props => {
  const styles = stylesUpdater.getStyles();

  const Container = useMemo<React.FC>(
    () => props.onPress
      ? ({children}) => (
        <NitroxInteractive
          onPress={props.onPress}
          testID='button_icon_play_trailer'
          style={styles.playIcon}
        >
          {children}
        </NitroxInteractive>
      )
      : ({children}) => <View style={styles.playIcon}>{children}</View>,
    [props.onPress, styles.playIcon]
  );

  return (
    <View style={styles.playIconContainer}>
      <Container>
        <Icon type={IconType.Trailer} size={props.iconSize} color={styles.iconPlayColor} shadow />
      </Container>
    </View>
  );
};

export default React.memo(PlayIcon);
