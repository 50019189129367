import {createStyles} from 'common-styles';
import React, {ReactNode, useMemo, useCallback, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {View, Keyboard} from 'react-native';

import {isPhone, dimensions, isMobile, getValue, isBigScreen} from 'common/constants';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Error} from 'mw/api/Error';

import {BigScreenHeaderProps} from 'components/BigScreenHeader';
import ErrorPopup, {useErrorPopup} from 'components/ErrorPopup';
import {MobileScreenHeaderProps} from 'components/mobileScreenHeader/MobileScreenHeader';
import ForwardButton from 'components/navigation/ForwardButton';
import NitroxButton, {NitroxButtonTheme} from 'components/NitroxButton';
import {useNavigation, useFunction} from 'hooks/Hooks';
import {useLocalized} from 'locales/i18nUtils';
import NitroxScreen from 'screens/NitroxScreen';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flex: 1,
    backgroundColor: isMobile ? colors.settingsScreen.mobile.profile.edit.background : colors.transparent,
    justifyContent: 'flex-start'
  },
  safeArea: {
    borderTopLeftRadius: isBigScreen ? 40 : undefined,
    backgroundColor: getValue({
      mobile: colors.doubleBackground.top,
      tablet: colors.parallelNavigator.column.right,
      defaultValue: colors.settingsScreen.transparentBackground
    })
  },
  topContent: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: getValue({
      mobile: colors.doubleBackground.top,
      tablet: colors.parallelNavigator.column.right,
      defaultValue: colors.transparent
    })
  },
  bottomContent: {
    flex: 1
  },
  mobileHeader: {
    backgroundColor: isPhone ? colors.doubleBackground.top : colors.parallelNavigator.column.right
  },
  rightContentButtonsContainer: {
    alignSelf: 'center',
    flexDirection: 'row'
  },
  rightContentButton: {
    height: dimensions.buttons.small,
    borderRadius: Math.round(dimensions.buttons.small / 2),
    marginRight: dimensions.margins.medium
  }
}));

export type ProfileEditSubmitResponse = {ok: true} | {ok: false; error: Error};

type SettingsProfileEditProps = {
  onSubmit?: () => Promise<ProfileEditSubmitResponse>;
  header: ReactNode;
  title?: string;
  buttonsVisible?: boolean;
}
const SettingsProfileEdit: React.FC<SettingsProfileEditProps> = ({
  title,
  header,
  children,
  onSubmit,
  buttonsVisible
}) => {
  const waitingForConfirmation = useRef(false);
  const [submitting, setSubmitting] = useState(false);
  const navigation = useNavigation();

  const {error, showError, onCloseErrorPopup} = useErrorPopup();
  const {t} = useTranslation();

  const handleSubmit = useCallback(async () => {
    if (!onSubmit || waitingForConfirmation.current) {
      return;
    }
    waitingForConfirmation.current = true;

    Keyboard.dismiss();
    setSubmitting(true);

    const response = await onSubmit();
    if (response.ok) {
      if (isPhone) {
        navigation.pop();
      }
    } else {
      Log.error('SettingsProfileEdit', 'Error while trying to save profile:', response.error);
      showError(`${response.error.message || ''} (code: ${response.error.type})`.trim());
    }
    waitingForConfirmation.current = false;
    setSubmitting(false);
  }, [showError, onSubmit, navigation]);

  const handleCancel = useFunction(() => {
    Keyboard.dismiss();
  });

  const styles = stylesUpdater.getStyles();

  const rightContentButtons = useMemo(() => {
    return (
      <View style={styles.rightContentButtonsContainer} >
        <NitroxButton
          style={styles.rightContentButton}
          theme={NitroxButtonTheme.Secondary}
          onPress={handleCancel}
          textType={'forward-buttons'}
          text={t('settings.cancel')}
        />
        <ForwardButton onPress={handleSubmit} text={t('common.done')} busy={submitting} />
      </View>
    );
  }, [handleCancel, handleSubmit, styles.rightContentButton, styles.rightContentButtonsContainer, submitting, t]);

  const {toUpperCase} = useLocalized();

  const mobileHeader = useMemo<MobileScreenHeaderProps>(() => ({
    title: buttonsVisible ? undefined : title,
    rightContent: onSubmit && buttonsVisible && rightContentButtons,
    style: styles.mobileHeader
  }), [title, onSubmit, buttonsVisible, rightContentButtons, styles.mobileHeader]);

  const bigScreenHeader = useMemo<BigScreenHeaderProps>(() => ({
    title: toUpperCase(title || '')
  }), [title, toUpperCase]);

  return (
    <NitroxScreen
      navigation={useNavigation()}
      style={styles.safeArea}
      mobileHeaderProps={mobileHeader}
      bigScreenHeaderProps={bigScreenHeader}
      showBigScreenHeader
      embedded={!isPhone}
      showOperatorLogo={!isPhone}
    >
      <View style={styles.container}>
        {isMobile && (
          <View style={styles.topContent}>
            {header}
          </View>
        )}
        <View style={styles.bottomContent}>
          {children}
        </View>
      </View>
      <ErrorPopup
        error={error}
        onClose={onCloseErrorPopup}
      />
    </NitroxScreen>
  );
};

export default SettingsProfileEdit;
