import {EventEmitter} from 'common/EventEmitter';

import {Media} from 'mw/api/Metadata';
import {Profile} from 'mw/api/Profile';
import {mw} from 'mw/MW';

import {DisabledWatchListManager} from './DisabledWatchListManager';
import {EnabledWatchListManager} from './EnabledWatchListManager';

export enum WatchListEvent {
  WatchListChange = 'WatchListChange'
}
export interface WatchListManager extends EventEmitter<WatchListEvent>{
  watchList: Media[];
  add(media: Media): Promise<void>;
  remove(mediaArray: Media[]): Promise<void>;
  isOnWatchList(media: Media): boolean;
  refresh(): Promise<void>;
}

export function createWatchListManager(profile: Profile): WatchListManager {
  return new (mw.configuration.enabledWatchlist ? EnabledWatchListManager : DisabledWatchListManager)(profile);
}
