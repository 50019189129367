import {Channel, Picture} from 'mw/api/Metadata';
import {ChannelObject} from 'mw/metadata/ChannelObject';

import {PictureMapper} from './PictureMapper';

export class ChannelMapper {
  private static findEPGId(element: any): string {
    for (const externalId of element.externalIds || []) {
      if (externalId.source === 'epgChannelId' && typeof externalId.id === 'string') {
        return externalId.id;
      }
    }
    return '';
  }

  public static channelsFromJson(jsonContent: any[]): Channel[] {
    const channels: Channel[] = [];
    let lcn = 1;
    jsonContent.forEach((element: any) => {
      let pictures: Picture[] = [];
      if (element.images) {
        pictures = element.images.map(PictureMapper.pictureFromJson);
      }
      const channel = new ChannelObject(element.uid, element.title, element.title, lcn++);
      channel.pictures = pictures;
      channel.customProperties.epgId = ChannelMapper.findEPGId(element);
      channels.push(channel);
    });
    return channels;
  }
}
