import {PlayerEvent, AvailableVideoResolutionList, VideoResolutionsChanged as VideoResolutionsChangedInterface} from 'mw/api/PlayerEvent';
import {PlayerType} from 'mw/playback/types/PlayerType';

import {WebPlaybackEvent} from './WebPlaybackEvent';

export class VideoResolutionsChanged extends WebPlaybackEvent {

  public videoResolutionsChanged: VideoResolutionsChangedInterface;

  public constructor(playerType: PlayerType, videoTracks: string[], trackIndex: number, streamId: number) {
    super(PlayerEvent.VideoResolutionsChanged, playerType, streamId);

    const availableVideoResolutionList: AvailableVideoResolutionList = {
      videoResolutions: videoTracks
    };

    if (trackIndex >= 0 && trackIndex < videoTracks.length) {
      availableVideoResolutionList.currentResolution = trackIndex;
    }

    this.videoResolutionsChanged = {
      availableVideoResolutionList: availableVideoResolutionList
    };
  }
}
