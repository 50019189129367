import {EventEmitter} from 'common/EventEmitter';

import {PlaybackEvent} from './types/PlaybackEvent';

interface EventEmitterInterface {
  addListener(eventName: string, callback: (event: PlaybackEvent) => void): void;
  removeListener(eventName: string, callback: (event: PlaybackEvent) => void): void;
}

export interface NativeEventEmitter {
  sendEvent(playbackEvent: any): void;
}

enum NativePlaybackEvents {
  playbackEvent = 'playbackEvent'
}

class WebPlaybackEventEmitter extends EventEmitter<NativePlaybackEvents> implements EventEmitterInterface, NativeEventEmitter {
  public static readonly instance = new WebPlaybackEventEmitter();

  public addListener(event: NativePlaybackEvents, callback: (event: PlaybackEvent) => void): void {
    this.on(event, callback);
  }

  public removeListener(event: NativePlaybackEvents, callback: (event: PlaybackEvent) => void): void {
    this.off(event, callback);
  }

  public sendEvent = (playbackEvent: any): void => {
    this.notify(NativePlaybackEvents.playbackEvent, playbackEvent);
  }
}

export const NativeEventEmitter: NativeEventEmitter = WebPlaybackEventEmitter.instance;
export const NativePlaybackEventEmitter: EventEmitterInterface = WebPlaybackEventEmitter.instance;
