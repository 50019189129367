import {DateUtils} from 'common/DateUtils';
import {EventEmitter} from 'common/EventEmitter';
import {compactMap, split, SplitElement, flatten, isSeriesEpisode} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {Error, ErrorType} from 'mw/api/Error';
import {boProxy} from 'mw/bo-proxy/BOProxy';
import {mw, CatalogEvent} from 'mw/MW';

import {
  Event,
  isSingleRecording,
  OngoingEventRecording,
  PVREvent,
  PVRGuardTimes,
  PVRQueryParameters,
  PVRQuota,
  PVRScheduleParameters,
  PVRUpdateParameters,
  Recording,
  RecordingsSorting,
  RecordingStatus,
  SingleRecording,
  SortRecordingsBy,
  PVRAvailableGuardTimes,
  RecordingType
} from './Metadata';
import {nxffConfig} from './NXFF';

const TAG = 'PVR';

export class PVR extends EventEmitter<PVREvent> {
  private quota: PVRQuota = {
    available: 0,
    remaining: 0
  };

  public readonly statusCache: Map<string, RecordingStatus> = new Map();

  public constructor() {
    super();
  }

  private refreshTimeoutId = 0;
  private scheduleRecordingsListRefresh(): void {
    if (!nxffConfig.getConfig().PVR.KeepStatesOfRecordings) {
      return;
    }

    this.cancelRecordingsListRefresh();
    this.refreshTimeoutId = setTimeout(() => {
      this.refreshTimeoutId = 0;
      this.updateRecordingsList()
        .catch((error: Error) => {
          Log.error(TAG, 'scheduleRecordingsListRefresh: failed to update list', error);
        })
        .finally(() => this.scheduleRecordingsListRefresh());
    }, nxffConfig.getConfig().PVR.RecordingsRefreshInterval * DateUtils.msInMin);
  }

  private cancelRecordingsListRefresh(): void {
    if (this.refreshTimeoutId) {
      clearTimeout(this.refreshTimeoutId);
      this.refreshTimeoutId = 0;
    }
  }

  private quotaRefreshTimeoutId = 0;
  private scheduleQuotaRefresh(): void {
    if (!nxffConfig.getConfig().PVR.QuotaRefreshInterval) {
      return;
    }

    this.cancelQuotaRefresh();
    this.quotaRefreshTimeoutId = setTimeout(() => {
      this.refreshCachedPVRQuota()
        .catch((error: Error) => {
          Log.error(TAG, 'scheduleQuotaRefresh: failed to refresh quota', error);
        })
        .finally(() => this.scheduleQuotaRefresh());
    }, nxffConfig.getConfig().PVR.QuotaRefreshInterval * DateUtils.msInMin);
  }

  private cancelQuotaRefresh(): void {
    if (this.quotaRefreshTimeoutId) {
      clearTimeout(this.quotaRefreshTimeoutId);
      this.quotaRefreshTimeoutId = 0;
    }
  }

  public async updateRecordingsList(): Promise<void> {
    if (!nxffConfig.getConfig().PVR.KeepStatesOfRecordings) {
      return;
    }
    const statusesMap = await boProxy.bo.getAllRecordingsStatuses();
    this.statusCache.clear();
    statusesMap.forEach((status, eventId) => {
      this.statusCache.set(eventId, status);
    });
  }

  public defaultSorting(status: RecordingStatus): RecordingsSorting {
    return status === RecordingStatus.Scheduled
      ? {type: SortRecordingsBy.start, ascending: true}
      : {type: SortRecordingsBy.start, ascending: false};
  }

  public setPVRQuota(quota: PVRQuota): void {
    const changed = quota.available !== this.quota.available || quota.remaining !== this.quota.remaining;
    // send notification after cached value is updated in order to make sure that UI will use a proper value
    if (changed) {
      this.quota = quota;
      Log.debug(TAG, `NPVR quota changed with available space ${this.quota.available} and remaining space ${this.quota.remaining}`);
      this.notify(PVREvent.PVRQuotaChanged, this.getCachedPVRQuota());
    }
  }

  public getCachedPVRQuota(): PVRQuota {
    return this.quota;
  }

  public getPVRQuota(): Promise<PVRQuota> {
    return this.refreshCachedPVRQuota()
      .then(() => this.quota);
  }

  public getAvailableGuardTimes(event?: Event): PVRAvailableGuardTimes {
    if (!event) {
      let start = nxffConfig.getConfig().PVR.AvailableGuardTimes;
      let end = nxffConfig.getConfig().PVR.AvailableGuardTimes;

      if (start.length) {
        start = [0, ...start];
      }

      if (end.length) {
        end = [0, ...end];
      }

      return {start, end};
    }

    const channel = mw.catalog.getChannelById(event.channelId);

    if (!channel) {
      return {
        start: [],
        end: []
      };
    }

    // calculate initial allowedPreMinutes based on rolling buffer size
    // according to restriction Event.AvailabilityStart - GuardTimeStart >= now - Channel.RecordingInThePastLimit
    let allowedPreMinutes = Math.max((event.start.getTime() - (new Date).getTime()) / DateUtils.msInMin + channel.recordingInThePastLimit, 0);

    if (event.isNow) {
      switch (channel.ongoingEventRecording) {
        case OngoingEventRecording.FromStart:
          // use initial value, guard time restricted only by rolling buffer
          break;
        case OngoingEventRecording.FromTunePoint:
          const currentMedia = mw.players.main.getCurrentMedia();
          const playbackStartTime = mw.players.main.playbackStartTime;
          if (playbackStartTime && channel.equals(currentMedia) && playbackStartTime < event.start) {
            //tune point is before Event.AvailabilityStart, calculating based on Event.AvailabilityStart - GuardTimeStart > tune point
            allowedPreMinutes = Math.min(allowedPreMinutes, (event.start.getTime() - playbackStartTime.getTime()) / DateUtils.msInMin);
          } else {
            // tune point is after Event.AvailabilityStart, not possible to set pre minutes
            allowedPreMinutes = 0;
          }
          break;
        case OngoingEventRecording.FromRequest:
        case OngoingEventRecording.NotAllowed:
          // not possible to set pre minutes
          allowedPreMinutes = 0;
          break;
      }
    }

    let start = nxffConfig.getConfig().PVR.AvailableGuardTimes.filter(guardTime => guardTime <= allowedPreMinutes);
    let end = nxffConfig.getConfig().PVR.AvailableGuardTimes;

    if (start.length) {
      start = [0, ...start];
    }

    if (end.length) {
      end = [0, ...end];
    }

    return {start, end};
  }

  private verifyGuardTimes(guardTimes: PVRGuardTimes, event?: Event): boolean {
    const availableGuardTimes = mw.pvr.getAvailableGuardTimes(event);

    return (guardTimes.start === undefined || availableGuardTimes.start.includes(guardTimes.start))
      && (guardTimes.end === undefined || availableGuardTimes.end.includes(guardTimes.end));
  }

  public getGuardTimes(): Promise<PVRGuardTimes> {
    return Promise.reject('getGuardTimes is not implemented');
  }

  public setGuardTimes(value: PVRGuardTimes): Promise<boolean> {
    return Promise.reject('setGuardTimes is not implemented');
  }

  private cacheRecordingStatus = (rec: Recording): void => {
    const eventId = rec.event?.id;
    const status = rec.status;
    if (eventId && status != null) {
      this.statusCache.set(eventId, status);
    }
  }

  public getRecordings(params: PVRQueryParameters): Promise<Recording[]> {
    return boProxy.bo.getRecordings(params).then((recordings) => {
      recordings.forEach(this.cacheRecordingStatus);
      return recordings;
    });
  }

  public async scheduleRecording(params: PVRScheduleParameters): Promise<void> {
    const eventForGuardTimes = params.type === RecordingType.Single ? params.event : undefined;
    if (params.guardTimes && !this.verifyGuardTimes(params.guardTimes, eventForGuardTimes)) {
      throw new Error(ErrorType.InvalidParameter, `guardTime provided in params is not not allowed by NXFD`);
    }

    const recording = await this.scheduleRecordingImpl(params);
    return this.refreshRecordingsState([recording]);
  }

  private async getSeriesRecordingEvents(recording: Recording): Promise<Event[]> {
    const queryParams: PVRQueryParameters = {
      parentRecording: recording
    };
    const recordings = await boProxy.bo.getRecordings(queryParams).catch((reason) => {
      // this may happen when scheduled statusCache refresh was triggered on Series Recording that has been removed
      Log.warn(TAG, `getSeriesRecordingEvents: failed to get recordings`, reason);
      return [];
    });
    if (recordings.length === 0) {
      return [];
    }

    const {single: episodes, series} = split(recordings, (childRecording) => {
      const result: SplitElement<Recording> = {};
      result[childRecording.recordingType] = childRecording;
      return result;
    });

    const events = episodes && this.getEventsCachedInEpg(episodes as SingleRecording[]) || [];
    const promises = series?.map((recording: Recording): Promise<Event[]> => this.getSeriesRecordingEvents(recording));
    if (promises?.length) {
      const eventsArray = await Promise.all(promises);
      flatten(eventsArray, events);
    }
    return events;
  }

  private getEventsCachedInEpg(recordings: SingleRecording[]): Event[] {
    return compactMap(recordings, (childRecording: SingleRecording): Event | null => {
      const {event} = childRecording;
      return mw.catalog.getEpgCacheEventById(event.id, event.channelId);
    });
  }

  private scheduleRecordingImpl(params: PVRScheduleParameters): Promise<Recording> {
    const {event} = params;

    if (!event) {
      Log.error(TAG, 'Failed to schedule a new recording an event is required');
      return Promise.reject(new Error(ErrorType.InvalidParameter, 'Failed to schedule a new recording'));
    }

    const scheduleParameters: PVRScheduleParameters = {
      ...params
    };

    if (event.isNow) {
      const channel = mw.catalog.getChannelById(event.channelId);
      if (!channel) {
        return Promise.reject(new Error(ErrorType.InvalidParameter, 'can\'t schedule for unknown channel for live event'));
      }

      switch (channel.ongoingEventRecording) {
        case OngoingEventRecording.FromTunePoint:
          const currentMedia = mw.players.main.getCurrentMedia();
          const playbackStartTime = mw.players.main.playbackStartTime;
          scheduleParameters.startTime = playbackStartTime && channel.equals(currentMedia) ?
            (event.start > playbackStartTime ? event.start : playbackStartTime) :
            new Date();
          break;
        case OngoingEventRecording.FromStart:
          scheduleParameters.startTime = event.start;
          break;
        case OngoingEventRecording.FromRequest:
          // there is no need to any action
          break;
        case OngoingEventRecording.NotAllowed:
          return Promise.reject(new Error(ErrorType.InvalidParameter, 'Failed to create query to schedule a new recording - recording ongoing event on this channel is forbidden'));
      }
    }
    return boProxy.bo.scheduleRecording(scheduleParameters);
  }

  private removeDeletedRecordingsFromCache(events: Event[]) {
    events.forEach((event) => {
      this.statusCache.delete(event.id);
    });
    mw.catalog.notify(CatalogEvent.EventsIsRecordedUpdated, events); //TODO: CL-3595 event should be renamed to more common one
  }

  private async updateRecordingStateMap(events: Event[]) {
    if (events.length === 0) {
      return;
    }
    const map = await boProxy.bo.getRecordingsStatus(events);
    if (map.size === 0) {
      this.removeDeletedRecordingsFromCache(events);
      return;
    }

    const updatedEvents: Event[] = [];
    map.forEach((status, eventId) => {
      const previousState = this.statusCache.get(eventId);
      if (previousState !== status) {
        this.statusCache.set(eventId, status);
        const event = events.find((event) => event.id === eventId);
        if (event) {
          updatedEvents.push(event);
        }
      }
    });
    mw.catalog.notify(CatalogEvent.EventsIsRecordedUpdated, updatedEvents); //TODO: CL-3595 event should be renamed to more common one
  }

  public async updateEventsRecordingsState(events: Event[]): Promise<void> {
    if (!nxffConfig.getConfig().PVR.KeepStatesOfRecordings) {
      await mw.catalog.updateEventsIsRecorded(events);
      this.notify(PVREvent.PVRRecordingsChanged);
      return;
    }
    await this.updateRecordingStateMap(events);
    this.notify(PVREvent.PVRRecordingsChanged);
  }

  private async getAndUpdateEventsRecordingState(recordings: Recording[]): Promise<void> {
    const events = await this.getRecordingsEvents(recordings);
    await this.updateRecordingStateMap(events);
  }

  public async updateRecordingsStates(recordings: Recording[]): Promise<void> {
    if (!nxffConfig.getConfig().PVR.KeepStatesOfRecordings) {
      const events = await this.getRecordingsEvents(recordings);
      await mw.catalog.updateEventsIsRecorded(events);
      this.notify(PVREvent.PVRRecordingsChanged);
      return;
    }

    const single: Recording[] = [];
    const series: Recording[] = [];
    recordings.forEach((recording) => {
      if (isSingleRecording(recording)) {
        single.push(recording);
      } else {
        series.push(recording);
      }
    });

    if (series.length) {
      setTimeout(() => {
        this.getAndUpdateEventsRecordingState(series)
          .then(() => this.notify(PVREvent.PVRRecordingsChanged));
      }, nxffConfig.getConfig().PVR.RecordingsRefreshDelayAfterUpdateAction * DateUtils.msInSec);
    }
    if (single.length) {
      await this.getAndUpdateEventsRecordingState(single);
      this.notify(PVREvent.PVRRecordingsChanged);
    }
  }

  private async refreshRecordingsState(recordings: Recording[]): Promise<void> {
    await this.refreshCachedPVRQuota(); // we need to refresh cached quota after every CRUD operation
    return this.updateRecordingsStates(recordings);
  }

  private async getRecordingsEvents(recordings: Recording[]): Promise<Event[]> {
    const single: Event[] = [];
    const seriesPromises: Promise<Event[]>[] = [];
    recordings.forEach((recording) => {
      if (isSingleRecording(recording)) {
        single.push(recording.event);
      } else {
        seriesPromises.push(this.getSeriesRecordingEvents(recording));
      }
    });
    const series = await Promise.all(seriesPromises).then(flatten);
    return flatten([single, series]);
  }

  public async deleteRecordings(recordings: Recording[]): Promise<void> {
    const events = await this.getRecordingsEvents(recordings);
    try {
      await boProxy.bo.deleteRecordings(recordings);
    } finally {
      // Multiple recordings deletion can fail partially, so quota and recording states refresh has to be done anyway
      await this.refreshCachedPVRQuota();
      await this.updateEventsRecordingsState(events);
    }
  }

  public async cancelRecordings(recordings: Recording[]): Promise<void> {
    try {
      await boProxy.bo.cancelRecordings(recordings);
    } finally {
      // Multiple recordings cancellation can fail partially, so recording states refresh has to be done anyway
      await this.refreshRecordingsState(recordings);
    }
  }

  public async resumeRecordings(recordings: Recording[], params?: PVRUpdateParameters): Promise<void> {
    try {
      await boProxy.bo.resumeRecordings(recordings, params);
    } finally {
      // Multiple recordings resumption can fail partially, so recording states refresh has to be done anyway
      await this.refreshRecordingsState(recordings);
    }
  }

  public async updateRecordings(recordings: Recording[], params: PVRUpdateParameters): Promise<void> {
    if (params.guardTimes) {
      for (const recording of recordings) {
        const eventForGuardTimes = recording.recordingType === RecordingType.Single ? recording.event : undefined;
        if (!this.verifyGuardTimes(params.guardTimes, eventForGuardTimes)) {
          throw new Error(ErrorType.InvalidParameter, `guardTime provided in params is not not allowed by NXFD`);
        }
      }
    }

    try {
      await boProxy.bo.updateRecordings(recordings, params);
    } finally {
      // Multiple recordings update can fail partially, so recording states refresh has to be done anyway
      await this.refreshRecordingsState(recordings);
    }
  }

  public initialize(): void {
    this.updateRecordingsList();
    this.scheduleRecordingsListRefresh();
    this.scheduleQuotaRefresh();
  }

  public uninitialize(): void {
    this.quota = {
      available: 0,
      remaining: 0
    };
    this.cancelRecordingsListRefresh();
    this.cancelQuotaRefresh();
  }

  public isEventNPVRAllowed(event: Event): boolean {
    const channel = mw.catalog.getChannelById(event.channelId);
    return mw.configuration.isNPVREnabled
      && event.customProperties.isNetworkRecordingAllowed
      && (!event.isPast || (nxffConfig.getConfig().PVR.EnabledRecordingPastEvents && event.hasTstv))
      && (!event.isNow || !(channel?.ongoingEventRecording === OngoingEventRecording.NotAllowed))
      || false;
  }

  public isSeriesNPVRAllowed(event: Event): boolean {
    return mw.configuration.isNPVREnabled
      && isSeriesEpisode(event.title)
      && event.customProperties.isNetworkRecordingAllowed;
  }

  private refreshCachedPVRQuota(): Promise<void> {
    // update cached value
    return boProxy.bo.getNPVRQuota()
      .then(quota => {
        this.setPVRQuota(quota);
      });
  }
}
