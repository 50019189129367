import {AppState, AppStateStatus, AppStateEvent} from 'react-native';

import {isDesktopBrowser} from 'common/constants';

export class NitroxAppState {
  public static get currentState(): AppStateStatus {
    if (isDesktopBrowser) return 'active';
    return AppState.currentState;
  }

  public static addEventListener(type: AppStateEvent, handler: (state: AppStateStatus) => void): void {
    if (isDesktopBrowser) return;
    return AppState.addEventListener(type, handler);
  }

  public static removeEventListener(type: AppStateEvent, handler: (state: AppStateStatus) => void): void {
    if (isDesktopBrowser) return;
    return AppState.removeEventListener(type, handler);
  }
}
