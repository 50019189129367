import {createStyles} from 'common-styles';
import React, {useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {View, Animated} from 'react-native';

import {DOT_SEPARATOR, dimensions} from 'common/constants';
import {getMediaTitle, getMetadataFromMedia, getMetadataItems, getLongestSynopsis, openMediaDetails} from 'common/HelperFunctions';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxButton, {NitroxButtonTheme} from 'components/NitroxButton';
import NitroxTag, {Tag, TagTypes} from 'components/NitroxTag';
import NitroxText from 'components/NitroxText';
import {useNavigation} from 'hooks/Hooks';

import {PromotionalBannerDetailsProps} from './PromotionalBannerDetails.shared';

const defaultTitleNumberOfLines = 2;
const metadataNumberOfLines = 1;
const defaultSynopsisNumberOfLines = 3;

const styles = createStyles({
  container: {
    height: '100%',
    width: '50%',
    justifyContent: 'center'
  },
  titleContainer: {
    marginBottom: dimensions.margins.large
  },
  tagContainer: {
    flexDirection: 'row',
    marginBottom: dimensions.margins.xxLarge
  },
  metadataContainer: {
    marginBottom: dimensions.margins.xLarge
  },
  synopsisContainer: {
    marginBottom: dimensions.margins.xxLarge
  },
  button: {
    alignSelf: 'flex-start'
  },
  tagStyle: {
    marginRight: dimensions.margins.large
  }
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  textColor: {
    color: colors.vodScreen.promotionalBanner.details.text
  }
}));

const PromotionalBannerDetailsGrosso: React.FunctionComponent<PromotionalBannerDetailsProps> = props => {
  const {media, style, animatedContainerStyle, titleNumberOfLines = defaultTitleNumberOfLines, synopsisNumberOfLines = defaultSynopsisNumberOfLines, margins, staticallyFocused, hasTvPreferredFocus} = props;
  const dynamicStyles = stylesUpdater.getStyles();
  const {t} = useTranslation();
  const navigation = useNavigation();

  const containerStyle = useMemo(() => [styles.container, style], [style]);
  const titleContainer = useMemo(() => [styles.titleContainer, margins?.title !== undefined ? {marginBottom: margins.title} : {}], [margins]);
  const tagsContainer = useMemo(() => [styles.tagContainer, margins?.tags !== undefined ? {marginBottom: margins.tags} : {}], [margins]);
  const metadataContainer = useMemo(() => [styles.metadataContainer, margins?.metadata !== undefined ? {marginBottom: margins.metadata} : {}], [margins]);
  const synopsisContainer = useMemo(() => [styles.synopsisContainer, margins?.synopsis !== undefined ? {marginBottom: margins.synopsis} : {}], [margins]);

  const metadata = useMemo(() => media ? getMetadataFromMedia(media) : undefined, [media]);
  const metadataItems = useMemo(() => metadata ? getMetadataItems([
    'productionYear',
    'genres',
    'duration'
  ], t, metadata, media).join(` ${DOT_SEPARATOR} `) : [], [metadata, t, media]);
  const tags = useMemo<Tag[]>(() => {
    const arr: Tag[] = [];
    if (!media) {
      return [];
    }
    const rating = media.pcRatings[0]?.value;
    rating && arr.push({
      type: TagTypes.PC_RATING,
      customText: rating
    });
    return arr;
  }, [media]);

  const onMoreInfoPress = useCallback(() => {
    if (!media) {
      return;
    }
    openMediaDetails(navigation, media.id, media.getType());
  }, [media, navigation]);

  if (!media) {
    return null;
  }

  return (
    <Animated.View style={containerStyle}>
      <View style={titleContainer}>
        <NitroxText
          textType='title'
          style={dynamicStyles.textColor}
          numberOfLines={titleNumberOfLines}
          handleEllipsize
        >
          {getMediaTitle(media)}
        </NitroxText>
      </View>
      {!!tags.length && (
        <View style={tagsContainer}>
          {tags.map(item =>
            (
              <NitroxTag
                key={item.customText || item.type}
                tag={item}
                tagSize={'big'}
                style={styles.tagStyle}
              />
            ))
          }
        </View>
      )}
      {!!metadataItems.length && (
        <View style={metadataContainer}>
          <NitroxText
            textType='subhead-medium'
            style={dynamicStyles.textColor}
            numberOfLines={metadataNumberOfLines}
            handleEllipsize
          >
            {metadataItems}
          </NitroxText>
        </View>
      )}
      <Animated.View style={animatedContainerStyle}>
        {metadata && (
          <View style={synopsisContainer}>
            <NitroxText
              textType='subhead-medium'
              style={dynamicStyles.textColor}
              numberOfLines={synopsisNumberOfLines}
              handleEllipsize
            >
              {getLongestSynopsis(metadata)}
            </NitroxText>
          </View>
        )}
        <NitroxButton
          border
          activeOpacity={1}
          onPress={onMoreInfoPress}
          text={t('common.moreInfo')}
          theme={NitroxButtonTheme.Primary}
          style={styles.button}
          staticallyFocused={staticallyFocused}
          hasTvPreferredFocus={hasTvPreferredFocus}
        />
      </Animated.View>
    </Animated.View>
  );
};

export default React.memo(PromotionalBannerDetailsGrosso);

