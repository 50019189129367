import {createStyles} from 'common-styles';
import React, {useCallback, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {View, KeyboardAvoidingView} from 'react-native';

import {dimensions, isBigScreen, isMobile, isIOS, isPhone} from 'common/constants';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {CustomSSOActionType, CustomSSOActionChoice} from 'mw/bo-proxy/SSOInterface';

import FocusParent, {useFocusParent} from 'components/FocusParent';
import LabeledTextInput from 'components/inputs/LabeledTextInput';
import LoginStepSelection, {SelectionType} from 'components/login/LoginStepSelection';
import NitroxButton from 'components/NitroxButton';
import NitroxText from 'components/NitroxText';
import SplashBackground from 'components/SplashBackground';

const TAG = 'CustomLoginStepScreen';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  message: {
    textAlign: 'center',
    color: colors.settingsScreen.mobile.list.tabletSubtitle,
    paddingTop: dimensions.margins.medium,
    paddingBottom: isBigScreen ? dimensions.margins.xxxLarge : dimensions.margins.xxLarge
  },
  buttonText: {
    color: colors.focusableTextInput.text.unfocused
  },
  buttonTextBlack: {
    color: colors.button.primary.text.focused
  },
  buttonTextActive: {
    color: colors.focusableTextInput.text.unfocused
  },
  buttonTextFocused: {
    color: colors.button.primary.text.focused
  },
  buttonFocused: {
    backgroundColor: colors.button.primary.background.focused
  }
}));

const staticStyles = createStyles({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    height: isMobile ? 50 : 75,
    marginLeft: 0,
    marginRight: 0,
    justifyContent: 'center',
    marginVertical: isBigScreen ? dimensions.margins.medium : dimensions.margins.small
  },
  innerContainer: {
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'stretch',
    ...isMobile && {paddingHorizontal: dimensions.margins.xxLarge},
    width: isPhone ? '100%' : dimensions.inputs.width.login
  },
  input: {
    marginBottom: dimensions.margins.xxxLarge,
    marginTop: dimensions.margins.large
  }
});

type UserSelection = {
  id?: string;
  selectedChoices: string[];
}

type Props = {
  onNextStep: (userSelection: UserSelection) => void;
  onCancelStep: () => void;
  message?: string;
  type?: CustomSSOActionType;
  choices?: CustomSSOActionChoice[];
}

export const CustomLoginStepScreen: React.FC<Props> = (props) => {
  const {message, type, choices, onNextStep, onCancelStep} = props;
  const {t} = useTranslation();

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const onPressItem = useCallback((alias: string) => {
    const {type} = props;
    const isSelected = selectedItems.includes(alias);
    if (type === CustomSSOActionType.MultiChoice) {
      setSelectedItems(isSelected
        ? selectedItems.filter(i => i !== alias)
        : [...selectedItems, alias]
      );
    } else if (type === CustomSSOActionType.SingleChoice) {
      setSelectedItems([alias]);
    }
  }, [props, selectedItems]);

  /* For input view */
  const onChangeText = useCallback((text: string) => {
    setSelectedItems([text]);
  }, []);

  const onNext = useCallback(() => {
    Log.debug(TAG, 'onNext');
    const userSelection = {selectedChoices: selectedItems};
    onNextStep(userSelection);
  }, [onNextStep, selectedItems]);

  const onCancel = useCallback(() => {
    Log.debug(TAG, 'onCancel');
    onCancelStep();
  }, [onCancelStep]);

  const renderStep = useCallback((type: CustomSSOActionType | undefined) => {
    switch (type) {
      case CustomSSOActionType.InputValue:
        return (
          <View style={staticStyles.input}>
            <LabeledTextInput
              testID='input_login_step'
              onChangeText={onChangeText}
              label={''}
            />
          </View>
        );
      case CustomSSOActionType.MultiChoice:
        return (
          <LoginStepSelection
            selectionType={SelectionType.CheckBox}
            onPress={onPressItem}
            items={choices}
            selectedItems={selectedItems}
          />
        );
      case CustomSSOActionType.SingleChoice:
        return (
          <LoginStepSelection
            selectionType={SelectionType.RadioBox}
            onPress={onPressItem}
            items={choices}
            selectedItems={selectedItems}
          />
        );
      default:
        return (
          <LoginStepSelection
            selectionType={SelectionType.RadioBox}
            onPress={onPressItem}
            items={choices}
            selectedItems={selectedItems}
          />
        );
    }
  }, [choices, onChangeText, onPressItem, selectedItems]);

  const [onReady, focus] = useFocusParent();
  useEffect(() => focus(), [focus]);

  const styles = stylesUpdater.getStyles();

  return (
    <>
      {isMobile && <SplashBackground />}
      <View style={staticStyles.container}>
        <KeyboardAvoidingView style={staticStyles.innerContainer} behavior='position' enabled={isIOS}>
          <FocusParent onReady={onReady}>
            {!!message && <NitroxText style={styles.message} textType={isBigScreen ? 'body' : 'subhead-medium'}>{t(message)}</NitroxText>}
            {renderStep(type)}
            <NitroxButton
              disabled={!selectedItems.length}
              border
              onPress={onNext}
              text={t('common.next')}
              style={staticStyles.button}
              textStyleFocused={styles.buttonTextFocused}
              textStyle={styles.buttonText}
              textType={isBigScreen ? 'body' : 'title2'}
            />
            <NitroxButton
              border
              onPress={onCancel}
              text={t('common.cancel')}
              style={staticStyles.button}
              textStyleFocused={styles.buttonTextFocused}
              textStyle={styles.buttonText}
              textType={isBigScreen ? 'body' : 'title2'}
            />
          </FocusParent>
        </KeyboardAvoidingView>
      </View>
    </>
  );
};

export default CustomLoginStepScreen;
