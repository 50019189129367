import {PlayerEvent} from 'mw/api/PlayerEvent';
import {PlaybackEvent} from 'mw/playback/types/PlaybackEvent';
import {PlayerType} from 'mw/playback/types/PlayerType';

export const normalizeString = (value = ''): string => {
  return value.toLowerCase();
};

export abstract class WebPlaybackEvent implements PlaybackEvent {

  public playerType: PlayerType;
  public eventType: PlayerEvent;
  public streamId: number;

  public constructor(eventType: PlayerEvent, playerType: PlayerType, streamId: number) {
    this.eventType = eventType;
    this.playerType = playerType;
    this.streamId = streamId;
  }
}
