import React, {useRef, useMemo, useCallback} from 'react';
import {PanResponder, Animated, PanResponderInstance, PanResponderGestureState, Insets} from 'react-native';

import {Direction, Axis} from 'common/constants';
import {Size} from 'common/HelperTypes';

import {useLazyRef, useFunction, useScreenInfo, useChangeEffect, getScreenInfo} from 'hooks/Hooks';

import {isSwipe, inInsets, getDistanceFromEdges, swipeVelocityThreshold, swipeDistanceThreshold} from './GestureGeometry';

const defaultInsets = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 0
};

const defaultGestureDistanceThreshold = 50;
const defaultGestureVelocityThreshold = 0.2;

export type SwipeTrackChildrenProps = {
  panResponder: PanResponderInstance;
  positionDelta: Animated.Value;
}
export type SwipeTrackProps = {
  /**
   * direction which swipe originates of,
   * Example: `swipeOrigin = Direction.Left` means swipe is allowed in horizontal direction
   * and gestures are reported in left to right sense
   */
  swipeOrigin: Direction;

  /**
   * original displacement from swipe origin,
   * Example: `swipeOrigin = Direction.Left` and `initialOriginDisplacement = 10` means original `left` position will be equal to `10`
   */
  initialOriginDisplacement?: number;

  /**
   * listener for tab/press event on element which panresponder is bound to
   */
  onSwipeAnchorTap?: () => void;

  /**
   * whether close animation (and thus close callback) should be fired on tap event,
   * there's no other option for running close animation from outside than this
   */
  closeOnAnchorTap?: boolean;

  /**
   * callback fired on gesture start
   */
  onGestureStart?: () => void;

  /**
   * callback fired on gesture end, before animation is started
   */
  onGestureEnd?: (isCloseGesture: boolean) => void;

  /**
   * callback fired on close animation done
   */
  onCloseGesture?: () => void;

  /**
   * is distance (in given via `swipeOrigin` sense) threshold to fire close animation,
   * it's separate close trigger to velocity, most commonly half of container height is correct value
   */
  closeGestureThreshold?: number;

  /**
   * distance for close animation, by default it's screen size in same direction as `swipeOrigin`
   */
  flyAwayDistance?: number;

  /**
   * whether component can be moved beyond `initialOriginDisplacement`,
   * in other words, whether positionDelta can be negative
   */
  enableReverseDirection?: boolean;

  /**
   * minimum distance to grant panresponder (and thus override other gestures like press events)
   */
  gestureDistanceThreshold?: number;

  /**
   * minimum velocity to grant panresponder (and thus override other gestures like press events)
   */
  gestureVelocityThreshold?: number;

  /**
   * is a function which is given parameters such as `panResponder` and `positionDelta` which can be bound to other elements (see ModalSelect),
   * this approach lets us to make one element, e.g. some header, be drag anchor, and other to be displaced on gestures - e.g. whole container
   */
  children: (props: SwipeTrackChildrenProps) => React.ReactElement | null;

  /**
   * whether component should become responder on start of a touch,
   * true by default
   */
  becomeResponderOnTouchStart?: boolean;
  /**
   * Insets to limit gesture recognition to given area.
   * Note: To make `insets.right` and `insets.bottom` work correctly on non full screen components, make sure to provide `recognitionAreaSize` prop.
   */
  gestureRecognitionInsets?: Insets;
  /**
   * Provide `width` if recognition area width is not equal to screen width and `gestureRecognitionInsets.right` is used.
   * Provide `height` if recognition area height is not equal to screen height and `gestureRecognitionInsets.bottom` is used.
   * By default, full screen recognizer is assumed.
   */
  recognitionAreaSize?: Partial<Size>;
}

const animationParams = {
  bounceBack: {speed: 6, bounciness: 12, overshootClamping: true},
  flyAway: {speed: 18, bounciness: 0, overshootClamping: true}
};

const SwipeTrack: React.FC<SwipeTrackProps> = ({
  children: renderChildren,
  swipeOrigin,
  initialOriginDisplacement = 0,
  onSwipeAnchorTap,
  closeOnAnchorTap = true,
  onGestureStart: propsOnGestureStart,
  onGestureEnd: propsOnGestureEnd,
  onCloseGesture,
  flyAwayDistance: propsFlyAwayDistance,
  closeGestureThreshold = swipeDistanceThreshold,
  enableReverseDirection = false,
  gestureDistanceThreshold = defaultGestureDistanceThreshold,
  gestureVelocityThreshold = defaultGestureVelocityThreshold,
  becomeResponderOnTouchStart = true,
  gestureRecognitionInsets = defaultInsets,
  recognitionAreaSize: propsRecognitionAreaSize
}) => {
  const positionDelta = useRef(new Animated.Value(initialOriginDisplacement));

  useChangeEffect(() => {
    positionDelta.current.setValue(initialOriginDisplacement);
    positionDelta.current.setOffset(0);
  }, [initialOriginDisplacement]);

  const screenInfo = useScreenInfo();
  const isVertical = useFunction(() => [Direction.Up, Direction.Down].includes(swipeOrigin));

  const flyAwayDistance = useMemo(() => {
    if (typeof propsFlyAwayDistance === 'number') {
      return propsFlyAwayDistance;
    }

    return isVertical() ? screenInfo.size.height : screenInfo.size.width;
  }, [screenInfo, propsFlyAwayDistance, isVertical]);

  const displacementSign = useFunction(() => [Direction.Up, Direction.Left].includes(swipeOrigin) ? 1 : -1);

  const animatePositionDeltaTo = useCallback(
    (toValue: number, config: Partial<Animated.SpringAnimationConfig> = {}, onDone?: () => void) =>
      Animated
        .spring(positionDelta.current, {toValue, useNativeDriver: false, ...config})
        .start(onDone),
    []
  );
  const animateToInitialPosition = useFunction(
    () => animatePositionDeltaTo(initialOriginDisplacement, animationParams.bounceBack)
  );
  const animateOffScreen = useFunction(
    () => animatePositionDeltaTo(displacementSign() * flyAwayDistance + initialOriginDisplacement, animationParams.flyAway, onCloseGesture)
  );

  const onAnchorTap = useFunction(() => {
    if (closeOnAnchorTap) {
      animateOffScreen();
    }
    if (onSwipeAnchorTap) {
      onSwipeAnchorTap();
    }
  });

  const gestureVelocity = useFunction((gestureState: PanResponderGestureState) => {
    const vertical = isVertical();
    const velocity = Math.abs(vertical ? gestureState.vy : gestureState.vx);
    const oppositeAxisVelocity = Math.abs(vertical ? gestureState.vx : gestureState.vy);
    return velocity > oppositeAxisVelocity ? velocity : 0;
  });

  /**
   * @param absolute ignore sense lock in distance calculations
   */
  const gestureDisplacement = useFunction((gestureState: PanResponderGestureState, absolute = false) => {
    const vertical = isVertical();
    const distance = vertical ? gestureState.dy : gestureState.dx;
    const oppositeAxisDistance = vertical ? gestureState.dx : gestureState.dy;

    if (Math.abs(distance) < Math.abs(oppositeAxisDistance)) {
      return 0;
    }

    const sign = displacementSign();

    if (!absolute && !enableReverseDirection && Math.sign(distance) !== sign) {
      return 0;
    }
    return distance;
  });

  const isCloseGesture = useFunction((gestureState: PanResponderGestureState) => {
    if (gestureVelocity(gestureState) > swipeVelocityThreshold) {
      return true;
    }
    return Math.abs(gestureDisplacement(gestureState)) >= closeGestureThreshold;
  });

  const gestureThreshold = useFunction(() => ({gestureDistanceThreshold, gestureVelocityThreshold}));
  const gestureInsets = useFunction(() => gestureRecognitionInsets);
  const recognitionAreaSize = useFunction(() => {
    const {width: screenWidth, height: screenHeight} = getScreenInfo().size;
    return {
      width: propsRecognitionAreaSize?.width ?? screenWidth,
      height: propsRecognitionAreaSize?.height ?? screenHeight
    };
  });

  const onGestureStart = useFunction(() => propsOnGestureStart?.());
  const onGestureEnd = useFunction((isCloseGesture: boolean) => propsOnGestureEnd?.(isCloseGesture));

  const panResponder = useLazyRef(() => PanResponder.create({
    onStartShouldSetPanResponder: () => becomeResponderOnTouchStart,
    onStartShouldSetPanResponderCapture: () => false,
    onMoveShouldSetPanResponder: (event, gestureState) => {
      const {gestureVelocityThreshold: velocityThreshold, gestureDistanceThreshold: distanceThreshold} = gestureThreshold();
      const gestureIsSwipe = isSwipe(gestureState, isVertical() ? Axis.Y : Axis.X, {velocityThreshold, distanceThreshold});
      const gestureInInsets = inInsets(
        getDistanceFromEdges(gestureState, recognitionAreaSize()),
        gestureInsets()
      );
      return gestureIsSwipe && gestureInInsets;
    },
    onMoveShouldSetPanResponderCapture: () => false,
    onPanResponderTerminationRequest: () => true,
    onPanResponderTerminate: () => {},
    onPanResponderGrant: () => {
      positionDelta.current.extractOffset();
    },
    onPanResponderStart: () => {
      onGestureStart();
    },
    onPanResponderMove: (event, gestureState) => {
      positionDelta.current.setValue(gestureDisplacement(gestureState));
    },
    onPanResponderRelease: (event, gestureState) => {
      positionDelta.current.flattenOffset();
      if (Math.abs(gestureDisplacement(gestureState, true)) < 1) {
        onGestureEnd(false);
        onAnchorTap();
        return;
      }

      if (isCloseGesture(gestureState)) {
        onGestureEnd(true);
        animateOffScreen();
      } else {
        onGestureEnd(false);
        animateToInitialPosition();
      }
    }
  })).current;
  /// Example for preventing scroll in some direction, say the opposite sense of given direction
  // .interpolate({
  //   inputRange: [0, 1],
  //   outputRange: [0, 1],
  //   extrapolateLeft: 'clamp',
  // })
  // consider above as an optional variant for `enableReverseDirection`
  return (
    renderChildren({positionDelta: positionDelta.current, panResponder})
  );
};

export default SwipeTrack;
