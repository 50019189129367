import {runTasks} from 'common/Async';
import {EventEmitter} from 'common/EventEmitter';
import {ChangeEvent} from 'common/HelperTypes';
import {Log} from 'common/Log';
import {getPlatformFeatures} from 'common/utils';

import {Error, ErrorType} from 'mw/api/Error';
import {Consent, PaymentMethod, PaymentMethodId} from 'mw/api/Metadata';
import {Profile, ProfileConfigurableProperties, ProfileEvent, ProfilePropertiesChangePayload, isPCPropertyChanged} from 'mw/api/Profile';
import {BOEvent} from 'mw/bo-proxy/BOInterface';
import {RegistrationData} from 'mw/bo-proxy/BOInterface';
import {boProxy} from 'mw/bo-proxy/BOProxy';
import {ConsentSlug} from 'mw/cms/ConsentInfo';
import {createLocalSettings} from 'mw/common/utils';
import {mw} from 'mw/MW';
import {asyncStorage} from 'mw/platform/async-storage/AsyncStorage';

import {Device} from './Device';
import {DeviceManager} from './DeviceManager';
import {nxffConfig} from './NXFF';

const TAG = 'Customer';
const UI_LANGUAGE_ASYNC_STORAGE_KEY = `Customer-uiLanguage`;

const lastUsedProfileKey = 'last_used_profile_id';

export enum CustomerEvent {
  CustomerDataLoaded = 'CustomerDataLoaded',
  DeviceRegistered = 'DeviceRegistered',
  DeviceUnregistered = 'DeviceUnregistered',
  DateFormatChanged = 'DateFormatChanged',
  EULAConsentAccepted = 'EULAConsentAccepted',
  EULAConsentNotAccepted = 'EULAConsentNotAccepted',
  EULAConsentNetworkRequestFailed = 'EULAConsentNetworkRequestFailed',
  FailedToRegisterNewDevice = 'FailedToRegisterNewDevice',
  ProfileChange = 'ProfileChange',
  ProfileChoiceRequired = 'ProfileChoiceRequired',
  ProfileListUpdate = 'ProfileListUpdate',
  ProfilePinRequired = 'ProfilePinRequired',
  Registered = 'Registered',
  RegistrationError = 'RegistrationError',
  TooManyDevices = 'TooManyDevices',
  TooManyDeviceSwaps = 'TooManyDeviceSwaps',
  TimeFormatChanged = 'TimeFormatChanged',
  UILanguageChanged = 'UILanguageChanged',
  PCEnabledChanged = 'PCEnabledChanged'
}

type EventType = CustomerEvent;

export type CustomerEventPayload =
  | ChangeEvent<Profile | null>
  | ChangeEvent<string>
  | ChangeEvent<boolean>
  | Device
  | Profile
  | Profile[]
  | Consent
  | null;

export class Customer extends EventEmitter<EventType, CustomerEventPayload> {
  public id: string | null = null;
  public currentDevice: Device | null = null;
  public currentProfile: Profile | null = null;
  public defaultProfileId?: string;
  public externalId: string | null = null;
  public profiles: Profile[] = [];
  public mainProfile: Profile | null = null;
  public regionId: string | null = null;
  public stylingName?: string;
  public acceptedEULAVersion?: string;
  public paymentMethods: PaymentMethod[] = [];

  private loadLastUsedProfileOnInit = true;

  private readonly localSettings = createLocalSettings(() =>
    `BO_${nxffConfig.getBackOfficeCode()}_CUSTOMER_${this.id || this.externalId}_`
  );

  public setRegionId(regionId: string): void {
    this.regionId = regionId;
  }

  private onDeviceUnregistered = () => {
    this.currentDevice = null;
    this.notify(CustomerEvent.DeviceUnregistered);
  };

  public setLoadLastUsedProfileOnInit(loadLastUsedProfileOnInit: boolean): void {
    this.loadLastUsedProfileOnInit = loadLastUsedProfileOnInit;
  }

  public async initialize(): Promise<void> {
    const customer = await boProxy.bo.getCustomer();
    this.id = customer.id || null;
    this.defaultProfileId = customer.defaultProfileId;
    this.externalId = customer.externalId || null;
    this.regionId = customer.regionId || null;
    this.stylingName = customer.stylingName;
    this.acceptedEULAVersion = customer.acceptedEULAVersion;
    if (customer.profiles?.length) {
      this.profiles = customer.profiles;
      this.notify(CustomerEvent.ProfileListUpdate, this.profiles);
    }
    if (customer.mainProfile) {
      this.mainProfile = customer.mainProfile;
    }
    customer.pvrQuota && mw.pvr.setPVRQuota(customer.pvrQuota);

    this.currentDevice = await this.getCurrentDevice();
    this.paymentMethods = await this.getPaymentMethods();

    boProxy.bo.on(BOEvent.DeviceUnregistered, this.onDeviceUnregistered);
    this.notify(CustomerEvent.CustomerDataLoaded);
  }

  public initProfiles(): Promise<void> {
    return this.getInitialProfile()
      .then(profile => {
        if (!profile) {
          this.notify(CustomerEvent.ProfileChoiceRequired);
          return Promise.resolve();
        }

        if (profile.isPinRequired) {
          this.notify(CustomerEvent.ProfilePinRequired, profile);
          return Promise.resolve();
        }

        return this.setProfile(profile, null);
      });
  }

  private getInitialProfile(): Promise<Profile | null> {
    return (this.loadLastUsedProfileOnInit ? this.localSettings.get(lastUsedProfileKey) : Promise.resolve(null))
      .then(lastUsedProfileId => {
        let profile = lastUsedProfileId && this.profiles.find(p => p.id === lastUsedProfileId) || null;
        if (!profile && this.profiles.length === 1) {
          profile = this.profiles[0];
        }
        return profile;
      });
  }

  public uninitialize(): void {
    boProxy.bo.off(BOEvent.DeviceUnregistered, this.onDeviceUnregistered);

    this.clear();
    this.profiles = [];
    this.mainProfile = null;
    this.currentProfile = null;
    this.currentDevice = null;
    this.regionId = null;
  }

  public sendLocation(): Promise<void> {
    return boProxy.bo.sendLocation();
  }

  public refreshProfiles(): Promise<void> {
    return boProxy.bo.getProfiles()
      .then(profiles => {
        this.profiles = profiles;
        this.mainProfile = profiles.find(profile => profile.isMain) || null;
        if (this.currentProfile) {
          const oldCurrentProfile = this.currentProfile;
          const id = this.currentProfile.id;
          this.currentProfile = this.profiles.find(p => p.id === id) || null;
          if (this.currentProfile && isPCPropertyChanged(this.currentProfile, oldCurrentProfile)) {
            this.currentProfile?.refresh();
          }
        }
        this.notify(CustomerEvent.ProfileListUpdate, this.profiles);
      });
  }

  private uiLanguageChange(previous: string, current: string): Promise<void> {
    return this.saveUILanguage(current)
      .then(() => mw.catalog.onUILanguageChange())
      .then(() => this.notify(CustomerEvent.UILanguageChanged, {from: previous, to: current}));
  }

  private saveUILanguage(uiLanguage: string): Promise<void> {
    Log.debug(TAG, 'Saving locally ' + uiLanguage + ' as UI language');
    return asyncStorage.setSecureItem(UI_LANGUAGE_ASYNC_STORAGE_KEY, uiLanguage);
  }

  public getSavedUILanguage(): Promise<string | null> {
    // we need to use asyncStorage directly here instead of localStorage because we need to be able to get the saved UI language just after the BackOfficeEvent.nxffProfileSet event
    return asyncStorage.getSecureItem(UI_LANGUAGE_ASYNC_STORAGE_KEY);
  }

  private onProfilePropertiesChange = (change?: ProfilePropertiesChangePayload): Promise<void> => {
    if (!change?.to) {
      return Promise.resolve();
    }

    switch (change.key) {
      case 'uiLanguage': {
        return this.uiLanguageChange(change.from.uiLanguage, change.to.uiLanguage);
      }
      case 'dateFormat': {
        const {from, to} = change;
        this.notify(CustomerEvent.DateFormatChanged, {from: from.dateFormat, to: to.dateFormat});
        return Promise.resolve();
      }
      case 'timeFormat': {
        const {from, to} = change;
        this.notify(CustomerEvent.TimeFormatChanged, {from: from.timeFormat, to: to.timeFormat});
        return Promise.resolve();
      }
      case 'isPCEnabled': {
        const {from, to} = change;
        this.notify(CustomerEvent.PCEnabledChanged, {from: from.isPCEnabled, to: to.isPCEnabled});
        return Promise.resolve();
      }
      default:
        return Promise.resolve();
    }
  };

  private onProfileChange = ({from: previousProfile, to: currentProfile}: ChangeEvent<Profile | null>): Promise<void> => {
    const stopMainPlayer = (): Promise<void> => {
      Log.debug(TAG, 'Stopping main player');
      return mw.players.main.stop();
    };
    const updateUILanguage = (): Promise<void> => {
      if (!currentProfile) {
        Log.debug(TAG, 'There is no need to update the UI language because no new profile was selected');
        return Promise.resolve();
      }
      const previousUILanguage = previousProfile ? previousProfile.uiLanguage : '';
      const currentUILanguage = currentProfile.uiLanguage;
      if (previousUILanguage === currentUILanguage) {
        Log.debug(TAG, `There is no need to update the UI language ${currentUILanguage}`);
        return Promise.resolve();
      }
      Log.debug(TAG, `Updating UI language from ${previousUILanguage} to ${currentUILanguage}`);
      return this.uiLanguageChange(previousUILanguage, currentUILanguage);
    };
    const refreshProfile = (): Promise<void> => {
      if (!currentProfile) {
        return Promise.resolve();
      }
      Log.debug(TAG, 'Refreshing profile:', currentProfile);
      return currentProfile.refresh();
    };
    return runTasks([stopMainPlayer, updateUILanguage, refreshProfile]);
  };

  public async setProfile(profile: Profile | null, pin: string | null): Promise<void> {
    if (this.currentProfile === profile) {
      Log.info(TAG, 'New profile is the same as current, not changing');
      this.notify(CustomerEvent.ProfileChange, {to: profile, from: profile});
      return;
    }
    let previousProfile: Profile | null;
    [this.currentProfile, previousProfile] = [profile, this.currentProfile];
    if (profile) {
      await boProxy.sso.switchProfile(profile, pin || '');
      profile.on(ProfileEvent.PropertiesChange, this.onProfilePropertiesChange);
    }
    if (previousProfile) {
      previousProfile.off(ProfileEvent.PropertiesChange, this.onProfilePropertiesChange);
    }
    this.currentProfile && await this.localSettings.set(lastUsedProfileKey, this.currentProfile.id);
    await this.onProfileChange({from: previousProfile, to: profile});
    Log.info(TAG, 'Successfully changed profile to:', profile);
    this.notify(CustomerEvent.ProfileChange, {to: profile, from: previousProfile});
  }

  public getProfile(): Profile {
    if (!this.currentProfile) {
      Log.error(TAG, 'getProfile: no current profile');
      throw new Error(ErrorType.UnknownError);
    }

    return this.currentProfile;
  }

  public async addProfile(config: Partial<ProfileConfigurableProperties>, masterPin: string): Promise<{error?: Error}> {
    const {profileCreated, error} = await boProxy.bo.createProfile(config, masterPin);
    if (error) {
      Log.error(TAG, 'Some errors occurred when creating new profile:', error);
    }
    if (profileCreated) {
      Log.info(TAG, `Successfully created new profile!`);
      await this.refreshProfiles();
      return {error};
    } else {
      throw error;
    }
  }

  public async deleteProfile(profile: Profile, masterPin: string): Promise<void> {
    if (profile.isMain) {
      throw new Error(ErrorType.ProfileCannotRemoveMaster);
    }
    await boProxy.bo.deleteProfile(profile, masterPin);
    this.profiles = this.profiles.filter(p => p.id !== profile.id);
    this.notify(CustomerEvent.ProfileListUpdate, this.profiles);
    if (this.currentProfile && this.currentProfile.id === profile.id) {
      await this.setProfile(null, null);
    }
    Log.info(TAG, 'Successfully deleted profile:', profile);
  }

  public clearLastUsedProfile(): Promise<void> {
    return this.localSettings.remove(lastUsedProfileKey);
  }

  public registerDevice(): Promise<void> {
    if (this.currentDevice) {
      Log.debug(TAG, 'There is no need to register a device');
      this.notify(CustomerEvent.DeviceRegistered);
      return Promise.resolve();
    }
    Log.debug(TAG, 'Registering a device');
    const device = new Device(DeviceManager.getInstance().getId(), DeviceManager.getInstance().getDeviceName());
    return boProxy.bo.registerDevice(device)
      .then(() => {
        this.currentDevice = device;
        this.notify(CustomerEvent.DeviceRegistered);
      })
      .catch((error: Error) => {
        Log.error(TAG, 'Register device failed:', error);
        switch (error.type) {
          case ErrorType.TooManyDevices:
            this.notify(CustomerEvent.TooManyDevices);
            break;
          case ErrorType.TooManyDeviceSwaps:
            this.notify(CustomerEvent.TooManyDeviceSwaps);
            break;
          default:
            this.notify(CustomerEvent.FailedToRegisterNewDevice);
            break;
        }
        throw error;
      });
  }

  public unregisterDevices(devices: Device[], masterPin: string): Promise<void> {
    return boProxy.bo.unregisterDevices(devices, masterPin);
  }

  public getRegisteredDevices(): Promise<Device[]> {
    return boProxy.bo.getDevices();
  }

  private getCurrentDevice(): Promise<Device | null> {
    const deviceId = DeviceManager.getInstance().getId();
    return this.getRegisteredDevices()
      .then(devices => {
        return devices.find((device: Device) => device.id === deviceId) || null;
      })
      .catch((error: Error) => {
        Log.error(TAG, 'Getting device id failed:', error);
        return null;
      });
  }

  public checkPurchasePin(pin: string): Promise<void> {
    return boProxy.bo.verifyPurchasePin(pin)
      .then(() => Log.debug(TAG, 'Purchase pin verification successful'))
      .catch(error => {
        Log.error(TAG, 'Error verifying purchase pin:', error);
        throw error;
      });
  }

  public setPurchasePin(pin: string): Promise<void> {
    return boProxy.bo.setPurchasePin(pin)
      .then(() => Log.debug(TAG, 'Successfully set purchase pin'))
      .catch(error => {
        Log.error(TAG, 'Error setting purchase pin:', error);
        throw error;
      });
  }

  public setConsent(slug: string, version: string, accepted: boolean): Promise<void> {
    return boProxy.bo.setConsent(slug, version, accepted)
      .then(() => {
        if (slug === ConsentSlug.NitroxEula) {
          this.acceptedEULAVersion = accepted ? version : '';
          if (accepted) {
            this.notify(CustomerEvent.EULAConsentAccepted);
          }
        }
        Log.debug(TAG, `Successfully updated consent: ${slug}, version: ${version}, accepted: ${accepted}`);
      })
      .catch(error => {
        Log.error(TAG, `Could not update consent: ${slug}, version: ${version}, accepted: ${accepted}, error:`, error);
        throw error;
      });
  }

  public checkEULAConsent(): Promise<void> {
    return mw.cms.getConsentData(ConsentSlug.NitroxEula)
      .then((eulaConsent) => {
        Log.debug(TAG, `Successfully loaded, ${ConsentSlug.NitroxEula} consent, version: ${eulaConsent.version || 'unknown'}`);
        if (this.acceptedEULAVersion === eulaConsent.version) {
          Log.debug(TAG, `${ConsentSlug.NitroxEula} consent, version: ${eulaConsent.version} is accepted`);
          this.notify(CustomerEvent.EULAConsentAccepted);
        } else {
          Log.debug(TAG, `${ConsentSlug.NitroxEula} consent, version: ${eulaConsent.version} is not accepted`);
          this.notify(CustomerEvent.EULAConsentNotAccepted, eulaConsent);
        }
      })
      .catch((error: Error) => {
        switch (error?.type) {
          case ErrorType.HttpNotFound:
          case ErrorType.NotConfigured: //UXM not configured
          case ErrorType.NotSupported: //MM does not support Consents
            Log.warn(TAG, `${ConsentSlug.NitroxEula} consent not configured/supported. Skipping the check. Error:`, error);
            this.notify(CustomerEvent.EULAConsentAccepted); //proceed as if it was accepted
            break;
          default:
            Log.error(TAG, `${ConsentSlug.NitroxEula} consent network error. Error:`, error);
            this.notify(CustomerEvent.EULAConsentNetworkRequestFailed);
            throw error;
        }
      });
  }

  public register(registrationData: RegistrationData): Promise<void> {
    return boProxy.bo.registerAccount(registrationData)
      .then(() => {
        Log.debug(TAG, 'Customer registered');
        this.notify(CustomerEvent.Registered);
      })
      .catch(error => {
        Log.error(TAG, 'Registration error', error);
        this.notify(CustomerEvent.RegistrationError);
        throw error;
      });
  }

  public validate(registrationData: Partial<RegistrationData>): Promise<void> {
    return boProxy.bo.validateAccount(registrationData);
  }

  public static activateAccount(accountId: number, token: string): Promise<void> {
    return boProxy.bo.activateAccount(accountId, token);
  }

  public sendAccountActivationNotification(emailOrUsername: string): Promise<void> {
    return boProxy.bo.sendAccountActivationNotification(emailOrUsername);
  }

  private getPaymentMethods(): Promise<PaymentMethod[]> {
    const appPaymentMethods: PaymentMethodId[] = getPlatformFeatures()?.PaymentMethods ?? [];
    if (appPaymentMethods.length === 0) {
      return Promise.resolve([]);
    }
    return boProxy.bo.getPaymentMethods()
      .then((boPaymentMethods: PaymentMethod[]) => {
        return boPaymentMethods.filter(boPaymentMethod => appPaymentMethods.includes(boPaymentMethod.id));
      })
      .catch(error => {
        switch (error?.type) {
          case ErrorType.NotSupported:
            Log.warn(TAG, 'getPaymentMethods not supported', error);
            break;

          default:
            Log.error(TAG, `getPaymentMethods error. Error:`, error);
            break;
        }
        return [];
      });
  }
}
