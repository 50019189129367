import {createStyles} from 'common-styles';
import React from 'react';
import {View, ViewStyle, StyleSheet, Platform} from 'react-native';
import KeepAwake from 'react-native-keep-awake';

import Video from 'mw/playback/Video';

export enum PlayerViews {
  Fullscreen = 1,
  Floater,
  Home,
  Zapper,
  Epg,
  ChannelDetails,
  None // On web based platforms it is required to add fake player view
}

const styles = createStyles({
  container: {
    overflow: 'hidden'
  }
});

interface PlayerViewProps {
  debugName?: string;
  type: PlayerViews;
  onReady?: () => void;
  style?: ViewStyle;
}

export const PlayerView: React.FunctionComponent<PlayerViewProps> = props => {
  return (
    <View
      style={[styles.container, props.style]}
      testID='player_view'
    >
      <Video debugName={props.debugName} viewId={props.type} style={StyleSheet.absoluteFillObject} onReady={props.onReady} />
      <View style={StyleSheet.absoluteFillObject}>
        {props.children}
      </View>
      {Platform.OS === 'android' && <KeepAwake />}
    </View>
  );
};
