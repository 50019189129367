import {createStyles} from 'common-styles';
import moment from 'moment';
import React, {useRef, useCallback, useState, useMemo, useImperativeHandle, useEffect} from 'react';
import {Animated} from 'react-native';

import {epgLimits} from 'common/constants';
import {isSmartTV, isPhone} from 'common/constants';
import {Size, Point} from 'common/HelperTypes';

import {Channel, Event} from 'mw/api/Metadata';
import {mw, CatalogEvent} from 'mw/MW';

import {EpgGrid, EpgGridProps, EpgGridInterface} from 'components/epg/EpgGrid';
import {ScrollDirection} from 'components/epg/NitroxScrollView';
import {minEpgTileContentWidth} from 'components/epg/RoundBorder';
import FocusParent from 'components/FocusParent';
import {useDebounce, useEventListener} from 'hooks/Hooks';

const debouncedStateChangesDelay = 800;
const epgVisibleRangeInHours = 2.75;
const minEpgGridHourWidth = Math.floor(minEpgTileContentWidth / (epgLimits.minVisibleEventDuration / 3600));

export const enableFocusDriver = isSmartTV;

const styles = createStyles({
  epgGrid: {
    flex: 1
  }
});

type Props = {
  channels: Channel[];
  currentChannel: Channel;
  onEventFocus: EpgGridProps['onEventFocus'];
  onEventPress: EpgGridProps['onEventPress'];
  onChannelPress?: (channelId: string) => void;
  onEpgGridVisibleTime: (date: Date) => void;
  focusedEvent?: Event;
}

const Epg: React.FC<Props> = (props, ref) => {
  const {channels, currentChannel, onEventFocus, onEventPress, onChannelPress, onEpgGridVisibleTime, focusedEvent} = props;
  const epgGridRef = useRef<EpgGridInterface>(null);

  const timebarOriginTime = useRef(moment());
  const epgGridOriginTime = useMemo(() => (
    timebarOriginTime.current.startOf('hour')
  ), [timebarOriginTime]);

  const [epgGridSize, setEpgGridSize] = useState<Size>();
  const [epgGridContentStartOffset, setEpgGridContentStartOffset] = useState<Point>({x: -1, y: -1});
  const [epgGridHourWidth, setEpgGridHourWidth] = useState(minEpgGridHourWidth);

  useEffect(() => {
    if (epgGridSize && epgGridSize.width > 0) {
      // Adjust the hour width to be able to show at least the minimum event duration that should have graphical representation.
      const stretchedSize = Math.floor(epgGridSize.width / epgVisibleRangeInHours);
      setEpgGridHourWidth(Math.max(minEpgGridHourWidth, stretchedSize));
    }
  }, [epgGridSize]);

  const scrollPositionRef = useRef(new Animated.ValueXY());
  const epgGridPreviousPosition = useRef<number>(0);

  const setEpgGridVisibleTimeDebounced = useDebounce(time => {
    onEpgGridVisibleTime(time);
  }, debouncedStateChangesDelay);

  const onScrollHorizontal = useCallback((position: number) => {
    if (position === epgGridPreviousPosition.current || epgGridHourWidth === 0) {
      return;
    }
    const minutes = ((position - epgGridContentStartOffset.x) / epgGridHourWidth) * 60;
    const startTime = moment(epgGridOriginTime).add(minutes, 'minutes');
    if (position === epgGridContentStartOffset.x) {
      // set the inital value without debouncing
      onEpgGridVisibleTime(startTime.toDate());
    } else if (epgGridPreviousPosition.current > position) {
      // use start time when user navigates to the beginning of the epg
      setEpgGridVisibleTimeDebounced(startTime.toDate());
    } else {
      // use end time when user navigates to the end of the epg
      const endTime = moment(startTime).add(epgVisibleRangeInHours * 60, 'minutes');
      setEpgGridVisibleTimeDebounced(endTime.toDate());
    }
    epgGridPreviousPosition.current = position;
  }, [epgGridContentStartOffset.x, epgGridOriginTime, epgGridHourWidth, onEpgGridVisibleTime, setEpgGridVisibleTimeDebounced]);

  const scrollToDate = useCallback((date: Date) => {
    epgGridRef.current?.scrollToDate(date);
  }, [epgGridRef]);

  const scrollToNow = useCallback(() => {
    epgGridRef.current?.scrollToNow();
  }, [epgGridRef]);

  const scrollByPage = useCallback((axis: ScrollDirection, offset: number) => {
    epgGridRef.current?.scrollByPage(axis, offset);
  }, [epgGridRef]);

  const scrollToEvent = useCallback((event: Event) => {
    epgGridRef.current?.scrollToEvent(event);
  }, [epgGridRef]);

  const onIsRecordedUpdated = useCallback((events: Event[]) => {
    events.forEach(event => epgGridRef.current?.updateEventView(event));
  }, [epgGridRef]);

  useEventListener(CatalogEvent.EventsIsRecordedUpdated, onIsRecordedUpdated, mw.catalog);

  useImperativeHandle(ref, () => ({
    scrollToDate,
    scrollToNow,
    scrollByPage,
    scrollToEvent
  }), [scrollByPage, scrollToDate, scrollToNow, scrollToEvent]);

  return (
    <FocusParent trapFocus rememberLastFocused style={styles.epgGrid}>
      <EpgGrid
        ref={epgGridRef}
        style={styles.epgGrid}
        channels={channels}
        currentChannel={currentChannel}
        hourWidth={epgGridHourWidth}
        originTime={epgGridOriginTime}
        onScrollHorizontal={onScrollHorizontal}
        scrollPositionRef={scrollPositionRef}
        onEventFocus={onEventFocus}
        onEventPress={onEventPress}
        onChannelPress={onChannelPress}
        onSetContentStartOffset={setEpgGridContentStartOffset}
        onLayout={setEpgGridSize}
        useFocusDriver={enableFocusDriver}
        showIcons={!isPhone}
        focusedEvent={focusedEvent}
      />
    </FocusParent>
  );
};

export default React.memo(React.forwardRef(Epg));
