export enum ErrorType {
  LoginError,
  SSONoCredentials,
  BOInvalidAuthentication,
  BOInvalidCustomerID,
  BONotSupportedProperty,
  BOPvrQuotaExceeded,
  BOUnavailableError,
  CMSStylingNotFound,
  DefaultUILanguageNotFound,
  UILanguagesNotFound,
  TranslationsNotFound,
  SSOAccessForbidden,
  SSOBadRequest,
  SSOInvalidResponse,
  SSOInvalidToken,
  SSONotLoggedIn,
  SSORefreshTokenExpired,
  SSOServerError,
  SSOTimeout,
  SSONoDeviceSlotsRemaining,
  SSOUnauthorized,
  SSOUnavailableError,
  SSOUnknownError,
  SSOCanceledByUser,
  SSOCustomerNotSubscribed,
  UnknownBackOffice,
  InvalidParameter,
  NotConfigured,
  NotImplemented,
  NotSupported,
  ImproperConfiguration,
  UnknownError,
  ParsingError,
  IncorrectPin,

  JWTInvalidKey,
  JWTInvalidToken,

  CustomerUpdateFailure,

  ProfileNameEmpty,
  ProfileNameTooLong,
  ProfileNameAlreadyExists,
  ProfileCreationFailure,
  ProfileUpdateFailure,
  ProfileCannotRemoveMaster,
  ProfileDeletionFailure,
  ProfileBirthDateTooOld,
  ProfileBirthDateTooLate,
  ProfileYearOfBirthMandatory,
  ProfileYearOfBirthNotANumber,
  ProfileNotFound,
  ProfilePINTooShort,

  AccountActivationTokenInvalid,
  AccountAlreadyConfirmed,
  AccountNotActivated,
  AccountDoesntExist,
  ConfirmationCodeExpired,
  ConfirmationCodeInvalid,
  ValidationError,

  DeviceInfoInitializationError,
  TooManyDevices,
  TooManyDeviceSwaps,
  FailedToRegisterNewDevice,
  FailedToUnregisterDevice,
  DeviceUnregistered,

  BOBadResponse,
  BOServerError,

  TransactionNotFinalized,
  TransactionFailed,
  TransactionLocationForbidden,
  TransactionVPNForbidden,

  OrderNotFound,
  OrderInvalidState,

  RemoteNxfdNotFound,
  RemoteNxfdValidationFailed,
  RemoteNxfdUnknownError,

  HttpBadRequest = 400,
  HttpForbidden = 403,
  HttpNotFound = 404,
  HttpTimeout = 408,
  HttpUnprocessableEntity = 422,
  HttpUnavailableForLegalReasons = 451,

  PlayerNotSupportedSource = 600,
  PlayerNotStarted,
  PlayerSourceMissing,
  PlayerControllerMissing,
  PlaybackSessionMissing,
  PlaybackSessionError,
  PlaybackSessionExpired,
  PlaybackSourceNotPlayable,
  PlaybackEntitlementIssue,
  PlaybackLocationForbidden,
  PlaybackVPNForbidden,
  PlaybackTooManyConcurrentSessions,
  PlaybackSessionInvalidContent,
  PlaybackSessionGeneralError,
  PlaybackSessionNoAsset,
  PlaybackSessionContentNotPlayable,
  PlaybackDrmSessionError,
  PlaybackSourceMissing,
  PlaybackSourceError,
  PlaybackSourceCorrupted,
  PlaybackChannelNotAvailable,
  PlaybackNoChannelsAvailable,
  PlaybackSessionNoContent,

  BPKNoUrl = 700,

  NativePlayerPlayerNotFound = 1301,
  NativePlayerPlayFailed = 1308,
  NativePlayerNoDVBSignal = 1309,
  NativePlayerResolutionUnavailable = 1317,
  NativePlayerUnknownError = 1318,
  NativePlayerUnknownPlayerPosition = 1320,
  NativePlayerError = 1330,
  NativePlayerSourceNotFound,
  NativePlayerSourceError,
  NativePlayerSourceCorrupted,
  NativePlayerDrmUnauthorized = 1350,
  NativePlayerDrmUnknownError,
  NativePlayerDrmDeviceLimitExceeded,
  NativePlayerDrmUserNotEntitled,
  NativePlayerDrmRegionBlocked,
  NativePlayerDrmLicenseAcquisitionTimeout,
  NativePlayerDrmMerchantError,
  NativePlayerDrmAssetNotFound,
  NativePlayerDrmLicenseAcquisitionConnectionRefused,
  NativePlayerDrmDvbCasInternalError,
  NativePlayerDrmDvbUnsupported,
  NativePlayerDrmDvbKeyError,

  DRMNotRequired = 1400,
  DrmLicenseServerError,
  DrmLicenseServerGeoblocking,
  DrmLicenseServerUnavailable,
  DrmLicenseServerUnknownContent,
  DrmLicenseServerInvalidSession,
  DrmLicenseServerDeviceLimitReached,
  DrmLicenseServerEntitlementError,
  DrmLicenseServerPreconditionFailed,
  DrmDvbNoAcces,

  NetworkRequestFailed,
  NetworkNoConnection,

  OperationCanceled
}

export enum ValidationErrorCode {
  NotEmpty = 'NotEmpty',
  Pattern = 'Pattern',
  Email = 'Email',
  ActiveAccountWithEmailExists = 'ActiveAccountWithEmailExists',
  InactiveAccountWithEmailExists = 'InactiveAccountWithEmailExists',
  ActiveAccountWithUsernameExists = 'ActiveAccountWithUsernameExists',
  InactiveAccountWithUsernameExists = 'InactiveAccountWithUsernameExists',
  Size = 'Size'
}

export enum ValidationErrorField {
  Username = 'username',
  Email = 'email',
  Password = 'password',
  FirstName = 'firstName',
  Surname = 'surname',
  Pin = 'pin'
}

type ValidationFieldError = {field: ValidationErrorField, errorCode: ValidationErrorCode};

class MWError extends Error {
  public readonly type: ErrorType;
  public readonly code?: string;
  public readonly extra?: string;

  public constructor(type: ErrorType, message?: string, errorCode?: string, extra?: string) {
    super(message);
    this.type = type;
    this.code = errorCode;
    this.extra = extra;
  }

  public toString(): string {
    const messagePart = this.message ? ` message=${this.message}` : '';
    const stackPart = this.stack ? ` stack=${this.stack}` : '';
    return `[MWError] type=${this.type}${messagePart}${stackPart}`;
  }
}

export {MWError as Error};

export class ValidationError extends MWError {
  public readonly errors: ValidationFieldError[];

  public constructor(errors: ValidationFieldError[]) {
    super(ErrorType.ValidationError);
    this.errors = errors;
  }
}

export class AccountNotActivatedError extends MWError {
  public readonly username: string;

  public constructor(username: string, message?: string) {
    super(ErrorType.AccountNotActivated, message);
    this.username = username;
  }
}

export class MWForbiddenTransactionError extends MWError {
  public constructor(type: ErrorType, message?: string, errorCode?: string) {
    super(type, message, errorCode);
  }
}

export class Errors {
  public static PlayerNotSupportedSource = new MWError(ErrorType.PlayerNotSupportedSource, 'Source not supported');
  public static PlayerNotStarted = new MWError(ErrorType.PlayerNotStarted, 'Player not started');
  public static PlayerSourceMissing = new MWError(ErrorType.PlayerSourceMissing, 'Source not provided');
  public static PlayerControllerMissing = new MWError(ErrorType.PlayerControllerMissing, 'Playback controller missing');

  public static PlaybackSessionNoAsset = new MWError(ErrorType.PlaybackSessionNoAsset, 'Playback asset missing');
  public static PlaybackSessionMissing = new MWError(ErrorType.PlaybackSessionMissing, 'Playback session missing');

  public static BpkNoUrl = new MWError(ErrorType.BPKNoUrl, 'Broadpeak returned empty url');
  public static NativePlayerPlayerNotFound = new MWError(ErrorType.NativePlayerPlayerNotFound, 'Player not found');
  public static NativePlayerUnknownPlayerPosition = new MWError(ErrorType.NativePlayerUnknownPlayerPosition, 'Unknown player position');
  public static NativePlayerNoSignal = new MWError(ErrorType.NativePlayerNoDVBSignal, 'No signal');
}

export function mapNativeError(errorType: ErrorType, message?: string, extra?: string): MWError {
  switch (errorType) {
    case ErrorType.NativePlayerSourceCorrupted:
      return new MWError(ErrorType.PlaybackSourceCorrupted, message);
    case ErrorType.NativePlayerSourceError:
      return new MWError(ErrorType.PlaybackSourceError, message);
    case ErrorType.NativePlayerSourceNotFound:
      return new MWError(ErrorType.PlaybackSourceMissing, message);
    case ErrorType.NativePlayerDrmUnauthorized:
      return new MWError(ErrorType.DrmLicenseServerInvalidSession, message);
    case ErrorType.NativePlayerDrmDeviceLimitExceeded:
      return new MWError(ErrorType.DrmLicenseServerDeviceLimitReached, message);
    case ErrorType.NativePlayerDrmUserNotEntitled:
      return new MWError(ErrorType.DrmLicenseServerEntitlementError, message);
    case ErrorType.NativePlayerDrmRegionBlocked:
      return new MWError(ErrorType.DrmLicenseServerGeoblocking, message);
    case ErrorType.NativePlayerDrmUnknownError:
    case ErrorType.NativePlayerDrmLicenseAcquisitionTimeout:
    case ErrorType.NativePlayerDrmLicenseAcquisitionConnectionRefused:
      return new MWError(ErrorType.DrmLicenseServerUnavailable, message);
    case ErrorType.NativePlayerDrmMerchantError:
      return new MWError(ErrorType.DrmLicenseServerPreconditionFailed, message);
    case ErrorType.NativePlayerDrmAssetNotFound:
      return new MWError(ErrorType.DrmLicenseServerUnknownContent, message);
    case ErrorType.NativePlayerDrmDvbCasInternalError:
    case ErrorType.NativePlayerDrmDvbUnsupported:
    case ErrorType.NativePlayerDrmDvbKeyError:
      return new MWError(ErrorType.DrmDvbNoAcces, message, undefined, extra);
    default:
      return new MWError(errorType, message);
  }
}
