import {createStyles} from 'common-styles';
import React from 'react';
import {TextStyle, View, ViewStyle, StyleProp} from 'react-native';

import {dimensions} from 'common/constants';
import {humanCaseToSnakeCase} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Icon, IconProps} from 'components/Icon';
import NitroxInteractive from 'components/NitroxInteractive';
import NitroxText, {TextType} from 'components/NitroxText';

const TAG = 'SelectionMenu';

const actionHeight = dimensions.icon.small;
const actionBorderRadius = Math.floor(actionHeight / 2);

const staticStyles = createStyles({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: dimensions.margins.large,
    height: dimensions.screen.header.height
  },
  actionWithText: {
    minWidth: 81,
    paddingHorizontal: actionBorderRadius // place the action where the rounded border ends
  }
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  actionText: {
    color: colors.button.secondary.text.focused
  },
  actionContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: colors.button.secondary.background.focused,
    height: actionHeight,
    borderRadius: actionBorderRadius
  },
  selectedActionText: {
    color: colors.button.primary.text.focused
  },
  selectedActionContainer: {
    backgroundColor: colors.button.primary.background.focused
  },
  disabledActionContainer: {
    backgroundColor: colors.button.secondary.background.focused
  },
  disabledActionText: {
    color: colors.button.secondary.text.focused
  }
}));

export type SelectionActionProps = {
  text?: string;
  icon?: IconProps;
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: TextStyle;
  textType?: TextType;
}

export type SelectionMenuAction = {
  key: string;
  props: SelectionActionProps;
  selectedProps?: SelectionActionProps;
  disabledProps?: SelectionActionProps;
  onPress: (selected: boolean) => void;
  renderCustomComponent?: (key: string, props: SelectionActionProps, onPress: () => void) => JSX.Element;
};

export type SelectionMenuProps = {
  actions: SelectionMenuAction[];
  disabledActions?: string[];
  selectedActions?: string[];
  style?: StyleProp<ViewStyle>;
};

type SelectionActionKeys = {[key in string]?: boolean};

export class SelectionMenu extends React.Component<SelectionMenuProps> {

  private findAction(actionKey: string): SelectionMenuAction | null {
    const action = this.props.actions.find(action => action.key === actionKey);
    if (!action) {
      Log.warn(TAG, 'Unable to find action with key ' + actionKey);
      return null;
    }
    return action;
  }

  private toggleAction = (action: SelectionMenuAction, selected: boolean, disabled: boolean) => {
    if (disabled) {
      Log.debug(TAG, 'Action with key ' + action.key + ' is disabled - ignoring request to toogle it');
      return;
    }
    Log.debug(TAG, (selected ? 'Selecting' : 'Deselecting') + ' action with key ' + action.key);
    action.onPress?.(selected);
  }

  private renderAction = (action: SelectionMenuAction, index: number, selectedActions: SelectionActionKeys, disabledActions: SelectionActionKeys) => {
    const styles = stylesUpdater.getStyles();
    const disabled = !!disabledActions[action.key];
    const selected = !!selectedActions[action.key];
    const actionProps = disabled && action.disabledProps || selected && action.selectedProps || action.props;
    const actionContainer: StyleProp<ViewStyle> = [styles.actionContainer];
    if (index > 0) {
      actionContainer.push({marginLeft: dimensions.margins.small});
    }
    if (selected && styles.selectedActionContainer) {
      actionContainer.push(styles.selectedActionContainer);
    }
    if (disabled && styles.disabledActionContainer) {
      actionContainer.push(styles.disabledActionContainer);
    }
    if (actionProps.text) {
      actionContainer.push(staticStyles.actionWithText);
    }
    if (actionProps.containerStyle) {
      actionContainer.push(actionProps.containerStyle);
    }
    const textStyle: StyleProp<TextStyle> = [styles.actionText, {
      paddingLeft: actionProps.icon ? dimensions.margins.xsmall : 0
    }];
    if (selected && styles.selectedActionText) {
      textStyle.push(styles.selectedActionText);
    }
    if (disabled && styles.disabledActionText) {
      textStyle.push(styles.disabledActionText);
    }
    if (actionProps.textStyle) {
      textStyle.push(actionProps.textStyle);
    }
    const textType = actionProps.textType || 'buttons-settings';
    const text = actionProps.text || '';
    if (action.renderCustomComponent) {
      return action.renderCustomComponent(action.key, actionProps, () => this.toggleAction(action, selected, disabled));
    }
    const testIdDetail = actionProps.text || actionProps.icon?.type.valueOf();
    const testID = testIdDetail ? `button_${humanCaseToSnakeCase(testIdDetail)}` : 'button';
    return (
      <NitroxInteractive key={action.key} style={actionContainer} onPress={() => this.toggleAction(action, selected, disabled)} testID={testID}>
        {actionProps.icon &&
          <Icon type={actionProps.icon.type} size={actionProps.icon.size} color={actionProps.icon.color} />
        }
        {!!text &&
          <NitroxText style={textStyle} textType={textType} numberOfLines={1}>{text}</NitroxText>
        }
      </NitroxInteractive>
    );
  }

  private mapActions(actionKeys?: string[]): SelectionActionKeys {
    const mapped: SelectionActionKeys = {};
    actionKeys?.forEach(actionKey => {
      if (this.findAction(actionKey)) {
        mapped[actionKey] = true;
      }
    });
    return mapped;
  }

  public render() {
    const selectedActions = this.mapActions(this.props.selectedActions);
    const disabledActions = this.mapActions(this.props.disabledActions);
    return (
      <View style={[staticStyles.container, this.props.style]} testID='selection_menu'>
        {this.props.actions.map((action, index) => this.renderAction(action, index, selectedActions, disabledActions))}
      </View>
    );
  }
}
