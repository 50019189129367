import React from 'react';
import {ViewStyle, View} from 'react-native';

import {dimensions, Direction} from 'common/constants';
import {Log} from 'common/Log';

import {Icon, IconType} from 'components/Icon';
import NitroxInteractive from 'components/NitroxInteractive';

const TAG = 'NavArrow';

type Props = {
  direction: Direction;
  height: number;
  width: number;
  opacity?: number;
  iconSize?: number;
  backgroundColor?: string;
  insetLeft?: number;
  insetRight?: number;
  handlePress?: () => void;
}

export const NavArrow: React.FunctionComponent<Props> = props => {
  const {direction, backgroundColor, handlePress, height, width, iconSize, opacity, insetLeft, insetRight} = props;
  let directionIcon: IconType;
  let viewStyle: ViewStyle;
  let touchableStyle: ViewStyle;
  const opacityValue = opacity ?? 1;
  const iconSizeValue = iconSize ?? dimensions.icon.xsmall;

  switch (direction) {
    case Direction.Left:
      directionIcon = IconType.ArrowLeftBold;
      viewStyle = {left: insetLeft || 0, height: '100%', width};
      touchableStyle = {width: '100%', height};
      break;
    case Direction.Right:
      directionIcon = IconType.ArrowRightBold;
      viewStyle = {right: insetRight || 0, height: '100%', width};
      touchableStyle = {width: '100%', height};
      break;
    case Direction.Up:
      directionIcon = IconType.ArrowUpBold;
      viewStyle = {top: 0, width: '100%', height};
      touchableStyle = {height: '100%', width};
      break;
    case Direction.Down:
      directionIcon = IconType.ArrowDownBold;
      viewStyle = {bottom: 0, width: '100%', height};
      touchableStyle = {height: '100%', width};
      break;
    default:
      Log.error(TAG, 'Wrong direction: ' + direction);
      return null;
  }

  return (
    <View style={{...viewStyle, position: 'absolute', justifyContent: 'center', alignItems: 'center'}}>
      <NitroxInteractive
        testID={`button_arrow_${direction}`}
        style={{...touchableStyle, backgroundColor: backgroundColor ? backgroundColor : 'transparent', opacity: opacityValue}}
        activeOpacity={1}
        onPress={handlePress}
        mouseOnly
      >
        <View style={{opacity: 1, width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
          <Icon type={directionIcon} size={iconSizeValue} />
        </View>
      </NitroxInteractive>
    </View>
  );
};

export default React.memo(NavArrow);
