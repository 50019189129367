// improvement use BROWSERS DOMParser, XMLSerializer
import {Parser, processors} from 'xml2js';
import {DOMParser, XMLSerializer} from 'xmldom';

interface SingleCreator {
  (xmlDoc: Document, paramsObject: any): any;
}

enum XMLNodeTypes {
  Attr,
  Element,
  Text
}

export interface XMLNode {
  typeOf: XMLNodeTypes;
  data: Node;
}

class XmlUtils {
  public static instance = new XmlUtils();

  public parseXmlString = (txt: string): Document => {
    return (new DOMParser()).parseFromString(txt, 'application/xml');
  }

  public getXmlDoc = (root: string, declaration?: string): Document => {
    if (root === null || !root) {
      throw new Error('You must supply root tag, e.g.: <RootTag/>');
    }
    return this.parseXmlString((declaration || '<?xml version="1.0" encoding="utf-8"?>') + root);
  }

  public serializeXmlDoc = (xmlDoc: Document): string => {
    return (new XMLSerializer()).serializeToString(xmlDoc);
  }

  public appendChild = (parentNode: Node, childNode: XMLNode) => {
    parentNode.appendChild(childNode.data);
  }

  public createElement = (xmlDoc: Document, element: string, params?: any): XMLNode => {
    const newEle = xmlDoc.createElementNS(xmlDoc.documentElement.namespaceURI, element);
    this.setParams(xmlDoc, newEle, params);
    return {
      data: newEle,
      typeOf: XMLNodeTypes.Element
    };
  }

  public setParams = (xmlDoc: Document, element: Element, params: any): void => {
    if (typeof params === 'undefined') {
      return;
    }
    if (params instanceof Array) {
      for (let i = 0; i < params.length; ++i) {
        this.setParams(xmlDoc, element, params[i]);
      }
      return;
    }
    switch (params.typeOf) {
      case XMLNodeTypes.Attr:
        element.setAttributeNode(params.data);
        break;

      case XMLNodeTypes.Element:
      case XMLNodeTypes.Text:
        element.appendChild(params.data);
        break;

      default:
        element.appendChild(this.createTextCdata(xmlDoc, params));
        break;
    }
  }

  public createText = (xmlDoc: Document, text: string): XMLNode => {
    return {
      data: xmlDoc.createTextNode(text),
      typeOf: XMLNodeTypes.Text
    };
  }

  public createCdata = (xmlDoc: Document, data: string): CDATASection => {
    return xmlDoc.createCDATASection(data);
  }

  private createTextCdata = (xmlDoc: Document, param: string): Node => {
    return !/["&'<>]/.test(param) ? this.createText(xmlDoc, param).data : this.createCdata(xmlDoc, param);
  }

  public createAttr = (xmlDoc: Document, attributes: any): XMLNode[] => {
    const nodes: XMLNode[] = [];
    for (const attribute in attributes) {
      if (attributes.hasOwnProperty(attribute)) {
        const newAtt = xmlDoc.createAttribute(attribute);
        newAtt.value = attributes[attribute];
        nodes.push({
          typeOf: XMLNodeTypes.Attr,
          data: newAtt
        });
      }
    }
    return nodes;
  }

  private selfCreator: SingleCreator = (xmlDoc: Document, s: any): any => s;

  public createMultiElement = (xmlDoc: Document, multiName: string, singleName: string, attrName: string, paramsArray: any, singleCreator?: SingleCreator): XMLNode => {
    const creator: (xmlDoc: Document, s: any) => any = singleCreator || this.selfCreator;
    const elementArray: XMLNode[] = [];
    if (!(paramsArray instanceof Array)) {
      paramsArray = [paramsArray];
    }
    paramsArray.forEach((paramsObject: any) => {
      for (const prop in paramsObject) {
        if (!paramsObject.hasOwnProperty(prop)) {
          continue;
        }

        const attrObj: any = {};
        attrObj[attrName] = prop;
        const elements = paramsObject[prop] instanceof Array ? paramsObject[prop] : [paramsObject[prop]];
        elements.forEach((element: any): void => {
          elementArray.push(this.createElement(xmlDoc, singleName, [
            this.createAttr(xmlDoc, attrObj),
            creator(xmlDoc, element)
          ]));
        });
      }
    });

    return this.createElement(xmlDoc, multiName, elementArray);
  }

  public xml2json = (xmlText: string): Promise<any> => {
    return new Promise(resolve => {
      const parser: Parser = new Parser({
        mergeAttrs: true,
        explicitArray: false,
        charkey: 'Value',
        tagNameProcessors: [processors.stripPrefix],
        attrValueProcessors: [processors.stripPrefix]
      });
      parser.parseString(xmlText, (error: Error, result: any) => {
        resolve(error ? xmlText : result);
      });
    });
  }
}

export const xmlUtils = XmlUtils.instance;
