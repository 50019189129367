import {Offer, PaymentMethodId, Product, ProductType} from 'mw/api/Metadata';
import {convertJSONDateToJSDate, convertScormToSeconds} from 'mw/common/utils';
import {OfferObject} from 'mw/metadata/OfferrObject';
import {ProductObject} from 'mw/metadata/ProductObject';

import {checkEntitlement} from './MappersHelper';

export class ProductMapper {
  public static fromJSON(json: any): Product {
    const product = new ProductObject(json.id);

    if (json.IsAvailable != null) {
      product.isAvailable = json.IsAvailable || false;
    }

    if (json.EntitlementState) {
      product.entitlementState = checkEntitlement(json.EntitlementState);
    }

    if (json.Name != null) {
      product.name = json.Name;
    }

    switch (json.Type) {
      case ProductType.Free:
      case ProductType.Subscription:
        product.type = json.Type;
        break;
      case ProductType.Transaction:
        // TODO: CL-849 and CL-5877 - change this value to TransactionRent or TransactionBuy after implementing purchase/rent VOD on ADR7 BO
        product.type = ProductType.TransactionRent;
        break;
    }

    if (json.RelationAvailabilityStart != null) {
      product.relationStart = convertJSONDateToJSDate(json.RelationAvailabilityStart);
    }

    if (json.RelationAvailabilityEnd != null) {
      product.relationEnd = convertJSONDateToJSDate(json.RelationAvailabilityEnd);
    }

    if (json.OfferPrice?.Offer != null) {
      product.offers = json.OfferPrice.Offer.map((offerJson: any): Offer => {
        let rentalPeriod: number | undefined;
        if (offerJson.rentalPeriod != null) {
          rentalPeriod = convertScormToSeconds(offerJson.rentalPeriod);
        } else if (json.RentalPeriod != null) {
          rentalPeriod = convertScormToSeconds(json.RentalPeriod);
        }
        return new OfferObject(offerJson.id, PaymentMethodId.Billing, offerJson.Value, json.Currency, rentalPeriod);
      });
    }

    if (json.IsRentalPeriodIndefinite != null) {
      product.isAvailableToBuy = json.IsRentalPeriodIndefinite;
      product.isAvailableToRent = !json.IsRentalPeriodIndefinite;
    }
    return product;
  }

  public static fromJSONArray(jsonArray: any[]): Product[] {
    return jsonArray.map(ProductMapper.fromJSON);
  }
}
