/* eslint-disable no-restricted-syntax */
import i18n from './i18n';
import {i18nUtils, i18nUtilsType, LocalizedTextTransformFunction} from './i18nUtils';

const interpolationLangOpeningTagRegExp = /{{lang:(\w{2,})}}/;
const interpolationLangClosingTagRegExp = /{{lang}}/;
const langOpeningTagRegExp = /_{lang:(\w{2,})}_/;
const langClosingTagRegExp = /_{lang}_/;
const langOpeningTagRegExpGlobal = new RegExp(langOpeningTagRegExp, 'g');
const langClosingTagRegExpGlobal = new RegExp(langClosingTagRegExp, 'g');
const taggedResourceRegExp = /_{lang:(\w{2,})}_(.+?)_{lang}_/;

type TextTransformFunctions = {
  [key: string]: LocalizedTextTransformFunction;
  default: LocalizedTextTransformFunction;
}

export const hasInterpolationLangTag = (value: string) => (
  !!(value.match(interpolationLangOpeningTagRegExp) || value.match(interpolationLangClosingTagRegExp))
);
const hasLangTag = (value: string) => (
  !!(value.match(langOpeningTagRegExp) || value.match(langClosingTagRegExp))
);

export const replaceInterpolationTags = (value: string) => value.replace('{{', '_{').replace('}}', '}_');

/**
 * This feature enables localized text transforms for a language other than currently used.
 * To perform localized text transform surround the part of the string you want to mark with: '{{lang:LANG_CODE}}' and '{{lang}}' tags.
 * Example: 'Username / {{lang:el}}Όνομα Χρήστη{{lang}}'.
 * NOTE: untagged/incorrectly tagged parts are transformed according to the current language.
 */
const resourceTextTransformFactory = (textTransformFunctions: TextTransformFunctions) => {
  return (text: string, currentLanguage: string) => {
    const defaultTextTransformFunction = textTransformFunctions[currentLanguage] || textTransformFunctions.default;
    let transformedText = text;
    while (true) {
      const match = transformedText.match(taggedResourceRegExp);
      if (!match) {
        break;
      }
      const taggedResource = match[0];
      const locale = match[1];
      const substringToTransform = match[2];
      transformedText = transformedText.replace(
        taggedResource,
        (textTransformFunctions[locale] || defaultTextTransformFunction)(substringToTransform)
      );
    }
    //get rid off invalid tags
    transformedText = transformedText.replace(langOpeningTagRegExpGlobal, '').replace(langClosingTagRegExpGlobal, '');
    return (defaultTextTransformFunction)(transformedText);
  };
};

const textTransformPostProcessorFactory = (transformFn: keyof i18nUtilsType) => {
  const textTransformFunctions: TextTransformFunctions = Object.keys(i18nUtils).reduce((functions: TextTransformFunctions, item: string) => {
    const localizedTextTransformFunction = i18nUtils[item][transformFn];
    if (localizedTextTransformFunction) {
      functions[item] = localizedTextTransformFunction;
    }
    return functions;
  }, {default: i18nUtils.default[transformFn]});

  const resourceTextTransformFunction = resourceTextTransformFactory(textTransformFunctions);

  return (value: string, key: string[], options: i18n.TOptions, translator: any) => {
    if (hasLangTag(value)) {
      return resourceTextTransformFunction(value, translator.language || i18n.language);
    } else {
      return (textTransformFunctions[translator.language || i18n.language] || textTransformFunctions.default)(value);
    }
  };
};

export enum PostProcessors {
  ToUpperCase = 'toUpperCase',
  ToLowerCase = 'toLowerCase',
  Capitalize = 'capitalize'
}

export const toUpperCasePostProcessor = {
  type: 'postProcessor',
  name: PostProcessors.ToUpperCase,
  process: textTransformPostProcessorFactory(PostProcessors.ToUpperCase)
};

export const toLowerCasePostProcessor = {
  type: 'postProcessor',
  name: PostProcessors.ToLowerCase,
  process: textTransformPostProcessorFactory(PostProcessors.ToLowerCase)
};

export const capitalizePostProcessor = {
  type: 'postProcessor',
  name: PostProcessors.Capitalize,
  process: textTransformPostProcessorFactory(PostProcessors.Capitalize)
};
