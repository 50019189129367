import {createStyles} from 'common-styles';
import React, {ReactNode, useMemo, useEffect, useState, useCallback, useContext} from 'react';
import {View, ViewStyle, Platform} from 'react-native';

import {dimensions, isBigScreen, isTablet, isMobile} from 'common/constants';
import {humanCaseToSnakeCase} from 'common/HelperFunctions';
import {TestProps} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {ChromecastConnectionState} from 'mw/api/Metadata';
import {RewindDirection} from 'mw/playback/Player';

import {channelIconConstants} from 'components/ChannelIcon';
import {ChromecastContext} from 'components/ChromecastContext';
import FloatingBubble, {FloatingBubbleType} from 'components/FloatingBubble';
import {EnterStrategy} from 'components/focusManager/FocusManagerTypes';
import FocusParent, {useFocusParent} from 'components/FocusParent';
import {useFullScreenControl} from 'components/fullscreen/FullScreenControlProvider';
import {Icon, IconType} from 'components/Icon';
import NitroxInteractive from 'components/NitroxInteractive';
import {NitroxInteractiveController} from 'components/NitroxInteractiveControllerContext';
import {getFontStyle} from 'components/NitroxText';
import {channelListContainerLeftMargin} from 'components/zapper/Zapper';
import {useScreenInfo, useEffectOnce} from 'hooks/Hooks';

const collapseButtonHeight = 15;
const maxTitlePercentageHeight = 40;
const defaultNumberOfLines = 2;
const lowResNumberOfLines = 1;
const margin = isTablet ? dimensions.icon.medium : dimensions.icon.small;
const iconOffset = 3;
const fullScreenIconSize = isBigScreen ? dimensions.icon.small : dimensions.icon.xxsmall;

export enum PlayerControlsRecordDotState {
  Record = 'Record',
  IsNowRecorded = 'IsNowRecorded',
}

export type PlayerControlsHandlers = {
  restartHandler?: () => void;
  playPrevEpisodeHandler?: () => void;
  skipBackHandler?: () => void;
  fastBackwardsHandler?: () => void;
  playPauseHandler?: () => void;
  stopHandler?: () => void;
  skipForwardHandler?: () => void;
  fastForwardHandler?: () => void;
  playNextEpisodeHandler?: () => void;

  showEPGHandler?: () => void;
  collapseHandler?: () => void;
  castHandler?: () => void;
  episodesHandler?: () => void;
  toggleHandler?: () => void;
  subtitleHandler?: () => void;
  goToLiveHandler?: () => void;
  recordDotHandler?: (RecordDotState: PlayerControlsRecordDotState) => void;
  channelListHandler?: () => void;
  fullScreenHandler?: () => void;
  fullScreenBackHandler?: () => void;
  moreInfoHandler?: () => void;
};

export type PlaybackControlsVisibility = {
  restart: boolean;
  goToLive: boolean;
  subtitles: boolean;
  fastBackwards: boolean;
  skipBack: boolean;
  pausePlay: boolean;
  skipForward: boolean;
  fastForward: boolean;
};

export type PlayerNavigationControlsVisibility = {
  collapse: boolean;
  episodes: boolean;
  recordDot: PlayerControlsRecordDotState | null;
  channelList: boolean;
  chromecast: boolean;
  options: boolean;
  prevEpisode: boolean;
  nextEpisode: boolean;
  fullscreen: boolean;
  backFromFullscreen: boolean;
  showEPG: boolean;
  moreInfo: boolean;
}

export type PlayerControlsViewButtonsVisibility = PlayerNavigationControlsVisibility & {
  playback: PlaybackControlsVisibility;
};

const defaultIconSize = isBigScreen ? dimensions.playerButton.iconSize : dimensions.icon.medium;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: isMobile ? 'hidden' : 'visible'
  },
  bigScreenContainer: {
    position: 'absolute',
    bottom: '15%',
    height: dimensions.icon.small,
    width: '80%',
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  playbackControlIcons: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  playbackOptionsContainer: {
    flexDirection: 'row',
    bottom: '20%',
    justifyContent: 'flex-end',
    ...isBigScreen && {alignItems: 'center'}
  },
  liveTVOptionsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  horizontalMargin: {
    marginHorizontal: margin
  },
  playbackControlIconsView: {
    marginHorizontal: margin,
    ...isMobile && {
      height: defaultIconSize,
      width: defaultIconSize
    },
    ...isTablet && {
      height: defaultIconSize + dimensions.margins.xsmall,
      width: defaultIconSize + dimensions.margins.xsmall
    }
  },
  leftMargin: {
    marginLeft: margin
  },
  playerIcon: {
    marginLeft: margin,
    width: dimensions.icon.small,
    height: dimensions.icon.small
  },
  rightMargin: {
    marginRight: margin
  },
  topContainer: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    paddingHorizontal: dimensions.margins.xxLarge,
    paddingTop: dimensions.margins.xxLarge
  },
  bottomContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingHorizontal: isTablet ? dimensions.margins.xxLarge : dimensions.margins.xxLarge,
    paddingBottom: isTablet ? dimensions.margins.xxxLarge : dimensions.margins.xxLarge
  },
  mediaDetails: {
    ...isBigScreen && {
      justifyContent: 'flex-start',
      position: 'absolute',
      width: '90%',
      height: '45%',
      top: '30%',
      left: '10%'
    },
    ...isMobile && {
      flex: 1
    }
  },
  portalStyle: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  borderStyle: {
    borderWidth: 2,
    borderRadius: 2,
    borderColor: colors.playerScreen.playerButton.border
  },
  closeScreenButton: {
    ...isMobile && {
      marginBottom: dimensions.margins.xxxLarge - dimensions.margins.xxLarge - (dimensions.player.progressBar.default.expandedHeight / 2) + (dimensions.player.progressBar.default.progressHeight) + ((dimensions.player.progressBar.default.expandedHeight - dimensions.icon.xxsmall) / 2)
    }
  },
  iconColorFocused: colors.playerScreen.playerButton.icon.focused,
  iconColorUnfocused: colors.playerScreen.playerButton.icon.unfocused,
  iconColorDisabled: colors.playerScreen.playerButton.icon.disabled,
  iconCastConnected: colors.chromecast.connectedIcon,
  backgroundColorFocused: colors.playerScreen.playerButton.background.focused,
  backgroundColorUnfocused: colors.playerScreen.playerButton.background.unfocused,
  tooltipContainer: {
    position: 'absolute',
    top: -80
  },
  tooltipText: {
    color: colors.playerScreen.playerButton.tooltip.textColor,
    marginHorizontal: dimensions.margins.medium,
    whiteSpace: 'nowrap'
  },
  tooltipColor: colors.playerScreen.playerButton.tooltip.background
}));

enum FocusPriority {
  moreInfo,
  channelList,
  subtitles,
  recordDot,
  restart,
  pausePlay,
  fastBackwards,
  fastForward
}

type PlayerButtonProps = {
  icon: IconType;
  focusPriority?: number;
  onPress?: () => void;
  style?: ViewStyle;
  iconSize?: number;
  disabled?: boolean;
} & TestProps;

const hitInset = isBigScreen ? 0 : defaultIconSize / 2;
const hitSlop = {
  left: hitInset,
  right: hitInset,
  top: hitInset,
  bottom: hitInset
};

const mapRecordDotStateToIcon = (recordDotState: PlayerControlsRecordDotState): IconType => {
  switch (recordDotState) {
    case PlayerControlsRecordDotState.Record:
      return IconType.RecordDot;
    case PlayerControlsRecordDotState.IsNowRecorded:
      return IconType.RecordingDot;
  }
};

const mapChromecastConnectionStateToIcon = (chromecastConnectionState: ChromecastConnectionState): IconType => {
  switch (chromecastConnectionState) {
    case ChromecastConnectionState.Disconnected:
      return IconType.CastDisconnected;
    case ChromecastConnectionState.Connecting:
      return IconType.CastConnecting;
    default:
      return IconType.CastConnected;
  }
};

const PlayerButton: React.FunctionComponent<PlayerButtonProps> = props => {
  const [focused, onFocusStateChanged] = useState(false);
  const styles = stylesUpdater.getStyles();
  const iconColor = useMemo(() => {
    switch (props.icon) {
      case IconType.CastConnected:
        return styles.iconCastConnected;
      default:
        if (props.disabled) {
          return styles.iconColorDisabled;
        }
        return focused ? styles.iconColorFocused : styles.iconColorUnfocused;
    }
  }, [focused, props.disabled, props.icon, styles.iconCastConnected, styles.iconColorDisabled, styles.iconColorFocused, styles.iconColorUnfocused]);
  const backgroundColor = !props.disabled && focused ? styles.backgroundColorFocused : styles.backgroundColorUnfocused;
  const iconSize = props.iconSize || defaultIconSize;
  const height = isBigScreen ? iconSize + 2 * dimensions.margins.xLarge : iconSize;
  const width = isBigScreen ? height : undefined;
  const size = {height, width};
  // move play icon to the right to balance its weight
  const offset = useMemo(() => {
    switch (props.icon) {
      case IconType.Play:
        return isMobile ? 0 : iconOffset;
      case IconType.FastForward:
        return iconOffset;
      case IconType.FastBackwards:
        return -iconOffset;
      default:
        return 0;
    }
  }, [props.icon]);
  const testID = props.testID && `button_${humanCaseToSnakeCase(props.testID)}`;

  return (
    <NitroxInteractive
      activeOpacity={Platform.isTV ? 1 : dimensions.opacity.xhigh}
      style={{borderRadius: size.height / 2, backgroundColor, ...size, justifyContent: 'center', alignItems: 'center', ...props.style}}
      onPress={props.disabled ? undefined : props.onPress}
      focusPriority={props.focusPriority}
      onFocusStateChanged={onFocusStateChanged}
      focusOnPress={isBigScreen}
      testID={testID}
      hitSlop={hitSlop}
      disabled={props.disabled}
    >
      <View style={{left: offset}}>
        <Icon type={props.icon} size={iconSize} color={iconColor} focused={focused} />
      </View>
    </NitroxInteractive>
  );
};

type TooltipProps = {
  visible: boolean;
  text?: string;
}

const floatingBubblePosition = Math.round((defaultIconSize + margin) / 2);

const Tooltip: React.FC<TooltipProps> = React.memo(({
  visible,
  text = ''
}) => {
  const styles = stylesUpdater.getStyles();

  return (
    <View style={styles.tooltipContainer}>
      {visible && (
        <FloatingBubble
          label={text}
          textStyle={styles.tooltipText}
          type={FloatingBubbleType.PlayerButtonTooltip}
          backgroundColor={styles.tooltipColor}
          initialPositionX={floatingBubblePosition}
        />
      )}
    </View>
  );
});
Tooltip.displayName = 'Tooltip';

export type TooltipsProps = {
  fastBackwards?: TooltipProps;
  fastForward?: TooltipProps;
};

type ControlsProps = {
  buttonsVisibility: PlayerControlsViewButtonsVisibility;
  tooltipsProps?: TooltipsProps;
  handlers: PlayerControlsHandlers;
  playbackError?: boolean;
  chromecastConnectionState?: ChromecastConnectionState;
  isChannelBlocked?: boolean;
}

type PlabackState = {
  paused: boolean
  rewindDirection?: RewindDirection;
};

type PlaybackControlsProps = ControlsProps & PlabackState;

const PlaybackControls: React.FC<PlaybackControlsProps> = props => {
  const {buttonsVisibility, handlers, tooltipsProps, paused, rewindDirection, playbackError, isChannelBlocked} = props;
  const styles = stylesUpdater.getStyles();

  const renderTooltip = useCallback((tooltipProps?: TooltipProps) => (
    isBigScreen && tooltipProps
      ? <Tooltip {...tooltipProps} />
      : null
  ), []);

  return (
    <View style={styles.playbackControlIcons}>
      {buttonsVisibility.playback.fastBackwards && (
        <View style={styles.playbackControlIconsView}>
          <PlayerButton testID='fastBackwards' icon={IconType.FastBackwards} onPress={handlers.fastBackwardsHandler} disabled={playbackError} focusPriority={rewindDirection === RewindDirection.FastBackwards ? FocusPriority.fastBackwards : undefined} />
          {renderTooltip(tooltipsProps?.fastBackwards)}
        </View>
      )}
      {buttonsVisibility.playback.skipBack && (
        <View style={styles.playbackControlIconsView}>
          <PlayerButton testID='skip_back_10' icon={IconType.SkipBack10} onPress={handlers.skipBackHandler} disabled={playbackError} />
        </View>
      )}
      {buttonsVisibility.prevEpisode && <PlayerButton testID='backward' icon={IconType.Backward} onPress={handlers.playPrevEpisodeHandler} style={styles.horizontalMargin} />}
      {!isChannelBlocked && (
        <View style={styles.playbackControlIconsView}>
          <PlayerButton testID='play_pause' focusPriority={buttonsVisibility.playback.pausePlay ? FocusPriority.pausePlay : undefined} icon={paused || !!rewindDirection ? IconType.Play : IconType.Pause} onPress={handlers.playPauseHandler} disabled={playbackError || !buttonsVisibility.playback.pausePlay} />
        </View>
      )}
      {buttonsVisibility.nextEpisode && <PlayerButton testID='forward' icon={IconType.Forward} onPress={handlers.playNextEpisodeHandler} style={styles.horizontalMargin} />}
      {buttonsVisibility.playback.skipForward && (
        <View style={styles.playbackControlIconsView}>
          <PlayerButton testID='skip_forward_10' icon={IconType.SkipForward10} onPress={handlers.skipForwardHandler} disabled={playbackError} />
        </View>
      )}
      {buttonsVisibility.playback.fastForward && (
        <View style={styles.playbackControlIconsView}>
          <PlayerButton testID='fastForward' icon={IconType.FastForward} onPress={handlers.fastForwardHandler} disabled={playbackError} focusPriority={rewindDirection === RewindDirection.FastForward ? FocusPriority.fastForward : undefined} />
          {renderTooltip(tooltipsProps?.fastForward)}
        </View>
      )}
    </View>
  );
};

const PlaybackOptions: React.FC<ControlsProps> = props => {
  const {buttonsVisibility, handlers, playbackError, chromecastConnectionState = ChromecastConnectionState.Disconnected} = props;
  const iconSize = isBigScreen ? dimensions.icon.small : dimensions.icon.xxsmall;
  const styles = stylesUpdater.getStyles();
  const onRecordPress = () => buttonsVisibility.recordDot && handlers?.recordDotHandler?.(buttonsVisibility.recordDot);
  const {enabled: fullScreenControlEnabled} = useFullScreenControl();
  return isBigScreen ? (
    <View style={styles.playbackOptionsContainer}>
      {buttonsVisibility.episodes && <PlayerButton testID='episodes' icon={IconType.Episodes} onPress={handlers.episodesHandler} style={styles.leftMargin} iconSize={iconSize} />}
      {buttonsVisibility.playback.subtitles && <PlayerButton testID='subtitle' focusPriority={FocusPriority.subtitles} icon={IconType.CCSubtitle} onPress={handlers.subtitleHandler} style={styles.leftMargin} iconSize={iconSize} disabled={playbackError} />}
      {buttonsVisibility.moreInfo && <PlayerButton testID='moreInfo' focusPriority={FocusPriority.moreInfo} icon={IconType.MoreInfo} onPress={handlers.moreInfoHandler} style={styles.leftMargin} iconSize={iconSize} />}
      {buttonsVisibility.channelList && <PlayerButton testID='channels_list' focusPriority={FocusPriority.channelList} icon={IconType.ChannelsList} onPress={handlers.channelListHandler} style={styles.leftMargin} iconSize={iconSize} />}
      {fullScreenControlEnabled && <FullScreenControls buttonsVisibility={buttonsVisibility} handlers={handlers} />}
    </View>
  ) : (
    <View style={styles.playbackOptionsContainer}>
      {buttonsVisibility.showEPG && <PlayerButton testID='epg' icon={IconType.Epg} onPress={handlers.showEPGHandler} style={styles.playerIcon} iconSize={iconSize} />}
      {buttonsVisibility.playback.goToLive && <PlayerButton testID='go_to_live' icon={IconType.GoToLive} onPress={handlers.goToLiveHandler} style={styles.playerIcon} iconSize={iconSize} disabled={playbackError} />}
      {buttonsVisibility.episodes && <PlayerButton testID='episodes' icon={IconType.Episodes} onPress={handlers.episodesHandler} style={styles.playerIcon} iconSize={iconSize} />}
      {buttonsVisibility.chromecast && <PlayerButton testID='cast' icon={mapChromecastConnectionStateToIcon(chromecastConnectionState)} onPress={handlers.castHandler} style={styles.playerIcon} iconSize={iconSize} />}
      {buttonsVisibility.playback.subtitles && <PlayerButton testID='subtitle' icon={IconType.SubtitleMobile} onPress={handlers.subtitleHandler} style={styles.playerIcon} iconSize={iconSize} disabled={playbackError} />}
      {buttonsVisibility.playback.restart && <PlayerButton testID='restart' icon={IconType.Restart} onPress={handlers.restartHandler} style={styles.playerIcon} iconSize={iconSize} disabled={playbackError} />}
      {buttonsVisibility.recordDot && <PlayerButton testID='record' icon={mapRecordDotStateToIcon(buttonsVisibility.recordDot)} onPress={onRecordPress} style={styles.playerIcon} iconSize={iconSize} disabled={playbackError} />}
      {buttonsVisibility.options && handlers.toggleHandler && <PlayerButton testID='more_actions' icon={IconType.Toggle} onPress={handlers.toggleHandler} style={styles.playerIcon} iconSize={iconSize} />}
    </View>
  );
};

const LiveTVOptions: React.FC<ControlsProps> = props => {
  const {buttonsVisibility, handlers, playbackError} = props;
  const styles = stylesUpdater.getStyles();
  return isBigScreen ? (
    <View style={styles.liveTVOptionsContainer}>
      {(buttonsVisibility.playback.goToLive) &&
        (
          <PlayerButton
            testID='go_to_live'
            icon={IconType.GoToLive}
            onPress={handlers.goToLiveHandler}
            style={styles.rightMargin}
            disabled={playbackError}
          />
        )
      }
      {(buttonsVisibility.playback.restart) &&
        (
          <PlayerButton
            testID='restart'
            focusPriority={FocusPriority.restart}
            icon={IconType.Restart}
            onPress={handlers.restartHandler}
            style={styles.rightMargin}
            disabled={playbackError}
          />
        )
      }
      {(buttonsVisibility.recordDot) &&
        (
          <PlayerButton
            testID='record'
            focusPriority={FocusPriority.recordDot}
            icon={mapRecordDotStateToIcon(buttonsVisibility.recordDot)}
            onPress={() => buttonsVisibility.recordDot && handlers?.recordDotHandler?.(buttonsVisibility.recordDot)}
            style={styles.leftMargin}
            disabled={playbackError}
          />
        )
      }
    </View>
  ) : (
    <View>
      {buttonsVisibility.collapse &&
          (
            <PlayerButton
              testID='collapse'
              icon={IconType.Collapse}
              onPress={handlers.collapseHandler}
              style={styles.rightMargin}
              iconSize={collapseButtonHeight}
            />
          )
      }
    </View>
  );
};

const FullScreenControls: React.FC<ControlsProps> = props => {
  const {buttonsVisibility, handlers} = props;
  const styles = stylesUpdater.getStyles();
  return (
    <View>
      {buttonsVisibility.backFromFullscreen && (
        <PlayerButton
          testID='fullscreen_back'
          icon={IconType.FullscreenBack}
          onPress={handlers.fullScreenBackHandler}
          style={{...styles.leftMargin, ...styles.closeScreenButton}}
          iconSize={fullScreenIconSize}
        />
      )}
      {buttonsVisibility.fullscreen && (
        <PlayerButton
          testID='fullscreen'
          icon={IconType.FullScreen}
          onPress={handlers.fullScreenHandler}
          style={styles.leftMargin}
          iconSize={fullScreenIconSize}
        />
      )}
    </View>
  );
};

type Props = {
  handlers: PlayerControlsHandlers;
  buttonsVisibility: PlayerControlsViewButtonsVisibility;
  tooltipsProps?: TooltipsProps;
  paused: boolean;
  rewindDirection?: RewindDirection;
  focusPriority?: number;
  renderDetails?: (titleNumberOfLines?: number) => ReactNode | null;
  onReady?: (params: {focus: (enterStrategy?: EnterStrategy) => void}) => void;
  /**
   * Use instead of 'onReady' & useFocusParent when conditional rendering. useFocusParent does not handle remount phase.
   */
  focusOnReady?: boolean;
  landscape?: boolean;
  zapperMode?: boolean;
  shouldDisplayPlaybackControls?: boolean;
  playbackError?: boolean;
  isChannelBlocked?: boolean;
}

const PlayerControlsView: React.FunctionComponent<Props> = ({
  landscape = false,
  renderDetails,
  zapperMode = false,
  shouldDisplayPlaybackControls = true,
  buttonsVisibility,
  tooltipsProps,
  handlers,
  paused,
  rewindDirection,
  focusPriority,
  onReady,
  playbackError,
  focusOnReady = false,
  isChannelBlocked
}) => {
  const [numberOfLines, setNumberOfLines] = useState(defaultNumberOfLines);
  const {size: {width: componentWidth}} = useScreenInfo();
  const topContainerLeftMargin = useMemo(() => ({
    marginLeft: (zapperMode && landscape) ? channelIconConstants.channelList.scaledMobile + channelListContainerLeftMargin : 105
  }), [zapperMode, landscape]);

  const {connectionState: chromecastConnectionState} = useContext(ChromecastContext);

  useEffect(() => {
    if (isMobile && !landscape) {
      const componentHeight = componentWidth * 9 / 16;
      const {lineHeight = 0} = getFontStyle(landscape ? 'player-title' : 'title1');
      const {lineHeight: lineHeightSubtitle = 0} = getFontStyle(!landscape ? 'callout-small' : 'callout');
      const maxTitleHeight = Math.round((maxTitlePercentageHeight / 100) * componentHeight);
      const titleHeight = (lineHeight + lineHeightSubtitle) * defaultNumberOfLines;
      if (titleHeight >= maxTitleHeight) {
        setNumberOfLines(lowResNumberOfLines);
      }
    }
  }, [landscape, componentWidth]);

  const styles = stylesUpdater.getStyles();

  const [onControlsReadyInternal, focusControlsInternal] = useFocusParent();

  useEffectOnce(() => {
    if (isBigScreen && focusOnReady) {
      focusControlsInternal();
    }
  }, [focusOnReady]);

  return isBigScreen ? (
    <View style={styles.container} testID='player_controls'>
      {renderDetails &&
        (
          <View style={[styles.mediaDetails]} testID='player_details'>
            {renderDetails()}
          </View>
        )
      }
      {shouldDisplayPlaybackControls &&
        (
          <NitroxInteractiveController omitGeometryCaching>
            <FocusParent enterStrategy='byPriority' focusPriority={focusPriority} rememberLastFocused style={styles.bigScreenContainer} onReady={focusOnReady ? onControlsReadyInternal : onReady}>
              <LiveTVOptions handlers={handlers} buttonsVisibility={buttonsVisibility} playbackError={playbackError} />
              <PlaybackControls isChannelBlocked={isChannelBlocked} handlers={handlers} buttonsVisibility={buttonsVisibility} tooltipsProps={tooltipsProps} paused={paused} rewindDirection={rewindDirection} playbackError={playbackError} />
              <PlaybackOptions handlers={handlers} buttonsVisibility={buttonsVisibility} playbackError={playbackError} />
            </FocusParent>
          </NitroxInteractiveController>
        )
      }
    </View>
  ) : shouldDisplayPlaybackControls ? (
    <View style={styles.container}>
      <View style={styles.topContainer}>
        <LiveTVOptions handlers={handlers} buttonsVisibility={buttonsVisibility} />
        {landscape && renderDetails &&
          (
            <View style={[styles.mediaDetails, topContainerLeftMargin]}>
              {renderDetails()}
            </View>
          )
        }
        <PlaybackOptions handlers={handlers} buttonsVisibility={buttonsVisibility} playbackError={playbackError} chromecastConnectionState={chromecastConnectionState} />
      </View>
      <PlaybackControls isChannelBlocked={isChannelBlocked} handlers={handlers} buttonsVisibility={buttonsVisibility} tooltipsProps={tooltipsProps} paused={paused} rewindDirection={rewindDirection} playbackError={playbackError} />
      <View style={styles.bottomContainer}>
        {!landscape && renderDetails &&
          (
            <View style={styles.mediaDetails}>
              {renderDetails(numberOfLines)}
            </View>
          )
        }
        <FullScreenControls handlers={handlers} buttonsVisibility={buttonsVisibility} />
      </View>
    </View>
  ) : null;
};

export default React.memo(PlayerControlsView);
