import {useEffect, useState} from 'react';

import {DateUtils} from 'common/DateUtils';

/**
 * Returns now date updated in constant intervals.
 * @param interval Defaults to 30 secs.
 */
export function useNowDate(interval = 30) {
  const [nowDate, setNowDate] = useState(new Date());
  useEffect(() => {
    const id = setInterval(() => setNowDate(new Date()), interval * DateUtils.msInSec);
    return () => clearInterval(id);
  }, [interval]);
  return nowDate;
}
