import {EventEmitter} from 'common/EventEmitter';
import {Log} from 'common/Log';

import {Media} from 'mw/api/Metadata';
import {Profile} from 'mw/api/Profile';

import {WatchListManager, WatchListEvent} from './WatchListManager';

const TAG = 'DisabledWatchListManager';

export class DisabledWatchListManager extends EventEmitter<WatchListEvent> implements WatchListManager {
  public constructor(profile: Profile) {
    super();
    Log.info(TAG, 'Watchlist feature is disabled for profile', profile);
  }

  public get watchList(): Media[] {
    return [];
  }

  public add(): Promise<void> {
    return Promise.resolve();
  }

  public remove(): Promise<void> {
    return Promise.resolve();
  }

  public isOnWatchList(): boolean {
    return false;
  }

  public refresh(): Promise<void> {
    return Promise.resolve();
  }
}
