import {createStyles} from 'common-styles';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions} from 'common/constants';
import {Log} from 'common/Log';

import {PinProtectionLevel} from 'mw/api/PinProtection';
import {PinState} from 'mw/api/Profile';
import {mw} from 'mw/MW';

import {IconType} from 'components/Icon';
import {useCurrentProfile, useNavigation} from 'hooks/Hooks';
import SettingsChangePin from 'screens/settings/SettingsChangePIN';
import SettingsDetails from 'screens/settings/SettingsDetails';
import {SettingsDetailsHeader} from 'screens/settings/SettingsDetailsHeader';
import SettingsTogglePINProtection from 'screens/settings/SettingsTogglePINProtection';

const TAG = 'SettingsPinScreen';

const styles = createStyles({
  container: {
    paddingTop: dimensions.screen.settings.container.paddingTop,
    paddingHorizontal: dimensions.screen.container.paddingHorizontal
  }
});

const SettingsPinScreen: React.FC = () => {
  const {t} = useTranslation();
  const profile = useCurrentProfile();
  const changePinVisible = mw.configuration.pinProtectionLevel === PinProtectionLevel.Profile || (mw.configuration.pinProtectionLevel === PinProtectionLevel.Account && mw.customer.currentProfile?.isMain);

  const setProfilePinState = useCallback(async (value: boolean) => {
    try {
      // This might only set pinState to required or not. No need to handle ProfilePinNotSet state
      profile && await profile.setPinState(value ? PinState.ProfilePinRequired : PinState.ProfilePinNotRequired);
    } catch (error) {
      Log.error(TAG, 'Set is pin required error', error);
    }
  }, [profile]);

  const onChangePin = useCallback(async pin => {
    try {
      if (profile) {
        await profile.setPin(pin);
        if (pin && profile.pinState === PinState.ProfilePinNotSet) {
          // If pin was not set, we want to change pinState to NoPinRequired. Otherwise we don't want to interfere
          setProfilePinState(false);
        }
      }
    } catch (error) {
      Log.error(TAG, 'Set new pin error', error);
    }
  }, [profile, setProfilePinState]);

  return (
    <SettingsDetails
      title={t('settings.myPin')}
      barText={t('settings.pinSettings')}
      header={<SettingsDetailsHeader icon={IconType.PinBig} />}
      navigation={useNavigation()}
      testID='screen_settings_pin'
    >
      <View style={styles.container}>
        {changePinVisible && (
          <SettingsChangePin
            title={t('settings.pinCreated')}
            pinSet={profile?.pinState !== PinState.ProfilePinNotSet}
            onPINChanged={onChangePin}
          />
        )}
        <SettingsTogglePINProtection
          pinSet={profile?.pinState !== PinState.ProfilePinNotSet}
          pinRequired={profile?.isPinRequired}
          onPINChanged={onChangePin}
          onProtectWithPINChanged={setProfilePinState}
        />
      </View>
    </SettingsDetails>
  );
};

export default SettingsPinScreen;
