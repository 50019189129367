import {fromEntries} from 'common/HelperFunctions';
import {useUpdater} from 'common/hooks/Hooks';

import {useEventListener} from 'hooks/Hooks';

/**
 * Returns url part after '#/' or empty string if no hash in url
 */
export function getHashPath() {
  const [, hash] = window.location.hash.match(/#\/(.*)/) ?? [];

  return hash ?? '';
}

export function useHashPath() {
  const {state: path, update: updatePath} = useUpdater(getHashPath);

  useEventListener('hashchange', updatePath, window);

  return path;
}

export enum KnownRoutes {
  ActivateAccount = 'account-activation'
}
export const knownRoutes = Object.values(KnownRoutes);

export function isKnownRoute(hashPath: string | KnownRoutes): hashPath is KnownRoutes {
  return knownRoutes.includes(hashPath as KnownRoutes);
}

export type KnownParams = {
  language?: string;
  accountId?: number;
  token?: string;
}

export function getQueryParams(): KnownParams {
  const {searchParams} = new URL(window.location.href.replace('#', ''));
  return fromEntries(searchParams.entries());
}
