import {PVRQuota} from 'mw/api/Metadata';
import {Customer} from 'mw/bo-proxy/types';

import {namedPropertiesFromJSON} from './MappersHelper';

const StylingName = 'StylingName';
const AcceptedEULAVersion = 'AcceptedEULAVersion';

export class CustomerMapper {

  public static fromJSON(json: any): Customer {
    const customer: Customer = {
      id: json.id,
      regionId: json.SimSubRegionId
    };

    const alias = json.Aliases && json.Aliases.Alias[0] && json.Aliases.Alias[0];
    if (alias.type === 'IngestId') {
      customer.externalId = alias.Value;
    }

    customer.defaultProfileId = json?.DefaultProfiles.Profile[0]?.id;
    customer.pvrQuota = CustomerMapper.pvrQuotaFromJSON(json);

    const namedProperties = namedPropertiesFromJSON(json);
    customer.stylingName = namedProperties[StylingName];
    customer.acceptedEULAVersion = namedProperties[AcceptedEULAVersion];

    return customer;
  }

  public static pvrQuotaFromJSON(json: any): PVRQuota {
    return {
      available: json.NpvrQuota || 0,
      remaining: json.NpvrQuotaRemaining || 0
    };
  }
}
