import {EventEmitter} from 'common/EventEmitter';

import {Notification} from './Notification';

export enum NotificationEvent {
  NotificationReceived = 'NotificationReceived'
}

export abstract class NotificationsManager extends EventEmitter<NotificationEvent, Notification> {
  public abstract start(): void;
  public abstract stop(): void;
}
