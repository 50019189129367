import {createStyles} from 'common-styles';
import React, {useCallback} from 'react';
import {StyleSheet} from 'react-native';

import {isAndroid} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import DateTimePicker from '@react-native-community/datetimepicker';
import {CalendarProps} from 'components/Calendar';

const staticStyles = createStyles({
  picker: {
    ...StyleSheet.absoluteFillObject
  }
});

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    backgroundColor: colors.popup.background
  },
  text: colors.popup.text
}));

type NativeCalendarProps = {
  /** Date changed but Calendar is still opened */
  onDateChange?: (newDate: Date) => void;
  /** Date was submitted by closing the Calendar */
  onDateSubmitted?: (newDate: Date) => void;
  /** Calendar was closed without selecting any date */
  onClose?: () => void;
} & CalendarProps;

const NativeCalendar: React.FC<NativeCalendarProps> = ({
  date = new Date(), maxDate, minDate, onDateChange, onDateSubmitted, onClose
}) => {
  const dynamicStyles = dynamicStylesUpdater.getStyles();

  const onChange = useCallback((event, newDate) => {
    if (newDate) {
      // on Android platforms date change event is fired only once after pressing Ok button
      (isAndroid ? onDateSubmitted : onDateChange)?.(new Date(newDate));
    } else if (event.type === 'dismissed') {
      onClose?.(); // only on Android platforms
    }
  }, [onDateSubmitted, onDateChange, onClose]);

  return (
    <DateTimePicker
      value={date}
      mode={'date'}
      maximumDate={maxDate}
      minimumDate={minDate}
      display={'calendar'} // Android only
      textColor={dynamicStyles.text} // iOS only
      style={[staticStyles.picker, dynamicStyles.container]} // iOS only
      onChange={onChange}
    />
  );
};

export default React.memo(NativeCalendar);
