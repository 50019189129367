import {createStyles} from 'common-styles';
import React, {Ref, useCallback, useMemo} from 'react';
import {GestureResponderEvent, View, ViewStyle, StyleProp} from 'react-native';

import {dimensions} from 'common/constants';
import {humanCaseToOneWord} from 'common/HelperFunctions';

import {constColors} from 'common-styles/variables/base-colors';

import {channelIconConstants, ChannelIconType} from 'components/ChannelIcon';
import {FocusableComponent} from 'components/focusManager/FocusManagerTypes';
import NitroxInteractive from 'components/NitroxInteractive';

import ChannelsListItemBase from './ChannelsListItemBase';
import {ChannelsListItemProps} from './ChannelsListItemProps';

const styles = createStyles({
  channelIconContainer: {
    width: channelIconConstants.channelList.scaledMobile,
    height: channelIconConstants.channelList.scaledMobile
  }
});

const ChannelsListItem: React.FC<ChannelsListItemProps> = (props, ref: Ref<FocusableComponent>) => {
  const {onPress: propsOnPress, iconType = ChannelIconType.ChannelListMobile, ...otherProps} = props;
  const onPress = useCallback((event?: GestureResponderEvent) => {
    propsOnPress?.(props.channel.id, event);
  }, [propsOnPress, props.channel]);

  const style: StyleProp<ViewStyle> = useMemo(() => {
    if (!props.isSelected) {
      return [styles.channelIconContainer];
    }

    return [styles.channelIconContainer, props.horizontal ? {marginHorizontal: dimensions.margins.small} : {marginVertical: dimensions.margins.small}];
  }, [props.horizontal, props.isSelected]);

  return (
    <NitroxInteractive
      ref={ref}
      activeOpacity={1}
      underlayColor={constColors.transparent}
      onPress={onPress}
      testID={`button_channel_${humanCaseToOneWord(props.channel.name)}`}
    >
      {/* This view is required to fix crash when displaying mobile application in web browser: https://github.com/facebook/react-native/issues/1040 */}
      <View>
        <ChannelsListItemBase
          style={style}
          iconType={iconType}
          {...otherProps}
        />
      </View>
    </NitroxInteractive>
  );
};

const MemoizedChannelListItem = React.memo(React.forwardRef<FocusableComponent, ChannelsListItemProps>(ChannelsListItem));
MemoizedChannelListItem.displayName = ChannelsListItem.name;
export default MemoizedChannelListItem;
