import {createStyles} from 'common-styles';
import React from 'react';
import {View, StyleProp, ViewStyle, TextStyle} from 'react-native';

import {RADIUS, dimensions} from 'common/constants';
import {getTime} from 'common/utils';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {mw} from 'mw/MW';

import NitroxText, {TextType} from 'components/NitroxText';

export enum FloatingBubbleType {
  Primary = 'primary', Secondary = 'secondary', PlayerButtonTooltip = 'playerButtonTooltip'
}

const getContainerHeight = (pointerSize: number, pointerDiagonal: number) => {
  return Math.round(pointerDiagonal - (pointerDiagonal - pointerSize) / 2 - RADIUS / 2 + dimensions.margins.small);
};

type BubbleType = {
  boxHeight: number;
  pointerSize: number;
  containerHeight: number;
  textType: TextType;
}

export const floatingBubbleLineWidth = 1;
export const FloatingBubbleTypes: {primary: BubbleType; secondary: BubbleType; playerButtonTooltip: BubbleType} = (() => {
  const primaryPointerSize = 24;
  const primaryPointerDiagonal = primaryPointerSize * Math.sqrt(2);
  const primaryTextType: TextType = 'callout';
  const secondaryTextType: TextType = 'epg-timebar';
  const playerButtonTooltipTextType: TextType = 'tooltip';
  return ({
    primary: {
      boxHeight: 30,
      pointerSize: primaryPointerSize,
      containerHeight: getContainerHeight(primaryPointerSize, primaryPointerDiagonal),
      textType: primaryTextType
    },
    secondary: {
      boxHeight: 20,
      pointerSize: 0,
      containerHeight: 0,
      textType: secondaryTextType
    },
    playerButtonTooltip: {
      boxHeight: 50,
      pointerSize: 50,
      containerHeight: getContainerHeight(primaryPointerSize, primaryPointerDiagonal),
      textType: playerButtonTooltipTextType
    }
  });
})();

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    position: 'absolute'
  },
  view: {
    alignItems: 'center'
  },
  pointer: {
    position: 'absolute',
    top: dimensions.margins.small,
    borderRadius: RADIUS,
    transform: [{rotate: '45deg'}]
  },
  pointerLine: {
    position: 'absolute',
    backgroundColor: colors.epgScreen.columnEpg.timeBubble.background,
    width: floatingBubbleLineWidth,
    zIndex: 1
  },
  box: {
    position: 'absolute',
    borderRadius: RADIUS,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5
  },
  text: {
    color: colors.epgScreen.columnEpg.timeBubble.text
  }
}));

type Props = {
  label?: string;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  type?: FloatingBubbleType;
  backgroundColor?: string;
  pointerLineHeight?: number;
  pointerLineWidth?: number;
  initialPositionX?: number;
}

type State = {
  time: string;
  positionX?: number;
}

class FloatingBubble extends React.PureComponent<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      time: '',
      positionX: this.props.initialPositionX
    };
  }

  public setTime(time: Date) {
    this.setState({time: getTime(time, mw.configuration.timeFormat)});
  }

  public setX(positionX: number) {
    this.setState({positionX});
  }

  private getBubbleType() {
    const type = this.props.type ?? FloatingBubbleType.Primary;
    return FloatingBubbleTypes[type];
  }

  private styles = stylesUpdater.getStyles();

  public render() {
    const {pointerLineHeight, pointerLineWidth, textStyle, type, style} = this.props;
    const positionX = this.state.positionX;
    const label = this.props.label || this.state.time;
    const {boxHeight, pointerSize, containerHeight, textType} = this.getBubbleType();
    const backgroundColor = this.props.backgroundColor ?? this.styles.pointerLine.backgroundColor;

    if (positionX === undefined) {
      return null;
    }

    return (
      <View style={[this.styles.container, {height: containerHeight + (pointerLineHeight ?? 0)}, {left: positionX}, style]} testID='timebar_bubble'>
        {!!label && (
          <View style={this.styles.view}>
            <View style={[this.styles.pointer, {width: pointerSize, height: pointerSize, backgroundColor}]} />
            {pointerLineHeight && (
              <View style={[
                this.styles.pointerLine,
                {
                  backgroundColor,
                  top: containerHeight,
                  height: pointerLineHeight,
                  width: pointerLineWidth ?? this.styles.pointerLine.width
                }]}
              />
            )}
            <View style={[
              this.styles.box,
              {
                backgroundColor,
                height: boxHeight,
                top: (type === FloatingBubbleType.Secondary && pointerLineHeight)
                  ? Math.floor((pointerLineHeight - boxHeight) / 2)
                  : 0
              }]}
            >
              <NitroxText style={[this.styles.text, textStyle]} textType={textType}>{label}</NitroxText>
            </View>
          </View>
        )}
      </View>
    );
  }
}

export default FloatingBubble;
