import {PlayerEvent} from 'mw/api/PlayerEvent';
import {PlayerType} from 'mw/playback/types/PlayerType';

import {WebPlaybackEvent} from './WebPlaybackEvent';

export class MediaEvent extends WebPlaybackEvent {

  public constructor(playerType: PlayerType, url: string, eventType: PlayerEvent, streamId: number) {
    super(eventType, playerType, streamId);

    (this as any)[eventType as string] = {
      url: url
    };
  }
}
