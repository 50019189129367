import {isTruthy} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {Error, ErrorType} from 'mw/api/Error';
import {ManagedBy} from 'mw/bo-proxy/SSOInterface';
import {DrmType} from 'mw/Constants';
import {DrmRequest, DrmResponse, DrmDomain} from 'mw/playback/web-native-player/nxpal/DrmInterceptor';
import {httpFetch, HttpMethods, HttpStatus} from 'mw/utils/HttpUtils';

import {DefaultDrmInterceptor} from './DefaultDrmInterceptor';

type ViuSKD = {
  KeyId: string,
  ContentRef: string
}

const TAG = 'FairPlayDrmInterceptor';

const utf8Decoder = new TextDecoder();

function extractSKD(data: Uint8Array): string {
  const skd = utf8Decoder
    .decode(data)
    .replace(/\u0000/g, '');
  return skd.substr(skd.indexOf('skd'));
}

function uintArrayToString(array: Uint8Array): string {
  let string = '';
  for (let i = 0; i < array.byteLength; ++i) {
    string += String.fromCharCode(array[i]);
  }
  return string;
}

function arrayBufferToBase64(buffer: ArrayBuffer): string {
  return btoa(uintArrayToString(new Uint8Array(buffer)));
}

function base64LicenseToUint8Array(license: string): Uint8Array {
  const rawLicense = atob(license);
  const array = new Uint8Array(new ArrayBuffer(rawLicense.length));
  for (let i = 0; i < rawLicense.length; ++i) {
    array[i] = rawLicense.charCodeAt(i);
  }
  return array;
}

function generateViuSPC(skd: string): string {
  let skdJSON, decodedSkd;
  try {
    decodedSkd = atob(skd);
    skdJSON = JSON.parse(decodedSkd);
  } catch (exception) {
    if (exception instanceof DOMException && exception.name === 'InvalidCharacterError') {
      throw new Error(ErrorType.DrmLicenseServerUnavailable, `SKD is not properly encoded Base64 string. ${skd}`);
    }
    if (exception instanceof SyntaxError) {
      throw new Error(ErrorType.DrmLicenseServerUnavailable, `Decoded SKD is not proper JSON string. ${decodedSkd}`);
    }
    throw new Error(ErrorType.DrmLicenseServerUnavailable, `Unknown error while parsing skd=${skd}. ${exception}`);
  }
  if (!skdJSON?.hasOwnProperty('ContentRef') || !skdJSON?.hasOwnProperty('KeyId')) {
    throw new Error(ErrorType.DrmLicenseServerUnavailable, `Decoded SKD does not contain required fields. ${decodedSkd}`);
  }
  return JSON.stringify({contentRef: skdJSON.ContentRef, keyId: skdJSON.KeyId});
}

export class FairPlayDrmInterceptor extends DefaultDrmInterceptor {
  public constructor() {
    super(DrmType.FairPlay);
  }

  public onDrmRequest(): DrmRequest {
    return {
      keySystems: {
        [DrmDomain.FairPlay]: {
          getCertificate: (_: unknown, callback: (error: Error | null, cert?: Uint8Array) => void) => {
            const cert = this.drmProperties?.fairplayCertificate;
            if (cert instanceof Uint8Array) {
              callback(null, cert);
            } else {
              callback(new Error(ErrorType.NotConfigured, 'Lack of FairPlay certificate!'));
            }
          },
          getContentId: (_: unknown, initData: Uint8Array) => extractSKD(initData),
          getLicense: (_: unknown, skd: string, keyMessage: ArrayBuffer, callback: (error: Error | null, license?: Uint8Array) => void) => {
            const url = this.drmProperties?.fairplayLicenseUrl;
            if (!url) {
              callback(new Error(ErrorType.NotConfigured, 'FairplayLicenseUrl empty!'));
              return;
            }
            const spc = encodeURIComponent(arrayBufferToBase64(keyMessage));
            const assetId = this.drmProperties?.managedBy === ManagedBy.Viu ? encodeURIComponent(generateViuSPC(skd)) : '';
            const body = [
              `spc=${spc}`,
              assetId ? `assetId=${assetId}` : null
            ].filter(isTruthy).join('&');
            httpFetch(url, {
              method: HttpMethods.POST,
              headers: {
                'Content-type': 'application/x-www-form-urlencoded',
                ...this.drmHeaders,
                skd
              },
              body: body
            })
              .then(response => response.status === HttpStatus.Ok
                ? response.text()
                : Promise.reject(new Error(ErrorType.DrmLicenseServerError, `getLicense failed: ${response.status} ${response.statusText}`))
              )
              .then(base64LicenseToUint8Array)
              .then(license => callback(null, license))
              .catch(error => {
                Log.error(TAG, 'Error fetching license from drm proxy:', error);
                if (error.type === ErrorType.HttpTimeout) {
                  callback(new Error(ErrorType.NativePlayerDrmLicenseAcquisitionTimeout));
                } else if (error.type === ErrorType.NetworkRequestFailed) {
                  callback(new Error(ErrorType.NativePlayerDrmLicenseAcquisitionConnectionRefused));
                } else {
                  callback(error);
                }
              });
          }
        }
      }
    };
  }

  public onDrmResponse(response: DrmResponse): void {}
}
