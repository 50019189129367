import React, {forwardRef, useMemo} from 'react';

import {DateUtils} from 'common/DateUtils';
import {getMediaTitle, isTruthy} from 'common/HelperFunctions';
import {getEpisodeAndSeasonNumber, getEpisodeTitle, getFormattedDuration, GetEpisodeAndSeasonNumberOptions, getSeriesName, getStartHour} from 'common/utils';

import {Recording} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import MediaTileBase from 'components/mediaTiles/MediaTileBase';
import {MediaTileBaseProps, MediaTileConfig, MediaTileType} from 'components/mediaTiles/MediaTileHooks';

const episodeAndSeasonNumberOptions: GetEpisodeAndSeasonNumberOptions = {
  padLength: 2,
  separator: '',
  showFullText: false
};

const getRecordingTileConfig = (recording: Recording): MediaTileConfig => {
  const subtitleList = [];

  subtitleList.push(getFormattedDuration(recording));
  subtitleList.push(getStartHour(recording, mw.configuration.timeFormat));
  recording.event && subtitleList.push(DateUtils.formatDate(recording.event.start, mw.configuration.dateFormat));
  subtitleList.push(getEpisodeAndSeasonNumber(recording, episodeAndSeasonNumberOptions));
  subtitleList.push(getSeriesName(recording));

  return {
    infoSection: subtitleList.filter(isTruthy).join(', '),
    title: getEpisodeTitle(recording) ?? getMediaTitle(recording),
    hideProgress: true
  };
};

type Props = {
  recording: Recording;
} & Omit<MediaTileBaseProps, 'media'>

const RecordingTile: React.FC<Props> = (props, ref) => {
  const {recording, config: propsConfig, tileType = MediaTileType.Episode, ...baseProps} = props;
  const tileConfig = useMemo(() => getRecordingTileConfig(recording), [recording]);

  return (
    <MediaTileBase
      {...baseProps}
      ref={ref}
      media={recording}
      config={propsConfig ?? tileConfig}
      tileType={tileType}
    />
  );
};

export default React.memo(forwardRef(RecordingTile));
