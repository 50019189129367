import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ImageSourcePropType, View, StyleSheet} from 'react-native';

import {dimensions, RADIUS, isTablet, featureFlags} from 'common/constants';
import {getLongestSynopsis, getRatingToDisplay, isSeriesEpisode} from 'common/HelperFunctions';
import {getFormattedDuration} from 'common/utils';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Title, PictureType, PictureMode} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import ExpandableText from 'components/ExpandableText';
import {Icon, IconType} from 'components/Icon';
import {useShouldDisplayNotEntitledLabel} from 'components/mediadetails/MediaInfoView.shared';
import NotEntitledLabel from 'components/mediadetails/NotEntitledLabel';
import MediaPicture from 'components/MediaPicture';
import MiniProgressBar from 'components/MiniProgressBar';
import NitroxInteractive from 'components/NitroxInteractive';
import NitroxText from 'components/NitroxText';
import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';
import PlayIcon from 'components/PlayIcon';
import {useScreenInfo} from 'hooks/Hooks';

const horizontalPadding = isTablet ? dimensions.margins.xxLarge : dimensions.margins.large;
const episodePosterSize = dimensions.pictures.mobileTile.series;
const playIconSize = dimensions.tile.iconHeight / (isTablet ? 1.9 : 2.5);
const episodeTitleNumberOfLines = isTablet ? 1 : 2;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  innerContainer: {
    flexDirection: 'row',
    paddingTop: dimensions.margins.medium,
    paddingHorizontal: horizontalPadding
  },
  posterPlaceholder: {
    width: episodePosterSize.width,
    height: episodePosterSize.height,
    borderRadius: RADIUS,
    justifyContent: 'center',
    overflow: 'hidden',
    backgroundColor: colors.tile.poster.placeholder.background
  },
  posterImage: {
    ...StyleSheet.absoluteFillObject
  },
  noPosterIcon: {
    alignItems: 'center'
  },
  infoAndActionsContainer: {
    alignItems: 'flex-start',
    paddingLeft: dimensions.margins.small,
    flexGrow: 1,
    flexShrink: 1
  },
  titleContainer: {
    marginRight: dimensions.icon.small
  },
  tabletPortraitMarginRight: {
    marginRight: dimensions.icon.medium - dimensions.margins.xsmall
  },
  tabletLandscapeMarginRight: {
    marginRight: dimensions.icon.medium + dimensions.margins.xsmall
  },
  titleText: {
    color: colors.columnTile.title
  },
  episodeInfoText: {
    color: colors.columnTile.subtitle
  },
  moreActions: {
    width: isTablet ? dimensions.icon.medium : dimensions.icon.small,
    marginLeft: 'auto',
    paddingTop: dimensions.margins.small,
    justifyContent: 'center',
    alignItems: 'center'
  },
  titleColumnContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start'
  },
  progressBar: {
    marginTop: isTablet ? 3 : 'auto'
  },
  episodeSynopsisText: {
    color: colors.columnTile.subtitle,
    paddingTop: isTablet ? 3 : dimensions.margins.small,
    paddingBottom: dimensions.margins.small,
    paddingHorizontal: isTablet ? 0 : horizontalPadding
  },
  componentBackgroundColor: colors.mediaDetailsScreen.content.background,
  moreActionsButtonColor: colors.mediaDetailsScreen.content.background
}));

type Props = {
  media: Title;
  onPosterPress?: () => void;
  onMoreActionsPress?: () => void;
};

const EpisodeTile: React.FunctionComponent<Props> = props => {
  const [mediaPictureSource, setMediaPictureSource] = useState<ImageSourcePropType | null>(null);
  const {orientation} = useScreenInfo();
  const {t} = useTranslation();
  const {media, onPosterPress, onMoreActionsPress} = props;
  const {isMediaBlocked} = useParentalControl();
  const isBlocked = isMediaBlocked(media);

  const episodeTitle = media.episode && isSeriesEpisode(media) ? media.episode.title : '';
  const episodeSynopsis = getLongestSynopsis(media.metadata);
  const episodeInfo = useMemo(() => {
    const values: string[] = [];
    const parentalControlCode = getRatingToDisplay(media);
    const duration = getFormattedDuration(media);
    if (parentalControlCode) {
      values.push(parentalControlCode);
    }

    if (media.episode && media.episode.number) {
      values.push(t('mediaDetails.episodeNumberFull', {episodeNumber: media.episode.number}));
    }

    if (duration) {
      values.push(duration);
    }

    return values.join(', ');
  }, [t, media]);

  const styles = stylesUpdater.getStyles();
  const orientationBasedMarginStyle = isTablet ? (orientation.isLandscape ? styles.tabletLandscapeMarginRight : styles.tabletPortraitMarginRight) : {};

  useEffect(() => {
    const uri = mw.catalog.getPictureUrl(media, PictureType.Tile, episodePosterSize.width, episodePosterSize.height, PictureMode.BOX);
    const pictureSourceUri = uri ? {uri} : null;
    setMediaPictureSource(pictureSourceUri);
  }, [media]);

  return (
    <View>
      <View style={styles.innerContainer}>
        <View style={styles.posterPlaceholder}>
          <MediaPicture source={mediaPictureSource} mediaType={media.getType()} iconSize={episodePosterSize.height} isBlocked={isBlocked} />
          {media.isPlayAllowed() && <PlayIcon onPress={onPosterPress} iconSize={playIconSize} />}
        </View>
        <View style={styles.infoAndActionsContainer}>
          <View style={styles.titleColumnContainer}>
            <View style={[styles.titleContainer, orientationBasedMarginStyle]}>
              <NitroxText textType='title2' style={styles.titleText} numberOfLines={episodeTitleNumberOfLines} ellipsizeMode='tail'>{episodeTitle}</NitroxText>
              <NitroxText textType='callout-small' style={styles.episodeInfoText} numberOfLines={1} ellipsizeMode='tail'>{episodeInfo}</NitroxText>
            </View>
            {featureFlags.episodeTileMoreInfoButton && (
              <NitroxInteractive
                style={styles.moreActions}
                onPress={onMoreActionsPress}
                testID='button_more_actions'
              >
                <Icon
                  type={IconType.MoreActions}
                  size={dimensions.icon.xxsmall}
                  color={styles.moreActionsButtonColor}
                />
              </NitroxInteractive>
            )}
          </View>
          <MiniProgressBar progress={media.progress} style={[styles.progressBar, orientationBasedMarginStyle]} />
          {useShouldDisplayNotEntitledLabel(media) && <NotEntitledLabel />}
          {isTablet && !isBlocked && <ExpandableText fadeColor={styles.componentBackgroundColor} numberOfLines={3} textType='body1' style={[styles.episodeSynopsisText, orientationBasedMarginStyle]}>{episodeSynopsis}</ExpandableText>}
        </View>
      </View>
      {!isTablet && !isBlocked && <ExpandableText fadeColor={styles.componentBackgroundColor} numberOfLines={3} textType='body1' style={styles.episodeSynopsisText}>{episodeSynopsis}</ExpandableText>}
    </View>
  );
};

export default EpisodeTile;
