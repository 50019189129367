export async function* asyncIterator<T>(array: T[]): AsyncIterableIterator<T[]> {
  return array;
}

/**
 * maps an array, using a closure that can return an optional value and filters the optionals afterwards
 */
export function compactMap<T, U>(array: T[], transform: (element: T) => U | null | undefined): U[] {
  const result: U[] = [];
  array.forEach(element => {
    const mappedElement = transform(element);
    if (mappedElement !== null && typeof mappedElement !== 'undefined') {
      result.push(mappedElement);
    }
  });
  return result;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function compareArrays(...arrays: (readonly any[])[]) {
  const sameLengths = !arrays
    .some((leftArray, leftArrayIndex) =>
      arrays
        .slice(leftArrayIndex)
        .some(rightArray => rightArray.length !== leftArray.length)
    );
  if (!sameLengths) {
    return false;
  }

  return !arrays
    .some((leftArray, leftArrayIndex) =>
      leftArray.some((leftValue, leftValueIndex) =>
        arrays
          .slice(leftArrayIndex)
          .some(rightArray => rightArray[leftValueIndex] !== leftValue)
      )
    );
}

export function flatten<T>(arrayOfArrays: T[][], initialArray: T[] = []): T[] {
  return arrayOfArrays.reduce((result, array) => {
    result.push(...array);
    return result;
  }, initialArray);
}

export function maxBy<T>(array: T[], compare: (lhs: T, rhs: T) => boolean): T | undefined {
  if (!array.length) {
    return;
  }
  let index = 0;
  let max = array[index];
  while (++index < array.length) {
    if (compare(array[index], max)) {
      max = array[index];
    }
  }
  return max;
}

export function shuffle<T>(items: T[]) {
  for (let i = items.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [items[i], items[j]] = [items[j], items[i]];
  }
  return items;
}

export interface SplitElement<T> {
  [key: string]: T | undefined;
}

export interface SplitHash<T> {
  [key: string]: T[] | undefined;
}

export interface SplitSelection<T> {
  (input: T): SplitElement<T>;
}

export function split<T>(array: T[], selection: SplitSelection<T>): SplitHash<T> {
  const initialValue: SplitHash<T> = {};
  array.reduce((previousValue, currentValue: T) => {
    const result = selection(currentValue);
    for (const key in result) {
      const keyResult = result[key];
      if (!keyResult) {
        continue;
      }

      const previousKeyValue = previousValue[key] || [];
      previousKeyValue.push(keyResult);
      previousValue[key] = previousKeyValue;
    }
    return previousValue;
  }, initialValue);

  return initialValue;
}

/**
 * Returns an array with duplicate elements filtered out.
 * @param array Array to be filtered.
 * @param key Optional key for which equality must be checked to determine uniqueness
 */
export function unique<T>(array: T[], key?: keyof T): T[] {
  const uniques: T[] = [];
  const uniqueIds = new Set();
  array.forEach(value => {
    const id = key ? value[key] : value;
    if (!uniqueIds.has(id)) {
      uniqueIds.add(id);
      uniques.push(value);
    }
  });
  return uniques;
}
