import Color from 'color';
import {createStyles} from 'common-styles';
import React from 'react';
import {View, StyleSheet} from 'react-native';

import {dimensions, RADIUS} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';

import {Channel} from 'mw/api/Metadata';

import {ChannelIcon, ChannelIconType, channelIconConstants} from 'components/ChannelIcon';
import ChannelNumber, {channelNumberSize} from 'components/ChannelNumber';
import Flipper from 'components/Flipper';
import GradientFill from 'components/GradientFill';
import NitroxText from 'components/NitroxText';

const styles = createStyles({
  container: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    height: channelIconConstants.zapper.size,
    width: channelIconConstants.zapper.size
  },
  channelIcon: {
    marginRight: dimensions.margins.xxxLarge,
    alignSelf: 'stretch',
    alignItems: 'center',
    height: channelIconConstants.zapper.size,
    width: channelIconConstants.zapper.size
  },
  channelNumber: {
    position: 'absolute',
    bottom: -Math.floor(channelNumberSize / 2)
  }
});

const dynamicStyles = new StylesUpdater(colors => createStyles({
  inputContainer: {
    backgroundColor: colors.channelIcon.background.default,
    borderRadius: RADIUS,
    justifyContent: 'center'
  },
  channelNumberLabel: {
    color: colors.tvScreen.channelNumberInput
  }
}));

const gradientUpdater = new StylesUpdater(colors => ({
  gradientColors: [
    Color(colors.tvScreen.overlay.shade).alpha(0)
      .toString(),
    colors.tvScreen.overlay.shade
  ]
}));

type Props = {
  channelNumberString: string;
  onFinishedTransition?: (showsChannelIcon: boolean) => void;
  channel?: Channel;
}

const ChannelNumberInputOverlay: React.FC<Props> = ({
  channelNumberString,
  onFinishedTransition,
  channel
}) => {
  const {inputContainer, channelNumberLabel} = dynamicStyles.getStyles();
  const {gradientColors} = gradientUpdater.getStyles();

  return (
    <View style={StyleSheet.absoluteFill}>
      <GradientFill
        colors={gradientColors}
      />
      <Flipper
        style={styles.container}
        showsFront={!channelNumberString}
        onChangedSide={onFinishedTransition}
        animated
      >
        <ChannelIcon
          type={ChannelIconType.Zapper}
          channelId={channel?.id}
          style={styles.channelIcon}
        >
          {channel && <ChannelNumber channel={channel} styles={styles.channelNumber} />}
        </ChannelIcon>
        <View style={[styles.channelIcon, inputContainer]}>
          <NitroxText
            style={channelNumberLabel}
            textType='headline'
          >
            {channelNumberString}
          </NitroxText>
        </View>
      </Flipper>
    </View>
  );
};

export default ChannelNumberInputOverlay;
