import {createStyles} from 'common-styles';
import React from 'react';
import {View, ViewStyle, StyleProp} from 'react-native';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  defaultTopStyle: {
    backgroundColor: colors.doubleBackground.top,
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30
  },
  defaultBottomStyle: {
    backgroundColor: colors.doubleBackground.bottom
  }
}));

export type DoubleBackgroundProps = {
  topChild?: React.ReactNode;
  bottomChild?: React.ReactNode;
  topStyle?: StyleProp<ViewStyle>;
  bottomStyle?: StyleProp<ViewStyle>;
  overlap?: number;
}

const DoubleBackground: React.FunctionComponent<DoubleBackgroundProps> = props => {

  const overlapStyle: ViewStyle = {
    marginBottom: typeof props.overlap === 'number' ? -props.overlap : 0,
    paddingBottom: typeof props.overlap === 'number' ? props.overlap : 0
  };

  const styles = stylesUpdater.getStyles();

  return (
    <>
      <View style={[styles.defaultTopStyle, props.topStyle, overlapStyle]}>
        {props.topChild}
      </View>
      <View style={[styles.defaultBottomStyle, props.bottomStyle]}>
        {props.bottomChild}
      </View>
    </>
  );
};

export default DoubleBackground;
