const performanceConstants = {
  interactionHandle: {
    /**
     * Period (ms) "reserved" by scroll event. Queued post-interactions tasks are blocked until elapsed.
     */
    scrollDelay: 500
  },
  postInteractionQueue: {
    /**
     * When queued tasks operation time exceeds deadline, rest of them are postponed.
     * The bigger value is, the faster heavy operations handling (see EPG new tiles layouting - via NitroxContentView).
     * Smaller value throttles heavy operations, which result in better UX-wise interactions, but slower queued task processing.
     * This should never exceed 100ms, basically anything blocking over 100ms is noticeable by user.
     */
    deadline: 100
  }
};

export default performanceConstants;
