import {DateUtils} from 'common/DateUtils';
import {EventEmitter} from 'common/EventEmitter';
import {Log} from 'common/Log';

const TAG = 'Updater';

const minUpdateDelayInS = 5;
const maxUpdateDelayInS = DateUtils.sInHour;

export abstract class Updater<T, R, U> extends EventEmitter<T, R> {
  private timestamp: Date | null = null;
  private requestsCount = 0;
  protected updateInProgress: Promise<void> | null = null;

  protected abstract updateData(data?: U): Promise<void>;
  protected abstract handleError(error: any): void;

  private onEnd = (): void => {
    this.requestsCount = 0;
    this.timestamp = null;
  }

  private onError = (error: any): void => {
    const delay = Math.min(maxUpdateDelayInS, Math.pow(this.requestsCount++, 2) * DateUtils.sInMin + minUpdateDelayInS);
    this.timestamp = DateUtils.addSeconds(new Date(), delay);
    Log.error(TAG, 'updateData failed', error, `delay:`, delay);

    this.handleError(error);
  }

  public update(data?: U): Promise<void> {
    if (this.updateInProgress) {
      return this.updateInProgress.finally(() => this.update(data));
    }
    this.updateInProgress = new Promise<void>(resolve => {
      this.updateData(data)
        .then(this.onEnd)
        .catch(this.onError)
        .finally(() => {
          this.updateInProgress = null;
          resolve();
        });
    });
    return this.updateInProgress;
  }

  public canUpdate = (): boolean => {
    if (!!this.updateInProgress) {
      Log.debug(TAG, 'canUpdate: blocked because update in progress');
      return false;
    }

    if (this.timestamp != null && new Date() < this.timestamp) {
      Log.debug(TAG, 'canUpdate: update delayed until', this.timestamp);
      return false;
    }

    return true;
  }

  public release = (): void => {
    this.clear();
    this.requestsCount = 0;
    this.timestamp = null;
  }

  public getUpdateInProgress(): Promise<void> | null {
    return this.updateInProgress;
  }
}
