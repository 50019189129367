import {Order, OrderStatus} from 'mw/api/Metadata';

export class OrderObject implements Order {
  public readonly id: string;
  public readonly productId: string;
  public readonly mediaId: string | undefined;
  public readonly currency: string;
  public readonly paymentMethodId: string;
  public readonly price: number;
  public readonly creationDate: Date;
  public readonly orderStatus: OrderStatus = OrderStatus.Unpaid;
  public readonly productName: string;

  public constructor(id: string, productId: string, paymentMethodId: string, currency: string, price: number, creationDate: Date, orderStatus: OrderStatus, productName: string, mediaId?: string) {
    this.id = id;
    this.productId = productId;
    this.mediaId = mediaId;
    this.currency = currency;
    this.paymentMethodId = paymentMethodId;
    this.price = price;
    this.creationDate = creationDate;
    this.productName = productName;
    this.orderStatus = orderStatus;
  }
}
