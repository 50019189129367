import {createStyles} from 'common-styles';
import React, {memo, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Linking, View} from 'react-native';

import {dimensions, getValue, isMobile, isWeb} from 'common/constants';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxButton from 'components/NitroxButton';
import NitroxText from 'components/NitroxText';
import Popup from 'components/Popup';

const TAG = 'ForgotCredentials';

const styles = createStyles({
  containerStyle: {
    width: isMobile ? '100%' : 1120
  },
  content: {
    alignItems: 'center',
    marginBottom: getValue({mobile: 3 * dimensions.margins.medium, defaultValue: 2 * dimensions.margins.xxLarge}),
    marginTop: getValue({mobile: dimensions.margins.medium, defaultValue: 3 * dimensions.margins.medium})
  },
  title: {
    marginBottom: dimensions.margins.medium
  },
  description: {
    marginBottom: dimensions.margins.xLarge
  }
});

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  text: {
    color: colors.forgotCredentials.text,
    textAlign: 'center'
  },
  linkWrapper: {
    paddingHorizontal: getValue({mobile: dimensions.margins.medium, tablet: 36, defaultValue: 3 * dimensions.margins.medium}),
    paddingVertical: dimensions.margins.medium,
    backgroundColor: colors.forgotCredentials.textBackground,
    height: 'auto'
  }
}));

type ForgotCredentialsProps = {
  link: string;
  onClose: () => void;
  visible: boolean;
  title: string;
  message: string;
}

const ForgotCredentials = ({onClose, link, message, title, visible}: ForgotCredentialsProps) => {
  const {t} = useTranslation();
  const dynamicStyles = dynamicStylesUpdater.getStyles();
  const onPress = useCallback(() => {
    Linking.openURL(link)
      .catch(error => Log.error(TAG, `Error opening link '${link}'`, error));
  }, [link]);
  const buttonContent = useMemo(() => <NitroxText style={dynamicStyles.text} textType='dialog-message-bold'>{link}</NitroxText>, [dynamicStyles.text, link]);

  return (
    <Popup
      containerStyle={styles.containerStyle}
      visible={visible}
      onClose={onClose}
      negativeLabel={t('common.close')}
    >
      <View style={styles.content}>
        <NitroxText style={[styles.title, dynamicStyles.text]} textType='dialog-title'>{title}</NitroxText>
        <NitroxText style={[styles.description, dynamicStyles.text]} textType='dialog-message'>{message}</NitroxText>
        {isWeb || isMobile ? (
          <NitroxButton onPress={onPress} style={dynamicStyles.linkWrapper}>
            {buttonContent}
          </NitroxButton>
        ) : (
          <View style={dynamicStyles.linkWrapper}>
            {buttonContent}
          </View>
        )}
      </View>
    </Popup>
  );
};

export default memo(ForgotCredentials);
