import {createStyles} from 'common-styles';
import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {isBigScreen, dimensions, isPhone, isMobile} from 'common/constants';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {CustomerEvent} from 'mw/api/Customer';
import {ErrorType} from 'mw/api/Error';
import {Consent} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import ButtonsRow from 'components/ButtonsRow';
import ErrorPopup, {useErrorPopup} from 'components/ErrorPopup';
import InteractiveScrollView from 'components/InteractiveScrollView';
import {NitroxButtonProps} from 'components/NitroxButton';
import NitroxText from 'components/NitroxText';
import {useFunction} from 'hooks/Hooks';
import {PostProcessors} from 'locales/i18nPostProcessors';

const TAG = 'EulaScreen';

const buttonWidth = 250;
const buttonsRowColumnsCount = isPhone ? 1 : 2;

const styles = createStyles({
  scrollView: {
    flex: 1,
    marginTop: isBigScreen
      ? dimensions.margins.xxxxLarge
      : dimensions.margins.xxLarge
  },
  buttonsRow: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: dimensions.margins.xxxxLarge,
    marginBottom: isBigScreen ? dimensions.margins.xxxLarge : 0
  }
});

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: colors.popup.background,
    paddingTop: dimensions.margins.xxxxLarge,
    paddingBottom: isBigScreen
      ? dimensions.margins.xxxxLarge * 2
      : dimensions.margins.xxxxLarge + dimensions.margins.medium,
    paddingHorizontal: isBigScreen
      ? dimensions.margins.xxxxLarge * 2
      : dimensions.margins.xxLarge
  },
  text: {
    color: colors.defaultColors.icon
  }
}));

type Props = {
  eulaConsent: Consent;
  onConsentAccepted: () => void;
  onConsentRejected: () => void;
}

const EulaScreen: React.FC<Props> = props => {
  const {t} = useTranslation();

  const {eulaConsent: {name, version, consent}, onConsentAccepted, onConsentRejected} = props;
  const [focusHoldersCount, setFocusHoldersCount] = useState<number | null>(null);
  const {error, showError, onCloseErrorPopup} = useErrorPopup();

  const convertErrorTypeToMessage = useFunction((errorType: ErrorType) => {
    switch (errorType) {
      case ErrorType.BOInvalidAuthentication:
      case ErrorType.BOInvalidCustomerID:
      case ErrorType.DeviceUnregistered:
        return t('common.unexpectedAuthError', {errorCode: errorType});
      case ErrorType.NetworkNoConnection:
        return t('common.noNetworkError');
      case ErrorType.HttpTimeout:
        return t('common.httpTimeoutError');
      case ErrorType.NetworkRequestFailed:
      case ErrorType.CustomerUpdateFailure:
      default:
        return t('common.unexpectedError', {errorCode: errorType});
    }
  });

  const onAccept = useCallback(() => {
    Log.debug(TAG, `Accepted EULA, version: ${version}, moving forward`);
    mw.customer.once(CustomerEvent.EULAConsentAccepted, onConsentAccepted);
    mw.customer.setConsent(name, version, true).catch((e) => {
      showError(convertErrorTypeToMessage(e?.type));
    });
  }, [version, onConsentAccepted, name, showError, convertErrorTypeToMessage]);

  const onCancel = useCallback(() => {
    Log.warn(TAG, `Rejected EULA, version: ${version}, logging out`);
    mw.bo.logout();
    onConsentRejected();
  }, [version, onConsentRejected]);

  const onClosePopup = useCallback(() => {
    Log.debug(TAG, `Error occured, logging out`);
    onCloseErrorPopup();
    mw.bo.logout();
  }, [onCloseErrorPopup]);

  const dynamicStyles = dynamicStylesUpdater.getStyles();

  const buttonsData = useMemo(() => {
    const data: NitroxButtonProps[] = [{
      hasTvPreferredFocus: focusHoldersCount === 0,
      border: isBigScreen,
      text: t('cms.eula.accept', {postProcess: PostProcessors.ToUpperCase}),
      onPress: onAccept
    },
    {
      border: true,
      text: t('cms.eula.cancel', {postProcess: PostProcessors.ToUpperCase}),
      textStyle: isMobile ? dynamicStyles.text : {},
      onPress: onCancel
    }];
    return isPhone ? data : data.reverse();
  }, [focusHoldersCount, onAccept, onCancel, dynamicStyles.text, t]);

  return (
    <View style={dynamicStyles.container}>
      <NitroxText style={dynamicStyles.text} textType={'headline'}>{t('cms.eula.title', {postProcess: PostProcessors.ToUpperCase})}</NitroxText>
      <InteractiveScrollView
        style={styles.scrollView}
        hasTVPreferredFocus={!!focusHoldersCount}
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
        indicatorStyle={'white'}
        onFocusHoldersCount={setFocusHoldersCount}
      >
        <NitroxText style={dynamicStyles.text} textType={'body'}>{consent}</NitroxText>
        <ButtonsRow
          containerStyle={styles.buttonsRow}
          data={buttonsData}
          columns={buttonsRowColumnsCount}
          buttonWidth={buttonWidth}
          xMargin={dimensions.margins.xxLarge}
          yMargin={dimensions.margins.large}
        />
      </InteractiveScrollView>
      <ErrorPopup
        error={error}
        onClose={onClosePopup}
      />
    </View>
  );
};

export default EulaScreen;
