import React from 'react';
import {useTranslation} from 'react-i18next';

import DeviceRegistrationErrorPopup from './DeviceRegistrationErrorPopup';

type Props = {
  visible: boolean;
  logout: () => void;
};

const TooManyDeviceSwapsPopup: React.FunctionComponent<Props> = props => {
  const {t} = useTranslation();
  return (
    <DeviceRegistrationErrorPopup
      title={t('tooManyDeviceSwapsPopup.title')}
      message={t('tooManyDeviceSwapsPopup.message')}
      {...props}
    />
  );
};

export default React.memo(TooManyDeviceSwapsPopup);
