import {createStyles} from 'common-styles';
import React from 'react';
import {View} from 'react-native';

import {StylesUpdater} from 'common-styles/StylesUpdater';

const lineWidth = 2;

const stylesUpdater = new StylesUpdater(colors => createStyles({
  container: {
    backgroundColor: colors.epgScreen.grid.verticalTimeIndicator.line,
    flexDirection: 'row',
    width: lineWidth,
    height: '100%'
  }
}));

type NowLineProps = {
  nowDate: Date;
  offsetForDate: (date: Date) => number;
}

const NowLine: React.FC<NowLineProps> = React.memo(({
  nowDate,
  offsetForDate
}) => {
  const styles = stylesUpdater.getStyles();
  return (
    <View
      style={[styles.container, {left: offsetForDate(nowDate)}]}
      pointerEvents='none'
    />
  );
});
NowLine.displayName = 'NowLine';

export default NowLine;
