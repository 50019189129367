import React, {useState, useMemo, useEffect, useCallback} from 'react';

import {DateUtils} from 'common/DateUtils';
import {doNothing} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {Media, ChromecastConnectionState} from 'mw/api/Metadata';
import chromecastEventEmitter from 'mw/platform/chromecast/ChromecastEventEmitter';
import {ChromecastDevice, ChromecastEvent, PlaybackFinishedReason, isChromecastSupported, ChromecastMedia} from 'mw/platform/chromecast/ChromecastInterface';
import {ChromecastModule} from 'mw/platform/chromecast/ChromecastModule';
import {getMediaByChromecastMedia} from 'mw/platform/chromecast/ChromecastUtils';

import {useDisposable, useToggle, useEventListener} from 'hooks/Hooks';

const TAG = 'ChromecastContextProvider';

// value used as interval, should be in ms
const deviceRefreshInterval = 3 * DateUtils.msInMin;
const chromecastConnectedStates = [ChromecastConnectionState.Connected, ChromecastConnectionState.MediaConnected, ChromecastConnectionState.MediaConnecting];

export type ChromecastContextType = {
  media?: Media;
  setMediaContext: (media?: Media) => void;
  availableDevices: ChromecastDevice[];
  isDeviceListPopupVisible: boolean;
  showDeviceListPopup: () => void;
  hideDeviceListPopup: () => void;
  isDisconnectPopupVisible: boolean;
  showDisconnectPopup: () => void;
  hideDisconnectPopup: () => void;
  connectionState: ChromecastConnectionState;
  castAfterConnecting: (position: number) => void;
  clearCastAfterConnectingRequest: () => void;
  currentDevice: ChromecastDevice | null;
  castMedia: (media: Media, position: number) => void;
  isPaused: boolean;
  isChromecastConnected: boolean;
  stopCastingMedia: () => void;
}

export const ChromecastContext = React.createContext<ChromecastContextType>({
  media: undefined,
  setMediaContext: (media?: Media) => {},
  availableDevices: [],
  isDeviceListPopupVisible: false,
  showDeviceListPopup: doNothing,
  hideDeviceListPopup: doNothing,
  isDisconnectPopupVisible: false,
  showDisconnectPopup: doNothing,
  hideDisconnectPopup: doNothing,
  connectionState: ChromecastConnectionState.Disconnected,
  castAfterConnecting: (position: number) => {},
  clearCastAfterConnectingRequest: doNothing,
  currentDevice: null,
  castMedia: (media: Media, position: number) => {},
  isPaused: false,
  isChromecastConnected: false,
  stopCastingMedia: doNothing
});

const ChromecastContextProviderComponent: React.FC = ({children}) => {
  const [connectionState, setConnectionState] = useState<ChromecastConnectionState>(ChromecastConnectionState.Disconnected);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [availableDevices, setAvailableDevices] = useState<ChromecastDevice[]>([]);
  const [currentDevice, setCurrentDevice] = useState<ChromecastDevice | null>(null);
  const [currentMedia, setCurrentMedia] = useState<Media | undefined>();

  const [isDevicePickerVisible, {on: showDevicePicker, off: hideDevicePicker}] = useToggle(false);
  const [isDisconnectPopupVisible, {on: showDisconnectPopup, off: hideDisconnectPopup}] = useToggle(false);

  const [shouldCast, setShouldCast] = useState<boolean>(false);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [position, setPosition] = useState<number>(0);

  const getChromecastDevices = useDisposable(() => ChromecastModule.getDevices());
  const getConnectionDetails = useDisposable(() => ChromecastModule.getConnectionDetails());
  const cast = useDisposable((media: Media, position: number) => ChromecastModule.castMedia(media, position));

  const getDevices = useDisposable(async () => {
    try {
      const devices = await getChromecastDevices();
      setAvailableDevices(devices);
      return devices;
    } catch (error) {
      Log.error(TAG, 'Failed getting chromecast devices:', error);
      setAvailableDevices([]);
      return [];
    }
  });

  const updateConnection = useCallback((connectionState: ChromecastConnectionState, device: ChromecastDevice | null = null) => {
    setConnectionState(connectionState);
    setCurrentDevice(device);
  }, []);

  const clearStateOnMediaPlaybackStopped = useCallback(() => {
    setConnectionState(ChromecastConnectionState.Connected);
    setCurrentMedia(undefined);
  }, []);

  const clearStateOnDisconnected = useCallback(() => {
    updateConnection(ChromecastConnectionState.Disconnected);
    getDevices();
    setCurrentMedia(undefined);
  }, [updateConnection, getDevices]);

  useEffect(() => {
    getDevices();
    const timer = setInterval(getDevices, deviceRefreshInterval);
    return () => {
      clearInterval(timer);
    };
  }, [getDevices]);

  useEffect(() => {
    const getConnectionState = async () => {
      try {
        const {connectionState, device} = await getConnectionDetails();
        updateConnection(connectionState, device);
      } catch (error) {
        Log.error(TAG, 'Failed getting current connection status:', error);
        clearStateOnDisconnected();
      }
    };
    getConnectionState();
  }, [getConnectionDetails, updateConnection, clearStateOnDisconnected]);

  useEffect(() => {
    setIsConnected(chromecastConnectedStates.includes(connectionState));
  }, [connectionState]);

  const showDeviceListPopup = useCallback(async () => {
    const devices = await getDevices();
    !!devices.length && showDevicePicker();
  }, [getDevices, showDevicePicker]);

  const castAfterConnecting = useCallback((positionToSet: number) => {
    setShouldCast(true);
    setPosition(positionToSet);
  }, []);

  const clearCastAfterConnectingRequest = useCallback(() => {
    setShouldCast(false);
    setPosition(0);
  }, []);

  const castMedia = useCallback((media: Media, position: number) => {
    cast(media, position).then(() => {
      setConnectionState(ChromecastConnectionState.MediaConnecting);
    }).catch(() => {
      clearStateOnMediaPlaybackStopped();
    });
  }, [cast, clearStateOnMediaPlaybackStopped]);

  const stopCastingMedia = useCallback(() => {
    ChromecastModule.stop().finally(() => {
      clearStateOnMediaPlaybackStopped();
    });
  }, [clearStateOnMediaPlaybackStopped]);

  const onChromecastConnecting = useCallback(() => {
    Log.info(TAG, 'Connecting to chromecast device');
    updateConnection(ChromecastConnectionState.Connecting);
  }, [updateConnection]);

  const onChromecastConnectingFailed = useCallback((error: any) => {
    Log.info(TAG, 'Failed connecting to device:', error);
    clearStateOnDisconnected();
  }, [clearStateOnDisconnected]);

  const onChromecastDisconnected = useCallback((reason: any) => {
    Log.info(TAG, 'Disconnected from chromecast device:', reason);
    clearStateOnDisconnected();
  }, [clearStateOnDisconnected]);

  const onChromecastConnected = useCallback(async (payload) => {
    Log.info(TAG, 'Connected to chromecast device:', payload);
    try {
      const {connectionState, device} = await getConnectionDetails();
      updateConnection(connectionState, device);
    } catch (error) {
      Log.error(TAG, 'Failed getting current connection status after connecting:', error);
      clearStateOnDisconnected();
    }
    if (!shouldCast || !currentMedia) {
      return;
    }
    try {
      castMedia(currentMedia, position);
    } catch (error) {
      Log.error(TAG, 'Failed to cast media despite existing chromecast connection.', error);
    } finally {
      clearCastAfterConnectingRequest();
    }
  }, [clearCastAfterConnectingRequest, castMedia, currentMedia, getConnectionDetails, position, shouldCast, updateConnection, clearStateOnDisconnected]);
  useEventListener(ChromecastEvent.SessionStarting, onChromecastConnecting, chromecastEventEmitter);
  useEventListener(ChromecastEvent.SessionResuming, onChromecastConnecting, chromecastEventEmitter);
  useEventListener(ChromecastEvent.SessionStarted, onChromecastConnected, chromecastEventEmitter);
  useEventListener(ChromecastEvent.SessionResumed, onChromecastConnected, chromecastEventEmitter);
  useEventListener(ChromecastEvent.SessionSuspended, onChromecastDisconnected, chromecastEventEmitter);
  useEventListener(ChromecastEvent.SessionEnded, onChromecastDisconnected, chromecastEventEmitter);
  useEventListener(ChromecastEvent.SessionStartFailed, onChromecastConnectingFailed, chromecastEventEmitter);

  useEventListener(ChromecastEvent.MediaPlaybackStarted, () => {
    Log.info(TAG, 'Playback started');
    setConnectionState(ChromecastConnectionState.MediaConnected);
  }, chromecastEventEmitter);

  useEventListener(ChromecastEvent.MediaPlaybackPlaying, () => {
    Log.info(TAG, 'Playback playing');
    setConnectionState(ChromecastConnectionState.MediaConnected);
    setIsPaused(false);
  }, chromecastEventEmitter);
  useEventListener(ChromecastEvent.MediaPlaybackEnded, ({endedReason}) => {
    Log.info(TAG, 'Playback ended');
    // this means that current playback was stopped because of a request to load a new one
    if (endedReason === PlaybackFinishedReason.Interrupted) {
      return;
    }
    clearStateOnMediaPlaybackStopped();
  }, chromecastEventEmitter);

  useEventListener(ChromecastEvent.MediaPlaybackPaused, () => {
    Log.info(TAG, 'Playback paused');
    setConnectionState(ChromecastConnectionState.MediaConnected);
    setIsPaused(true);
  }, chromecastEventEmitter);

  useEventListener(ChromecastEvent.CurrentMedia, async ({chromecastMedia}: {chromecastMedia: ChromecastMedia}) => {
    if (!chromecastMedia) {
      return;
    }
    if (!currentMedia || currentMedia.id !== chromecastMedia.id) {
      const media = await getMediaByChromecastMedia(chromecastMedia.id, chromecastMedia.type);
      setCurrentMedia(media);
      setConnectionState(ChromecastConnectionState.MediaConnected);
    }
  }, chromecastEventEmitter);

  const contextValue = useMemo((): ChromecastContextType => {
    return {
      media: currentMedia,
      setMediaContext: setCurrentMedia,
      availableDevices,
      isDeviceListPopupVisible: isDevicePickerVisible,
      showDeviceListPopup,
      hideDeviceListPopup: hideDevicePicker,
      isDisconnectPopupVisible,
      showDisconnectPopup,
      hideDisconnectPopup,
      connectionState,
      castAfterConnecting,
      clearCastAfterConnectingRequest,
      currentDevice,
      castMedia,
      isPaused,
      isChromecastConnected: isConnected,
      stopCastingMedia
    };
  }, [
    currentMedia, availableDevices, connectionState,
    isDevicePickerVisible, showDeviceListPopup, hideDevicePicker,
    isDisconnectPopupVisible, showDisconnectPopup, hideDisconnectPopup,
    castAfterConnecting, clearCastAfterConnectingRequest,
    currentDevice, castMedia, isPaused, isConnected, stopCastingMedia
  ]);

  return (
    <ChromecastContext.Provider value={contextValue}>
      {children}
    </ChromecastContext.Provider>
  );
};

export const ChromecastContextProvider: React.FC = ({children}) => {
  if (isChromecastSupported()) {
    return (
      <ChromecastContextProviderComponent>
        {children}
      </ChromecastContextProviderComponent>
    );
  } else {
    return <>{children}</>;
  }
};
