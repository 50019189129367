import {createStyles} from 'common-styles';
import React, {useCallback, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {ListRenderItemInfo, View} from 'react-native';

import {dimensions} from 'common/constants';
import {idKeyExtractor} from 'common/HelperFunctions';

import {Series} from 'mw/api/Metadata';

import {FocusManager} from 'components/focusManager/FocusManager';
import {FocusableComponent} from 'components/focusManager/FocusManagerTypes';
import {useFocusParentUtility} from 'components/FocusParent';
import NitroxButton, {NitroxButtonTheme} from 'components/NitroxButton';
import NitroxFlatList from 'components/NitroxFlatList';
import {PostProcessors} from 'locales/i18nPostProcessors';

import {mediaDetailHorizontalMargin} from './MediaDetailUtils';

const buttonHorizontalMargin = dimensions.margins.xsmall;
const styles = createStyles({
  list: {
    marginTop: dimensions.margins.xxLarge
  },
  button: {
    marginHorizontal: buttonHorizontalMargin
  },
  contentContainer: {
    paddingHorizontal: mediaDetailHorizontalMargin - buttonHorizontalMargin
  }
});

type Props = {
  seasons: Series[];
  onSelected: (season: Series) => void;
  initialSeason?: Series;
}

const BigScreenSeasonPicker: React.FunctionComponent<Props> = props => {
  const {seasons, onSelected, initialSeason} = props;
  const {t} = useTranslation();
  const initialSelectedButtonRef = useRef<FocusableComponent>(null);

  const {focus: focusNearestParent} = useFocusParentUtility();

  const renderButton = useCallback((listItemInfo: ListRenderItemInfo<Series>) => (
    <NitroxButton
      ref={listItemInfo.item === initialSeason ? initialSelectedButtonRef : null}
      text={t('mediaDetails.seasonNumberFull', {seasonNumber: listItemInfo.item.seasonNumber, postProcess: PostProcessors.ToUpperCase})}
      theme={NitroxButtonTheme.Primary}
      border
      onPress={() => onSelected(listItemInfo.item)}
      style={styles.button}
    />
  ), [initialSeason, onSelected, t]);

  useEffect(() => {
    FocusManager.getInstance().forceFocus(initialSelectedButtonRef);
    return () => {setTimeout(() => focusNearestParent?.(), 0);};
  }, [focusNearestParent]);

  return (
    <View style={styles.list}>
      <NitroxFlatList<Series>
        horizontal
        data={seasons}
        keyExtractor={idKeyExtractor}
        contentContainerStyle={styles.contentContainer}
        renderItem={renderButton}
      />
    </View>
  );
};

export default BigScreenSeasonPicker;
