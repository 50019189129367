import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {ViewStyle} from 'react-native';

import {NativeNotification} from 'mw/api/System';
import {mw} from 'mw/MW';

import {useDisposable} from 'hooks/Hooks';

import NotificationBox from './NotificationBox';

type NotificationBoxProps = {
  notification: NativeNotification;
  style?: ViewStyle;
  hasTVPreferredFocus?: boolean;
};

const NativeNotificationBox: React.FunctionComponent<NotificationBoxProps> = (props) => {
  const {notification, hasTVPreferredFocus = false} = props;
  const {t} = useTranslation();

  const dismissNativeNotification = useDisposable((key: string) => mw.system.dismissNativeNotification(key));
  const invokeNativeNotificationsAction = useDisposable((key: string) => mw.system.invokeNativeNotificationsAction(key));

  const onNotificationPressed = useCallback(() => {
    invokeNativeNotificationsAction(notification.id);
  }, [invokeNativeNotificationsAction, notification.id]);

  const onDismissButtonPressed = useCallback(() => {
    dismissNativeNotification(notification.id);
  }, [dismissNativeNotification, notification.id]);

  return (
    <NotificationBox
      icon={notification.icon}
      title={notification.title}
      text={notification.text}
      disabled={!notification.isClickable}
      dismissText={t('launcher.notifications.dismiss')}
      isDismissable={notification.isDismissable}
      hasTVPreferredFocus={hasTVPreferredFocus}
      onPress={onNotificationPressed}
      onDismissPress={onDismissButtonPressed}
    />
  );
};

export default React.memo(NativeNotificationBox);
