import {epgItemHeight} from 'common/constants';
import {DateUtils} from 'common/DateUtils';

import {nxffConfig} from 'mw/api/NXFF';

export const pointsPerHour = 630;
export const pointsPerMillisecond = pointsPerHour / DateUtils.msInHour;

export const epgLimits = () => ({
  /**
   * In hours
   */
  intoPast: nxffConfig.getConfig().EPG.EPGPastRange * 24,
  /**
   * In hours
   */
  intoFuture: nxffConfig.getConfig().EPG.EPGFutureRange * 24
});

export const gridWidth = () => {
  const limits = epgLimits();
  return (limits.intoPast + limits.intoFuture) * pointsPerHour;
};

/**
 * Position of originDate on x axis.
 */
export const initialXOffset = () => epgLimits().intoPast * pointsPerHour;

/**
 * Following constants control how chunks are selected for fetching.
 *
 * E.g.: F is current chunk, direction of movement is 'right':
 *
 *                horizontalPreloadLength
 *                <------------->
 *       ___  ___  ___  ___
 *      |   ||   ||   ||   |
 *      |___||___||___||___|
 *       ___  ___  ___  ___  ___
 *      |   || F ||   ||   ||   |
 *      |___||___||___||___||___|
 *       ___  ___  ___  ___
 *      |   ||   ||   ||   |
 *      |___||___||___||___|
 *                <-------->
 *                horizontalPreloadLength - 1
 */

/**
 * Number of chunks fetched in the direction of horizontal movement.
 */
export const preloadLengthHorizontal = 2;
/**
 * Number of chunks fetched in the direction of vertical movement.
 */
export const preloadLengthVertical = 2;

/**
 * Number of chunks that are cached in LRU cache.
 */
export const cacheSize = 16;

/**
 * Offset of focused row from the top of grid.
 */
export const focusedRowOffset = 1.5 * epgItemHeight;
