import React, {useCallback, useMemo} from 'react';
import {View} from 'react-native';

import {dimensions, isBigScreen, isDesktopBrowser, isSTBBrowser} from 'common/constants';

import {Media} from 'mw/api/Metadata';

import {useDefaultMediaSwimlaneTiles} from 'components/AnimatedSwimlaneStack';
import AnimatedSwimlaneStackBase, {SetupSwimlaneType} from 'components/AnimatedSwimlaneStackBase';
import {mediaDetailSwimlaneInsets} from 'components/mediadetails/MediaDetailUtils';
import {useMediaTileFocusedStyle} from 'components/mediaTiles/MediaTileBase';

const mediaTileWidth = dimensions.tile.width + 2 * dimensions.margins.small;
const mediaTileHeight = dimensions.tile.height + dimensions.margins.small;

type Props = {
  header?: string,
  dataFetcher: AsyncIterableIterator<Media[]> | AsyncGenerator<Media[]> | undefined;
  onTileFocusChange?: (data: Media)=> void;
};

const AnimatedSwimlaneMediaBasic: React.FC<Props> = ({
  dataFetcher,
  onTileFocusChange,
  header
}) => {
  const swimlaneComponent = useMemo(() => [{title: '', dataFetcher}], [dataFetcher]);

  const focusedTileFrameStyle = useMediaTileFocusedStyle();
  const renderFocusedTileFrame = useCallback(() => {
    return <View style={focusedTileFrameStyle} />;
  }, [focusedTileFrameStyle]);

  const setupSwimlane = (): SetupSwimlaneType<Media> => {
    return {
      header,
      row: 0,
      insets: {
        left: mediaDetailSwimlaneInsets.left
      },
      renderNavigationArrows: isDesktopBrowser && !isSTBBrowser,
      itemWidth: mediaTileWidth,
      itemHeight: mediaTileHeight,
      dataFetcher
    };
  };

  const onTileFocus = useCallback((tileIndex, row, data: Media) => {
    onTileFocusChange?.(data);
  }, [onTileFocusChange]);

  const {createTile} = useDefaultMediaSwimlaneTiles();

  return (
    <AnimatedSwimlaneStackBase<Media>
      swimlaneComponents={swimlaneComponent}
      fixedFocusPosition={isBigScreen}
      setupSwimlane={setupSwimlane}
      createTile={createTile}
      swimlaneItemHeight={mediaTileHeight}
      renderFocusedTileFrame={renderFocusedTileFrame}
      onTileFocus={onTileFocus}
    />
  );
};

export default AnimatedSwimlaneMediaBasic;
