import {withinBoundaries} from 'common/utils';

import {EntitlementState, Title, MediaType, Playable, TitleType, Event, Metadata, Episode, Content, ContentProvider} from 'mw/api/Metadata';
import {isPCDisabled} from 'mw/common/ParentalControl';
import {mw} from 'mw/MW';

import {MediaObject} from './MediaObject';

export class TitleObject extends MediaObject implements Title {
  public contents: Content[] = [];
  public entitlementState = EntitlementState.NotEntitled;
  public episode: Episode | null;  // exists only if title is part of a series
  public events: Event[] = [];
  public contentProvider?: ContentProvider;
  public isTrailer = false;
  public metadata: Metadata;
  public type: TitleType;
  public trailers: Title[] = [];
  public parentId?: string;

  public constructor(id = '', name = '', type: TitleType = TitleType.UNKNOWN, metadata: Metadata = new Metadata(), episode: Episode | null = null) {
    super(id, name);
    this.type = type;
    this.metadata = metadata;
    this.episode = episode;
  }

  public get progress(): number {
    if (this.isViewedCompletely) {
      return 1;
    }
    return withinBoundaries(0, 1, this.bookmark / this.metadata.duration);
  }

  public getType(): MediaType {
    return MediaType.Title;
  }

  public getPlayable(): Playable | null {
    // this iterates on external(deep linking) and internal contents
    return this.contents.find((playable: Content) => playable.isAllowed()) || null;
  }

  public isBlocked(): boolean {
    // Do not calculate isBlocked's value before explicitly checking if PC is disabled.
    // There is no way of telling whether false returned from super.isBlocked means that PC is disabled or if it is the calculated value.
    if (isPCDisabled()) {
      return false;
    }
    return mw.customer.currentProfile?.blockUnratedMovies && !this.hasRatings()
      || super.isBlocked();
  }

  public get isEntitled(): boolean {
    return this.entitlementState === EntitlementState.Entitled;
  }
}
