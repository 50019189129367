import {delay} from 'common/Async';
import {EventEmitter} from 'common/EventEmitter';
import {compactMap, flatten, fetchWholeGeneratorContent, createEmptyAsyncIterator} from 'common/HelperFunctions';
import {maxBy} from 'common/helpers/ArrayHelperFunctions';
import {Log} from 'common/Log';
import {makeArray} from 'common/utils';

import {EPGParams, EPGResponse, SearchParameters, SearchResult, SearchSource, PurchaseMethodParams, PurchaseResult, ContentQueryParameters, RecommendationsQueryParameters, StartPaymentParams} from 'mw/api/CatalogInterface';
import {PurchaseAuthorizationMethod} from 'mw/api/Configuration';
import {Device} from 'mw/api/Device';
import {CAPMessage, CAPQueryParameters} from 'mw/api/EASMetadata';
import {Error, ErrorType} from 'mw/api/Error';
import {Filter} from 'mw/api/Filter';
import {
  Channel,
  ChannelList,
  Consent,
  EntitlementState,
  Event,
  isEvent,
  isTitle,
  Media,
  Order,
  OrderStatus,
  Payment,
  PaymentMethod,
  Picture,
  PictureMode,
  Product,
  PVRQueryParameters,
  PVRQuota,
  PVRScheduleParameters,
  PVRUpdateParameters,
  Recording,
  Series,
  SortVodBy,
  Styling,
  Title,
  UpdateMediaParams,
  PurchaseMethod,
  SortingOrder,
  SortOwnedBy,
  RecordingStatus
} from 'mw/api/Metadata';
import {nxffConfig} from 'mw/api/NXFF';
import {PinProtectionLevel} from 'mw/api/PinProtection';
import {PinState, Profile, ProfileConfigurableProperties, ProfileStoredProperties} from 'mw/api/Profile';
import {RecommendationsEngine} from 'mw/bo-proxy/bo/RecommendationsEngine';
import {createRecommendationsEngine} from 'mw/bo-proxy/bo/RecommendationsEngineFactory';
import {SearchEngine} from 'mw/bo-proxy/bo/SearchEngine';
import {Utils} from 'mw/bo-proxy/bo/Utils';
import {BOEvent, BOInterface, ChannelUrls, DeletePlaybackSessionParams, OwnedQueryParameters, OwnedSource, PlaybackSessionData, ProfileCreationResult, RegistrationData, OrdersQueryParameters, SortOrdersQueryParameters} from 'mw/bo-proxy/BOInterface';
import {boProxy} from 'mw/bo-proxy/BOProxy';
import {ADR8PlaybackSessionManager} from 'mw/bo-proxy/session-manager/ADR8PlaybackSessionManager';
import {Customer} from 'mw/bo-proxy/types';
import {Translations, UXManager} from 'mw/bo-proxy/uxmanager/UXManager';
import {UXMInterface} from 'mw/bo-proxy/UXMInterface';
import {ConsentInfo, ConsentSlug} from 'mw/cms/ConsentInfo';
import {Link, Menu} from 'mw/cms/Menu';
import {Page} from 'mw/cms/Page';
import {DynamicImageService} from 'mw/common/DynamicImageService';
import {EntitledProduct} from 'mw/common/entitled-products/EntitledProductInterface';
import {mw} from 'mw/MW';
import {NotificationsManager} from 'mw/notifications/NotificationsManager';
import {PlaybackSession, PlaybackSessionManager, SessionParams} from 'mw/playback/sessions/PlaybackSessionManager';

import {ADR8Requester, AdditionalHeaders} from './ADR8Requester';
import {ADR8SearchEngine} from './ADR8SearchEngine';
import {AccountResponse, OrderResponse, OrderResponseStatus, WatchlistResponse, AssetAccessResponse, AssetAccessStatus, OrdersResponse} from './ADR8Types';
import {ADR8Utils} from './ADR8Utils';
import {handleActivateAccountError, handleConcurencyControlError, handleGetConsentError, handleOrderCancelResponse, handleProcessOrderError, handleRegisterDeviceError, handleSessionError, handleTransactionError, handleUnRegisterDeviceError} from './errors/ErrorHandlers';
import {AssetTypes} from './mappers/constants';
import {ContentMapper} from './mappers/ContentMapper';
import {DeviceMapper} from './mappers/DeviceMapper';
import {EntitledProductMapper} from './mappers/EntitledProductMapper';
import {EventMapper} from './mappers/EventMapper';
import {MediaMapper} from './mappers/MediaMapper';
import {OrderMapper} from './mappers/OrderMapper';
import {PaymentMapper} from './mappers/PaymentMapper';
import {PaymentMethodMapper} from './mappers/PaymentMethodMapper';
import {ProductMapper} from './mappers/ProductMapper';
import {profileMapper} from './mappers/ProfileMapper';
import {SeasonMapper} from './mappers/SeasonMapper';
import {SeriesMapper} from './mappers/SeriesMapper';
import {TitleMapper} from './mappers/TitleMapper';
import OrderResponseHandler from './orders/OrderResponseHandler';
import {SessionMapper} from './SessionMapper';

const TAG = 'ADR8Adapter';

const searchMinimumLength = 4;
const continueWatchingTitlesCount = 2;
const continueWatchingSeriesCount = 3;
const numberOfRecentlyWatchedItems = 20;
const recentlyWatchedOffset = 0;
const orderStatusCheckInterval = 500;
const orderStatusCheckMaxRetries = 2;

/**
 * Page size for fetching product's contents at the start of the app.
 * This has to be greater than defaultPageSize to reduce number of requests.
 */
const productsPageSize = 100;

enum AssetFields {
  AssetType = 'assetType',
  Actors = 'actors',
  Description = 'description',
  Directors = 'directors',
  Duration = 'duration',
  ExternalResources = 'externalResources',
  LongDescription = 'longDescription',
  Number = 'number',
  ParentalRatings = 'parentalRatings',
  Relations = 'relations',
  ReleaseYear = 'releaseYear',
  TaxonomyTerms = 'taxonomyTerms',
  Title = 'title',
  Uid = 'uid',
}
const catalogueSearchFieldsParam = Object.values(AssetFields).join(',');

enum SeriesItemType {
  Episode = 'episode',
  Season = 'season'
}

enum ADR8Api {
  Accounts = 'accounts',
  AssetByUids = 'search/public/asset-by-uids',
  CatalogueSearch = 'catalogue-search',
  CheckPin = 'accounts/check-pin',
  ConcurencyControl = 'concurrency-control',
  GeoLocationCheck = 'geolocation-check',
  Confirm = 'confirm',
  Consent = 'consent',
  DeviceManager = 'device-manager',
  Devices = 'devices',
  Favourites = 'favourites',
  MyLibrary = 'order/public/my-library',
  Order = 'order/public',
  OrderCancel = 'order/public/cancel',
  OrderAssetAccess = 'order/public/access',
  OrdersForAccount = 'order/public/for-account',
  Payments = 'payments',
  PaymentsPublic = 'payments/public',
  Playback = 'playback',
  PlaybackProgress = 'playback/progress',
  Pricing = 'pricing/public/for-asset',
  Products = 'products/public',
  ProductsByIds = 'products/public/by-ids',
  Profiles = 'accounts/profiles',
  PublicAsset = 'search/public/asset',
  PublicPhrase = 'search/public/phrase',
  RecentlyWatched = 'recently-watched',
  RedeemVoucher = 'redeem-voucher/public',
  Relations = 'relations',
  SendConfirmationNotification = 'send-confirmation-notification',
  Shop = 'shop',
  Ticket = 'ticket',
  Validate = 'validate',
  ViewerActivity = 'vieweractivity',
  DynamicImageService = 'dynamic-image-service'
}

/**
 * Those endpoints do not require passing XSESSION header
 * and as a result, they can be cached on CDN side.
 */
const publicADR8Endpoints: ADR8Api[] = [
  ADR8Api.AssetByUids,
  ADR8Api.CatalogueSearch,
  ADR8Api.PublicAsset,
  ADR8Api.PublicPhrase
];

enum FilteringParameters {
  Movie = 'movie',
  Series = 'series'
}

enum ConsentState {
  ACCEPT = 'accept',
  REJECT = 'reject'
}

interface RedeemVoucherResult {
  productId: string;
  assetUid?: string;
}

enum TransactionReferenceType {
  Order = 'ORDER'
}

interface CreateOrderParameters {
  productId: string;
  mediaId?: string;
  paymentMethodId: string;
  currency: string;
  offeredPrice: number;
}

interface PaymentTransactionParams {
  paymentMethodId: string;
  amount: number;
  currency: string;
  referenceType: string;
  referenceId: string;
  description: string;
}

const filteringParametersKey = encodeURI('parameters[assetType]');

const ordersQueryDefaultParameters: Required<OrdersQueryParameters> = {
  page: 1, // by default we should be requesting orders from the first page
  size: 100
};

const ordersQuerySortedParameters: SortOrdersQueryParameters = {
  page: 1,
  size: 10
};

enum AssetAccessType {
  Rented = 'rented',
  Bought = 'bought'
}

export class ADR8Adapter extends EventEmitter<BOEvent> implements BOInterface {
  private url?: string;
  private epgRecommendationsEngine: RecommendationsEngine | null = null;
  private vodRecommendationsEngine: RecommendationsEngine | null = null;
  private searchEngine: SearchEngine | null = null;
  private static readonly availableVODSortOptions: SortVodBy[] = [SortVodBy.default, SortVodBy.title, SortVodBy.productionYear];
  private uxManager: UXMInterface;
  private adr8Requester: ADR8Requester;
  private unauthenticatedRequester: ADR8Requester;
  private dynamicImageService: DynamicImageService;
  private readonly orderResponseHandler = new OrderResponseHandler();

  public constructor() {
    super();
    const tenant = nxffConfig.getConfig().Environment.Tenant;
    this.epgRecommendationsEngine = createRecommendationsEngine('epg', this);
    this.vodRecommendationsEngine = createRecommendationsEngine('vod', this);
    this.searchEngine = new ADR8SearchEngine(this, false);
    this.adr8Requester = new ADR8Requester({
      tenant,
      useSSOToken: true,
      publicEndpoints: publicADR8Endpoints
    });
    this.unauthenticatedRequester = new ADR8Requester({
      tenant,
      // UXManager is used before user logs in so it doesn't make sense
      // to retrieve this url from SSO
      url: nxffConfig.getConfig().Environment.BOURL,
      onInvalidateAuthorization: (authorizationToken: string | null) => boProxy.sso.invalidateToken(authorizationToken),
      onServiceUnauthorized: () => this.notify(BOEvent.BOUnauthorized),
      onServiceUnavailable: (error: Error) => this.notify(BOEvent.BOUnavailable, error)
    });
    this.uxManager = new UXManager(this.unauthenticatedRequester);
    this.dynamicImageService = new DynamicImageService();
  }

  public async setParameters(): Promise<void> {
    this.url = await boProxy.sso.getBoUrl();
    this.adr8Requester.setUrl(this.url);
    this.dynamicImageService.setServerUrl(`${this.url}/${ADR8Api.DynamicImageService}`);
  }

  public async getProfiles(): Promise<Profile[]> {
    const json = await this.adr8Requester.sendGetRequest({
      api: ADR8Api.Accounts,
      query: ADR8Api.Profiles
    });
    const result = profileMapper.profilesFromJson(json);
    if (result.ok) {
      return result.value;
    }
    throw result.error;
  }

  public async createProfile(config: Partial<ProfileConfigurableProperties>, masterPin: string): Promise<ProfileCreationResult> {
    const {name, pinState, ...properties} = config;
    if (!name) {
      Log.error(TAG, 'Cannot create profile without a name!');
      return {profileCreated: false, error: new Error(ErrorType.ProfileCreationFailure)};
    }

    const response = await this.adr8Requester.sendPostRequest({
      api: ADR8Api.Accounts,
      query: ADR8Api.Profiles,
      body: JSON.stringify({
        name: name,
        requiresPin: pinState === PinState.ProfilePinRequired,
        avatarUri: null,
        parentalRatingLevels: {}
      })
    });
    const result = profileMapper.profileFromJson(response);
    if (!result.ok) {
      Log.error(TAG, 'Error creating profile: ', response, result.error);
      return {profileCreated: false, error: new Error(ErrorType.ProfileCreationFailure)};
    }
    const profile = result.value;
    try {
      await this.updateProfile(profile, properties);
    } catch (error) {
      return {profileCreated: true, error};
    }
    return {profileCreated: true};
  }

  private updateProfile(profile: Profile, properties: Partial<ProfileConfigurableProperties>) {
    return this.adr8Requester.sendPatchRequest({
      api: ADR8Api.Accounts,
      query: `${ADR8Api.Profiles}/${profile.id}`,
      body: JSON.stringify({
        name: properties.name ?? profile.name,
        avatarUri: properties.avatarUri ?? profile.avatarUri,
        requiresPin: properties.pinState ? properties.pinState === PinState.ProfilePinRequired : profile.isPinRequired,
        attributes: profileMapper.extraDetailsFromProfileProperties({
          ...profile.getProperties(),
          ...properties
        })
      })
    })
      .catch(error => {
        Log.error(TAG, `Error updating profile ${profile.id}`, error);
        throw new Error(ErrorType.ProfileUpdateFailure);
      });
  }

  // TODO: CL-4757 remove PIN from BOInterace
  public deleteProfile(profile: Profile, masterPin: string): Promise<void> {
    return this.adr8Requester.sendDeleteRequest({
      api: ADR8Api.Accounts,
      query: `${ADR8Api.Profiles}/${profile.id}`
    })
      .catch(error => {
        Log.error(TAG, 'Error deleting profile: ', error);
        throw new Error(ErrorType.ProfileDeletionFailure);
      });
  }

  // TODO: CL-4757 remove PIN from BOInterace
  public setNameForProfile(profile: Profile, name: string, masterPin: string): Promise<void> {
    return this.updateProfile(profile, {name});
  }

  public async setPinForProfile(profile: Profile, pin: string): Promise<void> {
    if (!profile.isMain) {
      Log.error(TAG, 'ADR8 does not support setting PIN on profile other than main.');
      throw new Error(ErrorType.ProfileUpdateFailure);
    }

    try {
      const account = await this.getAccount();
      await this.adr8Requester.sendPutRequest({
        api: ADR8Api.Accounts,
        query: ADR8Api.Accounts,
        body: JSON.stringify({
          firstName: account.firstName,
          surname: account.surname,
          pin
        })
      });
    } catch (error) {
      Log.error(TAG, 'Error setting pin', error);
      throw new Error(ErrorType.ProfileUpdateFailure);
    }
  }

  private getAccount(): Promise<AccountResponse> {
    return this.adr8Requester.sendGetRequest({
      api: ADR8Api.Accounts,
      query: ADR8Api.Accounts
    });
  }

  public setDataForProfile<Property extends keyof ProfileStoredProperties>(profile: Profile, key: Property, value: ProfileStoredProperties[Property]): Promise<void> {
    if (!profileMapper.isStoredInExtraDetails(key) && key !== 'pinState') {
      Log.error(TAG, `Setting ${key} for profile is not implemented!`);
      return Promise.reject(new Error(ErrorType.NotImplemented));
    }
    return this.updateProfile(profile, {[key]: value});
  }

  public addTitleToWatchList(profile: Profile, titleId: string): Promise<void> {
    return this.adr8Requester.sendPostRequest({
      api: ADR8Api.ViewerActivity,
      query: ADR8Api.Favourites,
      queryParams: {profileId: profile.id},
      body: JSON.stringify({mediaId: titleId, mediaType: FilteringParameters.Movie})
    });
  }

  public addSeriesToWatchList(profile: Profile, seriesId: string): Promise<void> {
    return this.adr8Requester.sendPostRequest({
      api: ADR8Api.ViewerActivity,
      query: ADR8Api.Favourites,
      queryParams: {profileId: profile.id},
      body: JSON.stringify({mediaId: seriesId, mediaType: FilteringParameters.Series})
    });
  }

  public removeTitlesFromWatchList(profile: Profile, titlesIds: string[]): Promise<void> {
    const promises: Promise<void>[] = titlesIds.map(titleId =>
      this.removeMediaFromWatchList(profile.id, titleId)
    );
    return Promise.all(promises).then(() => Promise.resolve());
  }

  public removeSeriesFromWatchList(profile: Profile, seriesIds: string[]): Promise<void> {
    const promises: Promise<void>[] = seriesIds.map(serieId =>
      this.removeMediaFromWatchList(profile.id, serieId)
    );
    return Promise.all(promises).then(() => Promise.resolve());
  }

  private removeMediaFromWatchList(profileId: string, mediaId: string): Promise<void> {
    return this.adr8Requester.sendDeleteRequest({
      api: ADR8Api.ViewerActivity,
      query: `${ADR8Api.Favourites}/${mediaId}`,
      queryParams: {profileId: profileId}
    });
  }

  public async getWatchList(): Promise<Media[]> {
    return fetchWholeGeneratorContent(this.getWatchlistGenerator())
      .then((favourites: WatchlistResponse[]) => {
        return this.getMediaByIds(favourites.map(favourite => favourite.mediaId));
      });
  }

  public async *getWatchlistGenerator(): AsyncIterableIterator<WatchlistResponse[]> {
    let page = 1;
    const pageSize = 100;
    while (true) {
      try {
        const paging = {page: page++, size: pageSize};
        const chunk: WatchlistResponse[] = await this.adr8Requester.sendGetRequest({
          api: ADR8Api.ViewerActivity,
          query: ADR8Api.Favourites,
          queryParams: {
            profileId: this.profileId,
            ...paging
          },
          // TODO: this is a temporary workaround until ADR-2178 is done
          otherParams: {
            additionalHeaders: {'Cache-Control': 'no-cache'}
          }
        });
        if (chunk.length < pageSize) {
          return chunk;
        } else {
          yield chunk;
        }
      } catch (error) {
        Log.error(TAG, `Error fetching watchlist`, error);
        return [];
      }
    }
  }

  public getPinProtectionLevel(): PinProtectionLevel {
    return PinProtectionLevel.Account;
  }

  public verifyProfilePin(_: Profile, pin: string): Promise<void> {
    return this.adr8Requester.sendPostRequest({
      api: ADR8Api.Accounts,
      query: ADR8Api.CheckPin,
      body: JSON.stringify({pin})
    })
      .then(res => res.isValid ? Promise.resolve() : Promise.reject(new Error(ErrorType.IncorrectPin)));
  }

  public isPurchasePinSupported(): boolean {
    return boProxy.sso.isPurchasePinSupported();
  }

  public setPurchasePin(pin: string): Promise<void> {
    return boProxy.sso.setPurchasePin(pin);
  }

  public verifyPurchasePin(pin: string): Promise<void> {
    return boProxy.sso.verifyPurchasePin(pin);
  }

  public getPurchaseAuthorizationMethod(): PurchaseAuthorizationMethod {
    return PurchaseAuthorizationMethod.None;
  }

  public getChannels(): Promise<Channel[]> {
    //NOTE: ADR8 does not support channels yet
    return Promise.resolve([]);
  }

  public getEPG(params: EPGParams): Promise<EPGResponse> {
    return Promise.resolve(new Map<string, Event[]>());
  }

  public async getEventById(id: string): Promise<Event> {
    const eventId = id.split('_')[1];
    const airing = await this.adr8Requester.sendGetRequest({
      api: 'mmapi',
      query: `epg/program/${eventId}`
    });
    if (airing.status === 'error') {
      return Promise.reject('Failed to fetch event details for event with id ' + eventId);
    }
    const channels = await mw.catalog.getAllChannels();
    const channel = channels.find((channel: Channel): boolean => {
      return channel.customProperties.epgId === airing.station_id;
    });
    if (!channel) {
      return Promise.reject('Unable to find channel for event with id ' + eventId + ' and station id ' + airing.station_id);
    }
    Log.debug(TAG, 'Received details for event with id ' + eventId + ' from channel ' + channel);
    return EventMapper.convertAiringToEvent(channel.id, airing);
  }

  public getPictureUrl(picture: Picture, width: number, height: number, mode: PictureMode): string {
    if (!picture.url) {
      return '';
    }
    return this.dynamicImageService.getPictureUrl(picture.url, width, height, mode);
  }

  public async getRecommendations(params: RecommendationsQueryParameters): Promise<Media[]> {
    const requests: Promise<Media[]>[] = [];
    if (params.sources.includes('epg')) {
      if (!this.epgRecommendationsEngine) {
        Log.error(TAG, 'EPG recommendations engine not created!');
      } else {
        requests.push(this.epgRecommendationsEngine.getRecommendations(params));
      }
    }
    if (params.sources.includes('vod')) {
      if (!this.vodRecommendationsEngine) {
        Log.error(TAG, 'VOD recommendations engine not created!');
      } else {
        requests.push(this.vodRecommendationsEngine.getRecommendations(params));
      }
    }
    return flatten(await Promise.all(requests));
  }

  /**
   * Function responsible for filtering out vod screen in
   * case of using defaultMenu. This is supposed not be displayed
   * when UXM unreachable. Some traxis environment still use vod-root
   * implementation to request Vod cataogs.
   * Done in CL-2980
   * @param menu - menu to filter
   */
  private static filterdefaultMainMenuFromVodRoot(menu: Menu): Menu {
    const menuItems = menu.items.filter(item => item.link?.slug !== 'vod-root');
    return new Menu({...menu, items: menuItems});
  }

  public getMenu(id: string, depth: number): Promise<Menu> {
    return this.uxManager.getMenu(id, depth);
  }

  public getMainMenu(): Promise<Menu> {
    return this.uxManager.getMainMenu().then(ADR8Adapter.filterdefaultMainMenuFromVodRoot);
  }

  public getUnauthenticatedMainMenu(): Promise<Menu> {
    return this.uxManager.getDefaultMainMenu().then(ADR8Adapter.filterdefaultMainMenuFromVodRoot);
  }

  public getPage(link: Link): Promise<Page> {
    return this.uxManager.getPage(link);
  }

  public async createPlaybackSession(params: SessionParams): Promise<PlaybackSessionData> {
    const json = await this.adr8Requester.sendPostRequest({
      api: ADR8Api.Playback,
      query: `${ADR8Api.Playback}/${ADR8Api.Ticket}`,
      body: JSON.stringify({
        streamId: params.id
      })
    })
      .catch(handleSessionError);

    await this.sendConcurencyControl(params.id, params.assetId);
    return SessionMapper.toPlaybackSessionData(json, params.assetId);
  }

  private sendConcurencyControl(id: string, assetId: string): Promise<void> {
    return this.adr8Requester.sendPostRequest({
      api: ADR8Api.ConcurencyControl,
      query: ADR8Api.ConcurencyControl,
      body: JSON.stringify({
        /* eslint-disable @typescript-eslint/naming-convention */
        external_stream_id: id,
        device_id: mw.customer.currentDevice?.id,
        asset_uid: assetId
        /* eslint-enable @typescript-eslint/naming-convention */
      })
    })
      .catch(handleConcurencyControlError);
  }

  public keepAlivePlaybackSession(session: PlaybackSession, params: any): Promise<void> {
    return this.sendConcurencyControl(params.assetId, session.id);
  }

  public geoLocationPlaybackCheck(params: any): Promise<void> {
    return this.adr8Requester.sendPostRequest({
      api: ADR8Api.Playback,
      query: `${ADR8Api.Playback}/${ADR8Api.GeoLocationCheck}`,
      body: JSON.stringify({
        assetId: params.id
      })
    })
      .catch(handleSessionError);
  }

  public deletePlaybackSession(session: PlaybackSession, params: DeletePlaybackSessionParams): Promise<void> {
    return Promise.resolve();
  }

  public getContinueWatching(): Promise<Media[]> {
    return this.getRecentlyWatchedItems(numberOfRecentlyWatchedItems, recentlyWatchedOffset)
      .then(recentlyWatchedItems =>
        [
          ...MediaMapper.getViewedVodTitles(continueWatchingTitlesCount, recentlyWatchedItems),
          ...MediaMapper.getViewedSeriesTitles(continueWatchingSeriesCount, recentlyWatchedItems)
        ]
      );
  }

  private getRecentlyWatchedItems(limit: number, offset: number): Promise<Media[]> {
    return this.adr8Requester.sendGetRequest({
      api: ADR8Api.ViewerActivity,
      query: ADR8Api.RecentlyWatched,
      queryParams: {profileId: this.profileId, limit: limit, offset: offset}
    })
      .then(recentlyWatchedItems => {
        const promises: Promise<Media>[] = recentlyWatchedItems.map((item: {mediaId: string}) => {
          return this.getAssetById(item.mediaId)
            .then(async (media) => {
              if (!isTitle(media)) {
                return media;
              }

              const bookmark = await this.getBookmark(media);
              ADR8Utils.setTitleBookmark(media, bookmark);
              return media;
            });
        });
        return Promise.all(promises);
      });
  }

  private static getAdultHeaders(): AdditionalHeaders | undefined {
    if (!mw.customer.getProfile().isPCEnabled || mw.customer.getProfile().showAdultContent) {
      return;
    }
    const adultRatings: string[] = [];
    mw.configuration.supportedPCRatings
      .filter((pcRating) => pcRating.adultOnlyRatings.length > 0)
      .forEach((pcRating) => {
        const maxNoneAdultRating = maxBy(pcRating.ratings, (current) => !pcRating.adultOnlyRatings.includes(current));
        if (maxNoneAdultRating) {
          adultRatings.push(`${pcRating.authority},${maxNoneAdultRating}`);
        }
      });
    if (adultRatings.length === 0) {
      return;
    }
    // In order to temporarily work around issues with BO API we send only the first PC-related header.
    // Later a proper concatenation of the authorities must be implemented here! For more info check CL-5939.
    return {'x-parental-rating': adultRatings[0]};
  }

  public async *getContent(filters: Filter[], parameters: ContentQueryParameters): AsyncIterableIterator<Media[]> {
    const filterIds = compactMap(filters, filter => {
      const components = filter.value.split('/');
      if (!components.length) {
        Log.error(TAG, 'Incorrect filter:', filter);
        return null;
      }
      return components[components.length - 1];
    });
    const ids = filterIds.join(',');
    let page = Math.max(parameters.pageNumber || 0, 0) + 1;
    const {pageSize, ...options} = parameters;
    while (true) {
      Log.info(TAG, `Fetching page ${page} of size ${pageSize} for filter "${ids}."`);
      const paging = {page: page++, size: pageSize};
      const json = await this.adr8Requester.sendGetRequest({
        api: ADR8Api.CatalogueSearch,
        query: `search/public/filter/${ids}`,
        queryParams: {
          language: this.uiLanguage,
          fields: catalogueSearchFieldsParam,
          ...paging,
          ...ADR8Utils.queryParamsFromOptions(options)
        },
        otherParams: {
          additionalHeaders: ADR8Adapter.getAdultHeaders()
        }
      }).catch(() => ({}));
      const nextPage = json.content && json.content.length ? compactMap(json.content, ContentMapper.mediaFromJson) : [];
      Log.info(TAG, `Downloaded ${nextPage.length} results for filter "${ids}."`);
      if (nextPage.length < pageSize) {
        return nextPage;
      } else {
        yield nextPage;
      }
    }
  }

  public async getFakeVodRecommendationsSource(): Promise<Filter | null> {
    const filterSlug = nxffConfig.getConfig().Recommendations.VODRecommendationsFilter;
    if (!filterSlug) {
      Log.debug(TAG, 'VODRecommendationsFilter not defined!');
      return null;
    }
    return {
      value: filterSlug,
      isSpecial: false,
      isPersonal: false,
      cacheTimeInMinutes: 0
    };
  }

  public getAssetById(id: string): Promise<Media> {
    return this.adr8Requester.sendGetRequest({
      api: ADR8Api.CatalogueSearch,
      query: `${ADR8Api.PublicAsset}/${id}`,
      queryParams: {language: this.uiLanguage},
      otherParams: {additionalHeaders: ADR8Adapter.getAdultHeaders()}
    })
      .then(ContentMapper.mediaFromJson);
  }

  public getSeriesById(id: string): Promise<Series> {
    return this.adr8Requester.sendGetRequest({
      api: ADR8Api.CatalogueSearch,
      query: `${ADR8Api.PublicAsset}/${id}`,
      queryParams: {language: this.uiLanguage},
      otherParams: {additionalHeaders: ADR8Adapter.getAdultHeaders()}
    })
      .then(SeriesMapper.seriesFromJson);
  }

  public getSeriesSeasonsById(seriesId: string): Promise<Series[]> {
    return this.getSeriesItems(seriesId, SeriesItemType.Season)
      .then(SeasonMapper.seasonsFromJson);
  }

  public async getSeriesEpisodesById(seriesId: string): Promise<Title[]> {
    const episodesJson = await this.getSeriesItems(seriesId, SeriesItemType.Episode);
    const episodes = TitleMapper.episodesFromJson(episodesJson);

    await Promise.all(episodes.map(async (episode: Title) => {
      ADR8Utils.setTitleBookmark(episode, await this.getBookmark(episode));
    }));

    return episodes;
  }

  public getSeasonEpisodesById = this.getSeriesEpisodesById;

  private async getSeriesItems(seriesId: string, type: SeriesItemType): Promise<any[]> {
    const items = [];
    let currentPage = 1;
    let totalPages = 1;
    let itemsJson = await this.getSeriesItemsPage(seriesId, type, 1);

    if (typeof itemsJson.totalPages === 'number') {
      totalPages = itemsJson.totalPages;
    }

    while (currentPage <= totalPages) {
      if (itemsJson.content) {
        items.push(...itemsJson.content);
      }

      ++currentPage;

      if (currentPage <= totalPages) {
        itemsJson = await this.getSeriesItemsPage(seriesId, type, currentPage);
      }
    }

    return items;
  }

  private getSeriesItemsPage(seriesId: string, assetType: SeriesItemType, page: number): Promise<any> {
    return this.adr8Requester.sendGetRequest({
      api: ADR8Api.CatalogueSearch,
      query: `${ADR8Api.PublicAsset}/${seriesId}/${ADR8Api.Relations}`,
      queryParams: {assetType, page, language: this.uiLanguage},
      otherParams: {additionalHeaders: ADR8Adapter.getAdultHeaders()}
    });
  }

  public async getRecommendedEpisode(series: Series): Promise<Title | null> {
    if (series.externalId) {
      const json = await this.adr8Requester.sendGetRequest({
        api: 'mmapi',
        query: `media/series/${series.externalId}/recommended-episode`
      });
      return TitleMapper.recommendedEpisodeFromJson(json);
    }
    return null;
  }

  public getPlaybackSessionManager(): PlaybackSessionManager {
    return new ADR8PlaybackSessionManager();
  }

  /** ADR8 does not support push notifications */
  public getNotificationsManager(): NotificationsManager | null {
    return null;
  }

  private getTrailersDetails(trailers: {uid: string}[], parentId: string): Promise<Title[]> {
    const promises = trailers.map(trailer => this.getTitleById(trailer.uid)
      .then(title => {
        title.parentId = parentId;
        return title;
      })
    );

    return Promise.all(promises);
  }

  private async getProductsWithOffersById(id: string): Promise<Product[]> {
    const offersJson = await this.adr8Requester.sendGetRequest({
      api: ADR8Api.Shop,
      query: `${ADR8Api.Pricing}/${id}`
    });
    const offersArray = makeArray(offersJson);
    if (!offersArray.length) {
      return [];
    }

    const products = await this.getProducts(offersArray);
    return ProductMapper.productsWithOffersFromJson(offersArray, products);
  }

  private async getProducts(json: any[]): Promise<Product[]> {
    const distinctIds: number[] = json
      .map(pricing => pricing.productId)
      .filter((id, index, self) => self.indexOf(id) === index);
    return await this.adr8Requester.sendPostRequest({
      api: ADR8Api.Shop,
      query: `${ADR8Api.ProductsByIds}`,
      body: JSON.stringify(distinctIds)
    }).then(ProductMapper.productsFromJson);
  }

  public async getTitleById(id: string): Promise<Title> {
    const json = await this.adr8Requester.sendGetRequest({
      api: ADR8Api.CatalogueSearch,
      query: `${ADR8Api.PublicAsset}/${id}`,
      queryParams: {language: this.uiLanguage},
      otherParams: {additionalHeaders: ADR8Adapter.getAdultHeaders()}
    });
    const products = await this.getProductsWithOffersById(id);
    let assetAccess: AssetAccessResponse = {
      // The app should assume that customer is always entitled to play any trailer.
      status: json.assetType === AssetTypes.Trailer ? AssetAccessStatus.Available : AssetAccessStatus.Unavailable,
      endTime: null
    };
    if (assetAccess.status !== AssetAccessStatus.Available) {
      try {
        assetAccess = await this.checkAssetAccess(id);
      } catch (error) {
        Log.error(TAG, 'Error checking asset access', error);
      }
    }
    const title = TitleMapper.titleFromJson(json, {products, assetAccess});
    ADR8Utils.setTitleBookmark(title, await this.getBookmark(title));
    if (json?.relations?.trailer) {
      title.trailers = await this.getTrailersDetails(json.relations.trailer, id);
    }
    return title;
  }

  public getChannelUrls(): Promise<ChannelUrls> {
    return Promise.resolve({});
  }

  public updateMedia(media: Media, params: UpdateMediaParams): Promise<void> {
    switch (params) {
      case UpdateMediaParams.Bookmarks:
        if (!isEvent(media)) {
          return Promise.reject(new Error(ErrorType.NotImplemented));
        }
        return this.updateEventBookmark(media);
      case UpdateMediaParams.EntitlementState:
        return this.updateMediaEntitlementState(media);
      default:
        return Promise.reject(new Error(ErrorType.NotImplemented));
    }
  }

  private updateEventBookmark(event: Event): Promise<void> {
    // not supported
    return Promise.resolve();
  }

  private updateMediaEntitlementState(media: Media): Promise<void> {
    if (!isTitle(media)) {
      return Promise.reject(new Error(ErrorType.NotSupported, `Updating entitlement state is not supported for ${media.getType()} media type`));
    }
    return this.checkAssetAccess(media.id)
      .then(response => {
        media.entitlementState = TitleMapper.assetAccessStatusToEntitlementState(response.status);
      });
  }

  private getBookmark(media: Media): Promise<number> {
    return this.adr8Requester.sendGetRequest({
      api: ADR8Api.ViewerActivity,
      query: `${ADR8Api.PlaybackProgress}/${media.id}`,
      queryParams: {profileId: this.profileId}
    })
      .then(ADR8Utils.bookmarkFromJson);
  }

  public async deleteBookmark(media: Media): Promise<void> {
    return this.setBookmark(media, 0);
  }

  public setBookmark(media: Media, position: number): Promise<void> {
    const queryParams = this.profileId ? {profileId: this.profileId} : undefined;
    return this.adr8Requester.sendPutRequest({
      api: ADR8Api.ViewerActivity,
      query: `${ADR8Api.PlaybackProgress}/${media.id}`,
      body: JSON.stringify({progress: Math.floor(position)}),
      queryParams
    })
      .then(() => this.adr8Requester.sendPostRequest({
        api: ADR8Api.ViewerActivity,
        query: ADR8Api.RecentlyWatched,
        body: JSON.stringify({mediaId: media.id}),
        queryParams
      }));
  }

  public search(params: SearchParameters): AsyncIterableIterator<SearchResult> {
    if (!this.searchEngine) {
      throw new Error(ErrorType.UnknownError, 'SearchEngine not created!');
    }
    return this.searchEngine.search(params);
  }

  public getSearchMinimumLength(): number {
    return searchMinimumLength;
  }

  public async sendRecommendationsQuery(limit: number): Promise<Title[]> {
    const recommendations = await this.adr8Requester.sendGetRequest({
      api: 'catalogue',
      query: 'recommendations',
      queryParams: {
        language: this.uiLanguage,
        page: 1,
        size: limit
      }
    });
    if (!recommendations || !recommendations.content || !Array.isArray(recommendations.content)) {
      Log.error(TAG, 'Could not parse recommendations!');
      return [];
    }
    return compactMap(recommendations.content, TitleMapper.titleFromJson);
  }

  public sendSearchQuery(source: SearchSource, query: string, params: {[key: string]: number | string}): Promise<any> {
    switch (source) {
      case SearchSource.Channel:
        Log.info(TAG, 'Channel search not supported');
        return Promise.resolve([]);
      case SearchSource.Epg:
        Log.info(TAG, 'Epg search not supported');
        return Promise.resolve([]);
      case SearchSource.Vod:
        return this.adr8Requester.sendGetRequest({
          api: ADR8Api.CatalogueSearch,
          query: `${ADR8Api.PublicPhrase}/${query}`,
          queryParams: {
            language: this.uiLanguage,
            fields: catalogueSearchFieldsParam,
            [filteringParametersKey]: [
              FilteringParameters.Movie, FilteringParameters.Series
            ].join(','),
            ...params
          },
          otherParams: {
            additionalHeaders: ADR8Adapter.getAdultHeaders()
          }
        });
      default:
        return Promise.reject(new Error(ErrorType.NotSupported, `Not supported source: ${source}`));
    }
  }

  public getNPVRQuota(): Promise<PVRQuota> {
    return Promise.resolve({
      available: 0,
      remaining: 0
    });
  }

  public getRecordingsStatus(events: Event[]): Promise<Map<string, RecordingStatus>> {
    return Promise.reject(new Error(ErrorType.NotImplemented));
  }

  public scheduleRecording(params: PVRScheduleParameters): Promise<Recording> {
    return Promise.reject(new Error(ErrorType.NotImplemented));
  }

  public deleteRecordings(recordings: Recording[]): Promise<void> {
    return Promise.reject(new Error(ErrorType.NotImplemented));
  }

  public cancelRecordings(recordings: Recording[]): Promise<void> {
    return Promise.reject(new Error(ErrorType.NotImplemented));
  }

  public resumeRecordings(recordings: Recording[], params?: PVRUpdateParameters): Promise<void> {
    return Promise.reject(new Error(ErrorType.NotImplemented));
  }

  public updateRecordings(recordings: Recording[], params: PVRUpdateParameters): Promise<void> {
    return Promise.reject(new Error(ErrorType.NotImplemented));
  }

  public getAllRecordingsStatuses(): Promise<Map<Event['id'], RecordingStatus>> {
    return new Promise(() => (new Map()));
  }

  public getRecordings(params: PVRQueryParameters): Promise<Recording[]> {
    return Promise.reject(new Error(ErrorType.NotImplemented));
  }

  public getIsRecorded(events: Event[]): Promise<Map<Event['id'], boolean>> {
    return Promise.reject(new Error(ErrorType.NotImplemented));
  }

  public isSeenFilterAvailable(): boolean {
    return false;
  }

  public getAvailableVODSortOptions(): SortVodBy[] {
    return ADR8Adapter.availableVODSortOptions;
  }

  public async getCustomer(): Promise<Customer> {
    const profiles = await this.getProfiles();
    const mainProfile = profiles.find(p => p.isMain);
    if (!mainProfile) {
      Log.error(TAG, 'Could not find main profile!');
      throw new Error(ErrorType.LoginError);
    }
    const customer: Customer = {
      externalId: boProxy.sso.getIdentity().id,
      profiles: profiles,
      mainProfile: mainProfile,
      defaultProfileId: mainProfile.id
    };
    const consent = await this.getConsent(ConsentSlug.NitroxEula, mainProfile.id);
    if (consent.accepted) {
      customer.acceptedEULAVersion = consent.version;
    }
    return Promise.resolve(customer);
  }

  public registerDevice(device: Device): Promise<void> {
    return this.adr8Requester.sendPostRequest({
      api: ADR8Api.DeviceManager,
      query: ADR8Api.Devices,
      body: JSON.stringify({
        uid: device.id,
        name: device.name
      })
    })
      .catch(handleRegisterDeviceError);
  }

  public async unregisterDevices(devices: Device[], masterPin: string): Promise<void> {
    for (const device of devices) {
      await this.adr8Requester.sendDeleteRequest({
        api: ADR8Api.DeviceManager,
        query: `${ADR8Api.Devices}/${device.backOfficeId}`
      })
        .catch(handleUnRegisterDeviceError);
    }
  }

  public async getDevices(): Promise<Device[]> {
    const json = await this.adr8Requester.sendGetRequest({
      api: ADR8Api.DeviceManager,
      query: ADR8Api.Devices
    });

    return DeviceMapper.devicesFromJson(json);
  }

  public generateCpeId(): string {
    return Utils.sha1ToUUID5(Utils.generateCpeId());
  }

  /**
   * Maps product to a promise of an object containing product and media assigned to it
   */
  private getProductWithAssignedMedia(product: Product, orders: ({productId: string; assetUid?: string; mediaId?: string})[]): Promise<{product: Product; media: Media[]}> {
    let mediaPromise: Promise<Media[]>;
    if (product.isSingle) {
      const order = orders.find(({productId}) => `${productId}` === `${product.id}`);
      const assetId = order?.assetUid ?? order?.mediaId;
      mediaPromise = assetId
        ? Promise.resolve([TitleMapper.titleFromJson({uid: assetId}, {products: [product]})])
        : Promise.resolve([]);
    } else {
      mediaPromise = fetchWholeGeneratorContent(this.getProductContents(product, {pageSize: productsPageSize}));
    }
    return mediaPromise.then(media => ({
      product,
      media
    }));
  }

  public async getEntitledProducts(): Promise<EntitledProduct[]> {
    // TODO: this is a temporary workaround until CL-5336 is done
    const allowedStatuses: OrderResponseStatus[] = ['PENDING_DELIVERY', 'PROCESSING_PAYMENT', 'PENDING_PAYMENT', 'DELIVERED'];

    const orders = await this.getAllOrders().then(orders =>
      // filter to only include delivered or pending
      orders.filter(({orderStatus}) => allowedStatuses.includes(orderStatus))
    );

    const productsWithMedia = await this.getProducts(orders)
      // map products to objects containing product and media assigned to it
      .then(products =>
        Promise.all(products.map(product => this.getProductWithAssignedMedia(product, orders)))
      );

    return EntitledProductMapper.fromOrdersJsonWithProducts(orders, productsWithMedia);
  }

  private get uiLanguage(): string | null {
    // we don't want empty string in query - null will be filtered out
    return mw.customer.currentProfile?.uiLanguage || null;
  }

  private get profileId(): string | null {
    return mw.customer.currentProfile?.id ?? null;
  }

  public sendLocation(): Promise<void> {
    return Promise.reject(new Error(ErrorType.NotSupported));
  }

  public getStyling(name: string, version: string): Promise<Styling> {
    return this.uxManager.getStyling(name, version);
  }

  public areChannelListsSupported(): boolean {
    return false;
  }

  public getChannelLists(profile: Profile): Promise<ChannelList[]> {
    return Promise.reject(new Error(ErrorType.NotSupported));
  }

  public createChannelList(profile: Profile, name: string, channels: Channel[]): Promise<ChannelList> {
    return Promise.reject(new Error(ErrorType.NotSupported));
  }

  public deleteChannelList(profile: Profile, channelList: ChannelList): Promise<void> {
    return Promise.reject(new Error(ErrorType.NotSupported));
  }

  public updateChannelList(profile: Profile, channelList: ChannelList, name: string, channels: Channel[]): Promise<void> {
    return Promise.reject(new Error(ErrorType.NotSupported));
  }

  public getConsentData(name: string): Promise<Consent> {
    return this.uxManager.getConsentData(name);
  }

  public setConsent(slug: string, version: string, accepted: boolean): Promise<void> {
    if (!mw.customer.mainProfile) {
      Log.error(TAG, 'Could not find main profile!');
      throw new Error(ErrorType.CustomerUpdateFailure);
    }
    const profileId = mw.customer.mainProfile.id;
    return this.adr8Requester.sendPostRequest({
      api: ADR8Api.ViewerActivity,
      query: `${ADR8Api.Consent}/${slug}`,
      body: JSON.stringify({
        action: accepted ? ConsentState.ACCEPT : ConsentState.REJECT
      }),
      queryParams: {profileId}
    });
  }

  private async getConsent(slug: string, profileId: string): Promise<ConsentInfo> {
    const json = await this.adr8Requester.sendGetRequest({
      api: ADR8Api.ViewerActivity,
      query: `${ADR8Api.Consent}/${slug}`,
      queryParams: {profileId}
    })
      .catch(handleGetConsentError);

    return Promise.resolve({
      slug: slug,
      version: json.version,
      accepted: json.status === ConsentState.ACCEPT,
      timestamp: json.date
    });
  }

  public getAvailableUILanguages(): Promise<string[]> {
    return Promise.reject(new Error(ErrorType.NotSupported));
  }

  public getDefaultUILanguage(): Promise<string> {
    return Promise.reject(new Error(ErrorType.NotSupported));
  }

  public getTranslations(version: string): Promise<Translations> {
    return this.uxManager.getTranslations(version);
  }

  public getCAPMessage(params: CAPQueryParameters): Promise<CAPMessage> {
    return Promise.reject(new Error(ErrorType.NotSupported));
  }

  public async getProductById(productId: string): Promise<Product> {
    const json = await this.adr8Requester.sendGetRequest({
      api: ADR8Api.Shop,
      query: `${ADR8Api.Products}/${productId}`
    });

    return ProductMapper.productFromJson(json);
  }

  private purchaseByVoucher(voucher: string): Promise<RedeemVoucherResult> {
    return this.adr8Requester.sendPostRequest({
      api: ADR8Api.Shop,
      query: `${ADR8Api.RedeemVoucher}/${voucher}`,
      body: JSON.stringify({})
    })
      .catch(handleTransactionError);
  }

  public async purchase(purchaseMethod: PurchaseMethod, params: PurchaseMethodParams): Promise<PurchaseResult> {
    let mediaId: string | undefined, productId: string, order: Order | undefined;

    switch (purchaseMethod) {
      case PurchaseMethod.Voucher: {
        if (!params.voucher) {
          throw new Error(ErrorType.InvalidParameter);
        }

        const result = await this.purchaseByVoucher(params.voucher);
        productId = result.productId;
        mediaId = result.assetUid;
        break;
      }
      case PurchaseMethod.Stripe: {
        if (!params.orderId || !params.media || !isTitle(params.media)) {
          throw new Error(ErrorType.InvalidParameter);
        }
        await this.startPaymentProcess(params.orderId, params.media.id);
        params.media.entitlementState = EntitlementState.EntitlementInProgress;
        order = await this.getFinalizedOrderById(params.orderId, params.orderStatus)
          .catch(error => {
            if (error?.type === ErrorType.TransactionFailed && isTitle(params.media)) {
              params.media.entitlementState = EntitlementState.NotEntitled;
            }
            // rethrowing so UI can handle it properly
            throw error;
          });
        productId = order.productId;
        mediaId = order.mediaId;
        switch (order.orderStatus) {
          case OrderStatus.Success:
            params.media.entitlementState = EntitlementState.Entitled;
            break;
          case OrderStatus.Pending:
          case OrderStatus.Unpaid:
            throw new Error(ErrorType.TransactionNotFinalized);
        }
        break;
      }
      default:
        throw new Error(ErrorType.InvalidParameter);
    }

    if (!params.load) {
      return {result: undefined};
    }

    if (mediaId) {
      return {result: await this.getTitleById(mediaId), updatedOrder: order};
    }

    if (!params.queryParameters) {
      throw new Error(ErrorType.InvalidParameter);
    }

    const product = await this.getProductById(productId);
    return {result: await this.getProductContents(product, params.queryParameters)};
  }

  private startPaymentProcess(orderId: string, mediaId: string): Promise<void> {
    return this.adr8Requester.sendPutRequest({
      api: ADR8Api.Shop,
      query: `${ADR8Api.Order}/${orderId}/process`,
      body: ''
    })
      .catch(handleProcessOrderError)
      .catch((error: Error) => {
        Log.error(TAG, `Error starting payment process for order ${orderId}`, error);
        if (error.type === ErrorType.OrderNotFound || error.type === ErrorType.OrderInvalidState) {
          throw error;
        }
        this.orderResponseHandler.markOrderIsProcessingPayment({id: orderId, mediaId: mediaId});
      });
  }

  private getProductContents(product: Product, parameters: ContentQueryParameters): AsyncIterableIterator<Media[]> {
    if (!product.filterId) {
      Log.warn(TAG, 'Unable to get product contents - filterId is missing');
      return createEmptyAsyncIterator<Media[]>();
    }

    const filter = {
      value: product.filterId,
      isPersonal: false,
      isSpecial: false,
      cacheTimeInMinutes: 0
    };

    return this.getContent([filter], parameters);
  }

  public registerAccount(registrationData: RegistrationData): Promise<void> {
    const {username, email, firstName, surname, password, pin, phone = ''} = registrationData;

    const logData = Object.entries(registrationData)
      .map(([key, value]) => `${key}: ${value?.trim().length ? 'filled' : 'not filled'}`)
      .join(', ');
    Log.info(TAG, 'register customer', logData);

    return this.unauthenticatedRequester.sendPostRequest({
      api: ADR8Api.Accounts,
      query: ADR8Api.Accounts,
      body: JSON.stringify({
        username,
        email,
        firstName,
        surname,
        password,
        pin,
        phone,
        attributes: {}
      }),
      otherParams: {secret: true}
    });
  }

  public async activateAccount(accountId: number, token: string): Promise<void> {
    return this.unauthenticatedRequester.sendGetRequest({
      api: ADR8Api.Accounts,
      query: `${ADR8Api.Accounts}/${accountId}/${ADR8Api.Confirm}/${token}`
    })
      .catch(handleActivateAccountError);
  }

  public async validateAccount(registrationData: Partial<RegistrationData>): Promise<void> {
    const {username = null, email = null, firstName = null, surname = null, password = null, pin = null, phone = null} = registrationData;
    return this.unauthenticatedRequester.sendPostRequest({
      api: ADR8Api.Accounts,
      query: `${ADR8Api.Accounts}/${ADR8Api.Validate}`,
      body: JSON.stringify({
        username,
        email,
        firstName,
        surname,
        password,
        pin,
        phone,
        attributes: {}
      })
    });
  }

  public sendAccountActivationNotification(emailOrUsername: string): Promise<void> {
    return this.unauthenticatedRequester.sendPostRequest({
      api: ADR8Api.Accounts,
      query: `${ADR8Api.Accounts}/${ADR8Api.SendConfirmationNotification}`,
      body: JSON.stringify({emailOrUsername})
    })
      .catch(handleActivateAccountError);
  }

  public getPaymentMethods(): Promise<PaymentMethod[]> {
    return this.adr8Requester.sendGetRequest({
      api: ADR8Api.Payments,
      query: ADR8Api.PaymentsPublic
    })
      .then(PaymentMethodMapper.paymentMethodsFromJson);
  }

  private async getOrdersImpl(params?: SortOrdersQueryParameters): Promise<OrdersResponse> {
    return this.adr8Requester.sendGetRequest({
      api: ADR8Api.Shop,
      query: ADR8Api.OrdersForAccount,
      queryParams: {
        page: params?.page ?? ordersQuerySortedParameters.page,
        size: params?.size ?? ordersQuerySortedParameters.size,
        sort: params?.sort ? ADR8Utils.defaultSortOrders() : undefined,
        orderStatus: params?.orderStatus
      }
    })
      .then((response: OrdersResponse) => ({
        content: response.content.map(orderResponse => this.orderResponseHandler.handleOrderResponse(orderResponse)),
        totalElements: response.totalElements
      }));
  }

  public getOrders(params?: SortOrdersQueryParameters): Promise<{
    content: Order[];
    totalElements: number;
  }> {
    return this.getOrdersImpl(params)
      .then(({content, totalElements}) => ({
        totalElements,
        content: content.map(OrderMapper.orderFromJson)
      }));
  }

  private async getAllOrders(): Promise<OrderResponse[]> {
    const orders: OrderResponse[] = [];
    let page = ordersQueryDefaultParameters.page;

    while (true) {
      try {
        const response = await this.getOrdersImpl({page});
        orders.push(...response.content);
        if (page * ordersQueryDefaultParameters.size >= response.totalElements) {
          return orders;
        } else {
          page++;
        }
      } catch (error) {
        Log.error(TAG, 'Could not get all orders', error);
        return orders;
      }
    }
  }

  public startPayment(params: StartPaymentParams): Promise<Payment> {
    return this.createOrder({...params, offeredPrice: params.price}).then(order => this.startPaymentTransaction({
      paymentMethodId: params.paymentMethodId,
      amount: order.price,
      currency: order.currency,
      referenceId: order.id,
      referenceType: TransactionReferenceType.Order,
      description: 'n/a'
    }));
  }

  public startRepayment(order: Order): Promise<Payment> {
    return this.startPaymentTransaction({
      paymentMethodId: order.paymentMethodId,
      amount: order.price,
      currency: order.currency,
      referenceId: order.id,
      referenceType: TransactionReferenceType.Order,
      description: 'n/a'
    });
  }

  private createOrder(params: CreateOrderParameters): Promise<Order> {
    return this.adr8Requester.sendPostRequest({
      api: ADR8Api.Shop,
      query: ADR8Api.Order,
      body: JSON.stringify(params)
    })
      .then(OrderMapper.orderFromJson)
      .catch(handleTransactionError);
  }

  private startPaymentTransaction(params: PaymentTransactionParams): Promise<Payment> {
    return this.adr8Requester.sendPostRequest({
      api: ADR8Api.Payments,
      query: ADR8Api.PaymentsPublic,
      body: JSON.stringify(params)
    })
      .then(PaymentMapper.paymentFromJson);
  }

  private async getFinalizedOrderById(orderId: string, orderStatus?: OrderStatus, retryCount = 0): Promise<Order> {
    await delay(orderStatusCheckInterval);
    const order = await this.getOrderById(orderId);
    if (orderStatus === order.orderStatus && retryCount < orderStatusCheckMaxRetries) {
      Log.info(TAG, 'getFinalizedOrderById', orderId, 'retryCount', retryCount);
      return this.getFinalizedOrderById(orderId, orderStatus, retryCount + 1);
    }
    switch (order.orderStatus) {
      case OrderStatus.Success:
        return order;

      case OrderStatus.Canceled:
      case OrderStatus.PaymentFailed:
      case OrderStatus.Failed:
        throw new Error(ErrorType.TransactionFailed);
    }
    if (retryCount >= orderStatusCheckMaxRetries) {
      Log.info(TAG, 'getFinalizedOrderById', orderId, 'Max retries reached. retryCount', retryCount);
      return order;
    }
    Log.info(TAG, 'getFinalizedOrderById', orderId, 'retryCount', retryCount);
    return this.getFinalizedOrderById(orderId, orderStatus, retryCount + 1);
  }

  private getOrderById(orderId: string): Promise<Order> {
    return this.adr8Requester.sendGetRequest({
      api: ADR8Api.Shop,
      query: `${ADR8Api.Order}/${orderId}`
    })
      .then((response: OrderResponse) => {
        return OrderMapper.orderFromJson(this.orderResponseHandler.handleOrderResponse(response));
      });
  }

  public getMediaByIds(ids: string[]): Promise<Media[]> {
    if (!ids.length) {
      return Promise.resolve([]);
    }
    return this.adr8Requester.sendPostRequest({
      api: ADR8Api.CatalogueSearch,
      query: ADR8Api.AssetByUids,
      body: JSON.stringify(ids)
    }).then(json => compactMap(json, ContentMapper.mediaFromJson));
  }

  public isEPGAvailable(): boolean {
    return false;
  }

  private checkAssetAccess(id: string): Promise<AssetAccessResponse> {
    return this.adr8Requester.sendGetRequest({
      api: ADR8Api.Shop,
      query: `${ADR8Api.OrderAssetAccess}/${id}`
    })
      .then((response: AssetAccessResponse) => this.orderResponseHandler.handleAssetAccessResponse(id, response));
  }

  public async *getOwned(params: OwnedQueryParameters): AsyncIterableIterator<Media[]> {
    let assetAccessType;
    switch (params.source) {
      case OwnedSource.Bought:
        assetAccessType = {assetAccessType: AssetAccessType.Bought};
        break;
      case OwnedSource.Rented:
        assetAccessType = {assetAccessType: AssetAccessType.Rented};
        break;
      case OwnedSource.All:
        assetAccessType = undefined;
        break;
    }
    let page = 1;
    while (true) {
      try {
        const paging = {page: page++, size: params.pageSize};
        const response = await this.adr8Requester.sendGetRequest({
          api: ADR8Api.Shop,
          query: ADR8Api.MyLibrary,
          queryParams: {
            ...paging,
            sort: `${SortOwnedBy.availableFrom},${SortingOrder.descending}`,
            ...assetAccessType
          }
        });
        const mediaIds = response.content.map(({assetUid}: {assetUid: string}) => assetUid);
        const medias = await this.getMediaByIds(mediaIds);
        if (medias.length < params.pageSize) {
          return medias;
        } else {
          yield medias;
        }
      } catch (error) {
        Log.error(TAG, `Error fetching ${assetAccessType?.assetAccessType} assets`, error);
        return [];
      }
    }
  }

  public cancelOrder(orderId: string): Promise<void> {
    return this.adr8Requester.sendPostRequest({
      api: ADR8Api.Shop,
      query: `${ADR8Api.OrderCancel}/${orderId}`,
      body: ''
    })
      .catch(handleOrderCancelResponse);
  }
}
