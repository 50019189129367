import {createStyles} from 'common-styles';
import React, {useContext, useCallback, useState} from 'react';
import {StyleProp, ViewStyle} from 'react-native';
import {NavigationContext} from 'react-navigation';

import {isBigScreen, isIOS} from 'common/constants';
import {Navigation} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import FocusParent from './FocusParent';
import {Icon, IconType} from './Icon';
import NitroxInteractive from './NitroxInteractive';

export const backButtonSize = 60;
export const backButtonTop = isBigScreen ? 59 : 25;
export const backButtonLeft = isBigScreen ? '10%' : 45;

const styles = createStyles({
  backButton: {
    position: 'absolute',
    top: backButtonTop,
    left: backButtonLeft,
    ...isIOS ? {zIndex: 10} : {},
    height: backButtonSize,
    width: backButtonSize
  },
  wrapper: {
    flex: 1
  },
  button: {
    width: backButtonSize,
    height: backButtonSize
  }
});

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  iconColorFocused: colors.button.primary.background.focused,
  iconColorUnfocused: colors.button.primary.background.unfocused
}));

type BackButtonProps = {
  navigation?: Navigation;
  style?: StyleProp<ViewStyle>;
  hasTVPreferredFocus?: boolean;
  onPress?: () => boolean;
  onFocus?: () => void;
};

const BackButton: React.FunctionComponent<BackButtonProps> = props => {
  const {onPress: propsOnPress, onFocus: propsOnFocus} = props;
  const navigation = useContext(NavigationContext) || props.navigation;
  const [focused, helperOnFocusStateChanged] = useState(false);
  const onFocusStateChanged = useCallback((focused: boolean) => {
    helperOnFocusStateChanged(focused);
    if (focused) {
      propsOnFocus && propsOnFocus();
    }
  }, [helperOnFocusStateChanged, propsOnFocus]);

  const onPress = useCallback(() => {
    const pressHandled = propsOnPress?.();
    if (!pressHandled) {
      navigation && navigation.goBack?.();
    }
  }, [navigation, propsOnPress]);

  const dynamicStyles = dynamicStylesUpdater.getStyles();
  const iconType = focused ? IconType.BackSTBFocused : IconType.BackSTB;
  const iconColor = focused ? dynamicStyles.iconColorFocused : dynamicStyles.iconColorUnfocused;
  return (
    <FocusParent debugName='BackButton' style={[styles.backButton, props.style]}>
      <NitroxInteractive
        onPress={onPress}
        onFocusStateChanged={onFocusStateChanged}
        activeOpacity={isBigScreen ? 1 : undefined}
        style={styles.button}
        testID='button_back'
        hasTVPreferredFocus={props.hasTVPreferredFocus}
      >
        <Icon type={iconType} size={backButtonSize} color={iconColor} />
      </NitroxInteractive>
    </FocusParent>
  );
};

export default React.memo(BackButton);
