import {createStyles, defaultStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {withTranslation} from 'react-i18next';
import {View, ActivityIndicator} from 'react-native';

import {dimensions, getValue, featureFlags, isMobile, isPhone} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {RecordingsFilter, RecordingStatus} from 'mw/api/Metadata';

import DoubleBackground from 'components/DoubleBackground';
import FilterSelect, {createRecordingsFilterOptions} from 'components/FilterSelect';
import {NitroxTabSelector} from 'components/NitroxTabSelector';
import RecordingsList from 'components/pvr/RecordingsList';
import RecordingsSortOrderSelect from 'components/pvr/RecordingsSortOrderSelect';
import QuotaBar from 'components/QuotaBar';
import NitroxScreen from 'screens/NitroxScreen';
import {RecordingSubscreenProps} from 'screens/RecordingsScreenHelperTypes';

const backgroundDecoratorHeight = dimensions.screen.header.height;

const staticStyles = createStyles({
  contentContainer: {
    flex: 1,
    width: '100%',
    paddingHorizontal: getValue({tablet: dimensions.margins.xxLarge, defaultValue: dimensions.margins.large}),
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  filtersContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  quotaBar: {
    width: '100%',
    marginTop: dimensions.margins.large
  },
  tabSelector: {
    width: '90%'
  },
  tabSelectorContainer: {
    position: 'absolute',
    top: Math.floor(backgroundDecoratorHeight / 2),
    left: 0,
    right: 0,
    alignItems: 'center'
  },
  activityIndicator: {
    flex: 1,
    alignSelf: 'center'
  },
  bottomBackground: {
    flexGrow: 1
  },
  selectionMenu: {
    alignSelf: 'flex-end'
  }
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    ...defaultStyles.view,
    backgroundColor: colors.recordingsScreen.background
  },

  headerBackgroundDecorator: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: backgroundDecoratorHeight,
    right: 0,
    backgroundColor: colors.doubleBackground.top,
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30
  }
}));

const RecordingsScreenPiccolo: React.FC<RecordingSubscreenProps> = (props: RecordingSubscreenProps) => {
  const {t, changeRecordingsStatus, onTilePress} = props;
  const tabs = useMemo(() => ([
    {
      label: t('recordings.recorded'),
      onPress: () => changeRecordingsStatus(RecordingStatus.Recorded)
    },
    {
      label: t('recordings.planned'),
      onPress: () => changeRecordingsStatus(RecordingStatus.Scheduled)
    }
  ]), [changeRecordingsStatus, t]);

  const filterRecordingsOptions = useMemo(() => createRecordingsFilterOptions(), []);

  const dynamicStyles = stylesUpdater.getStyles();
  return (
    <NitroxScreen
      style={dynamicStyles.container}
      navigation={props.navigation}
      mobileHeaderProps={{
        title: isMobile && props.selectable ? '' : props.t('common.recordings'),
        showBackButton: false,
        rightContent: props.selectionMenu
      }}
      showOperatorLogo={!isPhone}
      onScreenFocused={props.refreshPVRQuota}
    >
      <DoubleBackground
        overlap={Math.floor(backgroundDecoratorHeight / 2)}
        bottomStyle={staticStyles.bottomBackground}
        bottomChild={(
          <View style={staticStyles.contentContainer}>
            <NitroxTabSelector
              containerStyle={staticStyles.tabSelector}
              items={tabs}
            />
            <QuotaBar style={staticStyles.quotaBar} remaining={props.quota.remaining} available={props.quota.available} />
            <View style={staticStyles.filtersContainer}>
              {featureFlags.recordingsSortingSelection && (
                <RecordingsSortOrderSelect
                  currentSortOrder={props.recordingsSorting}
                  onSortOrderSelected={props.sortRecordings}
                />
              )}
              {featureFlags.recordingsFilterSelection &&
                <FilterSelect<RecordingsFilter> filterOptions={filterRecordingsOptions} onFilterSelected={props.filterRecordings} />}
            </View>
            {props.isLoading ? <ActivityIndicator size='large' animating={true} style={staticStyles.activityIndicator} /> : (
              <RecordingsList
                recordings={props.recordings}
                selectable={props.selectable}
                selection={props.selection}
                requestRecordings={props.requestRecordings}
                onTilePress={onTilePress}
                onSelectionCheckboxPress={props.selectRecording}
              />
            )}
          </View>
        )}
      />
    </NitroxScreen>
  );
};

export default withTranslation()(RecordingsScreenPiccolo);
