import {defaultStyles} from 'common-styles';
import React from 'react';
import {View, StyleSheet} from 'react-native';
import {NavigationScreenProps} from 'react-navigation';

import {Media} from 'mw/api/Metadata';

import MediaPlayer from 'components/mediaplayer/MediaPlayer';
import PlayerManagerComponent from 'components/player/PlayerManager';

type Props = NavigationScreenProps<{
  media: Media;
  position?: number;
}>

const MediaPlayerScreen: React.FunctionComponent<Props> = props => {
  const media = props.navigation.getParam('media');
  const position = props.navigation.getParam('position');

  return (
    <View
      style={StyleSheet.absoluteFill}
    >
      <PlayerManagerComponent navigation={props.navigation} stopPlaybackOnAppear={false} debugName='mediaPlayer' />
      <View style={defaultStyles.view}>
        <MediaPlayer media={media} position={position} />
      </View>
    </View>
  );
};

export default MediaPlayerScreen;
