import {createStyles} from 'common-styles';
import React, {useState, useMemo} from 'react';
import {ViewStyle, View, StyleProp} from 'react-native';

import {RADIUS, dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {arrowSelector} from 'components/ArrowSelector.shared';

import {ArrowSelectorComponentProps} from './ArrowSelector.shared';
import FocusParent from './FocusParent';
import {IconType, Icon} from './Icon';
import NitroxInteractive from './NitroxInteractive';
import NitroxText from './NitroxText';

const defaultTextFieldWidth = 140;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  textStyle: {
    color: colors.arrowSelector.text
  },
  arrowContainerBackground: colors.arrowSelector.bigScreen.iconBackground.default,
  arrowContainerBackgroundFocused: colors.arrowSelector.bigScreen.iconBackground.focused,
  arrowFocused: colors.arrowSelector.bigScreen.icon.focused,
  arrow: colors.arrowSelector.bigScreen.icon.default,
  arrowContainer: {
    borderColor: colors.arrowSelector.bigScreen.border,
    borderWidth: 1,
    borderRadius: arrowSelector.height / 2,
    height: arrowSelector.height,
    width: arrowSelector.height,
    justifyContent: 'center',
    alignItems: 'center'
  },
  textContainer: {
    height: arrowSelector.height,
    marginHorizontal: dimensions.margins.xxLarge,
    borderRadius: RADIUS,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.arrowSelector.bigScreen.textBackground
  }
}));

const styles = createStyles({
  disabled: {
    opacity: dimensions.opacity.xlow
  },
  container: {
    flexDirection: 'row',
    height: arrowSelector.height,
    borderRadius: RADIUS,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
  }
});

const ArrowSelectorGrosso: React.FunctionComponent<ArrowSelectorComponentProps> = props => {
  const {arrowLeftDisabled, arrowRightDisabled, onArrowPressLeft, onArrowPressRight, disabled, displayValue, textFieldWidth} = props;
  const dynamicStyles = stylesUpdater.getStyles();
  const [arrowLeftFocused, setArrowLeftFocused] = useState(false);
  const [arrowRightFocused, setArrowRightFocused] = useState(false);
  const arrowLeftContainerStyle = useMemo<StyleProp<ViewStyle>>(() => [
    dynamicStyles.arrowContainer,
    {backgroundColor: arrowLeftFocused ? dynamicStyles.arrowContainerBackgroundFocused : dynamicStyles.arrowContainerBackground}
  ], [dynamicStyles, arrowLeftFocused]);
  const arrowRightContainerStyle = useMemo<StyleProp<ViewStyle>>(() => [
    dynamicStyles.arrowContainer,
    {backgroundColor: arrowRightFocused ? dynamicStyles.arrowContainerBackgroundFocused : dynamicStyles.arrowContainerBackground}
  ], [dynamicStyles, arrowRightFocused]);
  const textStyle = useMemo(() => [dynamicStyles.textStyle, disabled && styles.disabled], [disabled, dynamicStyles]);
  const textContainer = useMemo(() => [dynamicStyles.textContainer, {width: textFieldWidth ?? defaultTextFieldWidth}], [dynamicStyles]);
  // HACK: Wrapping interactive components to handle opacity change as focused elements do not refresh when changing activeOpacity param
  const arrowLeftOpacity = useMemo(() => ({opacity: (arrowLeftDisabled || disabled) ? dimensions.opacity.xlow : 1}), [arrowLeftDisabled, disabled]);
  const arrowRightOpacity = useMemo(() => ({opacity: (arrowRightDisabled || disabled) ? dimensions.opacity.xlow : 1}), [arrowRightDisabled, disabled]);

  return (
    <FocusParent style={[props.style, styles.container]}>
      <View style={arrowLeftOpacity}>
        <NitroxInteractive
          onPress={onArrowPressLeft}
          onFocusStateChanged={setArrowLeftFocused}
          disabled={disabled}
          style={arrowLeftContainerStyle}
          activeOpacity={1}
        >
          <Icon
            type={IconType.ArrowLeft}
            size={arrowSelector.arrowSize}
            color={arrowLeftFocused ? dynamicStyles.arrowFocused : dynamicStyles.arrow}
          />
        </NitroxInteractive>
      </View>
      <View style={textContainer}>
        <NitroxText
          textType='guardTime'
          style={textStyle}
        >
          {displayValue}
        </NitroxText>
      </View>
      <View style={arrowRightOpacity}>
        <NitroxInteractive
          onPress={onArrowPressRight}
          onFocusStateChanged={setArrowRightFocused}
          disabled={disabled}
          style={arrowRightContainerStyle}
          activeOpacity={1}
        >
          <Icon
            type={IconType.ArrowRight}
            size={arrowSelector.arrowSize}
            color={arrowRightFocused ? dynamicStyles.arrowFocused : dynamicStyles.arrow}
          />
        </NitroxInteractive>
      </View>
    </FocusParent>
  );
};

export default React.memo(ArrowSelectorGrosso);
