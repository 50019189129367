import {createStyles} from 'common-styles';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {View, Animated, Easing} from 'react-native';

import {dimensions, Direction} from 'common/constants';
import {doNothing, humanCaseToSnakeCase, isTruthy} from 'common/HelperFunctions';
import {Size} from 'common/HelperTypes';

import {StylesUpdater, useStyles} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {useChangeEffect, useDisposableCallback, useLazyValue, useScreenInfo} from 'hooks/Hooks';

import {useAnimatedTiming} from './animations/useAnimatedTiming';
import useAnimatedValue from './animations/useAnimatedValue';
import FocusPrison from './focusManager/FocusPrison';
import GradientFill, {GradientFillDirection} from './GradientFill';
import {AnimatedIcon, Icon, IconType} from './Icon';
import {AnimatedIconRound} from './IconRound';
import {Modal} from './Modal';
import MouseAwareView from './MouseAwareView';
import NitroxText from './NitroxText';
import {ProfileSwitcherActionType, ProfileSwitcherDataType} from './profileManagement/ProfileSwitcher.shared';
import {ProfileSelectProps} from './ProfileSelect.shared';
import FiniteList, {FiniteListInterface} from './scroll/FiniteList.grosso';

const iconSize = {
  unfocused: 200,
  focused: 280
};

const sizeAnimationConfig = {
  duration: 200,
  useNativeDriver: false,
  easing: Easing.inOut(Easing.quad)
};

const borderAnimationConfig = {
  duration: 300,
  useNativeDriver: false,
  easing: Easing.in(Easing.quad)
};

const iconContainerRatio = 0.6;

const itemBorderWidth = 8;
const itemPadding = 5;
const focusFrameSize = (itemSize: number) => itemSize + 2 * itemBorderWidth + 2 * itemPadding;
const itemBorderAndPadding = focusFrameSize(0);

const textBoxHeight = 220;
const focusedTextHorizontalOverflow = 25;

const listHeight = focusFrameSize(iconSize.focused) + textBoxHeight;

const arrowIconSize = 107;
const arrowMargin = 100;

const listInset = 2 * arrowIconSize + 2 * arrowMargin;

// this should be dynamic if rwd is considered
const displayedListElements = 5;

const backgroundGradientUpdater = new StylesUpdater(colors => colors.settingsScreen.bigScreen.profileSelect.background.gradient);

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  modal: {
    backgroundColor: colors.settingsScreen.bigScreen.profileSelect.bottomClearance
  },
  headlineText: {
    color: colors.settingsScreen.bigScreen.profileSelect.name,
    textAlign: 'center'
  },
  profileNameText: {
    color: colors.settingsScreen.bigScreen.profileSelect.item.name,
    marginTop: dimensions.margins.xxLarge,
    marginHorizontal: dimensions.margins.xLarge,
    textAlign: 'center'
  },
  profileNameTextFocused: {
    marginHorizontal: 0,
    marginTop: dimensions.margins.medium
  },
  focusFrame: {
    borderWidth: itemBorderWidth,
    borderColor: colors.settingsScreen.bigScreen.profileSelect.item.name,
    padding: dimensions.margins.xsmall,
    justifyContent: 'center',
    alignItems: 'center'
  },
  arrowFocused: {
    backgroundColor: colors.settingsScreen.bigScreen.profileSelect.navArrow.background.focused
  }
}));

const staticStyles = createStyles({
  arrow: {
    height: iconSize.unfocused,
    width: arrowIconSize,
    position: 'absolute',
    top: (() => {
      const unfocusedIconSizeOffset = (iconSize.focused - iconSize.unfocused) / 2;
      const unfocusedIconMarginOffset = itemBorderWidth + itemPadding;

      return unfocusedIconSizeOffset + unfocusedIconMarginOffset;
    })(),
    alignItems: 'center',
    justifyContent: 'center'
  },
  arrowLeft: {
    left: arrowMargin
  },
  arrowRight: {
    right: arrowMargin
  },
  focusPrison: {
    flex: 1,
    width: '100%',
    flexDirection: 'column'
  },
  overlayContent: {
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  listSection: {
    height: listHeight,
    width: '100%',
    alignItems: 'center'
  },
  contentContainer: {
    justifyContent: 'center'
  },
  header: {
    // TODO CL-1083, restore flex header/footer layout when avatar change button is added
    // flex: 1,
    justifyContent: 'flex-end',
    paddingBottom: dimensions.margins.xxxxLarge
  },
  footer: {
    // TODO CL-1083, see 'header' style
    // flex: 1
  },
  bottomClearance: {
    height: 90
  },
  // outer container provides space for focused element scaling
  itemOuterContainer: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  itemInnerContainer: {
    justifyContent: 'center',
    alignItems: 'center'
  }
});

function BottomClearance({onClick}: {onClick?: () => void}) {
  return (
    <MouseAwareView onClick={onClick} style={staticStyles.bottomClearance} />
  );
}

const Arrow: React.FC<{
  // Can't use (Direction.Left | Direction.Right) because of
  // https://github.com/microsoft/TypeScript/issues/35875
  direction: Direction
  onClick: () => void;
}> = ({
  direction,
  onClick
}) => {
  const styles = stylesUpdater.getStyles();

  return (
    <MouseAwareView
      style={[
        staticStyles.arrow,
        direction === Direction.Left && staticStyles.arrowLeft,
        direction === Direction.Right && staticStyles.arrowRight
      ]}
      hoverStyle={styles.arrowFocused}
      onClick={onClick}
      testID={`button_arrow_${direction}`}
    >
      <Icon type={direction === Direction.Left ? IconType.ArrowLeftThin : IconType.ArrowRightThin} size={arrowIconSize} />
    </MouseAwareView>
  );
};

const ProfileSelectGrosso2: React.FC<ProfileSelectProps> = props => {
  const {
    visible = true,
    onClose: propsOnClose,
    profiles,
    onSelect,
    currentProfile,
    title,
    portal,
    onCancel,
    onAddNewPress
  } = props;
  const {t} = useTranslation();
  const screenInfo = useScreenInfo();
  const styles = useStyles(stylesUpdater);
  const backgroundGradient = useStyles(backgroundGradientUpdater);

  const onClose = useDisposableCallback(propsOnClose || doNothing);

  const listRef = useRef<FiniteListInterface<ProfileSwitcherDataType>>(null);

  const onPress = useCallback((data: ProfileSwitcherDataType) => {
    if (data === ProfileSwitcherActionType.AddNew) {
      onAddNewPress?.();
      return;
    } else {
      onSelect(data.id);
    }
  }, [onAddNewPress, onSelect]);

  const listWidth = screenInfo.size.width - listInset;

  const elementWidth = listWidth / displayedListElements;
  const elementSize = useMemo(() => ({width: elementWidth, height: listHeight}), [elementWidth]);

  const renderElement = useCallback(({
    element,
    focused,
    selected,
    nearViewPort
  }: {
    element: ProfileSwitcherDataType;
    focused: boolean;
    selected: boolean;
    nearViewPort: boolean;
  }) => {
    return (
      <ProfileSelectGrossoItem
        data={element}
        onClick={onPress}
        key={element === ProfileSwitcherActionType.AddNew ? 'add_new' : element.id}
        focused={focused}
        selected={selected}
        nearViewPort={nearViewPort}
        size={elementSize}
      />
    );
  }, [elementSize, onPress]);

  const elements = useMemo<ProfileSwitcherDataType[]>(() => {
    const actions = [
      currentProfile?.isMain && !!onAddNewPress && ProfileSwitcherActionType.AddNew
    ].filter(isTruthy);

    const profilesData = profiles.map(profile => {
      const name = `${profile.name}${profile.isMain ? t('common.masterProfileSuffix') : ''}`;

      return {name, id: profile.id};
    });

    return [...actions, ...profilesData] as ProfileSwitcherDataType[];
  }, [currentProfile?.isMain, onAddNewPress, profiles, t]);

  const currentElement = useMemo(() => {
    if (!currentProfile) {
      return undefined;
    }

    return elements.find(element => element !== ProfileSwitcherActionType.AddNew && element.id === currentProfile.id);
  }, [currentProfile, elements]);

  const scrollLeft = useCallback(() => {
    listRef.current?.scrollBy(-1);
  }, []);

  const scrollRight = useCallback(() => {
    listRef.current?.scrollBy(1);
  }, []);

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      contentStyle={[staticStyles.contentContainer, screenInfo.size]}
      style={styles.modal}
      portal={portal}
      focusEnterStrategy='byPriority'
      focusNearestParentOnClose={false}
    >
      <FocusPrison
        focusOnAppear
        style={staticStyles.focusPrison}
      >
        <View style={staticStyles.overlayContent}>
          <GradientFill
            direction={GradientFillDirection.DiagonalLeft}
            colors={[
              backgroundGradient.start,
              backgroundGradient.stop
            ]}
          />
          <View style={staticStyles.header}>
            <NitroxText textType='profile-selector-title' style={styles.headlineText}>{title ?? t('common.switchProfile')}</NitroxText>
          </View>
          <View style={staticStyles.listSection}>
            <View
              style={{
                height: listHeight,
                width: listWidth
              }}
            >
              <FiniteList<ProfileSwitcherDataType>
                displayedElements={displayedListElements}
                elementSize={elementSize}
                elements={elements}
                fixedFocusPosition={
                  (listWidth / 2) - (elementWidth / 2)
                }
                focused={!!visible}
                horizontal
                onPress={onPress}
                renderElement={renderElement}
                currentElement={currentElement}
                ref={listRef}
              />
            </View>
            <Arrow
              direction={Direction.Left}
              onClick={scrollLeft}
            />
            <Arrow
              direction={Direction.Right}
              onClick={scrollRight}
            />
          </View>
          {/* TODO: CL-1083, PLM-168. This is just "spacer" now. To be replaced with actions container when avatars are implemented. */}
          <View style={staticStyles.footer} />
        </View>
        <BottomClearance onClick={onClose} />
      </FocusPrison>
    </Modal>
  );
};

type ProfileSelectGrossoItemProps = {
  data: ProfileSwitcherDataType;
  focused: boolean;
  selected: boolean;
  nearViewPort: boolean;
  onClick: (data: ProfileSwitcherDataType) => void;
  size: Size;
}

const focusAnimationConfig = {
  values: {
    unfocused: 0,
    focused: 100
  }
};

const focusAnimationRange = [
  focusAnimationConfig.values.unfocused,
  focusAnimationConfig.values.focused
];

function useFocusAnimation({
  focused,
  size
}: {
  focused: boolean;
  size: number;
}) {
  const stopAnimationRef = useRef<() => void>();

  const animatedSize = useAnimatedValue(size);
  const focusAnimation = useAnimatedValue(
    focused
      ? focusAnimationConfig.values.focused
      : focusAnimationConfig.values.unfocused
  );

  const {createAnimation: createFocusTransitionAnimationTo} = useAnimatedTiming(focusAnimation, borderAnimationConfig);
  const {createAnimation: createSizeAnimationTo} = useAnimatedTiming(animatedSize, sizeAnimationConfig);

  useChangeEffect(() => {
    stopAnimationRef.current?.();

    const focusAnimation = createFocusTransitionAnimationTo(
      focused
        ? focusAnimationConfig.values.focused
        : focusAnimationConfig.values.unfocused
    );
    const sizeAnimation = createSizeAnimationTo(size);

    const animation = Animated.parallel([
      focusAnimation,
      sizeAnimation
    ]);
    stopAnimationRef.current = animation.stop;

    animation.start();
  }, [focused, size]);

  return {animatedSize, focusAnimation};
}

function FocusFrame({
  size: animatedSize,
  children,
  focusAnimation
}: {
  size: Animated.Animated;
  children: React.ReactNode;
  focusAnimation: Animated.Value;
}) {
  const styles = useStyles(stylesUpdater);

  const borderColor = useLazyValue(() => focusAnimation.interpolate(
    {
      inputRange: focusAnimationRange,
      outputRange: ['transparent', styles.focusFrame.borderColor]
    }
  ));

  const borderWidth = useLazyValue(() => focusAnimation.interpolate(
    {inputRange: focusAnimationRange, outputRange: [0, styles.focusFrame.borderWidth]}
  ));

  const borderRadius = useLazyValue(() => Animated.divide(animatedSize, 2));

  return (
    <Animated.View
      style={[
        {width: animatedSize, height: animatedSize, borderRadius},
        styles.focusFrame,
        {borderColor, borderWidth}
      ]}
    >
      {children}
    </Animated.View>
  );
}

const ProfileSelectGrossoItem: React.FC<ProfileSelectGrossoItemProps> = ({
  data,
  onClick: propsOnClick,
  focused,
  selected,
  nearViewPort,
  size
}) => {
  const [profile, action] = data === ProfileSwitcherActionType.AddNew
    ? [null, data]
    : [data, null];

  const {t} = useTranslation();
  const [hovered, onHoverChange] = useState(false);
  const onClick = useCallback(() => propsOnClick(data), [propsOnClick, data]);
  const testID = `button_${humanCaseToSnakeCase(profile?.name ?? 'add_new_profile')}`;

  const styles = stylesUpdater.getStyles();

  const text = useMemo(() => {
    if (profile) {
      return profile.name;
    } else if (action === ProfileSwitcherActionType.AddNew) {
      return t('settings.addNew');
    }
  }, [action, profile, t]);

  const currentIconSize = Math.min(size.width, focused ? iconSize.focused : iconSize.unfocused);

  const {animatedSize: currentIconSizeAnimated, focusAnimation} = useFocusAnimation({focused: focused || hovered, size: currentIconSize});

  const frameSize = useLazyValue(() => Animated.add(currentIconSizeAnimated, itemBorderAndPadding));

  if (!nearViewPort) {
    return null;
  }

  return (
    <View
      style={[staticStyles.itemOuterContainer, size]}
    >
      <MouseAwareView
        onHoverChange={onHoverChange}
        onClick={onClick}
        testID={testID}
        style={[staticStyles.itemInnerContainer, size]}
      >
        {profile && (
          <FocusFrame
            size={frameSize}
            focusAnimation={focusAnimation}
          >
            <AnimatedIconRound
              type={IconType.UserAvatar}
              size={currentIconSizeAnimated}
              iconContainerRatio={iconContainerRatio}
            />
          </FocusFrame>
        )}
        {action && (
          <FocusFrame
            size={frameSize}
            focusAnimation={focusAnimation}
          >
            <Animated.View style={{width: currentIconSizeAnimated, height: currentIconSizeAnimated}}>
              <AnimatedIcon
                type={IconType.PlusCircle}
                size={currentIconSizeAnimated}
              />
            </Animated.View>
          </FocusFrame>
        )}
        <View
          style={{
            height: textBoxHeight,
            marginHorizontal: focused
              ? -focusedTextHorizontalOverflow
              : 0,
            alignSelf: 'stretch'
          }}
        >
          <NitroxText
            textType={focused ? 'profile-selector-item-focused' : 'profile-selector-item'}
            style={[styles.profileNameText, focused && styles.profileNameTextFocused]}
            numberOfLines={4}
          >
            {text}
          </NitroxText>
        </View>
      </MouseAwareView>
    </View>
  );
};

export default ProfileSelectGrosso2;
