import {Insets, PanResponderGestureState} from 'react-native';

import {Axis} from 'common/constants';
import {Size} from 'common/HelperTypes';

import {getScreenInfo} from 'hooks/Hooks';

/*
 * REMINDER: This is coordinate system orientation in React Native:
 *                      x
 *   +------------------->
 *   |
 *   |
 *   |
 *   |
 *   |
 *   |
 *   |
 * y |
 *   v
 *
 */

export const swipeVelocityThreshold = 0.3;
export const swipeDistanceThreshold = 100;

/**
  * @param point Point coordinates in distance to container's each side
  */
export function inInsets(point: Required<Insets>, insets: Insets) {
  return (
    (insets.left == null || point.left >= insets.left) &&
    (insets.right == null || point.right >= insets.right) &&
    (insets.top == null || point.top >= insets.top) &&
    (insets.bottom == null || point.bottom >= insets.bottom)
  );
}

export function getGesturePosition(gestureState: PanResponderGestureState) {
  return {
    x: gestureState.moveX - gestureState.dx,
    y: gestureState.moveY - gestureState.dy
  };
}

export function getDistanceFromEdges(
  gestureState: PanResponderGestureState,
  {width, height}: Partial<Size> = {}
) {
  const screenSize = getScreenInfo().size;
  width = width ?? screenSize.width;
  height = height ?? screenSize.height;
  const {x, y} = getGesturePosition(gestureState);

  return {
    left: x,
    right: width - x,
    top: y,
    bottom: height - y
  };
}

type IsSwipeParams = {
  distanceThreshold?: number;
  velocityThreshold?: number;
};
/**
 * Checks if gesture is swipe in given direction.
 * To be considered a swipe, gesture has to exceed both velocity and distance thresholds.
 */
export function isSwipe(
  gestureState: PanResponderGestureState,
  axis: Axis,
  {distanceThreshold = swipeDistanceThreshold, velocityThreshold = swipeVelocityThreshold}: IsSwipeParams = {}
) {
  const horizontal = axis === Axis.X;

  const velocity = Math.abs(horizontal ? gestureState.vx : gestureState.vy);
  const distance = Math.abs(horizontal ? gestureState.dx : gestureState.dy);

  return velocity > velocityThreshold && distance > distanceThreshold;
}
