import {Log} from 'common/Log';

import {VideojsNitroxPlayer} from 'mw/playback/web-native-player/adapters/videojs/VideojsNitroxPlayer';

const TAG = 'VideojsNitroxAudioPlayer';

export class VideojsNitroxAudioPlayer extends VideojsNitroxPlayer {

  protected getLiveWindow(): number {
    Log.error(TAG, 'getLiveWindow: live window is not available for audio content type');
    return 0;
  }

  protected drmInit(): void {
    Log.warn(TAG, 'drmInit: no drm implementation for audio');
  }
}
