import {DateUtils} from 'common/DateUtils';
import {Log} from 'common/Log';

import {Error, ErrorType} from 'mw/api/Error';
import {nxffConfig} from 'mw/api/NXFF';
import {Storage} from 'mw/utils/Storage';

const TAG = 'Resources';

interface Properties {
  [name: string]: boolean;
}

interface Resource {
  actions?: Properties;
  props?: Properties;
  relations?: Properties;
}

interface ResourceMap {
  [name: string]: Resource;
}

interface ResourceStorage {
  resourceMap: ResourceMap;
  usedResources: string[];
}

const usedResources = [
  'CAP',
  'CAPInfo',
  'Category',
  'Channel',
  'ChannelList',
  'Content',
  'Coupon',
  'Cpe',
  'Customer',
  'Event',
  'Message',
  'Policy',
  'Product',
  'Profile',
  'Recording',
  'Root',
  'Title',
  'Series'
];

const resourceValidationTimeOffset = 1 * DateUtils.msInHour;

class Resources {
  public static instance = new Resources();
  private resources: ResourceMap = {};
  private propertyValue = 'Value';
  private actionExtensionsValue = 'actionName';
  private storage?: Storage<ResourceStorage>;
  private lastValidationTime = 0;

  public initializeStorage(): void {
    const storageName = `Traxis_${nxffConfig.getProfileName()}_${nxffConfig.getBackOfficeCode()}-Resources`;
    this.storage = new Storage<ResourceStorage>(storageName);
  }

  public getStorage(): Storage<ResourceStorage> {
    if (!this.storage) {
      throw new Error(ErrorType.UnknownError, 'try to use uninitialized storage');
    }

    return this.storage;
  }

  public shouldUpdate(): boolean {
    return Date.now() - this.lastValidationTime > resourceValidationTimeOffset;
  }

  public loadFromCache(): Promise<boolean> {
    return this.getStorage().get()
      .then((resourceStorage) => {
        if (!(resourceStorage?.resourceMap && resourceStorage.usedResources)) {
          return false;
        }

        const usedResourcesChanged = resourceStorage.usedResources.length !== usedResources.length ||
          JSON.stringify(resourceStorage.usedResources) !== JSON.stringify(usedResources);
        if (usedResourcesChanged) {
          return false;
        }

        this.resources = resourceStorage.resourceMap;
        return true;
      });
  }

  public parse(response: any): ResourceMap {
    const resources: {[name: string]: Resource} = {};
    const traxisResources = response.Resources;
    traxisResources.Resource
      .filter((resource: any) => usedResources.includes(resource.id))
      .forEach((resource: any) => {
        const resourceName = resource.id;
        const resourceObject: Resource = {};

        if (resource.Properties) {
          resourceObject.props = {};
          Resources.parseProperties(resource.Properties.Property, resourceObject.props, this.propertyValue);
        }
        if (resource.Relations) {
          resourceObject.relations = {};
          Resources.parseProperties(resource.Relations.Relation, resourceObject.relations, this.propertyValue);
        }
        if (resource.Actions) {
          resourceObject.actions = {};
          const actions = resource.Actions;
          if (actions.Action) {
            Resources.parseProperties(actions.Action, resourceObject.actions, this.propertyValue);
          }
          if (actions.ActionExtensions) {
            Resources.parseProperties(actions.ActionExtensions, resourceObject.actions, this.actionExtensionsValue);
          }
        }

        resources[resourceName.toLowerCase()] = resourceObject;
      });

    return resources;
  }

  public initialize(response: any): void {
    Log.debug(TAG, 'initialize resources');
    this.lastValidationTime = Date.now();
    this.resources = this.parse(response);
    this.getStorage().save({
      resourceMap: this.resources,
      usedResources: usedResources
    });
  }

  private static parseProperties(resource: any, resourceObject: Properties, property: string): void {
    const resourceArray = resource instanceof Array ? resource : [resource];
    resourceArray.forEach((element: any): void => {
      const prop = element[property];
      if (prop) {
        resourceObject[prop.toLowerCase()] = true;
      }
    });
  }

  private isPropKnown(boResource: Resource, property: string): boolean {
    const lowerCaseProperty = property.toLowerCase();
    return boResource.props && boResource.props[lowerCaseProperty] || boResource.relations && boResource.relations[lowerCaseProperty] || false;
  }

  public removeUnknownProperties(resourceName: string, props: string): string {
    if (!props) {
      return '';
    }

    const boResource = this.resources[resourceName.toLowerCase()];
    if (!boResource) {
      return '';
    }

    return props
      .split(',')
      .filter(property => this.isPropKnown(boResource, property))
      .join(',');
  }

  public chooseSupportedProperty(resourceName: string, propsArray: string[]): string {
    const boResource = this.resources[resourceName.toLowerCase()];
    if (!boResource) {
      return '';
    }
    return propsArray.find(property => this.isPropKnown(boResource, property)) || '';
  }

  public chooseSupportedAction(resourceName: string, actionsArray: string[]): string {
    const boResource = this.resources[resourceName.toLowerCase()];
    if (!boResource || !boResource.actions) {
      return '';
    }

    return actionsArray.find(action => {
      const lowerCaseAction = action.toLowerCase();
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return boResource.actions![lowerCaseAction]; // assertion checked above
    }) || '';
  }

  public isActionSupported(resourceName: string, actionName: string): boolean {
    return !!this.chooseSupportedAction(resourceName, [actionName]);
  }

  public hasProperties(resourceName: string, props: string): boolean {
    const boResource = this.resources[resourceName.toLowerCase()];
    if (!boResource) {
      return false;
    }
    return props
      .split(',')
      .every(property => this.isPropKnown(boResource, property));
  }

  public hasResource(resourceName: string): boolean {
    return this.resources[resourceName.toLowerCase()] ? true : false;
  }

  public hasRelation(resourceName: string, relationName: string) {
    const boResource = this.resources[resourceName.toLowerCase()];
    return !!boResource?.relations?.[relationName.toLowerCase()];
  }
}

export const resources: Resources = Resources.instance;
