import {createStyles} from 'common-styles';
import React, {useMemo, useState, useCallback} from 'react';
import {View} from 'react-native';
import Animated from 'react-native-reanimated';

import {Direction, isTVOS} from 'common/constants';
import {useDelayedFocusTVOS} from 'common/hooks/useDelayedFocusTVOS';

import {StylesUpdater} from 'common-styles/StylesUpdater';

import {Channel} from 'mw/api/Metadata';

import ReanimatedScrollView from 'components/epg/animated/ReanimatedScrollView';
import {Icon, IconType} from 'components/Icon';
import {SupportedKeys} from 'components/KeyEventManager';
import MouseAwareView from 'components/MouseAwareView';
import {useInteractionHandle} from 'components/performance/InteractionHandle';
import performanceConstants from 'components/performance/performanceConstants';
import {calculateWrapAroundIndex, calculateWrapAroundDistance} from 'components/scroll/scrollUtils';
import {useDiscreteWheelHandler} from 'components/scroll/useDiscreteWheelHandler';
import {PreventDefaultWebScroll} from 'components/scroll/usePreventDefaultWebScroll';
import {useReanimatedScrollTo} from 'components/scroll/useReanimatedScrollTo';
import {useLazyRef, useChangeEffect} from 'hooks/Hooks';
import {useArrowsListener, useKeyListener} from 'hooks/rcuHooks';

import {tvChannelListConstants} from './ChannelListConstants';
import ChannelTile from './ChannelListTile';

const arrowIconSize = 16;

const staticStyles = createStyles({
  scrollViewContainer: {
    position: 'absolute',
    top: 0,
    left: 130,
    bottom: 0,
    width: tvChannelListConstants.channelTileWidth,
    justifyContent: 'center',
    alignItems: 'center'
  },
  scrollView: {
    height: tvChannelListConstants.scrollViewHeight,
    // web
    maxHeight: tvChannelListConstants.scrollViewHeight,
    minHeight: tvChannelListConstants.scrollViewHeight,

    width: tvChannelListConstants.channelTileWidth,
    overflow: 'hidden'
  },
  arrow: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  arrowUp: {
    marginTop: 'auto',
    height: 100
  },
  arrowDown: {
    bottom: 0,
    height: 100,
    marginBottom: 'auto'
  }
});

const stylesUpdater = new StylesUpdater(colors => createStyles({
  arrowFocused: {
    backgroundColor: colors.tvScreen.navArrow.background.focused
  }
}));

const ChannelListItem: React.FC<{
  index: number;
}> = ({
  index,
  children
}) => {
  return (
    <Animated.View
      style={{
        width: tvChannelListConstants.channelTileWidth,
        height: tvChannelListConstants.channelTileHeight,
        position: 'absolute',
        top: tvChannelListConstants.channelTileHeight * index,
        left: 0
      }}
      testID='channel_list_item'
    >
      {children}
    </Animated.View>
  );
};

const Arrow: React.FC<{
  // Can't use (Direction.Up | Direction.Down) because of
  // https://github.com/microsoft/TypeScript/issues/35875
  direction: Direction
  onClick: () => void;
}> = ({
  direction,
  onClick
}) => {
  const styles = stylesUpdater.getStyles();

  return (
    <MouseAwareView
      style={[
        staticStyles.arrow,
        direction === Direction.Up && staticStyles.arrowUp,
        direction === Direction.Down && staticStyles.arrowDown
      ]}
      hoverStyle={styles.arrowFocused}
      onClick={onClick}
      testID={
        direction === Direction.Up
          ? 'channel_list_arrow_up'
          : 'channel_list_arrow_down'
      }
    >
      <Icon type={direction === Direction.Up ? IconType.SlimArrowUp : IconType.SlimArrowDown} size={arrowIconSize} />
    </MouseAwareView>
  );
};

const TvChannelList: React.FC<{
  channel: Channel;
  channels: Channel[];
  onChannelPress: (channel: Channel) => void;
  showOverlay: () => void;
  visible: boolean;
}> = ({
  channel: currentChannel,
  channels,
  onChannelPress,
  showOverlay,
  visible: propsListVisible
}) => {
  const {obtainedFocus: obtainedFocusTVOS} = useDelayedFocusTVOS(propsListVisible);
  const listVisible = isTVOS
    ? propsListVisible && obtainedFocusTVOS
    : propsListVisible;

  const currentChannelIndex = useMemo(() => channels.indexOf(currentChannel), [channels, currentChannel]);
  const [focusedChannelIndex, setFocusedChannelIndex] = useState(currentChannelIndex);
  const contentPosition = useLazyRef(() => new Animated.Value<number>(-focusedChannelIndex * tvChannelListConstants.channelTileHeight)).current;

  useChangeEffect(() => {
    setFocusedChannelIndex(currentChannelIndex);
  }, [currentChannelIndex]);

  useChangeEffect(() => {
    if (!listVisible) {
      setFocusedChannelIndex(currentChannelIndex);
    }
  }, [listVisible], [currentChannelIndex]);

  const isNearViewPort = useCallback((channelIndex: number) => {
    const distance = calculateWrapAroundDistance(channels.length, channelIndex, focusedChannelIndex);
    return distance <= tvChannelListConstants.displayedChannels;
  }, [channels.length, focusedChannelIndex]);

  const renderChannel = useCallback((channel: Channel, index: number) => {
    return (
      <ChannelListItem index={index} key={index}>
        <ChannelTile
          channel={channel}
          focused={index === focusedChannelIndex}
          selected={channel === currentChannel}
          nearViewPort={isNearViewPort(index)}
          listVisible={listVisible}
          onClick={onChannelPress}
        />
      </ChannelListItem>
    );
  }, [currentChannel, focusedChannelIndex, isNearViewPort, listVisible, onChannelPress]);

  const {scrollTo} = useReanimatedScrollTo(contentPosition);

  const {createTemporary: createTemporaryAnimationHandle} = useInteractionHandle(performanceConstants.interactionHandle.scrollDelay);

  useChangeEffect(() => {
    createTemporaryAnimationHandle();
    scrollTo(-focusedChannelIndex * tvChannelListConstants.channelTileHeight);
  }, [contentPosition, focusedChannelIndex, scrollTo]);

  const scrollBy = useCallback((delta: number) => {
    setFocusedChannelIndex(index => calculateWrapAroundIndex(channels.length, index + delta));
  }, [channels.length]);

  const onArrowUp = useCallback(() => {
    scrollBy(-1);
  }, [scrollBy]);

  const onArrowDown = useCallback(() => {
    scrollBy(1);
  }, [scrollBy]);

  useArrowsListener(direction => {
    if (direction === Direction.Up) {
      onArrowUp();
    } else {
      onArrowDown();
    }
  }, [Direction.Up, Direction.Down], {handleTVOSPanGesture: true, active: listVisible});

  useKeyListener(SupportedKeys.Ok, () => {
    onChannelPress(channels[focusedChannelIndex]);
  }, {active: listVisible});

  const onNavArrowUp = useCallback(() => {
    // TvScreen triggers `showOverlay` on RCU arrows internally, rest cases should be handled manually
    showOverlay();
    onArrowUp();
  }, [onArrowUp, showOverlay]);

  const onNavArrowDown = useCallback(() => {
    showOverlay();
    onArrowDown();
  }, [onArrowDown, showOverlay]);

  const fixedFocusContentPosition = useLazyRef(() => Animated.add(contentPosition, tvChannelListConstants.fixedFocusPosition)).current;
  const {onWheel} = useDiscreteWheelHandler(direction => {
    direction === Direction.Up
      ? onArrowUp()
      : onArrowDown();

    showOverlay();
  }, {
    directions: [Direction.Up, Direction.Down],
    onWheel: showOverlay,
    active: listVisible
  });

  return (
    <View
      style={staticStyles.scrollViewContainer}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onWheel={onWheel}
    >
      <Arrow direction={Direction.Up} onClick={onNavArrowUp} />
      <ReanimatedScrollView
        size={{
          width: tvChannelListConstants.channelTileWidth,
          height: channels.length * tvChannelListConstants.channelTileHeight
        }}
        style={staticStyles.scrollView}
        positionY={fixedFocusContentPosition}
        testID='channel_list_scroll_view'
      >
        {channels.map(renderChannel)}
      </ReanimatedScrollView>
      <Arrow direction={Direction.Down} onClick={onNavArrowDown} />
      <PreventDefaultWebScroll />
    </View>
  );
};

export default React.memo(TvChannelList);
