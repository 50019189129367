import React, {useMemo, createContext, useContext, useState, useCallback} from 'react';

import {Event, Media, Title, isTitle, isEvent} from 'mw/api/Metadata';

import {useUnlockModal} from 'components/unlockmodal/UnlockModal';

export type PCMedia = Title | Event;

export type ParentalControlContextType = {
  isMediaBlocked: (media: PCMedia) => boolean;
  resetUnlockedMedia: () => void;
  shouldBeCheckedForPC: (media: Media | null | undefined) => media is PCMedia;
  unlockedMedia: PCMedia[];
  unlockMedia: (media: PCMedia | null | undefined) => Promise<boolean>,
  unlockModalVisible: boolean;
}

const ParentalControlContext = createContext<ParentalControlContextType>({
  isMediaBlocked: media => true,
  resetUnlockedMedia: () => {},
  shouldBeCheckedForPC: (media: Media | null | undefined): media is PCMedia => true,
  unlockMedia: media => Promise.resolve(false),
  unlockedMedia: [],
  unlockModalVisible: false
});

export const useParentalControl = () => {
  return useContext(ParentalControlContext);
};

export const ParentalControlProvider: React.FC = ({children}) => {
  const [unlockedMedia, setUnlockedMedia] = useState<PCMedia[]>([]);
  const {renderModal, authorizeWithAdultProfile, unlockModalVisible} = useUnlockModal();

  const isMediaBlocked = useCallback((media: PCMedia) => !unlockedMedia.some(({id}) => id === media.id) && media.isBlocked(), [unlockedMedia]);

  const unlockMedia = useCallback(async (media?: PCMedia | null) => {
    if (!media) {
      return false;
    }
    if (isMediaBlocked(media)) {
      await authorizeWithAdultProfile();

      setUnlockedMedia([
        ...unlockedMedia,
        media,
        ...((media as Title)?.trailers ?? [])
      ]);
    }
    return true;
  }, [authorizeWithAdultProfile, isMediaBlocked, unlockedMedia]);

  const resetUnlockedMedia = useCallback(() => setUnlockedMedia([]), []);

  const shouldBeCheckedForPC = useCallback((media?: Media | null): media is PCMedia => {
    return isEvent(media) || isTitle(media);
  }, []);

  const contextValue = useMemo((): ParentalControlContextType => {
    return {
      isMediaBlocked,
      resetUnlockedMedia,
      shouldBeCheckedForPC,
      unlockMedia,
      unlockedMedia,
      unlockModalVisible
    };
  }, [isMediaBlocked, resetUnlockedMedia, shouldBeCheckedForPC, unlockMedia, unlockModalVisible, unlockedMedia]);

  return (
    <ParentalControlContext.Provider value={contextValue}>
      {children}
      {renderModal()}
    </ParentalControlContext.Provider>
  );
};

type WrappedProps<T> = Omit<T, keyof ParentalControlContextType>;

export function withParentalControl<Props extends ParentalControlContextType>(WrappedComponent: React.ComponentType<Props>): React.ComponentType<WrappedProps<Props>> {
  function Wrapped(props: WrappedProps<Props>) {
    const parentalControlContextProps = useContext(ParentalControlContext);
    return (
      <WrappedComponent
        {...props as Props}
        {...parentalControlContextProps}
      />
    );
  }
  Wrapped.displayName = WrappedComponent.displayName;
  return Wrapped;
}
