import {Channel, ChannelList} from 'mw/api/Metadata';

export class ChannelListObject implements ChannelList {
  public readonly id: string;
  public name: string;
  public channels: Channel[];

  public constructor(id: string, name: string, channels: Channel[] = []) {
    this.id = id;
    this.name = name;
    this.channels = channels;
  }
}
