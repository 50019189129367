import {createStyles} from 'common-styles';
import React, {useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {isPhone} from 'common/constants';

import SelectionPopup, {SelectionPopupSection} from 'components/SelectionPopup';
import {Selection} from 'components/utils/Selection';
import {PostProcessors} from 'locales/i18nPostProcessors';

const styles = createStyles({
  columnStyle: {
    alignItems: 'center'
  }
});

interface Props<T extends {id: string}> {
  visible: boolean;
  selection?: Selection<T>;
  title: string;
  info: string;
  onCancel: () => void;
  onConfirmation: (selection?: Selection<T>) => void;
}

enum OptionKey {
  Cancel = 'Cancel',
  Delete = 'Delete'
}

const popupProps = {
  actions: []
};

type DeleteConfirmationPopupI<T extends {id: string} = any> = React.FC<Props<T>>

const DeleteConfirmationPopup: DeleteConfirmationPopupI = props => {
  const {onCancel, onConfirmation, selection, title, info} = props;
  const {t} = useTranslation();

  const onDelete = useCallback(() => {
    onConfirmation && onConfirmation(selection);
  }, [selection, onConfirmation]);

  const sections: SelectionPopupSection[] = useMemo(() => {
    const options = [{
      key: OptionKey.Cancel,
      text: t('common.cancel', {postProcess: PostProcessors.ToUpperCase}),
      onPress: onCancel
    }];
    if (!selection || selection.hasSelected()) {
      options.push({
        key: OptionKey.Delete,
        text: t('common.delete', {postProcess: PostProcessors.ToUpperCase}),
        onPress: onDelete
      });
    }
    return [{options}];
  }, [onCancel, onDelete, selection, t]);

  const columns = useMemo(() => {
    return (sections[0] && sections[0].options.length) || 0;
  }, [sections]);

  return (
    <SelectionPopup
      sections={sections}
      popupProps={popupProps}
      title={title}
      info={info}
      focusedKey={OptionKey.Cancel}
      onClose={onCancel}
      visible={props.visible}
      loading={false}
      columnStyle={styles.columnStyle}
      columnCount={isPhone ? 1 : columns}
    />
  );
};

export default React.memo(DeleteConfirmationPopup);
