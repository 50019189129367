import {Log} from 'common/Log';

import {Filter} from 'mw/api/Filter';
import {Media, VodSorting, SortingOrder, VodFilter} from 'mw/api/Metadata';
import {OwnedSource} from 'mw/bo-proxy/BOInterface';
import {boProxy} from 'mw/bo-proxy/BOProxy';
import {mw} from 'mw/MW';

const TAG = 'ContentCache';

export type RecommendationsSource = 'vod' | 'epg';

export type RecommendationsQueryParameters = {
  pageSize: number;
  offset: number;
  sources: RecommendationsSource[];
  media?: Media;
};

export type ContentQueryParameters = {
  filter?: VodFilter;
  sorting?: VodSorting;
  pageNumber?: number; //page number, counting starts from page 0
  pageSize: number;
};

export enum SpecialFilter {
  EPGRecommendations = 'EPGRecommendations',
  VODRecommendations = 'VODRecommendations',
  ContinueWatching = 'ContinueWatching',
  WatchList = 'Watchlist',
  RentedAssets = 'RentedAssets',
  BoughtAssets = 'BoughtAssets',
  BoughtAndRentedAssets = 'BoughtAndRentedAssets',
  RecommendationsForWatchedMovie = 'RecommendationsForWatchedMovie',
  RecommendationsForWatchedGenre = 'RecommendationsForWatchedGenre'
}

function generateVodSortingKey(vodSorting?: VodSorting): string {
  return vodSorting ? `${vodSorting.type}.${vodSorting.ascending ? SortingOrder.ascending : SortingOrder.descending}` : '';
}

function filterArrayIds(filters: Filter[]): string {
  return filters.map(({value}) => value).join('|');
}

function generateContentKey(filters: Filter[], parameters: ContentQueryParameters): string {
  // use only properties that identify the items that are fetched and the order in which they are returned
  return `${filterArrayIds(filters)}-${filters[0].isPersonal}-${filters[0].isSpecial}-${parameters.filter}-` + generateVodSortingKey(parameters.sorting);
}

type ContentCacheEntry = {
  /**
   * Cached content. This may represent only a slice of the BO's results set starting from any position within it.
   */
  media: Media[];
  /**
   * Indicates whether the last BO's page request is done and has fetched all of the items.
   */
  done: boolean;
  /**
   * Index at which the cached data starts in the overall results set.
   */
  offset: number;
  /**
   * The max amount of items that should be stored in cached. If there are less items in cache than this value it means that there are no more items that could be fetched.
   */
  limit: number;
  /**
   * Date when this entry was last updated by adding new items.
   */
  retrieved: Date;
}

export class ContentCache {
  private entries = new Map<string, ContentCacheEntry>();

  public clear(): void {
    this.entries.clear();
  }

  public removeSpecialContent(specialFilter: SpecialFilter): void {
    Log.debug(TAG, `Trying to remove special cache content ${specialFilter}`);
    Array.from(this.entries.keys())
      .filter(key => key.startsWith(specialFilter))
      .forEach(key => {
        Log.debug(TAG, `Removing cache content ${key}`);
        this.entries.delete(key);
      });
  }

  public async *getRecommendations(pageSize: number, sources: RecommendationsSource[], media?: Media): AsyncIterableIterator<Media[]> {
    Log.debug(TAG, `Getting ${pageSize} recommendations of type ${sources}` + (media ? ` for media ${media}` : ''));
    return await boProxy.bo.getRecommendations({offset: 0, pageSize, media, sources});
  }

  public async *getContinueWatching(): AsyncIterableIterator<Media[]> {
    Log.debug(TAG, 'Getting continue watching');
    return await boProxy.bo.getContinueWatching();
  }

  public async *getWatchList(pageSize: number): AsyncIterableIterator<Media[]> {
    Log.debug(TAG, `Getting ${pageSize} items from WatchList`);
    if (!mw.customer.currentProfile?.watchList.length) {
      return [];
    }
    let page = 0;
    while (true) {
      const start = pageSize * page++;
      const medias = mw.customer.currentProfile.watchList.slice(start, start + pageSize);
      if (medias.length < pageSize) {
        return medias;
      } else {
        yield medias;
      }
    }
  }

  public getOwned(pageSize: number, source: OwnedSource): AsyncIterableIterator<Media[]> {
    Log.debug(TAG, `Getting ${pageSize} owned items from ${source} media`);
    return boProxy.bo.getOwned({pageSize, source});
  }

  public getContent(filters: Filter[], parameters: ContentQueryParameters): AsyncIterableIterator<Media[]> {
    return this.findContent(filters, parameters) || this.fetchContent(filters, parameters);
  }

  private findContent(filters: Filter[], parameters: ContentQueryParameters): AsyncIterableIterator<Media[]> | null {
    // when given array of Filters, assume that all of them have the same settings
    const {cacheTimeInMinutes} = filters[0];
    // simply ignore looking for the content in cache in case the given filter has no cache time set
    if (cacheTimeInMinutes <= 0) {
      Log.debug(TAG, 'Caching content of ' + filterArrayIds(filters) + ' was disabled');
      return null;
    }
    // look for the content in cache
    const key = generateContentKey(filters, parameters);
    const entry = this.entries.get(key);
    if (!entry) {
      Log.debug(TAG, 'Content ' + key + ' not found in cache - we need to fetch it');
      return null;
    }
    // remove outdated entries
    if (Date.now() - entry.retrieved.getTime() > cacheTimeInMinutes * 60 * 1000) {
      Log.debug(TAG, 'Found outdated cached content ' + key + ' - removing it and fetching new one');
      this.entries.delete(key);
      return null;
    }
    // in case there is not enough data we will simply remove the cached entry and fetch it once more
    const pageNumber = Math.max(parameters.pageNumber || 0, 0);
    const offset = parameters.pageSize * pageNumber;
    if (offset < entry.offset || offset + parameters.pageSize > entry.offset + entry.media.length) {
      Log.debug(TAG, 'Found cached content ' + key + ' with not enough data to fill the requested page of size ' + parameters.pageSize);
      if (entry.media.length >= entry.limit) {
        Log.debug(TAG, 'Content for ' + key + ' can be fetched - removing it from cache and fetching it');
        this.entries.delete(key);
        return null;
      } else {
        Log.debug(TAG, 'There is no more content ' + key + ' to be fetched');
      }
    }
    Log.debug(TAG, 'Found valid cached content ' + key + ' retrieved at ' + entry.retrieved + ' - using it');
    return (async function* (contentCache, iteratorContext): AsyncIterableIterator<Media[]> {
      while (true) {
        const index = parameters.pageSize * iteratorContext.pageNumber;
        const page = entry.media.slice(index, index + parameters.pageSize);
        if (page.length >= parameters.pageSize) {
          Log.debug(TAG, 'Found ' + page.length + ' items for page ' + iteratorContext.pageNumber + ' for cached content ' + key);
          yield page;
        } else {
          // we have already fetch every available item
          if (entry.done) {
            Log.debug(TAG, 'Found remaining ' + page.length + ' items for the last page of ' + iteratorContext.pageNumber + ' for cached content ' + key);
            return page;
          }
          // in case there are still some items available delegate fetching them
          Log.debug(TAG, 'There are no more items for cached content ' + key + ' to fill the page ' + iteratorContext.pageNumber + ' - we need to start fetching it');
          const iterator = contentCache.fetchContent(filters, {
            ...parameters,
            pageNumber: iteratorContext.pageNumber
          });
          const nextPage = await iterator.next();
          if (nextPage.done) {
            return nextPage.value;
          } else {
            yield nextPage.value;
          }
        }
        iteratorContext.pageNumber++;
      }
    })(this, {pageNumber});
  }

  private fetchContent(filters: Filter[], parameters: ContentQueryParameters): AsyncIterableIterator<Media[]> {
    // request a single page of content and return it as is if we do not want to store it in cache
    const iterator = this.requestContent(filters, parameters);
    if (filters[0].cacheTimeInMinutes <= 0) {
      return iterator;
    }
    // return async iterator that will store every fetched page of content
    const pageNumber = Math.max(parameters.pageNumber || 0, 0);
    const key = generateContentKey(filters, parameters);
    return (async function* (contentCache, iteratorContext): AsyncIterableIterator<Media[]> {
      while (true) {
        try {
          const page = await iterator.next();
          const retrieved = new Date();
          Log.debug(TAG, 'Fetched ' + page.value.length + ' items for page ' + iteratorContext.pageNumber + ' for cached content ' + key +
            ' at ' + retrieved + (page.done ? ' - there are no more items available' : ' - there are still some items available'));
          const entry = contentCache.entries.get(key);
          const index = parameters.pageSize * iteratorContext.pageNumber;
          if (entry) {
            // we need to insert items in a specific index to make sure we do not override any previously cached items and also bear in mind that pageSize can change on every getContent call
            Log.trace(TAG, 'Inserting ' + page.value.length + ' items at index ' + index + ' for cached content ' + key);
            Array.prototype.splice.apply(entry.media, ([index, 0] as any).concat(page.value));
            entry.done = !!page.done;
            entry.offset = Math.min(entry.offset, index);
            entry.limit = Math.max(entry.limit, index + parameters.pageSize) - entry.offset;
            entry.retrieved = retrieved;
          } else {
            Log.trace(TAG, 'Setting ' + page.value.length + ' items for cached content ' + key);
            contentCache.entries.set(key, {
              media: page.value,
              done: !!page.done,
              offset: index,
              limit: parameters.pageSize,
              retrieved
            });
          }
          // make sure to return a proper value of the done prop
          if (page.done) {
            return page.value;
          } else {
            yield page.value;
          }
          iteratorContext.pageNumber++; // fetch next page in the next iteration
        } catch (reason) {
          Log.debug(TAG, 'Failed to fetch items for content ' + key + ' - reason ' + reason);
          throw reason;
        }
      }
    })(this, {pageNumber});
  }

  private requestContent(filters: Filter[], parameters: ContentQueryParameters): AsyncIterableIterator<Media[]> {
    Log.debug(TAG, 'Requesting content for filters ' + filterArrayIds(filters));
    if (filters.length === 1 && filters[0].isSpecial) {
      switch (filters[0].value) {
        case SpecialFilter.EPGRecommendations:
          return this.getRecommendations(parameters.pageSize, ['epg']);
        case SpecialFilter.VODRecommendations:
          return this.getRecommendations(parameters.pageSize, ['vod']);
        case SpecialFilter.WatchList:
          return this.getWatchList(parameters.pageSize);
        case SpecialFilter.ContinueWatching:
          return this.getContinueWatching();
        case SpecialFilter.RentedAssets:
          return this.getOwned(parameters.pageSize, OwnedSource.Rented);
        case SpecialFilter.BoughtAssets:
          return this.getOwned(parameters.pageSize, OwnedSource.Bought);
        case SpecialFilter.BoughtAndRentedAssets:
          return this.getOwned(parameters.pageSize, OwnedSource.All);
        default:
          throw new Error(`Special filter ${filters[0].value} not supported!`);
      }
    }
    return boProxy.bo.getContent(filters, parameters);
  }
}

