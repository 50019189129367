import {EventEmitter} from 'common/EventEmitter';

import {NativePlaybackParameters} from 'mw/playback/types/NativePlaybackParameters';
import {PlaybackRequestParameters, PlaybackResponse} from 'mw/playback/types/PlaybackParameters';

export enum RewindDirection {
  FastForward = 1,
  FastBackwards = -1
}

export enum PlaybackRewinderEvent {
  Rewinding = 'Rewinding',
  PositionChanged = 'PositionChanged',
  Stopped = 'Stopped'
}

export interface RewindingPositionChangedEvent {
  position: number;
}

export interface RewindingEvent extends RewindingPositionChangedEvent {
  delta: number;
}

export type ChangeParametersHandler = (playbackParameters: PlaybackRequestParameters) => Promise<PlaybackResponse>;

export abstract class PlaybackRewinder extends EventEmitter<PlaybackRewinderEvent> {
  public constructor() {
    super();
  }

  public abstract isRewinding(): boolean;
  public abstract getRewindDirection(): RewindDirection | undefined;
  public abstract start(direction: RewindDirection): Promise<void>;
  public abstract stop(): Promise<void>;
  public abstract updateParameters(parameters: NativePlaybackParameters): Promise<void>;
}
