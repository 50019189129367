import {PlaybackProperties, OngoingEventRecording, Channel, MediaType, Playable, PlayableType, Location} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import {MediaObject} from './MediaObject';

export class ChannelObject extends MediaObject implements Channel {
  public longName: string;
  public lcn: number;
  public location: Location;
  public playbackProperties: PlaybackProperties | null = null;
  public isAvailableByPolicy = true;
  public isNetworkRecordingAllowed = false;
  public recordingInThePastLimit = 0;
  public ongoingEventRecording?: OngoingEventRecording;

  public constructor(id: string, name: string, longName: string, lcn: number, url = '', fallback = false) {
    super(id, name);
    this.name = name ?? `${lcn}`;
    this.longName = longName;
    this.location = new Location(url, fallback);
    this.lcn = lcn;
  }

  public getType(): MediaType {
    return MediaType.Channel;
  }

  public getPlayable(): Playable | null {
    return this;
  }

  public getUrl(): string {
    return this.location.url;
  }

  public getPlayableType(): PlayableType {
    return PlayableType.Channel;
  }

  public getId(): string {
    return this.id;
  }

  public isAllowed(): boolean {
    // Currently application has only entitled ad viewable channels
    return true;
  }

  public hasFallbackPlayback(): boolean {
    return this.location.fallback;
  }

  public isBlocked(): boolean {
    const profile = mw.customer.currentProfile;
    if (!profile) {
      return true;
    }
    if (!profile.isPCEnabled) {
      return false;
    }
    return this.isAdult;
  }
}
