import {createStyles} from 'common-styles';
import React, {forwardRef, useCallback, useState, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import {Omit, withNavigation} from 'react-navigation';

import {AppRoutes, dimensions, isDesktopBrowser, isSTBBrowser} from 'common/constants';
import {unique} from 'common/HelperFunctions';
import {asyncIterator} from 'common/helpers/ArrayHelperFunctions';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {SearchSource} from 'mw/api/CatalogInterface';
import {Media} from 'mw/api/Metadata';

import AnimatedSwimlaneStackBase, {SwimlaneComponent} from 'components/AnimatedSwimlaneStackBase';
import FocusParent from 'components/FocusParent';
import MediaTile, {MediaTileType, mediaTileMarginHorizontal} from 'components/mediaTiles/MediaTile';
import {useMediaTileFocusedStyle} from 'components/mediaTiles/MediaTileBase';
import NitroxButton from 'components/NitroxButton';
import NitroxText from 'components/NitroxText';
import {SwimlaneProps, SwimlaneTileProps, AnimatedTileProps} from 'components/Swimlane';
import {useChangeEffect, useFunction} from 'hooks/Hooks';
import {PostProcessors} from 'locales/i18nPostProcessors';

import NoResults from './NoResults';
import {SearchResultProps} from './SearchResults';

const swimlaneInsets = {
  left: dimensions.screen.container.paddingHorizontal - mediaTileMarginHorizontal
};

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flex: 1
  },
  headerContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  resultTitleAndClearContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: dimensions.inputs.width.search + dimensions.margins.small
  },
  swimlaneContainer: {
    flex: 1
  },
  label: {
    marginTop: dimensions.margins.large,
    marginBottom: dimensions.margins.large * 2,
    color: colors.searchScreen.header,
    marginLeft: dimensions.margins.small
  }
}));

type SearchSwimlaneComponent = SwimlaneComponent<Media> & {
  searchSource: SearchSource;
};

const swimlaneHeight = dimensions.tile.height + dimensions.margins.xLarge;
const swimlaneLimit = 14;

function SearchResultsGrosso(props: SearchResultProps) {
  const {searchResult, navigation, onClearResults, searchText, searchResultsFocusPriority} = props;
  const {t} = useTranslation();

  const [swimlaneData, setSwimlaneData] = useState<SearchSwimlaneComponent[]>([]);

  useChangeEffect(() => {
    const swimlaneData: SearchSwimlaneComponent[] = [];

    if (!searchResult) {
      setSwimlaneData([]);
      return;
    }

    if (searchResult.channel?.length) {
      swimlaneData.push({
        title: t('search.channels'),
        dataFetcher: asyncIterator(searchResult.channel),
        searchSource: SearchSource.Channel
      });
    }
    if (searchResult.vod?.length) {
      swimlaneData.push({
        title: t('search.movies'),
        dataFetcher: asyncIterator(searchResult.vod),
        searchSource: SearchSource.Vod
      });
    }
    if (searchResult.epgPast?.length || searchResult.epgNow?.length || searchResult.epgFuture?.length || searchResult.epgPvr?.length) {
      const events = unique([
        ...searchResult.epgNow || [],
        ...searchResult.epgPvr || [],
        ...searchResult.epgFuture || [],
        ...searchResult.epgPast || []
      ], 'id');
      swimlaneData.push({
        title: t('search.tv'),
        dataFetcher: asyncIterator(events),
        searchSource: SearchSource.Epg
      });
    }

    setSwimlaneData(swimlaneData);
  }, [searchResult, t], []);

  const styles = stylesUpdater.getStyles();

  const createTile = useCallback((props: SwimlaneTileProps<Media>, animatedTileProps?: AnimatedTileProps) => (
    (focused: boolean) => (
      <MediaTile
        ref={animatedTileProps?.refHandler}
        media={props.data as Media}
        onFocus={props.onFocus}
        staticallyFocused={focused}
        tileType={MediaTileType.Search}
      />
    )
  ), []);

  const setupSwimlane = useCallback((row: number): Omit<SwimlaneProps<Media>, 'createTile'> => {
    return {
      header: swimlaneData[row].title,
      row,
      insets: {
        left: swimlaneInsets.left
      },
      headerInsetLeft: mediaTileMarginHorizontal,
      renderNavigationArrows: isDesktopBrowser && !isSTBBrowser,
      itemWidth: dimensions.tile.width + 2 * dimensions.margins.small,
      itemHeight: dimensions.tile.height + dimensions.margins.small,
      dataFetcher: swimlaneData[row]?.dataFetcher,
      showHeaderActions: true
    };
  }, [swimlaneData]);

  const openSearchResultFull = useFunction((searchSource: SearchSource) => {
    navigation?.push(AppRoutes.SearchResultFull, {
      searchSource,
      searchResult,
      searchPhrase: searchText
    });
  });

  const createVisibilityLimit = useCallback((searchSource: SearchSource) => ({
    shouldApply: (row?: number) => (typeof row !== 'undefined' && swimlaneData[row].searchSource === searchSource),
    name: 'showAll',
    label: t('common.showAll', {postProcess: PostProcessors.ToUpperCase}),
    limit: swimlaneLimit,
    onPress: () => openSearchResultFull(searchSource)
  }), [t, swimlaneData, openSearchResultFull]);

  const visibilityLimits = useMemo(() => ([
    createVisibilityLimit(SearchSource.Channel),
    createVisibilityLimit(SearchSource.Epg),
    createVisibilityLimit(SearchSource.Vod)
  ]), [createVisibilityLimit]);

  const focusedTileFrameStyle = useMediaTileFocusedStyle();
  const renderFocusedTileFrame = useCallback(() => {
    return <View style={focusedTileFrameStyle} />;
  }, [focusedTileFrameStyle]);

  if (!searchResult) {
    return null;
  }

  return (
    <View style={styles.container}>
      <FocusParent style={styles.headerContainer}>
        <View style={styles.resultTitleAndClearContainer}>
          <NitroxText textType='headline' style={styles.label}>{t('search.resultsFor', {searchText: searchText})}</NitroxText>
          <NitroxButton
            text={t('search.clearResults', {postProcess: PostProcessors.ToUpperCase})}
            onPress={onClearResults}
            testID={'button_clear_search_results'}
          />
        </View>
      </FocusParent>
      <FocusParent style={styles.swimlaneContainer} focusPriority={searchResultsFocusPriority} rememberLastFocused>
        {!swimlaneData.length ? (
          <NoResults />
        ) : (
          <AnimatedSwimlaneStackBase<Media>
            swimlaneComponents={swimlaneData}
            swimlaneItemHeight={swimlaneHeight}
            setupSwimlane={setupSwimlane}
            createTile={createTile}
            visibilityLimits={visibilityLimits}
            renderFocusedTileFrame={renderFocusedTileFrame}
          />
        )}
      </FocusParent>
    </View>
  );
}

export default withNavigation(forwardRef(SearchResultsGrosso));
