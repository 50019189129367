import {createStyles} from 'common-styles';
import React, {useMemo, useState, useCallback, useImperativeHandle, RefAttributes} from 'react';
import {View, StyleSheet} from 'react-native';
import Animated from 'react-native-reanimated';

import {Direction, isTVOS} from 'common/constants';
import {Size} from 'common/HelperTypes';
import {useDelayedFocusTVOS} from 'common/hooks/useDelayedFocusTVOS';

import ReanimatedScrollView from 'components/epg/animated/ReanimatedScrollView';
import {SupportedKeys} from 'components/KeyEventManager';
import {calculateWrapAroundIndex, calculateWrapAroundDistance} from 'components/scroll/scrollUtils';
import {useLazyRef, useChangeEffect, useKeyEventHandler} from 'hooks/Hooks';
import {useArrowsListener} from 'hooks/rcuHooks';

import {useReanimatedScrollTo} from './useReanimatedScrollTo';

const tag = 'FiniteList';

const staticStyles = createStyles({
  scrollViewContainer: {
    ...StyleSheet.absoluteFillObject
  },
  scrollView: {
    ...StyleSheet.absoluteFillObject,
    overflow: 'hidden'
  }
});

const FiniteListItem: React.FC<{
  index: number;
  horizontal: boolean;
  size: Size;
}> = ({
  index,
  children,
  horizontal,
  size
}) => {
  return (
    <Animated.View
      style={[
        {
          ...size,
          position: 'absolute',
          top: 0,
          left: 0
        },
        horizontal && {left: size.width * index},
        !horizontal && {top: size.height * index}
      ]}
    >
      {children}
    </Animated.View>
  );
};

type FiniteListProps<T> = {
  elementSize: Size;
  displayedElements: number;

  currentElement?: T;
  elements: T[];
  onPress: (element: T) => void;

  /**
   * Unseen things will happen on change attempt during component lifecycle.
   * This is yet to be developed if needed.
   */
  horizontal: boolean;

  renderElement: (config: {
    element: T;
    focused: boolean;
    selected: boolean;
    nearViewPort: boolean;
  }) => React.ReactNode;

  fixedFocusPosition: number;

  focused: boolean;
};

export type FiniteListInterface<T> = {
  scrollToIndex: (index: number) => void;
  scrollToElement: (element: T) => void;
  scrollBy: (delta: number) => void;
}

/**
 * List designed for components with constant data set, like profile switcher or finite swimlane.
 * Uses imperative scroll underneath, therefore `focused` prop is needed to indicate whether arrow keys should be handled.
 * 
 * For components with lazy fetched data, consider creating custom implementation, using this or `TvChannelList` as a reference.
 */
function FiniteListComponent<T>({
  currentElement,
  elements,
  displayedElements,
  onPress,
  elementSize,
  horizontal,
  renderElement: renderElementContent,
  fixedFocusPosition = 0,
  focused: propsFocused
}: FiniteListProps<T>, ref: React.Ref<FiniteListInterface<T>>) {
  const {obtainedFocus: obtainedFocusTVOS} = useDelayedFocusTVOS(propsFocused);

  const focused = isTVOS
    ? obtainedFocusTVOS && propsFocused
    : propsFocused;

  const oriented = useCallback((size: Size) => horizontal ? size.width : size.height, [horizontal]);
  const forwardDirection = horizontal ? Direction.Right : Direction.Down;
  const backwardDirection = horizontal ? Direction.Left : Direction.Up;

  const currentElementIndex = useMemo(() => currentElement ? elements.indexOf(currentElement) : -1, [elements, currentElement]);
  const [focusedElementIndex, setFocusedElementIndex] = useState(currentElementIndex !== -1 ? currentElementIndex : 0);
  const contentPosition = useLazyRef(() => new Animated.Value<number>(-focusedElementIndex * oriented(elementSize))).current;
  const fixedFocusPositionAnimated = useLazyRef(() => new Animated.Value<number>(fixedFocusPosition)).current;

  useChangeEffect(() => {
    fixedFocusPositionAnimated.setValue(fixedFocusPosition);
  }, [fixedFocusPosition]);

  useChangeEffect(() => {
    if (currentElementIndex !== -1) {
      setFocusedElementIndex(currentElementIndex);
    }
  }, [currentElementIndex]);

  const isNearViewPort = useCallback((elementIndex: number) => {
    const distance = calculateWrapAroundDistance(elements.length, elementIndex, focusedElementIndex);
    return distance <= displayedElements;
  }, [displayedElements, elements.length, focusedElementIndex]);

  const renderElement = useCallback((element: T, index: number) => {
    return (
      <FiniteListItem index={index} key={index} horizontal={horizontal} size={elementSize}>
        {
          renderElementContent({
            element,
            focused: index === focusedElementIndex,
            selected: element === currentElement,
            nearViewPort: isNearViewPort(index)
          })
        }
      </FiniteListItem>
    );
  }, [currentElement, elementSize, focusedElementIndex, horizontal, isNearViewPort, renderElementContent]);

  const {scrollTo} = useReanimatedScrollTo(contentPosition);

  useChangeEffect(() => {
    scrollTo(-focusedElementIndex * oriented(elementSize));
  }, [contentPosition, focusedElementIndex, scrollTo], [oriented, elementSize]);

  // Adjust scroll position after element size changes
  useChangeEffect(() => {
    contentPosition.setValue(-focusedElementIndex * oriented(elementSize));
  }, [elementSize], [focusedElementIndex]);

  const scrollBy = useCallback((delta: number) => {
    setFocusedElementIndex(index => calculateWrapAroundIndex(elements.length, index + delta));
  }, [elements.length]);

  const onArrowBackward = useCallback(() => {
    scrollBy(-1);
  }, [scrollBy]);

  const onArrowForward = useCallback(() => {
    scrollBy(1);
  }, [scrollBy]);

  useArrowsListener(direction => {
    if (direction === forwardDirection) {
      onArrowForward();
    } else {
      onArrowBackward();
    }
  }, [forwardDirection, backwardDirection], {handleTVOSPanGesture: true, active: focused});

  useKeyEventHandler('keyup', ({key}) => {
    if (focused && key === SupportedKeys.Ok) {
      onPress(elements[focusedElementIndex]);
    }
  });

  // eslint-disable-next-line schange-rules/no-use-imperative-handle-hook
  useImperativeHandle(ref, () => ({
    scrollBy,
    scrollToIndex: (index: number) => setFocusedElementIndex(index),
    scrollToElement: (element: T) => {
      const index = elements.indexOf(element);
      if (index !== -1) {
        setFocusedElementIndex(index);
      }
    }
  }), [elements, scrollBy]);

  const fixedFocusContentPosition = useLazyRef(() => Animated.add(contentPosition, fixedFocusPositionAnimated)).current;
  return (
    <View style={staticStyles.scrollViewContainer}>
      <ReanimatedScrollView
        size={{
          width: horizontal
            ? elements.length * elementSize.width
            : elementSize.width,
          height: horizontal
            ? elementSize.height
            : elements.length * elementSize.height
        }}
        style={staticStyles.scrollView}
        positionY={
          horizontal
            ? undefined
            : fixedFocusContentPosition
        }
        positionX={
          horizontal
            ? fixedFocusContentPosition
            : undefined
        }
      >
        {elements.map(renderElement)}
      </ReanimatedScrollView>
    </View>
  );
}

type FiniteListType = <T>(props: FiniteListProps<T> & RefAttributes<FiniteListInterface<T>>) => React.ReactElement | null;
const FiniteList: FiniteListType = React.memo(React.forwardRef<FiniteListInterface<any>>(FiniteListComponent as any));

export default FiniteList;
