import {createStyles} from 'common-styles';
import React, {useCallback, useMemo} from 'react';
import {View} from 'react-native';

import {dimensions, tabBarInsetCorrection} from 'common/constants';
import {getMetadataFromMedia} from 'common/HelperFunctions';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Media, PictureType, PictureMode} from 'mw/api/Metadata';
import {mw} from 'mw/MW';
import {ChromecastModule} from 'mw/platform/chromecast/ChromecastModule';

import BackgroundPoster from 'components/mediadetails/BackgroundPoster';
import {BottomMetadataView} from 'components/mediadetails/MetadataView';
import NitroxText from 'components/NitroxText';
import {useToggle, useScreenInfo} from 'hooks/Hooks';

import ChromecastRemoteControlBar from './ChromecastRemoteControlBar';
import ChromecastRemoteControlExpanded from './ChromecastRemoteControlExpanded';
import ChromecastRemoteControlFolded from './ChromecastRemoteControlFolded';

const posterHeight = dimensions.mobilePoster.height;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  text: {
    color: colors.popup.text
  },
  container: {
    width: '100%',
    padding: dimensions.margins.large,
    paddingTop: 0,
    maxHeight: dimensions.chromecast.remoteControl.container.maxHeight,
    backgroundColor: colors.chromecast.remoteController.container.background
  },
  expandedWrapper: {
    top: 0,
    backgroundColor: colors.mediaDetailsScreen.poster.gradientCore
  }
}));

const styles = createStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    position: 'absolute',
    bottom: dimensions.chromecast.remoteControl.wrapper.bottom + tabBarInsetCorrection,
    left: 0
  },
  posterContainer: {
    maxHeight: dimensions.mobilePoster.height,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  mediaInfo: {
    alignItems: 'center',
    paddingHorizontal: dimensions.margins.large,
    maxWidth: '100%',
    bottom: dimensions.chromecast.remoteControl.mediaInfo.bottom
  },
  poster: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  }
});

type Props = {
  media: Media;
  paused: boolean;
  onDisconnectPopupOpen: () => void;
  onLanguagePopupOpen: () => void;
  onStopMediaSending: () => void;
}

const ChromecastRemoteControl: React.FC<Props> = (props) => {
  const {
    onDisconnectPopupOpen,
    media,
    onLanguagePopupOpen,
    onStopMediaSending,
    paused = false
  } = props;
  const [expanded, {on: expandRemoteControl, off: foldRemoteControl}] = useToggle(false);

  const onSkipBackward = useCallback(() => {
    ChromecastModule.seek(-mw.configuration.playbackSkip);
  }, []);

  const onPausePlayback = useCallback(() => {
    ChromecastModule.pause();
  }, []);

  const onStartPlayback = useCallback(() => {
    ChromecastModule.play();
  }, []);

  const metadata = useMemo(() => getMetadataFromMedia(media), [media]);
  const {size: screenSize} = useScreenInfo();
  const posterWidth = Math.max(screenSize.width, screenSize.height);
  const posterUrl = useMemo(() => mw.catalog.getPictureUrl(media, PictureType.Background, posterWidth, posterHeight, PictureMode.BOX), [media, posterWidth]);

  const dynamicStyle = stylesUpdater.getStyles();

  const expandedWrapperStyles = useMemo(() => {
    return [styles.wrapper, expanded && dynamicStyle.expandedWrapper];
  }, [dynamicStyle.expandedWrapper, expanded]);
  const posterStyles = useMemo(() => {
    return [styles.poster, {maxHeight: posterHeight}];
  }, []);

  const remoteControlProps = {
    paused,
    onSkipBackward,
    onStartPlayback,
    onPausePlayback
  };

  return (
    <View style={expandedWrapperStyles}>
      {expanded && (
        <View style={styles.posterContainer}>
          <BackgroundPoster imageUri={posterUrl} style={posterStyles} imageResizeMode='cover' />
          <View style={styles.mediaInfo}>
            <NitroxText numberOfLines={1} textType='title' style={dynamicStyle.text} >{media.name}</NitroxText>
            <BottomMetadataView media={media} metadata={metadata} />
          </View>
        </View>
      )}
      <View style={dynamicStyle.container}>
        <ChromecastRemoteControlBar expanded={expanded} expandRemoteControl={expandRemoteControl} foldRemoteControl={foldRemoteControl} />
        {expanded
          ? (
            <ChromecastRemoteControlExpanded
              {...remoteControlProps}
              onDisconnectPopupOpen={onDisconnectPopupOpen}
              onLanguagePopupOpen={onLanguagePopupOpen}
              onStopMediaSending={onStopMediaSending}
            />
          )
          : (
            <ChromecastRemoteControlFolded
              media={media}
              {...remoteControlProps}
            />
          )}
      </View>
    </View>
  );
};

export default React.memo(ChromecastRemoteControl);
