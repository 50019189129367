import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {View, ViewStyle, StyleProp} from 'react-native';

import {dimensions, isAndroid, featureFlags} from 'common/constants';

import {ItemPosition} from './NitroxContentView';

const styles = createStyles({
  singleContainer: {
    flex: 1
  },
  multipleContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  leftContent: {
    position: 'absolute',
    left: 0,
    height: '100%',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  content: {
    height: '100%'
  },
  rightContent: {
    position: 'absolute',
    right: 0,
    height: '100%',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    alignSelf: 'flex-end'
  }
});

type Props = {
  children: JSX.Element;
  layout?: ItemPosition | {width: number};
  containerStyle?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
};

// There is no matter if we are using FlatBorder or RoundBorder border here because Tile's width is always the same for both.
export const minEpgTileContentWidth = dimensions.epg.minTileContentWidth + dimensions.epg.tileRadius * 2;

const RoundBorder = (props: Props) => {
  const {children, layout, containerStyle: containerStyleProps, contentStyle: contentStyleProps} = props;

  const isNarrow = useMemo(() => {
    return layout && layout.width < minEpgTileContentWidth;
  }, [layout]);

  const containerStyle = useMemo(() => {
    return isAndroid && !isNarrow
      ? [styles.multipleContainer, containerStyleProps]
      : [styles.singleContainer, containerStyleProps, contentStyleProps, {borderRadius: dimensions.epg.tileRadius}];
  }, [isNarrow, containerStyleProps, contentStyleProps]);

  const leftContentStyle = useMemo(() => [
    styles.leftContent, {
      width: dimensions.epg.tileRadius,
      borderTopLeftRadius: dimensions.epg.tileRadius,
      borderBottomLeftRadius: dimensions.epg.tileRadius
    }, contentStyleProps
  ], [contentStyleProps]);

  const contentStyle = useMemo(() => [
    styles.content, {
      marginLeft: dimensions.epg.tileRadius,
      marginRight: dimensions.epg.tileRadius
    }, contentStyleProps
  ], [contentStyleProps]);

  const rightContentStyle = useMemo(() => [
    styles.rightContent, {
      width: dimensions.epg.tileRadius,
      borderTopRightRadius: dimensions.epg.tileRadius,
      borderBottomRightRadius: dimensions.epg.tileRadius
    }, contentStyleProps
  ], [contentStyleProps]);

  // Because of a known bug in react native on Android platforms (https://github.com/facebook/react-native/issues/15826) when a big view
  // (several thousands pixels in any dimension) is rendered with round border it does not render the background. Therefore to overcome
  // this issue on Android platforms we are going to build the rounded border from 3 subsequent Views.
  return (
    <>
      {isAndroid && !isNarrow
        ? (
          <View style={containerStyle}>
            <View style={leftContentStyle} />
            <View style={contentStyle}>
              {children}
            </View>
            <View style={rightContentStyle} />
          </View>
        )
        : (
          <View style={containerStyle}>
            {children}
          </View>
        )
      }
    </>
  );
};

type FlatProps = {
  children: JSX.Element;
  containerStyle?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
};

/**
 * {@link RoundBorder} implies significant performance drop on weak ATV STBs.
 * Temporarly remove views with border radius for all platforms, until performance goals are achieved.
 */
const FlatBorder: React.FC<FlatProps> = (props: FlatProps) => {
  const style = useMemo(() =>[
    styles.singleContainer, props.containerStyle, props.contentStyle
  ], [props.containerStyle, props.contentStyle]);
  return (
    <View style={style}>
      {props.children}
    </View>
  );
};

const component = featureFlags.flatEPGTiles ? FlatBorder : RoundBorder;

export default React.memo(component);
