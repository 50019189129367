import {createStyles} from 'common-styles';
import React, {createRef} from 'react';
import {View, ActivityIndicator, ViewStyle, Insets} from 'react-native';

import {AppRoutes, dimensions, navigationBarHeight, defaultPageSize, isDesktopBrowser, isTablet} from 'common/constants';
import {humanCaseToSnakeCase} from 'common/HelperFunctions';
import {Log} from 'common/Log';
import TestContext from 'common/TestContext';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {VodSorting, VodFilter, PictureType} from 'mw/api/Metadata';
import {nxffConfig} from 'mw/api/NXFF';
import {Component as CMSComponent, isDataSourceFilterBased} from 'mw/cms/Component';
import {Link, LinkType, Menu, MenuRenderType, MenuSubcategoriesMode} from 'mw/cms/Menu';
import {mw} from 'mw/MW';

import FilterSelect, {createVodFilterOptions, FilterOption} from 'components/FilterSelect';
import {HotKeysContextProvider} from 'components/HotKeys';
import {SupportedKeys} from 'components/KeyEventManager';
import MediaGrid from 'components/MediaGrid';
import {mediaTileMarginHorizontal} from 'components/mediaTiles/MediaTile';
import MediaTileSwimlanesStack from 'components/MediaTileSwimlanesStack';
import NitroxButton, {NitroxButtonProps, NitroxButtonTheme} from 'components/NitroxButton';
import {NitroxInteractiveController} from 'components/NitroxInteractiveControllerContext';
import NitroxText from 'components/NitroxText';
import {SwimlaneStackInterface} from 'components/SwimlaneInterfaces';
import {NamedAction} from 'components/utils/SwimlaneVisibilityLimit';
import {Hotspot, Grid} from 'components/uxm-components/UMXComponentStack.shared';
import VodCategoryNavigator, {VodCategory, formatCategoryPath, VodCategoryNavigatorInterface} from 'components/vod/categoryNavigator/VodCategoryNavigator';
import {PromotionalBannerAnimatedScrollView, PromotionalBannerAnimatedScrollViewInterface} from 'components/vod/promotionalBanner/PromotionalBanner';
import VodSortOrderSelect from 'components/vod/VodSortOrderSelect';
import VoucherRedeem from 'components/voucherRedeem/VoucherRedeem';
import {PostProcessors} from 'locales/i18nPostProcessors';

import NitroxScreen from './NitroxScreen';
import {VodCategoryScreenProps, VodCategoryScreenState, vodCategoryScreenMargin} from './VodScreen.shared';

const TAG = 'VodScreen';

const promotionalBannerMarginBottom = 50;
const hotKeys = [SupportedKeys.Info];

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    backgroundColor: colors.vodScreen.background
  },
  categoryNavigatorContainer: {
    marginLeft: 0,
    marginRight: 0
  },
  gridHeader: {
    paddingTop: dimensions.margins.large,
    marginLeft: vodCategoryScreenMargin,
    color: colors.vodScreen.label
  },
  gridContainer: {
    flex: 1,
    width: '100%',
    paddingHorizontal: isTablet ? dimensions.margins.small : 0
  },
  grid: {
    flex: 1
  },
  promotionalBanner: {
    marginLeft: vodCategoryScreenMargin,
    marginRight: vodCategoryScreenMargin,
    marginTop: dimensions.mainMenu.height - 2 * dimensions.margins.xxLarge,
    marginBottom: promotionalBannerMarginBottom
  },
  noBannerPadding: {
    paddingTop: dimensions.mainMenu.height
  },
  activityIndicator: {
    position: 'absolute',
    top: '50%',
    alignSelf: 'center'
  },
  categoryButtonWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: dimensions.margins.xxLarge
  },
  queryParametersContainer: {
    width: '100%',
    flexDirection: 'row'
  },
  sortingContainer: {
    marginLeft: vodCategoryScreenMargin
  },
  filterContainer: {
    marginRight: vodCategoryScreenMargin,
    marginLeft: vodCategoryScreenMargin
  },
  contentFocusParent: {
    flex: 1,
    marginTop: 0
  },
  fillScreen: {
    width: '100%',
    height: '100%'
  },
  redeemButton: {
    height: dimensions.buttons.standard,
    marginHorizontal: dimensions.margins.medium,
    marginTop: dimensions.margins.medium
  }
}));

const testIdContext = {
  NitroxButton: (props: NitroxButtonProps) => props.testID ? props.testID : props.text ? `button_${humanCaseToSnakeCase(props.text)}` : 'button',
  Modal: 'modal_subcategory_selection'
};

const swimlaneInset: Insets = {
  left: mediaTileMarginHorizontal
};

const gridInset: Insets = {
  bottom: dimensions.margins.small
};

function mapMenuToVodCategory(menu: Menu, parent?: VodCategory): VodCategory {
  return {
    parent,
    children: [],
    inlineSubcategories: (menu.subcategoriesMode === MenuSubcategoriesMode.InlineList),
    label: menu.title,
    slug: {
      type: LinkType.MENU,
      slug: menu.slug
    },
    page: menu.link,
    banner: menu.promotionalBanner ?? parent?.banner
  };
}

export default class VodCategoryScreenClassPiccolo extends React.Component<VodCategoryScreenProps, VodCategoryScreenState> {
  private swimlaneStackRef = createRef<SwimlaneStackInterface>();
  private promotionalBannerAnimatedScrollViewRef = createRef<PromotionalBannerAnimatedScrollViewInterface>();
  private categoryNavigatorRef = createRef<VodCategoryNavigatorInterface>();
  private categoryNavigatorStyle: ViewStyle = {};
  private supportedSortOptions = mw.catalog.getAvailableVODSortOptions();
  private filterVodOptions: FilterOption<VodFilter>[];

  public constructor(props: VodCategoryScreenProps) {
    super(props);
    this.state = {
      category: undefined,
      fetchingSubcategories: false,
      queryOptions: {pageSize: defaultPageSize},
      isSeenFilterAvailable: false,
      voucherRedeemModalVisible: false,
      isMenuVisible: true,
      shouldFocusBanner: false
    };
    this.filterVodOptions = createVodFilterOptions();
  }

  /**
   * returns true when button has been handled.
   */
  private categoryNavigatorBackHandler = (): boolean => {
    const parent = this.state.category?.parent;
    if (parent) {
      this.setState({isMenuVisible: true});
      this.onCategoryPress(parent);
    }
    return true;
  }

  public componentDidMount() {
    const navBarVisible = this.props.navigation.getParam('navigationBarVisible', false);
    this.categoryNavigatorStyle = {marginTop: navBarVisible ? navigationBarHeight : 0};
  }

  private fetchSubCategories = async (category?: VodCategory, link?: Link) => {
    const menuSlug = link?.slug;
    if (!menuSlug) {
      Log.info(TAG, 'fetchSubCategories: Did not receive slug for vod menu!');
      return;
    }
    this.setState({fetchingSubcategories: true});
    try {
      const menu: Menu = await mw.cms.getMenu(menuSlug, 1);
      if (!category) {
        category = mapMenuToVodCategory(menu, category);
      }
      category.banner = menu.promotionalBanner ?? category.banner;
      category.children = menu.items
        .filter(subMenu => subMenu.renderType === MenuRenderType.Default)
        .map(subCategory => mapMenuToVodCategory(subCategory, category));
      category.backgroundImageUrl = menu.pictures.find(picture => picture.type === PictureType.BackgroundHorizontal)?.url;
      category.backgroundImageColor = menu.backgroundColor;
      this.setState({
        category: category,
        fetchingSubcategories: false,
        isSeenFilterAvailable: false,
        filters: menu.filter && [menu.filter]
      });
      if (this.swimlaneStackRef.current) {
        this.swimlaneStackRef.current.clear();
        this.swimlaneStackRef.current.fetchPageLayout(menu.link);
      }

      if (category.children.length === 0 && menu.filter) {
        try {
          const isSeenFilterAvailable = await mw.catalog.isSeenFilterAvailableForCategory([menu.filter]);
          this.setState({isSeenFilterAvailable: isSeenFilterAvailable});

        } catch (error) {
          Log.error(TAG, 'Error checking if seen filter is available for subcategory: ', error);
          this.setState({isSeenFilterAvailable: false});
        }
      }
    } catch (error) {
      Log.error(TAG, 'Error fetching subcategories: ', error);
      this.setState({fetchingSubcategories: false});
    }
  };

  private onMenuPress = (menu: Menu) => {
    this.onCategoryPress(mapMenuToVodCategory(menu, this.state.category));
  };

  private onCategoryPress = (category: VodCategory) => {
    Log.debug(TAG, 'onCategoryPress, path: ' + formatCategoryPath(category));
    this.fetchSubCategories(category, category.slug);
  };

  private onSubCategoryPress = (category: VodCategory) => {
    Log.debug(TAG, 'onSubCategoryPress: path: ' + formatCategoryPath(category));
    this.fetchSubCategories(category, category.slug);
  };

  private onScreenFocused = async () => {
    if (!this.state.category) {
      await this.fetchSubCategories(this.state.category, this.props.navigation.getParam('link'));
    }
  };

  private onSortOrderSelected = (sortOrder?: VodSorting) => {
    this.setState(previousState => ({
      queryOptions: {...previousState.queryOptions, sorting: sortOrder}
    }));
  };

  private onFilterSelected = (filter?: VodFilter) => {
    this.setState(previousState => ({
      queryOptions: {...previousState.queryOptions, filter: filter}
    }));
  };

  private onVoucherRedeemModalCloseHandler = () => {
    this.setState({voucherRedeemModalVisible: false});
  };

  private onVoucherRedeemModalOpenHandler = () => {
    this.setState({voucherRedeemModalVisible: true});
  };

  private createShowAllAction = (component: CMSComponent): NamedAction => ({
    name: 'showAll',
    label: this.props.t('common.showAll'),
    onPress: () => this.props.navigation.navigate(AppRoutes.MediaGrid, {component})
  });

  private createVisibilityLimits = (component: CMSComponent) => ([{
    shouldApply: () => true,
    ...this.createShowAllAction(component)
  }]);

  private createStaticHeaderActions = (component: CMSComponent): NamedAction[] => ([this.createShowAllAction(component)]);

  private onFetchedHotspot = (hotspot: Hotspot) => {
    this.setState(prev => ({
      ...prev,
      category: prev.category && {
        ...prev.category,
        banner: hotspot
      }
    }));
  };

  private onFetchedGrid = (grid: Grid) => {
    this.setState(prev => isDataSourceFilterBased(grid.dataSource)
      ? {
        ...prev,
        category: prev.category && {
          ...prev.category,
          page: undefined
        },
        filters: grid.dataSource.filters
      }
      : prev
    );
  };

  public render() {
    const shouldDisplaySwimlanes = !!this.state.category?.page;
    const shouldDisplayGrid = !shouldDisplaySwimlanes && !!this.state.filters?.length;
    const shouldDisplayQueryParameters = !!(shouldDisplayGrid && this.state.filters?.length && this.state.category?.children.length === 0);
    const shouldDisplaySortOptions = this.supportedSortOptions.length > 0;
    const styles = stylesUpdater.getStyles();
    const voucherRedeemButton = (
      <NitroxButton
        onPress={this.onVoucherRedeemModalOpenHandler}
        style={styles.redeemButton}
        text={this.props.t('voucherRedemption.titles.redeem', {postProcess: PostProcessors.ToUpperCase})}
        theme={NitroxButtonTheme.Secondary}
      />
    );
    return (
      <TestContext.Provider value={testIdContext}>
        <NitroxScreen
          style={styles.container}
          navigation={this.props.navigation}
          onBackButtonPressed={this.categoryNavigatorBackHandler}
          onScreenFocused={this.onScreenFocused}
          testID='screen_vod'
        >
          <NitroxInteractiveController omitGeometryCaching>
            <PromotionalBannerAnimatedScrollView
              /* eslint-disable @typescript-eslint/ban-ts-comment */
              // @ts-ignore
              ref={this.promotionalBannerAnimatedScrollViewRef}
              /* eslint-enable @typescript-eslint/ban-ts-comment */
              promoBanner={this.state.category?.banner}
            >
              <View>
                <View style={styles.fillScreen}>
                  <View style={styles.categoryNavigatorContainer}>
                    <VodCategoryNavigator
                      style={this.categoryNavigatorStyle}
                      ref={this.categoryNavigatorRef}
                      category={this.state.category}
                      onSubCategoryPress={this.onSubCategoryPress}
                      onBackPress={this.categoryNavigatorBackHandler}
                      inlineList={this.state.category?.inlineSubcategories}
                    />
                    {nxffConfig.getConfig().Purchases.VoucherRedemptionEnabled && isDesktopBrowser && voucherRedeemButton}
                  </View>
                  <View style={styles.contentFocusParent}>
                    <HotKeysContextProvider hotKeys={hotKeys}>
                      {shouldDisplaySwimlanes && (
                        <MediaTileSwimlanesStack
                          ref={this.swimlaneStackRef}
                          swimlaneInsets={swimlaneInset}
                          headerInsetLeft={mediaTileMarginHorizontal}
                          createVisibilityLimits={this.createVisibilityLimits}
                          createStaticHeaderActions={this.createStaticHeaderActions}
                        />
                      )}
                      {shouldDisplayQueryParameters && (
                        <View>
                          <View style={[styles.queryParametersContainer, {justifyContent: shouldDisplaySortOptions ? 'space-between' : 'flex-end'}]}>
                            {shouldDisplaySortOptions && (
                              <VodSortOrderSelect
                                supportedSortOptions={this.supportedSortOptions}
                                currentSortOrder={this.state.queryOptions.sorting}
                                onSortOrderSelected={this.onSortOrderSelected}
                                style={styles.sortingContainer}
                              />
                            )}
                            {this.state.isSeenFilterAvailable && (
                              <FilterSelect<VodFilter>
                                filterOptions={this.filterVodOptions}
                                currentFilter={this.state.queryOptions.filter}
                                onFilterSelected={this.onFilterSelected}
                                style={styles.filterContainer}
                              />
                            )}
                          </View>
                        </View>
                      )}
                      {shouldDisplayGrid &&
                        <NitroxText textType='headline' style={styles.gridHeader}>{this.state.category ? this.state.category.label : ''}</NitroxText>}
                      {shouldDisplayGrid && !!this.state.filters?.length && (
                        <View style={styles.gridContainer}>
                          <MediaGrid
                            style={styles.grid}
                            contentInset={gridInset}
                            dataSource={this.state.filters}
                            queryOptions={this.state.queryOptions}
                            maximumSpacing={0}
                          />
                        </View>
                      )}
                    </HotKeysContextProvider>
                  </View>
                  <ActivityIndicator style={styles.activityIndicator} animating={this.state.fetchingSubcategories} />
                  {this.state.voucherRedeemModalVisible && <VoucherRedeem onClose={this.onVoucherRedeemModalCloseHandler} />}
                </View>
              </View>
            </PromotionalBannerAnimatedScrollView>
          </NitroxInteractiveController>
        </NitroxScreen>
      </TestContext.Provider>
    );
  }
}
