import React, {useEffect, useState, useCallback, useImperativeHandle, forwardRef, Ref} from 'react';

import {Log} from 'common/Log';

import FocusParent from 'components/FocusParent';

import CreditCardForm from './CreditCardForm';
import {StripeProps, PaymentParams, StripeInterface, StripeError} from './Types';

const TAG = 'Stripe Checkout';

type StripeResultError = {
  message?: string;
  code?: string;
  type?: string;
  decline_code?: string;
}

let stripeAvailable = false;
let loadStripe: any = null;
let Elements: any = null;
let CardNumberElement: any = null;
let CardExpiryElement: any = null;
let CardCvcElement: any = null;
let useStripe: any = null;
let useElements: any = null;
//TODO: CL-5716
try {
  const stripeJs = require('@stripe/stripe-js');
  ({loadStripe} = stripeJs);
  const reactStripeJs = require('@stripe/react-stripe-js');
  ({
    Elements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useStripe,
    useElements
  } = reactStripeJs);
  stripeAvailable = true;
} catch (error) {
  Log.warn(TAG, 'Cannot load stripe', error);
}

const Checkout: React.FC<StripeProps> = (props, ref: Ref<StripeInterface>) => {
  const {
    onPaymentSuccess,
    onPaymentError,
    ...creditCardFormProps
  } = props;

  const stripe = useStripe();
  const elements = useElements();

  const startPayment = useCallback(async (params: PaymentParams) => {
    if (!stripe) {
      return;
    }
    Log.debug(TAG, 'Submitting payment');

    try {
      const result = await stripe.confirmCardPayment(params.clientSecret, {
        'payment_method': {
          'card': elements.getElement(CardNumberElement)
        }
      });
      if (result?.error) {
        const error: StripeResultError = result.error;
        Log.error(TAG, 'Payment error', error);
        onPaymentError(new StripeError(error.message, error.code, error.type, error.decline_code));
      } else {
        onPaymentSuccess();
      }
    } catch (error) {
      Log.error(TAG, 'Payment error', error);
      onPaymentError(error instanceof Error ? error : new Error(`Payment error: '${error}'`));
    }
  }, [stripe, elements, onPaymentError, onPaymentSuccess]);

  useImperativeHandle (ref, () => ({startPayment}), [startPayment]);

  return (
    <CreditCardForm
      cardNumberComponent={CardNumberElement}
      cardExpiryComponent={CardExpiryElement}
      cardCvcComponent={CardCvcElement}
      {...creditCardFormProps}
    />
  );
};

const StripeCheckout = forwardRef(Checkout);

const Stripe: React.FC<StripeProps> = (props, ref: Ref<StripeInterface>) => {
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    if (stripeAvailable && props.publishableKey) {
      setStripe(loadStripe(props.publishableKey));
    }
  }, [props.publishableKey]);

  return (
    <FocusParent>
      {stripe ? (
        <Elements stripe={stripe}>
          <StripeCheckout ref={ref} {...props} />
        </Elements>
      ) : (
        <CreditCardForm
          cardNumberComponent={null}
          cardExpiryComponent={null}
          cardCvcComponent={null}
          {...props}
        />
      )}
    </FocusParent>
  );
};

export default forwardRef(Stripe);
