import {createStyles} from 'common-styles';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ViewStyle, StyleProp, StyleSheet, ActivityIndicator, View} from 'react-native';

import {dimensions, getValue} from 'common/constants';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {mw} from 'mw/MW';

import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';
import ScalableScrollList from 'components/ScalableScrollList';
import {useUnlockModal, UnlockModalRejectionReason} from 'components/unlockmodal/UnlockModal';
import {useProfileList, useCurrentProfile, useProfileProperties} from 'hooks/Hooks';
import {useDisposableCallback} from 'hooks/Hooks';

import {ProfileSwitcherActionType, ProfileSwitcherDataType} from './ProfileSwitcher.shared';
import ProfileSwitcherTile, {tileSize} from './ProfileSwitcherTile';

const TAG = 'AnimatedProfileSwitcher';

const itemWidth = getValue({tablet: dimensions.margins.xLarge + tileSize, defaultValue: tileSize});
const itemHeight = tileSize + dimensions.margins.xxLarge;

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  loadingIndicator: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: colors.settingsScreen.headerBackground
  }
}));

type AnimatedProfileSwitcherProps = {
  onAddNewPress?: () => void;
  onLoading?: (param: boolean) => void;
  style?: StyleProp<ViewStyle>;
  loadingIndicatorStyle?: StyleProp<ViewStyle>;
};

const AnimatedProfileSwitcher: React.FunctionComponent<AnimatedProfileSwitcherProps> = ({
  onAddNewPress,
  style,
  onLoading,
  loadingIndicatorStyle
}) => {
  const {t} = useTranslation();
  const dynamicStyles = dynamicStylesUpdater.getStyles();

  useEffect(() => {
    mw.customer.refreshProfiles();
  }, []);

  const [loading, setLoading] = useState(true);
  const profiles = useProfileList();
  const currentProfile = useCurrentProfile();
  const currentProfileName = useProfileProperties()?.name ?? '';
  const {renderModal, authorizeProfile} = useUnlockModal();
  const {resetUnlockedMedia} = useParentalControl();

  const [data, initialSelectedIndex] = useMemo(() => {
    const actions = currentProfile?.isMain
      ? [ProfileSwitcherActionType.AddNew]
      : [];
    const profilesData = profiles.map(({id, name}) => ({
      id: id,
      // trigger update of data array on profile name change
      name: id === currentProfile?.id
        ? `${currentProfileName}${currentProfile.isMain ? t('common.masterProfileSuffix') : ''}`
        : name
    }));
    return [[...actions, ...profilesData], actions.length];
  }, [profiles, currentProfile, currentProfileName, t]);

  const onRendered = useCallback(() => setLoading(false), []);
  const onRenderedDisposable = useDisposableCallback(onRendered, [onRendered]);

  const onPress = useCallback(async (data: ProfileSwitcherDataType) => {
    if (data === ProfileSwitcherActionType.AddNew) {
      onAddNewPress?.();
      return;
    }
    const profile = profiles.find(({id}) => id === data.id);
    if (!profile) {
      Log.error(TAG, 'Could not find profile with id', data.id);
      return;
    }
    if (profile.id === mw.customer.currentProfile?.id) {
      Log.debug(TAG, 'There is no need to select the same profile');
      return;
    }
    try {
      const pin = profile.isPinRequired ? await authorizeProfile(profile) : null;
      setLoading(true);
      onLoading?.(true);
      mw.customer.setProfile(profile, pin);
      resetUnlockedMedia();
    } catch (error) {
      if (error.reason !== UnlockModalRejectionReason.Cancel) {
        Log.error(TAG, `Failed to select profile ${data}. Got error`, error);
        setLoading(false);
        onLoading?.(false);
      }
    }
  }, [profiles, onAddNewPress, authorizeProfile, onLoading, resetUnlockedMedia]);

  const renderItem = useCallback((data: ProfileSwitcherDataType) => (
    <ProfileSwitcherTile
      data={data}
      selected={data === currentProfile}
      onPress={() => onPress(data)}
    />
  ), [currentProfile, onPress]);

  return (
    <View style={style}>
      <ScalableScrollList<ProfileSwitcherDataType>
        data={data}
        renderItem={renderItem}
        itemSize={itemWidth}
        itemHeight={itemHeight}
        initialSelectedIndex={initialSelectedIndex}
        onRendered={onRenderedDisposable}
      />
      {loading && (
        <ActivityIndicator
          animating
          style={[dynamicStyles.loadingIndicator, loadingIndicatorStyle]}
          size='large'
        />
      )}
      {renderModal()}
    </View>
  );
};

export default React.memo(AnimatedProfileSwitcher);
