import {createStyles} from 'common-styles';
import React, {useMemo, useRef, ReactElement, useCallback, useState} from 'react';
import {View, Insets, ViewStyle, StyleProp, NativeSyntheticEvent, NativeScrollEvent, ListRenderItemInfo, ScrollView} from 'react-native';

import {Direction, dimensions, WEB_ARROW_CONTAINER_WIDTH, isWeb, isDesktopBrowser, isSTBBrowser, isBigScreen, isMobile, isATV, isTVOS, featureFlags} from 'common/constants';
import {indexKeyExtractor, createScrollViewPager, humanCaseToSnakeCase} from 'common/HelperFunctions';
import {createDataSource} from 'common/HelperFunctions';
import {FocusOptions, Rect, TestProps} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {MetaEvent, DataEvents} from 'mw/utils/MetaEventsEmitter';

import {ItemPosition} from 'components/epg/NitroxContentView';
import {NitroxScrollView, ScrollDirection, LayoutItem, ScrollEvent, NitroxScrollViewInterface} from 'components/epg/NitroxScrollView';
import {ListItem} from 'components/ListView';
import {NavArrow} from 'components/NavArrow';
import NitroxText, {getFontStyle} from 'components/NitroxText';
import Separator from 'components/Separator';
import {NamedAction, isLimitedAsyncIterableIterator} from 'components/utils/SwimlaneVisibilityLimit';
import {useFunction, useScreenInfo, useDisposableCallback, useDebounce, useForceUpdate, useChangeEffect, useEventListener, useLazyEffect, useHeaderActions} from 'hooks/Hooks';

import {WithChromecastExtraBottomPadding, withChromecastExtraBottomPadding} from './chromecast/ChromecastExtraBottomPadding';
import {NitroxVerticalScrollView, NitroxHorizontalScrollView} from './epg/NitroxNativeScrollView';
import FocusParent from './FocusParent';
import NitroxFlatList from './NitroxFlatList';
import {NitroxInteractiveController} from './NitroxInteractiveControllerContext';
import {calculateWrapAroundIndex} from './scroll/scrollUtils';

const TAG = 'Swimlane';
const startIndexUpdateDelay = 500;
const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  headerText: {
    marginLeft: dimensions.margins.small,
    color: colors.swimlane.header
  },
  navArrowBackground: colors.swimlane.navArrow.background,
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    layoutItems: 'center'
  },
  headerActionsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: dimensions.margins.xsmall
  },
  headerAction: {
    marginRight: dimensions.margins.medium
  },
  separator: {
    width: 1,
    height: 14,
    marginHorizontal: dimensions.margins.small,
    backgroundColor: colors.swimlane.header
  }
}));

export const swimlaneHeaderHeight = dimensions.margins.xsmall + (getFontStyle('headline').lineHeight || (isMobile ? 25 : 50));

export const defaultHeaderActionsVisibilityThreshold = (isMobile || isDesktopBrowser)
  ? undefined // always visible
  : 1; // user has to navigate to a second tile

export interface SwimlaneTileProps<TileData> {
  data: TileData;
  row: number;
  index: number;
  onFocus?: (event?: any, options?: FocusOptions) => void;
  wrapAroundActive: () => boolean;
}

export type SwimlaneLayoutProps = {
  itemWidth: number;
  itemHeight: number;
  insets?: Insets;
  /** If explicitly set to false the navigation arrows will be aligned to the vertical edges of the swimlane allowing to apply the insets only to swimlane content. */
  alignNavigationArrowsToInsets?: boolean;
  fixedFocusPosition?: boolean;
}

export type SwimlaneDataProps<TileData> = {
  /**
   * For simple use-cases this is a reference to already existing async iterator used to fetch the data.
   * If provided the createDataFactory will be ignored and data fetcher will not be recreated during Swimlane's liftime.
   */
  dataFetcher?: AsyncIterableIterator<TileData[]>;

  /**
   * Factory function that creates a new data fetcher. If provided the data fetcher may be recreated during Swimlane's lifetime.
   * It will be ignored if an already existing instance of data fetcher is provided (see dataFecher prop).
   */
  createDataFetcher?: () => AsyncIterableIterator<TileData[]>;
  onDataFetcherStateChanged?: (state: SwimlaneDataFetcherState, row : number) => void;

  /**
   * Header actions that do not depend on swimlane's state
   */
  staticHeaderActions?: NamedAction[];
} & Partial<DataEvents>;

export type AnimatedTileProps = {
  refHandler?: (ref: unknown) => void
}

export type SwimlaneProps<TileData> = {
  style?: ViewStyle;
  row: number;
  width?: number;
  /**
  * Function used to create swimlane element.
  */
  createTile: (props: SwimlaneTileProps<TileData>, animatedTileProps?: AnimatedTileProps) => ReactElement;
  createHeaderTile?: (props: SwimlaneTileProps<NamedAction>, animatedTileProps?: AnimatedTileProps) => ReactElement;
  /**
   * A marker for telling the Swimlane to re-render. Interface similar to the one used in FlatList.
   */
  extraData?: any;
  header?: string | ((row: number) => ReactElement);
  headerInsetLeft?: number;
  placeholderComponent?: (row: number, focused?: boolean, animatedTileProps?: AnimatedTileProps) => ReactElement | null;
  loadingPlaceholderComponent?: () => ReactElement | null;
  /**
   * Indicates if header actions should be visible or not
   */
  showHeaderActions?: boolean;
  /**
   * Number of tiles that user has to scroll in order to show the header actions.
   */
  headerActionsVisibilityThreshold?: number;
  renderNavigationArrows?: boolean;
  wrapAround?: boolean;
  swimlaneHeight?: number;
  vertical?: boolean;
} & SwimlaneLayoutProps & SwimlaneDataProps<TileData> & TestProps;

export enum SwimlaneDataFetcherState {
  Initializing,
  FetchingFirstPage,
  HasData,
  NoData
}

function SwimlaneComponent<TileData>(props: SwimlaneProps<TileData>) {
  const {
    style,
    row,
    width: propsWidth,
    itemHeight,
    itemWidth,
    createTile,
    extraData,
    placeholderComponent,
    loadingPlaceholderComponent,
    header,
    fixedFocusPosition = isBigScreen,
    insets = {},
    createDataFetcher,
    dataChangeEvent = MetaEvent.None,
    dataRefreshEvent = MetaEvent.None,
    dataEventsEmitter,
    wrapAround = featureFlags.wrapAround,
    renderNavigationArrows = isDesktopBrowser && !isSTBBrowser,
    alignNavigationArrowsToInsets = true,
    swimlaneHeight,
    onDataFetcherStateChanged
  } = props;
  const {size: {width: screenWidth}} = useScreenInfo();
  const width = useMemo(() => propsWidth ?? screenWidth, [propsWidth, screenWidth]);
  const numberOfItemsVisibleAtOnce = useMemo(() => Math.ceil(width / itemWidth), [width, itemWidth]);
  const [dataFetcher, setDataFetcher] = useState(props.dataFetcher || (createDataFetcher?.()));
  const [dataFetcherState, setDataFetcherState] = useState<SwimlaneDataFetcherState>(SwimlaneDataFetcherState.Initializing);
  const dataSource = useRef(createDataSource(dataFetcher, `Swimlane #${row}`));
  const scrollView = useRef<NitroxScrollViewInterface<TileData | null>>(null);
  const wrapAroundActive = useRef(false);
  const startIndex = useRef(0);
  const previousOffset = useRef(0);

  const insetLeft = useMemo(() => {
    return (insets.left || 0) + (renderNavigationArrows ? WEB_ARROW_CONTAINER_WIDTH : 0);
  }, [renderNavigationArrows, insets.left]);

  const navigationArrowsInsetLeft = useMemo(() => {
    return renderNavigationArrows && alignNavigationArrowsToInsets ? insets.left : 0;
  }, [renderNavigationArrows, alignNavigationArrowsToInsets, insets.left]);

  useChangeEffect(() => {
    dataSource.current = createDataSource(dataFetcher, `Swimlane #${row}`);
    setDataFetcherState(SwimlaneDataFetcherState.FetchingFirstPage);
    scrollView.current?.invalidateLayout();
  }, [dataFetcher], [row]);

  const onDataChange = useCallback(() => {
    if (createDataFetcher) {
      setDataFetcher(createDataFetcher());
    }
  }, [createDataFetcher]);

  const onDataRefresh = useCallback(() => {
    scrollView.current?.invalidateLayout();
  }, []);

  useEventListener(dataChangeEvent, onDataChange, dataEventsEmitter);
  useEventListener(dataRefreshEvent, onDataRefresh, dataEventsEmitter);

  useChangeEffect(onDataRefresh, [extraData], [onDataRefresh]);

  useChangeEffect(() => {
    onDataFetcherStateChanged?.(dataFetcherState, row);
  }, [dataFetcherState], [onDataFetcherStateChanged]);

  const emptyLayoutForRect = useFunction((rect: Rect): Promise<LayoutItem<TileData | null>[]> => {
    if (rect.y !== 0) {
      return Promise.resolve([]);
    }
    const layoutItems: LayoutItem<TileData | null>[] = [];
    const numberOfItemsInRect = Math.ceil(rect.width / itemWidth);
    for (let index = 0; index < numberOfItemsInRect; ++index) {
      layoutItems.push({
        x: index * itemWidth,
        y: 0,
        width: itemWidth,
        height: itemHeight,
        data: null
      });
    }
    return Promise.resolve(layoutItems);
  });

  const layoutItemsForRect = useFunction(async (rect: Rect): Promise<LayoutItem<TileData | null>[]> => {
    if (rect.y !== 0) {
      return [];
    }
    const layoutItems: LayoutItem<TileData | null>[] = [];
    const initialIndex = Math.floor(rect.x / itemWidth);
    const numberOfItemsInRect = Math.ceil(rect.width / itemWidth);
    for (let index = initialIndex; index < initialIndex + numberOfItemsInRect; ++index) {
      if ((index < 0 && (!wrapAroundActive.current || !dataSource.current.isFinished())) ||
        (isBigScreen && index < startIndex.current)) {
        // don't wrap around
        continue;
      }
      const wasFinished = dataSource.current.isFinished();
      let data = await dataSource.current.getDataForIndex(index);
      if (index === 0) {
        setDataFetcherState(data ? SwimlaneDataFetcherState.HasData : SwimlaneDataFetcherState.NoData);
      }
      if (!wasFinished && dataSource.current.isFinished() && wrapAroundActive.current) {
        scrollView.current && scrollView.current.invalidateLayout();
      }
      if (!data && wrapAroundActive.current) {
        const wrappedAroundIndex = calculateWrapAroundIndex(dataSource.current.getDataLength(), index);
        data = await dataSource.current.getDataForIndex(wrappedAroundIndex);
      }
      if (!data) {
        // render placeholder if there's no data
        if (index === 0) {
          return [{
            x: 0,
            y: 0,
            width: itemWidth,
            height: itemHeight,
            data: null
          }];
        }
        continue;
      }
      layoutItems.push({
        x: index * itemWidth,
        y: 0,
        width: itemWidth,
        height: itemHeight,
        data: data
      });
    }
    return layoutItems;
  });

  const renderItem = useFunction((data: TileData | null, ref: any, layout: ItemPosition) => {
    if (data === null) {
      return placeholderComponent ? placeholderComponent(row) : null;
    }
    const index = dataSource.current.getIndexForData(data);

    const onFocus = (event?: any, options?: FocusOptions) => {
      if (fixedFocusPosition && isWeb && (!options || !options.preventScroll)) {
        scrollView.current && scrollView.current.scrollToXPosition(layout.left - insetLeft, true);
      }
    };

    return createTile({data, index, row, onFocus, wrapAroundActive: () => wrapAroundActive.current});
  });

  const renderEmptyItem = useFunction(() => {
    return loadingPlaceholderComponent ? loadingPlaceholderComponent() : null;
  });

  const scrollLeft = useCallback(() => {
    scrollView.current && scrollView.current.scrollByOffset(ScrollDirection.Horizontal, -Math.floor(width / itemWidth) * itemWidth);
  }, [width, itemWidth]);

  const scrollRight = useCallback(() => {
    scrollView.current && scrollView.current.scrollByOffset(ScrollDirection.Horizontal, Math.floor(width / itemWidth) * itemWidth);
  }, [width, itemWidth]);

  const shouldActivateWrapAround = useCallback((offsetX: number) => {
    return (
      wrapAround &&
      dataSource.current.isFinished() &&
      dataSource.current.getDataLength() >= numberOfItemsVisibleAtOnce &&
      // turn on wrap around when last item is visible on screen
      offsetX >= dataSource.current.getDataLength() * itemWidth - width
    );
  }, [wrapAround, numberOfItemsVisibleAtOnce, itemWidth, width]);

  const updateStartIndex = useCallback((offsetX: number) => {
    const firstItemOffset = offsetX + insetLeft;
    if (dataSource.current.getDataLength() === 0 || firstItemOffset < 0) {
      return;
    }
    const allDataLength = (dataSource.current.getDataLength() * itemWidth);
    const newIndex = Math.floor(firstItemOffset / allDataLength) * dataSource.current.getDataLength();
    if (newIndex > startIndex.current) {
      startIndex.current = newIndex;
      scrollView.current?.invalidateLayout();
    }
  }, [itemWidth, insetLeft]);

  // in case of fast scrolling start index cannot be updated before the layoutItemsForRect is executed for the next grid because we will block loading data for it
  const scheduleStartIndexUpdate = useDebounce(updateStartIndex, startIndexUpdateDelay);

  const onScroll = useCallback((event: ScrollEvent) => {
    const offset = event.relativeOffset.x;
    if (!wrapAroundActive.current && shouldActivateWrapAround(offset)) {
      Log.info(TAG, `Swimlane #${row} turning on wrap-around.`);
      wrapAroundActive.current = true;
      scrollView.current?.invalidateLayout();
    }
    // on some platforms onScroll callback can be called very frequently - make sure to update start index only once when scrolling in direction of active wraparound is finished
    if (offset > previousOffset.current) {
      scheduleStartIndexUpdate(offset);
    } else {
      updateStartIndex(offset);
    }
    previousOffset.current = offset;
  }, [shouldActivateWrapAround, row, updateStartIndex, scheduleStartIndexUpdate]);

  const onSetContentStartOffset = useCallback(() => {
    previousOffset.current = 0; // this offset is expressed in relative coordinates
  }, []);

  const scrollViewSize = useMemo(() => ({width, height: itemHeight}), [itemHeight, width]);

  const viewStyle: ViewStyle = useMemo(() => ({
    flex: 1,
    paddingTop: insets.top,
    paddingBottom: insets.bottom,
    ...dataFetcherState === SwimlaneDataFetcherState.Initializing && {height: swimlaneHeight},
    ...style
  }), [style, insets.top, insets.bottom, dataFetcherState, swimlaneHeight]);

  const scrollViewWrapperStyle = useMemo<ViewStyle>(() => ({
    flex: 1
  }), []);

  const styles = stylesUpdater.getStyles();

  const renderedHeader = useMemo(() => {
    if (!header) {
      return null;
    }
    if (typeof header === 'string') {
      return (
        <NitroxText
          textType='swimlane-title'
          style={{...styles.headerText, height: swimlaneHeaderHeight, paddingLeft: insetLeft}}
        >
          {header}
        </NitroxText>
      );
    } else if (typeof header === 'function') {
      return header(row);
    }
    return null;
  }, [header, insetLeft, row, styles.headerText]);

  const debugName = useMemo(() => `swimlane #${row}`, [row]);

  const navArrowProps = useMemo(() => ({
    height: itemHeight,
    backgroundColor: styles.navArrowBackground,
    width: WEB_ARROW_CONTAINER_WIDTH,
    opacity: dimensions.opacity.xxhigh
  }), [itemHeight, styles.navArrowBackground]);

  const swimlaneScrollView = useCallback((layoutItems, renderItem, ref = null) => (
    <NitroxScrollView<TileData | null>
      debugName={debugName}
      ref={ref}
      style={scrollViewSize}
      viewSize={scrollViewSize}
      contentHeight={scrollViewSize.height}
      direction={ScrollDirection.Horizontal}
      layoutItemsForRect={layoutItems}
      renderItem={renderItem}
      onScrollHorizontal={onScroll}
      onSetContentStartOffset={onSetContentStartOffset}
      scrollEnabled={isATV || !fixedFocusPosition}  // scrollEnabled won't lock scrolling on ATV, it disables animation instead
      showIndicators={false}
      fixedHorizontalFocusPosition={fixedFocusPosition}
      leftInset={insetLeft}
    />
  ), [scrollViewSize, debugName, fixedFocusPosition, insetLeft, onScroll, onSetContentStartOffset]);

  const swimlaneEmpty = useMemo(() => {
    return swimlaneScrollView(emptyLayoutForRect, renderEmptyItem);
  }, [swimlaneScrollView, emptyLayoutForRect, renderEmptyItem]);

  const swimlaneData = useMemo(() => {
    return swimlaneScrollView(layoutItemsForRect, renderItem, scrollView);
  }, [swimlaneScrollView, layoutItemsForRect, renderItem]);

  const testID = props.testID ?? `swimlane_` + (typeof header === 'string' ? humanCaseToSnakeCase(header) : String(row));

  return dataFetcherState === SwimlaneDataFetcherState.NoData ? null : ( // don't render anything if dataFetcher has no data
    <View
      style={viewStyle}
      testID={testID}
    >
      {renderedHeader}
      <FocusParent debugName={`Swimlane #${row}`} enterStrategy='topLeft' rememberLastFocused={fixedFocusPosition} style={scrollViewWrapperStyle}>
        {dataFetcherState === SwimlaneDataFetcherState.Initializing && swimlaneEmpty}
        {/* call swimlaneData unconditionally to ask for and fetch data */}
        {swimlaneData}
        {renderNavigationArrows && (
          <>
            <NavArrow
              {...navArrowProps}
              direction={Direction.Left}
              handlePress={scrollLeft}
              insetLeft={navigationArrowsInsetLeft}
            />
            <NavArrow
              {...navArrowProps}
              direction={Direction.Right}
              handlePress={scrollRight}
              insetLeft={navigationArrowsInsetLeft}
            />
          </>
        )}
      </FocusParent>
    </View>
  );
}

const staticStyles = createStyles({
  defaultHeaderTile: {
    paddingTop: 0,
    paddingBottom: 0,
    height: swimlaneHeaderHeight
  }
});

const defaultCreateHeaderTile = ({data: {name, label, onPress}}: SwimlaneTileProps<NamedAction>) => (
  <ListItem
    key={name}
    label={label}
    onPress={onPress}
    textType='settings-label'
    defaultContainerStyle={staticStyles.defaultHeaderTile}
  />
);

function FlatlistSwimlaneComponent<TileData>(props: SwimlaneProps<TileData>) {
  const {
    style,
    insets,
    createTile,
    createHeaderTile = defaultCreateHeaderTile,
    extraData,
    createDataFetcher,
    dataChangeEvent = MetaEvent.None,
    dataRefreshEvent = MetaEvent.None,
    dataEventsEmitter,
    header,
    row,
    swimlaneHeight,
    loadingPlaceholderComponent,
    vertical,
    onDataFetcherStateChanged,
    staticHeaderActions = [],
    showHeaderActions = false,
    headerActionsVisibilityThreshold = defaultHeaderActionsVisibilityThreshold
  } = props;
  const [data, setData] = useState<TileData[]>([]);
  const [dataFetcher, setDataFetcher] = useState(props.dataFetcher || (createDataFetcher?.()));
  const fetching = useRef(false);
  const finished = useRef(false);
  const [dataFetcherState, setDataFetcherState] = useState<SwimlaneDataFetcherState>(SwimlaneDataFetcherState.FetchingFirstPage);
  const {forceUpdate, forceUpdateState} = useForceUpdate();

  const {setDataSourceHeaderAction, headerActions} = useHeaderActions(staticHeaderActions);
  const [headerActionsVisible, setHeaderActionsVisible] = useState(headerActionsVisibilityThreshold == null);

  const processResponse = useDisposableCallback((result: IteratorResult<TileData[]>) => {
    const newData: TileData[] = result.value || [];
    if (dataFetcherState === SwimlaneDataFetcherState.FetchingFirstPage) {
      setDataFetcherState(newData.length ? SwimlaneDataFetcherState.HasData : SwimlaneDataFetcherState.NoData);
    }
    setData(data => [...data, ...newData]);
    if (isLimitedAsyncIterableIterator(dataFetcher) && dataFetcher.isLimited?.()) {
      setDataSourceHeaderAction(dataFetcher.getAction?.());
    } else {
      setDataSourceHeaderAction(undefined);
    }
    if (result.done) {
      finished.current = true;
    }
    fetching.current = false;
  }, [dataFetcherState]);

  const fetchNextPage = useCallback(() => {
    if (!dataFetcher) {
      throw new Error('Unable to fetch next page - data fetcher is missing');
    }
    if (finished.current || fetching.current) {
      return;
    }
    fetching.current = true;
    dataFetcher.next()
      .then(processResponse);
  }, [dataFetcher, processResponse]);

  const onDataChange = useCallback(() => {
    if (createDataFetcher) {
      setDataFetcher(createDataFetcher());
    }
  }, [createDataFetcher]);

  const onDataRefresh = useCallback(() => {
    forceUpdate();
  }, [forceUpdate]);

  useEventListener(dataChangeEvent, onDataChange, dataEventsEmitter);
  useEventListener(dataRefreshEvent, onDataRefresh, dataEventsEmitter);

  useChangeEffect(onDataRefresh, [extraData], [onDataRefresh]);

  useLazyEffect(() => {
    finished.current = false;
    fetching.current = false;
    setData([]);
    setDataFetcherState(SwimlaneDataFetcherState.FetchingFirstPage);
    setDataSourceHeaderAction(undefined);
    fetchNextPage();
  }, [dataFetcher], [fetchNextPage]);

  useChangeEffect(() => {
    onDataFetcherStateChanged?.(dataFetcherState, row);
  }, [dataFetcherState], [onDataFetcherStateChanged]);

  const onTileFocusHandler = useCallback(({index}: SwimlaneTileProps<TileData>) => {
    setHeaderActionsVisible(showHeaderActions && index > (headerActionsVisibilityThreshold ?? 0) - 1);
  }, [showHeaderActions, headerActionsVisibilityThreshold]);

  const renderItem = useCallback((info: ListRenderItemInfo<TileData>) => {
    return createTile({data: info.item, index: info.index, row: 0, onFocus: onTileFocusHandler, wrapAroundActive: () => false});
  }, [createTile, onTileFocusHandler]);

  const styles = stylesUpdater.getStyles();

  const renderTitle = useCallback(() => {
    if (typeof header === 'string') {
      return (
        <NitroxText
          textType='swimlane-title'
          style={{...styles.headerText, height: swimlaneHeaderHeight, paddingLeft: insets?.left}}
        >
          {header}
        </NitroxText>
      );
    }
    if (typeof header === 'function') {
      return header(row);
    }
    return null;
  }, [header, insets, row, styles.headerText]);

  const renderHeaderTile = useCallback((data: NamedAction, index: number) => (
    <View key={`index${index}`} style={styles.headerAction}>
      {createHeaderTile({data, index, row, wrapAroundActive: () => false})}
    </View>
  ), [createHeaderTile, row, styles.headerAction]);

  const renderedHeader = useMemo(() => {
    const title = renderTitle();
    if (!headerActionsVisible || !title || headerActions.length === 0) {
      return title;
    }
    return (
      <View style={styles.headerContainer}>
        {title}
        <View style={styles.headerActionsContainer}>
          <Separator style={styles.separator} />
          {headerActions.map((headerAction, index) =>
            renderHeaderTile(headerAction, index)
          )}
        </View>
      </View>
    );
  }, [renderTitle, headerActions, headerActionsVisible, renderHeaderTile, styles.headerContainer, styles.headerActionsContainer, styles.separator]);

  const viewStyle: ViewStyle = useMemo(() => ({
    flex: 1,
    paddingTop: insets?.top,
    paddingBottom: insets?.bottom,
    height: swimlaneHeight,
    ...style
  }), [style, insets, swimlaneHeight]);

  const contentContainerStyle = useMemo(() => ({
    paddingLeft: insets?.left,
    paddingRight: insets?.right
  }), [insets]);

  const testID = `swimlane_` + (typeof header === 'string' ? humanCaseToSnakeCase(header) : String(row));

  const screenWidth = useScreenInfo().size.width;
  const footerStyle = useMemo(() => isBigScreen ? {width: screenWidth} : undefined, [screenWidth]);

  const renderEmptyItem = useFunction(() => {
    return loadingPlaceholderComponent ? loadingPlaceholderComponent() : null;
  });

  const swimlaneScrollView = useCallback((renderItem, data, scrollable) => (
    <NitroxFlatList
      horizontal={!vertical}
      extraData={forceUpdateState}
      onEndReached={fetchNextPage}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      data={data}
      renderItem={renderItem}
      contentContainerStyle={contentContainerStyle}
      // big screen
      contentInset={isBigScreen ? insets : undefined}
      CustomNativeScrollViewComponent={isATV && NitroxHorizontalScrollView}
      fixedFocusBehaviour={isBigScreen}
      fixedFocusPosition={isBigScreen ? insets?.left : undefined}
      focusable={false}
      contentInsetAdjustmentBehavior='never'
      automaticallyAdjustContentInsets={false}
      scrollEnabled={scrollable}
      // Make fixed focus possible even for last elements
      ListFooterComponent={isBigScreen ? View : undefined}
      ListFooterComponentStyle={footerStyle}
    />
  ), [contentContainerStyle, fetchNextPage, footerStyle, forceUpdateState, insets, vertical]);

  const swimlaneEmpty = useMemo(() => {
    // we don't know how many items swimlane will contain until we fetch data thus we generate 10 placeholders to fill the screen
    const emptyData = Array(10).fill({item: undefined});
    return swimlaneScrollView(renderEmptyItem, emptyData, false);
  }, [swimlaneScrollView, renderEmptyItem]);

  const swimlaneData = useMemo(() => {
    return swimlaneScrollView(renderItem, data, true);
  }, [swimlaneScrollView, renderItem, data]);

  return dataFetcherState === SwimlaneDataFetcherState.NoData ? null : ( // don't render anything if dataFetcher has no data
    <View
      style={viewStyle}
      testID={testID}
    >
      {renderedHeader}
      {dataFetcherState !== SwimlaneDataFetcherState.HasData ? swimlaneEmpty : swimlaneData}
    </View>
  );
}

export const Swimlane = React.memo(isMobile ? FlatlistSwimlaneComponent : SwimlaneComponent) as typeof SwimlaneComponent;

interface SwimlaneStackProps<TileData> {
  rowCount: number;
  style?: ViewStyle;
  contentContainerStyle?: StyleProp<ViewStyle>;

  swimlaneHeight: number;
  setupSwimlane: (row: number) => Omit<SwimlaneProps<TileData>, 'createTile' | 'emptySwimlaneComponent'>;
  createTile: (props: SwimlaneTileProps<TileData>) => ReactElement;
  placeholderComponent?: (row: number, onFocus: (event?: any, options?: FocusOptions) => void) => ReactElement;
  loadingPlaceholderComponent?: () => ReactElement;
  swimlaneInsets?: Insets;

  fixedFocusPosition?: boolean;
  topInset?: number;

  refreshing?: boolean;
  onRefresh?: () => void;

  onEndReachedThreshold?: number;
  onEndReached?: () => void;
  getVisibleComponentIndex?: (row: number) => number;
}

type SwimlaneStackState = {
  focusedRow?: number;
}

const ListHeader: React.FC = () => {
  return (
    <View />
  );
};

class SwimlaneStackComponent<TileData> extends React.PureComponent<SwimlaneStackProps<TileData> & WithChromecastExtraBottomPadding, SwimlaneStackState> {
  private flatListRef = React.createRef<NitroxFlatList<TileData>>();
  private pager = createScrollViewPager();
  private data: any[] = [];
  private preventScroll = false;

  public constructor(props: SwimlaneStackProps<TileData> & WithChromecastExtraBottomPadding) {
    super(props);
    this.data = Array(props.rowCount);
    this.state = {
      focusedRow: 0
    };
  }

  public componentDidUpdate(prevProps: SwimlaneStackProps<TileData>, prevState: SwimlaneStackState) {
    if (this.preventScroll || isTVOS) {
      return;
    }
    setImmediate(() => {
      const flatlist = this.flatListRef.current;
      if (!flatlist) {
        return;
      }
      if (this.props.fixedFocusPosition && prevState.focusedRow !== this.state.focusedRow && typeof this.state.focusedRow === 'number') {
        const componentIndex = this.props.getVisibleComponentIndex?.(this.state.focusedRow) ?? this.state.focusedRow;
        flatlist.scrollToOffset({offset: this.props.swimlaneHeight * componentIndex});
      }
    });
  }

  private onFocusTile = (row: number, options?: FocusOptions) => {
    if (isTVOS) {
      return;
    }
    this.preventScroll = !!(options && options.preventScroll);
    if (row !== this.state.focusedRow) {
      this.setState({focusedRow: row});
    }
  };

  private createPlaceholder = (row: number) => {
    const onFocus = (event?: any, options?: FocusOptions) => {
      this.onFocusTile(row, options);
    };
    return this.props.placeholderComponent ? this.props.placeholderComponent(row, onFocus) : null;
  }

  private renderItem = (info: ListRenderItemInfo<TileData>) => {
    const createTile = (props: SwimlaneTileProps<TileData>) => {
      const focusHandler = (event?: any, options?: FocusOptions) => {
        this.onFocusTile(info.index, options);
        props.onFocus?.(event, options);
      };
      return this.props.createTile({...props, onFocus: focusHandler});
    };
    return (
      <Swimlane
        createTile={createTile}
        placeholderComponent={this.createPlaceholder}
        loadingPlaceholderComponent={this.props.loadingPlaceholderComponent}
        swimlaneHeight={this.props.swimlaneHeight}
        {...this.props.setupSwimlane(info.index)}
      />
    );
  }

  private onScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    this.pager.onScroll(event, this.props.onEndReached, this.props.onEndReachedThreshold);
  };

  public render() {
    const {style, contentContainerStyle, rowCount, refreshing, onRefresh, topInset = 0} = this.props;

    if (this.data.length !== rowCount) {
      this.data = Array(rowCount);
    }

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - missing ListHeaderComponentStyle definition
      <NitroxFlatList
        ref={this.flatListRef}
        // this is buggy, don't use it mindlessly, breaks apple tv scroll
        // removeClippedSubviews
        initialNumToRender={1}
        maxToRenderPerBatch={2}
        bounces
        style={style}
        scrollEnabled={isDesktopBrowser || isTVOS || !this.props.fixedFocusPosition}
        // the padding here is a hack to workaround the problem with invisible list items not being focusable on ATV
        contentContainerStyle={[{paddingTop: 1}, contentContainerStyle, this.props.chromecastExtraBottomPadding]}
        ListHeaderComponent={topInset ? ListHeader : undefined}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - missing ListHeaderComponentStyle definition
        ListHeaderComponentStyle={{height: topInset}}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        contentInsetAdjustmentBehavior='never'
        data={this.data}
        scrollEventThrottle={5}
        onScroll={this.onScroll}
        renderItem={this.renderItem}
        keyExtractor={indexKeyExtractor}
        refreshing={refreshing}
        onRefresh={onRefresh}
      />
    );
  }
}

function ATVSwimlaneStackComponent<TileData>(props: SwimlaneStackProps<TileData>) {
  const {
    createTile,
    placeholderComponent,
    loadingPlaceholderComponent,
    setupSwimlane,
    rowCount,
    fixedFocusPosition,
    topInset = 0
  } = props;
  const pager = useRef(createScrollViewPager());
  const spacerHeight = topInset + swimlaneHeaderHeight;

  const swimlanes = useMemo(() => {
    if (!rowCount) {
      return null;
    }

    const data = Array.from(Array(rowCount).keys());

    const createPlaceholder = (row: number) => {
      return placeholderComponent ? placeholderComponent(row, () => {}) : null;
    };

    return data.map((it, index) => {
      return (
        <Swimlane
          key={index}
          createTile={createTile}
          placeholderComponent={createPlaceholder}
          loadingPlaceholderComponent={loadingPlaceholderComponent}
          {...setupSwimlane(it)}
        />
      );
    });
  }, [createTile, loadingPlaceholderComponent, placeholderComponent, rowCount, setupSwimlane]);

  const onScrollVertical = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    pager.current.onScroll(event, props.onEndReached, props.onEndReachedThreshold);
  };

  return !swimlanes ? null : (
    <NitroxInteractiveController omitGeometryCaching>
      <ScrollView
        horizontal={false}
        showsVerticalScrollIndicator={false}
        onScroll={onScrollVertical}
        scrollEventThrottle={8}
        contentInsetAdjustmentBehavior='never'
        automaticallyAdjustContentInsets={false}
        scrollEnabled={true}
        CustomNativeScrollViewComponent={NitroxVerticalScrollView}
        fixedFocusBehaviour={fixedFocusPosition}
        fixedFocusPosition={spacerHeight}
        alwaysBounceVertical={false}
      >
        <View style={{height: spacerHeight}} />
        {swimlanes}
      </ScrollView>
    </NitroxInteractiveController>
  );
}

export const SwimlaneStack = React.memo(!isATV ? withChromecastExtraBottomPadding(SwimlaneStackComponent) : ATVSwimlaneStackComponent) as typeof ATVSwimlaneStackComponent;
