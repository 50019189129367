import {createStyles} from 'common-styles';
import React, {memo, useCallback, useState} from 'react';
import {View, StyleSheet} from 'react-native';

import {menuFocusParentOffset, dimensions} from 'common/constants';
import {humanCaseToSnakeCase} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxInteractive from 'components/NitroxInteractive';

import {STBMenuButtonProps} from './STBMenuButtonProps';

const TAG = 'STBMenuIconButton';

export const menuIconSize = 35;
export const menuIconPadding = Math.round((dimensions.icon.large - menuIconSize) / 2);
const menuIconSelectionBorderSize = 5;
const menuIconMarkSize = dimensions.icon.large + menuIconSelectionBorderSize;

const styles = createStyles({
  button: {
    height: '100%',
    paddingTop: menuFocusParentOffset + dimensions.mainMenu.marginTop,
    width: dimensions.icon.large
  },
  imageContainer: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageSection: {
    height: '100%',
    width: '100%'
  },
  imageOverlay: {
    position: 'absolute',
    height: menuIconSize,
    width: menuIconSize,
    justifyContent: 'center',
    alignItems: 'center'
  },
  iconMark: {
    width: menuIconMarkSize,
    height: menuIconMarkSize,
    borderRadius: Math.floor(menuIconMarkSize / 2),
    position: 'absolute'
  }
});

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  iconFocused: {
    ...styles.iconMark,
    backgroundColor: colors.mainMenu.button.background.focused,
    zIndex: -1
  },
  iconSelection: {
    ...styles.iconMark,
    borderWidth: menuIconSelectionBorderSize,
    borderColor: colors.mainMenu.button.selectionIndicator
  }
}));

export type STBMenuIconButtonProps = STBMenuButtonProps;

const STBMenuIconButton: React.FunctionComponent<STBMenuIconButtonProps> = ({
  onPress: propagatePress,
  focusPriority,
  route,
  text,
  style,
  renderImageOverlay,
  renderIcon,
  selected,
  disabled
}) => {

  const onPress = useCallback(() => {
    if (route) {
      propagatePress(route);
    } else {
      Log.error(TAG, `Menu button ${text} points to non existing route.`);
    }
  }, [propagatePress, route, text]);

  const [focused, onFocusStateChanged] = useState(false);
  const dynamicStyles = dynamicStylesUpdater.getStyles();

  const testID = `button_${humanCaseToSnakeCase(text)}`;

  return (
    <NitroxInteractive
      testID={testID}
      style={[styles.button, style]}
      activeOpacity={1}
      onFocusStateChanged={onFocusStateChanged}
      onPress={onPress}
      disabled={disabled}
      focusPriority={focusPriority}
    >
      <View style={styles.imageSection}>
        <View style={styles.imageContainer}>
          {focused && (
            <View style={dynamicStyles.iconFocused} />
          )}
          {renderIcon()}
          {renderImageOverlay && (
            <View style={styles.imageOverlay}>
              {renderImageOverlay()}
            </View>
          )}
          {(selected || focused) && (
            <View style={dynamicStyles.iconSelection} />
          )}
        </View>
      </View>
    </NitroxInteractive>
  );
};

export default memo(STBMenuIconButton);
