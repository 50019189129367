import {createStyles} from 'common-styles';
import moment from 'moment';
import React, {useRef, useCallback, useMemo} from 'react';
import {useState, useEffect} from 'react';
import {ViewStyle, TextStyle, View} from 'react-native';

import {getTime} from 'common/utils';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {mw} from 'mw/MW';

import NitroxText from 'components/NitroxText';
import {useDisposableCallback} from 'hooks/Hooks';
import {useLocalized} from 'locales/i18nUtils';

type Resolution = 'hour' | 'minute' | 'second';
type Props = {
  resolution?: Resolution;
  containerStyle?: ViewStyle;
  textStyle?: TextStyle;
  active?: boolean;
}
const DEFAULT_RESOLUTION: Resolution = 'minute';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    width: '100%',
    height: '100%',
    justifyContent: 'center'
  },
  textStyle: {
    color: colors.tvScreen.clock,
    alignSelf: 'center'
  }
}));

function computeTimeout(resolution: Resolution = DEFAULT_RESOLUTION): number {
  const endOf = moment().endOf(resolution as moment.unitOfTime.StartOf)
    .unix();
  return (endOf - moment().unix()) * 1000;
}

const Clock: React.FunctionComponent<Props> = ({
  containerStyle,
  resolution,
  textStyle,
  active = true
}) => {
  const [time, setTime] = useState(new Date());
  const {toUpperCase} = useLocalized();
  const updateTimer = useRef<number | null>(null);
  const setUpdateTimer = useDisposableCallback((timerId: number | null) => updateTimer.current = timerId);
  const timeout = useMemo(() => computeTimeout(resolution), [resolution]);

  const cancelNextUpdate = useCallback(() => {
    if (!updateTimer.current) {
      return;
    }
    clearTimeout(updateTimer.current);
    setUpdateTimer(null);
  }, [setUpdateTimer]);
  const scheduleNextUpdate = useCallback(() => {
    cancelNextUpdate();
    setUpdateTimer(setTimeout(() => {
      setTime(new Date());
      scheduleNextUpdate();
    }, timeout));
  }, [cancelNextUpdate, setUpdateTimer, timeout]);

  useEffect(() => {
    if (active) {
      setTime(new Date());
      scheduleNextUpdate();
    }
    return cancelNextUpdate;
  }, [cancelNextUpdate, scheduleNextUpdate, active]); // run this hook only on mount events

  const styles = stylesUpdater.getStyles();
  return (
    <View style={[styles.container, containerStyle]} testID={`clock${active ? '' : '_disabled'}`}>
      <NitroxText textType='clock' numberOfLines={1} style={[styles.textStyle, textStyle]}>
        {toUpperCase(getTime(time, mw.configuration.timeFormat))}
      </NitroxText>
    </View>
  );
};

export default Clock;
