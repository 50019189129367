import {epgItemHeight} from 'common/constants';
import {DateUtils} from 'common/DateUtils';
import {reduceMap, flatten} from 'common/HelperFunctions';
import {Size, Point} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {EPGParams} from 'mw/api/CatalogInterface';
import {Event, Channel} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import {NativeKeyEvent, SupportedKeys} from 'components/KeyEventManager';

import {Chunk, ChunkParameters, chunkId} from './Chunk';
import {initialXOffset, pointsPerMillisecond, focusedRowOffset, preloadLengthHorizontal, preloadLengthVertical} from './EpgConstants';

/**
 * Returns currentDate if it's within event timespan or event's start date otherwise.
 */
export function clampedStartDate(event: Event, currentDate: Date) {
  return event.start < currentDate && event.end > currentDate ? currentDate : event.start;
}

/**
 * Returns index of a channel with given id or 0 if not found.
 */
export function channelIndex(channelId: string | undefined, channels: Channel[]) {
  return Math.max(0, channels.findIndex(({id}) => id === channelId));
}

/**
 * Calculates horizontal offset in points for a given date.
 * @param date Date for which to calculate offset.
 * @param originDate Date for which offset equals initialXOffset.
 */
export function offsetX(date: Date, originDate: Date): number {
  return initialXOffset() + pointsPerMillisecond * (date.getTime() - originDate.getTime());
}

/**
 * Calculates vertical offset in points for a row with given channelId.
 * @param channelId id of a channel for which to calculate offset.
 * @param channels Array of all channels.
 */
export function offsetY(channelId: string | undefined, channels: Channel[]) {
  return channelIndex(channelId, channels) * epgItemHeight;
}

/**
 * Calculates vertical offset for grid when a given channel is focused.
 * @param channelId id of focused channel.
 * @param channels Array of all channels.
 * @param windowSize Grid visible area size.
 */
export function scrollOffsetY(channelId: string | undefined, channels: Channel[], windowSize: Size) {
  const targetOffset = offsetY(channelId, channels) - focusedRowOffset;
  const maxOffset = epgItemHeight * channels.length - windowSize.height;
  return Math.max(0, Math.min(targetOffset, maxOffset));
}

export function initialScrollOffset(channels: Channel[], currentChannel?: Channel): Point {
  const index = channelIndex(currentChannel?.id ?? '', channels);
  return {
    x: initialXOffset(),
    y: Math.max(0, index * epgItemHeight - focusedRowOffset)
  };
}

/**
 * Returns a promise of a chunk with given parameters.
 * @param params Parameters of chunk (startDate, endDate, startRow and endRow).
 * @param channels Array of all channels.
 */
export function fetchChunk({startDate, endDate, startRow, endRow}: ChunkParameters, channels: Channel[]): Promise<Chunk | null> {
  const config: EPGParams = {
    channels: channels.slice(startRow, endRow + 1),
    startTime: startDate,
    endTime: endDate,
    includeIsRecorded: true
  };
  return mw.catalog.unblockIdleActions()
    .then(() => (
      mw.catalog
        .getEPG(config)
        .then(response => {
          const events = reduceMap(response, (events, [, newEvents]) => {
            events.push(...newEvents);
            return events;
          }, [] as Event[]);
          const params: ChunkParameters = {
            startDate,
            endDate,
            startRow,
            endRow
          };
          return {
            id: chunkId(params),
            ...params,
            events
          };
        })
        .catch(error => {
          Log.error('fetchChunk', 'Error fetching epg chunk: ', config, error);
          return null;
        })
    ));
}

export type OffscreenRenderThreshold = {
  x?: number;
  y?: number;
}
/**
 * Calculates needed chunks in form of an array of tuples that represent (x, y) offsets from current chunk.
 * @param navDirection Navigation direction. More chunks are loaded in direction of movement.
 * @param currentChunk Current chunk, i.e. chunk that contains focusedChannel and focusedDate.
 * @param gridOffsetDate Grid horizontal offset mapped to date.
 * @param gridOffsetY Grid vertical offset.
 * @param windowSize Grid visible area size.
 * @param offscreenRenderThreshold Render off screen items in given distance. Does not apply to `navDirection` side. Logically 'enlarges' windowSize in this calculation.
 */
export function calculateNeededChunksOffsets(
  navDirection: NativeKeyEvent['key'],
  currentChunk: ChunkParameters,
  gridOffsetDate: Date,
  gridOffsetY: number,
  windowSize: Size,
  {x: offscreenRenderThresholdX = 0, y: offscreenRenderThresholdY = 0}: OffscreenRenderThreshold = {}
): ([number, number])[] {
  const delta = [SupportedKeys.Up, SupportedKeys.Left].includes(navDirection ?? SupportedKeys.Down) ? -1 : 1;
  const offsets: ([number, number])[] = [[0, 0]];
  switch (navDirection) {
    case SupportedKeys.Right:
    case SupportedKeys.Left:
      // render vertical neighbour chunks only if they are on the screen (including offscreenRenderThreshold)
      const shouldLoadChunkAbove = gridOffsetY - offscreenRenderThresholdY < currentChunk.startRow * epgItemHeight;
      const shouldLoadChunkBelow = (currentChunk.endRow + 1) * epgItemHeight < gridOffsetY + windowSize.height + offscreenRenderThresholdY;
      for (let column = delta; column !== delta * preloadLengthHorizontal + delta; column += delta) {
        offsets.push([column, 0]);
        shouldLoadChunkAbove && offsets.push([column - delta, -1]);
        shouldLoadChunkBelow && offsets.push([column - delta, 1]);
      }
      offsets.push([-delta, 0]);
      shouldLoadChunkAbove && offsets.push([-delta, -1]);
      shouldLoadChunkBelow && offsets.push([-delta, 1]);
      break;
    case SupportedKeys.Down:
    case SupportedKeys.Up:
    default:
      const offscreenMilisecondsThreshold = offscreenRenderThresholdX / pointsPerMillisecond;
      // render horizontal neighbour chunks only if they are on the screen (including offscreenRenderThreshold)
      const shouldLoadChunkOnTheLeft = gridOffsetDate.getTime() - offscreenMilisecondsThreshold < currentChunk.startDate.getTime();
      const shouldLoadChunkOnTheRight = (currentChunk.endDate.getTime() - gridOffsetDate.getTime()) * pointsPerMillisecond < windowSize.width + offscreenRenderThresholdX;
      for (let row = delta; row !== delta * preloadLengthVertical + delta; row += delta) {
        offsets.push([0, row]);
        shouldLoadChunkOnTheLeft && offsets.push([-1, row - delta]);
        shouldLoadChunkOnTheRight && offsets.push([1, row - delta]);
      }
      offsets.push([0, -delta]);
      shouldLoadChunkOnTheLeft && offsets.push([-1, -delta]);
      shouldLoadChunkOnTheRight && offsets.push([1, -delta]);
      break;
  }
  return offsets;
}

export function isInRange(value: number, range: [number, number]) {
  return value >= range[0] && value <= range[1];
}

function eventsForRow(row: number, chunks: Chunk[], channels: Channel[]) {
  const events = chunks
    .filter(({startRow, endRow}) => isInRange(row, [startRow, endRow]))
    .map(({events}) => events);
  return flatten(events)
    .filter(({channelId}) => channelId === channels[row]?.id);
}

/**
 * Searches chunks' array for an event at specified date on specified channel. Returns null if not found.
 * @param chunks Chunks' array.
 * @param date Searching date.
 * @param channelIndex Index of channel for which we perform search.
 * @param channels Array of all channels.
 */
export function findEvent(chunks: Chunk[], date: Date, channelIndex: number, channels: Channel[]): Event | null {
  return eventsForRow(channelIndex, chunks, channels)
    .find(({start, end}) => DateUtils.isDateBetween(date, start, end)) ?? null;
}

/**
 * Searches for a future neighbouring event of an event at specified date and row. Returns null if not found.
 * @param row Index of channel for which we perform search.
 * @param date Date at which we start the search.
 * @param chunks Chunks' array.
 * @param channels Array of all channels.
 */
export function findNextEventForRow(row: number, date: Date, chunks: Chunk[], channels: Channel[]): Event | null {
  const currentEvent = findEvent(chunks, date, row, channels);
  return currentEvent && (
    eventsForRow(row, chunks, channels)
      .find(({start}) => start.getTime() === currentEvent.end.getTime()) ?? null
  );
}

/**
 * Searches for a past neighbouring event of an event at specified date and row. Returns null if not found.
 * @param row Index of channel for which we perform search.
 * @param date Date at which we start the search.
 * @param chunks Chunks' array.
 * @param channels Array of all channels.
 */
export function findPreviousEventForRow(row: number, date: Date, chunks: Chunk[], channels: Channel[]): Event | null {
  const currentEvent = findEvent(chunks, date, row, channels);
  return currentEvent && (
    eventsForRow(row, chunks, channels)
      .find(({end}) => end.getTime() === currentEvent.start.getTime()) ?? null
  );
}
