import React, {forwardRef} from 'react';
import {View} from 'react-native';

import {doNothing} from 'common/HelperFunctions';

import {ComponentSettings} from 'mw/api/CMSInterface';

import TileContainer from 'components/TileContainer';

import {useMenuTileStyling} from './MenuTile';

type MenuTilePlaceholderProps = {
  tileShape: ComponentSettings['tileShape'];
};

const MenuTilePlaceholder: React.FunctionComponent<MenuTilePlaceholderProps> = (props, ref) => {
  const {tileShape} = props;

  const {contentStyle, imageContainerStyle, focusFrameStyle, descriptionStyle} = useMenuTileStyling(tileShape, false);

  return (
    <TileContainer
      ref={ref}
      onPress={doNothing}
      style={contentStyle}
    >
      <View style={focusFrameStyle}>
        <View style={imageContainerStyle} />
      </View>
      <View style={[descriptionStyle]} />
    </TileContainer>
  );
};

export default React.memo(forwardRef(MenuTilePlaceholder));
