import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View, ViewStyle} from 'react-native';

import {dimensions, getValue, isBigScreen} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Icon, IconType} from 'components/Icon';
import MiniProgressBar from 'components/MiniProgressBar';
import NitroxText, {textStyles} from 'components/NitroxText';

type Props = {
  style?: ViewStyle;
  remaining: number;
  available: number;
}

const progressRunningOutThreshold = 0.8;
const quotaRunningOutIconSize = getValue({mobile: 14, defaultValue: 24});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  labelsRow: {
    flexDirection: 'row',
    height: isBigScreen ? 45 : 27,
    justifyContent: 'space-between'
  },
  progressBar: {
    height: isBigScreen ? 6 : 3
  },
  labelWithDigit: {
    flexDirection: 'row'
  },
  grayLabel: {
    color: colors.recordingsScreen.quota.descriptionText
  },
  lightLabel: {
    color: colors.recordingsScreen.quota.valueText
  },
  progressColor: colors.recordingsScreen.quota.bar.used,
  progressBackground: colors.recordingsScreen.quota.bar.remaining,
  progressRunningOutColor: colors.recordingsScreen.quota.bar.low,
  quotaRunningOutIconContainer: {
    marginLeft: isBigScreen ? dimensions.margins.medium : dimensions.margins.small,
    height: textStyles.body1().lineHeight,
    justifyContent: 'center'
  }
}));

const QuotaBar: React.FunctionComponent<Props> = props => {
  const {t} = useTranslation();
  const quotaProgress = useMemo(() => (props.available - props.remaining) / props.available, [props.available, props.remaining]);
  const usedHourString = useMemo(() => t('recordings.hourString', {hours: Math.round((props.available - props.remaining) / 60)}), [t, props.available, props.remaining]);
  const totalHourString = useMemo(() => t('recordings.hourString', {hours: Math.round(props.available / 60)}), [t, props.available]);
  const isQuotaRunningOut = quotaProgress > progressRunningOutThreshold;

  const styles = stylesUpdater.getStyles();
  return (
    <View style={props.style}>
      <View style={styles.labelsRow}>
        <View style={styles.labelWithDigit}>
          <NitroxText style={styles.grayLabel} textType='body1'>{t('recordings.usedSpaceLabel')}</NitroxText>
          <NitroxText style={styles.lightLabel} textType='body1'>{usedHourString}</NitroxText>
        </View>
        <View style={styles.labelWithDigit}>
          <NitroxText style={styles.grayLabel} textType='body1'>{t('recordings.totalSpaceLabel')}</NitroxText>
          <NitroxText style={styles.lightLabel} textType='body1'>{totalHourString}</NitroxText>
          {isQuotaRunningOut && (
            <View style={styles.quotaRunningOutIconContainer}>
              <Icon type={IconType.WarningCircle} size={quotaRunningOutIconSize} />
            </View>
          )}
        </View>
      </View>
      <MiniProgressBar
        style={styles.progressBar}
        progress={quotaProgress}
        progressColor={isQuotaRunningOut ? styles.progressRunningOutColor : styles.progressColor}
        backgroundColor={styles.progressBackground}
      />
    </View>
  );
};

export default React.memo(QuotaBar);
