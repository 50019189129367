import React, {useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {dimensions} from 'common/constants';
import {Log} from 'common/Log';

import {ChromecastDevice} from 'mw/platform/chromecast/ChromecastInterface';

import {IconType, Icon} from 'components/Icon';
import {ModalSelect} from 'components/ModalSelect';

const TAG = 'DeviceSelectionPopup';

type DeviceSelectionProps = {
  visible: boolean;
  devices: ChromecastDevice[];
  onClose: () => void;
  onDeviceChanged: (device: ChromecastDevice) => void;
}

const DeviceSelectionPopup: React.FC<DeviceSelectionProps> = props => {
  const {
    visible,
    devices,
    onClose,
    onDeviceChanged
  } = props;
  const {t} = useTranslation();

  const renderOptionIcon = useCallback(() => <Icon type={IconType.Cast} size={dimensions.icon.xxsmall} />, []);

  const options = useMemo(
    () => devices
      .filter(device => {
      // There is no certainity judging from the documentation that castDevice always have name set.
      // IOS implementation suggest name is an optional and might be null, as in Java it might always be null pointer
        if (!device.name) {
          Log.error(TAG, 'Cast Device ',device.id, ' with no name');
          return false;
        }
        return true;
      }).map(device => ({
        value: device,
        key: device.id,
        label: device.name || '', // INFO: label is used in modalselect (mobile)
        onPress: () => onDeviceChanged(device)
      })), [devices, onDeviceChanged]);

  return (
    <ModalSelect
      title={t('chromecast.deviceSelectionPopup.title')}
      options={options}
      visible={visible}
      onChange={onDeviceChanged}
      onClose={onClose}
      renderOptionIcon={renderOptionIcon}
    />
  );
};

export default DeviceSelectionPopup;
