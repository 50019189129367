import {compactMap} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {Error, ErrorType} from 'mw/api/Error';
import {PinState, Profile, ProfileConfigurableProperties, ProfileType} from 'mw/api/Profile';
import {InterfaceMapper, Result, ResultType} from 'mw/common/utils';

const TAG = 'ProfileMapper';

interface ADR8ExtraDetails {
  IsChildProfile?: any;
  UILanguage?: any;
  AudioLanguage?: any;
  SubtitleLanguage?: any;
  YearOfBirth?: any;
  DateFormat?: any;
  TimeFormat?: any;
  UIColor?: any;
  CCEnabled?: any;
  ShowAdultContent?: any;
  BlockUnratedEvents?: any;
  BlockUnratedMovies?: any;
  PCRatings?: any;
}

const extraDetailsKeys = [
  'isPCEnabled',
  'uiLanguage',
  'audioLanguage',
  'subtitleLanguage',
  'yearOfBirth',
  'dateFormat',
  'timeFormat',
  'uiColor',
  'ccEnabled',
  'showAdultContent',
  'blockUnratedEvents',
  'blockUnratedMovies',
  'pcRatings'
] as const;

export type ExtraDetailsKeys = typeof extraDetailsKeys[number];

function isStoredInExtraDetails(key: keyof ProfileConfigurableProperties): key is ExtraDetailsKeys {
  return extraDetailsKeys.includes(key as ExtraDetailsKeys);
}

export type ProfileExtraDetailsProperties = Pick<ProfileConfigurableProperties, ExtraDetailsKeys>;

const mappers: InterfaceMapper<ADR8ExtraDetails, ProfileExtraDetailsProperties> = {
  isPCEnabled: {
    fromPropertyName: 'IsChildProfile',
    map: (isChildProfile?: any) => !!isChildProfile,
    reverseMap: isPCEnabled => isPCEnabled
  },
  uiLanguage: {
    fromPropertyName: 'UILanguage',
    map: (uiLanguage?: any) => typeof uiLanguage === 'string' ? uiLanguage : '',
    reverseMap: uiLanguage => uiLanguage || undefined
  },
  audioLanguage: {
    fromPropertyName: 'AudioLanguage',
    map: (audioLanguage?: any) => typeof audioLanguage === 'string' ? audioLanguage : '',
    reverseMap: audioLanguage => audioLanguage || undefined
  },
  subtitleLanguage: {
    fromPropertyName: 'SubtitleLanguage',
    map: (subtitleLanguage?: any) => typeof subtitleLanguage === 'string' ? subtitleLanguage : '',
    reverseMap: subtitleLanguage => subtitleLanguage || undefined
  },
  yearOfBirth: {
    fromPropertyName: 'YearOfBirth',
    map: (yearOfBirth?: any) => Number.parseInt(yearOfBirth || -1, 10),
    reverseMap: yearOfBirth => yearOfBirth && yearOfBirth > 0 ? yearOfBirth : undefined
  },
  dateFormat: {
    fromPropertyName: 'DateFormat',
    map: (dateFormat?: any) => typeof dateFormat === 'string' ? dateFormat : '',
    reverseMap: dateFormat => dateFormat || undefined
  },
  timeFormat: {
    fromPropertyName: 'TimeFormat',
    map: (timeFormat?: any) => typeof timeFormat === 'string' ? timeFormat : '',
    reverseMap: timeFormat => timeFormat || undefined
  },
  uiColor: {
    fromPropertyName: 'UIColor',
    map: (uiColor?: any) => typeof uiColor === 'string' ? uiColor : '',
    reverseMap: uiColor => uiColor || undefined
  },
  ccEnabled: {
    fromPropertyName: 'CCEnabled',
    map: (CCEnabled?: any) => !!CCEnabled,
    reverseMap: ccEnabled => ccEnabled
  },
  showAdultContent: {
    fromPropertyName: 'ShowAdultContent',
    map: (showAdultContent?: any) => !!showAdultContent,
    reverseMap: showAdultContent => showAdultContent
  },
  blockUnratedEvents: {
    fromPropertyName: 'BlockUnratedEvents',
    map: (blockUnratedEvents?: any) => !!blockUnratedEvents,
    reverseMap: blockUnratedEvents => blockUnratedEvents
  },
  blockUnratedMovies: {
    fromPropertyName: 'BlockUnratedMovies',
    map: (blockUnratedMovies?: any) => !!blockUnratedMovies,
    reverseMap: blockUnratedMovies => blockUnratedMovies
  },
  pcRatings: {
    fromPropertyName: 'PCRatings',
    map: (pcRatings?: any) => pcRatings || {},
    reverseMap: pcRatings => typeof pcRatings !== 'undefined' ? pcRatings : undefined
  }
};

function extraDetailsFromProfileProperties(config: Partial<ProfileExtraDetailsProperties>): ADR8ExtraDetails {
  const result: ADR8ExtraDetails = {};
  return Object.entries(mappers).reduce((result, [propName, mapper]) => {
    const mappedPropName = mapper.fromPropertyName;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const mappedValue = mapper.reverseMap(config[propName]);
    if (typeof mappedValue !== 'undefined') {
      result[mappedPropName] = mappedValue;
    }
    return result;
  }, result);
}

function configurablePropertiesFromJson(json: ADR8ExtraDetails): ProfileExtraDetailsProperties {
  return {
    isPCEnabled: mappers.isPCEnabled.map(json.IsChildProfile),
    uiLanguage: mappers.uiLanguage.map(json.UILanguage),
    audioLanguage: mappers.audioLanguage.map(json.AudioLanguage),
    subtitleLanguage: mappers.subtitleLanguage.map(json.SubtitleLanguage),
    yearOfBirth: mappers.yearOfBirth.map(json.YearOfBirth),
    dateFormat: mappers.dateFormat.map(json.DateFormat),
    timeFormat: mappers.timeFormat.map(json.TimeFormat),
    uiColor: mappers.uiColor.map(json.UIColor),
    ccEnabled: mappers.ccEnabled.map(json.CCEnabled),
    showAdultContent: mappers.showAdultContent.map(json.ShowAdultContent),
    blockUnratedEvents: mappers.blockUnratedEvents.map(json.BlockUnratedEvents),
    blockUnratedMovies: mappers.blockUnratedMovies.map(json.BlockUnratedMovies),
    pcRatings: mappers.pcRatings.map(json.PCRatings)
  };
}

function profileFromJson(json: any): ResultType<Profile> {
  if (!json || !json.id) {
    Log.error(TAG, 'Could not parse profile from json: ', json);
    return Result.failure(new Error(ErrorType.ParsingError));
  }
  const extraDetails = configurablePropertiesFromJson(json.attributes || {});
  const type = !!json.main ? ProfileType.Main : ProfileType.Normal;
  return Result.success(new Profile({
    id: String(json.id || ''),
    name: json.username || json.name || '',
    type,
    avatarUri: json.avatarUri,
    ...extraDetails,
    pinState: !!json.requiresPin ? PinState.ProfilePinRequired : PinState.ProfilePinNotRequired
  }));
}

function profilesFromJson(json: any): ResultType<Profile[]> {
  if (!json || !Array.isArray(json)) {
    Log.error(TAG, 'Could not parse profiles from json: ', json);
    return Result.failure(new Error(ErrorType.ParsingError));
  }
  return Result.success(compactMap(json, p => {
    return Result.unwrap(profileFromJson(p));
  }));
}

export const profileMapper = {
  profileFromJson,
  profilesFromJson,
  isStoredInExtraDetails,
  extraDetailsFromProfileProperties
};
