import {Device} from 'mw/api/Device';

export class CpeMapper {

  public static toDevice(json: any): Device {
    return new Device(json.id, json.Name, json.CustomerManaged, '');
  }

  public static toDevices(jsonArray: any[]): Device[] {
    return jsonArray.map(CpeMapper.toDevice);
  }
}
