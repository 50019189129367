import React, {useEffect, useState, useCallback} from 'react';
import {NavigationInjectedProps} from 'react-navigation';

import {NavigationFocusState} from 'common/HelperTypes';

import Orientation from 'components/orientationLocker/OrientationLocker';
import {OrientationType} from 'components/orientationLocker/OrientationLockerTypes';
import {TopLevelPortalManager, TopLevelPortalType} from 'components/TopLevelPortalManager';
import {useNavigationFocusState, useEventListener} from 'hooks/Hooks';

export type SupportedOrientations = 'all' | 'portrait' | 'landscape';

type OrientationManagerProps = {
  allowedOrientations: SupportedOrientations;

  /**
   * Only works when allowedOrientations === 'all'
   */
  initialOrientation?: Exclude<SupportedOrientations, 'all'>;
} & NavigationInjectedProps;

function chooseLandscapeLock(orientation: OrientationType) {
  if (orientation === 'LANDSCAPE-LEFT' || orientation === 'PORTRAIT') {
    Orientation.lockToLandscapeLeft();
  } else {
    Orientation.lockToLandscapeRight();
  }
}

function lockToLandscape() {
  Orientation.getDeviceOrientation(chooseLandscapeLock);
}

const OrientationManager: React.FC<OrientationManagerProps> = props => {
  const {allowedOrientations, initialOrientation, navigation} = props;
  const focusState = useNavigationFocusState(navigation);
  const [floatingPortal, setFloatingPortal] = useState(TopLevelPortalManager.getInstance().getCurrentPortal() === TopLevelPortalType.Floating);

  const portalChangeHandler = useCallback((portalType?: TopLevelPortalType) => {
    if (floatingPortal && (portalType === TopLevelPortalType.Fullscreen || portalType === TopLevelPortalType.Floating)) {
      return;
    }
    setFloatingPortal(portalType === TopLevelPortalType.Floating);
  }, [floatingPortal]);
  useEventListener('change', portalChangeHandler, TopLevelPortalManager.getInstance());

  useEffect(() => {
    if (focusState === NavigationFocusState.IsBlurred || focusState === NavigationFocusState.IsBlurring) {
      return;
    }
    if (floatingPortal) {
      Orientation.unlockAllOrientations();
      return;
    }
    switch (allowedOrientations) {
      case 'landscape':
        const handleSwitchLandscape = (orientation: OrientationType) => {
          if (allowedOrientations !== 'landscape' || orientation === 'UNKNOWN') {
            return;
          }
          chooseLandscapeLock(orientation);
        };
        lockToLandscape();
        Orientation.addDeviceOrientationListener(handleSwitchLandscape);
        return () => Orientation.removeDeviceOrientationListener(handleSwitchLandscape);
      case 'portrait':
        Orientation.lockToPortrait();
        break;
      case 'all':
        if (initialOrientation) {
          switch (initialOrientation) {
            case 'portrait':
              Orientation.lockToPortrait();
              break;
            case 'landscape':
              lockToLandscape();
              break;
          }
          const handleChange = () => Orientation.unlockAllOrientations();
          Orientation.addDeviceOrientationListener(handleChange);
          return () => Orientation.removeDeviceOrientationListener(handleChange);
        }
        Orientation.unlockAllOrientations();
        break;
    }
  }, [allowedOrientations, initialOrientation, focusState, floatingPortal]);

  return null;
};

export default OrientationManager;
