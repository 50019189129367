import {isBigScreen} from 'common/constants';

import SettingsClickableOptionContentGrosso from './SettingsClickableOptionContent.grosso';
import SettingsClickableOptionContentPiccolo from './SettingsClickableOptionContent.piccolo';

export type SettingsOptionProps = {
  title: string;
  description?: string;
  type?: string;
  buttonLabel: string;
  onPress: () => void;
}

export default isBigScreen ? SettingsClickableOptionContentGrosso : SettingsClickableOptionContentPiccolo;
