import React, {useRef, useCallback, ReactNode, useEffect} from 'react';

import {isBigScreen, isDesktopBrowser} from 'common/constants';
import {DateUtils} from 'common/DateUtils';
import {Log} from 'common/Log';

import {nxffConfig} from 'mw/api/NXFF';

import InactivityPopup from 'components/InactivityPopup';
import {KeyEventManager} from 'components/KeyEventManager';

import {useDisposableCallback, useFunction, useDisposableState, useLazyEffect, useIsScreenFocused} from './Hooks';

const mouseEvents: (keyof GlobalEventHandlersEventMap)[] = [
  'mousedown',
  'mousemove',
  'mouseup'
  // those are not all mouse events, but they suffice to determine if user is active
];

function useAnyMouseEventListener(active: boolean, onMouseEvent: () => void) {
  const listener = useFunction(onMouseEvent);
  useEffect(() => {
    if (!active || !isDesktopBrowser) {
      return;
    }
    mouseEvents.forEach(event => document.addEventListener(event, listener));
    return () => {
      mouseEvents.forEach(event => document.removeEventListener(event, listener));
    };
  }, [active, listener]);
}

// check time of last interaction only every 30 secs for performance reasons
const checkInactivityInterval = 30 * DateUtils.msInSec;

type UseInactivityPopup = {
  userInactive: boolean;
  renderInactivityPopup: () => ReactNode;
}

/**
 * This hook monitors time from last user interaction with the
 * app and displays an InactivityPopup when user is inactive
 * for `nxffConfig.getConfig().UI.InactivityTimeout` minutes.
 * `userInactive` flag is flipped to true if user does not interact with
 * the app for `nxffConfig.getConfig().UI.InactivityReactionTimeout` seconds
 * after displaying the InactivityPopup.
 * `userInactive` flips to false again after any user activity.
 *
 * Big screen only
 */
export function useInactivityTimeout(debugTag: string, enabled = isBigScreen): UseInactivityPopup {
  const [popupVisible, setPopupVisible] = useDisposableState(false);
  const [userInactive, setUserInactive] = useDisposableState(false);
  const intervalId = useRef(0);
  const lastMouseEventTimestamp = useRef(Date.now());
  const {isScreenFocused} = useIsScreenFocused();
  const tag = useRef(`useInactivityTimeout - ${debugTag}`).current;

  const getLastInteractionTimestamp = useFunction((): number => {
    const keyEvent = KeyEventManager.getInstance().getLastInteractionTimestamp();
    return Math.max(keyEvent, lastMouseEventTimestamp.current);
  });

  const shouldCheckInactivity = useFunction(() => {
    return isBigScreen
      && !userInactive
      && isScreenFocused
      && !popupVisible // don't check inactivity if inactivity popup is already on the screen
      && nxffConfig.getConfig().UI.InactivityTimeout; // value of 0 means disabling the feature
  });

  const popupInterrupted = useFunction(() => {
    if (!popupVisible) {
      return;
    }
    Log.info(tag, 'User became active once again, closing popup.');
    setPopupVisible(false);
  });

  const becomeActiveAgain = useFunction(() => {
    Log.info(tag, 'User became active once again.');
    setUserInactive(false);
  });

  const isInactive = useFunction(() => {
    if (nxffConfig.getConfig().UI.InactivityTimeout === 0) {
      return false;
    }
    const timeout = nxffConfig.getConfig().UI.InactivityTimeout * DateUtils.msInMin;
    const lastInteraction = getLastInteractionTimestamp();
    const now = Date.now();
    const delta = now - lastInteraction;
    Log.info(tag, `Checking inactivity: time from last interaction: ${delta / DateUtils.msInSec}s`);
    return delta >= timeout;
  });

  const checkInactivity = useDisposableCallback(() => {
    if (!shouldCheckInactivity()) {
      return;
    }
    if (isInactive()) {
      Log.info(tag, 'User inactive, displaying popup.');
      setPopupVisible(true);
      KeyEventManager.getInstance().notifyOnFirstActivity(popupInterrupted);
    }
  }, [shouldCheckInactivity, isInactive, tag]);

  const stopCheckingInactivity = useCallback(() => {
    if (intervalId.current) {
      Log.info(tag, 'Stop checking inactivity.');
      clearInterval(intervalId.current);
      intervalId.current = 0;
    }
  }, [tag]);

  const startCheckingInactivity = useFunction(() => {
    stopCheckingInactivity();
    Log.info(tag, `Start checking inactivity every ${checkInactivityInterval / DateUtils.msInSec}s.`);
    intervalId.current = setInterval(checkInactivity, checkInactivityInterval);
  });

  useLazyEffect(() => {
    if (!enabled) {
      return;
    }
    if (isScreenFocused) {
      if (isInactive()) {
        Log.info(tag, 'Navigated to the screen while inactive.');
        setUserInactive(true);
      } else {
        startCheckingInactivity();
      }
    } else {
      stopCheckingInactivity();
    }
  }, [isScreenFocused], [tag, enabled, startCheckingInactivity, isInactive, stopCheckingInactivity]);

  useLazyEffect(() => {
    if (!enabled) {
      return;
    }
    if (userInactive) {
      KeyEventManager.getInstance().notifyOnFirstActivity(becomeActiveAgain);
    } else {
      if (isScreenFocused) {
        startCheckingInactivity();
      }
    }
  }, [userInactive], [tag, enabled, isScreenFocused]);

  const renderInactivityPopup = useCallback(() => {
    return enabled ? (
      <InactivityPopup
        visible={popupVisible}
        timeout={nxffConfig.getConfig().UI.InactivityReactionTimeout}
        onTimeout={() => {
          Log.info(tag, 'No reaction to inactivity popup, setting inactive state.');
          setPopupVisible(false);
          setUserInactive(true);
        }}
      />
    ) : null;
  }, [tag, enabled, popupVisible, setPopupVisible, setUserInactive]);

  useAnyMouseEventListener(enabled, useCallback(() => {
    lastMouseEventTimestamp.current = Date.now();
    if (popupVisible) {
      popupInterrupted();
    }
    if (userInactive) {
      becomeActiveAgain();
    }
  }, [userInactive, popupVisible, popupInterrupted, becomeActiveAgain]));

  return {
    userInactive,
    renderInactivityPopup
  };
}
