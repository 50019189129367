import {ComponentType as UXMComponentType} from 'mw/api/CMSInterface';
import {Component as UXMComponent} from 'mw/cms/Component';

import {VodCategoryNavigatorProps} from 'components/vod/categoryNavigator/VodCategoryNavigator';
import {VodScreenQueryParametersProps} from 'screens/VodScreenQueryParameters';

export type Hotspot = UXMComponent & {type: UXMComponentType.Hotspot};

export type Grid = UXMComponent & {type: UXMComponentType.Grid | UXMComponentType.GridMenu};

export enum CustomComponentType {
  breadcrumbs = 'breadcrumbs',
  queryParameters = 'queryParameters'
}

export enum UXMComponentPlacement {
  afterBreadcrumbs = 'afterBreadcrumbs',
  afterPromoBanner = 'afterPromoBanner'
}

export type UXMComponentStackBreadcrumbsComponent = {
  type: CustomComponentType.breadcrumbs;
  title: CustomComponentType.breadcrumbs;
  placement: UXMComponentPlacement | number;
  props: VodCategoryNavigatorProps;
  onPress: (categoryIndex: number) => void;
}

export type UXMComponentStackQueryParametersComponent = {
  type: CustomComponentType.queryParameters;
  title: CustomComponentType.queryParameters;
  placement: UXMComponentPlacement | number;
  props: VodScreenQueryParametersProps;
  onPress: () => void;
}

export type UXMComponentStackCustomComponent = UXMComponentStackBreadcrumbsComponent | UXMComponentStackQueryParametersComponent;
export type UMXStackComponent = UXMComponent | UXMComponentStackCustomComponent;

export function isGrid(component: UMXStackComponent): component is Grid {
  return component.type === UXMComponentType.Grid || component.type === UXMComponentType.GridMenu;
}

export function isHotspot(component: UMXStackComponent): component is Hotspot {
  return component.type === UXMComponentType.Hotspot;
}
