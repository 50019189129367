import {defaultLogLevel, levels, LogLevel} from './FileLoggerInterface';

// TODO CL-3898 Need to add FileLogger.web.ts for Web and Tizen
const FileLogger: any = {
  setSeverity: (logLevel: any) => Object.keys(levels)[logLevel]
};

Object.keys(levels).forEach(level => {
  FileLogger[level] = (msg: any) => {};
});

function getLogPaths(): string[] { return []; }
async function syncLogFiles(): Promise<void> {}

export {FileLogger, defaultLogLevel, levels, LogLevel, getLogPaths, syncLogFiles};
