import {Authority} from 'mw/api/NXFF';
export class ParentalControlRating {
  public authority: string;
  public ratings: string[];
  public adultOnlyRatings: string[];
  private _ratingsMap?: Map<string, string[]>;

  public constructor(rating: Authority) {
    this.authority = rating.authority || '';
    this.ratings = rating.ratings || [];
    this.adultOnlyRatings = rating.adultOnlyRatings || [];

    if (rating.ratingsMap) {
      const map: Map<string, string[]> = new Map();
      Object.getOwnPropertyNames(rating.ratingsMap).forEach((property: string) => {
        map.set(property, rating.ratingsMap[property]);
      });
      this._ratingsMap = map;
    }
  }

  public get ratingsMap(): Map<string, string[]> {
    if (!this._ratingsMap) {
      const map: Map<string, string[]> = new Map();
      this.ratings.forEach((rating: string) => {
        const startIndex = this.ratings.indexOf(rating) + 1;
        if (startIndex < this.ratings.length) {
          map.set(rating, this.ratings.slice(startIndex, this.ratings.length));
        }
      });

      this._ratingsMap = map;
    }

    return this._ratingsMap;
  }
}
