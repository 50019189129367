import {createStyles} from 'common-styles';
import React from 'react';
import {View, ViewStyle, StyleSheet, StyleProp} from 'react-native';

type Props = {
  style?: StyleProp<ViewStyle>;
  horizontal?: boolean;
};

const styles = createStyles({
  horizontal: {
    height: StyleSheet.hairlineWidth
  },
  vertical: {
    width: StyleSheet.hairlineWidth
  }
});
const Separator: React.FunctionComponent<Props> = props => {
  return <View style={[props.horizontal ? styles.horizontal : styles.vertical, props.style]} />;
};
export default React.memo(Separator);
