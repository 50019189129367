import React, {useMemo, useEffect, useCallback} from 'react';
import {Animated, ViewStyle} from 'react-native';

import {dimensions} from 'common/constants';

import AnimatedScrollView from './AnimatedScrollView';
import TimeBarCurrentTime from './TimeBarCurrentTime';
import {useTimeBar, tileEndDate} from './TimeBarHooks';
import TimeBarTile from './TimeBarTile';

export const timebarHeight = dimensions.epg.timeBarHeight;
const timebarTileMargin = dimensions.margins.small;

type TimeBarViewProps = {
  scrollOffset: Animated.Value;
  gridWidth: number;
  timeBarWidth: number;
  originDate: Date;
  offsetForDate: (date: Date) => number;
  dateForOffset: (offset: number) => Date;
  nowDate: Date;
};

const TimeBarView: React.FC<TimeBarViewProps> = React.memo(({
  scrollOffset,
  gridWidth,
  timeBarWidth,
  originDate,
  offsetForDate,
  dateForOffset,
  nowDate
}) => {
  const tileStyle = useCallback((start: Date, end: Date): ViewStyle => {
    const left = offsetForDate(start);
    const width = offsetForDate(end) - left;
    return {
      position: 'absolute',
      top: timebarTileMargin,
      left,
      width,
      bottom: timebarTileMargin
    };
  }, [offsetForDate]);

  const {
    scrollOffsetChanged,
    timeBarScrollOffset,
    timeBarContent,
    currentTileStart,
    currentTileProgress
  } = useTimeBar({originDate, nowDate, dateForOffset});

  useEffect(() => {
    const id = scrollOffset.addListener(scrollOffsetChanged);
    return () => scrollOffset.removeListener(id);
  }, [scrollOffset, scrollOffsetChanged]);

  const content = useMemo(() => {
    return timeBarContent.map(({start, end, isPast}) => (
      <TimeBarTile
        key={start.getTime()}
        start={start}
        isPast={isPast}
        style={tileStyle(start, end)}
      />
    ));
  }, [tileStyle, timeBarContent]);

  const currentTileStyle = useMemo(
    () => tileStyle(currentTileStart, tileEndDate(currentTileStart)),
    [currentTileStart, tileStyle]
  );

  const size = useMemo(() => ({
    width: gridWidth,
    height: timebarHeight
  }), [gridWidth]);

  return (
    <AnimatedScrollView
      offsetX={scrollOffset}
      size={size}
    >
      {content}
      <TimeBarTile
        start={currentTileStart}
        progress={currentTileProgress}
        style={currentTileStyle}
      />
      <TimeBarCurrentTime
        nowDate={nowDate}
        offsetForDate={offsetForDate}
        timeBarWidth={timeBarWidth}
        timeBarScrollOffset={timeBarScrollOffset}
      />
    </AnimatedScrollView>
  );
});
TimeBarView.displayName = 'TimeBarView';

export default TimeBarView;
