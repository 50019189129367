import Color from 'color';
import {createStyles, defaultStyles} from 'common-styles';
import React, {useRef, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleSheet, StyleProp, ViewStyle, View} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import {withNavigation, NavigationScreenProps} from 'react-navigation';

import {dimensions, isBigScreen, isSmartTV, isWeb, AppRoutes, isDesktopBrowser, isMobile, homescreenPromotionalBannerOverlap} from 'common/constants';
import {doNothing} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {constColors, BaseColors} from 'common-styles/variables/base-colors';

import {SpecialFilter} from 'mw/api/CatalogInterface';
import {Media} from 'mw/api/Metadata';
import {Component as CMSComponent, isDataSourceFilterBased} from 'mw/cms/Component';
import {Link, LinkType} from 'mw/cms/Menu';
import {mw} from 'mw/MW';

import {useCreateDefaultVisibilityLimits} from 'components/AnimatedSwimlaneStack';
import FocusParent from 'components/FocusParent';
import {NativeKeyEvent, SupportedKeys} from 'components/KeyEventManager';
import {ListShadow, ListShadowPosition} from 'components/ListShadow';
import {mediaTileMarginHorizontal} from 'components/mediaTiles/MediaTile';
import MediaTileSwimlanesStack from 'components/MediaTileSwimlanesStack';
import {STBMenuState} from 'components/navigation/NavigationHelperTypes';
import {useSTBMenu} from 'components/navigation/STBNavigationView';
import {PlayerManager} from 'components/player/PlayerManager';
import {PlayerViews, PlayerView} from 'components/player/PlayerView';
import {SwimlaneStackInterface} from 'components/SwimlaneInterfaces';
import {NamedAction} from 'components/utils/SwimlaneVisibilityLimit';
import {Hotspot} from 'components/uxm-components/UMXComponentStack.shared';
import UXMComponentStack from 'components/uxm-components/UXMComponentStack';
import PromotionalBanner, {PromotionalBannerAnimatedScrollView, PromotionalBannerAnimatedScrollViewInterface} from 'components/vod/promotionalBanner/PromotionalBanner';
import {PromotionalBannerMode} from 'components/vod/promotionalBanner/PromotionalBanner.shared';
import {useKeyEventHandler, useDisposable, useLazyEffect, useScreenInfo, useChangeEffect} from 'hooks/Hooks';
import {useInactivityTimeout} from 'hooks/useInactivityTimeout';
import {useTabBarState} from 'hooks/useTabBarState';

import NitroxScreen, {useScreenManagerFocusHelper, ScreenVisibilityState} from './NitroxScreen';

const TAG = 'HomeScreen';

const swimlaneInsets = {
  left: isBigScreen
    ? dimensions.mainMenu.marginHorizontal - mediaTileMarginHorizontal
    : dimensions.margins.small
};

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  mobileHeader: {
    backgroundColor: constColors.transparent
  },
  playerGradientMaskColor: colors.playerMaskGradient.background
}));

const staticStyles = createStyles({
  container: {
    flex: 1
  },
  promotionalBannerDetails: {
    marginLeft: swimlaneInsets.left
  },
  listShadow: {
    bottom: 0
  },
  componentStackContainer: {
    overflow: 'visible'
  },
  swimlaneContainer: {
    width: '100%',
    ...isBigScreen ? {
      height: '100%'
    } : {marginBottom: 50}
  }
});

const promotionalBannerProps = {
  mode: PromotionalBannerMode.desktopFullScreen,
  detailsStyle: staticStyles.promotionalBannerDetails
};

const playerMaskGradientProps = {
  locations: [0, 0.88, 0.95],
  useAngle: true,
  angle: 180
};

let swimlaneStack: SwimlaneStackInterface | null = null;
const handleArrowPress = (event: NativeKeyEvent) => {
  if (!isWeb) {
    return;
  }
  switch (event.key) {
    case SupportedKeys.Left:
    case SupportedKeys.Right:
    case SupportedKeys.Up:
    case SupportedKeys.Down:
      event.event && event.event.preventDefault();
  }
};

const componentStackInset = isDesktopBrowser ? 0 : dimensions.margins.xxLarge;
const firstRowCustomOffset = -componentStackInset + 65;

type Props = NavigationScreenProps<{
  link?: Link;
  reloadScreen?: boolean;
}>

const HomeScreen: React.FunctionComponent<Props> = props => {
  const {t} = useTranslation();
  const styles = stylesUpdater.getStyles();
  const player = useRef(mw.players.main);
  const promotionalBannerAnimatedScrollViewRef = useRef<PromotionalBannerAnimatedScrollViewInterface>(null);
  const {screenVisibilityState, onScreenVisibilityChange} = useScreenManagerFocusHelper(props.navigation);
  const stbMenu = useSTBMenu();
  const getLastPlayedChannel = useDisposable(() => mw.catalog.getLastPlayedChannel(), []);
  const [promoBanner, setPromoBanner] = useState<Hotspot | null>(null);
  const [menuVisible, setMenuVisible] = useState<boolean>(true);
  const {size} = useScreenInfo();
  const swimlaneContainerStyle = useMemo(() => ({
    ...staticStyles.swimlaneContainer,
    ...isBigScreen && size,
    // in case there is no promo banner swimlane container has to be moved down and match components stack top
    // because otherwise it would overlap with the menu's focus parent
    ...(isBigScreen && !isDesktopBrowser) && {top: promoBanner ? -homescreenPromotionalBannerOverlap : dimensions.mainMenu.height}
  }), [size, promoBanner]);
  const componentStackContainerStyle: StyleProp<ViewStyle> = !promoBanner && staticStyles.componentStackContainer;
  const [promoBannerExpanded, setPromoBannerExpanded] = useState(true);
  const [firstRowFocused, setFirstRowFocused] = useState(false);

  const {userInactive, renderInactivityPopup} = useInactivityTimeout(TAG, isSmartTV);
  const tuneLastPlayedChannel = useCallback(async () => {
    try {
      const channel = await getLastPlayedChannel();
      PlayerManager.getInstance().tune(channel, PlayerViews.Home, {
        playRate: 1
      });
    } catch (error) {
      Log.error(TAG, error);
    }
  }, [getLastPlayedChannel]);

  const onBackPressed = useCallback(() => {
    stbMenu?.focusMenu();
    return true;
  }, [stbMenu]);

  useLazyEffect(() => {
    if (!isSmartTV || screenVisibilityState !== ScreenVisibilityState.Active) {
      return;
    }
    if (userInactive || promoBanner) {
      Log.info(TAG, `${userInactive ? 'User inactive' : 'Promotional banner present'}, stopping player...`);
      mw.players.main.stop();
    } else {
      tuneLastPlayedChannel();
    }
  }, [userInactive, screenVisibilityState, promoBanner], [tuneLastPlayedChannel, screenVisibilityState]);

  const onScreenFocused = useCallback(() => {
    const reloadScreen = props.navigation.getParam('reloadScreen');

    /**
      * Reload swimlanes when {@link reloadScreen} is undefined for sanity. First launch on app may result in unset navigation params.
      */
    if (swimlaneStack !== null && reloadScreen !== false) {
      Log.info(TAG, 'fetching page layout');

      /* Current implementation of swimlanes trigger data fetch only when swimlane is recreated from scratch, or when swimlane is an special swimlane reacting to MetaEvents. Requirement for HomeScreen is to refresh swimlanes everytime we enter, data still may be fetched from cache (ContentCache.getContent method)
      */
      swimlaneStack.clear();
      swimlaneStack.fetchPageLayout(props.navigation.getParam('link'));
    }
    props.navigation.setParams({reloadScreen: false});
  }, [props.navigation]);

  const onPlayerViewReady = useCallback(() => {
    if (screenVisibilityState !== ScreenVisibilityState.Active) {
      return;
    }
    player.current.switchPlayerView(PlayerViews.Home)
      .catch(reason => {
        Log.error(TAG, 'onPlayerViewReady', reason);
      });
  }, [screenVisibilityState]);

  const createShowAllAction = useCallback((component: CMSComponent): NamedAction => {
    const isWatchList = isDataSourceFilterBased(component.dataSource)
      && component.dataSource.filters[0].value === SpecialFilter.WatchList;
    return {
      name: 'showAll',
      label: t('common.showAll'),
      onPress: isWatchList
        ? () => props.navigation.navigate(AppRoutes.WatchList)
        : () => props.navigation.navigate(AppRoutes.MediaGrid, {component})
    };
  }, [t, props.navigation]);

  const createVisibilityLimits = useCreateDefaultVisibilityLimits();

  const createStaticHeaderActions = useCallback((component: CMSComponent): NamedAction[] => (
    isMobile
      ? [createShowAllAction(component)]
      : []
  ), [createShowAllAction]);

  useKeyEventHandler('keydown', handleArrowPress);

  const onSwimlanesFocusChange = useCallback((focusedRow: number) => {
    if (isDesktopBrowser) {
      return;
    }
    if (!promoBanner) {
      setMenuVisible(focusedRow < 1);
      return;
    }
    if (focusedRow === 1) {
      promotionalBannerAnimatedScrollViewRef.current?.hide(homescreenPromotionalBannerOverlap);
      setFirstRowFocused(false);
      setMenuVisible(false);
    } else if (focusedRow === 0) {
      setPromoBannerExpanded(false);
      promotionalBannerAnimatedScrollViewRef.current?.show();
      setFirstRowFocused(true);
      setMenuVisible(true);
    }
  }, [promoBanner]);

  const onFocus = useCallback((index: number, row: number, media: Media, options: FocusOptions | undefined, wrapAroundActive: () => boolean) => {
    if (options && options.preventScroll) {
      return;
    }
    onSwimlanesFocusChange(row);
  }, [onSwimlanesFocusChange]);

  const onSwimlanesStackReady = useCallback(() => {
    Log.info(TAG, 'Swimlanes are ready - unblocking EPG refresh');
    mw.catalog.unblockIdleActions();
  }, []);

  const mobileHeader = useMemo(() => ({
    showLogo: true,
    showNewStylingNotification: true,
    shouldDisplayMenu: true
  }), []);

  const link = useMemo(() => {
    const link = props.navigation.getParam('link');
    return link?.type === LinkType.PAGE ? link : null;
  }, [props.navigation]);

  const onFocusEscapedSwimlanes = useCallback(() => {
    if (props.navigation.isFocused()) {
      setPromoBannerExpanded(true);
    }
  }, [props.navigation]);

  const getTabBarState = useTabBarState();

  useChangeEffect(() => {
    const {thisTabIndex, currentTabIndex} = getTabBarState() ?? {};
    if (screenVisibilityState !== ScreenVisibilityState.Active && (
      thisTabIndex !== currentTabIndex || promoBannerExpanded
    )) {
      setFirstRowFocused(false);
    }
  }, [screenVisibilityState], [getTabBarState, promoBannerExpanded]);

  const promoBannerPreferredFocus = !!promoBanner && menuVisible && !firstRowFocused;

  const getTopInset = useCallback((hasHotspot: boolean): number => componentStackInset + (!hasHotspot && isDesktopBrowser ? dimensions.mainMenu.height : 0), []);

  return (
    <NitroxScreen
      style={[defaultStyles.view, {justifyContent: 'flex-start'}]}
      menuState={menuVisible ? STBMenuState.Transparent : STBMenuState.Hidden}
      navigation={props.navigation}
      shouldUnblockIdleActionsOnAppear={false}
      stopPlaybackOnAppear={!isSmartTV}
      onScreenFocused={onScreenFocused}
      onScreenVisibilityChange={onScreenVisibilityChange}
      onBackButtonPressed={onBackPressed}
      mobileHeaderProps={mobileHeader}
      testID='screen_home'
    >
      <PromotionalBannerAnimatedScrollView
        /* eslint-disable @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        ref={promotionalBannerAnimatedScrollViewRef}
        /* eslint-enable @typescript-eslint/ban-ts-comment */
        promoBanner={promoBanner}
      >
        {(isSmartTV && !promoBanner) && (
          <View style={StyleSheet.absoluteFillObject}>
            {screenVisibilityState === ScreenVisibilityState.Active && <PlayerView debugName={TAG} type={PlayerViews.Home} style={StyleSheet.absoluteFillObject} onReady={onPlayerViewReady} />}
            <LinearGradient
              {...playerMaskGradientProps}
              colors={[dimensions.opacity.xlow, dimensions.opacity.xxhigh, dimensions.opacity.xxhigh].map(
                alpha => Color(styles.playerGradientMaskColor).alpha(alpha)
                  .toString())}
              style={StyleSheet.absoluteFillObject}
            />
          </View>
        )}
        <FocusParent
          rememberLastFocused={!promoBannerExpanded || !menuVisible}
        >
          {isBigScreen && promoBanner && (
            <PromotionalBanner
              dataSource={promoBanner}
              detailsStyle={staticStyles.promotionalBannerDetails}
              mode={PromotionalBannerMode.fullWidth}
              visible={menuVisible}
              expanded={promoBannerExpanded && menuVisible}
              hasTvPreferredFocus={promoBannerPreferredFocus}
            />
          )}
          <FocusParent
            debugName='HomeScreenMediaTileSwimlanesStack'
            style={swimlaneContainerStyle}
            onFocusEscape={onFocusEscapedSwimlanes}
          >
            {isMobile && (
              <MediaTileSwimlanesStack
                ref={ref => {swimlaneStack = ref as SwimlaneStackInterface;}}
                onTileFocus={onFocus}
                fixedFocusPosition={isBigScreen}
                swimlaneInsets={swimlaneInsets}
                headerInsetLeft={mediaTileMarginHorizontal}
                createVisibilityLimits={createVisibilityLimits}
                onReady={onSwimlanesStackReady}
                createStaticHeaderActions={createStaticHeaderActions}
              />
            )}
            {isBigScreen && link && (
              <UXMComponentStack
                focusOnAppear={!promoBannerPreferredFocus}
                debugName='HomeScreen'
                link={link}
                onFocusChange={onSwimlanesFocusChange}
                onReady={onSwimlanesStackReady}
                topInset={getTopInset}
                firstNonEmptyRowCustomOffset={firstRowCustomOffset}
                rowInsets={swimlaneInsets}
                containerStyle={componentStackContainerStyle}
                onFetchedHotspot={isDesktopBrowser ? undefined : setPromoBanner} // TODO: https://jira.schange.com/browse/CL-7435
                onFetchedGrid={doNothing}
                promotionalBannerProps={promotionalBannerProps}
              />
            )}
            {renderInactivityPopup()}
          </FocusParent>
        </FocusParent>
      </PromotionalBannerAnimatedScrollView>
      <ListShadow direction={ListShadowPosition.Bottom} style={staticStyles.listShadow} />
    </NitroxScreen>
  );
};

export default withNavigation(HomeScreen);
