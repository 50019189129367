import {createStyles} from 'common-styles';
import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {NavigationScreenProps} from 'react-navigation';

import {disposable, disposableCallback} from 'common/Async';
import {isMobile, AppRoutes, isBigScreen} from 'common/constants';
import {openMediaDetails} from 'common/HelperFunctions';
import {Log} from 'common/Log';
import {Pager, PagerEvent, PagerDataChangedEvent} from 'common/Pager';

import {PVRQuota, Recording, RecordingStatus, RecordingsSorting, RecordingsFilter, PVREvent, isSingleRecording, isSeriesRecording} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import DeleteConfirmationPopup from 'components/DeleteConfirmationPopup';
import DeleteSelectionMenu, {DeleteSelectionMenuActionKey} from 'components/deleteSelectionMenu/DeleteSelectionMenu';
import {Selection, hasAll, hasNone} from 'components/utils/Selection';
import RecordingsScreenPiccolo from 'screens/recordings/RecordingsScreen.piccolo';
import {RecordingsCollection, RecordingSubscreenProps} from 'screens/RecordingsScreenHelperTypes';

import {RecordingsScreenGrosso} from './RecordingsScreen.grosso';

const TAG = 'RecordingsScreen';

const recordingsPageSize = 20;

const staticStyles = createStyles({
  deleteSelectionMenu: {
    ...isBigScreen && {
      height: 'auto',
      marginRight: 0
    }
  }
});

export const deleteSelectionMenuStyle = staticStyles.deleteSelectionMenu;

type RecordingsScreenProps = NavigationScreenProps & WithTranslation;

type RecordingsScreenState = {
  deleteConfirmationPopupVisible: boolean;
  selectionMenuActions: DeleteSelectionMenuActionKey[];
  selectionMenuDisabledActions: DeleteSelectionMenuActionKey[];
  selectionMenuSelectedActions: DeleteSelectionMenuActionKey[];
} & RecordingsCollection;

class RecordingsScreen extends React.Component<RecordingsScreenProps, RecordingsScreenState> {
  private readonly mwGetRecordings = disposable(mw.pvr.getRecordings.bind(mw.pvr));
  private readonly mwGetPVRQuota = disposable(mw.pvr.getPVRQuota.bind(mw.pvr));
  private readonly mwDeleteRecordings = disposable(mw.pvr.deleteRecordings.bind(mw.pvr));

  private pager = new Pager<Recording>((offset: number, limit: number) => this.mwGetRecordings({
    offset,
    limit,
    sortBy: this.state.recordingsSorting,
    status: this.state.recordingsStatus,
    type: this.state.recordingsType
  }), recordingsPageSize);

  private readonly onLoadingStarted = disposableCallback(firstPage => {
    firstPage && this.setState({isLoading: true});
  });

  private readonly onDataChanged = disposableCallback((event: PagerDataChangedEvent<Recording>) => {
    Log.debug(TAG, event.numChangedItems + ' recordings were ' + event.changeType + ' starting from index ' + event.changedItemsStartIndex);
    this.setState({
      quota: mw.pvr.getCachedPVRQuota(),
      recordings: event.data,
      hasMoreRecordings: event.hasMoreData,
      isLoading: false
    });
  });

  private readonly onErrorOccured = disposableCallback(() => {
    this.setState({
      hasMoreRecordings: false,
      isLoading: false
    });
  });

  private readonly onRecordingsChanged = () => {
    Log.debug(TAG, 'Recordings have changed - refreshing them');
    this.refreshRecordings();
  };

  private readonly onQuotaChanged = (quota: PVRQuota) => {
    Log.debug(TAG, 'Quota has changed - refreshing it');
    this.setState({quota});
  };

  private readonly refreshPVRQuota = () => {
    this.mwGetPVRQuota().then((quota: PVRQuota) => {
      this.setState({quota});
    });
  }

  public constructor(props: RecordingsScreenProps) {
    super(props);
    const status = RecordingStatus.Recorded;
    this.state = {
      quota: mw.pvr.getCachedPVRQuota(),
      isLoading: false,
      recordings: [],
      recordingsSorting: mw.pvr.defaultSorting(status),
      recordingsStatus: status,
      recordingsType: undefined, // by default, fetch both single and series recordings
      hasMoreRecordings: true,
      deleteConfirmationPopupVisible: false,
      selectionMenuActions: [DeleteSelectionMenuActionKey.OpenMenu],
      selectionMenuDisabledActions: [],
      selectionMenuSelectedActions: [],
      selectable: false,
      selection: new Selection<Recording>()
    };
  }

  private refreshRecordings = () => {
    Log.debug(TAG, 'Refreshing recordings using current sort order ' + this.state.recordingsSorting + ', status ' + this.state.recordingsStatus + ' and type ' + this.state.recordingsType);
    this.pager.clear();
  }

  private sortRecordings = (recordingsSorting?: RecordingsSorting) => {
    Log.debug(TAG, 'Sorting recording with sort order ' + (recordingsSorting ? recordingsSorting.type : 'none'));
    this.setState({recordingsSorting}, this.refreshRecordings);
  }

  private changeRecordingsStatus = (recordingsStatus: RecordingStatus) => {
    Log.debug(TAG, 'Changing recordings status to ' + recordingsStatus);
    this.setState({
      recordingsStatus,
      recordingsSorting: mw.pvr.defaultSorting(recordingsStatus)
    }, this.refreshRecordings);
  }

  private filterRecordings = (filter?: RecordingsFilter) => {
    Log.debug(TAG, 'Filtering recordings using filter ' + filter);
    // TODO (CL-1692) Implement
  }

  private onTilePress = (recording: Recording) => {
    if (isSingleRecording(recording)) {
      openMediaDetails(this.props.navigation, recording.event.id, recording.getType());
    } else if (isSeriesRecording(recording)) {
      this.props.navigation.push(
        AppRoutes.RecordingsFolder,
        {
          seriesRecording: recording,
          topLevelSeriesRecording: recording,
          status: this.state.recordingsStatus,
          onTilePress: (recording: Recording) => this.onTilePress(recording)
        }
      );
    }
  }

  private openMoreActions = (recording: Recording) => {
    Log.debug(TAG, 'Opening more actions for recording ' + recording);
    // TODO (CL-1691) Implement
  }

  public selectRecording = (recording: Recording, selected: boolean) => {
    Log.debug(TAG, (selected ? 'Selecting' : 'Deselecting') + ' recording with id ' + recording.id);
    this.setState(previousState => {
      const selection = previousState.selection.clone().select(recording, selected);
      return {
        selection,
        selectionMenuSelectedActions: hasAll(selection, previousState.recordings, previousState.hasMoreRecordings) ? [DeleteSelectionMenuActionKey.SelectAll] : [],
        selectionMenuDisabledActions: hasNone(selection, previousState.recordings, previousState.hasMoreRecordings) ? [DeleteSelectionMenuActionKey.Delete] : []
      };
    });
  }

  public componentDidMount() {
    this.pager.on(PagerEvent.loadingStarted, this.onLoadingStarted);
    this.pager.on(PagerEvent.dataChanged, this.onDataChanged);
    this.pager.on(PagerEvent.errorOccured, this.onErrorOccured);
    mw.pvr.on(PVREvent.PVRRecordingsChanged, this.onRecordingsChanged);
    mw.pvr.on(PVREvent.PVRQuotaChanged, this.onQuotaChanged);
  }

  public componentWillUnmount() {
    this.mwGetRecordings.dispose();
    this.mwGetPVRQuota.dispose();
    this.mwDeleteRecordings.dispose();
    this.onLoadingStarted.dispose();
    this.onDataChanged.dispose();
    this.onErrorOccured.dispose();
    this.pager.off(PagerEvent.loadingStarted, this.onLoadingStarted);
    this.pager.off(PagerEvent.dataChanged, this.onDataChanged);
    this.pager.off(PagerEvent.errorOccured, this.onErrorOccured);
    mw.pvr.off(PVREvent.PVRRecordingsChanged, this.onRecordingsChanged);
    mw.pvr.off(PVREvent.PVRQuotaChanged, this.onQuotaChanged);
  }

  public render() {
    const title = this.props.t('recordings.deleteConfirmationTitle', {count: this.state.selection.getSelectedSize()});
    const info = this.props.t('recordings.deleteConfirmationInfo', {count: this.state.selection.getSelectedSize()});

    const selectionMenu = (
      <DeleteSelectionMenu
        style={staticStyles.deleteSelectionMenu}
        actions={this.state.selectionMenuActions}
        disabledActions={this.state.selectionMenuDisabledActions}
        selectedActions={this.state.selectionMenuSelectedActions}
        onOpenMenuPress={this.onOpenMenuPress}
        onCancelPress={this.onSelectionCancelPress}
        onSelectAllPress={this.onSelectionSelectAllPress}
        onDeletePress={this.onSelectionDeletePress}
      />
    );

    const subscreenProps: RecordingSubscreenProps = Object.assign({},
      this.state,
      {
        requestRecordings: this.pager.requestNextPage,
        onTilePress: this.onTilePress,
        changeRecordingsStatus: this.changeRecordingsStatus,
        sortRecordings: this.sortRecordings,
        filterRecordings: this.filterRecordings,
        openMoreActions: this.openMoreActions,
        selectRecording: this.selectRecording,
        selectionMenu: selectionMenu,
        closeSelectionMenu: this.closeSelectionMode,
        refreshPVRQuota: this.refreshPVRQuota
      }, this.props);

    return (
      <>
        {isMobile ? <RecordingsScreenPiccolo {...subscreenProps} /> : <RecordingsScreenGrosso {...subscreenProps} />}
        <DeleteConfirmationPopup
          visible={this.state.deleteConfirmationPopupVisible}
          selection={this.state.selection}
          title={title}
          info={info}
          onCancel={this.closeDeleteConfirmationPopup}
          onConfirmation={this.deleteSelectedRecordings}
        />
      </>
    );
  }

  private onOpenMenuPress = () => {
    Log.debug(TAG, 'Showing selection menu');
    this.setState({
      selectable: true,
      selectionMenuActions: [DeleteSelectionMenuActionKey.Cancel, DeleteSelectionMenuActionKey.SelectAll, DeleteSelectionMenuActionKey.Delete],
      selectionMenuDisabledActions: [DeleteSelectionMenuActionKey.Delete]
    });
  }

  private onSelectionCancelPress = () => {
    Log.debug(TAG, 'Hiding selection menu');
    this.closeSelectionMode();
  }

  private onSelectionSelectAllPress = (selected: boolean) => {
    if (this.state.recordings.length === 0) {
      Log.debug(TAG, 'There are no recordings - ignoring request to select all of them');
      return;
    }
    Log.debug(TAG, (selected ? 'Selecting' : 'Deselecting') + ' all recordings');
    this.setState(previousState => ({
      selection: previousState.selection.clone().selectAll(!selected),
      selectionMenuDisabledActions: selected ? [DeleteSelectionMenuActionKey.Delete] : [],
      selectionMenuSelectedActions: !selected ? [DeleteSelectionMenuActionKey.SelectAll] : []
    }));
  }

  private onSelectionDeletePress = () => {
    Log.debug(TAG, 'Showing delete confirmation popup');
    this.setState(previousState => ({
      selection: previousState.selection.clone(),
      deleteConfirmationPopupVisible: true
    }));
  };

  private closeDeleteConfirmationPopup = () => {
    Log.debug(TAG, 'Closing delete confirmation popup');
    this.setState({
      deleteConfirmationPopupVisible: false
    });
  }

  private closeSelectionMode = () => {
    Log.debug(TAG, 'Closing delete confirmation popup, closing the selection menu and clearing the selection');
    this.setState(() => ({
      deleteConfirmationPopupVisible: false,
      selectable: false,
      selection: new Selection<Recording>(),
      selectionMenuActions: [DeleteSelectionMenuActionKey.OpenMenu],
      selectionMenuDisabledActions: [],
      selectionMenuSelectedActions: []
    }));
  }

  private deleteSelectedRecordings = async (selection?: Selection<Recording>) => {
    if (!selection) {
      return;
    }
    this.closeSelectionMode();
    try {
      const selectedRecordings = await this.getSelectedRecordings(selection);
      Log.debug(TAG, 'Deleting ' + selectedRecordings.length + ' selected recordings');
      await this.mwDeleteRecordings(selectedRecordings);
      // TODO (CL-1541) After implementing paging make sure to reset current page number to the first one
    } catch (error) {
      Log.error(TAG, 'Failed to delete selected recording. Reason: ', error);
    }
  }

  private async getSelectedRecordings(selection: Selection<Recording>) {
    const availableRecordings = selection.areAllSelected()
      ? await this.mwGetRecordings({
        status: this.state.recordingsStatus ?? RecordingStatus.Recorded,
        type: this.state.recordingsType
        // TODO (CL-1692) After implementing filtering make sure to add filtering-related props here to ensure that only recordings from current view will be removed
      })
      : [];
    const selectedRecordings = selection.areAllSelected()
      ? selection.filterDeselectedItems(availableRecordings)
      : selection.getSelectedItems();
    Log.debug(TAG, 'Found ' + selectedRecordings.length + ' selected recordings');
    return selectedRecordings;
  }
}

export default withTranslation()(RecordingsScreen);
