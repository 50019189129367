import {OrderStatus} from 'mw/api/Metadata';

export const PAGE_SIZE = 9;

export enum ButtonType {
  Increment = 'increment',
  Decrement = 'decrement',
  First = 'first',
  Last = 'last',
  IncrementExtra = 'incrementExtra',
  DecrementExtra = 'decrementExtra'
}

export const labelTitles = {
  [OrderStatus.Success]: 'filter.success',
  [OrderStatus.Failed]: 'filter.failed',
  [OrderStatus.Canceled]: 'filter.canceled',
  [OrderStatus.PaymentFailed]: 'filter.paymentFailed',
  [OrderStatus.Pending]: 'filter.pending',
  [OrderStatus.Unpaid]: 'filter.unpaid'
};

export enum SettingsOrdersFilterKey {
  All = 'ALL',
  ProcessingPayment = 'processingPayment',
  Pending = 'pending',
  Success = 'success',
  Failed = 'failed',
  Canceled = 'canceled',
  PaymentFailed = 'paymentFailed',
  Unpaid = 'unpaid'
}

type SettingsOrderField = 'PENDING_PAYMENT' | 'PROCESSING_PAYMENT' | 'PAYMENT_FAILED' | 'PENDING_DELIVERY' | 'DELIVERY_FAILED' | 'CANCELED' | 'DELIVERED';

type SettingsOrdersFilterOptionsKey = {[key in SettingsOrdersFilterValue]: string}

export type SettingsOrdersFilterValue = SettingsOrderField | SettingsOrdersFilterKey.All;

export const SettingsOrdersFilterOptionsKeys: SettingsOrdersFilterOptionsKey = {
  [SettingsOrdersFilterKey.All]: 'filter.all',
  DELIVERED: 'filter.success',
  DELIVERY_FAILED: 'filter.failed',
  CANCELED: 'filter.canceled',
  PAYMENT_FAILED: 'filter.paymentFailed',
  PENDING_DELIVERY: 'filter.pending',
  PROCESSING_PAYMENT: 'filter.processingPayment',
  PENDING_PAYMENT: 'filter.unpaid'
};
