import {Log} from 'common/Log';

import {Error, ErrorType} from 'mw/api/Error';
import {Content, MediaType} from 'mw/api/Metadata';
import {SeaChangeDRMAdapter, PropertiesParams} from 'mw/bo-proxy/drm/SeaChangeDRMAdapter';
import {CreateSessionParams} from 'mw/playback/sessions/DRMSessionManager';

export class SeaChangeAdr8DrmAdapter extends SeaChangeDRMAdapter {
  protected TAG = 'SeaChangeAdr8DrmAdapter';

  protected getParams(params: CreateSessionParams): PropertiesParams {
    switch (params.media.getType()) {
      case MediaType.Event:
      case MediaType.Recording:
      case MediaType.Title: {
        const asset = params.session.assets[0];
        if (!asset.ticket) {
          Log.debug(this.TAG, 'Failed to prepare drm properties: no ticket');
          throw new Error(ErrorType.DRMNotRequired);
        }
        const content: Content = params.playable as Content;
        if (!content.getId()) {
          Log.error(this.TAG, 'Failed to prepare drm properties: no Id');
          throw new Error(ErrorType.NotSupported);
        }
        return {
          streamId: content.getId(),
          drmTicket: asset.ticket
        };
      }

      default:
        throw new Error(ErrorType.NotSupported);
    }
  }
}
