import {makeArray} from 'common/utils';

import {Content, ExternalLink} from 'mw/api/Metadata';
import {getAlias, getCustomProperty} from 'mw/bo-proxy/bo/traxis/mappers/MappersHelper';
import {convertJSONDateToJSDate} from 'mw/common/utils';
import {ContentObject} from 'mw/metadata/ContentObject';
import {ExternalContentObject} from 'mw/metadata/ExternalContentObject';

import {checkEntitlement} from './MappersHelper';
import {ProductMapper} from './ProductMapper';

const deeplinkingURLHref = 'urn:schange:metadata:cs:CustomContentPropertyCS:2020:DeeplinkUrl';
const deeplinkingProviderHref = 'urn:schange:metadata:cs:CustomContentPropertyCS:2020:ExternalContentProvider';
const reporterHref = 'urn:schange:metadata:cs:CustomContentPropertyCS:2020:managedBy';

const vodBackOfficeId = 'VodBackOfficeId';
const ingestId = 'IngestId';

export class ContentMapper {
  private static fromJSON(json: any): Content {
    let externalLink: ExternalLink | undefined;
    const customProperties = makeArray(json.CustomProperties?.CustomProperty);
    if (customProperties.length) {
      const url = getCustomProperty(customProperties, deeplinkingURLHref);
      const provider = getCustomProperty(customProperties, deeplinkingProviderHref);
      if (url && provider) {
        externalLink = {
          url,
          provider
        };
      }
    }

    const content = new (externalLink ? ExternalContentObject : ContentObject)(json.id);
    content.entitlementEnd = json.EntitlementEnd && convertJSONDateToJSDate(json.EntitlementEnd);
    content.firstAvailability = json.FirstAvailability && convertJSONDateToJSDate(json.FirstAvailability);
    if (typeof json.DurationInSeconds !== 'undefined') {
      content.duration = json.DurationInSeconds;
    }
    if (json.Aliases) {
      content.externalIds.playbackId = getAlias(json, vodBackOfficeId);
      content.externalIds.ingestId = getAlias(json, ingestId);
    }
    if (customProperties.length) {
      content.reporter = getCustomProperty(customProperties, reporterHref);
    }

    content.externalLink = externalLink;

    content.entitlementState = checkEntitlement(json.EntitlementState);
    content.lastAvailability = json.LastAvailability && convertJSONDateToJSDate(json.LastAvailability);
    content.products = json.Products && ProductMapper.fromJSONArray(json.Products.Product) || [];

    content.recommendedPlaybackOffset = json.RecommendedPlaybackNPT || 0;
    content.isAdult = !!json.IsAdult;

    return content;
  }

  public static fromJSONArray(jsonArray: any[]): Content[] {
    return jsonArray.map(ContentMapper.fromJSON);
  }
}
