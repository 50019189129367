import {createStyles} from 'common-styles';
import React, {useState, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {dimensions, getValue} from 'common/constants';

import {IconType} from 'components/Icon';
import InfoPopup from 'components/InfoPopup';

const styles = createStyles({
  subtitle: {
    marginBottom: getValue({mobile: dimensions.margins.large, defaultValue: dimensions.margins.xxxLarge})
  }
});

export type PopupError = {
  title?: string;
  subtitle?: string;
  message: string | string[];
  icon?: IconType;
}

export type ErrorPopupProps = {
  error: PopupError | null;
  buttonLabel?: string;
  onClose?: () => void;
}

const ErrorPopup: React.FC<ErrorPopupProps> = props => {
  const {t} = useTranslation();
  const {error, buttonLabel, onClose} = props;
  return (
    <InfoPopup
      visible={!!error}
      title={error?.title ?? t('common.error')}
      subtitle={error?.subtitle}
      subtitleStyle={styles.subtitle}
      message={error?.message || ''}
      icon={error?.icon}
      buttonLabel={buttonLabel}
      onClose={onClose}
    />
  );
};

export function useErrorPopup() {
  const [error, setError] = useState<PopupError | null>(null);

  const showError = useCallback((error: PopupError | string) => {
    if (typeof error === 'string') {
      setError({message: error});
    } else {
      setError(error);
    }
  }, []);

  const onCloseErrorPopup = useCallback(() => {
    setError(null);
  }, []);

  return {error, showError, onCloseErrorPopup};
}

export default React.memo(ErrorPopup);
