import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {View, ScrollView, ActivityIndicator} from 'react-native';

import {RADIUS, dimensions, isBigScreen, isTablet} from 'common/constants';
import {getMetadataFromMedia, getShortestSynopsis, getSportTeamsInfo} from 'common/HelperFunctions';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Event, PictureMode, PictureType} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import FocusParent from 'components/FocusParent';
import MediaPicture from 'components/MediaPicture';
import {Modal} from 'components/Modal';
import NitroxButton, {NitroxButtonTheme} from 'components/NitroxButton';
import NitroxText from 'components/NitroxText';
import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';
import {useFunction, useScreenInfo, getScreenInfo} from 'hooks/Hooks';

import {formatEventInfo, EpgMenuAction} from './EpgMenuPopup.shared';

export type EpgTilePopupProps = {
  event: Event;
  actions: EpgMenuAction[];
  loading?: boolean;
  onModalClose?: () => void;
  visible?: boolean;
};

const maxButtonLabelLetters = 12;
const smallResolutionWidth = getScreenInfo().size.width - 2 * dimensions.margins.xsmall;
const posterSize = dimensions.pictures.epgPopup;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  modalContainer: {
    alignItems: 'center'
  },
  posterStyle: {
    backgroundColor: colors.tile.poster.placeholder.background
  },
  container: {
    flexDirection: 'row',
    overflow: 'hidden',
    height: posterSize.height,
    borderRadius: RADIUS,
    backgroundColor: colors.epgScreen.grid.detailsPopup.background
  },
  contentContainer: {
    flex: 1,
    justifyContent: 'space-between'
  },
  contentContainerVerticalPadding: {
    paddingVertical: dimensions.margins.medium
  },
  metadataContainer: {
    paddingHorizontal: dimensions.margins.large
  },
  scrollViewContainer: {
    alignItems: 'flex-end',
    paddingLeft: dimensions.margins.large,
    paddingRight: dimensions.margins.small,
    marginBottom: dimensions.margins.xsmall
  },
  titleStyle: {
    color: colors.epgScreen.grid.detailsPopup.text,
    paddingTop: isBigScreen ? dimensions.margins.xLarge : 0
  },
  secondSubtitleStyle: {
    color: colors.epgScreen.grid.detailsPopup.text,
    paddingTop: isBigScreen ? dimensions.margins.xsmall : 0
  },
  subtitleStyle: {
    color: colors.epgScreen.grid.detailsPopup.subtitle,
    marginBottom: dimensions.margins.xsmall
  },
  synopsisStyle: {
    color: colors.epgScreen.grid.detailsPopup.text,
    marginBottom: dimensions.margins.small,
    paddingTop: isBigScreen ? dimensions.margins.large : 0
  },
  buttonStyle: {
    marginRight: dimensions.margins.small
  },
  iconStyle: {
    height: dimensions.epg.detailsPopup.button.iconSize,
    color: colors.epgScreen.grid.detailsPopup.icon
  }
}));

const EpgDetailsPopup: React.FC<EpgTilePopupProps> = props => {
  const {event, onModalClose, actions, loading, visible} = props;
  const styles = stylesUpdater.getStyles();
  const {isMediaBlocked} = useParentalControl();
  const isBlocked = isMediaBlocked(event);

  const poster = useMemo(() => {
    const uri = mw.catalog.getPictureUrl(event, PictureType.Tile, posterSize.width, posterSize.height, PictureMode.BOX);
    return uri ? {uri} : null;
  }, [event]);
  const metadata = useMemo(() => getMetadataFromMedia(event), [event]);
  const {orientation} = useScreenInfo();

  const sportTeams = useMemo(() => getSportTeamsInfo(event), [event]);

  const popupWidth = useMemo(() => (
    isBigScreen
      ? dimensions.epg.detailsPopup.width.bigScreen
      : isTablet && orientation.isLandscape
        ? Math.min(dimensions.epg.detailsPopup.width.tabletLandscape, smallResolutionWidth)
        : Math.min(dimensions.epg.detailsPopup.width.tabletPortrait, smallResolutionWidth)
  ), [orientation.isLandscape]);

  const contentContainerStyle = useMemo(() => {
    return [styles.contentContainer, !sportTeams && styles.contentContainerVerticalPadding];
  }, [sportTeams, styles]);

  const getButtonText = (fullText: string) => {
    if (fullText.length < maxButtonLabelLetters) return fullText;
    const buttonText = fullText.substring(0, maxButtonLabelLetters).trim();
    return fullText.length === maxButtonLabelLetters ? buttonText : `${buttonText}...`;
  };

  const renderAction = useFunction((action: EpgMenuAction, index: number) => (
    <NitroxButton
      key={action.key}
      hasTvPreferredFocus={isBigScreen && index === 0}
      style={styles.buttonStyle}
      theme={NitroxButtonTheme.Icon}
      themeFocused={NitroxButtonTheme.Primary}
      onPress={action.onPress}
      border
      text={isBigScreen ? getButtonText(action.text) : undefined}
      textStyle={{display: 'none'}}
      textStyleFocused={{display: 'flex'}}
      icon={action.icon && {type: action.icon, size: styles.iconStyle.height, ...isTablet && {color: styles.iconStyle.color}}}
    />
  ));

  return (
    <Modal
      visible={!!event && visible}
      onClose={onModalClose}
      style={styles.modalContainer}
      testID='modal_epg_details'
    >
      <View style={[styles.container, {width: popupWidth}]}>
        <View style={[posterSize, styles.posterStyle]}>
          <MediaPicture source={poster} mediaType={event.getType()} isBlocked={isBlocked} />
        </View>
        <View style={contentContainerStyle}>
          <View style={styles.metadataContainer}>
            <NitroxText textType={'title4'} style={styles.titleStyle} numberOfLines={1} handleEllipsize testID='title'>{event.name}</NitroxText>
            {!!sportTeams && <NitroxText textType='sportTeams' style={styles.secondSubtitleStyle} numberOfLines={1} handleEllipsize>{sportTeams}</NitroxText>}
            {!isBlocked && (
              <>
                <NitroxText textType={'body1'} style={styles.subtitleStyle} numberOfLines={1} testID='air_time'>{formatEventInfo(event)}</NitroxText>
                <NitroxText textType={'body1'} style={styles.synopsisStyle} numberOfLines={2} testID='description'>{getShortestSynopsis(metadata)}</NitroxText>
              </>
            )}
          </View>
          <FocusParent>
            {loading ? <ActivityIndicator /> : (
              <ScrollView
                horizontal={true}
                contentContainerStyle={styles.scrollViewContainer}
                showsHorizontalScrollIndicator={false}
                alwaysBounceVertical={false}
              >
                {actions.map(renderAction)}
              </ScrollView>
            )}
          </FocusParent>
        </View>
      </View>
    </Modal>
  );
};

export default React.memo(EpgDetailsPopup);
