import React, {useCallback} from 'react';
import {AppStateStatus} from 'react-native';

import {getMillisecondsToEndOfCurrentEvent} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {Channel} from 'mw/api/Metadata';

import {UseAppState} from 'hooks/HookComponents';
import {Tune} from 'screens/tv/TvScreenHelperTypes';

const TAG = 'PlaybackRetuneScheduler';

type ScheduleRequest = {
  tuneTimestamp: number;
  channel: Channel;
  tune: Tune;
}

export class PlaybackRetuneScheduler {
  private static instance: PlaybackRetuneScheduler = new PlaybackRetuneScheduler();

  private retuneTimeout: number | null = null;
  private lastScheduleRequest: ScheduleRequest | null = null;

  public cancelScheduledRetune(clearSavedRequest = true) {
    if (this.retuneTimeout) {
      Log.debug(TAG, `Clearing timeout ${this.retuneTimeout}`);
      clearTimeout(this.retuneTimeout);
      this.retuneTimeout = null;
    }
    if (clearSavedRequest) {
      this.lastScheduleRequest = null;
    }
  }

  private scheduleRetune(channel: Channel, tune: Tune, timeout: number) {
    this.cancelScheduledRetune();
    this.retuneTimeout = setTimeout(tune, timeout);
    Log.debug(TAG, `Setting timeout ${this.retuneTimeout} at ${new Date()} to retune ${channel} in ${timeout / 1000} sec.`);
  }

  public scheduleRetuneOnNextEvent(channel: Channel, tune: Tune) {
    getMillisecondsToEndOfCurrentEvent(channel).then(timeToCurrentEventEnd => {
      if (timeToCurrentEventEnd) {
        this.scheduleRetune(channel, tune, timeToCurrentEventEnd);
        this.lastScheduleRequest = {tuneTimestamp: Date.now() + timeToCurrentEventEnd, channel, tune};
      }
    });
  }

  public scheduleRetuneOnAppActivate() {
    if (this.lastScheduleRequest) {
      if (this.lastScheduleRequest.tuneTimestamp < Date.now()) {
        // Event is already in the past, so try to tune immediately
        this.scheduleRetune(this.lastScheduleRequest.channel, this.lastScheduleRequest.tune, 0);
      } else {
        this.scheduleRetuneOnNextEvent(this.lastScheduleRequest.channel, this.lastScheduleRequest.tune);
      }
    }
  }

  public static getInstance() {
    return this.instance;
  }
}

export function AppStatePlaybackRetuneScheduler() {
  const onAppStateChange = useCallback((appState: AppStateStatus) => {
    if (appState !== 'active') {
      PlaybackRetuneScheduler.getInstance().cancelScheduledRetune(false);
    } else {
      PlaybackRetuneScheduler.getInstance().scheduleRetuneOnAppActivate();
    }
  }, []);

  return <UseAppState onAppStateChange={onAppStateChange} />;
}
