import {createStyles} from 'common-styles';
import React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {ViewStyle, StyleProp} from 'react-native';

import {dimensions, isBigScreen} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {constColors} from 'common-styles/variables/base-colors';
import {BaseColors} from 'common-styles/variables/base-colors';

import {IconType} from 'components/Icon';
import NitroxButton from 'components/NitroxButton';
import {SelectionMenu, SelectionMenuAction, SelectionActionProps} from 'components/SelectionMenu';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  selectionMenuButton: {
    marginStart: dimensions.margins.xxLarge
  },
  selectAllTextStyle: {
    color: colors.button.primary.text.focused
  },
  selectAllContainerStyle: {
    backgroundColor: colors.button.primary.background.focused
  },
  deleteContainerStyle: {
    backgroundColor: colors.button.destructive.background.focused
  },
  deleteTextStyle: {
    color: colors.button.destructive.text.focused
  },
  deleteIconColor: {
    color: colors.button.destructive.text.focused
  }
}));

export enum DeleteSelectionMenuActionKey {
  OpenMenu = 'OpenMenu',
  Cancel = 'Cancel',
  SelectAll = 'SelectAll',
  Delete = 'Delete'
}

export type DeleteSelectionMenuProps = {
  style?: StyleProp<ViewStyle>;
  actions: DeleteSelectionMenuActionKey[];
  disabledActions?: DeleteSelectionMenuActionKey[];
  selectedActions?: DeleteSelectionMenuActionKey[];
  onOpenMenuPress?: (selected: boolean) => void;
  onCancelPress?: (selected: boolean) => void;
  onSelectAllPress?: (selected: boolean) => void;
  onDeletePress?: (selected: boolean) => void;
} & WithTranslation;

class DeleteSelectionMenu extends React.Component<DeleteSelectionMenuProps> {
  private renderBigScreenButton = (key: string, props: SelectionActionProps, onPress: () => void) => {
    return <SelectionMenuBigScreenButton key={key} params={props} onPress={onPress} />;
  }

  private openMenuAction: SelectionMenuAction = {
    key: DeleteSelectionMenuActionKey.OpenMenu,
    props: {
      text: isBigScreen ? this.props.t('common.delete') : undefined,
      icon: {
        type: IconType.CircledTrash,
        size: dimensions.icon.small
      },
      containerStyle: {
        backgroundColor: constColors.transparent,
        borderRadius: 0,
        height: dimensions.icon.small
      }
    },
    onPress: (selected: boolean) => {
      this.props.onOpenMenuPress?.(selected);
    },
    renderCustomComponent: isBigScreen ? this.renderBigScreenButton : undefined
  };

  private cancelAction: SelectionMenuAction = {
    key: DeleteSelectionMenuActionKey.Cancel,
    props: {
      text: this.props.t('common.cancel')
    },
    onPress: (selected: boolean) => {
      this.props.onCancelPress?.(selected);
    },
    renderCustomComponent: isBigScreen ? this.renderBigScreenButton : undefined
  };

  private selectAllAction: SelectionMenuAction = {
    key: DeleteSelectionMenuActionKey.SelectAll,
    props: {
      text: this.props.t('common.selectAll')
    },
    selectedProps: {
      text: this.props.t('common.deselectAll')
    },
    onPress: (selected: boolean) => {
      this.props.onSelectAllPress?.(selected);
    },
    renderCustomComponent: isBigScreen ? this.renderBigScreenButton : undefined
  }

  private deleteAction: SelectionMenuAction = {
    key: DeleteSelectionMenuActionKey.Delete,
    props: {
      text: this.props.t(isBigScreen ? 'recordings.removeSelected' : 'common.delete'),
      icon: {
        type: IconType.Trash
      }
    },
    onPress: (selected: boolean) => {
      this.props.onDeletePress?.(selected);
    },
    renderCustomComponent: isBigScreen ? this.renderBigScreenButton : undefined
  };

  private mapActions(): SelectionMenuAction[] {
    const styles = stylesUpdater.getStyles();
    return this.props.actions.map((action): SelectionMenuAction => {
      let result;
      switch (action) {
        case DeleteSelectionMenuActionKey.OpenMenu:
          return this.openMenuAction;
        case DeleteSelectionMenuActionKey.Cancel:
          return this.cancelAction;
        case DeleteSelectionMenuActionKey.Delete:
          result = this.deleteAction;
          result.props.textStyle = styles.deleteTextStyle;
          result.props.containerStyle = styles.deleteContainerStyle;
          if (result.props.icon) {
            result.props.icon.color = styles.deleteIconColor.color;
          }
          break;
        case DeleteSelectionMenuActionKey.SelectAll:
          result = this.selectAllAction;
          result.props.textStyle = styles.selectAllTextStyle;
          result.props.containerStyle = styles.selectAllContainerStyle;
          break;
        default:
          throw new Error('Unsupported action ' + action);
      }
      return result;
    });
  }

  public render() {
    return (
      <SelectionMenu
        actions={this.mapActions()}
        disabledActions={this.props.disabledActions}
        selectedActions={this.props.selectedActions}
        style={this.props.style}
      />
    );
  }
}

export default withTranslation()(DeleteSelectionMenu);

type SelectionMenuBigScreenButtonProps = {
  params: SelectionActionProps;
  onPress: () => void;
}

const SelectionMenuBigScreenButton: React.FC<SelectionMenuBigScreenButtonProps> = ({params, onPress}) => {
  const styles = stylesUpdater.getStyles();
  return (
    <NitroxButton
      border
      text={params.text}
      onPress={onPress}
      style={styles.selectionMenuButton}
    />
  );
};
