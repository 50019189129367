import React, {useEffect} from 'react';

import {Log} from 'common/Log';

const TAG = 'UnknownRoute';

const UnknownRoute: React.FC = () => {
  useEffect(() => {
    Log.warn(TAG, `Hash path ${window.location.hash} not recognized, redirecting.`);
    window.location.hash = '';
  }, []);
  return null;
};

export default UnknownRoute;
