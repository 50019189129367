import React, {useMemo, useCallback} from 'react';
import {StyleProp, ViewStyle, ViewProps, View} from 'react-native';

import {useToggle, useChangeEffect} from 'hooks/Hooks';

const MouseAwareView: React.FC<{
  hoverStyle?: StyleProp<ViewStyle>;
  onClick?: () => void;
  onHoverChange?: (hovered: boolean) => void;
} & ViewProps> = ({
  hoverStyle,
  style,
  onClick,
  onHoverChange,
  ...viewProps
}) => {
  const [hovered, {on: onMouseEnter, off: onMouseLeave}] = useToggle(false);
  const styles = useMemo(() => [style, hovered && hoverStyle], [hoverStyle, hovered, style]);
  const handleMouseEnter = useCallback((event?: any) => {
    if (event?.movementX === 0 && event?.movementY === 0) {
      return;
    }
    onMouseEnter();
  }, [onMouseEnter]);

  useChangeEffect(() => {
    onHoverChange?.(hovered);
  }, [hovered], [onHoverChange]);

  return (
    <View
      {...viewProps}
      style={styles}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onMouseMove={handleMouseEnter}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onMouseLeave={onMouseLeave}

      onClick={onClick}
    />
  );
};

export default MouseAwareView;
