import {AppRegistry} from 'react-native';
import 'core-js/stable';
import 'whatwg-fetch';

import {name as appName} from 'apps/default/app.json';

import {WebApp} from './WebApp';

AppRegistry.registerComponent(appName, () => WebApp);
AppRegistry.runApplication(appName, {
  rootTag: document.getElementById('root')
});
