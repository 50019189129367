export class Device {
  public id: string;
  public name: string;
  public isCustomerManaged: boolean;
  public backOfficeId?: string;

  public constructor(id: string, name: string, isCustomerManaged?: boolean, backOfficeId?: string) {
    this.id = id;
    this.name = name;
    this.isCustomerManaged = typeof isCustomerManaged !== 'undefined' ? isCustomerManaged : true;
    this.backOfficeId = backOfficeId;
  }
}
