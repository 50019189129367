import React from 'react';

import {isBigScreen} from 'common/constants';

import WizardParentalControlRatingsPageGrosso from './WizardParentalControlRatingsPage.grosso';
import WizardParentalControlRatingsPagePiccolo from './WizardParentalControlRatingsPage.piccolo';
import {WizardParentalControlRatingsPageProps} from './WizardParentalControlRatingsPage.shared';

const WizardParentalControlRatingsPage: React.FC<WizardParentalControlRatingsPageProps> = props => {
  if (isBigScreen) {
    return <WizardParentalControlRatingsPageGrosso {...props} />;
  }
  return <WizardParentalControlRatingsPagePiccolo {...props} />;
};

export default WizardParentalControlRatingsPage;
