import {EntitlementState, Offer, Product, ProductType} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

export class ProductObject implements Product {

  public readonly id: string;
  public isAvailable = false;
  public entitlementState?: EntitlementState;
  public name = '';
  public offers: Offer[] = [];
  public type: ProductType = ProductType.Unknown;
  public relationStart?: Date;
  public relationEnd?: Date;
  public filterId?: string;
  public duration?: number; // value in ms
  public description?: string;
  public isSingle?: boolean;
  public isAvailableToBuy = false;
  public isAvailableToRent = false;

  public constructor(id: string) {
    this.id = id;
  }

  public isAllowed(): boolean {
    return this.entitlementState != null ? this.entitlementState === EntitlementState.Entitled : this.checkEntitlement();
  }

  public get isEntitled(): boolean {
    return this.entitlementState === EntitlementState.Entitled;
  }

  private checkEntitlement(): boolean {
    const now = new Date();
    const entitledProducts = mw.catalog.entitledProducts.products;
    return entitledProducts.get(this.id) &&
      this.relationStart && this.relationStart < now &&
      this.relationEnd && this.relationEnd > now || false;
  }
}
