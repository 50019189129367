// this file is just MediaOverlay <-> MediaPlayer require cycle breaker
import {RefObject} from 'react';
import React from 'react';

import {FocusableComponent} from 'components/focusManager/FocusManagerTypes';

export type MediaPlayerFocusableElements = {
  mediaDetails?: RefObject<FocusableComponent>;
}
export const mediaPlayerFocusMap: MediaPlayerFocusableElements = {
  mediaDetails: React.createRef<FocusableComponent>()
};
export const MediaPlayerFocusContext = React.createContext<MediaPlayerFocusableElements>({});
