import {findDOMNode} from 'react-dom';

import {isWeb} from 'common/constants';

import {FocusNode, FocusNodeProps, NodeGeometry, isFocusParentNode} from './FocusManagerTypes';
import {getGeometry} from './geometry';

const TAG = 'DebuggableFocusNodesMap';

export class DebuggableFocusNodesMap extends Map<FocusNode, FocusNodeProps> {
  private static logTimeout = 400;
  private logTimeoutId = 0;

  public clear() {
    this.scheduleLog();
    return super.clear();
  }

  public delete(key: FocusNode) {
    this.scheduleLog();
    return super.delete(key);
  }

  public set(key: FocusNode, value: FocusNodeProps) {
    this.scheduleLog();
    return super.set(key, value);
  }

  private async logNodes() {
    const records = Array.from(this);
    const geometries = await Promise.all(records.map(([node, props]) => props.geometry || getGeometry(node)));
    console.groupCollapsed(`${TAG}: FocusNodes`);
    console.table(
      records.map(([node, props], index) => ({
        node: isFocusParentNode(node)
          ? node.toString()
          // TODO: Move web specific code to the web implementation of FocusManager
          // eslint-disable-next-line react/no-find-dom-node
          : node.current && `${node.current.props.testID}` + (isWeb ? ': ' + findDOMNode(node.current)?.textContent : ''),
        geometry: DebuggableFocusNodesMap.geometryToString(geometries[index]),
        priority: props.priority
      }))
    );
    console.groupEnd();
  }

  private scheduleLog() {
    if (this.logTimeoutId) {
      clearTimeout(this.logTimeoutId);
    }
    this.logTimeoutId = setTimeout(
      () => {
        this.logTimeoutId = 0;
        this.logNodes();
      },
      DebuggableFocusNodesMap.logTimeout
    );
  }

  private static geometryToString(geometry: NodeGeometry | null): string {
    if (!geometry) {
      return 'null';
    }
    const {x, y, width, height} = geometry;
    return `(${x},${y}) ${width}x${height}`;
  }
}
