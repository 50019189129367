import React from 'react';

import {WebRouter} from './router/WebRouter';
import {WebTAFocusReporter} from './taRelated/WebTAFocusReporter';

export const WebApp: React.FC = () => {
  return (
    <>
      <WebTAFocusReporter />
      <WebRouter />
    </>
  );
};
