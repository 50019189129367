import {Log} from 'common/Log';

import {EASAlert} from 'mw/api/EASMetadata';
import {Audio, isChannel} from 'mw/api/Metadata';
import {PlayerEvent} from 'mw/api/PlayerEvent';
import {mw} from 'mw/MW';
import {PlaybackResponse} from 'mw/playback/types/PlaybackParameters';

import {PlayerViews} from 'components/player/PlayerView';

const TAG = 'EASPlayerManager';

export default class EASPlayerManager {
  private static instance: EASPlayerManager = new EASPlayerManager();
  public static getInstance(): EASPlayerManager {
    return this.instance;
  }

  private audioItems: Audio[][] = [];
  private playing = false;

  private clearAudioItems() {
    this.audioItems = [];
  }

  private setAudioItems(easAlert: EASAlert) {
    this.clearAudioItems();
    Log.trace(TAG, 'Got new EAS alert', easAlert.id);
    if (easAlert.preAttentionSignal) {
      this.audioItems.push([easAlert.preAttentionSignal]);
    }
    easAlert.infos.forEach((easInfo) => {
      if (easInfo.broadcastAudio.length) {
        this.audioItems.push(easInfo.broadcastAudio);
      }
    });
    if (easAlert.postAttentionSignal) {
      this.audioItems.push([easAlert.postAttentionSignal]);
    }
    Log.debug(TAG, `Found ${this.audioItems.length} audio items to be played for the incoming EAS alert ${easAlert.id}`);
  }

  private startNextAudioItem = async () => {
    const nextAudioItem = this.audioItems.shift();
    if (!nextAudioItem) {
      Log.debug(TAG, 'There are no more audio items to be played - playback of a EAS message is done');
      await this.stop(); // restore main playback
      return;
    }
    // for each audio item there are two tracks - main one and one that should be used as a fallback in case playback fails
    while (true) {
      const nextAudioTrack = nextAudioItem.shift();
      if (!nextAudioTrack) {
        Log.debug(TAG, 'There are no more audio tracks to be played');
        return;
      }
      Log.debug(TAG, `Starting playback of an audio track ${nextAudioTrack}`);
      try {
        await mw.players.audio.start(PlayerViews.None, nextAudioTrack, {playRate: 1});
        Log.debug(TAG, `Playback of an audio track ${nextAudioTrack} started`);
        return;
      } catch (error) {
        Log.warn(TAG, `Failed to start playback of an audio track ${nextAudioTrack}. Got error`, error);
      }
    }
  }

  private restoreMainPlayback: (() => Promise<PlaybackResponse | void>) = Promise.resolve;

  private suspendMainPlayback(): Promise<PlaybackResponse | void> {
    const currentMedia = mw.players.main.getCurrentMedia();
    if (!currentMedia) {
      return Promise.resolve();
    }
    // for LiveTV playback mute the audio
    if (isChannel(currentMedia)) {
      this.restoreMainPlayback = () => {
        Log.debug(TAG, 'Unmuting LiveTV playback');
        this.restoreMainPlayback = Promise.resolve;
        return mw.players.main.setAudioMuted(false);
      };
      Log.debug(TAG, 'Muting current LiveTV playback');
      return mw.players.main.setAudioMuted(true);
    }
    // for other types of playback pause them
    const playRate = mw.players.main.getParameters().playRate;
    this.restoreMainPlayback = () => {
      this.restoreMainPlayback = Promise.resolve;
      Log.debug(TAG, `Restoring previous play rate ${playRate}`);
      return mw.players.main.changeParameters({playRate});
    };
    Log.debug(TAG, 'Pause current playback');
    return mw.players.main.changeParameters({
      playRate: 0
    });
  }

  private stopAlertPlayback(): Promise<void> {
    this.playing = false;
    mw.players.audio.off(PlayerEvent.EndOfContent, this.startNextAudioItem);
    this.clearAudioItems();
    return mw.players.audio.stop();
  }

  private startAlertPlayback(easAlert: EASAlert): Promise<void> {
    this.playing = true;
    mw.players.audio.on(PlayerEvent.EndOfContent, this.startNextAudioItem);
    this.setAudioItems(easAlert);
    return this.startNextAudioItem();
  }

  public start(easAlert: EASAlert): Promise<PlaybackResponse | void> {
    return this.suspendMainPlayback()
      .finally(() => this.startAlertPlayback(easAlert));
  }

  public stop(): Promise<PlaybackResponse | void> {
    return this.playing
      ? this.stopAlertPlayback()
        .finally(this.restoreMainPlayback)
      : Promise.resolve();
  }
}
