import {Content, Event, MediaType, Playable, Recording, RecordingErrorCode, RecordingStatus, RecordingType, Series} from 'mw/api/Metadata';
import {isPCDisabled} from 'mw/common/ParentalControl';
import {mw} from 'mw/MW';

import {MediaObject} from './MediaObject';

export class RecordingObject extends MediaObject implements Recording {
  public contents: Content[] = [];
  public duration = 0;
  public seasonsCount = 0;
  public episodesCount = 0;
  public event?: Event;
  public errorCode?: RecordingErrorCode;
  public seedEventId?: string;
  public seedSeriesNumber?: number;
  public series?: Series;
  public recordingType: RecordingType;
  public profileId?: string;
  public recordings?: Recording[];
  public guardTimeStart = 0;
  public guardTimeEnd = 0;

  public status?: RecordingStatus;
  public channelId?: string;
  public parentRecordingId?: string;

  public constructor(id: string, recordingType: RecordingType, name: string, parentRecordingId?: string) {
    super(id, name);
    this.recordingType = recordingType;
    this.parentRecordingId = parentRecordingId;
  }

  public getType(): MediaType {
    return MediaType.Recording;
  }

  public getPlayable(): Playable | null {
    return this.contents.find((playable: Content) => playable.isAllowed()) || null;
  }

  public isBlocked(): boolean {
    // Do not calculate isBlocked's value before explicitly checking if PC is disabled.
    // There is no way of telling whether false returned from super.isBlocked means that PC is disabled or if it is the calculated value.
    if (isPCDisabled()) {
      return false;
    }
    return this.event?.channelId && mw.catalog.getChannelById(this.event.channelId)?.isBlocked()
      || mw.customer.currentProfile?.blockUnratedEvents && !this.hasRatings()
      || super.isBlocked();
  }
}
