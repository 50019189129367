import {Errors} from 'mw/api/Error';
import {Playable, PlayableType} from 'mw/api/Metadata';
import {boProxy} from 'mw/bo-proxy/BOProxy';
import {PlaybackSession, PlaybackSessionManager, SessionParams, CreateSessionParams, SessionTerminationReason} from 'mw/playback/sessions/PlaybackSessionManager';

import {PlaybackSession5j} from './PlaybackSession5j';

const TAG = 'Manager5j';

export class Manager5j extends PlaybackSessionManager {
  public createPlaybackSession(params: CreateSessionParams): Promise<PlaybackSession> {
    return boProxy.bo.createPlaybackSession(this.getSessionParams(params.playable))
      .then(sessionData => {
        return new PlaybackSession5j(sessionData.id, sessionData.assets, params.playbackParametersProvider);
      });
  }

  public destroySession(session: PlaybackSession, reason: SessionTerminationReason): Promise<void> {
    return session.terminate(reason);
  }

  protected getSessionParams(source: Playable): SessionParams {
    switch (source.getPlayableType()) {
      case PlayableType.Channel:
        return {ChannelId: source.getId()};

      case PlayableType.Content:
        return {ContentId: source.getId()};

      default:
        throw Errors.PlayerNotSupportedSource;
    }
  }

  protected createInformativeSessions(params: CreateSessionParams): Promise<PlaybackSession> {
    return super.createInformativeSessions(params)
      .then(playbackSession => {
        const fakeSessionParams: SessionParams = {
          ...this.getSessionParams(params.playable),
          'SessionType': 'Informative'
        };

        return boProxy.bo.createPlaybackSession(fakeSessionParams)
          .then(sessionData => {
            return new PlaybackSession5j(sessionData.id, playbackSession.assets, params.playbackParametersProvider);
          });
      });
  }
}
