import {createStyles} from 'common-styles';
import i18next from 'i18next';
import moment from 'moment';
import React, {useContext, useState} from 'react';
import {ViewStyle, TextStyle, View, StyleProp} from 'react-native';

import {DOT_SEPARATOR, isMobile, isBigScreen, dimensions} from 'common/constants';
import {getMediaTitle, getMetadataFromMedia, getShortestSynopsis, isTruthy, formatGenres, getSportTeamsInfo, isSeriesEpisode} from 'common/HelperFunctions';
import {getEpisodeAndSeasonNumber} from 'common/utils';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Event, Title, Recording, isSingleRecording} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import NitroxText, {TextType} from 'components/NitroxText';
import {PostProcessors} from 'locales/i18nPostProcessors';
import {TVScreenFocusContext} from 'screens/tv/TvScreenContext';
import {getFormattedStartEndTime} from 'screens/tv/TvScreenHelperFunctions';

import {MediaPlayerFocusContext} from './mediaplayer/MediaPlayerFocusContext';
import NitroxInteractive from './NitroxInteractive';
import {PCMedia} from './parentalControl/ParentalControlProvider';

const numberOfTitleLines = 2;
const numberOfDescriptionLines = 4;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    backgroundColor: colors.transparent
  },
  mobileDetailsMeta: {
    color: colors.overlayMediaDetails.text.mobile
  },
  mobileDetailsSubtitle: {
    color: colors.overlayMediaDetails.subtitle.mobile
  },
  sportTeamsSubtitle: isMobile ? {
    marginTop: -dimensions.margins.xsmall
  } : {
    marginTop: -dimensions.margins.small,
    marginBottom: dimensions.margins.xsmall
  },
  focusedDetailsMeta: {
    color: colors.overlayMediaDetails.text.focused
  },
  unfocusedDetailsMeta: {
    color: colors.overlayMediaDetails.text.unfocused
  },
  focusedDetailsSubtitle: {
    color: colors.overlayMediaDetails.subtitle.focused
  },
  unfocusedDetailsSubtitle: {
    color: colors.overlayMediaDetails.subtitle.unfocused
  },
  descriptionWidth: {
    width: 698
  }
}));

const getInfoForTitle = (title: Title, isEvent: boolean, separator: string): string => {
  const components: string[] = [];
  const ratings = title.pcRatings[0]?.value;
  if (ratings) {
    components.push(ratings);
  }

  const episodeInfo = getEpisodeAndSeasonNumber(title, {showSeason: isEvent});
  if (episodeInfo) {
    components.push(episodeInfo);
  }

  if (title.contents.length && title.contents[0].duration) {
    const duration = moment.duration(title.contents[0].duration, 'seconds').format('h[h] m[m]', {trim: 'both final'});
    components.push(duration);
  }
  if (title.metadata.genres.length) {
    components.push(formatGenres(title.metadata.genres));
  }
  return components.filter(isTruthy).join(separator);
};

const renderText = (params: {type: TextType; text: string; style?: TextStyle; focused?: boolean; linesNumber?: number; key?: string | number; handleEllipsize?: boolean; testID?: string}) => {
  const styles = stylesUpdater.getStyles();
  const {focused, key, type, style, linesNumber = 1, text, handleEllipsize, testID} = params;
  const defaultStyle = !isBigScreen ? styles.mobileDetailsMeta :
    focused ? styles.focusedDetailsMeta : styles.unfocusedDetailsMeta;
  return <NitroxText key={key} textType={type} style={[defaultStyle, style]} numberOfLines={linesNumber} handleEllipsize={handleEllipsize} testID={testID}>{text}</NitroxText>;
};

const renderTitle = (params: {title: string; focused: boolean; landscape: boolean; linesNumber: number; key?: string | number}) => {
  const {title, focused, landscape, linesNumber, key} = params;
  return renderText({type: landscape ? 'player-title' : 'title1', text: title, focused, key, linesNumber, handleEllipsize: true, testID: 'title'});
};

const renderHeading = (params: {heading?: string; focused: boolean; landscape: boolean; key?: number | string; textType?: TextType}, completeDetails: boolean) => {
  const {heading, focused, landscape, key, textType} = params;
  if (!heading || heading.length <= 0 || (!completeDetails && isMobile && landscape)) {
    return null;
  }
  const defaultTextType = (isMobile && !completeDetails) ? 'callout-small' : 'subhead';
  return renderText({type: textType ?? defaultTextType, text: heading, focused, key, handleEllipsize: true, testID: 'heading'});
};

const renderSportTeamsSubtitle = (params: {sportTeamsSubtitle?: string; focused: boolean; landscape: boolean; focusable?: boolean; key?: number | string}) => {
  const {sportTeamsSubtitle, focused, key} = params;
  if (!sportTeamsSubtitle) {
    return null;
  }
  const style = stylesUpdater.getStyles().sportTeamsSubtitle;
  return renderText({type: 'sportTeams', text: sportTeamsSubtitle, focused, style, key, handleEllipsize: true});
};

const renderSubtitle = (params: {subtitle?: string; focused: boolean; landscape: boolean; focusable?: boolean; key?: number | string}, completeDetails: boolean) => {
  const {subtitle, focused, landscape, key} = params;
  if (!subtitle || subtitle.length <= 0) {
    return null;
  }
  const styles = stylesUpdater.getStyles();
  const style = !isBigScreen ? styles.mobileDetailsSubtitle :
    focused ? styles.focusedDetailsSubtitle : styles.unfocusedDetailsSubtitle;
  return renderText({type: !completeDetails && isMobile && !landscape ? 'callout-small' : 'callout', text: subtitle, style, focused, key, handleEllipsize: true, testID: 'subtitle'});
};

const renderDescription = (params: {description?: string; focused: boolean; isBlocked?: boolean; key?: number | string}, completeDetails: boolean, hasPoster?: boolean) => {
  const {description, focused, key, isBlocked} = params;

  if (!isBlocked && description && (!isMobile || completeDetails)) {
    const style = hasPoster ? stylesUpdater.getStyles().descriptionWidth : undefined;
    return renderText({type: 'description', text: description, style, focused, linesNumber: numberOfDescriptionLines, key, testID: 'description'});
  }
  return null;
};

export interface Metadata {
  heading?: string;
  title: string;
  sportTeamsSubtitle?: string;
  subtitle?: string;
  description?: string;
  isBlocked?: boolean;
}

type Props = Metadata & {
  style?: StyleProp<ViewStyle>;
  headingTextType?: TextType;
  landscape: boolean;
  focusable?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onPress?: () => void;
  focusOnMount?: boolean;
  focusPriority?: number;
  completeDetails?: boolean;
  children?: React.ReactNode;
  titleNumberOfLines?: number;
  hasPoster?: boolean;
};

const ShortDetails: React.FunctionComponent<Props> = props => {
  const tvScreenFocusContext = useContext(TVScreenFocusContext);
  const mediaPlayerFocusContext = useContext(MediaPlayerFocusContext);

  const [focused, onFocusStateChanged] = useState(false);
  const appearFocused = focused || !props.focusable;

  const children = [
    renderHeading({heading: props.heading, focused: appearFocused, landscape: props.landscape, textType: props.headingTextType, key: 1}, !!props.completeDetails),
    renderTitle({title: props.title, focused: appearFocused, landscape: props.landscape, linesNumber: props.titleNumberOfLines ?? numberOfTitleLines, key: 2}),
    renderSportTeamsSubtitle({sportTeamsSubtitle: props.sportTeamsSubtitle, focused: appearFocused, landscape: props.landscape, focusable: props.focusable, key: 3}),
    renderSubtitle({subtitle: props.subtitle, focused: appearFocused, landscape: props.landscape, focusable: props.focusable, key: 4}, !!props.completeDetails),
    renderDescription({description: props.description, focused: appearFocused, isBlocked: props.isBlocked, key: 5}, !!props.completeDetails, props.hasPoster)
  ];

  const styles = stylesUpdater.getStyles();
  if (props.focusable) {
    // this needs to be wrapped in view, as props.children may be focusable, while being a child of Touchable prevents it
    return (
      <View>
        <NitroxInteractive
          focusPriority={props.focusPriority}
          ref={tvScreenFocusContext.eventDetails || mediaPlayerFocusContext.mediaDetails}
          activeOpacity={1}
          style={[styles.container, props.style]}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onPress={props.onPress}
          onFocusStateChanged={onFocusStateChanged}
          focusOnMount={props.focusOnMount}
          testID='details'
        >
          {children}
        </NitroxInteractive>
        {props.children}
      </View>
    );
  } else {
    return (
      <View style={[styles.container, props.style]}>
        {children}
        {props.children}
      </View>
    );
  }
};
ShortDetails.defaultProps = {
  focusable: true
};
export default React.memo(ShortDetails);

export const getEpisodeMetadata = (title: Title): Metadata => {
  if (!title.episode || !isSeriesEpisode(title)) {
    return {title: title.name};
  }

  const heading = title.episode.seriesName + ' - ' + i18next.t('common.season') + ' ' + title.episode.seasonNumber;
  const episodeTitle = title.episode.title ? title.episode.title : title.name;
  const subtitle = i18next.t('common.episode') + ' ' + title.episode.number + ' • ' + formatGenres(title.metadata.genres);
  const description = title.metadata.shortSynopsis;
  return {heading, title: episodeTitle, subtitle, description};
};

export const getTitleMetadata = (title: Title): Metadata => {
  const subtitle = getInfoForTitle(title, false, ` ${DOT_SEPARATOR} `);
  return {title: title.name, subtitle, description: getShortestSynopsis(title.metadata)};
};

export const getEventMetadata = (event: Event, t: i18next.TFunction, isLandscape?: boolean): Metadata => {
  let heading: string | undefined;
  if (!isLandscape || !isMobile) {
    if (event.isPast) {
      heading = `${t('common.previous', {postProcess: PostProcessors.ToUpperCase})} - ${moment(event.start).format(mw.configuration.timeFormat)}`;
    } else if (event.isFuture) {
      heading = `${t('common.next', {postProcess: PostProcessors.ToUpperCase})} - ${moment(event.start).format(mw.configuration.timeFormat)}`;
    } else if (event.isNow) {
      heading = t('common.now', {postProcess: PostProcessors.ToUpperCase});
    }
  }
  const subtitle = getInfoForTitle(event.title, true, ` ${DOT_SEPARATOR} `);
  const sportTeamsSubtitle = isMobile ? getSportTeamsInfo(event) : undefined;

  return {heading, title: event.name, sportTeamsSubtitle, subtitle, description: event.title.metadata.shortSynopsis};
};

export const getZapperMetadata = (event: Event): Metadata => {
  const heading = getFormattedStartEndTime(event);
  const subtitle = getInfoForTitle(event.title, true, ` ${DOT_SEPARATOR} `);

  return {heading, title: event.name, subtitle, description: event.title.metadata.shortSynopsis};
};

export const getEPGMetadata = (event: Event, t: i18next.TFunction, isMediaBlocked: (media: PCMedia) => boolean): Metadata => {
  const headingTime = [
    moment(event.start).format(mw.configuration.timeFormat),
    moment(event.end).format(mw.configuration.timeFormat)
  ].filter(isTruthy).join(' - ');
  const subtitle = getInfoForTitle(event.title, true, ` ${DOT_SEPARATOR} `);
  const sportTeamsSubtitle = isBigScreen ? getSportTeamsInfo(event) : undefined;
  const metadata = getMetadataFromMedia(event);
  const description = getShortestSynopsis(metadata);
  const isBlocked = isMediaBlocked(event);
  const heading = event.isNow ? `${t('common.now')} | ${headingTime}` : headingTime;
  return {heading, title: event.name, sportTeamsSubtitle, subtitle, description, isBlocked};
};

export const getRecordingMetadata = (recording: Recording, t: i18next.TFunction, isMediaBlocked: (media: PCMedia) => boolean): Metadata | undefined => {
  if (!isSingleRecording(recording)) {
    return;
  }
  return {...getEPGMetadata(recording.event, t, isMediaBlocked), title: getMediaTitle(recording)};
};
