import {Product} from 'mw/api/Metadata';

import {EntitledProduct, EntitledMedia} from './EntitledProductInterface';

export class EntitledProductObject implements EntitledProduct {

  public readonly product: Product;
  public readonly media?: EntitledMedia[];

  public constructor(product: Product, media?: EntitledMedia[]) {
    this.product = product;
    this.media = media;
  }
}
