import {createStyles} from 'common-styles';
import React, {useMemo, useState} from 'react';
import {View} from 'react-native';

import {isMobile, dimensions} from 'common/constants';
import {getCreditsFullName, getPictureUrl} from 'common/HelperFunctions';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Credits} from 'mw/api/Metadata';

import MediaPicture from 'components/MediaPicture';
import NitroxInteractive from 'components/NitroxInteractive';
import NitroxText from 'components/NitroxText';

const photoSize = isMobile ? {width: 81, height: 81} : {width: 175, height: 175};
const placeholderHeight = photoSize.height * 0.4;

export const mugShotWidth = isMobile ? 120 : 250;
export const mugShotPadding = (mugShotWidth - photoSize.width) / 2;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: mugShotWidth
  },
  picture: {
    ...photoSize,
    backgroundColor: colors.tile.poster.placeholder.background,
    borderRadius: photoSize.height / 2,
    overflow: 'hidden'
  },
  text: {
    color: colors.tile.contentText.unfocused,
    textAlign: 'center',
    marginTop: dimensions.margins.small
  },
  focusedText: {
    opacity: dimensions.opacity.medium
  },
  placeholderColor: colors.tile.poster.placeholder.icon
}));

type Props = {
  credits: Credits;
  onFocus?: () => void;
}

const MugShot: React.FC<Props> = props => {
  const imageSource = useMemo(() => getPictureUrl(props.credits, photoSize), [props.credits]);
  const name = useMemo(() => getCreditsFullName(props.credits), [props.credits]);
  const [focused, onFocusStateChanged] = useState(false);
  const styles = stylesUpdater.getStyles();

  return (
    <NitroxInteractive
      activeOpacity={1}
      onFocusStateChanged={onFocusStateChanged}
      onFocus={props.onFocus}
      style={styles.container}
      testID='button_mugshot'
    >
      <View style={styles.picture}>
        <MediaPicture source={imageSource} mediaType='credits' iconSize={placeholderHeight} iconColor={styles.placeholderColor} />
      </View>
      <NitroxText textType='subhead' numberOfLines={2} style={[styles.text, focused && styles.focusedText]}>{name}</NitroxText>
    </NitroxInteractive>
  );
};

export default React.memo(MugShot);
