import {createStyles} from 'common-styles';
import React from 'react';
import {View, StyleProp, ViewStyle} from 'react-native';

import {dimensions, getValue} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import FocusableTextInput from 'components/inputs/FocusableTextInput';
import {FocusableTextInputImplProps} from 'components/inputs/FocusableTextInputImpl';
import NitroxText, {TextType} from 'components/NitroxText';

type Props = FocusableTextInputImplProps & {
  label: string;
  style?: StyleProp <ViewStyle>;
  textType?: TextType;
}

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  label: {
    color: colors.input.label,
    marginHorizontal: dimensions.inputs.focusBorder,
    marginBottom: getValue({mobile: dimensions.margins.medium, defaultValue: dimensions.margins.large})
  },
  inputField: {
    marginBottom: getValue({mobile: dimensions.margins.large, defaultValue: dimensions.margins.xLarge}),
    marginTop: getValue({mobile: dimensions.margins.large, defaultValue: dimensions.margins.xLarge})
  }
}));

const LabeledTextInput: React.FC<Props> = (props) => {
  const {
    label,
    style,
    textType = 'input-label',
    ...textInputProps
  } = props;

  const styles = stylesUpdater.getStyles();

  return (
    <View style={[styles.inputField, style]}>
      <NitroxText textType={textType} style={styles.label}>{label}</NitroxText>
      <FocusableTextInput {...textInputProps} />
    </View>
  );
};

export default LabeledTextInput;
