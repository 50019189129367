import {useRef, useEffect, useState, useContext, MutableRefObject, useCallback} from 'react';
import {Dimensions, BackHandler, NativeSyntheticEvent, NativeScrollEvent, BackPressEventName, AppStateStatus} from 'react-native';
import {NavigationScreenProp, NavigationParams, NavigationEventPayload, NavigationEventCallback, NavigationContext} from 'react-navigation';

import {CallableAsync, DisposableFunction, disposable, Callable, DebouncedFunction, debounce, throttle, disposableCallback, DisposableCallback} from 'common/Async';
import {isBigScreen, isDesktopBrowser, usingNitroxFocusEngine, isWeb, isMobileBrowser} from 'common/constants';
import {createScrollViewPager, doNothing, generateUniqueId} from 'common/HelperFunctions';
import {ScreenSizeBasedOrientation, NavigationFocusState, ScreenInfo, ChangeEvent, TestProps, Emitter, Rect, Size, WebSubtype} from 'common/HelperTypes';
import {useEventListener} from 'common/hooks/Hooks';
import {Log} from 'common/Log';
import TestContext from 'common/TestContext';

import {CustomerEvent} from 'mw/api/Customer';
import {Media, Event, isEvent} from 'mw/api/Metadata';
import {nxffConfig} from 'mw/api/NXFF';
import {Profile, ProfileEvent, ProfileStoredProperties, ProfilePropertiesChangePayload} from 'mw/api/Profile';
import {mw} from 'mw/MW';
import {NitroxAppState} from 'mw/platform/app-state/AppState';

import {useFocusParentUtility} from 'components/FocusParent';
import {KeyEventManager, SupportedKeyEventTypes, NativeKeyEvent, SupportedKeys} from 'components/KeyEventManager';

/*
 * re-export common hooks
 */
export {useEventListener, useIntId} from 'common/hooks/Hooks';
export {useCurrentTime} from './useCurrentTime';
export {useNowDate} from './useNowDate';
export {useHeaderActions} from './useHeaderActions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useFunctionRef<T extends Callable>(fn: T, deps?: readonly any[] | undefined): MutableRefObject<TypedFunction<T>> {
  const f = useRef(fn);

  useEffect(() => {
    f.current = fn;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return f;
}

/**
 * `useFunction` is similar to `useCallback` except that the returned function persists
 * under the same reference for the full lifetime of the component.
 * Use it whenever you want to prevent unnecessary renders caused by callback changes.
 *
 * @param fn Function that should be memoized.
 */
export function useFunction<T extends Callable>(callback: T): T {
  const ref = useRef(callback);
  ref.current = callback;

  const callCurrent: T = useCallback((...args: Parameters<T>): ReturnType<T> => ref.current(...args), []) as T;

  return callCurrent;
}

/**
 * Implements concept known as late binding which allows to decouple a reference to the underlying function from its definition
 * and in the result allows to use the function before its implementation. Returns a reference the late binded function which
 * does not change for the full lifetime of the component and a binder function which accepts a function with the
 * implementation (use useFunction to create it).
 *
 * The use of a late bound function for which no actual binding has been performed is considered to be an error
 * and therefore in such situations an exception will be thrown.
 */
export function useLateBinding<T extends Callable>(): [TypedFunction<T, void | ReturnType<T>>, TypedFunction<(f: TypedFunction<T>) => void>] {
  const volatileRef = useRef<undefined | TypedFunction<T>>();
  const consistentRef = useRef<TypedFunction<T, void | ReturnType<T>>>((...args: Parameters<T>) => {
    if (!volatileRef.current) {
      throw new Error('Reference to late binded function is missing!');
    }
    volatileRef.current(...args);
  });
  const binder = useFunction((fn: TypedFunction<T>) => {
    volatileRef.current = fn;
  });
  return [consistentRef.current, binder];
}

/**
 * Simplifies using *ref* as a component's lifetime variable.
 * You don't have to reach *ref.current* - you use it almost like state.
 * Such variable is similar to a property of a class component.
 *
 * Returns a variable getter and a setter.
 */
export function useProperty<S>(initialValue: S): [() => S, (value: S) => void] {
  const value = useRef<S>(initialValue);
  const getValue = useFunction(() => value.current);
  const setValue = useFunction((newValue: S) => value.current = newValue);
  return [
    getValue,
    setValue
  ];
}

type SynchronizedStateValue<S> = {
  getStateSync: () => S;
  state: S;
};
export function useSynchronizedState<S>(initialState: S): [SynchronizedStateValue<S>, (value: S) => void] {
  const [getProperty, setProperty] = useProperty(initialState);
  const [state, setState] = useState(initialState);

  const setValue = useFunction((value: S) => {
    setProperty(value);
    setState(value);
  });

  return [
    {getStateSync: getProperty, state},
    setValue
  ];
}

/**
 * This is like useEffect except that it separates dependencies which change
 * should trigger callback call and those which should just update callback's closure.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useLazyEffect(effect: React.EffectCallback, callDeps: readonly any[], closureDeps: readonly any[]): void {
  const callback = useFunction(effect);
  useEffect(callback, callDeps);
}

export function useEffectOnce(effect: React.EffectCallback, closureDeps: readonly any[]): void {
  useLazyEffect(effect, [], closureDeps);
}

/**
 * Minimal width for each device type.
 */
export const webSubtypeBreakpoints = {
  phone: 0,
  tablet: 540,
  bigScreen: 960
};
export function getWebSubtype(width: number): WebSubtype {
  if (width >= webSubtypeBreakpoints.bigScreen) {
    return 'bigScreen';
  }
  if (width >= webSubtypeBreakpoints.tablet) {
    return 'tablet';
  }

  return 'phone';
}

const baseWidth = 1920;
const baseHeight = 1080;
const baseAspectRatio = baseWidth / baseHeight;
export const getBaseSize = ({width, height}: Size): Size & {scale: number} => {
  // Do not scale non-web, mobile web and high resolution on desktop
  if (!isWeb || isMobileBrowser || (isDesktopBrowser && width > baseWidth && height > baseHeight)) {
    return {width, height, scale: 1};
  }
  const aspectRatio = width / height;
  return (aspectRatio > baseAspectRatio)
    ? {width: baseHeight * aspectRatio, height: baseHeight, scale: height / baseHeight}
    : {width: baseWidth, height: baseWidth / aspectRatio, scale: width / baseWidth};
};

const landscape: ScreenSizeBasedOrientation = {isLandscape: true, isPortrait: false};
const portrait: ScreenSizeBasedOrientation = {isLandscape: false, isPortrait: true};

export function getScreenInfo(dimensions: Size = Dimensions.get('window')): ScreenInfo {
  const size = dimensions || Dimensions.get('window');
  return {
    trueSize: size,
    size: getBaseSize(size),
    orientation: size.width >= size.height ? landscape : portrait,
    webSubtype: getWebSubtype(size.width)
  };
}

export function useScreenInfo(): ScreenInfo {
  const [screenInfo, setScreenInfo] = useState(getScreenInfo());
  useEventListener('change', (event: any) => {
    setScreenInfo(getScreenInfo(event.window));
  }, Dimensions);
  return screenInfo;
}

export function useNavigationFocusState<S, P>(navigation: NavigationScreenProp<S, P> | undefined): NavigationFocusState {
  const [state, setState] = useState(navigation?.isFocused() ? NavigationFocusState.IsFocused : NavigationFocusState.IsBlurred);
  useEffect(() => {
    const subscriptionWillFocus = navigation?.addListener('willFocus', () => setState(NavigationFocusState.IsFocusing));
    const subscriptionDidFocus = navigation?.addListener('didFocus', () => setState(NavigationFocusState.IsFocused));
    const subscriptionWillBlur = navigation?.addListener('willBlur', () => setState(NavigationFocusState.IsBlurring));
    const subscriptionDidBlur = navigation?.addListener('didBlur', () => setState(NavigationFocusState.IsBlurred));
    return () => {
      subscriptionWillFocus?.remove();
      subscriptionDidFocus?.remove();
      subscriptionWillBlur?.remove();
      subscriptionDidBlur?.remove();
    };
  }, [navigation]);
  return state;
}

export function useAppState(): AppStateStatus {
  const [appState, setAppState] = useState(NitroxAppState.currentState);
  useEventListener('change', setAppState, NitroxAppState);
  return appState;
}

/**
 * This is like useEffect except that:
 * - it does not fire for initial render
 * - it separates dependencies which change should trigger
 * callback call and those which should just update callback's closure.
 */
export function useChangeEffect(effect: React.EffectCallback, callDeps: readonly any[], closureDeps?: readonly any[]): void {
  const didMount = useRef(false);
  useLazyEffect(() => {
    if (didMount.current) {
      effect();
    } else {
      didMount.current = true;
    }
  }, callDeps, closureDeps || []);
}

export function useMounted() {
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  return mounted;
}

export function useLazyRef<T>(initializer: () => T): MutableRefObject<T> {
  const ref = useRef<T>();
  if (!ref.current) {
    ref.current = initializer();
  }

  return ref as MutableRefObject<T>;
}

export function useLazyValue<T>(initializer: () => T): T {
  return useLazyRef(initializer).current;
}

/**
 * Similar to `useDisposableCallback`, except it works with Promises.
 *
 * This works like `useCallback` but:
 * - returned function persists for the whole life of a component under the same reference
 * - after unmount gets automatically turned off and the promise chain gets cut off / abandoned,
 *   meaning even chained promises won't run after unmount (it is safe that way).
 */
export function useDisposable<T extends CallableAsync>(fn: T, closureDeps: readonly any[] = []): DisposableFunction<T> {
  const cb = useFunction(fn);
  const disposableFn = useLazyRef(() => disposable(cb));

  useEffect(() => () => disposableFn.current.dispose(), [disposableFn]);

  return disposableFn.current;
}

export function useDebounce<T extends Callable>(fn: T, ms: number): DebouncedFunction<T> {
  const cb = useFunction(fn);
  const debouncedFn = useLazyRef(() => debounce(cb, ms));

  useEffect(() => () => debouncedFn.current.abort(), [debouncedFn]);

  return debouncedFn.current;
}

export function useThrottle<T extends Callable>(fn: T, ms: number): T | ((...args: Parameters<T>) => void) {
  const cb = useFunction(fn);
  const throttledFn = useLazyRef(() => throttle(cb, ms));

  return throttledFn.current;
}

/**
 * This works like `useCallback` but:
 * - returned function persists for the whole life of component under the same reference
 * - after unmount this function gets automatically turned off (hence disposable),
 *   meaning it won't run after the unmount (it is safe that way).
 */
export function useDisposableCallback<T extends Callable>(fn: T, closureDeps: readonly any[] = []): DisposableCallback<T> {
  const cb = useFunction(fn);
  const callback = useLazyRef(() => disposableCallback(cb));

  useEffect(() => () => callback.current.dispose(), [callback]);

  return callback.current;
}

/**
 * Works the same as useState, but setter is wrapped in disposable decorator
 * so that it can be used e.g. as a callback to a promise.
 */
export function useDisposableState<S>(initialState: S): [S, React.Dispatch<React.SetStateAction<S>>] {
  const [state, setState] = useState(initialState);
  const disposableSetter = useDisposableCallback(setState);
  return [state, disposableSetter];
}

type TypedFunction<T extends Callable, RT = ReturnType<T>> = {
  (...args: Parameters<T>): RT;
}

export function useKeyEventHandler(keyEventType: SupportedKeyEventTypes, handler: (event: NativeKeyEvent) => void): () => void {
  return useEventListener(keyEventType, handler, KeyEventManager.getInstance());
}

export function useScrollHandler(handler: (event: WheelEvent) => void): void {
  useEffect(() => {
    if (isDesktopBrowser) {
      window.addEventListener('wheel', handler);
    }

    return () => {
      if (isDesktopBrowser) {
        window.removeEventListener('wheel', handler);
      }
    };
  }, [handler]);
}

const backPressCaptureHookParams = usingNitroxFocusEngine
  ? {
    event: 'keyup' as SupportedKeyEventTypes | BackPressEventName,
    emitter: KeyEventManager.getInstance() as Emitter<SupportedKeyEventTypes | BackPressEventName>
  } : {
    event: 'hardwareBackPress' as SupportedKeyEventTypes | BackPressEventName,
    // BackHandler expects non-argument callback, passing (x: Type | undefined) => {} fails type-wise.
    // Conditional typing of this scenario would require a lot of run-time conditions
    emitter: BackHandler as unknown as Emitter<SupportedKeyEventTypes | BackPressEventName>
  };

// return true to override default back behavior, e.g. navigation back
export function useBackPressCapture(handler: (event: NativeKeyEvent | undefined) => boolean) {
  const wrappedHandler = useCallback((event: NativeKeyEvent | undefined) => {
    if (usingNitroxFocusEngine && event?.key !== SupportedKeys.Back) {
      return true;
    }
    return handler(event);
  }, [handler]);

  useEventListener(backPressCaptureHookParams.event, wrappedHandler, backPressCaptureHookParams.emitter);
}

export function useScrollViewPaging(onEndReached?: () => void, onEndReachedThreshold?: number) {
  const pager = useRef(createScrollViewPager());
  const onScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    pager.current.onScroll(event, onEndReached, onEndReachedThreshold);
  };
  return onScroll;
}

/**
 * Useful for debugging function components (usehooks.com)
 */
export function useWhyDidYouUpdate<Props extends {[key: string]: any}>(name: string, props: Props) {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = useRef<Props | null>(null);

  useEffect(() => {
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({...previousProps.current, ...props});
      // Use this object to keep track of changed props
      const changesObj: {[key: string]: any} = {};
      // Iterate through keys
      allKeys.forEach(key => {
        // If previous is different from current
        if (previousProps.current && previousProps.current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key]
          };
        }
      });

      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        Log.info('[why-did-you-update]', `${name}`, changesObj);
      }
    }

    // Finally update previousProps with current props for next hook call
    previousProps.current = props;

  }); // runs every render
}

export function useForceUpdate() {
  const [value, setValue] = useState(() => Symbol());
  return {forceUpdateState: value, forceUpdate: useFunction(() => {
    setValue(Symbol());
  })};
}

export function useCurrentProfile() {
  const [profile, setProfile] = useState(mw.customer.currentProfile);
  const onChange = ({to: profile}: ChangeEvent<Profile | null>) => {
    setProfile(profile);
  };
  useEventListener(CustomerEvent.ProfileChange, onChange, mw.customer);
  return profile ? profile : undefined; // map null to undefined for easier api in ui components
}

export function useProfileList() {
  const [profiles, setProfiles] = useState(mw.customer.profiles);
  const currentProfile = useCurrentProfile();
  const sort = (a: Profile, b: Profile) => {
    if (a === currentProfile) return -1;
    if (b === currentProfile) return 1;
    return a.name.localeCompare(b.name);
  };
  useEventListener(
    CustomerEvent.ProfileListUpdate,
    (profileList: Profile[]) => setProfiles([...profileList].sort(sort)),
    mw.customer
  );
  useLazyEffect(() => {
    setProfiles([...profiles].sort(sort));
  }, [currentProfile], [profiles, sort]);

  return profiles;
}

export function useProfileProperties() {
  const [properties, setProperties] = useState<ProfileStoredProperties | null>(mw.customer.currentProfile && mw.customer.currentProfile.getProperties());
  useEventListener(
    ProfileEvent.PropertiesChange,
    useCallback(({to}: ProfilePropertiesChangePayload) => setProperties(to), []),
    useCurrentProfile()
  );
  useEventListener(
    CustomerEvent.ProfileChange,
    useCallback(({to}: ChangeEvent<Profile | null>) => setProperties(to && to.getProperties()), []),
    mw.customer
  );
  return properties;
}

function findBackOffice(code: string) {
  return nxffConfig.getSelectableBackOffices().find(backOffice => backOffice.code === code);
}
export function useCurrentBackOffice() {
  const backOfficeCode = mw.bo.getBackOfficeCode();
  const [backOffice, setBackOffice] = useState(findBackOffice(backOfficeCode));

  useEffect(() => {
    setBackOffice(findBackOffice(backOfficeCode));
  }, [backOfficeCode]);

  return backOffice;
}

export function useToggle(initialValue?: boolean): [boolean, {on: () => void; off: () => void; toggle: () => void}] {
  const [state, setState] = useState(!!initialValue);
  const on = useCallback(() => setState(true), []);
  const off = useCallback(() => setState(false), []);
  // callback inside setState is essential here, races will break state consistency otherwise
  const toggle = useCallback(() => setState(prevState => !prevState), []);

  return [state, {on, off, toggle}];
}

export function useNavigation() {
  return useContext(NavigationContext);
}

export function useIsScreenFocused() {
  const navigation = useNavigation();
  const isScreenFocused = useNavigationFocusState(navigation) === NavigationFocusState.IsFocused;

  return {
    isScreenFocused,
    inNavigationContext: !!navigation
  };
}

export function useNavigationParam<Params extends NavigationParams>(param: keyof Params): Params[typeof param] {
  // keyof can also return number/symbol as of Typescript 2.9+
  return useNavigation().getParam(param as string);
}

export function useTestID(props: TestProps, testIDFunctionKey: string, fallback?: string): string | undefined {
  const testContext = useContext(TestContext);
  const testIDFactory = testContext[testIDFunctionKey];
  const {testID} = props;

  if (testID && testIDFactory) {
    Log.warn(testIDFunctionKey, 'Both testID and testIDFactory function defined.');
  }

  if (typeof testIDFactory === 'string') {
    return testIDFactory;
  } else if (typeof testIDFactory == 'function') {
    return testIDFactory({fallback: fallback, ...props});
  } else {
    return testID;
  }
}

export function useOnAppear(onAppear: Callable, onDisappear?: Callable) {
  const navigationState = useNavigationFocusState(useNavigation());

  useChangeEffect(() => {
    if (!isBigScreen) {
      return;
    }

    if (navigationState === NavigationFocusState.IsFocused) {
      onAppear();
    } else {
      onDisappear?.();
    }
  }, [navigationState], [onAppear, onDisappear]);
}

export function useWillAppear(callback: NavigationEventCallback) {
  const navigation = useNavigation();

  const willFocus = useFunction((payload: NavigationEventPayload) => {
    callback(payload);
  });

  useEffect(() => {
    const subscription = navigation?.addListener('willFocus', willFocus);
    return () => subscription?.remove();
  }, [navigation, willFocus]);
}

export function useFocusOnAppear() {
  const {focus: focusNearestParent} = useFocusParentUtility();

  useOnAppear(focusNearestParent || doNothing);
}

export function useWatchList() {
  const profile = useCurrentProfile();
  const [watchList, setWatchList] = useState<Media[]>(profile?.watchList || []);

  useChangeEffect(() => {
    setWatchList(profile?.watchList || []);
  }, [profile]);

  const updateWatchList = useCallback(() => {
    setWatchList(profile?.watchList || []);
  }, [profile]);

  useEventListener(ProfileEvent.WatchListChange, updateWatchList, profile);

  return watchList;
}

type Countdown = {
  initialValue: number;
  onFinish: () => void;
}

export function useCountdown({initialValue, onFinish}: Countdown) {
  const [count, setCount] = useState(initialValue);
  const [counting, setCounting] = useState(false);

  const startCounting = useCallback(() => {
    setCounting(true);
  }, []);

  const stopCounting = useCallback(() => {
    setCounting(false);
    setCount(initialValue);
  }, [initialValue]);

  useLazyEffect(() => {
    if (!counting) return;

    if (count > 0) {
      const id = setTimeout(() => {
        setCount(count => count - 1);
      }, 1000);
      return () => clearTimeout(id);
    } else {
      stopCounting();
      onFinish();
    }
  }, [counting, count], [onFinish, setCount, stopCounting]);

  return {count, counting, stopCounting, startCounting};
}

/**
 * 'useScrollViewChunksLayout' provides layout of ScrollView's content divided into chunks.
 * Use chunksCount to define number of chunks to divide ScrollView's content into.
 */
export function useScrollViewChunksLayout(horizontal: boolean, initialChunksCount = 0) {
  const [chunksLayout, setChunksLayout] = useState<Rect[]>([]);

  const [contentSize, setContentSize] = useState<Size | null>(null);
  const [chunksCount, setChunksCount] = useState<number>(initialChunksCount);

  useChangeEffect(() => {
    if (!chunksCount || !contentSize) {
      setChunksLayout([]);
      return;
    }
    const newChunksLayout: Rect[] = [];
    const contentSizeInOrientation = horizontal ? contentSize.width : contentSize.height;
    const chunkSize = Math.floor(contentSizeInOrientation / chunksCount);

    for (let i = 0; i < chunksCount; ++i) {
      const isLastChunk = i === chunksCount - 1;
      const position = i * chunkSize;
      const size = isLastChunk ? Math.floor(contentSizeInOrientation - position) : chunkSize;

      const layout = {
        y: horizontal ? 0 : position,
        x: horizontal ? position : 0,
        width: horizontal ? size : contentSize.width,
        height: horizontal ? contentSize.height : size
      };

      if (position + size > contentSizeInOrientation) {
        Log.error('useScrollViewChunksLayout', 'ScrollView content will be overflowed - omitting item');
        continue;
      }
      newChunksLayout.push(layout);
    }
    setChunksLayout(newChunksLayout);
  }, [chunksCount, contentSize], [horizontal]);

  return {
    chunksLayout,
    chunksCount,
    updateContentSize: setContentSize,
    updateChunksCount: setChunksCount
  };
}

export function useTimer() {
  const id = useRef(0);

  const clearTimer = useCallback(() => {
    if (id.current) {
      clearTimeout(id.current);
      id.current = 0;
    }
  }, []);

  const setTimer = useCallback((callback: Callable, delay: number) => {
    clearTimer();
    id.current = setTimeout(callback, delay);
  }, [clearTimer]);

  useLazyEffect(() => clearTimer, [], [clearTimer]);

  return {setTimer, clearTimer};
}

/**
 * Keeps track of current live event from the provided collection of media. Available media is provided by a getter in order to enable pull interface.
 * It can contain events from several different channels and therefore will return a live event that is going to end earlier than any others found in the collection.
 * Please bear in mind that current live event is not same thing as current media fetched from the player.
 */
export function useNearestLiveEvent(getMedia: TypedFunction<() => Media[], Media[]>) {
  const [liveEvent, setLiveEvent] = useState<Event | null>(null);
  const {setTimer, clearTimer} = useTimer();

  const update = useCallback(() => {
    const nearest = getMedia().reduce((previous: Event | null, event) => {
      return isEvent(event) && event.isNow && (!previous || event.end.getTime() < previous.end.getTime())
        ? event
        : previous;
    }, null);
    setLiveEvent(nearest);
  }, [getMedia]);

  useEffect(update, [update]);

  const scheduleNextUpdate = useCallback(() => {
    clearTimer();
    if (liveEvent) {
      setTimer(update, Math.max(0, liveEvent.end.getTime() - Date.now()));
    }
  }, [clearTimer, setTimer, liveEvent, update]);

  useEffect(scheduleNextUpdate, [liveEvent]);

  return liveEvent;
}

/**
 * Allows to run callback function after each navigation focus
 */
export function useOnNavigationDidFocus(callback: Callable) {
  const focusState = useNavigationFocusState(useNavigation());
  const screenFocused = focusState === NavigationFocusState.IsFocused;
  useEffect(() => {
    if (screenFocused) {
      callback();
    }
  }, [callback, screenFocused]);
}

/**
 * Keeps track whether the screen has been entered by going back in navigation.
 */
export function useIsBack() {
  const [isBack, setBack] = useProperty(false);
  useWillAppear(
    useCallback((payload: NavigationEventPayload) => {
      setBack(payload.action.type === 'Navigation/BACK' || payload.action.type === 'Navigation/POP');
    }, [setBack])
  );
  return isBack;
}

/**
 * Uses generateUniqueId from HelperFunctions.ts
 * to generate a unique id for a component instance.
 * Doesn't support changing tag in consequent renders.
 */
export function useUniqueId(tag: string): string {
  return useLazyRef(() => generateUniqueId(tag)).current;
}

/**
 * Returns a function that produces a promise that resolves when some stateValue changes its value to expectedValue.
 * @param stateValue value that is observed for change
 * @param timeout timeout after which promise is to be rejected
 */
export function useWaitForStateChange<T>(stateValue: T, timeout?: number): (expectedValue: T) => Promise<void> {
  type Wait = {
    value: T;
    resolve: () => void;
    reject: () => void;
  }

  const waitingPromises = useRef<Set<Wait>>(new Set());
  const timeouts = useRef<Set<number>>(new Set());

  const waitForStateChange = useFunction((expectedValue: T): Promise<void> => {
    return new Promise((resolve, reject) => {
      let timeoutId = 0;
      const newWait = {
        value: expectedValue,
        resolve: () => {
          resolve();
          clearTimeout(timeoutId);
        },
        reject
      };
      waitingPromises.current.add(newWait);
      if (!timeout) {
        return;
      }

      timeoutId = setTimeout(() => {
        if (waitingPromises.current.has(newWait)) {
          newWait.reject();
          waitingPromises.current.delete(newWait);
        }
        timeouts.current.delete(timeoutId);
      }, timeout);
      timeouts.current.add(timeoutId);
    });
  });

  useEffect(() => {
    const timeoutsSet = timeouts.current;
    return () => {
      // make sure no promise will be rejected when timeouts finish after unmount
      timeoutsSet.forEach(clearTimeout);
    };
  }, []);

  useChangeEffect(() => {
    waitingPromises.current.forEach(wait => {
      if (wait.value === stateValue) {
        wait.resolve();
        waitingPromises.current.delete(wait);
      }
    });
  }, [stateValue]);

  return waitForStateChange;
}

export function usePropsDependentState<T>(prop: T): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = useState(prop);
  useEffect(() => {
    //TODO: CL-4028 check if setState(state) triggers rerender
    setState(prop);
  }, [prop]);
  return [state, setState];
}

export function usePropsDependentSynchronizedState<T>(prop: T): [SynchronizedStateValue<T>, (value: T) => void] {
  const [{getStateSync, state}, setState] = useSynchronizedState(prop);
  useChangeEffect(() => {
    setState(prop);
  }, [prop]);
  return [
    {getStateSync, state},
    setState
  ];
}

export function usePropsDependentRef<T>(prop: T): React.MutableRefObject<T> {
  const value = useRef(prop);
  value.current = prop;
  return value;
}

export function useChannelAvailability(channelId = ''): boolean {
  const channel = mw.catalog.getChannelById(channelId);
  return !!channel?.isAvailableByPolicy;
}

export function useStaticallyFocused(propsFocusable?: boolean, staticallyFocused?: boolean): {
  onFocusStateChanged: React.Dispatch<React.SetStateAction<boolean>>;
  focusable: boolean;
  focused: boolean;
} {
  const [isFocused, onFocusStateChanged] = useState(false);
  const focusable = staticallyFocused != null ? false : !!propsFocusable;
  const focused = focusable ? isFocused : !!staticallyFocused;

  return {onFocusStateChanged, focusable, focused};
}
