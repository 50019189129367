import {Audio} from 'mw/api/Metadata';

export type CAPQueryParameters = {
  id: string;
  languages?: string[];
}

export class CAPResource {
  public description: string;
  public mimeType: string;
  public uri?: string;
  public derefUri?: any;

  public constructor(description: string, mimeType: string) {
    this.description = description;
    this.mimeType = mimeType;
  }
}

export class CAPInfo {
  public language: string;
  public event: string;
  public senderName: string;
  public instruction: string;
  public description: string;
  public expires?: Date;
  public areasDescriptions: string[] = [];
  public resources: CAPResource[] = [];
  public params = new Map<string, string>();

  public constructor(language: string, event: string, senderName: string, instruction: string, description: string) {
    this.language = language;
    this.event = event;
    this.senderName = senderName;
    this.instruction = instruction;
    this.description = description;
  }
}

export class CAPMessage {
  public id: string;
  public infos: CAPInfo[] = [];

  public constructor(id: string) {
    this.id = id;
  }
}

export class EASInfo extends CAPInfo {
  public message = '';
  public uiLanguage = '';
  public broadcastAudio: Audio[] = [];

  public constructor(capInfo: CAPInfo) {
    super(capInfo.language, capInfo.event, capInfo.senderName, capInfo.instruction, capInfo.description);
    this.expires = capInfo.expires;
    this.areasDescriptions = capInfo.areasDescriptions;
    this.resources = capInfo.resources;
    this.params = capInfo.params;
  }
}

export class EASAlert {
  public id: string;
  public infos: EASInfo[];
  public preAttentionSignal?: Audio;
  public postAttentionSignal?: Audio;

  public constructor(id: string, infos: EASInfo[]) {
    this.id = id;
    this.infos = infos;
  }
}
