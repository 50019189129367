import {createStyles} from 'common-styles';
import React, {useMemo, useRef, useCallback, useContext} from 'react';
import {requireNativeComponent, View, StyleProp, ViewStyle, UIManager, findNodeHandle} from 'react-native';

import {isTVOS, debugFeatures, isBigScreen} from 'common/constants';
import {doNothing} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {useFunction, useDisposableCallback, useLazyEffect, useChangeEffect, useLazyRef, useIsScreenFocused} from 'hooks/Hooks';

import {FocusManager} from './focusManager/FocusManager';
import {EnterStrategy} from './focusManager/FocusManagerTypes';
import {FocusParentNode, FocusParentNodeProps} from './focusManager/FocusParentNode';
import {useFocusParentDebugger} from './FocusParentDebugger';
import {FocusSwitch, FocusSwitchProps, useFocusSwitchValue} from './FocusSwitch';
import {SupportedKeys} from './KeyEventManager';
import {NitroxInteractiveControllerContext} from './NitroxInteractiveControllerContext';

/* eslint-disable schange-rules/no-literal-color */
const debugStyles = createStyles({
  focusOutside: {
    borderColor: 'red',
    borderWidth: 1
  },
  focusInside: {
    borderColor: 'green',
    borderWidth: 1
  }
});
/* eslint-enable schange-rules/no-literal-color */

export const FocusParentContext = React.createContext(0);

export const defaultFocusParentProps: FocusParentNodeProps = {
  rememberLastFocused: false,
  trapFocus: false,
  trapExitEdges: [],
  holdFocus: false
};

type StyleProps = {
  style?: StyleProp<ViewStyle>;
  childFocusedStyle?: StyleProp<ViewStyle>;
}

type FocusParentReadyProps = {
  onReady?: (params: {focus: (enterStrategy?: EnterStrategy) => void}) => void;
};

export type FocusParentProps = React.PropsWithChildren<{
  debug?: boolean;
  focusPriority?: number;
}
& StyleProps
& Partial<FocusParentNodeProps>>
& FocusParentReadyProps
& FocusSwitchProps;

const Wrapper: React.FC<FocusParentNodeProps & FocusParentReadyProps & StyleProps & {debug?: boolean; focusPriority: number}> = ({
  style,
  childFocusedStyle,
  debug,
  ...props
}) => {
  const {focused} = useFocusSwitchValue();
  const focusedStyle = childFocusedStyle ?? [style, debug && debugStyles.focusInside];
  return (
    <FocusParentNative
      style={focused ? focusedStyle : style}
      debug={debug}
      {...props}
    />
  );
};

type FocusParentNativeProps = FocusParentNodeProps & FocusParentReadyProps & {
  style?: StyleProp<ViewStyle>;
  focusPriority: number;
  debug?: boolean
};

const TVOSFocusParent = isTVOS ? requireNativeComponent('FocusParent') : null;

const FocusParentNativeTVOS: React.FC<FocusParentNativeProps> = ({
  onReady,
  ...props
}) => {
  const focus = useDisposableCallback((instance: View, enterStrategy?: EnterStrategy) => {
    // @ts-ignore react native typings don't declare dispatchViewManagerCommand and getViewManagerConfig
    const node = findNodeHandle(instance);
    if (!node) {
      Log.warn('FocusParentNativeTVOS', `Attempted focusing unavailable element, consider using 'useFocusParent' instead`);
      return;
    }
    UIManager?.dispatchViewManagerCommand?.(
      node,
      UIManager?.getViewManagerConfig?.('FocusParent')?.Commands?.focus,
      [enterStrategy]
    );
  }, []);
  const onRefReady = useCallback((instance: View) => {
    onReady?.({
      focus: (enterStrategy?: EnterStrategy) => focus(instance, enterStrategy)
    });
  }, [onReady, focus]);

  const exitEdges = useMemo(
    // tvOS expects the edges in string format, i.e. 'Left' | 'Right' | 'Up' | 'Down'
    () => props.trapExitEdges.map(edge => SupportedKeys[edge]),
    [props.trapExitEdges]
  );

  return <TVOSFocusParent ref={onRefReady} {...props} trapExitEdges={exitEdges} />;
};

const FocusParentNativeDefault: React.FC<FocusParentNativeProps> = ({
  style,
  enterStrategy,
  rememberLastFocused,
  trapFocus,
  trapExitEdges,
  holdFocus,
  active = true,
  onReady,
  focusPriority,
  debugName,
  children,
  debug = debugFeatures.focusParent
}) => {
  const focusParentId = useContext(FocusParentContext);
  const viewRef = useRef<View>(null);

  const omitGeometryCaching = useContext(NitroxInteractiveControllerContext).omitGeometryCaching;

  const getProps = useFunction(() => ({
    enterStrategy,
    rememberLastFocused,
    trapFocus,
    trapExitEdges,
    holdFocus,
    active,
    debugName
  }));

  const node = useLazyRef<FocusParentNode>(() => new FocusParentNode(getProps, viewRef));
  const focus = useDisposableCallback((id: number, enterStrategy?: EnterStrategy) => {
    FocusManager.getInstance().passFocusToParent(id, enterStrategy);
  }, []);

  const registeredFocusParentNode = useRef<FocusParentNode>();

  useLazyEffect(() => {
    return () => {
      registeredFocusParentNode.current && FocusManager.getInstance().unregisterNode(registeredFocusParentNode.current);
    };
  }, [], [focusParentId, onReady, focusPriority]);

  useChangeEffect(() => {
    FocusManager.getInstance().updateNodeParent(node.current, focusParentId);
  }, [focusParentId], [node]);

  useChangeEffect(() => {
    FocusManager.getInstance().updateNodePriority(node.current, focusPriority);
  }, [focusPriority], [node]);

  const {updateDebugGeometry, renderDebugger} = useFocusParentDebugger({
    node: node.current,
    debug,
    debugName
  });

  const onLayout = useCallback(() => {
    if (!registeredFocusParentNode.current && node.current) {
      registeredFocusParentNode.current = node.current; // local variable assigned here to unregister proper instance
      FocusManager.getInstance().registerNode(registeredFocusParentNode.current, {focusParentId, geometry: null, priority: focusPriority});
      const nodeId = registeredFocusParentNode.current?.id;
      if (nodeId) {
        onReady?.({
          focus: (enterStrategy?: EnterStrategy) => focus(nodeId, enterStrategy)
        });
      }
    }
    if (!omitGeometryCaching && node.current) {
      FocusManager.getInstance().updateNodeGeometry(node.current);
    }
    updateDebugGeometry();
  }, [focus, focusParentId, focusPriority, node, omitGeometryCaching, onReady, updateDebugGeometry]);

  return (
    <View
      ref={viewRef}
      style={style}
      onLayout={onLayout}

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onMouseEnter={updateDebugGeometry}
    >
      <FocusParentContext.Provider value={node.current.id}>
        {children}
      </FocusParentContext.Provider>
      {renderDebugger()}
    </View>
  );
};

const FocusParentNative = isTVOS ? FocusParentNativeTVOS : FocusParentNativeDefault;

export type FocusParentUtilityContextType = {focus?: () => void};
export const FocusParentUtilityContext = React.createContext<FocusParentUtilityContextType>({});
export function useFocusParentUtility() {
  return useContext(FocusParentUtilityContext);
}

type FocusParentReadyHandlerType = [Required<FocusParentProps>['onReady'], (enterStrategy?: EnterStrategy) => void];

export function useFocusParent(): FocusParentReadyHandlerType {
  const focusRef = useRef<(enterStrategy?: EnterStrategy) => void>(doNothing);
  const shouldFocusWhenReady = useRef(false);
  const isParentReady = useRef(false);

  const focus = useCallback((enterStrategy?: EnterStrategy) => {
    if (isParentReady.current) {
      focusRef.current?.(enterStrategy);
      shouldFocusWhenReady.current = false;
    } else {
      shouldFocusWhenReady.current = true;
    }
  }, []);

  const onParentReady = useCallback((params: {focus: (enterStrategy?: EnterStrategy) => void}) => {
    focusRef.current = params.focus;
    isParentReady.current = true;
    if (shouldFocusWhenReady.current) {
      focus();
    }
  }, [focus]);

  return [onParentReady, focus];
}

export type WithFocusParent<T extends string> = {[Label in T]: {
  onParentReady: FocusParentReadyHandlerType[0];
  focus: FocusParentReadyHandlerType[1];
}}

export const withFocusParent = <Y extends string>(label: Y) => <T extends WithFocusParent<Y>>(WrappedComponent: React.ComponentType<T>) => {
  type InnerT = Omit<T, keyof WithFocusParent<Y>>;
  const Wrapper: React.ComponentType<InnerT> = (props: InnerT, ref: React.RefObject<React.ComponentType<T>>) => {
    const [onParentReady, focus] = useFocusParent();
    const withFocusParentProps = useMemo(() => ({[label]: {onParentReady, focus}}), [focus, onParentReady]);
    const passDownProps = {
      ...props as T,
      ...withFocusParentProps as WithFocusParent<Y>,
      ref
    };
    return <WrappedComponent {...passDownProps} />;
  };
  Wrapper.displayName = `WithFocusParent<${label}>(${WrappedComponent.displayName})`;
  return React.forwardRef(Wrapper);
};

type FocusParentConsumedContexts = {
  isScreenBlurred: boolean;
}

/**
 * FocusParent component is a tool that makes it easy
 * to control focus behaviour declaratively.
 *
 * It's a component that wraps other FocusParents and
 * focusable elements (NitroxInteractive) creating a
 * hierarchy that is respected by both android/web
 * FocusManager as well as Apple TV's native focus engine.
 * FocusParent itself is not focusable, but defines
 * which one of its child should be focused by the engine.
 */

const FocusParent: React.FC<FocusParentProps & FocusParentConsumedContexts> = ({
  style,
  childFocusedStyle,
  enterStrategy,
  rememberLastFocused = defaultFocusParentProps.rememberLastFocused,
  trapFocus = defaultFocusParentProps.trapFocus,
  trapExitEdges = defaultFocusParentProps.trapExitEdges,
  holdFocus = defaultFocusParentProps.holdFocus,
  active,
  focusPriority = 0,
  onFocusEnter,
  onFocusEscape,
  onInternalFocusUpdate,
  onReady,
  debugName,
  debug = debugFeatures.focusParent,
  children,
  isScreenBlurred
}) => {
  const [onParentReady, focus] = useFocusParent();
  const focusUtility = useMemo<FocusParentUtilityContextType>(() => ({focus}), [focus]);

  const parentActive = active ?? !isScreenBlurred;

  const wrapperStyle = useMemo(() => [
    debug && debugStyles.focusOutside,
    style
  ], [debug, style]);

  const onWrapperReady = useCallback((params: {focus: () => void}) => {
    onParentReady(params);
    onReady?.(params);
  }, [onReady, onParentReady]);

  return (
    <FocusSwitch
      onFocusEnter={onFocusEnter}
      onFocusEscape={onFocusEscape}
      onInternalFocusUpdate={onInternalFocusUpdate}
    >
      <FocusParentUtilityContext.Provider
        value={focusUtility}
      >
        <Wrapper
          debug={debug}
          style={wrapperStyle}
          childFocusedStyle={childFocusedStyle}
          enterStrategy={enterStrategy}
          rememberLastFocused={rememberLastFocused}
          trapFocus={trapFocus}
          trapExitEdges={trapExitEdges}
          holdFocus={holdFocus}
          active={parentActive}
          onReady={onWrapperReady}
          focusPriority={focusPriority}
          debugName={debugName}
        >
          {children}
        </Wrapper>
      </FocusParentUtilityContext.Provider>
    </FocusSwitch>
  );
};
const MemoizedFocusParent = React.memo(FocusParent);
const FocusParentBigScreen: React.FC<FocusParentProps> = props => {
  const {inNavigationContext, isScreenFocused} = useIsScreenFocused();
  const isScreenBlurred = inNavigationContext && !isScreenFocused;

  return (
    <MemoizedFocusParent
      isScreenBlurred={isScreenBlurred}
      {...props}
    />
  );
};

const FocusParentMobile: React.FC<FocusParentProps> = ({children, style}) => <View style={style}>{children}</View>;

export default React.memo(isBigScreen ? FocusParentBigScreen : FocusParentMobile);
