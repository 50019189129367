import {FocusNode, isFocusParentNode} from './FocusManagerTypes';

export function focusNodeToString(node?: FocusNode) {
  const wrapLog = (s?: string) => `[FocusNode ${s}]`;
  if (!node) {
    return wrapLog();
  }
  if (isFocusParentNode(node)) {
    return node.toString();
  }
  if (!node.current) {
    return wrapLog();
  }
  const {props: {focusPriority, testID, debugName}} = node.current;
  const log = JSON.stringify({focusPriority, testID, debugName});

  return wrapLog(log);
}

export function isFocusable(node: FocusNode): boolean {
  return !isFocusParentNode(node) || !!node.getProps().active;
}
