import {createStyles} from 'common-styles';
import React, {memo, useCallback, useState} from 'react';
import {Animated, View} from 'react-native';

import {dimensions, menuFocusParentOffset} from 'common/constants';
import {humanCaseToSnakeCase} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxInteractive from 'components/NitroxInteractive';
import NitroxText from 'components/NitroxText';

import {STBMenuButtonProps} from './STBMenuButtonProps';

const TAG = 'STBMenuButton';

export const STBMenuButtonHeight = 76;

const styles = createStyles({
  button: {
    height: '100%',
    paddingTop: menuFocusParentOffset + dimensions.mainMenu.marginTop
  },
  buttonText: {
    alignItems: 'flex-start',
    flex: 1,
    justifyContent: 'center',
    height: '100%',
    width: '100%'
  },
  text: {
    textAlign: 'center'
  }
});

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  text: {
    ...styles.text,
    color: colors.mainMenu.button.text.active
  },
  disabledText: {
    ...styles.text,
    color: colors.mainMenu.button.text.disabled
  },
  focusLine: {
    position: 'absolute',
    backgroundColor: colors.mainMenu.button.background.focused,
    width: '100%',
    bottom: 0,
    height: dimensions.mainMenu.focusLineHeight,
    borderRadius: Math.floor(dimensions.mainMenu.focusLineHeight / 2)
  }
}));

const STBMenuButton: React.FunctionComponent<STBMenuButtonProps> = ({
  onPress: propagatePress,
  focusPriority,
  route,
  text,
  selected,
  disabled,
  style
}) => {

  const onPress = useCallback(() => {
    if (route) {
      propagatePress(route);
    } else {
      Log.error(TAG, `Menu button ${text} points to non existing route.`);
    }
  }, [propagatePress, route, text]);

  const [focused, onFocusStateChanged] = useState(false);
  const dynamicStyles = dynamicStylesUpdater.getStyles();

  const testID = `button_${humanCaseToSnakeCase(text)}`;

  return (
    <NitroxInteractive
      testID={testID}
      style={[styles.button, style]}
      activeOpacity={1}
      onFocusStateChanged={onFocusStateChanged}
      onPress={onPress}
      disabled={disabled}
      focusPriority={focusPriority}
    >
      <>
        {focused && (
          <View style={dynamicStyles.focusLine} />
        )}
        <Animated.View style={styles.buttonText}>
          <NitroxText
            style={disabled ? dynamicStyles.disabledText : dynamicStyles.text}
            numberOfLines={1}
            textType={selected ? 'label-medium' : 'label'}
          >
            {text}
          </NitroxText>
        </Animated.View>
      </>
    </NitroxInteractive>
  );
};

export default memo(STBMenuButton);
