import {createStyles} from 'common-styles';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {StyleSheet, View} from 'react-native';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxButton, {NitroxButtonTheme} from 'components/NitroxButton';
import NitroxText from 'components/NitroxText';
import {useBackPressCapture} from 'hooks/Hooks';
import {PostProcessors} from 'locales/i18nPostProcessors';

import FocusParent from './FocusParent';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: colors.mediaDetailsScreen.synopsis.background,
    alignItems: 'center',
    justifyContent: 'center'
  },
  content: {
    alignItems: 'center',
    width: 1230
  },
  titleText: {
    color: colors.mediaDetailsScreen.label,
    textAlign: 'center',
    marginTop: 78
  },
  synopsisText: {
    color: colors.mediaDetailsScreen.label,
    textAlign: 'justify',
    marginTop: 29,
    marginBottom: 41
  }
}));

type Props = {
  title: string;
  synopsis: string;
  onClose: () => void;
}

const FullSynopsisOverlay: React.FunctionComponent<Props> = props => {
  const {t} = useTranslation();

  useBackPressCapture(() => {
    props.onClose();
    return true;
  });

  const styles = stylesUpdater.getStyles();
  return (
    <FocusParent trapFocus style={StyleSheet.absoluteFill}>
      <View style={styles.overlay} testID='overlay_full_synopsis'>
        <View style={styles.content}>
          <NitroxText textType='title' style={styles.titleText} testID='title'>{props.title}</NitroxText>
          <NitroxText textType='body' style={styles.synopsisText} testID='synopsis'>{props.synopsis}</NitroxText>
          <NitroxButton
            text={t('common.close', {postProcess: PostProcessors.ToUpperCase})}
            theme={NitroxButtonTheme.Primary}
            onPress={props.onClose}
            hasTvPreferredFocus
            border
          />
        </View>
      </View>
    </FocusParent>
  );
};

export default FullSynopsisOverlay;
