import videojs from 'video.js';
// videojs-contrib-eme has no typings
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import videojsEmePlugin from 'videojs-contrib-eme';

import {DateUtils} from 'common/DateUtils';
import {isBrowser} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {ContentType} from 'mw/api/Metadata';
import {VideojsNitroxPlayerHls} from 'mw/playback/web-native-player/adapters/videojs/VideojsNitroxPlayerHls';

const TAG = 'VideojsNitroxPlayerHlsSafari';

if (isBrowser('safari')) {
  Log.info(TAG, 'Registering videojs-contrib-eme plugin.');
  videojs.registerPlugin('eme', videojsEmePlugin);
}

interface DateReferencePoint {
  position: number;
  date: number;
}

export class VideojsNitroxPlayerHlsSafari extends VideojsNitroxPlayerHls {
  private dateReferencePoint: DateReferencePoint | null = null;

  public setContentType(contentType: ContentType): void {
    if (this.contentType === ContentType.LIVE && contentType === ContentType.NPLTV) {
      this.dateReferencePoint = {
        // number of seconds since the beginning of the current playlist - typically around 1800s = 30min
        // to the end of the playlist (actual Live moment)
        position: this.getPlayer().liveTracker?.liveCurrentTime(),
        date: DateUtils.getSeconds(new Date())
      };
    }

    super.setContentType(contentType);
  }

  protected getHlsLivePlaylistStartDate(): number | null {
    // Because we do not have access to the dates we assume that the end of the playlist is playing NOW
    if (this.contentType === ContentType.LIVE) {
      return Math.round(DateUtils.getSeconds(new Date()) - this.getLiveWindow());
    } else if (this.contentType === ContentType.NPLTV && this.dateReferencePoint) {
      const diff = this.getPlayer().liveTracker?.liveCurrentTime() - this.dateReferencePoint.position;
      return this.dateReferencePoint.date + diff - this.getLiveWindow();
    }

    return null;
  }

  protected drmInit(): void {
    try {
      this.getPlayer().eme?.();
    } catch (error) {
      Log.error(TAG, 'Error initializing eme plugin:', error);
    }
  }
}
