import {defaultStyles} from 'common-styles';
import React from 'react';
import {ActivityIndicator, View} from 'react-native';

import EmptyBackgroundScreen from './EmptyBackgroundScreen';

const LoadingScreen: React.FunctionComponent = () => {
  return (
    <EmptyBackgroundScreen>
      <View style={defaultStyles.view}>
        <ActivityIndicator animating={true} size='large' />
      </View>
    </EmptyBackgroundScreen>
  );
};

export default LoadingScreen;
