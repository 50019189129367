// TODO CL-4669

export enum ComponentType {
  // legacy UXM api
  AdrenalinNode = 'adrenalin_node',
  Content = 'content',
  Custom = 'custom',
  Filterng = 'filterng',
  Menu = 'menu',

  // new UXM api

  /**
   * Regular swimlane of movies, events, etc.
   */
  Swimlane = 'swimlane',
  /**
   * Will be used to show regular grid of movies, events, etc
   */
  Grid = 'grid',
  /**
   * Will be used to show swimlane of movies, events, etc where focused element is displayed  in a special way
   */
  SwimlaneWithHighlightedBanner = 'swimlane-highlighted-banner',
  /**
   * Will be used to show a grid of elements linking to pages
   */
  GridMenu = 'grid-menu',
  /**
   * Will be used to show a swimlane of elements linking to pages
   */
  SwimlaneMenu = 'swimlane-menu',
  /**
   * Will be used to show promotional banner on top of VOD or Home Screen
   */
  Hotspot = 'hotspot',
  /**
   * Will be used to show promotional banner among swimlanes
   */
  Banner = 'banner',
  /**
   * A type of swimlane with vertical posters
   */
  SwimlaneBanner = 'swimlane-banner',
  /**
   * Events - a type of swimlane designed to show live events
   */
  SwimlaneLiveEvents = 'swimlane-live-events'
}

export type ComponentSettings = {
  bannerRotation?: number;
  itemsLimit?: number;
  tileShape?: 'rectangle' | 'circle';
  showName?: boolean;
  images?: {
    format?: string;
    height?: number;
    width?: number;
    type?: 'leading-image';
    url?: string;
  }[];
}

export enum ComponentDataSourceType {
  /**
   * List of ADR8 filters
   */
  Filters = 'filters',
  /**
   * One ADR8 filter (legacy)
   */
  Filter = 'filter',
  /**
   * List of ADR7 categories
   */
  Nodes = 'nodes',
  /**
   * One ADR7 category (legacy)
   */
  Node = 'node',
  /**
   * For formatted text
   */
  Content = 'content',
  /**
   * Custom
   */
  Custom = 'custom',
  /**
   * Link to an UXM menu
   */
  Menu = 'menu',
  /**
   * Link to an UXM page
   */
  Page = 'page',
  /**
   * Data is calculated by the client itself
   */
  SpecialFilter = 'specialFilter'
}

export type ComponentDataSourceSettings = {
  isPersonalized?: boolean;
  cacheTimeInMinutes?: number;
}
