import {MediaType, Playable, PlayableType, Location, Audio} from 'mw/api/Metadata';

import {MediaObject} from './MediaObject';

export class AudioObject extends MediaObject implements Audio {
  public location: Location;

  public constructor(id = '', name = '', url = '', fallback = false) {
    super(id, name);
    this.location = new Location(url, fallback);
  }

  public getType(): MediaType {
    return MediaType.Audio;
  }

  public getPlayable(): Playable | null {
    return this;
  }

  public getUrl(): string {
    return this.location.url;
  }

  public getPlayableType(): PlayableType {
    return PlayableType.Content;
  }

  public getId(): string {
    return this.id;
  }

  public isAllowed(): boolean {
    return true;
  }

  public hasFallbackPlayback(): boolean {
    return this.location.fallback;
  }
}
