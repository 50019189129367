import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, TextStyle, View, ViewStyle} from 'react-native';

import {dimensions, getValue, isBigScreen, RADIUS, isMobile} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxText from 'components/NitroxText';
import {PostProcessors} from 'locales/i18nPostProcessors';

export const minTagWidth = 45;

const posterTitlePositionHorizontalMargin = getValue({mobile: 6, defaultValue: 10});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  defaultTagContainer: {
    minWidth: minTagWidth,
    justifyContent: 'center',
    alignItems: 'center'
  },
  tagSizeSmall: {
    height: 20,
    borderRadius: RADIUS,
    paddingHorizontal: isMobile ? dimensions.margins.xsmall : dimensions.margins.small
  },
  tagSizeBig: {
    height: 40,
    borderRadius: 2 * RADIUS,
    paddingHorizontal: dimensions.margins.medium
  },
  tagStyleLive: {
    backgroundColor: colors.tags.live.background
  },
  tagLabelStyleLive: {
    color: colors.tags.live.text
  },
  tagStylePPV: {
    backgroundColor: colors.tags.ppv.background
  },
  tagLabelStylePPV: {
    color: colors.tags.ppv.text
  },
  tagStyleNewRecording: {
    backgroundColor: colors.tags.newRecording.background
  },
  tagLabelStyleNewRecording: {
    color: colors.tags.newRecording.text
  },
  tagDateTime: {
    backgroundColor: colors.tags.dateTime.background
  },
  tagPCRating: {
    backgroundColor: colors.tags.pcRating.background
  },
  tagLabelDateTime: {
    color: colors.tags.dateTime.text
  },
  tagLabelPCRating: {
    color: colors.tags.pcRating.text
  },
  posterTagContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: getValue({mobile: 15, defaultValue: 25}),
    borderTopRightRadius: RADIUS,
    borderBottomRightRadius: RADIUS
  },
  posterTagTextStyle: {
    paddingHorizontal: getValue({mobile: 8, defaultValue: 14})
  },
  titleTagContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: getValue({mobile: 18, defaultValue: 30}),
    marginHorizontal: posterTitlePositionHorizontalMargin,
    borderRadius: RADIUS
  },
  titleTagTextStyle: {
    paddingHorizontal: dimensions.margins.small
  }
}));

export enum TagTypes {
  LIVE = 'LIVE',
  PPV = 'PPV',
  NEW_RECORDING = 'NEW_RECORDING',
  DATE_TIME = 'DATE_TIME',
  PC_RATING = 'PC_RATING'
}

export enum TagPosition {
  Poster,
  Title,
  Default
}

export interface Tag {
  type: TagTypes;
  customText?: string;
}

export interface TagType {
  key: TagTypes;
  i18nKey?: string;
  style: ViewStyle;
  textStyle?: TextStyle;
}

interface NitroxTagProps {
  tag: Tag;
  style?: StyleProp<ViewStyle>;
  position?: TagPosition;
  tagSize?: 'big' | 'small'
}

const textTypeSmall = isBigScreen ? 'callout-micro' : 'callout-small-bold';
const textTypeBig = 'title3';
const posterTextType = 'poster-tag';

const liveTag = {
  type: TagTypes.LIVE
};

const ppvTag = {
  type: TagTypes.PPV
};

const newRecordingTag = {
  type: TagTypes.NEW_RECORDING
};

const pcRatingTag = {
  type: TagTypes.PC_RATING
};

export const Tags = {
  liveTag,
  ppvTag,
  newRecordingTag,
  pcRatingTag
};

const NitroxTag: React.FunctionComponent<NitroxTagProps> = props => {
  const {style, position = TagPosition.Default, tag, tagSize = 'small'} = props;
  const {t} = useTranslation();
  const styles = stylesUpdater.getStyles();

  const TagTypesObjects:{[key in keyof typeof TagTypes]: {style: ViewStyle, textStyle: TextStyle, i18nKey?: string}} = useMemo(() => ({
    LIVE: {
      i18nKey: 'tags.live',
      style: styles.tagStyleLive,
      textStyle: styles.tagLabelStyleLive
    },
    PPV: {
      i18nKey: 'tags.ppv',
      style: styles.tagStylePPV,
      textStyle: styles.tagLabelStylePPV
    },
    NEW_RECORDING: {
      i18nKey: 'tags.newRecording',
      style: styles.tagStyleNewRecording,
      textStyle: styles.tagLabelStyleNewRecording
    },
    DATE_TIME: {
      style: styles.tagDateTime,
      textStyle: styles.tagLabelDateTime
    },
    PC_RATING: {
      style: styles.tagPCRating,
      textStyle: styles.tagLabelPCRating
    }
  }), [styles.tagDateTime, styles.tagLabelDateTime, styles.tagLabelPCRating, styles.tagLabelStyleLive, styles.tagLabelStyleNewRecording, styles.tagLabelStylePPV, styles.tagPCRating, styles.tagStyleLive, styles.tagStyleNewRecording, styles.tagStylePPV]);

  const tagObject = TagTypesObjects[tag.type];
  if (!tagObject) {
    return null;
  }
  const displayedText = tag.customText || tagObject.i18nKey && t(tagObject.i18nKey, {postProcess: PostProcessors.ToUpperCase});

  switch (position) {
    case TagPosition.Poster:
      return (
        <View style={[styles.posterTagContainer, tagObject.style, style]}>
          <NitroxText textType={posterTextType} style={[styles.posterTagTextStyle, tagObject.textStyle]}>{displayedText}</NitroxText>
        </View>
      );
    case TagPosition.Title:
      return (
        <View style={[styles.titleTagContainer, tagObject.style, style]}>
          <NitroxText textType='title3' style={[styles.titleTagTextStyle, tagObject.textStyle]}>{displayedText}</NitroxText>
        </View>
      );
    default:
      return (
        <View style={style}>
          <View style={[styles.defaultTagContainer, tagSize === 'small' ? styles.tagSizeSmall : styles.tagSizeBig, tagObject.style]}>
            <NitroxText
              textType={tagSize === 'small' ? textTypeSmall : textTypeBig}
              style={[tagObject.textStyle]}
            >
              {displayedText}
            </NitroxText>
          </View>
        </View>
      );
  }
};

export default React.memo(NitroxTag);
