import {createStyles} from 'common-styles';
import React, {useRef, useMemo} from 'react';
import {Animated, Easing, ViewStyle} from 'react-native';

import {isBigScreen} from 'common/constants';
import {dimensions} from 'common/constants';
import {TestProps} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {constColors, BaseColors} from 'common-styles/variables/base-colors';

import {Icon, IconType} from 'components/Icon';
import NitroxInteractive from 'components/NitroxInteractive';
import {useChangeEffect, useToggle, useTestID} from 'hooks/Hooks';

type ToggleProps = {
  value: boolean;
  onPress: () => void;
  height?: number;
  width?: number;
  borderWidth?: number;
  activeColor?: string;
  inactiveColor?: string;
  knobColor?: string;
  disabled?: boolean;
} & TestProps

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  toggleActiveColor: colors.toggleSwitch.active,
  toggleInactiveColor: colors.toggleSwitch.notActive,
  toggleKnobColor: colors.toggleSwitch.knob,
  borderContainerBackground: colors.toggleSwitch.focused,
  iconColor: colors.toggleSwitch.icon
}));

const Toggle: React.FC<ToggleProps> = props => {
  const dynamicStyles = dynamicStylesUpdater.getStyles();
  const {
    value,
    onPress,
    borderWidth = isBigScreen ? dimensions.toggleSwitch.focused.borderWidth : 0,
    height = dimensions.toggleSwitch.height,
    width = dimensions.toggleSwitch.width,
    activeColor = dynamicStyles.toggleActiveColor,
    inactiveColor = dynamicStyles.toggleInactiveColor,
    knobColor = dynamicStyles.toggleKnobColor,
    disabled
  } = props;

  const [focused, {on: setFocusedOn, off: setFocusedOff}] = useToggle(false);

  const animation = useRef(new Animated.Value(value ? 1 : 0)).current;

  const {knobSize, containerPadding, iconSize, iconMargin, toggleHeight, toggleWidth} = useMemo(() => {
    const toggleHeight = height - 2 * borderWidth;
    const toggleWidth = width - 2 * borderWidth;
    const containerPadding = 0.1 * toggleHeight;
    const knobSize = toggleHeight - 2 * containerPadding;
    const iconSize = 0.3 * toggleHeight;
    const iconMargin = -0.15 * toggleHeight;
    return {knobSize, containerPadding, iconSize, iconMargin, toggleHeight, toggleWidth};
  }, [height, width, borderWidth]);

  const styles = useMemo(() => ({
    borderContainer: {
      height,
      width,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: height / 2,
      backgroundColor: focused ? dynamicStyles.borderContainerBackground : constColors.transparent
    } as ViewStyle,
    toggleContainer: {
      height: toggleHeight,
      width: toggleWidth,
      justifyContent: 'center',
      alignItems: 'flex-start',
      borderRadius: toggleHeight / 2,
      backgroundColor: animation.interpolate({
        inputRange: [0, 1],
        outputRange: [inactiveColor, activeColor]
      })
    },
    knob: {
      height: knobSize,
      width: knobSize,
      borderRadius: knobSize / 2,
      backgroundColor: knobColor,
      transform: [
        {
          translateX: animation.interpolate({
            inputRange: [0, 1],
            outputRange: [containerPadding, toggleWidth - knobSize - containerPadding]
          })
        }
      ]
    },
    clearIcon: {
      position: 'absolute',
      top: '50%',
      marginTop: iconMargin,
      left: '50%',
      marginLeft: iconMargin,
      opacity: animation.interpolate({
        inputRange: [0, 1],
        outputRange: [1, 0]
      })
    },
    checkIcon: {
      position: 'absolute',
      top: '50%',
      marginTop: iconMargin,
      left: '50%',
      marginLeft: iconMargin,
      opacity: animation.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1]
      })
    }
  }), [height, width, focused, dynamicStyles.borderContainerBackground, toggleHeight, toggleWidth, animation, inactiveColor, activeColor, knobSize, knobColor, containerPadding, iconMargin]);
  const testID = useTestID(props, 'Toggle') || 'toggle';

  useChangeEffect(() => {
    Animated.timing(animation, {
      duration: 200,
      toValue: +value,
      easing: Easing.linear
    }).start();
  }, [animation, value]);

  return (
    <NitroxInteractive
      onPress={onPress}
      activeOpacity={1}
      onFocus={setFocusedOn}
      onBlur={setFocusedOff}
      style={styles.borderContainer}
      disabled={disabled}
      testID={testID}
    >
      <Animated.View style={styles.toggleContainer}>
        <Animated.View style={styles.knob}>
          <Animated.View style={styles.clearIcon}><Icon type={IconType.ToggleOff} size={iconSize} color={dynamicStyles.iconColor} /></Animated.View>
          <Animated.View style={styles.checkIcon}><Icon type={IconType.ToggleOn} size={iconSize} color={dynamicStyles.iconColor} /></Animated.View>
        </Animated.View>
      </Animated.View>
    </NitroxInteractive>
  );
};

export default Toggle;
