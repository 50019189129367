import React, {useCallback, useState, useContext, useRef} from 'react';

import {Log} from 'common/Log';

import {Event, Media, isEvent} from 'mw/api/Metadata';
import {isChromecastSupported} from 'mw/platform/chromecast/ChromecastInterface';

import {ChromecastContext} from 'components/ChromecastContext';
import ContinueWatchingPopup from 'components/ContinueWatchingPopup';
import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';
import {PlayerLauncherProps, PlayerLauncherModalType, PlayerLauncherConfigProps, PlayerLauncherTag, WithPlayerLauncher, PlaybackDataProps, navigateToMediaPlayer, navigateToZapper, getPlayableRecording} from 'components/playerLauncher/PlayerLauncher.shared';
import UnlockModal from 'components/unlockmodal/UnlockModal';
import {useNavigation, useDisposable, useFunction} from 'hooks/Hooks';
import {TvScreenParams} from 'screens/tv/TvScreenHelperTypes';

const PlayerLauncher: React.FC<PlayerLauncherProps> = props => {
  const {
    visibleModal,
    bookmark,
    hideModals,
    hideUnlockModal,
    startPlaybackOfUnlockedMedia,
    startPlaybackFromBookmark,
    startPlaybackFromBeginning
  } = props;

  return (
    <>
      <UnlockModal
        type='adult'
        visible={visibleModal === PlayerLauncherModalType.ParentalControlUnlock}
        onSuccess={startPlaybackOfUnlockedMedia}
        onClose={hideUnlockModal}
      />
      <ContinueWatchingPopup
        visible={visibleModal === PlayerLauncherModalType.ContinueWatching}
        bookmark={bookmark}
        onPositive={startPlaybackFromBookmark}
        onNegative={startPlaybackFromBeginning}
        onClose={hideModals}
      />
    </>
  );
};

export function usePlayerLauncher(config?: PlayerLauncherConfigProps) {
  const shouldReplaceMediaPlayer = config?.shouldReplaceMediaPlayer ?? false;
  const [visibleModal, setVisibleModal] = useState(PlayerLauncherModalType.None);
  const navigation = useNavigation();
  const {castMedia, setMediaContext, isChromecastConnected} = useContext(ChromecastContext);
  const getRecording = useDisposable(async (event: Event) => getPlayableRecording(event));
  const mediaRef = useRef<Media>();
  const tvScreenParamsRef = useRef<TvScreenParams>();
  const unlockedRef = useRef<boolean | undefined>(false);
  const {isMediaBlocked, shouldBeCheckedForPC} = useParentalControl();
  const isBlocked = shouldBeCheckedForPC(mediaRef.current) && isMediaBlocked(mediaRef.current);

  const hideModals = useCallback(() => {
    setVisibleModal(PlayerLauncherModalType.None);
  }, []);

  const hideUnlockModal = useCallback(() => {
    if (visibleModal === PlayerLauncherModalType.ParentalControlUnlock) {
      setVisibleModal(PlayerLauncherModalType.None);
    }
  }, [visibleModal]);

  const openPlayer = useCallback(async (position?: number) => {
    hideModals();

    if (!mediaRef.current) {
      if (tvScreenParamsRef.current) {
        navigateToZapper({tvScreenParams: tvScreenParamsRef.current}, navigation, position);
      } else {
        Log.error(PlayerLauncherTag, 'Can\'t start playback - media not defined');
      }
      return;
    }

    if (isChromecastSupported()) {
      setMediaContext(mediaRef.current);
      try {
        if (isChromecastConnected) {
          castMedia(mediaRef.current, position || 0);
          return;
        }
      } catch (e) {
        // if chromecast connection exists, try casting to chromecast without any fallback
        if (isChromecastConnected) {
          Log.error(PlayerLauncherTag, 'Failed to cast media despite existing chromecast connection.', e);
          return;
          // if no chromecast connection established, then process to in-app playback as a fallback
        } else {
          Log.error(PlayerLauncherTag, 'No chromecast connection. Opening in-app player.', e);
        }
      }
    }

    if (isEvent(mediaRef.current)) {
      if (mediaRef.current.isRecorded) {
        const recording = await getRecording(mediaRef.current);
        if (!recording) {
          Log.error(PlayerLauncherTag, `Failed to find playable recording for event: ${mediaRef.current.id}`);
          return;
        }
        navigateToMediaPlayer(recording, navigation, shouldReplaceMediaPlayer, position);
      } else {
        navigateToZapper({media: mediaRef.current}, navigation, position);
      }
    } else {
      navigateToMediaPlayer(mediaRef.current, navigation, shouldReplaceMediaPlayer, position);
    }
  }, [hideModals, navigation, setMediaContext, isChromecastConnected, castMedia, getRecording, shouldReplaceMediaPlayer]);

  const startPlaybackFromBookmark = useFunction(() => {
    openPlayer(mediaRef.current?.bookmark || 0);
  });

  const startPlaybackFromBeginning = useFunction(() => {
    openPlayer();
  });

  const startPlaybackOfUnlockedMedia = useFunction(() => {
    if (mediaRef.current) {
      const continueWatchingPopupVisible = mediaRef.current.isPartiallyViewed();
      if (continueWatchingPopupVisible) {
        setVisibleModal(PlayerLauncherModalType.ContinueWatching);
      } else {
        startPlaybackFromBeginning();
      }
    } else if (tvScreenParamsRef.current) {
      startPlaybackFromBeginning();
    }
  });

  const runPlayback = useFunction(() => {
    if (!mediaRef.current && !tvScreenParamsRef.current) {
      return;
    }

    if (unlockedRef.current) {
      startPlaybackOfUnlockedMedia();
      return;
    }

    if (isBlocked) {
      setVisibleModal(PlayerLauncherModalType.ParentalControlUnlock);
    } else {
      startPlaybackOfUnlockedMedia();
    }
  });

  const startPlayback = useCallback((playbackData: PlaybackDataProps) => {
    const {media, unlocked, tvScreenParams} = playbackData;

    if (tvScreenParams) {
      tvScreenParamsRef.current = tvScreenParams;
    }

    if (media) {
      mediaRef.current = media;
    }

    unlockedRef.current = unlocked;
    runPlayback();
  }, [runPlayback]);

  const renderPlayerLauncherComponent = useCallback(() => (
    <PlayerLauncher
      visibleModal={visibleModal}
      bookmark={mediaRef.current?.bookmark || 0}
      hideModals={hideModals}
      hideUnlockModal={hideUnlockModal}
      startPlaybackOfUnlockedMedia={startPlaybackOfUnlockedMedia}
      startPlaybackFromBookmark={startPlaybackFromBookmark}
      startPlaybackFromBeginning={startPlaybackFromBeginning}
    />
  ), [hideModals, hideUnlockModal, startPlaybackFromBeginning, startPlaybackFromBookmark, startPlaybackOfUnlockedMedia, visibleModal]);

  return {renderPlayerLauncherComponent, startPlayback};
}

type WrappedProps<T> = Omit<T, keyof WithPlayerLauncher>;

export function withPlayerLauncher<Props extends WithPlayerLauncher>(WrappedComponent: React.ComponentType<Props>, config?: PlayerLauncherConfigProps): React.ComponentType<WrappedProps<Props>> {
  function Wrapped(props: WrappedProps<Props>) {
    const {renderPlayerLauncherComponent, startPlayback} = usePlayerLauncher(config);
    return (
      <WrappedComponent
        {...props as Props}
        renderPlayerLauncherComponent={renderPlayerLauncherComponent}
        startPlayback={startPlayback}
      />
    );
  }
  Wrapped.displayName = WrappedComponent.displayName;
  return Wrapped;
}
