import {EventEmitter} from 'common/EventEmitter';
import {Log} from 'common/Log';
import {withinBoundaries} from 'common/utils';

import {DeviceManager} from 'mw/api/DeviceManager';
import {Error} from 'mw/api/Error';
import {ContentType, PlayableType, Content} from 'mw/api/Metadata';
import {nxffConfig} from 'mw/api/NXFF';
import {PlayerEvent, MediaEvent} from 'mw/api/PlayerEvent';
import {boProxy} from 'mw/bo-proxy/BOProxy';
import {mw} from 'mw/MW';
import UserAgent from 'mw/platform/user-agent/UserAgent';
import {httpFetch, HttpMethods} from 'mw/utils/HttpUtils';

import {EventAdditionalParams, ReporterInterface, ReporterEvent, ReportingEvent} from './ReporterInterface';

const TAG = 'ToolboxReporter';

const reporterName = 'Toolbox';

const ToolboxVersion = 2; // 'It is important to send the version attribute with value: 2' - Toolbox Api Docs
const StreamingProgressInterval = 300000;

type HandledProgressPercentageValues = 10 | 25 | 50 | 75 | 95 | 99;

enum ToolboxUnityEvent {
  Start = 'start',
  Close = 'close',
  Pause = 'pause',
  Resume = 'resume',
  StartBuffering = 'start-buffering',
  ReBuffering = 're-buffering',
  Progressmark = 'progressmark',
  FirstQuartile = 'firstquartile',
  Midpoint = 'midpoint',
  ThirdQuartile = 'thirdquartile',
  Complete = 'complete',
  Ended = 'ended',
  StreamingProgress = 'StreamingProgress',
  PlaybackError = 'playbackError',
  Login = 'login',
  Logout = 'logout'
}

interface ToolboxRequestEvent {
  name: ToolboxUnityEvent; // Event Name.
  number: number; // Event order.
  value?: any; // Event value - depends on the event
}

interface ToolboxRequestBody {
  events: Array<ToolboxRequestEvent>;
  content?: {
    /** @param id - Content Id.*/
    id: string;
    /** @param  drmType - DRM Type */
    drmType?: string;
    /** @param formatType - Content Encoding Format Mime-Type */
    formatType?: string;
    /** @param  playbackUrl - URL of the content */
    playbackUrl?: string;
  };
  /** @param playback -  Playback information */
  playback?: {
    /** @param position - Current playback position */
    position: number;
    /** @param timeSpent - Current playback position */
    timeSpent: number;
    bitrate: number;
    /** @param resolution - Player's current content display resolution */
    resolution: {
      width: number;
      height: number;
    };
  };
  user?: {
    profiledId: string;
  };
  /** @param playerID - Player current unique identifier */
  playerID?: string;
  /** @param version - It is important to send the version attribute with value: 2 */
  version: 2;
}

interface ProgressIndicationEvent {
  wasSent: boolean;
  readonly name: ToolboxUnityEvent;
}

export class ToolboxReporter extends EventEmitter<ReportingEvent> implements ReporterInterface {
  private playerId = '';
  private jwtToken = '';
  private profileId = '';
  private contentUrl = '';
  private streamingProgressReportingTimer?: number;

  private progressIndicationEvents = new Map<HandledProgressPercentageValues, ProgressIndicationEvent>([
    [10, {
      wasSent: false,
      name: ToolboxUnityEvent.Progressmark
    }],
    [25, {
      wasSent: false,
      name: ToolboxUnityEvent.FirstQuartile
    }],
    [50, {
      wasSent: false,
      name: ToolboxUnityEvent.Midpoint
    }],
    [75, {
      wasSent: false,
      name: ToolboxUnityEvent.ThirdQuartile
    }],
    [95, {
      wasSent: false,
      name: ToolboxUnityEvent.Complete
    }],
    [99, {
      wasSent: false,
      name: ToolboxUnityEvent.Ended
    }]
  ]);

  public async handleLoginEvent(): Promise<void> {
    Log.debug(TAG, 'logging in');
    await this.init();
    this.sendRequest(this.prepareLoginLogoutBody('login'));
  }

  public handleLogoutEvent(): void {
    Log.debug(TAG, 'logging out');
    this.sendRequest(this.prepareLoginLogoutBody('logout'));
    this.playerId = '';
    this.jwtToken = '';
    this.profileId = '';
  }

  private canHandle(additionalParams: EventAdditionalParams): boolean {
    if (additionalParams.contentType !== ContentType.VOD) {
      return false;
    }

    if (additionalParams?.playable?.getPlayableType() === PlayableType.Content) {
      return (additionalParams?.playable as Content).reporter === reporterName;
    }

    return false;
  }

  public handleEvent(event: ReporterEvent, eventParams: any, additionalParams: EventAdditionalParams): void {
    if (!this.canHandle(additionalParams)) {
      return;
    }

    const content = additionalParams?.playable?.getPlayableType() === PlayableType.Content ? additionalParams?.playable as Content : null;
    const contentId = content?.externalIds?.ingestId;
    if (!contentId) {
      Log.error(TAG, 'Error ingestId not provided, it must be provided for every toolbox content');
      return;
    }

    Log.debug(TAG, 'scheduling events...');
    switch (event) {
      case PlayerEvent.Buffering:
        this.onBuffering(eventParams, contentId);
        break;
      case PlayerEvent.FirstFrame:
        this.onFirstFrame(contentId);
        break;
      case PlayerEvent.PositionChanged:
        this.onPositionChanged(eventParams, additionalParams, contentId);
        break;
      case PlayerEvent.ParametersChanged:
        this.onParametersChanged(eventParams, contentId);
        break;
      case PlayerEvent.Stopped:
        this.onStopped(contentId);
        break;
      case PlayerEvent.Error:
        this.onError(eventParams, contentId);
        break;
      default:
        break;
    }
  }

  private get isProperlyInitialised(): boolean {
    return !!(this.jwtToken && this.profileId && this.playerId);
  }

  private async init(): Promise<void> {
    const token = await boProxy.sso.getToken();
    this.jwtToken = token ? `JWT ${token}` : '';
    this.playerId = DeviceManager.getInstance().getId();
    this.profileId = mw.customer.mainProfile?.externalId ?? '';

    if (this.isProperlyInitialised) {
      Log.debug(TAG, `initializing with params: token: ${this.jwtToken}, playerId: ${this.playerId}, profileId: ${this.profileId}`);
    } else {
      Log.error(TAG, `Error Toolbox not initialized properly: token: ${this.jwtToken}, playerId: ${this.playerId}, profileId: ${this.profileId}`);
    }
  }

  private onBuffering({url}: MediaEvent, contentId: string): void {
    Log.debug(TAG, 'onBuffering event received');
    const initialBufferingEventSent = url === this.contentUrl;
    this.contentUrl = url;
    this.sendRequest(this.preparePlaybackBody(contentId, {
      name: initialBufferingEventSent ? ToolboxUnityEvent.ReBuffering : ToolboxUnityEvent.StartBuffering
    }));
  }

  private onFirstFrame(contentId: string): void {
    Log.debug(TAG, 'onReady event received');
    if (!this.streamingProgressReportingTimer) {
      this.startStreamingProgressReporting(contentId);
    }
    this.sendRequest(this.preparePlaybackBody(contentId, {
      name: ToolboxUnityEvent.Start
    }));
  }

  private onError(error: Error, contentId: string): void {
    Log.debug(TAG, 'onError event received');
    this.sendRequest(this.preparePlaybackBody(contentId, {
      name: ToolboxUnityEvent.PlaybackError,
      value: {
        code: error.type,
        description: error.message
      }
    }));
  }

  private onParametersChanged(params: any, contentId: string): void {
    Log.debug(TAG, 'onParametersChanged event received', params);
    const {playRate} = params;
    if (playRate != null) {
      this.sendRequest(this.preparePlaybackBody(contentId, {
        name: playRate ? ToolboxUnityEvent.Resume : ToolboxUnityEvent.Pause
      }));
    }
  }

  private onStopped(contentId: string): void {
    Log.debug(TAG, 'onStopped event received');
    this.clearReporter();
    this.sendRequest(this.preparePlaybackBody(contentId, {
      name: ToolboxUnityEvent.Close
    }));
  }

  private onPositionChanged(params: any, additionalParams: EventAdditionalParams, contentId: string): void {
    Log.debug(TAG, 'onPositionChanged event received', params);

    const progressPercentage = withinBoundaries(0, 100, Math.round(params.position / (additionalParams.playable as Content)?.duration * 100));
    this.progressIndicationEvents.forEach((event, eventThreshold) => {
      if (!event.wasSent && progressPercentage >= eventThreshold) {
        event.wasSent = true;
        this.sendRequest(this.preparePlaybackBody(contentId, {
          name: event.name
        }));
      }
    });
  }

  private startStreamingProgressReporting(contentId: string): void {
    this.streamingProgressReportingTimer = setInterval(() => {
      this.sendRequest(this.preparePlaybackBody(contentId, {
        name: ToolboxUnityEvent.StreamingProgress
      }));
    }, StreamingProgressInterval);
  }

  private stopStreamingProgressReporting(): void {
    this.streamingProgressReportingTimer && clearInterval(this.streamingProgressReportingTimer);
  }

  private clearReporter(): void {
    Log.debug(TAG, 'clearing reporter...');
    this.contentUrl = '';
    this.progressIndicationEvents.forEach((event) => {
      event.wasSent = false;
    });
    this.stopStreamingProgressReporting();
  }

  private prepareLoginLogoutBody(event: 'login' | 'logout'): ToolboxRequestBody {
    return {
      events: [{
        name: event === 'login' ? ToolboxUnityEvent.Login : ToolboxUnityEvent.Logout,
        number: 1
      }],
      user: {
        profiledId: this.profileId
      },
      playerID: this.playerId,
      version: ToolboxVersion
    };
  }

  private preparePlaybackBody(contentId: string, ...args: Array<Omit<ToolboxRequestEvent, 'number'>>): ToolboxRequestBody {
    const numeratedEvents: ToolboxRequestEvent[] = args.map<ToolboxRequestEvent>((event, index) => ({...event, number: index + 1}));
    return {
      events: numeratedEvents,
      content: {
        id: contentId
      },
      playerID: this.playerId,
      version: ToolboxVersion
    };
  }

  private async sendRequest(toolboxRequestBody: ToolboxRequestBody): Promise<void> {
    const url = nxffConfig.getConfig().Toolbox.ToolboxEventsURL;

    if (!(url && this.isProperlyInitialised)) {
      Log.error(TAG, 'Error Toolbox not initialized');
      return;
    }

    const headers: {[key: string]: string} = {
      'content-type': 'application/json',
      'Authorization': this.jwtToken
    };

    if (UserAgent.value) {
      headers['user-agent'] = UserAgent.value;
    }

    const response = await httpFetch(url, {
      method: HttpMethods.POST,
      body: JSON.stringify(toolboxRequestBody),
      headers
    });

    if (!response.ok) {
      Log.error(TAG, `Error sending Toolbox request. Response status: ${response.status}`);
    }
  }
}
