import {createStyles} from 'common-styles';
import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import {BlackPortal} from 'react-native-portal';

import {dimensions} from 'common/constants';
import {getMetadataFromMedia, getLongestSynopsis, getMediaSubtypes, getSportTeamsInfo} from 'common/HelperFunctions';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Media, isEvent} from 'mw/api/Metadata';

import {ChannelIcon, ChannelIconType} from 'components/ChannelIcon';
import FocusParent, {useFocusParentUtility} from 'components/FocusParent';
import FullSynopsisOverlay from 'components/FullSynopsisOverlay';
import {modalPortalName} from 'components/ModalPortal';
import NitroxInteractive from 'components/NitroxInteractive';
import NitroxText, {getFontStyle} from 'components/NitroxText';
import {useToggle, useChangeEffect} from 'hooks/Hooks';

import {mediaDetailHorizontalMargin} from './MediaDetailUtils';
import {MediaInfoViewComponentProps, useShouldDisplayNotEntitledLabel} from './MediaInfoView.shared';
import {TopMetadataView, BottomMetadataView} from './MetadataView';
import NotEntitledLabel from './NotEntitledLabel';
import ProgressBarView from './ProgressBarView';
import RecordingErrorLabel from './RecordingErrorLabel';
import RecordingLimitations from './RecordingLimitations';

const synopsisNumberOfLines = 3;
const synopsisTextType = 'body';
const synopsisLineHeight = getFontStyle(synopsisTextType).lineHeight ?? 0;
const titleNumberOfLines = 1;

const staticStyles = createStyles({
  view: {
    flexDirection: 'row'
  },
  contentView: {
    paddingHorizontal: mediaDetailHorizontalMargin,
    maxWidth: '100%',
    alignItems: 'flex-start'
  },
  titleContainer: {
    flexDirection: 'row',
    marginLeft: -mediaDetailHorizontalMargin,
    maxWidth: '100%'
  },
  channelIconContainer: {
    alignItems: 'center',
    marginTop: dimensions.margins.small,
    width: mediaDetailHorizontalMargin
  },
  topContainer: {
    width: '100%'
  },
  secondSubtitle: {
    marginBottom: dimensions.margins.small
  }
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  synopsis: {
    color: colors.mediaDetailsScreen.synopsis.text,
    marginTop: dimensions.margins.xsmall,
    overflow: 'hidden',
    height: synopsisNumberOfLines * synopsisLineHeight,
    width: dimensions.mediaInfoView.synopsis.width
  },
  textColorFocused: colors.overlayMediaDetails.text.focused,
  textColorUnFocused: colors.overlayMediaDetails.text.unfocused
}));

const getMediaTitle = (media: Media) => {
  const {episode} = getMediaSubtypes(media);
  return episode && episode.title ? episode.title : media.name;
};

const MediaInfoViewGrosso: React.FunctionComponent<MediaInfoViewComponentProps> = props => {
  const [focused, onFocusStateChanged] = useState(false);
  const styles = stylesUpdater.getStyles();
  const textColor = {color: focused ? styles.textColorFocused : styles.textColorUnFocused};
  const sportTeamsStyle = useMemo(() => [textColor, staticStyles.secondSubtitle], [textColor]);
  const [synopsisVisible, {on: openFullSynopsisOverlay, off: closeFullSynopsisOverlay}] = useToggle(false);
  const {focus: focusNearestParent} = useFocusParentUtility();
  const {t} = useTranslation();
  const title = useMemo(() => getMediaTitle(props.media), [props.media]);
  const metadata = useMemo(() => getMetadataFromMedia(props.media), [props.media]);
  const synopsis = useMemo(() => props.isBlocked ? t('unlock.detailPageLock') : getLongestSynopsis(metadata), [t, metadata, props.isBlocked]);
  const event = useMemo(() => isEvent(props.media) ? props.media : null, [props.media]);
  const sportTeams = useMemo(() => getSportTeamsInfo(props.media), [props.media]);

  useChangeEffect(() => {
    if (!synopsisVisible) {
      focusNearestParent?.();
    }
  }, [synopsisVisible], [focusNearestParent]);

  return (
    <View style={staticStyles.view}>
      <View style={staticStyles.contentView} testID='top_banner'>
        <View style={staticStyles.titleContainer}>
          <View style={staticStyles.channelIconContainer}>
            {!!event?.channelId && <ChannelIcon type={ChannelIconType.EventDetails} channelId={event.channelId} style={props.channelIconStyle} />}
          </View>
          <View style={staticStyles.topContainer}>
            <TopMetadataView media={props.media} textStyle={textColor} />
            <NitroxText testID={'title'} textType='title' style={textColor} numberOfLines={titleNumberOfLines} handleEllipsize>
              {title}
            </NitroxText>
            {!!sportTeams && <NitroxText textType='sportTeams' style={sportTeamsStyle} handleEllipsize>{sportTeams}</NitroxText>}
          </View>
        </View>
        {metadata && (
          <BottomMetadataView metadata={metadata} textStyle={textColor} media={props.media} />
        )}
        {useShouldDisplayNotEntitledLabel(props.media) && <NotEntitledLabel />}
        {props.recordingErrorCode && <RecordingErrorLabel errorCode={props.recordingErrorCode} />}
        {!!synopsis && (
          <FocusParent focusPriority={0.5} rememberLastFocused>
            <NitroxInteractive
              hasTVPreferredFocus={props.hasTVPreferredFocus}
              onFocusStateChanged={onFocusStateChanged}
              activeOpacity={1}
              onPress={openFullSynopsisOverlay}
              testID='description'
            >
              <NitroxText
                textType={synopsisTextType}
                style={[styles.synopsis, textColor]}
                numberOfLines={synopsisNumberOfLines}
              >
                {synopsis}
              </NitroxText>
            </NitroxInteractive>
          </FocusParent>
        )}
        <RecordingLimitations {...props.recordingLimitations} />
        <ProgressBarView progress={props.playbackProgress ?? 0} />
      </View>
      {synopsisVisible && (
        <BlackPortal name={modalPortalName}>
          <FullSynopsisOverlay
            title={title}
            synopsis={synopsis}
            onClose={closeFullSynopsisOverlay}
          />
        </BlackPortal>
      )}
    </View>
  );
};

export default React.memo(MediaInfoViewGrosso);
