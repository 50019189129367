import {compactMap} from 'common/HelperFunctions';
import {makeArray} from 'common/utils';

import {PlaybackAsset} from 'mw/bo-proxy/BOInterface';
import {ManagedBy} from 'mw/bo-proxy/SSOInterface';

import {mapDefaultRemoteAsset} from './DefaultRemoteAssetMapper';
import {RemoteAsset} from './MappersHelper';
import {mapViuRemoteAsset} from './ViuRemoteAssetMapper';

function mapRemoteAssetToPlaybackAsset(remoteAsset: RemoteAsset): PlaybackAsset | null {
  switch (remoteAsset.managedBy) {
    case ManagedBy.Viu:
      return mapViuRemoteAsset(remoteAsset);
    case ManagedBy.Toolbox:
    default:
      return mapDefaultRemoteAsset(remoteAsset);
  }
}

export function remoteAssetMapper(remoteAsset: RemoteAsset[]): PlaybackAsset[] {
  return compactMap(makeArray(remoteAsset), mapRemoteAssetToPlaybackAsset);
}
