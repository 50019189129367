import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ViewStyle, StyleProp} from 'react-native';

import {dimensions} from 'common/constants';
import {humanCaseToSnakeCase} from 'common/HelperFunctions';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {IconType} from 'components/Icon';
import IconRound, {defaultSize as defaultIconSize} from 'components/IconRound';
import NitroxInteractive from 'components/NitroxInteractive';
import NitroxText from 'components/NitroxText';

import {ProfileSwitcherActionType, ProfileSwitcherDataType} from './ProfileSwitcher.shared';

export const tileSize = defaultIconSize;

const staticStyles = createStyles({
  container: {
    width: tileSize
  }
});

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  profileName: {
    textAlign: 'center',
    marginTop: dimensions.margins.xsmall,
    color: colors.settingsScreen.mobile.profile.name
  },
  selectedBorder: {
    borderWidth: dimensions.tile.borderWidth,
    borderColor: colors.settingsScreen.mobile.profile.tile.borderColor
  }
}));

type ProfileSwitcherTileProps = {
  data: ProfileSwitcherDataType;
  selected?: boolean;
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
};

const ProfileSwitcherTile: React.FunctionComponent<ProfileSwitcherTileProps> = ({
  data,
  selected,
  style,
  onPress
}) => {
  const dynamicStyles = dynamicStylesUpdater.getStyles();
  const {t} = useTranslation();

  const iconStyle = useMemo(() => (
    selected ? dynamicStyles.selectedBorder : {}
  ), [selected, dynamicStyles]);

  const [iconType, label] = useMemo(() =>
    data === ProfileSwitcherActionType.AddNew
      ? [IconType.AddProfile, t('settings.addNew')]
      : [IconType.UserAvatar, data.name]
  , [data, t]);

  return (
    <NitroxInteractive
      style={[staticStyles.container, style]}
      onPress={onPress}
      testID={`tile_${humanCaseToSnakeCase(label)}`}
    >
      <IconRound type={iconType} style={iconStyle} />
      <NitroxText
        style={dynamicStyles.profileName}
        textType='profile-selector-item'
        numberOfLines={1}
        handleEllipsize
      >
        {label}
      </NitroxText>
    </NitroxInteractive>
  );
};

export default ProfileSwitcherTile;
