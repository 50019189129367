import {createStyles} from 'common-styles';
import i18next from 'i18next';
import React, {useCallback} from 'react';
import {View, ViewStyle} from 'react-native';
import {Omit} from 'react-navigation';

import {dimensions, isDesktopBrowser, isSTBBrowser} from 'common/constants';

import {Media} from 'mw/api/Metadata';

import AnimatedSwimlaneStackBase, {SwimlaneComponent} from 'components/AnimatedSwimlaneStackBase';
import MediaTile, {mediaTileMarginHorizontal} from 'components/mediaTiles/MediaTile';
import {useMediaTileFocusedStyle} from 'components/mediaTiles/MediaTileBase';
import {SwimlaneProps, SwimlaneTileProps, AnimatedTileProps} from 'components/Swimlane';

const swimlaneInsets = {
  left: dimensions.screen.container.paddingHorizontal - mediaTileMarginHorizontal
};

const styles = createStyles({
  container: {
    flex: 1,
    marginLeft: -swimlaneInsets.left - mediaTileMarginHorizontal
  }
});

const swimlaneHeight = dimensions.tile.height + dimensions.margins.xLarge;

type Props = {
  resultsNow: Media[];
  resultsRecordings: Media[];
  resultsPast: Media[];
  resultsFuture: Media[];
  style?: ViewStyle;
  onFirstTileMount?: () => void;
};

type SwimlaneRow = {
  title: string;
  data: Media[];
  dataFetcher: AsyncIterableIterator<Media[]>;
}

async function *dataFetcher(data: Media[]) {
  return data;
}

const SearchFullResultsTv = (props: Props) => {
  const {onFirstTileMount} = props;
  const swimlaneRows: SwimlaneComponent<Media>[] = [];

  if (props.resultsNow.length > 0) {
    swimlaneRows.push({
      title: i18next.t('search.now'),
      dataFetcher: dataFetcher(props.resultsNow)
    });
  }

  if (props.resultsRecordings.length > 0) {
    swimlaneRows.push({
      title: i18next.t('search.recordings'),
      dataFetcher: dataFetcher(props.resultsRecordings)
    });
  }

  if (props.resultsPast.length > 0) {
    swimlaneRows.push({
      title: i18next.t('search.past'),
      dataFetcher: dataFetcher(props.resultsPast)
    });
  }

  if (props.resultsFuture.length > 0) {
    swimlaneRows.push({
      title: i18next.t('search.future'),
      dataFetcher: dataFetcher(props.resultsFuture)
    });
  }

  const createTile = useCallback((tileProps: SwimlaneTileProps<Media>, animatedTileProps?: AnimatedTileProps) => {
    const onMount = (tileProps.index === 0 && tileProps.row === 0) ? onFirstTileMount : undefined;
    const tile = (focused: boolean) => (
      <MediaTile
        ref={animatedTileProps?.refHandler}
        media={tileProps.data}
        onFocus={tileProps.onFocus}
        onMount={onMount}
        staticallyFocused={focused}
      />
    );
    return tile;
  }, [onFirstTileMount]);

  const setupSwimlane = useCallback((row: number): Omit<SwimlaneProps<Media>, 'createTile'> => {
    return {
      header: swimlaneRows[row].title,
      row,
      insets: {
        left: swimlaneInsets.left,
        right: dimensions.margins.large
      },
      headerInsetLeft: mediaTileMarginHorizontal,
      renderNavigationArrows: isDesktopBrowser && !isSTBBrowser,
      itemWidth: dimensions.tile.width + 2 * dimensions.margins.small,
      itemHeight: dimensions.tile.height + dimensions.margins.small,
      dataFetcher: swimlaneRows[row]?.dataFetcher
    };
  }, [swimlaneRows]);

  const focusedTileFrameStyle = useMediaTileFocusedStyle();
  const renderFocusedTileFrame = useCallback(() => {
    return <View style={focusedTileFrameStyle} />;
  }, [focusedTileFrameStyle]);

  return (
    <View style={[styles.container, props.style]}>
      <AnimatedSwimlaneStackBase<Media>
        swimlaneItemHeight={swimlaneHeight}
        swimlaneComponents={swimlaneRows}
        setupSwimlane={setupSwimlane}
        createTile={createTile}
        renderFocusedTileFrame={renderFocusedTileFrame}
      />
    </View>
  );
};

export default React.memo(SearchFullResultsTv);
