import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Profile} from 'mw/api/Profile';

import {unlockModalPortalName} from 'components/ModalPortal';
import PinKeyboard from 'components/pinKeyboard/PinKeyboard';
import {UnlockModalProps} from 'components/unlockmodal/UnlockModalProps';
import {useChangeEffect, useCurrentProfile, useDisposable} from 'hooks/Hooks';

const TAG = 'UnlockPinKeyboard';

type UnlockPinKeyboardProps = Omit<UnlockModalProps, 'profile, type, visible'> & {
  selectedProfile: Profile;
  maxTries: number;
  onMaxTriesReached: () => void;
}

const UnlockPinKeyboard: React.FC<UnlockPinKeyboardProps> = ({
  onClose,
  onSuccess,
  selectedProfile,
  maxTries,
  onMaxTriesReached
}) => {
  const {t} = useTranslation();
  const currentProfile = useCurrentProfile();
  const [title, setTitle] = useState<string>('');
  const [tries, setTries] = useState(0);

  const checkPin = useDisposable((profile: Profile, pin: string) => profile.checkPin(pin));
  const validate = useCallback(async (pin: string) => {
    try {
      await checkPin(selectedProfile, pin);
      setTitle('');
      onSuccess(pin);
    } catch {
      setTitle(t('unlock.wrongPin'));
      setTries(tries => tries + 1);
    }
  }, [onSuccess, selectedProfile, t, checkPin]);

  useChangeEffect(() => {
    if (tries >= maxTries) {
      onMaxTriesReached();
      setTries(0);
      setTitle('');
    }
  }, [tries], [onMaxTriesReached, setTries, setTitle]);

  const selfAuthorization = currentProfile === selectedProfile;
  const defaultTitle = selfAuthorization
    ? t('unlock.enterCurrentProfilePin')
    : t('unlock.enterProfilePin', {profile: selectedProfile.name});

  return (
    <PinKeyboard
      onClose={onClose}
      onSubmit={validate}
      title={title || defaultTitle}
      portal={unlockModalPortalName}
      visible
    />
  );
};

export default UnlockPinKeyboard;
