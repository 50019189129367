import {createStyles} from 'common-styles';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {ImageBackground, StyleSheet, View} from 'react-native';

import {isBigScreen, isPhone} from 'common/constants';
import {Navigation} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {ErrorType} from 'mw/api/Error';
import {mw} from 'mw/MW';

import {assets} from 'brand/Resources';
import NitroxLogo from 'components/navigation/NitroxLogo';
import OrientationManager from 'components/OrientationManager';
import {useChangeEffect} from 'hooks/Hooks';
import SettingsRegisteredDevicesScreen from 'screens/settings/SettingsRegisteredDevicesScreen';

import DeviceRegistrationFailedPopup from './DeviceRegistrationFailedPopup';
import DeviceUnregisteredPopup from './DeviceUnregisteredPopup';
import TooManyDevicesPopup from './TooManyDevicesPopup';
import TooManyDeviceSwapsPopup from './TooManyDeviceSwapsPopup';

const TAG = 'DeviceRegistrationErrorHandler';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  imageBackground: {
    backgroundColor: colors.loginScreen.background,
    width: '100%',
    height: '100%'
  },
  logoContainer: {
    width: '100%',
    height: isBigScreen ? 100 : 80,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    flexBasis: '30%'
  },
  logo: {
    ...isBigScreen ? {
      height: 210,
      width: 540
    } : {
      height: 100
    }
  }
}));

type Params = {[key: string]: any};

class DummyNavigation implements Navigation {

  private readonly params: Params;
  public state = {
    index: 0,
    isTransitioning: false,
    key: '',
    params: undefined,
    routeName: '',
    routes: []
  };

  public constructor(params: Params) {
    this.params = params;
  }

  public dispatch() {
    return false;
  }

  public goBack() {
    return false;
  }

  public dismiss() {
    return false;
  }

  public navigate() {
    return false;
  }

  public openDrawer() {
  }

  public closeDrawer() {
  }

  public toggleDrawer() {
  }

  public getParam(param: any) {
    return this.params[param];
  }

  public setParams() {
    return false;
  }

  public addListener() {
    return {remove: () => {}};
  }

  public push() {
    return false;
  }

  public replace() {
    return false;
  }

  public pop() {
    return false;
  }

  public popToTop() {
    return false;
  }

  public isFocused() {
    return true;
  }

  public dangerouslyGetParent() {
    return undefined;
  }
}

enum PopupType {
  None,
  TooManyDevices,
  TooManyDeviceSwaps,
  DeviceRegistrationFailed,
  DeviceUnregistered,
  DeviceManagement
}

type Props = {
  error: ErrorType | null;
  logo?: boolean;
  onClose?: () => void;
}

const DeviceRegistrationErrorHandler: React.FC<Props> = props => {
  const {error, logo, onClose} = props;
  const [popup, setPopup] = useState<PopupType>(PopupType.None);
  const showDevicesManagement = useCallback(() => setPopup(PopupType.DeviceManagement), []);

  const handleError = useCallback((errorType: ErrorType) => {
    switch (errorType) {
      case ErrorType.TooManyDevices:
        setPopup(PopupType.TooManyDevices);
        break;
      case ErrorType.TooManyDeviceSwaps:
        setPopup(PopupType.TooManyDeviceSwaps);
        break;
      case ErrorType.DeviceUnregistered:
        setPopup(PopupType.DeviceUnregistered);
        break;
      default:
        setPopup(PopupType.DeviceRegistrationFailed);
        break;
    }
  }, []);

  const logout = useCallback(() => {
    setPopup(PopupType.None);
    mw.bo.logout();
  }, []);

  const onBackButtonPressed = useCallback(() => {
    logout();
    return true;
  }, [logout]);

  const register = useCallback(() => {
    setPopup(PopupType.None);
    mw.customer.registerDevice()
      .catch(error => {
        Log.error(TAG, 'Register device failed', error);
        handleError(error.type);
      });
  }, [handleError]);

  // 'dummy' navigation needed only to display SettingsRegisteredDevicesScreen as component and handle 'back' and 'register' buttons on it
  const settingsNavigation = useMemo(() => new DummyNavigation({onBackButtonPressed: onBackButtonPressed, register: register}), [onBackButtonPressed, register]);

  useEffect(() => {
    if (error != null) {
      handleError(error);
    }
  }, [error, handleError]);

  useChangeEffect(() => {
    if (popup === PopupType.None) {
      onClose?.();
    }
  }, [popup], [onClose]);

  const forcePortraitOrientation = isPhone && popup === PopupType.DeviceManagement;
  const styles = stylesUpdater.getStyles();
  return (
    <>
      {logo && (
        <ImageBackground source={assets.common.splashScreen} style={styles.imageBackground} resizeMode='cover'>
          <View style={styles.logoContainer}>
            <NitroxLogo
              pictureUrl={assets.common.loginScreenLogo}
              style={styles.logo}
            />
          </View>
        </ImageBackground>
      )}
      <TooManyDevicesPopup visible={popup === PopupType.TooManyDevices} logout={logout} showDeviceManagement={showDevicesManagement} />
      <TooManyDeviceSwapsPopup visible={popup === PopupType.TooManyDeviceSwaps} logout={logout} />
      <DeviceRegistrationFailedPopup visible={popup === PopupType.DeviceRegistrationFailed} logout={logout} register={register} />
      <DeviceUnregisteredPopup visible={popup === PopupType.DeviceUnregistered} register={register} logout={logout} />
      {popup === PopupType.DeviceManagement && (
        <View style={StyleSheet.absoluteFillObject}>
          <SettingsRegisteredDevicesScreen navigation={settingsNavigation} />
        </View>
      )}
      {forcePortraitOrientation && <OrientationManager navigation={settingsNavigation} allowedOrientations='portrait' />}
    </>
  );
};

export default DeviceRegistrationErrorHandler;
