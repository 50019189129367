import {useEffect} from 'react';
import {Image} from 'react-native';

import {useDisposableState, useFunction} from './Hooks';

/**
 * Returns width of the image given its height so that it maintains original aspect ratio.
 * @param height height of the image
 * @param imgUri image URL
 */
export function useFixedHeightImage(height: number, imgUri: string | undefined): {width: number, ratio: number} {
  const [width, setWidth] = useDisposableState(0);
  const [ratio, setRatio] = useDisposableState(0);

  const sizeCallback = useFunction((imgWidth: number, imgHeight: number, src: string) => {
    if (src !== imgUri) {
      // ignore results if img uri changed in the meantime
      return;
    }
    if (!imgWidth || !imgHeight) {
      setWidth(0);
      setRatio(0);
      return;
    }
    const ratio = imgWidth / imgHeight;
    setWidth(Math.round(ratio * height));
    setRatio(ratio);
  });

  useEffect(() => {
    if (!imgUri) {
      setWidth(0);
      setRatio(0);
      return;
    }
    Image.getSize(
      imgUri,
      (imgWidth, imgHeight) => sizeCallback(imgWidth, imgHeight, imgUri),
      () => {}
    );
  }, [imgUri, setWidth, setRatio, sizeCallback]);

  return {width, ratio};
}
