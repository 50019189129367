import {createStyles} from 'common-styles';
import React from 'react';
import {View, StyleProp, ViewStyle} from 'react-native';

import {getValue} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxText from './NitroxText';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  separatorText: {
    color: colors.vodScreen.navigator.separator
  },
  separatorContainer: {
    justifyContent: 'center',
    marginHorizontal: getValue({mobile: 11, defaultValue: 22})
  }
}));

type Props = {
  textStyle?: StyleProp<ViewStyle>;
}

const BreadcrumbsSeparator: React.FunctionComponent<Props> = props => {
  const styles = stylesUpdater.getStyles();
  return (
    <View style={styles.separatorContainer}>
      <NitroxText textType='headline' style={[styles.separatorText, props.textStyle]}>{'>'}</NitroxText>
    </View>
  );
};

export default BreadcrumbsSeparator;
