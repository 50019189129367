import {CreditCardFormProps} from './CreditCardForm';

export class StripeError extends Error {
  public readonly code?: string;
  public readonly type?: string;
  public readonly declineCode?: string;

  public constructor(message?: string, code?: string, type?: string, declineCode?: string) {
    super(message);
    this.code = code;
    this.type = type;
    this.declineCode = declineCode;
  }
}

export type StripeProps = {
  publishableKey: string;
  onPaymentSuccess: () => void;
  onPaymentError: (error: Error) => void;
} & Omit<CreditCardFormProps, 'cardNumberComponent' | 'cardExpiryComponent' | 'cardCvcComponent'>

export type PaymentParams = {
  clientSecret: string;
}

export interface StripeInterface {
  startPayment: (params: PaymentParams) => void;
}
