import {Offer} from 'mw/api/Metadata';

export class OfferObject implements Offer {
  public readonly id?: string;
  public readonly paymentMethodId: string;
  public readonly price: number;
  public readonly currency: string;
  public readonly rentalPeriod?: number;

  public constructor(id: string | undefined, paymentMethodId: string, price: number, currency: string, rentalPeriod?: number) {
    this.id = id;
    this.paymentMethodId = paymentMethodId;
    this.price = price;
    this.currency = currency;
    this.rentalPeriod = rentalPeriod;
  }

}
