import {createStyles} from 'common-styles';
import i18next from 'i18next';
import moment from 'moment';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, View, ViewStyle, TextStyle} from 'react-native';

import {isBigScreen} from 'common/constants';
import {DateUtils} from 'common/DateUtils';
import {formatGenres, getMediaSubtypes} from 'common/HelperFunctions';
import {TestProps} from 'common/HelperTypes';
import {getEpisodeAndSeasonNumber} from 'common/utils';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Event, isEvent, Metadata, Media} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import NitroxText, {getFontStyle, TextType} from 'components/NitroxText';
import {useTestID} from 'hooks/Hooks';
import {PostProcessors} from 'locales/i18nPostProcessors';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  metadata: {
    color: colors.overlayMediaDetails.subtitle.focused
  },
  separator: {
    backgroundColor: colors.overlayMediaDetails.subtitle.unfocused,
    borderRadius: 50,
    height: 5,
    width: 5,
    marginLeft: 5,
    marginRight: 5
  }
}));

type MetadataViewProps = {
  items: string[];
  textType: TextType;
  style?: StyleProp<ViewStyle>;
  textStyle?: TextStyle;
} & TestProps

const renderMetadataItem = (item: string, index: number, textType: TextType, textStyle?: TextStyle) => {
  const styles = stylesUpdater.getStyles();

  return (
    <View key={index} style={styles.item}>
      {index > 0 && <View style={styles.separator} />}
      <NitroxText textType={textType} style={[styles.metadata, textStyle]}>
        {item}
      </NitroxText>
    </View>
  );
};

const MetadataView: React.FunctionComponent<MetadataViewProps> = props => {
  const {items, textType, textStyle, style} = props;
  const styles = stylesUpdater.getStyles();

  const testID = useTestID(props, 'MetadataView') || 'metadata';

  return (
    <View style={[styles.container, style]} testID={testID}>
      {items.map((item: string, key: number) => renderMetadataItem(item, key, textType, textStyle))}
    </View>
  );
};

const formatDuration = (durationInSeconds: number) => {
  return moment.duration(durationInSeconds, 'seconds').format('h[h] m[m]');
};

const getEventTimeInfo = (t: i18next.TFunction, media: Event) => {
  const startTime = DateUtils.formatDate(media.start, mw.configuration.timeFormat);
  const endTime = DateUtils.formatDate(media.end, mw.configuration.timeFormat);

  if (!media.isRecorded) {
    return `${startTime} - ${endTime}`;
  }

  const date = DateUtils.formatDate(media.start, mw.configuration.dateFormat);
  const timeInfo = t(media.isPast ? 'recordings.recordedAt' : 'recordings.plannedAt', {time: startTime, date});
  const channel = mw.catalog.getChannelById(media.channelId);
  return channel?.name ? `${timeInfo} ${t('recordings.fromChannel', {channel: channel.name})}` : timeInfo;
};

const createTopMetadataItems = (t: i18next.TFunction, media: Media) => {
  const items: string[] = [];
  const {episode} = getMediaSubtypes(media);

  if (isEvent(media) && media.isNow) {
    items.push(t('mediaDetails.now', {postProcess: PostProcessors.ToUpperCase}));
  }

  if (episode?.seriesName) {
    items.push(episode.seriesName);
  }

  if (episode?.seasonNumber) {
    items.push(t('common.seasonNumberFull', {seasonNumber: episode.seasonNumber.toString()}));
  }

  return items;
};

const createBottomMetadataItems = (t: i18next.TFunction, metadata: Metadata, media?: Media, availableUntil?: Date) => {
  const items: string[] = [];

  const ratings = media?.pcRatings[0]?.value;
  if (ratings) {
    items.push(ratings);
  }

  const episodeInfo = media ? getEpisodeAndSeasonNumber(media, {showSeason: false}) : undefined;
  if (episodeInfo) {
    items.push(episodeInfo);
  }

  if (metadata.genres.length > 0) {
    items.push(formatGenres(metadata.genres));
  }

  if (metadata.productionYear > 0) {
    items.push(metadata.productionYear.toString());
  }

  if (isEvent(media)) {
    const timeInfo = getEventTimeInfo(t, media);
    items.push(timeInfo);
  } else if (metadata.duration > 0) {
    items.push(formatDuration(metadata.duration));
  }

  if (availableUntil) {
    items.push(availableUntil.toDateString());
  }

  return items;
};

type TopMetadataViewProps = {
  media: Media;
  textStyle?: TextStyle;
  style?: StyleProp<ViewStyle>;
}

export const TopMetadataView: React.FunctionComponent<TopMetadataViewProps> = React.memo(props => {
  const {media, textStyle, style} = props;
  const {t} = useTranslation();
  const items = createTopMetadataItems(t, media);

  return (
    <MetadataView
      testID='season_info'
      items={items}
      textType={'subhead'}
      style={style}
      textStyle={textStyle}
    />
  );
});

type BottomMetadataViewProps = {
  media?: Media;
  metadata: Metadata;
  availableUntil?: Date;
  style?: StyleProp<ViewStyle>;
  textStyle?: TextStyle;
}

export const BottomMetadataView: React.FunctionComponent<BottomMetadataViewProps> = React.memo(props => {
  const {media, metadata, availableUntil, style: propsStyle, textStyle} = props;
  const {t} = useTranslation();
  const textType = isBigScreen ? 'callout' : 'callout-small';
  const itemHeight = getFontStyle(textType).lineHeight ?? 0;
  const style = useMemo(() => {
    return [
      isBigScreen ? {height: itemHeight} : {},
      propsStyle
    ];
  }, [itemHeight, propsStyle]);
  const items = useMemo(() => createBottomMetadataItems(t, metadata, media, availableUntil), [availableUntil, media, metadata, t]);

  return (
    <MetadataView
      items={items}
      textType={textType}
      style={style}
      textStyle={textStyle}
    />
  );
});
