import {encode} from 'base-64';
import jose from 'node-jose';

import {Log} from 'common/Log';

import {Error, ErrorType} from 'mw/api/Error';

const TAG = 'sso/Utils';

interface JWKKey {
  x5c: string[];
}

export class Utils {

  public static createAuthorizationHeader(clientId: string, clientPassword: string): string {
    return `Basic ${encode(escape(clientId) + ':' + escape(clientPassword))}`;
  }

  public static getSeaChangeAuthorizationHeader(tokenId: string, classType: string): string {
    return `SeacToken token="${tokenId}" class="${classType}"`;
  }

  public static async verifyToken(token: string, key: JWKKey): Promise<jose.JWS.VerificationResult> {
    const functionName = 'verifyTokenUsingJWK';

    if (!key.x5c.length) {
      throw new Error(ErrorType.JWTInvalidKey, `Invalid key: ${key}`);
    }

    Log.debug(TAG, `${functionName} using key:`, key);

    const pem = Utils.certToPEM(key.x5c[0]);
    try {
      const decoded = await Utils.verify(token, pem);
      Log.debug(TAG, `${functionName} token is valid:`, decoded);
      return decoded;
    } catch ({message}) {
      Log.debug(TAG, `${functionName} token is invalid`);
      throw new Error(ErrorType.JWTInvalidToken, message);
    }
  }

  private static certToPEM(cert: string): string {
    const tmpArray = cert.match(/.{1,64}/g);
    return tmpArray ? '-----BEGIN CERTIFICATE-----\n' + tmpArray.join('\n') + '\n-----END CERTIFICATE-----\n' : '';
  }

  private static async verify(token: string, pemKey: string) {
    return jose.JWS.createVerify(
      await jose.JWK.asKey(pemKey, 'pem'),
      {algorithms: ['RS256']}
    ).verify(token);
  }
}
