import {Hashmap} from 'common/HelperTypes';
import {makeArray} from 'common/utils';

import {SeriesType, Metadata, Title, TitleType} from 'mw/api/Metadata';
import {SeriesObject} from 'mw/metadata/SeriesObject';

import {MetadataMapper} from './MetadataMapper';
import {PictureMapper} from './PictureMapper';
import {SeasonMapper} from './SeasonMapper';
import {TitleMapper} from './TitleMapper';

const emptySeries = new SeriesObject('', '', new Metadata(), SeriesType.Series);

export class SeriesMapper {
  public static fromJSON(json: any) {
    if (!json) {
      return emptySeries;
    }
    const hasParentSeries = json.ParentSeriesCount > 0;
    const series = new SeriesObject(json.id, json.Name, MetadataMapper.fromJSON(json), hasParentSeries ? SeriesType.Season : SeriesType.Series);
    series.pictures = PictureMapper.toPictures(json.Pictures ? json.Pictures.Picture : []);
    series.seasonNumber = json.RelationOrdinal || json.Ordinal;
    series.seasons = makeArray(json.ChildSeriesCollection && json.ChildSeriesCollection.Series).map(SeriesMapper.fromJSON);
    series.seasonsCount = json.ChildSeriesCount || series.seasons.length;
    series.isAdult = !!json.IsAdult;

    const parentSeriesJson = json.ParentSeriesCollection?.Series?.[0];
    if (parentSeriesJson) {
      const parentSeries = SeriesMapper.fromJSON(parentSeriesJson);

      if (!series.pictures.length) {
        series.pictures = parentSeries.pictures;
      }

      if (json.IsAdult === undefined) {
        series.pcRatings = parentSeries.pcRatings;
      }

      if (typeof series.seasonNumber !== 'number') {
        series.seasonNumber = parentSeries.seasonNumber;
      }
    }

    return series;
  }

  public static extractViewedSeriesTitlesMap(response: any, limit: number): Hashmap<Title> {
    const episodes: unknown[] = makeArray(response?.Titles?.Title);
    const results: Hashmap<Title> = {};
    episodes.forEach((episode: unknown) => {
      if (Object.keys(results).length >= limit || !episode) {
        return;
      }
      const title = TitleMapper.fromJSON(episode, TitleType.VOD);
      if (!title.episode?.seasonId) {
        return;
      }

      if (title.episode.seriesId) {
        if (!results[title.episode.seriesId]) {
          results[title.episode.seriesId] = title;
        }
        return;
      }
      if (!results[title.episode.seasonId]) {
        results[title.episode.seasonId] = title;
      }
    });
    return results;
  }

  public static findLastNotViewedCompletelyEpisode(episodes: Title[]): Title | null {
    for (let i = episodes.length - 1; i >= 0; i--) {
      if (episodes[i].isViewedCompletely) {
        const nextEpisodeIndex = i + 1;
        if (nextEpisodeIndex < episodes.length) {
          return episodes[nextEpisodeIndex];
        }
        break;
      }
    }
    return null;
  }

  public static getTitlesFromJSON(seriesJson: any): Title[] {
    return makeArray(seriesJson?.ChildSeriesCollection?.Series).reduce((previous: Title[], seasonJson: any) => {
      previous.push(...SeasonMapper.getTitlesFromJSON(seasonJson));
      return previous;
    }, []);
  }
}
