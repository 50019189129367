import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {View, StyleProp, ViewStyle, TextStyle} from 'react-native';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import BackButton, {backButtonSize, backButtonTop, backButtonLeft} from 'components/BackButton';

import FocusParent from './FocusParent';
import NitroxText from './NitroxText';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  title: {
    color: colors.screenHeader.title,
    position: 'absolute'
  }
}));

const staticStyles = createStyles({
  container: {
    height: dimensions.screen.header.height,
    justifyContent: 'center'
  }
});

export enum BigScreenTitlePosition {
  Left,
  Center
}

export enum BigScreenBackButtonPosition {
  Default,
  Left
}

export interface BigScreenHeaderProps {
  title?: string;
  titlePosition?: BigScreenTitlePosition;
  style?: StyleProp<ViewStyle>;
  showBackButton?: boolean;
  backButtonPosition?: BigScreenBackButtonPosition;
}

const BigScreenHeader: React.FC<BigScreenHeaderProps> = ({
  title,
  style,
  titlePosition = BigScreenTitlePosition.Center,
  showBackButton,
  backButtonPosition = BigScreenBackButtonPosition.Default
}) => {
  const styles = stylesUpdater.getStyles();
  const Wrapper = showBackButton ? FocusParent : View;

  const {backButtonStyle, titleTextstyle} = useMemo(() => ({
    backButtonStyle: {
      ...(backButtonPosition === BigScreenBackButtonPosition.Left) && {
        left: 0
      }
    },
    titleTextstyle: {
      ...styles.title,
      ...showBackButton && {
        top: backButtonTop + dimensions.margins.xsmall,
        height: backButtonSize
      },
      ...(showBackButton && backButtonPosition === BigScreenBackButtonPosition.Default) && {
        marginLeft: backButtonLeft
      },
      ...(titlePosition === BigScreenTitlePosition.Left) ? {
        textAlign: 'left',
        left: backButtonSize + dimensions.margins.large
      } : {
        textAlign: 'center',
        left: 0,
        right: 0
      }
    } as TextStyle
  }), [backButtonPosition, titlePosition, showBackButton]);

  return (
    <Wrapper style={[staticStyles.container, style]}>
      {title != null && (
        <NitroxText
          textType='headline'
          style={titleTextstyle}
          upperCase
        >
          {title}
        </NitroxText>
      )}
      {showBackButton && <BackButton hasTVPreferredFocus style={backButtonStyle} />}
    </Wrapper>
  );
};

export default React.memo(BigScreenHeader);
