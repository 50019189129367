import {createStyles} from 'common-styles';
import React, {useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions} from 'common/constants';
import {doNothing} from 'common/HelperFunctions';

import {mw} from 'mw/MW';

import {IconType} from 'components/Icon';
import IconRound from 'components/IconRound';
import {ModalSelect, ModalSelectOption} from 'components/ModalSelect';
import {ProfileSelectProps} from 'components/ProfileSelect.shared';
import {useDisposableCallback} from 'hooks/Hooks';
import {SettingsLogoutButton} from 'screens/settings/SettingsList';

const styles = createStyles({
  containerStyle: {
    alignItems: 'center'
  },
  buttonStyle: {
    paddingHorizontal: dimensions.margins.large,
    minWidth: dimensions.inputs.width.settings
  },
  logoutButtonContainer: {
    alignItems: 'center',
    paddingHorizontal: dimensions.margins.large
  },
  logoutButton: {
    width: '100%',
    maxWidth: dimensions.inputs.width.settings
  }
});

function onLogoutPress() {
  mw.bo.logout();
}

export const ProfileSelectPiccolo: React.FC<ProfileSelectProps> = props => {
  const {
    profiles,
    onClose: propsOnClose,
    onSelect,
    fullscreen,
    hasLogoutButton,
    markCurrent = true,
    title,
    visible,
    portal,
    currentProfile
  } = props;

  const {t} = useTranslation();

  const options: ModalSelectOption<string>[] = useMemo(() => profiles.map(profile => ({
    value: profile.id,
    label: `${profile.name}${profile.isMain ? t('common.masterProfileSuffix') : ''}`,
    key: profile.id
  })), [profiles, t]);

  const renderOptionIcon = useCallback((value: string) => {
    return <IconRound type={IconType.UserAvatar} size={dimensions.icon.small} />;
  }, []);

  const onClose = useDisposableCallback(propsOnClose ?? doNothing);

  return (
    <ModalSelect
      options={options}
      defaultValue={currentProfile && currentProfile.id}
      visible={visible}
      fullscreen={fullscreen}
      swipeable={!fullscreen}
      onChange={onSelect}
      onClose={onClose}
      portal={portal}
      selectedOptionIcon={markCurrent ? IconType.Check : undefined}
      title={title ?? t('common.switchProfile')}
      renderOptionIcon={renderOptionIcon}
      bottomSection={hasLogoutButton && (
        <View style={styles.logoutButtonContainer}>
          <SettingsLogoutButton key='logout' style={styles.logoutButton} onPress={onLogoutPress} />
        </View>
      )}
    />
  );
};
