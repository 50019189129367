import React, {useState, useCallback, useContext, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View, ActivityIndicator, Keyboard} from 'react-native';
import {NavigationContext} from 'react-navigation';

import {isBigScreen, dimensions, isPhone, defaultPageSize} from 'common/constants';
import {doNothing, asyncIterator, openMediaDetails} from 'common/HelperFunctions';
import {Log} from 'common/Log';
import {isAsyncIterator} from 'common/utils';

import {Error as MWError, ErrorType, MWForbiddenTransactionError} from 'mw/api/Error';
import {Media, isTitle, isEvent, isSeries, PurchaseMethod} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import ErrorPopup, {PopupError} from 'components/ErrorPopup';
import {IconType} from 'components/Icon';
import FocusableTextInput from 'components/inputs/FocusableTextInput';
import {mediaDetailSwimlaneInsets} from 'components/mediadetails/MediaDetailUtils';
import MediaTile from 'components/mediaTiles/MediaTile';
import StaticTile from 'components/mediaTiles/StaticTile';
import {getTransactionErrorMessage} from 'components/payments/PaymentHelperFunctions';
import Popup from 'components/Popup';
import {PopupAction} from 'components/Popup';
import {SwimlaneTileProps, Swimlane} from 'components/Swimlane';
import {useDisposable, useLazyEffect} from 'hooks/Hooks';

import MobileVoucherRedeemTile from './MobileVoucherRedeemTile';
import {voucherRedeemStyles as styles, voucherRedeemStylesUpdater as stylesUpdater, popupWidth, singleElementSwimlaneWidth, swimlaneItemWidth, swimlaneItemHeight} from './VoucherRedeem.style';
import {VoucherState, VoucherRedemptionProps} from './VoucherRedeemProps';

const TAG = 'VoucherRedeem';

const transitionTimeout = 100;
const mediaTileConfig = {infoSection: ''};
const actions = {
  [VoucherState.Activated]: [PopupAction.NEGATIVE],
  [VoucherState.RedeemError]: [PopupAction.POSITIVE, PopupAction.NEGATIVE],
  [VoucherState.ForbiddenTransactionError]: [],
  [VoucherState.Loading]: [PopupAction.NEGATIVE],
  [VoucherState.Redeem]: [PopupAction.POSITIVE, PopupAction.NEGATIVE]
};
const titles = {
  [VoucherState.Activated]: 'voucherRedemption.titles.activated',
  [VoucherState.RedeemError]: 'voucherRedemption.titles.error',
  [VoucherState.ForbiddenTransactionError]: 'voucherRedemption.titles.forbiddenTransactionError',
  [VoucherState.Loading]: 'voucherRedemption.titles.redeem',
  [VoucherState.Redeem]: 'voucherRedemption.titles.redeem'
};
const subtitles = {
  [VoucherState.Activated]: 'voucherRedemption.subtitles.activated',
  [VoucherState.RedeemError]: (error: MWError) => {
    switch (error.type) {
      case ErrorType.HttpUnprocessableEntity:
        return 'voucherRedemption.subtitles.error';
      default:
        return 'common.unexpectedError';
    }
  },
  [VoucherState.ForbiddenTransactionError]: () => 'common.errorCode',
  [VoucherState.Loading]: '',
  [VoucherState.Redeem]: 'voucherRedemption.subtitles.redeem'
};

const VoucherRedeem: React.FunctionComponent<VoucherRedemptionProps> = ({onClose}) => {
  const {t} = useTranslation();
  const dynamicStyles = stylesUpdater.getStyles();
  const [currentState, setCurrentState] = useState<VoucherState>(VoucherState.Redeem);
  const [redemptionCode, setRedemptionCode] = useState<string>('');
  const [singleElement, setSingleElement] = useState(false);
  const [error, setError] = useState<MWError>();
  const [dataFetcher, setDataFetcher] = useState<AsyncIterableIterator<Media[]>>();
  const navigation = useContext(NavigationContext);
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  useLazyEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener('keyboardWillShow', e => setKeyboardHeight(e.endCoordinates.height));
    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => setKeyboardHeight(0));

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
    };
  }, [], [currentState]);

  const renderMediaTile = useCallback(({onFocus, data, index}: SwimlaneTileProps<Media>) => {
    const closeHandler = () => {
      onClose?.();

      if (isTitle(data) || isEvent(data) || isSeries(data)) {
        // Timeout isn't necessary at all, however with it, transition between close and open details is smoother and there is no sense of flashing screen
        setTimeout(() => openMediaDetails(navigation, data.id, data.getType()), transitionTimeout);
      }
    };

    return isBigScreen
      ? <MediaTile media={data} onFocus={onFocus} onPress={closeHandler} config={mediaTileConfig} />
      : <MobileVoucherRedeemTile key={data.id} onPress={closeHandler} media={data} index={index} />;
  }, [navigation, onClose]);

  const createLoadingPlaceholder = useCallback(() => <StaticTile onPress={doNothing} empty />, []);

  const getTitle = useCallback(() => t(titles[currentState]), [currentState, t]);

  const getSubtitle = useCallback(() => {
    const translationKey = subtitles[currentState];
    if (typeof translationKey === 'string') {
      return t(translationKey);
    }
    if (error && typeof translationKey === 'function') {
      return t(translationKey(error), {errorCode: error.type});
    }
  }, [currentState, error, t]);

  const getMessage = useCallback(() => (
    error ? getTransactionErrorMessage(t, error) : ''
  ), [t, error]);

  const getIcon = useCallback(() => {
    switch (error?.type) {
      case ErrorType.TransactionLocationForbidden:
        return IconType.ErrorGeographic;
      case ErrorType.TransactionVPNForbidden:
        return IconType.ErrorConfiguration;
      default:
        return undefined;
    }
  }, [error]);

  const forbiddenTransactionError = useMemo<PopupError | undefined>(() =>
    currentState === VoucherState.ForbiddenTransactionError
      ? {
        title: getTitle(),
        subtitle: getSubtitle(),
        message: getMessage(),
        icon: getIcon()
      }
      : undefined
  , [currentState, getTitle, getSubtitle, getMessage, getIcon]);

  const tryAgainHandler = () => setCurrentState(VoucherState.Redeem);

  const redeemCode = useDisposable(async () => {
    Log.debug(TAG, `Voucher redeem code: ${redemptionCode}`);
    try {
      setCurrentState(VoucherState.Loading);
      const {result} = await mw.catalog.purchase(PurchaseMethod.Voucher, {voucher: redemptionCode, load: true, queryParameters: {pageSize: defaultPageSize}});
      if (!result) {
        return;
      }
      setCurrentState(VoucherState.Activated);
      if (isAsyncIterator(result)) { // type of result is AsyncIterableIterator<Media[]>
        setDataFetcher(result);
      } else { // type of result is Media
        setSingleElement(true);
        setDataFetcher(() => asyncIterator([result]));
      }
    } catch (error) {
      Log.debug(TAG, `Error on code redeem ${error}`);
      setCurrentState(error instanceof MWForbiddenTransactionError ? VoucherState.ForbiddenTransactionError : VoucherState.RedeemError);
      setError(error);
    }
  });

  if (forbiddenTransactionError) {
    return (
      <ErrorPopup error={forbiddenTransactionError} onClose={onClose} />
    );
  }

  return (
    <Popup
      visible
      title={getTitle()}
      subtitle={getSubtitle()}
      subtitleStyle={dynamicStyles.subtitle}
      subtitleType={isBigScreen ? 'callout' : 'options-texts'}
      actions={actions[currentState]}
      positiveLabel={t(`voucherRedemption.buttons.${currentState === VoucherState.Redeem ? 'activate' : 'tryAgain'}`)}
      negativeLabel={t('voucherRedemption.buttons.close')}
      onModalClose={onClose}
      onNegative={onClose}
      onPositive={currentState === VoucherState.Redeem ? redeemCode : tryAgainHandler}
      containerStyle={[styles.container, {bottom: keyboardHeight}]}
      menuStyle={styles.menuStyle}
      highlightPositiveButton={isBigScreen}
      menuButtonStyle={styles.menuButtonStyle}
      menuHasPreferredFocus
      width={popupWidth}
    >
      {(currentState === VoucherState.Redeem || currentState === VoucherState.RedeemError) && (
        <View style={isPhone ? styles.inputContainerMobile : styles.inputContainer}>
          <FocusableTextInput
            validationError={currentState === VoucherState.RedeemError}
            initialValue={redemptionCode}
            onChangeText={setRedemptionCode}
            onInput={tryAgainHandler}
            textAlign='center'
          />
        </View>
      )}
      {currentState === VoucherState.Loading && <ActivityIndicator size={dimensions.icon.medium} />}
      {currentState === VoucherState.Activated && !!dataFetcher && (
        <View style={[styles.mediaContainer, singleElement && styles.singleMediaContainer]}>
          <Swimlane<Media>
            dataFetcher={dataFetcher}
            row={0}
            itemWidth={swimlaneItemWidth}
            width={singleElement ? singleElementSwimlaneWidth : popupWidth}
            itemHeight={swimlaneItemHeight + dimensions.margins.small}
            insets={mediaDetailSwimlaneInsets}
            createTile={renderMediaTile}
            renderNavigationArrows={false}
            loadingPlaceholderComponent={createLoadingPlaceholder}
            wrapAround={false}
            vertical={!isBigScreen}
          />
        </View>
      )}
    </Popup>
  );
};

export default VoucherRedeem;
