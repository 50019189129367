import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import RadialGradientView from './RadialGradientView';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  gradient: {
    start: colors.backgroundGradient.start,
    end: colors.backgroundGradient.end
  }
}));

const AppBackground: React.FC = () => {
  const styles = stylesUpdater.getStyles();
  const colors = useMemo(() => ([
    {color: styles.gradient.start},
    {color: styles.gradient.end}
  ]), [styles.gradient.start, styles.gradient.end]);
  return (
    <RadialGradientView
      colors={colors}
      rotation={dimensions.gradientBackground.rotation}
      scaleX={dimensions.gradientBackground.scaleX}
      scaleY={dimensions.gradientBackground.scaleY}
    />
  );
};

export default AppBackground;
