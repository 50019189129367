import {ContentType} from 'mw/api/Metadata';
import {PlayerType} from 'mw/playback/types/PlayerType';

import {BookmarkedPlayer} from './BookmarkedPlayer';

export class VODPlayer extends BookmarkedPlayer {

  public constructor(playerType: PlayerType) {
    super(playerType, ContentType.VOD);
  }
}
