import {createStyles, defaultStyles} from 'common-styles';
import React, {ReactNode} from 'react';
import {StyleSheet, SafeAreaView, View, ViewStyle, StyleProp} from 'react-native';

import {isMobile} from 'common/constants';
import {TestProps} from 'common/HelperTypes';

import {useTestID} from 'hooks/Hooks';

type Props = {
  style?: StyleProp<ViewStyle>;
  children?: ReactNode;
} & TestProps;

const styles = createStyles({
  safeAreaView: {
    ...StyleSheet.absoluteFillObject
  }
});

// TODO: replace usage SafeAreaView on main screens

const NitroxSafeAreaView: React.FC<Props> = props => {
  const testID = useTestID(props, 'NitroxSafeArea') || 'screen';
  return isMobile
    ? <SafeAreaView style={[styles.safeAreaView, props.style]} testID={testID}>{props.children}</SafeAreaView>
    : <View style={[defaultStyles.view, props.style]} testID={testID}>{props.children}</View>;
};

export default NitroxSafeAreaView;
