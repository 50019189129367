import {useState} from 'react';

import {Log} from 'common/Log';

// eslint-disable-next-line import/order
import {mw} from 'mw/MW';
import {Styling} from 'mw/api/Metadata';
import {CMSEvent} from 'mw/cms/CMS';

import colorPalette from 'brand/current/color-palette';
import {useEventListener, useFunction} from 'hooks/Hooks';

import {Styles} from '.';
import {createBaseColors, BaseColors, getColorPaletteFromObject} from './variables/base-colors';

const TAG = 'StylesUpdater';

type StyleObjectCreator<T> = (baseColors: BaseColors) => T;

const builtInColors = createBaseColors(colorPalette);
const builtInVersion = 'builtIn';

export class StylesUpdater<T extends Styles> {
  private styleCreator: StyleObjectCreator<T>;
  private cachedStyle: T;
  private cachedStyleVersion: string;
  private static baseColors = builtInColors;
  private static baseColorsVersion = builtInVersion;

  public constructor(objectCreator: StyleObjectCreator<T>) {
    this.styleCreator = objectCreator;
    this.cachedStyle = objectCreator(StylesUpdater.baseColors);
    this.cachedStyleVersion = StylesUpdater.baseColorsVersion;
  }

  private objectNeedsUpdate(): boolean {
    return this.cachedStyleVersion !== StylesUpdater.baseColorsVersion;
  }

  public getStyles(): T {
    if (this.objectNeedsUpdate()) {
      this.cachedStyle = this.styleCreator(StylesUpdater.baseColors);
      this.cachedStyleVersion = StylesUpdater.baseColorsVersion;
    }
    return this.cachedStyle;
  }

  public static update = (styling: Styling | null): void => {
    if (!styling) {
      StylesUpdater.reset();
      return;
    }
    Log.info(TAG, 'update: new styling, revision: ' + styling.revision);

    const [newColorPalette, isBuiltIn] = getColorPaletteFromObject(styling.styling);
    if (isBuiltIn) {
      StylesUpdater.reset();
      return;
    }

    StylesUpdater.baseColors = createBaseColors(newColorPalette);
    StylesUpdater.baseColorsVersion = styling.version + '|' + styling.revision;
    mw.cms.stylingApplied(styling.revision, true);
  }

  public static reset(): void {
    if (builtInVersion === StylesUpdater.baseColorsVersion) {
      return;
    }
    Log.info(TAG, 'StylesUpdater.reset');
    StylesUpdater.baseColors = builtInColors;
    StylesUpdater.baseColorsVersion = builtInVersion;
    mw.cms.stylingApplied(null, true);
  }
}

export function useStyles<T extends Styles>(stylesUpdater: StylesUpdater<T>): T {
  const [styles, setStyles] = useState(stylesUpdater.getStyles());
  const onStylingUpdate = useFunction(() => {
    setStyles(stylesUpdater.getStyles());
  });

  useEventListener(CMSEvent.newStylingApplied, onStylingUpdate, mw.cms);

  return styles;
}
