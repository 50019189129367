import {Log} from 'common/Log';

import {mw} from 'mw/MW';
import {NativePlaybackParameters} from 'mw/playback/types/NativePlaybackParameters';
import {PlaybackParametersProvider} from 'mw/playback/types/PlaybackParameters';

import {PlaybackRewinder, PlaybackRewinderEvent, RewindDirection, ChangeParametersHandler} from './PlaybackRewinder';

const TAG = 'SkipBasedPlaybackRewinder';

const updateTimeout = 1000;

export class SkipBasedPlaybackRewinder extends PlaybackRewinder {
  /**
   * Delta time that is added to the accumulatedDelta with each update. Its value reflects the speed at which rewinding is performed.
   */
  private delta = 0;

  /**
   * Delta time accumulated by the updates that is going to be used as the skip value by the next skip operation.
   */
  protected accumulatedDelta = 0;

  /**
   * Playback position accumulated over the time that rewinding is being performed.
   */
  private accumulatedPosition = 0;
  private pendingSkip = false;
  private updateTimer = 0;

  public constructor(
    private parametersProvider: PlaybackParametersProvider,
    protected changeParameters: ChangeParametersHandler
  ) {
    super();
  }

  public isRewinding(): boolean {
    return this.updateTimer !== 0;
  }

  public getRewindDirection(): RewindDirection | undefined {
    if (!this.isRewinding()) {
      return;
    }
    return this.delta > 0 ? RewindDirection.FastForward : RewindDirection.FastBackwards;
  }

  private skip(): Promise<void> {
    if (this.accumulatedDelta === 0) {
      this.pendingSkip = false;
      return Promise.resolve();
    }
    this.pendingSkip = true;
    const accumulatedDelta = this.accumulatedDelta; // accumulatedDelta can be changed while new playback parameters are being applied
    this.accumulatedDelta = 0;
    Log.debug(TAG, `Updating playback parameters to skip by ${accumulatedDelta} seconds`);
    // return promise only for the first change parameters request
    return this.changeParameters({skip: accumulatedDelta})
      .then(() => {
        Log.debug(TAG, `Skipped ${accumulatedDelta} seconds`);
        this.skip();
      });
  }

  protected update = (): Promise<void> => {
    this.updateHandler();
    // in case there are no pending skips - start them
    if (!this.pendingSkip) {
      return this.skip();
    }
    return Promise.resolve();
  };

  protected updateHandler(): void {
    // update accumulated delta and position and schedule next update
    this.accumulatedDelta += this.delta;
    this.accumulatedPosition = Math.min(this.parametersProvider.getEndPosition(),
      Math.max(this.parametersProvider.getBeginPosition(), this.accumulatedPosition + this.delta));
    if (!this.isPositionInRange()) {
      Log.info(TAG, `Position ${this.accumulatedPosition} exceeds positions ${this.parametersProvider.getBeginPosition()}-${this.parametersProvider.getEndPosition()}`);
    }
    this.updateTimer = setTimeout(this.update, updateTimeout);
    Log.debug(TAG, `Updated accumulated delta to ${this.accumulatedDelta} and position to ${this.accumulatedPosition}`);
    this.notify(PlaybackRewinderEvent.PositionChanged, {
      position: this.accumulatedPosition
    });
  }

  protected isPositionInRange(): boolean {
    return this.accumulatedPosition > this.parametersProvider.getBeginPosition() && this.accumulatedPosition < this.parametersProvider.getEndPosition();
  }
  private computeDelta(direction: RewindDirection): number {
    const skips = mw.configuration.playbackRewindSkips;
    // look for first rewind request or direction change
    if (this.delta === 0 || (this.delta / Math.abs(this.delta)) !== direction) {
      return direction * skips[0];
    }
    // otherwise use next configured skip delta
    const intervalIndex = skips.findIndex(skip => skip === Math.abs(this.delta));
    return direction * skips[(intervalIndex + 1) % skips.length];
  }

  public start(direction: RewindDirection): Promise<void> {
    if (!this.isRewinding()) {
      this.accumulatedPosition = this.parametersProvider.getPosition();
    }
    // update current delta
    const previousDelta = this.delta;
    this.delta = this.computeDelta(direction);
    Log.debug(TAG, 'Computed new delta', this.delta);
    // send notification about new rewinding delta
    if (previousDelta !== this.delta) {
      this.notify(PlaybackRewinderEvent.Rewinding, {
        position: this.accumulatedPosition,
        delta: this.delta
      });
    }
    // in case updates are not scheduled - schedule them
    if (!this.updateTimer) {
      return this.update();
    }
    return Promise.resolve();
  }

  public stop(): Promise<void> {
    if (!this.isRewinding()) {
      Log.debug(TAG, 'There is no need to stop a rewind');
      return Promise.resolve();
    }
    Log.debug(TAG, 'Stopping rewind');
    this.reset();
    return Promise.resolve();
  }

  protected reset(): void {
    if (this.updateTimer) {
      clearTimeout(this.updateTimer);
      this.updateTimer = 0;
    }
    this.delta = 0;
    this.accumulatedDelta = 0;
    this.accumulatedPosition = 0;
    this.notify(PlaybackRewinderEvent.Stopped);
  }

  public updateParameters(parameters: NativePlaybackParameters): Promise<void> {
    if (!this.isRewinding()) {
      return Promise.resolve();
    }
    Log.debug(TAG, 'Playback parameters have been changed outside of rewinder');
    if (typeof parameters.playRate !== 'undefined') {
      Log.debug(TAG, 'Playback play rate changed - stopping rewind');
      return this.stop();
    }
    const accumulatedPosition = (parameters.position ?? this.accumulatedPosition) + (parameters.skip || 0);
    if (accumulatedPosition !== this.accumulatedPosition) {
      Log.debug(TAG, 'Playback position changed - updating accumulated position to', accumulatedPosition);
      this.accumulatedPosition = accumulatedPosition;
    }
    return Promise.resolve();
  }
}
