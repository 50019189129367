import {createStyles} from 'common-styles';
import React, {useState, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {ChromecastConnectionState} from 'mw/api/Metadata';
import {ChromecastDevice} from 'mw/platform/chromecast/ChromecastInterface';

import {IconType, Icon} from 'components/Icon';
import NitroxText from 'components/NitroxText';
import Popup, {PopupAction} from 'components/Popup';

import VolumeSlider from './VolumeSlider';

const containerMaxWidth = 500;
const popupActions = [PopupAction.POSITIVE, PopupAction.NEGATIVE];

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  text: {
    color: colors.popup.text,
    textAlign: 'center'
  }
}));

const styles = createStyles({
  container: {
    alignSelf: 'center',
    maxWidth: containerMaxWidth,
    width: '100%',
    marginHorizontal: dimensions.margins.xxLarge
  },
  volumeContainer: {
    marginTop: dimensions.margins.medium
  },
  volumeSliderContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginVertical: dimensions.margins.medium
  },
  deviceContainer: {
    marginTop: dimensions.margins.medium
  },
  deviceNameContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: dimensions.margins.medium
  },
  deviceName: {
    marginStart: dimensions.margins.large
  }
});

type ChromecastMoreActionsPopupProps = {
  visible: boolean;
  device: ChromecastDevice;
  currentVolume: number;
  connectionState: ChromecastConnectionState;
  onVolumeChanged: (newVolume: number) => void;
  onClose: () => void;
  onDeviceDisconnected: () => void;
}

const ChromecastMoreActionsPopup: React.FC<ChromecastMoreActionsPopupProps> = props => {
  const {
    visible,
    device,
    onClose,
    onDeviceDisconnected,
    currentVolume,
    connectionState,
    onVolumeChanged: propsOnVolumeChanged
  } = props;

  const {t} = useTranslation();
  const [changingVolume, setChangingVolume] = useState<boolean>(false);

  const dynamicStyle = stylesUpdater.getStyles();

  const onVolumeChanged = useCallback((newVolume: number) => {
    setChangingVolume(true);
    propsOnVolumeChanged(newVolume);
    setChangingVolume(false);
  }, [propsOnVolumeChanged]);

  return (
    <Popup
      actions={popupActions}
      visible={visible}
      onClose={onClose}
      onPositive={onDeviceDisconnected}
      onNegative={onClose}
      positiveLabel={t('chromecast.moreActionPopup.disconnect')}
      negativeLabel={t('chromecast.moreActionPopup.close')}
    >
      <View style={styles.container}>
        <View style={styles.volumeContainer}>
          <NitroxText textType='dialog-title' style={dynamicStyle.text}>{t('chromecast.moreActionPopup.volumeTitle')}</NitroxText>
          <View style={styles.volumeSliderContainer}>
            <VolumeSlider
              changingVolume={changingVolume}
              currentVolume={currentVolume}
              availableVolumeStart={0}
              availableVolumeEnd={1}
              onVolumeChange={onVolumeChanged}
            />
          </View>
        </View>
        <View style={styles.deviceContainer}>
          <NitroxText textType='dialog-title' style={dynamicStyle.text}>{t('chromecast.moreActionPopup.deviceTitle')}</NitroxText>
          <View style={styles.deviceNameContainer}>
            <Icon type={IconType.Screen} size={dimensions.icon.small} />
            <NitroxText numberOfLines={1} textType='dialog-message' style={[dynamicStyle.text, styles.deviceName]}>{device.name}</NitroxText>
          </View>
        </View>
      </View>
    </Popup>
  );
};

export default React.memo(ChromecastMoreActionsPopup);
