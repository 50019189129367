import {createStyles} from 'common-styles';
import React, {forwardRef, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ViewStyle, ActivityIndicator} from 'react-native';

import {dimensions, isMobile, isBigScreen} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxButton, {NitroxButtonProps} from 'components/NitroxButton';
import {PostProcessors} from 'locales/i18nPostProcessors';
import {useLocalized} from 'locales/i18nUtils';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  forwardButtonColor: colors.button.primary.background.focused
}));

const ForwardButton: React.FC<NitroxButtonProps & {busy?: boolean}> = (props, ref) => {
  const {t} = useTranslation();
  const {toUpperCase} = useLocalized();
  const {
    busy,
    text,
    hasTvPreferredFocus = true,
    ...otherProps
  } = props;

  const buttonText = useMemo(() => {
    if (busy) {
      return;
    }
    else if (isMobile) {
      return text || t('common.next');
    }
    else {
      return text ? toUpperCase(text) : t('common.next', {postProcess: PostProcessors.ToUpperCase});
    }
  }, [busy, text, t, toUpperCase]);

  const styles = stylesUpdater.getStyles();
  const forwardButtonStyle: ViewStyle = {
    height: dimensions.buttons.small,
    ...isMobile && {
      minWidth: dimensions.forwardButton.minWidth,
      backgroundColor: styles.forwardButtonColor,
      borderRadius: Math.floor(dimensions.buttons.small / 2),
      marginLeft: 'auto',
      marginRight: dimensions.margins.large
    }
  };

  return (
    <NitroxButton
      style={forwardButtonStyle}
      {...otherProps}
      hasTvPreferredFocus={hasTvPreferredFocus}
      border={isBigScreen}
      text={buttonText}
      textType={'forward-buttons'}
      disabled={busy}
      ref={ref}
    >
      {busy && <ActivityIndicator size='small' animating />}
    </NitroxButton>
  );
};

export default forwardRef(ForwardButton);
