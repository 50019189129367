import React, {useMemo} from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';

import {asyncIterator} from 'common/HelperFunctions';

import {Media} from 'mw/api/Metadata';

import AnimatedSwimlaneMediaBasic from 'components/AnimatedSwimlaneMediaBasic';

type RelatedRecommendationsProps = {
  style?: StyleProp<ViewStyle>;
  recommendations: Media[];
};

const RelatedRecommendationsView: React.FC<RelatedRecommendationsProps> = ({recommendations, style}) => {
  const dataFetcher = useMemo(() => asyncIterator(recommendations), [recommendations]);

  return (
    <View style={style}>
      <AnimatedSwimlaneMediaBasic dataFetcher={dataFetcher} />
    </View>
  );
};

export default RelatedRecommendationsView;
