import React from 'react';
import {withTranslation} from 'react-i18next';

import {isMobile} from 'common/constants';

import {withFocusParent} from 'components/FocusParent';

import VodCategoryScreenClassGrosso from './VodScreen.grosso';
import VodCategoryScreenClassPiccolo from './VodScreen.piccolo';
import {VodCategoryScreenProps} from './VodScreen.shared';

const VodCategoryScreenClass: React.FC<VodCategoryScreenProps> = props => {
  return isMobile ? <VodCategoryScreenClassPiccolo {...props} /> : <VodCategoryScreenClassGrosso {...props} />;
};

export const VodCategoryScreen = withFocusParent('vodScreen')(VodCategoryScreenClass);
export const VodLandingScreen = withTranslation()(VodCategoryScreen);
