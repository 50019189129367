import React from 'react';
import {GestureResponderEvent} from 'react-native';
import {NavigationScreenProps, withNavigation} from 'react-navigation';

import {isBigScreen} from 'common/constants';

import {SearchResult} from 'mw/api/CatalogInterface';
import {Media, VodSorting, ChannelSorting, AllResultsSorting} from 'mw/api/Metadata';

import {SearchEpgFilter} from 'components/SearchEpgFilterSelect';

import SearchResultsGrosso from './SearchResults.grosso';
import SearchResultsPiccolo from './SearchResults.piccolo';

//TODO: CL-3087 SearchResultProps is used in imported files SearchResults.[piccolo/grosso]
export type SearchResultProps = NavigationScreenProps<{}> & {
  searchResult?: SearchResult;
  searchResultsFocusPriority?: number;
  currentAllResultsSorting?: AllResultsSorting;
  onAllResultsSortingSelected: (allResultsSorting?: AllResultsSorting) => void;
  currentChannelSorting?: ChannelSorting;
  onChannelSortingSelected: (channelSorting?: ChannelSorting) => void;
  currentEpgFilter?: SearchEpgFilter;
  onEpgFilterSelected: (epgFilter?: SearchEpgFilter) => void;
  currentVodSorting: VodSorting;
  onVodSortingSelected: (vodSorting?: VodSorting) => void;
  searchText: string;
  onClearResults?: (event?: GestureResponderEvent) => void;
  onMoreActionsPress?: (media: Media) => void;
  onStartPlayback?: (media: Media) => void;
};

function SearchResults(props: SearchResultProps) {
  return isBigScreen ? <SearchResultsGrosso {...props} /> : <SearchResultsPiccolo {...props} />;
}

export default React.memo(withNavigation(SearchResults));
