import {Log} from 'common/Log';
const TAG = 'Environment';

export enum DebugFeaturesVars {
  focusGuide = 'DEBUG_FEATURE_FOCUS_GUIDE',
  focusManager = 'DEBUG_FEATURE_FOCUS_MANAGER',
  epgFocusDriver = 'DEBUG_FEATURE_EPG_FOCUS_DRIVER',
  focusParent = 'DEBUG_FEATURE_FOCUS_PARENT',
  taFocusReporter = 'DEBUG_FEATURE_TA_FOCUS_REPORTER',
  channelKeys = 'DEBUG_FEATURE_CHANNEL_KEYS'
}

export enum TestAutomationVars {
  enableLogsForTesting = 'ENABLE_LOGS_FOR_TESTING',
  deviceIdOverride = 'DEVICE_ID_OVERRIDE',
  deviceNameOverride = 'DEVICE_NAME_OVERRIDE',
  exposeMiddleware = 'EXPOSE_MIDDLEWARE',
  enableFocusReporter = 'ENABLE_FOCUS_REPORTER'
}

export type KnownVars = 'PROFILER_ENABLED' | 'SELECTABLE_BO' | 'BUILD_TAG' | 'BUILD_SHA' | 'BUILD_DATE' | 'CI_JOB_ID' | 'CI_PIPELINE_ID' | 'TRANSLATION_VERSION' | DebugFeaturesVars | TestAutomationVars ;

export class Environment {

  public static readonly instance = new Environment();
  private values: {[key: string]: string} = {};

  private constructor() {
    try {
      this.values = require('../../env.json');
    } catch (e) {
      Log.warn(TAG, 'Missing env.json file in repo root!');
    }
  }

  public getBoolean(key: KnownVars, fallback = false): boolean {
    if (typeof this.values[key] === 'undefined') {
      return fallback;
    }
    return this.values[key].toLowerCase() === 'true';
  }

  public getString(key: KnownVars, fallback = ''): string {
    if (typeof this.values[key] === 'undefined') {
      return fallback;
    }
    return this.values[key];
  }

  public getNumber(key: KnownVars, fallback = 0): number {
    if (typeof this.values[key] === 'undefined') {
      return fallback;
    }
    return Number.parseFloat(this.values[key]);
  }

  public get(key: string): string {
    return this.values[key];
  }
}

export const environment: Environment = Environment.instance;
