import {createStyles} from 'common-styles';
import React, {useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {View, StyleSheet} from 'react-native';
import {NavigationScreenProps} from 'react-navigation';

import {dimensions} from 'common/constants';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors, constColors} from 'common-styles/variables/base-colors';

import {mw} from 'mw/MW';

import DoubleBackground from 'components/DoubleBackground';
import {MobileScreenHeaderProps} from 'components/mobileScreenHeader/MobileScreenHeader';
import AnimatedProfileSwitcher from 'components/profileManagement/AnimatedProfileSwitcher';
import NitroxScreen from 'screens/NitroxScreen';

import {CommonSettingsScreenProps} from './CommonSettingsScreenProps';
import {useSettingsNavigation} from './hooks';
import SettingsList from './SettingsList';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  titleAndUserIconContainer: {
    backgroundColor: colors.settingsScreen.headerBackground
  },
  mainContainer: {
    flex: 1,
    paddingHorizontal: dimensions.margins.xxxLarge
  },
  separator: {
    opacity: dimensions.opacity.xlow,
    width: '50%',
    marginTop: 58
  },
  profileSwitcher: {
    marginVertical: dimensions.margins.medium
  },
  bottomBackground: {
    flexGrow: 1,
    zIndex: -1,
    marginTop: dimensions.margins.small
  },
  safeArea: {
    backgroundColor: colors.settingsScreen.headerBackground
  },
  content: {
    flex: 1,
    backgroundColor: colors.settingsScreen.background
  },
  rightIcons: {
    justifyContent: 'center',
    paddingRight: dimensions.margins.xsmall
  },
  iconColor: colors.screenHeader.icon,
  headerIcon: {
    backgroundColor: constColors.transparent
  },
  activityIndicatorContainer: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

type Props = NavigationScreenProps & CommonSettingsScreenProps;

const TAG = 'SettingsScreenPiccolo';

const SettingsScreenPiccolo: React.FunctionComponent<Props> = ({authorizeWithPin, showLogoutModal, renderModals, style, navigation}) => {
  const {navigators} = useSettingsNavigation(authorizeWithPin);
  const {t} = useTranslation();
  const styles = stylesUpdater.getStyles();

  const mobileHeader: MobileScreenHeaderProps = useMemo(() => ({
    title: t('settings.header'),
    style: styles.titleAndUserIconContainer
  }), [t, styles.titleAndUserIconContainer]);

  const shareLogs = useCallback(() => {
    return mw.system.shareLogs(t('settings.logsShareTitle'))
      .catch(error => Log.error(TAG, 'share logs failed', error));
  }, [t]);

  const SettingsListComponent = (
    <View style={styles.mainContainer}>
      <SettingsList
        onCustomerIDPress={navigators.customerID}
        onRegisteredDevicesPress={navigators.registeredDevices}
        onApplicationVersionPress={navigators.applicationVersion}
        onBackOfficeSelectorPress={navigators.backOfficeSelector}
        onInfoPress={navigators.info}
        onChannelListsPress={navigators.channelLists}
        onLanguagePress={navigators.language}
        onTimeAndDatePress={navigators.timeAndDate}
        onParentalControlPress={navigators.parentalControl}
        onMyPinPress={navigators.myPin}
        onDeleteProfilePress={navigators.deleteProfile}
        onLogoutPress={showLogoutModal}
        onShareLogsPress={shareLogs}
        onMyAccountPress={navigators.myAccount}
        onHelpPress={navigators.help}
      />
    </View>
  );
  return (
    <NitroxScreen
      // in this case style is default (common for piccolo and grosso), and local styles are more specific
      style={[style, styles.safeArea]}
      navigation={navigation}
      mobileHeaderProps={mobileHeader}
      testID='screen_settings'
    >
      <View style={styles.content}>
        <DoubleBackground
          bottomStyle={styles.bottomBackground}
          topChild={(
            <AnimatedProfileSwitcher
              style={styles.profileSwitcher}
              onAddNewPress={navigators.profileWizard}
            />
          )}
          bottomChild={SettingsListComponent}
          overlap={dimensions.margins.small}
        />
      </View>
      {renderModals()}
    </NitroxScreen>
  );
};
export default SettingsScreenPiccolo;
