import {createStyles} from 'common-styles';
import i18next from 'i18next';
import React, {useCallback, useImperativeHandle} from 'react';
import {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {View, Platform, ActivityIndicator, ViewStyle} from 'react-native';

import {dimensions, isDesktopBrowser, isSTBBrowser, WEB_ARROW_CONTAINER_WIDTH, Direction, isBigScreen} from 'common/constants';
import {idKeyExtractor, isSeriesEpisode} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors, constColors} from 'common-styles/variables/base-colors';

import {Event, Media, Title, MediaType, isEvent, isTitle, Recording} from 'mw/api/Metadata';

import FocusParent from 'components/FocusParent';
import {IconType} from 'components/Icon';
import {NativeKeyEvent, SupportedKeys} from 'components/KeyEventManager';
import NavArrow from 'components/NavArrow';
import {FAR_FAR_AWAY} from 'components/navigation/ResourceSavingScene';
import NitroxButton from 'components/NitroxButton';
import NitroxButtonsMenu from 'components/NitroxButtonsMenu';
import {NitroxButtonsMenuAction} from 'components/NitroxButtonsMenu';
import NitroxList, {NitroxListItemsRequest, NitroxListItemsBatch, NitroxListItemLayout} from 'components/NitroxList';
import NitroxText from 'components/NitroxText';
import {useParentalControl, PCMedia} from 'components/parentalControl/ParentalControlProvider';
import {useRecord} from 'components/pvr/Record';
import {RecordActionType} from 'components/pvr/RecordingUtils';
import Separator from 'components/Separator';
import ShortDetails, {getEventMetadata, getTitleMetadata, getEpisodeMetadata, getRecordingMetadata} from 'components/ShortDetailsView';
import {useKeyEventHandler, useFunction, useDisposableCallback, useLazyEffect, useLateBinding, useDisposable} from 'hooks/Hooks';

const TAG = 'MediaDetailsTemplate';

export type DetailsButtonsHandlers = {
  onWatch: (media: Media, unlocked?: boolean) => void;
  onShowDetails?: (media: Media) => void;
  onRestart?: (media: Media) => void;
  onRecord?: (media: Media) => Promise<void>;
  onAddToWatchList?: (media: Media) => Promise<void>;
  onRemoveFromWatchList?: (mediaArray: Media[]) => Promise<void>;
  onDownload?: (media: Media) => void;
  onBingeBack?: () => void;
  /** Binge counter stop due to user interaction */
  onBingeStop?: () => void;
  onBuyPress?: (title: Title) => void;
  onRentPress?: (title: Title) => void;
}

export const getMediaMetadata = (t: i18next.TFunction, isMediaBlocked: (media: PCMedia) => boolean, media?: Media, landscape?: boolean) => {
  const mediaType = media && media.getType();
  let errorMessage = 'getMediaMetadata: unsupported media type!';
  switch (mediaType) {
    case MediaType.Event:
      return getEventMetadata(media as Event, t, landscape);
    case MediaType.Recording:
      const metadata = getRecordingMetadata(media as Recording, t, isMediaBlocked);
      if (metadata) {
        return metadata;
      } else {
        errorMessage = 'getMediaMetadata: metadata cannot be retreived from series recording!';
      }
      break;
    case MediaType.Title:
      if (isSeriesEpisode(media as Title)) {
        return getEpisodeMetadata(media as Title);
      } else {
        return getTitleMetadata(media as Title);
      }
  }
  Log.error(TAG, errorMessage);
  return {title: '', subtitle: ''};
};

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    overflow: 'visible',
    width: '100%',
    height: dimensions.playerMediaDetails.containerHeight
  },
  bingeWatchContainer: {
    height: dimensions.playerMediaDetails.smallContainerHeight
  },
  detailsContainer: {
    position: 'absolute',
    left: 0,
    width: dimensions.playerMediaDetails.width,
    height: '100%',
    paddingLeft: 4,
    paddingRight: 7
  },
  detailsSeparator: {
    marginTop: 16,
    backgroundColor: colors.overlayMediaDetails.separator
  },
  detailsButtonsMenu: {
    marginTop: 18
  },
  mediaContainer: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  leftArrowContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: dimensions.tile.height, width: WEB_ARROW_CONTAINER_WIDTH
  },
  rightArrowContainer: {
    position: 'absolute',
    top: 0,
    right: -WEB_ARROW_CONTAINER_WIDTH,
    height: dimensions.tile.height,
    width: WEB_ARROW_CONTAINER_WIDTH
  },
  hidden: {
    top: FAR_FAR_AWAY,
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  unlockButton: {
    alignSelf: 'flex-start',
    marginTop: dimensions.margins.xxLarge
  }
}));

type MediaHelpersProps = {
  mediaDimensions: {width: number; height: number};
}

const MediaLoadingIndicator: React.FC<MediaHelpersProps> = (props: MediaHelpersProps) => {
  const styles = stylesUpdater.getStyles();
  return (
    <View style={[styles.mediaContainer, {width: props.mediaDimensions.width, height: props.mediaDimensions.height}]}>
      <ActivityIndicator size='large' animating />
    </View>
  );
};

const MediaPlaceholder: React.FC<MediaHelpersProps> = (props: MediaHelpersProps) => {
  const {t} = useTranslation();
  const styles = stylesUpdater.getStyles();
  return (
    <View style={[styles.mediaContainer, {width: props.mediaDimensions.width, height: props.mediaDimensions.height}]}>
      <NitroxText textType='headline'>{t('zapper.noAvailableEvents')}</NitroxText>
    </View>
  );
};

type DimensionsProps = {
  mediaDimensions: {width: number; height: number};
  leftMediaListContainer: ViewStyle;
  rightMediaListContainer: ViewStyle;
}

export type DetailsBaseProps = {
  handlers?: DetailsButtonsHandlers;
  landscape: boolean;
  extraData?: any; // marker property for telling the list to re-render
  focusedMedia: Media | undefined;
  overlayVisible?: boolean;
  playerControlsVisible?: boolean;
  mediaListsVisible?: boolean;
  bingeActive?: boolean;
  onFocus?: (media: Media | undefined) => void;
  onPress?: () => void;
  authorized?: boolean;
  onAdultUnlockPress?: () => void;
}

type Props = {
  renderMedia: (itemData: Media, index: number) => React.ReactElement | undefined;
  requestData?: (itemsRequest: NitroxListItemsRequest) => Promise<NitroxListItemsBatch<Media>>;
  onFocusChange?: (index: number) => void;
} & DetailsBaseProps & DimensionsProps;

const MediaDetailsTemplate: React.FC<Props> = (props, ref) => {
  const {handlers: propsHandlers, bingeActive, onFocusChange, focusedMedia: propsMedia, authorized = true} = props;
  const {t} = useTranslation();
  const [focusedMedia, setFocusedMedia] = useState(propsMedia);
  const [mediaActions, setMediaActions] = useState<NitroxButtonsMenuAction[]>([]);
  const [mediaActionsLoading, setMediaActionsLoading] = useState(false);
  const leftMediaListRef = useRef<NitroxList<Media> | null>(null);
  const rightMediaListRef = useRef<NitroxList<Media> | null>(null);
  const detailsFocused = useRef(false);
  const blurTimeout = useRef<number | null>();
  const {getRecordActionTypeForEvent} = useRecord();
  const {isMediaBlocked} = useParentalControl();

  const clearMediaLists = useCallback(() => {
    Log.debug(TAG, 'Clearing the media lists');
    if (!leftMediaListRef.current || !rightMediaListRef.current) {
      return;
    }
    leftMediaListRef.current.clear();
    rightMediaListRef.current.clear();
  }, []);

  const onFocusDetails = useCallback(() => {
    const focusedMediaType = focusedMedia && Platform.isTV && focusedMedia.getType();
    switch (focusedMediaType) {
      case MediaType.Title:
        // TODO(CL-1480): open details
        break;
    }
    if (blurTimeout.current) {
      clearTimeout(blurTimeout.current);
      blurTimeout.current = null;
    }
    detailsFocused.current = true;
  }, [focusedMedia]);

  const blurTimeoutHandler = useCallback(() => {
    detailsFocused.current = false;
  }, []);

  const onBlurDetails = useCallback(() => {
    if (blurTimeout.current) {
      clearTimeout(blurTimeout.current);
      blurTimeout.current = null;
    }
    blurTimeout.current = setTimeout(blurTimeoutHandler, 100);
  }, [blurTimeoutHandler]);

  const onShowDetails = useCallback(() => {
    if (focusedMedia && propsHandlers?.onShowDetails) {
      propsHandlers.onShowDetails(focusedMedia);
    }
  }, [propsHandlers, focusedMedia]);

  const [refreshMediaActions, bindRefreshMediaActions] = useLateBinding<(media?: Media) => Promise<void>>();

  const refreshActions = useDisposableCallback(async (media?: Media) => {
    setMediaActionsLoading(true);
    await refreshMediaActions(media);
    setMediaActionsLoading(false);
  }, []);

  const onRestartHandler = useDisposableCallback((media: Media) => {
    propsHandlers?.onRestart?.(media);
  }, [propsHandlers]);

  const onWatchHandler = useDisposableCallback((media: Media) => {
    propsHandlers?.onWatch?.(media);
  }, [propsHandlers]);

  const onRecordHandler = useDisposableCallback(async (media: Media): Promise<void> => {
    if (propsHandlers?.onRecord) {
      await propsHandlers.onRecord(media);
      refreshActions(media);
    }
  }, [propsHandlers, refreshActions]);

  const onAddToWatchListHandler = useDisposableCallback(async (media: Media) => {
    if (propsHandlers?.onAddToWatchList) {
      await propsHandlers.onAddToWatchList(media);
      refreshActions(media);
    }
  }, [propsHandlers, refreshActions]);

  const onRemoveFromWatchListHandler = useDisposableCallback(async (media: Media) => {
    if (propsHandlers?.onRemoveFromWatchList) {
      await propsHandlers.onRemoveFromWatchList([media]);
      refreshActions(media);
    }
  }, [propsHandlers, refreshActions]);

  bindRefreshMediaActions(useDisposable(async (media?: Media): Promise<void> => {
    // TODO: Handle onPress for every action
    if (!media) {
      setMediaActions([]);
      return;
    }
    const restartAction = {
      text: isEvent(media) && media.isPast ? t('zapper.goBack') : t('zapper.restart'),
      onPress: () => onRestartHandler(media)
    };
    const watchAction = {
      text: t('zapper.watch'),
      onPress: () => onWatchHandler(media)
    };
    const recordAction = {
      text: t('zapper.recording'),
      onPress: () => onRecordHandler(media)
    };
    const recordSeriesAction = {
      text: t('zapper.recordSeries'),
      onPress: () => onRecordHandler(media)
    };
    const recordAllAction = {
      text: t('zapper.recordAll'),
      onPress: () => onRecordHandler(media)
    };
    const addToWatchListAction = {
      text: t('zapper.watchList'),
      icon: {type: IconType.Add},
      onPress: () => onAddToWatchListHandler(media)
    };
    const removeFromWatchListAction = {
      text: t('zapper.watchList'),
      icon: {type: IconType.Remove},
      onPress: () => onRemoveFromWatchListHandler(media)
    };
    // use only allowed actions in proper order
    const actions: NitroxButtonsMenuAction[] = [];
    if (isEvent(media)) {
      if (media.hasTstv) {
        actions.push(restartAction);
      }
      if (media.isNow) {
        actions.push(watchAction);
      }

      switch (await getRecordActionTypeForEvent(media)) {
        case RecordActionType.record:
          actions.push(recordAction);
          break;
        case RecordActionType.recordSeries:
          actions.push(recordSeriesAction);
          break;
        case RecordActionType.recordAll:
          actions.push(recordAllAction);
          break;
      }

      if (media.isAllowedOnWatchList()) {
        actions.push(media.isOnWatchList ? removeFromWatchListAction : addToWatchListAction);
      }
    } else if (isTitle(media)) {
      const current = media.equals(propsMedia);
      const playable = media.isPlayAllowed() != null;
      if (!current && playable) {
        actions.push(watchAction);
      }
    }
    setMediaActions(actions);
  }, [propsMedia, t, onRestartHandler, onWatchHandler, onRecordHandler, onAddToWatchListHandler, onRemoveFromWatchListHandler]));

  const getFocusedMediaItemIndex = useCallback((): number => {
    if (!leftMediaListRef.current || !rightMediaListRef.current) {
      return 0;
    }
    const focusedIndex = rightMediaListRef.current.getFocusedIndex();
    if (focusedIndex !== leftMediaListRef.current.getFocusedIndex()) {
      Log.error(TAG, 'Focused index values differ between the media lists!');
    }
    return focusedIndex;
  }, []);

  const scrollMediaListsToIndex = useCallback((index: number, forceIndex?: boolean) => {
    if (!leftMediaListRef.current || !rightMediaListRef.current) {
      return;
    }
    Log.debug(TAG, 'Scolling media lists to index ' + index);
    leftMediaListRef.current.scrollToIndex(index, forceIndex);
    rightMediaListRef.current.scrollToIndex(index, forceIndex);
  }, []);

  useImperativeHandle(ref, () => ({
    scrollToIndex: (index: number, forceIndex?: boolean) => {
      scrollMediaListsToIndex(index, forceIndex);
    }
  }));

  const scrollMediaListsInDirection = useCallback((direction: SupportedKeys.Left | SupportedKeys.Right) => {
    const mediaItemIndex = getFocusedMediaItemIndex() + (direction === SupportedKeys.Left ? -1 : 1);
    scrollMediaListsToIndex(mediaItemIndex);
  }, [getFocusedMediaItemIndex, scrollMediaListsToIndex]);

  const layoutMedia = useCallback((): NitroxListItemLayout => {
    return {width: props.mediaDimensions.width, height: props.mediaDimensions.height};
  }, [props.mediaDimensions.height, props.mediaDimensions.width]);

  const renderMediaLoadingIndicator = useCallback((): React.ReactElement | undefined => {
    return <MediaLoadingIndicator mediaDimensions={props.mediaDimensions} />;
  }, [props.mediaDimensions]);

  const renderMediaPlaceholder = useCallback((): React.ReactElement | undefined => {
    return <MediaPlaceholder mediaDimensions={props.mediaDimensions} />;
  }, [props.mediaDimensions]);

  useEffect(() => {
    clearMediaLists();
  }, [clearMediaLists, props.extraData]);

  useLazyEffect(() => {
    setFocusedMedia(propsMedia);
    refreshMediaActions(propsMedia);
  }, [propsMedia], [refreshMediaActions]);

  const onKeyPress = useFunction((event: NativeKeyEvent): void => {
    // ignore every key when the overlay is not visible
    if (!props.overlayVisible) {
      return;
    }
    switch (event.key) {
      case SupportedKeys.Left:
      case SupportedKeys.Right:
        if (!detailsFocused.current || !leftMediaListRef.current || leftMediaListRef.current.isEmpty() || !rightMediaListRef.current || rightMediaListRef.current.isEmpty()) {
          return;
        }
        scrollMediaListsInDirection(event.key);
        break;
      case SupportedKeys.Back:
        if (bingeActive) {
          propsHandlers?.onBingeBack?.();
          return;
        }

        // handle events lists visibility changes only on TVs
        if (!Platform.isTV) {
          return;
        }
        if (focusedMedia && focusedMedia.getType() === MediaType.Title) {
          setFocusedMedia(propsMedia);
          if (leftMediaListRef.current && !leftMediaListRef.current.isEmpty() && rightMediaListRef.current && !rightMediaListRef.current.isEmpty()) {
            scrollMediaListsToIndex(0);
          }
        }
        break;
    }
  });
  useKeyEventHandler('keyup', onKeyPress);
  useKeyEventHandler('ios:swipe', onKeyPress);

  const styles = stylesUpdater.getStyles();
  return (
    <FocusParent style={[styles.container, isBigScreen && bingeActive && styles.bingeWatchContainer]} enterStrategy='byPriority' rememberLastFocused>
      {props.requestData && (
        <View style={[props.leftMediaListContainer, {
          left: -props.mediaDimensions.width
        }]}
        >
          <NitroxList<Media>
            ref={ref => {leftMediaListRef.current = ref;}}
            horizontal
            enableDebugLogs
            debugName='leftMediaList'
            refreshOnMount={false} // refresh calls are triggered by channel changes
            requestItemsData={props.requestData}
            keyExtractor={idKeyExtractor}
            renderItem={props.renderMedia}
            layoutItem={layoutMedia}
            onContainerLayoutChanged={(width) => {
              if (leftMediaListRef.current) {
                leftMediaListRef.current.setViewportOffsets(width, width + props.mediaDimensions.width);
              }
            }}
          />
        </View>
      )}
      <View style={[styles.detailsContainer, !focusedMedia && styles.hidden]}>
        <ShortDetails
          focusPriority={1}
          onFocus={onFocusDetails}
          onBlur={onBlurDetails}
          onPress={onShowDetails}
          landscape={props.landscape}
          {...getMediaMetadata(t, isMediaBlocked, focusedMedia)}
          titleNumberOfLines={isBigScreen ? 1 : 2}
        >
        </ShortDetails>
        <Separator horizontal style={styles.detailsSeparator} />
        {!bingeActive && (
          <>
            {authorized && (
              <NitroxButtonsMenu
                borderButtons
                containerStyle={styles.detailsButtonsMenu}
                actions={mediaActions}
                loading={mediaActionsLoading}
              />
            )}
            {!authorized && props.onAdultUnlockPress && (
              <NitroxButton style={styles.unlockButton} onPress={props.onAdultUnlockPress} text={t('common.enterPin')} />
            )}
          </>
        )}
      </View>
      <View style={[props.rightMediaListContainer, {left: dimensions.playerMediaDetails.width}]}>
        {isDesktopBrowser && !isSTBBrowser && props.requestData && (
          <View style={styles.leftArrowContainer}>
            <NavArrow
              direction={Direction.Left}
              height={dimensions.tile.height}
              backgroundColor={constColors.transparent}
              width={WEB_ARROW_CONTAINER_WIDTH}
              opacity={1}
              handlePress={() => {
                scrollMediaListsInDirection(SupportedKeys.Left);
              }}
            />
          </View>
        )}
        {props.requestData && (
          <NitroxList<Media>
            ref={ref => {rightMediaListRef.current = ref;}}
            horizontal
            enableDebugLogs
            debugName='rightMediaList'
            refreshOnMount={false}  // refresh calls are triggered by channel changes
            requestItemsData={props.requestData}
            keyExtractor={idKeyExtractor}
            renderItem={props.renderMedia}
            layoutItem={layoutMedia}
            renderLoadingIndicator={renderMediaLoadingIndicator}
            layoutLoadingIndicator={layoutMedia}
            renderMissingDataPlaceholder={renderMediaPlaceholder}
            layoutMissingDataPlaceholder={layoutMedia}
            minViewportOffset={-props.mediaDimensions.width}
            maxViewportOffset={0}
            onScrolledToIndex={(index: number, media: Media | undefined): void => {
              setFocusedMedia(media);
              refreshMediaActions(media);
              onFocusChange?.(index);
            }}
          />
        )}
        {isDesktopBrowser && !isSTBBrowser && props.requestData && (
          <View style={styles.rightArrowContainer}>
            <NavArrow
              direction={Direction.Right}
              height={dimensions.tile.height}
              backgroundColor={constColors.transparent}
              width={WEB_ARROW_CONTAINER_WIDTH}
              opacity={1}
              handlePress={() => {
                scrollMediaListsInDirection(SupportedKeys.Right);
              }}
            />
          </View>
        )}
      </View>
    </FocusParent>
  );
};

export default React.forwardRef(MediaDetailsTemplate);
