import {makeArray} from 'common/utils';

import {mw} from 'mw/MW';
import {HttpMethods} from 'mw/utils/HttpUtils';
import {StringUtils} from 'mw/utils/stringUtils';
import {xmlUtils, XMLNode} from 'mw/utils/xmlUtils';

interface SessionTraceParams {
  TrackId: number;
  TimeOffset: number;
}

interface SessionEndParams extends SessionTraceParams {
  SessionEndCode: number;
  SessionEndDescription: string;
}

interface SessionParams {
  ChannelId?: string;
  ContentId?: string;
  SessionType?: string;
}

interface Identities {
  CpeId?: string;
  CustomerId?: string;
  ProfileId?: string;
}

interface Parameters {
  Language?: string;
  RecommendationContext?: string;
  Output?: string;
}

export interface Arguments {
  [argName: string]: string | number;
}

export interface ArgumentsQuery {
  args?: Arguments;
}

interface Query {
  [propName: string]: any; // fixme: create proper interface description
}

interface BaseQuery {
  identity?: Identities;
  query: Query;
}

export interface Resources {
  id: string;
  args?: any;
}

interface ActionsQuery {
  actionName: string;
  parameters?: Parameters;
  identity: Identities;
  resourceAttributes?: ResourceAttributes;
  resources: Resources[];
  resourceType: string;
}

interface RootActionQuery {
  actionName: string;
  args?: Arguments;
  identity?: Identities;
  options?: Options;
}

interface ActionQuery extends RootActionQuery {
  resourceType: string;
  resourceId: string;
}

interface ResourceAttributes {
  aliasType?: string;
  resourceType?: string;
  resourceId?: string;
  actionName?: string;
}

interface RelationAttributes {
  relationName?: string;
  resourceType?: string;
  resourceId?: string;
}

interface ResourcesRelationQuery extends BaseQuery {
  resourceType: string;
  relationName: string;
  resources: {id: string; aliasType?: string;}[];
  parameters?: Parameters;
}

export interface ResourceQuery extends BaseQuery {
  resourceType: string;
  resourceId: string;
  parameters?: Parameters;
  resourceAttributes?: ResourceAttributes;
}

interface ResourcesQuery extends BaseQuery {
  resourceType: string;
  resourceIds: ResourcesParams[];
  parameters?: Parameters;
}

export interface ResourcesParams {
  id: string;
  attributes?: ResourcesAttributes
}

export interface ResourcesAttributes {
  aliasType?: string;
}

interface RootRelationQuery extends BaseQuery {
  relationName: string;
  parameters?: Parameters;
  relationAttributes?: RelationAttributes;
}

export interface ExtendedRelationQuery extends RootRelationQuery {
  resourceType: string;
  resourceId: string;
  relationSubQuery?: Query;
}

export interface ResourceOptions {
  resourceType?: string,
  options: Options;
}

export interface RelationSubQueryAttributes {
  relationName: string;
  resourceType: string;
  subQuery: Query;
}

export interface RelationsQuery {
  identity?: Identities;
  resourceType?: string;
  resourceId?: string;
  aliasType?: string;
  relations?: string[];
  options?: ResourceOptions[];
  subQueries?: RelationSubQueryAttributes[];
}

interface RelationOption {
  attributes: any;
  value: string;
}

export interface ExtendedRootRelationQuery {
  identity: Identities;
  relationName: string;
  relationOptionsArray: [{attributes: {}; value: ''}];
  relationSubQuery: Query;
  parameters: Parameters;
  relationAttributes: RelationAttributes;
}

export interface Options {
  props?: string;
  propset?: string;
  search?: string;
  nestedsearch?: string;
  filter?: string;
  sort?: string;
  paging?: string;
  limit?: string;
}

export interface RequestQuery {
  method: HttpMethods;
  body?: string;
}

interface ParentalControl {
  isAdult?: boolean;
}

interface QueryParams {
  options?: Options;
  attributes?: any;
  subQueries?: any;
}

class QueryUtils {
  public static instance = new QueryUtils();

  private traxisRequestTag = '<Request xmlns="urn:eventis:traxisweb:1.0"/>';

  private createXmlTraxisRequest(): Document {
    return xmlUtils.getXmlDoc(this.traxisRequestTag);
  }

  public createActionQuery(params: ActionQuery): RequestQuery {
    const xmlDoc = this.createXmlTraxisRequest();
    const request = xmlDoc.getElementsByTagName('Request')[0];
    if (params.identity) {
      request.appendChild(this.createIdentity(xmlDoc, params.identity));
    }
    request.appendChild(this.createParameters(xmlDoc, {Output: 'JSON'}));
    const actionQuery = xmlUtils.createElement(xmlDoc, 'ActionQuery', [
      xmlUtils.createAttr(xmlDoc, {
        actionName: params.actionName,
        resourceType: params.resourceType,
        resourceId: params.resourceId
      })
    ]);
    if (params.args) {
      xmlUtils.appendChild(actionQuery.data, xmlUtils.createMultiElement(xmlDoc, 'Arguments', 'Argument', 'name', params.args));
    }

    if (params.options) {
      xmlUtils.appendChild(actionQuery.data, this.createOptions(xmlDoc, params.options));
    }

    request.appendChild(actionQuery.data);
    return this.createPostRequest(xmlDoc);
  }

  public createRelationsQuery(params: RelationsQuery): RequestQuery {
    const xmlDoc = this.createXmlTraxisRequest();
    const request = xmlDoc.getElementsByTagName('Request')[0];
    if (params.identity) {
      request.appendChild(this.createIdentity(xmlDoc, params.identity));
    }
    request.appendChild(this.createParameters(xmlDoc, {Output: 'JSON'}));

    const resourceAttributes: ResourceAttributes = {
      resourceType: params.resourceType,
      resourceId: params.resourceId,
      ...params.aliasType && {aliasType: params.aliasType}
    };

    const relationsQuery = xmlUtils.createElement(xmlDoc, 'RelationsQuery', [xmlUtils.createAttr(xmlDoc, resourceAttributes)]);

    if (params.relations) {
      xmlUtils.appendChild(relationsQuery.data, xmlUtils.createElement(
        xmlDoc,
        'RelationNames',
        params.relations.map(relation => xmlUtils.createElement(xmlDoc, 'RelationName', relation)))
      );
    }

    if (params.options) {
      xmlUtils.appendChild(relationsQuery.data, xmlUtils.createElement(
        xmlDoc,
        'Options',
        params.options.map(resourceOption => this.createExtendedOptions(xmlDoc, resourceOption.options, resourceOption.resourceType))
      ));
    }

    if (params.subQueries) {
      xmlUtils.appendChild(relationsQuery.data, xmlUtils.createElement(
        xmlDoc,
        'SubQueries',
        params.subQueries.map(subQuery => this.createExtendedSubQueries(xmlDoc, subQuery.relationName, subQuery.resourceType, subQuery.subQuery))
      ));
    }

    request.appendChild(relationsQuery.data);
    return this.createPostRequest(xmlDoc);
  }

  private createExtendedOptions(xmlDoc: Document, options: Options, resourceType?: string): XMLNode[] {
    return Object.entries(options).map(
      ([key, value]) => (
        xmlUtils.createElement(xmlDoc, 'Option', [
          xmlUtils.createAttr(xmlDoc, {type: key, ...resourceType && {resourceType: resourceType}}),
          value
        ])
      )
    );
  }

  private createExtendedSubQueries(xmlDoc: Document, relationName: string, resourceType: string, subQuery: any): XMLNode {
    return xmlUtils.createElement(xmlDoc, 'SubQuery', [
      this.createAttributes(xmlDoc, {relationName: relationName, resourceType: resourceType}),
      this.createOptionsAndSubQueries(xmlDoc, subQuery)
    ]);
  }

  public createRootActionQuery(params: RootActionQuery): RequestQuery {
    const xmlDoc = this.createXmlTraxisRequest();
    const request = xmlDoc.getElementsByTagName('Request')[0];
    request.appendChild(this.createParameters(xmlDoc, {Output: 'JSON'}));

    if (params.identity) {
      request.appendChild(this.createIdentity(xmlDoc, params.identity));
    }

    const paramsArray = [
      ...makeArray(this.createArguments(xmlDoc, params.args)),
      xmlUtils.createAttr(xmlDoc, {actionName: params.actionName})
    ];

    if (params.options) {
      paramsArray.push(this.createOptions(xmlDoc, params.options));
    }

    request.appendChild(xmlUtils.createElement(xmlDoc, 'RootActionQuery', paramsArray).data);

    return this.createPostRequest(xmlDoc);
  }

  public createResourceQuery(params: ResourceQuery): RequestQuery {
    const xmlDoc = this.createXmlTraxisRequest();
    const request = xmlDoc.getElementsByTagName('Request')[0];
    const parameters = params.parameters || {};

    request.appendChild(this.createParameters(xmlDoc, {...this.getQueryDefaultParameters, ...parameters}));
    if (params.identity) {
      request.appendChild(this.createIdentity(xmlDoc, params.identity));
    }

    const resourceAttributes: ResourceAttributes = params.resourceAttributes || {};
    resourceAttributes.resourceType = params.resourceType;
    resourceAttributes.resourceId = params.resourceId;

    request.appendChild(xmlUtils.createElement(xmlDoc, 'ResourceQuery', [
      xmlUtils.createAttr(xmlDoc, resourceAttributes),
      this.createOptionsAndSubQueries(xmlDoc, params.query)
    ]).data);
    return this.createPostRequest(xmlDoc);
  }

  public createResourcesQuery(params: ResourcesQuery): RequestQuery {
    const xmlDoc = this.createXmlTraxisRequest();
    const request = xmlDoc.getElementsByTagName('Request')[0];
    const parameters = params.parameters || {};

    request.appendChild(this.createParameters(xmlDoc, {...this.getQueryDefaultParameters, ...parameters}));
    if (params.identity) {
      request.appendChild(this.createIdentity(xmlDoc, params.identity));
    }

    const resourceIds = xmlUtils.createElement(xmlDoc, 'ResourceIds');
    params.resourceIds.forEach((resourceId) => {
      xmlUtils.appendChild(resourceIds.data, xmlUtils.createElement(xmlDoc, 'ResourceId', [
        resourceId.attributes && xmlUtils.createAttr(xmlDoc, resourceId.attributes),
        resourceId.id
      ]));
    });

    xmlUtils.appendChild(request, xmlUtils.createElement(xmlDoc, 'ResourcesQuery', [
      xmlUtils.createAttr(xmlDoc, {resourceType: params.resourceType}),
      resourceIds,
      this.createOptionsAndSubQueries(xmlDoc, params.query)
    ]));

    return this.createPostRequest(xmlDoc);
  }

  public createResourcesRelationQuery(params: ResourcesRelationQuery): RequestQuery {
    const xmlDoc = this.createXmlTraxisRequest();
    const request = xmlDoc.getElementsByTagName('Request')[0];
    const parameters = params.parameters || {};

    request.appendChild(this.createParameters(xmlDoc, {...this.getQueryDefaultParameters, ...parameters}));
    if (params.identity) {
      request.appendChild(this.createIdentity(xmlDoc, params.identity));
    }

    const {resourceType, relationName, resources} = params;

    const resourcesRelationQuery = xmlUtils.createElement(
      xmlDoc,
      'ResourcesRelationQuery',
      xmlUtils.createAttr(xmlDoc, {resourceType, relationName})
    );

    const resourceIdsTags = xmlUtils.createElement(xmlDoc, 'ResourceIds');
    resources.forEach(({id, aliasType}) => {
      const resourceIdTag = xmlUtils.createElement(xmlDoc, 'ResourceId', [
        aliasType && xmlUtils.createAttr(xmlDoc, {aliasType}),
        xmlUtils.createText(xmlDoc, id)
      ]);
      xmlUtils.appendChild(resourceIdsTags.data, resourceIdTag);
    });
    resourcesRelationQuery.data.appendChild(resourceIdsTags.data);

    this.createOptionsAndSubQueries(xmlDoc, params.query)
      .forEach(child => resourcesRelationQuery.data.appendChild(child.data));

    request.appendChild(resourcesRelationQuery.data);

    return this.createPostRequest(xmlDoc);
  }

  public createExtendedRelationQuery(params: ExtendedRelationQuery): RequestQuery {
    const xmlDoc = this.createXmlTraxisRequest();
    const request = xmlDoc.getElementsByTagName('Request')[0];
    const parameters = params.parameters || {};

    request.appendChild(this.createParameters(xmlDoc, {...this.getQueryDefaultParameters, ...parameters}));
    if (params.identity) {
      request.appendChild(this.createIdentity(xmlDoc, params.identity));
    }

    const relationAttributes: RelationAttributes = params.relationAttributes || {};
    relationAttributes.relationName = params.relationName;
    relationAttributes.resourceId = params.resourceId;
    relationAttributes.resourceType = params.resourceType;

    const queryParams: QueryParams = this.filterQueryParams(params.query);
    const relationQuery = xmlUtils.createElement(xmlDoc, 'RelationQuery', [
      xmlUtils.createAttr(xmlDoc, relationAttributes),
      queryParams?.options && xmlUtils.createElement(xmlDoc, 'Options', this.createExtendedOptions(xmlDoc, queryParams.options, params.resourceType)),
      queryParams?.subQueries && xmlUtils.createElement(xmlDoc, 'SubQueries', Object.entries(queryParams.subQueries).map(
        ([key, value]) => this.createExtendedSubQueries(xmlDoc, key, params.resourceType, value)
      ))
    ]);
    request.appendChild(relationQuery.data);

    return this.createPostRequest(xmlDoc);
  }

  public createRootRelationQuery(params: RootRelationQuery): RequestQuery {
    const xmlDoc = this.createXmlTraxisRequest();
    const request = xmlDoc.getElementsByTagName('Request')[0];
    const parameters = params.parameters || {};

    request.appendChild(this.createParameters(xmlDoc, {...this.getQueryDefaultParameters, ...parameters}));
    if (params.identity) {
      request.appendChild(this.createIdentity(xmlDoc, params.identity));
    }
    const relationAttributes = params.relationAttributes || {};
    relationAttributes.relationName = params.relationName;
    xmlUtils.appendChild(request, xmlUtils.createElement(xmlDoc, 'RootRelationQuery', [
      xmlUtils.createAttr(xmlDoc, relationAttributes),
      this.createOptionsAndSubQueries(xmlDoc, params.query)
    ]));
    return this.createPostRequest(xmlDoc);
  }

  public createExtendedRootRelationQuery(params: ExtendedRootRelationQuery): RequestQuery {
    const xmlDoc = this.createXmlTraxisRequest();
    const request = xmlDoc.getElementsByTagName('Request')[0];
    const parameters = params.parameters || {};

    request.appendChild(this.createParameters(xmlDoc, {...this.getQueryDefaultParameters, ...parameters}));
    if (params.identity) {
      request.appendChild(this.createIdentity(xmlDoc, params.identity));
    }
    const relationAttributes = params.relationAttributes || {};
    relationAttributes.relationName = params.relationName;

    const rootRelationQuery = xmlUtils.createElement(xmlDoc, 'RootRelationQuery', [
      xmlUtils.createAttr(xmlDoc, relationAttributes),
      xmlUtils.createElement(xmlDoc, 'Options', params.relationOptionsArray.map((option: RelationOption) => {
        return xmlUtils.createElement(xmlDoc, 'Option', [
          this.createAttributes(xmlDoc, option.attributes),
          option.value
        ]);
      })),
      params.relationSubQuery ? this.createSubQueries(xmlDoc, params.relationSubQuery) : undefined
    ]);
    request.appendChild(rootRelationQuery.data);

    return this.createPostRequest(xmlDoc);
  }

  public createRootResourceQuery(params: Options): RequestQuery {
    const xmlDoc = this.createXmlTraxisRequest();
    const request = xmlDoc.getElementsByTagName('Request')[0];
    request.appendChild(this.createParameters(xmlDoc, {Output: 'JSON'}));
    xmlUtils.appendChild(request, xmlUtils.createElement(xmlDoc, 'RootResourceQuery', [
      this.createOptionsAndSubQueries(xmlDoc, params)
    ]));
    return this.createPostRequest(xmlDoc);
  }

  public createActionsQuery(params: ActionsQuery): RequestQuery {
    const xmlDoc = this.createXmlTraxisRequest();
    const request = xmlDoc.getElementsByTagName('Request')[0];
    if (params.identity) {
      request.appendChild(this.createIdentity(xmlDoc, params.identity));
    }
    const parameters = params.parameters || {};
    parameters.Output = 'JSON';
    request.appendChild(this.createParameters(xmlDoc, parameters));

    const resourceAttributes = params.resourceAttributes || {};
    resourceAttributes.actionName = params.actionName;
    resourceAttributes.resourceType = params.resourceType;

    xmlUtils.appendChild(request, xmlUtils.createElement(xmlDoc, 'ActionsQuery', [
      xmlUtils.createAttr(xmlDoc, resourceAttributes),
      this.createResources(xmlDoc, params.resources)
    ]));
    return this.createPostRequest(xmlDoc);
  }

  public create5jSession(sessionParams: SessionParams): RequestQuery {
    const xmlDoc = xmlUtils.getXmlDoc('<CreateSession />');
    const createSession = xmlDoc.getElementsByTagName('CreateSession')[0];
    xmlUtils.setParams(xmlDoc, createSession, this.createElements(xmlDoc, sessionParams));
    return this.createPostRequest(xmlDoc);
  }

  public keepAlive5jSession(params: SessionTraceParams): string {
    const xmlDoc = xmlUtils.getXmlDoc('<KeepAliveNotification />');
    const keepAliveNotification = xmlDoc.getElementsByTagName('KeepAliveNotification')[0];
    xmlUtils.setParams(xmlDoc, keepAliveNotification, this.createElements(xmlDoc, params));
    return xmlUtils.serializeXmlDoc(xmlDoc);
  }

  public delete5jSession(params: SessionEndParams): string {
    const xmlDoc = xmlUtils.getXmlDoc('<DeleteSession />');
    const deleteSession = xmlDoc.getElementsByTagName('DeleteSession')[0];
    xmlUtils.setParams(xmlDoc, deleteSession, this.createElements(xmlDoc, params));
    return xmlUtils.serializeXmlDoc(xmlDoc);
  }

  public getIsAdultFilter(params: ParentalControl, isTitle?: boolean): string | null {
    let ret = typeof params.isAdult === 'undefined' ? null : 'IsAdult==' + (params.isAdult ? 'true' : 'false');
    if (ret && isTitle) {
      const noRatingsFilter = '(isnull(Ratings))'; // aditional parenthesis to workaround ADRA-4061
      const adultAge = 18; // TODO: move to constants configuration
      const noAdultInferredMinimumAgeFilter = '!(Ratings.InferredMinimumAge>=' + adultAge + ')';
      ret = '(' + ret + '&&(' + noRatingsFilter + '||' + noAdultInferredMinimumAgeFilter + '))';
    }
    return ret;
  }

  public createPagingParameter(paging: {offset: number; pageSize: number}) {
    return `${paging.offset},${paging.pageSize},rc`;
  }

  public parseXMLOrJSON(value?: string): Promise<any> {
    if (!value) {
      return Promise.resolve('');
    }

    return /^<\?xml/.test(value) ? xmlUtils.xml2json(value) : Promise.resolve(JSON.parse(value));
  }

  private createArguments(xmlDoc: Document, params: any) {
    if (!params) {
      return undefined;
    }
    const args = [];
    for (const param in params) {
      if (params.hasOwnProperty(param)) {
        const paramArray = makeArray(params[param]);
        for (let i = 0; i < paramArray.length; ++i) {
          args.push(xmlUtils.createElement(xmlDoc, 'Argument', [
            xmlUtils.createAttr(xmlDoc, {name: param}),
            xmlUtils.createText(xmlDoc, paramArray[i])
          ]));
        }
      }
    }
    return xmlUtils.createElement(xmlDoc, 'Arguments', args);
  }

  private createResources(xmlDoc: Document, resources: any[]): XMLNode {
    const args = resources.map(resource => {
      if (StringUtils.isString(resource) || !isNaN(resource)) {
        return xmlUtils.createElement(xmlDoc, 'Resource', [
          xmlUtils.createAttr(xmlDoc, {id: resource})
        ]);
      }
      return xmlUtils.createElement(xmlDoc, 'Resource', [
        xmlUtils.createAttr(xmlDoc, {id: resource.id}),
        this.createArguments(xmlDoc, resource.args)
      ]);
    });
    return xmlUtils.createElement(xmlDoc, 'Resources', args);
  }

  private createElements(xmlDoc: Document, params: any): XMLNode[] {
    const elements: XMLNode[] = [];
    for (const param in params) {
      if (params.hasOwnProperty(param)) {
        elements.push(xmlUtils.createElement(xmlDoc, param, (params as any)[param]));
      }
    }
    return elements;
  }

  private createIdentity(xmlDoc: Document, params: Identities): Node {
    return xmlUtils.createElement(xmlDoc, 'Identity', this.createElements(xmlDoc, params)).data;
  }

  private createParameters(xmlDoc: Document, params: Parameters) {
    return xmlUtils.createMultiElement(xmlDoc, 'Parameters', 'Parameter', 'name', params).data;
  }

  private createOptions(xmlDoc: Document, params: Options): XMLNode {
    return xmlUtils.createMultiElement(xmlDoc, 'Options', 'Option', 'type', params);
  }

  private createSubQueries(xmlDoc: Document, params: any): XMLNode {
    return xmlUtils.createMultiElement(xmlDoc, 'SubQueries', 'SubQuery', 'relationName', params, this.createOptionsAndSubQueries);
  }

  private createAttributes(xmlDoc: Document, params: any) {
    const paramsArray: any[] = [];
    for (const prop in params) {
      if (params.hasOwnProperty(prop)) {
        const attribute: any = {};
        attribute[prop] = params[prop];
        paramsArray.push(xmlUtils.createAttr(xmlDoc, attribute));
      }
    }
    return paramsArray;
  }

  private filterQueryParams = (params: any): QueryParams => {
    let options: Options | undefined;
    let attributes: any;
    let subQueries: any;
    for (const prop in params) {
      if (params.hasOwnProperty(prop)) {
        if (/(props|propset|search|nestedsearch|filter|sort|paging|limit)/.test(prop)) {
          options = options || {};
          (options as any)[prop] = params[prop];
        } else if (/(attributes)/.test(prop)) {
          attributes = attributes || {};
          (attributes as any)[prop] = params[prop];
        } else {
          subQueries = subQueries || {};
          subQueries[prop] = params[prop];
        }
      }
    }
    return {
      options,
      attributes,
      subQueries
    };
  }

  private createOptionsAndSubQueries = (xmlDoc: Document, params: any): XMLNode[] => {
    const paramsArray: XMLNode[] = [];
    const queryParams: QueryParams = this.filterQueryParams(params);

    if (queryParams.options) {
      paramsArray.push(this.createOptions(xmlDoc, queryParams.options));
    }
    if (queryParams.attributes) {
      for (const attribute in queryParams.attributes) {
        paramsArray.push(...(this.createAttributes(xmlDoc, attribute)));
      }
    }
    if (queryParams.subQueries) {
      paramsArray.push(this.createSubQueries(xmlDoc, queryParams.subQueries));
    }
    return paramsArray;
  }

  private createPostRequest(xmlDoc: Document): RequestQuery {
    return {
      method: HttpMethods.POST,
      body: xmlUtils.serializeXmlDoc(xmlDoc)
    };
  }

  private get getQueryDefaultParameters(): Partial<Parameters> {
    return {
      ...mw.customer.currentProfile?.uiLanguage && {Language: mw.customer.currentProfile?.uiLanguage},
      Output: 'JSON'
    };
  }
}

export const queryUtils: QueryUtils = QueryUtils.instance;
