import {createStyles} from 'common-styles';
import React, {useCallback, useRef, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {View, Animated} from 'react-native';

import {dimensions, RADIUS} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors, constColors} from 'common-styles/variables/base-colors';

import AnimatedScrollView from 'components/epg/animated/AnimatedScrollView';
import FocusParent from 'components/FocusParent';
import NitroxInteractive from 'components/NitroxInteractive';
import NitroxText, {TextType} from 'components/NitroxText';

const titleHeight = 40;
const tileMarginTop = 3;
const numberContainerWidth = 58;
const collapsedItemsCount = 5;
const expandedItemsCount = 9;

const styles = createStyles({
  container: {
    width: dimensions.payments.order.contentWidth
  },
  focusParent: {
    flex: 1
  },
  listElement: {
    flexDirection: 'row',
    height: titleHeight,
    marginTop: tileMarginTop,
    borderWidth: dimensions.payments.product.tile.borderWidth,
    borderRadius: RADIUS
  },
  listNumber: {
    flexShrink: 0,
    width: numberContainerWidth
  },
  listText: {
    paddingRight: dimensions.margins.large
  },
  showMore: {
    width: dimensions.payments.order.contentWidth,
    height: titleHeight,
    borderWidth: dimensions.payments.product.tile.borderWidth,
    borderRadius: RADIUS,
    justifyContent: 'center'
  }
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  text: {
    color: colors.popup.text,
    alignSelf: 'center',
    textAlign: 'center'
  },
  listElement: {
    borderColor: constColors.transparent
  },
  listElementWithBackground: {
    backgroundColor: colors.payments.order.numberedList.background
  },
  listElementFocused: {
    borderColor: colors.defaultColors.icon
  }
}));

type ListElementProps = {
  number: string;
  text: string;
  withBackground: boolean;
  textType: TextType;
  interactive: boolean;
  focusPriority?: number;
  hasTVPreferredFocus?: boolean;
};

const ListElement: React.FunctionComponent<ListElementProps> = props => {
  const {
    number,
    text,
    withBackground,
    textType,
    interactive,
    focusPriority = 0,
    hasTVPreferredFocus = false
  } = props;

  const dynamicStyle = stylesUpdater.getStyles();

  let Component = null;
  let componentProps = {};
  if (interactive) {
    Component = NitroxInteractive;
    componentProps = {
      styleFocused: dynamicStyle.listElementFocused,
      focusPriority: focusPriority,
      hasTVPreferredFocus: hasTVPreferredFocus
    };
  } else {
    Component = View;
  }

  return (
    <Component
      style={[styles.listElement, dynamicStyle.listElement, withBackground && dynamicStyle.listElementWithBackground]}
      {...componentProps}
    >
      <NitroxText
        style={[dynamicStyle.text, styles.listNumber]}
        textType={textType}
        numberOfLines={1}
      >
        {number}
      </NitroxText>
      <NitroxText
        style={[dynamicStyle.text, styles.listText]}
        textType={textType}
        numberOfLines={1}
      >
        {text}
      </NitroxText>
    </Component>
  );
};

type NumberedListProps = {
  items: string[];
  header: string;
};

const NumberedList: React.FunctionComponent<NumberedListProps> = props => {
  const {
    items,
    header
  } = props;

  const {t} = useTranslation();
  const dynamicStyle = stylesUpdater.getStyles();
  const listOffset = useRef(new Animated.Value(0));

  const [visibleItemsCount, setVisibleItemsCount] = useState(Math.min(items.length, collapsedItemsCount));
  const [showMoreVisible, setShowMoreVisible] = useState(items.length > collapsedItemsCount);

  const listElements = useMemo(() => (
    items.map((item, index) => {
      const number = index + 1;
      return (
        <ListElement
          key={number}
          number={`${number}`}
          text={item}
          withBackground={!!(number % 2)}
          textType='callout'
          interactive={!showMoreVisible && items.length > collapsedItemsCount || showMoreVisible && index < collapsedItemsCount}
          focusPriority={items.length - number}
          hasTVPreferredFocus={!showMoreVisible && index === collapsedItemsCount}
        />
      );
    })
  ), [items, showMoreVisible]);

  const scrollViewSize = useMemo(() => ({
    width: dimensions.payments.order.contentWidth,
    height: (titleHeight + tileMarginTop) * (visibleItemsCount + 1)
  }), [visibleItemsCount]);

  const containerStyle = useMemo(() => ({
    ...styles.container,
    height: (titleHeight + tileMarginTop) * (visibleItemsCount + 1) + (showMoreVisible ? titleHeight : 0)
  }), [visibleItemsCount, showMoreVisible]);

  const showMoreStyle = useMemo(() => ({
    ...styles.showMore,
    ...dynamicStyle.text,
    ...dynamicStyle.listElement
  }), [dynamicStyle]);

  const onShowMorePress = useCallback(() => {
    setVisibleItemsCount(Math.min(items.length, expandedItemsCount));
    setShowMoreVisible(false);
  }, [items, setVisibleItemsCount, setShowMoreVisible]);

  return (
    <FocusParent style={containerStyle} enterStrategy='byPriority'>
      <FocusParent style={styles.focusParent} enterStrategy='byPriority'>
        <ListElement
          number={t('payments.order.assetsList.number')}
          text={header}
          withBackground={false}
          textType={'callout-small'}
          interactive={false}
        />
        <AnimatedScrollView
          size={scrollViewSize}
          offsetX={listOffset.current}
        >
          {listElements}
        </AnimatedScrollView>
      </FocusParent>
      {showMoreVisible && (
        <NitroxInteractive
          style={showMoreStyle}
          styleFocused={dynamicStyle.listElementFocused}
          focusPriority={items.length}
          onPress={onShowMorePress}
        >
          <NitroxText
            style={dynamicStyle.text}
            textType={'callout-small'}
            numberOfLines={1}
          >
            {t('payments.order.assetsList.more')}
          </NitroxText>
        </NitroxInteractive>
      )}
    </FocusParent>
  );
};

export default React.memo(NumberedList);
