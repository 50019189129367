import {createStyles} from 'common-styles';
import React, {useState, forwardRef, useCallback, ReactNode, useMemo} from 'react';
import {Image, ViewStyle, StyleProp, GestureResponderEvent, ImageStyle, TextStyle, TextProps, LayoutChangeEvent, ImageSourcePropType} from 'react-native';

import {dimensions, RADIUS, isBigScreen, isMobile} from 'common/constants';
import {humanCaseToSnakeCase} from 'common/HelperFunctions';
import {TestProps} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors, transparent, constColors} from 'common-styles/variables/base-colors';

import {Gradient} from 'brand/ColorTypes';
import NitroxText, {TextType} from 'components/NitroxText';
import {useChangeEffect} from 'hooks/Hooks';

import {Icon, IconProps} from './Icon';
import NitroxButtonGrosso from './NitroxButton.grosso';
import NitroxInteractive, {FocusPriorityProps} from './NitroxInteractive';

export enum NitroxButtonTheme {
  Primary = 1, Secondary, Tertiary, Quaternary, Icon, Destructive
}

export enum IconPosition {
  LEFT,
  RIGHT
}

export type ButtonIconProps = IconProps & {
  position?: IconPosition;
}

type NewButtonProps = {
  /**
   * defines if icon should be rendered in corner of button
   */
  isCornerIcon?: boolean;
  /**
   * used for tertiary theme only, defines if special button icon should be displayed
   */
  specialIcon?: boolean;
  /**
   * used for quaternary theme only
   */
  gradient?: Gradient;
  /**
   * used as a temporary solution for not redesigned screens
   */
  useOldImplementation?: boolean;
  themeFocused?: NitroxButtonTheme;
  /**
   * by default set to true. If set to false, view is rendered instead of NitroxInteractive
   */
  focusable?: boolean;
}

export type NitroxButtonProps = {
  style?: StyleProp<ViewStyle>; //FIXME: CL-3880 to be removed?
  styleFocused?: StyleProp<ViewStyle>; //FIXME: CL-3880 to be removed
  styleSelected?: StyleProp<ViewStyle>; //FIXME: CL-3880 to be removed
  imageStyle?: ImageStyle; //FIXME: CL-3880 to be removed
  source?: ImageSourcePropType;
  uri?: string;
  icon?: ButtonIconProps;
  iconContainerStyle?: StyleProp<ViewStyle>; //FIXME: CL-3880 to be removed
  text?: string;
  textStyle?: StyleProp<TextStyle>; //FIXME: CL-3880 to be removed
  textStyleFocused?: StyleProp<TextStyle>; //FIXME: CL-3880 to be removed
  textStyleSelected?: StyleProp<TextStyle>; //FIXME: CL-3880 to be removed
  textType?: TextType; //FIXME: CL-3880 to be removed
  textTypeFocused?: TextType; //FIXME: CL-3880 to be removed
  textProps?: TextProps; //FIXME: CL-3880 to be removed
  border?: boolean; //FIXME: CL-3880 to be removed
  isSelected?: boolean;
  theme?: NitroxButtonTheme;
  activeOpacity?: number; //FIXME: CL-3880 to be removed
  hasTvPreferredFocus?: boolean;
  onPress?: (event?: GestureResponderEvent) => void;
  onLayout?: (event: LayoutChangeEvent) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean; //FIXME: CL-3880 to be removed
  children?: ReactNode;
  numberOfLines?: number;
  staticallyFocused?: boolean;
} & TestProps & FocusPriorityProps & NewButtonProps;

type ButtonColors = {
  background: {
    focused: string;
    unfocused: string;
  };
  text: {
    focused: string;
    unfocused: string;
  };
}
function mapStyleToThemeStyle(style: ButtonColors, focused: boolean, withBorder?: boolean): ThemeStyle {
  let borderColor;
  if (withBorder) {
    borderColor = focused ? style.text.focused : style.text.unfocused;
  }

  return {
    fillColor: focused ? style.background.focused : style.background.unfocused,
    textColor: focused ? style.text.focused : style.text.unfocused,
    borderColor: borderColor
  };
}

export const styles = createStyles({
  container: {
    justifyContent: 'center',
    backgroundColor: constColors.transparent,
    borderRadius: RADIUS,
    alignItems: 'center',
    height: dimensions.buttons.standard
  },
  buttonBorder: {
    borderWidth: 1
  },
  tvSpecific: {
    padding: 0,
    paddingLeft: dimensions.margins.xxLarge,
    paddingRight: dimensions.margins.xxLarge
  },
  tvSpecificSelected: {
    paddingLeft: 28,
    paddingRight: 28,
    borderWidth: dimensions.button.selected.borderWidth
  },
  mobileSpecific: {
    padding: 0,
    paddingLeft: dimensions.margins.medium,
    paddingRight: dimensions.margins.medium
  },
  mobileSpecificSelected: {
    paddingLeft: 13,
    paddingRight: 13,
    borderWidth: dimensions.button.selected.borderWidth
  },
  iconTextContainer: {
    padding: dimensions.margins.small,
    flexDirection: 'row',
    justifyContent: 'center'
  },
  textStyle: {
    alignSelf: 'center',
    flexShrink: 1
  },
  textWithIconLeftStyle: {
    paddingLeft: dimensions.margins.small
  },
  textWithIconRightStyle: {
    paddingRight: dimensions.margins.small
  },
  disabled: {
    opacity: dimensions.opacity.xlow
  }
});

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  nitroxButtonThemePrimaryFocused: mapStyleToThemeStyle(colors.button.primary, true),
  nitroxButtonThemePrimaryUnfocused: mapStyleToThemeStyle(colors.button.primary, false),
  nitroxButtonThemeSecondaryFocused: mapStyleToThemeStyle(colors.button.secondary, true),
  nitroxButtonThemeSecondaryUnfocused: mapStyleToThemeStyle(colors.button.secondary, false),
  nitroxButtonThemeTertiaryFocused: mapStyleToThemeStyle(colors.button.tertiary, true, true),
  nitroxButtonThemeTertiaryUnfocused: mapStyleToThemeStyle(colors.button.tertiary, false, true),
  nitroxButtonThemeDestructiveFocused: mapStyleToThemeStyle(colors.button.destructive, true),
  nitroxButtonThemeDestructiveUnfocused: mapStyleToThemeStyle(colors.button.destructive, false)
}));

type ThemeStyle = {
  fillColor: string;
  textColor: string;
  borderColor?: string;
};

const iconView = (icon: IconProps, defaultColor: string) => {
  return <Icon type={icon.type} size={icon.size} color={icon.color || defaultColor} />;
};

const NitroxButtonComponent: React.FunctionComponent<NitroxButtonProps> = (props, ref) => {
  const {
    onFocus,
    onBlur,
    numberOfLines = 1,
    staticallyFocused,
    theme = isBigScreen ? NitroxButtonTheme.Primary : undefined
  } = props;
  const [isPressed, setIsPressed] = useState(false);
  const [focused, setFocused] = useState(staticallyFocused ?? false);

  useChangeEffect(() => {
    if (staticallyFocused != null) {
      setFocused(staticallyFocused);
    }
  }, [staticallyFocused]);

  const dynamicStyles = dynamicStylesUpdater.getStyles();
  const defaultStyle = dynamicStyles.nitroxButtonThemePrimaryFocused;
  const colorMapFocused = useMemo(() => {
    const colorMap = new Map<NitroxButtonTheme | undefined, ThemeStyle>();
    colorMap.set(NitroxButtonTheme.Primary, defaultStyle);
    colorMap.set(NitroxButtonTheme.Secondary, dynamicStyles.nitroxButtonThemeSecondaryFocused);
    colorMap.set(NitroxButtonTheme.Tertiary, dynamicStyles.nitroxButtonThemeTertiaryFocused);
    colorMap.set(NitroxButtonTheme.Destructive, dynamicStyles.nitroxButtonThemeDestructiveFocused);
    return colorMap;
  }, [defaultStyle, dynamicStyles.nitroxButtonThemeDestructiveFocused, dynamicStyles.nitroxButtonThemeSecondaryFocused, dynamicStyles.nitroxButtonThemeTertiaryFocused]);

  const colorMapUnfocused = useMemo(() => {
    const colorMap = new Map<NitroxButtonTheme | undefined, ThemeStyle>();
    colorMap.set(NitroxButtonTheme.Primary, dynamicStyles.nitroxButtonThemePrimaryUnfocused);
    colorMap.set(NitroxButtonTheme.Secondary, dynamicStyles.nitroxButtonThemeSecondaryUnfocused);
    colorMap.set(NitroxButtonTheme.Tertiary, dynamicStyles.nitroxButtonThemeTertiaryUnfocused);
    colorMap.set(NitroxButtonTheme.Destructive, dynamicStyles.nitroxButtonThemeDestructiveUnfocused);
    return colorMap;
  }, [dynamicStyles.nitroxButtonThemeDestructiveUnfocused, dynamicStyles.nitroxButtonThemePrimaryUnfocused, dynamicStyles.nitroxButtonThemeSecondaryUnfocused, dynamicStyles.nitroxButtonThemeTertiaryUnfocused]);
  /* We use colorMapUnfocused only for selectable buttons on mobile, when property isSelected exist and equals false */
  const colorMap = focused || (isMobile && props.isSelected !== false) ? colorMapFocused : colorMapUnfocused;

  const onFocusHandler = useCallback(() => {
    setFocused(true);
    if (onFocus) {
      onFocus();
    }
  }, [onFocus, setFocused]);

  const onBlurHandler = useCallback(() => {
    setFocused(false);
    if (onBlur) {
      onBlur();
    }
  }, [onBlur, setFocused]);

  const source = props.source || (props.uri ? {uri: props.uri} : null);
  const iconPosition = props.icon && props.icon.position ? props.icon.position : IconPosition.LEFT;
  const colorMapTheme = colorMap.get(theme) || defaultStyle;
  const textWithIconStyle = iconPosition === IconPosition.LEFT ? styles.textWithIconLeftStyle : styles.textWithIconRightStyle;

  // TODO CL-3880 This logic is counter intuitive and should be changed or simplified or variables in color-palette should be renamed
  const fillColor = colorMapTheme.fillColor;
  let borderColor = colorMapTheme.borderColor ? colorMapTheme.borderColor : fillColor;
  let textColor = colorMapTheme.textColor;
  if (!isMobile && !props.isSelected && !focused) {
    if (!isPressed) {
      textColor = transparent(textColor, dimensions.opacity.xhigh);
    }
    borderColor = transparent(borderColor, dimensions.opacity.xhigh);
  }

  const testID = useMemo(() => {
    let suffix;
    if (props.testID) {
      return props.testID;
    } else if (props.text) {
      suffix = humanCaseToSnakeCase(props.text);
    } else if (props.icon?.type) {
      suffix = humanCaseToSnakeCase(props.icon.type);
    }
    return suffix ? `button_${suffix}` : 'button';
  }, [props.icon, props.testID, props.text]);

  return (
    <NitroxInteractive
      {...props}
      hasTVPreferredFocus={props.hasTvPreferredFocus}
      testID={testID}
      ref={ref}
      activeOpacity={typeof props.activeOpacity !== 'undefined' ? props.activeOpacity : isBigScreen ? dimensions.opacity.xxxxhigh : dimensions.opacity.xhigh}
      // TODO: CL-7054 divide focusable/non-focusable logic inside component
      // this is just workaround using fact, that disabled NitroxInteractive is non focusable
      disabled={props.disabled || staticallyFocused != null}
      style={[styles.container,
        props.border && styles.buttonBorder,
        props.border || theme === NitroxButtonTheme.Tertiary && !isPressed ? {borderColor: borderColor} : {backgroundColor: fillColor},
        isBigScreen && focused && {backgroundColor: fillColor},
        !!props.text && (props.iconContainerStyle || styles.iconTextContainer),
        isBigScreen ? styles.tvSpecific : styles.mobileSpecific,
        props.isSelected && props.border && (isBigScreen ? styles.tvSpecificSelected : styles.mobileSpecificSelected),
        props.disabled && styles.disabled,
        props.style,
        focused && props.styleFocused,
        props.isSelected && props.styleSelected
      ]}
      onPress={props.onPress}
      onPressIn={() => setIsPressed(true)}
      onPressOut={() => setIsPressed(false)}
      onFocus={onFocusHandler}
      onBlur={onBlurHandler}
      onLayout={props.onLayout}
      focusPriority={props.focusPriority}
    >
      {props.icon && iconPosition === IconPosition.LEFT && iconView(props.icon, textColor)}
      {source && <Image style={props.imageStyle} source={source} />}
      {!!props.text && (
        <NitroxText
          textType={(focused && props.textTypeFocused) || props.textType || 'buttons'}
          numberOfLines={numberOfLines}
          style={[
            {color: textColor},
            styles.textStyle,
            props.icon && textWithIconStyle,
            props.textStyle,
            focused && props.textStyleFocused,
            props.isSelected && props.textStyleSelected
          ]}
          {...props.textProps}
        >
          {props.text}
        </NitroxText>
      )}
      {props.children}
      {props.icon && iconPosition === IconPosition.RIGHT && iconView(props.icon, textColor)}
    </NitroxInteractive>
  );
};
const NitroxButtonWithRef = forwardRef(NitroxButtonComponent);

const NitroxButton: React.FunctionComponent<NitroxButtonProps> = (props, ref) => {
  const {useOldImplementation = false} = props;
  if (isBigScreen && !useOldImplementation) {
    return <NitroxButtonGrosso {...props} ref={ref} />;
  }
  return <NitroxButtonWithRef {...props} ref={ref} />;
};

export default React.memo(forwardRef(NitroxButton));
