import {ADR8Requester} from 'mw/bo-proxy/bo/adr8/ADR8Requester';
import {UXManager} from 'mw/bo-proxy/uxmanager/UXManager';
import {LocalNxfd, NxfdSections} from 'mw/common/NXFFTypes';
import {Authority, DateTimeFormat, DeploymentConfigurationInterface, ParentalControlType, SelectableBO} from 'mw/nxff-config/nxfd-schema';

export {Authority, ParentalControlType, SelectableBO};
export type TimeFormatDescription = DateTimeFormat;
export type DateFormatDescription = TimeFormatDescription

export interface Credentials {
  username: string;
  password?: string;
}

interface NXFFConfig {
  getProfileName(): string;
  getSelectableBackOffices(): SelectableBO[];
  getBackOfficeCode(): string;
  getConfig(): DeploymentConfigurationInterface;
}

type NxfdBackOffices = {
  [key: string]: NxfdSections | undefined
};

type NxfdProfile = NxfdSections & {
  BackOffices?: NxfdBackOffices;
  UseProfile?: string;
  UseFeatures?: string;
};

type NxfdProfiles = {
  [key: string]: NxfdProfile | undefined
};

type Nxfd = {
  Profiles: NxfdProfiles
};

type NxfdEnvironmentSection = DeploymentConfigurationInterface['Environment'] & {
  EnabledRemoteNXFD?: boolean
};

class NXFF implements NXFFConfig {

  public static instance = new NXFF();
  private readonly jsonConfig: Nxfd;
  private readonly defaultConfig: NxfdSections;
  private readonly localConfig: LocalNxfd;
  private readonly schemaVersion: number;

  private currentProfileJson: NxfdProfile = {};
  private currentProfileTag = '';
  private useProfileJson: NxfdProfile | undefined;
  private currentBackOffice: NxfdProfile | undefined;
  private currentBackOfficeTag = '';
  private backOffices: NxfdBackOffices = {};

  private config: DeploymentConfigurationInterface = {} as DeploymentConfigurationInterface;

  public constructor() {
    this.jsonConfig = require('../nxff-config/nxfd/nxfd.json');
    if (!this.jsonConfig) {
      throw new Error('Config is not set!');
    }
    this.defaultConfig = require('../nxff-config/nxfd-defaults.json');
    if (!this.defaultConfig) {
      throw new Error('Default config is not set!');
    }
    this.localConfig = require('../../../nxfd.override.json');

    // Schema version is contained in 'title' property of schema file, because of UXM limitations.
    // https://wiki.schange.com/display/NIT/NXFD+from+UXM#NXFDfromUXM-2.6.1JSONschema
    const schema = require('../nxff-config/nxfd/nxfd.schema.json');
    if (!schema) {
      throw new Error('Nxfd schema not found!');
    }
    if (!schema.title || isNaN(schema.title)) {
      throw new Error('Schema version is not set!');
    }
    this.schemaVersion = Number(schema.title);
  }

  public setProfile(profileTag: string) {
    const profiles = this.jsonConfig.Profiles;
    if (!profiles) {
      throw new Error('Profiles node is missing!');
    }
    const profileJson = profiles[profileTag];
    if (!profileJson) {
      throw new Error(`Profile ${profileTag} is missing!`);
    }
    this.currentProfileJson = profileJson;
    this.currentProfileTag = profileTag;
    this.useProfileJson = (profileJson.UseProfile && profiles[profileJson.UseProfile]) || {};

    this.resetConfig();
    this.applyConfig(this.useProfileJson);
    this.applyConfig(profileJson);

    this.backOffices = this.currentProfileJson.BackOffices || this.useProfileJson?.BackOffices || {};
  }

  private resetConfig() {
    this.config = {} as DeploymentConfigurationInterface;
    this.applyConfig(this.defaultConfig);
  }

  private applyConfig(configToApply: NxfdSections): void {
    for (const section in configToApply) {
      if (typeof configToApply[section] !== 'object' || section === 'Profiles' || section === 'BackOffices') {
        continue;
      }
      const currentSectionObject = this.config[section];
      this.config[section] = {...(typeof currentSectionObject === 'object' && currentSectionObject), ...configToApply[section]};
    }
  }

  public async setBackOffice(backOfficeTag: string): Promise<void> {
    if (!this.currentProfileJson) {
      throw new Error('Profile not set!');
    }

    if (!this.backOffices) {
      throw new Error('Backoffices node is missing!');
    }

    this.currentBackOffice = this.backOffices[backOfficeTag];
    if (!this.currentBackOffice) {
      throw new Error(`Backoffice ${backOfficeTag} is missing!`);
    }
    this.currentBackOfficeTag = backOfficeTag;
    const useFeaturesBackOffice = (this.currentBackOffice.UseFeatures && this.backOffices[this.currentBackOffice.UseFeatures]) || {};

    this.resetConfig();
    this.useProfileJson && this.applyConfig(this.useProfileJson);
    this.applyConfig(this.currentProfileJson);
    this.applyConfig(useFeaturesBackOffice);
    this.applyConfig(this.currentBackOffice);

    this.applyConfigOverrides();

    const environment = this.getConfig().Environment as NxfdEnvironmentSection;
    if (environment.EnabledRemoteNXFD && environment.UXMURL && environment.UXMTenant) {
      const uxManager = new UXManager(new ADR8Requester({
        url: environment.UXMURL,
        tenant: environment.UXMTenant
      }));
      this.applyConfig(await uxManager.getConfig(this.schemaVersion));
      this.applyConfigOverrides();
    }
  }

  private applyConfigOverrides() {
    if (this.localConfig.Enabled) {
      this.applyConfig(this.localConfig);
      this.config.DemoFeatures.EnabledBOSelection = false;
    }
  }

  public getSelectableBackOffices(): SelectableBO[] {
    if (!this.config.DemoFeatures.EnabledBOSelection) {
      return [];
    }

    return this.config.DemoFeatures.SelectableBO || [];
  }

  public getProfileName(): string {
    return this.currentProfileTag;
  }

  public getBackOfficeCode(): string {
    return this.currentBackOfficeTag;
  }

  public getConfig(): DeploymentConfigurationInterface {
    return this.config;
  }
}

export const nxff = NXFF.instance;
export const nxffConfig: NXFFConfig = nxff;
