import {createStyles, defaultStyles} from 'common-styles';
import React, {Component, createRef} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {ActivityIndicator, AppStateStatus, View} from 'react-native';
import {NavigationScreenProps, Omit, withNavigation} from 'react-navigation';

import {dimensions, isBigScreen, isDesktopBrowser, isSTBBrowser} from 'common/constants';
import {Log} from 'common/Log';

import {NativeApplication} from 'mw/api/System';
import {mw} from 'mw/MW';

import AnimatedSwimlaneStackBase, {SwimlaneComponent} from 'components/AnimatedSwimlaneStackBase';
import ApplicationTile, {applicationTileHeight, gameTileHeight, tileWidth} from 'components/appsGames/ApplicationTile';
import {FocusManager} from 'components/focusManager/FocusManager';
import {FocusableComponent} from 'components/focusManager/FocusManagerTypes';
import FocusParent from 'components/FocusParent';
import {STBMenuState} from 'components/navigation/NavigationHelperTypes';
import {SwimlaneProps, SwimlaneTileProps, AnimatedTileProps} from 'components/Swimlane';
import {UseAppState} from 'hooks/HookComponents';

import NitroxScreen from './NitroxScreen';

const TAG = 'AppsGamesScreen';

const swimlaneLeftMargin = 140;
const swimlaneItemWidth = tileWidth + 2 * dimensions.margins.medium;
const swimlaneStackTopInset = 280;

const styles = createStyles({
  screen: {
    ...defaultStyles.view,
    justifyContent: 'flex-start'
  },
  focusParent: {
    flex: 1
  },
  activityIndicator: {
    position: 'absolute',
    top: '50%',
    alignSelf: 'center'
  }
});

const ListHeader: React.FC = () => {
  return (
    <View />
  );
};

type Props = NavigationScreenProps<{
  reloadScreen?: boolean;
}> & WithTranslation;

type State = {
  swimlaneComponents: SwimlaneComponent<NativeApplication>[];
  refreshing: boolean;
  firstTileReady: boolean;
}

class AppsGamesScreen extends Component<Props, State> {
  private firstTile = createRef<FocusableComponent>();

  private setupSwimlane = (row: number): Omit<SwimlaneProps<NativeApplication>, 'createTile'> => {
    return {
      header: row === 0 ? this.props.t('appsGames.apps') : this.props.t('appsGames.games'),
      row,
      insets: {
        left: swimlaneLeftMargin + dimensions.margins.xsmall,
        right: dimensions.margins.large
      },
      renderNavigationArrows: isDesktopBrowser && !isSTBBrowser,
      itemWidth: swimlaneItemWidth,
      itemHeight: row === 0 ? applicationTileHeight : gameTileHeight,
      dataFetcher: this.state.swimlaneComponents[row]?.dataFetcher
    };
  };

  private createTile = (props: SwimlaneTileProps<NativeApplication>, animatedTileProps?: AnimatedTileProps) => {
    const onFirstTileMount = (props.row === 0 && props.index === 0) ? this.onFirstTileReady : undefined;
    const tile = (focused: boolean) => (
      <ApplicationTile
        ref={animatedTileProps?.refHandler}
        application={props.data}
        onPress={this.openNativeApplication}
        onMount={onFirstTileMount}
        staticallyFocused={focused}
      />
    );
    return tile;
  };

  private onFirstTileReady = () => {
    this.setState({firstTileReady: true});
  };

  private openNativeApplication = (application: NativeApplication) => {
    mw.system.openNativeApplication(application.packageName)
      .then(() => Log.debug(TAG, 'Opening native application:', application.packageName))
      .catch(error => Log.error(TAG, 'Error opening native application:', error));
  };

  private onAppStateChange = (appState: AppStateStatus) => {
    if (appState === 'active') {
      this.updateNativeApplications();
    }
  };

  private onScreenFocused = () => {
    if (!!this.props.navigation.getParam('reloadScreen')) {
      this.loadNativeApplications();
    }
  };

  public constructor(props: Props) {
    super(props);
    this.state = {
      swimlaneComponents: [],
      refreshing: false,
      firstTileReady: false
    };
  }

  public componentDidMount() {
    if (!this.props.navigation.getParam('reloadScreen')) {
      this.loadNativeApplications();
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (!prevState.firstTileReady && this.state.firstTileReady) {
      FocusManager.getInstance().forceFocus(this.firstTile);
    }
  }

  private loadNativeApplications() {
    this.props.navigation.setParams({reloadScreen: false});
    this.updateNativeApplications();
  }

  private updateNativeApplications() {
    Log.debug(TAG, 'Updating native applications');
    this.setState({firstTileReady: false, refreshing: true});

    mw.system.getNativeApplications()
      .then(applications => {
        const swilanesData: SwimlaneComponent<NativeApplication>[] = [];
        if (applications) {
          swilanesData.push(
            {title: this.props.t('appsGames.apps'), dataFetcher: this.dataFetcher(applications.filter(application => !application.isGame))},
            {title: this.props.t('appsGames.games'), dataFetcher: this.dataFetcher(applications.filter(application => application.isGame))}
          );
          // Data fetchers must be cleared here to fix issue with missing swimlane headers and make refreshing more smooth.
          this.setState({swimlaneComponents: []});
        }
        Log.debug(TAG, 'Native applications updated');
        this.setState({swimlaneComponents: swilanesData, refreshing: false});
      })
      .catch(error => {
        Log.error(TAG, 'Error updating native applications:', error);
        this.setState({swimlaneComponents: [], refreshing: false});
      });
  }

  private async *dataFetcher(applications: NativeApplication[]): AsyncIterableIterator<NativeApplication[]> {
    return applications;
  }

  public render() {
    return (
      <NitroxScreen
        style={styles.screen}
        menuState={STBMenuState.Above}
        navigation={this.props.navigation}
        onScreenFocused={this.onScreenFocused}
        testID='screen_apps_games'
      >
        <UseAppState onAppStateChange={this.onAppStateChange} />
        <FocusParent style={styles.focusParent} enterStrategy='topLeft' rememberLastFocused>
          <AnimatedSwimlaneStackBase<NativeApplication>
            swimlaneComponents={this.state.swimlaneComponents}
            fixedFocusPosition={isBigScreen}
            topInset={swimlaneStackTopInset}
            setupSwimlane={this.setupSwimlane}
            createTile={this.createTile}
            swimlaneItemHeight={gameTileHeight}
          />
          <ActivityIndicator size='large' animating={this.state.refreshing} style={styles.activityIndicator} />
        </FocusParent>
      </NitroxScreen>
    );
  }
}

export default withTranslation()(withNavigation(AppsGamesScreen));
