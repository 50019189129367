import {createStyles} from 'common-styles';
import moment from 'moment';
import React, {useCallback, useContext, useMemo, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {View, StyleSheet, ViewStyle, ImageURISource, Image} from 'react-native';
import {NavigationContext} from 'react-navigation';

import {AppRoutes, dimensions, isTablet, RADIUS} from 'common/constants';
import {openMediaDetails, humanCaseToSnakeCase, getMediaTitle, getMediaSubtypes} from 'common/HelperFunctions';
import {TestProps} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {constColors,BaseColors} from 'common-styles/variables/base-colors';

import {isChannel, isRecording, isSeries, isTitle, Media, MediaType, PictureMode, PictureType, RecordingStatus} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import {ChannelIcon, ChannelIconType} from 'components/ChannelIcon';
import {Icon, IconType} from 'components/Icon';
import MediaPicture from 'components/MediaPicture';
import {calculateProgress, channelIconOffset, MediaTileType, useMediaInfoSection} from 'components/mediaTiles/MediaTileHooks';
import MiniProgressBar, {progressBarHeight} from 'components/MiniProgressBar';
import NitroxInteractive from 'components/NitroxInteractive';
import NitroxText from 'components/NitroxText';
import {useParentalControl, PCMedia} from 'components/parentalControl/ParentalControlProvider';
import PlayIcon from 'components/PlayIcon';
import TileIconsRow, {prepareEventIcons, prepareMediaIcons, MediaIcons} from 'components/TileIconsRow';
import {ViewMode} from 'screens/tv/TvScreen';

const TAG = 'MobileMediaTile';

const posterSize = dimensions.pictures.mobileTile.default;
const iconPlaceholderSize = dimensions.icon.medium;

export const mobileMediaTileMarginHorizontal = isTablet ? dimensions.margins.xxLarge : dimensions.margins.large;

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  contentWithSeparator: {
    flex: 1,
    justifyContent: 'center',
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: colors.columnTile.border,
    marginHorizontal: mobileMediaTileMarginHorizontal
  },
  posterContainer: {
    ...posterSize,
    borderRadius: RADIUS,
    overflow: 'hidden',
    backgroundColor: colors.tile.poster.placeholder.background
  },
  titleLabel: {
    color: colors.columnTile.title,
    textAlign: 'left',
    flexShrink: 1
  },
  subtitleLabel: {
    color: colors.columnTile.subtitle,
    textAlign: 'left'
  },
  startTimeLabel: {
    color: colors.columnTile.title,
    textAlign: 'left',
    flexShrink: 1,
    marginRight: dimensions.margins.small
  },
  liveLabelText: {
    color: colors.columnTile.liveLabel.text
  },
  moreActionsIconColor: colors.columnTile.moreActionsIcon,
  noPosterIcon: {
    ...StyleSheet.absoluteFillObject,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.tile.poster.placeholder.background
  }
}));

const styles = createStyles({
  container: {
    height: posterSize.height + 2 * dimensions.margins.medium,
    width: '100%'
  },
  contentView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  contentViewDefault: {
    alignItems: 'center'
  },
  contentViewEpisode: {
    alignItems: 'flex-start'
  },
  progressBar: {
    position: 'absolute',
    bottom: 0,
    left: posterSize.width + dimensions.margins.small,
    right: 0
  },
  channelIcon: {
    position: 'absolute',
    top: -channelIconOffset,
    left: -channelIconOffset
  },
  labelsOuterContainer: {
    flexShrink: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: dimensions.margins.small
  },
  labelsContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: dimensions.margins.xsmall + progressBarHeight,
    paddingTop: dimensions.margins.xLarge
  },
  mediaIcons: {
    paddingVertical: 2
  },
  iconsContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  moreActions: {
    width: dimensions.icon.small,
    height: dimensions.icon.small,
    marginLeft: dimensions.margins.small,
    justifyContent: 'center',
    alignItems: 'center'
  },
  channelPosterContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
});

export enum MobileMediaTileType {
  Default,
  Episode
}

type Props = {
  media: Media;
  extraData?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  tileType?: MobileMediaTileType;
  onMoreActionsPress?: (media: Media) => void;
  onStartPlayback?: (media: Media) => void;
} & TestProps;

const MobileMediaTile: React.FC<Props> = props => {
  const {media, extraData, onMoreActionsPress, onStartPlayback, tileType = MobileMediaTileType.Default} = props;
  const {t} = useTranslation();
  const navigation = useContext(NavigationContext);
  const dynamicStyles = dynamicStylesUpdater.getStyles();
  const isChannelMedia = useMemo(() => isChannel(media), [media]);
  const {isMediaBlocked, shouldBeCheckedForPC, unlockMedia} = useParentalControl();
  const isBlocked = shouldBeCheckedForPC(media) && isMediaBlocked(media);
  const mediaInfoSection = useMediaInfoSection(media, t, tileType === MobileMediaTileType.Default ? MediaTileType.Default : MediaTileType.Episode);
  const shouldRenderFailedRecordingIcon = useMemo(() => isRecording(media) && media.status === RecordingStatus.Failed, [media]);

  const {event, channelId, poster, title, subtitle, startTimeLabel, maxNumberOfLines, isPlayable} = useMemo(() => {
    if (isChannelMedia) {
      const channelLogoSize = {
        width: Math.round(posterSize.width * dimensions.channelTile.logoWidthRatio),
        height: Math.round(posterSize.height * dimensions.channelTile.logoHeightRatio)
      };
      return {
        event: null,
        poster: ({
          ...channelLogoSize,
          uri: mw.catalog.getPictureUrl(media, PictureType.Logo, channelLogoSize.width, channelLogoSize.height, PictureMode.BOX)
        } as ImageURISource),
        title: media.name,
        maxNumberOfLines: 2
      };
    }
    const {event, episode} = getMediaSubtypes(media);
    const uri = mw.catalog.getPictureUrl(media, PictureType.Tile, posterSize.width, posterSize.height, PictureMode.BOX);
    const poster = uri ? {uri} : null;
    return {
      event,
      channelId: event?.channelId,
      poster: poster,
      title: ((isTitle(media) || tileType === MobileMediaTileType.Episode) && episode?.title) || getMediaTitle(media),
      subtitle: isSeries(media) ? '' : mediaInfoSection,
      startTimeLabel: tileType === MobileMediaTileType.Default && event ? moment(event.start).format(mw.configuration.timeFormat) : '',
      maxNumberOfLines: media.getType() === MediaType.Event ? 2 : 3,
      isPlayable: event?.isNow || media.isPlayAllowed() || event?.isRecorded
    };
  }, [media, isChannelMedia, t, tileType]);

  const [mediaIcons, setMediaIcons] = useState<MediaIcons>({});
  const isNowEvent = !!event?.isNow;
  useEffect(() => {
    setMediaIcons(event ? prepareEventIcons(event, {isBlocked}) : prepareMediaIcons(media));
  }, [media, event, isNowEvent, media.isOnWatchList, extraData, isBlocked]); // isNow and isOnWatchList are computed properties which can change independently from the event's object reference

  const testID = useMemo(() => `tile_${humanCaseToSnakeCase(title)}`, [title]);

  const labelsContainer: ViewStyle = useMemo(() => {
    if (media.getType() === MediaType.Event) {
      return {
        ...styles.labelsContainer,
        justifyContent: 'flex-start'
      };
    }
    return {
      ...styles.labelsContainer,
      justifyContent: 'center'
    };
  }, [media]);

  const moreActionsHandler = useCallback(() => {
    onMoreActionsPress && onMoreActionsPress(media);
  }, [media, onMoreActionsPress]);

  const onPress = useCallback(() => {
    if (isChannelMedia) {
      navigation.push(AppRoutes.Zapper, {viewMode: ViewMode.OneChannelEpg, channelId: media.id, requestedByRoute: navigation.state.routeName});
      return;
    }
    openMediaDetails(navigation, media.id, media.getType());
  }, [media, isChannelMedia, navigation]);

  const startPlayback = useCallback(() => {
    if (isBlocked) {
      unlockMedia(media as PCMedia)
        .then(() => onStartPlayback?.(media))
        .catch(error => Log.error(TAG, 'Error when authorizing with pin: ', error));
    } else {
      onStartPlayback?.(media);
    }
  }, [isBlocked, media, onStartPlayback, unlockMedia]);

  const {moreActionsIcon, moreActionsOnPress, posterImage} = useMemo(() => ({
    moreActionsIcon: isChannelMedia ? IconType.ArrowRight : IconType.MoreActions,
    moreActionsOnPress: isChannelMedia ? onPress : moreActionsHandler,
    posterImage: isChannelMedia && poster
      ? (
        <View style={styles.channelPosterContainer}>
          <Image resizeMode='contain' source={poster} />
        </View>
      ) : (
        <MediaPicture
          source={poster}
          mediaType={media.getType()}
          isBlocked={isBlocked}
          iconSize={iconPlaceholderSize}
        />
      )
  }), [isChannelMedia, onPress, moreActionsHandler, poster, media, isBlocked]);

  const progress = calculateProgress(event);

  return (
    <>
      <NitroxInteractive
        style={styles.container}
        underlayColor={constColors.transparent}
        onPress={onPress}
        testID={testID}
      >
        <View style={dynamicStyles.contentWithSeparator}>
          <View style={[styles.contentView, tileType === MobileMediaTileType.Default ? styles.contentViewDefault : styles.contentViewEpisode]}>
            <View style={dynamicStyles.posterContainer}>
              {shouldRenderFailedRecordingIcon ? (
                <View style={dynamicStyles.noPosterIcon}>
                  <Icon type={IconType.FailedRecording} size={dimensions.icon.small} />
                </View>
              ) : (
                posterImage
              )}
              {isPlayable && onStartPlayback && (
                <PlayIcon onPress={startPlayback} iconSize={dimensions.icon.small} />
              )}
            </View>
            <View style={styles.labelsOuterContainer}>
              <View style={labelsContainer}>
                <NitroxText
                  textType='tile-title'
                  numberOfLines={maxNumberOfLines}
                  ellipsizeMode='tail'
                  style={dynamicStyles.titleLabel}
                  testID='title'
                >
                  {title}
                </NitroxText>
                {!!subtitle &&
                  <NitroxText textType='callout-small' numberOfLines={1} ellipsizeMode='tail' style={dynamicStyles.subtitleLabel}>{subtitle}</NitroxText>
                }
                <View style={styles.iconsContainer}>
                  {!!startTimeLabel &&
                    <NitroxText textType='callout-bold' numberOfLines={1} style={dynamicStyles.startTimeLabel}>{startTimeLabel}</NitroxText>
                  }
                  <TileIconsRow style={styles.mediaIcons} mediaIcons={mediaIcons} />
                </View>
              </View>
            </View>
            {tileType === MobileMediaTileType.Default && (
              <NitroxInteractive
                style={styles.moreActions}
                onPress={moreActionsOnPress}
                testID='button_more_actions'
              >
                <Icon
                  type={moreActionsIcon}
                  size={dimensions.icon.xxsmall}
                  color={dynamicStyles.moreActionsIconColor}
                />
              </NitroxInteractive>
            )}
            {!!channelId && <ChannelIcon type={ChannelIconType.Static} channelId={channelId} style={styles.channelIcon} />}
            {progress > 0 && <MiniProgressBar style={styles.progressBar} progress={progress} />}
          </View>
        </View>
      </NitroxInteractive>
    </>
  );
};

export default React.memo(MobileMediaTile);
