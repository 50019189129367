import React, {useRef, useCallback, forwardRef} from 'react';
import {StyleSheet} from 'react-native';

import {FocusableComponent} from 'components/focusManager/FocusManagerTypes';
import NitroxInteractive from 'components/NitroxInteractive';

import EpgTile, {EpgTileProps} from './EpgTile';

const TouchableEpgTile: React.FC<EpgTileProps> = (props, ref) => {
  const touchableRef = useRef<FocusableComponent>(null);

  const onFocusHandler = useCallback(() => {
    ref.current?.onFocus();
  }, [ref]);

  const onBlurHandler = useCallback(() => {
    ref.current?.onBlur();
  }, [ref]);

  const onPressHandler = useCallback(() => {
    ref.current?.onPress();
  }, [ref]);

  return (
    <NitroxInteractive
      ref={touchableRef}
      onFocus={onFocusHandler}
      onBlur={onBlurHandler}
      onPress={onPressHandler}
      activeOpacity={1}
      style={StyleSheet.absoluteFill}
      testID='touchable_tile'
    >
      <EpgTile ref={ref} {...props} />
    </NitroxInteractive>
  );
};

export default forwardRef(TouchableEpgTile);
