import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, ViewStyle} from 'react-native';

import {isMobile} from 'common/constants';

import {SortingOrder, SortingInterface} from 'mw/api/Metadata';

import {IconType} from './Icon';
import {ButtonIconProps, IconPosition} from './NitroxButton';
import OptionPicker from './OptionPicker';
import {Option} from './OptionPicker.shared';

export interface SortOption<T extends SortingInterface> extends Option {
  sortOption?: T;
}

const createSortOptionIconProps = (iconType: IconType): ButtonIconProps => ({
  type: iconType,
  size: isMobile ? 7 : 9,
  position: IconPosition.RIGHT
});

export const DEFAULT_OPTION_KEY = 'default';

export const sortOrderAscendingIconProps = createSortOptionIconProps(IconType.ArrowUpBold);
export const sortOrderDescendingIconProps = createSortOptionIconProps(IconType.ArrowDownBold);

function createOptionKey(item: SortingInterface): string {
  return item.type + '.' + (item.ascending ? SortingOrder.ascending : SortingOrder.descending);
}

export function createOption<T extends SortingInterface>(item: T, label: string, iconProps?: ButtonIconProps): SortOption<T> {
  return {
    key: createOptionKey(item),
    label: label,
    iconProps: iconProps,
    sortOption: item
  };
}

export type SortOrderSelectProps<T extends SortingInterface> = {
  currentSortOrder?: T;
  onSortOrderSelected: (value?: T) => void;
  options: SortOption<T>[];
  style?: StyleProp<ViewStyle>;
}

function SortOrderSelect<T extends SortingInterface>(props: SortOrderSelectProps<T>) {
  const {currentSortOrder, onSortOrderSelected, style, options} = props;
  const {t} = useTranslation();

  const currentSortOrderKey = useMemo((): string => {
    if (currentSortOrder) {
      return createOptionKey(currentSortOrder);
    }
    return DEFAULT_OPTION_KEY;
  }, [currentSortOrder]);

  const onOptionSelected = useCallback((key: string) => {
    const option = options.find(option => option.key === key);
    if (option) {
      onSortOrderSelected(option.sortOption);
    }
  }, [onSortOrderSelected, options]);

  return (
    <OptionPicker
      label={t('sorting.sortBy')}
      options={options}
      defaultOptionKey={currentSortOrderKey}
      onOptionSelected={onOptionSelected}
      style={style}
    />
  );
}

export default React.memo(SortOrderSelect) as typeof SortOrderSelect;

