import {createStyles} from 'common-styles';
import React from 'react';
import {View, ViewStyle} from 'react-native';

import {isBigScreen} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxText from 'components/NitroxText';

const indicatorSize = 20;
const indicatorOffset = 7;
const textType = isBigScreen ? 'callout-micro' : 'tile-subhead';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  indicator: {
    position: 'absolute',
    top: -indicatorOffset,
    right: -indicatorOffset,
    height: indicatorSize,
    width: indicatorSize,
    borderRadius: Math.round(indicatorSize / 2),
    backgroundColor: colors.mainMenu.button.notifications.indicator,
    justifyContent: 'center',
    alignItems: 'center'
  },
  label: {
    color: colors.mainMenu.button.text.active
  }
}));

type NotificationsIndicatorProps = {
  notificationsCount: number;
  style?: ViewStyle;
}

const NotificationsIndicator: React.FC<NotificationsIndicatorProps> = props => {
  const {notificationsCount} = props;
  if (!notificationsCount) {
    return null;
  }
  const styles = stylesUpdater.getStyles();
  return (
    <View style={[styles.indicator, props.style]}>
      <NitroxText style={styles.label} textType={textType}>{notificationsCount}</NitroxText>
    </View>
  );
};

export default NotificationsIndicator;
