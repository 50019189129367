import React, {RefObject, useRef, useCallback, Component} from 'react';
import {NativeMethodsMixinStatic, ViewStyle, Dimensions, Insets} from 'react-native';

import {disposable} from 'common/Async';
import {dimensions, isDesktopBrowser, isSTBBrowser, isMobile, defaultPageSize, isBigScreen} from 'common/constants';
import {doNothing} from 'common/HelperFunctions';
import {FocusOptions} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {SpecialFilter} from 'mw/api/CatalogInterface';
import {InvalidMedia} from 'mw/api/InvalidMedia';
import {Media} from 'mw/api/Metadata';
import {Component as CMSComponent, isDataSourceFilterBased} from 'mw/cms/Component';
import {Link, LinkType} from 'mw/cms/Menu';
import {mw} from 'mw/MW';
import {MetaEventsEmitter} from 'mw/utils/MetaEventsEmitter';

import {FocusableComponent} from 'components/focusManager/FocusManagerTypes';
import MediaTile from 'components/mediaTiles/MediaTile';
import StaticTile from 'components/mediaTiles/StaticTile';
import {SwimlaneDataFetcherState, SwimlaneDataProps, swimlaneHeaderHeight, SwimlaneProps, SwimlaneStack, SwimlaneTileProps} from 'components/Swimlane';
import {NamedAction, VisibilityLimit, createLimitedDataFetcher} from 'components/utils/SwimlaneVisibilityLimit';
import {getScreenInfo, useEffectOnce} from 'hooks/Hooks';

import AnimatedSwimlaneStack from './AnimatedSwimlaneStack';

const TAG = 'MediaTileSwimlanesStack';

const maxSwimlanesBatchSize = 4;
const swimlaneItemWidth = dimensions.tile.width + 2 * dimensions.margins.small;
const swimlaneItemHeight = dimensions.tile.height + dimensions.margins.small;
const defaultSwimlaneInsets = {
  top: dimensions.margins.medium,
  right: dimensions.margins.large,
  bottom: isMobile ? dimensions.margins.medium : dimensions.margins.xLarge,
  left: dimensions.margins.small
};

type TileData = Media | NamedAction;

type Props = {
  page?: Link;
  onTileFocus?: (tileIndex: number, row: number, data: Media, options: FocusOptions | undefined, wrapAroundActive: () => boolean) => void;
  onTilePress?: (tileIndex: number, data: Media) => void;
  fixedFocusPosition?: boolean;
  topInset?: number;
  wrapAround?: boolean;
  style?: ViewStyle;
  swimlaneInsets?: Insets;
  onFirstTileMount?: () => void;
  createVisibilityLimits?: (component: CMSComponent) => VisibilityLimit<CMSComponent>[];
  /**
   * Executed when all currently visible swimlanes have their data. The number of visible swimlanes depends of stack's vertical position.
   */
  onReady?: () => void;
  createStaticHeaderActions?: (component: CMSComponent) => NamedAction[];
}

type SwimlaneComponent = {
  component: CMSComponent;
} & SwimlaneDataProps<TileData>;

type State = {
  swimlaneComponents: SwimlaneComponent[];
  visibleSwimlanes: number;
  swimlanesBatchSize: number;
  dataVersion: number;
  refreshing: boolean;
}

type TileProps = Omit<SwimlaneTileProps<Media>, 'onFocus'> & {
  onFocus: (ref: RefObject<FocusableComponent & NativeMethodsMixinStatic>, event?: any, options?: FocusOptions) => void;
  onPress?: (index: number, media: Media) => void;
  onMount?: () => void;
};

const Tile = React.memo((props: TileProps) => {
  const {onFocus: propagateFocus, onPress: propagatePress, index, data, onMount} = props;
  const reference = useRef<FocusableComponent & NativeMethodsMixinStatic>(null);
  const onFocus = useCallback((media: Media, options?: FocusOptions) => propagateFocus(reference, media, options), [propagateFocus]);
  const onPress = propagatePress ? () => propagatePress(index, data) : undefined;

  useEffectOnce(() => onMount?.(), [onMount]);

  return (
    <MediaTile
      ref={reference}
      media={props.data}
      onFocus={onFocus}
      scrollOnFocus={false}
      onPress={onPress}
    />
  );
});
Tile.displayName = 'Tile';

const createLoadingPlaceholder = () => {
  return (
    <StaticTile
      focusable={false}
      onPress={doNothing}
      empty
    />
  );
};

class MediaTileSwimlanesStack extends Component<Props, State> {
  private metaEventsEmitter = new MetaEventsEmitter();
  private visibleSwimlanes = 0;
  private loadedSwimlanes = 0;
  private emptySwimlanes: number[] = [];

  public constructor(props: Props) {
    super(props);
    this.state = {
      swimlaneComponents: [],
      visibleSwimlanes: 0,
      swimlanesBatchSize: this.calculateSwimlanesBatchSize(),
      dataVersion: 0,
      refreshing: false
    };
  }

  private swimlaneHeight(): number {
    const insets = this.swimlaneInsets();
    return swimlaneHeaderHeight + swimlaneItemHeight + insets.top + insets.bottom;
  }

  private calculateSwimlanesBatchSize() {
    const {size: {height: screenHeight}} = getScreenInfo();
    return Math.max(
      maxSwimlanesBatchSize,
      Math.ceil(screenHeight / this.swimlaneHeight())
    );
  }

  public componentDidMount() {
    Dimensions.addEventListener('change', this.handleDimensionsChange);
    this.metaEventsEmitter.addListeners();
  }

  private handleDimensionsChange = () => {
    const newBatchSize = this.calculateSwimlanesBatchSize();
    if (newBatchSize !== this.state.swimlanesBatchSize) {
      this.setState({swimlanesBatchSize: newBatchSize});
    }
  };

  public componentWillUnmount() {
    Dimensions.removeEventListener('change', this.handleDimensionsChange);
    this.metaEventsEmitter.removeListeners();
    this.getCmsPage.dispose();
  }

  private createShowAllTile = (props: SwimlaneTileProps<TileData>) => {
    const {onFocus, data} = props;
    const tileData = data as NamedAction;
    return (
      <StaticTile
        label={tileData.label}
        onFocus={onFocus}
        onPress={tileData.onPress}
      />
    );
  };

  private createMediaTile = (props: SwimlaneTileProps<TileData>) => {
    const {onFocus, data, ...otherProps} = props;
    const media = data as Media;
    const focusHandler = (ref: RefObject<FocusableComponent & NativeMethodsMixinStatic>, event?: any, options?: FocusOptions) => {
      onFocus?.(event, options);
      this.props.onTileFocus && this.props.onTileFocus(otherProps.index, otherProps.row, media, options, props.wrapAroundActive);
    };
    return (
      <Tile
        onMount={(props.row === 0 && props.index === 0) ? this.props.onFirstTileMount : undefined}
        {...otherProps}
        data={media}
        onFocus={focusHandler}
        onPress={this.props.onTilePress}
      />
    );
  };

  private createTile = (props: SwimlaneTileProps<TileData>) => {
    return props.data instanceof NamedAction
      ? this.createShowAllTile(props)
      : this.createMediaTile(props);
  };

  private createTilePlaceholder = (row: number, onFocus: (event?: any, options?: FocusOptions) => void) => {
    return this.createTile({
      data: new InvalidMedia(`${row}`, ''),
      index: 0,
      row,
      onFocus,
      wrapAroundActive: () => false
    });
  };

  private createDataFetcher(component: CMSComponent): AsyncIterableIterator<TileData[]> {
    if (!isDataSourceFilterBased(component.dataSource)) {
      // TODO CL-6875 - handle all data sources types
      return (async function* () {
        return [];
      })();
    }
    const visibilityLimits = this.props.createVisibilityLimits?.(component);
    return createLimitedDataFetcher(mw.catalog.getContent(component.dataSource.filters, {pageSize: defaultPageSize}), visibilityLimits, component);
  }

  private filterSwimlaneComponents(swimlaneComponent: CMSComponent) {
    return isDataSourceFilterBased(swimlaneComponent.dataSource) // TODO CL-6875 - handle all data sources types
      && (
        mw.configuration.enabledWatchlist || swimlaneComponent.dataSource.filters[0].value !== SpecialFilter.WatchList
      );
  }
  private getCmsPage = disposable((link: Link) => mw.cms.getPage(link));

  public fetchPageLayout = async (link: Link | undefined) => {
    if (this.state.refreshing) {
      return;
    }
    this.setState({refreshing: true});
    try {
      if (!link || link.type !== LinkType.PAGE) {
        Log.info(TAG, 'Did not receive slug for page');
        this.setState({refreshing: false});
        return;
      }
      const page = await this.getCmsPage(link);
      const swimlaneComponents = page.componentGroup[0];
      if (!swimlaneComponents) {
        throw new Error('Could not find swimlanes!');
      }
      const dataVersion = this.state.dataVersion + 1;
      const filteredSwimlaneComponents = swimlaneComponents.components
        .filter(this.filterSwimlaneComponents)
        .map(component => {
          const dataEvents = isDataSourceFilterBased(component.dataSource)
            ? this.metaEventsEmitter.getDataEventsForFilter(component.dataSource.filters[0])
            : {};
          return {
            component,
            createDataFetcher: () => this.createDataFetcher(component),
            staticHeaderActions: this.props.createStaticHeaderActions?.(component),
            ...dataEvents
          };
        });
      this.visibleSwimlanes = Math.min(this.state.swimlanesBatchSize, filteredSwimlaneComponents.length);
      this.loadedSwimlanes = 0;
      this.emptySwimlanes = [];
      this.setState({
        swimlaneComponents: filteredSwimlaneComponents,
        visibleSwimlanes: this.visibleSwimlanes,
        dataVersion: dataVersion,
        refreshing: false
      });
    } catch (error) {
      Log.error(TAG, error);
      this.setState({refreshing: false});
      this.clear();
    }
  };

  public setData(swimlaneComponents: Pick<SwimlaneComponent, 'component' | 'dataFetcher' | 'createDataFetcher'>[]) {
    this.clear();
    const newSwimlaneComponents: SwimlaneComponent[] = swimlaneComponents.map(swimlaneComponent => {
      const dataEvents = isDataSourceFilterBased(swimlaneComponent.component.dataSource)
        ? this.metaEventsEmitter.getDataEventsForFilter(swimlaneComponent.component.dataSource.filters[0])
        : {};
      return {
        ...swimlaneComponent,
        ...dataEvents
      };
    });
    this.visibleSwimlanes = Math.min(this.state.swimlanesBatchSize, swimlaneComponents.length);
    this.setState({
      swimlaneComponents: newSwimlaneComponents,
      visibleSwimlanes: Math.min(this.state.swimlanesBatchSize, swimlaneComponents.length),
      dataVersion: this.state.dataVersion + 1
    });
  }

  public clear() {
    this.visibleSwimlanes = 0;
    this.loadedSwimlanes = 0;
    this.emptySwimlanes = [];
    this.setState({swimlaneComponents: [], visibleSwimlanes: 0, dataVersion: 0, refreshing: false});
  }

  private swimlaneInsets() {
    return {
      ...defaultSwimlaneInsets,
      ...this.props.swimlaneInsets
    };
  }

  /**
   * Translates `row` index of `data` array into an index of non-empty swimlane components.
   *
   * Use it when you need to know swimlane's index from a user perspective, e.g. to properly
   * calculate offset of swimlanes list.
   *
   * We need this function because empty swimlanes are always present in components tree,
   * they just render nothing.
   */
  private getVisibleComponentIndex = (row: number) => this.emptySwimlanes.reduce(
    (index, emptyIndex) => emptyIndex < row ? index - 1 : index,
    row
  );

  private setupSwimlane = (row: number): Omit<SwimlaneProps<TileData>, 'createTile' | 'createHeaderTile'> => {
    const swimlaneComponent = this.state.swimlaneComponents[row];
    return {
      header: swimlaneComponent.component.title,
      row,
      insets: this.swimlaneInsets(),
      renderNavigationArrows: isDesktopBrowser && !isSTBBrowser,
      itemWidth: swimlaneItemWidth,
      itemHeight: swimlaneItemHeight,
      dataFetcher: swimlaneComponent.dataFetcher,
      createDataFetcher: swimlaneComponent.createDataFetcher,
      dataChangeEvent: swimlaneComponent.dataChangeEvent,
      dataRefreshEvent: swimlaneComponent.dataRefreshEvent,
      dataEventsEmitter: swimlaneComponent.dataEventsEmitter,
      staticHeaderActions: swimlaneComponent.staticHeaderActions,
      fixedFocusPosition: this.props.fixedFocusPosition,
      wrapAround: this.props.wrapAround,
      onDataFetcherStateChanged: this.onSwimlaneDataFetcherStateChanged
    };
  }

  private onRefresh = () => {};

  private onSwimlaneDataFetcherStateChanged = (state: SwimlaneDataFetcherState, row: number) => {
    switch (state) {
      case SwimlaneDataFetcherState.HasData:
        this.loadedSwimlanes++;
        break;
      case SwimlaneDataFetcherState.NoData:
        Log.debug(TAG, 'Swimlane without data');
        this.increaseVisibleSwimlanes(1);
        this.emptySwimlanes = [...this.emptySwimlanes, row];
        break;
    }
    if (this.emptySwimlanes.length + this.loadedSwimlanes >= this.visibleSwimlanes) {
      Log.debug(TAG, 'All currently visible swimlanes are ready');
      this.props.onReady?.();
    }
  }

  private onEndReached = () => {
    if (this.state.visibleSwimlanes >= this.state.swimlaneComponents.length) {
      return;
    }
    Log.debug(TAG, 'onEndReached, increasing loaded swimlanes');
    this.increaseVisibleSwimlanes(this.state.swimlanesBatchSize);
  };

  private increaseVisibleSwimlanes(quantity: number) {
    // visibleSwimlanes has to be stored in state (to force children re-renders) and in class prop to be accessed in async callblack onSwimlaneDataFetcherStateChanged.
    // This is basically what would useSynchronizedState hook do.
    this.visibleSwimlanes = Math.min(this.visibleSwimlanes + quantity, this.state.swimlaneComponents.length);
    this.setState({
      visibleSwimlanes: this.visibleSwimlanes
    });
  }

  public render() {
    return (
      <SwimlaneStack<TileData>
        swimlaneHeight={this.swimlaneHeight()}
        fixedFocusPosition={this.props.fixedFocusPosition}
        topInset={this.props.topInset}
        style={this.props.style}
        refreshing={this.state.refreshing}
        onRefresh={this.onRefresh}
        rowCount={this.state.visibleSwimlanes}
        onEndReached={this.onEndReached}
        setupSwimlane={this.setupSwimlane}
        placeholderComponent={this.createTilePlaceholder}
        loadingPlaceholderComponent={createLoadingPlaceholder}
        createTile={this.createTile}
        getVisibleComponentIndex={this.getVisibleComponentIndex}
      />
    );
  }
}

export default isBigScreen ? AnimatedSwimlaneStack : MediaTileSwimlanesStack;
