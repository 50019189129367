import {DateUtils} from 'common/DateUtils';
import {EventEmitter} from 'common/EventEmitter';
import {Log} from 'common/Log';

import {EPGParams, EPGResponse} from 'mw/api/CatalogInterface';
import {Event, Channel} from 'mw/api/Metadata';
import {nxffConfig} from 'mw/api/NXFF';
import {boProxy} from 'mw/bo-proxy/BOProxy';
import {BlockableIdleActions} from 'mw/common/BlockableIdleActions';
import {convertJSDateToJSONDate} from 'mw/common/utils';
import {mw} from 'mw/MW';

const TAG = 'EPGCache';

class EPGFetchParams {
  public channels: Channel[];
  public startTime: Date;
  public endTime: Date;
  public limit: number;
  public chanFrom: number;
  public chanTo: number;
  public refreshing: boolean;
  public timespansGeneration: number;

  public constructor(params: EPGParams, timespansGeneration: number) {
    this.channels = params.channels;
    this.startTime = params.startTime;
    this.endTime = params.endTime;
    this.limit = params.limit || 0;
    this.chanFrom = 0;
    this.chanTo = params.channels.length - 1;
    this.refreshing = params.refreshing === true;
    this.timespansGeneration = timespansGeneration;
  }
}

class EPGTimespan {
  public key: string;
  public channel: Channel;
  public retrieved: Date;
  public locks = 0;
  public events: Event[] = [];
  public generation = 0;
  public eventsMap: Map<string, Event>;

  public constructor(key: string, channel: Channel) {
    this.key = key;
    this.channel = channel;
    this.retrieved = new Date();
    this.eventsMap = new Map<string, Event>();
  }
}

class EPGChunk {
  public chanFrom: number;
  public chanTo = -1;
  public requestedChannels: Channel[] = [];
  public alignmentChannels: Channel[] = [];
  public readyTimespans: EPGTimespan[] = [];
  public missingStartTime: Date | null = null;
  public missingEndTime: Date | null = null;

  public constructor(chanFrom: number) {
    this.chanFrom = chanFrom;
  }
}

export enum EPGCacheEvent {
  Refreshed = 'Refreshed'
}

export class EPGCache extends EventEmitter<EPGCacheEvent> implements BlockableIdleActions {
  private eventsInTimespans = 0;
  private refreshedStartTime: Date | null = null;
  private refreshedEndTime: Date | null = null;
  private timespansGeneration = 0;
  private timespansCleanupTimer: any = null;
  private refreshTimer?: number | null;
  private refreshLock: Promise<EPGResponse> | null = null;
  private idleActionsBlocked = true;
  private idleActions: Promise<void> = Promise.resolve();
  private wasRefreshRequested = false;

  public clear(): void {
    this.refreshedStartTime = null;
    this.refreshedEndTime = null;
    this.eventsInTimespans = 0;
    this.timespansGeneration = 0;
    this.idleActionsBlocked = true;
    this.wasRefreshRequested = false;
    this.cancelNextRefresh();
    this.cancelTimespansCleanup();
  }

  public getEPG(params: EPGParams): Promise<EPGResponse> {
    if (this.refreshLock) {
      return this.refreshLock.then(() => this.getEPG(params));
    }
    if (params.refreshing) {
      this.refreshLock = new Promise<EPGResponse>((resolve, reject) => {
        this.getEPGSync(params)
          .then(epgResponse => {
            this.refreshLock = null;
            resolve(epgResponse);
          })
          .catch(error => {
            this.refreshLock = null;
            reject(error);
          });
      });
      return this.refreshLock;
    }

    return this.getEPGSync(params);
  }

  private getEPGSync(params: EPGParams): Promise<EPGResponse> {
    const fetchParams = new EPGFetchParams(params, this.timespansGeneration++);
    Log.debug(TAG, `Looking for events from channel ${fetchParams.chanFrom} to ${fetchParams.chanTo}
      from ${fetchParams.startTime} to ${fetchParams.endTime} using generation ${fetchParams.timespansGeneration}
      and events limit ${fetchParams.limit} refreshing ${fetchParams.refreshing}`);
    // compute requested chunks and timespans
    const chunks = this.findChunks(fetchParams);
    this.findTimespans(chunks, fetchParams);
    this.subdivideChunks(chunks, fetchParams);
    this.lockReadyTimespans(chunks, true);
    // if there are none missing timespans within the found chunks - return them
    const events = this.copyRequestedChunks(chunks, fetchParams);
    if (events.size > 0) {
      this.lockReadyTimespans(chunks, false);
      return Promise.resolve(events);
    }
    // there are some missing timespans - request them
    return this.requestMissingChunks(chunks, fetchParams);
  }

  public blockIdleActions(): void {
    Log.debug(TAG, 'Blocking idle actions');
    this.idleActionsBlocked = true;
  }

  public unblockIdleActions(): Promise<void> {
    if (this.idleActionsBlocked) {
      Log.debug(TAG, 'Unblocking idle actions');
      this.idleActionsBlocked = false;
      if (this.wasRefreshRequested) {
        Log.debug(TAG, 'Executing postponed EPG refresh');
        return this.refreshEPG();
      }
    }
    Log.debug(TAG, 'EPG refresh has already been unblocked');
    return this.idleActions;
  }

  public async refreshEPG(): Promise<void> {
    if (this.idleActionsBlocked) {
      Log.debug(TAG, 'Idle actions are blocked - ignoring request');
      this.wasRefreshRequested = true;
      return;
    }
    // if there are any ongoing idle actions wait for them to finish
    return this.idleActions = this.idleActions.finally(async () => {
      this.cancelNextRefresh(); // refresh can be trigger by external calls therefore make sure to not stack scheduled refreshes
      // compute channels and timespan for the refresh
      const channels = await mw.catalog.getAllChannels();
      if (channels.length === 0) {
        Log.debug(TAG, 'Unable to refresh EPG - channels lineup is missing');
        this.scheduleNextRefresh(); // we want to postpone the refresh in time and not to cancel it completely
        return;
      }
      const alignedNow = this.alignTimespanTime(new Date(), true);
      const timespanSize = nxffConfig.getConfig().EPG.EPGCacheTimespanSize;
      const epgCacheRefreshChunkNumber = nxffConfig.getConfig().EPG.EPGCacheRefreshChunkNumber;
      const startTime = DateUtils.addHours(alignedNow, -timespanSize);
      const endTime = DateUtils.addHours(startTime, timespanSize * epgCacheRefreshChunkNumber);
      // before continuing unlock timespans locked by previous refresh
      if (this.refreshedStartTime && this.refreshedEndTime) {
        this.lockRefreshedTimespans(false, {
          channels: channels,
          startTime: this.refreshedStartTime,
          endTime: this.refreshedEndTime
        });
      }
      // fetch the EPG in refreshing mode - this will alter the way the chunks and missing timespans are computed and also how the timespans subdivision works
      Log.debug(TAG, `Refreshing EPG from ${channels.length} channels, starting from ${startTime} to ${endTime}`);
      const params: EPGParams = {channels, startTime, endTime, refreshing: true};
      await this.getEPG(params);
      // finally lock just refreshed timespans and schedule next refresh
      this.lockRefreshedTimespans(true, params);
      this.scheduleNextRefresh();
      this.wasRefreshRequested = false;
      this.notify(EPGCacheEvent.Refreshed);
    });
  }

  private cancelNextRefresh(): void {
    if (!this.refreshTimer) {
      return;
    }
    clearTimeout(this.refreshTimer);
    this.refreshTimer = null;
  }

  private scheduleNextRefresh(): void {
    const refreshTimeout = Math.floor(nxffConfig.getConfig().EPG.EPGCacheTimespanSize * DateUtils.msInHour / 2);
    Log.debug(TAG, 'Scheduling next EPG refresh in ' + refreshTimeout + ' ms');
    this.refreshTimer = setTimeout(this.refreshEPG.bind(this), refreshTimeout);
  }

  private alignChannelIndex(index: number, down: boolean): number {
    const config = nxffConfig.getConfig().EPG;
    let result = 0;
    if (down) {
      index /= config.EPGCacheChannelsChunkSize;
      result = Math.floor(index) * config.EPGCacheChannelsChunkSize;
    } else {
      index = (index + 1) / config.EPGCacheChannelsChunkSize;
      result = (Math.ceil(index) * config.EPGCacheChannelsChunkSize) - 1;
    }
    return result;
  }

  private findChunks(fetchParams: EPGFetchParams): EPGChunk[] {
    const config = nxffConfig.getConfig().EPG;
    const chunks: EPGChunk[] = [];
    let channelsCounter = 0;
    const pushChannel = (chanIndex: number): void => {
      // sometimes (like boot time and periodical retrieval of current EPG) we do not want to chunk the requests
      let nextChunk = chunks.length === 0;
      if (!fetchParams.refreshing || config.EPGCacheChunkRequestsWhenRefreshing) {
        nextChunk = (channelsCounter++ % config.EPGCacheChannelsChunkSize) === 0;
      }
      if (nextChunk) {
        chunks.push(new EPGChunk(chanIndex));
      }
      const chunk = chunks[chunks.length - 1];
      chunk.chanTo = chanIndex;
      const channels = (chanIndex >= fetchParams.chanFrom && chanIndex <= fetchParams.chanTo) ? chunk.requestedChannels : chunk.alignmentChannels;
      const channel = fetchParams.channels[chanIndex];
      channels.push(channel);
    };
    if (config.EPGCacheAllowEPGCircularLoading && (fetchParams.chanFrom > fetchParams.chanTo)) {
      const alignedChanFrom = Math.min(this.alignChannelIndex(fetchParams.chanFrom, false), fetchParams.channels.length - 1);
      const alignedChanTo = this.alignChannelIndex(fetchParams.chanTo, true);
      for (let chanIndex = alignedChanFrom; chanIndex < fetchParams.channels.length; ++chanIndex) {
        pushChannel(chanIndex);
      }
      for (let chanIndex = 0; chanIndex <= alignedChanTo; ++chanIndex) {
        pushChannel(chanIndex);
      }
    } else {
      // non-circular or one-channel type of request
      let alignedChanFrom = fetchParams.chanFrom;
      let alignedChanTo = fetchParams.chanTo;
      if (fetchParams.chanFrom !== fetchParams.chanTo) {
        alignedChanFrom = this.alignChannelIndex(fetchParams.chanFrom, true);
        alignedChanTo = Math.min(this.alignChannelIndex(fetchParams.chanTo, false), fetchParams.channels.length - 1);
      }
      for (let chanIndex = alignedChanFrom; chanIndex <= alignedChanTo; ++chanIndex) {
        pushChannel(chanIndex);
      }
    }
    return chunks;
  }

  private isChunkMissingTimespans(chunk: EPGChunk): boolean {
    return !!chunk.missingStartTime || !!chunk.missingEndTime;
  }

  private alignTimespanTime(time: Date, down: boolean): Date {
    const timespanSize = nxffConfig.getConfig().EPG.EPGCacheTimespanSize * DateUtils.sInHour;
    let seconds = (time.getHours() * DateUtils.sInHour + time.getMinutes() * DateUtils.sInMin + time.getSeconds()) / timespanSize;
    seconds = down ? Math.floor(seconds) : Math.ceil(seconds);
    seconds = seconds * timespanSize;
    const ret = new Date(time.getTime()); // use timestamp to disgard the timezone offset
    ret.setHours(seconds / DateUtils.sInHour);
    ret.setMinutes(0);
    ret.setSeconds(0);
    ret.setMilliseconds(0);
    return ret;
  }

  private buildTimespanKey(startTime: Date): string {
    // in case we will need to support genres make sure to encode them here into the generated key
    return convertJSDateToJSONDate(startTime);
  }

  private isTimespanOutdated(timespan: EPGTimespan): boolean {
    const config = nxffConfig.getConfig().EPG;
    return (Date.now() - timespan.retrieved.getTime()) > config.EPGCacheTimespanLifetimeHours * DateUtils.msInHour;
  }

  private isTimespanIncomplete(timespan: EPGTimespan, startTime: Date, endTime: Date): boolean {
    if (timespan.events.length === 0) {
      return false;
    }
    return timespan.events[timespan.events.length - 1].end < endTime || timespan.events[0].start > startTime;
  }

  private findTimespans(chunks: EPGChunk[], fetchParams: EPGFetchParams) {
    const config = nxffConfig.getConfig().EPG;
    const alignedStartTime = this.alignTimespanTime(fetchParams.startTime, true);
    for (const chunk of chunks) {
      for (const channel of chunk.requestedChannels) {
        let timespanStartTime = alignedStartTime;
        while (timespanStartTime < fetchParams.endTime) {
          const timespanEndTime = new Date(timespanStartTime);
          timespanEndTime.setHours(timespanEndTime.getHours() + config.EPGCacheTimespanSize);
          const timespan = channel.customProperties.timespans ? channel.customProperties.timespans[this.buildTimespanKey(timespanStartTime)] : null;
          if (!timespan || fetchParams.refreshing || this.isTimespanIncomplete(timespan, timespanStartTime, timespanEndTime) || this.isTimespanOutdated(timespan)) {
            if (!chunk.missingStartTime || chunk.missingStartTime > timespanStartTime) {
              chunk.missingStartTime = timespanStartTime;
            }
            if (!chunk.missingEndTime || chunk.missingEndTime < timespanEndTime) {
              chunk.missingEndTime = timespanEndTime;
            }
          } else {
            chunk.readyTimespans.push(timespan);
          }
          timespanStartTime = timespanEndTime;
        }
      }
    }
  }

  private isChunkOversized(chunk: EPGChunk): boolean {
    if (!chunk.missingEndTime || !chunk.missingStartTime) {
      return false;
    }
    const config = nxffConfig.getConfig().EPG;
    const chunkSize = chunk.requestedChannels.length + chunk.alignmentChannels.length;
    const missingDuration = Math.ceil(chunk.missingEndTime.getTime() - chunk.missingStartTime.getTime()) / DateUtils.msInHour;
    return missingDuration > config.EPGCacheTimespanSize || chunkSize * missingDuration > config.EPGCacheMaxChannelHoursPerChunk;
  }

  private subdivideChunk(chunk: EPGChunk, fetchParams: EPGFetchParams): EPGChunk[] {
    const config = nxffConfig.getConfig().EPG;
    const subdividedChunks: EPGChunk[] = [];
    const channels = chunk.requestedChannels.concat(chunk.alignmentChannels);
    const hoursPerTimespanSize = config.EPGCacheMaxChannelHoursPerChunk / config.EPGCacheTimespanSize;
    const subdividedChunkSize = Math.max(Math.floor(hoursPerTimespanSize / config.EPGCacheChannelsChunkSize) * config.EPGCacheChannelsChunkSize, config.EPGCacheChannelsChunkSize);
    let timespanStartTime = this.alignTimespanTime(fetchParams.startTime, true);
    while (timespanStartTime < fetchParams.endTime) {
      const timespanEndTime = new Date(timespanStartTime);
      timespanEndTime.setHours(timespanEndTime.getHours() + config.EPGCacheTimespanSize);
      const timespanKey = this.buildTimespanKey(timespanStartTime);
      for (let channelIndex = 0; channelIndex < channels.length; ++channelIndex) {
        const channel = channels[channelIndex];
        if (channelIndex % subdividedChunkSize === 0) {
          subdividedChunks.push(new EPGChunk(fetchParams.channels.indexOf(channel)));
        }
        const subdividedChunk = subdividedChunks[subdividedChunks.length - 1];
        const subdividedChannels = channelIndex < chunk.requestedChannels.length ? subdividedChunk.requestedChannels : subdividedChunk.alignmentChannels;
        subdividedChannels.push(channel);
        subdividedChunk.chanTo = fetchParams.channels.indexOf(channel);
        const timespan = channel.customProperties.timespans ? channel.customProperties.timespans[timespanKey] : null;
        if (!timespan || fetchParams.refreshing || this.isTimespanIncomplete(timespan, timespanStartTime, timespanEndTime) || this.isTimespanOutdated(timespan)) {
          subdividedChunk.missingStartTime = timespanStartTime;
          subdividedChunk.missingEndTime = timespanEndTime;
        } else {
          subdividedChunk.readyTimespans.push(timespan);
        }
      }
      timespanStartTime = timespanEndTime;
    }
    return subdividedChunks;
  }

  private subdivideChunks(chunks: EPGChunk[], fetchParams: EPGFetchParams): void {
    for (let chunkIndex = 0; chunkIndex < chunks.length; ++chunkIndex) {
      const chunk = chunks[chunkIndex];
      if (!this.isChunkMissingTimespans(chunk) || !this.isChunkOversized(chunk)) {
        continue;
      }
      Log.debug(TAG, `Chunk with channels ${chunk.chanFrom} to ${chunk.chanTo} with missing timespans from ${chunk.missingStartTime} to ${chunk.missingEndTime} is oversized`);
      const subdividedChunks = this.subdivideChunk(chunk, fetchParams);
      if (subdividedChunks.length === 0) {
        Log.error(TAG, 'Failed to subdivide a chunk');
        continue;
      }
      Log.debug(TAG, `Chunk subdivided into ${subdividedChunks.length} chunks`);
      Array.prototype.splice.apply(chunks, ([chunkIndex, 1] as any).concat(subdividedChunks));
      chunkIndex += subdividedChunks.length - 1;
    }
  }

  private lockReadyTimespans(chunks: EPGChunk[], lock: boolean): void {
    for (const chunk of chunks) {
      if (this.isChunkMissingTimespans(chunk)) {
        continue;
      }
      // lock only ready timespans
      for (const timespan of chunk.readyTimespans) {
        timespan.locks += lock ? 1 : -1;
      }
    }
  }

  private lockRefreshedTimespans(lock: boolean, params: EPGParams): void {
    // find the biggest possible time range that has been refreshed
    const config = nxffConfig.getConfig().EPG;
    const alignedStartTime = this.alignTimespanTime(params.startTime, true);
    const alignedEndTime = this.alignTimespanTime(params.endTime, false);
    let timespanStartTime = this.refreshedStartTime;
    let timespanEndTime = this.refreshedEndTime;
    if (lock) {
      if (!timespanStartTime || timespanStartTime > alignedStartTime) {
        timespanStartTime = alignedStartTime;
      }
      if (!timespanEndTime || timespanEndTime < alignedEndTime) {
        timespanEndTime = alignedEndTime;
      }
      this.refreshedStartTime = new Date(timespanStartTime);
      this.refreshedEndTime = new Date(timespanEndTime);
      Log.debug(TAG, `Locking refreshed timespans from ${timespanStartTime} to ${timespanEndTime}`);
    } else {
      if (!timespanStartTime || !timespanEndTime) {
        Log.debug(TAG, 'There are no locked and refreshed timespans');
        return;
      } else if (alignedStartTime > timespanEndTime || alignedEndTime < timespanStartTime) {
        Log.debug(TAG, `Ignoring an attempt to unlock refreshed timespans from ${alignedStartTime} to ${alignedEndTime} that have not been previously locked ${timespanStartTime} - ${timespanEndTime}`);
        return;
      }
      this.refreshedStartTime = null;
      this.refreshedEndTime = null;
      Log.debug(TAG, `Unlocking refreshed timespans from ${timespanStartTime} to ${timespanEndTime}`);
    }
    while (timespanStartTime < timespanEndTime) {
      const timespanKey = this.buildTimespanKey(timespanStartTime);
      for (const channel of params.channels) {
        const timespan = channel.customProperties.timespans ? channel.customProperties.timespans[timespanKey] : null;
        if (timespan) {
          timespan.locks += lock ? 1 : -1;
        }
      }
      timespanStartTime.setHours(timespanStartTime.getHours() + config.EPGCacheTimespanSize);
    }
  }

  private updateMissingTimespans(chunk: EPGChunk, events: EPGResponse): void {
    if (!chunk.missingStartTime || !chunk.missingEndTime) {
      return;
    }
    if (events.size > 0) {
      const config = nxffConfig.getConfig().EPG;
      const channels = chunk.requestedChannels.concat(chunk.alignmentChannels);
      let timespanStartTime = chunk.missingStartTime;
      while (timespanStartTime < chunk.missingEndTime) {
        const timespanEndTime = new Date(timespanStartTime);
        timespanEndTime.setHours(timespanEndTime.getHours() + config.EPGCacheTimespanSize);
        const timespanKey = this.buildTimespanKey(timespanStartTime);
        for (const channel of channels) {
          if (!channel.customProperties.timespans) {
            channel.customProperties.timespans = {};
          }
          let timespan = channel.customProperties.timespans[timespanKey];
          if (!timespan) {
            timespan = channel.customProperties.timespans[timespanKey] = new EPGTimespan(timespanKey, channel);
          }
          for (const event of events.get(channel.id) || []) {
            if (event.start > timespanEndTime || event.end < timespanStartTime || timespan.eventsMap.has(event.id)) {
              continue;
            }
            timespan.events.push(event);
            timespan.eventsMap.set(event.id, event);
            ++this.eventsInTimespans;
          }
          if (timespan.events.length === 0) {
            Log.debug(TAG, `No events were updated for timespan ${timespanKey} on channel ${channel}`);
          }
        }
        timespanStartTime = timespanEndTime;
      }
    }
    chunk.missingStartTime = null;
    chunk.missingEndTime = null;
  }

  private copyRequestedTimespans(fetchParams: EPGFetchParams, chunk: EPGChunk, events: EPGResponse): void {
    const config = nxffConfig.getConfig().EPG;
    const {startTime, endTime, limit} = fetchParams;

    for (const channel of chunk.requestedChannels) {
      const eventsOnChannel: Event[] = [];
      const timespanStartTime = this.alignTimespanTime(startTime, true);
      while (timespanStartTime < endTime && (limit === 0 || eventsOnChannel.length < limit)) {
        const timespanKey = this.buildTimespanKey(timespanStartTime);
        const timespan = channel.customProperties.timespans ? channel.customProperties.timespans[timespanKey] : null;
        // setting start time for next timespan
        timespanStartTime.setHours(timespanStartTime.getHours() + config.EPGCacheTimespanSize);
        if (!timespan) {
          Log.warn(TAG, `Missing requested timespan ${timespanKey} for channel ${channel}`);
          continue;
        }
        let eventsPerTimespan = 0;
        for (const event of timespan.events) {
          if (event.start > endTime || (limit > 0 && eventsOnChannel.length >= limit)) {
            break;
          }
          if (event.end < startTime) {
            continue;
          }
          eventsPerTimespan++;
          eventsOnChannel.push(event);
        }
        if (eventsPerTimespan > 0) {
          timespan.generation = fetchParams.timespansGeneration;
        }
      }
      if (eventsOnChannel.length > 0) {
        events.set(channel.id, (events.get(channel.id) || []).concat(eventsOnChannel));
      } else {
        Log.debug(TAG, `No events were copied for channel ${channel}`);
      }
    }
  }

  private copyRequestedChunks(chunks: EPGChunk[], fetchParams: EPGFetchParams): EPGResponse {
    const events = new Map<string, Event[]>();
    if (chunks.findIndex(this.isChunkMissingTimespans) !== -1) {
      Log.debug(TAG, `Waiting for chunks with missing timespans in generation ${fetchParams.timespansGeneration}`);
      return events;
    }
    Log.debug(TAG, `All requested timespans found in cache - copying requested timespans for generation ${fetchParams.timespansGeneration}`);
    for (const chunk of chunks) {
      this.copyRequestedTimespans(fetchParams, chunk, events);
    }
    return events;
  }

  private async findUnlockedTimespans(fetchParams: EPGFetchParams): Promise<EPGTimespan[]> {
    const timespans: EPGTimespan[] = [];
    const channels = await mw.catalog.getAllChannels();
    for (const channel of channels) {
      if (!channel.customProperties.timespans) {
        continue;
      }
      // we need to iterate over the keys here because of the unknown type of timespans taken from custom properties
      for (const timespanKey in channel.customProperties.timespans) {
        const timespan = channel.customProperties.timespans[timespanKey];
        if (timespan.locks > 0) {
          continue;
        }
        timespans.push(timespan);
      }
    }
    return timespans;
  }

  private async cleanupTimespans(fetchParams: EPGFetchParams): Promise<void> {
    const config = nxffConfig.getConfig().EPG;
    if (this.eventsInTimespans <= config.EPGCacheMaxEventsInTimespans) {
      Log.debug(TAG, `The number of events in timespans ${this.eventsInTimespans} is below the cleanup threshold value ${config.EPGCacheMaxEventsInTimespans}`);
      return;
    }
    Log.debug(TAG, `There are ${this.eventsInTimespans} events in timespans - starting the cleanup, will keep ${config.EPGCacheKeepEventsInTimespans} events`);
    const timespans = await this.findUnlockedTimespans(fetchParams);
    timespans.sort((a, b) => {
      const aOutdated = this.isTimespanOutdated(a);
      const bOutdated = this.isTimespanOutdated(b);
      // make sure to delete the outdated timespans first
      if (aOutdated && !bOutdated) {
        return -1;
      } else if (!aOutdated && bOutdated) {
        return 1;
      }
      return a.generation - b.generation;
    });
    while (this.eventsInTimespans > config.EPGCacheKeepEventsInTimespans) {
      const timespan = timespans.shift();
      if (!timespan) {
        break;
      }
      this.eventsInTimespans -= timespan.events.length;
      timespan.events.length = 0;
      timespan.eventsMap.clear();
      delete timespan.channel.customProperties.timespans[timespan.key];
    }
    Log.debug(TAG, `There are ${this.eventsInTimespans} events in all timespans after the cleanup`);
  }

  private cancelTimespansCleanup(): void {
    if (this.timespansCleanupTimer) {
      clearTimeout(this.timespansCleanupTimer);
      this.timespansCleanupTimer = null;
    }
  }

  private scheduleTimespansCleanup(fetchParams: EPGFetchParams): void {
    const config = nxffConfig.getConfig().EPG;
    this.cancelTimespansCleanup();
    this.timespansCleanupTimer = setTimeout(async () => {
      await this.cleanupTimespans(fetchParams);
      this.timespansCleanupTimer = null;
    }, config.EPGCacheTimespansCleanupTimeoutSeconds * 1000);
  }

  private async requestMissingChunk(chunk: EPGChunk, fetchParams: EPGFetchParams): Promise<boolean> {
    if (!this.isChunkMissingTimespans(chunk)) {
      return true;
    }
    Log.debug(TAG, `Sending new request for chunk with channels ${chunk.chanFrom} to ${chunk.chanTo} with missing timespans from ${chunk.missingStartTime} to ${chunk.missingEndTime}`);
    if (!chunk.missingStartTime || !chunk.missingEndTime) {
      Log.warn(TAG, 'Cannot fetch chunk, missingStartTime/EndTime are null!');
      return false;
    }
    let events = new Map<string, Event[]>();
    try {
      events = await boProxy.bo.getEPG({
        channels: (chunk.requestedChannels || []).concat(chunk.alignmentChannels || []),
        startTime: chunk.missingStartTime,
        endTime: chunk.missingEndTime
      });
    } catch (error) {
      Log.warn(TAG, `Fetching events failed for chunk with channels ${chunk.chanFrom} to ${chunk.chanTo} with missing timespans from ${chunk.missingStartTime} to ${chunk.missingEndTime}`);
      return false;
    }
    Log.debug(TAG, `Got response for chunk with channels ${chunk.chanFrom} to ${chunk.chanTo} with missing timespans from ${chunk.missingStartTime} to ${chunk.missingEndTime}`);
    this.updateMissingTimespans(chunk, events);
    return true;
  }

  private async requestMissingChunks(chunks: EPGChunk[], fetchParams: EPGFetchParams): Promise<EPGResponse> {
    const events = new Map<string, Event[]>();
    if (chunks.length === 0) {
      Log.warn(TAG, 'No missing chunks');
      return events;
    }
    const maxConcurrentRequests = nxffConfig.getConfig().EPG.EPGCacheMaxConcurrentRequestsWhenRefreshing || 1;
    const pendingChunks = [...chunks];
    while (true) {
      const nextPendingChunks = pendingChunks.splice(0, fetchParams.refreshing ? maxConcurrentRequests : pendingChunks.length);
      if (nextPendingChunks.length === 0) {
        break;
      }
      const pendingPromises = nextPendingChunks.map(nextPendingChunk => this.requestMissingChunk(nextPendingChunk, fetchParams));
      Log.debug(TAG, `Waiting for ${pendingPromises.length} requests`);
      await Promise.all(pendingPromises);
    }
    Log.debug(TAG, `All pending requests have been completed`);
    this.scheduleTimespansCleanup(fetchParams);
    this.lockReadyTimespans(chunks, false);
    return this.copyRequestedChunks(chunks, fetchParams);
  }
}
