export type OrderResponse = {
  id: string;
  productId: string;
  creationDate: string;
  assetUid?: string;
  paymentMethodId: string;
  currency: string;
  amount: number;
  orderStatus: OrderResponseStatus;
  productName: string;
  name?: string;
}

export type OrdersResponse = {
  content: OrderResponse[];
  totalElements: number;
}

export type OrderResponseStatus = 'DELIVERED' | 'PENDING_DELIVERY' | 'PROCESSING_PAYMENT' | 'PENDING_PAYMENT' | 'PAYMENT_FAILED' | 'CANCELED' | 'DELIVERY_FAILED';

export type OfferResponseDimension = {
  term: string;
  name: string;
}

export type OfferResponse = {
  dimensions: OfferResponseDimension[];
  [key: string]: any;
}

export type WatchlistResponse = {
  lastUpdate: string;
  mediaId: string;
  profileId: string;
}

export type AccountResponse = {
  firstName: string,
  surname: string
}

export enum AssetAccessStatus {
  Unavailable = 'unavailable',
  Purchasing = 'purchasing',
  Available = 'available'
}

export type AssetAccessResponse = {
  status: AssetAccessStatus;
  endTime: Date | null;
}
