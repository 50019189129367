import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions, isBigScreen} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {IconType} from 'components/Icon';
import NitroxText from 'components/NitroxText';
import Popup, {PopupAction} from 'components/Popup';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  messageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: dimensions.margins.xxLarge
  },
  menu: {
    justifyContent: 'center'
  },
  message: {
    color: colors.popup.text
  },
  button: {
    width: isBigScreen ? 'auto' : dimensions.popup.button.width,
    marginHorizontal: dimensions.margins.small
  }
}));

type Props = {
  visible: boolean;
  title: string;
  message: string;
  logout: () => void;
  actionButtonMessage?: string;
  action?: () => void;
};

const DeviceRegistrationErrorPopup: React.FunctionComponent<Props> = props => {
  const {title, message, actionButtonMessage, visible, action, logout} = props;
  const {t} = useTranslation();

  const actions = useMemo(() => {
    return (actionButtonMessage && action) ? [PopupAction.NEGATIVE, PopupAction.POSITIVE] : [PopupAction.NEGATIVE];
  }, [actionButtonMessage, action]);

  const styles = stylesUpdater.getStyles();
  return (
    <Popup
      visible={visible}
      title={title}
      actions={actions}
      menuStyle={styles.menu}
      menuButtonStyle={styles.button}
      negativeLabel={t('common.logout')}
      onModalClose={logout}
      onNegative={logout}
      positiveLabel={actionButtonMessage}
      onPositive={action}
      icon={IconType.ErrorLimitation}
      menuHasPreferredFocus
    >
      <View style={styles.messageContainer}>
        <NitroxText style={styles.message} textType='dialog-message'>{message}</NitroxText>
      </View>
    </Popup>
  );
};

export default React.memo(DeviceRegistrationErrorPopup);
