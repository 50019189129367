import {dimensions} from 'common/constants';

import {channelIconConstants} from 'components/ChannelIcon';

const scaledItemSize = channelIconConstants.channelList.scaledMobile;
const scaledItemOffset = scaledItemSize - channelIconConstants.channelList.mobile;

function calculateSeparatorContainerSize(desiredSeparatorSize: number) {
  return desiredSeparatorSize < scaledItemOffset ? 0 : desiredSeparatorSize - scaledItemOffset;
}

const horizontalDesiredSeparatorSize = calculateSeparatorContainerSize(dimensions.icon.xxsmall);
const verticalDesiredSeparatorSize = calculateSeparatorContainerSize(dimensions.icon.small);

export const channelsListItemDimensions = {
  size: scaledItemSize,
  offset: scaledItemOffset
};

export const channelsListSeparatorDimensions = {
  horizontalContainerSize: horizontalDesiredSeparatorSize,
  verticalContainerSize: verticalDesiredSeparatorSize
};
