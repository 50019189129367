import {createStyles} from 'common-styles';
import React, {useState, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions, isMobile, isBigScreen} from 'common/constants';
import {useSafeAwait} from 'common/hooks/Hooks';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {ErrorType} from 'mw/api/Error';
import {mw} from 'mw/MW';

import {IconType} from 'components/Icon';
import NitroxText from 'components/NitroxText';
import Popup, {PopupAction} from 'components/Popup';
import {getScreenInfo, useFunction} from 'hooks/Hooks';

const TAG = 'AccountAlreadyRegisteredPopup';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  title: {
    marginTop: isMobile ? dimensions.margins.large : dimensions.margins.xxLarge
  },
  subtitle: {
    ...isBigScreen && {marginBottom: dimensions.margins.xLarge}
  },
  messageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: isMobile ? dimensions.margins.xsmall : dimensions.margins.xxxLarge,
    marginTop: isMobile ? dimensions.margins.large : dimensions.margins.small
  },
  message: {
    textAlign: 'center',
    color: colors.popup.text,
    marginBottom: dimensions.margins.xLarge
  },
  menu: {
    ...isBigScreen && {backgroundColor: colors.payments.wizard.menuBackground},
    justifyContent: 'space-evenly'
  }
}));

export enum AccountAlreadyRegisteredPopupType {
  Login,
  Registration
}

type PopupContentTextProps = {
  message: string;
}

const PopupContentText: React.FC<PopupContentTextProps> = React.memo(props => {
  const styles = stylesUpdater.getStyles();
  return (
    <View style={styles.messageContainer}>
      <NitroxText style={styles.message} textType='dialog-message'>{props.message}</NitroxText>
    </View>
  );
});
PopupContentText.displayName = 'PopupContentText';

enum State {
  AccountAlreadyRegistered,
  AccountEmailSent,
  AccountDoesntExists,
  AccountAlreadyActive,
  NetworkError,
  UnexpectedError
}

type Props = {
  visible: boolean;
  emailOrUsername: string;
  onClose: () => void;
  type: AccountAlreadyRegisteredPopupType;
}

const AccountAlreadyRegisteredPopup: React.FunctionComponent<Props> = props => {
  const {visible, emailOrUsername, onClose, type} = props;
  const {t} = useTranslation();
  const [state, setState] = useState(State.AccountAlreadyRegistered);
  const [error, setError] = useState<ErrorType>();
  const {width: screenWidth} = getScreenInfo().size;
  const width = isMobile ? screenWidth : dimensions.registration.errorPopup.width;
  const safeAwait = useSafeAwait();

  const closePopup = useCallback(() => {
    onClose();
    setState(State.AccountAlreadyRegistered);
    setError(undefined);
  }, [onClose]);

  const resendActivationMail = useFunction(async () => {
    try {
      await safeAwait(mw.customer.sendAccountActivationNotification(emailOrUsername));
      Log.info(TAG, 'Account activation notification has been sent');
      setState(State.AccountEmailSent);
    } catch (error) {
      Log.error(TAG, 'Failed to send account activation notification', error);
      switch (error.type) {
        case ErrorType.AccountDoesntExist:
          setState(State.AccountDoesntExists);
          break;
        case ErrorType.AccountAlreadyConfirmed:
          setState(State.AccountAlreadyActive);
          break;
        case ErrorType.HttpTimeout:
        case ErrorType.NetworkNoConnection:
        case ErrorType.NetworkRequestFailed:
          setState(State.NetworkError);
          setError(error.type);
          break;
        default:
          setState(State.UnexpectedError);
          setError(error.type);
          break;
      }
    }
  });

  const styles = stylesUpdater.getStyles();
  const {component, ...popupProps} = useMemo(() => {
    switch (state) {
      case State.AccountAlreadyRegistered:
        const message = type === AccountAlreadyRegisteredPopupType.Login ? t('credentials.accountNotActivated') : t('registration.alreadyRegistered');
        return {
          title: t('registration.registrationResult.error.title'),
          component: (
            <View style={styles.messageContainer}>
              <NitroxText style={styles.message} textType='dialog-message'>{message}</NitroxText>
              <NitroxText style={styles.message} textType='dialog-message'>{t('registration.activationLink')}</NitroxText>
            </View>
          ),
          positiveLabel: t('registration.login'),
          onPositive: closePopup,
          negativeLabel: t('registration.resend'),
          onNegative: resendActivationMail,
          actions: [PopupAction.POSITIVE, PopupAction.NEGATIVE]
        };
      case State.AccountEmailSent:
        return {
          component: <PopupContentText message={t('registration.mailSent')} />,
          positiveLabel: t('common.ok'),
          onPositive: closePopup,
          actions: [PopupAction.POSITIVE]
        };
      case State.AccountDoesntExists:
        return {
          title: t('registration.registrationResult.error.title'),
          component: <PopupContentText message={t('registration.accountActivation.error.accountWithGivenEmailDoesntExist')} />,
          positiveLabel: t('common.ok'),
          onPositive: closePopup,
          actions: [PopupAction.POSITIVE]
        };
      case State.AccountAlreadyActive:
        return {
          title: t('registration.registrationResult.error.title'),
          component: <PopupContentText message={t('registration.accountActivation.error.accountAlreadyActive')} />,
          positiveLabel: t('common.ok'),
          onPositive: closePopup,
          actions: [PopupAction.POSITIVE]
        };
      case State.NetworkError:
        return {
          icon: IconType.ErrorInternetConnection,
          title: t('registration.registrationResult.error.title'),
          subtitle: t('common.errorCode', {errorCode: error}),
          component: <PopupContentText message={t('registration.accountActivation.error.networkProblem')} />,
          positiveLabel: t('common.retry'),
          onPositive: resendActivationMail,
          negativeLabel: t('common.back'),
          onNegative: closePopup,
          actions: [PopupAction.POSITIVE, PopupAction.NEGATIVE]
        };
      default:
        return {
          icon: IconType.ErrorUnknown,
          title: t('registration.unexpectedError.title'),
          subtitle: t('common.errorCode', {errorCode: error}),
          component: <PopupContentText message={t('registration.unexpectedError.message')} />,
          positiveLabel: t('common.retry'),
          onPositive: resendActivationMail,
          negativeLabel: t('common.back'),
          onNegative: closePopup,
          actions: [PopupAction.POSITIVE, PopupAction.NEGATIVE]
        };
    }
  }, [state, closePopup, resendActivationMail, t, styles, error, type]);

  return (
    <Popup
      visible={visible}
      width={width}
      titleStyle={styles.title}
      subtitleStyle={styles.subtitle}
      menuStyle={styles.menu}
      onModalClose={closePopup}
      {...popupProps}
    >
      {component}
    </Popup>
  );
};

export default React.memo(AccountAlreadyRegisteredPopup);
