import {asyncStorage} from 'mw/platform/async-storage/AsyncStorage';

export class Storage<T> {

  private tag: string;
  private data: T | null = null;

  public constructor(uniqueTag: string) {
    this.tag = uniqueTag;
  }

  public save(data: T | null): Promise<void> {
    this.data = data;
    return asyncStorage.setItem(this.tag, JSON.stringify(data));
  }

  public get(): Promise<T | null> {
    return this.data && Promise.resolve(this.data) || asyncStorage.getItem(this.tag)
      .then(data => data && JSON.parse(data));
  }
}
