import {EventEmitter} from 'common/EventEmitter';
import {Log} from 'common/Log';

import {netInfo} from 'mw/platform/net-info/NetInfo';
import {NetInfoConnectionType, NetInfoState, Subscription} from 'mw/platform/net-info/NetInfoInterface';

export enum NetworkConnectionEvent {
  WifiStateChanged = 'WifiStateChanged',
  ConnectionStateChanged = 'ConnectionStateChanged'
}

const TAG = 'NetworkConnectionEventNotifier';

export default class NetworkConnectionEventNotifier extends EventEmitter<NetworkConnectionEvent> {
  private isConnected = false;
  private currentConnectionType: NetInfoConnectionType = NetInfoConnectionType.None;
  private removeEventListener: Subscription | null = null;

  public constructor() {
    super();
    netInfo.fetch()
      .then(state => {
        this.isConnected = state.isConnected;
        this.currentConnectionType = state.type;
        this.removeEventListener = netInfo.addEventListener(this.updateConnectionState);
      });
  }

  public clear(): void {
    super.clear();
    this.removeEventListener?.();
  }

  private updateConnectionState = (state: NetInfoState) => {
    const connectionTypeChanged = state.type !== this.currentConnectionType;

    if (connectionTypeChanged && (state.type === NetInfoConnectionType.Wifi || this.currentConnectionType === NetInfoConnectionType.Wifi)) {
      Log.debug(TAG, 'event: NetworkConnectionEvent.WifiStateChanged', state.isConnected);
      this.notify(NetworkConnectionEvent.WifiStateChanged, state.isConnected);
    }

    if (state.isConnected && (!this.isConnected || connectionTypeChanged)) {
      Log.debug(TAG, 'event: NetworkConnectionEvent.ConnectionStateChanged');
      this.notify(NetworkConnectionEvent.ConnectionStateChanged);
    }

    this.isConnected = state.isConnected;
    this.currentConnectionType = state.type;
  }
}
