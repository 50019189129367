import {useEffect, useState} from 'react';

import {isTVOS} from 'common/constants';

import {useDebounce} from 'hooks/Hooks';

/**
 * On tvOS, focus is considered 'obtained' after small delay, to prevent races.
 * Native focus transition is handled faster than keyevent is passed, what lead to unnecessary arrow or OK key handling immediately after focus-in.
 */
export function useDelayedFocusTVOS(focusPrisonActive: boolean) {
  const [obtainedFocus, setObtainedFocus] = useState(false);
  const setObtainedFocusDebounced = useDebounce((value: boolean) => setObtainedFocus(value), 100);
  useEffect(() => {
    if (!isTVOS) {
      return;
    }

    if (focusPrisonActive) {
      setObtainedFocusDebounced(true);
    } else {
      setObtainedFocus(false);
    }

    return () => setObtainedFocusDebounced.abort();
  }, [focusPrisonActive, setObtainedFocusDebounced]);

  return {obtainedFocus};
}
