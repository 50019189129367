import {DateUtils} from 'common/DateUtils';
import {Log} from 'common/Log';

import {isSeriesRecording, Recording, RecordingErrorCode, RecordingStatus, RecordingType} from 'mw/api/Metadata';
import {convertScormToSeconds} from 'mw/common/utils';
import {RecordingObject} from 'mw/metadata/RecordingObject';

import {ChannelMapper} from './ChannelMapper';
import {ContentMapper} from './ContentMapper';
import {EventMapper} from './EventMapper';
import {SeriesMapper} from './SeriesMapper';

const TAG = 'RecordingMapper';

enum TraxisRecordingStatus {
  // for single recordings
  Complete = 'Complete',
  Partial = 'Partial',
  Recording = 'Recording',
  PartiallyRecording = 'PartiallyRecording',
  Failed = 'Failed',
  Scheduled = 'Scheduled',
  Deleted = 'Deleted',

  // for series recordings
  Active = 'Active',
  Suspended = 'Suspended',
  Completed = 'Completed'
}

enum TraxisRecordingType {
  Event = 'Event',
  Series = 'Series'
}

enum TraxisRecordingErrorCode {
  QuotaExceeded = 'QUOTA_EXCEEDED'
}

const getRecordingStatus = (recordingJson: any): RecordingStatus | undefined => {
  switch (recordingJson.State) {
    case TraxisRecordingStatus.Complete:
    case TraxisRecordingStatus.Partial:
      return RecordingStatus.Recorded;
    case TraxisRecordingStatus.Recording:
    case TraxisRecordingStatus.PartiallyRecording:
      return RecordingStatus.Recording;
    case TraxisRecordingStatus.Failed:
      return RecordingStatus.Failed;
    case TraxisRecordingStatus.Scheduled:
      return RecordingStatus.Scheduled;
    case TraxisRecordingStatus.Deleted:
      return RecordingStatus.Deleted;
  }

  switch (recordingJson.FactoryState) {
    case TraxisRecordingStatus.Active:
      return RecordingStatus.Active;
    case TraxisRecordingStatus.Completed:
      return RecordingStatus.Completed;
    case TraxisRecordingStatus.Suspended:
      return RecordingStatus.Suspended;
  }
};

export class RecordingMapper {
  public static fromJSON(recordingJson: any, parentRecording?: Recording): Recording {
    const {GuardTimeStart = '', GuardTimeEnd = '', StartTime, ActualStartTime, EndTime, ActualEndTime, Type, ParentRecordings, ChildRecordings} = recordingJson;

    const recording = new RecordingObject(
      recordingJson.id,
      RecordingMapper.getRecordingType(recordingJson),
      recordingJson.Name,
      ParentRecordings?.Recording?.[0]?.id || parentRecording?.id
    );

    const channelJson = (recordingJson.Channels && recordingJson.Channels.Channel && recordingJson.Channels.Channel[0]) || {};
    const channel = ChannelMapper.toChannel(channelJson);
    if (channel) {
      recording.channelId = channel.id;
    }

    switch (Type) {
      case TraxisRecordingType.Event:
        recording.event = EventMapper.fromRecordingsJSON(recordingJson);
        recording.pictures = recording.event.title.pictures;
        recording.pcRatings = recording.event.pcRatings;
        break;

      case TraxisRecordingType.Series:
        let childRecordings;
        if (ChildRecordings?.Recording) {
          childRecordings = RecordingMapper.fromJSONArray(recordingJson.ChildRecordings.Recording, recording);
          childRecordings.forEach(childRecording => {
            childRecording.recordingType === RecordingType.Single ? recording.episodesCount++ : recording.seasonsCount++;
          });
          recording.recordings = childRecordings;
        }

        recording.seedEventId = recordingJson.EventId || parentRecording?.seedEventId;
        recording.seedSeriesNumber = recordingJson.SeedSeriesNumber;
        recording.series = SeriesMapper.fromJSON(recordingJson.SeriesCollection?.Series?.[0]);
        recording.pcRatings = recording.series.pcRatings;
        recording.pictures = (recording.series?.pictures.length ? recording.series?.pictures : childRecordings?.[0]?.event?.title.pictures) || [];
        break;
    }

    if (typeof recordingJson.PersonalBookmark !== 'undefined') {
      recording.bookmark = recordingJson.PersonalBookmark;
    }

    const contentsJson = recordingJson.Contents?.Content;

    if (contentsJson) {
      recording.contents = ContentMapper.fromJSONArray(contentsJson);
    }

    /**
     * Until traxis version 4.8.4113.15, traxis did NOT include guard times in Duration and
     * ActualStartTime/ActualEndTime values.
     *
     * Since traxis version 4.8.4113.16, by default traxis DOES include guard times values in those properties.
     * This is configurable (by default guard times are added), so it MIGHT be disabled.
     *
     * There is no way for the CPE to directly check whether this is enabled or not, but it can be inferred from the
     * following info:
     * If guardTimeStart > 0 and StartTime === ActualStartTime then (traxisVersion LESS OR EQUAL 4.8.4113.15) or
     * (traxisVersion GREATER OR EQUAL 4.8.4113.16 AND traxis default config changed)
     *
     * in this case, client should locally sum Duration, GuardTimeStart and GuardTimeEnd to determine the true length
     * of a recording.
     */
    const guardTimeStartInSec = convertScormToSeconds(GuardTimeStart);
    const guardTimeEndInSec = convertScormToSeconds(GuardTimeEnd);
    recording.guardTimeStart = guardTimeStartInSec / DateUtils.sInMin;
    recording.guardTimeEnd = guardTimeEndInSec / DateUtils.sInMin;
    recording.duration = recordingJson.Duration ? convertScormToSeconds(recordingJson.Duration) : recording.contents[0]?.duration;

    if ((guardTimeStartInSec && StartTime === ActualStartTime) || (guardTimeEndInSec && EndTime === ActualEndTime)) {
      recording.duration += guardTimeStartInSec + guardTimeEndInSec;
    }

    if (typeof recordingJson.IsPersonallyViewedCompletely !== 'undefined') {
      recording.isViewedCompletely = recordingJson.IsPersonallyViewedCompletely;
    }

    if (recordingJson.ProfileId) {
      recording.profileId = recordingJson.ProfileId;
    }

    recording.status = getRecordingStatus(recordingJson);

    if (recordingJson.ErrorCode === TraxisRecordingErrorCode.QuotaExceeded) {
      recording.errorCode = RecordingErrorCode.QuotaExceeded;
    }

    return recording;
  }

  public static fromJSONArray(recordingsJsonArray: any[], parentRecording?: Recording): Recording[] {
    return recordingsJsonArray.map(recordingJson => RecordingMapper.fromJSON(recordingJson, parentRecording));
  }

  private static getRecordingType(recordingJson: any): RecordingType {
    switch (recordingJson.Type) {
      case TraxisRecordingType.Series:
        return RecordingType.Series;

      case TraxisRecordingType.Event:
        return RecordingType.Single;

      default:
        Log.warn(TAG, 'getRecordingType: not supported recording type', recordingJson.Type);
        return RecordingType.Unsupported;
    }
  }

  public static extractViewedSeriesRecordingsMap(recordings: Recording[], limit: number): Map<string, Recording> {
    const results: Map<string, Recording> = new Map();
    for (const recording of recordings) {
      if (results.size >= limit) {
        break;
      }
      const title = recording.event?.title;
      if (!title?.episode?.seasonId) {
        continue;
      }

      if (title.episode.seriesId) {
        if (!results.has(title.episode.seriesId)) {
          results.set(title.episode.seriesId, recording);
        }
        continue;
      }
      if (!results.has(title.episode.seasonId)) {
        results.set(title.episode.seasonId, recording);
      }
    }

    return results;
  }

  public static findLastNotViewedCompletelyEpisode(recordings: Recording[], recording: Recording): Recording | null {
    if (!recordings.length) {
      return null;
    }

    // recording should be matched with only one parent series recording (series or season)
    const seriesRecording = recordings[0];
    const childRecordings = isSeriesRecording(seriesRecording) && seriesRecording.recordings;
    if (!childRecordings || !childRecordings.length) {
      return null;
    }

    const flattenRecordings: Recording[] = [];
    childRecordings.reduce((reduced, current) => {
      if (isSeriesRecording(current)) {
        reduced.push(...current.recordings);
      } else {
        reduced.push(current);
      }
      return reduced;
    }, flattenRecordings);

    let index = flattenRecordings.findIndex(r => r.id === recording.id);
    if (index === -1) {
      return null;
    }
    do {
      const found = flattenRecordings[++index];
      if (!found) {
        return null;
      }

      if (!found.isViewedCompletely) {
        return found;
      }
    } while (true);
  }

  public static getRecordingsStatus(recordingsJson: any): Map<string, RecordingStatus> {
    const map = (recordingsJson.Recordings.Recording || []).reduce((events: Map<string, RecordingStatus>, recordingJson: any) => {
      const status = getRecordingStatus(recordingJson);
      if (status == null) {
        return;
      }
      return events.set(recordingJson.EventId, status);
    }, new Map());
    return map;
  }
}
