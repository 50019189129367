import {createStyles} from 'common-styles';
import React from 'react';
import {View} from 'react-native';

import {dimensions, isBigScreen} from 'common/constants';
import {doNothing} from 'common/HelperFunctions';

import {Credits} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import {Grid} from 'components/Grid';

import CreditsViewGrosso from './CreditsView.grosso';
import CreditsViewPiccolo from './CreditsView.piccolo';
import {mediaDetailHorizontalMargin} from './MediaDetailUtils';
import MugShot, {mugShotWidth, mugShotPadding} from './MugShot';

const styles = createStyles({
  mugshotGridContainer: {
    paddingHorizontal: mediaDetailHorizontalMargin - mugShotPadding
  }
});

function renderMugShot(props: {data: Credits; spacing: number; onFocus?: () => void}) {
  return (
    <View style={{marginLeft: props.spacing}}>
      <MugShot credits={props.data} onFocus={props.onFocus} />
    </View>
  );
}

type Props = {
  directors: Credits[];
  actors: Credits[];
  onPress?: (creditsItem: Credits) => void;
};

const CreditsView: React.FC<Props> = (props: Props) => {
  const properties = {
    credits: [...props.directors, ...props.actors],
    onPress: props.onPress ?? doNothing
  };

  if (mw.catalog.providesMugShots()) {
    return (
      <View style={styles.mugshotGridContainer}>
        <Grid<Credits>
          data={properties.credits}
          itemWidth={mugShotWidth}
          minimumSpacing={dimensions.margins.small}
          maximumSpacing={mugShotPadding}
          columnWrapperStyle={{marginBottom: dimensions.margins.medium}}
          createElement={renderMugShot}
        />
      </View>
    );
  }

  return isBigScreen ? <CreditsViewGrosso {...properties} /> : <CreditsViewPiccolo {...properties} />;
};

export default React.memo(CreditsView);
