import {Media} from 'mw/api/Metadata';

type VoucherRedemptionProps = {
  onClose?: () => void;
}

enum VoucherState {
  Activated = 'Activated',
  RedeemError = 'RedeemError',
  ForbiddenTransactionError = 'ForbiddenTransactionError',
  Loading = 'Loading',
  Redeem = 'Redeem',
}

type MobileScrollElementProps = {
  index: number;
  media: Media;
  onPress: () => void;
}

export {MobileScrollElementProps, VoucherRedemptionProps, VoucherState};
