import {ContentType} from 'mw/api/Metadata';
import {PlaybackResponse} from 'mw/playback/types/PlaybackParameters';
import {PlayerType} from 'mw/playback/types/PlayerType';

import {BookmarkedPlayer} from './BookmarkedPlayer';
import {StartPlaybackParameters} from './PlaybackController';

export class NPVRPlayer extends BookmarkedPlayer {

  public constructor(playerType: PlayerType) {
    super(playerType, ContentType.NPVR);
  }

  public startPlayback(params: StartPlaybackParameters): Promise<PlaybackResponse> {
    params.playbackParameters.position = params.playbackParameters.position || 0;
    return super.startPlayback(params);
  }
}
