import fscreen from 'fscreen';
import React, {createContext, useMemo, useContext, useState, useEffect} from 'react';

import {isFullScreenModeSupported} from 'common/constants';
import {doNothing} from 'common/HelperFunctions';
import {NavigationFocusState} from 'common/HelperTypes';

import {SupportedKeys, NativeKeyEvent} from 'components/KeyEventManager';
import {useNavigationFocusState, useNavigation, useKeyEventHandler, useChangeEffect, useFunction} from 'hooks/Hooks';

export type FullScreenControlContextType = {
  enabled: boolean;
  setEnabled(enabled: boolean): void;
  fullScreenModeActive: boolean;
  enterFullScreenMode(): void;
  exitFullScreenMode(): void;
  toggleFullScreenMode(): void;
};

const FullScreenControlContext = createContext<FullScreenControlContextType>({
  enabled: false,
  setEnabled: doNothing,
  fullScreenModeActive: false,
  enterFullScreenMode: doNothing,
  exitFullScreenMode: doNothing,
  toggleFullScreenMode: doNothing
});

export function useFullScreenControl(): FullScreenControlContextType {
  return useContext(FullScreenControlContext);
}

export const FullScreenControlProvider: React.FC = ({children}) => {
  const [fullScreenModeActive, setFullScreenModeActive] = useState(false);
  const onFullScreenModeChange = useFunction(() => {
    setFullScreenModeActive(!!fscreen.fullscreenElement);
  });

  useEffect(() => {
    // fscreen on none-browser-based platforms maps document to an empty object therefore a checked whether or not
    // the full-screen mode is support is required before using any of its properties.
    if (isFullScreenModeSupported) {
      fscreen.addEventListener('fullscreenchange', onFullScreenModeChange);
    }
    return () => {
      if (isFullScreenModeSupported) {
        fscreen.removeEventListener('fullscreenchange', onFullScreenModeChange);
      }
    };
  }, [onFullScreenModeChange]);

  const [enabled, setEnabled] = useState(false);
  const contextValue = useMemo((): FullScreenControlContextType => ({
    enabled,
    setEnabled: isFullScreenModeSupported ? setEnabled : doNothing,
    fullScreenModeActive,
    enterFullScreenMode: enabled && !fullScreenModeActive ? () => {
      fscreen.requestFullscreen(document.body);
    } : doNothing,
    exitFullScreenMode: enabled && fullScreenModeActive ? () => {
      fscreen.exitFullscreen();
    } : doNothing,
    toggleFullScreenMode: enabled ? () => {
      if (fullScreenModeActive) {
        fscreen.exitFullscreen();
      } else {
        fscreen.requestFullscreen(document.body);
      }
    } : doNothing
  }), [enabled, setEnabled, fullScreenModeActive]);

  const onKeyPress = useFunction((event: NativeKeyEvent) => {
    if (event.key === SupportedKeys.FullScreen) {
      contextValue.toggleFullScreenMode();
    }
  });

  useKeyEventHandler('keyup', onKeyPress);

  useChangeEffect(() => {
    if (!enabled && fullScreenModeActive) {
      fscreen.exitFullscreen();
    }
  }, [enabled], [fullScreenModeActive]);

  return (
    <FullScreenControlContext.Provider value={contextValue}>
      {children}
    </FullScreenControlContext.Provider>
  );
};

export function useEnableFullScreenControlOnAppear(): void {
  const navigation = useNavigation();
  const navigationFocusState = useNavigationFocusState(navigation);
  const {setEnabled} = useFullScreenControl();

  useEffect(() => {
    setEnabled(navigationFocusState === NavigationFocusState.IsFocused);
  }, [setEnabled, navigationFocusState]);
}
