import React, {useMemo} from 'react';
import Reanimated, {useCode, call} from 'react-native-reanimated';

import {epgItemHeight} from 'common/constants';

import {Channel} from 'mw/api/Metadata';

import {useChannelList} from './ChannelListHooks';
import ChannelView from './ChannelView';
import AnimatedScrollView from './ReanimatedScrollView';

export const channelsContainerWidth = 190;

type ChannelListViewProps = {
  channels: Channel[];
  currentChannel?: Channel;
  position: Reanimated.Value<number>;
}

const ChannelListView: React.FC<ChannelListViewProps> = React.memo(({
  channels,
  currentChannel,
  position
}) => {
  const channelListSize = useMemo(() => ({
    width: channelsContainerWidth,
    height: channels.length * epgItemHeight
  }), [channels.length]);

  const {
    displayedChannels,
    scrollOffsetChanged
  } = useChannelList({currentChannel, channels});

  const displayedChannelIcons = useMemo(() => displayedChannels.map(({channel, index, isCurrent}) => (
    <ChannelView
      key={channel.id}
      channel={channel}
      isCurrent={isCurrent}
      index={index}
    />
  )), [displayedChannels]);

  useCode(() => call([position], ([position]) => {
    const offset = -position;

    scrollOffsetChanged({value: offset});
  }), [position]);

  return (
    <AnimatedScrollView
      positionY={position}
      size={channelListSize}
    >
      {displayedChannelIcons}
    </AnimatedScrollView>
  );
});
ChannelListView.displayName = 'ChannelListView';

export default ChannelListView;
