import {createStyles} from 'common-styles';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Icon, IconType} from 'components/Icon';
import NitroxInteractive from 'components/NitroxInteractive';
import NitroxText from 'components/NitroxText';
import {PostProcessors} from 'locales/i18nPostProcessors';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  buttonText: {
    color: colors.overlayMediaDetails.text.focused
  }
}));
const staticStyles = createStyles({
  buttonContainer: {
    alignItems: 'center',
    width: '100%'
  },
  moreButtonText: {
    marginBottom: dimensions.margins.medium
  },
  moreButtonContainer: {
    paddingBottom: dimensions.margins.xxLarge
  },
  closeButtonContainer: {
    paddingTop: dimensions.margins.xxLarge
  },
  closeButtonText: {
    marginTop: dimensions.margins.medium
  }
});

type Props = {
  onPress: () => void;
  disabled?: boolean;
}

export const SeeMoreButton: React.FC<Props> = ({onPress, disabled}) => {
  const styles = stylesUpdater.getStyles();
  const {t} = useTranslation();
  return (
    <NitroxInteractive style={[staticStyles.buttonContainer, staticStyles.moreButtonContainer]} onPress={onPress} disabled={disabled}>
      <NitroxText textType='callout' style={[styles.buttonText, staticStyles.moreButtonText]}>
        {t('mediaDetails.seeMore', {postProcess: PostProcessors.ToUpperCase})}
      </NitroxText>
      <Icon type={IconType.SlimArrowDown} size={dimensions.icon.xxxsmall} />
    </NitroxInteractive>
  );
};

export const CloseMoreButton: React.FC<Props> = ({onPress, disabled}) => {
  const styles = stylesUpdater.getStyles();
  const {t} = useTranslation();
  return (
    <NitroxInteractive style={[staticStyles.buttonContainer, staticStyles.closeButtonContainer]} onPress={onPress} disabled={disabled}>
      <Icon type={IconType.SlimArrowUp} size={dimensions.icon.xxxsmall} />
      <NitroxText textType='callout' style={[styles.buttonText, staticStyles.closeButtonText]}>
        {t('common.close', {postProcess: PostProcessors.ToUpperCase})}
      </NitroxText>
    </NitroxInteractive>
  );
};
