import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View, ViewStyle} from 'react-native';

import {dimensions, getValue} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import ArrowSelector from 'components/ArrowSelector';
import {arrowSelector} from 'components/ArrowSelector.shared';
import NitroxText from 'components/NitroxText';

const marginVertical = 20;
const columnContainerHeight = 2 * arrowSelector.height + 2 * marginVertical;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  textStyle: {
    color: colors.popup.text
  }
}));

const styles = createStyles({
  container: {
    alignItems: getValue({mobile: 'flex-start', tablet: 'center', defaultValue: 'center'}),
    overflow: 'hidden',
    paddingTop: getValue({mobile: dimensions.margins.medium, tablet: 0, defaultValue: 0}),
    paddingBottom: getValue({tablet: dimensions.margins.large, defaultValue: 0})
  },
  columnContainer: {
    flexDirection: 'column',
    height: columnContainerHeight,
    justifyContent: 'space-around',
    alignItems: 'flex-start'
  },
  itemContainer: {
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center'
  },
  prefixContainer: {
    marginRight: getValue({mobile: dimensions.margins.medium, defaultValue: 0})
  },
  guardTimePickerContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  arrowSelector: {
    marginHorizontal: getValue({mobile: dimensions.margins.small, defaultValue: dimensions.margins.xxLarge})
  },
  disabled: {
    opacity: dimensions.opacity.xlow
  }
});

type Props = {
  availableStartValues?: number[],
  availableEndValues?: number[],
  initialStartValue?: number,
  initialEndValue?: number,
  onGuardTimeStartChange: (value: number) => void,
  onGuardTimeEndChange: (value: number) => void,
  startStyle?: ViewStyle,
  endStyle?: ViewStyle,
  disabled?: boolean
}

const GuardTimeSelector: React.FunctionComponent<Props> = props => {
  const {
    onGuardTimeStartChange,
    availableStartValues,
    initialStartValue,
    startStyle,
    onGuardTimeEndChange,
    availableEndValues,
    initialEndValue,
    disabled
  } = props;
  const dynamicStyles = stylesUpdater.getStyles();
  const {t} = useTranslation();
  const containerStyle = useMemo(() => [styles.guardTimePickerContainer, startStyle], [startStyle]);
  const startPrefixStyle = useMemo(() => [dynamicStyles.textStyle, styles.prefixContainer, disabled && styles.disabled], [dynamicStyles, disabled]);
  const startSuffixStyle = useMemo(() => [dynamicStyles.textStyle, disabled && styles.disabled], [dynamicStyles, disabled]);
  const endPrefixStyle = useMemo(() => [dynamicStyles.textStyle, styles.prefixContainer, disabled && styles.disabled], [dynamicStyles, disabled]);
  const endSuffixStyle = useMemo(() => [dynamicStyles.textStyle, disabled && styles.disabled], [dynamicStyles, disabled]);

  return (
    <View style={containerStyle}>
      <View style={[styles.columnContainer, {maxWidth: '30%'}]}>
        {!!availableStartValues?.length &&
          (
            <View style={styles.itemContainer}>
              <NitroxText
                textType='arrowSelector'
                style={startPrefixStyle}
                numberOfLines={2}
              >
                {t('recordings.recordingConfigPopup.guardTimeStart.prefix')}
              </NitroxText>
            </View>
          )
        }
        {!!availableEndValues?.length &&
          (
            <View style={styles.itemContainer}>
              <NitroxText
                textType='arrowSelector'
                style={endPrefixStyle}
                numberOfLines={2}
              >
                {t('recordings.recordingConfigPopup.guardTimeEnd.prefix')}
              </NitroxText>
            </View>
          )
        }
      </View>
      <View style={styles.columnContainer}>
        {!!availableStartValues?.length &&
          (
            <View style={styles.itemContainer}>
              <ArrowSelector
                style={styles.arrowSelector}
                availableValues={availableStartValues}
                onValueChange={onGuardTimeStartChange}
                initialValue={initialStartValue}
                suffix={t('time.min')}
                disabled={disabled}
              />
            </View>
          )
        }
        {!!availableEndValues?.length &&
          (
            <View style={styles.itemContainer}>
              <ArrowSelector
                style={styles.arrowSelector}
                availableValues={availableEndValues}
                onValueChange={onGuardTimeEndChange}
                initialValue={initialEndValue}
                suffix={t('time.min')}
                disabled={disabled}
              />
            </View>
          )
        }
      </View>
      <View style={[styles.columnContainer, {maxWidth: '25%'}]}>
        {!!availableStartValues?.length &&
          (
            <View style={styles.itemContainer}>
              <NitroxText
                textType='arrowSelector'
                numberOfLines={2}
                style={startSuffixStyle}
              >
                {t('recordings.recordingConfigPopup.guardTimeStart.suffix')}
              </NitroxText>
            </View>
          )
        }
        {!!availableEndValues?.length &&
          (
            <View style={styles.itemContainer}>
              <NitroxText
                textType='arrowSelector'
                numberOfLines={2}
                style={endSuffixStyle}
              >
                {t('recordings.recordingConfigPopup.guardTimeEnd.suffix')}
              </NitroxText>
            </View>
          )
        }
      </View>
    </View>
  );
};

export default React.memo(GuardTimeSelector);
