import {createStyles} from 'common-styles';
import React, {useState, useCallback} from 'react';
import {View} from 'react-native';

import {dimensions, isBigScreen} from 'common/constants';
import {TestProps} from 'common/HelperTypes';

import {StylesUpdater, useStyles} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import Checkbox from 'components/Checkbox';
import NitroxInteractive from 'components/NitroxInteractive';
import NitroxText from 'components/NitroxText';
import RadioButton from 'components/payments/RadioButton';
import Separator from 'components/Separator';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  itemContainerFocused: {
    backgroundColor: colors.listView.item.focused.background
  },
  labelContainer: {
    color: colors.settingsScreen.mobile.list.tabletSubtitle
  },
  separator: {
    marginTop: dimensions.margins.xsmall,
    marginBottom: dimensions.margins.xsmall,
    backgroundColor: colors.epgScreen.label
  }
}));

const staticStyles = createStyles({
  container: {
    justifyContent: 'center'
  },
  itemContainer: {
    paddingTop: dimensions.margins.medium,
    paddingBottom: dimensions.margins.medium,
    justifyContent: 'space-between',
    ...isBigScreen && {
      paddingLeft: dimensions.margins.large
    }
  },
  listItemContent: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  rightContent: {
    flexBasis: '90%',
    paddingLeft: isBigScreen ? dimensions.margins.xLarge : dimensions.margins.large
  },
  leftContent: {
    flexBasis: '10%'
  },
  radioButton: {
    width: dimensions.buttons.small,
    height: dimensions.buttons.small,
    borderRadius: 50
  },
  radioButtonInner: {
    width: isBigScreen ? dimensions.margins.xxLarge : dimensions.icon.xxsmall,
    height: isBigScreen ? dimensions.margins.xxLarge : dimensions.icon.xxsmall,
    borderRadius: 50
  }
});

export enum SelectionType {
  CheckBox = 'checkBox',
  RadioBox = 'radioBox',
}

export type ListItemProps = {
  alias: string;
  label: string;
  selectionType?: SelectionType;
  selected?: boolean;
  onPress?: (alias: string) => void;
} & TestProps;

export type ListViewProps = {
  selectionType: SelectionType;
  items?: ListItemProps[];
  selectedItems: string[];
  onPress?: (alias: string) => void;
};

const ListItem = (props: ListItemProps) => {
  const styles = useStyles(stylesUpdater);
  const {
    alias,
    label,
    selected = false,
    selectionType,
    onPress: onPressItem
  } = props;
  const [focused, setFocused] = useState(false);

  const onPress = useCallback(() => {
    onPressItem?.(alias);
  }, [alias, onPressItem]);

  return (
    <NitroxInteractive
      activeOpacity={isBigScreen ? 1 : undefined}
      onPress={onPress}
      onFocusStateChanged={setFocused}
    >
      <View style={[
        staticStyles.itemContainer,
        // this is defined globally per list, so it has lower precedence that containerStyle defined for item exclusively
        focused && styles.itemContainerFocused
      ]}
      >
        <View style={staticStyles.listItemContent}>
          <View style={staticStyles.leftContent}>
            {selectionType === SelectionType.CheckBox && <Checkbox selected={selected} />}
            {selectionType === SelectionType.RadioBox && <RadioButton selected={selected} style={staticStyles.radioButton} styleInner={staticStyles.radioButtonInner} />}
          </View>
          <View style={staticStyles.rightContent}>
            <NitroxText
              textType={isBigScreen ? 'label' : 'subhead-medium'}
              style={styles.labelContainer}
              // can't trim line height here, as it breaks centering text for larger fonts
              trimLine={false}
            >
              {label}
            </NitroxText>
          </View>
        </View>
      </View>
    </NitroxInteractive>
  );
};

export const LoginStepSelection: React.FC<ListViewProps> = props => {
  const styles = useStyles(stylesUpdater);
  const {onPress, items, selectedItems, selectionType} = props;

  return (
    <>
      {(items || []).map((item, i, items) => {
        const selected = selectedItems.includes(item.alias);
        return (
          <View key={item.alias}>
            <ListItem selectionType={selectionType} {...item} selected={selected} onPress={onPress} />
            {items.length - 1 !== i && <Separator horizontal style={styles.separator} />}
          </View>
        );
      })}
    </>
  );
};

export default LoginStepSelection;
