import {createStyles} from 'common-styles';
import React, {useCallback} from 'react';
import {View} from 'react-native';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors, constColors} from 'common-styles/variables/base-colors';

import {Media, MediaType, Title} from 'mw/api/Metadata';

import MediaTile from 'components/mediaTiles/MediaTile';
import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';

import NitroxInteractive from './NitroxInteractive';
import NitroxText from './NitroxText';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  description: {
    width: dimensions.tile.width,
    paddingHorizontal: dimensions.margins.small,
    paddingBottom: dimensions.margins.small
  },
  descriptionText: {
    color: colors.tile.description
  }
}));

type Props = {
  media: Media;
  onPress?: (media: Media) => void;
}

const PosterTile: React.FC<Props> = props => {
  const {media, onPress} = props;
  const {isMediaBlocked, shouldBeCheckedForPC} = useParentalControl();
  const isBlocked = shouldBeCheckedForPC(media) && isMediaBlocked(media);

  let description = '';
  if (media.getType() === MediaType.Title) {
    const {metadata} = media as Title;
    description = metadata.mediumSynopsis || metadata.shortSynopsis || metadata.longSynopsis;
  }

  const onPressHandler = useCallback(() => {onPress?.(media);}, [media, onPress]);

  const styles = stylesUpdater.getStyles();
  return (
    <NitroxInteractive
      onPress={onPressHandler}
      underlayColor={constColors.transparent}
      testID='poster'
    >
      <View>
        <MediaTile media={props.media} focusable={false} />
        {!isBlocked && description && (
          <View style={styles.description}>
            <NitroxText numberOfLines={4} textType='callout' style={styles.descriptionText}>
              {description}
            </NitroxText>
          </View>
        )}
      </View>
    </NitroxInteractive>
  );
};

export default React.memo(PosterTile);
