import {createStyles} from 'common-styles';
import React from 'react';
import {ScrollView} from 'react-native';

import {dimensions} from 'common/constants';

import FocusParent, {useFocusParent} from 'components/FocusParent';
import {HotKeysContextProvider} from 'components/HotKeys';
import {SupportedKeys} from 'components/KeyEventManager';
import {mediaTileMarginHorizontal} from 'components/mediaTiles/MediaTile';
import {NitroxInteractiveController} from 'components/NitroxInteractiveControllerContext';
import {useRecordingsGrid} from 'screens/recordings/Hooks';

import {RecordingsFolderContentProps} from './RecordingsFolderHelperTypes';

const hotKeys = [SupportedKeys.Info];

const staticStyles = createStyles({
  grid: {
    marginTop: dimensions.screen.recordings.grid.marginTop,
    marginLeft: -mediaTileMarginHorizontal, // compensate tiles margins
    width: '100%',
    flex: 1
  },
  gridContentStyle: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  }
});

const RecordingsFolderContentGrosso: React.FC<RecordingsFolderContentProps> = React.memo(({
  recordings,
  selectable,
  selection,
  selectRecording,
  onTilePress
}) => {
  const [onParentReady, focus] = useFocusParent();

  const {renderGridTile, tilesData, onGridLayout} = useRecordingsGrid({
    recordings,
    selection,
    selectable,
    selectRecording,
    onTilePress,
    onFirstTileMount: focus,
    isFolder: true
  });

  return (
    <FocusParent
      style={staticStyles.grid}
      onReady={onParentReady}
    >
      <HotKeysContextProvider hotKeys={hotKeys}>
        <NitroxInteractiveController omitGeometryCaching>
          <ScrollView
            contentContainerStyle={staticStyles.gridContentStyle}
            onLayout={onGridLayout}
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            alwaysBounceVertical={false}
          >
            {tilesData.map(renderGridTile)}
          </ScrollView>
        </NitroxInteractiveController>
      </HotKeysContextProvider>
    </FocusParent>
  );
});
RecordingsFolderContentGrosso.displayName = 'RecordingsFolderContentGrosso';

export default RecordingsFolderContentGrosso;
