import {createStyles} from 'common-styles';
import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, View, ViewStyle} from 'react-native';

import {dimensions, getValue} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {PCRatingsMap} from 'mw/api/Profile';
import {ParentalControlRating} from 'mw/common/ParentalControlRating';
import {mw, PCType} from 'mw/MW';

import InfoPopup from 'components/InfoPopup';
import WizardButton from 'components/WizardButton';
import {useFunction} from 'hooks/Hooks';
import {PCTranslationsType} from 'screens/settings/ParentalControlHelpers';
import {getPCAuthorityDescriptionTranslation} from 'screens/settings/ParentalControlHelpers';
import ParentalControlRatingSelectionPopup from 'screens/settings/ParentalControlRatingSelectionPopup';
import ParentalControlUnratedSelectionPopup from 'screens/settings/ParentalControlUnratedSelectionPopup';
import BirthInput from 'screens/settings/profileEdit/BirthInput';

import {WizardToggleSwitch} from './WizardToggleSwitch';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    width: '100%'
  },
  parentalSwitch: {
    marginBottom: getValue({mobile: 0, tablet: dimensions.margins.xxxLarge, defaultValue: dimensions.margins.small})
  },
  parentalMessage: {
    color: colors.settingsScreen.profile.wizard.sublabel
  },
  adultContentToggle: {
    marginVertical: dimensions.margins.small
  },
  disabledAdultContentToggle: {
    opacity: dimensions.opacity.xlow
  },
  toggleSwitchLabel: {
    width: dimensions.toggleSwitch.label.width
  }
}));

enum PopupType {
  None,
  Ratings,
  UnratedEvents,
  UnratedMovies,
  RatingStatus
}

type PopupState = {
  type: PopupType;
}

type RatingsState = {
  rating?: ParentalControlRating;
  initialRating?: string;
}

const NoPopup: PopupState = {type: PopupType.None};
const RatingsPopup: PopupState = {type: PopupType.Ratings};
const RatingStatus: PopupState = {type: PopupType.RatingStatus};
const UnratedEventsPopup: PopupState = {type: PopupType.UnratedEvents};
const UnratedMoviesPopup: PopupState = {type: PopupType.UnratedMovies};

export type WizardParentalControlRatingsSettingsProps = {
  parentalControl: boolean;
  pcRatings: PCRatingsMap;
  blockUnratedEvents: boolean;
  blockUnratedMovies: boolean;
  showAdultContent: boolean;
  contentStyle?: StyleProp<ViewStyle>;
  embedded?: boolean;
  pcTranslationsType?: PCTranslationsType;
  onParentalChanged: (enabled: boolean) => void;
  onPCRatingChanged: (authority: string, rating: string) => void;
  onBlockUnratedEventsChanged: (block: boolean) => void;
  onBlockUnratedMoviesChanged: (block: boolean) => void;
  onShowAdultContentChanged: (show: boolean) => void;
  yearOfBirth?: number;
  onBirthDateChanged?: (year?: number) => void;
  onBirthDateSubmit?: () => void;
  toggleParentalControl: () => void;
  onInputFocusChange?: (isInputFocused: boolean) => void;
};

const WizardParentalControlRatingsSettings: React.FC<WizardParentalControlRatingsSettingsProps> = props => {
  const {
    parentalControl,
    pcRatings,
    pcTranslationsType = PCTranslationsType.Settings,
    onPCRatingChanged,
    onBlockUnratedEventsChanged,
    onBlockUnratedMoviesChanged,
    showAdultContent,
    onShowAdultContentChanged,
    yearOfBirth,
    onBirthDateChanged,
    onBirthDateSubmit,
    toggleParentalControl,
    onInputFocusChange
  } = props;
  const {t} = useTranslation();

  const ageBased = mw.configuration.pcPolicy.type === PCType.AgeBased;
  const [popupState, setPopupState] = useState(NoPopup);
  const [ratingsState, setRatingsState] = useState<RatingsState>({});

  const showRatingsPopup = useFunction((rating: ParentalControlRating) => {
    setPopupState(RatingsPopup);
    setRatingsState({
      rating,
      initialRating: pcRatings[rating.authority]
    });
  });

  const showRatingStatus = useFunction(() => setPopupState(RatingStatus));
  const showUnratedTvEventsPopup = useFunction(() => setPopupState(UnratedEventsPopup));
  const showUnratedMoviePopup = useFunction(() => setPopupState(UnratedMoviesPopup));
  const hidePopup = useFunction(() => setPopupState(NoPopup));

  const ratingButtons = useMemo(() => (
    mw.configuration.supportedPCRatings.map((rating: ParentalControlRating, index: number) => {
      return (
        <WizardButton
          key={index}
          description={getPCAuthorityDescriptionTranslation(t, rating.authority, pcTranslationsType)}
          label={pcRatings[rating.authority] || t('settings.parentalControl.select')}
          disabled={!parentalControl}
          onPress={() => showRatingsPopup(rating)}
        />
      );
    })
  ), [t, pcRatings, pcTranslationsType, parentalControl, showRatingsPopup]);

  const onInputFocusHandler = useFunction(() => {
    onInputFocusChange?.(true);
  });

  const onInputBlurHandler = useFunction(() => {
    onInputFocusChange?.(false);
  });

  const toggleParentalInformationPopup = useFunction(() => {
    showRatingStatus();
    toggleParentalControl();
  });

  const toggleShowAdultContent = useFunction(() => {
    onShowAdultContentChanged(!showAdultContent);
  });

  const styles = stylesUpdater.getStyles();
  const adultContentToggleStyle = useMemo(() => [
    styles.adultContentToggle,
    !parentalControl && styles.disabledAdultContentToggle
  ], [styles.adultContentToggle, styles.disabledAdultContentToggle, parentalControl]);

  return (
    <>
      <View style={styles.container}>
        {ageBased && (
          <BirthInput
            onBirthDateChanged={onBirthDateChanged}
            yearOfBirth={yearOfBirth}
            embedded
            onBirthDateSubmit={onBirthDateSubmit}
            onFocus={onInputFocusHandler}
            onBlur={onInputBlurHandler}
          />
        )}
        <WizardToggleSwitch
          style={styles.parentalSwitch}
          labelStyle={props.embedded && styles.toggleSwitchLabel}
          title={t('settings.parentalControl.parentalControl')}
          message={props.embedded ? t('profileWizard.parentalControlDescription') : undefined}
          messageStyle={props.embedded && styles.parentalMessage}
          value={parentalControl}
          onPress={toggleParentalInformationPopup}
        />
        <InfoPopup
          visible={popupState.type === PopupType.RatingStatus}
          title={t(`settings.${parentalControl ? 'parentalControlActivated' : 'parentalControlDeactivated'}.title`)}
          message={t(`settings.${parentalControl ? 'parentalControlActivated' : 'parentalControlDeactivated'}.message`)}
          onClose={hidePopup}
        />
        {ratingButtons}
        {ratingsState.rating && (
          <ParentalControlRatingSelectionPopup
            visible={popupState.type === PopupType.Ratings}
            title={t('settings.parentalControl.ratingsPopup.title.tv')}
            rating={ratingsState.rating}
            initialRating={ratingsState.initialRating}
            onConfirm={onPCRatingChanged}
            onClose={hidePopup}
          />
        )}
        <WizardButton
          description={t('settings.parentalControl.unratedTvShows')}
          label={props.blockUnratedEvents ? t('settings.parentalControl.requestPin') : t('settings.parentalControl.allow')}
          disabled={!parentalControl}
          onPress={showUnratedTvEventsPopup}
        />
        <ParentalControlUnratedSelectionPopup
          visible={popupState.type === PopupType.UnratedEvents}
          title={t('settings.parentalControl.unratedTvShows')}
          blockUnrated={props.blockUnratedEvents}
          onConfirm={onBlockUnratedEventsChanged}
          onClose={hidePopup}
        />
        <WizardButton
          description={t('settings.parentalControl.unratedMovies')}
          label={props.blockUnratedMovies ? t('settings.parentalControl.requestPin') : t('settings.parentalControl.allow')}
          disabled={!parentalControl}
          onPress={showUnratedMoviePopup}
        />
        <ParentalControlUnratedSelectionPopup
          visible={popupState.type === PopupType.UnratedMovies}
          title={t('settings.parentalControl.unratedMovies')}
          blockUnrated={props.blockUnratedMovies}
          onConfirm={onBlockUnratedMoviesChanged}
          onClose={hidePopup}
        />
        <WizardToggleSwitch
          style={adultContentToggleStyle}
          labelStyle={props.embedded && styles.toggleSwitchLabel}
          title={t('settings.parentalControl.allowAdultContent')}
          value={showAdultContent}
          onPress={toggleShowAdultContent}
          disabled={!parentalControl}
        />
      </View>
    </>
  );
};

export default React.memo(WizardParentalControlRatingsSettings);
