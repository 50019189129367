import {createStyles} from 'common-styles';
import React from 'react';

import {dimensions, isWeb} from 'common/constants';

import {Media} from 'mw/api/Metadata';

import MediaTile from 'components/mediaTiles/MediaTile';
import {NitroxListItemsRequest, NitroxListItemsBatch} from 'components/NitroxList';

import MediaDetailsTemplate, {DetailsBaseProps} from './MediaDetailsTemplate';

const mediaDimensions = {
  width: dimensions.tile.width + dimensions.margins.small * 2, // include two (left and right) margins
  height: dimensions.tile.height + dimensions.margins.small
};

const styles = createStyles({
  leftMediaListContainer: {
    position: 'absolute',
    left: -mediaDimensions.width,
    width: mediaDimensions.width,
    height: mediaDimensions.height,
    paddingRight: 150
  },
  rightMediaListContainer: {
    position: 'absolute',
    left: dimensions.playerMediaDetails.width,
    width: (isWeb ? '60%' : '100%'),
    height: mediaDimensions.height,
    paddingLeft: 67 // keep the padding fixed and adjust only the width of the container
  }
});

const renderMedia = (itemData: Media): React.ReactElement | undefined => {
  return <MediaTile focusable={false} media={itemData} />;
};

type Props = {
  requestData?: (itemsRequest: NitroxListItemsRequest) => Promise<NitroxListItemsBatch<Media>>;
} & DetailsBaseProps;

const MediaDetails: React.FC<Props> = props => {
  return (
    <MediaDetailsTemplate
      {...props}
      renderMedia={renderMedia}
      mediaDimensions={mediaDimensions}
      leftMediaListContainer={styles.leftMediaListContainer}
      rightMediaListContainer={styles.rightMediaListContainer}
    />
  );
};

export default React.memo(MediaDetails);
