import React, {useCallback, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {ErrorType} from 'mw/api/Error';
import {mw} from 'mw/MW';

import ErrorPopup from 'components/ErrorPopup';
import InfoPopup from 'components/InfoPopup';
import PinKeyboard, {PinKeyboardProps} from 'components/pinKeyboard/PinKeyboard';
import {useDisposable} from 'hooks/Hooks';
import {validatePIN} from 'screens/settings/pinValidation';
import SettingsClickableOption from 'screens/settings/SettingsClickableOption';

enum PinKeyboardState {
  Hidden,
  Current,
  New,
  Confirm
}

type PopupState = {
  title: string;
  message: string;
  isError: boolean;
}

const SettingsChangePurchasePIN: React.FC = () => {
  const {t} = useTranslation();
  const [popupState, setPopupState] = useState<PopupState | null>(null);
  const [pinKeyboardState, setPinKeyboardState] = useState(PinKeyboardState.Hidden);
  const [title, setTitle] = useState('');
  const newPin = useRef<string>('');

  const showPopup = useCallback((title: string, message: string, isError: boolean) => setPopupState({title, message, isError}), []);
  const closePopup = useCallback(() => setPopupState(null), []);

  const showKeyboard = useCallback(() => setPinKeyboardState(PinKeyboardState.Current), []);
  const hideKeyboard = useCallback(() => {
    setTitle('');
    newPin.current = '';
    setPinKeyboardState(PinKeyboardState.Hidden);
  }, []);

  const checkPurchasePin = useDisposable((pin: string) => mw.customer.checkPurchasePin(pin));
  const onSubmitCurrent = useCallback(async (pin: string) => {
    try {
      await checkPurchasePin(pin);
      setTitle('');
      setPinKeyboardState(PinKeyboardState.New);
    } catch (error) {
      if (error.type === ErrorType.IncorrectPin) {
        setTitle(t('settings.wrongPin'));
      } else {
        showPopup(t('settings.errors.error'), t('common.unexpectedError'), true);
        hideKeyboard();
      }
    }
  }, [checkPurchasePin, t, showPopup, hideKeyboard]);

  const onSubmitNew = useCallback((pin: string) => {
    try {
      validatePIN(pin);
      newPin.current = pin;
      setPinKeyboardState(PinKeyboardState.Confirm);
    } catch (error) {
      if (error.type === ErrorType.ProfilePINTooShort) {
        showPopup(t('settings.errors.error'), t('settings.errors.profilePinTooShort'), true);
      } else {
        showPopup(t('settings.errors.error'), t('common.unexpectedError'), true);
      }
    }
  }, [t, showPopup]);

  const setPurchasePin = useDisposable((pin: string) => mw.customer.setPurchasePin(pin));
  const onSubmitConfirm = useCallback(async (pin: string) => {
    if (newPin.current === pin) {
      try {
        await setPurchasePin(pin);
        showPopup(t('settings.success'), t('settings.changePurchasePINSuccess'), false);
      } catch (error) {
        showPopup(t('settings.errors.error'), t('common.unexpectedError'), true);
      }
      hideKeyboard();
    } else {
      showPopup(t('settings.errors.confirmationFailed'), t('settings.errors.purchasePINConfirmationFailed'), true);
    }
  }, [hideKeyboard, setPurchasePin, showPopup, t]);

  const pinKeyboardProps = useMemo<PinKeyboardProps>(() => {
    switch (pinKeyboardState) {
      case PinKeyboardState.Current:
        return {
          visible: true,
          title: title || t('settings.enterCurrentPurchasePin'),
          onClose: hideKeyboard,
          onSubmit: onSubmitCurrent
        };
      case PinKeyboardState.New:
        return {
          visible: true,
          title: t('settings.enterNewPurchasePin'),
          onClose: hideKeyboard,
          onSubmit: onSubmitNew
        };
      case PinKeyboardState.Confirm:
        return {
          visible: true,
          title: title || t('settings.confirmNewPurchasePin'),
          onClose: hideKeyboard,
          onSubmit: onSubmitConfirm
        };
      default:
        return {visible: false} as PinKeyboardProps;
    }
  }, [t, pinKeyboardState, title, onSubmitCurrent, onSubmitNew, onSubmitConfirm, hideKeyboard]);

  return (
    <>
      <SettingsClickableOption
        title={t('settings.changePurchasePIN')}
        buttonLabel={t('settings.changePIN')}
        onPress={showKeyboard}
      />
      {/* TODO: CL-4425 */}
      {popupState ? (
        <>
          {popupState.isError && <ErrorPopup error={{message: popupState.message, title: popupState.title}} onClose={closePopup} />}
          {!popupState.isError && <InfoPopup visible title={popupState.title} message={popupState.message} onClose={closePopup} />}
        </>
      ) : (
        <PinKeyboard {...pinKeyboardProps} />
      )}
    </>
  );
};

export default SettingsChangePurchasePIN;
