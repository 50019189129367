import {Log} from 'common/Log';

import {Error, ErrorType} from 'mw/api/Error';
import {PlaybackSessionData} from 'mw/bo-proxy/BOInterface';

const TAG = 'ADR8SessionMapper';

export class SessionMapper {

  public static toPlaybackSessionData(json: any, assetId: string): PlaybackSessionData {
    if (!json.url) {
      Log.error(TAG, 'No playable stream url found', json);
      throw new Error(ErrorType.PlaybackSessionNoAsset);
    }

    return {
      id: assetId,
      assets: [{
        url: json.url,
        ticket: json.ticket,
        // hardocde playback limitations
        playbackLimitations: {
          allowPause: true,
          allowSkipForward: true,
          allowSkipBackward: true,
          allowFastForward: true,
          allowRewind: true
        }
      }]
    };
  }
}
