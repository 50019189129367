import jose from 'node-jose';

export const createUnsignedJWT = (input: object): string => {
  const header = jose.util.base64url.encode(JSON.stringify({
    alg: 'none',
    typ: 'JWT'
  }));
  const payload = jose.util.base64url.encode(JSON.stringify(input));
  const signature = '';
  return [header, payload, signature].join('.');
};
