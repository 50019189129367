import {createStyles} from 'common-styles';
import React, {useCallback, useMemo} from 'react';
import {View} from 'react-native';

import {dimensions, isMobile} from 'common/constants';
import {getLongestSynopsis, getMetadataFromMedia, getMediaSubtypes, getSportTeamsInfo} from 'common/HelperFunctions';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {isTitle, isEvent} from 'mw/api/Metadata';

import {ChannelIcon, ChannelIconType} from 'components/ChannelIcon';
import ExpandableText from 'components/ExpandableText';
import {Icon, IconType} from 'components/Icon';
import NitroxInteractive from 'components/NitroxInteractive';
import NitroxText from 'components/NitroxText';

import {mediaDetailHorizontalMargin} from './MediaDetailUtils';
import {MediaInfoViewComponentProps, useShouldDisplayNotEntitledLabel} from './MediaInfoView.shared';
import {TopMetadataView, BottomMetadataView} from './MetadataView';
import NotEntitledLabel from './NotEntitledLabel';
import ProgressBarView from './ProgressBarView';
import RecordingErrorLabel from './RecordingErrorLabel';
import RecordingLimitations from './RecordingLimitations';

const synopsisNumberOfLines = isMobile ? 11 : 8;
const trailerIconSize = 83;
const staticStyles = createStyles({
  channelIconContainer: {
    height: dimensions.mediaInfoView.channelIconContainer.height,
    paddingHorizontal: mediaDetailHorizontalMargin
  },
  playButtonContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center'
  },
  metadataContainer: {
    alignItems: 'flex-start',
    paddingHorizontal: mediaDetailHorizontalMargin
  },
  synopsis: {
    paddingHorizontal: mediaDetailHorizontalMargin,
    textAlign: 'justify'
  },
  channelIcon: {
    marginBottom: dimensions.margins.medium
  }
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  synopsis: {
    color: colors.mediaDetailsScreen.synopsis.text,
    marginTop: dimensions.margins.xsmall,
    overflow: 'hidden'
  },
  expandableFadeColor: colors.mediaInfoView.background,
  iconTrailerColor: colors.mediaDetailsScreen.icon,
  mediaNameText: {
    color: colors.overlayMediaDetails.text.mobile
  },
  secondSubtitle: {
    color: colors.overlayMediaDetails.text.mobile,
    marginTop: -dimensions.margins.xsmall
  }
}));

const MediaInfoViewPiccolo: React.FunctionComponent<MediaInfoViewComponentProps> = props => {
  const {media, onStartPlayback, playbackProgress} = props;
  const styles = stylesUpdater.getStyles();
  const {episode} = useMemo(() => getMediaSubtypes(media), [media]);
  const title = useMemo(() => episode?.title ? episode.title : media.name, [media, episode]);
  const metadata = useMemo(() => getMetadataFromMedia(media), [media]);
  const synopsis = useMemo(() => getLongestSynopsis(metadata), [metadata]);
  const hasPlayableTrailer = useMemo(() => isTitle(media) && !media.contents.some(content => content.externalLink) && media.isPlayAllowed(), [media]);
  const sportTeams = useMemo(() => getSportTeamsInfo(media), [media]);

  const startPlayback = useCallback(() => {
    isTitle(media) && onStartPlayback?.(media);
  }, [onStartPlayback, media]);

  return (
    <>
      <View style={[staticStyles.channelIconContainer]}>
        <View style={staticStyles.playButtonContainer}>
          {hasPlayableTrailer && (
            <NitroxInteractive
              onPress={startPlayback}
              testID='button_icon_play_trailer'
            >
              <Icon
                type={IconType.Trailer}
                size={trailerIconSize}
                color={styles.iconTrailerColor}
                shadow
              />
            </NitroxInteractive>
          ) //TODO: missing style change on focus
          }
        </View>
        {isEvent(media) && <ChannelIcon style={staticStyles.channelIcon} type={ChannelIconType.EventDetails} channelId={media.channelId} />}
      </View>
      <View style={staticStyles.metadataContainer}>
        <RecordingLimitations {...props.recordingLimitations} />
        <ProgressBarView progress={playbackProgress} />
        <TopMetadataView media={media} />
        <NitroxText testID={'main_title'} textType='title' style={styles.mediaNameText}>
          {title}
        </NitroxText>
        {!!sportTeams && <NitroxText textType='sportTeams' style={styles.secondSubtitle} handleEllipsize>{sportTeams}</NitroxText>}
        <BottomMetadataView metadata={metadata} media={media} />
        {useShouldDisplayNotEntitledLabel(media) && <NotEntitledLabel />}
        {props.recordingErrorCode && <RecordingErrorLabel errorCode={props.recordingErrorCode} />}
      </View>
      <ExpandableText
        textType='body'
        style={[styles.synopsis, staticStyles.synopsis]}
        fadeColor={styles.expandableFadeColor}
        numberOfLines={synopsisNumberOfLines}
        testID='media_description'
      >
        {synopsis}
      </ExpandableText>
    </>
  );
};

export default React.memo(MediaInfoViewPiccolo);
