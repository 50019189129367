import {createStyles} from 'common-styles';
import React from 'react';
import {StyleSheet, View} from 'react-native';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Event, Channel} from 'mw/api/Metadata';
import {RewindDirection} from 'mw/playback/Player';

import FocusParent, {useFocusParent} from 'components/FocusParent';
import PlayerControlsView, {PlayerControlsHandlers, PlayerControlsViewButtonsVisibility} from 'components/player/PlayerControlsView';
import {useEffectOnce} from 'hooks/Hooks';
import {useRewindTooltips} from 'screens/tv/TvScreenHooks';

import Clock from './Clock';
import EventShortDetails from './EventShortDetails.grosso';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    ...StyleSheet.absoluteFillObject,

    justifyContent: 'center',
    alignItems: 'center'
  },
  overlayContainer: {
    backgroundColor: colors.tvScreen.overlay.shade
  },
  clock: {
    position: 'absolute',
    top: '5%',
    right: '4%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    height: 60
  },
  controlsContainer: {
    position: 'absolute',
    alignSelf: 'center',
    width: '100%',
    bottom: 60
  },
  progressBarContainer: {
    alignSelf: 'center',
    width: '80%',
    marginTop: 80
  }
}));

type TrickplayOverlayProps = {
  paused: boolean;
  rewindDirection?: RewindDirection;
  playerControlsHandlers: PlayerControlsHandlers;
  buttonsVisibility: PlayerControlsViewButtonsVisibility;
  event?: Event;
  channel?: Channel;
  progressBar?: React.ReactNode;
  playbackError?: boolean;
}

const TrickplayOverlay: React.FC<TrickplayOverlayProps> = props => {
  const {
    paused,
    rewindDirection,
    event,
    channel,
    playerControlsHandlers,
    buttonsVisibility,
    progressBar,
    playbackError
  } = props;
  const styles = stylesUpdater.getStyles();
  const [onReady, focus] = useFocusParent();

  useEffectOnce(focus, [focus]);

  const {tooltipsProps} = useRewindTooltips();

  return (
    <FocusParent style={styles.container} onReady={onReady}>
      <View testID='trickplay_overlay' style={[styles.container, styles.overlayContainer]}>
        <Clock containerStyle={styles.clock} />
        <EventShortDetails
          event={event}
          channel={channel}
        />
        <View style={styles.controlsContainer}>
          <PlayerControlsView
            zapperMode
            landscape
            paused={paused}
            rewindDirection={rewindDirection}
            handlers={playerControlsHandlers}
            buttonsVisibility={buttonsVisibility}
            tooltipsProps={tooltipsProps}
            playbackError={playbackError}
            isChannelBlocked={!channel?.isAvailableByPolicy}
          />
          <View style={styles.progressBarContainer}>
            {progressBar}
          </View>
        </View>
      </View>
    </FocusParent>
  );
};

export default TrickplayOverlay;
