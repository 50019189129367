import {createStyles} from 'common-styles';
import React, {useCallback} from 'react';
import {View, ListRenderItemInfo, ActivityIndicator} from 'react-native';

import {indexKeyExtractor} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {Media} from 'mw/api/Metadata';

import {useChromecastExtraBottomPadding} from 'components/chromecast/ChromecastExtraBottomPadding';
import {ListShadow, ListShadowPosition} from 'components/ListShadow';
import NitroxFlatList from 'components/NitroxFlatList';
import {useLazyEffect} from 'hooks/Hooks';

import WatchListTile from './WatchListTile';
import {WatchListSubscreenProps} from './WatchListTypes';

const TAG = 'WatchListContentPiccolo';

const styles = createStyles({
  container: {
    width: '100%',
    flex: 1
  },
  shadow: {
    bottom: 0
  },
  activityIndicator: {
    flex: 1,
    alignSelf: 'center'
  }
});

type WatchListProps = Pick<WatchListSubscreenProps, 'watchList' | 'selectable' | 'loading' | 'onSelectMedia' | 'selection' | 'requestNextPage'>;

const WatchList: React.FC<WatchListProps> = ({watchList, requestNextPage, loading, ...props}) => {
  const {onSelectMedia, selectable, selection} = props;

  const renderTile = useCallback((info: ListRenderItemInfo<Media>) => {
    const media = info.item;
    const selected = !!media && selection.isSelected(media);

    return (
      <WatchListTile
        media={media}
        onSelect={(selected: boolean) => onSelectMedia(media, selected)}
        selectable={selectable}
        selected={selected}
      />
    );
  }, [onSelectMedia, selection, selectable]);

  useLazyEffect(() => {
    if (watchList.length === 0) {
      Log.debug(TAG, 'WatchList changed - requesting the first page');
      requestNextPage();
    }
  }, [watchList], [requestNextPage]);

  const onEndReached = useCallback(() => {
    Log.debug(TAG, 'We have reached the end of the list - requesting next page');
    requestNextPage();
  }, [requestNextPage]);

  const chromecastExtraBottomPadding = useChromecastExtraBottomPadding();

  return (
    <View style={styles.container}>
      {loading
        ? <ActivityIndicator size='large' animating style={styles.activityIndicator} />
        : (
          <NitroxFlatList
            style={styles.container}
            contentContainerStyle={chromecastExtraBottomPadding}
            data={watchList}
            renderItem={renderTile}
            keyExtractor={indexKeyExtractor}
            showsVerticalScrollIndicator={false}
            overScrollMode='never'
            onEndReached={onEndReached}
            {...props}
          />
        )
      }
      <ListShadow direction={ListShadowPosition.Bottom} style={styles.shadow} />
    </View>
  );
};

export default WatchList;
