import {isMobile, isBigScreen, isWeb} from 'common/constants';
import {compactMap} from 'common/HelperFunctions';
import {Log} from 'common/Log';
import {getPlatformFeatures} from 'common/utils';

import {Credentials, nxffConfig, Authority, SelectableBO} from 'mw/api/NXFF';
import {BOType} from 'mw/bo-proxy/bo/BOType';
import {boProxy} from 'mw/bo-proxy/BOProxy';
import {createPCPolicy, PCPolicy} from 'mw/common/ParentalControl';
import {ParentalControlRating} from 'mw/common/ParentalControlRating';
import {mw} from 'mw/MW';
import {DateTimeFormat} from 'mw/nxff-config/nxfd-schema';
import {deviceInfo} from 'mw/platform/device-info/DeviceInfo';

import {PictureType} from './Metadata';
import {PinProtectionLevel} from './PinProtection';

const TAG = 'Configuration';

// TODO: Move this to Purchasing module in the future CL-242
export enum PurchaseType {
  Plain = 'Plain',
  Coupons = 'Coupons',
  Vouchers = 'Vouchers'
}

export enum PurchaseFlowType {
  Full = 'Full',
  Message = 'Message',
  Redirect = 'Redirect',
  Disabled = 'Disabled'
}

export enum PurchaseAuthorizationMethod {
  PurchasePin = 'PurchasePin',
  MasterPin = 'MasterPin',
  None = 'None'
}

export class Configuration {
  private _uiLanguage: string | null = null;
  private _supportedPCRating: ParentalControlRating[] | null = null;
  private _supportedPCAuthorities: string[] | null = null;
  private _pcPolicy: PCPolicy | null = null;

  public get pinProtectionLevel(): PinProtectionLevel {
    return boProxy.bo.getPinProtectionLevel();
  }

  public get defaultProfilePin(): string {
    return nxffConfig.getConfig().ProfileSettings.DefaultProfilePin;
  }

  public get defaultUILanguage(): string {
    return nxffConfig.getConfig().ProfileSettings.DefaultUILanguage;
  }

  public get defaultAudioLanguage(): string {
    return nxffConfig.getConfig().ProfileSettings.DefaultAudioLanguage;
  }

  public get defaultSubtitlesLanguage(): string {
    return nxffConfig.getConfig().ProfileSettings.DefaultSubtitlesLanguage;
  }

  public get isPurchasePinSupported(): boolean {
    return boProxy.bo.isPurchasePinSupported();
  }

  public get purchaseAuthorizationMethod(): PurchaseAuthorizationMethod {
    return boProxy.bo.getPurchaseAuthorizationMethod();
  }

  public get purchaseTypes(): PurchaseType[] {
    Log.warn(TAG, 'purchaseTypes is not implemented');
    return [];
  }

  public get selectableBackOffices(): SelectableBO[] {
    return nxffConfig.getSelectableBackOffices();
  }

  public get supportedPCRatings(): ParentalControlRating[] {
    if (!this._supportedPCRating) {
      const pcRatingsObject = nxffConfig.getConfig().ParentalControl.SupportedPCRatings;
      this._supportedPCRating = pcRatingsObject ?
        compactMap(pcRatingsObject, (rating: Authority) => new ParentalControlRating(rating)) :
        [];
    }

    return this._supportedPCRating;
  }

  public get supportedPCAuthorities(): string[] {
    if (!this._supportedPCAuthorities) {
      this._supportedPCAuthorities = mw.configuration.supportedPCRatings.map(pcRating => pcRating.authority);
    }

    return this._supportedPCAuthorities;
  }

  public get backOfficeCode(): string {
    return nxffConfig.getBackOfficeCode();
  }

  public get enabledBOSelection(): boolean {
    return nxffConfig.getConfig().DemoFeatures.EnabledBOSelection;
  }

  public get enabledWatchlist(): boolean {
    return nxffConfig.getConfig().Watchlist.EnabledWatchlist;
  }

  public get defaultCredentials(): Credentials {
    return nxffConfig.getConfig().Environment.DefaultCredentials;
  }

  public get isNPVREnabled(): boolean {
    const quota = mw.pvr.getCachedPVRQuota();
    return nxffConfig.getConfig().PVR.EnabledNPVR && quota.available > 0;
  }

  public get uiLanguages(): string[] {
    const availableUiLanguages = mw.cms.getUILanguages();
    return availableUiLanguages.length ? availableUiLanguages : nxffConfig.getConfig().Languages.AvailableUILanguages;
  }

  public get uiLanguage(): string {
    return this._uiLanguage || nxffConfig.getConfig().ProfileSettings.DefaultUILanguage || this.uiLanguages[0];
  }

  public set uiLanguage(value: string) {
    if (value && this.uiLanguages.includes(value)) {
      this._uiLanguage = value;
    }
  }

  public get audioLanguages(): string[] {
    const availableUILanguages = mw.cms.getUILanguages();
    return availableUILanguages.length ? availableUILanguages : nxffConfig.getConfig().Languages.AvailableContentLanguages;
  }

  public get subtitlesLanguages(): string[] {
    const availableUILanguages = mw.cms.getUILanguages();
    return availableUILanguages.length ? availableUILanguages : nxffConfig.getConfig().Languages.AvailableContentLanguages;
  }

  public get availableDateFormats(): DateTimeFormat[] {
    return [...nxffConfig.getConfig().ProfileSettings.AvailableDateFormats];
  }

  public get availableTimeFormats(): DateTimeFormat[] {
    return [...nxffConfig.getConfig().ProfileSettings.AvailableTimeFormats];
  }

  public getDefaultDateFormat(): string {
    return nxffConfig.getConfig().ProfileSettings.DefaultDateFormat;
  }

  public getDefaultTimeFormat(): string {
    return nxffConfig.getConfig().ProfileSettings.DefaultTimeFormat;
  }

  public get dateFormat(): string {
    return mw.customer.currentProfile?.dateFormat || this.getDefaultDateFormat();
  }

  public get timeFormat(): string {
    return mw.customer.currentProfile?.timeFormat || this.getDefaultTimeFormat();
  }

  public get isTstvEnabled(): boolean {
    return this.isAssetBasedTstvEnabled;
  }

  public get isLauncher(): boolean {
    return deviceInfo.isLauncher();
  }

  public get isDVBCapable(): boolean {
    return deviceInfo.isDVBCapable();
  }

  public get isAssetBasedTstvEnabled(): boolean {
    return true;
  }

  public get areChannelListsSupported(): boolean {
    return boProxy.bo.areChannelListsSupported();
  }

  public get pcPolicy(): PCPolicy {
    if (!this._pcPolicy) {
      this._pcPolicy = createPCPolicy();
    }
    return this._pcPolicy;
  }

  public get playbackSkip(): number {
    return nxffConfig.getConfig().Playback.PlaybackSkip;
  }

  public get playbackRewindSkips(): number[] {
    return nxffConfig.getConfig().Playback.PlaybackRewindSkips;
  }

  public get registrationUseMailAsUsername(): boolean {
    return nxffConfig.getConfig().Registration.RegistrationUseMailAsUsername;
  }

  public get playbackSessionKeepAliveInterval(): number {
    return nxffConfig.getConfig().Playback.PlaybackSessionKeepAliveInterval;
  }

  public get playbackSessionGeoLocationCheckInterval(): number {
    return nxffConfig.getConfig().Playback.PlaybackSessionGeoLocationCheckInterval;
  }

  public get isSearchFiltersEnabled(): boolean {
    if (nxffConfig.getConfig().Environment.BOType === BOType.ADR8) {
      return false;
    }

    return nxffConfig.getConfig().Search.SearchFiltersEnabled;
  }

  public get defaultChannelID(): string {
    return nxffConfig.getConfig().EPG.DefaultChannelID;
  }

  public getPictureTypes(mediaType: string, type: PictureType): string[] {
    const schema = nxffConfig.getConfig().UI.PicturesSelectionSchema;
    const mediaTypeSchema = schema?.[mediaType] as {[k: string]: string[]} | undefined;
    return mediaTypeSchema?.[type] ?? [];
  }

  public get isRefreshingOnNetworkChangeEnabled(): boolean {
    return isMobile && nxffConfig.getConfig().LocationService.EnabledRefreshingOnNetworkChange;
  }

  public get isLoginSessionRenewEnabled(): boolean {
    return isMobile && nxffConfig.getConfig().Environment.SSOType === 'Mega';
  }

  public get isEnabledSharingLogs(): boolean {
    const enabledSharingLogs = nxffConfig.getConfig().Debugging.EnabledSharingLogs;
    return isWeb ? false : enabledSharingLogs;
  }

  public isEPGAvailable(): boolean {
    return boProxy.bo.isEPGAvailable() && nxffConfig.getConfig().EPG.EPGWatchlistAvailable;
  }

  public getAlertPreventClosingTimeout(): number {
    return nxffConfig.getConfig().EAS.AlertPreventClosingTimeout;
  }

  public getAlertAutoCloseTimeout(): number {
    return nxffConfig.getConfig().EAS.AlertAutoCloseTimeout;
  }

  public getSwimlaneContentLimit(): number {
    return isBigScreen ? nxffConfig.getConfig().UI.SwimlaneContentLimit : 0;
  }

  public getPurchaseFlowType(): PurchaseFlowType {
    return getPlatformFeatures()?.Purchase ?? PurchaseFlowType.Disabled;
  }

  public isSportTeamsSupported(): boolean {
    return nxffConfig.getConfig().Environment.BOType === BOType.ADR7;
  }

  public uninitialize(): void {
    //NOTE: Nothing to be done here, no state to mop-up.
    //However, if this module will implement caching, this will be required
  }
}
