import {ErrorType} from 'mw/api/Error';
import {ErrorEvent, PlayerEvent} from 'mw/api/PlayerEvent';
import {PlayerType} from 'mw/playback/types/PlayerType';

import {WebPlaybackEvent} from './WebPlaybackEvent';

export class Error extends WebPlaybackEvent {

  public error: ErrorEvent;
  public constructor(playerType: PlayerType, errorType: ErrorType, streamId: number) {
    super(PlayerEvent.Error, playerType, streamId);

    this.error = {
      error: errorType
    };
  }
}
