import {createStyles} from 'common-styles';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View, StyleSheet, ViewStyle} from 'react-native';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Channel, Event} from 'mw/api/Metadata';

import {ChannelIcon, ChannelIconType} from 'components/ChannelIcon';
import {Icon, IconType} from 'components/Icon';
import NavigationFocusGuard from 'components/NavigationFocusGuard';
import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';
import {PlayerView, PlayerViews} from 'components/player/PlayerView';
import ShortDetails, {getEPGMetadata} from 'components/ShortDetailsView';

const bigScreenPlayerWidth = 480;
const parentalControlIconSize = 120;

const staticStyles = createStyles({
  infoWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  detailsContainer: {
    flexDirection: 'row',
    flexShrink: 1
  },
  channelIcon: {
    width: 120,
    height: 120
  },
  detailsWrapper: {
    marginLeft: dimensions.margins.medium,
    marginRight: dimensions.margins.xxxxLarge,
    flex: 1,
    flexDirection: 'row'
  },
  shortDetails: {
    flex: 1
  }
});

const infoContainerHeight = 370; // Value from inVision
const videoSize = dimensions.videoSize(bigScreenPlayerWidth);

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  infoContainer: {
    height: infoContainerHeight,
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: Math.floor((infoContainerHeight - videoSize.height) / 2),
    backgroundColor: colors.epgScreen.topInfoContainer.background
  },
  blockedPlayerContainer: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: colors.epgScreen.player.blockedBackground,
    alignItems: 'center',
    justifyContent: 'center'
  },
  iconColor: colors.tile.poster.placeholder.icon
}));

type Props = {
  channel: Channel;
  currentEvent?: Event;
  isPlayerBlocked: boolean;
  onPipPlayerViewReady: () => void;
  containerStyle?: ViewStyle;
  playerType: PlayerViews;
};

const EpgBigScreenDetails: React.FunctionComponent<Props> = props => {
  const styles = stylesUpdater.getStyles();
  const {isMediaBlocked} = useParentalControl();
  const {t} = useTranslation();

  return (
    <View style={[styles.infoContainer, props.containerStyle]} testID='details'>
      <View style={staticStyles.infoWrapper}>
        {props.children}
        {/* bar section */}
        <View style={staticStyles.detailsContainer}>
          {props.channel && <ChannelIcon style={staticStyles.channelIcon} type={ChannelIconType.Epg} channelId={props.channel.id} />}
          <View style={staticStyles.detailsWrapper}>
            {props.currentEvent && <ShortDetails style={staticStyles.shortDetails} landscape focusable={false} {...getEPGMetadata(props.currentEvent, t, isMediaBlocked)} titleNumberOfLines={1} />}
          </View>
        </View>
        <View style={videoSize}>
          <NavigationFocusGuard>
            {props.isPlayerBlocked ? (
              <View style={styles.blockedPlayerContainer}>
                <Icon type={IconType.ParentalControl} size={parentalControlIconSize} color={styles.iconColor} />
              </View>
            ) :
              <PlayerView debugName='EpgBigScreenDetails' type={props.playerType} onReady={props.onPipPlayerViewReady} style={StyleSheet.absoluteFillObject} />
            }
          </NavigationFocusGuard>
        </View>
      </View>
    </View>
  );
};

export default EpgBigScreenDetails;
