import React, {useMemo} from 'react';
import {Animated} from 'react-native';

import {epgItemHeight, dimensions} from 'common/constants';

import {Channel} from 'mw/api/Metadata';

import {useLazyEffect} from 'hooks/Hooks';

import AnimatedScrollView from './AnimatedScrollView';
import {useChannelList} from './ChannelListHooks';
import ChannelView, {epgChannelIconWidth} from './ChannelView';

export const channelsMarginLeft = dimensions.margins.xxxLarge;
export const channelsContainerWidth = 190 + channelsMarginLeft; // margin is added to the width because this space should be taken by a channel number text
export const channelNameMarginLeft = channelsContainerWidth - epgChannelIconWidth;

type ChannelListViewProps = {
  channels: Channel[];
  currentChannel?: Channel;
  scrollOffset: Animated.Value;
}

const ChannelListView: React.FC<ChannelListViewProps> = React.memo(({
  channels,
  currentChannel,
  scrollOffset
}) => {
  const channelListSize = useMemo(() => ({
    width: channelsContainerWidth,
    height: channels.length * epgItemHeight
  }), [channels.length]);

  const {
    displayedChannels,
    scrollOffsetChanged
  } = useChannelList({currentChannel, channels});

  useLazyEffect(() => {
    const id = scrollOffset.addListener(({value}) => {
      scrollOffsetChanged({value});
    });
    return () => scrollOffset.removeListener(id);
  }, [scrollOffset], [scrollOffsetChanged]);

  const displayedChannelIcons = useMemo(() => displayedChannels.map(({channel, index, isCurrent}) => (
    <ChannelView
      key={channel.id}
      channel={channel}
      isCurrent={isCurrent}
      index={index}
    />
  )), [displayedChannels]);

  return (
    <AnimatedScrollView
      offsetY={scrollOffset}
      size={channelListSize}
    >
      {displayedChannelIcons}
    </AnimatedScrollView>
  );
});
ChannelListView.displayName = 'ChannelListView';

export default ChannelListView;
