import {Log} from 'common/Log';

import {Error, Errors, ErrorType} from 'mw/api/Error';
import {Track} from 'mw/api/PlayerEvent';
import {NativePlaybackParameters} from 'mw/playback/types/NativePlaybackParameters';
import {PlaybackResponse} from 'mw/playback/types/PlaybackParameters';
import {PlayerType} from 'mw/playback/types/PlayerType';

import {EventHandler} from './EventHandler';
import {PlayerPositionEvent} from './events/PlayerPositionEvent';
import {NitroxPlayer} from './nxpal/NitroxPlayer';
import {NitroxPlayerEventHandler} from './nxpal/NitroxPlayerEventHandler';
import {NitroxPlayerFactory} from './nxpal/NitroxPlayerFactory';
import {PlayerView} from './types';

const TAG = 'WebPlayerModule';

const defaultNotificationFrequency = 1000; // in milliseconds

class PlayerEventsHandler implements NitroxPlayerEventHandler {
  private playerController: WebPlayerModule | null;
  private currentPlayerId: number;
  private firstFrameEmitted = false;

  public constructor(playerController: WebPlayerModule, currentPlayerId: number) {
    this.playerController = playerController;
    this.currentPlayerId = currentPlayerId;
  }

  public isFirstFrameEmitted(): boolean {
    return this.firstFrameEmitted;
  }

  public onStop(): void {
    if (this.playerController) {
      const playerController = this.playerController;
      this.playerController = null;
      playerController.cleanUpPlayer(this.currentPlayerId);
    }
  }

  public onFirstFrame(): void {
    this.firstFrameEmitted = true;
  }

  public onStart(): void {}
  public onBos(): void {}
  public onEos(): void {}
  public onError(): void {}

  public onBuffering(): void {}
  public onReady(): void {}
  public onAudioTracksChanged(): void {}
  public onSubtitleTracksChanged(): void {}
  public onVideoTracksChanged(): void {}
}

class PlayerInfo {
  public id?: number;
  public player: NitroxPlayer | null = null;
  public handler: PlayerEventsHandler | null = null;
  public readonly positionNotify: boolean;
  public positionNotifier: number | null = null;
  public eventHandler: EventHandler | null = null;

  public constructor(positionNotify = false) {
    this.positionNotify = positionNotify;
  }
}

export class WebPlayerModule {
  private static instance: WebPlayerModule;

  public static getInstance() {
    return WebPlayerModule.instance || (WebPlayerModule.instance = new WebPlayerModule());
  }

  private players: Map<PlayerType, PlayerInfo>;
  private currentPlayerId = 0;
  private playerViews: Map<number, PlayerView> = new Map<number, PlayerView>();

  private constructor() {
    this.players = new Map<PlayerType, PlayerInfo>();
    this.players.set(PlayerType.MAIN, new PlayerInfo(true));
    this.players.set(PlayerType.AUDIO, new PlayerInfo());
  }

  public create(playerViewId: number, playerType: PlayerType, parameters: NativePlaybackParameters): NitroxPlayer | null {
    const info = this.players.get(playerType);
    if (!info) {
      Log.error(TAG, 'create: Unsupported player type', playerType);
      return null;
    }

    const playerView = this.playerViews.get(playerViewId);
    if (playerView == null) {
      if (playerType === PlayerType.AUDIO) {
        Log.error(TAG, 'create: On web based platforms it is required to add fake player view');
      } else {
        Log.error(TAG, 'create: Unsupported playerViewId ' + playerViewId);
      }

      return null;
    }
    if (parameters.url == null) {
      Log.error(TAG, 'create: Cannot create player without url!');
      return null;
    }
    if (parameters.contentType == null) {
      Log.error(TAG, 'create: Cannot create player without contentType!');
      return null;
    }

    this.currentPlayerId++;
    Log.debug(TAG, `create: stream id: ${this.currentPlayerId}, url: ${parameters.url}`);
    const player = NitroxPlayerFactory.getInstance().createPlayer(parameters.url, playerView);
    if (!player) {
      Log.error(TAG, 'create: Failed to create player type: ${playerType} viewId: ${parameters.playerViewId}');
      return null;
    }

    info.player = player;
    info.id = this.currentPlayerId;

    info.eventHandler = new EventHandler(this.currentPlayerId, parameters.url, playerType);
    player.addEventHandler(info.eventHandler);

    info.handler = new PlayerEventsHandler(this, this.currentPlayerId);
    player.addEventHandler(info.handler);

    player.setProperties({});

    if (parameters.drmProperties != null) {
      player.getDrmInterceptor()?.setProperties(parameters.drmProperties);
    }
    if (parameters.drmHeaders != null) {
      player.getDrmInterceptor()?.setHeaders(parameters.drmHeaders);
    }

    player.setSource(parameters.url, parameters.contentType);

    Log.info(TAG, 'create: Created player type', playerType);

    return info.player;
  }

  public destroyCurrentStream(playerType: PlayerType): void {
    const info = this.players.get(playerType);
    if (!info) {
      Log.error(TAG, 'destroyCurrentStream: Unsupported player type', playerType);
      return;
    }

    this.unsubscribeFromPlayerPositionNotification(playerType);

    if (!info.player) {
      Log.warn(TAG, 'Player already stopped', playerType);
      return;
    }

    Log.debug(TAG, 'Destroying stream ${info.id} for url: ${info.player.getSource()}');

    const player = info.player;

    info.player = null;
    info.eventHandler = null;
    info.handler = null;
    NitroxPlayerFactory.getInstance().destroyPlayer(player);
  }

  public cleanUpPlayer(playerId: number) {
    this.players.forEach((playerInfo: PlayerInfo, playerType: PlayerType) => {
      if (playerInfo.id === playerId) {
        this.destroyCurrentStream(playerType);
      }
    });
  }

  private subscribeForPlayerPositionNotification(playerType: PlayerType) {
    const info = this.players.get(playerType);
    const isPositionNotificationNeeded = playerType === PlayerType.MAIN;
    if (!isPositionNotificationNeeded) {
      Log.debug(TAG, 'subscribeForPlayerPositionNotification: Position notification is not required for player type: ', playerType);
      return;
    }

    if (!info || !info.positionNotify) {
      Log.error(TAG, 'subscribeForPlayerPositionNotification: Unsupported player type', playerType);
      return;
    }

    Log.debug(TAG, 'subscribeForPlayerPositionNotification');
    if (info.positionNotifier != null) {
      clearInterval(info.positionNotifier);
    }

    info.positionNotifier = setInterval(() => {
      const info = this.players.get(playerType);
      if (!info) {
        Log.error(TAG, 'notifyPosition: unsupported player type', playerType);
        return;
      }

      if (!info.player || !info.handler || !info.id || !info.eventHandler) {
        return;
      }

      if (!info.handler.isFirstFrameEmitted()) {
        Log.debug(TAG, 'notifyPosition: do not notify position before starting playback');
        return;
      }

      const streamPosition = info.player.getStreamPosition();
      if (typeof streamPosition.position === 'undefined') {
        Log.trace(TAG, 'time unset: id: ${info.id}, do not notify position');
        return;
      }

      Log.trace(TAG, 'notifyPosition', info.id, streamPosition);

      info.eventHandler.sendEvent(new PlayerPositionEvent(playerType, info.id, streamPosition));
    }, defaultNotificationFrequency);
  }

  private unsubscribeFromPlayerPositionNotification(playerType: PlayerType): void {
    const info = this.players.get(playerType);
    if (!info || !info.positionNotify) {
      Log.error(TAG, 'unsubscribeFromPlayerPositionNotification: Unsupported player type', playerType);
      return;
    }

    if (info.positionNotifier == null) {
      Log.warn(TAG, 'unsubscribeFromPlayerPositionNotification: Already stopped player type', playerType);
      return;
    }

    Log.debug(TAG, 'unsubscribeFromPlayerPositionNotification');
    clearInterval(info.positionNotifier);
    info.positionNotifier = null;
  }

  private getPlayer(playerType: PlayerType): NitroxPlayer {
    const info = this.players.get(playerType);
    if (!info || !info.player) {
      Log.error(TAG, 'getPlayer: Unsupported player type', playerType);
      throw Errors.NativePlayerPlayerNotFound;
    }

    return info.player;
  }

  public setCurrentAudioTrack(playerType: PlayerType, language: string, type: string, codec?: string): Promise<void> {
    Log.debug(TAG, 'setCurrentAudioTrack: language', language);
    const player = this.getPlayer(playerType);

    const track = player.getAudioTracks().find((availableTrack: Track) => {
      Log.debug(TAG, 'setCurrentAudioTrack: checking', availableTrack);
      return availableTrack.language === language && availableTrack.type === type && (!codec || availableTrack.codec === codec);
    });

    if (!track) {
      Log.error(TAG, 'setCurrentAudioTrack: no such track', language);
      return Promise.reject(new Error(ErrorType.InvalidParameter, 'Audio track not found'));
    }

    if (!player.setAudioTrack(track)) {
      Log.error(TAG, 'setCurrentAudioTrack: unable to change audio track', language);
      return Promise.reject(new Error(ErrorType.UnknownError, 'Unable to change audio track'));
    }

    return Promise.resolve();
  }

  public setCurrentSubtitleTrack(playerType: PlayerType, language: string, type: string): Promise<void> {
    Log.debug(TAG, 'setCurrentSubtitleTrack: language', language);
    const player = this.getPlayer(playerType);

    if (language === 'off') {
      Log.debug(TAG, 'setCurrentSubtitleTrack: disabling subtitles');

      if (!player.setSubtitleTrack()) {
        Log.error(TAG, 'setCurrentSubtitleTrack: unable to disable subtitles');
        return Promise.reject(new Error(ErrorType.UnknownError, 'Unable to disable subtitles'));
      }

      return Promise.resolve();
    }

    const tracks = player.getSubtitleTracks();
    const foundTrack = tracks.find(availableTrack => {
      Log.debug(TAG, 'setCurrentSubtitleTrack: checking', availableTrack);
      return availableTrack.language === language && availableTrack.type === type;
    });

    if (!foundTrack) {
      Log.error(TAG, 'setCurrentSubtitleTrack: no such track', language);
      return Promise.reject(new Error(ErrorType.InvalidParameter, 'Subtitles track not found'));
    }

    if (!player.setSubtitleTrack(foundTrack)) {
      Log.error(TAG, 'setCurrentSubtitleTrack: unable to change subtitles track', language);
      return Promise.reject(new Error(ErrorType.UnknownError, 'Unable to change subtitles track'));
    }

    return Promise.resolve();
  }

  public setVideoResolution(playerType: PlayerType, videoResolution: string): boolean {
    Log.debug(TAG, 'setVideoResolution', videoResolution);
    const player = this.getPlayer(playerType);

    if (videoResolution.toLowerCase() === 'adaptive') {
      Log.debug(TAG, 'setVideoResolution: setting adaptive');
      return player.setVideoTrack();
    }

    const tracks = player.getVideoTracks();
    const found = tracks.find(availableTrack => {
      Log.debug(TAG, 'setVideoResolution: checking', availableTrack);
      return availableTrack === videoResolution;
    });

    if (!found) {
      Log.error(TAG, 'setVideoResolution: no such resolution', videoResolution);
      return false;
    }

    return player.setVideoTrack(found);
  }

  public register(viewId: number, playerView: PlayerView) {
    this.playerViews.set(viewId, playerView);
  }

  public unregister(viewId: number): void {
    this.playerViews.delete(viewId);
  }

  private isDetune(parameters: NativePlaybackParameters): boolean {
    return !parameters.url &&
      typeof parameters.playRate === 'undefined' &&
      typeof parameters.skip === 'undefined' &&
      typeof parameters.position === 'undefined' &&
      typeof parameters.mute === 'undefined' &&
      typeof parameters.hideVideo === 'undefined' &&
      (typeof parameters.drmProperties === 'undefined' || typeof parameters.drmHeaders === 'undefined');
    // TODO: Use following when trickplay limitations will be implemented (e.g. for TekSavvy)
    // typeof parameters.lowestAllowedPosition === 'undefined' &&
    // typeof parameters.highestAllowedPosition === 'undefined';
  }

  private returnSuccess(streamId: number): Promise<PlaybackResponse> {
    return Promise.resolve({streamId});
  }

  private returnFailure(streamId: number, error: Error): Promise<PlaybackResponse> {
    return Promise.reject({streamId, userInfo: error});
  }

  public async setParameters(viewId: number, parameters: NativePlaybackParameters): Promise<PlaybackResponse> {
    Log.debug(TAG, 'setParameters: PlaybackParameters', parameters);

    let streamCreated = false;
    const {playerType, url} = parameters;

    const playerInfo = this.players.get(playerType);
    if (!playerInfo) {
      Log.error(TAG, 'setParameters: Unsupported playerType', playerType);
      return this.returnFailure(this.currentPlayerId, Errors.NativePlayerPlayerNotFound);
    }

    let {player} = playerInfo;

    if (this.isDetune(parameters)) {
      Log.debug(TAG, 'setParameters: Stopping player ${player ? \'url: \' + player.getSource() : \'There is no player, therefore no playback to stop.\'}');
      this.destroyCurrentStream(playerType);
      return this.returnSuccess(this.currentPlayerId);
    }

    if (url) {
      if (player != null) {
        const previousUrl = player.getSource();
        if (url !== previousUrl) {
          Log.debug(TAG, 'setParameters: Switch player from: ${previousUrl} to: ${url}');
          this.destroyCurrentStream(playerType);
          player = null;
        }
      }

      if (!player) {
        Log.debug(TAG, 'setParameters: Starting playback for url', url);
        player = await this.startPlayback(viewId, parameters, playerType);
        if (player == null) {
          Log.error(TAG, 'setParameters: Failed to create a player');
          return this.returnFailure(this.currentPlayerId, Errors.NativePlayerPlayerNotFound);
        }

        streamCreated = true;
        player.setAudioMuted(false);
      }
    }

    playerInfo.player = player;

    if (player == null) {
      Log.warn(TAG, 'setParameters: some parameters provided but we have no player');
      return this.returnFailure(this.currentPlayerId, Errors.NativePlayerPlayerNotFound);
    }

    if (parameters.contentType) {
      Log.debug(TAG, 'setParameters: Applying contentType', parameters.contentType);
      player.setContentType(parameters.contentType);
    }

    if (typeof parameters.playRate !== 'undefined') {
      Log.debug(TAG, 'setParameters: Applying rate', parameters.playRate);
      player.setSpeed(parameters.playRate);
    }

    if (typeof parameters.skip !== 'undefined') {
      const currentPosition = player.getPosition();
      const desiredPosition = currentPosition + parameters.skip;
      Log.debug(TAG, 'setParameters: changing position', parameters.skip, currentPosition, desiredPosition);
      player.setPosition(desiredPosition);
    }

    if (typeof parameters.mute !== 'undefined') {
      Log.debug(TAG, 'setParameters: Setting mute to', parameters.mute);
      player.setAudioMuted(parameters.mute);
    }

    if (!streamCreated) {
      if (typeof parameters.position !== 'undefined') {
        Log.debug(TAG, 'setParameters: Applying start position', parameters.position);
        player.setPosition(parameters.position);
      }

      if (parameters.drmProperties) {
        player.getDrmInterceptor()?.setProperties(parameters.drmProperties);
      }

      if (parameters.drmHeaders) {
        player.getDrmInterceptor()?.setHeaders(parameters.drmHeaders);
      }
    }

    if (streamCreated) {
      this.subscribeForPlayerPositionNotification(playerType);
    }

    return this.returnSuccess(this.currentPlayerId);
  }

  private async startPlayback(playerViewId: number, parameters: NativePlaybackParameters, playerType: PlayerType): Promise<NitroxPlayer | null> {
    const player = this.create(playerViewId, playerType, parameters);
    if (!player) {
      Log.error(TAG, 'startPlayback: Failed to create a player');
      return null;
    }

    const {playRate = 1, position} = parameters;
    player.start(playRate, position);
    return player;
  }

  public switchPlayerView(oldViewId: number, viewId: number, playerType: PlayerType): Promise<PlaybackResponse> {
    Log.debug(TAG, `switchPlayerView notImplemented, viewId: ${viewId}`);
    // todo: CL-1433
    return Promise.resolve(this.returnSuccess(this.currentPlayerId));
  }

  public setPlaybackBoundaries(playerType: PlayerType, lowestPosition: number, highestPosition: number): Promise<PlaybackResponse> {
    try {
      this.getPlayer(playerType).setPlaybackBoundaries(lowestPosition, highestPosition);
    } catch (error) {
      Log.error(TAG, 'setAudioMuted: error:', error);
      return this.returnFailure(this.currentPlayerId, error);
    }

    return this.returnSuccess(this.currentPlayerId);
  }
}
