import {Filter} from 'mw/api/Filter';
import {Picture} from 'mw/api/Metadata';

export enum LinkType {
  MENU = 'menu-slug',
  PAGE = 'page-slug'
}

/**
 * @enum Defines type of CMS menu.
 * @property {string} DEFAULT Standard menu, without specific platform destination, without exceptions
 * @property {string} NATIVE Menu that implements android TV Native feature (like installed apps, system settings)
 * @property {string} LOGIN Login is an exception in menu and should be rendered differently than rest of items
 */
export enum MenuType {
  DEFAULT = 'default',
  NATIVE = 'native',
  LOGIN = 'login',
  PROFILE = 'profile'
}

/**
 * Defines how menu is represented in VOD screen UI
 */
export enum MenuRenderType {
  /**
   * Menu is treated as a subcategory and is represented as a breadcrumb in VOD screen
   */
  Default = 'Default',
  /**
   * Menu is treated as a source of "Promotional Banner" data displayed on top of VOD screen page
   */
  PromotionalBanner = 'PromotionalBanner'
}

/**
 * @enum Dfines alignment of menu optin vertically on bigscreens
 * @property {string} TOP Alignment top.
 * @property {string} CENTER Alignment center. This is default behaviour.
 * @property {string} BOTTOM (deprecated) Used currently only by Roku.
 * @property {string} ICON_AREA (BigScreen only) It is displayed in the right part as an icon. For example: notifications.
 */
export enum MenuAlignment {
  TOP = 'top',
  CENTER = 'center',
  BOTTOM = 'bottom',
  ICON_AREA = 'icon-area'
}

export interface Link {
  type: LinkType;
  slug: string;
  idType?: string;
}

export enum MenuSubcategoriesMode {
  InlineList = 'InlineList',
  Grouped = 'Grouped'
}

type MenuParams = {
  title: string;
  slug: string;
  screen: string;
  pictures?: Picture[];
  items?: Menu[];
  screenParameters?: object;
  filter?: Filter;
  link?: Link;
  position?: MenuAlignment;
  renderType?: MenuRenderType;
  bannerRotation?: number;
  type?: MenuType;
  isDisabled?: boolean;
  primary?: boolean;
  subcategoriesMode?: MenuSubcategoriesMode;
  backgroundColor?: string;
  backgroundGradientPrimaryColor?: string;
  backgroundGradientSecondaryColor?: string;
}

export class Menu {
  public title: string;
  public slug: string;
  public screen: string;
  public pictures: Picture[];
  public items: Menu[]
  public screenParameters: object; // todo TBD
  public position: MenuAlignment;
  public renderType: MenuRenderType;
  public bannerRotation?: number;
  public type: MenuType;
  public isDisabled: boolean;
  public backgroundColor?: string;
  public backgroundGradientPrimaryColor?: string;
  public backgroundGradientSecondaryColor?: string;
  // Defines which screens should be entered after logging to app, if none has the flag set, first screen will be used.
  public primary: boolean;
  public subcategoriesMode?: MenuSubcategoriesMode;

  public filter?: Filter;
  public link?: Link;

  public constructor(params: MenuParams) {
    this.title = params.title;
    this.slug = params.slug;
    this.screen = params.screen;
    this.pictures = params.pictures || [];
    this.items = params.items || [];
    this.screenParameters = params.screenParameters || {};
    this.filter = params.filter;
    this.link = params.link;
    this.position = params.position ?? MenuAlignment.CENTER;
    this.renderType = params.renderType ?? MenuRenderType.Default;
    this.bannerRotation = params.bannerRotation;
    this.type = params.type || MenuType.DEFAULT;
    this.isDisabled = !!params.isDisabled;
    this.primary = !!params.primary;
    this.subcategoriesMode = params.subcategoriesMode ?? MenuSubcategoriesMode.Grouped;
    this.backgroundColor = params.backgroundColor;
    this.backgroundGradientPrimaryColor = params.backgroundGradientPrimaryColor;
    this.backgroundGradientSecondaryColor = params.backgroundGradientSecondaryColor;
  }

  public getType(): string {
    return 'Menu';
  }

  public get promotionalBanner(): Menu | undefined {
    return this.items.find(item => item.renderType === MenuRenderType.PromotionalBanner);
  }

  public static empty = new Menu({title: '', slug: '', screen: ''});
}
