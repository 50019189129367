import {createStyles} from 'common-styles';
import React, {forwardRef, useImperativeHandle, useCallback, useRef, Ref, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {View, ViewStyle, StyleProp} from 'react-native';

import {isMobile, isBigScreen, dimensions} from 'common/constants';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Link, Menu} from 'mw/cms/Menu';

import {Gradient} from 'brand/ColorTypes';
import FocusParent, {useFocusParent} from 'components/FocusParent';
import NitroxButton from 'components/NitroxButton';
import {Hotspot} from 'components/uxm-components/UMXComponentStack.shared';
import CategorySelector from 'components/vod/CategorySelector';
import {PostProcessors} from 'locales/i18nPostProcessors';
import {vodCategoryScreenMargin} from 'screens/VodScreen.shared';

import CategoryNavigatorHeader from './VodCategoryNavigatorHeader';
import VodSubCategoryButton from './VodSubCategoryButton.piccolo';

const TAG = 'VodCategoryNavigator';

const staticStyles = createStyles({
  container: {
    width: '100%'
  },
  categorySelectorContainer: {
    marginTop: dimensions.margins.xxLarge,
    marginBottom: 0
  },
  categorySelector: {
    marginBottom: dimensions.margins.large,
    height: dimensions.buttons.standard
  },
  redeemButton: {
    height: dimensions.buttons.standard,
    position: 'absolute',
    right: vodCategoryScreenMargin
  }
});

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  containerColor: {
    backgroundColor: isBigScreen ? colors.vodScreen.navigator.background.default : colors.vodScreen.navigator.background.mobile
  }
}));

export type VodCategory = {
  parent?: VodCategory;
  children: VodCategory[];
  inlineSubcategories: boolean;
  label: string;
  slug?: Link;
  page?: Link;
  banner?: Menu | Hotspot;
  backgroundImageUrl?: string;
  backgroundImageColor?: string;
  backgroundGradient?: Gradient;
}

export function getCategoryPath(category?: VodCategory): VodCategory[] {
  const path: VodCategory[] = [];
  for (let current = category; current; current = current.parent) {
    path.unshift(current);
  }
  return path;
}

export function formatCategoryPath(category?: VodCategory): string {
  return getCategoryPath(category)
    .map(part => part.label)
    .join('/');
}

export type VodCategoryNavigatorProps = {
  category?: VodCategory;
  onBackPress: () => void;
  onSubCategoryPress: (subCategory: VodCategory) => void;
  style?: StyleProp<ViewStyle>;
  inlineList?: boolean;
  shouldDisplayRedeemButton?: boolean;
  onRedeemButtonPress?: () => void;
  onFocusEnter?: () => void;
}

export interface VodCategoryNavigatorInterface extends VodCategoryNavigatorProps {
  focus: () => void;
}

const VodCategoryNavigator: React.FC<VodCategoryNavigatorProps> = ({
  category,
  onSubCategoryPress,
  onBackPress,
  style,
  inlineList = false,
  shouldDisplayRedeemButton,
  onRedeemButtonPress,
  onFocusEnter
}, ref: Ref<VodCategoryNavigatorInterface>) => {
  const dynamicStyles = dynamicStylesUpdater.getStyles();
  const [onCategorySelectorReady, focusCategorySelector] = useFocusParent();
  const timeoutIdRef = useRef<number>();
  const {t} = useTranslation();

  useEffect(() => () => clearTimeout(timeoutIdRef.current), []);

  // eslint-disable-next-line schange-rules/no-use-imperative-handle-hook
  useImperativeHandle(ref, () => ({
    focus: focusCategorySelector
  } as VodCategoryNavigatorInterface));

  const onSelectorPressed = useCallback((value: VodCategory | string | number | undefined) => {
    const selectedSubCategory = typeof value === 'object' ? value : category?.children.find(item => item.label === value);
    if (selectedSubCategory) {
      onSubCategoryPress(selectedSubCategory);
    } else {
      Log.error(TAG, 'onSelectorPressed: no subCategories available');
    }
  }, [category, onSubCategoryPress]);

  const path = getCategoryPath(category);
  const inRoot = path.length <= 1;

  const mobileCategorySelector = !!category?.children.length && (
    <View style={staticStyles.categorySelector}>
      {inlineList && (
        <CategorySelector
          categories={category.children}
          onPress={onSelectorPressed}
        />
      )}
      {!inlineList && (
        <VodSubCategoryButton
          category={category}
          onSelectorPressed={onSelectorPressed}
        />
      )}
    </View>
  );

  return (
    <FocusParent
      enterStrategy='byPriority'
      style={[staticStyles.container, dynamicStyles.containerColor, style]}
      onFocusEnter={onFocusEnter}
    >
      {shouldDisplayRedeemButton && (
        <NitroxButton
          border
          onPress={onRedeemButtonPress}
          style={staticStyles.redeemButton}
          text={t('voucherRedemption.titles.redeem', {postProcess: PostProcessors.ToUpperCase})}
        />
      )}
      <View>
        {category && (
          <CategoryNavigatorHeader
            title={category.label}
            previousTitle={category.parent?.label}
            inRoot={inRoot}
            onBackPress={onBackPress}
          />
        )}
        {isMobile && mobileCategorySelector}
      </View>
      {isBigScreen && !!category?.children.length && (
        <FocusParent
          style={staticStyles.categorySelectorContainer}
          holdFocus
          onReady={onCategorySelectorReady}
          debugName='VodCategoryNavigator'
          focusPriority={1}
          enterStrategy='topLeft'
        >
          <CategorySelector buttonsActiveOpacity={1} categories={category.children} onPress={onSelectorPressed} />
        </FocusParent>
      )}
    </FocusParent>
  );
};

export default forwardRef(VodCategoryNavigator);
