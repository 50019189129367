import dot from 'dot-object';

import {Log} from 'common/Log';

import {Error, ErrorType} from 'mw/api/Error';
import {Consent, Images, Styling} from 'mw/api/Metadata';
import {nxffConfig} from 'mw/api/NXFF';
import {ADR8Requester} from 'mw/bo-proxy/bo/adr8/ADR8Requester';
import {CategoryMapper} from 'mw/bo-proxy/bo/adr8/mappers/CategoryMapper';
import {CMSMapper} from 'mw/bo-proxy/bo/cms/CMSMapper';
import {UXMInterface} from 'mw/bo-proxy/UXMInterface';
import {Menu, Link} from 'mw/cms/Menu';
import {Page} from 'mw/cms/Page';
import {NxfdSections} from 'mw/common/NXFFTypes';
import {mw} from 'mw/MW';

import {NxfdMapper} from './NxfdMapper';
import {pageFromJson} from './PageMapper';

const TAG = 'UXManager';
const UXM_API = 'cms';
const MAIN_MENU_DEPTH = 1;

interface StylingImageResponse {
  role: string;
  url: string;
}

interface TranslationsResponse {
  [key: string]: {
    [x: string]: string;
  };
}
export interface Translations {
  [key: string]: void;
}

export class UXManager implements UXMInterface {
  private requester: ADR8Requester;
  public static defaultAlias = 'VodBackOfficeId';

  public constructor(requester: ADR8Requester) {
    this.requester = requester;
  }

  private extractImages(images: any): Images | null {
    if (!Array.isArray(images)) {
      return null;
    }

    const extractedImages: Images = images.reduce((extractedImages: Images, image: StylingImageResponse) => {
      extractedImages[image.role] = image.url;
      return extractedImages;
    }, {});

    return extractedImages;
  }

  private extractLanguages(languages: any[]): string[] {
    return languages.map(language => language.code);
  }

  private extractTranslations(data: TranslationsResponse): Translations {
    return Object.entries(data).reduce(
      (translations, [key, value]) => ({
        ...translations,
        [key]: dot.object(value)
      }),
      {} as Translations
    );
  }

  private get uiLanguage() {
    return mw.configuration.uiLanguage;
  }

  public getStyling(name: string, version: string): Promise<Styling> {
    Log.debug(TAG, `getStyling name: ${name}, version: ${version}`);
    return this.requester.sendGetRequest({
      api: UXM_API,
      query: `brands/${name}/${version}`
    })
      .then(json => {
        if (!json.colorSchema) {
          throw new Error(ErrorType.BOBadResponse);
        }
        return {
          ...json,
          images: this.extractImages(json.images),
          styling: dot.object(json.colorSchema)
        };
      })
      .catch((error: Error) => {
        switch (error?.type) {
          case ErrorType.HttpNotFound:
            throw new Error(ErrorType.CMSStylingNotFound, error.message);

          default:
            throw error;
        }
      });
  }

  public getConsentData(name: string): Promise<Consent> {
    return this.requester.sendGetRequest({
      api: UXM_API,
      query: `consent/${name}`
    })
      .then(json => {
        if (!json.consent || !json.version || !json.name) {
          throw new Error(ErrorType.BOBadResponse);
        }
        return json;
      });
  }

  public getDefaultMainMenu(): Promise<Menu> {
    return Promise.resolve(CMSMapper.getMainMenu());
  }

  public getMenu(id: string, depth: number): Promise<Menu> {
    Log.debug(TAG, `getMenu id: ${id}, depth: ${depth}`);
    return this.requester
      .sendGetRequest({
        api: UXM_API,
        query: `menu/${id}`,
        queryParams: {language: this.uiLanguage, depth}
      })
      .then(json => CategoryMapper.menuFromJson(json, UXManager.defaultAlias));
  }

  public getMainMenu(): Promise<Menu> {
    Log.debug(TAG, `getMainMenu`);
    const mainMenuSlug = nxffConfig.getConfig().UI.MainMenuSlug;
    if (!mainMenuSlug) {
      return this.getDefaultMainMenu();
    }
    return this.getMenu(mainMenuSlug, MAIN_MENU_DEPTH)
      .catch(() => {
        Log.error(TAG, 'Call to UXM instances failed, fallback to defaultMainMenu.');
        return this.getDefaultMainMenu();
      });
  }

  public getPage(link: Link): Promise<Page> {
    return this.requester.sendGetRequest({
      api: 'cms',
      query: `pages/${link.slug}`,
      queryParams: {language: this.uiLanguage}
    })
      .then(pageFromJson)
      .then(page =>
        page ?? Promise.reject(
          new Error(ErrorType.ParsingError, 'Could not parse page ' + link.slug)
        )
      );
  }

  public getAvailableUILanguages(): Promise<string[]> {
    Log.debug(TAG, 'getAvailableUILanguages');
    return this.requester.sendGetRequest({
      api: 'cms',
      query: `languages/v1`
    })
      .then(json => {
        return this.extractLanguages(json);
      })
      .catch((error: Error) => {
        switch (error?.type) {
          case ErrorType.HttpNotFound:
            throw new Error(ErrorType.UILanguagesNotFound, error.message);
          default:
            throw error;
        }
      });
  }

  public getDefaultUILanguage(): Promise<string> {
    Log.debug(TAG, 'getDefaultUILanguage');
    return this.requester.sendGetRequest({
      api: 'cms',
      query: `languages/v1/default`
    })
      .then(json => {
        return json.language;
      })
      .catch((error: Error) => {
        switch (error?.type) {
          case ErrorType.HttpNotFound:
            throw new Error(ErrorType.DefaultUILanguageNotFound, error.message);
          default:
            throw error;
        }
      });
  }

  public getTranslations(version: string): Promise<Translations> {
    Log.debug(TAG, 'getTranslations');
    return this.requester.sendGetRequest({
      api: 'cms',
      query: `public/translation/${version}`
    })
      .then(json => {
        return this.extractTranslations(json);
      })
      .catch((error: Error) => {
        switch (error?.type) {
          case ErrorType.HttpNotFound:
            throw new Error(ErrorType.TranslationsNotFound, error.message);
          default:
            throw error;
        }
      });
  }

  public getConfig(schemaVersion: number): Promise<NxfdSections> {
    Log.debug(TAG, 'getConfig');
    return this.requester.sendGetRequest({
      api: UXM_API,
      query: `clientconfiguration/versions/${schemaVersion}`
    })
      .then(NxfdMapper.nxfdSectionsFromJson)
      .catch((error: Error) => {
        switch (error?.type) {
          case ErrorType.HttpNotFound:
            throw new Error(ErrorType.RemoteNxfdNotFound, error.message || 'Lack of suitable configuration in configuration service.');
          default:
            throw new Error(ErrorType.RemoteNxfdUnknownError, error.message || 'The configuration service is not available right now.');
        }
      });
  }
}
