import {createStyles} from 'common-styles';
import React, {useMemo, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {isBigScreen, dimensions} from 'common/constants';
import {openMediaDetails} from 'common/HelperFunctions';

import {Event, Media, TitleType, isTitle, isEvent} from 'mw/api/Metadata';

import EventSelector from 'components/EventSelector';
import {IconType} from 'components/Icon';
import MediaTile, {mediaTileMarginHorizontal, mediaTileMarginTop} from 'components/mediaTiles/MediaTile';
import {MediaTileConfig, useMediaInfoSection} from 'components/mediaTiles/MediaTileHooks';
import MobileMediaTileWide from 'components/mediaTiles/MobileMediaTileWide';
import NitroxTag, {Tags} from 'components/NitroxTag';
import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';
import {useNavigation} from 'hooks/Hooks';

export const watchListTileMarginHorizontal = isBigScreen ? mediaTileMarginHorizontal : 0;
export const watchListTileMarginTop = isBigScreen ? mediaTileMarginTop : 0;

const staticStyles = createStyles({
  secondRowContainer: {
    flexDirection: 'row'
  },
  firstRowContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  }
});

type WatchListTileProps = {
  media: Media;
  selectable: boolean;
  selected: boolean;
  onSelect: (selected: boolean) => void;
  onFocus?: () => void;
};

const excludeIcons = [IconType.Check];

const WatchListTileGrosso: React.FC<WatchListTileProps> = props => {
  const {media, selectable, selected, onSelect} = props;
  const {t} = useTranslation();
  const mediaInfoSection = useMediaInfoSection(media, t);

  const tileConfig: MediaTileConfig = useMemo(() => ({
    posterMedia: media,
    title: media.name,
    infoSection: mediaInfoSection,
    hideProgress: true
  }), [media, mediaInfoSection]);

  return (
    <MediaTile
      excludeIcons={excludeIcons}
      media={props.media}
      onFocus={props.onFocus}
      config={tileConfig}
      checkboxVisible={selectable}
      checkboxChecked={selected}
      onCheckboxToggle={onSelect}
    />
  );
};

const WatchListTilePiccolo: React.FC<WatchListTileProps> = props => {
  const {media, selectable, selected, onSelect} = props;
  const navigation = useNavigation();
  const {t} = useTranslation();
  const [eventsForSelection, setEventsForSelection] = useState<Event[]>([]);
  const {isMediaBlocked, shouldBeCheckedForPC} = useParentalControl();
  const isBlocked = shouldBeCheckedForPC(media) && isMediaBlocked(media);
  const mediaInfoSection = useMediaInfoSection(media, t);

  const onPress = useCallback(() => {
    if (isTitle(media) && media.type === TitleType.EPG) {
      const firstEvent = media.events[0];
      if (media.events.length > 1) {
        setEventsForSelection(media.events);
      } else if (isEvent(firstEvent)) {
        openMediaDetails(navigation, firstEvent.id, firstEvent.getType());
      }
    } else {
      openMediaDetails(navigation, media.id, media.getType());
    }
  }, [media, navigation]);

  const onEventSelectionClose = useCallback(() => {
    setEventsForSelection([]);
  }, []);
  const onEventSelected = useCallback((event: Event) => {
    openMediaDetails(navigation, event.id, event.getType());
  }, [navigation]);

  const tileConfig: MediaTileConfig = useMemo(() => ({
    posterMedia: media,
    title: media.name,
    infoSection: mediaInfoSection,
    hideProgress: true
  }), [media, mediaInfoSection]);

  const renderFirstRow = useCallback(() => {
    const event = isEvent(media) ? media : undefined;
    return (
      <View style={staticStyles.firstRowContainer}>
        {event?.isNow && <NitroxTag tag={Tags.liveTag} style={{marginRight: dimensions.margins.small}} />}
      </View>
    );
  }, [media]);

  return (
    <>
      <MobileMediaTileWide
        media={media}
        onPress={onPress}
        isBlocked={isBlocked}
        config={tileConfig}
        firstRowItemsRenderer={renderFirstRow}
        selectable={selectable}
        selected={selected}
        onSelectionCheckboxPress={onSelect}
      />
      {!!eventsForSelection.length && (
        <EventSelector
          events={eventsForSelection}
          onClose={onEventSelectionClose}
          onSelect={onEventSelected}
        />
      )}
    </>
  );
};

const WatchListTile = isBigScreen ? WatchListTileGrosso : WatchListTilePiccolo;
export default WatchListTile;
