import {makeArray} from 'common/utils';

import {EntitlementState, Title, TitleType, Event} from 'mw/api/Metadata';
import {TitleObject} from 'mw/metadata/TitleObject';

import {ContentMapper} from './ContentMapper';
import {EventMapper} from './EventMapper';
import {MetadataMapper} from './MetadataMapper';
import {PictureMapper} from './PictureMapper';
import {RatingsMapper} from './RatingsMapper';

export class TitleMapper {
  public static fromJSON(json: any, type: TitleType, seasonJson?: any, assignTitleToEvents = false): Title {
    const metadata = MetadataMapper.fromJSON(json);
    const title = new TitleObject(json.id, json.Name, type, metadata);
    if (json.Contents && json.Contents.Content && json.Contents.Content.length) {
      title.contents = ContentMapper.fromJSONArray(json.Contents.Content);
      if (title.contents && title.contents.length > 0) {
        metadata.duration = title.contents[0].duration;
      }
    }

    title.entitlementState = title.contents.some(content => content.entitlementState === EntitlementState.Entitled) ? EntitlementState.Entitled : EntitlementState.NotEntitled;

    title.pictures = PictureMapper.toPictures(TitleMapper.findPictures(json));
    if (typeof json.PersonalBookmark !== 'undefined') {
      title.bookmark = json.PersonalBookmark;
    } else if (typeof json.Bookmark !== 'undefined') {
      title.bookmark = json.Bookmark;
    }
    if (typeof json.IsPersonallyViewedCompletely !== 'undefined') {
      title.isViewedCompletely = json.IsPersonallyViewedCompletely;
    } else if (typeof json.IsViewedCompletely !== 'undefined') {
      title.isViewedCompletely = json.IsViewedCompletely;
    }

    const seasonJSON = seasonJson || json?.SeriesCollection?.Series?.[0];
    const seriesJSON = seasonJSON?.ParentSeriesCollection?.Series?.[0];

    const seasonNumber = json?.OriginalSeasonNumber || seriesJSON?.RelationOrdinal || seasonJSON?.Ordinal;
    const episodeNumber = json?.OriginalEpisodeNumber || seasonJSON?.RelationOrdinal || json?.Ordinal;

    if (seriesJSON && seasonJSON) {
      title.episode = {
        seriesId: seriesJSON.id,
        seasonId: seasonJSON.id,
        title: json.EpisodeName || '',
        number: episodeNumber,
        seriesName: seriesJSON?.Name || '',
        seasonName: seasonJSON?.Name || '',
        seasonNumber: seasonNumber,
        firstEpisodeOfSeason: episodeNumber === 1
      };
    } else if (json.EpisodeName) {
      // Way to keep information about the playing teams in sport events
      title.episode = {
        seriesId: '',
        seasonId: '',
        title: json.EpisodeName
      };
    }

    title.pcRatings = RatingsMapper.fromJSON(json.Ratings);

    title.isTrailer = json.IsPreview;
    if (json.Previews && json.Previews.Title) {
      title.trailers = json.Previews.Title.map((json: any) => TitleMapper.fromJSON(json, TitleType.VOD));
      title.trailers.forEach(trailer => trailer.parentId = title.id);
    }
    title.isAdult = !!json.IsAdult;

    if (json.Events && json.Events.Event && json.Events.Event.length) {
      title.events = EventMapper.fromJSONArray(json.Events.Event, assignTitleToEvents ? title : undefined);
    }

    return title;
  }

  private static findPictures(json: any): any {
    const titlePicturesJson = json.Pictures && json.Pictures.Picture;
    if (titlePicturesJson && titlePicturesJson.length > 0) {
      return titlePicturesJson;
    }
    const serieJson = json.SeriesCollection && json.SeriesCollection.Series && json.SeriesCollection.Series[0];
    const seriePicturesJson = serieJson && serieJson.Pictures && serieJson.Pictures.Picture;
    if (seriePicturesJson && seriePicturesJson.length > 0) {
      return seriePicturesJson;
    }
    return [];
  }

  public static toTitles(jsonArray: any[]): Title[] {
    return jsonArray.map((obj: any) => this.fromJSON(obj, TitleType.VOD));
  }

  public static eventsOrTitlesFromJsonArray(jsonArray: any[]): (Event | Title)[] {
    return makeArray(jsonArray)
      .map((titleJson: any) => TitleMapper.fromJSON(titleJson, titleJson?.Events?.Event ? TitleType.EPG : TitleType.VOD))
      .map((title: Title) => title?.events?.length ? EventMapper.fromTitle(title) : title);
  }
}
