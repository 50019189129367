import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ViewStyle, StyleProp} from 'react-native';

import {isBigScreen, isMobile, isTablet} from 'common/constants';

import {Event} from 'mw/api/Metadata';

import {IconType} from 'components/Icon';
import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';
import SelectionPopup from 'components/SelectionPopup';
import {prepareEventIcons} from 'components/TileIconsRow';

import EpgDetailsPopup from './EpgDetailsPopup';
import {EpgMenuAction, formatEventInfo} from './EpgMenuPopup.shared';

type Props = {
  event: Event;
  actions: EpgMenuAction[];
  visible?: boolean;
  loading?: boolean;
  style?: ViewStyle;
  activityIndicatorStyle?: StyleProp<ViewStyle>;
  onClose?: () => void;
}

const EpgMenuPopup: React.FunctionComponent<Props> = props => {
  const {onClose, actions, event, loading, visible, activityIndicatorStyle} = props;
  const {t} = useTranslation();
  const {isMediaBlocked} = useParentalControl();
  const isBlocked = isMediaBlocked(event);
  const sections = useMemo(() => {
    return [{
      options: actions.map(action => ({...action, icon: action.icon && {type: action.icon}})),
      separator: true
    }];
  }, [actions]);

  const mediaIcons = useMemo(() => {
    return prepareEventIcons(event, {
      includeAll: true,
      excludeIcons: [IconType.Check],
      isBlocked
    });
  }, [event, isBlocked]);

  if (isTablet || isBigScreen) {
    return (
      <EpgDetailsPopup event={event} onModalClose={onClose} actions={actions} loading={loading} visible={visible} />
    );
  } else {
    return (
      <SelectionPopup
        sections={sections}
        title={isBigScreen ? t('epg.options') : event.name}
        info={isMobile ? formatEventInfo(event) : undefined}
        mediaIcons={mediaIcons}
        onClose={onClose}
        visible={visible}
        loading={loading}
        style={props.style}
        activityIndicatorStyle={activityIndicatorStyle}
        // web focus manager does not work well with 2 columns, e.g. focus goes down on right click
        columnCount={3}
      />
    );
  }
};

export default React.memo(EpgMenuPopup);
