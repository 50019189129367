import React, {useCallback, useState, Ref, useImperativeHandle, forwardRef, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ViewStyle, Insets, View} from 'react-native';

import {disposable} from 'common/Async';
import {dimensions, isDesktopBrowser, isSTBBrowser, isMobile, defaultPageSize, AppRoutes} from 'common/constants';
import {doNothing} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {Media} from 'mw/api/Metadata';
import {Component as CMSComponent, isDataSourceFilterBased} from 'mw/cms/Component';
import {Link, LinkType} from 'mw/cms/Menu';
import {SpecialFilter} from 'mw/common/ContentCache';
import {mw} from 'mw/MW';
import {MetaEventsEmitter} from 'mw/utils/MetaEventsEmitter';

import MediaTile, {AnimatedTileInterface} from 'components/mediaTiles/MediaTile';
import StaticTile from 'components/mediaTiles/StaticTile';
import {SwimlaneTileProps, AnimatedTileProps} from 'components/Swimlane';
import {NamedAction, VisibilityLimit, createLimitedDataFetcher} from 'components/utils/SwimlaneVisibilityLimit';
import {useFunction, useLazyRef, useEffectOnce, useNavigation} from 'hooks/Hooks';

import {AnimatedSwimlaneProps} from './AnimatedSwimlane';
import AnimatedSwimlaneStackBase, {SetupSwimlaneType, SwimlaneComponent} from './AnimatedSwimlaneStackBase';
import {useMediaTileFocusedStyle} from './mediaTiles/MediaTileBase';
import {SwimlaneStackInterface} from './SwimlaneInterfaces';

const TAG = 'AnimatedSwimlaneStack';

export const swimlaneItemWidth = dimensions.tile.width + 2 * dimensions.margins.small;
export const swimlaneItemHeight = dimensions.tile.height + dimensions.margins.small;
export const defaultSwimlaneInsets = {
  top: dimensions.margins.medium,
  right: 0,
  bottom: isMobile ? dimensions.margins.medium : dimensions.margins.xLarge,
  left: dimensions.margins.small
};

type TileProps = SwimlaneTileProps<Media> & {
  focused: boolean;
  onPress?: (index: number, media: Media) => void;
  onMount?: () => void;
};

const Tile = React.memo(forwardRef((props: TileProps, ref: Ref<AnimatedTileInterface>) => {
  const {onFocus: propagateFocus, onPress: propagatePress, index, data, onMount, focused} = props;
  const onPress = propagatePress ? () => propagatePress(index, data) : void 0;

  useEffectOnce(() => onMount?.(), [onMount]);

  return (
    <MediaTile
      ref={ref}
      media={props.data}
      onFocus={propagateFocus}
      scrollOnFocus={false}
      onPress={onPress}
      staticallyFocused={focused}
    />
  );
}));
Tile.displayName = 'Tile';

export function useDefaultMediaSwimlaneTiles(
  config?: Pick<TileProps, 'onPress'> & {onFirstTileMount?: () => void}
): Pick<AnimatedSwimlaneProps<Media>, 'createTile' | 'placeholderComponent'> {
  const {onPress: onTilePress, onFirstTileMount} = config ?? {};
  const createTile = useCallback((tileProps: SwimlaneTileProps<Media>, animatedTileProps?: AnimatedTileProps) => {
    const onMount = (tileProps.index === 0 && tileProps.row === 0) ? onFirstTileMount : undefined;
    // eslint-disable-next-line react/display-name
    return (focused: boolean) => (
      <Tile
        ref={animatedTileProps?.refHandler}
        onMount={onMount}
        {...tileProps}
        key={tileProps.data.id}
        onPress={onTilePress}
        focused={focused}
      />
    );
  }, [onTilePress, onFirstTileMount]);

  const placeholderComponent = useCallback((row: number, focused?: boolean, animatedTileProps?: AnimatedTileProps) => {
    return (
      <StaticTile
        onPress={doNothing}
        empty
        staticallyFocused={focused}
      />
    );
  }, []);

  return {createTile, placeholderComponent};
}

export function useCreateDefaultVisibilityLimits(): (component: CMSComponent) => VisibilityLimit<CMSComponent>[] {
  const {t} = useTranslation();
  const navigation = useNavigation();

  return useCallback((component: CMSComponent) => {
    const isWatchList = isDataSourceFilterBased(component.dataSource)
      && component.dataSource.filters[0].value === SpecialFilter.WatchList;
    const commonProps = {
      name: 'showAll',
      label: t('common.showAll')
    };
    return [{
      ...commonProps,
      shouldApply: () => !isWatchList,
      onPress: () => navigation.navigate(AppRoutes.MediaGrid, {component})
    }, {
      ...commonProps,
      shouldApply: () => isWatchList,
      onPress: () => navigation.navigate(AppRoutes.WatchList)
    }];
  }, [t, navigation]);
}

export function useDefaultVisibilityLimits(component: CMSComponent): VisibilityLimit<CMSComponent>[] {
  const createLimits = useCreateDefaultVisibilityLimits();
  return useMemo(
    () => createLimits(component),
    [createLimits, component]
  );
}

type Props = {
  page?: Link;
  onTileFocus?: (tileIndex: number, row: number, data: Media, options: FocusOptions | undefined, wrapAroundActive: () => boolean) => void;
  onTilePress?: (tileIndex: number, data: Media) => void;
  fixedFocusPosition?: boolean;
  topInset?: number;
  headerInsetLeft?: number;
  wrapAround?: boolean;
  showHeaderActions?: boolean;
  style?: ViewStyle;
  swimlaneInsets?: Insets;
  onFirstTileMount?: () => void;
  createVisibilityLimits?: (component: CMSComponent) => VisibilityLimit<CMSComponent>[];
  /**
   * Executed when all currently visible swimlanes have their data. The number of visible swimlanes depends of stack's vertical position.
   */
  onReady?: () => void;
  createStaticHeaderActions?: (component: CMSComponent) => NamedAction[];
}

const AnimatedSwimlaneStack: React.FC<Props> = (props: Props, ref: Ref<SwimlaneStackInterface>) => {
  const metaEventsEmitter = useLazyRef(() => new MetaEventsEmitter());
  useEffectOnce(() => {
    metaEventsEmitter.current.addListeners();
    return () => metaEventsEmitter.current.removeListeners();
  }, [metaEventsEmitter]);
  const {
    createVisibilityLimits,
    createStaticHeaderActions,
    swimlaneInsets: propsSwimlaneInsets,
    onTileFocus,
    onFirstTileMount,
    onTilePress,
    fixedFocusPosition,
    wrapAround,
    showHeaderActions = true,
    topInset = 0,
    headerInsetLeft = dimensions.margins.small,
    onReady
  } = props;
  const createDefaultLimits = useCreateDefaultVisibilityLimits();
  const createLimits = createVisibilityLimits ?? createDefaultLimits;

  const createDataFetcher = useFunction((component: CMSComponent): AsyncIterableIterator<Media[]> => {
    if (!isDataSourceFilterBased(component.dataSource)) {
      // TODO CL-6875 - handle all data sources types
      return (async function* () {
        return [];
      })();
    }
    const visibilityLimits = createLimits?.(component);
    return createLimitedDataFetcher(mw.catalog.getContent(component.dataSource.filters, {pageSize: defaultPageSize}), visibilityLimits, component);
  });

  const filterSwimlaneComponents = useFunction((swimlaneComponent: CMSComponent) => {
    return isDataSourceFilterBased(swimlaneComponent.dataSource) // TODO CL-6875 - handle all data sources types
      && (
        mw.configuration.enabledWatchlist || swimlaneComponent.dataSource.filters[0].value !== SpecialFilter.WatchList
      );
  });

  const getCmsPage = disposable((link: Link) => mw.cms.getPage(link));

  const swimlaneInsets = useFunction(() => {
    return {
      ...defaultSwimlaneInsets,
      ...propsSwimlaneInsets
    };
  });

  const [refreshing, setRefreshing] = useState(false);
  const [dataVersion, setDataVersion] = useState(0);
  const [swimlaneComponents, setSwimlaneComponents] = useState<SwimlaneComponent<Media>[]>([]);

  const clear = useCallback(() => {
    setDataVersion(0);
    setRefreshing(false);
    setSwimlaneComponents([]);
  }, []);

  const fetchPageLayout = useFunction(async (link: Link | undefined) => {
    if (refreshing) {
      return;
    }
    setRefreshing(true);
    try {
      if (!link || link.type !== LinkType.PAGE) {
        Log.info(TAG, 'Did not receive slug for page');
        setRefreshing(false);
        return;
      }
      const page = await getCmsPage(link);
      const swimlaneComponents = page.componentGroup[0];
      if (!swimlaneComponents) {
        throw new Error('Could not find swimlanes!');
      }
      const localDataVersion = dataVersion + 1;
      const filteredSwimlaneComponents = swimlaneComponents.components
        .filter(filterSwimlaneComponents)
        .map(component => {
          const dataEvents = isDataSourceFilterBased(component.dataSource)
            ? metaEventsEmitter.current.getDataEventsForFilter(component.dataSource.filters[0])
            : {};
          return {
            component,
            createDataFetcher: () => createDataFetcher(component),
            staticHeaderActions: createStaticHeaderActions?.(component),
            ...dataEvents,
            title: component.title
          };
        });
      setSwimlaneComponents(filteredSwimlaneComponents);
      setDataVersion(localDataVersion);
      setRefreshing(false);
    } catch (error) {
      Log.error(TAG, error);
      setRefreshing(false);
      clear();
    }
  });

  const onTileFocusHandler = useCallback((tileIndex: number, row: number, data: Media, options: FocusOptions | undefined, wrapAroundActive: () => boolean) => {
    if (data instanceof NamedAction) {
      return;
    }
    onTileFocus?.(tileIndex, row, data, options, wrapAroundActive);
  }, [onTileFocus]);

  /* eslint-disable schange-rules/no-use-imperative-handle-hook */
  useImperativeHandle(ref, () => ({
    clear,
    fetchPageLayout
  }), [clear, fetchPageLayout]);
  /* eslint-enable schange-rules/no-use-imperative-handle-hook */

  const {createTile, placeholderComponent} = useDefaultMediaSwimlaneTiles({
    onPress: onTilePress,
    onFirstTileMount
  });

  const focusedTileFrameStyle = useMediaTileFocusedStyle();
  const renderFocusedTileFrame = useCallback(() => {
    // eslint-disable-next-line react/display-name
    return () => <View style={focusedTileFrameStyle} />;
  }, [focusedTileFrameStyle]);

  const setupSwimlane = useCallback((row: number): SetupSwimlaneType<Media> => {
    const swimlaneComponent = swimlaneComponents[row];
    return {
      header: swimlaneComponent.title,
      row,
      insets: swimlaneInsets(),
      headerInsetLeft: headerInsetLeft,
      renderNavigationArrows: isDesktopBrowser && !isSTBBrowser,
      itemWidth: swimlaneItemWidth,
      itemHeight: swimlaneItemHeight,
      dataFetcher: swimlaneComponent.dataFetcher,
      createDataFetcher: swimlaneComponent.createDataFetcher,
      dataChangeEvent: swimlaneComponent.dataChangeEvent,
      dataRefreshEvent: swimlaneComponent.dataRefreshEvent,
      dataEventsEmitter: swimlaneComponent.dataEventsEmitter,
      staticHeaderActions: swimlaneComponent.staticHeaderActions,
      fixedFocusPosition: fixedFocusPosition,
      wrapAround,
      showHeaderActions,
      placeholderComponent
    };
  }, [swimlaneComponents, swimlaneInsets, headerInsetLeft, fixedFocusPosition, wrapAround, showHeaderActions, placeholderComponent]);

  const insets = swimlaneInsets();

  if (swimlaneComponents.length === 0) {
    return null;
  }

  return (
    <AnimatedSwimlaneStackBase<Media>
      swimlaneItemHeight={swimlaneItemHeight}
      swimlaneComponents={swimlaneComponents}
      setupSwimlane={setupSwimlane}
      createTile={createTile}
      renderFocusedTileFrame={renderFocusedTileFrame}
      topInset={topInset}
      swimlaneInsets={insets}
      onTileFocus={onTileFocusHandler}
      onReady={onReady}
    />
  );
};

export default React.memo(React.forwardRef(AnimatedSwimlaneStack));
