import {Event, Media, Series, TitleType} from 'mw/api/Metadata';
import {ResponseJson} from 'mw/bo-proxy/bo/traxis/TraxisTypes';

import {EventMapper} from './EventMapper';
import {SeriesMapper} from './SeriesMapper';
import {TitleMapper} from './TitleMapper';

type SeriesResult = {
  series: Series;
  firstEpisode: Media;
  numberOfEpisodes: number;
}

class EpisodeGrouper {
  private seriesMap: Map<string, SeriesResult> = new Map();
  private searchResults: Media[] = [];
  private readonly type: TitleType

  public constructor(type: TitleType) {
    this.type = type;
  }

  public appendEpisodesFromJson(json: ResponseJson, searchEvents?: Event[]) {
    for (const titleJson of json.Titles.Title) {
      const seriesJson = this.extractSeriesJson(titleJson);
      let titleSearchResults: Media[] = searchEvents ?? [];

      if (!searchEvents) {
        const title = TitleMapper.fromJSON(titleJson, this.type, undefined, true);
        titleSearchResults = this.type === TitleType.EPG ? title.events : [title];
      }

      if (!seriesJson) {
        this.searchResults.push(...titleSearchResults);
      } else {
        const seriesId = seriesJson.id;
        const seriesResult = this.seriesMap.get(seriesId);
        if (!seriesResult) {
          if (titleSearchResults.length > 0) {
            const series = SeriesMapper.fromJSON(seriesJson);
            this.seriesMap.set(seriesId, {
              series: series,
              firstEpisode: titleSearchResults[0],
              numberOfEpisodes: titleSearchResults.length
            });
            this.searchResults.push(series);
          }
        } else {
          seriesResult.numberOfEpisodes += titleSearchResults.length;
        }
      }
    }
  }

  private extractSeriesJson(titleJson: ResponseJson): ResponseJson | undefined {
    const seasonJson = titleJson.SeriesCollection?.Series?.[0];
    const seriesJson = seasonJson?.ParentSeriesCollection?.Series && seasonJson.ParentSeriesCollection.Series[0];

    return seriesJson || seasonJson;
  }

  public getGroupedSearchResult(): Media[] {
    this.ungroupSeriesWithSingleEpisode();
    return this.searchResults;
  }

  private ungroupSeriesWithSingleEpisode() {
    this.seriesMap.forEach((seriesResult: SeriesResult) => {
      if (seriesResult.numberOfEpisodes === 1) {
        const indexOfSeries = this.searchResults.indexOf(seriesResult.series);
        this.searchResults[indexOfSeries] = seriesResult.firstEpisode;
      }
    });
  }
}

export class SearchMapper {
  public static titlesFromJSON(json: ResponseJson): Media[] {
    return SearchMapper.mediaFromJson(json, TitleType.VOD);
  }

  public static eventsFromJSON(json: ResponseJson): Media[] {
    return SearchMapper.mediaFromJson(json, TitleType.EPG);
  }

  private static mediaFromJson(json: ResponseJson, type: TitleType): Media[] {
    if (!json?.Titles?.Title?.length) {
      return [];
    }

    const grouper = new EpisodeGrouper(type);
    grouper.appendEpisodesFromJson(json);

    return grouper.getGroupedSearchResult();
  }

  public static eventsFromRecordingsJSON(json: ResponseJson): Media[] {
    if (!json?.Recordings?.Recording?.length) {
      return [];
    }

    const grouper = new EpisodeGrouper(TitleType.EPG);

    for (const recordingJson of json.Recordings.Recording) {
      const titleSearchResults = json.Recordings.Recording.map(EventMapper.fromRecordingsJSON);
      grouper.appendEpisodesFromJson(recordingJson, titleSearchResults);
    }

    return grouper.getGroupedSearchResult();
  }

  public static eventsFromRecordedTitlesJSON(json: ResponseJson): Media[] {
    if (!json?.RecordedTitles?.RecordedTitle?.length) {
      return [];
    }

    const grouper = new EpisodeGrouper(TitleType.EPG);

    for (const recordedTitle of json.RecordedTitles.RecordedTitle) {
      const titleSearchResults = recordedTitle.Recordings.Recording.map((recording: any) => {
        return EventMapper.fromRecordingsJSON({
          ...recording,
          Titles: recordedTitle.Titles
        });
      });
      grouper.appendEpisodesFromJson(recordedTitle, titleSearchResults);
    }

    return grouper.getGroupedSearchResult();
  }
}
