import React, {RefObject, useCallback, useMemo, useRef} from 'react';
import {View} from 'react-native';

import {isPhone} from 'common/constants';

import {Media} from 'mw/api/Metadata';

import MediaTile, {mediaTileMarginHorizontal} from 'components/mediaTiles/MediaTile';
import MobileMediaTile, {mobileMediaTileMarginHorizontal} from 'components/mediaTiles/MobileMediaTile';
import {useLazyEffect} from 'hooks/Hooks';

import {FocusableComponent} from './focusManager/FocusManagerTypes';
import {GridElementProps} from './Grid';

export const gridMediaTileMarginHorizontal = isPhone ? mobileMediaTileMarginHorizontal : mediaTileMarginHorizontal;

type TileProps = GridElementProps<Media> & {
  extraData?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  onTileFocus?: (tileIndex: number, data: Media) => void;
  onLastFocusedTileChanged?: (reference: RefObject<FocusableComponent>) => void;
  onMoreActionPress?: (media: Media) => void;
  tileRenderer?: (onFocusHandler: () => void, reference: RefObject<FocusableComponent>) => JSX.Element;
};

const GridMediaTile = (props: TileProps) => {
  const {onLastFocusedTileChanged, onTileFocus, onMoreActionPress, tileRenderer, data, index, spacing, extraData} = props;
  const reference = useRef<FocusableComponent>(null);

  useLazyEffect(() => {
    if (index === 0) {
      onLastFocusedTileChanged?.(reference);
    }
  },[], [index, onLastFocusedTileChanged]);

  const onFocusHandler = useCallback(() => {
      onLastFocusedTileChanged?.(reference);
      onTileFocus?.(index, data);
  }, [onTileFocus, onLastFocusedTileChanged, index, data]);

  const tile = useMemo(() => {
    if (tileRenderer) {
      return tileRenderer(onFocusHandler, reference);
    }
    if (isPhone) {
      return (
        <MobileMediaTile
          key={data.id}
          media={data}
          extraData={extraData}
          onMoreActionsPress={onMoreActionPress}
        />
      );
    }
    return (
      <MediaTile
        ref={reference}
        key={data.id}
        media={data}
        extraData={extraData}
        onFocus={onFocusHandler}
      />
    );
  }, [tileRenderer, data, onFocusHandler, extraData, onMoreActionPress]);

  return (
    <View style={{paddingLeft: spacing}}>
      {tile}
    </View>
  );
};

export default React.memo(GridMediaTile);
