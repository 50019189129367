import {createStyles} from 'common-styles';
import i18next from 'i18next';
import React, {useState, useCallback, useMemo} from 'react';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Series} from 'mw/api/Metadata';

import {Icon, IconType} from 'components/Icon';
import {ModalSelect} from 'components/ModalSelect';
import NitroxInteractive from 'components/NitroxInteractive';
import NitroxText from 'components/NitroxText';

const TAG = 'VodSeriesMobileOverlaySeasonPicker';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  seasonsPicker: {
    width: 100,
    height: 30,
    borderRadius: 3,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: colors.button.secondary.background.focused
  },
  seasonsButtonText: {
    color: colors.button.secondary.text.focused
  }
}));

function getSeasonPickerLabel(season: Series) {
  if (season.seasonNumber) {
    return i18next.t('vod.seasonNumberFull', {seasonNumber: season.seasonNumber.toString()});
  } else {
    return i18next.t('vod.season');
  }
}

type Props = {
  onSeasonSelected: (item: string | number | undefined) => void;
  season: Series;
  seasons: Series[];
}

function VodSeriesMobileOverlaySeasonPicker(props: Props) {
  const {onSeasonSelected: propsOnSeasonSelected} = props;

  const [expanded, setExpanded] = useState(false);
  const label = getSeasonPickerLabel(props.season);

  const options = useMemo(() => {
    if (!Array.isArray(props.seasons)) {
      return;
    }

    return props.seasons.map(season => {
      return {value: season.id, label: getSeasonPickerLabel(season), key: season.id};
    });
  }, [props.seasons]);

  const onPickerPress = useCallback(async () => {
    setExpanded(true);
  }, []);

  const onSeasonSelectedHandler = useCallback(item => {
    setExpanded(false);
    propsOnSeasonSelected(item);
  }, [propsOnSeasonSelected]);

  const onCloseHandler = useCallback(() => {
    setExpanded(false);
  }, []);

  const styles = stylesUpdater.getStyles();
  return (
    <NitroxInteractive
      style={styles.seasonsPicker}
      onPress={onPickerPress}
      testID='button_close'
    >
      <NitroxText style={styles.seasonsButtonText} textType='body1'>{label}</NitroxText>
      <Icon type={IconType.ArrowDownBold} size={8} />
      {options && (
        <ModalSelect
          options={options}
          visible={expanded}
          onChange={onSeasonSelectedHandler}
          onClose={onCloseHandler}
        />
      )}
    </NitroxInteractive>
  );
}

export default VodSeriesMobileOverlaySeasonPicker;
