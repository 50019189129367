import {createStyles} from 'common-styles';
import React, {useState, useEffect, useCallback, useRef, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View, StyleSheet, GestureResponderEvent} from 'react-native';
import DeviceInfo from 'react-native-device-info';

import {dimensions, epgLimits, getValue, isTablet} from 'common/constants';
import {DateUtils} from 'common/DateUtils';
import {idKeyExtractor, getMediaDuration} from 'common/HelperFunctions';
import {ScreenSizeBasedOrientation} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Channel, Event, ContentType} from 'mw/api/Metadata';
import {PlayerEvent, PositionChanged} from 'mw/api/PlayerEvent';
import {mw} from 'mw/MW';
import {RewindDirection} from 'mw/playback/Player';

import {channelIconConstants, ChannelIconType} from 'components/ChannelIcon';
import VerticalChannelsListPiccolo from 'components/channelsList/VerticalChannelsList.piccolo';
import FocusParent from 'components/FocusParent';
import {FAR_FAR_AWAY} from 'components/navigation/ResourceSavingScene';
import {NitroxListItemsRequest, NitroxListItemsBatch, NitroxListItemsAction} from 'components/NitroxList';
import {AppStatePlaybackRetuneScheduler} from 'components/player/PlaybackRetuneScheduler';
import PlayerControlsView, {PlayerControlsHandlers, PlayerControlsViewButtonsVisibility} from 'components/player/PlayerControlsView';
import {SafeAreaWrapper} from 'components/SafeAreaWrapper';
import {getEventMetadata} from 'components/ShortDetailsView';
import ShortDetails from 'components/ShortDetailsView';
import ProgressBarView from 'components/zapper/ProgressBarView';
import {getScreenInfo, useEventListener, useDisposableCallback, useLazyEffect, useDisposable, useFunction} from 'hooks/Hooks';
import {computeProgress} from 'screens/tv/TvScreenHelperFunctions';

import {DetailsButtonsHandlers} from './MediaDetailsTemplate';

const progressBarWidth = '90%';
const screenWidth = getScreenInfo().size.width;
export const channelListContainerLeftMargin = DeviceInfo.hasNotch() ? dimensions.notch.height : Math.round(0.05 * screenWidth);

const TAG = 'Zapper';

const focusPositionBigScreen = 0.8;
const lockIconSize = 400;

export enum ZapperMode {
  INTERACTIVE,
  STATIC
}

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  channelsListContainer: {
    position: 'absolute',
    top: dimensions.margins.xxLarge,
    bottom: dimensions.margins.xxxLarge,
    left: channelListContainerLeftMargin
  },
  hiddenChannelsListContainer: {
    left: FAR_FAR_AWAY
  },
  clock: {
    position: 'absolute',
    top: '5%',
    right: '4%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    height: getValue({mobile: 34, defaultValue: 61})
  },
  progressBarContainer: {
    position: 'absolute',
    alignSelf: 'center',
    left: channelListContainerLeftMargin,
    marginLeft: dimensions.margins.xxxLarge,
    right: dimensions.margins.xxLarge + dimensions.icon.xxsmall + dimensions.margins.large,
    bottom: isTablet ? dimensions.margins.xxxLarge : (dimensions.margins.xxxLarge - (dimensions.player.progressBar.default.expandedHeight / 2) + (dimensions.player.progressBar.default.progressHeight))
  },
  progressBarWithChannelsListContainer: {
    marginLeft: channelIconConstants.channelList.scaledMobile + dimensions.margins.large
  },
  controlsViewContainer: {
    ...StyleSheet.absoluteFillObject
  },
  shortDetailsMobiles: {
    top: dimensions.margins.xsmall
  },
  lockedIcon: {
    position: 'absolute',
    alignSelf: 'center',
    width: progressBarWidth,
    alignItems: 'center',
    top: '50%',
    marginTop: -lockIconSize / 2
  },
  unlockButton: {
    alignSelf: 'flex-start'
  },
  lockedIconColor: colors.tile.poster.placeholder.icon
}));

interface EventsRequest {
  fetchedBatch?: NitroxListItemsBatch<Event>;
  pendingPromise?: Promise<NitroxListItemsBatch<Event>>;
}

type FetchEvents = (props: {
  channel?: Channel;
  currentEvent?: Event;
  itemsRequest: NitroxListItemsRequest;
  fetchedStartTime: Date;
  fetchedEndTime: Date;
  requests: Map<string, EventsRequest>;
  eventsKeys: Set<string>;
  onCurrentEventChanged: (event: Event) => void;
}) => {
  promise: Promise<NitroxListItemsBatch<Event>>;
  newFetchedStartTime: Date;
  newFetchedEndTime: Date;
}

const fetchEvents: FetchEvents = props => {
  const {channel, currentEvent, itemsRequest, fetchedStartTime, fetchedEndTime, requests, eventsKeys, onCurrentEventChanged} = props;
  let newFetchedStartTime = fetchedStartTime;
  let newFetchedEndTime = fetchedEndTime;
  if (!channel) {
    Log.debug(TAG, 'Unable to load events - channel is missing');
    return {
      promise: Promise.resolve(new NitroxListItemsBatch([], false)),
      newFetchedStartTime,
      newFetchedEndTime
    };
  }
  // check if we can reuse already fetched batch
  const eventsRequestKey = Object.values(itemsRequest).join('_');
  const eventsRequest = requests.get(eventsRequestKey) || {};
  if (eventsRequest.fetchedBatch) {
    Log.debug(TAG, 'Request ' + eventsRequestKey + ' was already fetched - using it');
    return {
      promise: Promise.resolve(eventsRequest.fetchedBatch),
      newFetchedStartTime,
      newFetchedEndTime
    };
  }
  if (eventsRequest.pendingPromise) {
    Log.debug(TAG, 'We are still waiting for request ' + eventsRequestKey);
    return {
      promise: eventsRequest.pendingPromise,
      newFetchedStartTime,
      newFetchedEndTime
    };
  }
  // send a new request
  requests.set(eventsRequestKey, eventsRequest);
  eventsRequest.pendingPromise = new Promise(async resolve => {
    let startTime: Date;
    let endTime: Date;
    if (itemsRequest.action === NitroxListItemsAction.Append) {
      startTime = new Date(fetchedEndTime.getTime());
      endTime = DateUtils.addHours(fetchedEndTime, epgLimits.oneChannel.timespanPerRequest);
      newFetchedEndTime = endTime;
    } else {
      endTime = new Date(fetchedStartTime.getTime());
      startTime = DateUtils.addHours(fetchedStartTime, -epgLimits.oneChannel.timespanPerRequest);
      newFetchedStartTime = startTime;
    }

    Log.debug(TAG, `Loading events from channel ${channel} starting from ${startTime.toLocaleString()} to ${endTime.toLocaleString()}`);
    try {
      const events = await mw.catalog.getEPG({
        channels: [channel],
        startTime: startTime,
        endTime: endTime
      });
      const eventsOnChannel = (events.get(channel.id) || []).filter((event: Event, index: number) => {
        const key = idKeyExtractor(event, index);
        if (eventsKeys.has(key)) {
          Log.warn(TAG, `Found duplicated event ${event} with key ${key}`);
          return false;
        }
        eventsKeys.add(key);
        return true;
      });
      eventsRequest.fetchedBatch = new NitroxListItemsBatch(eventsOnChannel, eventsOnChannel.length > 0);
      eventsRequest.pendingPromise = undefined;

      const focusedIndex = Math.max(0, eventsOnChannel.findIndex(currentEvent ?
        ((event: Event): boolean => event.id === currentEvent.id) :
        ((event: Event): boolean => event.isNow)
      ));
      if (!currentEvent) {
        onCurrentEventChanged(eventsOnChannel[focusedIndex]);
        eventsRequest.fetchedBatch.focusedIndex = focusedIndex;
      }

      Log.debug(TAG, `Loaded ${eventsOnChannel.length} events - from channel ${channel} starting from ${startTime.toLocaleString()} to ${endTime.toLocaleString()}`);
      resolve(eventsRequest.fetchedBatch);
    } catch (reason) {
      Log.debug(TAG, `Failed to load events from channel ${channel} - reason: ${reason}`);
      resolve(new NitroxListItemsBatch([], false));
    }
  });
  return {
    promise: eventsRequest.pendingPromise,
    newFetchedStartTime,
    newFetchedEndTime
  };
};

export type MediaInfoVisibility = {
  mediaDetails: boolean;
  progressBar: boolean;
}

type Props = {
  mode?: ZapperMode;
  channels: Channel[];
  changingPosition?: boolean;
  currentChannel?: Channel;
  onPressChannelIcon?: (channel: Channel, event?: GestureResponderEvent) => void;
  onFocusChannelIcon?: (channel: Channel) => void;
  onFocusDetails?: () => void;
  onBlurDetails?: () => void;
  onMouseDown?: () => void;
  onMouseMove?: () => void;
  currentPositionChanged?: (position: number) => void;
  onProgressChange?: (position: number) => void;
  playerControlsHandlers: PlayerControlsHandlers;
  detailsHandlers?: DetailsButtonsHandlers;
  orientation: ScreenSizeBasedOrientation;
  paused: boolean;
  rewindDirection?: RewindDirection;
  buttonsVisibility: PlayerControlsViewButtonsVisibility;
  mediaInfoVisibility: MediaInfoVisibility;
  overlayVisible: boolean;
  onScroll?: () => void;
  shortDetailsVisible?: boolean;
  channelsListVisible?: boolean;
  forceDisplayEvent?: Event | null;
  onAdultContentUnlockAuthorized?: () => void;
  authorized?: boolean;
  playbackError?: boolean;
  onGestureStateChange?: (value: boolean) => void;
}

const Zapper: React.FC<Props> = props => {
  const date = new Date();
  const [focusedChannel, setFocusedChannel] = useState(props.currentChannel);
  const [focusedEvent, setFocusedEvent] = useState<Event | undefined>(undefined);
  const [currentProgress, setCurrentProgress] = useState(date);
  const [availableProgressStart, setAvailableProgressStart] = useState(date);
  const [availableProgressEnd, setAvailableProgressEnd] = useState(date);
  const [currentTime, setCurrentTime] = useState(date);
  const landscape = props.orientation.isLandscape;
  const eventsRequests = useRef<Map<string, EventsRequest>>(new Map<string, EventsRequest>());
  const eventsKeys = useRef<Set<string>>(new Set<string>());
  const fetchedStartTime = useRef<Date>(new Date());
  const fetchedEndTime = useRef<Date>(new Date());
  const {overlayVisible, mediaInfoVisibility, shortDetailsVisible = true, channelsListVisible = true, authorized, onGestureStateChange} = props;
  const {currentPositionChanged: propsCurrentPositionChanged} = props;
  const {onProgressChange: propsOnProgressChange} = props;
  const isDraggableForward = props.buttonsVisibility.playback.skipForward;
  const isDraggableBackward = props.buttonsVisibility.playback.skipBack;
  // Only display stream buffer boundaries when we’re allowed to pause
  const shouldDisplayBufferBoundaries = props.buttonsVisibility.playback.pausePlay;
  const {t} = useTranslation();

  const [duringVerticalChannelsListScroll, setDuringVerticalChannelsListScroll] = useState(false);
  const onVerticalChannelsListScroll = useFunction(() => {
    if (!duringVerticalChannelsListScroll) {
      setDuringVerticalChannelsListScroll(true);
    }
    props.onScroll?.();
  });

  const clearMediaListsData = useCallback(() => {
    fetchedStartTime.current = new Date();
    fetchedEndTime.current = new Date();
    eventsRequests.current.clear();
    eventsKeys.current.clear();
  }, []);

  const updateFocusedEvent = useDisposable(async (channel?: Channel) => {
    if (!channel) {
      Log.debug(TAG, 'Ignoring request to update focused event - there is no channel');
      return;
    }
    try {
      setFocusedEvent(await mw.catalog.getCurrentEvent(channel));
    } catch (error) {
      Log.error(TAG, `Failed to update focused event on channel ${channel} - got error:`, error);
    }
  });

  useEffect(() => {
    setDuringVerticalChannelsListScroll(false);
    if (!overlayVisible) {
      setFocusedChannel(props.currentChannel);
    }
  }, [overlayVisible, props.currentChannel]);

  useLazyEffect(() => {
    setFocusedChannel(props.currentChannel);
    clearMediaListsData();
    const position = mw.players.main.getParameters().position;
    setCurrentTime(position ? new Date(position * DateUtils.msInSec) : new Date());
  }, [props.currentChannel], [clearMediaListsData]);

  useLazyEffect(() => {
    if (props.forceDisplayEvent) {
      setFocusedEvent(props.forceDisplayEvent);
    } else {
      updateFocusedEvent(focusedChannel);
    }
  }, [focusedChannel, props.forceDisplayEvent], [updateFocusedEvent]);

  const currentPositionChanged = useCallback((position: number) => {
    propsCurrentPositionChanged && propsCurrentPositionChanged(position);
  }, [propsCurrentPositionChanged]);

  const updateCurrentTime = useCallback((position: number) => {
    if (focusedEvent) {
      const positionInSec = getMediaDuration(focusedEvent, mw.players.main.contentType) * position / DateUtils.msInSec;
      setCurrentTime(DateUtils.addSeconds(focusedEvent.start, positionInSec));
    }
  }, [focusedEvent]);

  const onProgressChange = useCallback((position: number) => {
    updateCurrentTime(position);
    propsOnProgressChange && propsOnProgressChange(position);
  }, [updateCurrentTime, propsOnProgressChange]);

  const styles = stylesUpdater.getStyles();
  const shortDetailsStyle = useMemo(() => {
    return props.orientation.isPortrait ? styles.shortDetailsMobiles : {};
  }, [styles.shortDetailsMobiles, props.orientation.isPortrait]);

  const onPositionChanged = useDisposableCallback((params: PositionChanged) => {
    switch (mw.players.main.contentType) {
      case ContentType.LIVE:
      case ContentType.NPLTV:
        const currentPosition = new Date(params.position * DateUtils.msInSec);
        setAvailableProgressStart(new Date(params.beginPosition * DateUtils.msInSec));
        setAvailableProgressEnd(new Date(params.endPosition * DateUtils.msInSec));
        setCurrentProgress(currentPosition);
        setCurrentTime(currentPosition);
        break;
      case ContentType.TSTV:
        if (focusedEvent) {
          const currentPosition = DateUtils.addSeconds(focusedEvent.start, params.position);
          setCurrentTime(currentPosition);
          setCurrentProgress(currentPosition);
          setAvailableProgressStart(DateUtils.addSeconds(focusedEvent.start, params.beginPosition));
          setAvailableProgressEnd(focusedEvent.isNow ? DateUtils.addSeconds(focusedEvent.start, params.endPosition) : focusedEvent.end);
        }
        break;
      default:
        const now = new Date();
        setAvailableProgressStart(now);
        setAvailableProgressEnd(now);
        setCurrentProgress(now);
        setCurrentTime(now);
        break;
    }
  });

  useEventListener(PlayerEvent.PositionChanged, onPositionChanged, mw.players.main);

  const selectedChannelOnMobile = props.currentChannel && props.currentChannel.id || focusedChannel && focusedChannel.id || '';
  const renderDetails = useCallback((titleNumberOfLines?: number) => {
    if (focusedChannel && mediaInfoVisibility.mediaDetails) {
      if (focusedEvent && shortDetailsVisible) {
        return (
          <>
            <ShortDetails style={shortDetailsStyle} landscape={landscape} titleNumberOfLines={titleNumberOfLines} {...getEventMetadata(focusedEvent, t, landscape)} />
            {!authorized && (
              <AppStatePlaybackRetuneScheduler />
            )}
          </>
        );
      } else {
        return null;
      }
    }
  }, [focusedChannel, mediaInfoVisibility.mediaDetails, authorized, focusedEvent, shortDetailsVisible, landscape, shortDetailsStyle, t]);

  const onPressChannelIcon = useCallback((channel: Channel) => props.onPressChannelIcon?.(channel), [props.onPressChannelIcon]);

  return (
    <SafeAreaWrapper
      {...{
        onMouseMove: props.onMouseMove,
        onMouseDown: props.onMouseDown
      }}
      style={StyleSheet.absoluteFill}
    >
      {!duringVerticalChannelsListScroll && (
        <>
          <FocusParent trapFocus style={styles.controlsViewContainer}>
            <PlayerControlsView
              zapperMode
              landscape={landscape}
              renderDetails={renderDetails}
              paused={props.paused}
              rewindDirection={props.rewindDirection}
              shouldDisplayPlaybackControls={true}
              handlers={props.playerControlsHandlers}
              buttonsVisibility={props.buttonsVisibility}
              playbackError={props.playbackError}
              isChannelBlocked={!props.currentChannel?.isAvailableByPolicy}
            />
          </FocusParent>
          {landscape && focusedEvent && mediaInfoVisibility.progressBar && (
            <View style={[styles.progressBarContainer, channelsListVisible && styles.progressBarWithChannelsListContainer]} >
              <ProgressBarView
                startTime={focusedEvent.start}
                changingProgress={props.changingPosition}
                currentTime={currentTime}
                endTime={focusedEvent.end}
                currentProgress={computeProgress(focusedEvent.start, focusedEvent.end, currentProgress) || 0}
                availableProgressStart={shouldDisplayBufferBoundaries && focusedEvent && computeProgress(focusedEvent.start, focusedEvent.end, availableProgressStart) || 0}
                availableProgressEnd={shouldDisplayBufferBoundaries ? focusedEvent && computeProgress(focusedEvent.start, focusedEvent.end, availableProgressEnd) || 1 : 0}
                currentPositionChanged={currentPositionChanged}
                onProgressChange={onProgressChange}
                currentTimeLabelVisible={true}
                currentTimeLabelAlignment={'bubble'}
                isDraggableForward={isDraggableForward}
                isDraggableBackward={isDraggableBackward}
                overlayVisible={overlayVisible}
                onGestureStateChange={onGestureStateChange}
                onExpand={updateCurrentTime}
              />
            </View>
          )}
        </>
      )}
      {(
        // Recreating VerticalChannelsListPiccolo is very expensive so it's better to move it far away and inform it that is not visible to disable animations.
        <View style={[styles.channelsListContainer, (!landscape || !channelsListVisible) && styles.hiddenChannelsListContainer]}>
          <VerticalChannelsListPiccolo
            channels={props.channels}
            selectedChannelId={selectedChannelOnMobile}
            onPress={onPressChannelIcon}
            onScroll={onVerticalChannelsListScroll}
            visible={landscape && channelsListVisible && props.overlayVisible}
            showChannelNumber
            iconType={ChannelIconType.ChannelListMobileZapper}
          />
        </View>
      )}
    </SafeAreaWrapper>
  );
};

export default React.memo(Zapper);
