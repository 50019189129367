import {Metadata, SeriesType, Title, MediaType, Playable, Series} from 'mw/api/Metadata';

import {MediaObject} from './MediaObject';

export class SeriesObject extends MediaObject implements Series {
  public metadata: Metadata;
  public seriesType: SeriesType;
  public seasonNumber?: number;
  public seasons: Series[] = [];
  public seasonsCount = 0;
  public titles: Title[] = [];
  public externalId?: string;

  private type: MediaType;

  public constructor(id: string, name: string, metadata: Metadata, seriesType: SeriesType, seasonNumber?: number) {
    super(id, name);
    this.metadata = metadata;
    this.seriesType = seriesType;
    this.seasonNumber = seasonNumber;
    this.type = this.seriesType === SeriesType.Season ? MediaType.Season : MediaType.Series;
  }

  public getType(): MediaType {
    return this.type;
  }

  public getPlayable(): Playable | null {
    return null;
  }

  public isBlocked(): boolean {
    return false;
  }

  public isPlayAllowed(): boolean {
    // series playback not supported in current design
    return false;
  }
}
