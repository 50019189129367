import {createStyles} from 'common-styles';
import moment, {Moment} from 'moment';
import React from 'react';
import {forwardRef, useState, useImperativeHandle, RefObject} from 'react';
import {View, StyleSheet, TextStyle, ViewStyle} from 'react-native';

import {dimensions, isBigScreen, getTADateFormat} from 'common/constants';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {mw} from 'mw/MW';

import NitroxText from 'components/NitroxText';

const TAG = 'EpgTimebarTile';

export type EpgTimebarRef = RefObject<{
  updateProgress: () => void;
}>;

const textType = isBigScreen ? 'subhead-bold' : 'callout-bold';
const marginVertical = isBigScreen ? dimensions.margins.small : 0;

interface Props {
  time: moment.Moment;
  timeEnd?: moment.Moment;
  style?: ViewStyle;
  textStyle?: TextStyle;
  dynamicProgress?: boolean;
}

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  timebarElementContainer: {
    marginVertical,
    flex: 1,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: colors.epgScreen.timeBar.border,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: colors.epgScreen.timeBar.background.future
  },
  timebarElementText: {
    color: colors.epgScreen.timeBar.text,
    lineHeight: 30,
    zIndex: 1
  },
  progress: {
    backgroundColor: colors.epgScreen.timeBar.background.past
  }
}));

function EpgTimebarTile(props: Props, ref: any): React.ReactElement {
  const {time, dynamicProgress, timeEnd} = props;
  const [now, setNow] = useState<Moment>();
  const styles = stylesUpdater.getStyles();

  if (dynamicProgress && !timeEnd) {
    Log.error(TAG, 'dynamicProgress will not work (timeEnd not passed)');
  }

  const progress = 1 - (moment(timeEnd).diff(now) / moment(timeEnd).diff(moment(time)));
  const testID = `tile_${time.format(getTADateFormat())}`;
  const timeString = time.format(mw.configuration.timeFormat);

  useImperativeHandle(ref, () => ({
    updateProgress: () => setNow(moment())
  }));

  return (
    <View style={[styles.timebarElementContainer, props.style]} testID={testID}>
      {dynamicProgress && (
        <View style={{...StyleSheet.absoluteFillObject, flexDirection: 'row'}}>
          <View style={[{flex: progress}, styles.progress]} />
        </View>
      )}
      <NitroxText textType={textType} style={[styles.timebarElementText, props.textStyle]}>{timeString}</NitroxText>
    </View>
  );
}

export default React.memo(forwardRef(EpgTimebarTile));
