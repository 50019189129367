import {createStyles} from 'common-styles';
import React, {forwardRef, useCallback, useImperativeHandle, useMemo} from 'react';
import {StyleProp, ViewStyle} from 'react-native';

import {dimensions} from 'common/constants';
import {humanCaseToSnakeCase} from 'common/HelperFunctions';
import {FocusOptions, TestProps} from 'common/HelperTypes';

import {AnimatedTileInterface} from 'components/mediaTiles/MediaTile';
import {MediaTileCommonProps} from 'components/mediaTiles/MediaTileHooks';
import NitroxButton, {NitroxButtonTheme} from 'components/NitroxButton';
import {swimlaneHeaderHeight} from 'components/Swimlane';
import TileContainer from 'components/TileContainer';
import {useStaticallyFocused} from 'hooks/Hooks';

const staticStyles = createStyles({
  container: {
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    width: 170,
    justifyContent: 'center'
  },
  button: {
    height: '100%'
  }
});

type Props = {
  label?: string;
  buttonStyle?: StyleProp<ViewStyle>,
  onPress: () => void;
  onFocus?: (event: any, options?: FocusOptions) => void;
} & MediaTileCommonProps & TestProps;

/**
 * Tile component that wraps Button and supports static focus therefore it can be directly used to render AnimatedSwimlane's items.
 */
const ButtonTile: React.FC<Props> = ({
  label,
  focusable: propsFocusable = true,
  scrollOnFocus = true,
  staticallyFocused,
  style: containerStyle,
  buttonStyle,
  height = swimlaneHeaderHeight - dimensions.margins.small,
  onPress,
  onFocus: propagateFocus
}, ref) => {

  const onFocus = useCallback((event: any, options?: FocusOptions) => {
    propagateFocus?.(event, options);
  }, [propagateFocus]);

  const {onFocusStateChanged, focusable, focused} = useStaticallyFocused(propsFocusable, staticallyFocused);

  /* eslint-disable schange-rules/no-use-imperative-handle-hook */
  useImperativeHandle(ref, () => {
    const handlers: AnimatedTileInterface = {
      onPress: () => {
        if (!focusable && focused) {
          onPress();
        }
      }
    };
    return handlers;
  }, [onPress, focusable, focused]);
  /* eslint-enable schange-rules/no-use-imperative-handle-hook */

  const testID = 'button_tile_' + humanCaseToSnakeCase(label || 'empty');

  const computedContainerStyle = useMemo(() => ([
    staticStyles.container,
    {height},
    containerStyle
  ]), [containerStyle, height]);
  const computedButtonStyle = useMemo(() => ([
    staticStyles.button, buttonStyle
  ]), [buttonStyle]);

  return (
    <TileContainer
      ref={ref}
      onFocus={onFocus}
      scrollOnFocus={scrollOnFocus}
      focusable={focusable}
      onPress={onPress}
      onFocusStateChanged={onFocusStateChanged}
      testID={testID}
      style={computedContainerStyle}
    >
      <NitroxButton
        border
        style={computedButtonStyle}
        focusable={false}
        staticallyFocused={focused}
        theme={NitroxButtonTheme.Secondary}
        text={label}
      />
    </TileContainer>
  );
};

export default React.memo(forwardRef(ButtonTile));
