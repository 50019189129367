import {useRef, useMemo, useCallback} from 'react';
import {ViewStyle, Animated} from 'react-native';

import {mainMenuWidth, menuFocusParentOffset} from 'common/constants';

import {SideMenuState} from './NavigationHelperTypes';

type SideMenuContainerStyle = Omit<ViewStyle, 'width'> & {
  width: Animated.Value;
};
export interface SideAnimationsContainerConfig {
  opacity: number;
  x: number;
}

export interface SideAnimationsConfigParams {
  menuWidth: number;
  textContainer: SideAnimationsContainerConfig;
  iconContainer: SideAnimationsContainerConfig;
}

export interface SideMenuAnimationsConfig {
  [SideMenuState.Expanded]: SideAnimationsConfigParams;
  [SideMenuState.SemiCollapsed]: SideAnimationsConfigParams;
  [SideMenuState.Collapsed]: SideAnimationsConfigParams;
  [SideMenuState.Hidden]: SideAnimationsConfigParams;
}

export const SideAnimationsContainerHidden: SideAnimationsContainerConfig = {
  opacity: 0,
  x: -150
};

export const SideMenuAnimationsContainerVisible: SideAnimationsContainerConfig = {
  opacity: 1,
  x: 0
};
const DefaultAnimationsConfig: SideMenuAnimationsConfig = {
  [SideMenuState.Expanded]: {
    menuWidth: mainMenuWidth.expanded + menuFocusParentOffset,
    textContainer: SideMenuAnimationsContainerVisible,
    iconContainer: SideMenuAnimationsContainerVisible
  },
  [SideMenuState.SemiCollapsed]: {
    menuWidth: mainMenuWidth.semiCollapsed + menuFocusParentOffset,
    textContainer: SideAnimationsContainerHidden,
    iconContainer: SideMenuAnimationsContainerVisible
  },
  [SideMenuState.Collapsed]: {
    menuWidth: mainMenuWidth.collapsed + menuFocusParentOffset,
    textContainer: SideAnimationsContainerHidden,
    iconContainer: SideAnimationsContainerHidden
  },
  [SideMenuState.Hidden]: {
    menuWidth: menuFocusParentOffset,
    textContainer: SideAnimationsContainerHidden,
    iconContainer: SideAnimationsContainerHidden
  }
};

export function useSideMenuAnimations(initialState: SideMenuState, customConfig?: Partial<SideMenuAnimationsConfig>) {
  const config = useMemo<SideMenuAnimationsConfig>((): SideMenuAnimationsConfig => {
    return {...DefaultAnimationsConfig, ...customConfig};
  }, [customConfig]);
  const menuWidth = useRef(new Animated.Value(config[initialState].menuWidth));
  const textContainerOpacity = useRef(new Animated.Value(config[initialState].textContainer.opacity));
  const iconsOpacity = useRef(new Animated.Value(config[initialState].iconContainer.opacity));
  const textContainerX = useRef(new Animated.Value(config[initialState].textContainer.x));
  const iconContainerX = useRef(new Animated.Value(config[initialState].iconContainer.x));
  const menuContainerStyle: SideMenuContainerStyle = useMemo(() => ({
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: menuWidth.current
  }), []);
  const currentAnimation = useRef<Animated.CompositeAnimation | null>(null);

  const animateMenu = useCallback((state: SideMenuState) => {
    const currentConfig = config[state];
    currentAnimation.current?.stop();
    currentAnimation.current = Animated.parallel([
      Animated.timing(
        menuWidth.current, {
          toValue: currentConfig.menuWidth,
          duration: 0
        }
      ),
      Animated.timing(
        textContainerOpacity.current, {
          toValue: currentConfig.textContainer.opacity,
          duration: 0
        }
      ),
      Animated.timing(
        iconsOpacity.current, {
          toValue: currentConfig.iconContainer.opacity,
          duration: 0
        }
      ),
      Animated.timing(
        textContainerX.current, {
          toValue: currentConfig.textContainer.x,
          duration: 0
        }
      ),
      Animated.timing(
        iconContainerX.current, {
          toValue: currentConfig.iconContainer.x,
          duration: 0
        }
      )
    ]);
    currentAnimation.current?.start();
  }, [config]);

  return {
    animateMenu,
    menuContainerStyle,
    textContainerOpacity: textContainerOpacity.current,
    iconsOpacity: iconsOpacity.current,
    textContainerX: textContainerX.current,
    iconContainerX: iconContainerX.current,
    menuWidth
  };
}
