import {createStyles} from 'common-styles';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {NativeModules} from 'react-native';
import {NavigationScreenProps} from 'react-navigation';

import {dimensions, isTVOS, mainMenuWidth} from 'common/constants';
import {Log} from 'common/Log';

import {mw} from 'mw/MW';

import FocusParent, {useFocusParent} from 'components/FocusParent';
import {useLoggedIn} from 'components/navigation/NavigationHelperFunctions';
import {STBMenuState} from 'components/navigation/NavigationHelperTypes';
import Popup from 'components/Popup';
import {useToggle, useIsBack} from 'hooks/Hooks';
import NitroxScreen from 'screens/NitroxScreen';
import ParallelNavigator from 'screens/ParallelNavigator';

import {CommonSettingsScreenProps} from './CommonSettingsScreenProps';
import {useSettingsNavigation} from './hooks';
import SettingsList, {settingsListHorizontalSpace} from './SettingsList';

const TAG = 'SettingsScreen.grosso';

const styles = createStyles({
  mainContainer: {
    flex: 1,
    paddingLeft: dimensions.mainMenu.marginHorizontal - settingsListHorizontalSpace,
    paddingRight: dimensions.margins.xxxLarge
  },
  parallelNavigatorContainer: {
    flex: 1
  }
});

export type NativeExtraSettingsScreenProps = {
  onAndroidSettingsPress: () => void;
}

type Props = NavigationScreenProps & CommonSettingsScreenProps & NativeExtraSettingsScreenProps;

const SettingsScreenGrosso: React.FunctionComponent<Props> = props => {
  const {navigation, authorizeWithPin, showLogoutModal, onAndroidSettingsPress, renderModals} = props;

  const {t} = useTranslation();
  const {navigators, subRoute, subRouteProps} = useSettingsNavigation(authorizeWithPin);
  const isBack = useIsBack();
  const [onFocusParentReady, focus] = useFocusParent();
  const onScreenFocused = useCallback(() => {
    if (isBack()) {
      return;
    }
    focus();
    navigators.initial();
  }, [focus, isBack, navigators]);
  const [logAddress, setLogAddress] = useState('');
  const [shareLogsPopupVisible, {on: showShareLogsPopup, off: hideShareLogsPopup}] = useToggle(false);

  const startShareLogs = useCallback(async () => {
    // TODO: CL-3971
    return mw.system.shareLogs(t('settings.logsShareTitle'))
      .then(logAddress => {
        if (isTVOS) {
          setLogAddress(logAddress);
          showShareLogsPopup();
        }
      })
      .catch(error => Log.error(TAG, 'share logs failed', error));
  }, [t, showShareLogsPopup]);

  const stopShareLogs = useCallback(async () => {
    hideShareLogsPopup();
    NativeModules.LogsShareManager.stop();
  }, [hideShareLogsPopup]);

  const loggedIn = useLoggedIn();
  return (
    <NitroxScreen
      navigation={props.navigation}
      style={props.style}
      onScreenFocused={onScreenFocused}
      menuState={STBMenuState.Above}
      testID='screen_settings'
    >
      <FocusParent
        rememberLastFocused
        style={styles.parallelNavigatorContainer}
      >
        <ParallelNavigator subRoute={loggedIn ? subRoute : undefined} screenProps={{...subRouteProps, navigation, navigators}} mainScreenWidth={mainMenuWidth.semiCollapsed + dimensions.sideMenu.width}>
          <FocusParent
            onReady={onFocusParentReady}
            style={styles.mainContainer}
            rememberLastFocused
            debugName='SettingsList'
          >
            <SettingsList
              onCustomerIDPress={navigators.customerID}
              onAndroidSettingsPress={onAndroidSettingsPress}
              onRegisteredDevicesPress={navigators.registeredDevices}
              onApplicationVersionPress={navigators.applicationVersion}
              onBackOfficeSelectorPress={navigators.backOfficeSelector}
              onInfoPress={navigators.info}
              onChannelListsPress={navigators.channelLists}
              onLanguagePress={navigators.language}
              onTimeAndDatePress={navigators.timeAndDate}
              onParentalControlPress={navigators.parentalControl}
              onMyPinPress={navigators.myPin}
              onDeleteProfilePress={navigators.deleteProfile}
              onLogoutPress={showLogoutModal}
              activeRoute={subRoute}
              onShareLogsPress={startShareLogs}
              onSettingsOrdersPress={navigators.settingsOrders}
              onHelpPress={navigators.help}
            />
          </FocusParent>
        </ParallelNavigator>
      </FocusParent>
      {renderModals()}
      <Popup
        visible={shareLogsPopupVisible}
        title={t('settings.shareLogsPopup', {logAddress})}
        onClose={stopShareLogs}
      />
    </NitroxScreen>
  );
};
export default SettingsScreenGrosso;
