import {createStyles} from 'common-styles';
import React, {useMemo, useCallback} from 'react';
import {View, ViewStyle, StyleProp} from 'react-native';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors, transparent} from 'common-styles/variables/base-colors';

import NitroxButton from 'components/NitroxButton';
import {PageNavigatorProps} from 'components/PageNavigatorProps';

export const dotSize = 10;
export const dotMargin = dimensions.margins.xsmall;

const staticStyles = createStyles({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dot: {
    width: dotSize,
    height: dotSize,
    borderRadius: Math.floor(dotSize / 2),
    margin: dotMargin,
    padding: 0,
    paddingLeft: 0,
    paddingRight: 0
  }
});

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  selectedDot: {
    backgroundColor: colors.button.primary.background.focused
  },
  dot: {
    backgroundColor: transparent(colors.button.primary.background.focused, dimensions.opacity.medium)
  }
}));

type DotsPageNavigatorProps = {
  interactive?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  dotStyle?: StyleProp<ViewStyle>;
} & PageNavigatorProps;

const DotsPageNavigator: React.FC<DotsPageNavigatorProps> = ({
  interactive, pageIndex, numberOfPages, containerStyle, dotStyle, onPageSelected
}) => {
  const dynamicStyles = dynamicStylesUpdater.getStyles();

  const renderDot = useCallback((currentPageIndex: number) => {
    const key = `dot-${currentPageIndex}`;
    const isSelected = pageIndex === currentPageIndex;
    if (interactive) {
      return (
        <NitroxButton
          key={key}
          style={[dynamicStyles.dot, staticStyles.dot, dotStyle]}
          styleSelected={dynamicStyles.selectedDot}
          isSelected={isSelected}
          onPress={() => onPageSelected?.(currentPageIndex)}
        />
      );
    }
    const styles = [dynamicStyles.dot, staticStyles.dot, dotStyle];
    if (isSelected) {
      styles.push(dynamicStyles.selectedDot);
    }
    return (<View key={key} style={styles} />);
  }, [interactive, dynamicStyles.dot, dynamicStyles.selectedDot, dotStyle, pageIndex, onPageSelected]);

  const dots = useMemo(() => (
    Array.from(Array(numberOfPages).keys(), renderDot)
  ), [numberOfPages, renderDot]);

  return (
    <View style={[staticStyles.container, containerStyle]}>
      {dots}
    </View>
  );
};

export default React.memo(DotsPageNavigator);
