import {createStyles} from 'common-styles';
import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {dimensions, isBigScreen, getValue} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Styling} from 'mw/api/Metadata';

import {Modal} from 'components/Modal';
import NitroxButton, {NitroxButtonTheme} from 'components/NitroxButton';
import NitroxText from 'components/NitroxText';
import {useScreenInfo} from 'hooks/Hooks';

import {Icon, IconType} from './Icon';

const bigScreenPopupWidth = 1052;
const iconSize = isBigScreen ? 300 : 200;
const margin = getValue({mobile: dimensions.margins.xxLarge, tablet: dimensions.margins.xxxLarge, defaultValue: 0});
const marginTop = getValue({mobile: dimensions.margins.xxxxLarge, tablet: dimensions.margins.xxxLarge, defaultValue: 0});

const styles = createStyles({
  modal: {
    alignItems: 'center'
  },
  title: {
    marginTop: dimensions.margins.xxxLarge
  }
});

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  modalContent: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: marginTop,
    marginBottom: margin,
    marginHorizntal: margin,
    backgroundColor: colors.popup.background,
    paddingHorizontal: dimensions.margins.xLarge,
    paddingVertical: dimensions.margins.xxxLarge,
    borderRadius: 20
  },
  button: {
    marginTop: isBigScreen ? dimensions.margins.xxxxLarge : dimensions.margins.xxxLarge,
    backgroundColor: colors.button.primary.background.focused
  },
  buttonText: {
    color: colors.button.primary.text.focused
  },
  text: {
    color: colors.defaultColors.icon,
    textAlign: 'center'
  }
}));

type NewStylingPopupProps = {
  styling: Styling | null;
  onClose?: () => void;
  visible: boolean;
}

const NewStylingPopup: React.FC<NewStylingPopupProps> = props => {
  const {t} = useTranslation();
  const {styling, onClose, visible} = props;
  const {size: {width: screenWidth, height: screenHeight}} = useScreenInfo();

  const onPress = useCallback(() => {
    StylesUpdater.update(styling);
  }, [styling]);
  const dynamicStyles = dynamicStylesUpdater.getStyles();

  const contentStyle = useMemo(() => ([dynamicStyles.modalContent,
    {
      width: isBigScreen ? bigScreenPopupWidth : screenWidth - margin * 2,
      height: isBigScreen ? 'auto' : screenHeight - marginTop - margin
    }
  ]), [dynamicStyles.modalContent, screenWidth, screenHeight]);

  return (
    <Modal
      visible={visible}
      preventClosingOnBack
      onClose={onClose}
      style={styles.modal}
      contentStyle={contentStyle}
    >
      <Icon type={IconType.NewStyle} size={iconSize} />
      <NitroxText style={[styles.title, dynamicStyles.text]} textType={'title'}>{t('newStylingPopup.title')}</NitroxText>
      <NitroxText style={dynamicStyles.text} textType={'title2'}>{t('newStylingPopup.text')}</NitroxText>
      <NitroxButton
        border
        style={dynamicStyles.button}
        textStyle={dynamicStyles.buttonText}
        theme={NitroxButtonTheme.Primary}
        text={t('newStylingPopup.update')}
        onPress={onPress}
        hasTvPreferredFocus
      />
    </Modal>
  );
};

export default NewStylingPopup;
