import {EventEmitter} from 'common/EventEmitter';
import {Log} from 'common/Log';

import {Error, ErrorType} from 'mw/api/Error';
import {Credentials} from 'mw/api/NXFF';
import {Profile} from 'mw/api/Profile';
import {CustomerIdentity} from 'mw/bo-proxy/bo/traxis/Identities';
import {DrmSession, Identity, SSOEvent, SSOInterface} from 'mw/bo-proxy/SSOInterface';
import {AsyncStorageData} from 'mw/platform/async-storage/AsyncStorageInterface';

const TAG = 'TraxisSSOAdapter';

export abstract class TraxisSSOAdapter extends EventEmitter<SSOEvent> implements SSOInterface {

  private customerIdentity: CustomerIdentity | null = null;
  private tokenPromise: Promise<string> | null = null;

  public setIdentity(id?: string): void {
    this.customerIdentity = id ? new CustomerIdentity(id) : null;
  }

  public logout(): Promise<void> {
    this.customerIdentity = null;
    return Promise.resolve();
  }

  public switchProfile(profile: Profile, pin: string): Promise<void> {
    // not needed for adrenalin
    return Promise.resolve();
  }

  public getIdentity(): Identity {
    if (this.customerIdentity) {
      return this.customerIdentity;
    }

    throw new Error(ErrorType.UnknownError);
  }

  public getDrmSession(): Promise<DrmSession> {
    return Promise.resolve({});
  }

  public isLoggedIn(): boolean {
    return this.customerIdentity != null;
  }

  public getDevicePublicId(): string {
    throw new Error(ErrorType.NotSupported);
  }

  public isPurchasePinSupported(): boolean {
    return false;
  }

  public setPurchasePin(pin: string): Promise<void> {
    throw new Error(ErrorType.NotSupported);
  }

  public verifyPurchasePin(pin: string): Promise<void> {
    throw new Error(ErrorType.NotSupported);
  }

  public getCrossLoginData(): Promise<AsyncStorageData[]> {
    return Promise.resolve([]);
  }

  public login(credentials?: Credentials): Promise<void> {
    return this.requestToken(credentials)
      .then(() => Promise.resolve());
  }

  protected requestToken(credentials?: Credentials): Promise<string> {
    if (this.tokenPromise) {
      return this.tokenPromise;
    }

    this.tokenPromise = this.loginImpl(credentials)
      .then(token => {
        if (!token) {
          throw new Error(ErrorType.SSOUnauthorized);
        }
        return token;
      })
      .finally(() => this.tokenPromise = null);

    return this.tokenPromise;
  }

  protected detectConnectionErrors(error: Error): string {
    Log.error(TAG, 'detectConnectionErrors', error);
    switch (error.type) {
      case ErrorType.HttpTimeout:
      case ErrorType.NetworkNoConnection:
        throw error;
      default:
        throw new Error(ErrorType.SSOUnauthorized);
    }
  }

  public renewToken(): Promise<void> {
    return Promise.reject(new Error(ErrorType.NotSupported));
  }

  protected abstract loginImpl(credentials?: Credentials): Promise<string | null>;
  abstract getBoUrl(): Promise<string>;
  abstract getToken(): Promise<string>;
  abstract invalidateToken(authorizationToken: string | null): void;
  abstract getMasterPin(): string;
  abstract isMasterPinSupported(): boolean;
  abstract setMasterPin(pin: string): Promise<void>;
}
