import {shuffle, genresIntersect} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {RecommendationsQueryParameters, CatalogEvent} from 'mw/api/CatalogInterface';
import {CustomerEvent} from 'mw/api/Customer';
import {Event, Media, Title, MediaType, Metadata, Series, isEvent} from 'mw/api/Metadata';
import {RecommendationsEngine} from 'mw/bo-proxy/bo/RecommendationsEngine';
import {BOInterface} from 'mw/bo-proxy/BOInterface';
import {mw} from 'mw/MW';

const TAG = 'FakeRecommendations';

const numberOfRetrievedMovies = 50;

/** ms */
const cacheRefreshInterval = 60 * 60 * 1000;

/** hours */
const cacheSpan = 2;

const numberOfSimilarRecommendations = 10;
const numberOfGeneralRecommendations = 20;
const numberOfChannelsForEpgRecommendations = 12;

type CachedEvents = {
  events: Event[];
  timestamp: Date;
}

function removePastEvents(cacheEntry: CachedEvents) {
  cacheEntry.events = cacheEntry.events.filter(event => !event.isPast);
}

export class FakeEpgRecommendations implements RecommendationsEngine {
  private cachedEvents = new Map<string, CachedEvents>();

  public constructor() {
    mw.catalog.on(CatalogEvent.ChannelsListRefreshed, () => this.clearCache());
    mw.customer.on(CustomerEvent.UILanguageChanged, () => this.clearCache());
    mw.customer.on(CustomerEvent.ProfileChange, () => this.clearCache());
  }

  private async updateCachedEvents(channelId?: string) {
    let channels = await mw.catalog.getChannels();
    if (channelId) {
      const channel = channels.find(c => c.id === channelId);
      if (!channel) {
        Log.warn(TAG, `Failed to find a channel with the given id ${channelId}`);
        return;
      }
      channels = [channel];
    }
    const startTime = new Date();
    const endTime = new Date(startTime);
    endTime.setHours(endTime.getHours() + cacheSpan);
    const eventsMap = await mw.catalog.getEPG({
      channels: channels.slice(0, numberOfChannelsForEpgRecommendations),
      startTime,
      endTime
    });
    eventsMap.forEach((events, channelId) => {
      this.cachedEvents.set(channelId, {
        events,
        timestamp: new Date()
      });
    });
  }

  private findMinTimestamp(): Date | null {
    let result: Date | null = null;
    this.cachedEvents.forEach(entry => {
      if (!result || entry.timestamp.getTime() <= result.getTime()) {
        result = entry.timestamp;
      }
    });
    return result;
  }

  private cleanupPastCachedEvents(): number {
    let availableEventsCount = 0;
    this.cachedEvents.forEach(cacheEntry => {
      removePastEvents(cacheEntry);
      availableEventsCount += cacheEntry.events.length;
    });
    return availableEventsCount;
  }

  private async loadSimilarRecommendations(event: Event): Promise<Event[]> {
    Log.trace(TAG, `Loading similar recommendations for event ${event}`);
    const now = new Date();
    const cacheEntry = this.cachedEvents.get(event.channelId);
    if (cacheEntry) {
      removePastEvents(cacheEntry);
    }
    if (!cacheEntry || cacheEntry.events.length === 0 || (now.getTime() - cacheEntry.timestamp.getTime() >= cacheRefreshInterval)) {
      Log.debug(TAG, 'Updating cached events used for similar recommendations');
      await this.updateCachedEvents(event.channelId);
    }
    const events = this.cachedEvents.get(event.channelId)?.events; // make sure to use get here because update can create a new instance of cache entry for the given key
    if (!events) {
      Log.warn(TAG, `Failed to find event on channel ${event.channelId} - skipping from loading similar recommendations`);
      return [];
    }
    const recommendations = shuffle(events).slice(0, numberOfSimilarRecommendations);
    Log.debug(TAG, `Loaded ${recommendations.length} similar recommendations for event ${event}`);
    return recommendations;
  }

  private async loadGeneralRecommendations(): Promise<Event[]> {
    Log.trace(TAG, 'Loading general recommendations');
    const now = new Date();
    const minTimestamp = this.findMinTimestamp();
    const availableEventsCount = this.cleanupPastCachedEvents();
    if (!minTimestamp || availableEventsCount === 0 || (now.getTime() - minTimestamp.getTime() >= cacheRefreshInterval)) {
      Log.debug(TAG, 'Updating cached events used for general recommendations');
      await this.updateCachedEvents();
    }
    const events: Event[] = [];
    this.cachedEvents.forEach(entry => {
      events.push(...entry.events);
    });
    const recommendations = shuffle(events).slice(0, numberOfGeneralRecommendations);
    Log.debug(TAG, `Loaded ${recommendations.length} general recommendations`);
    return recommendations;
  }

  public async getRecommendations(params: RecommendationsQueryParameters): Promise<Media[]> {
    if (params.sources.includes('vod')) {
      Log.error(TAG, 'FakeEpgRecommendationsEngine does not support vod recommendations!');
      return [];
    }
    if (params.sources.includes('epg')) {
      return isEvent(params.media)
        ? this.loadSimilarRecommendations(params.media)
        : this.loadGeneralRecommendations();
    }
    Log.error(TAG, 'Source of recommendations not provided!');
    return [];
  }

  private clearCache() {
    this.cachedEvents.clear();
  }
}

export class FakeVodRecommendations implements RecommendationsEngine {
  private movies: Title[];
  private moviesRetrieveTime: number;
  private boAdapter: BOInterface;
  private noSource = false;

  public constructor(adapter: BOInterface) {
    this.boAdapter = adapter;
    this.movies = [];
    this.moviesRetrieveTime = 0;
  }

  private async loadVODRecommendations(params: RecommendationsQueryParameters): Promise<Media[]> {
    if (this.shouldLoadMovies()) {
      const source = await this.boAdapter.getFakeVodRecommendationsSource();
      if (!source) {
        this.noSource = true;
        this.movies = [];
        return [];
      }
      try {
        const result = await this.boAdapter.getContent([source], {pageSize: numberOfRetrievedMovies}).next();
        this.moviesRetrieveTime = Date.now();
        this.movies = result.value as Title[];
      } catch (error) {
        this.movies = [];
        Log.error(TAG, 'Error fetching recommendations:', error);
      }
    }
    if (params.media) {
      let metadata: Metadata | undefined;
      switch (params.media.getType()) {
        case MediaType.Title:
          metadata = (params.media as Title).metadata;
          break;
        case MediaType.Series:
          metadata = (params.media as Series).metadata;
          break;
        case MediaType.Season:
          metadata = (params.media as Series).metadata;
          break;
        case MediaType.Event:
          metadata = (params.media as Event).title.metadata;
          break;
        default:
          break;
      }
      if (metadata && metadata.genres.length) {
        const filterSimilar = genresIntersect({metadata});
        const similarRecommendations = this.movies
          .filter(title =>
            params.media && title.id !== params.media.id &&
            filterSimilar(title));
        if (similarRecommendations.length) {
          return similarRecommendations.slice(0, numberOfSimilarRecommendations);
        }
      }
      return this.movies.filter(m => params.media && m.id !== params.media.id).slice(0, numberOfSimilarRecommendations);
    }
    return shuffle(this.movies).slice(0, numberOfGeneralRecommendations);
  }

  private shouldLoadMovies() {
    return !this.noSource && (this.movies.length === 0 || (Date.now() - this.moviesRetrieveTime > cacheRefreshInterval));
  }

  public async getRecommendations(params: RecommendationsQueryParameters): Promise<Media[]> {
    if (params.sources.includes('epg')) {
      Log.error(TAG, 'FakeVodRecommendationsEngine does not support epg recommendations!');
    }
    if (params.sources.includes('vod')) {
      const results = await this.loadVODRecommendations(params);
      return results;
    }
    Log.error(TAG, 'Source of recommendations not provided!');
    return [];
  }
}
