import {createStyles} from 'common-styles';
import React, {memo, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {dimensions, isMobile, isBigScreen} from 'common/constants';
import {getBuyProducts, getRentProducts} from 'common/HelperFunctions';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {EntitlementState, Title} from 'mw/api/Metadata';
import {nxffConfig} from 'mw/api/NXFF';

import FocusParent from 'components/FocusParent';
import NitroxButton from 'components/NitroxButton';
import {getBuyButtonText, getRentButtonText, isPurchaseAllowed} from 'components/payments/PaymentHelperFunctions';
import {useCountdown, useKeyEventHandler, useFunction, useLazyEffect} from 'hooks/Hooks';
import {PostProcessors} from 'locales/i18nPostProcessors';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flexDirection: 'row'
  },
  buttonStyle: {
    marginRight: dimensions.margins.small
  },
  buttonTextStyle: {
    ...isMobile && {color: colors.popup.text}
  }
}));

type Props = {
  buttonType?: string;
  title?: Title;
  onBingeWatch: () => void;
  onBingeBack?: () => void;
  onBingeStop?: () => void;
  onBuyPress?: () => void;
  onRentPress?: () => void;
}

const BingeButtons: React.FC<Props> = ({buttonType, title, onBingeWatch, onBingeBack, onBingeStop, onBuyPress, onRentPress}) => {
  const {t, i18n} = useTranslation();
  const styles = stylesUpdater.getStyles();

  const {count, counting, stopCounting, startCounting} = useCountdown({initialValue: 10, onFinish: onBingeWatch});
  useLazyEffect(() => {
    startCounting();
  }, [], [startCounting]);

  useLazyEffect(() => {
    if (!title) {
      return;
    }
    if (!title.isEntitled) {
      stopCounting();
    }
  }, [title], [stopCounting]);

  const counter = useMemo(() => counting
    ? ': 00:' + `${count}`.padStart(2, '0')
    : '',
  [count, counting]);

  const onKeyPress = useFunction(() => {
    if (counting) {
      stopCounting();
      onBingeStop?.();
    }
  });

  const {buyText, rentText} = useMemo(() => {
    return {
      buyText: title ? getBuyButtonText(t, i18n, title) : '',
      rentText: title ? getRentButtonText(t, i18n, title) : ''
    };
  }, [i18n, title, t]);

  const {isBuyable, isRentable, purchaseInProgress} = useMemo(() => {
    let isBuyable = false;
    let isRentable = false;
    let purchaseInProgress = false;
    // Do not allow to buy/rent on binge screen at all for ADR8, currently there is no better way do it.
    if (title && nxffConfig.getConfig().Environment.BOType !== 'ADR8') {
      const contents = title.contents.filter(content => content.isAllowed());
      if (title.entitlementState === EntitlementState.EntitlementInProgress) {
        purchaseInProgress = true;
      } else if (isPurchaseAllowed() && !contents.length) {
        isBuyable = !!getBuyProducts(title).length;
        isRentable = !!getRentProducts(title).length;
      }
    }

    return {isBuyable, isRentable, purchaseInProgress};
  }, [title]);

  useKeyEventHandler('keyup', onKeyPress);

  return (
    <FocusParent style={styles.container}>
      {title?.isEntitled && (
        <NitroxButton
          border={!!isBigScreen}
          hasTvPreferredFocus
          text={`${t(`zapper.${buttonType || 'watchNext'}`, {postProcess: PostProcessors.ToUpperCase})}${counter}`}
          style={styles.buttonStyle}
          onPress={onBingeWatch}
        />
      )}
      {isPurchaseAllowed() && (
        <>
          {isBuyable && (
            <NitroxButton
              border={!!isBigScreen}
              hasTvPreferredFocus
              text={buyText}
              style={styles.buttonStyle}
              onPress={onBuyPress}
            />
          )}
          {isRentable && (
            <NitroxButton
              border={!!isBigScreen}
              hasTvPreferredFocus
              text={rentText}
              style={styles.buttonStyle}
              onPress={onRentPress}
            />
          )}
        </>
      )}
      {purchaseInProgress && (
        <NitroxButton
          border={!!isBigScreen}
          text={t('payments.inProgress')}
          style={styles.buttonStyle}
          disabled={true}
        />
      )}
      {onBingeBack && (
        <NitroxButton
          border
          text={t('zapper.cancel', {postProcess: PostProcessors.ToUpperCase})}
          onPress={onBingeBack}
          textStyle={styles.buttonTextStyle}
        />
      )}
    </FocusParent>
  );
};

export default memo(BingeButtons);
