import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {View} from 'react-native';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import ProgressTracker from 'components/ProgressTracker';
import {useNavigation} from 'hooks/Hooks';
import NitroxScreen from 'screens/NitroxScreen';

import ErrorPopup from './ErrorPopup';
import {MobileScreenHeaderProps} from './mobileScreenHeader/MobileScreenHeader';
import ForwardButton from './navigation/ForwardButton';
import {WizardContentProps} from './Wizard';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flex: 1,
    backgroundColor: colors.settingsScreen.background
  },
  progress: {
    backgroundColor: colors.settingsScreen.headerBackground
  },
  safeArea: {
    backgroundColor: colors.settingsScreen.headerBackground,
    flex: 1
  }
}));

const Wizard: React.FC<WizardContentProps> = ({
  currentStep,
  title,
  error,
  onForwardPress,
  onBackPress,
  embedded,
  numberOfSteps,
  stepIndex,
  onErrorPopupClose
}) => {
  const mobileHeader = useMemo<MobileScreenHeaderProps>(() => {
    if (!currentStep) {
      return {};
    }
    const {
      showForwardButton = true,
      forwardButtonText,
      showBackButton = true,
      canGoBack = true,
      mobileHeaderStyle
    } = currentStep;
    return {
      title,
      rightContent: showForwardButton && <ForwardButton onPress={onForwardPress} text={forwardButtonText} />,
      showBackButton,
      hideNativeBackButton: !showBackButton || !canGoBack,
      onBackPress,
      style: mobileHeaderStyle
    };
  }, [title, currentStep, onForwardPress, onBackPress]);

  const styles = stylesUpdater.getStyles();
  const tracker = <ProgressTracker currentStep={stepIndex} numberOfSteps={numberOfSteps} containerStyle={styles.progress} />;
  return (
    <NitroxScreen
      navigation={useNavigation()}
      mobileHeaderProps={mobileHeader}
      embedded={embedded}
      style={styles.safeArea}
    >
      <View style={styles.container}>
        {currentStep?.showStepper && !currentStep?.embedElements && tracker}
        {currentStep?.renderContent?.(...currentStep?.embedElements ? [{tracker}] : [])}
      </View>
      <ErrorPopup error={error} onClose={onErrorPopupClose} />
    </NitroxScreen>
  );
};

export default Wizard;
