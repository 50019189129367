import {TouchableOpacity} from 'react-native';

import {queued} from 'common/Async';
import {debugFeatures, Direction} from 'common/constants';
import {EventEmitter} from 'common/EventEmitter';
import {filterMap, findInMap, reduceMap} from 'common/HelperFunctions';
import {Point, Segment} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {FocusManagerInterface} from 'components/focusManager/FocusManagerInterface';
import {KeyEventManager, NativeKeyEvent, SupportedKeys} from 'components/KeyEventManager';

import {DebuggableFocusNodesMap} from './DebuggableFocusNodesMap';
import {focusNodeToString, isFocusable} from './focusManagerHelpers';
import {NodeGeometry, FocusableComponentProps, FocusNodeProps, FocusNodesMap, CompleteFocusNodesMap, FocusNodePropsComplete, FocusNode, isFocusParentNode, EnterStrategy, FocusResult, isFocusLeafNode, FocusManagerEvent, FocusEventPayload} from './FocusManagerTypes';
import {FocusLeafNode} from './FocusManagerTypes';
import {FocusParentNode} from './FocusParentNode';
import {getGeometry, orthogonalDirection, calculateMetricBetweenPoints, directionalDistanceCalculator, frameDistanceCalculator} from './geometry';

const TAG = 'FocusManagerBase';

/**
 * If this deadline of handling key event is exceeded, event will be ignored.
 */
const focusQueueDeadline = 200;

/**
 * Time threshold, queued task is considered stale after elapsed.
 * Stale focus actions should never occur and should be treated as critical bugs.
 * Hanging promises should not break application navigation though.
 */
const focusQueueStaleThreshold = 3000;

/**
 * Arbitrary timeout to wait when first child registered in focusParent that
 * holds focus. It is to wait for all children currently fetched to display,
 * in order not to focus wrong child due to undeterministic order of renders.
 */
const passFocusOnChildrenRegisterTimeout = 10;

enum Message {
  FocusPropsMissing = 'Could not find focus node\'s props! Did you forget to register it?'
}

export abstract class FocusManagerBase extends EventEmitter<FocusManagerEvent, FocusEventPayload> implements FocusManagerInterface {
  private focusedNode: {current: FocusNode | null; previous: FocusLeafNode | null} = {
    current: null,
    previous: null
  }
  /**
   * Variable stores id of setTimeout function used, while passing focus from parrent,
   * that holds it to new registered children. See FocusParent holdFocus prop.
   */
  private passFocusToChildrenTimeoutId = 0;

  protected get focused(): FocusNode | null {
    return this.focusedNode.current;
  }
  protected set focused(focusedNode: FocusNode | null) {
    if (focusedNode === this.focused) {
      return;
    }
    if (isFocusLeafNode(this.focused)) {
      this.focusedNode.previous = this.focused;
    }
    this.passFocusToChildrenTimeoutId && clearTimeout(this.passFocusToChildrenTimeoutId);
    this.passFocusToChildrenTimeoutId = 0;
    this.focusedNode.current = focusedNode;
  }

  protected get previouslyFocused(): FocusLeafNode | null {
    return this.focusedNode.previous;
  }

  protected nodes: FocusNodesMap = debugFeatures.focusManager ? new DebuggableFocusNodesMap() : new Map();
  private lastFocusedNodes: {[key: number]: FocusNode} = {};
  protected static instance?: FocusManagerBase;

  protected constructor() {
    super();
    Log.info(TAG, 'Initializing FocusManager');

    if (__DEV__) {
      Object.defineProperty(window, 'focusManager', {value: this});
    }

    KeyEventManager.getInstance().addEventListener('keydown', this.keyEventHandler);
  }

  public async updateNodeGeometry(node: FocusNode): Promise<void> {
    const oldProps = this.nodes.get(node);
    if (!oldProps) {
      Log.error(TAG, `updateNodeGeometry: ${Message.FocusPropsMissing}`, focusNodeToString(node));
      return;
    }
    const geometry = await getGeometry(node);
    if (!geometry) {
      Log.error(TAG, `updateNodeGeometry: Could not get the geometry of node`, focusNodeToString(node));
      this.nodes.set(node, {...oldProps, geometry: null});
      return;
    }
    if (!this.nodes.has(node)) { // getGeometry is async so the node may have been unregistered
      return;
    }
    this.nodes.set(node, {...oldProps, geometry});
  }

  public updateNodeParent(node: FocusNode, focusParentId: number): void {
    const oldProps = this.nodes.get(node);
    if (!oldProps) {
      Log.error(TAG, `updateNodeParent: ${Message.FocusPropsMissing}`, focusNodeToString(node));
      return;
    }
    this.nodes.set(node, {...oldProps, focusParentId});
    this.passHeldFocusToChildren(focusParentId);
  }

  public updateNodePriority(node: FocusNode, priority: number): void {
    const oldProps = this.nodes.get(node);
    if (!oldProps) {
      Log.error(TAG, `updateNodePriority:  ${Message.FocusPropsMissing}`, focusNodeToString(node));
      return;
    }
    this.nodes.set(node, {...oldProps, priority});
  }

  public registerNode(node: FocusNode, props: FocusNodeProps): void {
    if (this.nodes.has(node)) {
      Log.warn(TAG, 'registerNode: node already registered!', focusNodeToString(node));
      return;
    }
    this.nodes.set(node, props);
    this.passHeldFocusToChildren(props.focusParentId);
  }

  public unregisterNode(node: FocusNode): void {
    if (!this.nodes.has(node)) {
      Log.warn(TAG, 'unregisterNode: node is not registered!', focusNodeToString(node));
      return;
    }
    const parentId = this.nodes.get(node)?.focusParentId;
    if (parentId != null && this.lastFocusedNodes[parentId] === node) {
      delete this.lastFocusedNodes[parentId];
    }
    if (isFocusParentNode(node)) {
      delete this.lastFocusedNodes[node.id];
    }
    if (this.focused === node) {
      Log.debug(TAG, 'Unregistered focused node.');
      this.focused = null;
    }
    this.nodes.delete(node);
  }

  private enqueueFocusTransition = queued(async (transitionFn: () => Promise<void>) => {
    await transitionFn();
  }, {deadlineAfter: focusQueueDeadline, staleAfter: focusQueueStaleThreshold})

  /**
   * Allow to pass focus to descendants of FocusParent,
   * according to chosen strategy
   *
   * @param id Id of FocusParent
   * @param enterStrategy strategy to choose descendant of FocusParent, if empty property 'enterStrategy' of particular FocusParent is used
   * @param force allow to recalculate focus, when chosen FocusParent is holding it focus, and new descendants register
   */
  public async passFocusToParent(id: number, enterStrategy?: EnterStrategy, force = false): Promise<void> {
    this.enqueueFocusTransition.flush();
    this.enqueueFocusTransition(async () => {
      await this.moveFocusToParent(id, enterStrategy, force);
    });
  }

  private async moveFocusToParent(id: number, enterStrategy?: EnterStrategy, force = false): Promise<void> {
    let next: FocusNode | null;
    let parentId = id;
    let holdFocusParentId = this.getParent(id)?.node.getProps().holdFocus ? id : null;
    let firstIteration = true;
    if (isFocusParentNode(this.focused) && this.focused?.id === id && !force) {
      // Focus already held by the parent
      return;
    }
    while (true) {
      next = await this.findInitialFocusInParent(parentId, firstIteration ? enterStrategy : undefined);
      if (isFocusParentNode(next)) {
        parentId = next.id;
        if (next.getProps().holdFocus) {
          holdFocusParentId = next.id;
        }
      } else if (isFocusLeafNode(next)) {
        await this.focus(next);
        return;
      } else {
        break;
      }
      firstIteration = false;
    }
    //No Focusable descendants.
    holdFocusParentId && this.holdFocus(holdFocusParentId);
  }

  private hasFocusableDescendants(parentId: number): boolean {
    return !!findInMap(this.nodes, (props, node) => {
      if (props.focusParentId !== parentId || !isFocusable(node)) {
        return false;
      }
      return isFocusParentNode(node)
        ? node.getProps().holdFocus || this.hasFocusableDescendants(node.id)
        : true;
    });
  }

  private async holdFocus(parentId: number) {
    const parent = this.getParent(parentId);
    if (!parent) {
      Log.warn(TAG, 'No parent with ID', parentId);
      return;
    }
    if (!parent?.node.getProps().holdFocus) {
      Log.error(TAG, 'Parent with ID', parentId, 'can not hold focus.');
      return;
    }
    await this.blur();
    this.focused = parent.node;
    Log.debug(TAG, 'FocusParent with id:', parentId, 'start to hold focus.');
    if (debugFeatures.focusManager) {
      this.highlightNextPossibleSteps();
    }
  }

  private passHeldFocusToChildren(parentId?: number | null): void {
    if (!isFocusParentNode(this.focused) || parentId == null) {
      return;
    }
    if (this.focused.id !== parentId) {
      this.passHeldFocusToChildren(this.getParent(parentId)?.nodeProps.focusParentId);
      return;
    }
    if (!this.passFocusToChildrenTimeoutId) {
      this.passFocusToChildrenTimeoutId = setTimeout(() => {
        this.passFocusToChildrenTimeoutId = 0;
        this.passFocusToParent(parentId, undefined, true);
      }, passFocusOnChildrenRegisterTimeout);
    }
  }

  public reportFocus(leaf: FocusLeafNode): void {
    const lastFocusedLeaf = isFocusLeafNode(this.focused) ? this.focused : this.previouslyFocused;
    this.notify(FocusManagerEvent.FocusChange, {to: leaf, from: lastFocusedLeaf});
    const parentId = this.nodes.get(leaf)?.focusParentId;
    if (parentId) {
      this.lastFocusedNodes[parentId] = leaf;

      let currentParentId = parentId;
      while (true) {
        const currentParent = this.getParent(currentParentId);
        if (!currentParent) {
          break;
        }
        const nextParentId = currentParent.nodeProps.focusParentId;
        this.lastFocusedNodes[nextParentId] = currentParent.node;
        currentParentId = nextParentId;
      }
    }
    if (this.focused === leaf) {
      return;
    }
    this.focused = leaf;
    if (debugFeatures.focusManager) {
      this.highlightNextPossibleSteps();
    }
  }

  protected cleanup(): void {
    KeyEventManager.getInstance().removeEventListener('keydown', this.keyEventHandler);
  }

  private static findTopLeftNode(components: CompleteFocusNodesMap): FocusNode | null {
    return FocusManagerBase.getFocusableClosestToPoint(components);
  }

  private static findPreferredNodeByPriority(components: CompleteFocusNodesMap): FocusNode | null {
    const maxPriority = reduceMap(components, (max, [, {priority}]) => Math.max(max, priority), 0);
    return maxPriority
      ? this.findTopLeftNode(filterMap(components, props => props.priority === maxPriority))
      : this.findTopLeftNode(components);
  }

  private async moveFocus(direction: Direction): Promise<void> {
    this.enqueueFocusTransition(async () => {
      if (!this.nodes.size) {
        Log.warn(TAG, 'No components to focus, cannot determine where to focus next');
        return;
      }

      if (!this.focused) {
        this.enqueueFocusTransition.flush();
        await this.moveFocusToParent(0);
        if (!this.focused) {
          Log.error(TAG, 'Something went terribly wrong, got non-empty component-geometry map, but could not figure out initial focus.');
          return;
        }
      }

      const focusedNode = this.focused;
      const focusedProps = this.nodes.get(this.focused);

      if (!focusedProps) {
        Log.error(TAG, `moveFocus: ${Message.FocusPropsMissing}`);
        return;
      }
      if (focusedProps.freezeFocus && !focusedProps.exitEdges?.includes(direction)) {
        Log.debug(TAG, 'Focus is imprisoned. To restore it, force it somewhere else.');
        return;
      }
      if (focusedProps.debugName) {
        Log.debug(TAG, `Moving focus from ${focusedProps.debugName}`);
      }
      const focusParentId = focusedProps.focusParentId ?? 0;

      let next: FocusNode | null = null;
      next = await this.nestedSearch({parentId: focusParentId, direction, currentlyFocused: this.focused});
      if (next) {
        if (focusedNode !== this.focused) {
          Log.debug(TAG, 'Focus transition rejected - focused node has changed in the meantime.');
          return;
        }
        if (isFocusParentNode(this.focused) && this.previouslyFocused === next.current) {
          await this.forceFocus(next);
          return;
        }
        await this.focus(next);
        return;
      }

      // couldn't find any focusable siblings nor descendants
      // pass control to ancestors
      let currentParentId = focusParentId;
      while (true) {
        if (!currentParentId) {
          // no more ancestors
          return;
        }
        const currentParent = this.getParent(currentParentId);
        if (!currentParent) {
          Log.error(TAG, `Focus Parent, with ID: ${currentParentId}, is not registered.`);
          return;
        }
        const currentParentProps = currentParent.node.getProps();
        if (currentParentProps.trapFocus && !currentParentProps.trapExitEdges.includes(direction)) {
          Log.debug(TAG, `Focus is trapped. The ${direction} edge is not an trap exit edge.`);
          return;
        }
        currentParentId = currentParent.nodeProps.focusParentId;
        next = await this.nestedSearch({parentId: currentParentId, direction, currentlyFocused: this.focused, excluded: currentParent.node});
        if (next) {
          if (focusedNode !== this.focused) {
            Log.debug(TAG, 'Focus transition rejected - focused node has changed in the meantime.');
            return;
          }
          await this.focus(next);
          return;
        }
      }
    });
  }

  private getParent(id: number): {node: FocusParentNode; nodeProps: FocusNodeProps} | null {
    const found = findInMap(this.nodes, (props, node) => isFocusParentNode(node) && node.id === id);
    return found ? {node: found.key as FocusParentNode, nodeProps: found.value} : null;
  }

  private async nestedSearch(params: {parentId: number; direction: Direction; currentlyFocused: FocusNode; excluded?: FocusNode}): Promise<FocusLeafNode | null> {
    const {parentId: start, direction, currentlyFocused, excluded = null} = params;
    let next: FocusNode | null = null;
    let parentId = start;
    let respectStrategy = false;
    do {
      const parent = this.getParent(parentId);
      const siblings = await this.getComponentsGeometry(this.getComponentsWithinParent(parentId, [currentlyFocused, excluded]));
      if (respectStrategy && parent) {
        next = await this.findInitialFocusInParent(parentId);
      } else if (this.nodes.get(currentlyFocused)?.focusParentId !== parentId) {
        // entering other focus parent
        next = await this.getFocusableInDirection(direction, currentlyFocused, siblings)
          ?? await this.getFocusableInDirection(direction, currentlyFocused, siblings, true);
      } else {
        next = await this.getFocusableInDirection(direction, currentlyFocused, siblings);
      }
      parentId = isFocusParentNode(next) && next.id || 0;
      respectStrategy = true;
    } while (isFocusParentNode(next));
    return next;
  }

  private async findInitialFocusInParent(parentId: number, enterStrategy?: EnterStrategy): Promise<FocusNode | null> {
    const parentProps = this.getParent(parentId)?.node.getProps();
    const lastFocused = this.lastFocusedNodes[parentId];
    if (parentProps?.rememberLastFocused && lastFocused && this.nodes.has(lastFocused) && !enterStrategy) {
      return lastFocused;
    }
    const strategy = enterStrategy ?? parentProps?.enterStrategy ?? 'topLeft';
    switch (strategy) {
      case 'topLeft':
        return FocusManagerBase.findTopLeftNode(await this.getComponentsGeometry(this.getComponentsWithinParent(parentId)));
      case 'byPriority':
        return FocusManagerBase.findPreferredNodeByPriority(await this.getComponentsGeometry(this.getComponentsWithinParent(parentId)));
      default:
        Log.warn(TAG, 'Unrecognized enterStrategy: ', strategy);
        return null;
    }
  }

  protected getFocusedCurrentProps(): FocusableComponentProps | null {
    return (
      isFocusParentNode(this.focused)
        ? this.previouslyFocused?.current?.props
        : this.focused?.current?.props
    ) || null;
  }

  protected getComponentsWithinParent(id: number, excluded: (FocusNode | null)[] = []): FocusNodesMap {
    return filterMap(this.nodes, (props, node) =>
      props.focusParentId === id &&
      !excluded.includes(node) &&
      (
        !isFocusParentNode(node)
        || (!!node.getProps().active && this.hasFocusableDescendants(node.id))
      )
    );
  }

  /** This is the actual focus calculator
   * The algorithm of searching view to be focused next:
   * - consider only elements laying in the shadow of @param start casted in @param direction
   * - take closest view from the center point of @param start to the edge of the view's frame
   * - if more than one frame found, take the one with ceneter point laying closer to the center of @param start in perpendicular axis to the @param direction
   * - if more than one frame found, take the one with lesser x,y coordinates
   */
  private getFocusableInDirection = async (direction: Direction, start: FocusNode, unfilteredComponents: CompleteFocusNodesMap, halfPlane = false): Promise<FocusNode | null> => {
    const startProps = this.nodes.get(start);
    if (!startProps) {
      //FIXME: Make this bullet-proof for screen changing
      Log.error(TAG, 'Lost focus starting point!');
      return null;
    }
    const geometry = startProps.geometry || await getGeometry(start);
    if (!geometry) {
      Log.error(TAG, 'getFocusableInDirection: Could not get the geometry of the start component', focusNodeToString(start));
      return null;
    }
    const components = FocusManagerBase.directionalFilter(geometry, direction, unfilteredComponents, halfPlane);
    if (!components.size) {
      return null;
    }

    return FocusManagerBase.findClosestComponent(geometry.center, components, direction);
  };

  private static findClosestComponent(center: Point, components: CompleteFocusNodesMap, direction: Direction): FocusLeafNode | null {
    if (components.size === 0) {
      return null;
    }
    if (components.size === 1) {
      return components.keys().next().value;
    }

    const calculateDistance = directionalDistanceCalculator(orthogonalDirection(direction));
    const calculateFrameDistance = frameDistanceCalculator(direction);
    let closestComponent: FocusNode | null = null;
    let minFrameDistance = Infinity;
    let minCenterDistance = Infinity;

    components.forEach((nodeProps, nodeRef) => {
      const frameDistance = Math.floor(calculateFrameDistance(center, nodeProps.geometry));
      if (frameDistance < minFrameDistance) {
        minFrameDistance = frameDistance;
        minCenterDistance = Math.floor(calculateDistance(center, nodeProps.geometry.center));
        closestComponent = nodeRef;
        return;
      }
      if (frameDistance === minFrameDistance) {
        const centerDistance = Math.floor(calculateDistance(center, nodeProps.geometry.center));
        if (centerDistance < minCenterDistance) {
          minCenterDistance = centerDistance;
          closestComponent = nodeRef;
          return;
        }
        // In case of equivalent destination rectangles prefer the one with lesser coordinates
        if (centerDistance === minCenterDistance) {
          const dimension = direction === Direction.Up || direction === Direction.Down ? 'x' : 'y';
          if (!closestComponent || (components.get(closestComponent)?.geometry[dimension] ?? Infinity) > nodeProps.geometry[dimension]) {
            closestComponent = nodeRef;
          }
        }
      }
    });

    return closestComponent;
  }

  private static getFocusableClosestToPoint(components: CompleteFocusNodesMap, point: Point = {x: 0, y: 0}): FocusNode | null {
    const minimum: {component: FocusNode | null; value: number} = {component: null, value: Number.POSITIVE_INFINITY};
    components.forEach((props, component) => {
      // TODO: CL-3573 this should be resolved globally via some measured nodes postfilering
      const refValue = isFocusParentNode(component)
        ? component.ref.current
        : component.current;
      if (!refValue) {
        Log.warn(TAG, `Component ${focusNodeToString(component)} has unmounted during measure phase`);
        return;
      }

      const distance = calculateMetricBetweenPoints(props.geometry.center, point);
      if (distance < minimum.value) {
        minimum.component = component;
        minimum.value = distance;
      }
    });
    return minimum.component;
  }

  private keyEventHandler = (event?: NativeKeyEvent) => {
    if (!event) {
      Log.error(TAG, 'Received event which is undefined');
      return;
    }
    if (event.event?.repeat) { //NOTE: iOS repeat isn't supported at the moment
      this.handleKeyRepeat(event);
    } else {
      this.handleKey(event);
    }
  }

  private handleKey = (event: NativeKeyEvent) => {
    switch (event.key) {
      case SupportedKeys.Up:
      case SupportedKeys.Right:
      case SupportedKeys.Down:
      case SupportedKeys.Left:
        Log.trace(TAG, 'Handling focus transition: ' + event.event.toString());
        // TODO: CL-4028 TS does not recognize "sub-enum" relation between SupportedKeys and Direction
        this.moveFocus(event.key as unknown as Direction);
        return;
      default:
        Log.trace(TAG, 'Unsupported key event: ' + event.event.toString());
        return;
    }
  }

  private handleKeyRepeat = (event: NativeKeyEvent) => {
    this.handleKey(event);
  }

  /**
  * Returns list of components that lays "in the shadow" of start component. The shadow is defined as rectangle made by
  * casting @param start component from its position into specified @param direction.
  * @param halfPlane set to true, loosens the filter by only considering position in primary axis.
  *
  * @param {NodeGeometry} start geometry of initially focused component
  * @param {Direction} direction desired focus shift direction
  * @param {CompleteFocusNodesMap} components map of available, focusable components and their respective geometries
  * @param {boolean} halfPlane whether to only take primary axis component position into consideration
  * @returns Filtered CompleteFocusableComponentsMap from which focus should be picked
  */
  private static directionalFilter = (start: NodeGeometry, direction: Direction, components: CompleteFocusNodesMap, halfPlane = false): CompleteFocusNodesMap => {
    const directionalFilterPredicate = FocusManagerBase.directionalFilterPredicate(direction, halfPlane);
    return filterMap(components, (componentProps: FocusNodePropsComplete) => {
      const {geometry: componentGeometry} = componentProps;
      return directionalFilterPredicate(start, componentGeometry);
    });
  };

  private static rangesOverlap(range1: Segment, range2: Segment): boolean {
    return range1.end >= range2.start && range1.start <= range2.end;
  }

  /**
   * @param {Direction} direction determines predicate
   * @param {boolean} halfPlane whether to only take primary axis component position into consideration
   * @returns function that returns true if component should be considered in further calculations
   */
  private static directionalFilterPredicate(direction: Direction, halfPlane = false): (start: NodeGeometry, componentGeometry: NodeGeometry) => boolean {
    switch (direction) {
      case Direction.Up:
        return (start: NodeGeometry, componentGeometry: NodeGeometry): boolean => {
          return (start.center.y > (componentGeometry.y + componentGeometry.height) && (halfPlane || FocusManagerBase.rangesOverlap({start: start.x, end: start.x + start.width}, {start: componentGeometry.x, end: componentGeometry.x + componentGeometry.width})));
        };
      case Direction.Right:
        return (start: NodeGeometry, componentGeometry: NodeGeometry): boolean => {
          return (start.center.x < componentGeometry.x && (halfPlane || FocusManagerBase.rangesOverlap({start: start.y, end: start.y + start.height}, {start: componentGeometry.y, end: componentGeometry.y + componentGeometry.height})));
        };
      case Direction.Down:
        return (start: NodeGeometry, componentGeometry: NodeGeometry): boolean => {
          return (start.center.y < componentGeometry.y && (halfPlane || FocusManagerBase.rangesOverlap({start: start.x, end: start.x + start.width}, {start: componentGeometry.x, end: componentGeometry.x + componentGeometry.width})));
        };
      case Direction.Left:
        return (start: NodeGeometry, componentGeometry: NodeGeometry): boolean => {
          return (start.center.x > (componentGeometry.x + componentGeometry.width) && (halfPlane || FocusManagerBase.rangesOverlap({start: start.y, end: start.y + start.height}, {start: componentGeometry.y, end: componentGeometry.y + componentGeometry.height})));
        };
    }

    throw new Error(`Unsupported direction ${direction}`);
  }

  private readonly supportedDirections: Direction[] = [Direction.Left, Direction.Right, Direction.Up, Direction.Down];

  private highlightNextPossibleSteps() {
    if (!this.focused) {
      return;
    }

    const componentProps = this.nodes.get(this.focused);
    if (!componentProps) {
      return;
    }
    this.supportedDirections.forEach(async direction => {
      if (!this.focused) {
        return;
      } //typescript requires this check even if callback is not asynchronous. This must be TypeScript compilation imperfection issue
      const next = await this.getFocusableInDirection(direction, this.focused, await this.getComponentsGeometry(this.getComponentsWithinParent(componentProps.focusParentId)));
      if (next && !isFocusParentNode(next)) {
        next.current?.highlightAsNext(direction);
      }
    });
  }

  private async getComponentsGeometry(components: FocusNodesMap): Promise<CompleteFocusNodesMap> {
    const result: CompleteFocusNodesMap = new Map<FocusNode, FocusNodePropsComplete>();
    const componentsWithoutGeometry: Promise<{node: FocusNode; props: FocusNodePropsComplete} | null>[] = [];
    components.forEach((props, node) => {
      if (props.geometry) {
        result.set(node, props as FocusNodePropsComplete);
        return;
      }
      const geometryPromise = getGeometry(node);
      componentsWithoutGeometry.push(
        geometryPromise.then(geometry => geometry
          ? {node, props: {...props, geometry}}
          : null
        )
      );
    });

    const componentsWithFetchedGeometry = await Promise.all(componentsWithoutGeometry);
    componentsWithFetchedGeometry.forEach(value => {
      // components that couldn't be measured are filtered out
      value && result.set(value.node, value.props);
    });
    return result;
  }

  protected abstract focus(leaf: FocusLeafNode): Promise<FocusResult>;
  protected abstract blur(leaf?: FocusLeafNode): Promise<FocusResult>;

  public abstract forceFocus(leaf: FocusLeafNode): Promise<FocusResult>;
  public registerAndroidPlaceholderComponent(component: TouchableOpacity): void {}
}
