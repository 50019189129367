import {createStyles} from 'common-styles';
import React, {useState, useCallback} from 'react';
import {ViewStyle, View, TextStyle, StyleProp} from 'react-native';

import {ModalSelectOption, ModalSelect} from 'components/ModalSelect';
import NitroxButton, {ButtonIconProps, NitroxButtonTheme} from 'components/NitroxButton';

import {IconType} from './Icon';
import {TextType} from './NitroxText';

const styles = createStyles({
  container: {
    alignItems: 'flex-start'
  }
});

type Props<T> = {
  label: string;
  options: ModalSelectOption<T>[];
  onSelected: (item: T) => void;
  title?: string;
  theme?: NitroxButtonTheme;
  buttonStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  textStyle?: TextStyle;
  textType?: TextType;
  selectedOptionIcon?: IconType;
  value?: T;
  buttonIcon?: ButtonIconProps;
  renderOptionIcon?: (value: T) => JSX.Element;
  renderButtonChild?: (value: T) => JSX.Element;
}

function ModalSelectorButton<T>(props: Props<T>) {
  const [expanded, setExpanded] = useState(false);

  const onPress = useCallback(() =>
    setExpanded(!expanded)
  , [expanded]);

  return (
    <View style={[styles.container, props.style]}>
      <NitroxButton
        text={props.label}
        theme={props.theme}
        textType={props.textType}
        textStyle={props.textStyle}
        style={props.buttonStyle}
        icon={props.buttonIcon}
        onPress={onPress}
      />
      <ModalSelect
        title={props.title}
        options={props.options}
        selectedOptionIcon={props.selectedOptionIcon}
        value={props.value}
        visible={expanded}
        renderOptionIcon={props.renderOptionIcon}
        renderButtonChild={props.renderButtonChild}
        onChange={item => {
          setExpanded(false);
          props.onSelected(item);
        }}
        onClose={() => setExpanded(false)}
      />
    </View>
  );
}
export default ModalSelectorButton;
