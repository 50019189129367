import {NavigationScreenProp, NavigationRoute, NavigationParams} from 'react-navigation';

import {useNavigation, useFunction} from './Hooks';

type TabBarState = {
  /**
   * Index of the tab that the component is rendered in.
   */
  thisTabIndex: number;
  /**
   * Index of currently selected tab in tab navigator.
   */
  currentTabIndex: number;
}

export function getTabBarState(navigation: NavigationScreenProp<NavigationRoute<NavigationParams>, NavigationParams>): TabBarState | null {
  const stackNavigator = navigation?.dangerouslyGetParent();
  const tabNavigator = stackNavigator?.dangerouslyGetParent();
  const thisTabIndex = tabNavigator?.state.routes.findIndex(route => route.key === stackNavigator?.state.key);
  if (!stackNavigator || !tabNavigator || thisTabIndex == null || thisTabIndex < 0) {
    return null;
  }
  return {
    thisTabIndex,
    currentTabIndex: tabNavigator.state.index
  };
}

/**
 * Use this hook to get information about which tab is currently selected in tab navigator
 * and in which tab is the component rendered.
 * This hook assumes that the app consists of a tab navigator with a separate stack navigator
 * inside each tab and that we're calling it from a component rendered inside one of the stacks.
 */
export function useTabBarState(): () => TabBarState | null {
  const navigation = useNavigation();
  return useFunction(() => getTabBarState(navigation));
}
