import {createStyles} from 'common-styles';
import React from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';

import {isBigScreen, RADIUS} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Channel} from 'mw/api/Metadata';

import NitroxText from './NitroxText';

export const channelNumberSize = isBigScreen ? 35 : 27;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    backgroundColor: colors.channelNumber.background,
    minWidth: channelNumberSize,
    height: channelNumberSize,
    borderRadius: RADIUS,
    alignItems: 'center',
    justifyContent: 'center'
  },
  text: {
    color: colors.channelNumber.text
  }
}));

type Props = {
  channel: Channel;
  styles?: StyleProp<ViewStyle>;
}

const ChannelNumber: React.FC<Props> = props => {
  const styles = stylesUpdater.getStyles();
  return (
    <View style={[styles.container, props.styles]} testID='channel_number'>
      <NitroxText style={styles.text} textType='callout'>{props.channel.lcn}</NitroxText>
    </View>
  );
};

export default React.memo(ChannelNumber);
