import {createStyles} from 'common-styles';
import React from 'react';
import {StyleProp, ViewStyle} from 'react-native';

import {dimensions, isMobile} from 'common/constants';

import {ButtonIconProps} from 'components/NitroxButton';
import SettingsButton from 'screens/settings/SettingsButton';
import SettingsLabel from 'screens/settings/SettingsLabel';

import FocusParent from './FocusParent';

const styles = createStyles({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    height: dimensions.buttons.large,
    marginVertical: dimensions.margins.large
  }
});

type Props = {
  description: string;
  label: string;
  style?: StyleProp<ViewStyle>;
  icon?: ButtonIconProps;
  onPress?: () => void;
  disabled?: boolean;
}

const WizardButton: React.FC<Props> = ({
  description,
  label,
  style,
  icon,
  onPress,
  disabled
}) => (
  <FocusParent style={[styles.container, style]}>
    <SettingsLabel label={description} disabled={disabled} />
    <SettingsButton
      text={label}
      icon={icon}
      onPress={onPress}
      disabled={disabled}
      useOldImplementation={isMobile}
    />
  </FocusParent>
);

export default React.memo(WizardButton);
