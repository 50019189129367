import {Picture} from 'mw/api/Metadata';

export class PictureMapper {
  public static toPicture(json: any): Picture {
    if (json.resolution) {
      const [width, height] = json.resolution.split('x');
      return new Picture(json.type, json.Value, Number.parseFloat(width), Number.parseFloat(height));
    }

    return new Picture(json.type, json.Value);
  }

  public static toPictures(jsonArray: any[]): Picture[] {
    return jsonArray.map(PictureMapper.toPicture);
  }
}
