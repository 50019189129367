import {CancelEvent, cancelablePipeline, CancelResponse} from 'common/Async';
import {EventEmitter} from 'common/EventEmitter';
import {Log} from 'common/Log';

import {Errors, ErrorType, Error as MWError} from 'mw/api/Error';
import {Media, MediaType, PlaybackLimitations, ContentType, PlaybackStopReason} from 'mw/api/Metadata';
import {PlayerEvent, StopParams, Track, TracksList} from 'mw/api/PlayerEvent';

import {AssetBasedTstvPlayer} from './controllers/AssetBasedTstvPlayer';
import {AudioPlayer} from './controllers/AudioPlayer';
import {InvalidPlayer} from './controllers/InvalidPlayer';
import {LivePlayer} from './controllers/LivePlayer';
import {NPVRPlayer} from './controllers/NPVRPlayer';
import {PlaybackController, RewindDirection} from './controllers/PlaybackController';
import {VODPlayer} from './controllers/VODPlayer';
import {PlaybackEvent} from './types/PlaybackEvent';
import {isPlaybackParametersEqual, PlaybackParameters, PlaybackRequestParameters, PlaybackResponse} from './types/PlaybackParameters';
import {PlayerType} from './types/PlayerType';

export {isPlaybackParametersEqual, PlaybackParameters, PlaybackRequestParameters, RewindDirection};

const TAG = 'Player';

export class Player extends EventEmitter<PlayerEvent> {

  private playbackController: PlaybackController = InvalidPlayer.getInstance();
  private playerType: PlayerType;
  private streamId = -1;
  private queuedEvents: PlaybackEvent[] = [];
  private viewId?: number;
  private startingPlayback = false;
  private static readonly MAX_QUEUED_EVENTS: number = 10;

  private runningTask: {
    type: 'start' | 'stop';
    promise: Promise<void>;
    cancel?: () => void;
  } | null = null

  private awaitingTask: {
    type: 'start' | 'stop';
    task: () => Promise<void>;
    /**
     * Call this when replacing awaitingTask with other one.
     * This allows proper promise rejecting/resolving when task is ignored.
     */
    dispose?: () => void;
  } | null = null

  private onTaskDone = () => {
    this.runningTask = null;
    if (this.awaitingTask) {
      this.awaitingTask.task();
      this.awaitingTask = null;
    }
  }

  public getCurrentMedia(): Media | null {
    return this.playbackController.getCurrentMedia();
  }

  public constructor(playerType: PlayerType) {
    super();
    this.playerType = playerType;
  }

  public isPlayerInitialized = (): boolean => this.playbackController !== InvalidPlayer.getInstance();

  /**
   * Player .start and .stop methods are mutually synchronized.
   * Consider these method calls as `Tasks`.
   * Tasks never run in parallel - each call either enqueues task or ignores it and immediately rejects (`start`) or synchronizes promise resolution (`stop`).
   * Enqueued `start` is overriden when new `start` call occurs.
   * Enqueued `start` is dequeued when new `stop` call occurs.
   * Running `start` is canceled when new `start`/`stop` occurs.
   * Running `stop` is never canceled.
   * Calling `start` during running `stop`, enqueues `start`.
   * Calling `stop` during running `stop` is ignored (but resolved properly when running `stop` resolves).
   */
  public start = (viewId: number, source: Media, parameters: PlaybackRequestParameters): Promise<void> => {
    Log.info(TAG, 'Start called on player:', this.playerType);
    if (this.runningTask) {
      return new Promise((resolve, reject) => {
        // Clear awaiting task if exists.
        this.awaitingTask?.dispose?.();

        // Enqueue start, resolve/reject this promise whenever enqueued one does.
        this.awaitingTask = {
          task: () => this
            .start(viewId, source, parameters)
            .then(resolve)
            .catch(reject),
          type: 'start',
          dispose: () => reject(new MWError(ErrorType.OperationCanceled))
        };

        this.runningTask?.cancel?.();
      });
    }

    const promise = this.cancelableStart(viewId, source, parameters);

    this.runningTask = {
      type: 'start',
      cancel: this.cancelableStart.cancel,
      promise
    };

    return promise.finally(this.onTaskDone);
  }

  public cancelableStart = cancelablePipeline(
    async (viewId: number, source: Media, parameters: PlaybackRequestParameters) => {
      if (!source) {
        throw Errors.PlayerSourceMissing;
      }

      await this.executeStop();
      return {viewId, source, parameters};
    },
    async ({viewId, source, parameters}: {viewId: number; source: Media; parameters: PlaybackRequestParameters}) => {
      try {
        const playbackController = this.getPlaybackController(source, parameters);
        this.playbackController = playbackController;
        this.viewId = viewId;
        Log.debug(TAG, 'Got controller for source: ', source, this.playbackController);

        playbackController.connect((playerEvent: PlayerEvent, params?: any) => this.notify(playerEvent, params));

        this.startingPlayback = true;

        const cancelInitializeOnCancel = () => playbackController.cancelableInitialize.cancel();
        this.cancelableStart.once(CancelEvent.Cancel, cancelInitializeOnCancel);
        this.streamId = (await playbackController.initialize(viewId, source, parameters)).streamId;
        this.cancelableStart.off(CancelEvent.Cancel, cancelInitializeOnCancel);

        this.startingPlayback = false;

        /* Some events (eg. buffering) may trigger before initialize call returns, so queue them */
        let event: PlaybackEvent | undefined;
        while (event = this.queuedEvents.shift()) {
          this.handleEvent(event);
        }

        // player view changed while playback was starting
        if (this.viewId !== viewId) {
          await this.switchPlayerView(this.viewId);
        }
      } catch (error) {
        Log.error(TAG, 'Teardown all sessions on failure', error);
        const stopReason = error instanceof CancelResponse ? {reason: PlaybackStopReason.UserAction} : {reason: PlaybackStopReason.Error, error};

        await this.executeStop(stopReason);

        if (!(error instanceof CancelResponse)) {
          this.notify(PlayerEvent.Error, error);
        }

        throw error;
      }
    },
    async () => {
      Log.info(TAG, 'Started player:', this.playerType);
    }
  )

  public switchPlayerView(viewId: number): Promise<PlaybackResponse> {
    Log.info(TAG, `switchPlayerView(${viewId}) of player:`, this.playerType);
    this.viewId = viewId;
    return this.playbackController.switchPlayerView(viewId, this.playerType);
  }

  public getPlayerViewId(): number | undefined {
    return this.playbackController.getPlayerViewId();
  }

  /**
   * Internal stop implementation that omits .start calls cleanup.
   */
  private async executeStop(params: StopParams = {reason: PlaybackStopReason.UserAction}): Promise<void> {
    try {
      this.queuedEvents = [];
      this.viewId = undefined;
      this.startingPlayback = false;
      await this.playbackController.stop(params);
      Log.info(TAG, 'Stopped player:', this.playerType);
    } catch (e) {
      Log.warn(TAG, 'Error stopping playback', e);
    } finally {
      this.playbackController.disconnect();
      this.playbackController = InvalidPlayer.getInstance();
    }
  }

  public stop(reason: PlaybackStopReason = PlaybackStopReason.UserAction): Promise<void> {
    Log.info(TAG, 'Stop called on player:', this.playerType);
    if (this.runningTask) {
      if (this.runningTask.type === 'stop') {
        // Stop is already running. Cancel awaiting start() task.
        Log.info(TAG, 'Stop is already being handled by player:', this.playerType);
        this.awaitingTask?.dispose?.();
        this.awaitingTask = null;

        return this.runningTask.promise;
      }

      return new Promise((resolve, reject) => {
        // Chain with awaiting stop task to synchronize promises.
        Log.info(TAG, 'There is already stop command pending in player:', this.playerType);
        if (this.awaitingTask?.type === 'stop') {
          const task = this.awaitingTask.task;

          this.awaitingTask.task = () => task()
            .then(resolve)
            .catch(reject);
          return;
        }

        this.awaitingTask?.dispose?.();

        // Schedule stop after start.cancel() finishes.
        this.awaitingTask = {
          type: 'stop',
          task: () => this.stop(reason)
            .then(resolve)
            .catch(reject)
        };

        this.runningTask?.cancel?.();
      });
    }

    const promise = this.executeStop({reason});

    this.runningTask = {
      type: 'stop',
      promise
      // no .cancel() provided, as stop should never be canceled
    };

    return promise.finally(this.onTaskDone);
  }

  public changeParameters(parameters: PlaybackRequestParameters): Promise<PlaybackResponse> {
    Log.info(TAG, 'Changing parameters of', this.playerType, 'player to:', parameters);
    return this.playbackController.changeParameters(parameters);
  }

  public getParameters(): PlaybackParameters {
    return this.playbackController.getPlaybackParameters();
  }

  public startRewind(direction: RewindDirection): Promise<void> {
    return this.playbackController.startRewind(direction);
  }

  public stopRewind(): Promise<void> {
    return this.playbackController.stopRewind();
  }

  public isRewinding(): boolean {
    return this.playbackController.isRewinding();
  }

  public getRewindDirection(): RewindDirection | undefined {
    return this.playbackController.getRewindDirection();
  }

  public getPlaybackLimitations(): PlaybackLimitations {
    return this.playbackController.getPlaybackLimitations();
  }

  public handleEvent(playbackEvent: PlaybackEvent): void {
    if (this.startingPlayback) {
      if (this.queuedEvents.length < Player.MAX_QUEUED_EVENTS || playbackEvent.eventType === PlayerEvent.Error) {
        this.queuedEvents.push(playbackEvent);
      } else {
        Log.warn(TAG, 'Dropping playback event:', playbackEvent);
      }
      return;
    }
    if (playbackEvent.streamId !== this.streamId) {
      Log.info(TAG, 'Caught event for unowned stream ID:', playbackEvent.streamId);
      return;
    }
    switch (playbackEvent.eventType) {
      case PlayerEvent.AudioLanguagesChanged:
        if (playbackEvent.audioLanguagesChanged) {
          this.playbackController.onAudioLanguagesChanged(playbackEvent.audioLanguagesChanged);
        }
        break;

      case PlayerEvent.BeginOfContent:
        if (playbackEvent.beginOfContent) {
          this.playbackController.onBeginOfContent(playbackEvent.beginOfContent);
        }
        break;

      case PlayerEvent.Buffering:
        if (playbackEvent.buffering) {
          this.playbackController.onBuffering(playbackEvent.buffering);
        }
        break;

      case PlayerEvent.EndOfContent:
        if (playbackEvent.endOfContent) {
          this.playbackController.onEndOfContent(playbackEvent.endOfContent);
        }
        break;

      case PlayerEvent.Error:
        if (playbackEvent.error) {
          // On iOS error code is delivered as string from native implementation, convert it to number.
          this.playbackController.onError({
            error: isNaN(playbackEvent.error.error) ? ErrorType.NativePlayerUnknownError : Number(playbackEvent.error.error),
            message: playbackEvent.error.message,
            extra: playbackEvent.error.extra
          });
        }
        break;

      case PlayerEvent.FirstFrame:
        if (playbackEvent.firstFrame && this.playbackController.validateUrl(playbackEvent.firstFrame.url)) {
          this.playbackController.onFirstFrame(playbackEvent.firstFrame);
        }
        break;

      case PlayerEvent.PositionChanged:
        if (playbackEvent.positionChanged) {
          this.playbackController.positionChanged(playbackEvent.positionChanged);
        }
        break;

      case PlayerEvent.Ready:
        if (playbackEvent.ready) {
          this.playbackController.onReady(playbackEvent.ready);
        }
        break;

      case PlayerEvent.Stopped:
        if (playbackEvent.stopped && this.playbackController.validateUrl(playbackEvent.stopped.url)) {
          this.playbackController.onStopped(playbackEvent.stopped);
        }
        break;

      case PlayerEvent.SubtitlesChanged:
        if (playbackEvent.subtitlesChanged) {
          this.playbackController.onSubtitlesChanged(playbackEvent.subtitlesChanged);
        }
        break;

      case PlayerEvent.VideoResolutionsChanged:
        break;

      default:
        Log.info(TAG, 'handleEvent: event not supported:', playbackEvent.eventType);
        break;
    }
  }

  private getPlaybackController(source: Media, parameters: PlaybackRequestParameters): PlaybackController {
    Log.debug(TAG, 'getPlaybackController: ', source, parameters);
    switch (source.getType()) {
      case MediaType.Recording:
        return new NPVRPlayer(this.playerType);

      case MediaType.Title:
        return new VODPlayer(this.playerType);

      case MediaType.Channel:
        return new LivePlayer(this.playerType);

      case MediaType.Event:
        return new AssetBasedTstvPlayer(this.playerType);

      case MediaType.Audio:
        return new AudioPlayer(this.playerType);

      default:
        return InvalidPlayer.getInstance();
    }
  }

  public getAudioLanguages(): TracksList {
    return this.playbackController.getAudioLanguages();
  }

  public getSubtitleLanguages(): TracksList {
    return this.playbackController.getSubtitleLanguages();
  }

  public setCurrentAudioLanguageTrack(track: Track): Promise<void> {
    Log.info(TAG, 'Changing audio track of player', this.playerType, 'to:', track);
    return this.playbackController.setCurrentAudioLanguageTrack(this.playerType, track);
  }

  public setCurrentSubtitleTrack(track: Track): Promise<void> {
    Log.info(TAG, 'Changing subtitle track of player', this.playerType, 'to:', track);
    return this.playbackController.setCurrentSubtitleTrack(this.playerType, track);
  }

  public get contentType(): ContentType {
    return this.playbackController.getContentType();
  }

  public get playbackStartTime(): Date | null {
    return this.playbackController.getPlaybackStartTime();
  }

  public setAudioMuted(muted: boolean): Promise<PlaybackResponse> {
    return this.playbackController.setAudioMuted(muted);
  }

  public isAudioMuted(): boolean {
    return this.playbackController.isAudioMuted();
  }

  public get isPCBlocked(): boolean {
    return this.playbackController.isPCBlocked();
  }

  public unblockPC(): Promise<void> {
    return this.playbackController.setPCBlocked(false);
  }

  public blockPC(): Promise<void> {
    return this.playbackController.setPCBlocked(true);
  }
}
