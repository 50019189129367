import {createStyles} from 'common-styles';
import React, {useMemo, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ActivityIndicator, StyleSheet} from 'react-native';
import {NavigationScreenProps} from 'react-navigation';

import {isBigScreen, isTablet, dimensions, isMobile, AppRoutes, Direction} from 'common/constants';
import {openMediaDetails} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {RecordingStatus, Recording, isSeriesRecording, SeriesType, PVREvent, isSingleRecording} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import {BigScreenHeaderProps, BigScreenTitlePosition, BigScreenBackButtonPosition} from 'components/BigScreenHeader';
import {useDeleteSelectionMenu} from 'components/deleteSelectionMenu/useDeleteSelectionMenu';
import FocusParent, {useFocusParent} from 'components/FocusParent';
import {MobileScreenHeaderProps} from 'components/mobileScreenHeader/MobileScreenHeader';
import {STBMenuState} from 'components/navigation/NavigationHelperTypes';
import {useRecord} from 'components/pvr/Record';
import {Selection} from 'components/utils/Selection';
import {useNavigationParam, useNavigation, useDisposableState, useEventListener, useLazyEffect, useChangeEffect} from 'hooks/Hooks';
import i18n from 'locales/i18n';
import NitroxScreen from 'screens/NitroxScreen';
import {deleteSelectionMenuStyle} from 'screens/recordings/RecordingsScreen';

import {RecordingsFolderContent} from './RecordingsFolderContent';
import RecordingsManagementButtons, {Action} from './RecordingsManagementButtons';

const TAG = 'RecordingsFolder';

function screenTitle(recording: Recording, t: i18n.TFunction) {
  const seasonNumber = isSeriesRecording(recording) && recording.series.seriesType === SeriesType.Season ? recording.series.seasonNumber : undefined;
  let suffix = '';
  if (seasonNumber) {
    const separator = isBigScreen || isTablet ? ' - ' : '\n';
    suffix = `${separator}${t('common.seasonNumberFull', {seasonNumber})}`;
  }
  return `${recording.name}${suffix}`;
}

const styles = createStyles({
  container: {
    flex: 1,
    width: '100%'
  },
  bigScreenDeleteSection: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  screen: {
    ...isBigScreen && {
      paddingHorizontal: dimensions.mainMenu.marginHorizontal
    }
  }
});

type Props = {
  seriesRecording: Recording;
  topLevelSeriesRecording: Recording;
  status: RecordingStatus;
  onMoreActionsPress: (recording: Recording) => void;
};

const RecordingsFolder: React.FC<NavigationScreenProps<Props>> = React.memo(() => {
  const {t} = useTranslation();
  const navigation = useNavigation();
  const [series, setSeries] = useState(useNavigationParam<Props>('seriesRecording') as Recording);
  const [topLevelSeriesRecording, setTopLevelSeriesRecording] = useState(useNavigationParam<Props>('topLevelSeriesRecording') as Recording);
  const isSeason = isSeriesRecording(series) && series.series.seriesType === SeriesType.Season;
  const status = useNavigationParam<Props>('status') as RecordingStatus;
  const onMoreActionsPress = useNavigationParam<Props>('onMoreActionsPress') as (recording: Recording) => void;
  const [children, setChildren] = useDisposableState<Recording[]>([]);
  const [fetching, setFetching] = useDisposableState(false);
  const [onSelectionMenuParentReady, focusSelectionMenu] = useFocusParent();
  const {
    renderRecordingsComponents,
    cancelRecording,
    isSeriesCancelAvailable,
    isParamsChangeAvailableForRecording,
    changeRecordingsParams,
    resumeRecording,
    isResumeAvailable
  } = useRecord();

  const recordingsManagementActions = useMemo(() => {
    const actions: Action[] = [];

    if (isResumeAvailable(topLevelSeriesRecording)) {
      actions.push({
        key: 'resumeSeries',
        text: t('common.resumeSeries'),
        onPress: () => resumeRecording(topLevelSeriesRecording)
      });
    } else {
      if (isParamsChangeAvailableForRecording(topLevelSeriesRecording)) {
        actions.push({
          key: 'recordSeries',
          text: t('common.record'),
          onPress: () => changeRecordingsParams(topLevelSeriesRecording)
        });
      }

      if (isSeriesCancelAvailable(topLevelSeriesRecording)) {
        actions.push({
          key: 'cancelSeries',
          text: t('recordings.cancelSeries'),
          onPress: () => cancelRecording(topLevelSeriesRecording)
        });
      }
    }

    return actions;
  }, [isResumeAvailable, topLevelSeriesRecording, isParamsChangeAvailableForRecording, isSeriesCancelAvailable, t, resumeRecording, changeRecordingsParams, cancelRecording]);

  const sortRecordings = useCallback((recordings: Recording[]): Recording[] => {
    if (status === RecordingStatus.Scheduled) {
      return recordings;
    }
    const episodeNumber = (recording: Recording) => recording.event?.title.episode?.number ?? 0;
    const seasonNumber = (recording: Recording) => recording.series?.seasonNumber ?? 0;
    return recordings.sort((lhs, rhs) =>
      episodeNumber(lhs) <= episodeNumber(rhs) && seasonNumber(lhs) <= seasonNumber(rhs)
        ? -1
        : 1
    );
  }, [status]);

  const refreshData = useCallback((isInitial = false) => {
    setFetching(true);
    if (!isInitial) {
      mw.pvr.getRecordings({id: series.id})
        .then(result => setSeries(result[0]))
        .catch(error => {
          Log.error(TAG, `Error fetching recording for series (${series}): `, error);
        });

      mw.pvr.getRecordings({id: topLevelSeriesRecording.id})
        .then(result => setTopLevelSeriesRecording(result[0]))
        .catch(error => {
          Log.error(TAG, `Error fetching recording for parent series (${topLevelSeriesRecording}): `, error);
        });
    }

    mw.pvr.getRecordings({
      parentRecording: series,
      status,
      ...(status === RecordingStatus.Scheduled) && {
        sortBy: mw.pvr.defaultSorting(status)
      }
    })
      .then(sortRecordings)
      .then(children => {
        setChildren(children);
        if (!children.length && !isInitial && navigation.isFocused()) {
          navigation.goBack();
        }
      })
      .catch(error => {
        Log.error(TAG, `Error fetching child recordings of status ${RecordingStatus[status]} for series (${series}): `, error);
      })
      .finally(() => {
        setFetching(false);
      });

  }, [setFetching, series, status, sortRecordings, topLevelSeriesRecording, setChildren, navigation]);

  // fetch on mount
  useLazyEffect(() => {
    refreshData(true);
  }, [], [refreshData]);

  // refresh data on navigating back to this screen
  useEventListener(PVREvent.PVRRecordingsChanged, refreshData, mw.pvr);

  const deleteSelected = useCallback((selection: Selection<Recording>): Promise<void> => {
    const selected = selection.areAllSelected() ? children : selection.getSelectedItems();
    if (!selected.length) {
      return Promise.resolve();
    }

    const onDeleted = () => {
      if (selected.length === children.length) {
        navigation.goBack();
      }
    };

    setFetching(true);
    return mw.pvr.deleteRecordings(selected)
      .then(onDeleted)
      .catch(error => {
        Log.error(TAG, 'Error deleting child recordings: ', error);
      }).finally(() => setFetching(false));
  }, [setFetching, children, navigation]);

  const {selectionMenu, confirmationPopup, selectionMenuActive, selection, onSelectItem} = useDeleteSelectionMenu<Recording>({
    items: children,
    hasMoreItems: false,
    deleteSelected,
    style: deleteSelectionMenuStyle,
    confirmationPopupParams: {
      title: selection => t('recordings.deleteConfirmationTitle', {count: selection.getSelectedSize()}),
      info: selection => t('recordings.deleteConfirmationInfo', {count: selection.getSelectedSize()})
    }
  });

  const title = useMemo(() => screenTitle(series, t), [series, t]);

  const mobileHeaderProps: MobileScreenHeaderProps = useMemo(() => ({
    title: selectionMenuActive ? undefined : title,
    ...isSeason && {
      rightContent: selectionMenu,
      style: {height: 1.5 * dimensions.screen.header.height}
    },
    showBackButton: true,
    // we provide our own back button to align it vertically
    // when the header title takes 2 lines
    hideNativeBackButton: true,
    onBackPress: () => navigation.pop()
  }), [title, selectionMenu, selectionMenuActive, navigation, isSeason]);

  const bigScreenHeaderProps: BigScreenHeaderProps = useMemo(() => ({
    title,
    titlePosition: BigScreenTitlePosition.Left,
    showBackButton: true,
    backButtonPosition: BigScreenBackButtonPosition.Left
  }), [title]);

  const trapExitEdges: Direction[] = useMemo(() => [Direction.Left], []);

  const onTilePress = useCallback((recording: Recording) => {
    if (isSingleRecording(recording)) {
      openMediaDetails(navigation, recording.event.id, recording.getType());
    } else if (isSeriesRecording(recording)) {
      navigation.push(
        AppRoutes.RecordingsFolder,
        {
          seriesRecording: recording,
          topLevelSeriesRecording,
          status
        }
      );
    }
  }, [navigation, status, topLevelSeriesRecording]);

  useChangeEffect(() => {
    focusSelectionMenu();
  }, [selectionMenuActive]);

  return (
    <FocusParent
      trapFocus
      trapExitEdges={trapExitEdges}
      style={styles.container}
    >
      <NitroxScreen
        style={styles.screen}
        navigation={navigation}
        mobileHeaderProps={mobileHeaderProps}
        bigScreenHeaderProps={bigScreenHeaderProps}
        showBigScreenHeader
        popStackOnBack
        menuState={STBMenuState.Above}
      >
        {isBigScreen && (
          <FocusParent style={styles.bigScreenDeleteSection} onReady={onSelectionMenuParentReady}>
            {!selectionMenuActive && !!recordingsManagementActions.length && <RecordingsManagementButtons actions={recordingsManagementActions} />}
            {isSeason && selectionMenu}
          </FocusParent>
        )}
        {isMobile && !!recordingsManagementActions.length && <RecordingsManagementButtons actions={!selectionMenuActive ? recordingsManagementActions : []} />}
        <RecordingsFolderContent
          recordings={children}
          selectable={selectionMenuActive}
          selection={selection}
          onTilePress={onTilePress}
          onMoreActionsPress={onMoreActionsPress}
          selectRecording={onSelectItem}
        />
        {fetching && <ActivityIndicator animating size='large' style={StyleSheet.absoluteFill} />}
        {confirmationPopup}
        {renderRecordingsComponents()}
      </NitroxScreen>
    </FocusParent>
  );
});
RecordingsFolder.displayName = 'RecordingsFolder';

export default RecordingsFolder;
