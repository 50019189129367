import {createStyles} from 'common-styles';
import React, {useState, useCallback, useRef, useMemo, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {ActivityIndicator} from 'react-native';

import {getValue, dimensions} from 'common/constants';
import {isSeriesEpisode} from 'common/HelperFunctions';
import {useSafeAwait, useEventListener} from 'common/hooks/Hooks';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Error, ErrorType} from 'mw/api/Error';
import {Event, RecordingType, PVRScheduleParameters, PVRRecordingScope, Recording, isSeriesRecording, RecordingStatus, PVREvent, PVRUpdateParameters, Series, PVRGuardTimes} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import NitroxText from 'components/NitroxText';
import Popup, {PopupAction} from 'components/Popup';
import {SelectionPopupOption} from 'components/SelectionPopup';
import {useToggle, useFunction} from 'hooks/Hooks';

import RecordingConfigPopup, {getStartingSeasonLabel} from './RecordingConfigPopup';
import {RelatedRecordings, RecordOption, getRecordingsStatus, RelatedRecordingsStatus, hasAvailableGuardTimes} from './RecordingUtils';

const TAG = 'RecordingHooks';

type UseRecordingsMetadata = {
  loading: boolean;
  clearRecordingsMetadata: () => void;
  setCurrentEvent: (event: Event) => Promise<RelatedRecordings | undefined>;
  relatedRecordings?: RelatedRecordings;
  currentEvent?: Event;
  currentSeasons?: Series[];
};

export function useRecordingsMetadata(): UseRecordingsMetadata {
  const safeAwait = useSafeAwait();
  const [loading, {on: showLoader, off: hideLoader}] = useToggle(false);
  const [relatedRecordings, setRelatedRecordings] = useState<RelatedRecordings>();
  const [currentEvent, setEvent] = useState<Event>();
  const [currentSeasons, setSeasons] = useState<Series[]>();

  const clearRecordingsMetadata = useFunction(() => {
    setEvent(undefined);
    setRelatedRecordings(undefined);
    hideLoader;
  });

  // Clear loaded data on PVRRecordingsChanged event
  useEventListener(PVREvent.PVRRecordingsChanged, clearRecordingsMetadata, mw.pvr);

  const fetchRelatedRecordings = useCallback(async (event: Event): Promise<RelatedRecordings> => {
    const recordings = await safeAwait(mw.pvr.getRecordings({media: event}).catch(() => []));

    return {
      eventRecording: recordings.find(recording => recording.recordingType === RecordingType.Single),
      seriesRecording: recordings.find(recording => recording.recordingType === RecordingType.Series)
    };
  }, [safeAwait]);

  const setCurrentEvent = useCallback(async (event: Event): Promise<RelatedRecordings | undefined> => {
    if (event?.id === currentEvent?.id) {
      return relatedRecordings;
    }
    clearRecordingsMetadata();
    showLoader();
    setEvent(event);
    if (event.title.episode && isSeriesEpisode(event.title)) {
      setSeasons(await mw.catalog.getSeriesSeasonsById(event.title.episode.seriesId));
    }
    return fetchRelatedRecordings(event).then(relatedRecordings => {
      setRelatedRecordings(relatedRecordings);
      hideLoader();
      return relatedRecordings;
    });
  }, [clearRecordingsMetadata, currentEvent, fetchRelatedRecordings, hideLoader, relatedRecordings, showLoader]);

  return {loading, relatedRecordings, clearRecordingsMetadata, setCurrentEvent, currentEvent, currentSeasons};
}

type UseScheduleRecording = {
  scheduleRecording: (event: Event) => Promise<void>;
  renderSeriesRecordingModal: (onModalClose?: () => void) => JSX.Element;
}

type UseGuardTimes = {
  setGuardTimeStart: React.Dispatch<React.SetStateAction<number>>;
  setGuardTimeEnd: React.Dispatch<React.SetStateAction<number>>;
  clearGuardTimes: () => void;
  getGuardTimeForRelatedRecordings: () => PVRGuardTimes | undefined;
  getNewGuardTimeValues: () => PVRGuardTimes | undefined;
};

export function useGuardTimes(eventRecording?: Recording, seriesRecording?: Recording, event?: Event): UseGuardTimes {
  const [guardTimeStart, setGuardTimeStart] = useState<number>(0);
  const [guardTimeEnd, setGuardTimeEnd] = useState<number>(0);

  const getGuardTimeForRelatedRecordings = useCallback((): PVRGuardTimes | undefined => {
    let currentGuardTime;
    if (seriesRecording) {
      currentGuardTime = {
        start: seriesRecording.guardTimeStart,
        end: seriesRecording.guardTimeEnd
      };
    }

    if (eventRecording) {
      currentGuardTime = {
        start: eventRecording.guardTimeStart,
        end: eventRecording.guardTimeEnd
      };
    }
    return currentGuardTime;
  }, [eventRecording, seriesRecording]);

  const clearGuardTimes = useCallback(() => {
    const currentGuardTime = getGuardTimeForRelatedRecordings();
    setGuardTimeStart(currentGuardTime?.start || 0);
    setGuardTimeEnd(currentGuardTime?.end || 0);
  }, [setGuardTimeEnd, setGuardTimeStart, getGuardTimeForRelatedRecordings]);

  const getNewGuardTimeValues = useCallback((): PVRGuardTimes | undefined => {
    const guardTimes: PVRGuardTimes = {};
    const {hasStartGuardTimes, hasEndGuardTimes} = hasAvailableGuardTimes(event);
    if (hasStartGuardTimes) {
      guardTimes.start = guardTimeStart;
    }

    if (hasEndGuardTimes) {
      guardTimes.end = guardTimeEnd;
    }

    return guardTimes;
  }, [guardTimeStart, guardTimeEnd, event]);

  useEffect(() => {
    clearGuardTimes();
  }, [eventRecording, seriesRecording, clearGuardTimes]);
  return {setGuardTimeStart, setGuardTimeEnd, clearGuardTimes, getGuardTimeForRelatedRecordings, getNewGuardTimeValues};
}

export function useScheduleRecording(
  currentEvent?: Event,
  currentSeasons?: Series[],
  relatedRecordings?: RelatedRecordings,
  loading?: boolean
): UseScheduleRecording {
  const [scheduledEvent, setScheduledEvent] = useState<Event>();

  const {t} = useTranslation();
  const safeAwait = useSafeAwait();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecordingOption, setSelectedRecordingOption] = useState<RecordOption>();
  const [initialRecordingOption, setInitialRecordingOption] = useState<RecordOption>();
  const getCurrentOption = useCallback(() => selectedRecordingOption ?? initialRecordingOption, [selectedRecordingOption, initialRecordingOption]);
  const [selectedStartingSeason, setSelectedStartingSeason] = useState<number>();
  const [boundToChannel, {toggle: toggleBoundToChannel, off: clearBoundToChannel}] = useToggle(false);
  const {clearGuardTimes, getNewGuardTimeValues, getGuardTimeForRelatedRecordings, setGuardTimeStart, setGuardTimeEnd} = useGuardTimes(relatedRecordings?.eventRecording, relatedRecordings?.seriesRecording, scheduledEvent);

  const resolver = useRef<(() => void)>();
  const rejecter = useRef<((reason?: any) => void)>();

  const clear = useFunction(() => {
    setScheduledEvent(undefined);
    rejecter.current = undefined;
    resolver.current = undefined;
    setSelectedRecordingOption(undefined);
    setInitialRecordingOption(undefined);
    setSelectedStartingSeason(undefined);
    clearBoundToChannel();
    clearGuardTimes();
  });

  const record = useFunction(async (params: PVRScheduleParameters) => {
    if (!(params.event && params.type)) {
      rejecter.current?.(new Error(ErrorType.InvalidParameter, t('recordings.errors.scheduleRecordingFailed')));
      Log.error(TAG, 'Recording event or type missing');
      return;
    }
    try {
      if (params.type === RecordingType.Series && relatedRecordings?.seriesRecording?.status === RecordingStatus.Suspended) {
        const resumeParams = ((): PVRUpdateParameters | undefined => {
          if (typeof params.scope === 'undefined') {
            return params.guardTimes ? {guardTimes: params.guardTimes} : undefined;
          }
          if (params.scope === PVRRecordingScope.StartingFromSeason) {
            return {
              scope: params.scope,
              startingSeason: params.startingSeason,
              guardTimes: params.guardTimes
            };
          }
          return {
            scope: params.scope,
            event: params.event,
            guardTimes: params.guardTimes
          };
        })();
        await safeAwait(mw.pvr.resumeRecordings([relatedRecordings.seriesRecording], resumeParams));
        resolver.current?.();
      } else {
        await safeAwait(mw.pvr.scheduleRecording(params));
        resolver.current?.();
      }
    } catch (error) {
      Log.error(TAG, `Request failed with error: ${error}`);
      rejecter.current?.(error);
    }
  });

  const changeParameters = useFunction(async (params: PVRUpdateParameters) => {
    try {
      if (relatedRecordings?.seriesRecording) {
        await safeAwait(mw.pvr.updateRecordings([relatedRecordings.seriesRecording], params));
        resolver.current?.();
      } else {
        rejecter.current?.(new Error(ErrorType.InvalidParameter, 'Changing recording params is not available for this recording'));
      }
    } catch (error) {
      rejecter.current?.(error);
    }
  });

  const recordOptions = useMemo<SelectionPopupOption[]>(() => {
    const recordOptions: RecordOption[] = [];
    if (!currentEvent || !relatedRecordings || loading) {
      return [];
    }

    const recordingsStatus = getRecordingsStatus(currentEvent, relatedRecordings?.eventRecording, relatedRecordings?.seriesRecording);

    switch (recordingsStatus) {
      case RelatedRecordingsStatus.noneRecorded:
        if (currentEvent.isEventNPVRAllowed) {
          setInitialRecordingOption(RecordOption.current);
          recordOptions.push(RecordOption.current);
        }

        if (currentEvent.isSeriesNPVRAllowed) {
          if (!currentEvent.isEventNPVRAllowed) {
            setInitialRecordingOption(RecordOption.new);
          }
          recordOptions.push(RecordOption.new, RecordOption.all);
        }
        break;
      case RelatedRecordingsStatus.eventRecorded:
        setInitialRecordingOption(RecordOption.current);
        recordOptions.push(RecordOption.current);
        if (currentEvent.isSeriesNPVRAllowed) {
          recordOptions.push(RecordOption.new, RecordOption.all);
        }
        break;
      case RelatedRecordingsStatus.onlyNewRecorded:
        setInitialRecordingOption(RecordOption.new);
        recordOptions.push(RecordOption.new);
        if (currentEvent.isSeriesNPVRAllowed) {
          recordOptions.push(RecordOption.all);
        }
        break;
      case RelatedRecordingsStatus.seriesRecorded:
        setInitialRecordingOption(RecordOption.all);
        recordOptions.push(RecordOption.all);
        break;
    }

    const createOption = (option: RecordOption) => ({
      key: option,
      text: t(option),
      selected: getCurrentOption() === option,
      onPress: () => setSelectedRecordingOption(option)
    });

    return (recordOptions.length > 1 ? recordOptions : []).map(createOption);
  }, [currentEvent, getCurrentOption, loading, relatedRecordings, t]);

  const startingSeasonOptions = useMemo<SelectionPopupOption[]>(() => {
    if (!currentSeasons) {
      return [];
    }
    return currentSeasons.map((season, index): SelectionPopupOption => {
      const seasonNumber = season.seasonNumber ?? (index + 1); // seasonNumber is optional
      return {
        key: `${seasonNumber}`,
        text: getStartingSeasonLabel(t, seasonNumber),
        selected: selectedStartingSeason === seasonNumber,
        onPress: () => setSelectedStartingSeason(seasonNumber)
      };
    });
  }, [t, currentSeasons, selectedStartingSeason]);

  const onClose = useFunction(() => {
    setModalVisible(false);
  });

  const onCancel = useFunction(() => {
    resolver.current?.();
    clear();
  });

  const onPositive = useFunction(() => {
    if (!currentEvent) {
      rejecter.current?.(new Error(ErrorType.InvalidParameter, 'Recording event or type missing'));
      Log.error(TAG, 'Recording event or type missing');
      return;
    }

    const recordingsStatus = getRecordingsStatus(currentEvent, relatedRecordings?.eventRecording, relatedRecordings?.seriesRecording);
    if (recordingsStatus !== RelatedRecordingsStatus.noneRecorded && (!selectedRecordingOption || selectedRecordingOption === initialRecordingOption)) {
      onCancel();
      return;
    }

    switch (recordingsStatus) {
      case RelatedRecordingsStatus.noneRecorded:
      case RelatedRecordingsStatus.eventRecorded:
        const currentOption = getCurrentOption();
        record(((): PVRScheduleParameters => {
          if (currentOption === RecordOption.current) {
            return {
              event: currentEvent,
              type: RecordingType.Single,
              guardTimes: getNewGuardTimeValues()
            };
          }
          const commonSeriesParams = {
            event: currentEvent,
            boundChannelId: boundToChannel ? currentEvent.channelId : undefined,
            guardTimes: getNewGuardTimeValues()
          };
          if (currentOption === RecordOption.all) {
            return typeof selectedStartingSeason === 'number'
              ? {
                ...commonSeriesParams,
                type: RecordingType.Series,
                scope: PVRRecordingScope.StartingFromSeason,
                startingSeason: selectedStartingSeason
              } : {
                ...commonSeriesParams,
                type: RecordingType.Series,
                scope: PVRRecordingScope.All
              };
          }
          return {
            ...commonSeriesParams,
            type: RecordingType.Series,
            scope: PVRRecordingScope.OnlyNew
          };
        })());
        break;
      case RelatedRecordingsStatus.onlyNewRecorded:
        changeParameters({
          scope: PVRRecordingScope.All,
          guardTimes: getNewGuardTimeValues()
        });
      case RelatedRecordingsStatus.seriesRecorded:
        onClose();
    }

  });

  const isGuardTimeDisabled = useMemo(() => {
    if (!currentEvent) {
      return true;
    }

    const recordingsStatus = getRecordingsStatus(currentEvent, relatedRecordings?.eventRecording, relatedRecordings?.seriesRecording);
    return recordingsStatus !== RelatedRecordingsStatus.noneRecorded && (!selectedRecordingOption || selectedRecordingOption === initialRecordingOption);
  }, [currentEvent, initialRecordingOption, relatedRecordings, selectedRecordingOption]);

  const renderSeriesRecordingModal = useCallback((onModalClose?: () => void) => {
    const close = () => {
      onClose();
      onModalClose && onModalClose();
    };

    const cancel = () => {
      onCancel();
      onModalClose && onModalClose();
    };

    const positive = () => {
      onPositive();
      onModalClose && onModalClose();
    };

    const showBoundToChannel = !!currentEvent?.title.episode;
    const currentOption = getCurrentOption();
    const disableStartingSeasonOptions = currentOption !== RecordOption.all;
    const disableBoundToChannel = currentOption !== RecordOption.all && currentOption !== RecordOption.new;
    const availableGuardTimes = mw.pvr.getAvailableGuardTimes((selectedRecordingOption ?? initialRecordingOption) === RecordOption.current ? scheduledEvent : undefined);

    return (
      <RecordingConfigPopup
        visible={modalVisible}
        recordOptions={recordOptions}
        startingSeasonOptions={startingSeasonOptions}
        disableStartingSeasonOptions={disableStartingSeasonOptions}
        startingSeason={selectedStartingSeason}
        showBoundToChannel={showBoundToChannel}
        disableBoundToChannel={disableBoundToChannel}
        boundToChannel={boundToChannel}
        onBoundToChannelPress={toggleBoundToChannel}
        onPositive={positive}
        onCancel={cancel}
        onClose={close}
        loading={scheduledEvent !== currentEvent || loading}
        availableGuardTimes={availableGuardTimes}
        onGuardTimeStartChanged={setGuardTimeStart}
        onGuardTimeEndChanged={setGuardTimeEnd}
        initialGuardTimeStart={getGuardTimeForRelatedRecordings()?.start}
        initialGuardTimeEnd={getGuardTimeForRelatedRecordings()?.end}
        disableGuardTimes={isGuardTimeDisabled}
      />
    );
  }, [
    modalVisible, recordOptions, startingSeasonOptions, selectedStartingSeason, boundToChannel, toggleBoundToChannel,
    onPositive, onCancel, onClose, scheduledEvent, currentEvent, loading, getGuardTimeForRelatedRecordings,
    setGuardTimeStart, setGuardTimeEnd, getCurrentOption, selectedRecordingOption, initialRecordingOption, isGuardTimeDisabled
  ]);

  const scheduleRecording = useCallback((event: Event): Promise<void> => {
    return new Promise((resolve, reject) => {
      clear();
      setScheduledEvent(event);
      resolver.current = resolve;
      rejecter.current = reject;

      if (event.title.episode || hasAvailableGuardTimes(event).hasGuardTimes) {
        setModalVisible(true);
      } else {
        record({event: event, type: RecordingType.Single});
      }
    });
  }, [clear, record]);

  // Close popup when PVRRecordingsChanged event received
  useEventListener(PVREvent.PVRRecordingsChanged, () => {
    onCancel();
    onClose();
  }, mw.pvr);

  return {scheduleRecording, renderSeriesRecordingModal};
}

const cancelRecordingStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  modalText: {
    color: colors.popup.text,
    textAlign: 'center',
    marginBottom: getValue({mobile: dimensions.margins.small, defaultValue: dimensions.margins.large})
  },
  menuButtons: {
    margin: dimensions.margins.small
  }
}));

type UseCancelSeriesRecordingForEvent = {
  cancelSeriesForEvent: (event: Event) => Promise<void>;
  renderCancelSeriesRecordingPopup: () => JSX.Element;
};

export function useCancelSeriesRecordingForEvent(
  currentEvent?: Event,
  relatedRecordings?: RelatedRecordings,
  loading?: boolean
): UseCancelSeriesRecordingForEvent {
  const {t} = useTranslation();
  const safeAwait = useSafeAwait();
  const styles = cancelRecordingStylesUpdater.getStyles();

  const [cancelledEvent, setEvent] = useState<Event>();

  const [modalVisible, {on: showModal, off: hideModal}] = useToggle(false);
  const resolver = useRef<(() => void)>();
  const rejecter = useRef<((reason?: any) => void)>();

  const clear = useFunction(() => {
    setEvent(undefined);
    rejecter.current = undefined;
    resolver.current = undefined;
  });

  const cancelSeriesForEvent = useCallback((event: Event) => {
    return new Promise<void>((resolve, reject) => {
      clear();
      setEvent(event);
      resolver.current = resolve;
      rejecter.current = reject;
      showModal();
    });

  }, [clear, showModal]);

  const onClose = useFunction(() => {
    hideModal();
  });

  const onCancel = useFunction(() => {
    resolver.current?.();
    clear();
  });

  const onPositive = useFunction(async () => {
    if (cancelledEvent !== currentEvent) {
      rejecter.current?.(new Error(ErrorType.InvalidParameter, 'Event or type missing'));
      Log.error(TAG, 'Event or type missing');
      return;
    }

    if (!relatedRecordings?.seriesRecording) {
      rejecter.current?.(new Error(ErrorType.InvalidParameter, 'Recording missing'));
      Log.error(TAG, 'Recording missing');
      return;
    }

    try {
      await safeAwait(mw.pvr.cancelRecordings([relatedRecordings?.seriesRecording]));
      resolver.current?.();
    } catch (error) {
      rejecter.current?.(error);
    }
  });

  const renderCancelSeriesRecordingPopup = useCallback(() => {
    return (
      <Popup
        visible={modalVisible}
        title={loading ? '' : t('recordings.cancelSeriesPopup.title')}
        actions={loading ? [] : [PopupAction.POSITIVE, PopupAction.NEGATIVE]}
        menuButtonStyle={styles.menuButtons}
        menuHasPreferredFocus={true}
        onNegative={onCancel}
        onModalClose={onCancel}
        onClose={onClose}
        onPositive={onPositive}
      >
        {!loading ?
          (
            <NitroxText style={styles.modalText} textType='dialog-message'>{t('recordings.cancelSeriesPopup.message')}</NitroxText>
          ) :
          (
            <ActivityIndicator />
          )
        }
      </Popup>
    );
  }, [loading, modalVisible, onCancel, onClose, onPositive, styles.menuButtons, styles.modalText, t]);

  // Close popup when PVRRecordingsChanged event received
  useEventListener(PVREvent.PVRRecordingsChanged, () => {
    onCancel();
    onClose();
  }, mw.pvr);

  return {cancelSeriesForEvent, renderCancelSeriesRecordingPopup};
}

type UseCancelRecording = {
  cancelRecording: (recording: Recording) => Promise<void>;
  isSeriesCancelAvailable: (recording: Recording) => boolean;
  renderCancelRecordingPopup: () => JSX.Element;
};

export function useCancelRecording(): UseCancelRecording {
  const {t} = useTranslation();
  const safeAwait = useSafeAwait();
  const styles = cancelRecordingStylesUpdater.getStyles();

  const [cancelledRecording, setRecording] = useState<Recording>();

  const [modalVisible, {on: showModal, off: hideModal}] = useToggle(false);
  const resolver = useRef<(() => void)>();
  const rejecter = useRef<((reason?: any) => void)>();

  const clear = useFunction(() => {
    setRecording(undefined);
    rejecter.current = undefined;
    resolver.current = undefined;
  });

  const cancelRecording = useCallback((recording: Recording) => {
    return new Promise<void>(async (resolve, reject) => {
      clear();
      if (recording.recordingType === RecordingType.Single) {
        try {
          await safeAwait(mw.pvr.deleteRecordings([recording]));
          resolve();
        } catch (err) {
          reject(err);
        }
      } else {
        setRecording(recording);
        resolver.current = resolve;
        rejecter.current = reject;
        showModal();
      }
    });

  }, [clear, safeAwait, showModal]);

  const onClose = useFunction(() => {
    hideModal();
  });

  const onCancel = useFunction(() => {
    resolver.current?.();
    clear();
  });

  const onPositive = useFunction(async () => {
    if (!cancelledRecording) {
      rejecter.current?.(new Error(ErrorType.InvalidParameter, 'Recording missing'));
      Log.error(TAG, 'Recording missing');
      return;
    }
    try {
      await safeAwait(mw.pvr.cancelRecordings([cancelledRecording]));
      resolver.current?.();
    } catch (error) {
      rejecter.current?.(error);
    }
  });

  const isSeriesCancelAvailable = useFunction((recording: Recording): boolean => {
    return !!(isSeriesRecording(recording) && recording?.status === RecordingStatus.Active);
  });

  const renderCancelRecordingPopup = useCallback(() => {
    return (
      <Popup
        visible={modalVisible}
        title={t('recordings.cancelSeriesPopup.title')}
        actions={[PopupAction.POSITIVE, PopupAction.NEGATIVE]}
        menuButtonStyle={styles.menuButtons}
        menuHasPreferredFocus={true}
        onNegative={onCancel}
        onModalClose={onCancel}
        onClose={onClose}
        onPositive={onPositive}
      >
        <NitroxText style={styles.modalText} textType='dialog-message'>{t('recordings.cancelSeriesPopup.message')}</NitroxText>
      </Popup>
    );
  }, [modalVisible, onCancel, onClose, onPositive, styles.menuButtons, styles.modalText, t]);

  // Close popup when PVRRecordingsChanged event received
  useEventListener(PVREvent.PVRRecordingsChanged, () => {
    onCancel();
    onClose();
  }, mw.pvr);

  return {cancelRecording, isSeriesCancelAvailable, renderCancelRecordingPopup};
}

/*
 *  Use this hook to change recording params from recording only new episodes to record
 *  whole series when event is not available ex. RecordingsFolder.
 *  When trying to schedule/change params based on event use useScheduleRecording hook.
 */

type UseChangeSeriesRecordingParams = {
  changeRecordingsParams: (recording: Recording) => Promise<void>;
  isParamsChangeAvailableForRecording: (recording: Recording) => boolean;
  renderChangeSeriesRecordingParamsModal: () => JSX.Element;
};

export function useChangeSeriesRecordingParams(): UseChangeSeriesRecordingParams {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecordingOption, setSelectedRecordingOption] = useState<RecordOption | null>();
  const {t} = useTranslation();
  const safeAwait = useSafeAwait();
  const resolver = useRef<(() => void) | null>();
  const rejecter = useRef<((reason?: any) => void) | null>();

  const [currentRecording, setCurrentRecording] = useState<Recording | undefined>();
  const {clearGuardTimes, getNewGuardTimeValues, getGuardTimeForRelatedRecordings, setGuardTimeStart, setGuardTimeEnd} = useGuardTimes(undefined, currentRecording);

  const clear = useFunction(() => {
    rejecter.current = null;
    resolver.current = null;
    setCurrentRecording(undefined);
    setSelectedRecordingOption(RecordOption.new);
    clearGuardTimes();
  });

  const isParamsChangeAvailableForRecording = useFunction((recording: Recording): boolean => {
    return !!(mw.configuration.isNPVREnabled && isSeriesRecording(recording) && recording.seedEventId);
  });

  const changeParameters = useFunction(async (params: PVRUpdateParameters) => {
    try {
      if (currentRecording) {
        await safeAwait(mw.pvr.updateRecordings([currentRecording], params));
        resolver.current?.();
      } else {
        rejecter.current?.(new Error(ErrorType.InvalidParameter, 'Changing recording params is not available for this recording'));
      }
    } catch (error) {
      rejecter.current?.(error);
    }
  });

  const changeRecordingsParams = useCallback((recording: Recording): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (!isParamsChangeAvailableForRecording(recording)) {
        reject(new Error(ErrorType.InvalidParameter, 'Changing recording params is not available for this recording'));
      } else {
        clear();
        resolver.current = resolve;
        rejecter.current = reject;

        setCurrentRecording(recording);
        setModalVisible(true);
      }
    });
  }, [clear, isParamsChangeAvailableForRecording]);

  const recordOptions = useMemo<SelectionPopupOption[]>(() => {
    const recordOptions: RecordOption[] = [];

    recordOptions.push(RecordOption.new, RecordOption.all);

    const createOption = (option: RecordOption) => ({
      key: option,
      text: t(option),
      selected: selectedRecordingOption === option,
      onPress: () => setSelectedRecordingOption(option)
    });

    return recordOptions.map(createOption);
  }, [selectedRecordingOption, t]);

  const onClose = useFunction(() => {
    setModalVisible(false);
  });

  const onCancel = useFunction(() => {
    resolver.current?.();
    clear();
  });

  const onPositive = useFunction(() => {
    if (!currentRecording) {
      rejecter.current?.(new Error(ErrorType.InvalidParameter, 'Recording missing'));
      Log.error(TAG, 'Recording missing');
      return;
    }

    if (selectedRecordingOption === RecordOption.all) {
      changeParameters({
        scope: PVRRecordingScope.All,
        guardTimes: getNewGuardTimeValues()
      });
    }
  });

  const renderChangeSeriesRecordingParamsModal = useCallback(() => {
    return (
      <RecordingConfigPopup
        visible={modalVisible}
        recordOptions={recordOptions}
        onPositive={onPositive}
        onCancel={onCancel}
        onClose={onClose}
        availableGuardTimes={mw.pvr.getAvailableGuardTimes()}
        onGuardTimeStartChanged={setGuardTimeStart}
        onGuardTimeEndChanged={setGuardTimeEnd}
        initialGuardTimeStart={getGuardTimeForRelatedRecordings()?.start}
        initialGuardTimeEnd={getGuardTimeForRelatedRecordings()?.end}
        disableGuardTimes={selectedRecordingOption !== RecordOption.all}
      />
    );
  }, [modalVisible, recordOptions, onPositive, onCancel, onClose, setGuardTimeStart, setGuardTimeEnd, getGuardTimeForRelatedRecordings, selectedRecordingOption]);

  // Close popup when PVRRecordingsChanged event received
  useEventListener(PVREvent.PVRRecordingsChanged, () => {
    onCancel();
    onClose();
  }, mw.pvr);

  return {changeRecordingsParams, isParamsChangeAvailableForRecording, renderChangeSeriesRecordingParamsModal};
}

type UseResumeRecording = {
  resumeRecording: (recording: Recording) => Promise<void>;
  isResumeAvailable: (recording: Recording) => boolean;
  renderResumeRecordingModal: (onModalClose?: () => void) => JSX.Element;
};

export function useResumeRecording(): UseResumeRecording {
  const safeAwait = useSafeAwait();
  const {t} = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [currentRecording, setCurrentRecording] = useState<Recording>();
  const {clearGuardTimes, getNewGuardTimeValues, getGuardTimeForRelatedRecordings, setGuardTimeStart, setGuardTimeEnd} = useGuardTimes(currentRecording);
  const availableGuardTimes = useMemo(() => mw.pvr.getAvailableGuardTimes(), []);

  const resolver = useRef<(() => void)>();
  const rejecter = useRef<((reason?: any) => void)>();

  const clear = useFunction(() => {
    rejecter.current = undefined;
    resolver.current = undefined;
    setCurrentRecording(undefined);
    clearGuardTimes();
  });

  const isResumeAvailable = useFunction((recording: Recording): boolean => {
    return !!(mw.configuration.isNPVREnabled && recording.status === RecordingStatus.Suspended);
  });

  const resumeRecording = useCallback((recording: Recording): Promise<void> => {
    if (hasAvailableGuardTimes().hasGuardTimes) {
      return new Promise((resolve, reject) => {
        clear();
        setCurrentRecording(recording);
        resolver.current = resolve;
        rejecter.current = reject;
        setModalVisible(true);
      });
    } else {
      return safeAwait(mw.pvr.resumeRecordings([recording]));
    }
  }, [clear, safeAwait]);

  const resume = useFunction(async (params?: PVRUpdateParameters) => {
    if (!currentRecording) {
      rejecter.current?.(new Error(ErrorType.InvalidParameter, 'Recording missing'));
      Log.error(TAG, 'Recording missing');
      return;
    }

    try {
      await safeAwait(mw.pvr.resumeRecordings([currentRecording], params));
      resolver.current?.();
    } catch (error) {
      Log.error(TAG, `Request failed with error: ${error}`);
      rejecter.current?.(error);
    }
  });

  const onClose = useFunction(() => {
    setModalVisible(false);
  });

  const onCancel = useFunction(() => {
    resolver.current?.();
    clear();
  });

  const onPositive = useFunction(() => {
    if (!currentRecording) {
      rejecter.current?.(new Error(ErrorType.InvalidParameter, 'Recording missing'));
      Log.error(TAG, 'Recording missing');
      return;
    }
    resume({guardTimes: getNewGuardTimeValues()});
  });

  const renderResumeRecordingModal = useCallback((onModalClose?: () => void) => {
    const close = () => {
      onClose();
      onModalClose && onModalClose();
    };

    const cancel = () => {
      onCancel();
      onModalClose && onModalClose();
    };

    const positive = () => {
      onPositive();
      onModalClose && onModalClose();
    };

    return (
      <RecordingConfigPopup
        visible={modalVisible}
        customTitle={t('recordings.recordingConfigPopup.titles.resume')}
        onPositive={positive}
        onCancel={cancel}
        onClose={close}
        availableGuardTimes={availableGuardTimes}
        onGuardTimeStartChanged={setGuardTimeStart}
        onGuardTimeEndChanged={setGuardTimeEnd}
        initialGuardTimeStart={getGuardTimeForRelatedRecordings()?.start}
        initialGuardTimeEnd={getGuardTimeForRelatedRecordings()?.end}
      />
    );
  }, [t, modalVisible, onPositive, onCancel, onClose, getGuardTimeForRelatedRecordings, setGuardTimeStart, setGuardTimeEnd, availableGuardTimes]);

  return {resumeRecording, isResumeAvailable, renderResumeRecordingModal};
}
