import {createStyles} from 'common-styles';
import React from 'react';
import {ActivityIndicator} from 'react-native';

import {Modal} from './Modal';

const styles = createStyles({
  container: {
    alignItems: 'center',
    justifyContent: 'center'
  }
});

type Props = {
  visible: boolean;
}

const FullscreenActivityIndicator: React.FunctionComponent<Props> = props => {
  const {visible} = props;
  return (
    <Modal visible={visible} style={styles.container}>
      <ActivityIndicator animating={visible} size='large' />
    </Modal>
  );
};

export default FullscreenActivityIndicator;
