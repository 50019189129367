import {DateUtils} from 'common/DateUtils';
import {EventEmitter} from 'common/EventEmitter';
import {isSeriesEpisode} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {Media, MediaType, isTitle, isEvent, Event, TitleType} from 'mw/api/Metadata';
import {nxffConfig} from 'mw/api/NXFF';
import {Profile} from 'mw/api/Profile';
import {boProxy} from 'mw/bo-proxy/BOProxy';
import {mw} from 'mw/MW';

import {WatchListManager, WatchListEvent} from './WatchListManager';

const TAG = 'WatchList';

/** Events from WatchList are grouped into the following groups before sorting */
enum EventGroupName {
  Live = 'Live',
  TSTV = 'TSTV',
  PVR = 'PVR',
  Future = 'Future',
  Other = 'Other'
}

function getEventGroupName(event: Event): EventGroupName {
  switch (true) {
    case event.isNow:
      return EventGroupName.Live;
    case event.isFuture:
      return EventGroupName.Future;
    case event.isRecorded:
      return EventGroupName.PVR;
    case event.hasTstv:
      return EventGroupName.TSTV;
    default:
      return EventGroupName.Other;
  }
}

type MediaInfo = {
  mediaType: MediaType;
  mediaId: string;
};

/**
 * Returns media id and type valid from WatchList's point-of-view.
 * First: Media can be a Title or an Event
 * Second: Episodes should be handled as a whole Series (adding or removing episode adds or removes a whole series).
 */
function getMediaInfo(media: Media): MediaInfo {
  if (isEvent(media)) {
    // event that is part of a series
    if (media.title.episode && isSeriesEpisode(media.title)) {
      return {mediaType: MediaType.Series, mediaId: media.title.episode.seriesId};
    }
    // single event
    return {mediaType: MediaType.Title, mediaId: media.title.id};
  }
  // title that is part of a series
  if (isTitle(media) && media.episode && isSeriesEpisode(media)) {
    return {mediaType: MediaType.Series, mediaId: media.episode.seriesId};
  }
  // title that is a trailer
  if (isTitle(media) && media.isTrailer && media.parentId) {
    return {mediaType: media.getType(), mediaId: media.parentId};
  }
  return {mediaType: media.getType(), mediaId: media.id};
}

export class EnabledWatchListManager extends EventEmitter<WatchListEvent> implements WatchListManager {
  private profile: Profile;
  private cachedWatchList = new Array<Media>();
  private mappedWatchList = new Map<MediaType, Set<string>>();
  private nextSortTimerId?: number | null;

  public constructor(profile: Profile) {
    super();
    this.profile = profile;
  }

  public get watchList(): Media[] { return this.cachedWatchList; }

  public async add(media: Media): Promise<void> {
    const {mediaType, mediaId} = getMediaInfo(media);
    try {
      Log.debug(TAG, `Adding media ${media} to WatchList for profile ${this.profile}`);
      if (mediaType === MediaType.Series) {
        await boProxy.bo.addSeriesToWatchList(this.profile, mediaId);
      } else if (isTitle(media)) {
        if (media.isTrailer && media.parentId) {
          await boProxy.bo.addTitleToWatchList(this.profile, media.parentId);
        } else {
          // If Title is a trailer it always has parentId, so this condition is only for Titles that aren't trailers
          await boProxy.bo.addTitleToWatchList(this.profile, mediaId);
        }
      } else {
        await boProxy.bo.addTitleToWatchList(this.profile, mediaId);
      }
      Log.debug(TAG, `Successfully added media ${media} to WatchList for profile ${this.profile}`);
      await this.refresh();
    } catch (error) {
      Log.error(TAG, `Failed to add media ${media} to WatchList for profile ${this.profile}`, error);
      throw error;
    }
  }

  public async remove(mediaArray: Media[]): Promise<void> {
    Log.debug(TAG, `Removing ${mediaArray.length} items from WatchList on profile ${this.profile}`);
    try {
      // We have to distinguish two types of requests here one for titles and another one for series.
      // Also in case we get several episodes from the same series we want to group them together.
      const titlesIds: string[] = [];
      const seriesIdsSet = new Set<string>();
      mediaArray.forEach((media) => {
        const {mediaType, mediaId} = getMediaInfo(media);
        if (mediaType === MediaType.Series) {
          seriesIdsSet.add(mediaId);
        } else if (isTitle(media)) {
          if (media.isTrailer && media.parentId) {
            titlesIds.push(media.parentId);
          } else {
            // If Title is a trailer it always has parentId, so this condition is only for Titles that aren't trailers
            titlesIds.push(mediaId);
          }
        } else {
          titlesIds.push(mediaId);
        }
      });
      const pendingRequests: Promise<any>[] = [];
      if (titlesIds.length > 0) {
        pendingRequests.push(boProxy.bo.removeTitlesFromWatchList(this.profile, titlesIds));
      }
      const seriesIds = Array.from(seriesIdsSet.values());
      if (seriesIds.length > 0) {
        pendingRequests.push(boProxy.bo.removeSeriesFromWatchList(this.profile, seriesIds));
      }
      await Promise.all(pendingRequests);
      Log.debug(TAG, `Successfully removed ${mediaArray.length} items from WatchList on profile ${this.profile}`);
      await this.refresh();
    } catch (error) {
      Log.error(TAG, `Failed to remove ${mediaArray.length} items from WatchList on profile ${this.profile}`, error);
      throw error;
    }
  }

  public isOnWatchList(media: Media): boolean {
    // WatchListMap contains individual Events, Series and Titles
    const {mediaType, mediaId} = getMediaInfo(media);
    const mappedType = this.mappedWatchList.get(mediaType);
    return !!mappedType?.has(mediaId);
  }

  public async refresh(): Promise<void> {
    // update cached value and send notification only when new WatchList really changed
    const newWatchList = await boProxy.bo.getWatchList(this.profile);
    if (this.isEqual(newWatchList)) {
      Log.debug(TAG, `There is no need to update cached WatchList for profile ${this.profile}`);
      return;
    }
    Log.debug(TAG, `WatchList for profile ${this.profile} changed - sending notification`);
    this.cachedWatchList = newWatchList;
    this.sort();
    this.scheduleNextSort();
    this.createMap();
    this.notify(WatchListEvent.WatchListChange);
  }

  private isEqual(newWatchList: Media[]): boolean {
    if (this.cachedWatchList.length !== newWatchList.length) {
      return false;
    }
    return newWatchList.findIndex((media) => {
      return !this.isOnWatchList(media);
    }) === -1;
  }

  private sortEvents(events: Event[]): Event[] {
    // group the entire WatchList by the group names
    const groups = new Map<EventGroupName, Event[]>();
    events.forEach((event) => {
      const groupName = getEventGroupName(event);
      const group = groups.get(groupName);
      if (group) {
        group.push(event);
      } else {
        groups.set(groupName, [event]);
      }
    });
    // sort events by start time and it that is no concrete then alphabetically by channel name
    const results: Event[] = [];
    [
      EventGroupName.Live,
      EventGroupName.TSTV,
      EventGroupName.PVR,
      EventGroupName.Future
      // anything that was group under the Other group name should be ignored and not available on the WatchList
    ].forEach((groupName) => {
      const group = (groups.get(groupName) || []).sort((leftEvent, rightEvent) => {
        const startTimeDelta = leftEvent.start.getTime() - rightEvent.start.getTime();
        if (startTimeDelta !== 0) {
          return startTimeDelta;
        }
        const leftChannel = mw.catalog.getChannelById(leftEvent.channelId);
        const rightChannel = mw.catalog.getChannelById(rightEvent.channelId);
        if (!leftChannel || !rightChannel) {
          return 0;
        }
        return leftChannel.name.localeCompare(rightChannel.name);
      });
      results.push(...group);
    });
    return results;
  }

  private sort() {
    // sort the entire list alphabetically by name
    this.cachedWatchList = this.cachedWatchList.sort((leftMedia, rightMedia) => {
      return leftMedia.name.localeCompare(rightMedia.name);
    });
    // look for EPG Media and sort their events
    this.cachedWatchList.forEach((media) => {
      if (isTitle(media) && media.type === TitleType.EPG) {
        media.events = this.sortEvents(media.events);
      }
    });
  }

  private findNearestEndingAfterNow(events: Event[]) {
    const now = Date.now();
    return events.reduce((nearestDuration: number, event: Event) => {
      const duration = event.end.getTime() - now;
      return duration >= 0 && duration < nearestDuration
        ? duration
        : nearestDuration;
    }, Infinity);
  }

  private findSortTimeout() {
    return this.cachedWatchList.reduce((timeout, media) => {
      return isTitle(media) && media.type === TitleType.EPG
        ? Math.min(timeout, this.findNearestEndingAfterNow(media.events))
        : timeout;
    }, Infinity);
  }

  private cancelNextSort() {
    if (this.nextSortTimerId) {
      clearTimeout(this.nextSortTimerId);
      this.nextSortTimerId = null;
    }
  }

  private scheduleNextSort() {
    this.cancelNextSort();
    const timeout = this.findSortTimeout();
    if (!isFinite(timeout)) {
      return;
    }
    const minTimeout = nxffConfig.getConfig().Watchlist.WatchListMinSortTimeout * DateUtils.msInMin;
    this.nextSortTimerId = setTimeout(() => {
      this.sort();
      this.scheduleNextSort();
      this.notify(WatchListEvent.WatchListChange);
    }, Math.max(minTimeout, timeout));
  }

  private createMap() {
    this.mappedWatchList.clear();
    this.cachedWatchList.forEach((media) => {
      const {mediaType, mediaId} = getMediaInfo(media);
      let mappedType = this.mappedWatchList.get(mediaType);
      if (!mappedType) {
        mappedType = new Set<string>();
        this.mappedWatchList.set(mediaType, mappedType);
      }
      mappedType.add(mediaId);
    });
  }
}
