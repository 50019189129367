import {createStyles} from 'common-styles';
import React, {useState, useCallback} from 'react';
import {Image, ImageSourcePropType, StyleSheet, View, NativeSyntheticEvent, ImageErrorEventData} from 'react-native';

import {dimensions, isWeb} from 'common/constants';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {MediaType} from 'mw/api/Metadata';

import {Icon, IconType} from 'components/Icon';

const TAG = 'MediaPicture';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  posterImage: {
    ...StyleSheet.absoluteFillObject
  },
  noPosterIcon: {
    ...StyleSheet.absoluteFillObject,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.columnTile.poster.placeholder.background
  },
  placeholderIconColor: colors.columnTile.poster.placeholder.icon
}));

type Props = {
  source?: ImageSourcePropType | null;
  mediaType?: MediaType | 'credits';
  isBlocked?: boolean;
  iconSize?: number;
  iconColor?: string;
  customPlaceholderIcon?: IconType;
};

const MediaPicture: React.FunctionComponent<Props> = props => {
  const [imageLoadFailed, setImageLoadFailed] = useState(false);
  const {source} = props;
  const styles = stylesUpdater.getStyles();

  const onErrorHandler = useCallback((error: NativeSyntheticEvent<ImageErrorEventData>) => {
    Log.error(TAG, 'MediaPicture: failed to fetch picture:', error?.nativeEvent);
    setImageLoadFailed(true);
  }, []);

  if (!props.isBlocked && source && !imageLoadFailed) {
    return (
      <Image
        style={styles.posterImage}
        source={source}
        /*
         * resizeMode='center' doesn't work properly for web. It doesn't scale down images when needed - react-native-web displays images
         * as div background and uses 'background-size: auto' for resizeMode 'center', so image is displayed in its original size.
         */
        resizeMode={isWeb ? 'contain' : 'center'}
        /*
         * resizeMethod set to auto/scale vastly decreases performance on Mibox S
         * despite the documentation (https://reactnative.dev/docs/0.61/image#resizemethod)
         * suggests otherwise
         */
        resizeMethod='resize'
        onError={onErrorHandler}
      />
    );
  } else {
    const {mediaType, iconSize = dimensions.tile.iconHeight, iconColor = styles.placeholderIconColor} = props;
    let placeholderIconType: IconType;
    if (props.isBlocked) {
      placeholderIconType = IconType.ParentalControl;
    } else if (props.customPlaceholderIcon) {
      placeholderIconType = props.customPlaceholderIcon;
    } else if (mediaType === MediaType.Event) {
      placeholderIconType = IconType.PlaceholderTV;
    } else if (mediaType === 'credits') {
      placeholderIconType = IconType.PlaceholderCast;
    } else {
      placeholderIconType = IconType.PlaceholderVOD;
    }

    return (
      <View style={styles.noPosterIcon}>
        <Icon type={placeholderIconType} size={iconSize} color={iconColor} />
      </View>
    );
  }
};

export default React.memo(MediaPicture);
