import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {PinProtectionLevel} from 'mw/api/PinProtection';
import {Profile} from 'mw/api/Profile';
import {mw} from 'mw/MW';

import {useFocusParentUtility} from 'components/FocusParent';
import {unlockModalPortalName} from 'components/ModalPortal';
import ProfileSelect from 'components/ProfileSelect';
import PinResetHandler from 'components/unlockmodal/PinResetHandler';
import {UnlockModalProps} from 'components/unlockmodal/UnlockModalProps';
import UnlockPinKeyboard from 'components/unlockmodal/UnlockPinKeyboard';
import {useChangeEffect, useCurrentProfile, useToggle} from 'hooks/Hooks';

const defaultMaxTries = 3;

const UnlockModal: React.FC<UnlockModalProps> = props => {
  const {visible, profile, onClose: propsOnClose, onSuccess: propsOnSuccess, type} = props;
  const {t} = useTranslation();
  const [selectedProfile, setSelectedProfile] = useState<Profile | undefined>(profile);
  const [pinResetHandlerVisible, {on: showPinResetHandler, off: hidePinResetHandler}] = useToggle(false);
  const {focus: focusNearestParent} = useFocusParentUtility();

  const onProfileSelect = useCallback(id => setSelectedProfile(mw.customer.profiles.find(p => p.id === id) || undefined), []);

  const onClose = useCallback(() => {
    setSelectedProfile(profile);
    propsOnClose();
  }, [propsOnClose, profile]);

  const onSuccess = useCallback((pin: string) => {
    propsOnSuccess(pin);
    setSelectedProfile(profile);
  }, [propsOnSuccess, profile]);

  useEffect(() => () => {
    focusNearestParent?.();
  }, [focusNearestParent]);

  useChangeEffect(() => {
    setSelectedProfile(profile);
  }, [profile]);

  const currentProfile = useCurrentProfile();
  useEffect(() => {
    // If main profile is PC blocked, it authorizes itself
    if (visible && type === 'adult' && currentProfile?.isMain) {
      setSelectedProfile(currentProfile);
    }
  }, [visible, type, currentProfile]);

  if (!visible) {
    return null;
  }

  if (!selectedProfile) {
    return (
      <ProfileSelect
        markCurrent={false}
        onClose={onClose}
        onSelect={onProfileSelect}
        profilesType={type}
        title={t('unlock.selectAdultProfile')}
        initialFocusStrategy='first'
        portal={unlockModalPortalName}
        focusNearestParentOnClose={false}
        visible
      />
    );
  } else if (pinResetHandlerVisible) {
    return (
      <PinResetHandler closePopup={hidePinResetHandler} closeModal={onClose} selectedProfile={selectedProfile} />
    );
  } else {
    return (
      <UnlockPinKeyboard
        type={type}
        selectedProfile={selectedProfile}
        onClose={onClose}
        onSuccess={onSuccess}
        maxTries={mw.configuration.pinProtectionLevel === PinProtectionLevel.Profile ? defaultMaxTries : Infinity}
        onMaxTriesReached={showPinResetHandler}
      />
    );
  }
};

export default UnlockModal;

export interface WithUnlockModal {
  authorizeWithCurrentProfile: () => Promise<string>;
  authorizeWithAdultProfile: () => Promise<string>;
  authorizeProfile: (profile: Profile | null) => Promise<string>;
  renderUnlockModal: (onModalClose?: () => void) => React.ReactNode;
  resetUnlockModal: () => void;
}

export enum UnlockModalRejectionReason {
  Cancel,
  Error
}

export class UnlockModalRejection {
  public constructor(
    public reason: UnlockModalRejectionReason,
    public error?: Error
  ) {}
}

export function useUnlockModal(onCancel?: () => void) {
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState<UnlockModalProps['type']>('any');
  const [profile, setProfile] = useState<Profile>();

  const onSuccess = useRef<(pin: string) => void>();
  const onFail = useRef<(error?: UnlockModalRejection) => void>();

  const reset = useCallback(() => {
    setVisible(false);
    setProfile(undefined);
    onSuccess.current = undefined;
    onFail.current = undefined;
  }, []);

  const renderModal = useCallback((onModalClose?: () => void) => (
    visible ? (
      <UnlockModal
        type={type}
        profile={profile}
        onClose={() => {
          onCancel?.();
          onModalClose?.();
          onFail.current?.(new UnlockModalRejection(UnlockModalRejectionReason.Cancel));
          reset();
        }}
        onSuccess={pin => onSuccess.current && onSuccess.current(pin)}
        visible={visible}
      />
    ) : null
  ), [visible, type, profile, onCancel, reset]);

  const createPromise: () => Promise<string> = useCallback(() => new Promise((resolve, reject) => {
    onSuccess.current = (pin: string) => {
      resolve(pin);
      reset();
    };
    onFail.current = (error?: UnlockModalRejection) => {
      reject(error);
      reset();
    };
  }), [reset]);

  const authorizeProfile: (profileToAuthorize: Profile) => Promise<string> = useCallback(profileToAuthorize => {
    setType('any');
    setProfile(profileToAuthorize);
    setVisible(true);

    return createPromise();
  }, [createPromise]);

  const authorizeWithCurrentProfile: () => Promise<string> = useCallback(() => {
    if (!mw.customer.currentProfile) {
      return Promise.reject(new UnlockModalRejection(UnlockModalRejectionReason.Error, new Error('Current profile must be defined!')));
    }

    return authorizeProfile(mw.customer.currentProfile);
  }, [authorizeProfile]);

  const authorizeWithAdultProfile: () => Promise<string> = useCallback(() => {
    setType('adult');
    setProfile(undefined);
    setVisible(true);

    return createPromise();
  }, [createPromise]);

  return {renderModal, authorizeWithCurrentProfile, authorizeWithAdultProfile, authorizeProfile, unlockModalVisible: visible, resetUnlockModal: reset};
}

type WrappedProps<T> = Omit<T, keyof WithUnlockModal>;

export function withUnlockModal<Props extends WithUnlockModal>(WrappedComponent: React.ComponentType<Props>): React.ComponentType<WrappedProps<Props>> {
  function Wrapped(props: WrappedProps<Props>) {
    const {renderModal, ...unlockModalFunctionality} = useUnlockModal();
    return (
      <WrappedComponent
        {...props as Props}
        {...unlockModalFunctionality}
        renderUnlockModal={renderModal}
      />
    );
  }
  Wrapped.displayName = WrappedComponent.displayName;
  return Wrapped;
}
