import moment from 'moment';
import React, {useCallback, useContext, useMemo, useState, useEffect, useImperativeHandle} from 'react';
import {ViewStyle, GestureResponderEvent, StyleProp} from 'react-native';
import {NavigationContext} from 'react-navigation';

import {DateUtils} from 'common/DateUtils';
import {openMediaDetails, getMediaSubtypes, getMediaTitle, getRatingToDisplay, openChannelDetails, isSeriesEpisode} from 'common/HelperFunctions';
import {FocusOptions, TestProps} from 'common/HelperTypes';
import {getFormattedDuration} from 'common/utils';

import {isRecording, isTitle, TitleType, isEvent, isChannel, Media,Event, isSeries} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import {channelIconConstants} from 'components/ChannelIcon';
import {useHotKey} from 'components/HotKeys';
import {IconType} from 'components/Icon';
import {SupportedKeys} from 'components/KeyEventManager';
import {Tag} from 'components/NitroxTag';
import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';
import {TileIconType, TileIcon} from 'components/TileIconsRow';
import {useStaticallyFocused} from 'hooks/Hooks';
import i18n from 'locales/i18n';

export interface AnimatedTileInterface {
  onPress: () => void;
}

export interface MediaTileConfig {
  posterMedia?: Media;
  customPlaceholderIcon?: IconType;
  title?: string;
  infoSection?: string;
  hideProgress?: boolean;
  isBlocked?: boolean;
}

export type MediaTileCommonProps = {
  focusable?: boolean;
  scrollOnFocus?: boolean;
  staticallyFocused?: boolean;
  style?: StyleProp<ViewStyle>;
  height?: number;
}

export type MediaTileBaseProps = {
  media: Media;
  config?: MediaTileConfig;
  extraData?: any;
  onPress?: (media: Media, e?: GestureResponderEvent) => void;
  onFocus?: (media: Media, options?: FocusOptions) => void;
  width?: number;
  tileType?: MediaTileType;
  selected?: boolean;
  checkboxVisible?: boolean;
  checkboxChecked?: boolean;
  showIcons?: boolean;
  excludeIcons?: TileIconType[];
  onCheckboxToggle?: (value: boolean) => void;
  additionalIcons?: TileIcon[];
  renderMediaPoster?: () => JSX.Element;
  tag?: Tag;
  onMount?: () => void;
  secondRowItemsRenderer?: () => React.ReactNode;
} & MediaTileCommonProps & TestProps;

type MediaTileHelpersProps = Pick<MediaTileBaseProps,
'checkboxVisible' |
'checkboxChecked' |
'onCheckboxToggle' |
'onPress' |
'media' |
'config' |
'focusable' |
'staticallyFocused'
> & {
  setEventsForSelection: (events: Event[]) => void;
  ref: any
};

type MediaTileHelpersReturn = {
  isBlocked: boolean,
  onPress: () => void,
  onFocusStateChanged: React.Dispatch<React.SetStateAction<boolean>>,
  focusable:boolean,
  focused: boolean
}

export function useMediaTileHelpers({checkboxVisible, checkboxChecked, onCheckboxToggle, onPress: propagatePress, media, setEventsForSelection, config, focusable: propsFocusable, staticallyFocused, ref}: MediaTileHelpersProps): MediaTileHelpersReturn {
  const {event} = getMediaSubtypes(media);
  const navigation = useContext(NavigationContext);
  const {isMediaBlocked, shouldBeCheckedForPC} = useParentalControl();

  const {onFocusStateChanged, focusable, focused} = useStaticallyFocused(propsFocusable, staticallyFocused);

  const openDetails = useCallback(() => {
    if (isRecording(media)) {
      event && openMediaDetails(navigation, event.id, event.getType());
    } else if (isTitle(media) && media.type === TitleType.EPG) {
      const firstEvent = media.events[0];
      if (media.events.length > 1) {
        setEventsForSelection(media.events);
      } else if (isEvent(firstEvent)) {
        openMediaDetails(navigation, firstEvent.id, firstEvent.getType());
      }
    } else if (isTitle(media) || isEvent(media) || isSeries(media)) {
      openMediaDetails(navigation, media.id, media.getType());
    } else if (isChannel(media)) {
      openChannelDetails(navigation, media.id);
    }
  }, [media, navigation, event, setEventsForSelection]);

  const onPress = useCallback((e?: GestureResponderEvent) => {
    if (checkboxVisible && onCheckboxToggle) {
      onCheckboxToggle(!checkboxChecked);
    } else if (propagatePress) {
      propagatePress(media, e);
    } else {
      openDetails();
    }
  }, [media, propagatePress, checkboxChecked, checkboxVisible, onCheckboxToggle, openDetails]);

  const onInfoPress = useCallback(() => {
    if (checkboxVisible) {
      return;
    }
    if (propagatePress) {
      propagatePress(media);
    } else {
      openDetails();
    }
  }, [checkboxVisible, propagatePress, media, openDetails]);

  useHotKey(SupportedKeys.Info, onInfoPress, focused);

  /* eslint-disable schange-rules/no-use-imperative-handle-hook */
  useImperativeHandle(ref, () => {
    const handlers: AnimatedTileInterface = {
      onPress: () => {
        if (!focusable && focused) {
          onPress();
        }
      }
    };
    return handlers;
  }, [onPress, focusable, focused]);
  /* eslint-enable schange-rules/no-use-imperative-handle-hook */

  const isBlocked = useMemo(() => {
    let isBlocked = config?.isBlocked;
    if (!isBlocked) {
      const m = isRecording(media) ? media.event : media;
      isBlocked = shouldBeCheckedForPC(m) && isMediaBlocked(m);
    }
    return isBlocked;
  }, [config, isMediaBlocked, media, shouldBeCheckedForPC]);

  return {onPress, isBlocked, onFocusStateChanged, focusable, focused};
}

export const eventProgressRefreshInterval = 30 * DateUtils.msInSec;

export function useProgress(media: Media): number {
  const [progress, setProgress] = useState(0);
  const {event, title} = getMediaSubtypes(media);
  useEffect(() => {
    if (event) {
      setProgress(calculateProgress(event));
      const timer = setInterval(() => {
        setProgress(calculateProgress(event));
      }, eventProgressRefreshInterval);

      return () => {
        clearInterval(timer);
      };
    } else if (title) {
      setProgress(title.progress);
    }
  }, [event, title]);

  return progress;
}

export enum MediaTileType {
  Default,
  Episode,
  Search
}

const channelIconBoxDiagonal = channelIconConstants.static.size * Math.sqrt(2);
const channelIconCircleRadius = channelIconConstants.static.size / 2;
export const channelIconOffset = Math.floor((channelIconBoxDiagonal / 2 - channelIconCircleRadius) / Math.sqrt(2));

export const eventDateShouldShowShortString = (eventDate: Date): boolean => {
  const yesterday = moment().subtract(1, 'day')
    .startOf('day');
  const tomorrow = moment().add(1, 'day')
    .endOf('day');
  return moment(eventDate.getTime()).isBetween(yesterday, tomorrow);
};

export const eventDateString = (eventDate: Date, t: i18n.TFunction): string => {
  const date = moment(eventDate);
  if (eventDateShouldShowShortString(eventDate)) {
    if (date.isSame(moment(), 'day')) {
      return t('common.today');
    } else {
      const yesterday = moment().subtract(1, 'day')
        .startOf('day');
      if (date.isSame(yesterday, 'day')) {
        return t('common.yesterday');
      } else {
        const tomorrow = moment().add(1, 'day')
          .endOf('day');
        if (date.isSame(tomorrow, 'day')) {
          return t('common.tomorrow');
        }
      }
    }
  }
  return date.format(mw.configuration.dateFormat);
};

export function useMediaInfoSection(media: Media, t: i18n.TFunction, tileType: MediaTileType = MediaTileType.Default): string {
  return useMemo(() => {
    const {event, title, episode} = getMediaSubtypes(media);
    const parentalControlCode = getRatingToDisplay(media);
    const values: string[] = [];
    if (parentalControlCode) {
      values.push(parentalControlCode);
    }
    if (event) {
      const duration = getFormattedDuration(event);
      duration && values.push(duration);

      values.push(eventDateString(event.start, t));

      if (episode?.seasonNumber) {
        values.push(t('common.seasonNumber', {seasonNumber: episode.seasonNumber.toString().padStart(2, '0')}));
      }
      if (episode?.number) {
        values.push(t('common.episodeNumber', {episodeNumber: episode.number.toString().padStart(2, '0')}));
      }

      if (tileType === MediaTileType.Default) {
        if (title?.metadata.genres.length) {
          values.push(title.metadata.genres[0].name);
        }
        if (isSeriesEpisode(event.title) && episode?.title) {
          values.push(episode.title);
        }
      }
    } else if (title) {
      const duration = getFormattedDuration(title);

      if (episode && isSeriesEpisode(title)) {
        if (tileType === MediaTileType.Episode) {
          if (duration) {
            values.push(duration);
          }
        } else {
          if (episode.seasonNumber) {
            values.push(t('common.seasonNumber', {seasonNumber: episode.seasonNumber.toString().padStart(2, '0')}));
          }
          if (episode.number) {
            values.push(t('common.episodeNumber', {episodeNumber: episode.number.toString().padStart(2, '0')}));
          }
          if (episode.title) {
            values.push(episode.title);
          }
        }
      } else {
        duration && values.push(duration);
      }

      if (!episode && title.metadata.genres.length) {
        values.push(title.metadata.genres[0].name);
      }
    } else if (tileType !== MediaTileType.Search && isSeries(media) && media.seasonsCount > 0) {
      values.push(t('common.seasonsAmount', {count: media.seasonsCount}));
    }
    return values.join(', ');
  },[media, t, tileType]);

}

export const calculateProgress = (event: Event | null): number => {
  return event
    ? event.hasTstv && event.viewedProgress ? event.viewedProgress : event.progress
    : 0;
};

export const getMediaTitleForType = (media: Media, tileType: MediaTileType): string => {
  return tileType === MediaTileType.Episode
    ? getMediaSubtypes(media).episode?.title || media.name
    : getMediaTitle(media);
};
