import {createStyles} from 'common-styles';
import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {ImageURISource} from 'react-native';

import {dimensions} from 'common/constants';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {constColors, BaseColors} from 'common-styles/variables/base-colors';

import {DefaultMenuItemSlug} from 'mw/cms/CMS';
import {Menu, MenuAlignment} from 'mw/cms/Menu';
import {mw} from 'mw/MW';

import {IconProps, IconType} from 'components/Icon';
import {getMenuIcon, getOperatorLogoUrl} from 'components/navigation/MainMenu';
import {routeNameForMenu} from 'components/navigation/NavigationHelperFunctions';
import NitroxButton, {ButtonIconProps} from 'components/NitroxButton';
import {useNavigation, useDisposableCallback} from 'hooks/Hooks';

const TAG = 'MobileHeaderMenu';

const styles = createStyles({
  headerIcon: {
    backgroundColor: constColors.transparent
  },
  imageIcon: {
    width: dimensions.icon.xxsmall,
    height: dimensions.icon.xxsmall
  }
});

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  iconColor: colors.screenHeader.icon
}));

const iconProps = (type: IconType): IconProps => ({
  type,
  size: dimensions.icon.xxsmall
});

const getButtonProps = (item: Menu, dynamicStyles: {iconColor: string}): {source: ImageURISource} | {icon: ButtonIconProps} | undefined => {
  const icon = getMenuIcon(item);

  if (!item.pictures.length && item.slug === DefaultMenuItemSlug.Settings) {
    // This is special sceario to use icon_user as default icon for settings on mobile devices
    return {icon: {...iconProps(IconType.User), color: dynamicStyles.iconColor}};
  } else if (icon.pngUrl?.inactive.uri) {
    return {source: icon.pngUrl.inactive};
  } else if (icon.svgType) {
    return {icon: {...iconProps(icon.svgType.inactive), color: dynamicStyles.iconColor}};
  }
};

type Props = {
  item: Menu;
  operatorLogoUrl: string;
}

const HeaderMenuOptionComponent: React.FC<Props> = props => {
  const {item, operatorLogoUrl} = props;
  const {navigate} = useNavigation();
  const onPress = useCallback(() => {
    if (item.isDisabled) {
      Log.warn(TAG, `${item.title} screen is disabled`);
      return;
    }
    navigate(item.screen, {operatorLogoUrl});
  }, [item.isDisabled, item.screen, item.title, navigate, operatorLogoUrl]);

  const dynamicStyles = dynamicStylesUpdater.getStyles();
  const source = useMemo(() => getButtonProps(item, dynamicStyles), [item, dynamicStyles]);

  return (
    <NitroxButton
      {...source}
      style={styles.headerIcon}
      imageStyle={styles.imageIcon}
      onPress={onPress}
    />
  );
};

const HeaderMenuOption = React.memo(HeaderMenuOptionComponent);

type HeaderMenuItem = {
  item: Menu;
  operatorLogoUrl: string;
}

const MobileHeaderMenuComponent: React.FC<{}> = () => {
  const [headerMenuItems, setHeaderMenuItems] = useState<HeaderMenuItem[]>([]);
  const setHeaderMenuItemsDisposable = useDisposableCallback((menu: Menu, items: Menu[]) => {
    if (items.length) {
      const headerMenuItems: HeaderMenuItem[] = items.map(item => (
        {item, operatorLogoUrl: getOperatorLogoUrl(menu, routeNameForMenu(item))}
      ));
      setHeaderMenuItems(headerMenuItems);
    }
  }, []);
  useEffect(() => {
    mw.cms.getMainMenu()
      .then(menu => {
        const items = menu.items.filter(item => item.position === MenuAlignment.TOP);
        setHeaderMenuItemsDisposable(menu, items);
      })
      .catch(error => Log.warn(TAG, 'Errors while fething mainMenu.', error));
  }, [setHeaderMenuItemsDisposable]);

  return (
    <>
      {headerMenuItems.map(headerMenuItem => (
        <HeaderMenuOption key={headerMenuItem.item.slug} item={headerMenuItem.item} operatorLogoUrl={headerMenuItem.operatorLogoUrl} />
      ))}
    </>
  );
};

export const MobileHeaderMenu = React.memo(MobileHeaderMenuComponent);
