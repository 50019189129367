import {Profile} from 'mw/api/Profile';

import {ModalProps} from './Modal';

export const ProfileSelectTag = 'ProfileSelect';

export type ProfileSelectModalProps = {
  visible?: boolean;
  fullscreen?: boolean;
  hasLogoutButton?: boolean;
  markCurrent?: boolean;
  title?: string;
  profilesType?: 'adult' | 'child' | 'any';
  initialFocusStrategy?: 'first' | 'current';
  portal?: string;
  onSelect?: (profileId: string) => void;
  onClose?: () => void;
  onCancel?: () => void;
  onLoading?: (param: boolean) => void;
  onAddNewPress?: () => void;
  /**
   * Whether profiles list should be loaded when displaying profile selector
   * (changing `visible` to `true`).
   *
   * Default value is `true`.
   */
  refreshProfilesOnAppear?: boolean;
} & Pick<ModalProps, 'focusNearestParentOnClose'>;

export type ProfileSelectProps = ProfileSelectModalProps & {
  profiles: Profile[];
  currentProfile?: Profile;
} & Required<Pick<ProfileSelectModalProps, 'onSelect'>>
