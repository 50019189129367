import {useRef} from 'react';
import {Animated} from 'react-native';

import {useFunction} from 'hooks/Hooks';

type UseAnimatedTimingParams = {
  easing?: (value: number) => number;
  duration?: number;
  delay?: number;
  useNativeDriver?: boolean;
}
export function useAnimatedTiming(value: Animated.Value, config: UseAnimatedTimingParams): {
  animateTo: (value: number) => void;
  stopAnimation: () => void;
  /**
   * Use to manually animate / create animation compositions. Note: you have to cancel previous animations manually!
   */
  createAnimation: (value: number) => Animated.CompositeAnimation;
} {
  const stopAnimationRef = useRef<() => void>();

  const stopAnimation = useFunction(() => {
    stopAnimationRef.current?.();
  });

  const createAnimation = useFunction((toValue: number) => {
    return Animated.timing(value, {...config, toValue});
  });

  const animateTo = useFunction((toValue: number) => {
    stopAnimation();
    const animation = createAnimation(toValue);
    stopAnimationRef.current = animation.stop;

    animation.start();
  });

  return {animateTo, stopAnimation, createAnimation};
}
