import {createStyles} from 'common-styles';
import React, {forwardRef, ReactNode, useCallback} from 'react';
import {GestureResponderEvent, StyleProp, View, ViewStyle} from 'react-native';

import {dimensions, isBigScreen, isMobile, RADIUS} from 'common/constants';
import {FocusOptions, TestProps} from 'common/HelperTypes';

import MouseAwareView from './MouseAwareView';
import NitroxInteractive from './NitroxInteractive';

const borderRadius = isMobile ? RADIUS : RADIUS * 2;

const styles = createStyles({
  contentView: {
    width: dimensions.tile.width,
    height: dimensions.tile.height,
    marginTop: dimensions.margins.small,
    marginHorizonal: dimensions.margins.small,
    borderRadius: borderRadius
  }
});

type TileContainerProps = {
  focusable?: boolean;
  style?: StyleProp<ViewStyle>;
  onPress: () => void;
  onFocus?: (event: any, options?: FocusOptions) => void;
  scrollOnFocus?: boolean;
  onFocusStateChanged?: (focused: boolean) => void;
  children: ReactNode;
} & TestProps;

type ContainerProps = {
  onPress: (e?: GestureResponderEvent) => void;
  onFocus: (event: any, options?: FocusOptions) => void;
  scrollOnFocus: boolean;
  children?: ReactNode;
  focusable?: boolean;
  onFocusStateChanged?: (focused: boolean) => void;
} & TestProps;

// eslint-disable-next-line react/display-name
const Container = forwardRef(((props, ref) => {
  return props.focusable ? (
    <NitroxInteractive
      ref={ref}
      onFocus={props.onFocus}
      scrollOnFocus={props.scrollOnFocus}
      onFocusStateChanged={props.onFocusStateChanged}
      onPress={props.onPress}
      activeOpacity={isBigScreen ? 1 : 6}
      testID={props.testID}
    >
      {props.children}
    </NitroxInteractive>
  ) : (
    <MouseAwareView
      testID={props.testID}
      onClick={props.onPress}
    >
      {props.children}
    </MouseAwareView>
  );
}) as React.FC<ContainerProps>);
Container.displayName = 'Container';

const TileContainer: React.FunctionComponent<TileContainerProps> = (props, ref) => {
  const {focusable = true, onPress: propagatePress, onFocus: propagateFocus, scrollOnFocus = true, onFocusStateChanged: focusStateChanged} = props;

  const onFocus = useCallback((event: any, options?: FocusOptions) => {
    propagateFocus?.(event, options);
  }, [propagateFocus]);

  return (
    <Container
      ref={ref}
      onFocus={onFocus}
      scrollOnFocus={scrollOnFocus}
      focusable={focusable}
      onPress={propagatePress}
      onFocusStateChanged={focusStateChanged}
      testID={props.testID}
    >
      <View testID='tile' style={[styles.contentView, props.style]}>
        {props.children}
      </View>
    </Container>
  );
};

export default forwardRef(TileContainer);
