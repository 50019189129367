import {createStyles} from 'common-styles';
import React from 'react';
import {View} from 'react-native';

import {dimensions, getValue} from 'common/constants';
import {doNothingAsync} from 'common/HelperFunctions';

import RecordingsList from 'components/pvr/RecordingsList';

import {RecordingsFolderContentProps} from './RecordingsFolderHelperTypes';

const styles = createStyles({
  container: {
    flex: 1,
    paddingHorizontal: getValue({tablet: dimensions.margins.xxLarge, defaultValue: dimensions.margins.large})
  }
});

const RecordingsFolderContentPiccolo: React.FC<RecordingsFolderContentProps> = React.memo(({
  recordings,
  selection,
  selectable,
  onTilePress,
  selectRecording
}) => {
  return (
    <View style={styles.container}>
      <RecordingsList
        recordings={recordings}
        requestRecordings={doNothingAsync}
        selectable={selectable}
        selection={selection}
        onTilePress={onTilePress}
        onSelectionCheckboxPress={selectRecording}
        isInsideFolder={true}
      />
    </View>
  );
});
RecordingsFolderContentPiccolo.displayName = 'RecordingsFolderContentPiccolo';

export default RecordingsFolderContentPiccolo;
