import {useState, useCallback, useEffect} from 'react';

import {useSafeAwait} from 'common/hooks/Hooks';

export function useAsyncUpdater<T>(updater: () => Promise<T>) {
  const [state, setState] = useState<T>();
  const [loading, setLoading] = useState(true);
  const safeAwait = useSafeAwait();

  // this is `useCallback` not `useFunction` to properly react to `updater` change
  const updateState = useCallback(async () => {
    setLoading(true);
    setState(await safeAwait(updater()));
    setLoading(false);
  }, [safeAwait, updater]);

  useEffect(() => {
    updateState();
  }, [updateState]);

  return {state, loading, update: updateState};
}
