import React from 'react';
import {ViewStyle, StyleSheet, requireNativeComponent, InteractionManager} from 'react-native';

import {debounce} from 'common/Async';
import {isTVOS, debugFeatures} from 'common/constants';
import {pointInRect} from 'common/HelperFunctions';
import {Hashmap, Rect, Point, Size} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {Event} from 'mw/api/Metadata';

import FocusParent from 'components/FocusParent';
import {STBMenuContextType, STBMenuContext} from 'components/navigation/NavigationHelperTypes';
import {UseFocusOnAppear} from 'hooks/HookComponents';

import EpgFocusHolder, {EpgFocusableElement, epgCursorWidth} from './EpgFocusHolder';
import {RenderedItem} from './EpgNitroxContentView';

const FocusBarrierView = isTVOS ? requireNativeComponent('FocusBarrier') : <></>;

const TAG = 'EpgFocusDriver';

// This value decides whether to inject additional focus holder for long events
// to prevent chaotic scrolling when focus is on channel with long events
const cursorLatitude = 800;
const debounceTimeout = 50;
const debugMode = debugFeatures.epgFocusDriver;

interface EpgFocusDriverProps {
  style?: ViewStyle;
  entranceItemPoint: Point;
}

export class EpgFocusDriver extends React.PureComponent<EpgFocusDriverProps> {
  public static contextType = STBMenuContext;

  private focusableItems: Hashmap<RenderedItem<Event>> = {};
  private visibleRect: Rect = {x: 0, y: 0, width: 0, height: 0};
  private cursor?: Point;
  /** Item that is focused but may still be waiting to be highlighted due to delay between navigation actions and tile rendering and mounting by the EpgNitroxContentView. */
  private focusedItem?: EpgFocusableElement;
  /** Item that gained the focus and was successfully highlighted. */
  private highlightedItem?: EpgFocusableElement;
  private previousEntrancePoint?: Point = undefined;
  private focusViewTimerId: number | null = null;

  private debounceForceUpdate = debounce(() => {
    const handle = InteractionManager.createInteractionHandle();
    this.forceUpdate(() => InteractionManager.clearInteractionHandle(handle));
  }, debounceTimeout);

  public componentWillUnmount() {
    this.debounceForceUpdate.abort();
    this.cancelFocusView();
  }

  private cancelFocusView() {
    if (this.focusViewTimerId) {
      clearTimeout(this.focusViewTimerId);
      this.focusViewTimerId = null;
    }
  }

  /**
   * Holder may not have view to pass focus to.
   * Pospone focusing event tile until it's rendered
   */
  private focusView = () => {
    this.cancelFocusView();
    if (this.isMenuFocused()) {
      return;
    }
    const focusedTile = this.focusedItem?.ref?.current;
    if (focusedTile?.isMounted()) {
      focusedTile.onFocus();
      this.highlightedItem = this.focusedItem;
    } else {
      this.focusViewTimerId = setTimeout(this.focusView, debounceTimeout);
    }
  }

  private onFocus = (item: EpgFocusableElement) => {
    Log.info(TAG, 'Currently focused element: ' + item.id);

    // TODO: remove manual onBlur on old item when React implementation onBlur works realiably
    const highlightedTile = this.highlightedItem?.ref?.current;
    if (highlightedTile?.isMounted() && !this.isItemHighlighted(item)) {
      highlightedTile.onBlur();
    }

    this.cursor = {x: item.x, y: item.y};
    this.focusedItem = item;
    this.focusView();

    this.debounceForceUpdate();
  }

  private isMenuFocused() {
    return (this.context as STBMenuContextType)?.hasFocus;
  }

  private isItemHighlighted(item: EpgFocusableElement) {
    return this.focusedItem && this.focusedItem.originId === item.originId;
  }

  private onBlur = (item: EpgFocusableElement) => {
    const itemTile = item.ref?.current;
    if (itemTile?.isMounted() && (this.isMenuFocused() || !this.isItemHighlighted(item))) {
      itemTile.onBlur();
    }
  }

  private onPress = (item: EpgFocusableElement) => {
    const itemTile = item.ref?.current;
    if (itemTile?.isMounted()) {
      itemTile.onPress();
    }
  }

  public onVisibleFrameSizeChanged({width, height}: Size) {
    this.visibleRect.width = width;
    this.visibleRect.height = height;
  }

  public onVisibleFramePositionChanged({x, y}: Partial<Point>) {
    if (typeof x !== 'undefined') {
      this.visibleRect.x = x;
    }
    if (typeof y !== 'undefined') {
      this.visibleRect.y = y;
    }
  }

  public newLayout(items: Hashmap<RenderedItem<Event>>) {
    if (Object.keys(items).length === 0) {
      Log.debug(TAG, 'newLayout received 0 items. Return');
      return;
    }

    this.focusableItems = {...items};

    // TODO:
    // Can't use forceFocus ad hoc due to 'Cannot update during an existing state transition' error
    this.debounceForceUpdate();
  }

  public frameForFocusedItem(): Rect | null {
    return this.focusedItem && {
      x: this.focusedItem.x,
      y: this.focusedItem.y,
      width: epgCursorWidth,
      height: this.focusedItem.height
    } || null;
  }

  private isCursorInItem(item: Rect) {
    return this.cursor && item.x <= this.cursor.x && this.cursor.x < (item.x + item.width);
  }

  private createHolder(item: RenderedItem<Event>, x: number): EpgFocusableElement {
    return {
      ref: item.ref,
      id: `${x}.${item.y}.${item.width}`,
      x,
      y: item.y,
      width: item.width,
      height: item.height,
      originId: item.key
    };
  }

  private computeXFor(item: Rect): number {
    let x = item.x;
    if (!this.cursor || item.y !== this.cursor.y) {
      x = (this.cursor || item).x;
    }

    if (this.cursor
      && item.x <= this.cursor.x
      && this.cursor.x < (item.x + item.width)) {
      x = this.cursor.x;
    }
    return x;
  }

  private renderHolder = (holder?: EpgFocusableElement, index?: number): React.ReactElement | null => {
    if (!holder) {
      return null;
    }

    const {ref, ...holderProps} = holder;

    /**
     * Boolean responsible for focusing first rendered holder when nothing has been focused previously
     */
    const firstRenderedHolder = index === 0 && (!this.focusedItem || !this.cursor);

    /**
     * Focused holder may be remounted when pages are changed rapidly.
     * Boolean below restores focus to last focused holder on mount
     */
    const lastFocusedHolder = (holder.id === this.focusedItem?.id);

    return (
      <EpgFocusHolder
        {...holderProps}
        holderRef={ref}
        key={'focus-item-' + holder.id}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onPress={this.onPress}
        focusOnMount={firstRenderedHolder || lastFocusedHolder}
      />
    );
  }

  private focusBarriersForIos(holders: EpgFocusableElement[]): typeof FocusBarrierView[] {
    const cursor = this.cursor;
    if (!isTVOS || !holders.length || !cursor) {
      return [];
    }

    const focusBarriers: (typeof FocusBarrierView)[] = [];
    const commonStyle = {
      ...(debugMode && {backgroundColor: 'red'}),
      position: 'absolute',
      left: cursor.x,
      top: cursor.y,
      width: epgCursorWidth,
      height: holders[0].height
    };

    // Find location of horizontal barriers
    const horizontalHolders = holders.filter(holder => holder.y === cursor.y);
    if (horizontalHolders.length) {
      const [minX, maxX] = horizontalHolders.reduce(([min, max], holder) => {
        return [Math.min(min, holder.x), Math.max(max, holder.x + epgCursorWidth)];
      }, [horizontalHolders[0].x, horizontalHolders[0].x]);

      focusBarriers.push(<FocusBarrierView style={[commonStyle, {left: minX - epgCursorWidth - 1}]} key={`FocusBarrierMinX${minX}`} />);
      focusBarriers.push(<FocusBarrierView style={[commonStyle, {left: maxX + 1}]} key={`FocusBarrierMaxX${maxX}`} />);
    }

    // Find locations of vertical barriers
    const verticalHolders = holders.filter(holder => holder.x === cursor.x);
    if (verticalHolders.length) {
      const [minY, maxY] = verticalHolders.reduce(([min, max], holder) => {
        return [Math.min(min, holder.y), Math.max(max, holder.y + holder.height)];
      }, [verticalHolders[0].y, verticalHolders[0].y]);

      minY > 0 && focusBarriers.push(<FocusBarrierView style={[commonStyle, {top: minY - verticalHolders[0].height}]} key={`FocusBarrierMinY${minY}`} />);
      focusBarriers.push(<FocusBarrierView style={[commonStyle, {top: maxY + 1}]} key={`FocusBarrierMaxY${maxY}`} />);
    }
    return focusBarriers;
  }

  /**
   * We define "far outside" as outside of visibleRect
   * with a margin of the same size in every direction.
   */
  private isCursorFarOutsideVisibleRect(cursor: Point) {
    return this.visibleRect.x && this.visibleRect.width && this.visibleRect.height && !pointInRect(cursor, {
      x: this.visibleRect.x - this.visibleRect.width,
      width: this.visibleRect.width * 3,
      y: this.visibleRect.y - this.visibleRect.height,
      height: this.visibleRect.height * 3
    });
  }

  /**
   * When user triggers apple tv's fast scroll feature by swiping on the edge of the remote,
   * it is possible to scroll whole epg vertically with one swipe.
   * When that happens, i.e. cursor is far outside visible rect, we should reset it to the middle of the screen.
   */
  private shouldResetCursorAfterFastScroll(cursor: Point) {
    return isTVOS && this.isCursorFarOutsideVisibleRect(cursor);
  }

  private visibleRectMiddlePoint(): Point {
    return {
      x: this.visibleRect.x + this.visibleRect.width / 2,
      y: this.visibleRect.y + this.visibleRect.height / 2
    };
  }

  public render() {
    const entranceItemPointChanged = this.props.entranceItemPoint !== this.previousEntrancePoint;
    if (entranceItemPointChanged) {
      this.cursor = undefined;
    }
    this.previousEntrancePoint = this.props.entranceItemPoint;

    let cursor = this.cursor || this.props.entranceItemPoint;

    if (!entranceItemPointChanged && this.shouldResetCursorAfterFastScroll(cursor)) {
      Log.warn(TAG, 'Scrolled out epg so that cursor is far outside visibleRect, resetting cursor to: ', cursor);
      this.cursor = undefined;
      cursor = this.visibleRectMiddlePoint();
    }

    const focusCandidates: RenderedItem<Event>[] = Object.values(this.focusableItems)
      .filter(item => {
        if (!this.cursor) {
          return pointInRect(cursor, {
            ...item,
            // make sure this doesn't match adjacent tiles
            width: item.width - 1,
            height: item.height - 1
          });
        }
        const onVerticalLine = item.x <= this.cursor.x && this.cursor.x < (item.x + item.width);
        const onHorizontalLine = item.y === this.cursor.y;
        return onHorizontalLine || onVerticalLine;
      });

    let allHolders: EpgFocusableElement[];

    if (!focusCandidates.length) {
      allHolders = [];
    } else if (!this.cursor) {
      // before initial focus is set, render only one holder
      allHolders = [this.createHolder(focusCandidates[0], cursor.x)];
    } else {
      // update this to reflect maximum count of holders created by the reduce below
      const maxFocusHoldersCount = focusCandidates.length * 4;
      let index = 0;
      const focusHolders = focusCandidates.reduce((allHolders, item) => {
        // render holder at the beginning of tile or on cursor if the tile is currently focused
        allHolders[index++] = this.createHolder(item, this.computeXFor(item));

        // Add additional focus holder on left side of the cursor when long event is focused
        if (item.y === cursor.y && this.isCursorInItem(item) && (cursor.x - item.x) > cursorLatitude) {
          const distanceToEdge = cursor.x - item.x;
          const distanceToHolder = Math.min(distanceToEdge, 2 * cursorLatitude);
          allHolders[index++] = this.createHolder(item, cursor.x - distanceToHolder);
        }
        // Add additional focus holder on right side of the cursor when long event is focused
        if (item.y === cursor.y && this.isCursorInItem(item) && (item.x - cursor.x + item.width) > cursorLatitude) {
          const distanceToEdge = item.x + item.width - cursor.x - epgCursorWidth;
          const distanceToHolder = Math.min(distanceToEdge, 2 * cursorLatitude);
          allHolders[index++] = this.createHolder(item, cursor.x + distanceToHolder);
        }

        // Add additional focus holder for unfocused event if its beginning
        // is far to the left of visible rectangle to avoid scrolling a great
        // distance in x axis at once.
        if (item.y === cursor.y && !this.isCursorInItem(item) && item.x < this.visibleRect.x - cursorLatitude && item.width > cursorLatitude) {
          const x = Math.max(item.x, Math.min(this.visibleRect.x - cursorLatitude / 2, item.x + item.width - 1));
          allHolders[index++] = this.createHolder(item, x);
        }

        return allHolders;
      }, new Array<EpgFocusableElement>(maxFocusHoldersCount));

      allHolders = focusHolders.slice(0, index);
    }

    return (
      <FocusParent key={'EpgFocusDriverMainView'} style={StyleSheet.absoluteFill} rememberLastFocused>
        <UseFocusOnAppear />
        {allHolders.map(this.renderHolder)}
        {this.cursor && this.focusBarriersForIos(allHolders)}
      </FocusParent>
    );
  }
}
