import {boProxy} from 'mw/bo-proxy/BOProxy';

import {EntitledProduct} from './EntitledProductInterface';

export class EntitledProducts {
  public products: Map<string, EntitledProduct> = new Map<string, EntitledProduct>();

  public refresh(): Promise<void> {
    return boProxy.bo.getEntitledProducts()
      .then(products => {
        this.products = this.parseProducts(products);
      });
  }

  private parseProducts(products: EntitledProduct[]): Map<string, EntitledProduct> {
    const entitledProducts = new Map<string, EntitledProduct>();
    products.forEach(entitledProduct => {
      const ep = entitledProducts.get(entitledProduct.product.id);
      if (!ep) {
        entitledProducts.set(entitledProduct.product.id, entitledProduct);
        return;
      }
      if (entitledProduct.media?.length) {
        ep.media?.push(...entitledProduct.media);
      }
    });
    return entitledProducts;
  }
}
