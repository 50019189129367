import {createStyles} from 'common-styles';
import React, {Component} from 'react';
import {StyleSheet, View} from 'react-native';
import {NavigationInjectedProps} from 'react-navigation';

import {disposable} from 'common/Async';
import {isWeb, dimensions} from 'common/constants';
import {idKeyExtractor, isSeriesEpisode} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Media, Title, Series, Episode} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import {Icon, IconType} from 'components/Icon';
import {FAR_FAR_AWAY} from 'components/navigation/ResourceSavingScene';
import NitroxFlatList from 'components/NitroxFlatList';
import NitroxInteractive from 'components/NitroxInteractive';
import {withParentalControl, ParentalControlContextType} from 'components/parentalControl/ParentalControlProvider';
import PosterTile from 'components/PosterTile';
import {WithUnlockModal, withUnlockModal, UnlockModalRejectionReason} from 'components/unlockmodal/UnlockModal';
import VodSeriesMobileOverlaySeasonPicker from 'components/vod/VodSeriesMobileOverlaySeasonPicker';
import {DetailsButtonsHandlers} from 'components/zapper/MediaDetailsTemplate';

import VodSeriesMobileDetailsOverlay from './VodSeriesMobileDetailsOverlay';

const TAG = 'VodSeriesMobileOverlay';

export const closeSize = dimensions.icon.large;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: colors.playerScreen.overlay
  },
  content: {
    marginLeft: 45,
    marginRight: 45,
    marginTop: 25
  },
  topRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: 20
  },
  hidden: {
    top: FAR_FAR_AWAY,
    position: 'absolute',
    width: '100%',
    height: '100%',
    ...isWeb ? {visibility: 'hidden'} : {}
  },
  closeButtonIconColor: colors.playerScreen.closeIcon
}));

type CloseButtonProps = {
  onPress: () => void;
};

function CloseButtonComponent(props: CloseButtonProps) {
  const styles = stylesUpdater.getStyles();
  return (
    <NitroxInteractive
      onPress={props.onPress}
    >
      <Icon
        type={IconType.BackSTB}
        size={closeSize}
        color={styles.closeButtonIconColor}
      />
    </NitroxInteractive>
  );
}
export const CloseButton = React.memo(CloseButtonComponent);

type Props = {
  title: Title;
  visible: boolean;
  onClose: () => void;
  handlers: DetailsButtonsHandlers;
} & NavigationInjectedProps & WithUnlockModal & ParentalControlContextType;

type State = {
  seasonEpisodes: Media[];
  currentSeason?: Series;
  seasons: Series[];
  currentEpisode?: Media; //to [show/hide] episodes overlay set object [valid media/undefined]
}

class VodSeriesMobileOverlay extends Component<Props, State> {

  private readonly getSeriesSeasonsById = disposable(mw.catalog.getSeriesSeasonsById.bind(mw.catalog));
  private readonly getSeasonEpisodesById = disposable(mw.catalog.getSeasonEpisodesById.bind(mw.catalog));
  private detailsOverlayHandlers = {
    onWatch: (media: Media) => {
      this.props.handlers.onWatch(media);
      this.props.onClose();
    }
  };

  public constructor(props: Props) {
    super(props);
    this.state = {
      seasonEpisodes: [],
      seasons: []
    };
  }

  public componentDidMount() {
    const episode = this.props.title.episode && isSeriesEpisode(this.props.title) ? this.props.title.episode : undefined;
    if (!episode) {
      Log.error(TAG, 'componentDidMount: title is not an episode. We should not be here');
      return;
    }

    this.fetchSeasonEpisodes(episode.seasonId);
    this.fetchSeasons(episode);
  }

  public componentWillUnmount() {
    this.getSeriesSeasonsById.dispose();
    this.getSeasonEpisodesById.dispose();
  }

  //TODO: consider functions with binding, they are faster than arrow;
  private fetchSeasonEpisodes = async (seasonId: string) => {
    this.setState({seasonEpisodes: await this.getSeasonEpisodesById(seasonId)});
  }

  private fetchSeasons = async (episode: Episode) => {
    if (this.state.seasons.length !== 0) {
      Log.info(TAG, 'fetchSeasons: seasons already fetched, return');
      return;
    }

    const seasons = await this.getSeriesSeasonsById(episode.seriesId);
    this.setState({
      seasons: seasons,
      currentSeason: seasons.find(season => season.id === episode.seasonId)
    });
  }

  private onNewSeasonSelected = (seasonId: string | number | undefined) => {
    if (typeof seasonId !== 'string' || !this.state.seasons) {
      return;
    }

    if (this.state.currentSeason && this.state.currentSeason.id === seasonId) {
      return;
    }

    const season = this.state.seasons.find(season => season.id === seasonId);
    this.setState({currentSeason: season});
    this.fetchSeasonEpisodes(seasonId);
  }

  private showEpisodeDetails = (media: Media) => {
    this.setState({currentEpisode: media});
  }

  private dismissEpisodeDetails = () => {
    this.setState({currentEpisode: undefined});
  }

  private onItemPress = (media: Media) => {
    if (this.props.shouldBeCheckedForPC(media) && this.props.isMediaBlocked(media)) {
      this.props.authorizeWithAdultProfile()
        .then(() => this.showEpisodeDetails(media))
        .catch(error => {
          if (error.reason !== UnlockModalRejectionReason.Cancel) {
            Log.error(TAG, 'Error when authorizing with pin: ', error);
          }
        });
    } else {
      this.showEpisodeDetails(media);
    }
  }

  private renderEpisodeItem = ({item}: {item: Media}) => {
    return <PosterTile media={item} onPress={this.onItemPress} />;
  }

  private renderSeriesEpisodes = () => {
    const styles = stylesUpdater.getStyles();
    return (
      <React.Fragment>
        <View style={styles.topRow}>
          <CloseButton onPress={this.props.onClose} />
          {this.state.currentSeason && isSeriesEpisode(this.props.title) && (
            <VodSeriesMobileOverlaySeasonPicker
              season={this.state.currentSeason}
              seasons={this.state.seasons}
              onSeasonSelected={this.onNewSeasonSelected}
            />
          )}
        </View>
        <NitroxFlatList
          horizontal
          data={this.state.seasonEpisodes}
          renderItem={this.renderEpisodeItem}
          keyExtractor={idKeyExtractor}
        />
      </React.Fragment>
    );
  }

  private renderEpisodeDetails(media: Media) {
    const currentlyPlayerMedia = media.id === this.props.title.id;

    return (
      <VodSeriesMobileDetailsOverlay
        media={media}
        currentlyPlayedMedia={currentlyPlayerMedia}
        handlers={this.detailsOverlayHandlers}
        onClose={this.dismissEpisodeDetails}
      />
    );
  }

  public render() {
    const styles = stylesUpdater.getStyles();
    return (
      <>
        <View style={[styles.container, this.props.visible ? {} : styles.hidden]}>
          <View style={styles.content}>
            {!this.state.currentEpisode ? this.renderSeriesEpisodes() : this.renderEpisodeDetails(this.state.currentEpisode)}
          </View>
        </View>
        {this.props.renderUnlockModal()}
      </>
    );
  }
}

export default withParentalControl(withUnlockModal(VodSeriesMobileOverlay));
