import {SearchField} from 'mw/api/CatalogInterface';

export enum SearchFilterKey {
  All = 'all',
  Title = 'title',
  CastAndCrew = 'castAndCrew',
  Description = 'description',
}

export type SearchFilterValue = SearchField | SearchFilterKey.All;

export type SearchFilterOptionItemType = {
  value: SearchFilterValue;
  labelTranslationKey: string;
}

export type SearchFilterOptionsKey = {[key in SearchFilterValue]: string}

export const SearchFilterOptionsKeys: SearchFilterOptionsKey = {
  [SearchFilterKey.All]: 'filter.all',
  [SearchFilterKey.Title]: 'filter.title',
  [SearchFilterKey.CastAndCrew]: 'filter.cast',
  [SearchFilterKey.Description]: 'filter.synopsis'
};
