import {ViewershipError} from './ViewershipError';

export enum ViewershipEventStatus {
  Open = 'Open',
  Closed = 'Closed'
}

export enum TransactionType {
  FVOD = 'FVOD',
  TVOD = 'TVOD',
  EST = 'EST',
  SVOD = 'SVOD'
}

export enum DistributionType {
  Streaming = 'Streaming',
  Download = 'Download'
}

export enum EventType {
  StopPlayback = '001',
  EndOfContent = '002',
  ErrorDuringPlayback = '003'
}

export class ViewershipEvent {
  public eventId: string;
  public eventType?: EventType | null = null;
  public eventTimestamp: number;
  public transactionType: TransactionType;
  public distributionType: DistributionType;
  public assetId: string;
  public assetName: string;
  public contentProviderId?: string | null = null;
  public contentProviderName?: string | null = null;
  public duration: number;
  public startIndex: number;
  public stopIndex?: number | null = null;
  public status: ViewershipEventStatus;
  public errorCode?: ViewershipError | null = null;

  public constructor(viewershipEvent: {
    eventId: string;
    eventTimestamp: number;
    transactionType: TransactionType;
    distributionType: DistributionType;
    assetId: string;
    assetName: string;
    contentProviderId?: string;
    contentProviderName?: string;
    duration: number;
    startIndex: number;
    status: ViewershipEventStatus;
  }) {
    this.eventId = viewershipEvent.eventId;
    this.eventTimestamp = viewershipEvent.eventTimestamp;
    this.transactionType = viewershipEvent.transactionType;
    this.distributionType = viewershipEvent.distributionType;
    this.assetId = viewershipEvent.assetId;
    this.assetName = viewershipEvent.assetName;
    this.contentProviderId = viewershipEvent.contentProviderId || null;
    this.contentProviderName = viewershipEvent.contentProviderName || null;
    this.duration = viewershipEvent.duration;
    this.startIndex = viewershipEvent.startIndex;
    this.status = viewershipEvent.status;
  }

  public closeEvent(): void {
    this.status = ViewershipEventStatus.Closed;
  }

  public setEventType(eventType: EventType): void {
    this.eventType = eventType;
  }
}
