import {Media, MediaType} from 'mw/api/Metadata';
import {nxffConfig} from 'mw/api/NXFF';
import {PlaybackAsset} from 'mw/bo-proxy/BOInterface';

import {BPKSessionManager} from './BPKSessionManager';

export class CDNSession {
  public readonly id: string;
  public readonly url: string;
  public cdnSessionManager: CDNSessionManager;

  public constructor(id: string, url: string, cdnSessionManager: CDNSessionManager) {
    this.id = id;
    this.url = url;
    this.cdnSessionManager = cdnSessionManager;
  }

  public onFirstFrame() {
    this.cdnSessionManager.onFirstFrame();
  }

  public terminate(): Promise<void> {
    return this.cdnSessionManager.destroySession(this);
  }
}

export interface CDNSessionManager {
  createSession(url: string, params: any): Promise<CDNSession>;
  destroySession(session: CDNSession): Promise<void>;
  onFirstFrame(): void;
}

export class DummyCDNSessionManager implements CDNSessionManager {
  public async createSession(url: string, params: any): Promise<CDNSession> {
    return new CDNSession('', url, this);
  }

  public async destroySession(session: CDNSession): Promise<void> {}
  public onFirstFrame() {}
}

export class CDNSessionManagerFactory {
  private static isBPKEnabled(): boolean {
    return nxffConfig.getConfig().Playback.CDNType === 'BROADPEAK';
  }

  private static isBPKSessionRequired(media: Media, asset: PlaybackAsset): boolean {
    // for audio media there is no need for BPK support
    if (media.getType() === MediaType.Audio) {
      return false;
    }
    // otherwise check if the asset is going to be played from broadpeak of external server
    const features = nxffConfig.getConfig().Playback;
    return asset.url.startsWith('http') && (!features.CDNURL || asset.url.startsWith(features.CDNURL));
  }

  public static getCDNSessionManager(media: Media, asset: PlaybackAsset): CDNSessionManager {
    return CDNSessionManagerFactory.isBPKEnabled() && CDNSessionManagerFactory.isBPKSessionRequired(media, asset)
      ? new BPKSessionManager()
      : new DummyCDNSessionManager();
  }
}
