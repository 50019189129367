import moment from 'moment';

import {formatGenres} from 'common/HelperFunctions';

import {Event} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import {IconType} from 'components/Icon';

export type EpgMenuAction = {
  key: string;
  text: string;
  icon?: IconType;
  onPress: () => void;
}

export function formatEventInfo(event: Event): string {
  let info = moment(event.start).format(mw.configuration.timeFormat) + ' - ' + moment(event.end).format(mw.configuration.timeFormat);
  const metadata = event.title.metadata;
  if (metadata.genres.length) {
    info += ' | ' + formatGenres(metadata.genres);
  }
  return info;
}
