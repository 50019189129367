import i18next from 'i18next';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {WatchListSorting, SortWatchListBy} from 'mw/api/Metadata';

import SortOrderSelect, {SortOrderSelectProps, SortOption, sortOrderAscendingIconProps, sortOrderDescendingIconProps, createOption} from 'components/SortOrderSelect';

const sortOptionsFactory: (t: i18next.TFunction) => SortOption<WatchListSorting>[] = t => ([
  createOption<WatchListSorting>(
    {
      type: SortWatchListBy.title,
      ascending: true
    },
    t('sorting.name'),
    sortOrderAscendingIconProps
  ),
  createOption<WatchListSorting>(
    {
      type: SortWatchListBy.title,
      ascending: false
    },
    t('sorting.name'),
    sortOrderDescendingIconProps
  ),
  createOption<WatchListSorting>(
    {
      type: SortWatchListBy.productionYear,
      ascending: true
    },
    t('sorting.year'),
    sortOrderAscendingIconProps
  ),
  createOption<WatchListSorting>(
    {
      type: SortWatchListBy.productionYear,
      ascending: false
    },
    t('sorting.year'),
    sortOrderDescendingIconProps
  )
]);

type Props = Omit<SortOrderSelectProps<WatchListSorting>, 'options'>

const WatchListSortOrderSelect: React.FC<Props> = props => {
  const {t} = useTranslation();
  const options = useMemo(() => sortOptionsFactory(t), [t]);
  return (
    <SortOrderSelect<WatchListSorting>
      {...props}
      options={options}
    />
  );
};

export default React.memo(WatchListSortOrderSelect);

