import {OrderResponse, AssetAccessResponse, AssetAccessStatus} from 'mw/bo-proxy/bo/adr8/ADR8Types';

import OrderDataSet, {OrderData} from './OrderDataSet';

export {OrderData};

export default class OrderResponseHandler {
  private readonly paymentProcessingOrders = new OrderDataSet();

  public markOrderIsProcessingPayment(order: OrderData): void {
    this.paymentProcessingOrders.add(order);
  }

  public isOrderProcessingPayment(orderId: string): boolean {
    return this.paymentProcessingOrders.hasOrderId(orderId);
  }

  public handleOrderResponse(response: OrderResponse): OrderResponse {
    const orderId = response.id;
    if (!this.isOrderProcessingPayment(orderId)) {
      return response;
    }

    if (response.orderStatus === 'PENDING_PAYMENT') {
      return {...response, orderStatus: 'PROCESSING_PAYMENT'};
    }

    this.paymentProcessingOrders.removeByOrderId(orderId);
    return response;
  }

  public handleAssetAccessResponse(assetId: string, response: AssetAccessResponse): AssetAccessResponse {
    if (!this.paymentProcessingOrders.hasMediaId(assetId)) {
      return response;
    }

    if (response.status === AssetAccessStatus.Unavailable) {
      return {...response, status: AssetAccessStatus.Purchasing};
    }

    this.paymentProcessingOrders.removeByMediaId(assetId);
    return response;
  }
}
