import {createStyles} from 'common-styles';
import React, {useState, useCallback} from 'react';
import {ScrollView, View} from 'react-native';

import {dimensions, isMobile, isBigScreen} from 'common/constants';

import {PinProtectionLevel} from 'mw/api/PinProtection';
import {mw} from 'mw/MW';

import DoubleBackground from 'components/DoubleBackground';
import FocusParent from 'components/FocusParent';
import {WizardElements} from 'components/Wizard';
import SettingsChangeLanguage from 'screens/settings/SettingsChangeLanguage';
import SettingsChangePin from 'screens/settings/SettingsChangePIN';
import SettingsTogglePINProtection from 'screens/settings/SettingsTogglePINProtection';

const styles = createStyles({
  container: {
    alignItems: isBigScreen ? 'stretch' : 'center',
    marginVertical: dimensions.margins.medium,
    paddingHorizontal: dimensions.wizard.configScreen.horizontalPadding
  },
  topChild: {
    justifyContent: 'center',
    alignItems: 'center',
    ...isMobile && {
      paddingTop: dimensions.margins.small,
      paddingBottom: dimensions.margins.xLarge
    }
  },
  bottomChild: {
    marginTop: dimensions.wizard.configScreen.doubleBackgroundOverlap + dimensions.margins.large
  },
  actions: {
    alignItems: 'center',
    marginTop: dimensions.margins.xxxxLarge
  }
});

type WizardConfigurationPageProps = {
  pin?: string;
  pinRequired?: boolean;
  uiLanguage?: string;
  audioLanguage?: string;
  subtitleLanguage?: string;
  onPinChanged: (pin: string) => void;
  onProtectWithPINChanged: (isProtected: boolean) => void;
  onUILanguageChanged: (interfaceLanguage: string) => void;
  onAudioLanguageChanged: (audioLanguage: string) => void;
  onSubtitlesLanguageChanged: (subtitlesLanguage: string) => void;
};

const WizardConfigurationPage: React.FC<WizardConfigurationPageProps & WizardElements> = props => {
  const {
    pinRequired,
    uiLanguage,
    audioLanguage,
    subtitleLanguage,
    onPinChanged: onPropsPINChanged,
    onProtectWithPINChanged,
    onUILanguageChanged,
    onAudioLanguageChanged,
    onSubtitlesLanguageChanged,
    forwardButton,
    header,
    tracker
  } = props;
  const [pin, setPin] = useState(props.pin); // cannot use props pin, since props pin doesn't change
  const onPINChanged = useCallback((value: string) => {
    setPin(value);
    onPropsPINChanged(value);
  }, [onPropsPINChanged]);

  const pinProtectionAvailable = mw.configuration.pinProtectionLevel === PinProtectionLevel.Profile;

  const content = (
    <ScrollView contentContainerStyle={styles.container} alwaysBounceVertical={false}>
      {pinProtectionAvailable && (
        <>
          <SettingsChangePin onPINChanged={onPINChanged} initialValue={pin} pinSet={!!pin} />
          <SettingsTogglePINProtection
            onProtectWithPINChanged={onProtectWithPINChanged}
            onPINChanged={onPINChanged}
            pinRequired={pinRequired}
            pinSet={!!pin}
          />
        </>
      )}
      <SettingsChangeLanguage type='uiLanguages' onLanguageChanged={onUILanguageChanged} initialValue={uiLanguage || 'en'} />
      <SettingsChangeLanguage type='audioLanguages' onLanguageChanged={onAudioLanguageChanged} initialValue={audioLanguage || 'auto'} />
      <SettingsChangeLanguage type='subtitlesLanguages' onLanguageChanged={onSubtitlesLanguageChanged} initialValue={subtitleLanguage || 'off'} />
    </ScrollView>
  );

  return (
    <DoubleBackground
      topChild={(
        <View>
          {header}
          {tracker}
        </View>
      )}
      topStyle={styles.topChild}
      bottomChild={(
        <View>
          {content}
          {forwardButton && (
            <FocusParent style={styles.actions}>
              {forwardButton}
            </FocusParent>
          )}
        </View>
      )}
      bottomStyle={styles.bottomChild}
      overlap={dimensions.wizard.configScreen.doubleBackgroundOverlap}
    />

  );
};

export default React.memo(WizardConfigurationPage);
