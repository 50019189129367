import {useState} from 'react';

import {isWeb} from 'common/constants';
import {NavigationFocusState} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {BlockedByPCEventState} from 'mw/api/Metadata';
import {PlayerEvent} from 'mw/api/PlayerEvent';
import {mw} from 'mw/MW';

import {TuneRequestState, PlayerManager} from 'components/player/PlayerManager';
import {useDisposableCallback, useEventListener, useFunction} from 'hooks/Hooks';

import {TunePipParams, TunePipCallback, PlayerView} from './EpgHelperTypes';

export function usePipPlayerVisibility(TAG: string, playerView: PlayerView, focusState: NavigationFocusState) {
  const [isPlayerBlocked, setIsPlayerBlocked] = useState<boolean>(false);

  const tunePip: TunePipCallback = useDisposableCallback(async (tuneParams: TunePipParams) => {
    try {
      // try to use previously tuned channel, if not tune the first channel on the lineup
      const currentMedia = mw.players.main.getCurrentMedia();
      const params = Object.assign({playRate: 1}, tuneParams.params);
      const currentPlayer = mw.players.main.getPlayerViewId();
      if (currentMedia
          && (!isWeb || currentPlayer === tuneParams.playerView) // TODO: CL-1433 Remove checking playerView after switchPlayerView gets implemented
          && (!tuneParams.channel || tuneParams.channel && tuneParams.channel.id === currentMedia.id && !tuneParams.forceTuneAttempt)) {
        Log.debug(TAG, 'No need to tune media again');

        if (tuneParams.playerView !== currentPlayer) { // FIXME: CL-1433 this is just a race workaround
          await mw.players.main.switchPlayerView(tuneParams.playerView);
        }
        if (tuneParams.callback) {
          const tuneRequest = {media: currentMedia, playerView: tuneParams.playerView, parameters: params, callback: tuneParams.callback};
          tuneParams.callback(TuneRequestState.Completed, tuneRequest);
        }
      } else {
        const pipChannel = tuneParams.channel || await mw.catalog.getLastPlayedChannel();
        PlayerManager.getInstance().tune(pipChannel, tuneParams.playerView, params, tuneParams.forceTuneAttempt, tuneParams.callback);
      }
    } catch (error) {
      Log.debug(TAG, 'Failed to get last played channel, error', error);
    }
  }, []);

  const setPlayerVisibility = useFunction(({blocked}: BlockedByPCEventState) => {
    if (focusState === NavigationFocusState.IsFocused) {
      setIsPlayerBlocked(blocked);
    }
  });

  useEventListener(PlayerEvent.BlockedByParentalControl, setPlayerVisibility, mw.players.main);

  return {isPlayerBlocked, tunePip};
}
