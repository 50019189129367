import {asyncStorage} from 'mw/platform/async-storage/AsyncStorage';

export function convertJSDateToJSONDate(jsDate: Date, includeTime = true): string {
  let jsonDate = `${jsDate.getUTCFullYear()}`;
  function prepend(prefix: string, value: number) {
    jsonDate += prefix + `${value}`.padStart(2, '0');
  }
  prepend('-', jsDate.getUTCMonth() + 1);
  prepend('-', jsDate.getUTCDate());
  if (includeTime) {
    prepend('T', jsDate.getUTCHours());
    prepend(':', jsDate.getUTCMinutes());
    prepend(':', jsDate.getUTCSeconds());
  }
  return jsonDate;
}

export function convertJSONDateToJSDate(jsonDate: string, result?: Date): Date {
  const jsDate = /^(\d{4})-?(\d{2})-?(\d{2})T(\d{2}):?(\d{2}):?(\d{2}(?:\.\d*)?)(?:\+\d*)?Z?$/.exec(jsonDate);
  if (!jsDate) {
    return new Date(parseInt(jsonDate));
  }
  if (result instanceof Date) {
    result.setUTCFullYear(+jsDate[1]);
    result.setUTCMonth(+jsDate[2] - 1);
    result.setUTCDate(+jsDate[3]);
    result.setUTCHours(+jsDate[4]);
    result.setUTCMinutes(+jsDate[5]);
    result.setUTCSeconds(+jsDate[6]);
    return result;
  }
  return new Date(Date.UTC(+jsDate[1], +jsDate[2] - 1, +jsDate[3], +jsDate[4], +jsDate[5], +jsDate[6]));
}

// Convert duration from seconds (e.g. 20 seconds) to SCORM (PT20S)
export function convertSecondsToScorm(seconds: number): string {
  return isNaN(seconds) ? '' : `PT${seconds}S`;
}

// Convert duration from SCORM (e.g. PT1M20S) to seconds (80 seconds)
// This is a dumb converter for a specific use, so it would only process Hours, Minutes, and Seconds
export function convertScormToSeconds(scorm: string): number {
  let accumulatedSeconds = 0;
  let currentNumber = 0; // explicitly given number
  const parts = scorm.split('T');
  const dateParts = parts[0];
  if (dateParts) {
    for (const datePart of dateParts) {
      const datePartAsNumber = parseInt(datePart, 10);
      switch (true) {
        case !isNaN(datePartAsNumber):
          currentNumber = currentNumber * 10 + datePartAsNumber;
          break;
        case datePart === 'Y':
          accumulatedSeconds += (currentNumber * 31536000);
          currentNumber = 0;
          break;
        case datePart === 'M':
          accumulatedSeconds += (currentNumber * 2592000);
          currentNumber = 0;
          break;
        case datePart === 'D':
          accumulatedSeconds += (currentNumber * 86400);
          currentNumber = 0;
          break;
        default:
          currentNumber = 0;
      }
    }
  }
  const timeParts = parts[1];
  if (timeParts) {
    for (const timePart of timeParts) {
      const timePartAsNumber = parseInt(timePart, 10);
      switch (true) {
        case !isNaN(timePartAsNumber):
          currentNumber = currentNumber * 10 + timePartAsNumber;
          break;
        case timePart === 'H':
          accumulatedSeconds += (currentNumber * 3600);
          currentNumber = 0;
          break;
        case timePart === 'M':
          accumulatedSeconds += (currentNumber * 60);
          currentNumber = 0;
          break;
        case timePart === 'S':
          accumulatedSeconds += currentNumber;
          currentNumber = 0;
          break;
        default:
          currentNumber = 0;
      }
    }
  }
  return accumulatedSeconds;
}

export type PropertyMapper<FromInterface, ToInterface, ToInterfaceProperty extends keyof ToInterface> = {
  fromPropertyName: keyof FromInterface;
  map: (fromProperty: any, defaultValue?: ToInterface[ToInterfaceProperty]) => ToInterface[ToInterfaceProperty];
  reverseMap: (property?: ToInterface[ToInterfaceProperty]) => any;
}

/**
 * Object of this type describes mapping of properties belonging to different interfaces.
 */
export type InterfaceMapper<FromInterface, ToInterface> = {
  [ToInterfaceProperty in keyof ToInterface]: PropertyMapper<FromInterface, ToInterface, ToInterfaceProperty>
}

export type ResultType<T, E = any> = {
  ok: true;
  value: T;
} | {
  ok: false;
  error?: E;
}

export const Result = {
  success: function <T>(value: T): ResultType<T, never> {
    return {ok: true, value};
  },
  failure: function <E = any>(error?: E): ResultType<never, E> {
    return {ok: false, error};
  },
  unwrap: function <T>(result: ResultType<T>): T | null {
    return result.ok ? result.value : null;
  },
  mapValue: function <T, U>(result: ResultType<T>, transformValue: (value: T) => U): ResultType<U> {
    return result.ok ? {ok: true, value: transformValue(result.value)} : result;
  },
  mapError: function <T, E, U>(result: ResultType<T, E>, transformError: (error?: E) => U): ResultType<T, U> {
    return result.ok ? result : {ok: false, error: transformError(result.error)};
  }
};

export function createLocalSettings(prefix: () => string) {
  return {
    get: (key: string) => asyncStorage.getSecureItem(prefix() + key),
    set: (key: string, value: string) => asyncStorage.setSecureItem(prefix() + key, value),
    remove: (key: string) => asyncStorage.removeSecureItem(prefix() + key)
  };
}

export function shortId(): string {
  return 'xxxxxxxx'.replace(/x/g, () =>
    (Math.random() * 16 | 0).toString(16)
  );
}

export function httpRequestLogPrefix(id: string, time?: {networkTime: number; parsingTime: number;}): string {
  return [
    `[${id}]`,
    ...time ? [
      `Network time: ${time.networkTime} ms.`,
      `Parsing time: ${time.parsingTime} ms.`
    ] : []
  ].join(' ');
}
