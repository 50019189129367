import {PixelRatio} from 'react-native';

import {EventEmitter} from 'common/EventEmitter';
import {ChangeEvent} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {boProxy} from 'mw/bo-proxy/BOProxy';
import {Menu} from 'mw/cms/Menu';
import {BlockableIdleActions} from 'mw/common/BlockableIdleActions';
import {ChannelsCache, ChannelsCacheEvent} from 'mw/common/ChannelsCache';
import {ContentCache, RecommendationsSource, ContentQueryParameters, SpecialFilter} from 'mw/common/ContentCache';
import {EntitledProducts} from 'mw/common/entitled-products/EntitledProducts';
import {EPG, EPGEvent} from 'mw/common/Epg';
import {getBestPictureUrl} from 'mw/common/helpers/PictureHelperFunctions';
import {mw} from 'mw/MW';
import {sortEpgSearchResults, filterChannelSearchResults} from 'mw/utils/CatalogUtils';

import {CacheType, CatalogEvent, EPGParams, EPGResponse, PurchaseMethodParams, PurchaseResult, SearchParameters, SearchResult, StartPaymentParams} from './CatalogInterface';
import {CustomerEvent, Customer} from './Customer';
import {Filter} from './Filter';
import {Channel, Event, isChannel, isEvent, isTitle, Media, PictureMode, Picture, Title, PictureType, Series, Credits, MediaType, SortVodBy, UpdateMediaParams, Payment, PurchaseMethod, Order} from './Metadata';
import {Profile, ProfileEvent} from './Profile';

const TAG = 'Catalog';
const LAST_PLAYED_CHANNEL_ID_KEY = `Catalog-lastPlayedChannelId`;
const pixelRatio = PixelRatio.get();

export class Catalog extends EventEmitter<CatalogEvent> implements BlockableIdleActions {
  private channelsCache: ChannelsCache;
  private epg: EPG;
  private contentCache: ContentCache;
  public readonly entitledProducts: EntitledProducts

  public constructor() {
    super();
    // can't use .initialize() here, as typescript states required fields to be "definitely assigned in the constructor"
    this.channelsCache = new ChannelsCache();
    this.epg = new EPG();
    this.contentCache = new ContentCache();
    this.entitledProducts = new EntitledProducts();
    this.addListeners();
  }

  public onUILanguageChange(): Promise<void> {
    return this.reinitialize()
      .then(() => this.refreshEPG())
      .catch(error => Log.error(TAG, 'UILanguageChanged: reload data failed', error));
  }

  public addCustomerEventListeners(customer: Customer): void {
    customer.on(CustomerEvent.PCEnabledChanged, () => {
      this.reinitialize();
    });

    customer.on(CustomerEvent.ProfileChange, params => {
      // consider improving EventEmitter typing to omit these ugly unsafe casts
      const {to: profile, from: previousProfile} = params as ChangeEvent<Profile | null>;
      previousProfile?.off(ProfileEvent.WatchListChange, this.onWatchListChange);
      profile?.on(ProfileEvent.WatchListChange, this.onWatchListChange);
      // uiLanguage change is handled above, this if prevents duplicated content cache reinitialization
      if (previousProfile && profile && profile.uiLanguage === previousProfile.uiLanguage) {
        this.reinitializeContentCache();
      }
    });

    customer.currentProfile?.on(ProfileEvent.WatchListChange, this.onWatchListChange);
  }

  private onWatchListChange = () => {
    Log.debug(TAG, 'WatchList changed - removing WatchList items from ContentCache');
    this.contentCache.removeSpecialContent(SpecialFilter.WatchList);
  };

  private addListeners() {
    this.channelsCache.on(ChannelsCacheEvent.Refreshed, this.notifyChannelsCacheRefreshed);
    this.epg.on(EPGEvent.EPGCacheRefreshed, this.notifyEpgCacheRefreshed);
  }

  private removeListeners() {
    this.channelsCache.off(ChannelsCacheEvent.Refreshed, this.notifyChannelsCacheRefreshed);
    this.epg.off(EPGEvent.EPGCacheRefreshed, this.notifyEpgCacheRefreshed);
  }

  public async validatePlayingMedia(): Promise<void> {
    const currentMedia = mw.players.main.getCurrentMedia();
    if (!currentMedia) {
      return;
    }
    if (isChannel(currentMedia)) {
      const channel = mw.catalog.getChannelById(currentMedia.id);
      if (!channel) {
        this.stopPlaybackAndNotify(CatalogEvent.MediaNotFound, currentMedia);
      } else if (!channel.isAvailableByPolicy) {
        this.stopPlaybackAndNotify(CatalogEvent.ChannelNotAvailableByPolicy);
      }
      return;
    }
    if (isTitle(currentMedia)) {
      const media = await mw.catalog.getTitleById(currentMedia.id);
      if (!media.getPlayable()) {
        this.stopPlaybackAndNotify(CatalogEvent.MediaNotFound, currentMedia);
      }
      return;
    }
    if (isEvent(currentMedia)) {
      const media = await mw.catalog.getEventById(currentMedia.id);
      if (!media.getPlayable() || !mw.catalog.getChannelById(currentMedia.channelId)) {
        this.stopPlaybackAndNotify(CatalogEvent.MediaNotFound, currentMedia);
      }
      return;
    }
  }

  private notifyChannelsCacheRefreshed = (): void => {
    Log.debug(TAG, 'Channels list refreshed - forwarding notification');
    this.notify(CatalogEvent.ChannelsListRefreshed);
  }

  private notifyEpgCacheRefreshed = (): void => {
    Log.debug(TAG, 'EPG refreshed - forwarding notification');
    this.notify(CatalogEvent.EPGRefreshed);
  }

  private stopPlaybackAndNotify = (catalogEvent: CatalogEvent, payload?: unknown): void => {
    Log.info(TAG, 'stopPlaybackAndNotify', catalogEvent);
    mw.players.main.stop()
      .catch(error => Log.error(TAG, 'stopPlaybackAndNotify: error during player stop', error))
      .finally(() => this.notify(catalogEvent, payload));
  }

  public initialize(): Promise<void> {
    this.initializeEpgAndChannelsCaches();
    this.initializeContentCache();
    return this.getAllChannels().then(() => Promise.resolve());
  }

  public uninitialize(): void {
    this.uninitializeEpgAndChannelsCaches();
    this.uninitializeContentCache();
  }

  public reinitialize(): Promise<void> {
    this.uninitialize();
    return this.initialize();
  }

  private initializeContentCache() {
    this.contentCache = new ContentCache();
  }

  private uninitializeContentCache() {
    this.contentCache.clear();
  }

  private reinitializeContentCache() {
    this.uninitializeContentCache();
    this.initializeContentCache();
  }

  private initializeEpgAndChannelsCaches(): void {
    this.channelsCache = new ChannelsCache();
    this.epg.initializeCache();
    this.addListeners();
  }

  private uninitializeEpgAndChannelsCaches() {
    this.removeListeners();
    this.channelsCache.clear();
    this.epg.uninitializeCache();
  }

  public getChannels = async (): Promise<Channel[]> => {
    try {
      const channelList = await mw.customer.getProfile().getSelectedChannelList();
      // Force cache refresh if its needed
      return channelList?.channels || await this.getAllChannels();
    } catch (error) {
      throw new Error('Error while getting channels');
    }
  };

  public getChannelById(id: string): Channel | undefined {
    return this.channelsCache.getChannelById(id);
  }

  public getAllChannels(): Promise<Channel[]> {
    return this.channelsCache.getChannels();
  }

  public getSortedChannelsIds(): string[] {
    return this.channelsCache.getSortedChannelsIds();
  }

  public setLastPlayedChannelId(channelId: string): Promise<void> {
    const profile = mw.customer.currentProfile;
    if (!profile) {
      throw new Error('Error while setting last played channel - profile is undefined');
    }
    return profile.localSettings.set(LAST_PLAYED_CHANNEL_ID_KEY, channelId);
  }

  public async getLastPlayedChannel(): Promise<Channel> {
    const profile = mw.customer.currentProfile;
    if (!profile) {
      throw new Error('Error while getting last played channel - profile is undefined');
    }

    const [channels, lastPlayedChannelId] = await Promise.all([this.getChannels(), profile.localSettings.get(LAST_PLAYED_CHANNEL_ID_KEY)]);
    if (!channels || channels.length === 0) {
      throw new Error('Unable to find last played channel - channels lineup is missing');
    }

    const lastPlayedChannel = lastPlayedChannelId && channels.find((c: Channel) => c.id === lastPlayedChannelId);
    if (lastPlayedChannel) {
      Log.debug(TAG, 'Found last played channel with id ' + lastPlayedChannelId);
      return lastPlayedChannel;
    }
    const defaultChannelId = mw.configuration.defaultChannelID;
    if (defaultChannelId) {
      const defaultChannel = this.getChannelById(defaultChannelId);
      if (defaultChannel) {
        Log.debug(TAG, 'Found default channel with id ' + defaultChannelId);
        return defaultChannel;
      } else {
        Log.error(TAG, 'Can not get channel by id with default channel id ' + defaultChannelId);
      }
    }
    Log.debug(TAG, 'Failed to find last played channel in current channels lineup - returning the first available one:', channels[0]);
    return channels[0];
  }

  public getEPG(params: EPGParams): Promise<EPGResponse> {
    return this.epg.getEPG(params);
  }

  public getEventById(id: string): Promise<Event> {
    return boProxy.bo.getEventById(id);
  }

  public updateMedia(media: Media, params: UpdateMediaParams): Promise<void> {
    return boProxy.bo.updateMedia(media, params);
  }

  public getPictureUrl(media: Media | Menu | Credits, type: PictureType, width: number, height: number, mode: PictureMode): string {
    if (media instanceof Credits) {
      return media.pictures.length ? media.pictures[0].url : '';
    }
    const mediaType = media.getType();
    const types = mw.configuration.getPictureTypes(mediaType, type);

    /* Find media.pictures with type that is at the lowest position in schema[mediaType][type]
     * and pass it to boAdapter for further selection and to get an actual url */
    const picturesByType: {[type: string]: Picture[]} = {};
    media.pictures.forEach((picture: Picture) => {
      if (typeof picture.type !== 'string') {
        return;
      }
      const type = picture.type.toLowerCase();
      if (!picturesByType[type]) {
        picturesByType[type] = [picture];
      } else {
        picturesByType[type].push(picture);
      }
    });
    const pictureType = types.find((type: string) => !!picturesByType[type.toLowerCase()])?.toLowerCase();
    const pictures = pictureType ? picturesByType[pictureType] : media.pictures;
    const widthPixelRatio = Math.round(width * pixelRatio);
    const heightPixelRatio = Math.round(height * pixelRatio);
    const picture = getBestPictureUrl(pictures, widthPixelRatio, heightPixelRatio);
    if (!picture) {
      return '';
    }
    return boProxy.bo.getPictureUrl(picture, widthPixelRatio, heightPixelRatio, mode);
  }

  public getSeriesById(seriesId: string): Promise<Series> {
    return boProxy.bo.getSeriesById(seriesId);
  }

  public getSeriesSeasonsById(seriesId: string): Promise<Series[]> {
    return boProxy.bo.getSeriesSeasonsById(seriesId);
  }

  public getSeriesEpisodesById(seriesId: string): Promise<Title[]> {
    return boProxy.bo.getSeriesEpisodesById(seriesId);
  }

  public getSeasonEpisodesById(seasonId: string, includeTvEvents = false): Promise<Media[]> {
    return boProxy.bo.getSeasonEpisodesById(seasonId, includeTvEvents);
  }

  public getRecommendedEpisode(series: Series): Promise<Title | null> {
    return boProxy.bo.getRecommendedEpisode(series);
  }

  public getRecommendations(pageSize: number, sources: RecommendationsSource[], media?: Media): Promise<Media[]> {
    return boProxy.bo.getRecommendations({offset: 0, pageSize, media, sources});
  }

  public getContent(filters: Filter[], parameters: ContentQueryParameters): AsyncIterableIterator<Media[]> {
    return this.contentCache.getContent(filters, parameters);
  }

  public getTitleById(id: string): Promise<Title> {
    return boProxy.bo.getTitleById(id);
  }

  public startPayment(params: StartPaymentParams): Promise<Payment> {
    return boProxy.bo.startPayment(params);
  }

  public startRepayment(order: Order): Promise<Payment> {
    return boProxy.bo.startRepayment(order);
  }

  public async search(params: SearchParameters): Promise<SearchResult> {
    const searchResult: SearchResult = {};
    const result = await boProxy.bo.search(params).next();
    searchResult.channel = result.value.channel;
    if (searchResult.channel) {
      filterChannelSearchResults(searchResult.channel, params);
    }
    searchResult.epgPast = result.value.epgPast;
    if (searchResult.epgPast) {
      sortEpgSearchResults(searchResult.epgPast);
    }
    searchResult.epgNow = result.value.epgNow;
    if (searchResult.epgNow) {
      sortEpgSearchResults(searchResult.epgNow);
    }
    searchResult.epgFuture = result.value.epgFuture;
    if (searchResult.epgFuture) {
      sortEpgSearchResults(searchResult.epgFuture);
    }
    searchResult.epgPvr = result.value.epgPvr;
    if (searchResult.epgPvr) {
      sortEpgSearchResults(searchResult.epgPvr);
    }
    searchResult.vod = result.value.vod;
    return searchResult;
  }

  public getSearchMinimumLength(): number {
    return boProxy.bo.getSearchMinimumLength();
  }

  public providesMugShots(): boolean {
    return false;
  }

  public async isSeenFilterAvailableForCategory(filters: Filter[]): Promise<boolean> {
    if (!boProxy.bo.isSeenFilterAvailable()) {
      return false;
    }

    try {
      const result = await this.getContent(filters, {pageSize: 1}).next();
      return result.value && result.value.length > 0 && result.value[0].getType() === MediaType.Title;

    } catch (error) {
      return false;
    }
  }

  public getAvailableVODSortOptions(): SortVodBy[] {
    return boProxy.bo.getAvailableVODSortOptions();
  }

  public getCurrentEvent(channel: Channel): Promise<Event> {
    return this.epg.getEvent(channel, new Date());
  }

  public getEvent(channel: Channel, date: Date): Promise<Event> {
    return this.epg.getEvent(channel, date);
  }

  public async updateEventsIsRecorded(events: Event[]): Promise<void> {
    try {
      const eventsMap = await boProxy.bo.getIsRecorded(events);
      const changedEvents: Event[] = [];
      events.forEach((event: Event) => {
        const eventIsRecorded = eventsMap.get(event.id);
        if (typeof eventIsRecorded == 'boolean') {
          if (!!event.isRecorded !== eventIsRecorded) {
            event.isRecorded = eventIsRecorded;
            changedEvents.push(event);
          }
        } else {
          Log.error(TAG, 'updateEventsIsRecorded: failed to update isRecorded for event:', event);
        }
      });
      this.notify(CatalogEvent.EventsIsRecordedUpdated, changedEvents);
    } catch (error) {
      Log.error(TAG, 'updateEventsIsRecorded: failed to update events isRecorded, error:', error);
    }
  }

  public getEpgCacheEventById(eventId: string, channelId: string): Event | null {
    const channel = this.getChannelById(channelId);
    if (!channel || !channel.customProperties.timespans) {
      return null;
    }
    for (const timespanKey in channel.customProperties.timespans) {
      const timespan = channel.customProperties.timespans[timespanKey];
      const event = timespan.eventsMap.get(eventId);
      if (event) {
        return event;
      }
    }
    return null;
  }

  public purchase(purchaseMethod: PurchaseMethod, params: PurchaseMethodParams): Promise<PurchaseResult> {
    return boProxy.bo.purchase(purchaseMethod, params);
  }

  public refreshCache(...cacheTypes: CacheType[]): Promise<void> {
    const promises: Promise<void>[] = [];

    if (cacheTypes.includes(CacheType.Channels)) {
      promises.push(
        this.refreshChannels()
          .then(() => {
            if (!cacheTypes.includes(CacheType.EPG)) {
              return Promise.resolve();
            }
            return this.refreshEPG();
          })
      );

    } else if (cacheTypes.includes(CacheType.EPG)) {
      promises.push(this.refreshEPG());
    }

    if (cacheTypes.includes(CacheType.Content)) {
      promises.push(this.refreshContents());
    }

    if (cacheTypes.includes(CacheType.Products)) {
      promises.push(this.refreshProducts());
    }

    return Promise.all(promises)
      .then(Promise.resolve);
  }

  public blockIdleActions(): void {
    this.epg.blockIdleActions();
  }

  public unblockIdleActions(): Promise<void> {
    return this.epg.unblockIdleActions();
  }

  private refreshEPG(): Promise<void> {
    return this.epg.refreshEPG()
      .then(Promise.resolve);
  }

  private refreshChannels(): Promise<void> {
    this.channelsCache.clear();
    return this.channelsCache.getChannels()
      .then(Promise.resolve);
  }

  private refreshContents(): Promise<void> {
    this.contentCache.clear();
    return Promise.resolve();
  }

  private refreshProducts(): Promise<void> {
    return this.entitledProducts.refresh();
  }

  public cancelOrder(orderId: string): Promise<void> {
    return boProxy.bo.cancelOrder(orderId);
  }
}
