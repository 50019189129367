import {ComponentGroup} from './ComponentGroup';

export class Page {
  public title: string;
  public slug: string;
  public type: string;
  public layout: string;
  public componentGroup: ComponentGroup[];

  public constructor(title: string, slug: string, type: string, layout: string, componentGroup: ComponentGroup[]) {
    this.title = title;
    this.slug = slug;
    this.type = type;
    this.layout = layout;
    this.componentGroup = componentGroup;
  }
}
