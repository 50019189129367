import {Menu, Link, LinkType, MenuType} from 'mw/cms/Menu';
import {deviceInfo} from 'mw/platform/device-info/DeviceInfo';

import cmsData, {CMSData} from './CMSData';

export class CMSMapper {
  public static getMainMenu(): Menu {
    const slug = 'nitrox-main-menu';
    return CMSMapper.getMenu(slug, cmsData);
  }

  private static shouldDisplayMenu(menuName: string, cmsDataInt: CMSData) {
    const menuPlatforms = cmsDataInt[menuName].platforms;
    return menuPlatforms ? menuPlatforms.includes(deviceInfo.deviceType) : true;
  }

  private static getMenu(slug: string, cmsDataInt: CMSData): Menu {
    const cmsMenu = {menu: cmsDataInt[slug], cmsDataInternal: cmsDataInt};
    const nestedMenus: Menu[] = cmsMenu.menu.items
      .filter(val => CMSMapper.shouldDisplayMenu(val, cmsDataInt))
      .map(val => CMSMapper.getMenu(val, cmsDataInt));

    let link: Link | undefined;
    if (cmsMenu.menu.link && Object.values(LinkType).includes(cmsMenu.menu.link.type)) {
      link = {type: cmsMenu.menu.link.type, slug: cmsMenu.menu.link.slug};
    }
    const type = MenuType.DEFAULT;

    return new Menu({title: cmsMenu.menu.title, slug, screen: cmsMenu.menu.screen, items: nestedMenus, link, type});
  }
}
