import {createStyles} from 'common-styles';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {dimensions} from 'common/constants';
import {idKeyExtractor} from 'common/HelperFunctions';

import {Series} from 'mw/api/Metadata';

import FocusParent from 'components/FocusParent';
import NitroxButton from 'components/NitroxButton';
import NitroxFlatList from 'components/NitroxFlatList';
import {PostProcessors} from 'locales/i18nPostProcessors';

const styles = createStyles({
  container: {
    flexDirection: 'row',
    marginTop: dimensions.margins.xxxLarge
  },
  buttonStyle: {
    height: 50,
    width: 160,
    marginRight: dimensions.margins.small
  }
});

type Props = {
  data: Series[];
  onSeasonPress: (season: Series) => void;
  activeSeasonId?: string;
}

export default function SeasonsRow({data, onSeasonPress, activeSeasonId}: Props) {
  const {t} = useTranslation();

  return (
    <FocusParent style={styles.container}>
      <NitroxFlatList
        data={data}
        keyExtractor={idKeyExtractor}
        extraData={activeSeasonId}
        renderItem={({item}) => (
          <NitroxButton
            border
            style={styles.buttonStyle}
            text={t('zapper.seasonNumberFull', {seasonNumber: item.seasonNumber, postProcess: PostProcessors.ToUpperCase})}
            onPress={() => onSeasonPress(item)}
            isSelected={activeSeasonId === item.id}
          />
        )}
        horizontal
      />
    </FocusParent>
  );
}
