import React from 'react';
import {FlatList, Platform} from 'react-native';

import {NitroxInteractiveController} from './NitroxInteractiveControllerContext';

class NitroxFlatList<T> extends FlatList<T> {
  public static defaultProps = {
    // @ts-ignore - react native typings don't declare FlatList.defaultProps
    ...FlatList.defaultProps,
    /**
     * FlatList enables this prop for Android by default as it 'greatly improves scrolling performance with no known issues'.
     * We need to disable it for ATV as it breaks navigation.
     * Enabling it on iOS has a few known issues.
     */
    removeClippedSubviews: Platform.OS === 'android' && !Platform.isTV,
    alwaysBounceVertical: false
  };
  public render() {
    return (
      <NitroxInteractiveController omitGeometryCaching>
        {super.render()}
      </NitroxInteractiveController>
    );
  }
}

export default NitroxFlatList;
