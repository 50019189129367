import {createStyles} from 'common-styles';
import React, {ReactNode, useMemo, useCallback, useState} from 'react';
import {View, ViewStyle, StyleProp, StyleSheet, LayoutChangeEvent} from 'react-native';
import {NavigationScreenProps} from 'react-navigation';

import {isTablet, isBigScreen, dimensions, isMobile, isPhone} from 'common/constants';
import {TestProps} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {BigScreenHeaderProps} from 'components/BigScreenHeader';
import {useChromecastExtraBottomPadding} from 'components/chromecast/ChromecastExtraBottomPadding';
import DoubleBackground from 'components/DoubleBackground';
import {MobileScreenHeaderProps} from 'components/mobileScreenHeader/MobileScreenHeader';
import NitroxButton, {NitroxButtonTheme} from 'components/NitroxButton';
import NitroxText from 'components/NitroxText';
import {useTestID} from 'hooks/Hooks';
import {useLocalized} from 'locales/i18nUtils';
import NitroxScreen from 'screens/NitroxScreen';

const textBarStyles = createStyles({
  mobileContainer: {
    paddingHorizontal: 35,
    justifyContent: 'center',
    alignItems: isPhone ? 'stretch' : 'center',
    height: dimensions.inputs.height
  },
  bigScreenContainer: {
    alignItems: 'center',
    height: undefined
  },
  textContainer: {
    paddingHorizontal: isTablet ? 64 : 0,
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    ...isMobile ? {
      borderWidth: 2,
      height: 35
    } : {
      marginBottom: dimensions.margins.small
    }
  }
});

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  text: {
    color: colors.settingsScreen.settingsTextBar.text,
    textAlign: 'center'
  },
  textContainer: {
    backgroundColor: isMobile ? colors.elipseSwitch.background : undefined,
    borderColor: colors.settingsScreen.settingsTextBar.border
  },
  underline: {
    backgroundColor: colors.settingsScreen.underlineBackground,
    height: 4,
    borderRadius: 2,
    opacity: 0.5,
    marginBottom: dimensions.margins.xxLarge
  }
}));

type TextPart = {
  text: string;
  weight?: 'bold' | 'regular';
};
export type SettingsBarText = string | TextPart[];
function isString(text: SettingsBarText): text is string {
  return typeof text === 'string';
}

type SettingsTextBarProps = {
  text: SettingsBarText;
  height?: number;
  style?: StyleProp<ViewStyle>;
}
const SettingsTextBar: React.FC<SettingsTextBarProps> = ({text, height, style}) => {
  const [underlineWidth, setUnderlineWidth] = useState(0);
  const dynamicTextBarStyles = dynamicStylesUpdater.getStyles();
  const containerStyle = useMemo(() => StyleSheet.flatten([
    isBigScreen ? textBarStyles.bigScreenContainer : textBarStyles.mobileContainer,
    style,
    (typeof height === 'number') && {height}
  ]), [height, style]);

  const textContainer = useMemo(() => ({
    ...textBarStyles.textContainer,
    ...dynamicTextBarStyles.textContainer,
    borderRadius: Math.floor(
      // might also be a string
      (typeof containerStyle.height === 'number' ? containerStyle.height : 0) / 2
    )
  }), [containerStyle.height, dynamicTextBarStyles.textContainer]);

  const content = useMemo(() => {
    return isString(text)
      ? text
      : text.map(({text, weight}, i) => {
        const textType = weight === 'bold' ? 'settings-subhead-bold' : 'settings-subhead';
        return <NitroxText key={i} textType={textType} style={dynamicTextBarStyles.text}>{text}</NitroxText>;
      });
  }, [dynamicTextBarStyles.text, text]);

  //FIXME: CL-7443 remove onLayout workaround for appleTV
  const onLayout = useCallback((event: LayoutChangeEvent) => {
    setUnderlineWidth(event.nativeEvent.layout.width);
  }, []);

  const underline = useMemo(() => ({
    ...dynamicTextBarStyles.underline,
    width: underlineWidth
  }), [dynamicTextBarStyles.underline, underlineWidth]);

  return (
    <View style={containerStyle}>
      <View style={textContainer}>
        <NitroxText onLayout={onLayout} textType={isBigScreen ? 'settings-subhead-bold' : 'settings-subhead'} upperCase={isBigScreen} style={dynamicTextBarStyles.text}>{content}</NitroxText>
        {isBigScreen && <View style={underline} />}
      </View>
    </View>
  );
};

type SettingsDetailsProps = {
  title: string;
  barText?: SettingsBarText;
  barHeight?: number;
  containerStyle?: StyleProp<ViewStyle>;
  header: ReactNode;
  headerStyle?: StyleProp<ViewStyle>;
  mobileHeader?: MobileScreenHeaderProps;
  bigScreenHeader?: BigScreenHeaderProps;
  children?: ReactNode;
  footerAction?: {
    text: string;
    onPress: () => void;
  };
  onBackButtonPressed?: () => boolean;
} & TestProps;

const footerActionStyles = createStyles({
  containerMobile: {
    marginTop: 'auto',
    marginBottom: 30,
    justifyContent: 'center',
    alignItems: isTablet ? 'center' : 'stretch',
    paddingHorizontal: 35
  },
  containerBigScreen: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    height: dimensions.buttons.large,
    minWidth: isTablet ? 300 : undefined
  }
});

function FooterAction({text, onPress}: Required<SettingsDetailsProps>['footerAction']) {
  return (
    <View style={isBigScreen ? footerActionStyles.containerBigScreen : footerActionStyles.containerMobile}>
      <NitroxButton
        border
        text={text}
        theme={NitroxButtonTheme[isBigScreen ? 'Primary' : 'Tertiary']}
        onPress={onPress}
        style={footerActionStyles.button}
      />
    </View>
  );
}

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flex: 1,
    backgroundColor: colors.settingsScreen.background,
    justifyContent: 'flex-start'
  },
  header: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: isBigScreen ? 0 : 50,
    marginBottom: isBigScreen ? 0 : 60,
    backgroundColor: isMobile ? colors.doubleBackground.top : colors.parallelNavigator.column.right
  },
  screenHeader: {
    backgroundColor: isMobile ? colors.screenHeader.background : colors.parallelNavigator.column.right
  },
  background: {
    borderTopLeftRadius: isBigScreen ? 40 : undefined,
    backgroundColor: isMobile ? colors.settingsScreen.background : colors.settingsScreen.transparentBackground
  },
  textContainer: {
    borderColor: colors.settingsScreen.settingsTextBar.border
  }
}));

const SettingsDetails: React.FC<NavigationScreenProps<{}> & SettingsDetailsProps> = props => {
  const styles = stylesUpdater.getStyles();
  const {toUpperCase} = useLocalized();
  const defaultScreenHeader = useMemo(() => {
    return {
      title: isMobile ? props.title : toUpperCase(props.title),
      style: styles.screenHeader
    };
  }, [props.title, styles.screenHeader, toUpperCase]);
  const testID = useTestID(props, 'SettingsDetails') || 'screen_settings_details';
  const containerStyles = useChromecastExtraBottomPadding([styles.container, props.containerStyle]);

  return (
    <NitroxScreen
      navigation={props.navigation}
      mobileHeaderProps={props.mobileHeader || defaultScreenHeader}
      showBigScreenHeader
      bigScreenHeaderProps={props.bigScreenHeader || defaultScreenHeader}
      style={styles.background}
      embedded={isBigScreen}
      testID={testID}
      onBackButtonPressed={props.onBackButtonPressed}
    >
      <View style={containerStyles}>
        {isMobile ? (
          <DoubleBackground
            topChild={(
              <View style={[styles.header, props.headerStyle]}>
                {props.header}
              </View>
            )}
            bottomChild={(props.barText && <SettingsTextBar text={props.barText} height={props.barHeight} />)}
            overlap={typeof props.barHeight === 'number' ? props.barHeight / 2 : 25}
          />
        ) : (
          <React.Fragment>
            {props.barText && <SettingsTextBar text={props.barText} height={props.barHeight} />}
          </React.Fragment>
        )}
        {props.children}
        {props.footerAction && <FooterAction {...props.footerAction} />}
      </View>
    </NitroxScreen>
  );
};

export default SettingsDetails;
