import {Platform} from 'react-native';

import {isBrowser} from 'common/HelperFunctions';
import {Log} from 'common/Log';
import {makeArray} from 'common/utils';

import {PlaybackAsset} from 'mw/bo-proxy/BOInterface';
import {ManagedBy} from 'mw/bo-proxy/SSOInterface';

import {selectCustomParameter, CustomParameterType, Content, RemoteAssetTypes, RemoteAsset} from './MappersHelper';

const TAG = 'RemoteAssetMapper';

enum ContentType {
  Dash = 'DASH',
  Hls = 'HLS',
  SmoothStreaming = 'SmoothStreaming',
  Url = 'URL',
  WebVTT = 'WebVTT'
}
function selectContentType(contents: Content[], contentType: ContentType): Content | undefined {
  return contents.find(content => content.CustomParameters?.CustomParameter.some(customParameter => selectCustomParameter(customParameter, CustomParameterType.ContentType, contentType)));
}

function selectAndroid(contents: Content[]): Content | undefined {
  return selectContentType(contents, ContentType.Dash) ||
    selectContentType(contents, ContentType.Hls);
}

function selectIOS(contents: Content[]): Content | undefined {
  return selectContentType(contents, ContentType.Hls);
}

function selectWeb(contents: Content[]): Content | undefined {
  return (isBrowser('safari') ? selectIOS : selectAndroid)(contents);
}

function selectContent(contents: Content[] | null): Content | undefined {
  Log.debug(TAG, 'selectEncodedContent ' + Platform.OS);
  if (!contents) {
    return;
  }

  const mediaContents = contents
    .filter(content => content?.CustomParameters?.CustomParameter.some(customParameter => selectCustomParameter(customParameter, CustomParameterType.Type, RemoteAssetTypes.Media)));

  const selectedContent = Platform.select({
    'android': () => selectAndroid(mediaContents),
    'ios': () => selectIOS(mediaContents),
    'web': () => selectWeb(mediaContents),
    'default': () => undefined
  })();

  return selectedContent;
}

export function mapDefaultRemoteAsset(remoteAsset: RemoteAsset): PlaybackAsset | null {
  const content = selectContent(makeArray(remoteAsset.Content));
  return content ? {
    playbackLimitations: {
      allowPause: true,
      allowSkipForward: true,
      allowSkipBackward: true,
      allowFastForward: true,
      allowRewind: true
    },
    url: content.PlaybackUrl,
    managedBy: ManagedBy.Toolbox
  } : null;
}
