import {Log} from 'common/Log';

import {Error, ErrorType} from 'mw/api/Error';
import {Credentials, nxffConfig} from 'mw/api/NXFF';
import {SecureStorage} from 'mw/utils/SecureStorage';

import {TraxisSSOAdapter} from './TraxisSSOAdapter';

const TAG = 'NoSSOAdapter';

export class NoSSOAdapter extends TraxisSSOAdapter {

  private secureStorage = new SecureStorage<string>('NoSSOAdapter-LoginInfo');
  private boUrl: string;

  public constructor() {
    super();
    this.boUrl = nxffConfig.getConfig().Environment.BOURL;
  }

  public async login(credentials?: Credentials): Promise<void> {
    Log.debug(TAG, 'Successfully logged in.');

    const username = credentials && credentials.username || await this.secureStorage.get();
    if (!username) {
      Log.error(TAG, 'login: not enough data to login: no username');
      throw new Error(ErrorType.LoginError);
    }

    this.setIdentity(username);
    await this.secureStorage.save(username);
  }

  protected loginImpl(credentials?: Credentials): Promise<string | null> {
    throw new Error(ErrorType.NotSupported);
  }

  public logout(): Promise<void> {
    return super.logout()
      .then(() => this.secureStorage.save(null));
  }

  public async getToken(): Promise<string> {
    return '';
  }

  public async getBoUrl(): Promise<string> {
    return this.boUrl;
  }

  public isPurchasePinSupported() {
    return false;
  }

  public setPurchasePin(pin: string): Promise<void> {
    throw new Error(ErrorType.NotSupported);
  }

  public verifyPurchasePin(pin: string): Promise<void> {
    throw new Error(ErrorType.NotSupported);
  }

  public invalidateToken(authorizationToken: string | null): void {
  }

  public getMasterPin(): string {
    throw new Error(ErrorType.NotSupported);
  }

  public isMasterPinSupported(): boolean {
    return false;
  }

  public setMasterPin(pin: string): Promise<void> {
    return Promise.reject(new Error(ErrorType.NotSupported));
  }
}
