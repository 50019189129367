import {createStyles} from 'common-styles';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import DoubleBackground from 'components/DoubleBackground';
import FocusParent from 'components/FocusParent';
import FocusableTextInput from 'components/inputs/FocusableTextInput';
import NitroxText from 'components/NitroxText';
import {WizardElements} from 'components/Wizard';
import SettingsDeleteProfile from 'screens/settings/SettingsDeleteProfile';

import {WizardNamePageProps} from './WizardNamePage';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    color: colors.settingsScreen.profile.wizard.label,
    marginVertical: dimensions.margins.medium
  },
  topChild: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  topChildEmbedded: {
    backgroundColor: colors.parallelNavigator.column.right
  },
  bottomChild: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  actions: {
    alignItems: 'center',
    marginTop: dimensions.margins.xxxxLarge
  },
  editField: {
    width: dimensions.inputs.width.settings
  }
}));

const WizardNamePage: React.FC<WizardNamePageProps & WizardElements> = props => {
  const {
    name,
    onNameChanged,
    showTitle = true,
    header,
    tracker,
    forwardButton,
    focusForwardButton,
    onSubmitEditting,
    embedded,
    deleteProfileVisible,
    pin
  } = props;
  const {t} = useTranslation();

  const styles = stylesUpdater.getStyles();
  const title = showTitle && <NitroxText style={styles.label} textType='input-prompt' trimLine>{t('profileWizard.enterName')}</NitroxText>;

  const onInputBlurCapture = useCallback(() => {
    focusForwardButton?.();
    return true;
  }, [focusForwardButton]);

  return (
    <DoubleBackground
      topChild={(
        <View>
          {header}
          {tracker}
          {title}
        </View>
      )}
      topStyle={[styles.topChild, embedded && styles.topChildEmbedded]}
      bottomChild={(
        <>
          <View style={styles.editField}>
            <FocusableTextInput
              onInputBlurCapture={onInputBlurCapture}
              onChangeText={onNameChanged}
              onSubmitEditing={onSubmitEditting}
              initialValue={name}
              testID='input_new_profile_name'
              placeholder={embedded ? undefined : t('settings.edit.namePrompt')}
              textAlign={'center'}
            />
          </View>
          {deleteProfileVisible && (
            <SettingsDeleteProfile pin={pin} />
          )}
          {forwardButton && (
            <FocusParent style={styles.actions}>
              {forwardButton}
            </FocusParent>
          )}
        </>
      )}
      bottomStyle={styles.bottomChild}
      overlap={Math.floor(dimensions.inputs.height / 2)}
    />
  );
};

export default React.memo(WizardNamePage);
