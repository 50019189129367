import React, {useCallback, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {ErrorType} from 'mw/api/Error';

import ErrorPopup, {PopupError} from 'components/ErrorPopup';
import PinKeyboard, {PinKeyboardProps} from 'components/pinKeyboard/PinKeyboard';
import {useChangeEffect} from 'hooks/Hooks';
import {validatePIN} from 'screens/settings/pinValidation';

enum State {
  Hidden,
  NewPin,
  ConfirmPin,
  Error
}

type Props = {
  visible: boolean;
  onSubmit: (pin: string) => void;
  onCancel: () => void;
}

const ConfirmablePinKeyboard: React.FC<Props> = props => {
  const {t} = useTranslation();
  const {visible, onSubmit, onCancel} = props;
  const [error, setError] = useState<PopupError | null>(null);
  const [state, setState] = useState(visible ? State.NewPin : State.Hidden);
  const newPin = useRef<string>('');
  const lastState = useRef<State>(State.Hidden);

  const setAndRememberState = useCallback((newState: State) => {
    lastState.current = newState;
    setState(newState);
  }, []);

  useChangeEffect(() => {
    setAndRememberState(visible ? State.NewPin : State.Hidden);
  }, [visible], [setAndRememberState]);

  const showErrorPopup = useCallback((title: string, message: string) => {
    setError({title, message});
    setState(State.Error);
  }, []);

  const hideErrorPopup = useCallback(() => {
    setError(null);
    setState(lastState.current);
  }, []);

  const onSubmitNew = useCallback((pin: string) => {
    try {
      validatePIN(pin);
      newPin.current = pin;
      setAndRememberState(State.ConfirmPin);
    } catch (error) {
      if (error.type === ErrorType.ProfilePINTooShort) {
        showErrorPopup(t('settings.errors.error'), t('settings.errors.profilePinTooShort'));
      } else {
        showErrorPopup(t('settings.errors.error'), t('common.unexpectedError'));
      }
    }
  }, [t, showErrorPopup, setAndRememberState]);

  const onSubmitConfirm = useCallback((pin: string) => {
    if (newPin.current === pin) {
      onSubmit(pin);
    } else {
      showErrorPopup(t('settings.errors.confirmationFailed'), t('settings.errors.confirmationPINFailed'));
    }
  }, [showErrorPopup, t, onSubmit, newPin]);

  const pinKeyboardProps = useMemo<PinKeyboardProps>(() => {
    switch (state) {
      case State.NewPin:
        return {
          visible: true,
          title: t('settings.setPinTitle'),
          onClose: onCancel,
          onSubmit: onSubmitNew
        };
      case State.ConfirmPin:
        return {
          visible: true,
          title: t('settings.confirmNewPin'),
          onClose: onCancel,
          onSubmit: onSubmitConfirm
        };
      default:
        return {visible: false} as PinKeyboardProps;
    }
  }, [t, state, onSubmitNew, onSubmitConfirm, onCancel]);

  return (
    <>
      <ErrorPopup error={state === State.Error && error ? {message: error.message, title: error.title} : null} onClose={hideErrorPopup} />
      <PinKeyboard {...pinKeyboardProps} />
    </>
  );
};

export default React.memo(ConfirmablePinKeyboard);
