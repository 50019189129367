import {mw} from 'mw/MW';

export class NamedAction {
  public constructor(
    public name: string,
    public label: string,
    public onPress: () => void
  ) {}
}

export type VisibilityLimit<Limiter> = {
  /** Should the visibility limit be applied to the given limiter - this can be something like CMS component or search category etc */
  shouldApply: (limiter?: Limiter) => boolean;
  /** How many items should be visible before showing the Show All tile. By default SwimlaneContentLimit NXFF config is used. */
  limit?: number;
} & Pick<NamedAction, 'name' | 'label' | 'onPress'>;

export function findVisibilityLimit<Limiter>(
  visibilityLimits?: VisibilityLimit<Limiter>[],
  limiter?: Limiter
): VisibilityLimit<Limiter> | undefined {
  // find visibility limit that should be applied to the given limiter with the lowest number of visible items
  const defaultLimit = mw.configuration.getSwimlaneContentLimit();
  let min: VisibilityLimit<Limiter> | undefined;
  for (const current of visibilityLimits || []) {
    // skip 0 - it should be interpreted as no limit
    const currentLimit = current.limit || defaultLimit;
    if (currentLimit > 0 && current.shouldApply(limiter) && (!min || currentLimit < (min.limit || defaultLimit))) {
      min = current;
    }
  }
  return min;
}

export interface LimitedAsyncIterableIterator<T> extends AsyncIterableIterator<T> {
  isLimited?(): boolean;
  getAction?(): NamedAction;
}

export function isLimitedAsyncIterableIterator<T>(iterator?: AsyncIterableIterator<T[]>): iterator is LimitedAsyncIterableIterator<T[]> {
  const cast = iterator as LimitedAsyncIterableIterator<T[]>;
  return !!(cast.isLimited && cast.getAction);
}

export function createLimitedDataFetcher<MediaType, Limiter>(
  dataFetcher: AsyncIterableIterator<MediaType[]>,
  visibilityLimits?: VisibilityLimit<Limiter>[],
  limiter?: Limiter
): LimitedAsyncIterableIterator<MediaType[]> {
  // if there is no matching visibility limit just use the content iterator directly
  const visibilityLimit = findVisibilityLimit(visibilityLimits, limiter);
  if (!visibilityLimit) {
    return dataFetcher;
  }
  // wrap the content iterator to stop fetching the data after reaching the limit and add ShowAll tile's data
  const defaultLimit = mw.configuration.getSwimlaneContentLimit();
  let limited = false;
  const iterator = (async function *(): AsyncIterableIterator<MediaType[]> {
    let fetchedItems = 0;
    while (true) {
      const page = await dataFetcher.next();
      const pageValue: MediaType[] = page.value ? [...page.value] : [];
      const overflow = fetchedItems + pageValue.length - (visibilityLimit.limit || defaultLimit);
      limited = overflow >= 0;
      if (limited) {
        pageValue.splice(-overflow, overflow);
      }
      fetchedItems += pageValue.length;
      if (page.done || overflow > 0) {
        return pageValue;
      } else {
        yield pageValue;
      }
    }
  })() as LimitedAsyncIterableIterator<MediaType[]>;
  iterator.isLimited = () => limited;
  iterator.getAction = () => visibilityLimit;
  return iterator;
}

export function createShowAllDataFetcher<MediaType, Limiter>(
  dataFetcher: AsyncIterableIterator<MediaType[]>,
  visibilityLimits?: VisibilityLimit<Limiter>[],
  limiter?: Limiter
): LimitedAsyncIterableIterator<(MediaType | NamedAction)[]> {

  const visibilityLimit = findVisibilityLimit(visibilityLimits, limiter);
  if (!visibilityLimit) {
    return dataFetcher;
  }
  return (async function *(): AsyncIterableIterator<(MediaType | NamedAction)[]> {
    const limitedDataFetcher = createLimitedDataFetcher(dataFetcher, visibilityLimits, limiter);
    while (true) {
      const page = await limitedDataFetcher.next();
      const pageValue: (MediaType | NamedAction)[] = [...page.value];
      const limited = limitedDataFetcher.isLimited?.();
      if (limited) {
        pageValue.push(new NamedAction(visibilityLimit.name, visibilityLimit.label, visibilityLimit.onPress));
      }
      if (page.done || limited) {
        return pageValue;
      } else {
        yield pageValue;
      }
    }
  })();
}
