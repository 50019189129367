/* eslint-disable @typescript-eslint/no-unused-vars */
import {Log} from 'common/Log';

import {ToasterInterface, ToasterDuration} from './ToasterInterface';

const TAG = 'Toaster';

export class Toaster implements ToasterInterface {
  public static instance: Toaster = new Toaster();
  public show(message: string, duration: ToasterDuration): void {
    Log.error(TAG, 'Method show() not implemented');
  }
}

export const toaster: ToasterInterface = Toaster.instance;
