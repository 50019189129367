import {createStyles} from 'common-styles';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions} from 'common/constants';

import {constColors} from 'brand/ColorTypes';
import NitroxText from 'components/NitroxText';

import StripeFocusableInput from './FocusableInput';

const cardNumberWidth = 380;
const cardExpirationWidth = 161;
const cardCvcWidth = 170;
const containerHeight = 129;
const styles = createStyles({
  container: {
    width: dimensions.payments.order.contentWidth,
    height: containerHeight,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  text: {
    color: constColors.white
  },
  cardNumber: {
    width: cardNumberWidth
  },
  cardExpiration: {
    width: cardExpirationWidth
  },
  cardCvc: {
    width: cardCvcWidth
  }
});

export type CreditCardFormProps = {
  cardNumberComponent?: any;
  cardNumberValidationError: boolean;
  onCardNumberChange: (event: any) => void;
  cardExpiryComponent?: any;
  cardExpiryValidationError: boolean;
  onCardExpiryChange: (event: any) => void;
  cardCvcComponent?: any;
  cardCvcValidationError: boolean;
  onCardCvcChange: (event: any) => void;
};

const CreditCardForm: React.FunctionComponent<CreditCardFormProps> = props => {
  const {
    cardNumberComponent,
    cardNumberValidationError,
    onCardNumberChange,
    cardExpiryComponent,
    cardExpiryValidationError,
    onCardExpiryChange,
    cardCvcComponent,
    cardCvcValidationError,
    onCardCvcChange
  } = props;

  const {t} = useTranslation();

  return (
    <View style={styles.container}>
      <View style={styles.cardNumber}>
        <NitroxText textType={'callout'} style={styles.text}>
          {t('payments.order.creditCard.number')}
        </NitroxText>
        <StripeFocusableInput
          Component={cardNumberComponent}
          validationError={cardNumberValidationError}
          showIcon
          onChange={onCardNumberChange}
        />
      </View>
      <View style={styles.cardExpiration}>
        <NitroxText textType={'callout'} style={styles.text}>
          {t('payments.order.creditCard.expiration')}
        </NitroxText>
        <StripeFocusableInput
          Component={cardExpiryComponent}
          validationError={cardExpiryValidationError}
          placeholder={'MM/YY'}
          onChange={onCardExpiryChange}
        />
      </View>
      <View style={styles.cardCvc}>
        <NitroxText textType={'callout'} style={styles.text}>
          {t('payments.order.creditCard.cvc')}
        </NitroxText>
        <StripeFocusableInput
          Component={cardCvcComponent}
          validationError={cardCvcValidationError}
          onChange={onCardCvcChange}
        />
      </View>
    </View>
  );
};

export default CreditCardForm;
