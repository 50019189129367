import React from 'react';

import {Event} from 'mw/api/Metadata';

import {PlayerControlsViewButtonsVisibility} from 'components/player/PlayerControlsView';
import {computeProgress} from 'screens/tv/TvScreenHelperFunctions';
import {usePlayerPosition} from 'screens/tv/TvScreenHooks';

import ProgressBarView from './ProgressBarView';

type ProgressBarProps = {
  event?: Event;
  buttonsVisibility: PlayerControlsViewButtonsVisibility;
}
const ProgressBar: React.FC<ProgressBarProps> = ({
  event,
  buttonsVisibility
}) => {
  const [currentProgress, availableProgressStart, availableProgressEnd] = usePlayerPosition(event);
  // Only display stream buffer boundaries when we’re allowed to pause
  const shouldDisplayBufferBoundaries = buttonsVisibility.playback.pausePlay;

  if (!event) {
    return null;
  }

  return (
    <ProgressBarView
      startTime={event.start}
      endTime={event.end}
      currentProgress={computeProgress(event.start, event.end, currentProgress) || 0}
      availableProgressStart={shouldDisplayBufferBoundaries && computeProgress(event.start, event.end, availableProgressStart) || 0}
      availableProgressEnd={shouldDisplayBufferBoundaries ? computeProgress(event.start, event.end, availableProgressEnd) || 1 : 0}
      currentTime={currentProgress}
      currentTimeLabelVisible={true}
      currentTimeLabelAlignment={'bubble'}
      showAvailableProgressIndicators={shouldDisplayBufferBoundaries}
      isDraggableForward={buttonsVisibility.playback.skipForward}
      isDraggableBackward={buttonsVisibility.playback.skipBack}
    />
  );
};

export default React.memo(ProgressBar);
