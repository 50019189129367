import {createStyles} from 'common-styles';
import {useEffect, useState, useRef} from 'react';
import React, {useCallback, useMemo, PropsWithChildren, ReactNode} from 'react';
import {StyleSheet, Image, ViewStyle, View, Animated, ImageStyle, GestureResponderEvent} from 'react-native';

import {getValue, isBigScreen, isWeb, RADIUS, dimensions, isMobile} from 'common/constants';
import {humanCaseToOneWord} from 'common/HelperFunctions';
import {TestProps} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {constColors} from 'common-styles/variables/base-colors';
import {BaseColors} from 'common-styles/variables/base-colors';

import {PictureType, PictureMode} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import {FocusableComponent} from 'components/focusManager/FocusManagerTypes';
import NitroxText from 'components/NitroxText';
import {useDisposableCallback, useLazyEffect, useChannelAvailability} from 'hooks/Hooks';

import NitroxInteractive from './NitroxInteractive';

const borderRadius = RADIUS;

export const channelIconConstants = {
  static: {
    size: dimensions.pictures.channelLogo.tile
  },
  channelList: {
    mobile: dimensions.pictures.channelLogo.channelListSelector,
    scaledMobile: dimensions.pictures.channelLogo.channelListMagnified
  },
  channelGrid: {
    size: dimensions.pictures.channelLogo.zapper
  },
  eventDetails: {
    size: dimensions.pictures.channelLogo.detail
  },
  channelShortDetails: {
    height: 87,
    width: 100,
    radius: 10
  },
  epg: {
    size: dimensions.pictures.channelLogo.epg,
    scaledSize: dimensions.pictures.channelLogo.epg
  },
  zapper: {
    size: dimensions.pictures.channelLogo.zapper
  },
  eventSelector: {
    size: getValue({mobile: 65, tablet: 50, defaultValue: 120})
  }
};

export enum ChannelIconType {
  Static, ChannelListMobile, ChannelListMobileZapper, ChannelGrid, ChannelShortDetails, EventDetails, Epg, Zapper, EventSelector
}

const sizes = createStyles({
  static: {
    width: channelIconConstants.static.size,
    height: channelIconConstants.static.size,
    borderRadius: borderRadius
  },
  channelListMobile: {
    width: channelIconConstants.channelList.mobile,
    height: channelIconConstants.channelList.mobile,
    borderRadius: borderRadius
  },
  scaledChannelListMobile: {
    width: channelIconConstants.channelList.scaledMobile,
    height: channelIconConstants.channelList.scaledMobile,
    borderRadius: borderRadius
  },
  channelGrid: {
    width: channelIconConstants.channelGrid.size,
    height: channelIconConstants.channelGrid.size,
    borderRadius: borderRadius
  },
  eventDetails: {
    width: channelIconConstants.eventDetails.size,
    height: channelIconConstants.eventDetails.size,
    borderRadius: borderRadius
  },
  channelShortDetails: {
    width: channelIconConstants.channelShortDetails.width,
    height: channelIconConstants.channelShortDetails.height,
    borderTopLeftRadius: channelIconConstants.channelShortDetails.radius,
    borderTopRightRadius: channelIconConstants.channelShortDetails.radius
  },
  epg: {
    width: channelIconConstants.epg.size,
    height: channelIconConstants.epg.size,
    borderRadius: borderRadius
  },
  scaledEpg: {
    width: channelIconConstants.epg.scaledSize,
    height: channelIconConstants.epg.scaledSize,
    borderRadius: borderRadius
  },
  zapper: {
    width: channelIconConstants.zapper.size,
    height: channelIconConstants.zapper.size,
    borderRadius: 2 * borderRadius
  },
  eventSelector: {
    width: channelIconConstants.eventSelector.size,
    height: channelIconConstants.eventSelector.size,
    borderRadius: borderRadius
  }
});

export const unavailableChannelStyle = {
  opacity: 0.2
};

const styles = createStyles({
  container: {
    alignSelf: 'center'
  },
  iconContainer: {
    position: 'absolute',
    justifyContent: 'center',
    alignSelf: 'flex-end',
    top: '-40%',
    right: '-40%',
    width: '80%',
    height: '80%'
  },
  iconTouchable: {
    width: '100%',
    height: '100%'
  },
  channelIconView: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  },
  iconWrapper: {
    overflow: 'hidden'
  }
});

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  iconBackground: {
    backgroundColor: isMobile
      ? colors.channelIcon.background.default
      : colors.newUi.channelIcon.background.default
  },
  selectedIconBackground: {
    backgroundColor: isMobile
      ? colors.channelIcon.background.selected
      : colors.newUi.channelIcon.background.selected
  },
  focusedIconBackground: {
    backgroundColor: isMobile
      ? colors.channelIcon.background.focused
      : colors.newUi.channelIcon.background.focused
  },
  epgIconBackground: {
    backgroundColor: isMobile
      ? colors.channelIcon.background.default
      : colors.epgScreen.grid.channelIcon.unfocused
  },
  epgActiveIconBackground: {
    backgroundColor: isMobile
      ? colors.channelIcon.background.selected
      : colors.epgScreen.grid.channelIcon.active
  }
}));

const defaultStyle = StyleSheet.flatten([sizes.static, styles.container]) as ViewStyle;
const styleMap = new Map<ChannelIconType, {default: ViewStyle; focused?: ViewStyle; selected?: ViewStyle}>();
styleMap.set(ChannelIconType.Static, {
  default: sizes.static
});
styleMap.set(ChannelIconType.ChannelListMobile, {
  default: StyleSheet.flatten([sizes.channelListMobile, styles.container]),
  focused: StyleSheet.flatten([sizes.scaledChannelListMobile, styles.container]),
  selected: StyleSheet.flatten([sizes.scaledChannelListMobile, styles.container])
});
styleMap.set(ChannelIconType.ChannelListMobileZapper, {
  default: StyleSheet.flatten([sizes.channelListMobile, styles.container]),
  focused: StyleSheet.flatten([sizes.scaledChannelListMobile, styles.container]),
  selected: StyleSheet.flatten([sizes.scaledChannelListMobile, styles.container])
});
styleMap.set(ChannelIconType.ChannelGrid, {
  default: StyleSheet.flatten([sizes.channelGrid, styles.container]),
  focused: StyleSheet.flatten([sizes.channelGrid, styles.container]),
  selected: StyleSheet.flatten([sizes.channelGrid, styles.container])
});
styleMap.set(ChannelIconType.EventDetails, {
  default: sizes.eventDetails
});
styleMap.set(ChannelIconType.ChannelShortDetails, {
  default: sizes.channelShortDetails,
  focused: sizes.channelShortDetails,
  selected: sizes.channelShortDetails
});
styleMap.set(ChannelIconType.Epg, {
  default: sizes.epg,
  focused: sizes.scaledEpg,
  selected: sizes.scaledEpg
});
styleMap.set(ChannelIconType.Zapper, {
  default: sizes.zapper
});
styleMap.set(ChannelIconType.EventSelector, {
  default: StyleSheet.flatten([sizes.eventSelector, styles.container]),
  focused: StyleSheet.flatten([sizes.eventSelector, styles.container]),
  selected: StyleSheet.flatten([sizes.eventSelector, styles.container])
});

const getStyle = (isSelected: boolean, isFocused: boolean, type: ChannelIconType) => {
  const focused = isBigScreen ? isFocused : false;
  const style = styleMap.get(type);
  const styleDefault = style && style.default ? style.default : defaultStyle;
  const styleFocused = style && style.focused ? style.focused : defaultStyle;
  const styleSelected = style && style.selected ? style.selected : defaultStyle;
  return focused ? styleFocused : (isSelected ? styleSelected : styleDefault);
};

const getSizeFromStyle = (style: ViewStyle) => Math.max(+(style.width || 0), +(style.height || 0));

export type ChannelIconProps = {
  style?: ViewStyle;
  iconBackgroundStyle?: ViewStyle;
  type: ChannelIconType;
  isSelected?: boolean;
  channelId?: string;
  pictureUrl?: string;
  animated?: boolean;
  children?: ReactNode;
  onPress?: (channelId?: string, event?: GestureResponderEvent) => void;
  onFocus?: (channelId?: string, event?: GestureResponderEvent) => void;
  onFocusCapture?: (channelId?: string) => boolean;
  onBlur?: () => void;
};

const maxSizeRelativeToDiameter = 0.65; // sqrt(2) / 2 - some padding

function imageStyle(ratio: number): ImageStyle {
  if (ratio === 0) {
    return {};
  }

  if (ratio > 1) {
    return {
      width: (100 * maxSizeRelativeToDiameter) + '%',
      height: (maxSizeRelativeToDiameter * 100 / ratio) + '%'
    };
  }

  return {
    width: (maxSizeRelativeToDiameter * 100 * ratio) + '%',
    height: (100 * maxSizeRelativeToDiameter) + '%'
  };
}

function fetchChannel(channelId: string, type: PictureType, width: number, height: number, mode: PictureMode): {url?: string; name: string} | undefined {
  const channel = mw.catalog.getChannelById(channelId);
  if (!channel) {
    return;
  }
  const channelName = channel.name || channel.longName;
  if (channel.pictures.length) {
    const uri = mw.catalog.getPictureUrl(channel, type, width, height, mode);
    return {url: uri, name: channelName};
  }
  return {name: channelName};
}

type ChannelIconWrapperProps = PropsWithChildren<TestProps & {
  channelName: string;
  type: ChannelIconType;
  onPress: (event?: GestureResponderEvent) => void;
  onFocus: (event?: GestureResponderEvent) => void;
  onBlur: () => void;
}>;

const ChannelIconWrapper = React.forwardRef<FocusableComponent, ChannelIconWrapperProps>((props, ref) => {
  const testID = `button_channel_${humanCaseToOneWord(props.channelName)}`;

  switch (props.type) {
    case ChannelIconType.Static:
    case ChannelIconType.EventDetails:
    case ChannelIconType.Epg:
    case ChannelIconType.Zapper:
    case ChannelIconType.ChannelListMobile:
    case ChannelIconType.ChannelListMobileZapper:
    case ChannelIconType.ChannelShortDetails:
      return (<>{props.children}</>);
    default:
      return (
        <NitroxInteractive
          focusOnPress={isWeb}
          ref={ref}
          style={styles.iconTouchable}
          activeOpacity={1}
          underlayColor={constColors.transparent}
          onPress={props.onPress}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          testID={testID}
        >
          {props.children}
        </NitroxInteractive>
      );
  }
});
ChannelIconWrapper.displayName = 'ChannelIconWrapper';

const ChannelIconComponent = React.forwardRef<FocusableComponent, ChannelIconProps>((props, ref) => {
  const {onPress: propsOnPress, channelId: propsChannelId, onFocus: propsOnFocus, onFocusCapture, onBlur: propsOnBlur, type} = props;
  const [channelName, setChannelName] = useState('??');
  const [isFocused, setIsFocused] = useState(props.isSelected || false);
  const [style, setStyle] = useState(getStyle(!!props.isSelected, isFocused, props.type));
  const animationSize = useRef(new Animated.Value(getSizeFromStyle(style)));

  const propagateFocus = useCallback((event?: GestureResponderEvent) => {
    propsOnFocus?.(propsChannelId, event);
  }, [propsOnFocus, propsChannelId]);

  const onFocus = useCallback((event?: GestureResponderEvent) => {
    const handled = onFocusCapture && onFocusCapture(propsChannelId);
    if (handled) {
      return;
    }
    setIsFocused(true);
    propagateFocus(event);
  }, [onFocusCapture, propagateFocus, propsChannelId]);

  const onBlur = useCallback(() => {
    setIsFocused(false);
    propsOnBlur?.();
  }, [propsOnBlur]);

  const animateSize = (value: number) => {
    Animated.timing(animationSize.current, {toValue: value, duration: 250}).start();
  };

  // react to blurs accordingly
  useEffect(() => {
    if (!props.isSelected) {
      setIsFocused(!!props.isSelected);
    }
  }, [props.isSelected]);

  const iconSizeToFetch = useMemo(() => {
    switch (props.type) {
      case ChannelIconType.Static:
        return channelIconConstants.static.size;
      case ChannelIconType.ChannelGrid:
        return channelIconConstants.channelGrid.size;
      case ChannelIconType.ChannelListMobile:
      case ChannelIconType.ChannelListMobileZapper:
        return channelIconConstants.channelList.scaledMobile;
      case ChannelIconType.EventDetails:
        return channelIconConstants.eventDetails.size;
      case ChannelIconType.ChannelShortDetails:
        return Math.min(channelIconConstants.channelShortDetails.height, channelIconConstants.channelShortDetails.width);
      case ChannelIconType.Epg:
        return channelIconConstants.epg.scaledSize;
      case ChannelIconType.Zapper:
        return channelIconConstants.zapper.size;
      case ChannelIconType.EventSelector:
        return channelIconConstants.eventSelector.size;
      default:
        return channelIconConstants.static.size;
    }
  }, [props.type]);

  const getChannelInfo = useCallback(() => {
    if (!props.channelId) {
      return;
    }
    return fetchChannel(props.channelId, PictureType.Logo, iconSizeToFetch, iconSizeToFetch, PictureMode.BOX);
  }, [props.channelId, iconSizeToFetch]);

  const isChannelAvailable = useChannelAvailability(props.channelId);
  const channelStyle = useMemo(() => isChannelAvailable ? {} : unavailableChannelStyle, [isChannelAvailable]);

  const [pictureUrl, setPictureUrl] = useState(getChannelInfo()?.url);

  useLazyEffect(() => {
    const info = getChannelInfo();
    if (!info) {
      return;
    }
    setPictureUrl(info.url);
    setChannelName(info.name);
  }, [props.channelId, iconSizeToFetch], [getChannelInfo]);

  useEffect(() => {
    setStyle(getStyle(!!props.isSelected, isFocused, props.type));
  }, [isFocused, props.isSelected, props.type]);

  useEffect(() => {
    props.animated && animateSize(getSizeFromStyle(style));
  }, [props.animated, style]);

  const [ratio, setRatio] = useState(0);
  const handleRatio = useDisposableCallback(
    (srcWidth: number, srcHeight: number) => {
      if (srcHeight === 0) {
        return;
      }
      const ratio = srcWidth / srcHeight;
      setRatio(ratio);
    }
  );

  useEffect(() => {
    if (!pictureUrl) {
      return;
    }
    Image.getSize(
      pictureUrl,
      handleRatio,
      e => {}
    );
  }, [pictureUrl, handleRatio]);

  const onPress = useCallback((event?: GestureResponderEvent) => {
    propsOnPress?.(propsChannelId, event);
  }, [propsChannelId, propsOnPress]);

  const channelIconWrapperProps = {
    ...props,
    channelName,
    onPress,
    onBlur,
    onFocus,
    type
  };

  const dynamicStyles = dynamicStylesUpdater.getStyles();

  const backgroundStyle = useMemo(() => {
    switch (type) {
      case ChannelIconType.ChannelListMobile:
      case ChannelIconType.ChannelGrid:
        return props.isSelected ? dynamicStyles.selectedIconBackground : dynamicStyles.iconBackground;
      case ChannelIconType.Epg:
        return props.isSelected ? dynamicStyles.epgActiveIconBackground : dynamicStyles.epgIconBackground;
      case ChannelIconType.EventSelector:
        return isBigScreen && isFocused ? dynamicStyles.focusedIconBackground : dynamicStyles.iconBackground;
      default:
        return dynamicStyles.iconBackground;
    }
  }, [dynamicStyles, type, isFocused, props.isSelected]);

  const borderRadiusStyle = useMemo<ViewStyle>(() => {
    const {borderRadius, borderTopLeftRadius,borderTopRightRadius, borderBottomLeftRadius, borderBottomRightRadius} = props.style || style;
    return {borderRadius, borderTopLeftRadius, borderTopRightRadius, borderBottomLeftRadius, borderBottomRightRadius};
  }, [props.style, style]);

  return (
    <Animated.View style={[style, props.style, props.animated && {width: animationSize.current, height: animationSize.current}]}>
      <ChannelIconWrapper ref={ref} {...channelIconWrapperProps}>
        <View style={[backgroundStyle, borderRadiusStyle, styles.channelIconView, props.iconBackgroundStyle, styles.iconWrapper]} testID='channel_icon'>
          {!!pictureUrl && !!ratio && <Image style={[channelStyle, {...imageStyle(ratio)}]} source={{uri: pictureUrl}} testID={`image_${channelName}`} />}
          {!pictureUrl && <NitroxText style={channelStyle} textType='callout-micro' testID={`text_${channelName}`}>{channelName}</NitroxText>}
        </View>
      </ChannelIconWrapper>
      {props.children}
    </Animated.View>
  );
});
ChannelIconComponent.displayName = 'ChannelIconComponent';
export const ChannelIcon = React.memo(ChannelIconComponent);
ChannelIcon.displayName = 'ChannelIcon';
