import i18next from 'i18next';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, ViewStyle} from 'react-native';

import {VodFilter, RecordingsFilter} from 'mw/api/Metadata';

import OptionPicker from './OptionPicker';
import {Option} from './OptionPicker.shared';

const defaultFilter = VodFilter.all;

export interface FilterOption<T> extends Option {
  filter: T;
}

export const createVodFilterOptions: () => FilterOption<VodFilter>[] = () => ([
  {
    key: VodFilter.seen,
    filter: VodFilter.seen,
    label: i18next.t('filter.seen')
  },
  {
    key: VodFilter.notSeen,
    filter: VodFilter.notSeen,
    label: i18next.t('filter.notSeen')
  },
  {
    key: VodFilter.all,
    filter: VodFilter.all,
    label: i18next.t('filter.all')
  }
]);

export const createRecordingsFilterOptions: () => FilterOption<RecordingsFilter>[] = () => ([
  {
    key: RecordingsFilter.all,
    filter: RecordingsFilter.all,
    label: i18next.t('filter.all')
  }
]);

export type FilterSelectProps<T> = {
  filterOptions: FilterOption<T>[];
  currentFilter?: string;
  onFilterSelected: (value: T) => void;
  style?: StyleProp<ViewStyle>;
}

function FilterSelect<T>(props: FilterSelectProps<T>) {
  const {filterOptions, currentFilter = defaultFilter, onFilterSelected, style} = props;
  const {t} = useTranslation();

  const onOptionSelected = useCallback((key: string) => {
    const option = filterOptions.find(option => option.key === key);
    if (option) {
      onFilterSelected(option.filter);
    }
  }, [onFilterSelected, filterOptions]);

  return (
    <OptionPicker
      label={t('filter.filter')}
      options={filterOptions}
      defaultOptionKey={currentFilter}
      onOptionSelected={onOptionSelected}
      style={style}
    />
  );
}

export default React.memo(FilterSelect) as typeof FilterSelect;
