import {Log} from 'common/Log';

import {Error} from 'mw/api/Error';
import {PlayerEvent, Track} from 'mw/api/PlayerEvent';
import {NativeEventEmitter} from 'mw/playback/NativePlaybackEventEmitter.web';
import {PlayerType} from 'mw/playback/types/PlayerType';

import {AudioLanguagesChanged} from './events/AudioLanguagesChanged';
import {Error as ErrorEvent} from './events/Error';
import {MediaEvent} from './events/MediaEvent';
import {SubtitlesChanged} from './events/SubtitlesChanged';
import {VideoResolutionsChanged} from './events/VideoResolutionsChanged';
import {NitroxPlayerEventHandler} from './nxpal/NitroxPlayerEventHandler';

const TAG = 'EventHandler';

export class EventHandler implements NitroxPlayerEventHandler {

  private playerId: number;
  private url: string;
  private playerType: PlayerType;

  public constructor(playerId: number, url: string, playerType: PlayerType) {
    this.playerId = playerId;
    this.url = url;
    this.playerType = playerType;
  }

  public onStart(): void {}

  public onStop(): void {
    this.sendMediaEvent(PlayerEvent.Stopped);
  }

  public onBos(): void {
    this.sendMediaEvent(PlayerEvent.BeginOfContent);
  }

  public onEos(): void {
    this.sendMediaEvent(PlayerEvent.EndOfContent);
  }

  public onError(error: Error): void {
    Log.debug(TAG, 'onError():', error);
    this.sendEvent(new ErrorEvent(this.playerType, error.type, this.playerId));
  }

  public onFirstFrame(): void {
    this.sendMediaEvent(PlayerEvent.FirstFrame);
  }

  public onBuffering(): void {
    this.sendMediaEvent(PlayerEvent.Buffering);
  }

  public onReady(): void {}

  public onAudioTracksChanged(tracks: Track[], trackIndex: number): void {
    Log.debug(TAG, 'onAudioTracksChanged: current', trackIndex);
    this.sendEvent(new AudioLanguagesChanged(this.playerType, tracks, trackIndex, this.playerId));
  }

  public onSubtitleTracksChanged(tracks: Track[], trackIndex: number): void {
    Log.debug(TAG, 'onSubtitleTracksChanged: current', trackIndex);
    this.sendEvent(new SubtitlesChanged(this.playerType, tracks, trackIndex, this.playerId));
  }

  public onVideoTracksChanged(tracks: string[], trackIndex: number): void {
    Log.debug(TAG, 'onVideoTracksChanged: current', trackIndex);
    this.sendEvent(new VideoResolutionsChanged(this.playerType, tracks, trackIndex, this.playerId));
  }

  private sendMediaEvent(eventType: PlayerEvent): void {
    Log.debug(TAG, 'sendMediaEvent', eventType);
    this.sendEvent(new MediaEvent(this.playerType, this.url, eventType, this.playerId));
  }

  public sendEvent = NativeEventEmitter.sendEvent;
}
