import React, {useRef, useState, useCallback} from 'react';
import {Animated, StyleProp, ViewStyle, Easing} from 'react-native';
import LinearGradient, {LinearGradientProps} from 'react-native-linear-gradient';

import {useLazyEffect} from 'hooks/Hooks';

// GradientHelper was created as animated components don't work
// with arrays of Animated.Values passed in props. Instead of passing
// array, multiple color params are passed as separated props and
// parse it to array.
//
// Please note that interpolation works only with colors passed as RGB values.

const defaultColors = ['transparent', 'transparent'];

type GradientHelperProps = {
  [key: number]: string;
} & Omit<LinearGradientProps, 'colors'>;

class GradientHelper extends React.Component<GradientHelperProps> {
  private generateColorsArray(props: GradientHelperProps): string[] {
    const colorsArray: string[] = [];
    Object.keys(props).forEach(key => {
      const numberKey = parseInt(key, 10);
      if (
        !isNaN(numberKey) &&
        typeof props[numberKey] === 'string' &&
        props[numberKey].search('rgb') !== -1
      ) {
        colorsArray.push(props[numberKey]);
      }
    });

    return colorsArray.length ? colorsArray : defaultColors;
  }

  public render() {
    const {
      style,
      locations,
      start = {x: 0, y: 0},
      end = {x: 0, y: 1}
    } = this.props;

    return (
      <LinearGradient
        colors={this.generateColorsArray(this.props)}
        start={start}
        end={end}
        style={style}
        locations={locations}
      />
    );
  }
}

const AnimatedGradientHelper = Animated.createAnimatedComponent(GradientHelper);

type Props = {
  animationDuration: number;
  colors: string[];
  style?: Animated.WithAnimatedValue<StyleProp<ViewStyle>>
} & Omit<LinearGradientProps, 'colors' | 'style'>;

const AnimatedGradient: React.FunctionComponent<Props> = props => {
  const {colors, animationDuration} = props;
  const [previousColors, setPreviousColors] = useState<string[]>(colors);
  const [currentColors, setCurrentColors] = useState<string[]>(colors);
  const animation = useRef<Animated.CompositeAnimation | null>(null);
  const animatedValue = useRef(new Animated.Value(0));

  const generateAnimatedColors = useCallback((colors: string[], prevColors: string[]) => {
    return {
      ...colors.map((color, index) => animatedValue.current.interpolate({
        inputRange: [0, 1],
        outputRange: [prevColors?.[index] || color, color],
        easing: Easing.inOut(Easing.sin)
      }))
    };
  }, []);

  const [animatedColors, setAnimatedColors] = useState<{[key: number]: Animated.AnimatedInterpolation}>(generateAnimatedColors(colors, previousColors));

  useLazyEffect(() => {
    animatedValue.current = new Animated.Value(0);
    setPreviousColors(currentColors);
    setCurrentColors(colors);
  }, [colors], [currentColors]);

  useLazyEffect(() => {
    setAnimatedColors(generateAnimatedColors(colors, previousColors));
    animation.current = Animated.timing(animatedValue.current, {
      toValue: 1,
      duration: animationDuration
    });
    animation.current.start();
    return () => {
      animation.current?.stop();
    };
  }, [currentColors], [animationDuration, previousColors]);

  return (
    <AnimatedGradientHelper
      {...animatedColors}
      {...props}
    />
  );
};

export default React.memo(AnimatedGradient);
