import {Log} from 'common/Log';

import {RewindDirection} from './PlaybackRewinder';
import {SkipBasedPlaybackRewinder} from './SkipBasedPlaybackRewinder';

const TAG = 'SimplePlaybackRewinder';

export class SimplePlaybackRewinder extends SkipBasedPlaybackRewinder {

  protected update = (): Promise<void> => {
    this.updateHandler();
    if (!this.isPositionInRange()) {
      return this.stop();
    }
    return Promise.resolve();
  };

  public start(direction: RewindDirection): Promise<void> {
    this.changeParameters({playRate: 0});
    return super.start(direction);
  }

  public stop(): Promise<void> {
    if (!this.isRewinding()) {
      Log.debug(TAG, 'There is no need to stop a rewind');
      return Promise.resolve();
    }
    Log.debug(TAG, 'Stopping rewind');
    const skip = this.accumulatedDelta;
    this.reset();
    this.changeParameters({playRate: 1, skip});
    return Promise.resolve();
  }
}
