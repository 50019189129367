import {useMemo, useState} from 'react';

import {unique} from 'common/helpers/ArrayHelperFunctions';

import {NamedAction} from 'components/utils/SwimlaneVisibilityLimit';

const headerActionsUniqueKey = 'name';

export function useHeaderActions(staticHeaderActions: NamedAction[] = []) {
  const [dataSourceHeaderAction, setDataSourceHeaderAction] = useState<NamedAction>();

  const headerActions = useMemo(() => {
    const actions = [...staticHeaderActions];
    if (dataSourceHeaderAction) {
      actions.push(dataSourceHeaderAction);
    }
    return unique(actions, headerActionsUniqueKey);
  }, [staticHeaderActions, dataSourceHeaderAction]);

  return {
    setDataSourceHeaderAction,
    headerActions
  };
}
