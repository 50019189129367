import {environment} from 'common/Environment';
import {Log, LogLevel} from 'common/Log';

const TAG = 'Profiler';

interface ProfilerData {
  start: number;
  modify?: number;
}

const appStartTime = Date.now();

export class Profiler {
  public static enabled = environment.getBoolean('PROFILER_ENABLED');
  private static profiles = new Map<string, ProfilerData>();

  public static start(tag: string, ...comments: any) {
    if (!Profiler.enabled) {
      return;
    }

    const start = Date.now();
    Profiler.profiles.set(tag, {start});
    Log.profiler(TAG, 'start', tag, comments, start - appStartTime);
  }

  public static entry(tag: string, ...comments: any) {
    if (!Profiler.enabled) {
      return;
    }
    const now = Date.now();
    const profile = Profiler.profiles.get(tag);
    if (!profile) {
      Log.profiler(TAG, 'end: no such profile', tag, comments, now - appStartTime);
      return;
    }

    const fromStart = now - profile.start;
    const fromModify = profile.modify && (now - profile.modify);
    profile.modify = now;

    Log.profiler(TAG, tag, comments, now - appStartTime, fromStart, fromModify);
  }

  public static add(callback: () => void): void {
    Profiler.enabled ? callback() : null;
  }
}

if (Profiler.enabled) {
  Log.setLogLevel(LogLevel.PROFILER);
}

