import dashjs, {MediaPlayerClass, ErrorEvent} from 'dashjs';
import videojs from 'video.js';

import {Log} from 'common/Log';

import {Error, ErrorType} from 'mw/api/Error';
import {VideojsNitroxPlayer, VjsWithHttpStreaming} from 'mw/playback/web-native-player/adapters/videojs/VideojsNitroxPlayer';

const TAG = 'VideojsNitroxPlayerDash';

export class VideojsNitroxPlayerDash extends VideojsNitroxPlayer {

  public release(): void {
    const mediaPlayer = this.getDashMediaPlayer();
    if (mediaPlayer) {
      mediaPlayer.off('error', this.onError);
    }

    super.release();
  }

  protected errorHandlingInit(): void {
    (videojs as any).Html5DashJS.hook('beforeinitialize', this.errorHandlingHook);
  }

  private errorHandlingHook = (player: VjsWithHttpStreaming, mediaPlayer: dashjs.MediaPlayerClass) => {
    mediaPlayer.on('error', this.onError);

    (videojs as any).Html5DashJS.removeHook('beforeinitialize', this.errorHandlingHook);
  }

  private onError = (errorEvent: ErrorEvent) => {
    if (errorEvent.error === 'download') {
      // Ignore 'download' error because it is reported twice, second time as MediaPlayerErrorEvent with specific error code
      // and that is the error that is handled further
      return;
    }

    let errorType = ErrorType.NativePlayerError;

    if (typeof errorEvent.error === 'object') {
      // if error is of type object, it means its MediaPlayerErrorEvent
      switch (errorEvent.error.code) {
        case dashjs.MediaPlayer.errors.MANIFEST_LOADER_PARSING_FAILURE_ERROR_CODE:
          errorType = ErrorType.NativePlayerSourceCorrupted;
          break;

        // It's impossible to define the reason of manifest download failure,
        // so its not possible to implement CL-4973 (ErrorType.PlaybackSourceError)
        // so all the manifest download failure errors are handled as described in CL-4972 (ErrorType.PlaybackSourceMissing)
        case dashjs.MediaPlayer.errors.DOWNLOAD_ERROR_ID_MANIFEST_CODE:
          errorType = ErrorType.NativePlayerSourceNotFound;
          break;

        // It's impossible to distinguish connection refused and connection timeout errors (CL-6036)
        case dashjs.MediaPlayer.errors.MEDIA_KEY_MESSAGE_LICENSER_ERROR_CODE:
          errorType = ErrorType.NativePlayerDrmLicenseAcquisitionConnectionRefused;
          break;
      }
    }

    this.handlePlaybackError(new Error(errorType, JSON.stringify(errorEvent)));
  }

  private getDashMediaPlayer(): MediaPlayerClass | null {
    // VideoJs is actually an interface for playback implementations,
    // dash playback is provided with additional library so the property 'dash' may appear
    const dashHandler = this.getPlayer().dash || this.playerTech()?.sourceHandler_?.mediaPlayer_;
    // http://cdn.dashjs.org/latest/jsdoc/module-MediaPlayer.html
    return dashHandler?.mediaPlayer || null;
  }

  public getPosition(): number {
    if (this.isLiveStream()) {

      const dashMediaPlayer = this.getDashMediaPlayer();
      if (dashMediaPlayer) {
        const timeAsUTC = dashMediaPlayer.timeAsUTC();
        if (!isNaN(timeAsUTC)) {
          return Math.round(timeAsUTC);
        }
      }
    }

    return super.getPosition();
  }

  protected getLiveStreamPlaylistEnd(): number | null {
    const dashMediaPlayer = this.getDashMediaPlayer();
    if (dashMediaPlayer) {

      const liveCurrentTime = dashMediaPlayer.durationAsUTC();
      if (liveCurrentTime && (liveCurrentTime !== Infinity)) {
        return Math.round(liveCurrentTime);
      }
    }

    return super.getLiveStreamPlaylistEnd();
  }

  protected getLiveWindow(): number {
    const dashMediaPlayer = this.getDashMediaPlayer();
    if (dashMediaPlayer) {
      return dashMediaPlayer.getDVRWindowSize();
    }

    return 0;
  }

  protected drmInit(): void {
    if (!this.drmInterceptor) {
      Log.warn(TAG, 'drmInit: drmInterceptor not set');
      return;
    }

    (videojs as any).Html5DashJS.hook('beforeinitialize', this.drmHook);
  }

  private drmHook = (player: VjsWithHttpStreaming, mediaPlayer: dashjs.MediaPlayerClass) => {
    mediaPlayer.on((dashjs as any).Protection.events.LICENSE_REQUEST_COMPLETE, (eventData: dashjs.LicenseRequestCompleteEvent) => {
      Log.debug(TAG, 'LicenseRequestCompleteEvent', eventData?.error, eventData?.data?.messageType);
      this.drmInterceptor?.onDrmResponse(eventData);
    });

    (videojs as any).Html5DashJS.removeHook('beforeinitialize', this.drmHook);
  }
}
