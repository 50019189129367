import {createStyles} from 'common-styles';
import React, {useMemo, useCallback} from 'react';
import {StyleProp, ViewStyle} from 'react-native';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {IconType} from 'components/Icon';
import NitroxButton from 'components/NitroxButton';
import {useToggle} from 'hooks/Hooks';
import SettingsLabel from 'screens/settings/SettingsLabel';

import FocusParent from './FocusParent';

const styles = createStyles({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    height: dimensions.buttons.large
  }
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  icon: {
    color: colors.checkbox.icon,
    backgroundColor: colors.checkbox.background
  }
}));

type Props = {
  label: string;
  style?: StyleProp<ViewStyle>;
  selected?: boolean;
  disabled?: boolean;
  size?: number;
  onPress?: () => void;
}

const WizardCheckbox: React.FC<Props> = ({
  label,
  style,
  selected: propsSelected,
  disabled,
  size = dimensions.tile.checkboxSize,
  onPress: propsOnPress
}) => {
  const [selected, {toggle: toggleSelected}] = useToggle(!!propsSelected);
  const [focused, {on: onFocus, off: onBlur}] = useToggle(false);
  const dynamicStyles = stylesUpdater.getStyles();

  const buttonIcon = useMemo(() => {
    if (!selected) {
      return;
    }
    return {
      type: IconType.Check,
      size,
      color: focused ? dynamicStyles.icon.color.focused : dynamicStyles.icon.color.unfocused
    };
  }, [selected, focused, size, dynamicStyles.icon]);

  const buttonStyle = useMemo(() => ({
    paddingLeft: 0,
    paddingRight: 0,
    width: size + dimensions.checkbox.padding,
    height: size + dimensions.checkbox.padding
  }), [size]);

  const onPress = useCallback(() => {
    toggleSelected();
    propsOnPress?.();
  }, [toggleSelected, propsOnPress]);

  return (
    <FocusParent style={[styles.container, style]}>
      <SettingsLabel label={label} disabled={disabled} />
      <NitroxButton
        border
        style={buttonStyle}
        onPress={onPress}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        textType='buttons-settings'
        icon={buttonIcon}
        useOldImplementation
      />
    </FocusParent>
  );
};

export default React.memo(WizardCheckbox);
