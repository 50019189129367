import React from 'react';
import {StyleSheet, View, ViewStyle, StyleProp} from 'react-native';
import {Screen, screensEnabled} from 'react-native-screens';
import {ScreenProps, NavigationScreenComponent, NavigationContext} from 'react-navigation';

import {isWeb, isIOS, Direction} from 'common/constants';

import FocusParent, {FocusParentProps} from 'components/FocusParent';

type Props = {
  debugName?: string;
  isVisible: boolean;
  style?: StyleProp<ViewStyle>;
  ChildComponent: NavigationScreenComponent;
  screenProps?: ScreenProps;
}
& Pick<FocusParentProps, 'enterStrategy'>;

export const FAR_FAR_AWAY = 30000; // this should be big enough to move the whole view out of its container

const styles = StyleSheet.create({
  container: {
    flex: 1,
    overflow: 'hidden'
  },
  attached: {
    flex: 1
  },
  detached: {
    flex: 1,
    top: FAR_FAR_AWAY
  }
});

export class ResourceSavingScene extends React.PureComponent<Props> {
  public render() {
    const {debugName, isVisible, enterStrategy, style, ChildComponent, screenProps, ...rest} = this.props;
    if (screensEnabled && screensEnabled()) {
      return (
        <Screen active={isVisible} style={style}>
          <FocusParent
            style={StyleSheet.absoluteFill}
            enterStrategy={enterStrategy}
            active={isVisible}
            debugName={debugName ?? ResourceSavingScene.name}
            trapFocus
            trapExitEdges={[Direction.Up]}
          >
            <NavigationContext.Consumer>
              {navigation => <ChildComponent navigation={navigation} screenProps={screenProps} />}
            </NavigationContext.Consumer>
          </FocusParent>
        </Screen>
      );
    }

    return (
      <View
        style={[
          styles.container,
          style,
          {...(isWeb) ? {display: (isVisible) ? 'flex' : 'none'} : {opacity: isVisible ? 1 : 0}},
          isVisible ? styles.attached : styles.detached
        ]}
        collapsable={false}
        removeClippedSubviews={
          // On iOS, set removeClippedSubviews to true only when not focused
          // This is an workaround for a bug where the clipped view never re-appears
          isIOS ? !isVisible : true
        }
        pointerEvents={isVisible ? 'auto' : 'none'}
        {...rest}
      >
        <FocusParent
          style={isVisible ? styles.attached : styles.detached}
          enterStrategy={enterStrategy}
          active={isVisible}
          debugName={ResourceSavingScene.name}
          trapFocus
          trapExitEdges={[Direction.Up]}
        >
          <NavigationContext.Consumer>
            {navigation => <ChildComponent navigation={navigation} screenProps={screenProps} />}
          </NavigationContext.Consumer>
        </FocusParent>
      </View>
    );
  }
}
