export enum ViewershipError {
  PlayerNotSupportedSource = 101,
  PlaybackSessionError = 102,
  PlaybackSessionExpired = 103,
  PlaybackSourceNotPlayable = 104,
  PlaybackEntitlementIssue = 105,
  PlaybackLocationForbidden = 106,
  PlaybackVPNForbidden = 107,
  PlaybackTooManyConcurrentSessions = 108,
  PlaybackSessionInvalidContent = 109,
  PlaybackSessionGeneralError = 110,
  PlaybackSessionNoAsset = 111,
  PlaybackSessionInvalidCpe = 112,
  PlaybackSessionContentNotPlayable = 113,
  PlaybackSourceMissing = 200,
  PlaybackSourceError = 201,
  PlaybackSourceCorrupted = 202,
  BPKNoUrl = 210,
  DrmLicenseServerGeoblocking = 300,
  DrmLicenseServerUnavailable = 301,
  DrmLicenseServerUnknownContent = 302,
  DrmLicenseServerInvalidSession = 303,
  DrmLicenseServerDeviceLimitReached = 304,
  DrmLicenseServerEntitlementError = 305,
  DrmLicenseServerPreconditionFailed = 306,
  NetworkNoConnection = 900,
  HttpTimeout = 901,
  NetworkRequestFailed = 902,
  Offline = 903,
  UnexpectedApplicationTermination = 950,
  PlayerSourceMissing = 999,
  PlaybackSessionMissing = 999,
  UnexpectedError	= 999
}
