import {createStyles} from 'common-styles';
import React, {useState, useRef, useMemo} from 'react';
import {StyleSheet, View} from 'react-native';
import {NavigationScreenProps, NavigationContext, withNavigation} from 'react-navigation';

import {Direction, AppRoutes} from 'common/constants';
import {NavigationFocusState} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {ErrorType} from 'mw/api/Error';
import {isEvent, Media, Channel} from 'mw/api/Metadata';
import {PlayerEvent} from 'mw/api/PlayerEvent';
import {mw} from 'mw/MW';

import FocusParent from 'components/FocusParent';
import {useEnableFullScreenControlOnAppear} from 'components/fullscreen/FullScreenControlProvider';
import FullScreenTouchableView from 'components/fullscreen/FullScreenTouchableView';
import {STBMenuState} from 'components/navigation/NavigationHelperTypes';
import {useSTBMenu} from 'components/navigation/STBNavigationView';
import NavigationFocusGuard from 'components/NavigationFocusGuard';
import {awaitInteractions} from 'components/performance/InteractionHandle';
import PlayerLanguageController from 'components/player/PlayerLanguageController';
import {PlayerViews, PlayerView} from 'components/player/PlayerView';
import {usePlayerErrorPopup, PlayerErrorPopupHandlers} from 'components/player/usePlayerErrorPopup';
import {useRecord} from 'components/pvr/Record';
import TvOverlay from 'components/zapper/TvOverlay.grosso';
import UnavailableChannelMessage from 'components/zapper/UnavailableChannelMessage';
import {useNavigationFocusState, useNavigation, useFunction, useEventListener, useChangeEffect, useWaitForStateChange} from 'hooks/Hooks';
import {useInactivityTimeout} from 'hooks/useInactivityTimeout';
import NitroxScreen from 'screens/NitroxScreen';

import {findChannelByDirection} from './TvScreenHelperFunctions';
import {TuneParams} from './TvScreenHelperTypes';
import {useChannels, useTvScreenParams, useEPG, useEndOfContentHandler, useTune, usePlayerViewReadyCallback, usePlayerControlsViewButtonsVisibility, useTvPlayerControlsHandlers, useMenuIntegration, usePlaybackParameters, useOverlayVisibility, useTvFocusParent, useCurrentMedia, useUpdateTvScreenParams, useLogTvScreenParams, useRewindListener} from './TvScreenHooks';

const TAG = 'TvScreenGrosso';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    backgroundColor: colors.tvScreen.background,
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  hidden: {
    opacity: 0
  },
  portalContainer: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: colors.playerScreen.background
  }
}));

type Props = NavigationScreenProps<{
  channelId?: string;
  eventId?: string;
  position?: number;
  requestedByRoute?: AppRoutes; // this prop is reset after first tuning attempt
}>;

const TvScreen: React.FC<Props> = () => {
  useLogTvScreenParams();

  const params = useTvScreenParams();
  const {channels} = useChannels();
  useEPG(channels);
  const {currentChannel, currentEvent, updateCurrentEvent, playedMedia} = useCurrentMedia(channels);
  const {updateMedia} = useUpdateTvScreenParams();

  const navigation = useNavigation();
  const stbMenu = useSTBMenu();
  const {userInactive, renderInactivityPopup} = useInactivityTimeout(TAG);

  useChangeEffect(() => {
    if (userInactive) {
      Log.info(TAG, 'User inactive, navigating to home screen...');
      navigation.navigate(AppRoutes.Home);
    }
  }, [userInactive], [navigation]);

  const [tuning, setTuning] = useState(false);
  const waitForChannelChange = useWaitForStateChange(currentChannel, 5000);

  const tuneChannel = useFunction(async (newChannel: Channel): Promise<void> => {
    setTuning(true);
    const expectedChannelChange = waitForChannelChange(newChannel);
    updateMedia(newChannel);
    await expectedChannelChange
      .catch(() => Log.error(TAG, 'Timed out waiting for channel to change'));
  });

  const customErrorHandlers = useMemo<PlayerErrorPopupHandlers>(() => ({
    [ErrorType.PlaybackChannelNotAvailable]: () => {
      channels?.length && tuneChannel(channels[0]);
    }
  }), [channels, tuneChannel]);

  const {playbackError, clearPlaybackError, renderPlaybackErrorPopups} = usePlayerErrorPopup(customErrorHandlers);

  const onTuneAuthorized = useFunction(() => {
    setTuning(true);
    clearPlaybackError();
  });

  const {paused, rewindDirection} = usePlaybackParameters();

  const onMediaUpdate = useFunction((media: Media) => {
    if (isEvent(media)) {
      updateCurrentEvent(media, false);
    }
  });

  useEventListener(PlayerEvent.MediaUpdate, onMediaUpdate, mw.players.main);

  const playerLanguageController = useRef<PlayerLanguageController>(null);
  const {playerControlsViewButtonsVisibility, refresh: refreshPlayerControlsVisibility, onPlayerTracksChanged} = usePlayerControlsViewButtonsVisibility(currentEvent);

  const onTuneFinished = useFunction(() => {
    refreshPlayerControlsVisibility();
    setTuning(false);
  });
  const {tune, scheduleTune, getTunedByRoute} = useTune(onTuneAuthorized, onTuneFinished);
  const {renderRecordingsComponents, scheduleRecording} = useRecord();
  const playerControlsHandlers = useTvPlayerControlsHandlers(tune, playerLanguageController, playerControlsViewButtonsVisibility, refreshPlayerControlsVisibility, scheduleRecording, currentEvent);

  const navigationFocusState = useNavigationFocusState(useNavigation());
  const screenFocused = navigationFocusState === NavigationFocusState.IsFocused;

  const {onEndOfContent, exitTstv} = useEndOfContentHandler(playerControlsHandlers, getTunedByRoute);
  useEventListener(PlayerEvent.EndOfContent, onEndOfContent, mw.players.main);

  const {overlayVisible, hideOverlay, showOverlay, setHidePrevented} = useOverlayVisibility({modalVisible: !!stbMenu?.hasVisibleModal});
  const styles = stylesUpdater.getStyles();

  const {backHandler: menuBackHandler} = useMenuIntegration(overlayVisible);

  const backHandler = useFunction(() => {
    if (overlayVisible) {
      hideOverlay();
    } else {
      return menuBackHandler();
    }
    return overlayVisible;
  });

  const onZap = useFunction((direction: Direction) => {
    const targetChannel = findChannelByDirection(direction, channels, currentChannel);
    if (targetChannel) {
      updateMedia(targetChannel);
    }
  });

  useRewindListener(tune, showOverlay);

  const firstTune = useRef(true);

  // schedule tune on playedMedia change
  useChangeEffect(() => {
    if (!screenFocused || !playedMedia) {
      return;
    }
    setTuning(true);
    const tuneParams: TuneParams = {
      media: playedMedia,
      playerView: PlayerViews.Zapper,
      params: {position: params.position}
    };

    if (firstTune.current) {
      firstTune.current = false;
      tune(tuneParams);
    } else {
      awaitInteractions()
        .then(() => scheduleTune(tuneParams));
    }
  }, [playedMedia, screenFocused], [scheduleTune, params]);

  // tune instantly on screen focused, if playedMedia exists
  useChangeEffect(() => {
    if (!screenFocused) {
      firstTune.current = true;
      return;
    }
  }, [screenFocused]);

  useEnableFullScreenControlOnAppear();

  return (
    <NitroxScreen
      style={styles.container}
      navigation={useNavigation()}
      stopPlaybackOnAppear={false}
      onBackButtonPressed={backHandler}
      onScreenFocused={showOverlay}
      testID='screen_tv'
      menuState={STBMenuState.Hidden}
    >
      <FocusParent style={StyleSheet.absoluteFillObject} {...useTvFocusParent({showOverlay})}>
        <View
          style={styles.portalContainer}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onMouseMove={showOverlay}
        >
          <NavigationFocusGuard>
            <PlayerView
              debugName={TAG}
              type={PlayerViews.Zapper}
              style={StyleSheet.absoluteFillObject}
              onReady={usePlayerViewReadyCallback(PlayerViews.Zapper)}
            >
              <View style={StyleSheet.absoluteFillObject} testID='zapper'>
                <TvOverlay
                  buttonsVisibility={playerControlsViewButtonsVisibility}
                  channels={channels ?? []}
                  hideOverlay={hideOverlay}
                  showOverlay={showOverlay}
                  channel={currentChannel}
                  visible={overlayVisible}
                  onZap={onZap}
                  tuneChannel={tuneChannel}
                  tuning={tuning}
                  paused={paused}
                  rewindDirection={rewindDirection}
                  playerControlsHandlers={playerControlsHandlers}
                  event={currentEvent}
                  setPreventHidingOverlay={setHidePrevented}
                  playbackError={!!playbackError}
                  exitTstv={exitTstv}
                />
                {!tuning && currentChannel?.isAvailableByPolicy === false && (
                  <UnavailableChannelMessage />
                )}
              </View>
              {!overlayVisible && (
                <FullScreenTouchableView onPress={showOverlay} testID='hidden_zapper_touch_responder' />
              )}
            </PlayerView>
          </NavigationFocusGuard>
        </View>
      </FocusParent>
      <PlayerLanguageController
        ref={playerLanguageController}
        player={mw.players.main}
        onTracksChanged={onPlayerTracksChanged}
      />
      {renderPlaybackErrorPopups()}
      {renderRecordingsComponents()}
      {renderInactivityPopup()}
    </NitroxScreen>
  );
};

// TODO: CL-3092
// - resolve navigation object problems this globally
// - resolve modal visibility globally
const TvScreenWrapper: React.FC<Props> = ({navigation, ...otherProps}) => {
  return (
    <NavigationContext.Provider value={navigation}>
      <TvScreen navigation={navigation} {...otherProps} />
    </NavigationContext.Provider>
  );
};

export default withNavigation(TvScreenWrapper);
