import {Notification, SEACNotification, CustomNotification, ActionType, SEACNotificationType} from 'mw/notifications/Notification';

export class NotificationMapper5q {
  public static fromJSON(json: any): Notification {
    // get common and required props
    const sourceId = json.SourceId;
    if (!sourceId) {
      throw new Error('SourceId is missing is notification');
    }
    const instanceId = json.NotificationInstanceId;
    if (!instanceId) {
      throw new Error('NotificationInstanceId is missing in notification');
    }
    const interfaceContract = NotificationMapper5q.getInterfaceContract(json);
    const actionType = NotificationMapper5q.getActionType(json);

    const notification = (() => {
      if (json.SEAC) {
        // build a SEAC notification
        const notificationType = NotificationMapper5q.getSEACNotificationType(json);
        const notification = new SEACNotification(sourceId, instanceId, interfaceContract, actionType, notificationType);
        notification.profileId = json.ProfileId;
        notification.recordingId = json.RecordingId;
        notification.payload = json.Payload;
        notification.messageType = json.SEAC?.MessageType;
        return notification;
      }
      // build a custom notification
      const notification = new CustomNotification(sourceId, instanceId, interfaceContract, actionType);
      notification.text = json.Text;
      notification.payload = json.Payload;
      return notification;
    })();

    // finally add common and optional props
    if (json.DeliveryWindowStart) {
      notification.deliveryWindowStart = new Date(json.DeliveryWindowStart);
    }
    if (json.DeliveryWindowEnd) {
      notification.deliveryWindowEnd = new Date(json.DeliveryWindowEnd);
    }
    notification.reference = NotificationMapper5q.getReference(json);

    return notification;
  }

  private static getInterfaceContract(json: any): string {
    if (!json.InterfaceContract) {
      throw new Error('InterfaceContract is missing in notification');
    }
    const hasSEACContract = json.InterfaceContract.indexOf('SEAC') >= 0;
    if (hasSEACContract && !json.SEAC) {
      throw new Error('Notification with SEAC interface contract has no SEAC payload');
    }
    if (!hasSEACContract && json.SEAC) {
      throw new Error('SEAC payload found in notification without an SEACH interface contract');
    }
    return json.InterfaceContract;
  }

  private static getReference(json: any): string[] {
    if (!json.Reference) {
      return [];
    }
    // The format of IDs is "sender,identifier,sent". We do not need "sent" part so if we have more than 1 comma in the ID let's remove the last part
    const reference: string[] = json.Reference;
    return reference.map(ref => ref.match(/,/)
      ? ref.split(',').slice(0, -1).join(',')
      : ref
    );
  }

  private static getActionType(json: any): ActionType {
    switch (json.ActionType) {
      case ActionType.New:
      case ActionType.Update:
      case ActionType.Cancel:
        return json.ActionType;
      default:
        throw new Error('Invalid action type found in notification');
    }
  }

  private static getSEACNotificationType(json: any): SEACNotificationType {
    switch (json.SEAC?.NotificationType) {
      case SEACNotificationType.CustomerModified:
      case SEACNotificationType.ClientModified:
      case SEACNotificationType.SubscriptionModified:
      case SEACNotificationType.PurchaseCompleted:
      case SEACNotificationType.RecordingModified:
      case SEACNotificationType.CustomerPromotionModified:
      case SEACNotificationType.CouponUsed:
      case SEACNotificationType.ProfileModified:
      case SEACNotificationType.FavoritesModified:
      case SEACNotificationType.WishlistModified:
      case SEACNotificationType.WishlistSeriesCollectionModified:
      case SEACNotificationType.WishlistProductsModified:
      case SEACNotificationType.RemindersModified:
      case SEACNotificationType.Message:
        return json.SEAC.NotificationType;
      default:
        throw new Error('Invalid SEAC notification type found in notification');
    }
  }
}
