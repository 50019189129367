import {EventEmitterInterface} from 'common/EventEmitterInterface';

import {Credentials} from 'mw/api/NXFF';
import {Profile} from 'mw/api/Profile';
import {CrossLoginDataProvider} from 'mw/common/CrossLoginDataProvider';
import {DrmType} from 'mw/Constants';

export interface SSOInterface extends CrossLoginDataProvider, EventEmitterInterface<SSOEvent> {
  login(credentials?: Credentials): Promise<void>;
  logout(): Promise<void>;
  switchProfile(profile: Profile, pin: string): Promise<void>;
  getBoUrl(): Promise<string>;
  getToken(): Promise<string>;
  invalidateToken(authorizationToken: string | null): void;
  getIdentity(): Identity;
  setIdentity(id?: string): void;
  getDrmSession(): Promise<DrmSession>;
  isLoggedIn(): boolean;
  getDevicePublicId(): string;
  getMasterPin(): string;
  isMasterPinSupported(): boolean;
  setMasterPin(pin: string): Promise<void>;
  isPurchasePinSupported(): boolean;
  setPurchasePin(pin: string): Promise<void>;
  verifyPurchasePin(pin: string): Promise<void>;
  renewToken(): Promise<void>;
}

export enum ManagedBy {
  Toolbox = 'Toolbox',
  Viu = 'Viu'
}

export interface DrmAsset {
  drmType?: DrmType;
  url?: string;
  headers?: {
    'drm-attributes': string;
  };
}

export interface DrmProperties {
  widevineLicenseUrl?: string;
  fairplayLicenseUrl?: string;
  fairplayCertificate?: Uint8Array | string;
  managedBy?: ManagedBy;
}

export interface DrmParameters {
  drmHeaders?: {[key: string]: string};
  drmProperties?: DrmProperties;
}

export interface DrmSession extends DrmParameters {
  timeout?: number;
}

export interface Identity {
  readonly id: string;
  get(): any;
}

export enum SSOEvent {
  SSOTokenRenewed = 'SSOTokenRenewed',
  SSOUnauthorized = 'SSOUnauthorized',
  SSOUserActionNeeded = 'SSOUserActionNeeded'
}

export enum CustomSSOActionType {
  SingleChoice,
  MultiChoice,
  InputValue
}

export type CustomSSOActionChoice = {
  alias: string;
  label: string;
}

export type CustomSSOAction = {
  id: string;
  message: string;
  type: CustomSSOActionType;
  choices: CustomSSOActionChoice[];
  onUserResponse: (response: CustomSSOActionUserResponse) => void;
  onUserCancel: () => void;
}

export type CustomSSOActionUserResponse = {
  id: string;
  selectedChoices: CustomSSOActionChoice['alias'][];
}

export interface SSOTokenRenewedParams {
  forced: boolean;
}
