import {Error, ErrorType} from 'mw/api/Error';
import {MediaType, Playable} from 'mw/api/Metadata';
import {MediaObject} from 'mw/metadata/MediaObject';

export class InvalidMedia extends MediaObject {
  public constructor(id: string = MediaType.InvalidMedia, name: string = MediaType.InvalidMedia) {
    super(id, name);
  }

  public getType(): MediaType {
    return MediaType.InvalidMedia;
  }

  public getPlayable(): Playable | null {
    throw new Error(ErrorType.UnknownError);
  }

  public isBlocked(): boolean {
    return false;
  }
}
