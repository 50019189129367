import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {View} from 'react-native';

import {dimensions} from 'common/constants';
import {getCreditsFullName} from 'common/HelperFunctions';

import {Credits} from 'mw/api/Metadata';

import {FAR_FAR_AWAY} from 'components/navigation/ResourceSavingScene';
import NitroxButton, {NitroxButtonTheme} from 'components/NitroxButton';

import {mediaDetailHorizontalMargin} from './MediaDetailUtils';

const mobileButtonMargin = dimensions.margins.large;
const mobileHalfButtonMargin = mobileButtonMargin / 2;

const styles = createStyles({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginHorizontal: mediaDetailHorizontalMargin - mobileHalfButtonMargin
  },
  detachedContainer: {
    top: FAR_FAR_AWAY
  },
  button: {
    marginHorizontal: mobileHalfButtonMargin,
    marginVertical: mobileHalfButtonMargin,
    paddingHorizontal: dimensions.margins.xsmall,
    flexGrow: 1,
    // TODO: CL-2324 remove this after implementing PLM
    opacity: 1
  },
  buttonText: {
    paddingLeft: 0,
    paddingRight: 0
  },
  gapFiller: {
    height: 35,
    flexGrow: 100
  }
});

type CreditsButtonProps = {
  credits: Credits;
  onPress: (credits: Credits) => void;
}

const CreditsButtonComponent: React.FC<CreditsButtonProps> = (props: CreditsButtonProps) => {
  const {credits} = props;
  const name = useMemo(() => getCreditsFullName(credits), [credits]);

  return (
    <NitroxButton
      text={name}
      style={styles.button}
      theme={NitroxButtonTheme.Secondary}
      textStyle={styles.buttonText}
      textType='callout'
      // TODO: CL-2324 remove this after implementing PLM
      disabled
    />
  );
};

const CreditsButton = React.memo(CreditsButtonComponent);

type Props = {
  credits: Credits[];
  onPress: (creditsItem: Credits) => void;
};

const CreditsViewPiccolo: React.FunctionComponent<Props> = props => {
  return (
    <View style={styles.container}>
      {props.credits.map((creditsItem, index) =>
        <CreditsButton key={index} credits={creditsItem} onPress={props.onPress} />
      )}
      <View style={styles.gapFiller} />
    </View>
  );
};

export default CreditsViewPiccolo;
