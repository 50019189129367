import {Log} from 'common/Log';

import {OrientationType} from './OrientationLockerTypes';

const TAG = 'Orientation';

export default class Orientation {
  static addDeviceOrientationListener(callback: (orientation: OrientationType) => void) {
    Log.debug(TAG, 'addOrientationListener: not supported');
    callback('UNKNOWN');
  }
  static removeDeviceOrientationListener(callback: (orientation: OrientationType) => void) {
    Log.debug(TAG, 'removeOrientationListener: not supported');
    callback('UNKNOWN');
  }
  static lockToPortrait() {
    Log.debug(TAG, 'lockToPortrait: not supported');
  }
  static lockToLandscapeLeft() {
    Log.debug(TAG, 'lockToLandscapeLeft: not supported');
  }
  static lockToLandscapeRight() {
    Log.debug(TAG, 'lockToLandscapeRight: not supported');
  }
  static unlockAllOrientations() {
    Log.debug(TAG, 'unlockAllOrientations: not supported');
  }
  static getDeviceOrientation(callback: (orientation: OrientationType) => void) {
    Log.debug(TAG, 'getDeviceOrientation: not supported');
    callback('UNKNOWN');
  }
}
