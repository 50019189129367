import mitt, {Emitter} from 'mitt';

import {NativeKeyEventSource} from './NativeKeyEventSource';

export enum SupportedKeys {
  /** Numeric keys  */
  Num0 = 0,
  Num1,
  Num2,
  Num3,
  Num4,
  Num5,
  Num6,
  Num7,
  Num8,
  Num9,
  /** Navigation */
  Left = 100,
  Right,
  Up,
  Down,
  Ok,
  Back,
  ChannelUp,
  ChannelDown,
  LastChannel,
  Search,
  Info,
  CloseKeyboard,
  /** Media controls */
  Play = 200,
  Pause,
  PlayPause,
  Stop,
  Backspace,
  Rewind,
  FastForward,
  Record,
  Subtitles,
  FullScreen
}

export type SupportedKeyEventTypes = 'keyup' | 'keydown' | 'longpress' | 'ios:pan' | 'ios:swipe' | 'web:keypress'
export const supportedDirections = [SupportedKeys.Left, SupportedKeys.Right, SupportedKeys.Up, SupportedKeys.Down] as const;
export const supportedChannelKeys: readonly SupportedKeys[] = [SupportedKeys.ChannelUp, SupportedKeys.ChannelDown] as const;
export type SupportedDirectionKeys = typeof supportedDirections[number];
export type NativeKeyEvent = {
  key?: SupportedKeys;
  event?: any;
}

const emitter = new mitt();

export interface KeyEventSource {
  bind(emitter: Emitter): void;
}

export class KeyEventManager implements Emitter {
  private static shared: KeyEventManager;
  private static keyEventEmitter: KeyEventSource;

  private onceListeners: (() => void)[] = [];

  private lastInteractionTimestamp = Date.now();
  public getLastInteractionTimestamp(): number {
    return this.lastInteractionTimestamp;
  }

  public static getInstance(): KeyEventManager {
    if (!this.shared) {
      this.shared = new KeyEventManager();
      this.keyEventEmitter = new NativeKeyEventSource();
      this.keyEventEmitter.bind(this.shared);
    }

    return this.shared;
  }

  public emit(type: SupportedKeyEventTypes, event?: NativeKeyEvent): void {
    this.lastInteractionTimestamp = Date.now();
    if (this.onceListeners.length) {
      this.onceListeners.forEach(callback => callback());
      this.onceListeners = [];
    }
    emitter.emit(type, event);
  }

  public notifyOnFirstActivity(handler: () => void): void {
    this.onceListeners.push(handler);
  }

  public addEventListener(type: SupportedKeyEventTypes, handler: (event?: NativeKeyEvent) => void): void {
    emitter.on(type, handler);
  }

  public removeEventListener(type: SupportedKeyEventTypes, handler: (event?: NativeKeyEvent) => void): void {
    emitter.off(type, handler);
  }

  public on = this.addEventListener;
  public off = this.removeEventListener;
}
