import {createStyles} from 'common-styles';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions, isMobile} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxText from 'components/NitroxText';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  popupContainer: {
    alignItems: 'center'
  },
  title: {
    color: colors.popup.text,
    marginTop: isMobile ? dimensions.margins.small : dimensions.margins.xxLarge,
    marginBottom: isMobile ? dimensions.margins.large : dimensions.margins.xxLarge,
    textAlign: 'center'
  }
}));

type Props = {
  productTitle: string;
}

const PurchaseSuccess: React.FunctionComponent<Props> = props => {
  const {productTitle} = props;
  const {t} = useTranslation();
  const styles = stylesUpdater.getStyles();

  return (
    <View style={styles.popupContainer}>
      <NitroxText textType='dialog-title' style={styles.title}>{t('payments.dialog.accessAcquired', {name: productTitle})}</NitroxText>
    </View>
  );
};

export default React.memo(PurchaseSuccess);
