import React, {useMemo} from 'react';
import {View} from 'react-native';

import {dimensions} from 'common/constants';

import {PictureType, PictureMode} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import MediaPicture from 'components/MediaPicture';
import NitroxInteractive from 'components/NitroxInteractive';
import NitroxText from 'components/NitroxText';
import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';

import {mobileVoucherRedeemTileStylesUpdater as stylesUpdater, mobileVoucherRedeemTileStyles as styles} from './VoucherRedeem.style';
import {MobileScrollElementProps} from './VoucherRedeemProps';

const MobileVoucherRedeemTile = ({media, index, onPress}: MobileScrollElementProps) => {
  const dynamicStyles = stylesUpdater.getStyles();
  const poster = useMemo(() => {
    const uri = mw.catalog.getPictureUrl(media, PictureType.Tile, dimensions.tile.poster.width, dimensions.tile.poster.height, PictureMode.BOX);
    return uri ? {uri} : null;
  }, [media]);
  const {isMediaBlocked, shouldBeCheckedForPC} = useParentalControl();
  const isBlocked = shouldBeCheckedForPC(media) && isMediaBlocked(media);
  return (
    <NitroxInteractive
      key={media.id}
      style={[styles.element, dynamicStyles.element, !index && dynamicStyles.firstElement]}
      onPress={onPress}
    >
      <View style={styles.imageStyle}>
        <MediaPicture
          source={poster}
          mediaType={media.getType()}
          isBlocked={isBlocked}
        />
      </View>
      <NitroxText style={[styles.textStyle, dynamicStyles.textStyle]} textType='buttons' numberOfLines={2}>{media.name}</NitroxText>
    </NitroxInteractive>
  );
};

export default MobileVoucherRedeemTile;
