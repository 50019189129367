import {DeviceEventEmitter, NativeModules} from 'react-native';
import DeviceInfo from 'react-native-device-info';
import Share from 'react-native-share';

import {delay} from 'common/Async';
import {isTVOS} from 'common/constants';
import {DateUtils} from 'common/DateUtils';
import {EventEmitter} from 'common/EventEmitter';
import {getLogPaths, syncLogFiles} from 'common/fileLogger/FileLogger';
import {Log} from 'common/Log';

import {Error, ErrorType} from 'mw/api/Error';
import {mw} from 'mw/MW';
import {asyncStorage} from 'mw/platform/async-storage/AsyncStorage';
import {NativeSystemModule} from 'mw/platform/native-system/NativeSystemModule';

import {KeyEventManager, SupportedKeys, NativeKeyEvent} from 'components/KeyEventManager';

const port = 32500;
const path = '/logs';

export interface OpenScreenEvent {
  screenName: Intents;
}

export interface NativeNotification {
  id: string;
  title: string;
  text: string;
  icon: string;
  isDismissable: boolean;
  isClickable: boolean;
  isSeen?: boolean;
}

export interface NativeApplication {
  packageName: string;
  label: string;
  banner: string;
  icon: string;
  isGame: boolean;
  isSystemApplication: boolean;
}

type AppsPriority = {[packageName: string]: number};

const APPS_LAST_USAGE_DATA_KEY = 'AppsLastUsageData';
export const newStylingNotificationId = 'newStylingNotification';

//Infinity has highest priority
const defaultAppsPriority: AppsPriority = {
  'com.android.vending': 5,
  'com.google.android.videos': 4,
  'com.google.android.youtube.tv': 3,
  'com.google.android.youtube.tvmusic': 2,
  'com.google.android.play.games': 1
};

const TAG = 'System';
const notificationsSeenKey = 'notificationsSeen';

/**
 * Routes used by intents and possibly other abstracted actions
 * like Intent of opening home screen on launcher, behavior may differ but intent stays same
 */
enum Intents {
  HOME = 'home',
  APPS_N_GAMES = 'launcherAppsGames',
  EPG = 'epg',
  SEARCH = 'search'
}

enum SystemEvents {
  nativeNotificationsListChanged = 'nativeNotificationsListChanged',
  openScreen = 'openScreen',
  lowMemoryStateDetected = 'lowMemoryStateDetected'
}

export enum NativeNotificationEvent {
  nativeNotificationsListChanged = 'nativeNotificationsListChanged',
  openScreen = 'openScreen',
  lowMemoryStateDetected = 'lowMemoryStateDetected'
}

export type SystemEventType = NativeNotification[] | OpenScreenEvent;

export class System extends EventEmitter<NativeNotificationEvent, SystemEventType> {
  private cachedNotifications: NativeNotification[] | null = null;
  private appsLastUsage: AppsPriority = {};

  public async initialize(): Promise<void> {
    if (mw.configuration.isLauncher) {
      // TODO PLM-444 remove this workaround
      Log.info(TAG, 'initialize: wait for device to be ready');
      await this.waitForPlatform();
      Log.info(TAG, 'initialize: should be ready');
      DeviceEventEmitter.addListener(SystemEvents.nativeNotificationsListChanged, this.onNativeNotificationsListChanged);
      DeviceEventEmitter.addListener(SystemEvents.openScreen, this.onOpenScreen);
    }
    DeviceEventEmitter.addListener(SystemEvents.lowMemoryStateDetected, this.onLowMemoryDetected);
    KeyEventManager.getInstance().addEventListener('keyup', this.onKeyUp);

    this.appsLastUsage = JSON.parse(await asyncStorage.getSecureItem(APPS_LAST_USAGE_DATA_KEY) || '{}');
  }

  private onKeyUp = (event?: NativeKeyEvent): void => {
    switch (event?.key) {
      case SupportedKeys.Search:
        this.notify(NativeNotificationEvent.openScreen, {screenName: Intents.SEARCH});
    }
  }

  private onLowMemoryDetected = (): void => {
    this.notify(NativeNotificationEvent.lowMemoryStateDetected);
  }

  private onOpenScreen = (screenEvent: OpenScreenEvent): void => {
    this.notify(NativeNotificationEvent.openScreen, screenEvent);
  }

  private onNativeNotificationsListChanged = (data: NativeNotification[]): void => {
    this.cachedNotifications = Object.values(data);
    this.notify(NativeNotificationEvent.nativeNotificationsListChanged, this.cachedNotifications);
  }

  public uninitialize(): void {
    if (mw.configuration.isLauncher) {
      DeviceEventEmitter.removeListener(SystemEvents.nativeNotificationsListChanged, this.onNativeNotificationsListChanged);
      DeviceEventEmitter.removeListener(SystemEvents.openScreen, this.onOpenScreen);
    }
    DeviceEventEmitter.removeListener(SystemEvents.lowMemoryStateDetected, this.onLowMemoryDetected);
    KeyEventManager.getInstance().removeEventListener('keyup', this.onKeyUp);
    this.clear();
  }

  public openLink(url: string): Promise<void> {
    return NativeSystemModule.openNativeApplicationLink(url);
  }

  public canOpenLink(url: string): Promise<boolean> {
    return NativeSystemModule.canOpenNativeApplicationLink(url);
  }

  public openAppStoreSearch(phrase: string): Promise<void> {
    return NativeSystemModule.openNativeStoreLink(phrase);
  }

  public getNativeApplications(): Promise<NativeApplication[] | null> {
    return NativeSystemModule.getNativeApplications()
      .then(apps => (apps || []).sort((a, b) => {
        const aPriority = this.appsLastUsage[a.packageName] || defaultAppsPriority[a.packageName] || 0;
        const bPriority = this.appsLastUsage[b.packageName] || defaultAppsPriority[b.packageName] || 0;
        return bPriority - aPriority;
      }));
  }

  public openNativeApplication(packageName: string): Promise<boolean | null> {
    this.appsLastUsage[packageName] = Math.floor(DateUtils.getSeconds(new Date()));
    asyncStorage.setSecureItem(APPS_LAST_USAGE_DATA_KEY, JSON.stringify(this.appsLastUsage));
    return NativeSystemModule.openNativeApplication(packageName);
  }

  public openSystemSettings(): Promise<void> {
    if (!mw.configuration.isLauncher) {
      return Promise.reject(new Error(ErrorType.NotSupported, TAG + ': openSystemSettings() can not be called in non launcher mode'));
    }

    return NativeSystemModule.openSystemSettings();
  }

  public getNativeNotifications(): Promise<NativeNotification[] | null> {
    if (!mw.configuration.isLauncher) {
      return Promise.reject(new Error(ErrorType.NotSupported, TAG + ': getNativeNotifications() can not be called in non launcher mode'));
    }

    if (this.cachedNotifications) {
      return Promise.resolve(this.cachedNotifications);
    }

    return NativeSystemModule.getNativeNotifications()
      .then(notifications => {
        if (notifications) {
          this.cachedNotifications = Object.values(notifications);
        }
        return this.cachedNotifications;
      });
  }

  public dismissNativeNotification(key: string): Promise<void> {
    if (!mw.configuration.isLauncher) {
      return Promise.reject(new Error(ErrorType.NotSupported, TAG + ': dismissNativeNotification() can not be called in non launcher mode'));
    }

    return NativeSystemModule.dismissNativeNotification(key);
  }

  public invokeNativeNotificationsAction(key: string): Promise<void> {
    if (!mw.configuration.isLauncher) {
      return Promise.reject(new Error(ErrorType.NotSupported, TAG + ': invokeNativeNotificationsAction() can not be called in non launcher mode'));
    }

    return NativeSystemModule.invokeNativeNotificationsAction(key);
  }

  public loadStorageNotifications(): Promise<string[]> {
    return asyncStorage.getSecureItem(notificationsSeenKey)
      .then(serializedNotifications => {
        Log.debug(TAG, 'loadStorageNotifications', 'Notifications loaded from storage');
        return serializedNotifications && JSON.parse(serializedNotifications) || [];
      })
      .catch(error => {
        Log.error(TAG, 'loadStorageNotifications', 'Notifications could not be loaded from storage', error);
        return [];
      });
  }

  public saveStorageNotifications(notifications: string[]): Promise<void> {
    return asyncStorage.setSecureItem(notificationsSeenKey, JSON.stringify(notifications))
      .then(() => Log.debug(TAG, 'saveStorageNotifications', 'Notifications saved into storage'))
      .catch(error => Log.error(TAG, 'saveStorageNotifications', 'Notifications could not be saved to storage', error));
  }

  public async markNotificationsAsSeen(notifications: NativeNotification[]): Promise<void> {
    const systemNotifications = await this.getNativeNotifications() || [];
    const storageNotifications = await this.loadStorageNotifications() || [];

    const currentStorageNotifications = storageNotifications
      .filter((storageNotification: string) => (
        systemNotifications.some((element) => element.id === storageNotification) || storageNotification === newStylingNotificationId
      ));

    const newStorageNotifications = notifications
      .filter(notification => !storageNotifications.includes(notification.id))
      .map(notification => notification.id);

    this.saveStorageNotifications([...currentStorageNotifications, ...newStorageNotifications]);
  }

  // TODO PLM-444 remove this workaround
  private async waitForPlatform() {
    return delay(10 * DateUtils.msInSec);
  }

  public shareLogs(title: string): Promise<string> {
    Log.info(TAG, 'shareLogs syncing log files...');
    return syncLogFiles()
      .then(() => getLogPaths())
      .then((filesPaths) => {
        if (isTVOS) {
          return DeviceInfo
            .getIpAddress()
            .then(ip => {
              NativeModules.LogsShareManager.start(filesPaths, port, path);
              return `${ip}:${port}${path}`;
            });
        }

        const shareOptions = {
          title: title,
          urls: filesPaths.map(path => 'file://' + path),
          failOnCancel: false
        };

        if (!Share) {
          return '';
        }

        return Share.open(shareOptions)
          .then(() => '');
      });
  }
}
