import React, {forwardRef} from 'react';
import {NativeSyntheticEvent, TextInputChangeEventData} from 'react-native';

import FocusableTextInput, {FocusableTextInputImplProps} from 'components/inputs/FocusableTextInputImpl';
import 'components/inputs/FocusableTextInput.web.css';
import {findDomElement} from 'components/focusManager/geometry';

import {isBigScreen} from 'common/constants';

const shouldFocusInteractiveOnInputBlur = (blurEvent: NativeSyntheticEvent<TextInputChangeEventData>, component?: React.Component | null) => {
  // MouseEvent.relatedTarget/FocusEvent.relatedTarget for blur events is the element receiving focus (if any)
  // @ts-ignore react native typings don't declare relatedTarget on NativeSyntheticEvent<TextInputChangeEventData
  return isBigScreen && (!blurEvent.relatedTarget || !!component && blurEvent.relatedTarget === findDomElement(component));
};

const FocusableTextInputComponent: React.FunctionComponent<FocusableTextInputImplProps> = (props, ref) => (
  <FocusableTextInput ref={ref} shouldFocusInteractiveOnInputBlur={shouldFocusInteractiveOnInputBlur} {...props} />
);

export default forwardRef(FocusableTextInputComponent);
