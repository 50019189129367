import React from 'react';
import {LayoutRectangle} from 'react-native';

import {initialTabBarLayout} from 'common/constants';

import {CustomMenuItemRoute} from 'mw/cms/CMS';

export enum SideMenuState {
  Collapsed,
  Hidden,
  Expanded,
  SemiCollapsed,
}

export enum STBMenuState {
  Collapsed, //TODO: CL-7405 Removing this unused type breaks down changing menu states 🤯
  Hidden,
  Above,
  Covered,
  CoveredAutoHide,
  CoveredAutoHideWithTip,
  Transparent
}

export type STBMenuContextType = {
  hasVisibleModal: boolean;
  hasFocus: boolean;
  focusMenu: () => void;
  setMenuState: (state: STBMenuState) => void;
};

export const STBMenuContext = React.createContext<STBMenuContextType | null>(null);

export type TabBarContextType = {
  layout: LayoutRectangle;
  onChange?: (layout: LayoutRectangle) => void;
}
export const TabBarContext = React.createContext<TabBarContextType>({layout: initialTabBarLayout});

export type CustomMenuItemHandlers = {
  [CustomMenuItemRoute.VoucherRedemption]: () => void;
}
