import {useCallback, ComponentType, useState, useMemo} from 'react';
import {NavigationComponent, NavigationRoute} from 'react-navigation';

import {AppRoutes, isPhone, isBigScreen} from 'common/constants';

import {BackOfficeEvent} from 'mw/api/BackOffice';
import {PVREvent} from 'mw/api/Metadata';
import {PinState} from 'mw/api/Profile';
import {Menu} from 'mw/cms/Menu';
import {mw, MWEvent} from 'mw/MW';

import {useToggle, useEventListener} from 'hooks/Hooks';
import AppsGamesScreen from 'screens/AppsGamesScreen';
import ChannelDetailScreen from 'screens/ChannelDetailScreen';
import EpgScreen from 'screens/epg/EpgScreen';
import HomeScreen from 'screens/HomeScreen';
import CredentialsScreen from 'screens/login/CredentialsScreen';
import RegistrationScreen from 'screens/login/RegistrationScreen';
import MediaDetailScreen from 'screens/MediaDetailScreen';
import MediaGridScreen from 'screens/MediaGridScreen';
import MediaPlayerScreen from 'screens/MediaPlayerScreen';
import RecordingsFolder from 'screens/recordings/folder/RecordingsFolder';
import RecordingsScreen from 'screens/recordings/RecordingsScreen';
import SearchFullResultsScreen from 'screens/search/SearchFullResultsScreen';
import SearchScreen from 'screens/search/SearchScreen';
import CreateProfileWizard from 'screens/settings/CreateProfileWizard';
import OrderDetails from 'screens/settings/OrderDetails';
import SettingsLanguageScreen from 'screens/settings/profileEdit/SettingsLanguageScreen';
import SettingsNameScreen from 'screens/settings/profileEdit/SettingsNameScreen';
import SettingsParentalControlScreen from 'screens/settings/profileEdit/SettingsParentalControlScreen';
import SettingsPinScreen from 'screens/settings/profileEdit/SettingsPinScreen';
import SettingsTimeAndDateScreen from 'screens/settings/profileEdit/SettingsTimeAndDateScreen';
import SettingsAccountScreen from 'screens/settings/SettingsAccountScreen';
import SettingsAppVersionScreen from 'screens/settings/SettingsAppVersionScreen';
import SettingsBackOfficeScreen from 'screens/settings/SettingsBackOfficeScreen';
import SettingsCustomerScreen from 'screens/settings/SettingsCustomerScreen';
import SettingsDeleteProfileScreen from 'screens/settings/SettingsDeleteProfileScreen';
import SettingsHelpScreen from 'screens/settings/SettingsHelpScreen';
import SettingsOrders from 'screens/settings/settingsOrder/SettingsOrdersScreen';
import SettingsRegisteredDevicesScreen from 'screens/settings/SettingsRegisteredDevicesScreen';
import SettingsScreen from 'screens/settings/SettingsScreen';
import TvScreen, {ViewMode} from 'screens/tv/TvScreen';
import TvScreenGrosso from 'screens/tv/TvScreen.grosso';
import {VodCategoryScreen, VodLandingScreen} from 'screens/VodScreen';
import WatchListScreen from 'screens/watch-list/WatchListScreen';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ScreensMapType = {readonly [key in AppRoutes]: (() => ComponentType<any>)};

// TODO: CL-3087 Cyclic imports issue
const screensMap: ScreensMapType = {
  [AppRoutes.Home]: () => HomeScreen,
  [AppRoutes.Epg]: () => isPhone ? TvScreen : EpgScreen,
  [AppRoutes.Zapper]: () => isBigScreen ? TvScreenGrosso : TvScreen,
  [AppRoutes.Pvr]: () => RecordingsScreen,
  [AppRoutes.Vod]: () => VodLandingScreen,
  [AppRoutes.VodCategory]: () => VodCategoryScreen,
  [AppRoutes.Settings]: () => SettingsScreen,
  [AppRoutes.SettingsCustomer]: () => SettingsCustomerScreen,
  [AppRoutes.SettingsAppVersion]: () => SettingsAppVersionScreen,
  [AppRoutes.SettingsBackOffice]: () => SettingsBackOfficeScreen,
  [AppRoutes.SettingsDeleteProfile]: () => SettingsDeleteProfileScreen,
  [AppRoutes.SettingsParentalControl]: () => SettingsParentalControlScreen,
  [AppRoutes.SettingsLanguage]: () => SettingsLanguageScreen,
  [AppRoutes.SettingsName]: () => SettingsNameScreen,
  [AppRoutes.SettingsPin]: () => SettingsPinScreen,
  [AppRoutes.SettingsTimeAndDate]: () => SettingsTimeAndDateScreen,
  [AppRoutes.CreateProfileWizard]: () => CreateProfileWizard,
  [AppRoutes.Search]: () => SearchScreen,
  [AppRoutes.SearchResultFull]: () => SearchFullResultsScreen,
  [AppRoutes.WatchList]: () => WatchListScreen,
  [AppRoutes.MediaPlayer]: () => MediaPlayerScreen,
  [AppRoutes.MediaDetail]: () => MediaDetailScreen,
  [AppRoutes.SettingsRegisteredDevices]: () => SettingsRegisteredDevicesScreen,
  [AppRoutes.LauncherAppsGames]: () => AppsGamesScreen,
  [AppRoutes.Credentials]: () => CredentialsScreen,
  [AppRoutes.Registration]: () => RegistrationScreen,
  [AppRoutes.ChannelDetail]: () => ChannelDetailScreen,
  [AppRoutes.RecordingsFolder]: () => RecordingsFolder,
  [AppRoutes.SettingsAccount]: () => SettingsAccountScreen,
  [AppRoutes.SettingsOrders]: () => SettingsOrders,
  [AppRoutes.SettingsHelp]: () => SettingsHelpScreen,
  [AppRoutes.OrderDetails]: () => OrderDetails,
  [AppRoutes.MediaGrid]: () => MediaGridScreen
};

export function getScreenForRoute(route: string): NavigationComponent {
  return screensMap[route as AppRoutes]?.() ?? HomeScreen;
}

// routes must be unique
// for main menu tabs, use both screen and slug, to enable configuring e.g. two screens with tag "vod" in cms
export const routeNameForMenu = (menu: Menu) => `${menu.screen}_${menu.slug}`;

export const extractRouteName = (navigationRouteName: string): string => navigationRouteName.split('_')[0];

export function getCurrentMenu(menu: Menu, key: string): Menu {
  return menu.items.filter(submenu => routeNameForMenu(submenu) === key)[0];
}

export function getRouteInitialParams(routeName: string) {
  switch (routeName) {
    case AppRoutes.Zapper:
      return {channelId: undefined, eventId: undefined, viewMode: ViewMode.ChannelsGrid};
    case AppRoutes.Epg:
      if (isPhone) {
        return {viewMode: ViewMode.Epg, phoneEpgMode: true};
      }
      return null;
    case AppRoutes.Home:
      return {reloadScreen: true};
    case AppRoutes.LauncherAppsGames:
      return {reloadScreen: true};
    default:
      return null;
  }
}

export function isPinProtected(route: string): boolean {
  switch (route) {
    case AppRoutes.SettingsParentalControl:
      return true;
    case AppRoutes.SettingsDeleteProfile:
    case AppRoutes.SettingsPin:
      return mw.customer.currentProfile?.pinState !== PinState.ProfilePinNotSet;
  }
  return false;
}

export function useLoggedIn() {
  const [state, {on, off}] = useToggle(mw.bo.isLoggedIn);

  useEventListener(BackOfficeEvent.loggedIn, on, mw.bo);
  useEventListener(BackOfficeEvent.loggedOut, off, mw.bo);

  return state;
}

// This does not use useLoggedIn hook, because of optimalization for launcher to not rerender STBNavigationView too much.
export function useShouldRenderMenu(): boolean {
  const [state, {on, off}] = useToggle(mw.isInitialized || mw.configuration.isLauncher);

  const onShould = useCallback(() => {
    if (!mw.configuration.isLauncher) {
      on();
    }
  }, [on]);

  const onShouldNot = useCallback(() => {
    if (!mw.configuration.isLauncher) {
      off();
    }
  }, [off]);

  useEventListener(MWEvent.initialized, onShould, mw);
  useEventListener(BackOfficeEvent.loggedOut, onShouldNot, mw.bo);
  useEventListener(MWEvent.initializationFailed, onShouldNot, mw);

  return state;
}

export function useFilteredRoutes(routes: NavigationRoute[]): NavigationRoute[] {
  const [excludedRoutes, setExcludedRoutes] = useState<string[]>([]);
  const filteredRoutes = useMemo<NavigationRoute[]>(() => (
    excludedRoutes.length > 0
      ? routes.filter(route => !excludedRoutes.includes(route.routeName))
      : routes
  ), [routes, excludedRoutes]);

  const onPVRQuotaChanged = useCallback(() => {
    setExcludedRoutes(
      !mw.configuration.isNPVREnabled
        ? routes
          .filter(route => extractRouteName(route.routeName) === AppRoutes.Pvr)
          .map(route => route.routeName)
        : []
    );
  }, [routes]);
  useEventListener(PVREvent.PVRQuotaChanged, onPVRQuotaChanged, mw.pvr);

  return filteredRoutes;
}
