import React, {useMemo} from 'react';
import {View} from 'react-native';

import {dimensions} from 'common/constants';

import {createStyles} from 'common-styles/index';

import NitroxButtonsMenu, {NitroxButtonsMenuAction} from './NitroxButtonsMenu';
import NitroxText from './NitroxText';
import {OptionPickerProps, optionPickerStylesUpdater} from './OptionPicker.shared';

const styles = createStyles({
  container: {
    marginTop: dimensions.margins.xxxLarge,
    marginBottom: dimensions.margins.xxxLarge
  },
  buttonsMenu: {
    paddingTop: 0
  }
});

export const OptionPickerGrosso: React.FunctionComponent<OptionPickerProps> = props => {
  const {label, options, defaultOptionKey, onOptionSelected, style} = props;

  const actions = useMemo((): NitroxButtonsMenuAction[] => options.map(option => ({
    text: option.label,
    icon: option.iconProps,
    onPress: () => onOptionSelected(option.key),
    isSelected: option.key === defaultOptionKey
  })), [defaultOptionKey, onOptionSelected, options]);

  const dynamicPickerStyles = optionPickerStylesUpdater.getStyles();
  return (
    <View style={[dynamicPickerStyles.container, styles.container, style]}>
      <NitroxText textType='options-texts' style={dynamicPickerStyles.label}>{label}</NitroxText>
      <NitroxButtonsMenu
        scrolled={false}
        borderButtons
        containerStyle={styles.buttonsMenu}
        actions={actions}
      />
    </View>
  );
};
