import {createStyles} from 'common-styles';
import i18next from 'i18next';
import React, {useState, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleSheet, View} from 'react-native';
import {NavigationInjectedProps} from 'react-navigation';

import {isWeb, isTablet, RADIUS, dimensions} from 'common/constants';
import {isSeriesEpisode} from 'common/HelperFunctions';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Title, PictureType, PictureMode} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import {AspectRatio, Ratio} from 'components/AspectRatio';
import MediaPicture from 'components/MediaPicture';
import {FAR_FAR_AWAY} from 'components/navigation/ResourceSavingScene';
import Separator from 'components/Separator';
import ShortDetails, {getTitleMetadata, getEpisodeMetadata} from 'components/ShortDetailsView';
import {WithUnlockModal, withUnlockModal} from 'components/unlockmodal/UnlockModal';
import BingeButtons from 'components/zapper/BingeButtons';
import {DetailsButtonsHandlers} from 'components/zapper/MediaDetailsTemplate';
import {useLazyEffect, useScreenInfo} from 'hooks/Hooks';

import {CloseButton} from './VodSeriesMobileOverlay';

const TAG = 'VodBingeWatchingMobileOverlay';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: colors.playerScreen.overlay
  },
  content: {
    marginLeft: 45,
    marginRight: 45,
    marginTop: 25
  },
  topRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom: 20
  },
  detailsColumn: {
    flex: 1,
    flexDirection: 'column',
    marginLeft: 18,
    marginRight: isTablet ? 255 : 56
  },
  hidden: {
    top: FAR_FAR_AWAY,
    position: 'absolute',
    width: '100%',
    height: '100%',
    ...isWeb ? {visibility: 'hidden'} : {}
  },
  closeButtonIconColor: colors.playerScreen.closeIcon,
  imageContainerStyle: {
    width: isTablet ? '31%' : '29%',
    borderRadius: RADIUS
  },
  imagePlaceholder: {
    backgroundColor: colors.tile.poster.placeholder.background,
    width: '100%'
  },
  separator: {
    marginTop: 20,
    marginBottom: 20,
    marginRight: 41,
    backgroundColor: colors.epgScreen.grid.tile.active
  }
}));

type Props = {
  title: Title;
  onClose: () => void;
  handlers: DetailsButtonsHandlers;
} & NavigationInjectedProps & WithUnlockModal;

const VodBingeWatchingMobileOverlay: React.FC<Props> = (props) => {
  const {title, handlers, onClose} = props;
  const {t} = useTranslation();
  const {orientation} = useScreenInfo();
  const [nextTitle, setNextTitle] = useState< Title>();

  const onBingeWatch = useCallback(() => {
    if (!nextTitle) return;
    handlers?.onWatch(nextTitle, true);
  }, [handlers, nextTitle]);

  const onBuyPress = useCallback(() => {
    if (!nextTitle) return;
    handlers?.onBuyPress?.(nextTitle);
  }, [handlers, nextTitle]);

  const onRentPress = useCallback(() => {
    if (!nextTitle) return;
    handlers?.onRentPress?.(nextTitle);
  }, [handlers, nextTitle]);

  useLazyEffect(() => {
    const fetchSeasons = async (seriesId: string) => {
      const titles: Title[] = await mw.catalog.getSeriesEpisodesById(seriesId);
      const nextTitle = titles[titles.findIndex((item: Title) => item.id === title.id) + 1];
      setNextTitle(nextTitle);
    };

    title.episode && isSeriesEpisode(title) && fetchSeasons(title.episode.seriesId);
  }, [title], [title]);

  const nextTitlePoster = useMemo(() => {
    if (!nextTitle) {
      return null;
    }
    const posterSize = isTablet && orientation.isLandscape ? dimensions.pictures.standardTile.tabletLandscapeBinge_16_9 : dimensions.pictures.standardTile.default_16_9;
    const uri = mw.catalog.getPictureUrl(nextTitle, PictureType.Tile, posterSize.width, posterSize.height, PictureMode.BOX);
    return uri ? {uri} : null;
  }, [nextTitle, orientation]);

  const styles = stylesUpdater.getStyles();

  const getMediaMetadata = (t: i18next.TFunction, media?: Title) => {
    if (!media) return {title: '', subtitle: ''};

    if (media?.episode) {
      return getEpisodeMetadata(media as Title);
    } else {
      return getTitleMetadata(media as Title);
    }
  };

  if (!nextTitle) {
    return null;
  }

  return (
    <>
      <View style={[styles.container]}>
        <View style={styles.content}>
          <View style={styles.topRow}>
            <CloseButton onPress={onClose} />
            <View style={styles.detailsColumn}>
              <ShortDetails
                landscape
                completeDetails
                {...getMediaMetadata(t, nextTitle)}
              />
              <Separator horizontal style={styles.separator} />
              <BingeButtons
                onBingeWatch={onBingeWatch}
                onBingeBack={onClose}
                onBuyPress={onBuyPress}
                onRentPress={onRentPress}
                title={nextTitle}
              />
            </View>
            <View style={styles.imageContainerStyle}>
              <AspectRatio ratio={Ratio.RATIO_16_9}>
                <MediaPicture source={nextTitlePoster} mediaType={nextTitle.getType()} />
              </AspectRatio>
            </View>
          </View>
        </View>
      </View>
      {props.renderUnlockModal()}
    </>
  );
};

export default withUnlockModal(VodBingeWatchingMobileOverlay);
