// eslint-disable-next-line no-restricted-imports
import {PanGestureHandlerGestureEvent, State} from 'react-native-gesture-handler';
import Animated, {
  add,
  and,
  block,
  Clock,
  clockRunning,
  cond,
  decay,
  divide,
  eq,
  event,
  floor,
  greaterThan,
  max,
  min,
  modulo,
  multiply,
  neq,
  not,
  round,
  or,
  set,
  spring,
  startClock,
  stopClock
} from 'react-native-reanimated';

import {isAndroid, Axis} from 'common/constants';

import {useLazyRef, useFunction} from 'hooks/Hooks';

function clamp(value: Animated.Node<number>, minValue?: Animated.Adaptable<number>, maxValue?: Animated.Adaptable<number>) {
  return min(
    max(value, minValue ?? value),
    maxValue ?? value
  );
}

// Fast momentum scroll is too much to handle for Android tablets
const defaultDeceleration = isAndroid ? 0.992 : 0.996;

type MomentumParams = {
  velocity: Animated.Adaptable<number>;
  position: Animated.Value<number>;
  offset: Animated.Value<number>;

  state: Animated.Adaptable<number>;
  /**
   * Set to 1 to stop animation.
   * Value will be internally reset to 0.
   */
  interruptSignal: Animated.Value<0 | 1>;
  minValue?: Animated.Value<number>;
  maxValue?: Animated.Value<number>;
  /**
   * Velocity deceleration rate. The closer value is to '1', the faster and longer momentum animation will be.
   * Current velocity is multplied each (logical) animation frame by given value, and this way decelerated.
   * Animation ends when velocity falls below threshold.
   * Native scroll view uses 0.998.
   * Defaults to 0.996 on iOS and 0.992 on Android. Value should be less than native default, scrolling in both axes is heavier performance-wise.
   */
  deceleration?: number;
}

const addMomentum = ({
  velocity,
  position,
  offset,
  state,
  minValue,
  maxValue,
  deceleration = defaultDeceleration,
  interruptSignal = new Animated.Value(0)
}: MomentumParams) => {
  const clock = new Clock();
  const decayState = {
    finished: new Animated.Value(0),
    velocity: new Animated.Value(0),
    position: new Animated.Value(0),
    time: new Animated.Value(0)
  };

  /**
   * Animation is either finished or interrupted.
   */
  const done = new Animated.Value(0);
  const interrupted = and(eq(state, State.BEGAN), clockRunning(clock));

  const stop = [
    set(done, 1),
    set(offset, position),
    stopClock(clock)
  ];

  return block([
    cond(interrupted, stop),
    cond(neq(state, State.END), [
      set(decayState.finished, 0),
      set(done, 0),
      set(decayState.position, position)
    ]),
    cond(eq(state, State.END), [
      cond(and(not(clockRunning(clock)), not(done)), [
        set(decayState.velocity, velocity),
        set(decayState.time, 0),
        set(decayState.position, position),
        startClock(clock)
      ]),
      decay(clock, decayState, {deceleration}),
      // whole animation ends
      cond(decayState.finished, stop),
      // animation is interrupted (e.g. epg "go to now")
      cond(interruptSignal, [
        stop,
        set(interruptSignal, 0)
      ]),
      cond(clockRunning(clock), [
        set(position, clamp(decayState.position, minValue, maxValue))
      ])
    ])
  ]);
};

type SnapToParams = {
  clock: Clock;
  velocity: Animated.Adaptable<number>;
  position: Animated.Value<number>;
  snapToInterval: Animated.Value<number>;
  minValue?: Animated.Value<number>;
  maxValue?: Animated.Value<number>;
};

const hardSpringConfig = {
  damping: 1000,
  mass: 1,
  stiffness: 1000,
  overshootClamping: true,
  restSpeedThreshold: 0.001,
  restDisplacementThreshold: 0.001
};

const snapTo = ({
  clock,
  velocity,
  position,
  minValue,
  maxValue,
  snapToInterval
}: SnapToParams) => {
  const springState = {
    finished: new Animated.Value(0),
    velocity: new Animated.Value(0),
    position: new Animated.Value(0),
    time: new Animated.Value(0)
  };

  const springConfig = {
    ...hardSpringConfig,
    toValue: new Animated.Value(0)
  };

  const toValue = floor(multiply(snapToInterval, round(divide(position, snapToInterval))));

  return [
    cond(not(clockRunning(clock)), [
      set(springState.finished, 0),
      set(springState.velocity, velocity),
      set(springState.position, position),
      set(springConfig.toValue, toValue),
      startClock(clock)
    ]),
    spring(clock, springState, springConfig),
    cond(springState.finished, [
      stopClock(clock)
    ]),
    cond(clockRunning(clock), [
      set(position, clamp(springState.position, minValue, maxValue))
    ]),
    springState.position
  ];
};

type SnapMomentumParams = MomentumParams & {
  snapToInterval?: Animated.Value<number>;
}

/**
 * Adds momentum to snap to a given interval. This is not the final solution but a good starting point :)
 */
const addSnapMomentum = ({
  velocity,
  position,
  offset,
  state,
  minValue,
  maxValue,
  snapToInterval = new Animated.Value(0),
  deceleration = defaultDeceleration,
  interruptSignal = new Animated.Value(0)
}: SnapMomentumParams) => {
  const decayClock = new Clock();
  const decayState = {
    finished: new Animated.Value(0),
    velocity: new Animated.Value(0),
    position: new Animated.Value(0),
    time: new Animated.Value(0)
  };

  const springClock = new Clock();
  const startSnapping = and(greaterThan(snapToInterval, 0), neq(modulo(position, snapToInterval), 0));

  const done = new Animated.Value(0);
  const interrupted = and(eq(state, State.BEGAN), or(clockRunning(decayClock), clockRunning(springClock)));

  const stopDecay = [
    set(done, 1),
    set(offset, position),
    stopClock(decayClock)
  ];

  const stop = [
    ...stopDecay,
    stopClock(springClock)
  ];

  return block([
    cond(interrupted, stop),
    cond(neq(state, State.END), [
      set(decayState.finished, 0),
      set(done, 0),
      set(decayState.position, position)
    ]),
    cond(eq(state, State.END), [
      cond(and(not(clockRunning(decayClock)), not(done)), [
        set(decayState.velocity, velocity),
        set(decayState.time, 0),
        set(decayState.position, position),
        startClock(decayClock)
      ]),
      decay(decayClock, decayState, {deceleration}),
      cond(decayState.finished, stopDecay),
      cond(interruptSignal, [
        stop,
        set(interruptSignal, 0)
      ]),
      cond(clockRunning(decayClock), [
        set(position, clamp(decayState.position, minValue, maxValue))
      ], cond(startSnapping, [
        snapTo({
          clock: springClock,
          velocity,
          position,
          minValue,
          maxValue,
          snapToInterval
        })
      ]))
    ])
  ]);
};

type UseOmniScroll = {
  positionX: Animated.Value<number>;
  positionY: Animated.Value<number>;
  positionOffsetX: Animated.Value<number>;
  positionOffsetY: Animated.Value<number>;

  // Boundaries cannot be Animated.Adaptables / raw numbers.
  // Once Animated.event is declared, it never changes.
  // Animated.event is to be considered as 'declaration' or 'config' of behavior.
  // Nothing is handled imperatively there. Any behavior change must be done via .setValue of passed Animated.Values.

  minX?: Animated.Value<number>;
  maxX?: Animated.Value<number>;

  minY?: Animated.Value<number>;
  maxY?: Animated.Value<number>;
}

/**
 * This typing is not 100% true, not all keys are Adaptable Nodes.
 * However, it's enough for pan gesture handling.
 */
type PanEvent = {
  nativeEvent: {
    [key in keyof PanGestureHandlerGestureEvent['nativeEvent']]: Animated.Adaptable<number>;
  };
};

export function useOmniScroll({
  positionX,
  positionY,
  positionOffsetX: offsetX,
  positionOffsetY: offsetY,
  minX,
  maxX,
  minY,
  maxY
}: UseOmniScroll) {
  const momentumXInterruptSignal = useLazyRef(() => new Animated.Value<0 | 1>(0)).current;
  const momentumYInterruptSignal = useLazyRef(() => new Animated.Value<0 | 1>(0)).current;
  const onGestureEvent = useLazyRef(() => event<PanEvent>([
    {
      nativeEvent: ({translationX: x, translationY: y, state, velocityX, velocityY}) => {
        // translationX/Y do not reset after animation is done.
        // It seems to be a bug in react-native-reanimated/react-native-gesture-handler.
        // Reproduction:
        // - Make long gesture from A to B
        // - Imperatively reset position via .setValue() to A
        // - Start new gesture
        // Effect:
        // - Position jumps immediately to B
        // TODO: CL-3122 Investigate, provide minimal reproduction repro and create GH ticket.
        const animatedTranslationX = new Animated.Value(0);
        const animatedTranslationY = new Animated.Value(0);

        const clampedX = clamp(
          add(animatedTranslationX, offsetX),
          minX,
          maxX
        );
        const clampedY = clamp(
          add(animatedTranslationY, offsetY),
          minY,
          maxY
        );

        return block([
          cond(eq(state, State.ACTIVE), [
            set(positionX, clampedX),
            set(positionY, clampedY),
            set(animatedTranslationX, x),
            set(animatedTranslationY, y)
          ]),
          cond(eq(state, State.END), [
            set(offsetX, positionX),
            set(offsetY, positionY),
            set(animatedTranslationX, 0),
            set(animatedTranslationY, 0)
          ]),
          addMomentum({
            state,
            velocity: velocityX,
            offset: offsetX,
            position: positionX,
            minValue: minX,
            maxValue: maxX,
            interruptSignal: momentumXInterruptSignal
          }),
          addMomentum({
            state,
            velocity: velocityY,
            offset: offsetY,
            position: positionY,
            minValue: minY,
            maxValue: maxY,
            interruptSignal: momentumYInterruptSignal
          })
        ]);
      }
    }
  ])).current;

  const stopMomentumScroll = useFunction(() => {
    momentumXInterruptSignal.setValue(1);
    momentumYInterruptSignal.setValue(1);
  });

  return {onGestureEvent, stopMomentumScroll};
}

type UseScroll = {
  axis: Axis;
  position: Animated.Value<number>;
  offset: Animated.Value<number>;
  minValue?: Animated.Value<number>;
  maxValue?: Animated.Value<number>;
  snapToInterval?: Animated.Value<number>;
}

export function useScroll({
  axis,
  position,
  offset,
  minValue,
  maxValue,
  snapToInterval
}: UseScroll) {
  const interruptSignal = useLazyRef(() => new Animated.Value<0 | 1>(0)).current;

  const onGestureEvent = useLazyRef(() => event<PanEvent>([
    {
      nativeEvent: ({translationX, translationY, state, velocityX, velocityY}) => {
        const [translation, velocity] = axis === Axis.X
          ? [translationX, velocityX]
          : [translationY, velocityY];
        const animatedTranslation = new Animated.Value(0);
        const clampedTranslation = clamp(
          add(animatedTranslation, offset),
          minValue,
          maxValue
        );
        return block([
          cond(eq(state, State.ACTIVE), [
            set(position, clampedTranslation),
            set(animatedTranslation, translation)
          ]),
          cond(eq(state, State.END), [
            set(offset, position),
            set(animatedTranslation, 0)
          ]),
          addSnapMomentum({
            state,
            velocity,
            offset,
            position,
            minValue,
            maxValue,
            snapToInterval,
            interruptSignal
          }),
          position
        ]);
      }
    }
  ])).current;

  const stopMomentumScroll = useFunction(() => {
    interruptSignal.setValue(1);
  });

  return {onGestureEvent, stopMomentumScroll};
}
