import {createStyles} from 'common-styles';
import React, {useState, useCallback} from 'react';
import {View, Image, ImageStyle, ViewStyle} from 'react-native';

import {Direction} from 'common/constants';

import {constColors} from 'common-styles/variables/base-colors';

import {assets} from 'brand/Resources';
import NitroxInteractive from 'components/NitroxInteractive';

const dPadWidth = 100;
const dPadHeight = 50;

const closePadWidth = 35;
const closePadHeight = closePadWidth;

const openPadWidth = 60;
const openPadHeight = openPadWidth;

const nowPadWidth = 56;
const nowPadHeight = nowPadWidth;

const containerWidth = 155;
const dPadContainerWidth = 140;

const styles = createStyles({
  container: {
    width: containerWidth,
    height: containerWidth
  },
  dPadContainer: {
    position: 'absolute',
    width: dPadContainerWidth,
    height: dPadContainerWidth,
    top: containerWidth - dPadContainerWidth
  },
  dPad: {
    width: dPadWidth,
    height: dPadHeight,
    position: 'absolute'
  },
  dPadUp: {
    top: 0,
    left: Math.round((dPadContainerWidth - dPadWidth) / 2)
  },
  dPadRight: {
    top: Math.round((dPadContainerWidth - dPadWidth + dPadHeight) / 2),
    right: -dPadHeight / 2
  },
  dPadDown: {
    bottom: -dPadContainerWidth,
    left: Math.round((dPadContainerWidth - dPadWidth) / 2)
  },
  dPadLeft: {
    top: Math.round((dPadContainerWidth - dPadWidth + dPadHeight) / 2),
    left: -dPadHeight / 2
  },
  nowPad: {
    position: 'absolute',
    width: nowPadWidth,
    height: nowPadHeight,
    top: 42, //These two values come from curvature of dPad, I don't want to really calculate this.
    left: 42 //it would be something like dPadHeight - 8
  },
  closePad: {
    position: 'absolute',
    width: closePadWidth,
    height: closePadHeight,
    top: 0,
    right: 0
  },
  openPad: {
    position: 'absolute',
    width: openPadWidth,
    height: openPadHeight,
    top: Math.round((dPadContainerWidth - openPadHeight)) / 2 + (containerWidth - dPadContainerWidth),
    left: Math.round((dPadContainerWidth - openPadWidth) / 2)
  }
});

const directionToRotation = (direction: Direction): string => {
  switch (direction) {
    case Direction.Up: return '0deg';
    case Direction.Right: return '90deg';
    case Direction.Down: return '180deg';
    case Direction.Left: return '270deg';
    default: return '0deg';
  }
};

type ButtonProps = {
  onPress?: () => void;
  style?: ImageStyle;
};

type ImageButttonProps = {
  source: any;
  testID: string;
} & ButtonProps;

type DPadProps = {
  direction: Direction;
} & ButtonProps;

const DPad = React.memo((props: DPadProps) => {
  const [source, setSource] = useState(assets.web.dpad.pad);

  const onMouseEnterHandler = useCallback(() => setSource(assets.web.dpad.hover), []);
  const onMouseLeaveHandler = useCallback(() => setSource(assets.web.dpad.pad), []);

  return (
    <NitroxInteractive
      onPress={props.onPress}
      activeOpacity={1}
      underlayColor={constColors.transparent}
      testID={`button_arrow_${props.direction}`}
    >
      <Image
        source={source}
        {...{
          onMouseEnter: onMouseEnterHandler,
          onMouseLeave: onMouseLeaveHandler
        }}
        style={[
          styles.dPad as ImageStyle,
          {transform: [{rotate: directionToRotation(props.direction)}]},
          props.style
        ]}
      />
    </NitroxInteractive>
  );
});
DPad.displayName = DPad.name;

const ImageButton = React.memo((props: ImageButttonProps) => {
  return (
    <NitroxInteractive
      onPress={props.onPress}
      activeOpacity={1}
      underlayColor={constColors.transparent}
      testID={props.testID}
    >
      <Image source={props.source} style={props.style} />
    </NitroxInteractive>
  );
});
ImageButton.displayName = 'ImageButton';

export type EpgNavigatorProps = {
  onLeftPress: () => void;
  onRightPress: () => void;
  onDownPress: () => void;
  onUpPress: () => void;
  onNowPress: () => void;
  style?: ViewStyle;
}

const EpgNavigator = (props: EpgNavigatorProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <View style={[styles.container, props.style]} testID='navigator'>
      <View>
        {expanded ? (
          <>
            <View style={styles.dPadContainer}>
              <DPad direction={Direction.Up} style={styles.dPadUp} onPress={props.onUpPress} />
              <DPad direction={Direction.Right} style={styles.dPadRight} onPress={props.onRightPress} />
              <DPad direction={Direction.Down} style={styles.dPadDown} onPress={props.onDownPress} />
              <DPad direction={Direction.Left} style={styles.dPadLeft} onPress={props.onLeftPress} />
              <ImageButton source={assets.web.dpad.now} testID='button_now' style={styles.nowPad} onPress={props.onNowPress} />
            </View>
            <ImageButton source={assets.web.dpad.close} testID='button_close' style={styles.closePad} onPress={() => {setExpanded(false);}} />
          </>
        ) : (
          <ImageButton source={assets.web.dpad.collapsed} testID='button_open' style={styles.openPad} onPress={() => {setExpanded(true);}} />
        )}
      </View>
    </View>
  );
};

export default EpgNavigator;
