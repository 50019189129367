import {createStyles} from 'common-styles';
import moment from 'moment';
import React, {forwardRef, useCallback, useContext, useMemo} from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleSheet, View, ViewStyle, StyleProp} from 'react-native';
import {NavigationContext} from 'react-navigation';

import {dimensions, getValue} from 'common/constants';
import {openMediaDetails, getMediaSubtypes, humanCaseToSnakeCase} from 'common/HelperFunctions';
import {FocusOptions} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Event, PictureType, PictureMode} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import {AspectRatio, Ratio} from 'components/AspectRatio';
import {ChannelIcon, ChannelIconType, channelIconConstants} from 'components/ChannelIcon';
import Checkbox from 'components/Checkbox';
import EventSelector from 'components/EventSelector';
import {Icon, IconType} from 'components/Icon';
import MediaPicture from 'components/MediaPicture';
import {MediaTileBaseProps, MediaTileType, getMediaTitleForType, channelIconOffset, useProgress, useMediaTileHelpers, useMediaInfoSection} from 'components/mediaTiles/MediaTileHooks';
import MiniProgressBar from 'components/MiniProgressBar';
import NitroxTag, {TagPosition, TagTypes, Tags} from 'components/NitroxTag';
import NitroxText from 'components/NitroxText';
import Separator from 'components/Separator';
import TileContainer from 'components/TileContainer';
import TileIconsRow, {prepareMediaIcons, MediaIcons} from 'components/TileIconsRow';
import {useChangeEffect, useEffectOnce, useTestID} from 'hooks/Hooks';

const {borderRadius, borderWidth} = dimensions.tile;

const posterRatio = Ratio.RATIO_16_9;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  textFocused: {
    color: colors.tile.contentText.focused
  },
  textUnfocused: {
    color: colors.tile.contentText.unfocused
  },
  imageContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: colors.tile.poster.placeholder.background,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    overflow: 'hidden'
  },
  separatorFocused: {
    backgroundColor: colors.tile.separator.focused
  },
  separatorUnfocused: {
    backgroundColor: colors.tile.separator.unfocused
  },
  progressStyle: {
    backgroundColor: colors.progressBar.default.progress.current
  },
  descriptionViewFocused: {
    backgroundColor: colors.tile.background.selected
  },
  contentFocused: {
    borderColor: colors.tile.background.selected
  },
  contentUnfocused: {
    borderColor: colors.tile.background.default
  },
  icon: {
    color: colors.tile.channelIcon.background
  },
  profileTagBackground: {
    backgroundColor: colors.defaultColors.screenBackground
  }
}));

const styles = createStyles({
  contentView: {
    borderRadius: borderRadius
  },
  descriptionView: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    paddingVertical: dimensions.margins.xsmall
  },
  descriptionViewDefault: {
    alignItems: 'center'
  },
  descriptionViewEpisode: {
    alignItems: 'flex-start'
  },
  separator: {
    opacity: dimensions.opacity.xlow,
    marginVertical: dimensions.margins.xsmall,
    marginHorizontal: dimensions.margins.small
  },
  dateView: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  channelIcon: {
    position: 'absolute',
    left: -channelIconOffset,
    top: -channelIconOffset
  },
  mediaIconsContainer: {
    width: dimensions.icon.small,
    marginLeft: dimensions.margins.medium,
    marginRight: dimensions.margins.small,
    justifyContent: 'center'
  },
  progressContainer: {
    position: 'absolute',
    height: dimensions.tile.progress.height,
    width: '100%',
    flexDirection: 'row'
  },
  iconContainer: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center'
  },
  checkboxContainer: {
    position: 'absolute',
    top: dimensions.margins.large,
    right: dimensions.margins.large
  },
  descriptionContent: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'stretch'
  },
  descriptionTexts: {
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1
  },
  descriptionContainer: {
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  centeredContainer: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center'
  },
  posterTagPosition: {
    position: 'absolute',
    top: getValue({mobile: 6, defaultValue: 8}),
    flexDirection: 'row'
  },
  posterFirstTagStyle: {
    paddingLeft: channelIconConstants.static.size - channelIconOffset
  },
  posterTagStyle: {
    borderRadius: borderRadius,
    marginLeft: dimensions.margins.small
  },
  progressBar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  },
  liveTag: {
    width: dimensions.icon.xxsmall,
    paddingBottom: dimensions.margins.xsmall
  },
  profileTagPosition: {
    position: 'absolute',
    top: Math.floor(dimensions.tile.width / posterRatio) - dimensions.margins.xxxLarge,
    borderTopRightRadius: borderRadius,
    borderBottomRightRadius: borderRadius,
    paddingHorizontal: dimensions.margins.small
  }
});

const MobileMediaTileBase: React.FunctionComponent<MediaTileBaseProps> = (props, ref) => {
  const {
    media,
    config,
    onPress: propagatePress,
    onFocus: propagateFocus,
    scrollOnFocus = true,
    width = dimensions.tile.width,
    height = dimensions.tile.height,
    tileType = MediaTileType.Default,
    selected = false,
    checkboxChecked,
    checkboxVisible,
    showIcons = true,
    excludeIcons,
    onCheckboxToggle,
    additionalIcons,
    renderMediaPoster,
    focusable: propsFocusable = true,
    tag,
    onMount,
    secondRowItemsRenderer,
    staticallyFocused
  } = props;
  const {t} = useTranslation();
  const progress = useProgress(media);
  const [eventsForSelection, setEventsForSelection] = useState<Event[]>([]);
  const [mediaIcons, setMediaIcons] = useState<MediaIcons>({});
  const navigation = useContext(NavigationContext);
  const poster = useMemo(() => {
    const uri = mw.catalog.getPictureUrl(config?.posterMedia ?? media, PictureType.Tile, width, Math.floor(width / posterRatio), PictureMode.BOX);
    return uri ? {uri} : null;
  }, [config, media, width]);

  const {event, title} = getMediaSubtypes(media);

  const {onPress, isBlocked, onFocusStateChanged, focusable, focused} = useMediaTileHelpers({checkboxVisible, checkboxChecked, onCheckboxToggle, onPress: propagatePress, media, setEventsForSelection, config, focusable: propsFocusable, staticallyFocused, ref});

  const infoSection = useMediaInfoSection(media, t, tileType);

  useEffect(() => {
    const mediaIcons = prepareMediaIcons(media, {excludeIcons});
    const mediaIconsArray = mediaIcons?.icons || [];
    const additionalIconsArray = additionalIcons || [];
    const icons = [...mediaIconsArray, ...additionalIconsArray];
    setMediaIcons(showIcons ? {icons, tags: mediaIcons.tags} : {});
  }, [showIcons, excludeIcons, media, media.isOnWatchList, additionalIcons]);

  const onFocus = useCallback((event: any, options?: FocusOptions) => {
    propagateFocus?.(media, options);
  }, [propagateFocus, media]);

  const dynamicStyles = stylesUpdater.getStyles();

  const textColor = useCallback((focused: boolean) => {
    return focused ? dynamicStyles.textFocused : dynamicStyles.textUnfocused;
  }, [dynamicStyles.textFocused, dynamicStyles.textUnfocused]);

  const getContentStyle = useCallback((tileType: MediaTileType, width: number, height: number, selected: boolean): ViewStyle[] => {
    switch (tileType) {
      case MediaTileType.Episode:
        return [styles.contentView, {
          width,
          height: height + borderWidth,
          ...selected ? dynamicStyles.contentFocused : dynamicStyles.contentUnfocused,
          borderWidth,
          marginTop: dimensions.margins.small - borderWidth,
          marginHorizontal: dimensions.margins.small - borderWidth
        }];
      case MediaTileType.Default:
      case MediaTileType.Search:
      default:
        return [styles.contentView, {
          width,
          height,
          marginTop: dimensions.margins.small,
          marginHorizontal: dimensions.margins.small
        }];
    }
  }, [dynamicStyles.contentFocused, dynamicStyles.contentUnfocused]);

  const getDescriptionViewStyle = useCallback((tileType: MediaTileType, hasIcons: boolean, focused: boolean, selected: boolean): ViewStyle[] => {
    return [
      styles.descriptionView,
      tileType === MediaTileType.Episode ? styles.descriptionViewEpisode : styles.descriptionViewDefault,
      {borderBottomLeftRadius: borderRadius, borderBottomRightRadius: borderRadius},
      {paddingLeft: focused || selected ? dimensions.tile.descriptionHorizontalPadding : 0},
      {paddingRight: hasIcons && (focused || selected) ? dimensions.tile.descriptionHorizontalPadding : 0},
      focused ? dynamicStyles.descriptionViewFocused : {}
    ];
  }, [dynamicStyles.descriptionViewFocused]);

  const posterRadius = (focused: boolean, borderRadius: number) => focused ? {borderRadius: borderRadius, borderBottomLeftRadius: 0, borderBottomRightRadius: 0} : {borderRadius: borderRadius};

  const titleStyle = useMemo(() => [textColor(focused), !infoSection && {marginBottom: 6}], [focused, infoSection, textColor]);
  const infoSectionStyle = useMemo(() => ({...textColor(focused), opacity: dimensions.opacity.xxhigh}), [focused, textColor]);
  const dateViewStyle = useMemo(() => (textColor(focused)), [focused, textColor]);
  const contentStyle = useMemo(() => getContentStyle(tileType, width, height, !focused && selected), [getContentStyle, tileType, width, height, focused, selected]);
  const imageContainerStyle: StyleProp<ViewStyle> = useMemo(() => ({...dynamicStyles.imageContainer, ...posterRadius(focused, borderRadius)}), [focused, dynamicStyles.imageContainer]);
  const progressStyle = useMemo(() => ({flex: progress, ...dynamicStyles.progressStyle}), [progress, dynamicStyles.progressStyle]);
  const descriptionViewStyle = useMemo(() => getDescriptionViewStyle(tileType, !!(mediaIcons.icons?.length || mediaIcons.tags?.length), focused, selected), [getDescriptionViewStyle, tileType, mediaIcons, focused, selected]);
  const separatorStyle = useMemo(() => [styles.separator, focused ? dynamicStyles.separatorFocused : dynamicStyles.separatorUnfocused], [focused, dynamicStyles.separatorFocused, dynamicStyles.separatorUnfocused]);

  const testIdSuffix = (config?.title ?? getMediaTitleForType(media, tileType)) || props.media.name;
  const testID = useTestID(props, 'MediaTile') || `tile_${humanCaseToSnakeCase(testIdSuffix)}`;

  const onEventSelectionClose = useCallback(() => setEventsForSelection([]), []);
  const onEventSelected = useCallback((event: Event) => openMediaDetails(navigation, event.id, event.getType()), [navigation]);

  useEffectOnce(() => onMount?.(), [onMount]);

  const [shouldRenderLiveTag, setShouldRenderLiveTag] = useState<boolean>(!!event?.isNow);
  useChangeEffect(() => {
    setShouldRenderLiveTag(!!event?.isNow);
  }, [event, progress]);

  const shouldRenderTagFromProps = useMemo(() => !shouldRenderLiveTag || (tag?.type !== TagTypes.LIVE), [shouldRenderLiveTag, tag]);

  const infoSectionComponent = useMemo(() => {
    if (!infoSection && !config?.infoSection) {
      return null;
    }
    return (
      <NitroxText textType='callout-small-extra-condensed' style={infoSectionStyle} numberOfLines={1} ellipsizeMode='tail'>
        {config?.infoSection ?? infoSection}
      </NitroxText>
    );
  }, [config?.infoSection, infoSection, infoSectionStyle]);

  return (
    <>
      <TileContainer
        ref={ref}
        onFocus={onFocus}
        scrollOnFocus={scrollOnFocus}
        focusable={focusable}
        onPress={onPress}
        onFocusStateChanged={onFocusStateChanged}
        style={contentStyle}
        testID={testID}
      >
        <AspectRatio ratio={posterRatio}>
          <View style={[imageContainerStyle]}>
            {renderMediaPoster
              ? <View style={styles.centeredContainer}>{renderMediaPoster()}</View>
              : (
                <MediaPicture
                  source={poster}
                  mediaType={(config?.posterMedia ?? media).getType()}
                  isBlocked={isBlocked}
                  customPlaceholderIcon={config?.customPlaceholderIcon}
                />
              )
            }
            {poster && title && title.isTrailer && (
              <View style={styles.iconContainer}>
                <Icon type={IconType.Trailer} size={dimensions.tile.iconHeight} color={dynamicStyles.icon.color} shadow />
              </View>
            )}
            {props.checkboxVisible && (
              <View style={styles.checkboxContainer}>
                <Checkbox selected={props.checkboxChecked} size={dimensions.tile.checkboxSize} />
              </View>
            )}
            {!config?.hideProgress && tileType === MediaTileType.Default && (
              <View style={styles.progressContainer}>
                <View style={progressStyle} />
              </View>
            )}
          </View>
        </AspectRatio>
        <>
          <View style={styles.posterTagPosition}>
            {shouldRenderLiveTag && tileType === MediaTileType.Default && (
              <NitroxTag tag={Tags.liveTag} position={TagPosition.Poster} style={event && styles.posterFirstTagStyle} />
            )}
            {tag && shouldRenderTagFromProps && (
              <NitroxTag
                tag={tag}
                position={TagPosition.Poster}
                style={event && (!shouldRenderLiveTag ? styles.posterFirstTagStyle : styles.posterTagStyle)}
              />
            )}
          </View>
          {event && (<ChannelIcon style={styles.channelIcon} type={ChannelIconType.Static} channelId={event.channelId} />)}
        </>
        <View style={descriptionViewStyle}>
          {event && tileType === MediaTileType.Default && (
            <View style={styles.dateView}>
              <NitroxText textType='tile-subhead' style={dateViewStyle}>
                {moment(event.start).format(mw.configuration.timeFormat)}
              </NitroxText>
            </View>
          )}
          <View style={styles.descriptionContent}>
            {event && tileType === MediaTileType.Default && <Separator style={separatorStyle} />}
            <View style={styles.descriptionContainer}>
              <View style={styles.descriptionTexts}>
                <NitroxText
                  textType='tile-title'
                  style={titleStyle}
                  numberOfLines={1}
                  ellipsizeMode='tail'
                  testID='title'
                >
                  {config?.title ?? getMediaTitleForType(media, tileType)}
                </NitroxText>
                {infoSectionComponent}
                {tileType === MediaTileType.Episode && (
                  <>
                    {shouldRenderLiveTag && <NitroxTag tag={Tags.liveTag} style={styles.liveTag} />}
                    {progress > 0 && <MiniProgressBar style={styles.progressBar} progress={progress} />}
                  </>
                )}
              </View>
            </View>
          </View>
          {(mediaIcons.icons?.length || mediaIcons.tags?.length) && (
            <View style={styles.mediaIconsContainer}>
              <TileIconsRow focused={focused} mediaIcons={mediaIcons} />
            </View>
          )}
        </View>
      </TileContainer>
      {!!eventsForSelection.length && (
        <EventSelector
          events={eventsForSelection}
          onClose={onEventSelectionClose}
          onSelect={onEventSelected}
        />
      )}
    </>
  );
};

export default React.memo(forwardRef(MobileMediaTileBase));
