import {createStyles} from 'common-styles';
import React from 'react';
import {View, StyleProp, ViewStyle, Animated} from 'react-native';

import {isMobile, isWeb, isDesktopBrowser, isMobileBrowser} from 'common/constants';
import {AnimatedStyle} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxInteractive from './NitroxInteractive';

export type OverlayProps = {
  onClickAway?: () => any;
  style?: StyleProp<AnimatedStyle<ViewStyle>>;
}

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  overlay: {
    flex: 1,
    backgroundColor: colors.popup.overlay.background
  },
  outerWrapper: {
    flexGrow: 1
  }
}));

const Wrapper: React.FC<{onPress?: (e?: any) => any; style?: StyleProp<ViewStyle>; testID: string}> = ({children, onPress, style, testID}) => {
  if (isMobile || isMobileBrowser) {
    return (
      <NitroxInteractive
        activeOpacity={1}
        onPress={onPress}
        style={style}
        testID={`overlay_${testID}`}
      >
        {children}
      </NitroxInteractive>
    );
  } else if (isDesktopBrowser) {
    return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore this is needed for web in order not to kill focus handling
      <View onClick={onPress} style={style} testID={`overlay_${testID}`}>
        {children}
      </View>
    );
  } else {
    return <>{children}</>;
  }
};

/// Fire clickaway handler
/// Capture inner click event though, thus not calling false-positive clickaway
export const Overlay: React.FunctionComponent<OverlayProps> = props => {
  const {onClickAway, children, style} = props;
  const styles = stylesUpdater.getStyles();
  return (
    <Wrapper testID='click_away' onPress={onClickAway} style={styles.outerWrapper}>
      <Animated.View style={[styles.overlay, style]}>
        <Wrapper testID='wrapper' onPress={isWeb ? (e => {e?.stopPropagation?.();}) : (undefined)}>
          {children}
        </Wrapper>
      </Animated.View>
    </Wrapper>
  );
};
