import React, {useMemo, useContext, useState, useRef, useCallback} from 'react';

import {doNothing} from 'common/HelperFunctions';

import {useChangeEffect, useDisposableCallback} from 'hooks/Hooks';

type FocusSwitchInterfaceContextType = {
  /**
   * register 'focus' event within FocusSwitch scope
   */
  on: () => void;
  /**
   * register 'blur' event within FocusSwitch scope
   */
  off: () => void;
  /**
   * reset focus state, e.g. on 'disabled' prop set
   */
  reset: () => void;
}

type FocusSwitchValueContextType = {
  /**
   * flag indicating whether there is focused element in FocusSwitch scope
   */
  focused: boolean;
}

type RenderChildren = {
  renderChildren?: () => React.ReactNode;
};

/**
 * FocusSwitch context is divided to omit rerendering consumers that do not use its state
 */
const FocusSwitchValueContext = React.createContext<FocusSwitchValueContextType>({focused: false});
const FocusSwitchInterfaceContext = React.createContext<FocusSwitchInterfaceContextType>({on: doNothing, off: doNothing, reset: doNothing});

export function useFocusSwitchInterface() {
  return useContext(FocusSwitchInterfaceContext);
}
export function useFocusSwitchValue() {
  return useContext(FocusSwitchValueContext);
}

/**
 * Context.Consumer rerenders always when provider does, even despite no change context value. Memoizing provider solves this issue.
 * This is local component for now, as this is quite hacky solution that must be used consciously.
 */
function PureProvider<T>({value, children, renderChildren, Context}: {value: T; children?: React.ReactNode; Context: React.Context<T>} & RenderChildren) {
  return (
    <Context.Provider value={value}>
      {children ?? renderChildren?.()}
    </Context.Provider>
  );
}
const PureContextProvider = React.memo(PureProvider) as typeof PureProvider;

export type FocusSwitchProps = {
  onFocusEnter?: () => void;
  onFocusEscape?: () => void;
  /**
   * Callback triggered when Focused element change without escaping given FocusParent.
   * It is not triggered on focusEnter, as well as it is not triggered on focusEscape
   */
  onInternalFocusUpdate?: () => void;
};

type ParentSwitchProps = {
  parent: Pick<FocusSwitchInterfaceContextType, 'on' | 'off' | 'reset'>;
};

const FocusSwitchComponent: React.FC<FocusSwitchProps & ParentSwitchProps & {children: React.ReactNode}> = ({
  onFocusEnter,
  onFocusEscape,
  onInternalFocusUpdate,
  children,
  parent
}) => {
  const focusedElements = useRef(0);

  const [focused, setFocused] = useState(false);

  const updateState = useDisposableCallback(() => {
    setFocused(!!focusedElements.current);
  }, []);

  const on = useCallback(() => {
    focusedElements.current++;
    parent.on();
    /**
     * Bellow condition depends on:
     * on callback is synchronious and is triggered right after descendant gain focus
     * off callback is asynchronious, thus being triggered after new elements get focused
     * hence `focusedElements.current > 1` means focused moved to new element which is as well descendant of this focusParent
     * asynchronism of off callback is thanks to timout 200ml in its implementation.
     */
    focusedElements.current > 1 && onInternalFocusUpdate?.();
    updateState();
  }, [onInternalFocusUpdate, parent, updateState]);

  const off = useCallback(() => {
    setTimeout(() => {
      focusedElements.current--;
      parent.off();
      updateState();
    /**
     * Below timeout cause off callback to be processed after on callback of next focused element.
     * Calling onInternalFocusUpdate in on callback also depend on that.
     */
    }, 200);
  }, [parent, updateState]);

  const reset = useCallback(() => {
    focusedElements.current = 0;
    parent.reset();
    updateState();
  }, [parent, updateState]);

  const interfaceContext = useMemo(() => ({
    on,
    off,
    reset
  }), [on, off, reset]);

  const valueContext = useMemo(() => ({
    focused
  }), [focused]);

  useChangeEffect(() => {
    focused
      ? onFocusEnter?.()
      : onFocusEscape?.();
  }, [focused], [onFocusEnter, onFocusEscape]);

  const renderChildren = useCallback(() => {
    return (
      <PureContextProvider
        Context={FocusSwitchInterfaceContext}
        value={interfaceContext}
        renderChildren={() => children}
      />
    );
  }, [interfaceContext, children]);

  return (
    <PureContextProvider
      Context={FocusSwitchValueContext}
      value={valueContext}
      renderChildren={renderChildren}
    />
  );
};
const MemoizedFocusSwitchComponent = React.memo(FocusSwitchComponent);

// this hack omits unnecessary focus switch tree (or branch at least) rerender when parent switch rerenders with no context change
export const FocusSwitch: React.FC<FocusSwitchProps> = ({children, ...props}) => {
  return (
    <MemoizedFocusSwitchComponent
      {...props}
      parent={useFocusSwitchInterface()}
    >
      {children}
    </MemoizedFocusSwitchComponent>
  );
};
