import {isMobile} from 'common/constants';
import {getPlatformFeatures} from 'common/utils';

import {Media} from 'mw/api/Metadata';
import {ChromecastConnectionState} from 'mw/api/Metadata';
import {nxffConfig} from 'mw/api/NXFF';
import {Track} from 'mw/api/PlayerEvent';

export const isChromecastSupported = (): boolean => isMobile && !!getPlatformFeatures()?.ChromecastApplicationId && nxffConfig.getConfig().Chromecast.ChromecastEnabled;

export interface ChromecastInterface {
  getDevices(): Promise<ChromecastDevice[]>;
  getConnectionDetails(): Promise<ChromecastConnectedDevice>;
  disconnectDevice(id: string): Promise<void>;
  connectDevice(id: string): Promise<void>;

  castMedia(media: Media, position: number): Promise<void>;
  play(): Promise<void>;
  pause(): Promise<void>;
  seek(seconds: number): Promise<void>;
  setVolume(volume: number): Promise<void>;
  getVolume(): Promise<number>;
  setAudioTrack(trackId: number): Promise<void>;
  getAudioTracks(): Promise<ChromecastTracksList>;
  setSubtitleTrack(trackId: number): Promise<void>;
  getSubtitleTracks(): Promise<ChromecastTracksList>;
  stop(): Promise<void>;
}

export interface ChromecastDevice {
  readonly id: string;
  // IOS implementation gives friendlyName as optional hence name is optional
  readonly name: string | undefined;
}

export enum NativeConnectionState {
  Disconnected = 0,
  Connecting = 1,
  Connected = 2,
  Disconnecting = 3
}

export interface ChromecastConnectedDevice {
  readonly device: ChromecastDevice | null;
  readonly connectionState: ChromecastConnectionState;
}

export type NativeConnectedDevice = {
  id: string,
  name: string,
  connectionState: NativeConnectionState;
}

interface NXFFConfig {
  readonly profile: string;
  readonly backoffice: string;
}

interface SSOConfig {
  readonly BOToken: string;
  readonly widevineURL: string;
}

interface BOConfig {
  readonly BOURL: string;
}

interface Customer {
  readonly id: string;
  readonly profileId: string;
  readonly simSubRegionId: string;
  readonly deviceId: string;
}

export interface ChromecastMedia {
  readonly id: string;
  readonly type: ChromecastMediaType;
  readonly tunePoint?: number;
}

export enum ChromecastMediaType {
  LIVE = 'LIVE',
  VOD = 'VOD',
  NPVR = 'NPVR',
  TSTV = 'TSTV',
  UNKNOWN = 'UNKNOWN'
}

/**
 * Detailed information about interface to be found: https://wiki.schange.com/display/NIT/Chromecast+integration#Chromecastintegration-3.1.1AppController
 */
export interface CustomData {
  readonly NXFFConfig: NXFFConfig;
  readonly SSOConfig: SSOConfig;
  readonly BOConfig?: BOConfig;
  readonly customer: Customer;
  readonly media: ChromecastMedia;
}

export enum ChromecastEvent {
  SessionStarting = 'SessionStarting',
  SessionStartFailed = 'SessionStartFailed',  // payload: error
  SessionStarted = 'SessionStarted',
  SessionSuspended = 'SessionSuspended',  // payload: reason
  SessionResuming = 'SessionResuming',
  SessionResumed = 'SessionResumed',  // payload: wasSuspended
  SessionEnded = 'SessionEnded',  // payload: error
  MediaPlaybackStarted = 'MediaPlaybackStarted',
  MediaPlaybackPaused = 'MediaPlaybackPaused',
  MediaPlaybackPlaying = 'MediaPlaybackPlaying',
  MediaPlaybackEnded = 'MediaPlaybackEnded',  // payload: reason
  MediaPlaybackPosition = 'MediaPlaybackPosition', // payload: position, duration
  VolumeChanged = 'VolumeChanged', // payload: volume
  AudioLanguagesChanged = 'AudioLanguagesChanged', // payload: tracks, currentTrack
  SubtitlesChanged = 'SubtitlesChanged', // payload: tracks, currentTrack
  CurrentMedia = 'CurrentMedia' // payload: chromecastMedia
}

export enum PlaybackFinishedReason {
  Interrupted = 'INTERRUPTED'
}

export type ChromecastTracksList = {
  tracks: Track[];
  currentTrack?: Track;
}

export type MediaPlaybackPosition = {
  position: number;
  duration: number;
}
