import Color from 'color';
import {createStyles} from 'common-styles';
import React from 'react';
import {ImageBackground, ImageResizeMode, StyleProp, StyleSheet, ViewStyle} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import {dimensions, isBigScreen} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  gradient: {
    color: colors.mediaDetailsScreen.poster.gradientCore
  }
}));

const gradientStart = {x: 0, y: isBigScreen ? 0 : 0.4};
const gradientEnd = {x: 0, y: 0.95};

type Props = {
  imageUri: string;
  imageResizeMode: ImageResizeMode;
  style?: StyleProp<ViewStyle>;
};

const BackgroundPoster: React.FunctionComponent<Props> = props => {
  const styles = stylesUpdater.getStyles();
  const gradientColors = isBigScreen ? [
    Color(styles.gradient.color).alpha(dimensions.opacity.xlow).toString(),
    Color(styles.gradient.color).alpha(dimensions.opacity.xxhigh).toString(),
    styles.gradient.color
  ] : [
    Color(styles.gradient.color).alpha(0).toString(),
    styles.gradient.color
  ];

  return (
    <ImageBackground
      source={{uri: props.imageUri}}
      imageStyle={{resizeMode: props.imageResizeMode}}
      style={props.style}
    >
      <LinearGradient
        colors={gradientColors}
        start={gradientStart}
        end={gradientEnd}
        style={StyleSheet.absoluteFill}
      />
    </ImageBackground>
  );
};

export default React.memo(BackgroundPoster);
