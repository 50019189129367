import {WithTranslation} from 'react-i18next';
import {NavigationScreenProps} from 'react-navigation';

import {isMobile, dimensions} from 'common/constants';

import {ContentQueryParameters} from 'mw/api/CatalogInterface';
import {Filter} from 'mw/api/Filter';
import {Link} from 'mw/cms/Menu';

import {WithFocusParent} from 'components/FocusParent';
import {VodCategory} from 'components/vod/categoryNavigator/VodCategoryNavigator';

export const vodCategoryScreenMargin = isMobile ? dimensions.margins.large : dimensions.mainMenu.marginHorizontal;

export type VodCategoryScreenProps = NavigationScreenProps<{
  link?: Link;
  navigationBarVisible?: boolean;
}> & WithTranslation & WithFocusParent<'vodScreen'>;

export type VodCategoryScreenState = {
  category?: VodCategory;
  fetchingSubcategories: boolean;

  filters?: Filter[];
  queryOptions: ContentQueryParameters;
  isSeenFilterAvailable: boolean;

  voucherRedeemModalVisible: boolean;

  isMenuVisible: boolean;
  shouldFocusBanner: boolean;
}
