import i18next from 'i18next';
import moment from 'moment';

import {getBuyProducts, getRentProducts} from 'common/HelperFunctions';
import {formatCurrency} from 'common/utils';

import {PurchaseFlowType} from 'mw/api/Configuration';
import {Error, ErrorType} from 'mw/api/Error';
import {isTitle, Media} from 'mw/api/Metadata';
import {nxffConfig} from 'mw/api/NXFF';
import {mw} from 'mw/MW';

export function formatRentalDuration(duration: number, t: i18next.TFunction): string {
  const now = Date.now();
  const rentalEnd = moment(now + duration);
  const days = rentalEnd.diff(now, 'days');
  if (days) {
    return t('payments.dialog.rentalExpiresDays', {count: days});
  }

  const hours = rentalEnd.diff(now, 'hours');
  if (hours) {
    return t('payments.dialog.rentalExpiresHours', {count: hours});
  }

  const minutes = rentalEnd.diff(now, 'minutes');
  return t('payments.dialog.rentalExpiresMinutes', {count: minutes});
}

export const isPurchaseAllowed = (): boolean => {
  return nxffConfig.getConfig().Purchases.VodPurchasesEnabled && mw.configuration.getPurchaseFlowType() !== PurchaseFlowType.Disabled;
};

export const getBuyButtonText = (t: i18next.TFunction, i18n: i18next.i18n, media: Media): string => {
  if (isTitle(media)) {
    const products = getBuyProducts(media);
    if (products.length === 1 && products[0].offers.length === 1) {
      const {price, currency} = products[0].offers[0];
      return t('payments.buyWithPrice', {price: formatCurrency(price, currency, i18n.language)});
    }
  }
  return t('mediaDetails.buy');
};

export const getRentButtonText = (t: i18next.TFunction, i18n: i18next.i18n, media: Media): string => {
  if (isTitle(media)) {
    const products = getRentProducts(media);
    if (products.length === 1 && products[0].offers.length === 1) {
      const {price, currency} = products[0].offers[0];
      return t('payments.rentWithPrice', {price: formatCurrency(price, currency, i18n.language)});
    }
  }
  return t('mediaDetails.rent');
};

export function getTransactionErrorMessage(t: i18next.TFunction, error: Error): string {
  switch (error.type) {
    case ErrorType.TransactionLocationForbidden:
      return t('payments.error.transaction.locationForbidden');
    case ErrorType.TransactionVPNForbidden:
      return t('payments.error.transaction.vpnForbidden');
    default:
      return t('payments.error.other', {
        code: error.code,
        type: error.type,
        message: error.message
      });
  }
}
