import {createStyles} from 'common-styles';
import React, {useEffect} from 'react';
import {View, ViewStyle} from 'react-native';

import {dimensions, isIOS} from 'common/constants';
import {EventEmitter} from 'common/EventEmitter';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';

import {Icon, IconType} from 'components/Icon';
import {useDisposableState} from 'hooks/Hooks';

const TAG = 'MobileHeaderBackButton';

const dynamicStylesUpdater = new StylesUpdater(colors => createStyles({
  mobileHeaderBackIcon: {
    backgroundColor: colors.button.primary.background.focused
  }
}));

const styles = createStyles({
  mobileHeaderBack: {
    height: dimensions.screen.header.height,
    justifyContent: 'center',
    ...(isIOS ? {
      paddingLeft: dimensions.margins.large
    } : {
      // For android mobiles we have to explicitly provide the back image container's dimensions here and not through the headerStyle prop
      // in order to make it sure that it will always be large enough to be interactive.
      width: Math.floor(dimensions.backButton.size * 2),
      height: Math.floor(dimensions.backButton.size * 2),
      padding: Math.floor(dimensions.backButton.size / 2)
    }
    )
  }
});

type Event = 'change';
const emitter = new EventEmitter<Event>();

let shouldDisplayBackButton = true;

export const MobileBackButtonManager = Object.freeze({
  show: () => {
    Log.info(TAG, 'Setting back button shouldDisplay to true');
    shouldDisplayBackButton = true;
    emitter.notify('change');
  },
  hide: () => {
    Log.info(TAG, 'Setting back button shouldDisplay to false');
    shouldDisplayBackButton = false;
    emitter.notify('change');
  }
});

export const BackButton: React.FC<{style?: ViewStyle}> = ({style}) => {
  const dynamicStyles = dynamicStylesUpdater.getStyles();
  return (
    <View style={[styles.mobileHeaderBack, style]}>
      <Icon
        type={IconType.BackMobile}
        size={dimensions.backButton.size}
        color={dynamicStyles.mobileHeaderBackIcon.backgroundColor}
      />
    </View>
  );
};

const MobileHeaderBackButton: React.FC = () => {
  const [shouldDisplay, setShouldDisplay] = useDisposableState(shouldDisplayBackButton);

  useEffect(() => {
    const change = () => setShouldDisplay(shouldDisplayBackButton);
    change();
    emitter.on('change', change);
    return () => {
      emitter.off('change', change);
    };
  }, [setShouldDisplay]);

  Log.info(TAG, 'Rendering back button with shouldDisplay =', shouldDisplay);

  return shouldDisplay ? <BackButton /> : null;
};

export default React.memo(MobileHeaderBackButton);
