import {createStyles} from 'common-styles';
import React from 'react';
import {View} from 'react-native';

import {dimensions, isMobile} from 'common/constants';

import MiniProgressBar from 'components/MiniProgressBar';

const styles = createStyles({
  container: isMobile ? {
    height: 15,
    width: '100%'
  } : {
    height: 80,
    paddingTop: 55,
    width: dimensions.mediaInfoView.progressBar.width
  }
});

type Props = {
  progress?: number;
}

const ProgressBarView: React.FunctionComponent<Props> = props => {
  return (
    <View style={styles.container} testID='progress_bar'>
      {typeof props.progress === 'number' && <MiniProgressBar progress={props.progress} />}
    </View>
  );
};

export default ProgressBarView;
