import Animated, {useCode, set, stopClock, startClock, cond, block, not, clockRunning, spring} from 'react-native-reanimated';

import {useLazyRef, useFunction} from 'hooks/Hooks';

const hardSpringConfig = {
  damping: 1000,
  mass: 1,
  stiffness: 1000,
  overshootClamping: true,
  restSpeedThreshold: 0.001,
  restDisplacementThreshold: 0.001
};

export function useReanimatedScrollTo(contentPosition: Animated.Value<number>): {
  scrollTo: (position: number) => void;
} {
  const clock = useLazyRef(() => new Animated.Clock()).current;
  const state = useLazyRef(() => ({
    finished: new Animated.Value(0),
    velocity: new Animated.Value(0),
    position: new Animated.Value(0),
    time: new Animated.Value(0)
  })).current;
  const springConfig = useLazyRef(() => ({
    ...hardSpringConfig,
    toValue: new Animated.Value<number>(0)
  })).current;
  const shouldRunAnimation = useLazyRef(() => new Animated.Value<0 | 1>(0)).current;
  const animationDestination = useLazyRef(() => new Animated.Value<number>(0)).current;

  useCode(() => {
    const onFinished = [
      set(state.finished, 0),

      // Don't stop clock if request to start new animation has been called
      cond(not(shouldRunAnimation), [
        stopClock(clock)
      ])
    ];

    const runAnimation = [
      set(springConfig.toValue, animationDestination),
      startClock(clock),
      set(shouldRunAnimation, 0)
    ];

    const onAnimating = [
      // necessary when next animation is run before previous has finished
      // updates destination, making overlapping animations smooth
      set(springConfig.toValue, animationDestination),
      set(contentPosition, state.position)
    ];

    const onNotAnimating = [
      set(state.position, contentPosition),

      cond(shouldRunAnimation, runAnimation)
    ];

    return block([
      cond(not(clockRunning(clock)), onNotAnimating),
      spring(clock, state, springConfig),
      cond(clockRunning(clock), onAnimating),
      cond(state.finished, onFinished)
    ]);
  }, [clock, state.finished, state.position, contentPosition, shouldRunAnimation, animationDestination, springConfig.toValue]);

  const scrollTo = useFunction((position: number) => {
    animationDestination.setValue(position);
    shouldRunAnimation.setValue(1);
  });

  return {scrollTo};
}
