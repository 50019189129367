import {useCallback} from 'react';

import {useDisposable} from 'hooks/Hooks';

/**
 * Handy wrapper for `useDisposable`.
 * Pass promise to returned function like `safeAwait(promise)`, returned promise won't be resolved after component unmount.
 */
export function useSafeAwait() {
  const disposableWrapper = useDisposable(<T>(promise: Promise<T>) => promise);
  return useCallback(<T>(promise: Promise<T>) => disposableWrapper(promise) as Promise<T>, [disposableWrapper]);
}
