import {createStyles} from 'common-styles';
import React from 'react';
import {Animated, View, LayoutChangeEvent, ViewStyle} from 'react-native';

import {debounce, DebouncedFunction} from 'common/Async';
import {isWeb, isTVOS} from 'common/constants';
import {Log} from 'common/Log';

const TAG = 'NitroxList';
const origin = {x: 0, y: 0};

const defaultMinAvailableItemsData = 16;
const defaultMinAvailableRenderedItems = 8;
const defaultMinViewportOffset = 0;
const defaultMaxViewportOffset = 0;
const defaultViewportTransition = 200;
const defaultRenderTimeout = 100;

const styles = createStyles({
  container: {
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  viewport: {
    position: 'absolute',
    overflow: 'visible',
    flex: 0
  },
  itemContainer: {
    position: 'absolute'
  }
});

export enum NitroxListItemsAction {
  Prepend,
  Append
}

export type NitroxListItemsRequest = {
  action: NitroxListItemsAction; // indicates what kind of operation triggered this request (appending to the end of the list or prepending to the beginning)
  beginIndex: number; // beginning index of items data that the list is missing
  endIndex: number;  // end index of items data that the list is missing, please bear in mind that this index is also a part of the missing range
}

function compareRequests(a: NitroxListItemsRequest, b: NitroxListItemsRequest): boolean {
  return a.action === b.action && a.beginIndex === b.beginIndex && a.endIndex === b.endIndex;
}

export class NitroxListItemsBatch<ItemData> {
  public itemsData: ItemData[]; // an array of items data for this batch - items which generate the same keys will be ignored
  public hasMore: boolean; // indicates whether there are more data available for the requested action (appending or prepending)
  public focusedIndex?: number;  // index relative to this batch of an item which should be scrolled to after receiving and rendering this batch

  public constructor(itemsData: ItemData[] = [], hasMore: boolean, focusedIndex?: number) {
    this.itemsData = itemsData;
    this.hasMore = hasMore;
    this.focusedIndex = focusedIndex;
  }
}

export type NitroxListItemLayout = {
  width: number;
  height: number;
  marginBefore?: number;
  marginAfter?: number;
};

export type NitroxListProps<ItemData> = {
  horizontal: boolean; // list orientation
  containerStyle?: ViewStyle;
  minViewportOffset?: number; // offset in pixels of the left or top (depending on the orientation) edge of the viewport (content's clipping window) - it's a limiting point of a closed interval
  maxViewportOffset?: number; // offset in pixels of the right or bottom (depending on the orientation) edge of the viewport (content's clipping window) - it's a limiting point of a closed interval
  viewportTransition?: number; // number of miliseconds of the viewport's transition (adjustment) animation
  moveViewportOverLoadingIndicators?: boolean; // indicates if loading indicators should be moved to viewport when scrolling
  minAvailableItemsData?: number; // the minimum number of preloaded data items that will be available to the list to use
  minAvailableRenderedItems?: number; // the minimum number of prerendered items that the list should keep
  renderTimeout?: number; // timeout after which render is triggered started after finishing scrolling to index
  enableDebugLogs?: boolean; // should the list print debug logs about it's internal workings
  debugName?: string; // human-readable name of this list used in debug logs
  refreshOnMount?: boolean; // indicates if you want the list to automatically call refresh after mounting is done
  requestItemsData: (request: NitroxListItemsRequest) => Promise<NitroxListItemsBatch<ItemData>>;
  renderItem: (itemData: ItemData, index: number) => React.ReactElement | undefined;
  layoutItem: (itemData: ItemData, index: number) => NitroxListItemLayout;
  keyExtractor: (itemData: ItemData, index: number) => string;
  renderLoadingIndicator?: () => React.ReactElement | undefined;
  layoutLoadingIndicator?: () => NitroxListItemLayout;
  renderMissingDataPlaceholder?: () => React.ReactElement | undefined;
  layoutMissingDataPlaceholder?: () => NitroxListItemLayout;
  onItemsRendered?: () => void; // all required items have been rendered and layouted
  onContainerLayoutChanged?: (width: number, height: number) => void; // at this point dimansions are known therefore it's a good place for example to set viewport dimensions
  onViewportAdjusted?: () => void; // viewport adjustment and animation have finished
  onScrolledToIndex?: (index: number, itemData: ItemData | undefined) => void; // called when new focused index value was computed due to scolling but before executing the scrolling animations
};

export type NitroxListState<ItemData> = {
  itemsData: ItemData[];
  firstAvailableIndex: number;
  lastAvailableIndex: number;
  reachedBeginning: boolean;
  reachedEnd: boolean;
}

type NitroxListRenderedItem = {
  element: React.ReactElement;
  index: number;
  offset: number;
  animatedOffset: Animated.ValueXY;
  visible: boolean;
  layout?: NitroxListItemLayout;
}

export default class NitroxList<ItemData> extends React.Component<NitroxListProps<ItemData>, NitroxListState<ItemData>> {
  private containerRef: React.RefObject<View>;
  private containerWidth = 0;
  private containerHeight = 0;
  private minViewportOffset: number;
  private maxViewportOffset: number;
  private viewportTransition: number;
  private viewportOffset = 0;
  private viewportAnimatedOffset: Animated.ValueXY = new Animated.ValueXY(origin);
  private minAvailableItemsData: number;
  private minAvailableRenderedItems: number;
  private focusedIndex = 0;
  private focusedIndexOverflow = 0;
  private firstRenderedLoadingIndicator?: NitroxListRenderedItem;
  private lastRenderedLoadingIndicator?: NitroxListRenderedItem;
  private renderedMissingDataPlaceholder?: NitroxListRenderedItem;
  private renderedItems: NitroxListRenderedItem[] = [];
  private pendingRequests: NitroxListItemsRequest[] = [];
  private scheduleRefresh: DebouncedFunction<() => void>;
  private renderedItemsChanged = false;
  private visibilityChanged = false;

  public constructor(props: NitroxListProps<ItemData>) {
    super(props);
    this.state = {
      itemsData: [],
      firstAvailableIndex: 0,
      lastAvailableIndex: 0,
      reachedBeginning: false,
      reachedEnd: false
    };
    this.containerRef = React.createRef<View>();
    this.minViewportOffset = props.minViewportOffset || defaultMinViewportOffset;
    this.maxViewportOffset = props.maxViewportOffset || defaultMaxViewportOffset;
    this.viewportTransition = props.viewportTransition || defaultViewportTransition;
    this.minAvailableItemsData = props.minAvailableItemsData || defaultMinAvailableItemsData;
    this.minAvailableRenderedItems = props.minAvailableRenderedItems || defaultMinAvailableRenderedItems;
    this.scheduleRefresh = debounce(this.refresh.bind(this), props.renderTimeout || defaultRenderTimeout);
  }

  public clear(): void {
    this.log('Clearing the entire list');
    this.focusedIndex = 0;
    this.focusedIndexOverflow = 0;
    this.renderedItems = [];
    this.pendingRequests = [];
    this.viewportOffset = 0;
    this.viewportAnimatedOffset.stopAnimation();
    this.viewportAnimatedOffset.setValue(origin);
    this.renderedItemsChanged = true;
    this.visibilityChanged = true;
    this.setState({
      itemsData: [],
      firstAvailableIndex: 0,
      lastAvailableIndex: 0,
      reachedBeginning: false,
      reachedEnd: false
    });
  }

  public scrollToIndex = (index: number, forceIndex?: boolean): void => {
    if (forceIndex && index > this.state.itemsData.length) return;
    const focusedIndex = forceIndex ? index : Math.max(Math.min(index, this.computeMaxScrollableIndex()), this.computeMinScrollableIndex());
    if (this.focusedIndex === focusedIndex) {
      this.log('There is no need to scroll to index ' + focusedIndex);
      return;
    }
    this.focusedIndex = focusedIndex;
    this.focusedIndexOverflow = index - this.focusedIndex;
    this.log('Scrolling to index ' + this.focusedIndex + ' accumulated overflow is ' + this.focusedIndexOverflow);
    this.adjustViewport(this.viewportTransition);
    this.scheduleRefresh();
    if (this.props.onScrolledToIndex) {
      this.props.onScrolledToIndex(this.getFocusedIndex(), this.getFocusedItemData());
    }
  };

  public isEmpty(): boolean {
    return this.state.itemsData.length === 0;
  }

  public hasReachedBeginning(): boolean {
    return this.state.reachedBeginning;
  }

  public hasReachedEnd(): boolean {
    return this.state.reachedEnd;
  }

  public getItemData(index: number): ItemData | undefined {
    return this.state.itemsData[this.state.firstAvailableIndex < 0 ? index - this.state.firstAvailableIndex : index];
  }

  public findItemData(predicate: (itemData: ItemData, index: number) => boolean): ItemData | undefined {
    return this.state.itemsData.find((itemData, index): boolean => {
      // make sure to remap the item index
      return predicate(itemData, this.state.firstAvailableIndex < 0 ? index - this.state.firstAvailableIndex : index);
    });
  }

  public getFocusedIndex(): number {
    return this.focusedIndex;
  }

  public getFocusedItemData(): ItemData | undefined {
    return this.getItemData(this.focusedIndex);
  }

  public setViewportOffsets(minViewportOffset: number, maxViewportOffset: number): void {
    if (this.minViewportOffset === minViewportOffset && this.maxViewportOffset === maxViewportOffset) {
      return;
    }
    this.minViewportOffset = minViewportOffset;
    this.maxViewportOffset = maxViewportOffset;
    this.adjustViewport(0);

    this.log('Triggering refresh due to viewport offset change');
    this.refresh();
  }

  public async refresh(): Promise<void> {
    this.log('Refreshing is triggered');
    await this.requestItemsData();
    const renderedItemsChanged = this.renderItems();
    const visibilityChanged = this.adjustRenderedItemsVisibility();
    if (renderedItemsChanged || visibilityChanged) {
      this.forceUpdate();
      this.layoutRenderedItems();
      this.adjustViewport(0);
      if (this.props.onItemsRendered) {
        this.props.onItemsRendered();
      }
    }
  }

  private refreshLoadingIndicators(): void {
    this.log('Refreshing loading indicators');
    const renderedItemsChanged = this.renderLoadingIndicators();
    const visibilityChanged = this.adjustRenderedItemsVisibility();
    if (renderedItemsChanged || visibilityChanged) {
      this.layoutRenderedItems();
      this.forceUpdate();
    }
  }

  private computeMinScrollableIndex(): number {
    // array with rendered items is always sorted by the index value
    const firstRenderedItem = this.renderedItems[0];
    if (firstRenderedItem && isFinite(firstRenderedItem.index)) {
      return firstRenderedItem.index;
    }
    return this.state.firstAvailableIndex;
  }

  private computeMaxScrollableIndex(): number {
    // array with rendered items is always sorted by the index value
    const lastRenderedItem = this.renderedItems[this.renderedItems.length - 1];
    if (lastRenderedItem && isFinite(lastRenderedItem.index)) {
      return lastRenderedItem.index;
    }
    return this.state.lastAvailableIndex;
  }

  private findPendingRequestIndex(request: NitroxListItemsRequest): number {
    return this.pendingRequests.findIndex((pendingRequest: NitroxListItemsRequest) => {
      return compareRequests(pendingRequest, request);
    });
  }

  private hasPendingRequest(request: NitroxListItemsRequest): boolean {
    return this.findPendingRequestIndex(request) !== -1;
  }

  private removePendingRequest(request: NitroxListItemsRequest): void {
    this.pendingRequests.splice(this.findPendingRequestIndex(request), 1);
  }

  private async requestItemsDataForAppending(): Promise<boolean> {
    // try to preload addition data for items at the end of the list
    if (this.state.reachedEnd || this.state.lastAvailableIndex - this.focusedIndex >= this.minAvailableItemsData) {
      return false;
    }
    const beginIndex = this.state.itemsData.length === 0 ? 0 : this.state.lastAvailableIndex + 1;
    const request: NitroxListItemsRequest = {
      action: NitroxListItemsAction.Append,
      beginIndex: beginIndex,
      endIndex: beginIndex + this.minAvailableItemsData - 1
    };
    if (this.hasPendingRequest(request)) {
      this.log('Ignoring duplicated request for items at the end from ' + request.beginIndex + ' to ' + request.endIndex);
      return false;
    }

    // send a request for at least min available items
    this.log('Requesting data for items at the end from ' + request.beginIndex + ' to ' + request.endIndex + ' - current focused index is ' + this.focusedIndex);
    this.pendingRequests.push(request);
    const {itemsData, hasMore, focusedIndex} = await this.props.requestItemsData(request);
    if (!this.hasPendingRequest(request)) { // list might get cleared after we send the request and before we got the response
      this.log('Received data for unexisting append request - ignoring it');
      return false;
    }

    this.invalidateRenderedItems();
    if (itemsData.length > 0) {
      // adjust the focused index to match the requested one which was relative the returned batch
      if (typeof focusedIndex != 'undefined' && focusedIndex > 0) {
        this.focusedIndex = this.state.itemsData.length + focusedIndex;
        this.log('Resetting focused index to ' + this.focusedIndex);
      }
      this.setState(previousState => {
        return {
          itemsData: previousState.itemsData.concat(itemsData),
          lastAvailableIndex: (previousState.itemsData.length === 0 ? itemsData.length - 1 : previousState.lastAvailableIndex + itemsData.length),
          reachedEnd: !hasMore
        };
      });
    } else {
      // make sure to update the state here also - otherwise we will endlessly requesting new data
      this.setState({reachedEnd: !hasMore});
    }

    this.log('Received data for ' + itemsData.length + ' items for the end, available items span from ' + this.state.firstAvailableIndex + ' to ' + this.state.lastAvailableIndex
      + (this.state.reachedEnd ? ' - the end has been reached' : ' - there are still more data'));
    this.removePendingRequest(request);
    return true;
  }

  private async requestItemsDataForPrepending(): Promise<boolean> {
    // try to preload addition data for items at the beginning of the list
    if (this.state.reachedBeginning || this.focusedIndex - this.state.firstAvailableIndex >= this.minAvailableItemsData) {
      return false;
    }

    // send a request to keep at least min available items
    const endIndex = this.state.itemsData.length === 0 ? 0 : this.state.firstAvailableIndex - 1;
    const request: NitroxListItemsRequest = {
      action: NitroxListItemsAction.Prepend,
      beginIndex: endIndex - this.minAvailableItemsData + 1,
      endIndex: endIndex
    };
    if (this.hasPendingRequest(request)) {
      this.log('Ignoring duplicated request for items at the beginning from ' + request.beginIndex + ' to ' + request.endIndex);
      return false;
    }

    this.log('Requesting data for items at the beginning from ' + request.beginIndex + ' to ' + request.endIndex);
    this.pendingRequests.push(request);
    const {itemsData, hasMore, focusedIndex} = await this.props.requestItemsData(request);
    if (!this.hasPendingRequest(request)) { // list might get cleared after we send the request and before we got the response
      this.log('Received data for unexisting prepend request - ignoring it');
      return false;
    }

    this.invalidateRenderedItems();
    if (itemsData.length > 0) {
      // adjust the focused index to match the requested one which was relative the returned batch
      if (typeof focusedIndex != 'undefined' && focusedIndex > 0) {
        this.focusedIndex = this.state.firstAvailableIndex + (focusedIndex - itemsData.length);
        this.log('Resetting focused index to ' + this.focusedIndex);
      }
      this.setState(previousState => {
        return {
          itemsData: itemsData.concat(previousState.itemsData),
          firstAvailableIndex: ((previousState.itemsData.length === 0 ? -itemsData.length + 1 : previousState.firstAvailableIndex - itemsData.length)),
          reachedBeginning: !hasMore
        };
      });
    } else {
      // make sure to update the state here also - otherwise we will endlessly requesting new data
      this.setState({reachedBeginning: !hasMore});
    }

    this.log('Received data for ' + itemsData.length + ' items for the beginning, available items span from ' + this.state.firstAvailableIndex + ' to ' + this.state.lastAvailableIndex
      + (this.state.reachedBeginning ? ' - the beginning has been reached' : ' - there are still more data'));
    this.removePendingRequest(request);
    return true;
  }

  private async requestItemsData(): Promise<boolean[]> {
    return Promise.all([
      this.requestItemsDataForAppending(),
      this.requestItemsDataForPrepending()
    ]);
  }

  private renderMissingDataPlaceholder(): boolean {
    if (!this.props.renderMissingDataPlaceholder || this.renderedMissingDataPlaceholder) {
      return false;
    }
    const itemElement = this.props.renderMissingDataPlaceholder();
    if (!itemElement) {
      this.log('Failed to render missing data placeholder');
      return false;
    }
    const animatedOffset = new Animated.ValueXY(origin);
    const element = (
      <Animated.View key={'missingDataPlaceholder'} style={[styles.itemContainer, animatedOffset.getLayout()]}>
        {itemElement}
      </Animated.View>
    );
    this.renderedMissingDataPlaceholder = {element, index: Infinity, offset: 0, animatedOffset, visible: true};
    return true;
  }

  private renderLoadingIndicators(): boolean {
    // skip if loading indicators are disabled or they have already been rendered
    if (!this.props.renderLoadingIndicator || this.firstRenderedLoadingIndicator || this.lastRenderedLoadingIndicator) {
      return false;
    }
    const renderLoadingIndicator = (key: string): NitroxListRenderedItem | undefined => {
      const itemElement = this.props.renderLoadingIndicator && this.props.renderLoadingIndicator();
      if (!itemElement) {
        this.log('Failed to render loading indicator ' + key);
        return undefined;
      }
      const animatedOffset = new Animated.ValueXY(origin);
      const element = (
        <Animated.View key={key} style={[styles.itemContainer, animatedOffset.getLayout()]}>
          {itemElement}
        </Animated.View>
      );
      return {element, index: Infinity, offset: 0, animatedOffset, visible: true};
    };

    this.log('Rendering loading indicators');
    this.firstRenderedLoadingIndicator = renderLoadingIndicator('firstLoadingIndicator');
    this.lastRenderedLoadingIndicator = renderLoadingIndicator('lastLoadingIndicator');
    return true;
  }

  private invalidateRenderedItems(): void {
    this.log('Invalidating ' + this.renderedItems.length + ' rendered items');
    this.focusedIndexOverflow = 0; // we have new data items therefore we can ignore accumulated index overflow
    this.renderedItems.forEach((renderedItem: NitroxListRenderedItem): void => {
      renderedItem.index = Infinity;
    });
  }

  // renders all required items - returns true if new rendered items had to be created and therefore a relayout is required
  private renderItems(): boolean {
    if (this.state.firstAvailableIndex === this.state.lastAvailableIndex) {
      this.log('There are no available items data to be rendered');
      return false;
    }
    // a range of items data indices that should be visible are based around current focused index
    const indicesDelta = Math.floor(this.minAvailableRenderedItems / 2);
    const firstVisibleIndex = Math.max(this.state.firstAvailableIndex, this.focusedIndex - indicesDelta);
    const lastVisibleIndex = Math.min(this.state.lastAvailableIndex, this.focusedIndex + indicesDelta);
    this.log('Rendering items from ' + firstVisibleIndex + ' to ' + lastVisibleIndex + ' - current focused index is ' + this.focusedIndex
      + ' with first ' + this.state.firstAvailableIndex + ' and last ' + this.state.lastAvailableIndex + ' available indices');

    // find already rendered items and keys that are still valid and can be reused and items indices for which we should render new ones
    const renderedItems: NitroxListRenderedItem[] = [];
    const renderedKeys = new Map<string | number | null, boolean>();
    const missingIndices: number[] = [];
    for (let index = firstVisibleIndex; index <= lastVisibleIndex; ++index) {
      const renderedItem = this.renderedItems.find((renderedItem: NitroxListRenderedItem) => renderedItem.index === index);
      if (renderedItem) {
        this.log('Found rendered item for index ' + index + ' and key ' + renderedItem.element.key);
        renderedItems.push(renderedItem);
        renderedKeys.set(renderedItem.element.key, true);
      } else {
        missingIndices.push(index);
      }
    }

    if (missingIndices.length === 0) {
      this.log('There are no missing items that need to be rendered');
      return false;
    }

    // render elements for items that we are missing
    let changed = false;
    for (const index of missingIndices) {
      const itemData = this.getItemData(index);
      if (!itemData) {
        this.log('There is no data available to render item for index ' + index);
        continue;
      }

      // make sure not to duplicate any keys
      const key = this.props.keyExtractor(itemData, index);
      this.log('Rendering item for index ' + index + ' with key ' + key);
      if (renderedKeys.has(key)) {
        Log.error(TAG, 'Found duplicated key ' + key + ' for index ' + index);
        continue;
      }

      // render the item at initial position because at this point we are still unaware of the complete layout
      const itemElement = this.props.renderItem(itemData, index);
      if (!itemElement) {
        this.log('Failed to render element for item with index ' + index);
        continue;
      }

      const animatedOffset = new Animated.ValueXY(origin);
      const element = (
        <Animated.View key={key} style={[styles.itemContainer, animatedOffset.getLayout()]}>
          {itemElement}
        </Animated.View>
      );
      renderedItems.push({element, index, offset: 0, animatedOffset, visible: true});
      renderedKeys.set(key, true);
      changed = true;
    }

    // make sure that we present the rendered items in the correct order
    this.renderedItems = renderedItems.sort((left, right) => left.index < right.index ? -1 : 1);
    return changed;
  }

  private adjustRenderedItemsVisibility(): boolean {
    // adjust loading indicators visibility first
    let changed = false;
    if (this.firstRenderedLoadingIndicator) {
      const visible = ((this.state.itemsData.length === 0 && !this.state.reachedBeginning && !this.state.reachedEnd) || // we are waiting for initial items data
        (this.state.itemsData.length > 0 && !this.state.reachedBeginning)); // there are still some data for the beginning of the list
      changed = changed || this.firstRenderedLoadingIndicator.visible !== visible;
      this.firstRenderedLoadingIndicator.visible = visible;
      this.log('First loading indicator is visible ' + visible);
    }
    if (this.lastRenderedLoadingIndicator) {
      const visible = this.state.itemsData.length > 0 && !this.state.reachedEnd; // there are still some data for the end of the list
      changed = changed || this.lastRenderedLoadingIndicator.visible !== visible;
      this.lastRenderedLoadingIndicator.visible = visible;
      this.log('Last loading indicator is visible ' + visible);
    }

    // adjust missing data placeholder
    if (this.renderedMissingDataPlaceholder) {
      const visible = this.state.itemsData.length === 0 && this.state.reachedBeginning && this.state.reachedEnd; // we have already tried to fetch all available data but there are none
      changed = changed || this.renderedMissingDataPlaceholder.visible !== visible;
      this.renderedMissingDataPlaceholder.visible = visible;
      this.log('Missing data placeholder is visible ' + visible);
    }

    return changed;
  }

  private getRenderedItems(): NitroxListRenderedItem[] {
    // because some components are rendered in different moments in time we need to combine all of them before proceeding with layouting
    const renderedItems = [...this.renderedItems];

    // add loading indicators
    if (this.firstRenderedLoadingIndicator) {
      renderedItems.unshift(this.firstRenderedLoadingIndicator);
    }
    if (this.lastRenderedLoadingIndicator) {
      renderedItems.push(this.lastRenderedLoadingIndicator);
    }

    // add missing data placeholder here - it doesn't matter where we put it
    if (this.renderedMissingDataPlaceholder) {
      renderedItems.push(this.renderedMissingDataPlaceholder);
    }

    return renderedItems;
  }

  private findRenderedItemToCenter(): NitroxListRenderedItem | undefined {
    // handle index overflow by pointing to one of the loading indicators
    if (this.focusedIndexOverflow > 0 && this.props.moveViewportOverLoadingIndicators && this.lastRenderedLoadingIndicator) {
      return this.lastRenderedLoadingIndicator;
    }
    if (this.focusedIndexOverflow < 0 && this.props.moveViewportOverLoadingIndicators && this.firstRenderedLoadingIndicator) {
      return this.firstRenderedLoadingIndicator;
    }

    // if not then try to find rendered item that holds currently focused item data
    return this.renderedItems.find(renderedItem => renderedItem.index === this.focusedIndex);
  }

  private findLayoutForRenderedItem(renderedItem: NitroxListRenderedItem): NitroxListItemLayout | undefined {
    // hidden rendered items always have zero dimensions in order to trigger their relayout
    if (!renderedItem.visible) {
      return {width: 0, height: 0};
    }

    // handle loading indicators
    if (renderedItem === this.firstRenderedLoadingIndicator || renderedItem === this.lastRenderedLoadingIndicator) {
      return this.props.layoutLoadingIndicator && this.props.layoutLoadingIndicator();
    }

    // handle missing data placeholder
    if (renderedItem === this.renderedMissingDataPlaceholder) {
      return this.props.layoutMissingDataPlaceholder && this.props.layoutMissingDataPlaceholder();
    }

    // if not then use generic item layouting method
    const itemData = this.getItemData(renderedItem.index);
    if (!itemData) {
      this.log('There is no data available to compute the layout for index ' + renderedItem.index);
      return undefined;
    }
    return this.props.layoutItem(itemData, renderedItem.index);
  }

  private layoutRenderedItems(): void {
    const renderedItems = this.getRenderedItems();
    const layoutAnimations: Animated.CompositeAnimation[] = [];

    // hidden rendered items are moved outside the visible portion of the viewport
    let accumulatedOffset = 0;
    const defaultHiddenOffset = 4000; //until we are unaware of the container dimensions this offset will be used to hide the items
    const computeAnimationsOffset = (renderedItem: NitroxListRenderedItem) => {
      const animatedOffset = {x: 0, y: 0};
      if (this.props.horizontal) {
        animatedOffset.x = accumulatedOffset;
        animatedOffset.y = renderedItem.visible ? 0 : -Math.max(this.containerHeight, defaultHiddenOffset);
      } else {
        animatedOffset.x = renderedItem.visible ? 0 : -Math.max(this.containerWidth, defaultHiddenOffset);
        animatedOffset.y = accumulatedOffset;
      }
      layoutAnimations.push(Animated.timing(renderedItem.animatedOffset, {
        toValue: animatedOffset,
        duration: 0
      }));
      return animatedOffset;
    };

    const isLayoutRequired = (renderedItem: NitroxListRenderedItem, itemLayout: NitroxListItemLayout): boolean => {
      return !renderedItem.layout || renderedItem.offset !== accumulatedOffset ||
        renderedItem.layout && (renderedItem.layout.width !== itemLayout.width || renderedItem.layout.height !== itemLayout.height);
    };

    // compute a series of layout animations for each rendered item
    for (const renderedItem of renderedItems) {
      const itemLayout = this.findLayoutForRenderedItem(renderedItem);
      if (!itemLayout) {
        this.log('There is no layout available rendered item with index ' + renderedItem.index);
        continue;
      }
      accumulatedOffset += itemLayout.marginBefore || 0;

      this.log('Computing layout for rendered item for index ' + renderedItem.index + ' at position ' + accumulatedOffset);
      if (isLayoutRequired(renderedItem, itemLayout)) {
        layoutAnimations.push(Animated.timing(renderedItem.animatedOffset, {
          toValue: computeAnimationsOffset(renderedItem),
          duration: 0
        }));
      }

      renderedItem.offset = accumulatedOffset;
      renderedItem.layout = itemLayout;

      accumulatedOffset += (this.props.horizontal ? itemLayout.width : itemLayout.height) + (itemLayout.marginAfter || 0);
    }

    // now we can start the layouting as a single composite animation - this is a separate step from animating the main container's offset
    this.log('Layouting ' + layoutAnimations.length + ' rendered items');
    Animated.parallel(layoutAnimations).start();
  }

  private adjustViewport(duration: number): void {
    // skip viewport adjustment when there is nothing to adjust to
    const renderedItem = this.findRenderedItemToCenter();
    if (!renderedItem || !renderedItem.layout) {
      this.log('Unable to adjust the viewport - rendered item that should be center inside the viewport is missing');
      return;
    }

    // compute new viewport offset based on currently focused rendered item
    const minViewportOffset = this.minViewportOffset || 0;
    const itemRelativeOffset = this.viewportOffset + renderedItem.offset;
    let viewportOffset = this.viewportOffset;
    if (this.props.horizontal) {
      const maxViewportOffset = typeof this.maxViewportOffset === 'number' ? this.maxViewportOffset : (this.containerWidth > 0 ? this.containerWidth - 1 : 0);
      const viewportWidth = maxViewportOffset - minViewportOffset + 1;
      if (renderedItem.layout.width > viewportWidth) {
        // focused rendered item is bigger than the viewport - we need to align it at the center of the viewport
        viewportOffset += (minViewportOffset + Math.floor(viewportWidth / 2)) - (itemRelativeOffset + Math.floor(renderedItem.layout.width / 2));
      } else {
        // move the viewport to make focused rendered item visible again
        if (itemRelativeOffset < minViewportOffset) {
          viewportOffset += minViewportOffset - itemRelativeOffset;
        }
        const itemRelativeRight = itemRelativeOffset + renderedItem.layout.width + (renderedItem.layout.marginAfter || 0);
        if (itemRelativeRight > maxViewportOffset) {
          viewportOffset -= itemRelativeRight - maxViewportOffset;
        }
      }
    } else {
      const maxViewportOffset = typeof this.maxViewportOffset === 'number' ? this.maxViewportOffset : (this.containerHeight > 0 ? this.containerHeight - 1 : 0);
      const viewportHeight = maxViewportOffset - minViewportOffset + 1;
      if (renderedItem.layout.height > viewportHeight) {
        // focused rendered item is bigger than the viewport - we need to align it at the center of the viewport
        viewportOffset += (minViewportOffset + Math.floor(viewportHeight / 2)) - (itemRelativeOffset + Math.floor(renderedItem.layout.height / 2));
      } else {
        // move the viewport to make focused rendered item visible again
        if (itemRelativeOffset < minViewportOffset) {
          viewportOffset += minViewportOffset - itemRelativeOffset;
        }
        const itemRelativeBottom = itemRelativeOffset + renderedItem.layout.height + (renderedItem.layout.marginAfter || 0);
        if (itemRelativeBottom > maxViewportOffset) {
          viewportOffset -= itemRelativeBottom - maxViewportOffset;
        }
      }
    }

    // execute the animation to apply new viewport offset only if it has changed
    if (viewportOffset === this.viewportOffset) {
      this.log('There is no need to adjust the viewport to ' + viewportOffset);
      return;
    }

    this.log('Adjusting viewport to ' + viewportOffset);
    this.viewportOffset = viewportOffset;
    Animated.timing(this.viewportAnimatedOffset, {
      toValue: {
        x: (this.props.horizontal ? viewportOffset : 0),
        y: (!this.props.horizontal ? viewportOffset : 0)
      },
      duration: duration,
      // tvos has problems with transform styling, so fallback to top/left JS thread animation
      useNativeDriver: !isTVOS // send this directly to native driver to make sure to not block this animtion in JS thread
    }).start(() => {
      this.log('Viewport adjusted to ' + viewportOffset);
      if (this.props.onViewportAdjusted) {
        this.props.onViewportAdjusted();
      }
    });
  }

  private onContainerLayoutChanged = (event: LayoutChangeEvent): void => {
    if (!this.containerRef.current) {
      return;
    }
    this.containerRef.current.measure((x, y, width, height, pageX, pageY) => {
      // react will report 0s for hidden components' dimensions - just ignore this
      if (width === 0 || height === 0) {
        return;
      }
      this.log('Acquired container dimensions ' + width + 'x' + height);
      this.containerWidth = width;
      this.containerHeight = height;
      if (this.props.onContainerLayoutChanged) {
        this.props.onContainerLayoutChanged(width, height);
      }
    });
  }

  private log(message: string): void {
    if (this.props.enableDebugLogs) {
      const now = new Date();
      Log.debug(TAG, `[${now.getMinutes()}:${now.getSeconds()}:${now.getMilliseconds()}]` + (this.props.debugName ? '[' + this.props.debugName + '] ' : '') + message);
    }
  }

  public componentDidMount(): void {
    this.renderMissingDataPlaceholder();
    this.refreshLoadingIndicators();
    if (this.props.refreshOnMount !== false) {
      this.refresh();
    }
  }

  public shouldComponentUpdate(nextProps: NitroxListProps<ItemData>, nextState: NitroxListState<ItemData>): boolean {
    const shouldUpdate = this.renderedItemsChanged || this.visibilityChanged;

    if (shouldUpdate) {
      this.log(`Updating component renderedItemsChanged=${this.renderedItemsChanged}, visibilityChanged=${this.visibilityChanged}`);
      this.renderedItemsChanged = false;
      this.visibilityChanged = false;
    }

    return shouldUpdate;
  }

  public componentDidUpdate(): void {
    this.log('Scheduling refresh due to component update');
    this.scheduleRefresh();
  }

  public componentWillUnmount(): void {
    this.scheduleRefresh.abort();
  }

  public render(): JSX.Element {
    this.log('Rendering the entire container');
    const renderedElements = this.getRenderedItems().map(renderedItem => renderedItem && renderedItem.element);
    return (
      // we need to pack animated view into regular view in order the be able to measure it's dimensions and clip it's content
      <View
        ref={this.containerRef}
        style={[styles.container, this.props.containerStyle]}
        onLayout={this.onContainerLayoutChanged}
      >
        <Animated.View style={[styles.viewport, isWeb || isTVOS ? this.viewportAnimatedOffset.getLayout() : this.viewportAnimatedOffset.getTranslateTransform()]}>
          {renderedElements}
        </Animated.View>
      </View>
    );
  }
}
