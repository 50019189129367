import {findDOMNode} from 'react-dom';

import {Direction, isATV, isWeb} from 'common/constants';
import {Point, Rect} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {isFocusParentNode, FocusNode, NodeGeometry, OrthogonalDirection} from './FocusManagerTypes';

const TAG = 'Geometry';

function verticalDistanceCalculator(lhs: Point, rhs: Point): number {
  return Math.abs(lhs.y - rhs.y);
}

function horizontalDistanceCalculator(lhs: Point, rhs: Point): number {
  return Math.abs(lhs.x - rhs.x);
}

function calculateCenter(geometry: Rect): Point {
  return {
    x: geometry.x + geometry.width / 2,
    y: geometry.y + geometry.height / 2
  };
}

export function calculateMetricBetweenPoints(a: Point, b: Point = {x: 0, y: 0}): number {
  const dx = Math.abs(a.x - b.x);
  const dy = Math.abs(a.y - b.y);
  return dx * dx + dy * dy; // There's no need for exact distance, so let's drop expensive sqrt
}

// TODO: Move to the web specific FocusManager implementation
function getElementRect(node: Element): Rect {
  const {width, height, top, left} = node.getBoundingClientRect();
  return {width, height, y: top, x: left};
}

// TODO: Move to the web specific FocusManager implementation
export function findDomElement(component: React.Component): Element | null {
  // eslint-disable-next-line react/no-find-dom-node
  const element = findDOMNode(component);
  return element instanceof Element
    ? element
    : null;
}

// TODO: Split into platform specific FocusManager implementations
export async function getGeometry(node: FocusNode): Promise<NodeGeometry | null> {
  const component = isFocusParentNode(node)
    ? node.ref.current
    : node.current;
  if (!component) {
    return null;
  }
  if (isATV) {
    return new Promise<NodeGeometry | null>(resolve => {
      component.measure((x: number, y: number, width: number, height: number, pageX: number, pageY: number) => {
        if (x == null || y == null || width == null || height == null || pageX == null || pageY == null) {
          Log.error(TAG, 'getGeometry: measure returned undefined values');
          return resolve(null);
        }
        resolve({
          x: pageX,
          y: pageY,
          width,
          height,
          center: {
            x: pageX + width / 2,
            y: pageY + height / 2
          }
        });
      });
    });
  }
  if (isWeb) {
    const element = findDomElement(component);
    if (!element) {
      return null;
    }
    const geometry: Rect = getElementRect(element);
    return {
      ...geometry,
      center: calculateCenter(geometry)
    };
  }
  return null;
}

export function orthogonalDirection(direction: Direction): OrthogonalDirection {
  return direction === Direction.Up || direction === Direction.Down ? 'horizontal' : 'vertical';
}

export function directionalDistanceCalculator(direction: OrthogonalDirection): (lhs: Point, rhs: Point) => number {
  switch (direction) {
    case 'vertical':
      return verticalDistanceCalculator;
    case 'horizontal':
      return horizontalDistanceCalculator;
  }
}

export function frameDistanceCalculator(direction: Direction): (origin: Point, component: NodeGeometry) => number {
  switch (direction) {
    case Direction.Up:
      return (origin, component) => origin.y - (component.y + component.height);
    case Direction.Down:
      return (origin, component) => component.y - origin.y;
    case Direction.Left:
      return (origin, component) => origin.x - (component.x + component.width);
    case Direction.Right:
      return (origin, component) => component.x - origin.x;
  }
  throw new Error(`Unsupported direction ${direction}`);
}
