import {createStyles} from 'common-styles';
import React, {useMemo, useCallback} from 'react';
import {Insets} from 'react-native';

import {Direction, isMobile} from 'common/constants';
import {doNothing} from 'common/HelperFunctions';

import {constColors} from 'common-styles/variables/base-colors';

import ConditionalWrapper from 'components/ConditionalWrapper';
import GestureRecognizerView from 'components/gestures/GestureRecognizerView';
import {PageNavigatorProps} from 'components/PageNavigatorProps';

const defaultPreviousPageOn = [Direction.Up, Direction.Left];
const defaultNextPageOn = [Direction.Down, Direction.Right];

const staticStyles = createStyles({
  gestureRecognizer: {
    flex: 1,
    backgroundColor: constColors.transparent
  }
});

type GesturePageNavigatorProps = {
  previousPageOn?: Direction[];
  nextPageOn?: Direction[];
  gestureRecognitionInsets?: Insets;
} & PageNavigatorProps;

const GesturePageNavigator: React.FunctionComponent<GesturePageNavigatorProps> = ({
  previousPageOn = defaultPreviousPageOn,
  nextPageOn = defaultNextPageOn,
  gestureRecognitionInsets,
  pageIndex,
  numberOfPages,
  onPageSelected,
  ...props
}) => {

  const directions = useMemo(() => nextPageOn.concat(previousPageOn), [nextPageOn, previousPageOn]);

  const selectPreviousPage = useCallback(() => {
    onPageSelected?.(Math.max(pageIndex - 1, 0));
  }, [pageIndex, onPageSelected]);

  const selectNextPage = useCallback(() => {
    onPageSelected?.(Math.min(pageIndex + 1, numberOfPages - 1));
  }, [pageIndex, numberOfPages, onPageSelected]);

  const [onSwipeUp, onSwipeDown, onSwipeLeft, onSwipeRight] = useMemo(() => {
    const findPageSelector = (direction: Direction) => (
      previousPageOn.includes(direction)
        ? selectPreviousPage
        : nextPageOn.includes(direction)
          ? selectNextPage
          : doNothing
    );
    return [Direction.Up, Direction.Down, Direction.Left, Direction.Right].map(findPageSelector);
  }, [selectPreviousPage, selectNextPage, previousPageOn, nextPageOn]);

  return (
    <ConditionalWrapper
      condition={isMobile && numberOfPages > 1}
      wrapper={children => (
        <GestureRecognizerView
          style={staticStyles.gestureRecognizer}
          directions={directions}
          gestureRecognitionInsets={gestureRecognitionInsets}
          becomeResponderOnTouchStart
          onSwipeUp={onSwipeUp}
          onSwipeDown={onSwipeDown}
          onSwipeLeft={onSwipeLeft}
          onSwipeRight={onSwipeRight}
        >
          {children}
        </GestureRecognizerView>
      )}
    >
      {props.children}
    </ConditionalWrapper>
  );
};

export default GesturePageNavigator;
