import {createStyles} from 'common-styles';
import React from 'react';
import {View} from 'react-native';

import {dimensions, isMobile} from 'common/constants';

import {StylesUpdater, useStyles} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxText from 'components/NitroxText';

import SettingsLabel from './SettingsLabel';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    alignSelf: 'stretch',
    paddingHorizontal: isMobile ? dimensions.margins.xxLarge : undefined,
    marginBottom: dimensions.margins.xxxLarge,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  value: {
    color: colors.settingsScreen.mobile.profile.clickableOption.title,
    alignSelf: 'center'
  }
}));

type Props = {
  title: string;
  value: string;
}

const SettingsSimpleOption: React.FC<Props> = props => {
  const {title, value} = props;
  const styles = useStyles(stylesUpdater);

  return (
    <View style={styles.container}>
      <SettingsLabel label={title} />
      <NitroxText style={styles.value} textType='body1'>{value}</NitroxText>
    </View>
  );
};

export default SettingsSimpleOption;
