import {defaultStyles, createStyles} from 'common-styles';
import React, {useMemo, useCallback, useState, useEffect} from 'react';
import {View, ActivityIndicator, StyleProp, ViewStyle} from 'react-native';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {NitroxButtonTheme} from 'components/NitroxButton';
import NitroxText from 'components/NitroxText';

import ButtonsRow from './ButtonsRow';
import FocusParent from './FocusParent';
import Popup from './Popup';
import {SelectionPopupProps} from './SelectionPopup';

const TAG = 'SelectionPopup';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    ...defaultStyles.popup,
    backgroundColor: colors.popup.background
  },
  column: {
    flexShrink: 1,
    flexDirection: 'column'
  },
  info: {
    color: colors.popup.text,
    marginBottom: dimensions.margins.small,
    textAlign: 'left'
  },
  buttonsSeparator: {
    marginBottom: dimensions.margins.xxLarge,
    backgroundColor: colors.popup.buttonSeparator
  },
  activityIndicatorStyle: {
    marginBottom: dimensions.margins.xxxLarge
  }
}));

const buttonMaxWidth = Math.floor(dimensions.popup.width / 2);

const SelectionPopup: React.FunctionComponent<SelectionPopupProps> = props => {
  const {
    onClose,
    sections,
    focusedKey: focusedKeyFromProps,
    title,
    info,
    popupProps,
    columnStyle,
    buttonHeight = dimensions.popup.button.height,
    buttonWidth = dimensions.popup.button.width,
    buttonMargin = dimensions.margins.small,
    buttonTextStyle,
    centerFirstRow,
    shouldWaitForButtons = true,
    columnCount
  } = props;

  const [buttonsReady, setButtonsReady] = useState(false);
  useEffect(() => {
    if (!props.visible) {
      setButtonsReady(false);
    }
  }, [props.visible]);

  const onCloseHandler = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  const sectionsData = useMemo(() => {
    return sections.map((section, index) => ({
      options: section.options.map(option => ({
        border: true,
        isSelected: option.selected || option.key === focusedKeyFromProps,
        theme: NitroxButtonTheme.Primary,
        textStyle: buttonTextStyle,
        hasTvPreferredFocus: option.key === focusedKeyFromProps,
        ...option
      })),
      id: `${index}`,
      title: section.title || '',
      separator: section.separator || false,
      center: section.center,
      containerStyle: section.containerStyle || {}
    }));
  }, [sections, focusedKeyFromProps, buttonTextStyle]);

  let gridProps = {};
  let buttonStyle: StyleProp<ViewStyle>;
  if (typeof columnCount !== 'undefined' && typeof buttonWidth !== 'undefined') {
    gridProps = {
      columns: columnCount,
      buttonWidth
    };
  } else {
    buttonStyle = {
      marginRight: buttonMargin,
      marginBottom: buttonMargin,
      minWidth: buttonWidth
    };
  }

  const styles = stylesUpdater.getStyles();
  return (
    <Popup
      visible={props.visible}
      containerStyle={shouldWaitForButtons && !buttonsReady ? {opacity: 0} : undefined}
      title={props.loading ? '' : title}
      onClose={onCloseHandler}
      {...popupProps}
      menuHasPreferredFocus={!focusedKeyFromProps}
    >
      {!props.loading && (
        <FocusParent>
          <View style={[styles.column, columnStyle]}>
            {info && <NitroxText textType='callout' style={styles.info}>{info}</NitroxText>}
            {/* consider using ScrollView here for bigger datasets */}
            {sectionsData.map(section => (
              <FocusParent
                key={`${TAG}-sectionView-${section.id}`}
                rememberLastFocused
              >
                {!!section.title && <NitroxText textType='callout' style={[styles.info, {textAlign: 'left'}]}>{section.title}</NitroxText>}
                <ButtonsRow
                  data={section.options}
                  buttonHeight={buttonHeight}
                  buttonMaxWidth={buttonMaxWidth}
                  xMargin={buttonMargin * 2}
                  yMargin={buttonMargin}
                  containerStyle={[{
                    marginTop: buttonMargin,
                    marginBottom: buttonMargin + dimensions.margins.xxLarge,
                    marginLeft: buttonMargin,
                    justifyContent: section.center ? 'center' : undefined
                  }, section.containerStyle]}
                  buttonStyle={buttonStyle}
                  onButtonsReady={setButtonsReady}
                  centerFirstRow={centerFirstRow}
                  {...gridProps}
                >
                </ButtonsRow>
              </FocusParent>
            )
            )}
          </View>
          {props.children}
        </FocusParent>
      )}
      {props.loading &&
        <ActivityIndicator style={[styles.activityIndicatorStyle, props.activityIndicatorStyle]} />
      }
    </Popup>
  );
};

export default React.memo(SelectionPopup);
