/* eslint-disable no-restricted-syntax */
import {i18nUtilsType} from './i18nUtils';

//Map generated based on https://wordpress.org/plugins/remove-uppercase-accents/#description
const tonesMap = {
  'ΆΙ': 'ΑΪ',
  'ΆΥ': 'ΑΫ',
  'ΈΙ': 'ΕΪ',
  'ΌΙ': 'ΟΪ',
  'ΈΥ': 'ΕΫ',
  'ΌΥ': 'ΟΫ',
  'άι': 'αϊ',
  'έι': 'εϊ',
  'Άυ': 'αϋ',
  'άυ': 'αϋ',
  'όι': 'οϊ',
  'Έυ': 'εϋ',
  'έυ': 'εϋ',
  'όυ': 'οϋ',
  'Όυ': 'οϋ',
  'Ά': 'Α',
  'ά': 'α',
  'Έ': 'Ε',
  'έ': 'ε',
  'Ή': 'Η',
  'ή': 'η',
  'Ί': 'Ι',
  'Ϊ': 'Ι',
  'ί': 'ι',
  'ϊ': 'ι',
  'ΐ': 'ϊ',
  'Ό': 'Ο',
  'ό': 'ο',
  'Ύ': 'Υ',
  'Ϋ': 'Υ',
  'ύ': 'υ',
  'ϋ': 'υ',
  'ΰ': 'ϋ',
  'Ώ': 'Ω',
  'ώ': 'ω'
};

function stripTones(text: string): string {
  return Object.entries(tonesMap).reduce((formattedText, [key, value]) => {
    const matcher = new RegExp(key, 'g');
    return formattedText.replace(matcher, value);
  }, text);
}
const toUpperCase = (text: string) => String.prototype.toUpperCase.call(stripTones(text));
const capitalize = (text: string) => `${toUpperCase(text.charAt(0))}${text.slice(1)}`;

const elUtils: i18nUtilsType = {
  toUpperCase: toUpperCase,
  capitalize: capitalize
};

export default elUtils;
