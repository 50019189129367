import {MediaPlayerClass} from 'dashjs';
import videojs, {VideoJsPlayer, VideoJsPlayerOptions} from 'video.js';
import 'video.js/dist/video-js.css'; // use to style video tag as video-js ( do not remove )
import 'videojs-contrib-dash';

import {DateUtils} from 'common/DateUtils';
import {EventEmitter} from 'common/EventEmitter';
import {isBrowser} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {Error, Errors, ErrorType} from 'mw/api/Error';
import {ContentType} from 'mw/api/Metadata';
import {Track} from 'mw/api/PlayerEvent';
import {DrmInterceptor} from 'mw/playback/web-native-player/nxpal/DrmInterceptor';
import {NitroxPlayer} from 'mw/playback/web-native-player/nxpal/NitroxPlayer';
import {NitroxPlayerEvents, NitroxPlayerEventHandler} from 'mw/playback/web-native-player/nxpal/NitroxPlayerEventHandler';
import {NitroxPlayerEventNotifier} from 'mw/playback/web-native-player/nxpal/NitroxPlayerEventNotifier';
import {StreamPosition} from 'mw/playback/web-native-player/nxpal/StreamPosition';
import {StreamTypeProvider} from 'mw/playback/web-native-player/StreamTypeProvider';
import {PlayerView} from 'mw/playback/web-native-player/types';

//TODO: CL-6055 these types are added as Typescript 3.9.6 now misses these interfaces
interface AudioTrackListEventMap {
  'addtrack': TrackEvent;
  'change': Event;
  'removetrack': TrackEvent;
}

//TODO: CL-6055 these types are added as Typescript 3.9.6 now misses these interfaces
interface AudioTrackList extends EventTarget {
  readonly length: number;
  onaddtrack: ((this: AudioTrackList, ev: TrackEvent) => any) | null;
  onchange: ((this: AudioTrackList, ev: Event) => any) | null;
  onremovetrack: ((this: AudioTrackList, ev: TrackEvent) => any) | null;
  getTrackById(id: string): videojs.VideojsAudioTrack | null;
  item(index: number): videojs.VideojsAudioTrack;
  addEventListener<K extends keyof AudioTrackListEventMap>(type: K, listener: (this: AudioTrackList, ev: AudioTrackListEventMap[K]) => any, options?: boolean | AddEventListenerOptions): void;
  addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void;
  removeEventListener<K extends keyof AudioTrackListEventMap>(type: K, listener: (this: AudioTrackList, ev: AudioTrackListEventMap[K]) => any, options?: boolean | EventListenerOptions): void;
  removeEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void;
  [index: number]: videojs.VideojsAudioTrack;
}

//TODO: CL-6055 these types are added as Typescript 3.9.6 now misses these interfaces
declare const AudioTrackList: {
  prototype: AudioTrackList;
  new(): AudioTrackList;
};

const TAG = 'VideojsNitroxPlayer';

// based on https://docs.videojs.com/texttrack#~Kind
enum TextTrackKind {
  Subtitles = 'subtitles',
  Captions = 'captions',
  Descriptions = 'descriptions',
  Chapters = 'chapters',
  Metadata = 'metadata'
}

enum TrackListEvent {
  Add = 'addtrack',
  Change = 'change',
  Remove = 'removetrack'
}

// Safety buffer to be added to the beginning of the NPLTV playlist.
// It results from differences in refreshing the beginning of the playlist and the current position.
const defaultNPLTVBeginOfStreamOffset = 5;

let defaultNPLTVEndOfStreamOffset: number | null;

/* Interface to type data not typed at VideoJsPlayer
 */
export interface VjsWithHttpStreaming extends VideoJsPlayer {
  audioTracks: () => AudioTrackList;
  dash?: { //mediaPlayer
    mediaPlayer: MediaPlayerClass;
  };
  vhs?: { // HlsHandler from https://github.com/videojs/videojs-contrib-hls/blob/master/src/videojs-contrib-hls.js
    playlists: { // PlaylistLoader
      media(): { // Playlist
        dateTimeString?: number;
        dateTimeObject?: Date;
      };
    };
  };
  /**
   * Initialize videojs-contrib-eme plugin required for FairPlay DRM.
   */
  eme?: () => void;
}

interface TechWithPlayerProps extends videojs.Tech {
  sourceHandler_?: {
    mediaPlayer_?: { // mediaPlayer
      mediaPlayer: MediaPlayerClass;
    };
  };
  hls?: {// HlsHandler from https://github.com/videojs/videojs-contrib-hls/blob/master/src/videojs-contrib-hls.js
    playlists: { // PlaylistLoader
      media(): { // Playlist
        dateTimeString?: number;
        dateTimeObject?: Date;
      };
    };
  };
}

export abstract class VideojsNitroxPlayer extends EventEmitter<NitroxPlayerEvents> implements NitroxPlayer {

  private player: VjsWithHttpStreaming | null = null;
  protected contentType: ContentType = ContentType.UNKNOWN;
  private sourceUrl = '';
  private notifier: NitroxPlayerEventNotifier = new NitroxPlayerEventNotifier();
  private audioTracks: Track[] = [];
  private currentAudioTrackIndex = -1;
  private subtitleTracks: Track[] = [];
  private currentSubtitleTrackIndex = -1;
  private playerView: PlayerView;
  protected drmInterceptor: DrmInterceptor | null = null;
  private initialPosition?: number;
  private lowestAllowedPosition?: number;

  private onLoadedMetadata = () => {
    // Restart Live TV event workaround
    if (typeof this.initialPosition !== 'undefined') {
      this.setPosition(this.initialPosition);
      this.initialPosition = undefined;
    }
  };

  public constructor(playerView: PlayerView) {
    super();

    this.playerView = playerView;

    const prepareOptions: VideoJsPlayerOptions = {
      controls: false,
      controlBar: false,
      loop: false,
      preload: 'auto',
      html5: {
        nativeAudioTracks: false,
        nativeVideoTracks: false,
        hls: {
          overrideNative: !isBrowser('safari') // https://github.com/videojs/http-streaming#overridenative
        }
      },
      /*
       Below line is intended for disabling loadingSpinner child.
       It enables only used child components, so other will be disabled (including loadingSpinner), see https://docs.videojs.com/tutorial-options.html#component-options
       */
      children: ['liveTracker']
    };
    this.player = videojs(document.querySelector(`#${playerView.playerViewId}`), prepareOptions, () => {
      this.notifier.onReady();
    }) as VjsWithHttpStreaming || null;
  }

  protected getPlayer(): VjsWithHttpStreaming {
    if (!this.player) {
      Log.error(TAG, 'no player');
      throw Errors.NativePlayerPlayerNotFound;
    }
    return this.player;
  }

  // Access player's tech property in safe way
  protected playerTech(): TechWithPlayerProps | undefined {
    // according to https://github.com/videojs/video.js/issues/2617
    return this.getPlayer().tech({IWillNotUseThisInPlugins: true});
  }

  public init(): void {
    const player = this.getPlayer();
    player.on('play', this.notifier.onStart);
    player.on('pause', () => Log.debug(TAG, 'pause'));
    player.on('ended', this.notifier.onEos);
    player.on('abort', this.notifier.onEos);
    player.on('loadstart', this.notifier.onBos);

    player.on('loadedmetadata', this.onLoadedMetadata);
    player.on('canplay', this.notifier.onFirstFrame);
    player.on('waiting', this.notifier.onBuffering);
    player.audioTracks().addEventListener(TrackListEvent.Add, this.onAudioTracksChanged);
    player.audioTracks().addEventListener(TrackListEvent.Change, this.onAudioTracksChanged);
    player.audioTracks().addEventListener(TrackListEvent.Remove, this.onAudioTracksChanged);
    player.textTracks().addEventListener(TrackListEvent.Add, this.initialOnSubtitleTracksChanged);

    this.errorHandlingInit();
    this.drmInit();
  }

  public getSource(): string {
    return this.sourceUrl;
  }

  public setSource(url: string, contentType: ContentType): void {
    this.sourceUrl = url;
    this.contentType = contentType;

    let sourceOptions: any = {
      src: this.sourceUrl,
      type: StreamTypeProvider.getStreamType(url)
    };

    if (this.drmInterceptor) {
      sourceOptions = {
        ...sourceOptions,
        ...this.drmInterceptor.onDrmRequest()
      };
    }
    this.getPlayer().src(sourceOptions);
  }

  public setContentType(contentType: ContentType): void {
    if (this.contentType === ContentType.LIVE && contentType === ContentType.NPLTV) {
      defaultNPLTVEndOfStreamOffset = Math.max(Math.round(DateUtils.getSeconds(new Date())) - this.getPosition(), 0);
    }

    this.contentType = contentType;
  }

  public start(rate = 1.0, position?: number): void {
    this.initialPosition = position;
    this.setSpeed(rate);
  }

  public stop(): void {
    this.getPlayer().pause();
    this.notifier.onStop();
  }

  public release(): void {
    if (this.player) {
      /*
        This used to be chained, like: this.player.pause().dispose();
        But for some reason, despite docs saying it should return player instance
        pause() returns undefined which obviously caused error
        (which in this case got noticed as it prevented setting bookmark on VOD on web)
      */
      this.player.pause();
      this.player.off();
      this.player.audioTracks().removeEventListener(TrackListEvent.Add, this.onAudioTracksChanged);
      this.player.audioTracks().removeEventListener(TrackListEvent.Change, this.onAudioTracksChanged);
      this.player.audioTracks().removeEventListener(TrackListEvent.Remove, this.onAudioTracksChanged);
      this.player.textTracks().removeEventListener(TrackListEvent.Add, this.initialOnSubtitleTracksChanged);
      this.player.dispose();
      this.player = null;

      this.notifier.onStop();
      this.playerView.onRelease();
    }
    this.notifier.clear();
  }

  public setSpeed(speed: number): void {
    if (!this.player) {
      return;
    }

    if (speed === 0) {
      this.player.pause();
    } else {
      if (this.contentType === ContentType.NPLTV) {
        const {beginPosition, position} = this.getStreamPosition();
        // Resuming playback from outside of available buffer
        if (position < beginPosition) {
          this.setPosition(beginPosition);
        }
      }
      this.player.play();
    }

    this.player.playbackRate(speed);
  }

  public addEventHandler(handler: NitroxPlayerEventHandler): void {
    this.notifier.addEventHandler(handler);
  }

  public removeEventHandler(handler: NitroxPlayerEventHandler): void {
    this.notifier.removeEventHandler(handler);
  }

  // returns Infinity for Live playback and might return NaN and the beginning of VOD HLS on Android Chrome
  public getDuration(): number {
    return Math.round(this.getPlayer().duration());
  }

  public getStreamPosition(): StreamPosition {
    return {
      beginPosition: this.getSeekableBeginPosition(),
      endPosition: this.getSeekableEndPosition(),
      position: this.getPosition()
    };
  }

  public getPosition(): number {
    if (this.isLiveStream()) {
      Log.warn(TAG, 'getPosition: could not check time - using current date and time');
      return DateUtils.getSeconds(new Date());
    }

    // for non-live it returns a number of seconds from stream beginning
    return Math.round(this.getPlayer().currentTime());
  }

  /**
   * @param newPosition 'desired position in EPOCH' for Live/NPLTV or 'seconds from the beginning' for VOD
   */
  public setPosition(newPosition: number): void {
    const {beginPosition, endPosition, position: oldPosition} = this.getStreamPosition();

    newPosition = Math.min(newPosition, endPosition);
    if (newPosition < beginPosition) {
      newPosition = beginPosition;
      Log.info(TAG, `setPosition: desired position is lower than begin position, notify about BOS`);
      this.notifier.onBos();
    }
    const duration = this.getDuration();
    if (!isNaN(duration) && (duration !== Infinity) && (newPosition > duration)) {
      newPosition = duration;
      Log.info(TAG, `setPosition: position greater than duration (${duration}), go to the end of stream`);
    }

    if (this.isLiveStream()) {
      // LIVE and NPLTV
      // we set position since the beginning of a playlist
      const timeDiff = newPosition - oldPosition;
      const currentTime = this.getPlayer().currentTime();
      this.getPlayer().currentTime(currentTime + timeDiff);
    } else {
      // we set position since the beginning of a video
      this.getPlayer().currentTime(newPosition);
    }
  }

  protected getLiveStreamPlaylistEnd(): number | null {
    if (this.isLiveStream()) {
      Log.warn(TAG, 'getLiveStreamCurrentDate: the current live date of the live stream cannot be determined');
      return DateUtils.getSeconds(new Date());
    }

    return null;
  }

  // returns buffer length in seconds
  protected abstract getLiveWindow(): number;

  private getLowestAllowedPosition(): number | null {
    const lowestAllowedPosition = this.lowestAllowedPosition;
    const liveStreamCurrentDate = this.getLiveStreamPlaylistEnd();

    if (liveStreamCurrentDate) {
      const liveWindow = this.getLiveWindow();

      if (!isNaN(liveWindow)) {
        const beginPosition = liveStreamCurrentDate - Math.round(liveWindow) + defaultNPLTVBeginOfStreamOffset;
        return Math.max(lowestAllowedPosition || 0, beginPosition);
      }
      Log.warn(TAG, 'getLowestAllowedPosition: stream does not have the correct live window');
      return typeof lowestAllowedPosition != 'undefined' ? lowestAllowedPosition : null;
    }

    const seekable = this.getPlayer().seekable();
    if (seekable.length > 0) {
      return Math.max(lowestAllowedPosition || 0, seekable.start(0));
    }

    Log.warn(TAG, 'getLowestAllowedPosition: stream does not have the correct time ranges');
    return typeof lowestAllowedPosition != 'undefined' ? lowestAllowedPosition : null;
  }

  private getSeekableBeginPosition(): number {
    switch (this.contentType) {
      case ContentType.LIVE:
      case ContentType.NPLTV: {
        const lowestAllowedPosition = this.getLowestAllowedPosition();
        return lowestAllowedPosition ?? this.getPosition();
      }
      default: {
        return 0;
      }
    }
  }

  private getSeekableEndPosition(): number {
    switch (this.contentType) {
      case ContentType.LIVE: {
        return this.getPosition();
      }
      case ContentType.NPLTV: {
        if (defaultNPLTVEndOfStreamOffset) {
          return Math.round(DateUtils.getSeconds(new Date())) - defaultNPLTVEndOfStreamOffset;
        }
        const liveStreamPlaylistEnd = this.getLiveStreamPlaylistEnd();
        if (liveStreamPlaylistEnd) {
          return liveStreamPlaylistEnd;
        }
        Log.warn(TAG, 'getSeekableEndPosition: the current stream does not have the correct liveStreamPlaylistEnd');
        break;
      }
      default: {
        const seekable = this.getPlayer().seekable();
        const endRange = seekable.length - 1;
        if (endRange >= 0) {
          return seekable.end(endRange);
        }
        Log.warn(TAG, 'getSeekableEndPosition: the current stream does not have the correct time ranges');
        break;
      }
    }

    return 0;
  }

  public setPlaybackBoundaries(lowestPosition: number, highestPosition: number): void {
    Log.info(TAG, `setPlaybackBoundaries: ${lowestPosition} - ${highestPosition}`);
    if (highestPosition) {
      Log.error(TAG, `setPlaybackBoundaries: high boundary not implemented`);
    }
    this.lowestAllowedPosition = lowestPosition;
  }

  public isLiveStream(): boolean {
    return this.contentType === ContentType.LIVE || this.contentType === ContentType.NPLTV;
  }

  public setAudioMuted(muted: boolean): void {
    this.getPlayer().muted(muted);
  }

  // TextTrackProperties getTextTrackProperties();
  // void setTextTrackProperties(TextTrackProperties properties);

  private onAudioTracksChanged = (): void => {
    Log.debug(TAG, 'onAudioTracksChanged');
    const audioTracks: Track[] = [];
    let currentAudioTrackIndex = -1;
    let playerAudioTracks: AudioTrackList;
    try {
      playerAudioTracks = this.getPlayer().audioTracks();
    } catch (error) {
      Log.error(TAG, 'onAudioTracksChanged', error);
      return;
    }

    for (let i = 0; i < playerAudioTracks.length; i++) {
      const {enabled, kind = '', language = 'undefined'} = playerAudioTracks[i];
      audioTracks.push({
        language,
        type: kind
      });
      if (enabled) {
        currentAudioTrackIndex = i;
      }
    }

    this.audioTracks = audioTracks;
    this.currentAudioTrackIndex = currentAudioTrackIndex;

    this.notifier.onAudioTracksChanged(this.audioTracks, this.currentAudioTrackIndex);
  };

  public getAudioTracks(): Track[] {
    return this.audioTracks;
  }

  public setAudioTrack(track?: Track): boolean {
    if (!track) {
      return false;
    }

    let found = false;

    // Method audioTracks() return TrackList but documentation says it should return AudioTrackList
    const playerAudioTracks = this.playerTech()?.audioTracks() as AudioTrackList | undefined;
    if (playerAudioTracks) {
      for (let i = 0; i < playerAudioTracks.length; i++) {
        const playerAudioTrack = playerAudioTracks[i];

        if (playerAudioTrack instanceof videojs.AudioTrack) {
          playerAudioTrack.enabled = false;

          if (track && playerAudioTrack.language === track.language) {
            playerAudioTrack.enabled = true;
            found = true;
          }
        }
      }
    }

    return found;
  }

  private initialOnSubtitleTracksChanged = (): void => {
    Log.debug(TAG, 'initialOnSubtitleTracksChanged');
    try {
      const playerTextTracks = this.getPlayer().textTracks();
      playerTextTracks.removeEventListener('addtrack', this.initialOnSubtitleTracksChanged);
      playerTextTracks.addEventListener('change', this.onSubtitleTracksChanged);
    } catch (error) {
      Log.error(TAG, 'initialOnSubtitleTracksChanged', error);
    }

    this.onSubtitleTracksChanged();
  };

  private onSubtitleTracksChanged = () => {
    Log.debug(TAG, 'onSubtitleTracksChanged');

    let playerTextTracks: TextTrackList;
    try {
      playerTextTracks = this.getPlayer().textTracks();
    } catch (error) {
      Log.error(TAG, 'onSubtitleTracksChanged', error);
      return;
    }

    const subtitleTracks: Track[] = [{language: 'off', type: ''}];
    let currentSubtitleTrackIndex = 0;

    for (let i = 0; i < playerTextTracks.length; i++) {
      const {kind, language = '', mode} = playerTextTracks[i];
      switch (kind) {
        case TextTrackKind.Subtitles:
        case TextTrackKind.Captions:
          const type = kind === TextTrackKind.Captions ? 'cc' : '';
          subtitleTracks.push({language, type});
          if (mode === 'showing') {
            currentSubtitleTrackIndex = i;
          }
          break;
        default:
      }
    }

    this.subtitleTracks = subtitleTracks;
    this.currentSubtitleTrackIndex = currentSubtitleTrackIndex;

    this.notifier.onSubtitleTracksChanged(this.subtitleTracks, this.currentSubtitleTrackIndex);
  }

  public getSubtitleTracks(): Track[] {
    return this.subtitleTracks;
  }

  public setSubtitleTrack(track?: Track): boolean {
    let found = !track;
    const playerTextTracks = this.getPlayer().textTracks();

    for (let i = 0; i < playerTextTracks.length; i++) {
      const playerTextTrack = playerTextTracks[i];
      if (playerTextTrack.kind !== TextTrackKind.Subtitles) {
        continue;
      }
      playerTextTrack.mode = 'disabled';

      if (track && playerTextTrack.language === track.language) {
        playerTextTrack.mode = 'showing';
        found = true;
      }
    }

    return found;
  }

  public getVideoTracks(): string[] {
    // todo: implement
    return [];
  }

  public setVideoTrack(track?: string): boolean {
    // todo: implement
    return true;
  }

  public setDrmInterceptor(interceptor: DrmInterceptor | null): void {
    this.drmInterceptor = interceptor;
  }

  public getDrmInterceptor(): DrmInterceptor | null {
    return this.drmInterceptor;
  }

  public setProperties(properties: any): void {
  }

  protected abstract drmInit(): void
  protected errorHandlingInit(): void {
    const player = this.getPlayer();

    const onError = () => {
      const playerError = player.error();
      let errorType = ErrorType.NativePlayerError;

      if (playerError?.code === MediaError.MEDIA_ERR_NETWORK) {
        // Handle cases where playlist cannot be downloaded (US CL-4972 and CL-4973)
        if ((playerError as any).status === 404) {
          errorType = ErrorType.PlaybackSourceMissing;
        } else {
          errorType = ErrorType.PlaybackSourceError;
        }
      }

      // The player does not report any error when playlist file is corrupted (just reloads it over and over)
      // and thus, US CL-4974 cannot be handled properly

      this.handlePlaybackError(new Error(errorType, JSON.stringify(playerError)));
    };
    player.on('onPlaybackError', onError);
    player.on('error', onError);
  }

  public getProperties(): any {
    return {};
  }

  public handlePlaybackError(error: Error): void {
    this.notifier.onError(error);
  }

  // setDisplayRect(Rect rect): void;
  // void toggleFullScreen(): void;
  // void setSurface(Surface surface): void;
  // void swapPlayerViews(PlayerView oldPlayerView, PlayerView newPlayerView): void;

  public getAbsoluteTuningPosition(): number {
    return 0;
  }
}
