import {useState, useEffect, useRef} from 'react';
import {LayoutChangeEvent, InteractionManager} from 'react-native';

import {epgItemHeight} from 'common/constants';
import {DateUtils} from 'common/DateUtils';
import {Size} from 'common/HelperTypes';
import {Log} from 'common/Log';
import LRU from 'common/LRU';

import {Channel, Event} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import {NativeKeyEvent} from 'components/KeyEventManager';
import {useDisposableState, useFunction, useChangeEffect} from 'hooks/Hooks';

import {getChunkParameters, Chunk, ChunkParameters, isSameChunk, getChunkOffsetBy, chunkId, chunkToString} from './Chunk';
import {pointsPerHour, cacheSize} from './EpgConstants';
import {channelIndex, calculateNeededChunksOffsets, scrollOffsetY, fetchChunk, OffscreenRenderThreshold} from './EpgHelpers';

const TAG = 'Epg';
/**
 * Returns now date updated in constant intervals.
 * Used for now line, time bar and refreshing current event.
 * @param interval Defaults to 30 secs.
 */
export function useNowDate(interval = 30) {
  const [nowDate, setNowDate] = useState(new Date());
  useEffect(() => {
    const id = setInterval(() => setNowDate(new Date()), interval * DateUtils.msInSec);
    return () => clearInterval(id);
  }, [interval]);
  return nowDate;
}

/**
 * Returns current event from currentChannel.
 * @param currentChannel Channel for which to return event.
 * @param nowDate Changing this triggers update.
 */
export function useCurrentEvent(currentChannel: Channel | undefined, nowDate: Date) {
  const [currentEvent, setCurrentEvent] = useDisposableState<Event | null>(null);
  useEffect(() => {
    if (!currentChannel) {
      return;
    }
    mw.catalog.getCurrentEvent(currentChannel)
      .then(setCurrentEvent)
      .catch(error => {
        Log.error('useCurrentEvent', 'Error getting current event for channel: ', currentChannel, error);
      });
  }, [currentChannel, nowDate, setCurrentEvent]);
  return currentEvent;
}

/**
 * Returns grid's visible area size, scrolling page size (area size mapped to full hours/rows)
 * and onLayout closure to use on grid container.
 */
export function useGridDimensions() {
  const [windowSize, setWindowSize] = useState<Size>({width: 0, height: 0});
  const scrollingPageSize = useRef({hours: 0, rows: 0});
  const onGridLayout = useFunction(({nativeEvent: {layout: {width, height}}}: LayoutChangeEvent) => {
    setWindowSize({width, height});
    scrollingPageSize.current = {
      hours: Math.floor(width / pointsPerHour),
      rows: Math.floor(height / epgItemHeight)
    };
  });
  return {windowSize, scrollingPageSize, onGridLayout};
}

export type UseFetchChunks = {
  channels: Channel[];
  gridOffsetDate: Date;
  focusedChannel?: Channel;
  offscreenRenderThreshold?: OffscreenRenderThreshold;
}

type FetchChunksParams = {
  navigationDirection: NativeKeyEvent['key'];
  onChunkLoad: (chunksMap: {[id: string]: Chunk}) => void;
  originDate: Date;
  windowSize: Size;
}

export function useFetchChunks({
  channels,
  focusedChannel,
  gridOffsetDate,
  offscreenRenderThreshold
}: UseFetchChunks) {
  const neededChunksIds = useRef<Set<string>>(new Set());
  const chunksCache = useRef<LRU<Chunk>>(new LRU(cacheSize));
  const currentChunk = useRef<ChunkParameters & {direction: NativeKeyEvent['key']} | null>(null);
  const fetchesInProgress = useRef<Set<string>>(new Set());
  const [fetching, setFetching] = useState(true);

  /**
   * Clear out epg cache changing channels' array.
   * //TODO: CL-3122 This could be returned as 'resetCache' method.
   */
  useChangeEffect(() => {
    chunksCache.current = new LRU(cacheSize);
    neededChunksIds.current = new Set();
    currentChunk.current = null;
  }, [channels]);

  const fetchChunks = useFunction(({
    navigationDirection,
    onChunkLoad,
    originDate,
    windowSize
  }: FetchChunksParams) => {
    if (!channels.length) {
      Log.info(TAG, 'Cannot fetch chunks, channel list is empty.');
      return;
    }
    const focusedRow = channelIndex(focusedChannel?.id, channels);
    const currentChunkParams = getChunkParameters(
      gridOffsetDate,
      focusedRow,
      originDate
    );
    if (currentChunk.current
      && isSameChunk(currentChunk.current, currentChunkParams)
      && currentChunk.current.direction === navigationDirection) {
      Log.debug(TAG, 'Omitting chunk fetch, current chunk has not changed.');
      return;
    }
    currentChunk.current = {...currentChunkParams, direction: navigationDirection};
    Log.info(TAG, `Changing current chunk to ${chunkToString(currentChunk.current)}. Calculating needed chunks.`);

    const neededChunks = calculateNeededChunksOffsets(
      navigationDirection,
      currentChunkParams,
      gridOffsetDate,
      scrollOffsetY(focusedChannel?.id, channels, windowSize),
      windowSize,
      offscreenRenderThreshold
    )
      .map(offset => getChunkOffsetBy(offset, currentChunkParams))
      .filter(({startRow, endRow}) => endRow >= 0 && startRow < channels.length) // out of bounds
      .filter(chunk => !chunksCache.current.get(chunkId(chunk))); // already in cache

    neededChunksIds.current = new Set(neededChunks.map(chunkId));

    neededChunks
      .forEach(chunkParams => {
        const id = chunkId(chunkParams);
        if (fetchesInProgress.current.has(id)) {
          return;
        }
        fetchesInProgress.current.add(id);
        setFetching(true);
        Log.info(TAG, `Fetching chunk: ${chunkToString(chunkParams)}`);
        const timestamp = Date.now();
        fetchChunk(chunkParams, channels)
          .then(chunk => {
            fetchesInProgress.current.delete(id);
            setFetching(!!fetchesInProgress.current.size);
            InteractionManager.runAfterInteractions(() => {
              if (!chunk) {
                return;
              }
              const isNeeded = neededChunksIds.current.has(chunk.id);
              Log.info(TAG, `Finished fetching chunk (${Date.now() - timestamp} ms): ${chunkToString(chunk)} ${isNeeded ? '' : ', but it\'s no longer needed.'}`);
              if (!isNeeded) {
                return;
              }
              chunksCache.current.put(chunk.id, chunk);
              onChunkLoad(chunksCache.current.toMap());
            });
          });
      });
  });

  return {fetching, fetchChunks};
}
