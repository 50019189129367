import {useRef, useEffect, useCallback} from 'react';

import {Emitter, EventHandlerType} from 'common/HelperTypes';
import {Log} from 'common/Log';

/**
 * Handles tedious registration/deregistration of event listeners.
 * The passed listener is used for the full lifetime of the component
 * and is automatically deregistered before unmount.
 */
export function useEventListener<Name extends string>(eventName: Name, handler: EventHandlerType, emitter?: Emitter<Name>): () => void {
  // Create a ref that stores handler
  const savedHandler = useRef<EventHandlerType>(handler);
  const removeRef = useRef(() => {});

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  const unsubscribe = useCallback(() => removeRef.current(), []);

  useEffect(() => {
    if (!emitter) {
      Log.debug('useEventListener', 'Emitter object is not defined, skipping event binding');
      return;
    }

    // Make sure emitter supports on/off
    let addListener = 'on' in emitter ? emitter.on : emitter.addEventListener;
    let removeListener = 'off' in emitter ? emitter.off : emitter.removeEventListener;

    addListener = addListener.bind(emitter);
    removeListener = removeListener.bind(emitter);

    // Create event listener that calls handler function stored in ref
    const eventListener = (event: any) => savedHandler.current(event);

    // Add event listener
    addListener(eventName, eventListener);

    // Store listener removing in ref so it can be returned and used manually
    removeRef.current = () => {
      removeListener(eventName, eventListener);
    };

    // Remove event listener on cleanup
    return () => {
      unsubscribe();
    };
  }, [eventName, emitter, unsubscribe]); // Re-run if eventName or emitter changes
  return unsubscribe;
}
