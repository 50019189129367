import {isWeb} from 'common/constants';

export const assets = {
  common: {
    splashScreen: isWeb ? require('./current/web/background.png') : {uri: 'Background'},
    loginScreenLogo: require('./current/commonResources/loginScreenLogo.png'),
    mobileHeaderLogo: require('./current/commonResources/mobileHeaderLogo.png'),
    stbMainMenuLogo: require('./current/commonResources/stbMainMenuLogo.png'),
    bellIcon: require('./current/commonResources/notification.png')
  },
  web: {
    dpad: {
      pad: require('./current/web/D-Pad/pad.png'),
      hover: require('./current/web/D-Pad/pad-hover.png'),
      now: require('./current/web/D-Pad/now.png'),
      collapsed: require('./current/web/D-Pad/collapsed.png'),
      close: require('./current/web/D-Pad/close.png')
    }
  }
};
