import React, {useState, useMemo, useCallback, useContext} from 'react';

import {isMobile} from 'common/constants';
import {doNothing} from 'common/HelperFunctions';

import {useLazyEffect} from 'hooks/Hooks';

export enum FocusScope {
  All = 'All',
  None = 'None'
}

type FocusPrecedenceContextType = {
  request: () => void;
  release: () => void;
  scope: FocusScope;
}
export const FocusPrecedenceContext = React.createContext<FocusPrecedenceContextType>({request: doNothing, release: doNothing, scope: FocusScope.All});

/*
Creates layers of focus 'precedence'.
Each level of FocusPrecedence's component tree is layer with incremented priority.

e.g. App root is of precedence layer 0, any rendered <FocusPrecedence> as App successor creates next layer (1).
Children of deeper FocusPrecedence become focusable, but anything below (1) should not be focusable from this moment. Modal is perfect example.

Keep in mind that this is zIndex alike mechanism, therefore children of same layer may pass focus each other freely,
this is the main difference between FocusPrecedence and FocusManager.

In other words, only leaves (deepest FocusPrecedences' children) with current maximum precedence layer may be focusable

Examples:
Rectangles - FocusPrecedence instances
XX - Focusable area

1.
note: focus can travel between these 2 squares
+------------------+
|                  |
|  +----+   +----+ |
|  |XXXX|   |XXXX| |
|  +----+   +----+ |
|                  |
+------------------+

2.
+---------------------+
|                     |
|  +-------+          |
|  | +---+ |  +-----+ |
|  | |XXX| |  | XXX | |
|  | +---+ |  | XXX | |
|  +-------+  +-----+ |
|                     |
+---------------------+
*/
/**
 * @deprecated Use FocusParent instead.
 */
export const FocusPrecedence: React.FC<{}> = ({children}) => {
  const ancestorContext = useContext(FocusPrecedenceContext);

  useLazyEffect(() => {
    ancestorContext.request();
    return ancestorContext.release;
  }, [], [ancestorContext]);

  const [successors, setSuccessors] = useState(0);
  const registerSuccessor = useCallback(() => setSuccessors(value => value + 1), []);
  const unregisterSuccessor = useCallback(() => setSuccessors(value => value - 1), []);

  const contextValue = useMemo(() => ({
    request: isMobile ? doNothing : registerSuccessor,
    release: isMobile ? doNothing : unregisterSuccessor,
    scope: successors === 0 ? FocusScope.All : FocusScope.None
  }), [registerSuccessor, unregisterSuccessor, successors]);

  return (
    <FocusPrecedenceContext.Provider value={contextValue}>
      {children}
    </FocusPrecedenceContext.Provider>
  );
};

export default FocusPrecedence;
