import React, {PureComponent} from 'react';

import {Log} from 'common/Log';
import {isCcTrack} from 'common/utils';

import {Track} from 'mw/api/PlayerEvent';
import {mw} from 'mw/MW';
import {Player} from 'mw/playback/Player';

import LanguageSelectionPopup from 'components/LanguageSelectionPopup';

import PlayerLanguageEventHandler, {PlayerTracksInfo} from './PlayerLanguageEventHandler';

const TAG = 'PlayerLanguageController';

type Props = {
  player: Player;
  onTracksChanged?: (playerTracksInfo: PlayerTracksInfo) => void;
  onPopupVisibleChanged?: (visible: boolean) => void;
}

type State = {
  popupVisible: boolean;
  playerTracksInfo?: PlayerTracksInfo;
}

export default class PlayerLanguageController extends PureComponent<Props, State> {

  public constructor(props: Props) {
    super(props);

    this.state = {
      popupVisible: false
    };
  }

  private onTracksChanged = (playerTracksInfo: PlayerTracksInfo) => {
    this.setState({playerTracksInfo});

    if (this.props.onTracksChanged) {
      this.props.onTracksChanged(playerTracksInfo);
    }
  };

  private onAudioSelected = (track: Track) => {
    this.props.player.setCurrentAudioLanguageTrack(track)
      .then(() => Log.debug(TAG, 'Audio language changed'))
      .catch(error => Log.error(TAG, 'Error changing audio language: ', error));
  };

  private onSubtitlesSelected = (track: Track) => {
    mw.customer.currentProfile?.setCCEnabled(isCcTrack(track));

    this.props.player.setCurrentSubtitleTrack(track)
      .then(() => Log.debug(TAG, 'Subtitle changed'))
      .catch(error => Log.error(TAG, 'Error changing subtitle: ', error));
  };

  private hideLanguageSelectionPopup = () => {
    this.setState({popupVisible: false});
    this.props.onPopupVisibleChanged?.(false);
  };

  public showLanguageSelectionPopup() {
    this.setState({popupVisible: true});
    this.props.onPopupVisibleChanged?.(true);
  }

  public render() {
    const playerTracksInfo = this.state.playerTracksInfo;

    return (
      <>
        <PlayerLanguageEventHandler
          player={this.props.player}
          onTracksChanged={this.onTracksChanged}
        />
        {playerTracksInfo && (
          <LanguageSelectionPopup
            visible={this.state.popupVisible}
            audio={playerTracksInfo.getAudioTracks()}
            subtitles={playerTracksInfo.getSubtitleTracks()}
            onAudioSelected={this.onAudioSelected}
            onSubtitlesSelected={this.onSubtitlesSelected}
            selectedAudio={playerTracksInfo.getCurrentAudioTrack()}
            selectedSubtitle={playerTracksInfo.getCurrentSubtitleTrack()}
            onClose={this.hideLanguageSelectionPopup}
          />
        )}
      </>
    );
  }
}
