import React, {useState, useEffect} from 'react';
import {Image, ImageProps, ImageStyle, ImageSourcePropType} from 'react-native';
import {SvgXml} from 'react-native-svg';

import {isWeb} from 'common/constants';
import {Log} from 'common/Log';

const TAG = 'NitroxImage';

type SourcelessImageProps = Omit<ImageProps, 'source'>;
type SVG = string | number;

type SVGProps = {
  svg: SVG;
} & SourcelessImageProps;

const NitroxSVG: React.FC<SVGProps> = (props: SVGProps) => {
  /**
   * Explicit 'undefined' width/height SVG prop overrides svg attributes with "undefined" (and thus 100% - internal default value for react-native-svg) values.
   * This is unwanted behavior in most cases, so we filter out "undefined"s. Use explicit styles instead.
   */
  const svgSize: {width?: number | string; height?: number | string} = {};
  const style = props.style as ImageStyle | undefined;
  const widthStyle = props.width ?? style?.width;
  const heightStyle = props.height ?? style?.height;
  if (widthStyle != null) {
    svgSize.width = widthStyle;
  }
  if (heightStyle != null) {
    svgSize.height = heightStyle;
  }

  //Sadly, we shouldn't save check result to variable, typescript doesn't match it with props.svg type and requires redundant casting
  const [assetXml, setAssetXml] = useState(typeof props.svg === 'number' ? undefined : props.svg);

  /*
   * Effect is a workaround for differentiation between imported asset type
   * Props.svg may contain:
   * - string: svg path (android),
   * - string: svg content (iOS debug),
   * - number: pointer to resource (iOS release)
   */
  useEffect(() => {
    const updateAssetXml = async () => {
      Log.info(TAG, `Got svg prop of type: ${typeof props.svg}`);
      if (typeof props.svg !== 'number') {
        setAssetXml(props.svg);
        return;
      }

      try {
        const uri = Image.resolveAssetSource(props.svg).uri;
        if (!uri) {
          Log.error(TAG, `Svg uri not found for ${props.svg}`);
          return;
        }
        const response = await fetch(uri);
        const responseXML = await response.text();
        if (!responseXML) {
          Log.error(TAG, `Empty svg for props.svg=${props.svg} uri=${uri}`);
          return;
        }
        setAssetXml(responseXML);
      } catch (e) {
        Log.error(TAG, `getAsset: failed to fetch asset: ${e}`);
      }
    };
    updateAssetXml();
  }, [props.svg]);

  if (!assetXml) {
    return null;
  }

  if (isWeb) {
    return (
      <img
        src={assetXml}
        style={svgSize}
      />
    );
  }

  return (
    <SvgXml
      {...svgSize}
      xml={assetXml}
    />
  );
};

export type NitroxImageProps = {
  svg?: SVG;
  source?: ImageSourcePropType;
} & SourcelessImageProps;

const NitroxImage: React.FC<NitroxImageProps> = ({svg, source, ...otherProps}) => {
  if (svg) {
    return (
      <NitroxSVG
        {...otherProps}
        svg={svg} //workaround for typescript props parsing error
      />
    );
  }

  if (source != null) {
    return (
      <Image
        {...otherProps}
        /*
         * resizeMethod set to auto/scale vastly decreases performance on Mibox S
         * despite the documentation (https://reactnative.dev/docs/0.61/image#resizemethod)
         * suggests otherwise
         */
        resizeMethod='resize'
        source={source}
      />
    );
  }

  Log.error(TAG, 'source or svg must be defined');
  return null;
};

export default React.memo(NitroxImage);
