import {ColorPalette} from 'brand/ColorTypes';

export enum PictureMode {
  CROP = 'crop',
  BOX = 'box',
  STRETCH = 'stretch'
}

export enum PictureType {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
  Logo = 'logo',
  Background = 'background',
  BackgroundHorizontal = 'background-horizontal',
  BackgroundVertical = 'background-vertical',
  PromotionalBanner = 'promotionalBanner',
  Tile = 'tile',
  HighlightedBannerTile = 'highlightedBannerTile'
}

export enum ContentType {
  UNKNOWN = 'UNKNOWN',
  LIVE = 'LIVE',
  PLTV = 'PLTV',
  NPLTV = 'NPLTV',
  VOD = 'VOD',
  TSTV = 'TSTV',
  NPVR = 'NPVR',
  AUDIO = 'AUDIO'
}

export enum PaymentMethodId {
  Stripe = 'stripe',
  Billing = 'billing'
}

export enum ChromecastConnectionState {
  Disconnected = 'Disconnected',
  Connecting = 'Connecting',
  Connected = 'Connected',
  MediaConnecting = 'MediaConnecting',
  MediaConnected = 'MediaConnected'
}

export class Picture {
  public type: string;
  public url: string;
  public width: number;
  public height: number;
  public format: string;

  public constructor(type: string, url: string, width = 0, height = 0, format = '') {
    this.type = type;
    this.url = url;
    this.width = width;
    this.height = height;
    this.format = format;
  }
}

/*************\/ MEDIA TYPES \/ *************/

export enum PlayableType {
  Channel = 'Channel',
  Content = 'Content',
}

export enum MediaType {
  Channel = 'Channel',
  Event = 'Event',
  InvalidMedia = 'InvalidMedia',
  Recording = 'Recording',
  Season = 'Season',
  Series = 'Series',
  Title = 'Title',
  Audio = 'Audio'
}

export enum TitleType {
  EPG,
  VOD,
  UNKNOWN
}

export enum SeriesType {
  Season, Series
}

export interface Playable {
  getUrl(): string;
  getPlayableType(): PlayableType;
  getId(): string;
  isAllowed(): boolean;
  hasFallbackPlayback(): boolean;
}

export interface ParentalControlRating {
  authority: string;
  minimumAge: number;
  value: string;
}

export interface ContentProvider {
  id: string;
  displayName: string;
}

export interface Media {
  readonly id: string;
  readonly name: string;
  ordinal: number;
  customProperties: any;
  pictures: Picture[];
  pcRatings: ParentalControlRating[];
  contentProvider?: ContentProvider;
  bookmark: number;
  isViewedCompletely: boolean;
  isAdult: boolean;

  /** Checks if media is on current's profile WatchList */
  readonly isOnWatchList: boolean;

  getPlayable(): Playable | null;
  isPlayAllowed(): boolean;
  isAllowedOnWatchList(): boolean;
  isPartiallyViewed(): boolean;

  equals(media?: Media | null): boolean;

  getType(): MediaType;
  isBlocked(): boolean;

  toString(): string;
}

export enum OngoingEventRecording {
  NotAllowed = 'NotAllowed',
  FromStart = 'FromStart',
  FromTunePoint = 'FromTunePoint',
  FromRequest = 'FromRequest'
}

export interface Audio extends Media, Playable {
  location: Location;
}

export interface Channel extends Media, Playable {
  longName: string;
  location: Location;
  playbackProperties: PlaybackProperties | null;
  isAvailableByPolicy: boolean;
  isNetworkRecordingAllowed: boolean;
  recordingInThePastLimit: number;
  ongoingEventRecording?: OngoingEventRecording;
  readonly lcn: number;
}

export interface ChannelList {
  id: string;
  name: string;
  channels: Channel[];
}

export enum DateTimeDistance {
  LongDistancePast = 'LongDistancePast',
  Yesterday = 'Yesterday',
  Today = 'Today',
  Tomorrow = 'Tomorrow',
  UpcomingWeek = 'UpcomingWeek',
  MoreThanWeek = 'MoreThanWeek',
}

export interface Event extends Media {
  title: Title;
  start: Date;
  end: Date;
  channelId: string;
  tstvContents: Content[] | null;
  isRecorded: boolean;
  pastTSTVEvent?: Event;
  readonly hasTstv: boolean;
  readonly isPast: boolean;
  readonly isNow: boolean;
  readonly isFuture: boolean;
  readonly progress: number;
  readonly viewedProgress: number;
  readonly isToday: boolean;
  readonly isTomorrow: boolean;
  readonly wasYesterday: boolean;
  readonly isInWeekDistance: boolean;
  readonly isSameYear: boolean;
  readonly eventTimeDistance: DateTimeDistance;
  readonly isEventNPVRAllowed: boolean;
  readonly isSeriesNPVRAllowed: boolean;
  toString(): string;
}

export interface Title extends Media {
  contents: Content[];
  entitlementState: EntitlementState;
  episode: Episode | null;  // exists only if title is part of a series
  events: Event[];
  isEntitled: boolean;
  isTrailer: boolean;
  metadata: Metadata;
  readonly progress: number;
  type: TitleType;
  trailers: Title[];
  parentId?: string;
}

export enum RecordingStatus {
  // for single recordings
  Scheduled,
  Recorded,
  Failed,
  Recording,
  Deleted,

  // for series recordings
  Active,
  Suspended,
  Completed
}

export enum RecordingType {
  Series = 'series',
  Single = 'single',
  Unsupported = 'unsupported'
}

export enum RecordingErrorCode {
  QuotaExceeded = 'QuotaExceeded'
}

export interface Recording extends Media {
  parentRecordingId?: string;
  recordingType: RecordingType;
  contents: Content[];
  event?: Event;
  seedEventId?: string;
  series?: Series;
  profileId?: string;
  duration: number;
  status?: RecordingStatus;
  channelId?: string;
  seedSeriesNumber?: number;
  seasonsCount: number;
  episodesCount: number;
  guardTimeStart: number;
  guardTimeEnd: number;
  errorCode?: RecordingErrorCode;
}

export interface SingleRecording extends Recording {
  type: RecordingType.Single;
  event: Event;
}

export interface SeriesRecording extends Recording {
  type: RecordingType.Series;
  series: Series;
  recordings: Recording[];
}

export interface BlockedByPCEventState {
  blocked: boolean;
  event?: Event;
}

// consider more generic type guards, and implement those for all Media subtypes (and in general - for all type casting) TODO: CL-1299
// type guards allow to use subtype without casting with 'as' operator and provide better type safety
export function isEvent(media?: Media | null): media is Event {
  if (!media) {
    return false;
  }
  return media.getType() === MediaType.Event;
}

export function isTitle(media?: Media | null): media is Title {
  if (!media) {
    return false;
  }
  return media.getType() === MediaType.Title;
}

export function isSeries(media?: Media | null): media is Series {
  if (!media) {
    return false;
  }
  return media.getType() === MediaType.Series;
}

export function isRecording(media?: Media | null): media is Recording {
  if (!media) {
    return false;
  }
  return media.getType() === MediaType.Recording;
}

export function isSingleRecording(media?: Media): media is SingleRecording {
  if (!media) {
    return false;
  }
  return isRecording(media) && media.recordingType === RecordingType.Single && !!media.event;
}

export function isSeriesRecording(media?: Media): media is SeriesRecording {
  if (!media) {
    return false;
  }
  return isRecording(media) && media.recordingType === RecordingType.Series && !!media.series;
}

export function isChannel(media?: Media | null): media is Channel {
  if (!media) {
    return false;
  }
  return media.getType() === MediaType.Channel;
}

export function isEpisode(media: Media): boolean {
  return !!(isTitle(media) && media.episode);
}

export function isOrder(order: Order | OrderedProduct): order is Order {
  return (order as Order).id !== undefined;
}

/************* /\ TYPES /\ *************/

export class PlaybackProperties {
  public resolution: string;
  public audioType: string;

  public constructor(resolution: string, audioType: string) {
    this.resolution = resolution;
    this.audioType = audioType;
  }
}

export enum ProductType {
  Free = 'free',
  Subscription = 'subscription',
  Transaction = 'transaction',
  TransactionBuy = 'transactionBuy',
  TransactionRent = 'transactionRent',
  Unknown = 'unknown'
}

export enum EntitlementState {
  Entitled,
  NotEntitled,
  EntitlementInProgress
}

export interface Product {
  readonly id: string;
  type: ProductType;
  name: string;
  offers: Offer[];
  isEntitled: boolean;
  entitlementState?: EntitlementState;
  isAvailable: boolean;
  relationStart?: Date;
  relationEnd?: Date;
  description?: string;
  isSingle?: boolean;
  filterId?: string;
  duration?: number; // value in ms
  isAllowed(): boolean;
  isAvailableToBuy: boolean;
  isAvailableToRent: boolean;
}

export enum PurchaseMethod {
  Voucher,
  Stripe,
  Billing
}

export interface ExternalLink {
  url: string;
  provider: string;
}

export interface ExternalIds {
  ingestId?: string;
  playbackId?: string;
}

export interface Content extends Playable {
  readonly id: string;
  duration: number;
  firstAvailability?: Date;
  lastAvailability?: Date;
  entitlementEnd?: Date;
  isEntitled: boolean;
  entitlementState: EntitlementState;
  playbackProperties?: PlaybackProperties;
  products: Product[];
  isAdult: boolean;
  recommendedPlaybackOffset: number;
  externalLink?: ExternalLink;
  reporter?: string;
  externalIds: ExternalIds;
}

export interface Offer {
  id?: string;
  paymentMethodId: string;
  price: number;
  currency: string;
  rentalPeriod?: number;
}

export interface PaymentMethod {
  id: PaymentMethodId;
  displayName: string;
}

export class Genre {
  public code: string;
  public name: string;

  public constructor(code: string, name: string) {
    this.code = code;
    this.name = name;
  }
}

export class Credits {
  public firstName: string;
  public lastName: string;
  public pictures: Picture[];

  public constructor(firstName: string, lastName: string, pictures: Picture[] = []) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.pictures = pictures;
  }
}

export class ParentalControl {
  public code: string;
  public age: number | null = null;

  public constructor(code: string) {
    this.code = code;
  }
}

export class Metadata {
  public shortSynopsis = '';
  public mediumSynopsis = '';
  public longSynopsis = '';
  public genres: Genre[] = [];
  public actors: Credits[] = [];
  public directors: Credits[] = [];
  public productionYear = -1;
  public productionLocation = '';
  public duration = 0;
}

export interface Series extends Media {
  metadata: Metadata;
  seriesType: SeriesType;
  seasonNumber?: number;
  /**
   * Seasons in the series. In some cases the seasons array may contain only a subset of all available seasons.
   */
  seasons: Series[];
  /**
   * Number of all available seasons in a series.
   */
  seasonsCount: number;
  titles: Title[];
  externalId?: string;
}

export interface Episode {
  seriesId: string;
  seasonId: string;
  title?: string;
  number?: number;
  seriesName?: string;
  seasonName?: string;
  seasonNumber?: number;
  firstEpisodeOfSeason?: boolean;
}

export interface PlaybackLimitations {
  readonly allowRestart?: boolean;
  readonly allowPause?: boolean;
  readonly allowSkipForward?: boolean;
  readonly allowSkipBackward?: boolean;
  readonly allowFastForward?: boolean;
  readonly allowRewind?: boolean;
  readonly allowGoToLive?: boolean;
}

export type PVRQuota = {
  available: number;
  remaining: number;
}

export type PVRGuardTimes = {
  start?: number;
  end?: number;
}

export type PVRAvailableGuardTimes = {
  start: number[];
  end: number[];
}

export enum PVRRecordingScope {
  All,
  OnlyNew,
  StartingFromSeason
}

export type PVRQueryParameters = {
  id?: string;
  limit?: number;
  offset?: number;
  sortBy?: RecordingsSorting;
  status?: RecordingStatus;
  parentRecording?: Recording;
  media?: Event | Series | Event[];
  type?: RecordingType;
}

type PVRScheduleCommonParameters = {
  /**
   * Event to be recorded or when `scope` is `OnlyNew` and `type` is `Series` determines that only episodes beyond this episode are recorded.
   */
  event: Event;
  guardTimes?: PVRGuardTimes;
  startTime?: Date;
}

type PVRScheduleSingleParameters = PVRScheduleCommonParameters & {
  type: RecordingType.Single;
};

type PVRScheduleSeriesParameters = PVRScheduleCommonParameters & {
  type: RecordingType.Series;
  /**
   * Channel id from which episodes will be recorded. Parameter is ignored when type is different than Series.
   */
  boundChannelId?: string;
};

type PVRScheduleAllOrNewParameters = PVRScheduleSeriesParameters & {
  type: RecordingType.Series;
  scope: PVRRecordingScope.All | PVRRecordingScope.OnlyNew;
};

type PVRScheduleFromSeasonParameters = PVRScheduleSeriesParameters & {
  type: RecordingType.Series;
  scope: PVRRecordingScope.StartingFromSeason;
  /**
   * Ordinal number of the season from which episodes will be recorded. Parameter is required when scope is FromSeason and type is Series.
   */
  startingSeason: number;
};

export type PVRScheduleParameters = PVRScheduleSingleParameters | PVRScheduleAllOrNewParameters | PVRScheduleFromSeasonParameters;

export type PVRUpdateParameters = {
  scope?: PVRRecordingScope;
  event?: Event;
  startingSeason?: number;
  guardTimes?: PVRGuardTimes;
}

export enum PVREvent {
  PVRQuotaChanged = 'PVRQuotaChanged',
  PVRRecordingsChanged = 'PVRRecordingsChanged'
}

export interface SortingInterface {
  type: SortAllResultsBy | SortChannelBy | SortRecordingsBy | SortVodBy | SortWatchListBy;
  ascending: boolean;
}

export interface AllResultsSorting extends SortingInterface {
  type: SortAllResultsBy;
}

export interface ChannelSorting extends SortingInterface {
  type: SortChannelBy;
}

export interface RecordingsSorting extends SortingInterface {
  type: SortRecordingsBy;
}

export interface VodSorting extends SortingInterface {
  type: SortVodBy;
}

export interface WatchListSorting extends SortingInterface {
  type: SortWatchListBy;
}

export enum SortAllResultsBy {
  name = 'name'
}

export enum SortChannelBy {
  name = 'name'
}

export enum SortRecordingsBy {
  create = 'create',
  name = 'name',
  start = 'start',
  lastViewDate = 'lastViewDate'
}

export enum SortVodBy {
  default = 'default',
  title = 'title',
  productionYear = 'productionYear'
}

export enum SortWatchListBy {
  title = 'title',
  productionYear = 'productionYear'
}

export enum SortOwnedBy {
  availableFrom = 'availableFrom'
}

export enum SortOrdersBy {
  creationDate = 'creationDate'
}

export enum SortingOrder {
  ascending = 'asc',
  descending = 'desc'
}

export enum RecordingsFilter {
  all = 'all'
}

export enum VodFilter {
  seen = 'seen',
  notSeen = 'notSeen',
  all = 'all'
}

export enum AvailabilityInTime {
  past = 'past',
  current = 'current',
  future = 'future'
}

export type EpgFilter = {
  availabilityInTime: AvailabilityInTime;
}

export const searchEpgFilterPast: EpgFilter = {availabilityInTime: AvailabilityInTime.past};
export const searchEpgFilterNow: EpgFilter = {availabilityInTime: AvailabilityInTime.current};
export const searchEpgFilterFuture: EpgFilter = {availabilityInTime: AvailabilityInTime.future};

export enum PlaybackStopReason {
  ParentalControl = 'ParentalControl',
  UserAction = 'UserAction',
  Error = 'Error'
}

export class Location {
  public url: string;
  public fallback: boolean;

  public constructor(url = '', fallback = false) {
    this.url = url;
    this.fallback = fallback;
  }
}

export type Images = {[role: string]: string}

export interface Styling {
  brand: string;
  version: string;
  revision: number;
  images: Images;
  styling: ColorPalette;
}

export interface Consent {
  name: string;
  consent: string;
  version: string;
}

export enum UpdateMediaParams {
  Bookmarks,
  EntitlementState
}

export enum OrderStatus {
  Pending = 'pending',
  Success = 'success',
  Failed = 'failed',
  Canceled = 'canceled',
  PaymentFailed = 'paymentFailed',
  Unpaid = 'unpaid'
}

export const FinalOrderStatuses = [
  OrderStatus.Success,
  OrderStatus.Failed,
  OrderStatus.Canceled
];

export interface Order {
  id: string;
  productId: string;
  mediaId?: string;
  currency: string;
  paymentMethodId: string;
  price: number;
  creationDate: Date;
  orderStatus: OrderStatus;
  productName: string;
}

export interface Payment {
  id: string;
  orderId: string;
  status: string;
  message: string;
  creationDate: Date;
  endDate: Date;

  customProperties: {
    // this field are present only for Stripe payments
    [PaymentMethodId.Stripe]?: StripeProperties;
  };
}

export interface StripeProperties {
  clientSecret: string;
  publicApiKey: string;
}

export type OrderedProduct = {productId: string; mediaId?: string};
