import React from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions, RADIUS, isMobile} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';

import {Icon, IconType} from 'components/Icon';
import NitroxText from 'components/NitroxText';

const iconSize = isMobile ? dimensions.icon.xxxsmall : dimensions.icon.xxsmall;
const stylesUpdated = new StylesUpdater(baseColors => ({
  container: {
    alignItems: 'center',
    borderRadius: RADIUS,
    backgroundColor: baseColors.notEntitledLabel.background,
    flexDirection: 'row',
    marginVertical: isMobile ? dimensions.margins.xsmall : dimensions.margins.medium,
    paddingHorizontal: isMobile ? dimensions.margins.small : dimensions.margins.medium,
    paddingVertical: dimensions.margins.xsmall
  },
  text: {
    color: baseColors.notEntitledLabel.text,
    marginLeft: isMobile ? dimensions.margins.xsmall : dimensions.margins.small
  }
}));

const NotEntitledLabel: React.FC = () => {
  const {t} = useTranslation();
  const styles = stylesUpdated.getStyles();

  return (
    <View style={styles.container}>
      <Icon type={IconType.Lock} size={iconSize} />
      <NitroxText textType='label-condensed' style={styles.text}>{t('mediaDetails.notEntitled')}</NitroxText>
    </View>
  );
};

export default NotEntitledLabel;
