import i18next from 'i18next';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Omit} from 'react-navigation';

import {SortRecordingsBy, RecordingsSorting} from 'mw/api/Metadata';

import SortOrderSelect, {SortOrderSelectProps, SortOption, sortOrderAscendingIconProps, sortOrderDescendingIconProps, DEFAULT_OPTION_KEY, createOption} from 'components/SortOrderSelect';

//TODO: CL-1923 Refactor of search components across whole application
const sortOptionsRecordingFactory: (t: i18next.TFunction) => SortOption<RecordingsSorting>[] = t => ([
  {
    key: DEFAULT_OPTION_KEY,
    label: t('sorting.default')
  },
  createOption<RecordingsSorting>(
    {
      type: SortRecordingsBy.create,
      ascending: true
    },
    t('sorting.create'),
    sortOrderAscendingIconProps
  ),
  createOption<RecordingsSorting>(
    {
      type: SortRecordingsBy.create,
      ascending: false
    },
    t('sorting.create'),
    sortOrderDescendingIconProps
  ),
  createOption<RecordingsSorting>(
    {
      type: SortRecordingsBy.name,
      ascending: true
    },
    t('sorting.name'),
    sortOrderAscendingIconProps
  ),
  createOption<RecordingsSorting>(
    {
      type: SortRecordingsBy.name,
      ascending: false
    },
    t('sorting.name'),
    sortOrderDescendingIconProps
  ),
  createOption<RecordingsSorting>(
    {
      type: SortRecordingsBy.start,
      ascending: true
    },
    t('sorting.start'),
    sortOrderAscendingIconProps
  ),
  createOption<RecordingsSorting>(
    {
      type: SortRecordingsBy.start,
      ascending: false
    },
    t('sorting.start'),
    sortOrderDescendingIconProps
  )
]);

type Props = Omit<SortOrderSelectProps<RecordingsSorting>, 'options'>

function RecordingsSortOrderSelect(props: Props) {
  const {t} = useTranslation();
  const options = useMemo(() => sortOptionsRecordingFactory(t), [t]);
  return (
    <SortOrderSelect<RecordingsSorting>
      {...props}
      options={options}
    />
  );
}

export default React.memo(RecordingsSortOrderSelect);

