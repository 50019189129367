import React from 'react';
import {useTranslation} from 'react-i18next';

import DeviceRegistrationErrorPopup from './DeviceRegistrationErrorPopup';

type Props = {
  visible: boolean;
  logout: () => void;
  register: () => void;
};

const DeviceUnregisteredPopup: React.FunctionComponent<Props> = props => {
  const {t} = useTranslation();
  return (
    <DeviceRegistrationErrorPopup
      title={t('deviceUnregisteredPopup.title')}
      message={t('deviceUnregisteredPopup.message')}
      actionButtonMessage={t('deviceUnregisteredPopup.registerButton')}
      action={props.register}
      {...props}
    />
  );
};

export default React.memo(DeviceUnregisteredPopup);
