import {createStyles} from 'common-styles';
import React, {RefObject, useCallback, useMemo} from 'react';
import {StyleProp, ViewStyle, View} from 'react-native';

import {debugFeatures} from 'common/constants';
import {Rect} from 'common/HelperTypes';

import NitroxInteractive from 'components/NitroxInteractive';
import NitroxText from 'components/NitroxText';
import {useFunction} from 'hooks/Hooks';

import {EpgTileInterface} from './EpgTile';

export const epgCursorWidth = 100;

const debugMode = debugFeatures.epgFocusDriver;

const styles = createStyles({
  /* eslint-disable schange-rules/no-literal-color */
  debugMode: {
    backgroundColor: 'yellow',
    opacity: 0.5
  }
  /* eslint-enable schange-rules/no-literal-color */
});

type DebugProps = {
  style: ViewStyle;
  originId: string;
}

const DebugRenderHolder = React.memo((props: DebugProps) => {
  return (
    <View style={props.style}>
      <NitroxText textType='callout-small-extra-condensed'>{`x: ${props.style.left}`}</NitroxText>
      <NitroxText textType='callout-small-extra-condensed'>{`y: ${props.style.top}`}</NitroxText>
      <NitroxText textType='callout-small-extra-condensed'>{`originId: ${props.originId}`}</NitroxText>
    </View>
  );
});
DebugRenderHolder.displayName = 'DebugRenderHolder';

export interface EpgFocusableElement extends Rect {
  id?: string;
  ref?: RefObject<EpgTileInterface>;
  originId: string;
}

interface Props extends EpgFocusableElement {
  onFocus: (holder: EpgFocusableElement) => void;
  onBlur: (holder: EpgFocusableElement) => void;
  onPress: (holder: EpgFocusableElement) => void;
  focusOnMount?: boolean;
  holderRef?: RefObject<EpgTileInterface>;
}

const EpgFocusHolder = React.memo((props: Props) => {
  const {
    onFocus: propsOnFocus,
    onBlur: propsOnBlur,
    onPress: propsOnPress,
    focusOnMount,
    height,
    width,
    x,
    y,
    id,
    originId,
    holderRef: ref
  } = props;

  const getHolder: () => EpgFocusableElement = useFunction(() => ({height, width, x, y, id, originId, ref}));

  const onFocus = useCallback(() => propsOnFocus(getHolder()), [getHolder, propsOnFocus]);
  const onBlur = useCallback(() => propsOnBlur(getHolder()), [getHolder, propsOnBlur]);
  const onPress = useCallback(() => propsOnPress(getHolder()), [getHolder, propsOnPress]);

  const style: StyleProp<ViewStyle> = useMemo(() => [{
    position: 'absolute',
    left: x,
    top: y,
    /**
     * Holders should not overlap as it may result in unpredictable focus transition.
     */
    width: Math.min(epgCursorWidth, width),
    height: height
  }], [x, y, width, height]);

  return (
    <>
      {debugMode && (
        <DebugRenderHolder
          style={{
            ...styles.debugMode,
            position: 'absolute',
            left: x,
            top: y,
            width: epgCursorWidth,
            height: height
          }}
          originId={originId}
        />
      )}
      <NitroxInteractive
        style={style}
        onFocus={onFocus}
        onBlur={onBlur}
        onPress={onPress}
        focusOnMount={focusOnMount}
      />
    </>
  );
});
EpgFocusHolder.displayName = 'RenderHolder';

export default EpgFocusHolder;
