import Moment from 'moment';
import React, {useCallback, useState, ReactNode, Ref} from 'react';
import {ViewStyle, LayoutChangeEvent, GestureResponderEvent, Animated} from 'react-native';

import {isBigScreen} from 'common/constants';
import {Size, Point} from 'common/HelperTypes';
import {Hashmap} from 'common/HelperTypes';

import {ScrollDirection, EpgNitroxScrollView, ScrollEvent, EpgNitroxScrollViewInterface} from './EpgNitroxScrollView';
import {DataDelegate, RenderedItem} from './NitroxContentView';

interface Props extends DataDelegate<Moment.Moment> {
  style?: ViewStyle;
  scrollEnabled?: boolean;
  controlled?: boolean;
  contentHeight?: number;
  contentWidth?: number;
  itemWidth?: number;
  leftInset?: number;
  children?: ReactNode;
  scrollPosition?: Animated.ValueXY | null;
  startPoint?: Point;
  onLayout?: (event: LayoutChangeEvent) => void;
  onScrollHorizontal?: (event: ScrollEvent) => void;
  onSetContentStartOffset?: (point: Point) => void;
  onContentLayout?: (items: Hashmap<RenderedItem<Moment.Moment>>) => void;
  onTouchStart?: (event: GestureResponderEvent) => void;
}

const EpgTimebar: React.FunctionComponent<Props> = (props: Props, ref: Ref<EpgNitroxScrollViewInterface<Moment.Moment>>) => {
  const [viewSize, setViewSize] = useState<Size>();
  const {onLayout, onTouchStart} = props;

  const onLayoutInternal = useCallback((event: LayoutChangeEvent) => {
    setViewSize({width: event.nativeEvent.layout.width, height: event.nativeEvent.layout.height});
    if (onLayout) {
      onLayout(event);
    }
  }, [onLayout]);

  return (
    <EpgNitroxScrollView<Moment.Moment>
      ref={ref}
      debugName={'EpgTimebar'}
      focusable={!isBigScreen}
      onLayout={onLayoutInternal}
      viewSize={viewSize || {width: 0, height: 0}}
      style={props.style}
      leftInset={props.leftInset}
      onSetContentStartOffset={props.onSetContentStartOffset}
      onScrollHorizontal={props.onScrollHorizontal}
      layoutItemsForRect={props.layoutItemsForRect}
      renderItem={props.renderItem}
      contentHeight={props.contentHeight}
      contentWidth={props.contentWidth}
      itemWidth={props.itemWidth}
      direction={ScrollDirection.Horizontal}
      showIndicators={false}
      scrollEnabled={props.scrollEnabled}
      controlled={props.controlled}
      onContentLayout={props.onContentLayout}
      scrollPosition={props.scrollPosition}
      startPoint={props.startPoint}
      onTouchStart={onTouchStart}
    >
      {props.children}
    </EpgNitroxScrollView>
  );
};

export default React.memo(React.forwardRef<EpgNitroxScrollViewInterface<Moment.Moment>, Props>(EpgTimebar));
