import {createStyles} from 'common-styles';
import React from 'react';
import {View} from 'react-native';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Media} from 'mw/api/Metadata';

import {Icon, IconType} from 'components/Icon';
import NitroxInteractive from 'components/NitroxInteractive';
import NitroxText from 'components/NitroxText';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  text: {
    color: colors.popup.text,
    alignSelf: 'center'
  }
}));

const styles = createStyles({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  textRow: {
    flexShrink: 1,
    alignSelf: 'center'
  },
  icon: {
    marginLeft: dimensions.margins.xxLarge
  },
  iconContainer: {
    width: dimensions.icon.small
  }
});

const staticStyles = {
  iconContainer: [styles.iconContainer, styles.icon]
};

type Props = {
  paused: boolean;
  media: Media;
  onSkipBackward: () => void;
  onStartPlayback: () => void;
  onPausePlayback: () => void;
}

const ChromecastRemoteControlFolded: React.FC<Props> = (props) => {
  const {
    paused,
    media,
    onSkipBackward,
    onStartPlayback,
    onPausePlayback
  } = props;

  const dynamicStyle = stylesUpdater.getStyles();

  return (
    <View style={styles.row}>
      <View style={styles.textRow}>
        <NitroxText numberOfLines={1} ellipsizeMode='tail' textType='headline' style={dynamicStyle.text}>{media.name}</NitroxText>
      </View>
      <View style={styles.row}>
        <NitroxInteractive onPress={onSkipBackward} style={staticStyles.iconContainer}>
          <Icon type={IconType.SkipBack10} size={dimensions.icon.small} />
        </NitroxInteractive>
        <NitroxInteractive onPress={paused ? onStartPlayback : onPausePlayback} style={staticStyles.iconContainer}>
          <Icon type={paused ? IconType.Play : IconType.Pause} size={dimensions.icon.small} />
        </NitroxInteractive>
      </View>
    </View>
  );
};

export default React.memo(ChromecastRemoteControlFolded);
