import {createStyles} from 'common-styles';
import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, View, ViewStyle, ScrollView, ViewProps} from 'react-native';

import {isBigScreen, isMobile, isTVOS, dimensions, isTablet} from 'common/constants';
import {findPlayableRecording, compactMap} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Event, Media, Series, isEvent, RecordingType} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import FocusParent from 'components/FocusParent';
import {IconType} from 'components/Icon';
import {IconPosition} from 'components/NitroxButton';
import NitroxText from 'components/NitroxText';
import {getBuyButtonText, getRentButtonText, isPurchaseAllowed} from 'components/payments/PaymentHelperFunctions';
import {PostProcessors} from 'locales/i18nPostProcessors';

import ActionButton, {ActionButtonProps} from './ActionButton';
import {Action, MediaDetailButtonsAction, isCustomAction, CustomAction, CustomActionKeys} from './MediaDetailUtils';

const TAG = 'MediaDetailButtons';
const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  buttonsContainer: {
    backgroundColor: colors.mediaDetailsScreen.buttonsRow.background,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    paddingTop: dimensions.margins.medium
  },
  button: {
    marginBottom: 0
  },
  bottomSeparator: {
    backgroundColor: colors.mediaDetailsScreen.buttonsRow.separator,
    height: 1
  },
  iconText: {
    marginTop: dimensions.margins.small
  },
  watchOnLabel: {
    color: colors.popup.text,
    paddingBottom: dimensions.margins.medium
  }
}));

const actionNotImplemented = () => {
  Log.debug(TAG, 'Action not implemented');
};

type Props = {
  media: Media;
  actions: Array<MediaDetailButtonsAction>;
  style: StyleProp<ViewStyle>;
  selectedSeason?: Series;
  onSelectSeasonPress: () => void;
  onRecordPress: (media: Media) => void;
  onDeleteRecordingPress: (media: Media) => void;
  onCancelRecordingPress: (media: Media, type: RecordingType) => void;
  onAddToWatchListPress: (media: Media) => void;
  onRemoveFromWatchListPress: (media: Media) => void;
  onStartPlayback: (media: Media) => void;
  onAdultContentUnlock?: (media: Media) => void;
  onCustomActionPress?: (key: CustomActionKeys, value: any) => void;
  onWatchOnPress?: (media: Media) => void;
  onBuyPress?: (media: Media) => void;
  onRentPress?: (media: Media) => void;
  hasTVPreferredFocus: boolean;
}

const minButtonWidth = isTablet ? 120 : 108;

const MediaDetailButtons: React.FunctionComponent<Props> = props => {
  const {
    media,
    actions,
    selectedSeason,
    onSelectSeasonPress,
    onRecordPress,
    onCancelRecordingPress,
    onDeleteRecordingPress,
    onStartPlayback,
    onAddToWatchListPress,
    onRemoveFromWatchListPress,
    onAdultContentUnlock,
    onCustomActionPress,
    onWatchOnPress,
    onBuyPress,
    onRentPress,
    hasTVPreferredFocus
  } = props;
  const {t, i18n} = useTranslation();
  const styles = stylesUpdater.getStyles();

  const startPlayback = useCallback(() => {
    onStartPlayback(media);
  }, [media, onStartPlayback]);

  const unlockAdultContent = useCallback(() => {
    onAdultContentUnlock?.(media);
  }, [onAdultContentUnlock, media]);

  const startLivePlayback = useCallback(() => {
    if (isEvent(media)) {
      const channel = mw.catalog.getChannelById(media.channelId);
      if (channel) {
        onStartPlayback(channel);
        return;
      }
    }
    Log.error(TAG, `Unable to start live playback for event: ${media}`);
  }, [media, onStartPlayback]);

  const startRecordingPlayback = useCallback(async () => {
    if (!isEvent(media)) {
      Log.error(TAG, 'Unable to start recording playback for media other than event');
      return;
    }
    const event = media as Event;
    const recording = findPlayableRecording(await mw.pvr.getRecordings({media: event, type: RecordingType.Single}).catch(() => []));
    if (!recording) {
      Log.error(TAG, `Failed to find playable recording for event: ${event}`);
      return;
    }
    onStartPlayback(recording);
  }, [media, onStartPlayback]);

  const buy = useCallback(() => {
    onBuyPress?.(media);
  }, [media, onBuyPress]);

  const rent = useCallback(() => {
    onRentPress?.(media);
  }, [media, onRentPress]);

  const buttonPropsList: ActionButtonProps[] = useMemo(() => {
    const mappedProps: ActionButtonProps[] = compactMap(actions, action => {
      const vodPurchasesEnabled = isPurchaseAllowed();
      switch (isCustomAction(action) ? action.action : action) {
        case Action.WATCH:
          return ({
            text: t('mediaDetails.watch'), //TODO CL-3508 those labels are translated only once and don't change when language is changed
            onPress: startPlayback,
            ...(isMobile && {iconType: IconType.Play})
          });

        case Action.WATCH_NOW:
          return ({
            text: t('mediaDetails.watchNow'),
            onPress: startLivePlayback,
            ...(isMobile && {iconType: IconType.Play})
          });

        case Action.WATCH_RECORDING:
          return ({
            text: t('mediaDetails.watchRecording'),
            onPress: startRecordingPlayback,
            ...(isMobile && {iconType: IconType.Play})
          });

        case Action.BUY:
          if (vodPurchasesEnabled) {
            return ({
              text: getBuyButtonText(t, i18n, media),
              onPress: buy,
              ...(isMobile && {iconType: IconType.Buy})
            });
          }
          break;

        case Action.RENT:
          if (vodPurchasesEnabled) {
            return ({
              text: getRentButtonText(t, i18n, media),
              onPress: rent,
              ...(isMobile && {iconType: IconType.Rent})
            });
          }
          break;

        case Action.PURCHASE_IN_PROGRESS:
          return ({
            text: t('payments.inProgress'),
            onPress: () => {},
            disabled: true,
            ...(isMobile && {iconType: IconType.Buy})
          });

        case Action.ADD_TO_WATCHLIST:
          return ({
            text: t('mediaDetails.watchList'),
            onPress: () => onAddToWatchListPress(media),
            iconType: IconType.Add
          });

        case Action.REMOVE_FROM_WATCHLIST:
          return ({
            text: t('mediaDetails.watchList'),
            onPress: () => onRemoveFromWatchListPress(media),
            iconType: IconType.Remove
          });

        case Action.SEEN_IT:
          // Design doesn't provide information about this button on mobile.
          if (isBigScreen) {
            return ({text: t('mediaDetails.seenIt'), onPress: actionNotImplemented});
          }
          break;

        case Action.SELECT_SEASON:
          if (isBigScreen && selectedSeason) {
            return ({
              text: t('mediaDetails.seasonNumberFull', {seasonNumber: selectedSeason.seasonNumber}),
              iconType: IconType.Collapse,
              iconPosition: IconPosition.RIGHT,
              iconSize: 10,
              onPress: onSelectSeasonPress,
              testID: 'button_select_season'
            });
          }
          break;

        case Action.RECORD:
          return ({
            text: t('mediaDetails.record'),
            onPress: () => onRecordPress(media),
            ...(isMobile && {iconType: IconType.RecordDot})
          });

        case Action.RECORD_SERIES:
          return ({
            text: t('mediaDetails.recordSeries'),
            onPress: () => onRecordPress(media),
            ...(isMobile && {iconType: IconType.RecordSeries})
          });

        case Action.RECORD_ALL:
          return ({
            text: t('mediaDetails.recordAll'),
            onPress: () => onRecordPress(media),
            ...(isMobile && {iconType: IconType.RecordSeries})
          });

        case Action.DELETE_RECORDING:
          return ({
            text: t('mediaDetails.deleteRecording'),
            onPress: () => onDeleteRecordingPress(media),
            ...(isMobile && {iconType: IconType.DeleteRecordingDot})
          });

        case Action.CANCEL_RECORDING:
          return ({
            text: t('mediaDetails.cancelRecording'),
            onPress: () => onCancelRecordingPress(media, RecordingType.Single),
            ...(isMobile && {iconType: IconType.CancelRecordingDot})
          });

        case Action.CANCEL_SERIES_RECORDING:
          return ({
            text: t('mediaDetails.cancelSeries'),
            onPress: () => onCancelRecordingPress(media, RecordingType.Series),
            ...(isMobile && {iconType: IconType.CancelRecordingDot})
          });

        case Action.RESTART:
          return ({
            text: isEvent(media) && media.isPast ? t('mediaDetails.goBack') : t('mediaDetails.restart'),
            onPress: startPlayback,
            ...(isMobile && {iconType: IconType.Restart})
          });

        case Action.UNLOCK:
          return ({
            text: t('common.enterPin'),
            onPress: unlockAdultContent,
            style: {width: undefined}
          });
        case Action.WATCH_ON:
          return ({
            text: t('deepLinking.watchOn'),
            onPress: () => onWatchOnPress?.(media),
            ...(isMobile && {iconType: IconType.Play})
          });
        case Action.CUSTOM:
          const customAction = action as CustomAction;
          return ({
            text: customAction.label,
            onPress: () => onCustomActionPress?.(customAction.key, customAction.value),
            ...(isMobile && {iconType: IconType.Play})
          });
        default:
          break;
      }
      Log.info(TAG, 'Ommited button for action: ' + action);
      return null;
    });

    if (actions.find(action => action === Action.RATE)) {
      if (isBigScreen) {
        mappedProps.push({iconType: IconType.Like, onPress: actionNotImplemented});
        mappedProps.push({iconType: IconType.Dislike, onPress: actionNotImplemented});
      } else {
        mappedProps.push({iconType: IconType.Like, text: t('mediaDetails.like'), onPress: actionNotImplemented});
      }
    }

    return mappedProps;
  }, [actions, t, startPlayback, startLivePlayback, startRecordingPlayback, selectedSeason, unlockAdultContent, i18n, media, buy, rent, onAddToWatchListPress, onRemoveFromWatchListPress, onSelectSeasonPress, onRecordPress, onDeleteRecordingPress, onCancelRecordingPress, onWatchOnPress, onCustomActionPress]);

  const [width, setWidth] = useState(0);
  const onLayout = useCallback(({nativeEvent: {layout: {width}}}) => {
    setWidth(width);
  }, []);
  const buttonsFittingOnScreen = width / minButtonWidth;
  const buttonsOverflow = isMobile && buttonPropsList.length > Math.floor(buttonsFittingOnScreen);
  const buttonWidth = isMobile
    ? (buttonsOverflow ? Math.floor(width / (buttonsFittingOnScreen - 0.25)) : minButtonWidth)
    : undefined;

  const ButtonContainer: React.FC<ViewProps> = useMemo(() => buttonsOverflow
    ? ({children, ...props}) => (
      <ScrollView
        contentContainerStyle={styles.buttonsContainer}
        horizontal
        {...props}
      >
        {children}
      </ScrollView>
    ) : ({children, ...props}) => (
      <View style={styles.buttonsContainer} {...props}>
        {children}
      </View>
    ), [buttonsOverflow, styles.buttonsContainer]);

  return (
    <>
      {!!buttonPropsList.length && (
        <FocusParent style={props.style} enterStrategy='byPriority' focusPriority={1}>
          {isBigScreen &&
            actions.find(action => isCustomAction(action) && action.isDeeplinkingAction) &&
            (
              <NitroxText textType='buttons' style={styles.watchOnLabel}>
                {t('deepLinking.watchOn', {postProcess: PostProcessors.ToUpperCase})}
              </NitroxText>
            )
          }
          <ButtonContainer onLayout={onLayout}>
            {buttonPropsList.map((buttonProps, index) => (
              <ActionButton
                key={index}
                focusPriority={+(index === 0)}
                hasTVPreferredFocus={!isTVOS && (index === 0) && hasTVPreferredFocus}
                style={[styles.button, {width: buttonWidth}]}
                textStyle={buttonProps.iconType && isMobile && styles.iconText}
                {...buttonProps}
              />
            ))}
          </ButtonContainer>
        </FocusParent>
      )}
      {isMobile && <View style={styles.bottomSeparator} />}
    </>
  );
};

export default React.memo(MediaDetailButtons);
