import crypto from 'crypto-js';

import {isAndroid, isATV, isIOS, isTizen, isTVOS, isWebOS} from 'common/constants';

import {Error, ErrorType} from 'mw/api/Error';
import {boProxy} from 'mw/bo-proxy/BOProxy';
import {deviceInfo} from 'mw/platform/device-info/DeviceInfo';

export class Utils {

  public static generateCpeId(): string {
    const platformId = Utils.getPlatformId();
    const manufacturer = deviceInfo.getManufacturer();
    const model = deviceInfo.getModel();
    const customerId = boProxy.sso.getIdentity().id;
    const deviceSpecificId = deviceInfo.getUniqueId();
    const baseCpeId = `${platformId}_${manufacturer}_${model}_${customerId}_${deviceSpecificId}`;

    return crypto.SHA1(baseCpeId).toString();
  }

  private static getPlatformId(): string {
    if (isATV) {
      return '100';
    } else if (isTizen) {
      return '110';
    } else if (isWebOS) {
      return '120';
    } else if (isAndroid) {
      return '200';
    } else if (isIOS) {
      return '300';
    } else if (isTVOS) {
      return '310';
    } else {
      return '400';
    }
  }

  public static sha1ToUUID5(hash: string): string {
    const uuid = hash.substring(0, 8) +
      '-' + hash.substring(8, 12) +
      // four most significant bits holds version number 5
      '-' + ((parseInt(hash.substring(12, 16), 16) & 0x0fff) | 0x5000).toString(16) +
      // two most significant bits holds zero and one for variant DCE1.1
      '-' + ((parseInt(hash.substring(16, 20), 16) & 0x3fff) | 0x8000).toString(16) +
      '-' + hash.substring(20, 32); //12 digits
    return uuid;
  }

  public static getToken(unauthorizedCallback?: () => void): Promise<string> {
    return boProxy.sso.getToken()
      .catch((error: Error) => {
        if (error.type === ErrorType.SSOUnauthorized) {
          unauthorizedCallback?.();
        }
        return Promise.reject(error);
      });
  }
}
