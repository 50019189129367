import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {Log} from 'common/Log';

import {Error, ErrorType} from 'mw/api/Error';
import {Media, isEvent, RecordingType, Recording} from 'mw/api/Metadata';

import ErrorPopup, {useErrorPopup} from 'components/ErrorPopup';

import {useRecordingsMetadata, useScheduleRecording, useCancelRecording, useCancelSeriesRecordingForEvent, useChangeSeriesRecordingParams, useResumeRecording} from './RecordingHooks';
import {RecordActionType, getRecordActionType} from './RecordingUtils';

const TAG = 'Record';
const errorDelayInMs = 50;

export type RecordingsComponentsHandlers = {
  onSeriesModalClose?: () => void;
}

export interface WithRecord {
  renderRecordingsComponents: (handlers?: RecordingsComponentsHandlers) => JSX.Element;

  scheduleRecording: (media: Media) => Promise<void>;
  changeRecordingsParams: (recording: Recording) => Promise<void>;
  getRecordActionTypeForEvent: (media: Media) => Promise<RecordActionType>;
  isParamsChangeAvailableForRecording: (recording: Recording) => boolean;

  cancelRecordingForEvent: (media: Media, type: RecordingType) => Promise<void>;
  cancelRecording: (recording: Recording) => Promise<void>;
  isSeriesCancelAvailable: (recording: Recording) => boolean;

  resumeRecording: (recording: Recording) => Promise<void>;
  isResumeAvailable: (recording: Recording) => boolean;
}

export const useRecord = (): WithRecord => {
  const {t} = useTranslation();
  const {loading, relatedRecordings, currentEvent, currentSeasons, setCurrentEvent} = useRecordingsMetadata();
  const {scheduleRecording: scheduleRecordingImpl, renderSeriesRecordingModal} = useScheduleRecording(currentEvent, currentSeasons, relatedRecordings, loading);
  const {changeRecordingsParams: changeRecordingsParamsImpl, isParamsChangeAvailableForRecording, renderChangeSeriesRecordingParamsModal} = useChangeSeriesRecordingParams();
  const {cancelSeriesForEvent: cancelSeriesForEventImpl, renderCancelSeriesRecordingPopup} = useCancelSeriesRecordingForEvent(currentEvent, relatedRecordings, loading);
  const {cancelRecording: cancelRecordingImpl, isSeriesCancelAvailable, renderCancelRecordingPopup} = useCancelRecording();
  const {resumeRecording: resumeRecordingImpl, isResumeAvailable, renderResumeRecordingModal} = useResumeRecording();
  const {error, showError: showErrorImpl, onCloseErrorPopup} = useErrorPopup();

  const showError = useCallback(params => {
    // TODO CL-2632 Delay showing error popup to avoid focus issues
    setTimeout(() => {
      showErrorImpl(params);
    }, errorDelayInMs);
  }, [showErrorImpl]);

  const getRecordActionTypeForEvent = useCallback(async (media: Media): Promise<RecordActionType> => {
    if (!isEvent(media)) {
      return RecordActionType.none;
    }
    const currentRecordings = await setCurrentEvent(media);
    return getRecordActionType(
      media,
      currentRecordings?.eventRecording,
      currentRecordings?.seriesRecording
    );
  }, [setCurrentEvent]);

  const scheduleRecording = useCallback((media: Media): Promise<void> => {
    if (!isEvent(media)) {
      Log.warn(TAG, `Failed to schedule a new recording for media ${media} - an event is required`);
      return Promise.reject(new Error(ErrorType.InvalidParameter, 'Provided media is not an event'));
    }

    setCurrentEvent(media);
    return scheduleRecordingImpl(media)
      .catch((error) => {
        if (error instanceof Error && error.type === ErrorType.BOPvrQuotaExceeded) {
          showError({
            title: t('recordings.errors.quotaExceeded.title'),
            message: t('recordings.errors.quotaExceeded.message')
          });
        } else {
          showError(t('recordings.errors.scheduleRecordingFailed'));
        }
      });
  }, [scheduleRecordingImpl, setCurrentEvent, showError, t]);

  const changeRecordingsParams = useCallback((recording: Recording): Promise<void> => {
    return changeRecordingsParamsImpl(recording)
      .catch((err) => {
        showError(err);
      });
  }, [changeRecordingsParamsImpl, showError]);

  const cancelRecording = useCallback((recording: Recording): Promise<void> => {
    return cancelRecordingImpl(recording)
      .catch(err => {
        showError(t('recordings.errors.cancelRecordingFailed'));
      });
  }, [cancelRecordingImpl, showError, t]);

  const cancelRecordingForEvent = useCallback((media: Media, type: RecordingType): Promise<void> => {
    if (!isEvent(media)) {
      Log.warn(TAG, `Failed to cancel a recording for media ${media} - an event is required`);
      return Promise.reject(new Error(ErrorType.InvalidParameter, 'Provided media is not an event'));
    }

    if (type === RecordingType.Single) {
      return setCurrentEvent(media).then(recordings => {
        if (recordings?.eventRecording) {
          return cancelRecording(recordings.eventRecording);
        } else {
          Log.warn(TAG, `No recording found for given event ${media.title} and type ${type}`);
          return Promise.reject(new Error(ErrorType.InvalidParameter, `No recording found for given event ${media.title} and type ${type}`));
        }
      });
    } else {
      setCurrentEvent(media);
      return cancelSeriesForEventImpl(media)
        .catch(err => {
          showError(t('recordings.errors.cancelRecordingFailed'));
        });
    }
  }, [cancelRecording, cancelSeriesForEventImpl, setCurrentEvent, showError, t]);

  const resumeRecording = useCallback((recording: Recording): Promise<void> => {
    return resumeRecordingImpl(recording)
      .catch((err) => {
        showError(err);
      });
  }, [resumeRecordingImpl, showError]);

  const renderRecordingsComponents = useCallback((handlers?: RecordingsComponentsHandlers) => {
    return (
      <>
        {renderSeriesRecordingModal(handlers?.onSeriesModalClose)}
        {renderCancelRecordingPopup()}
        {renderCancelSeriesRecordingPopup()}
        {renderChangeSeriesRecordingParamsModal()}
        {renderResumeRecordingModal()}
        <ErrorPopup
          error={error}
          onClose={onCloseErrorPopup}
        />
      </>
    );
  }, [error, onCloseErrorPopup, renderCancelRecordingPopup, renderCancelSeriesRecordingPopup, renderChangeSeriesRecordingParamsModal, renderSeriesRecordingModal, renderResumeRecordingModal]);

  return {
    renderRecordingsComponents,
    scheduleRecording,
    changeRecordingsParams,
    getRecordActionTypeForEvent,
    isParamsChangeAvailableForRecording,
    cancelRecordingForEvent,
    cancelRecording,
    isSeriesCancelAvailable,
    resumeRecording,
    isResumeAvailable
  };
};

type WrappedProps<T> = Omit<T, keyof WithRecord>;
export function withRecord<Props extends WithRecord>(WrappedComponent: React.ComponentType<Props>): React.ComponentType<WrappedProps<Props>> {
  function Wrapped(props: WrappedProps<Props>) {
    return (
      <WrappedComponent
        {...props as Props}
        {...useRecord()}
      />
    );
  }
  Wrapped.displayName = WrappedComponent.displayName;
  return Wrapped;
}
