import React from 'react';
import {Image, StyleProp, ImageStyle} from 'react-native';

import {Icon} from 'components/Icon';
import {MenuIcon} from 'components/navigation/MainMenu';

type Props = {
  isSelected: boolean;
  source: MenuIcon;
  iconSize: number;
  iconColor: string;
  style?: StyleProp<ImageStyle>;
}

const MainMenuIcon: React.FC<Props> = ({
  isSelected,
  source: {pngUrl, svgType},
  iconSize,
  iconColor,
  style
}) => {
  if (pngUrl) {
    return <Image style={style} source={isSelected ? pngUrl.active : pngUrl.inactive} />;
  } else if (svgType) {
    return <Icon type={isSelected ? svgType.active : svgType.inactive} size={iconSize} color={iconColor} />;
  }
  return null;
};

export default React.memo(MainMenuIcon);
