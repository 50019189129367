import {FileLogger, defaultLogLevel, levels, LogLevel} from 'common/fileLogger/FileLogger';

export {
  LogLevel
};

type ReplacerFunction = (key: string, value: unknown) => unknown;
/**
 * This is useful for logging. It works like {@link JSON.stringify} but does not throw on circular references.
 *
 * The implementation is based on:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value#Examples
 */
export const stringify = (object: unknown, replacer?: ReplacerFunction | string[] | null, space?: number | string) => {
  const seen = new WeakSet();
  return JSON.stringify(
    object,
    (key: string, value: unknown) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return '[Circular]';
        }
        seen.add(value);
      }
      if (typeof replacer === 'function') {
        return replacer(key, value);
      }
      if (Array.isArray(replacer)) {
        return replacer.includes(key) ? value : null;
      }
      return value;
    },
    space
  );
};

/**
 * Turns any kind of object or primitive value into a loggable string.
 * If an object provides its own `toString` method, then the method is used.
 * Otherwise, the object is turned into a string describing object's content - no more `[object Object]` in logs.
 *
 * @param arg an object or a primitive value that you would like to turn into a string
 */
export const toString = (arg: unknown) => {
  if (typeof arg === 'object') {
    if (arg === null) {
      return 'null';
    }
    if (arg.toString && arg.toString !== Object.prototype.toString) {
      return arg.toString();
    }
    return arg.constructor.name + stringify(arg);
  }
  if (typeof arg === 'function') {
    return `[function ${arg.name}]`;
  }
  return arg === undefined ? 'undefined' : arg;
};

export class Log {
  private static logLevel: LogLevel = defaultLogLevel;

  private static getSeverity = (logLevel: LogLevel) => Object.keys(levels)[logLevel] as keyof typeof LogLevel

  public static setLogLevel(logLevel: LogLevel) {
    Log.logLevel = logLevel;
    FileLogger.setSeverity(this.getSeverity(logLevel));
  }

  public static error(tag: string, msg: string, ...args: unknown[]) {
    Log.logInternal(LogLevel.ERROR, tag, msg, args);
  }

  public static warn(tag: string, msg: string, ...args: unknown[]) {
    Log.logInternal(LogLevel.WARN, tag, msg, args);
  }

  public static info(tag: string, msg: string, ...args: unknown[]) {
    Log.logInternal(LogLevel.INFO, tag, msg, args);
  }

  public static ta(tag: string, msg: string, ...args: unknown[]) {
    Log.logInternal(LogLevel.TA, tag, msg, args);
  }

  public static debug(tag: string, msg: string, ...args: unknown[]) {
    Log.logInternal(LogLevel.DEBUG, tag, msg, args);
  }

  public static trace(tag: string, msg: string, ...args: unknown[]) {
    Log.logInternal(LogLevel.TRACE, tag, msg, args);
  }

  public static profiler(tag: string, msg: string, ...args: unknown[]) {
    Log.logInternal(LogLevel.PROFILER, tag, msg, args);
  }

  private static logInternal(level: LogLevel, tag: string, msg: string, args: unknown[]) {
    if (Log.logLevel > level) {
      return;
    }
    console.log(`(${tag}) ${LogLevel[level]}: ${msg}`, ...args);
    FileLogger[this.getSeverity(level)](
      [
        `(${tag}): ${msg}`,
        ...args.map(arg => toString(arg))
      ].join(' ')
    );
  }
}
