import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions, isBigScreen, isPhone} from 'common/constants';
import {formatCurrency} from 'common/utils';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Product} from 'mw/api/Metadata';

import NitroxText from 'components/NitroxText';
import {formatRentalDuration} from 'components/payments/PaymentHelperFunctions';
import {getBestOffer} from 'components/payments/ProductsList';

const styles = createStyles({
  container: {
    alignItems: 'center'
  },
  title: {
    paddingTop: dimensions.margins.large,
    paddingBottom: dimensions.margins.small
  },
  addFee: {
    paddingBottom: isPhone ? dimensions.margins.small : dimensions.margins.large,
    paddingTop: dimensions.margins.xsmall
  },
  productTitlePhone: {
    paddingBottom: dimensions.margins.large
  },
  productDescription: {
    marginBottom: isBigScreen ? dimensions.margins.medium : dimensions.margins.xxLarge,
    marginTop: dimensions.margins.xxLarge
  },
  rentalLabel: {
    paddingBottom: dimensions.margins.xxLarge
  }
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  text: {
    color: colors.popup.text,
    textAlign: 'center'
  }
}));

type Props = {product: Product;};

const SelectedProduct: React.FunctionComponent<Props> = props => {
  const {product} = props;

  const {t, i18n} = useTranslation();
  const dynamicStyle = stylesUpdater.getStyles();

  const offer = getBestOffer(product.offers);
  const addFeeText = t('payments.dialog.addFee', {price: formatCurrency(offer.price, offer.currency, i18n.language)});
  const {dialogTitle, rentalLabel} = useMemo(() => {
    if (product.duration && product.duration > 0) {
      return {
        dialogTitle: t('payments.dialog.rent'),
        rentalLabel: (
          <NitroxText
            textType={'paymentProductDescription'}
            style={[dynamicStyle.text, styles.rentalLabel]}
          >
            {formatRentalDuration(product.duration, t)}
          </NitroxText>
        )
      };
    }
    return {
      dialogTitle: t('payments.dialog.buy'),
      rentalLabel: null
    };
  }, [t, dynamicStyle, product.duration]);

  return (
    <View style={styles.container}>
      <NitroxText
        textType={'dialog-title'}
        style={[dynamicStyle.text, styles.title]}
      >
        {dialogTitle}
      </NitroxText>
      <NitroxText
        textType={'paymentProductDescription'}
        style={[dynamicStyle.text, styles.addFee]}
      >
        {addFeeText}
      </NitroxText>
      <NitroxText
        textType={'paymentProductPrice'}
        style={[dynamicStyle.text, isPhone && styles.productTitlePhone]}
      >
        {product.name}
      </NitroxText>
      {!isPhone && (
        <NitroxText
          textType={'paymentProductDescription'}
          style={[dynamicStyle.text, styles.productDescription]}
        >
          {product.description}
        </NitroxText>
      )}
      {rentalLabel}
    </View>
  );
};

export default SelectedProduct;
