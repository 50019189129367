import {createStyles} from 'common-styles';
import i18next from 'i18next';
import React, {memo, useRef, useState, useEffect, useCallback, useMemo, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {View, StyleSheet} from 'react-native';

import {isBigScreen, dimensions, isMobile, AppRoutes, isDesktopBrowser} from 'common/constants';
import {DateUtils} from 'common/DateUtils';
import {getBuyProducts, getMediaDuration, getRentProducts, isSeriesEpisode, replaceMediaDetails} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Media, isTitle, isRecording, isSingleRecording, PlaybackLimitations, Title, Product} from 'mw/api/Metadata';
import {PlayerEvent, PositionChanged} from 'mw/api/PlayerEvent';
import {mw} from 'mw/MW';
import {isChromecastSupported} from 'mw/platform/chromecast/ChromecastInterface';
import {Player, RewindDirection} from 'mw/playback/Player';

import BackButton from 'components/BackButton';
import {ChromecastContext} from 'components/ChromecastContext';
import FocusParent, {useFocusParent} from 'components/FocusParent';
import {useFullScreenControl} from 'components/fullscreen/FullScreenControlProvider';
import FullScreenTouchableView from 'components/fullscreen/FullScreenTouchableView';
import {useParentalControl, PCMedia} from 'components/parentalControl/ParentalControlProvider';
import PaymentFlow from 'components/payments/PaymentFlow';
import {isPurchaseAllowed} from 'components/payments/PaymentHelperFunctions';
import PlayerControlsView, {PlayerControlsHandlers, PlayerControlsViewButtonsVisibility, PlaybackControlsVisibility} from 'components/player/PlayerControlsView';
import PlayerLanguageEventHandler, {PlayerTracksInfo} from 'components/player/PlayerLanguageEventHandler';
import {usePlayerLauncher} from 'components/playerLauncher/PlayerLauncher';
import ShortDetails, {getTitleMetadata, getEpisodeMetadata, getRecordingMetadata, Metadata} from 'components/ShortDetailsView';
import VodBingeWatchingMobileOverlay from 'components/vod/VodBingeWatchingMobileOverlay';
import VodSeriesMobileOverlay from 'components/vod/VodSeriesMobileOverlay';
import MediaDetails from 'components/zapper/MediaDetails';
import {DetailsButtonsHandlers} from 'components/zapper/MediaDetailsTemplate';
import ProgressBarView from 'components/zapper/ProgressBarView';
import SeriesDetails, {SeriesDetailsType} from 'components/zapper/SeriesDetails';
import {useFunction, useEventListener, useDisposableCallback, useLazyEffect, useDisposable, useNavigation} from 'hooks/Hooks';
import {useRewindTooltips} from 'screens/tv/TvScreenHooks';

const TAG = 'MediaOverlay';

const timeFormat = 'HH:mm:ss';
const progressBarWidth = isBigScreen ? '80%' : '90%';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: colors.playerScreen.overlay
  },
  controls: {
    ...StyleSheet.absoluteFillObject
  },
  progressBar: {
    position: 'absolute',
    alignSelf: 'center',
    width: progressBarWidth,
    bottom: isBigScreen ? '6%' : dimensions.margins.xxxLarge
  },
  hidden: {
    opacity: 0
  },
  backButton: {
    ...isBigScreen && {
      width: undefined,
      right: 0
    }
  }
}));

const defaultPlaybackControlsVisibility: PlaybackControlsVisibility = {
  restart: false,
  fastBackwards: false,
  skipBack: false,
  pausePlay: false,
  skipForward: false,
  fastForward: false,
  goToLive: false,
  subtitles: false
};

const getMediaMetadata = (media: Media, t: i18next.TFunction, isMediaBlocked: (media: PCMedia) => boolean): Metadata | null => {
  if (isTitle(media)) {
    if (isSeriesEpisode(media)) {
      return getEpisodeMetadata(media);
    } else {
      return getTitleMetadata(media);
    }
  } else if (isSingleRecording(media)) {
    return getRecordingMetadata(media, t, isMediaBlocked) || null;
  }
  Log.error(TAG, 'Unable to compute metadata from unsupported media type ' + media.getType());
  return null;
};

type Props = {
  player: Player;
  media: Media;
  visible: boolean;
  showPlayerOverlay: () => void;
  hidePlayerOverlay: () => void;
  heartbeat: () => void;
  playerControlsHandlers: PlayerControlsHandlers;
  playerPaused: boolean;
  playerRewindDirection?: RewindDirection;
  detailsHandlers: DetailsButtonsHandlers;
  playbackError?: boolean;
}

const MediaOverlay: React.FC<Props> = ({
  showPlayerOverlay,
  hidePlayerOverlay,
  visible: propsVisible,
  heartbeat,
  media,
  playerControlsHandlers: propsPlayerControlsHandlers,
  playerPaused,
  playerRewindDirection,
  player,
  detailsHandlers,
  playbackError
}) => {
  const [duration, setDuration] = useState(getMediaDuration(media, mw.players.main.contentType));
  const [playerPosition, setPlayerPosition] = useState(
    // possible timestamp position
    (mw.players.main.getParameters().position || 0) > duration ? 0 : mw.players.main.getParameters().position || 0
  );
  const [showEpisodesOverlay, setShowEpisodesOverlay] = useState(false);
  const [playbackControls, setPlaybackControls] = useState<PlaybackControlsVisibility>(defaultPlaybackControlsVisibility);
  const [onOverlayParentReady, focusOverlay] = useFocusParent();
  const [, focusControls] = useFocusParent();
  const isEpisode = isTitle(media) && isSeriesEpisode(media);
  const getEpisodes = useDisposable((id: string) => mw.catalog.getSeriesEpisodesById(id));
  const [isLastEpisode, setIsLastEpisode] = useState(false);
  const [isNextEpisodeEntitled, setIsNextEpisodeEntitled] = useState<boolean>();
  const changingPosition = useRef<boolean>(false);
  const {t} = useTranslation();
  const {availableDevices} = useContext(ChromecastContext);
  const navigation = useNavigation();
  const {isMediaBlocked} = useParentalControl();
  const [isPaymentPopupVisible, setIsPaymentPopupVisible] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [paymentTitle, setPaymentTitle] = useState<Title>();

  // calculate this once per media change, instead of checking this on every positionChange event
  useEffect(() => {
    if (isTitle(media) && isSeriesEpisode(media) && media.episode) {
      getEpisodes(media.episode.seriesId)
        .then(episodes => {
          const currentEpisodeIndex = episodes.findIndex((episode) => episode.id === media.id);
          if (currentEpisodeIndex === episodes.length - 1) {
            setIsLastEpisode(true);
          } else {
            setIsNextEpisodeEntitled(episodes[currentEpisodeIndex + 1].isEntitled);
          }
        })
        .catch(() => Log.error(TAG, `Error while getting ${media.episode?.seriesId} episodes`));
    }
  }, [media, getEpisodes]);
  const [bingeActive, setBingeActive] = useState(false);
  /** once canceled binge for media persists until media change, this simplifies binge cancel logic */
  const [bingeCanceled, setBingeCanceled] = useState(false);

  useEffect(() => {
    setBingeCanceled(false);
  }, [media]);

  const visible = propsVisible || bingeActive;

  // TODO: CL-1766
  const optionsButtonVisible = !isBigScreen && !isRecording(media);

  useLazyEffect(() => {
    setImmediate(() => focusOverlay());
  }, [], [focusOverlay]);

  useEffect(() => {
    if (visible && !bingeActive) {
      focusControls('byPriority');
    }
  }, [visible, focusControls, bingeActive]);

  const onProgressChange = useFunction((position: number) => {
    changingPosition.current = true;
    const newPosition = position * duration;
    const roundedNewPosition = Math.round(newPosition);
    mw.players.main.changeParameters({position: roundedNewPosition / DateUtils.msInSec});
    setPlayerPosition(newPosition);
    heartbeat();
  });

  const updateDuration = useCallback(() => {
    const duration = getMediaDuration(media, mw.players.main.contentType);
    if (!duration) {
      Log.warn(TAG, `Failed to compute duration for media ${media} of type ${mw.players.main.contentType}`);
    }
    setDuration(duration);
  }, [media]);

  useEffect(updateDuration, [updateDuration]);

  const updatePlaybackControls = useCallback((limitations: PlaybackLimitations) => {
    setPlaybackControls(prev => {
      const controls = {
        restart: limitations.allowRestart ?? isBigScreen,
        fastBackwards: isBigScreen && (limitations.allowSkipBackward ?? false),
        skipBack: !isBigScreen && (limitations.allowSkipBackward ?? false),
        pausePlay: limitations.allowPause ?? false,
        skipForward: !isBigScreen && (limitations.allowSkipForward ?? false),
        fastForward: isBigScreen && (limitations.allowSkipForward ?? false),
        goToLive: limitations.allowGoToLive ?? false,
        subtitles: prev.subtitles
      };
      Log.info(TAG, 'Updating playback controls visibility:', controls);
      return controls;
    });
  }, []);
  useEventListener(PlayerEvent.PlaybackLimitationsChanged, updatePlaybackControls, mw.players.main);

  const onFirstFrame = useCallback(() => {
    changingPosition.current = false;
    updateDuration(); // content type is required to compute media duration, which is unknown before the first frame event
    updatePlaybackControls(mw.players.main.getPlaybackLimitations());
  }, [updateDuration, updatePlaybackControls]);

  useEventListener(PlayerEvent.FirstFrame, onFirstFrame, mw.players.main);
  const {renderPlayerLauncherComponent, startPlayback} = usePlayerLauncher({shouldReplaceMediaPlayer: true});

  const onWatch = useCallback((media: Media, unlocked?: boolean) => {
    if (bingeActive) {
      setBingeCanceled(true);
    }
    if (isTitle(media)) {
      startPlayback({media, unlocked});
    }
  }, [bingeActive, startPlayback]);

  const changeEpisode = useCallback(async (delta: number) => {
    if (!media || !isTitle(media) || !isSeriesEpisode(media) || !media.episode) {
      return;
    }
    const episodes = await getEpisodes(media.episode.seriesId);
    const currentEpisodeIndex = episodes.findIndex((episode) => episode.id === media.id);

    const targetEpisode = episodes[currentEpisodeIndex + delta];
    if (targetEpisode) {
      onWatch(targetEpisode, true);
    }
  }, [media, getEpisodes, onWatch]);

  const playNextEpisode = useCallback(() => changeEpisode(1), [changeEpisode]);
  const playPreviousEpisode = useCallback(() => changeEpisode(-1), [changeEpisode]);

  const playerControlsHandlers = useMemo(() => ({
    ...propsPlayerControlsHandlers,
    episodesHandler: () => {
      setShowEpisodesOverlay(true);
      if (!playerPaused && playerControlsHandlers.playPauseHandler) {
        playerControlsHandlers.playPauseHandler();
      }
    },
    playNextEpisodeHandler: playNextEpisode,
    playPrevEpisodeHandler: playPreviousEpisode
  }), [playerPaused, propsPlayerControlsHandlers, playNextEpisode, playPreviousEpisode]);

  const onVideoFinished = useCallback(async () => {
    if (!media || !navigation) return;

    const prevScreen = navigation.getParam('prevScreen');

    if (isTitle(media) && isSeriesEpisode(media)) {
      if (isLastEpisode) {
        if (prevScreen === AppRoutes.MediaDetail) {
          return navigation.goBack();
        }

        replaceMediaDetails(navigation, media.id, media.getType());
      }
    } else {
      if (prevScreen === AppRoutes.MediaDetail) {
        return navigation.goBack();
      }

      replaceMediaDetails(navigation, isSingleRecording(media) ? media.event.id : media.id, isSingleRecording(media) ? media.event.getType() : media.getType());
    }
  }, [media, navigation, isLastEpisode]);
  useEventListener(PlayerEvent.EndOfContent, onVideoFinished, mw.players.main);

  const onBingeBack = useCallback(() => {
    setBingeCanceled(true);
    setBingeActive(false);
  }, []);

  const onBingeStop = useCallback(() => {
    setBingeCanceled(true);
  }, []);

  const detailButtonsHandlers = useMemo(() => ({
    onWatch,
    onBingeBack,
    onBingeStop
  }), [onBingeBack, onBingeStop, onWatch]);

  const {enabled: fullScreenControlEnabled, fullScreenModeActive} = useFullScreenControl();
  const buttonsVisibility: PlayerControlsViewButtonsVisibility = useMemo(() => {
    return {
      collapse: false,
      episodes: isEpisode,
      recordDot: null,
      channelList: false,
      chromecast: isChromecastSupported() && !!availableDevices.length,
      options: optionsButtonVisible,
      prevEpisode: isEpisode,
      nextEpisode: isEpisode,
      fullscreen: fullScreenControlEnabled && !fullScreenModeActive,
      backFromFullscreen: fullScreenControlEnabled && fullScreenModeActive,
      showEPG: false,
      moreInfo: false,
      playback: playbackControls
    };
  }, [isEpisode, availableDevices.length, optionsButtonVisible, fullScreenControlEnabled, fullScreenModeActive, playbackControls]);

  const {tooltipsProps} = useRewindTooltips();

  const detailsProps = useMemo(() => ({
    handlers: detailButtonsHandlers,
    landscape: true,
    overlayVisible: visible,
    mediaListsVisible: true,
    extraData: media,
    focusedMedia: media
  }), [detailButtonsHandlers, visible, media]);

  const onPositionChanged = useDisposableCallback(async (params: PositionChanged) => {
    const currentPosition = params.position * 1000;
    const shouldShowBinge = isEpisode && duration - currentPosition <= 30 * 1000 && !isLastEpisode && !bingeCanceled && (isNextEpisodeEntitled || isPurchaseAllowed());

    if (!changingPosition.current) {
      setPlayerPosition(currentPosition);
    }

    if (!bingeActive && shouldShowBinge) {
      Log.debug(TAG, `Starting binge`);
      setBingeActive(true);
    }
    if (bingeActive && !shouldShowBinge) {
      Log.debug(TAG, `Stopping binge`);
      setBingeActive(false);
    }
  }, [duration, isEpisode, isLastEpisode, bingeCanceled, isNextEpisodeEntitled, bingeActive]);

  useEventListener(PlayerEvent.PositionChanged, onPositionChanged, mw.players.main);

  const renderDetails = useCallback(() => {
    if (isBigScreen) {
      if (isEpisode) {
        const type = bingeActive ? SeriesDetailsType.BINGE : (playerPaused ? SeriesDetailsType.PAUSED : SeriesDetailsType.OVERLAY);
        return (
          <SeriesDetails
            type={type}
            {...detailsProps}
            onBuyPress={openBuyPopup}
            onRentPress={openRentPopup}
          />
        );
      } else {
        return <MediaDetails {...detailsProps} />;
      }
    } else if (media) {
      const metadata = getMediaMetadata(media, t, isMediaBlocked);
      if (!metadata) {
        Log.error(TAG, `Failed to compute metadata for media: ${media}`);
        return false;
      }

      if (isEpisode && bingeActive) {
        return (
          <SeriesDetails
            type={SeriesDetailsType.BINGE}
            {...detailsProps}
            onBuyPress={openBuyPopup}
            onRentPress={openRentPopup}
          />
        );
      }

      return (
        <ShortDetails
          completeDetails
          landscape={true}
          heading={metadata.heading}
          title={metadata.title}
          subtitle={metadata.subtitle}
        />
      );
    }
    return null;
  }, [media, isEpisode, bingeActive, playerPaused, detailsProps, t, isMediaBlocked]);

  const onSeriesOverlayClose = useCallback(() => setShowEpisodesOverlay(false), []);

  const onPlayerTracksChanged = useCallback((playerTracksInfo: PlayerTracksInfo) => {
    setPlaybackControls(prev => ({
      ...prev,
      subtitles: playerTracksInfo.hasAudioOrSubtitleTracks()
    }));
  }, []);

  const onBackPressed = useCallback(() => {
    if (!visible) {
      showPlayerOverlay();
      return true;
    }
    return false;
  }, [visible, showPlayerOverlay]);

  const styles = stylesUpdater.getStyles();

  const backButtonVisible = useMemo(() => {
    if (!isMobile) {
      return true;
    }

    return !showEpisodesOverlay && !bingeActive;
  }, [bingeActive, showEpisodesOverlay]);

  const openBuyPopup = useCallback((title: Title) => {
    setPaymentTitle(title);
    setProducts(getBuyProducts(title));
    setIsPaymentPopupVisible(true);
  }, []);

  const openRentPopup = useCallback((title: Title) => {
    setPaymentTitle(title);
    setProducts(getRentProducts(title));
    setIsPaymentPopupVisible(true);
  }, []);

  const onClosePaymentPopup = useCallback(() => {
    setIsPaymentPopupVisible(false);
    setProducts([]);
    setPaymentTitle(undefined);
  }, []);

  const onWatchAfterPurchase = useCallback(() => {
    setIsPaymentPopupVisible(false);
    paymentTitle && onWatch(paymentTitle);
  }, [onWatch, paymentTitle]);

  return (
    <FocusParent
      onReady={onOverlayParentReady}
      enterStrategy='byPriority'
      style={[styles.container, visible ? {} : styles.hidden]}
    >
      <View style={StyleSheet.absoluteFillObject} {...{onMouseMove: heartbeat}}>
        <FullScreenTouchableView onPress={isDesktopBrowser ? showPlayerOverlay : hidePlayerOverlay}>
          <View style={styles.controls}>
            {(!isMobile || !bingeActive) && visible && (
              <PlayerControlsView
                landscape
                paused={playerPaused}
                rewindDirection={playerRewindDirection}
                handlers={playerControlsHandlers}
                buttonsVisibility={buttonsVisibility}
                tooltipsProps={tooltipsProps}
                focusPriority={1}
                renderDetails={renderDetails}
                focusOnReady
                playbackError={playbackError}
              />
            )}
          </View>
          {backButtonVisible && <BackButton navigation={navigation} style={styles.backButton} onPress={onBackPressed} hasTVPreferredFocus />}
          <View style={styles.progressBar}>
            {(!isMobile || !bingeActive) && (
              <ProgressBarView
                labelsFormat={timeFormat}
                currentTime={playerPosition}
                currentProgress={playerPosition / duration}
                endTime={duration}
                availableProgressEnd={1}
                availableProgressStart={0}
                startTimeLabelVisible={false}
                currentTimeLabelVisible={true}
                currentTimeLabelAlignment='right'
                onProgressChange={onProgressChange}
                isDraggableForward={buttonsVisibility.playback.skipForward}
                isDraggableBackward={buttonsVisibility.playback.skipBack}
                overlayVisible={visible}
              />
            )}
          </View>
          {isMobile && isTitle(media) && isEpisode && (
            <VodSeriesMobileOverlay
              title={media}
              visible={showEpisodesOverlay}
              navigation={navigation}
              onClose={onSeriesOverlayClose}
              handlers={detailsHandlers}
            />
          )}
          {bingeActive && isMobile && isTitle(media) && (
            <VodBingeWatchingMobileOverlay
              title={media}
              navigation={navigation}
              onClose={onBingeBack}
              handlers={{
                ...detailsHandlers,
                onRentPress: openRentPopup,
                onBuyPress: openBuyPopup
              }}
            />
          )}
          {renderPlayerLauncherComponent()}
          <PlayerLanguageEventHandler player={player} onTracksChanged={onPlayerTracksChanged} />
        </FullScreenTouchableView>
        {(isMobile || isDesktopBrowser) && !visible && (
          // It blocks the passage of touch through.
          <View style={StyleSheet.absoluteFillObject}>
            <FullScreenTouchableView onPress={showPlayerOverlay} />
          </View>
        )}
        {paymentTitle && (
          <PaymentFlow
            visible={isPaymentPopupVisible}
            media={paymentTitle}
            onWatch={onWatchAfterPurchase}
            products={products}
            onClose={onClosePaymentPopup}
            onPaymentSuccess={onClosePaymentPopup}
          />
        )}
      </View>
    </FocusParent>
  );
};

export default memo(MediaOverlay);
