import {createStyles} from 'common-styles';
import React, {forwardRef, useCallback, useState, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import {withNavigation, NavigationScreenProps} from 'react-navigation';

import {dimensions} from 'common/constants';
import {combineDateAndTime} from 'common/HelperFunctions';
import {NavigationFocusState} from 'common/HelperTypes';
import {Log} from 'common/Log';
import {getUniqueEvents} from 'common/utils';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Channel, Media, Event, isEvent} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import AnimatedSwimlaneMediaBasic from 'components/AnimatedSwimlaneMediaBasic';
import BackButton from 'components/BackButton';
import FocusParent from 'components/FocusParent';
import {mediaDetailHorizontalMargin} from 'components/mediadetails/MediaDetailUtils';
import {PlayerViews} from 'components/player/PlayerView';
import {useLazyEffect, useFunction} from 'hooks/Hooks';
import EpgBigScreenDetails from 'screens/epg/EpgBigScreenDetails';
import {getPlayerView} from 'screens/epg/EpgHelperFunctions';
import {EpgScreenPlayerViewLocation} from 'screens/epg/EpgHelperTypes';
import {usePipPlayerVisibility} from 'screens/epg/EpgHooks';

const TAG = 'ChannelDetailScreenGrosso';

const playerLocation: EpgScreenPlayerViewLocation = 'channelDetails';
const playerView = getPlayerView(playerLocation);
const barContainerPadding = 2 * dimensions.margins.xxxLarge;

const staticStyles = createStyles({
  backButton: {
    left: mediaDetailHorizontalMargin,
    zIndex: 1
  },
  mainContainer: {
    paddingLeft: 0,
    flex: 1
  },
  detailsContainer: {
    flex: 1
  },
  eventsContainer: {
    flex: 1
  }
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    justifyContent: 'flex-start',
    backgroundColor: colors.channelDetailsScreen.background,
    flex: 1
  },
  textColorFocused: colors.overlayMediaDetails.text.focused,
  textColorUnFocused: colors.overlayMediaDetails.text.unfocused,
  eventsSection: {
    flex: 1,
    backgroundColor: colors.channelDetailsScreen.events.background
  },
  headerContainer: {
    flex: 1,
    backgroundColor: colors.channelDetailsScreen.background,
    paddingTop: barContainerPadding,
    paddingLeft: dimensions.margins.xxLarge
  }
}));

const loadData = (channel: Channel, startTime: Date, endTime: Date) => {
  return mw.catalog.getEPG({
    channels: [channel],
    startTime,
    endTime
  });
};

async function *channelEventsFetcher(channel: Channel, startTime: Date, endTime: Date, setCurrentEvent: React.Dispatch<React.SetStateAction<Event | undefined>>) {
  try {
    const channelEpg = await loadData(channel, startTime, endTime);
    const events = getUniqueEvents(channelEpg.get(channel.id) || []);
    const dateInFocus = combineDateAndTime(startTime, new Date());
    const initialEvent = events.find((event: Event) => event.start <= dateInFocus && dateInFocus < event.end);
    if (initialEvent) {
      setCurrentEvent(initialEvent as Event);
    }
    return events;
  } catch {
    Log.warn(TAG, 'Failed to fetch channel events');
    return [];
  }
}

type ChannelDetailsGrossoProps = {
  channel?: Channel;
  onPipPlayerViewReady: (playerLocation: EpgScreenPlayerViewLocation) => void;
  focusState: NavigationFocusState;
  startTime: Date;
  endTime: Date;
} & NavigationScreenProps;

const ChannelDetails: React.FC<ChannelDetailsGrossoProps> = ({
  channel,
  focusState,
  onPipPlayerViewReady: onPipPlayerViewReadyFromProps,
  startTime,
  endTime,
  navigation
}) => {
  const {t} = useTranslation();
  const styles = stylesUpdater.getStyles();
  const [currentEvent, setCurrentEvent] = useState<Event>();
  const {isPlayerBlocked, tunePip} = usePipPlayerVisibility(TAG, playerView, focusState);

  //on entering EPG or changing channel list
  useLazyEffect(() => {
    if (focusState === NavigationFocusState.IsFocused && channel) {
      tunePip({playerView, channel});
    }
  }, [focusState, channel], [tunePip]);

  const onPipPlayerViewReady = useCallback(() => {
    onPipPlayerViewReadyFromProps(playerLocation);
  }, [onPipPlayerViewReadyFromProps]);

  const onMediaTileFocus = useFunction((media: Media) => {
    isEvent(media) && setCurrentEvent(media);
  });

  const dataFetcher = useMemo(() =>
    channel && channelEventsFetcher(channel, startTime, endTime, setCurrentEvent),
  [channel, endTime, startTime]);

  return (
    <View style={styles.container}>
      <FocusParent style={staticStyles.mainContainer}>
        <FocusParent style={staticStyles.detailsContainer}>
          <BackButton
            navigation={navigation}
            style={staticStyles.backButton}
            hasTVPreferredFocus={true}
          />
          {channel && (
            <EpgBigScreenDetails
              channel={channel}
              currentEvent={currentEvent}
              isPlayerBlocked={isPlayerBlocked}
              onPipPlayerViewReady={onPipPlayerViewReady}
              containerStyle={styles.headerContainer}
              playerType={PlayerViews.ChannelDetails}
            />
          )}
        </FocusParent>
        <FocusParent style={staticStyles.eventsContainer}>
          {channel && (
            <AnimatedSwimlaneMediaBasic
              header={t('channelDetails.nextEvents')}
              dataFetcher={dataFetcher}
              onTileFocusChange={onMediaTileFocus}
            />
          )}
        </FocusParent>
      </FocusParent>
    </View>
  );
};

export default withNavigation(forwardRef(ChannelDetails));
