import {createStyles} from 'common-styles';
import React, {useMemo, useState, useCallback, useRef} from 'react';
import {View, ViewStyle, StyleProp, LayoutChangeEvent, NativeSyntheticEvent, NativeScrollEvent} from 'react-native';
// eslint-disable-next-line no-restricted-imports
import {PanGestureHandler} from 'react-native-gesture-handler';
import Animated, {multiply} from 'react-native-reanimated';

import {Axis, isAndroid, commonAnimationTransforms} from 'common/constants';

import {useScroll} from 'components/epg/animated/OmniScroll';
import ScalableTile, {ScalableTileProps} from 'components/ScalableTile';
import {useLazyRef, useProperty, useDebounce} from 'hooks/Hooks';
import {useLateBinding} from 'hooks/Hooks';

const staticStyles = createStyles({
  container: {
    flexDirection: 'row',
    overflow: 'hidden'
  }
});

type ScalableScrollListProps<DataType> = {
  data: DataType[];
  itemSize: number;
  itemHeight?: number;
  initialSelectedIndex?: number;
  onRendered?: () => void;
  style?: StyleProp<ViewStyle>;
} & Pick<ScalableTileProps<DataType>, 'samples' | 'renderItem'>;

function removePendingItem<DataType>(pendingItems: DataType[], itemData: DataType): boolean {
  const pendingIndex = pendingItems.findIndex((pendingItem: DataType) => pendingItem === itemData);
  if (pendingIndex === -1) {
    return false;
  }
  pendingItems.splice(pendingIndex, 1);
  return pendingItems.length === 0;
}

function AndroidScalableScrollList<DataType>({
  data,
  renderItem,
  itemSize,
  itemHeight,
  initialSelectedIndex = 0,
  onRendered,
  samples,
  style
}: ScalableScrollListProps<DataType>) {
  const initialOffset = useLazyRef(() => -initialSelectedIndex * itemSize).current;
  const offset = useLazyRef(() => (
    new Animated.Value(initialOffset)
  )).current;
  const position = useLazyRef(() => (
    new Animated.Value(initialOffset)
  )).current;
  const boundaries = useLazyRef(() => ({
    minValue: new Animated.Value<number>(-(data.length - initialSelectedIndex) * itemSize),
    maxValue: new Animated.Value<number>(0)
  })).current;
  const snapToInterval = useLazyRef(() => new Animated.Value(itemSize)).current;

  const {onGestureEvent} = useScroll({
    ...boundaries,
    axis: Axis.X,
    position,
    offset,
    snapToInterval
  });

  const [containerWidth, setContainerWidth] = useState(0);

  const onContainerLayout = useCallback((event: LayoutChangeEvent) => {
    setContainerWidth(event.nativeEvent.layout.width);
  }, []);

  const [onItemRendered, bindOnItemRendered] = useLateBinding<(itemData: DataType) => void>();

  const [tiles, pendingItems] = useMemo(() => ([
    data.map((itemData, itemIndex) => (
      <ScalableTile
        key={itemIndex}
        itemIndex={itemIndex}
        itemSize={itemSize}
        itemData={itemData}
        renderItem={renderItem}
        onRendered={onItemRendered}
        containerPosition={multiply(position, -1)}
        containerWidth={containerWidth}
        samples={samples}
      />
    )),
    [...data]
  ]), [data, itemSize, renderItem, position, containerWidth, samples]);

  const onRenderedDebounced = useDebounce(() => onRendered?.(), 0);

  const adjustContainerPosition = useCallback(() => {
    position.setValue(-initialSelectedIndex * itemSize);
  }, [initialSelectedIndex, itemSize]);

  bindOnItemRendered(useCallback((itemData: DataType) => {
    if (!removePendingItem(pendingItems, itemData)) {
      return; // there are still some pending items to be rendered and scaled
    }
    // Reanimated does not support value's listeners so current container position is unknown therefore a simple debounce will suffice here
    adjustContainerPosition();
    onRenderedDebounced();
  }, [pendingItems, adjustContainerPosition, onRenderedDebounced]));

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const animatedStyle: Animated.AnimateStyle<ViewStyle> = useMemo(() => ({
    position: 'absolute',
    flexDirection: 'row',
    top: 0,
    left: Math.floor((containerWidth - itemSize) / 2),
    width: data.length * itemSize,
    height: '100%',
    transform: [
      {translateX: position ?? 0},
      ...commonAnimationTransforms
    ]
  }), [position, itemSize, data, containerWidth]);

  const containerStyle = useMemo(() => ([
    staticStyles.container, {height: itemHeight || itemSize}, style
  ]), [itemHeight, itemSize, style]);

  return (
    <View style={containerStyle} onLayout={onContainerLayout}>
      {containerWidth > 0 && (
        <PanGestureHandler
          onHandlerStateChange={onGestureEvent}
          onGestureEvent={onGestureEvent}
          shouldCancelWhenOutside={false}
        >
          <Animated.View style={animatedStyle}>
            {tiles}
          </Animated.View>
        </PanGestureHandler>
      )}
    </View>
  );
}

function GenericScalableScrollList<DataType>({
  data,
  renderItem,
  itemSize,
  initialSelectedIndex = 0,
  onRendered,
  samples,
  style
}: ScalableScrollListProps<DataType>) {
  const containerPosition = useLazyRef(() =>
    new Animated.Value<number>(initialSelectedIndex * itemSize)
  ).current;
  const [containerWidth, setContainerWidth] = useState(0);

  const onContainerLayout = useCallback((event: LayoutChangeEvent) => {
    setContainerWidth(event.nativeEvent.layout.width);
  }, []);

  // Reanimated ScrollView allows to set insets but setting them adds extra scrollable space above and below the content.
  const contentContainerStyle = useMemo(() => ({
    paddingHorizontal: Math.floor(containerWidth - itemSize) / 2
  }), [itemSize, containerWidth]);

  const [onItemRendered, bindOnItemRendered] = useLateBinding<(itemData: DataType) => void>();

  const [tiles, pendingItems] = useMemo(() => ([
    data.map((itemData, itemIndex) => (
      <ScalableTile
        key={itemIndex}
        itemIndex={itemIndex}
        itemSize={itemSize}
        itemData={itemData}
        renderItem={renderItem}
        onRendered={onItemRendered}
        containerPosition={containerPosition}
        containerWidth={containerWidth}
        samples={samples}
      />
    )),
    [...data]
  ]), [data, itemSize, renderItem, containerPosition, containerWidth, samples, onItemRendered]);

  const containerRef = useRef<Animated.ScrollView>(null);
  const [hasPendingScroll, setPendingScroll] = useProperty(false);
  const [getContainerPosition, setContainerPosition] = useProperty(0);

  const adjustContainerPosition = useCallback(() => {
    const requiredScrollViewOffset = initialSelectedIndex * itemSize;
    if (requiredScrollViewOffset === getContainerPosition() || !containerRef.current) {
      return false;
    }
    setPendingScroll(true);
    containerRef.current.getNode().scrollTo({
      x: requiredScrollViewOffset,
      animated: false
    });
    return true;
  }, [initialSelectedIndex, itemSize]);

  bindOnItemRendered(useCallback((itemData: DataType) => {
    if (!removePendingItem(pendingItems, itemData)) {
      return; // there are still some pending items to be rendered and scaled
    }
    if (adjustContainerPosition()) {
      return; // container's position needs adjustment
    }
    onRendered?.(); // all tiles are rendered and scaled and container position does not need adjustment
  }, [pendingItems, onRendered, adjustContainerPosition]));

  const onScroll = useCallback((event: NativeSyntheticEvent<NativeScrollEvent>) => {
    // update container position's property and animated value
    const offset = event.nativeEvent.contentOffset.x;
    setContainerPosition(offset);
    containerPosition.setValue(offset);
    // in case a scroll was required to finish the rendering cal the callback
    if (hasPendingScroll()) {
      setPendingScroll(false);
      onRendered?.();
    }
  }, [containerPosition, onRendered]);

  const contentOffset = useMemo(() => ({
    x: initialSelectedIndex * itemSize,
    y: 0
  }), [initialSelectedIndex, itemSize]);

  return (
    <View style={[staticStyles.container, style]} onLayout={onContainerLayout}>
      <Animated.ScrollView
        ref={containerRef}
        scrollEventThrottle={16}
        horizontal={true}
        bounces={false}
        contentOffset={contentOffset}
        showsHorizontalScrollIndicator={false}
        snapToInterval={itemSize}
        contentContainerStyle={contentContainerStyle}
        onScroll={onScroll}
      >
        {tiles}
      </Animated.ScrollView>
    </View>
  );
}

const ScalableScrollList = isAndroid ? AndroidScalableScrollList : GenericScalableScrollList;
export default React.memo(ScalableScrollList) as typeof ScalableScrollList;
