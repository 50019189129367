import {StyleProp, ViewStyle} from 'react-native';

import {isBigScreen} from 'common/constants';

import {SettingsDeleteProfileProps} from 'screens/settings/SettingsDeleteProfile';

import WizardNamePageContentGrosso from './WizardNamePageContent.grosso';
import WizardNamePageContentPiccolo from './WizardNamePageContent.piccolo';

export type WizardNamePageProps = {
  name: string;
  showTitle?: boolean;
  onNameChanged: (newName: string) => void;
  containerStyle?: StyleProp<ViewStyle>;
  embedded?: boolean;
  deleteProfileVisible?: boolean;
  onSubmitEditting?: () => void;
  onInputFocusChange?: (isInputFocused: boolean) => void;
} & SettingsDeleteProfileProps;

export default isBigScreen ? WizardNamePageContentGrosso : WizardNamePageContentPiccolo;
