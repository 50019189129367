import {createStyles} from 'common-styles';
import React, {useCallback} from 'react';
import {View} from 'react-native';

import {dimensions, isPhone, isMobile} from 'common/constants';

import NitroxButton, {NitroxButtonTheme} from 'components/NitroxButton';

const styles = createStyles({
  recordingsManagementSectionContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    ...isMobile && {
      paddingVertical: dimensions.margins.xLarge,
      height: dimensions.buttons.small
    },
    paddingHorizontal: isPhone ? dimensions.margins.large : 0
  }
});

export type Action = {
  key: string;
  text: string;
  onPress: () => void;
};

type Props = {
  actions: Action[];
}

const RecordingsManagementButtons: React.FC<Props> = React.memo(({actions}) => {
  const renderButton = useCallback((recordingsManagementAction: Action, index: number) => {
    const style = {
      height: dimensions.buttons.small,
      ...index && {marginLeft: isMobile ? dimensions.margins.large : dimensions.margins.xxLarge},
      ...(isPhone && actions.length > 1) && {flex: 1}
    };

    return (
      <NitroxButton
        border={!isMobile}
        key={recordingsManagementAction.key}
        style={style}
        theme={isMobile ? NitroxButtonTheme.Secondary : undefined}
        textType='callout'
        text={recordingsManagementAction.text}
        onPress={recordingsManagementAction.onPress}
      />
    );
  }, [actions.length]);

  return (
    <View style={styles.recordingsManagementSectionContainer}>
      {actions.map(renderButton)}
    </View>
  );
});

RecordingsManagementButtons.displayName = 'RecordingsManagementButtons';

export default RecordingsManagementButtons;
