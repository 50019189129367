import React, {useMemo} from 'react';
import {View, StyleProp, ViewStyle} from 'react-native';

import {Icon, IconType} from 'components/Icon';

export type SettingsDetailsHeaderProps = {
  icon: IconType;
  iconSize?: number;
  style?: StyleProp<ViewStyle>;
};

export function SettingsDetailsHeader(props: SettingsDetailsHeaderProps) {
  const {icon, iconSize = 100, style} = props;
  const iconContainer = useMemo(() => [{width: iconSize, height: iconSize}, style], [iconSize, style]);
  return (
    <View style={iconContainer}>
      <Icon type={icon} size={iconSize} />
    </View>
  );
}
