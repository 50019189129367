import {delay} from 'common/Async';

import {Error} from 'mw/api/Error';

interface RetryParams<T> {
  maxRetries: number;
  retryPeriod?: number;
  try: () => Promise<T>;
  beforeRetry: (error: Error) => Promise<T>;
  isRetryNeeded: (error: Error) => boolean;
  onMaxRetryExceeded: (error: Error) => void;
}

export class RetryManager<T> {
  private params: RetryParams<T>;
  private retryCount = 0;

  public constructor(params: RetryParams<T>) {
    this.params = params;
  }

  public try(): Promise<T> {
    return this.params.try()
      .catch((error: Error) => this.errorHandler(error));
  }

  private retry(error: Error): Promise<T> {
    return this.params.beforeRetry(error)
      .then(() => {
        if (this.retryCount++ < this.params.maxRetries) {
          return this.params.try()
            .catch((error: Error) => this.errorHandler(error));
        }

        this.params.onMaxRetryExceeded(error);
        return Promise.reject(error);
      });
  }

  private errorHandler(error: Error): Promise<T> {
    if (!this.params.isRetryNeeded(error)) {
      return Promise.reject(error);
    }

    if (this.params.retryPeriod) {
      return delay(this.params.retryPeriod).then(() => this.retry(error));
    }

    return this.retry(error);
  }
}
