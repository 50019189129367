import {isMobile, isWeb, isSmartTV} from 'common/constants';
import {DateUtils} from 'common/DateUtils';
import {Log} from 'common/Log';

import {PlaybackAsset} from 'mw/bo-proxy/BOInterface';
import {boProxy} from 'mw/bo-proxy/BOProxy';
import {mw} from 'mw/MW';
import {CreateSessionParams, PlaybackSession, PlaybackSessionManager, SessionTerminationReason, PlaybackSessionEvent} from 'mw/playback/sessions/PlaybackSessionManager';

const TAG = 'PlaybackSessionAdr8';

const enableGeoLocationCheck = isMobile || (isWeb && !isSmartTV);

class PlaybackSessionAdr8 extends PlaybackSession {
  private keepAliveTimer?: number;
  private geoLocationCheckTimer?: number;
  private assetId: string;

  public constructor(id: string, assets: PlaybackAsset[], assetId: string) {
    super(id, assets);
    this.assetId = assetId;
    const keepAliveInterval = mw.configuration.playbackSessionKeepAliveInterval;
    if (keepAliveInterval > 0) {
      Log.debug(TAG, `Starting to send keep alives every ${keepAliveInterval} seconds`);
      this.keepAliveTimer = setInterval(this.keepAlive, keepAliveInterval * DateUtils.msInSec);
    }
    const geoLocationCheckInterval = mw.configuration.playbackSessionGeoLocationCheckInterval;
    if (geoLocationCheckInterval > 0 && enableGeoLocationCheck) {
      Log.debug(TAG, `Starting GeoLocation checks every ${keepAliveInterval} seconds`);
      this.geoLocationCheckTimer = setInterval(this.geoLocationCheck, geoLocationCheckInterval * DateUtils.msInSec);
    }
  }

  public terminate(reason: SessionTerminationReason): Promise<void> {
    if (this.keepAliveTimer) {
      clearInterval(this.keepAliveTimer);
    }
    if (this.geoLocationCheckTimer) {
      clearInterval(this.geoLocationCheckTimer);
    }
    this.assetId = '';
    return Promise.resolve();
  }

  private keepAlive = (): void => {
    boProxy.bo.keepAlivePlaybackSession(this, {
      assetId: this.assetId,
      id: this.id
    });
  }

  private geoLocationCheck = (): void => {
    boProxy.bo.geoLocationPlaybackCheck({
      assetId: this.assetId
    })
      .catch((error: Error) => {
        Log.debug(TAG, 'Active session is not allowed in current GeoLocation');
        this.notify(PlaybackSessionEvent.SessionError, error);
      });
  }
}

export class ADR8PlaybackSessionManager extends PlaybackSessionManager {

  public createPlaybackSession(params: CreateSessionParams): Promise<PlaybackSession> {
    return boProxy.bo.createPlaybackSession({id: params.playable.getId(), assetId: params.media.id})
      .then(sessionData => {
        return new PlaybackSessionAdr8(params.playable.getId(), sessionData.assets, sessionData.id);
      });
  }

  public destroySession(session: PlaybackSession, reason: SessionTerminationReason): Promise<void> {
    session.terminate(reason);
    return Promise.resolve(); // no need destroying sessions at ADR8
  }
}
