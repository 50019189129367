import {createStyles} from 'common-styles';
import React, {useState, useRef, useCallback, useMemo, useEffect} from 'react';
import {StyleProp, ViewStyle, Animated, InteractionManager} from 'react-native';

import {Direction, isDesktopBrowser, isSTBBrowser, isTVOS} from 'common/constants';
import {useDelayedFocusTVOS} from 'common/hooks/useDelayedFocusTVOS';
import {Log} from 'common/Log';

import {useScreenInfo, useChangeEffect, useFunction, useDisposableCallback, useThrottle, useKeyEventHandler, useScrollHandler, useLazyEffect, useSynchronizedState} from 'hooks/Hooks';
import {useTVOSPanGesture} from 'hooks/rcuHooks';

import {animationDuration} from './AnimatedSwimlane';
import AnimatedScrollView from './epg/animated/AnimatedScrollView';
import FocusPrison from './focusManager/FocusPrison';
import {NativeKeyEvent, SupportedKeys} from './KeyEventManager';
import {useDiscreteWheelHandler} from './scroll/useDiscreteWheelHandler';
import {PreventDefaultWebScroll} from './scroll/usePreventDefaultWebScroll';

const TAG = 'AnimatedVerticalStack';
const standardBatchSize = 4;

const styles = createStyles({
  focusPrison: {
    flex: 1
  }
});

function calculateInitialBatchSize(screenSizeHeight: number, rows: {height: number}[]): number {
  let height = 0;
  let batchSize = 0;
  for (const row of rows) {
    ++batchSize;
    height += row.height;
    if (height >= screenSizeHeight) {
      break;
    }
  }
  return batchSize;
}

export type AnimatedVerticalStackRowProps = {
  index: number;
  layout: ViewStyle;
  focused: boolean;
  onLoaded: (empty: boolean) => void;
  onHover: (on: boolean) => void;
  onElementFocus: (index: number, isTopEdge: boolean) => void;
}

type AnimatedVerticalStackProps = {
  focusOnAppear?: boolean;
  topInset?: number;
  firstNonEmptyRowCustomOffset?: number;
  rows: {id: string; height: number}[];
  containerStyle?: StyleProp<ViewStyle>;
  renderRow: (props: AnimatedVerticalStackRowProps) => JSX.Element;
  onFocusChange?: (row: number, index: number) => void;
  onNavigateLeft: (row: number) => void;
  onNavigateRight: (row: number) => void;
  onNavigateUp: (row: number) => {shouldStay: boolean};
  onNavigateDown: (row: number) => {shouldStay: boolean};
  onPressOK: (focusedRow: number) => void;
  onReady?: () => void;
};

/**
 * This component renders a vertical list of rows. It supports various heights for rows
 * and you can use any component as a row using `renderRow` prop. Scrolling and fixed focus
 * is handled here. Use `AnimatedSwimlaneStackBase` if all your rows are swimlanes.
 */
const AnimatedVerticalStack: React.FC<AnimatedVerticalStackProps> = React.memo(({
  focusOnAppear,
  topInset = 0,
  firstNonEmptyRowCustomOffset = 0,
  rows,
  containerStyle,
  renderRow,
  onFocusChange: propsOnFocusChange,
  onPressOK,
  onNavigateLeft,
  onNavigateRight,
  onNavigateUp,
  onNavigateDown,
  onReady
}) => {
  const {size: screenSize} = useScreenInfo();

  const [focusPrisonActive, setFocusPrisonActive] = useState(false);
  const {obtainedFocus} = useDelayedFocusTVOS(focusPrisonActive);

  const [exitEdges, setExitEdges] = useState<Direction[]>([Direction.Left, Direction.Up]);

  const gridScrollOffset = useRef(new Animated.ValueXY());

  const [visibleRows, setVisibleRows] = useState(
    Math.max(standardBatchSize, calculateInitialBatchSize(screenSize.height, rows))
  );
  const [rowsHeightsAfterLoading, setRowsHeightsAfterLoading] = useState<{[rowId: string]: number}>({});
  const actualRowHeight = useCallback((row: AnimatedVerticalStackProps['rows'][number]) => {
    return rowsHeightsAfterLoading[row.id] ?? row.height;
  }, [rowsHeightsAfterLoading]);

  const offsetForRow = useCallback((rowIndex: number) => {
    return rows
      .slice(0, rowIndex)
      .reduce(
        (offset, row) => {
          return offset + actualRowHeight(row);
        },
        -topInset
      );
  }, [rows, topInset, actualRowHeight]);

  const visibleRowsHeight = useMemo(
    () => offsetForRow(visibleRows),
    [offsetForRow, visibleRows]
  );

  const [{getStateSync: getFocusedRowSync, state: focusedRow}, setFocusedRow] = useSynchronizedState(0);
  const offsetY = useMemo(() => offsetForRow(focusedRow), [focusedRow, offsetForRow]);

  useChangeEffect(() => {
    if (visibleRowsHeight < offsetY + screenSize.height) {
      setVisibleRows(v => v + standardBatchSize);
    }
  }, [screenSize.height, visibleRowsHeight, offsetY]);

  const nextNonEmptyRow = useFunction((dir: 'previous' | 'next', currentRow: number): number => {
    if (dir === 'next') {
      const offset = rows
        .slice(currentRow + 1)
        .findIndex(row => actualRowHeight(row) > 0);
      return currentRow + 1 + offset;
    } else {
      const offset = rows
        .slice(0, currentRow)
        .reverse()
        .findIndex(row => actualRowHeight(row) > 0);
      return currentRow - 1 - offset;
    }
  });

  const rowIndexFilteringEmptyRows = useCallback((row: number) => {
    return rows
      .filter(row => actualRowHeight(row) > 0)
      .findIndex(({id}) => id === rows[row].id);
  }, [actualRowHeight, rows]);

  const onFocusChange = useFunction((row: number, elementIndex: number, isTopEdge: boolean) => {
    propsOnFocusChange?.(rowIndexFilteringEmptyRows(row), elementIndex);
    if (isDesktopBrowser) {
      setFocusedRow(row);
    }
    const newExitEdges: Direction[] = [];
    if (elementIndex === 0) {
      newExitEdges.push(Direction.Left);
    }
    const firstNonEmptyRow = nextNonEmptyRow('next', -1);
    if (row === firstNonEmptyRow && isTopEdge) {
      newExitEdges.push(Direction.Up);
    }
    //TODO: CL-4028 in future we may want to escape swimlanes on right/bottom edge.
    setExitEdges(newExitEdges);
  });

  const handleKeyPress = useDisposableCallback(({key}: NativeKeyEvent) => {
    if (!focusPrisonActive || (isDesktopBrowser && !isSTBBrowser)) {
      return;
    }
    if (isTVOS && !obtainedFocus) {
      Log.info(TAG, `handleKeyPress: key event ${key} ignored, focus not obtained yet.`);
      return;
    }
    const handle = InteractionManager.createInteractionHandle();
    switch (key) {
      case SupportedKeys.Ok:
        Log.info(TAG, 'handleKeyPress: ok pressed');
        onPressOK(getFocusedRowSync());
        break;
      case SupportedKeys.Left:
        Log.info(TAG, `handleKeyPress: left pressed focusedSwimlane: ${getFocusedRowSync()}`);
        onNavigateLeft(getFocusedRowSync());
        break;
      case SupportedKeys.Right:
        Log.info(TAG, `handleKeyPress: right pressed focusedSwimlane: ${getFocusedRowSync()}`);
        onNavigateRight(getFocusedRowSync());
        break;
      case SupportedKeys.Up: {
        if (onNavigateUp(getFocusedRowSync()).shouldStay) {
          break;
        }
        const previousRow = nextNonEmptyRow('previous', getFocusedRowSync());
        Log.info(TAG, `handleKeyPress: up pressed focused row: ${getFocusedRowSync()}, next row: ${getFocusedRowSync()}`);
        setFocusedRow(previousRow);
        break;
      }
      case SupportedKeys.Down: {
        if (onNavigateDown(getFocusedRowSync()).shouldStay) {
          break;
        }
        const nextRow = nextNonEmptyRow('next', getFocusedRowSync());
        Log.info(TAG, `handleKeyPress: down pressed focused row: ${getFocusedRowSync()}, next row: ${nextRow}`);
        setFocusedRow(nextRow);
        break;
      }
    }
    InteractionManager.clearInteractionHandle(handle);
  }, []);

  useChangeEffect(() => {
    if (isDesktopBrowser) {
      return;
    }
    const row = getFocusedRowSync();
    const offset = rowIndexFilteringEmptyRows(row) === 0 ? firstNonEmptyRowCustomOffset : 0;
    Animated.timing(gridScrollOffset.current.y, {
      toValue: offsetForRow(row) - offset,
      duration: animationDuration,
      useNativeDriver: true
    }).start();
  }, [focusedRow], [offsetForRow, getFocusedRowSync, rowIndexFilteringEmptyRows, firstNonEmptyRowCustomOffset]);

  const keyHandler = useThrottle(handleKeyPress, isTVOS ? 0 : 100);
  useKeyEventHandler(isTVOS ? 'keyup' : 'keydown', keyHandler);
  useKeyEventHandler('ios:pan', useTVOSPanGesture(keyHandler));

  const scrollVertically = useCallback((wheelEvent: WheelEvent) => {
    //TODO: CL-4028 we may want magnetizing to swimlane
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    const newValue = gridScrollOffset.current.y._value + wheelEvent.deltaY;
    /* eslint-enable @typescript-eslint/ban-ts-comment */
    const trimmedValue = wheelEvent.deltaY < 0 ?
      Math.max(newValue, -topInset) :
      Math.min(newValue, -topInset + visibleRowsHeight);
    gridScrollOffset.current.y.setValue(trimmedValue);
    if (newValue > visibleRowsHeight - screenSize.height) {
      setVisibleRows(v => v + standardBatchSize);
    }
  }, [screenSize.height, visibleRowsHeight, topInset]);

  const scrollHandler = useCallback((wheelEvent: WheelEvent) => {
    const isVertical = Math.abs(wheelEvent.deltaY) >= Math.abs(wheelEvent.deltaX);
    if (isVertical && !wheelEvent.shiftKey) {
      scrollVertically(wheelEvent);
    }
  }, [scrollVertically]);

  useScrollHandler(scrollHandler);
  const {onWheel} = useDiscreteWheelHandler((direction) => {
    if (direction === Direction.Left) {
      onNavigateLeft(getFocusedRowSync());
    }
    if (direction === Direction.Right) {
      onNavigateRight(getFocusedRowSync());
    }
  }, {
    directions: [Direction.Right, Direction.Left]
  });

  const onRowLoaded = useFunction((index: number, empty: boolean) => {
    setRowsHeightsAfterLoading(prev => ({
      ...prev,
      [rows[index].id]: empty ? 0 : rows[index].height
    }));
    if (empty && index === getFocusedRowSync()) {
      Log.info(TAG, 'Focused row turned out to be empty, focusing next, non-empty row');
      const nextRow = nextNonEmptyRow('next', getFocusedRowSync());
      if (nextRow !== getFocusedRowSync()) {
        setFocusedRow(nextRow);
      } else {
        setFocusedRow(nextNonEmptyRow('previous', getFocusedRowSync()));
      }
    }
    if (rows.slice(0, visibleRows).every(({id}) => id in rowsHeightsAfterLoading)) {
      onReady?.();
    }
  });

  const layoutForRow = useFunction((row: {id: string; height: number}, index: number): ViewStyle => {
    return {
      position: 'absolute',
      left: 0,
      right: 0,
      top: topInset + offsetForRow(index),
      height: rowsHeightsAfterLoading[row.id] ?? row.height
    };
  });

  // cache props passed to Row components to minimize rerenders
  // when scrolling vertically
  const rowsDataCache = useRef<{
    [row: number]: {
      layout: ViewStyle,
      onLoaded: (empty: boolean) => void;
      onHover: (on: boolean) => void;
      onElementFocus: (index: number, isTopEdge: boolean) => void
    }
  }>({});

  useEffect(() => {
    rowsDataCache.current = {};
  }, [rows]);

  const Row = useMemo(() => {
    const Row = React.memo(renderRow);
    Row.displayName = 'AnimatedVerticalStackRow';
    return Row;
  }, [renderRow]);

  const renderedRows = useMemo(() => {
    return rows
      .slice(0, visibleRows)
      .map((row, rowIndex) => {
        if (rowsHeightsAfterLoading[row.id] === 0) {
          return null;
        }
        const layout = layoutForRow(row, rowIndex);
        const cache = rowsDataCache.current[rowIndex];
        if (!cache) {
          rowsDataCache.current[rowIndex] = {
            layout: layoutForRow(row, rowIndex),
            onLoaded: empty => onRowLoaded(rowIndex, empty),
            onHover: on => on && setFocusedRow(rowIndex),
            onElementFocus: (elementIndex, isTopEdge) => onFocusChange(rowIndex, elementIndex, isTopEdge)
          };
        } else if (layout.top !== cache.layout.top
          || layout.height !== cache.layout.height
        ) {
          rowsDataCache.current[rowIndex].layout = layout;
        }
        return (
          <Row
            key={rowIndex}
            index={rowIndex}
            focused={rowIndex === focusedRow && (isDesktopBrowser || focusPrisonActive)}
            {...rowsDataCache.current[rowIndex]}
          />
        );
      });
  }, [focusPrisonActive, focusedRow, layoutForRow, onFocusChange, onRowLoaded, rows, rowsHeightsAfterLoading, setFocusedRow, visibleRows]);

  Log.info(TAG, 'Rendered rows:', rows.slice(0, visibleRows));

  //set initial position
  useLazyEffect(() => {
    gridScrollOffset.current.setValue({x: 0, y: -topInset});
  }, [], [topInset]);

  return (
    <FocusPrison
      style={styles.focusPrison}
      focusOnAppear={focusOnAppear}
      focusPrisonStateChanged={setFocusPrisonActive}
      exitEdges={exitEdges}
      // ATV and STBBrowser don't send 'keydown' OK keyevent
      onPress={
        isTVOS || isDesktopBrowser
          ? undefined
          : () => onPressOK(getFocusedRowSync())
      }
    >
      <AnimatedScrollView
        style={containerStyle}
        size={screenSize}
        offsetX={gridScrollOffset.current.x}
        offsetY={gridScrollOffset.current.y}
        onWheel={onWheel}
      >
        {renderedRows}
      </AnimatedScrollView>
      <PreventDefaultWebScroll />
    </FocusPrison>
  );
});
AnimatedVerticalStack.displayName = TAG;

export default AnimatedVerticalStack;
