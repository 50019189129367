import React, {useEffect} from 'react';
import {AppStateStatus} from 'react-native';

import {ScreenInfo, ChangeEvent} from 'common/HelperTypes';

import {CustomerEvent} from 'mw/api/Customer';
import {Profile} from 'mw/api/Profile';
import {mw} from 'mw/MW';

import {useEventListener, useScreenInfo, useChangeEffect, useAppState, useFocusOnAppear} from './Hooks';

export type UseCurrentProfileProps = {
  onProfileChanged: (profile: Profile) => void;
}

export const UseCurrentProfile: React.FC<UseCurrentProfileProps> = props => {
  const {onProfileChanged} = props;
  const onChange = ({to: profile}: ChangeEvent<Profile | null>) => {
    if (profile) {
      onProfileChanged(profile);
    }
  };
  useEventListener(CustomerEvent.ProfileChange, onChange, mw.customer);
  return null;
};

export type ScreenInfoListenerProps = {
  onChange: (screenInfo: ScreenInfo) => void;
}

export const UseScreenInfoListener: React.FC<ScreenInfoListenerProps> = ({onChange}) => {
  const screenInfo = useScreenInfo();
  useEffect(() => {
    onChange(screenInfo);
  }, [onChange, screenInfo]);

  return null;
};

export type UseAppStateProps = {
  onAppStateChange: (appState: AppStateStatus) => void;
};

export const UseAppState = (props: UseAppStateProps) => {
  const {onAppStateChange} = props;
  const appState = useAppState();
  useChangeEffect(() => {
    props.onAppStateChange(appState);
  }, [appState], [onAppStateChange]);
  return null;
};

export const UseFocusOnAppear: React.FC<{}> = () => {
  useFocusOnAppear();
  return null;
};
