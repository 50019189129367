import {createStyles} from 'common-styles';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import DoubleBackground from 'components/DoubleBackground';
import FocusParent from 'components/FocusParent';
import {WizardElements} from 'components/Wizard';
import {getScreenInfo} from 'hooks/Hooks';
import BirthInput from 'screens/settings/profileEdit/BirthInput';
import {useToggleParentalControl} from 'screens/settings/profileWizard/WizardHooks';

import {BirthPageProps} from './WizardBirthPage';
import {WizardToggleSwitch} from './WizardToggleSwitch';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  textBoxText: {
    color: colors.settingsScreen.profile.wizard.sublabel
  },
  bottomChildContainer: {
    width: Math.min(dimensions.inputs.width.settings, getScreenInfo().size.width - 2 * dimensions.margins.large)
  },
  parentalToggle: {
    width: '100%',
    alignSelf: 'center',
    marginTop: dimensions.margins.xxxLarge
  },
  actions: {
    alignItems: 'center',
    marginTop: dimensions.margins.xxxxLarge
  },
  topChild: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  topChildEmbedded: {
    backgroundColor: colors.parallelNavigator.column.right
  },
  bottomChild: {
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const doubleBackgroundOverlap = Math.floor(dimensions.inputs.height / 2) + dimensions.margins.xxLarge * 2;

const WizardBirthPageContent: React.FC<BirthPageProps & WizardElements> = ({
  onBirthDateChanged,
  onParentalChanged,
  yearOfBirth,
  parentalControl: propsParentalControl,
  header,
  tracker,
  forwardButton,
  embedded,
  onBirthDateSubmit,
  onPinChanged,
  pinState
}) => {

  const {t} = useTranslation();
  const {parentalControl, toggleParentalControl, renderPopup} = useToggleParentalControl({
    parentalControl: !!propsParentalControl,
    onParentalControlChanged: onParentalChanged,
    pinState,
    setPin: onPinChanged
  });
  const styles = stylesUpdater.getStyles();
  const toggle = (
    <WizardToggleSwitch
      title={t('settings.parentalControl.parentalControl')}
      onPress={toggleParentalControl}
      style={styles.parentalToggle}
      value={parentalControl}
      message={embedded ? t('profileWizard.parentalControlDescription') : undefined}
      messageStyle={styles.textBoxText}
    />
  );

  return (
    <DoubleBackground
      topStyle={[styles.topChild, embedded && styles.topChildEmbedded]}
      topChild={(
        <View>
          {header}
          {tracker}
        </View>
      )}
      bottomStyle={styles.bottomChild}
      bottomChild={(
        <View style={styles.bottomChildContainer}>
          <FocusParent rememberLastFocused>
            <BirthInput
              onBirthDateChanged={onBirthDateChanged}
              yearOfBirth={yearOfBirth}
              embedded={embedded}
              onBirthDateSubmit={onBirthDateSubmit}
            />
            {toggle}
            {forwardButton && (
              <FocusParent style={styles.actions}>
                {forwardButton}
              </FocusParent>
            )}
          </FocusParent>
          {renderPopup()}
        </View>
      )}
      overlap={doubleBackgroundOverlap}
    />
  );
};

export default React.memo(WizardBirthPageContent);
