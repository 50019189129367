export const regionIdKey = 'id_region';

export const CategoryLimits = {
  events: {
    hoursAhead: 12,
    limit: 50
  }
};

export const RecommendationsLimits = {
  events: {
    hoursAhead: 60,
    limit: 50
  }
};

export enum DrmType {
  Widevine = 'widevine',
  FairPlay = 'fairplay'
}
