import {createStyles} from 'common-styles';
import React, {useRef, useEffect, useState, useMemo, useCallback, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {View, StyleSheet} from 'react-native';
import {NavigationInjectedProps, withNavigation, NavigationContext} from 'react-navigation';

import {AppRoutes, playerOverlayConfig, isMobile, usingNitroxFocusEngine, isBigScreen, dimensions, Direction} from 'common/constants';
import {addToWatchList, removeFromWatchList, getPictureUrl} from 'common/HelperFunctions';
import {NavigationFocusState} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Media, ChromecastConnectionState} from 'mw/api/Metadata';
import {PlayerEvent, RewindingEvent} from 'mw/api/PlayerEvent';
import {mw, CatalogEvent} from 'mw/MW';
import {PlaybackParameters, RewindDirection} from 'mw/playback/Player';

import {ChromecastContext} from 'components/ChromecastContext';
import FocusParent from 'components/FocusParent';
import {useFullScreenControl, useEnableFullScreenControlOnAppear} from 'components/fullscreen/FullScreenControlProvider';
import {IconType} from 'components/Icon';
import {NativeKeyEvent, SupportedKeys} from 'components/KeyEventManager';
import MediaPicture from 'components/MediaPicture';
import {ModalVisibilityContext} from 'components/Modal';
import MoreActionsPopup, {MoreActionsAction} from 'components/MoreActionsPopup';
import {useSTBMenu} from 'components/navigation/STBNavigationView';
import NitroxBlackPortal from 'components/NitroxBlackPortal';
import OrientationManager from 'components/OrientationManager';
import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';
import {PlayerControlsHandlers} from 'components/player/PlayerControlsView';
import PlayerLanguageController from 'components/player/PlayerLanguageController';
import {PlayerManager} from 'components/player/PlayerManager';
import {PlayerView, PlayerViews} from 'components/player/PlayerView';
import {usePlayerErrorPopup} from 'components/player/usePlayerErrorPopup';
import {usePlayerLauncher} from 'components/playerLauncher/PlayerLauncher';
import {TopLevelPortalManager, TopLevelPortalType} from 'components/TopLevelPortalManager';
import {DetailsButtonsHandlers} from 'components/zapper/MediaDetailsTemplate';
import {useDebounce, useFunction, useKeyEventHandler, useNavigation, useNavigationFocusState, useBackPressCapture, useLazyEffect, useEventListener, useChangeEffect, useAppState, useToggle, useScreenInfo, useDisposable} from 'hooks/Hooks';
import {useArrowsListener} from 'hooks/rcuHooks';
import {useInactivityTimeout} from 'hooks/useInactivityTimeout';

import MediaOverlay from './MediaOverlay';

const contextsToPass = [ModalVisibilityContext];

const TAG = 'MediaPlayer';
const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    backgroundColor: colors.playerScreen.background,
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  portalContent: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: colors.playerScreen.background
  }
}));

const wrapPlayerControlsHandlers = (playerControlsHandlers: PlayerControlsHandlers, wrapper: <T extends Array<any>>(handler?: (((...args: T) => void))) => ((...args: T) => void) | undefined): PlayerControlsHandlers => {
  return {
    restartHandler: wrapper(playerControlsHandlers.restartHandler),
    playPrevEpisodeHandler: wrapper(playerControlsHandlers.playPrevEpisodeHandler),
    skipBackHandler: wrapper(playerControlsHandlers.skipBackHandler),
    fastBackwardsHandler: wrapper(playerControlsHandlers.fastBackwardsHandler),
    playPauseHandler: wrapper(playerControlsHandlers.playPauseHandler),
    stopHandler: wrapper(playerControlsHandlers.stopHandler),
    skipForwardHandler: wrapper(playerControlsHandlers.skipForwardHandler),
    fastForwardHandler: wrapper(playerControlsHandlers.fastForwardHandler),
    playNextEpisodeHandler: wrapper(playerControlsHandlers.playNextEpisodeHandler),

    showEPGHandler: wrapper(playerControlsHandlers.showEPGHandler),
    collapseHandler: wrapper(playerControlsHandlers.collapseHandler),
    castHandler: wrapper(playerControlsHandlers.castHandler),
    episodesHandler: wrapper(playerControlsHandlers.episodesHandler),
    toggleHandler: wrapper(playerControlsHandlers.toggleHandler),
    subtitleHandler: wrapper(playerControlsHandlers.subtitleHandler),
    goToLiveHandler: wrapper(playerControlsHandlers.goToLiveHandler),
    recordDotHandler: wrapper(playerControlsHandlers.recordDotHandler),
    channelListHandler: wrapper(playerControlsHandlers.channelListHandler),
    fullScreenHandler: wrapper(playerControlsHandlers.fullScreenHandler),
    fullScreenBackHandler: wrapper(playerControlsHandlers.fullScreenBackHandler),
    moreInfoHandler: wrapper(playerControlsHandlers.moreInfoHandler)
  };
};

type Props = {
  media: Media;
  position?: number;
} & NavigationInjectedProps;

const MediaPlayer: React.FC<Props> = props => {
  const {t} = useTranslation();
  const navigation = useNavigation();
  const logger = useRef((msg: string, ...msgs: string[]) => Log.info(TAG, msg, ...msgs));
  const [playerPaused, setPlayerPaused] = useState(mw.players.main.getParameters().playRate === 0);
  const [playerRewindDirection, setPlayerRewindDirection] = useState(mw.players.main.getRewindDirection());
  const focusState = useNavigationFocusState(props.navigation);
  const [overlayVisible, setOverlayVisible] = useState(true);
  const [playerViewReady, setPlayerViewReady] = useState(false);
  const playerLanguageController = useRef<PlayerLanguageController>(null);
  const [moreActionsPopupVisible, {on: showMoreActionsPopup, off: closeMoreActionsPopup}] = useToggle(false);
  const [isFinished, {on: onVideoFinish, off: onVideoStart}] = useToggle(false);
  const [languageSelectionPopupVisible, setLanguageSelectionPopupVisible] = useState(false);
  const {connectionState, showDeviceListPopup, showDisconnectPopup, castAfterConnecting} = useContext(ChromecastContext);
  const {isMediaBlocked, shouldBeCheckedForPC} = useParentalControl();
  const isBlocked = shouldBeCheckedForPC(props.media) && isMediaBlocked(props.media);

  const {userInactive, renderInactivityPopup} = useInactivityTimeout(TAG);
  const appState = useAppState();
  const {playbackError, clearPlaybackError, renderPlaybackErrorPopups} = usePlayerErrorPopup();

  useLazyEffect(() => {
    if (userInactive) {
      Log.info(TAG, 'User inactive, navigating back...');
      navigation.goBack();
    }
  }, [userInactive], [navigation]);

  useChangeEffect(() => {
    if (appState === 'active') {
      setOverlayVisible(true);
    }
  }, [appState], []);

  useEffect(() => {
    if (focusState === NavigationFocusState.IsFocused) {
      TopLevelPortalManager.getInstance().displayFullscreenPortal();
    } else {
      TopLevelPortalManager.getInstance().hideTopLevelPortal();
    }
  }, [focusState]);
  useEffect(() => () => {
    TopLevelPortalManager.getInstance().hideTopLevelPortal();
    setPlayerViewReady(false);
  }, []);

  const onPlayerParametersChanged = useFunction(() => {
    const {playRate} = mw.players.main.getParameters();
    setPlayerPaused(playRate === 0);
    Log.debug(TAG, `player playrate changed to ${playRate}`);
  });

  useEventListener(PlayerEvent.ParametersChanged, onPlayerParametersChanged, mw.players.main);

  const onMediaNotFound = useFunction(() => {
    navigation.navigate(AppRoutes.Home);
  });

  useEventListener(CatalogEvent.MediaNotFound, onMediaNotFound, mw.catalog);

  useLazyEffect(() => {
    clearPlaybackError();
    mw.players.main
      .start(PlayerViews.Fullscreen, props.media, {playRate: 1, position: props.position || 0})
      .then(onPlayerParametersChanged)
      .catch(error => Log.error(TAG, 'Error starting playback', error));
  }, [props.media], [onPlayerParametersChanged]);

  const onPlayerViewReady = useFunction(() => {
    logger.current('Player ready');
    setPlayerViewReady(true);
  });

  const hidePlayerOverlay = useFunction(() => {
    if (overlayVisible && !languageSelectionPopupVisible && !playerPaused && playerRewindDirection == null) {
      setOverlayVisible(false);
    }
  });
  const scheduleHideOverlay = useDebounce(hidePlayerOverlay, playerOverlayConfig.vodHideTimeout * 1000);

  useLazyEffect(() => {
    scheduleHideOverlay();
  }, [], [scheduleHideOverlay]);

  const showPlayerOverlay = useFunction(() => {
    setOverlayVisible(true);
    scheduleHideOverlay();
  });

  const onPlayerRewinding = useFunction((event: RewindingEvent) => {
    showPlayerOverlay();
    setPlayerRewindDirection(event.delta > 0 ? RewindDirection.FastForward : RewindDirection.FastBackwards);
  });

  const onPlayerRewindingStopped = useFunction(() => {
    Log.debug(TAG, 'Rewinding was stopped');
    setPlayerRewindDirection(undefined);
  });

  useEventListener(PlayerEvent.Rewinding, onPlayerRewinding, mw.players.main);
  useEventListener(PlayerEvent.RewindStopped, onPlayerRewindingStopped, mw.players.main);

  const moreActions: MoreActionsAction[] = useMemo<MoreActionsAction[]>(() => {
    const addToWatchListAction: MoreActionsAction = {
      key: 'addToWatchList',
      text: t('mediaPlayer.addToWatchList'),
      icon: IconType.Add,
      onPress: () => {
        addToWatchList(props.media);
        closeMoreActionsPopup();
      }
    };
    const removeFromWatchListAction: MoreActionsAction = {
      key: 'removeFromWatchList',
      text: t('mediaPlayer.removeFromWatchList'),
      icon: IconType.Remove,
      onPress: () => {
        removeFromWatchList([props.media]);
        closeMoreActionsPopup();
      }
    };

    const actions: MoreActionsAction[] = [];
    if (props.media.isAllowedOnWatchList()) {
      actions.push(props.media.isOnWatchList ? removeFromWatchListAction : addToWatchListAction);
    }

    return actions;
  }, [props.media, closeMoreActionsPopup, t]);

  const openMoreActionsPopup = useCallback(() => {
    showMoreActionsPopup();
  }, [showMoreActionsPopup]);

  useEventListener(PlayerEvent.EndOfContent, onPlayerParametersChanged, mw.players.main);

  const awaitPendingTunes = useDisposable(() => PlayerManager.getInstance().awaitPendingTunes());

  const playPauseHandler = useFunction(async () => {
    await awaitPendingTunes();

    const playbackParameters: PlaybackParameters = mw.players.main.getParameters();
    const rewinding = mw.players.main.isRewinding();
    const playRate = playbackParameters.playRate && !rewinding ? 0 : 1;
    try {
      mw.players.main
        .changeParameters({playRate})
        .catch((error: object) => Log.warn(TAG, 'playPauseHandler: promise reject: ', error))
        .finally(onPlayerParametersChanged);
    } catch (error) {
      Log.debug(TAG, 'playPauseHandler: thrown error: ', error);
    }
  });

  const handlerWrapper = useCallback(<T extends any[]>(handler?: (...args: T) => void): ((...params: T) => void) | undefined => {
    return handler ? (...params: T) => {
      showPlayerOverlay();
      handler?.(...params);
    } : undefined;
  }, [showPlayerOverlay]);

  useEnableFullScreenControlOnAppear();

  const {enterFullScreenMode, exitFullScreenMode} = useFullScreenControl();
  const playerControlsHandlers: PlayerControlsHandlers = useMemo<PlayerControlsHandlers>(() => {
    const wrappedHandlers = wrapPlayerControlsHandlers({
      restartHandler: async () => {
        await awaitPendingTunes();

        const playerPosition = mw.players.main.getParameters().position;
        if (playerPosition) {
          mw.players.main
            .changeParameters({skip: -playerPosition})
            .then((response: object) => Log.debug(TAG, 'restartHandler: promise result:', response))
            .catch((error: object) => Log.debug(TAG, 'restartHandler: promise reject:', error));
        }
      },
      subtitleHandler: () => {
        if (playerLanguageController.current) {
          playerLanguageController.current.showLanguageSelectionPopup();
        }
      },
      playPauseHandler,
      castHandler: () => {
        props.navigation.goBack();
        castAfterConnecting(mw.players.main.getParameters().position ?? 0);
        connectionState === ChromecastConnectionState.Disconnected ? showDeviceListPopup() : showDisconnectPopup();
      },
      toggleHandler: moreActions.length ? () => {
        showPlayerOverlay();
        openMoreActionsPopup();
      } : undefined,
      fullScreenHandler: enterFullScreenMode,
      fullScreenBackHandler: exitFullScreenMode
    }, handlerWrapper);

    return {
      ...wrappedHandlers,
      skipBackHandler: async () => {
        // if overlay is already visible, then prolong its timeout
        // otherwise, keep it hidden
        if (overlayVisible) {
          showPlayerOverlay();
        }
        await awaitPendingTunes();

        mw.players.main
          .changeParameters({skip: -mw.configuration.playbackSkip})
          .then((response: object) => Log.debug(TAG, 'skipBackHandler: promise result:', response))
          .catch((error: object) => Log.debug(TAG, 'skipBackHandler: promise reject:', error));
      },
      skipForwardHandler: async () => {
        // if overlay is already visible, then prolong its timeout
        // otherwise, keep it hidden
        if (overlayVisible) {
          showPlayerOverlay();
        }
        await awaitPendingTunes();

        mw.players.main
          .changeParameters({skip: mw.configuration.playbackSkip})
          .then((response: object) => Log.debug(TAG, 'skipForwardHandler: promise result:', response))
          .catch((error: object) => Log.debug(TAG, 'skipForwardHandler: promise reject:', error));
      },
      fastBackwardsHandler: async () => {
        await awaitPendingTunes();

        mw.players.main
          .startRewind(RewindDirection.FastBackwards)
          .then(() => Log.debug(TAG, 'fastBackwardsHandler: FastBackwards started'))
          .catch((error: object) => Log.debug(TAG, 'fastBackwardsHandler: promise reject:', error));
      },
      fastForwardHandler: async () => {
        await awaitPendingTunes();

        mw.players.main
          .startRewind(RewindDirection.FastForward)
          .then(() => Log.debug(TAG, 'fastForwardHandler: FastForward started'))
          .catch((error: object) => Log.debug(TAG, 'fastForwardHandler: promise reject:', error));
      }
    };
  }, [playPauseHandler, handlerWrapper, awaitPendingTunes, props.navigation, castAfterConnecting, connectionState, showDeviceListPopup, showDisconnectPopup, showPlayerOverlay, openMoreActionsPopup, overlayVisible, moreActions, enterFullScreenMode, exitFullScreenMode]);

  const onKeyUp = useFunction((event: NativeKeyEvent) => {
    const {allowFastForward, allowPause, allowRewind} = mw.players.main.getPlaybackLimitations();
    const {playRate} = mw.players.main.getParameters();
    switch (event.key) {
      case SupportedKeys.PlayPause:
        (allowPause || !playRate) && playPauseHandler();
        showPlayerOverlay();
        break;
      case SupportedKeys.Play:
        !playRate && playPauseHandler();
        showPlayerOverlay();
        break;
      case SupportedKeys.Pause:
        allowPause && playRate && playPauseHandler();
        showPlayerOverlay();
        break;
      case SupportedKeys.Stop:
        props.navigation.goBack();
        break;
      case SupportedKeys.FastForward:
        allowFastForward && playerControlsHandlers.fastForwardHandler?.();
        break;
      case SupportedKeys.Rewind:
        allowRewind && playerControlsHandlers.fastBackwardsHandler?.();
        break;
      // handled in useArrowsListener
      case SupportedKeys.Left:
      case SupportedKeys.Right:
        break;
      case SupportedKeys.Back:
        if (!!playerRewindDirection) {
          (allowPause || !playRate) && playPauseHandler();
        }
        // do not handle this here, indeterministic races with hardwareBackPress
        break;
      case SupportedKeys.Subtitles:
        playerControlsHandlers.subtitleHandler?.();
        break;
      default: {
        showPlayerOverlay();
      }
    }
  });
  useKeyEventHandler('keyup', onKeyUp);

  const menuContext = useSTBMenu();

  useArrowsListener(direction => {
    if (menuContext?.hasVisibleModal) {
      return;
    }
    if (overlayVisible) {
      showPlayerOverlay();
      return;
    }

    const {allowSkipBackward, allowSkipForward} = mw.players.main.getPlaybackLimitations();
    switch (direction) {
      case Direction.Left: {
        allowSkipBackward && playerControlsHandlers.skipBackHandler?.();
        return;
      }
      case Direction.Right: {
        allowSkipForward && playerControlsHandlers.skipForwardHandler?.();
        return;
      }
    }
  }, [Direction.Left, Direction.Right]);

  useArrowsListener(() => {
    showPlayerOverlay();
  }, [Direction.Up, Direction.Down]);

  // pass navigate back when either overlay is hidden or playback is stopped
  const backHandler = useFunction(() => {
    if (languageSelectionPopupVisible) {
      scheduleHideOverlay();
      return true;
    }

    if (focusState === NavigationFocusState.IsFocused && overlayVisible && !playerPaused && playerRewindDirection == null) {
      hidePlayerOverlay();
      return true;
    }

    if (usingNitroxFocusEngine && focusState === NavigationFocusState.IsFocused) {
      props.navigation.goBack();
      return true;
    }
    return false;
  });
  useBackPressCapture(backHandler);

  const {renderPlayerLauncherComponent, startPlayback} = usePlayerLauncher({shouldReplaceMediaPlayer: true});

  const onWatch = (media: Media) => {
    setPlayerPaused(false); //FIXME: replace it with playerEventListeners(Stop/Play) CL-696
    startPlayback({media});
  };

  const detailsHandlers: DetailsButtonsHandlers = {
    onWatch
  };

  const onLanguageSelectionPopupVisibleChanged = useCallback((popupVisible: boolean) => {
    setLanguageSelectionPopupVisible(popupVisible);
  }, []);

  useEventListener(PlayerEvent.EndOfContent, onVideoFinish, mw.players.main);
  useEventListener(PlayerEvent.FirstFrame, onVideoStart, mw.players.main);

  const {size: {width}} = useScreenInfo();
  const poster = useMemo(() => getPictureUrl(props.media, dimensions.videoSize(width)), [props.media, width]);

  const styles = stylesUpdater.getStyles();
  return (
    <View style={styles.container}>
      {isMobile && playerViewReady && <OrientationManager navigation={props.navigation} allowedOrientations='landscape' />}
      <NitroxBlackPortal name={TopLevelPortalType.Fullscreen} contexts={contextsToPass}>
        <FocusParent trapFocus style={styles.portalContent}>
          {
            isBigScreen && isFinished ? (
              <View style={StyleSheet.absoluteFillObject}>
                <MediaPicture source={poster} mediaType={props.media.getType()} isBlocked={isBlocked} />
              </View>
            ) : (
              <PlayerView debugName={TAG} type={PlayerViews.Fullscreen} onReady={onPlayerViewReady} style={StyleSheet.absoluteFillObject} />
            )
          }
          <NavigationContext.Provider value={props.navigation}>
            <MediaOverlay
              player={mw.players.main}
              media={props.media}
              visible={overlayVisible || !!menuContext?.hasVisibleModal}
              showPlayerOverlay={showPlayerOverlay}
              hidePlayerOverlay={hidePlayerOverlay}
              heartbeat={showPlayerOverlay}
              playerControlsHandlers={playerControlsHandlers}
              playerPaused={playerPaused}
              playerRewindDirection={playerRewindDirection}
              detailsHandlers={detailsHandlers}
              // to prevent races between navigation from one media to another
              // this component is reused by react despite navigation.replace
              key={props.media.id}
              playbackError={!!playbackError}
            />
          </NavigationContext.Provider>
          <MoreActionsPopup visible={moreActionsPopupVisible} actions={moreActions} onClose={closeMoreActionsPopup} />
          {renderPlayerLauncherComponent()}
          <PlayerLanguageController ref={playerLanguageController} player={mw.players.main} onPopupVisibleChanged={onLanguageSelectionPopupVisibleChanged} />
          {renderInactivityPopup()}
        </FocusParent>
      </NitroxBlackPortal>
      {renderPlaybackErrorPopups()}
    </View>
  );
};

export default withNavigation(React.memo(MediaPlayer));
