import {StyleProp, ViewStyle} from 'react-native';

import {isBigScreen} from 'common/constants';

import {PinState} from 'mw/api/Profile';

import WizardBirthPageContentGrosso from './WizardBirthPageContent.grosso';
import WizardBirthPageContentPiccolo from './WizardBirthPageContent.piccolo';

export type BirthPageProps = {
  yearOfBirth?: number;
  parentalControl?: boolean;
  pinState: PinState;
  contentStyle?: StyleProp<ViewStyle>;
  autoFocus?: boolean;
  embedded?: boolean;

  onBirthDateChanged: (year?: number) => void;
  onBirthDateSubmit?: () => void;

  onParentalChanged: (value: boolean) => void;
  onPinChanged: (pin: string) => void;
};

export default isBigScreen ? WizardBirthPageContentGrosso : WizardBirthPageContentPiccolo;
