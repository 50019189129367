import React, {useMemo, useCallback} from 'react';

import {isMobile, isDesktopBrowser} from 'common/constants';
import {formatStartDateForTile, getTimeWindow} from 'common/utils';

import {Event} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import EventSelectorContentGrosso from 'components/EventSelectorContent.grosso';
import EventSelectorContentPiccolo from 'components/EventSelectorContent.piccolo';
import {EventSelectorItem} from 'components/EventSelectorContent.shared';
import {IconType} from 'components/Icon';
import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';
import {prepareEventIcons} from 'components/TileIconsRow';

import {useFocusParentUtility} from './FocusParent';

type EventSelectorProps = {
  events: Event[];
  onClose: () => void;
  onSelect: (event: Event) => void;
};

const EventSelectorContent = isMobile ? EventSelectorContentPiccolo : EventSelectorContentGrosso;

const EventSelector: React.FC<EventSelectorProps> = ({events, onClose, onSelect}) => {
  const {isMediaBlocked} = useParentalControl();
  const {focus: focusNearestParent} = useFocusParentUtility();
  const onSelectorClose = useCallback(() => {
    onClose();
    if (!isDesktopBrowser) {
      focusNearestParent?.();
    }
  }, [focusNearestParent, onClose]);

  const items: EventSelectorItem[] = useMemo(() => events.map(
    event => ({
      event,
      date: formatStartDateForTile(event) || '',
      hours: getTimeWindow(event, mw.configuration.timeFormat),
      mediaIcons: prepareEventIcons(event, {excludeIcons: [IconType.Check], isBlocked: isMediaBlocked(event)}),
      onPress: () => onSelect(event)
    })
  ), [events, isMediaBlocked, onSelect]);

  return (
    <EventSelectorContent
      items={items}
      onClose={onSelectorClose}
    />
  );
};

/**
 * Watchlists can contain TV Events that are available on multiple channels and dates.
 * This selection dialog allows users to choose a source of an event.
 */
export default React.memo(EventSelector);
