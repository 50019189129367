import {DrmParameters, DrmProperties} from 'mw/bo-proxy/SSOInterface';

export enum DrmDomain {
  Widevine = 'com.widevine.alpha',
  FairPlay = 'com.apple.fps.1_0'
}

export type DrmRequest = {
  /**
   * Widevine DRM request configuration
   */
  keySystemOptions?: [{
    name: DrmDomain.Widevine;
    options: {
      serverURL?: string;
      videoRobustness: string;
      audioRobustness: string;
      httpRequestHeaders: {[key: string]: string} | null;
    }
  }];
  /**
   * FairPlay DRM request configuration (used on Safari only)
   */
  keySystems?: {
    [DrmDomain.FairPlay]: {
      getCertificate: (_: unknown, callback: (error: Error | null, cert?: Uint8Array) => void) => void;
      getContentId: (_: unknown, initData: Uint8Array) => string;
      getLicense: (_: unknown, skd: string, keyMessage: ArrayBuffer, callback: (error: Error | null, license?: Uint8Array) => void) => void;
    }
  };
};
export type DrmResponse = any;

export interface DrmInterceptor {
  onDrmRequest(): DrmRequest;
  onDrmResponse(response: DrmResponse): void;
  getDrmType(): string;
  setProperties(drmProperties: DrmProperties): void;
  setHeaders(drmHeaders: DrmParameters['drmHeaders']): void;
}
