import {createStyles} from 'common-styles';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {dimensions, isBigScreen} from 'common/constants';
import {Log} from 'common/Log';
import {isAsyncIterator} from 'common/utils';

import {PurchaseFlowType} from 'mw/api/Configuration';
import {Error as MWError, ErrorType} from 'mw/api/Error';
import {Media, Order, PaymentMethodId, PurchaseMethod, isTitle, Title} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import ErrorPopup, {useErrorPopup} from 'components/ErrorPopup';
import FocusParent from 'components/FocusParent';
import FullscreenActivityIndicator from 'components/FullscreenActivityIndicator';
import PurchaseModal from 'components/PurchaseModal';
import {useDisposable} from 'hooks/Hooks';

import PaymentWizard, {PaymentWizardProps} from './PaymentWizard';
import SummaryPurchasePopup from './SummaryPurchasePopup';
import MessageFlowPurchase from './WizardSteps/MessageFlowPurchase';

const TAG = 'PaymentFlow';
const styles = createStyles({
  stepContainer: {
    marginHorizontal: isBigScreen ? dimensions.margins.large : 0
  }
});

class AlreadyAcquiredError extends Error {
  public constructor(message?: string) {
    super(message);
  }
}

type Props = {
  visible: boolean;
  media: Media;
  onWatch: (purchasedMedia?: Media) => void;
} & PaymentWizardProps;

const PaymentFlow: React.FunctionComponent<Props> = props => {
  const {
    products,
    media,
    visible,
    onWatch,
    onPaymentSuccess,
    onClose
  } = props;

  const {t} = useTranslation();

  const paymentMethodId = useMemo(() => {
    return mw.customer.paymentMethods[0]?.id;
  }, []);

  const purchaseFlowType = useMemo(() => {
    return paymentMethodId ? mw.configuration.getPurchaseFlowType() : PurchaseFlowType.Disabled;
  }, [paymentMethodId]);

  const [isPaymentWizardVisible, setIsPaymentWizardVisible] = useState<boolean>(false);
  const [isMessagePopupVisible, setIsMessagePopupVisible] = useState<boolean>(false);
  const [isPurchasePopupVisible, setIsPurchasePopupVisible] = useState<boolean>(false);
  const [isSummaryPurchasePopupVisible, setISummaryPurchasePopupVisible] = useState<boolean>(false);
  const [offerId, setOfferId] = useState<string>();
  const [productId, setProductId] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {error, showError, onCloseErrorPopup: closeErrorPopup} = useErrorPopup();
  const [purchasedMedia, setPurchasedMedia] = useState<Media>();

  const purchaseMethod = useMemo(() => {
    switch (paymentMethodId) {
      case PaymentMethodId.Stripe:
        return PurchaseMethod.Stripe;
      case PaymentMethodId.Billing:
        return PurchaseMethod.Billing;
    }
  },[paymentMethodId]);

  const onMessagePopupClose = useCallback(() => {
    setIsMessagePopupVisible(false);
    onClose();
  }, [onClose]);

  const onClosePaymentPopup = useCallback(() => {
    setIsPaymentWizardVisible(false);
    onClose();
  }, [onClose]);

  const onPaymentWizardSuccess = useCallback((order?: Order, productId?: string, offerId?: string, purchasedMedia?: Media) => {
    setIsPaymentWizardVisible(false);
    switch (paymentMethodId) {
      case PaymentMethodId.Stripe:
        onPaymentSuccess(order, productId, offerId, purchasedMedia);
        break;
      case PaymentMethodId.Billing:
        offerId && setOfferId(offerId);
        productId && setProductId(productId);
        setIsPurchasePopupVisible(true);
        break;
    }
  }, [onPaymentSuccess, paymentMethodId]);

  const onClosePurchasePopup = useCallback(() => {
    setIsPurchasePopupVisible(false);
    onClose();
  }, [onClose]);

  const getTitleById = useDisposable(mw.catalog.getTitleById.bind(mw.catalog));
  const checkEntitlementState = useCallback(async (): Promise<void> => {
    if (!isTitle(media)) {
      return;
    }
    setIsLoading(true);
    let title: Title;
    try {
      title = await getTitleById(media.id);
    } catch (error) {
      throw new MWError(error.type ?? ErrorType.UnknownError, `Error updating entitlement state of title ${media.id}: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
    if (title.isEntitled) {
      // purchasedMedia will be passed to parent component via onClose()
      setPurchasedMedia(title);
      throw new AlreadyAcquiredError();
    }
  }, [media, getTitleById]);

  const purchase = useDisposable(mw.catalog.purchase.bind(mw.catalog));
  const makePurchase = useCallback(() => {
    setIsLoading(true);
    purchase(purchaseMethod, {
      load: true,
      media: media,
      productId: productId,
      offerId: offerId
    })
      .then(({result}) => {
        if (result && !isAsyncIterator(result)) {
          setPurchasedMedia(result);
        } else {
          setPurchasedMedia(undefined);
        }
        setISummaryPurchasePopupVisible(true);
      })
      .finally(() => setIsLoading(false));
  }, [purchase, media, offerId, productId, purchaseMethod]);

  const handleError = useCallback((error) => {
    if (error instanceof AlreadyAcquiredError) {
      Log.debug(TAG, `Media ${media.id} has already been acquired`);
      showError({
        title: '', // No title
        message: t('payments.error.alreadyAcquiredMovie')
      });
      return;
    }

    Log.error(TAG, 'Error purchasing content', error);
    if (error instanceof MWError) {
      if (error.type === ErrorType.NetworkNoConnection) {
        showError({
          title: t('payments.error.network.title'),
          message: t('payments.error.network.message')
        });
        return;
      }
      showError({
        title: t('payments.error.generic'),
        message: t('payments.error.genericMessage'),
        subtitle: t('common.errorCode', {errorCode: error.type})
      });
      return;
    }

    showError({
      title: t('payments.error.generic'),
      message: t('payments.error.genericMessage')
    });
  }, [showError, t, media]);

  const onPurchaseSuccess = useCallback(() => {
    setIsPurchasePopupVisible(false);
    checkEntitlementState()
      .then(makePurchase)
      .catch(handleError);
  }, [checkEntitlementState, makePurchase, handleError]);

  const onPurchaseSummaryPositive = useCallback(() => {
    setISummaryPurchasePopupVisible(false);
    onWatch(purchasedMedia);
  }, [onWatch, purchasedMedia]);

  const closePurchaseSummaryPopup = useCallback(() => {
    setISummaryPurchasePopupVisible(false);
    onClose(purchasedMedia);
  }, [onClose, purchasedMedia]);

  useEffect(() => {
    if (!visible) {
      return;
    }
    switch (purchaseFlowType) {
      case PurchaseFlowType.Full:
        checkEntitlementState()
          .then(() => setIsPaymentWizardVisible(true))
          .catch(handleError);
        break;
      case PurchaseFlowType.Message:
        checkEntitlementState()
          .then(() => setIsMessagePopupVisible(true))
          .catch(handleError);
        break;
      default:
        // Do not show any popup
        Log.warn(TAG, `Unhandled purchase flow type: ${purchaseFlowType}`);
        break;
    }
  }, [visible, purchaseFlowType, checkEntitlementState, handleError]);

  const onCloseErrorPopup = useCallback(() => {
    closeErrorPopup();
    onClose(purchasedMedia);
  }, [closeErrorPopup, onClose, purchasedMedia]);

  if (visible && isLoading) {
    return (
      <FullscreenActivityIndicator visible />
    );
  }
  return (
    <FocusParent>
      {!!products.length && media && visible && (
        <>
          <MessageFlowPurchase
            visible={isMessagePopupVisible}
            style={styles.stepContainer}
            onClose={onMessagePopupClose}
          />
          <PaymentWizard
            visible={isPaymentWizardVisible}
            products={products}
            media={media}
            onClose={onClosePaymentPopup}
            onPaymentSuccess={onPaymentWizardSuccess}
          />
          {paymentMethodId === PaymentMethodId.Billing && (
            <>
              <PurchaseModal
                visible={isPurchasePopupVisible}
                onSuccess={onPurchaseSuccess}
                onClose={onClosePurchasePopup}
                onFail={onClosePurchasePopup}
              />
              <SummaryPurchasePopup
                productTitle={media.name}
                onPositive={onPurchaseSummaryPositive}
                onClose={closePurchaseSummaryPopup}
                visible={isSummaryPurchasePopupVisible}
              />
            </>
          )}
          <ErrorPopup
            error={error}
            onClose={onCloseErrorPopup}
            buttonLabel={t('common.ok')}
          />
        </>
      )}
    </FocusParent>
  );
};

export default PaymentFlow;
