import {Error, ErrorType, MWForbiddenTransactionError} from 'mw/api/Error';

export function handleRegisterDeviceError(error: Error): never {
  const errorType = error.type === ErrorType.HttpForbidden ? ErrorType.TooManyDevices : ErrorType.FailedToRegisterNewDevice;
  throw new Error(errorType, error.message);
}

export function handleUnRegisterDeviceError(error: Error): never {
  throw new Error(ErrorType.FailedToUnregisterDevice, error.message);
}

export function handleGetConsentError(error: Error): Promise<any> {
  if (error.type === ErrorType.HttpNotFound) {
    return Promise.resolve({
      accepted: false
    });
  }
  throw error;
}

enum ForbiddenErrorMessage {
  NotEntitled = 'NotEntitled',
  LocationNotAllowed = 'LocationNotAllowed',
  AnonymousProxyNotAllowed = 'AnonymousProxyNotAllowed'
}

function getSessionErrorType(error: Error): ErrorType {
  if (error.type !== ErrorType.HttpForbidden) {
    return ErrorType.PlaybackSessionError;
  }
  switch (error.message) {
    case ForbiddenErrorMessage.LocationNotAllowed:
      return ErrorType.PlaybackLocationForbidden;
    case ForbiddenErrorMessage.AnonymousProxyNotAllowed:
      return ErrorType.PlaybackVPNForbidden;
    default: // including ForbiddenErrorMessage.NotEntitled
      return ErrorType.PlaybackEntitlementIssue;
  }
}

export function handleSessionError(error: Error): never {
  throw new Error(getSessionErrorType(error), error.message);
}

export function getTransactionErrorType(error: Error): ErrorType {
  if (error.type !== ErrorType.HttpForbidden) {
    return error.type;
  }
  switch (error.message) {
    case ForbiddenErrorMessage.LocationNotAllowed:
      return ErrorType.TransactionLocationForbidden;
    case ForbiddenErrorMessage.AnonymousProxyNotAllowed:
      return ErrorType.TransactionVPNForbidden;
    default:
      return ErrorType.TransactionFailed;
  }
}

export function handleTransactionError(error: Error): never {
  const errorType = getTransactionErrorType(error);
  if (errorType === ErrorType.TransactionLocationForbidden || errorType === ErrorType.TransactionVPNForbidden) {
    throw new MWForbiddenTransactionError(errorType, error.message);
  }
  throw new Error(errorType, error.message);
}

export function handleProcessOrderError(error: Error): never {
  switch (error.type) {
    case ErrorType.HttpBadRequest:
      throw new Error(ErrorType.OrderInvalidState, error.message);
    case ErrorType.HttpNotFound:
      throw new Error(ErrorType.OrderNotFound, error.message);
    default:
      throw error;
  }
}

export function handleConcurencyControlError(error: Error): never {
  const errorType = error.type === ErrorType.HttpForbidden ? ErrorType.PlaybackTooManyConcurrentSessions : ErrorType.PlaybackSessionError;
  throw new Error(errorType, error.message);
}

export enum ActivationAccountErrors {
  AccountActivationTokenInvalid = 'ConfirmationCodeInvalid',
  AccountAlreadyConfirmed = 'AccountAlreadyConfirmed',
  AccountDoesntExist = 'AccountDoesntExist',
  ConfirmationCodeExpired = 'ConfirmationCodeExpired',
  ConfirmationCodeInvalid = 'ConfirmationCodeInvalid'
}

export function handleActivateAccountError(error: Error): never {
  if (error.type === ErrorType.HttpNotFound) {
    throw new Error(ErrorType.AccountDoesntExist, error.message);
  }
  switch (error.code) {
    case ActivationAccountErrors.AccountActivationTokenInvalid:
      throw new Error(ErrorType.AccountActivationTokenInvalid, error.message);
    case ActivationAccountErrors.AccountAlreadyConfirmed:
      throw new Error(ErrorType.AccountAlreadyConfirmed, error.message);
    case ActivationAccountErrors.AccountDoesntExist:
      throw new Error(ErrorType.AccountDoesntExist, error.message);
    case ActivationAccountErrors.ConfirmationCodeExpired:
      throw new Error(ErrorType.ConfirmationCodeExpired, error.message);
    case ActivationAccountErrors.ConfirmationCodeInvalid:
      throw new Error(ErrorType.ConfirmationCodeInvalid, error.message);
    default:
      throw error;
  }
}

export function handleOrderCancelResponse(error: Error): never {
  switch (error.type) {
    case ErrorType.HttpBadRequest:
      throw new Error(ErrorType.OrderInvalidState, error.message);
    case ErrorType.HttpNotFound:
      throw new Error(ErrorType.OrderNotFound, error.message);
    default:
      throw error;
  }
}
