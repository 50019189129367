import {createStyles} from 'common-styles';
import React, {useCallback, useState, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View, StyleSheet} from 'react-native';
import {BlackPortal} from 'react-native-portal';
import {withNavigation, NavigationEventPayload} from 'react-navigation';

import {dimensions, isTizen, isWebOS, isWeb, isSTBBrowser, isBigScreen, AppRoutes} from 'common/constants';
import {combineDateAndTime} from 'common/HelperFunctions';
import {NavigationFocusState} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Channel, Event, isChannel} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import AnimatedScrollViewEpg, {channelNameMarginLeft} from 'components/epg/animated/Epg.grosso';
import ChannelsListsBarItem from 'components/epg/ChannelsListsBarItem';
import DateBarItem from 'components/epg/DateBarItem';
import EpgMenuPopup from 'components/epg/EpgMenuPopup';
import EpgNavigator from 'components/epg/EpgNavigator';
import {ScrollDirection} from 'components/epg/NitroxScrollView';
import FocusParent from 'components/FocusParent';
import {Icon, IconType} from 'components/Icon';
import NitroxText from 'components/NitroxText';
import {PlayerView, PlayerViews} from 'components/player/PlayerView';
import {usePlayerLauncher} from 'components/playerLauncher/PlayerLauncher';
import {useLazyEffect, useDisposable, useWillAppear, useIntId, useFunction, useNavigation} from 'hooks/Hooks';
import {useInactivityTimeout} from 'hooks/useInactivityTimeout';

import ScrollViewEpg from './Epg';
import EpgBigScreenDetails from './EpgBigScreenDetails';
import {getPlayerView, getEpgBoundaries} from './EpgHelperFunctions';
import {EpgScreenProps, EpgScreenPlayerViewLocation} from './EpgHelperTypes';
import {usePipPlayerVisibility} from './EpgHooks';

const Epg = isBigScreen ? AnimatedScrollViewEpg : ScrollViewEpg;

const TAG = 'EpgScreenGrosso';
const playerLocation: EpgScreenPlayerViewLocation = 'epgEmbedded';
const playerView = getPlayerView(playerLocation);

const detailsMargin = channelNameMarginLeft;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  focusParent: {
    flex: 1
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    backgroundColor: colors.epgScreen.background,
    marginLeft: 0
  },
  barContainer: {
    height: 2 * dimensions.margins.large + 30,
    paddingHorizontal: detailsMargin,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: colors.epgScreen.grid.bar.background
  },
  iconColor: colors.tile.poster.placeholder.icon,
  epgNavigator: {
    position: 'absolute',
    right: 50,
    bottom: 50
  },
  detailsContainer: {
    paddingLeft: detailsMargin
  },
  backTip: {
    position: 'absolute',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  backTipText: {
    marginLeft: dimensions.margins.small,
    color: colors.tile.description
  }
}));

const EpgScreenGrosso: React.FC<EpgScreenProps> = props => {
  const {
    epgRef, channels, focusState, focusedEvent, epgGridVisibleTime, menuPopupVisible, menuPopupLoading, onEventFocus, menuActions, anyModalVisible,
    onEventPress: onEventPressFromProps, onEpgGridVisibleTime, openMenuPopup, closeMenuPopup, onPipPlayerViewReady: onPipPlayerViewReadyFromProps, onRecordButtonPressed: onRecordButtonPressedFromProps, onInfoButtonPressed: onInfoButtonPressedFromProps
  } = props;

  const navigation = useNavigation();
  const {userInactive, renderInactivityPopup} = useInactivityTimeout(TAG);

  useLazyEffect(() => {
    if (userInactive) {
      Log.info(TAG, 'User inactive, navigating to home screen...');
      navigation.navigate(AppRoutes.Home);
    }
  }, [userInactive], [navigation]);

  const {isPlayerBlocked, tunePip} = usePipPlayerVisibility(TAG, playerView, focusState);
  const {startPlayback} = usePlayerLauncher();
  //on entering EPG or changing channel list
  useLazyEffect(() => {
    if (!isPlayerBlocked && focusState === NavigationFocusState.IsFocused) {
      tunePip({playerView});
    }
  }, [focusState, channels, isPlayerBlocked], [tunePip]);

  const scrollToNow = useCallback(() => {
    epgRef.current?.scrollToNow();
  }, [epgRef]);

  const onEventPress = useCallback(async (event: Event) => {
    if (event.isNow) {
      // TODO CL-6401 - adult unlock state should be stored in context
      return startPlayback({tvScreenParams: {channelId: event.channelId}});
    }
    onEventPressFromProps(event);
    await openMenuPopup(event, true);
  }, [onEventPressFromProps, openMenuPopup, startPlayback]);

  const focusedChannel = React.useMemo(() => {
    return focusedEvent ? mw.catalog.getChannelById(focusedEvent.channelId) : undefined;
  }, [focusedEvent]);

  const selectedChannel = React.useMemo(() => {
    return focusedChannel || channels[0]; // fallback to first channel not only when there is no focused event but also when the channel has been removed from the lineup
  }, [focusedChannel, channels]);

  const [selectedEvent, setSelectedEvent] = useState<Event | undefined>(focusedEvent);

  const onRecordButtonPressed = useFunction(() => {
    selectedEvent && onRecordButtonPressedFromProps?.(selectedEvent);
  });

  const onInfoButtonPressed = useFunction(() => {
    selectedEvent && onInfoButtonPressedFromProps?.(selectedEvent);
  });

  const updateSelectedEvent = useDisposable(async (focusedEvent?: Event, selectedChannel?: Channel) => {
    const selectedChannelId = selectedChannel && selectedChannel.id;
    try {
      if (!focusedEvent || selectedChannelId !== focusedEvent.channelId) {
        setSelectedEvent(selectedChannel && await mw.catalog.getCurrentEvent(selectedChannel));
      } else {
        setSelectedEvent(focusedEvent);
      }
    } catch (error) {
      Log.error(TAG, 'Failed to update selected event on channel with id ' + selectedChannelId + ' - got error: ' + error);
    }
  });

  useLazyEffect(() => {
    updateSelectedEvent(focusedEvent, selectedChannel);
  }, [focusedEvent, selectedChannel], [updateSelectedEvent]);

  const onLeftArrowPress = useCallback(() => epgRef.current.scrollByPage(ScrollDirection.Horizontal, -1), [epgRef]);
  const onRightArrowPress = useCallback(() => epgRef.current.scrollByPage(ScrollDirection.Horizontal, 1), [epgRef]);
  const onUpArrowPress = useCallback(() => epgRef.current.scrollByPage(ScrollDirection.Vertical, -1), [epgRef]);
  const onDownArrowPress = useCallback(() => epgRef.current.scrollByPage(ScrollDirection.Vertical, 1), [epgRef]);

  const onPipPlayerViewReady = useCallback(() => {
    onPipPlayerViewReadyFromProps(playerLocation);
  }, [onPipPlayerViewReadyFromProps]);

  const [lastPlayedChannel, setLastPlayedChannel] = useState<Channel>();

  useLazyEffect(() => {
    if (focusState === NavigationFocusState.IsFocused || focusState === NavigationFocusState.IsFocusing) {
      const currentMedia = mw.players.main.getCurrentMedia();
      if (isChannel(currentMedia)) {
        setLastPlayedChannel(currentMedia);
      } else {
        mw.catalog.getLastPlayedChannel()
          .then(setLastPlayedChannel)
          .catch(error => {
            setLastPlayedChannel(channels[0]);
            Log.error(TAG, 'Error while getting last played channel:', error);
          });
      }
    }
  }, [focusState], [channels]);

  const {id: epgKey, increment: resetEpg} = useIntId();

  useWillAppear(
    useCallback((payload: NavigationEventPayload) => {
      if (payload.action.type !== 'Navigation/BACK' && payload.action.type !== 'Navigation/POP') {
        resetEpg();
      }
    }, [resetEpg])
  );

  const scrollToDate = useCallback((date: Date) => {
    // scroll to the given date but keep time the same
    epgRef.current?.scrollToDate(combineDateAndTime(date, epgGridVisibleTime ?? new Date()));
  }, [epgRef, epgGridVisibleTime]);

  const {t} = useTranslation();
  const styles = stylesUpdater.getStyles();
  const [minDate, maxDate] = useMemo(getEpgBoundaries, []);
  Log.debug(TAG, 'render');
  return (
    <FocusParent style={styles.focusParent} enterStrategy='byPriority'>
      <View style={styles.container} testID='screen_epg'>
        {/* details section */}
        <EpgBigScreenDetails
          containerStyle={styles.detailsContainer}
          channel={selectedChannel}
          currentEvent={selectedEvent}
          isPlayerBlocked={isPlayerBlocked}
          onPipPlayerViewReady={onPipPlayerViewReady}
          playerType={PlayerViews.Epg}
        >
          {/* back tip section */}
          <View style={styles.backTip}>
            <Icon type={IconType.BackSTB} size={dimensions.icon.xsmall} />
            <NitroxText numberOfLines={1} textType='epgBackTip' upperCase style={styles.backTipText}>
              {t('epg.backTip')}
            </NitroxText>
          </View>
        </EpgBigScreenDetails>
        {/* bar section */}
        <View style={styles.barContainer}>
          {epgGridVisibleTime && <DateBarItem date={epgGridVisibleTime} onJumpToDatePress={scrollToDate} minDate={minDate} maxDate={maxDate} />}
          <ChannelsListsBarItem />
        </View>
        {/* epg section */}
        {lastPlayedChannel && (
          <FocusParent style={styles.focusParent} focusPriority={1}>
            <Epg
              key={epgKey}
              ref={epgRef}
              channels={channels}
              currentChannel={lastPlayedChannel}
              onEventFocus={onEventFocus}
              onEventPress={onEventPress}
              onRecordButtonPressed={onRecordButtonPressed}
              onInfoButtonPressed={onInfoButtonPressed}
              onEpgGridVisibleTime={onEpgGridVisibleTime}
              navigationDisabled={anyModalVisible}
            />
          </FocusParent>
        )}
        {/* epg navigator */}
        {isWeb && !isTizen && !isWebOS && !isSTBBrowser && (
          <EpgNavigator
            style={styles.epgNavigator}
            onLeftPress={onLeftArrowPress}
            onRightPress={onRightArrowPress}
            onDownPress={onDownArrowPress}
            onUpPress={onUpArrowPress}
            onNowPress={scrollToNow}
          />
        )}
        {/* menu popup section */}
        {focusedEvent && (
          <EpgMenuPopup
            visible={menuPopupVisible}
            loading={menuPopupLoading}
            actions={menuActions}
            event={focusedEvent}
            onClose={closeMenuPopup}
          />
        )}
        {renderInactivityPopup()}
      </View>
      <BlackPortal name={playerLocation}>
        <PlayerView type={playerView} style={StyleSheet.absoluteFillObject} onReady={onPipPlayerViewReady} />
      </BlackPortal>
    </FocusParent>
  );
};

export default withNavigation(React.memo(EpgScreenGrosso));
