import {EventEmitter} from 'common/EventEmitter';
import {flatten} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {nxffConfig} from 'mw/api/NXFF';
import {BOEvent} from 'mw/bo-proxy/BOInterface';
import {boProxy} from 'mw/bo-proxy/BOProxy';
import {ReportingEvent} from 'mw/bo-proxy/reporters/ReporterInterface';
import {CustomSSOAction, SSOEvent, SSOTokenRenewedParams} from 'mw/bo-proxy/SSOInterface';
import {CrossLoginDataProvider} from 'mw/common/CrossLoginDataProvider';
import {asyncStorage} from 'mw/platform/async-storage/AsyncStorage';
import {netInfo} from 'mw/platform/net-info/NetInfo';

import {Error, ErrorType} from './Error';
import {Credentials, nxff} from './NXFF';

const TAG = 'BackOffice';
const BO_ASYNC_STORAGE_KEY = 'BackOffice-bo';

export enum BackOfficeEvent {
  backOfficeUnavailable = 'backOfficeUnavailable',
  loginRequired = 'loginRequired',
  loginInProgress = 'loginInProgress',
  loginUserActionRequired = 'loginUserActionRequired',
  loggedIn = 'loggedIn',
  loginError = 'loginError',
  loginSessionRenewed = 'loginSessionRenewed',
  logoutInProgress = 'logoutInProgress',
  loggedOut = 'loggedOut',
  nxffProfileSet = 'nxffProfileSet',
  remoteNxfdError = 'remoteNxfdError'
}

export interface NXFFPreset {
  Profile: string;
  BackOffice?: string;
}

export class BackOffice extends EventEmitter<BackOfficeEvent> {
  private crossLoginDataProviders: CrossLoginDataProvider[] = [];

  public constructor() {
    super();
  }

  public async initialize(nxffPreset: NXFFPreset): Promise<void> {
    this.crossLoginDataProviders = [];
    nxff.setProfile(nxffPreset.Profile);
    this.notify(BackOfficeEvent.nxffProfileSet);

    const backOffice = await this.getBackOffice(nxffPreset);
    if (!backOffice) {
      Log.error(TAG, 'initialize failed, error: no backOffice to set');
      throw new Error(ErrorType.UnknownBackOffice);
    }

    await this.setBackOffice(backOffice);
  }

  public uninitialize(): void {
    this.clear();
  }

  private async getBackOffice(nxffPreset: NXFFPreset): Promise<string | null> {
    const savedBackOffice = await this.getSavedBackOffice();
    const selectableBackOffices = nxff.getSelectableBackOffices();
    const selectableBOCode = (boCode?: string | null) => selectableBackOffices.find(bo => bo.code === boCode)?.code;
    return selectableBOCode(savedBackOffice)
      || nxffPreset.BackOffice
      || selectableBOCode(nxff.getConfig().DemoFeatures.DefaultBO)
      || selectableBackOffices[0]?.code
      || null;
  }

  public async setBackOffice(boCode: string): Promise<void> {
    await nxff.setBackOffice(boCode)
      .catch(error => {
        Log.error(TAG, 'setBackOffice failed');
        this.clearBackOffice();
        switch (error?.type) {
          case ErrorType.RemoteNxfdNotFound:
          case ErrorType.RemoteNxfdValidationFailed:
          case ErrorType.RemoteNxfdUnknownError:
            this.notify(BackOfficeEvent.remoteNxfdError, error);
            break;
        }
        throw error;
      });
    boProxy.initialize();
    boProxy.bo.on(BOEvent.BOUnauthorized, this.onUnauthorizedEvent);
    boProxy.bo.on(BOEvent.BOUnavailable, this.onBOUnavailable);
    boProxy.sso.on(SSOEvent.SSOUnauthorized, this.onUnauthorizedEvent);
    boProxy.sso.on(SSOEvent.SSOUserActionNeeded, this.onUserActionNeeded);
    boProxy.sso.on(SSOEvent.SSOTokenRenewed, this.onTokenRenewed);
    boProxy.reportingManager?.on(ReportingEvent.Unauthorized, this.onUnauthorizedEvent);

    await this.saveBackOffice(boCode);

    if (boProxy.sso.isLoggedIn()) {
      this.notify(BackOfficeEvent.loggedIn);
    }
  }

  private onBOUnavailable = (error: Error): void => {
    Log.error(TAG, 'onBOUnavailable', error); //TODO: CL-5981 Message is not logged properly
    this.notify(BackOfficeEvent.backOfficeUnavailable, new Error(ErrorType.BOUnavailableError, error.message));
  }

  private onUnauthorizedEvent = (): Promise<void> => {
    if (!boProxy.sso.isLoggedIn()) {
      Log.debug(TAG, 'onUnauthorizedEvent: already logged out');
      return Promise.resolve();
    }

    Log.info(TAG, 'onUnauthorizedEvent: call to logout');

    return this.logout().
      then(() => this.notify(BackOfficeEvent.loginRequired));
  };

  private onUserActionNeeded = (payload: CustomSSOAction) => this.notify(BackOfficeEvent.loginUserActionRequired, payload);

  private onTokenRenewed = (params: SSOTokenRenewedParams) => {
    Log.info(TAG, 'onTokenRenewed', params);
    if (params.forced) {
      this.notify(BackOfficeEvent.loginSessionRenewed);
    }
  };

  public login(credentials?: Credentials): Promise<void> {
    return this.loginImpl(credentials, true);
  }

  public autologin(): Promise<void> {
    return this.loginImpl();
  }

  private loginImpl(credentials?: Credentials, notifyAuthorizationErrors = false): Promise<void> {
    this.notify(BackOfficeEvent.loginInProgress);
    return this.checkConnectedToNetwork()
      .then(() => boProxy.sso.login(credentials))
      .then(() => this.notify(BackOfficeEvent.loggedIn))
      .catch((error: Error) => {
        Log.error(TAG, 'Login failed', error);
        if (this.isSSOUnavailableError(error) || notifyAuthorizationErrors) {
          this.notify(BackOfficeEvent.loginError, this.createLoginError(error));
        }
        if (error.type !== ErrorType.AccountNotActivated) {
          this.notify(BackOfficeEvent.loginRequired);
        }
        return Promise.reject(error);
      });
  }

  private checkConnectedToNetwork(): Promise<void> {
    return netInfo.fetch()
      .then(netInfoState => {
        if (!netInfoState.isConnected) {
          return Promise.reject(new Error(ErrorType.SSOUnavailableError));
        }
      });
  }

  private isSSOUnavailableError(error: Error): boolean {
    switch (error.type) {
      case ErrorType.SSOUnavailableError:
      case ErrorType.HttpTimeout:
      case ErrorType.NetworkNoConnection:
        return true;
      default:
        return false;
    }
  }

  private createLoginError(error: Error): Error {
    if (this.isSSOUnavailableError(error)) {
      return new Error(ErrorType.SSOUnavailableError, error.message);
    }
    return error;
  }

  public relogin(): Promise<void> {
    const isLoggedIn = boProxy.sso.isLoggedIn();
    Log.info(TAG, 'Renewing login session', isLoggedIn);
    if (!isLoggedIn) {
      Log.info(TAG, 'relogin: user not logged in, no need to renew session');
      return Promise.resolve();
    }

    return this.checkConnectedToNetwork()
      .then(() => boProxy.sso.renewToken())
      .catch((error: Error) => {
        Log.error(TAG, 'Renewing login session failed', error);
        const loginError = this.createLoginError(error);
        if (loginError.type === ErrorType.SSOUnavailableError) {
          this.notify(BackOfficeEvent.loginError, this.createLoginError(error));
          this.notify(BackOfficeEvent.loginRequired);
          return;
        }

        // User is logged so we have to logout first otherwise MW initialization will not work properly on next login.
        // Error can't be passed to UI via BackOfficeEvent.loginError because it will be cleared on next MW initialization.
        return this.logout(loginError)
          .then(() => Promise.reject(error));
      });
  }

  private async prepareForLogout(): Promise<void> {
    this.notify(BackOfficeEvent.logoutInProgress);

    const enabledBOSelection = nxffConfig.getConfig().DemoFeatures.EnabledBOSelection;
    let backOfficeToSave: string | null = null;

    if (enabledBOSelection === true) {
      backOfficeToSave = await this.getSavedBackOffice();
    }

    const crossLoginData = flatten(
      await Promise.all(
        this.crossLoginDataProviders
          .map(provider => provider.getCrossLoginData())
      )
    );

    await boProxy.sso.logout();
    await asyncStorage.clear();

    await Promise.all(
      crossLoginData
        .map(({key, value}) => asyncStorage.setSecureItem(key, value))
    );

    if (backOfficeToSave) {
      await this.saveBackOffice(backOfficeToSave);
    }
  }

  public async logout(error?: Error): Promise<void> {
    await this.prepareForLogout();
    this.logoutFinished(error);
  }

  public async logoutAndSaveBackOffice(boCode: string): Promise<void> {
    await this.prepareForLogout();
    await this.saveBackOffice(boCode);
    this.logoutFinished();
  }

  public logoutFinished(error?: Error): void {
    this.notify(BackOfficeEvent.loggedOut, error);
    this.notify(BackOfficeEvent.loginRequired);
  }

  public getBackOfficeCode(): string {
    return nxff.getBackOfficeCode();
  }

  private getSavedBackOffice(): Promise<string | null> {
    return asyncStorage.getSecureItem(BO_ASYNC_STORAGE_KEY);
  }

  private saveBackOffice(backOffice: string): Promise<void> {
    return asyncStorage.setSecureItem(BO_ASYNC_STORAGE_KEY, backOffice);
  }

  private clearBackOffice(): Promise<void> {
    Log.debug(TAG, 'clearBackOffice');
    return asyncStorage.removeSecureItem(BO_ASYNC_STORAGE_KEY);
  }

  public registerCrossLoginDataProvider(provider: CrossLoginDataProvider): void {
    this.crossLoginDataProviders.push(provider);
  }

  public get isLoggedIn(): boolean {
    return boProxy.sso?.isLoggedIn?.() ?? false;
  }
}
