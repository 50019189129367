import React, {useState} from 'react';
import {LayoutRectangle} from 'react-native';

import {initialTabBarLayout} from 'common/constants';

import {useFunction} from 'hooks/Hooks';

import {TabBarContext} from './NavigationHelperTypes';

export const TabBarLayoutProvider: React.FC<{}> = ({children}) => {
  const [layout, setLayout] = useState(initialTabBarLayout);
  const onLayout = useFunction((layout: LayoutRectangle) => setLayout(layout));

  return (
    <TabBarContext.Provider value={{layout, onChange: onLayout}}>
      {children}
    </TabBarContext.Provider>
  );
};
