import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {ViewStyle, View, StyleProp} from 'react-native';

import {RADIUS, dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {arrowSelector} from 'components/ArrowSelector.shared';

import {ArrowSelectorComponentProps} from './ArrowSelector.shared';
import FocusParent from './FocusParent';
import {IconType, Icon} from './Icon';
import NitroxInteractive from './NitroxInteractive';
import NitroxText from './NitroxText';

const defaultTextFieldWidth = 70;
const minArrowContainerWidth = arrowSelector.arrowSize + 2 * dimensions.margins.xsmall;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  arrowColor: colors.arrowSelector.text,
  textStyle: {
    color: colors.arrowSelector.text,
    textAlign: 'center'
  },
  container: {
    flexDirection: 'row',
    height: arrowSelector.height,
    borderRadius: RADIUS,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.arrowSelector.mobile.background,
    overflow: 'hidden'
  }
}));

const styles = createStyles({
  disabled: {
    opacity: dimensions.opacity.xlow
  },
  arrowContainer: {
    paddingHorizontal: dimensions.margins.small,
    height: '100%',
    width: '50%',
    justifyContent: 'center'
  }
});

const ArrowSelectorPiccolo: React.FunctionComponent<ArrowSelectorComponentProps> = props => {
  const {arrowLeftDisabled, arrowRightDisabled, onArrowPressLeft, onArrowPressRight, disabled, displayValue, textFieldWidth} = props;
  const dynamicStyles = stylesUpdater.getStyles();
  const arrowLeftStyle = useMemo<StyleProp<ViewStyle>>(() => [
    styles.arrowContainer,
    (arrowLeftDisabled || disabled) && styles.disabled,
    {position: 'absolute', left: 0}
  ], [arrowLeftDisabled, disabled]);
  const arrowRightStyle = useMemo<StyleProp<ViewStyle>>(() => [
    styles.arrowContainer,
    (arrowRightDisabled || disabled) && styles.disabled,
    {alignItems: 'flex-end', position: 'absolute', right: 0}
  ], [arrowRightDisabled, disabled]);
  const textStyle = useMemo(() => [
    dynamicStyles.textStyle,
    disabled && styles.disabled,
    {width: (textFieldWidth ?? defaultTextFieldWidth) + 2 * minArrowContainerWidth}
  ], [disabled, dynamicStyles, textFieldWidth]);
  return (
    <FocusParent style={[props.style]}>
      <View style={dynamicStyles.container}>
        <NitroxText textType='guardTime' style={textStyle}>
          {displayValue}
        </NitroxText>
        <NitroxInteractive
          onPress={onArrowPressLeft}
          disabled={arrowLeftDisabled || disabled}
          style={arrowLeftStyle}
        >
          <Icon type={IconType.ArrowLeft} size={arrowSelector.arrowSize} color={dynamicStyles.arrowColor} />
        </NitroxInteractive>
        <NitroxInteractive
          onPress={onArrowPressRight}
          disabled={arrowRightDisabled || disabled}
          style={arrowRightStyle}
        >
          <Icon type={IconType.ArrowRight} size={arrowSelector.arrowSize} color={dynamicStyles.arrowColor} />
        </NitroxInteractive>
      </View>

    </FocusParent>
  );
};

export default React.memo(ArrowSelectorPiccolo);
