import React from 'react';
import {View} from 'react-native';

import {dimensions} from 'common/constants';

import {Recording} from 'mw/api/Metadata';

import {useLazyEffect} from 'hooks/Hooks';

import RecordingTile from './RecordingTile';

type RecordingGridTileProps = {
  recording?: Recording;
  isFolderTile?: boolean;
  onPress: (recording: Recording) => void;
  onMount?: () => void;
  index: number;
  selected: boolean;
  selectable: boolean;
  onSelectionCheckboxPress: () => void;
};

const RecordingGridTile: React.FC<RecordingGridTileProps> = React.memo(({
  recording,
  isFolderTile,
  onPress,
  onMount,
  index,
  selected,
  selectable,
  onSelectionCheckboxPress
}) => {
  useLazyEffect(() => {
    onMount?.();
  }, [], [onMount]);

  return recording ? (
    <RecordingTile
      onPress={onPress}
      recording={recording}
      selected={selected}
      selectable={selectable}
      onSelectionCheckboxPress={onSelectionCheckboxPress}
      isFolderTile={isFolderTile}
    />
  ) : ( // fake, invisible tile to render last row correctly
    <View
      key={index}
      style={{
        width: dimensions.tile.width,
        height: dimensions.tile.height,
        marginHorizontal: dimensions.margins.small
      }}
    />
  );
});
RecordingGridTile.displayName = 'RecordingGridTile';

export default RecordingGridTile;
