import {StyleProp, ViewStyle} from 'react-native';

import {isMobile, dimensions} from 'common/constants';

import {createStyles} from 'common-styles/index';
import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {ButtonIconProps} from './NitroxButton';

export interface Option {
  key: string;
  label: string;
  iconProps?: ButtonIconProps;
}

export type OptionPickerProps = {
  label: string;
  options: Option[];
  onOptionSelected: (key: string) => void;
  defaultOptionKey?: string;
  style?: StyleProp<ViewStyle>;
}

export const optionPickerStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  label: {
    color: colors.vodScreen.label,
    paddingRight: isMobile ? dimensions.margins.small : dimensions.margins.medium
  }
}));
