import {createStyles} from 'common-styles';
import React from 'react';
import {StyleSheet, View} from 'react-native';

import {getValue} from 'common/constants';

import {StylesUpdater, useStyles} from 'common-styles/StylesUpdater';

import {Channel, Event} from 'mw/api/Metadata';

import GradientFill, {GradientFillDirection} from 'components/GradientFill';
import {FAR_FAR_AWAY} from 'components/navigation/ResourceSavingScene';
import Clock from 'components/zapper/Clock';

import TvChannelList from './TvChannelList';

const TAG = 'ChannelListOverlay';

const gradientUpdater = new StylesUpdater(colors => ({
  colors: colors.tvScreen.overlay.channelList.gradient
}));

const staticStyles = createStyles({
  clock: {
    position: 'absolute',
    top: '5%',
    right: '4%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    height: getValue({mobile: 34, defaultValue: 61})
  },
  gradientContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: 1400
  },
  container: {
    ...StyleSheet.absoluteFillObject,

    justifyContent: 'center',
    alignItems: 'center'
  },
  hidden: {
    top: FAR_FAR_AWAY
  }
});

type Props = {
  channel?: Channel;
  event?: Event;
  channels?: Channel[];
  visible: boolean;

  // consider changing void -> Promise<void>, tune indicator could be helpful UX-wise
  onChannelPress: (channel: Channel) => void;
  showOverlay: () => void;
}

const ChannelListOverlay: React.FC<Props> = ({
  channel,
  channels,
  onChannelPress,
  showOverlay,
  visible
}) => {
  const gradientStyles = useStyles(gradientUpdater);

  return (
    <View style={[staticStyles.container, !visible && staticStyles.hidden]} testID='channel_list_overlay'>
      <View style={staticStyles.gradientContainer}>
        <GradientFill
          direction={GradientFillDirection.Horizontal}
          colors={gradientStyles.colors}
        />
      </View>
      <Clock
        containerStyle={staticStyles.clock}
        active={visible}
      />
      {channel != null && !!channels?.length && (
        <TvChannelList
          channel={channel}
          channels={channels}
          onChannelPress={onChannelPress}
          showOverlay={showOverlay}
          visible={visible}
        />
      )}
    </View>
  );
};

export default ChannelListOverlay;
