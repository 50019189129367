import {createStyles} from 'common-styles';
import React, {useCallback, useEffect, useState} from 'react';
import {StyleProp, StyleSheet, View, ViewStyle, ActivityIndicator} from 'react-native';

import {useEventListener, useIntId} from 'common/hooks/Hooks';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {PlayerEvent} from 'mw/api/PlayerEvent';
import {mw} from 'mw/MW';
import {WebPlayerModule} from 'mw/playback/web-native-player/WebPlayerModule';

const TAG = 'Video.web';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    backgroundColor: colors.playerScreen.background
  }
}));

type Props = {
  viewId: number;
  onReady?: () => void;
  style?: StyleProp<ViewStyle>;
}

export const Video: React.FunctionComponent<Props> = props => {
  const playerViewId = `video-player-${props.viewId}`;

  const {id: reloadKey, increment: reloadVideo} = useIntId();
  const [blackCurtain, setBlackCurtain] = useState(mw.players.main.isPCBlocked);
  const [loading, setLoading] = useState(false);

  const reload = useCallback(() => {
    Log.debug(TAG, 'Reloading');
    reloadVideo();
  }, [reloadVideo]);

  const onReady = () => {
    Log.debug(TAG, 'onReady');
    props.onReady?.();
  };

  const initializePlayer = (props: Props) => {
    Log.debug(TAG, 'init player viewId: ', playerViewId);
    WebPlayerModule.getInstance().register(props.viewId, {
      playerViewId: playerViewId,
      onRelease: reload // player.dispose removes video tag, call reload to re-create it
    });
    onReady();
  };

  const uninitializePlayer = (): void => {
    WebPlayerModule.getInstance().unregister(props.viewId);
  };

  useEffect(() => {
    initializePlayer(props);
    return uninitializePlayer;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEventListener(PlayerEvent.VideoHidden, setBlackCurtain, mw.players.main);
  useEventListener(PlayerEvent.Loading, setLoading, mw.players.main);

  const styles = stylesUpdater.getStyles();
  return (
    <View key={reloadKey} style={[styles.container, props.style]}>
      <div data-vjs-player>
        <video style={{width: '100%', height: '100%'}} id={playerViewId} />
        {blackCurtain && <View style={[styles.container, StyleSheet.absoluteFillObject]} />}
        <ActivityIndicator animating={loading} size='large' style={StyleSheet.absoluteFillObject} />
      </div>
    </View>
  );
};

export default React.memo(Video);
