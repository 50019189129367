import {Insets} from 'react-native';

import {getValue, dimensions, isBigScreen} from 'common/constants';
import {findPlayableRecording, compactMap, getRentProducts, getBuyProducts} from 'common/HelperFunctions';

import {Event, Media, MediaType, Series, Title, Recording, EntitlementState} from 'mw/api/Metadata';

import {mediaTileMarginHorizontal} from 'components/mediaTiles/MediaTile';
import {isPurchaseAllowed} from 'components/payments/PaymentHelperFunctions';
import {getRecordActionType, RecordActionType, getCancelRecordingActionType, CancelRecordingActionType} from 'components/pvr/RecordingUtils';

export const mediaDetailHorizontalMargin = getValue({
  mobile: dimensions.margins.large,
  tablet: dimensions.margins.xxLarge,
  defaultValue: 190
});

const mediaDetailSwimlaneHorizontalInset = mediaDetailHorizontalMargin - mediaTileMarginHorizontal;
export const mediaDetailSwimlaneInsets: Insets = {
  left: mediaDetailSwimlaneHorizontalInset,
  right: mediaDetailSwimlaneHorizontalInset
};

export enum Action {
  WATCH,
  WATCH_ON,
  WATCH_NOW,
  WATCH_RECORDING,
  BUY,
  RENT,
  PURCHASE_IN_PROGRESS,
  ADD_TO_WATCHLIST,
  REMOVE_FROM_WATCHLIST,
  SEEN_IT,
  RATE,
  SELECT_SEASON,
  RECORD,
  RECORD_SERIES,
  RECORD_ALL,
  DELETE_RECORDING,
  CANCEL_RECORDING,
  CANCEL_SERIES_RECORDING,
  RESTART,
  UNLOCK,
  CUSTOM
}

export enum CustomActionKeys {
  DEEPLINKING
}

export interface CustomAction {
  action: Action;
  label: string;
  key: CustomActionKeys;
  value: any;
  isDeeplinkingAction?: boolean;
}

export type MediaDetailButtonsAction = Action | CustomAction;

export function isCustomAction(obj: MediaDetailButtonsAction): obj is CustomAction {
  return typeof obj === 'object';
}

function getWatchListActions(media: Media): Action[] {
  return media.isAllowedOnWatchList()
    ? [media.isOnWatchList ? Action.REMOVE_FROM_WATCHLIST : Action.ADD_TO_WATCHLIST]
    : [];
}

const getTitleActions = (title: Title): MediaDetailButtonsAction[] => {
  const actions: MediaDetailButtonsAction[] = [];
  const contents = title.contents.filter(content => content.isAllowed());

  const deepLinkingActions = compactMap(contents, content => {
    if (content.externalLink) {
      return {
        action: Action.CUSTOM,
        label: content.externalLink.provider,
        key: CustomActionKeys.DEEPLINKING,
        value: content.externalLink,
        isDeeplinkingAction: true
      } as MediaDetailButtonsAction;
    }
  });

  if (deepLinkingActions.length) {
    actions.push(...isBigScreen ? deepLinkingActions : [Action.WATCH_ON]);
  }

  if (isBigScreen && contents.some(content => !content.externalLink)) {
    actions.push(Action.WATCH);
  }

  if (title.entitlementState === EntitlementState.EntitlementInProgress) {
    actions.push(Action.PURCHASE_IN_PROGRESS);
  } else if (isPurchaseAllowed() && !contents.length) {
    const isRentable = !!getRentProducts(title).length;
    const isBuyable = !!getBuyProducts(title).length;
    isBuyable && actions.push(Action.BUY);
    isRentable && actions.push(Action.RENT);
  }

  return [...Array.from(new Set(actions)), ...getWatchListActions(title)];
};

const getSeriesActions = (series: Series): Action[] => {
  const actions: Action[] = [];

  if (series.seasons.length > 1) {
    actions.push(Action.SELECT_SEASON);
  }

  return [...actions, ...getWatchListActions(series)];
};

const getEventActions = (event: Event, eventRecording?: Recording, seriesRecording?: Recording): Action[] => {
  const actions: Action[] = [];

  if (eventRecording && findPlayableRecording([eventRecording])) {
    actions.push(Action.WATCH_RECORDING);
  } else {
    if (event.channelId && event.isNow) {
      actions.push(Action.WATCH_NOW);
    }
    if (event.hasTstv) {
      actions.push(Action.RESTART);
    }
  }

  switch (getRecordActionType(event, eventRecording, seriesRecording)) {
    case RecordActionType.record:
      actions.push(Action.RECORD);
      break;
    case RecordActionType.recordSeries:
      actions.push(Action.RECORD_SERIES);
      break;
    case RecordActionType.recordAll:
      actions.push(Action.RECORD_ALL);
      break;
  }

  switch (getCancelRecordingActionType(event, eventRecording, seriesRecording)) {
    case CancelRecordingActionType.cancel:
      actions.push(Action.CANCEL_RECORDING);
      break;
    case CancelRecordingActionType.cancelSeries:
      actions.push(Action.CANCEL_SERIES_RECORDING);
      break;
  }

  if (eventRecording && findPlayableRecording([eventRecording])) {
    actions.push(Action.DELETE_RECORDING);
  }

  return [...actions, ...getWatchListActions(event)];
};

export const getMediaActions = (media: Media, eventRecording?: Recording, seriesRecording?: Recording, additionalActions: Action[] = []): MediaDetailButtonsAction[] => {
  const mediaActions: MediaDetailButtonsAction[] = [];
  switch (media.getType()) {
    case MediaType.Title:
      mediaActions.push(...getTitleActions(media as Title));
      break;
    case MediaType.Series:
      mediaActions.push(...getSeriesActions(media as Series));
      break;
    case MediaType.Event:
      mediaActions.push(...getEventActions(media as Event, eventRecording, seriesRecording));
      break;
  }
  mediaActions.push(...additionalActions);
  return Array.from(new Set(mediaActions));
};

export const isSameMedia = (firstMedia?: Media, secondMedia?: Media) => {
  return firstMedia?.id === secondMedia?.id;
};
