import React, {useState, useCallback, useRef} from 'react';
import {ViewStyle} from 'react-native';

import {isBigScreen} from 'common/constants';
import {Log} from 'common/Log';

import {Error} from 'mw/api/Error';

import {PopupError, useErrorPopup} from './ErrorPopup';
import FocusParent from './FocusParent';
import WizardGrosso from './WizardContent.grosso';
import WizardPiccolo from './WizardContent.piccolo';

const TAG = 'Wizard';

const WizardContent = isBigScreen ? WizardGrosso : WizardPiccolo;

export type WizardContentProps = {
  currentStep?: WizardStep;
  title?: string;
  error: PopupError | null;
  onForwardPress: () => void;
  onBackPress: () => void;
  onErrorPopupClose?: () => void;
  embedded?: boolean;
  numberOfSteps: number;
  stepIndex: number;
}

export type WizardElements = {
  header?: React.ReactNode;
  tracker?: React.ReactNode;
  forwardButton?: React.ReactNode;
  focusForwardButton?: () => void;
}

export type WizardStep = {
  renderContent: (elements?: WizardElements) => React.ReactNode;
  onConfirm: () => Promise<NextStepRequestResponse>;
  showStepper?: boolean;
  showForwardButton?: boolean;
  showBackButton?: boolean;
  forwardButtonText?: string;
  title?: string;
  canGoBack?: boolean;
  mobileHeaderStyle?: ViewStyle;
  /** whether step wants to render wizard elements like next button or tracker */
  embedElements?: boolean;
}

export type NextStepRequestResponse = {ok: true} | {ok: false; error: Error; moveForward?: boolean};

function formatErrorMessage(error: Error): string {
  let message = error.message || '';
  if (error.type) {
    message += ' (code: ' + error.type + ')';
  }
  return message.trim();
}

type WizardProps = {
  steps: WizardStep[];
  onDone: () => void;
  onCancel: () => void;
  title?: string;
  embedded?: boolean;
  numberOfSteps?: number;
}

const containerStyle: ViewStyle = {flex: 1};

const Wizard: React.FC<WizardProps> = ({
  title: propsTitle,
  steps,
  embedded,
  onDone,
  onCancel,
  numberOfSteps = steps.length
}) => {
  const [stepIndex, setStepIndex] = useState(0);
  const currentStep = steps[stepIndex];
  const title = currentStep.title ?? propsTitle;

  if (!currentStep) {
    Log.error(TAG, `Error: step #${stepIndex} doesn't exist!`);
  }

  const {error, showError, onCloseErrorPopup} = useErrorPopup();

  const onBackPress = useCallback(() => {
    if (stepIndex === 0) {
      onCancel();
    } else {
      setStepIndex(stepIndex - 1);
    }
  }, [onCancel, stepIndex]);

  const waitingForConfirmation = useRef(false);

  const moveForward = useCallback(() => {
    if (stepIndex === steps.length - 1) {
      Log.debug(TAG, 'There are no more steps - we are done');
      onDone();
    } else {
      Log.debug(TAG, 'Moving forward to the next step');
      setStepIndex(stepIndex + 1);
    }
  }, [onDone, stepIndex, steps.length]);

  const onForwardPress = useCallback(async () => {
    if (!currentStep || waitingForConfirmation.current) {
      return;
    }
    waitingForConfirmation.current = true;
    const response = await currentStep.onConfirm();
    if (response.ok) {
      moveForward();
    } else {
      Log.error(TAG, 'Error when trying to go to next step', response.error);
      if (response.moveForward) {
        moveForward();
      }
      showError(formatErrorMessage(response.error));
    }
    waitingForConfirmation.current = false;
  }, [currentStep, moveForward, showError]);

  return (
    <FocusParent style={containerStyle}>
      <WizardContent
        numberOfSteps={numberOfSteps}
        onBackPress={onBackPress}
        onForwardPress={onForwardPress}
        stepIndex={stepIndex}
        currentStep={currentStep}
        embedded={embedded}
        title={title}
        error={error}
        onErrorPopupClose={onCloseErrorPopup}
      />
    </FocusParent>
  );
};

export default Wizard;
