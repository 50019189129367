import React, {useState, useEffect, useCallback, useMemo} from 'react';

import {isMobile} from 'common/constants';

import ArrowSelectorGrosso from './ArrowSelector.grosso';
import ArrowSelectorPiccolo from './ArrowSelector.piccolo';
import {ArrowSelectorProps, ArrowSelectorComponentProps} from './ArrowSelector.shared';

const ArrowSelector: React.FC<ArrowSelectorProps> = props => {
  const {availableValues, onValueChange, initialValue, suffix} = props;

  const [currentIndex, setCurrentIndex] = useState<number>(Math.max(availableValues.findIndex(item => item === initialValue), 0));
  const [currentValue, setCurrentValue] = useState<number>(availableValues[Math.max(availableValues.findIndex(item => item === initialValue), 0)]);
  const arrowLeftDisabled = useMemo(() => currentIndex === 0, [currentIndex]);
  const arrowRightDisabled = useMemo(() => currentIndex === availableValues.length - 1, [currentIndex, availableValues]);

  const onArrowPressRight = useCallback(() => {
    if (arrowRightDisabled) {
      return;
    }
    const newIndex = currentIndex + 1;
    onValueChange(availableValues[newIndex]);
    setCurrentIndex(newIndex);
    setCurrentValue(availableValues[newIndex]);
  }, [currentIndex, availableValues, onValueChange, arrowRightDisabled]);

  const onArrowPressLeft = useCallback(() => {
    if (arrowLeftDisabled) {
      return;
    }
    const newIndex = currentIndex - 1;
    onValueChange(availableValues[newIndex]);
    setCurrentIndex(newIndex);
    setCurrentValue(availableValues[newIndex]);
  }, [currentIndex, onValueChange, availableValues, arrowLeftDisabled]);

  useEffect(() => {
    const currentValueIndex = availableValues.findIndex(value => value === currentValue);
    if (currentValueIndex >= 0) {
      setCurrentIndex(currentValueIndex);
      return;
    }

    const initialValueIndex = availableValues.findIndex(value => value === initialValue);
    if (initialValue && initialValueIndex >= 0) {
      setCurrentIndex(initialValueIndex);
      setCurrentValue(initialValue);
      return;
    }

    setCurrentIndex(0);
    setCurrentValue(availableValues[0]);

  }, [availableValues, currentValue, initialValue]);

  const componentProps: ArrowSelectorComponentProps = useMemo(() => ({
    ...props,
    currentIndex,
    currentValue,
    onArrowPressLeft,
    onArrowPressRight,
    arrowLeftDisabled,
    arrowRightDisabled,
    displayValue: `${currentValue}${suffix ? ' ' + suffix : ''}`
  }), [arrowLeftDisabled, arrowRightDisabled, currentIndex, currentValue, onArrowPressLeft, onArrowPressRight, props, suffix]);

  return isMobile ? <ArrowSelectorPiccolo {...componentProps} /> : <ArrowSelectorGrosso {...componentProps} />;
};

export default ArrowSelector;
