import {createStyles} from 'common-styles';
import React from 'react';
import {View} from 'react-native';

import {dimensions} from 'common/constants';

import {StylesUpdater, useStyles} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxText from 'components/NitroxText';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flex: 1
  },
  label: {
    color: colors.settingsScreen.mobile.profile.clickableOption.title
  },
  description: {
    color: colors.settingsScreen.mobile.profile.clickableOption.description,
    marginBottom: dimensions.margins.xsmall
  },
  disabled: {
    opacity: dimensions.opacity.xlow
  }
}));

type Props = {
  label: string;
  description?: string;
  disabled?: boolean;
};

const SettingsLabel: React.FC<Props> = ({label, description, disabled}) => {
  const styles = useStyles(stylesUpdater);
  return (
    <View style={[styles.container, disabled && styles.disabled]}>
      <NitroxText style={styles.label} textType='settings-label'>{label}</NitroxText>
      {!!description && <NitroxText textType='settings-description' style={styles.description}>{description}</NitroxText>}
    </View>
  );
};

export default SettingsLabel;
