import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {ViewStyle} from 'react-native';
import Animated, {sub, cond, divide, interpolate, abs, greaterThan, Extrapolate, useCode, call} from 'react-native-reanimated';

import {commonAnimationTransforms} from 'common/constants';
import {InterpolationSample} from 'common/HelperTypes';

const defaultSamples: InterpolationSample<number, number>[] = [
  {point: 0, value: 1},
  {point: 1, value: 0.6}
];

const staticStyles = createStyles({
  container: {
    overflow: 'hidden',
    flex: 1,
    flexDirection: 'column',
    alignContent: 'flex-start',
    justifyContent: 'center'
  }
});

export type ScalableTileProps<DataType> = {
  itemIndex: number;
  itemSize: number;
  itemData: DataType;
  renderItem: (itemData: DataType, itemIndex: number) => JSX.Element;
  /**
   * Called when ScalableTile is rendered, scaled and visible (scale is greater than 0).
   */
  onRendered?: (itemData: DataType) => void;
  containerPosition: Animated.Adaptable<number>;
  containerWidth: number;
  /**
   * Array of interpolation samples used to interpolate the tile's scale. Sample points are expressed as a distance from the center
   * of the tile to the center of the container and are expressed as a percentage ranging from 0 to 1 where 0 is the center
   * and 1 is the right or left side of the container. Sample values represent a scale to which the tile should be scaled up or down.
   */
  samples?: InterpolationSample<number, number>[];
  selected?: boolean;
};

function ScalableTile<DataType>({
  itemIndex,
  itemSize,
  itemData,
  renderItem,
  onRendered,
  containerPosition,
  containerWidth,
  samples = defaultSamples
}: ScalableTileProps<DataType>) {

  const [inputRange, outputRange] = useMemo(() => (
    samples.reduce<[number[], number[]]>((ranges, sample) => {
      ranges[0].push(sample.point);
      ranges[1].push(sample.value);
      return ranges;
    }, [[], []])
  ), [samples]);

  const scale = useMemo(() => {
    const marginLeft = Math.floor(containerWidth - itemSize) / 2;
    const containerCenter = Math.floor(containerWidth / 2);
    const iconCenter = (itemSize * itemIndex) + Math.floor(itemSize / 2) + marginLeft;
    const distanceToCenter = divide(abs(sub(sub(iconCenter, containerPosition), containerCenter)), containerCenter);
    return cond(greaterThan(containerWidth, 0), [
      interpolate(distanceToCenter, {
        inputRange,
        outputRange,
        extrapolate: Extrapolate.CLAMP
      })
    ], 1);
  }, [containerWidth, itemSize, itemIndex, containerPosition, inputRange, outputRange]);

  useCode(() => (
    cond(greaterThan(scale, 0),
      call([scale], () => onRendered?.(itemData)))
  ), [scale, itemData, onRendered]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const style: Animated.AnimateStyle<ViewStyle> = useMemo(() => ({
    ...staticStyles.container,
    width: itemSize,
    transform: [
      {scale},
      ...commonAnimationTransforms
    ]
  }), [scale, itemSize]);

  const content = useMemo(() => (
    renderItem(itemData, itemIndex)
  ), [renderItem, itemData, itemIndex]);

  return (
    <Animated.View style={style}>
      {content}
    </Animated.View>
  );
}

export default React.memo(ScalableTile) as typeof ScalableTile;
