import {createStyles} from 'common-styles';
import React, {Ref} from 'react';
import {GestureResponderEvent, View} from 'react-native';

import {dimensions, isBigScreen} from 'common/constants';

import {ChannelIcon, ChannelIconType} from 'components/ChannelIcon';
import ChannelNumber, {channelNumberSize} from 'components/ChannelNumber';
import {FocusableComponent} from 'components/focusManager/FocusManagerTypes';

import {ChannelsListItemProps} from './ChannelsListItemProps';

const channelNumberOffset = isBigScreen ? Math.floor(channelNumberSize / 2) : channelNumberSize - dimensions.margins.xsmall;
const styles = createStyles({
  channelIconContainer: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  channelNumber: {
    position: 'absolute',
    bottom: -channelNumberOffset
  }
});

type Props = ChannelsListItemProps & {
  iconType: ChannelIconType;
  onBlur?: (channelId?: string, event?: GestureResponderEvent) => void;
  showChannelNumber?: boolean;
};

const ChannelsListItemBase: React.FC<Props> = (props, ref: Ref<FocusableComponent>) => {
  return (
    <View style={[styles.channelIconContainer, props.style]}>
      <ChannelIcon
        ref={ref}
        type={props.iconType}
        animated={!props.horizontal}
        isSelected={props.isSelected}
        channelId={props.channel.id}
        onFocus={props.onFocus}
        onPress={props.onPress}
        onBlur={props.onBlur}
      />
      {props.isSelected && props.showChannelNumber && (
        <ChannelNumber channel={props.channel} styles={styles.channelNumber} />
      )}
    </View>
  );
};

const MemoizedChannelsListItemBase = React.memo(React.forwardRef<FocusableComponent, Props>(ChannelsListItemBase));
MemoizedChannelsListItemBase.displayName = ChannelsListItemBase.name;
export default MemoizedChannelsListItemBase;
