import {maxBy} from 'common/helpers/ArrayHelperFunctions';

import {Picture} from 'mw/api/Metadata';

function stuffingRatio(placeholderWidth: number, placeholderHeight: number, imageWidth: number, imageHeight: number): number {
  const scalingFactor = Math.pow(Math.min(placeholderWidth / imageWidth, placeholderHeight / imageHeight), 2);
  return placeholderWidth * placeholderHeight - imageWidth * imageHeight * scalingFactor;
}

function getSmallestPictureByStuffingRatio(pictures: Picture[], width: number, height: number): Picture | undefined {
  return maxBy(pictures, (picture1, picture2) => {
    const picture1StuffingRatio = stuffingRatio(width, height, picture1.width, picture1.height);
    const picture2StuffingRatio = stuffingRatio(width, height, picture2.width, picture2.height);
    if (picture1StuffingRatio === picture2StuffingRatio) {
      return (picture1.width * picture1.height) < (picture2.width * picture2.height);
    }
    return picture1StuffingRatio < picture2StuffingRatio;
  });
}

function getBiggestPictureByStuffingRatio(pictures: Picture[], width: number, height: number): Picture | undefined {
  return maxBy(pictures, (picture1, picture2) => {
    const picture1StuffingRatio = stuffingRatio(width, height, picture1.width, picture1.height);
    const picture2StuffingRatio = stuffingRatio(width, height, picture2.width, picture2.height);
    if (picture1StuffingRatio === picture2StuffingRatio) {
      return (picture1.width * picture1.height) > (picture2.width * picture2.height);
    }
    return picture1StuffingRatio < picture2StuffingRatio;
  });
}

export function getBestPictureUrl(pictures: Picture[], width: number, height: number): Picture | undefined {
  if (pictures.length === 0) {
    return;
  }

  /* Find picture with matching resolution to placeholder */
  const matchingResolutionPicture = pictures.find(picture => picture.width === width && picture.height === height);
  if (matchingResolutionPicture) {
    return matchingResolutionPicture;
  }

  /*
   * If no picture matches resolution, but there is a picture with matching aspect ratio.
   * When picture is bigger than the placeholder it should be downscaled. The smallest of available pictures matching this criteria should be selected.
   * When picture is smaller than the placeholder it should be upscaled. The biggest of available pictures matching this criteria should be selected.
   */
  const placeholderAspectRatio = width / height;
  const matchingAspectRatioPictures = pictures.filter(picture => (picture.width / picture.height) === placeholderAspectRatio);
  if (matchingAspectRatioPictures.length > 0) {
    const biggerPictures = matchingAspectRatioPictures.filter(picture => picture.width > width);
    const closestMatchingBiggerPicture = maxBy(biggerPictures, (candidate, best) => candidate.width < best.width);
    if (closestMatchingBiggerPicture) {
      return closestMatchingBiggerPicture;
    }

    const smallerPictures = matchingAspectRatioPictures.filter(picture => picture.width < width);
    const closestMatchingSmallerPicture = maxBy(smallerPictures, (best, candidate) => best.width > candidate.width);
    if (closestMatchingSmallerPicture) {
      return closestMatchingSmallerPicture;
    }
  }

  /*
   * If no picture matches resolution and there is no picture with matching aspect ratio.
   * When picture width or height is bigger or equal than width or height of placeholder
   * picture should be downscaled and displayed in letterbox or pillarbox.
   * Picture with smallest stuffing ratio should be used.
   * When there are more than one picture that gives the best stuffing ratio, application should use the smallest one.
   * When both of pictures dimensions are smaller than width and height of placeholder
   * picture should be upscaled and displayed in letterbox or pillarbox.
   * Picture with smallest stuffing ratio should be used.
   * When there are more than one picture that gives the best stuffing ratio, application should use the biggest one.
   */
  const notSmallerPictures = pictures.filter(picture => picture.width >= width || picture.height >= height);
  const bestFittingNotSmallerPicture = getSmallestPictureByStuffingRatio(notSmallerPictures, width, height);
  if (bestFittingNotSmallerPicture) {
    return bestFittingNotSmallerPicture;
  }

  const smallerPictures = pictures.filter(picture => picture.width < width && picture.height < height);
  return getBiggestPictureByStuffingRatio(smallerPictures, width, height);
}
