import {createStyles} from 'common-styles';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleSheet, View} from 'react-native';

import {getValue, dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Event, Channel} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import {IconType, Icon} from 'components/Icon';
import NitroxButton, {NitroxButtonTheme} from 'components/NitroxButton';
import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';

import Clock from './Clock';
import EventShortDetails from './EventShortDetails.grosso';

const lockIconSize = 400;

const styles = createStyles({
  container: {
    ...StyleSheet.absoluteFillObject,

    justifyContent: 'center',
    alignItems: 'center'
  },
  clock: {
    position: 'absolute',
    top: '5%',
    right: '4%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    height: getValue({mobile: 34, defaultValue: 61})
  },
  lockedIcon: {
    position: 'absolute',
    alignSelf: 'center',
    width: '80%',
    alignItems: 'center',
    top: '50%',
    marginTop: -lockIconSize / 2
  },
  unlockButton: {
    minWidth: dimensions.player.unlockButton.minWidth,
    height: dimensions.player.unlockButton.height,
    position: 'absolute',
    bottom: '15%'
  }
});

const colorsUpdater = new StylesUpdater((colors: BaseColors) => ({
  lockedIcon: colors.tile.poster.placeholder.icon
}));

type ParentalOverlayProps = {
  event?: Event;
  channel?: Channel;
};

const ParentalOverlay: React.FC<ParentalOverlayProps> = ({event, channel}) => {
  const colors = colorsUpdater.getStyles();
  const {shouldBeCheckedForPC, unlockMedia} = useParentalControl();
  const {t} = useTranslation();

  const pressHandler = useCallback(() => {
    shouldBeCheckedForPC(event) && unlockMedia(event)
      .then(unlocked => {
        if (unlocked) {
          mw.players.main.unblockPC();
        }
      });
  }, [event, shouldBeCheckedForPC, unlockMedia]);

  return (
    <View style={styles.container} testID='parental_overlay'>
      <View style={styles.lockedIcon}>
        <Icon type={IconType.ParentalControl} size={lockIconSize} color={colors.lockedIcon} />
      </View>
      <Clock containerStyle={styles.clock} />
      <EventShortDetails
        event={event}
        channel={channel}
      />
      <NitroxButton
        border
        style={styles.unlockButton}
        theme={NitroxButtonTheme.Primary}
        hasTvPreferredFocus
        text={t('common.enterPin')}
        onPress={pressHandler}
      />
    </View>
  );
};

export default ParentalOverlay;
