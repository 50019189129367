import {nxffConfig} from 'mw/api/NXFF';
import {BOType} from 'mw/bo-proxy/bo/BOType';
import {DRMSessionManager} from 'mw/playback/sessions/DRMSessionManager';

import {MegaDrmAdapter} from './MegaDrmAdapter';
import {SeaChangeAdr8DrmAdapter} from './SeaChangeAdr8DrmAdapter';
import {SeaChangeDRMAdapter} from './SeaChangeDRMAdapter';

export class DRMFactory {

  public static createDRMSessionManager(): DRMSessionManager | null {
    const drmProxyType = nxffConfig.getConfig().DRM.DRMProxyType;
    switch (drmProxyType) {
      case 'Mega':
        return new MegaDrmAdapter();

      case 'SeaChange':
        const boType = nxffConfig.getConfig().Environment.BOType;
        return boType === BOType.ADR8 ? new SeaChangeAdr8DrmAdapter() : new SeaChangeDRMAdapter();
    }
    return null;
  }
}
