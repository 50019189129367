import {EntitlementState} from 'mw/api/Metadata';

import {TraxisEntitlementState} from './constants';

interface BackOfficeProperty {
  name: string;
  Value: string;
}

export function namedPropertiesFromJSON(json: any): any {
  const array = json.NamedProperties && json.NamedProperties.Property || [];
  return array.reduce((result: any, property: BackOfficeProperty) => {
    result[property.name] = property.Value;
    return result;
  }, {});
}

interface Alias {
  type: string;
  Value: string;
}

export function getAlias(json: any, aliasName: string): string | undefined {
  if (json.Aliases?.Alias?.length) {
    return json.Aliases.Alias.find((alias: Alias) => alias?.type === aliasName)?.Value;
  }
}

export function checkEntitlement(entitlementState: any): EntitlementState {
  return [TraxisEntitlementState.Entitled, TraxisEntitlementState.PurchaseStarted].includes(entitlementState)
    ? EntitlementState.Entitled
    : EntitlementState.NotEntitled;
}

interface CustomProperty {
  href: string;
  Value?: string;
}

export function getCustomProperty(customProperties: CustomProperty[], propertyName: string): string | undefined {
  return customProperties.find((obj) => obj.href === propertyName)?.Value;
}

export enum RemoteAssetTypes {
  Media = 'media'
}

export enum CustomParameterType {
  Type = 'Type',
  ContentType = 'ContentType',
  Network = 'Network'
}

interface CustomParameter {
  type: RemoteAssetTypes;
  Value: string;
}

export interface Content {
  PlaybackUrl: string;
  CustomParameters?: {CustomParameter: CustomParameter[]};
}

export interface RemoteAsset {
  id: string;
  managedBy: string;
  Content: Content[];
  ordinal?: number;
  allowPause?: string,
  allowSkipForward?: string,
  allowSkipBackward?: string,
  allowFastForward?: string,
  allowRewind?: string
}

export function selectCustomParameter(customParameter: CustomParameter, type: string, typeValue: string): boolean {
  return customParameter.type === type && typeValue === customParameter.Value;
}

export function parseString(stringValue: any): boolean {
  return stringValue && stringValue === 'true';
}
