import React, {useMemo} from 'react';
import {ViewStyle, View, Animated, StyleProp} from 'react-native';

import {commonAnimationTransforms} from 'common/constants';
import {AnimatedStyle, Size} from 'common/HelperTypes';

const containerStyle: ViewStyle = {
  flex: 1,
  overflow: 'hidden'
};

type AnimatedScrollViewProps = {
  offsetX?: Animated.Value;
  offsetY?: Animated.Value;
  size: Size;
  style?: StyleProp<ViewStyle>;
  onWheel?: (event: React.WheelEvent<Element>) => void
};

/**
 * A component that allows to control its translation point
 * using a pair of Animated.Values.
 */
const AnimatedScrollView: React.FC<AnimatedScrollViewProps> = ({
  offsetX,
  offsetY,
  size,
  style,
  children,
  onWheel
}) => {
  const translation = useMemo(() => ({
    x: offsetX
      ? Animated.multiply(offsetX, new Animated.Value(-1))
      : new Animated.Value(0),
    y: offsetY
      ? Animated.multiply(offsetY, new Animated.Value(-1))
      : new Animated.Value(0)
  }), [offsetX, offsetY]);

  const animatedStyle: AnimatedStyle<ViewStyle> = useMemo(() => ({
    position: 'absolute',
    top: 0,
    left: 0,
    ...size,
    transform: [
      {translateX: translation.x},
      {translateY: translation.y},
      ...commonAnimationTransforms
    ]
  }), [translation, size]);

  return (
    <View
      style={[containerStyle, style]}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onWheel={onWheel}
    >
      <Animated.View style={animatedStyle}>
        {children}
      </Animated.View>
    </View>
  );
};

export default AnimatedScrollView;
