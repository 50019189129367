import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {Callable} from 'common/Async';
import {dimensions, isBigScreen, isPhone, isTablet, getValue} from 'common/constants';
import {doNothing} from 'common/HelperFunctions';

import {PVRAvailableGuardTimes} from 'mw/api/Metadata';

import {IconType} from 'components/Icon';
import {ButtonIconProps, IconPosition} from 'components/NitroxButton';
import {PopupAction} from 'components/Popup';
import GuardTimeSelector from 'components/pvr/GuardTimeSelector';
import SelectionPopup, {SelectionPopupSection, SelectionPopupOption} from 'components/SelectionPopup';
import WizardButton from 'components/WizardButton';
import WizardCheckbox from 'components/WizardCheckbox';
import {useScreenInfo, useToggle} from 'hooks/Hooks';
import i18n from 'locales/i18n';

type SeriesRecordingPopupProps = {
  visible: boolean;
  loading?: boolean;
  customTitle?: string;
  recordOptions?: SelectionPopupOption[];
  startingSeasonOptions?: SelectionPopupOption[];
  disableStartingSeasonOptions?: boolean;
  startingSeason?: number;
  showBoundToChannel?: boolean;
  disableBoundToChannel?: boolean;
  boundToChannel?: boolean;
  onBoundToChannelPress?: () => void;
  onPositive: () => void;
  onCancel: () => void;
  onClose: () => void;

  availableGuardTimes?: PVRAvailableGuardTimes;
  initialGuardTimeStart?: number;
  initialGuardTimeEnd?: number;
  onGuardTimeStartChanged?: (value: number) => void,
  onGuardTimeEndChanged?: (value: number) => void,
  disableGuardTimes?: boolean
}

const optionsColumnCount = isPhone ? 1 : undefined;

const styles = createStyles({
  title: {
    marginBottom: dimensions.margins.xxLarge
  },
  menuStyle: {
    justifyContent: 'center'
  },
  menuButtons: {
    margin: dimensions.margins.small
  },
  container: {
    alignItems: getValue({mobile: 'flex-start', tablet: 'center', defaultValue: 'center'}),
    overflow: 'hidden',
    paddingTop: getValue({mobile: dimensions.margins.medium, tablet: 0, defaultValue: 0}),
    paddingBottom: getValue({tablet: dimensions.margins.large, defaultValue: 0})
  },
  startingSeasonButton: {
    marginTop: isBigScreen ? dimensions.margins.small : 0
  },
  boundToChannelCheckbox: {
    marginTop: isBigScreen ? dimensions.margins.small : 0
  },
  additionalOptionsContainer: {
    marginTop: dimensions.margins.xsmall
  },
  startStyle: {
    marginBottom: isPhone ? dimensions.margins.small : dimensions.margins.large
  }
});

const startingSeasonIcon: ButtonIconProps = {
  type: IconType.ArrowDownBold,
  size: dimensions.icon.micro,
  position: IconPosition.RIGHT
};

export const getStartingSeasonLabel = (t: i18n.TFunction, seasonNumber: number): string => (
  seasonNumber === 0
    ? t('recordings.recordingConfigPopup.allSeasons')
    : t('recordings.recordingConfigPopup.seasonNumberFull', {seasonNumber})
);

const RecordingConfigPopup: React.FunctionComponent<SeriesRecordingPopupProps> = ({
  visible,
  loading,
  recordOptions = [],
  startingSeasonOptions = [],
  disableStartingSeasonOptions,
  startingSeason = 0,
  showBoundToChannel,
  disableBoundToChannel,
  boundToChannel = false,
  onBoundToChannelPress = doNothing,
  onPositive: propsOnPositive,
  onCancel: propsOnCancel,
  onClose: propsOnClose,
  availableGuardTimes,
  onGuardTimeStartChanged = () => {},
  onGuardTimeEndChanged = () => {},
  initialGuardTimeEnd,
  initialGuardTimeStart,
  disableGuardTimes,
  customTitle
}) => {
  const {orientation} = useScreenInfo();
  const {t} = useTranslation();
  const [startingSeasonOptionsVisible, {on: showStartingSeasonOptions, off: hideStartingSeasonOptions}] = useToggle(false);
  const guardTimesAvailable = useMemo(() => !!availableGuardTimes?.start.length || !!availableGuardTimes?.end.length, [availableGuardTimes]);

  const popupActions = useMemo(() => (
    startingSeasonOptionsVisible
      ? [PopupAction.POSITIVE]
      : isBigScreen ? [PopupAction.NEGATIVE, PopupAction.POSITIVE] : [PopupAction.POSITIVE, PopupAction.NEGATIVE]
  ), [startingSeasonOptionsVisible]);

  const sections: SelectionPopupSection[] = useMemo(() => ([{
    options: startingSeasonOptionsVisible ? startingSeasonOptions : recordOptions,
    center: true,
    containerStyle: {
      alignContent: 'center',
      marginBottom: isPhone && orientation.isPortrait ? 0 : dimensions.margins.xLarge,
      flexDirection: isTablet || isBigScreen ? 'row' : 'column'
    }
  }]), [recordOptions, startingSeasonOptions, orientation.isPortrait, startingSeasonOptionsVisible]);

  const {onClose, onPositive, onCancel} = useMemo(() => {
    const ensureToHideStartingSeasonOptions = (f: Callable) => (() => {
      if (startingSeasonOptionsVisible) {
        hideStartingSeasonOptions();
      } else {
        f();
      }
    });
    return {
      onClose: ensureToHideStartingSeasonOptions(propsOnClose),
      onPositive: ensureToHideStartingSeasonOptions(propsOnPositive),
      onCancel: ensureToHideStartingSeasonOptions(propsOnCancel)
    };
  }, [startingSeasonOptionsVisible, hideStartingSeasonOptions, propsOnClose, propsOnPositive, propsOnCancel]);

  const popupProps = useMemo(() => ({
    actions: popupActions,
    actionsSeparator: true,
    onPositive,
    onNegative: onCancel,
    onModalClose: propsOnClose,
    menuStyle: styles.menuStyle,
    menuButtonStyle: isBigScreen ? styles.menuButtons : {width: isTablet ? 160 : dimensions.popup.button.width}
  }), [popupActions, onPositive, onCancel, propsOnClose]);

  const title = startingSeasonOptionsVisible
    ? t('recordings.recordingConfigPopup.startingSeason')
    : t('recordings.recordingConfigPopup.titles.default');

  return (
    <SelectionPopup
      visible={visible}
      title={customTitle ?? title}
      sections={sections}
      onClose={onClose}
      popupProps={popupProps}
      centerFirstRow={true}
      loading={loading}
      columnCount={optionsColumnCount}
      shouldWaitForButtons={recordOptions.length > 0}
      scrollableWhenMaxHeightExceeded
      focusedKey={startingSeasonOptionsVisible && `${startingSeason}` || undefined}
    >
      <View>
        {guardTimesAvailable && !startingSeasonOptionsVisible &&
          (
            <View style={styles.container}>
              <GuardTimeSelector
                onGuardTimeStartChange={onGuardTimeStartChanged}
                availableStartValues={availableGuardTimes?.start || []}
                initialStartValue={initialGuardTimeStart}
                startStyle={styles.startStyle}
                onGuardTimeEndChange={onGuardTimeEndChanged}
                availableEndValues={availableGuardTimes?.end || []}
                initialEndValue={initialGuardTimeEnd}
                disabled={disableGuardTimes}
              />
            </View>
          )
        }
        {!startingSeasonOptionsVisible && (
          <View style={styles.additionalOptionsContainer}>
            {startingSeasonOptions.length > 0 && (
              <WizardButton
                description={t('recordings.recordingConfigPopup.startingSeason')}
                label={getStartingSeasonLabel(t, startingSeason)}
                icon={startingSeasonIcon}
                disabled={disableStartingSeasonOptions}
                onPress={showStartingSeasonOptions}
                style={styles.startingSeasonButton}
              />
            )}
            {showBoundToChannel && (
              <WizardCheckbox
                label={t('recordings.recordingConfigPopup.boundToChannel')}
                size={dimensions.popup.checkbox.size}
                selected={boundToChannel}
                disabled={disableBoundToChannel}
                onPress={onBoundToChannelPress}
                style={styles.boundToChannelCheckbox}
              />
            )}
          </View>
        )}
      </View>
    </SelectionPopup>
  );
};

export default React.memo(RecordingConfigPopup);
