import {createStyles} from 'common-styles';
import React from 'react';
import {View} from 'react-native';

import {dimensions} from 'common/constants';
import {humanCaseToSnakeCase} from 'common/HelperFunctions';

import SettingsButton from './SettingsButton';
import {SettingsOptionProps} from './SettingsClickableOption';
import SettingsLabel from './SettingsLabel';

const styles = createStyles({
  containerStyle: {
    alignSelf: 'stretch',
    paddingHorizontal: dimensions.margins.xxLarge,
    marginBottom: dimensions.margins.xxLarge,
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
});

const SettingsClickableOptionContent: React.FC<SettingsOptionProps> = props => {
  const {title, type, buttonLabel, onPress} = props;
  const testID = type ? `button_${humanCaseToSnakeCase(type)}` : undefined;

  return (
    <View style={styles.containerStyle}>
      <SettingsLabel label={title} />
      <SettingsButton
        text={buttonLabel}
        onPress={onPress}
        testID={testID}
      />
    </View>
  );
};

export default SettingsClickableOptionContent;
