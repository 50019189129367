import React, {useMemo, useCallback, useRef, useContext} from 'react';

import {isATV} from 'common/constants';
import {doNothing} from 'common/HelperFunctions';

import {useLazyEffect, useIsScreenFocused} from 'hooks/Hooks';
import {useKeysListener} from 'hooks/rcuHooks';

import {SupportedKeys} from './KeyEventManager';

type HotKeysContextType = {
  hotKeys: SupportedKeys[];
  register: (callback: (key: number) => void) => void;
  unregister: (callback: (key: number) => void) => void;
}

const HotKeysContext = React.createContext<HotKeysContextType>({
  hotKeys: [],
  register: doNothing,
  unregister: doNothing
});

type Props = {
  hotKeys: SupportedKeys[];
}

/**
 * Used to determine supported key events in a given context.
 * Additionally, it allows to register callbacks for supported key.
 * @param hotKeys determines supported keys in a given context
 */
export const HotKeysContextProvider: React.FunctionComponent<Props> = (props) => {
  const {hotKeys, children} = props;

  const callbacks = useRef<Set<(key: number) => void>>(new Set());
  const onKeyEvent = useCallback((key: number) => {
    if (hotKeys.includes(key)) {
      callbacks.current.forEach(callback => {
        callback(key);
      });
    }
  }, [hotKeys]);

  useKeysListener(hotKeys, onKeyEvent);

  const contextValue = useMemo(() => ({
    hotKeys,
    register: (callback: (key: number) => void) => {
      callbacks.current.add(callback);
    },
    unregister: (callback: (key: number) => void) => {
      callbacks.current.delete(callback);
    }
  }), [hotKeys]);

  return isATV ? (
    <HotKeysContext.Provider value={contextValue}>
      {children}
    </HotKeysContext.Provider>
  ) : (
    <>{children}</>
  );
};

/**
 * Conditionally listens to key event.
 * @param hotKey key event that we are listening to
 * @param onHotKey hotKey event callback
 * @param active determines whether listening should be turned on or not
 */
export const useHotKey = (hotKey: SupportedKeys, onHotKey: () => void, active: boolean): void => {
  const {inNavigationContext, isScreenFocused} = useIsScreenFocused();
  const isScreenBlurred = inNavigationContext && !isScreenFocused;
  const useHotKey = active ?? !isScreenBlurred;

  const {hotKeys, register, unregister} = useContext(HotKeysContext);

  const onKey = useCallback((key: number) => {
    if (key === hotKey) {
      onHotKey();
    }
  }, [hotKey, onHotKey]);

  useLazyEffect(() => {
    if (!useHotKey || !hotKeys.includes(hotKey)) {
      return;
    }
    register(onKey);
    return () => unregister(onKey);
  }, [useHotKey, hotKeys], [onKey]);
};
