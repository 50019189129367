import {Channel, ChannelList} from 'mw/api/Metadata';
import {ChannelListObject} from 'mw/metadata/ChannelListObject';
import {mw} from 'mw/MW';

export class ChannelListMapper {
  public static fromJSON(json: any): ChannelList {
    const channelListId = json.id;
    const channelArray = json.Channels?.Channel;
    const channels: Channel[] = [];
    if (typeof channelArray !== 'undefined') {
      json.Channels.Channel.forEach((channelJson: any) => {
        const channel = mw.catalog.getChannelById(channelJson.id);
        if (channel) {
          channels.push(channel);
        }
      });
    }

    return new ChannelListObject(channelListId, json.Name || '', channels);
  }

  public static toChannelLists(jsonArray: any): ChannelList[] {
    return jsonArray.map((obj: any) => ChannelListMapper.fromJSON(obj));
  }
}
