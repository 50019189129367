import {createStyles} from 'common-styles';
import React, {useState, useCallback} from 'react';

import {CalendarProps} from 'components/Calendar';
import {Modal} from 'components/Modal';
import NativeCalendar from 'components/NativeCalendar';
import {useChangeEffect} from 'hooks/Hooks';

export const calendarWidth = 315;
export const calendarHeight = 285;

const staticStyles = createStyles({
  modal: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    width: calendarWidth,
    height: calendarHeight
  }
});

export type CalendarModalPiccoloProps = {
  visible: boolean;
  /** Date changed but modal is still opened */
  onDateChange?: (date: Date) => void;
  /** Modal was closed with the given date */
  onClose?: (date: Date) => void;
} & CalendarProps;

const CalendarModalPiccolo: React.FC<CalendarModalPiccoloProps> = ({
  visible, date, maxDate, minDate, onDateChange: propsOnDateChange, onClose: propsOnClose
}) => {
  const [currentDate, setCurrentDate] = useState(date ?? new Date());

  useChangeEffect(() => {
    if (date) {
      setCurrentDate(date);
    }
  }, [date]);

  const onDateChange = useCallback((newDate: Date) => {
    setCurrentDate(newDate);
    propsOnDateChange?.(newDate);
  }, [propsOnDateChange]);

  const onDateSubmitted = useCallback((newDate: Date) => {
    propsOnClose?.(newDate);
  }, [propsOnClose]);

  const onClose = useCallback(() => {
    propsOnClose?.(currentDate);
  }, [propsOnClose, currentDate]);

  return (
    <Modal
      onClose={onClose}
      visible={visible}
      style={staticStyles.modal}
      contentStyle={staticStyles.content}
    >
      <NativeCalendar
        date={currentDate}
        maxDate={maxDate}
        minDate={minDate}
        onDateChange={onDateChange}
        onDateSubmitted={onDateSubmitted}
        onClose={onClose}
      />
    </Modal>
  );
};

export default React.memo(CalendarModalPiccolo);
