import {delay} from 'common/Async';
import {Log, stringify} from 'common/Log';

import {Error, ErrorType} from 'mw/api/Error';
import {nxffConfig} from 'mw/api/NXFF';
import {netInfo} from 'mw/platform/net-info/NetInfo';

const TAG = 'HttpUtils';

export enum HttpMethods {
  DELETE = 'DELETE',
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH'
}

export enum HttpStatus {
  Ok = 200,
  MultipleChoices = 300,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  RequestTimeout = 408,
  UnprocessableEntity = 422,
  UnavailableForLegalReasons = 451,
  InternalServerError = 500
}

export const objectToRequestParams = (params: any): string => {
  const data: string[] = [];
  for (const p in params) {
    data.push(p + '=' + encodeURIComponent(params[p]));
  }
  return data.join('&');
};

export const httpFetch = (input: RequestInfo, init?: RequestInit): Promise<Response> => {
  return Promise.race<Response>([
    delay(nxffConfig.getConfig().BOIntegration.HTTPRequestTimeout).then(() => Promise.reject(new Error(ErrorType.HttpTimeout))),
    fetch(input, init).catch(async (reason) => {
      Log.error(TAG, 'httpFetch', reason);
      const netInfoState = await netInfo.fetch();
      return Promise.reject(new Error(!netInfoState.isConnected ? ErrorType.NetworkNoConnection : ErrorType.NetworkRequestFailed, reason && stringify(reason)));
    })
  ]);
};

export function isUnavailableErrorCode(errorCode: number): boolean {
  switch (errorCode) {
    case HttpStatus.RequestTimeout:
    case HttpStatus.UnavailableForLegalReasons:
      return true;
  }
  if (errorCode >= HttpStatus.InternalServerError) {
    return true;
  }
  return false;
}
