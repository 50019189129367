import React, {useMemo, useCallback, useState} from 'react';
import {View} from 'react-native';

import {createStyles} from 'common-styles/index';
import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {IconType} from './Icon';
import {ModalSelect, ModalSelectOption} from './ModalSelect';
import NitroxButton, {NitroxButtonTheme} from './NitroxButton';
import NitroxText from './NitroxText';
import {Option, OptionPickerProps, optionPickerStylesUpdater} from './OptionPicker.shared';

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  buttonIcon: {
    color: colors.popup.text
  }
}));

export const OptionPickerPiccolo: React.FunctionComponent<OptionPickerProps> = props => {
  const {label, options, defaultOptionKey, onOptionSelected, style} = props;
  const [modalSelectVisible, setModalSelectVisible] = useState<boolean>(false);

  const dynamicStyles = dynamicStylesUpdater.getStyles();
  const modalOptions = useMemo((): ModalSelectOption<string>[] => options.map(option => ({
    value: option.key,
    label: option.label,
    key: option.key,
    buttonIcon: option.iconProps && {...option.iconProps, ...dynamicStyles.buttonIcon}
  })), [dynamicStyles.buttonIcon, options]);

  const defaultOption = useMemo((): Option | undefined => {
    return options.find(option => option.key === defaultOptionKey);
  }, [options, defaultOptionKey]);

  const onClose = useCallback(() => {
    setModalSelectVisible(false);
  }, []);

  const onChange = useCallback((value: string) => {
    onOptionSelected(value);
    onClose();
  }, [onClose, onOptionSelected]);

  const onPress = useCallback(() => {
    setModalSelectVisible(true);
  }, []);

  const dynamicPickerStyles = optionPickerStylesUpdater.getStyles();
  return (
    <View style={[dynamicPickerStyles.container, style]}>
      <NitroxText style={dynamicPickerStyles.label}>{label}</NitroxText>
      <NitroxButton
        text={defaultOption && defaultOption.label}
        icon={defaultOption && defaultOption.iconProps}
        theme={NitroxButtonTheme.Secondary}
        onPress={onPress}
      />
      <ModalSelect
        options={modalOptions}
        title={label}
        defaultValue={defaultOptionKey}
        visible={modalSelectVisible}
        onChange={onChange}
        onClose={onClose}
        selectedOptionIcon={IconType.Check}
      />
    </View>
  );
};
