import {createStyles} from 'common-styles';
import React from 'react';
import {StyleSheet, View} from 'react-native';

import {constColors} from 'common-styles/variables/base-colors';

import NitroxInteractive from 'components/NitroxInteractive';

import {channelsListItemDimensions, channelsListSeparatorDimensions} from './ChannelsListDimensions';

const styles = createStyles({
  horizontalContainer: {
    width: channelsListSeparatorDimensions.horizontalContainerSize,
    height: channelsListItemDimensions.size,
    alignItems: 'center',
    justifyContent: 'center'
  },
  verticalContainer: {
    width: channelsListItemDimensions.size,
    height: channelsListSeparatorDimensions.verticalContainerSize,
    alignItems: 'center',
    justifyContent: 'center'
  },
  separatorContent: {
    width: 100,
    height: 100
  }
});

type Props = {
  horizontal: boolean;
};

const ChannelsListItemSeparator: React.FC<Props> = props => {
  return (
    <View style={props.horizontal ? styles.horizontalContainer : styles.verticalContainer}>
      <NitroxInteractive
        style={StyleSheet.absoluteFillObject}
        activeOpacity={1}
        underlayColor={constColors.transparent}
      >
        <View style={styles.separatorContent} />
      </NitroxInteractive>
    </View>
  );
};

export default React.memo(ChannelsListItemSeparator);
