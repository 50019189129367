import i18next from 'i18next';
import moment from 'moment';
import {Platform} from 'react-native';

import BuildConfig from 'common/BuildConfig';
import {DateUtils} from 'common/DateUtils';
import {getMediaSubtypes, formatDuration, isSeriesEpisode} from 'common/HelperFunctions';

import {Media, MediaType, DateTimeDistance, Event, Title, isSingleRecording, isSeries} from 'mw/api/Metadata';
import {Track} from 'mw/api/PlayerEvent';
import {mw} from 'mw/MW';

import {CurrentPlatform, platformOverride, PlatformOverrides} from './constants';
import {maxBy} from './helpers/ArrayHelperFunctions';

export const withinBoundaries = (min: number, max: number, value: number) => isNaN(value) ? min : Math.max(min, Math.min(value, max));

// Media utils:

export const formatStartDateForTile = (media: Media, showToday = false, format?: string): string | undefined => {
  const {event} = getMediaSubtypes(media);
  if (!event) return undefined;
  const formatString = format || (event.isSameYear ? 'D MMMM' : 'D MMMM YYYY');
  const date = moment(event.start);
  switch (event.eventTimeDistance) {
    case DateTimeDistance.Yesterday:
      return i18next.t('common.yesterday') as string;
    case DateTimeDistance.Today:
      return showToday
        ? i18next.t('common.today') as string
        : undefined;
    case DateTimeDistance.Tomorrow:
      return i18next.t('common.tomorrow') as string;
    case DateTimeDistance.UpcomingWeek:
      return date.format('dddd');
    case DateTimeDistance.MoreThanWeek:
    case DateTimeDistance.LongDistancePast:
    default:
      return date.format(formatString);
  }
};

export const getFormattedDuration = (media: Media): string | undefined => {
  switch (media.getType()) {
    case MediaType.Recording:
      if (isSingleRecording(media)) return formatDuration(media.duration);
      return;
    case MediaType.Event:
      const event = media as Event;
      return formatDuration((event.end.getTime() - event.start.getTime()) / DateUtils.msInSec);
    case MediaType.Title:
      const title = media as Title;
      return formatDuration(title.metadata.duration);
  }
};

export type GetEpisodeAndSeasonNumberOptions = {
  padLength?: number;
  separator?: string;
  showFullText?: boolean;
  showSeason?: boolean;
}

export const getEpisodeAndSeasonNumber = (media: Media, options?: GetEpisodeAndSeasonNumberOptions): string | undefined => {
  const {title, episode} = getMediaSubtypes(media);
  if (title && !isSeriesEpisode(title)) {
    return undefined;
  }
  const {padLength = 0, separator = ', ', showFullText = true, showSeason = true} = options || {};
  const values = [];
  if (episode) {
    if (episode.seasonNumber && showSeason) {
      values.push(i18next.t(showFullText ? 'common.seasonNumberFull' : 'common.seasonNumber', {seasonNumber: episode.seasonNumber.toString().padStart(padLength, '0')}));
    }
    if (episode.number) {
      values.push(i18next.t(showFullText ? 'common.episodeNumberFull' : 'common.episodeNumber', {episodeNumber: episode.number.toString().padStart(padLength, '0')}));
    }
  }
  return values.length ? values.join(separator) : undefined;
};

export const getEpisodeTitle = (media: Media): string | undefined => {
  const {episode} = getMediaSubtypes(media);
  return episode?.title;
};

export const getSeriesName = (media: Media): string | undefined => {
  if (isSeries(media)) return media.name;
  const {episode} = getMediaSubtypes(media);
  return episode?.seriesName;
};

export const getTime = (date: Date, format: string) => moment(date).format(format);

export const getStartHour = (media: Media, format: string): string | undefined => {
  const {event} = getMediaSubtypes(media);
  if (event?.start) return getTime(event.start, format);
};

/**
 * Returns media duration in the following format: H:mm - H:mm
 */
export const getTimeWindow = (media: Media, format: string): string => {
  const {event} = getMediaSubtypes(media);
  return (event?.start && event.end)
    ? `${getTime(event.start, format)} - ${getTime(event.end, format)}`
    : '';
};

export function makeArray(param: any): any[] {
  if (!param) return [];
  if (!(param instanceof Array)) return [param];
  return param;
}

export function filterUndefinedValues(object: {[key: string]: any}) {
  const result: {[key: string]: any} = {};
  return Object.entries(object).reduce((result, [key, value]) => {
    if (typeof value !== 'undefined') {
      result[key] = value;
    }
    return result;
  }, result);
}

export const getUniqueEvents = (data: Event[]) => {
  const eventIds: Set<string> = new Set();
  const uniqueEvents: Event[] = [];

  for (const event of data) {
    if (!eventIds.has(event.id)) {
      eventIds.add(event.id);
      uniqueEvents.push(event);
    }
  }

  return uniqueEvents;
};

export function getCurrentPlatform(): CurrentPlatform | undefined {
  switch (Platform.OS) {
    case 'android':
      if (Platform.isTV) {
        if (mw.configuration.isLauncher) {
          return CurrentPlatform.AndroidLauncher;
        }
        return CurrentPlatform.AndroidTv;
      }
      return CurrentPlatform.Android;
    case 'ios':
      return CurrentPlatform.iOS;
    case 'web':
      if (platformOverride === PlatformOverrides.Tizen) {
        return CurrentPlatform.Tizen;
      }
      if (platformOverride === PlatformOverrides.WebOS) {
        return CurrentPlatform.WebOS;
      }
      return CurrentPlatform.Web;
  }
}

export const getPlatformFeatures = () => {
  const currentPlatform = getCurrentPlatform();
  return currentPlatform ? (BuildConfig.platforms?.[currentPlatform] as any)?.features : {};
};

export const formatCurrency = (value: number, currency: string, locale: string) => (
  new Intl.NumberFormat(locale, {style: 'currency', currency})?.format(value) || `${currency} ${value}`
);

export function getDisplayName<P>(Component: React.ComponentType<P>) {
  return Component.displayName || Component.name || 'Component';
}

export function isAsyncIterator<T>(m: T | AsyncIterableIterator<T[]>): m is AsyncIterableIterator<T[]> {
  return Symbol.asyncIterator in m;
}

export function isCcTrack(track: Track): boolean {
  //Use 'startsWith' method instead of strict equality to recognize closed captions track type.
  //Track type may contain additional values to distinguish similar (from metadata perspective) tracks - ('cc[ n]'), e.g. 'cc 1'.
  return track.type.toLowerCase().startsWith('cc');
}

export function maxByAbs(first: number, ...rest: number[]): number {
  const numbers = [first, ...rest];
  return maxBy(numbers, (lhs, rhs) => Math.abs(lhs) > Math.abs(rhs)) as number;
}
