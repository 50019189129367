import {Animated} from 'react-native';
import {NavigationState, NavigationAction, NavigationParams, NavigationScreenProp, NavigationRoute} from 'react-navigation';

import {AppRoutes} from 'common/constants';

import {MediaType} from 'mw/api/Metadata';

export type ScreenSizeBasedOrientation = {isLandscape: true; isPortrait: false} | {isLandscape: false; isPortrait: true};

export type WebSubtype = 'phone' | 'tablet' | 'bigScreen';
export type ScreenInfo = {
  size: Size;
  /**
   * Important for web platforms, returns real window size, regardless of scale.
   */
  trueSize: Size;
  orientation: ScreenSizeBasedOrientation;
  webSubtype: WebSubtype;
};

export type MediaOpenableProps = {
  mediaId?: string;
  mediaType?: MediaType;
};

export enum NavigationFocusState {
  IsFocusing,
  IsFocused,
  IsBlurring,
  IsBlurred
}

export interface Point {
  x: number;
  y: number;
}

export interface Size {
  width: number;
  height: number;
}

export interface Rect extends Point, Size {
}

export interface Segment {
  start: number;
  end: number;
}

export interface Hashmap<Item> {
  [key: string]: Item;
}

// see typescript -> lib -> lib.dom -> FocusOptions
export type FocusOptions = {
  preventScroll?: boolean;
}

export enum NavigationEvents {
  onNavigationStateChange = 'onNavigationStateChange'
}

export type NavigationStateChangeEvent = {
  prevState: NavigationState;
  nextState: NavigationState;
  action: NavigationAction;
};

export type Navigation = NavigationScreenProp<NavigationRoute<NavigationParams>, NavigationParams>;
export type NavigationDestination = AppRoutes | 'PreviousRoute' | 'CurrentRoute';
export type NavigationDestinationMapper<T> = (selector?: T) => NavigationDestination;

export interface StringKeyed {[key: string]: any}

// extension of https://stackoverflow.com/a/55279452
export type MaybeAnimated<T> = T | Animated.Value | Animated.AnimatedInterpolation;
type AnimatedScalar = string | number | undefined;

/**
 * note: don't trust this type blindly, this is just fine replacement for `any` typing of Animated module
 * @param T `ViewStyle` | `ImageStyle` | `TextStyle`
 */
export type AnimatedStyle<T> = {
  [Key in keyof T]: T[Key] extends AnimatedScalar
    ? MaybeAnimated<T[Key]>
    : T[Key] extends (infer U)[]
      ? (AnimatedStyle<U>)[]
      : AnimatedStyle<T[Key]>
};

/**
 * Type containing properties required and used for testing.
 */
export type TestProps = {
  testID?: string;
};

export type ChangeEvent<T> = {
  from: T;
  to: T;
};

export type EventHandlerType<Payload = any> = (payload: Payload) => any;
type EventBind<Name, Payload = any> = (event: Name, cb: EventHandlerType<Payload>) => void;
export type Emitter<Name, Payload = any> = {
  on: EventBind<Name, Payload>;
  off: EventBind<Name, Payload>;
} | {
  addEventListener: EventBind<Name, Payload>;
  removeEventListener: EventBind<Name, Payload>;
}

/**
 * Generic type that represents an interpolation sample
 */
export type InterpolationSample<PointType, ValueType> = {
  point: PointType;
  value: ValueType;
};
