import {DrmParameters, DrmProperties} from 'mw/bo-proxy/SSOInterface';
import {DrmType} from 'mw/Constants';
import {DrmInterceptor, DrmRequest, DrmResponse} from 'mw/playback/web-native-player/nxpal/DrmInterceptor';

export abstract class DefaultDrmInterceptor implements DrmInterceptor {
  protected drmHeaders?: DrmParameters['drmHeaders'];
  protected drmProperties?: DrmProperties;
  protected drmType: DrmType;

  public constructor(drmType: DrmType) {
    this.drmType = drmType;
  }

  public abstract onDrmRequest(): DrmRequest;
  public abstract onDrmResponse(response: DrmResponse): void;

  public getDrmType(): string {
    return this.drmType;
  }

  public setProperties(drmProperties: DrmProperties): void {
    this.drmProperties = drmProperties;
  }

  public setHeaders(drmHeaders: DrmParameters['drmHeaders']): void {
    this.drmHeaders = drmHeaders;
  }
}
