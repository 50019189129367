import {appendOptionsToUrl, isTruthy} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {Error, ErrorType, ValidationError} from 'mw/api/Error';
import {Utils} from 'mw/bo-proxy/bo/Utils';
import {shortId, httpRequestLogPrefix} from 'mw/common/utils';
import {mw} from 'mw/MW';
import {deviceInfo} from 'mw/platform/device-info/DeviceInfo';
import {httpFetch, HttpMethods, HttpStatus} from 'mw/utils/HttpUtils';
import {RetryManager} from 'mw/utils/RetryManager';

const TAG = 'ADR8Requester';

const maxRetries = 3;

export type AdditionalHeaders = {[key: string]: string};

export interface GetRequestInterface {
  api: string;
  query: string;
  queryParams?: {[key: string]: string | number | null | undefined};
  otherParams?: {
    secret?: boolean;
    useSSOToken?: boolean;
    additionalHeaders?: AdditionalHeaders;
  };
}

export interface SendRequestInterface {
  additionalHeaders?: AdditionalHeaders;
  api: string;
  body?: BodyInit;
  method: HttpMethods;
  secret?: boolean;
  query?: string;
  queryParams?: {[key: string]: string | number | null | undefined};
  token?: string;
  useSSOToken?: boolean;
}

export interface PostRequestInterface {
  api: string;
  query?: string;
  body: BodyInit;
  queryParams?: {[key: string]: string | number};
  otherParams?: {
    secret?: boolean;
    useSSOToken?: boolean;
  };
}

export interface PatchRequestInterface {
  api: string;
  query: string;
  body: BodyInit;
  queryParams?: {[key: string]: string | number};
  otherParams?: {
    secret?: boolean;
    useSSOToken?: boolean;
  };
}

export interface PutRequestInterface {
  api: string;
  query: string;
  body: BodyInit;
  queryParams?: {[key: string]: string | number};
  token?: string;
  otherParams?: {
    secret?: boolean;
    useSSOToken?: boolean;
  };
}

export interface DeleteRequestInterface {
  api: string;
  query: string;
  token?: string;
  queryParams?: {[key: string]: string | number};
  otherParams?: {
    secret?: boolean;
    useSSOToken?: boolean;
  };
}

export interface BodyRequestInterface {
  method: HttpMethods.POST | HttpMethods.PUT | HttpMethods.PATCH;
  api: string;
  query?: string;
  body: BodyInit;
  queryParams?: {[key: string]: string | number};
  token?: string;
  otherParams?: {
    secret?: boolean;
    useSSOToken?: boolean;
    additionalHeaders?: AdditionalHeaders;
  },
}

export interface ADR8RequesterParams {
  url?: string;
  tenant: string;
  useSSOToken?: boolean;
  maxRetries?: number;
  retryPeriod?: number;
  urlOnly?: boolean;
  onInvalidateAuthorization?: (authorizationToken: string | null) => void;
  onServiceUnauthorized?: () => void;
  /**
   * Declare endpoints for which XSESSION header can be omitted.
   */
  publicEndpoints?: string[];
  onServiceUnavailable?: (error: Error) => void
}

interface ResponseJson {
  [prop: string]: any;
}

interface ErrorJson {
  field: any,
  rejectedValue: any,
  defaultMessage?: string;
  errorCode?: string;
}

interface ErrorResponseJson {
  error?: string,
  message?: string,
  origin?: string,
  errors?: ErrorJson[];
}

export class ADR8Requester {
  private url?: string;
  private tenant: string;
  private useSSOToken: boolean;
  private publicEndpoints: string[];
  private urlOnly: boolean;
  private onInvalidateAuthorization?: (authorizationToken: string | null) => void;
  private onServiceUnauthorized?: () => void;
  private onServiceUnavailable?: (error: Error) => void
  private maxRetries: number;
  private retryPeriod?: number;

  public constructor(params: ADR8RequesterParams) {
    this.url = params.url;
    this.tenant = params.tenant;
    this.useSSOToken = !!params.useSSOToken;
    this.publicEndpoints = params.publicEndpoints ?? [];
    this.onInvalidateAuthorization = params.onInvalidateAuthorization;
    this.onServiceUnauthorized = params.onServiceUnauthorized;
    this.onServiceUnavailable = params.onServiceUnavailable;
    this.maxRetries = params.maxRetries ?? maxRetries;
    this.retryPeriod = params.retryPeriod;
    this.urlOnly = !!params.urlOnly;
  }

  public setUrl(url: string): void {
    this.url = url;
  }

  public sendRequest(params: SendRequestInterface): Promise<any> {
    let authorizationToken: string | null = null;
    const retryManager = new RetryManager({
      maxRetries: this.maxRetries,
      retryPeriod: this.retryPeriod,
      try: () => {
        return this.getToken(params)
          .then(authToken => {
            authorizationToken = authToken;
            return this.sendRequestImpl(params, authorizationToken);
          });
      },
      beforeRetry: (error: Error) => {
        Log.error(TAG, 'retry sendRequest failed');
        if (error?.type === ErrorType.BOInvalidAuthentication) {
          this.onInvalidateAuthorization?.(authorizationToken);
        }
        return Promise.resolve();
      },
      onMaxRetryExceeded: (error: Error) => {
        Log.error(TAG, 'retry sendRequest undone: max retries exceeded');
        if (error?.type === ErrorType.BOInvalidAuthentication) {
          this.onServiceUnauthorized?.();
        }
      },
      isRetryNeeded: (error: Error) => {
        return error?.type === ErrorType.BOInvalidAuthentication;
      }
    });

    return retryManager.try()
      .catch((error: Error) => {
        Log.error(TAG, 'sendRequest: httpFetch failed', error);
        switch (error?.type) {
          case ErrorType.HttpTimeout:
          case ErrorType.NetworkNoConnection:
          case ErrorType.NetworkRequestFailed:
            this.onServiceUnavailable?.(error);
            break;
        }
        throw error;
      });
  }

  private getToken({api, token, useSSOToken}: SendRequestInterface): Promise<string | null> {
    if (this.publicEndpoints.includes(api) || !(useSSOToken ?? this.useSSOToken)) {
      return Promise.resolve(null);
    }

    return token
      ? Promise.resolve(token)
      : Utils.getToken(this.onServiceUnauthorized);
  }

  private async sendRequestImpl(params: SendRequestInterface, authorizationToken: string | null): Promise<any> {
    if (!this.url) {
      return Promise.reject(new Error(ErrorType.ImproperConfiguration, 'url not set'));
    }
    const {
      additionalHeaders,
      api,
      body,
      method,
      secret,
      query,
      queryParams
    } = params;
    let url = this.urlOnly
      ? this.url
      : [this.url, api, this.tenant, query].filter(isTruthy).join('/');
    url = appendOptionsToUrl(url, queryParams);

    const headers: any = {
      'Accept-Language': mw.configuration.uiLanguage,
      'X-USER-DEVICE': deviceInfo.deviceType,
      'X-LANGUAGE': mw.configuration.uiLanguage,
      ...additionalHeaders && additionalHeaders
    };

    if (authorizationToken) {
      headers['XSSESSION'] = authorizationToken;
    }

    const id = shortId();
    let networkTime = Date.now();
    Log.info(TAG, `${httpRequestLogPrefix(id)} Send request url: ${url}, method: ${method}, ${secret ? 'secret content' : `headers: ${JSON.stringify(headers)}, body: ${body}`}`);
    const response = await httpFetch(url, {
      ...method && {method},
      ...body && {body},
      headers
    });
    networkTime = Date.now() - networkTime;
    let parsingTime = Date.now();
    const responseJson = this.isJsonInResponse(response) ? await response.json() : {};
    parsingTime = Date.now() - parsingTime;
    Log.info(TAG, `${httpRequestLogPrefix(id, {networkTime, parsingTime})} ${secret ? 'Got secret response' : `Got response: ${JSON.stringify(responseJson)}`}`);
    return this.validateResponse(response, responseJson);
  }

  private isJsonInResponse(response: Response): boolean {
    return !!response.headers.get('content-type')?.includes('application/json');
  }

  private validateResponse = (response: Response, responseJson: ResponseJson = {}): Promise<ResponseJson> => {
    if (!response.ok) {
      Log.error(TAG, 'validateResponse', response.status, response.statusText);
      const errorCode = responseJson?.statusCode ?? responseJson?.code;
      //FIXME MM-12959
      switch (response.status) {
        case HttpStatus.NotFound:
          throw new Error(ErrorType.HttpNotFound);
        case HttpStatus.BadRequest:
          const errorType = errorCode === ErrorType.HttpBadRequest ? ErrorType.HttpBadRequest : ErrorType.BOBadResponse;
          throw new Error(errorType, responseJson?.message, this.getFirstErrorCode(responseJson));
        case HttpStatus.Unauthorized:
          throw new Error(ErrorType.BOInvalidAuthentication, responseJson?.message);
        case HttpStatus.Forbidden:
          throw new Error(ErrorType.HttpForbidden, this.getFirstErrorCode(responseJson));
        case HttpStatus.UnprocessableEntity:
          if (!responseJson.errors) {
            throw new Error(ErrorType.HttpUnprocessableEntity);
          }
          throw new ValidationError(responseJson.errors);
        default:
          throw new Error(ErrorType.BOBadResponse);
      }
    }

    return Promise.resolve(responseJson);
  }

  private getFirstErrorCode(responseJson: ResponseJson): string | undefined {
    const errorResponseJson = responseJson as ErrorResponseJson;
    const errorJson = (errorResponseJson?.errors ?? []).find((errorJson: ErrorJson) => errorJson.errorCode);
    return errorJson?.errorCode;
  }

  public sendGetRequest(params: GetRequestInterface): Promise<any> {
    return this.sendRequest({
      method: HttpMethods.GET,
      ...params,
      additionalHeaders: {
        ...(params.otherParams?.additionalHeaders ?? {})
      }
    });
  }

  public sendDeleteRequest(params: DeleteRequestInterface): Promise<any> {
    return this.sendRequest({
      method: HttpMethods.DELETE,
      ...params
    });
  }

  public sendPutRequest(params: PutRequestInterface): Promise<any> {
    return this.sendBodyRequest({
      method: HttpMethods.PUT,
      ...params
    });
  }

  public sendPatchRequest(params: PatchRequestInterface): Promise<any> {
    return this.sendBodyRequest({
      method: HttpMethods.PATCH,
      ...params
    });
  }

  public sendPostRequest(params: PostRequestInterface): Promise<any> {
    return this.sendBodyRequest({
      method: HttpMethods.POST,
      ...params
    });
  }

  public sendBodyRequest(params: BodyRequestInterface): Promise<any> {
    const additionalHeaders = {'Content-Type': 'application/json'};
    const {otherParams, ...requestParams} = params;
    return this.sendRequest({
      ...requestParams,
      ...otherParams,
      additionalHeaders: {
        ...additionalHeaders,
        ...(otherParams?.additionalHeaders ?? {})
      }
    });
  }
}
