import {nxffConfig} from 'mw/api/NXFF';
import {convertJSDateToJSONDate} from 'mw/common/utils';
import {mw} from 'mw/MW';

import {Props} from './TraxisTypes';

export class Filters {
  public static isAvailable = 'IsAvailable==true';
  public static isEntitled = 'EntitlementState=="Entitled"';
  public static isLogicalChannelNumber = 'LogicalChannelNumber>=0';
  public static isViewable = 'isViewableOnCpe==true';
  public static isSeen = 'IsPersonallyViewedCompletely==true';
  public static isNotSeen = 'IsPersonallyViewedCompletely==false';
  public static personalBookmarkPositive = 'PersonalBookmark>0';
  public static isFeature = 'isFeature==true';
  public static hasEvents = 'Events>0';
  public static noEvents = 'Events==0';
  public static noEventCount = 'EventCount==0';
  public static hasContents = 'Contents>0';
  public static hasChannels = 'Channels>0';
  public static hasProducts = 'Products>0';
  public static hasRecordings = 'Recordings>0';
  public static productCountPositive = 'ProductCount>0';
  public static hasTitles = 'Titles>0';
  public static hasSeries = 'SeriesCollection>0';
  public static hasTstvContents = 'TstvContents>0';
  public static notAfterAvailabilityEnded = 'AvailabilityEnd<now()';
  public static beforeLastAvailabilityEnded = 'LastAvailability>now()';
  public static availableContents = 'FirstAvailability<now()&&LastAvailability>now()';
  public static pastEvents = 'AvailabilityEnd<now()';
  public static currentEvents = 'AvailabilityStart<now()&&AvailabilityEnd>now()';
  public static futureEvents = 'AvailabilityStart>now()';
  public static notPastEvents = 'AvailabilityEnd>now()';
  public static isNotRecording = 'IsRecording!=true';
  public static recordedEvents = 'IsRecorded==true';
  public static tstvEvents = 'HasTstv==true';
  public static noSeries = 'SeriesCount==0';
  public static seriesCountPositive = 'SeriesCount>0';
  public static contentCountPositive = 'ContentCount>0';
  public static eventCountPositive = 'EventCount>0';
  public static tstvEventCountPositive = 'TSTVEventCount>0';
  public static hasChildSeries = 'ChildSeriesCollection>0';
  public static childCategoriesCountPositive = 'ChildCategoryCount>0';
  public static isCategoryPersonal = '(CustomProperties.href==IsPersonal&&CustomProperties==True)';
  public static containsContentViewableOnCpe = 'ContainsContentViewableOnCpe==true';
  public static isRecordingComplete = 'State==Complete';
  public static futureStartTime = '(((isnull(ActualStartTime))&&StartTime>now())||ActualStartTime>now())';
  public static pastOrPresentStartTime = '(((isnull(ActualStartTime))&&StartTime<=now())||ActualStartTime<=now())';
  public static isRecordingScheduled = (): string => nxffConfig.getConfig().PVR.OngoingRecordingsAreRecorded
    ? `((State==Scheduled||State==Recording)&&${Filters.futureStartTime})`
    : '(State==Scheduled||State==Recording)';
  public static isRecordingRecorded = (): string => nxffConfig.getConfig().PVR.OngoingRecordingsAreRecorded
    ? `(State==Failed||${Filters.isRecordingComplete}||((State==Scheduled||State==Recording)&&${Filters.pastOrPresentStartTime}))`
    : `(State==Failed||${Filters.isRecordingComplete})`;
  public static noParentRecording = 'ParentRecordingCount==0';
  public static withParentRecording = 'ParentRecordingCount>0';
  public static parentRecordingCount = (n: number) => `ParentRecordingCount==${n}`;
  public static noAdult = 'isAdult==false';
  public static isSingle = 'Type!=Series';
  public static isRecordingEpisode = 'Type==Event';
  public static isSeries = 'Type==Series';
  public static isSeriesActive = 'FactoryState==Active';
  public static hasChildRecordings = 'ChildRecordings>0';
  public static profile = (profileId: string) => 'ProfileId==' + profileId;
  public static availableAfter = (startTime: Date, resource: 'event' | 'content' = 'event'): string => (resource === 'event' ? Props.AvailabilityEnd : Props.LastAvailability) + '>' + convertJSDateToJSONDate(startTime);
  public static availableBefore = (endTime: Date, resource: 'event' | 'content' = 'event'): string => (resource === 'event' ? Props.AvailabilityStart : Props.FirstAvailability) + '<' + convertJSDateToJSONDate(endTime);
  public static hasGenre = (genre: string): string => Props.Genres + '.type==' + genre;
  public static byEventID = (id: string): string => `EventId=="${id}"`;
  public static bySeriesID = (id: string): string => `SeriesId=="${id}"`;
  public static byChannelId = (id: string): string => `ChannelId==${id}`;
  public static byChannelLCN = (lcn: number): string => `LogicalChannelNumber==${lcn}`;
  private static noRatingsFilter = '(isnull(Ratings))'; // TODO: aditional parenthesis to workaround ADRA-4061
  public static noAdultAgeBasedFilter = () => {
    const noAdultInferredMinimumAgeFilter = `!(Ratings.InferredMinimumAge>=${nxffConfig.getConfig().ParentalControl.AdultAge})`;
    return `(${Filters.noRatingsFilter}||${noAdultInferredMinimumAgeFilter})`;
  };
  public static noAdultRatingBasedFilter = () => {
    const filters = [Filters.noRatingsFilter];
    const allowedRatingsFilters = mw.configuration.supportedPCRatings.map(pcRating => {
      return pcRating.ratings
        .filter(rating => !pcRating.adultOnlyRatings.includes(rating))
        .map(rating => `[Ratings.authority=="${pcRating.authority}"&&Ratings.Value=="${rating}"]`)
        .join('||');
    });
    allowedRatingsFilters.length && filters.push(`(${allowedRatingsFilters.join('||')})`);
    return `(${filters.join('||')})`;
  }

  public static channelIdsFilter(channelIds: string[]): string {
    const ids = channelIds.map(Filters.byChannelId)
      .join('||');
    return `(${ids})`;
  }

  public static channelLcnFilter(channelLcns: number[]): string {
    const lcns = channelLcns.map(Filters.byChannelLCN)
      .join('||');
    return `(${lcns})`;
  }
}
