import i18next from 'i18next';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ViewStyle} from 'react-native';

import {featureFlags} from 'common/constants';
import {addToWatchList, removeFromWatchList} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {Event, UpdateMediaParams} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import {IconType} from 'components/Icon';
import MoreActionsPopup, {MoreActionsAction} from 'components/MoreActionsPopup';
import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';
import PlayerLanguageController from 'components/player/PlayerLanguageController';
import PlayerLanguageEventHandler, {PlayerTracksInfo} from 'components/player/PlayerLanguageEventHandler';
import {useRecord} from 'components/pvr/Record';
import {RecordActionType} from 'components/pvr/RecordingUtils';
import {useDisposable, useDisposableCallback, useFunction, useLateBinding} from 'hooks/Hooks';

const TAG = 'EventMoreActionsPopup';

type Props = {
  width?: string | number; // percentage of the screen's width or dips
  height?: string | number;
  visible: boolean;
  style?: ViewStyle;
  onClose: () => void;
  event?: Event;
  isEventAuthorized?: (event: Event) => Promise<boolean>;
  tuneEvent?: (media: Event) => void;
  openEventDetail: (event: Event) => void;
  showEpgAction?: boolean;
  showEpg: () => void;
}

const EventMoreActionsPopup: React.FunctionComponent<Props> = props => {
  const {isEventAuthorized, showEpgAction = true} = props;
  const [moreActions, setMoreActions] = useState<MoreActionsAction[]>([]);
  const [moreActionsPopupLoading, setMoreActionsPopupLoading] = useState<boolean>(false);
  const [currentAudio, setCurrentAudio] = useState<string>('');
  const [currentSubtitle, setCurrentSubtitle] = useState<string>('');
  const playerLanguageController = useRef<PlayerLanguageController>(null);
  const player = useRef(mw.players.main);
  const {renderRecordingsComponents, scheduleRecording, getRecordActionTypeForEvent} = useRecord();
  const {unlockMedia, isMediaBlocked} = useParentalControl();

  const onPlayerTracksChanged = useCallback((playerTracksInfo: PlayerTracksInfo) => {
    const audio = playerTracksInfo.getCurrentAudioTrack();
    const subtitle = playerTracksInfo.getCurrentSubtitleTrack();

    setCurrentAudio(audio ? audio.language : '');
    setCurrentSubtitle(subtitle ? subtitle.language : '');
  }, []);

  const [refreshMoreActions, bindRefreshMoreActions] = useLateBinding<(event: Event, recordActionType: RecordActionType) => void>();

  const refreshActions = useDisposable(async (event: Event) => {
    setMoreActionsPopupLoading(true);
    refreshMoreActions(event, await getRecordActionTypeForEvent(event));
    setMoreActionsPopupLoading(false);
  });

  const openEventDetailHandler = useDisposableCallback((event: Event) => {
    props.openEventDetail(event);
    props.onClose();
  }, [props.openEventDetail, props.onClose]);

  const showEPGHandler = useDisposableCallback(() => {
    props.showEpg();
    props.onClose();
  }, [props.showEpg, props.onClose]);

  const showLanguageSelectionPopupHandler = useDisposableCallback(() => {
    if (playerLanguageController.current) {
      props.onClose();
      playerLanguageController.current.showLanguageSelectionPopup();
    }
  }, [props.onClose]);

  const updateEventBookmark = useDisposable((event: Event) => mw.catalog.updateMedia(event, UpdateMediaParams.Bookmarks));

  const tune = useDisposableCallback(async (event: Event) => {
    try {
      await updateEventBookmark(event);
    } catch (error) {
      Log.error(TAG, 'Error updating event bookmark', error);
    }
    props.tuneEvent?.(event);
    props.onClose();
  }, [props.tuneEvent, props.onClose]);

  const tuneEventHandler = useDisposableCallback(async (event: Event) => {
    props.onClose();
    if (!isMediaBlocked(event) || await isEventAuthorized?.(event)) {
      return tune(event);
    }
    return unlockMedia(event)
      .then(() => tune(event))
      .catch((error: string) => Log.error(TAG, 'Authorization with pin failed:', error));
  }, [tune, props.onClose]);

  const scheduleRecordingHandler = useDisposableCallback(async (event: Event) => {
    // close popup first as scheduleRecording displays another one
    props.onClose();
    await scheduleRecording(event);
  }, [props.onClose]);

  const addToWatchListHandler = useDisposableCallback(async (event: Event) => {
    await addToWatchList(event);
    refreshActions(event);
    props.onClose();
  }, [props.onClose, refreshActions]);

  const removeFromWatchListHandler = useDisposableCallback(async (event: Event) => {
    await removeFromWatchList([event]);
    refreshActions(event);
    props.onClose();
  }, [props.onClose, refreshActions]);

  bindRefreshMoreActions(useFunction((event: Event, recordActionType: RecordActionType) => {
    // define all available actions
    const moreInfoAction: MoreActionsAction = {
      key: 'moreInfo',
      icon: IconType.MoreInfo,
      text: i18next.t('zapper.moreInfo'),
      onPress: () => openEventDetailHandler(event)
    };
    const recordAction: MoreActionsAction = {
      key: 'record',
      icon: IconType.RecordDot,
      text: i18next.t('zapper.record'),
      onPress: () => scheduleRecordingHandler(event)
    };
    const recordSeriesAction: MoreActionsAction = {
      key: 'recordSeries',
      icon: IconType.RecordSeries,
      text: i18next.t('zapper.recordSeries'),
      onPress: () => scheduleRecordingHandler(event)
    };
    const recordAllAction: MoreActionsAction = {
      key: 'recordAll',
      icon: IconType.RecordSeries,
      text: i18next.t('zapper.recordAll'),
      onPress: () => scheduleRecordingHandler(event)
    };
    const epgAction: MoreActionsAction = {
      key: 'showEPG',
      icon: IconType.Epg,
      text: i18next.t('zapper.tvGuide'),
      onPress: () => showEPGHandler()
    };
    const subtitleAction: MoreActionsAction = {
      key: 'subtitle',
      icon: IconType.Subtitle,
      text: i18next.t('common.subtitles') + ' / ' + i18next.t('common.audio') + ' • ' + i18next.t(currentSubtitle) + ' / ' + i18next.t(currentAudio),
      onPress: () => showLanguageSelectionPopupHandler()
    };
    const channelListAction: MoreActionsAction = {
      key: 'channelList',
      icon: IconType.ChannelsList,
      text: i18next.t('common.channelList') + ' • ' + 'All channels', // TODO: Change static text 'All channels' when CL-142 will be done
      onPress: () => {
        // TODO: Implement Channel List Overlay (CL-142)
      }
    };
    const restartAction: MoreActionsAction = {
      key: 'restart',
      icon: IconType.Restart,
      text: event.isPast ? i18next.t('zapper.goBack') : i18next.t('zapper.restart'),
      onPress: () => tuneEventHandler(event)
    };
    const addToWatchListAction: MoreActionsAction = {
      key: 'addToWatchList',
      icon: IconType.Add,
      text: i18next.t('mediaPlayer.addToWatchList'),
      onPress: () => addToWatchListHandler(event)
    };
    const removeFromWatchListAction: MoreActionsAction = {
      key: 'removeFromWatchList',
      icon: IconType.Remove,
      text: i18next.t('mediaPlayer.removeFromWatchList'),
      onPress: () => removeFromWatchListHandler(event)
    };
    // use only allowed actions in proper order
    const actions = [];
    if (event.isNow) {
      actions.push(subtitleAction);
    }
    if (featureFlags.channelLists) {
      actions.push(channelListAction);
    }
    actions.push(moreInfoAction);

    switch (recordActionType) {
      case RecordActionType.record:
        actions.push(recordAction);
        break;
      case RecordActionType.recordSeries:
        actions.push(recordSeriesAction);
        break;
      case RecordActionType.recordAll:
        actions.push(recordAllAction);
        break;
    }

    if (showEpgAction) {
      actions.push(epgAction);
    }
    if (props.tuneEvent && event.hasTstv) {
      actions.push(restartAction);
    }
    if (event.isAllowedOnWatchList()) {
      actions.push(event.isOnWatchList ? removeFromWatchListAction : addToWatchListAction);
    }
    setMoreActions(actions);
  }));

  useEffect(() => {
    if (props.visible && props.event) {
      refreshActions(props.event);
    }
  }, [props.visible, props.event, refreshActions, currentSubtitle, currentAudio, props.tuneEvent, props.openEventDetail, props.showEpg, showEpgAction]);

  return (
    <>
      <MoreActionsPopup visible={props.visible} loading={moreActionsPopupLoading} actions={moreActions} onClose={props.onClose} />
      <PlayerLanguageController ref={playerLanguageController} player={player.current} />
      <PlayerLanguageEventHandler player={player.current} onTracksChanged={onPlayerTracksChanged} />
      {renderRecordingsComponents()}
    </>
  );
};

export default React.memo(EventMoreActionsPopup);
