import {createStyles} from 'common-styles';
import React, {useRef, useCallback} from 'react';
import {Animated, View} from 'react-native';

import {isSmartTV, showButtonAnimationLength, hideButtonAnimationLength} from 'common/constants';
import {doNothing} from 'common/HelperFunctions';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Media} from 'mw/api/Metadata';

import FocusParent from 'components/FocusParent';
import BigScreenMediaRecommendationsView from 'components/mediadetails/BigScreenRecommendationsView';
import {useScreenInfo} from 'hooks/Hooks';

import {CloseMoreButton} from './SeeMoreButtons';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  content: {
    backgroundColor: colors.recommendationsScreen.background,
    justifyContent: 'flex-start',
    width: '100%'
  }
}));
const staticStyles = createStyles({
  recommendationsContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'flex-start',
    width: '100%'
  },
  recommendationsContainerRow: {
    flex: 1
  }
});

type Props = {
  recommendations: Media[];
  onClose: () => void;
  onFocusEnter: () => void;
};

export const BigScreenRecommendationsSection: React.FC<Props> = ({
  recommendations,
  onClose,
  onFocusEnter: propsOnFocusEnter
}) => {
  const buttonOpacity = useRef(new Animated.Value(0)).current;
  const onFocusEnter = useCallback(() => {
    propsOnFocusEnter();
    if (isSmartTV) {
      Animated.timing(buttonOpacity, {toValue: 1, duration: showButtonAnimationLength}).start();
    }
  }, [propsOnFocusEnter, buttonOpacity]);
  const hideCloseButton = useCallback(() => {
    if (isSmartTV) {
      Animated.timing(buttonOpacity, {toValue: 0, duration: hideButtonAnimationLength}).start();
    }
  }, [buttonOpacity]);
  const screenInfo = useScreenInfo();
  const styles = stylesUpdater.getStyles();
  return (
    <FocusParent style={[styles.content, screenInfo.size]} debugName={BigScreenRecommendationsSection.name} onFocusEnter={onFocusEnter} onFocusEscape={hideCloseButton}>
      <Animated.View style={{opacity: isSmartTV ? buttonOpacity : 1}}>
        <CloseMoreButton onPress={isSmartTV ? doNothing : onClose} disabled={isSmartTV} />
      </Animated.View>
      <View style={staticStyles.recommendationsContainer}>
        <View style={staticStyles.recommendationsContainerRow}>
          <BigScreenMediaRecommendationsView recommendations={recommendations} />
        </View>
      </View>
    </FocusParent>
  );
};
