import {createStyles} from 'common-styles';
import React, {useMemo, useCallback, useState} from 'react';
import {Animated, View, LayoutChangeEvent, ViewStyle, Keyboard, StyleProp} from 'react-native';

import {Direction, dimensions, isMobile, getValue, isPhone, isTablet} from 'common/constants';
import {AnimatedStyle} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import SwipeTrack from 'components/gestures/SwipeTrack';
import {Icon} from 'components/Icon';
import Separator from 'components/Separator';
import {useChangeEffect, useScreenInfo} from 'hooks/Hooks';

import GradientFill from './GradientFill';
import {ModalCloseBar, Modal} from './Modal';
import NitroxButton, {NitroxButtonTheme} from './NitroxButton';
import NitroxText from './NitroxText';
import {PopupContentProps, popupContentStylesUpdater} from './PopupContent';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  modalGradient: [colors.popup.overlay.background, colors.popup.overlay.backgroundTransparent],
  gradientFill: colors.popup.gradient,
  button: {
    backgroundColor: colors.button.primary.background.focused
  },
  buttonText: {
    color: colors.button.primary.text.focused
  },
  iconContainer: {
    alignItems: 'center',
    padding: dimensions.margins.medium
  },
  separator: {
    marginTop: dimensions.margins.medium,
    backgroundColor: colors.popup.buttonSeparator
  },
  iconColor: colors.defaultColors.icon
}));

const iconSize = getValue({mobile: dimensions.icon.xlarge, tablet: dimensions.icon.xxLarge, defaultValue: dimensions.icon.xxxLarge});

const PopupContent: React.FC<PopupContentProps> = ({
  visible,
  actions,
  actionsSeparator,
  title,
  subtitle,
  onModalClose,
  children,
  width,
  menuStyle,
  containerStyle,
  titleStyle,
  titleType,
  icon,
  subtitleStyle,
  subtitleType,
  onLayout
}) => {
  const [contentHeight, setContentHeight] = useState<number>();
  const styles = stylesUpdater.getStyles();
  const modalStyle = useCallback((positionDelta: Animated.Value): AnimatedStyle<ViewStyle> => {
    if (!contentHeight) {
      return {};
    }
    return {
      backgroundColor: positionDelta.interpolate({
        inputRange: [0, contentHeight],
        outputRange: styles.modalGradient
      })
    };
  }, [contentHeight, styles.modalGradient]);

  const {orientation} = useScreenInfo();
  const popupDynamicStyles = popupContentStylesUpdater.getStyles();
  const actionsMenuStyle = useMemo<StyleProp<ViewStyle>>(() => ([
    popupDynamicStyles.menu,
    actions.length === 1 ? popupDynamicStyles.menuCentered : {width: width - dimensions.popup.padding * 2},
    menuStyle
  ]), [popupDynamicStyles.menu, popupDynamicStyles.menuCentered, actions.length, width, menuStyle]);

  const actionsMenuFlexParams = useMemo<StyleProp<ViewStyle>>(() => ([
    popupDynamicStyles.menuFlexParams,
    {flexDirection: orientation.isLandscape || isTablet ? 'row' : 'column'}
  ]), [orientation, popupDynamicStyles.menuFlexParams]);

  const separatorDynamicStyle = useMemo<StyleProp<ViewStyle>>(() => ([
    styles.separator,
    {width: isPhone && orientation.isPortrait ? dimensions.popup.button.width : '100%'},
    {flexDirection: orientation.isLandscape ? 'row' : 'column'}
  ]), [orientation, styles.separator]);

  const onContainerLayout = useCallback((layoutChangeEvent: LayoutChangeEvent) => {
    onLayout?.(layoutChangeEvent);
    setContentHeight(layoutChangeEvent.nativeEvent.layout.height);
  }, [onLayout]);

  useChangeEffect(() => {
    // see Modal.tsx
    if (visible && isMobile) {
      setImmediate(Keyboard.dismiss);
    }
  }, [visible]);

  if (!visible) {
    return null;
  }

  return (
    <SwipeTrack
      swipeOrigin={Direction.Up}
      onCloseGesture={onModalClose}
      closeGestureThreshold={contentHeight && (contentHeight / 2)}
      flyAwayDistance={contentHeight}
    >
      {({panResponder, positionDelta}) => (
        <Modal
          visible={visible}
          onClose={onModalClose}
          style={modalStyle(positionDelta)}
          contentStyle={{width}}
          overlayKey={PopupContent.name}
        >
          <Animated.View
            style={[
              popupDynamicStyles.container,
              {width},
              containerStyle,
              {transform: [
                {translateY: positionDelta}
              ]}
            ]}
            onLayout={onContainerLayout}
          >
            <GradientFill colors={styles.gradientFill} />
            <View
              {...panResponder.panHandlers}
              collapsable={false}
            >
              <ModalCloseBar />
              {!!icon && (
                <View style={styles.iconContainer}>
                  <Icon type={icon} size={iconSize} color={styles.iconColor} />
                </View>
              )}
              {!!title && (
                <View>
                  <NitroxText textType={titleType || 'dialog-title'} style={[popupDynamicStyles.title, titleStyle]}>{title}</NitroxText>
                  {!!subtitle && <NitroxText textType={subtitleType || 'callout'} style={[popupDynamicStyles.subtitle, subtitleStyle]}>{subtitle}</NitroxText>}
                </View>
              )}
            </View>
            {children}
            {!!actions.length && (
              <View style={actionsMenuStyle}>
                {actionsSeparator && <Separator horizontal style={separatorDynamicStyle} />}
                <View style={actionsMenuFlexParams}>
                  {actions
                    .map(action => (
                      <NitroxButton
                        {...action}
                        key={action.key}
                        border
                        theme={NitroxButtonTheme.Tertiary}
                        isSelected={action.highlighted}
                        styleSelected={styles.button}
                        textStyleSelected={styles.buttonText}
                      />
                    )
                    )
                  }
                </View>
              </View>
            )}
          </Animated.View>
        </Modal>
      )}
    </SwipeTrack>
  );
};

export default PopupContent;
