import {createStyles} from 'common-styles';
import React, {useState, forwardRef, Ref, useMemo, useRef, useCallback, useImperativeHandle} from 'react';
import {View, ViewStyle, Insets} from 'react-native';

import {dimensions, defaultPageSize} from 'common/constants';
import {doNothing} from 'common/HelperFunctions';

import {Media} from 'mw/api/Metadata';
import {Component as UXMComponent, FilterBasedDataSource} from 'mw/cms/Component';
import {mw} from 'mw/MW';

import {useFunction} from 'hooks/Hooks';

import {AnimatedSwimlane, AnimatedSwimlaneInterface} from './AnimatedSwimlane';
import {useDefaultVisibilityLimits, defaultSwimlaneInsets} from './AnimatedSwimlaneStack';
import {AnimatedVerticalStackRowProps} from './AnimatedVerticalStack';
import {AspectRatio} from './AspectRatio';
import MediaPicture from './MediaPicture';
import HighlightedBannerPlaceholder from './mediaTiles/HighlightedBannerPlaceholder';
import HighlightedBannerTile from './mediaTiles/HighlightedBannerTile';
import {swimlaneHeaderHeight, SwimlaneDataFetcherState, AnimatedTileProps, SwimlaneTileProps} from './Swimlane';
import {createLimitedDataFetcher} from './utils/SwimlaneVisibilityLimit';
import {useDefaultMediaSwimlaneMetaEvents} from './uxm-components/SwimlaneBasedStackRow';
import {UXMComponentStackRowInterface} from './uxm-components/UXMComponentStackRow';

export const swimlaneItemWidth = dimensions.swimlaneWithHighlightedBanner.tileWidth + 2 * dimensions.margins.large;
export const swimlaneItemHeight = dimensions.swimlaneWithHighlightedBanner.tileHeight + 2 * (dimensions.margins.xsmall + dimensions.swimlaneWithHighlightedBanner.tileBorderWidth) + dimensions.margins.large;

type Props = {
  component: UXMComponent & {dataSource: FilterBasedDataSource};
  insets?: Insets;
} & AnimatedVerticalStackRowProps;

const styles = createStyles({
  imageContainer: {
    width: '100%',
    height: dimensions.swimlaneWithHighlightedBanner.tileHeight,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    overflow: 'hidden',
    borderRadius: 20
  }
});

function SwimlaneWithHighlightedBanner(props: Props, ref: Ref<UXMComponentStackRowInterface>): React.ReactElement {
  const {
    component,
    index,
    focused,
    layout,
    insets,
    onLoaded,
    onHover,
    onElementFocus
  } = props;
  const swimlaneRef = useRef<AnimatedSwimlaneInterface>(null);

  /* eslint-disable schange-rules/no-use-imperative-handle-hook */
  useImperativeHandle(ref, () => ({
    onPress: () => {
      swimlaneRef.current?.onPress();
    },
    onNavigateLeft: () => {
      swimlaneRef.current?.scrollLeft();
    },
    onNavigateRight: () => {
      swimlaneRef.current?.scrollRight();
    },
    onNavigateUp: () => {
      return {shouldStay: swimlaneRef.current?.navigateUp() ?? false};
    },
    onNavigateDown: () => {
      return {shouldStay: swimlaneRef.current?.navigateDown() ?? false};
    }
  }), []);
  /* eslint-enable schange-rules/no-use-imperative-handle-hook */

  const [selectedMedia, setSelectedMedia] = useState<Media>();

  const onTileFocus = useFunction((tileIndex: number, _: unknown, data: Media, headerActionsVisible: boolean) => {
    onElementFocus(tileIndex, !headerActionsVisible);
    setSelectedMedia(data);
  });
  const onHeaderTileFocus = useFunction((headerTileIndex: number) => {
    onElementFocus(headerTileIndex, false);
  });

  const onDataFetcherStateChanged = useFunction((state: SwimlaneDataFetcherState) => {
    if (state === SwimlaneDataFetcherState.HasData) {
      onLoaded(false);
    }
  });
  const onSwimlaneEmpty = useFunction(() => onLoaded(true));

  const swimlaneInsets = useMemo(() => ({
    ...defaultSwimlaneInsets,
    ...insets
  }), [insets]);

  const leadingPicture = useMemo(() => {
    const image = component.settings?.images?.find(({type}) => type === 'leading-image');
    if (image) {
      const scaledHeight = image.height || 0 > dimensions.swimlaneWithHighlightedBanner.tileHeight
        ? dimensions.swimlaneWithHighlightedBanner.tileHeight
        : image.height || 0;
      const calculatedWidth = (image.width || 0) * scaledHeight / (image.height || 0);
      const scaledWidth = image.width || 0 > calculatedWidth ? calculatedWidth : image.width || 0;
      return {
        ...image,
        height: scaledHeight,
        width: scaledWidth
      };
    }
    return null;
  }, [component]);

  const leadingPictureContainerWidth = useMemo(() => leadingPicture ? leadingPicture?.width + 2 * dimensions.margins.large : 0, [leadingPicture]);

  const leadingPictureStyle: ViewStyle = useMemo(() => ({
    position: 'absolute',
    top: (layout.top as number ?? 0) + swimlaneHeaderHeight + swimlaneInsets.top + dimensions.margins.large + dimensions.margins.xsmall + dimensions.swimlaneWithHighlightedBanner.tileBorderWidth,
    left: (layout.left as number ?? 0) + swimlaneInsets.left + dimensions.margins.xsmall + dimensions.swimlaneWithHighlightedBanner.tileBorderWidth,
    width: leadingPicture?.width
  }), [layout.top, layout.left, swimlaneInsets.top, swimlaneInsets.left, leadingPicture]);

  const highlightedTileContainerStyle: ViewStyle = useMemo(() => ({
    position: 'absolute',
    top: (layout.top as number ?? 0) + swimlaneHeaderHeight + swimlaneInsets.top,
    left: leadingPicture ?
      (layout.left as number ?? 0) + swimlaneInsets.left + leadingPictureContainerWidth
      : (layout.left as number ?? 0) + swimlaneInsets.left
  }), [layout.top, layout.left, swimlaneInsets.top, swimlaneInsets.left, leadingPicture, leadingPictureContainerWidth]);

  const renderPlaceholder = useCallback(() => {
    return (
      <HighlightedBannerPlaceholder onPress={doNothing} />
    );
  }, []);

  const createTile = useCallback((tileProps: SwimlaneTileProps<Media>, animatedTileProps?: AnimatedTileProps) => {
    const onMount = tileProps.index === 0 ? () => setSelectedMedia(tileProps.data) : undefined;
    // eslint-disable-next-line react/display-name
    return (focused: boolean) => (
      <HighlightedBannerTile
        ref={animatedTileProps?.refHandler}
        {...tileProps}
        key={tileProps.data.id}
        staticallyFocused={focused}
        showTileName={!!component.settings?.showName}
        onMount={onMount}
      />
    );
  }, [component.settings?.showName]);

  const dataEvents = useDefaultMediaSwimlaneMetaEvents(component.dataSource);
  const visibilityLimits = useDefaultVisibilityLimits(component);
  const dataFetcher = useMemo(() => {
    let limits = visibilityLimits;
    if (component.settings?.itemsLimit != null) {
      limits = limits.map(l => ({
        ...l,
        limit: component.settings?.itemsLimit
      }));
    }
    return createLimitedDataFetcher(
      mw.catalog.getContent(component.dataSource.filters, {pageSize: defaultPageSize}),
      limits
    );
  }, [component.dataSource.filters, component.settings?.itemsLimit, visibilityLimits]);

  const swimlaneLayout = useMemo(() => ({
    ...layout,
    ...(leadingPicture ? {left: layout.left as number + leadingPictureContainerWidth} : {})
  }), [layout, leadingPicture, leadingPictureContainerWidth]);
  return (
    <>
      <AnimatedSwimlane<Media>
        /* eslint-disable @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        ref={swimlaneRef}
        /* eslint-enable @typescript-eslint/ban-ts-comment */
        key={index}
        row={index}
        style={swimlaneLayout}
        onTileFocus={onTileFocus}
        onHeaderTileFocus={onHeaderTileFocus}
        onHoverChange={onHover}
        focused={focused}
        onSwimlaneEmpty={onSwimlaneEmpty}
        createTile={createTile}
        dataFetcher={dataFetcher}
        onDataFetcherStateChanged={onDataFetcherStateChanged}
        positionLeftOffset={dimensions.swimlaneWithHighlightedBanner.tileWidth}
        header={component.title}
        insets={swimlaneInsets}
        placeholderComponent={renderPlaceholder}
        itemHeight={swimlaneItemHeight}
        itemWidth={swimlaneItemWidth}
        {...dataEvents}
      />
      {leadingPicture && (
        <View style={leadingPictureStyle}>
          <AspectRatio ratio={leadingPicture.height / leadingPicture.width}>
            <View style={styles.imageContainer}>
              <MediaPicture
                source={{uri: leadingPicture.url}}
              />
            </View>
          </AspectRatio>
        </View>
      )}
      <View style={highlightedTileContainerStyle}>
        <HighlightedBannerTile
          data={selectedMedia}
          showTileName={!!component.settings?.showName}
          highlighted
          staticallyFocused={focused}
        />
      </View>
    </>
  );
}

export default React.memo(forwardRef(SwimlaneWithHighlightedBanner));
