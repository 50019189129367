import {Log} from 'common/Log';

import {nxffConfig} from 'mw/api/NXFF';
import {BOInterface} from 'mw/bo-proxy/BOInterface';

import {ADR8Adapter} from './adr8/ADR8Adapter';
import {BOType} from './BOType';
import {TraxisAdapter} from './traxis/TraxisAdapter';

const TAG = 'BOFactory';

export class BOFactory {

  public static createBOAdapter = (): BOInterface => {
    const boType = nxffConfig.getConfig().Environment.BOType;
    Log.debug(TAG, 'BOType', boType);
    switch (boType) {
      case BOType.ADR8:
        return new ADR8Adapter();

      default:
        return new TraxisAdapter();
    }
  }
}
