import {getAssetURL} from 'common/Assets/Assets';
import {DateUtils} from 'common/DateUtils';
import {EventEmitter} from 'common/EventEmitter';
import {Log} from 'common/Log';

import {CAPMessage, CAPInfo, EASAlert, EASInfo} from 'mw/api/EASMetadata';
import {Audio} from 'mw/api/Metadata';
import {nxffConfig} from 'mw/api/NXFF';
import {boProxy} from 'mw/bo-proxy/BOProxy';
import {AudioObject} from 'mw/metadata/AudioObject';
import {mw} from 'mw/MW';
import {Notification, SEACNotification, SEACNotificationType, ActionType} from 'mw/notifications/Notification';
import {NotificationEvent} from 'mw/notifications/NotificationsManager';

const TAG = 'EAS';

const capMessageType = 'CAP';
const easTextParamName = 'EASText';
const easHeaderParamName = 'EASHeader';
const broadcastTextParamName = 'layer:SOREM:1.0:Broadcast_Text';
const originAcronymParamName = 'EAS-ORG';
const acronymToOriginatorMap: Record<string, string> = {
  'PEP': 'Primary Entry Point Station',
  'CIV': 'Civil authorities',
  'WXR': 'National Weather Service',
  'EAS': 'EAS Participant',
  'EAN': 'Emergency Action Notification Network'
};
const broadcastAudioDescriptions = [
  'EAS Broadcast Content',
  'Broadcast Audio'
];
const broadcastAudioMimeTypes = [
  'audio/mpeg',
  'audio/x-ipaws-audio-mp3',
  'audio/x-ipaws-audio-wav',
  'audio/x-ipaws-streaming-audio-mp3'
];

enum AttentionSignalType {
  Pre,
  Post,
}

function isCAPNotification(notification?: Notification): notification is SEACNotification {
  // the MessageType is optional props and its case-sensitiveness is not strict and sometimes it can be represented as all-caps or camelcase.
  return notification instanceof SEACNotification && notification.notificationType === SEACNotificationType.Message &&
    (!notification.messageType || notification.messageType.toUpperCase() === capMessageType); // eslint-disable-line no-restricted-syntax
}

function findOriginAcronym(capInfo: CAPInfo): string {
  const originAcronym = capInfo.params.get(originAcronymParamName);
  if (originAcronym) {
    return originAcronym;
  }
  const easHeader = capInfo.params.get(easHeaderParamName);
  if (easHeader) {
    const matches = easHeader.match('ZCZC-([A-Z]{3})-');
    if (matches?.[1]) {
      return matches[1];
    }
  }
  return '';
}

function formatExpirationDate(capInfo: CAPInfo): string {
  if (!capInfo.expires) {
    return '';
  }
  return DateUtils.isSameDay(new Date(), capInfo.expires)
    ? DateUtils.formatDate(capInfo.expires, 'HH:MM TT')
    : DateUtils.formatDate(capInfo.expires, 'mm/dd/yyyy HH:MM TT');
}

function formatUSAMessage(capInfo: CAPInfo): string {
  const originAcronym = findOriginAcronym(capInfo);
  if (!originAcronym) {
    return '';
  }
  const originator = acronymToOriginatorMap[originAcronym] || '';
  const area = capInfo.areasDescriptions.join(',');
  const expiration = formatExpirationDate(capInfo);
  return `A ${originator} has issued a ${capInfo.event} for ${area}, effective until ${expiration}. ${capInfo.description} ${capInfo.instruction}`;
}

function formatMessage(capInfo: CAPInfo): string {
  const easType = nxffConfig.getConfig().EAS.EasType;
  return easType === 'USA'
    ? capInfo.params.get(easTextParamName) || formatUSAMessage(capInfo)
    : capInfo.params.get(broadcastTextParamName) || '';
}

function findBroadcastAudio(capInfo: CAPInfo): Audio[] {
  const broadcastAudioResource = capInfo.resources.find((capResource) =>
    broadcastAudioDescriptions.includes(capResource.description) &&
    broadcastAudioMimeTypes.includes(capResource.mimeType) &&
    (capResource.uri || capResource.derefUri)
  );
  // there is no broadcast audio or uri and derefUri are missing
  const broadcastAudio: Audio[] = [];
  if (!broadcastAudioResource) {
    return broadcastAudio;
  }
  // prefer the use of uri as the main audio but provide the derefUri as a fallback
  if (broadcastAudioResource.uri) {
    broadcastAudio.push(new AudioObject('', broadcastAudioResource.description, broadcastAudioResource.uri));
  }
  if (broadcastAudioResource.derefUri) {
    broadcastAudio.push(new AudioObject('', broadcastAudioResource.description, broadcastAudioResource.derefUri));
  }
  return broadcastAudio;
}

function deregionalizeLanguage(language: string): string {
  return language.replace(/[-_].*/, '');
}

function findUILanguage(capInfo: CAPInfo): string {
  // first look for an exact match, otherwise try a deregionalized languages
  const find = (language: string, deregionalized: boolean): string => {
    const comparableLanguage = deregionalized ? deregionalizeLanguage(language) : language;
    return mw.configuration.uiLanguages.find((uiLanguage) => {
      const comparableUILanguage = deregionalized ? deregionalizeLanguage(uiLanguage) : uiLanguage;
      return comparableUILanguage === comparableLanguage;
    }) || '';
  };
  return find(capInfo.language, false) || find(capInfo.language, true);
}

function sortEASInfos(easInfos: EASInfo[]): EASInfo[] {
  const uiLanguage = mw.configuration.uiLanguage;
  if (!uiLanguage) {
    Log.warn(TAG, 'Unable to sort EAS infos - UI language is not set');
    return easInfos;
  }
  const matchedIndex = easInfos.findIndex(easInfo => easInfo.uiLanguage === uiLanguage);
  if (matchedIndex === -1) {
    Log.warn(TAG, `Unable to find EAS info with language that would match currently used UI language ${uiLanguage}`);
    return easInfos;
  }
  Log.debug(TAG, `Found EAS info matching currently used UI language ${uiLanguage}`);
  const [matchedEASInfo] = easInfos.splice(matchedIndex, 1);
  easInfos.unshift(matchedEASInfo);
  return easInfos;
}

function createEASInfo(capInfo: CAPInfo): EASInfo {
  const easInfo = new EASInfo(capInfo);
  easInfo.message = formatMessage(capInfo);
  easInfo.uiLanguage = findUILanguage(capInfo);
  if (!easInfo.uiLanguage) {
    Log.warn(TAG, `Unable to find UI language that would be most suitable for CAP info in language ${capInfo.language}`);
    easInfo.uiLanguage = capInfo.language; // pass through the CAP info's language and let UI handle it in the best possible way
  }
  easInfo.broadcastAudio = findBroadcastAudio(capInfo);
  return easInfo;
}

function createAttentionSignal(type: AttentionSignalType): Audio | undefined {
  const attentionSignalURL = getAssetURL(type === AttentionSignalType.Pre ? nxffConfig.getConfig().EAS.EasPreAttentionSignalURL : nxffConfig.getConfig().EAS.EasPostAttentionSignalURL);
  return attentionSignalURL
    ? new AudioObject('', 'EASAttentionSignal', attentionSignalURL)
    : undefined;
}

function createEASAlert(capMessage: CAPMessage): EASAlert | null {
  const easInfos = capMessage.infos.map(createEASInfo);
  if (easInfos.length === 0) {
    Log.warn(TAG, `Unable to create EAS alert from CAP message ${capMessage.id} - there are no CAP infos`);
    return null;
  }
  const easAlert = new EASAlert(capMessage.id, sortEASInfos(easInfos));
  easAlert.preAttentionSignal = createAttentionSignal(AttentionSignalType.Pre);
  easAlert.postAttentionSignal = createAttentionSignal(AttentionSignalType.Post);
  return easAlert;
}

export enum EASEvent {
  AlertReceived = 'AlertReceived',
  AlertUpdated = 'AlertUpdated'
}

export class EAS extends EventEmitter<EASEvent, EASAlert> {
  private initialized = false;
  private easAlerts: EASAlert[] = [];
  private easAlertsIndices = new Map<string, number>();

  public constructor() {
    super();
  }

  public async initialize(): Promise<void> {
    if (!nxffConfig.getConfig().EAS.EnabledEas) {
      return;
    }
    boProxy.notificationsManager?.on(NotificationEvent.NotificationReceived, this.onNotificationReceived);
    this.initialized = true;
  }

  public uninitialize(): void {
    if (!this.initialized) {
      return;
    }
    boProxy.notificationsManager?.off(NotificationEvent.NotificationReceived, this.onNotificationReceived);
    this.initialized = false;
  }

  public confirmViewed(easAlert: EASAlert): EASAlert | undefined {
    this.removeEASAlertById(easAlert.id);
    return this.easAlerts[0];
  }

  private processEASAlert(easAlert: EASAlert): void {
    const index = this.easAlertsIndices.get(easAlert.id) ?? this.easAlerts.length;
    const removed = this.easAlerts.splice(index, 1, easAlert);
    this.easAlertsIndices.set(easAlert.id, index);
    if (removed.length > 0) {
      Log.debug(TAG, `Updated EAS alert ${easAlert.id}`);
      this.notify(EASEvent.AlertUpdated, easAlert);
    } else {
      Log.debug(TAG, `Received EAS alert ${easAlert.id}`);
      this.notify(EASEvent.AlertReceived, easAlert);
    }
  }

  private processCAPMessage = (capMessage: CAPMessage): void => {
    Log.debug(TAG, `Got CAP message ${capMessage.id}`);
    const easAlert = createEASAlert(capMessage);
    if (!easAlert) {
      Log.warn(TAG, `Skipping from processing CAP message ${capMessage.id} - failed to create EAS alert from it`);
      return;
    }
    this.processEASAlert(easAlert);
  }

  private processCAPNotification(notification: SEACNotification): void {
    Log.debug(TAG, `Requesting CAP message ${notification.instanceId}`);
    boProxy.bo.getCAPMessage({
      id: notification.instanceId,
      languages: nxffConfig.getConfig().EAS.EasLanguages || mw.configuration.uiLanguages
    }).then(this.processCAPMessage);
  }

  private removeEASAlertById = (id: string): void => {
    // remove the alert from the queue
    const index = this.easAlertsIndices.get(id) ?? -1;
    if (index === -1) {
      return;
    }
    this.easAlerts.splice(index, 1);
    // remove all ids under which this alert was previously known and updates the ones that have been queued after it
    const removeIds: string[] = [];
    const updateIds: string[] = [];
    this.easAlertsIndices.forEach((easAlertIndex, easAlertId) => {
      if (easAlertIndex === index) {
        removeIds.push(easAlertId);
      } else if (easAlertIndex > index) {
        updateIds.push(easAlertId);
      }
    });
    removeIds.forEach((id) => {
      this.easAlertsIndices.delete(id);
    });
    updateIds.forEach((id) => {
      const index = this.easAlertsIndices.get(id);
      if (!!index) {
        this.easAlertsIndices.set(id, index - 1);
      }
    });
  }

  private removeCAPNotification(notification: SEACNotification): void {
    notification.reference.forEach(this.removeEASAlertById);
  }

  private onNotificationReceived = (notification?: Notification): void => {
    if (!isCAPNotification(notification)) {
      Log.info(TAG, `Received not a CAP message ${notification?.instanceId} - ignoring it`);
      return;
    }
    Log.debug(TAG, `Received notification about new CAP message ${notification.instanceId}`);
    switch (notification.actionType) {
      case ActionType.New:
      case ActionType.Update:
        this.processCAPNotification(notification);
        break;
      case ActionType.Cancel:
        this.removeCAPNotification(notification);
        break;
      default:
        Log.warn(TAG, `Ignoring unsupported type of operation on the incoming CAP message ${notification.instanceId}`);
    }
  };
}
