import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {View, ViewStyle} from 'react-native';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

export const progressBarHeight = 2;
const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flexDirection: 'row',
    height: progressBarHeight
  },
  progress: {
    backgroundColor: colors.progressBar.default.progress.current
  },
  background: {
    backgroundColor: colors.progressBar.default.progress.remaining
  }
}));

type Props = {
  progress: number;
  style?: ViewStyle | ViewStyle[];
  progressColor?: string;
  backgroundColor?: string;
};

const MiniProgressBar: React.FunctionComponent<Props> = props => {
  let progress = props.progress;
  if (isNaN(progress)) { //TODO: CL-1479 there should be no NAN
    progress = 0;
  }

  const styles = stylesUpdater.getStyles();
  const progressStyle = useMemo(() => ({
    ...styles.progress,
    ...props.progressColor ? {backgroundColor: props.progressColor} : (undefined),
    flex: progress
  }), [progress, props.progressColor, styles.progress]);

  const backgroundStyle = useMemo(() => ({
    ...styles.background,
    ...props.backgroundColor ? {backgroundColor: props.backgroundColor} : (undefined),
    flex: 1 - progress
  }), [styles.background, props.backgroundColor, progress]);

  return (
    <View style={[styles.container, props.style]}>
      <View style={progressStyle} />
      <View style={backgroundStyle} />
    </View>
  );
};

export default React.memo(MiniProgressBar);
