import {useMemo} from 'react';

import {getTime} from 'common/utils';

import {mw} from 'mw/MW';

import {useNowDate} from './useNowDate';

/**
 * Returns formatted time given from current MW configuration.
 * Updated in constant intervals.
 * @param interval Defaults to 30 secs.
 */
export function useCurrentTime(interval = 30) {
  const nowDate = useNowDate(interval);
  return useMemo(
    () => getTime(nowDate, mw.configuration.timeFormat),
    [nowDate]
  );
}
