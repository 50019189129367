import React from 'react';

import {isMobile} from 'common/constants';

import PromotionalBannerDetailsGrosso from './PromotionalBannerDetails.grosso';
import PromotionalBannerDetailsPiccolo from './PromotionalBannerDetails.piccolo';
import {PromotionalBannerDetailsProps} from './PromotionalBannerDetails.shared';

const PromotionalBannerDetails: React.FC<PromotionalBannerDetailsProps> = props => {
  return isMobile ? <PromotionalBannerDetailsPiccolo {...props} /> : <PromotionalBannerDetailsGrosso {...props} />;
};

export default PromotionalBannerDetails;
