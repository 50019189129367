import {createStyles} from 'common-styles';
import React from 'react';
import {ImageBackground, StyleSheet} from 'react-native';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {assets} from 'brand/Resources';

const styles = new StylesUpdater((colors: BaseColors) => createStyles({
  background: {
    backgroundColor: colors.loginScreen.background,
    ...StyleSheet.absoluteFillObject
  }
}));

const SplashBackground: React.FC = ({children}) => {
  const style = styles.getStyles();

  return (
    <ImageBackground
      style={style.background}
      source={assets.common.splashScreen}
      resizeMode='cover'
    >
      {children}
    </ImageBackground>
  );
};

export default SplashBackground;
