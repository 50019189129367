import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import BuildConfig from 'common/BuildConfig';
import {dimensions, isTablet, isMobile, isBigScreen} from 'common/constants';
import {compactMap} from 'common/HelperFunctions';

import {Title, Content} from 'mw/api/Metadata';

import ButtonsRow from 'components/ButtonsRow';
import {NitroxButtonTheme} from 'components/NitroxButton';
import Popup, {PopupAction} from 'components/Popup';

const buttonWidth = isTablet ? 200 : 250;
const emptyActionsArray: PopupAction[] = [];

const styles = createStyles({
  title: {
    marginBottom: dimensions.margins.xxLarge
  },
  container: {
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});

type Props = {
  visible: boolean;
  title?: Title;
  onPress: (title: Title, content?: Content) => void;
  onClose: () => void;
}

const WatchOnPopup: React.FunctionComponent<Props> = props => {
  const {onClose, onPress, visible, title} = props;
  const {t} = useTranslation();

  const items: any[] = useMemo(() => {
    if (!title) {
      return [];
    }
    const items: any[] = [];
    const contents = title.contents.filter(content => content.isAllowed());

    if (contents.some(content => !content.externalLink)) {
      const name = isMobile ? BuildConfig.name.mobile : BuildConfig.name.bigScreen;
      items.push({
        text: name,
        onPress: () => onPress(title),
        key: name
      });
    }

    items.push(...compactMap(contents, content => {
      const externalLink = content.externalLink;
      if (externalLink) {
        return {
          text: externalLink.provider,
          onPress: () => onPress(title, content),
          key: externalLink.provider
        };
      }
    }));

    return items;
  }, [title, onPress]);

  return (
    <Popup
      visible={visible}
      title={t('deepLinking.watchOn')}
      titleStyle={styles.title}
      containerStyle={styles.container}
      onClose={onClose}
      actions={emptyActionsArray}
    >
      <ButtonsRow
        data={items}
        columns={isTablet ? 3 : 1}
        buttonWidth={buttonWidth}
        buttonTheme={NitroxButtonTheme.Primary}
        xMargin={dimensions.margins.xxLarge}
        yMargin={dimensions.margins.large}
        buttonBorder={isBigScreen}
        centerFirstRow
      />
    </Popup>
  );
};

export default React.memo(WatchOnPopup);
