import {NxfdSections} from 'mw/common/NXFFTypes';

export class NxfdMapper {

  public static nxfdSectionsFromJson(json: any): NxfdSections {
    let config: NxfdSections = {};
    if (json.configuration) {
      config = JSON.parse(json.configuration);

      NxfdMapper.removeEmpty(config);
    }
    return config;
  }

  private static removeEmpty(obj: any): void {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') {
        NxfdMapper.removeEmpty(obj[key]);
      }
      if (NxfdMapper.isEmpty(obj[key])) {
        delete obj[key];
      }
    });
  }

  private static isEmpty(obj: any): boolean {
    return (obj == null || obj === '')
      || (Array.isArray(obj) && obj.length === 0)
      || (typeof obj === 'object' && !Object.keys(obj).length);
  }
}
