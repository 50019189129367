import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {View} from 'react-native';

import {dimensions} from 'common/constants';

import {Channel, Event} from 'mw/api/Metadata';

import {ChannelIcon, ChannelIconType} from 'components/ChannelIcon';
import ChannelNumber, {channelNumberSize} from 'components/ChannelNumber';
import ShortDetailsView, {getZapperMetadata} from 'components/ShortDetailsView';

const styles = createStyles({
  details: {
    width: '80%',
    position: 'absolute',
    top: '10%',
    left: '10%',
    flexDirection: 'row',
    alignItems: 'center'
  },
  shortDetails: {
    flex: 1
  },
  channelIcon: {
    marginRight: dimensions.margins.xxxLarge,
    alignSelf: 'stretch',
    alignItems: 'center',
    height: 100,
    width: 100
  },
  channelNumber: {
    position: 'absolute',
    bottom: -Math.floor(channelNumberSize / 2)
  }
});

type EventShortDetailsProps = {
  channel?: Channel;
  event?: Event;
}

const EventShortDetails: React.FC<EventShortDetailsProps> = ({
  channel,
  event
}) => {
  const metadata = useMemo(() => event && getZapperMetadata(event), [event]);

  return (
    <View style={styles.details} testID='event_short_details'>
      {channel && (
        <ChannelIcon
          type={ChannelIconType.Zapper}
          channelId={channel.id}
          style={styles.channelIcon}
        >
          <ChannelNumber channel={channel} styles={styles.channelNumber} />
        </ChannelIcon>
      )}
      {metadata && event?.channelId === channel?.id && (
        <ShortDetailsView
          style={styles.shortDetails}
          landscape
          title={metadata.title}
          focusable={false}
          isBlocked={metadata.isBlocked}
          heading={metadata.heading}
          headingTextType='callout'
        />
      )}
    </View>
  );
};

export default EventShortDetails;
