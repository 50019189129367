import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {View} from 'react-native';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import DoubleBackground from 'components/DoubleBackground';
import FocusParent from 'components/FocusParent';
import {useToggleParentalControl} from 'screens/settings/profileWizard/WizardHooks';

import {WizardParentalControlRatingsPageProps} from './WizardParentalControlRatingsPage.shared';
import WizardParentalControlRatingsSettings from './WizardParentalControlRatingsSettings';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  topChild: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  topChildEmbedded: {
    backgroundColor: colors.parallelNavigator.column.right
  },
  embeddedBottomChild: {
    paddingHorizontal: dimensions.screen.container.paddingHorizontal
  },
  bottomChild: {
    alignItems: 'stretch',
    marginTop: dimensions.wizard.configScreen.doubleBackgroundOverlap + dimensions.margins.large,
    paddingHorizontal: dimensions.wizard.configScreen.horizontalPadding
  },
  actions: {
    alignItems: 'center',
    marginTop: dimensions.margins.xxxxLarge
  }
}));

const WizardParentalControlRatingsPage: React.FC<WizardParentalControlRatingsPageProps> = props => {
  const {header, tracker, forwardButton, embedded, parentalControl: propsParentalControl, onParentalChanged, pinState, onPinChanged, yearOfBirth} = props;
  const styles = stylesUpdater.getStyles();
  const topChildStyle = useMemo(() => [styles.topChild, embedded && styles.topChildEmbedded], [styles.topChild, embedded, styles.topChildEmbedded]);
  const bottomChildStyle = useMemo(() => embedded ? styles.embeddedBottomChild : styles.bottomChild, [embedded, styles]);

  const {parentalControl, toggleParentalControl, renderPopup} = useToggleParentalControl({
    parentalControl: propsParentalControl,
    onParentalControlChanged: onParentalChanged,
    pinState,
    setPin: onPinChanged,
    validateYearOfBirth: true,
    yearOfBirth
  });

  return (
    <DoubleBackground
      overlap={dimensions.wizard.configScreen.doubleBackgroundOverlap}
      topStyle={topChildStyle}
      topChild={(
        <View>
          {header}
          {tracker}
        </View>
      )}
      bottomStyle={bottomChildStyle}
      bottomChild={(
        <>
          <FocusParent rememberLastFocused>
            <WizardParentalControlRatingsSettings
              {...props}
              parentalControl={parentalControl}
              toggleParentalControl={toggleParentalControl}
            />
          </FocusParent>
          {forwardButton && (
            <FocusParent style={styles.actions}>
              {forwardButton}
            </FocusParent>
          )}
          {renderPopup()}
        </>
      )}
    />
  );
};

export default React.memo(WizardParentalControlRatingsPage);
