import {useRef, useEffect, useCallback} from 'react';
import {Animated, ImageURISource} from 'react-native';

import {useDisposableState} from './Hooks';

type UseImageFadeAnimation = {
  source: Pick<ImageURISource, 'uri'>;
  onLoadEnd: () => void;
  opacity: Animated.Value;
}

/**
 * Use this hook, to animate changing source property of Image component with a fade out - fade in style animation.
 * @param uri image URL
 * @returns object containing:
 *  - source: source property to be passed to Image component
 *  - onLoadEnd: onLoadEnd callback to be passed to Image component
 *  - opacity: opacity animated value to be used to style Image component
 */
export function useImageFadeAnimation(uri: string | undefined): UseImageFadeAnimation {
  const opacity = useRef(new Animated.Value(0));
  const animation = useRef<Animated.CompositeAnimation | undefined>();
  const [source, setSource] = useDisposableState<UseImageFadeAnimation['source']>({uri});

  useEffect(() => {
    if (!uri) {
      return;
    }
    animation.current?.stop();
    animation.current = Animated.timing(opacity.current, {
      toValue: 0,
      duration: 200
    });
    animation.current?.start(() => {
      setSource({uri});
    });
  }, [setSource, uri]);

  const onLoadEnd = useCallback(() => {
    animation.current?.stop();
    animation.current = Animated.timing(opacity.current, {
      toValue: 1,
      duration: 600
    });
    animation.current?.start();
  }, []);

  return {
    source,
    onLoadEnd,
    opacity: opacity.current
  };
}
