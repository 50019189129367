import {createStyles} from 'common-styles';
import React, {useCallback, useState, useMemo} from 'react';
import {View, StyleSheet, ScrollView, Animated, LayoutChangeEvent, ViewStyle, StyleProp, TextStyle, ActivityIndicator} from 'react-native';

import {RADIUS, dimensions, Direction} from 'common/constants';
import {humanCaseToSnakeCase} from 'common/HelperFunctions';
import {AnimatedStyle} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors, constColors} from 'common-styles/variables/base-colors';

import SwipeTrack, {SwipeTrackChildrenProps} from 'components/gestures/SwipeTrack';
import {useScreenInfo} from 'hooks/Hooks';

import GradientFill from './GradientFill';
import {Icon, IconType} from './Icon';
import {ModalProps, Modal, ModalCloseBar} from './Modal';
import NitroxButton, {ButtonIconProps} from './NitroxButton';
import NitroxText, {TextType} from './NitroxText';

export type ModalSelectOption<T> = {
  value: T;
  label: string;
  key: string;
  style?: StyleProp<ViewStyle>;
  buttonIcon?: ButtonIconProps;
  buttonTextStyle?: StyleProp<TextStyle>;
  buttonTextType?: TextType;
}

export type ModalSelectProps<T> = {
  options: ModalSelectOption<T>[];
  /** How many rows should be displayed in the modal */
  maxRows?: number;
  portal?: string;
  fullscreen?: boolean;
  loading?: boolean;
  swipeable?: boolean;
  title?: string;
  selectedOptionIcon?: IconType;
  defaultValue?: T;
  onChange?: (value: T) => any;
  onClose?: () => void;
  value?: T;
  bottomSection?: React.ReactNode;
  renderOptionIcon?: (value: T) => JSX.Element;
  renderButtonChild?: (value: T) => JSX.Element;
}

const iconSize = dimensions.icon.small;
const modalMaxHeight = 330;
const optionHeight = dimensions.icon.small + 2 * dimensions.margins.small;
const selectRows = 4.5;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  modal: {
    paddingBottom: 0
  },
  modalHeader: {
    paddingTop: dimensions.margins.small,
    paddingBottom: dimensions.margins.large,
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    color: colors.popup.text,
    textAlign: 'center',
    paddingHorizontal: dimensions.margins.small
  },
  modalContent: {
    justifyContent: 'center',
    alignItems: 'stretch',
    borderRadius: RADIUS,
    alignSelf: 'stretch',
    overflow: 'hidden'
  },
  modalScrollable: {
    width: '100%',
    flexGrow: 0,
    flexShrink: 1
  },
  option: {
    backgroundColor: constColors.transparent,
    flex: 1,
    paddingLeft: dimensions.margins.medium,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: optionHeight,
    paddingHorizontal: dimensions.margins.large,
    paddingVertical: dimensions.margins.small
  },
  optionButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  scrollViewContainer: {
    marginBottom: dimensions.margins.large
  },
  selectedIcon: {
    marginRight: dimensions.margins.medium
  },
  text: {
    color: colors.popup.text,
    alignSelf: 'flex-start',
    paddingHorizontal: dimensions.margins.medium
  },
  modalSelectGradient: {
    top: colors.popup.overlay.background,
    bottom: colors.popup.overlay.backgroundTransparent
  },
  gradientFill: colors.popup.gradient
}));

const closeGestureThreshold = 50;

export function ModalSelect<T>(props: ModalSelectProps<T> & ModalProps) {
  const {
    maxRows,
    options,
    selectedOptionIcon,
    visible = true,
    fullscreen = false,
    loading = false,
    swipeable = true,
    onClose = () => {},
    defaultValue,
    onChange = () => {},
    value = defaultValue,
    title,
    portal,
    bottomSection,
    renderOptionIcon,
    renderButtonChild
  } = props;

  const [contentHeight, setContentHeight] = useState<number>();
  const modalStyle = useCallback((positionDelta: Animated.Value): AnimatedStyle<ViewStyle> => {
    if (!contentHeight) {
      return {};
    }
    const styles = stylesUpdater.getStyles();
    return {
      backgroundColor: positionDelta.interpolate({
        inputRange: [0, contentHeight],
        outputRange: [styles.modalSelectGradient.top, styles.modalSelectGradient.bottom]
      })
    };
  }, [contentHeight]);

  const screenInfo = useScreenInfo();
  const handleClose = useCallback(() => onClose(value), [onClose, value]);

  const contentSize = useMemo(() => ({
    width: screenInfo.size.width,
    height: fullscreen ? screenInfo.size.height : undefined,
    maxHeight: fullscreen ? undefined : modalMaxHeight
  }), [screenInfo.size.width, screenInfo.size.height, fullscreen]);

  if (!visible) {
    return null;
  }

  const styles = stylesUpdater.getStyles();
  return (
    <SwipeTrack
      swipeOrigin={Direction.Up}
      onCloseGesture={handleClose}
      closeOnAnchorTap
      flyAwayDistance={contentHeight || modalMaxHeight}
      closeGestureThreshold={closeGestureThreshold}
    >
      {swipeProps => (
        <Modal
          onClose={handleClose}
          visible={visible}
          contentStyle={contentSize}
          portal={portal}
          style={[
            styles.modal,
            modalStyle(swipeProps.positionDelta)
          ]}
        >
          <ModalSelectContent
            maxRows={maxRows}
            onChange={onChange}
            value={value}
            fullscreen={fullscreen}
            loading={loading}
            options={options}
            selectedOptionIcon={selectedOptionIcon}
            title={title}
            onHeightChange={setContentHeight}
            bottomSection={bottomSection}
            renderOptionIcon={renderOptionIcon}
            renderButtonChild={renderButtonChild}
            {...swipeable && swipeProps}
          />
        </Modal>
      )}
    </SwipeTrack>
  );
}

type SelectedIconProps = {
  type?: IconType;
}
const SelectedIcon: React.FC<SelectedIconProps> = ({type}) => (
  <View style={stylesUpdater.getStyles().selectedIcon}>
    {type ? <Icon type={type} size={iconSize} /> : <View style={{width: iconSize}} />}
  </View>
);

type ModalSelectContentProps<T> = Pick<ModalSelectProps<T>, 'maxRows' | 'loading' | 'options' | 'selectedOptionIcon' | 'onChange' | 'value' | 'title' | 'fullscreen' | 'bottomSection' | 'renderOptionIcon' | 'renderButtonChild'>
& Partial<SwipeTrackChildrenProps>
& {
  onHeightChange: (height: number) => void;
};

function ModalSelectContent<T>(props: ModalSelectContentProps<T>) {
  const {
    maxRows,
    onChange = () => {},
    selectedOptionIcon: propsSelectedOptionIcon,
    options,
    value: propsValue,
    fullscreen = false,
    loading = false,
    title,
    panResponder,
    positionDelta,
    onHeightChange,
    bottomSection,
    renderOptionIcon,
    renderButtonChild
  } = props;

  const onContainerLayout = useCallback(({nativeEvent: {layout: {height}}}: LayoutChangeEvent) => {
    onHeightChange(height);
  }, [onHeightChange]);

  const styles = stylesUpdater.getStyles();
  return (
    <Animated.View
      style={[
        styles.modalContent,
        fullscreen ? [StyleSheet.absoluteFill, {paddingVertical: dimensions.margins.xxxLarge}] : {},
        typeof positionDelta !== 'undefined' ? {transform: [
          {translateY: positionDelta}
        ]} : {}
      ]}
      onLayout={onContainerLayout}
    >
      <GradientFill colors={styles.gradientFill} />
      <View {...panResponder && panResponder.panHandlers} collapsable={false}>
        {!!panResponder && (
          <ModalCloseBar />
        )}
        {title && (
          <View style={styles.modalHeader}>
            <NitroxText textType='dialog-title' style={styles.title}>{title}</NitroxText>
          </View>
        )}
      </View>
      <View style={[styles.scrollViewContainer, {maxHeight: Math.round((maxRows || selectRows) * optionHeight)}]}>
        <ActivityIndicator animating={loading} />
        {!loading && (
          <ScrollView alwaysBounceVertical={false} showsVerticalScrollIndicator={false} style={styles.modalScrollable}>
            {options.map(({
              value: optionValue,
              label,
              key,
              style: optionStyle,
              buttonIcon,
              buttonTextStyle,
              buttonTextType = 'options-texts'
            }) => (
              <NitroxButton
                key={key}
                icon={buttonIcon}
                style={[
                  styles.option,
                  optionStyle
                ]}
                testID={label && `button_${humanCaseToSnakeCase(label)}`}
                onPress={() => onChange(optionValue)}
              >
                <View style={styles.optionButtonContainer}>
                  {propsSelectedOptionIcon && <SelectedIcon type={optionValue === propsValue ? propsSelectedOptionIcon : undefined} />}
                  {optionValue && renderOptionIcon?.(optionValue)}
                  <View>
                    <NitroxText numberOfLines={1} textType={buttonTextType} style={[styles.text, buttonTextStyle]}>{label}</NitroxText>
                    {optionValue && renderButtonChild?.(optionValue)}
                  </View>
                </View>
              </NitroxButton>
            ))}
          </ScrollView>
        )}
      </View>
      {bottomSection}
    </Animated.View>
  );
}
