import {createStyles} from 'common-styles';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View, ViewStyle, GestureResponderEvent} from 'react-native';

import {dimensions, getValue, featureFlags} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import ChannelsListsMenu from 'components/ChannelsListsMenu';
import {Icon, IconType} from 'components/Icon';
import NitroxText from 'components/NitroxText';

const barContainerPadding = getValue({mobile: dimensions.margins.large, tablet: dimensions.margins.large, defaultValue: 75});
const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  barItemContainer: {
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: 50
  },
  barItemText: {
    marginLeft: dimensions.margins.small,
    color: colors.epgScreen.grid.bar.text
  },
  barItemButton: {
    marginLeft: dimensions.margins.small
  },
  channelsListsBarItem: {
    position: 'absolute',
    right: barContainerPadding
  }
}));

type ChannelsListsBarItemProps = {
  style?: ViewStyle;
  activatable?: boolean;
  showJumpToNowButton?: boolean;
  onChannelsListPress?: (channelsListId: string, event?: GestureResponderEvent) => void;
  onJumpToNowPress?: (event?: GestureResponderEvent) => void;
};

const ChannelsListsBarItem: React.FC<ChannelsListsBarItemProps> = props => {
  const {t} = useTranslation();
  const styles = stylesUpdater.getStyles();

  if (props.activatable) {
    return <ChannelsListsMenu {...props}></ChannelsListsMenu>;
  }
  return (featureFlags.channelLists ? (
    <View style={[styles.barItemContainer, props.style]}>
      <Icon type={IconType.ChannelsList} size={31} />
      <NitroxText style={styles.barItemText} textType='callout'>{t('epg.allChannels')}</NitroxText>
    </View>
  ) : null
  );
};

export default React.memo(ChannelsListsBarItem);
