import {DateUtils} from 'common/DateUtils';
import {compactMap} from 'common/HelperFunctions';
import {Log} from 'common/Log';
import {makeArray} from 'common/utils';

import {InvalidMedia} from 'mw/api/InvalidMedia';
import {Content, EntitlementState, Media, Product} from 'mw/api/Metadata';
import {ContentObject} from 'mw/metadata/ContentObject';
import {ExternalContentObject} from 'mw/metadata/ExternalContentObject';
import {deviceInfo} from 'mw/platform/device-info/DeviceInfo';
import {PlatformTrackSelector} from 'mw/playback/sessions/PlatformTrackSelector';
import {PlaybackStream} from 'mw/playback/sessions/PlaybackSessionManager';

import {AssetTypes} from './constants';
import {SeasonMapper} from './SeasonMapper';
import {SeriesMapper} from './SeriesMapper';
import {TitleMapper} from './TitleMapper';

const TAG = 'ContentMapper';

interface Adr8DeepLinkInterface {
  id: string;
  sourceUrl?: string;
  metadata: {
    type?: string;
    providerName?: string;
    devices?: string[];
  }
}

export type TitleParams = {
  products: Product[];
  entitlementState: EntitlementState;
  entitlementEnd: Date;
}

export class ContentMapper {
  public static mediaFromJson(contentJson: any): Media {
    const {assetType} = contentJson;
    switch (assetType) {
      case AssetTypes.Movie:
      case AssetTypes.Episode:
        return TitleMapper.titleFromJson(contentJson);
      case AssetTypes.Season:
        return SeasonMapper.seasonFromJson(contentJson);
      case AssetTypes.Series:
        return SeriesMapper.seriesFromJson(contentJson);
      default:
        Log.debug(TAG, 'Not supported type: ', assetType);
    }

    return new InvalidMedia();
  }

  public static contentsFromTitleJson(json: any, params: TitleParams): Content[] {
    const content = ContentMapper.contentFromTitleJson(json, params);
    const contents: Content[] = content ? [content] : [];

    const deepLinks = compactMap(makeArray(json.externalResources?.deepLink), (deepLink: Adr8DeepLinkInterface) => {
      if (Array.isArray(deepLink.metadata?.devices) && deepLink.metadata.devices.includes(deviceInfo.deviceType)) {
        return ContentMapper.externalContentFromTitleJson(deepLink);
      }
      return null;
    });

    if (deepLinks.length > 0) {
      contents.push(...deepLinks);
    }

    return contents;
  }

  private static contentFromTitleJson(json: any, params: TitleParams): Content | null {
    let id = '';
    let duration = 0;
    if (json.externalResources && json.externalResources.stream) {
      const streams: PlaybackStream[] = json.externalResources.stream.map((stream: any) => new PlaybackStream(stream.id, stream.metadata.streamType, stream.length, stream.metadata.drmType));
      const stream = PlatformTrackSelector.selectStream(streams);
      if (stream) {
        id = stream.uid;
        duration = (stream.duration || json.duration) ?? 0;
      }
    }
    if (!id) {
      return null;
    }
    // for now we only support buying single titles there also is no way to get all media assigned to product
    // TODO: this is a temporary workaround until CL-5336 is ready and dedicated call can be used
    // TODO: CL-5713 Set entitlemnt state dynamically
    const {products, entitlementState, entitlementEnd} = params;
    const content = new ContentObject(id, products, entitlementState);
    content.duration = duration;
    const now = new Date();
    content.entitlementEnd = entitlementEnd;
    content.lastAvailability = content.entitlementEnd;
    content.firstAvailability = DateUtils.addHours(now, -24);
    return content;
  }

  private static externalContentFromTitleJson(deepLink: Adr8DeepLinkInterface): Content | null {
    if (!deepLink?.sourceUrl || !deepLink?.metadata?.providerName) {
      return null;
    }
    const content = new ExternalContentObject(deepLink.id, [], EntitlementState.Entitled);
    content.externalLink = {
      url: deepLink.sourceUrl,
      provider: deepLink.metadata.providerName
    };
    content.entitlementEnd = DateUtils.addHours(new Date(), 24);
    content.lastAvailability = content.entitlementEnd;
    content.firstAvailability = DateUtils.addHours(new Date(), -24);
    return content;
  }
}
