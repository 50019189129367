import {PlayerEvent, PositionChanged} from 'mw/api/PlayerEvent';
import {PlayerType} from 'mw/playback/types/PlayerType';
import {StreamPosition} from 'mw/playback/web-native-player/nxpal/StreamPosition';

import {WebPlaybackEvent} from './WebPlaybackEvent';

export class PlayerPositionEvent extends WebPlaybackEvent {

  public positionChanged: PositionChanged;

  public constructor(playerType: PlayerType, streamId: number, streamPosition: StreamPosition) {
    super(PlayerEvent.PositionChanged, playerType, streamId);

    this.positionChanged = {...streamPosition};
  }
}
