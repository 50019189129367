import React from 'react';

import AccountActivationScreen from 'web/src/accountActivation/AccountActivationScreen';

import DefaultRoute from './DefaultRoute';
import {KnownRoutes, isKnownRoute, useHashPath} from './routerUtils';
import UnknownRoute from './UnknownRoute';

// note: order matters, first match is rendered
const webRoutes = [
  {
    match: (hashPath: string) => hashPath.startsWith(KnownRoutes.ActivateAccount),
    Component: AccountActivationScreen
  },
  {
    match: (hashPath: string) => hashPath && !isKnownRoute(hashPath),
    Component: UnknownRoute
  }
];

const router = {
  DefaultRoute,
  routes: webRoutes
};

export const WebRouter: React.FC = () => {
  const path = useHashPath();

  const Route = router
    .routes
    .find(route => route.match(path))?.Component
  ?? router.DefaultRoute;

  return <Route />;
};
