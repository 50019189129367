import moment from 'moment';

import {DateUtils} from 'common/DateUtils';
import {compactMap, flatten, getMediaSubtypes, intoBrackets, isTruthy} from 'common/HelperFunctions';
import {Log} from 'common/Log';
import {makeArray} from 'common/utils';

import {EPGParams, SearchField, SearchParameters} from 'mw/api/CatalogInterface';
import {Device} from 'mw/api/Device';
import {DeviceManager} from 'mw/api/DeviceManager';
import {CAPQueryParameters} from 'mw/api/EASMetadata';
import {Error, ErrorType} from 'mw/api/Error';
import {Filter} from 'mw/api/Filter';
import {
  AvailabilityInTime,
  Channel,
  ChannelList,
  EpgFilter,
  Event,
  Media,
  MediaType,
  PVRQueryParameters,
  PVRRecordingScope,
  PVRScheduleParameters,
  Recording,
  RecordingStatus,
  RecordingType,
  SortRecordingsBy,
  SortVodBy,
  VodFilter,
  VodSorting,
  ChannelSorting,
  SortChannelBy,
  PVRUpdateParameters,
  isEvent,
  isSeries
} from 'mw/api/Metadata';
import {nxff, nxffConfig} from 'mw/api/NXFF';
import {boProxy} from 'mw/bo-proxy/BOProxy';
import {convertJSDateToJSONDate, convertSecondsToScorm} from 'mw/common/utils';
import {CategoryLimits, RecommendationsLimits} from 'mw/Constants';
import {mw, PCType} from 'mw/MW';
import {PlaybackSession, SessionTerminationReason} from 'mw/playback/sessions/PlaybackSessionManager';
import {HttpMethods} from 'mw/utils/HttpUtils';

import {Filters} from './Filters';
import {DeviceIdentity} from './Identities';
import {RecordingMapper} from './mappers/RecordingMapper';
import {queryProps} from './queryProps';
import {Arguments, ArgumentsQuery, queryUtils, RequestQuery, Resources as ActionQueryResources, ExtendedRelationQuery, ResourcesParams, RelationSubQueryAttributes, ResourceOptions} from './queryUtils';
import {resources} from './resources';
import {
  BookmarkActions,
  ChannelListAction,
  CpeAction,
  CustomerAction,
  LanguageType,
  ProductAction,
  ProfileAction,
  Props,
  RecordingsAction,
  Relations,
  Resources,
  ResponseJson,
  RootActions,
  SortByOptions,
  WatchListAction
} from './TraxisTypes';

const TAG = 'TraxisQueries';

const DefaultRecordingsPageSize = 20;

const searchResultsLimits = {
  epgEvents: 1,
  epgTitles: 33,
  recordedTitles: 33,
  vodTitles: 100
};

/**
 * There are some special characters that cannot be used in the search term of search queries.
 * This function returns search term split into chunks. The special characters and spaces are used as separators.
 * Whenever you want to build a search query, use this function to make sure that there are
 * no special characters in the search term.
 *
 * @param term search term
 * @returns array of allowed search term's parts
 */
const splitSearchTerm = (term: string): string[] => {
  // TODO: CL-5188 remove workaround for '&' and '|' when traxis fixes its search query implementation
  return term
    .trim()
    .split(/\s*[&+\-|!(){}\[\]^"'~*?:\\/\s]+\s*/g) // remove special chars and trim spaces
    .filter(isTruthy);
};

enum VodContentEventFilter {
  noEventCount = 'noEventCount',
  hasContentsOrEvents = 'hasContentsOrEvents'
}

interface KeepAliveSessionParams {
  position: number;
}

export enum SessionEndCode {
  UnknownReason = 0,
  TerminatedByCustomer = 2
}

interface DeleteSessionParams {
  position: number;
  sessionEndCode: SessionEndCode;
}

const sortDescending = (property: string): string => {
  return `~${property}`;
};

type SearchQueryParameters = Omit<SearchParameters, 'sources'>;

type ScheduleRecordingQueryOptions = Arguments & {
  GuardTimeStart?: string;
  GuardTimeEnd?: string;
  Location: string;
  SeedEventId?: string;
  StartTime?: string;
  SeedSeriesNumber?: number;
  SeedChannelId?: string;
}

interface GetRecordingsRequestHandler {
  requestFunction: (params: PVRQueryParameters) => RequestQuery;
  responseMapper: (response: ResponseJson) => Recording[];
}

export class TraxisQueries {

  public static updateCustomerNamedProperties(properties: {[key: string]: any}): RequestQuery {
    const customerId = mw.customer.id;
    if (!customerId) {
      throw new Error(ErrorType.InvalidParameter, 'Empty customer id');
    }
    return queryUtils.createActionQuery({
      identity: boProxy.sso.getIdentity().get(),
      actionName: CustomerAction.AddNamedProperties,
      resourceType: Resources.Customer,
      resourceId: customerId,
      args: properties
    });
  }

  private static profileQuery() {
    return {
      props: queryProps.props.Profile.basic
    };
  }

  public static getProfiles(): RequestQuery {
    return queryUtils.createRootRelationQuery({
      identity: boProxy.sso.getIdentity().get(),
      relationName: Relations.Profiles,
      query: TraxisQueries.profileQuery()
    });
  }

  public static createProfile(name: string): RequestQuery {
    return queryUtils.createRootActionQuery({
      identity: boProxy.sso.getIdentity().get(),
      actionName: ProfileAction.Create,
      args: {Name: name},
      options: TraxisQueries.profileQuery()
    });
  }

  public static deleteProfile(id: string): RequestQuery {
    return queryUtils.createActionQuery({
      identity: boProxy.sso.getIdentity().get(),
      actionName: ProfileAction.Delete,
      resourceType: Resources.Profile,
      resourceId: id
    });
  }

  public static updateProfile(id: string, properties: {[key: string]: string}) {
    return queryUtils.createActionQuery({
      identity: {
        ProfileId: id
      },
      resourceType: Resources.Profile,
      resourceId: id,
      actionName: ProfileAction.Update,
      args: properties
    });
  }

  public static updateProfileNamedProperties(id: string, properties: {[key: string]: any}) {
    return queryUtils.createActionQuery({
      identity: {
        ProfileId: id
      },
      actionName: ProfileAction.AddNamedProperties,
      resourceType: Resources.Profile,
      resourceId: id,
      args: properties
    });
  }

  public static updateProfileLanguagePreferences(id: string, languageType: LanguageType, language: string) {
    const removePreference = language === 'off' || language === 'auto';
    let actionPrefix: string;
    const actionSuffix = `${languageType}LanguagePreference`;
    const args: Arguments = {};
    if (removePreference) {
      actionPrefix = 'Delete';
    } else {
      actionPrefix = 'Set';
      args[actionSuffix] = language;
    }
    return queryUtils.createActionQuery({
      identity: {
        ProfileId: id
      },
      actionName: `${actionPrefix}${actionSuffix}s`,
      resourceType: Resources.Profile,
      resourceId: id,
      args
    });
  }

  public static addTitleToWatchList(profileId: string, titleId: string) {
    return TraxisQueries.prepareWatchListAction(WatchListAction.AddToWishList, Resources.Title, profileId, [titleId]);
  }

  public static addSeriesToWatchList(profileId: string, seriesId: string) {
    return TraxisQueries.prepareWatchListAction(WatchListAction.AddToWishListSeriesCollection, Resources.Series, profileId, [seriesId]);
  }

  public static removeTitlesFromWatchList(profileId: string, titlesIds: string[]) {
    return TraxisQueries.prepareWatchListAction(WatchListAction.DeleteFromWishList, Resources.Title, profileId, titlesIds);
  }

  public static removeSeriesFromWatchList(profileId: string, seriesIds: string[]) {
    return TraxisQueries.prepareWatchListAction(WatchListAction.DeleteFromWishListSeriesCollection, Resources.Series, profileId, seriesIds);
  }

  public static getWatchList(profileId: string) {
    const contentFilters = [Filters.isViewable, ...TraxisQueries.getAdultFilters()];
    const watchListEventsLimit = nxff.getConfig().Watchlist.WatchListEventsLimit;
    const options = {
      props: queryProps.props.Title.wishList,
      filter: [Filters.contentCountPositive, Filters.eventCountPositive].join('||'),
      Events: {
        props: queryProps.props.Event.wishList,
        filter: [Filters.notPastEvents, Filters.recordedEvents, Filters.hasTstvContents].join('||'),
        limit: watchListEventsLimit
      },
      SeriesCollection: {
        props: queryProps.props.Series.personal,
        ParentSeriesCollection: {
          props: queryProps.props.Series.minimal
        }
      },
      Contents: {
        props: queryProps.props.Content.personal,
        filter: contentFilters.join('&&'),
        Products: {
          props: queryProps.props.Product.personal,
          sort: Props.ListPrice
        }
      },
      Previews: {
        props: queryProps.props.Title.previewWishList,
        Contents: {
          props: queryProps.props.Content.preview,
          filter: Filters.isViewable
        }
      }
    };
    TraxisQueries.appendAssetBasedTstvOptions(options.Events);
    return queryUtils.createRootRelationQuery({
      identity: {ProfileId: profileId},
      relationName: 'WishList',
      query: options
    });
  }

  private static getAdultFilters(): string[] {
    if (mw.customer.getProfile().isPCEnabled && !mw.customer.getProfile().showAdultContent) {
      return [Filters.noAdult];
    }
    return [];
  }

  private static getPCPolicyAdultFilters(): string[] {
    if (mw.customer.getProfile().isPCEnabled && !mw.customer.getProfile().showAdultContent) {
      switch (nxffConfig.getConfig().ParentalControl.PCPolicy) {
        case PCType.AgeBased:
          return [Filters.noAdultAgeBasedFilter()];
        case PCType.RatingsBased:
          return [Filters.noAdultRatingBasedFilter()];
      }
    }
    return [];
  }

  public static getSeriesFromWatchList(profileId: string) {
    const options = {
      props: queryProps.props.Series.personal
    };
    return queryUtils.createRootRelationQuery({
      identity: {ProfileId: profileId},
      relationName: 'WishListSeriesCollection',
      query: options
    });
  }

  public static getChannelLists(profileId: string) {
    return queryUtils.createRootRelationQuery({
      identity: {
        ProfileId: profileId,
        CpeId: DeviceManager.getInstance().getId()
      },
      relationName: Relations.ChannelLists,
      query: {
        props: queryProps.props.ChannelList.basic,
        filter: Filters.profile(profileId),
        Channels: {
          props: 'PersonalChannelNumber',
          sort: 'PersonalChannelNumber'
        }
      }
    });
  }

  public static createChannelList(profileId: string, channelListName: string) {
    return queryUtils.createRootActionQuery({
      identity: {
        ProfileId: profileId
      },
      actionName: ChannelListAction.AddChannelList,
      args: {
        Name: channelListName
      }
    });
  }

  public static sendLocation(): RequestQuery {
    if (!resources.isActionSupported(Resources.Cpe, CpeAction.ForceUpdateLocation)) {
      throw new Error(ErrorType.NotSupported, 'Failed to create query to update location - BO do not support ForceUpdateLocation action on Cpe resource');
    }
    return queryUtils.createActionQuery({
      identity: boProxy.sso.getIdentity().get(),
      actionName: CpeAction.ForceUpdateLocation,
      resourceType: Resources.Cpe,
      resourceId: DeviceManager.getInstance().getId()
    });
  }

  public static addChannelsToChannelList(profileId: string, channelList: ChannelList, channels: Channel[]) {
    return queryUtils.createActionsQuery({
      identity: {
        ProfileId: profileId
      },
      actionName: ChannelListAction.AddToPersonalChannelList,
      resourceType: Resources.Channel,
      resources: TraxisQueries.mapChannelToResources(channels, channelList.id, true)
    });
  }

  private static mapChannelToResources(channelArray: Channel[], channelListId: string, includeLcn = false): ActionQueryResources[] {
    return channelArray.map(channel => ({
      id: channel.id,
      args: {ChannelListId: channelListId, ...includeLcn && {PersonalChannelNumber: channel.lcn}}
    }));
  }

  public static removeChannelsFromChannelList(profileId: string, channelList: ChannelList, channels: Channel[]) {
    return queryUtils.createActionsQuery({
      identity: {
        ProfileId: profileId
      },
      actionName: ChannelListAction.DeleteFromPersonalChannelList,
      resourceType: Resources.Channel,
      resources: TraxisQueries.mapChannelToResources(channels, channelList.id)
    });
  }

  public static deleteChannelList(profileId: string, channelList: ChannelList) {
    return queryUtils.createRootActionQuery({
      identity: {
        ProfileId: profileId
      },
      actionName: ChannelListAction.DeleteChannelList,
      args: {
        Id: channelList.id
      }
    });
  }

  public static updateChannelListName(profileId: string, channelList: ChannelList, name: string) {
    return queryUtils.createActionQuery({
      identity: {
        ProfileId: profileId
      },
      actionName: ChannelListAction.Update,
      resourceType: Resources.ChannelList,
      resourceId: channelList.id,
      args: {
        Name: name
      }
    });
  }

  public static purchase(productId: string, offerId: string): RequestQuery {
    return queryUtils.createActionQuery({
      identity: boProxy.sso.getIdentity().get(),
      actionName: ProductAction.Purchase,
      resourceType: Resources.Product,
      resourceId: productId,
      args: {
        OfferId: offerId
      }
    });
  }

  public static getProfilePin(id: string) {
    return queryUtils.createResourceQuery({
      identity: boProxy.sso.getIdentity().get(),
      resourceType: Resources.Profile,
      resourceId: id,
      query: {
        props: 'Pin'
      }
    });
  }

  public static getChannels(): RequestQuery {
    const options: any = {
      props: queryProps.props.Channel.channel,
      sort: Props.LogicalChannelNumber,
      filter: TraxisQueries.channelsFilter(),
      Products: {
        filter: Filters.isEntitled
      }
    };

    if (resources.hasRelation(Resources.Channel, Relations.Policies)) {
      options.Policies = {
        props: queryProps.props.Policy.basic
      };
    }

    return queryUtils.createRootRelationQuery({
      identity: boProxy.sso.getIdentity().get(),
      relationName: Relations.Channels,
      query: options
    });
  }

  public static getEPG(params: EPGParams): RequestQuery {
    const eventsFilters = [
      Filters.availableAfter(params.startTime),
      Filters.availableBefore(params.endTime),
      Filters.hasTitles
    ];
    const titlesFilters = [];
    if (params.genres) {
      titlesFilters.push(params.genres.map(Filters.hasGenre).join('||'));
    }
    const options: any = {
      props: queryProps.props.Channel.events,
      Events: {
        props: queryProps.props.Event.event,
        sort: Props.AvailabilityEnd,
        Titles: {
          props: queryProps.props.Title.titleEvent,
          SeriesCollection: {
            props: queryProps.props.Series.seriesCollection,
            ParentSeriesCollection: {
              props: queryProps.props.Series.minimal
            }
          }
        }
      }
    };
    if (eventsFilters.length > 0) {
      options.Events.filter = eventsFilters.join('&&');
    }
    if (titlesFilters.length > 0) {
      options.Events.Titles.filters = titlesFilters.join('&&');
    }

    TraxisQueries.appendAssetBasedTstvOptions(options.Events, true);

    return queryUtils.createResourcesQuery({
      resourceType: Resources.Channel,
      resourceIds: params.channels.map(({id}) => ({id})),
      query: options
    });
  }

  private static getTstvContentOption(): any {
    const filters = [Filters.isViewable, Filters.beforeLastAvailabilityEnded, ...TraxisQueries.getAdultFilters()];
    return {
      props: queryProps.props.Content.tstv,
      filter: filters.join('&&'),
      Products: {
        props: queryProps.props.Product.relations
      }
    };
  }

  private static appendAssetBasedTstvOptions(eventQuery: any, notPersonalized = false): any {
    if (!mw.configuration.isAssetBasedTstvEnabled) {
      return;
    }
    const tstvProps = notPersonalized ? queryProps.props.Event.tstv : queryProps.props.Event.personalTstv;
    eventQuery.props = [eventQuery.props, tstvProps].join(',');
    eventQuery.TstvContents = TraxisQueries.getTstvContentOption();

    if (!nxffConfig.getConfig().Playback.EnableFutureTSTV) {
      return;
    }

    if (!eventQuery.Titles) {
      eventQuery.Titles = {};
    }
    eventQuery.Titles.Events = {
      props: 'TstvContents',
      filter: [Filters.hasTstvContents, Filters.notAfterAvailabilityEnded].join('&&'),
      sort: Props.AvailabilityEnd,
      limit: 1,
      TstvContents: TraxisQueries.getTstvContentOption()
    };
  }

  public static getEventById(id: string): RequestQuery {
    const options: any = {
      props: queryProps.props.Event.details,
      Titles: {
        props: queryProps.props.Title.detailedTitleEvent,
        SeriesCollection: {
          props: queryProps.props.Series.seriesCollection,
          ParentSeriesCollection: {
            props: queryProps.props.Series.minimal
          }
        }
      },
      Products: {
        props: queryProps.props.Product.basic
      }
    };

    TraxisQueries.appendAssetBasedTstvOptions(options);

    return queryUtils.createResourceQuery({
      identity: {
        ProfileId: mw.customer.getProfile().id
      },
      resourceType: Resources.Event,
      resourceId: id,
      query: options
    });
  }

  public static keepAlivePlaybackSession(session: PlaybackSession, params: KeepAliveSessionParams): RequestQuery {
    return {
      method: HttpMethods.PUT,
      body: queryUtils.keepAlive5jSession({
        TrackId: session.getCurrentAssetNumber(),
        TimeOffset: params.position
      })
    };
  }

  public static deletePlaybackSession(session: PlaybackSession, params: DeleteSessionParams): RequestQuery {
    return {
      method: HttpMethods.DELETE,
      body: queryUtils.delete5jSession({
        TrackId: session.getCurrentAssetNumber(),
        TimeOffset: params.position,
        SessionEndCode: params.sessionEndCode,
        SessionEndDescription: TraxisQueries.getDescriptionForSessionEndCode(params.sessionEndCode)
      })
    };
  }

  private static getDescriptionForSessionEndCode(sessionEndCode: SessionEndCode) {
    switch (sessionEndCode) {
      case SessionEndCode.TerminatedByCustomer:
        return 'The session was terminated by the customer';
      default:
        return 'Playback finished for unknown reason';
    }
  }

  public static getEndCodeForSessionTerminationReason(sessionTerminationReason: SessionTerminationReason) {
    switch (sessionTerminationReason) {
      case SessionTerminationReason.UserAction:
        return SessionEndCode.TerminatedByCustomer;
      default:
        return SessionEndCode.UnknownReason;
    }
  }

  public static getTitleById(id: string): RequestQuery {
    const options = {
      props: queryProps.props.Title.title,
      Contents: {
        props: queryProps.props.Content.personal,
        filter: Filters.isViewable,
        Products: {
          props: queryProps.props.Product.personal,
          sort: Props.ListPrice
        }
      },
      SeriesCollection: {
        props: queryProps.props.Series.seriesCollection
      },
      Previews: {
        props: queryProps.props.Title.preview,
        filter: Filters.isViewable,
        Contents: {
          props: queryProps.props.Content.preview,
          filter: Filters.isViewable
        }
      }
    };

    return queryUtils.createResourceQuery({
      identity: {
        ProfileId: mw.customer.getProfile().id
      },
      resourceType: Resources.Title,
      resourceId: id,
      query: options
    });
  }

  public static getLastViewedVodTitles(limit: number): RequestQuery {
    const sort = sortDescending(Props.LastViewDate);
    return this.getViewedTitles(limit, [
      Filters.hasContents,
      Filters.noEvents,
      Filters.isNotSeen,
      Filters.noSeries,
      Filters.personalBookmarkPositive,
      ...TraxisQueries.getAdultFilters()
    ], sort);
  }

  public static getLastViewedRecordings(limit: number): RequestQuery {
    const filter = [
      Filters.personalBookmarkPositive,
      Filters.isNotSeen,
      Filters.noParentRecording,
      TraxisQueries.singleRecordingFilter(RecordingStatus.Completed),
      Filters.hasContents,
      Filters.hasTitles
    ].join('&&');

    const contentFilters = [Filters.isViewable, ...TraxisQueries.getAdultFilters()];

    const options: any = {
      props: queryProps.props.Recording.list,
      limit,
      filter,
      Titles: {
        props: queryProps.props.Title.rootRecording,
        SeriesCollection: {
          props: queryProps.props.Series.minimal,
          ParentSeriesCollection: {
            props: queryProps.props.Series.minimal
          }
        }
      },
      Contents: {
        props: queryProps.props.Content.preview,
        filter: contentFilters.join('&&')
      },
      Channels: {
        props: 'Pictures'
      }
    };

    const titlesFilters = [...TraxisQueries.getAdultFilters(), ...TraxisQueries.getPCPolicyAdultFilters()];
    if (titlesFilters.length) {
      options.Titles.filter = titlesFilters.join('&&');
    }

    options.sort = TraxisQueries.getRecordingsSorting({
      sortBy: {
        type: SortRecordingsBy.lastViewDate,
        ascending: false
      },
      status: RecordingStatus.Completed
    });

    return queryUtils.createRootRelationQuery({
      identity: {ProfileId: mw.customer.getProfile().id},
      relationName: Relations.Recordings,
      query: options
    });
  }

  public static getLastViewedSeriesRecordings(limit: number): RequestQuery {
    const filter = [
      Filters.personalBookmarkPositive,
      Filters.isRecordingEpisode,
      Filters.withParentRecording,
      Filters.isRecordingComplete,
      Filters.hasContents,
      Filters.hasTitles
    ].join('&&');

    const contentFilters = [Filters.isViewable, ...TraxisQueries.getAdultFilters()];

    const options: any = {
      props: queryProps.props.Recording.list,
      limit,
      filter,
      Titles: {
        props: queryProps.props.Title.rootRecording,
        SeriesCollection: {
          props: queryProps.props.Series.minimal,
          ParentSeriesCollection: {
            props: queryProps.props.Series.minimal
          }
        }
      },
      Contents: {
        props: queryProps.props.Content.preview,
        filter: contentFilters.join('&&')
      },
      Channels: {
        props: 'Pictures'
      },
      ParentRecordings: {
        propset: 'all'
      }
    };

    const titlesFilters = [...TraxisQueries.getAdultFilters(), ...TraxisQueries.getPCPolicyAdultFilters()];
    if (titlesFilters.length) {
      options.Titles.filter = titlesFilters.join('&&');
    }

    options.sort = TraxisQueries.getRecordingsSorting({
      sortBy: {
        type: SortRecordingsBy.lastViewDate,
        ascending: false
      },
      status: RecordingStatus.Completed
    });

    return queryUtils.createRootRelationQuery({
      identity: {ProfileId: mw.customer.getProfile().id},
      relationName: Relations.Recordings,
      query: options
    });
  }

  public static getRecordingSeries(seriesId: string): RequestQuery {
    const options: any = {
      props: 'Type',
      filter: [
        Filters.isSeries,
        Filters.bySeriesID(seriesId)
      ]
        .join('&&')
      ,
      ChildRecordings: {
        props: queryProps.props.Recording.list,
        filter: [
          TraxisQueries.seriesRecordingFilter(RecordingStatus.Completed),
          TraxisQueries.singleRecordingFilter(RecordingStatus.Completed)
        ]
          .map(intoBrackets)
          .join('||'),
        Titles: {
          props: queryProps.props.Title.rootRecording,
          ...TraxisQueries.seriesCollectionSubquery()
        },
        ...TraxisQueries.seriesCollectionSubquery(),
        ChildRecordings: {
          props: queryProps.props.Recording.basic,
          filter: TraxisQueries.singleRecordingFilter(RecordingStatus.Completed),
          Titles: {
            props: queryProps.props.Title.rootRecording,
            ...TraxisQueries.seriesCollectionSubquery()
          }
        }
      }
    };

    return queryUtils.createRootRelationQuery({
      identity: {ProfileId: mw.customer.getProfile().id},
      relationName: Relations.Recordings,
      query: options
    });
  }

  public static getLastViewedSeriesTitles(limit: number): RequestQuery {
    const sort = sortDescending(Props.LastViewDate);
    const subquery = {
      SeriesCollection: {
        props: queryProps.props.Series.minimal,
        ParentSeriesCollection: {
          props: queryProps.props.Series.minimal
        }
      }
    };
    return this.getViewedTitles(limit, [
      Filters.hasContents,
      Filters.noEvents,
      Filters.seriesCountPositive,
      Filters.personalBookmarkPositive,
      ...TraxisQueries.getAdultFilters()
    ], sort, subquery);
  }

  private static getViewedTitles(limit: number, filters: string[], sort?: string, subquery?: any): RequestQuery {
    const options = {
      props: queryProps.props.Title.title,
      ...sort && {sort: sort},
      Contents: {
        props: queryProps.props.Content.content,
        filter: Filters.isViewable
      },
      limit: limit,
      filter: filters.join('&&'),
      ...subquery && subquery
    };
    return queryUtils.createRootRelationQuery({
      identity: {
        ProfileId: mw.customer.getProfile().id
      },
      relationName: Relations.ViewedTitles,
      query: options
    });
  }

  public static getLastViewedEvents(limit: number) {
    const sort = sortDescending(Props.LastViewDate);
    return TraxisQueries.getViewedEvents(limit, [
      Filters.hasTitles,
      Filters.personalBookmarkPositive,
      Filters.isNotSeen,
      Filters.hasTstvContents
    ], sort);
  }

  private static getViewedEvents(limit: number, eventsFilters: string[], sort?: string) {
    const options = {
      props: queryProps.props.Event.event,
      ...sort && {sort: sort},
      limit: limit,
      filter: eventsFilters.join('&&'),
      Titles: {
        props: queryProps.props.Title.titleEvent,
        filter: TraxisQueries.titlesFilter(undefined, VodContentEventFilter.hasContentsOrEvents),
        SeriesCollection: {
          props: queryProps.props.Series.seriesCollection,
          ParentSeriesCollection: {
            props: queryProps.props.Series.minimal
          }
        }
      }
    };
    TraxisQueries.appendAssetBasedTstvOptions(options);
    return queryUtils.createRootRelationQuery({
      identity: {
        ProfileId: mw.customer.getProfile().id
      },
      relationName: Relations.ViewedEvents,
      query: options
    });
  }

  public static getSeriesById(seriesId: string): RequestQuery {
    const options = {
      props: queryProps.props.Series.personal
    };
    return queryUtils.createResourceQuery({
      identity: boProxy.sso.getIdentity().get(), // TODO: profile based identity
      resourceType: Resources.Series,
      resourceId: seriesId,
      query: options
    });
  }

  public static getSeriesSeasonsById(seriesId: string): RequestQuery {
    const options = {
      props: queryProps.props.Series.personal,
      ChildSeriesCollection: {
        ...TraxisQueries.seriesSeasonsSubQuery(),
        Titles: {
          filter: [Filters.contentCountPositive, Filters.eventCountPositive].join('||'),
          limit: 1
        }
      }
    };
    return queryUtils.createResourceQuery({
      identity: boProxy.sso.getIdentity().get(), // TODO: profile based identity
      resourceType: Resources.Series,
      resourceId: seriesId,
      query: options
    });
  }

  public static getSeriesEpisodesById(seriesId: string, includeTvEvents = false): RequestQuery {
    const seriesTitlesSubQuery = TraxisQueries.seriesTitlesSubQuery(includeTvEvents);
    const options = {
      props: queryProps.props.Series.personalAndTitles,
      Titles: seriesTitlesSubQuery,
      ChildSeriesCollection: {
        ...TraxisQueries.seriesSeasonsSubQuery(),
        Titles: seriesTitlesSubQuery
      },
      ParentSeriesCollection: {
        props: queryProps.props.Series.minimal
      }
    };
    return queryUtils.createResourceQuery({
      identity: {
        ProfileId: mw.customer.getProfile().id
      },
      resourceType: Resources.Series,
      resourceId: seriesId,
      query: options
    });
  }

  public static getChannelLocations(): RequestQuery {
    const options = {
      props: Props.Locations,
      filter: Filters.isViewable
    };

    return queryUtils.createRootRelationQuery({
      identity: new DeviceIdentity(DeviceManager.getInstance().getId()).get(),
      relationName: Relations.ChannelLocations,
      query: options
    });
  }

  public static getBookmarkForEvent(eventId: string): RequestQuery {
    const options: any = {
      props: queryProps.props.Event.bookmark
    };

    return queryUtils.createResourceQuery({
      identity: {
        ProfileId: mw.customer.getProfile().id
      },
      resourceType: Resources.Event,
      resourceId: eventId,
      query: options
    });
  }

  public static setBookmark(media: Media, position: number): RequestQuery {
    let actions = [BookmarkActions.SetPersonalBookmark, BookmarkActions.SetBookmark];
    let resourceType: string;
    const mediaType = media.getType();
    switch (mediaType) {
      case MediaType.Title:
        resourceType = Resources.Title;
        break;

      case MediaType.Recording:
        resourceType = Resources.Recording;
        break;

      case MediaType.Event:
        actions = [BookmarkActions.SetPersonalBookmark];
        resourceType = Resources.Event;
        break;

      default:
        throw (new Error(ErrorType.NotSupported, `setBookmark not supported for: ${mediaType}`));
    }

    const actionName = resources.chooseSupportedAction(resourceType, actions);
    if (!actionName) {
      Log.error(TAG, 'bookmarkAction', resourceType, actions);
      throw new Error(ErrorType.NotSupported, `BO not support actions Bookmark at: ${resourceType}`);
    }

    const args: Arguments = {Bookmark: position};
    return queryUtils.createActionQuery({
      identity: {
        ProfileId: mw.customer.getProfile().id
      },
      actionName: actionName,
      resourceType: resourceType,
      resourceId: media.id,
      args
    });
  }

  private static contentFilter() {
    const now = moment();
    const startOfHour = now.startOf('hour').toDate();
    const endOfHour = now.add(1, 'hour').startOf('hour')
      .toDate();
    const filters = [
      Filters.availableBefore(startOfHour, 'content'),
      Filters.availableAfter(endOfHour, 'content'),
      Filters.productCountPositive,
      Filters.isViewable,
      ...TraxisQueries.getAdultFilters()
    ];
    return filters.join('&&');
  }

  private static channelsFilter() {
    const filter = [Filters.isLogicalChannelNumber, Filters.hasProducts, Filters.isViewable, ...TraxisQueries.getAdultFilters()];
    return filter.join('&&');
  }

  private static eventFilter(hoursAhead = CategoryLimits.events.hoursAhead) {
    const now = moment();
    const startOfHour = now.startOf('hour').toDate();
    const endOfHour = now.add(hoursAhead, 'hour').startOf('hour')
      .toDate();
    const availableBefore = Filters.availableBefore(endOfHour, 'event');
    const availableAfter = Filters.availableAfter(startOfHour, 'event');
    const channelIds = Filters.channelIdsFilter(mw.catalog.getSortedChannelsIds());

    return `${availableBefore}&&(${Filters.hasTstvContents}||${availableAfter})&&${channelIds}`;
  }

  private static titlesFilter(filter?: VodFilter, contentEventFilter?: VodContentEventFilter) {
    const filters = [];

    if (contentEventFilter === VodContentEventFilter.hasContentsOrEvents) {
      filters.push(`((${Filters.hasContents}&&${Filters.isFeature}&&${Filters.noEventCount})||${Filters.hasEvents})`);
    } else {
      filters.push(Filters.hasContents);
      filters.push(Filters.isFeature);
      filters.push(Filters.noEventCount);
    }

    if (contentEventFilter === VodContentEventFilter.noEventCount) {
      filters.push(Filters.noEventCount);
    }

    if (filter === VodFilter.seen) {
      filters.push(Filters.isSeen);
    } else if (filter === VodFilter.notSeen) {
      filters.push(Filters.isNotSeen);
    }
    filters.push(...TraxisQueries.getAdultFilters(), ...TraxisQueries.getPCPolicyAdultFilters());
    return filters.join('&&');
  }

  private static seriesFilter() { // TODO: pass filtering options from UI (e.g. isPersonallyViewedCompletely)
    const filters: string[] = [
      `(${[Filters.hasTitles, Filters.hasChildSeries].join('||')})`,
      ...TraxisQueries.getAdultFilters()
    ];

    return filters.join('&&');
  }

  private static basicCategoryFilter() {
    const hasContent = [
      Filters.hasTitles,
      Filters.hasSeries,
      Filters.childCategoriesCountPositive
    ].join('||');
    const viewableOnCpe = nxff.getConfig().VOD.EnabledContainsContentViewableOnCpe ? `&&(${Filters.containsContentViewableOnCpe})` : '';
    const baseFilter = `(${hasContent})${viewableOnCpe}`;
    const personalFilter = `(${Filters.isCategoryPersonal}||(${baseFilter}))`;
    const filters: string[] = [personalFilter, ...TraxisQueries.getAdultFilters()];

    return filters.join('&&');
  }

  private static seriesSeasonsSubQuery() {
    return {
      props: queryProps.props.Series.series,
      sort: Props.Ordinal,
      filter: Filters.hasTitles,
      ParentSeriesCollection: {
        props: queryProps.props.Series.minimal
      }
    };
  }

  private static seriesTitlesSubQuery(includeTvEvents: boolean) {
    const eventsOptions = {
      props: queryProps.props.Event.details,
      sort: Props.AvailabilityStart,
      filter: TraxisQueries.prepareEpgSearchFilter(),
      Channels: {
        props: queryProps.props.Channel.basic,
        filter: Filters.isViewable
      },
      Products: {
        props: queryProps.props.Product.basic
      }
    };

    if (includeTvEvents) {
      TraxisQueries.appendAssetBasedTstvOptions(eventsOptions);
    }

    return {
      props: queryProps.props.Title.episode,
      sort: Props.Ordinal,
      filter: includeTvEvents ? TraxisQueries.titlesFilter(VodFilter.all, VodContentEventFilter.hasContentsOrEvents) : TraxisQueries.titlesFilter(),
      Contents: {
        props: queryProps.props.Content.personal,
        filter: TraxisQueries.contentFilter(),
        Products: {
          props: queryProps.props.Product.basic
        }
      },
      Previews: {
        props: queryProps.props.Title.title,
        Contents: {
          props: queryProps.props.Content.preview,
          filter: Filters.isViewable
        }
      },
      ...includeTvEvents && {Events: eventsOptions}
    };
  }

  private static categoryTitlesSubQuery() {
    return {
      filter: TraxisQueries.titlesFilter(),
      limit: 1,
      Contents: {
        filter: TraxisQueries.contentFilter(),
        limit: 1
      }
    };
  }

  private static categorySeriesSubQuery() {
    return {
      filter: TraxisQueries.seriesFilter(),
      limit: 1,
      Titles: TraxisQueries.categoryTitlesSubQuery(),
      ChildSeriesCollection: {
        filter: Filters.hasTitles,
        limit: 1,
        Titles: TraxisQueries.categoryTitlesSubQuery()
      }
    };
  }

  private static childCategoriesSubQuery() {
    return {
      props: queryProps.props.Category.extended,
      sort: Props.Ordinal,
      filter: TraxisQueries.basicCategoryFilter(),
      Titles: TraxisQueries.categoryTitlesSubQuery(),
      SeriesCollection: TraxisQueries.categorySeriesSubQuery()
    };
  }

  public static getRootCategories(): RequestQuery {
    return queryUtils.createRootRelationQuery({
      relationName: Relations.RootCategories,
      query: {
        props: queryProps.props.Category.rootcategory,
        sort: Props.Ordinal,
        limit: 1,
        filter: TraxisQueries.basicCategoryFilter(),
        ChildCategories: TraxisQueries.childCategoriesSubQuery()
      }
    });
  }

  public static getRootCategorySoftLinks(): RequestQuery {
    return queryUtils.createRootRelationQuery({
      relationName: Relations.RootCategories,
      query: {
        props: Props.SoftLinks,
        sort: Props.Ordinal,
        limit: 1
      }
    });
  }

  public static getCategory(id: string): RequestQuery {
    const query = {
      resourceType: Resources.Category,
      resourceId: id,
      query: {
        props: queryProps.props.Category.extended,
        Titles: TraxisQueries.categoryTitlesSubQuery(),
        SeriesCollection: TraxisQueries.categorySeriesSubQuery(),
        ChildCategories: TraxisQueries.childCategoriesSubQuery()
      }
    };
    return queryUtils.createResourceQuery(query);
  }

  public static getMultipleCategoriesQuery(categories: Filter[], paging: {offset: number; pageSize: number}, options?: {filter?: VodFilter; sorting?: VodSorting}): RequestQuery {
    const hasPersonalizedFilter = options && (options.filter === VodFilter.seen || options.filter === VodFilter.notSeen);
    const isPersonalized = categories.some(({isPersonal}) => isPersonal);
    const sorting = TraxisQueries.prepareSortParameter(options?.sorting);
    const query = {
      ...(hasPersonalizedFilter || isPersonalized) && {identity: {ProfileId: mw.customer.getProfile().id}},
      resourceType: Resources.Category,
      relationName: Relations.Titles,
      resources: categories.map(({value, aliasType}) => ({id: value, aliasType})),
      query: {
        props: queryProps.props.Title.basic,
        paging: queryUtils.createPagingParameter(paging),
        ...sorting && {sort: sorting},
        filter: TraxisQueries.titlesFilter(options?.filter, VodContentEventFilter.hasContentsOrEvents),
        ...TraxisQueries.seriesCollectionSubquery(),
        Events: {
          props: queryProps.props.Event.basic,
          filter: TraxisQueries.eventFilter(),
          sort: sortDescending(Props.AvailabilityStart),
          limit: CategoryLimits.events.limit
        }
      }
    };
    TraxisQueries.appendAssetBasedTstvOptions(query.query.Events);
    return queryUtils.createResourcesRelationQuery(query);
  }

  public static getCategoryRelationsQuery(category: Filter, paging: {offset: number; pageSize: number}, options?: {filter?: VodFilter; sorting?: VodSorting}): RequestQuery {
    const hasPersonalizedFilter = options && (options.filter === VodFilter.seen || options.filter === VodFilter.notSeen);
    const isPersonalized = category.isPersonal;
    const relations: string[] = [
      Relations.Titles,
      Relations.SeriesCollection
    ];
    const resourceOptions: ResourceOptions[] = [
      {
        options: {
          paging: queryUtils.createPagingParameter(paging)
        }
      },
      {
        resourceType: Resources.Title, options: {
          props: queryProps.props.Title.basic,
          filter: TraxisQueries.titlesFilter(options?.filter, VodContentEventFilter.hasContentsOrEvents)
        }
      },
      {
        resourceType: Resources.Series, options: {
          props: queryProps.props.Series.basic,
          filter: TraxisQueries.seriesFilter()
        }
      }
    ];
    const subQueries: RelationSubQueryAttributes[] = [
      {
        resourceType: Resources.Title,
        relationName: Relations.Contents,
        subQuery: {
          props: queryProps.props.Content.content,
          filter: TraxisQueries.contentFilter()
        }
      },
      {
        resourceType: Resources.Title,
        relationName: Relations.Events,
        subQuery: {
          props: queryProps.props.Event.basic,
          filter: TraxisQueries.eventFilter(),
          sort: sortDescending(Props.AvailabilityStart),
          limit: CategoryLimits.events.limit
        }
      },
      {
        resourceType: Resources.Series,
        relationName: Relations.Titles,
        subQuery: TraxisQueries.categoryTitlesSubQuery()
      },
      {
        resourceType: Resources.Series,
        relationName: Relations.ChildSeriesCollection,
        subQuery: TraxisQueries.categorySeriesSubQuery()
      }
    ];

    if (resources.hasRelation(Resources.Category, Relations.Channels)) {
      relations.push(Relations.Channels);
      resourceOptions.push({
        resourceType: Resources.Channel, options: {
          props: queryProps.props.Channel.basic,
          filter: TraxisQueries.categoryChannelsFilter()
        }
      });
    }
    if (resources.hasRelation(Resources.Category, Relations.Events)) {
      relations.push(Relations.Events);
      resourceOptions.push({
        resourceType: Resources.Event, options: {
          props: queryProps.props.Event.basic,
          filter: Filters.channelIdsFilter(mw.catalog.getSortedChannelsIds())
        }
      });
      subQueries.push({
        resourceType: Resources.Event,
        relationName: Relations.Titles,
        subQuery: {
          props: queryProps.props.Title.titleEvent
        }
      });
    }
    if (isPersonalized && resources.hasRelation(Resources.Category, Relations.Recordings)) {
      relations.push(Relations.Recordings);
      resourceOptions.push({
        resourceType: Resources.Recording,
        options: {
          props: queryProps.props.Recording.basic,
          filter: Filters.hasTitles
        }
      });
      const titleSubQueryFilter = TraxisQueries.getAdultFilters().join('&&');
      subQueries.push({
        resourceType: Resources.Recording,
        relationName: Relations.Titles,
        subQuery: {
          props: queryProps.props.Title.basic,
          ...titleSubQueryFilter && {filter: titleSubQueryFilter},
          SeriesCollection: {
            props: queryProps.props.Series.minimal,
            ParentSeriesCollection: {
              props: queryProps.props.Series.minimal
            }
          }
        }
      });
      subQueries.push({
        resourceType: Resources.Recording,
        relationName: Relations.Events,
        subQuery: {
          props: queryProps.props.Event.basic
        }
      });
    }

    return queryUtils.createRelationsQuery({
      ...(isPersonalized || hasPersonalizedFilter) && {identity: {ProfileId: mw.customer.getProfile().id}},
      ...category.aliasType && {aliasType: category.aliasType},
      resourceType: Resources.Category,
      resourceId: category.value,
      relations: relations,
      options: resourceOptions,
      subQueries: subQueries
    });
  }

  private static categoryChannelsFilter(): string {
    const filter = [
      Filters.channelLcnFilter(compactMap(mw.catalog.getSortedChannelsIds(), channelId => mw.catalog.getChannelById(channelId)?.lcn)),
      ...TraxisQueries.getAdultFilters()
    ];
    return filter.join('&&');
  }

  public static getRecommendations(id: string, limit: number): RequestQuery {
    const query: ExtendedRelationQuery = {
      resourceType: Resources.Title,
      resourceId: id,
      relationName: Relations.Recommendations,
      query: {
        props: queryProps.props.Title.basic,
        limit: `${limit}`,
        filter: TraxisQueries.titlesFilter(VodFilter.all, VodContentEventFilter.hasContentsOrEvents),
        Contents: {
          props: queryProps.props.Content.content,
          filter: TraxisQueries.contentFilter()
        },
        Events: {
          props: queryProps.props.Event.event,
          filter: TraxisQueries.eventFilter(RecommendationsLimits.events.hoursAhead),
          sort: sortDescending(Props.AvailabilityStart),
          limit: CategoryLimits.events.limit
        }
      }
    };
    TraxisQueries.appendAssetBasedTstvOptions(query.query.Events);
    return queryUtils.createExtendedRelationQuery(query);
  }

  public static getSoftlinkContent(id: string, idType: string): RequestQuery {
    const query: any = {
      resourceType: Resources.Category,
      resourceId: id,
      resourceAttributes: {
        aliasType: idType
      },
      query: {
        ChildCategories: {
          props: queryProps.props.Category.extended,
          // Titles: TraxisQueries.categoryTitlesSubQuery() /* disabled query used to filter empty categories because too long request duration */
          sort: Props.Ordinal
        }
      }
    };
    const filters: string[] = TraxisQueries.getAdultFilters();
    if (filters.length) {
      query.query.ChildCategories.filter = filters.join('&&');
    }
    return queryUtils.createResourceQuery(query);
  }

  private static prepareEpgSearchFilter(epgFilter?: EpgFilter): string {
    const filters = [Filters.hasChannels, Filters.channelIdsFilter(mw.catalog.getSortedChannelsIds())];

    if (epgFilter) {
      switch (epgFilter.availabilityInTime) {
        case AvailabilityInTime.past:
          filters.push(Filters.pastEvents, Filters.hasTstvContents);
          break;
        case AvailabilityInTime.current:
          filters.push(Filters.currentEvents);
          break;
        case AvailabilityInTime.future:
          filters.push(Filters.futureEvents);
          break;
      }
    }

    return filters.join('&&');
  }

  public static searchChannelsQuery(params: SearchQueryParameters): RequestQuery {
    const options: any = {
      props: queryProps.props.Channel.channel,
      sort: params.channelSorting ? TraxisQueries.prepareSortParameter(params.channelSorting) : Props.LogicalChannelNumber,
      search: TraxisQueries.prepareSearchProperty(params, queryProps.props.Channel.search.split(',')),
      filter: TraxisQueries.channelsFilter(),
      Products: {
        filter: Filters.isEntitled
      }
    };

    if (resources.hasRelation(Resources.Channel, Relations.Policies)) {
      options.Policies = {
        props: queryProps.props.Policy.basic
      };
    }

    return queryUtils.createRootRelationQuery({
      relationName: Relations.Channels,
      identity: boProxy.sso.getIdentity().get(),
      query: options
    });
  }

  public static searchEpgQuery(params: SearchQueryParameters, filter?: EpgFilter): RequestQuery {
    const query = {
      relationName: Relations.Titles,
      identity: boProxy.sso.getIdentity().get(),
      query: {
        props: queryProps.props.Title.basic,
        search: TraxisQueries.prepareSearchProperty(params),
        filter: [
          `(${Filters.hasEvents})`,
          ...TraxisQueries.getAdultFilters()
        ].join('&&'),
        limit: searchResultsLimits.epgTitles,
        Events: {
          props: queryProps.props.Event.search,
          filter: TraxisQueries.prepareEpgSearchFilter(filter),
          limit: searchResultsLimits.epgEvents,
          sort: Props.AvailabilityStart,
          Channels: {
            props: queryProps.props.Channel.basic,
            filter: Filters.isViewable
          }
        },
        SeriesCollection: {
          props: queryProps.props.Series.seriesCollection,
          ParentSeriesCollection: {
            props: queryProps.props.Series.search
          }
        }
      }
    };
    TraxisQueries.appendAssetBasedTstvOptions(query.query.Events);
    return queryUtils.createRootRelationQuery(query);
  }

  public static searchVodQuery(params: SearchParameters): RequestQuery {
    const sort = params.vodSorting ? {sort: TraxisQueries.prepareSortParameter(params.vodSorting)} : {};
    const query = {
      relationName: Relations.Titles,
      identity: boProxy.sso.getIdentity().get(),
      query: {
        props: queryProps.props.Title.basic,
        ...sort,
        search: TraxisQueries.prepareSearchProperty(params),
        filter: [
          `(${TraxisQueries.titlesFilter(undefined, VodContentEventFilter.noEventCount)})`,
          ...TraxisQueries.getAdultFilters()
        ].join('&&'),
        limit: searchResultsLimits.vodTitles,
        Contents: {
          props: queryProps.props.Content.entitlements,
          filter: [
            Filters.isViewable,
            Filters.isNotRecording,
            Filters.availableContents,
            Filters.hasProducts
          ].join('&&'),
          Products: {
            filter: Filters.isAvailable
          }
        },
        SeriesCollection: {
          props: queryProps.props.Series.seriesCollection,
          ParentSeriesCollection: {
            props: queryProps.props.Series.search
          }
        }
      }
    };
    return queryUtils.createRootRelationQuery(query);
  }

  public static searchPvrQuery(params: SearchQueryParameters): RequestQuery {
    const query = {
      relationName: Relations.RecordedTitles,
      identity: {
        ProfileId: mw.customer.getProfile().id
      },
      query: {
        search: TraxisQueries.prepareSearchProperty(params),
        filter: [
          Filters.hasRecordings,
          ...TraxisQueries.getAdultFilters()
        ].join('&&'),
        limit: searchResultsLimits.recordedTitles,
        Recordings: {
          props: queryProps.props.Recording.basic,
          filter: Filters.hasContents,
          sort: Props.AvailabilityStart,
          Channels: {},
          Contents: {
            props: queryProps.props.Content.entitlements,
            filter: Filters.isViewable
          }
        },
        Titles: {
          props: queryProps.props.Title.basic,
          SeriesCollection: {
            props: queryProps.props.Series.search,
            ParentSeriesCollection: {
              props: queryProps.props.Series.search
            }
          }
        }
      }
    };
    return queryUtils.createRootRelationQuery(query);
  }

  /**
   * This is a pvr fallback query that should be used when `PvrTitles` relation is not available in BO
   */
  public static searchPvrAlternativeQuery(params: SearchQueryParameters): RequestQuery {
    const query = {
      relationName: Relations.Recordings,
      identity: {
        ProfileId: mw.customer.getProfile().id
      },
      query: {
        props: queryProps.props.Recording.basic,
        filter: [
          Filters.hasTitles,
          Filters.isSingle
        ].join('&&'),
        limit: searchResultsLimits.recordedTitles,
        Titles: {
          props: queryProps.props.Title.basic,
          filter: [
            TraxisQueries.preparePvrFilterProperty(params),
            ...TraxisQueries.getAdultFilters()
          ].join('&&'),
          SeriesCollection: {
            props: queryProps.props.Series.search,
            ParentSeriesCollection: {
              props: queryProps.props.Series.search
            }
          }
        },
        Events: {
          props: queryProps.props.Event.search
        }
      }
    };

    return queryUtils.createRootRelationQuery(query);
  }

  public static getNPVRQuota(): RequestQuery {
    const query = {
      relationName: Relations.Customers,
      identity: boProxy.sso.getIdentity().get(),
      query: {
        props: queryProps.props.Customer.quota
      }
    };
    return queryUtils.createRootRelationQuery(query);
  }

  public static scheduleRecording(params: PVRScheduleParameters): RequestQuery {
    switch (params.type) {
      case RecordingType.Single:
        return this.scheduleRecordingImpl(params, Resources.Event, params.event.id);

      case RecordingType.Series:
        const seriesId = params.event.title.episode?.seriesId;
        if (!seriesId) {
          throw new Error(ErrorType.InvalidParameter, 'no parameter seriesId');
        }
        return this.scheduleRecordingImpl(params, Resources.Series, seriesId);

      default:
        throw new Error(ErrorType.InvalidParameter, 'not supported recording type', params);
    }
  }

  private static scheduleRecordingImpl(params: PVRScheduleParameters, resourceType: Resources, resourceId: string): RequestQuery {
    if (!resources.isActionSupported(resourceType, 'Record')) {
      throw new Error(ErrorType.NotSupported, `Failed to create query to schedule a new recording - BO do not support Record action on ${resourceType} resource`);
    }
    const args: ScheduleRecordingQueryOptions = {
      Location: 'Network'
    };

    if (params.guardTimes?.start) {
      const preMinutesInScorm = convertSecondsToScorm(params.guardTimes.start * DateUtils.sInMin);
      if (preMinutesInScorm) {
        args.GuardTimeStart = preMinutesInScorm;
      }
    }

    if (params.guardTimes?.end) {
      const postMinutesInScorm = convertSecondsToScorm(params.guardTimes.end * DateUtils.sInMin);
      if (postMinutesInScorm) {
        args.GuardTimeEnd = postMinutesInScorm;
      }
    }

    if (params.startTime) {
      args.StartTime = convertJSDateToJSONDate(params.startTime);
    }

    if (params.type === RecordingType.Series) {
      switch (params.scope) {
        case PVRRecordingScope.OnlyNew:
          args.SeedEventId = params.event.id;
          break;
        case PVRRecordingScope.StartingFromSeason:
          args.SeedSeriesNumber = params.startingSeason;
          break;
      }
      if (typeof params.boundChannelId !== 'undefined') {
        args.SeedChannelId = params.boundChannelId;
      }
    }

    return queryUtils.createActionQuery({
      identity: {
        ProfileId: mw.customer.getProfile().id
      },
      actionName: 'Record',
      resourceType: resourceType,
      resourceId: resourceId,
      args,
      options: {
        props: queryProps.props.Recording.basic
      }
    });
  }

  public static deleteRecordings(recordings: Recording[]): RequestQuery {
    return TraxisQueries.prepareRecordingsAction(RecordingsAction.Delete, recordings);
  }

  public static cancelRecordings(recordings: Recording[]): RequestQuery {
    return TraxisQueries.prepareRecordingsAction(RecordingsAction.Cancel, recordings);
  }

  public static resumeRecordings(recordings: Recording[]): RequestQuery {
    return TraxisQueries.prepareRecordingsAction(RecordingsAction.Resume, recordings);
  }

  public static updateRecordings(recordings: Recording[], params: PVRUpdateParameters): RequestQuery {
    if (params.scope === PVRRecordingScope.OnlyNew && !params.event) {
      throw new Error(ErrorType.InvalidParameter, 'PVRRecordingScope.OnlyNew requires event in params');
    }
    const args: Arguments = params.scope != null ? {
      SeedEventId: params.scope === PVRRecordingScope.OnlyNew && params.event?.id ? params.event.id : 'delete',
      SeedSeriesNumber: params.scope === PVRRecordingScope.StartingFromSeason && typeof params.startingSeason !== 'undefined' ? params.startingSeason : 0
    } : {};

    if (params.guardTimes?.start) {
      const preMinutesInScorm = convertSecondsToScorm(params.guardTimes.start * DateUtils.sInMin);
      if (preMinutesInScorm) {
        args.GuardTimeStart = preMinutesInScorm;
      }
    }

    if (params.guardTimes?.end) {
      const postMinutesInScorm = convertSecondsToScorm(params.guardTimes.end * DateUtils.sInMin);
      if (postMinutesInScorm) {
        args.GuardTimeEnd = postMinutesInScorm;
      }
    }

    return TraxisQueries.prepareRecordingsAction(RecordingsAction.Update, recordings, {args});
  }

  public static getAllRecordingsStatuses(): GetRecordingsRequestHandler {
    const filters = `${Filters.isRecordingScheduled()}||${Filters.isRecordingRecorded()}`;
    return {
      requestFunction: () => queryUtils.createRootRelationQuery({
        identity: {ProfileId: mw.customer.getProfile().id},
        relationName: Relations.Recordings,
        query: {
          props: queryProps.props.Recording.status,
          filter: filters
        }
      }),
      responseMapper: TraxisQueries.mapRecordings
    };
  }

  public static getRecordings(params: PVRQueryParameters): GetRecordingsRequestHandler {
    if (!!params.parentRecording) {
      return {
        requestFunction: TraxisQueries.getNPVRChildRecordings,
        responseMapper: response => TraxisQueries.mapChildRecordings(response, params.parentRecording)
      };
    }

    return {
      requestFunction: params.media ? TraxisQueries.getNPVRRecordingsDetails : TraxisQueries.getNPVRRecordings,
      responseMapper: TraxisQueries.mapRecordings
    };
  }

  public static mapRecordings(response: ResponseJson): Recording[] {
    const recordingsJson = response?.Recording ? makeArray(response?.Recording) : response?.Recordings?.Recording;
    return recordingsJson ? RecordingMapper.fromJSONArray(recordingsJson) : [];
  }

  private static mapChildRecordings(response: ResponseJson, parentRecording?: Recording): Recording[] {
    const recordingsJson = response?.Recording?.ChildRecordings?.Recording;
    return recordingsJson ? RecordingMapper.fromJSONArray(recordingsJson, parentRecording) : [];
  }

  private static getNPVRChildRecordings(params: PVRQueryParameters): RequestQuery {
    const {parentRecording} = params;
    if (!parentRecording) {
      throw new Error(ErrorType.InvalidParameter, 'no parentRecording set');
    }

    const options: any = {
      ChildRecordings: {
        props: queryProps.props.Recording.list,
        Titles: {
          props: queryProps.props.Title.rootRecording,
          ...TraxisQueries.seriesCollectionSubquery()
        },
        ...TraxisQueries.seriesCollectionSubquery()
      }
    };

    if (params.status != null) {
      // children level filter, e.g. for status === 'Recorded',
      // show only recorded episodes or seasons that have recorded episodes
      options.ChildRecordings.filter = [
        TraxisQueries.seriesRecordingFilter(params.status),
        TraxisQueries.singleRecordingFilter(params.status)
      ]
        .map(intoBrackets)
        .join('||');

      // grandChildren level filter, show only episodes that match status
      options.ChildRecordings.ChildRecordings = {
        props: queryProps.props.Recording.basic,
        filter: TraxisQueries.singleRecordingFilter(params.status),
        Titles: {
          props: 'Pictures'
        }
      };
    }

    if (params.sortBy) {
      options.ChildRecordings.sort = TraxisQueries.getRecordingsSorting({sortBy: params.sortBy, status: params.status});
    }

    return queryUtils.createResourceQuery({
      identity: {
        ProfileId: mw.customer.getProfile().id
      },
      resourceType: Resources.Recording,
      resourceId: parentRecording.id,
      query: options
    });
  }

  private static getNPVRRecordingsDetails(params: PVRQueryParameters): RequestQuery {
    if (!params.media) {
      throw new Error(ErrorType.InvalidParameter);
    }

    const subFilters = [];

    if (Array.isArray(params.media)) {
      subFilters.push(`((${params.media.map(event => Filters.byEventID(event.id)).join('||')})&&${Filters.isSingle})`);
    } else {
      if (params.type !== RecordingType.Series && isEvent(params.media)) {
        subFilters.push(`${Filters.byEventID(params.media.id)}&&${Filters.isSingle}`);
      }

      const {episode} = getMediaSubtypes(params.media);
      const seriesId = isSeries(params.media) ? params.media.id : episode?.seriesId;

      if (seriesId && params.type !== RecordingType.Single) {
        subFilters.push(`${Filters.bySeriesID(seriesId)}&&${Filters.isSeries}`);
      }
    }

    const filter = `${subFilters.map(filter => `(${filter})`).join('||')}`;

    const options = {
      props: queryProps.props.Recording.details,
      Titles: {
        props: queryProps.props.Title.extendedRecording,
        SeriesCollection: {
          props: queryProps.props.Series.minimal,
          ParentSeriesCollection: {
            props: queryProps.props.Series.minimal
          }
        }
      },
      Contents: {
        props: queryProps.props.Content.entitlements,
        filter: Filters.isViewable
      },
      ParentRecordings: {
        props: queryProps.props.Recording.parent
      },
      filter
    };

    return queryUtils.createRootRelationQuery({
      identity: {ProfileId: mw.customer.getProfile().id},
      relationName: 'Recordings',
      query: options
    });
  }

  public static getRecordingStatus(events: Event[]): RequestQuery {
    const filters: string = [
      `(${Filters.isRecordingScheduled()}||${Filters.isRecordingRecorded()})`,
      `(${events.map(({id}) => (`EventId=="${id}"`)).join(`||`)})`
    ].join('&&');
    return queryUtils.createRootRelationQuery({
      identity: {ProfileId: mw.customer.getProfile().id},
      relationName: Relations.Recordings,
      query: {
        props: queryProps.props.Recording.status,
        filter: filters
      }
    });
  }

  public static getIsRecorded(events: Event[]): RequestQuery {
    return queryUtils.createResourcesQuery({
      identity: {ProfileId: mw.customer.getProfile().id},
      resourceType: Resources.Event,
      resourceIds: events.map(({id}) => ({id})),
      query: {props: 'IsRecorded'}
    });
  }

  private static seriesCollectionSubquery() {
    return {
      SeriesCollection: {
        props: queryProps.props.Series.seriesCollection,
        ParentSeriesCollection: {
          props: queryProps.props.Series.seriesCollection
        },
        ChildSeriesCollection: {
          props: queryProps.props.Series.seriesCollection
        }
      }
    };
  }

  private static singleRecordingFilter = (status?: RecordingStatus) => {
    const filters = [Filters.isSingle];
    if (status != null) {
      filters.push(status === RecordingStatus.Scheduled
        ? Filters.isRecordingScheduled()
        : Filters.isRecordingRecorded()
      );
    }
    return filters.join('&&');
  };

  private static seriesRecordingFilter = (status?: RecordingStatus) => {
    const filters = [Filters.isSeries, Filters.hasChildRecordings];
    if (status === RecordingStatus.Scheduled) {
      filters.push(Filters.isSeriesActive);
    }
    return filters.join('&&');
  };

  private static getNPVRRecordings(params: PVRQueryParameters): RequestQuery {
    const singleFilter = !params.type || params.type === RecordingType.Single
      ? TraxisQueries.singleRecordingFilter(params.status)
      : undefined;
    const seriesFilter = !params.type || params.type === RecordingType.Series
      ? TraxisQueries.seriesRecordingFilter(params.status)
      : undefined;

    const typeAndStatusFilter = `(${compactMap([singleFilter, seriesFilter].filter(element => !!element), intoBrackets).join('||')})`;
    const contentFilters = [Filters.isViewable, ...TraxisQueries.getAdultFilters()];
    const shouldAddSeriesSubqueries = !params.type || params.type === RecordingType.Series;

    const options: any = {
      props: queryProps.props.Recording.list,
      Titles: {
        props: queryProps.props.Title.rootRecording,
        SeriesCollection: {
          props: queryProps.props.Series.seriesCollection,
          ParentSeriesCollection: {
            props: queryProps.props.Series.seriesCollection
          }
        }
      },
      ...shouldAddSeriesSubqueries && TraxisQueries.seriesCollectionSubquery(),
      Contents: {
        props: queryProps.props.Content.preview,
        filter: contentFilters.join('&&')
      },
      Channels: {
        props: 'Pictures'
      }
    };

    if (shouldAddSeriesSubqueries) {
      // first level of child recordings can either be series or events, use similar filter as in root query
      options.ChildRecordings = {
        props: queryProps.props.Recording.basic,
        Titles: {
          props: queryProps.props.Title.rootRecording
        },
        filter: typeAndStatusFilter
      };
      // recordings have at most two levels of children, last level will only be events
      options.ChildRecordings.ChildRecordings = {
        limit: 1,
        filter: singleFilter
      };
    }

    if (typeof params.offset !== 'undefined') {
      options.paging = queryUtils.createPagingParameter({
        offset: params.offset,
        pageSize: params.limit || DefaultRecordingsPageSize
      });
    } else if (typeof params.limit !== 'undefined') {
      options.limit = params.limit;
    }

    if (params.sortBy) {
      options.sort = TraxisQueries.getRecordingsSorting({sortBy: params.sortBy, status: params.status});
    }

    if (params.id) {
      return queryUtils.createResourceQuery({
        identity: {ProfileId: mw.customer.getProfile().id},
        resourceType: Resources.Recording,
        resourceId: params.id,
        query: options
      });
    } else {
      options.filter = `${Filters.parentRecordingCount(0)}&&${typeAndStatusFilter}`;
      return queryUtils.createRootRelationQuery({
        identity: {ProfileId: mw.customer.getProfile().id},
        relationName: Relations.Recordings,
        query: options
      });
    }
  }

  private static getRecordingsSorting({sortBy, status}: {sortBy: Required<PVRQueryParameters>['sortBy']; status: PVRQueryParameters['status']}): string {
    const prefix = sortBy.ascending ? '' : '~';
    switch (sortBy.type) {
      case SortRecordingsBy.create:
        return prefix + SortByOptions.CreateTime;
      case SortRecordingsBy.name:
        return prefix + SortByOptions.Name;
      case SortRecordingsBy.start:
        switch (status) {
          case RecordingStatus.Recorded:
            return prefix + SortByOptions.PreviouslyScheduledStartTime;
          case RecordingStatus.Scheduled:
            return `${prefix}${SortByOptions.StartTime},${prefix}${SortByOptions.NextScheduledStartTime}`;
        }
      case SortRecordingsBy.lastViewDate:
        return prefix + SortByOptions.LastViewDate;
      default:
        Log.error(TAG, 'getRecordingsSort: not implemented sortBy.type:', sortBy);
        throw new Error(ErrorType.InvalidParameter);
    }
  }

  private static prepareSearchProperty(params: SearchQueryParameters, searchFields?: string[]): string {
    const term = splitSearchTerm(params.term).join(' AND ') + '*';
    const fields = searchFields ?? flatten(TraxisQueries.mapSearchFields(params.searchFields ? params.searchFields : ['title', 'castAndCrew', 'description']));
    return fields.map(field => `${field}:(${term})`).join(' OR ');
  }

  private static preparePvrFilterProperty(params: SearchQueryParameters, searchFields?: string[]): string {
    const terms = splitSearchTerm(params.term);
    // We should also search for castAndCrew but we do not - this is a dirty workaround for GCI requested in CL-5455
    const fields = searchFields ?? flatten(TraxisQueries.mapPvrFilterFields(params.searchFields ? params.searchFields : ['title']));

    return fields.map(field => {
      const termParts = terms.map(term => `(${field}^${term})`);
      return `(${termParts.join('&&')})`;
    }).join('||');
  }

  private static mapSearchFields(fields: SearchField[]): string[][] {
    return fields.map(field => {
      switch (field) {
        case 'title':
          return ['Name', 'OriginalName'];
        case 'castAndCrew':
          return ['Directors', 'Actors', 'Characters', 'ActorsCharacters'];
        case 'description':
          return ['ShortSynopsis', 'MediumSynopsis', 'LongSynopsis'];
      }
    });
  }

  private static mapPvrFilterFields(fields: SearchField[]): string[][] {
    return fields.map(field => {
      switch (field) {
        case 'title':
          return ['Name', 'OriginalName'];
        case 'castAndCrew':
          return ['Directors', 'Actors'];
        case 'description':
          return ['Name', 'OriginalName'];
      }
    });
  }

  private static mapMediaToResources(mediaArray: Media[], args: ArgumentsQuery = {}): ActionQueryResources[] {
    return mediaArray.map((media): ActionQueryResources => {
      return {
        id: media.id,
        ...args
      };
    });
  }

  private static mapMediaIdsToResources(mediaIds: string[]): ActionQueryResources[] {
    return mediaIds.map((id): ActionQueryResources => {
      return {id};
    });
  }

  private static prepareRecordingsAction(action: RecordingsAction, recordings: Recording[], args: ArgumentsQuery = {}): RequestQuery {
    return queryUtils.createActionsQuery({
      identity: {
        ProfileId: mw.customer.getProfile().id
      },
      actionName: action,
      resourceType: Resources.Recording,
      resources: TraxisQueries.mapMediaToResources(recordings, args)
    });
  }

  private static prepareSortParameter(sorting?: ChannelSorting | VodSorting): string | undefined {
    let sortingString: string | undefined;
    if (sorting) {
      sortingString = sorting.type === SortChannelBy.name || sorting.type === SortVodBy.title ? Props.Name : Props.ProductionDate;
      if (!sorting.ascending) {
        sortingString = sortDescending(sortingString);
      }
    }
    return sortingString;
  }

  private static prepareWatchListAction(actionName: WatchListAction, resourceType: string, profileId: string, mediaIds: string[]): RequestQuery {
    return queryUtils.createActionsQuery({
      identity: {
        ProfileId: profileId
      },
      actionName,
      resourceType,
      resources: TraxisQueries.mapMediaIdsToResources(mediaIds)
    });
  }

  public static getCustomer(): RequestQuery {
    const query = {
      props: queryProps.props.Customer.basic
    };

    return queryUtils.createRootRelationQuery({
      identity: boProxy.sso.getIdentity().get(),
      relationName: Relations.Customers,
      query: query
    });
  }

  public static getDevices(): RequestQuery {
    const query = {
      props: queryProps.props.Cpe.basic
    };

    return queryUtils.createRootRelationQuery({
      identity: boProxy.sso.getIdentity().get(),
      relationName: Relations.Cpes,
      query: query
    });
  }

  public static registerDevice(device: Device): RequestQuery {
    const args: Arguments = {
      Id: device.id,
      Name: device.name
    };

    return queryUtils.createRootActionQuery({
      identity: boProxy.sso.getIdentity().get(),
      actionName: RootActions.AddCpeToCustomer,
      args
    });
  }

  private static mapDevicesToResources(devices: Device[]) {
    return devices.map((device) => ({
      id: device.id
    }));
  }

  public static unregisterDevices(devices: Device[]): RequestQuery {
    return queryUtils.createActionsQuery({
      identity: boProxy.sso.getIdentity().get(),
      actionName: 'Delete',
      resourceType: Resources.Cpe,
      resources: TraxisQueries.mapDevicesToResources(devices)
    });
  }

  public static getEntitledProducts(): RequestQuery {
    const query = {
      props: queryProps.props.Product.personal
    };

    return queryUtils.createRootRelationQuery({
      identity: {
        ...boProxy.sso.getIdentity().get(),
        CpeId: DeviceManager.getInstance().getId()
      },
      relationName: Relations.PurchasedProducts,
      query: query
    });
  }

  public static getCAPMessage(params: CAPQueryParameters): RequestQuery {
    const query: any = {
      props: queryProps.props.Message.basic,
      CAP: {
        props: queryProps.props.CAP.basic,
        CAPInfos: {
          props: queryProps.props.CAPInfo.basic
        }
      }
    };
    if (params.languages?.length) {
      query.CAP.CAPInfos.filter = params.languages
        .map(language => `Language==${language}`)
        .join('||');
    }
    return queryUtils.createResourceQuery({
      resourceType: Resources.Message,
      resourceId: params.id,
      query
    });
  }

  public static getCategoriesWithIsAdult(resourcesParams: ResourcesParams[]): RequestQuery {
    const query = {
      props: queryProps.props.Category.adults
    };

    return queryUtils.createResourcesQuery({
      identity: {ProfileId: mw.customer.getProfile().id},
      resourceType: Resources.Category,
      resourceIds: resourcesParams,
      query
    });
  }
}
