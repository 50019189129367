import {createStyles} from 'common-styles';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View, ViewStyle, StyleProp} from 'react-native';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Product} from 'mw/api/Metadata';

import NitroxText from 'components/NitroxText';
import ProductsList from 'components/payments/ProductsList';

const styles = createStyles({
  productsList: {
    marginTop: dimensions.margins.xxxLarge,
    marginBottom: dimensions.margins.xLarge
  }
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  text: {
    color: colors.popup.text,
    textAlign: 'center'
  }
}));

type Props = {
  style: StyleProp<ViewStyle>;
  products: Product[];
  selectedProductId?: string;
  onProductSelect: (selectedProductId: string) => void;
};

const SelectProduct: React.FunctionComponent<Props> = props => {
  const {
    style,
    products,
    selectedProductId,
    onProductSelect
  } = props;

  const {t} = useTranslation();
  const dynamicStyle = stylesUpdater.getStyles();

  return (
    <View style={style}>
      <NitroxText
        textType={'title1'}
        style={dynamicStyle.text}
      >
        {t('payments.dialog.chooseOption')}
      </NitroxText>
      <ProductsList
        style={styles.productsList}
        products={products}
        selectedProductId={selectedProductId}
        onPress={onProductSelect}
      />
    </View>
  );
};

export default SelectProduct;
