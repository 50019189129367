import {createStyles} from 'common-styles';
import React from 'react';
import {View} from 'react-native';

import {dimensions} from 'common/constants';
import {humanCaseToSnakeCase} from 'common/HelperFunctions';

import FocusParent from 'components/FocusParent';
import SettingsButton from 'screens/settings/SettingsButton';

import {SettingsOptionProps} from './SettingsClickableOption';
import SettingsLabel from './SettingsLabel';

const buttonWidth = 385;

const styles = createStyles({
  interactiveWrapper: {
    alignSelf: 'stretch'
  },
  container: {
    alignItems: 'center',
    alignSelf: 'stretch',
    marginBottom: dimensions.margins.xxxLarge,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  settingsButton: {
    minWidth: buttonWidth
  }
});

const SettingsClickableOptionContent: React.FC<SettingsOptionProps> = props => {
  const {title, type, buttonLabel, onPress} = props;
  const testID = type ? `button_${humanCaseToSnakeCase(type)}` : undefined;

  return (
    <FocusParent
      style={styles.interactiveWrapper}
      debugName='SettingsClickableOptionContent'
    >
      <View style={styles.container}>
        <SettingsLabel label={title} />
        <SettingsButton
          text={buttonLabel}
          onPress={onPress}
          testID={testID}
          style={styles.settingsButton}
        />
      </View>
    </FocusParent>
  );
};

export default SettingsClickableOptionContent;
