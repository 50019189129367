import {EventEmitterInterface} from 'common/EventEmitterInterface';

import {Media, Playable, ContentType} from 'mw/api/Metadata';
import {PlayerEvent} from 'mw/api/PlayerEvent';

export interface EventAdditionalParams {
  playable?: Playable;
  contentType?: ContentType;
  media: Media;
}

export enum ReportingEvent {
  Unauthorized
}

export interface ReporterInterface extends EventEmitterInterface<ReportingEvent> {
  handleLoginEvent: () => void;
  handleLogoutEvent: () => void;
  handleEvent: (event: any, eventParams: any, additionalParams: EventAdditionalParams) => void;
}

export type ReporterEvent = PlayerEvent;
