import {createStyles, defaultStyles} from 'common-styles';
import React, {useMemo, useState, useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import {NavigationInjectedProps, ScrollView} from 'react-navigation';

import {dimensions, RADIUS, isWeb, isMobile, isBigScreen} from 'common/constants';
import {DateUtils} from 'common/DateUtils';
import {openMediaDetails} from 'common/HelperFunctions';
import {useSafeAwait} from 'common/hooks/Hooks';
import {formatCurrency} from 'common/utils';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Error, ErrorType} from 'mw/api/Error';
import {Product, Order, OrderStatus, Media, PaymentMethodId} from 'mw/api/Metadata';
import {boProxy} from 'mw/bo-proxy/BOProxy';
import {mw} from 'mw/MW';

import {constColors} from 'brand/ColorTypes';
import BackButton from 'components/BackButton';
import ErrorPopup, {useErrorPopup} from 'components/ErrorPopup';
import FocusParent, {useFocusParent} from 'components/FocusParent';
import NitroxButton from 'components/NitroxButton';
import {NitroxInteractiveController} from 'components/NitroxInteractiveControllerContext';
import NitroxText from 'components/NitroxText';
import PaymentWizard from 'components/payments/PaymentWizard';
import Popup, {PopupAction} from 'components/Popup';
import {useToggle} from 'hooks/Hooks';
import i18n from 'locales/i18n';
import {PostProcessors} from 'locales/i18nPostProcessors';
import NitroxScreen, {useNitroxScreenBackCapture} from 'screens/NitroxScreen';

import {PAGE_SIZE} from './settingsOrder/SettingsOrderProps';
import {StatusLabel} from './settingsOrder/StatusLabel';
import Table, {HeadCellProps, ContentProps, HeadCellType} from './settingsOrder/Table';

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    ...defaultStyles.view,
    justifyContent: 'flex-start',
    borderTopLeftRadius: isBigScreen ? 40 : undefined,
    backgroundColor: isMobile ? colors.settingsScreen.background : colors.settingsScreen.transparentBackground,
    paddingHorizontal: dimensions.margins.xxLarge,
    color: constColors.white
  },
  headerTitle: {
    position: 'absolute',
    left: 0,
    right: 0,
    color: colors.screenHeader.title,
    textAlign: 'center'
  },
  paymentNotCompletedContainer: {
    backgroundColor: colors.payments.orderDetails.background,
    borderRadius: RADIUS,
    paddingVertical: dimensions.margins.medium,
    paddingHorizontal: dimensions.margins.large,
    marginTop: dimensions.margins.small,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  priceLabel: {
    backgroundColor: colors.payments.orderDetails.price.background,
    flexDirection: 'row',
    paddingHorizontal: dimensions.margins.medium,
    paddingVertical: dimensions.margins.xsmall,
    borderRadius: RADIUS,
    alignItems: 'center',
    justifyContent: 'center'
  },
  subtitle: {
    color: colors.payments.orderDetails.subtitle.text
  },
  whiteText: {
    color: constColors.white
  },
  confirmation: {
    color: colors.popup.text,
    marginTop: dimensions.margins.large,
    marginBottom: dimensions.margins.large,
    textAlign: 'center'
  }
}));

const styles = createStyles({
  backButton: {
    zIndex: 1,
    left: 0
  },
  dateContainer: {
    marginBottom: dimensions.margins.small
  },
  detailsRow: {
    flexDirection: 'row'
  },
  description: {
    marginBottom: dimensions.margins.xxxLarge,
    marginTop: dimensions.margins.large
  },
  headerContainer: {
    height: dimensions.screen.header.height,
    justifyContent: 'center'
  },
  priceContainer: {
    alignItems: 'center',
    justifyContent: 'center'
  }
});

type Props = {
  navigators?: {settingsOrders: () => void};
  params?: Order;
} & NavigationInjectedProps

const getConfig = (createButton: (row: Media) => JSX.Element): HeadCellProps<Media>[] => {
  const getIndex = (index: number, page: number) => `${(page - 1) * PAGE_SIZE + index + 1}`;

  return [
    {width: 80, head: 'settings.orders.table.number', content: ({index, page}: ContentProps<Media>) => getIndex(index, page), type: HeadCellType.Default},
    {head: 'settings.orders.table.orderItems', content: ({row}: ContentProps<Media>) => row.name || '', type: HeadCellType.Left},
    {width: 280, content: ({row}: ContentProps<Media>) => createButton(row)}
  ];
};

const formatOrderPrice = (order: Order) => {
  return (order.price && order.currency)
    ? formatCurrency(order.price, order.currency, i18n.language)
    : order.price ?? '';
};

const OrderDetails: React.FC<Props> = ({navigators, navigation, params: orderProps}) => {
  const dynamicStyles = dynamicStylesUpdater.getStyles();
  const {t} = useTranslation();
  const [order, setOrder] = useState<Order>();
  const [product, setProduct] = useState<Product>();
  const [media, setMedia] = useState<Media>();
  const [loading, setLoading] = useState(true);
  const [isPaymentPopupVisible, setIsPaymentPopupVisible] = useState(false);
  const closePaymentPopup = useCallback(() => setIsPaymentPopupVisible(false), []);
  const openPaymentPopup = useCallback(() => setIsPaymentPopupVisible(true), []);
  const [onReady, focus] = useFocusParent();
  const safeAwait = useSafeAwait();
  const onPaymentSucces = useCallback((order?: Order) => order && setOrder(order), []);
  const [isConfirmationPopupVisible, {on: showConfirmationPopup, off: closeConfirmationPopup}] = useToggle(false);
  const {error, showError, onCloseErrorPopup} = useErrorPopup();
  const [cancelationError, setCancelationError] = useState<Error>();
  const onBackButtonPressed = () => {
    navigators?.settingsOrders();
    return false;
  };

  const fetchData = useCallback(async () => {
    const product = orderProps?.productId && await safeAwait(boProxy.bo.getProductById(orderProps.productId));
    const media = orderProps?.mediaId && await safeAwait(boProxy.bo.getAssetById(orderProps.mediaId));
    product && setProduct(product);
    media && setMedia(media);
    product && setLoading(false);
  }, [orderProps, safeAwait]);

  useEffect(() => {
    setOrder(orderProps);
    fetchData();
  }, [fetchData, orderProps]);

  useEffect(() => focus(), [focus]);

  const handleOpenMediaDetails = useCallback((media: Media) => {
    openMediaDetails(navigation, media.id, media.getType());
  }, [navigation]);

  const createButton = useCallback((row: Media) => (
    <NitroxButton
      onPress={() => handleOpenMediaDetails(row)}
      border
      text={t('common.moreInfo', {postProcess: PostProcessors.ToUpperCase})}
    />
  ), [handleOpenMediaDetails, t]);

  const tableConfig: HeadCellProps<Media>[] = useMemo(() => getConfig(createButton), [createButton]);

  useNitroxScreenBackCapture(() => {
    navigators?.settingsOrders();
    return true;
  });

  const cancelOrder = useCallback(() => {
    if (!order) {
      return;
    }
    safeAwait(mw.catalog.cancelOrder(order.id))
      .then(() => {
        navigators?.settingsOrders();
      })
      .catch((error: Error) => {
        setCancelationError(error);
        showError({
          title: t('payments.orderDetails.cancelErrorTitle'),
          message: t(error.type === ErrorType.OrderInvalidState ? 'payments.orderDetails.cancelErrorOrderStatusMessage' : 'payments.orderDetails.cancelErrorGenericMessage')
        });
      });
  }, [navigators, order, safeAwait, showError, t]);

  const closeErrorPopup = useCallback(() => {
    onCloseErrorPopup();
    if (cancelationError?.type !== ErrorType.OrderInvalidState) {
      navigators?.settingsOrders();
    }
  }, [cancelationError, navigators, onCloseErrorPopup]);

  const shouldDisplayRepaymentButton = useMemo(() => isWeb && (order?.orderStatus === OrderStatus.PaymentFailed)
    && order?.paymentMethodId === PaymentMethodId.Stripe, [order]);
  const shouldDisplayCancelButton = useMemo(() => isWeb && (order?.orderStatus === OrderStatus.Unpaid)
    && order?.paymentMethodId === PaymentMethodId.Stripe, [order]);
  const Wrapper = isWeb ? FocusParent : View;

  return (
    <NitroxScreen
      onScreenFocused={focus}
      navigation={navigation}
      testID='screen_settings'
      embedded
    >
      <FocusParent style={dynamicStyles.container} rememberLastFocused onReady={onReady}>
        <NitroxInteractiveController omitGeometryCaching>
          <ScrollView showsVerticalScrollIndicator={false}>
            <FocusParent style={styles.headerContainer}>
              <BackButton
                style={styles.backButton}
                onPress={onBackButtonPressed}
                hasTVPreferredFocus
              />
              <NitroxText textType='headline' style={dynamicStyles.headerTitle}>{t('settings.orders.title')}</NitroxText>
            </FocusParent>
            {product && order && !loading && (
              <>
                <View style={styles.detailsRow}>
                  <NitroxText style={dynamicStyles.whiteText} textType='subhead'>{t('payments.orderDetails.detailsFor')}</NitroxText>
                  <NitroxText style={dynamicStyles.whiteText} textType='subhead-bold'>{order.id}</NitroxText>
                </View>
                <View style={styles.detailsRow}>
                  <NitroxText style={dynamicStyles.whiteText} textType='title'>{`${product.name} - `}</NitroxText>
                  <View style={styles.priceContainer}>
                    <View style={dynamicStyles.priceLabel}>
                      <NitroxText style={dynamicStyles.whiteText} textType='description'>
                        {t('payments.orderDetails.price')}
                      </NitroxText>
                      <NitroxText style={dynamicStyles.whiteText} textType='title1'>
                        {formatOrderPrice(order)}
                      </NitroxText>
                    </View>
                  </View>
                </View>
                <View style={[styles.detailsRow, styles.dateContainer]}>
                  <NitroxText style={dynamicStyles.subtitle} textType='subhead'>{t('payments.orderDetails.date')}</NitroxText>
                  <NitroxText style={dynamicStyles.subtitle} textType='subhead'>{DateUtils.formatDate(order.creationDate, mw.configuration.dateFormat)}</NitroxText>
                </View>
                <View style={styles.detailsRow}>
                  <NitroxText style={dynamicStyles.subtitle} textType='subhead'>{t('payments.orderDetails.status')}</NitroxText>
                  <StatusLabel status={order.orderStatus} />
                </View>
                {(shouldDisplayRepaymentButton || shouldDisplayCancelButton) && media && (
                  <Wrapper style={[styles.detailsRow, dynamicStyles.paymentNotCompletedContainer]}>
                    {shouldDisplayRepaymentButton && (
                      <>
                        <StatusLabel status={OrderStatus.Failed} errorText={t('payments.orderDetails.payDescription')} />
                        <NitroxButton
                          border
                          onPress={openPaymentPopup}
                          text={t('payments.orderDetails.pay', {postProcess: PostProcessors.ToUpperCase})}
                        />
                      </>
                    )}
                    {shouldDisplayCancelButton && (
                      <>
                        <StatusLabel status={OrderStatus.Canceled} errorText={t('payments.orderDetails.cancelDescription')} />
                        <NitroxButton
                          border
                          onPress={showConfirmationPopup}
                          text={t('payments.orderDetails.cancel', {postProcess: PostProcessors.ToUpperCase})}
                        />
                      </>
                    )}
                  </Wrapper>
                )}
                <View style={styles.description}>
                  <NitroxText style={dynamicStyles.whiteText} textType='description'>{product.description}</NitroxText>
                </View>
                {media && <Table<Media> data={[media]} page={1} loading={loading} tableConfig={tableConfig} />}
                {shouldDisplayRepaymentButton && media && (
                  <PaymentWizard
                    visible={isPaymentPopupVisible}
                    products={[product]}
                    media={media}
                    onClose={closePaymentPopup}
                    onPaymentSuccess={onPaymentSucces}
                    order={order}
                  />
                )}
                <Popup
                  actions={[PopupAction.NEGATIVE, PopupAction.POSITIVE]}
                  visible={isConfirmationPopupVisible}
                  onClose={closeConfirmationPopup}
                  positiveLabel={t('common.confirm')}
                  negativeLabel={t('common.close')}
                  onPositive={cancelOrder}
                >
                  <NitroxText style={dynamicStyles.confirmation} textType='body1'>{t('payments.orderDetails.cancelConfirmation')}</NitroxText>
                </Popup>
                <ErrorPopup
                  error={error}
                  onClose={closeErrorPopup}
                />
              </>
            )}
          </ScrollView>
        </NitroxInteractiveController>
      </FocusParent>
    </NitroxScreen>
  );
};

export default OrderDetails;
