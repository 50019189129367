import {createStyles} from 'common-styles';
import i18next from 'i18next';
import React, {useState, useMemo, useCallback, useRef, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions, isBigScreen, isTablet} from 'common/constants';
import {DateUtils} from 'common/DateUtils';
import {compareObjectsKeys} from 'common/HelperFunctions';
import {Log} from 'common/Log';
import {formatCurrency, isAsyncIterator} from 'common/utils';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {StartPaymentParams} from 'mw/api/CatalogInterface';
import {Error as MWError, ErrorType} from 'mw/api/Error';
import {Media, Order, PaymentMethodId, Product, PurchaseMethod, StripeProperties} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import {Icon, IconType} from 'components/Icon';
import NitroxText from 'components/NitroxText';
import Popup, {PopupAction} from 'components/Popup';
import {useChangeEffect, useDisposable, useScreenInfo} from 'hooks/Hooks';

import Stripe from './checkout/stripe/Stripe';
import {StripeInterface, StripeError} from './checkout/stripe/Types';
import {getTransactionErrorMessage} from './PaymentHelperFunctions';
import {maxContainerWidth, arrowContainerWidth, visibleElementsCount, getBestOffer} from './ProductsList';
import MakeOrder from './WizardSteps/MakeOrder';
import SelectedProduct from './WizardSteps/SelectedProduct';
import SelectProduct from './WizardSteps/SelectProduct';

const TAG = 'PaymentWizard';
const popupButtonWidth = 238;
const popupButtonTabletPortraitWidth = 216;
const marginHorizontal = dimensions.margins.xxLarge + dimensions.margins.large;
const styles = createStyles({
  stepContainer: {
    marginHorizontal: isBigScreen ? dimensions.margins.large : 0
  },
  menuButton: {
    width: popupButtonWidth,
    height: dimensions.popup.button.height,
    margin: dimensions.margins.small
  },
  confirmation: {
    marginVertical: dimensions.margins.xxLarge,
    alignItems: 'center'
  },
  error: {
    marginTop: dimensions.margins.xxLarge
  }
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    backgroundColor: colors.payments.wizard.background
  },
  menu: {
    ...isBigScreen && {backgroundColor: colors.payments.wizard.menuBackground},
    justifyContent: 'space-evenly'
  },
  text: {
    color: colors.popup.text,
    textAlign: 'center'
  },
  subtitle: {
    color: colors.popup.subtitle,
    textAlign: 'center'
  }
}));

type StripeElementChangeEvent = {
  empty: boolean;
  complete: boolean;
  error: any;
}

const initialCardStatus: StripeElementChangeEvent = {
  empty: true,
  complete: false,
  error: undefined
};

enum StripeErrorType {
  ApiConnectionError = 'api_connection_error',
  ApiError = 'api_error',
  CardDeclined = 'card_declined',
  DeclineCode = 'decline_code'
}

function isSamePayment(a: StartPaymentParams, b: StartPaymentParams): boolean {
  return compareObjectsKeys(a, b, 'productId', 'assetUid', 'paymentMethodId', 'currency', 'price');
}

function getErrorCode(t: i18next.TFunction, error?: Error): string | undefined {
  if (error instanceof MWError) {
    return t('common.errorCode', {errorCode: error.type});
  }
}

function getStripeErrorMessage(t: i18next.TFunction, error: StripeError): string {
  if (error.type === StripeErrorType.ApiConnectionError || error.type === StripeErrorType.ApiError) {
    return t(`payments.error.type.${error.type}`);
  }
  if (error.code) {
    const fallbackMessage = t('payments.dialog.error.notAvailable');
    if (error.code === StripeErrorType.CardDeclined) {
      return t(`payments.error.decline_code.${error.declineCode}`, fallbackMessage);
    }
    return t(`payments.error.code.${error.code}`, fallbackMessage);
  }
  return t('payments.error.other', {
    code: error.code,
    type: error.type,
    message: error.message
  });
}

type PopupError = {
  title: string;
  message: string;
  code?: string;
}

function getPopupError(t: i18next.TFunction, error?: Error): PopupError {
  if (error instanceof StripeError) {
    return {
      title: t('payments.dialog.error.noAuthorization'),
      message: getStripeErrorMessage(t, error)
    };
  }

  if (error instanceof MWError) {
    switch (error.type) {
      case ErrorType.TransactionLocationForbidden:
      case ErrorType.TransactionVPNForbidden:
      case ErrorType.TransactionNotFinalized:
      case ErrorType.TransactionFailed:
        return {
          title: t('payments.dialog.error.noAuthorization'),
          message: getTransactionErrorMessage(t, error)
        };
      case ErrorType.OrderNotFound:
      case ErrorType.OrderInvalidState:
        return {
          title: t('payments.error.unexpected.title'),
          message: t('payments.error.unexpected.message'),
          code: getErrorCode(t, error)
        };
      case ErrorType.NetworkNoConnection:
        return {
          title: t('payments.error.network.title'),
          message: t('payments.error.network.message')
        };
    }
  }

  return {
    title: t('payments.error.generic'),
    message: t('payments.error.genericMessage'),
    code: getErrorCode(t, error)
  };
}

function showRetryButton(error?: Error): boolean {
  return error instanceof StripeError && (error.type === StripeErrorType.ApiConnectionError || error.type === StripeErrorType.ApiError);
}

enum Step {
  SelectProduct,
  MakeOrder,
  Repayment,
  PaymentInProgress,
  PaymentConfirmed,
  Error
}

export type PaymentWizardProps = {
  visible: boolean;
  products: Product[];
  media: Media;
  onClose: (purchasedMedia?: Media) => void;
  onPaymentSuccess: (order?: Order, productId?: string, offerId?: string, purchasedMedia?: Media) => void;
  order?: Order;
};

const PaymentWizard: React.FunctionComponent<PaymentWizardProps> = props => {
  const {
    visible,
    products,
    media,
    onClose: propsOnClose,
    onPaymentSuccess: propsOnPaymentSuccess,
    order: failedOrder
  } = props;

  const paymentMethodId = mw.customer.paymentMethods[0]?.id;

  const {t, i18n} = useTranslation();
  const dynamicStyle = stylesUpdater.getStyles();
  const {orientation} = useScreenInfo();
  const menuButtonStyle = [styles.menuButton, {width: isTablet && orientation.isPortrait ? popupButtonTabletPortraitWidth : popupButtonWidth}];

  const stripeRef = useRef<StripeInterface>(null);

  const [step, setStep] = useState(products.length > 1 ? Step.SelectProduct : (failedOrder ? Step.Repayment : Step.MakeOrder));
  const [stripeProperties, setStripeProperties] = useState<StripeProperties | null>(null);
  const [stripePaymentInProgress, setStripePaymentInProgress] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(products.length === 1 ? products[0].id : '');
  const [error, setError] = useState<Error>();
  const [orderId, setOrderId] = useState<string>();
  const [order, setOrder] = useState<Order>();
  const [purchasedMedia, setPurchasedMedia] = useState<Media>();

  const [cardNumberStatus, setCardNumberStatus] = useState(initialCardStatus);
  const [cardExpiryStatus, setCardExpiryStatus] = useState(initialCardStatus);
  const [cardCvcStatus, setCardCvcStatus] = useState(initialCardStatus);
  const [formValid, setFormValid] = useState(false);

  const onCardNumberChange = useCallback((event: StripeElementChangeEvent) => setCardNumberStatus(event), []);
  const onExpirationDateChange = useCallback((event: StripeElementChangeEvent) => setCardExpiryStatus(event), []);
  const onCvcChange = useCallback((event: StripeElementChangeEvent) => setCardCvcStatus(event), []);
  const makePurchase = useDisposable(mw.catalog.purchase.bind(mw.catalog));

  const onPaymentError = useCallback((error: Error) => {
    Log.error(TAG, 'Payment error', error);
    setError(error);
    setStep(Step.Error);
  }, [setStep]);

  const purchaseMethod = useMemo(() => {
    switch (paymentMethodId) {
      case PaymentMethodId.Stripe:
        return PurchaseMethod.Stripe;
      case PaymentMethodId.Billing:
        return PurchaseMethod.Billing;
    }
  },[paymentMethodId]);

  const onPaymentSuccess = useCallback(() => {
    makePurchase(purchaseMethod, {
      orderId: orderId,
      load: true,
      media: media,
      orderStatus: failedOrder?.orderStatus
    })
      .then(({result, updatedOrder}) => {
        if (result && !isAsyncIterator(result)) {
          setPurchasedMedia(result);
          setStep(Step.PaymentConfirmed);
        } else {
          setPurchasedMedia(undefined);
        }
        if (updatedOrder) {
          updatedOrder && setOrder(updatedOrder);
        }
      })
      .catch(error => {
        switch (error.type) {
          case ErrorType.TransactionNotFinalized:
            setStep(Step.PaymentInProgress);
            break;
          default:
            onPaymentError(error);
            break;
        }
      });
  }, [failedOrder, makePurchase, media, onPaymentError, orderId, purchaseMethod]);

  useChangeEffect(() => {
    const formComplete = !!(cardNumberStatus.complete && cardExpiryStatus.complete && cardCvcStatus.complete);
    const validationError = !!(cardNumberStatus.error || cardExpiryStatus.error || cardCvcStatus.error);
    setFormValid(formComplete && !validationError);
  }, [cardNumberStatus, cardExpiryStatus, cardCvcStatus]);

  const reset = useCallback(() => {
    setStep(products.length > 1 ? Step.SelectProduct : (failedOrder ? Step.Repayment : Step.MakeOrder));
    setSelectedProductId(products.length === 1 ? products[0].id : '');
    setCardNumberStatus(initialCardStatus);
    setCardExpiryStatus(initialCardStatus);
    setCardCvcStatus(initialCardStatus);
    setFormValid(false);
    setStripePaymentInProgress(false);
  }, [failedOrder, products]);

  useChangeEffect(() => {
    reset();
  }, [products]);

  const startedPaymentParams = useRef<StartPaymentParams | null>(null);
  useEffect(() => {
    const selectedProduct = products.find(product => product.id === selectedProductId);
    if (failedOrder) {
      return;
    }
    if (!selectedProduct) {
      return;
    }
    const bestOffer = getBestOffer(selectedProduct.offers);
    if (!bestOffer) {
      return;
    }
    if (bestOffer.paymentMethodId !== PaymentMethodId.Stripe) {
      return;
    }
    const paymentParams = {
      productId: selectedProductId,
      assetUid: selectedProduct.isSingle ? media.id : undefined,
      paymentMethodId: bestOffer.paymentMethodId,
      currency: bestOffer.currency,
      price: bestOffer.price
    };
    if (startedPaymentParams.current && isSamePayment(startedPaymentParams.current, paymentParams)) {
      return;
    }

    startedPaymentParams.current = paymentParams;
    mw.catalog.startPayment(paymentParams)
      .then((result) => {
        const stripeProperties = result.customProperties[PaymentMethodId.Stripe];
        setOrderId(result.orderId);
        if (stripeProperties) {
          setStripeProperties(stripeProperties);
        }
      })
      .catch(onPaymentError);
  }, [products, media, startedPaymentParams, selectedProductId, orderId, failedOrder, onPaymentError]);

  useEffect(() => {
    if (!visible) {
      return;
    }
    if (failedOrder) {
      setOrderId(failedOrder.id);
      mw.catalog.startRepayment(failedOrder).then((result) => {
        const stripeProperties = result.customProperties[PaymentMethodId.Stripe];
        setOrderId(result.orderId);
        if (stripeProperties) {
          setStripeProperties(stripeProperties);
        }
      });
    }
  }, [failedOrder, visible]);

  const onClose = useCallback(() => {
    reset();
    propsOnClose();
  }, [propsOnClose, reset]);

  const cancelOrder = useCallback(() => {
    if (orderId) {
      mw.catalog.cancelOrder(orderId)
        .catch(error => Log.error(TAG, `Error canceling order ${orderId}`, error));
    }
    onClose();
  }, [orderId, onClose]);

  const confirmCardPayment = useCallback(() => {
    if (stripeRef?.current && stripeProperties?.clientSecret) {
      setStripePaymentInProgress(true);
      stripeRef.current.startPayment({clientSecret: stripeProperties.clientSecret});
    }
  }, [stripeProperties]);

  const retryPayment = useCallback(() => {
    if (stripeProperties?.clientSecret) {
      setStep(Step.MakeOrder);
      setStripePaymentInProgress(false);
    }
  }, [stripeProperties]);

  const closeConfirmation = useCallback(() => {
    propsOnPaymentSuccess(order, undefined, undefined, purchasedMedia);
    onClose();
  }, [onClose, order, propsOnPaymentSuccess, purchasedMedia]);

  const {component, width, actions, menuButtonDisabled, positiveLabel, onPositive, negativeLabel, onNegative, neutralLabel, onNeutral} = useMemo(() => {
    const selectedProduct = products.find(product => product.id === selectedProductId);
    if (step === Step.SelectProduct) {
      return {
        width: marginHorizontal * 2 + maxContainerWidth - (products.length > visibleElementsCount ? 0 : arrowContainerWidth * 2),
        component: (
          <SelectProduct
            style={styles.stepContainer}
            products={products}
            selectedProductId={selectedProductId}
            onProductSelect={setSelectedProductId}
          />
        ),
        actions: [PopupAction.NEGATIVE, PopupAction.POSITIVE],
        menuButtonDisabled: [false, !selectedProduct],
        positiveLabel: t('common.ok'),
        onPositive: () => setStep(Step.MakeOrder),
        negativeLabel: t('common.close'),
        onNegative: cancelOrder
      };
    } else if (step === Step.MakeOrder && selectedProduct) {
      const bestOffer = getBestOffer(selectedProduct.offers);
      if (paymentMethodId === PaymentMethodId.Stripe) {
        return {
          width: marginHorizontal * 2 + dimensions.payments.order.contentWidth,
          component: (
            <MakeOrder
              style={styles.stepContainer}
              title={selectedProduct.name}
              description={selectedProduct.description}
              duration={selectedProduct.duration}
              price={bestOffer.price}
              currency={bestOffer.currency}
              assets={[selectedProduct.name]}
            >
              {bestOffer.paymentMethodId === PaymentMethodId.Stripe && (
                <Stripe
                  ref={stripeRef}
                  cardNumberValidationError={!!cardNumberStatus.error && !cardNumberStatus.empty}
                  onCardNumberChange={onCardNumberChange}
                  cardExpiryValidationError={!!cardExpiryStatus.error && !cardExpiryStatus.empty}
                  onCardExpiryChange={onExpirationDateChange}
                  cardCvcValidationError={!!cardCvcStatus.error && !cardCvcStatus.empty}
                  onCardCvcChange={onCvcChange}
                  publishableKey={stripeProperties?.publicApiKey || ''}
                  onPaymentError={onPaymentError}
                  onPaymentSuccess={onPaymentSuccess}
                />
              )}
            </MakeOrder>
          ),
          actions: [PopupAction.NEGATIVE, PopupAction.POSITIVE],
          menuButtonDisabled: [false, !formValid || stripePaymentInProgress],
          positiveLabel: selectedProduct.duration ?
            t('payments.rentWithPrice', {price: formatCurrency(bestOffer.price, bestOffer.currency, i18n.language)}) :
            t('payments.buyWithPrice', {price: formatCurrency(bestOffer.price, bestOffer.currency, i18n.language)}),
          onPositive: confirmCardPayment,
          negativeLabel: t('common.cancel'),
          onNegative: cancelOrder
        };
      } else {
        return {
          width: marginHorizontal * 2 + dimensions.payments.order.contentWidth,
          component: (<SelectedProduct product={selectedProduct} />),
          actions: [PopupAction.NEGATIVE, PopupAction.NEUTRAL, PopupAction.POSITIVE],
          onNeutral: () => setStep(Step.SelectProduct),
          onNegative: cancelOrder,
          onPositive: () => propsOnPaymentSuccess(undefined, selectedProductId, bestOffer.id),
          neutralLabel: t('common.back'),
          negativeLabel: t('common.cancel'),
          positiveLabel: selectedProduct.duration ?
            t('payments.rentWithPrice', {price: formatCurrency(bestOffer.price, bestOffer.currency, i18n.language)}) :
            t('payments.buyWithPrice', {price: formatCurrency(bestOffer.price, bestOffer.currency, i18n.language)})
        };
      }
    } else if (step === Step.Repayment && selectedProduct && failedOrder) {
      return {
        width: marginHorizontal * 2 + dimensions.payments.order.contentWidth,
        component: (
          <MakeOrder
            style={styles.stepContainer}
            title={selectedProduct.name}
            description={selectedProduct.description}
            duration={selectedProduct.duration}
            price={failedOrder.price}
            currency={failedOrder.currency}
            assets={[selectedProduct.name]}
          >
            {failedOrder.paymentMethodId === PaymentMethodId.Stripe && (
              <Stripe
                ref={stripeRef}
                cardNumberValidationError={!!cardNumberStatus.error && !cardNumberStatus.empty}
                onCardNumberChange={onCardNumberChange}
                cardExpiryValidationError={!!cardExpiryStatus.error && !cardExpiryStatus.empty}
                onCardExpiryChange={onExpirationDateChange}
                cardCvcValidationError={!!cardCvcStatus.error && !cardCvcStatus.empty}
                onCardCvcChange={onCvcChange}
                publishableKey={stripeProperties?.publicApiKey || ''}
                onPaymentError={onPaymentError}
                onPaymentSuccess={onPaymentSuccess}
              />
            )}
          </MakeOrder>
        ),
        actions: [PopupAction.NEGATIVE, PopupAction.POSITIVE],
        menuButtonDisabled: [false, !formValid || stripePaymentInProgress],
        positiveLabel: selectedProduct.duration ?
          t('payments.rentWithPrice', {price: formatCurrency(failedOrder.price, failedOrder.currency, i18n.language)}) :
          t('payments.buyWithPrice', {price: formatCurrency(failedOrder.price, failedOrder.currency, i18n.language)}),
        onPositive: confirmCardPayment,
        negativeLabel: t('common.cancel'),
        onNegative: cancelOrder
      };
    } else if (step === Step.PaymentInProgress) {
      return {
        component: (
          <View style={[styles.stepContainer, styles.confirmation]}>
            <NitroxText textType={'subhead'} style={dynamicStyle.text}>
              {t('payments.dialog.inProgress')}
            </NitroxText>
          </View>
        ),
        negativeLabel: t('common.close'),
        onNegative: closeConfirmation
      };
    } else if (step === Step.PaymentConfirmed && selectedProduct) {
      const rented = !!selectedProduct.duration;
      return {
        component: (
          <View style={[styles.stepContainer, styles.confirmation]}>
            <NitroxText textType={'tile-title'} style={dynamicStyle.text}>
              <NitroxText style={dynamicStyle.text} textType={'subhead'} >
                {t(rented ? 'payments.dialog.rented' : 'payments.dialog.purchased', {name: selectedProduct.name})}
              </NitroxText>
              {rented && (
                <NitroxText style={dynamicStyle.text} textType={'subhead'} >
                  {t('payments.dialog.forPeriod')}
                </NitroxText>
              )}
              {rented && (
                <NitroxText style={dynamicStyle.text} textType={'subhead-bold'} >
                  {t('payments.dialog.day', {count: Math.floor((selectedProduct?.duration || 0) / DateUtils.msInDay)})}
                </NitroxText>
              )}
            </NitroxText>
          </View>
        ),
        negativeLabel: t('common.close'),
        onNegative: closeConfirmation
      };
    } else {
      const {title, message, code} = getPopupError(t, error);
      return {
        component: (
          <View style={[styles.stepContainer, styles.confirmation]}>
            <Icon type={IconType.ErrorEntitlement} size={dimensions.icon.xxxLarge} />
            <NitroxText style={dynamicStyle.text} textType='subhead-bold'>{title}</NitroxText>
            {code && (
              <NitroxText style={dynamicStyle.subtitle} textType='callout'>{code}</NitroxText>
            )}
            <NitroxText style={[dynamicStyle.text, styles.error]} textType='subhead'>{message}</NitroxText>
          </View>
        ),
        actions: showRetryButton(error) ? [PopupAction.NEGATIVE, PopupAction.POSITIVE] : undefined,
        positiveLabel: t('payments.retry'),
        onPositive: retryPayment,
        negativeLabel: t('common.close'),
        onNegative: onClose
      };
    }
  }, [paymentMethodId, products, step, failedOrder, selectedProductId, t, onClose, cancelOrder, cardNumberStatus.error, cardNumberStatus.empty, onCardNumberChange, cardExpiryStatus.error, cardExpiryStatus.empty, onExpirationDateChange, cardCvcStatus.error, cardCvcStatus.empty, onCvcChange, stripeProperties?.publicApiKey, onPaymentError, onPaymentSuccess, formValid, stripePaymentInProgress, i18n.language, confirmCardPayment, propsOnPaymentSuccess, dynamicStyle.text, dynamicStyle.subtitle, closeConfirmation, error, retryPayment]);

  return (
    <Popup
      key={step}
      visible={visible}
      width={isBigScreen ? width : undefined}
      actions={actions}
      containerStyle={dynamicStyle.container}
      menuStyle={dynamicStyle.menu}
      menuButtonStyle={menuButtonStyle}
      menuButtonDisabled={menuButtonDisabled}
      menuHasPreferredFocus
      positiveLabel={positiveLabel}
      onPositive={onPositive}
      negativeLabel={negativeLabel}
      onNegative={onNegative}
      neutralLabel={neutralLabel}
      onNeutral={onNeutral}
      onModalClose={onNegative}
    >
      {component}
    </Popup>
  );
};

export default PaymentWizard;
