import {isBigScreen} from 'common/constants';
import {DateUtils} from 'common/DateUtils';

import {Event} from 'mw/api/Metadata';

export const chunkSize = {
  /**
   * In ms.
   */
  width: isBigScreen
    ? 4 * DateUtils.msInHour
    : 3.5 * DateUtils.msInHour,
  /**
   * Channels count.
   */
  height: isBigScreen ? 5 : 10
};

export type ChunkParameters = {
  startDate: Date;
  endDate: Date;
  startRow: number;
  endRow: number;
}

export type Chunk = ChunkParameters & {
  id: string;
  events: Event[];
}

const getTwoDigitFormat = (date: number) => date.toString().padStart(2, '0');

function simpleDateFormat(date: Date) {
  return `${getTwoDigitFormat(date.getDate())}.${getTwoDigitFormat(date.getMonth() + 1)} ${getTwoDigitFormat(date.getHours())}:${getTwoDigitFormat(date.getMinutes())}`;
}

export function chunkToString(chunk: ChunkParameters & {events?: Event[]}) {
  return 'Chunk['
    + (chunk.events ? `events: ${chunk.events.length}, ` : '')
    + `rows: ${chunk.startRow}-${chunk.endRow}, dates: ${simpleDateFormat(chunk.startDate)} - ${simpleDateFormat(chunk.endDate)}`
    + ']';
}

export function chunkId({startDate, endDate, startRow, endRow}: ChunkParameters) {
  return `EPGChunk_${startDate}_${endDate}_${startRow}_${endRow}`;
}

export function getChunkParameters(date: Date, row: number, originDate: Date): ChunkParameters {
  const origin = originDate.getTime();
  const diff = Math.floor((date.getTime() - origin) / chunkSize.width);
  const startDate = new Date(origin + diff * chunkSize.width);
  const endDate = new Date(startDate.getTime() + chunkSize.width);
  const startRow = Math.floor(row / chunkSize.height) * chunkSize.height;
  const endRow = startRow + chunkSize.height - 1;
  return {
    startDate,
    endDate,
    startRow,
    endRow
  };
}

export function getChunkOffsetBy(vector: [number, number], from: ChunkParameters): ChunkParameters {
  const [deltaX, deltaY] = vector;
  const startDate = new Date(from.startDate.getTime() + deltaX * chunkSize.width);
  const endDate = new Date(startDate.getTime() + chunkSize.width);
  const startRow = from.startRow + deltaY * chunkSize.height;
  const endRow = startRow + chunkSize.height - 1;
  return {
    startDate,
    endDate,
    startRow,
    endRow
  };
}

export function isSameChunk(chunk1: ChunkParameters, chunk2: ChunkParameters) {
  return chunk1.startDate.getTime() === chunk2.startDate.getTime()
    && chunk1.endDate.getTime() === chunk2.endDate.getTime()
    && chunk1.startRow === chunk2.startRow
    && chunk1.endRow === chunk2.endRow;
}
