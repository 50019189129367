import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, ViewStyle} from 'react-native';

import {dimensions, isMobile} from 'common/constants';

import {EnterPinModalComponent} from 'components/EnterPinModalComponent';
import {useToggle, useChangeEffect} from 'hooks/Hooks';
import {WizardToggleSwitch} from 'screens/settings/profileWizard/WizardToggleSwitch';

const style = {
  paddingHorizontal: isMobile ? dimensions.margins.xxLarge : undefined,
  marginBottom: dimensions.margins.xxLarge
};

type SettingsTogglePINProtectionProps = {
  pinRequired?: boolean;
  onProtectWithPINChanged: (pinRequired: boolean) => void;
  style?: StyleProp<ViewStyle>;
  pinSet: boolean;
  onPINChanged: (pin: string) => void;
}

const SettingsTogglePINProtection: React.FC<SettingsTogglePINProtectionProps> = props => {
  const {t} = useTranslation();
  const {
    pinRequired: propsPINRequired = false,
    onProtectWithPINChanged,
    onPINChanged,
    pinSet
  } = props;

  const [pinRequired, {toggle: togglePinRequired}] = useToggle(propsPINRequired);
  const [isPinPopupVisible, {on: showPinPopup, off: hidePinPopup}] = useToggle(false);

  useChangeEffect(() => {
    onProtectWithPINChanged(pinRequired);
  }, [pinRequired], [onProtectWithPINChanged]);

  const onPressHandler = () => {
    if (!pinSet) {
      // force to set pin
      showPinPopup();
      return;
    }
    togglePinRequired();
  };

  const onSubmit = useCallback((value: string) => {
    onPINChanged(value);
    hidePinPopup();
    togglePinRequired();
  }, [hidePinPopup, onPINChanged, togglePinRequired]);

  return (
    <>
      <WizardToggleSwitch
        value={pinRequired}
        title={t('settings.protectProfile')}
        onPress={onPressHandler}
        style={[style, props.style]}
      />
      <EnterPinModalComponent
        visible={isPinPopupVisible}
        onSubmit={onSubmit}
        onClose={hidePinPopup}
        title={t('settings.setPinTitle')}
      />
    </>
  );
};

export default SettingsTogglePINProtection;
