import {DateUtils} from 'common/DateUtils';

import {Episode, Metadata, Title, TitleType, Product, EntitlementState} from 'mw/api/Metadata';
import {AssetAccessResponse, AssetAccessStatus} from 'mw/bo-proxy/bo/adr8/ADR8Types';
import {TitleObject} from 'mw/metadata/TitleObject';

import {AssetTypes} from './constants';
import {ContentMapper} from './ContentMapper';
import {MetadataMapper} from './MetadataMapper';
import {PictureMapper} from './PictureMapper';
import {RatingsMapper} from './RatingsMapper';

export interface TitleParams {
  isFirstEpisodeOfSeason?: boolean;
  products?: Product[];
  assetAccess?: AssetAccessResponse;
}

export class TitleMapper {
  private static episodeFromJson(titleJson: any, firstEpisodeOfSeason?: boolean): Episode | null {
    if (titleJson.assetType !== AssetTypes.Episode) {
      return null;
    }
    const season = titleJson.relations?.['episode-season']?.[0];
    const series = season?.relations?.['season-series']?.[0];

    return {
      seriesId: series?.uid ?? '',
      seasonId: season?.uid ?? '',
      title: titleJson.title,
      number: titleJson.number ?? -1,
      seriesName: series?.title ?? '',
      seasonName: season?.title ?? '',
      seasonNumber: season?.number ?? -1,
      firstEpisodeOfSeason
    };
  }

  public static titleFromJson(titleJson: any, params?: TitleParams): Title {
    const {uid, title, assetType, number: episodeNumber, externalResources, contentProvider} = titleJson;
    const episode = TitleMapper.episodeFromJson(titleJson, params?.isFirstEpisodeOfSeason);
    const contents = ContentMapper.contentsFromTitleJson(titleJson, {
      products: params?.products ?? [],
      entitlementState: params?.assetAccess ? TitleMapper.assetAccessStatusToEntitlementState(params.assetAccess.status) : EntitlementState.NotEntitled,
      entitlementEnd: params?.assetAccess?.endTime || DateUtils.addYears(new Date(), 1)
    });
    const metadata = MetadataMapper.metadataFromJson(titleJson);
    const t = new TitleObject(uid, title, TitleType.VOD, metadata, episode);
    t.isTrailer = assetType === AssetTypes.Trailer;
    t.contents = contents;
    t.entitlementState = contents[0]?.entitlementState ?? EntitlementState.NotEntitled;
    t.ordinal = episodeNumber ?? -1;
    t.pictures = externalResources?.image?.map(PictureMapper.pictureFromJson) ?? [];
    t.pcRatings = RatingsMapper.pcRatingsFromJson(titleJson);
    t.contentProvider = contentProvider;
    return t;
  }

  public static episodesFromJson(contentJson: any): Title[] {
    if (!Array.isArray(contentJson)) {
      return [];
    }

    let parsedSeason = '';
    return contentJson.map((object: any) => {
      let isFirstEpisodeOfSeason = false;
      if (!parsedSeason || parsedSeason !== object.number) {
        parsedSeason = object.number;
        isFirstEpisodeOfSeason = true;
      }
      return TitleMapper.titleFromJson(object, {isFirstEpisodeOfSeason: isFirstEpisodeOfSeason});
    });
  }

  public static recommendedEpisodeFromJson(json: any): Title | null {
    // JSON contains different structure of data so standard 'titleFromJson' cannot be used
    // Currently only mandatory data for issue CL-1106 is being parsed
    const uid = 'media-' + json.media_id;
    const title = json.titles.en;
    const metadata = new Metadata();
    let episode = null;
    if (json.series && json.series[0]) {
      const seriesId = 'series-' + json.series[0].id;
      const seasonId = 'season-' + json.series[0].season_id;
      const episodeNumber = json.episode;
      const seriesName = '';
      const seasonName = '';
      const seasonNumber = json.series[0].season_number;
      episode = {
        seriesId,
        seasonId,
        title,
        number: episodeNumber,
        seriesName,
        seasonName,
        seasonNumber,
        firstEpisodeOfSeason: episodeNumber === 1
      };
    }
    return new TitleObject(uid, title, TitleType.VOD, metadata, episode);
  }

  public static assetAccessStatusToEntitlementState(accessStatus: AssetAccessStatus): EntitlementState {
    switch (accessStatus) {
      case AssetAccessStatus.Unavailable:
        return EntitlementState.NotEntitled;
      case AssetAccessStatus.Purchasing:
        return EntitlementState.EntitlementInProgress;
      case AssetAccessStatus.Available:
        return EntitlementState.Entitled;
    }
  }
}
