import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {ErrorType} from 'mw/api/Error';

import ErrorPopup, {ErrorPopupProps} from 'components/ErrorPopup';
import {IconType} from 'components/Icon';
import DeviceRegistrationErrorHandler from 'components/login/DeviceRegistrationErrorHandler';
import AccountAlreadyRegisteredPopup, {AccountAlreadyRegisteredPopupType} from 'screens/login/AccountAlreadyRegisteredPopup';

type Props = {
  error: ErrorType | null;
  reinitialize: () => void;
  onCloseAccountAlreadyRegisteredPopup: () => void;
  emailOrUsername: string;
}

const AppErrorHandler: React.FunctionComponent<Props> = (props) => {
  const {error, reinitialize, onCloseAccountAlreadyRegisteredPopup, emailOrUsername} = props;
  const {t} = useTranslation();

  const errorPopupProps = useMemo((): ErrorPopupProps | null => {
    switch (error) {
      case ErrorType.RemoteNxfdNotFound:
        return {
          error: {
            message: t('configError.remoteNxfdNotFound.message'),
            title: t('configError.remoteNxfdNotFound.title'),
            subtitle: t('common.errorCode', {errorCode: error}),
            icon: IconType.ErrorConfiguration
          },
          buttonLabel: t('common.tryAgain'),
          onClose: reinitialize
        };

      case ErrorType.RemoteNxfdValidationFailed:
        return {
          error: {
            message: t('configError.remoteNxfdValidationFailed.message'),
            title: t('configError.remoteNxfdValidationFailed.title'),
            subtitle: t('common.errorCode', {errorCode: error}),
            icon: IconType.ErrorConfiguration
          },
          buttonLabel: t('common.tryAgain'),
          onClose: reinitialize
        };

      case ErrorType.RemoteNxfdUnknownError:
        return {
          error: {
            message: t('configError.remoteNxfdUnknownError.message'),
            title: t('configError.remoteNxfdUnknownError.title'),
            subtitle: t('common.errorCode', {errorCode: error}),
            icon: IconType.ErrorConfiguration
          },
          buttonLabel: t('common.tryAgain'),
          onClose: reinitialize
        };

      default:
        return null;
    }
  }, [error, t, reinitialize]);

  return (
    <>
      {errorPopupProps && <ErrorPopup {...errorPopupProps} />}
      {error === ErrorType.DeviceUnregistered && <DeviceRegistrationErrorHandler error={error} logo />}
      <AccountAlreadyRegisteredPopup
        visible={error === ErrorType.AccountNotActivated}
        emailOrUsername={emailOrUsername}
        onClose={onCloseAccountAlreadyRegisteredPopup}
        type={AccountAlreadyRegisteredPopupType.Login}
      />
    </>
  );
};

export default React.memo(AppErrorHandler);
