import {Platform} from 'react-native';

import {isBrowser} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {PlaybackStream} from 'mw/playback/sessions/PlaybackSessionManager';

const TAG = 'PlatformTrackSelector';

export class PlatformTrackSelector {
  public static selectStream(streams: PlaybackStream[]): PlaybackStream | undefined {
    Log.debug(TAG, 'Selecting stream for platform: ' + Platform.OS);
    return Platform.select({
      'android': () => PlatformTrackSelector.selectAndroid(streams),
      'ios': () => PlatformTrackSelector.selectIOS(streams),
      'web': () => PlatformTrackSelector.selectWeb(streams),
      'default': () => PlatformTrackSelector.selectIOS(streams)
    })();
  }

  private static selectAndroid(streams: PlaybackStream[]): PlaybackStream | undefined {
    return PlatformTrackSelector.selectStreamType(streams, 'mpd')
      || PlatformTrackSelector.selectStreamTypeNoDrm(streams, 'm3u8')
      || PlatformTrackSelector.selectStreamType(streams, 'mp4');
  }

  private static selectWeb(streams: PlaybackStream[]): PlaybackStream | undefined {
    if (isBrowser('safari')) {
      return PlatformTrackSelector.selectIOS(streams);
    }
    return PlatformTrackSelector.selectAndroid(streams);
  }

  private static selectIOS(streams: PlaybackStream[]): PlaybackStream | undefined {
    return PlatformTrackSelector.selectStreamTypeNoDrm(streams, 'm3u8')
      || PlatformTrackSelector.selectStreamType(streams, 'm3u8')
      || PlatformTrackSelector.selectStreamType(streams, 'mp4');
  }

  private static selectStreamType(streams: PlaybackStream[], type: string): PlaybackStream | undefined {
    return streams.find((stream: PlaybackStream) => stream.type === type);
  }

  private static selectStreamTypeNoDrm(streams: PlaybackStream[], type: string): PlaybackStream | undefined {
    return streams.find((stream: PlaybackStream) => (stream.type) === type && (!stream.drmType || (stream.drmType === 'none')));
  }
}
