import {createStyles} from 'common-styles';
import React from 'react';
import {View} from 'react-native';

import {StylesUpdater} from 'common-styles/StylesUpdater';

import {PromotionalBannerComponentProps} from './PromotionalBanner.shared';

const styles = new StylesUpdater(colors => createStyles({
}));

const staticStyles = createStyles({
});

const PromotionalBannerPiccolo: React.FC<PromotionalBannerComponentProps> = () => {
  return (
    <View />
  );
};

export default PromotionalBannerPiccolo;
