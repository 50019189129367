import React from 'react';

import {dimensions, RADIUS, isMobile} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';

import NitroxText from 'components/NitroxText';

const stylesUpdated = new StylesUpdater(colors => ({
  text: {
    backgroundColor: colors.label.error.background,
    borderRadius: RADIUS,
    color: colors.label.error.text,
    marginVertical: isMobile ? dimensions.margins.xsmall : dimensions.margins.small,
    paddingHorizontal: isMobile ? dimensions.margins.small : dimensions.margins.large,
    paddingVertical: dimensions.margins.xsmall
  }
}));

type Props = {
  children: string;
};

const ErrorLabel: React.FC<Props> = props => {
  const {children} = props;
  const styles = stylesUpdated.getStyles();
  return (
    <NitroxText textType='label-condensed' style={styles.text}>{children}</NitroxText>
  );
};

export default React.memo(ErrorLabel);
