import React, {useCallback, useRef, useMemo} from 'react';
import {ViewProperties, requireNativeComponent} from 'react-native';

import {Direction, isTVOS} from 'common/constants';

import {useFocusSwitchInterface} from 'components/FocusSwitch';
import {SupportedKeys} from 'components/KeyEventManager';
import NitroxInteractive, {FocusPriorityProps} from 'components/NitroxInteractive';
import {useWillAppear} from 'hooks/Hooks';

import {FocusManager} from './FocusManager';
import {FocusableComponent} from './FocusManagerTypes';

const TVOSFocusPrison = isTVOS ? requireNativeComponent('FocusPrison') : null;

type FocusPrisonProps = {
  focusOnAppear?: boolean;
  onPress?: (e?: any) => void;
  focusPrisonStateChanged?: (focused: boolean) => void;
  exitEdges?: Direction[];
} & FocusPriorityProps & Pick<ViewProperties, 'style'>;

/**
 * Component that can become focused, and when it does, freezes all focus movement.
 * To restore focus, it must be forced somewhere else.
 */
const FocusPrison: React.FC<FocusPrisonProps> = ({
  style,
  focusPriority = 0,
  focusOnAppear,
  onPress,
  focusPrisonStateChanged,
  exitEdges = [],
  children
}) => {
  const tvOSRef = useRef<FocusableComponent>(null);

  const {on, off} = useFocusSwitchInterface();
  const onFocusStateChangedTVOS = useCallback(event => {
    const focused = !!event?.nativeEvent?.focused;
    focused ? on() : off();
    focusPrisonStateChanged?.(!!event?.nativeEvent?.focused);
  }, [focusPrisonStateChanged, off, on]);

  useWillAppear(useCallback(() => {
    if (isTVOS && focusOnAppear) {
      FocusManager.getInstance().forceFocus(tvOSRef);
    }
  }, [focusOnAppear]));

  const tvOSExitEdges = useMemo(() => exitEdges.map(edge => SupportedKeys[edge]), [exitEdges]);

  return isTVOS ? (
    <TVOSFocusPrison
      ref={tvOSRef}
      style={style}
      focusPriority={focusPriority}
      exitEdges={tvOSExitEdges}
      hasTVPreferredFocus={focusOnAppear}
      onFocusStateChanged={onFocusStateChangedTVOS}
    >
      {children}
    </TVOSFocusPrison>
  ) : (
    <NitroxInteractive
      style={style}
      activeOpacity={1}
      freezeFocus
      exitEdges={exitEdges}
      focusPriority={focusPriority}
      hasTVPreferredFocus={focusOnAppear}
      onFocusStateChanged={focusPrisonStateChanged}
      onPress={onPress}
    >
      {children}
    </NitroxInteractive>
  );
};

export default FocusPrison;
