import {createStyles} from 'common-styles';
import React from 'react';

import {Media} from 'mw/api/Metadata';

import MediaGrid from 'components/MediaGrid';

const styles = createStyles({
  gridContainer: {
    flex: 1
  }
});

type Props = {
  results: Media[];
}

const SearchFullResultsVod: React.FC<Props> = ({results}: Props) => (
  <MediaGrid style={styles.gridContainer} dataSource={results} maximumSpacing={0} />
);

export default SearchFullResultsVod;
