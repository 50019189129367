import {useState, useCallback} from 'react';

/**
 * Returns integer id and function to increment it. Useful for resetting component instance by incrementing its key.
 */
export function useIntId(initialValue = 0) {
  const [id, setId] = useState(initialValue);
  return {
    id,
    increment: useCallback(() => setId(i => i + 1 >> 0), [])
  };
}
