import {Log} from 'common/Log';

import {NativePlaybackEventEmitter} from './NativePlaybackEventEmitter';
import {Player} from './Player';
import {PlayersManager} from './PlayersManager';
import {PlaybackEvent} from './types/PlaybackEvent';

const TAG = 'PlaybackEventsEmitter';

export class PlaybackEventEmitter {

  private static instance: PlaybackEventEmitter | null = null;
  public static getInstance = (): PlaybackEventEmitter => {
    if (!PlaybackEventEmitter.instance) {
      PlaybackEventEmitter.instance = new PlaybackEventEmitter();
    }

    return PlaybackEventEmitter.instance;
  }

  public uninitialize(): void {
    PlaybackEventEmitter.instance = null;
    NativePlaybackEventEmitter.removeListener('playbackEvent', this.onPlaybackEvent);
  }

  private players: PlayersManager | null = null;
  private constructor() {
    NativePlaybackEventEmitter.addListener('playbackEvent', this.onPlaybackEvent);
  }

  private onPlaybackEvent = (event: PlaybackEvent): void => {
    Log.debug(TAG, 'onPlaybackEvent', event);
    if (this.players) {
      const playerType: (keyof PlayersManager) = event.playerType;
      const player: Player = this.players[playerType];
      if (player) {
        player.handleEvent(event);
      }
    }
  }

  public setPlayers(players: PlayersManager): void {
    this.players = players;
  }
}
