import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {ViewStyle, StyleProp, Animated, View, TextStyle} from 'react-native';

import {dimensions} from 'common/constants';

import FocusParent, {FocusParentProps} from 'components/FocusParent';
import {FocusSwitchProps} from 'components/FocusSwitch';
import {useSideMenuAnimations, SideMenuAnimationsConfig, SideMenuAnimationsContainerVisible, SideAnimationsContainerHidden} from 'components/navigation/SideMenuAnimations';
import {useChangeEffect} from 'hooks/Hooks';

import {ListViewSection, ListItemProps} from './ListView';
import {SideMenuState} from './navigation/NavigationHelperTypes';

export {listViewHorizontalSpace as sideMenuHorizontalSpace} from './ListView';

const styles = createStyles({
  container: {
    width: dimensions.sideMenu.width,
    height: '100%'
  },
  animatedContainer: {
    overflow: 'hidden',
    height: '100%'
  }
});

type WithCustomRenderer = {
  contentRenderer: (iconOpacity: Animated.Value, textOpacity: Animated.Value) => JSX.Element;
}

type WithDefaultRenderer = {
  titleStyle?: StyleProp<TextStyle>;
  title: string;
  items: ListItemProps[];
}

type RenderContentProps = WithCustomRenderer | WithDefaultRenderer;

export type SideMenuProps = {
  style?: StyleProp<ViewStyle>;
  menuState?: SideMenuState;
  onFocusParentReady: FocusParentProps['onReady'];
} & RenderContentProps & Pick<FocusSwitchProps, 'onFocusEnter' | 'onFocusEscape'>;

const animationConfig: Partial<SideMenuAnimationsConfig> = {
  [SideMenuState.Expanded]: {
    menuWidth: dimensions.sideMenu.width,
    textContainer: SideMenuAnimationsContainerVisible,
    iconContainer: SideMenuAnimationsContainerVisible
  },
  [SideMenuState.Collapsed]: {
    menuWidth: dimensions.sideMenu.widthCollapsed,
    textContainer: SideAnimationsContainerHidden,
    iconContainer: SideAnimationsContainerHidden
  }
};

export const SideMenu: React.FC<SideMenuProps> = props => {
  const {
    style,
    menuState = SideMenuState.Expanded
  } = props;
  const {animateMenu, menuWidth, iconsOpacity, textContainerOpacity} = useSideMenuAnimations(menuState, animationConfig);

  useChangeEffect(() => {
    animateMenu(menuState);
  }, [animateMenu, menuState]);

  const {animatedViewStyle, viewStyle} = useMemo(() => ({
    animatedViewStyle: [styles.animatedContainer, {width: menuWidth.current}],
    viewStyle: [styles.container, style, {left: menuState === SideMenuState.Expanded ? 0 : dimensions.sideMenu.widthCollapsed}]
  }), [style, menuState, menuWidth]);

  return (
    <FocusParent onReady={props.onFocusParentReady} onFocusEnter={props.onFocusEnter} onFocusEscape={props.onFocusEscape} enterStrategy={'topLeft'} rememberLastFocused>
      <Animated.View style={animatedViewStyle}>
        <View style={viewStyle} >
          {'contentRenderer' in props
            ? props.contentRenderer(iconsOpacity, textContainerOpacity)
            : (
              <ListViewSection
                title={props.title}
                items={props.items}
                textType='side-menu-headline'
                titleStyle={props.titleStyle}
                textContainerOpacity={textContainerOpacity}
                iconOpacity={iconsOpacity}
              />
            )
          }
        </View>
      </Animated.View>
    </FocusParent>
  );
};
