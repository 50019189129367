import {testAutomationFeatures} from 'common/constants';
import {Log} from 'common/Log';

import {Error, ErrorType} from 'mw/api/Error';
import {deviceInfo} from 'mw/platform/device-info/DeviceInfo';

const TAG = 'DeviceManager';

export class DeviceManager {

  private static instance = new DeviceManager();

  public static getInstance(): DeviceManager {
    return this.instance;
  }

  private deviceId: string | null = null;

  public unintialize(): void {
    DeviceManager.instance = new DeviceManager();
  }

  public setId(id: string): void {
    this.deviceId = id;
  }

  public getId(): string {
    if (testAutomationFeatures.deviceIdOverride) {
      Log.info(TAG, `testAutomationFeatures.deviceIdOverride=${testAutomationFeatures.deviceIdOverride}`);
      return testAutomationFeatures.deviceIdOverride;
    }

    if (!this.deviceId) {
      Log.error(TAG, 'getId: no deviceId');
      throw new Error(ErrorType.UnknownError);
    }

    return this.deviceId;
  }

  public getDeviceName(): string {
    if (testAutomationFeatures.deviceNameOverride) {
      Log.info(TAG, `testAutomationFeatures.deviceNameOverride=${testAutomationFeatures.deviceNameOverride}`);
      return testAutomationFeatures.deviceNameOverride;
    }

    return deviceInfo.getDeviceName();
  }
}
