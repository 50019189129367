import {createStyles} from 'common-styles';
import React, {useCallback, useEffect, useMemo, useRef, useState, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {Animated, Easing, View, StyleSheet, ActivityIndicator} from 'react-native';
import {WhitePortal} from 'react-native-portal';
import {NavigationEventPayload} from 'react-navigation';

import {dimensions, Direction, isTablet, AppRoutes, isPhone} from 'common/constants';
import {DateUtils} from 'common/DateUtils';
import {openMediaDetails, getMediaDuration} from 'common/HelperFunctions';
import {NavigationFocusState} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {constColors, BaseColors} from 'common-styles/variables/base-colors';

import {Error, ErrorType} from 'mw/api/Error';
import {Channel, Media, isEvent, isChannel, Event, MediaType, ContentType, BlockedByPCEventState, ChromecastConnectionState} from 'mw/api/Metadata';
import {PlayerEvent} from 'mw/api/PlayerEvent';
import {mw} from 'mw/MW';
import {isChromecastSupported} from 'mw/platform/chromecast/ChromecastInterface';
import {useChromecastTvScreenControl} from 'mw/platform/chromecast/ChromecastUtils';

import {ChannelIcon, ChannelIconType, channelIconConstants} from 'components/ChannelIcon';
import HorizontalChannelsListPiccolo from 'components/channelsList/HorizontalChannelsList.piccolo';
import ChannelsListsMenu from 'components/ChannelsListsMenu';
import {useChromecastExtraBottomPadding} from 'components/chromecast/ChromecastExtraBottomPadding';
import {ChromecastContext} from 'components/ChromecastContext';
import ConditionalWrapper from 'components/ConditionalWrapper';
import DatePicker from 'components/DatePicker';
import {EpgScreenMobile} from 'components/epg/EpgScreenMobile.piccolo';
import GestureRecognizerView from 'components/gestures/GestureRecognizerView';
import {Grid, GridElementProps} from 'components/Grid';
import {Icon, IconType} from 'components/Icon';
import {MobileScreenHeaderProps} from 'components/mobileScreenHeader/MobileScreenHeader';
import {FAR_FAR_AWAY} from 'components/navigation/ResourceSavingScene';
import NitroxBlackPortal from 'components/NitroxBlackPortal';
import NitroxButton from 'components/NitroxButton';
import Orientation from 'components/orientationLocker/OrientationLocker';
import {SupportedOrientations} from 'components/OrientationManager';
import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';
import {AppStatePlaybackRetuneScheduler} from 'components/player/PlaybackRetuneScheduler';
import {PlayerControlsHandlers, PlayerControlsViewButtonsVisibility, PlaybackControlsVisibility} from 'components/player/PlayerControlsView';
import {TuneRequestState, TuneRequest} from 'components/player/PlayerManager';
import {PlayerView, PlayerViews} from 'components/player/PlayerView';
import {usePlayerErrorPopup, PlayerErrorPopupHandlers} from 'components/player/usePlayerErrorPopup';
import {usePlayerLauncher} from 'components/playerLauncher/PlayerLauncher';
import TapRecognizerView from 'components/TapRecognizerView';
import {TopLevelPortalManager, TopLevelPortalType} from 'components/TopLevelPortalManager';
import EventMoreActionsPopup from 'components/zapper/EventMoreActionsPopup';
import GoToLivePopup from 'components/zapper/GoToLivePopup';
import OneChannelEpg from 'components/zapper/OneChannelEpg';
import TuneEventHandler from 'components/zapper/TuneEventHandler';
import UnavailableChannelMessage from 'components/zapper/UnavailableChannelMessage';
import Zapper, {MediaInfoVisibility, channelListContainerLeftMargin} from 'components/zapper/Zapper';
import {useEventListener, useFunction, useScreenInfo, useToggle, useDisposableCallback, useLazyEffect, useWillAppear, useBackPressCapture, useChangeEffect, useLateBinding} from 'hooks/Hooks';
import {useTabBarState} from 'hooks/useTabBarState';
import {getEpgDates} from 'screens/epg/EpgHelperFunctions';
import NitroxScreen from 'screens/NitroxScreen';

import {CurrentMedia, ViewMode} from './TvScreen';
import {getPlayerView, isLivePosition} from './TvScreenHelperFunctions';
import {TvScreenComponentProps, TvScreenPlayerViewLocation, TuneParams} from './TvScreenHelperTypes';
import TvScreenTabletSwipeWrapper from './TvScreenTabletSwipeWrapper';

const TAG = 'TvScreenPiccolo';
const leftSectionWidth = 505;
const channelSelectorHeight = channelIconConstants.channelList.scaledMobile + dimensions.margins.small;
const mainGestureRecognizerDirections = [Direction.Left];
const playerViewGestureRecognizerDirections = [Direction.Down, Direction.Up];

const swipeRecognizerInsets = {
  left: channelIconConstants.channelList.scaledMobile + channelListContainerLeftMargin
};

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  button: {
    marginTop: dimensions.margins.xLarge,
    height: 50,
    width: 250
  },
  buttonWrapper: {
    ...StyleSheet.absoluteFillObject,
    alignItems: 'center',
    justifyContent: 'center'
  },
  container: {
    backgroundColor: colors.tvScreen.background,
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  channelListViewContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    paddingTop: dimensions.margins.medium
  },
  channelSelector: {
    height: channelSelectorHeight,
    backgroundColor: colors.tvScreen.background,
    justifyContent: 'center'
  },
  hidden: {
    top: FAR_FAR_AWAY,
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  iconColor: colors.tile.poster.placeholder.icon,
  shade: {
    backgroundColor: colors.playerScreen.overlay
  },
  gestureRecognizer: {
    flex: 1,
    backgroundColor: constColors.transparent
  },
  datePickerBackground: colors.tvScreen.datepicker.background,
  tabletOptionalContainer: {
    backgroundColor: colors.playerScreen.background
  },
  playerPortal: {
    backgroundColor: colors.playerScreen.background
  },
  mobileContainer: {
    flex: 1
  },
  playerViewTouchOverlay: {
    flex: 1
  },
  oneChannelEpgPortraitContainer: {
    flex: 1
  }
}));

const minimumGridSpacing = dimensions.margins.large;
const channelIconWidth = channelIconConstants.channelGrid.size;

const hiddenPlaybackControls: PlaybackControlsVisibility = {
  restart: false,
  goToLive: false,
  subtitles: false,
  fastBackwards: false,
  skipBack: false,
  pausePlay: false,
  skipForward: false,
  fastForward: false
};

const chromecastStatusesToStop = [ChromecastConnectionState.MediaConnected, ChromecastConnectionState.MediaConnecting];

const TvScreenPiccolo: React.FunctionComponent<TvScreenComponentProps> = props => {
  const {t} = useTranslation();
  const {size: screenSize, orientation} = useScreenInfo();
  const dates = useMemo(() => getEpgDates(), []);
  const datePickerItemWidthRatio = (1 / 3);
  const parentalControlIconSize = isTablet && orientation.isLandscape ? 139 : 74;

  const {
    player, navigation, overlayVisible, currentChannel, channels, channelsLoading, focusState, paused,
    playbackControlsVisibility: propsPlaybackControlsVisibility, tvPlaybackControlsHandlers, tvControlsViewButtonsVisibility,
    tune, showPlayerOverlay, hidePlayerOverlay, findChannelByDirection, onCurrentEventChanged, preventHideOverlay,
    tunedEvent, tunedByRoute, refreshControlsViewButtonsVisibility
  } = props;

  const [supportedOrientation, setSupportedOrientation] = useState<SupportedOrientations>('all');
  const [floating, setFloating] = useState(false);
  const [playerLocation, setPlayerLocation] = useState<TvScreenPlayerViewLocation>(orientation.isPortrait ? 'embedded' : TopLevelPortalType.Fullscreen);
  const [selectedDateIndex, setSelectedDateIndex] = useState(Math.floor(dates.length / 2));
  const [leftMenuShowed, setLeftMenuShowed] = useState(isTablet && orientation.isLandscape);
  const [overlayExtendedInfoVisible, setOverlayExtendedInfoVisible] = useState(!leftMenuShowed);
  const [currentMedia, setCurrentMedia] = useState<CurrentMedia>();
  const [playbackControlsVisible, {on: showPlaybackControls, off: hidePlaybackControls}] = useToggle(false);
  const [loading, setLoading] = useState(false);
  const [isChannelBlocked, setIsChannelBlocked] = useState(false);
  const {isMediaBlocked, shouldBeCheckedForPC, unlockMedia} = useParentalControl();

  const isLockingOrientation = useRef(false);
  const leftMenuWidth = useRef(new Animated.Value(-leftSectionWidth));
  const videoOffsetX = useRef(new Animated.Value(0));
  const epgMobileRef = useRef<EpgScreenMobile>(null);

  const [moreActionsPopupVisible, {on: showMoreActionsPopup, off: closeMoreActionsPopup}] = useToggle(false);
  const [goToLivePopupVisible, {on: showGoToLivePopup, off: closeGoToLivePopup}] = useToggle(false);

  const [currentEvent, setCurrentEvent] = useState<Event | undefined>(undefined);
  const pendingTuneFor = useRef<Media>();

  const [epgPlayerOpened, setEpgPlayerOpened] = useState<boolean>(true);

  const [handleChannelNotAvailable, bindHandleChannelNotAvailable] = useLateBinding();
  const customErrorHandlers = useMemo<PlayerErrorPopupHandlers>(() => ({
    [ErrorType.PlaybackChannelNotAvailable]: () => {
      handleChannelNotAvailable();
    }
  }), [handleChannelNotAvailable]);

  const {playbackError, clearPlaybackError, renderPlaybackErrorPopups} = usePlayerErrorPopup(customErrorHandlers);

  const tuneEventHandler = useRef<TuneEventHandler>(null);
  const {startPlayback} = usePlayerLauncher();

  const {connectionState, stopCastingMedia} = useContext(ChromecastContext);
  const {castControlEnabled, castControlHandler} = useChromecastTvScreenControl();

  useEffect(() => {
    if (isChromecastSupported() && chromecastStatusesToStop.includes(connectionState) && focusState === NavigationFocusState.IsFocused) {
      stopCastingMedia();
    }
  }, [connectionState, stopCastingMedia, focusState]);

  useEffect(() => {
    Log.info(TAG, 'playerLocation set to:', playerLocation);
  }, [playerLocation]);

  const viewMode = navigation.getParam('viewMode') ?? ViewMode.ChannelsGrid;
  const phoneEpgMode = navigation.getParam('phoneEpgMode');
  const requestedByRoute = navigation.getParam('requestedByRoute');

  const updateNavigationParams = useCallback(({media}: {media?: Media}) => {
    if (!media) {
      return;
    }
    if (isEvent(media)) {
      navigation.setParams({'channelId': undefined, 'eventId': media.id, position: undefined});
      return;
    }
    if (isChannel(media)) {
      navigation.setParams({'channelId': media.id, 'eventId': undefined, position: undefined});
      return;
    }

    Log.warn(TAG, 'Unsupported type of media passed to updateNavigationParams:', media.getType());
  }, [navigation]);

  const getTabBarState = useTabBarState();

  const clearNavigationParams = useCallback(() => {
    const tabBarState = getTabBarState();
    if (!tabBarState) {
      return;
    }
    const userMovedToDifferentTab = tabBarState.thisTabIndex !== tabBarState.currentTabIndex;
    if (userMovedToDifferentTab) {
      navigation.setParams({channelId: void 0, eventId: void 0, position: void 0});
    }
  }, [navigation, getTabBarState]);

  useLazyEffect(() => {
    switch (currentMedia?.media?.getType()) {
      case MediaType.Event:
        setCurrentEvent(currentMedia?.media as Event);
        break;
      case MediaType.Channel:
        const {tunedEvent} = props;
        if (mw.players.main.contentType !== ContentType.NPLTV
          || currentEvent?.channelId !== tunedEvent?.channelId) {
          setCurrentEvent(tunedEvent);
        }
        break;
    }
  }, [currentMedia], [setCurrentEvent]);

  useChangeEffect(() => {
    if (isTablet && orientation.isPortrait && leftMenuShowed) {
      setLeftMenuShowed(false);
    }
  }, [orientation.isPortrait], [leftMenuShowed]);

  const onTuneRequestStateChange = useFunction((state: TuneRequestState, tuneRequest: TuneRequest) => {
    switch (state) {
      case TuneRequestState.Accepted:
        const authorized = !(shouldBeCheckedForPC(tuneRequest.media) && isMediaBlocked(tuneRequest.media));
        setCurrentMedia({media: tuneRequest.media, authorized});
        updateNavigationParams({media: tuneRequest.media});
        hidePlaybackControls();
        clearPlaybackError();
        setIsChannelBlocked(false);
        setLoading(true);
        // switch to one channel epg in case a tune was requested from other screen
        const requestedChannelId = props.navigation.getParam('channelId');
        if (requestedChannelId && tuneRequest.media.id === requestedChannelId && viewMode !== ViewMode.Epg) {
          props.navigation.setParams({viewMode: ViewMode.OneChannelEpg});
        }
        break;
      case TuneRequestState.Completed: {
        setIsChannelBlocked(false);
        // Make sure after tuning, that the target playerView is correct.
        player
          .switchPlayerView(getPlayerView(playerLocation))
          .catch(reason => Log.error(TAG, 'Failed to switch playerView:', reason));
        break;
      }
      case TuneRequestState.UnavailableByPolicy: {
        setIsChannelBlocked(true);
        setLoading(false);
        if (tunedEvent) {
          setCurrentEvent(tunedEvent);
          refreshControlsViewButtonsVisibility(tunedEvent);
        }
        break;
      }
    }
  });
  useEventListener(PlayerEvent.Loading, setLoading, mw.players.main);

  const onLiveEventChanged = useCallback((event?: Event) => {
    if (isEvent(event)) {
      setCurrentEvent(event);
      onCurrentEventChanged(event);
    }
  }, [onCurrentEventChanged]);

  useEventListener(PlayerEvent.MediaUpdate, onLiveEventChanged, mw.players.main);

  const setPlayerVisibility = useFunction(({event, blocked}: BlockedByPCEventState) => {
    const shouldUnlock = !(event && shouldBeCheckedForPC(event) && isMediaBlocked(event));
    if (blocked && shouldUnlock) {
      mw.players.main.unblockPC();
    }
    setCurrentMedia({media: currentMedia?.media, authorized: shouldUnlock || !blocked});
    showPlaybackControls();
    if (event) {
      onLiveEventChanged(event);
    } else {
      Log.error(TAG, `setPlayerVisibility didn't get right event`);
    }
  });

  useEventListener(PlayerEvent.BlockedByParentalControl, setPlayerVisibility, mw.players.main);

  // tune on navigating into this screen
  useLazyEffect(() => {
    if (focusState === NavigationFocusState.IsFocused) {
      if (!floating && epgPlayerOpened) {
        const position = navigation.getParam('position');
        tune({
          playerView: getPlayerView(playerLocation),
          callback: onTuneRequestStateChange,
          requestedByRoute,
          params: {position}
        });
      }
      setFloating(false);
    } else if (focusState === NavigationFocusState.IsBlurred) {
      clearNavigationParams();
    }
  }, [focusState], [orientation.isLandscape, floating, tune, playerLocation, onTuneRequestStateChange, requestedByRoute, clearNavigationParams]);

  useLazyEffect(() => {
    if (tunedEvent) {
      const eventDate = DateUtils.startOfDay(tunedEvent.start);
      const dateIndex = dates.findIndex(date => date >= eventDate);
      if (dateIndex !== -1 && selectedDateIndex !== dateIndex) {
        setSelectedDateIndex(dateIndex);
      }
    }
  }, [tunedEvent], [selectedDateIndex, dates]);

  const onTapFloater = useCallback(() => {
    navigation.setParams({viewMode: ViewMode.OneChannelEpg});
    startPlayback({tvScreenParams: navigation.state.params});
  }, [navigation, startPlayback]);

  const onSwipeFloater = useCallback(() => {
    TopLevelPortalManager.getInstance().hideTopLevelPortal();
    setFloating(false);
    player.stop();
  }, [setFloating, player]);

  const handleCloseFloaterInOtherScreen = useFunction((portalType?: TopLevelPortalType) => {
    if (focusState !== NavigationFocusState.IsFocused && floating && portalType === TopLevelPortalType.None) {
      setFloating(false);
      // don't need to stop player here, PlayerManager handles that
    }
  });
  useEventListener('change', handleCloseFloaterInOtherScreen, TopLevelPortalManager.getInstance());

  const displayFullscreenPortal = useCallback(() => {
    TopLevelPortalManager.getInstance().displayFullscreenPortal();
    setPlayerLocation(TopLevelPortalType.Fullscreen);
  }, [setPlayerLocation]);

  // manage player location based on orientation/floating state
  useLazyEffect(() => {
    if (orientation.isLandscape) {
      if (focusState === NavigationFocusState.IsFocused || floating) {
        displayFullscreenPortal();
      }
    } else if (floating) {
      TopLevelPortalManager.getInstance().displayFloatingPortal(onTapFloater, onSwipeFloater);
      setPlayerLocation(TopLevelPortalType.Floating);
    } else if (focusState === NavigationFocusState.IsFocused && epgPlayerOpened) {
      TopLevelPortalManager.getInstance().hideTopLevelPortal();
      setPlayerLocation('embedded');
    }
  }, [orientation, floating, focusState], [onTapFloater, displayFullscreenPortal, setPlayerLocation, onSwipeFloater]);

  const onSwipe = useCallback((direction: Direction) => {
    const channel = findChannelByDirection(direction);
    showPlayerOverlay();
    if (!channel) {
      Log.error(TAG, 'onSwipe: channel is undefined!');
      return;
    }
    tune({playerView: getPlayerView(playerLocation), media: channel, callback: onTuneRequestStateChange, floating});
  }, [findChannelByDirection, tune, playerLocation, onTuneRequestStateChange, floating, showPlayerOverlay]);

  const onSwipeDown = useCallback(() => {
    Log.trace('TvScreenPiccolo', 'Swiped down');
    onSwipe(Direction.Down);
  }, [onSwipe]);

  const onSwipeUp = useCallback(() => {
    Log.trace('TvScreenPiccolo', 'Swiped up');
    onSwipe(Direction.Up);
  }, [onSwipe]);

  const onSwipeGestureStart = useFunction(() => setOverlayExtendedInfoVisible(false));
  const onSwipeGestureEnd = useFunction((isCloseGesture: boolean) => {
    if (isCloseGesture) {
      setOverlayExtendedInfoVisible(leftMenuShowed);
    } else {
      setOverlayExtendedInfoVisible(!leftMenuShowed);
    }
  });

  const onSwipeLeft = useCallback(() => {
    setLeftMenuShowed(false);
    Animated.parallel([
      Animated.timing(leftMenuWidth.current, {
        toValue: -leftSectionWidth,
        duration: 500,
        easing: Easing.linear
      }),
      Animated.timing(videoOffsetX.current, {
        toValue: 0,
        duration: 500,
        easing: Easing.linear
      })
    ]).start();
  }, [leftMenuWidth, videoOffsetX]);

  const onSwipeFromEdge = useCallback(() => {
    setLeftMenuShowed(true);
    Animated.parallel([
      Animated.timing(leftMenuWidth.current, {
        toValue: 0,
        duration: 500,
        easing: Easing.linear
      }),
      Animated.timing(videoOffsetX.current, {
        toValue: leftSectionWidth,
        duration: 500,
        easing: Easing.linear
      })
    ]).start();
  }, [leftMenuWidth, videoOffsetX]);

  useChangeEffect(() => setOverlayExtendedInfoVisible(!leftMenuShowed), [leftMenuShowed]);

  const tuneToChannel = useCallback((channel: Channel) => {
    if (currentChannel !== channel) {
      tune({playerView: getPlayerView(playerLocation), media: channel, callback: onTuneRequestStateChange, floating});
    }
  }, [tune, playerLocation, onTuneRequestStateChange, currentChannel, floating]);

  bindHandleChannelNotAvailable(useCallback(() => {
    channels.length && tuneToChannel(channels[0]);
  }, [channels, tuneToChannel]));

  const onPressChannelIcon = useCallback((channel: Channel) => {
    navigation.setParams({viewMode: ViewMode.OneChannelEpg});
    tuneToChannel(channel);
  }, [navigation, tuneToChannel]);

  const onBackPress = useFunction(() => {
    if (viewMode === ViewMode.ChannelsGrid) return;
    navigation.setParams({viewMode: ViewMode.ChannelsGrid});
  });

  // Effect required to restore player state upon transition from EPG view with minimalised player
  useChangeEffect(() => {
    if (isPhone && viewMode === ViewMode.ChannelsGrid && !mw.players.main.isPlayerInitialized()) {
      const position = navigation.getParam('position');
      setPlayerLocation('embedded');

      tune({
        playerView: PlayerViews.Zapper,
        requestedByRoute,
        params: {position}
      });
    }
  }, [viewMode], [navigation, requestedByRoute, tune]);

  useBackPressCapture(() => {
    if (focusState === NavigationFocusState.IsFocused) {
      if (viewMode === ViewMode.OneChannelEpg) {
        navigation.setParams({viewMode: ViewMode.ChannelsGrid});
      }
      return true;
    }
    return false;
  });

  const showEpg = useCallback(() => {
    if (isPhone) {
      navigation.setParams({viewMode: ViewMode.Epg});
      if (playerLocation === TopLevelPortalType.Fullscreen) {
        const playbackParams = {tvScreenParams: {viewMode: ViewMode.Epg}};
        startPlayback(playbackParams);
        TopLevelPortalManager.getInstance().hideTopLevelPortal();
      }
      if (orientation.isLandscape) {
        isLockingOrientation.current = true;
        setSupportedOrientation('portrait');
      }
    } else {
      navigation.navigate(AppRoutes.Epg);
    }
  }, [navigation, orientation.isLandscape, playerLocation, startPlayback]);

  const playbackOverlayControlsVisibility: PlayerControlsViewButtonsVisibility = useMemo(() => {
    const authorized = !!currentMedia?.authorized;
    const controlsVisibility: PlaybackControlsVisibility = (authorized && playbackControlsVisible) ?
      propsPlaybackControlsVisibility :
      hiddenPlaybackControls;

    return {
      collapse: orientation.isPortrait && authorized,
      episodes: false, // TODO: show when current event is a part of series
      recordDot: tvControlsViewButtonsVisibility.recordDot && authorized ? tvControlsViewButtonsVisibility.recordDot : null,
      channelList: false,
      chromecast: authorized && castControlEnabled,
      options: authorized,
      prevEpisode: false, // TODO: show for vods that are part of series
      nextEpisode: false, // TODO: show for vods that are part of series
      fullscreen: authorized && (orientation.isPortrait || (isTablet && leftMenuShowed && orientation.isLandscape)),
      backFromFullscreen: authorized && orientation.isLandscape && (isPhone || (isTablet && !leftMenuShowed)),
      showEPG: orientation.isLandscape && authorized,
      moreInfo: false,
      playback: controlsVisibility
    };
  }, [
    currentMedia, orientation.isPortrait, orientation.isLandscape,
    tvControlsViewButtonsVisibility.recordDot, leftMenuShowed, playbackControlsVisible,
    propsPlaybackControlsVisibility, castControlEnabled
  ]);

  const playerControlsHandlers: PlayerControlsHandlers = useMemo(() => ({
    ...tvPlaybackControlsHandlers,
    restartHandler: () => {
      tvPlaybackControlsHandlers.restartHandler({playerView: getPlayerView(playerLocation), callback: onTuneRequestStateChange});
    },
    playPauseHandler: () => {
      tvPlaybackControlsHandlers.playPauseHandler();
    },
    collapseHandler: () => {
      setFloating(true);
      navigation.navigate(AppRoutes.Home);
    },
    toggleHandler: () => {
      showPlayerOverlay();
      showMoreActionsPopup();
    },
    fullScreenHandler: () => {
      if (isTablet) {
        setLeftMenuShowed(false);
      }
      if (isPhone || orientation.isPortrait) {
        isLockingOrientation.current = true;
        setSupportedOrientation('landscape');
      }
    },
    fullScreenBackHandler: () => {
      if (isTablet && orientation.isLandscape && !isLockingOrientation.current) {
        setLeftMenuShowed(true);
      } else {
        isLockingOrientation.current = true;
        setSupportedOrientation('portrait');
      }
    },
    showEPGHandler: () => {
      showEpg();
    },
    goToLiveHandler: () => {
      showGoToLivePopup();
    },
    skipBackHandler: () => {
      tvPlaybackControlsHandlers.skipBackHandler();
    },
    skipForwardHandler: () => {
      tvPlaybackControlsHandlers.skipForwardHandler({playerView: getPlayerView(playerLocation), callback: onTuneRequestStateChange});
    },
    castHandler: castControlHandler
  }), [
    tvPlaybackControlsHandlers, playerLocation, onTuneRequestStateChange,
    navigation, showPlayerOverlay, showMoreActionsPopup, orientation.isPortrait,
    orientation.isLandscape, showEpg, showGoToLivePopup, castControlHandler
  ]);

  const mediaInfoVisibility: MediaInfoVisibility = useMemo(() => {
    const playbackAuthorized = !!currentMedia?.authorized;
    return {
      mediaDetails: playbackAuthorized || orientation.isLandscape,
      progressBar: playbackAuthorized
    };
  }, [currentMedia, orientation.isLandscape]);

  useWillAppear(
    useCallback((payload: NavigationEventPayload) => {
      const mode = payload.state.params.viewMode;
      if (mode) {
        navigation.setParams({viewMode: mode});
      } else if (payload.action.type !== 'Navigation/BACK') {
        navigation.setParams({viewMode: ViewMode.ChannelsGrid});
      }
    }, [navigation])
  );

  useEffect(() => {
    if (focusState !== NavigationFocusState.IsFocused) {
      return;
    }
    const handler = () => {
      if (isLockingOrientation.current) {
        isLockingOrientation.current = false;
        return;
      }
      if (viewMode === ViewMode.Epg && !epgPlayerOpened) {
        setSupportedOrientation('portrait');
      } else {
        setSupportedOrientation('all');
      }
    };
    Orientation.addDeviceOrientationListener(handler);
    return () => Orientation.removeDeviceOrientationListener(handler);
  }, [epgPlayerOpened, focusState, viewMode]);

  const renderChannelIcon = useCallback((channelIconProps: GridElementProps<Channel>) => {
    return (
      <View style={{marginLeft: channelIconProps.spacing, marginBottom: channelIconProps.spacing, width: channelIconWidth, height: channelIconWidth, alignItems: 'center', justifyContent: 'center'}}>
        <ChannelIcon channelId={channelIconProps.data.id} type={ChannelIconType.ChannelGrid} isSelected={currentChannel && channelIconProps.data.id === currentChannel.id} onPress={() => onPressChannelIcon(channelIconProps.data)} />
      </View>
    );
  }, [currentChannel, onPressChannelIcon]);
  const renderChannelsGridPlaceholder = useCallback(() => {
    return (
      <View style={{alignItems: 'center', justifyContent: 'center', paddingTop: minimumGridSpacing / 2, flexGrow: 1}}>
        <ActivityIndicator size='small' />
      </View>
    );
  }, []);

  const videoSize = useMemo(() => dimensions.videoSize(screenSize.width), [screenSize.width]);

  // attach player to new playerView
  const onPlayerViewReady = useCallback(() => {
    if (focusState !== NavigationFocusState.IsFocused && !floating) {
      return;
    }
    player.switchPlayerView(getPlayerView(playerLocation))
      .catch(reason => {
        Log.error(TAG, 'onPlayerViewReady', reason);
      });
  }, [focusState, floating, player, playerLocation]);

  const mobileHeader = useMemo<MobileScreenHeaderProps>(() => ({
    // disable back button override (which allows internal navigation between view modes) when phoneEpgMode is on or requestedByRoute param is not set
    showBackButton: !phoneEpgMode && (viewMode === ViewMode.OneChannelEpg || viewMode === ViewMode.Epg) && !requestedByRoute,
    onBackPress: onBackPress,
    title: t(viewMode === ViewMode.Epg ? 'cms.epg' : 'common.liveTV'),
    // There are 2 cases zapper can be reached from search screen:
    // 1. Tapping on channel search result. In this case, showing back button makes sense.
    // 2. Using main menu (bottom bar). We want to hide back button in this case because its not consistent with navigation from other screens.
    hideNativeBackButton: !requestedByRoute
  }), [phoneEpgMode, viewMode, requestedByRoute, onBackPress, t]);

  const isEventAuthorized = useCallback(async (event: Event) => {
    if (!currentMedia) {
      return false;
    }
    if (isEvent(currentMedia.media)) {
      return currentMedia.media.id === event.id && currentMedia.authorized;
    }
    if (isChannel(currentMedia.media) && currentMedia.authorized) {
      try {
        const currentEvent = await mw.catalog.getCurrentEvent(currentMedia.media);
        return !!currentEvent && currentEvent.id === event.id;
      } catch (error) {
        Log.warn(TAG, `Failed to find current event on channel ${currentMedia.media} - got error:`, error);
        return false;
      }
    }
    Log.info(TAG, 'Unknown media type. Treating it as unauthorized.');
    return false;
  }, [currentMedia]);

  /**
   * Reset pending tune on screen blur.
   */
  useChangeEffect(() => {
    if (focusState !== NavigationFocusState.IsFocused) {
      pendingTuneFor.current = undefined;
    }
  }, [focusState]);
  /**
   * This synchronously executes tune to ensure dependencies up-to-dateness.
   */
  const executeTuneEvent = useFunction((event: Event, additionalParams?: Partial<TuneParams>) => {
    if (focusState !== NavigationFocusState.IsFocused) {
      return;
    }
    if (pendingTuneFor.current === event) {
      tune({playerView: getPlayerView(playerLocation), media: event, callback: onTuneRequestStateChange, ...additionalParams});
      pendingTuneFor.current = undefined;
      setPlayerVisibility({event, blocked: false});
    } else {
      Log.info(TAG, `Ignoring tune for event ${event}, pending tune has changed.`);
    }
  });
  const tuneEvent = useCallback((event: Event, additionalParams?: Partial<TuneParams>) => {
    pendingTuneFor.current = event;
    tuneEventHandler.current?.handleTune(event, (position: number) => {
      if (!additionalParams) {
        additionalParams = {};
      }
      if (!additionalParams.params) {
        additionalParams.params = {};
      }
      additionalParams.params.position = position;
      executeTuneEvent(event, additionalParams);
    });
  }, [executeTuneEvent]);

  const openEventDetail = useCallback(async (event: Event) => {
    openMediaDetails(navigation, event.id, event.getType());
  }, [navigation]);

  const goToLive = useCallback(() => {
    tvPlaybackControlsHandlers.goToLiveHandler({playerView: getPlayerView(playerLocation), media: null, callback: onTuneRequestStateChange});
    closeGoToLivePopup();
  }, [tvPlaybackControlsHandlers, onTuneRequestStateChange, playerLocation, closeGoToLivePopup]);

  const onEPGButtonPress = useCallback(() => {
    if (isPhone) {
      navigation.setParams({viewMode: ViewMode.Epg});
    } else {
      navigation.push(AppRoutes.Epg);
    }
  }, [navigation]);

  const [changingPosition, setChangingPosition] = useState(false);

  // first frame can arrive before Position Change event triggered by skip action
  const onFirstPositionChange = useDisposableCallback(() => setChangingPosition(false), []);
  useEventListener(
    PlayerEvent.FirstFrame,
    () => {
      mw.players.main.once(PlayerEvent.PositionChanged, onFirstPositionChange);
      const media = mw.players.main.getCurrentMedia();
      if (isEvent(media)) { //for TSTV playback
        refreshControlsViewButtonsVisibility(media);
        showPlaybackControls();
      }
    },
    mw.players.main
  );

  const onEndOfContent = useDisposableCallback(() => {
    if (focusState !== NavigationFocusState.IsFocused || mw.players.main.contentType !== ContentType.TSTV) {
      return;
    }
    Log.info(TAG, 'End of TSTV playback detected');
    if (tunedByRoute) {
      Log.info(TAG, `Going back to the route ${tunedByRoute} from which the tune request originated`);
      navigation.navigate(tunedByRoute, {
        mediaId: navigation.getParam('eventId'),
        mediaType: MediaType.Event
      });
      return;
    }
    Log.info(TAG, 'Tunning LiveTV playback');
    goToLive();
  }, [focusState, tunedByRoute, navigation, goToLive]);

  useEventListener(PlayerEvent.EndOfContent, onEndOfContent, mw.players.main);

  const onProgressBarPositionChanged = useCallback((position: number) => {
    showPlayerOverlay();

    const player = mw.players.main;
    const contentType = player.contentType;

    if (!isChannel(currentChannel)) {
      Log.error(TAG, 'no currentChannel to switch progress', currentChannel);
      return;
    }

    if (!isEvent(currentEvent)) {
      Log.error(TAG, 'no currentEvent to switch progress', currentEvent);
      return;
    }

    setChangingPosition(true);
    // in case user skips between first frame and position change
    mw.players.main.off(PlayerEvent.PositionChanged, onFirstPositionChange);

    const positionInSec = getMediaDuration(currentEvent, contentType) * position / DateUtils.msInSec;

    switch (contentType) {
      case ContentType.LIVE:
      case ContentType.NPLTV:
        if (isLivePosition(currentEvent, positionInSec)) {
          Log.info(TAG, 'onProgressBarPositionChanged: time is near live - tune to live');
          tvPlaybackControlsHandlers.goToLiveHandler({playerView: getPlayerView(playerLocation), callback: onTuneRequestStateChange});
        } else if (currentEvent) {
          player.changeParameters({position: currentEvent.start.getTime() / DateUtils.msInSec + positionInSec})
            .catch((error: Error) => Log.error(TAG, 'onProgressBarPositionChanged', contentType, position, error));
        }
        break;
      default:
        if (isLivePosition(currentEvent, positionInSec)) {
          Log.info(TAG, 'onProgressBarPositionChanged: time is near live - tune to live');
          tvPlaybackControlsHandlers.goToLiveHandler({playerView: getPlayerView(playerLocation), callback: onTuneRequestStateChange});
        } else {
          player.changeParameters({position: positionInSec})
            .catch((error: Error) => Log.error(TAG, 'onProgressBarPositionChanged', contentType, position, error));
        }
        break;
    }
  }, [showPlayerOverlay, currentChannel, currentEvent, onFirstPositionChange, tvPlaybackControlsHandlers, playerLocation, onTuneRequestStateChange]);

  const closePlayerView = useCallback(() => {
    TopLevelPortalManager.getInstance().hideTopLevelPortal();
    mw.players.main.stop();
    setPlayerLocation('none');
    setEpgPlayerOpened(false);
  }, []);

  const openPlayerView = useCallback((channelId?: string) => {
    TopLevelPortalManager.getInstance().hideTopLevelPortal();
    isPhone && tune({playerView: getPlayerView(playerLocation), media: channelId ? mw.catalog.getChannelById(channelId) : null, callback: onTuneRequestStateChange});
    setPlayerLocation('embedded');
    setEpgPlayerOpened(true);
  }, [onTuneRequestStateChange, playerLocation, tune]);

  const onEpgEventPress = useCallback((event: Event) => {
    if (isPhone && event && !event.isNow) {
      openMediaDetails(props.navigation, event.id, MediaType.Event);
    }
  }, [props.navigation]);

  const onMoreActionsPress = useCallback((event: Event) => {
    if (event) {
      setCurrentEvent(event);
      showMoreActionsPopup();
    }
  }, [showMoreActionsPopup]);

  const onScreenBlurred = useCallback(() => {
    setSupportedOrientation('all');
  }, []);

  const styles = stylesUpdater.getStyles();
  const chromecastExtraBottomPadding = useChromecastExtraBottomPadding();

  const onAdultContentUnlockRequested = useCallback(() => {
    shouldBeCheckedForPC(tunedEvent) && unlockMedia(tunedEvent)
      .then(unlocked => {
        if (unlocked) {
          mw.players.main.unblockPC();
        }
      });
  }, [shouldBeCheckedForPC, tunedEvent, unlockMedia]);

  return (
    <NitroxScreen
      navigation={navigation}
      stopPlaybackOnAppear={false}
      allowedOrientations={supportedOrientation}
      style={[styles.container, (isTablet && orientation.isLandscape) ? styles.tabletOptionalContainer : {}]}
      showMobileHeader={orientation.isPortrait}
      mobileHeaderProps={mobileHeader}
      onScreenBlurred={onScreenBlurred}
      testID='screen_tv'
    >
      <GestureRecognizerView
        style={styles.gestureRecognizer}
        directions={mainGestureRecognizerDirections}
        swipeFromEdge={Direction.Left}
        onSwipeFromEdge={onBackPress}
      >
        {viewMode !== ViewMode.Epg && (
          <View style={{...videoSize, ...styles.playerPortal}}>
            {playerLocation === 'embedded' && focusState === NavigationFocusState.IsFocused && <WhitePortal name='embedded' />}
          </View>
        )}
        {orientation.isPortrait && viewMode === ViewMode.Epg && (
          <EpgScreenMobile
            ref={epgMobileRef}
            style={styles.mobileContainer}
            onOpenPlayerView={openPlayerView}
            onClosePlayerView={closePlayerView}
            onEventPress={onEpgEventPress}
            onEventMorePress={onMoreActionsPress}
            channels={channels}
            currentChannel={currentChannel}
            playerLocation={playerLocation}
          />
        )}
        {orientation.isPortrait && viewMode === ViewMode.ChannelsGrid && (
          <View style={{...styles.channelListViewContainer, paddingHorizontal: minimumGridSpacing}}>
            <ChannelsListsMenu showEPGButton onEPGButtonPress={onEPGButtonPress} />
            {channelsLoading
              ? renderChannelsGridPlaceholder()
              : (
                <Grid
                  testID='channel_list'
                  style={{paddingTop: minimumGridSpacing / 2, width: '100%'}}
                  itemWidth={channelIconWidth}
                  minimumSpacing={minimumGridSpacing}
                  maximumSpacing={dimensions.margins.xxxLarge}
                  data={channels}
                  extraData={currentChannel}
                  createElement={renderChannelIcon}
                  contentContainerStyle={chromecastExtraBottomPadding}
                />
              )
            }
          </View>
        )}
        {orientation.isPortrait && viewMode === ViewMode.OneChannelEpg && (
          <View style={styles.oneChannelEpgPortraitContainer}>
            <View style={styles.channelSelector}>
              <HorizontalChannelsListPiccolo
                channels={channels}
                selectedChannelId={currentChannel ? currentChannel.id : undefined}
                onPress={tuneToChannel}
              />
            </View>
            <DatePicker
              dates={dates}
              currentDateIndex={selectedDateIndex}
              backgroundColor={styles.datePickerBackground}
              onDateChangedHandler={setSelectedDateIndex}
              width={screenSize.width}
              itemWidthRatio={datePickerItemWidthRatio}
            />
            {currentChannel && (
              <OneChannelEpg
                startTime={dates[selectedDateIndex] || new Date()}
                channel={currentChannel}
                tuneEvent={tuneEvent}
                openEventDetail={openEventDetail}
                isEventAuthorized={isEventAuthorized}
                tunedEvent={tunedEvent}
                showEpg={showEpg}
              />
            )}
          </View>
        )}
        {currentChannel && (
          <EventMoreActionsPopup
            visible={moreActionsPopupVisible}
            onClose={closeMoreActionsPopup}
            event={currentEvent}
            openEventDetail={openEventDetail}
            isEventAuthorized={isEventAuthorized}
            showEpgAction={viewMode !== ViewMode.Epg}
            showEpg={showEpg}
            tuneEvent={tuneEvent}
          />
        )}
        <GoToLivePopup
          visible={goToLivePopupVisible}
          onCancel={closeGoToLivePopup}
          onConfirmation={goToLive}
        />
        <TuneEventHandler ref={tuneEventHandler} />
      </GestureRecognizerView>
      {(focusState === NavigationFocusState.IsFocused || floating) && (
        <NitroxBlackPortal name={playerLocation}>
          <ConditionalWrapper
            condition={isTablet && orientation.isLandscape}
            wrapper={children => (
              <TvScreenTabletSwipeWrapper
                channels={channels}
                leftMenuShowed={leftMenuShowed}
                onSwipeFromEdge={onSwipeFromEdge}
                onSwipeGestureStart={onSwipeGestureStart}
                onSwipeGestureEnd={onSwipeGestureEnd}
                onSwipeLeft={onSwipeLeft}
                onTuneRequestStateChange={onTuneRequestStateChange}
                openEventDetail={openEventDetail}
                playerLocation={playerLocation}
                selectedDateIndex={selectedDateIndex}
                setSelectedDateIndex={setSelectedDateIndex}
                tune={tune}
                tuneEvent={tuneEvent}
                currentChannel={currentChannel}
                showPlayerOverlay={showPlayerOverlay}
                tunedEvent={tunedEvent}
                showEpg={showEpg}
              >
                {children}
              </TvScreenTabletSwipeWrapper>
            )}
          >
            <PlayerView
              debugName={TAG}
              type={getPlayerView(playerLocation)}
              style={StyleSheet.absoluteFillObject}
              onReady={onPlayerViewReady}
            >
              {isChannelBlocked && !loading && (
                <UnavailableChannelMessage />
              )}
              <GestureRecognizerView
                style={styles.gestureRecognizer}
                directions={playerViewGestureRecognizerDirections}
                onSwipeDown={onSwipeDown}
                onSwipeUp={onSwipeUp}
                gestureRecognitionInsets={swipeRecognizerInsets}
              >
                <View
                  onTouchEnd={showPlayerOverlay}
                  style={styles.playerViewTouchOverlay}
                  collapsable={false}
                />
                {playerLocation !== TopLevelPortalType.Floating && (
                  <View style={{...styles.shade, ...overlayVisible ? StyleSheet.absoluteFillObject : styles.hidden}}>
                    <TapRecognizerView
                      style={StyleSheet.absoluteFillObject}
                      onTap={hidePlayerOverlay}
                    >
                      <Zapper {...{
                        authorized: currentMedia?.authorized,
                        channels,
                        changingPosition,
                        currentChannel: currentChannel || channels[0],
                        currentPositionChanged: onProgressBarPositionChanged,
                        onProgressChange: showPlayerOverlay,
                        onGestureStateChange: preventHideOverlay,
                        onPressChannelIcon,
                        paused,
                        orientation,
                        overlayVisible,
                        playerControlsHandlers,
                        buttonsVisibility: playbackOverlayControlsVisibility,
                        mediaInfoVisibility,
                        shortDetailsVisible: overlayExtendedInfoVisible,
                        channelsListVisible: overlayExtendedInfoVisible,
                        forceDisplayEvent: currentEvent,
                        onScroll: showPlayerOverlay,
                        playbackError: !!playbackError
                      }}
                      />
                    </TapRecognizerView>
                  </View>
                )}
                {currentMedia && !loading && !currentMedia.authorized && (
                  <>
                    <View style={styles.buttonWrapper} pointerEvents='box-none'>
                      <Icon type={IconType.ParentalControl} size={parentalControlIconSize} color={styles.iconColor} />
                      <NitroxButton text={t('common.enterPin')} onPress={onAdultContentUnlockRequested} style={styles.button} />
                    </View>
                    <AppStatePlaybackRetuneScheduler />
                  </>
                )}
              </GestureRecognizerView>
            </PlayerView>
          </ConditionalWrapper>
        </NitroxBlackPortal>
      )}
      {renderPlaybackErrorPopups()}
    </NitroxScreen>
  );
};

export default TvScreenPiccolo;
