import React, {ReactElement, useCallback, useState, useMemo, RefObject} from 'react';
import {StyleProp, View, ViewStyle, ActivityIndicator, LayoutChangeEvent, Insets} from 'react-native';

import {TestProps} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {useScrollViewPaging} from 'hooks/Hooks';

import FocusParent from './FocusParent';
import NitroxFlatList from './NitroxFlatList';

const TAG = 'Grid';
const containerStyle: ViewStyle = {flex: 1, flexDirection: 'row', justifyContent: 'center'};
const focusParentStyle = {flex: 1};

export interface GridElementProps<DataType> {
  index: number;
  data: DataType;
  spacing: number;
}

export type GridProps<DataType> = {
  data: DataType[];
  // use it if your data has no id and you don't want to use index as key
  keyExtractor?: (item: DataType, index: number) => string;
  animatingFetchingActivityIndicator?: boolean;
  animatingAppendingActivityIndicator?: boolean;
  contentInset?: Insets;
  fetchingActivityIndicatorStyle?: ViewStyle;
  appendingActivityIndicatorStyle?: ViewStyle;
  extraData?: any; // use it to rerender grid
  itemWidth: number;
  minimumSpacing: number;
  maximumSpacing: number;
  contentContainerStyle?: StyleProp<ViewStyle>;
  style?: ViewStyle;
  columnWrapperStyle?: StyleProp<ViewStyle>;
  createElement: (props: GridElementProps<DataType>) => ReactElement;
  onEndReached?: () => void;
  onEndReachedThreshold?: number; // works similarly to Flatlist's prop
  flatListRef?: RefObject<NitroxFlatList<DataType>>;
} & TestProps;

const defaultKeyExtractor = (item: any, index: number) => {
  if (typeof item.id === 'string') {
    return item.id;
  }
  return index.toString();
};

export function GridComponent<DataType>(props: GridProps<DataType>) {
  const {
    data,
    animatingFetchingActivityIndicator = false,
    fetchingActivityIndicatorStyle,
    animatingAppendingActivityIndicator = false,
    appendingActivityIndicatorStyle,
    contentInset,
    itemWidth,
    minimumSpacing,
    maximumSpacing,
    style,
    testID
  } = props;
  const [width, setWidth] = useState(0);
  const onScroll = useScrollViewPaging(props.onEndReached, props.onEndReachedThreshold);

  const renderFooterActivityIndicator = useCallback(() => {
    return <ActivityIndicator style={appendingActivityIndicatorStyle} animating={animatingAppendingActivityIndicator} />;
  }, [animatingAppendingActivityIndicator, appendingActivityIndicatorStyle]);

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    setWidth(event.nativeEvent.layout.width);
  }, []);

  const [columnCount, spacing, applyToFirstItem] = useMemo(() => {
    const columnCount = Math.floor((width + minimumSpacing) / (itemWidth + minimumSpacing));
    if (columnCount < 1) {
      return [0, 0, false];
    }
    const freeSpace = width - columnCount * itemWidth;
    let applyToFirstItem = columnCount === 1;
    let spacing = minimumSpacing;
    if (data.length >= columnCount) {
      spacing = freeSpace / (columnCount > 1 ? columnCount - 1 : 2);
    }
    if (maximumSpacing > 0 && spacing > maximumSpacing) {
      applyToFirstItem = true;
      spacing = freeSpace / (columnCount + 1);
    }
    return [columnCount, spacing, applyToFirstItem];
  }, [width, itemWidth, minimumSpacing, maximumSpacing, data]);

  const getSpacing = useCallback((index: number) => {
    return (index % columnCount > 0 || applyToFirstItem) ? spacing : 0;
  }, [columnCount, spacing, applyToFirstItem]);

  const columnWrapperStyle = columnCount > 1 ? [props.columnWrapperStyle, {flex: 1}] : undefined;

  const keyExtractor = useMemo(() => {
    if (!props.keyExtractor) {
      Log.warn(TAG, 'You did not provide keyExtractor, using default behaviour (id or index if id not present).');
      return defaultKeyExtractor;
    }
    return props.keyExtractor;
  }, [props.keyExtractor]);

  return (
    <View
      onLayout={onLayout}
      style={[containerStyle, style]}
      testID={testID}
    >
      {columnCount > 0 && (
        <>
          {fetchingActivityIndicatorStyle && <ActivityIndicator style={fetchingActivityIndicatorStyle} animating={animatingFetchingActivityIndicator} />}
          <FocusParent enterStrategy='topLeft' style={focusParentStyle}>
            <NitroxFlatList
              ref={props.flatListRef}
              style={props.style}
              key={columnCount /* needed to dynamically change columnCount: "Changing numColumns on the fly is not supported. Change the key prop on FlatList when changing the number of columns to force a fresh render of the component." */}
              scrollEventThrottle={5}
              nestedScrollEnabled
              onScroll={onScroll}
              contentContainerStyle={props.contentContainerStyle}
              columnWrapperStyle={columnWrapperStyle}
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}
              contentInset={contentInset}
              contentInsetAdjustmentBehavior='never'
              numColumns={columnCount}
              data={data}
              extraData={props.extraData}
              renderItem={({item, index}) => props.createElement({data: item, index, spacing: getSpacing(index)})}
              keyExtractor={keyExtractor}
              ListFooterComponent={renderFooterActivityIndicator}
            />
          </FocusParent>
        </>
      )}
    </View>
  );
}

export const Grid = React.memo(GridComponent) as typeof GridComponent;
