import React, {useImperativeHandle, useRef, useMemo, useCallback} from 'react';
import {Insets, View} from 'react-native';

import {isDesktopBrowser, isSTBBrowser, defaultPageSize} from 'common/constants';

import {Media} from 'mw/api/Metadata';
import {FilterBasedDataSource, Component as UXMComponent} from 'mw/cms/Component';
import {mw} from 'mw/MW';
import {MetaEventsEmitter, DataEvents} from 'mw/utils/MetaEventsEmitter';

import {AnimatedSwimlane, AnimatedSwimlaneInterface} from 'components/AnimatedSwimlane';
import {swimlaneItemWidth, swimlaneItemHeight, defaultSwimlaneInsets, useDefaultMediaSwimlaneTiles, useDefaultVisibilityLimits} from 'components/AnimatedSwimlaneStack';
import {AnimatedVerticalStackRowProps} from 'components/AnimatedVerticalStack';
import {useMediaTileFocusedStyle} from 'components/mediaTiles/MediaTileBase';
import {SwimlaneDataFetcherState} from 'components/Swimlane';
import {createLimitedDataFetcher} from 'components/utils/SwimlaneVisibilityLimit';
import {useFunction, useLazyRef, useEffectOnce} from 'hooks/Hooks';

import {UXMComponentStackRowInterface} from './UXMComponentStackRow';

export function useDefaultMediaSwimlaneMetaEvents(dataSource: FilterBasedDataSource): DataEvents<Media> {
  const metaEventsEmitter = useLazyRef(() => new MetaEventsEmitter()).current;
  useEffectOnce(() => {
    metaEventsEmitter.addListeners();
    return () => metaEventsEmitter.removeListeners();
  }, [metaEventsEmitter]);

  const dataEvents: DataEvents<Media> = useMemo(
    () => metaEventsEmitter.getDataEventsForFilter(dataSource.filters[0]),
    [dataSource.filters, metaEventsEmitter]
  );

  return dataEvents;
}

type Props = {
  component: UXMComponent & {
    dataSource: FilterBasedDataSource;
  }
  insets?: Insets;
} & AnimatedVerticalStackRowProps;

const SwimlaneBasedStackRow = React.memo(React.forwardRef<UXMComponentStackRowInterface, Props>(({
  index,
  component,
  layout,
  insets,
  focused,
  onElementFocus,
  onHover,
  onLoaded
}: Props, ref) => {
  const swimlaneRef = useRef<AnimatedSwimlaneInterface>(null);

  /* eslint-disable schange-rules/no-use-imperative-handle-hook */
  useImperativeHandle(ref, () => ({
    onPress: () => {
      swimlaneRef.current?.onPress();
    },
    onNavigateLeft: () => {
      swimlaneRef.current?.scrollLeft();
    },
    onNavigateRight: () => {
      swimlaneRef.current?.scrollRight();
    },
    onNavigateUp: () => {
      return {shouldStay: swimlaneRef.current?.navigateUp() ?? false};
    },
    onNavigateDown: () => {
      return {shouldStay: swimlaneRef.current?.navigateDown() ?? false};
    }
  }), []);
  /* eslint-enable schange-rules/no-use-imperative-handle-hook */

  const onSwimlaneEmpty = useFunction(() => onLoaded(true));
  const onTileFocus = useFunction((tileIndex: number, _: unknown, __: unknown, headerActionsVisible: boolean) => {
    onElementFocus(tileIndex, !headerActionsVisible);
  });
  const onHeaderFocus = useFunction((tileIndex: number) => {
    onElementFocus(tileIndex, true);
  });

  const onDataFetcherStateChanged = useFunction((state: SwimlaneDataFetcherState) => {
    if (state === SwimlaneDataFetcherState.HasData) {
      onLoaded(false);
    }
  });

  const {createTile, placeholderComponent} = useDefaultMediaSwimlaneTiles();

  const focusedTileFrameStyle = useMediaTileFocusedStyle();
  const renderFocusedTileFrame = useCallback(() => {
    return <View style={focusedTileFrameStyle} />;
  }, [focusedTileFrameStyle]);

  const dataEvents = useDefaultMediaSwimlaneMetaEvents(component.dataSource);
  const visibilityLimits = useDefaultVisibilityLimits(component);
  const dataFetcher = useMemo(() => {
    let limits = visibilityLimits;
    if (component.settings?.itemsLimit != null) {
      limits = limits.map(l => ({
        ...l,
        limit: component.settings?.itemsLimit
      }));
    }
    return createLimitedDataFetcher(
      mw.catalog.getContent(component.dataSource.filters, {pageSize: defaultPageSize}),
      limits
    );
  }, [component.dataSource.filters, component.settings?.itemsLimit, visibilityLimits]);

  const swimlaneInsets = useMemo(() => ({
    ...defaultSwimlaneInsets,
    ...insets
  }), [insets]);

  return (
    <AnimatedSwimlane<Media>
      /* eslint-disable @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      ref={swimlaneRef}
      /* eslint-enable @typescript-eslint/ban-ts-comment */
      key={index}
      row={index}
      style={layout}
      focused={focused}
      header={component.title}
      insets={swimlaneInsets}
      itemWidth={swimlaneItemWidth}
      itemHeight={swimlaneItemHeight}
      onHoverChange={onHover}
      onSwimlaneEmpty={onSwimlaneEmpty}
      fixedFocusPosition
      onTileFocus={onTileFocus}
      onHeaderTileFocus={onHeaderFocus}
      createTile={createTile}
      renderFocusedTileFrame={renderFocusedTileFrame}
      dataFetcher={dataFetcher}
      onDataFetcherStateChanged={onDataFetcherStateChanged}
      showHeaderActions
      placeholderComponent={placeholderComponent}
      dataChangeEvent={dataEvents.dataChangeEvent}
      dataRefreshEvent={dataEvents.dataRefreshEvent}
      dataEventsEmitter={dataEvents.dataEventsEmitter}
      renderNavigationArrows={isDesktopBrowser && !isSTBBrowser}
    />
  );
}));
SwimlaneBasedStackRow.displayName = 'SwimlaneBasedStackRow';

export default SwimlaneBasedStackRow;
