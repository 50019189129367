import {Log} from 'common/Log';

import {nxffConfig} from 'mw/api/NXFF';
import {SSOInterface} from 'mw/bo-proxy/SSOInterface';
import {mw} from 'mw/MW';

import {MegaSSOAdapter} from './MegaSSOAdapter';
import {NoSSOAdapter} from './NoSSOAdapter';
import {RedirectedSSO} from './RedirectedSSO';
import {SeaChangeSSOAdapter} from './SeaChangeSSOAdapter';

const TAG = 'SSOFactory';

export class SSOFactory {

  public static createSSOAdapter = (): SSOInterface => {
    const ssoType = nxffConfig.getConfig().Environment.SSOType;
    Log.debug(TAG, 'SSOType', ssoType);
    let sso: SSOInterface;
    switch (ssoType) {
      case 'Mega':
        sso = new MegaSSOAdapter();
        break;

      case 'SeaChange':
        sso = new SeaChangeSSOAdapter();
        break;

      default:
        sso = new NoSSOAdapter();
        break;
    }

    return mw.options.isChromeCast ? RedirectedSSO.reconfigure(sso) : sso;
  }
}
