import {makeArray} from 'common/utils';

import {EPGResponse} from 'mw/api/CatalogInterface';
import {Channel, Event, Location} from 'mw/api/Metadata';
import {ChannelUrls} from 'mw/bo-proxy/BOInterface';
import {ChannelObject} from 'mw/metadata/ChannelObject';
import {mw} from 'mw/MW';

import {EventMapper} from './EventMapper';
import {PictureMapper} from './PictureMapper';

interface TraxisPolicy {
  IsAvailableByPolicy?: boolean;
}

export class ChannelMapper {
  public static toChannel(json: any): Channel {
    const channel = new ChannelObject(json.id, json.Name, json.LongName, json.LogicalChannelNumber);
    if (json.Pictures) {
      channel.pictures = PictureMapper.toPictures(json.Pictures.Picture);
    }

    if (json.RecordingInThePastLimit) {
      channel.recordingInThePastLimit = json.RecordingInThePastLimit;
    }

    channel.isNetworkRecordingAllowed = !!json.IsNetworkRecordingAllowed;
    channel.ongoingEventRecording = json.OngoingEventRecording;
    channel.isAdult = !!json.IsAdult;
    channel.isAvailableByPolicy = !makeArray(json.Policies?.Policy).some((policy: TraxisPolicy) => policy?.IsAvailableByPolicy === false);

    return channel;
  }

  public static toChannels(jsonArray: any[]): Channel[] {
    return jsonArray.filter((json: any) => {
      return typeof (json.LogicalChannelNumber) !== 'undefined' && json.IsViewableOnCpe;
    }).map(ChannelMapper.toChannel);
  }

  public static toChannelUrls(jsonArray: any[]): ChannelUrls {
    const channelUrls: any = {};
    jsonArray.forEach((channelLocation: any) => {
      if (channelLocation.Locations && channelLocation.Locations.Location.length) {
        channelUrls[channelLocation.id] = ChannelMapper.traxisLocator2playerLocator(channelLocation.Locations.Location);
      }
    });
    return channelUrls;
  }

  public static toEvents(channelsJsonArray: any[]): EPGResponse {
    const events = new Map<string, Event[]>();
    channelsJsonArray.forEach((channelJson: any) => {
      const eventsJsonArray = channelJson.Events && channelJson.Events.Event;
      if (!eventsJsonArray) {
        return;
      }
      const eventsOnChannel = EventMapper.fromJSONArray(eventsJsonArray);
      events.set(channelJson.id, (events.get(channelJson.id) || []).concat(eventsOnChannel));
    });
    return events;
  }

  private static traxisLocator2playerLocator(locations: any[]): Location {
    const url = locations.find(location => location.Value.includes('://')).Value || '';
    if (!url) {
      return new Location();
    }
    const prefix = url.slice(0, url.lastIndexOf('://'));
    switch (prefix) {
      case 'http':
      case 'udp':
        return new Location(url);
      case 'dvbc':
      case 'dvbt':
        const fallback = !!locations.find(location => location.Value === 'FallbackAvailable=True');
        return mw.configuration.isDVBCapable ? new Location(url, fallback) : new Location();
      default:
        return new Location();
    }
  }
}
