// FIXME CL-5417 remove disable import/order
/* eslint-disable import/order */
import {Platform, StatusBar, StyleSheet, View, InteractionManager} from 'react-native';
import React, {useCallback, useEffect, useState, useRef, useMemo} from 'react';
import {createAppContainer, NavigationContainer} from 'react-navigation';
import {useScreens as enableScreens} from 'react-native-screens';
import {PortalProvider} from 'react-native-portal';
import i18next from 'i18next';
import {useTranslation} from 'react-i18next';
import SplashScreen from 'react-native-bootsplash';

import i18n from 'locales/i18n';
import LoadingScreen from 'screens/login/LoadingScreen';
import {LogInErrorContext} from 'screens/login/CredentialsScreen';
import EmptyBackgroundScreen from 'screens/login/EmptyBackgroundScreen';
import CustomLoginStepScreen from 'screens/login/CustomLoginStepScreen';
import createMainMenu, {getOperatorLogoUrl} from 'components/navigation/MainMenu';
import {TabBarLayoutProvider} from 'components/navigation/TabBarLayoutProvider';
import {mw, MWEvent} from 'mw/MW';
import {Log, LogLevel} from 'common/Log';
import {Error, ErrorType, AccountNotActivatedError} from 'mw/api/Error';
import {TopLevelPortal} from 'components/TopLevelPortalManager';
import {BackOfficeEvent} from 'mw/api/BackOffice';
import {Credentials, nxff} from 'mw/api/NXFF';
import {UserAgreementEvent} from 'mw/api/UserAgreement';
import 'components/Fonts';
import {ChangeEvent} from 'common/HelperTypes';
import ModalPortal from 'components/ModalPortal';
import FocusPrecedence from 'components/FocusPrecedence';
import {CustomerEvent, CustomerEventPayload} from 'mw/api/Customer';
import DeviceRegistrationErrorHandler from 'components/login/DeviceRegistrationErrorHandler';
import {Profile} from 'mw/api/Profile';
import {EASEvent} from 'mw/api/EAS';
import {useUnlockModal, UnlockModalRejection, UnlockModalRejectionReason} from 'components/unlockmodal/UnlockModal';
import {useEASAlertModal} from 'components/EASAlertModal';
import ProfileSelect from 'components/ProfileSelect';
import moment from 'moment';
import 'locales/moment'; // to use moment.locale we need to import the languages we are targeting.
import 'moment-duration-format'; // adds duration().format() function
import {useFunction, useEventListener, useDisposableCallback, useEffectOnce, useToggle} from 'hooks/Hooks';
import UidUsageAgreementPopup from 'components/UidUsageAgreementPopup';
import {paletteVersion, BaseColors} from 'common-styles/variables/base-colors';
import {UIReloadEmitter, uiReloadEmitter} from 'common/UIReloadEmitter';
import {CMSEvent, CustomMenuItemRoute} from 'mw/cms/CMS';
import {StylesUpdater} from 'common-styles/StylesUpdater';
import {isMobile, testAutomationFeatures, notificationDuration, translationsVersion, isAndroid, isIOS, isTVOS, AppRoutes} from 'common/constants';
import {Styling, Consent} from 'mw/api/Metadata';
import {isChromecastSupported} from 'mw/platform/chromecast/ChromecastInterface';
import {LogoContext} from 'components/navigation/LogoProvider';
import NitroxATVFocusPlaceholder from 'components/NitroxATVFocusPlaceholder';
import {createStyles} from 'common-styles';
import performanceConstants from 'components/performance/performanceConstants';
import EulaScreen from 'components/login/EulaScreen';
import {timeout} from 'common/Async';
import {Translations} from 'mw/bo-proxy/uxmanager/UXManager';
import {CustomSSOAction} from 'mw/bo-proxy/SSOInterface';
import ChromecastController from 'components/chromecast/ChromecastController';
import {ChromecastContextProvider} from 'components/ChromecastContext';
import AppErrorHandler from './AppErrorHandler';
import {routeNameForMenu} from 'components/navigation/NavigationHelperFunctions';
import {ParentalControlProvider} from 'components/parentalControl/ParentalControlProvider';
import VoucherRedeem from 'components/voucherRedeem/VoucherRedeem';
import {FullScreenControlProvider} from 'components/fullscreen/FullScreenControlProvider';

import AppBackground from 'components/AppBackground';
import {TAFocusReporter} from 'components/taRelated/TAFocusReporter';

TAFocusReporter.register();

// React-native-web does not implement stub for this function, therefore `?` operator is used
InteractionManager.setDeadline?.(performanceConstants.postInteractionQueue.deadline);

// TODO: CL-4533 YellowBox is currently broken on tvos and cannot be closed.
if (isTVOS) {
  console.disableYellowBox = true;
}

enableScreens();

const styles = createStyles({
  navigationContainerStyle: {
    flex: 1
  }
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: colors.defaultColors.screenBackground
  }
}));

const TAG = 'App';

//eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const v8Version = global._v8runtime?.().version;
if (v8Version) {
  Log.info(TAG, 'V8 version:', v8Version);
}

// TODO: CL-2868 [TA] Expose MW only for test builds
if (testAutomationFeatures.exposeMiddleware) {
  //eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  window.mw = mw;
}

testAutomationFeatures.enableLogsForTesting && Log.setLogLevel(LogLevel.TA);

type AppState = 'loading' | 'loginScreen' | 'loginCustomStep' | 'eulaConsent' | 'appError' | 'ready';

const changeLanguage = async (change: ChangeEvent<string | undefined>): Promise<boolean> => {
  const {to} = change;
  if (!to) {
    return false;
  }

  let languageChanged = false;
  if (moment.locale() !== to && moment.locales().includes(to)) {
    const momentLocale = moment.locale([to, 'en']); // use 'en' as fallback
    if (momentLocale !== to) {
      Log.error(TAG, `moment: failed to use locale: '${to}'. Fallback to '${momentLocale}'`);
    } else {
      languageChanged = true;
    }
  }

  if (i18next.language === to) {
    return languageChanged;
  }
  try {
    await i18next.changeLanguage(to);
  } catch (error) {
    Log.error(TAG, 'something went wrong when changing language: ', error);
  }
  return true;
};

const App: React.FunctionComponent = () => {
  const {t} = useTranslation();
  const [AppContainer, setAppContainer] = useState<NavigationContainer>();
  const [AuthContainer, setAuthContainer] = useState<NavigationContainer>();
  const [state, setState] = useState<AppState>('loading');
  const [defaultCredentials, setDefaultCredentials] = useState<Credentials>();
  const [error, setError] = useState<ErrorType | null>(null);
  const [appError, setAppError] = useState<ErrorType | null>(null);
  const currentBackOfficeCode = mw.bo.getBackOfficeCode();
  const [profileSelectVisible, setProfileSelectVisible] = useState(false);
  const [uidUsageAgreementVisible, setUidUsageAgreementVisible] = useState(false);
  const [logoUrl, setLogoUrl] = useState<string>('');
  const [eulaConsent, setEulaConsent] = useState<Consent | null>(null);
  const [loginCustomStep, setLoginCustomStep] = useState<CustomSSOAction | undefined>();
  const [credentialsScreenNotification, setCredentialsScreenNotification] = useState('');
  const {onEASAlertReceived, onEASAlertUpdated, renderEASAlertModal} = useEASAlertModal();
  const [inactiveAccountUsername, setInactiveAccountUsername] = useState<string>();

  const [isVoucherRedeemPopupVisible, {off: closeVoucherRedeemPopup, on: openVoucherRedeemPopup}] = useToggle(false);

  useEffect(() => {
    if (isAndroid || isIOS) {
      SplashScreen?.hide();
    }
    Log.info(TAG, `
----------------------------------------------------
------- App started, hiding splash screen... -------
----------------------------------------------------
`);
  }, []);

  useEffect(
    () => {
      try {
        if (currentBackOfficeCode) {
          const credentials = nxff.getConfig().Environment.DefaultCredentials;
          if (credentials) {
            // strange: this will crash ATV if credentials === undefined
            setDefaultCredentials(credentials);
          }
        }
      } catch (error) {
        Log.error(TAG, 'Default credentials error', error);
      }
    },
    [currentBackOfficeCode, defaultCredentials]
  );

  const handleError = useFunction(error => {
    Log.error(TAG, 'Got MW error ', error);
    if (error instanceof AccountNotActivatedError) {
      setInactiveAccountUsername(error.username);
      setAppError(ErrorType.AccountNotActivated);
      setState('appError');
    } else {
      setError(error instanceof Error ? error.type : ErrorType.UnknownError);
    }
  });

  const handleLoginRequired = useFunction(() => {
    Log.info(TAG, 'Login is required');
    setState('loginScreen');
  });

  const login = useCallback((credentials?: Credentials): void => {
    setError(null);
    const isLoggedIn = mw.bo.isLoggedIn;
    mw.bo.login(credentials)
      .then(() => {
        Log.info(TAG, 'Login was successful');
        setError(null);

        // if user were logged in there is need to switch to view eg. ready
        if (isLoggedIn) {
          setAppError(null);
          setState('ready');
        }
      })
      .catch((error: Error) => Log.error(TAG, 'login failed', error));
  }, []);

  const deviceRegisteredHandler = useCallback(() => {
    setAppError(null);
    setState('ready');
  }, []);

  const handleDeviceUnregistered = useCallback(() => {
    mw.customer.once(CustomerEvent.DeviceRegistered, deviceRegisteredHandler);
    setAppError(ErrorType.DeviceUnregistered);
    setState('appError');
  }, [deviceRegisteredHandler]);

  const handleRemoteNxfdError = useCallback((error: Error) => {
    setAppError(error.type);
    setState('appError');
  }, []);

  // despite having no deps at the moment, useFunction is used as its reference consistency is crucial for conditionally deferring its call
  // adding dependencies could break this unexpectedly otherwise
  const recreateAppContainer = useFunction(async () => {
    const menu = await mw.cms.getMainMenu();
    const screen = menu.items.find(item => item.screen === AppRoutes.Home);
    const screenRoute = screen ? routeNameForMenu(screen) : undefined;
    setLogoUrl(getOperatorLogoUrl(menu, screenRoute));
    setAppContainer(() => createAppContainer(createMainMenu(menu, {[CustomMenuItemRoute.VoucherRedemption]: openVoucherRedeemPopup})));
  });

  const recreateAuthContainer = useFunction(() => {
    const menu = mw.cms.getUnauthenticatedMainMenu();
    setAuthContainer(prevState => !prevState ? createAppContainer(createMainMenu(menu)) : prevState);
  });

  useEventListener(UIReloadEmitter.Reload, recreateAppContainer, uiReloadEmitter);

  const handleInitLanguageChange = useFunction(async (change: ChangeEvent<string | undefined>) => {
    await changeLanguage(change);
  });

  const handleNewTranslations = useCallback(async (translations: Translations) => {
    if (!translations) {
      return;
    }
    try {
      Object.entries(translations).forEach(
        ([key, value]) => i18next.addResourceBundle(key, 'translation', value, true, true)
      );
      mw.cms.translationsApplied();
    } catch (error) {
      Log.error(TAG, 'something went wrong when adding translations from UXM: ', error);
    }
  }, []);

  const onSettingsLanguageChange = useCallback(async (change: ChangeEvent<string | undefined>) => {
    try {
      if (await changeLanguage(change)) {
        await recreateAppContainer();
      }
    } catch (error) {
      Log.error(TAG, 'error when re-creating app container: ', error);
    }
  }, [recreateAppContainer]);

  const freshStyling = useRef<Styling | null>(null);

  const handleProfileChange = useCallback((eventPayload?: CustomerEventPayload) => {
    if (eventPayload) {
      const {to, from} = eventPayload as ChangeEvent<Profile | null>;
      if (to && from && to === from) {
        return;
      }
    }

    if (mw.customer.currentProfile) {
      StylesUpdater.update(freshStyling.current);
      recreateAppContainer();
    }
  }, [recreateAppContainer]);

  const handleNewStylingApplied = useCallback(() => {
    recreateAppContainer();
  }, [recreateAppContainer]);

  const onInitialized = useFunction(async () => {
    Log.info(TAG, 'MW was successfully initialized');
    try {
      mw.bo.off(BackOfficeEvent.backOfficeUnavailable, handleError);

      mw.cms.off(CMSEvent.newStyling, StylesUpdater.update);
      mw.cms.on(CMSEvent.newStylingApplied, handleNewStylingApplied);
      mw.customer.on(CustomerEvent.ProfileChange, handleProfileChange);
      mw.customer.on(CustomerEvent.DeviceUnregistered, handleDeviceUnregistered);

      mw.cms.off(CMSEvent.languageChanged, handleInitLanguageChange);
      mw.cms.on(CMSEvent.languageChanged, onSettingsLanguageChange);

      mw.eas.on(EASEvent.AlertReceived, onEASAlertReceived);
      mw.eas.on(EASEvent.AlertUpdated, onEASAlertUpdated);

      setError(null);
      setAppError(null);

      await recreateAppContainer();

      setState('ready');
    } catch (error) {
      Log.error(TAG, 'loggedIn', error);
    }
  });

  const onInitializationFailed = useFunction(() => {
    Log.error(TAG, 'MW initialization failed');
    setState('loginScreen');
  });

  const handleProfileChoiceRequired = useFunction(() => {
    setProfileSelectVisible(true);
  });

  const {renderModal: renderUnlockModal, unlockModalVisible, authorizeProfile} = useUnlockModal(handleProfileChoiceRequired);

  const handleProfilePinRequired = useFunction((profile?: CustomerEventPayload) => {
    if (profile instanceof Profile) {
      authorizeProfile(profile)
        .then(pin => mw.customer.setProfile(profile, pin))
        .catch(error => {
          if (!(error instanceof UnlockModalRejection && error.reason === UnlockModalRejectionReason.Cancel)) {
            Log.error(TAG, 'Error authorizing profile: ', error);
            setState('loginScreen');
          }
        });
    }
  });

  const onSelectProfile = useCallback((profileId?: string) => {
    const profile = mw.customer.profiles.find(p => p.id === profileId);
    setProfileSelectVisible(false);
    if (!profile) {
      return;
    }
    if (profile.isPinRequired) {
      handleProfilePinRequired(profile);
    } else {
      mw.customer.setProfile(profile, null);
    }
  }, [handleProfilePinRequired]);

  const onCloseProfileSelect = useCallback(() => {
    setProfileSelectVisible(false);
    mw.bo.logout();
  }, []);

  const handleDeviceRegistrationError = useFunction((error: ErrorType) => {
    setState('loading');
    setAppError(error);
  });

  const handleEulaConsent = useFunction((payload?: CustomerEventPayload) => {
    const consent = payload as Consent;
    if (consent) {
      setEulaConsent(consent);
      setState('eulaConsent');
    } else {
      Log.error(TAG, 'EULA consent is required, but Consent object is not defined');
    }
  });

  const onConsentAccepted = useCallback(() => {
    setState('loading');
  }, []);

  const hideCredentialsScreenNotification = useDisposableCallback(() => setCredentialsScreenNotification(''));
  const onConsentRejected = useCallback(() => {
    setState('loading');
    setCredentialsScreenNotification(t('cms.eula.rejectedMessage'));
    timeout(hideCredentialsScreenNotification, notificationDuration);
  }, [t, hideCredentialsScreenNotification]);

  const handleUidUsageAgreementConfirmationRequired = useFunction(() => {
    setUidUsageAgreementVisible(true);
  });

  const handleCustomerError = useFunction((error: ErrorType) => {
    Log.error(TAG, 'Got customer-related error', error);
    setState('loginScreen');
    setError(error);
  });

  const handleCustomerErrorAndLogout = useFunction((error: ErrorType) => {
    handleCustomerError(error);
    Log.warn(TAG, 'Logging out due to customer-related error', error);
    mw.bo.logout();
  });

  const confirmUidUsageAgreement = useCallback(() => {
    setUidUsageAgreementVisible(false);
    mw.userAgreement.confirmUidUsageAgreement();
  }, []);

  const handleNewStyling = useFunction((styling: Styling | null) => {
    freshStyling.current = styling;
    //by default applying new styling is handled by the user. Styling absence is an exception and it is handled automatically
    if (!styling) {
      StylesUpdater.reset();
    }
  });

  const onNextStep = useCallback((userSelection) => {
    loginCustomStep?.onUserResponse(userSelection);
    setState('loading');
  }, [loginCustomStep]);

  const onCancelStep = useCallback(() => {
    loginCustomStep?.onUserCancel();
    setState('loading');
  }, [loginCustomStep]);

  const handleCustomSSOLoginAction = useFunction((payload: CustomSSOAction | undefined) => {
    const nextStep = payload;
    if (nextStep) {
      setState('loginCustomStep');
      setLoginCustomStep(nextStep);
    } else {
      Log.error(TAG, 'No payload provided');
    }
  });

  const initialize = useCallback(() => {
    const initMW = (lastError?: Error) => {
      Log.info(TAG, 'Starting MW initialization, last error', error);

      if (mw.isInitialized) {
        if (mw.bo.isLoggedIn) {
          return recreateAppContainer()
            .then(() => {
              Log.info(TAG, 'MW already initialized and logged set state ready');
              setState('ready');
            });
        }

        Log.info(TAG, 'MW already initialized but not logged set state loginScreen');
        setState('loginScreen');
        return;
      }

      mw.cms.on(CMSEvent.newStyling, StylesUpdater.update); //handle new styling while init - apply automatically
      mw.cms.on(CMSEvent.newStyling, handleNewStyling); //handle new styling after init
      mw.cms.on(CMSEvent.languageLoading, () => setState('loading'));
      mw.bo.on(BackOfficeEvent.backOfficeUnavailable, handleError);
      mw.bo.on(BackOfficeEvent.loginInProgress, () => setState('loading'));
      mw.bo.on(BackOfficeEvent.loginUserActionRequired, handleCustomSSOLoginAction);
      mw.bo.on(BackOfficeEvent.loginError, handleError);
      mw.bo.on(BackOfficeEvent.loginRequired, handleLoginRequired);
      mw.bo.on(BackOfficeEvent.loggedOut, async (error?: Error) => {
        Log.debug(TAG, 'Logout reason', error);
        const cachedStyling = await mw.cms.getStyling();
        if (!cachedStyling) {
          StylesUpdater.reset();
        }
        await mw.customer.clearLastUsedProfile();
        await mw.userAgreement.invalidateUidUsageAgreement();
        setAppError(null);
        mw.uninitialize();
        initMW(error);
      });
      mw.bo.on(BackOfficeEvent.remoteNxfdError, handleRemoteNxfdError);
      mw.on(MWEvent.initialized, onInitialized);
      mw.on(MWEvent.initializationFailed, onInitializationFailed);
      mw.cms.on(CMSEvent.languageChanged, handleInitLanguageChange);
      mw.cms.once(CMSEvent.newTranslations, handleNewTranslations);
      mw.once(MWEvent.boInvalidCustomerID, () => handleCustomerError(ErrorType.BOInvalidCustomerID));

      mw.customer.on(CustomerEvent.ProfileChoiceRequired, handleProfileChoiceRequired);
      mw.customer.on(CustomerEvent.ProfilePinRequired, handleProfilePinRequired);
      mw.customer.on(CustomerEvent.ProfileChange, (payload?: CustomerEventPayload) => {
        if (payload && (payload as ChangeEvent<Profile | null>).to) {
          mw.customer.off(CustomerEvent.ProfileChoiceRequired, handleProfileChoiceRequired);
          mw.customer.off(CustomerEvent.ProfilePinRequired, handleProfilePinRequired);
        }
      });

      mw.customer.on(CustomerEvent.EULAConsentNetworkRequestFailed, () => handleCustomerErrorAndLogout(ErrorType.NetworkRequestFailed));
      mw.customer.on(CustomerEvent.EULAConsentNotAccepted, handleEulaConsent);

      mw.customer.once(CustomerEvent.TooManyDevices, () => handleDeviceRegistrationError(ErrorType.TooManyDevices));
      mw.customer.once(CustomerEvent.TooManyDeviceSwaps, () => handleDeviceRegistrationError(ErrorType.TooManyDeviceSwaps));
      mw.customer.once(CustomerEvent.FailedToRegisterNewDevice, () => handleDeviceRegistrationError(ErrorType.FailedToRegisterNewDevice));

      mw.userAgreement.on(UserAgreementEvent.uidUsageAgreementRequired, handleUidUsageAgreementConfirmationRequired);

      mw.customer.setLoadLastUsedProfileOnInit(isMobile);
      mw.cms.setSupportedStylingVersion(paletteVersion);
      mw.cms.setSupportedTranslationsVersion(translationsVersion);

      setError(lastError?.type ?? null); // keep track of the logout error
      setAppError(null);

      mw.initialize()
        .then(() => {
          // Do not clear the error codes (error and appError) here because these errors can still be asynchronously risen.
          // The real moment when the initialization and any post initialization steps are finished is notified by the MWEvent.initialized event.
          // Therefore both of these errors are cleared in onInitialized.
          setDefaultCredentials(nxff.getConfig().Environment.DefaultCredentials);
          recreateAuthContainer();
        })
        .catch(error => {
          Log.error(TAG, 'initMW', error);
          handleError(error);
        });
    };

    mw.cms.setSupportedStylingVersion(paletteVersion);
    //get and apply cached styling
    mw.cms.getStyling()
      .then(styling => {
        handleNewStyling(styling);
        if (!styling) {
          Log.warn(TAG, 'no cached styling');
          return;
        }
        StylesUpdater.update(styling);
      })
      .catch((error: Error) => {
        Log.error(TAG, 'Error occurred while retrieving cached styling: ', error);
      })
      .finally(() => {
        !Platform.isTV && StatusBar.setBarStyle('light-content');
        i18next.use(i18n);
        initMW();
      });
  }, [error, handleNewStyling, handleCustomSSOLoginAction, handleError, handleLoginRequired, handleRemoteNxfdError, onInitialized, onInitializationFailed, handleInitLanguageChange, handleNewTranslations, handleProfileChoiceRequired, handleProfilePinRequired, handleEulaConsent, handleUidUsageAgreementConfirmationRequired, recreateAppContainer, handleCustomerError, handleCustomerErrorAndLogout, handleDeviceRegistrationError, recreateAuthContainer]);

  useEffectOnce(initialize, [initialize]);

  const logInContext = useMemo(() => ({
    onLoginPressed: login,
    error,
    defaultCredentials,
    notification: credentialsScreenNotification
  }), [defaultCredentials, error, login, credentialsScreenNotification]);

  const onCloseAccountAlreadyRegisteredPopup = useCallback(() => {
    setAppError(null);
    setState('loginScreen');
    setInactiveAccountUsername(undefined);
  }, []);

  const app = (() => {
    switch (state) {
      case 'loading':
        return (
          <PortalProvider>
            {profileSelectVisible || uidUsageAgreementVisible || unlockModalVisible
              ? <EmptyBackgroundScreen />
              : <LoadingScreen />
            }
            {renderUnlockModal()}
            {renderEASAlertModal()}
            <ProfileSelect
              visible={profileSelectVisible}
              onSelect={onSelectProfile}
              onClose={onCloseProfileSelect}
              refreshProfilesOnAppear={false}
              focusNearestParentOnClose={false}
            />
            <UidUsageAgreementPopup
              visible={uidUsageAgreementVisible}
              onConfirm={confirmUidUsageAgreement}
            />
            <DeviceRegistrationErrorHandler error={appError} />
            <ModalPortal />
          </PortalProvider>
        );
      case 'loginScreen':
        if (!AuthContainer) {
          return <LoadingScreen />;
        }
        return (
          <PortalProvider>
            <FocusPrecedence>
              <LogInErrorContext.Provider value={logInContext}>
                <NitroxATVFocusPlaceholder />
                <AuthContainer />
              </LogInErrorContext.Provider>
              {renderEASAlertModal()}
              <TopLevelPortal />
              <ModalPortal />
            </FocusPrecedence>
          </PortalProvider>
        );
      case 'loginCustomStep':
        if (!loginCustomStep) {
          return <LoadingScreen />;
        }
        return (
          <PortalProvider>
            <FocusPrecedence>
              <LogInErrorContext.Provider value={logInContext}>
                <NitroxATVFocusPlaceholder />
                <CustomLoginStepScreen
                  type={loginCustomStep?.type}
                  choices={loginCustomStep?.choices}
                  message={loginCustomStep?.message}
                  onNextStep={onNextStep}
                  onCancelStep={onCancelStep}
                />
              </LogInErrorContext.Provider>
              {renderEASAlertModal()}
              <TopLevelPortal />
              <ModalPortal />
            </FocusPrecedence>
          </PortalProvider>
        );
      case 'eulaConsent':
        if (!eulaConsent) {
          return <LoadingScreen />;
        }
        return (
          <PortalProvider>
            <FocusPrecedence>
              <View style={StyleSheet.absoluteFillObject}>
                <EulaScreen
                  eulaConsent={eulaConsent}
                  onConsentAccepted={onConsentAccepted}
                  onConsentRejected={onConsentRejected}
                />
                {renderEASAlertModal()}
                <ModalPortal />
              </View>
            </FocusPrecedence>
          </PortalProvider>
        );
      case 'appError':
        return (
          <PortalProvider>
            <FocusPrecedence>
              <View style={StyleSheet.absoluteFillObject}>
                <AppErrorHandler
                  error={appError}
                  reinitialize={initialize}
                  onCloseAccountAlreadyRegisteredPopup={onCloseAccountAlreadyRegisteredPopup}
                  emailOrUsername={inactiveAccountUsername ?? ''}
                />
                {renderEASAlertModal()}
                <ModalPortal />
              </View>
            </FocusPrecedence>
          </PortalProvider>
        );
      case 'ready':
        if (!AppContainer) {
          return <LoadingScreen />;
        }
        return (
          <PortalProvider>
            <TabBarLayoutProvider>
              <FocusPrecedence>
                <View style={styles.navigationContainerStyle}>
                  <LogoContext.Provider value={logoUrl}>
                    <ChromecastContextProvider>
                      <ParentalControlProvider>
                        <FullScreenControlProvider>
                          <NitroxATVFocusPlaceholder />
                          <AppContainer />
                          {renderEASAlertModal()}
                          {isChromecastSupported() && <ChromecastController />}
                          {isVoucherRedeemPopupVisible && <VoucherRedeem onClose={closeVoucherRedeemPopup} />}
                          <TopLevelPortal />
                          <ModalPortal />
                        </FullScreenControlProvider>
                      </ParentalControlProvider>
                    </ChromecastContextProvider>
                  </LogoContext.Provider>
                </View>
              </FocusPrecedence>
            </TabBarLayoutProvider>
          </PortalProvider>
        );
      default:
        return <LoadingScreen />;
    }
  })();

  const dynamicStyles = stylesUpdater.getStyles();

  return isMobile ?
    (
      <View style={dynamicStyles.container}>
        {app}
      </View>
    ) : (
      <>
        <AppBackground />
        {app}
      </>
    );
};
export default App;
