/* eslint-disable max-lines */
import {createStyles} from 'common-styles';
import React, {useRef, useState, useMemo, useCallback, useImperativeHandle} from 'react';
import {View, ActivityIndicator, StyleSheet} from 'react-native';
// eslint-disable-next-line no-restricted-imports
import {PanGestureHandler} from 'react-native-gesture-handler';
import Reanimated, {useCode, call} from 'react-native-reanimated';

import {dimensions, epgItemHeight, isAndroid} from 'common/constants';
import {DateUtils} from 'common/DateUtils';
import {flatten, unique} from 'common/HelperFunctions';
import {Size, Point} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {CatalogEvent} from 'mw/api/CatalogInterface';
import {Channel, Event} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import {ScrollDirection} from 'components/epg/EpgNitroxScrollView';
import {NativeKeyEvent, SupportedKeys} from 'components/KeyEventManager';
import NitroxText from 'components/NitroxText';
import {useFunction, useLazyEffect, useSynchronizedState, useChangeEffect, useEventListener, useNearestLiveEvent, useLazyRef} from 'hooks/Hooks';
import {getEpgBoundaries} from 'screens/epg/EpgHelperFunctions';

import ChannelListView, {channelsContainerWidth} from './ChannelListView.piccolo';
import {Chunk} from './Chunk';
import {initialXOffset, pointsPerMillisecond, gridWidth, pointsPerHour, focusedRowOffset} from './EpgConstants';
import {offsetX, offsetY, initialScrollOffset as getInitialScrollOffset, channelIndex, scrollOffsetY, findEvent, isInRange} from './EpgHelpers';
import {useNowDate, useCurrentEvent, useGridDimensions, useFetchChunks} from './EpgHooks';
import {useOmniScroll} from './OmniScroll';
import AnimatedScrollView, {AnimatedScrollViewTapEvent} from './ReanimatedScrollView';
import EpgTile, {TileIcons as EpgTileIcons} from './Tile';
import TimeBarView from './TimeBarView.piccolo';

const debugMode = false;

const TAG = 'Epg';

const timebarHeight = dimensions.epg.timeBarHeight;

const staticStyles = createStyles({
  container: {
    flex: 1
  },
  debugContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: timebarHeight
  },
  debugText: {
    // eslint-disable-next-line schange-rules/no-literal-color
    color: 'white'
  },
  timebarContainer: {
    position: 'absolute',
    top: 0,
    left: channelsContainerWidth,
    right: 0,
    height: timebarHeight
  },
  channelsListContainer: {
    position: 'absolute',
    top: timebarHeight,
    left: 0,
    bottom: 0,
    width: channelsContainerWidth
  },
  gridContainer: {
    position: 'absolute',
    top: timebarHeight,
    left: channelsContainerWidth,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center'
  }
});

const offscreenRenderThreshold = {
  x: pointsPerHour,
  y: epgItemHeight
};

function renderEpgTile(event: Event, forcedStartDate: Date | undefined, channels: Channel[], originDate: Date, focused = false, selected = false) {
  const left = offsetX(forcedStartDate ?? event.start, originDate);
  const width = offsetX(event.end, originDate) - left;
  return (
    <View
      key={event.id}
      style={{
        position: 'absolute',
        top: offsetY(event.channelId, channels),
        left,
        width,
        height: epgItemHeight
      }}
    >
      <EpgTile
        width={width}
        event={event}
        focused={focused}
        selected={selected}
      />
    </View>
  );
}

function renderEpgTileIcons(event: Event, channels: Channel[], originDate: Date) {
  const start = offsetX(event.start, originDate);
  const end = offsetX(event.end, originDate);
  return (
    <EpgTileIcons
      key={event.id}
      event={event}
      tilePosition={{
        x: start,
        y: offsetY(event.channelId, channels)
      }}
      tileSize={{
        width: end - start,
        height: epgItemHeight
      }}
    />
  );
}

function usePositionListener(
  positionX: Reanimated.Value<number>,
  positionY: Reanimated.Value<number>,
  onChange: (position: Point, lastReportedPosition: Point | null) => void,
  {x: minChangeX, y: minChangeY} = offscreenRenderThreshold
) {
  const lastReportedPosition = useRef<Point | null>(null);

  const reportChange = useFunction((point: Point) => {
    onChange(point, lastReportedPosition.current);
    lastReportedPosition.current = point;
  });

  useCode(() => call([positionX, positionY], ([x, y]) => {
    if (!lastReportedPosition.current) {
      reportChange({x, y});
      return;
    }

    const dx = Math.abs(x - lastReportedPosition.current.x);
    const dy = Math.abs(y - lastReportedPosition.current.y);

    if (dx >= minChangeX || dy >= minChangeY) {
      reportChange({x, y});
      return;
    }
  }), [positionX, positionY]);
}

// Reminder: `position` is oriented in opposite way than `offset`
// Position (current/previous) is top-left anchor of content container that we move around to scroll visible content.
function getNavigationDirection(current: Point, previous: Point): NativeKeyEvent['key'] {
  const dx = current.x - previous.x;
  const dy = current.y - previous.y;

  // Horizontal direction
  if (Math.abs(dx) > Math.abs(dy)) {
    return dx < 0 ? SupportedKeys.Right : SupportedKeys.Left;
  } else {
    return dy < 0 ? SupportedKeys.Down : SupportedKeys.Up;
  }
}

/**
 * In MainActivity.onConfigurationChanged, we override dpi during orientation change, because Android natively restores default value.
 * This causes races between UI redraw and our dpi modification.
 * TODO: CL-3122
 */
function useAndroidSanityProps(windowSize: Size | null) {
  const key = windowSize && (windowSize.width > windowSize.height) ? 'landscape' : 'portrait';

  return isAndroid
    ? {
      grid: {key: `grid ${key}`},
      timeBar: {key: `timeBar ${key}`},
      channelList: {key: `channelList ${key}`}
    }
    // iOS's layout is sane and does not require such hacks
    : undefined;
}

const Content: React.FC<{visible: boolean}> = ({visible, children}) => {
  if (!visible) {
    return null;
  }

  return <>{children}</>;
};

type EpgProps = {
  channels: Channel[];
  currentChannel?: Channel;
  // TODO: change this prop name
  onEpgGridVisibleTime?: (date: Date) => void;
  onEventPress?: (event: Event) => void;
  onChannelPress?: (channel: string) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EpgPiccolo = React.memo(React.forwardRef<any, EpgProps>(({
  channels,
  currentChannel,
  onEpgGridVisibleTime,
  onEventPress
}, ref) => {

  const {windowSize, scrollingPageSize, onGridLayout} = useGridDimensions();

  // Don't display content until first onLayout is fired.
  // We need window size to calculate scroll boundaries correctly.
  const layoutReady = !!windowSize;
  const gridSize: Size = useMemo(() => ({
    width: gridWidth(),
    height: channels.length * epgItemHeight
  }), [channels.length]);

  const [{state: focusedDate, getStateSync: getFocusedDateSync}, setFocusedDate] = useSynchronizedState(new Date());
  /**
   * Actual date for current grid x offset. It's focusedDate rounded down to full half hours.
   */
  const gridOffsetDate = useMemo(() => DateUtils.startOfHalfHour(focusedDate), [focusedDate]);

  const [
    {state: focusedChannel, getStateSync: getFocusedChannelSync},
    setFocusedChannel
  ] = useSynchronizedState<Channel | undefined>(currentChannel ?? channels[0]);

  const originDate = useRef(DateUtils.startOfHalfHour(new Date()));

  const nowDate = useNowDate();
  const selectedEvent = useCurrentEvent(currentChannel, nowDate);

  const initialScrollOffset = useLazyRef(() => getInitialScrollOffset(channels, currentChannel)).current;
  const positionOffset = useLazyRef(() => ({
    x: new Reanimated.Value(-initialScrollOffset.x),
    y: new Reanimated.Value(-initialScrollOffset.y)
  })).current;

  /**
   * Container position.
   * This is negative in relation to natural offset direction. (offset == -position)
   * Consider this value as x,y position of top-left ScrollView corner. We move it up - content goes visually down.
   *                 ^
   *                 |
   *                 |
   *                 |
   *                 |
   *                 |
   *                 |
   *   <-------------+
   */
  const position = useLazyRef(() => ({
    x: new Reanimated.Value<number>(-initialScrollOffset.x),
    y: new Reanimated.Value<number>(-initialScrollOffset.y)
  })).current;

  const positionBoundaries = useLazyRef(() => ({
    minY: new Reanimated.Value<number>(-gridSize.height),
    maxY: new Reanimated.Value<number>(0),
    minX: new Reanimated.Value<number>(-gridSize.width),
    maxX: new Reanimated.Value<number>(0)
  })).current;

  const {onGestureEvent, stopMomentumScroll} = useOmniScroll({
    positionOffsetX: positionOffset.x,
    positionOffsetY: positionOffset.y,
    positionX: position.x,
    positionY: position.y,
    minY: positionBoundaries.minY,
    maxY: positionBoundaries.maxY,
    minX: positionBoundaries.minX,
    maxX: positionBoundaries.maxX
  });

  const setPosition = useFunction(({x, y}: Partial<Point>) => {
    stopMomentumScroll();
    if (x != null) {
      position.x.setValue(x);
      positionOffset.x.setValue(x);
    }

    if (y != null) {
      position.y.setValue(y);
      positionOffset.y.setValue(y);
    }
  });

  useLazyEffect(() => {
    const minY = Math.min(
      /**
       * Prevent scrolling past last event.
       * Using just gridSize.height would allow to scroll one window past last event.
       */
      -1 * (gridSize.height - windowSize.height),
      0
    );
    positionBoundaries.minY.setValue(minY);

    // Reset position to logical 'focus' position, to ensure boundaries fit.
    // For some reason Reanimated.Value.setValue(Reanimated.Value) doesnt take any effect neither here nor in useCode block...
    // TODO: CL-3122 Investigate.
    const focusPosition = -scrollOffsetY(focusedChannel?.id, channels, windowSize);
    setPosition({y: focusPosition});
  }, [windowSize], [gridSize.height, positionBoundaries.minY, focusedChannel, channels, setPosition]);

  const [chunks, setChunks] = useState<{[id: string]: Chunk}>({});
  const navigationDirection = useRef<NativeKeyEvent['key']>(SupportedKeys.Right);

  const offscreenThreshold = useMemo(() => ({x: windowSize?.width, y: windowSize?.height}), [windowSize]);
  const {fetchChunks, fetching} = useFetchChunks({channels, focusedChannel, gridOffsetDate, offscreenRenderThreshold: offscreenThreshold});

  useLazyEffect(() => {
    onEpgGridVisibleTime?.(gridOffsetDate);
    if (windowSize.width > 0) {
      fetchChunks({navigationDirection: navigationDirection.current, originDate: originDate.current, windowSize, onChunkLoad: setChunks});
    } else {
      Log.warn(TAG, 'Omitting chunk fetch, container layouting is still in progress.');
    }
  }, [gridOffsetDate, focusedChannel, windowSize], [channels, onEpgGridVisibleTime, navigationDirection.current, originDate.current, windowSize, fetchChunks]);

  /**
   * Clear out epg on changing channels' array.
   */
  useChangeEffect(() => {
    setChunks({});
    setFocusedDate(new Date());
  }, [channels]);

  const scrollToDate = useFunction((date: Date) => {
    const [epgBeginTime, epgEndTime] = getEpgBoundaries();

    const targetDate = DateUtils.startOfHalfHour(date);
    const inRange = isInRange(targetDate.getTime(), [epgBeginTime.getTime(), epgEndTime.getTime()]);

    if (inRange) {
      const offset = offsetX(targetDate, originDate.current);
      setPosition({x: -offset});
      setFocusedDate(date);
    }
  });

  const scrollToChannel = useFunction((channel: Channel) => {
    if (!channels.length || !windowSize) {
      return;
    }
    Log.debug(TAG, `Scrolling to channel ${channel.toString()}`);
    const offset = scrollOffsetY(channel.id, channels, windowSize);
    setPosition({y: -offset});
    setFocusedChannel(channel);
  });

  useChangeEffect(() => {
    currentChannel && scrollToChannel(currentChannel);
  }, [currentChannel, channels]);

  const scrollToNow = useFunction(() => {
    scrollToDate(new Date());
  });

  const scrollByPage = useFunction((axis: ScrollDirection, offset: number) => {
    if (axis === ScrollDirection.Horizontal) {
      scrollToDate(new Date(gridOffsetDate.getTime() + offset * scrollingPageSize.current.hours * DateUtils.msInHour));
    } else {
      const row = channelIndex(focusedChannel?.id, channels) + offset * scrollingPageSize.current.rows;
      const channel = channels[Math.max(0, Math.min(row, channels.length - 1))];
      channel && scrollToChannel(channel);
    }
  });

  const scrollToEvent = useFunction((event: Event) => {
    const channel = mw.catalog.getChannelById(event.channelId);
    if (channel) {
      scrollToDate(event.start);
      scrollToChannel(channel);
    }
  });

  // eslint-disable-next-line schange-rules/no-use-imperative-handle-hook
  useImperativeHandle(ref, () => ({
    scrollToDate,
    scrollToNow,
    scrollByPage,
    scrollToEvent
  }), [scrollToDate, scrollToNow, scrollByPage, scrollToEvent]);

  const rowForOffset = useCallback((offset: number) => {
    return Math.floor(offset / epgItemHeight);
  }, []);

  /**
   * Helper functions needed by timebar for calculations.
   */
  const offsetForDate = useCallback((date: Date) => {
    return offsetX(date, originDate.current);
  }, []);
  const dateForOffset = useCallback((offset: number) => {
    return new Date(originDate.current.getTime() + (offset - initialXOffset()) / pointsPerMillisecond);
  }, []);

  /**
   * Chunks' content, all events that are available in cache.
   */
  const renderedEvents = useMemo(() => {
    const events = flatten(Object.values(chunks).map(({events}) => events));
    // We need to eliminate duplicates which happen because events can span multiple chunks.
    return unique(events, 'id');
  }, [chunks]);

  /**
   * Rendered live event that is going to finish first, required to rerender the icons that change over time.
   */
  const nearestLiveEvent = useNearestLiveEvent(useCallback(() => renderedEvents, [renderedEvents]));

  /**
   * Grid content, tiles for all events from cache.
   */
  const epgTiles = useMemo(() => {
    return renderedEvents.map(event => renderEpgTile(event, undefined, channels, originDate.current));
  }, [renderedEvents, channels]);

  /**
   * Highlighted tile for a currently playing event.
   */
  const selectedTile = useMemo(() => {
    if (!selectedEvent) {
      return null;
    }
    return renderEpgTile(selectedEvent, undefined, channels, originDate.current, false, true);
  }, [selectedEvent, channels]);

  /**
   * Render event icons for tiles separately,
   * so that refreshing recording status
   * doesn't trigger whole grid rerender.
   */
  const [tileIcons, setTileIcons] = useState<React.ReactNode>(null);
  const updateTileIcons = useFunction(() => {
    setTileIcons(
      renderedEvents.map((event) => renderEpgTileIcons(event, channels, originDate.current))
    );
  });

  useLazyEffect(() => {
    updateTileIcons();
  }, [renderedEvents, nearestLiveEvent], [updateTileIcons]);

  useEventListener(CatalogEvent.EventsIsRecordedUpdated, updateTileIcons, mw.catalog);

  const onTap = useFunction(({offset: contentOffset}: AnimatedScrollViewTapEvent) => {
    const date = dateForOffset(contentOffset.x);
    const row = rowForOffset(contentOffset.y);
    const event = findEvent(Object.values(chunks), date, row, channels);

    if (event) {
      onEventPress?.(event);
    } else {
      Log.warn(
        TAG,
        `Event for row: ${row}, channel: ${channels[row]},\
        date: ${date.toLocaleDateString()} ${date.toLocaleTimeString()}, offset: x=${contentOffset.x} y=${contentOffset.y} not found`
      );
    }
  });

  const onPositionChange = useFunction(({x, y}: Point, lastPosition: Point | null) => {
    if (lastPosition) {
      navigationDirection.current = getNavigationDirection({x, y}, lastPosition);
    }

    const scrollOffset = {
      x: -x,
      y: -y
    };
    /**
     * Logical 'focus' position.
     * This allows to fully share data logic between big and small screen implementation.
     * 'Focused' channel and date are not indicated visually, but rather are used as chunk selection controller.
     */
    const focusPosition = {
      x: scrollOffset.x,
      y: Math.min(scrollOffset.y + focusedRowOffset, gridSize.height)
    };
    const date = DateUtils.startOfHalfHour(
      dateForOffset(focusPosition.x)
    );
    const row = rowForOffset(focusPosition.y);

    if (date.getTime() !== focusedDate.getTime()) {
      setFocusedDate(date);
    }
    const channel = channels[row];
    if (channel !== focusedChannel) {
      setFocusedChannel(channel);
    }
  });
  usePositionListener(position.x, position.y, onPositionChange);

  useCode(() => {
    if (!debugMode) {
      return undefined;
    }
    return call([position.x, position.y], ([x, y]) => {
      Log.debug(TAG, `Position=${x}, ${y}`);
    });
  }, [position.x, position.y]);

  const androidSanity = useAndroidSanityProps(windowSize);

  return (
    <PanGestureHandler
      onHandlerStateChange={onGestureEvent}
      onGestureEvent={onGestureEvent}
      shouldCancelWhenOutside={false}
    >
      <Reanimated.View style={staticStyles.container}>
        <Content visible={debugMode}>
          <View style={staticStyles.debugContainer}>
            <NitroxText style={staticStyles.debugText}>
              {/* {`Container size: \n w${windowSize.current.width} h${windowSize.current.height}`} */}
              {`Channel: ${focusedChannel?.name} [${focusedChannel?.lcn}]\n`}
              {`Date: ${focusedDate.toLocaleTimeString()}`}
              {/* {`Navigation direction: ${navigationDirection.current}`} */}
            </NitroxText>
          </View>
        </Content>
        <View style={staticStyles.timebarContainer}>
          <Content visible={layoutReady}>
            <TimeBarView
              scrollOffset={position.x}
              gridWidth={gridSize.width}
              timeBarWidth={windowSize.width}
              originDate={originDate.current}
              offsetForDate={offsetForDate}
              dateForOffset={dateForOffset}
              nowDate={nowDate}
              {...androidSanity?.timeBar}
            />
          </Content>
        </View>
        <View style={staticStyles.channelsListContainer}>
          <Content visible={layoutReady}>
            <ChannelListView
              channels={channels}
              currentChannel={currentChannel}
              position={position.y}
              {...androidSanity?.channelList}
            />
          </Content>
        </View>
        <View
          style={staticStyles.gridContainer}
          onLayout={onGridLayout}
          testID='epg_grid'
        >
          <Content visible={layoutReady}>
            <AnimatedScrollView
              positionX={position.x}
              positionY={position.y}
              size={gridSize}
              style={StyleSheet.absoluteFill}
              onTap={onTap}
              {...androidSanity?.grid}
            >
              {epgTiles}
              {selectedTile}
              {tileIcons}
            </AnimatedScrollView>
          </Content>
          <ActivityIndicator animating={fetching || !layoutReady} />
        </View>
      </Reanimated.View>
    </PanGestureHandler>
  );
}));
EpgPiccolo.displayName = 'EpgPiccolo';

export default EpgPiccolo;
