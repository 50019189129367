import {Log} from 'common/Log';

import {DeviceManager} from 'mw/api/DeviceManager';
import {nxffConfig} from 'mw/api/NXFF';
import {mw} from 'mw/MW';
import {Notification} from 'mw/notifications/Notification';
import {NotificationsManager, NotificationEvent} from 'mw/notifications/NotificationsManager';

import {NotificationMapper5q} from './NotificationMapper5q';

const TAG = 'NotificationsManager5q';

export class NotificationsManager5q extends NotificationsManager {
  private socket: WebSocket | null = null;
  private started = false;
  private connected = false;
  private retries = 0;
  private retryTimerId = 0;
  private pingTimerId = 0;

  public start(): void {
    if (this.started) {
      return;
    }
    Log.debug(TAG, 'Starting notifications manager');
    this.started = true;
    this.connect();
  }

  public stop(): void {
    if (!this.started) {
      return;
    }
    Log.debug(TAG, 'Stopping notifications manager');
    this.started = false;
    this.retries = 0;
    this.disconnect();
  }

  private getServerUrl(): string {
    const serverUrl = nxffConfig.getConfig().NotificationService.NotificationsServerURL;
    if (!serverUrl) {
      Log.error(TAG, 'Notifications server URL is missing');
      return '';
    }
    const params: string[] = [];
    const customerId = mw.customer.externalId;
    if (customerId) {
      params.push(`CustomerId=${customerId}`);
    } else {
      Log.error(TAG, 'Failed to format notifications server URL - customer id is missing');
      return '';
    }
    const deviceId = DeviceManager.getInstance().getId();
    if (deviceId) {
      params.push(`CpeId=${deviceId}`);
    } else {
      Log.error(TAG, 'Failed to format notifications server URL - device id is missing');
      return '';
    }
    return `${serverUrl}?${params.join('&')}`;
  }

  private inDeliveryWindow(notification: Notification): boolean {
    if (notification.deliveryWindowStart && notification.deliveryWindowEnd) {
      const now = new Date();
      return notification.deliveryWindowStart <= now && notification.deliveryWindowEnd > now;
    }
    return true;
  }

  private onSocketMessage = (event: MessageEvent): void => {
    try {
      if (!event.data) {
        Log.error(TAG, 'There is no data in the incoming message - ignoring it');
        return;
      }
      const notificationJson = JSON.parse(event.data)?.Notification;
      if (!notificationJson) {
        Log.warn(TAG, 'There is no Notification property in the incoming message - ignoring it');
        return;
      }
      const notification = NotificationMapper5q.fromJSON(notificationJson);
      if (!this.inDeliveryWindow(notification)) {
        Log.info(TAG, 'The incoming notification is outside of its delivery window - ignoring it');
        return;
      }
      Log.info(TAG, 'Got new notificaiton', notification);
      this.notify(NotificationEvent.NotificationReceived, notification);
    } catch (error) {
      Log.error(TAG, 'Failed to parse an incoming notification. Got error: ', error);
    }
  }

  private onSocketError = (event: Event): void => {
    Log.error(TAG, 'Got unexpected socket error:', event);
  }

  private onSocketClose = (event?: CloseEvent): void => {
    Log.info(TAG, 'Connection with the notifications server has been closed');
    this.connected = false;
    this.stopPinging();
    this.cancelRetry();
    if (this.started) {
      this.retry();
    }
  }

  private onSocketOpen = (event: Event): void => {
    Log.info(TAG, 'Connection with the notifications server has been established');
    this.connected = true;
    this.retries = 0; // reset retries counter to properly react when connection get lost
    this.startPinging();
  }

  private connect = (): void => {
    if (this.socket) {
      Log.debug(TAG, 'Ignoring attempt to connect to notifications server - socket still exists');
      return;
    }
    const serverUrl = this.getServerUrl();
    if (!serverUrl) {
      Log.error(TAG, 'Aborting attempt to connect to notifications server - URL is missing');
      return;
    }
    Log.info(TAG, `Connecting with the notifications server ${serverUrl}`);
    this.socket = new WebSocket(serverUrl);
    this.socket.onmessage = this.onSocketMessage;
    this.socket.onerror = this.onSocketError;
    this.socket.onclose = this.onSocketClose;
    this.socket.onopen = this.onSocketOpen;
  }

  private disconnect = (): void => {
    if (!this.socket) {
      return;
    }
    this.socket.close();
    // WebSocket can still send events after calling the close method. Assigning null to them is the only way to unregister from these events.
    Object.assign(this.socket, {
      onmessage: null,
      onerror: null,
      onclose: null,
      onopen: null
    });
    this.socket = null;
    this.onSocketClose();
  }

  private cancelRetry(): void {
    if (this.retryTimerId) {
      clearTimeout(this.retryTimerId);
      this.retryTimerId = 0;
    }
  }

  private retry(): void {
    const retryInterval = nxffConfig.getConfig().NotificationService.NotificationsRetryInterval;
    const maxRetries = nxffConfig.getConfig().NotificationService.NotificationsMaxRetries;
    if (this.retries++ < maxRetries) {
      Log.info(TAG, `Will try again for the ${this.retries}. time to connect to the notifications server in ${retryInterval}ms`);
      this.retryTimerId = setTimeout(this.connect, retryInterval);
    } else {
      Log.warn(TAG, `Failed to connect to notifications server after ${this.retries} attempts - stopping notifications manager`);
      this.stop();
    }
  }

  private startPinging(): void {
    const pingInterval = nxffConfig.getConfig().NotificationService.NotificationsPingInterval;
    if (pingInterval > 0) {
      Log.info(TAG, `Starting pings using ${pingInterval}ms interval`);
      this.pingTimerId = setInterval(this.ping, pingInterval);
    } else {
      Log.info(TAG, `Pings have been disabled`);
    }
  }

  private stopPinging(): void {
    if (!this.pingTimerId) {
      return;
    }
    Log.info(TAG, 'Stopping pings');
    clearInterval(this.pingTimerId);
    this.pingTimerId = 0;
  }

  private ping = (): void => {
    if (this.socket && this.connected) {
      try {
        this.socket.send('ping');
      } catch (error) {
        Log.error(TAG, 'Error sending ping', error);
      }
    }
  }
}
