import {createStyles} from 'common-styles';
import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions, isBigScreen} from 'common/constants';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Profile} from 'mw/api/Profile';
import {mw} from 'mw/MW';

import {EnterPinModalComponent} from 'components/EnterPinModalComponent';
import NitroxText from 'components/NitroxText';
import Popup, {PopupAction} from 'components/Popup';
import UnlockPinKeyboard from 'components/unlockmodal/UnlockPinKeyboard';
import {useDisposable, useToggle, useDisposableState} from 'hooks/Hooks';

const TAG = 'PinResetHandler';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  messageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: dimensions.margins.xxLarge
  },
  menu: {
    justifyContent: 'center'
  },
  message: {
    color: colors.popup.text
  },
  button: {
    width: isBigScreen ? 'auto' : dimensions.popup.button.width,
    marginHorizontal: dimensions.margins.small
  }
}));

const mainProfileUnlockMaxTries = 3;

type PinResetPopupProps = {
  title: string;
  message: string;
  negativeLabel?: string;
  onNegative?: () => void;
  positiveLabel?: string;
  onPositive?: () => void;
  onModalClose?: () => void;
}

const PinResetPopup: React.FC<PinResetPopupProps> = ({
  title,
  message,
  negativeLabel,
  onNegative,
  positiveLabel,
  onPositive,
  onModalClose
}) => {
  const styles = stylesUpdater.getStyles();
  const actions = useMemo(() => {
    const actions = [];
    if (negativeLabel && onNegative) {
      actions.push(PopupAction.NEGATIVE);
    }
    if (positiveLabel && onPositive) {
      actions.push(PopupAction.POSITIVE);
    }
    return actions;
  }, [negativeLabel, onNegative, positiveLabel, onPositive]);

  return (
    <Popup
      visible
      title={title}
      actions={actions}
      menuStyle={styles.menu}
      menuButtonStyle={styles.button}
      menuHasPreferredFocus
      negativeLabel={negativeLabel}
      onNegative={onNegative}
      positiveLabel={positiveLabel}
      onPositive={onPositive}
      onModalClose={onModalClose}
    >
      <View style={styles.messageContainer}>
        <NitroxText style={styles.message} textType='dialog-message'>{message}</NitroxText>
      </View>
    </Popup>
  );
};

enum State {
  MaxTriesReached,
  PinResetConfirmation,
  PinResetSuccess,
  PinResetFailure,
  PinChangeSuccess,
  PinChangeFailure,
  UnlockModal
}

type PinResetHandlerProps = {
  closePopup: () => void;
  closeModal: () => void;
  selectedProfile: Profile;
}

const PinResetHandler: React.FC<PinResetHandlerProps> = ({closePopup, closeModal, selectedProfile}) => {
  const {t} = useTranslation();
  const [state, setState] = useDisposableState<State>(State.MaxTriesReached);
  const [newPinModalVisible, {on: showNewPinModal, off: hideNewPinModal}] = useToggle();

  const setPin = useDisposable((pin: string) => selectedProfile.setPin(pin), [selectedProfile]);

  const changePin = useCallback(async (pin: string) => {
    try {
      hideNewPinModal();
      await setPin(pin);
      setState(State.PinChangeSuccess);
    } catch {
      setState(State.PinChangeFailure);
    }
  }, [setPin, hideNewPinModal, setState]);

  const setPinToDefault = useCallback(async () => {
    try {
      await setPin(mw.configuration.defaultProfilePin);
      setState(State.PinResetSuccess);
    } catch {
      setState(State.PinResetFailure);
    }
  }, [setPin, setState]);

  const resetPin = useCallback(async () => {
    if (mw.configuration.defaultProfilePin) {
      setPinToDefault();
    } else {
      showNewPinModal();
    }
  }, [setPinToDefault, showNewPinModal]);

  const popupProps = useMemo<PinResetPopupProps | null>(() => {
    switch (state) {
      case State.MaxTriesReached:
        return {
          title: t('unlock.maxTriesReachedPopup.title'),
          message: t('unlock.maxTriesReachedPopup.message'),
          negativeLabel: t('unlock.maxTriesReachedPopup.cancel'),
          onNegative: selectedProfile.isMain ? closeModal : closePopup,
          ...!selectedProfile.isMain && {
            positiveLabel: t('unlock.maxTriesReachedPopup.forgotten'),
            onPositive: () => setState(State.PinResetConfirmation)
          }
        };
      case State.PinResetConfirmation:
        return {
          title: t('unlock.pinResetConfirmationPopup.title'),
          message: t('unlock.pinResetConfirmationPopup.message', {profileName: selectedProfile.name}),
          negativeLabel: t('unlock.pinResetConfirmationPopup.no'),
          onNegative: closePopup,
          positiveLabel: t('unlock.pinResetConfirmationPopup.yes'),
          onPositive: () => setState(State.UnlockModal)
        };
      case State.PinResetSuccess:
        return {
          title: t('unlock.pinResetSuccessPopup.title'),
          message: t('unlock.pinResetSuccessPopup.message', {profileName: selectedProfile.name, defaultProfilePin: mw.configuration.defaultProfilePin}),
          positiveLabel: t('unlock.pinResetSuccessPopup.ok'),
          onPositive: closePopup
        };
      case State.PinResetFailure:
        return {
          title: t('unlock.pinResetFailurePopup.title'),
          message: t('unlock.pinResetFailurePopup.message', {profileName: selectedProfile.name}),
          positiveLabel: t('unlock.pinResetFailurePopup.ok'),
          onPositive: closeModal
        };
      case State.PinChangeSuccess:
        return {
          title: t('unlock.pinChangeSuccessPopup.title'),
          message: t('unlock.pinChangeSuccessPopup.message', {profileName: selectedProfile.name}),
          positiveLabel: t('unlock.pinChangeSuccessPopup.ok'),
          onPositive: closePopup
        };
      case State.PinChangeFailure:
        return {
          title: t('unlock.pinChangeFailurePopup.title'),
          message: t('unlock.pinChangeFailurePopup.message', {profileName: selectedProfile.name}),
          positiveLabel: t('unlock.pinChangeFailurePopup.ok'),
          onPositive: closeModal
        };
      case State.UnlockModal:
        return null;
    }
  }, [state, t, selectedProfile, closeModal, closePopup, setState]);

  if (!mw.customer.mainProfile) {
    Log.error(TAG, 'Main profile not found');
    closeModal();
    return null;
  } else if (newPinModalVisible) {
    return (
      <EnterPinModalComponent
        visible
        title={t('settings.enterNewPin')}
        onClose={closeModal}
        onSubmit={changePin}
      />
    );
  } else if (popupProps) {
    return (
      <PinResetPopup {...popupProps} onModalClose={closeModal} />
    );
  } else {
    return (
      <UnlockPinKeyboard
        type='any'
        selectedProfile={mw.customer.mainProfile}
        onClose={closeModal}
        onSuccess={resetPin}
        maxTries={mainProfileUnlockMaxTries}
        onMaxTriesReached={closeModal}
      />
    );
  }
};

export default React.memo(PinResetHandler);
