export enum LogLevel {
  TRACE,
  DEBUG,
  TA,
  INFO,
  WARN,
  ERROR,
  PROFILER,
  NONE
}

export const defaultLogLevel = LogLevel.INFO;

export interface Levels {
  [key: string]: number;
}

/**
 * @name levels
 * @description Dictionary of log levels with their numeric values
 * @return
 *   {
 *     TRACE: 0,
 *     DEBUG: 1,
 *     TA: 2,
 *     INFO: 3,
 *     WARN: 4,
 *     ERROR: 5,
 *     PROFILER: 6,
 *     NONE: 7
 *   }
 */
export const levels = Object.keys(LogLevel).reduce((acc: Levels, currentKey) => {
  if (!isNaN(+currentKey)) {
    return acc;
  }
  acc[currentKey] = LogLevel[currentKey as keyof typeof LogLevel];
  return acc;
}, {});
