import React from 'react';
import {StyleProp, ViewStyle, View, TouchableWithoutFeedback} from 'react-native';

type Props = {
  style?: StyleProp<ViewStyle>;
  onTap: () => void;
}

const innerViewStyle: ViewStyle = {flex: 1};

const TapRecognizerView: React.FC<Props> = props => {
  const {onTap} = props;

  return (
    <TouchableWithoutFeedback
      onPress={onTap}
      style={props.style}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      touchSoundDisabled
    >
      <View style={innerViewStyle} collapsable={false}>
        {props.children}
      </View>
    </TouchableWithoutFeedback>
  );
};

export default TapRecognizerView;
