import {createStyles} from 'common-styles';
import React, {memo, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {dimensions} from 'common/constants';

import {PopupAction} from 'components/Popup';
import SelectionPopup from 'components/SelectionPopup';
import {useChangeEffect} from 'hooks/Hooks';
import {PostProcessors} from 'locales/i18nPostProcessors';

import {SettingsOrdersFilterValue, SettingsOrdersFilterOptionsKeys} from './SettingsOrderProps';

const styles = createStyles({
  menu: {
    justifyContent: 'center'
  },
  menuButtons: {
    margin: dimensions.margins.small
  }
});

type SettingsOrdersFilterSelectProps = {
  modalVisible: boolean;
  searchField: SettingsOrdersFilterValue;
  onModalClosed: () => void;
  onModalApply: (searchField: SettingsOrdersFilterValue) => void;
}

const actions: PopupAction[] = [PopupAction.NEGATIVE, PopupAction.POSITIVE];

const SettingsOrdersFilterSelect: React.FunctionComponent<SettingsOrdersFilterSelectProps> = ({modalVisible, searchField, onModalApply, onModalClosed}) => {
  const {t} = useTranslation();
  const [selectedFilterKey, setSelectedFilterKey] = useState(searchField);

  useChangeEffect(() => {
    setSelectedFilterKey(searchField);
  }, [searchField]);

  const onApply = useCallback(() => onModalApply(selectedFilterKey), [onModalApply, selectedFilterKey]);

  const onClose = useCallback(() => {
    setSelectedFilterKey(searchField);
    onModalClosed();
  }, [onModalClosed, searchField]);

  const sections = useMemo(() =>
    [{
      options: Object.entries(SettingsOrdersFilterOptionsKeys).map(([key, value]) => ({
        key,
        text: t(value, {postProcess: PostProcessors.ToUpperCase}),
        onPress: () => setSelectedFilterKey(key as SettingsOrdersFilterValue)
      }))
    }], [t]);

  const popupProps = useMemo(() => ({
    actions,
    positiveLabel: t('common.apply', {postProcess: PostProcessors.ToUpperCase}),
    negativeLabel: t('common.close', {postProcess: PostProcessors.ToUpperCase}),
    onPositive: onApply,
    onNegative: onClose,
    menuStyle: styles.menu,
    menuButtonStyle: styles.menuButtons
  }), [onApply, onClose, t]);

  return (
    <SelectionPopup
      sections={sections}
      focusedKey={selectedFilterKey}
      title={t('search.filters')}
      visible={modalVisible}
      popupProps={popupProps}
      onClose={onClose}
      columnCount={3}
    />
  );
};

export default memo(SettingsOrdersFilterSelect);
