import {Player} from './Player';
import {PlayerType} from './types/PlayerType';

export class PlayersManager {
  public main: Player;
  public audio: Player;

  public constructor() {
    this.main = new Player(PlayerType.MAIN);
    this.audio = new Player(PlayerType.AUDIO);
  }

  public uninitialize(): void {
    this.main.stop();
    this.audio.stop();
  }
}
