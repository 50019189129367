import {createStyles} from 'common-styles';
import i18next from 'i18next';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {View, ViewStyle, StyleProp} from 'react-native';

import {dimensions, isBigScreen} from 'common/constants';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {ComponentType, ComponentDataSourceType} from 'mw/api/CMSInterface';
import {nxffConfig} from 'mw/api/NXFF';
import {LinkType} from 'mw/cms/Menu';
import {mw} from 'mw/MW';

import NitroxText from 'components/NitroxText';
import {maxContainerWidth} from 'components/payments/ProductsList';
import Popup, {PopupAction} from 'components/Popup';

const TAG = 'MessageFlowScenarioPurchase';

const styles = createStyles({
  container: {
    alignItems: 'center'
  },
  title: {
    paddingTop: dimensions.margins.large,
    paddingBottom: dimensions.margins.small
  },
  description: {
    paddingBottom: dimensions.margins.small,
    paddingTop: dimensions.margins.xsmall
  }
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  text: {
    color: colors.popup.text,
    textAlign: 'center'
  }
}));

type Props = {
  style: StyleProp<ViewStyle>;
  onClose?: () => void;
  visible: boolean;
};

async function getMessageFlowPurchaseDescription(t: i18next.TFunction): Promise<string> {
  let purchaseMessage = t('payments.dialog.messageFlowDescription');
  Log.debug(TAG, `getPurchaseMessage`);
  const purchaseMessageSlug = nxffConfig.getConfig().UI.UXMPurchaseMessageSlug;
  if (!purchaseMessageSlug) {
    return purchaseMessage;
  }

  try {
    const response = await mw.cms.getPage({
      type: LinkType.PAGE,
      slug: purchaseMessageSlug
    });
    Log.debug(TAG, `getPage with slug: ${purchaseMessageSlug}`, response);

    const component = response.componentGroup
      .find(group => group.title !== '')?.components
      .find(component => component.type === ComponentType.Content);

    purchaseMessage = component?.dataSource.type === ComponentDataSourceType.Content
      ? component.dataSource.data
      : purchaseMessage;
  } catch (error) {
    Log.warn(TAG, 'promise reject:', error);
  }

  return purchaseMessage;
}

const MessageFlowPurchase: React.FunctionComponent<Props> = props => {
  const {
    style,
    onClose,
    visible
  } = props;

  const {t} = useTranslation();
  const dialogTitle = t('payments.dialog.messageFlowTitle');
  const [description, setDescription] = useState<string>(t('payments.dialog.messageFlowDescription'));
  const dynamicStyle = stylesUpdater.getStyles();
  const width = maxContainerWidth + 2 * dimensions.margins.xxLarge;

  useEffect(() => {
    if (visible) {
      getMessageFlowPurchaseDescription(t)
        .then(setDescription);
    }
  }, [setDescription, t, visible]);

  return (
    <Popup
      visible={visible}
      key='MessageFlowPurchase'
      width={isBigScreen ? width : undefined}
      actions={[PopupAction.POSITIVE]}
      menuHasPreferredFocus
      positiveLabel={t('common.ok')}
      onPositive={onClose}
      onModalClose={onClose}
    >
      <View style={[styles.container, style]}>
        <NitroxText
          textType='dialog-title'
          style={[dynamicStyle.text, styles.title]}
        >
          {dialogTitle}
        </NitroxText>
        <NitroxText
          textType='paymentProductDescription'
          style={[dynamicStyle.text, styles.description]}
        >
          {description}
        </NitroxText>
      </View>
    </Popup>
  );
};

export default MessageFlowPurchase;
