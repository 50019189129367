import {RefObject} from 'react';

import {Direction} from 'common/constants';

import {Measurable, EnterStrategy} from './FocusManagerTypes';

export type FocusParentNodeProps = {
  /**
   * @property active - when false, FocusParent and its descendants can't be focused.
   * If already focused, setting active=false won't blur automatically.
   */
  active?: boolean;
  debugName?: string;
  /**
  * @property enterStrategy - defines behaviour of focus
  *  engine, when it "enters" parent, possible values are:
  *  - topLeft - engine chooses a child that is closest
  *    to topLeft corner of parent
  *  - byPriority - engine chooses a child that has the
  *    highest value of focusPriority property
  *    (both FocusParent and NitroxInteractive have that prop)
  *    if every child has the same value of priority, topLeft
  *    strategy is applied
  *  - undefined - not specifying enterStrategy makes
  *    focus engine select a next focused item using its
  *    basic algorithm, meaning that search area is a
  *    rectangle adjacent to the previously focused item
  *    that extends in the direction of focus movement;
  *    if there is no children in this search area, then
  *    it is enlarged to the whole parent area
  */
  enterStrategy?: EnterStrategy;
  /**
  * @property rememberLastFocused - FocusParent remembers
  *  last focused child and focus is passed to it on
  *  entering parent - this overrides enterStrategy
  */
  rememberLastFocused: boolean;
  /**
  * @property trapFocus - makes it impossible for focus
  *  to escape FocusParent using normal movement, forcing
  *  focus elsewhere still works
  */
  trapFocus: boolean;
  /**
  * @property trapExitEdge - while @property trapFocus
  * is set to true, this parameter allow to pass list of
  * directions that allow to escape focusTrap
  */
  trapExitEdges: Direction[];
  /**
  * @property holdFocus - allow to maintain focus by focus parent,
  * if no focusable children are present. On any child register focus will be
  * passed down to that child, with enterStrategy respected in case of multiple children registration
  * at once.
  * FocusParent will give away focus on any navigation event, if no child is present.
  */
  holdFocus: boolean;
};

const getId = (() => {
  let id = 0;
  return () => {
    id = id + 1 >> 0; // 32-bit int overflow
    return id;
  };
})();

export class FocusParentNode {
  public readonly id = getId();
  public constructor(
    public getProps: () => FocusParentNodeProps,
    public ref: RefObject<Measurable>
  ) {}

  public toString(): string {
    const {debugName, enterStrategy, trapFocus, rememberLastFocused} = this.getProps();
    let info = '';
    if (debugName) {
      info += `(${debugName})`;
    }
    if (enterStrategy) {
      info += ` enterStrategy='${enterStrategy}'`;
    }
    if (trapFocus) {
      info += ' trapFocus';
    }
    if (rememberLastFocused) {
      info += ' rememberLastFocused';
    }
    return `${FocusParentNode.name}(#${this.id}${info})`;
  }
}
