import {Track, PlayerEvent, TracksList, SubtitlesChanged as SubtitlesChangedInterface} from 'mw/api/PlayerEvent';
import {PlayerType} from 'mw/playback/types/PlayerType';

import {WebPlaybackEvent, normalizeString} from './WebPlaybackEvent';

export class SubtitlesChanged extends WebPlaybackEvent {

  public subtitlesChanged: SubtitlesChangedInterface;

  public constructor(playerType: PlayerType, availableTracks: Track[], trackIndex: number, streamId: number) {
    super(PlayerEvent.SubtitlesChanged, playerType, streamId);

    const subtitleLanguageList: TracksList = {
      currentTrack: trackIndex,
      tracks: availableTracks.map(subtitleTrack => {
        const track: Track = {
          language: normalizeString(subtitleTrack.language),
          type: normalizeString(subtitleTrack.type)
        };
        return track;
      })
    };

    this.subtitlesChanged = {
      subtitleLanguageList: subtitleLanguageList
    };
  }
}
