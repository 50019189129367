import {EventEmitterInterface, EventEmitterCallback} from 'common/EventEmitterInterface';
import {Log} from 'common/Log';

import {Error, ErrorType} from 'mw/api/Error';

import {ChromecastEvent} from './ChromecastInterface';

const TAG = 'ChromecastEventEmitterClass';

class ChromecastEventEmitterClass implements EventEmitterInterface<ChromecastEvent> {
  public on(event: ChromecastEvent, callback: EventEmitterCallback<any>): void {
    Log.info(TAG, 'Chromecast not supported. No listener added');
  }
  public once(event: ChromecastEvent, callback: EventEmitterCallback<any>): void {
    Log.info(TAG, 'Chromecast not supported. No listener added');
  }
  public off(event: ChromecastEvent, callback: EventEmitterCallback<any>): void {
    Log.info(TAG, 'Chromecast not supported. No listener removed');
  }
  public clear(): void {
    throw new Error(ErrorType.NotSupported);
  }
}

// There is no need to create numerous instances of a class that just forwards calls
// to library NativeEventEmitter, as EventEmitter methods are non static
// best is to create and export a singleton object.
const chromecastEventEmitter = new ChromecastEventEmitterClass();
export default chromecastEventEmitter;
