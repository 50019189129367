import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {dimensions, isTablet} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {ExternalLink} from 'mw/api/Metadata';

import ButtonsRow from 'components/ButtonsRow';
import {NitroxButtonTheme} from 'components/NitroxButton';
import NitroxText from 'components/NitroxText';
import Popup, {PopupAction} from 'components/Popup';

const ButtonWidth = isTablet ? 200 : 250;
const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  title: {
    marginBottom: dimensions.margins.xxLarge
  },
  container: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  modalSubtitle: {
    color: colors.popup.text,
    alignSelf: 'center',
    marginBottom: dimensions.margins.xxLarge
  }
}));

type Props = {
  visible: boolean;
  externalLink: ExternalLink;
  onOpenStore: () => void;
  onClose: () => void;
}

const WatchOnErrorPopup: React.FunctionComponent<Props> = props => {
  const {onClose, visible, onOpenStore, externalLink} = props;
  const {t} = useTranslation();
  const styles = stylesUpdater.getStyles();

  const items: any[] = useMemo(() => {
    const items: any[] = [
      {
        text: t('deepLinking.launchAppErrorButton'),
        onPress: onOpenStore,
        hasTvPreferredFocus: true
      }
    ];
    return items;
  }, [onOpenStore, t]);

  return (
    <Popup
      visible={visible}
      title={t('deepLinking.launchAppErrorTitle', {appName: externalLink.provider})}
      titleStyle={styles.title}
      containerStyle={styles.container}
      onClose={onClose}
      actions={[PopupAction.NEGATIVE]}
      menuHasPreferredFocus={false}
    >
      <NitroxText style={styles.modalSubtitle} textType='dialog-message'>{t('deepLinking.launchAppErrorMessage', {appName: externalLink.provider})}</NitroxText>
      <ButtonsRow
        data={items}
        columns={isTablet ? 3 : 1}
        buttonWidth={ButtonWidth}
        buttonTheme={NitroxButtonTheme.Primary}
        xMargin={dimensions.margins.xxLarge}
        yMargin={dimensions.margins.large}
        buttonBorder
        centerFirstRow
      />
    </Popup>
  );
};

export default React.memo(WatchOnErrorPopup);
