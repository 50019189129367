import {debounce, DebouncedFunction} from 'common/Async';
import {DateUtils} from 'common/DateUtils';
import {Log} from 'common/Log';

import {ContentType} from 'mw/api/Metadata';
import {nxff} from 'mw/api/NXFF';
import {PlayerEvent, StopParams} from 'mw/api/PlayerEvent';
import {boProxy} from 'mw/bo-proxy/BOProxy';
import {PlaybackController, StartPlaybackParameters} from 'mw/playback/controllers/PlaybackController';
import {NativePlaybackParameters} from 'mw/playback/types/NativePlaybackParameters';
import {PlaybackResponse} from 'mw/playback/types/PlaybackParameters';
import {PlayerType} from 'mw/playback/types/PlayerType';

const TAG = 'BookmarkedPlayer';

export abstract class BookmarkedPlayer extends PlaybackController {

  private readonly scheduleBookmarkUpdate?: DebouncedFunction<() => Promise<void>>;

  protected constructor(windowType: PlayerType, contentType: ContentType) {
    super(windowType, contentType);

    const settingBookmarkInterval = nxff.getConfig().Playback.SettingBookmarkInterval;
    if (settingBookmarkInterval > 0) {
      this.scheduleBookmarkUpdate = debounce(() => {
        this.updateBookmark()
          .catch(error => Log.error(TAG, 'bookmark timer: set bookmark error', error));
        this.scheduleBookmarkUpdate?.();
      }, settingBookmarkInterval * DateUtils.msInSec);
    }
  }

  public stop(params: StopParams): Promise<PlaybackResponse> {
    this.scheduleBookmarkUpdate?.abort();
    const position = this.getPosition();
    return super.stop(params)
      .finally(() => {
        this.setBookmark(position)
          .catch(error => Log.error(TAG, 'stop: set bookmark error', error));
      });
  }

  public startPlayback(params: StartPlaybackParameters): Promise<PlaybackResponse> {
    const media = this.currentMedia;
    if (media && media.isViewedCompletely) {
      boProxy.bo.deleteBookmark(media)
        .catch(error => Log.error(TAG, 'start: reset bookmark error', error));
    }

    this.scheduleBookmarkUpdate?.();

    return super.startPlayback(params);
  }

  protected applyChangedParameters(playbackParameters: NativePlaybackParameters): void {
    if (this.playbackParameters.playRate === 1 && playbackParameters.playRate === 0) {
      // Set bookmark and clear bookmark timer when player has been paused
      this.scheduleBookmarkUpdate?.abort();
      this.updateBookmark()
        .catch(error => Log.error(TAG, 'pause: set bookmark error', error));
    } else if (this.playbackParameters.playRate === 0 && playbackParameters.playRate === 1) {
      // Reschedule bookmark timer when player has been unpaused
      this.scheduleBookmarkUpdate?.();
    }

    super.applyChangedParameters(playbackParameters);
  }

  private updateBookmark(): Promise<void> {
    return this.setBookmark(this.getPosition());
  }

  private setBookmark(position: number): Promise<void> {
    const media = this.currentMedia;
    if (!media || !this.initialized) {
      return Promise.resolve();
    }
    return boProxy.bo.setBookmark(media, position)
      .then(() => this.notify(PlayerEvent.BookmarkUpdate, {media, position}));
  }
}
