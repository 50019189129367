import {CurrentPlatform} from 'common/constants';

// eslint-disable-next-line no-restricted-imports
import app from 'brand/current/app.json';

export enum ComponentsVersion {
  Default = 'default'
}

const BuildConfig = app as {
  componentsVersion?: ComponentsVersion;
  name: {
    bigScreen: string;
    mobile: string;
  };
  platforms?: {
    [key in CurrentPlatform]?: object & {
      features?: object;
    };
  };
  showCredentialsScreenSplashBackground?: boolean;
};

export default BuildConfig;
