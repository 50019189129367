import Color from 'color';

import {ColorPalette, constColors} from 'brand/ColorTypes';

const colorPalette: ColorPalette = {
  ...constColors,
  red: '#CB2828',
  label1: '#FFF',
  icons: '#FFF',
  background1: '#181B1C',
  background2: '#1F2224',
  background3: '#313436',
  background4: '#000',
  background5: '#6f6f6f',
  background6: '#1A1754',
  background7: '#151419',
  backgroundBlack: '#000',
  base1: '#181B1C',
  base2: '#000',
  base3: '#3D60AC',
  base4: '#0CB072',
  base5: '#F1C410',
  error: '#CB2828',
  epg: {
    active: '#6f6f6f',
    focused: '#3D60AC',
    past: '#35383a',
    default: '#313436'
  },
  menu: {
    base: '#5A00FF',
    background: '#313436',
    text: '#FFF'
  },
  overlayShade: Color('#000').alpha(0.8)
    .toString(),
  tile: {
    text: {
      focused: '#000',
      unfocused: '#FFF'
    },
    background: {
      selected: '#FFF',
      default: 'transparent'
    },
    placeholder: {
      background: '#313436'
    }
  },
  input: {
    background: {
      focused: '#FFF',
      unfocused: '#FFF'
    },
    text: {
      focused: '#000',
      unfocused: '#FFF'
    }
  },
  button: {
    primary: {
      background: {
        focused: '#FFF',
        unfocused: '#FFF'
      },
      text: {
        focused: '#000',
        unfocused: '#FFF'
      }
    },
    secondary: {
      background: {
        focused: '#313436',
        unfocused: '#313436'
      },
      text: {
        focused: '#FFF',
        unfocused: '#FFF'
      }
    },
    tertiary: {
      background: {
        focused: 'transparent',
        unfocused: 'transparent'
      },
      text: {
        focused: '#FFF',
        unfocused: '#FFF'
      }
    },
    destructive: {
      background: {
        focused: '#CB2828',
        unfocused: '#CB2828'
      },
      text: {
        focused: '#FFF',
        unfocused: '#FFF'
      }
    }
  },
  popup: {
    background: {
      top: '#1C1C1C',
      bottom: '#282828'
    }
  },
  progress: '#CB2828',
  channelIcon: {
    background: {
      default: '#313436',
      focused: '#6f6f6f',
      selected: '#6f6f6f'
    },
    text: {
      numberInput: '#FFF'
    }
  },
  userAvatar: {
    background: {
      placeholder: '#6f6f6f'
    }
  },
  paymentLabels: {
    canceled: '#6F6F6F',
    failed: '#FF2D55',
    unpaid: '#5856D6',
    paymentFailed: '#FF2D55',
    pending: '#FF9500',
    success: '#0CB072'
  },
  channelTile: {
    background: '#313436',
    iconContainerBackground: '#1F2224',
    text: '#FFF',
    channelIcon: {
      background: '#313436',
      lcn: {
        default: '#6f6f6f',
        selected: '#0CB072'
      }
    }
  },
  newUI: {
    background1: '#131031',
    button: {
      primary: {
        background: {
          focused: '#FFF',
          unfocused: '#FFF',
          selected: '#5A00FF'
        },
        text: {
          focused: '#000',
          unfocused: '#FFF',
          selected: '#fff'
        }
      },
      secondary: {
        background: {
          focused: '#FFF',
          unfocused: '#FFF'
        },
        text: {
          focused: '#000',
          unfocused: '#FFF'
        }
      },
      tertiary: {
        background: {
          focused: '#5856D6',
          unfocused: '#FFF'
        },
        text: {
          focused: '#FFF',
          unfocused: '#FFF'
        }
      },
      quaternary: {
        background: {
          focused: {
            start: '#5A00FF',
            stop: '#8C70EC'
          },
          unfocused: '#FFF'
        },
        text: {
          focused: '#FFF',
          unfocused: '#FFF'
        }
      },
      destructive: {
        background: {
          focused: '#CB2828',
          unfocused: '#CB2828'
        },
        text: {
          focused: '#FFF',
          unfocused: '#FFF'
        }
      }
    },
    gradient1: {
      start: '#5A00FF',
      stop: '#8C70EC'
    },
    gradient2: {
      start: '#1B1764',
      stop: '#141318'
    },
    channelIcon: {
      background: {
        default: '#5856D6',
        focused: '#6f6f6f',
        selected: '#6f6f6f'
      },
      text: {
        numberInput: '#FFF'
      }
    },
    popup: {
      background: {
        top: '#131031',
        bottom: '#393252'
      }
    },
    epg: {
      unfocused: '#fff',
      focused: '#5856D6',
      timebar: {
        past: '#9C9AF4',
        future: '#000'
      }
    },
    toggleSwitch: {
      active: '#5856D6',
      notActive: '#3D3B58'
    }
  }
};

export default colorPalette;
