import React from 'react';
import {StyleSheet} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

export enum GradientFillDirection {
  Horizontal = 1,
  Vertical,
  DiagonalLeft,
  DiagonalRight
}

const gradientConfig = {
  [GradientFillDirection.Horizontal]: [{x: 0, y: 0}, {x: 1, y: 0}],
  [GradientFillDirection.Vertical]: [{x: 0, y: 1}, {x: 0, y: 0}],
  [GradientFillDirection.DiagonalLeft]: [{x: 0, y: 0}, {x: 1, y: 1}],
  [GradientFillDirection.DiagonalRight]: [{x: 0, y: 1}, {x: 1, y: 0}]
};

export type GradientFillProps = {
  colors: string[];
  direction?: GradientFillDirection;
};

const GradientFill: React.FC<GradientFillProps> = ({
  colors,
  direction = GradientFillDirection.Vertical
}) => {
  const [start, end] = gradientConfig[direction];
  return (
    <LinearGradient
      start={start}
      end={end}
      colors={colors}
      style={StyleSheet.absoluteFill}
      pointerEvents='none'
    />
  );
};

export default React.memo(GradientFill);
