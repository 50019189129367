import {createStyles} from 'common-styles';
import React, {useState, useCallback, useMemo} from 'react';
import {View, Insets, ViewStyle, StyleProp} from 'react-native';
import {withNavigation, NavigationScreenProps} from 'react-navigation';

import {dimensions, isMobile, isBigScreen, getValue, defaultPageSize} from 'common/constants';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {ContentQueryParameters} from 'mw/api/CatalogInterface';
import {Filter} from 'mw/api/Filter';
import {VodSorting, VodFilter} from 'mw/api/Metadata';
import {Component as CMSComponent, isDataSourceFilterBased} from 'mw/cms/Component';
import {mw} from 'mw/MW';

import {BigScreenHeaderProps, BigScreenTitlePosition, BigScreenBackButtonPosition} from 'components/BigScreenHeader';
import FilterSelect, {createVodFilterOptions} from 'components/FilterSelect';
import FocusParent from 'components/FocusParent';
import MediaGrid from 'components/MediaGrid';
import {mediaTileMarginHorizontal} from 'components/mediaTiles/MediaTile';
import {STBMenuState} from 'components/navigation/NavigationHelperTypes';
import {NitroxInteractiveController} from 'components/NitroxInteractiveControllerContext';
import VodSortOrderSelect from 'components/vod/VodSortOrderSelect';
import {useDisposable} from 'hooks/Hooks';

import NitroxScreen from './NitroxScreen';
import {vodCategoryScreenMargin} from './VodScreen.shared';

const TAG = 'MediaGridScreen';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  screen: {
    backgroundColor: colors.vodScreen.background,
    ...isBigScreen && {
      paddingHorizontal: dimensions.mainMenu.marginHorizontal - 2 * mediaTileMarginHorizontal
    }
  },
  fillScreen: {
    width: '100%',
    flex: 1
  },
  gridContainer: {
    flex: 1,
    width: '100%',
    marginTop: isMobile ? 0 : dimensions.margins.small,
    marginHorizontal: getValue({
      mobile: 0,
      tablet: dimensions.margins.small,
      defaultValue: 0
    })
  },
  grid: {
    flex: 1
  },
  queryParametersContainer: {
    width: '100%',
    flexDirection: 'row',
    marginBottom: dimensions.margins.small
  },
  sortingContainer: {
    marginLeft: vodCategoryScreenMargin
  },
  filterContainer: {
    marginRight: vodCategoryScreenMargin,
    marginLeft: vodCategoryScreenMargin
  }
}));

const gridInset: Insets = {
  bottom: dimensions.margins.small
};

type Props = NavigationScreenProps<{
  component?: CMSComponent;
  queryOptions?: ContentQueryParameters;
}>;

const MediaGridScreen: React.FC<Props> = ({navigation}) => {
  const [component, setComponent] = useState<CMSComponent>();
  const [queryOptions, setQueryOptions] = useState<ContentQueryParameters>({pageSize: defaultPageSize});
  const [isSeenFilterAvailableForCategory, setSeenFilterAvailableForCategory] = useState(false);

  const supportedSortOptions = useMemo(() => mw.catalog.getAvailableVODSortOptions(), []);
  const filterVodOptions = useMemo(() => createVodFilterOptions(), []);

  const styles = stylesUpdater.getStyles();

  const updateAvailableVODSortOptions = useDisposable(async (filters: Filter[]) => {
    try {
      setSeenFilterAvailableForCategory(await mw.catalog.isSeenFilterAvailableForCategory(filters));
    } catch (error) {
      Log.error(TAG, 'Error occured while checking available filters', error);
      setSeenFilterAvailableForCategory(false);
    }
  });

  const onScreenFocused = useCallback(() => {
    const newComponent = navigation.getParam('component');
    if (!newComponent) {
      Log.debug(TAG, 'There is nothing to show - component is missing');
      return;
    }
    setComponent(newComponent);
    setQueryOptions({
      pageSize: defaultPageSize,
      ...(navigation.getParam('queryOptions') ?? {})
    });
    if (isDataSourceFilterBased(newComponent.dataSource)) {
      updateAvailableVODSortOptions(newComponent.dataSource.filters);
    }
  }, [navigation, updateAvailableVODSortOptions]);

  const onSortOrderSelected = useCallback((sorting?: VodSorting) => {
    setQueryOptions((previousQueryOptions) => ({
      ...previousQueryOptions,
      sorting
    }));
  }, []);

  const onFilterSelected = useCallback((filter?: VodFilter) => {
    setQueryOptions((previousQueryOptions) => ({
      ...previousQueryOptions,
      filter
    }));
  }, []);

  const title = component?.title ?? '';

  const bigScreenHeaderProps: BigScreenHeaderProps = useMemo(() => ({
    title,
    titlePosition: BigScreenTitlePosition.Left,
    showBackButton: true,
    backButtonPosition: BigScreenBackButtonPosition.Left
  }), [title]);

  const shouldDisplaySortOptions = supportedSortOptions.length > 0;

  const queryParametersContainerStyle = useMemo<StyleProp<ViewStyle>>(() => ([
    styles.queryParametersContainer, {
      justifyContent: shouldDisplaySortOptions ? 'space-between' : 'flex-end'
    }
  ]), [styles.queryParametersContainer, shouldDisplaySortOptions]);

  return (
    <NitroxScreen
      popStackOnBack
      navigation={navigation}
      style={styles.screen}
      menuState={STBMenuState.Hidden}
      onScreenFocused={onScreenFocused}
      mobileHeaderProps={{title}}
      bigScreenHeaderProps={bigScreenHeaderProps}
      showBigScreenHeader
    >
      <FocusParent
        style={styles.fillScreen}
        enterStrategy='topLeft'
        rememberLastFocused
        debugName='MediaGridScreen'
      >
        <NitroxInteractiveController omitGeometryCaching>
          {isMobile && (
            <View style={queryParametersContainerStyle}>
              {shouldDisplaySortOptions && (
                <VodSortOrderSelect
                  supportedSortOptions={supportedSortOptions}
                  currentSortOrder={queryOptions.sorting}
                  onSortOrderSelected={onSortOrderSelected}
                  style={styles.sortingContainer}
                />
              )}
              {isSeenFilterAvailableForCategory && (
                <FilterSelect<VodFilter>
                  filterOptions={filterVodOptions}
                  currentFilter={queryOptions.filter}
                  onFilterSelected={onFilterSelected}
                  style={styles.filterContainer}
                />
              )}
            </View>
          )}
          <View style={styles.gridContainer}>
            {component?.dataSource && isDataSourceFilterBased(component.dataSource) && (
              <MediaGrid
                style={styles.grid}
                contentInset={gridInset}
                dataSource={component.dataSource.filters}
                queryOptions={queryOptions}
                maximumSpacing={0}
              />
            )}
          </View>
        </NitroxInteractiveController>
      </FocusParent>
    </NitroxScreen>
  );
};

export default withNavigation(MediaGridScreen);
