import {CustomerEvent} from 'mw/api/Customer';
import {Channel, isChannel, Media} from 'mw/api/Metadata';
import {PlayerEvent} from 'mw/api/PlayerEvent';
import {mw} from 'mw/MW';

export class MediaObserver {
  private lastChannel: Channel | null = null;
  private currentMedia: Media | null = null;

  public constructor() {
    mw.customer.on(CustomerEvent.ProfileChange, this.onProfileChanged);
    mw.players.main.on(PlayerEvent.FirstFrame, () => this.onTuned(mw.players.main.getCurrentMedia()));
  }

  private onTuned(media: Media | null): void {
    if (!media || media.equals(this.currentMedia)) {
      return;
    }

    if (isChannel(this.currentMedia)) {
      this.lastChannel = this.currentMedia;
    }

    this.currentMedia = media;
  }

  private onProfileChanged = (): void => {
    this.lastChannel = null;
    this.currentMedia = null;
  }

  public getLastChannel(): Channel | undefined {
    if (!this.lastChannel) {
      return;
    }

    return mw.catalog.getChannelById(this.lastChannel.id);
  }
}
