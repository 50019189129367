import {createStyles} from 'common-styles';
import React, {Children, ReactElement, useMemo, useState} from 'react';
import {StyleProp, View, ViewStyle, TextStyle} from 'react-native';

import {FocusableComponent} from 'components/focusManager/FocusManagerTypes';

import TabBar from './TabBar';
import ViewPager from './ViewPager';

const styles = createStyles({
  container: {
    flex: 1
  }
});

type TabProps = {
  title: string;
  titleContainerStyle?: ViewStyle;
  titleTextStyle?: TextStyle;
  tabContainerStyle?: ViewStyle;
}

export const Tab: React.FunctionComponent<TabProps> = props => {
  return (
    <>
      {props.children}
    </>
  );
};

export type TabElement = ReactElement<TabProps, typeof Tab>;

type TabViewProps = {
  children: TabElement | TabElement[];
  initialTabIndex?: number;
  tabBarStyle?: StyleProp<ViewStyle>;
  style?: ViewStyle;
  activeUnderscore?: boolean;
  visible?: boolean;
};

const TabView = React.forwardRef<FocusableComponent, TabViewProps>((props, ref) => {
  const {visible = true} = props;
  const tabBarItems = useMemo(() => Children.map(props.children, (tab: TabElement) => tab.props), [props.children]);
  const [currentTabIndex, setCurrentTabIndex] = useState(props.initialTabIndex || 0);

  if (!visible) {
    return null;
  }

  return (
    <View style={styles.container}>
      <TabBar
        ref={ref}
        items={tabBarItems}
        selectedItemIndex={currentTabIndex}
        onItemPress={setCurrentTabIndex}
        style={props.tabBarStyle}
        activeUnderscore={props.activeUnderscore}
      />
      <ViewPager currentPageIndex={currentTabIndex}>
        {props.children}
      </ViewPager>
    </View>
  );
});
TabView.displayName = TabView.name;

export default TabView;
