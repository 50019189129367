import {Order, OrderStatus} from 'mw/api/Metadata';
import {OrderResponse, OrderResponseStatus} from 'mw/bo-proxy/bo/adr8/ADR8Types';
import {convertJSONDateToJSDate} from 'mw/common/utils';
import {OrderObject} from 'mw/metadata/OrderObject';

export class OrderMapper {

  public static orderFromJson(orderJson: OrderResponse): Order {
    return new OrderObject(
      orderJson.id,
      orderJson.productId,
      orderJson.paymentMethodId,
      orderJson.currency,
      orderJson.amount,
      convertJSONDateToJSDate(orderJson.creationDate),
      OrderMapper.toOrderStatus(orderJson.orderStatus),
      orderJson.productName,
      orderJson.assetUid
    );
  }

  private static toOrderStatus(status: OrderResponseStatus) {
    switch (status) {
      case 'PENDING_PAYMENT':
        return OrderStatus.Unpaid;
      case 'PROCESSING_PAYMENT':
      case 'PENDING_DELIVERY':
        return OrderStatus.Pending;
      case 'DELIVERED':
        return OrderStatus.Success;
      case 'CANCELED':
        return OrderStatus.Canceled;
      case 'PAYMENT_FAILED':
        return OrderStatus.PaymentFailed;
      case 'DELIVERY_FAILED':
      default:
        return OrderStatus.Failed;
    }
  }
}
