import React, {useEffect, useState} from 'react';
import {ImageProps} from 'react-native';

import ChromecastConnecting1 from 'brand/current/commonResources/svgs/ChromecastConnectingStep1Icon.svg';
import ChromecastConnecting2 from 'brand/current/commonResources/svgs/ChromecastConnectingStep2Icon.svg';
import ChromecastConnecting3 from 'brand/current/commonResources/svgs/ChromecastConnectingStep3Icon.svg';
import NitroxImage from 'components/NitroxImage';

const chromecastConnectingIcons = [ChromecastConnecting1, ChromecastConnecting2, ChromecastConnecting3];
const animationIntervalMs = 750;

type Props = {
  style?: Omit<ImageProps, 'source'>;
};

const ChromecastConnectingIcon: React.FC<Props> = ({style}) => {
  const [castConnectingIconVariant, setCastConnectingIconVariant] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCastConnectingIconVariant(prevVariant => (prevVariant + 1) % chromecastConnectingIcons.length);
    }, animationIntervalMs);
    return () => clearInterval(timer);
  }, []);

  return <NitroxImage svg={chromecastConnectingIcons[castConnectingIconVariant]} style={style} />;
};

export default React.memo(ChromecastConnectingIcon);
