import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, ViewStyle} from 'react-native';

import {dimensions, isMobile, RADIUS, isBigScreen} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {PostProcessors} from 'locales/i18nPostProcessors';

import {IconProps, IconType} from './Icon';
import NitroxButton, {NitroxButtonProps, NitroxButtonTheme} from './NitroxButton';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  profileSwitchButton: {
    paddingLeft: dimensions.margins.small,
    paddingRight: dimensions.margins.small,
    borderRadius: isMobile ? 20 : RADIUS
  },
  profileSwitchButtonText: {
    ...isMobile && {
      color: colors.settingsScreen.mobile.profile.switch.text
    },
    marginHorizontal: dimensions.margins.xsmall
  },
  mobileSwitchProfileIcon: colors.button.secondary.text.focused
}));

export type ProfileSwitchButtonProps = {
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
};

const ProfileSwitchButton: React.FC<ProfileSwitchButtonProps> = ({onPress, style}, ref) => {
  const {t} = useTranslation();
  const styles = stylesUpdater.getStyles();
  const buttonStyle = useMemo(() => [styles.profileSwitchButton, style], [style, styles.profileSwitchButton]);

  const switchProfileIcon: IconProps = useMemo(() => ({
    type: IconType.SwitchProfile,
    size: dimensions.icon.xxsmall,
    ...isMobile && {
      color: styles.mobileSwitchProfileIcon
    }
  }), [styles.mobileSwitchProfileIcon]);

  const nitroxButtonProps: NitroxButtonProps = useMemo(() => ({
    icon: switchProfileIcon,
    textStyle: styles.profileSwitchButtonText,
    ...isMobile && {
      textType: 'subhead'
    },
    iconContainerStyle: {
      padding: dimensions.margins.small,
      flexDirection: 'row'
    }
  }), [styles.profileSwitchButtonText, switchProfileIcon]);

  return (
    <NitroxButton
      {...nitroxButtonProps}
      border={isBigScreen}
      theme={isBigScreen ? NitroxButtonTheme.Primary : NitroxButtonTheme.Secondary}
      style={buttonStyle}
      onPress={onPress}
      text={t('common.switchProfile', {postProcess: isBigScreen ? PostProcessors.ToUpperCase : PostProcessors.Capitalize})}
      ref={ref}
    />
  );
};

export default React.forwardRef(ProfileSwitchButton);
