import {BlobFetcher} from 'common/blobFetcher/BlobFetcher';
import {isIOS, isTVOS} from 'common/constants';
import {isBrowser} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {Error, ErrorType} from 'mw/api/Error';
import {httpFetch, HttpStatus} from 'mw/utils/HttpUtils';

const TAG = 'getFairPlayCertificate';

export function getFairPlayCertificate(url: string): Promise<string | Uint8Array | undefined> {
  if (isIOS || isTVOS) {
    return BlobFetcher.fetchBlobEncoded(url)
      .catch(error => {
        Log.error(TAG, 'fetchBlobEncoded error:', error);
        return Promise.reject(error);
      });
  }
  if (isBrowser('safari')) {
    return httpFetch(url)
      .then(response => response.status === HttpStatus.Ok
        ? response.arrayBuffer()
        : Promise.reject(new Error(ErrorType.DrmLicenseServerError, `getFairPlayCertificate failed: ${response.status} ${response.statusText}`))
      )
      .then(cert => new Uint8Array(cert))
      .catch(error => {
        Log.error(TAG, 'Error fetching FairPlay certificate', error);
        return Promise.reject(error);
      });
  }
  return Promise.resolve(undefined);
}
