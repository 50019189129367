const displayedChannels = 5;
const channelTileHeight = 150;
const channelTileWidth = 650;
const scrollViewHeight = displayedChannels * channelTileHeight;

export const tvChannelListConstants = {
  displayedChannels,
  channelTileHeight,
  channelTileWidth,
  scrollViewHeight,
  /** Viewport's center */
  fixedFocusPosition: (scrollViewHeight / 2) - (channelTileHeight / 2)
};
