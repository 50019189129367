import {createStyles} from 'common-styles';
import mitt from 'mitt';
import React, {useContext} from 'react';
import {useState, useEffect, useRef} from 'react';
import {View, Animated, PanResponder, Dimensions, StyleSheet, StatusBar} from 'react-native';
// @ts-ignore the package does not contain typing yet
import {Immersive} from 'react-native-immersive';
import {WhitePortal} from 'react-native-portal';

import {dimensions, isAndroid, isBigScreen, isMobile} from 'common/constants';
import {EventEmitter} from 'common/EventEmitter';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {useEventListener, useScreenInfo} from 'hooks/Hooks';

import {TabBarContext} from './navigation/NavigationHelperTypes';

const TAG = 'TopLevelPortalManager';
const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  portal: {
    borderColor: colors.floater.border
  }
}));

export enum TopLevelPortalType {
  Fullscreen = 'FULLSCREEN',
  Floating = 'FLOATING',
  None = 'NONE'
}
const setPortalEventName = 'setTopLevelPortal';

const emitter = new mitt();

const setNativeFullScreenMode = (fullscreen: boolean) => {
  if (isMobile && isAndroid) {
    Immersive.setImmersive(fullscreen);
  }
};

export class TopLevelPortalManager extends EventEmitter<'change', TopLevelPortalType> {
  private static shared = new TopLevelPortalManager();
  private currentPortal = TopLevelPortalType.None;
  private constructor() {
    super();
  }

  public static getInstance() { return this.shared; }

  public getCurrentPortal() {
    return TopLevelPortalManager.getInstance().currentPortal;
  }

  private setCurrentPortal(portal: TopLevelPortalType) {
    TopLevelPortalManager.getInstance().currentPortal = portal;
    TopLevelPortalManager.getInstance().notify('change', portal);
  }

  /** Renders floating portal above everything */
  public displayFloatingPortal(onTap?: () => void, onClose?: () => void) {
    emitter.emit(setPortalEventName, {type: TopLevelPortalType.Floating, onTap, onClose});
    TopLevelPortalManager.getInstance().setCurrentPortal(TopLevelPortalType.Floating);
  }

  /** Renders fullscreen portal */
  public displayFullscreenPortal() {
    emitter.emit(setPortalEventName, {type: TopLevelPortalType.Fullscreen});
    TopLevelPortalManager.getInstance().setCurrentPortal(TopLevelPortalType.Fullscreen);
  }

  /** Renders null at top level */
  public hideTopLevelPortal() {
    emitter.emit(setPortalEventName, {type: TopLevelPortalType.None});
    TopLevelPortalManager.getInstance().setCurrentPortal(TopLevelPortalType.None);
  }
}

const initialMargin = dimensions.margins.medium;

export const TopLevelPortal: React.FunctionComponent<{}> = () => {
  const [portal, setPortal] = useState(TopLevelPortalType.None);
  const onTapFloater = useRef(() => {});
  const onCloseFloater = useRef(() => {});
  const currentMargin = useRef(0);
  const floaterRightMargin = useRef(new Animated.Value(initialMargin));
  const tabBarLayout = useContext(TabBarContext).layout;

  useEffect(() => () => {
    return setNativeFullScreenMode(false);
  }, []);

  useEffect(() => {
    Log.info(TAG, 'Portal set to:', portal);
    setNativeFullScreenMode(portal === TopLevelPortalType.Fullscreen);
  }, [portal]);

  useEffect(() => {
    floaterRightMargin.current.addListener(({value}) => {
      currentMargin.current = value;
    });
    return () => floaterRightMargin.current.removeAllListeners();
  }, []);

  const animateFloaterMarginToValue = (value: number) => {
    Animated.spring(floaterRightMargin.current, {toValue: value}).start();
  };

  const createPanResponder = () => PanResponder.create({
    onStartShouldSetPanResponder: () => true,
    onStartShouldSetPanResponderCapture: () => true,
    onMoveShouldSetPanResponder: () => true,
    onMoveShouldSetPanResponderCapture: () => true,
    onPanResponderTerminationRequest: () => true,
    onPanResponderTerminate: () => {},
    onPanResponderGrant: () => {
      floaterRightMargin.current.setOffset(currentMargin.current);
    },
    onPanResponderMove: (event, gestureState) => {
      floaterRightMargin.current.setValue(-gestureState.dx);
    },
    onPanResponderRelease: (event, gestureState) => {
      floaterRightMargin.current.flattenOffset();
      if (Math.abs(gestureState.dx) < 1) {
        // tap
        onTapFloater.current();
        return;
      }
      const screenWidth = Dimensions.get('window').width;
      // dynamic swipe left closes floating player
      if (gestureState.vx < -2 && gestureState.dx < screenWidth / 4) {
        onCloseFloater.current();
        animateFloaterMarginToValue(2 * screenWidth);
      } else {
        // snap to bottom right corner
        animateFloaterMarginToValue(dimensions.margins.medium);
      }
    }
  });

  const panResponder = useRef(createPanResponder());

  useEventListener(setPortalEventName, (event: any) => {
    const {type, onTap, onClose} = event;
    if (typeof onClose === 'function') {
      onCloseFloater.current = onClose;
    }
    if (typeof onTap === 'function') {
      onTapFloater.current = onTap;
    }
    if (type === TopLevelPortalType.None) {
      floaterRightMargin.current.setValue(initialMargin);
    }
    if (Object.values(TopLevelPortalType).includes(type)) {
      setPortal(type);
    }
  }, emitter);

  const {size: {width}} = useScreenInfo();

  const styles = stylesUpdater.getStyles();
  switch (portal) {
    case TopLevelPortalType.Floating:
      return (
        <Animated.View {...panResponder && panResponder.current.panHandlers} style={{borderWidth: 1, ...styles.portal, position: 'absolute', bottom: tabBarLayout.height + dimensions.margins.medium, ...dimensions.videoSize(width / 2), right: floaterRightMargin.current}}>
          <WhitePortal name={portal} />
        </Animated.View>
      );
    case TopLevelPortalType.Fullscreen:
      return (
        <View style={StyleSheet.absoluteFillObject} pointerEvents='box-none'>
          {!isBigScreen && <StatusBar hidden />}
          <WhitePortal name={portal} />
        </View>
      );
    default:
      return null;
  }
};
