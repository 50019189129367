import i18next from 'i18next';
import React, {useCallback, useState, ReactNode} from 'react';

import {isMobile, AppRoutes} from 'common/constants';
import {navigateToDestination} from 'common/HelperFunctions';
import {NavigationFocusState} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {Error, ErrorType} from 'mw/api/Error';
import {isChannel, Media} from 'mw/api/Metadata';
import {PlayerEvent} from 'mw/api/PlayerEvent';
import {mw, CatalogEvent} from 'mw/MW';

import ErrorPopup, {PopupError, useErrorPopup} from 'components/ErrorPopup';
import {IconType} from 'components/Icon';
import {useEventListener, useNavigation, useNavigationFocusState, useFunction} from 'hooks/Hooks';

const TAG = 'usePlayerErrorPopup';

type PlayerError = {
  title: string;
  subtitle?: boolean;
  message: string;
  icon?: IconType;
  extra?: boolean;
};

const playerErrorMap = new Map<ErrorType, PlayerError>([
  [ErrorType.PlaybackSessionError, {
    message: 'playbackError.accessDenied.message',
    title: 'playbackError.accessDenied.title'
  }],
  [ErrorType.NetworkRequestFailed, {
    message: 'playbackError.networkRequestFailed.message',
    title: 'playbackError.title',
    subtitle: true,
    icon: IconType.ErrorInternetConnection
  }],
  [ErrorType.HttpTimeout, {
    message: 'playbackError.httpTimeout.message',
    title: 'playbackError.title',
    subtitle: true,
    icon: IconType.ErrorInternetConnection
  }],
  [ErrorType.HttpNotFound, {
    message: 'playbackError.httpNotFound.message',
    title: 'playbackError.title',
    subtitle: true,
    icon: IconType.ErrorInternetConnection
  }],
  [ErrorType.BOServerError, {
    message: 'playbackError.noInternetConnection.message',
    title: 'playbackError.noInternetConnection.message',
    subtitle: true,
    icon: IconType.ErrorInternetConnection
  }],
  [ErrorType.PlaybackEntitlementIssue, {
    message: 'playbackError.noAuthorization.message',
    title: 'playbackError.noAuthorization.title',
    subtitle: true,
    icon: IconType.ErrorEntitlement
  }],
  [ErrorType.PlaybackLocationForbidden, {
    message: 'playbackError.locationForbidden.message',
    title: 'playbackError.locationForbidden.title',
    subtitle: true,
    icon: IconType.ErrorGeographic
  }],
  [ErrorType.PlaybackVPNForbidden, {
    message: 'playbackError.vpnForbidden.message',
    title: 'playbackError.vpnForbidden.title',
    subtitle: true,
    icon: IconType.ErrorConfiguration
  }],
  [ErrorType.PlaybackTooManyConcurrentSessions, {
    message: 'playbackError.concurrencyLimitation.message',
    title: 'playbackError.concurrencyLimitation.title',
    subtitle: true,
    icon: IconType.ErrorLimitation
  }],
  [ErrorType.NativePlayerNoDVBSignal, {
    message: 'playbackError.noSignal.message',
    title: 'playbackError.noSignal.title',
    icon: IconType.ErrorUnknown
  }],
  [ErrorType.PlaybackSessionInvalidContent, {
    message: 'playbackError.invalidContent.message',
    title: 'playbackError.invalidContent.title',
    subtitle: true,
    icon: IconType.ErrorAuthorizationLimitation
  }],
  [ErrorType.PlaybackSessionGeneralError, {
    message: 'playbackError.sessionGeneralError.message',
    title: 'playbackError.sessionGeneralError.title',
    subtitle: true,
    icon: IconType.ErrorAuthorizationLimitation
  }],
  [ErrorType.PlaybackSessionNoAsset, {
    message: 'playbackError.sessionNoAsset.message',
    title: 'playbackError.sessionNoAsset.title',
    subtitle: true,
    icon: IconType.ErrorAuthorizationLimitation
  }],
  [ErrorType.PlaybackSessionContentNotPlayable, {
    message: 'playbackError.sessionContentNotPlayable.message',
    title: 'playbackError.sessionContentNotPlayable.title',
    subtitle: true,
    icon: IconType.ErrorAuthorizationLimitation
  }],
  [ErrorType.PlaybackDrmSessionError, {
    message: 'playbackError.drmSessionError.message',
    title: 'playbackError.drmSessionError.title',
    subtitle: true,
    icon: IconType.ErrorEntitlement
  }],
  [ErrorType.PlaybackSourceMissing, {
    message: 'playbackError.sourceMissing.message',
    title: 'playbackError.sourceMissing.title',
    subtitle: true,
    icon: IconType.ErrorAuthorizationLimitation
  }],
  [ErrorType.PlaybackSourceError, {
    message: 'playbackError.sourceError.message',
    title: 'playbackError.sourceError.title',
    subtitle: true,
    icon: IconType.ErrorInternetConnection
  }],
  [ErrorType.PlaybackSourceCorrupted, {
    message: 'playbackError.sourceCorrupted.message',
    title: 'playbackError.sourceCorrupted.title',
    subtitle: true,
    icon: IconType.ErrorAuthorizationLimitation
  }],
  [ErrorType.PlaybackChannelNotAvailable, {
    message: 'playbackError.channelNotAvailable.message',
    title: 'playbackError.channelNotAvailable.title',
    subtitle: true,
    icon: IconType.ErrorGeographic
  }],
  [ErrorType.PlaybackNoChannelsAvailable, {
    message: 'playbackError.noChannelsAvailable.message',
    title: 'playbackError.noChannelsAvailable.title',
    subtitle: true,
    icon: IconType.ErrorGeographic
  }],
  [ErrorType.PlaybackSessionNoContent, {
    message: 'playbackError.sessionNoContent.message',
    title: 'playbackError.sessionNoContent.title',
    subtitle: true,
    icon: IconType.ErrorUnknown
  }],
  [ErrorType.DrmLicenseServerGeoblocking, {
    message: 'drmLicenseServerError.geoblocking',
    title: 'drmLicenseServerError.title',
    subtitle: true,
    icon: IconType.ErrorGeographic
  }],
  [ErrorType.DrmLicenseServerUnavailable, {
    message: 'drmLicenseServerError.unavailable',
    title: 'drmLicenseServerError.title',
    subtitle: true,
    icon: IconType.ErrorInternetConnection
  }],
  [ErrorType.DrmLicenseServerUnknownContent, {
    message: 'drmLicenseServerError.unknownContent',
    title: 'drmLicenseServerError.title',
    subtitle: true,
    icon: IconType.ErrorEntitlement
  }],
  [ErrorType.DrmLicenseServerInvalidSession, {
    message: 'drmLicenseServerError.invalidSession',
    title: 'drmLicenseServerError.title',
    subtitle: true,
    icon: IconType.ErrorEntitlement
  }],
  [ErrorType.DrmLicenseServerDeviceLimitReached, {
    message: 'drmLicenseServerError.deviceLimitReached',
    title: 'drmLicenseServerError.title',
    subtitle: true,
    icon: IconType.ErrorEntitlement
  }],
  [ErrorType.DrmLicenseServerEntitlementError, {
    message: 'drmLicenseServerError.entitlementError',
    title: 'drmLicenseServerError.title',
    subtitle: true,
    icon: IconType.ErrorEntitlement
  }],
  [ErrorType.DrmLicenseServerPreconditionFailed, {
    message: 'drmLicenseServerError.preconditionFailed',
    title: 'drmLicenseServerError.title',
    subtitle: true,
    icon: IconType.ErrorEntitlement
  }],
  [ErrorType.DrmDvbNoAcces, {
    message: 'playbackError.noAccess.message',
    title: 'playbackError.noAccess.title',
    subtitle: true,
    icon: IconType.ErrorEntitlement,
    extra: true
  }]
]);

function getPlaybackPopupError(error: Error): PopupError | null {
  const playerError = playerErrorMap.get(error.type);
  if (!playerError) {
    return null;
  }
  return {
    message: i18next.t(playerError.message),
    title: i18next.t(playerError.title),
    subtitle: playerError.subtitle ? i18next.t('common.errorCode', {errorCode: `${error.type}${(playerError.extra && error.extra) ? ` - ${error.extra}` : ''}`}) : undefined,
    icon: playerError.icon
  };
}

type UsePlayerErrorPopup = {
  playbackError?: Error;
  clearPlaybackError: () => void;
  renderPlaybackErrorPopups: () => ReactNode;
};

export type PlayerErrorPopupHandlers = {
  [error in ErrorType]?: () => void;
};

export function usePlayerErrorPopup(customErrorHandlers?: PlayerErrorPopupHandlers): UsePlayerErrorPopup {
  const navigation = useNavigation();
  const navigationFocusState = useNavigationFocusState(navigation);
  const [playbackError, setPlaybackError] = useState<Error | undefined>(undefined);
  const {error, showError, onCloseErrorPopup: closeErrorPopup} = useErrorPopup();

  const clearPlaybackError = useCallback(() => {
    setPlaybackError(undefined);
  }, []);

  const handlePlaybackError = useCallback((error: Error) => {
    if (navigationFocusState !== NavigationFocusState.IsFocused) {
      return;
    }
    Log.error(TAG, 'Player error', error);
    const playbackPopupError = getPlaybackPopupError(error);
    if (playbackPopupError) {
      setPlaybackError(error);
      showError(playbackPopupError);
    }
  }, [navigationFocusState, showError]);

  const handleMediaNotFound = useCallback((media?: Media) => {
    if (!isChannel(media)) {
      return;
    }
    const errorType = mw.catalog.getSortedChannelsIds().length
      ? ErrorType.PlaybackChannelNotAvailable
      : ErrorType.PlaybackNoChannelsAvailable;
    handlePlaybackError(new Error(errorType));
  }, [handlePlaybackError]);

  const handlePlayerReady = useCallback(() => {
    if (navigationFocusState !== NavigationFocusState.IsFocused) {
      return;
    }

    if (playbackError?.type === ErrorType.DrmDvbNoAcces) {
      // Thats special case when DRM DVB No Access error occured and later user was granted rights to watch it.
      // Playback is started over and DRM DVB No Access error should disappear.
      closeErrorPopup();
      clearPlaybackError();
    }
  }, [navigationFocusState, playbackError, clearPlaybackError, closeErrorPopup]);

  useEventListener(PlayerEvent.Error, handlePlaybackError, mw.players.main);
  useEventListener(CatalogEvent.MediaNotFound, handleMediaNotFound, mw.catalog);
  useEventListener(PlayerEvent.Ready, handlePlayerReady, mw.players.main);

  const defaultErrorHandler = useCallback((errorType?: ErrorType) => {
    switch (errorType) {
      case ErrorType.PlaybackLocationForbidden:
      case ErrorType.PlaybackVPNForbidden:
        if (isMobile) {
          navigateToDestination(navigation, 'PreviousRoute');
        }
        break;

      case ErrorType.PlaybackNoChannelsAvailable:
        navigateToDestination(navigation, AppRoutes.Home);
        break;
    }
  }, [navigation]);

  const onCloseErrorPopup = useFunction(() => {
    closeErrorPopup();
    const type = playbackError?.type;
    const handler = type && customErrorHandlers?.[type] || defaultErrorHandler;
    handler?.(type);
  });

  const renderPlaybackErrorPopups = useCallback(() => (
    <ErrorPopup error={error} onClose={onCloseErrorPopup} />
  ), [error, onCloseErrorPopup]);

  return {playbackError, clearPlaybackError, renderPlaybackErrorPopups};
}
