export type OrderData = {
  id: string;
  mediaId: string;
}

export default class OrderDataSet {
  private readonly orders = new Map<string, OrderData>();
  private readonly mediaIds = new Set<string>();

  public add(order: OrderData): void {
    this.orders.set(order.id, {id: order.id, mediaId: order.mediaId});
    this.mediaIds.add(order.mediaId);
  }

  public hasOrderId(orderId: string): boolean {
    return this.orders.has(orderId);
  }

  public removeByOrderId(orderId: string): void {
    const order = this.orders.get(orderId);
    if (!order) {
      return;
    }
    this.orders.delete(orderId);
    if (!Array.from(this.orders.values()).some(o => o.mediaId === order.mediaId)) {
      this.mediaIds.delete(order.mediaId);
    }
  }

  public hasMediaId(mediaId: string): boolean {
    return this.mediaIds.has(mediaId);
  }

  public removeByMediaId(mediaId: string): void {
    if (!this.hasMediaId(mediaId)) {
      return;
    }
    Array.from(this.orders.values())
      .filter(order => order.mediaId === mediaId)
      .forEach(order => this.orders.delete(order.id));
    this.mediaIds.delete(mediaId);
  }
}
