import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions, tabBarInsetCorrection} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {ChromecastConnectionState} from 'mw/api/Metadata';

import NitroxText from 'components/NitroxText';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  text: {
    color: colors.popup.text,
    alignSelf: 'center'
  },
  container: {
    width: '100%',
    padding: dimensions.margins.large,
    display: 'flex',
    justifyContent: 'center',
    height: dimensions.chromecast.statusBar.height,
    backgroundColor: colors.chromecast.remoteController.container.background
  }
}));

const styles = createStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    position: 'absolute',
    bottom: dimensions.chromecast.remoteControl.wrapper.bottom + tabBarInsetCorrection
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
  }
});

type Props = {
  deviceName: string;
  connectionState: ChromecastConnectionState;
};

const ChromecastStatusBar: React.FunctionComponent<Props> = ({connectionState, deviceName}) => {
  const dynamicStyle = stylesUpdater.getStyles();
  const {t} = useTranslation();
  const connectionStateLabel = t(connectionState === ChromecastConnectionState.Connecting ? 'chromecast.remoteControl.connecting' : 'chromecast.remoteControl.mediaSending');

  const textStyle = useMemo(() => {
    return [dynamicStyle.text, connectionState === ChromecastConnectionState.Connecting && {paddingRight: dimensions.buttons.large}];
  }, [dynamicStyle.text, connectionState]);

  return (
    <View style={styles.wrapper}>
      <View style={dynamicStyle.container}>
        <View style={styles.row}>
          <NitroxText numberOfLines={1} style={textStyle}>
            {connectionStateLabel}
            {deviceName}
          </NitroxText>
        </View>
      </View>
    </View>
  );
};

export default React.memo(ChromecastStatusBar);
