import React, {useCallback, useEffect, useState, useContext} from 'react';
import {useTranslation} from 'react-i18next';

import {addToWatchList, openMediaDetails, removeFromWatchList} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {Event, isEvent, Media} from 'mw/api/Metadata';

import {IconType} from 'components/Icon';
import MoreActionsPopup, {MoreActionsAction} from 'components/MoreActionsPopup';
import {useRecord} from 'components/pvr/Record';
import {RecordActionType} from 'components/pvr/RecordingUtils';
import {useDisposable, useNavigation} from 'hooks/Hooks';
import {ViewMode} from 'screens/tv/TvScreen';

import {ChromecastContext} from './ChromecastContext';
import {usePlayerLauncher} from './playerLauncher/PlayerLauncher';

const TAG = 'MediaMoreActionsPopup';

type Props = {
  media?: Media;
  onClose: () => void;
}

const MediaMoreActionsPopup: React.FC<Props> = props => {
  const {media, onClose} = props;
  const {t} = useTranslation();
  const navigation = useNavigation();
  const {renderRecordingsComponents, scheduleRecording, getRecordActionTypeForEvent} = useRecord();
  const [moreActions, setMoreActions] = useState<MoreActionsAction[]>([]);
  const [loading, setLoading] = useState(false);
  const [schedulingRecording, setSchedulingRecording] = useState(false);
  const {startPlayback} = usePlayerLauncher();
  const {isChromecastConnected} = useContext(ChromecastContext);

  const refreshActions = useCallback((media: Media, recordActionType: RecordActionType) => {

    const moreInfoAction: MoreActionsAction = {
      key: 'moreInfo',
      text: t('search.moreInfo'),
      icon: IconType.MoreInfo,
      onPress: () => {
        if (navigation) {
          openMediaDetails(navigation, media.id, media.getType());
        }
        onClose();
      }
    };

    const actions = [moreInfoAction];

    if (media.isAllowedOnWatchList()) {
      if (!media.isOnWatchList) {
        const addToWatchListAction: MoreActionsAction = {
          key: 'addToWatchList',
          icon: IconType.Add,
          text: t('mediaPlayer.addToWatchList'),
          onPress: () => {
            addToWatchList(media);
            onClose();
          }
        };

        actions.push(addToWatchListAction);
      } else {
        const removeFromWatchListAction: MoreActionsAction = {
          key: 'removeFromWatchList',
          icon: IconType.Remove,
          text: t('mediaPlayer.removeFromWatchList'),
          onPress: () => {
            removeFromWatchList([media]);
            onClose();
          }
        };

        actions.push(removeFromWatchListAction);
      }
    }

    if (isEvent(media)) {
      const onRecordActionPress = () => {
        setSchedulingRecording(true);
        scheduleRecording(media)
          .finally(() => {
            // onClose() must be called first because scheduleRecording() may show error popup after Promise has been rejected.
            // This will prevent displaying more than one popup at once.
            onClose();
            setSchedulingRecording(false);
          });
      };
      const recordAction: MoreActionsAction = {
        key: 'record',
        text: t('search.record'),
        icon: IconType.RecordDot,
        onPress: onRecordActionPress
      };
      const recordSeriesAction: MoreActionsAction = {
        key: 'recordSeries',
        text: t('search.recordSeries'),
        icon: IconType.RecordSeries,
        onPress: onRecordActionPress
      };
      const recordAllAction: MoreActionsAction = {
        key: 'recordAll',
        text: t('search.recordAll'),
        icon: IconType.RecordSeries,
        onPress: onRecordActionPress
      };

      // use only allowed event-related actions in proper order
      switch (recordActionType) {
        case RecordActionType.record:
          actions.push(recordAction);
          break;
        case RecordActionType.recordSeries:
          actions.push(recordSeriesAction);
          break;
        case RecordActionType.recordAll:
          actions.push(recordAllAction);
          break;
      }

      if (media.hasTstv) {
        const restartAction: MoreActionsAction = {
          key: 'restart',
          text: isEvent(media) && media.isPast ? t('search.goBack') : t('search.restart'),
          icon: IconType.Restart,
          onPress: () => {
            startPlayback(isChromecastConnected ? {media} : {
              tvScreenParams: {
                eventId: media.id,
                channelId: undefined,
                viewMode: ViewMode.OneChannelEpg
              }
            });
            onClose();
          }
        };

        actions.push(restartAction);
      }
    }

    setMoreActions(actions);
  }, [t, navigation, onClose, scheduleRecording, startPlayback, isChromecastConnected]);

  const getRecordActionType = useDisposable((event: Event) => getRecordActionTypeForEvent(event), [getRecordActionTypeForEvent]);

  useEffect(() => {
    if (!media) {
      return;
    }

    if (!isEvent(media)) {
      refreshActions(media, RecordActionType.none);
      return;
    }

    setLoading(true);
    getRecordActionType(media)
      .then(recordActionType => refreshActions(media, recordActionType))
      .catch(error => {
        Log.error(TAG, `Error getting record action type for event ${media.id}`, error);
        refreshActions(media, RecordActionType.none);
      })
      .finally(() => setLoading(false));
  }, [media, refreshActions, getRecordActionType]);

  return (
    <>
      <MoreActionsPopup
        visible={!!media && !schedulingRecording}
        loading={loading}
        actions={moreActions}
        onClose={onClose}
      />
      {renderRecordingsComponents()}
    </>
  );
};

export default React.memo(MediaMoreActionsPopup);
