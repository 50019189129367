import React, {useState, useCallback} from 'react';
import {StyleSheet} from 'react-native';

import {delay} from 'common/Async';
import {Direction, isDesktopBrowser} from 'common/constants';
import {Log} from 'common/Log';
import TestContext from 'common/TestContext';

import {Channel, Event, ContentType} from 'mw/api/Metadata';
import {mw} from 'mw/MW';
import {RewindDirection} from 'mw/playback/Player';

import {If, Case, Switch} from 'components/Conditionals';
import FullScreenTouchableView from 'components/fullscreen/FullScreenTouchableView';
import {NativeKeyEvent, SupportedKeys} from 'components/KeyEventManager';
import {useSTBMenu} from 'components/navigation/STBNavigationView';
import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';
import {PlayerControlsHandlers, PlayerControlsViewButtonsVisibility} from 'components/player/PlayerControlsView';
import {PlayerManager} from 'components/player/PlayerManager';
import {useChangeEffect, useKeyEventHandler, useFunction, useThrottle} from 'hooks/Hooks';
import {useArrowsListener, useChannelKeysListener, useKeyListener} from 'hooks/rcuHooks';
import {tvScreenConstants} from 'screens/tv/TvScreenConstants';
import {useRemoteControl} from 'screens/tv/TvScreenHooks';

import ChannelListOverlay from './channelList/ChannelListOverlay.grosso';
import ChannelNumberInputOverlay from './ChannelNumberInputOverlay';
import ParentalOverlay from './ParentalOverlay.grosso';
import TrickplayOverlay from './TrickplayOverlay.grosso';
import TvProgressBar from './TvProgressBar';
import ZapOverlay from './ZapOverlay.grosso';

const TAG = 'TvOverlay.grosso';

const testIdContext = {
  Modal: 'modal_parental_control'
};

enum TvOverlayState {
  Zap,
  Trickplays,
  Epg,
  ChannelNumberInput,
  ChannelList
}

type TvOverlayProps = {
  onZap: (direction: Direction) => void;
  tuneChannel: (channel: Channel) => Promise<void>;
  showOverlay: () => void;
  hideOverlay: () => void;
  setPreventHidingOverlay: (value: boolean) => void;
  exitTstv: () => void;
  playerControlsHandlers: PlayerControlsHandlers;
  buttonsVisibility: PlayerControlsViewButtonsVisibility;
  paused: boolean;
  rewindDirection?: RewindDirection;
  visible: boolean;

  channels: Channel[];
  channel?: Channel;
  event?: Event;
  tuning?: boolean;

  playbackError?: boolean;
};
const TvOverlay: React.FC<TvOverlayProps> = props => {
  const {
    visible,
    onZap,
    showOverlay,
    setPreventHidingOverlay,
    playerControlsHandlers,
    buttonsVisibility,
    paused,
    rewindDirection,
    channel,
    channels,
    tuneChannel,
    event,
    playbackError,
    exitTstv,
    tuning
  } = props;
  const stbMenu = useSTBMenu();
  const {isMediaBlocked, shouldBeCheckedForPC} = useParentalControl();
  const isBlocked = shouldBeCheckedForPC(event) && isMediaBlocked(event);

  const [overlayState, setOverlayState] = useState(TvOverlayState.Zap);
  useChangeEffect(() => {
    if (!visible) {
      setOverlayState(TvOverlayState.Zap);
    }
  }, [visible]);

  useArrowsListener(async () => {
    if (!visible) {
      // postpone key event handling to prevent races between overlay showing & arrow handling inside particular overlays
      // e.g. ChannelListOverlay scrolls immediately after shown on web & tvos if no timeout is used here
      await delay(0);
    }
    if (overlayState === TvOverlayState.Zap) {
      setOverlayState(TvOverlayState.ChannelList);
    }
    showOverlay();
  }, [Direction.Up, Direction.Down], {active: !stbMenu?.hasVisibleModal, handleTVOSPanGesture: true});

  useChannelKeysListener(
    useThrottle(direction => {
      onZap(direction);
      // If on channel list, then extend overlay timer, elseway show zap overlay
      if (overlayState !== TvOverlayState.ChannelList) {
        setOverlayState(TvOverlayState.Zap);
      }
      cancelInput();
      showOverlay();
    }, tvScreenConstants.channelKeyThrottle)
  );

  useArrowsListener(direction => {
    const playbackLimitations = mw.players.main.getPlaybackLimitations();
    switch (direction) {
      case Direction.Left: {
        if (!playbackLimitations.allowSkipBackward) {
          return;
        }
        if (overlayState === TvOverlayState.Zap) {
          playerControlsHandlers.skipBackHandler?.();
          return;
        }
        break;
      }
      case Direction.Right: {
        if (!playbackLimitations.allowSkipForward) {
          return;
        }
        if (overlayState === TvOverlayState.Zap) {
          playerControlsHandlers.skipForwardHandler?.();
          return;
        }
        break;
      }
    }
    showOverlay();
  }, [Direction.Left, Direction.Right], {active: !stbMenu?.hasVisibleModal});

  const tuneChannelNumber = useFunction(async (channelNumber: number): Promise<void> => {
    const newChannel = channels.find(c => c.lcn === channelNumber);
    if (!newChannel || newChannel.id === channel?.id) {
      return;
    }
    await tuneChannel(newChannel);
  });
  const {channelNumberString, cancelInput} = useRemoteControl(tuneChannelNumber);

  useChangeEffect(() => {
    if (overlayState !== TvOverlayState.ChannelNumberInput && channelNumberString) {
      setPreventHidingOverlay(true);
      setOverlayState(TvOverlayState.ChannelNumberInput);
    }
  }, [channelNumberString], [overlayState]);

  const onFinishedTransition = useCallback((channelInputHidden: boolean) => {
    if (channelInputHidden) {
      setPreventHidingOverlay(false);
      setOverlayState(TvOverlayState.Zap);
    }
  }, [setPreventHidingOverlay]);

  useKeyEventHandler('keyup', (event: NativeKeyEvent) => {
    if (stbMenu?.hasFocus || stbMenu?.hasVisibleModal || isBlocked) {
      return;
    }
    const {
      allowPause,
      allowFastForward,
      allowRewind
    } = mw.players.main.getPlaybackLimitations();
    switch (event.key) {
      case SupportedKeys.Ok: {
        if (overlayState === TvOverlayState.Zap) {
          setOverlayState(TvOverlayState.Trickplays);
        }
        if (overlayState === TvOverlayState.ChannelNumberInput) {
          cancelInput();
        }
        break;
      }
      case SupportedKeys.PlayPause: {
        if (!allowPause) {
          return;
        }
        playerControlsHandlers.playPauseHandler?.();
        if (overlayState === TvOverlayState.Zap) {
          setOverlayState(TvOverlayState.Trickplays);
        }
        break;
      }
      case SupportedKeys.Play: {
        if (!allowPause && !paused) {
          return;
        }
        if (paused) {
          playerControlsHandlers.playPauseHandler?.();
          if (overlayState === TvOverlayState.Zap) {
            setOverlayState(TvOverlayState.Trickplays);
          }
        }
        break;
      }
      case SupportedKeys.Pause: {
        if (!allowPause) {
          return;
        }
        if (!paused) {
          playerControlsHandlers.playPauseHandler?.();
          if (overlayState === TvOverlayState.Zap) {
            setOverlayState(TvOverlayState.Trickplays);
          }
        }
        break;
      }
      case SupportedKeys.Stop: {
        if (mw.players.main.contentType === ContentType.LIVE) {
          return;
        } else if (mw.players.main.contentType === ContentType.TSTV) {
          exitTstv();
          if (overlayState === TvOverlayState.Zap) {
            setOverlayState(TvOverlayState.Trickplays);
          }
        } else if (mw.players.main.contentType === ContentType.NPLTV) {
          playerControlsHandlers.goToLiveHandler?.();
          if (overlayState === TvOverlayState.Zap) {
            setOverlayState(TvOverlayState.Trickplays);
          }
        }
        break;
      }
      case SupportedKeys.FastForward: {
        if (!allowFastForward) {
          return;
        }
        playerControlsHandlers.fastForwardHandler?.();
        if (overlayState === TvOverlayState.Zap) {
          setOverlayState(TvOverlayState.Trickplays);
        }
        break;
      }
      case SupportedKeys.Rewind: {
        if (!allowRewind) {
          return;
        }
        playerControlsHandlers.fastBackwardsHandler?.();
        if (overlayState === TvOverlayState.Zap) {
          setOverlayState(TvOverlayState.Trickplays);
        }
        break;
      }
      case SupportedKeys.Record: {
        if (!buttonsVisibility.recordDot) {
          return;
        }
        playerControlsHandlers.recordDotHandler?.(buttonsVisibility.recordDot);
        if (overlayState === TvOverlayState.Zap) {
          setOverlayState(TvOverlayState.Trickplays);
        }
        break;
      }
      case SupportedKeys.Back: {
        cancelInput();
        if (!!rewindDirection) {
          playerControlsHandlers.playPauseHandler?.();
        }
        // do not handle this here, indeterministic races with hardwareBackPress
        return;
      }
      // handled in useArrowsListener
      case SupportedKeys.Up:
      case SupportedKeys.Down:
      case SupportedKeys.Left:
      case SupportedKeys.Right: {
        return;
      }
      case SupportedKeys.Subtitles:
        if (!buttonsVisibility.playback.subtitles) {
          return;
        }
        playerControlsHandlers.subtitleHandler?.();
        if (overlayState === TvOverlayState.Zap) {
          setOverlayState(TvOverlayState.Trickplays);
        }
        break;
      case SupportedKeys.Info:
        if (overlayState === TvOverlayState.ChannelList) {
          return;
        }
        playerControlsHandlers.moreInfoHandler?.();
        break;
    }
    showOverlay();
  });

  useKeyListener(SupportedKeys.LastChannel, () => {
    Log.info(TAG, 'onLastChannel');
    if (overlayState === TvOverlayState.ChannelNumberInput) {
      cancelInput();
    }

    const channel = PlayerManager.getInstance().getLastChannel();
    channel && tuneChannel(channel);
  });

  const onBackgroundPress = useFunction(() => {
    showOverlay();
    if (overlayState === TvOverlayState.Zap) {
      setOverlayState(TvOverlayState.Trickplays);
    }
  });

  const parentalBlocked = !tuning && isBlocked;

  return (
    <FullScreenTouchableView
      disabled={!isDesktopBrowser}
      style={StyleSheet.absoluteFillObject}
      onPress={onBackgroundPress}
    >
      <If
        condition={
          parentalBlocked &&
          // lets user zap out of blocked channel
          (overlayState !== TvOverlayState.ChannelList)
        }
      >
        <TestContext.Provider value={testIdContext}>
          <ParentalOverlay
            event={event}
            channel={channel}
          />
        </TestContext.Provider>
      </If>
      <ChannelListOverlay
        channel={channel}
        channels={channels}
        event={event}
        onChannelPress={tuneChannel}
        showOverlay={showOverlay}
        visible={visible && !stbMenu?.hasVisibleModal && (overlayState === TvOverlayState.ChannelList)}
      />
      <If condition={visible && !parentalBlocked}>
        <Switch expression={overlayState}>
          <Case value={TvOverlayState.ChannelNumberInput}>
            <ChannelNumberInputOverlay
              channelNumberString={channelNumberString}
              onFinishedTransition={onFinishedTransition}
              channel={channel}
            />
          </Case>
          <Case value={TvOverlayState.Zap}>
            <ZapOverlay
              channel={channel}
              event={event}
            />
          </Case>
          <Case value={TvOverlayState.Trickplays}>
            <TrickplayOverlay
              playerControlsHandlers={playerControlsHandlers}
              buttonsVisibility={buttonsVisibility}
              paused={paused}
              rewindDirection={rewindDirection}
              channel={channel}
              event={event}
              progressBar={<TvProgressBar event={event} buttonsVisibility={buttonsVisibility} />}
              playbackError={playbackError}
            />
          </Case>
        </Switch>
      </If>
    </FullScreenTouchableView>
  );
};

export default React.memo(TvOverlay);
