import React, {useContext, useCallback} from 'react';
import {View, ImageURISource} from 'react-native';
// eslint-disable-next-line no-restricted-imports
import 'react-native-gesture-handler';

import {createBottomTabNavigator, NavigationRouteConfigMap, TabRouter, createNavigator, createStackNavigator, BottomTabBar, BottomTabBarProps, NavigationScreenProp, NavigationRoute, NavigationParams} from 'react-navigation';

import {dimensions, isMobile, AppRoutes, isIOS, isTablet, isBigScreen, isWeb, isMobileBrowser, tabBarInsetCorrection} from 'common/constants';
import {humanCaseToSnakeCase} from 'common/HelperFunctions';
import {Hashmap} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {CustomMenuItemRoute} from 'mw/cms/CMS';
import {Menu, Link, MenuType, MenuAlignment} from 'mw/cms/Menu';
import {mw} from 'mw/MW';

import {createStyles} from 'common-styles';

import {IconType} from 'components/Icon';
import MainMenuIcon from 'components/navigation/MainMenuIcon';
import MobileHeaderBackButton from 'components/navigation/MobileHeaderBackButton';
import {getScreenForRoute, routeNameForMenu, getRouteInitialParams, useShouldRenderMenu, useFilteredRoutes} from 'components/navigation/NavigationHelperFunctions';
import {TabBarContext, CustomMenuItemHandlers} from 'components/navigation/NavigationHelperTypes';
import {STBMenuProps} from 'components/navigation/STBMenu';
import STBNavigationView from 'components/navigation/STBNavigationView';
import NitroxText from 'components/NitroxText';

const styles = createStyles({
  tab: {
    height: '100%',
    alignItems: 'center',
    flex: 1
  },
  tabBarLabel: {
    height: '100%',
    flexDirection: isTablet ? 'row' : 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    paddingVertical: 6
  },
  icon: {
    width: dimensions.icon.xsmall,
    height: dimensions.icon.xsmall
  }
});

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  tabBarComponent: {
    paddingHorizontal: isTablet ? dimensions.margins.xxLarge : dimensions.margins.large,
    backgroundColor: colors.mainMenu.background
  },
  tabBar: {
    justifyContent: 'center',
    backgroundColor: colors.mainMenu.background,
    ...!isWeb && {
      marginBottom: -tabBarInsetCorrection
    }
  },
  text: {
    marginLeft: 1,
    marginRight: 1,
    color: colors.mainMenu.button.text.active,
    ...isTablet && {
      marginLeft: dimensions.margins.medium
    }
  },
  tabBarActive: {
    backgroundColor: colors.mainMenu.button.icon.focused
  },
  tabBarInactive: {
    backgroundColor: colors.mainMenu.button.icon.unfocused
  },
  iconColor: colors.defaultColors.icon
}));

export const defaultMenuIcons = {
  home: {
    active: IconType.HomeSidebar,
    inactive: IconType.HomeSidebar
  },
  zapper: {
    active: IconType.LiveTvSidebar,
    inactive: IconType.LiveTvSidebar
  },
  settings: {
    active: IconType.SettingsSidebar,
    inactive: IconType.SettingsSidebar
  },
  epg: {
    active: IconType.EpgSidebarActive,
    inactive: IconType.EpgSidebar
  },
  vod: {
    active: IconType.VodSidebar,
    inactive: IconType.VodSidebar
  },
  search: {
    active: IconType.SearchSidebar,
    inactive: IconType.SearchSidebar
  },
  pvr: {
    active: IconType.PvrSidebar,
    inactive: IconType.PvrSidebar
  },
  watchlist: {
    active: IconType.WatchListSidebar,
    inactive: IconType.WatchListSidebar
  },
  launcherNotifications: {
    active: IconType.NotificationsSidebar,
    inactive: IconType.NotificationsSidebar
  },
  launcherAppsGames: {
    active: IconType.ApplicationsSidebar,
    inactive: IconType.ApplicationsSidebar
  },
  quit: {
    active: IconType.Close,
    inactive: IconType.Close
  },
  [CustomMenuItemRoute.VoucherRedemption]: {
    active: IconType.VoucherSidebar,
    inactive: IconType.VoucherSidebar
  }
};

// slight redundant with above, but stacks may overlap, keep it simple then
// 'route' is always stack head, so only tail needs to be returned
// 'routes' is all routes, also containing 'route'
function getStackTail(route: string, routes: AppRoutes[]) {
  const details = [AppRoutes.MediaDetail, AppRoutes.MediaPlayer, AppRoutes.MediaGrid];
  const settingsSections = isMobile ? [
    AppRoutes.SettingsCustomer,
    AppRoutes.SettingsAppVersion,
    AppRoutes.SettingsBackOffice,
    AppRoutes.SettingsDeleteProfile,
    AppRoutes.CreateProfileWizard,
    AppRoutes.SettingsParentalControl,
    AppRoutes.SettingsRegisteredDevices,
    AppRoutes.SettingsLanguage,
    AppRoutes.SettingsTimeAndDate,
    AppRoutes.SettingsPin,
    AppRoutes.SettingsName,
    AppRoutes.SettingsAccount,
    AppRoutes.SettingsHelp
  ] : [...details];

  const commonScreens = isBigScreen
    ? [AppRoutes.CreateProfileWizard]
    : [];

  switch (route) {
    case AppRoutes.Credentials:
      return [AppRoutes.Registration, ...commonScreens];
    case AppRoutes.Settings:
      return [...settingsSections, ...commonScreens];
    case AppRoutes.Home:
      const watchList = routes.includes(AppRoutes.WatchList) ? [] : [AppRoutes.WatchList];
      // if 'settings' is already in root routes, then we should navigate to it as tab, keeping consistent navigation
      if (routes.includes(AppRoutes.Settings)) {
        return [...details, ...watchList, ...commonScreens];
      }
      return [AppRoutes.Settings, ...settingsSections, ...details, ...watchList, ...commonScreens];
    case AppRoutes.Zapper:
      if (routes.includes(AppRoutes.Epg)) {
        return [...details, ...commonScreens];
      }
      return [AppRoutes.Epg, ...details, ...commonScreens];
    case AppRoutes.Epg:
      return [AppRoutes.SearchResultFull, AppRoutes.ChannelDetail, ...details, ...commonScreens];
    case AppRoutes.Search:
      const zapper = isMobile ? [AppRoutes.Zapper] : [];
      return [AppRoutes.SearchResultFull, AppRoutes.ChannelDetail, ...zapper, ...details, ...commonScreens];
    case AppRoutes.Pvr:
      return [AppRoutes.RecordingsFolder, ...details, ...commonScreens];
    case AppRoutes.RecordingsFolder:
    case AppRoutes.SearchResultFull:
    case AppRoutes.WatchList:
      return [...details, ...commonScreens];
    case AppRoutes.Vod:
      return [AppRoutes.VodCategory, AppRoutes.ChannelDetail, ...details, ...commonScreens];
    case AppRoutes.WatchList:
      return [AppRoutes.WatchList, ...details, ...commonScreens];
    default:
      return commonScreens;
  }
}

function getStackNavigator(initialRoute: string, params: NavigationParams, screens: {screenType: string}[]) {
  const stack = [initialRoute, ...getStackTail(initialRoute, screens.map(screen => screen.screenType as AppRoutes))];
  const routes = stack.reduce((routesMap: {[key: string]: any}, route) => {
    routesMap[route] = getScreenForRoute(route);
    return routesMap;
  }, {});

  return createStackNavigator({
    ...routes
  }, {
    initialRouteName: initialRoute,
    initialRouteParams: params,
    headerMode: (isMobile && !isMobileBrowser) ? 'screen' : 'none',
    headerBackTitleVisible: false,
    // Setting this to 'true' breaks RNSScreen & RNSScreenContainer
    //// transparent card is treated as active by react-navigation-stack
    //// RNSScreenContainer disables user interactions until active "Screen" count inside "ScreenContainer" reaches 1
    //// therefore, having >1 transparent cards in stack breaks RCU navigation.
    // Cards' transparency is set via react-navigation-stack patch.
    transparentCard: false,
    defaultNavigationOptions: {
      gesturesEnabled: isMobile,
      headerTransparent: true,
      ...isMobile ? {
        headerBackImage: <MobileHeaderBackButton />,
        ...isIOS && {headerStyle: {width: 0, overflow: 'visible', height: dimensions.screen.header.height}}
      } : {
        headerStyle: {height: 0}
      }
    }
  });
}

const TabBarComponent = ({navigation, ...otherProps}: BottomTabBarProps) => {
  const tabBarContext = useContext(TabBarContext);
  const onLayout = useCallback(({nativeEvent: {layout}}) => tabBarContext.onChange && tabBarContext.onChange(layout), [tabBarContext]);
  const filteredRoutes = useFilteredRoutes(navigation.state.routes);

  const dynamicStyles = dynamicStylesUpdater.getStyles();
  return useShouldRenderMenu() ? (
    <View testID='menubar' onLayout={onLayout} style={dynamicStyles.tabBarComponent}>
      <BottomTabBar
        {...otherProps}
        navigation={{
          ...navigation,
          state: {
            ...navigation.state,
            routes: filteredRoutes
          }
        }}
        adaptive={false}
      />
    </View>
  ) : null;
};

function tabBarOnPress(customItemsHandlers?: CustomMenuItemHandlers) {
  return (options: {navigation: NavigationScreenProp<NavigationRoute>; defaultHandler: () => void}) => {
  // NOTE: there's an issue with popToTop(), see: https://github.com/react-navigation/react-navigation/issues/3449
    const route = options.navigation.state.routes[0];
    if (customItemsHandlers) {
      switch (route.routeName) {
        case CustomMenuItemRoute.VoucherRedemption:
          customItemsHandlers[CustomMenuItemRoute.VoucherRedemption]();
          return;
      }
    }
    const params = getRouteInitialParams(route.routeName);
    if (params) {
      options.navigation.navigate({...route, params: {...route.params, ...params}});
    } else {
      options.navigation.navigate(route);
    }
  };
}

export const getMenuIcon = ({pictures, screen}: Menu): MenuIcon => {
  const activeIconUrl = pictures.find(el => el.type === 'logo')?.url;
  if (!activeIconUrl) {
    return ({svgType: defaultMenuIcons[screen as keyof typeof defaultMenuIcons]});
  }
  const inactiveIconUrl = pictures.find(el => el.type === 'logo-inactive')?.url;
  return ({
    pngUrl: {
      active: {uri: activeIconUrl},
      inactive: {uri: inactiveIconUrl || activeIconUrl}
    }
  });
};

type Screen = {
  route: string;
  screenType: string;
  title: string;
  icon: MenuIcon;
  type: MenuType;
  link?: Link;
  primary?: boolean;
  position: MenuAlignment;
}

type MenuIconSet<T> = {
  active: T;
  inactive: T;
}

export type MenuIcon = {
  pngUrl?: MenuIconSet<ImageURISource>;
  svgType?: MenuIconSet<IconType>;
}

function filterMenuItem(menuItem: Menu): boolean {
  return !!(
    menuItem.title
    && menuItem.screen
    && (getMenuIcon(menuItem) || menuItem.screen === 'credentials')
    && !(menuItem.screen === 'pvr' && !mw.configuration.isNPVREnabled)
    && !(menuItem.screen === 'watchlist' && !mw.configuration.enabledWatchlist)
    && (isBigScreen || menuItem.position !== MenuAlignment.TOP)
  );
}

function getSTBMenuProps(menuItems: Menu[]): Hashmap<STBMenuProps> {
  return menuItems.reduce((menuProps: Hashmap<STBMenuProps>, menuItem: Menu) => {
    menuProps[routeNameForMenu(menuItem)] = {
      title: menuItem.title,
      icon: getMenuIcon(menuItem),
      slug: menuItem.slug,
      type: menuItem.type,
      isDisabled: menuItem.isDisabled,
      position: menuItem.position
    };
    return menuProps;
  }, {});
}

const tabBarLabelTextType = isTablet ? 'callout' : 'callout-small';

enum OperatorLogo {
  BigScreen = 'operator-logo-big-screen',
  Mobiles = 'operator-logo-mobiles',
  Default = 'operator-logo'
}

const deviceOperatorLogoType = isMobile ? OperatorLogo.Mobiles : OperatorLogo.BigScreen;

function getMenuOperatorLogoUrl(menu: Menu): string {
  let defaultUrl = '';
  for (const picture of menu.pictures) {
    if (picture.type === deviceOperatorLogoType) {
      return picture.url;
    }
    if (!defaultUrl && picture.type === OperatorLogo.Default) {
      defaultUrl = picture.url;
    }
  }
  return defaultUrl;
}

export function getOperatorLogoUrl(menu: Menu, screenRoute?: string): string {
  const menuItem = screenRoute ? menu.items.find(item => routeNameForMenu(item) === screenRoute) : undefined;
  if (!menuItem) {
    return getMenuOperatorLogoUrl(menu);
  }
  return getMenuOperatorLogoUrl(menuItem) || getMenuOperatorLogoUrl(menu);
}

export default function createMainMenu(menu: Menu, customItemsHandlers?: CustomMenuItemHandlers) {
  const filteredMenuItems = menu.items.filter(filterMenuItem);
  const screens: Screen[] = filteredMenuItems.map(submenu => ({
    route: routeNameForMenu(submenu),
    screenType: submenu.screen,
    title: submenu.title,
    icon: getMenuIcon(submenu),
    link: submenu.link,
    primary: submenu.primary,
    position: submenu.position,
    type: submenu.type
  }));

  const routes: NavigationRouteConfigMap = {};
  screens.forEach(screen => {
    const operatorLogoUrl = getOperatorLogoUrl(menu, screen.route);
    routes[screen.route] = getStackNavigator(screen.screenType, {link: screen.link, reloadScreen: true, operatorLogoUrl}, screens);
  });

  const initialScreen = screens.find(s => s.primary) || screens[0];

  if (isBigScreen) {
    const filteredMenu = {...menu, items: filteredMenuItems};
    const stbMenuProps = getSTBMenuProps(filteredMenuItems);
    const CustomTabRouter = TabRouter(
      routes, {
        initialRouteName: initialScreen.route
      }
    );

    return createNavigator(STBNavigationView, CustomTabRouter, {filteredMenu, stbMenuProps, customItemsHandlers});
  }

  const dynamicStyles = dynamicStylesUpdater.getStyles();
  // TODO: CL-1697 Wrap buttons in NitroxInteractive
  return createBottomTabNavigator(
    routes,
    {
      defaultNavigationOptions: ({navigation}) => {
        const screen = screens.find((s: { route: string }) => s.route === navigation.state.routeName);
        return (screen && screen.icon ?
          {
            tabBarLabel: props => (// eslint-disable-line react/display-name
              <View style={styles.tabBarLabel}>
                <MainMenuIcon
                  isSelected={props.focused}
                  source={screen.icon}
                  iconSize={dimensions.icon.xsmall}
                  iconColor={dynamicStyles.iconColor}
                  style={styles.icon}
                />
                <NitroxText textType={tabBarLabelTextType} numberOfLines={1} style={dynamicStyles.text}>{screen.title}</NitroxText>
              </View>
            ),
            tabBarOnPress: tabBarOnPress(customItemsHandlers),
            tabBarTestID: `button_${humanCaseToSnakeCase(screen.title)}`
          } : {});
      },
      tabBarComponent: TabBarComponent,
      initialRouteName: initialScreen.route,
      tabBarOptions: {
        showIcon: false,
        scrollEnabled: true,
        activeTintColor: dynamicStyles.tabBarActive.backgroundColor,
        inactiveTintColor: dynamicStyles.tabBarInactive.backgroundColor,
        style: {...dynamicStyles.tabBar, ...isWeb ? {height: dimensions.tabBar.height} : dimensions.tabBar},
        tabStyle: styles.tab,
        minWidthOverridenValue: styles.icon.width as number
      }
    }
  );
}
