import {makeArray} from 'common/utils';

import {SpecialFilter} from 'mw/api/CatalogInterface';
import {Filter} from 'mw/api/Filter';
import {Menu, Link, LinkType} from 'mw/cms/Menu';

const swimlaneTypeHref = 'urn:schange:nitrox:metadata:cs:SwimLanes:2019:Type';
const swimlineCacheTimeInMinutesHref = 'urn:schange:nitrox:metadata:cs:SwimLanes:2019:CacheTimeInMinutes';
const swimlanesSoftLinkId = 'SwimLanes';

type TraxisAlias = {
  Value: string;
}

type TraxisCategory = {
  Aliases: {
    Alias: TraxisAlias[]
  };
  IsAdult: boolean;
  id: string
}

type TraxisCategories = {
  Category: TraxisCategory[];
}

export class CategoryMapper {
  public static menuFromJson(json: any, forceHasContent?: boolean): Menu {
    if (!json) {
      return Menu.empty;
    }

    let children: Menu[] = [];
    if (json.ChildCategories) {
      children = makeArray(json.ChildCategories.Category).map(childJson => CategoryMapper.menuFromJson(childJson, forceHasContent));
    }
    let isPersonal = false;
    const customProperties = makeArray(json.CustomProperties && json.CustomProperties.CustomProperty);
    const isPersonalProp = customProperties.find(p => (typeof p.href === 'string') && /IsPersonal/i.test(p.href));
    if (isPersonalProp && typeof isPersonalProp.value === 'string') {
      isPersonal = /True/i.test(isPersonalProp.value);
    }

    let link: Link | undefined;
    let filter: Filter | undefined;

    const softLinksData = makeArray(json.SoftLinks && json.SoftLinks.SoftLink);
    const swimlanesSoftLink = softLinksData.find(s => s.qualifier === swimlanesSoftLinkId);
    if (swimlanesSoftLink && typeof swimlanesSoftLink.id === 'string' && typeof swimlanesSoftLink.idType === 'string') {
      link = {type: LinkType.PAGE, slug: swimlanesSoftLink.id, idType: swimlanesSoftLink.idType};
    } else {
      const hasContent = forceHasContent ||
        json.Titles?.Title?.length > 0 ||
        json.SeriesCollection?.Series?.length > 0;
      const cacheTimeInMinutesProp = customProperties.find(p => p.href === swimlineCacheTimeInMinutesHref);
      const cacheTimeInMinutes = (cacheTimeInMinutesProp && parseFloat(cacheTimeInMinutesProp.Value)) || 0;
      if (hasContent) {
        filter = {isPersonal, isSpecial: false, value: json.id, cacheTimeInMinutes};
      }
      const swimlaneTypeProp = customProperties.find(p => p.href === swimlaneTypeHref);
      if (swimlaneTypeProp?.Value && Object.values(SpecialFilter).includes(swimlaneTypeProp.Value)) { // custom dynamic swimlane
        filter = {isPersonal, isSpecial: true, value: swimlaneTypeProp.Value, cacheTimeInMinutes};
      }
    }

    return new Menu({title: json.Name, slug: json.id, screen: '', items: children, filter, link});
  }

  public static filterAdultCategories(categories: TraxisCategories, menu: Menu): Menu {
    const filteredMenuItems = menu.items.filter(item => {
      const category = categories.Category
        .find(category => category.Aliases.Alias
          .find(alias => item.filter?.value && alias.Value === item.filter.value));
      return !category?.IsAdult;
    });
    return new Menu({...menu, items: filteredMenuItems});
  }
}
