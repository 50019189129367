import {createStyles} from 'common-styles';
import React from 'react';
import {StyleSheet, Animated} from 'react-native';

import {isSmartTV, dimensions} from 'common/constants';
import {Log} from 'common/Log';

import {Media} from 'mw/api/Metadata';

import ConditionalWrapper from 'components/ConditionalWrapper';
import {Modal} from 'components/Modal';
import {useScreenInfo, useToggle} from 'hooks/Hooks';

import {BigScreenRecommendationsSection} from './BigScreenRecommendationsSection';
import {SeeMoreButton} from './SeeMoreButtons';

const styles = createStyles({
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: dimensions.margins.xLarge,
    width: '100%'
  }
});

type Props = {
  buttonAnimatedOpacity: Animated.Value;
  recommendations: Media[];
  onFocusEnter: () => void;
};

const TAG = 'RelatedSeeMore';

const RelatedSeeMore: React.FC<Props> = (props) => {
  const {buttonAnimatedOpacity, ...recommendationsProps} = props;
  const [modalVisible, {on: showModal, off: hideModal}] = useToggle(false);
  const screenInfo = useScreenInfo();

  if (!props.recommendations) {
    Log.debug(TAG, 'No recommendations returned');
    return null;
  }

  return (
    <>
      <Animated.View style={[styles.buttonContainer, {opacity: buttonAnimatedOpacity}]}>
        <SeeMoreButton onPress={showModal} disabled={isSmartTV} />
      </Animated.View>
      <ConditionalWrapper
        condition={!isSmartTV}
        wrapper={children => (
          <Modal
            style={StyleSheet.absoluteFill}
            contentStyle={screenInfo.size}
            visible={modalVisible}
          >
            {children}
          </Modal>
        )}
      >
        <BigScreenRecommendationsSection onClose={hideModal} {...recommendationsProps} />
      </ConditionalWrapper>
    </>
  );
};

export default RelatedSeeMore;
