import {EventEmitterInterface, EventEmitterCallback} from './EventEmitterInterface';

export class EventEmitter<T, ParamsType = any> implements EventEmitterInterface<T, ParamsType> {
  private callbacks: Map<T, Set<EventEmitterCallback<ParamsType>>> = new Map();
  private onceCallbacks: Map<T, Set<(EventEmitterCallback<ParamsType>)>> = new Map();

  public on(event: T, callback: EventEmitterCallback<ParamsType>): void {
    const eventCallbacks = this.callbacks.get(event);
    if (eventCallbacks) {
      eventCallbacks.add(callback);
    } else {
      this.callbacks.set(event, new Set([callback]));
    }
  }

  public once(event: T, callback: EventEmitterCallback<ParamsType>): void {
    const eventCallbacks = this.onceCallbacks.get(event);
    if (eventCallbacks) {
      eventCallbacks.add(callback);
    } else {
      this.onceCallbacks.set(event, new Set([callback]));
    }
  }

  public off(event: T, callback: EventEmitterCallback<ParamsType>): void {
    const eventCallbacks = this.callbacks.get(event);
    if (eventCallbacks) {
      eventCallbacks.delete(callback);
    }
    const onceEventCallbacks = this.onceCallbacks.get(event);
    if (onceEventCallbacks) {
      onceEventCallbacks.delete(callback);
    }
  }

  public clear(): void {
    this.callbacks.clear();
    this.onceCallbacks.clear();
  }

  public notify(event: T, params?: ParamsType): void {
    const eventCallbacks = this.callbacks.get(event);
    if (eventCallbacks) {
      eventCallbacks.forEach(callback => {callback && callback(params);});
    }
    const onceEventCallbacks = this.onceCallbacks.get(event);
    if (onceEventCallbacks) {
      onceEventCallbacks.forEach(callback => {callback && callback(params);});
      this.onceCallbacks.delete(event);
    }
  }
}
