import {Event, TitleType, Title} from 'mw/api/Metadata';
import {convertJSONDateToJSDate} from 'mw/common/utils';
import {EventObject} from 'mw/metadata/EventObject';
import {mw} from 'mw/MW';

import {ChannelMapper} from './ChannelMapper';
import {ContentMapper} from './ContentMapper';
import {RatingsMapper} from './RatingsMapper';
import {TitleMapper} from './TitleMapper';

export class EventMapper {
  public static fromJSON(eventJson: any, titleObject?: Title): Event {
    const titleJson = eventJson?.Titles?.Title?.[0] ?? {};
    const title = titleObject ?? TitleMapper.fromJSON(titleJson, TitleType.EPG);
    const event = mw.catalog.getEpgCacheEventById(eventJson.id, eventJson.ChannelId) ||
      new EventObject(
        eventJson.id, title.name, title,
        eventJson.AvailabilityStart && convertJSONDateToJSDate(eventJson.AvailabilityStart),
        eventJson.AvailabilityEnd && convertJSONDateToJSDate(eventJson.AvailabilityEnd),
        eventJson.ChannelId
      );
    title.events.push(event);
    // event objects are shared within the entire app therefore be sure not to override existing props with invalid values
    event.pcRatings = title.pcRatings.length ? title.pcRatings : event.pcRatings;
    event.pictures = title.pictures.length ? title.pictures : event.pictures;
    event.isAdult = typeof titleJson.IsAdult === 'boolean' ? titleJson.IsAdult : event.isAdult;
    event.tstvContents = eventJson.TstvContents && ContentMapper.fromJSONArray(eventJson.TstvContents.Content) || event.tstvContents;
    if (typeof eventJson.IsNetworkRecordingAllowed === 'boolean') {
      event.customProperties.isNetworkRecordingAllowed = eventJson.IsNetworkRecordingAllowed;
    }
    if (eventJson.IsRepeat) {
      event.pastTSTVEvent = title.events.find((e: Event) => e.tstvContents) || event.pastTSTVEvent;
    }
    if (typeof eventJson.IsRecorded === 'boolean') {
      event.isRecorded = eventJson.IsRecorded;
    }

    EventMapper.applyBookmarkData(event, eventJson);

    return event;
  }

  public static applyBookmarkData(event: Event, eventJson: any) {
    if (typeof eventJson.PersonalBookmark !== 'undefined') {
      event.bookmark = eventJson.PersonalBookmark;
    }
    if (typeof eventJson.IsPersonallyViewedCompletely !== 'undefined') {
      event.isViewedCompletely = eventJson.IsPersonallyViewedCompletely;
    }
  }

  public static fromJSONArray(eventsJsonArray: any[], titleObject?: Title): Event[] {
    return eventsJsonArray.map((eventJson: any) => EventMapper.fromJSON(eventJson, titleObject));
  }

  public static fromRecordingsJSON(recordingJson: any): Event {
    const channelJson = (recordingJson.Channels && recordingJson.Channels.Channel && recordingJson.Channels.Channel[0]) || {};
    const channel = ChannelMapper.toChannel(channelJson);

    const titleJson = (recordingJson.Titles && recordingJson.Titles.Title && recordingJson.Titles.Title[0]) || {};
    const title = TitleMapper.fromJSON(titleJson, TitleType.EPG);

    const event = mw.catalog.getEpgCacheEventById(recordingJson.EventId, channel.id) ||
      new EventObject(
        recordingJson.EventId, titleJson.Name, title,
        recordingJson.StartTime && convertJSONDateToJSDate(recordingJson.StartTime),
        recordingJson.EndTime && convertJSONDateToJSDate(recordingJson.EndTime),
        channel.id
      );

    event.isRecorded = true;
    // event objects are shared within the entire app therefore be sure not to override existing props with invalid values
    if (titleJson.Ratings) {
      event.pcRatings = RatingsMapper.fromJSON(titleJson.Ratings);
    }
    title.events.push(event);
    event.pictures = title.pictures || event.pictures;
    return event;
  }

  public static getIsRecorded(eventsJson: any): Map<Event['id'], boolean> {
    return (eventsJson.Event || []).reduce((events: Map<Event['id'], boolean>, eventJson: any) => (
      events.set(eventJson.id, eventJson.IsRecorded)
    ), new Map());
  }

  public static fromTitle(title: Title): Event {
    if (!title?.events?.length) {
      throw new Error('Title without events');
    }
    const now = Date.now();
    let event: Event;
    // Events are sorted descending by start time
    const firstPastEventIndex = title.events.findIndex((e: Event) => e.start.getTime() < now);
    if (firstPastEventIndex === -1) { // only future events, get last - closest to now
      event = title.events[title.events.length - 1];
    } else { // got past or current event - closest to now
      event = title.events[firstPastEventIndex];
    }

    event.title = title;
    event.pictures = title.pictures;
    event.pcRatings = title.pcRatings;
    return event;
  }
}
