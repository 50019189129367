import {AppRoutes} from 'common/constants';
import {replaceMediaPlayer, openMediaPlayer, findPlayableRecording} from 'common/HelperFunctions';
import {Navigation} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {Media, isEvent, RecordingType, Event, Recording} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import {ViewMode} from 'screens/tv/TvScreen';
import {TvScreenParams} from 'screens/tv/TvScreenHelperTypes';

export const PlayerLauncherTag = 'PlayerLauncher';

export enum PlayerLauncherModalType {
  None,
  ContinueWatching,
  ParentalControlUnlock
}

export type PlayerLauncherConfigProps = {
  shouldReplaceMediaPlayer?: boolean;
}

export type PlaybackDataProps = {
  media?: Media;
  unlocked?: boolean;
  tvScreenParams?: TvScreenParams;
}

export type PlayerLauncherProps = {
  visibleModal: PlayerLauncherModalType;
  bookmark: number;
  hideModals: () => void;
  hideUnlockModal: () => void;
  startPlaybackOfUnlockedMedia: () => void;
  startPlaybackFromBookmark: () => void;
  startPlaybackFromBeginning: () => void;
}

export interface WithPlayerLauncher {
  renderPlayerLauncherComponent: () => JSX.Element;
  startPlayback: (playbackProps: PlaybackDataProps) => void;
}

export const navigateToMediaPlayer = (media: Media, navigation: Navigation, shouldReplaceMediaPlayer: boolean, position?: number) => {
  if (shouldReplaceMediaPlayer) {
    replaceMediaPlayer(navigation, media, position);
  } else {
    openMediaPlayer(navigation, media, position);
  }
};

export const navigateToZapper = (playbackData: PlaybackDataProps, navigation: Navigation, position?: number) => {
  const {media, tvScreenParams} = playbackData;

  if (tvScreenParams) {
    navigation.navigate(AppRoutes.Zapper, tvScreenParams);
    return;
  }

  if (!media || !isEvent(media)) {
    return;
  }

  const zapperCommonProps = {
    viewMode: ViewMode.OneChannelEpg,
    requestedByRoute: navigation.state.routeName
  };

  if (media.isPlayAllowed()) {
    navigation.navigate(AppRoutes.Zapper, {
      eventId: media.id,
      channelId: undefined,
      position,
      ...zapperCommonProps
    });
  } else if (media.isNow && media.channelId) {
    navigation.navigate(AppRoutes.Zapper, {
      channelId: media.channelId,
      eventId: undefined,
      ...zapperCommonProps
    });
  } else {
    Log.error(PlayerLauncherTag, 'Unable to start playback of event', media.id);
  }
};

export const getPlayableRecording = async (event: Event): Promise<Recording | undefined> => {
  const recordings = await mw.pvr.getRecordings({media: event, type: RecordingType.Single})
    .catch(error => {
      Log.error(PlayerLauncherTag, `Error loading recordings for event ${event.id}`, error);
      return [];
    });
  return findPlayableRecording(recordings);
};
