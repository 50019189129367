import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {View, StyleProp, ViewStyle, Animated} from 'react-native';
import Reanimated from 'react-native-reanimated';

import {isBigScreen, getValue} from 'common/constants';
import {AnimatedStyle, MaybeAnimated} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {IconProps, Icon, ReanimatedIcon, AnimatedIcon} from 'components/Icon';
import {useLazyValue} from 'hooks/Hooks';

export const defaultSize = getValue({mobile: 150, tablet: 125, defaultValue: 180});
export const defaultIconContainerRatio = isBigScreen ? 0.8 : 0.65;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.iconRound.background
  }
}));

export type IconRoundProps = IconProps & {
  style?: StyleProp<ViewStyle>;
  iconContainerRatio?: number;
};

const IconRound: React.FC<IconRoundProps> = props => {
  const {
    size = defaultSize,
    iconContainerRatio = defaultIconContainerRatio,
    style,
    ...iconProps
  } = props;

  const styles = stylesUpdater.getStyles();
  const containerStyle = useMemo(() => [
    styles.container,
    {
      width: size,
      height: size,
      borderRadius: size / 2
    },
    style
  ], [size, style, styles.container]);

  return (
    <View style={containerStyle}>
      <Icon {...iconProps} size={Math.round(size * iconContainerRatio)} />
    </View>
  );
};

export type ReanimatedIconRoundProps = Omit<IconProps, 'size'> & {
  style?: StyleProp<Reanimated.AnimateStyle<ViewStyle>>;
  size: Reanimated.Node<number>;
  iconContainerRatio?: number;
};

export const ReanimatedIconRound: React.FC<ReanimatedIconRoundProps> = props => {
  const {
    size,
    iconContainerRatio = defaultIconContainerRatio,
    style,
    ...iconProps
  } = props;

  const styles = stylesUpdater.getStyles();
  const borderRadius = useLazyValue(() =>
    Reanimated.divide(size, 2)
  );
  const iconSize = useLazyValue(() =>
    Reanimated.multiply(size, iconContainerRatio)
  );

  const containerStyle = useMemo(() => [
    styles.container,
    {
      width: size,
      height: size,
      borderRadius
    },
    style
  ], [borderRadius, size, style, styles.container]);

  return (
    <Reanimated.View style={containerStyle}>
      <ReanimatedIcon {...iconProps} size={iconSize} />
    </Reanimated.View>
  );
};

export type AnimatedIconRoundProps = Omit<IconProps, 'size'> & {
  style?: StyleProp<AnimatedStyle<ViewStyle>>;
  size: MaybeAnimated<number>;
  iconContainerRatio?: number;
};

export const AnimatedIconRound: React.FC<AnimatedIconRoundProps> = props => {
  const {
    size,
    iconContainerRatio = defaultIconContainerRatio,
    style,
    ...iconProps
  } = props;

  const styles = stylesUpdater.getStyles();
  const borderRadius = useLazyValue(() =>
    Animated.divide(size, 2)
  );
  const iconSize = useLazyValue(() =>
    Animated.multiply(size, iconContainerRatio)
  );

  const containerStyle = useMemo(() => [
    styles.container,
    {
      width: size,
      height: size,
      borderRadius
    },
    style
  ], [borderRadius, size, style, styles.container]);

  return (
    <Animated.View style={containerStyle}>
      <AnimatedIcon {...iconProps} size={iconSize} />
    </Animated.View>
  );
};

export default IconRound;
