import {createStyles} from 'common-styles';
import React, {useMemo, forwardRef, useCallback, useImperativeHandle, useContext} from 'react';
import {View, GestureResponderEvent, ImageBackground as ReactImageBackground, Animated} from 'react-native';
import {NavigationContext} from 'react-navigation';

import {dimensions} from 'common/constants';
import {humanCaseToSnakeCase, openMediaDetails, openChannelDetails} from 'common/HelperFunctions';
import {TestProps} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Media, isTitle, isEvent, isSeries, isChannel, PictureType, PictureMode} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import {AspectRatio, Ratio} from 'components/AspectRatio';
import MediaPicture from 'components/MediaPicture';
import {AnimatedTileInterface} from 'components/mediaTiles/MediaTile';
import NitroxText from 'components/NitroxText';
import TileContainer from 'components/TileContainer';
import {useStaticallyFocused, useTestID, useEffectOnce} from 'hooks/Hooks';
import {useImageFadeAnimation} from 'hooks/useImageFadeAnimation';

const ImageBackground = Animated.createAnimatedComponent(ReactImageBackground);

const paddingWithBorderWidth = dimensions.margins.xsmall + dimensions.swimlaneWithHighlightedBanner.tileBorderWidth;

const styles = createStyles({
  tileContainer: {
    width: dimensions.swimlaneWithHighlightedBanner.focusedTileWidth,
    height: dimensions.swimlaneWithHighlightedBanner.tileHeight + 2 * paddingWithBorderWidth,
    marginTop: dimensions.margins.large + paddingWithBorderWidth,
    marginLeft: paddingWithBorderWidth,
    marginRight: paddingWithBorderWidth
  },
  focusedTileContainer: {
    marginTop: dimensions.margins.large,
    width: dimensions.swimlaneWithHighlightedBanner.focusedTileWidth + 2 * paddingWithBorderWidth,
    padding: dimensions.margins.xsmall,
    marginRight: 0,
    marginLeft: 0
  },
  tileNameContainer: {
    flex: 1,
    justifyContent: 'center',
    width: '100%',
    position: 'absolute',
    bottom: dimensions.swimlaneWithHighlightedBanner.nameBottomOffset + 2 * paddingWithBorderWidth
  }
});
const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  imageContainer: {
    width: '100%',
    height: dimensions.swimlaneWithHighlightedBanner.tileHeight,
    backgroundColor: colors.tile.poster.placeholder.background,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    overflow: 'hidden',
    borderRadius: 20
  },
  tileContainer: {
    borderColor: colors.tile.background.selected,
    borderWidth: dimensions.swimlaneWithHighlightedBanner.tileBorderWidth,
    borderRadius: 30
  },
  tileName: {
    color: colors.tile.contentText.unfocused,
    textAlign: 'center',
    paddingHorizontal: dimensions.margins.xxLarge
  }
}));

type HighlightedBannerTile = {
  data?: Media;
  showTileName?: boolean;
  highlighted?: boolean;
  scrollOnFocus?: boolean;
  onPress?: (media: Media, e?: GestureResponderEvent) => void;
  onFocus?: (media: Media, options?: FocusOptions) => void;
  focusable?: boolean;
  staticallyFocused?: boolean;
  onMount?: () => void;
}
const HighlightedBannerTile: React.FunctionComponent<HighlightedBannerTile & TestProps> = (props, ref) => {
  const {
    data: media,
    scrollOnFocus = true,
    onPress: propagatePress,
    onFocus: propagateFocus,
    showTileName,
    highlighted = false,
    focusable: propsFocusable,
    staticallyFocused,
    onMount
  } = props;

  useEffectOnce(() => onMount?.(), [onMount]);
  const navigation = useContext(NavigationContext);
  const dynamicStyles = stylesUpdater.getStyles();

  const ratio = useMemo(() => highlighted ? Ratio.RATIO_4_3 : Ratio.RATIO_2_3, [highlighted]);
  const width = useMemo(() => highlighted
    ? dimensions.swimlaneWithHighlightedBanner.focusedTileWidth
    : dimensions.swimlaneWithHighlightedBanner.tileWidth, [highlighted]);
  const height = dimensions.swimlaneWithHighlightedBanner.tileHeight;

  const tileContainerStyle = useMemo(
    () => highlighted && staticallyFocused
      ? [styles.tileContainer, styles.focusedTileContainer, dynamicStyles.tileContainer]
      : [styles.tileContainer, {width}],
    [highlighted, staticallyFocused, dynamicStyles.tileContainer, width]
  );

  const tileNameContainerStyle = useMemo(() => highlighted && staticallyFocused
    ? [styles.tileNameContainer, {
      bottom: dimensions.swimlaneWithHighlightedBanner.nameBottomOffset + dimensions.margins.xsmall,
      left: dimensions.margins.xsmall
    }]
    : styles.tileNameContainer,
  [highlighted, staticallyFocused]);

  const posterUri = useMemo(
    () => media && mw.catalog.getPictureUrl(media, PictureType.HighlightedBannerTile, width, height, PictureMode.BOX),
    [media, width, height]
  );

  const {source, onLoadEnd: onImageLoadEnd, opacity} = useImageFadeAnimation(highlighted ? posterUri : undefined);
  const openDetails = useCallback(() => {
    if (isTitle(media) || isEvent(media) || isSeries(media)) {
      openMediaDetails(navigation, media.id, media.getType());
    } else if (isChannel(media)) {
      openChannelDetails(navigation, media.id);
    }
  }, [media, navigation]);

  const onPress = useCallback((e?: GestureResponderEvent) => {
    if (propagatePress && media) {
      propagatePress(media, e);
    } else {
      openDetails();
    }
  }, [media, propagatePress, openDetails]);

  const onFocus = useCallback((event: any, options?: FocusOptions) => {
    propagateFocus && media && propagateFocus(media, options);
  }, [propagateFocus, media]);

  const {onFocusStateChanged, focusable, focused} = useStaticallyFocused(propsFocusable, staticallyFocused);
  /* eslint-disable schange-rules/no-use-imperative-handle-hook */
  useImperativeHandle(ref, () => {
    const handlers: AnimatedTileInterface = {
      onPress: () => {
        if (!focusable && focused) {
          onPress();
        }
      }
    };
    return handlers;
  }, [onPress, focusable, focused]);
  /* eslint-enable schange-rules/no-use-imperative-handle-hook */

  const imageStyle = useMemo(() => ({
    flex: 1,
    opacity
  }), [opacity]);

  const testID = useTestID(props, 'MediaTile') || `tile_${humanCaseToSnakeCase(media?.name || '')}`;

  return (
    <TileContainer
      ref={ref}
      scrollOnFocus={scrollOnFocus}
      onPress={onPress}
      style={tileContainerStyle}
      testID={testID}
      onFocus={onFocus}
      focusable={focusable}
      onFocusStateChanged={onFocusStateChanged}
    >
      <AspectRatio ratio={ratio}>
        <View style={dynamicStyles.imageContainer}>
          {highlighted && source && (
            <ImageBackground
              onLoadEnd={onImageLoadEnd}
              style={imageStyle}
              resizeMode='contain'
              source={source}
            />
          )}
          {!highlighted && media && posterUri && (
            <MediaPicture
              source={{uri: posterUri}}
              mediaType={media.getType()}
            />
          )}
        </View>
      </AspectRatio>
      {showTileName && (
        <View style={tileNameContainerStyle}>
          <NitroxText textType='swimlane-with-higlighted-banner' style={dynamicStyles.tileName} numberOfLines={1} upperCase>
            {media?.name}
          </NitroxText>
        </View>
      )}
    </TileContainer>
  );
};

export default React.memo(forwardRef(HighlightedBannerTile));
