import {Metadata, Genre, Credits} from 'mw/api/Metadata';

export class MetadataMapper {
  public static fromJSON(json: any): Metadata {
    const metadata = new Metadata();
    metadata.shortSynopsis = json.ShortSynopsis || '';
    metadata.mediumSynopsis = json.MediumSynopsis || '';
    metadata.longSynopsis = json.LongSynopsis || '';

    if (json.Genres && json.Genres.Genre) {
      metadata.genres = MetadataMapper.mapGenres(json.Genres.Genre);
    }

    if (json.ActorsCharacters && json.ActorsCharacters.Actor) {
      metadata.actors = MetadataMapper.mapCreditsFromCharacters(json.ActorsCharacters.Actor);
    } else if (json.Actors && json.Actors.Actor) {
      metadata.actors = MetadataMapper.extractCreditsFromNames(json.Actors.Actor);
    }

    if (json.DirectorNames && json.DirectorNames.DirectorName) {
      metadata.directors = MetadataMapper.mapCreditsFromCharacters(json.DirectorNames.DirectorName);
    } else if (json.Directors && json.Directors.Director) {
      metadata.directors = MetadataMapper.extractCreditsFromNames(json.Directors.Director);
    }

    if (json.ProductionDate) {
      metadata.productionYear = parseInt(json.ProductionDate);
    }
    if (json.ProductionLocations && json.ProductionLocations.ProductionLocation) {
      metadata.productionLocation = json.ProductionLocations.ProductionLocation.join(', ');
    }

    return metadata;
  }

  private static mapGenres(genresJson: any): Genre[] {
    return genresJson.map((genreJson: any) => {
      return new Genre(genreJson.type || '', genreJson.Value || '');
    });
  }

  private static mapCreditsFromCharacters(charactersJson: any): Credits[] {
    return charactersJson.map((characterJson: any) =>
      new Credits(characterJson.givenName || '', characterJson.familyName || '')
    );
  }

  private static extractCreditsFromNames(namesJson: any): Credits[] {
    return namesJson.map((nameJson: any) => {
      const tokens = (nameJson.Value ? nameJson.Value : nameJson).split(/\s*,\s*/);
      return new Credits(tokens[1] || '', tokens[0] || '');
    });
  }

}
