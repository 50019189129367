import {DateUtils} from 'common/DateUtils';
import {Log} from 'common/Log';

import {Error, ErrorType, AccountNotActivatedError} from 'mw/api/Error';
import {Credentials, nxffConfig} from 'mw/api/NXFF';
import {BOType} from 'mw/bo-proxy/bo/BOType';
import {CustomSSOActionType, CustomSSOAction, CustomSSOActionUserResponse, SSOEvent} from 'mw/bo-proxy/SSOInterface';
import {HttpMethods, HttpStatus, httpFetch, isUnavailableErrorCode, objectToRequestParams} from 'mw/utils/HttpUtils';
import {SecureStorage} from 'mw/utils/SecureStorage';

import {OpenIdConfiguration, wellKnownConfigurationUri} from './OAuth2/OpenIdConfiguration';
import {TraxisSSOAdapter} from './TraxisSSOAdapter';
import {Utils} from './Utils';

const TAG = 'SeaChangeSSOAdapter';

const ADR8CustomerIDClaim = 'accountId';

interface LoginInfo {
  refreshToken?: string;
  refreshTokenExpiration?: number;
}

enum GrantType {
  Password = 'password',
  RefreshToken = 'refresh_token'
}

enum ErrorCode {
  AccountNotActivated = 'AccountNotActivated'
}

interface TokenInfo {
  loginInfo: LoginInfo | null;
  token: string | null;
  tokenExpiration: number;
  nextStep: LoginStepConfig | null;
}

const customActionTypes: {[str: string]: CustomSSOActionType} = {
  'com:seachange:sso-login:grant-type:step:select-single-choice': CustomSSOActionType.SingleChoice,
  'com:seachange:sso-login:grant-type:step:select-multi-choice': CustomSSOActionType.MultiChoice,
  'com:seachange:sso-login:grant-type:step:input-value': CustomSSOActionType.InputValue
};

interface LoginStepConfig {
  /**
   * identifier of an action, used as a parameter in subsequent request
   */
  step_id: string;
  /**
   * identifier of the message defined in Translation Manager;
   * the message needs to be displayed by the client to describe action that need to be performed by user (eg. OID selection)
   */
  message_id: string;
  /**
   * indicates what type of activity is required
   */
  grant_type: string;
  /**
   * array of possible options client has to display when grant_type is SingleChoice or MultiChoice
   */
  choices?: {
    alias: string;
    label: string;
  }[];
}

interface SSOTokenInfo {
  access_token: string;
  expires_in: number; // time period in seconds
  refresh_token: string;
  refresh_expires_in?: number; // time period in seconds, 0 or unavailability means that token does not expire or client can't track it's expiration
  token_type: string;
  /**
   * Presence of next_step property indicates that the issued authorization token is a temporary one
   * and can be used only to get through the next step of authentication process
   */
  next_step?: LoginStepConfig;
}

export class SeaChangeSSOAdapter extends TraxisSSOAdapter {

  private storageName = 'SeaChangeSSOAdapter-LoginInfo';
  private secureStorage = new SecureStorage<LoginInfo>(this.storageName);
  private url: string;
  private boUrl: string;
  private tenant: string;
  private requiredScope = 'openid profile';
  private tokenInfo: TokenInfo | null = null;
  private customerIDClaim?: string;
  private customerId: string | null = null;

  private baseRequestHeaders = {
    'content-type': 'application/x-www-form-urlencoded',
    accept: 'application/json'
  }

  private authorizationHeader?: {Authorization: string};

  public constructor() {
    super();

    const environment = nxffConfig.getConfig().Environment;
    this.url = environment.SSOURL;
    this.boUrl = environment.BOURL;
    this.tenant = environment.Tenant;
    if (!!environment.SSOClientId || !!environment.SSOClientPassword) {
      this.authorizationHeader = {
        Authorization: Utils.createAuthorizationHeader(environment.SSOClientId || '', environment.SSOClientPassword || '')
      };
    }
    this.customerIDClaim = environment.BOType === BOType.ADR8 ? ADR8CustomerIDClaim : environment.SSOCustomerIDClaim;
  }

  private userChoicesToString(choices: string[], type: CustomSSOActionType): string {
    switch (type) {
      case CustomSSOActionType.SingleChoice:
        return `selected_choice=${encodeURIComponent(choices[0])}`;
      case CustomSSOActionType.InputValue:
        return `value=${encodeURIComponent(choices[0])}`;
      case CustomSSOActionType.MultiChoice:
        return choices.map(c => `selected_choices=${encodeURIComponent(c)}`).join('&');
    }
  }

  private handleCustomAuthorizationStep(stepConfig: LoginStepConfig, token: string, tokenEndpoint: string): Promise<TokenInfo> {
    const actionType: CustomSSOActionType = customActionTypes[stepConfig.grant_type];

    const waitForUserAction = new Promise<CustomSSOActionUserResponse>((resolve, reject) => {
      const eventPayload: CustomSSOAction = {
        id: stepConfig.step_id,
        type: actionType,
        message: stepConfig.message_id,
        choices: stepConfig.choices ?? [],
        onUserResponse: resolve,
        onUserCancel: () => reject(new Error(ErrorType.SSOCanceledByUser))
      };
      // notify UI that user action is needed,
      // UI should handle the action and call
      // onUserResponse or onUserCancel callback
      // provided in event payload
      this.notify(SSOEvent.SSOUserActionNeeded, eventPayload);
    });

    return waitForUserAction
      .then(response =>
        this.sendRequest(tokenEndpoint, {
          method: HttpMethods.POST,
          headers: {
            ...this.baseRequestHeaders,
            Authorization: `Bearer ${token}`
          },
          body: [
            objectToRequestParams({'grant_type': stepConfig.grant_type, 'step_id': stepConfig.step_id}),
            this.userChoicesToString(response.selectedChoices, actionType)
          ].join('&')
        })
      )
      .then(json => this.parseToken(json))
      .catch((error?: Error) => {
        if (error?.type === ErrorType.SSOUnauthorized) {
          // differentiate HTTP 401 for custom actions from other login requests
          return Promise.reject(new Error(ErrorType.SSOInvalidToken));
        }
        return Promise.reject(error);
      });
  }

  protected async loginImpl(credentials?: Credentials): Promise<string | null> {
    const loginInfo = await this.getLoginInfo();

    const username = credentials?.username;
    const password = credentials?.password;
    const refreshToken = credentials ? null : loginInfo.refreshToken;

    if (!(username && password) && !refreshToken) {
      Log.error(TAG, 'login: not enough data to login', {username, password: !!password, refreshToken: !!refreshToken});
      throw new Error(ErrorType.SSONoCredentials);
    }

    const openIdConfiguration = await this.loadServerConfiguration();

    if (!credentials) {
      this.tokenInfo = await this.refreshAccessToken(openIdConfiguration, loginInfo);
    } else {
      let tokenInfo = await this.getSSOToken(openIdConfiguration, credentials);
      while (tokenInfo.nextStep && tokenInfo.token) {
        // nextStep being present in tokenInfo object, indicates that
        // tokenInfo.token is a temporary token used to get through
        // next, custom authorization step
        tokenInfo = await this.handleCustomAuthorizationStep(tokenInfo.nextStep, tokenInfo.token, openIdConfiguration.token_endpoint);
      }
      this.tokenInfo = tokenInfo;
    }

    if (!this.tokenInfo.token) {
      Log.error(TAG, 'login: failed to retrieve token');
      throw new Error(ErrorType.SSOUnauthorized);
    }

    this.secureStorage.save(this.tokenInfo.loginInfo);

    if (!this.customerId) {
      if (!this.customerIDClaim) {
        Log.error(TAG, 'login: not configured: customerIDClaim');
        throw new Error(ErrorType.ImproperConfiguration);
      }

      this.customerId = await this.loadCustomerId(openIdConfiguration, this.tokenInfo.token, this.customerIDClaim);
    }

    if (!this.customerId) {
      Log.error(TAG, 'login: not loaded valid customerId');
      throw new Error(ErrorType.LoginError);
    }

    super.setIdentity(this.customerId);
    return this.tokenInfo.token;
  }

  public logout(): Promise<void> {
    Log.debug(TAG, 'logout');
    this.tokenInfo = null;
    this.customerId = null;
    return super.logout();
  }

  public getToken(): Promise<string> {
    if (!this.isLoggedIn()) {
      Log.error(TAG, 'getToken: try to get token before login');
      return Promise.reject(new Error(ErrorType.SSONotLoggedIn));
    }

    if (this.tokenInfo && this.tokenInfo.token && this.tokenInfo.tokenExpiration > Date.now()) {
      return Promise.resolve(this.tokenInfo.token);
    }

    return this.requestToken()
      .catch((error: Error) => {
        Log.error(TAG, 'getToken: failed to get token', error);
        switch (error.type) {
          case ErrorType.HttpTimeout:
          case ErrorType.NetworkNoConnection:
          case ErrorType.SSOInvalidResponse:
          case ErrorType.SSOUnavailableError:
            return Promise.reject(new Error(ErrorType.SSOUnavailableError));

          default:
            return this.detectConnectionErrors(error);
        }
      });
  }

  public invalidateToken(authorizationToken: string | null): void {
    Log.debug(TAG, 'invalidateToken');
    if (this.tokenInfo && this.tokenInfo.token === authorizationToken) {
      this.tokenInfo.token = null;
    }
  }

  public getBoUrl(): Promise<string> {
    return Promise.resolve(this.boUrl);
  }

  private getLoginInfo(): Promise<LoginInfo> {
    const loginInfo = this.tokenInfo?.loginInfo;
    if (loginInfo) {
      return Promise.resolve(loginInfo);
    }

    return this.secureStorage.get()
      .then(storedLoginInfo => {
        return storedLoginInfo || {};
      });
  }

  private sendRequest(url: string, requestInit: RequestInit): Promise<any> {
    Log.debug(TAG, 'sendRequest', url);
    return httpFetch(url, requestInit)
      .then(response => {
        if (response.status !== HttpStatus.Ok) {
          Log.error(TAG, 'sendRequest', response.status, response.statusText);
          return this.httpResponseToErrorCode(response);
        }
        return response.json()
          .catch(error => {
            Log.error(TAG, 'sendRequest', error);
            throw new Error(ErrorType.SSOInvalidResponse);
          });
      });
  }

  private getErrorCode(response: Response): Promise<ErrorCode | undefined> {
    if (!response.headers.get('content-type')?.includes('application/json')) {
      return Promise.resolve(undefined);
    }
    return response.json()
      .then(json => json.error)
      .catch(error => Log.error(TAG, 'Error parsing JSON in response', error));
  }

  private async httpResponseToErrorCode(response: Response): Promise<void> {
    switch (response.status) {
      case HttpStatus.BadRequest:
        const errorCode = await this.getErrorCode(response);
        throw new Error(errorCode === ErrorCode.AccountNotActivated ? ErrorType.AccountNotActivated : ErrorType.SSOBadRequest);

      case HttpStatus.Unauthorized:
        throw new Error(ErrorType.SSOUnauthorized);
    }

    if (isUnavailableErrorCode(response.status)) {
      throw new Error(ErrorType.SSOUnavailableError);
    }

    throw new Error(ErrorType.SSOUnknownError);
  }

  private loadServerConfiguration(): Promise<OpenIdConfiguration> {
    Log.debug(TAG, 'loadServerConfiguration');
    return this.sendRequest(this.url + wellKnownConfigurationUri, {
      method: HttpMethods.GET,
      headers: this.baseRequestHeaders
    })
      .then(json => {
        const openIdConfiguration: OpenIdConfiguration = json;
        this.validateOpenIdConfiguration(openIdConfiguration);
        return openIdConfiguration;
      })
      .catch(error => {
        Log.error(TAG, 'loadServerConfiguration', error);
        throw error;
      });
  }

  private validateOpenIdConfiguration(openIdConfiguration: OpenIdConfiguration): void {
    if (!openIdConfiguration) {
      Log.error(TAG, 'validateOpenIdConfiguration: no openIdConfiguration');
      throw new Error(ErrorType.SSOInvalidResponse);
    }

    if (!openIdConfiguration.token_endpoint || !openIdConfiguration.userinfo_endpoint) {
      Log.error(TAG, 'validateOpenIdConfiguration: openIdConfiguration: lack of data in configuration', openIdConfiguration);
      throw new Error(ErrorType.SSOInvalidResponse);
    }
  }

  private getSSOToken(openIdConfiguration: OpenIdConfiguration, credentials: Credentials): Promise<TokenInfo> {
    Log.debug(TAG, 'getSSOToken');
    return this.sendRequest(openIdConfiguration.token_endpoint, {
      method: HttpMethods.POST,
      body: objectToRequestParams({
        username: credentials.username,
        password: credentials.password,
        scope: this.requiredScope,
        'grant_type': GrantType.Password
      }),
      headers: {
        ...this.baseRequestHeaders,
        ...this.authorizationHeader
      }
    })
      .then(json => this.parseToken(json))
      .catch(error => {
        if (error.type === ErrorType.AccountNotActivated) {
          throw new AccountNotActivatedError(credentials.username, error.message);
        }
        throw error;
      });
  }

  private parseToken(json: SSOTokenInfo): TokenInfo {
    const now = Date.now();
    return {
      loginInfo: {
        refreshToken: json.refresh_token,
        refreshTokenExpiration: json.refresh_expires_in && (now + json.refresh_expires_in * DateUtils.msInSec)
      },
      token: json.access_token,
      tokenExpiration: now + json.expires_in * DateUtils.msInSec,
      nextStep: json.next_step ?? null
    };
  }

  private refreshAccessToken(openIdConfiguration: OpenIdConfiguration, loginInfo: LoginInfo): Promise<TokenInfo> {
    Log.debug(TAG, 'refreshAccessToken');
    const now = Date.now();
    if (!loginInfo.refreshToken || (loginInfo.refreshTokenExpiration && loginInfo.refreshTokenExpiration <= now)) {
      Log.error(TAG, 'refreshAccessToken: no valid refresh token', loginInfo);
      throw new Error(ErrorType.SSORefreshTokenExpired);
    }

    return this.sendRequest(openIdConfiguration.token_endpoint, {
      method: HttpMethods.POST,
      body: objectToRequestParams({
        'refresh_token': loginInfo.refreshToken,
        scope: this.requiredScope,
        'grant_type': GrantType.RefreshToken
      }),
      headers: {
        ...this.baseRequestHeaders,
        ...this.authorizationHeader
      }
    }).then(json => this.parseToken(json));
  }

  private loadCustomerId(openIdConfiguration: OpenIdConfiguration, token: string, customerIDClaim: string): Promise<string> {
    Log.debug(TAG, 'loadCustomerId');
    return this.sendRequest(openIdConfiguration.userinfo_endpoint, {
      method: HttpMethods.POST,
      headers: {
        ...this.baseRequestHeaders,
        Authorization: `Bearer ${token}`
      }
    }).then(json => {
      if (json.sub_is_subscriber_id === false) {
        return Promise.reject(new Error(ErrorType.SSOCustomerNotSubscribed));
      }

      return json[customerIDClaim];
    });
  }

  public getMasterPin(): string {
    throw new Error(ErrorType.NotSupported);
  }

  public isMasterPinSupported(): boolean {
    return false;
  }

  public setMasterPin(pin: string): Promise<void> {
    return Promise.reject(new Error(ErrorType.NotSupported));
  }
}
