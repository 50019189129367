import {createStyles, defaultStyles} from 'common-styles';
import React, {useRef, useCallback, useState, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ActivityIndicator, NativeSyntheticEvent, StyleSheet, TextInputSubmitEditingEventData, View, ScrollView, Keyboard} from 'react-native';

import {timeout} from 'common/Async';
import {isMobile, dimensions, isBigScreen, isAndroid, isPhone} from 'common/constants';
import {EventEmitter} from 'common/EventEmitter';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {constColors} from 'common-styles/variables/base-colors';
import {BaseColors} from 'common-styles/variables/base-colors';

import {SearchResult, SearchSource} from 'mw/api/CatalogInterface';
import {Media, SortVodBy, VodSorting, isTitle, ChannelSorting, SortChannelBy, AllResultsSorting, SortAllResultsBy} from 'mw/api/Metadata';
import {mw} from 'mw/MW';
import {sortSearchResults} from 'mw/utils/CatalogUtils';

import ConditionalWrapper from 'components/ConditionalWrapper';
import {withDeepLinking, WithDeepLinking} from 'components/DeepLinking';
import FocusParent, {useFocusParent} from 'components/FocusParent';
import {IconType} from 'components/Icon';
import FocusableTextInput, {FocusableTextInputRefType} from 'components/inputs/FocusableTextInput';
import {ChangeTextEvents} from 'components/inputs/FocusableTextInputImpl';
import MediaMoreActionsPopup from 'components/MediaMoreActionsPopup';
import {STBMenuState} from 'components/navigation/NavigationHelperTypes';
import NitroxButton, {ButtonIconProps} from 'components/NitroxButton';
import {usePlayerLauncher} from 'components/playerLauncher/PlayerLauncher';
import SearchFilterSelectGrosso from 'components/search/SearchFilterSelect.grosso';
import SearchFilterSelectPiccolo from 'components/search/SearchFilterSelect.piccolo';
import {SearchHistory} from 'components/search/SearchHistory';
import SearchLabel from 'components/search/SearchLabel';
import SearchResults from 'components/search/SearchResults';
import {SearchEpgFilter} from 'components/SearchEpgFilterSelect';
import {useNavigation, useToggle, useChangeEffect, useOnNavigationDidFocus, useIsBack} from 'hooks/Hooks';
import {PostProcessors} from 'locales/i18nPostProcessors';
import NitroxScreen from 'screens/NitroxScreen';

import {SearchFilterValue, SearchFilterOptionsKeys, SearchFilterKey} from './SearchHelperTypes';

const TAG = 'SearchScreen';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: isMobile ? 'stretch' : 'flex-start',
    backgroundColor: colors.epgScreen.background
  },
  contentContainer: {
    flex: 1,
    width: '100%',
    ...isMobile ? {
      paddingVertical: dimensions.margins.large,
      backgroundColor: colors.epgScreen.background
    } : {
      marginTop: 91
    }
  },
  formContainer: {
    width: '100%',
    alignItems: isMobile ? 'flex-start' : 'center'
  },
  inputContainer: {
    ...isMobile ? {
      flexDirection: 'row',
      width: '100%',
      paddingHorizontal: dimensions.margins.large,
      marginBottom: dimensions.margins.xxLarge
    } : {
      flexDirection: 'column-reverse',
      width: dimensions.inputs.width.search
    }
  },
  searchHeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  input: {
    flexGrow: 1,
    ...isBigScreen && {marginTop: dimensions.margins.xxLarge}
  },
  inputWithFilterButton: {
    marginRight: dimensions.margins.large
  },
  inputFilter: {
    backgroundColor: constColors.transparent,
    alignSelf: 'center',
    padding: 0,
    paddingLeft: 0,
    paddingRight: 0
  },
  activityIndicatorContainer: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center'
  },
  searchResultsContainer: {
    flex: 1
  },
  filterIcon: {
    color: colors.defaultColors.icon
  }
}));

const filterIcon: ButtonIconProps = {type: IconType.FiltersSearch, size: dimensions.icon.xsmall};
const defaultAllResultsSorting: AllResultsSorting = {type: SortAllResultsBy.name, ascending: true};
const defaultChannelSorting: ChannelSorting = {type: SortChannelBy.name, ascending: true};
const defaultEpgFilter: SearchEpgFilter | undefined = isMobile ? SearchEpgFilter.current : undefined;
const defaultVodSorting: VodSorting = {type: SortVodBy.title, ascending: true};

const textInputFocusPriority = 1;
const searchResultsFocusPriority = 2;

const SearchScreen: React.FunctionComponent<WithDeepLinking> = ({
  showDeepLinkingModal,
  renderDeepLinkingModal
}) => {
  const searchHistoryRef = useRef<SearchHistory>(null);
  const navigation = useNavigation();
  const {t} = useTranslation();
  const [onReady, focus] = useFocusParent();
  const [onInputReady, focusInput] = useFocusParent();
  const [editModeOnFocus, setEnterEditModeOnFocus] = useState(false);
  const searchInputRef = useRef<FocusableTextInputRefType>(null);

  const [searchResult, setSearchResult] = useState<SearchResult>();
  const [searching, setSearching] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchFilter, setSearchFilter] = useState<SearchFilterValue>(SearchFilterKey.All);
  const [currentAllResultsSorting, setCurrentAllResultsSorting] = useState<AllResultsSorting>(defaultAllResultsSorting);
  const [currentChannelSorting, setCurrentChannelSorting] = useState<ChannelSorting>(defaultChannelSorting);
  const [currentVodSorting, setCurrentVodSorting] = useState<VodSorting>(defaultVodSorting);
  const [currentEpgFilter, setCurrentEpgFilter] = useState<SearchEpgFilter | undefined>(defaultEpgFilter);
  const [selectedMedia, setSelectedMedia] = useState<Media>();
  const [filterSelectModalVisible, {on: showFilterSelectPopup, off: closeFilterSelectPopup}] = useToggle(false);
  const {renderPlayerLauncherComponent, startPlayback} = usePlayerLauncher();

  const onStartPlaybackPressed = useCallback((media: Media) => {
    if (isTitle(media) && media.contents.some(content => content.externalLink)) {
      showDeepLinkingModal(media, () => startPlayback({media}));
    } else {
      startPlayback({media});
    }
  }, [showDeepLinkingModal, startPlayback]);

  const search = useCallback((text: string) => {
    setSearchResult(undefined);
    if (text.length < mw.catalog.getSearchMinimumLength()) {
      setSearching(false);
      setSearchText(text);
      return;
    }
    setSearching(true);
    const searchFields = searchFilter !== SearchFilterKey.All ? [searchFilter] : undefined;
    mw.catalog.search({
      term: text,
      sources: [SearchSource.Channel, SearchSource.Epg, SearchSource.Vod],
      searchFields: searchFields,
      channelSorting: currentChannelSorting
    })
      .then(result => {
        if (result.vod?.length) {
          sortSearchResults(result.vod, currentVodSorting);
        }
        const hasResults = Object.values(result).some((value) => value?.length);
        setSearchResult(hasResults ? result : {});
        searchHistoryRef.current?.addSearchPhrase(text);
      })
      .catch(error => {
        Log.error(TAG, 'Error searching:', error);
      })
      .finally(() => {
        setSearching(false);
        setSearchText(text);
      });
  }, [currentChannelSorting, currentVodSorting, searchFilter]);

  const searchTextEventEmitter = useMemo(() => new EventEmitter<ChangeTextEvents>(), []);

  const clearSearchField = useCallback(() => searchTextEventEmitter.notify(ChangeTextEvents.Clear), [searchTextEventEmitter]);
  const clearSearchResult = useCallback(() => search(''), [search]);
  const clearSearchResultsAndField = useCallback(() => {
    focusInput();
    clearSearchResult();
    clearSearchField();
  }, [clearSearchField, clearSearchResult, focusInput]);

  const onSubmittedText = useCallback((event: NativeSyntheticEvent<TextInputSubmitEditingEventData>) => search(event.nativeEvent.text), [search]);
  const onSearchPhrasePress = useCallback((phrase: string) => {
    if (phrase === searchText) {
      return;
    }
    searchTextEventEmitter.notify(ChangeTextEvents.Change, phrase);
    search(phrase);
  }, [search, searchText, searchTextEventEmitter]);

  const openMoreActionsPopup = useCallback((media: Media) => {
    setSelectedMedia(media);
  }, []);

  const closeMoreActionsPopup = useCallback(() => {
    setSelectedMedia(undefined);
  }, []);

  const onSearchFilterModalClosed = useCallback((searchFilter: SearchFilterValue) => {
    setSearchFilter(searchFilter);
    closeFilterSelectPopup();
  }, [closeFilterSelectPopup]);

  const onAllResultsSortingSelected = useCallback((allResultsSorting?: AllResultsSorting) => setCurrentAllResultsSorting(allResultsSorting || defaultAllResultsSorting), []);
  const onChannelSortingSelected = useCallback((channelSorting?: ChannelSorting) => setCurrentChannelSorting(channelSorting || defaultChannelSorting), []);
  const onEpgFilterSelected = useCallback((epgFilter?: SearchEpgFilter) => setCurrentEpgFilter(epgFilter || defaultEpgFilter), []);
  const onVodSortingSelected = useCallback((vodSorting?: VodSorting) => setCurrentVodSorting(vodSorting || defaultVodSorting), []);
  useChangeEffect(() => search(searchText), [searchFilter, currentChannelSorting], [search]);

  const onFilterButtonPress = useCallback(() => {
    Keyboard.dismiss();
    showFilterSelectPopup();
  }, [showFilterSelectPopup]);

  const onInputBlurCapture = useCallback(() => {
    Keyboard.dismiss();
    return undefined;
  }, []);

  const isBack = useIsBack();

  const onScreenFocused = useCallback(() => {
    setEnterEditModeOnFocus(!isBack());
    if (isBigScreen) {
      if (isBack()) {
        focus();
      } else {
        clearSearchResultsAndField();
      }
    }
  }, [focus, isBack, clearSearchResultsAndField]);

  // We have to be sure that input is ready after each focus on the screen
  // Then we can call focus on mobile - it protects against keyboard stuck
  const [isInputReady, setIsInputReady] = useState(false);
  const onInputLayout = useCallback(() => {
    setIsInputReady(true);
  }, []);
  const onMobileScreenFocused = useCallback(() => {
    if (isMobile && isInputReady && !isBack()) {
      if (!isAndroid) {
        searchInputRef.current?.focus();
      } else {
        //https://github.com/facebook/react-native/issues/19366
        searchInputRef.current?.blur();
        timeout(() => {
          if (navigation.isFocused()) {
            searchInputRef.current?.focus();
          }
        }, 100);
      }
    }
  }, [isBack, isInputReady, navigation]);
  useOnNavigationDidFocus(onMobileScreenFocused);
  const onInternalFocusUpdate = useCallback(() => setEnterEditModeOnFocus(false), [setEnterEditModeOnFocus]);

  const styles = stylesUpdater.getStyles();
  const isSearchFiltersEnabled = mw.configuration.isSearchFiltersEnabled;
  const currentFilterOptionKey = SearchFilterOptionsKeys[searchFilter];
  return (
    <NitroxScreen
      style={[defaultStyles.view, styles.container]}
      navigation={navigation}
      menuState={STBMenuState.Above}
      mobileHeaderProps={{title: t('search.search')}}
      testID='screen_search'
      onScreenFocused={onScreenFocused}
      showOperatorLogo={!isPhone}
    >
      <FocusParent
        onReady={onReady}
        style={styles.contentContainer}
        enterStrategy={'byPriority'}
        onInternalFocusUpdate={onInternalFocusUpdate}
        rememberLastFocused
      >
        {/* Wrapping with ScrollView for handling FocusableTextInput events */}
        <ConditionalWrapper
          condition={isMobile}
          wrapper={(children) => (
            <ScrollView keyboardShouldPersistTaps='handled' contentContainerStyle={{flex: 1}}>
              {children}
            </ScrollView>
          )}
        >
          <View style={styles.activityIndicatorContainer}>
            <ActivityIndicator animating={searching} />
          </View>
          <View style={styles.formContainer}>
            <View
              style={styles.inputContainer}
              testID={'search_input_container'}
            >
              <View style={[styles.input, (isSearchFiltersEnabled && isMobile) && styles.inputWithFilterButton]}>
                <FocusParent
                  focusPriority={textInputFocusPriority}
                  onReady={onInputReady}
                  holdFocus
                >
                  <FocusableTextInput
                    ref={searchInputRef}
                    changeTextEventEmitter={searchTextEventEmitter}
                    type='search'
                    testID='input_search'
                    onSubmitEditing={onSubmittedText}
                    enterEditModeOnFocus={editModeOnFocus}
                    clearIcon
                    iconAction={clearSearchResultsAndField}
                    displayMobileBackButton
                    onInputBlurCapture={onInputBlurCapture}
                    onLayout={onInputLayout}
                    placeholder={t('search.searchPrompt')}
                  />
                </FocusParent>
              </View>
              {isSearchFiltersEnabled && isMobile && (
                <>
                  <NitroxButton
                    icon={{...filterIcon, ...styles.filterIcon}}
                    style={styles.inputFilter}
                    onPress={onFilterButtonPress}
                  />
                  <SearchFilterSelectPiccolo
                    modalVisible={filterSelectModalVisible}
                    searchField={searchFilter}
                    onModalClosed={onSearchFilterModalClosed}
                  />
                </>
              )}
              {isSearchFiltersEnabled && isBigScreen && (
                <>
                  <View style={styles.searchHeaderContainer}>
                    <SearchLabel />
                    <NitroxButton
                      border
                      text={`${t('search.filters', {postProcess: PostProcessors.ToUpperCase})}: ${t(currentFilterOptionKey, {postProcess: PostProcessors.ToUpperCase})}`}
                      onPress={showFilterSelectPopup}
                    />
                  </View>
                  <SearchFilterSelectGrosso
                    modalVisible={filterSelectModalVisible}
                    searchField={searchFilter}
                    onModalClosed={closeFilterSelectPopup}
                    onModalApply={onSearchFilterModalClosed}
                  />
                </>
              )}
            </View>
            <SearchHistory
              visible={isMobile || !searchResult}
              ref={searchHistoryRef}
              onSearchPhrasePress={onSearchPhrasePress}
              onClearPress={focus}
            />
          </View>
          <View style={styles.searchResultsContainer}>
            <SearchResults
              searchResult={searchResult}
              searchResultsFocusPriority={searchResultsFocusPriority}
              currentAllResultsSorting={currentAllResultsSorting}
              onAllResultsSortingSelected={onAllResultsSortingSelected}
              currentChannelSorting={currentChannelSorting}
              onChannelSortingSelected={onChannelSortingSelected}
              currentEpgFilter={currentEpgFilter}
              onEpgFilterSelected={onEpgFilterSelected}
              currentVodSorting={currentVodSorting}
              onVodSortingSelected={onVodSortingSelected}
              onClearResults={clearSearchResultsAndField}
              searchText={searchText}
              onMoreActionsPress={openMoreActionsPopup}
              onStartPlayback={onStartPlaybackPressed}
            />
            <MediaMoreActionsPopup media={selectedMedia} onClose={closeMoreActionsPopup} />
          </View>
        </ConditionalWrapper>
      </FocusParent>
      {renderPlayerLauncherComponent()}
      {renderDeepLinkingModal()}
    </NitroxScreen>
  );
};

export default withDeepLinking(SearchScreen);
