import {areLangaugesEqual} from 'common/LanguageHelperFunctions';
import {isCcTrack} from 'common/utils';

import {Track} from 'mw/api/PlayerEvent';
import {regionIdKey} from 'mw/Constants';
import {mw} from 'mw/MW';
import {PlaybackSession} from 'mw/playback/sessions/PlaybackSessionManager';

export const addRegion = (playbackSession: PlaybackSession): PlaybackSession => {
  playbackSession.assets.forEach(asset => {
    const regionId = mw.customer.regionId;
    if (!regionId) {
      return;
    }
    const url = asset.url;
    const sign = (url.indexOf('?') === -1) ? '?' : '&';
    asset.url = `${url}${sign}${regionIdKey}=${regionId}`;
  });
  return playbackSession;
};

export const getDefaultAudioTrack = (tracks: Track[]): Track | undefined => {
  const preferredLang = mw.customer.currentProfile?.audioLanguage;
  return preferredLang ? tracks.find(t => areLangaugesEqual(t.language, preferredLang)) : undefined;
};

export const getDefaultSubtitlesTrack = (tracks: Track[]): Track | undefined => {
  const preferredAudioLang = mw.customer.currentProfile?.audioLanguage;
  const preferredSubtitlesLang = mw.customer.currentProfile?.subtitleLanguage;
  const isCCEnabled = mw.customer.currentProfile?.ccEnabled;
  let defaultTrack: Track | undefined;

  if (isCCEnabled) {
    defaultTrack = ((preferredAudioLang && tracks.find(t => isCcTrack(t) && areLangaugesEqual(t.language, preferredAudioLang))) // preferred audio lang CC track
      || (preferredSubtitlesLang && tracks.find(t => isCcTrack(t) && areLangaugesEqual(t.language, preferredSubtitlesLang))) // preferred subtitles' lang CC track
      || tracks.find(t => isCcTrack(t)) // any CC track
      || tracks.find(t => t.language === 'off'));
  } else {
    defaultTrack = ((preferredSubtitlesLang && tracks.find(t => areLangaugesEqual(t.language, preferredSubtitlesLang)))
      || tracks.find(t => t.language === 'off'));
  }
  return defaultTrack;
};
