import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {View} from 'react-native';

import {dimensions} from 'common/constants';
import {getCreditsFullName} from 'common/HelperFunctions';

import {Credits} from 'mw/api/Metadata';

import NitroxButton, {NitroxButtonTheme} from 'components/NitroxButton';

import {mediaDetailHorizontalMargin} from './MediaDetailUtils';

const styles = createStyles({
  buttonsContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  button: {
    flexGrow: 1,
    marginHorizontal: dimensions.margins.xsmall,
    marginVertical: dimensions.margins.xsmall,
    // TODO: CL-2324 remove this after implementing PLM
    opacity: 1
  },
  gapFiller: {
    flexGrow: 100
  }
});

type CreditsButtonProps = {
  credits: Credits;
  onPress: (credits: Credits) => void;
};

const CreditsButtonComponent: React.FC<CreditsButtonProps> = (props: CreditsButtonProps) => {
  const {credits} = props;
  const fullName = useMemo(() => getCreditsFullName(credits), [credits]);

  return (
    <NitroxButton
      text={fullName}
      theme={NitroxButtonTheme.Primary}
      border
      style={styles.button}
      // TODO: CL-2324 remove this after implementing PLM
      disabled
    />
  );
};

const CreditsButton = React.memo(CreditsButtonComponent);

type Props = {
  credits: Credits[];
  onPress: (creditsItem: Credits) => void;
};

const CreditsViewGrosso: React.FunctionComponent<Props> = props => {
  const {credits, onPress} = props;
  const marginHorizontal = mediaDetailHorizontalMargin - dimensions.margins.xsmall;

  return (
    <View style={[styles.buttonsContainer, {marginHorizontal}]} testID='credits'>
      {credits.map((creditsItem: Credits, index: number) => (
        <CreditsButton key={index} credits={creditsItem} onPress={onPress} />)
      )}
      <View style={styles.gapFiller} />
    </View>
  );
};

export default CreditsViewGrosso;
