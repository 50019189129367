import {Log} from 'common/Log';

import {Error, ErrorType} from 'mw/api/Error';
import {Credentials, nxffConfig} from 'mw/api/NXFF';
import {SSOEvent, SSOTokenRenewedParams} from 'mw/bo-proxy/SSOInterface';
import {AsyncStorageData} from 'mw/platform/async-storage/AsyncStorageInterface';
import {deviceInfo} from 'mw/platform/device-info/DeviceInfo';
import {HttpMethods, HttpStatus, httpFetch, isUnavailableErrorCode} from 'mw/utils/HttpUtils';
import {SecureStorage} from 'mw/utils/SecureStorage';
import {xmlUtils} from 'mw/utils/xmlUtils';

import {TraxisSSOAdapter} from './TraxisSSOAdapter';

const TAG = 'MegaSSOAdapter';

const maxRequestRetries = 2;

interface Account {
  appId: string;
  deviceId: string;
}

interface Accounts {
  [username: string]: Account;
}

interface LoginInfo {
  username: string | null;
  persistCookie: string | null;
  accounts: Accounts;
}

interface MegaSSOData {
  code?: string;
  customerId?: string;
  sessionId?: string;
  persistCookie?: string;
  customerPin?: string;
  payPin?: string;
  appId?: string;
  devicePublicId?: string;
}

enum RequestType {
  LoginPersistUserDeviceRequest = 'LoginPersistUserDeviceRequest',
  AuthenticateUserDeviceRequest = 'AuthenticateUserDeviceRequest'
}

interface LoginRequestParams extends Credentials {
  appId: string | null;
  devicePublicId: string | null;
  serialNumber: string | null;
  persistCookie: string | null;
  requestType: RequestType;
}

enum ErrorCode {
  StatusOk = '0000',
  UserNotExist = '0011',
  BadRequest = '0012',
  SessionExpired = '0016',
  InvalidCredentials = '0027',
  InvalidXMLFormat = '0032',
  ServerBusy = '0033',
  AuthenticationAttemptForValidSession = '0048',
  NoDeviceSlots = '0501',
  NoDeviceSwaps = '0601'
}

export class MegaSSOAdapter extends TraxisSSOAdapter {

  private storageName = 'MegaSSOAdapter-LoginInfo';
  private secureStorage = new SecureStorage<LoginInfo>(this.storageName);
  private fixedParams = '&namespace_id=2&vas_id=1000';
  private masterPinSettingParams = 'UserManagement/SetAdminParentalCode';
  private payPinSettingParams = 'UserManagement/SetAdminPaymentCode';
  private loginInfo: LoginInfo | null = null;
  private url: string;
  private boUrl: string;
  private token: string | null = null;
  private masterPinCode = '';
  private payPin = '';
  private tokenRenewForced = false;

  public constructor() {
    super();

    this.url = nxffConfig.getConfig().Environment.SSOURL;
    this.boUrl = nxffConfig.getConfig().Environment.BOURL;
  }

  public getCrossLoginData(): Promise<AsyncStorageData[]> {
    if (this.loginInfo === null) {
      return Promise.resolve([]);
    }

    const crossLoginData = {...this.loginInfo};
    crossLoginData.persistCookie = null;
    crossLoginData.username = null;

    return Promise.resolve([{
      key: this.storageName,
      value: JSON.stringify(crossLoginData, (k, v) => (v === null) ? undefined : v)
    }]);
  }

  protected loginImpl(credentials?: Credentials): Promise<string | null> {
    return this.sendLoginRequest(credentials);
  }

  protected requestToken(credentials?: Credentials): Promise<string> {
    Log.info(TAG, 'Authentication started');
    return super.requestToken(credentials)
      .then(token => {
        Log.info(TAG, 'Authentication successful');
        return token;
      })
      .catch(error => {
        Log.error(TAG, 'Authentication failed', error);
        throw error;
      });
  }

  private async sendLoginRequest(credentials?: Credentials): Promise<string> {
    this.loginInfo = await this.getLoginInfo();

    const username = credentials && credentials.username || this.loginInfo.username;
    const password = credentials && credentials.password;
    const persistCookie = credentials ? null : this.loginInfo.persistCookie;

    if (!username || (!password && !persistCookie)) {
      Log.error(TAG, 'login: not enough data to login', {username, password: !!password, persistCookie: !!persistCookie});
      throw new Error(ErrorType.LoginError);
    }

    Log.debug(TAG, 'request persistCookie', persistCookie);

    const requestType = persistCookie && RequestType.LoginPersistUserDeviceRequest || RequestType.AuthenticateUserDeviceRequest;

    const params: LoginRequestParams = {
      username,
      password,
      persistCookie,
      requestType,
      appId: this.getAppId(username),
      devicePublicId: this.getDevicePublicIdForUsername(username),
      serialNumber: deviceInfo.getSerialNumber()
    };

    const requestUrl = `${this.url}?op=${requestType}${this.fixedParams}`;
    const body = xmlUtils.serializeXmlDoc(this.generateLoginRequestXml(params));

    const response = await httpFetch(requestUrl, {
      method: 'POST',
      body: body,
      credentials: 'omit',
      headers: {
        'content-type': 'text/xml; charset=utf-8'
      }
    });

    Log.info(TAG, `login request finished with status code: ${response.status}`);
    if (response.status !== 200) {
      this.throwIfOfflineError(response.status);
      throw new Error(ErrorType.SSOInvalidResponse, `Failed to retrieve SSO token: ${response.status}`);
    }

    const responseTxt = await response.text();
    let ssoResponse: MegaSSOData | null = null;
    try {
      ssoResponse = this.extractDataFromResponse(responseTxt);
    } catch (error) {
      Log.error(TAG, 'error parsing response', error, responseTxt);
      throw new Error(ErrorType.SSOInvalidResponse, `Failed to parse SSO response: ${response.status}`);
    }

    if (ssoResponse.code !== ErrorCode.StatusOk) {
      Log.error(TAG, 'error response', JSON.stringify(ssoResponse));
      throw this.handleErrorCode(ssoResponse.code);
    }

    this.loginInfo.username = username;

    if (!ssoResponse.sessionId) {
      throw new Error(ErrorType.SSOInvalidResponse);
    }

    const token = ssoResponse.sessionId;
    this.token = token;

    if (ssoResponse.persistCookie) {
      Log.debug(TAG, 'response persistCookie', ssoResponse.persistCookie);
      this.loginInfo.persistCookie = ssoResponse.persistCookie;
    }

    if (ssoResponse.appId && ssoResponse.devicePublicId) {
      this.loginInfo.accounts[username] = {appId: ssoResponse.appId, deviceId: ssoResponse.devicePublicId};
    }

    if (!ssoResponse.customerPin) {
      throw new Error(ErrorType.SSOInvalidResponse, 'Response doesn\'t contain customer PIN');
    }
    this.masterPinCode = ssoResponse.customerPin;

    if (!ssoResponse.payPin) {
      throw new Error(ErrorType.SSOInvalidResponse, 'Response doesn\'t contain pay PIN');
    }
    this.payPin = ssoResponse.payPin;

    this.setIdentity(ssoResponse.customerId);

    Log.info(TAG, 'login succeeded');

    await this.secureStorage.save(this.loginInfo)
      .then(() => Log.debug(TAG, 'loginInfo saved'))
      .catch(error => Log.error(TAG, 'Unable to save login info in secure storage', error));

    return token;
  }

  public logout(): Promise<void> {
    this.token = null;
    return super.logout();
  }

  public getToken(): Promise<string> {
    if (!this.isLoggedIn()) {
      return Promise.reject(new Error(ErrorType.SSONotLoggedIn));
    }

    if (this.token) {
      return Promise.resolve(this.token);
    }

    return this.requestToken()
      .then(token => {
        const tokenRenewedParams: SSOTokenRenewedParams = {
          forced: this.tokenRenewForced
        };
        this.notify(SSOEvent.SSOTokenRenewed, tokenRenewedParams);
        this.tokenRenewForced = false;
        return token;
      })
      .catch((error: Error) => this.detectConnectionErrors(error));
  }

  public invalidateToken(authorizationToken: string | null): void {
    if (authorizationToken === this.token) {
      this.token = null;
    }
  }

  public renewToken(): Promise<void> {
    this.tokenRenewForced = true;
    this.invalidateToken(this.token);
    return this.getToken()
      .then(() => Promise.resolve());
  }

  public async getBoUrl(): Promise<string> {
    return this.boUrl;
  }

  public getDevicePublicId(): string {
    const username = this.loginInfo && this.loginInfo.username;
    const devicePublicId = username && this.getDevicePublicIdForUsername(username);
    if (!devicePublicId) {
      throw new Error(ErrorType.UnknownError, 'Public id is not available');
    }

    return devicePublicId;
  }

  public isPurchasePinSupported() {
    return true;
  }

  public setPurchasePin(pin: string): Promise<void> {
    const identity = encodeURIComponent(this.getIdentity().id);
    const requestUrl = `${this.url}?op=${this.payPinSettingParams}&acc_pubid=${identity}&usr_pay_code=${pin}`;

    return this.sendRequest(requestUrl)
      .then(() => {
        this.payPin = pin;
      });
  }

  public verifyPurchasePin(pin: string): Promise<void> {
    if (this.payPin !== pin) {
      return Promise.reject(new Error(ErrorType.IncorrectPin));
    }
    return Promise.resolve();
  }

  private async getLoginInfo(): Promise<LoginInfo> {
    if (this.loginInfo) {
      return this.loginInfo;
    }

    return await this.secureStorage.get() || {
      accounts: {},
      username: null,
      persistCookie: null
    };
  }

  private getAppId = (username: string): string | null => {
    return this.loginInfo && this.loginInfo.accounts && this.loginInfo.accounts[username] && this.loginInfo.accounts[username].appId || null;
  };

  private getDevicePublicIdForUsername(username: string): string | null {
    return this.loginInfo && this.loginInfo.accounts && this.loginInfo.accounts[username] && this.loginInfo.accounts[username].deviceId || null;
  }

  private generateLoginRequestXml = (params: LoginRequestParams): Document => {
    const requestType = params.requestType;
    const xmlDoc = xmlUtils.getXmlDoc(`<${requestType} version='4.7'/>`);
    const root = xmlDoc.getElementsByTagName(requestType)[0];
    const request = xmlUtils.createElement(xmlDoc, 'request');
    xmlUtils.appendChild(root, request);

    const user = xmlUtils.createElement(xmlDoc, 'user');
    switch (requestType) {
      case RequestType.AuthenticateUserDeviceRequest: // manual login
        xmlUtils.appendChild(request.data, xmlUtils.createElement(xmlDoc, 'cred_type', [
          xmlUtils.createText(xmlDoc, '1')
        ]));
        xmlUtils.appendChild(user.data, xmlUtils.createElement(xmlDoc, 'u', [
          xmlUtils.createText(xmlDoc, params.username)
        ]));
        xmlUtils.appendChild(user.data, xmlUtils.createElement(xmlDoc, 'p', [
          params.password && xmlUtils.createText(xmlDoc, params.password)
        ]));
        break;
      case RequestType.LoginPersistUserDeviceRequest: // automatic login
        xmlUtils.appendChild(user.data, xmlUtils.createElement(xmlDoc, 'persist_cookie', [
          params.persistCookie && xmlUtils.createText(xmlDoc, params.persistCookie)
        ]));
        break;
    }
    xmlUtils.appendChild(request.data, user);

    const device = xmlUtils.createElement(xmlDoc, 'device');
    xmlUtils.appendChild(device.data, xmlUtils.createElement(xmlDoc, 'info', [
      xmlUtils.createText(xmlDoc, this.generateDeviceInfo())
    ]));

    if (params.devicePublicId) {
      xmlUtils.appendChild(device.data, xmlUtils.createElement(xmlDoc, 'public_id', [
        xmlUtils.createText(xmlDoc, params.devicePublicId)
      ]));
    }
    if (params.appId) {
      xmlUtils.appendChild(device.data, xmlUtils.createElement(xmlDoc, 'app_id', [
        xmlUtils.createText(xmlDoc, params.appId)
      ]));
    }
    if (params.serialNumber) {
      xmlUtils.appendChild(device.data, xmlUtils.createElement(xmlDoc, 'dev_pubid', [
        xmlUtils.createText(xmlDoc, params.serialNumber)
      ]));
    }
    xmlUtils.appendChild(request.data, device);

    return xmlDoc;
  };

  /*manufacturer:Xiaomi|model:MIBOX3|os:Android|os_version:6.0.3|tabletscreen:false*/
  private generateDeviceInfo = (): string => {
    return [
      'manufacturer:' + deviceInfo.getManufacturer(),
      'model:' + deviceInfo.getModel(),
      'os:' + deviceInfo.getSystemName(),
      'os_version:' + deviceInfo.getSystemVersion(),
      'tabletscreen:' + deviceInfo.isTablet()
    ].join('|');
  };

  public getMasterPin(): string {
    return this.masterPinCode;
  }

  public isMasterPinSupported(): boolean {
    return true;
  }

  public setMasterPin(pin: string): Promise<void> {
    const identity = encodeURIComponent(this.getIdentity().id);
    const requestUrl = `${this.url}?op=${this.masterPinSettingParams}&acc_pubid=${identity}&usr_pin_code=${pin}`;

    return this.sendRequest(requestUrl).
      then(() => {
        this.masterPinCode = pin;
      });
  }

  private async sendRequest(requestUrl: string, retryCount = 0): Promise<Response> {
    const authorizationToken = await this.getToken()
      .catch((error: Error) => {
        if (error.type === ErrorType.SSOUnauthorized) {
          this.notify(SSOEvent.SSOUnauthorized);
        }
        throw error;
      });

    const headers: {[key: string]: string} = {
      'Cookie': 'JSESSIONID=' + authorizationToken
    };

    const response = await httpFetch(requestUrl, {
      method: HttpMethods.POST,
      headers: headers
    });

    Log.debug(TAG, `request finished with status code: ${response.status}`);
    if (response && response.status !== HttpStatus.Ok) {
      this.throwIfOfflineError(response.status);
      throw new Error(ErrorType.SSOInvalidResponse, `Invalid response from SSO: ${response.status}`);
    }

    const responseTxt = await response.text();
    const ssoResponse = this.extractDataFromResponse(responseTxt);

    switch (ssoResponse.code) {
      case ErrorCode.StatusOk:
        return response;
      case ErrorCode.SessionExpired:
        this.invalidateToken(authorizationToken);
        if (retryCount > maxRequestRetries) {
          Log.error(TAG, 'sendRequest SSOInvalidAuthentication', 'retryCount', retryCount);
          this.notify(SSOEvent.SSOUnauthorized);
          throw new Error(ErrorType.SSOUnauthorized);
        }
        return await this.sendRequest(requestUrl, retryCount + 1);
      default:
        Log.error(TAG, 'sendRequest: response code matched as error', responseTxt);
        throw this.handleErrorCode(ssoResponse.code);
    }
  }

  private throwIfOfflineError(responseStatus: number) {
    if (isUnavailableErrorCode(responseStatus)) {
      throw new Error(ErrorType.SSOUnavailableError, `SSO connection problem: ${responseStatus}`);
    }
  }

  private extractDataFromResponse = (response: string): MegaSSOData => {
    const xml = xmlUtils.parseXmlString(response);
    const errorCodeTag = xml.getElementsByTagName('error_code')[0];
    if (!errorCodeTag || !errorCodeTag.textContent) {
      Log.error(TAG, 'missing <error_code> tag in response from SSO server');
      return {};
    }

    const result: MegaSSOData = {};
    result.code = errorCodeTag.textContent;
    const accountTag = xml.getElementsByTagName('account')[0];
    if (accountTag) {
      const publicIdTag = accountTag.getElementsByTagName('public_id')[0];
      if (publicIdTag && publicIdTag.textContent) {
        result.customerId = publicIdTag.textContent;
      }
    }
    const sessionIdTag = xml.getElementsByTagName('session_id')[0];
    if (sessionIdTag && sessionIdTag.textContent) {
      result.sessionId = sessionIdTag.textContent;
    }
    const persistCookieTag = xml.getElementsByTagName('persist_cookie')[0];
    if (persistCookieTag && persistCookieTag.textContent) {
      result.persistCookie = persistCookieTag.textContent;
    }
    const customerPinTag = xml.getElementsByTagName('pin_code')[0];
    if (customerPinTag && customerPinTag.textContent) {
      result.customerPin = customerPinTag.textContent;
    }
    const payPinTag = xml.getElementsByTagName('pay_code')[0];
    if (payPinTag && payPinTag.textContent) {
      result.payPin = payPinTag.textContent;
    }
    const appIdTag = xml.getElementsByTagName('app_id')[0];
    if (appIdTag && appIdTag.textContent) {
      result.appId = appIdTag.textContent;
    }
    const deviceTag = xml.getElementsByTagName('device')[0];
    if (deviceTag) {
      const devicePublicIdTag = deviceTag.getElementsByTagName('public_id')[0];
      if (devicePublicIdTag && devicePublicIdTag.textContent) {
        result.devicePublicId = devicePublicIdTag.textContent;
      }
    }
    return result;
  };

  private handleErrorCode = (code?: string): Error => {
    switch (code) {
      case ErrorCode.UserNotExist:
      case ErrorCode.InvalidCredentials:
        return new Error(ErrorType.LoginError, code);

      case ErrorCode.BadRequest:
      case ErrorCode.InvalidXMLFormat:
        return new Error(ErrorType.SSOServerError, code);

      case ErrorCode.ServerBusy:
        return new Error(ErrorType.SSOTimeout, code);

      case ErrorCode.NoDeviceSlots:
      case ErrorCode.NoDeviceSwaps:
        return new Error(ErrorType.SSONoDeviceSlotsRemaining, code);

      case ErrorCode.AuthenticationAttemptForValidSession:
      default: // Unknown ATES error code
        return new Error(ErrorType.SSOUnknownError, code);
    }
  }
}
