import {Media} from 'mw/api/Metadata';

import {EntitledMedia} from './EntitledProductInterface';

export class EntitledMediaObject implements EntitledMedia {

  public readonly media: Media;
  public readonly relationStart?: Date;
  public readonly relationEnd?: Date;

  public constructor(media: Media, relationStart?: Date, relationEnd?: Date) {
    this.media = media;
    this.relationStart = relationStart;
    this.relationEnd = relationEnd;
  }
}
