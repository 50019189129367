export enum ActionType {
  New = 'New',
  Update = 'Update',
  Cancel = 'Cancel'
}

export class Notification {
  public sourceId: string;
  public instanceId: string;
  public interfaceContract: string;
  public actionType: ActionType;
  public deliveryWindowStart?: Date;
  public deliveryWindowEnd?: Date;
  public reference: string[] = [];

  public constructor(sourceId: string, instanceId: string, interfaceContract: string, actionType: ActionType) {
    this.sourceId = sourceId;
    this.instanceId = instanceId;
    this.interfaceContract = interfaceContract;
    this.actionType = actionType;
  }
}

export enum SEACNotificationType {
  CustomerModified = 'CustomerModified',
  ClientModified = 'ClientModified',
  SubscriptionModified = 'SubscriptionModified',
  PurchaseCompleted = 'PurchaseCompleted',
  RecordingModified = 'RecordingModified',
  CustomerPromotionModified = 'CustomerPromotionModified',
  CouponUsed = 'CouponUsed',
  ProfileModified = 'ProfileModified',
  FavoritesModified = 'FavoritesModified',
  WishlistModified = 'WishlistModified',
  WishlistSeriesCollectionModified = 'WishlistSeriesCollectionModified',
  WishlistProductsModified = 'WishlistProductsModified',
  RemindersModified = 'RemindersModified',
  Message = 'Message'
}

export class SEACNotification extends Notification {
  public notificationType: SEACNotificationType;
  public profileId?: string;
  public recordingId?: string;
  public messageType?: string;
  public payload?: any;

  public constructor(sourceId: string, instanceId: string, interfaceContract: string, actionType: ActionType, notificationType: SEACNotificationType) {
    super(sourceId, instanceId, interfaceContract, actionType);
    this.notificationType = notificationType;
  }
}

export class CustomNotification extends Notification {
  public text?: string;
  public payload?: any;

  public constructor(sourceId: string, instanceId: string, interfaceContract: string, actionType: ActionType) {
    super(sourceId, instanceId, interfaceContract, actionType);
  }
}
