import {isSeries, isTitle, Media, Title} from 'mw/api/Metadata';
import {ResponseJson} from 'mw/bo-proxy/bo/traxis/TraxisTypes';

import {AssetTypes} from './constants';
import {ContentMapper} from './ContentMapper';

type SeriesResult = {
  firstEpisode: Title;
  numberOfEpisodes: number;
}

export class SearchMapper {
  public static searchResultsFromJson(json: ResponseJson): {results: Media[]; seriesSet: Set<string>} {
    const seriesMap: Map<string, SeriesResult> = new Map();
    const results: Media[] = [];

    for (const contentJson of json.content) {
      if (contentJson.assetType === AssetTypes.Trailer) {
        continue;
      }

      const media = ContentMapper.mediaFromJson(contentJson);
      if (isTitle(media)) {
        if (!media.episode) {
          results.push(media);
        } else {
          const seriesId = media.episode.seriesId;
          const seriesResult = seriesMap.get(seriesId);
          if (!seriesResult) {
            seriesMap.set(seriesId, {
              firstEpisode: media,
              numberOfEpisodes: 1
            });
          } else {
            seriesResult.numberOfEpisodes += 1;
          }
        }
      } else if (isSeries(media)) {
        results.push(media);
      }
    }

    const seriesSet: Set<string> = new Set();
    seriesMap.forEach((seriesResult: SeriesResult, seriesId: string) => {
      if (seriesResult.numberOfEpisodes === 1) {
        results.push(seriesResult.firstEpisode);
      } else {
        seriesSet.add(seriesId);
      }
    });

    return {results, seriesSet};
  }
}
