import {useState, useMemo} from 'react';
import Reanimated from 'react-native-reanimated';

import {DateUtils} from 'common/DateUtils';
import {flatten} from 'common/HelperFunctions';

import {useFunction, useChangeEffect, useLazyRef} from 'hooks/Hooks';

import {initialXOffset} from './EpgConstants';
export const timebarItemDuration = 30; // minutes

export function tileEndDate(start: Date) {
  return new Date(start.getTime() + timebarItemDuration * DateUtils.msInMin);
}

function timeBarDatesForDay(date: Date): Date[] {
  const dates: Date[] = [];
  for (let minutes = 0; minutes < 24 * 60; minutes += timebarItemDuration) {
    dates.push(new Date(date.getTime() + minutes * DateUtils.msInMin));
  }
  return dates;
}

function tileStartForDate(date: Date): Date {
  const dayStart = DateUtils.startOfDay(date);
  const minutesFromDayStart = timebarItemDuration * Math.floor((date.getTime() - dayStart.getTime()) / (timebarItemDuration * DateUtils.msInMin));
  return new Date(dayStart.getTime() + minutesFromDayStart * DateUtils.msInMin);
}

export type UseTimeBar = {
  originDate: Date;
  nowDate: Date;
  dateForOffset: (offset: number) => Date;
}

export function useTimeBar({
  originDate,
  nowDate,
  dateForOffset
}: UseTimeBar) {
  const [currentDay, setCurrentDay] = useState(DateUtils.startOfDay(originDate));
  const [currentTileStart, setCurrentTileStart] = useState(tileStartForDate(nowDate));

  const timeBarContent = useMemo(() => {
    const dates = [
      currentDay,
      DateUtils.previousDay(currentDay),
      DateUtils.nextDay(currentDay)
    ].map(timeBarDatesForDay);

    return flatten(dates)
      .filter(start => {
        const isPast = start.getTime() < currentTileStart.getTime();
        const isFuture = start.getTime() > currentTileStart.getTime();
        return isPast || isFuture;
      })
      .map(start => {
        const end = tileEndDate(start);
        const isPast = start.getTime() < currentTileStart.getTime();
        return ({
          start,
          end,
          isPast
        });
      });
  }, [currentDay, currentTileStart]);

  // On BigScreen platforms Animated library is still used for animations.
  // This will provide a way for passing scroll offset to subcomponents that relay on Reanimated.
  // scrollOffsetChanged is a callback that is suposed to be attached as Animated.Value listener.
  const timeBarScrollOffset: Reanimated.Value<number> = useLazyRef(() => (
    new Reanimated.Value(-initialXOffset())
  )).current;
  const scrollOffsetChanged = useFunction(({value}: {value: number}) => {
    timeBarScrollOffset.setValue(-value);
    const newDay = DateUtils.startOfDay(dateForOffset(value));
    if (newDay.getTime() !== currentDay.getTime()) {
      setCurrentDay(newDay);
    }
  });

  useChangeEffect(() => {
    const newCurrentTileStart = tileStartForDate(nowDate);
    if (newCurrentTileStart.getTime() !== currentTileStart.getTime()) {
      setCurrentTileStart(newCurrentTileStart);
    }
  }, [nowDate], [currentTileStart]);

  const currentTileProgress = useMemo(
    () => (nowDate.getTime() - currentTileStart.getTime()) / (timebarItemDuration * DateUtils.msInMin),
    [currentTileStart, nowDate]
  );

  return {
    currentTileStart,
    currentTileProgress,
    timeBarContent,
    scrollOffsetChanged,
    timeBarScrollOffset
  };
}
