import {createStyles} from 'common-styles';
import React from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';

import {isBigScreen} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import ConditionalWrapper from 'components/ConditionalWrapper';
import FocusParent from 'components/FocusParent';
import {FocusSwitchProps} from 'components/FocusSwitch';
import NitroxText from 'components/NitroxText';

import {mediaDetailHorizontalMargin} from './MediaDetailUtils';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  bigScreenContainer: {
    paddingTop: 60,
    paddingBottom: 50
  },
  headline: {
    color: colors.mediaDetailsScreen.label,
    marginBottom: isBigScreen ? 30 : 10,
    marginHorizontal: mediaDetailHorizontalMargin
  }
}));

type Props = {
  title?: string;
  style?: StyleProp<ViewStyle>;
  focusable?: boolean;
} & FocusSwitchProps;

const SectionView: React.FunctionComponent<Props> = props => {
  const {title, style: propsStyle, focusable = true, children, ...focusSwitchProps} = props;
  const styles = stylesUpdater.getStyles();

  return (
    <ConditionalWrapper
      condition={focusable}
      wrapper={(children) => (
        <FocusParent {...focusSwitchProps}>
          {children}
        </FocusParent>
      )}
    >
      <View style={[isBigScreen && styles.bigScreenContainer, propsStyle]}>
        {title && (
          <NitroxText textType='headline' style={styles.headline}>
            {title}
          </NitroxText>
        )}
        <View>
          {children}
        </View>
      </View>
    </ConditionalWrapper>
  );
};

export default SectionView;
