import {CAPMessage, CAPInfo, CAPResource} from 'mw/api/EASMetadata';

interface CAPAreaJSON {
  AreaDesc: string;
}

interface CAPResourceJSON {
  ResourceDesc: string;
  MimeType: string;
  Uri?: string;
  DerefUri?: string;
}

interface CAPParameterJSON {
  ValueName?: string;
  Value?: string;
}

interface CAPInfoJSON {
  Language: string;
  Event?: string;
  SenderName?: string;
  Instruction?: string;
  Description?: string;
  CAPAreas?: {
    CAPArea: CAPAreaJSON[];
  };
  CAPResources?: {
    CAPResource: CAPResourceJSON[];
  };
  CAPParameters?: {
    CAPParameter: CAPParameterJSON[];
  };
}

export interface CAPMessageJSON {
  id: string;
  CAP?: {
    CAP?: {
      CAPInfos?: {
        CAPInfo: CAPInfoJSON[];
      };
    }[];
  };
}

export class CAPMessageMapper {
  private static fromCAPAreasDescriptionsJSON(capAreasJson: CAPAreaJSON[]): string[] {
    return capAreasJson.map((capAreaJson: any) => {
      return capAreaJson.AreaDesc;
    });
  }

  private static fromCAPResourcesJSON(capResourcesJson: CAPResourceJSON[]): CAPResource[] {
    return capResourcesJson.map((capResourceJson: any) => {
      const resource = new CAPResource(capResourceJson.ResourceDesc, capResourceJson.MimeType);
      if (capResourceJson.Uri) {
        resource.uri = capResourceJson.Uri;
      }
      if (capResourceJson.DerefUri) {
        resource.derefUri = capResourceJson.DerefUri;
      }
      return resource;
    });
  }

  private static fromCAPParametersJSON(capParametersJson: CAPParameterJSON[]): Map<string, string> {
    const params = new Map<string, string>();
    capParametersJson.forEach((capParameterJson: any) => {
      if (capParameterJson.ValueName && capParameterJson.Value) {
        params.set(capParameterJson.ValueName, capParameterJson.Value);
      }
    });
    return params;
  }

  private static fromCAPInfoJSON(capInfoJson: CAPInfoJSON): CAPInfo {
    const capInfo = new CAPInfo(capInfoJson.Language, capInfoJson.Event || '', capInfoJson.SenderName || '', capInfoJson.Instruction || '', capInfoJson.Description || '');
    const capAreasJson = capInfoJson.CAPAreas?.CAPArea;
    if (capAreasJson) {
      capInfo.areasDescriptions = CAPMessageMapper.fromCAPAreasDescriptionsJSON(capAreasJson);
    }
    const capResourcesJson = capInfoJson.CAPResources?.CAPResource;
    if (capResourcesJson) {
      capInfo.resources = CAPMessageMapper.fromCAPResourcesJSON(capResourcesJson);
    }
    const capParametersJson = capInfoJson.CAPParameters?.CAPParameter;
    if (capParametersJson) {
      capInfo.params = CAPMessageMapper.fromCAPParametersJSON(capParametersJson);
    }
    return capInfo;
  }

  private static fromCAPInfosJSON(capInfosJson: CAPInfoJSON[]): CAPInfo[] {
    return capInfosJson.map(CAPMessageMapper.fromCAPInfoJSON);
  }

  public static fromJSON(json: CAPMessageJSON): CAPMessage {
    const message = new CAPMessage(json.id);
    const capInfoJson = json?.CAP?.CAP?.[0]?.CAPInfos?.CAPInfo;
    if (capInfoJson) {
      message.infos = CAPMessageMapper.fromCAPInfosJSON(capInfoJson);
    }
    return message;
  }
}
