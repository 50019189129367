import {createStyles} from 'common-styles';
import React, {memo, useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {View, ActivityIndicator} from 'react-native';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import FocusParent from 'components/FocusParent';
import NitroxText from 'components/NitroxText';

import {PAGE_SIZE} from './SettingsOrderProps';

const rowOffset = 2;

const styles = createStyles({
  cell: {
    alignItems: 'center',
    paddingHorizontal: dimensions.margins.large,
    justifyContent: 'center'
  },
  cellFullWidth: {
    flexShrink: 1,
    flexGrow: 1
  },
  row: {
    height: dimensions.table.rowHeight,
    borderRadius: dimensions.table.borderRadius,
    flexDirection: 'row',
    marginVertical: rowOffset
  },
  head: {
    height: dimensions.table.head,
    paddingVertical: dimensions.margins.xsmall
  },
  separator: {
    width: 1,
    height: dimensions.table.separator,
    alignSelf: 'center',
    opacity: dimensions.opacity.xlow
  },
  separatorHead: {
    height: '100%'
  },
  loadingContainer: {
    height: PAGE_SIZE * (dimensions.table.rowHeight + 2 * rowOffset) + dimensions.table.head + 2 * rowOffset,
    justifyContent: 'center'
  },
  cellText: {
    textAlign: 'center'
  },
  cellTextLeft: {
    alignSelf: 'flex-start'
  }
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  rowEven: {
    backgroundColor: colors.table.backgroundEven
  },
  rowOdd: {
    backgroundColor: colors.table.backgroundOdd
  },
  cellText: {
    color: colors.settingsScreen.title
  },
  separator: {
    backgroundColor: colors.table.backgroundSeparator
  }
}));

const isComponent = (m: JSX.Element | string | number): m is JSX.Element => typeof m !== 'string' && typeof m !== 'number';

export interface ContentProps<T> {
  index: number;
  page: number;
  row: T;
}

export enum HeadCellType {
  Default = 1,
  Left
}

export interface HeadCellProps<T> {
  width?: number;
  head?: string;
  text?: string;
  content: (options: ContentProps<T>) => JSX.Element | string | number;
  type?: HeadCellType;
  noEllipsis?: boolean;
}

interface TableBodyProps<T> {
  rowConfig: HeadCellProps<T>;
  index?: number;
  row?: T;
  page: number;
  head?: boolean;
  rowIndex?: number;
}

interface TableProps<T> {
  data: T[];
  page: number;
  loading: boolean;
  tableConfig: HeadCellProps<T>[];
}

interface TableCellProps {
  width?: number;
  component?: JSX.Element;
}

const TableCell = memo(({component, width}: TableCellProps) => (
  <View style={[styles.cell, width ? {width} : styles.cellFullWidth]}>
    {component}
  </View>
));

TableCell.displayName = 'TableCell';

const Table = <T extends {id: string}>({data, page, loading, tableConfig}: TableProps<T>) => {
  const {t} = useTranslation();
  const dynamicStyles = stylesUpdater.getStyles();

  const separatorBody = useMemo(() => <View style={[styles.separator, dynamicStyles.separator]} />, [dynamicStyles.separator]);
  const separatorHead = useMemo(() => <View style={[styles.separator, styles.separatorHead, dynamicStyles.separator]} />, [dynamicStyles.separator]);

  const createCell = useCallback(({rowConfig, head, ...tableProps}: TableBodyProps<T>) => {
    const separator = head ? separatorHead : separatorBody;
    let content: JSX.Element | string | number, component: JSX.Element | undefined;
    if (!head) {
      content = rowConfig.content({...tableProps as ContentProps<T>});
      if (isComponent(content)) {
        component = content;
      } else if (rowConfig.type) {
        const style = rowConfig.type === HeadCellType.Left ? styles.cellTextLeft : undefined;
        const numberOfLines = rowConfig.noEllipsis ? undefined : 1;
        component = <NitroxText style={[style, dynamicStyles.cellText]} textType='subhead' numberOfLines={numberOfLines}>{content}</NitroxText>;
      }
    } else {
      component = rowConfig.head ? <NitroxText style={dynamicStyles.cellText} textType='options-texts' numberOfLines={1}>{t(rowConfig.head)}</NitroxText> : undefined;
    }
    return (
      <>
        {!!tableProps.rowIndex && separator}
        <TableCell width={rowConfig.width} component={component} />
      </>
    );
  }, [dynamicStyles.cellText, separatorBody, separatorHead, t]);

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size={dimensions.icon.medium} />
      </View>
    );
  }

  return (
    <FocusParent rememberLastFocused>
      {/* Table Head */}
      <View style={[styles.row, styles.head, dynamicStyles.rowEven]}>
        {tableConfig.map((rowConfig: HeadCellProps<T>, rowIndex: number) => createCell({rowConfig, page, head: true, rowIndex}))}
      </View>
      {/* Table Body */}
      {data.map((row, index) => (
        <View key={row.id} style={[styles.row, index % 2 ? dynamicStyles.rowEven : dynamicStyles.rowOdd]}>
          {tableConfig.map((rowConfig: HeadCellProps<T>, rowIndex: number) => createCell({rowConfig, index, row, page, rowIndex}))}
        </View>
      ))}
    </FocusParent>
  );
};

export default Table;
