import {Content, EntitlementState, PlayableType, PlaybackProperties, Product, ExternalLink, ExternalIds} from 'mw/api/Metadata';

export class ContentObject implements Content {
  public duration = 0;
  public firstAvailability?: Date;
  public lastAvailability?: Date;
  public entitlementEnd?: Date;
  public playbackProperties?: PlaybackProperties;
  public isAdult = false;
  public recommendedPlaybackOffset = 0;
  public externalLink?: ExternalLink;
  public reporter?: string;
  public externalIds: ExternalIds = {};

  public constructor(
    public readonly id: string,
    public products: Product[] = [],
    public entitlementState: EntitlementState = EntitlementState.NotEntitled
  ) {}

  public getUrl(): string {
    return '';
  }

  public hasFallbackPlayback(): boolean {
    return false;
  }

  public getPlayableType(): PlayableType {
    return PlayableType.Content;
  }

  public getId(): string {
    return this.id;
  }

  public isAllowed(): boolean {
    const now = new Date();
    return this.isEntitled
      && this.entitlementEnd && this.entitlementEnd > now
      && this.firstAvailability && this.firstAvailability < now
      && this.lastAvailability && this.lastAvailability > now
      || false;
  }

  public get isEntitled(): boolean {
    return this.entitlementState === EntitlementState.Entitled;
  }
}
