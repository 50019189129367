import {createStyles} from 'common-styles';
import i18next from 'i18next';
import React, {useState, useMemo, useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions, isBigScreen, isTablet, isMobile, Direction} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {EASAlert} from 'mw/api/EASMetadata';

import ArrowsPageNavigator, {arrowWidth, arrowHeight} from 'components/ArrowsPageNavigator';
import DotsPageNavigator, {dotSize, dotMargin} from 'components/DotsPageNavigator';
import FocusParent, {useFocusParentUtility} from 'components/FocusParent';
import GesturePageNavigator from 'components/GesturePageNavigator';
import InteractiveScrollView from 'components/InteractiveScrollView';
import NitroxButton from 'components/NitroxButton';
import NitroxText from 'components/NitroxText';
import {useScreenInfo, useChangeEffect} from 'hooks/Hooks';
import {Namespaces} from 'locales/i18n';
import {PostProcessors} from 'locales/i18nPostProcessors';

const easNamespaces = [`${Namespaces.Branded}.eas`, `${Namespaces.Translation}.eas`];
const borderRadius = dimensions.margins.xxLarge;
const delimiterHeight = 32;
const delimiterMargin = dimensions.margins.small;
const buttonHeight = 50;
const buttonMargin = isMobile ? dimensions.margins.xxLarge : dimensions.margins.xxxLarge;

const previousPageOn = [Direction.Left];
const nextPageOn = [Direction.Right];

const staticStyles = createStyles({
  container: {
    flex: 1,
    borderRadius,
    paddingHorizontal: borderRadius
  },
  content: {
    flex: -1,
    minWidth: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  messageSection: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center'
  },
  menuSection: {
    // Menu section has to be layouted by anchoring it at bottom in order to allow to dynamically allocate as much space as possible for the message.
    // Because of that height for this view has to be explicitly set in order to keep at the same position.
    height: delimiterHeight + delimiterMargin + buttonHeight + buttonMargin + dotSize + dotMargin,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  pageText: {
    marginTop: isBigScreen ? 24 : 10
  },
  messageScrollView: {
    flex: 1,
    marginTop: isBigScreen ? 55 : 62,
    marginHorizontal: isBigScreen ? arrowWidth : 0
  },
  delimiterText: {
    height: delimiterHeight,
    marginTop: delimiterMargin
  },
  button: {
    height: buttonHeight
  },
  leftArrow: {
    marginRight: Math.floor(arrowWidth / 2),
    maringTop: -Math.floor(arrowHeight / 2)
  },
  rightArrow: {
    marginLeft: Math.floor(arrowWidth / 2),
    maringTop: -Math.floor(arrowHeight / 2)
  }
});

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    backgroundColor: colors.easAlertModal.background
  },
  text: {
    color: colors.easAlertModal.text
  }
}));

type EASAlertModalContentProps = {
  easAlert: EASAlert;
  preventClosing?: boolean;
  onClose?: () => void;
};

const EASAlertModalContent: React.FC<EASAlertModalContentProps> = ({
  easAlert, preventClosing, onClose
}) => {
  const dynamicStyles = dynamicStylesUpdater.getStyles();
  const {i18n} = useTranslation();

  const [pageIndex, setPageIndex] = useState(0);

  // jump to first page when content is reused with previously enqueued alert
  useChangeEffect(() => {
    setPageIndex(0);
  }, [easAlert]);

  // translators for every language that appears in the incoming CAP infos
  // getFixedT will always return some translator that should be most suitable for the given language
  const translations = useMemo(() => {
    const result: {[uiLanguage: string]: i18next.TFunction} = {};
    easAlert.infos.forEach((easInfo) => {
      result[easInfo.uiLanguage] = i18n.getFixedT(easInfo.uiLanguage, easNamespaces);
    });
    return result;
  }, [i18n, easAlert]);

  // translator used for current page
  const t = useMemo(() => {
    const easInfo = easAlert.infos[pageIndex];
    return translations[easInfo.uiLanguage];
  }, [translations, pageIndex, easAlert]);

  // page indicator text for current page
  const pageText = useMemo(() => {
    return t('eas.pageNumber', {
      pageNumber: pageIndex + 1,
      numberOfPages: easAlert.infos.length
    });
  }, [t, pageIndex, easAlert]);

  // alert message for current page
  const messageText = useMemo(() => {
    const easInfo = easAlert.infos[pageIndex];
    return easInfo.message || t('eas.message', {
      senderName: easInfo.senderName,
      event: easInfo.event,
      areasDescriptions: easInfo.areasDescriptions.join(', '),
      instruction: easInfo.instruction
    });
  }, [t, pageIndex, easAlert]);

  // next page/language delimiter 
  const delimiterText = useMemo(() => {
    const nextEASInfo = easAlert.infos[pageIndex + 1];
    const nextT = translations[nextEASInfo?.uiLanguage];
    return nextT?.('eas.delimiter') || '';
  }, [translations, pageIndex, easAlert]);

  // orientation-dependent styles
  const {orientation} = useScreenInfo();
  const dynamicContentStyles = useMemo(() => {
    if (isTablet) {
      return {paddingTop: orientation.isLandscape ? 135 : 270};
    }
    if (isMobile) {
      return {paddingTop: orientation.isLandscape ? borderRadius : 120}; // borderRadius is the minimum value that looks symmetrical
    }
    return {paddingTop: 72}; // big screens 
  }, [orientation]);
  const dynamicMenuStyles = useMemo(() => {
    if (isTablet) {
      return {paddingBottom: orientation.isLandscape ? 207 : 346};
    }
    if (isMobile) {
      return {paddingBottom: orientation.isLandscape ? dimensions.margins.small : 173};
    }
    return {paddingBottom: 72}; //big screens 
  }, [orientation]);
  const dynamicMessageScrollViewStyles = useMemo(() => {
    if (isBigScreen) {
      return {marginTop: 55};
    }
    return {
      marginTop: orientation.isLandscape ? dimensions.margins.small : 62,
      paddingBottom: orientation.isLandscape ? dimensions.margins.small : 0
    };
  }, [orientation]);
  const dynamicButtonStyles = useMemo(() => ({
    // delimiterMargin is the minimum value that looks symmetrical to the delimiter that is placed above the buttons
    marginTop: isMobile && orientation.isLandscape ? delimiterMargin : buttonMargin
  }), [orientation]);

  // there is neither wraparound nor a possibility to skip an alert - user can only navigate by moving forward or backward by one page
  const onPageSelected = useCallback((newPageIndex: number) => {
    setPageIndex((currentPageIndex) =>
      Math.max(currentPageIndex - 1, Math.min(currentPageIndex + 1, newPageIndex))
    );
  }, []);

  const [focusHoldersCount, setFocusHoldersCount] = useState(0);
  const {focus: focusNearestParent} = useFocusParentUtility();

  useEffect(() => focusNearestParent?.(), [pageIndex, preventClosing, focusHoldersCount, focusNearestParent]);

  const hasPages = easAlert.infos.length > 1;
  const isLastPage = pageIndex === easAlert.infos.length - 1;

  return (
    <View style={[dynamicStyles.container, staticStyles.container]}>
      <GesturePageNavigator
        pageIndex={pageIndex}
        numberOfPages={easAlert.infos.length}
        previousPageOn={previousPageOn}
        nextPageOn={nextPageOn}
        onPageSelected={onPageSelected}
      >
        <View style={[dynamicContentStyles, staticStyles.content]}>
          <View style={staticStyles.messageSection}>
            <NitroxText style={[dynamicStyles.text]} textType='eas-alert-header'>{t('eas.header', {postProcess: PostProcessors.ToUpperCase})}</NitroxText>
            {pageText && <NitroxText style={[dynamicStyles.text, staticStyles.pageText]} textType='eas-alert-callout'>{pageText}</NitroxText>}
            <ArrowsPageNavigator
              containerStyle={[staticStyles.messageScrollView, dynamicMessageScrollViewStyles]}
              leftArrowStyle={staticStyles.leftArrow}
              rightArrowStyle={staticStyles.rightArrow}
              pageIndex={pageIndex}
              numberOfPages={easAlert.infos.length}
              onPageSelected={onPageSelected}
            >
              <InteractiveScrollView
                hasTVPreferredFocus={!!focusHoldersCount}
                alwaysBounceVertical={false}
                showsHorizontalScrollIndicator={false}
                scrollStep={100}
                onFocusHoldersCount={setFocusHoldersCount}
              >
                <NitroxText style={dynamicStyles.text} textType='eas-alert-message'>{messageText}</NitroxText>
              </InteractiveScrollView>
            </ArrowsPageNavigator>
          </View>
          <FocusParent>
            <View style={[dynamicMenuStyles, staticStyles.menuSection]}>
              <NitroxText style={[dynamicStyles.text, staticStyles.delimiterText]} textType='eas-alert-callout'>{delimiterText}</NitroxText>
              {(isMobile && hasPages) && (
                <DotsPageNavigator
                  pageIndex={pageIndex}
                  numberOfPages={easAlert.infos.length}
                  onPageSelected={onPageSelected}
                />
              )}
              {(isLastPage || isBigScreen) && (
                <NitroxButton
                  border={isBigScreen}
                  style={[staticStyles.button, dynamicButtonStyles]}
                  disabled={preventClosing}
                  text={t('common.close')}
                  onPress={onClose}
                />
              )}
            </View>
          </FocusParent>
        </View>
      </GesturePageNavigator>
    </View>
  );
};

export default React.memo(EASAlertModalContent);
