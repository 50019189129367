import {Media, isEpisode, isTitle} from 'mw/api/Metadata';
import {ADR8Utils} from 'mw/bo-proxy/bo/adr8/ADR8Utils';

export class MediaMapper {
  public static getViewedVodTitles(limit: number, media: Media[]): Media[] {
    return media
      .filter(el => isTitle(el) && !isEpisode(el) && ADR8Utils.continueWatchingProgressFilter(el.progress))
      .slice(0, limit);
  }

  public static getViewedSeriesTitles(limit: number, media: Media[]): Media[] {
    return media
      .filter(el => isTitle(el) && isEpisode(el) && ADR8Utils.continueWatchingProgressFilter(el.progress))
      .slice(0, limit);
  }
}
