import {createStyles} from 'common-styles';
import i18next from 'i18next';
import React, {useMemo, useRef, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ViewStyle} from 'react-native';

import {delay} from 'common/Async';
import {isBigScreen, isMobile} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {ProfileConfigurableProperties, PinState, PCRatingsMap} from 'mw/api/Profile';
import {mw, PCType} from 'mw/MW';

import {useSTBMenu} from 'components/navigation/STBNavigationView';
import Wizard, {NextStepRequestResponse, WizardStep, WizardElements} from 'components/Wizard';
import {useNavigation, useChangeEffect} from 'hooks/Hooks';
import {PCTranslationsType} from 'screens/settings/ParentalControlHelpers';

import {validateProfileName, validateYearOfBirth} from './profileValidation';
import WizardBirthPage from './profileWizard/WizardBirthPage';
import WizardConfigurationPage from './profileWizard/WizardConfigurationPage';
import WizardNamePage from './profileWizard/WizardNamePage';
import WizardParentalControlRatingsPage from './profileWizard/WizardParentalControlRatingsPage';
import WizardSummaryPage from './profileWizard/WizardSummaryPage';

// summary page is not a real wizard step, therefore should not be considered for progress tracker
const numberOfSteps = 3;

type WizardProfileConfiguration = Partial<ProfileConfigurableProperties> & {pin: string};

function createWizardNamePageStep(config: Pick<WizardProfileConfiguration, 'name'>): WizardStep {
  let name = config.name || '';

  function nameChanged(newName: string) {
    name = newName.trim();
  }

  async function onConfirm(): Promise<NextStepRequestResponse> {
    const validationError = validateProfileName(name);
    if (validationError) {
      return {ok: false, error: validationError};
    }
    config.name = name;
    return {ok: true};
  }

  return {
    showStepper: true,
    // eslint-disable-next-line react/display-name
    renderContent: elements => (
      <WizardNamePage
        name={name}
        onNameChanged={nameChanged}
        {...elements}
      />
    ),
    onConfirm,
    embedElements: true
  };
}

function setPin(config: Pick<WizardProfileConfiguration, 'pin' | 'pinState'>, newPin: string) {
  config.pin = newPin;
  if (!newPin) {
    config.pinState = PinState.ProfilePinNotSet;
  } else if (config.pinState === PinState.ProfilePinNotSet) {
    config.pinState = PinState.ProfilePinNotRequired;
  }
}

type ConfigType = Pick<WizardProfileConfiguration, 'pin' | 'pinState' | 'uiLanguage' | 'audioLanguage' | 'subtitleLanguage'>;

const createWizardConfigurationPageStep = (config: ConfigType, finishWizard: () => Promise<NextStepRequestResponse>): WizardStep => {
  const onConfirm = (): Promise<NextStepRequestResponse> => finishWizard();
  const onPinChanged = (newPin: string) => setPin(config, newPin);
  const onProtectWithPINChanged = (pinRequired: boolean) => {
    if (config.pin) {
      config.pinState = pinRequired ? PinState.ProfilePinRequired : PinState.ProfilePinNotRequired;
    }
  };

  return {
    showStepper: true,
    // eslint-disable-next-line react/display-name
    renderContent: elements => (
      <WizardConfigurationPage
        pin={config.pin}
        pinRequired={config.pinState === PinState.ProfilePinRequired}
        uiLanguage={config.uiLanguage}
        audioLanguage={config.audioLanguage}
        subtitleLanguage={config.subtitleLanguage}
        onPinChanged={onPinChanged}
        onProtectWithPINChanged={onProtectWithPINChanged}
        onUILanguageChanged={(language: string) => config.uiLanguage = language}
        onAudioLanguageChanged={(language: string) => config.audioLanguage = language}
        onSubtitlesLanguageChanged={(language: string) => config.subtitleLanguage = language}
        {...elements}
      />
    ),
    onConfirm,
    embedElements: true
  };
};

type DynamicStyles = {
  summaryPageHeader: ViewStyle;
};

const stylesUpdater = new StylesUpdater<DynamicStyles>((colors: BaseColors) => createStyles({
  summaryPageHeader: {
    backgroundColor: colors.settingsScreen.background
  }
}));

function createSummaryPage(config: Pick<WizardProfileConfiguration, 'name'>, t: i18next.TFunction, styles: DynamicStyles): WizardStep {
  return {
    showStepper: false,
    title: '',
    showBackButton: false,
    forwardButtonText: t('common.ok'),
    embedElements: isBigScreen,
    mobileHeaderStyle: styles.summaryPageHeader,
    onConfirm: () => {
      return Promise.resolve({ok: true});
    },
    // eslint-disable-next-line react/display-name
    renderContent: elements => (
      <WizardSummaryPage
        profileName={config.name || ''}
        {...elements}
      />
    )};
}

function createWizardBirthPageStep(config: Pick<WizardProfileConfiguration, 'yearOfBirth' | 'isPCEnabled' | 'pin' | 'pinState'>): WizardStep {

  async function onConfirm(): Promise<NextStepRequestResponse> {
    const validationError = validateYearOfBirth(config.yearOfBirth, config.isPCEnabled);
    if (validationError) {
      return {ok: false, error: validationError};
    }
    return {ok: true};
  }

  const onPinChanged = (newPin: string) => setPin(config, newPin);

  return {
    // eslint-disable-next-line react/display-name
    renderContent: elements => (
      <WizardBirthPage
        yearOfBirth={config.yearOfBirth}
        parentalControl={config.isPCEnabled}
        onBirthDateChanged={year => config.yearOfBirth = year}
        onParentalChanged={parental => config.isPCEnabled = parental}
        autoFocus={isMobile}
        onPinChanged={onPinChanged}
        pinState={config.pinState ?? PinState.ProfilePinNotSet}
        {...elements}
      />
    ),
    onConfirm,
    showStepper: true,
    embedElements: true
  };
}

type WizardRatingsPageStepConfig = Pick<WizardProfileConfiguration, 'isPCEnabled' | 'blockUnratedEvents' | 'blockUnratedMovies' | 'pcRatings' | 'showAdultContent' | 'pin' | 'pinState'>;

type WizardRatingsPageStepProps = {
  config: WizardRatingsPageStepConfig;
  elements?: WizardElements;
  onParentalChanged: (enabled: boolean) => void;
  onPCRatingChanged: (pcRatings: PCRatingsMap) => void;
  onBlockUnratedEventsChanged: (block: boolean) => void;
  onBlockUnratedMoviesChanged: (block: boolean) => void;
  onShowAdultContentChanged: (show: boolean) => void;
};

const WizardRatingsPageStep: React.FC<WizardRatingsPageStepProps> = ({
  config,
  elements,
  onParentalChanged: propsOnParentalChanged,
  onPCRatingChanged: propsOnPCRatingChanged,
  onBlockUnratedEventsChanged: propsOnBlockUnratedEventsChanged,
  onBlockUnratedMoviesChanged: propsOnBlockUnratedMoviesChanged,
  onShowAdultContentChanged: propsOnShowAdultContentChanged
}) => {
  const [parentalControl, setParentalControl] = useState(!!config.isPCEnabled);
  const [pcRatings, setPCRatings] = useState<PCRatingsMap>(config.pcRatings ?? {});
  const [blockUnratedEvents, setBlockUnratedEvents] = useState(!!config.blockUnratedEvents);
  const [blockUnratedMovies, setBlockUnratedMovies] = useState(!!config.blockUnratedMovies);
  const [showAdultContent, setShowAdultContent] = useState(!!config.showAdultContent);

  const onParentalChanged = useCallback((enabled: boolean) => {
    setParentalControl(enabled);
    propsOnParentalChanged(enabled);
  }, [propsOnParentalChanged]);

  const onPCRatingChanged = useCallback((authority: string, rating: string) => {
    setPCRatings((pcRatings: PCRatingsMap) => ({...pcRatings, [authority]: rating}));
  }, []);

  useChangeEffect(() => propsOnPCRatingChanged(pcRatings), [pcRatings], [propsOnPCRatingChanged]);

  const onBlockUnratedEventsChanged = useCallback((block: boolean) => {
    setBlockUnratedEvents(block);
    propsOnBlockUnratedEventsChanged(block);
  }, [propsOnBlockUnratedEventsChanged]);

  const onBlockUnratedMoviesChanged = useCallback((block: boolean) => {
    setBlockUnratedMovies(block);
    propsOnBlockUnratedMoviesChanged(block);
  }, [propsOnBlockUnratedMoviesChanged]);

  const onShowAdultContentChanged = useCallback((show: boolean) => {
    setShowAdultContent(show);
    propsOnShowAdultContentChanged(show);
  }, [propsOnShowAdultContentChanged]);

  const onPinChanged = (newPin: string) => setPin(config, newPin);

  return (
    <WizardParentalControlRatingsPage
      parentalControl={parentalControl}
      pcRatings={pcRatings}
      pcTranslationsType={PCTranslationsType.Overlay}
      blockUnratedEvents={blockUnratedEvents}
      blockUnratedMovies={blockUnratedMovies}
      showAdultContent={showAdultContent}
      onParentalChanged={onParentalChanged}
      onPCRatingChanged={onPCRatingChanged}
      onBlockUnratedEventsChanged={onBlockUnratedEventsChanged}
      onBlockUnratedMoviesChanged={onBlockUnratedMoviesChanged}
      onShowAdultContentChanged={onShowAdultContentChanged}
      onPinChanged={onPinChanged}
      pinState={config.pinState ?? PinState.ProfilePinNotSet}
      {...elements}
    />
  );
};

function createWizardRatingsPageStep(config: WizardRatingsPageStepConfig): WizardStep {
  async function onConfirm(): Promise<NextStepRequestResponse> {
    return {ok: true};
  }

  return {
    // eslint-disable-next-line react/display-name
    renderContent: elements => (
      <WizardRatingsPageStep
        config={config}
        elements={elements}
        onParentalChanged={(enabled) => config.isPCEnabled = enabled}
        onPCRatingChanged={(pcRatings) => config.pcRatings = pcRatings}
        onBlockUnratedEventsChanged={(block) => config.blockUnratedEvents = block}
        onBlockUnratedMoviesChanged={(block) => config.blockUnratedMovies = block}
        onShowAdultContentChanged={(show) => config.showAdultContent = show}
      />
    ),
    onConfirm,
    showStepper: true,
    embedElements: true
  };
}

const CreateProfileWizard: React.FC<{pin: string}> = () => {
  const navigation = useNavigation();
  const {t} = useTranslation();
  const masterPin = navigation.getParam('pin', '');
  const styles = stylesUpdater.getStyles();
  const config = useRef<WizardProfileConfiguration>({
    pin: mw.configuration.defaultProfilePin,
    uiLanguage: mw.configuration.defaultUILanguage,
    audioLanguage: mw.configuration.defaultAudioLanguage,
    subtitleLanguage: mw.configuration.defaultSubtitlesLanguage,
    pinState: PinState.ProfilePinNotSet
  });

  const onConfigStepConfirm: () => Promise<NextStepRequestResponse> = useCallback(async () => {
    try {
      const result = await mw.customer.addProfile(config.current, masterPin);
      if (result.error) {
        throw result.error;
      }
      return {ok: true};
    } catch (error) {
      return {
        ok: false,
        moveForward: true,
        error: {
          ...error,
          message: t('settings.errors.profileCreationError')
        }
      };
    }
  }, [masterPin, t]);

  const wizardSteps: WizardStep[] = useMemo(() => {
    const steps = [createWizardNamePageStep(config.current)];
    if (mw.configuration.pcPolicy.type === PCType.RatingsBased) {
      if (mw.configuration.supportedPCRatings.length > 0) {
        steps.push(createWizardRatingsPageStep(config.current));
      }
    } else {
      steps.push(createWizardBirthPageStep(config.current));
    }
    steps.push(
      createWizardConfigurationPageStep(config.current, onConfigStepConfirm),
      createSummaryPage(config.current, t, styles)
    );
    return steps;
  }, [onConfigStepConfirm, styles, t]);

  const menu = useSTBMenu();

  const closeWizard = useCallback(async () => {
    navigation.pop();

    if (isBigScreen) {
      // TODO CL-7535, focus should be restored by default if screen does not handle its initial focus flow.
      await delay(0);
      menu?.focusMenu();
    }
  }, [menu, navigation]);

  return (
    <Wizard
      steps={wizardSteps}
      title={t('settings.createProfile')}
      onCancel={closeWizard}
      onDone={closeWizard}
      numberOfSteps={numberOfSteps}
    />
  );
};

export default CreateProfileWizard;
