export type ConsentInfo = {
  slug: string;
  version: string;
  accepted: boolean;
  timestamp?: Date;
}

export enum ConsentSlug {
  NitroxEula = 'nitrox-eula'
}
