import React, {useCallback, useMemo, useRef} from 'react';
import {useEffect, useState} from 'react';
import {ActivityIndicator, View, ListRenderItemInfo, LayoutChangeEvent} from 'react-native';

import {dimensions} from 'common/constants';
import {idKeyExtractor, isTruthy, getRatingToDisplay} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {Event, Channel} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import {useChromecastExtraBottomPadding} from 'components/chromecast/ChromecastExtraBottomPadding';
import {ListShadow, ListShadowPosition} from 'components/ListShadow';
import NitroxFlatList from 'components/NitroxFlatList';
import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';
import {stylesUpdater} from 'components/styles/EpgOneDimensionList.style';
import {MediaIcons, prepareEventIcons} from 'components/TileIconsRow';
import EventTile, {useEventTileSize} from 'components/zapper/EventTile';
import EventTileSeparator, {epgTileSeparatorHeight} from 'components/zapper/EventTileSeparator';
import {useWatchList, useForceUpdate, useChangeEffect, useDisposable, useNearestLiveEvent, useDebounce, useLazyEffect, useFunction} from 'hooks/Hooks';

const TAG = 'EpgOneDimensionList';

const fetchEPGEvents = async (channels: Channel[], date?: Date) => {
  const parsedEvents: Event[] = [];
  const time = date || new Date();
  await mw.catalog.unblockIdleActions();
  const data = await mw.catalog.getEPG({channels, startTime: time, endTime: time});
  data.forEach((events: Event[]) => parsedEvents.push(...events));

  return parsedEvents;
};

type ItemProps = {
  info: ListRenderItemInfo<Event>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraData?: any;
  onMoreActionsPress?: (event: Event) => void;
  onActionsPress?: (event: Event) => void;
  isCurrentItem: boolean;
}

const EpgOneDimensionListItem: React.FC<ItemProps> = React.memo(props => {
  const {extraData, onMoreActionsPress, onActionsPress} = props;
  const event = props.info.item;
  const {isMediaBlocked} = useParentalControl();
  const isBlocked = isMediaBlocked(event);

  const onMoreActionsPressCallback = useCallback(() => {
    onMoreActionsPress && onMoreActionsPress(event);
  }, [onMoreActionsPress, event]);

  const onActionsPressCallback = useCallback(() => {
    onActionsPress && onActionsPress(event);
  }, [onActionsPress, event]);

  const progress = useMemo(() => (
    event.isNow ? (Date.now() - event.start.getTime()) / (event.end.getTime() - event.start.getTime()) : 0
  ), [event.end, event.isNow, event.start]);

  const subtitle = useMemo(() => [getRatingToDisplay(event), ...event.title.metadata.genres.map(genre => genre.name)].filter(isTruthy).join(', '), [event]);

  const [mediaIcons, setMediaIcons] = useState<MediaIcons>({});
  useEffect(() => {
    setMediaIcons(prepareEventIcons(event, {isBlocked}));
  }, [event, event.isNow, event.isOnWatchList, extraData, isBlocked]);  // isNow and isOnWatchList are computed properties which can change independently from the event's object reference

  return (
    <EventTile
      mediaId={event.id}
      channelId={event.channelId}
      title={event.name}
      use='picture'
      mediaIcons={mediaIcons}
      subtitle={subtitle}
      progress={progress}
      onActionsPress={onActionsPressCallback}
      onMoreActionsPress={onMoreActionsPressCallback}
      showProgress={event.isNow}
      isSelected={props.isCurrentItem}
    />
  );
});
EpgOneDimensionListItem.displayName = 'EpgOneDimensionListItem';

type Props = {
  channels: Channel[];
  currentChannel?: Channel;
  date?: Date;
  onActionsPress?: (event: Event) => void;
  onMoreActionsPress?: (event: Event) => void;
};

const EpgOneDimensionList: React.FunctionComponent<Props> = props => {
  const {onActionsPress, onMoreActionsPress, channels, date} = props;
  const [events, setEvents] = useState<Event[]>([]);
  const watchList = useWatchList();
  const {forceUpdate, forceUpdateState} = useForceUpdate();
  const [refreshing, setRefreshing] = useState(true);
  const styles = stylesUpdater.getStyles();
  const initialScrollPerformed = useRef<boolean>(false);
  const flatListRef = useRef<NitroxFlatList<Event>>(null);
  const eventTileSize = useEventTileSize();
  const eventTileWithSeparatorHeight = eventTileSize.totalHeight + epgTileSeparatorHeight;
  const [height, setHeight] = useState(0);
  const nearestliveEvent = useNearestLiveEvent(useCallback(() => events, [events]));

  const getEPGEvents = useDisposable((channels: Channel[], date: Date) => fetchEPGEvents(channels, date));
  const loadEpgData = useFunction((channels: Channel[], date: Date) => {
    getEPGEvents(channels, date)
      .then(setEvents)
      .catch(error => {
        setEvents([]);
        Log.error(TAG, `Error loading EPG data for date ${date}`, error);
      })
      .finally(() => setRefreshing(false));
  });

  // When EPG data is retrieved from cache mw.catalog.getEPG is synchronous and it blocks UI for too long.
  // Small delay is needed to make it more responsive.
  const debounceLoadEpgData = useDebounce(loadEpgData, 250);
  useEffect(() => {
    if (!date || !channels.length) {
      return;
    }
    setRefreshing(true);
    debounceLoadEpgData(channels, date)
      .catch(error => Log.warn(TAG, 'Error while loading EPG data', error));
  }, [channels, date, debounceLoadEpgData]);

  const scrollToOffset = useDebounce((offset: number) => {
    flatListRef.current?.scrollToOffset({animated: false, offset});
  }, 0);

  useLazyEffect(() => {
    if (!initialScrollPerformed.current && props.currentChannel && events.length > 0 && height > 0) {
      const initialEventIndex = events.findIndex((event: Event) => event.channelId === props.currentChannel?.id);
      const offset = Math.max(0, initialEventIndex * eventTileWithSeparatorHeight - (height - eventTileWithSeparatorHeight) / 2);
      const contentContainerHeight = events.length * eventTileWithSeparatorHeight;
      if (offset < contentContainerHeight) {
        initialScrollPerformed.current = true;
        scrollToOffset(offset);
      }
    }
  }, [events, height], [eventTileWithSeparatorHeight, props.currentChannel]);

  // update event icons that change over time
  useChangeEffect(forceUpdate, [watchList, nearestliveEvent], [forceUpdate]);

  const onLayout = useFunction((event: LayoutChangeEvent) => {
    setHeight(event.nativeEvent.layout.height);
  });

  const getItemLayout = useCallback((_, index: number) => (
    {length: eventTileSize.totalHeight, offset: eventTileSize.totalHeight * index, index}
  ), [eventTileSize]);

  const renderItem = useCallback((info: ListRenderItemInfo<Event>) => (
    <EpgOneDimensionListItem
      info={info}
      extraData={forceUpdateState}
      onMoreActionsPress={onMoreActionsPress}
      onActionsPress={onActionsPress}
      isCurrentItem={props.currentChannel?.id === info.item.channelId}
    />
  ), [onActionsPress, onMoreActionsPress, props.currentChannel, forceUpdateState]);

  const renderSeparator = useCallback(() => <EventTileSeparator />, []);

  const chromecastExtraBottomPadding = useChromecastExtraBottomPadding();

  return (
    <View style={styles.container} onLayout={onLayout}>
      <NitroxFlatList
        contentContainerStyle={chromecastExtraBottomPadding}
        ref={flatListRef}
        data={events}
        extraData={forceUpdateState}
        getItemLayout={getItemLayout}
        keyExtractor={idKeyExtractor}
        ItemSeparatorComponent={renderSeparator}
        renderItem={renderItem}
        refreshing={refreshing}
        testID='epg_list'
      />
      <View style={styles.activityIndicatorContainer} pointerEvents='none'>
        <ActivityIndicator
          color={styles.activityIndicatorColor}
          size={dimensions.icon.medium}
          animating={refreshing}
        />
      </View>
      <ListShadow direction={ListShadowPosition.Bottom} style={{bottom: 0}} />
    </View>
  );
};

export default EpgOneDimensionList;
