import {createStyles} from 'common-styles';
import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View, ScrollView} from 'react-native';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {NativeNotification, newStylingNotificationId} from 'mw/api/System';
import {mw} from 'mw/MW';

import NitroxButton, {NitroxButtonTheme} from 'components/NitroxButton';
import NitroxText from 'components/NitroxText';
import {useDisposable} from 'hooks/Hooks';

import NativeNotificationBox from './NativeNotificationBox';
import NotificationBox from './NotificationBox';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  modal: {
    alignItems: 'flex-start',
    flexDirection: 'column',
    flex: 1
  },
  modalContent: {
    backgroundColor: colors.parallelNavigator.column.left,
    height: '100%',
    flex: 1,
    width: 500,
    paddingTop: dimensions.margins.xxxLarge
  },
  panelContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  mainHeader: {
    color: colors.mainMenu.notificationsPanel.text
  },
  sectionHeader: {
    alignSelf: 'stretch',
    alignContent: 'flex-start',
    marginLeft: dimensions.margins.xxLarge,
    marginTop: dimensions.margins.xxLarge,
    marginBottom: dimensions.margins.xxLarge
  },
  headerText: {
    color: colors.mainMenu.notificationsPanel.text
  }
}));

type NotificationsPanelProps = {
  notifications: NativeNotification[];
  newStylingAvailable: boolean;
  onNewStylingPopupVisible: (visible: boolean) => void;
}

const NotificationsPanel: React.FC<NotificationsPanelProps> = props => {
  const {t} = useTranslation();
  const {notifications, onNewStylingPopupVisible} = props;

  const dismissNativeNotification = useDisposable((key: string) => mw.system.dismissNativeNotification(key));

  const {new: newNotifications, old: oldNotifications} = useMemo(() => (
    {
      new: notifications.filter(notification => !notification.isSeen),
      old: notifications.filter(notification => notification.isSeen)
    }
  ), [notifications]);

  const onDismissAllPressed = useCallback(() => {
    notifications.forEach(notification => {
      notification.isDismissable && dismissNativeNotification(notification.id);
    });
  }, [dismissNativeNotification, notifications]);

  const onNewStylingNotificationPress = useCallback(() => {
    onNewStylingPopupVisible(true);
  }, [onNewStylingPopupVisible]);

  const getNotificationsElements = useCallback((notifications: NativeNotification[], focusFirstItem: boolean) => (
    notifications.map((notification, index) => {
      const focusItem = index === 0 && focusFirstItem;
      return notification.id === newStylingNotificationId
        ? <NotificationBox {...notification} onPress={onNewStylingNotificationPress} hasTVPreferredFocus={focusItem} />
        : <NativeNotificationBox key={notification.id} notification={notification} hasTVPreferredFocus={focusItem} />;
    })
  ), [onNewStylingNotificationPress]);

  const newNotificationsElements = useMemo(() => (
    getNotificationsElements(newNotifications, newNotifications.length > 0)
  ), [getNotificationsElements, newNotifications]);

  const oldNotificationsElements = useMemo(() => (
    getNotificationsElements(oldNotifications, newNotifications.length === 0)
  ), [getNotificationsElements, oldNotifications, newNotifications.length]);

  const styles = stylesUpdater.getStyles();
  return (
    <View style={styles.modal}>
      <View style={styles.modalContent}>
        <View style={{flex: 1, flexDirection: 'column'}}>
          <View style={styles.panelContainer}>
            <NitroxText textType={'headline'} style={styles.mainHeader}>{t('launcher.notifications.headline')}</NitroxText>
            <NitroxButton onPress={onDismissAllPressed} theme={NitroxButtonTheme.Primary} border={true} text={t('launcher.notifications.dismissAll')} />
          </View>
          <View style={{alignItems: 'center'}}>
            {!!newNotifications.length && (
              <>
                <View style={styles.sectionHeader}>
                  <NitroxText style={styles.headerText} textType='callout-small'>{t('launcher.notifications.new')}</NitroxText>
                </View>
                <ScrollView showsVerticalScrollIndicator={false} alwaysBounceVertical={false}>
                  {newNotificationsElements}
                </ScrollView>
              </>
            )}
            {!!oldNotifications.length && (
              <>
                <View style={styles.sectionHeader}>
                  <NitroxText style={styles.headerText} textType='callout-small'>{t('launcher.notifications.old')}</NitroxText>
                </View>
                <ScrollView showsVerticalScrollIndicator={false} alwaysBounceVertical={false}>
                  {oldNotificationsElements}
                </ScrollView>
              </>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

export default React.memo(NotificationsPanel);
