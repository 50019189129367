import React, {useState, useCallback, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleSheet} from 'react-native';
import {NavigationScreenProps} from 'react-navigation';

import {isBigScreen, defaultPageSize} from 'common/constants';
import {getMetadataFromMedia, removeFromWatchList} from 'common/HelperFunctions';
import {Log} from 'common/Log';
import {Pager, PagerEvent, PagerDataChangedEvent, PagerDataChangeType} from 'common/Pager';
import TestContext from 'common/TestContext';

import {MediaType, Media, WatchListSorting, SortWatchListBy, TitleType, isTitle} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import DeleteConfirmationPopup from 'components/DeleteConfirmationPopup';
import DeleteSelectionMenu, {DeleteSelectionMenuActionKey} from 'components/deleteSelectionMenu/DeleteSelectionMenu';
import FocusParent from 'components/FocusParent';
import {Selection, hasAll, hasNone} from 'components/utils/Selection';
import {useWatchList, useChangeEffect, useFunction, useDisposableCallback, useEventListener} from 'hooks/Hooks';
import {deleteSelectionMenuStyle} from 'screens/recordings/RecordingsScreen';

import WatchListScreenGrosso from './WatchListScreen.grosso';
import WatchListScreenPiccolo from './WatchListScreen.piccolo';

const TAG = 'WatchListScreen';

const SubScreen = isBigScreen ? WatchListScreenGrosso : WatchListScreenPiccolo;

const defaultSorting: WatchListSorting = {type: SortWatchListBy.title, ascending: true};

function sortComparator({type, ascending}: WatchListSorting) {
  if (type === SortWatchListBy.title) {
    return (lhs: Media, rhs: Media) => {
      if (!ascending) {
        [rhs, lhs] = [lhs, rhs];
      }
      return lhs.name.localeCompare(rhs.name);
    };
  }
  if (type === SortWatchListBy.productionYear) {
    return (lhs: Media, rhs: Media) => {
      if (!ascending) {
        [rhs, lhs] = [lhs, rhs];
      }
      const lhsProductionYear = getMetadataFromMedia(lhs).productionYear;
      const rhsProductionYear = getMetadataFromMedia(rhs).productionYear;
      return lhsProductionYear - rhsProductionYear;
    };
  }
}

const testIdContext = {
  Modal: 'modal_delete_watchlist'
};

type WatchListScreenProps = {};
const WatchListScreen: React.FC<WatchListScreenProps & NavigationScreenProps> = () => {
  const [loading, setLoading] = useState(true);
  const watchList = useWatchList();
  const [pagedWatchList, setPagedWatchList] = useState<Media[]>([]);
  const [mediaType, setMediaType] = useState<MediaType>(MediaType.Series);
  const [sortBy, setSortBy] = useState<WatchListSorting>(defaultSorting);
  const onSortOrderSelected = useCallback((sortBy) => {
    setSortBy(sortBy || defaultSorting);
  }, []);
  const [deleteConfirmationPopupVisible, setDeleteConfirmationPopupVisible] = useState(false);
  const [selectable, setSelectable] = useState(false);
  const [selectionMenuActions, setSelectionMenuActions] = useState([DeleteSelectionMenuActionKey.OpenMenu]);
  const [selectionMenuDisabledActions, setSelectionMenuDisabledActions] = useState([DeleteSelectionMenuActionKey.Delete]);
  const [selectionMenuSelectedActions, setSelectionMenuSelectedActions] = useState([DeleteSelectionMenuActionKey.SelectAll]);
  const [selection, setSelection] = useState(new Selection<Media>());
  const {t} = useTranslation();

  const filteredWatchList = useRef<Media[]>([]);

  const filterPredicate = useCallback((media: Media) => {
    switch (mediaType) {
      case MediaType.Event:
        return isTitle(media) && media.type === TitleType.EPG;
      case MediaType.Title:
        return isTitle(media) && media.type === TitleType.VOD;
      default:
        return media.getType() === mediaType;
    }
  }, [mediaType]);

  const pagerRequestHandler = useFunction((offset: number, limit: number): Promise<Media[]> => {
    return new Promise((resolve) => {
      // create a local copy of a filtered and sorted WatchList used for paging and selection
      if (filteredWatchList.current.length === 0) {
        filteredWatchList.current = watchList
          .filter(filterPredicate)
          .sort(sortComparator(sortBy));
      }
      resolve(filteredWatchList.current.slice(offset, offset + limit));
    });
  });

  const pagerRef = useRef(new Pager<Media>(pagerRequestHandler, defaultPageSize));

  const onLoadingStarted = useDisposableCallback(firstPage => {
    firstPage && setLoading(true);
  });

  const onDataChanged = useDisposableCallback((event: PagerDataChangedEvent<Media>) => {
    Log.debug(TAG, event.numChangedItems + ' WatchList items were ' + event.changeType + ' starting from index ' + event.changedItemsStartIndex);
    if (event.changeType === PagerDataChangeType.removed && event.data.length === 0) {
      // remove cached WatchList used for paging and selection because of pager being cleared
      filteredWatchList.current = [];
    }
    setPagedWatchList(event.data);
    setLoading(false);
  });

  const onErrorOccured = useDisposableCallback(() => {
    setLoading(false);
  });

  useEventListener(PagerEvent.loadingStarted, onLoadingStarted, pagerRef.current);
  useEventListener(PagerEvent.dataChanged, onDataChanged, pagerRef.current);
  useEventListener(PagerEvent.errorOccured, onErrorOccured, pagerRef.current);

  useChangeEffect(() => {
    pagerRef.current.clear();
  }, [mediaType, watchList, sortBy]);

  const requestNextPage = useCallback((): Promise<void> => {
    return pagerRef.current.requestNextPage();
  }, []);

  const onOpenMenuPress = useCallback(() => {
    setSelectionMenuActions([DeleteSelectionMenuActionKey.Cancel, DeleteSelectionMenuActionKey.SelectAll, DeleteSelectionMenuActionKey.Delete]);
    setSelectionMenuSelectedActions([]);
    setSelectionMenuDisabledActions([DeleteSelectionMenuActionKey.Delete]);
    setSelectable(true);
  }, []);

  const closeSelectionMode = useCallback(() => {
    setDeleteConfirmationPopupVisible(false);
    setSelectable(false);
    setSelection(new Selection<Media>());
    setSelectionMenuActions([DeleteSelectionMenuActionKey.OpenMenu]);
    setSelectionMenuDisabledActions([]);
    setSelectionMenuSelectedActions([]);
  }, []);

  const onSelectionSelectAllPress = useCallback((selected: boolean) => {
    setSelection(selection.clone().selectAll(!selected));
    setSelectionMenuDisabledActions(selected ? [DeleteSelectionMenuActionKey.Delete] : []);
    setSelectionMenuSelectedActions(!selected ? [DeleteSelectionMenuActionKey.SelectAll] : []);
  }, [selection]);

  const selectMedia = useCallback((media: Media, selected: boolean) => {
    const selectionValue = selection.clone().select(media, selected);
    setSelectionMenuSelectedActions(hasAll(selectionValue, filteredWatchList.current) ? [DeleteSelectionMenuActionKey.SelectAll] : []);
    setSelectionMenuDisabledActions(hasNone(selectionValue, filteredWatchList.current) ? [DeleteSelectionMenuActionKey.Delete] : []);
    setSelection(selectionValue);
  }, [selection]);

  const title = useMemo(() => {
    return t('watchList.deleteConfirmationTitle', {count: selection.getSelectedSize()});
  }, [selection, t]);

  const info = useMemo(() => {
    return t('watchList.deleteConfirmationInfo', {count: selection.getSelectedSize()});
  }, [selection, t]);

  const deleteSelectedItems = useCallback(() => {
    closeSelectionMode();
    const selectedMedia = selection.areAllSelected()
      ? selection.filterDeselectedItems(filteredWatchList.current)
      : selection.getSelectedItems();
    removeFromWatchList(selectedMedia);
  }, [selection, closeSelectionMode]);

  const onDeletePress = useCallback(() => {
    setDeleteConfirmationPopupVisible(true);
  },[]);

  const onCancelDeleteConfirmationPopup = useCallback(() => {
    setDeleteConfirmationPopupVisible(false);
  },[]);

  const onMediaTypeSelect = useCallback((mediaType: MediaType) => {
    if (selectable) {
      closeSelectionMode();
    }
    setMediaType(mediaType);
  }, [selectable, closeSelectionMode]);

  const selectionMenu = (
    <DeleteSelectionMenu
      style={deleteSelectionMenuStyle}
      actions={selectionMenuActions}
      disabledActions={selectionMenuDisabledActions}
      selectedActions={selectionMenuSelectedActions}
      onOpenMenuPress={onOpenMenuPress}
      onCancelPress={closeSelectionMode}
      onSelectAllPress={onSelectionSelectAllPress}
      onDeletePress={onDeletePress}
    />
  );

  const isTvTabAvailable = mw.configuration.isEPGAvailable();

  return (
    <FocusParent rememberLastFocused style={StyleSheet.absoluteFillObject}>
      <SubScreen
        watchList={pagedWatchList}
        onMediaTypeSelect={onMediaTypeSelect}
        mediaType={mediaType}
        selectable={selectable}
        closeSelectionMenu={closeSelectionMode}
        selectionMenu={selectionMenu}
        onSelectMedia={selectMedia}
        selection={selection}
        loading={loading}
        currentSortOrder={sortBy}
        onSortOrderSelected={onSortOrderSelected}
        requestNextPage={requestNextPage}
        isTvTabAvailable={isTvTabAvailable}
        testID='screen_watchlist'
      />
      <TestContext.Provider value={testIdContext}>
        <DeleteConfirmationPopup
          visible={deleteConfirmationPopupVisible}
          selection={selection}
          title={title}
          info={info}
          onCancel={onCancelDeleteConfirmationPopup}
          onConfirmation={deleteSelectedItems}
        />
      </TestContext.Provider>
    </FocusParent>
  );
};

export default WatchListScreen;
