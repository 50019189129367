import {createStyles} from 'common-styles';
import React, {createRef} from 'react';
import {View, ActivityIndicator, ViewStyle, Insets} from 'react-native';

import {AppRoutes, dimensions, navigationBarHeight, defaultPageSize, isDesktopBrowser} from 'common/constants';
import {humanCaseToSnakeCase} from 'common/HelperFunctions';
import {Log} from 'common/Log';
import TestContext from 'common/TestContext';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {VodSorting, VodFilter, PictureType} from 'mw/api/Metadata';
import {nxffConfig} from 'mw/api/NXFF';
import {Component as CMSComponent, isDataSourceFilterBased} from 'mw/cms/Component';
import {Link, LinkType, Menu, MenuRenderType} from 'mw/cms/Menu';
import {mw} from 'mw/MW';

import {createVodFilterOptions, FilterOption} from 'components/FilterSelect';
import FocusParent from 'components/FocusParent';
import {HotKeysContextProvider} from 'components/HotKeys';
import {SupportedKeys} from 'components/KeyEventManager';
import MediaGrid, {gridMediaTileMarginHorizontal} from 'components/MediaGrid';
import {mediaTileMarginHorizontal} from 'components/mediaTiles/MediaTile';
import {STBMenuState, STBMenuContext, STBMenuContextType} from 'components/navigation/NavigationHelperTypes';
import {NitroxButtonProps} from 'components/NitroxButton';
import {NitroxInteractiveController} from 'components/NitroxInteractiveControllerContext';
import NitroxText from 'components/NitroxText';
import {SwimlaneStackInterface} from 'components/SwimlaneInterfaces';
import {NamedAction} from 'components/utils/SwimlaneVisibilityLimit';
import {Hotspot, Grid, CustomComponentType, UXMComponentPlacement, UXMComponentStackCustomComponent} from 'components/uxm-components/UMXComponentStack.shared';
import UXMComponentStack from 'components/uxm-components/UXMComponentStack';
import VodCategoryNavigator, {VodCategory, formatCategoryPath, VodCategoryNavigatorInterface} from 'components/vod/categoryNavigator/VodCategoryNavigator';
import PromotionalBanner, {PromotionalBannerAnimatedScrollView, PromotionalBannerAnimatedScrollViewInterface} from 'components/vod/promotionalBanner/PromotionalBanner';
import {PromotionalBannerMode} from 'components/vod/promotionalBanner/PromotionalBanner.shared';
import ScreenBackground from 'components/vod/ScreenBackground';
import VoucherRedeem from 'components/voucherRedeem/VoucherRedeem';
import {getScreenInfo} from 'hooks/Hooks';
import {getTabBarState} from 'hooks/useTabBarState';

import NitroxScreen from './NitroxScreen';
import {VodCategoryScreenProps, VodCategoryScreenState, vodCategoryScreenMargin} from './VodScreen.shared';
import {VodScreenQueryParameters} from './VodScreenQueryParameters';

const TAG = 'VodScreen';

const promotionalBannerMarginBottom = 50;
const hotKeys = [SupportedKeys.Info];
const swimlaneMarginBottom = 45;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    backgroundColor: colors.vodScreen.background,
    paddingTop: isDesktopBrowser ? 0 : dimensions.margins.xxLarge
  },
  categoryNavigatorContainer: {
    paddingHorizontal: vodCategoryScreenMargin
  },
  gridHeader: {
    paddingTop: dimensions.margins.xxxLarge,
    marginLeft: vodCategoryScreenMargin,
    color: colors.vodScreen.label
  },
  gridContainer: {
    flex: 1,
    width: '100%',
    paddingHorizontal: dimensions.screen.container.paddingHorizontal - 2 * gridMediaTileMarginHorizontal
  },
  grid: {
    flex: 1
  },
  promotionalBanner: {
    marginHorizontal: vodCategoryScreenMargin,
    marginTop: dimensions.mainMenu.height - 2 * dimensions.margins.xxLarge,
    marginBottom: promotionalBannerMarginBottom
  },
  noBannerPadding: {
    paddingTop: dimensions.mainMenu.height
  },
  gridPadding: {
    paddingTop: dimensions.mainMenu.height
  },
  activityIndicator: {
    position: 'absolute',
    top: '75%',
    alignSelf: 'center'
  },
  categoryButtonWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: dimensions.margins.xxLarge
  },
  contentFocusParent: {
    flex: 1,
    marginTop: isDesktopBrowser ? 0 : dimensions.margins.xxLarge
  },
  fillScreen: {
    width: '100%',
    height: '100%'
  },
  filterContainerStyle: {
    marginVertical: 0,
    alignItems: 'flex-start'
  }
}));

const testIdContext = {
  NitroxButton: (props: NitroxButtonProps) => props.testID ? props.testID : props.text ? `button_${humanCaseToSnakeCase(props.text)}` : 'button',
  Modal: 'modal_subcategory_selection'
};

const swimlaneInset: Insets = {
  left: dimensions.screen.container.paddingHorizontal - mediaTileMarginHorizontal,
  top: 0,
  bottom: swimlaneMarginBottom
};

const gridInset: Insets = {
  bottom: dimensions.margins.small
};

function mapMenuToVodCategory(menu: Menu, parent?: VodCategory): VodCategory {
  return {
    parent,
    children: [],
    inlineSubcategories: true,
    label: menu.title,
    slug: {
      type: LinkType.MENU,
      slug: menu.slug
    },
    page: menu.link,
    banner: menu.promotionalBanner ?? parent?.banner
  };
}

const promotionalBannerDesktopProps = {
  imageSize: dimensions.pictures.promotionalBanner.vod.default,
  mode: PromotionalBannerMode.framed,
  style: {
    marginHorizontal: vodCategoryScreenMargin
  }
};

export default class VodCategoryScreenClassGrosso extends React.Component<VodCategoryScreenProps, VodCategoryScreenState> {
  public static contextType = STBMenuContext;
  private swimlaneStackRef = createRef<SwimlaneStackInterface>();
  private fillScreenBigScreen: ViewStyle = getScreenInfo().size;
  private promotionalBannerAnimatedScrollViewRef = createRef<PromotionalBannerAnimatedScrollViewInterface>();
  private categoryNavigatorRef = createRef<VodCategoryNavigatorInterface>();
  private categoryNavigatorStyle: ViewStyle = {};
  private supportedSortOptions = mw.catalog.getAvailableVODSortOptions();
  private filterVodOptions: FilterOption<VodFilter>[];

  public constructor(props: VodCategoryScreenProps) {
    super(props);
    this.state = {
      category: undefined,
      fetchingSubcategories: false,
      queryOptions: {pageSize: defaultPageSize},
      isSeenFilterAvailable: false,
      voucherRedeemModalVisible: false,
      isMenuVisible: true,
      shouldFocusBanner: true
    };
    this.filterVodOptions = createVodFilterOptions();
  }

  private focusCategoryNavigator = () => {
    this.categoryNavigatorRef.current?.focus();
  };

  /**
   * returns true when button has been handled.
   */
  private categoryNavigatorBackHandler = (): boolean => {
    const parent = this.state.category?.parent;
    if (parent) {
      this.setState({isMenuVisible: true});
      this.onCategoryPress(parent);
      this.focusCategoryNavigator();
    } else {
      (this.context as STBMenuContextType)?.focusMenu();
    }
    return true;
  }

  public componentDidMount() {
    const navBarVisible = this.props.navigation.getParam('navigationBarVisible', false);
    this.categoryNavigatorStyle = {
      ...stylesUpdater.getStyles().categoryNavigatorContainer,
      marginTop: navBarVisible ? navigationBarHeight : 0
    };
  }

  public componentDidUpdate(_: unknown, prevState: VodCategoryScreenState) {
    if (prevState.isMenuVisible !== this.state.isMenuVisible) {
      this.state.isMenuVisible
        ? this.promotionalBannerAnimatedScrollViewRef.current?.show()
        : this.promotionalBannerAnimatedScrollViewRef.current?.hide();
    }
  }

  private fetchSubCategories = async (category?: VodCategory, link?: Link) => {
    const menuSlug = link?.slug;
    if (!menuSlug) {
      Log.info(TAG, 'fetchSubCategories: Did not receive slug for vod menu!');
      return;
    }
    this.setState({fetchingSubcategories: true});
    try {
      const menu: Menu = await mw.cms.getMenu(menuSlug, 1);
      if (!category) {
        category = mapMenuToVodCategory(menu, category);
      }
      category.banner = menu.promotionalBanner ?? category.banner;
      category.children = menu.items
        .filter(subMenu => subMenu.renderType === MenuRenderType.Default)
        .map(subCategory => mapMenuToVodCategory(subCategory, category));
      category.backgroundImageUrl = menu.pictures.find(picture => picture.type === PictureType.BackgroundHorizontal)?.url;
      category.backgroundImageColor = menu.backgroundColor;
      if (menu.backgroundGradientPrimaryColor && menu.backgroundGradientSecondaryColor) {
        category.backgroundGradient = {
          start: menu.backgroundGradientPrimaryColor,
          stop: menu.backgroundGradientSecondaryColor
        };
      }
      this.setState({
        category: category,
        fetchingSubcategories: false,
        isSeenFilterAvailable: false,
        filters: menu.filter && [menu.filter]
      });
      if (this.swimlaneStackRef.current) {
        this.swimlaneStackRef.current.clear();
        this.swimlaneStackRef.current.fetchPageLayout(menu.link);
      }

      if (category.children.length === 0 && menu.filter) {
        this.focusCategoryNavigator();
        try {
          const isSeenFilterAvailable = await mw.catalog.isSeenFilterAvailableForCategory([menu.filter]);
          this.setState({isSeenFilterAvailable: isSeenFilterAvailable});

        } catch (error) {
          Log.error(TAG, 'Error checking if seen filter is available for subcategory: ', error);
          this.setState({isSeenFilterAvailable: false});
        }
      }
    } catch (error) {
      Log.error(TAG, 'Error fetching subcategories: ', error);
      this.setState({fetchingSubcategories: false});
    }
  };

  private onMenuPress = (menu: Menu) => {
    this.onCategoryPress(mapMenuToVodCategory(menu, this.state.category));
  };

  private onCategoryPress = (category: VodCategory) => {
    Log.debug(TAG, 'onCategoryPress, path: ' + formatCategoryPath(category));
    this.fetchSubCategories(category, category.slug);
  };

  private onSubCategoryPress = (category: VodCategory) => {
    Log.debug(TAG, 'onSubCategoryPress: path: ' + formatCategoryPath(category));
    this.fetchSubCategories(category, category.slug)
      .then(this.focusCategoryNavigator);
  };

  private onScreenFocused = async () => {
    this.fillScreenBigScreen = getScreenInfo().size;
    if (!this.state.category) {
      await this.fetchSubCategories(this.state.category, this.props.navigation.getParam('link'));
    }
  };

  private onScreenBlurred = () => {
    const {currentTabIndex, thisTabIndex} = getTabBarState(this.props.navigation) ?? {};
    const shouldReset = thisTabIndex !== currentTabIndex;
    if (!shouldReset) {
      return;
    }
    this.setState({
      isMenuVisible: true,
      shouldFocusBanner: true
    });
  };

  private onSortOrderSelected = (sortOrder?: VodSorting) => {
    this.setState(previousState => ({
      queryOptions: {...previousState.queryOptions, sorting: sortOrder}
    }));
  };

  private onFilterSelected = (filter?: VodFilter) => {
    this.setState(previousState => ({
      queryOptions: {...previousState.queryOptions, filter: filter}
    }));
  };

  private onVoucherRedeemModalCloseHandler = () => {
    this.setState({voucherRedeemModalVisible: false});
  };

  private onVoucherRedeemModalOpenHandler = () => {
    this.setState({voucherRedeemModalVisible: true});
  };

  private createShowAllAction = (component: CMSComponent): NamedAction => ({
    name: 'showAll',
    label: this.props.t('common.showAll'),
    onPress: () => this.props.navigation.navigate(AppRoutes.MediaGrid, {component})
  });

  private createVisibilityLimits = (component: CMSComponent) => ([{
    shouldApply: () => true,
    ...this.createShowAllAction(component)
  }]);

  private onFocusEntersSwimlanes = () => {
    if (!isDesktopBrowser && this.state.category?.banner) {
      this.setState({isMenuVisible: false});
    }
  };

  private onFocusEnteredSectionAboveSwimlanes = () => {
    if (!isDesktopBrowser && this.state.category?.banner) {
      this.setState({isMenuVisible: true});
    }
  };

  private onFocusBanner = () => {
    this.setState({shouldFocusBanner: false});
    this.onFocusEnteredSectionAboveSwimlanes();
  };

  private onSwimlanesFocusChange = (row: number) => {
    this.onFocusEntersSwimlanes();
    if (!isDesktopBrowser && !this.state.category?.banner) {
      this.setState({isMenuVisible: row < 1});
    }
  };

  private onFetchedHotspot = (hotspot: Hotspot) => {
    this.setState(prev => ({
      ...prev,
      category: prev.category && {
        ...prev.category,
        banner: hotspot
      }
    }));
  };

  private onFetchedGrid = (grid: Grid) => {
    this.setState(prev => isDataSourceFilterBased(grid.dataSource)
      ? {
        ...prev,
        category: prev.category && {
          ...prev.category,
          page: undefined
        },
        filters: grid.dataSource.filters
      }
      : prev
    );
  };

  private prepareCustomComponents = (shouldDisplayQueryParameters: boolean, shouldDisplaySortOptions: boolean): UXMComponentStackCustomComponent[] => {
    const styles = stylesUpdater.getStyles();
    const customComponents: UXMComponentStackCustomComponent[] = [];
    isDesktopBrowser && customComponents.push({
      type: CustomComponentType.breadcrumbs,
      title: CustomComponentType.breadcrumbs,
      onPress: () => {},
      placement: UXMComponentPlacement.afterPromoBanner,
      props: {
        style: this.categoryNavigatorStyle,
        category: this.state.category,
        onSubCategoryPress: this.onSubCategoryPress,
        onBackPress: this.categoryNavigatorBackHandler,
        inlineList: this.state.category?.inlineSubcategories,
        shouldDisplayRedeemButton: nxffConfig.getConfig().Purchases.VoucherRedemptionEnabled && isDesktopBrowser,
        onRedeemButtonPress: this.onVoucherRedeemModalOpenHandler,
        onFocusEnter: this.onFocusEnteredSectionAboveSwimlanes
      }
    });
    if (isDesktopBrowser && shouldDisplayQueryParameters) {
      customComponents.push({
        type: CustomComponentType.queryParameters,
        title: CustomComponentType.queryParameters,
        onPress: () => {},
        placement: UXMComponentPlacement.afterBreadcrumbs,
        props: {
          filterContainerStyle: styles.filterContainerStyle,
          shouldDisplayQueryParameters: shouldDisplayQueryParameters,
          shouldDisplaySortOptions: shouldDisplaySortOptions,
          isSeenFilterAvailable: this.state.isSeenFilterAvailable,
          supportedSortOptions: this.supportedSortOptions,
          filterOptions: this.filterVodOptions,
          currentFilter: this.state.queryOptions.filter,
          currentSortOrder: this.state.queryOptions.sorting,
          enterStrategy: 'topLeft',
          onFocusEnter: this.onFocusEnteredSectionAboveSwimlanes,
          onSortOrderSelected: this.onSortOrderSelected,
          onFilterSelected: this.onFilterSelected
        }
      });
    }
    return customComponents;
  }

  public render() {
    const shouldDisplaySwimlanes = !!this.state.category?.page;
    const shouldDisplayGrid = !shouldDisplaySwimlanes && !!this.state.filters?.length;
    const shouldDisplayQueryParameters = !!(shouldDisplayGrid && this.state.filters?.length && this.state.category?.children.length === 0);
    const shouldDisplaySortOptions = this.supportedSortOptions.length > 0;
    const styles = stylesUpdater.getStyles();
    const customComponents = this.prepareCustomComponents(shouldDisplayQueryParameters, shouldDisplaySortOptions);

    return (
      <TestContext.Provider value={testIdContext}>
        <NitroxScreen
          style={styles.container}
          menuState={this.state.isMenuVisible ? STBMenuState.Transparent : STBMenuState.Hidden}
          navigation={this.props.navigation}
          onBackButtonPressed={this.categoryNavigatorBackHandler}
          onScreenFocused={this.onScreenFocused}
          onScreenBlurred={this.onScreenBlurred}
          testID='screen_vod'
        >
          <ScreenBackground
            imageUri={this.state.category?.backgroundImageUrl}
            imageBackgroundColor={this.state.category?.backgroundImageColor}
            gradient={this.state.category?.backgroundGradient}
          />
          <NitroxInteractiveController omitGeometryCaching>
            <PromotionalBannerAnimatedScrollView
              /* eslint-disable @typescript-eslint/ban-ts-comment */
              // @ts-ignore
              ref={this.promotionalBannerAnimatedScrollViewRef}
              /* eslint-enable @typescript-eslint/ban-ts-comment */
              promoBanner={this.state.category?.banner}
            >
              <FocusParent
                debugName='FocusParentVodScreen'
                rememberLastFocused={!this.state.isMenuVisible}
              >
                {!isDesktopBrowser && this.state.category?.banner && ( // TODO: https://jira.schange.com/browse/CL-7435
                  <PromotionalBanner
                    style={styles.promotionalBanner}
                    dataSource={this.state.category.banner}
                    mode={PromotionalBannerMode.framed}
                    imageSize={dimensions.pictures.promotionalBanner.vod.default}
                    visible={this.state.isMenuVisible}
                    hasTvPreferredFocus={this.state.shouldFocusBanner}
                    onFocus={this.onFocusBanner}
                  />
                )}
                <View style={[
                  this.fillScreenBigScreen,
                  !this.state.category?.banner && !isDesktopBrowser && styles.noBannerPadding,
                  isDesktopBrowser && shouldDisplayGrid && styles.gridPadding
                ]}
                >
                  {(!isDesktopBrowser || shouldDisplayGrid) && (
                    <VodCategoryNavigator
                      style={this.categoryNavigatorStyle}
                      ref={this.categoryNavigatorRef}
                      category={this.state.category}
                      onSubCategoryPress={this.onSubCategoryPress}
                      onBackPress={this.categoryNavigatorBackHandler}
                      inlineList={this.state.category?.inlineSubcategories}
                      shouldDisplayRedeemButton={nxffConfig.getConfig().Purchases.VoucherRedemptionEnabled && isDesktopBrowser}
                      onRedeemButtonPress={this.onVoucherRedeemModalOpenHandler}
                      onFocusEnter={this.onFocusEnteredSectionAboveSwimlanes}
                    />
                  )}
                  <FocusParent
                    style={styles.contentFocusParent}
                    rememberLastFocused
                    holdFocus
                    debugName='SwimlaneStackFocusParent'
                  >
                    <HotKeysContextProvider hotKeys={hotKeys}>
                      {this.state.category?.page && (
                        <UXMComponentStack
                          debugName='VodScreen'
                          customComponents={isDesktopBrowser ? customComponents : undefined}
                          link={this.state.category.page}
                          rowInsets={swimlaneInset}
                          onMenuPress={this.onMenuPress}
                          onFetchedHotspot={isDesktopBrowser ? undefined : this.onFetchedHotspot}
                          onFetchedGrid={this.onFetchedGrid}
                          onFocusChange={this.onSwimlanesFocusChange}
                          focusOnAppear={!this.state.isMenuVisible}
                          topInset={isDesktopBrowser ? dimensions.mainMenu.height : 0}
                          promotionalBannerProps={promotionalBannerDesktopProps}
                        />
                      )}
                      <VodScreenQueryParameters
                        shouldDisplayQueryParameters={shouldDisplayQueryParameters}
                        onFocusEnter={this.onFocusEnteredSectionAboveSwimlanes}
                        shouldDisplaySortOptions={shouldDisplaySortOptions}
                        supportedSortOptions={this.supportedSortOptions}
                        currentSortOrder={this.state.queryOptions.sorting}
                        onSortOrderSelected={this.onSortOrderSelected}
                        isSeenFilterAvailable={this.state.isSeenFilterAvailable}
                        filterOptions={this.filterVodOptions}
                        currentFilter={this.state.queryOptions.filter}
                        onFilterSelected={this.onFilterSelected}
                      />
                      {shouldDisplayGrid &&
                        <NitroxText textType='headline' style={styles.gridHeader}>{this.state.category ? this.state.category.label : ''}</NitroxText>}
                      {shouldDisplayGrid && !!this.state.filters?.length && (
                        <View style={styles.gridContainer}>
                          <MediaGrid
                            style={styles.grid}
                            contentInset={gridInset}
                            dataSource={this.state.filters}
                            queryOptions={this.state.queryOptions}
                            maximumSpacing={0}
                            onTileFocus={this.onFocusEntersSwimlanes}
                          />
                        </View>
                      )}
                    </HotKeysContextProvider>
                  </FocusParent>
                  <ActivityIndicator style={styles.activityIndicator} animating={this.state.fetchingSubcategories} />
                  {this.state.voucherRedeemModalVisible && <VoucherRedeem onClose={this.onVoucherRedeemModalCloseHandler} />}
                </View>
              </FocusParent>
            </PromotionalBannerAnimatedScrollView>
          </NitroxInteractiveController>
        </NitroxScreen>
      </TestContext.Provider>
    );
  }
}
