import React, {useState, useCallback, useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {Log} from 'common/Log';

import {ChromecastConnectionState} from 'mw/api/Metadata';
import {Track} from 'mw/api/PlayerEvent';
import chromecastEventEmitter from 'mw/platform/chromecast/ChromecastEventEmitter';
import {ChromecastDevice, ChromecastEvent, ChromecastTracksList} from 'mw/platform/chromecast/ChromecastInterface';
import {ChromecastModule} from 'mw/platform/chromecast/ChromecastModule';

import {ChromecastContext} from 'components/ChromecastContext';
import LanguageSelectionPopup from 'components/LanguageSelectionPopup';
import {useToggle, useEventListener} from 'hooks/Hooks';

import ChromecastButton from './ChromecastButton';
import ChromecastDeviceSelectionPopup from './ChromecastDeviceSelectionPopup';
import ChromecastMoreActionsPopup from './ChromecastMoreActionsPopup';
import ChromecastRemoteControl from './ChromecastRemoteControl';
import ChromecastStatusBar from './ChromecastStatusBar';

const TAG = 'ChromecastController';

const ChromecastController: React.FC = () => {
  const {
    media,
    availableDevices,
    isDisconnectPopupVisible,
    showDeviceListPopup,
    hideDeviceListPopup,
    isDeviceListPopupVisible,
    showDisconnectPopup,
    hideDisconnectPopup,
    connectionState,
    clearCastAfterConnectingRequest,
    currentDevice,
    isPaused,
    stopCastingMedia
  } = useContext(ChromecastContext);
  const {t} = useTranslation();
  const [isLanguagePopupVisible, {on: showLanguagePopup, off: hideLanguagePopup}] = useToggle(false);
  // TODO: use values from currentDevice/MW/media CL-5735 & CL-5774
  const [currentSubtitle, setCurrentSubtitle] = useState<Track | undefined>();
  const [availableSubtitles, setAvailableSubtitles] = useState<Track[]>([]);
  const [currentAudio, setCurrentAudio] = useState<Track | undefined>();
  const [availableAudio, setAvailableAudio] = useState<Track[]>([]);
  const [deviceName, setDeviceName] = useState<string | undefined>();

  const [currentVolume, setCurrentVolume] = useState<number>(0);

  useEffect(() => {
    if (connectionState === ChromecastConnectionState.Connected) {
      ChromecastModule.getVolume().then(setCurrentVolume);
    }
  }, [connectionState]);

  useEventListener(
    ChromecastEvent.VolumeChanged,
    useCallback(({volume}) => setCurrentVolume(volume), []),
    chromecastEventEmitter
  );

  const onAudioLanguagesChanged = useCallback((data: ChromecastTracksList = {tracks: [], currentTrack: undefined}) => {
    const tracks = data.tracks || [];
    const currentTrack = data.currentTrack;
    setAvailableAudio(tracks);
    if (currentTrack) {
      setCurrentAudio(tracks.find(track => track.id === currentTrack.id));
    }
  }, []);

  useEventListener(
    ChromecastEvent.AudioLanguagesChanged,
    onAudioLanguagesChanged,
    chromecastEventEmitter
  );

  const onSubtitlesChanged = useCallback((data: ChromecastTracksList = {tracks: [], currentTrack: undefined}) => {
    const tracks = data.tracks || [];
    const currentTrack = data.currentTrack;
    setAvailableSubtitles(tracks);
    if (currentTrack) {
      setCurrentSubtitle(tracks.find(track => track.id === currentTrack.id));
    }
  }, []);

  useEventListener(
    ChromecastEvent.SubtitlesChanged,
    onSubtitlesChanged,
    chromecastEventEmitter
  );

  const hideDeviceSelectionPopup = useCallback(() => {
    clearCastAfterConnectingRequest();
    hideDeviceListPopup();
  }, [clearCastAfterConnectingRequest, hideDeviceListPopup]);

  const onDeviceChanged = useCallback((device: ChromecastDevice) => {
    hideDeviceListPopup();
    setDeviceName(device.name);
    ChromecastModule.connectDevice(device.id).catch(error => {
      Log.error(TAG, 'Cannot connect to device:', device.id, error);
      setDeviceName(undefined);
    });
  }, [hideDeviceListPopup]);

  const onDeviceDisconnected = useCallback(() => {
    if (!currentDevice) {
      return;
    }
    hideDisconnectPopup();
    ChromecastModule.disconnectDevice(currentDevice.id).catch(error => {
      Log.error(TAG, 'Cannot disconnect device:', currentDevice.id, error);
    });
    setDeviceName(undefined);
  }, [currentDevice, hideDisconnectPopup]);

  const onAudioSelected = useCallback((track: Track) => {
    track.id && ChromecastModule.setAudioTrack(track.id);
  }, []);

  const onSubtitlesSelected = useCallback((track: Track) => {
    track.id && ChromecastModule.setSubtitleTrack(track.id);
  }, []);

  const handleChromecastButtonPress = useCallback(() => {
    switch (connectionState) {
      case ChromecastConnectionState.Disconnected:
        showDeviceListPopup();
        break;
      case ChromecastConnectionState.Connecting:
        break;
      default:
        showDisconnectPopup();
        break;
    }
  }, [connectionState, showDeviceListPopup, showDisconnectPopup]);

  const onVolumeChanged = useCallback((volume: number) => {
    setCurrentVolume(volume); //set current volume immediately to provide better user experience (communicating with receiver takes some time)
    ChromecastModule.setVolume(volume);
  }, []);

  return (
    <>
      <ChromecastDeviceSelectionPopup
        onDeviceChanged={onDeviceChanged}
        onClose={hideDeviceSelectionPopup}
        devices={availableDevices}
        visible={isDeviceListPopupVisible}
      />
      {currentDevice && (
        <ChromecastMoreActionsPopup
          onDeviceDisconnected={onDeviceDisconnected}
          onClose={hideDisconnectPopup}
          visible={isDisconnectPopupVisible}
          device={currentDevice}
          currentVolume={currentVolume}
          onVolumeChanged={onVolumeChanged}
          connectionState={connectionState}
        />
      )}
      {
        (deviceName && (connectionState === ChromecastConnectionState.Connecting ||
          connectionState === ChromecastConnectionState.MediaConnecting)) &&
        (
          <ChromecastStatusBar
            deviceName={deviceName}
            connectionState={connectionState}
          />
        )
      }
      {!!availableDevices.length && (
        <ChromecastButton
          connectionState={connectionState}
          onPress={handleChromecastButtonPress}
        />
      )}
      {currentDevice && media && connectionState === ChromecastConnectionState.MediaConnected && (
        <>
          <ChromecastRemoteControl
            onDisconnectPopupOpen={showDisconnectPopup}
            media={media}
            onStopMediaSending={stopCastingMedia}
            onLanguagePopupOpen={showLanguagePopup}
            paused={isPaused}
          />
          <LanguageSelectionPopup
            title={t('chromecast.languageSelection.title')}
            visible={isLanguagePopupVisible}
            audio={availableAudio}
            subtitles={availableSubtitles}
            onAudioSelected={onAudioSelected}
            onSubtitlesSelected={onSubtitlesSelected}
            selectedAudio={currentAudio}
            selectedSubtitle={currentSubtitle}
            onClose={hideLanguagePopup}
          />
        </>
      )}
    </>
  );
};

export default React.memo(ChromecastController);
