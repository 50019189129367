import React from 'react';
import {Image, ImageStyle, StyleProp} from 'react-native';

type Props = {
  pictureUrl: string | number; // For native devices, require will return number
  style?: StyleProp<ImageStyle>;
}

const NitroxLogo: React.FC<Props> = ({pictureUrl, style}) => (
  <Image
    style={style}
    resizeMode={'contain'}
    resizeMethod={'resize'}
    source={typeof pictureUrl === 'number' ? pictureUrl : {uri: pictureUrl}}
  />
);

export default React.memo(NitroxLogo);
