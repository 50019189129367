import {createStyles} from 'common-styles';
import i18next from 'i18next';
import React, {useState, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {View, Image, ImageStyle, LayoutChangeEvent} from 'react-native';

import {isTablet, dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Media, PictureType, PictureMode} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import {Icon, IconType} from 'components/Icon';
import NitroxButtonsMenu, {NitroxButtonsMenuAction} from 'components/NitroxButtonsMenu';
import NitroxInteractive from 'components/NitroxInteractive';
import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';
import Separator from 'components/Separator';
import ShortDetails from 'components/ShortDetailsView';
import {getMediaMetadata, DetailsButtonsHandlers} from 'components/zapper/MediaDetailsTemplate';

const TAG = 'VodSeriesMobileDetailsOverlay';
const posterSize = dimensions.pictures.standardTile.default_16_9;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  closeDetailsContainer: {
    flexDirection: 'row'
  },
  detailsContainer: {
    marginLeft: 18
  },
  detailsImage: {
    width: posterSize.width,
    height: posterSize.height
  },
  detailsButtonsMenu: {
    marginTop: 20,
    marginLeft: 78
  },
  separator: {
    width: '90%',
    height: 1,
    backgroundColor: colors.overlayMediaDetails.separator
  },
  episodeDetails: {
    width: 353
  },
  backSTBIconColor: colors.backButton.icon,
  buttonTextStyle: {
    color: colors.popup.text
  },
  buttonBackgroundStyle: {
    backgroundColor: colors.button.primary.background.unfocused
  }

}));

const backSize = dimensions.icon.large;

type BackButtonProps = {
  onPress: () => void;
}

export function BackButton(props: BackButtonProps) {
  const styles = stylesUpdater.getStyles();
  return (
    <NitroxInteractive
      onPress={props.onPress}
      testID='button_back'
    >
      <Icon
        type={IconType.BackSTB}
        size={backSize}
        color={styles.backSTBIconColor}
      />
    </NitroxInteractive>
  );
}

type Props = {
  media: Media;
  currentlyPlayedMedia: boolean;
  handlers: DetailsButtonsHandlers;
  onClose: () => void;
}

export default function VodSeriesMobileDetailsOverlay(props: Props) {
  const [detailsWidth, setDetailsWidth] = useState(0);
  const uri = mw.catalog.getPictureUrl(props.media, PictureType.Tile, posterSize.width, posterSize.height, PictureMode.BOX);
  const actions: NitroxButtonsMenuAction[] = [];
  const {t} = useTranslation();
  const styles = stylesUpdater.getStyles();
  const {isMediaBlocked} = useParentalControl();

  if (!props.currentlyPlayedMedia) {
    //TODO: add check for media.isEntitled CL-695
    actions.push({
      text: i18next.t('vod.watch'),
      isSelected: true,
      onPress: () => {
        props.handlers.onWatch(props.media);
        props.onClose();
      }
    });
  }
  actions.push({
    text: i18next.t('common.cancel'),
    isSelected: props.currentlyPlayedMedia,
    onPress: props.onClose
  });

  const onContainerLayout = useCallback((event: LayoutChangeEvent) => {
    setDetailsWidth(event.nativeEvent.layout.width - backSize - posterSize.width - Number(styles.detailsContainer.marginLeft));
  }, [styles.detailsContainer]);

  return (
    <React.Fragment>
      <View
        style={styles.container}
        onLayout={onContainerLayout}
      >
        <View style={styles.closeDetailsContainer}>
          <BackButton onPress={props.onClose} />
          <View style={styles.detailsContainer}>
            <ShortDetails
              style={isTablet ? styles.episodeDetails : {width: detailsWidth}}
              {...getMediaMetadata(t, isMediaBlocked, props.media)}
              completeDetails
              landscape
            />
            <Separator style={styles.separator} />
          </View>
        </View>
        <Image
          style={styles.detailsImage as ImageStyle}
          source={{uri}}
        />
      </View>
      <NitroxButtonsMenu
        borderButtons
        containerStyle={styles.detailsButtonsMenu}
        buttonsStyleSelected={styles.buttonBackgroundStyle}
        textsStyleNotSelected={styles.buttonTextStyle}
        actions={actions}
      />
    </React.Fragment>
  );
}
