import {createStyles} from 'common-styles';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions, isPhone} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import FocusParent from 'components/FocusParent';
import {IconProps, IconType} from 'components/Icon';
import NitroxText from 'components/NitroxText';
import ProfileSwitcher from 'components/profileManagement/ProfileSwitcher';
import {WizardElements} from 'components/Wizard';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flex: 1,
    alignItems: 'center'
  },
  content: {
    paddingHorizontal: dimensions.margins.xLarge,
    flex: 1,
    justifyContent: isPhone ? 'flex-start' : 'center'
  },
  header: {
    alignItems: 'center',
    marginTop: dimensions.wizard.summaryScreen.header.marginTop
  },
  headerText: {
    color: colors.settingsScreen.title,
    textAlign: 'center'
  },
  profileSwitcher: {
    marginTop: dimensions.wizard.summaryScreen.profileSwitcher.margin
  },
  forwardButton: {
    marginRight: dimensions.margins.small,
    minWidth: dimensions.wizard.summaryScreen.profileSwitcher.actionButton.minWidth
  },
  actions: {
    marginTop: dimensions.wizard.summaryScreen.profileSwitcher.margin
  }
}));

type Props = {
  profileName: string;
}

const icon: IconProps = {
  type: IconType.Check,
  size: dimensions.wizard.summaryScreen.profileSwitcher.icon
};

const WizardSummaryPage: React.FC<Props & WizardElements> = props => {
  const styles = stylesUpdater.getStyles();
  return (
    <FocusParent style={styles.container}>
      <View style={styles.content}>
        <View style={styles.header}>
          <NitroxText textType='title' style={styles.headerText}>
            {useTranslation().t('profileWizard.wizardDone')}
          </NitroxText>
        </View>
        <ProfileSwitcher
          style={styles.profileSwitcher}
          profileName={props.profileName}
          iconProps={icon}
          actions={
            props.forwardButton && (
              <View style={styles.forwardButton}>
                {props.forwardButton}
              </View>
            )}
          actionsStyle={styles.actions}
        />
      </View>
    </FocusParent>
  );
};

export default React.memo(WizardSummaryPage);
