import {createStyles} from 'common-styles';
import React, {useState, useCallback, PureComponent, useContext} from 'react';
import {BackHandler, ViewStyle, StyleProp, View, Image} from 'react-native';
import {NavigationScreenProp, NavigationEventSubscription} from 'react-navigation';

import {isBigScreen, isMobile, isTablet, dimensions, isIOS, usingNitroxFocusEngine, isWebOS} from 'common/constants';
import {TestProps, Emitter, Navigation} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {mw} from 'mw/MW';

import BigScreenHeader, {BigScreenHeaderProps} from 'components/BigScreenHeader';
import ConditionalWrapper from 'components/ConditionalWrapper';
import {NativeKeyEvent, KeyEventManager, SupportedKeys} from 'components/KeyEventManager';
import MobileScreenHeader, {MobileScreenHeaderProps} from 'components/mobileScreenHeader/MobileScreenHeader';
import {ModalVisibilityReporter} from 'components/Modal';
import {STBMenuState, STBMenuContext, STBMenuContextType} from 'components/navigation/NavigationHelperTypes';
import NitroxSafeAreaView from 'components/NitroxSafeAreaView';
import OrientationManager, {SupportedOrientations} from 'components/OrientationManager';
import PlayerManagerComponent from 'components/player/PlayerManager';
import {useEventListener} from 'hooks/Hooks';

export enum ScreenVisibilityState {
  Active, Inactive
}

export function useScreenManagerFocusHelper<S, P>(navigation: NavigationScreenProp<S, P>) {
  const [screenVisibilityState, setScreenVisibilityState] = useState(navigation.isFocused() ? ScreenVisibilityState.Active : ScreenVisibilityState.Inactive);
  const onScreenVisibilityChange = useCallback((state: ScreenVisibilityState) => {
    setScreenVisibilityState(state);
  }, []);

  return {screenVisibilityState, onScreenVisibilityChange};
}

const BackPress = 'BackPress';
type BackPressContextType = {
  on: (type: typeof BackPress, handler: () => boolean) => void;
  off: (type: typeof BackPress, handler: () => boolean) => void;
}
export const NitroxScreenBackCaptureContext = React.createContext<BackPressContextType | undefined>(undefined);
function useNitroxScreenBackEmitter() {
  return useContext(NitroxScreenBackCaptureContext);
}

/**
 * Use to override default NitroxScreen back handling.
 * Note: Usable only in NitroxScreen's descendants!
 */
export function useNitroxScreenBackCapture(handler: () => boolean) {
  // see useBackPressCapture for typing info
  const emitter = useNitroxScreenBackEmitter() as unknown as (Emitter<typeof BackPress> | undefined);
  useEventListener(BackPress, handler, emitter);
}

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  notchBackground: {
    top: 0,
    left: 0,
    right: 0,
    height: dimensions.notch.height
  },
  background: {
    backgroundColor: colors.defaultColors.screenBackground
  },
  notchBackgroundColor: colors.screenHeader.background,
  operatorLogo: {
    position: 'absolute',
    width: dimensions.logo.width,
    height: dimensions.logo.height,
    right: isBigScreen ? dimensions.margins.xxxLarge : dimensions.margins.xxLarge,
    bottom: isBigScreen ? dimensions.margins.xxxLarge : dimensions.margins.large
  }
}));

type NotchBackgroundProps = {
  style?: ViewStyle;
}

const NotchBackground: React.FC<NotchBackgroundProps> = React.memo(props => {
  if (!isIOS || !isMobile) {
    return null;
  }

  const styles = stylesUpdater.getStyles();
  const backgroundColor = props.style && props.style.backgroundColor ? props.style.backgroundColor : styles.notchBackgroundColor;
  return (<View style={[styles.notchBackground, {backgroundColor}]} />);
});
NotchBackground.displayName = 'NotchBackground';

type WebKeyPressedEventListenerProps = {
  onWebKeyPressed: (event?: NativeKeyEvent) => void;
}

const WebKeyPressEventListener: React.FC<WebKeyPressedEventListenerProps> = React.memo(props => {
  useEventListener('keyup', props.onWebKeyPressed, KeyEventManager.getInstance());
  return null;
});
WebKeyPressEventListener.displayName = 'WebKeyPressEventListener';

type HardwareBackPressEventListenerProps = {
  onBackPressed: () => boolean;
}

const HardwareBackPressEventListener: React.FC<HardwareBackPressEventListenerProps> = React.memo(props => {
  // Casting to unknown is needed due to non-argument handler expected by BackHandler.
  // See Hooks.useBackPressCapture.
  useEventListener('hardwareBackPress', props.onBackPressed, BackHandler as unknown as Emitter<'hardwareBackPress'>);
  return null;
});
HardwareBackPressEventListener.displayName = 'HardwareBackPressEventListener';

type Props = {
  //wrapper view
  style?: StyleProp<ViewStyle>;

  //navigation
  navigation: Navigation;
  onBackButtonPressed?: () => boolean; // return true to omit native handling
  onScreenFocused?: () => void;
  onScreenBlurred?: () => void;
  onScreenVisibilityChange?: (state: ScreenVisibilityState) => void;
  onModalVisibilityChange?: (visible: boolean) => void;
  popStackOnBack: boolean;
  menuState?: STBMenuState;
  showWebOSLauncherBar?: boolean; // webos specific, to show launcher bar on CredentialsScreen on back button pressed

  //playback
  stopPlaybackOnAppear: boolean;
  onPlaybackStopped?: () => void;
  closeFloaterOnAppear?: boolean;

  //mobile header props
  mobileHeaderProps?: MobileScreenHeaderProps;
  showMobileHeader: boolean;

  bigScreenHeaderProps?: BigScreenHeaderProps;
  showBigScreenHeader: boolean;

  allowedOrientations: SupportedOrientations;

  /**
   * Whether MW's idle actions should be unblocked when the screen appears.
   * If you set it to false it is your responsibility to make sure that your screen unlocks idle actions
   * at some point. Otherwise data loads that you request from MW never complete.
   *
   * If not set the default value is `true`.
   */
  shouldUnblockIdleActionsOnAppear: boolean;

  /**
   * Only works when allowedOrientations === 'all'
   */
  initialOrientation?: Exclude<SupportedOrientations, 'all'>;

  // embedded screen, which takes advantage of NitroxScreen's styling, header, etc, but is not screen per se
  // example - settings details screens are true screens on mobile, but are embedded in settings' drawer for tablets
  // 'embedded' flag switches off managers like orientation and player, to prevent potential races between this screen and its parent
  embedded?: boolean;
  showOperatorLogo?: boolean;
} & TestProps;

export default class NitroxScreen extends PureComponent<Props> {
  public static contextType = STBMenuContext;

  public static defaultProps: Partial<Props> = {
    stopPlaybackOnAppear: true,
    allowedOrientations: isTablet ? 'all' : 'portrait',
    shouldUnblockIdleActionsOnAppear: true,
    showMobileHeader: true,
    showBigScreenHeader: false,
    popStackOnBack: false
  };

  private onScreenFocusedListener: NavigationEventSubscription | undefined;
  private onScreenBlurredListener: NavigationEventSubscription | undefined;
  private isModalVisible = false;

  private backHandlers = new Set<() => boolean>();

  private runContextBackHandlers = () => {
    return Array.from(this.backHandlers)
      .reduce((alreadyHandled, handler) => {
        return handler() || alreadyHandled;
      }, false);
  }

  private onBackPressed = (): boolean => {
    if (usingNitroxFocusEngine || !this.props.navigation.isFocused()) {
      return false;
    }

    if (this.isModalVisible || (this.context as STBMenuContextType | undefined)?.hasVisibleModal) {
      return true;
    }

    if (this.props.onBackButtonPressed && this.props.onBackButtonPressed()) {
      return true;
    }

    if (this.runContextBackHandlers()) {
      return true;
    }

    return this.handleBack();
  };

  private registerBackHandler(handler: () => boolean) {
    this.backHandlers.add(handler);
  }

  private unregisterBackHandler(handler: () => boolean) {
    this.backHandlers.delete(handler);
  }

  private backPressContextValue = {
    on: (_: typeof BackPress, handler: () => boolean) => this.registerBackHandler(handler),
    off: (_: typeof BackPress, handler: () => boolean) => this.unregisterBackHandler(handler)
  }

  private onWebBackPressed = (event?: NativeKeyEvent) => {
    if (!usingNitroxFocusEngine || !this.props.navigation.isFocused() || !event || event.key !== SupportedKeys.Back || this.isModalVisible) {
      return;
    }

    if (this.isModalVisible || (this.context as STBMenuContextType | undefined)?.hasVisibleModal) {
      return true;
    }

    if (this.props.onBackButtonPressed && this.props.onBackButtonPressed()) {
      return;
    }

    if (this.runContextBackHandlers()) {
      return true;
    }

    if (this.handleBack()) {
      return;
    }

    this.props.navigation.pop();
  };

  private handleBack() {
    if (isMobile) {
      return false;
    }
    const context = this.context as STBMenuContextType;
    if (!context) {
      return false;
    }

    if (this.props.popStackOnBack) {
      return false;
    }

    if (!context.hasFocus) {
      context.focusMenu();
    }

    if (this.props.showWebOSLauncherBar && isWebOS) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.webOS?.platformBack?.();
    }

    return true;
  }

  private onScreenFocusedHandler = async () => {
    if (this.props.menuState) {
      (this.context as STBMenuContextType)?.setMenuState(this.props.menuState);
    }

    if (this.props.onScreenVisibilityChange) {
      this.props.onScreenVisibilityChange(ScreenVisibilityState.Active);
    }
    if (this.props.shouldUnblockIdleActionsOnAppear) {
      mw.catalog.unblockIdleActions();
    }

    if (this.props.onScreenFocused) {
      await this.props.onScreenFocused();
    }
  };

  private onScreenBlurredHandler = () => {
    if (this.props.onScreenVisibilityChange) {
      this.props.onScreenVisibilityChange(ScreenVisibilityState.Inactive);
    }

    if (this.props.onScreenBlurred) {
      this.props.onScreenBlurred();
    }
  };

  public componentDidMount() {
    this.onScreenFocusedListener = this.props.navigation.addListener('didFocus', this.onScreenFocusedHandler);
    this.onScreenBlurredListener = this.props.navigation.addListener('didBlur', this.onScreenBlurredHandler);
  }

  public componentWillUnmount() {
    if (this.onScreenFocusedListener) {
      this.onScreenFocusedListener.remove();
    }
    if (this.onScreenBlurredListener) {
      this.onScreenBlurredListener.remove();
    }
  }

  public componentDidUpdate(prev: Props): void {
    if (this.props.menuState && this.props.menuState !== prev.menuState) {
      if (this.props.navigation.isFocused()) {
        (this.context as STBMenuContextType)?.setMenuState(this.props.menuState);
      }
    }
  }

  private onModalVisibilityChange = (visible: boolean) => {
    this.isModalVisible = visible;
    this.props.onModalVisibilityChange?.(visible);
  };

  public render() {
    const styles = stylesUpdater.getStyles();
    const operatorLogoUrl = this.props.showOperatorLogo && this.props.navigation.getParam('operatorLogoUrl');
    return (
      <ConditionalWrapper
        condition={!this.props.embedded}
        wrapper={children => (
          <ModalVisibilityReporter onModalVisibilityChange={this.onModalVisibilityChange}>
            {children}
          </ModalVisibilityReporter>
        )}
      >
        <NitroxScreenBackCaptureContext.Provider value={this.backPressContextValue}>
          {!this.props.embedded && (
            <>
              {usingNitroxFocusEngine && <WebKeyPressEventListener onWebKeyPressed={this.onWebBackPressed} />}
              {!usingNitroxFocusEngine && <HardwareBackPressEventListener onBackPressed={this.onBackPressed} />}
            </>
          )}
          <NitroxSafeAreaView
            style={[
              styles.background,
              this.props.style,
              // Prevent whole stack being visible in screen's background
              !this.props.navigation.isFocused() && isBigScreen && {opacity: 0}
            ]}
            testID={this.props.testID}
          >
            {isMobile && !this.props.embedded && (
              <OrientationManager
                navigation={this.props.navigation}
                allowedOrientations={this.props.allowedOrientations}
                initialOrientation={this.props.initialOrientation}
              />
            )}
            {isMobile && this.props.mobileHeaderProps && this.props.showMobileHeader &&
              <MobileScreenHeader {...this.props.mobileHeaderProps} />}
            {isBigScreen && this.props.showBigScreenHeader && this.props.bigScreenHeaderProps &&
              <BigScreenHeader {...this.props.bigScreenHeaderProps} />}
            {!this.props.embedded && (
              <PlayerManagerComponent
                navigation={this.props.navigation}
                stopPlaybackOnAppear={this.props.stopPlaybackOnAppear}
                onPlaybackStopped={this.props.onPlaybackStopped}
                closeFloaterOnAppear={this.props.closeFloaterOnAppear}
                debugName={this.props.testID}
              />
            )}
            {this.props.children}
          </NitroxSafeAreaView>
          {isMobile && this.props.mobileHeaderProps && this.props.showMobileHeader &&
            <NotchBackground style={this.props.mobileHeaderProps?.style} />}
          {!!operatorLogoUrl && <Image source={{uri: operatorLogoUrl}} style={styles.operatorLogo} />}
        </NitroxScreenBackCaptureContext.Provider>
      </ConditionalWrapper>
    );
  }
}
