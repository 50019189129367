import {isBigScreen, isWebOS} from 'common/constants';
import {DateUtils} from 'common/DateUtils';
import {EventEmitter} from 'common/EventEmitter';
import {ChangeEvent} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {CustomerEvent} from 'mw/api/Customer';
import {Error, ErrorType} from 'mw/api/Error';
import {Consent, Styling} from 'mw/api/Metadata';
import {nxffConfig} from 'mw/api/NXFF';
import {CMSMapper} from 'mw/bo-proxy/bo/cms/CMSMapper';
import {boProxy} from 'mw/bo-proxy/BOProxy';
import {Translations} from 'mw/bo-proxy/uxmanager/UXManager';
import {CrossLoginDataProvider} from 'mw/common/CrossLoginDataProvider';
import {mw} from 'mw/MW';
import {asyncStorage} from 'mw/platform/async-storage/AsyncStorage';
import {AsyncStorageData} from 'mw/platform/async-storage/AsyncStorageInterface';

import i18n from 'locales/i18n';

import {Menu, Link, MenuType, MenuAlignment} from './Menu';
import {Page} from './Page';

const STYLING_PREFIX = 'styling-';
const cachedStylingKey = 'cachedStyling';
const TAG = 'CMS';
const homePageId = 'nitrox-home-page';

export enum DefaultMenuItemSlug {
  LauncherAppsGames = 'launcher-apps-games',
  BigScreenNotifications = 'big-screen-notifications',
  Settings = 'settings',
  Login = 'login',
  Profile = 'profile',
  Quit = 'quit'
}

export enum DefaultMenuItemScreen {
  Settings = 'settings',
  Credentials = 'credentials',
  LauncherNotifications = 'launcherNotifications',
  LauncherAppsGames = 'launcherAppsGames',
  ProfileSelection = 'profileSelection',
  Quit = 'quit'
}

export enum CustomMenuItemRoute {
  VoucherRedemption = 'voucher-redemption'
}

enum CMSState {
  uninitialized,
  initializing,
  initialized
}

export enum CMSEvent {
  newStyling = 'newStyling',
  newStylingApplied = 'newStylingApplied',
  cmsInitialized = 'cmsInitialized',
  languageLoading = 'languageLoading',
  languageChanged = 'languageChanged',
  newTranslations = 'newTranslations',
  i18nInitialized = 'i18nInitialized'
}

enum NativeMenuNames {
  appsAndGames = 'appsAndGames',
  notifications = 'notifications',
  settings = 'settings',
  login = 'login',
  profile = 'profile',
  quit = 'quit'
}

export class CMS extends EventEmitter<CMSEvent> implements CrossLoginDataProvider {
  private stylingName?: string;
  private stylingVersion: string | null = null;
  private lastTriedRevision = -1;
  private cmsState: CMSState = CMSState.uninitialized;
  private translationsVersion: string | null = null;
  private uiLanguages: string[] = [];
  private defaultUILanguage: string | null = null;
  private refreshTimeoutId = 0;
  private homePage: Page | null = null;

  private static readonly nativeMenus: {[name in NativeMenuNames]: () => Menu} = {
    appsAndGames: () => (new Menu({
      title: i18n.t('cms.native-apps-games'),
      slug: DefaultMenuItemSlug.LauncherAppsGames,
      screen: DefaultMenuItemScreen.LauncherAppsGames,
      type: MenuType.NATIVE,
      position: MenuAlignment.ICON_AREA
    })),
    notifications: () => (new Menu({
      title: i18n.t('cms.native-notifications'),
      slug: DefaultMenuItemSlug.BigScreenNotifications,
      screen: DefaultMenuItemScreen.LauncherNotifications,
      type: MenuType.NATIVE,
      position: MenuAlignment.ICON_AREA
    })),
    settings: () => (new Menu({
      title: i18n.t('cms.settings'),
      slug: DefaultMenuItemSlug.Settings,
      screen: DefaultMenuItemScreen.Settings,
      type: MenuType.NATIVE,
      position: isBigScreen ? MenuAlignment.ICON_AREA : MenuAlignment.TOP
    })),
    login: () => (new Menu({
      title: i18n.t('cms.login'),
      slug: DefaultMenuItemSlug.Login,
      screen: DefaultMenuItemScreen.Credentials,
      type: MenuType.LOGIN,
      position: isBigScreen ? MenuAlignment.TOP : undefined,
      primary: true
    })),
    profile: () => (new Menu({
      title: mw.customer.currentProfile ? mw.customer.currentProfile.name : '',
      slug: DefaultMenuItemSlug.Profile,
      screen: isBigScreen ? DefaultMenuItemScreen.ProfileSelection : DefaultMenuItemScreen.Settings,
      type: MenuType.PROFILE,
      position: MenuAlignment.TOP
    })),
    quit: () => (new Menu({
      title: i18n.t('cms.quit'),
      slug: DefaultMenuItemSlug.Quit,
      screen: DefaultMenuItemScreen.Quit,
      type: MenuType.NATIVE,
      position: MenuAlignment.ICON_AREA
    }))
  }

  public getPage(link: Link): Promise<Page> {
    if (link.slug === homePageId) {
      if (this.homePage) {
        Log.debug(TAG, 'Retrieving home page from cache');
        return Promise.resolve(this.homePage);
      }

      Log.debug(TAG, 'Retrieving home page from BO');
      return boProxy.bo.getPage(link)
        .then(page => {
          this.homePage = page;
          return page;
        });
    }

    return boProxy.bo.getPage(link);
  }

  public clearPageCache(): void {
    this.homePage = null;
  }

  public getMenu(name: string, depth: number): Promise<Menu> {
    return boProxy.bo.getMenu(name, depth);
  }

  public async getMainMenu(): Promise<Menu> {
    const mainMenu = await boProxy.bo.getMainMenu();
    return this.adjustMainMenu(mainMenu);
  }

  public getUnauthenticatedMainMenu(): Menu {
    const mainMenu = CMSMapper.getMainMenu();
    return this.adjustMainMenu(mainMenu, true);
  }

  private adjustMainMenu(menu: Menu, unauthenticated = false): Menu {
    const hasLauncherAppsGames = menu.items.some(item => item.slug === DefaultMenuItemSlug.LauncherAppsGames);

    if (mw.configuration.isLauncher && !hasLauncherAppsGames) {
      menu.items.push(CMS.nativeMenus.appsAndGames());
    } else if (hasLauncherAppsGames) {
      menu.items = menu.items.filter(item => item.slug !== DefaultMenuItemSlug.LauncherAppsGames);
    }

    const hasBigScreenNotifications = menu.items.some(item => item.slug === DefaultMenuItemSlug.BigScreenNotifications);
    if (isBigScreen) {
      if (!hasBigScreenNotifications) {
        menu.items.push(CMS.nativeMenus.notifications());
      }
    } else {
      if (hasBigScreenNotifications) {
        menu.items = menu.items.filter(item => item.slug !== DefaultMenuItemSlug.BigScreenNotifications);
      }
    }
    //TODO: CL-2624 Prepare main menu items slug naming convention
    if (!menu.items.some(item => item.slug.includes(DefaultMenuItemSlug.Settings))) {
      menu.items.push(CMS.nativeMenus.settings());
    }

    if (isBigScreen && !unauthenticated) {
      menu.items.push(CMS.nativeMenus.profile());
    }

    if (unauthenticated) {
      menu.items.forEach(item => item.isDisabled = (item.type === MenuType.DEFAULT && item.screen !== DefaultMenuItemScreen.Settings));
      menu.items.push(CMS.nativeMenus.login());
    }

    if (isWebOS) {
      menu.items.push(CMS.nativeMenus.quit());
    }

    return menu;
  }

  public setSupportedStylingVersion(version: string): void {
    this.stylingVersion = version;
  }

  public initialize(styleName?: string): Promise<void> {
    this.stylingName = styleName || nxffConfig.getConfig().UI.StylingDefaultName;

    this.cmsState = CMSState.initializing;
    return this.refreshStyling()
      .then((newStyleLoaded) => {
        if (!newStyleLoaded) {
          this.cmsState = CMSState.initialized;
          this.notify(CMSEvent.cmsInitialized);
        }
      })
      .catch((error: Error) => {
        Log.error(TAG, 'refreshStyling failed', error);
        this.cmsState = CMSState.initialized;
        this.notify(CMSEvent.newStyling, null);
        this.notify(CMSEvent.cmsInitialized);
      });
  }

  public getStyling(): Promise<Styling | null> {
    if (!this.stylingVersion) {
      return Promise.reject(new Error(ErrorType.ImproperConfiguration, 'no styling version'));
    }

    return this.getCachedStyling()
      .then(cachedStyling => {
        return cachedStyling?.version === this.stylingVersion && cachedStyling || null;
      });
  }

  private getCachedStyling(): Promise<Styling | null> {
    return asyncStorage.getSecureItem(cachedStylingKey)
      .then(stylingString => {
        if (!stylingString) {
          return null;
        }

        return JSON.parse(stylingString);
      })
      .catch(error => {
        Log.error(TAG, 'getCachedStyling error', error);
        return null;
      });
  }

  private getBOStyling(stylingName: string, stylingVersion: string): Promise<{styling: Styling; stylingName: string}> {
    return boProxy.bo.getStyling(stylingName, stylingVersion)
      .then(styling => {
        return {
          styling,
          stylingName
        };
      })
      .catch((error: Error) => {
        Log.error(TAG, 'getBOStyling: no styling available', stylingName, stylingVersion);
        const stylingDefaultName = nxffConfig.getConfig().UI.StylingDefaultName;
        if (error?.type === ErrorType.CMSStylingNotFound && stylingName !== stylingDefaultName) {
          Log.warn(TAG, 'getBOStyling: try get default styling', stylingDefaultName);
          return this.getBOStyling(stylingDefaultName, stylingVersion);
        }
        throw error;
      });
  }

  private async refreshStyling(): Promise<boolean> {
    if (!this.stylingVersion) {
      Log.warn(TAG, 'getStyling: stylingVersion not set - dynamic branding not supported');
      throw new Error(ErrorType.ImproperConfiguration, 'no styling version');
    }

    if (!this.stylingName) {
      Log.warn(TAG, 'getStyling: stylingName not set - dynamic branding not supported');
      throw new Error(ErrorType.ImproperConfiguration, 'no styling name');
    }

    const {styling, stylingName} = await this.getBOStyling(this.stylingName, this.stylingVersion)
      .catch((error: Error) => {
        Log.error(TAG, 'refreshStyling: no styling available, returnig cached styling');
        this.scheduleRefresh();
        throw error;
      });

    const cachedStyling = await this.getCachedStyling()
      .then(cachedStyling => {
        return cachedStyling?.brand === stylingName && cachedStyling.version === this.stylingVersion ? cachedStyling : null;
      })
      .catch((error: Error) => {
        Log.warn(TAG, 'refreshStyling no valid cached styling', error);
        return null;
      });

    if (styling.revision === this.lastTriedRevision || (cachedStyling?.revision === styling.revision)) {
      Log.debug(TAG, 'refreshStyling: no new styling available');
      this.scheduleRefresh();
      return false;
    }

    styling.images = await asyncStorage.cacheImages(styling.images, `${STYLING_PREFIX}${styling.revision}`);
    await asyncStorage.setSecureItem(`${STYLING_PREFIX}${styling.revision}`, JSON.stringify(styling));
    Log.info(TAG, 'refreshStyling: have revision ' + styling.revision);
    this.notify(CMSEvent.newStyling, styling);
    this.scheduleRefresh();
    return true;
  }

  private scheduleRefresh(): void {
    const stylingRefreshIntervalInMinutes = nxffConfig.getConfig().UI.StylingRefreshInterval;
    if (!stylingRefreshIntervalInMinutes) {
      Log.error(TAG, 'scheduleRefresh: styling refresh interval is not defined');
    }
    this.cancelRefresh();
    this.refreshTimeoutId = setTimeout(() => {
      this.refreshTimeoutId = 0;
      this.refreshStyling()
        .catch((error: Error) => {
          Log.error(TAG, 'refreshStyling failed', error);
          if (error.type === ErrorType.CMSStylingNotFound) {
            this.lastTriedRevision = -1;
            this.notify(CMSEvent.newStyling, null);
          }
        });
    }, stylingRefreshIntervalInMinutes * DateUtils.msInMin);
  }

  private cancelRefresh(): void {
    if (this.refreshTimeoutId) {
      clearTimeout(this.refreshTimeoutId);
      this.refreshTimeoutId = 0;
    }
  }

  public async stylingApplied(revision: number | null, successful: boolean): Promise<void> {
    if (revision == null) {
      await asyncStorage.removeSecureItem(cachedStylingKey);
      await asyncStorage.invalidateImageCache(STYLING_PREFIX, '');
      this.lastTriedRevision = -1;
      this.notify(CMSEvent.newStylingApplied, null);
      return;
    }
    Log.info(TAG, 'stylingApplied ' + revision + ' aplied: ' + successful);
    this.lastTriedRevision = revision;
    const cachedStyling = await this.getCachedStyling();
    const revisionToKeep = successful ? revision : cachedStyling?.revision;
    const stylingToKeep = revisionToKeep ? `${STYLING_PREFIX}${revisionToKeep}` : '';

    await asyncStorage.invalidateImageCache(STYLING_PREFIX, stylingToKeep);
    if (successful) {
      const styling = await asyncStorage.getSecureItem(stylingToKeep);
      if (styling) {
        await asyncStorage.setSecureItem(cachedStylingKey, styling);
      }
      this.notify(CMSEvent.newStylingApplied, styling);
    } else {
      this.notify(CMSEvent.newStylingApplied, null);
    }

    if (this.cmsState === CMSState.initializing) {
      this.cmsState = CMSState.initialized;
      this.notify(CMSEvent.cmsInitialized);
    }
  }

  public uninitialize(): void {
    this.clear();
    this.cancelRefresh();
    // NOTE: Nothing else to be done here, no state to mop-up. boProxy has own uninitialize method.
    // However, if this module will implement caching, this will be required
  }

  public getCrossLoginData(): Promise<AsyncStorageData[]> {
    if (nxffConfig.getConfig().UI.StylingClearOnLogout) {
      return Promise.resolve([]);
    }
    return this.getCachedStyling()
      .then(styling => {
        if (!styling) {
          return [];
        }
        return [{
          key: cachedStylingKey,
          value: JSON.stringify(styling)
        }];
      });
  }

  public getConsentData(name: string): Promise<Consent> {
    return boProxy.bo.getConsentData(name);
  }

  public setSupportedTranslationsVersion(version: string): void {
    this.translationsVersion = version;
  }

  private getBOAvailableUILanguages(): Promise<string[]> {
    return boProxy.bo.getAvailableUILanguages()
      .then(response => {
        return response;
      })
      .catch((error: Error) => {
        Log.error(TAG, 'getBOAvailableUILanguages: no UI languages available');
        throw error;
      });
  }

  private getBODefaultUILanguage(): Promise<string> {
    return boProxy.bo.getDefaultUILanguage()
      .then(response => {
        return response;
      })
      .catch((error: Error) => {
        Log.error(TAG, 'getBODefaultUILanguage: no default UI language available');
        throw error;
      });
  }

  private getBOTranslations(): Promise<Translations | void> {
    if (!this.translationsVersion) {
      return Promise.reject(new Error(ErrorType.ImproperConfiguration, 'no translations version'));
    }

    return boProxy.bo.getTranslations(this.translationsVersion)
      .catch((error: Error) => {
        Log.error(TAG, 'getBOTranslations: no translations available', error);
      });
  }

  public async retrieveAvailableUILanguages(): Promise<void> {
    this.uiLanguages = await this.getBOAvailableUILanguages();
  }

  public async retrieveDefaultUILanguage(): Promise<void> {
    this.defaultUILanguage = await this.getBODefaultUILanguage();
  }

  public getUILanguages(): string[] {
    return this.uiLanguages;
  }

  public getDefaultUILanguage(): string | null {
    return this.defaultUILanguage;
  }

  public async initializeI18N(): Promise<void> {
    try {
      this.notify(CMSEvent.languageLoading);
      await this.retrieveAvailableUILanguages().catch(error => Log.info(TAG, 'Unable to get available UI languages from BO', error));

      let uiLanguage = await mw.customer.getSavedUILanguage();
      if (!uiLanguage) {
        await this.retrieveDefaultUILanguage().catch(error => Log.info(TAG, 'Unable to get default UI language from BO', error));
        uiLanguage = this.getDefaultUILanguage();
      }

      if (uiLanguage) {
        mw.configuration.uiLanguage = uiLanguage;
      }

      Log.debug(TAG, 'Got preconfigured UI language ' + mw.configuration.uiLanguage + ' - sending notification');

      mw.customer.on(CustomerEvent.UILanguageChanged, params => {
        const {to} = params as ChangeEvent<string>;
        mw.configuration.uiLanguage = to;
        this.notify(CMSEvent.languageChanged, {to: mw.configuration.uiLanguage});
      });

      const translations = await this.getBOTranslations()
        .catch(error => Log.error(TAG, 'Failed get translations', error));
      if (translations) {
        this.notify(CMSEvent.newTranslations, this.filterTranslations(translations));
      } else {
        this.completeI18NInitialization();
      }
    } catch (error) {
      Log.debug(TAG, 'initialize', 'initialize I18N fails', error);
      this.notify(CMSEvent.i18nInitialized);
    }
  }

  private filterTranslations(translations: Translations): Translations {
    return Object.keys(translations)
      .filter(key => mw.configuration.uiLanguages.includes(key))
      .reduce((reducedTranslations, key) => {
        reducedTranslations[key] = translations[key];
        return reducedTranslations;
      },
      {} as Translations);
  }

  private completeI18NInitialization(): void {
    this.notify(CMSEvent.languageChanged, {to: mw.configuration.uiLanguage});
    this.notify(CMSEvent.i18nInitialized);
  }

  public translationsApplied(): void {
    Log.debug(TAG, 'Translations applied');
    this.completeI18NInitialization();
  }
}
