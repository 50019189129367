import {createStyles} from 'common-styles';
import React from 'react';
import {ImageBackground, StyleProp, StyleSheet, View, ViewStyle} from 'react-native';

import {dimensions} from 'common/constants';

import {Gradient} from 'brand/ColorTypes';
import RadialGradientView from 'components/RadialGradientView';

const styles = createStyles({
  background: {
    ...StyleSheet.absoluteFillObject,
    opacity: 0.1
  }
});

type Props = {
  imageUri?: string;
  imageBackgroundColor?: string
  gradient?: Gradient
  style?: StyleProp<ViewStyle>;
};

const ScreenBackground: React.FunctionComponent<Props> = props => {
  const {
    imageUri,
    imageBackgroundColor,
    gradient,
    style
  } = props;

  const shouldRenderGradient = !imageUri && gradient;
  const shouldRenderSolid = imageUri || (imageBackgroundColor && !shouldRenderGradient);
  return (
    <>
      {shouldRenderSolid && <View style={[StyleSheet.absoluteFill, {backgroundColor: imageBackgroundColor}]} />}
      {imageUri && (
        <ImageBackground
          source={{uri: imageUri}}
          imageStyle={{resizeMode: 'contain'}}
          style={[styles.background, style, {backgroundColor: imageBackgroundColor}]}
        />
      )}
      {shouldRenderGradient && gradient && (
        <RadialGradientView
          colors={[
            {color: gradient.start},
            {color: gradient.stop}
          ]}
          rotation={dimensions.gradientBackground.rotation}
          scaleX={dimensions.gradientBackground.scaleX}
          scaleY={dimensions.gradientBackground.scaleY}
        />
      )}
    </>
  );
};

export default React.memo(ScreenBackground);
