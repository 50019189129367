import {createStyles} from 'common-styles';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {LayoutChangeEvent, ListRenderItemInfo, StyleProp, View, ViewStyle} from 'react-native';

import {isMobileBrowser} from 'common/constants';
import {idKeyExtractor} from 'common/HelperFunctions';

import {constColors} from 'common-styles/variables/base-colors';

import {Channel} from 'mw/api/Metadata';

import ChannelsListItem from 'components/channelsList/ChannelsListItem.piccolo';
import NitroxFlatList from 'components/NitroxFlatList';
import NitroxInteractive from 'components/NitroxInteractive';
import {useFunction, useLazyEffect} from 'hooks/Hooks';

import {channelsListItemDimensions, channelsListSeparatorDimensions} from './ChannelsListDimensions';
import {getChannelsListItemLayout} from './ChannelsListHelperFunctions';
import ChannelsListItemSeparator from './ChannelsListItemSeparator';
import {ChannelsListProps} from './ChannelsListProps';

const focusPosition = 0.5;
const itemWidth = channelsListItemDimensions.size;
const separatorWidth = channelsListSeparatorDimensions.horizontalContainerSize;

const styles = createStyles({
  listContainer: {
    flex: 1,
    height: channelsListItemDimensions.size
  },
  contentContainerStyle: {
    paddingLeft: 0,
    paddingRight: 0,
    alignItems: 'center'
  }
});

type ListEndProps = {
  width: number;
  onPress: () => void;
}

const ListEndComponent: React.FC<ListEndProps> = props => {
  return (
    <NitroxInteractive
      activeOpacity={1}
      underlayColor={constColors.transparent}
      onPress={props.onPress}
    >
      <View style={{width: props.width, height: '100%'}} />
    </NitroxInteractive>
  );
};

const ListEnd = React.memo(ListEndComponent);

function calculateListEndWidth(viewWidth: number) {
  return (viewWidth * focusPosition) - (channelsListItemDimensions.size / 2);
}

const HorizontalChannelsListPiccolo: React.FC<ChannelsListProps> = props => {
  const {channels: propsChannels, onPress, selectedChannelId} = props;
  const list = useRef<NitroxFlatList<Channel>>(null);
  const [width, setWidth] = useState(0);
  const onLayout = useCallback((event: LayoutChangeEvent) => setWidth(event.nativeEvent.layout.width), []);
  const [channels, setChannels] = useState<Channel[]>([]);
  const selectedChannelIndex = useMemo(() => propsChannels.findIndex(channel => channel.id === selectedChannelId), [propsChannels, selectedChannelId]);

  // useFunction is used to prevent re-rendering the list header and footer on each change of selectedChannelId
  const changeChannel = useFunction((index: number) => {
    if (!selectedChannelId) {
      return;
    }
    const currentChannelIndex = channels.findIndex(channel => channel.id === selectedChannelId);
    const newChannelIndex = currentChannelIndex + index;
    if (newChannelIndex < 0 || newChannelIndex >= channels.length) {
      return;
    }
    onPress(channels[newChannelIndex]);
  });

  const changeToPreviousChannel = useCallback(() => changeChannel(-1), [changeChannel]);
  const renderListHeader = useCallback(() => {
    const listEndWidth = calculateListEndWidth(width);
    return (
      <ListEnd width={listEndWidth} onPress={changeToPreviousChannel} />
    );
  }, [width, changeToPreviousChannel]);

  const changeToNextChannel = useCallback(() => changeChannel(1), [changeChannel]);
  const renderListFooter = useCallback(() => {
    const listEndWidth = calculateListEndWidth(width);
    return (
      <ListEnd width={listEndWidth} onPress={changeToNextChannel} />
    );
  }, [width, changeToNextChannel]);

  const onChannelPress = useCallback((channelId?: string) => {
    const channel = channelId ? channels.find(c => c.id === channelId) : undefined;
    channel && onPress(channel);
  }, [channels, onPress]);

  const renderItem = useCallback((item: ListRenderItemInfo<Channel>) => {
    return (
      <ChannelsListItem
        channel={item.item}
        horizontal
        isSelected={item.index === selectedChannelIndex}
        onPress={onChannelPress}
      />
    );
  }, [selectedChannelIndex, onChannelPress]);

  const renderSeparator = useCallback(() => <ChannelsListItemSeparator horizontal />, []);

  // Content container size must be set before passing data to FlatList otherwise the initial scrolling may
  // fail or it will be visible for the user.
  const itemsWidth = itemWidth * propsChannels.length;
  const separatorCount = Math.max(0, propsChannels.length - 1);
  const separatorsWidth = separatorWidth * separatorCount;
  const contentContainerWidth = itemsWidth + separatorsWidth + (2 * calculateListEndWidth(width));
  const contentContainerStyle = useMemo<StyleProp<ViewStyle>>(() => [{width: contentContainerWidth}, styles.contentContainerStyle], [contentContainerWidth]);
  const [initialScrollPerformed, setInitialScrollPerformed] = useState(false);

  useLazyEffect(() => {
    if (width > 0 && contentContainerWidth > 0 && selectedChannelIndex >= 0) {
      const offset = (itemWidth + separatorWidth) * selectedChannelIndex;
      if (offset < contentContainerWidth) {
        list.current?.scrollToOffset({offset, animated: initialScrollPerformed});
        if (!initialScrollPerformed) {
          setInitialScrollPerformed(true);
        }
      }
    }
  }, [width, contentContainerWidth, selectedChannelIndex], [initialScrollPerformed]);

  // Data must be passed to FlatList after initial scroll has been performed otherwise scrolling may be visible for
  // user and resources will be wasted for rendering items which are not in current viewport.
  useEffect(() => {
    if (initialScrollPerformed) {
      setChannels(propsChannels);
    }
  }, [propsChannels, initialScrollPerformed]);
  return (
    <View
      style={styles.listContainer}
      onLayout={onLayout}
      testID='channels_list_horizontal'
    >
      {width > 0 && (
        <NitroxFlatList<Channel>
          ref={list}
          horizontal
          data={channels}
          // Required to force updating selected item in web browser
          extraData={isMobileBrowser ? selectedChannelId : undefined}
          contentContainerStyle={contentContainerStyle}
          ListHeaderComponent={renderListHeader}
          ListFooterComponent={renderListFooter}
          renderItem={renderItem}
          ItemSeparatorComponent={renderSeparator}
          onScroll={props.onScroll}
          initialNumToRender={channels.length}
          keyExtractor={idKeyExtractor}
          getItemLayout={getChannelsListItemLayout}
          decelerationRate='fast'
          scrollEventThrottle={3}
          overScrollMode='never'
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
        />
      )}
    </View>
  );
};

export default React.memo(HorizontalChannelsListPiccolo);
