import {createStyles} from 'common-styles';
import React, {useCallback, useMemo, useState, useRef, Ref, useImperativeHandle, forwardRef, useEffect, ReactNode, RefObject, Component} from 'react';
import {View, ViewStyle, Animated} from 'react-native';

import {isSTBBrowser, isDesktopBrowser, WEB_ARROW_CONTAINER_WIDTH, dimensions, Direction} from 'common/constants';
import {createDataSource, humanCaseToSnakeCase, doNothing} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {MetaEvent} from 'mw/utils/MetaEventsEmitter';

import AnimatedSwimlaneTile, {TileRenderer} from 'components/AnimatedSwimlaneTile';
import ButtonTile from 'components/ButtonTile';
import AnimatedScrollView from 'components/epg/animated/AnimatedScrollView';
import {ItemPosition} from 'components/epg/NitroxContentView';
import FocusParent from 'components/FocusParent';
import {AnimatedTileInterface} from 'components/mediaTiles/MediaTile';
import MouseAwareView from 'components/MouseAwareView';
import NavArrow from 'components/NavArrow';
import NitroxText from 'components/NitroxText';
import {SwimlaneProps, SwimlaneDataFetcherState, swimlaneHeaderHeight, SwimlaneTileProps, AnimatedTileProps, defaultHeaderActionsVisibilityThreshold} from 'components/Swimlane';
import {NamedAction} from 'components/utils/SwimlaneVisibilityLimit';
import {useScreenInfo, useLazyEffect, useChangeEffect, useEventListener, useFunction, useHeaderActions} from 'hooks/Hooks';

import {TAFocusReporter} from './taRelated/TAFocusReporter';

const TAG = `AnimatedSwimlane`;

export const animationDuration = 200;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flex: 1
  },
  headerText: {
    color: colors.swimlane.header
  },
  navArrowBackground: colors.swimlane.navArrow.background,
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  headerActionsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: dimensions.margins.xxxLarge
  },
  headerAction: {
    marginRight: dimensions.margins.medium
  }
}));

enum ScrollDirection {
  Left,
  Right
}

enum ScrollMode {
  Page,
  Tile
}

enum NavigationDirection {
  Up,
  Down
}

type SwimlaneHeaderInset<T> = {
  data: T;
  index: number;
};

type SwimlaneInset<T> = SwimlaneHeaderInset<T> & {
  position: ItemPosition;
};

function getPagesIndexes(pageIndex: number) {
  if (pageIndex < 0) {
    return [];
  }

  if (pageIndex === 0) {
    return [0, 1];
  }
  return [pageIndex - 1, pageIndex, pageIndex + 1];
}

export type AnimatedSwimlaneProps<TileData> = Omit<SwimlaneProps<TileData>, 'createTile' | 'createHeaderTile'> & {
  focused: boolean;
  renderFocusedTileFrame?: () => ReactNode;
  onSwimlaneEmpty: (swimlaneIndex: number) => void;
  onFirstTileMount?: () => void;
  onTilePress?: (tileIndex: number, data: TileData) => void;
  onTileFocus?: (tileIndex: number, row: number, data: TileData, headerActionsVisible: boolean) => void;
  onHeaderTileFocus?: (headerTileIndex: number, row: number, data: NamedAction) => void;
  createTile: (props: SwimlaneTileProps<TileData>, animatedTileProps?: AnimatedTileProps) => TileRenderer;
  createHeaderTile?: (props: SwimlaneTileProps<NamedAction>, animatedTileProps?: AnimatedTileProps) => TileRenderer;
  onHoverChange?: (hovered: boolean, row: number) => void;
  positionLeftOffset?: number;
};

export interface AnimatedSwimlaneInterface {
  scrollLeft: () => void;
  scrollRight: () => void;
  navigateUp: () => boolean;
  navigateDown: () => boolean;
  onPress: () => void;
}

const defaultCreateHeaderTile = ({data, onFocus}: SwimlaneTileProps<NamedAction>, animatedTileProps?: AnimatedTileProps) => (
  (focused: boolean) => (
    <ButtonTile
      ref={animatedTileProps?.refHandler}
      label={data.label}
      onFocus={onFocus}
      onPress={data.onPress}
      staticallyFocused={focused}
    />
  )
);

function AnimatedSwimlaneComponent<TileData>(props: AnimatedSwimlaneProps<TileData>, ref: Ref<AnimatedSwimlaneInterface>): React.ReactElement | null {
  const {
    style,
    row,
    width: propsWidth,
    itemHeight,
    itemWidth,
    extraData,
    header,
    headerInsetLeft = dimensions.margins.small,
    insets = {},
    createDataFetcher,
    dataChangeEvent = MetaEvent.None,
    dataRefreshEvent = MetaEvent.None,
    dataEventsEmitter,
    renderNavigationArrows = isDesktopBrowser && !isSTBBrowser,
    alignNavigationArrowsToInsets = true,
    swimlaneHeight,
    onTileFocus,
    onHeaderTileFocus,
    onDataFetcherStateChanged,
    focused: focusedSwimlane,
    onSwimlaneEmpty,
    createTile,
    createHeaderTile = defaultCreateHeaderTile,
    placeholderComponent,
    renderFocusedTileFrame,
    onHoverChange,
    staticHeaderActions = [],
    showHeaderActions = false,
    headerActionsVisibilityThreshold = defaultHeaderActionsVisibilityThreshold,
    positionLeftOffset = 0
  } = props;

  const {size: {width: screenWidth}} = useScreenInfo();
  const width = useMemo(() => propsWidth ?? screenWidth, [propsWidth, screenWidth]);
  const scrollViewSize = useMemo(() => ({width, height: itemHeight}), [itemHeight, width]);

  const arrowWidth = renderNavigationArrows ? WEB_ARROW_CONTAINER_WIDTH : 0;
  const marginLeft = arrowWidth + (insets.left || 0);
  const marginRight = arrowWidth + (insets.right || 0);
  const numberOfItemsInPage = Math.floor((width - marginLeft - marginRight) / itemWidth);

  const [dataFetcher, setDataFetcher] = useState(props.dataFetcher || (createDataFetcher?.()));
  const [dataFetcherState, setDataFetcherState] = useState<SwimlaneDataFetcherState>(SwimlaneDataFetcherState.Initializing);
  const dataSource = useRef(createDataSource(dataFetcher, `Swimlane #${row}`));

  const [focusedPage, setFocusedPage] = useState(0);

  const [itemsData, setItemsData] = useState<SwimlaneInset<TileData | undefined>[]>([]);
  const {setDataSourceHeaderAction, headerActions} = useHeaderActions(staticHeaderActions);
  const [headerActionsVisible, setHeaderActionsVisible] = useState(headerActionsVisibilityThreshold == null && !!staticHeaderActions.length);

  const gridScrollOffset = useRef(new Animated.ValueXY());
  const [headerFocused, setHeaderFocused] = useState(false);
  const [focusedHeaderTileIndex, setFocusedHeaderTileIndex] = useState(0);
  const [focusedTileIndex, setFocusedTileIndex] = useState(0);
  const focusedTileRef = useRef<AnimatedTileInterface | null>(null);

  const onHoverChangeInternal = useCallback((on: boolean) => {
    onHoverChange?.(on, row);
  }, [onHoverChange, row]);

  const onDataRefresh = useCallback(() => {
    async function getItems() {
      const items: Promise<SwimlaneInset<TileData | undefined>>[] = [];
      const source = dataSource.current;
      getPagesIndexes(focusedPage).forEach(page => {
        for (let index = page * numberOfItemsInPage; index < (page + 1) * numberOfItemsInPage; index++) {
          items.push(
            source.getDataForIndex(index)
              .then(data => ({
                data,
                index,
                position: {
                  left: (index * itemWidth) + marginLeft,
                  top: 0,
                  width: itemWidth,
                  height: itemHeight
                }
              }))
          );
        }
      });

      const newItems = (await Promise.all(items)).filter((item) => item.data);
      Log.info(TAG, `swimlane ${row} "${header}": has: ${newItems.length} items`);
      if (dataSource.current !== source) {
        Log.info(TAG, `swimlane ${row} "${header}": dataSource changed while refreshing data, ignoring results...`);
        return;
      }
      if (!newItems.length) {
        onSwimlaneEmpty(row);
      }
      //TODO: CL-6954 investigate if time between tile change on edge of pages can be improved.
      setItemsData(newItems);
      setDataFetcherState(newItems.length ? SwimlaneDataFetcherState.HasData : SwimlaneDataFetcherState.NoData);
      setDataSourceHeaderAction(dataSource.current.getAction());
    }
    getItems();
  }, [focusedPage, row, header, setDataSourceHeaderAction, numberOfItemsInPage, itemWidth, marginLeft, itemHeight, onSwimlaneEmpty]);

  useChangeEffect(() => {
    dataSource.current = createDataSource(dataFetcher, `Swimlane #${row}`);
    setDataFetcherState(SwimlaneDataFetcherState.FetchingFirstPage);
    setDataSourceHeaderAction(undefined);
    onDataRefresh();
  }, [dataFetcher], [row, onDataRefresh]);

  const onDataChange = useCallback(() => {
    if (createDataFetcher) {
      setDataFetcher(createDataFetcher());
    }
  }, [createDataFetcher]);

  useEventListener(dataChangeEvent, onDataChange, dataEventsEmitter);
  useEventListener(dataRefreshEvent, onDataRefresh, dataEventsEmitter);

  useChangeEffect(onDataRefresh, [extraData], [onDataRefresh]);

  useChangeEffect(() => {
    onDataFetcherStateChanged?.(dataFetcherState, row);
  }, [dataFetcherState], [onDataFetcherStateChanged]);

  const onTileFocusHandler = useCallback((tileIndex: number, row: number, data: TileData, ref: RefObject<Component>) => {
    TAFocusReporter.reportFocusByRef(ref);
    if (isDesktopBrowser) {
      setFocusedTileIndex(tileIndex);
    }
    const actionsVisible = showHeaderActions && focusedSwimlane && tileIndex > (headerActionsVisibilityThreshold ?? 0) - 1 && !!headerActions.length;
    setHeaderActionsVisible(actionsVisible);
    setHeaderFocused(false);
    onTileFocus?.(tileIndex, row, data, actionsVisible);
  }, [showHeaderActions, focusedSwimlane, headerActionsVisibilityThreshold, headerActions.length, onTileFocus]);

  const createTileHandler = useCallback((tileProps: SwimlaneTileProps<TileData>) => {
    const tileFocused = focusedTileIndex === tileProps.index && focusedSwimlane && !headerFocused;
    const refHandler = (ref: unknown) => {
      if (tileFocused) {
        focusedTileRef.current = ref as AnimatedTileInterface;
      }
    };
    const createTileCallback = (focused: boolean) => {
      return createTile(tileProps, {refHandler: refHandler})(focused);
    };
    return (
      <AnimatedSwimlaneTile<TileData>
        index={tileProps.index}
        row={tileProps.row}
        data={tileProps.data}
        // focus state is purely logical for desktop browser
        // implicates only scroll position
        focused={!isDesktopBrowser && tileFocused}
        onTileFocus={onTileFocusHandler}
        createTileCallback={createTileCallback}
      />
    );
  }, [createTile, focusedSwimlane, focusedTileIndex, onTileFocusHandler, headerFocused]);

  const navigationArrowsInsetLeft = useMemo(() => {
    return renderNavigationArrows && alignNavigationArrowsToInsets ? insets.left : 0;
  }, [renderNavigationArrows, alignNavigationArrowsToInsets, insets.left]);

  const navigationArrowsInsetRight = useMemo(() => {
    return renderNavigationArrows && alignNavigationArrowsToInsets ? insets.right : 0;
  }, [renderNavigationArrows, alignNavigationArrowsToInsets, insets.right]);

  useLazyEffect(() => {
    onDataRefresh();
  }, [focusedPage], [onDataRefresh]);

  const navigateToAction = useFunction((direction: ScrollDirection) => {
    const delta = direction === ScrollDirection.Right ? 1 : -1;
    const nextIndex = Math.max(0, Math.min(headerActions.length - 1, focusedHeaderTileIndex + delta));
    setFocusedHeaderTileIndex(nextIndex);
  });

  const scroll = useFunction((direction: ScrollDirection, scrollMode: ScrollMode) => {
    if (headerFocused) {
      navigateToAction(direction);
      return;
    }
    let newTileIndex: number;
    const lastRenderedIndex = Math.max(...itemsData.map(item => item.index));
    if (scrollMode === ScrollMode.Page && renderNavigationArrows) {
      if (!itemsData.length) {
        return;
      }
      newTileIndex = direction === ScrollDirection.Left ? Math.max(focusedTileIndex - numberOfItemsInPage, 0) : Math.min(focusedTileIndex + numberOfItemsInPage, lastRenderedIndex);
    } else {
      if (!focusedSwimlane || !itemsData.length) {
        return;
      }
      newTileIndex = direction === ScrollDirection.Left ? focusedTileIndex - 1 : focusedTileIndex + 1;
    }
    const tileData = itemsData.find((item) => item.index === newTileIndex);
    if (!tileData) {
      Log.error(TAG, `scroll: there's no tile with calculated index: ${newTileIndex}`);
      return;
    }

    Animated.timing(gridScrollOffset.current, {
      toValue: {x: tileData.position.left - marginLeft, y: 0},
      duration: animationDuration,
      useNativeDriver: true
    }).start();
    setFocusedTileIndex(newTileIndex);
    if (tileData.index < focusedPage * numberOfItemsInPage) {
      setFocusedPage(focusedPage - 1);
    } else if (tileData.index >= (focusedPage + 1) * numberOfItemsInPage) {
      setFocusedPage(focusedPage + 1);
    }
  });

  const webScrollLeft = useCallback(() => {
    scroll(ScrollDirection.Left, ScrollMode.Page);
  }, [scroll]);
  const webScrollRight = useCallback(() => {
    scroll(ScrollDirection.Right, ScrollMode.Page);
  }, [scroll]);

  const navigate = useFunction((direction: NavigationDirection): boolean => {
    if (direction === NavigationDirection.Up && !headerActionsVisible) {
      return false;
    }
    if (direction === NavigationDirection.Down && headerFocused) {
      setHeaderFocused(false);
      return true;
    }
    if (direction === NavigationDirection.Up && !headerFocused) {
      setHeaderFocused(true);
      return true;
    }
    return false;
  });

  /* eslint-disable schange-rules/no-use-imperative-handle-hook */
  useImperativeHandle(ref, () => {
    const handlers: AnimatedSwimlaneInterface = {
      scrollLeft: () => scroll(ScrollDirection.Left, ScrollMode.Tile),
      scrollRight: () => scroll(ScrollDirection.Right, ScrollMode.Tile),
      navigateUp: () => navigate(NavigationDirection.Up),
      navigateDown: () => navigate(NavigationDirection.Down),
      onPress: () => {
        if (!focusedSwimlane) {
          return;
        }
        focusedTileRef.current?.onPress();
      }
    };
    return handlers;
  }, [focusedSwimlane, scroll, navigate]);
  /* eslint-enable schange-rules/no-use-imperative-handle-hook */

  const renderItem = useCallback((inset: SwimlaneInset<TileData | undefined>) => {
    if (inset?.data == null) {
      return null;
    }
    const {position, data, index} = inset;
    return (
      <View
        style={{
          position: 'absolute',
          left: position.left,
          top: position.top,
          width: position.width,
          height: position.height
        }}
        key={`x${position.left}index${index}`}
      >
        {createTileHandler({data, index, row, onFocus: doNothing, wrapAroundActive: () => false})}
      </View>
    );
  }, [createTileHandler, row]);

  const renderPlaceholder = useCallback((unusedData, index) => {
    const data = {
      position: {
        left: (index * itemWidth) + marginLeft,
        top: 0,
        width: itemWidth,
        height: itemHeight
      }
    };
    const tileFocused = focusedTileIndex === index && focusedSwimlane && !headerFocused;
    return (
      <View
        style={{
          position: 'absolute',
          left: data.position.left,
          top: data.position.top,
          width: data.position.width,
          height: data.position.height
        }}
        key={`x${data.position.left}index${index}`}
      >
        {placeholderComponent?.(row, tileFocused)}
      </View>
    );
  }, [marginLeft, itemHeight, itemWidth, focusedTileIndex, focusedSwimlane, placeholderComponent, row, headerFocused]);

  const testID = props.testID ?? `swimlane_` + (typeof header === 'string' ? humanCaseToSnakeCase(header) : String(row));

  const viewStyle: ViewStyle = useMemo(() => ({
    flex: 1,
    paddingTop: insets?.top,
    paddingBottom: insets?.bottom,
    height: swimlaneHeight,
    ...style
  }), [style, insets, swimlaneHeight]);

  useEffect(() => {
    if (!focusedSwimlane) {
      setHeaderActionsVisible(headerActionsVisibilityThreshold == null && !!headerActions.length);
      setHeaderFocused(false);
    }
  }, [focusedSwimlane, headerActions.length, headerActionsVisibilityThreshold]);

  const styles = stylesUpdater.getStyles();

  const renderTitle = useCallback(() => {
    if (typeof header === 'string') {
      return (
        <NitroxText
          textType='headline'
          style={{...styles.headerText, height: swimlaneHeaderHeight, paddingLeft: (insets?.left || 0) + headerInsetLeft}}
          upperCase
        >
          {header}
        </NitroxText>
      );
    }
    if (typeof header === 'function') {
      return header(row);
    }
    return null;
  }, [header, insets, headerInsetLeft, row, styles.headerText]);

  const onHeaderTileFocusHandler = useCallback((headerTileIndex: number, row: number, data: NamedAction, ref: RefObject<Component>) => {
    TAFocusReporter.reportFocusByRef(ref);
    if (isDesktopBrowser) {
      setFocusedHeaderTileIndex(headerTileIndex);
    }
    setHeaderFocused(true);
    onHeaderTileFocus?.(headerTileIndex, row, data);
  }, [onHeaderTileFocus]);

  const createHeaderTileHandler = useCallback((headerTileProps: SwimlaneTileProps<NamedAction>) => {
    const {index, row, data} = headerTileProps;
    const headerTileFocused = focusedHeaderTileIndex === index && focusedSwimlane && headerFocused;
    const refHandler = (ref: unknown) => {
      if (headerTileFocused) {
        focusedTileRef.current = ref as AnimatedTileInterface;
      }
    };
    return (
      <AnimatedSwimlaneTile<NamedAction>
        index={index}
        row={row}
        data={data}
        focused={!isDesktopBrowser && headerTileFocused}
        onTileFocus={onHeaderTileFocusHandler}
        createTileCallback={(focused: boolean) => createHeaderTile(headerTileProps, {refHandler})(focused)}
      />
    );
  }, [focusedSwimlane, focusedHeaderTileIndex, onHeaderTileFocusHandler, headerFocused, createHeaderTile]);

  const renderHeaderTile = useCallback(({data, index}: SwimlaneHeaderInset<NamedAction>) => (
    <View key={`index${index}`} style={styles.headerAction}>
      {createHeaderTileHandler({index, row, data, onFocus: doNothing, wrapAroundActive: () => false})}
    </View>
  ), [createHeaderTileHandler, row, styles.headerAction]);

  const renderedHeader = useMemo(() => {
    const title = renderTitle();
    if (!headerActionsVisible || !title || headerActions.length === 0) {
      return title;
    }
    return (
      <View style={styles.headerContainer}>
        {title}
        <View style={styles.headerActionsContainer}>
          {headerActions.map((headerAction, index) =>
            renderHeaderTile({data: headerAction, index})
          )}
        </View>
      </View>
    );
  }, [renderTitle, headerActions, headerActionsVisible, renderHeaderTile, styles.headerContainer, styles.headerActionsContainer]);

  const navArrowProps = useMemo(() => ({
    height: itemHeight,
    backgroundColor: styles.navArrowBackground,
    width: WEB_ARROW_CONTAINER_WIDTH,
    opacity: dimensions.opacity.xxhigh
  }), [itemHeight, styles.navArrowBackground]);

  const items = useMemo(() => {
    return itemsData.length ? itemsData.map(renderItem) : Array.from(Array(numberOfItemsInPage + 1).keys()).map(renderPlaceholder);
  }, [itemsData, renderItem, renderPlaceholder, numberOfItemsInPage]);

  const swimlaneContainer = useMemo(() => {
    return {
      width: scrollViewSize.width,
      height: itemHeight,
      left: positionLeftOffset
    };
  }, [positionLeftOffset, itemHeight, scrollViewSize]);

  const focusedTileFrameStyle: ViewStyle = useMemo(() => {
    return {
      position: 'absolute',
      top: 0,
      left: marginLeft,
      width: itemWidth,
      height: itemHeight,
      opacity: focusedSwimlane && !headerFocused ? 1 : 0
    };
  }, [focusedSwimlane, headerFocused, itemHeight, itemWidth, marginLeft]);

  return (
    <MouseAwareView
      style={viewStyle}
      testID={testID}
      onHoverChange={onHoverChangeInternal}
    >
      <FocusParent
        debugName={`Swimlane #${row}`}
        enterStrategy='topLeft'
        rememberLastFocused={true}
        style={styles.container}
        active={false}
      >
        {renderedHeader}
        <View style={swimlaneContainer}>
          <AnimatedScrollView
            size={scrollViewSize}
            offsetX={gridScrollOffset.current.x}
            offsetY={gridScrollOffset.current.y}
          >
            {items}
          </AnimatedScrollView>
          {renderNavigationArrows && (
            <>
              <NavArrow
                {...navArrowProps}
                direction={Direction.Left}
                handlePress={webScrollLeft}
                insetLeft={navigationArrowsInsetLeft}
              />
              <NavArrow
                {...navArrowProps}
                direction={Direction.Right}
                handlePress={webScrollRight}
                insetRight={navigationArrowsInsetRight}
              />
            </>
          )}
          {!isDesktopBrowser && (
            <View
              style={focusedTileFrameStyle}
            >
              {renderFocusedTileFrame?.()}
            </View>
          )}
        </View>
      </FocusParent>
    </MouseAwareView>
  );
}

export const AnimatedSwimlane = React.memo(forwardRef(AnimatedSwimlaneComponent)) as typeof AnimatedSwimlaneComponent;
