/* eslint-disable no-restricted-syntax */
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import elUtils from './elUtils';

export const i18nUtils: {[key: string]: i18nUtilsType; default: i18nUtilsDefaultType} = {
  el: elUtils,
  default: {
    toUpperCase: text => text.toUpperCase(),
    toLowerCase: text => text.toLowerCase(),
    capitalize: text => `${text.charAt(0).toUpperCase()}${text.slice(1)}`
  }
};

export type LocalizedTextTransformFunction = (text: string) => string;

export type i18nUtilsType = {
  toUpperCase?: LocalizedTextTransformFunction;
  toLowerCase?: LocalizedTextTransformFunction;
  capitalize?: LocalizedTextTransformFunction;
}

type i18nUtilsDefaultType = Required<i18nUtilsType>;

// Name convention for Type after WithTranslation
export type WithLocalized = i18nUtilsDefaultType;

const getI18nUtilFn = (language: string) => (identifier: keyof i18nUtilsType) => (text: string) =>
  (({...i18nUtils.default, ...i18nUtils[language]})[identifier](text));

const localizedFunctions = (language: string): WithLocalized => {
  const getUtil = getI18nUtilFn(language);
  return {
    toUpperCase: getUtil('toUpperCase'),
    capitalize: getUtil('capitalize'),
    toLowerCase: getUtil('toLowerCase')
  };
};

export const useLocalized = () => {
  const {i18n} = useTranslation();
  return useMemo(() => localizedFunctions(i18n.language), [i18n.language]);
};

export function withLocalized<T extends WithLocalized>(WrappedComponent: React.ComponentType<T>) {
  type InnerT = Omit<T, keyof WithLocalized>;
  const LocalizedTextTransform: React.ComponentType<InnerT> = (props: InnerT, ref: React.RefObject<React.ComponentType<T>>) => {
    const localizedFunctions = useLocalized();
    const passDownProps = {
      ...props as T,
      ...localizedFunctions,
      ref
    };

    return (
      <WrappedComponent {...passDownProps} />
    );
  };

  LocalizedTextTransform.displayName = `localizedTextTransform(${WrappedComponent.displayName})`;
  return React.forwardRef(LocalizedTextTransform);
}
