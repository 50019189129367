import {createStyles} from 'common-styles';
import React from 'react';
import {View} from 'react-native';

import {dimensions, isBigScreen, isMobile} from 'common/constants';
import {humanCaseToSnakeCase} from 'common/HelperFunctions';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import FocusParent from 'components/FocusParent';
import InlineBackButton from 'components/navigation/InlineBackButton';
import NitroxText from 'components/NitroxText';

const staticStyles = createStyles({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    ...isMobile ? {
      height: dimensions.screen.header.height
    } : {
      marginTop: 0
    }
  },
  backButton: {
    marginHorizontal: dimensions.margins.large,
    width: dimensions.backButton.size
  },
  backButtonGrosso: {
    marginLeft: dimensions.margins.xxLarge
  },
  titleContainer: {
    justifyContent: 'center',
    flexGrow: 1
  }
});

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  title: {
    color: colors.vodScreen.navigator.text.focused,
    textAlign: 'center'
  }
}));

type HeaderProps = {
  title: string;
  previousTitle?: string;
  inRoot: boolean;
  onBackPress: () => void;
};

function HeaderGrosso({
  title,
  inRoot,
  onBackPress,
  previousTitle
}: HeaderProps) {
  const styles = dynamicStylesUpdater.getStyles();
  return (
    <FocusParent
      style={staticStyles.header}
      debugName='VodCategoryNavigatorHeader'
    >
      <NitroxText
        textType='headline'
        style={styles.title}
        upperCase
        testID={`category_navigator_header_${humanCaseToSnakeCase(title)}`}
      >
        {title}
      </NitroxText>
      {!inRoot && (
        <InlineBackButton
          onPress={onBackPress}
          style={staticStyles.backButtonGrosso}
          text={previousTitle}
        />
      )}
    </FocusParent>
  );
}

function HeaderPiccolo({
  title,
  inRoot,
  onBackPress
}: HeaderProps) {
  const styles = dynamicStylesUpdater.getStyles();

  const spacer = (
    <View
      style={staticStyles.backButton}
    />
  );
  return (
    <View style={staticStyles.header}>
      {!inRoot && (
        <InlineBackButton
          onPress={onBackPress}
          style={staticStyles.backButton}
        />
      )}
      <View style={staticStyles.titleContainer}>
        <NitroxText
          textType='headline'
          style={styles.title}
          testID={`category_navigator_header_${humanCaseToSnakeCase(title)}`}
        >
          {title}
        </NitroxText>
      </View>
      {!inRoot && spacer}
    </View>
  );
}

export default isBigScreen ? HeaderGrosso : HeaderPiccolo;
