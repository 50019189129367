import {createStyles} from 'common-styles';
import React, {useCallback, useMemo, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import {NavigationContext} from 'react-navigation';

import {dimensions} from 'common/constants';
import {asyncIterator, openMediaDetails} from 'common/HelperFunctions';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Event} from 'mw/api/Metadata';

import {ChannelIcon, ChannelIconType, channelIconConstants} from 'components/ChannelIcon';
import {EventSelectorItem, EventSelectorContentProps} from 'components/EventSelectorContent.shared';
import NitroxText from 'components/NitroxText';
import Popup, {PopupAction} from 'components/Popup';
import {Swimlane, SwimlaneTileProps} from 'components/Swimlane';
import TileIconsRow from 'components/TileIconsRow';

const itemWidth = channelIconConstants.eventSelector.size;
const itemHeight = channelIconConstants.eventSelector.size + dimensions.icon.small + dimensions.margins.large * 4;
const itemMargin = dimensions.margins.large;
const visibleItems = 5;

const contentWidth = visibleItems * (itemWidth + itemMargin);
const contentHeight = itemHeight;
const swimlaneInsets = {
  left: Math.floor((contentWidth - itemWidth - itemMargin) / 2)
};

const styles = createStyles({
  container: {
    flexDirection: 'column',
    width: '100%',
    height: contentHeight,
    alignItems: 'center'
  },
  swimlane: {
    width: contentWidth
  },
  channelIcon: {
    flexDirection: 'column',
    alignItems: 'center'
  },
  label: {
    marginTop: dimensions.margins.large
  },
  mediaIcons: {
    marginTop: dimensions.margins.large
  }
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  label: {
    color: colors.popup.text
  },
  info: {
    color: colors.tile.description
  }
}));

const popupActions = [PopupAction.NEGATIVE];

const EventSelectorContentGrosso: React.FC<EventSelectorContentProps> = ({items, onClose}) => {
  const {t} = useTranslation();
  const navigation = useContext(NavigationContext);

  const onPress = useCallback((event: Event) => {
    openMediaDetails(navigation, event.id, event.getType());
    onClose();
  }, [navigation, onClose]);

  const dynamicStyles = stylesUpdater.getStyles();

  const createTile = useCallback((props: SwimlaneTileProps<EventSelectorItem>) => {
    const {event, mediaIcons, hours, date} = props.data;
    return (
      <ChannelIcon
        type={ChannelIconType.EventSelector}
        channelId={event.channelId}
        style={styles.channelIcon}
        onPress={() => onPress(event)}
      >
        {!!hours && <NitroxText style={[styles.label, dynamicStyles.label]} textType='event-selector-title'>{hours}</NitroxText>}
        {!!date && <NitroxText style={dynamicStyles.info} textType='event-selector-subtitle'>{date}</NitroxText>}
        <TileIconsRow style={styles.mediaIcons} mediaIcons={mediaIcons} />
      </ChannelIcon>
    );
  }, [dynamicStyles, onPress]);

  const dataFetcher = useMemo(() => asyncIterator(items), [items]);

  return (
    <Popup
      onClose={onClose}
      actions={popupActions}
      negativeLabel={t('common.close')}
      title={t('common.selectEvent')}
      visible
    >
      <View style={styles.container}>
        <Swimlane<EventSelectorItem>
          dataFetcher={dataFetcher}
          wrapAround={false}
          style={styles.swimlane}
          width={contentWidth}
          insets={swimlaneInsets}
          alignNavigationArrowsToInsets={false}
          row={0}
          itemWidth={itemWidth + itemMargin}
          itemHeight={itemHeight}
          createTile={createTile}
        />
      </View>
    </Popup>
  );
};

export default EventSelectorContentGrosso;
