import {useState, useMemo} from 'react';

import {AppRoutes, isMobile} from 'common/constants';
import {Log} from 'common/Log';

import {Order} from 'mw/api/Metadata';

import {ListItemProps} from 'components/ListView';
import {isPinProtected} from 'components/navigation/NavigationHelperFunctions';
import {UnlockModalRejectionReason} from 'components/unlockmodal/UnlockModal';
import {useNavigation} from 'hooks/Hooks';
import {SubScreenRoutes} from 'screens/ParallelNavigator';

import {CommonSettingsScreenProps} from './CommonSettingsScreenProps';

const TAG = 'settings/hooks';

type RouteProps = {
  pin?: string;
  params?: RouteParams[SubScreenRoutes];
};

// FIXME CL-6134 - add possibility to get conditional type from map of types
type RouteParams = {
  [AppRoutes.OrderDetails]: Order
  [AppRoutes.SettingsCustomer]: undefined,
  [AppRoutes.SettingsAppVersion]: undefined,
  [AppRoutes.SettingsBackOffice]: undefined,
  [AppRoutes.SettingsDeleteProfile]: undefined,
  [AppRoutes.CreateProfileWizard]: undefined,
  [AppRoutes.SettingsParentalControl]: undefined,
  [AppRoutes.SettingsRegisteredDevices]: undefined,
  [AppRoutes.SettingsLanguage]: undefined,
  [AppRoutes.SettingsTimeAndDate]: undefined,
  [AppRoutes.SettingsPin]: undefined,
  [AppRoutes.SettingsName]: {operatorLogoUrl: string},
  [AppRoutes.SettingsAccount]: undefined,
  [AppRoutes.SettingsOrders]: undefined,
  [AppRoutes.SettingsHelp]: undefined
}

export function useSettingsNavigation(
  authorizeWithPin: CommonSettingsScreenProps['authorizeWithPin']
) {
  const [subRoute, setSubRoute] = useState<SubScreenRoutes>(AppRoutes.SettingsName);
  const [subRouteProps, setSubRouteProps] = useState<RouteProps>();
  const navigation = useNavigation();
  const navigators = useMemo(() => {
    const navigateToSubScreen = <Route extends SubScreenRoutes>(route: SubScreenRoutes, adultProfile = false, params?: RouteParams[Route]) => {
      const navigate = (route: SubScreenRoutes, routeProps: RouteProps = {}) => {
        if (isMobile) {
          navigation.navigate(route, {...routeProps.params, pin: routeProps.pin});
        } else {
          setSubRoute(route);
          setSubRouteProps(routeProps);
        }
      };

      if (isPinProtected(route)) {
        authorizeWithPin(adultProfile)
          .then(pin => navigate(route, {params, pin}))
          .catch(error => {
            if (error.reason !== UnlockModalRejectionReason.Cancel) {
              Log.error(TAG, 'Error when authorizing with pin: ', error);
            }
          });
      } else {
        navigate(route, {params});
      }
    };

    const navigateToInfo = () => {
      const operatorLogoUrl = navigation.getParam('operatorLogoUrl');
      navigateToSubScreen(AppRoutes.SettingsName, false, {operatorLogoUrl});
    };

    return {
      initial: navigateToInfo,
      info: navigateToInfo,
      customerID: () => navigateToSubScreen(AppRoutes.SettingsCustomer),
      registeredDevices: () => navigateToSubScreen(AppRoutes.SettingsRegisteredDevices),
      applicationVersion: () => navigateToSubScreen(AppRoutes.SettingsAppVersion),
      backOfficeSelector: () => navigateToSubScreen(AppRoutes.SettingsBackOffice),
      channelLists: () => {},
      language: () => navigateToSubScreen(AppRoutes.SettingsLanguage),
      timeAndDate: () => navigateToSubScreen(AppRoutes.SettingsTimeAndDate),
      parentalControl: () => navigateToSubScreen(AppRoutes.SettingsParentalControl, true),
      myPin: () => navigateToSubScreen(AppRoutes.SettingsPin),
      deleteProfile: () => navigateToSubScreen(AppRoutes.SettingsDeleteProfile),
      profileWizard: () => navigation.navigate(AppRoutes.CreateProfileWizard),
      myAccount: (accountSettingsItems: ListItemProps[]) => navigation.navigate(AppRoutes.SettingsAccount, {accountSettingsItems: accountSettingsItems}),
      settingsOrders: () => navigateToSubScreen(AppRoutes.SettingsOrders),
      orderDetails: (order: Order) => navigateToSubScreen(AppRoutes.OrderDetails, false, order),
      help: () => navigateToSubScreen(AppRoutes.SettingsHelp)
    };
  }, [navigation, authorizeWithPin]);
  return {
    navigators,
    subRoute: subRoute as SubScreenRoutes, // yep ts, you are great
    subRouteProps
  };
}
