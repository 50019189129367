import {DateUtils} from 'common/DateUtils';
import {Log} from 'common/Log';

import {Error, ErrorType} from 'mw/api/Error';
import {Channel, Event} from 'mw/api/Metadata';
import {mw} from 'mw/MW';
import {Updater} from 'mw/utils/Updater';

import {EventUpdaterEvents} from './EventUpdaterEvents';

const TAG = `LiveEventUpdater`;

export class LiveEventUpdater extends Updater<EventUpdaterEvents, Event, Date> {
  protected currentEvent: Event | null = null;
  private channel: Channel | null = null;

  public constructor(channel: Channel) {
    super();
    this.channel = channel;
  }

  protected updateData(date: Date): Promise<void> {
    if (!this.channel) {
      Log.error(TAG, 'updateData: Channel not set');
      return Promise.reject(new Error(ErrorType.InvalidParameter));
    }

    Log.debug(TAG, 'updateData: getCurrentEvent');
    return mw.catalog
      .getEvent(this.channel, date)
      .then(event => {
        const currentEventChanged = !this.currentEvent || !this.currentEvent.equals(event);
        this.currentEvent = event;
        Log.debug(TAG, 'updateData: getCurrentEvent: update success', currentEventChanged, event);
        currentEventChanged && this.notify(EventUpdaterEvents.NewEvent, event);
      });
  }

  protected handleError(error: any): void {
    Log.error(TAG, 'handleError', error);
    this.notify(EventUpdaterEvents.NewEvent);
  }

  protected isUpdateNeeded(position: number): boolean {
    const positionDate = new Date(position * DateUtils.msInSec);
    return !this.currentEvent || positionDate > this.currentEvent.end || positionDate < this.currentEvent.start;
  }

  public triggerUpdate(position: number): void {
    if (!this.canUpdate()) {
      return;
    }

    this.waitForUpdate(position)
      .catch(error => Log.error(TAG, `Error while updating current event`, error));
  }

  public waitForUpdate(position: number): Promise<void> {
    if (!this.isUpdateNeeded(position)) {
      return Promise.resolve();
    }

    Log.debug(TAG, 'waitForUpdate: update event', position);
    return this.update(new Date(position * DateUtils.msInSec));
  }

  public getCurrentEvent(): Event | null {
    return this.currentEvent;
  }
}
