import {Series, SeriesType} from 'mw/api/Metadata';
import {SeriesObject} from 'mw/metadata/SeriesObject';

import {AssetTypes} from './constants';
import {MetadataMapper} from './MetadataMapper';
import {PictureMapper} from './PictureMapper';

export class SeasonMapper {
  public static seasonsFromJson(contentJson: any): Series[] {
    if (!Array.isArray(contentJson)) {
      return [];
    }
    return contentJson.map(SeasonMapper.seasonFromJson);
  }

  public static seasonFromJson(seasonJson: any): Series {
    const {uid, title, number, externalResources, assetType} = seasonJson;

    if (assetType !== AssetTypes.Season) {
      throw new Error('Invalid subtype for season: ' + assetType);
    }

    const season = new SeriesObject(uid, title, MetadataMapper.metadataFromJson(seasonJson), SeriesType.Season, number);
    season.pictures = externalResources?.image?.map(PictureMapper.pictureFromJson) ?? [];

    return season;
  }
}
