import Color from 'color';
import {createStyles} from 'common-styles';
import moment from 'moment';
import React, {useCallback, useMemo} from 'react';
import {View, StyleSheet, NativeScrollEvent, LayoutChangeEvent, NativeSyntheticEvent, TextStyle} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import {debounce} from 'common/Async';
import {isTablet, Direction, dimensions, getTADateFormat} from 'common/constants';
import {indexKeyExtractor} from 'common/HelperFunctions';
import {TestProps} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {constColors, BaseColors} from 'common-styles/variables/base-colors';

import NitroxText from 'components/NitroxText';
import {useTestID} from 'hooks/Hooks';

import {Icon, IconType} from './Icon';
import NitroxFlatList from './NitroxFlatList';
import NitroxInteractive from './NitroxInteractive';

const TAG = 'DatePicker';
const arrowIconWidth = 14;
const arrowPadding = isTablet ? 30 : 20;
const arrowWidth = arrowPadding + arrowIconWidth;
const itemSpacing = dimensions.margins.xLarge;
const gradientWidth = isTablet ? 100 : 60;
const defaultDatePickerFormat = 'dddd, MMMM D';

export const datePickerHeight = 46;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  listContainer: {
    flexDirection: 'row',
    width: '100%',
    height: datePickerHeight,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.tvScreen.datepicker.background,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: colors.tvScreen.datepicker.border
  },
  linearGradient: {
    position: 'absolute',
    width: gradientWidth,
    height: '100%',
    top: 0
  },
  textContainer: {
    backgroundColor: constColors.transparent,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
  arrow: {
    width: arrowWidth,
    height: arrowWidth,
    justifyContent: 'center'
  },
  arrowLeft: {
    alignItems: 'flex-end'
  },
  arrowRight: {
    alignItems: 'flex-start'
  },
  arrowIcon: {
    color: colors.tvScreen.datepicker.arrow
  },
  itemFocused: {
    color: colors.tvScreen.datepicker.text.focused
  },
  itemUnfocused: {
    color: colors.tvScreen.datepicker.text.unfocused
  }
}));

type ArrowProps = {
  direction: Direction;
  onPress: (delta: number) => void;
  listWidth: number;
}

const Arrow = React.memo((props: ArrowProps) => {
  const {onPress: propsOnPress} = props;
  const onPressHandler = useCallback(() => {
    propsOnPress(props.direction === Direction.Left ? -1 : 1);
  }, [props.direction, propsOnPress]);
  const testID = useMemo(() => `button_arrow_${props.direction}`, [props.direction]);
  const styles = stylesUpdater.getStyles();
  return props.listWidth > 0 ? (
    <NitroxInteractive
      style={[styles.arrow, props.direction === Direction.Left ? styles.arrowLeft : styles.arrowRight]}
      onPress={onPressHandler}
      testID={testID}
    >
      <Icon
        type={props.direction === Direction.Left ? IconType.ArrowLeft : IconType.ArrowRight}
        size={arrowIconWidth}
        color={styles.arrowIcon.color}
      />
    </NitroxInteractive>
  ) : null;
});
Arrow.displayName = 'Arrow';

type ListItem = {
  text: string;
  textWidth: number;
  taDate: string;  // Date formatted for TA
  position: number;
  selected: boolean;
}

type DatePickerProps = {
  dates: Date[];
  dateFormat?: string;
  currentDateIndex: number;
  backgroundColor?: string; /*
    Color is needed to define gradient at the edges of component.
    Actual background color of this component is transparent.
    */
  onDateChangedHandler: (index: number) => void;
  width: number;
  itemWidthRatio: number;
};

type DatePickerState = {
  listWidth: number;
  itemWidth: number;
  items: ListItem[];
  itemsMeasured: boolean;
  highlightedDateIndex: number;
};

type DatePickerItemProps = {
  onListItemLayout: (event: LayoutChangeEvent, index: number) => void;
  onItemPressed: (item: ListItem, index: number) => void;
  textStyle: TextStyle;
  item: ListItem;
  index: number;
  itemSize: number;
} & TestProps
const DatePickerItemComponent: React.FC<DatePickerItemProps> = props => {
  const {
    onItemPressed,
    onListItemLayout,
    textStyle,
    item,
    index,
    itemSize
  } = props;
  const onPressHandler = useCallback(() => onItemPressed(item, index), [index, item, onItemPressed]);
  const onLayoutHandler = useCallback((event: LayoutChangeEvent) => {
    onListItemLayout(event, index);
  }, [index, onListItemLayout]);
  const testID = useTestID(props, 'DateItem') || `date_item_${item.taDate}`;
  const styles = stylesUpdater.getStyles();
  return (
    <NitroxInteractive
      style={[styles.textContainer, {width: itemSize}]}
      onPress={onPressHandler}
      onLayout={onLayoutHandler}
      testID={testID}
    >
      <NitroxText style={textStyle} textType='callout' ellipsizeMode='middle' numberOfLines={1}>{item.text}</NitroxText>
    </NitroxInteractive>
  );
};
const DatePickerItem = React.memo(DatePickerItemComponent);

export default class DatePicker extends React.PureComponent<DatePickerProps, DatePickerState> {
  private flatListRef: NitroxFlatList<ListItem> | null = null;
  private isScrollInvokedByDrag = false;

  public constructor(props: DatePickerProps) {
    super(props);
    this.state = {
      listWidth: DatePicker.calculateListWidth(this.props.width),
      itemWidth: DatePicker.calculateItemWidth(this.props.width, this.props.itemWidthRatio),
      items: this.props.dates.map(date => {
        const momentDate = moment(date);
        const text = momentDate.format(this.props.dateFormat ?? defaultDatePickerFormat);
        return {text, textWidth: 0, taDate: momentDate.format(getTADateFormat()), position: 0, selected: false};
      }),
      itemsMeasured: false,
      highlightedDateIndex: this.props.currentDateIndex
    };
  }

  static getDerivedStateFromProps(props: DatePickerProps, state: DatePickerState) {
    const listWidth = DatePicker.calculateListWidth(props.width);
    const itemWidth = DatePicker.calculateItemWidth(props.width, props.itemWidthRatio);
    if (state.listWidth !== listWidth || state.itemWidth !== itemWidth) {
      return {listWidth, itemWidth};
    }
    return null;
  }

  public componentDidMount() {
    this.initialScroll();
  }

  public componentDidUpdate(prevProps: DatePickerProps, prevState: DatePickerState) {
    if (!this.state.itemsMeasured) {
      return;
    }
    if (this.flatListRef === null) {
      Log.error(TAG, 'componentDidUpdate: flat list reference shouldn\'t be null');
      return;
    }
    if (prevProps.currentDateIndex !== this.props.currentDateIndex || prevState.itemsMeasured !== this.state.itemsMeasured) {
      this.abortScrollActions();
      this.updateSelection(this.props.currentDateIndex);
      this.requestScrollTo(this.props.currentDateIndex);
    }
  }

  private static calculateListWidth(propsWidth: number) {
    return propsWidth - (2 * arrowWidth);
  }

  private static calculateItemWidth(propsWidth: number, itemWidthRatio: number) {
    return ((propsWidth - (2 * arrowWidth)) * itemWidthRatio) + itemSpacing;
  }

  public componentWillUnmount() {
    this.abortScrollActions();
  }

  private calculatePositions = (windowWidth: number) => {
    const items: ListItem[] = this.state.items.map(x => ({...x}));
    items[0].position = (windowWidth - this.state.itemWidth) / 2;

    for (let index = 1; index < items.length; ++index) {
      items[index].position = items[index - 1].position + this.state.itemWidth;
    }
    return {items};
  };

  private onListItemLayout = (event: LayoutChangeEvent, index: number) => {
    const items: ListItem[] = [...this.state.items];
    items[index] = {...items[index], textWidth: event.nativeEvent.layout.width};
    if (items.length - 1 === index) {
      // The if is a hack to change itemsMeasured flag only once from false to true,
      // We cannot ensure the order of children layouting. There's no guarantee that last child (index-wise) will be rendered last
      this.setState({itemsMeasured: true, items});
    } else {
      this.setState({items});
    }
  };

  private getCenterItem = (event: NativeSyntheticEvent<NativeScrollEvent>, items: ListItem[]) => {
    const middleX: number = event.nativeEvent.contentOffset.x + this.state.listWidth / 2;
    return items.findIndex(item => {
      return item.position < middleX && item.position + item.textWidth > middleX;
    });
  };

  private onScroll = (event: NativeSyntheticEvent<NativeScrollEvent>, items: ListItem[]) => {
    const resultIndex = this.getCenterItem(event, items);
    if (resultIndex !== -1 && resultIndex !== this.state.highlightedDateIndex) {
      this.setState({highlightedDateIndex: resultIndex});
    }
  };

  private onScrollEnd = (event: NativeSyntheticEvent<NativeScrollEvent>, items: ListItem[]) => {
    if (!this.isScrollInvokedByDrag) {
      return;
    }
    this.isScrollInvokedByDrag = false;
    const resultIndex = this.getCenterItem(event, items);
    if (resultIndex !== -1 && resultIndex !== this.props.currentDateIndex) {
      this.abortScrollActions();
      this.updateSelection(resultIndex);
    }
    if (resultIndex !== -1) {
      this.scrollTo(resultIndex);
    }
  };

  private updateSelection = debounce((selectedIndex: number) => {
    this.props.onDateChangedHandler(selectedIndex);
  }, 50);

  private updateSelectionBy = debounce((delta: number) => {
    const newIndex = this.props.currentDateIndex + delta;
    if (newIndex > this.state.items.length - 1 || newIndex < 0) {
      return;
    }
    this.props.onDateChangedHandler(newIndex);
  }, 50);

  private initialScroll = debounce(() => {
    this.scrollTo(this.props.currentDateIndex, false);
  }, 0);

  private requestScrollTo = debounce((index: number, animated?: boolean) => {
    /// offset 0 is placed in the middle of first item
    this.scrollTo(index, animated);
  }, 100);

  private scrollTo = (index: number, animated = true) => {
    /// offset 0 is placed in the middle of first item
    this.flatListRef?.scrollToOffset({animated, offset: (index * this.state.itemWidth)});
  };

  private abortScrollActions = () => {
    this.initialScroll.abort();
    this.requestScrollTo.abort();
    this.updateSelection.abort();
  };

  private onItemPressed = (item: ListItem, index: number) => {
    this.updateSelection(index);
  };

  private onScrollBeginDrag = () => {
    this.isScrollInvokedByDrag = true;
  }

  private itemColorStyle = (index: number, focusedStyle: TextStyle, unfocusedStyle: TextStyle) => {
    if (index === this.state.highlightedDateIndex) {
      return focusedStyle;
    }

    return unfocusedStyle;
  };

  private itemWidth = () => {
    const baseWidth = (this.props.width - (2 * arrowWidth)) * this.props.itemWidthRatio;
    return baseWidth + itemSpacing;
  };

  public render() {
    const {items} = this.calculatePositions(this.state.listWidth);
    const containerStyle = {
      paddingLeft: (this.state.listWidth - this.state.itemWidth) / 2,
      paddingRight: (this.state.listWidth - this.state.itemWidth) / 2
    };
    const styles = stylesUpdater.getStyles();
    return (
      <View
        style={styles.listContainer}
        testID='date_picker'
      >
        <Arrow
          direction={Direction.Left}
          onPress={this.updateSelectionBy}
          listWidth={this.state.listWidth}
        />
        <View style={{flex: 1}}>
          <NitroxFlatList
            ref={ref => {this.flatListRef = ref;}}
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            data={items}
            initialNumToRender={items.length}
            style={{opacity: this.state.itemsMeasured ? 1 : 0}}
            snapToOffsets={[...Array(items.length)].map((x, i) => (i * this.state.itemWidth))}
            decelerationRate='fast'
            renderItem={({item, index}) => (
              <DatePickerItem
                item={item}
                index={index}
                textStyle={this.itemColorStyle(index, styles.itemFocused, styles.itemUnfocused)}
                onListItemLayout={this.onListItemLayout}
                itemSize={this.state.itemWidth}
                onItemPressed={this.onItemPressed}
              />
            )}
            contentContainerStyle={containerStyle}
            keyExtractor={indexKeyExtractor}
            onMomentumScrollEnd={event => this.onScrollEnd(event, items)}
            onTouchStart={this.abortScrollActions}
            onScrollBeginDrag={this.onScrollBeginDrag}
            onScroll={event => this.onScroll(event, items)}
            scrollEventThrottle={3}
            overScrollMode='always'
          />
          {this.props.backgroundColor && (
            <LinearGradient
              start={{x: 0, y: 0}}
              end={{x: 1, y: 0}}
              colors={[this.props.backgroundColor, Color(this.props.backgroundColor).alpha(0).toString()]}
              style={[styles.linearGradient, {left: 0}]}
              pointerEvents='none'
            />
          )}
          {this.props.backgroundColor && (
            <LinearGradient
              start={{x: 1, y: 0}}
              end={{x: 0, y: 0}}
              colors={[this.props.backgroundColor, Color(this.props.backgroundColor).alpha(0).toString()]}
              style={[styles.linearGradient, {right: 0}]}
              pointerEvents='none'
            />
          )}
        </View>
        <Arrow
          direction={Direction.Right}
          onPress={this.updateSelectionBy}
          listWidth={this.state.listWidth}
        />
      </View>
    );
  }
}
