import {createStyles} from 'common-styles';
import moment from 'moment';
import React, {forwardRef, useCallback, useMemo, useState, useEffect} from 'react';
import {StyleSheet, View} from 'react-native';
import {withNavigation, NavigationScreenProps} from 'react-navigation';

import {isPhone, AppRoutes, RADIUS, dimensions} from 'common/constants';
import {openMediaDetails} from 'common/HelperFunctions';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Channel, Event} from 'mw/api/Metadata';

import {ChannelIcon, ChannelIconType, channelIconConstants} from 'components/ChannelIcon';
import {useCurrentEvent} from 'components/epg/animated/EpgHooks';
import NitroxText, {textStyles} from 'components/NitroxText';
import OneChannelEpg from 'components/zapper/OneChannelEpg';
import {useNowDate} from 'hooks/Hooks';
import {ViewMode} from 'screens/tv/TvScreen';

const dateFormat = 'dddd, MMMM D';

type ChannelDetailsPiccoloProps = {
  channel?: Channel;
  startTime: Date;
  endTime: Date;
} & NavigationScreenProps;

const staticStyles = createStyles({
  container: {
    flex: 1
  },
  iconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: dimensions.margins.xxLarge
  },
  icon: {
    height: channelIconConstants.epg.size,
    width: channelIconConstants.epg.size,
    borderRadius: RADIUS
  }
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  content: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.channelDetailsScreen.background
  },
  dateContainer: {
    width: '100%',
    alignItems: 'center',
    paddingTop: dimensions.margins.medium,
    paddingBottom: dimensions.margins.medium,
    borderColor: colors.tvScreen.datepicker.border,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderBottomWidth: StyleSheet.hairlineWidth
  },
  date: {
    color: colors.channelDetailsScreen.date.text
  }
}));

const ChannelDetails: React.FC<ChannelDetailsPiccoloProps> = (props) => {
  const styles = stylesUpdater.getStyles();
  const showEpg = useCallback(() => {
    if (isPhone) {
      props.navigation.push(AppRoutes.Zapper, {viewMode: ViewMode.Epg});
    } else {
      props.navigation.navigate(AppRoutes.Epg);
    }
  }, [props.navigation]);

  const openEventDetail = useCallback((event: Event) => {
    openMediaDetails(props.navigation, event.id, event.getType());
  }, [props.navigation]);
  const [date, setDate] = useState(props.startTime);
  const formatedDate = useMemo(() => moment(date).format(dateFormat), [date]);
  const handleEpgScroll = useCallback((topEvent) => {
    if (date.toDateString() !== topEvent.start.toDateString()) {
      setDate(topEvent.start);
    }
  }, [date]);

  useEffect(() => {
    setDate(props.startTime);
  }, [props.startTime]);

  const nowDate = useNowDate();
  const currentEvent = useCurrentEvent(props.channel, nowDate);

  return (
    <View style={staticStyles.container}>
      <View style={staticStyles.iconContainer}>
        {props.channel?.id && <ChannelIcon type={ChannelIconType.EventDetails} channelId={props.channel.id} style={staticStyles.icon} />}
      </View>
      <View style={styles.content}>
        <View style={styles.dateContainer}>
          <NitroxText style={useMemo(() => [textStyles.body(), styles.date], [styles.date])} textType='callout'>{formatedDate}</NitroxText>
        </View>
        {props.channel && (
          <OneChannelEpg
            channel={props.channel}
            openEventDetail={openEventDetail}
            showEpg={showEpg}
            startTime={props.startTime}
            endTime={props.endTime}
            onScroll={handleEpgScroll}
            tunedEvent={currentEvent ?? undefined}
          />
        )}
      </View>
    </View>
  );
};

export default withNavigation(forwardRef(ChannelDetails));
