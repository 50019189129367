import {Filter} from 'mw/api/Filter';
import {Picture} from 'mw/api/Metadata';
import {Link, LinkType, Menu, MenuAlignment, MenuType, MenuRenderType, MenuSubcategoriesMode} from 'mw/cms/Menu';

import {ActionType} from './constants';

export class CategoryMapper {
  private static pictureFromJson(json: any): Picture {
    return new Picture(json.type, json.uri || json.url, json.format, json.width, json.height);
  }

  public static menuFromJson(json: any, aliasType?: string): Menu {
    const {items, images, tags, actions, title, slug} = json;
    const nestedMenus: Menu[] = items.map((item: unknown) => CategoryMapper.menuFromJson(item, aliasType));
    const pictures: Picture[] = images.map(CategoryMapper.pictureFromJson);

    const tagsMap = tags ?
      new Map<string, string>(tags.map((tag: string) => {
        const [name, value] = tag.split(':', 2);
        return [name, value || ''];
      })) : new Map<string, string>();

    const screen = tagsMap.get('screen') || '';
    const position = tagsMap.get('position') as MenuAlignment;
    const menuType = MenuType.DEFAULT;
    const renderType = tagsMap.get('render-type') as MenuRenderType || MenuRenderType.Default;
    const subcategoriesMode = tagsMap.has('show-subcategories-as-a-list') ? MenuSubcategoriesMode.InlineList : undefined;
    const backgroundGradientPrimaryColor = tagsMap.get('background-gradient-primary-color');
    const backgroundGradientSecondaryColor = tagsMap.get('background-gradient-secondary-color');
    const backgroundColor = tagsMap.get('background-color');
    let bannerRotation: number | undefined;
    const rotation = tagsMap.get('banner-rotation');
    if (rotation) {
      const value = Number.parseInt(rotation);
      if (!Number.isNaN(value)) {
        bannerRotation = value;
      }
    }

    let link: Link | undefined;
    let filter: Filter | undefined;
    if (actions && actions.length) {
      const {dataType, tags, data} = actions[0];
      if (dataType === ActionType.Custom && tags && tags.length) {
        for (const tag of tags) {
          const [tagType, slug] = tag.split(':');
          if (tagType && slug && Object.values(LinkType).includes(tagType)) {
            link = {type: tagType, slug};
            break;
          }
        }
      }
      if (dataType === ActionType.Page) {
        link = {type: LinkType.PAGE, slug: data};
      }
      if (dataType === ActionType.Filterng && data && typeof data === 'string') {
        filter = {value: `filter/${data}`, isSpecial: false, isPersonal: false, cacheTimeInMinutes: 0};
      }
      if (dataType === ActionType.AdrenalinNode) {
        filter = {value: actions[0].data.split('\"')[3], isSpecial: false, isPersonal: false, cacheTimeInMinutes: 0, aliasType};
      }
    }

    return new Menu({
      title,
      slug,
      screen,
      pictures,
      items: nestedMenus,
      filter,
      link,
      position,
      renderType,
      bannerRotation,
      type: menuType,
      subcategoriesMode,
      backgroundColor,
      backgroundGradientPrimaryColor,
      backgroundGradientSecondaryColor
    });
  }
}
