import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View, StyleProp, ViewStyle} from 'react-native';

import {dimensions, isTablet} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import DoubleBackground from 'components/DoubleBackground';
import {WizardElements} from 'components/Wizard';
import {useScreenInfo} from 'hooks/Hooks';
import BirthInput from 'screens/settings/profileEdit/BirthInput';
import {useToggleParentalControl} from 'screens/settings/profileWizard/WizardHooks';

import {BirthPageProps} from './WizardBirthPage';
import {WizardToggleSwitch} from './WizardToggleSwitch';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  embeddedContainer: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    paddingHorizontal: dimensions.margins.xxLarge
  },
  container: {
    flex: 1
  },
  label: {
    color: colors.settingsScreen.profile.wizard.label,
    marginVertical: dimensions.margins.medium
  },
  content: {
    alignItems: 'center'
  },
  textBoxText: {
    marginTop: dimensions.margins.medium,
    color: colors.settingsScreen.profile.wizard.sublabel
  },
  parentalToggle: {
    marginTop: dimensions.margins.xxxLarge
  },
  mobileTopChild: {
    justifyContent: isTablet ? 'flex-end' : 'center',
    alignItems: 'center'
  },
  mobileBottomChildWrapper: {
    alignItems: 'center'
  },
  mobileBottomChild: {
    flex: 1
  },
  bottomChildContainer: {
    alignItems: 'center'
  },
  mobileHeader: {
    alignSelf: 'stretch'
  },
  tracker: {
    marginTop: dimensions.margins.small
  }
}));

const overlap = dimensions.wizard.configScreen.doubleBackgroundOverlap + dimensions.buttons.large;

type EmbeddedContentProps = {
  style?: StyleProp<ViewStyle>;
  birthInput?: React.ReactNode;
  toggleSwitch?: React.ReactNode;
}
const EmbeddedContent: React.FC<EmbeddedContentProps> = ({
  style,
  toggleSwitch,
  birthInput
}) => {
  const {orientation} = useScreenInfo();
  const styles = stylesUpdater.getStyles();
  return (
    <View style={styles.embeddedContainer}>
      <View style={style}>
        {birthInput}
        {orientation.isPortrait && toggleSwitch}
      </View>
      {orientation.isLandscape && toggleSwitch}
    </View>
  );
};

const WizardBirthPageContent: React.FC<BirthPageProps & WizardElements> = props => {
  const {
    onBirthDateChanged,
    onParentalChanged,
    yearOfBirth,
    parentalControl: propsParentalControl,
    autoFocus = true,
    tracker,
    embedded,
    onPinChanged,
    pinState
  } = props;
  const {t} = useTranslation();
  const {size: {width: screenWidth}} = useScreenInfo();

  const {parentalControl, toggleParentalControl, renderPopup} = useToggleParentalControl({
    parentalControl: !!propsParentalControl,
    onParentalControlChanged: onParentalChanged,
    pinState,
    setPin: onPinChanged
  });

  const styles = stylesUpdater.getStyles();

  const contentStyle = useMemo(() => [
    styles.content,
    {width: Math.min(dimensions.inputs.width.settings, screenWidth - 2 * dimensions.margins.large)},
    props.contentStyle
  ], [styles.content, screenWidth, props.contentStyle]);

  const toggleSwitch = (
    <WizardToggleSwitch
      title={t('settings.parentalControl.parentalControl')}
      onPress={toggleParentalControl}
      style={styles.parentalToggle}
      value={parentalControl}
      message={embedded ? t('profileWizard.parentalControlDescription') : undefined}
      messageStyle={embedded ? styles.textBoxText : undefined}
    />
  );

  const birthInput = (
    <BirthInput
      onBirthDateChanged={onBirthDateChanged}
      yearOfBirth={yearOfBirth}
      embedded={embedded}
      autoFocus={autoFocus}
    />
  );

  if (isTablet && embedded) {
    return (
      <EmbeddedContent
        style={contentStyle}
        toggleSwitch={toggleSwitch}
        birthInput={birthInput}
      />
    );
  }

  return (
    <DoubleBackground
      topChild={(
        <View style={styles.tracker}>
          {tracker}
        </View>
      )}
      topStyle={styles.mobileTopChild}
      bottomChild={(
        <View style={styles.bottomChildContainer}>
          <View style={[contentStyle, props.contentStyle]}>
            {birthInput}
            {toggleSwitch}
            {renderPopup()}
          </View>
        </View>
      )}
      bottomStyle={styles.mobileBottomChild}
      overlap={overlap}
    />
  );
};

export default React.memo(WizardBirthPageContent);
