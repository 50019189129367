import {createStyles} from 'common-styles';
import React, {useCallback, useContext, useMemo} from 'react';
import {Animated, StyleSheet, View} from 'react-native';

import {Direction} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Channel, Event} from 'mw/api/Metadata';

import VerticalChannelsListPiccolo from 'components/channelsList/VerticalChannelsList.piccolo';
import DatePicker from 'components/DatePicker';
import SwipeTrack from 'components/gestures/SwipeTrack';
import {TabBarContext} from 'components/navigation/NavigationHelperTypes';
import {TuneRequest, TuneRequestState} from 'components/player/PlayerManager';
import OneChannelEpg from 'components/zapper/OneChannelEpg';
import {useNavigation, useScreenInfo} from 'hooks/Hooks';
import {getEpgDates} from 'screens/epg/EpgHelperFunctions';

import {getPlayerView} from './TvScreenHelperFunctions';
import {TuneParams, TvScreenComponentProps, TvScreenPlayerViewLocation} from './TvScreenHelperTypes';

const leftChannelSectionWidth = 135;
const leftSectionWidth = 505;
const dateFormat = 'dddd, MMM D';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  channelListContainer: {
    width: leftChannelSectionWidth,
    alignItems: 'center',
    backgroundColor: colors.tvScreen.foldableEpg.channelListBackground
  },
  datePickerContainer: {
    backgroundColor: colors.tvScreen.background
  },
  datePickerBackground: colors.tvScreen.datepicker.background,
  tabletOptionalContainer: {
    backgroundColor: colors.playerScreen.background
  }
}));

type TvScreenTabletSwipeWrapperProps = {
  leftMenuShowed: boolean;
  onSwipeGestureStart: () => void;
  onSwipeGestureEnd: (isCloseGesture: boolean) => void;
  onSwipeLeft: () => void;
  onSwipeFromEdge: () => void;
  playerLocation: TvScreenPlayerViewLocation;
  selectedDateIndex: number;
  setSelectedDateIndex: (index: number) => void;
  tuneEvent: (event: Event, additionalParams?: Partial<TuneParams>) => void;
  openEventDetail: (event: Event) => void;
  onTuneRequestStateChange: (state: TuneRequestState, tuneRequest: TuneRequest) => void;
  showEpg: () => void;
} & Pick<TvScreenComponentProps, 'channels' | 'currentChannel' | 'tune' | 'showPlayerOverlay' | 'tunedEvent'>;

const closeGestureThreshold = 50;
const TvScreenTabletSwipeWrapper: React.FC<TvScreenTabletSwipeWrapperProps> = ({
  children,
  leftMenuShowed,
  onSwipeGestureStart,
  onSwipeGestureEnd,
  onSwipeLeft,
  channels,
  onSwipeFromEdge,
  openEventDetail,
  playerLocation,
  selectedDateIndex,
  setSelectedDateIndex,
  tuneEvent,
  currentChannel,
  tune,
  onTuneRequestStateChange,
  showPlayerOverlay,
  tunedEvent,
  showEpg
}) => {
  const {size: screenSize} = useScreenInfo();
  const tabBarLayout = useContext(TabBarContext).layout;
  const navigation = useNavigation();
  const dates = useMemo(() => getEpgDates(), []);
  const datePickerItemWidthRatio = (2 / 5);

  const onChannelListsPress = useCallback((channel: Channel) => {
    tune({playerView: getPlayerView(playerLocation), media: channel, callback: onTuneRequestStateChange});
  }, [tune, playerLocation, onTuneRequestStateChange]);

  const styles = stylesUpdater.getStyles();
  return (
    <SwipeTrack
      swipeOrigin={leftMenuShowed ? Direction.Right : Direction.Left}
      onGestureStart={onSwipeGestureStart}
      onGestureEnd={onSwipeGestureEnd}
      onCloseGesture={leftMenuShowed ? onSwipeLeft : onSwipeFromEdge}
      closeOnAnchorTap={false}
      flyAwayDistance={leftSectionWidth}
      closeGestureThreshold={closeGestureThreshold}
      initialOriginDisplacement={leftMenuShowed ? leftSectionWidth : 0}
      onSwipeAnchorTap={leftMenuShowed ? undefined : showPlayerOverlay}
      becomeResponderOnTouchStart={false}
    >
      {swipeTrackProps => {
        const {positionDelta} = swipeTrackProps;
        const interpolateRange = [0, leftSectionWidth];
        return (
          <>
            <Animated.View
              {...swipeTrackProps.panResponder.panHandlers}
              style={[
                StyleSheet.absoluteFillObject,
                {transform: [
                  {translateX: positionDelta.interpolate({
                    inputRange: interpolateRange,
                    outputRange: interpolateRange,
                    extrapolateRight: 'clamp'
                  })}
                ]},
                {
                  width: Animated.subtract(
                    screenSize.width,
                    positionDelta.interpolate({
                      inputRange: interpolateRange,
                      outputRange: [0, leftSectionWidth],
                      extrapolateRight: 'clamp'
                    })
                  ),
                  bottom: positionDelta.interpolate({
                    inputRange: interpolateRange,
                    outputRange: [0, tabBarLayout.height]
                  })
                }
              ]}
            >
              {children}
            </Animated.View>
            <Animated.View
              style={{
                transform: [
                  {translateX: Animated.subtract(swipeTrackProps.positionDelta, leftSectionWidth)
                    .interpolate({
                      inputRange: [-leftSectionWidth, 0],
                      outputRange: [-leftSectionWidth, 0],
                      extrapolateRight: 'clamp'
                    })}
                ],
                width: leftSectionWidth,
                height: screenSize.height - tabBarLayout.height
              }}
            >
              <View style={{flexDirection: 'row', height: '100%'}}>
                <View style={styles.channelListContainer}>
                  {/* Recreating VerticalChannelsListPiccolo is very expensive so it's better to just inform it that is not visible to disable animations. */}
                  <VerticalChannelsListPiccolo
                    visible={leftMenuShowed}
                    channels={channels}
                    onPress={onChannelListsPress}
                    selectedChannelId={currentChannel?.id}
                  />
                </View>
                <View style={{width: leftSectionWidth - leftChannelSectionWidth, ...styles.datePickerContainer}}>
                  <DatePicker
                    dates={dates}
                    dateFormat={dateFormat}
                    currentDateIndex={selectedDateIndex}
                    onDateChangedHandler={setSelectedDateIndex}
                    width={leftSectionWidth - leftChannelSectionWidth}
                    itemWidthRatio={datePickerItemWidthRatio}
                    backgroundColor={styles.datePickerBackground}
                  />
                  {currentChannel && (
                    <OneChannelEpg
                      navigation={navigation}
                      startTime={dates[selectedDateIndex] || new Date()}
                      channel={currentChannel}
                      tuneEvent={tuneEvent}
                      openEventDetail={openEventDetail}
                      tunedEvent={tunedEvent}
                      showEpg={showEpg}
                      visible={leftMenuShowed}
                      tileType='small'
                    />
                  )}
                </View>
              </View>
            </Animated.View>
          </>
        );
      }}
    </SwipeTrack>
  );
};

export default TvScreenTabletSwipeWrapper;
