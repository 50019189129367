import {createStyles} from 'common-styles';
import moment from 'moment';
import React, {useCallback, useState, useRef, useEffect, useMemo} from 'react';
import {StyleProp, TextStyle, View, GestureResponderEvent, Animated} from 'react-native';

import {isTablet, getValue, isMobile, dimensions, isBigScreen, defaultThrottle} from 'common/constants';
import {interpolateToPercentage} from 'common/HelperFunctions';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {mw} from 'mw/MW';

import FloatingBubble, {FloatingBubbleType} from 'components/FloatingBubble';
import NitroxText from 'components/NitroxText';
import {useDisposableCallback, useThrottle} from 'hooks/Hooks';

export enum ProgressBarViewTheme {
  Default = 'Default',
  Chromecast = 'Chromecast'
}

const progressDotFocusedSize = isTablet ? 28 : 16;
const focusedTextMargin = dimensions.margins.medium;
const progressRangeIndicatorHeight = getValue({tablet: 20, mobile: 10, defaultValue: 13});
const progressRangeIndicatorWidth = 2;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column-reverse',
    height: dimensions.player.progressBar.default.expandedHeight,
    marginBottom: isBigScreen ? dimensions.margins.xxLarge : 0
  },
  row: {
    flexDirection: 'row'
  },
  topContainer: {
    alignItems: 'flex-end',
    flexDirection: 'row',
    zIndex: 2
  },
  textContainer: {
    width: '100%',
    height: getValue({tv: 73, mobile: 51, desktopBrowser: 73}),
    position: 'absolute'
  },
  textContainerStatic: {
    position: 'relative',
    height: 'auto',
    width: dimensions.player.progressBar.chromecast.textContainerStaticWidth,
    alignItems: 'flex-end',
    alignSelf: 'center'
  },
  text: {
    position: 'absolute',
    justifyContent: 'flex-start',
    alignItems: 'center',
    top: 0,
    bottom: 0
  },
  textFocused: {
    justifyContent: 'center',
    marginHorizontal: focusedTextMargin
  },
  textTypography: {
    color: colors.progressBar.default.text,
    top: 0
  },
  progressContainer: {
    flexDirection: 'row',
    height: dimensions.player.progressBar.default.expandedHeight,
    overflow: 'hidden'
  },
  progress: {
    alignSelf: 'center'
  },
  progressFocused: {
    height: dimensions.player.progressBar.default.expandedHeight
  },
  currentProgress: {
    backgroundColor: colors.progressBar.default.progress.current
  },
  currentProgressNotAvailable: {
    position: 'absolute',
    backgroundColor: colors.progressBar.default.progress.current,
    opacity: dimensions.opacity.medium
  },
  availableProgress: {
    backgroundColor: colors.progressBar.default.progress.available
  },
  notAvailableProgress: {
    backgroundColor: colors.progressBar.default.progress.remaining
  },
  currentProgressDot: {
    position: 'absolute',
    alignSelf: 'center'
  },
  currentProgressDotDefault: {
    backgroundColor: colors.progressBar.default.dot.background
  },
  currentProgressDotChromecast: {
    backgroundColor: colors.progressBar.chromecast.dot.background
  },
  currentProgressDotFocused: {
    height: progressDotFocusedSize,
    width: progressDotFocusedSize,
    borderRadius: progressDotFocusedSize / 2,
    marginLeft: -progressDotFocusedSize / 2,
    borderColor: colors.progressBar.default.dot.border,
    borderWidth: getValue({tablet: 4, mobile: 2, defaultValue: 4})
  },
  attachedToDot: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    top: 0,
    bottom: 0
  },
  floatingBubbleText: {
    color: colors.progressBar.default.bubble.text
  },
  indicator: {
    position: 'absolute',
    borderRightWidth: progressRangeIndicatorWidth,
    height: progressRangeIndicatorHeight
  },
  floatingBubbleBackground: colors.progressBar.default.bubble.background,
  rangeIndicatorColorBegin: colors.progressBar.default.bubble.background,
  rangeIndicatorColorEnd: colors.progressBar.default.progress.available
}));

type Props = {
  startTime?: Date | number;
  currentTime?: Date | number;
  endTime?: Date | number;
  changingProgress?: boolean;
  currentProgress: number;
  availableProgressStart: number;
  availableProgressEnd: number;
  labelsFormat?: string;
  labelsPlaceholder?: string;
  startTimeLabelVisible?: boolean;
  currentTimeLabelVisible?: boolean;
  currentTimeLabelAlignment?: 'left' | 'center' | 'right' | 'nextTo' | 'dot' | 'bubble';
  currentTimeSeparator?: string;
  endTimeLabelVisible?: boolean;
  isDraggableForward?: boolean;
  isDraggableBackward?: boolean;
  overlayVisible?: boolean;
  showAvailableProgressIndicators?: boolean;
  isExpandable?: boolean;
  theme?: ProgressBarViewTheme;
  currentPositionChanged?: (position: number) => void;
  // similar to flatlist onScroll - fires on every change (also during drag phase)
  onProgressChange?: (position: number) => void;
  onGestureStateChange?: (value: boolean) => void;
  onExpand?: (position: number) => void;
}

const ProgressBarView: React.FC<Props> = props => {
  const {
    changingProgress,
    currentPositionChanged,
    onProgressChange,
    overlayVisible,
    availableProgressEnd,
    availableProgressStart,
    labelsFormat,
    labelsPlaceholder = '--:--',
    startTimeLabelVisible = true,
    currentTimeLabelAlignment = 'center',
    currentTimeLabelVisible = false,
    currentTimeSeparator = ' | ',
    isDraggableBackward = false,
    isDraggableForward = false,
    endTimeLabelVisible = true,
    startTime,
    endTime,
    currentTime,
    showAvailableProgressIndicators,
    onGestureStateChange,
    onExpand,
    isExpandable = true,
    theme = ProgressBarViewTheme.Default
  } = props;
  const [progressContainerPageX, setProgressContainerPageX] = useState(0);
  const [progressContainerWidth, setProgressContainerWidth] = useState(0);
  const [startTimeWidth, setStartTimeWidth] = useState(0);
  const [endTimeWidth, setEndTimeWidth] = useState(0);
  const [currentTimeWidth, setCurrentTimeWidth] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const animatedCurrentProgress = useRef(new Animated.Value(0));
  const animatedCurrentProgressPX = useRef(new Animated.Value(0));
  const animatedAvailableProgress = useRef(new Animated.Value(0));
  const animatedNotAvailableProgressBegin = useRef(new Animated.Value(0));
  const animatedNotAvailableProgressEnd = useRef(new Animated.Value(0));
  const animatedAvailableProgressRangeEnd = useRef(new Animated.Value(0));
  const animatedDotProgress = useRef(new Animated.Value(0));
  const progressContainerRef = useRef<View | null>(null);
  const startTimeRef = useRef<View | null>(null);
  const endTimeRef = useRef<View | null>(null);
  const currentTimeRef = useRef<View | null>(null);
  const [isTouched, setIsTouched] = useState(false);
  const currentProgress = useRef<number>(0);
  const floatingBubbleRef = useRef<FloatingBubble>(null);

  useEffect(() => {
    !overlayVisible && setIsExpanded(false);
  }, [overlayVisible]);

  const setCurrentProgress = useCallback((progress: number, saveValue = true) => {
    if (saveValue) {
      currentProgress.current = progress;
    }
    // camp this value in case user tries to jump beyond available progress,
    const calculatedAvailableProgress = Math.max(Math.min(availableProgressEnd - progress, 1), 0);
    Animated.parallel([
      Animated.timing(animatedNotAvailableProgressBegin.current, {
        toValue: availableProgressStart,
        duration: 0
      }),
      Animated.timing(animatedCurrentProgress.current, {
        toValue: progress - availableProgressStart,
        duration: 0
      }),
      Animated.timing(animatedCurrentProgressPX.current, {
        toValue: progress * progressContainerWidth,
        duration: 0
      }),
      Animated.timing(animatedDotProgress.current, {
        toValue: progress,
        duration: 0
      }),
      Animated.timing(animatedAvailableProgress.current, {
        toValue: calculatedAvailableProgress,
        duration: 0
      }),
      Animated.timing(animatedNotAvailableProgressEnd.current, {
        toValue: 1 - progress - calculatedAvailableProgress,
        duration: 0
      }),
      Animated.timing(animatedAvailableProgressRangeEnd.current, {
        toValue: Math.min(progress + calculatedAvailableProgress, 1) * progressContainerWidth - progressRangeIndicatorWidth,
        duration: 0
      })
    ]).start();
    progressContainerWidth && floatingBubbleRef.current?.setX(progress * progressContainerWidth - Math.floor(progressRangeIndicatorWidth / 2));
  }, [availableProgressEnd, availableProgressStart, progressContainerWidth]);

  useEffect(() => {
    !isTouched && !changingProgress && setCurrentProgress(props.currentProgress);
  }, [props.currentProgress, changingProgress, isTouched, setCurrentProgress]);

  const onContainerMeasure = useDisposableCallback((x, y, width, height, pageX, pageY) => {
    setProgressContainerPageX(pageX);
    setProgressContainerWidth(width);
  });

  const onStartTimeMeasure = useDisposableCallback((x, y, width, height, pageX, pageY) => {
    setStartTimeWidth(width);
  });

  const onEndTimeMeasure = useDisposableCallback((x, y, width, height, pageX, pageY) => {
    setEndTimeWidth(width);
  });

  const onCurrentTimeMeasure = useDisposableCallback((x, y, width, height, pageX, pageY) => {
    setCurrentTimeWidth(width);
  });

  // We need position in global coordinate system of the container in order to compute current position in the gesture hanlders
  // We attach to the progress container here is order to include any margins or offset defined in the styles.
  const onProgressContainerLayout = useCallback(() => {
    if (!progressContainerRef.current) {
      return;
    }
    progressContainerRef.current.measure(onContainerMeasure);
  }, [onContainerMeasure]);

  const onStartTimeLayout = useCallback(() => {
    if (!startTimeRef.current) {
      return;
    }
    startTimeRef.current.measure(onStartTimeMeasure);
  }, [onStartTimeMeasure]);

  const onEndTimeLayout = useCallback(() => {
    if (!endTimeRef.current) {
      return;
    }
    endTimeRef.current.measure(onEndTimeMeasure);
  }, [onEndTimeMeasure]);

  const onCurrentTimeLayout = useCallback(() => {
    if (!currentTimeRef.current) {
      return;
    }
    currentTimeRef.current.measure(onCurrentTimeMeasure);
  }, [onCurrentTimeMeasure]);

  const styles = stylesUpdater.getStyles();

  const themeStyles = useMemo(() => {
    const progressDotSize = theme === ProgressBarViewTheme.Chromecast ? 20 : 8;
    const currentProgressDotStyles = {
      width: progressDotSize,
      height: progressDotSize,
      borderRadius: progressDotSize / 2,
      marginLeft: -progressDotSize / 2
    };

    switch (theme) {
      case ProgressBarViewTheme.Chromecast:
        return {
          container: [styles.container, styles.row],
          progressContainer: [styles.progressContainer, {flex: 1}],
          textContainer: [styles.textContainer, styles.textContainerStatic],
          progress: [styles.progress, {height: dimensions.player.progressBar.chromecast.progressHeight}],
          currentProgressDot: [styles.currentProgressDot, currentProgressDotStyles, styles.currentProgressDotChromecast]
        };
      default:
        return {
          container: styles.container,
          progressContainer: styles.progressContainer,
          textContainer: styles.textContainer,
          progress: [styles.progress, {height: dimensions.player.progressBar.default.progressHeight}],
          currentProgressDot: [styles.currentProgressDot, currentProgressDotStyles, styles.currentProgressDotDefault]
        };
    }
  }, [styles.container, styles.currentProgressDot, styles.currentProgressDotChromecast, styles.currentProgressDotDefault, styles.progress, styles.progressContainer, styles.row, styles.textContainer, styles.textContainerStatic, theme]);

  // create time labels
  const labelStyle: StyleProp<TextStyle> = [styles.text, isExpanded && {justifyContent: 'center'}];
  const labelElements: React.ReactElement[] = [];
  const formatTimeLabel = useCallback((time?: Date | number): string => {
    const momentDate = typeof time === 'number' ? moment.utc(time) : typeof time === 'object' ? moment(time) : undefined;
    return (momentDate ? momentDate.format(labelsFormat || mw.configuration.timeFormat) : labelsPlaceholder) || '';
  }, [labelsFormat, labelsPlaceholder]);

  let startTimeLabel = startTimeLabelVisible ? formatTimeLabel(startTime) : '';
  if (currentTimeLabelVisible && currentTimeLabelAlignment === 'left') {
    startTimeLabel += currentTimeSeparator + formatTimeLabel(currentTime);
  }
  if (startTimeLabel) {
    labelElements.push(
      <View ref={startTimeRef} style={labelStyle.concat([{left: 0}])} key='start' onLayout={onStartTimeLayout}>
        <NitroxText textType='callout' key='startTime' style={[styles.textTypography, isExpanded && styles.textFocused]} testID='start_time'>
          {startTimeLabel}
        </NitroxText>
      </View>
    );
  }

  if (currentTimeLabelVisible && currentTimeLabelAlignment === 'center') {
    labelElements.push(
      <View style={labelStyle.concat([{alignSelf: 'center'}])} key='current'>
        <NitroxText textType='callout' key='currentTime' style={[styles.textTypography, isExpanded && styles.textFocused]} testID='current_time'>
          {formatTimeLabel(currentTime)}
        </NitroxText>
      </View>
    );
  }

  let endTimeLabel = endTimeLabelVisible ? formatTimeLabel(endTime) : '';
  if (currentTimeLabelVisible && currentTimeLabelAlignment === 'right') {
    endTimeLabel = formatTimeLabel(currentTime) + currentTimeSeparator + endTimeLabel;
  }
  if (endTimeLabel) {
    labelElements.push(
      <View ref={endTimeRef} style={labelStyle.concat({right: 0})} key='end' onLayout={onEndTimeLayout}>
        <NitroxText textType='callout' key='endTime' style={[styles.textTypography, isExpanded && styles.textFocused]} testID='end_time'>
          {endTimeLabel}
        </NitroxText>
      </View>
    );
  }

  if (currentTimeLabelVisible && currentTimeLabelAlignment === 'nextTo') {
    labelElements.push(
      <View key='current'>
        <NitroxText textType='callout' key='currentTime' style={styles.textTypography} testID='current_time'>
          {formatTimeLabel(currentTime)}
        </NitroxText>
      </View>
    );
  }

  const computeCurrentPosition = useCallback((pageX: number): number => {
    if (progressContainerWidth === 0) { // wait for the progress container's measurements
      return 0;
    }
    return Math.max(Math.min((pageX - progressContainerPageX) / progressContainerWidth, availableProgressEnd), availableProgressStart);
  }, [progressContainerWidth, progressContainerPageX, availableProgressEnd, availableProgressStart]);

  const onPositionChange = useThrottle((currentPosition: number) => {
    onProgressChange && onProgressChange(currentPosition);
  }, defaultThrottle);

  const onResponderMove = useCallback((event: GestureResponderEvent): void => {
    if (!isDraggableForward && !isDraggableBackward) {
      return;
    }
    const newPosition = computeCurrentPosition(event.nativeEvent.pageX);
    const movingForward = newPosition > currentProgress.current;
    if (movingForward && !isDraggableForward) {
      return;
    }
    if (!movingForward && !isDraggableBackward) {
      return;
    }
    setCurrentProgress(newPosition, false);
    onPositionChange(newPosition);
  }, [isDraggableForward, isDraggableBackward, computeCurrentPosition, setCurrentProgress, onPositionChange]);

  const onResponderStart = useCallback((event: GestureResponderEvent) => {
    if (!isDraggableForward && !isDraggableBackward) {
      return;
    }
    onGestureStateChange?.(true);
    setIsTouched(true);
    if (isExpandable) {
      setIsExpanded(true);
      onExpand?.(computeCurrentPosition(event.nativeEvent.pageX));
    }
    onResponderMove(event);
  }, [isDraggableForward, isDraggableBackward, onGestureStateChange, isExpandable, onExpand, computeCurrentPosition, onResponderMove]);

  const onResponderRelease = useCallback((event: GestureResponderEvent): void => {
    setIsTouched(false);
    onGestureStateChange?.(false);
    if (!isDraggableForward && !isDraggableBackward) {
      return;
    }
    const newPosition = computeCurrentPosition(event.nativeEvent.pageX);
    const movingForward = newPosition > currentProgress.current;
    if (movingForward && !isDraggableForward) {
      return;
    }
    if (!movingForward && !isDraggableBackward) {
      return;
    }
    setCurrentProgress(newPosition);
    onProgressChange && onProgressChange(newPosition);
    // execute this callback only once after all gesture activities are done
    if (currentPositionChanged) {
      currentPositionChanged(newPosition);
    }
  }, [computeCurrentPosition, setCurrentProgress, currentPositionChanged, onProgressChange, isDraggableForward, isDraggableBackward, onGestureStateChange]);

  const labelAttachedToDotTranslation = useMemo(() => {
    if (progressContainerWidth === 0) {
      return null;
    }

    const textShiftToTheLeft = currentTimeWidth + (progressDotFocusedSize);
    const textShiftToTheRight = progressDotFocusedSize;
    const textLeftShift = startTimeWidth + focusedTextMargin;
    const textRightShift = endTimeWidth + focusedTextMargin;

    return {
      left: animatedCurrentProgressPX.current.interpolate(
        {
          inputRange: [
            0,
            textLeftShift,
            progressContainerWidth / 2 - 1,
            progressContainerWidth / 2,
            // make sure that values are not decreasing, it would cause a crash
            Math.max(progressContainerWidth - textRightShift, progressContainerWidth / 2 + 1),
            progressContainerWidth
          ],
          outputRange: [
            textLeftShift + textShiftToTheRight,
            textLeftShift + textShiftToTheRight,
            progressContainerWidth / 2 + textShiftToTheRight,
            progressContainerWidth / 2 - textShiftToTheLeft,
            progressContainerWidth - textShiftToTheLeft - textRightShift,
            progressContainerWidth - textShiftToTheLeft - textRightShift
          ]
        }
      )
    };
  }, [currentTimeWidth, endTimeWidth, progressContainerWidth, startTimeWidth]);

  const renderLabelAttachedToDot = useCallback(() => {
    if (isMobile && props.currentProgress !== 0 && isExpanded && currentTimeLabelVisible && currentTimeLabelAlignment === 'dot' && labelAttachedToDotTranslation !== null) {
      return (
        <Animated.View style={[styles.attachedToDot, labelAttachedToDotTranslation]}>
          <View ref={currentTimeRef} onLayout={onCurrentTimeLayout}>
            <NitroxText textType='callout' key='currentTime' style={[styles.textTypography, isExpanded && styles.textFocused]}>
              {formatTimeLabel(currentTime)}
            </NitroxText>
          </View>
        </Animated.View>
      );
    }
    return null;
  }, [props.currentProgress, currentTimeLabelVisible, currentTimeLabelAlignment, currentTime, isExpanded, labelAttachedToDotTranslation, styles.attachedToDot, styles.textTypography, styles.textFocused, onCurrentTimeLayout, formatTimeLabel]);

  const renderBubbleLabel = useCallback(() => {
    if ((isBigScreen || (isMobile && isExpanded)) && props.currentProgress !== 0 && currentTimeLabelVisible && currentTimeLabelAlignment === 'bubble') {
      return (
        <FloatingBubble
          ref={floatingBubbleRef}
          label={formatTimeLabel(currentTime)}
          textStyle={styles.floatingBubbleText}
          type={FloatingBubbleType.Primary}
          backgroundColor={styles.floatingBubbleBackground}
          pointerLineHeight={progressRangeIndicatorHeight}
          pointerLineWidth={progressRangeIndicatorWidth + 1}
        />
      );
    }
  }, [props.currentProgress, currentTimeLabelVisible, currentTimeLabelAlignment, currentTime, isExpanded, formatTimeLabel, styles.floatingBubbleBackground, styles.floatingBubbleText]);

  return (
    <>
      <View style={styles.topContainer}>
        {isBigScreen && showAvailableProgressIndicators && progressContainerWidth > 0 && (
          <>
            <Animated.View
              style={[styles.indicator, {
                left: interpolateToPercentage(animatedNotAvailableProgressBegin.current),
                borderColor: styles.rangeIndicatorColorBegin
              }]}
            />
            <Animated.View
              style={[styles.indicator, {
                left: animatedAvailableProgressRangeEnd.current,
                borderColor: styles.rangeIndicatorColorEnd
              }]}
            />
          </>
        )}
        {renderBubbleLabel()}
      </View>
      <View
        style={themeStyles.container}
        onStartShouldSetResponder={() => true}
        onMoveShouldSetResponder={() => true}
        onStartShouldSetResponderCapture={() => true}
        onMoveShouldSetResponderCapture={() => true}
        onResponderMove={onResponderMove}
        onResponderStart={onResponderStart}
        onResponderRelease={onResponderRelease}
        onResponderTerminate={onResponderRelease}
        onResponderTerminationRequest={() => false}
        testID='progress_bar'
      >
        <View
          ref={progressContainerRef}
          style={[themeStyles.progressContainer, isExpanded && styles.progressFocused]}
          onLayout={onProgressContainerLayout}
        >
          <Animated.View style={[themeStyles.progress, styles.notAvailableProgress, isExpanded && styles.progressFocused, {width: interpolateToPercentage(animatedNotAvailableProgressBegin.current)}]} />
          <Animated.View testID='unavailable_progress' style={[themeStyles.progress, styles.currentProgressNotAvailable, isExpanded && styles.progressFocused, {width: interpolateToPercentage(animatedNotAvailableProgressBegin.current)}]} />
          <Animated.View testID='current_progress' style={[themeStyles.progress, styles.currentProgress, isExpanded && styles.progressFocused, {width: interpolateToPercentage(animatedCurrentProgress.current)}]} />
          <Animated.View style={[themeStyles.progress, styles.availableProgress, isExpanded && styles.progressFocused, {width: interpolateToPercentage(animatedAvailableProgress.current)}]} />
          <Animated.View style={[themeStyles.progress, styles.notAvailableProgress, isExpanded && styles.progressFocused, {width: interpolateToPercentage(animatedNotAvailableProgressEnd.current)}]} />
          {isMobile && props.currentProgress !== 0 && availableProgressEnd !== availableProgressStart && (
            <Animated.View
              style={[
                themeStyles.currentProgressDot,
                isExpanded && styles.currentProgressDotFocused,
                {left: interpolateToPercentage(animatedDotProgress.current)}]}
            />
          )}
          {renderLabelAttachedToDot()}
        </View>
        <View style={themeStyles.textContainer}>
          {labelElements}
        </View>
      </View>
    </>
  );
};

export default React.memo(ProgressBarView);
