import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {StyleProp, ViewStyle, View, TextStyle} from 'react-native';

import {isPhone, dimensions, isBigScreen, isMobile} from 'common/constants';

import ConditionalWrapper from 'components/ConditionalWrapper';
import FocusParent from 'components/FocusParent';
import NitroxText from 'components/NitroxText';
import Toggle from 'components/Toggle';
import {useFunction} from 'hooks/Hooks';
import SettingsLabel from 'screens/settings/SettingsLabel';

const styles = createStyles({
  container: {
    alignSelf: 'stretch'
  },
  disabledContainer: {
    opacity: dimensions.opacity.xlow
  },
  toggleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  toggle: {
    alignItems: 'flex-start'
  },
  label: {
    alignItems: 'flex-start',
    marginRight: isBigScreen ? dimensions.margins.xxLarge : dimensions.margins.medium
  },
  messageContainer: {
    marginTop: dimensions.margins.large
  }
});

type Props = {
  title: string;
  message?: string;
  value: boolean;
  labelStyle?: StyleProp<TextStyle>;
  messageStyle?: StyleProp<TextStyle>;
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
}
export const WizardToggleSwitch: React.FC<Props> = ({
  title,
  value,
  onPress: propsOnPress,
  style,
  labelStyle,
  message,
  messageStyle,
  disabled
}) => {
  const containerStyle = useMemo(() => [
    styles.container,
    style,
    disabled && styles.disabledContainer
  ], [style, disabled]);

  const onPress = useFunction(() => {
    !disabled && propsOnPress();
  });

  return (
    <ConditionalWrapper
      condition={isBigScreen}
      wrapper={children => (
        <FocusParent>
          {children}
        </FocusParent>
      )}
    >
      <View style={containerStyle}>
        <View style={styles.toggleContainer}>
          <View style={[styles.label, labelStyle]}>
            <SettingsLabel label={title} description={!isMobile ? message : ''} />
          </View>
          <View style={styles.toggle}>
            <Toggle
              value={value}
              onPress={onPress}
              disabled={disabled}
            />
          </View>
        </View>
        {isPhone && (
          <View style={styles.messageContainer}>
            <NitroxText textType='settings-description' style={messageStyle}>
              {message}
            </NitroxText>
          </View>
        )}
      </View>
    </ConditionalWrapper>
  );
};
