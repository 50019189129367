import {createStyles} from 'common-styles';
import React from 'react';
import {View} from 'react-native';

import {isMobile} from 'common/constants';

import {FAR_FAR_AWAY} from 'components/navigation/ResourceSavingScene';

const styles = createStyles({
  hidden: {
    position: 'absolute',
    left: FAR_FAR_AWAY,
    top: FAR_FAR_AWAY,
    opacity: 0
  },
  visible: {
    flex: 1
  }
});

type ViewPagerProps = {
  currentPageIndex?: number;
};

const ViewPager: React.FC<ViewPagerProps> = props => {
  const {children, currentPageIndex = 0} = props;

  return isMobile ? (
    <View style={styles.visible}>
      {React.Children.map(children, (child, index) => (
        <View style={index === currentPageIndex ? styles.visible : styles.hidden}>
          {child}
        </View>
      ))}
    </View>
  ) : (
    <View style={styles.visible}>
      {React.Children.toArray(children)[currentPageIndex]}
    </View>
  );
};

export default ViewPager;
