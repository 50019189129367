import i18next from 'i18next';

import {humanToCamelCase} from 'common/HelperFunctions';
import {Log} from 'common/Log';

const TAG = 'ParentalControlHelpers';

export enum PCTranslationsType {
  Settings = 'settings',
  Overlay = 'overlay'
}

export function getPCAuthorityDescriptionTranslationKey(authority: string, type: PCTranslationsType): string {
  const authorityKey = humanToCamelCase(authority);
  return authorityKey
    ? `settings.parentalControl.authorityDescriptions.${type}.${authorityKey}`
    : '';
}

export function getPCAuthorityDescriptionTranslation(t: i18next.TFunction, authority: string, type: PCTranslationsType): string {
  const descriptionKey = getPCAuthorityDescriptionTranslationKey(authority, type);
  const description = t(descriptionKey);
  if (description === descriptionKey) {
    Log.debug(TAG, `Using generic authority description translation for ${authority} authority and ${type} pc type`);
    return t(`settings.parentalControl.authorityDescription.${type}`, {authority});
  }
  return description;
}

export function getPCRatingDescriptionTranslationKey(authority: string, rating: string): string {
  const authorityKey = humanToCamelCase(authority);
  const ratingKey = rating.toLowerCase();
  return authorityKey && ratingKey
    ? `settings.parentalControl.ratingDescriptions.${authorityKey}.${ratingKey}`
    : '';
}

export function getPCRatingDescriptionTranslation(t: i18next.TFunction, authority: string, rating: string): string {
  const descriptionKey = getPCRatingDescriptionTranslationKey(authority, rating);
  const description = t(descriptionKey);
  if (description === descriptionKey) {
    Log.debug(TAG, `Failed to find rating description translation for ${authority} authority and ${rating} rating`);
    return '';
  }
  return description;
}
