import {createStyles} from 'common-styles';
import React, {useMemo, useRef, useCallback, useContext, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {View, StyleSheet, ViewStyle, StyleProp} from 'react-native';
import {ScreenContainer} from 'react-native-screens';
import {ScreenProps, NavigationDescriptor, NavigationInjectedProps, NavigationScreenComponent, NavigationParams, NavigationRoute, NavigationScreenProp, NavigationLeafRoute, NavigationContext} from 'react-navigation';

import {delay} from 'common/Async';
import {menuFocusParentOffset, mainMenuWidth, dimensions, isBigScreen, AppRoutes} from 'common/constants';
import {Hashmap} from 'common/HelperTypes';
import {Log} from 'common/Log';
import TestContext from 'common/TestContext';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Styling} from 'mw/api/Metadata';
import {NativeNotificationEvent, NativeNotification, newStylingNotificationId, SystemEventType} from 'mw/api/System';
import {CMSEvent, CustomMenuItemRoute} from 'mw/cms/CMS';
import {Menu} from 'mw/cms/Menu';
import {mw} from 'mw/MW';

import {If} from 'components/Conditionals';
import FocusParent, {useFocusParent} from 'components/FocusParent';
import {IconType} from 'components/Icon';
import {ModalVisibilityReporter} from 'components/Modal';
import NewStylingPopup from 'components/NewStylingPopup';
import NotificationsPanel from 'components/notifications/NotificationsPanel';
import Popup, {PopupAction} from 'components/Popup';
import ProfileSelect from 'components/ProfileSelect';
import {useFunction, useChangeEffect, useDisposable, useEventListener, useDisposableCallback, useForceUpdate} from 'hooks/Hooks';

import {useShouldRenderMenu, getRouteInitialParams} from './NavigationHelperFunctions';
import {STBMenuState, STBMenuContextType, STBMenuContext} from './NavigationHelperTypes';
import {ResourceSavingScene} from './ResourceSavingScene';
import STBMenu, {STBMenuProps} from './STBMenu';

const TAG = 'STBNavigationView';

const styles = createStyles({
  navigationViewContainer: {
    ...StyleSheet.absoluteFillObject,
    top: -menuFocusParentOffset
  },
  menuParent: {
    position: 'absolute',
    width: '100%',
    height: dimensions.mainMenu.height
  },
  // Menu parent shouldn't overlap with screen when hidden 
  menuParentHidden: {
    top: -dimensions.mainMenu.height
  },
  notificationPanelView: {
    flex: 1,
    marginLeft: mainMenuWidth.semiCollapsed
  }
});

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: colors.mainMenu.notificationsPanel.backgroundOverlay
  },
  activeScreenContainer: {
    ...StyleSheet.absoluteFillObject,
    top: menuFocusParentOffset
  }
}));

const noNotificationsPopupActions = [PopupAction.POSITIVE];

type STBNavigationViewProps = {
  navigationConfig: {menu: Menu; stbMenuProps: Hashmap<STBMenuProps>, customItemsHandlers: Record<CustomMenuItemRoute, () => void>};
  screenProps?: ScreenProps;
} & NavigationInjectedProps;

type RouteProps = {
  descriptor: NavigationDescriptor<NavigationParams>;
  screenProps?: ScreenProps;
  isFocused: boolean;
};

const Route: React.FC<RouteProps> = ({
  descriptor,
  screenProps,
  isFocused
}) => {
  return (
    <NavigationContext.Provider value={descriptor.navigation}>
      <ResourceSavingScene
        debugName={descriptor.state?.routeName}
        style={StyleSheet.absoluteFill}
        isVisible={isFocused}
        enterStrategy='topLeft'
        screenProps={screenProps}
        ChildComponent={descriptor.getComponent() as NavigationScreenComponent}
      />
    </NavigationContext.Provider>
  );
};

type ScreensContainerProps = {
  style: StyleProp<ViewStyle>;
  routes: NavigationRoute[];
  screenProps?: ScreenProps;
  currentScreen: number;
};

type Descriptors = {
  [key: string]: NavigationDescriptor<NavigationParams>
};

const DescriptorsContext = React.createContext<Descriptors>({});

const ScreensContainer: React.FC<ScreensContainerProps> = ({
  style,
  routes,
  screenProps,
  currentScreen
}) => {
  const {forceUpdate} = useForceUpdate();
  /* Loaded screens have to be stored in ref,
    because we cannot update it async in useEffect,
    as it will cause problems with listening to screens focus events. */
  const loadedScreens = useRef<number[]>([]);
  if (!loadedScreens.current.includes(currentScreen)) {
    loadedScreens.current.push(currentScreen);
  }

  const onLowMemoryDetected = useCallback(() => {
    if (loadedScreens.current.length === 1) {
      Log.warn(TAG, 'Low memory detected: no screens to clear');
      return;
    }
    Log.warn(TAG, 'Low memory detected: clearing cached screens');
    loadedScreens.current = [currentScreen];
    forceUpdate();
  }, [currentScreen, forceUpdate]);

  useEventListener(NativeNotificationEvent.lowMemoryStateDetected, onLowMemoryDetected, mw.system);

  return (
    <DescriptorsContext.Consumer>
      {descriptors => (
        <ScreenContainer style={style}>
          {routes.map((route, index) =>
            loadedScreens.current.includes(index)
              ? (
                <Route
                  key={route.key}
                  descriptor={descriptors[route.key]}
                  screenProps={screenProps}
                  isFocused={index === currentScreen}
                />
              )
              : null
          )}
        </ScreenContainer>
      )}
    </DescriptorsContext.Consumer>
  );
};

const testIdContext = {
  Modal: 'modal_notifications'
};

const STBNavigationView: React.FC<Pick<STBNavigationViewProps, 'screenProps' | 'navigationConfig'> & {
  routes: NavigationRoute[];
  navigationIndex: number;
  navigate: NavigationScreenProp<unknown>['navigate']
}> = ({
  routes,
  navigate,
  navigationIndex,
  navigationConfig,
  screenProps
}) => {
  const {t} = useTranslation();
  const [onMenuParentReady, focusMenu] = useFocusParent();
  const [menuState, setMenuState] = useState(STBMenuState.Hidden);
  const [notificationPanelVisible, setNotificationPanelVisible] = useState(false);
  const [newStylingPopupVisible, setNewStylingPopupVisible] = useState(false);
  const [noNotificationsPopupVisible, setNoNotificationsPopupVisible] = useState(false);
  const [profileSelectionVisible, setProfileSelectionVisible] = useState(false);
  const [notifications, setNotifications] = useState<NativeNotification[]>([]);
  const [newStyling, setNewStyling] = useState<Styling | null>(null);
  const notificationsCount = notifications.length + +!!newStyling;
  const [visibleModals, setVisibleModals] = useState(0);

  const newStylingNotification: NativeNotification = useMemo(() => ({
    id: newStylingNotificationId,
    title: '',
    text: t('newStylingPopup.text'),
    icon: IconType.NewStyle,
    isSeen: false,
    isDismissable: false,
    isClickable: true
  }), [t]);

  const expandMenuOnFocus = useRef(false);

  const loadStorageNotifications = useDisposable(mw.system.loadStorageNotifications.bind(mw.system));

  const refreshNotifications = useCallback(async (notifications: NativeNotification[]) => {
    const storageNotifications = await loadStorageNotifications() || [];
    const currentStorageNotifications = storageNotifications.filter((storageNotification: string) => {
      return notifications.some((notification) => notification.id === storageNotification) || storageNotification === newStylingNotificationId;
    });
    mw.system.saveStorageNotifications(currentStorageNotifications);
    const flaggedNotifications = notifications.map((notification) => {
      return {...notification, isSeen: storageNotifications.includes(notification.id)};
    });
    setNotifications(flaggedNotifications || []);
  }, [loadStorageNotifications]);

  const getNativeNotifications = useDisposable(() => mw.system.getNativeNotifications());

  useEffect(() => {
    async function getNotifications() {
      const notifications = await getNativeNotifications() || [];
      refreshNotifications(newStyling ? [newStylingNotification, ...notifications] : notifications);
    }
    mw.configuration.isLauncher && getNotifications();
  }, [getNativeNotifications, refreshNotifications, newStyling, newStylingNotification]);

  const onNativeNotificationsListChanged = (notifications: NativeNotification[]) => {
    refreshNotifications(notifications);
  };

  const navigateToScreen = (route: NavigationRoute<NavigationParams>) => {
    Log.info(TAG, `Menu item ${route.routeName} chosen`);
    if (navigationConfig.stbMenuProps[route.routeName].isDisabled) {
      Log.info(TAG, `Menu item ${route.routeName} is disabled`);
      return;
    }
    const subRoute = route.routes[0];
    const params = getRouteInitialParams(subRoute.routeName);
    subRoute.params = {...subRoute.params, ...params};
    if (route === routes[navigationIndex]) {
      focusScreen();
    } else {
      navigate(route);
    }
  };

  const handleMenuIntent = (event: SystemEventType) => {
    if ('screenName' in event) {
      Log.info(TAG, 'handleMenuIntent:', event.screenName);
      const route = routes.find(route => event.screenName === route.routes[0].routeName);
      if (route) {
        navigateToScreen(route);
      } else {
        Log.warn(TAG, `Screen ${event.screenName} not found among available routes`);
      }
    } else {
      Log.warn(TAG, `Not recognize openScreen event: ${event}`);
    }
  };

  useEventListener(NativeNotificationEvent.nativeNotificationsListChanged, onNativeNotificationsListChanged, mw.system);

  useEventListener(NativeNotificationEvent.openScreen, handleMenuIntent, mw.system);

  const onNewStylingAvailable = useDisposableCallback((styling: Styling) => setNewStyling(styling), []);
  useEventListener(CMSEvent.newStyling, onNewStylingAvailable, mw.cms);

  const onNewStylingApplied = useDisposableCallback(() => setNewStyling(null), []);
  useEventListener(CMSEvent.newStylingApplied, onNewStylingApplied, mw.cms);

  const onFocusMove = useCallback(() => {
    expandMenuOnFocus.current = true;
  }, []);

  const onFocusEscapeMenu = useCallback(() => {
    if (menuState === STBMenuState.CoveredAutoHide || menuState === STBMenuState.CoveredAutoHideWithTip) {
      setMenuState(STBMenuState.Hidden);
    }
  }, [menuState]);

  const onNewStylingPopupVisible = useCallback((visible: boolean) => setNewStylingPopupVisible(visible), []);
  const onNoNotificationsPopupVisible = useCallback((visible: boolean) => setNoNotificationsPopupVisible(visible), []);
  const onNewStylingPopupClose = useCallback(() => setNewStylingPopupVisible(false), []);
  const onNoNotificationsPopupClose = useCallback(() => setNoNotificationsPopupVisible(false), []);

  const nestedNavigatorsIndexes = routes.map(r => r.index);

  useChangeEffect(() => {
    expandMenuOnFocus.current = false;
  }, [navigationIndex, ...nestedNavigatorsIndexes]);

  const focusMenuHandler = useCallback(() => {
    if (menuState === STBMenuState.Hidden) {
      setMenuState(STBMenuState.CoveredAutoHide);
    }
    setTimeout(() => focusMenu(), 0);
  }, [focusMenu, menuState]);

  const anyModalVisible = visibleModals > 0;
  const menuContextValue: STBMenuContextType = useMemo(() => ({
    // TODO:  CL-7404
    hasFocus: routes[navigationIndex].key === 'zapper_live-tv' && menuState !== STBMenuState.Hidden,
    focusMenu: focusMenuHandler,
    setMenuState,
    hasVisibleModal: anyModalVisible
  }), [focusMenuHandler, anyModalVisible, navigationIndex, menuState, routes]);

  useChangeEffect(() => {
    if (!notificationPanelVisible) {
      const allSeenNotifications = notifications.map((notification) => {
        return {...notification, isSeen: true};
      });
      setNotifications(allSeenNotifications);
      mw.system.markNotificationsAsSeen(notifications);
    }
  }, [notificationPanelVisible], [notifications, refreshNotifications]);

  const [onScreenFocusParentReady, focusScreen] = useFocusParent();

  const dynamicStyles = dynamicStylesUpdater.getStyles();

  const onModalVisibilityChange = useCallback((on: boolean) => {
    const delta = on ? 1 : -1;
    setVisibleModals(count => count + delta);
  }, []);

  const onProfileSelectionClose = useCallback(() => {
    setProfileSelectionVisible(false);
    focusMenu();
  }, [focusMenu]);

  const dynamicScreenContainer: ViewStyle = useMemo(() => ({
    ...dynamicStyles.activeScreenContainer,
    marginTop: menuState === STBMenuState.Above ? dimensions.mainMenu.height : 0
  }), [dynamicStyles.activeScreenContainer, menuState]);

  const onAddNewProfilePress = useCallback(async () => {
    navigate(AppRoutes.CreateProfileWizard);

    // TODO CL-7538 Implement proper solution to fix races between focus restoration (due to unmount) and focus initialization (e.g. via 'hasTvPreferredFocus')
    await delay(0);
    setProfileSelectionVisible(false);
  }, [navigate]);

  return (
    <STBMenuContext.Provider value={menuContextValue}>
      <ModalVisibilityReporter onModalVisibilityChange={onModalVisibilityChange}>
        <FocusParent
          trapFocus
          style={StyleSheet.absoluteFill}
          debugName='ROOT'
          onInternalFocusUpdate={onFocusMove}
          enterStrategy={'byPriority'}
        >
          <View style={styles.navigationViewContainer}>
            <FocusParent
              debugName='ScreensContainer'
              onReady={onScreenFocusParentReady}
              style={dynamicScreenContainer}
              focusPriority={1}
            >
              <ScreensContainer
                style={StyleSheet.absoluteFill}
                routes={routes}
                screenProps={screenProps}
                currentScreen={navigationIndex}
              />
            </FocusParent>
            {useShouldRenderMenu() && (
              <FocusParent
                style={[styles.menuParent, menuState === STBMenuState.Hidden && styles.menuParentHidden]}
                enterStrategy='byPriority'
                debugName='STBMenu'
                onFocusEscape={onFocusEscapeMenu}
                onReady={onMenuParentReady}
              >
                <STBMenu
                  routes={routes}
                  navigationIndex={navigationIndex}
                  displayState={menuState}
                  onPanelVisible={setNotificationPanelVisible}
                  menuProps={navigationConfig.stbMenuProps}
                  notificationsCount={notificationsCount}
                  newStylingAvailable={!!newStyling}
                  onNewStylingPopupVisible={onNewStylingPopupVisible}
                  onNoNotificationsPopupVisible={onNoNotificationsPopupVisible}
                  onProfileSelectionVisible={setProfileSelectionVisible}
                  onRedeemVoucherPopupVisible={navigationConfig.customItemsHandlers?.[CustomMenuItemRoute.VoucherRedemption]}
                  notificationPanelVisible={notificationPanelVisible}
                  navigateToScreen={navigateToScreen}
                />
                <If condition={isBigScreen}>
                  <ProfileSelect
                    visible={profileSelectionVisible}
                    onClose={onProfileSelectionClose}
                    focusNearestParentOnClose={false}
                    onAddNewPress={onAddNewProfilePress}
                  />
                </If>
              </FocusParent>
            )}
          </View>
          {notificationPanelVisible && (
            <View style={styles.notificationPanelView}>
              <View style={dynamicStyles.container} />
              <FocusParent onFocusEscape={() => setNotificationPanelVisible(false)} style={StyleSheet.absoluteFill} >
                <NotificationsPanel
                  notifications={notifications}
                  newStylingAvailable={!!newStyling}
                  onNewStylingPopupVisible={onNewStylingPopupVisible}
                />
              </FocusParent>
            </View>
          )}
          <NewStylingPopup
            visible={newStylingPopupVisible && !!newStyling}
            styling={newStyling}
            onClose={onNewStylingPopupClose}
          />
          <TestContext.Provider value={testIdContext}>
            <Popup
              visible={noNotificationsPopupVisible}
              title={t('launcher.notifications.empty')}
              actions={noNotificationsPopupActions}
              onClose={onNoNotificationsPopupClose}
            />
          </TestContext.Provider>
        </FocusParent>
      </ModalVisibilityReporter>
    </STBMenuContext.Provider>
  );
};

function leafRoutesHash(routes: NavigationLeafRoute[]) {
  return routes.map(route => route.key).join('|');
}

function routesHash(routes: NavigationRoute[]) {
  return routes.map(route => leafRoutesHash(route.routes)).join('^');
}

function areStackShapesEqual(lhs: NavigationRoute[], rhs: NavigationRoute[]) {
  const lhsHash = routesHash(lhs);
  const rhsHash = routesHash(rhs);

  return lhsHash === rhsHash;
}

function useMemoizedRoutes(routes: NavigationRoute[]) {
  const memoizedRoutes = useRef(routes);

  if (memoizedRoutes.current !== routes) {
    if (!areStackShapesEqual(memoizedRoutes.current, routes)) {
      memoizedRoutes.current = routes;
    }
  }

  return memoizedRoutes.current;
}

const MemoizedSTBNavigationView = React.memo(STBNavigationView);

type STBNavigationViewContainerProps = STBNavigationViewProps & {
  descriptors: Descriptors;
}

const STBNavigationViewContainer: React.FC<STBNavigationViewContainerProps> = ({
  navigation,
  navigationConfig,
  descriptors,
  screenProps
}) => {
  // react-navigation@3 typing is hard to extract
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const navigate = useFunction(navigation.navigate);
  const {state: {index, routes}} = navigation;

  const memoizedRoutes = useMemoizedRoutes(routes);

  return (
    <DescriptorsContext.Provider value={descriptors}>
      <MemoizedSTBNavigationView
        navigationConfig={navigationConfig}
        navigationIndex={index}
        routes={memoizedRoutes}
        navigate={navigate as NavigationScreenProp<unknown>['navigate']}
        screenProps={screenProps}
      />
    </DescriptorsContext.Provider>
  );
};

export default React.memo(STBNavigationViewContainer);

export function useSTBMenu() {
  return useContext(STBMenuContext);
}
