import {createStyles} from 'common-styles';
import React, {useState, useRef, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {View, ActivityIndicator} from 'react-native';

import {dimensions, isPhone, getValue} from 'common/constants';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';

import LabeledTextInput from 'components/inputs/LabeledTextInput';
import NitroxText from 'components/NitroxText';
import ProgressTracker from 'components/ProgressTracker';
import {useChangeEffect, useDisposableCallback} from 'hooks/Hooks';
import {useNitroxScreenBackCapture} from 'screens/NitroxScreen';

import FormButton, {buttonTextPostProcessor} from './FormButton';

const TAG = 'Form';

const styles = createStyles({
  spacer: {
    height: getValue({
      mobile: 0,
      tablet: dimensions.margins.xxLarge,
      defaultValue: dimensions.margins.medium
    })
  },
  spinner: {
    marginLeft: dimensions.margins.small
  }
});

const dynamicStyles = new StylesUpdater((colors) => createStyles({
  headline: {
    color: colors.screenHeader.title,
    marginBottom: isPhone ? dimensions.margins.small : dimensions.margins.medium,
    textAlign: 'center'
  }
}));

type Data = {
  [key: string]: string;
}

type Field<T extends Data> = {
  /**
   * Key that couples input field with property of `data` object provided in form config.
   * This is used to update data field when the value of text input changes.
   */
  dataKey: Extract<keyof T, string>;
  title: string;
  maxLength?: number;
  /**
   * Whether value should be hidden from the user.
   */
  secure?: boolean;
}

type InputProps<T extends Data> = {
  initialValue?: string;
  onValueChanged: (value: string) => void;
} & Field<T>;

function Input<T extends Data>(props: InputProps<T>) {
  const {dataKey, initialValue, maxLength, onValueChanged, secure, title} = props;
  return (
    <LabeledTextInput
      label={title}
      initialValue={initialValue}
      maxLength={maxLength}
      onChangeText={onValueChanged}
      secureTextEntry={secure}
      testID={`input_${dataKey}`}
    />
  );
}

type FormStep<T extends Data> = {
  fields: Field<T>[],
  validator: (data: T) => Promise<void>
}

export type FormConfig<T extends Data> = {
  steps: FormStep<T>[];
  data: T;
}

export type FormProps<T extends Data> = {
  formConfig: FormConfig<T>;
  onCancel?: () => void;
  onSubmit: (data: T) => Promise<void>;
  title: string;
}

function Form<T extends Data>(props: FormProps<T>) {
  const {
    formConfig,
    onCancel,
    onSubmit,
    title
  } = props;
  const {steps} = formConfig;
  const {t} = useTranslation();
  const {headline: headlineStyle} = dynamicStyles.getStyles();
  const [submitting, setSubmitting] = useState(false);
  const [validating, setValidating] = useState(false);
  const onSubmitted = useDisposableCallback(() => setSubmitting(false));
  const onValidated = useDisposableCallback(() => setValidating(false));

  const data = useRef({...formConfig.data});
  const [stepIndex, setStepIndex] = useState(0);
  const isFirstStep = stepIndex === 0;
  const isLastStep = stepIndex === steps.length - 1;

  useChangeEffect(() => {
    data.current = {...formConfig.data};
    setStepIndex(0);
  }, [formConfig]);

  useNitroxScreenBackCapture(() => {
    if (isFirstStep) {
      return false;
    }
    setStepIndex(stepIndex - 1);
    return true;
  });

  const onNext = useCallback(() => {
    const validator = steps[stepIndex].validator;
    if (!validator) {
      setStepIndex(stepIndex + 1);
      return;
    }
    if (validating) {
      return;
    }
    setValidating(true);
    const dataToValidate = Object.assign({}, ...steps[stepIndex].fields.map(({dataKey}) => ({[dataKey]: data.current[dataKey]})));

    validator(dataToValidate)
      .then(() => setStepIndex(stepIndex + 1))
      .catch(e => Log.error(TAG, 'Partial validation error:', e))
      .finally(onValidated);
  }, [onValidated, stepIndex, steps, validating]);

  return (
    <View>
      {title && (
        <NitroxText style={headlineStyle} textType='headline'>{title}</NitroxText>
      )}
      {steps.length > 1 && (
        <ProgressTracker
          currentStep={stepIndex}
          numberOfSteps={steps.length}
        />
      )}
      {steps[stepIndex].fields.map(({dataKey, maxLength, secure, title}) => (
        <Input<T>
          initialValue={data.current[dataKey]}
          dataKey={dataKey}
          key={dataKey}
          maxLength={maxLength}
          onValueChanged={value => data.current = {...data.current, [dataKey]: value}}
          secure={secure}
          title={title}
        />
      ))}
      <View style={styles.spacer} />
      {isLastStep ? (
        <FormButton
          positive
          text={t('common.submit', {postProcess: buttonTextPostProcessor})}
          testID='button_submit'
          onPress={() => {
            if (submitting) return;
            setSubmitting(true);
            onSubmit(data.current).finally(onSubmitted);
          }}
        >
          {submitting && <ActivityIndicator style={styles.spinner} size='small' />}
        </FormButton>
      ) : (
        <FormButton
          positive
          text={t('common.next', {postProcess: buttonTextPostProcessor})}
          testID='button_next'
          onPress={onNext}
        >
          {validating && <ActivityIndicator style={styles.spinner} size='small' />}
        </FormButton>
      )}
      {!isFirstStep ? (
        <FormButton
          key='back' // key is used to prevent losing focus because of button change
          text={t('common.back', {postProcess: buttonTextPostProcessor})}
          testID='button_back'
          onPress={() => setStepIndex(stepIndex - 1)}
        />
      ) : (
        onCancel && (
          <FormButton
            key='back'
            text={t('common.cancel', {postProcess: buttonTextPostProcessor})}
            testID='button_cancel'
            onPress={onCancel}
          />
        )
      )}
    </View>
  );
}

export default React.memo(Form) as typeof Form;
