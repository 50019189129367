import {isWebOS} from 'common/constants';
import {EventEmitter} from 'common/EventEmitter';

import {asyncStorage} from 'mw/platform/async-storage/AsyncStorage';

const UID_USAGE_AGREEMENT_ASYNC_STORAGE_KEY = 'UserAgreement-uid';

export enum UserAgreementEvent {
  uidUsageAgreementRequired = 'uidUsageAgreementRequired',
  uidUsageAgreementConfirmed = 'uidUsageAgreementConfirmed',
}

export class UserAgreement extends EventEmitter<UserAgreementEvent> {

  public async checkUidUsageAgreement(): Promise<void> {
    const agree = !isWebOS || await this.getSavedUidUsageAgreement();
    if (agree) {
      this.notify(UserAgreementEvent.uidUsageAgreementConfirmed);
    } else {
      this.notify(UserAgreementEvent.uidUsageAgreementRequired);
    }
  }

  public async confirmUidUsageAgreement(): Promise<void> {
    await this.saveUidUsageAgreement();
    this.notify(UserAgreementEvent.uidUsageAgreementConfirmed);
  }

  public async invalidateUidUsageAgreement(): Promise<void> {
    return await this.clearUidUsageAgreement();
  }

  private getSavedUidUsageAgreement(): Promise<string | null> {
    return asyncStorage.getSecureItem(UID_USAGE_AGREEMENT_ASYNC_STORAGE_KEY);
  }

  private saveUidUsageAgreement(): Promise<void> {
    return asyncStorage.setSecureItem(UID_USAGE_AGREEMENT_ASYNC_STORAGE_KEY, 'true');
  }

  private clearUidUsageAgreement(): Promise<void> {
    return asyncStorage.removeSecureItem(UID_USAGE_AGREEMENT_ASYNC_STORAGE_KEY);
  }
}
