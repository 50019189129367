import {EventEmitter} from 'common/EventEmitter';

import {nxffConfig} from 'mw/api/NXFF';

import {EventAdditionalParams, ReporterInterface, ReporterEvent, ReportingEvent} from './ReporterInterface';
import {ToolboxReporter} from './ToolboxReporter';
import {ViewershipReporter} from './ViewershipReporter';

export class ReportingManager extends EventEmitter<ReportingEvent> implements ReporterInterface {
  private reporters: Array<ReporterInterface> = [];

  public constructor() {
    super();

    if (nxffConfig.getConfig().Toolbox.ToolboxEnabled ) {
      this.reporters.push(new ToolboxReporter());
    }
    if (nxffConfig.getConfig().Reporting.ReportingEnabled) {
      this.reporters.push(new ViewershipReporter());
    }

    this.reporters.forEach((reporter) => {
      reporter.on(ReportingEvent.Unauthorized, () => this.notify(ReportingEvent.Unauthorized));
    });
  }

  public clear(): void {
    this.reporters.forEach((reporter) => reporter.clear());
  }

  public handleLoginEvent = (): void => {
    this.reporters.forEach(reporter => reporter.handleLoginEvent());
  }

  public handleLogoutEvent = (): void => {
    this.reporters.forEach(reporter => reporter.handleLogoutEvent());
  }

  public handleEvent = (event: ReporterEvent, eventParams: any, additionalParams: EventAdditionalParams): void => {
    this.reporters.forEach(reporter => reporter.handleEvent(event, eventParams, additionalParams));
  }
}
