import React, {useState, useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {isMobile, isBigScreen, dimensions} from 'common/constants';

import {DateFormatDescription, TimeFormatDescription} from 'mw/api/NXFF';

import {IconType} from 'components/Icon';
import {ModalSelect} from 'components/ModalSelect';
import SelectionPopup from 'components/SelectionPopup';
import {useChangeEffect, useToggle} from 'hooks/Hooks';

import SettingsClickableOption from './SettingsClickableOption';

type SettingsChangeFormat = {
  formats: DateFormatDescription[] | TimeFormatDescription[];
  initialValue: DateFormatDescription | TimeFormatDescription;
  onFormatChanged: (format: DateFormatDescription | TimeFormatDescription) => void;
  title: string;
  getDefaultLabel: (format: string) => string;
}

const SettingsChangeFormat: React.FC<SettingsChangeFormat> = ({
  formats,
  initialValue,
  onFormatChanged: onPropsFormatChanged,
  title,
  getDefaultLabel
}) => {
  const {t} = useTranslation();
  const [isPickerVisible, {on: showPicker, off: hidePicker}] = useToggle(false);
  const [format, setFormat] = useState(initialValue);

  useChangeEffect(() => {
    onPropsFormatChanged(format);
  }, [format], [onPropsFormatChanged]);

  const onFormatChanged = useCallback((format: DateFormatDescription | TimeFormatDescription | string) => {
    setFormat(typeof format === 'string' ? formats.find(f => f.value === format) as DateFormatDescription | TimeFormatDescription : format);
    hidePicker();
  }, [formats, hidePicker]);

  const getLabel = useCallback((format: DateFormatDescription | TimeFormatDescription) =>
    format.label ? t(format.label) : getDefaultLabel(format.value)
  , [getDefaultLabel, t]);

  const options = useMemo(() => formats.map(format => ({
    value: format.value,
    key: format.value,
    label: getLabel(format),
    text: getLabel(format),
    onPress: () => onFormatChanged(format)
  })), [formats, getLabel, onFormatChanged]);

  const label = getLabel(format);

  return (
    <>
      <SettingsClickableOption
        title={title}
        buttonLabel={label}
        onPress={showPicker}
      />
      {isMobile && (
        <ModalSelect
          title={title}
          options={options}
          value={format.value}
          visible={isPickerVisible}
          onChange={onFormatChanged}
          onClose={hidePicker}
          selectedOptionIcon={IconType.Check}
        />
      )}
      {isBigScreen && (
        <SelectionPopup
          title={title}
          sections={[{options}]}
          visible={isPickerVisible}
          onClose={hidePicker}
          focusedKey={format.value}
          buttonWidth={dimensions.popup.button.language.minWidth}
        />
      )}
    </>
  );
};

export default SettingsChangeFormat;
