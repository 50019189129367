import {createStyles} from 'common-styles';
import React from 'react';
import {useMemo} from 'react';
import {View} from 'react-native';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Icon} from 'components/Icon';

import FocusParent from './FocusParent';
import {Modal} from './Modal';
import NitroxButton, {NitroxButtonTheme} from './NitroxButton';
import {NitroxInteractiveController} from './NitroxInteractiveControllerContext';
import NitroxText from './NitroxText';
import {PopupContentProps, popupContentStylesUpdater} from './PopupContent';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  withoutRoundedBottom: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  withoutRoundedTop: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  },
  iconContainer: {
    alignItems: 'center',
    padding: dimensions.margins.xLarge
  },
  iconColor: colors.defaultColors.icon
}));

const iconSize = dimensions.icon.xxxLarge;

const PopupContent: React.FC<PopupContentProps> = ({
  visible,
  actions,
  title,
  subtitle,
  children,
  onModalClose,
  width,
  menuStyle,
  containerStyle,
  titleStyle,
  titleType,
  subtitleStyle,
  icon,
  portal,
  focusNearestParentOnClose
}) => {
  const popupContentStyles = popupContentStylesUpdater.getStyles();
  const styles = stylesUpdater.getStyles();

  const actionsMenuStyle = useMemo(() => ([
    popupContentStyles.menu,
    popupContentStyles.menuFlexParams,
    actions.length === 1 && popupContentStyles.menuCentered,
    menuStyle
  ]), [actions.length, menuStyle, popupContentStyles.menu, popupContentStyles.menuFlexParams, popupContentStyles.menuCentered]);

  return (
    <Modal
      visible={visible}
      onClose={onModalClose}
      contentStyle={{width}}
      portal={portal}
      focusNearestParentOnClose={focusNearestParentOnClose}
    >
      <NitroxInteractiveController omitGeometryCaching>
        <View>
          <View style={[
            popupContentStyles.container,
            {width},
            styles.withoutRoundedBottom,
            containerStyle
          ]}
          >
            {!!icon && (
              <View style={styles.iconContainer}>
                <Icon type={icon} size={iconSize} color={styles.iconColor} />
              </View>
            )}
            {!!title && (
              <View>
                <NitroxText textType={titleType || 'dialog-title'} style={[popupContentStyles.title, titleStyle]}>{title}</NitroxText>
                {!!subtitle && <NitroxText textType='callout' style={[popupContentStyles.subtitle, subtitleStyle]}>{subtitle}</NitroxText>}
              </View>
            )}
            {children}
          </View>
          {!!actions.length && (
            <FocusParent>
              <View style={[
                popupContentStyles.container,
                {width},
                containerStyle,
                actionsMenuStyle,
                styles.withoutRoundedTop
              ]}
              >
                {actions
                  .map(action => (
                    <NitroxButton
                      {...action}
                      key={action.key}
                      border
                      theme={NitroxButtonTheme.Primary}
                    />
                  )
                  )
                }
              </View>
            </FocusParent>
          )}
        </View>
      </NitroxInteractiveController>
    </Modal>
  );
};

export default PopupContent;
