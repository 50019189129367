import {debounce, DebouncedFunction} from 'common/Async';
import {DateUtils} from 'common/DateUtils';
import {EventEmitter} from 'common/EventEmitter';
import {Log} from 'common/Log';

import {EntitlementState, Title} from 'mw/api/Metadata';

const TAG = 'EntitlementStateChangeNotifier';

export enum EntitlementStateChangeEvent {
  StateChanged
}

export type EntitlementStateUpdater = (title: Title) => Promise<void>;

export default class EntitlementStateChangeNotifier extends EventEmitter<EntitlementStateChangeEvent> {
  private static defaultCheckDelayMs = 5 * DateUtils.msInMin;
  private readonly updateEntitlementState: EntitlementStateUpdater;
  private readonly debounceCheckEntitlementState: DebouncedFunction<(title: Title) => Promise<void>>;
  private started = false;

  public constructor(updateEntitlementState: EntitlementStateUpdater, checkDelayMs?: number) {
    super();
    this.updateEntitlementState = updateEntitlementState;
    this.debounceCheckEntitlementState = debounce(this.checkEntitlementState, checkDelayMs || EntitlementStateChangeNotifier.defaultCheckDelayMs);
  }

  private checkEntitlementState = async (title: Title): Promise<void> => {
    try {
      await this.updateEntitlementState(title);
      if (title.entitlementState === EntitlementState.EntitlementInProgress) {
        return this.debounceCheckEntitlementState(title);
      }
      this.notify(EntitlementStateChangeEvent.StateChanged);
      this.stop();
    } catch (error) {
      Log.error(TAG, `Error getting entitlement state for ${title.id}`, error);
      return this.debounceCheckEntitlementState(title);
    }
  };

  public start(title: Title): void {
    if (title.entitlementState === EntitlementState.EntitlementInProgress) {
      this.started = true;
      this.debounceCheckEntitlementState(title)
        .catch(error => Log.error(TAG, `Failed to check entitlement state for ${title.id}`, error));
    } else {
      this.stop();
    }
  }

  public stop(): void {
    this.debounceCheckEntitlementState.abort();
    this.started = false;
  }

  public isStarted(): boolean {
    return this.started;
  }
}
