import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions, isPhone, isTablet} from 'common/constants';

import {ModalSelectOption} from 'components/ModalSelect';
import ModalSelectorButton from 'components/ModalSelectorButton';
import {NitroxButtonTheme} from 'components/NitroxButton';

import {VodCategory} from './VodCategoryNavigator';

const staticStyles = createStyles({
  button: {
    ...isTablet && {
      minWidth: 335
    },
    ...isPhone && {
      width: '100%'
    },
    alignItems: 'center'
  },
  container: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: dimensions.margins.large,
    paddingRight: dimensions.margins.large
  }
});

function mapCategoriesToOptions(categories: VodCategory[]): ModalSelectOption<string>[] {
  return categories.map(category => ({
    value: category.label,
    label: category.label,
    key: category.label
  }));
}

type Props = {
  category?: VodCategory;
  onSelectorPressed: (value: VodCategory | string | number | undefined) => void;
};

const VodSubCategoryButton: React.FC<Props> = ({
  category,
  onSelectorPressed
}) => {
  const {t} = useTranslation();

  const options = useMemo(() => mapCategoriesToOptions(category?.children || []), [category?.children]);

  if (!category?.children.length) {
    return null;
  }

  return (
    <View style={staticStyles.container}>
      <ModalSelectorButton
        title={t('vod.subcategory')}
        label={t('vod.selectSubcategory')}
        theme={NitroxButtonTheme.Secondary}
        buttonStyle={staticStyles.button}
        options={options}
        onSelected={onSelectorPressed}
        style={staticStyles.button}
      />
    </View>
  );
};

export default VodSubCategoryButton;
