import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxText from 'components/NitroxText';
import Popup, {PopupAction} from 'components/Popup';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  button: {
    marginVertical: dimensions.margins.small,
    marginHorizontal: dimensions.margins.small
  },
  info: {
    color: colors.popup.text,
    marginBottom: dimensions.margins.large,
    textAlign: 'center'
  }
}));

type Props = {
  visible: boolean;
  onCancel: () => void;
  onConfirmation: () => void;
}

const goToLivePopupActions = [PopupAction.POSITIVE, PopupAction.NEGATIVE];

const GoToLivePopup: React.FunctionComponent<Props> = props => {
  const {onCancel, onConfirmation} = props;
  const {t} = useTranslation();

  const styles = stylesUpdater.getStyles();

  const title = useMemo(() => {
    return t('zapper.goToLiveConfirmationTitle');
  }, [t]);

  const info = useMemo(() => {
    return t('zapper.goToLiveConfirmationInfo');
  }, [t]);

  return (
    <Popup
      actions={goToLivePopupActions}
      visible={props.visible}
      onClose={onCancel}
      positiveLabel={t('common.ok')}
      negativeLabel={t('common.cancel')}
      onNegative={onCancel}
      onPositive={onConfirmation}
      title={title}
      menuButtonStyle={styles.button}
    >
      <View>
        <NitroxText style={styles.info} textType='body1'>{info}</NitroxText>
      </View>
    </Popup>
  );
};

export default React.memo(GoToLivePopup);
