import {Log} from 'common/Log';

import {ParentalControlRating} from 'mw/api/Metadata';
import {nxffConfig, ParentalControlType} from 'mw/api/NXFF';
import {Profile} from 'mw/api/Profile';
import {mw} from 'mw/MW';

const TAG = 'PCPolicy';

export class PCType {
  private constructor() {}

  public static readonly AgeBased: ParentalControlType = 'AgeBased';
  public static readonly RatingsBased: ParentalControlType = 'RatingsBased';
}

export interface PCPolicy {
  type: ParentalControlType;
  isBlocked(profile: Profile, pcRatings: ParentalControlRating[]): boolean;
}

export function createPCPolicy(): PCPolicy {
  const type = nxffConfig.getConfig().ParentalControl.PCPolicy;

  switch (type) {
    case PCType.RatingsBased:
      return {
        type,
        isBlocked: (profile: Profile, pcRatings: ParentalControlRating[]): boolean => {
          const supportedRatings = mw.configuration.supportedPCRatings;
          return pcRatings.some(contentRating => {
            const profileRating = profile.pcRatings[contentRating.authority];
            const ratingMap = supportedRatings.find(r => r.authority === contentRating.authority)?.ratingsMap;
            const blockedRatings = ratingMap?.get(profileRating);
            return !!blockedRatings?.includes(contentRating.value);
          });
        }
      };
    default:
      Log.error(TAG, 'PC policy not set falling back to age based PC');
    case PCType.AgeBased:
      return {
        type,
        isBlocked: (profile: Profile, pcRatings: ParentalControlRating[]): boolean => {
          if (pcRatings.length) {
            const {minimumAge} = pcRatings[0];
            return minimumAge > profile.age;
          }
          // In a particular type of media, the decision is made as to what to do if there are no ratings.
          return false;
        }
      };
  }
}

export function isPCDisabled(): boolean {
  const profile = mw.customer.currentProfile;
  return !!profile && !profile.isPCEnabled;
}

export function filterPCRatings(pcRatings: ParentalControlRating[]) {
  const supportedAuthorities = mw.configuration.supportedPCAuthorities;
  return pcRatings
    .filter((rating: ParentalControlRating) => supportedAuthorities.includes(rating.authority))
    .sort((obj1, obj2) => supportedAuthorities.indexOf(obj1.authority) - supportedAuthorities.indexOf(obj2.authority));
}
