import {createStyles} from 'common-styles';
import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {dimensions} from 'common/constants';

import {PopupAction} from 'components/Popup';
import SelectionPopup from 'components/SelectionPopup';
import {PostProcessors} from 'locales/i18nPostProcessors';
import {SearchFilterValue, SearchFilterOptionsKeys} from 'screens/search/SearchHelperTypes';

const actions: PopupAction[] = [PopupAction.NEGATIVE, PopupAction.POSITIVE];

type Props = {
  modalVisible: boolean;
  searchField: SearchFilterValue;
  onModalClosed: () => void;
  onModalApply: (searchField: SearchFilterValue) => void;
}

const styles = createStyles({
  menu: {
    justifyContent: 'center'
  },
  menuButtons: {
    margin: dimensions.margins.small
  }
});

const SearchFilterSelect: React.FunctionComponent<Props> = props => {
  const {modalVisible, searchField, onModalApply, onModalClosed} = props;
  const {t} = useTranslation();
  const [selectedFilterKey, setSelectedFilterKey] = useState(searchField);

  const onApply = useCallback(() => {
    onModalApply(selectedFilterKey);
  }, [onModalApply, selectedFilterKey]);

  const onClose = useCallback(() => {
    setSelectedFilterKey(searchField);
    onModalClosed();
  }, [onModalClosed, searchField]);

  const sections = useMemo(
    () => {
      const filterOptionsKeys = Object.keys(SearchFilterOptionsKeys) as SearchFilterValue[];
      const filterOptionsTranslations = Object.values(SearchFilterOptionsKeys);

      return [{
        options: filterOptionsKeys.map((key: SearchFilterValue, index: number) => {
          return {
            key: key,
            text: t(filterOptionsTranslations[index], {postProcess: PostProcessors.ToUpperCase}),
            onPress: () => setSelectedFilterKey(key)
          };
        })
      }];
    },
    [t]
  );

  const popupProps = useMemo(() => ({
    actions,
    positiveLabel: t('common.apply', {postProcess: PostProcessors.ToUpperCase}),
    negativeLabel: t('common.close', {postProcess: PostProcessors.ToUpperCase}),
    onPositive: onApply,
    onNegative: onClose,
    menuStyle: styles.menu,
    menuButtonStyle: styles.menuButtons
  }), [onApply, onClose, t]);

  return (
    <SelectionPopup
      sections={sections}
      focusedKey={selectedFilterKey}
      title={t('search.filters')}
      visible={modalVisible}
      popupProps={popupProps}
      onClose={onClose}
    />
  );
};

export default React.memo(SearchFilterSelect) as typeof SearchFilterSelect;
