import {defaultStyles} from 'common-styles';
import React, {forwardRef, useEffect, useState} from 'react';
import {WithTranslation} from 'react-i18next';
import {withNavigation, NavigationScreenProps} from 'react-navigation';

import {isBigScreen, isTablet} from 'common/constants';
import {DateUtils} from 'common/DateUtils';

import {Channel} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import ChannelDetailsGrosso from 'components/mediadetails/ChannelDetails.grosso';
import ChannelDetailsPiccolo from 'components/mediadetails/ChannelDetails.piccolo';
import {STBMenuState} from 'components/navigation/NavigationHelperTypes';
import {useNavigationFocusState} from 'hooks/Hooks';
import {onPipPlayerViewReady} from 'screens/epg/EpgHelperFunctions';
import {EpgScreenPlayerViewLocation} from 'screens/epg/EpgHelperTypes';
import NitroxScreen from 'screens/NitroxScreen';

const TAG = 'ChannelDetailScreen';

export type ChannelDetailScreenProps = NavigationScreenProps<{
  mediaId: string;
}> & WithTranslation;

const ChannelDetailScreen: React.FC<ChannelDetailScreenProps> = (props) => {
  const mediaId = props.navigation.getParam('mediaId');
  const [channel, setChannel] = useState<Channel>();
  const focusState = useNavigationFocusState(props.navigation);

  useEffect(() => {
    if (!mediaId) {
      return;
    }
    const channelDetails = mw.catalog.getChannelById(mediaId);
    setChannel(channelDetails);
  }, [mediaId]);

  const date = new Date();
  const commonProps = {
    ...props,
    channel,
    startTime: date,
    endTime: DateUtils.addHours(date, 24)
  };

  const grossoProps = {
    focusState,
    onPipPlayerViewReady: (playerLocation: EpgScreenPlayerViewLocation) => onPipPlayerViewReady(playerLocation, focusState, TAG)
  };

  return (
    <NitroxScreen
      style={defaultStyles.view}
      navigation={props.navigation}
      menuState={STBMenuState.Hidden}
      mobileHeaderProps={{title: channel?.name}}
      stopPlaybackOnAppear={isTablet}
      popStackOnBack
    >
      {isBigScreen ?
        <ChannelDetailsGrosso {...commonProps} {...grossoProps} /> :
        <ChannelDetailsPiccolo {...commonProps} />
      }
    </NitroxScreen>
  );
};

export default withNavigation(forwardRef(ChannelDetailScreen));
