import {createStyles} from 'common-styles';
import React, {useContext, useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {View, ViewStyle, GestureResponderEvent} from 'react-native';
import {NavigationContext} from 'react-navigation';

import {dimensions, AppRoutes, featureFlags} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxButton, {NitroxButtonTheme} from 'components/NitroxButton';
import NitroxText from 'components/NitroxText';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 1
  },
  label: {
    color: colors.epgScreen.label,
    flexShrink: 0
  },
  button: {
    flexShrink: 1,
    marginHorizontal: dimensions.margins.small
  }
}));

type Props = {
  style?: ViewStyle;
  showEPGButton?: boolean;
  showJumpToNowButton?: boolean;
  onChannelsListPress?: (channelsListId: string, event?: GestureResponderEvent) => void;
  onEPGButtonPress?: (event?: GestureResponderEvent) => void;
  onJumpToNowPress?: (event?: GestureResponderEvent) => void;
}

const ChannelsListsMenu: React.FC<Props> = props => {
  const {onChannelsListPress, onEPGButtonPress} = props;
  const navigation = useContext(NavigationContext);
  const {t} = useTranslation();

  // TODO: There is no support of channels lists in the MW from where channelsLists should be loaded, therefore we are hardcoding this pseudo channels list for now.
  const channelsLists = useMemo(() => [
    {id: 'default', name: t('common.allChannels')}
  ], [t]);

  // memo each of the callbacks
  const onChannelsListPressCallbacks = useMemo(() => channelsLists.map(list => (event?: GestureResponderEvent) => {
    if (onChannelsListPress) {
      onChannelsListPress(list.id, event);
    }
  }), [onChannelsListPress, channelsLists]);

  const onEPGButtonPressCallback = useCallback((event?: GestureResponderEvent) => {
    // if custom handler was provided used it otherwise open the EPG
    if (onEPGButtonPress) {
      onEPGButtonPress(event);
    } else {
      navigation.navigate(AppRoutes.Epg);
    }
  }, [navigation, onEPGButtonPress]);

  const styles = stylesUpdater.getStyles();
  return (
    <View style={[styles.container, props.style]}>
      {featureFlags.channelLists && (
        <View style={styles.content}>
          <NitroxText numberOfLines={1} style={styles.label}>
            {t('common.channelList')}
            :
          </NitroxText>
          {channelsLists.map((channelsList, index) => (
            <NitroxButton
              key={channelsList.id}
              onPress={onChannelsListPressCallbacks[index]}
              theme={NitroxButtonTheme.Secondary}
              text={channelsList.name}
              style={styles.button}
            />
          ))
          }
        </View>
      )}
      {props.showEPGButton && <NitroxButton theme={NitroxButtonTheme.Secondary} testID='button_tv_guide' text={t('common.tvGuide')} onPress={onEPGButtonPressCallback} />}
      {props.showJumpToNowButton && <NitroxButton theme={NitroxButtonTheme.Secondary} testID='button_now' text={t('common.now')} onPress={props.onJumpToNowPress} />}
    </View>
  );
};

export default React.memo(ChannelsListsMenu);
