import React, {useCallback, useState, useMemo} from 'react';
import {View, StyleSheet, LayoutChangeEvent, StyleProp, ViewStyle} from 'react-native';
import Svg, {RadialGradient, Defs, Stop, StopProps, Rect} from 'react-native-svg';

import {isATV} from 'common/constants';
import {doNothing} from 'common/HelperFunctions';
import {Size} from 'common/HelperTypes';

import {shortId} from 'mw/common/utils';

import {useLazyRef} from 'hooks/Hooks';

const TAG = 'RadialGradientView';
const initialContentSize = {width: 0, height: 0};

const androidDivider = 2; //TODO: CL-7639 use common divider

type ColorConfig = {
  color: StopProps['stopColor'];
  location?: StopProps['offset']; //By default, colors will be distributed evenly
  opacity?: StopProps['stopOpacity']; //By default, color will be fully opaque
}

type EllipseRadius = {
  horizontal: number;
  vertical: number;
}

type Props = {
  /**
   * gradient colors - it must not be empty
   */
  colors: ColorConfig[];
  radius?: number;
  ellipseRadius?: EllipseRadius;
  style?: StyleProp<ViewStyle>;
  scaleX?: number;
  scaleY?: number;
  rotation?: number;
}

const RadialGradientView: React.FunctionComponent<Props> = (props) => {
  const {
    colors,
    radius = null,
    ellipseRadius = null,
    style,
    scaleX = 1,
    scaleY = 1,
    rotation = 0
  } = props;

  const id = useLazyRef(() => `${TAG}-${shortId()}`).current;
  const radialGradientId = `${id}-RadialGradient`;
  const shouldUseContentSize = radius === null && ellipseRadius === null;
  const [contentSize, setContentSize] = useState<Size>(initialContentSize);

  const {contentSizeProps} = useMemo(() => {
    const horizontalRadius = shouldUseContentSize
      ? Math.round(contentSize.width / 2)
      : ellipseRadius?.horizontal ?? radius ?? 0;
    const verticalRadius = shouldUseContentSize
      ? Math.round(contentSize.height / 2)
      : ellipseRadius?.vertical ?? radius ?? 0;

    return {
      contentSizeProps: {
        width: horizontalRadius * 2,
        height: verticalRadius * 2
      }
    };
  }, [shouldUseContentSize, contentSize, radius, ellipseRadius]);

  const gradientColors = useMemo(() => {
    const useLocations = colors.every(colorConfig => typeof colorConfig.location === 'number');
    return colors.map((colorConfig, index) => (
      <Stop
        key={`${id}-${index}`}
        stopColor={colorConfig.color}
        offset={useLocations
          ? colorConfig.location
          : index / (colors.length - 1)
        }
        stopOpacity={colorConfig.opacity ?? 1}
      />
    ));
  }, [colors, id]);

  const lastColor = colors[colors.length - 1]?.color as string || '';
  const viewStyle = useMemo(() => ([
    StyleSheet.absoluteFill,
    style,
    {backgroundColor: lastColor}
  ]), [style, lastColor]);

  const onLayout = useCallback(({nativeEvent: {layout: {width, height}}}: LayoutChangeEvent) => {
    setContentSize({width, height});
  }, []);

  const rect = useMemo(() => {
    const rectangle = {
      width: contentSizeProps.width * scaleX,
      height: contentSizeProps.height * scaleY,
      originX: (contentSizeProps.width) / 2,
      originY: (contentSizeProps.height) / 2,
      x: contentSizeProps.width * (1 - scaleX) / 2,
      y: contentSizeProps.height * (1 - scaleY) / 2,
      rotation: rotation
    };
    if (isATV) { //Android screen density is tuned in ScreenDensityManager.java, however svgs are not using our workaround, therefore value adjusting is required.
      rectangle.width = rectangle.width / androidDivider;
      rectangle.height = rectangle.height / androidDivider;
      rectangle.originX = rectangle.originX / androidDivider;
      rectangle.originY = rectangle.originY / androidDivider;
      rectangle.x = rectangle.x / androidDivider;
      rectangle.y = rectangle.y / androidDivider;
    }
    return rectangle;
  }, [rotation, scaleX, scaleY, contentSizeProps]);

  return (
    <View
      style={viewStyle}
      onLayout={shouldUseContentSize ? onLayout : doNothing}
    >
      <Svg
        width={contentSizeProps.width}
        height={contentSizeProps.height}
      >
        <Defs>
          <RadialGradient id={radialGradientId}>
            {gradientColors}
          </RadialGradient>
        </Defs>
        <Rect
          fill={`url(#${radialGradientId})`}
          width={rect.width}
          height={rect.height}
          originX={rect.originX}
          originY={rect.originY}
          x={rect.x}
          y={rect.y}
          rotation={rotation}
        />
      </Svg>
    </View>
  );
};

export default React.memo(RadialGradientView);
