import {createStyles} from 'common-styles';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View, StyleSheet} from 'react-native';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxText from 'components/NitroxText';

const styles = createStyles({
  container: {
    ...StyleSheet.absoluteFillObject,

    justifyContent: 'center',
    alignItems: 'center'
  }
});

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  text: {
    color: colors.channelShortDetails.text
  }
}));

const UnavailableChannelMessage: React.FC = () => {
  const {t} = useTranslation();
  const dynamicStyles = dynamicStylesUpdater.getStyles();

  return (
    <View
      style={styles.container}
      testID='unavailable_channel_message'
    >
      <NitroxText textType='title1' style={dynamicStyles.text}>{t('playbackError.unavailableChannelByPolicy.title')}</NitroxText>
      <NitroxText textType='subhead' style={dynamicStyles.text}>{t('playbackError.unavailableChannelByPolicy.message')}</NitroxText>
    </View>
  );
};

export default UnavailableChannelMessage;
