import React, {useMemo, useCallback} from 'react';
import {ViewStyle} from 'react-native';
import Reanimated, {useCode, call} from 'react-native-reanimated';

import {dimensions} from 'common/constants';

import AnimatedScrollView from './ReanimatedScrollView';
import TimeBarCurrentTime from './TimeBarCurrentTime';
import {useTimeBar, tileEndDate} from './TimeBarHooks';
import TimeBarTile from './TimeBarTile';

export const timebarHeight = dimensions.epg.timeBarHeight;
const timebarTileMargin = dimensions.margins.small;

type TimeBarViewProps = {
  scrollOffset: Reanimated.Value<number>;
  gridWidth: number;
  timeBarWidth: number;
  originDate: Date;
  offsetForDate: (date: Date) => number;
  dateForOffset: (offset: number) => Date;
  nowDate: Date;
};

const TimeBarView: React.FC<TimeBarViewProps> = React.memo(({
  scrollOffset,
  gridWidth,
  timeBarWidth,
  originDate,
  offsetForDate,
  dateForOffset,
  nowDate
}) => {
  const tileStyle = useCallback((start: Date, end: Date): ViewStyle => {
    const left = offsetForDate(start);
    const width = offsetForDate(end) - left;
    return {
      position: 'absolute',
      top: timebarTileMargin,
      left,
      width,
      bottom: timebarTileMargin
    };
  }, [offsetForDate]);

  const {
    scrollOffsetChanged,
    timeBarContent,
    currentTileStart,
    currentTileProgress
  } = useTimeBar({originDate, nowDate, dateForOffset});

  useCode(() => call([scrollOffset], ([value]) => {
    scrollOffsetChanged({value: -value});
  }), [scrollOffset]);

  const content = useMemo(() => {
    return timeBarContent.map(({start, end, isPast}) => (
      <TimeBarTile
        key={start.getTime()}
        start={start}
        isPast={isPast}
        style={tileStyle(start, end)}
      />
    ));
  }, [tileStyle, timeBarContent]);

  const currentTileStyle = useMemo(
    () => tileStyle(currentTileStart, tileEndDate(currentTileStart)),
    [currentTileStart, tileStyle]
  );

  const size = useMemo(() => ({
    width: gridWidth,
    height: timebarHeight
  }), [gridWidth]);

  return (
    <AnimatedScrollView
      positionX={scrollOffset}
      size={size}
    >
      {content}
      <TimeBarTile
        start={currentTileStart}
        progress={currentTileProgress}
        style={currentTileStyle}
      />
      <TimeBarCurrentTime
        nowDate={nowDate}
        offsetForDate={offsetForDate}
        timeBarWidth={timeBarWidth}
        timeBarScrollOffset={scrollOffset}
      />
    </AnimatedScrollView>
  );
});
TimeBarView.displayName = 'TimeBarView';

export default TimeBarView;
