import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {getValue, isBigScreen} from 'common/constants';
import {doNothing, identity} from 'common/HelperFunctions';
import {getLanguageDictionaryKey} from 'common/LanguageHelperFunctions';
import {isCcTrack} from 'common/utils';

import {Track} from 'mw/api/PlayerEvent';

import {PostProcessors} from 'locales/i18nPostProcessors';
import {useLocalized} from 'locales/i18nUtils';

import {PopupAction} from './Popup';
import SelectionPopup, {SelectionPopupSection, SelectionPopupOption} from './SelectionPopup';

const columnCount = getValue({
  mobile: 3, tablet: 4,
  defaultValue: 3
});

const popupActions = isBigScreen ? [PopupAction.POSITIVE] : [];

type Props = {
  visible: boolean;
  audio: Track[];
  subtitles: Track[];
  onAudioSelected: (track: Track) => void;
  onSubtitlesSelected: (track: Track) => void;
  selectedAudio?: Track;
  selectedSubtitle?: Track;
  onClose?: () => void;
  title?: string;
}

function isSurround(codec: string): boolean {
  switch (codec.toLowerCase()) {
    // AC-3
    case 'ac-3':
    case 'mp4a.a5':
    // E-AC-3
    case 'ec-3':
    case 'mp4a.a6':
      return true;
    default:
      return false;
  }
}

function isAudio3D(codec: string): boolean {
  // MPEG-H Audio
  return codec.toLowerCase() === 'mhm1.0x0d';
}

enum AudioCodecLabel {
  Stereo = 'Stereo',
  Surround = 'Surround',
  Audio3D = '3D'
}

function getCodecLabel(codec: string): AudioCodecLabel {
  if (isSurround(codec)) {
    return AudioCodecLabel.Surround;
  }
  if (isAudio3D(codec)) {
    return AudioCodecLabel.Audio3D;
  }
  return AudioCodecLabel.Stereo;
}

const LanguageSelectionPopup: React.FunctionComponent<Props> = props => {
  const {t} = useTranslation();
  const {visible, audio, subtitles, selectedAudio, selectedSubtitle, onClose, onAudioSelected, onSubtitlesSelected, title} = props;
  const {toUpperCase} = useLocalized();

  const audioOptions: SelectionPopupOption[] = useMemo(() => {
    const languagesCount = audio
      .map(track => track.language)
      .reduce((acc, current) => {
        const langCount = acc.get(current) || 0;
        return acc.set(current, langCount + 1);
      }, new Map<string,number>());

    const getLanguageName = (track: Track) => {
      const count = languagesCount.get(track.language) || 0;
      const language = t(getLanguageDictionaryKey(track.language), {defaultValue: toUpperCase(track.language)});
      return count > 1 && track.codec ? `${language} ${getCodecLabel(track.codec)}` : language;
    };

    const options = audio.map(track => ({
      key: track.language,
      text: (isBigScreen ? toUpperCase : identity)(getLanguageName(track)),
      selected: track === selectedAudio,
      onPress: () => onAudioSelected(track)
    }));

    if (options.length === 0) {
      options.push({
        key: 'no_audio',
        text: t('common.noAudio', isBigScreen ? {postProcess: PostProcessors.ToUpperCase} : undefined),
        selected: true,
        onPress: () => doNothing()
      });
    }

    return options;
  }, [audio, selectedAudio, onAudioSelected, t, toUpperCase]);

  const subtitlesOptions: SelectionPopupOption[] = useMemo(() => {
    return subtitles.map(track => {
      const isCc = isCcTrack(track);
      const language = track.language
        ? t(getLanguageDictionaryKey(track.language)) + (isCc ? '(CC)' : '')
        : t(isCc ? 'settings.closedCaptions' : 'languages.off');
      return {
        key: track.language,
        text: `${(isBigScreen ? toUpperCase : identity)(language)}`,
        selected: track === selectedSubtitle,
        onPress: () => onSubtitlesSelected(track)
      };
    });
  }, [subtitles, selectedSubtitle, onSubtitlesSelected, toUpperCase, t]);

  const sections: SelectionPopupSection[] = useMemo(() => ([
    {options: audioOptions, title: t('common.audio'), separator: isBigScreen},
    {options: subtitlesOptions, title: t('common.subtitles')}
  ]), [audioOptions, subtitlesOptions, t]);

  return (
    <SelectionPopup
      visible={visible}
      title={title ?? t('common.language')}
      sections={sections}
      onClose={onClose}
      columnCount={columnCount}
      popupProps={{
        actions: popupActions,
        positiveLabel: t('common.close')
      }}
    />
  );
};

export default LanguageSelectionPopup;
