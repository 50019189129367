import React from 'react';
import {WhitePortal} from 'react-native-portal';

export const modalPortalName = 'ModalPortal';
export const unlockModalPortalName = 'UnlockModalPortal';
export const easAlertPopupPortalName = 'EASAlertPopupPortalName';
export const inactivityPopupPortalName = 'InactivityPopupPortalName';

function ModalPortal() {
  return (
    <>
      <WhitePortal name={modalPortalName} />
      <WhitePortal name={unlockModalPortalName} />
      <WhitePortal name={easAlertPopupPortalName} />
      <WhitePortal name={inactivityPopupPortalName} />
    </>
  );
}

export default React.memo(ModalPortal);
