import {createStyles, defaultStyles} from 'common-styles';
import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {Animated, KeyboardAvoidingView, View} from 'react-native';
import {NavigationScreenProps} from 'react-navigation';

import {disposable, cancelable, CancelResponse, CancelablePromise} from 'common/Async';
import BuildConfig from 'common/BuildConfig';
import {dimensions, getValue, isBigScreen, isMobile, isIOS, MAX_LOGIN_USERNAME_LENGTH, isPhone, AppRoutes} from 'common/constants';
import {environment} from 'common/Environment';
import {humanCaseToSnakeCase, doNothing} from 'common/HelperFunctions';
import {Log} from 'common/Log';
import TestContext from 'common/TestContext';

import {ComponentType, ComponentDataSourceType} from 'mw/api/CMSInterface';
import {ErrorType} from 'mw/api/Error';
import {Credentials, nxffConfig} from 'mw/api/NXFF';
import {Link, LinkType} from 'mw/cms/Menu';
import {Page} from 'mw/cms/Page';
import {mw} from 'mw/MW';

import {assets} from 'brand/Resources';
import BackOfficeSelect from 'components/BackOfficeSelect';
import FocusParent, {WithFocusParent, withFocusParent} from 'components/FocusParent';
import FormButton, {buttonTextPostProcessor} from 'components/form/FormButton';
import LabeledTextInput from 'components/inputs/LabeledTextInput';
import BackOfficeSelector from 'components/login/BackOfficeSelector';
import ForgotCredentials from 'components/login/ForgotCredentials';
import LoginErrorNotification from 'components/login/LoginErrorNotification';
import OfflinePopup from 'components/login/OfflinePopup';
import {STBMenuState} from 'components/navigation/NavigationHelperTypes';
import NitroxLogo from 'components/navigation/NitroxLogo';
import {NitroxButtonProps} from 'components/NitroxButton';
import {NitroxInteractiveController} from 'components/NitroxInteractiveControllerContext';
import Orientation from 'components/orientationLocker/OrientationLocker';
import SplashBackground from 'components/SplashBackground';
import {WithLocalized, withLocalized} from 'locales/i18nUtils';
import NitroxScreen from 'screens/NitroxScreen';

import Divider from './Divider';

const TAG = 'CredentialsScreen';

const backOfficeSelectorVisible = environment.getBoolean('SELECTABLE_BO');
const notificationsContainerHeight = isBigScreen ? 140 : 100;
const showMainMenu = mw.configuration.isLauncher;
const showSplashBackground = BuildConfig.showCredentialsScreenSplashBackground ?? isMobile;

const styles = createStyles({
  focusParent: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  innerContainer: {
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'stretch',
    ...isMobile && {paddingHorizontal: dimensions.margins.xxLarge},
    width: isPhone ? '100%' : dimensions.inputs.width.login
  },
  logoContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
  },
  logo: {
    ...dimensions.logo.credentials
  },
  forgotCredentialsButton: {
    borderWidth: getValue({mobile: 0, defaultValue: 2})
  },
  notificationContainer: {
    height: notificationsContainerHeight,
    justifyContent: 'center'
  },
  buttonRegister: {
    marginTop: dimensions.margins.small
  }
});

type ForgotCredentialsProps = {
  text: string;
  onPress: () => void;
} & WithLocalized;

const ForgotCredentialsButtonComponent: React.FC<ForgotCredentialsProps> = props => {
  const {toUpperCase, capitalize, text, onPress} = props;
  return (
    <FormButton
      border
      textType={isBigScreen ? 'buttons' : 'buttons-minor'}
      style={styles.forgotCredentialsButton}
      testID='button_forgot_credentials'
      text={isBigScreen ? toUpperCase(text) : capitalize(text)}
      onPress={onPress}
    />
  );
};
const ForgotCredentialsButton = React.memo(withLocalized(ForgotCredentialsButtonComponent));

type LogInErrorContextType = {
  error: ErrorType | null;
  onLoginPressed?: (credentials?: Credentials) => void;
  defaultCredentials?: Credentials;
  notification?: string;
}
export const LogInErrorContext = React.createContext<LogInErrorContextType>({error: null});

const testIdContext = {
  NitroxButton: (props: NitroxButtonProps) => props.testID ? props.testID : props.text ? `button_${humanCaseToSnakeCase(props.text)}` : 'button',
  Modal: 'modal_backoffice_selection',
  ErrorNotificationPopup: 'popup_errornotification',
  ErrorMessage: 'text_errormessage'
};

type Props = WithTranslation & NavigationScreenProps & WithFocusParent<'screenFocusParent'>;

type State = {
  enabledForgotCredentials: boolean;
  error: ErrorType | null;
  errorMessage: string | null;
  forgotCredentialsLink: string;
  forgotCredentialsMessage: string;
  forgotCredentialsTitle: string;
  isBackOfficePickerVisible: boolean;
  isChangeBoButtonVisible: boolean;
  isForgotCredentialsPopupVisible: boolean;
  isOfflinePopupVisible: boolean;
  isRegistrationEnabled: boolean;
};

class CredentialsScreen extends React.Component<Props, State> {
  public static contextType = LogInErrorContext;
  public context!: React.ContextType<typeof LogInErrorContext>;

  public state: State = {
    enabledForgotCredentials: false,
    error: null,
    errorMessage: null,
    forgotCredentialsLink: '',
    forgotCredentialsMessage: '',
    forgotCredentialsTitle: '',
    isBackOfficePickerVisible: false,
    isChangeBoButtonVisible: false,
    isForgotCredentialsPopupVisible: false,
    isOfflinePopupVisible: false,
    isRegistrationEnabled: false
  };

  private username = '';
  private password = '';
  private errorNotificationOpacity = new Animated.Value(0);
  private updateStateFromPropsEnabled = true;

  private enabledBOSelection = nxffConfig.getConfig().DemoFeatures.EnabledBOSelection;
  private UXMLoginSlug = nxffConfig.getConfig().UI.UXMLoginSlug;
  private UXMRegistrationSlug: string = nxffConfig.getConfig().UI.UXMRegistrationSlug as string;
  private uxmUpdates: CancelablePromise<Page>[] = [];

  private showBackOfficePicker = () => {
    this.setState({isBackOfficePickerVisible: true});
  };

  private hideBackOfficePicker = () => {
    this.setState({isBackOfficePickerVisible: false});
  };

  private setCredentials(credentials: Credentials) {
    this.setUsername(credentials.username);
    this.setPassword(credentials.password ?? '');
  }

  private clearCredentials = () => {
    const credentials: Credentials = {username: '', password: ''};
    this.setCredentials(credentials);
  };

  private onBoChange = () => {
    this.clearCredentials();
    this.setState({isBackOfficePickerVisible: false, isChangeBoButtonVisible: false});
    this.updateUXMFeatures();
  };

  private setUsername = (username: string) => {
    this.username = username;
  };

  private setPassword = (password: string) => {
    this.password = password;
  };

  private clearError = () => {
    this.setState({error: null});
  };

  private onLoginPressed = () => {
    this.updateStateFromPropsEnabled = true;
    if (!this.username) {
      if (!this.password && this.context.defaultCredentials) {
        this.context.onLoginPressed?.(this.context.defaultCredentials);
        return;
      }

      this.context.onLoginPressed?.(); // request for autologin
      return;
    }

    this.context.onLoginPressed?.({
      username: this.username,
      password: this.password
    });
  };

  private onRegisterPressed = () => {
    this.props.navigation.navigate(AppRoutes.Registration);
  };

  private closeOfflinePopup = () => {
    this.props.screenFocusParent.focus();
    this.setState({isOfflinePopupVisible: false, isChangeBoButtonVisible: true, error: null});
  };

  private onTryAgainLoginPressed = () => {
    this.closeOfflinePopup();
    this.onLoginPressed();
  };

  private removeHTMLTags(value: string) {
    /**
     * Will replace all occurances of (potential) tags with empty string
     * @inputs
     * <p>Test</p>
     * <P>Test</P>
     * <p>Test<img src="" /></p>
     * <p><span>Test</span></p>
     * <p>Test<This message will be removed></p>
     * @output
     * Test
     */

    return value.replace(/(<([^>]+)>)/gi, '');
  }
  private getCmsPage = disposable((link: Link) => mw.cms.getPage(link));

  private checkRegistrationFeature() {
    const cancelablePromise = cancelable(
      () => this.getCmsPage(({type: LinkType.PAGE, slug: this.UXMRegistrationSlug}))
    )();
    cancelablePromise
      .then((response) => {
        Log.debug(TAG, `getPage with slug: ${this.UXMRegistrationSlug}`, response);
        this.setState({isRegistrationEnabled: true});
      })
      .catch((error: object) => {
        if (error instanceof CancelResponse) return;
        this.setState({isRegistrationEnabled: false});
        Log.debug(TAG, 'register reject:', error);
      });
    return cancelablePromise;
  }

  private checkForgotCredentialsFeature() {
    const cancelablePromise = cancelable(
      () => this.getCmsPage({
        type: LinkType.PAGE,
        slug: this.UXMLoginSlug
      })
    )();
    cancelablePromise
      .then((response) => {
        Log.debug(TAG, `getPage with slug: ${this.UXMLoginSlug}`, response);
        const comp = response.componentGroup
          .find(group => group.title === 'forgot-credentials')?.components
          .find(component => component.type === ComponentType.Content);

        if (comp && comp.dataSource.type === ComponentDataSourceType.Content) {
          const {extraData = '', data = ''} = comp.dataSource;
          this.setState({
            enabledForgotCredentials: true,
            forgotCredentialsLink: extraData,
            forgotCredentialsTitle: comp.title,
            forgotCredentialsMessage: this.removeHTMLTags(data)
          });
        }
      })
      .catch((error: object) => {
        if (error instanceof CancelResponse) return;
        this.setState({
          enabledForgotCredentials: false,
          forgotCredentialsLink: '',
          forgotCredentialsTitle: '',
          forgotCredentialsMessage: ''
        });
        Log.debug(TAG, 'promise reject:', error);
      });
    return cancelablePromise;
  }

  public componentDidMount() {
    this.updateStateFromPropsEnabled = true;
    if (isPhone) {
      Orientation.lockToPortrait();
    }
    this.setState({error: this.context.error});
    this.updateUXMFeatures();
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (this.updateStateFromPropsEnabled && this.context.error !== null) {
      this.updateStateFromPropsEnabled = false;
      this.setState({error: this.context.error});
    }

    if (prevState.error !== this.state.error) {
      this.updateErrorMessage();
    }
  }

  private updateUXMFeatures() {
    this.uxmUpdates.forEach(p => p.cancel());
    this.uxmUpdates = [
      this.checkForgotCredentialsFeature(),
      this.checkRegistrationFeature()
    ];
  }

  private updateErrorMessage() {
    const error = this.state.error;
    if (error !== null) {
      switch (error) {
        case ErrorType.SSOUnavailableError:
        case ErrorType.BOUnavailableError:
          this.setState({isOfflinePopupVisible: true});
          break;

        default:
          this.setErrorMessage(error);
          break;
      }
    } else {
      this.setErrorNotificationVisible(false);
    }
  }

  private setErrorMessage(error: ErrorType) {
    this.setState({errorMessage: this.convertErrorTypeToMessage(error)}, () => this.setErrorNotificationVisible(true));
  }

  private showForgotCredentialsPopup = () => {
    this.setState({isForgotCredentialsPopupVisible: true});
  }

  private hideForgotCredentialsPopup = () => {
    this.setState({isForgotCredentialsPopupVisible: false});
  }

  private convertErrorTypeToMessage(errorType: ErrorType) {
    const t = this.props.t;

    switch (errorType) {
      case ErrorType.LoginError:
      case ErrorType.SSONoCredentials:
      case ErrorType.SSORefreshTokenExpired:
      case ErrorType.SSOUnauthorized:
        return t('common.invalidCredentials');
      case ErrorType.SSOInvalidToken:
        return t('common.invalidToken');
      case ErrorType.BOInvalidCustomerID:
        return t('boInvalidCustomerIDPopup.message');
      case ErrorType.NetworkRequestFailed:
        return t('common.unexpectedError');
      case ErrorType.SSOCustomerNotSubscribed:
        return t('credentials.customerNotSubscribed');
      default:
        return t('common.unexpectedAuthError', {errorCode: errorType});
    }
  }

  private isLoginError(error: ErrorType | null): boolean {
    switch (error) {
      case ErrorType.LoginError:
      case ErrorType.SSONoCredentials:
      case ErrorType.SSORefreshTokenExpired:
      case ErrorType.SSOUnauthorized:
      case ErrorType.SSOCustomerNotSubscribed:
        return true;
      default:
        return false;
    }
  }

  private setErrorNotificationVisible(visible: boolean) {
    Animated.timing(this.errorNotificationOpacity, {
      duration: 200,
      toValue: visible ? 1 : 0
    }).start();
  }

  public componentWillUnmount() {
    this.getCmsPage.dispose();
  }

  public render() {
    const {t} = this.props;
    const {
      error,
      errorMessage,
      isBackOfficePickerVisible,
      isChangeBoButtonVisible,
      isOfflinePopupVisible,
      isForgotCredentialsPopupVisible,
      isRegistrationEnabled,
      enabledForgotCredentials,
      forgotCredentialsLink,
      forgotCredentialsTitle,
      forgotCredentialsMessage
    } = this.state;
    const isLoginError = this.isLoginError(error);
    const notification = this.context.notification;

    const forgotCredentialsButton = (
      <ForgotCredentialsButton
        text={forgotCredentialsTitle}
        onPress={this.showForgotCredentialsPopup}
      />
    );

    //TODO: CL-6942 Remove workaround with checking isOfflinePopupVisible as it should be solved in a general way.
    const onScreenFocused = isOfflinePopupVisible ? doNothing : this.props.screenFocusParent.focus;

    return (
      <NitroxScreen
        navigation={this.props.navigation}
        style={defaultStyles.view}
        testID='screen_credentials'
        onScreenFocused={onScreenFocused}
        showWebOSLauncherBar
        menuState={showMainMenu ? STBMenuState.Above : undefined}
      >
        {showSplashBackground && <SplashBackground />}
        <TestContext.Provider value={testIdContext}>
          <FocusParent
            style={styles.focusParent}
            enterStrategy={'byPriority'}
            debugName={'CredentialsScreen'}
            onInternalFocusUpdate={this.clearError}
            holdFocus={!showMainMenu}
            onReady={this.props.screenFocusParent.onParentReady}
          >
            <KeyboardAvoidingView style={styles.innerContainer} behavior='position' enabled={isIOS}>
              <View style={styles.logoContainer}>
                <NitroxLogo
                  pictureUrl={assets.common.loginScreenLogo}
                  style={styles.logo}
                />
              </View>
              <FocusParent
                enterStrategy={'byPriority'}
                focusPriority={1}
                active //FIXME: CL-6247 There's a race between getting focusable children in FocusManagerBase.getComponentsWithinParent and setting active flag to true (it depends on isScreenFocused)
              >
                {/* Layout may change because of UXM requests */}
                <NitroxInteractiveController omitGeometryCaching>
                  {backOfficeSelectorVisible && (
                    <BackOfficeSelector
                      backOffices={nxffConfig.getSelectableBackOffices()}
                      initialBackOfficeCode={nxffConfig.getBackOfficeCode()}
                      onBoChange={this.onBoChange}
                    />
                  )}
                  <LabeledTextInput
                    testID='input_username'
                    maxLength={MAX_LOGIN_USERNAME_LENGTH}
                    validationError={isLoginError}
                    onChangeText={this.setUsername}
                    onInput={this.clearError}
                    label={t('credentials.username')}
                  />
                  <LabeledTextInput
                    testID='input_password'
                    secureTextEntry
                    validationError={isLoginError}
                    onChangeText={this.setPassword}
                    onInput={this.clearError}
                    label={t('credentials.password')}
                  />
                  {enabledForgotCredentials && isMobile && forgotCredentialsButton}
                  <FormButton
                    text={t('credentials.login', {postProcess: buttonTextPostProcessor})}
                    testID='button_sign_in'
                    onPress={this.onLoginPressed}
                    focusPriority={isLoginError ? 1 : undefined}
                  />
                  {enabledForgotCredentials && isBigScreen && forgotCredentialsButton}
                  {isRegistrationEnabled && (
                    <>
                      <Divider label={t('common.or')} />
                      <FormButton
                        border
                        testID='button_register'
                        text={t('credentials.registerAccount', {postProcess: buttonTextPostProcessor})}
                        onPress={this.onRegisterPressed}
                        style={styles.buttonRegister}
                      />
                    </>
                  )}
                  {this.enabledBOSelection && isChangeBoButtonVisible && (
                    <FormButton
                      testID='BOChangeButton'
                      text={t('credentials.changeBO', {postProcess: buttonTextPostProcessor})}
                      onPress={this.showBackOfficePicker}
                    />
                  )}
                </NitroxInteractiveController>
              </FocusParent>
            </KeyboardAvoidingView>
            <Animated.View
              style={[
                styles.notificationContainer,
                {opacity: notification ? 1 : this.errorNotificationOpacity}
              ]}
            >
              {errorMessage && <LoginErrorNotification message={errorMessage} />}
              {!!notification && <LoginErrorNotification message={notification} />}
            </Animated.View>
            <FocusParent>
              <OfflinePopup
                onTryAgain={this.onTryAgainLoginPressed}
                onClose={this.closeOfflinePopup}
                visible={isOfflinePopupVisible}
              />
            </FocusParent>
            {enabledForgotCredentials && (
              <ForgotCredentials
                visible={isForgotCredentialsPopupVisible}
                onClose={this.hideForgotCredentialsPopup}
                link={forgotCredentialsLink}
                title={forgotCredentialsTitle}
                message={forgotCredentialsMessage}
              />
            )}
            {this.enabledBOSelection && isChangeBoButtonVisible && (
              <BackOfficeSelect
                onBoChange={this.onBoChange}
                visible={isBackOfficePickerVisible}
                onClose={this.hideBackOfficePicker}
                logoutOnChange
              />
            )}
          </FocusParent>
        </TestContext.Provider>
      </NitroxScreen>
    );
  }
}

export default withFocusParent<'screenFocusParent'>('screenFocusParent')(withTranslation()(CredentialsScreen));
