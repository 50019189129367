import {createStyles} from 'common-styles';
import React, {forwardRef, ReactNode} from 'react';
import {View, ScrollView, ViewStyle, TextStyle, ActivityIndicator, StyleProp} from 'react-native';

import {dimensions} from 'common/constants';

import FocusParent from 'components/FocusParent';

import ConditionalWrapper from './ConditionalWrapper';
import NitroxButton from './NitroxButton';
import {NitroxButtonProps} from './NitroxButton';

export type NitroxButtonsMenuAction = NitroxButtonProps;

type Props = {
  actions: NitroxButtonsMenuAction[];
  loading?: boolean;
  containerStyle?: ViewStyle;
  buttonsStyle?: ViewStyle;
  buttonsStyleSelected?: ViewStyle;
  textsStyle?: TextStyle;
  textsStyleNotSelected?: TextStyle;
  borderButtons?: boolean;
  scrolled?: boolean;
  children?: ReactNode;
  activityIndicatorStyle?: StyleProp<ViewStyle>;
};

const styles = createStyles({
  wrapper: {
    paddingTop: 18
  },
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  staticContainer: {
    flexDirection: 'row',
    justifyContent: 'center'
  },
  buttonsStyle: {
    marginRight: dimensions.margins.large
  }
});

function renderScrollViewWrapper(children: ReactNode) {
  return (
    <ScrollView
      contentContainerStyle={styles.container}
      horizontal={true}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      alwaysBounceVertical={false}
    >
      {children}
    </ScrollView>
  );
}

const NitroxButtonsMenu: React.FunctionComponent<Props> = (props, ref) => {
  const {scrolled = true} = props;
  // TODO: CL-292 Add some fancy animations later
  return (
    <FocusParent style={[styles.wrapper, !scrolled && styles.staticContainer, props.containerStyle]}>
      {props.loading ? <ActivityIndicator style={props.activityIndicatorStyle} /> : (
        <>
          <ConditionalWrapper
            condition={scrolled}
            wrapper={renderScrollViewWrapper}
          >
            <React.Fragment>
              {props.actions.map((action: NitroxButtonsMenuAction, index: number) => {
                return (
                  <View key={index}>
                    <NitroxButton
                      testID={`buttons_menu_item_${index}`}
                      border={props.borderButtons}
                      ref={index === 0 ? ref : undefined}
                      {...action}
                      key={'action' + index}
                      style={[styles.buttonsStyle, props.buttonsStyle, action.isSelected && props.buttonsStyleSelected]}
                      textStyle={[props.textsStyle, !action.isSelected && props.textsStyleNotSelected]}
                    />
                  </View>
                );
              })}
            </React.Fragment>
          </ConditionalWrapper>
          {props.children}
        </>
      )}
    </FocusParent>
  );
};

export default forwardRef(NitroxButtonsMenu);
