import {ContentQueryParameters} from 'mw/common/ContentCache';

import {Channel, Event, Media, VodSorting, ChannelSorting, OrderStatus, Order} from './Metadata';

export {RecommendationsSource, RecommendationsQueryParameters, ContentQueryParameters, SpecialFilter} from 'mw/common/ContentCache';

export interface EPGParams {
  channels: Channel[];
  startTime: Date;
  endTime: Date;
  includeIsRecorded?: boolean;
  refreshing?: boolean;
  [propName: string]: any;
}

export type EPGResponse = Map<string, Event[]>;

export type SearchField = 'title' | 'castAndCrew' | 'description';
export enum SearchSource {
  Vod = 'vod',
  Channel = 'channel',
  Epg = 'epg'
}

export type SearchParameters = {
  term: string;
  sources: SearchSource[];
  searchFields?: SearchField[];
  channelSorting?: ChannelSorting;
  vodSorting?: VodSorting;
};

export type SearchResultType = 'channel' | 'vod' | 'epgPast' | 'epgNow' | 'epgFuture' | 'epgPvr';

export type SearchResult = {
  [source in SearchResultType]?: Media[];
};

export enum CatalogEvent {
  ChannelsListRefreshed = 'ChannelsListRefreshed',
  EPGRefreshed = 'EPGRefreshed',
  EventsIsRecordedUpdated = 'EventsIsRecordedUpdated',
  ChannelNotAvailableByPolicy = 'ChannelNotAvailableByPolicy',
  MediaNotFound = 'MediaNotFound'
}

export enum CacheType {
  Channels,
  EPG,
  Content,
  Products
}

export type PurchaseMethodParams = {
  voucher?: string;
  load?: boolean;
  queryParameters?: ContentQueryParameters;
  orderId?: string;
  media?: Media;
  orderStatus?: OrderStatus;
  productId?: string;
  offerId?: string;
}

export type PurchaseResult = {
  result?: Media | AsyncIterableIterator<Media[]>;
  updatedOrder?: Order;
};

export interface StartPaymentParams {
  productId: string;
  assetUid?: string;
  paymentMethodId: string;
  currency: string;
  price: number;
}

