import fscreen from 'fscreen';
import {Platform, PerpectiveTransform} from 'react-native';
import DeviceInfo from 'react-native-device-info';
import {getInset} from 'react-native-safe-area-view';
import {getStatusBarHeight} from 'react-native-status-bar-height';

import {DebugFeaturesVars, TestAutomationVars, environment} from 'common/Environment';

import {SupportedKeys} from 'components/KeyEventManager';

import {WebSubtype} from './HelperTypes';
import PlatformOverride from './platform.json';

export enum AppRoutes {
  Home = 'home',
  Epg = 'epg',
  Zapper = 'zapper',
  Pvr = 'pvr',
  Vod = 'vod',
  VodCategory = 'vodcategory',
  Settings = 'settings',
  SettingsCustomer = 'settingscustomer',
  SettingsAppVersion = 'settingsappversion',
  SettingsBackOffice = 'settingsbackoffice',
  SettingsDeleteProfile = 'settingsdeleteprofile',
  SettingsParentalControl = 'settingsparentalcontrol',
  SettingsLanguage = 'settingslanguage',
  SettingsTimeAndDate = 'settingsTimeAndDate',
  SettingsName = 'settingsname',
  SettingsPin = 'settingspin',
  SettingsAccount = 'settingsaccount',
  SettingsHelp = 'settingshelp',
  CreateProfileWizard = 'createprofilewizard',
  SettingsRegisteredDevices = 'settingsregistereddevices',
  Search = 'search',
  SearchResultFull = 'searchresultfull',
  WatchList = 'watchlist',
  MediaPlayer = 'mediaplayer',
  MediaDetail = 'mediadetail',
  LauncherAppsGames = 'launcherAppsGames',
  Credentials = 'credentials',
  ChannelDetail = 'channeldetail',
  RecordingsFolder = 'recordingsFolder',
  Registration = 'registration',
  SettingsOrders = 'settingsOrders',
  OrderDetails = 'orderDetails',
  MediaGrid = 'MediaGrid'
}

export const MAX_LOGIN_USERNAME_LENGTH = 30;
export const RADIUS = 4;
export const translationsVersion = '1';

export enum PlatformOverrides {
  Tizen = 'tizen',
  WebOS = 'webos',
  PhoneBrowser = 'phonebrowser',
  TabletBrowser = 'tabletbrowser',
  STBBrowser = 'stbbrowser'
}
export const platformOverride = PlatformOverride.platform?.toLowerCase();

/* eslint-disable @typescript-eslint/no-explicit-any */
const isPlatformOverriden = Object.values(PlatformOverrides).includes(platformOverride as any);
/* eslint-enable @typescript-eslint/no-explicit-any */

export const isTVOS = Platform.OS === 'ios' && Platform.isTV;
export const isIOS = Platform.OS === 'ios';
export const isAndroid = Platform.OS === 'android';
export const isATV = isAndroid && Platform.isTV;
export const isTablet = DeviceInfo.isTablet() || platformOverride === PlatformOverrides.TabletBrowser;
export const isWeb = Platform.OS === 'web';
export const isTizen = isWeb && platformOverride === PlatformOverrides.Tizen;
export const isWebOS = isWeb && platformOverride === PlatformOverrides.WebOS;
export const isSmartTV = isTizen || isWebOS || !!Platform.isTV;
export const isDesktopBrowser = isWeb && !isSmartTV && !isPlatformOverriden; // browser version
export const isMobileBrowser = isWeb && !isSmartTV && (platformOverride === PlatformOverrides.PhoneBrowser || platformOverride === PlatformOverrides.TabletBrowser); // mobile simulation in browser
/**
 * All web-based platforms except simulated mobile web. Use instead `isWeb` for mobile TA breaking code.
 */
export const isNonMobileWeb = isWeb && !isMobileBrowser;
export const isSTBBrowser = isWeb && !isSmartTV && platformOverride === PlatformOverrides.STBBrowser; // stb simulation in browser
export const isBigScreen = isDesktopBrowser || isSTBBrowser || isSmartTV;
export const isMobile = !isBigScreen;
export const isPhone = isMobile && !isTablet;
export const DOT_SEPARATOR = '•';
export const WEB_ARROW_CONTAINER_WIDTH = 62;

export enum CurrentPlatform {
  Tizen = 'tizen',
  WebOS = 'webos',
  Web = 'web',
  iOS = 'iOS',
  Android = 'android',
  AndroidLauncher = 'androidLauncher',
  AndroidTv = 'androidtv'
}

export const usingNitroxFocusEngine = isWeb || isATV;

// use it to move UI below navigation bar, SafeAreaView doesn't include this
export const navigationBarHeight = Platform.OS === 'android' ? 56 : 44;

// just "high enough" value, there's no focusPriority limit value
export const highestFocusPriority = 9001;

// remove when implemented
export const featureFlags = {
  episodeTileMoreInfoButton: false, // TODO: CL-1700
  recordingsFilterSelection: false, // TODO: CL-1682 and CL-1692
  recordingsSortingSelection: false, // TODO: CL-259 and CL-1231, sorting is not described in AC of main recording screen tickets
  ppvEntitlementTag: false, // TODO: CL-1571, CL-1470
  channelLists: false, // TODO: CL-1678
  flatEPGTiles: true, //TODO: CL-3485 enable round EPGTiles or disable them just for some platforms,
  wrapAround: false
};

export enum Direction {
  Left = SupportedKeys.Left,
  Right = SupportedKeys.Right,
  Up = SupportedKeys.Up,
  Down = SupportedKeys.Down
}
export function isHorizontal(direction: Direction): boolean {
  return [Direction.Left, Direction.Right].includes(direction);
}

export enum Axis {
  X = 'x',
  Y = 'y'
}

export const directionToAxis: Record<Direction, Axis> = {
  [Direction.Left]: Axis.X,
  [Direction.Right]: Axis.X,
  [Direction.Up]: Axis.Y,
  [Direction.Down]: Axis.Y
};

export function getTADateFormat(): string {
  return 'YYYY-MM-DD_HH:mm';
}

/**
 * @param webSubtype Overrides platform for web.
 * Should be removed from arguments and checked internally when full application RWD is implemented.
 */
export function getValue<T>(obj: {tv?: T; desktopBrowser?: T; mobile?: T; tablet?: T; defaultValue?: T}, webSubtype: WebSubtype = 'bigScreen'): T {
  const isBigBrowser = isDesktopBrowser && webSubtype === 'bigScreen';
  const isTabletBrowser = isDesktopBrowser && webSubtype === 'tablet';
  const isPhoneBrowser = isDesktopBrowser && webSubtype === 'phone';

  let retValue: T | undefined;
  if (isSmartTV || isSTBBrowser) {
    retValue = typeof obj.tv !== 'undefined' ? obj.tv : obj.defaultValue;
  } else if (isBigBrowser) {
    retValue = typeof obj.desktopBrowser !== 'undefined' ? obj.desktopBrowser : obj.defaultValue;
  } else if (isTablet || isTabletBrowser) {
    if (typeof obj.tablet !== 'undefined') {
      retValue = obj.tablet;
    } else if (typeof obj.mobile !== 'undefined') {
      retValue = obj.mobile;
    } else {
      retValue = obj.defaultValue;
    }
  } else if (isMobile || isPhoneBrowser) {
    retValue = typeof obj.mobile !== 'undefined' ? obj.mobile : obj.defaultValue;
  }
  if (typeof retValue !== 'undefined') {
    return retValue;
  }
  throw new Error('setDimension: Please specify a value!!!');
}

export const font = {
  light: 'FiraSans-Light',
  regular: 'FiraSans-Regular',
  medium: 'FiraSans-Medium',
  semiBold: 'FiraSans-SemiBold',
  bold: 'FiraSans-Bold',
  extraBold: 'FiraSans-Bold',
  extraCondensed: 'FiraSansExtraCondensed-Regular'
};

export const eventTileDimensions = {
  height: {
    mobile: 56,
    tablet: 80
  }
};

export const dimensions = (() => {
  const base = {
    icon: {
      micro: 8,
      xxxsmall: 12,
      xxsmall: 20,
      xsmall: 24,
      small: 30,
      medium: 40,
      large: 60,
      xlarge: 80,
      xxLarge: 100,
      xxxLarge: 125
    },
    margins: {
      xsmall: 5,
      small: 10,
      medium: 15,
      large: 20,
      xLarge: 25,
      xxLarge: 30,
      xxxLarge: 40,
      xxxxLarge: 70
    },
    buttons: {
      xsmall: 25,
      small: getValue({mobile: 30, defaultValue: 50}),
      standard: getValue({mobile: 35, defaultValue: 50}),
      large: isBigScreen ? 60 : 50,
      xLarge: 75
    },
    pictures: {
      channelLogo: {
        channelListMagnified: getValue({tablet: 76, mobile: 50, defaultValue: 0}),
        channelListSelector: getValue({tablet: 55, mobile: 34, defaultValue: 0}),
        detail: getValue({tablet: 70, mobile: 50, defaultValue: 100}),
        epg: getValue({tablet: 100, mobile: 50, defaultValue: 120}),
        tile: getValue({mobile: 34, defaultValue: 50}),
        zapper: getValue({tablet: 70, mobile: 65, defaultValue: 100})
      },
      detailPage: {
        default: {
          width: getValue({tablet: 834, mobile: 375, defaultValue: 1920}),
          height: getValue({tablet: 469, mobile: 375, defaultValue: 1080})
        },
        tabletLandscape: {
          width: 1112,
          height: 626
        }
      },
      epgPopup: {
        width: getValue({tablet: 329, mobile: 0, defaultValue: 574}),
        height: getValue({tablet: 185, mobile: 0, defaultValue: 322})
      },
      highlightedBannerFocused: {
        width: getValue({mobile: 0, defaultValue: 800}),
        height: getValue({mobile: 0, defaultValue: 600})
      },
      highlightedBannerUnfocused: {
        width: getValue({mobile: 0, defaultValue: 400}),
        height: getValue({mobile: 0, defaultValue: 600})
      },
      mobileTile: {
        default: {
          width: getValue({mobile: 126, defaultValue: 0}),
          height: getValue({mobile: 71, defaultValue: 0})
        },
        series: {
          width: getValue({tablet: 190, mobile: 126, defaultValue: 0}),
          height: getValue({tablet: 107, mobile: 71, defaultValue: 0})
        }
      },
      promotionalBanner: {
        vod: {
          default: {
            width: getValue({tablet: 834, mobile: 375, defaultValue: 835}),
            height: getValue({tablet: 469, mobile: 500, defaultValue: 470})
          },
          tabletLandscape: {
            width: 1113,
            height: 626
          }
        }
      },
      standardTile: {
        default_16_9: {
          width: getValue({mobile: 234, defaultValue: 416}),
          height: getValue({mobile: 132, defaultValue: 234})
        },
        tabletLandscapeBinge_16_9: {
          width: 329,
          height: 185
        }
      }
    }
  };

  const swimline = {
    title: {
      height: getValue({mobile: 30, defaultValue: 50})
    }
  };

  const notificationBox = {
    width: 440,
    height: 85,
    icon: {
      size: 48
    }
  };

  const tile = {
    width: getValue({mobile: 234, defaultValue: 440}),
    height: getValue({mobile: 174, defaultValue: 320}),
    progress: {
      height: 4,
      width: 100
    },
    checkboxSize: 30,
    iconHeight: getValue({mobile: 72, defaultValue: 120}),
    borderWidth: getValue({mobile: 3, defaultValue: 8}),
    borderPadding: 7,
    borderRadius: getValue({mobile: RADIUS, defaultValue: 20}),
    posterBig: {
      width: 390,
      height: 220
    },
    poster: {
      width: 126,
      height: 70
    },
    channelIcon: {
      width: 60,
      height: 60,
      borderRadius: 15
    },
    descriptionHorizontalPadding: base.margins.medium
  };

  const swimlaneWithHighlightedBanner = {
    tileWidth: getValue({defaultValue: 400}),
    focusedTileWidth: getValue({defaultValue: 800}),
    tileHeight: getValue({defaultValue: 600}),
    tileBorderWidth: 8,
    nameBottomOffset: 30
  };

  const channelTile = {
    logoWidthRatio: 0.8,
    logoHeightRatio: isBigScreen ? 0.33 : 0.6
  };

  const menuTile = {
    circleTileWidth: getValue({defaultValue: 220}),
    rectangleTileWidth: getValue({defaultValue: 320}),
    tileHeight: getValue({defaultValue: 280}) + 2 * (tile.borderWidth + tile.borderPadding),
    rectangleTileBorderRadius: 20,
    borderWidth: tile.borderWidth,
    borderPadding: tile.borderPadding
  };

  const epg = {
    /** Minimum width of EPG Tile's content (without the rounded borders) expressed in logical pixels.*/
    minTileContentWidth: 2,
    tileRadius: RADIUS,
    playerWidth: 576,
    pcIconSize: 120,
    detailsPopup: {
      width: {
        bigScreen: 1440,
        tabletLandscape: 840,
        tabletPortrait: 755
      },
      button: {
        borderRadius: RADIUS,
        borderWidth: 1,
        iconSize: getValue({tablet: 18, defaultValue: 27})
      }
    },
    timeBarHeight: 65,
    timeBarCurrentTimeHeight: 30,
    timeLine: {
      width: isMobile ? 1 : 2,
      top: 10,
      height: isMobile ? 45 : 65
    }
  };
  const tabBar = {
    height: isTablet ? 59 : 56,
    stbExpandedWidth: 375
  };
  const popup = {
    padding: 31,
    radius: isBigScreen ? 10 : 0,
    actionHeight: 55,
    // popups aren't designed consistently - these are average values. Adjust it with styles if required
    width: getValue({mobile: 335, defaultValue: 852}),
    button: {
      width: 250,
      height: 50,
      language: {
        minWidth: 150
      }
    },
    icon: {
      close: {
        size: 10
      }
    },
    checkbox: {
      size: 21
    }
  };
  const videoSize = (width: number) => ({width, height: width * 9 / 16});

  const opacity = {
    xxxlow: 0.1,
    xxlow: 0.2,
    xlow: 0.3,
    medium: 0.5,
    high: 0.6,
    xhigh: 0.7,
    xxhigh: 0.8,
    xxxhigh: 0.9,
    xxxxhigh: 0.96,
    default: 1
  };

  const button = {
    selected: {
      borderWidth: 3
    }
  };

  const checkbox = {
    padding: 3
  };

  const actionButton = {
    regular: {
      minWidth: 180
    },
    short: {
      minWidth: 50
    },
    iconSize: {
      small: getValue({tablet: 38, mobile: 35, defaultValue: 18}),
      big: getValue({tablet: 38, mobile: 35, defaultValue: 24})
    }
  };

  const backButton = {
    size: getValue({mobile: 20, tv: 62, desktopBrowser: 62, defaultValue: 0})
  };

  const forwardButton = {
    minWidth: 80
  };

  const mainMenu = {
    marginHorizontal: 100,
    marginTop: 60,
    focusLineHeight: 4,
    height: 182
  };

  const screen = {
    header: {
      height: isBigScreen ? 180 : 53
    },
    container: {
      ...isBigScreen ? {
        paddingTop: base.margins.xsmall,
        paddingHorizontal: mainMenu.marginHorizontal // to match with the main menu right edge
      } : {
        paddingTop: 0,
        paddingHorizontal: 0
      }
    },
    settings: {
      container: {
        paddingTop: isBigScreen ? 100 : base.margins.xxLarge
      }
    },
    recordings: {
      grid: {
        marginTop: base.margins.xxxLarge
      }
    }
  };

  const logo = {
    width: isBigScreen ? 210 : 169,
    height: isBigScreen ? 70 : 40,
    credentials: {
      width: isBigScreen ? 400 : 220,
      height: isBigScreen ? 240 : 132
    }
  };

  const inputHeight = isBigScreen ? 75 : 50;
  const inputFocusBorder = isBigScreen ? 2 : 0;
  const inputs = {
    height: inputHeight,
    focusBorder: inputFocusBorder,
    padding: isBigScreen ? base.margins.xxLarge : base.margins.large,
    icons: {
      clearSearch: {
        size: 12,
        padding: 20
      },
      edit: {
        size: 20,
        padding: 20
      },
      error: {
        iconSize: Math.floor(inputHeight / 4),
        backgroundSize: Math.floor(inputHeight / 2),
        innerPadding: Math.floor(inputHeight / 4)
      }
    },
    width: {
      search: (isBigScreen ? 805 + 2 * inputFocusBorder : 310),
      settings: (isBigScreen ? 800 + 2 * inputFocusBorder : 305),
      login: (isBigScreen ? 600 + 2 * inputFocusBorder : 400)
    }
  };

  const shadows = {
    small: 15,
    large: 50
  };

  const notch = {
    height: getStatusBarHeight()
  };

  const progressTracker = {
    height: isBigScreen ? 55 : 25
  };

  const registration = {
    errorPopup: {
      width: 1120
    },
    maxScreenWidth: getValue({mobile: undefined, tablet: 675, defaultValue: 905})
  };

  const wizard = {
    title: {
      height: 50
    },
    configScreen: {
      horizontalPadding: isBigScreen ? 400 : 0,
      doubleBackgroundOverlap: 25
    },
    summaryScreen: {
      header: {
        marginTop: getValue({mobile: 30, tablet: 0, defaultValue: 0})
      },
      content: {
        width: isBigScreen ? 500 : 300
      },
      profileSwitcher: {
        margin: isBigScreen ? 130 : 70,
        actionButton: {
          minWidth: 200
        },
        icon: isBigScreen ? 225 : 150
      }
    },
    parentalControl: {
      button: {
        width: getValue({mobile: 140, tablet: 140, defaultValue: 165})
      }
    }
  };

  const toggleSwitch = {
    height: isBigScreen ? 70 : 45,
    width: isBigScreen ? 135 : 90,
    focused: {
      borderWidth: 4
    },
    label: {
      width: isBigScreen ? 773 : 'auto'
    }
  };

  const applicationTile = {
    width: 320,
    height: 180,
    borderWidth: 20,
    labelHeight: 70
  };

  const playerMediaDetails = {
    width: '36%',
    containerHeight: 403,
    smallContainerHeight: 360
  };

  const channelList = {
    container: {
      top: 60,
      paddingTop: 0.3
    }
  };

  const player = {
    unlockButton: {
      minWidth: 250,
      height: 50
    },
    progressBar: {
      default: {
        expandedHeight: getValue({tablet: 51, mobile: 26, defaultValue: 3}),
        progressHeight: 3
      },
      chromecast: {
        progressHeight: 2,
        textContainerStaticWidth: 60
      }
    }
  };

  const mediaInfoView = {
    view: {
      marginTop: 150
    },
    progressBar: {
      width: 670
    },
    synopsis: {
      width: 753
    },
    channelIconContainer: {
      height: isTablet ? 350 : 260
    }
  };

  const eventTile = {
    height: getValue({
      mobile: eventTileDimensions.height.mobile,
      tablet: eventTileDimensions.height.tablet,
      defaultValue: eventTileDimensions.height.mobile
    })
  };

  const payments = {
    product: {
      tile: {
        width: 334,
        height: 354,
        borderWidth: 3
      }
    },
    order: {
      contentWidth: 904
    }
  };

  const statusLabel = {
    width: 114,
    height: 30
  };

  const table = {
    borderRadius: RADIUS,
    head: 30,
    rowHeight: 80,
    separator: 40
  };

  const chromecast = {
    floatingButton: {
      bottom: 71,
      right: 10
    },
    statusBar: {
      height: 79
    },
    remoteControl: {
      wrapper: {
        bottom: 56
      },
      container: {
        maxHeight: 375
      },
      collapseIcon: {
        width: 9
      },
      expanded: {
        innerContainer: {
          maxWidth: 400
        },
        iconTop: {
          height: 54
        }
      },
      mediaInfo: {
        bottom: 50
      }
    },
    volumeBar: {
      height: getValue({tablet: 51, mobile: 26, defaultValue: 3}),
      barHeight: 3
    }
  };

  const mobilePoster = {
    height: getValue({tablet: 470, mobile: 382, defaultValue: 470})
  };

  const sideMenu = {
    width: 380,
    widthCollapsed: 20
  };

  const playerButton = {
    iconSize: 33
  };

  const gradientBackground = {
    rotation: -15,
    scaleX: 1.2,
    scaleY: 1.1
  };

  return {
    ...base,
    notificationBox,
    tile,
    swimlaneWithHighlightedBanner,
    channelTile,
    menuTile,
    epg,
    swimline,
    tabBar,
    popup,
    videoSize,
    opacity,
    button,
    checkbox,
    actionButton,
    backButton,
    forwardButton,
    screen,
    inputs,
    shadows,
    notch,
    progressTracker,
    registration,
    wizard,
    toggleSwitch,
    applicationTile,
    playerMediaDetails,
    channelList,
    player,
    mediaInfoView,
    eventTile,
    logo,
    payments,
    statusLabel,
    table,
    chromecast,
    mobilePoster,
    sideMenu,
    mainMenu,
    playerButton,
    gradientBackground
  };
})();

// EPG limits are expressed in hours
export const epgLimits = {
  oneChannel: {
    start: Platform.isTV ? 0 : 24,
    end: Platform.isTV ? 2 : 24,
    timespanPerRequest: 8
  },
  /** Minimum event's duration expressed in seconds that should have graphical representation in EPG grid. */
  minVisibleEventDuration: 90
};

export const mainMenuWidth = {
  expanded: dimensions.tabBar.stbExpandedWidth,
  semiCollapsed: 95,
  collapsed: 25
};
// STBMenu real width is increased by this offset, so that FocusManager
// treats the FocusParent that wraps active screen as a sibling to the
// FocusParent that wraps STBMenu, this way, moving right from STBMenu
// automatically focuses screen's children,
// this is unnecessary on tvOS and causes problems with focus
export const menuFocusParentOffset = isTVOS ? 0 : dimensions.tabBar.stbExpandedWidth * 2;

// thanks to initial value - it stays true for non mobile platforms, returns zero-size layout
export const initialTabBarLayout = {
  x: 0,
  y: 0,
  width: 0,
  height: 0
};

const zapperHideTimeout = (() => {
  if (isSTBBrowser) {
    return 10;
  }

  // tvos and desktop browser render overlay much faster than atv, tizen, webos
  // extra 2 seconds is added to compensate delay
  return isTVOS || isDesktopBrowser
    ? 3
    : 5;
})();

export const playerOverlayConfig = {
  hideTimeout: zapperHideTimeout,
  vodHideTimeout: 3,
  updateInterval: 1
};

export const debugFeatures = {
  focusGuide: false || environment.getBoolean(DebugFeaturesVars.focusGuide),
  focusManager: false || environment.getBoolean(DebugFeaturesVars.focusManager),
  epgFocusDriver: false || environment.getBoolean(DebugFeaturesVars.epgFocusDriver),
  focusParent: false || environment.getBoolean(DebugFeaturesVars.focusParent),
  taFocusReporter: false || environment.getBoolean(DebugFeaturesVars.taFocusReporter),
  /**
   * Currently supported only on web.
   * When true, "i" key simulates ChannelUp, "k" key simulates ChannelDown.
   */
  channelKeys: false || environment.getBoolean(DebugFeaturesVars.channelKeys)
};

export const testAutomationFeatures = {
  enableLogsForTesting: environment.getBoolean(TestAutomationVars.enableLogsForTesting),
  deviceIdOverride: environment.getString(TestAutomationVars.deviceIdOverride),
  deviceNameOverride: environment.getString(TestAutomationVars.deviceNameOverride),
  exposeMiddleware: environment.getBoolean(TestAutomationVars.exposeMiddleware),
  enableFocusReporter: environment.getBoolean(TestAutomationVars.enableFocusReporter)
};

export const epgItemHeight = isMobile ? 110 : 130;

export const notificationDuration = 7000;

export const defaultPageSize = 20;

export const defaultThrottle = 500;

export const tabBarInsetCorrection = !isWeb ? Math.floor(getInset('bottom', false) / 2) : 0;

/**
 * Based on https://reactnative.dev/docs/0.51/animations not setting this altogether causes weird behaviour.
 * Additionally on Web in scenerios where there are two visually overlapping animations having a perspective transform (value does not even matter)
 * will cause the last one to have no effect at all.
 */
export const commonAnimationTransforms: PerpectiveTransform[] = isWeb
  ? []
  : [{perspective: 1000}];

export const showButtonAnimationLength = 800;

export const hideButtonAnimationLength = 300;

/**
 * Indicates whether or not full-screen mode is available. Do not be misled by the fullscreenEnabled property name here,
 * for more info please read https://github.com/rafgraph/fscreen#readme and https://developer.mozilla.org/en-US/docs/Web/API/Document/fullscreenEnabled
 */
export const isFullScreenModeSupported = isDesktopBrowser && fscreen.fullscreenEnabled;

export const homescreenPromotionalBannerOverlap = 150;

