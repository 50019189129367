import {createStyles, defaultStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {useTranslation, withTranslation, WithTranslation} from 'react-i18next';
import {ActivityIndicator, Dimensions, ListRenderItemInfo, StyleSheet, View} from 'react-native';
import {NavigationScreenProps} from 'react-navigation';

import {disposable} from 'common/Async';
import {dimensions, isBigScreen, isMobile, isTablet} from 'common/constants';
import {indexKeyExtractor} from 'common/HelperFunctions';
import {ScreenSizeBasedOrientation} from 'common/HelperTypes';
import {Log} from 'common/Log';
import TestContext from 'common/TestContext';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Device} from 'mw/api/Device';
import {Error, ErrorType} from 'mw/api/Error';
import {mw} from 'mw/MW';

import Checkbox from 'components/Checkbox';
import DeleteSelectionMenu, {DeleteSelectionMenuActionKey} from 'components/deleteSelectionMenu/DeleteSelectionMenu';
import ErrorPopup, {PopupError} from 'components/ErrorPopup';
import {FocusManager} from 'components/focusManager/FocusManager';
import {FocusableComponent} from 'components/focusManager/FocusManagerTypes';
import FocusParent from 'components/FocusParent';
import {Icon, IconType} from 'components/Icon';
import NitroxButton, {NitroxButtonTheme} from 'components/NitroxButton';
import NitroxFlatList from 'components/NitroxFlatList';
import NitroxInteractive from 'components/NitroxInteractive';
import {NitroxInteractiveController} from 'components/NitroxInteractiveControllerContext';
import NitroxText from 'components/NitroxText';
import Popup, {PopupAction} from 'components/Popup';
import {withUnlockModal, WithUnlockModal, UnlockModalRejectionReason} from 'components/unlockmodal/UnlockModal';
import {hasAll, hasNone, Selection} from 'components/utils/Selection';
import {getScreenInfo} from 'hooks/Hooks';
import SettingsDetails from 'screens/settings/SettingsDetails';

const TAG = 'SettingsRegisteredDevicesScreen';

const imageSize = 100;
const registerButtonWidth = 305;
const registerButtonHeight = 50;
const bigScreenItemWidth = 500;
const focusBorderWidth = 2;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  iconContainer: {
    height: imageSize,
    width: imageSize * 2,
    justifyContent: 'center',
    alignItems: 'center'
  },
  editButtonsContainer: {
    flexDirection: 'row'
  },
  devicesList: {
    ...isBigScreen ? {
      marginTop: dimensions.margins.xxLarge,
      marginBottom: dimensions.margins.xxLarge,
      alignSelf: 'center'
    } : {
      marginTop: dimensions.margins.xLarge,
      marginHorizontal: dimensions.margins.xLarge
    }
  },
  listItemContainer: {
    paddingLeft: isBigScreen ? dimensions.margins.large : dimensions.margins.xsmall,
    paddingRight: isBigScreen ? 0 : dimensions.margins.xsmall,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    height: dimensions.buttons.standard,
    marginVertical: dimensions.margins.xsmall,
    overflow: 'hidden',
    ...isBigScreen ? {
      width: bigScreenItemWidth
    } : {
      flex: 1
    }
  },
  textContainer: {
    borderRadius: 3,
    paddingLeft: dimensions.margins.medium,
    paddingRight: dimensions.margins.xLarge,
    marginRight: focusBorderWidth,
    height: dimensions.buttons.standard,
    flexGrow: 1,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: colors.settingsScreen.mobile.device.listItem.background,
    width: '100%'
  },
  textContainerFocused: {
    borderWidth: focusBorderWidth,
    borderColor: colors.settingsScreen.mobile.device.listItem.label,
    marginLeft: -focusBorderWidth,
    marginRight: 0
  },
  activityIndicatorContainer: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center'
  },
  listItemText: {
    color: colors.settingsScreen.mobile.device.listItem.label,
    justifyContent: 'flex-start',
    textAlign: 'left',
    marginLeft: dimensions.margins.small,
    flexGrow: 1
  },
  operatorManaged: {
    backgroundColor: colors.settingsScreen.mobile.device.listItem.operator
  },
  bigScreenDeleteDeviceButton: {
    height: dimensions.buttons.standard,
    marginTop: dimensions.margins.xxxLarge,
    alignSelf: 'center'
  },
  bigScreenEditButtonsContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginBottom: dimensions.margins.xxLarge
  },
  bigScreenDeleteDevicesButton: {
    height: dimensions.buttons.standard,
    alignSelf: 'center',
    marginRight: dimensions.margins.xxLarge
  },
  bigScreenCancelButton: {
    height: dimensions.buttons.standard,
    alignSelf: 'center'
  },
  messageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: dimensions.margins.xxLarge
  },
  message: {
    color: colors.popup.text
  },
  registerButton: {
    width: registerButtonWidth,
    height: registerButtonHeight,
    alignSelf: 'center',
    marginBottom: dimensions.margins.small,
    marginTop: dimensions.margins.xLarge
  },
  registerText: {
    color: colors.settingsScreen.mobile.device.buttonDescription,
    alignSelf: 'center',
    marginBottom: dimensions.margins.xLarge
  },
  popupMenu: {
    ...isBigScreen && {
      justifyContent: 'center'
    }
  },
  popupMenuButton: {
    ...isBigScreen && {
      width: 'auto',
      marginHorizontal: dimensions.margins.small
    }
  },
  listIconColor: colors.settingsScreen.mobile.device.listItem.current,
  tabletTitle: {
    right: 300
  }
}));

type DeviceUnregistrationErrorPopupProps = {
  error: ErrorType | null;
  closePopup: () => void;
};

const testIdContext = (error?: ErrorType | null) => {
  let suffix: string;
  switch (error) {
    case ErrorType.TooManyDeviceSwaps:
      suffix = 'too_many_devices';
    case ErrorType.IncorrectPin:
      suffix = 'incorrect_pin';
    default:
      suffix = 'delete_registered_devices';
  }

  return {Modal: `modal_${suffix}`};
};

const DeviceUnregistrationErrorPopup: React.FC<DeviceUnregistrationErrorPopupProps> = props => {
  const {error, closePopup} = props;
  const {t} = useTranslation();
  const errorPopup = useMemo<PopupError | null>(() => {
    if (error === null) {
      return null;
    }

    switch (error) {
      case ErrorType.TooManyDeviceSwaps:
        return {title: t('tooManyDeviceSwapsPopup.title'), message: t('tooManyDeviceSwapsPopup.message')};
      case ErrorType.IncorrectPin:
        return {title: t('incorrectPinPopup.title'), message: t('incorrectPinPopup.message')};
      default:
        return {title: t('settings.deleteDevicesErrorPopup.title'), message: t('settings.deleteDevicesErrorPopup.message')};
    }
  }, [error, t]);

  return (
    <TestContext.Provider value={testIdContext(error)}>
      <ErrorPopup error={errorPopup} onClose={closePopup} />
    </TestContext.Provider>
  );
};

type DevicesCollection = {
  devices: Device[];
  hasMoreDevices: boolean;
  isLoading: boolean;
  selectable: boolean;
  selection: Selection<Device>;
};

type Props = WithUnlockModal & WithTranslation & NavigationScreenProps<{
  onBackButtonPressed?: () => boolean;
  register?: () => void;
}>;

type State = {
  currentDevice: Device | null;
  deleteConfirmationPopupVisible: boolean;
  error: ErrorType | null;
  orientation: ScreenSizeBasedOrientation;
  selectionMenuActions: DeleteSelectionMenuActionKey[];
  selectionMenuDisabledActions: DeleteSelectionMenuActionKey[];
  selectionMenuSelectedActions: DeleteSelectionMenuActionKey[];
} & DevicesCollection

function HeaderIcon() {
  const styles = stylesUpdater.getStyles();
  return (
    <View style={styles.iconContainer}>
      <Icon type={IconType.DeviceBig} size={imageSize} />
    </View>
  );
}

const popupActions = [PopupAction.POSITIVE, PopupAction.NEGATIVE];

class SettingsRegisteredDevicesScreen extends React.Component<Props, State> {
  private firstItemRef = React.createRef<FocusableComponent>();
  private deleteButtonRef = React.createRef<FocusableComponent>();

  private unregisterDevices = disposable((selectedDevices: Device[], pin: string) => {
    return mw.customer.unregisterDevices(selectedDevices, pin);
  });

  public constructor(props: Props) {
    super(props);
    this.state = {
      currentDevice: null,
      deleteConfirmationPopupVisible: false,
      devices: [],
      error: null,
      hasMoreDevices: true,
      isLoading: false,
      orientation: getScreenInfo().orientation,
      selectable: false,
      selection: new Selection<Device>(),
      selectionMenuActions: [DeleteSelectionMenuActionKey.OpenMenu],
      selectionMenuDisabledActions: [],
      selectionMenuSelectedActions: []
    };
  }

  public componentDidMount() {
    Dimensions.addEventListener('change', this.handleDimensionsChange);

    this.fetchDevices();
  }

  public componentWillUnmount() {
    this.unregisterDevices.dispose();
    Dimensions.removeEventListener('change', this.handleDimensionsChange);
  }
  private handleDimensionsChange = () => {
    this.setState({
      orientation: getScreenInfo().orientation
    });
  };

  private closeSelectionMode = () => {
    Log.debug(TAG, 'Closing delete confirmation popup, closing the selection menu and clearing the selection');
    this.setState(() => ({
      deleteConfirmationPopupVisible: false,
      selectable: false,
      selection: new Selection<Device>(),
      selectionMenuActions: [DeleteSelectionMenuActionKey.OpenMenu],
      selectionMenuDisabledActions: [],
      selectionMenuSelectedActions: []
    }));
  };

  private onSelectionCancelPress = () => {
    Log.debug(TAG, 'Hiding selection menu');
    this.closeSelectionMode();
    this.forceFocusFirstItem();
  };

  private onSelectionSelectAllPress = (selected: boolean) => {
    if (!this.state.devices.length) {
      Log.debug(TAG, 'There are no devices - ignoring request to select all of them');
      return;
    }
    Log.debug(TAG, (selected ? 'Selecting' : 'Deselecting') + ' all devices');
    this.setState(previousState => ({
      selection: previousState.selection.clone().selectAll(!selected),
      selectionMenuDisabledActions: selected ? [DeleteSelectionMenuActionKey.Delete] : [],
      selectionMenuSelectedActions: !selected ? [DeleteSelectionMenuActionKey.SelectAll] : []
    }));
  };

  private onOpenMenuPress = () => {
    Log.debug(TAG, 'Showing selection menu');
    this.setState({
      selectable: true,
      selectionMenuActions: [DeleteSelectionMenuActionKey.Cancel, DeleteSelectionMenuActionKey.SelectAll, DeleteSelectionMenuActionKey.Delete],
      selectionMenuDisabledActions: [DeleteSelectionMenuActionKey.Delete]
    });
  };

  private onSelectionDeletePress = () => {
    Log.debug(TAG, 'Showing delete confirmation popup');
    this.setState(previousState => ({
      selection: previousState.selection.clone(),
      deleteConfirmationPopupVisible: true
    }));
  };

  private onEdit = () => {
    this.setState({
      selectable: true
    });

    this.forceFocusFirstItem();
  };

  private forceFocusFirstItem = () => {
    FocusManager.getInstance().forceFocus(this.firstItemRef);
  };

  private fetchDevices = () => {
    this.setState({
      devices: [],
      isLoading: true
    });

    mw.customer.getRegisteredDevices()
      .then((devices: Device[]) => {
        this.setState({
          currentDevice: mw.customer.currentDevice,
          devices: devices,
          isLoading: false
        });

        devices.forEach((device: Device) => {
          Log.debug(TAG, `Device: ${device.name} Id: ${device.id} CustomerMng: ${device.isCustomerManaged} BackOfficeId: ${device.backOfficeId}`);
        });
      })
      .catch(error => {
        Log.error(TAG, 'Error fetching devices: ' + error);
        this.setState({isLoading: false});
      });
  };

  public selectDevice = (device: Device, selected: boolean) => {
    Log.debug(TAG, (selected ? 'Selecting' : 'Deselecting') + ' device with id ' + device.id);
    this.setState(previousState => {
      const selection = previousState.selection.clone().select(device, selected);
      return {
        selection,
        selectionMenuSelectedActions: hasAll(selection, previousState.devices, previousState.hasMoreDevices) ? [DeleteSelectionMenuActionKey.SelectAll] : [],
        selectionMenuDisabledActions: hasNone(selection, previousState.devices, previousState.hasMoreDevices) ? [DeleteSelectionMenuActionKey.Delete] : []
      };
    });
  };

  private renderListItem = (itemInfo: ListRenderItemInfo<Device>) => {
    const device = itemInfo.item;
    const currentDeviceId = this.state.currentDevice && this.state.currentDevice.id;
    const showCurrentDeviceIndicator = device.id === currentDeviceId;
    const isSelectable = this.state.selectable && device.isCustomerManaged && !showCurrentDeviceIndicator;
    const deviceSelected = this.state.selection.isSelected(device);
    const name = device.isCustomerManaged ? device.name : this.props.t('settings.operatorManaged');
    const ref = (itemInfo.index === 0) ? this.firstItemRef : null;

    const styles = stylesUpdater.getStyles();
    return (
      <View style={styles.listItemContainer}>
        <NitroxInteractive
          ref={ref}
          activeOpacity={1}
          style={[styles.textContainer, !device.isCustomerManaged && styles.operatorManaged]}
          styleFocused={isBigScreen && styles.textContainerFocused}
          onPress={() => isSelectable && this.selectDevice(device, !deviceSelected)}
          testID={`item_${itemInfo.index}`}
        >
          {isSelectable &&
            <Checkbox size={dimensions.icon.xxxsmall} selected={deviceSelected} />
          }
          <NitroxText textType='label' style={styles.listItemText} numberOfLines={1}>{name}</NitroxText>
          {showCurrentDeviceIndicator &&
            <Icon type={IconType.OvalDevice} color={styles.listIconColor} />
          }
        </NitroxInteractive>
      </View>
    );
  };

  private closePopup = () => {
    this.setState({deleteConfirmationPopupVisible: false});
  };

  private forceFocusDeleteButton = () => {
    FocusManager.getInstance().forceFocus(this.deleteButtonRef);
  }

  private onCancelDelete = () => {
    this.setState({deleteConfirmationPopupVisible: false}, this.forceFocusDeleteButton);
  }

  private onConfirmDelete = () => {
    this.closePopup();
    this.authorizeWithPin()
      .then(async (pin) => {
        try {
          const selectedDevices = this.getSelectedDevices();
          await this.unregisterDevices(selectedDevices, pin);
        } catch (error) {
          if (error.reason !== UnlockModalRejectionReason.Cancel) {
            Log.error(TAG, 'Failed to unregister device: ', error);
            this.setState({error: error.type});
          }
        } finally {
          this.closeSelectionMode();
          this.fetchDevices();
        }
      })
      .catch(error => {
        Log.error(TAG, 'Error when authorizing with pin: ', error);
        this.setState({error: ErrorType.IncorrectPin});
      });
  };

  private getSelectedDevices() {
    if (this.state.selection.areAllSelected()) {
      const availableDevices = this.state.devices.filter(device => device.id !== this.state.currentDevice?.id && device.isCustomerManaged);
      return this.state.selection.filterDeselectedItems(availableDevices);
    }
    return this.state.selection.getSelectedItems();
  }

  private closeErrorPopup = () => {
    this.setState({error: null});
  };

  private authorizeWithPin = (): Promise<string> => {
    if (!mw.customer.currentProfile?.isMain) {
      const mainProfile = mw.customer.mainProfile;
      return mainProfile ? this.props.authorizeProfile(mainProfile) : Promise.reject(new Error(ErrorType.IncorrectPin));
    }
    return this.props.authorizeWithCurrentProfile();
  };

  public render() {
    const t = this.props.t;
    const listColumns = (isTablet && this.state.orientation.isLandscape) || isBigScreen ? 2 : 1;
    const onBackButtonPressed = this.props.navigation.getParam('onBackButtonPressed');
    const register = this.props.navigation.getParam('register');
    const {orientation} = getScreenInfo();

    const selectionMenu = (
      <DeleteSelectionMenu
        actions={this.state.selectionMenuActions}
        disabledActions={this.state.selectionMenuDisabledActions}
        selectedActions={this.state.selectionMenuSelectedActions}
        onOpenMenuPress={this.onOpenMenuPress}
        onCancelPress={this.onSelectionCancelPress}
        onSelectAllPress={this.onSelectionSelectAllPress}
        onDeletePress={this.onSelectionDeletePress}
      />
    );

    const styles = stylesUpdater.getStyles();
    return (
      <>
        <SettingsDetails
          title={t('settings.registeredDevices')}
          barText={t('settings.registeredDevicesBarText')}
          header={<HeaderIcon />}
          containerStyle={defaultStyles.view}
          mobileHeader={{
            title: isMobile && orientation.isPortrait && this.state.selectable ? '' : t('settings.registeredDevices'),
            rightContent: selectionMenu,
            ...onBackButtonPressed && {showBackButton: true, onBackPress: onBackButtonPressed},
            titleStyle: isTablet && orientation.isLandscape && this.state.selectable ? styles.tabletTitle : undefined
          }}
          navigation={this.props.navigation}
          onBackButtonPressed={onBackButtonPressed}
          testID='screen_settings_registered_devices'
        >
          <NitroxInteractiveController omitGeometryCaching>
            <View style={styles.activityIndicatorContainer}>
              <ActivityIndicator animating={this.state.isLoading} />
            </View>
            {isBigScreen && !this.state.selectable && (
              <NitroxButton
                style={styles.bigScreenDeleteDeviceButton}
                text={t('settings.deleteDeviceButton')}
                onPress={this.onEdit}
                border
              />
            )}
            <NitroxFlatList
              key={listColumns}
              style={styles.devicesList}
              data={this.state.devices}
              renderItem={this.renderListItem}
              keyExtractor={indexKeyExtractor}
              extraData={this.state}
              numColumns={listColumns}
              testID='list_registered_devices'
            />
            {isBigScreen && this.state.selectable && (
              <View style={styles.bigScreenEditButtonsContainer}>
                <NitroxButton
                  ref={this.deleteButtonRef}
                  border
                  style={styles.bigScreenDeleteDevicesButton}
                  text={t('settings.deleteSelectedDevicesButton')}
                  onPress={this.onSelectionDeletePress}
                />
                <NitroxButton
                  border
                  style={styles.bigScreenCancelButton}
                  text={t('settings.cancel')}
                  onPress={this.onSelectionCancelPress}
                />
              </View>
            )}
            {!!register && (
              <>
                <NitroxButton
                  border
                  style={styles.registerButton}
                  text={t('settings.registerButton')}
                  theme={NitroxButtonTheme[isBigScreen ? 'Primary' : 'Tertiary']}
                  onPress={register}
                  disabled={this.state.selectable}
                />
                <NitroxText style={styles.registerText} textType='callout-small'>{t('settings.registerText')}</NitroxText>
              </>
            )}
          </NitroxInteractiveController>
          <TestContext.Provider value={testIdContext()}>
            <FocusParent>
              <Popup
                visible={this.state.deleteConfirmationPopupVisible}
                title={t('settings.deleteDevicesPopup.title')}
                actions={popupActions}
                positiveLabel={t('settings.delete')}
                negativeLabel={t('settings.cancel')}
                onModalClose={this.onCancelDelete}
                onNegative={this.onCancelDelete}
                onPositive={this.onConfirmDelete}
                menuStyle={styles.popupMenu}
                menuButtonStyle={styles.popupMenuButton}
              >
                <View style={styles.messageContainer}>
                  <NitroxText style={styles.message} textType='dialog-message'>{t('settings.deleteDevicesPopup.message')}</NitroxText>
                </View>
              </Popup>
            </FocusParent>
          </TestContext.Provider>
          <DeviceUnregistrationErrorPopup error={this.state.error} closePopup={this.closeErrorPopup} />
        </SettingsDetails>
        {this.props.renderUnlockModal()}
      </>
    );
  }
}

export default withTranslation()(withUnlockModal(SettingsRegisteredDevicesScreen));
