import {createStyles} from 'common-styles';
import React, {useMemo, useState, useCallback} from 'react';
import {View, ViewStyle} from 'react-native';

import {dimensions, isIOS} from 'common/constants';
import {NavigationFocusState} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {constColors, BaseColors} from 'common-styles/variables/base-colors';

import {Styling} from 'mw/api/Metadata';
import {CMSEvent} from 'mw/cms/CMS';
import {mw} from 'mw/MW';

import {assets} from 'brand/Resources';
import {IconType} from 'components/Icon';
import {useLogo} from 'components/navigation/LogoProvider';
import {MobileBackButtonManager, BackButton} from 'components/navigation/MobileHeaderBackButton';
import NitroxLogo from 'components/navigation/NitroxLogo';
import NewStylingPopup from 'components/NewStylingPopup';
import NitroxButton from 'components/NitroxButton';
import NitroxInteractive from 'components/NitroxInteractive';
import NitroxText from 'components/NitroxText';
import NotificationsIndicator from 'components/notifications/NotificationsIndicator';
import {useToggle, useDisposableCallback, useEventListener, useNavigation, useNavigationFocusState, useWillAppear, useLazyEffect} from 'hooks/Hooks';

import {MobileHeaderMenu} from './MobileHeaderMenu';

const sideSlotWidth = dimensions.backButton.size + dimensions.margins.large;

const styles = createStyles({
  icons: {
    position: 'absolute',
    flexDirection: 'row'
  },
  leftIcons: {
    alignSelf: 'flex-start'
  },
  rightSlot: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-end',
    paddingRight: dimensions.margins.xsmall,
    flexDirection: 'row',
    height: '100%',
    minWidth: sideSlotWidth
  },
  transparentBg: {
    backgroundColor: constColors.transparent
  },
  backButtonIcon: {
    ...isIOS && {
      paddingLeft: 0
    }
  },
  backButtonSlot: {
    alignItems: 'center',
    alignSelf: 'flex-start',
    backgroundColor: constColors.transparent,
    height: dimensions.screen.header.height,
    justifyContent: 'center',
    width: sideSlotWidth
  },
  imageIcon: {
    width: dimensions.icon.xxsmall,
    height: dimensions.icon.xxsmall
  },
  notificationIndicator: {
    marginTop: dimensions.margins.xsmall,
    marginRight: dimensions.margins.medium
  },
  logoContainer: {
    paddingLeft: dimensions.margins.large,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  logo: {
    ...dimensions.logo
  }
});

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  titleAndUserIconContainer: {
    alignItems: 'center',
    backgroundColor: colors.screenHeader.background,
    flexDirection: 'row',
    height: dimensions.screen.header.height,
    justifyContent: 'flex-start',
    width: '100%'
  },
  titleTextStyle: {
    alignSelf: 'center',
    color: colors.screenHeader.title,
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'hidden',
    textAlign: 'center'
  },
  iconColor: colors.screenHeader.icon,
  backIconColor: colors.button.primary.background.focused
}));

export type MobileHeaderRightIcon = {
  type: IconType;
  onPress?: () => void;
}

export type MobileScreenHeaderProps = {
  style?: ViewStyle;
  titleStyle?: ViewStyle;
  title?: string;
  hideNativeBackButton?: boolean;
  showBackButton?: boolean;
  onBackPress?: () => void;
  rightContent?: React.ReactNode;
  showLogo?: boolean;
  showNewStylingNotification?: boolean;
  shouldDisplayMenu?: boolean;
};

const MobileScreenHeader: React.FC<MobileScreenHeaderProps> = props => {
  const {
    style,
    titleStyle,
    title = '',
    showBackButton = false,
    hideNativeBackButton = false,
    onBackPress,
    showLogo = false,
    showNewStylingNotification = false,
    shouldDisplayMenu = false
  } = props;
  const [newStyling, setNewStyling] = useState<Styling | null>(null);
  const logoUrl = useLogo() || assets.common.mobileHeaderLogo;

  const [headerWidth, setHeaderWidth] = useState(0);
  const onLayout = useCallback(({nativeEvent: {layout: {width}}}) => {
    setHeaderWidth(width);
  }, []);
  const dynamicStyles = dynamicStylesUpdater.getStyles();
  const containerStyle: ViewStyle = useMemo(() => ({...dynamicStyles.titleAndUserIconContainer, ...style}), [dynamicStyles.titleAndUserIconContainer, style]);
  const titleTextStyle: ViewStyle = useMemo(() => {
    const titleWidth = headerWidth - (2 * sideSlotWidth);
    return {...dynamicStyles.titleTextStyle, ...titleStyle, maxWidth: titleWidth};
  }, [dynamicStyles.titleTextStyle, headerWidth, titleStyle]);

  const onNewStylingAvailable = useDisposableCallback((styling: Styling) => setNewStyling(styling), []);
  useEventListener(CMSEvent.newStyling, onNewStylingAvailable, mw.cms);
  const onNewStylingApplied = useDisposableCallback(() => setNewStyling(null), []);
  useEventListener(CMSEvent.newStylingApplied, onNewStylingApplied, mw.cms);

  const [newStylingPopupVisible, {on: newStylingButtonPress, off: newStylingPopupClose}] = useToggle(false);

  const shouldHideNativeBackButton = showBackButton || hideNativeBackButton;

  const updateNativeBackButtonState = useCallback((shouldHide: boolean) => {
    if (shouldHide) {
      MobileBackButtonManager.hide();
    } else {
      MobileBackButtonManager.show();
    }
  }, []);

  const inFocusedScreen = useNavigationFocusState(useNavigation()) === NavigationFocusState.IsFocused;
  useLazyEffect(() => {
    if (inFocusedScreen) {
      updateNativeBackButtonState(shouldHideNativeBackButton);
    }
  }, [shouldHideNativeBackButton, inFocusedScreen], [updateNativeBackButtonState]);

  useWillAppear(() => updateNativeBackButtonState(shouldHideNativeBackButton));

  return (
    <View style={containerStyle} onLayout={onLayout}>
      {showLogo
        ? (
          <View style={styles.logoContainer}>
            <NitroxLogo style={styles.logo} pictureUrl={logoUrl} />
          </View>
        )
        : (
          <View style={styles.backButtonSlot}>
            {showBackButton && (
              <NitroxInteractive
                style={styles.transparentBg}
                onPress={onBackPress}
              >
                <BackButton style={styles.backButtonIcon} />
              </NitroxInteractive>
            )}
          </View>
        )
      }
      <NitroxText textType='headline' style={titleTextStyle}>
        {title}
      </NitroxText>
      {props.rightContent
        ? props.rightContent
        : (
          <View style={styles.rightSlot}>
            {showNewStylingNotification && newStyling && (
              <NitroxButton
                source={assets.common.bellIcon}
                style={styles.transparentBg}
                imageStyle={styles.imageIcon}
                onPress={newStylingButtonPress}
              >
                <NotificationsIndicator
                  notificationsCount={+!!newStyling}
                  style={styles.notificationIndicator}
                />
              </NitroxButton>
            )}
            {shouldDisplayMenu && <MobileHeaderMenu />}
          </View>
        )
      }
      <NewStylingPopup
        visible={newStylingPopupVisible && !!newStyling}
        styling={newStyling}
        onClose={newStylingPopupClose}
      />
    </View>
  );
};

export default React.memo(MobileScreenHeader);
