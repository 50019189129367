import {compactMap} from 'common/HelperFunctions';
import {makeArray} from 'common/utils';

import {Device} from 'mw/api/Device';

export class DeviceMapper {
  private static deviceFromJson(json: any): Device | null {
    if (!json) {
      return null;
    }
    return new Device(json.uid, json.name, true, json.uid);
  }

  public static devicesFromJson(json: any): Device[] {
    if (!json) {
      return [];
    }
    return compactMap(makeArray(json), DeviceMapper.deviceFromJson);
  }
}
