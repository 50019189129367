import {createStyles} from 'common-styles';
import i18next from 'i18next';
import React, {forwardRef, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ListRenderItemInfo, View} from 'react-native';
import {withNavigation} from 'react-navigation';

import {dimensions} from 'common/constants';
import {indexKeyExtractor, unique} from 'common/HelperFunctions';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Media, isEvent, SortVodBy, SortChannelBy, ChannelSorting, SortAllResultsBy, AllResultsSorting} from 'mw/api/Metadata';
import {compareNames, sortSearchResults} from 'mw/utils/CatalogUtils';

import {useChromecastExtraBottomPadding} from 'components/chromecast/ChromecastExtraBottomPadding';
import MobileMediaTile from 'components/mediaTiles/MobileMediaTile';
import NitroxFlatList from 'components/NitroxFlatList';
import NitroxText from 'components/NitroxText';
import SearchEpgFilterSelect, {SearchEpgFilter} from 'components/SearchEpgFilterSelect';
import SortOrderSelect, {createOption} from 'components/SortOrderSelect';
import TabView, {Tab} from 'components/tabs/TabView';
import VodSortOrderSelect from 'components/vod/VodSortOrderSelect';
import {useWatchList, useForceUpdate, useChangeEffect, useNearestLiveEvent} from 'hooks/Hooks';

import NoResults from './NoResults';
import {SearchResultProps} from './SearchResults';

const styles = createStyles({
  container: {
    flex: 1
  },
  tabView: {
    marginTop: dimensions.margins.large,
    marginLeft: dimensions.margins.large,
    marginRight: dimensions.margins.large,
    justifyContent: 'space-between'
  },
  tabContainerStyle: {
    flex: 0
  },
  tab: {
    flex: 1
  },
  sortSelect: {
    marginLeft: dimensions.margins.large,
    marginVertical: dimensions.margins.medium
  },
  filterSelect: {
    marginLeft: dimensions.margins.large,
    marginVertical: dimensions.margins.medium
  }
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  label: {
    marginTop: dimensions.margins.large,
    marginLeft: dimensions.margins.large,
    color: colors.searchScreen.header
  }
}));

const supportedVodSortOptions: SortVodBy[] = [SortVodBy.title, SortVodBy.productionYear];
const channelSortOptions = (t: i18next.TFunction) => [
  createOption<ChannelSorting>({type: SortChannelBy.name, ascending: true}, t('sorting.a-z')),
  createOption<ChannelSorting>({type: SortChannelBy.name, ascending: false}, t('sorting.z-a'))
];
const allResultsSortOptions = (t: i18next.TFunction) => [
  createOption<AllResultsSorting>({type: SortAllResultsBy.name, ascending: true}, t('sorting.a-z')),
  createOption<AllResultsSorting>({type: SortAllResultsBy.name, ascending: false}, t('sorting.z-a'))
];

function SearchResultsPiccolo(props: SearchResultProps) {
  const {
    searchResult,
    currentAllResultsSorting,
    onAllResultsSortingSelected,
    currentChannelSorting,
    onChannelSortingSelected,
    currentEpgFilter,
    onEpgFilterSelected,
    currentVodSorting,
    onVodSortingSelected,
    onMoreActionsPress,
    onStartPlayback
  } = props;
  const {t} = useTranslation();
  const chromecastExtraPadding = useChromecastExtraBottomPadding();

  const channelResults = useMemo<Media[]>(() => {
    return searchResult?.channel ?? [];
  }, [searchResult]);

  const vodResults = useMemo<Media[]>(() => {
    const vodResults = Array.from(searchResult?.vod ?? []);
    if (vodResults.length) {
      sortSearchResults(vodResults, currentVodSorting);
    }
    return vodResults;
  }, [searchResult, currentVodSorting]);

  const epgResults = useMemo<Media[]>(() => {
    switch (currentEpgFilter) {
      case SearchEpgFilter.past:
        return searchResult?.epgPast ?? [];
      case SearchEpgFilter.current:
        return searchResult?.epgNow ?? [];
      case SearchEpgFilter.future:
        return searchResult?.epgFuture ?? [];
      case SearchEpgFilter.recorded:
        return searchResult?.epgPvr ?? [];
      default:
        return [];
    }
  }, [searchResult, currentEpgFilter]);

  const sortAllResultsGroup = useCallback((unSortedResults: Media[]) => {
    const ascending = currentAllResultsSorting?.ascending ?? true;
    const results = [...unSortedResults];
    return results.sort((a, b) => {
      return compareNames(ascending, isEvent(a) ? a.title : a, isEvent(b) ? b.title : b);
    });
  }, [currentAllResultsSorting]);

  const sortedAllResults = useMemo(
    () => {
      const sortedChannelResults = sortAllResultsGroup(channelResults);
      const sortedVodResults = sortAllResultsGroup(vodResults);
      const sortedEpgResults = sortAllResultsGroup([
        ...(searchResult?.epgPast ?? []),
        ...(searchResult?.epgNow ?? []),
        ...(searchResult?.epgFuture ?? []),
        ...(searchResult?.epgPvr ?? [])
      ]);
      // Filter out redundant events, e.g. series from 'All' view as the events are grouped in series per now, future, past, pvr.
      const uniqueEpgResults = unique(sortedEpgResults, 'id');

      return [
        ...sortedChannelResults,
        ...sortedVodResults,
        ...uniqueEpgResults
      ];
    },
    [channelResults, searchResult, sortAllResultsGroup, vodResults]
  );

  const watchList = useWatchList();
  const nearestLiveEvent = useNearestLiveEvent(useCallback(() => epgResults, [epgResults]));
  const {forceUpdate, forceUpdateState} = useForceUpdate();

  // update event icons that change over time
  useChangeEffect(forceUpdate, [watchList, nearestLiveEvent], [forceUpdate]);

  const renderItem = useCallback((info: ListRenderItemInfo<Media>) => {
    return (
      <MobileMediaTile
        media={info.item}
        extraData={forceUpdateState}
        onMoreActionsPress={onMoreActionsPress}
        onStartPlayback={onStartPlayback}
      />
    );
  }, [onMoreActionsPress, onStartPlayback, forceUpdateState]);

  const dynamicStyles = stylesUpdater.getStyles();
  if (!searchResult) {
    return null;
  } else if (!Object.keys(searchResult).length) {
    return (
      <>
        <NitroxText textType='headline' style={dynamicStyles.label}>{t('search.resultsFor', {searchText: props.searchText})}</NitroxText>
        <NoResults />
      </>
    );
  }

  return (
    <View style={styles.container}>
      <TabView tabBarStyle={styles.tabView} visible activeUnderscore>
        <Tab key={'all'} title={t('search.all')} tabContainerStyle={styles.tabContainerStyle}>
          <SortOrderSelect<AllResultsSorting> options={allResultsSortOptions(t)} currentSortOrder={currentAllResultsSorting} onSortOrderSelected={onAllResultsSortingSelected} style={styles.sortSelect} />
          <NitroxFlatList
            contentContainerStyle={chromecastExtraPadding}
            data={sortedAllResults}
            extraData={forceUpdateState}
            renderItem={renderItem}
            keyExtractor={indexKeyExtractor}
          />
        </Tab>
        <Tab key={'channels'} title={t('search.channels')} tabContainerStyle={styles.tabContainerStyle}>
          <SortOrderSelect<ChannelSorting> options={channelSortOptions(t)} currentSortOrder={currentChannelSorting} onSortOrderSelected={onChannelSortingSelected} style={styles.sortSelect} />
          <NitroxFlatList
            contentContainerStyle={chromecastExtraPadding}
            data={channelResults}
            extraData={forceUpdateState}
            renderItem={renderItem}
            keyExtractor={indexKeyExtractor}
          />
        </Tab>
        <Tab key={'movies'} title={t('search.movies')} tabContainerStyle={styles.tabContainerStyle}>
          <VodSortOrderSelect supportedSortOptions={supportedVodSortOptions} currentSortOrder={currentVodSorting} onSortOrderSelected={onVodSortingSelected} style={styles.sortSelect} />
          <NitroxFlatList
            contentContainerStyle={chromecastExtraPadding}
            data={vodResults}
            extraData={forceUpdateState}
            renderItem={renderItem}
            keyExtractor={indexKeyExtractor}
          />
        </Tab>
        <Tab key={'tv'} title={t('search.tv')} tabContainerStyle={styles.tabContainerStyle}>
          <SearchEpgFilterSelect currentFilter={currentEpgFilter} onFilterSelected={onEpgFilterSelected} style={styles.filterSelect} />
          <NitroxFlatList
            contentContainerStyle={chromecastExtraPadding}
            data={epgResults}
            extraData={forceUpdateState}
            renderItem={renderItem}
            keyExtractor={indexKeyExtractor}
          />
        </Tab>
      </TabView>
    </View>
  );
}

export default withNavigation(forwardRef(SearchResultsPiccolo));
