import React, {useMemo} from 'react';

import {isMobile} from 'common/constants';

import {Recording, isEvent, RecordingStatus, RecordingErrorCode} from 'mw/api/Metadata';

import MediaInfoViewGrosso from './MediaInfoView.grosso';
import MediaInfoViewPiccolo from './MediaInfoView.piccolo';
import {MediaInfoViewBaseProps, MediaInfoViewComponentProps} from './MediaInfoView.shared';

type Props = MediaInfoViewBaseProps & {
  eventRecordings?: Recording[];
};

const MediaInfoView: React.FC<Props> = props => {
  const {eventRecordings, ...baseProps} = props;
  const {media} = baseProps;
  const recordingErrorCode = useMemo((): RecordingErrorCode | undefined => {
    if (!isEvent(media)) {
      return;
    }
    const recording = eventRecordings?.find(recording => recording.event?.id === media.id);
    return recording && recording.status === RecordingStatus.Failed ? recording.errorCode : undefined;
  }, [media, eventRecordings]);

  const componentProps: MediaInfoViewComponentProps = {
    ...baseProps,
    recordingErrorCode
  };

  return isMobile ? <MediaInfoViewPiccolo {...componentProps} /> : <MediaInfoViewGrosso {...componentProps} />;
};

export default MediaInfoView;
