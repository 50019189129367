import {NotificationsManager} from 'mw/notifications/NotificationsManager';
import {DRMSessionManager} from 'mw/playback/sessions/DRMSessionManager';
import {PlaybackSessionManager} from 'mw/playback/sessions/PlaybackSessionManager';

import {BOFactory} from './bo/BOFactory';
import {BOInterface} from './BOInterface';
import {DRMFactory} from './drm/DRMFactory';
import {ReportingManager} from './reporters/ReportingManager';
import {SSOFactory} from './sso/SSOFactory';
import {SSOInterface} from './SSOInterface';

class BOProxy {
  public static instance = new BOProxy();

  public sso: SSOInterface = {} as any as SSOInterface;
  public bo: BOInterface = {} as any as BOInterface;
  public playbackSessionManager: PlaybackSessionManager = {} as any as PlaybackSessionManager;
  public notificationsManager: NotificationsManager | null = null;
  public drmSessionManager: DRMSessionManager | null = null;
  public reportingManager: ReportingManager | null = null;

  public initialize() {
    this.uninitialize();
    this.sso = SSOFactory.createSSOAdapter();
    this.bo = BOFactory.createBOAdapter();
    this.playbackSessionManager = this.bo.getPlaybackSessionManager();
    this.notificationsManager = this.bo.getNotificationsManager();
    this.drmSessionManager = DRMFactory.createDRMSessionManager();
    this.reportingManager = new ReportingManager();
  }

  //TODO: CL-827 turn this into a proper singleton
  public uninitialize() {
    this.bo.clear?.();
    this.sso.clear?.();
    this.reportingManager?.clear();
    this.sso = {} as any as SSOInterface;
    this.bo = {} as any as BOInterface;
    this.playbackSessionManager = {} as any as PlaybackSessionManager;
    this.notificationsManager = null;
    this.drmSessionManager = null;
    this.reportingManager = null;
  }
}

export const boProxy: BOProxy = BOProxy.instance;
