import {Component} from './Component';

export class ComponentGroup {
  public title: string;
  public components: Component[]

  public constructor(title: string, components: Component[]) {
    this.title = title;
    this.components = components;
  }
}
