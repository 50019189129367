import {createStyles} from 'common-styles';
import React from 'react';
import {View, ViewStyle, StyleProp} from 'react-native';

import {SupportedDirectionKeys, SupportedKeys} from 'components/KeyEventManager';
import NitroxText from 'components/NitroxText';

export enum DebugWrapperType {
  Idle,
  Available,
  PossibleNext
}

/* eslint-disable schange-rules/no-literal-color */
const styles = createStyles({
  debugWrapper: {
    position: 'absolute',
    alignSelf: 'center',
    justifyContent: 'center',
    width: '95%',
    height: '95%'
  },
  possibleNext: {
    borderWidth: 1,
    backgroundColor: 'rgba(255,0,100,0.2)',
    borderColor: 'rgba(255,0,100,0.6)'
  },
  debugArrow: {
    alignSelf: 'center',
    justifyContent: 'center',
    color: '#FF0000'
  }
});
/* eslint-enable schange-rules/no-literal-color */

export type DebugInfo = {
  type: DebugWrapperType;
  direction?: SupportedDirectionKeys;
}

type Props = {
  debugInfo: DebugInfo;
}

const arrows = new Map<SupportedDirectionKeys, string>([
  [SupportedKeys.Left, '←'],
  [SupportedKeys.Right, '→'],
  [SupportedKeys.Down, '↓'],
  [SupportedKeys.Up, '↑']
]);

const DebugWrapper: React.FC<Props> = (props: Props) => {
  const style: StyleProp<ViewStyle>[] = [styles.debugWrapper];
  if (props.debugInfo.type === DebugWrapperType.PossibleNext) {
    style.push(styles.possibleNext);
  }
  return (
    <View style={style}>
      {props.debugInfo.direction && (
        <NitroxText
          textType={'title'}
          style={styles.debugArrow}
        >
          {arrows.get(props.debugInfo.direction)}
        </NitroxText>
      )}
    </View>
  );
};
export default DebugWrapper;
