import {createStyles} from 'common-styles';
import React, {useCallback, useState, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View, ScrollView} from 'react-native';

import {dimensions, isBigScreen} from 'common/constants';
import {compactMap} from 'common/HelperFunctions';
import {useSafeAwait} from 'common/hooks/Hooks';
import {getTime} from 'common/utils';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Media, Order} from 'mw/api/Metadata';
import {boProxy} from 'mw/bo-proxy/BOProxy';
import {mw} from 'mw/MW';

import FocusParent, {useFocusParent} from 'components/FocusParent';
import {STBMenuState} from 'components/navigation/NavigationHelperTypes';
import NitroxButton from 'components/NitroxButton';
import {NitroxInteractiveController} from 'components/NitroxInteractiveControllerContext';
import {useToggle, useNavigation} from 'hooks/Hooks';
import {PostProcessors} from 'locales/i18nPostProcessors';
import NitroxScreen from 'screens/NitroxScreen';

import PaginationBar from './PaginationBar';
import {SettingsOrdersFilterValue, SettingsOrdersFilterKey, SettingsOrdersFilterOptionsKeys, PAGE_SIZE} from './SettingsOrderProps';
import SettingsOrdersFilterSelect from './SettingsOrdersFilterSelect';
import {StatusLabel} from './StatusLabel';
import Table, {HeadCellType, ContentProps, HeadCellProps} from './Table';

const getConfig = (createButton: (row: Order) => JSX.Element, dateFormat: string): HeadCellProps<Order>[] => {
  const getIndex = (index: number, page: number) => `${(page - 1) * PAGE_SIZE + index + 1}`;

  return [
    {width: 80, head: 'settings.orders.table.number', content: ({index, page}: ContentProps<Order>) => getIndex(index, page), type: HeadCellType.Default, noEllipsis: true},
    {width: 130, head: 'settings.orders.table.orderNumber', content: ({row}: ContentProps<Order>) => row.id, type: HeadCellType.Default},
    {head: 'settings.orders.table.orderItems', content: ({row}: ContentProps<Order>) => row.productName, type: HeadCellType.Left},
    {width: 180, head: 'settings.orders.table.date', content: ({row}: ContentProps<Order>) => getTime(new Date(row.creationDate), dateFormat), type: HeadCellType.Default},
    // eslint-disable-next-line react/display-name
    {width: 160, head: 'settings.orders.table.status', content: ({row}: ContentProps<Order>) => <StatusLabel status={row.orderStatus} />},
    {width: 280, content: ({row}: ContentProps<Order>) => createButton(row)}
  ];
};

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  screenContainer: {
    borderTopLeftRadius: isBigScreen ? 40 : undefined,
    backgroundColor: isBigScreen ? colors.settingsScreen.transparentBackground : undefined,
    paddingHorizontal: dimensions.margins.xxLarge,
    paddingBottom: dimensions.margins.xxLarge
  }
}));

const styles = createStyles({
  button: {
    paddingLeft: dimensions.margins.small,
    paddingRight: dimensions.margins.small
  },
  header: {
    marginBottom: -dimensions.margins.xxLarge
  },
  parallelNavigatorContainer: {
    flex: 1
  },
  filterContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: dimensions.margins.small
  },
  titleWrapper: {
    alignItems: 'center'
  }
});

type Props = {
  navigators?: {orderDetails: (order: Order) => void};
}

const SettingsOrdersScreen: React.FC<Props> = ({navigators}) => {
  const dynamicStyles = stylesUpdater.getStyles();
  const [data, setData] = useState<Order[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const [filterSelectModalVisible, {on: showFilterSelectPopup, off: closeFilterSelectPopup}] = useToggle(false);
  const [searchFilter, setSearchFilter] = useState<SettingsOrdersFilterValue>(SettingsOrdersFilterKey.All);
  const navigation = useNavigation();
  const dateFormat = mw.configuration.getDefaultDateFormat();
  const safeAwait = useSafeAwait();

  const onSearchFilterModalClosed = useCallback((searchFilter: SettingsOrdersFilterValue) => {
    setSearchFilter(searchFilter);
    setPage(1); // Reset to first page
    closeFilterSelectPopup();
  }, [closeFilterSelectPopup]);

  const handleRequest = useCallback(async (data: {content: Order[]; totalElements: number}) => {
    const ids = compactMap(data.content, order => order.mediaId);
    const assets: Media[] = await safeAwait(boProxy.bo.getMediaByIds(ids));

    const preparedData = data.content.map(row => {
      // For assets, names have to be fetched manually
      const asset = assets.find((asset => asset.id === row.mediaId));
      row.productName = asset?.name || row.productName;
      return row;
    });
    setData(preparedData);
    setTotalElements(data.totalElements);
    setTotalPages(Math.ceil(data.totalElements / PAGE_SIZE));
    setLoading(false);
    setInitialLoading(false);
  }, [safeAwait]);

  const handleChangePage = useCallback((incomingPage: number) => {
    if (page !== incomingPage) {
      setPage(incomingPage);
    }
  }, [page]);

  useEffect(() => {
    handleChangePage(page);
    const orderStatus = searchFilter === SettingsOrdersFilterKey.All ? undefined : searchFilter;
    setLoading(true);
    safeAwait(boProxy.bo.getOrders({page, size: PAGE_SIZE, sort: true, orderStatus}))
      .then(handleRequest);
  }, [totalElements, page, handleRequest, searchFilter, handleChangePage, safeAwait]);

  const onResetPress = () => {
    setSearchFilter(SettingsOrdersFilterKey.All);
    setPage(1); // Reset to first page
  };

  const currentFilterOptionKey = SettingsOrdersFilterOptionsKeys[searchFilter];

  const onDetailsOrderPress = useCallback((order: Order) => {
    navigators?.orderDetails(order);
  }, [navigators]);

  const createButton = useCallback((row: Order) => (
    <NitroxButton
      onPress={() => onDetailsOrderPress(row)}
      border
      style={styles.button}
      text={t('settings.orderDetails', {postProcess: PostProcessors.ToUpperCase})}
    />
  ), [onDetailsOrderPress, t]);

  const tableConfig: HeadCellProps<Order>[] = useMemo(() => getConfig(createButton, dateFormat), [createButton, dateFormat]);

  const defaultScreenHeader = useMemo(() => ({
    title: t('settings.orders.title'),
    style: styles.header
  }), [t]);

  const [onReady, focus] = useFocusParent();
  useEffect(() => focus(), [focus]);

  return (
    <NitroxScreen
      navigation={navigation}
      style={dynamicStyles.screenContainer}
      onScreenFocused={focus}
      menuState={STBMenuState.Above}
      bigScreenHeaderProps={defaultScreenHeader}
      showBigScreenHeader
      testID='screen_settings'
    >
      <NitroxInteractiveController omitGeometryCaching>
        <FocusParent
          onReady={onReady}
          rememberLastFocused
          style={styles.parallelNavigatorContainer}
        >
          <ScrollView showsVerticalScrollIndicator={false}>
            <View style={styles.filterContainer}>
              <NitroxButton
                border
                text={`${t('settings.orders.filterTitle', {postProcess: PostProcessors.ToUpperCase})}: ${t(currentFilterOptionKey, {postProcess: PostProcessors.ToUpperCase})}`}
                onPress={showFilterSelectPopup}
              />
              <SettingsOrdersFilterSelect
                modalVisible={filterSelectModalVisible}
                searchField={searchFilter}
                onModalClosed={closeFilterSelectPopup}
                onModalApply={onSearchFilterModalClosed}
              />
              <NitroxButton
                onPress={onResetPress}
                border={true}
                text={t('settings.resetFilters', {postProcess: PostProcessors.ToUpperCase})}
              />
            </View>
            <Table<Order> data={data} page={page} loading={loading} tableConfig={tableConfig} />
            {/* Display pagination after initial loading and only if there is at least 2 pages */}
            {!initialLoading && totalPages > 1 && <PaginationBar totalPages={totalPages} currentPage={page} handleChange={handleChangePage} />}
          </ScrollView>
        </FocusParent>
      </NitroxInteractiveController>
    </NitroxScreen>
  );
};

export default SettingsOrdersScreen;
