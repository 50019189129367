import {createStyles} from 'common-styles';
import React, {PropsWithChildren, useRef} from 'react';
import {TouchableWithoutFeedback, View, StyleProp, ViewStyle} from 'react-native';

import {doNothing} from 'common/HelperFunctions';
import {TestProps} from 'common/HelperTypes';

import {useFunction, useTimer, useChangeEffect} from 'hooks/Hooks';

import {useFullScreenControl} from './FullScreenControlProvider';

const multiPressDelayMs = 250;
const styles = createStyles({
  internalView: {
    flex: 1
  }
});

type Props = PropsWithChildren<{
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
} & TestProps>;

const FullScreenTouchableView: React.FC<Props> = (props) => {
  const {disabled, style, onPress: propsOnPress = doNothing, children, testID} = props;
  const {enabled, toggleFullScreenMode} = useFullScreenControl();

  const onDoublePress = useFunction(() => {
    toggleFullScreenMode();
    propsOnPress();
  });

  const onDoublePressCallback = useDoublePressCallback({onSinglePress: propsOnPress, onDoublePress});

  if (disabled) {
    return (
      <View style={style}>
        {children}
      </View>
    );
  }

  const onPress = enabled ? onDoublePressCallback : propsOnPress;
  return (
    <TouchableWithoutFeedback
      style={style}
      onPress={onPress}
      // @ts-ignore Disable sounds invoked on touch
      touchSoundDisabled
    >
      <View testID={testID} style={styles.internalView}>
        {children}
      </View>
    </TouchableWithoutFeedback>
  );
};

export default React.memo(FullScreenTouchableView);

function useDoublePressCallback(props: {onSinglePress: () => void, onDoublePress: () => void}): () => void {
  const {onSinglePress: propsOnSinglePress, onDoublePress} = props;
  const counter = useRef(0);
  const {setTimer, clearTimer} = useTimer();
  const onSinglePress = useRef(propsOnSinglePress);

  useChangeEffect(() => {
    onSinglePress.current = propsOnSinglePress;
  }, [propsOnSinglePress]);

  return useFunction(() => {
    counter.current++;
    if (counter.current === 1) {
      setTimer(() => {
        counter.current = 0;
        onSinglePress.current();
      }, multiPressDelayMs);
      return;
    }
    if (counter.current === 2) {
      counter.current = 0;
      onDoublePress();
      clearTimer();
    }
  });
}
