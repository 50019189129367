import {encode} from 'base-64';

import {DeviceManager} from 'mw/api/DeviceManager';
import {Event, MediaType, Recording, isRecording} from 'mw/api/Metadata';
import {nxffConfig} from 'mw/api/NXFF';
import {DrmSession} from 'mw/bo-proxy/SSOInterface';
import {mw} from 'mw/MW';
import {CreateSessionParams} from 'mw/playback/sessions/DRMSessionManager';
import {createUnsignedJWT} from 'mw/utils/JwsUtils';

import {SeaChangeDRMAdapter, PropertiesParams} from './SeaChangeDRMAdapter';

export class MegaDrmAdapter extends SeaChangeDRMAdapter {
  protected TAG = 'MegaDrmAdapter';

  protected createDrmHeaders(parameters: CreateSessionParams): DrmSession['drmHeaders'] {
    return {
      'x-dt-custom-data': encode(JSON.stringify({
        userId: mw.customer.id,
        sessionId: createUnsignedJWT({
          session: parameters.session.id,
          cpeId: DeviceManager.getInstance().getId()
        }),
        merchant: nxffConfig.getConfig().DRM.MerchantId
      }))
    };
  }

  protected getParams(params: CreateSessionParams): PropertiesParams {
    let assetId;
    const media = params.media;
    switch (media.getType()) {
      case MediaType.Event: {
        const event: Event = media as Event;
        assetId = event.channelId;
      }
        break;

      case MediaType.Recording: {
        const recording: Recording = media as Recording;
        if (isRecording(media) && recording.channelId) {
          assetId = recording.channelId;
          break;
        }
      }

      default:
        assetId = super.getParams(params).streamId;
    }

    return {specConform: true, assetId};
  }
}
