import Color from 'color';
import {createStyles} from 'common-styles';
import React from 'react';
import {StyleSheet, View} from 'react-native';

import {getValue} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Channel, Event} from 'mw/api/Metadata';

import GradientFill from 'components/GradientFill';

import Clock from './Clock';
import EventShortDetails from './EventShortDetails.grosso';

const styles = createStyles({
  container: {
    ...StyleSheet.absoluteFillObject,

    justifyContent: 'center',
    alignItems: 'center'
  },
  clock: {
    position: 'absolute',
    top: '5%',
    right: '4%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    height: getValue({mobile: 34, defaultValue: 61})
  }
});

const gradientUpdater = new StylesUpdater((colors: BaseColors) => ({
  gradientColors: [
    Color(colors.tvScreen.overlay.shade).alpha(0)
      .toString(),
    colors.tvScreen.overlay.shade
  ]
}));

type ZapOverlayProps = {
  channel?: Channel;
  event?: Event;
}

const ZapOverlay: React.FC<ZapOverlayProps> = props => {
  const {
    event,
    channel
  } = props;
  const {gradientColors} = gradientUpdater.getStyles();

  return (
    <View style={styles.container} testID='zap_overlay'>
      <GradientFill
        colors={gradientColors}
      />
      <Clock containerStyle={styles.clock} />
      <EventShortDetails
        event={event}
        channel={channel}
      />
    </View>
  );
};

export default ZapOverlay;
