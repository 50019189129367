import {createStyles} from 'common-styles';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View, TextInputProps} from 'react-native';

import {dimensions, isBigScreen} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import FocusParent from 'components/FocusParent';
import FocusableTextInput from 'components/inputs/FocusableTextInput';
import NitroxText from 'components/NitroxText';

import {useYearOfBirth} from './../profileWizard/WizardHooks';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  label: {
    color: colors.settingsScreen.profile.wizard.label,
    marginTop: isBigScreen ? 0 : dimensions.margins.medium,
    marginBottom: isBigScreen ? dimensions.margins.large : dimensions.margins.medium
  },
  textBoxText: {
    color: colors.settingsScreen.profile.wizard.sublabel
  },
  ageHint: {
    paddingHorizontal: dimensions.margins.xxxLarge,
    alignItems: 'center'
  },
  ageHintText: {
    textAlign: 'center',
    marginBottom: isBigScreen ? dimensions.margins.large : dimensions.margins.medium
  },
  focusParent: {
    alignItems: 'center',
    width: '100%'
  },
  editField: {
    ...isBigScreen ? {
      width: dimensions.inputs.width.settings,
      marginBottom: dimensions.margins.large
    } : {
      width: '100%',
      marginBottom: dimensions.margins.medium
    }
  },
  container: {
    alignItems: 'center',
    width: '100%'
  }
}));

const inputProps: TextInputProps = {
  keyboardType: isBigScreen ? 'number-pad' : 'numeric',
  maxLength: 4,
  // prevent pasting text into input
  contextMenuHidden: true,
  returnKeyType: 'done'
};

export type BirthInputProps = {
  onBirthDateChanged?: (year?: number) => void;
  yearOfBirth?: number;
  embedded?: boolean;
  onBirthDateSubmit?: () => void;
  autoFocus?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
}

const BirthInput: React.FunctionComponent<BirthInputProps> = ({
  onBirthDateChanged,
  yearOfBirth,
  embedded,
  onBirthDateSubmit,
  autoFocus,
  onFocus,
  onBlur
}) => {

  const styles = stylesUpdater.getStyles();
  const {t} = useTranslation();
  const {profileAge, onChangeText} = useYearOfBirth(onBirthDateChanged);

  return (
    <View style={styles.container}>
      <NitroxText style={styles.label} textType='callout-bold' trimLine>{t('profileWizard.enterYearOfBirth')}</NitroxText>
      <FocusParent style={styles.focusParent}>
        <View style={styles.editField}>
          <FocusableTextInput
            autoFocus={autoFocus}
            onChangeText={onChangeText}
            initialValue={yearOfBirth && yearOfBirth > 0 ? `${yearOfBirth}` : undefined}
            inputProps={inputProps}
            placeholder={embedded || !isBigScreen ? undefined : t('settings.edit.yearOfBirthPrompt')}
            onSubmitEditing={onBirthDateSubmit}
            delaySubmitEditing={isBigScreen}
            textAlign={isBigScreen ? 'center' : undefined}
            editIndicatorIcon={!isBigScreen}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </View>
      </FocusParent>
      <View style={styles.ageHint}>
        <NitroxText textType={'settings-description'} style={[styles.textBoxText, styles.ageHintText]}>
          {t('profileWizard.profileAgeIs', {age: profileAge ?? '-'})}
        </NitroxText>
      </View>
    </View>
  );
};

export default React.memo(BirthInput);
