/* eslint-disable schange-rules/no-literal-color */
import {useEffect, useRef, useState} from 'react';

import {useFunction} from 'hooks/Hooks';

export function useWebFocusListener(listener: (element: Element) => void): void {
  const externalListener = useFunction(listener);

  useEffect(() => {
    const internalListener = (event: FocusEvent) => {
      externalListener(event.target as Element);
    };
    document.addEventListener('focusin', internalListener);
    return () => document.removeEventListener('focusin', internalListener);
  }, [externalListener]);
}

export function useWebFocusedElement(): Element | undefined {
  const [focusedElement, setFocusedElement] = useState<Element>();

  useWebFocusListener(setFocusedElement);

  return focusedElement;
}

export function useWebFocusDebugAnimation(): void {
  const animation = useRef<Animation>();

  useWebFocusListener((element) => {
    animation.current?.cancel();

    animation.current = element.animate([
      {backgroundColor: 'darkblue', border: '1px solid blue'},
      {backgroundColor: 'black', border: '1px solid cyan'},
      {backgroundColor: 'darkblue', border: '1px solid blue'}
    ], {
      duration: 1500,
      iterations: Infinity
    });
  });
}
