import {createStyles} from 'common-styles';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxText from './NitroxText';
import Popup, {PopupAction} from './Popup';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  modal: {
    alignItems: 'center'
  },
  modalText: {
    color: colors.popup.text
  }
}));

type LogoutOverlayProps = {
  onLogout: () => void;
  visible: boolean;
  onClose: () => void;
}

const LogoutOverlay: React.FunctionComponent<LogoutOverlayProps> = props => {
  const {t} = useTranslation();
  const {onLogout, visible, onClose} = props;

  const styles = stylesUpdater.getStyles();
  return (
    <Popup
      actions={[PopupAction.POSITIVE, PopupAction.NEGATIVE]}
      visible={visible}
      onClose={onClose}
      positiveLabel={t('common.ok')}
      negativeLabel={t('common.cancel')}
      onNegative={onClose}
      onPositive={onLogout}
      title={t('common.logout')}
      containerStyle={styles.modal}
      menuHasPreferredFocus
    >
      <NitroxText style={styles.modalText} textType='dialog-message'>{t('common.logoutDescription')}</NitroxText>
    </Popup>
  );
};

export default React.memo(LogoutOverlay);
