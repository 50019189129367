import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {Animated} from 'react-native';
import Reanimated from 'react-native-reanimated';
import {createIconSetFromIcoMoon} from 'react-native-vector-icons';

import {dimensions} from 'common/constants';
import {MaybeAnimated} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import ChromecastDisconnected from 'brand/current/commonResources/svgs/ChromecastDisconnectedIcon.svg';
import RecordingIcon from 'brand/current/commonResources/svgs/RecordingIcon.svg';
import RecordingIconFailed from 'brand/current/commonResources/svgs/RecordingIconFailed.svg';
import RecordingIconFocused from 'brand/current/commonResources/svgs/RecordingIconFocused.svg';
import WarningCircle from 'brand/current/commonResources/svgs/WarningCircle.svg';

import ChromecastConnectingIcon from './chromecast/ChromecastConnectingIcon';
import NitroxImage from './NitroxImage';

import selection from '../../icomoon/selection.json';

export enum IconType {
  AddProfile = 'icon_add_profile',
  AppVersion = 'icon_app_version',
  AppVersionBig = 'icon_app_version_big',
  ArrowLeft = 'icon_arrow_left',
  ArrowRight = 'icon_arrow_right',
  ArrowLeftBold = 'icon_arrow_left_bold',
  ArrowRightBold = 'icon_arrow_right_bold',
  ArrowUpBold = 'icon_arrow_up_bold',
  ArrowDownBold = 'icon_arrow_down_bold',
  ArrowLeftThin = 'icon_arrow_left_thin',
  ArrowRightThin = 'icon_arrow_right_thin',
  Backoffice = 'icon_backoffice',
  BackofficeBig = 'icon_backoffice_big',
  Backspace = 'backspace',
  Backward = 'icon_backward',
  CancelRecordingDot = 'icon_cancel_recording',
  RecordSeries = 'icon_record_series',
  Cast = 'icon_chromecast',
  ChannelsList = 'icon_channel_list',
  ChannelListSettings = 'icon_settings_channel_list',
  Check = 'icon_check',
  Collapse = 'icon_collapse',
  Customer = 'icon_customer',
  CustomerBig = 'icon_customer_big',
  DeleteRecordingDot = 'icon_delete_recording',
  Device = 'icon_device',
  DeviceBig = 'devices_icon_big',
  Edit = 'icon_edit',
  Episodes = 'icon_episodes',
  ErrorAuthorizationLimitation = 'icon_error_authorization_limitation',
  ErrorConfiguration = 'icon_error_configuration',
  ErrorEntitlement = 'icon_error_entitlement',
  ErrorGeographic = 'icon_error_geographic',
  ErrorInternetConnection = 'icon_error_internet_connection',
  ErrorLimitation = 'icon_error_limitation',
  ErrorUnknown = 'icon_error_unknown',
  Forward = 'icon_forward',
  FullScreen = 'icon_fullscreen',
  FullscreenBack = 'icon_fullscreen_back',
  GoToLive = 'icon_go_to_live',
  Help = 'icon_help',
  HelpBig = 'icon_help_big',
  Language = 'icon_language',
  LanguageBig = 'icon_language_big',
  MoreActions = 'icon_more_actions',
  NotPlayable = 'icon_not_playable',
  ParentalControl = 'icon_parental_control',
  ParentalControlSettings = 'icon_settings_parental_control',
  ParentalControlSettingsBig = 'parental_control_icon_big',
  Pause = 'icon_pause',
  Pin = 'icon_pin',
  PinBig = 'icon_pin_big',
  PlaceholderTV = 'icon_noposter_livetv',
  PlaceholderVOD = 'icon_noposter',
  PlaceholderCast = 'icon_noposter_cast',
  Play = 'icon_play',
  Profile = 'icon_profile',
  ProfileBig = 'icon_profile_info_big',
  RecordDot = 'icon_record',
  RecordingDot = 'icon_recording',
  RecordedDot = 'icon_completed_recording',
  ScheduledRecordDot = 'icon_scheduled_recording',
  FailedRecordDot = 'icon_failed_recordings',
  Restart = 'icon_restart',
  Series = 'icon_series',
  ShowAll = 'icon_show_all',
  SkipBack = 'icon_skip_back',
  SkipBack10 = 'icon_skip_back_10',
  SkipForward = 'icon_skip_forward',
  SkipForward10 = 'icon_skip_forward_10',
  Subtitle = 'icon_subtitle',
  SubtitleMobile = 'icon_subtitle_mobile',
  CCSubtitle = 'icon_cc_subtitle',
  SwitchProfile = 'icon_switch_profile',
  Time = 'icon_time',
  TimeSettings = 'icon_time_settings',
  Toggle = 'icon_toggle',
  ToggleOn = 'icon_toggle_on',
  ToggleOff = 'icon_toggle_off',
  Trailer = 'icon_play_trailer',
  Trash = 'icon_trash',
  User = 'icon_user',
  UserAvatar = 'icon_user_avatar',
  Warning = 'icon_warning',
  BackMobile = 'icon_back_mobile',
  BackSTB = 'icon_back_stb',
  Calendar = 'icon_calendar',
  Download = 'icon_download',
  Like = 'icon_like',
  Dislike = 'icon_dislike',
  Add = 'icon_plus',
  Buy = 'icon_buy',
  Rent = 'icon_rent',
  ClearSearch = 'clear_search_icon',
  FiltersSearch = 'filters_search_icon',
  Epg = 'epg_icon',
  Close = 'close_icon',
  Search = 'search_icon',
  BackSTBFocused = 'icon_back_stb_focused',
  CircledTrash = 'icon_circled_trash',
  CheckBoxActive = 'check_box_active',
  CheckBoxInactive = 'check_box_inactive',
  OvalDevice = 'oval_devices',
  SettingsAndroid = 'settings_android',
  NewStyle = 'icon_newstyle',
  MoreInfo = 'icon_more_info',
  Tv = 'icon_tv',
  TvSeries = 'icon_tv_series',
  Films = 'icon_films',
  HomeSidebar = 'icon_home_sidebar',
  HomeSidebarActive = 'icon_home_sidebar_active',
  LiveTvSidebar = 'icon_live_tv_sidebar',
  LiveTvSidebarActive = 'icon_live_tv_sidebar_active',
  SettingsSidebar = 'icon_settings_sidebar',
  SettingsSidebarActive= 'icon_settings_sidebar_active',
  EpgSidebar = 'icon_epg_sidebar',
  EpgSidebarActive = 'icon_epg_sidebar_active',
  VodSidebar = 'icon_films_sidebar',
  VodSidebarActive = 'icon_films_sidebar_active',
  SearchSidebar = 'icon_search_sidebar',
  SearchSidebarActive = 'icon_search_sidebar_active',
  PvrSidebar = 'icon_pvr_sidebar',
  PvrSidebarActive = 'icon_pvr_sidebar_active',
  WatchListSidebar = 'icon_watchlist_sidebar',
  WatchListSidebarActive= 'icon_watchlist_sidebar_active',
  NotificationsSidebar = 'icon_notification_sidebar',
  NotificationsSidebarActive = 'icon_notification_sidebar_active',
  ApplicationsSidebar = 'icon_applications_sidebar',
  ApplicationsSidebarActive = 'icon_applications_sidebar_active',
  Remove = 'icon_x',
  GoToNow = 'icon_go_to_now',
  Orders = 'icon_orders_big',
  Stop = 'icon_stop',
  CastConnected = 'icon_chromecast_connected',
  CastDisconnected = 'icon_chromecast_disconnected',
  CastConnecting = 'icon_chromecast_connecting',
  Screen = 'icon_screen',
  Volume = 'icon_volume',
  LT = 'icon_lt',
  GT = 'icon_gt',
  DoubleLT = 'icon_double_lt',
  DoubleGT = 'icon_double_gt',
  SlimArrowUp = 'slim_arrow_up',
  SlimArrowDown = 'slim_arrow_down',
  FastForward = 'icon_fast_forward',
  FastBackwards = 'icon_fast_backwards',
  VoucherSidebar = 'icon_voucher_sidebar',
  VoucherSidebarActive = 'icon_voucher_sidebar_active',
  Lock = 'icon_lock',
  FailedRecording = 'icon_failed_recording',
  WarningCircle = 'icon_warning_circle',
  PlusCircle = 'icon_plus_circle',
  MiniBack = 'icon_mini_back_button',
  Login = 'icon_login'
}

export type IconProps = {
  type: IconType;
  size?: number;
  color?: string;
  focused?: boolean;
  shadow?: boolean;
}

const IconSet = createIconSetFromIcoMoon(selection);

const ReanimatedIconSet = Reanimated.createAnimatedComponent(IconSet);
const AnimatedIconSet = Animated.createAnimatedComponent(IconSet);

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  iconColor: colors.defaultColors.icon,
  iconShadow: {
    textShadowColor: colors.defaultColors.iconShadow,
    textShadowRadius: dimensions.shadows.small,
    textAlign: 'center',
    paddingTop: dimensions.shadows.small
  }
}));

type IconTypeString = keyof typeof IconType;

for (const icon in IconType) {
  // TODO: CL-6130 do not check icons not used via font
  if (!IconSet.hasIcon(IconType[icon as IconTypeString])) {
    throw new Error(`Icon named ${IconType[icon as IconTypeString]} does not exist`);
  }
}

type AnimatedIconProps = Omit<IconProps, 'size'> & {
  size: MaybeAnimated<number>;
};

export const AnimatedIcon: React.FC<AnimatedIconProps> = props => {
  const styles = stylesUpdater.getStyles();
  const {type, size = 0, color, shadow} = props;

  const shadowStyle = useMemo(() => {
    if (shadow) {
      const iconSize = Animated.add(size, Animated.multiply(2, dimensions.shadows.small));
      return [{width: iconSize, height: iconSize}, styles.iconShadow];
    }
  }, [shadow, size, styles.iconShadow]);

  const style = useMemo(() => [shadowStyle, {fontSize: size}], [shadowStyle, size]);

  return <AnimatedIconSet style={style} name={type} color={color || styles.iconColor} />;
};

type ReanimatedIconProps = Omit<IconProps, 'size'> & {
  size: Reanimated.Node<number>;
};

export const ReanimatedIcon: React.FC<ReanimatedIconProps> = props => {
  const styles = stylesUpdater.getStyles();
  const {type, size = 0, color, shadow} = props;

  const shadowStyle = useMemo(() => {
    if (shadow) {
      const iconSize = Reanimated.add(size, Reanimated.multiply(2, dimensions.shadows.small));
      return [{width: iconSize, height: iconSize}, styles.iconShadow];
    }
  }, [shadow, size, styles.iconShadow]);

  const style = useMemo(() => [shadowStyle, {fontSize: size}], [shadowStyle, size]);

  return <ReanimatedIconSet style={style} name={type} color={color || styles.iconColor} />;
};

export const Icon: React.FunctionComponent<IconProps> = props => {
  const styles = stylesUpdater.getStyles();
  const {type, size = 0, color, focused, shadow} = props;

  const svgStyle = useMemo(() => {
    return {width: size, height: size};
  }, [size]);

  const shadowStyle = useMemo(() => {
    if (shadow) {
      const iconSize = size + 2 * dimensions.shadows.small;
      return [{width: iconSize, height: iconSize}, styles.iconShadow];
    }
  }, [shadow, size, styles.iconShadow]);

  switch (type) {
    // TODO: CL-6130 move to external component
    case IconType.RecordingDot:
      return <NitroxImage svg={focused ? RecordingIconFocused : RecordingIcon} style={svgStyle} />;
    case IconType.CastDisconnected:
      return <NitroxImage svg={ChromecastDisconnected} style={svgStyle} />;
    case IconType.CastConnecting:
      return <ChromecastConnectingIcon style={svgStyle} />;
    case IconType.FailedRecording:
      return <NitroxImage svg={RecordingIconFailed} style={svgStyle} />;
    case IconType.WarningCircle:
      return <NitroxImage svg={WarningCircle} style={svgStyle} />;
    default:
      return <IconSet style={shadowStyle} name={type} size={size} color={color || styles.iconColor} />;
  }
};
