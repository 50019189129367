import {createStyles} from 'common-styles';
import React, {useMemo, useRef, useState, RefObject} from 'react';
import {useTranslation} from 'react-i18next';
import {ViewStyle, TextStyle, StyleProp, LayoutChangeEvent} from 'react-native';

import {dimensions, isMobile} from 'common/constants';

import {FocusableComponent} from 'components/focusManager/FocusManagerTypes';
import {IconType} from 'components/Icon';
import {TextType} from 'components/NitroxText';
import {useBackPressCapture, useFunction, useScreenInfo, useLazyEffect} from 'hooks/Hooks';
import {PostProcessors} from 'locales/i18nPostProcessors';
import {useLocalized} from 'locales/i18nUtils';

import PopupContent from './PopupContent';

export enum PopupAction {
  NEGATIVE, POSITIVE, NEUTRAL
}

const styles = createStyles({
  menuButton: {
    width: dimensions.popup.button.width,
    height: dimensions.popup.button.height,
    ...isMobile && {
      margin: dimensions.margins.medium
    }
  }
});

enum ActionKey {
  Negative = 'negative',
  Positive = 'positive',
  Neutral = 'neutral'
}

export type PopupProps = {
  visible?: boolean;
  title?: string;
  subtitle?: string;
  actions?: PopupAction[];
  actionsSeparator?: boolean;
  width?: number;
  titleStyle?: StyleProp<ViewStyle>;
  titleType?: TextType;
  subtitleStyle?: TextStyle;
  subtitleType?: TextType;
  containerStyle?: StyleProp<ViewStyle>;
  menuStyle?: StyleProp<ViewStyle>;
  menuButtonStyle?: StyleProp<ViewStyle>;
  menuButtonDisabled?: boolean[];
  menuRef?: RefObject<FocusableComponent>;
  menuHasPreferredFocus?: boolean;
  negativeLabel?: string;
  positiveLabel?: string;
  neutralLabel?: string;
  portal?: string;
  focusNearestParentOnClose?: boolean;
  onClose?: () => void;
  onModalClose?: () => void;
  onNegative?: () => void;
  onPositive?: () => void;
  onNeutral?: () => void;
  onLayout?: (event: LayoutChangeEvent) => void;
  highlightPositiveButton?: boolean;
  icon?: IconType;
}

const Popup: React.FunctionComponent<PopupProps> = props => {
  const {size: {width: screenWidth}} = useScreenInfo();
  const {t} = useTranslation();
  const {toUpperCase} = useLocalized();
  const {
    visible,
    title,
    subtitle,
    actions = [PopupAction.NEGATIVE],
    actionsSeparator,
    width = isMobile ? screenWidth : dimensions.popup.width,
    titleStyle,
    subtitleStyle,
    subtitleType,
    containerStyle,
    menuStyle,
    menuButtonStyle,
    menuButtonDisabled,
    menuRef: menuRefFromProps,
    menuHasPreferredFocus = true,
    negativeLabel = t('common.cancel', {postProcess: PostProcessors.ToUpperCase}),
    positiveLabel = t('common.ok', {postProcess: PostProcessors.ToUpperCase}),
    portal,
    focusNearestParentOnClose,
    neutralLabel,
    onClose,
    onModalClose,
    onNegative,
    onPositive,
    onNeutral,
    onLayout,
    highlightPositiveButton = true,
    icon
  } = props;

  const internalMenuRef = useRef<FocusableComponent>(null);
  const menuRef = menuRefFromProps || internalMenuRef;
  const [focusedKey, setFocusedKey] = useState(actions[0] === PopupAction.NEGATIVE ? 'negative' : 'positive');
  const onFocus = useFunction((actionKey: string) => {
    setFocusedKey(actionKey);
  });

  useLazyEffect(() => {
    setFocusedKey(actions[0] === PopupAction.NEGATIVE ? 'negative' : 'positive');
  }, [visible], [actions]);

  const onCloseHandler = useFunction(() => {
    onClose && onClose();
  });

  const onModalCloseHandler = useFunction(() => {
    onCloseHandler();
    onModalClose && onModalClose();
  });

  const onNegativeHandler = useFunction(() => {
    onCloseHandler();
    onNegative && onNegative();
  });

  const onPositiveHandler = useFunction(() => {
    onCloseHandler();
    onPositive && onPositive();
  });

  const onNeutralHandler = useFunction(() => {
    onCloseHandler();
    onNeutral && onNeutral();
  });

  const onBackPressCapture = useFunction(() => {
    if (!visible) {
      return false;
    }
    onCloseHandler();
    return true;
  });
  useBackPressCapture(onBackPressCapture);

  const filteredActions = actions.filter(action => action !== PopupAction.NEUTRAL || neutralLabel);
  const actionsData = useMemo(() => filteredActions.map((action, index) => {
    let actionKey: ActionKey;
    let text: string | undefined;
    let onPress: () => void;
    switch (action) {
      case PopupAction.NEGATIVE:
        actionKey = ActionKey.Negative;
        text = negativeLabel;
        onPress = onNegativeHandler;
        break;
      case PopupAction.POSITIVE:
        actionKey = ActionKey.Positive;
        text = positiveLabel;
        onPress = onPositiveHandler;
        break;
      default:
        actionKey = ActionKey.Neutral;
        text = neutralLabel;
        onPress = onNeutralHandler;
        break;
    }
    return ({
      key: actionKey,
      ref: actionKey === focusedKey ? menuRef : null,
      hasTvPreferredFocus: menuHasPreferredFocus && actionKey === focusedKey,
      text: text && toUpperCase(text),
      style: [styles.menuButton, menuButtonStyle],
      onPress: onPress,
      onFocus: () => onFocus(actionKey),
      highlighted: highlightPositiveButton && action === PopupAction.POSITIVE,
      disabled: !!menuButtonDisabled?.[index]
    });
  }), [filteredActions, focusedKey, menuRef, menuHasPreferredFocus, toUpperCase, negativeLabel, positiveLabel, neutralLabel, menuButtonStyle, menuButtonDisabled, onNegativeHandler, onPositiveHandler, onNeutralHandler, onFocus, highlightPositiveButton]);

  return (
    <PopupContent
      onModalClose={onModalCloseHandler}
      actions={actionsData}
      actionsSeparator={actionsSeparator}
      title={title}
      subtitle={subtitle}
      width={width}
      menuStyle={menuStyle}
      menuRef={menuRef}
      containerStyle={containerStyle}
      titleStyle={titleStyle}
      subtitleStyle={subtitleStyle}
      subtitleType={subtitleType}
      visible={visible}
      icon={icon}
      portal={portal}
      focusNearestParentOnClose={focusNearestParentOnClose}
      onLayout={onLayout}
    >
      {props.children}
    </PopupContent>
  );
};

export default Popup;
