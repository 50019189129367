import {Log} from 'common/Log';

import {Error, ErrorType} from 'mw/api/Error';
import {ProfileMapper} from 'mw/bo-proxy/bo/traxis/mappers/profileMapper';
import {ResponseJson} from 'mw/bo-proxy/bo/traxis/TraxisTypes';
import {HttpStatus} from 'mw/utils/HttpUtils';

const TAG = 'ErrorHandlers';

enum InternalError {
  EInvalidAlias = 'EInvalidAlias',
  EInvalidSessionId = 'EInvalidSessionId',
  EInvalidSTBId = 'EInvalidSTBId',
  ERestServiceError = 'ERestServiceError',
  ESsoError = 'ESsoError',
  EAccessEntitlementIssue = 'EAccess.EntitlementIssue',
  ECustomerInvalidStbId = 'ECustomer.InvalidStbId',
  EGeoLocationAccessFromLocationForbidden = 'EGeoLocation.AccessFromLocationForbidden',
  ESession5JIncompatibleChannelDetected = 'ESession5J.IncompatibleChannelDetected',
  ESession5JIncompatibleContentDetected = 'ESession5J.IncompatibleContentDetected',
  ESession5JIncompatibleEncodingProfiles = 'ESession5J.IncompatibleEncodingProfiles',
  ESystemTooManyConcurrentSessions = 'ESystem.TooManyConcurrentSessions',
  EQuotaExceeded = 'ERecording.QuotaExceeded',
  EInvalidContentId = 'EInvalidContentId',
  EGeneralError = 'EGeneralError'
}

enum LegacyInternalError {
  EQuotaExceeded = 'EQuotaExceeded'
}

const ssoAuthenticationMarker = 'SeacToken';

const notSupportedPropertyTag = 'Builder for resource';

export function validateResponse<T extends ResponseJson>(response: T): Promise<T> {
  return !response.Error ? Promise.resolve(response) : Promise.reject(new Error(ErrorType.BOBadResponse, response.Error.Message));
}

export function validateCustomer(response: ResponseJson): Promise<ResponseJson> {
  return validateResponse(response)
    .catch(error => {
      if (response.Error.InternalError === InternalError.EInvalidAlias) {
        return Promise.reject(new Error(ErrorType.BOInvalidCustomerID));
      }
      return Promise.reject(error);
    });
}

export function validateCreateSession(responseJson: ResponseJson): Promise<ResponseJson> {
  const internalError = responseJson?.Error?.InternalError;
  if (!internalError) {
    return Promise.resolve(responseJson);
  }

  switch (internalError) {
    case InternalError.EAccessEntitlementIssue: return Promise.reject(new Error(ErrorType.PlaybackEntitlementIssue));
    case InternalError.EGeoLocationAccessFromLocationForbidden: return Promise.reject(new Error(ErrorType.PlaybackLocationForbidden));
    case InternalError.ESession5JIncompatibleChannelDetected:
    case InternalError.ESession5JIncompatibleContentDetected:
    case InternalError.ESession5JIncompatibleEncodingProfiles:
      return Promise.reject(new Error(ErrorType.PlaybackSessionContentNotPlayable));
    case InternalError.ESystemTooManyConcurrentSessions: return Promise.reject(new Error(ErrorType.PlaybackTooManyConcurrentSessions));
    case InternalError.EInvalidContentId: return Promise.reject(new Error(ErrorType.PlaybackSessionInvalidContent));
    case InternalError.EGeneralError:
    default:
      return Promise.reject(new Error(ErrorType.PlaybackSessionGeneralError, responseJson?.Error?.Message));
  }
}

export function validateHttpStatus(response: Response, responseJson: ResponseJson): Promise<ResponseJson> {
  if (response.status === HttpStatus.NotFound) {
    return Promise.reject(new Error(ErrorType.HttpNotFound));
  }

  if (response.status >= HttpStatus.InternalServerError) {
    return Promise.reject(new Error(ErrorType.BOServerError, `${TAG} : ${response.status} : ${response.statusText}`));
  }

  return Promise.resolve(responseJson);
}

export function validateDeviceRegistration(response: ResponseJson): Promise<any> {
  return validateResponse(response)
    .catch(() => {
      const errorMessage: string = response.Error.Message;
      Log.error(TAG, 'registerDevice: ' + errorMessage);

      if (errorMessage.includes('Maximum number of not back-office managed devices') || errorMessage.includes('Not allowed to register cpe. Maximum registrations reached.')) {
        return Promise.reject(new Error(ErrorType.TooManyDevices, errorMessage));
      } else if (errorMessage.includes('Customer has no available mutations left')) {
        return Promise.reject(new Error(ErrorType.TooManyDeviceSwaps, errorMessage));
      } else {
        return Promise.reject(new Error(ErrorType.FailedToRegisterNewDevice, errorMessage));
      }
    });
}

export function validateDeviceUnregistration(response: ResponseJson): Promise<any> {
  return validateResponse(response)
    .catch(() => {
      const errorMessage: string = response.Error.Message;
      Log.error(TAG, 'unregisterDevice: ' + errorMessage);

      if (errorMessage.includes('Customer has no available mutations left')) {
        return Promise.reject(new Error(ErrorType.TooManyDeviceSwaps, errorMessage));
      } else {
        return Promise.reject(new Error(ErrorType.FailedToUnregisterDevice, errorMessage));
      }
    });
}

export function validateAuthorizationError(response: Response, responseJson: ResponseJson): Promise<ResponseJson> {
  if (response.status >= HttpStatus.Ok && response.status < HttpStatus.MultipleChoices) {
    return Promise.resolve(responseJson);
  }

  const internalError = responseJson && responseJson.Error && responseJson.Error.InternalError;
  switch (internalError) {
    case InternalError.ESsoError: return Promise.reject(new Error(ErrorType.BOInvalidAuthentication));
    case InternalError.EInvalidAlias: return Promise.reject(new Error(ErrorType.BOInvalidCustomerID));
    case InternalError.EInvalidSTBId:
    case InternalError.ECustomerInvalidStbId:
      return Promise.reject(new Error(ErrorType.DeviceUnregistered, responseJson.Error.Message));
  }

  const authenticateHeader = response.headers && response.headers.get('www-authenticate');
  if (!authenticateHeader) {
    return Promise.resolve(responseJson);
  }

  const challenge = authenticateHeader && authenticateHeader.split(/\s/);
  return challenge && challenge.indexOf(ssoAuthenticationMarker) >= 0 && Promise.reject(new Error(ErrorType.BOInvalidAuthentication)) || Promise.resolve(responseJson);
}

export function validateProfilePin(response: ResponseJson, pin: string): Promise<void> {
  const correctPin = ProfileMapper.profilePinFromJson(response && response.Profile);
  if (correctPin.ok) {
    if (correctPin.value !== pin) {
      return Promise.reject(new Error(ErrorType.IncorrectPin));
    }
  } else {
    return Promise.reject(correctPin.error);
  }

  return Promise.resolve();
}

export function validateNotSupportedPropertyError(response: ResponseJson): Promise<ResponseJson> {
  const internalError = response.Error?.InternalError;
  const detectError = internalError === InternalError.ERestServiceError && (response.Error.Message as string)?.startsWith?.(notSupportedPropertyTag);
  return detectError ? Promise.reject(new Error(ErrorType.BONotSupportedProperty, response.Error.Message)) : Promise.resolve(response);
}

export function validateQuotaExceededError(response: ResponseJson): Promise<ResponseJson> {
  const quotaExceeded = [InternalError.EQuotaExceeded, LegacyInternalError.EQuotaExceeded].includes(response.Error?.InternalError);
  return quotaExceeded ? Promise.reject(new Error(ErrorType.BOPvrQuotaExceeded, response.Error.Message)) : Promise.resolve(response);
}

export function validateKeepAliveSession(responseJson: ResponseJson): Promise<ResponseJson> {
  const internalError = responseJson?.Error?.InternalError;

  switch (internalError) {
    case InternalError.EInvalidSessionId: return Promise.reject(new Error(ErrorType.PlaybackSessionExpired, responseJson.Error.Message));
  }

  return Promise.resolve(responseJson);
}
