import React, {useMemo, useContext} from 'react';
import {View, ViewStyle} from 'react-native';

import {isNonMobileWeb} from 'common/constants';

import App from 'apps/default/App';
import {useScreenInfo, getBaseSize} from 'hooks/Hooks';

/**
 * Renders web content scaled to full hd design.
 * Embeds App in fhd View scaled down to current window size, respecting current aspect ratio.
 *
 * This is hacky antipattern that should never be used.
 * This solution is nowhere near RWD and is not meant to remain.
 */
function useScaleCheat() {
  const {trueSize: {width, height}} = useScreenInfo();
  const {width: baseWidth, height: baseHeight, scale} = getBaseSize({width, height});

  return {
    transform: `scale(${scale})`,
    width: baseWidth,
    height: baseHeight,
    scale: scale
  };
}

type AppScaleContextType = {
  scale: number;
}
export const AppScaleContext = React.createContext<AppScaleContextType>({scale: 1});
export function useAppScale(): number {
  return useContext(AppScaleContext).scale;
}

const DefaultRoute: React.FC = () => {
  const {transform, width, height, scale} = useScaleCheat();

  // disable scaling for TA overriden mobile web platforms which rely on "piccolo" layout
  const containerStyle = useMemo(() => isNonMobileWeb
    ? {
      flex: 1,
      transform,
      width,
      height,
      minHeight: height,
      maxHeight: height,
      transformOrigin: '0 0'
      // string `transform` property is not accepted by react-native typing
    } as unknown as ViewStyle
    : {flex: 1},
  [height, transform, width]);

  const scaleContext = useMemo(() => ({scale}), [scale]);

  return (
    <View style={{flex: 1}}>
      <View style={containerStyle}>
        <AppScaleContext.Provider value={scaleContext}>
          <App />
        </AppScaleContext.Provider>
      </View>
    </View>
  );
};

export default DefaultRoute;
