import React, {useImperativeHandle, useMemo, useRef, useCallback} from 'react';
import {Insets, View} from 'react-native';

import {isDesktopBrowser, isSTBBrowser, dimensions} from 'common/constants';

import {ComponentDataSourceType as UXMComponentDataSourceType} from 'mw/api/CMSInterface';
import {Component as UXMComponent} from 'mw/cms/Component';
import {Menu} from 'mw/cms/Menu';
import {mw} from 'mw/MW';

import {AnimatedSwimlane, AnimatedSwimlaneInterface} from 'components/AnimatedSwimlane';
import {defaultSwimlaneInsets} from 'components/AnimatedSwimlaneStack';
import {AnimatedVerticalStackRowProps} from 'components/AnimatedVerticalStack';
import MenuTile, {useMenuTileStyling} from 'components/MenuTile';
import MenuTilePlaceholder from 'components/MenuTilePlaceholder';
import {SwimlaneDataFetcherState, SwimlaneTileProps, AnimatedTileProps} from 'components/Swimlane';
import {useFunction} from 'hooks/Hooks';

import {UXMComponentStackRowInterface} from './UXMComponentStackRow';

type Props = {
  component: UXMComponent & {
    dataSource: {type: UXMComponentDataSourceType.Menu};
  }
  onMenuPress?: (menu: Menu) => void;
  insets?: Insets;
} & AnimatedVerticalStackRowProps;

const MenuSwimlane = React.memo(React.forwardRef<UXMComponentStackRowInterface, Props>(({
  index,
  component,
  layout,
  insets,
  focused,
  onElementFocus,
  onMenuPress,
  onHover,
  onLoaded
}: Props, ref) => {
  const swimlaneRef = useRef<AnimatedSwimlaneInterface>(null);

  /* eslint-disable schange-rules/no-use-imperative-handle-hook */
  useImperativeHandle(ref, () => ({
    onPress: () => {
      swimlaneRef.current?.onPress();
    },
    onNavigateLeft: () => {
      swimlaneRef.current?.scrollLeft();
    },
    onNavigateRight: () => {
      swimlaneRef.current?.scrollRight();
    },
    onNavigateUp: () => {
      return {shouldStay: swimlaneRef.current?.navigateUp() ?? false};
    },
    onNavigateDown: () => {
      return {shouldStay: swimlaneRef.current?.navigateDown() ?? false};
    }
  }), []);
  /* eslint-enable schange-rules/no-use-imperative-handle-hook */

  const onSwimlaneEmpty = useFunction(() => onLoaded(true));
  const onTileFocus = useFunction((tileIndex: number, _: unknown, __: unknown, headerActionsVisible: boolean) => {
    onElementFocus(tileIndex, !headerActionsVisible);
  });
  const onHeaderFocus = useFunction((tileIndex: number) => {
    onElementFocus(tileIndex, true);
  });

  const onDataFetcherStateChanged = useFunction((state: SwimlaneDataFetcherState) => {
    if (state === SwimlaneDataFetcherState.HasData) {
      onLoaded(false);
    }
  });

  const createTile = useCallback((tileProps: SwimlaneTileProps<Menu>, animatedTileProps?: AnimatedTileProps) => {
    // eslint-disable-next-line react/display-name
    return (focused: boolean) => (
      <MenuTile
        ref={animatedTileProps?.refHandler}
        key={tileProps.data.slug}
        menu={tileProps.data}
        tileShape={component.settings?.tileShape ?? 'circle'}
        focused={focused}
        onPress={onMenuPress}
      />
    );
  }, [component.settings?.tileShape, onMenuPress]);

  const itemWidth = component.settings?.tileShape === 'rectangle'
    ? dimensions.menuTile.rectangleTileWidth
    : dimensions.menuTile.circleTileWidth;

  const {focusFrameStyle} = useMenuTileStyling(component.settings?.tileShape ?? 'circle', true);

  const renderFocusedTileFrame = useCallback(() => {
    return <View style={focusFrameStyle} />;
  }, [focusFrameStyle]);

  const placeholderComponent = useCallback(() => {
    // eslint-disable-next-line react/display-name
    return (
      <MenuTilePlaceholder
        tileShape={component.settings?.tileShape ?? 'circle'}
      />
    );
  }, [component.settings?.tileShape]);

  const dataFetcher = useMemo(() => {
    const menuSlug = component.dataSource.menuSlug;
    return (async function* () {
      try {
        const menu = await mw.cms.getMenu(menuSlug, 1);
        const limit = component.settings?.itemsLimit ?? menu.items.length;
        return menu.items.slice(0, limit);
      } catch (error) {
        return [];
      }
    })();
  }, [component.dataSource.menuSlug, component.settings?.itemsLimit]);

  const swimlaneInsets = useMemo(() => ({
    ...defaultSwimlaneInsets,
    ...insets
  }), [insets]);

  return (
    <AnimatedSwimlane<Menu>
      /* eslint-disable @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      ref={swimlaneRef}
      /* eslint-enable @typescript-eslint/ban-ts-comment */
      key={index}
      row={index}
      style={layout}
      focused={focused}
      header={component.title}
      insets={swimlaneInsets}
      itemWidth={itemWidth + 2 * (dimensions.menuTile.borderPadding + dimensions.menuTile.borderWidth)}
      itemHeight={dimensions.menuTile.tileHeight}
      onHoverChange={onHover}
      onSwimlaneEmpty={onSwimlaneEmpty}
      fixedFocusPosition
      onTileFocus={onTileFocus}
      onHeaderTileFocus={onHeaderFocus}
      createTile={createTile}
      renderFocusedTileFrame={renderFocusedTileFrame}
      dataFetcher={dataFetcher}
      onDataFetcherStateChanged={onDataFetcherStateChanged}
      showHeaderActions={false}
      placeholderComponent={placeholderComponent}
      renderNavigationArrows={isDesktopBrowser && !isSTBBrowser}
    />
  );
}));
MenuSwimlane.displayName = 'MenuSwimlane';

export default MenuSwimlane;

