export enum ActionType {
  Custom = 'CUSTOM',
  Page = 'PAGE',
  Filterng = 'FILTERNG',
  AdrenalinNode = 'ADRENALIN_NODE'
}

export enum AssetTypes {
  Movie = 'movie',
  Episode = 'episode',
  Season = 'season',
  Series = 'series',
  Trailer = 'trailer'
}
