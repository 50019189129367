import React from 'react';

type Props = {
  condition: boolean;
  children?: React.ReactNode;
}

export function If({
  condition,
  children
}: Props): React.ReactElement | null {
  return <>{condition ? children : null}</>;
}

type CaseProps<T> = {
  value: T;
  children?: React.ReactNode;
};

export function Case<T>({children}: CaseProps<T>): React.ReactElement | null {
  return <>{children}</>;
}

type CaseElement<T> = React.ReactElement<CaseProps<T>, typeof Case>;

type SwitchProps<T> = {
  expression: T;
  children: CaseElement<T>[];
  match?: (lhs: T, rhs: T) => boolean;
}

export function Switch<T>({expression, children, match = (lhs, rhs) => lhs === rhs}: SwitchProps<T>): React.ReactElement | null {
  return React.Children
    .toArray(children)
    .find(child => match(child.props.value, expression))
    ?? null;
}
