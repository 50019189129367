import React from 'react';
import {useTranslation} from 'react-i18next';
import {NavigationScreenProps} from 'react-navigation';

import {isBigScreen} from 'common/constants';

import {IconType} from 'components/Icon';

import SettingsDeleteProfile, {SettingsDeleteProfileProps} from './SettingsDeleteProfile';
import SettingsDetails from './SettingsDetails';
import {SettingsDetailsHeader} from './SettingsDetailsHeader';

const barHeight = isBigScreen ? 75 : 50;

type SettingsDeleteProfileScreenProps = SettingsDeleteProfileProps;

const SettingsDeleteProfileScreen: React.FC<NavigationScreenProps<SettingsDeleteProfileScreenProps> & SettingsDeleteProfileScreenProps> = ({
  navigation, pin: propsPin
}) => {
  const {t} = useTranslation();
  const pin = typeof propsPin === 'string' ? propsPin : navigation.getParam('pin', '');
  return (
    <SettingsDetails
      title={t('settings.deleteProfile')}
      barText={t('settings.removalOfCurrentProfile')}
      barHeight={barHeight}
      header={<SettingsDetailsHeader icon={IconType.Trash} />}
      navigation={navigation}
      testID='screen_settings_delete_profile'
    >
      <SettingsDeleteProfile pin={pin} />
    </SettingsDetails>
  );
};

export default SettingsDeleteProfileScreen;
