import {RecommendationsSource} from 'mw/api/CatalogInterface';
import {SearchSource} from 'mw/api/CatalogInterface';
import {BOInterface} from 'mw/bo-proxy/BOInterface';
import {FakeVodRecommendations, FakeEpgRecommendations} from 'mw/common/recommendations/FakeRecommendations';

import {RecommendationsEngine} from './RecommendationsEngine';

export function createRecommendationsEngine(source: RecommendationsSource, boAdapter: BOInterface): RecommendationsEngine | null {
  return source === SearchSource.Epg ? new FakeEpgRecommendations() : new FakeVodRecommendations(boAdapter);
}
