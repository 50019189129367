import {SearchField} from 'mw/api/CatalogInterface';
import {SortingOrder, SortVodBy, Title, VodFilter, VodSorting, SortOrdersBy} from 'mw/api/Metadata';

const TAG = 'ADR8Utils';

const VIEWED_COMPLETELY_THRESHOLD = 0.9;

/* The following Tag typedefs are for MW's internal use only */
export type Tag = {
  key: string;
  value?: string;
};

export class ADR8Utils {
  public static bookmarkFromJson(bookmarkJson?: any): number {
    const {progress} = bookmarkJson;

    return typeof progress === 'number' ? progress : -1;
  }

  public static setTitleBookmark(title: Title, bookmark: number) {
    title.bookmark = bookmark;
    title.isViewedCompletely = title.metadata.duration > 0 && title.metadata.duration >= bookmark && title.metadata.duration * VIEWED_COMPLETELY_THRESHOLD < bookmark;
  }

  public static queryParamsFromOptions(options?: {filter?: VodFilter; sorting?: VodSorting}): {[key: string]: string} {
    if (!options) {
      return {};
    }
    return {
      ...options.sorting && {sort: `${options.sorting.type === SortVodBy.title ? 'title' : 'releaseYear'}-${options.sorting.ascending ? SortingOrder.ascending : SortingOrder.descending}`}
    };
  }

  public static defaultSortOrders() {
    return `${SortOrdersBy.creationDate},${SortingOrder.descending}`;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static tagsFromJson(json: any): Tag[] {
    return json.map((tag: string) => {
      const separatorIndex = tag.lastIndexOf(':');
      if (separatorIndex === -1) {
        return {key: tag};
      }
      return {
        key: tag.substring(0, separatorIndex),
        value: tag.substring(separatorIndex + 1)
      };
    });
  }

  public static findTagValueAsString(tags: Tag[], key: string, defaultValue: string): string {
    const tag = tags.find(tag => tag.key.startsWith(key));
    return (tag && tag.value) || defaultValue;
  }

  public static findTagValueAsNumber(tags: Tag[], key: string, defaultValue: number): number {
    const tag = tags.find(tag => tag.key.startsWith(key));
    if (tag && tag.value) {
      const value = parseFloat(tag.value);
      if (!isNaN(value)) {
        return value;
      }
    }
    return defaultValue;
  }

  public static findTagValueAsBoolean(tags: Tag[], key: string): boolean {
    return ADR8Utils.findTagValueAsString(tags, key, 'false').toLowerCase() === 'true';
  }

  public static searchProfileForFields(searchFields?: SearchField[]): string | undefined {
    if (searchFields) {
      const fields: string[] = [];
      if (searchFields.includes('title')) {
        fields.push('title');
      }
      if (searchFields.includes('castAndCrew')) {
        fields.push('credits');
      }
      if (searchFields.includes('description')) {
        fields.push('shortDescription');
      }
      if (fields.length) {
        return fields.join('_');
      }
    }
  }

  public static continueWatchingProgressFilter(progress: number) {
    return progress > 0.05 && progress <= 0.95;
  }
}
