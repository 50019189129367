import {useMemo} from 'react';
import {ViewStyle} from 'react-native';

import {canBePurchasedOrRented} from 'common/HelperFunctions';

import {PurchaseFlowType} from 'mw/api/Configuration';
import {isTitle, Media, EntitlementState, RecordingStatus, RecordingErrorCode} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

export type RecordingLimitationsProps = {
  channel?: string;
  startingSeason?: number;
  status?: RecordingStatus
}

export type MediaInfoViewBaseProps = {
  media: Media;
  playbackProgress?: number;
  onStartPlayback?: (media: Media) => void;
  hasTVPreferredFocus?: boolean;
  isBlocked?: boolean;
  channelIconStyle?: ViewStyle;
  recordingLimitations?: RecordingLimitationsProps;
}

export type MediaInfoViewComponentProps = MediaInfoViewBaseProps & {
  recordingErrorCode?: RecordingErrorCode;
};

export function useShouldDisplayNotEntitledLabel(media: Media): boolean {
  const title = isTitle(media) ? media : undefined;
  const entitlementState = title?.entitlementState;
  return useMemo(() => {
    return !!title && entitlementState === EntitlementState.NotEntitled &&
      (mw.configuration.getPurchaseFlowType() === PurchaseFlowType.Message || !canBePurchasedOrRented(title));
  }, [title, entitlementState]);
}
