import React, {useState, ReactNode, useRef, RefObject, Component} from 'react';
import {View} from 'react-native';

import {isDesktopBrowser} from 'common/constants';

import {useLazyEffect} from 'hooks/Hooks';

import MouseAwareView from './MouseAwareView';
import {SwimlaneTileProps} from './Swimlane';

export type TileRenderer = (focused: boolean) => ReactNode;

type Props<TileData> = Omit<SwimlaneTileProps<TileData>, 'onFocus' | 'wrapAroundActive'> & {
  row: number;
  focused: boolean;
  onTileFocus?: (tileIndex: number, row: number, data: TileData, ref: RefObject<Component>) => void;
  createTileCallback: TileRenderer;
}

function AnimatedSwimlaneTile<TileData>(props: Props<TileData>) {
  const {
    data,
    index,
    row,
    focused,
    onTileFocus
  } = props;
  const [hovered, setHovered] = useState(false);
  const internalRef = useRef<View>(null);

  useLazyEffect(() => {
    if (focused) {
      onTileFocus?.(index, row, data, internalRef);
    }
  }, [focused], [index, data, onTileFocus, row]);

  const tile = props.createTileCallback(focused || hovered);
  if (isDesktopBrowser) {
    return (
      <MouseAwareView
        onHoverChange={setHovered}
      >
        <View ref={internalRef}>
          {tile}
        </View>
      </MouseAwareView>
    );
  }

  return (
    <View ref={internalRef}>
      {tile}
    </View>
  );
}

export default React.memo(AnimatedSwimlaneTile) as typeof AnimatedSwimlaneTile;
