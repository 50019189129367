import {Recording, Media, isEvent, RecordingStatus, Event} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

export enum RecordActionType {
  record = 'record',
  recordSeries = 'recordSeries',
  recordAll = 'recordAll',
  none = 'none'
}

export enum CancelRecordingActionType {
  cancel = 'cancel',
  cancelSeries = 'cancelSeries',
  none = 'none'
}

export enum RelatedRecordingsStatus {
  noneRecorded = 'noneRecorded',
  eventRecorded = 'eventRecorded',
  onlyNewRecorded = 'onlyNewRecorded',
  seriesRecorded = 'seriesRecorded',
  notAvailable = 'notAvailable'
}

export enum RecordOption {
  current = 'recordings.recordingConfigPopup.recordEpisodeButton',
  new = 'recordings.recordingConfigPopup.recordNewEpisodesButton',
  all = 'recordings.recordingConfigPopup.recordSeriesButton'
}

export type RelatedRecordings = {
  eventRecording?: Recording;
  seriesRecording?: Recording;
}

export function getRecordingsStatus(media: Media, eventRecording?: Recording, seriesRecording?: Recording): RelatedRecordingsStatus {
  if (!isEvent(media)) {
    return RelatedRecordingsStatus.notAvailable;
  }

  if (seriesRecording && seriesRecording.status === RecordingStatus.Active) {
    if (seriesRecording.seedEventId) {
      return RelatedRecordingsStatus.onlyNewRecorded;
    } else {
      return RelatedRecordingsStatus.seriesRecorded;
    }
  } else {
    if (eventRecording) {
      return RelatedRecordingsStatus.eventRecorded;
    } else {
      return RelatedRecordingsStatus.noneRecorded;
    }
  }
}

export function getRecordActionType(media: Media, eventRecording?: Recording, seriesRecording?: Recording): RecordActionType {
  if (!isEvent(media)) {
    return RecordActionType.none;
  }

  const recordingsStatus = getRecordingsStatus(media, eventRecording, seriesRecording);

  if (media.isSeriesNPVRAllowed && recordingsStatus === RelatedRecordingsStatus.onlyNewRecorded) {
    return RecordActionType.recordAll;
  }

  if (media.isSeriesNPVRAllowed && recordingsStatus === RelatedRecordingsStatus.eventRecorded) {
    return RecordActionType.recordSeries;
  }

  if ((media.isEventNPVRAllowed || media.isSeriesNPVRAllowed) && recordingsStatus === RelatedRecordingsStatus.noneRecorded) {
    return RecordActionType.record;
  }

  return RecordActionType.none;
}

export function getCancelRecordingActionType(media: Media, eventRecording?: Recording, seriesRecording?: Recording): CancelRecordingActionType {
  if (seriesRecording?.status === RecordingStatus.Active) {
    return CancelRecordingActionType.cancelSeries;
  }
  if (eventRecording?.status === RecordingStatus.Scheduled) {
    return CancelRecordingActionType.cancel;
  }
  return CancelRecordingActionType.none;
}

export function hasAvailableGuardTimes(event?: Event): {hasStartGuardTimes: boolean, hasEndGuardTimes: boolean, hasGuardTimes: boolean} {
  const availableGuardTimes = mw.pvr.getAvailableGuardTimes(event);

  return {
    hasStartGuardTimes: !!availableGuardTimes.start.length,
    hasEndGuardTimes: !!availableGuardTimes.end.length,
    hasGuardTimes: !!availableGuardTimes.start.length || !!availableGuardTimes.end.length
  };
}
