import moment from 'moment';

import {withinBoundaries} from 'common/utils';

import {Content, DateTimeDistance, Event, MediaType, Playable, Product, Title, RecordingStatus} from 'mw/api/Metadata';
import {nxffConfig} from 'mw/api/NXFF';
import {isPCDisabled} from 'mw/common/ParentalControl';
import {mw} from 'mw/MW';

import {MediaObject} from './MediaObject';
import {TitleObject} from './TitleObject';

interface ContentAllowed {
  playable: Content;
  product: Product;
}

export class EventObject extends MediaObject implements Event {
  public title: Title;
  public start: Date;
  public end: Date;
  public channelId: string;
  public tstvContents: Content[] | null = null;
  public pastTSTVEvent?: Event;

  public constructor(id: string, name = '', title: Title = new TitleObject(), start: Date = new Date(0), end: Date = new Date(0), channelId = '') {
    super(id, name);
    this.title = title;
    this.start = start;
    this.end = end;
    this.channelId = channelId;
  }

  public getType(): MediaType {
    return MediaType.Event;
  }

  public getPlayable(): Playable | null {
    if (this.pastTSTVEvent && this.isFuture) {
      return this.pastTSTVEvent.getPlayable();
    }

    const allowed: ContentAllowed[] = [];
    this.tstvContents?.forEach((content: Content) => {
      content.products
        .filter((product: Product) => product.isAllowed())
        .forEach((product: Product) => {
          allowed.push({
            playable: content,
            product
          });
        });
    });

    return allowed.reduce((ref: ContentAllowed | null, value) => {
      const refRelationEnd = ref?.product.relationEnd ?? new Date(0);
      const valueRelationEnd = value?.product.relationEnd ?? new Date(0);
      return valueRelationEnd > refRelationEnd ? value : ref;
    }, null)?.playable || null;
  }

  public isPlayAllowed(): boolean {
    if (this.pastTSTVEvent && this.isFuture) {
      return this.pastTSTVEvent.isPlayAllowed();
    }

    return this.tstvContents?.some(content => content.products.some((product: Product) => product.isAllowed())) ?? false;
  }

  private _isRecorded = false;
  public set isRecorded(value: boolean) {
    this._isRecorded = value;
  }

  public get isRecorded(): boolean {
    if (!nxffConfig.getConfig().PVR.KeepStatesOfRecordings) {
      return this._isRecorded;
    }
    const state = mw.pvr.statusCache.get(this.id);
    switch (state) {
      case RecordingStatus.Scheduled:
      case RecordingStatus.Recorded:
      case RecordingStatus.Recording:
        return true;
      case RecordingStatus.Failed:
      case RecordingStatus.Deleted:
      default:
        return false;
    }
  }

  public isAllowedOnWatchList(): boolean {
    return mw.configuration.enabledWatchlist && (!this.isPast || this.hasTstv || this.isRecorded);
  }

  public get isEventNPVRAllowed(): boolean {
    return mw.pvr.isEventNPVRAllowed(this);
  }

  public get isSeriesNPVRAllowed(): boolean {
    return mw.pvr.isSeriesNPVRAllowed(this);
  }

  public get hasTstv(): boolean {
    return !!this.isPlayAllowed();
  }

  public get isPast(): boolean {
    return this.end.getTime() < Date.now();
  }

  public get isNow(): boolean {
    const now = Date.now();
    return this.start.getTime() <= now && now <= this.end.getTime();
  }

  public get isFuture(): boolean {
    return this.start.getTime() > Date.now();
  }

  public get progress(): number {
    if (!this.isNow) {
      return 0;
    }

    return this.calculateProgress(Date.now() - this.start.getTime());
  }

  public get viewedProgress(): number {
    if (this.hasTstv) {
      if (this.isViewedCompletely) {
        return 1;
      }

      if (this.bookmark) {
        return this.calculateProgress(this.bookmark);
      }
    }

    return 0;
  }

  private calculateProgress(position: number) {
    return withinBoundaries(0, 1, position / (this.end.getTime() - this.start.getTime()));
  }

  public get isToday(): boolean {
    return moment(this.start).isSame(new Date(), 'day');
  }

  public get isTomorrow(): boolean {
    const tomorrow = moment()
      .add(1, 'day')
      .endOf('day');
    return moment(this.start).isSame(tomorrow, 'day');
  }

  public get wasYesterday(): boolean {
    const yesterday = moment()
      .subtract(1, 'day')
      .startOf('day');
    return moment(this.start).isSame(yesterday, 'day');
  }

  public get isInWeekDistance(): boolean {
    const nextWeekEnd = moment()
      .add(6, 'day')
      .endOf('day');
    return moment(this.start).isBetween(moment().startOf('day'), nextWeekEnd);
  }

  public get isSameYear(): boolean {
    return moment(this.start).isSame(new Date(), 'year');
  }

  public get eventTimeDistance(): DateTimeDistance {
    switch (true) {
      case this.wasYesterday:
        return DateTimeDistance.Yesterday;
      case this.isToday:
        return DateTimeDistance.Today;
      case this.isTomorrow:
        return DateTimeDistance.Tomorrow;
      case this.isInWeekDistance:
        return DateTimeDistance.UpcomingWeek;
      case this.isPast:
        return DateTimeDistance.LongDistancePast;
      default:
        return DateTimeDistance.MoreThanWeek;
    }
  }

  public isBlocked(): boolean {
    // Do not calculate isBlocked's value before explicitly checking if PC is disabled.
    // There is no way of telling whether false returned from super.isBlocked means that PC is disabled or if it is the calculated value.
    if (isPCDisabled()) {
      return false;
    }
    return mw.catalog.getChannelById(this.channelId)?.isBlocked()
      || mw.customer.currentProfile?.blockUnratedEvents && !this.hasRatings()
      || super.isBlocked();
  }

  public toString() {
    return `Event(id: "${this.id}", channel id: "${this.channelId}")`;
  }
}
