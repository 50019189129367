import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Log} from 'common/Log';

import {PinState} from 'mw/api/Profile';
import {mw, PCType} from 'mw/MW';

import CreatePinPopup from 'components/CreatePinPopup';
import InfoPopup from 'components/InfoPopup';
import {useChangeEffect, useLazyEffect, useToggle} from 'hooks/Hooks';

const TAG = 'WizardHooks';

export function useToggleParentalControl(params: {
  parentalControl: boolean,
  onParentalControlChanged: (enabled: boolean) => void,
  pinState: PinState,
  setPin: (pin: string) => void,
  validateYearOfBirth?: boolean,
  yearOfBirth?: number
}) {
  const {parentalControl: paramsParentalControl, onParentalControlChanged: paramsOnParentalControlChanged, pinState, setPin, validateYearOfBirth, yearOfBirth} = params;
  const [createPinPopupVisible, {on: showCreatePinPopup, off: hideCreatePinPopup}] = useToggle(false);
  const [parentalControl, setParentalControl] = useState(paramsParentalControl);

  const [noDatePopupVisible, {on: showNoDatePopup, off: hideNoDatePopup}] = useToggle(false);
  const checkYearOfBirth = useCallback((yearOfBirth?: number) => !!(yearOfBirth && yearOfBirth > 0), []);

  useChangeEffect(() => setParentalControl(paramsParentalControl), [paramsParentalControl]);
  useChangeEffect(() => paramsOnParentalControlChanged(parentalControl), [parentalControl]);

  const {t} = useTranslation();

  const onParentalControlChanged = useCallback((enabled: boolean) => {
    if (enabled && validateYearOfBirth && mw.configuration.pcPolicy.type === PCType.AgeBased && !checkYearOfBirth(yearOfBirth)) {
      showNoDatePopup();
    } else if (enabled && pinState === PinState.ProfilePinNotSet) {
      showCreatePinPopup();
    } else {
      setParentalControl(enabled);
    }
  }, [pinState, showCreatePinPopup, yearOfBirth, showNoDatePopup, checkYearOfBirth, validateYearOfBirth]);

  const toggleParentalControl = useCallback(() => {
    onParentalControlChanged(!parentalControl);
  }, [onParentalControlChanged, parentalControl]);

  const onCreatePin = useCallback((newPin: string): Promise<void> => {
    try {
      setPin(newPin);
      setParentalControl(true);
      hideCreatePinPopup();
      return Promise.resolve();
    } catch (error) {
      Log.error(TAG, 'Error changing PIN', error);
      return Promise.reject(error);
    }
  }, [setPin, hideCreatePinPopup]);

  const renderPopup = useCallback(() => (
    <>
      <CreatePinPopup
        visible={createPinPopupVisible}
        onCreatePin={onCreatePin}
        onCancel={hideCreatePinPopup}
      />
      <InfoPopup
        visible={noDatePopupVisible}
        title={t('settings.noDate.title')}
        message={t('settings.noDate.message')}
        onClose={hideNoDatePopup}
      />
    </>
  ), [createPinPopupVisible, hideCreatePinPopup, onCreatePin, noDatePopupVisible, hideNoDatePopup, t]);

  return {
    parentalControl,
    toggleParentalControl,
    renderPopup
  };
}

export function useYearOfBirth(onChange?: (year?: number) => void, initialValue?: number) {
  const [profileAge, setProfileAge] = useState<number>();

  // NaN should never occur
  // switch to controlled text input if it ever does
  const onChangeText = useCallback((text: string) => {
    if (!text) {
      setProfileAge(undefined);
      onChange?.(undefined);
      return;
    }
    const year = +text;
    const age = Number.isNaN(year)
      ? 0
      : (new Date()).getFullYear() - year;

    setProfileAge(age);
    onChange?.(year);
  }, [onChange]);

  useLazyEffect(() => {
    if (initialValue != null) {
      onChangeText(`${initialValue}`);
    }
  }, [], [initialValue, onChangeText]);

  return {
    profileAge,
    onChangeText
  };
}
