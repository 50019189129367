import Color from 'color';
import {createStyles} from 'common-styles';
import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {LayoutChangeEvent, View, ViewStyle, ImageStyle} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import {NavigationRoute, NavigationParams, NavigationLeafRoute, NavigationStateRoute} from 'react-navigation';

import {dimensions, menuFocusParentOffset, isAndroid, isWebOS} from 'common/constants';
import {Hashmap} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors, constColors} from 'common-styles/variables/base-colors';

import {Profile} from 'mw/api/Profile';
import {DefaultMenuItemSlug, CustomMenuItemRoute, DefaultMenuItemScreen} from 'mw/cms/CMS';
import {Menu, MenuType, MenuAlignment} from 'mw/cms/Menu';
import {mw} from 'mw/MW';

import {assets} from 'brand/Resources';
import {IconType} from 'components/Icon';
import IconRound from 'components/IconRound';
import {useLogo} from 'components/navigation/LogoProvider';
import {MenuIcon} from 'components/navigation/MainMenu';
import MainMenuIcon from 'components/navigation/MainMenuIcon';
import NotificationsSTBMenuButton from 'components/navigation/NotificationsSTBMenuButton';
import {NitroxInteractiveController} from 'components/NitroxInteractiveControllerContext';
import {toaster} from 'components/toaster/Toaster';
import {ToasterDuration} from 'components/toaster/ToasterInterface';
import {useCurrentProfile} from 'hooks/Hooks';
import {useLocalized} from 'locales/i18nUtils';

import {useFilteredRoutes} from './NavigationHelperFunctions';
import {STBMenuState} from './NavigationHelperTypes';
import NitroxLogo from './NitroxLogo';
import STBMenuButton from './STBMenuButton';
import STBMenuIconButton, {menuIconPadding, menuIconSize} from './STBMenuIconButton';

const TAG = 'STBMenu';

const userIconSize = dimensions.icon.large;
const textButtonMargin = 30;
const iconButtonMargin = 20 - menuIconPadding;
const menuSpacing = 77;
const menuMarginBottom = 60;
const linearGradientHeight = 651;
const linearGradientColor = 'black';
const linearGradientStartPoint = {x: 0, y: 0};
const linearGradientEndPoint = {x: 0, y: 1};
const linearGradientLocations = [0, 0.4, 1];
const linearGradientColors = [linearGradientColor, Color(linearGradientColor).alpha(0.9).toString(), constColors.transparent];

const styles = createStyles({
  menu: {
    width: '100%',
    height: '100%'
  },
  menuContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: dimensions.mainMenu.marginHorizontal,
    marginBottom: menuMarginBottom
  },
  logoContainer: {
    paddingTop: menuFocusParentOffset + dimensions.mainMenu.marginTop,
    width: dimensions.logo.width,
    marginRight: menuSpacing,
    height: '100%'
  },
  textButtonsContainer: {
    height: '100%',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginHorizontal: -textButtonMargin,
    overflow: 'hidden'
  },
  textButton: {
    marginHorizontal: textButtonMargin
  },
  iconButtonsContainer: {
    flexShrink: 0,
    height: '100%',
    marginHorizontal: menuSpacing - iconButtonMargin - menuIconPadding,
    flexDirection: 'row'
  },
  iconButton: {
    marginHorizontal: iconButtonMargin
  },
  userIconContainer: {
    width: userIconSize,
    height: '100%'
  },
  disabled: {
    opacity: dimensions.opacity.xlow
  },
  logoImage: {
    height: '100%',
    width: '100%'
  },
  linearGradient: {
    position: 'absolute',
    right: 0,
    width: '100%',
    height: linearGradientHeight,
    top: menuFocusParentOffset
  }
});

export const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  tabContainer: {
    width: '100%',
    height: dimensions.mainMenu.height + menuFocusParentOffset
  },
  centerMenuContainer: {
    marginVertical: dimensions.margins.xLarge
  },
  userAvatar: {
    backgroundColor: colors.userAvatar.placeholder.background
  },
  loginIcon: {
    backgroundColor: constColors.transparent
  },
  iconColor: colors.defaultColors.icon,
  iconImage: {
    height: menuIconSize,
    width: menuIconSize,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    tintColor: colors.mainMenu.button.icon.focused,
    resizeMode: 'contain'
  }
}));

type LogoProps = {
  profile?: Profile;
  style?: ViewStyle;
  visible?: boolean;
  onHeightChanged?: (height: number) => void;
}

const Logo: React.FC<LogoProps> = props => {
  const {onHeightChanged, style, visible = true} = props;
  const onLayout = useCallback((event: LayoutChangeEvent) => {
    onHeightChanged && onHeightChanged(event.nativeEvent.layout.height);
  }, [onHeightChanged]);
  const logoUrl = useLogo() || assets.common.stbMainMenuLogo;

  return (
    <View style={style} onLayout={onLayout} testID='logo'>
      {!!logoUrl && (
        <NitroxLogo
          style={[styles.logoImage, {display: visible ? 'flex' : 'none'}]}
          pictureUrl={logoUrl}
        />
      )}
    </View>
  );
};

export type STBMenuProps = {
  title: Menu['title'];
  icon: MenuIcon;
  slug: Menu['slug'];
  type: MenuType;
  isDisabled: boolean;
  position: MenuAlignment;
}

type MenuElementProps = {
  key: string,
  route?: NavigationLeafRoute<NavigationParams> | NavigationStateRoute<NavigationParams>,
  focusPriority: number,
  selected: boolean,
  disabled: boolean,
  text: string,
  onPress: () => void,
  renderIcon: () => JSX.Element
}

type PlacementOfMenuItems = {
  textButtons: JSX.Element[],
  icons: JSX.Element[],
  rightIcon: JSX.Element[],
}

const getElementsPlacement = (elements: PlacementOfMenuItems, stbMenuProps: STBMenuProps) => {
  // BOTTOM is handled like ICON_AREA to ensure backward compatibility
  if (stbMenuProps.position === MenuAlignment.ICON_AREA || stbMenuProps.position === MenuAlignment.BOTTOM) {
    return elements.icons;
  }
  if (stbMenuProps.type === MenuType.PROFILE || stbMenuProps.type === MenuType.LOGIN) {
    return elements.rightIcon;
  }
  return elements.textButtons;
};

type Props = {
  navigationIndex: number;
  routes: NavigationRoute[];
  menuProps: Hashmap<STBMenuProps>;
  displayState: STBMenuState;
  notificationsCount: number;
  newStylingAvailable: boolean;
  notificationPanelVisible: boolean;
  onPanelVisible: (visible: boolean) => void;
  onNewStylingPopupVisible: (visible: boolean) => void;
  onNoNotificationsPopupVisible: (visible: boolean) => void;
  onProfileSelectionVisible: (visible: boolean) => void;
  navigateToScreen: (route: NavigationRoute<NavigationParams>) => void;
  onRedeemVoucherPopupVisible: () => void;
}

type MenuButtonsElements = {
  top: JSX.Element[];
  center: JSX.Element[];
  bottom: JSX.Element[];
};

const STBMenu: React.FC<Props> = ({
  routes,
  navigationIndex,
  menuProps,
  displayState,
  notificationsCount,
  newStylingAvailable,
  onPanelVisible,
  onNewStylingPopupVisible,
  onNoNotificationsPopupVisible,
  onProfileSelectionVisible,
  onRedeemVoucherPopupVisible,
  notificationPanelVisible,
  navigateToScreen
}) => {
  const {t} = useTranslation();
  const {toUpperCase} = useLocalized();
  const currentProfile = useCurrentProfile();
  const dynamicStyles = stylesUpdater.getStyles();

  const filteredRoutes = useFilteredRoutes(routes);

  const onMenuButtonPress = useCallback((route: NavigationRoute<NavigationParams>) => {
    // TODO: After fix CL-7404 check STBMenuState will not be required
    if (displayState === STBMenuState.Hidden) {
      return;
    }
    const subRoute = route.routes[0];
    switch (subRoute.routeName) {
      case DefaultMenuItemScreen.LauncherNotifications:
        if (!notificationsCount) {
          if (isAndroid) {
            toaster.show(t('launcher.notifications.empty'), ToasterDuration.LONG);
          } else {
            onNoNotificationsPopupVisible(true);
          }
        } else if (mw.configuration.isLauncher) {
          onPanelVisible(true);
        } else {
          //for non-launcher platforms there is only 'new styling' notification
          newStylingAvailable && onNewStylingPopupVisible(true);
        }
        break;
      case DefaultMenuItemScreen.ProfileSelection:
        onProfileSelectionVisible(true);
        break;
      case DefaultMenuItemScreen.Quit:
        if (isWebOS) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.webOS?.platformBack?.();
        }
        break;
      case CustomMenuItemRoute.VoucherRedemption:
        onRedeemVoucherPopupVisible();
        break;
      default:
        if (mw.configuration.isLauncher) {
          onPanelVisible(false);
        }
        navigateToScreen(route);
    }
  }, [
    notificationsCount, onProfileSelectionVisible, onRedeemVoucherPopupVisible, navigateToScreen,
    t, onNoNotificationsPopupVisible, onPanelVisible, newStylingAvailable, onNewStylingPopupVisible, displayState
  ]);

  const renderMenuElement = useCallback((stbMenuProps: STBMenuProps, {disabled, ...elementProps}: MenuElementProps) => {
    if (stbMenuProps.slug === DefaultMenuItemSlug.BigScreenNotifications) {
      return (
        <NotificationsSTBMenuButton
          {...elementProps}
          notificationsCount={notificationsCount}
          style={styles.iconButton}
        />
      );
    }
    if (stbMenuProps.position === MenuAlignment.CENTER) {
      return (
        <STBMenuButton
          {...elementProps}
          disabled={disabled}
          style={styles.textButton}
        />
      );
    }
    return (
      <STBMenuIconButton
        {...elementProps}
        style={styles.iconButton}
        disabled={disabled}
      />
    );
  }, [notificationsCount]);

  const renderIcon = useCallback((stbMenuProps: STBMenuProps, isSelected: boolean) => {
    switch (stbMenuProps.type) {
      case MenuType.PROFILE:
        // This avatar icon will be replaced by a real avatar image in CL-7395
        return (
          <IconRound
            style={dynamicStyles.userAvatar}
            type={IconType.UserAvatar}
            size={userIconSize}
          />
        );
      case MenuType.LOGIN:
        return (
          <IconRound
            style={dynamicStyles.loginIcon}
            type={IconType.Login}
            size={userIconSize - dimensions.margins.medium}
          />
        );
      default:
        return (
          <MainMenuIcon
            isSelected={isSelected}
            source={stbMenuProps.icon}
            iconSize={menuIconSize}
            iconColor={dynamicStyles.iconColor}
            style={dynamicStyles.iconImage as ImageStyle}
          />
        );
    }
  }, [dynamicStyles.userAvatar, dynamicStyles.iconColor, dynamicStyles.iconImage, dynamicStyles.loginIcon]);

  const onIconPress = useCallback((route?: NavigationRoute<NavigationParams>) => {
    if (route) {
      onMenuButtonPress(route);
    }
  }, [onMenuButtonPress]);

  const computeIconFocusPriority = useCallback((stbMenuProps: STBMenuProps, isSelected: boolean, routeName: string) => {
    if (notificationPanelVisible && routeName === `${DefaultMenuItemScreen.LauncherNotifications}_${DefaultMenuItemSlug.BigScreenNotifications}`) {
      return 2;
    }
    if (notificationPanelVisible && !stbMenuProps.isDisabled) {
      return 1;
    }
    return isSelected ? 2 : +!stbMenuProps.isDisabled;
  }, [notificationPanelVisible]);

  const computeMenuElementProps = useCallback((stbMenuProps: STBMenuProps, routeName: string): MenuElementProps => {
    const route = routes.find(route => route.routeName === routeName);
    const isSelected = route ? routes.indexOf(route) === navigationIndex : false;
    return {
      route,
      selected: isSelected,
      disabled: stbMenuProps.isDisabled,
      key: routeName,
      text: stbMenuProps.title,
      focusPriority: computeIconFocusPriority(stbMenuProps, isSelected, routeName),
      onPress: () => onIconPress(route),
      renderIcon: () => renderIcon(stbMenuProps, isSelected)
    };
  }, [routes, navigationIndex, renderIcon, onIconPress, computeIconFocusPriority]);

  const menuButtons = useMemo(() => {
    return Object.entries(menuProps)
      .filter(([routeName]) => filteredRoutes.find(route => route.routeName === routeName))
      .reduce((elements: PlacementOfMenuItems, [routeName, stbMenuProps]) => {
        getElementsPlacement(elements, stbMenuProps).push(renderMenuElement(stbMenuProps, computeMenuElementProps(stbMenuProps, routeName)));
        return elements;
      }, {rightIcon: [], textButtons: [], icons: []});
  }, [menuProps, filteredRoutes, renderMenuElement, computeMenuElementProps]);

  const tabContainerStyle: ViewStyle = useMemo(() => ({
    ...dynamicStyles.tabContainer,
    position: 'absolute',
    marginTop: displayState === STBMenuState.Hidden ? - dimensions.mainMenu.height : 0
  }), [dynamicStyles.tabContainer, displayState]);

  const showLinearGradient = displayState !== STBMenuState.Above && displayState !== STBMenuState.Hidden && displayState !== STBMenuState.Transparent;

  return (
    <NitroxInteractiveController omitGeometryCaching>
      <View style={tabContainerStyle} >
        <>
          {showLinearGradient && (
            <LinearGradient
              start={linearGradientStartPoint}
              end={linearGradientEndPoint}
              locations={linearGradientLocations}
              colors={linearGradientColors}
              style={styles.linearGradient}
              pointerEvents='none'
            />
          )}
          <View style={styles.menu} >
            <View style={styles.menuContainer}>
              <Logo
                style={styles.logoContainer}
                profile={currentProfile}
                visible
              />
              <View style={styles.textButtonsContainer} >
                {menuButtons.textButtons}
              </View>
              <View style={styles.iconButtonsContainer}>
                {menuButtons.icons}
              </View>
              <View style={styles.userIconContainer} >
                {menuButtons.rightIcon}
              </View>
            </View>
          </View>
        </>
      </View>
    </NitroxInteractiveController>
  );
};

export default React.memo(STBMenu);
