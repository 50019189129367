import {createStyles} from 'common-styles';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

export const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  activityIndicatorContainer: {
    position: 'absolute',
    height: '100%',
    justifyContent: 'center',
    alignSelf: 'center'
  },
  activityIndicatorColor: colors.defaultColors.spinner,
  container: {
    flex: 1
  }
}));
