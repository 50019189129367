import {Log} from 'common/Log';

import {Payment, StripeProperties, PaymentMethodId} from 'mw/api/Metadata';
import {PaymentObject} from 'mw/metadata/PaymentObject';

const TAG = 'PaymentMapper';

export class PaymentMapper {

  public static paymentFromJson(paymentJson: any): Payment {
    const payment = new PaymentObject(
      paymentJson.id,
      paymentJson.referenceId,
      paymentJson.status,
      paymentJson.message,
      paymentJson.creationDate,
      paymentJson.endDate
    );

    if (paymentJson.paymentMethodId === PaymentMethodId.Stripe) {
      if (typeof paymentJson.stripePaymentClientSecret === 'undefined' || typeof paymentJson.stripePublishableApiKey === 'undefined' ) {
        Log.error(TAG, 'Missing required Stripe properties', paymentJson);
      }
      const stripeProperties: StripeProperties = {
        clientSecret: paymentJson.stripePaymentClientSecret,
        publicApiKey: paymentJson.stripePublishableApiKey
      };
      payment.customProperties = {
        ...payment.customProperties,
        [PaymentMethodId.Stripe]: stripeProperties
      };
    }

    return payment;
  }
}
