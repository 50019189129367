import {useMemo, useState} from 'react';

import {epgItemHeight} from 'common/constants';

import {Channel} from 'mw/api/Metadata';

import {useFunction} from 'hooks/Hooks';

import {channelIndex} from './EpgHelpers';

const chunkLength = 8;

function channelIndexForOffset(offset: number) {
  return Math.floor(offset / epgItemHeight);
}

function chunkForIndex(index: number) {
  return Math.floor(index / chunkLength);
}

function displayedIndexRangeForChunk(chunk: number, channelsLength: number): [number, number] {
  const index = chunk * chunkLength;
  return [
    Math.max(0, index - chunkLength),
    Math.min(index + 2 * chunkLength, channelsLength)
  ];
}

export type UseChannelList = {
  currentChannel?: Channel;
  channels: Channel[];
};

export function useChannelList({
  currentChannel,
  channels
}: UseChannelList) {
  const currentChannelIndex = useMemo(() => channelIndex(currentChannel?.id, channels), [channels, currentChannel]);

  const [currentChunk, setCurrentChunk] = useState(chunkForIndex(currentChannelIndex));

  const scrollOffsetChanged = useFunction(({value}: {value: number}) => {
    const channelIndex = channelIndexForOffset(value);
    const chunk = chunkForIndex(channelIndex);
    setCurrentChunk(chunk);
  });

  const displayedChannels = useMemo(() => {
    const [start, end] = displayedIndexRangeForChunk(currentChunk, channels.length);
    return channels
      .slice(start, end)
      .map((channel, sliceIndex) => {
        const index = sliceIndex + start;
        return ({
          channel,
          isCurrent: index === currentChannelIndex,
          index
        });
      });
  }, [currentChunk, channels, currentChannelIndex]);

  return {
    displayedChannels,
    scrollOffsetChanged
  };
}
