import {createStyles} from 'common-styles';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions, isPhone} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import DoubleBackground from 'components/DoubleBackground';
import FocusableTextInput from 'components/inputs/FocusableTextInput';
import NitroxText from 'components/NitroxText';
import {WizardElements} from 'components/Wizard';
import {getScreenInfo, useFunction} from 'hooks/Hooks';

import {WizardNamePageProps} from './WizardNamePage';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    color: colors.settingsScreen.profile.wizard.label,
    marginVertical: dimensions.margins.medium
  },
  input: {
    width: Math.min(dimensions.inputs.width.settings, getScreenInfo().size.width - 2 * dimensions.margins.large)
  },
  mobileTopChild: {
    justifyContent: isPhone ? 'center' : 'flex-end',
    alignItems: 'center'
  },
  mobileBottomChild: {
    justifyContent: isPhone ? 'center' : 'flex-start',
    alignItems: 'center',
    ...!isPhone && {
      flex: 1
    }
  },
  tracker: {
    marginTop: dimensions.margins.small
  }
}));

const WizardNamePage: React.FC<WizardNamePageProps & Pick<WizardElements, 'tracker'>> = props => {
  const {
    name,
    onNameChanged,
    showTitle = true,
    containerStyle,
    tracker,
    onInputFocusChange,
    onSubmitEditting,
    embedded
  } = props;
  const {t} = useTranslation();

  const styles = stylesUpdater.getStyles();
  const onInputFocusHandler = useFunction(() => {
    onInputFocusChange?.(true);
  });

  const onInputBlurHandler = useFunction(() => {
    onInputFocusChange?.(false);
  });
  const title = showTitle && <NitroxText style={styles.label} textType='input-prompt'>{t('profileWizard.enterName')}</NitroxText>;
  const input = (
    <View style={styles.input}>
      <FocusableTextInput
        testID='input_new_profile_name'
        initialValue={name}
        onChangeText={onNameChanged}
        onSubmitEditing={onSubmitEditting}
        editIndicatorIcon
        onFocus={onInputFocusHandler}
        onBlur={onInputBlurHandler}
      />
    </View>
  );
  if (!isPhone && embedded) {
    return (
      <View style={[styles.container, containerStyle]}>
        {title}
        {input}
      </View>
    );
  }

  return (
    <DoubleBackground
      topChild={(
        <View>
          <View style={styles.tracker}>
            {tracker}
          </View>
          {title}
        </View>
      )}
      topStyle={styles.mobileTopChild}
      bottomChild={input}
      bottomStyle={styles.mobileBottomChild}
      overlap={dimensions.wizard.configScreen.doubleBackgroundOverlap}
    />
  );
};

export default React.memo(WizardNamePage);
