import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {ErrorType} from 'mw/api/Error';

import ErrorPopup, {useErrorPopup} from 'components/ErrorPopup';
import {useFocusParentUtility} from 'components/FocusParent';
import PinKeyboard from 'components/pinKeyboard/PinKeyboard';
import {useChangeEffect} from 'hooks/Hooks';
import {validatePIN} from 'screens/settings/pinValidation';

type EnterPinModalComponentProps = {
  visible: boolean;
  onSubmit: (value: string) => void;
  onClose: () => void;
  title: string;
}
export const EnterPinModalComponent: React.FC<EnterPinModalComponentProps> = ({
  visible = false,
  onSubmit,
  onClose,
  title
}) => {
  const {t} = useTranslation();
  const {error, showError, onCloseErrorPopup} = useErrorPopup();
  const {focus: focusNearestParent} = useFocusParentUtility();
  const validate = useCallback((value: string) => {
    try {
      validatePIN(value);
      onSubmit(value);
    } catch ({type}) {
      showError(type === ErrorType.ProfilePINTooShort ?
        t('settings.errors.profilePinTooShort') :
        t('common.unexpectedError'));
    }
  }, [onSubmit, showError, t]);

  useChangeEffect(() => {
    if (!visible) {
      focusNearestParent?.();
    }
  }, [visible], [focusNearestParent]);

  if (!visible) {
    return null;
  }
  if (error) {
    return (
      <ErrorPopup
        error={error}
        onClose={onCloseErrorPopup}
      />
    );
  }
  return (
    <PinKeyboard
      visible={visible}
      title={title}
      onSubmit={validate}
      onClose={onClose}
    />
  );
};
