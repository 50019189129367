import {DateUtils} from 'common/DateUtils';
import {Log} from 'common/Log';
import {withinBoundaries} from 'common/utils';

import {Channel, ContentType, Event, Media, Playable, PlaybackLimitations, BlockedByPCEventState} from 'mw/api/Metadata';
import {nxffConfig} from 'mw/api/NXFF';
import {MediaEvent, PositionChanged, PlayerEvent, RewindingPositionChangedEvent, StopParams} from 'mw/api/PlayerEvent';
import {isPCDisabled} from 'mw/common/ParentalControl';
import {mw} from 'mw/MW';
import {EventUpdaterEvents} from 'mw/playback/controllers/updaters/EventUpdaterEvents';
import {LiveEventUpdater} from 'mw/playback/controllers/updaters/LiveEventUpdater';
import {PlaybackSession} from 'mw/playback/sessions/PlaybackSessionManager';
import {NativePlaybackParameters} from 'mw/playback/types/NativePlaybackParameters';
import {NativePositionChanged} from 'mw/playback/types/PlaybackEvent';
import {PlaybackRequestParameters, PlaybackResponse} from 'mw/playback/types/PlaybackParameters';
import {PlayerType} from 'mw/playback/types/PlayerType';

import {PlaybackController, StartPlaybackParameters} from './PlaybackController';
import {addRegion} from './utils';

const TAG = 'LivePlayer';

const minBufferDurationForNPLTV = 5 * DateUtils.sInMin;
const defaultNPLTVBeginOfStreamOffset = 5;

export class LivePlayer extends PlaybackController {

  private playbackStartTime: Date | null = null;

  protected eventUpdater: LiveEventUpdater | null = null;
  protected isNPLTVAvailable = false;
  protected npltvMode = false;

  private playbackBeginPosition = 0;
  private playbackEndPosition = 0;
  private accumulatePositionChange = 0;
  private accumulatePositionChangeOffset = 0;
  private beforeSetParametersPosition = 0;
  private forcePCUnlock = false;

  public constructor(playerType: PlayerType) {
    super(playerType, ContentType.LIVE);
    this.once(PlayerEvent.FirstFrame, () => {
      this.once(PlayerEvent.PositionChanged, ({position, beginPosition, endPosition}: PositionChanged) => {
        this.playbackStartTime = new Date(position * DateUtils.msInSec);
        if (!nxffConfig.getConfig().Playback.EnabledNPLTVSeekBeforeZaptime) {
          this.setPlaybackBoundaries(position, 0);
        }

        const isNPLTVAvailable = nxffConfig.getConfig().Playback.EnabledNPLTV && (endPosition - beginPosition > minBufferDurationForNPLTV);
        Log.debug(TAG, 'isNPLTVAvailable', isNPLTVAvailable);
        if (isNPLTVAvailable !== this.isNPLTVAvailable) {
          this.isNPLTVAvailable = isNPLTVAvailable;
          this.notify(PlayerEvent.PlaybackLimitationsChanged, this.getPlaybackLimitations());
        }
      });
    });
    this.on(PlayerEvent.EndOfContent, this.onEndOfContent);
  }

  public async startPlayback(params: StartPlaybackParameters): Promise<PlaybackResponse> {
    this.notify(PlayerEvent.Loading, true);

    this.forcePCUnlock = params.playbackParameters.forcePCUnlock || false;
    const channel = params.playable as Channel;
    this.clearUpdater();
    this.eventUpdater = new LiveEventUpdater(channel);
    this.eventUpdater.on(EventUpdaterEvents.NewEvent, event => this.onNewEPGEvent(event));

    const isPCEnabled = !isPCDisabled();
    return await super.startPlayback({
      ...params,
      playbackParameters: {
        ...params.playbackParameters,
        forcePCUnlock: false,
        // when starting playback and Parental Control is enabled we want sound to be muted and video hidden
        // just to hide (and mute) first frames of playback
        // before permissions are checked
        hideVideo: isPCEnabled,
        mute: isPCEnabled
      }
    });
  }

  public stop(params: StopParams): Promise<PlaybackResponse> {
    Log.debug(TAG, 'stop', params.reason);
    this.clearUpdater();
    this.notify(PlayerEvent.Loading, false);
    return super.stop(params);
  }

  public onEndOfContent = (mediaEvent?: MediaEvent) => {
    if (this.npltvMode) {
      this.switchPlayerMode(ContentType.LIVE);
    }
  };

  public onRewindingPositionChanged(event: RewindingPositionChangedEvent): void {
    this.eventUpdater?.triggerUpdate(event.position);
    super.onRewindingPositionChanged(event);
  }

  public onFirstFrame(mediaEvent: MediaEvent): void {
    const currentMedia = this.getCurrentMedia();
    if (currentMedia) {
      Log.debug(TAG, 'First frame displayed - setting last played channel id to ' + currentMedia.id);
      mw.catalog.setLastPlayedChannelId(currentMedia.id);
    } else {
      Log.warn(TAG, 'Unable to set last played channel id - current media is missing');
    }
    super.onFirstFrame(mediaEvent);
  }

  public onStopped(mediaEvent: MediaEvent): void {
    Log.debug(TAG, 'onStopped');
    this.clearUpdater();
    super.onStopped(mediaEvent);
  }

  protected createPlaybackSession(playable: Playable, media: Media): Promise<PlaybackSession> {
    return super.createPlaybackSession(playable, media)
      .then(addRegion);
  }

  public positionChanged(nativePositionChanged: NativePositionChanged): void {
    const positionChanged: PositionChanged = {
      ...nativePositionChanged,
      position: nativePositionChanged.position || Math.round(Date.now() / 1000)
    };

    this.playbackBeginPosition = nativePositionChanged.beginPosition;
    this.playbackEndPosition = nativePositionChanged.endPosition;

    super.positionChanged(positionChanged);
    if (this.eventUpdater && !this.playbackRewinder.isRewinding()) { // onRewindingPositionChanged callback will handle the event updates
      this.eventUpdater.triggerUpdate(positionChanged.position);
    }
  }

  private clearUpdater = (): void => {
    this.eventUpdater && this.eventUpdater.release();
    this.eventUpdater = null;
  }

  public getPlaybackLimitations(): PlaybackLimitations {
    const event = this.eventUpdater ? this.eventUpdater.getCurrentEvent() : null;
    const hasTstv = !!event?.hasTstv;

    if (this.npltvMode) {
      const playbackLimitation: PlaybackLimitations = {
        allowRestart: hasTstv,
        allowPause: true,
        allowSkipBackward: true,
        allowSkipForward: true,
        allowGoToLive: true,
        allowFastForward: true,
        allowRewind: true
      };
      return playbackLimitation;
    }

    const playbackLimitation: PlaybackLimitations = {
      allowRestart: hasTstv,
      allowPause: this.isNPLTVAvailable,
      allowSkipBackward: this.isNPLTVAvailable,
      allowRewind: this.isNPLTVAvailable
    };
    return playbackLimitation;
  }

  public getPlaybackStartTime(): Date | null {
    return this.playbackStartTime ?? super.getPlaybackStartTime();
  }

  protected convertParameters(playbackParameters: PlaybackRequestParameters): NativePlaybackParameters {
    const nativePlaybackParameters = super.convertParameters(playbackParameters);
    if (this.isNPLTVAvailable && (this.npltvMode || this.shouldSwitchToNPLTVMode(nativePlaybackParameters))) {
      nativePlaybackParameters.contentType = ContentType.NPLTV;
    }
    return nativePlaybackParameters;
  }

  /* This function checks if there is need to switch to NPLTV mode as if after skip ( or position change ) there will be current event change.
   * Event change triggers an event updater onNewEPGEvent to check if parental control access changed.
   * Function call could be delayed if there is currently update event in progress
   * accumulatePositionChange is used to validate event change even on request which native part has not handled yet
   * it's incremented before request send and decremented after response received
   */
  protected async beforeSetParameters(nativePlaybackParameters: NativePlaybackParameters): Promise<void> {
    await super.beforeSetParameters(nativePlaybackParameters);

    this.beforeSetParametersPosition = this.playbackParameters.position || 0;
    if (!this.npltvMode && this.isNPLTVAvailable && this.shouldSwitchToNPLTVMode(nativePlaybackParameters)) {
      this.switchPlayerMode(ContentType.NPLTV);
    }

    if (!this.npltvMode || !this.playbackParameters.position || !this.eventUpdater) {
      return;
    }

    const updateInProgress = this.eventUpdater.getUpdateInProgress();
    if (!!updateInProgress) {
      await updateInProgress.catch(err => Log.info(TAG, 'setParameters: update failed', err));
      await this.beforeSetParameters(nativePlaybackParameters);
      return;
    }

    if (nativePlaybackParameters.skip) {
      this.accumulatePositionChange += nativePlaybackParameters.skip;
    }

    if (nativePlaybackParameters.position != null) {
      this.accumulatePositionChangeOffset = nativePlaybackParameters.position - (this.playbackParameters.position || 0);
      this.accumulatePositionChange = this.accumulatePositionChangeOffset;
    }

    if (!this.accumulatePositionChange) {
      return;
    }

    let changedPosition = (this.playbackParameters.position ?? 0) + this.accumulatePositionChange;
    changedPosition = withinBoundaries(this.playbackBeginPosition, this.playbackEndPosition, changedPosition);

    await this.eventUpdater.waitForUpdate(changedPosition);
  }

  protected applyChangedParameters(playbackParameters: NativePlaybackParameters): void {
    super.applyChangedParameters(playbackParameters);

    if (!this.npltvMode || !(playbackParameters.skip || playbackParameters.position)) {
      return;
    }
    const changedPosition = (this.beforeSetParametersPosition ?? 0) + this.accumulatePositionChange;
    if (changedPosition <= this.playbackBeginPosition) {
      this.notify(PlayerEvent.BeginOfContent);
    } else if (changedPosition >= (this.playbackEndPosition - defaultNPLTVBeginOfStreamOffset)) {
      this.notify(PlayerEvent.EndOfContent);
    }
  }

  protected afterSetParameters(nativePlaybackParameters: NativePlaybackParameters): void {
    if (nativePlaybackParameters.skip) {
      this.accumulatePositionChange -= nativePlaybackParameters.skip;
    }

    if (nativePlaybackParameters.position != null) {
      this.accumulatePositionChange -= this.accumulatePositionChangeOffset;
      this.accumulatePositionChangeOffset = 0;
    }
  }

  private shouldSwitchToNPLTVMode(playbackParameters: NativePlaybackParameters): boolean {
    return playbackParameters.skip != null || !!playbackParameters.position || (playbackParameters.playRate != null && playbackParameters.playRate !== 1);
  }

  private switchPlayerMode(contentType: ContentType): void {
    if (!this.eventUpdater) {
      Log.error(TAG, 'switchPlayerMode: player not started', contentType);
      return;
    }

    if (contentType === ContentType.LIVE) {
      this.playbackRewinder.stop();
    }

    this.contentType = contentType;
    this.npltvMode = (contentType === ContentType.NPLTV);

    this.notify(PlayerEvent.PlaybackLimitationsChanged, this.getPlaybackLimitations());
  }

  private onNewEPGEvent = (event?: Event): void => {
    this.forcePCUnlock = false;
    Log.debug(TAG, PlayerEvent.MediaUpdate, EventUpdaterEvents.NewEvent, event);
    this.notify(PlayerEvent.MediaUpdate, event);

    this.updatePCBlocked(event);
  }

  private updatePCBlocked = (event = this.eventUpdater?.getCurrentEvent()) => {
    let blocked = false;
    if (!this.forcePCUnlock) {
      blocked = event?.isBlocked() ?? !isPCDisabled();
    }

    this.setPCBlocked(blocked)
      .catch(error => Log.error(TAG, `updatePCBlocked: ${blocked ? 'blocking' : 'unblocking'} failed`, error));

    this.notify(PlayerEvent.Loading, false);
  }

  public setPCBlocked(blocked: boolean): Promise<void> {
    const blockedEventStatus: BlockedByPCEventState = {
      event: this.eventUpdater?.getCurrentEvent() || undefined,
      blocked
    };

    return super.setPCBlocked(blocked)
      .finally(() => this.notify(PlayerEvent.BlockedByParentalControl, blockedEventStatus));
  }
}
