import React, {forwardRef} from 'react';

import {isMobile} from 'common/constants';

import {isChannel, isRecording} from 'mw/api/Metadata';

import ChannelTile from 'components/mediaTiles/ChannelTile';
import MediaTileBase from 'components/mediaTiles/MediaTileBase';
import {AnimatedTileInterface, calculateProgress, channelIconOffset, MediaTileConfig, MediaTileType, MediaTileBaseProps} from 'components/mediaTiles/MediaTileHooks';
import MobileMediaTileBase from 'components/mediaTiles/MobileMediaTileBase';
import RecordingTile from 'components/mediaTiles/RecordingTile';

export {AnimatedTileInterface, calculateProgress, channelIconOffset, MediaTileConfig, MediaTileType};
export {mediaTileMarginHorizontal, mediaTileMarginTop} from 'components/mediaTiles/MediaTileBase';

const MediaTile: React.FunctionComponent<MediaTileBaseProps> = (props, ref) => {
  const {media, ...baseProps} = props;
  if (isMobile) {
    return (<MobileMediaTileBase ref={ref} {...props} />);
  }
  if (isRecording(media)) {
    return (<RecordingTile ref={ref} recording={media} {...baseProps} />);
  }
  if (isChannel(media)) {
    return (<ChannelTile ref={ref} channel={media} {...baseProps} />);
  }
  return (<MediaTileBase ref={ref} {...props} />);
};

export default React.memo(forwardRef(MediaTile));
