import {createStyles} from 'common-styles';
import React from 'react';
import {View, StyleProp, ViewStyle} from 'react-native';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

const outerWidth = 20;
const innerWidth = 10;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  outer: {
    height: outerWidth,
    width: outerWidth,
    borderRadius: outerWidth / 2,
    backgroundColor: colors.payments.product.radio.outerBackground,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center'
  },
  inner: {
    height: innerWidth,
    width: innerWidth,
    borderRadius: innerWidth / 2,
    backgroundColor: colors.payments.product.radio.innerBackground
  }
}));

type RadioButtonProps = {
  style: StyleProp<ViewStyle>;
  styleInner?: StyleProp<ViewStyle>;
  selected: boolean;
}

const RadioButton: React.FunctionComponent<RadioButtonProps> = props => {
  const {selected, style, styleInner = {}} = props;
  const dynamicStyle = stylesUpdater.getStyles();
  return (
    <View style={[dynamicStyle.outer, style]}>
      {selected && <View style={[dynamicStyle.inner, styleInner]} />}
    </View>
  );
};

export default RadioButton;
