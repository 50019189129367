import {createStyles} from 'common-styles';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions, RADIUS} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors, transparent} from 'common-styles/variables/base-colors';

import {RecordingStatus} from 'mw/api/Metadata';

import NitroxText, {getFontStyle} from 'components/NitroxText';

import {RecordingLimitationsProps} from './MediaInfoView.shared';

const limitationsTextType = 'body';
const limitationsLineHeight = getFontStyle(limitationsTextType).lineHeight ?? 0;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  limitationsContainer: {
    alignItems: 'center',
    backgroundColor: transparent(colors.recordingLimitations.background, dimensions.opacity.xhigh),
    borderRadius: RADIUS,
    flexDirection: 'row',
    marginTop: dimensions.margins.xxLarge,
    paddingHorizontal: dimensions.margins.large
  },
  limitationsStyle: {
    color: colors.recordingLimitations.text,
    height: limitationsLineHeight
  },
  separator: {
    backgroundColor: colors.recordingLimitations.text,
    borderRadius: 50,
    height: 5,
    width: 5,
    marginLeft: dimensions.margins.medium,
    marginRight: dimensions.margins.medium
  }
}));

const RecordingLimitations: React.FC<RecordingLimitationsProps> = ({startingSeason, channel, status}) => {
  const {t} = useTranslation();

  if ((startingSeason === null && !channel) || (status === RecordingStatus.Suspended)) {
    return null;
  }

  const styles = stylesUpdater.getStyles();
  const separator = <View style={styles.separator} />;

  const seasonLimitations = t('mediaDetails.recordingLimitations.startingSeason', {startingSeason: startingSeason?.toString()});
  const channelLimitations = t('mediaDetails.recordingLimitations.channel');
  const hasStartingSeason = startingSeason != null;
  const hasSpecifiedChannel = channel != null;

  return (
    <View style={styles.limitationsContainer}>
      {hasStartingSeason && (
        <NitroxText style={styles.limitationsStyle} textType={limitationsTextType}>
          {seasonLimitations}
        </NitroxText>
      )}
      {hasStartingSeason && hasSpecifiedChannel && separator}
      {hasSpecifiedChannel && (
        <NitroxText style={styles.limitationsStyle} textType={limitationsTextType}>
          {channelLimitations}
        </NitroxText>
      )}
    </View>
  );
};

export default React.memo(RecordingLimitations);
