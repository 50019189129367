import {createStyles} from 'common-styles';
import React, {useState, useEffect, useCallback} from 'react';
import {ActivityIndicator, StyleSheet, View} from 'react-native';

import {useSafeAwait} from 'common/hooks/Hooks';
import {Log} from 'common/Log';

import {Channel, Event} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import MouseAwareView from 'components/MouseAwareView';

import {tvChannelListConstants} from './ChannelListConstants';
import ChannelShortDetails from './ChannelShortDetails';

const TAG = 'ChannelListTile';

const staticStyles = createStyles({
  channelTile: {
    width: tvChannelListConstants.channelTileWidth,
    height: tvChannelListConstants.channelTileHeight,
    justifyContent: 'center',
    alignItems: 'center'
  },
  activityIndicatorContainer: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center'
  }
});

const Loader: React.FC = () => {
  return (
    <View style={staticStyles.activityIndicatorContainer}>
      <ActivityIndicator />
    </View>
  );
};

type ChannelTileProps = {
  channel: Channel;
  selected: boolean;
  focused: boolean;
  nearViewPort: boolean;
  listVisible: boolean;

  /**
   * Mouse only. For RCU use single listener to avoid multiple keyevent listener instances across list.
   * Passes 'channel' as argument to make memoization easier.
   */
  onClick: (channel: Channel) => void;
}

const ChannelTile: React.FC<ChannelTileProps> = ({
  channel,
  selected,
  focused,
  nearViewPort,
  onClick: propsOnClick,
  listVisible
}) => {
  const [event, setEvent] = useState<Event>();
  const safeAwait = useSafeAwait();

  useEffect(() => {
    async function fetchCurrentEvent() {
      try {
        setEvent(await safeAwait(mw.catalog.getCurrentEvent(channel)));
      } catch (e) {
        Log.error(TAG, `Error while fetching current event for channel ${channel.toString()}`, e);
      }
    }

    if (nearViewPort && !event && listVisible) {
      fetchCurrentEvent();
    }
  }, [channel, event, nearViewPort, safeAwait, listVisible]);

  const onClick = useCallback(() => {
    propsOnClick(channel);
  }, [channel, propsOnClick]);

  const [hovered, setHovered] = useState(false);

  if (!nearViewPort) {
    return null;
  }

  return (
    <MouseAwareView
      style={staticStyles.channelTile}
      onClick={onClick}
      onHoverChange={setHovered}
    >
      <ChannelShortDetails
        channel={channel}
        event={event}
        isFocused={focused || hovered}
        isSelected={selected}
      />
      {!event && nearViewPort && <Loader />}
    </MouseAwareView>
  );
};

export default React.memo(ChannelTile);
