import {EventEmitterInterface} from 'common/EventEmitterInterface';

import {EPGParams, EPGResponse, SearchParameters, SearchResult, PurchaseMethodParams, PurchaseResult, RecommendationsQueryParameters, ContentQueryParameters, StartPaymentParams} from 'mw/api/CatalogInterface';
import {PurchaseAuthorizationMethod} from 'mw/api/Configuration';
import {Device} from 'mw/api/Device';
import {CAPQueryParameters, CAPMessage} from 'mw/api/EASMetadata';
import {Error} from 'mw/api/Error';
import {Filter} from 'mw/api/Filter';
import {
  Channel,
  ChannelList,
  Consent,
  Event,
  Location,
  Media,
  Order,
  Payment,
  PaymentMethod,
  Picture,
  PictureMode,
  PlaybackLimitations,
  Product,
  PurchaseMethod,
  PVRQueryParameters,
  PVRQuota,
  PVRScheduleParameters,
  PVRUpdateParameters,
  Recording,
  Series,
  SortVodBy,
  Styling,
  Title,
  UpdateMediaParams,
  RecordingStatus
} from 'mw/api/Metadata';
import {PinProtectionLevel} from 'mw/api/PinProtection';
import {Profile, ProfileConfigurableProperties, ProfileStoredProperties} from 'mw/api/Profile';
import {Translations} from 'mw/bo-proxy/uxmanager/UXManager';
import {Menu, Link} from 'mw/cms/Menu';
import {Page} from 'mw/cms/Page';
import {EntitledProduct} from 'mw/common/entitled-products/EntitledProductInterface';
import {NotificationsManager} from 'mw/notifications/NotificationsManager';
import {PlaybackSession, PlaybackSessionManager, SessionParams, SessionTerminationReason} from 'mw/playback/sessions/PlaybackSessionManager';

import {DrmAsset, ManagedBy} from './SSOInterface';
import {Customer} from './types';

export interface PlaybackSessionData {
  id: string;
  assets: PlaybackAsset[];
  timeout?: number; // in seconds
}

export interface PlaybackAsset {
  url: string;
  isAdvertisement?: boolean;
  duration?: number;
  playbackLimitations?: PlaybackLimitations;
  drmAsset?: DrmAsset;
  ticket?: string;
  ordinal?: number;
  managedBy?: ManagedBy;
}

export type RegistrationData = {
  username: string;
  email: string;
  firstName: string;
  surname: string;
  password: string;
  pin: string;
  phone?: string;
}

export interface DeletePlaybackSessionParams {
  position: number;
  reason: SessionTerminationReason;
}

export interface ChannelUrls {
  readonly [channelId: string]: Location;
}

export interface SearchQuery {
  sources: unknown;
  searchField: string;
}

export interface ProfileCreationResult {
  profileCreated: boolean;
  error?: Error;
}

export enum BOEvent {
  BOUnauthorized = 'BOUnauthorized',
  BOUnavailable = 'BOUnavailable',
  DeviceUnregistered = 'DeviceUnregistered',
  PlaybackLocationForbidden = 'PlaybackLocationForbidden'
}

export type OrdersQueryParameters = {
  page?: number; // page number starting from 1 specifying offset of results matching request.
  size?: number; // defines how many records should be returned per page, max 100.
};

export type SortOrdersQueryParameters = OrdersQueryParameters & {
  sort?: boolean | string;
  orderStatus?: string;
}

export type OwnedQueryParameters = {
  pageSize: number;
  source?: OwnedSource;
};

export enum OwnedSource {
  All = 'all',
  Rented = 'rented',
  Bought = 'bought'
}

export interface BOInterface extends EventEmitterInterface<BOEvent> {
  setParameters(): Promise<void>;
  getProfiles(): Promise<Profile[]>;
  createProfile(config: Partial<ProfileConfigurableProperties>, masterPin: string): Promise<ProfileCreationResult>;
  deleteProfile(profile: Profile, masterPin: string): Promise<void>;
  setNameForProfile(profile: Profile, name: string, masterPin: string): Promise<void>;
  setPinForProfile(profile: Profile, pin: string): Promise<void>;
  setDataForProfile<Property extends keyof ProfileStoredProperties>(profile: Profile, key: Property, value: ProfileStoredProperties[Property]): Promise<void>;
  addTitleToWatchList(profile: Profile, titleId: string): Promise<void>;
  addSeriesToWatchList(profile: Profile, seriesId: string): Promise<void>;
  removeTitlesFromWatchList(profile: Profile, titlesIds: string[]): Promise<void>;
  removeSeriesFromWatchList(profile: Profile, seriesIds: string[]): Promise<void>;
  getWatchList(profile: Profile): Promise<Media[]>;
  getPinProtectionLevel(): PinProtectionLevel;
  verifyProfilePin(profile: Profile, pin: string): Promise<void>;
  isPurchasePinSupported(): boolean;
  getPurchaseAuthorizationMethod(): PurchaseAuthorizationMethod;
  setPurchasePin(pin: string): Promise<void>;
  verifyPurchasePin(pin: string): Promise<void>;
  getChannels(): Promise<Channel[]>;
  getEPG(params: EPGParams): Promise<EPGResponse>;
  getEventById(id: string): Promise<Event>;
  getPage(link: Link): Promise<Page>;
  getMenu(id: string, depth: number): Promise<Menu>;
  getMainMenu(): Promise<Menu>;
  getUnauthenticatedMainMenu(): Promise<Menu>;
  getPictureUrl(picture: Picture, width: number, height: number, mode: PictureMode): string;
  createPlaybackSession(params: SessionParams): Promise<PlaybackSessionData>;
  keepAlivePlaybackSession(session: PlaybackSession, params: any): Promise<void>;
  geoLocationPlaybackCheck(params: any): Promise<void>;
  deletePlaybackSession(session: PlaybackSession, params: DeletePlaybackSessionParams): Promise<void>;
  getRecommendations(params: RecommendationsQueryParameters): Promise<Media[]>;
  getContinueWatching(): Promise<Media[]>;
  getFakeVodRecommendationsSource(): Promise<Filter | null>;
  getOwned(params: OwnedQueryParameters): AsyncIterableIterator<Media[]>;
  getContent(filters: Filter[], parameters: ContentQueryParameters): AsyncIterableIterator<Media[]>;
  getPlaybackSessionManager(): PlaybackSessionManager;
  getNotificationsManager(): NotificationsManager | null;
  getTitleById(id: string): Promise<Title>;
  getSeriesById(id: string): Promise<Series>;
  getSeriesSeasonsById(seriesId: string): Promise<Series[]>;
  getSeriesEpisodesById(seriesId: string): Promise<Title[]>;
  getSeasonEpisodesById(seasonId: string, includeTvEvents?: boolean): Promise<Media[]>;
  getRecommendedEpisode(series: Series): Promise<Title | null>;
  getChannelUrls(): Promise<ChannelUrls>;
  updateMedia(media: Media, params: UpdateMediaParams): Promise<void>;
  setBookmark(media: Media, position: number): Promise<void>;
  deleteBookmark(media: Media): Promise<void>;
  search(params: SearchParameters): AsyncIterableIterator<SearchResult>;
  getSearchMinimumLength(): number;
  getNPVRQuota(): Promise<PVRQuota>;
  scheduleRecording(params: PVRScheduleParameters): Promise<Recording>;
  deleteRecordings(recordings: Recording[]): Promise<void>;
  cancelRecordings(recordings: Recording[]): Promise<void>;
  resumeRecordings(recordings: Recording[], params?: PVRUpdateParameters): Promise<void>;
  updateRecordings(recordings: Recording[], params: PVRUpdateParameters): Promise<void>;
  getRecordings(params: PVRQueryParameters): Promise<Recording[]>;
  getAllRecordingsStatuses(): Promise<Map<Event['id'], RecordingStatus>>;
  getRecordingsStatus(events: Event[]): Promise<Map<Event['id'], RecordingStatus>>;
  getIsRecorded(events: Event[]): Promise<Map<Event['id'], boolean>>;
  isSeenFilterAvailable(): boolean;
  getAvailableVODSortOptions(): SortVodBy[];
  getCustomer(): Promise<Customer>;
  registerDevice(device: Device): Promise<void>;
  unregisterDevices(device: Device[], masterPin: string): Promise<void>;
  getDevices(): Promise<Device[]>;
  generateCpeId(): string;
  getEntitledProducts(): Promise<EntitledProduct[]>;
  getStyling(name: string, version: string): Promise<Styling>;
  getAvailableUILanguages(): Promise<string[]>;
  getDefaultUILanguage(): Promise<string>;
  getTranslations(version: string): Promise<Translations>;
  areChannelListsSupported(): boolean;
  getChannelLists(profile: Profile): Promise<ChannelList[]>;
  createChannelList(profile: Profile, name: string, channels: Channel[]): Promise<ChannelList>;
  deleteChannelList(profile: Profile, channelList: ChannelList): Promise<void>;
  updateChannelList(profile: Profile, channelList: ChannelList, name: string, channels: Channel[]): Promise<void>;
  getConsentData(name: string): Promise<Consent>;
  setConsent(slug: string, version: string, accepted: boolean): Promise<void>;
  getCAPMessage(params: CAPQueryParameters): Promise<CAPMessage>;
  purchase(purchaseMethod: PurchaseMethod, params: PurchaseMethodParams): Promise<PurchaseResult>;
  activateAccount(accountId: number, token: string): Promise<void>;
  sendAccountActivationNotification(emailOrUsername: string): Promise<void>;
  registerAccount(data: RegistrationData): Promise<void>;
  validateAccount(data: Partial<RegistrationData>): Promise<void>;
  getPaymentMethods(): Promise<PaymentMethod[]>;
  startPayment(params: StartPaymentParams): Promise<Payment>;
  getOrders(params?: SortOrdersQueryParameters): Promise<{content: Order[]; totalElements: number}>;
  cancelOrder(orderId: string): Promise<void>;
  getMediaByIds(ids: string[]): Promise<Media[]>;
  getProductById(productId: string): Promise<Product>;
  getAssetById(id: string): Promise<Media>;
  startRepayment(order: Order): Promise<Payment>;
  isEPGAvailable(): boolean;
  sendLocation(): Promise<void>;
}
