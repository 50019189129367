import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {View} from 'react-native';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Icon, IconType} from 'components/Icon';
import NitroxInteractive from 'components/NitroxInteractive';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  collapseIcon: colors.chromecast.collapseIcon
}));

const styles = createStyles({
  remoteControlBar: {
    alignItems: 'center'
  },
  collapsedBar: {
    paddingVertical: dimensions.margins.small
  },
  expandedBar: {
    alignItems: 'center',
    paddingVertical: dimensions.margins.medium
  },
  expandIcon: {
    transform: [{scaleY: -1}]
  }
});

type Props = {
  expanded: boolean;
  expandRemoteControl: () => void;
  foldRemoteControl: () => void;
}

const ChromecastRemoteControlBar: React.FC<Props> = (props) => {
  const {expanded, foldRemoteControl, expandRemoteControl} = props;

  const dynamicStyle = stylesUpdater.getStyles();

  const remoteControlBarStyles = useMemo(() => {
    return [styles.remoteControlBar, expanded ? styles.expandedBar : styles.collapsedBar];
  }, [expanded]);

  return (
    <View style={!expanded && styles.expandIcon}>
      <NitroxInteractive onPress={expanded ? foldRemoteControl : expandRemoteControl}>
        <View style={remoteControlBarStyles}>
          <Icon type={IconType.Collapse} size={dimensions.chromecast.remoteControl.collapseIcon.width} color={dynamicStyle.collapseIcon} />
        </View>
      </NitroxInteractive>
    </View>
  );
};

export default React.memo(ChromecastRemoteControlBar);
