import {TitleType, Media} from 'mw/api/Metadata';

import {SeriesMapper} from './SeriesMapper';
import {TitleMapper} from './TitleMapper';

export class WatchListMapper {
  public static fromTitlesArray(titlesJsonArray: any[]): Media[] {
    return titlesJsonArray.map((titleJson) => {
      const titleType = titleJson?.Events?.Event ? TitleType.EPG : TitleType.VOD;
      return TitleMapper.fromJSON(titleJson, titleType);
    });
  }

  public static fromSeriesArray(seriesJsonArray: any[]): Media[] {
    return seriesJsonArray.map((seriesJson) => SeriesMapper.fromJSON(seriesJson));
  }

  public static fromJSONArray(jsonArray: any): Media[] {
    const mediaArray: Media[] = [];
    jsonArray.forEach((json: any) => {
      if (json.Titles && json.Titles.Title && json.Titles.Title.length) {
        Array.prototype.push.apply(mediaArray, WatchListMapper.fromTitlesArray(json.Titles.Title));
      }
      if (json.SeriesCollection && json.SeriesCollection.Series && json.SeriesCollection.Series.length) {
        Array.prototype.push.apply(mediaArray, WatchListMapper.fromSeriesArray(json.SeriesCollection.Series));
      }
    });
    return mediaArray;
  }
}
