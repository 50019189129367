import {createStyles} from 'common-styles';
import React, {useState, useCallback, useRef} from 'react';
import {View} from 'react-native';

import {dimensions} from 'common/constants';
import {DateUtils} from 'common/DateUtils';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {mw} from 'mw/MW';
import chromecastEventEmitter from 'mw/platform/chromecast/ChromecastEventEmitter';
import {ChromecastEvent, MediaPlaybackPosition} from 'mw/platform/chromecast/ChromecastInterface';
import {ChromecastModule} from 'mw/platform/chromecast/ChromecastModule';

import {Icon, IconType} from 'components/Icon';
import NitroxInteractive from 'components/NitroxInteractive';
import Separator from 'components/Separator';
import ProgressBarView, {ProgressBarViewTheme} from 'components/zapper/ProgressBarView';
import {useEventListener} from 'hooks/Hooks';

const TAG = 'ChromecastRemoteControlExpanded';

const timeFormat = 'H:mm:ss';
const bigIconSize = dimensions.chromecast.remoteControl.expanded.iconTop.height;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  separator: {
    width: '100%',
    height: 1,
    marginVertical: dimensions.margins.large,
    backgroundColor: colors.payments.order.numberedList.background
  },
  connectedIcon: colors.chromecast.connectedIcon
}));

const styles = createStyles({
  innerContainer: {
    paddingHorizontal: dimensions.margins.small,
    maxWidth: dimensions.chromecast.remoteControl.expanded.innerContainer.maxWidth,
    width: '100%',
    alignSelf: 'center'
  },
  expandedSection: {
    paddingVertical: dimensions.margins.large
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  centeredRow: {
    justifyContent: 'center'
  },
  icon: {
    marginLeft: dimensions.margins.xxLarge
  },
  bigIconContainer: {
    width: bigIconSize
  }
});

const staticStyles = {
  expandedSectionRow: [styles.row, styles.expandedSection],
  expandedCenteredSectionRow: [styles.row, styles.expandedSection, styles.centeredRow]
};

type Props = {
  paused: boolean;
  onSkipBackward: () => void;
  onStartPlayback: () => void;
  onPausePlayback: () => void;
  onStopMediaSending: () => void;
  onDisconnectPopupOpen: () => void;
  onLanguagePopupOpen: () => void;
}

const ChromecastRemoteControlExpanded: React.FC<Props> = (props) => {
  const {
    paused,
    onSkipBackward,
    onStartPlayback,
    onPausePlayback,
    onStopMediaSending,
    onDisconnectPopupOpen,
    onLanguagePopupOpen
  } = props;
  const [duration, setDuration] = useState(0);
  const [playerPosition, setPlayerPosition] = useState(0);
  const progressResponderStarted = useRef(false);

  useEventListener(ChromecastEvent.MediaPlaybackPosition, ({position, duration}: MediaPlaybackPosition) => {
    Log.debug(TAG, `onMediaPlaybackPosition: position: ${position} duration: ${duration}`);
    if (progressResponderStarted.current) {
      //Do not update player position while dragging the progress cursor as onProgressChange uses it to compute seek delta
      return;
    }
    setPlayerPosition(position);
    setDuration(duration);
  }, chromecastEventEmitter);

  const onProgressResponderStateChanged = useCallback((started: boolean) => {
    progressResponderStarted.current = started;
  }, []);

  const onProgressChange = useCallback((position: number) => {
    const newPosition = position * duration - playerPosition;
    ChromecastModule.seek(newPosition);
    //Set player position immediately so the next delta can be computed correctly
    setPlayerPosition(playerPosition + newPosition);
  }, [duration, playerPosition]);

  const onSkipForward = useCallback(() => {
    ChromecastModule.seek(mw.configuration.playbackSkip);
  }, []);

  const dynamicStyle = stylesUpdater.getStyles();

  return (
    <View style={styles.innerContainer}>
      <View style={staticStyles.expandedSectionRow}>
        <NitroxInteractive onPress={onSkipBackward}>
          <Icon type={IconType.SkipBack10} size={bigIconSize} />
        </NitroxInteractive>
        <NitroxInteractive onPress={paused ? onStartPlayback : onPausePlayback} style={styles.bigIconContainer}>
          <Icon type={paused ? IconType.Play : IconType.Pause} size={bigIconSize} />
        </NitroxInteractive>
        <NitroxInteractive onPress={onStopMediaSending}>
          <Icon type={IconType.Stop} size={bigIconSize} />
        </NitroxInteractive>
        <NitroxInteractive onPress={onSkipForward}>
          <Icon type={IconType.SkipForward10} size={bigIconSize} />
        </NitroxInteractive>
      </View>
      <Separator horizontal style={dynamicStyle.separator} />
      <View style={staticStyles.expandedSectionRow}>
        <ProgressBarView
          labelsFormat={timeFormat}
          currentTime={playerPosition * DateUtils.msInSec}
          currentProgress={playerPosition / duration}
          availableProgressEnd={1}
          availableProgressStart={0}
          startTimeLabelVisible={false}
          endTimeLabelVisible={false}
          currentTimeLabelVisible={true}
          currentTimeLabelAlignment='nextTo'
          currentTimeSeparator=''
          onProgressChange={onProgressChange}
          isDraggableForward={true}
          isDraggableBackward={true}
          isExpandable={false}
          theme={ProgressBarViewTheme.Chromecast}
          onGestureStateChange={onProgressResponderStateChanged}
        />
      </View>
      <Separator horizontal style={dynamicStyle.separator} />
      <View style={staticStyles.expandedCenteredSectionRow}>
        <NitroxInteractive onPress={onDisconnectPopupOpen}>
          <Icon type={IconType.CastConnected} size={dimensions.icon.xxsmall} color={dynamicStyle.connectedIcon} />
        </NitroxInteractive>
        <NitroxInteractive onPress={onLanguagePopupOpen} style={styles.icon}>
          <Icon type={IconType.SubtitleMobile} size={dimensions.icon.xxsmall} />
        </NitroxInteractive>
      </View>
    </View>
  );
};

export default React.memo(ChromecastRemoteControlExpanded);
