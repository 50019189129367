import {compactMap} from 'common/HelperFunctions';

import {Credits, Genre, Metadata} from 'mw/api/Metadata';

export class MetadataMapper {
  private static createCredits(name: string): Credits | null {
    if (typeof name !== 'string') {
      return null;
    }
    return new Credits(name, '');
  }

  public static metadataFromJson(json: any): Metadata {
    const {description, longDescription, taxonomyTerms, actors, directors, releaseYear, duration} = json;
    const metadata = new Metadata();
    metadata.shortSynopsis = description ?? '';
    metadata.longSynopsis = longDescription ?? '';
    if (taxonomyTerms?.genres) {
      const genres: [string, string][] = Object.entries(taxonomyTerms.genres);
      metadata.genres = genres
        .sort((a, b) => a[1].toLowerCase().localeCompare(b[1].toLowerCase()))
        .map(([code, name]) => new Genre(code, name));
    }
    if (actors) {
      metadata.actors = compactMap(actors, MetadataMapper.createCredits);
    }
    if (directors) {
      metadata.directors = compactMap(directors, MetadataMapper.createCredits);
    }
    metadata.productionYear = releaseYear ?? -1;
    metadata.duration = duration ?? 0;
    return metadata;
  }
}
