import {createStyles} from 'common-styles';
import React, {ReactNode, useMemo} from 'react';
import {View} from 'react-native';
import {NavigationInjectedProps} from 'react-navigation';

import {AppRoutes} from 'common/constants';

import FocusParent from 'components/FocusParent';
import {useNavigation} from 'hooks/Hooks';
import SettingsAccountScreen from 'screens/settings/SettingsAccountScreen';

import OrderDetails from './settings/OrderDetails';
import SettingsLanguageScreen from './settings/profileEdit/SettingsLanguageScreen';
import SettingsNameScreen from './settings/profileEdit/SettingsNameScreen';
import SettingsParentalControlScreen from './settings/profileEdit/SettingsParentalControlScreen';
import SettingsPinScreen from './settings/profileEdit/SettingsPinScreen';
import SettingsTimeAndDateScreen from './settings/profileEdit/SettingsTimeAndDateScreen';
import SettingsAppVersionScreen from './settings/SettingsAppVersionScreen';
import SettingsBackOfficeScreen from './settings/SettingsBackOfficeScreen';
import SettingsCustomerScreen from './settings/SettingsCustomerScreen';
import SettingsDeleteProfileScreen from './settings/SettingsDeleteProfileScreen';
import SettingsHelpScreen from './settings/SettingsHelpScreen';
import SettingsOrdersScreen from './settings/settingsOrder/SettingsOrdersScreen';
import SettingsRegisteredDevicesScreen from './settings/SettingsRegisteredDevicesScreen';

const subScreens = {
  [AppRoutes.SettingsCustomer]: SettingsCustomerScreen,
  [AppRoutes.SettingsAppVersion]: SettingsAppVersionScreen,
  [AppRoutes.SettingsBackOffice]: SettingsBackOfficeScreen,
  [AppRoutes.SettingsDeleteProfile]: SettingsDeleteProfileScreen,
  [AppRoutes.SettingsParentalControl]: SettingsParentalControlScreen,
  [AppRoutes.SettingsLanguage]: SettingsLanguageScreen,
  [AppRoutes.SettingsTimeAndDate]: SettingsTimeAndDateScreen,
  [AppRoutes.SettingsName]: SettingsNameScreen,
  [AppRoutes.SettingsRegisteredDevices]: SettingsRegisteredDevicesScreen,
  [AppRoutes.SettingsPin]: SettingsPinScreen,
  [AppRoutes.SettingsAccount]: SettingsAccountScreen,
  [AppRoutes.SettingsOrders]: SettingsOrdersScreen,
  [AppRoutes.SettingsHelp]: SettingsHelpScreen,
  [AppRoutes.OrderDetails]: OrderDetails
};

export type SubScreenRoutes = keyof typeof subScreens;

const getSubScreenForRoute = <T extends SubScreenRoutes>(route: T): (typeof subScreens[T]) => {
  return subScreens[route];
};

type ScreenProps = {
  [key: string]: any;
} & NavigationInjectedProps<any>;

type ParallelNavigatorProps = {
  subRoute?: SubScreenRoutes;
  children: ReactNode;

  subScreenWidth?: number;
  mainScreenWidth?: number;
  screenProps?: ScreenProps;
}

const styles = createStyles({
  container: {
    flexDirection: 'row',
    flex: 1
  }
});

const ParallelNavigator: React.FC<ParallelNavigatorProps> = props => {
  const SubScreenComponent = props.subRoute ? getSubScreenForRoute(props.subRoute) : null;
  const navigation = useNavigation();
  const screenProps = useMemo(() => props.screenProps || {navigation}, [props.screenProps, navigation]);
  const subScreenStyle = useMemo(() => typeof props.subScreenWidth === 'number' ? {width: props.subScreenWidth} : {flex: 1}, [props.subScreenWidth]);
  const mainScreenStyle = useMemo(() => typeof props.mainScreenWidth === 'number' ? {width: props.mainScreenWidth} : {flex: 1}, [props.mainScreenWidth]);
  return (
    <View style={styles.container}>
      <View style={mainScreenStyle}>
        {props.children}
      </View>
      {SubScreenComponent && (
        <FocusParent style={subScreenStyle} debugName='ParallelNavigator'>
          <SubScreenComponent {...screenProps} />
        </FocusParent>
      )}
    </View>
  );
};

export default ParallelNavigator;
