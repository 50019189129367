import {Log} from 'common/Log';

import {nxffConfig} from 'mw/api/NXFF';

import {resources} from './resources';

const TAG = 'QueryProps';

interface Basic {
  basic: string;
}

interface Category extends Basic {
  extended: string;
  subCategory: string;
  rootcategory: string;
  adults: string;
}

interface Channel extends Basic {
  channel: string;
  entitlements: string;
  isOnRecordingOptInList: string;
  minimal: string;
  events: string;
  search: string;
}

interface Content extends Basic {
  aliases: string;
  content: string;
  entitlements: string;
  personal: string;
  preview: string;
  tstv: string;
}

interface Coupon extends Basic {
  customer: string;
}

interface Customer {
  quota: string;
  basic: string;
}

interface Event extends Basic {
  details: string;
  event: string;
  main: string;
  tstv: string;
  personalTstv: string;
  recommended: string;
  reminder: string;
  search: string;
  wishList: string;
  bookmark: string;
}

interface Product extends Basic {
  event: string;
  personal: string;
  relations: string;
  searchAll: string;
}

interface Recording extends Basic {
  details: string;
  list: string;
  status: string;
  parent: string;
  personalPage: string;
}

interface Title extends Basic {
  detailsRecording: string;
  extendedRecording: string;
  personal: string;
  personalPageRecording: string;
  detailedTitleEvent: string;
  preview: string;
  previewWishList: string;
  recommendedEvent: string;
  rootRecording: string;
  searchAll: string;
  title: string;
  episode: string;
  titleEvent: string;
  wishList: string;
  subRecording: string;
}

interface Series extends Basic {
  minimal: string;
  seriesCollection: string;
  series: string;
  personal: string;
  personalAndTitles: string;
  search: string;
}

interface Props {
  CAP: Basic;
  CAPInfo: Basic;
  Category: Category;
  Channel: Channel;
  ChannelList: Basic;
  Content: Content;
  Coupon: Coupon;
  Cpe: Basic;
  Customer: Customer;
  Event: Event;
  Message: Basic;
  Policy: Basic;
  Product: Product;
  Profile: Basic;
  Recording: Recording;
  Root: Basic;
  Title: Title;
  Series: Series;
}

class QueryProps {
  public static instance = new QueryProps();
  public props: Props = {} as any as Props;

  //model-Traxis mapping
  //TODO: redesign the structure, make it generic, apply to other model objects (???)
  public readonly seriesMap = {
    'isAdult': 'IsAdult',
    'name': 'Name',
    'onWishList': 'OnWishList',
    'ordinal': 'Ordinal',
    'personalRating': 'PersonalRating',
    'productionDate': 'ProductionDate',
    'sortname': 'SortName'
  };

  public readonly titleMap = {
    'actors': 'Actors',
    'ageRating': 'Ratings',
    'allGenres': 'AllGenres',
    'bookmark': 'Bookmark',
    'broadcastDate': 'BroadcastDate',
    'contents': 'Contents',
    'customProperties': 'CustomProperties',
    'directors': 'Directors',
    'durationInSeconds': 'DurationInSeconds',
    'episodeName': 'EpisodeName',
    'episodeNum': 'Ordinal',
    'events': 'Events',
    'genres': 'Genres',
    'longSynopsis': 'LongSynopsis',
    'isEntitled': '', //no EntitlementState in Title
    'isAdult': 'IsAdult',
    'isPersonallyViewedCompletely': 'IsPersonallyViewedCompletely',
    'isPreview': 'IsPreview',
    'isViewable': 'IsViewableOnCpe',
    'isViewedCompletely': 'IsViewedCompletely',
    'name': 'Name',
    'onWishList': 'OnWishList',
    'ordinal': 'Ordinal',
    'personalRating': 'PersonalRating',
    'pictures': 'Pictures',
    'previews': 'Previews',
    'productionDate': 'ProductionDate',
    'productionLocations': 'ProductionLocations',
    'seasonNum': 'SeriesCollection',
    'series': 'SeriesCollection',
    'seriesName': 'SeriesCollection',
    'shortSynopsis': 'ShortSynopsis',
    'sortname': 'SortName',
    'studio': 'Studio'
  };

  public readonly categoryMap = {
    'childCategories': 'ChildCategories',
    'childCategoryCount': 'ChildCategoryCount',
    'customProperties': 'CustomProperties',
    'isAdult': 'IsAdult',
    'minimumAge': 'MinimumAge',
    'name': 'Name',
    'ordinal': 'Ordinal',
    'pictures': 'Pictures',
    'productCount': 'ProductCount',
    'products': 'Products',
    'series': 'SeriesCollection',
    'seriesCount': 'SeriesCount',
    'shortSynopsis': 'ShortSynopsis',
    'softLinks': 'SoftLinks',
    'titleCount': 'TitleCount',
    'titles': 'Titles'
  };

  public initialize = (): void => {

    if (!resources.hasResource('Coupon')) {
      Log.warn(TAG, 'not enough data to use Coupons');
      // TODO: turn off coupons in configuration
    }

    if (!resources.hasProperties('Recording', 'ParentRecordingCount')) {
      Log.warn(TAG, 'not enough data to use nPvr');
      // TODO: turn off recordings in configuration
    }

    const props: any = {};

    props.CAP = {
      basic: 'CAPInfos,MsgType,Scope,Sender,Status'
    };

    props.CAPInfo = {
      basic: 'Language,SenderName,Event,Effective,Expires,Headline,Instruction,Description,CAPResources,CAPParameters,CAPAreas'
    };

    props.Category = {
      basic: 'Name,Pictures,IsAdult,ShortSynopsis,CustomProperties,ChildCategoryCount,MinimumAge'
    };
    props.Category.extended = props.Category.basic + ',SoftLinks';
    props.Category.subCategory = props.Category.basic + ',ShortSynopsis,IsAdult,Ordinal,SortName';
    props.Category.rootcategory = props.Category.subCategory + ',IsRoot,SoftLinks';
    props.Category.adults = 'Aliases,IsAdult';

    props.Channel = {
      minimal: 'LogicalChannelNumber,IsViewableOnCpe',
      isOnRecordingOptInList: 'IsOnRecordingOptInList',
      events: 'Events',
      search: 'Name,LongName,Owners'
    };
    props.Channel.basic = props.Channel.minimal + ',Name,Pictures';
    // TODO: use configuration constants
    const useNPvr = nxffConfig.getConfig().PVR.EnabledNPVR;
    const traxisRecordingOptIn = false;
    props.Channel.entitlements = props.Channel.minimal + ',Products' + (useNPvr ? ',IsNetworkRecordingAllowed' : '');
    props.Channel.channel = props.Channel.basic + ',LongName,Products,IsAdult,Policies' +
      (useNPvr ? ',IsNetworkRecordingAllowed,RecordingInThePastLimit' : '') +
      (traxisRecordingOptIn ? ',IsOnRecordingOptinList' : '') +
      ',OngoingEventRecording';

    props.ChannelList = {
      basic: 'Name,ProfileId,RelationOrdinal,Channels'
    };

    props.Content = {
      aliases: 'Aliases',
      entitlements: 'Aliases,EntitlementState,EntitlementEnd,FirstAvailability,LastAvailability,IsAdult,DurationInSeconds,CustomProperties',
      basic: 'FirstAvailability,LastAvailability,CustomProperties',
      tstv: 'Aliases,Products,DurationInSeconds,RecommendedPlaybackNPT,FirstAvailability,LastAvailability,IsAdult',
      preview: 'EntitlementState,EntitlementEnd,Aliases,DurationInSeconds,FirstAvailability,LastAvailability'
    };
    props.Content.content = props.Content.basic + ',DurationInSeconds,IsAdult,Products,Aliases';
    props.Content.personal = props.Content.content + ',EntitlementState,EntitlementEnd';

    props.Coupon = {
      basic: 'Name,Value,Currency,ExpirationDate,PromotionCode'
    };
    props.Coupon.customer = props.Coupon.coupon + ',StartDate,DiscountPercentage';

    props.Cpe = {
      basic: 'Name,CustomerManaged'
    };

    props.Customer = {
      basic: 'Aliases,SimSubRegionId,NamedProperties,NpvrQuota,NpvrQuotaRemaining,DefaultProfiles',
      quota: 'NpvrQuota,NpvrQuotaRemaining'
    };

    props.Event = {
      basic: 'ChannelId,AvailabilityStart,AvailabilityEnd',
      tstv: 'IsRepeat,TstvContents'
    };
    props.Event.personalTstv = props.Event.tstv + ',PersonalBookmark,IsPersonallyViewedCompletely';
    props.Event.main = props.Event.basic + (useNPvr ? ',IsNetworkRecordingAllowed' : '');
    props.Event.wishList = props.Event.basic + (useNPvr ? ',IsRecorded' : '');
    props.Event.event = props.Event.main + ',Titles';
    props.Event.recommended = props.Event.event + ',Channels';
    props.Event.reminder = props.Event.basic + ',Titles,OnReminders';
    props.Event.search = props.Event.basic + (useNPvr ? ',IsNetworkRecordingAllowed,IsRecorded' : '');
    props.Event.details = props.Event.event + (useNPvr ? ',IsRecorded' : '');
    props.Event.bookmark = 'PersonalBookmark,IsPersonallyViewedCompletely';

    props.Message = {
      basic: 'MessageType,CAP'
    };

    props.Policy = {
      basic: 'IsAvailableByPolicy'
    };

    props.Product = {
      searchAll: 'EntitlementState,OfferPrice,Type,ListPrice,Currency',
      event: 'Type,Currency,IsAvailable,ListPrice'
    };
    props.Product.basic = props.Product.event + ',Name,OfferPrice,RentalPeriod,IsRentalPeriodIndefinite';
    props.Product.personal = props.Product.basic + ',EntitlementState,EntitlementStart,EntitlementEnd';

    props.Product.relations = 'RelationAvailabilityStart,RelationAvailabilityEnd';

    props.Profile = {
      basic: 'Aliases,Name,Language,Pin,AudioLanguagePreferences,SubtitleLanguagePreferences,NamedProperties'
    };

    props.Recording = {
      basic: 'Name,Type,State,IsPersonal,Duration,GuardTimeStart,GuardTimeEnd,Channels,StartTime,EndTime,EventId,FactoryState,ProfileId,PersonalBookmark,IsPersonallyViewedCompletely,ActualStartTime,ActualEndTime,Contents,SeedSeriesNumber,ErrorCode',
      status: 'Name,Type,State,EventId',
      parent: 'FactoryState,State,Type,IsPersonal,ProfileId',
      personalPage: 'Name,Type,Duration,EventId'
    };

    props.Recording.list = props.Recording.basic + ',ChildRecordings';
    props.Recording.details = props.Recording.basic + ',ParentRecordings';

    props.Root = {
      basic: 'InterfaceVersion,Name,ChannelLocationsUrl'
    };

    props.Title = {
      tmpBasic: 'Name,IsAdult,Ratings,Pictures,Genres,EpisodeName,ProductionDate,ShortSynopsis',
      rootRecording: 'IsAdult,Ordinal,IsPersonallyViewedCompletely,EpisodeName,OriginalSeasonNumber,OriginalEpisodeNumber,Pictures,Ratings,Genres',
      personal: [
        resources.chooseSupportedProperty('Title', ['PersonalBookmark', 'Bookmark']),
        resources.chooseSupportedProperty('Title', ['IsPersonallyViewedCompletely', 'IsViewedCompletely'])
      ].join(','),
      preview: 'Name,Ratings,Contents,IsPreview,Pictures'
    };
    props.Title.basic = props.Title.tmpBasic + ',Contents';
    props.Title.extendedRecording = props.Title.rootRecording + ',LastViewDate,MediumSynopsis,LongSynopsis' + resources.chooseSupportedProperty('Title', ['PersonalBookmark', 'Bookmark']);
    props.Title.titleEvent = props.Title.tmpBasic + ',MediumSynopsis,LongSynopsis,SeriesCollection,ProductionLocations';
    props.Title.detailedTitleEvent = props.Title.titleEvent + ',ActorsCharacters,DirectorNames,OnWishList,PersonalRating';
    props.Title.recommendedEvent = props.Title.detailedTitleEvent + ',IsPreview';
    props.Title.subRecording = props.Title.rootRecording + ',Name';

    props.Title.title = props.Title.tmpBasic + ',' + props.Title.personal + ',ShortName,IsPreview,IsFeature,IsViewableOnCpe,Actors,Directors,BroadcastDate,Previews,Contents,DurationInSeconds,MediumSynopsis,LongSynopsis,MinimumAge,SeriesCollection,ProductionLocations,OriginalLanguages,Ordinal,PersonalRating,AllGenres';
    props.Title.episode = props.Title.title + ',OriginalSeasonNumber,OriginalEpisodeNumber';
    props.Title.wishList = props.Title.title + ',EventCount,Events,ContentCount,OnWishList';
    props.Title.previewWishList = props.Title.preview + ',OnWishList';
    props.Title.searchAll = 'Name,Pictures,EpisodeName,DurationInSeconds,ProductionDate,Genres,Ratings,ShortSynopsis,OriginalSeasonNumber,OriginalEpisodeNumber,EpisodeName';

    props.Series = {
      minimal: 'Name,Ordinal,RelationOrdinal,Type,ParentSeriesCount'
    };
    props.Series.basic = props.Series.minimal + ',IsAdult,ShortSynopsis,MediumSynopsis,LongSynopsis,Pictures,ChildSeriesCount,NumberOfEpisodes,TitleCount';
    props.Series.seriesCollection = props.Series.basic + ',ParentSeriesCollection';
    props.Series.series = props.Series.basic + ',ChildSeriesCollection';
    props.Series.personal = props.Series.series + ',PersonalRating';
    props.Series.personalAndTitles = props.Series.personal + ',Titles';
    props.Series.search = props.Series.minimal + ',Pictures';

    this.props = this.filterProps(props);
  }

  private filterProps(props: any): Props {
    const filteredProps: any = {};
    for (const resourceName in props) {
      const resourceObject = props[resourceName];
      const filteredObject: any = {};
      for (const label in resourceObject) {
        filteredObject[label] = resources.removeUnknownProperties(resourceName, resourceObject[label]);
      }
      filteredProps[resourceName] = filteredObject;
    }
    return filteredProps;
  }
}

export const queryProps: QueryProps = QueryProps.instance;
