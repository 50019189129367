import {defaultStyles, createStyles} from 'common-styles';
import React, {useCallback} from 'react';
import {View, ImageBackground} from 'react-native';

import {isMobile} from 'common/constants';

import {assets} from 'brand/Resources';
import ConditionalWrapper from 'components/ConditionalWrapper';

const styles = createStyles({
  width: '100%',
  height: '100%'
});

const EmptyBackgroundScreen: React.FunctionComponent = props => {
  const imageBackgroundWrapper = useCallback(children => (
    <ImageBackground
      style={styles}
      source={assets.common.splashScreen}
      resizeMode={'cover'}
    >
      {children}
    </ImageBackground>
  ), []);

  return (
    <ConditionalWrapper
      condition={isMobile}
      wrapper={imageBackgroundWrapper}
    >
      <View style={defaultStyles.view}>
        {props.children}
      </View>

    </ConditionalWrapper>
  );
};

export default EmptyBackgroundScreen;
