import React from 'react';
import {useRef} from 'react';
import {View, PanResponder, PanResponderGestureState, GestureResponderEvent, ViewStyle, Insets} from 'react-native';

import {Direction, directionToAxis, Axis} from 'common/constants';
import {maxBy} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {inInsets, getDistanceFromEdges, isSwipe} from './GestureGeometry';

const TAG = 'GestureRecognizerView';

const EDGE_DISTANCE_THRESHOLD = 20;
const defaultInsets = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 0
};

type Props = {
  style: ViewStyle;
  directions: Direction[];
  swipeFromEdge?: Direction;
  /**
   * Insets to limit gesture recognition to some area.
   */
  gestureRecognitionInsets?: Insets;
  /**
   * Whether component should become responder on start of a touch.
   * Don't use unless you know what you're doing!
   * false by default
   */
  becomeResponderOnTouchStart?: boolean;
  onSwipeFromEdge?: () => void;
  onSwipeUp?: () => void;
  onSwipeDown?: () => void;
  onSwipeLeft?: () => void;
  onSwipeRight?: () => void;
}

type SwipeHandler = {
  handler: () => void;
  magnitude: number;
};

const GestureRecognizerView: React.FunctionComponent<Props> = props => {
  const {
    gestureRecognitionInsets: insets = defaultInsets,
    becomeResponderOnTouchStart = false
  } = props;
  const swipedFromEdge = useRef(false);
  const responderHandlers = PanResponder.create({
    onStartShouldSetPanResponder: () => becomeResponderOnTouchStart,
    onMoveShouldSetPanResponder: (evt, gestureState) => {
      const distanceFromEdge = getDistanceFromEdges(gestureState);

      if (!inInsets(distanceFromEdge, insets)) {
        Log.trace('GestureRecognizerView', `Ignoring out-of-insets gesture`);
        return false;
      }
      const isHorizontalSwipe = isSwipe(gestureState, Axis.X);
      const isVerticalSwipe = isSwipe(gestureState, Axis.Y);

      // return true if user is swiping, return false if it's a single click
      // swipe from edge has priority
      if (props.swipeFromEdge) {
        Log.trace('GestureRecognizerView', `Distance from edge`, distanceFromEdge);

        if (isHorizontalSwipe) {
          switch (props.swipeFromEdge) {
            case Direction.Right:
              if (distanceFromEdge.right >= 0 && distanceFromEdge.right <= EDGE_DISTANCE_THRESHOLD) {
                swipedFromEdge.current = true;
                return true;
              }
              break;
            case Direction.Left:
              if (distanceFromEdge.left >= 0 && distanceFromEdge.left <= EDGE_DISTANCE_THRESHOLD) {
                swipedFromEdge.current = true;
                return true;
              }
              break;
          }
        }
      }
      const axes = props.directions.map(direction => directionToAxis[direction]);
      const handledAxes = {
        vertical: axes.includes(Axis.Y),
        horizontal: axes.includes(Axis.X)
      };

      const shouldSet = (handledAxes.vertical && isVerticalSwipe) ||
        (handledAxes.horizontal && isHorizontalSwipe);

      Log.trace('GestureRecognizerView', `vx=${Math.abs(gestureState.vx)}, dx=${Math.abs(gestureState.dx)}, shouldSet=${shouldSet}`);

      return shouldSet;
    },
    onStartShouldSetPanResponderCapture: () => false,
    onMoveShouldSetPanResponderCapture: () => false,
    onPanResponderRelease: (e: GestureResponderEvent, gestureState: PanResponderGestureState) => {
      Log.debug(TAG, `onPanResponderRelease: dy: ${gestureState.dy}, vy: ${gestureState.vy}, dx: ${gestureState.dx}, vx: ${gestureState.vx}`);

      if (props.swipeFromEdge && swipedFromEdge.current === true) {
        props.onSwipeFromEdge && props.onSwipeFromEdge();
        swipedFromEdge.current = false;
        return;
      }

      // detect gesture movement for all of the registered swipe handlers
      const swipeHandlers: SwipeHandler[] = [];
      if (props.onSwipeDown && gestureState.vy < 0) {
        swipeHandlers.push({handler: props.onSwipeDown, magnitude: -gestureState.vy});
      }
      if (props.onSwipeUp && gestureState.vy > 0) {
        swipeHandlers.push({handler: props.onSwipeUp, magnitude: gestureState.vy});
      }
      if (props.onSwipeLeft && gestureState.vx > 0) {
        swipeHandlers.push({handler: props.onSwipeLeft, magnitude: gestureState.vx});
      }
      if (props.onSwipeRight && gestureState.vx < 0) {
        swipeHandlers.push({handler: props.onSwipeRight, magnitude: -gestureState.vx});
      }

      if (swipeHandlers.length === 0) {
        Log.error(TAG, 'Unknown swipe. Something went wrong.');
        return;
      }

      // execute the most fitting swipe handler
      maxBy(swipeHandlers, (lhs, rhs) => lhs.magnitude > rhs.magnitude)?.handler();
    }
  });

  return (
    <View
      style={props.style}
      {...responderHandlers.panHandlers}
    >
      {props.children}
    </View>
  );
};

export default GestureRecognizerView;
