import {useCallback, useMemo, useState} from 'react';
import {NavigationInjectedProps} from 'react-navigation';

import {isWeb} from 'common/constants';
import {doNothing} from 'common/HelperFunctions';
import {NavigationFocusState} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {BackOfficeEvent} from 'mw/api/BackOffice';
import {ContentType, isChannel, Media} from 'mw/api/Metadata';
import {mw} from 'mw/MW';
import {isPlaybackParametersEqual, PlaybackRequestParameters, Player} from 'mw/playback/Player';

import {PlaybackRetuneScheduler} from 'components/player/PlaybackRetuneScheduler';
import {PlayerViews} from 'components/player/PlayerView';
import {TopLevelPortalManager, TopLevelPortalType} from 'components/TopLevelPortalManager';
import {useAppState, useChangeEffect, useDisposable, useEventListener, useFunction, useNavigationFocusState, useLazyEffect} from 'hooks/Hooks';

import {MediaObserver} from './MediaObserver';

const TAG = 'PlayerManager';

export enum TuneRequestState {
  Accepted,
  Completed,
  Rejected,
  UnavailableByPolicy
}

type TuneCallback = (state: TuneRequestState, tuneRequest: TuneRequest) => void;

export type TuneRequest = {
  media: Media;
  playerView: PlayerViews;
  parameters: PlaybackRequestParameters;
  callback: TuneCallback;
  forceTuneAttempt?: boolean;
}

function isTuneRequestEqual(a: TuneRequest, b: TuneRequest): boolean {
  return a.media.id === b.media.id
    && a.playerView === b.playerView
    && isPlaybackParametersEqual(a.parameters, b.parameters);
}

export class PlayerManager extends MediaObserver {
  private pendingTuneRequest: TuneRequest | null = null;
  private processingTuneRequest: TuneRequest | null = null;

  private idlePromise: Promise<void> | null = null;
  private onIdle: (() => void) | null = null;

  private constructor() {
    super();
  }

  private static instance: PlayerManager = new PlayerManager();

  private async handleTuneRequest(tuneRequest: TuneRequest) {
    const player = mw.players.main;
    const currentMedia = player.getCurrentMedia();
    const currentPlayer = player.getPlayerViewId();
    if (isChannel(tuneRequest.media) && !tuneRequest.media.isAvailableByPolicy) {
      await player.stop();
      Log.debug(TAG, `Channel is blocked by policy: ${tuneRequest.media.id}`);

      tuneRequest.callback(TuneRequestState.UnavailableByPolicy, tuneRequest);
      return;
    }
    if (!isWeb && // TODO: CL-1433 Remove checking playerView after switchPlayerView gets implemented
      player.isPlayerInitialized() &&
      isChannel(currentMedia) &&
      currentMedia?.id === tuneRequest.media.id
    ) {
      if (tuneRequest.playerView !== currentPlayer) { // FIXME: CL-1433 this is just a race workaround
        await player.switchPlayerView(tuneRequest.playerView);
      } else {
        Log.debug(TAG, `There is no need to do anything to handle tune request for media: ${tuneRequest.media}`);
      }
      tuneRequest.callback(TuneRequestState.Completed, tuneRequest);
      return;
    }

    Log.debug(TAG, `Tuning media ${tuneRequest.media} and player view ${tuneRequest.playerView}`);

    await player
      .start(tuneRequest.playerView, tuneRequest.media, tuneRequest.parameters)
      .catch(error => Log.error(TAG, 'Tuning media failed', error));

    tuneRequest.callback(TuneRequestState.Completed, tuneRequest);
  }

  private handleNextTuneRequest() {
    const tuneRequest = this.processingTuneRequest = this.pendingTuneRequest;
    this.pendingTuneRequest = null;
    if (!tuneRequest) {
      this.onIdle?.();
      Log.debug(TAG, 'There are no pending tune requests to handle');
      return;
    }
    if (!this.idlePromise) {
      this.createIdlePromise();
    }
    Log.debug(TAG, `Handling request to tune media ${tuneRequest.media}`);
    this.handleTuneRequest(tuneRequest)
      .finally(() => {
        Log.debug(TAG, `Tune request for media ${tuneRequest.media} was handled`);
        this.processingTuneRequest = null;
        this.handleNextTuneRequest();
      });
  }

  private createIdlePromise() {
    this.idlePromise = new Promise(resolve => {
      this.onIdle = () => {
        resolve();
        this.idlePromise = null;
      };
    });
  }

  public awaitPendingTunes(): Promise<void> {
    return this.idlePromise ?? Promise.resolve();
  }

  public tune(
    media: Media | undefined,
    playerView: PlayerViews,
    parameters: PlaybackRequestParameters,
    forceTuneAttempt?: boolean,
    callback: TuneCallback = doNothing
  ): void {
    if (!media) {
      return;
    }
    // TODO: In future add some logic that could possible reject the tune request:
    // like passing a channel that was removed from the lineup, parental control restrictions etc
    const tuneRequest = {media, playerView, parameters, callback, forceTuneAttempt};
    Log.debug(TAG, `Requested media ${tuneRequest.media} and player view ${tuneRequest.playerView}`);
    if (this.processingTuneRequest && isTuneRequestEqual(this.processingTuneRequest, tuneRequest)) {
      Log.debug(TAG, `Found tune request for media ${tuneRequest.media} that is already processing - rejecting the duplicate`);
      tuneRequest.callback(TuneRequestState.Rejected, tuneRequest);
      return;
    }
    Log.debug(TAG, `Tune request for media ${tuneRequest.media} was accepted`);
    this.pendingTuneRequest = tuneRequest;
    tuneRequest.callback(TuneRequestState.Accepted, tuneRequest);

    // make sure to handle one tune request at a time
    if (this.processingTuneRequest) {
      Log.debug(TAG, `We are currently tunning - postponing tune request for media`, tuneRequest.media);
      return;
    }
    this.handleNextTuneRequest();
  }

  public static getInstance(): PlayerManager {
    return this.instance;
  }
}

type PlayerState = {
  contentType: ContentType;
  media?: Media;
  playerView?: PlayerViews;
  parameters: PlaybackRequestParameters;
};

function getPlayerState(player: Player): PlayerState {
  const playbackParameters = player.getParameters();
  return {
    contentType: player.contentType,
    media: player.getCurrentMedia() || undefined,
    playerView: player.getPlayerViewId(),
    parameters: {
      playRate: playbackParameters.playRate,
      position: playbackParameters.position
    }
  };
}

function restorePlayerState(playerState: PlayerState, pausePlayback: boolean) {
  if (playerState.media && playerState.playerView != null && playerState.parameters.position) {
    const parameters: PlaybackRequestParameters = {};
    const lastPlayedLiveOrNPLTV = [ContentType.LIVE, ContentType.NPLTV].includes(playerState.contentType);

    if (lastPlayedLiveOrNPLTV) {
      parameters.playRate = 1;
    } else {
      parameters.playRate = pausePlayback ? 0 : playerState.parameters.playRate;
      // FIXME: CL-2152 fix getParameters() to return position as a number
      parameters.position = parseFloat(playerState.parameters.position as any); // eslint-disable-line @typescript-eslint/no-explicit-any
    }

    PlayerManager.getInstance().tune(playerState.media, playerState.playerView, parameters, true);
  }
}

type Props = {
  stopPlaybackOnAppear: boolean;
  closeFloaterOnAppear?: boolean;
  onPlaybackStopped?: () => void;
  debugName?: string;
} & NavigationInjectedProps;

const PlayerManagerComponent: React.FunctionComponent<Props> = props => {
  const {navigation, onPlaybackStopped} = props;
  const [lastPlayerState, setLastPlayerState] = useState<PlayerState>();
  const {stopPlaybackOnAppear, closeFloaterOnAppear = false} = props;
  const appState = useAppState();
  const debugName = useMemo(() =>
    props.debugName ? `[${props.debugName}] ` : ''
  , [props.debugName]);
  const focusState = useNavigationFocusState(navigation);
  const [floatingPortal, setFloatingPortal] = useState(TopLevelPortalManager.getInstance().getCurrentPortal() === TopLevelPortalType.Floating);
  const player = mw.players.main;

  const portalChangeHandler = useCallback((portalType?: TopLevelPortalType) => {
    if (floatingPortal && (portalType === TopLevelPortalType.Fullscreen || portalType === TopLevelPortalType.Floating)) {
      return;
    }
    setFloatingPortal(portalType === TopLevelPortalType.Floating);
  }, [floatingPortal]);

  useEventListener('change', portalChangeHandler, TopLevelPortalManager.getInstance());

  const stopPlayer = useDisposable(() => player.stop(), [player]);
  const restoreLastPlayerState = useFunction(() => {
    if (!lastPlayerState) {
      return false;
    }
    Log.debug(TAG, 'Restoring last player state');
    restorePlayerState(lastPlayerState, true);
    setLastPlayerState(undefined);
    return true;
  });

  useChangeEffect(() => {
    switch (appState) {
      case 'background':
        if (player.isPlayerInitialized()) {
          setLastPlayerState(getPlayerState(player));
          stopPlayer()
            .catch(error => Log.error(TAG, 'Error stopping player on application state change', error));
        }
        break;
      case 'active':
        // If login session renewal is enabled player state is restored in onLoginSessionRenewed.
        if (!mw.configuration.isLoginSessionRenewEnabled) {
          restoreLastPlayerState();
        }
        break;
    }
  }, [appState], [player, lastPlayerState]);

  const onLoginSessionRenewed = useFunction(() => {
    if (focusState !== NavigationFocusState.IsFocused) {
      return;
    }
    if (restoreLastPlayerState()) {
      return;
    }
    if (player.isPlayerInitialized()) {
      Log.debug(TAG, 'Restarting player after login session renewed');
      const playerState = getPlayerState(player);
      stopPlayer()
        .then(() => restorePlayerState(playerState, false))
        .catch(error => Log.error(TAG, 'Error restarting player after login session renewed', error));
    }
  });

  useEventListener(BackOfficeEvent.loginSessionRenewed, onLoginSessionRenewed, mw.bo);

  useLazyEffect(() => {
    if (floatingPortal && !closeFloaterOnAppear) {
      return;
    }
    if (focusState !== NavigationFocusState.IsFocused) {
      return;
    }
    if (stopPlaybackOnAppear) {
      PlaybackRetuneScheduler.getInstance().cancelScheduledRetune();
      if (mw.players.main.isPlayerInitialized()) {
        Log.debug(TAG, debugName + 'Stopping the player');
        mw.players.main.stop()
          .then(() => onPlaybackStopped?.());
      }
      if (TopLevelPortalManager.getInstance().getCurrentPortal() === TopLevelPortalType.Fullscreen) {
        Log.debug(TAG, debugName + 'Hiding top level portal used from fullscreen playback');
        TopLevelPortalManager.getInstance().hideTopLevelPortal();
      }
    }
    if (closeFloaterOnAppear) {
      Log.debug(TAG, debugName + 'Hiding top level portal used from the floater');
      TopLevelPortalManager.getInstance().hideTopLevelPortal();
    }
  }, [focusState, stopPlaybackOnAppear, closeFloaterOnAppear, floatingPortal], [debugName, onPlaybackStopped]);
  return null;
};

export default PlayerManagerComponent;
