import {createStyles} from 'common-styles';
import React, {useState, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions, isPhone, isBigScreen, isMobile, getValue, isAndroid} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import FocusParent from 'components/FocusParent';
import GradientFill from 'components/GradientFill';
import {NativeKeyEvent, SupportedKeys} from 'components/KeyEventManager';
import {Modal} from 'components/Modal';
import NitroxButton from 'components/NitroxButton';
import NitroxInteractive from 'components/NitroxInteractive';
import NitroxText from 'components/NitroxText';
import {useKeyEventHandler, useScreenInfo} from 'hooks/Hooks';
import {PostProcessors} from 'locales/i18nPostProcessors';

import PinPad from './PinPad';

const pinLength = 4;
const dotSize = isBigScreen ? 12 : 8;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  modal: {
    alignItems: isPhone ? 'flex-end' : 'center',
    paddingBottom: 0
  },
  modalContent: {
    alignItems: 'stretch',
    maxWidth: getValue<string | number>({mobile: '100%', tv: 560, desktopBrowser: 560, tablet: 375}),
    width: undefined
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: dimensions.margins.medium,
    paddingBottom: dimensions.margins.small,
    paddingTop: isBigScreen ? dimensions.margins.medium : dimensions.margins.small,
    backgroundColor: colors.pinKeyboard.headerBackground
  },
  dotsContainer: {
    height: dotSize,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: dimensions.margins.xsmall
  },
  dot: {
    height: dotSize,
    width: dotSize,
    borderRadius: 50,
    backgroundColor: colors.popup.text,
    marginHorizontal: 2
  },
  title: {
    color: colors.popup.text,
    marginBottom: isBigScreen ? dimensions.margins.large : dimensions.margins.xsmall,
    textAlign: 'center'
  },
  messageText: {
    color: colors.pinKeyboard.message,
    minHeight: 26,
    textAlign: 'center',
    textAlignVertical: isAndroid ? 'center' : void(0)
  },
  buttonsContainer: {
    paddingHorizontal: 53,
    paddingTop: isBigScreen ? 38 : 24,
    paddingBottom: isBigScreen ? 41 : 32,
    ...isBigScreen && {backgroundColor: colors.pinKeyboard.pinPadBackground}
  },
  cancelContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 41
  },
  cancelButton: {
    alignSelf: 'center'
  },
  cancelText: {
    color: colors.pinKeyboard.cancel.text
  },
  gradientFillColors: colors.popup.gradient
}));

interface DotsProps {
  count: number;
}
const Dots: React.FC<DotsProps> = ({count}) => {
  const styles = stylesUpdater.getStyles();
  return (
    <View style={styles.dotsContainer}>
      {new Array<number>(count)
        .fill(0)
        .map((_, index) => <View key={index} style={styles.dot} />)}
    </View>
  );
};

export interface PinKeyboardProps {
  title: string;
  message?: string;
  visible?: boolean;
  portal?: string;
  onClose: () => void;
  onSubmit: (value: string) => void;
}

const PinKeyboard: React.FC<PinKeyboardProps> = ({
  title,
  message,
  visible,
  onClose,
  onSubmit,
  portal
}) => {
  const {t} = useTranslation();
  const {orientation} = useScreenInfo();

  const [currentValue, setCurrentValue] = useState('');
  const onValuePress = useCallback(
    (value: number) => currentValue.length < pinLength && setCurrentValue(currentValue + value),
    [currentValue]
  );
  const onBackspace = useCallback(
    () => setCurrentValue(currentValue.slice(0, currentValue.length - 1)),
    [currentValue]
  );
  const onCancel = useCallback(
    () => {
      onClose();
      setCurrentValue('');
    },
    [onClose]
  );
  const onValueSubmit = useCallback(
    () => {
      onSubmit(currentValue);
      setCurrentValue('');
    },
    [currentValue, onSubmit]
  );

  const keyHandler = useCallback((key?: SupportedKeys) => {
    if (key == null) return;
    switch (key) {
      case SupportedKeys.Backspace:
        onBackspace();
        break;
      default:
        key >= SupportedKeys.Num0 && key <= SupportedKeys.Num9 && onValuePress(key);
    }
  }, [onBackspace, onValuePress]);

  useKeyEventHandler('keydown', (event: NativeKeyEvent) => {
    keyHandler(event.key);
  });

  const headerTextType = isBigScreen ? 'label-medium' : 'pin-pad';
  const headerHeight = {minHeight: getValue({tablet: 100, mobile: orientation.isLandscape ? '20%' : 100, defaultValue: 150})};
  const buttonsContainerHeight = isPhone && orientation.isLandscape ? {height: '80%'} : {};

  const styles = stylesUpdater.getStyles();
  return (
    <Modal
      onClose={onCancel}
      visible={visible}
      style={styles.modal}
      contentStyle={styles.modalContent}
      portal={portal}
      overlayKey={PinKeyboard.name}
      testID='modal_pinpad'
    >
      <View style={[styles.header, headerHeight]}>
        <NitroxText style={styles.title} textType={headerTextType}>{title}</NitroxText>
        <Dots count={currentValue.length} />
        {!!message &&
          <NitroxText style={styles.messageText} textType='pin-pad-message'>{message}</NitroxText>
        }
      </View>
      <View style={[styles.buttonsContainer, buttonsContainerHeight]}>
        {isMobile && <GradientFill colors={styles.gradientFillColors} />}
        <PinPad
          onNumberPress={onValuePress}
          onSubmit={onValueSubmit}
          onBackspace={onBackspace}
        />
        <View>
          {isBigScreen ? (
            <FocusParent>
              <NitroxButton
                border
                onPress={onCancel}
                text={t('common.cancel', {postProcess: PostProcessors.ToUpperCase})}
                style={styles.cancelButton}
              />
            </FocusParent>
          ) : (
            <NitroxInteractive
              style={styles.cancelContainer}
              testID='button_cancel'
              onPress={onCancel}
            >
              <NitroxText style={styles.cancelText} textType={'pin-pad'}>{t('common.cancel', {postProcess: PostProcessors.ToUpperCase})}</NitroxText>
            </NitroxInteractive>
          )}
        </View>
      </View>
    </Modal>
  );
};

export default React.memo(PinKeyboard);
