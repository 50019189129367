import {timeout} from 'common/Async';
import {DateUtils} from 'common/DateUtils';
import {EventEmitter} from 'common/EventEmitter';
import {Log} from 'common/Log';

import {EPGParams, EPGResponse} from 'mw/api/CatalogInterface';
import {Error, ErrorType} from 'mw/api/Error';
import {Channel, Event} from 'mw/api/Metadata';
import {BlockableIdleActions} from 'mw/common/BlockableIdleActions';
import {mw} from 'mw/MW';

import {EPGCache, EPGCacheEvent} from './EPGCache';

const TAG = 'EPG';
const getEventTimespanInMin = 15;
const isRecordedUpdateDelay = 1000;

class IsRecordedUpdateManager {
  private eventsToUpdate: Event[];
  private timeoutId = 0;
  public constructor() {
    this.eventsToUpdate = [];
  }

  private update = () => {
    const uniqueEvents = Array.from(new Set(this.eventsToUpdate));
    if (!uniqueEvents.length) {
      return;
    }
    Log.debug(TAG, `IsRecordedUpdateManager - updating isRecorded for ${this.eventsToUpdate.length} events`);
    mw.pvr.updateEventsRecordingsState(uniqueEvents);
  }

  private reset() {
    this.eventsToUpdate = [];
    this.timeoutId = 0;
  }

  public requestUpdate(events: Event[]) {
    this.eventsToUpdate = this.eventsToUpdate.concat(events);
    if (this.timeoutId) {
      return;
    }
    this.timeoutId = timeout(() => {
      this.update();
      this.reset();
    }, isRecordedUpdateDelay);
  }
}

export enum EPGEvent {
  EPGCacheRefreshed = 'EPGCacheRefreshed'
}

export class EPG extends EventEmitter<EPGEvent> implements BlockableIdleActions {
  private epgCache: EPGCache
  private isRecordedUpdateManager: IsRecordedUpdateManager;

  public constructor() {
    super();
    // can't use .initializeCache() here, as typescript states required fields to be "definitely assigned in the constructor"
    this.epgCache = new EPGCache();
    this.isRecordedUpdateManager = new IsRecordedUpdateManager();
    this.addListeners();
  }

  private addListeners() {
    this.epgCache.on(EPGCacheEvent.Refreshed, this.notifyEpgCacheRefreshed);
  }

  private removeListeners() {
    this.epgCache.off(EPGCacheEvent.Refreshed, this.notifyEpgCacheRefreshed);
  }

  private notifyEpgCacheRefreshed = () => {
    Log.debug(TAG, 'EPG cache refreshed - forwarding notification');
    this.notify(EPGEvent.EPGCacheRefreshed);
  }

  private updateEventsIsRecorded(eventsMap: EPGResponse) {
    const eventsToUpdate: Event[] = [];
    eventsMap.forEach((events, channelId) => {
      const channel = mw.catalog.getChannelById(channelId);
      if (channel && channel.isNetworkRecordingAllowed) {
        events.forEach(event => event.customProperties.isNetworkRecordingAllowed && eventsToUpdate.push(event));
      }
    });
    if (eventsToUpdate.length) {
      this.isRecordedUpdateManager.requestUpdate(eventsToUpdate);
    }
  }

  public initializeCache(): void {
    this.epgCache = new EPGCache();
    this.addListeners();
  }

  public uninitializeCache(): void {
    this.removeListeners();
    this.epgCache.clear();
  }

  public getEPG(params: EPGParams): Promise<EPGResponse> {
    return this.epgCache.getEPG(params)
      .then(epgResponse => {
        if (mw.configuration.isNPVREnabled && params.includeIsRecorded) {
          this.updateEventsIsRecorded(epgResponse);
        }
        return epgResponse;
      });
  }

  public blockIdleActions(): void {
    this.epgCache.blockIdleActions();
  }

  public unblockIdleActions(): Promise<void> {
    return this.epgCache.unblockIdleActions();
  }

  public refreshEPG(): Promise<void> {
    return this.epgCache.refreshEPG();
  }

  public getEvent(channel: Channel, date: Date): Promise<Event> {
    return this.getEPG({
      channels: [channel],
      startTime: date,
      endTime: DateUtils.addMinutes(date, getEventTimespanInMin)
    })
      .then(channelsMap => (channelsMap.get(channel.id) || []))
      .then(events => {
        const event = events.find(event => event.start < date && event.end > date);
        return event || Promise.reject(new Error(ErrorType.BOBadResponse));
      });
  }
}
