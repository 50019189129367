import {Identity} from '../../SSOInterface';

abstract class TraxisIdentity implements Identity {
  public readonly id: string;

  public constructor(id: string) {
    this.id = id;
  }

  abstract get(): any;
}

export class CustomerIdentity extends TraxisIdentity {

  private readonly identity: {CustomerId: string};

  public constructor(id: string) {
    super(id);
    this.identity = {CustomerId: this.id};
  }

  public get(): any {
    return this.identity;
  }
}

export class DeviceIdentity extends TraxisIdentity {

  private readonly identity: {CpeId: string};

  public constructor(id: string) {
    super(id);
    this.identity = {CpeId: this.id};
  }

  public get(): any {
    return this.identity;
  }
}
