import {createStyles} from 'common-styles';
import React, {forwardRef, memo, useCallback, useEffect, useMemo, useImperativeHandle} from 'react';
import {Image, ImageURISource, View} from 'react-native';

import {dimensions, RADIUS} from 'common/constants';
import {humanCaseToSnakeCase} from 'common/HelperFunctions';
import {TestProps} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {NativeApplication} from 'mw/api/System';

import {AnimatedTileInterface} from 'components/mediaTiles/MediaTile';
import NitroxText from 'components/NitroxText';
import TileContainer from 'components/TileContainer';
import {useStaticallyFocused} from 'hooks/Hooks';

const borderRadius = RADIUS * 2;
const imageContainerBorderRadius = RADIUS * 3;
const margin = dimensions.applicationTile.borderWidth;
export const tileWidth = dimensions.applicationTile.width + dimensions.applicationTile.borderWidth * 2;
export const applicationTileHeight = dimensions.applicationTile.height + dimensions.applicationTile.borderWidth * 2;
export const gameTileHeight = applicationTileHeight + dimensions.applicationTile.labelHeight;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    marginTop: margin,
    marginBottom: margin,
    marginLeft: margin,
    marginRight: margin,
    width: dimensions.applicationTile.width
  },
  focusedApplicationContainer: {
    backgroundColor: colors.tile.background.selected,
    borderRadius: imageContainerBorderRadius,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    padding: dimensions.applicationTile.borderWidth,
    width: tileWidth
  },
  focusedGameContainer: {
    borderColor: colors.tile.background.selected,
    borderRadius: borderRadius
  },
  imageContainer: {
    width: dimensions.applicationTile.width,
    height: dimensions.applicationTile.height,
    borderRadius: borderRadius,
    overflow: 'hidden'
  },
  focusedGameImageContainer: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  image: {
    backgroundColor: colors.tile.poster.placeholder.background,
    width: dimensions.applicationTile.width,
    height: dimensions.applicationTile.height
  },
  tileLabelContainer: {
    height: dimensions.applicationTile.labelHeight,
    justifyContent: 'center'
  },
  focusedTileLabelContainer: {
    backgroundColor: colors.tile.background.selected,
    borderBottomLeftRadius: borderRadius,
    borderBottomRightRadius: borderRadius,
    paddingHorizontal: dimensions.margins.small
  },
  tileLabel: {
    color: colors.tile.contentText.unfocused
  },
  focusedTileLabel: {
    color: colors.tile.contentText.focused
  }
}));

type ApplicationTileProps = {
  application: NativeApplication;
  onPress: (application: NativeApplication) => void;
  onMount?: () => void;
  focusable?: boolean;
  staticallyFocused?: boolean;
} & TestProps;

const ApplicationTile: React.FC<ApplicationTileProps> = (props: ApplicationTileProps, ref) => {
  const {application, onPress: propsOnPress, onMount, focusable: propsFocusable = true, staticallyFocused} = props;
  const encodedImage = application.banner ?? application.icon;
  const height = application.isGame ? gameTileHeight : applicationTileHeight;

  const {onFocusStateChanged, focusable, focused} = useStaticallyFocused(propsFocusable, staticallyFocused);

  const styles = stylesUpdater.getStyles();
  const focusedContainerStyle = application.isGame ? styles.focusedGameContainer : styles.focusedApplicationContainer;
  const imageSource: ImageURISource = useMemo(() => ({uri: 'data:image/png;base64,' + encodedImage}), [encodedImage]);
  const onPress = useCallback(() => propsOnPress(application), [propsOnPress, application]);

  useEffect(() => onMount?.(), [onMount]);

  const testID = 'tile_' + humanCaseToSnakeCase(application.label);

  /* eslint-disable schange-rules/no-use-imperative-handle-hook */
  useImperativeHandle(ref, () => {
    const handlers: AnimatedTileInterface = {
      onPress: () => {
        if (!focusable && focused) {
          onPress();
        }
      }
    };
    return handlers;
  }, [onPress, focusable, focused]);
  /* eslint-enable schange-rules/no-use-imperative-handle-hook */

  return (
    <TileContainer
      ref={ref}
      style={[styles.container, {height}, focused && focusedContainerStyle]}
      focusable={focusable}
      onPress={onPress}
      onFocusStateChanged={onFocusStateChanged}
      testID={testID}
    >
      <View style={[styles.imageContainer, focused && application.isGame && styles.focusedGameImageContainer]}>
        <Image
          source={imageSource}
          width={dimensions.applicationTile.width}
          height={dimensions.applicationTile.height}
          style={styles.image}
          resizeMode='center'
        />
      </View>
      {application.isGame && (
        <View style={[styles.tileLabelContainer, focused && styles.focusedTileLabelContainer]}>
          <NitroxText textType='tile-title' numberOfLines={1} style={focused ? styles.focusedTileLabel : styles.tileLabel}>{application.label}</NitroxText>
        </View>
      )}
    </TileContainer>
  );
};

export default memo(forwardRef(ApplicationTile));
