import {createStyles} from 'common-styles';
import moment from 'moment';
import React, {forwardRef, useCallback, useContext, useMemo} from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleSheet, View, ViewStyle} from 'react-native';
import {NavigationContext} from 'react-navigation';

import {dimensions, RADIUS, isDesktopBrowser} from 'common/constants';
import {openMediaDetails, getMediaSubtypes, humanCaseToSnakeCase} from 'common/HelperFunctions';
import {FocusOptions} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Event, isRecording, RecordingStatus, PictureType, PictureMode} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import {AspectRatio, Ratio} from 'components/AspectRatio';
import {ChannelIconType, ChannelIcon} from 'components/ChannelIcon';
import Checkbox from 'components/Checkbox';
import EventSelector from 'components/EventSelector';
import {Icon, IconType} from 'components/Icon';
import MediaPicture from 'components/MediaPicture';
import {useMediaTileHelpers, MediaTileBaseProps, MediaTileType, getMediaTitleForType, useProgress, useMediaInfoSection} from 'components/mediaTiles/MediaTileHooks';
import MiniProgressBar from 'components/MiniProgressBar';
import NitroxTag, {TagPosition, Tags, TagTypes} from 'components/NitroxTag';
import NitroxText from 'components/NitroxText';
import Separator from 'components/Separator';
import TileContainer from 'components/TileContainer';
import TileIconsRow, {prepareMediaIcons, MediaIcons} from 'components/TileIconsRow';
import {useChangeEffect, useEffectOnce, useTestID} from 'hooks/Hooks';

const {borderRadius, borderPadding, borderWidth, width, height} = dimensions.tile;

const posterPadding = borderWidth + borderPadding;

const posterRatio = Ratio.RATIO_16_9;
const posterSize = {width: width - (posterPadding * 2), height: 0};
posterSize.height = Math.floor(posterSize.width / posterRatio);

export const mediaTileMarginTop = posterPadding;
export const mediaTileMarginHorizontal = posterPadding;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  text: {
    color: colors.tile.contentText.unfocused
  },
  imageContainer: {
    backgroundColor: colors.tile.poster.placeholder.background,
    width: posterSize.width,
    height: posterSize.height,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    overflow: 'hidden',
    borderRadius: borderRadius
  },
  separator: {
    backgroundColor: colors.tile.separator.unfocused
  },
  progressBackgroundStyle: {
    backgroundColor: colors.progressBar.default.progress.available
  },
  contentFocused: {
    borderColor: colors.tile.background.selected
  },
  contentUnfocused: {
    borderColor: colors.tile.background.default
  },
  icon: {
    color: colors.tile.channelIcon.background
  },
  profileTagBackground: {
    backgroundColor: colors.defaultColors.screenBackground
  },
  noPosterIcon: {
    ...StyleSheet.absoluteFillObject,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.tile.poster.placeholder.background
  }
}));

const styles = createStyles({
  focusFrame: {
    width: posterSize.width + (posterPadding * 2),
    height: posterSize.height + (posterPadding * 2),
    borderWidth: borderWidth,
    borderRadius: 30,
    padding: borderPadding
  },
  content: {
    width,
    height,
    marginTop: 0,
    marginHorizontal: 0
  },
  descriptionView: {
    width: posterSize.width,
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    marginHorizontal: posterPadding
  },
  descriptionViewDefault: {
    alignItems: 'center'
  },
  descriptionViewEpisode: {
    alignItems: 'flex-start'
  },
  separator: {
    opacity: dimensions.opacity.xlow,
    marginVertical: dimensions.margins.xsmall,
    marginHorizontal: dimensions.margins.small
  },
  dateView: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  channelIcon: {
    position: 'absolute',
    left: posterPadding,
    top: posterPadding,
    width: dimensions.tile.channelIcon.width,
    height: dimensions.tile.channelIcon.height,
    borderRadius: dimensions.tile.channelIcon.borderRadius
  },
  mediaIconsContainer: {
    width: dimensions.icon.small,
    marginLeft: dimensions.margins.medium,
    marginRight: dimensions.margins.small,
    justifyContent: 'center'
  },
  iconContainer: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center'
  },
  checkboxContainer: {
    position: 'absolute',
    top: dimensions.margins.large,
    right: dimensions.margins.large
  },
  descriptionContent: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'stretch'
  },
  descriptionTexts: {
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1
  },
  descriptionContainer: {
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  centeredContainer: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center'
  },
  posterTagPosition: {
    height: dimensions.tile.channelIcon.height,
    position: 'absolute',
    top: posterPadding,
    left: posterPadding + dimensions.tile.channelIcon.width,
    flexDirection: 'row',
    alignItems: 'center'
  },
  progressBar: {
    height: dimensions.tile.progress.height,
    width: dimensions.tile.progress.width,
    marginTop: dimensions.margins.xsmall
  },
  liveTag: {
    borderRadius: 20
  },
  profileTagPosition: {
    position: 'absolute',
    bottom: borderPadding,
    right: borderPadding,
    borderRadius: RADIUS,
    borderBottomRightRadius: borderRadius,
    paddingHorizontal: dimensions.margins.small
  }
});

export function useMediaTileFocusedStyle(): ViewStyle {
  const {contentFocused} = stylesUpdater.getStyles();
  return useMemo(() => ({
    ...styles.focusFrame,
    ...contentFocused
  }), [contentFocused]);
}

const MediaTileBase: React.FunctionComponent<MediaTileBaseProps> = (props, ref) => {
  const {
    media,
    config,
    onPress: propagatePress,
    onFocus: propagateFocus,
    scrollOnFocus = true,
    tileType = MediaTileType.Default,
    selected = false,
    checkboxChecked,
    checkboxVisible,
    showIcons = true,
    excludeIcons,
    onCheckboxToggle,
    additionalIcons,
    renderMediaPoster,
    focusable: propsFocusable = true,
    tag,
    onMount,
    secondRowItemsRenderer: profileTagRenderer,
    staticallyFocused
  } = props;
  const {t} = useTranslation();
  const progress = useProgress(media);
  const [eventsForSelection, setEventsForSelection] = useState<Event[]>([]);
  const [mediaIcons, setMediaIcons] = useState<MediaIcons>({});
  const navigation = useContext(NavigationContext);

  const poster = useMemo(() => {
    const uri = mw.catalog.getPictureUrl(config?.posterMedia ?? media, PictureType.Tile, dimensions.pictures.standardTile.default_16_9.width, dimensions.pictures.standardTile.default_16_9.height, PictureMode.BOX);
    return uri ? {uri} : null;
  }, [config, media]);
  const shouldRenderFailedRecordingIcon = useMemo(() => isRecording(media) && media.status === RecordingStatus.Failed, [media]);

  const {event, title} = getMediaSubtypes(media);

  const {onPress, isBlocked, onFocusStateChanged, focusable, focused} = useMediaTileHelpers({checkboxVisible, checkboxChecked, onCheckboxToggle, onPress: propagatePress, media, setEventsForSelection, config, focusable: propsFocusable, staticallyFocused, ref});

  useEffect(() => {
    const mediaIcons = prepareMediaIcons(media, {excludeIcons});
    const mediaIconsArray = mediaIcons?.icons || [];
    const additionalIconsArray = additionalIcons || [];
    const icons = [...mediaIconsArray, ...additionalIconsArray];
    setMediaIcons(showIcons ? {icons, tags: mediaIcons.tags} : {});
  }, [showIcons, excludeIcons, media, media.isOnWatchList, additionalIcons]);

  const onFocus = useCallback((event: any, options?: FocusOptions) => {
    propagateFocus?.(media, options);
  }, [propagateFocus, media]);

  const dynamicStyles = stylesUpdater.getStyles();

  const focusedStyle = useMediaTileFocusedStyle();

  const focusFrameStyle = useMemo(() => {
    return focused && (!staticallyFocused || isDesktopBrowser)
      ? focusedStyle
      : {...styles.focusFrame, ...dynamicStyles.contentUnfocused};
  }, [dynamicStyles.contentUnfocused, focused, focusedStyle, staticallyFocused]);

  const infoSection = useMediaInfoSection(media, t, tileType);
  const titleStyle = useMemo(() => [dynamicStyles.text, !infoSection && {marginBottom: 6}], [dynamicStyles.text, infoSection]);

  const descriptionViewStyle = useMemo(() => {
    return [
      styles.descriptionView,
      tileType === MediaTileType.Episode
        ? styles.descriptionViewEpisode
        : styles.descriptionViewDefault
    ];
  }, [tileType]);

  const testIdSuffix = (config?.title ?? getMediaTitleForType(media, tileType)) || props.media.name;
  const testID = useTestID(props, 'MediaTile') || `tile_${humanCaseToSnakeCase(testIdSuffix)}`;

  const onEventSelectionClose = useCallback(() => setEventsForSelection([]), []);
  const onEventSelected = useCallback((event: Event) => openMediaDetails(navigation, event.id, event.getType()), [navigation]);

  useEffectOnce(() => onMount?.(), [onMount]);

  const [shouldRenderLiveTag, setShouldRenderLiveTag] = useState<boolean>(!!event?.isNow);
  useChangeEffect(() => {
    setShouldRenderLiveTag(!!event?.isNow);
  }, [event, progress]);

  const profileTag = useMemo(() => {
    return profileTagRenderer
      ? (
        <View style={[dynamicStyles.profileTagBackground, styles.profileTagPosition]}>
          {profileTagRenderer()}
        </View>
      )
      : null;
  }, [profileTagRenderer, dynamicStyles.profileTagBackground]);

  const infoSectionComponent = useMemo(() => {
    if (!infoSection && !config?.infoSection) {
      return null;
    }
    return (
      <NitroxText textType='callout-small-extra-condensed' style={{...dynamicStyles.text, opacity: dimensions.opacity.xxhigh}} numberOfLines={1} ellipsizeMode='tail'>
        {config?.infoSection ?? infoSection}
      </NitroxText>
    );
  }, [config?.infoSection, dynamicStyles.text, infoSection]);

  const posterImage = useMemo(() => {
    return renderMediaPoster
      ? <View style={styles.centeredContainer}>{renderMediaPoster()}</View>
      : (
        <MediaPicture
          source={poster}
          mediaType={(config?.posterMedia ?? media).getType()}
          isBlocked={isBlocked}
          customPlaceholderIcon={config?.customPlaceholderIcon}
        />
      );
  }, [config?.customPlaceholderIcon, config?.posterMedia, isBlocked, media, poster, renderMediaPoster]);

  return (
    <>
      <TileContainer
        ref={ref}
        onFocus={onFocus}
        scrollOnFocus={scrollOnFocus}
        focusable={focusable}
        onPress={onPress}
        onFocusStateChanged={onFocusStateChanged}
        style={styles.content}
        testID={testID}
      >
        <View style={focusFrameStyle}>
          <AspectRatio ratio={posterRatio}>
            <View style={dynamicStyles.imageContainer}>
              {shouldRenderFailedRecordingIcon ? (
                <View style={dynamicStyles.noPosterIcon}>
                  <Icon type={IconType.FailedRecording} size={dimensions.icon.large} />
                </View>
              ) : (
                posterImage
              )}
              {poster && title && title.isTrailer && (
                <View style={styles.iconContainer}>
                  <Icon type={IconType.Trailer} size={dimensions.tile.iconHeight} color={dynamicStyles.icon.color} shadow />
                </View>
              )}
              {props.checkboxVisible && (
                <View style={styles.checkboxContainer}>
                  <Checkbox selected={props.checkboxChecked} size={dimensions.tile.checkboxSize} />
                </View>
              )}
            </View>
          </AspectRatio>
          {tag && tag.type !== TagTypes.LIVE && (
            <View style={styles.posterTagPosition}>
              <NitroxTag tag={tag} position={TagPosition.Poster} />
            </View>
          )}
          {event && (<ChannelIcon style={styles.channelIcon} type={ChannelIconType.Static} channelId={event.channelId} />)}
          {profileTag}
        </View>
        <View style={descriptionViewStyle}>
          {event && tileType === MediaTileType.Default && (
            <View style={styles.dateView}>
              <NitroxText textType='tile-subhead' style={dynamicStyles.text}>
                {moment(event.start).format(mw.configuration.timeFormat)}
              </NitroxText>
            </View>
          )}
          <View style={styles.descriptionContent}>
            {event && tileType === MediaTileType.Default && <Separator style={[styles.separator, dynamicStyles.separator]} />}
            <View style={styles.descriptionContainer}>
              <View style={styles.descriptionTexts}>
                <NitroxText
                  textType='tile-title'
                  style={titleStyle}
                  numberOfLines={1}
                  ellipsizeMode='tail'
                  testID='title'
                >
                  {config?.title ?? getMediaTitleForType(media, tileType)}
                </NitroxText>
                {infoSectionComponent}
                {!config?.hideProgress && progress > 0 && (
                  <MiniProgressBar
                    style={styles.progressBar}
                    progress={progress}
                    backgroundColor={dynamicStyles.progressBackgroundStyle.backgroundColor}
                  />
                )}
              </View>
            </View>
          </View>
          {(mediaIcons.icons?.length || mediaIcons.tags?.length) && (
            <View style={styles.mediaIconsContainer}>
              <TileIconsRow mediaIcons={mediaIcons} />
            </View>
          )}
          {shouldRenderLiveTag && (
            <NitroxTag tag={Tags.liveTag} position={TagPosition.Title} style={styles.liveTag} />
          )}
        </View>
      </TileContainer>
      {!!eventsForSelection.length && (
        <EventSelector
          events={eventsForSelection}
          onClose={onEventSelectionClose}
          onSelect={onEventSelected}
        />
      )}
    </>
  );
};

export default React.memo(forwardRef(MediaTileBase));
