import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {Animated, StyleProp, ViewStyle, View} from 'react-native';

import {dimensions} from 'common/constants';
import {AnimatedStyle} from 'common/HelperTypes';

import {StylesUpdater, useStyles} from 'common-styles/StylesUpdater';

const styles = new StylesUpdater(colors => createStyles({
  activeSegment: {
    backgroundColor: colors.vodScreen.promotionalBanner.progressIndicator.active
  },
  inactiveSegment: {
    backgroundColor: colors.vodScreen.promotionalBanner.progressIndicator.inactive
  }
}));

const staticStyles = createStyles({
  progressSegment: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginHorizontal: dimensions.margins.medium,
    overflow: 'hidden'
  }
});

type ProgressIndicatorProps = {
  numberOfPages: number;
  currentPage: number;
  progress: Animated.Value;
  style: StyleProp<ViewStyle>;
  segmentStyle: StyleProp<ViewStyle> & {width: number};
  activeSegmentScale?: number;
}

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
  numberOfPages,
  currentPage,
  progress,
  style,
  segmentStyle,
  activeSegmentScale = 4
}) => {
  const {activeSegment, inactiveSegment} = useStyles(styles);
  const activeSegmentAnimatedStyle: AnimatedStyle<ViewStyle> = useMemo(() => ({
    flex: 1,
    transform: [
      {
        translateX: progress.interpolate({
          inputRange: [0, 1],
          outputRange: [-activeSegmentScale * segmentStyle.width, 0]
        })
      }
    ]
  }), [progress, activeSegmentScale, segmentStyle.width]);
  return (
    <View style={style}>
      {Array.from(Array(numberOfPages).keys()).map((_, index) => (
        <View
          key={index}
          style={[
            staticStyles.progressSegment,
            inactiveSegment,
            segmentStyle,
            // currentPage segment is {activeSegmentScale} times wider than inactive segments
            index === currentPage ? {width: activeSegmentScale * segmentStyle.width} : {}
          ]}
        >
          {index === currentPage && (
            <Animated.View
              style={[
                activeSegment,
                activeSegmentAnimatedStyle
              ]}
            />
          )}
        </View>
      ))}
    </View>
  );
};

export default ProgressIndicator;
