import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View, ViewStyle, StyleProp} from 'react-native';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {IconType, IconProps} from 'components/Icon';
import IconRound from 'components/IconRound';
import NitroxText from 'components/NitroxText';
import ProfileSelect from 'components/ProfileSelect';
import ProfileSwitchButton from 'components/ProfileSwitchButton';
import {useToggle, useDisposableCallback, useCurrentProfile, useProfileProperties} from 'hooks/Hooks';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    alignItems: 'center',
    alignContent: 'space-around',
    justifyContent: 'flex-end'
  },
  profileNameContainer: {
    color: colors.settingsScreen.mobile.profile.name,
    margin: dimensions.margins.medium
  },
  actions: {
    flexDirection: 'row'
  }
}));

type ProfileSwitcherProps = {
  style?: StyleProp<ViewStyle>;
  profileName?: string;
  iconProps?: IconProps;
  actions?: React.ReactNode;
  actionsStyle?: StyleProp<ViewStyle>;
  onLoading?: (param: boolean) => void;
};

const ProfileSwitcher: React.FunctionComponent<ProfileSwitcherProps> = props => {
  const {t} = useTranslation();
  const currentProfileProperties = useProfileProperties();
  const profile = useCurrentProfile();
  const [profilePopupVisible, {on: showProfilePopup, off: hideProfilePopup}] = useToggle(false);
  const onProfileSelectClose = useDisposableCallback(hideProfilePopup);

  const currentProfileName = currentProfileProperties ? currentProfileProperties.name : '';
  const hasProfileName = typeof props.profileName === 'string';
  const profileName = hasProfileName ? props.profileName : currentProfileName;
  const shouldDisplayMasterLabel = !hasProfileName && profile?.isMain;
  const styles = stylesUpdater.getStyles();

  return (
    <View style={[styles.container, props.style]}>
      <ProfileSelect visible={profilePopupVisible} onClose={onProfileSelectClose} onLoading={props.onLoading} />
      <IconRound
        type={props.iconProps?.type ?? IconType.UserAvatar}
        size={props.iconProps?.size}
        color={props.iconProps?.color}
      />
      <NitroxText style={styles.profileNameContainer} textType='title1'>
        {profileName}
        {shouldDisplayMasterLabel && t('common.masterProfileSuffix')}
      </NitroxText>
      <View style={useMemo(() => [styles.actions, props.actionsStyle], [props.actionsStyle, styles.actions])}>
        {props.actions}
        <ProfileSwitchButton onPress={showProfilePopup} />
      </View>
    </View>
  );
};
export default ProfileSwitcher;
