import {createStyles} from 'common-styles';
import React, {forwardRef, useMemo, useImperativeHandle} from 'react';
import {View, ViewStyle, StyleProp} from 'react-native';

import {dimensions, isDesktopBrowser} from 'common/constants';
import {doNothing} from 'common/HelperFunctions';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {ComponentSettings} from 'mw/api/CMSInterface';
import {PictureType, PictureMode} from 'mw/api/Metadata';
import {Menu} from 'mw/cms/Menu';
import {mw} from 'mw/MW';

import {Ratio} from 'components/AspectRatio';
import MediaPicture from 'components/MediaPicture';
import NitroxText from 'components/NitroxText';
import TileContainer from 'components/TileContainer';

import {AnimatedTileInterface} from './mediaTiles/MediaTile';

const rectanglePosterRatio = Ratio.RATIO_16_11;
const roundPosterRatio = Ratio.RATIO_1_1;

const styles = createStyles({
  imageContainer: {
    overflow: 'hidden'
  },
  descriptionContainer: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    marginTop: dimensions.margins.large
  }
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  borderColor: colors.tile.background.selected,
  imageContainer: {
    backgroundColor: colors.tile.poster.placeholder.background
  },
  text: {
    color: colors.tile.contentText.unfocused
  }
}));

export type MenuTileProps = {
  menu: Menu;
  tileShape: ComponentSettings['tileShape'];
  focused: boolean;
  onPress?: (menu: Menu) => void;
};

export function useMenuTileStyling(tileShape: MenuTileProps['tileShape'], focused: boolean): {
  posterSize: {width: number; height: number;};
  contentStyle: ViewStyle;
  focusFrameStyle: ViewStyle;
  imageContainerStyle: ViewStyle;
  descriptionStyle: ViewStyle;
} {
  const dynamicStyles = stylesUpdater.getStyles();

  const posterRatio = tileShape === 'circle'
    ? roundPosterRatio
    : rectanglePosterRatio;

  const width = tileShape === 'rectangle'
    ? dimensions.menuTile.rectangleTileWidth
    : dimensions.menuTile.circleTileWidth;

  const height = dimensions.menuTile.tileHeight;

  const radius = useMemo(() => {
    return tileShape === 'circle'
      ? 9999
      : dimensions.menuTile.rectangleTileBorderRadius;
  }, [tileShape]);

  const posterSize = useMemo(() => ({
    width,
    height: Math.floor(width / posterRatio)
  }), [posterRatio, width]);

  const contentStyle: StyleProp<ViewStyle> = useMemo(() => ({
    marginTop: 0,
    height: height,
    width: width + 2 * (dimensions.menuTile.borderPadding + dimensions.menuTile.borderWidth),
    alignItems: 'center'
  }), [height, width]);

  const focusFrameStyle: ViewStyle = useMemo(() => {
    const padding = dimensions.menuTile.borderPadding + dimensions.menuTile.borderWidth;
    return {
      width: posterSize.width + 2 * padding,
      height: posterSize.height + 2 * padding,
      borderWidth: dimensions.menuTile.borderWidth,
      borderColor: focused ? dynamicStyles.borderColor : 'transparent',
      borderRadius: radius,
      padding,
      alignItems: 'center',
      justifyContent: 'center'
    };
  }, [dynamicStyles.borderColor, focused, posterSize.height, posterSize.width, radius]);

  const imageContainerStyle: StyleProp<ViewStyle> = useMemo(() => ({
    ...posterSize,
    borderRadius: radius,
    ...styles.imageContainer,
    backgroundColor: dynamicStyles.imageContainer.backgroundColor
  }), [dynamicStyles.imageContainer.backgroundColor, posterSize, radius]);

  const descriptionStyle: StyleProp<ViewStyle> = useMemo(() => ({
    ...styles.descriptionContainer,
    justifyContent: tileShape === 'circle' ? 'center' : 'flex-start'
  }), [tileShape]);

  return {posterSize, contentStyle, focusFrameStyle, imageContainerStyle, descriptionStyle};
}

const MenuTile: React.FC<MenuTileProps> = (props, ref) => {
  const {
    menu,
    tileShape,
    focused,
    onPress
  } = props;
  const dynamicStyles = stylesUpdater.getStyles();

  const {posterSize, contentStyle, focusFrameStyle, imageContainerStyle, descriptionStyle} = useMenuTileStyling(tileShape, focused && isDesktopBrowser);

  /* eslint-disable schange-rules/no-use-imperative-handle-hook */
  useImperativeHandle(ref, () => {
    const handlers: AnimatedTileInterface = {
      onPress: () => {
        onPress?.(menu);
      }
    };
    return handlers;
  }, [onPress, menu]);
  /* eslint-enable schange-rules/no-use-imperative-handle-hook */

  const source = useMemo(() => ({
    uri: mw.catalog.getPictureUrl(menu, PictureType.Logo, posterSize.width, posterSize.height, PictureMode.BOX)
  }), [menu, posterSize.height, posterSize.width]);

  const pressHandler = useMemo(() => {
    return isDesktopBrowser
      ? () => onPress?.(menu)
      : doNothing;
  }, [onPress, menu]);

  return (
    <TileContainer
      ref={doNothing}
      onPress={pressHandler}
      style={contentStyle}
      focusable
    >
      <View style={focusFrameStyle}>
        <View style={imageContainerStyle}>
          <MediaPicture source={source} />
        </View>
      </View>
      <View style={descriptionStyle}>
        <NitroxText
          textType='eas-alert-callout'
          style={dynamicStyles.text}
          numberOfLines={1}
          ellipsizeMode='tail'
          testID='title'
        >
          {menu.title}
        </NitroxText>
      </View>
    </TileContainer>
  );
};

export default React.memo(forwardRef(MenuTile));
