import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions} from 'common/constants';
import {getMediaCredits} from 'common/HelperFunctions';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Media, MediaType, Series, Title} from 'mw/api/Metadata';

import TabView, {Tab, TabElement} from 'components/tabs/TabView';

import CastSection from './CastSection';
import {mediaDetailHorizontalMargin} from './MediaDetailUtils';
import MobileEpisodesSection from './MobileEpisodesSection';
import RelatedRecommendationsView from './RelatedRecommendationsView';
import TrailersSection from './TrailersSection';

type Props = {
  media: Media;
  trailers: Title[];
  castAndMoreTitle: Title | null;
  selectedSeason?: Series;
  episodes?: Media[];
  seasons?: Series[];
  onSeasonSelected?: (season: Series) => void;
  onStartPlayback: (media: Media) => void;
  recommendations?: Media[] | null;
}

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    backgroundColor: colors.mediaDetailsScreen.content.background,
    paddingTop: 15
  },
  relatedRecommendations: {
    height: dimensions.tile.height + 2 * dimensions.margins.small
  },
  tabs: {
    marginHorizontal: mediaDetailHorizontalMargin
  }
}));

const MobileMediaDetailTabs: React.FunctionComponent<Props> = props => {
  const {media, castAndMoreTitle, trailers, selectedSeason, episodes: propsEpisodes, onSeasonSelected, recommendations} = props;
  const {t} = useTranslation();
  const tabs: TabElement[] = [];
  const styles = stylesUpdater.getStyles();
  const episodes = useMemo(() => propsEpisodes || [], [propsEpisodes]);

  if (recommendations && (media.getType() === MediaType.Title || media.getType() === MediaType.Event)) {
    tabs.push(
      <Tab key={0} title={t('mediaDetails.related')}>
        <RelatedRecommendationsView
          style={styles.relatedRecommendations}
          recommendations={recommendations}
        />
      </Tab>
    );
  } else if (media.getType() === MediaType.Series) {
    const series = media as Series;

    tabs.push(
      <Tab key={0} title={t('mediaDetails.episodes')}>
        <MobileEpisodesSection seasons={props.seasons || series.seasons} selectedSeason={selectedSeason} episodes={episodes} onSeasonSelected={onSeasonSelected} />
      </Tab>
    );
  }

  if (castAndMoreTitle || trailers.length) {
    const shouldDisplayTrailersSection = trailers.length > 0;
    const credits = getMediaCredits(castAndMoreTitle);
    const shouldDisplayCastSection = credits.actors.length > 0 || credits.directors.length > 0;

    if (shouldDisplayTrailersSection || shouldDisplayCastSection) {
      tabs.push(
        <Tab key={1} title={t('mediaDetails.castAndMore')}>
          {shouldDisplayTrailersSection && (
            <TrailersSection items={trailers} onStartPlayback={props.onStartPlayback} />
          )}
          {shouldDisplayCastSection && <CastSection {...credits} />}
        </Tab>
      );
    }
  }

  return (
    <View style={styles.container} testID='media_details_tabs'>
      <TabView tabBarStyle={styles.tabs} activeUnderscore>
        {tabs}
      </TabView>
    </View>
  );
};

export default MobileMediaDetailTabs;
