import {createStyles} from 'common-styles';
import i18next from 'i18next';
import React, {forwardRef, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions, isBigScreen} from 'common/constants';
import {DateUtils} from 'common/DateUtils';
import {getMediaTitle, getRatingToDisplay, isTruthy} from 'common/HelperFunctions';
import {getEpisodeAndSeasonNumber, getEpisodeTitle, getFormattedDuration, GetEpisodeAndSeasonNumberOptions} from 'common/utils';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {isSeriesRecording, MediaType, Recording, RecordingType} from 'mw/api/Metadata';
import {Profile} from 'mw/api/Profile';
import {mw} from 'mw/MW';

import {Icon, IconType} from 'components/Icon';
import MediaTileBase from 'components/mediaTiles/MediaTileBase';
import {MediaTileConfig} from 'components/mediaTiles/MediaTileHooks';
import MobileMediaTileWide from 'components/mediaTiles/MobileMediaTileWide';
import NitroxTag, {TagTypes, Tags} from 'components/NitroxTag';
import NitroxText from 'components/NitroxText';
import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';
import TileIconsRow, {MediaIcons, prepareRecordingIcons} from 'components/TileIconsRow';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  textStyle: {
    color: colors.columnTile.subtitle,
    textAlign: 'left',
    marginLeft: dimensions.margins.xsmall
  },
  firstRowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  secondRowContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  seriesIconContainer: {
    marginRight: dimensions.margins.small
  },
  seriesIconColor: colors.columnTile.seriesIcon,
  tagContainer: {
    marginRight: dimensions.margins.xsmall,
    marginBottom: dimensions.margins.xsmall
  },
  userAvatarIconColor: colors.columnTile.userIcon
}));

type RecordingTile = {
  recording: Recording;
  isFolderTile?: boolean;
  selectable?: boolean;
  selected?: boolean;
  onPress: (recording: Recording) => void;
  onSelectionCheckboxPress?: (recording: Recording, selected: boolean) => void;
  onFocus?: () => void;
};

const episodeAndSeasonNumberOptions: GetEpisodeAndSeasonNumberOptions = {
  padLength: 2,
  separator: '',
  showFullText: false
};

export const getRecordingTileConfig = (recording: Recording, t: i18next.TFunction, isFolderTile?: boolean): MediaTileConfig => {
  const subtitleList = [];
  if (isSeriesRecording(recording)) {
    recording.series.seasonNumber && subtitleList.push(t('common.seasonNumberFull', {seasonNumber: recording.series.seasonNumber}));
    recording.seasonsCount && subtitleList.push(t('common.seasonsAmount', {count: recording.seasonsCount}));
    recording.episodesCount && subtitleList.push(t('common.episodesAmount', {count: recording.episodesCount}));

    return {
      infoSection: subtitleList.filter(isTruthy).join(', '),
      title: getMediaTitle(recording),
      customPlaceholderIcon: IconType.PlaceholderTV,
      hideProgress: true
    };
  }

  const rating = getRatingToDisplay(recording);
  if (rating) {
    subtitleList.push(rating);
  }

  if (recording.getType() === MediaType.Recording || recording.getType() === MediaType.Title) {
    subtitleList.push(getFormattedDuration(recording));
  }

  subtitleList.push(getEpisodeAndSeasonNumber(recording, episodeAndSeasonNumberOptions));
  !isFolderTile && subtitleList.push(getEpisodeTitle(recording));

  return {
    infoSection: subtitleList.filter(isTruthy).join(', '),
    title: (isFolderTile && getEpisodeTitle(recording)) || getMediaTitle(recording),
    hideProgress: true
  };
};

const RecordingTile: React.FC<RecordingTile> = (props, ref) => {
  const {recording, onPress, selectable, selected, onSelectionCheckboxPress, onFocus, isFolderTile} = props;
  const {t} = useTranslation();
  const {isMediaBlocked, shouldBeCheckedForPC} = useParentalControl();
  const isBlocked = shouldBeCheckedForPC(recording.event) && isMediaBlocked(recording.event);

  const [recordingIcons, setRecordingIcons] = useState<MediaIcons>({});

  const onTilePress = useCallback(() => {
    onPress(recording);
  }, [recording, onPress]);

  const selectionCheckboxPressHandler = useCallback((selected: boolean) => {
    onSelectionCheckboxPress && onSelectionCheckboxPress(recording, selected);
  }, [recording, onSelectionCheckboxPress]);

  const profile = useMemo(() => mw.customer.profiles.find((profile: Profile) => profile.id === recording.profileId), [recording]);

  const channelId = recording.channelId;
  const dateTimeTag = useMemo(() => recording?.event?.start && ({
    type: TagTypes.DATE_TIME,
    customText: `${DateUtils.formatDate(recording.event.start, mw.configuration.timeFormat)}, ${DateUtils.formatDate(recording.event.start, mw.configuration.dateFormat)}`
  }), [recording]);

  const firstRowRenderer = useCallback(() => {
    const styles = stylesUpdater.getStyles();
    return (
      <View style={styles.firstRowContainer}>
        {recording.recordingType === RecordingType.Series && (
          <View style={styles.seriesIconContainer}>
            <Icon type={IconType.Series} size={15} color={styles.seriesIconColor} />
          </View>
        )}
        {recording.event?.isNow && <NitroxTag tag={Tags.liveTag} style={styles.tagContainer} />}
        {dateTimeTag && <NitroxTag tag={dateTimeTag} style={styles.tagContainer} />}
        {
          //TODO CL-4480 Define condition that determines showing NEW RECORDING tile, remember that big screen tile tags are set in deferent place in the code
        }
        {false && <NitroxTag tag={Tags.newRecordingTag} style={styles.tagContainer} />}
      </View>
    );
  }, [dateTimeTag, recording.event, recording.recordingType]);

  const secondRowRenderer = useCallback(() => {
    if (!profile) {
      return null;
    }
    const styles = stylesUpdater.getStyles();
    return (
      <View style={styles.secondRowContainer}>
        <Icon type={IconType.UserAvatar} size={15} color={styles.userAvatarIconColor} />
        <NitroxText textType={'callout-small'} style={styles.textStyle}>{profile.name}</NitroxText>
      </View>
    );
  }, [profile]);

  useEffect(() => {
    setRecordingIcons(prepareRecordingIcons(recording));
  }, [recording]);

  const failedRecordingIconRenderer = useCallback(() => {
    if (!recordingIcons?.icons?.length) {
      return null;
    }
    return (
      <TileIconsRow mediaIcons={recordingIcons} />
    );
  }, [recordingIcons]);

  const tileConfig = useMemo(() => getRecordingTileConfig(recording, t, isFolderTile), [recording, t, isFolderTile]);

  return (
    isBigScreen ? (
      <MediaTileBase
        ref={ref}
        media={recording}
        key={recording.id}
        onFocus={onFocus}
        onPress={onTilePress}
        config={tileConfig}
        checkboxVisible={selectable}
        checkboxChecked={selected}
        onCheckboxToggle={selectionCheckboxPressHandler}
        additionalIcons={recordingIcons?.icons}
        tag={dateTimeTag}
        secondRowItemsRenderer={secondRowRenderer}
      />
    )
      : (
        <MobileMediaTileWide
          media={recording}
          channelId={channelId}
          showMoreActionsButton={false}
          onPress={onTilePress}
          isBlocked={isBlocked}
          config={tileConfig}
          additionalIconRenderer={failedRecordingIconRenderer}
          firstRowItemsRenderer={firstRowRenderer}
          secondRowItemsRenderer={secondRowRenderer}
          selectable={selectable}
          selected={selected}
          onSelectionCheckboxPress={selectionCheckboxPressHandler}
        />
      )
  );
};

export default React.memo(forwardRef(RecordingTile));
