import {createStyles} from 'common-styles';
import moment from 'moment';
import React, {useCallback, useMemo} from 'react';
import {ViewStyle, View} from 'react-native';

import {dimensions, isBigScreen, isMobile, RADIUS} from 'common/constants';
import {formatDatePrefix} from 'common/HelperFunctions';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {mw} from 'mw/MW';

import Calendar from 'components/Calendar';
import {IconType, Icon} from 'components/Icon';
import NitroxButton, {NitroxButtonProps, NitroxButtonTheme} from 'components/NitroxButton';
import {useToggle} from 'hooks/Hooks';
import {useLocalized} from 'locales/i18nUtils';

const iconSize = dimensions.icon.small;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  barItemContainer: {
    ...isMobile && {
      borderRadius: RADIUS,
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginVertical: dimensions.margins.small,
      marginRight: dimensions.margins.xLarge,
      padding: dimensions.margins.small,
      borderWidth: 0,
      borderColor: colors.epgScreen.icon
    }
  },
  icon: {
    color: colors.epgScreen.icon
  },
  button: {
    ...isMobile ? {
      marginLeft: dimensions.margins.small,
      paddingHorizontal: dimensions.margins.large
    } : {
      paddingLeft: dimensions.margins.small
    }
  }
}));

function formatSelectedDate(date: Date): string {
  const prefix = formatDatePrefix(date);
  return prefix + (prefix.length > 0 ? ' ' : '') + moment(date).format(mw.configuration.dateFormat);
}

type DateBarItemProps = {
  date: Date;
  style?: ViewStyle;
  maxDate?: Date;
  minDate?: Date;
  onJumpToDatePress: (date: Date) => void;
};

const DateBarItem: React.FC<DateBarItemProps> = ({date, onJumpToDatePress, maxDate, minDate}) => {
  const {toUpperCase} = useLocalized();
  const [calendarVisible, {on: showCalendar, off: closeCalendar}] = useToggle();
  const styles = stylesUpdater.getStyles();

  const onClose = useCallback((date: Date) => {
    onJumpToDatePress(date);
    closeCalendar();
  }, [onJumpToDatePress, closeCalendar]);

  const formattedDate = formatSelectedDate(date);

  const buttonProps = useMemo<NitroxButtonProps>((): NitroxButtonProps => {
    return {
      style: styles.button,
      ...isBigScreen ? {
        icon: {type: IconType.Calendar, size: iconSize},
        textType: 'callout',
        theme: NitroxButtonTheme.Primary,
        border: true
      } : {
        theme: NitroxButtonTheme.Secondary
      },
      testID: 'button_date_bar_item',
      text: toUpperCase(formattedDate),
      onPress: showCalendar
    };
  }, [styles, toUpperCase, formattedDate, showCalendar]);

  return (
    <>
      <View style={styles.barItemContainer} testID='date_bar'>
        {isMobile && <Icon type={IconType.Calendar} size={iconSize} color={styles.icon.color} />}
        <NitroxButton {...buttonProps} />
      </View>
      <Calendar visible={calendarVisible} onClose={onClose} maxDate={maxDate} minDate={minDate} date={date} />
    </>
  );
};

export default DateBarItem;
