import React, {useMemo} from 'react';
import {BlackPortal, BlackPortalProps} from 'react-native-portal';
import {NavigationContext} from 'react-navigation';

import {unique} from 'common/HelperFunctions';
import {Log} from 'common/Log';

type NitroxBlackPortalProps = {
  /** Pass map array of contexts to proxy them into portal's scope */
  contexts?: React.Context<any>[];
} & BlackPortalProps;
type ContextValues = any[];

const NitroxBlackPortal: React.FC<NitroxBlackPortalProps> = ({
  name,
  contexts,
  children
}) => {
  const allContexts = useMemo(() => unique([
    ...(contexts || []),
    NavigationContext
  ]), [contexts]);

  const providers = (values: ContextValues) =>
    allContexts
      .reduce((child, Context, contextIndex) => {
        Log.debug('NitroxBlackPortal', `Rendering ${Context.displayName} with value of ${values[contextIndex]}. Total values: ${values.length}.`);
        return (
          <Context.Provider value={values[contextIndex]}>
            {child}
          </Context.Provider>
        );
      }, children);

  const renderProviders = (values: ContextValues) => (
    <BlackPortal name={name}>
      {providers(values)}
    </BlackPortal>
  );

  const consumers = allContexts
    .reduce((renderChild, Context) => {
      // eslint-disable-next-line react/display-name
      return contextValues => (
        <Context.Consumer>
          {value => renderChild([value, ...contextValues])}
        </Context.Consumer>
      );
    }, renderProviders);

  return consumers([]);
};

export default NitroxBlackPortal;
