import {DateUtils} from 'common/DateUtils';
import {compactMap} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {ErrorType, Error} from 'mw/api/Error';
import {EntitlementState, Order, Product, OrderStatus, Media, Title} from 'mw/api/Metadata';
import {OrderResponse} from 'mw/bo-proxy/bo/adr8/ADR8Types';
import {EntitledMediaObject} from 'mw/common/entitled-products/EntitledMediaObject';
import {EntitledProduct} from 'mw/common/entitled-products/EntitledProductInterface';
import {EntitledProductObject} from 'mw/common/entitled-products/EntitledProductObject';
import {ProductObject} from 'mw/metadata/ProductObject';

import {OrderMapper} from './OrderMapper';

const TAG = 'EntitledProductMapper';

export class EntitledProductMapper {
  public static PaymentMethod = 'paymentMethod';

  public static fromOrdersJsonWithProducts(orders: OrderResponse[], productsWithMedia: {product: Product, media: Media[]}[]): EntitledProduct[] {
    return compactMap(orders, order => EntitledProductMapper.fromOrderJsonWithProducts(order, productsWithMedia));
  }

  private static fromOrderJsonWithProducts(orderJson: OrderResponse, productsWithMedia: {product: Product; media: Media[]}[]): EntitledProduct | null {
    const productWithMedia = productsWithMedia.find(({product: {id}}) => id === orderJson.productId);
    if (!productWithMedia) {
      Log.warn(TAG, `Cannot find product object for order: ${orderJson.id}`);
      return null;
    }
    const order = OrderMapper.orderFromJson(orderJson);
    return EntitledProductMapper.fromOrderWithProduct(order, productWithMedia);
  }

  public static fromOrderWithProduct(order: Order, productWithMedia: {product: Product; media: Media[]}): EntitledProduct {
    const {product, media} = productWithMedia;
    if (!product || product.id !== order.productId) {
      Log.error(TAG, `Wrong product object for order: ${order.id}`);
      throw new Error(ErrorType.InvalidParameter);
    }
    const entitledProduct = new ProductObject(order.productId);
    switch (order.orderStatus) {
      case OrderStatus.Success:
        entitledProduct.relationStart = order.creationDate;
        const now = new Date();
        entitledProduct.relationEnd = product?.duration ? DateUtils.addMilliseconds(entitledProduct.relationStart, product.duration) : DateUtils.addYears(now, 1);
        entitledProduct.entitlementState = DateUtils.isDateBetween(now, entitledProduct.relationStart, entitledProduct.relationEnd) ? EntitlementState.Entitled : EntitlementState.NotEntitled;
        break;

      case OrderStatus.Pending:
      case OrderStatus.Unpaid:
        entitledProduct.entitlementState = EntitlementState.EntitlementInProgress;
        break;
    }
    return EntitledProductMapper.fromProductWithMedia({product: entitledProduct, media});
  }

  public static fromProductWithMedia({product, media}: {product: Product; media: Media[]}): EntitledProduct {
    return new EntitledProductObject(
      product,
      media.map(m => {
        (m as Title).entitlementState = product.entitlementState ?? EntitlementState.NotEntitled;
        return new EntitledMediaObject(m, product.relationStart, product.relationEnd);
      })
    );
  }
}
