import {createStyles} from 'common-styles';
import React, {useCallback, useRef} from 'react';
import {View} from 'react-native';

import {isBigScreen, dimensions} from 'common/constants';
import {indexKeyExtractor} from 'common/HelperFunctions';

import NitroxButton, {NitroxButtonTheme} from 'components/NitroxButton';
import NitroxFlatList from 'components/NitroxFlatList';
import {VodCategory} from 'components/vod/categoryNavigator/VodCategoryNavigator';
import {useChangeEffect} from 'hooks/Hooks';
import {useLocalized} from 'locales/i18nUtils';

const styles = createStyles({
  listContent: {
    paddingRight: 90,
    paddingLeft: isBigScreen ? 0 : dimensions.margins.large
  },
  separatorContainer: {
    width: isBigScreen ? dimensions.margins.large : dimensions.margins.small
  },
  button: {
    width: 280
  }
});

type CategorySelectorProps = {
  categories: VodCategory[];
  onPress: (category: VodCategory) => void;
  buttonsActiveOpacity?: number;
}

const Separator: React.FC = () => <View style={styles.separatorContainer} />;

const CategorySelector: React.FC<CategorySelectorProps> = props => {
  const {
    buttonsActiveOpacity,
    categories,
    onPress
  } = props;

  const {toUpperCase} = useLocalized();
  const flatList = useRef<NitroxFlatList<VodCategory>>(null);

  useChangeEffect(() => {
    flatList.current?.scrollToOffset({animated: false, offset: 0});
  }, [categories]);

  const renderItem = useCallback(({item, index}: {item: VodCategory; index: number}) => (
    <NitroxButton
      focusPriority={+(!index)}
      border={isBigScreen}
      activeOpacity={buttonsActiveOpacity}
      text={isBigScreen ? toUpperCase(item.label) : item.label}
      onPress={() => onPress(item)}
      style={styles.button}
      theme={isBigScreen ? NitroxButtonTheme.Quaternary : NitroxButtonTheme.Secondary}
    />
  ), [buttonsActiveOpacity, onPress, toUpperCase]);

  return (
    <NitroxFlatList
      ref={flatList}
      horizontal
      showsHorizontalScrollIndicator={false}
      data={categories}
      keyExtractor={indexKeyExtractor}
      renderItem={renderItem}
      ItemSeparatorComponent={Separator}
      contentContainerStyle={styles.listContent}
    />
  );
};

export default CategorySelector;
