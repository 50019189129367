import {compactMap} from 'common/HelperFunctions';
import {makeArray} from 'common/utils';

import {ParentalControlRating} from 'mw/api/Metadata';
import {filterPCRatings} from 'mw/common/ParentalControl';
import {mw, PCType} from 'mw/MW';

interface ParentalControlRatingJSON {
  mediaAuthority?: string;
  rating?: string;
}

export class RatingsMapper {
  public static pcRatingsFromJson(json: any): ParentalControlRating[] {
    // TODO CL-6304 - after configuration of response model, it need to be verified if mapping is done correctly
    const parentalRatings = makeArray(json.parentalRatings);
    return parentalRatings.length && mw.configuration.pcPolicy.type === PCType.RatingsBased
      ? filterPCRatings(compactMap(parentalRatings, RatingsMapper.pcRatingFromJson))
      : [];
  }

  private static pcRatingFromJson(json: ParentalControlRatingJSON): ParentalControlRating | null {
    return json.mediaAuthority && json.rating
      ? {authority: json.mediaAuthority, value: json.rating, minimumAge: -1}
      : null;
  }
}
