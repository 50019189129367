import {createStyles} from 'common-styles';
import React, {useState, useRef, useEffect, useCallback, useImperativeHandle, useMemo, ReactNode, Ref, RefAttributes} from 'react';
import {ScrollView, View, ViewStyle, NativeSyntheticEvent, NativeScrollEvent, LayoutChangeEvent, GestureResponderEvent, PanResponder, Animated, StyleProp, PanResponderGestureState, Easing} from 'react-native';

import {isATV, isAndroid} from 'common/constants';
import {interpolate, addOffset, addVector, subVector, shallowEqual, zeroPoint, transformSize} from 'common/HelperFunctions';
import {Point, Rect, Size, Hashmap} from 'common/HelperTypes';
import {Log} from 'common/Log';
import {withinBoundaries} from 'common/utils';

import {NitroxHorizontalScrollView, NitroxVerticalScrollView} from 'components/epg/NitroxNativeScrollView';
import {NitroxInteractiveController} from 'components/NitroxInteractiveControllerContext';
import {useInteractionHandle} from 'components/performance/InteractionHandle';
import performanceConstants from 'components/performance/performanceConstants';
import {useDisposableCallback, useLazyEffect, useFunction, useChangeEffect} from 'hooks/Hooks';

import {DataDelegate, EpgNitroxContentView, RenderedItem, DataFetchDirection} from './EpgNitroxContentView';

export enum ScrollDirection {
  Vertical,
  Horizontal,
  Both,
  Omni
}

type DirectionWrapperProps = {
  direction: ScrollDirection;
  horizontal?: (children: JSX.Element) => JSX.Element;
  vertical?: (children: JSX.Element) => JSX.Element;
  children: JSX.Element;
};

export type ScrollEvent = {
  offset: Point;
  relativeOffset: Point; // relative to middle point
}

export type EpgNitroxScrollParams = {
  animated?: boolean;
  forceWhenControlled?: boolean;
  beyondDrawnRect?: boolean;
};

/** Parameters for forced and immediate scrolls */
export const forcedScrollParams: EpgNitroxScrollParams = {
  animated: false,
  forceWhenControlled: true,
  beyondDrawnRect: true
};

/** Parameters for scrolls that should behave like they would be triggered by user interaction. */
export const defaultScrollParams: EpgNitroxScrollParams = {
  animated: true,
  forceWhenControlled: false,
  beyondDrawnRect: false
};

export interface EpgNitroxScrollViewInterface<DataType> {
  /** Scrolls to the given absolute position and allows to force scrolls on controlled views. */
  scrollToPosition: (position: Point, params?: EpgNitroxScrollParams) => void;
  /** Scrolls to the given absolute x position and allows to force scrolls on controlled views. */
  scrollToXPosition: (x: number, params?: EpgNitroxScrollParams) => void;
  /** Scrolls to the given absolute y position and allows to force scrolls on controlled views. */
  scrollToYPosition: (y: number, params?: EpgNitroxScrollParams) => void;
  /** Scrolls to the given frame which can be expressed in relative or absolute coordinates. */
  scrollToFrame: (rect: Rect, absolute?: boolean) => void;
  /** Scrolls to the given offset. ScrollView has to be fully initialized before calling this function. */
  scrollByOffset: (direction: ScrollDirection, offset: number) => void;
  invalidateLayout: () => void;
  getItemByPoint: (point: Point) => RenderedItem<DataType> | null;
  updateItem: (item: RenderedItem<DataType>) => void;
  updateItemByPoint: (point: Point) => void;
}

export interface EpgNitroxScrollViewProps<DataType> extends DataDelegate<DataType> {
  style?: ViewStyle;
  extraData?: any;
  /** Executed when container's position and dimensions have been measured. */
  onLayout?: (event: LayoutChangeEvent) => void;
  /** Executed after every horizontal scroll. */
  onScrollHorizontal?: (event: ScrollEvent) => void;
  /** Executed after every vertical scroll. */
  onScrollVertical?: (event: ScrollEvent) => void;
  /** Executed after middle point's (aka content start offset) position becomes known. */
  onSetContentStartOffset?: (point: Point) => void;
  /** Executed after every touch start. */
  onTouchStart?: (event: GestureResponderEvent) => void;
  viewSize: Size;
  contentWidth?: number; // If not defined "inifinite content" will be simulated
  contentHeight?: number; // If not defined "inifinite content" will be simulated
  /** Width of a fixed-sized items that are going to be rendered. */
  itemWidth?: number;
  /** Height of a fixed-sized items that are going to be rendered. */
  itemHeight?: number;
  direction: ScrollDirection;
  dataFetchDirection?: DataFetchDirection;
  scrollEnabled?: boolean;
  fixedHorizontalFocusPosition?: boolean;
  leftInset?: number;
  fixedVerticalFocusPosition?: boolean;
  topInset?: number;
  showIndicators?: boolean;
  children?: ReactNode;
  /** Executed when new items have been rerendered */
  onContentLayout?: (items: Hashmap<RenderedItem<DataType>>, drawnRect: Rect) => void;
  onPress?: (position: Point) => void;
  scrollPosition?: Animated.ValueXY | null;
  controlled?: boolean;
  focusable?: boolean; // Workaround for AndroidTV. ScrollView gets focus even if we have scrollEnabled on false
  debugName?: string;
  startPoint?: Point;
}

export interface LayoutItem<DataType> extends Rect {
  data: DataType;
}

const TAG = 'EpgNitroxScrollView';
const contentViewSize = 1000000; // This number can't be too big due to floating point arithmetic hidden underneath
const momentumScrollDurationRange: [number, number] = [250, 500];
// in other words: part of distance, that user would scroll with given velocity during animation period
const momentumScrollDistanceRatio = 0.2;
// minimum velocity (pixels/ms) to trigger momentum
const momentumScrollVelocityThreshold = 0.1;
const momentumScrollVelocityRange: [number, number] = [momentumScrollVelocityThreshold, 10];
// geasutre is recognized as a press event when the value of the accumulated distance of the gesture is below this threshold value
const pressDistanceThreshold = 2;
const scrollPadding: Size = {width: 100, height: 100};
const minDataWindowSizeToViewSizeRatio = 1;
const maxDataWindowSizeToViewSizeRatio = 2;
// there is a bug on android plaforms that reveals itself by onLayout callback being called several times in a row with slightly different dimensions
const scrollViewSizeThreshold = isAndroid ? 1 : 0;

const styles = createStyles({
  container: {
    flex: 1
  }
});

const DirectionWrapper: React.FunctionComponent<DirectionWrapperProps> = props => {
  switch (props.direction) {
    case ScrollDirection.Both:
    case ScrollDirection.Omni:
      if (typeof props.vertical !== 'function' || typeof props.horizontal !== 'function') {
        Log.error(TAG, 'Please provide function for horizontal and vertical!');
        return null;
      }
      return props.vertical(props.horizontal(props.children));
    case ScrollDirection.Horizontal:
      if (typeof props.horizontal !== 'function') {
        Log.error(TAG, 'Please provide function for horizontal!');
        return null;
      }
      return props.horizontal(props.children);
    case ScrollDirection.Vertical:
      if (typeof props.vertical !== 'function') {
        Log.error(TAG, 'Please provide function for vertical!');
        return null;
      }
      return props.vertical(props.children);
  }
};

type LayoutBoundaries = {
  vertical: [number, number];
  horizontal: [number, number];
};

function gestureToLayout(gestureState: Pick<PanResponderGestureState, 'dx' | 'dy'>, middlePoint: Point, scrollOffset: Point, scrollBoundaries: LayoutBoundaries) {
  const {vertical: [minY, maxY], horizontal: [minX, maxX]} = scrollBoundaries;
  const currentPosition = subVector(middlePoint, scrollOffset);
  const position = {
    x: withinBoundaries(minX, maxX, currentPosition.x + gestureState.dx),
    y: withinBoundaries(minY, maxY, currentPosition.y + gestureState.dy)
  };
  const offset = subVector(middlePoint, position);
  return {offset, position};
}

function EpgNitroxScrollViewComponent<DataType>(props: EpgNitroxScrollViewProps<DataType>, ref: Ref<EpgNitroxScrollViewInterface<DataType>>): React.ReactElement {
  const {
    extraData,
    onPress: propsOnPress,
    onLayout: propsOnLayout,
    fixedHorizontalFocusPosition = false,
    leftInset = 0,
    fixedVerticalFocusPosition = false,
    topInset = 0,
    showIndicators = false,
    dataFetchDirection = DataFetchDirection.Horizontal,
    itemWidth = 1,
    itemHeight = 1,
    startPoint
  } = props;
  const debugName = useMemo(() => props.debugName ? `[${props.debugName}] ` : '', [props.debugName]);
  const scrollRef = {vertical: useRef<ScrollView>(null), horizontal: useRef<ScrollView>(null)};
  const contentViewRef = useRef<EpgNitroxContentView<DataType>>(null);
  const [initialized, setInitialized] = useState(false);

  const {onScrollHorizontal, onScrollVertical, onSetContentStartOffset, onContentLayout} = props;

  const calculateDataWindowSize = useCallback((newGridSize): Size => {
    // we need to make sure that data window size can be divided by grid size without the remainder
    const [horizontalRatio, verticalRatio] = dataFetchDirection === DataFetchDirection.Horizontal
      ? [minDataWindowSizeToViewSizeRatio, maxDataWindowSizeToViewSizeRatio]
      : [maxDataWindowSizeToViewSizeRatio, minDataWindowSizeToViewSizeRatio];
    return {
      width: (props.direction === ScrollDirection.Vertical ? newGridSize.width : newGridSize.width * horizontalRatio),
      height: (props.direction === ScrollDirection.Horizontal ? newGridSize.height : newGridSize.height * verticalRatio)
    };
  }, [props.direction, dataFetchDirection]);

  const calculateContentSize = useCallback((): Size => {
    return {
      width: props.contentWidth || contentViewSize,
      height: props.contentHeight || contentViewSize
    };
  }, [props.contentWidth, props.contentHeight]);

  const calculateMiddlePoint = useCallback((): Point => {
    return {
      x: props.contentWidth ? 0 : Math.floor(contentViewSize / 2),
      y: props.contentHeight ? 0 : Math.floor(contentViewSize / 2)
    };
  }, [props.contentWidth, props.contentHeight]);

  const calculateGridSize = useCallback((): Size => {
    const viewSize = transformSize(props.viewSize, Math.floor);
    return {
      width: itemWidth * Math.ceil(viewSize.width / itemWidth),
      height: itemHeight * Math.ceil(viewSize.height / itemHeight)
    };
  }, [props.viewSize, itemHeight, itemWidth]);

  const initialGridSize = calculateGridSize();
  const [gridSize, setGridSize] = useState<Size>(initialGridSize);
  const [dataWindowSize, setDataWindowSize] = useState<Size>(calculateDataWindowSize(initialGridSize));

  const calculateCurrentGrid = useCallback((offset: Point): Point => {
    // currentGridPosition - position in ContentView space
    return {
      x: gridSize.width === 0 ? 0 : Math.round(offset.x / gridSize.width),
      y: gridSize.height === 0 ? 0 : Math.round(offset.y / gridSize.height)
    };
  }, [gridSize.width, gridSize.height]);

  const [contentSize, setContentSize] = useState<Size>(calculateContentSize());
  const [middlePoint, setMiddlePoint] = useState<Point>(startPoint ?? calculateMiddlePoint());
  const [currentGrid, setCurrentGrid] = useState<Point>(calculateCurrentGrid(middlePoint));

  // Currently here are two values that store current scroll position.
  // scrollPosition stores animated value and is used together with gesture handler
  // currentScrollOffset stores offset reported by callback function of ScrollView (which is a little bit out of sync
  // with what is drawn on the screen, but currently there is nothing better to use on STB
  const scrollPosition = useRef(props.scrollPosition || new Animated.ValueXY(zeroPoint()));
  const currentScrollOffset = useRef<Point>(zeroPoint());
  const previousScrollOffset = useRef<Point>(zeroPoint());
  const initialScrollOffset = useRef<Point | null>(null);

  const pendingAnimation = useRef<Animated.CompositeAnimation | null>();

  const [scrollOffset, setScrollOffset] = useState(middlePoint);
  const [scrollBoundaries, setScrollBoundaries] = useState<LayoutBoundaries>({vertical: [0, 0], horizontal: [0, 0]});

  const containerRef = useRef<View>(null);
  const containerPosition = useRef<Point | null>(null);

  const [mainViewSize, setMainViewSize] = useState<Size>({width: 0, height: 0});
  const [drawnRect, setDrawnRect] = useState<Rect>();
  const momentumScrollEnd = useRef<boolean>(true);

  const scrolledToOrigin = useRef(false);
  const scrolledBeyondDrawnRect = useRef(false);

  const calculateRelativeOffset = useCallback((absoluteOffset: Point) => {
    return subVector(absoluteOffset, middlePoint);
  }, [middlePoint]);

  const calculateContainerOrigin = useCallback(() => {
    return subVector(middlePoint, {x: leftInset, y: topInset});
  }, [middlePoint, leftInset, topInset]);

  const calculateInitialOffset = useCallback(() => {
    // try to set initial grid based on current offsets if not fallback to initially requested offset or to the middle point to reduce the number of grid changes
    const offset = props.controlled ? scrollOffset : currentScrollOffset.current;
    return offset.x !== 0 && offset.y !== 0
      ? offset
      : initialScrollOffset.current ?? calculateContainerOrigin();
  }, [props.controlled, scrollOffset, currentScrollOffset, calculateContainerOrigin]);

  useChangeEffect(() => {
    contentViewRef.current?.forceUpdate();
  }, [extraData]);

  useEffect(() => {
    if (props.direction === ScrollDirection.Omni || props.controlled) {
      // top left corner is middle point anchor
      const maxDistanceUp = Math.max(0, middlePoint.y);
      const maxDistanceDown = Math.max(0, contentSize.height - middlePoint.y - props.viewSize.height);
      const maxDistanceLeft = Math.max(0, middlePoint.x);
      const maxDistanceRight = Math.max(0, contentSize.width - middlePoint.x - props.viewSize.width);

      // frame - visible content frame
      // as frame goes up, top rises
      // as frame goes left, left rises
      setScrollBoundaries({vertical: [-maxDistanceDown, maxDistanceUp], horizontal: [-maxDistanceRight, maxDistanceLeft]});
    }
  }, [contentSize.height, contentSize.width, middlePoint.x, middlePoint.y, props.direction, props.viewSize.height, props.viewSize.width, props.controlled]);

  useEffect(() => {
    setScrollOffset({x: middlePoint.x, y: middlePoint.y});
    if (props.direction === ScrollDirection.Omni) {
      scrollPosition.current.setOffset(zeroPoint());
      scrollPosition.current.setValue(zeroPoint());
    }
  }, [middlePoint.x, middlePoint.y, props.direction]);

  useEffect(() => {
    setContentSize(calculateContentSize());
    setMiddlePoint(startPoint ?? calculateMiddlePoint());
  }, [calculateContentSize, calculateMiddlePoint, startPoint]);

  useLazyEffect(() => {
    onSetContentStartOffset && onSetContentStartOffset({x: middlePoint.x, y: middlePoint.y});
  }, [middlePoint.x, middlePoint.y], [onSetContentStartOffset]);

  useLazyEffect(() => {
    if (props.viewSize.width === 0 || props.viewSize.height === 0) {
      return;
    }
    const newGridSize = calculateGridSize();
    setGridSize(newGridSize);
    setDataWindowSize(calculateDataWindowSize(newGridSize));
    setInitialized(false);
  }, [props.viewSize, props.direction], [calculateDataWindowSize, calculateGridSize]);

  useEffect(() => {
    // make sure to scroll to origin again after reinitialization to adjust for new grid and data window values
    if (!initialized) {
      scrolledToOrigin.current = false;
      scrolledBeyondDrawnRect.current = false;
    }
  }, [initialized]);

  const scrollPosCurrent = scrollPosition.current;
  useEffect(() => {
    if (props.controlled) {
      const listener = scrollPosCurrent.addListener(({x, y}) => {
        currentScrollOffset.current.x = middlePoint.x - x;
        currentScrollOffset.current.y = middlePoint.y - y;
      });
      return () => scrollPosCurrent.removeListener(listener);
    }
  }, [scrollPosCurrent, props.controlled, middlePoint.x, middlePoint.y]);

  useEffect(() => {
    if (gridSize.width === 0 || gridSize.height === 0) {
      return;
    }
    if (!initialized) {
      setInitialized(true);
      setCurrentGrid(calculateCurrentGrid(calculateInitialOffset()));
    }
  }, [initialized, gridSize.width, gridSize.height, calculateCurrentGrid, calculateInitialOffset]);

  const onContainerMeasure = useDisposableCallback((x, y, width, height, pageX, pageY, callback?: () => void) => {
    // when view is hidden but still remained mounted (for example when screen on which it was rendered is changed) screen coordinates reported by react are undefineds
    if (typeof pageX === 'undefined' || typeof pageY === 'undefined') {
      callback && callback();
      return;
    }
    // store position of the container in global coordinates order to be able to convert press events' position into local coordinates
    containerPosition.current = {
      x: pageX,
      y: pageY
    };
    callback && callback();
  });

  const measureContainerPosition = useCallback((callback?: () => void) => {
    containerRef.current && containerRef.current.measure((x, y, width, height, pageX, pageY) => {
      onContainerMeasure(x, y, width, height, pageX, pageY, callback);
    });
  }, [containerRef, onContainerMeasure]);

  const resetMomentumScrollToOffset = useCallback((newOffset: Point, updateScrollPositionCallback: () => void) => {
    if (pendingAnimation.current) {
      pendingAnimation.current.stop();
    }
    const momentumOrigin = {x: 0, y: 0};
    pendingAnimation.current = Animated.timing(scrollPosition.current, {
      toValue: momentumOrigin,
      duration: 0
    });
    pendingAnimation.current.start(() => {
      scrollPosition.current.setOffset(momentumOrigin);
      scrollPosition.current.setValue(momentumOrigin);
      setScrollOffset(newOffset);
      updateScrollPositionCallback();
    });
  }, []);

  const scrollToFrame = useCallback((frame: Rect, absolute?: boolean) => {
    if (!scrolledToOrigin.current) {
      Log.warn(TAG, `${debugName}Failed to scroll to frame. View is not initialized.`);
      initialScrollOffset.current = {...frame};
      return;
    }
    // frame is in user space, so needs to be adjusted to absolute position in content view
    if (mainViewSize.height === 0 || mainViewSize.width === 0) {
      Log.warn(TAG, `${debugName}Failed to scroll to frame. mainViewSize has not been set.`);
      return;
    }
    const currentOffset = currentScrollOffset.current;
    const newOffset: Point = {...currentScrollOffset.current};
    const framePosition = addVector({x: frame.x, y: frame.y}, absolute ? zeroPoint() : middlePoint);

    if (framePosition.x < currentOffset.x) {
      newOffset.x = framePosition.x - scrollPadding.width;
    }

    if ((framePosition.x + frame.width) > (currentOffset.x + mainViewSize.width)) {
      if (framePosition.x < currentOffset.x) {
        Log.trace(TAG, `${debugName}Failed to scroll to frame. Frame doesn't fit to view`);
      } else {
        newOffset.x = framePosition.x + frame.width - mainViewSize.width + scrollPadding.width;
      }
    }

    if (framePosition.y < currentOffset.y) {
      newOffset.y = framePosition.y - scrollPadding.height;
    }

    if ((framePosition.y + frame.height) > (currentOffset.y + mainViewSize.height)) {
      if (framePosition.y < currentOffset.y) {
        Log.trace(TAG, `${debugName}Failed to scroll to frame. Frame doesn't fit to view`);
      } else {
        newOffset.y = framePosition.y + frame.height - mainViewSize.height + scrollPadding.height;
      }
    }

    resetMomentumScrollToOffset(newOffset, () => {
      scrollRef.horizontal.current?.scrollTo({x: newOffset.x});
      scrollRef.vertical.current?.scrollTo({y: newOffset.y});
    });
  }, [mainViewSize.height, mainViewSize.width, middlePoint, resetMomentumScrollToOffset, debugName, scrollRef.horizontal, scrollRef.vertical]);

  const scrollByOffset = useCallback((direction: ScrollDirection, offset: number) => {
    // currentScrollOffset is set after performing the initial scroll to origin there disallow any attempts to scroll by offset before then
    if (!scrolledToOrigin.current) {
      Log.warn(TAG, `${debugName}Failed to scroll by offset. View is not initialized.`);
      return;
    }
    const newOffset = addOffset(currentScrollOffset.current, offset);
    switch (direction) {
      case ScrollDirection.Vertical:
        scrollRef.vertical.current?.scrollTo({y: newOffset.y});
        break;
      case ScrollDirection.Horizontal:
        scrollRef.horizontal.current?.scrollTo({x: newOffset.x});
        break;
      default:
        Log.error(TAG, `${debugName}Can't scroll. Direction not supported`);
        break;
    }
  }, [debugName, scrollRef.vertical, scrollRef.horizontal]);

  const prepareToScroll = useCallback((position: Point, params: EpgNitroxScrollParams, callback: () => void) => {
    Log.trace(TAG, `${debugName}${params.forceWhenControlled ? 'Forcing scroll ' : 'Scrolling'} to position position.x=${position.x} position.y=${position.y}`);
    if (props.controlled && !params.forceWhenControlled) {
      return;
    }
    if (!scrolledToOrigin.current) {
      Log.warn(TAG, `${debugName}Failed to scroll to position. View is not initialized.`);
      initialScrollOffset.current = {...position};
      return;
    }
    resetMomentumScrollToOffset(position, callback);
  }, [props.controlled, debugName, resetMomentumScrollToOffset]);

  const scrollToPosition = useCallback((position: Point, params: EpgNitroxScrollParams = defaultScrollParams) => {
    prepareToScroll(position, params, () => {
      scrolledBeyondDrawnRect.current = !!params.beyondDrawnRect;
      scrollRef.horizontal.current?.scrollTo({x: position.x, y: 0, animated: params.animated});
      scrollRef.vertical.current?.scrollTo({x: 0, y: position.y, animated: params.animated});
    });
  }, [prepareToScroll, scrollRef.horizontal, scrollRef.vertical]);

  const scrollToXPosition = useCallback((x: number, params: EpgNitroxScrollParams = defaultScrollParams) => {
    const position = {x, y: 0};
    prepareToScroll(position, params, () => {
      scrolledBeyondDrawnRect.current = !!params.beyondDrawnRect;
      scrollRef.horizontal.current?.scrollTo({...position, animated: params.animated});
    });
  }, [prepareToScroll, scrollRef.horizontal]);

  const scrollToYPosition = useCallback((y: number, params: EpgNitroxScrollParams = defaultScrollParams) => {
    const position = {x: 0, y};
    prepareToScroll(position, params, () => {
      scrolledBeyondDrawnRect.current = !!params.beyondDrawnRect;
      scrollRef.vertical.current?.scrollTo({...position, animated: params.animated});
    });
  }, [prepareToScroll, scrollRef.vertical]);

  useImperativeHandle(ref, () => ({
    scrollToXPosition,
    scrollToYPosition,
    scrollToPosition,
    scrollToFrame,
    scrollByOffset,
    invalidateLayout: () => contentViewRef.current?.invalidateLayout(),
    getItemByPoint: (point: Point): RenderedItem<DataType> | null => contentViewRef.current ? contentViewRef.current.getItemByPoint(point) : null,
    updateItem: (item: RenderedItem<DataType>) => contentViewRef.current?.updateItem(item),
    updateItemByPoint: (point: Point) => contentViewRef.current?.updateItemByPoint(point)
  }), [
    scrollToXPosition,
    scrollToYPosition,
    scrollToPosition,
    scrollToFrame,
    scrollByOffset
  ]);

  const {createTemporary: createTemporaryScrollHandle} = useInteractionHandle(performanceConstants.interactionHandle.scrollDelay);

  const onMomentumScrollEnd = useCallback((offset: Point, position: Point) => {
    pendingAnimation.current = null;
    const {x, y} = offset;

    if (x !== scrollOffset.x && onScrollHorizontal) {
      onScrollHorizontal({offset, relativeOffset: calculateRelativeOffset(offset)});
    }
    if (y !== scrollOffset.y && onScrollVertical) {
      onScrollVertical({offset, relativeOffset: calculateRelativeOffset(offset)});
    }
    setScrollOffset({x, y});
    // we cant simply do offset.current.extractOffset(), because value may be corrected to fit into boundaries
    scrollPosition.current.setOffset({x: position.x, y: position.y});
    scrollPosition.current.setValue({x: 0, y: 0});

    momentumScrollEnd.current = true;
  }, [onScrollHorizontal, onScrollVertical, scrollOffset.x, scrollOffset.y, calculateRelativeOffset]);

  const panHandlers = useMemo(() => props.direction === ScrollDirection.Omni && props.scrollEnabled !== false
    ? PanResponder.create({
      onStartShouldSetPanResponder: () => momentumScrollEnd.current,
      onStartShouldSetPanResponderCapture: () => momentumScrollEnd.current,
      onMoveShouldSetPanResponder: () => momentumScrollEnd.current,
      onMoveShouldSetPanResponderCapture: () => momentumScrollEnd.current,
      onPanResponderStart: () => {
        if (pendingAnimation.current) {
          pendingAnimation.current.stop();
          pendingAnimation.current = null;
        }
      },
      onPanResponderMove: Animated.event([
        null,
        {dy: scrollPosition.current.y, dx: scrollPosition.current.x}
      ]),
      onPanResponderRelease: (evt, gestureState) => {
        const {nativeEvent} = evt;
        const {vx, vy, dx, dy} = gestureState;
        let momentumX = 0;
        let momentumY = 0;
        const vxAbs = Math.abs(vx);
        const vyAbs = Math.abs(vy);
        const momentumScrollDuration = interpolate(Math.max(vxAbs, vyAbs), {inputRange: momentumScrollVelocityRange, outputRange: momentumScrollDurationRange});
        // v{x/y} -> velocity in pixels per ms
        if (vxAbs > momentumScrollVelocityThreshold || vyAbs > momentumScrollVelocityThreshold) {
          momentumX = vx * momentumScrollDuration * momentumScrollDistanceRatio;
          momentumY = vy * momentumScrollDuration * momentumScrollDistanceRatio;
        }
        const {offset, position} = gestureToLayout({dx: dx + momentumX, dy: dy + momentumY}, middlePoint, scrollOffset, scrollBoundaries);
        const detectPressEvent = () => {
          if (!containerPosition.current || !propsOnPress || Math.abs(dx) >= pressDistanceThreshold || Math.abs(dy) >= pressDistanceThreshold) {
            return;
          }
          propsOnPress({
            x: (scrollOffset.x - middlePoint.x + nativeEvent.pageX - containerPosition.current.x),
            y: (scrollOffset.y - middlePoint.y + nativeEvent.pageY - containerPosition.current.y)
          });
        };
        // ensure that we have a valid container position which is essential to detect press event's
        if (containerPosition.current) {
          detectPressEvent();
        } else {
          measureContainerPosition(detectPressEvent);
        }
        const onScrollEnd = () => onMomentumScrollEnd(offset, position);
        if (momentumX || momentumY) {
          scrollPosition.current.flattenOffset();
          pendingAnimation.current = Animated.timing(scrollPosition.current, {
            toValue: position,
            duration: momentumScrollDuration,
            easing: Easing.out(Easing.quad)
          });
          momentumScrollEnd.current = false;
          pendingAnimation.current.start(onScrollEnd);
        } else {
          onScrollEnd();
        }
      }
    }).panHandlers
    : {},
  [scrollOffset, middlePoint, scrollBoundaries, onMomentumScrollEnd, containerPosition, propsOnPress, measureContainerPosition, props.direction, props.scrollEnabled]);

  const scrolledHorizontally = useCallback((event: NativeSyntheticEvent<NativeScrollEvent>) => {
    if (event.nativeEvent.contentSize.width === 0) {
      return;
    }
    createTemporaryScrollHandle();

    const offset = event.nativeEvent.contentOffset;
    onScrollHorizontal && onScrollHorizontal({offset, relativeOffset: calculateRelativeOffset(offset)});
    const newGrid = {
      ...calculateCurrentGrid(offset),
      y: currentGrid.y // keep the same y value
    };
    if (newGrid.x !== currentGrid.x && newGrid.x !== 0) {
      Log.trace(TAG, `${debugName}New scroll horizontal grid position: ${newGrid.x}`);
      setCurrentGrid(newGrid);
    }
    currentScrollOffset.current.x = offset.x;

    if (!drawnRect) {
      return;
    }

    if (typeof props.contentWidth === 'undefined' && !scrolledBeyondDrawnRect.current) {
      const minX = drawnRect.x - leftInset;
      if (currentScrollOffset.current.x < previousScrollOffset.current.x && currentScrollOffset.current.x < minX) {
        scrollRef.horizontal.current?.scrollTo({x: minX, animated: true});
        Log.trace(TAG, `${debugName}Force horizontal scroll to: ${minX}`);
      }

      const maxX = Math.max(minX, drawnRect.x + drawnRect.width + leftInset + (fixedHorizontalFocusPosition ? mainViewSize.width : 0));
      if (currentScrollOffset.current.x > previousScrollOffset.current.x && currentScrollOffset.current.x > maxX) {
        scrollRef.horizontal.current?.scrollTo({x: maxX, animated: true});
        Log.trace(TAG, `${debugName}Force horizontal scroll to: ${maxX}`);
      }
    }

    previousScrollOffset.current.x = currentScrollOffset.current.x;
    scrolledBeyondDrawnRect.current = false;
  }, [createTemporaryScrollHandle, onScrollHorizontal, calculateRelativeOffset, calculateCurrentGrid, currentGrid.y, currentGrid.x, drawnRect, props.contentWidth, debugName, leftInset, fixedHorizontalFocusPosition, mainViewSize.width, scrollRef.horizontal]);

  const scrolledVertically = useCallback((event: NativeSyntheticEvent<NativeScrollEvent>) => {
    if (event.nativeEvent.contentSize.height === 0) {
      return;
    }
    createTemporaryScrollHandle();

    const offset = event.nativeEvent.contentOffset;
    onScrollVertical && onScrollVertical({offset, relativeOffset: calculateRelativeOffset(offset)});
    const newGrid = {
      ...calculateCurrentGrid(offset),
      x: currentGrid.x // keep the same x value
    };
    if (newGrid.y !== currentGrid.y) {
      Log.trace(TAG, `${debugName}New scroll vertical grid position: ${newGrid.y}`);
      setCurrentGrid(newGrid);
    }
    currentScrollOffset.current.y = offset.y;

    if (!drawnRect) {
      return;
    }

    if (typeof props.contentHeight === 'undefined' && !scrolledBeyondDrawnRect.current) {
      const minY = drawnRect.y - topInset;
      if (currentScrollOffset.current.y < previousScrollOffset.current.y && currentScrollOffset.current.y < minY) {
        scrollRef.vertical.current?.scrollTo({y: minY, animated: false});
        Log.trace(TAG, `${debugName}Force vertical scroll to: ${minY}`);
      }

      const maxY = Math.max(minY, drawnRect.y + drawnRect.height + topInset + (fixedVerticalFocusPosition ? mainViewSize.height : 0));
      if (currentScrollOffset.current.y > previousScrollOffset.current.y && currentScrollOffset.current.y > maxY) {
        scrollRef.vertical.current?.scrollTo({y: maxY, animated: false});
        Log.trace(TAG, `${debugName}Force vertical scroll to: ${maxY}`);
      }
    }

    previousScrollOffset.current.y = currentScrollOffset.current.y;
    scrolledBeyondDrawnRect.current = false;
  }, [createTemporaryScrollHandle, onScrollVertical, calculateRelativeOffset, calculateCurrentGrid, currentGrid.x, currentGrid.y, drawnRect, props.contentHeight, debugName, topInset, fixedVerticalFocusPosition, mainViewSize.height, scrollRef.vertical]);

  const contentStyle = useMemo(() => {
    const size = {width: contentSize.width, height: contentSize.height};
    if (props.direction === ScrollDirection.Omni || props.controlled) {
      const topLeft = {
        top: scrollPosition.current.getLayout().top.interpolate({outputRange: scrollBoundaries.vertical, inputRange: scrollBoundaries.vertical, extrapolate: 'clamp'}),
        left: scrollPosition.current.getLayout().left.interpolate({outputRange: scrollBoundaries.horizontal, inputRange: scrollBoundaries.horizontal, extrapolate: 'clamp'})
      };
      return [size, topLeft];
    }
    return size;
  }, [contentSize.height, contentSize.width, props.direction, scrollBoundaries.horizontal, scrollBoundaries.vertical, props.controlled]) as StyleProp<ViewStyle>;

  const horizontalContentInset = useMemo(() => ({left: leftInset}), [leftInset]);
  const verticalContentInset = useMemo(() => ({left: topInset}), [topInset]);

  const onLayoutScrollView = useCallback((event: LayoutChangeEvent) => {
    const {width, height} = event.nativeEvent.layout;
    if (Math.abs(width - mainViewSize.width) <= scrollViewSizeThreshold && Math.abs(height - mainViewSize.height) <= scrollViewSizeThreshold) {
      return;
    }
    // Do not measure container's position here because on Apple platforms this is still too soon to do so.
    // This measurement is postponed until first press event has to be detected.
    propsOnLayout && propsOnLayout(event);
    setMainViewSize({width, height});
  }, [propsOnLayout, mainViewSize.width, mainViewSize.height]);

  const onNewItemsDrawn = useFunction((items, newDrawnRect) => {
    Log.trace(TAG, `${debugName} newContentLayout: ${newDrawnRect.width}`);
    onContentLayout && onContentLayout(items, newDrawnRect);
    if (newDrawnRect.width !== 0 && !shallowEqual(newDrawnRect, drawnRect)) {
      setDrawnRect(newDrawnRect);
    }
  });

  const onLayoutContentView = useCallback((event?: LayoutChangeEvent) => {
    // skip if we already scrolled or started scrolling to origin or the dimensions are still unknown
    if (scrolledToOrigin.current || !event) {
      return;
    }
    const {nativeEvent: {layout: {width, height}}} = event;
    if (width <= 0 || height <= 0) {
      return;
    }
    // compute the origin's position and update scroll state
    scrolledToOrigin.current = true;
    const origin = initialScrollOffset.current ?? calculateContainerOrigin();
    Log.trace(TAG, `${debugName} ContentView size set, scrolling to origin (${origin.x}, ${origin.y})`);
    scrollRef.horizontal.current?.scrollTo({x: origin.x, animated: false});
    scrollRef.vertical.current?.scrollTo({y: origin.y, animated: false});
  }, [scrollRef.horizontal, scrollRef.vertical, debugName, calculateContainerOrigin]);

  const shouldEnableScrolling = props.direction !== ScrollDirection.Omni && props.scrollEnabled;
  Log.trace(TAG, `${debugName} Render EpgNitroxScrollView.`);

  return (
    <NitroxInteractiveController omitGeometryCaching>
      <View ref={containerRef} style={props.style} onLayout={onLayoutScrollView} {...panHandlers}>
        <DirectionWrapper
          direction={props.direction}
          horizontal={(children: JSX.Element) => (
            <ScrollView
              focusable={props.focusable}
              ref={scrollRef.horizontal}
              keyboardShouldPersistTaps='handled'
              horizontal
              contentInset={horizontalContentInset}
              onScroll={scrolledHorizontally}
              scrollEventThrottle={8}
              style={styles.container}
              contentInsetAdjustmentBehavior='never'
              automaticallyAdjustContentInsets={false}
              scrollEnabled={shouldEnableScrolling}
              showsHorizontalScrollIndicator={showIndicators}
              CustomNativeScrollViewComponent={isATV && NitroxHorizontalScrollView}
              fixedFocusBehaviour={fixedHorizontalFocusPosition}
              fixedFocusPosition={leftInset}
              alwaysBounceVertical={false}
              onTouchStart={props.onTouchStart}
            >
              {children}
            </ScrollView>
          )}
          vertical={(children: JSX.Element) => (
            <ScrollView
              focusable={props.focusable}
              ref={scrollRef.vertical}
              onScroll={scrolledVertically}
              style={styles.container}
              contentInset={verticalContentInset}
              scrollEventThrottle={8}
              contentInsetAdjustmentBehavior='never'
              automaticallyAdjustContentInsets={false}
              scrollEnabled={shouldEnableScrolling}
              showsVerticalScrollIndicator={showIndicators}
              CustomNativeScrollViewComponent={isATV && NitroxVerticalScrollView}
              fixedFocusBehaviour={fixedVerticalFocusPosition}
              fixedFocusPosition={topInset}
              alwaysBounceVertical={false}
            >
              {children}
            </ScrollView>
          )}
        >
          {(initialized && currentGrid) ? (
            <EpgNitroxContentView<DataType>
              ref={contentViewRef}
              style={contentStyle}
              onLayout={onLayoutContentView}
              gridSize={gridSize}
              dataWindowSize={dataWindowSize}
              dataFetchDirection={dataFetchDirection}
              currentGrid={currentGrid}
              startPosition={middlePoint}
              layoutItemsForRect={props.layoutItemsForRect}
              renderItem={props.renderItem}
              keyExtractor={props.keyExtractor}
              debugName={debugName}
              onNewLayout={onNewItemsDrawn}
            >
              {props.children}
            </EpgNitroxContentView>
          )
            : <View style={contentSize} />
          }
        </DirectionWrapper>
      </View>
    </NitroxInteractiveController>
  );
}

type EpgNitroxScrollViewType = <T>(props: EpgNitroxScrollViewProps<T> & RefAttributes<EpgNitroxScrollViewInterface<T>>) => React.ReactElement | null;
export const EpgNitroxScrollView: EpgNitroxScrollViewType = React.memo(React.forwardRef<EpgNitroxScrollViewInterface<any>>(EpgNitroxScrollViewComponent as any));
