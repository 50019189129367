import {createStyles} from 'common-styles';
import React, {useMemo, useCallback, useState} from 'react';
import {ViewStyle, View, LayoutChangeEvent} from 'react-native';
import Reanimated, {lessThan, cond, greaterThan, eq, multiply, add} from 'react-native-reanimated';

import {RADIUS, dimensions, commonAnimationTransforms} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';

import NitroxText from 'components/NitroxText';
import {useCurrentTime} from 'hooks/Hooks';

const currentTimeMargin = (dimensions.epg.timeBarHeight - dimensions.epg.timeBarCurrentTimeHeight) / 2;

const stylesUpdater = new StylesUpdater(colors => createStyles({
  currentTimeContainerStyle: {
    position: 'absolute'
  },
  currentTimeLine: {
    position: 'absolute',
    top: dimensions.epg.timeLine.top,
    width: dimensions.epg.timeLine.width,
    height: dimensions.epg.timeLine.height,
    backgroundColor: colors.epgScreen.grid.verticalTimeIndicator.line
  },
  currentTimeLabel: {
    position: 'absolute',
    alignSelf: 'center',
    top: currentTimeMargin,
    backgroundColor: colors.epgScreen.grid.verticalTimeIndicator.line,
    borderRadius: RADIUS,
    paddingHorizontal: dimensions.margins.xsmall
  },
  currentTimeText: {
    color: colors.epgScreen.grid.verticalTimeIndicator.text,
    whiteSpace: 'nowrap'
  }
}));

type Props = {
  offsetForDate: (date: Date) => number;
  nowDate: Date;
  /**
   * Width of the visible portion of the timebar in which the current time bubble is floating. Required for dynamic label position adjustments.
   */
  timeBarWidth?: Reanimated.Adaptable<number>;

  /**
   * Current scroll offset of the timebar in which the current time bubble is floating. Required for dynamic label position adjustments.
   */
  timeBarScrollOffset?: Reanimated.Value<number>;
};

const TimeBarCurrentTime: React.FC<Props> = React.memo(({
  offsetForDate,
  nowDate,
  timeBarWidth,
  timeBarScrollOffset
}) => {
  const styles = stylesUpdater.getStyles();

  const currentTimeContainerStyle: ViewStyle = useMemo(
    () => ({left: offsetForDate(nowDate), ...styles.currentTimeContainerStyle}),
    [nowDate, offsetForDate, styles.currentTimeContainerStyle]
  );

  const [currentTimeLabelWidth, setCurrentTimeLabelWidth] = useState(0);
  const onCurrentTimeLabelLayout = useCallback((event: LayoutChangeEvent) => {
    setCurrentTimeLabelWidth(Math.round(event.nativeEvent.layout.width));
  }, []);

  const currentTimeLabelPosition = useMemo(() => {
    // in case there are no timebar-related parameters simply keep the label centered
    if (typeof timeBarWidth === 'undefined' || typeof timeBarScrollOffset === 'undefined') {
      return new Reanimated.Value(0);
    }
    // otherwise check if there is a need for adjustments by moving the label to the left or right side of the time line
    const center = Math.floor(currentTimeLabelWidth / 2);
    const currentOffset = offsetForDate(nowDate);
    const minOffset = multiply(timeBarScrollOffset, -1);
    const maxOffset = add(multiply(timeBarScrollOffset, -1), timeBarWidth);
    return cond(eq(minOffset, 0),
      0, // keep the label centered when scroll offset is still unknown
      cond(lessThan(currentOffset - center, minOffset),
        center, // move the label to the right side of the time line
        cond(greaterThan(currentOffset + center, maxOffset), -center, 0) // move the label to the left side of the time line or keep is centered
      )
    );
  }, [nowDate, currentTimeLabelWidth, timeBarWidth, timeBarScrollOffset, offsetForDate]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const currentTimeLabelStyle: Reanimated.AnimateStyle<ViewStyle> = useMemo(() => ({
    transform: [
      {translateX: currentTimeLabelPosition},
      ...commonAnimationTransforms
    ],
    ...styles.currentTimeLabel
  }), [currentTimeLabelPosition, styles.currentTimeLabel]);

  const currentTime = useCurrentTime();

  return (
    <View
      style={currentTimeContainerStyle}
    >
      <View
        style={styles.currentTimeLine}
      />
      <Reanimated.View style={currentTimeLabelStyle} onLayout={onCurrentTimeLabelLayout}>
        <NitroxText
          textType='epg-timebar'
          style={styles.currentTimeText}
        >
          {currentTime}
        </NitroxText>
      </Reanimated.View>
    </View>
  );
});
TimeBarCurrentTime.displayName = 'TimeBarCurrentTime';

export default TimeBarCurrentTime;
