import React, {useMemo, useState, useRef, useCallback} from 'react';
import {Text, TextProps, TextStyle, LayoutChangeEvent, View} from 'react-native';
import rnTextSize, {TSMeasureResult} from 'react-native-text-size';

import {font, isPhone, getValue, isAndroid, isWeb, isBigScreen} from 'common/constants';
import {TestProps, WebSubtype} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {useChangeEffect} from 'hooks/Hooks';
import {useLocalized} from 'locales/i18nUtils';

const TAG = 'NitroxText';

export type TextType =
  | 'title'
  | 'title1'
  | 'title2'
  | 'title3'
  | 'title4'
  | 'swimlane-with-higlighted-banner'
  | 'swimlane-title'
  | 'tile-title'
  | 'player-title'
  | 'profile-selector-title'
  | 'headline'
  | 'subhead'
  | 'subhead-medium'
  | 'tile-subhead'
  | 'subhead-bold'
  | 'body'
  | 'body1'
  | 'callout'
  | 'callout-bold'
  | 'callout-small'
  | 'callout-small-bold'
  | 'callout-small-regular'
  | 'callout-small-extra-condensed'
  | 'callout-micro'
  | 'description'
  | 'epg-timebar'
  | 'event-selector-title'
  | 'event-selector-subtitle'
  | 'buttons'
  | 'buttons-minor'
  | 'buttons-settings'
  | 'form-result-message'
  | 'forward-buttons'
  | 'options-texts'
  | 'clock'
  | 'epg-channel-number'
  | 'epg-tile'
  | 'settings-label'
  | 'settings-description'
  | 'tab-selector'
  | 'tab-selector-selected'
  | 'dialog-title'
  | 'dialog-message'
  | 'dialog-message-bold'
  | 'pin-pad'
  | 'pin-pad-message'
  | 'pin-pad-number-button'
  | 'profile-selector-item'
  | 'profile-selector-item-focused'
  | 'inactivity-popup-time-label'
  | 'inactivity-popup-time-separator'
  | 'input'
  | 'input-label'
  | 'input-prompt'
  | 'eas-alert-header'
  | 'eas-alert-callout'
  | 'eas-alert-message'
  | 'detail-action-button'
  | 'poster-tag'
  | 'label'
  | 'label-condensed'
  | 'label-medium'
  | 'disclaimer'
  | 'table-label'
  | 'tooltip'
  | 'lcn'
  | 'channelShortDetailsTitle'
  | 'channelShortDetailsTitleFocused'
  | 'channelShortDetailsMetadata'
  | 'channelShortDetailsMetadataFocused'
  | 'paymentProductTitle'
  | 'paymentProductDescription'
  | 'paymentProductPrice'
  | 'calendar'
  | 'arrowSelector'
  | 'guardTime'
  | 'epgBackTip'
  | 'entitlementLabel'
  | 'settings-subhead'
  | 'settings-subhead-bold'
  | 'side-menu-headline'
  | 'sportTeams';

/**
 * @param trimLine if true, then lineHeight will match fontSize
 */
type TextParams = {
  trimLine?: boolean;
  webSubtype?: WebSubtype;
}

type TextStyles = {
  [id in TextType]: (webSubtype?: WebSubtype) => TextStyle;
};
export const textStyles: TextStyles = {
  title: (webSubtype?: WebSubtype) => ({
    fontFamily: font.bold,
    fontSize: getValue({tablet: 43, mobile: 30, defaultValue: 64}, webSubtype),
    lineHeight: getValue({tablet: 60, mobile: 44, defaultValue: 90}, webSubtype),
    letterSpacing: 1
  }),
  title1: (webSubtype?: WebSubtype) => ({
    fontFamily: font.bold,
    fontSize: getValue({tablet: 28, mobile: 20, defaultValue: 32}, webSubtype),
    lineHeight: getValue({tablet: 40, mobile: 28, defaultValue: 45}, webSubtype),
    letterSpacing: 1
  }),
  title2: (webSubtype?: WebSubtype) => ({
    fontFamily: font.bold,
    fontSize: getValue({tablet: 24, mobile: 18, defaultValue: 26}, webSubtype),
    lineHeight: getValue({tablet: 28, mobile: 25, defaultValue: 28}, webSubtype),
    letterSpacing: 1
  }),
  title3: () => ({
    fontFamily: font.bold,
    fontSize: 16,
    letterSpacing: 0
  }),
  title4: () => ({
    fontFamily: font.bold,
    fontSize: 32,
    lineHeight: 40
  }),
  'swimlane-title': (webSubtype?: WebSubtype) => ({
    fontFamily: font.medium,
    fontSize: getValue({mobile: 18, defaultValue: 36}, webSubtype),
    lineHeight: getValue({mobile: 25, defaultValue: 50}, webSubtype),
    letterSpacing: 0
  }),
  'swimlane-with-higlighted-banner': () => ({
    fontFamily: font.bold,
    fontSize: 48,
    lineHeight: 59
  }),
  'tile-title': (webSubtype?: WebSubtype) => ({
    fontFamily: font.bold,
    fontSize: getValue({mobile: 16, defaultValue: 26}, webSubtype),
    lineHeight: getValue({mobile: 18, defaultValue: 30}, webSubtype),
    letterSpacing: getValue({mobile: 0, defaultValue: 1}, webSubtype)
  }),
  'player-title': (webSubtype?: WebSubtype) => ({
    fontFamily: font.bold,
    fontSize: getValue({tablet: 30, mobile: 30, defaultValue: 64}, webSubtype),
    lineHeight: getValue({tablet: 35, mobile: 35, defaultValue: 90}, webSubtype),
    letterSpacing: 1
  }),
  'profile-selector-title': () => ({
    fontSize: 94,
    fontFamily: font.bold
  }),
  headline: (webSubtype?: WebSubtype) => ({
    fontFamily: font.medium,
    fontSize: getValue({mobile: 18, defaultValue: 36}, webSubtype),
    lineHeight: getValue({mobile: 25, defaultValue: 50}, webSubtype),
    letterSpacing: 0
  }),
  subhead: (webSubtype?: WebSubtype) => ({
    fontFamily: font.regular,
    fontSize: getValue({tablet: 20, mobile: 15, defaultValue: 26}, webSubtype),
    letterSpacing: 0
  }),
  'subhead-bold': (webSubtype?: WebSubtype) => ({
    fontFamily: font.bold,
    fontSize: getValue({tablet: 20, mobile: 15, defaultValue: 26}, webSubtype),
    letterSpacing: 0
  }),
  'subhead-medium': () => ({
    fontFamily: font.medium,
    fontSize: 26,
    letterSpacing: 0
  }),
  'tile-subhead': (webSubtype?: WebSubtype) => ({
    fontFamily: font.bold,
    fontSize: getValue({mobile: 11, defaultValue: 18}, webSubtype),
    letterSpacing: 0
  }),
  body: (webSubtype?: WebSubtype) => ({
    fontFamily: font.regular,
    fontSize: getValue({tablet: 18, mobile: 15, defaultValue: 26}, webSubtype),
    lineHeight: getValue({tablet: 24, mobile: 20, defaultValue: 38}, webSubtype),
    letterSpacing: 0
  }),
  body1: (webSubtype?: WebSubtype) => ({
    fontFamily: font.regular,
    fontSize: getValue({tablet: 15, mobile: 13, defaultValue: 26}, webSubtype),
    lineHeight: getValue({tablet: 20, mobile: 18, defaultValue: 38}, webSubtype),
    letterSpacing: 0
  }),
  callout: (webSubtype?: WebSubtype) => ({
    fontFamily: font.regular,
    fontSize: getValue({tablet: 15, mobile: 13, defaultValue: 22}, webSubtype),
    lineHeight: getValue({tablet: 20, mobile: 18, defaultValue: 30}, webSubtype),
    letterSpacing: 0
  }),
  'callout-bold': (webSubtype?: WebSubtype) => ({
    fontFamily: font.bold,
    fontSize: getValue({tablet: 15, mobile: 13, defaultValue: 22}, webSubtype),
    lineHeight: getValue({tablet: 20, mobile: 18, defaultValue: 30}, webSubtype),
    letterSpacing: 0
  }),
  'callout-small': (webSubtype?: WebSubtype) => ({
    fontFamily: font.regular,
    fontSize: getValue({mobile: 11, defaultValue: 18}, webSubtype),
    lineHeight: getValue({mobile: 16, defaultValue: 26}, webSubtype),
    letterSpacing: 0
  }),
  'callout-small-bold': (webSubtype?: WebSubtype) => ({
    fontFamily: font.bold,
    fontSize: getValue({mobile: 11, defaultValue: 18}, webSubtype),
    lineHeight: getValue({mobile: 16, defaultValue: 26}),
    letterSpacing: 0
  }),
  'callout-small-regular': (webSubtype?: WebSubtype) => ({
    fontFamily: font.regular,
    fontSize: getValue({tablet: 18, mobile: 11, defaultValue: 22}, webSubtype),
    lineHeight: getValue({tablet: 22, mobile: 13, defaultValue: 30}, webSubtype),
    letterSpacing: 0
  }),
  'callout-small-extra-condensed': (webSubtype?: WebSubtype) => ({
    fontFamily: font.extraCondensed,
    fontSize: getValue({mobile: 11, defaultValue: 18}, webSubtype),
    letterSpacing: 0
  }),
  'callout-micro': (webSubtype?: WebSubtype) => ({
    fontFamily: font.bold,
    fontSize: getValue({mobile: 7, defaultValue: 12}, webSubtype),
    letterSpacing: 0
  }),
  'description': (webSubtype?: WebSubtype) => ({
    fontFamily: font.regular,
    fontSize: getValue({mobile: 15, defaultValue: 32}, webSubtype)
  }),
  'form-result-message': (webSubtype?: WebSubtype) => ({
    fontFamily: font.regular,
    fontSize: getValue({mobile: 15, defaultValue: 26}, webSubtype),
    lineHeight: getValue({mobile: 20, defaultValue: 30}, webSubtype)
  }),
  'epg-timebar': (webSubtype?: WebSubtype) => ({
    fontFamily: isPhone ? font.regular : font.semiBold,
    fontSize: getValue({mobile: 15, defaultValue: 26}, webSubtype),
    lineHeight: 30
  }),
  'event-selector-title': () => ({
    fontFamily: font.bold,
    fontSize: 18,
    letterSpacing: 0
  }),
  'event-selector-subtitle': (webSubtype?: WebSubtype) => ({
    fontFamily: font.regular,
    fontSize: getValue({mobile: 13, defaultValue: 18}, webSubtype),
    letterSpacing: 0,
    fontVariant: getValue({mobile: [], defaultValue: ['small-caps']}, webSubtype)
  }),
  'options-texts': () => ({
    fontFamily: font.regular,
    fontSize: 18,
    letterSpacing: 0
  }),
  buttons: (webSubtype?: WebSubtype) => ({
    fontFamily: getValue({mobile: font.regular, defaultValue: font.medium}, webSubtype),
    fontSize: 18,
    letterSpacing: 0
  }),
  'buttons-minor': (webSubtype?: WebSubtype) => ({
    fontFamily: getValue({mobile: font.regular, defaultValue: font.medium}, webSubtype),
    fontSize: getValue({mobile: 15, defaultValue: 18}, webSubtype),
    lineHeight: 18
  }),
  'buttons-settings': (webSubtype?: WebSubtype) => ({
    fontFamily: font.regular,
    fontSize: getValue({tablet: 15, mobile: 13, defaultValue: 22}, webSubtype),
    lineHeight: getValue({tablet: 20, mobile: 18, defaultValue: 30}, webSubtype),
    letterSpacing: 0
  }),
  'forward-buttons': (webSubtype?: WebSubtype) => ({
    fontFamily: getValue({mobile: font.regular, defaultValue: font.medium}, webSubtype),
    fontSize: getValue({mobile: 13, defaultValue: 18}, webSubtype),
    letterSpacing: 0
  }),
  clock: () => ({
    fontFamily: font.light,
    fontSize: 43,
    lineHeight: 61,
    letterSpacing: 0
  }),
  'epg-channel-number': (webSubtype?: WebSubtype) => ({
    fontFamily: font.regular,
    fontSize: getValue({mobile: 18, defaultValue: 32}, webSubtype),
    letterSpacing: 0
  }),
  'epg-tile': (webSubtype?: WebSubtype) => ({
    fontFamily: font.regular,
    fontSize: getValue({mobile: 20, defaultValue: 32}, webSubtype),
    lineHeight: getValue({mobile: 24, defaultValue: 40}, webSubtype)
  }),
  'settings-label': (webSubtype?: WebSubtype) => ({
    fontFamily: getValue({mobile: font.regular, defaultValue: font.bold}, webSubtype),
    fontSize: getValue({mobile: 18, defaultValue: 26}, webSubtype),
    lineHeight: isBigScreen ? 30 : undefined
  }),
  'settings-description': (webSubtype?: WebSubtype) => ({
    fontFamily: font.regular,
    fontSize: getValue({mobile: 11, defaultValue: 22}, webSubtype),
    lineHeight: getValue({mobile: 16, defaultValue: 30}, webSubtype)
  }),
  'tab-selector': () => ({
    fontFamily: font.regular,
    fontSize: 18,
    letterSpacing: 0,
    lineHeight: 40
  }),
  'tab-selector-selected': () => ({
    fontFamily: font.bold,
    fontSize: 18,
    letterSpacing: 0,
    lineHeight: 40
  }),
  'dialog-title': (webSubtype?: WebSubtype) => ({
    fontFamily: font.bold,
    fontSize: getValue({mobile: 24, defaultValue: 32}, webSubtype),
    lineHeight: getValue({mobile: 27, defaultValue: 46}, webSubtype)
  }),
  'dialog-message': (webSubtype?: WebSubtype) => ({
    fontFamily: font.regular,
    fontSize: getValue({mobile: 18, defaultValue: 30}, webSubtype),
    lineHeight: getValue({mobile: 20, defaultValue: 31}, webSubtype)
  }),
  'dialog-message-bold': (webSubtype?: WebSubtype) => ({
    fontFamily: font.bold,
    fontSize: getValue({mobile: 18, defaultValue: 26}, webSubtype),
    lineHeight: getValue({mobile: 20, defaultValue: 31}, webSubtype)
  }),
  'pin-pad': () => ({
    fontFamily: font.medium,
    fontSize: 18,
    letterSpacing: 0
  }),
  'pin-pad-message': () => ({
    fontFamily: font.regular,
    fontSize: 13,
    letterSpacing: 0
  }),
  'pin-pad-number-button': (webSubtype?: WebSubtype) => ({
    fontFamily: getValue({mobile: font.regular, defaultValue: font.bold}, webSubtype),
    fontSize: getValue({mobile: 38, defaultValue: 64}, webSubtype),
    lineHeight: Math.round(1.2 * getValue({mobile: 38, defaultValue: 64}, webSubtype))
  }),
  'profile-selector-item': (webSubtype?: WebSubtype) => ({
    fontFamily: font.medium,
    fontSize: getValue({mobile: 18, defaultValue: 26}, webSubtype),
    lineHeight: getValue({mobile: 25, defaultValue: 31}, webSubtype),
    letterSpacing: 0
  }),
  'profile-selector-item-focused': () => ({
    fontFamily: font.bold,
    fontSize: 42,
    lineHeight: 51,
    letterSpacing: 0
  }),
  'inactivity-popup-time-label': webSubtype => ({
    fontFamily: font.regular,
    fontSize: getValue({defaultValue: 100}, webSubtype),
    lineHeight: getValue({defaultValue: 119}, webSubtype)
  }),
  'inactivity-popup-time-separator': webSubtype => ({
    fontFamily: font.regular,
    fontSize: getValue({defaultValue: 60}, webSubtype),
    lineHeight: getValue({defaultValue: 72}, webSubtype)
  }),
  input: (webSubtype?: WebSubtype) => ({
    fontFamily: font.medium,
    fontSize: getValue({mobile: 18, defaultValue: 26}, webSubtype),
    letterSpacing: 1
  }),
  'input-label': (webSubtype?: WebSubtype) => ({
    fontFamily: font.regular,
    fontSize: getValue({mobile: 18, defaultValue: 28}, webSubtype),
    lineHeight: getValue({mobile: 22, defaultValue: 33}, webSubtype)
  }),
  'input-prompt': (webSubtype?: WebSubtype) => ({
    fontFamily: getValue({mobile: font.regular, defaultValue: font.bold}, webSubtype),
    fontSize: getValue({tablet: 15, mobile: 13, defaultValue: 22}, webSubtype),
    lineHeight: getValue({tablet: 20, mobile: 18, defaultValue: 30}, webSubtype),
    letterSpacing: 0
  }),
  'eas-alert-header': (webSubtype?: WebSubtype) => ({
    fontFamily: font.bold,
    fontSize: getValue({mobile: 24, defaultValue: 64}, webSubtype),
    lineHeight: getValue({mobile: 27, defaultValue: 90}, webSubtype),
    letterSpacing: 0
  }),
  'eas-alert-callout': (webSubtype?: WebSubtype) => ({
    fontFamily: font.regular,
    fontSize: getValue({mobile: 18, defaultValue: 26}, webSubtype),
    lineHeight: getValue({mobile: 20, defaultValue: 31}, webSubtype),
    letterSpacing: 0
  }),
  'eas-alert-message': (webSubtype?: WebSubtype) => ({
    fontFamily: font.regular,
    fontSize: getValue({mobile: 24, defaultValue: 40}, webSubtype),
    lineHeight: getValue({mobile: 27, defaultValue: 52}, webSubtype),
    letterSpacing: 0
  }),
  'detail-action-button': (webSubtype?: WebSubtype) => ({
    fontFamily: font.regular,
    fontSize: getValue({tablet: 14, mobile: 12, defaultValue: 22}, webSubtype),
    lineHeight: getValue({tablet: 18, mobile: 17, defaultValue: 30}, webSubtype),
    letterSpacing: 0
  }),
  'poster-tag': (webSubtype?: WebSubtype) => ({
    fontFamily: font.bold,
    fontSize: getValue({mobile: 9, defaultValue: 12}, webSubtype),
    lineHeight: getValue({mobile: 11, defaultValue: 15}, webSubtype)
  }),
  'label': (webSubtype?: WebSubtype) => ({
    fontFamily: font.regular,
    fontSize: getValue({tablet: 15, mobile: 13, defaultValue: 30}, webSubtype),
    lineHeight: getValue({tablet: 20, mobile: 18, defaultValue: 36}, webSubtype),
    letterSpacing: 0
  }),
  'label-condensed': (webSubtype?: WebSubtype) => ({
    fontFamily: font.regular,
    fontSize: getValue({tablet: 15, mobile: 11, defaultValue: 26}, webSubtype),
    lineHeight: getValue({tablet: 18, mobile: 13, defaultValue: 31}, webSubtype),
    letterSpacing: getValue({tablet: -0.2, mobile: -0.3, defaultValue: 0}, webSubtype)
  }),
  'label-medium': () => ({
    fontFamily: font.medium,
    fontSize: 30,
    lineHeight: 36
  }),
  'disclaimer': (webSubtype?: WebSubtype) => ({
    fontFamily: font.regular,
    fontSize: getValue({mobile: 11, defaultValue: 24}, webSubtype),
    lineHeight: getValue({mobile: 16, defaultValue: 30}, webSubtype),
    letterSpacing: 0
  }),
  'table-label': () => ({
    fontFamily: font.bold,
    fontSize: 14,
    lineHeight: 20
  }),
  'tooltip': () => ({
    fontFamily: font.regular,
    fontSize: 26,
    letterSpacing: 0
  }),
  'lcn': () => ({
    fontFamily: font.medium,
    fontSize: 28,
    lineHeight: 33
  }),
  'channelShortDetailsTitle': () => ({
    fontFamily: font.light,
    fontSize: 32,
    lineHeight: 38
  }),
  'channelShortDetailsTitleFocused': () => ({
    fontFamily: font.light,
    fontSize: 44,
    lineHeight: 53
  }),
  'channelShortDetailsMetadata': () => ({
    fontFamily: font.medium,
    fontSize: 20,
    lineHeight: 29
  }),
  'channelShortDetailsMetadataFocused': () => ({
    fontFamily: font.medium,
    fontSize: 22,
    lineHeight: 32
  }),
  'paymentProductTitle': (webSubtype?: WebSubtype) => ({
    fontFamily: font.bold,
    fontSize: getValue({mobile: 30, defaultValue: 26}, webSubtype),
    lineHeight: getValue({mobile: 32, defaultValue: 32}, webSubtype),
    letterSpacing: 1
  }),
  'paymentProductDescription': (webSubtype?: WebSubtype) => ({
    fontFamily: font.regular,
    fontSize: getValue({mobile: 18, defaultValue: 21}, webSubtype),
    lineHeight: getValue({mobile: 22, defaultValue: 26}, webSubtype),
    letterSpacing: 0
  }),
  'paymentProductPrice': (webSubtype?: WebSubtype) => ({
    fontFamily: font.bold,
    fontSize: getValue({mobile: 30, defaultValue: 32}, webSubtype),
    lineHeight: getValue({mobile: 43, defaultValue: 46}, webSubtype),
    letterSpacing: 1
  }),
  'calendar': (webSubtype?: WebSubtype) => ({
    fontFamily: font.bold,
    fontSize: getValue({tablet: 15, mobile: 13, defaultValue: 22}, webSubtype),
    lineHeight: getValue({tablet: 20, mobile: 18, defaultValue: 30}, webSubtype),
    letterSpacing: 0
  }),
  'arrowSelector': (webSubtype?: WebSubtype) => ({
    fontFamily: font.regular,
    fontSize: getValue({mobile: 18, defaultValue: 26}, webSubtype)
  }),
  'guardTime': (webSubtype?: WebSubtype) => ({
    fontFamily: font.medium,
    fontSize: getValue({mobile: 18, defaultValue: 26}, webSubtype)
  }),
  'entitlementLabel': (webSubtype?: WebSubtype) => ({
    fontFamily: font.regular,
    fontSize: getValue({tablet: 15, mobile: 11, defaultValue: 26}, webSubtype),
    lineHeight: getValue({tablet: 18, mobile: 13, defaultValue: 31}, webSubtype),
    letterSpacing: getValue({tablet: -0.2, mobile: -0.3, defaultValue: 0}, webSubtype)
  }),
  'epgBackTip': () => ({
    fontFamily: font.bold,
    fontSize: 20,
    lineHeight: 24
  }),
  'settings-subhead': (webSubtype?: WebSubtype) => ({
    fontFamily: font.regular,
    fontSize: getValue({mobile: 13, defaultValue: 24}, webSubtype),
    lineHeight: getValue({mobile: 18, defaultValue: 30}, webSubtype)
  }),
  'settings-subhead-bold': (webSubtype?: WebSubtype) => ({
    fontFamily: font.bold,
    fontSize: getValue({mobile: 13, defaultValue: 24}, webSubtype),
    lineHeight: getValue({mobile: 18, defaultValue: 30}, webSubtype)
  }),
  'side-menu-headline': (webSubtype?: WebSubtype) => ({
    fontFamily: font.medium,
    fontSize: getValue({mobile: 18, defaultValue: 36}, webSubtype),
    lineHeight: getValue({mobile: 25, defaultValue: 40}, webSubtype)
  }),
  'sportTeams': (webSubtype?: WebSubtype) => ({
    fontFamily: font.bold,
    fontSize: getValue({mobile: 15, defaultValue: 32}, webSubtype),
    lineHeight: getValue({mobile: 20, defaultValue: 39}, webSubtype)
  })
};

export const getFontStyle = (type: TextType = 'body', params?: TextParams): TextStyle => {
  const style = textStyles[type](params?.webSubtype);
  if (!style) {
    Log.error(TAG, `Cannot return font style for type: ${type}`);
    return {};
  }
  return {
    ...style,
    ...params?.trimLine && {lineHeight: style.fontSize}
  };
};

export type NitroxTextProps = {
  textType?: TextType;
  children?: React.ReactChild | string | object | undefined;
  handleEllipsize?: boolean;
  /**
   * This is inadvisible and should only be used in special cases like tvOS text input workaround.
   */
  overrideFontStyle?: boolean;
  /**
   * Set to true if the displayed text should be displayed in upper case.
   * The transformation is done using i18n.
   */
  upperCase?: boolean;
  /**
   * Plaftorm based styles will be determined using `webSubtype` if provided on web platform.
   * Use to implement responsive text.
   */
  webSubtype?: WebSubtype;
} & TextProps & TextParams & TestProps;

const NitroxBaseText: React.FunctionComponent<NitroxTextProps> = ({
  textType,
  trimLine,
  overrideFontStyle,
  webSubtype,
  ...props
}) => {
  const fontStyle = useMemo(() => getFontStyle(textType, {trimLine, webSubtype}), [textType, trimLine, webSubtype]);

  // Setting fontFamily, fontSize, fontWeight, fontStyle, letterSpacing and lineHeight in style is inadvisable. Use fontWeight and italic props.
  return (
    // eslint-disable-next-line schange-rules/local-alternative
    <Text
      allowFontScaling={false} //TODO: to be removed in scope of CL-1681
      textBreakStrategy='simple' // this parameter is required by react-native-text-size to measure text properly, when not set sometimes lines are not measured as expected
      {...props}
      style={
        overrideFontStyle
          ? [fontStyle, props.style]
          : [props.style, fontStyle]
      }
      testID={props.testID}
    >
      {props.children}
    </Text>
  );
};

export type NitroxEllipsizedTextProps = NitroxTextProps & {
  children: string;
  numberOfLines: number;
}

const NitroxEllipsizedText: React.FunctionComponent<NitroxEllipsizedTextProps> = props => {
  const {children, numberOfLines, textType} = props;
  const [containerWidth, setContainerWidth] = useState(0);
  const [displayedText, setDisplayedText] = useState('');
  const latestMeasurementTimestamp = useRef(0);

  const onContainerLayout = useCallback((event: LayoutChangeEvent) => {
    setContainerWidth(event.nativeEvent.layout.width);
  }, []);

  const setDisplayedTextForLatestMeasurement = useCallback((text: string, timestamp: number) => {
    if (timestamp === latestMeasurementTimestamp.current) {
      setDisplayedText(text);
    }
  }, []);

  const measureText = useCallback((text: string, width: number): Promise<TSMeasureResult> => {
    return rnTextSize.measure({
      text,
      textBreakStrategy: 'simple',
      lineInfoForLine: numberOfLines - 1,
      ...textStyles[textType || 'body'](),
      width: width
    });
  }, [numberOfLines, textType]);

  const ellipsizeSentence = useCallback(async (text: string, measurementTimestamp: number): Promise<string> => {
    if (measurementTimestamp !== latestMeasurementTimestamp.current) {
      return '';
    }
    const trimmedText = text.trim().replace(/[^a-zA-Z0-9]*$/g, '');
    const ellipsizedText = trimmedText + '...';
    const {lineCount} = await measureText(ellipsizedText, containerWidth);
    if (lineCount > numberOfLines) {
      return ellipsizeSentence(trimmedText.slice(0, trimmedText.length - 1), measurementTimestamp);
    } else {
      return ellipsizedText;
    }
  }, [containerWidth, measureText, numberOfLines]);

  useChangeEffect(() => {
    const timestamp = Date.now();
    latestMeasurementTimestamp.current = timestamp;
    measureText(children, containerWidth)
      .then((info) => {
        if (info.lineCount <= numberOfLines) {
          setDisplayedTextForLatestMeasurement(children, timestamp);
          return;
        }
        return ellipsizeSentence((children).substring(0, info?.lineInfo?.end), timestamp)
          .then((result) => setDisplayedTextForLatestMeasurement(result, timestamp));
      })
      .catch((e) => {
        Log.error(TAG, `Measuring text failed, using NitroxBaseText - error: ${e}`);
        setDisplayedTextForLatestMeasurement(children, timestamp);
      });
  }, [children, containerWidth]);

  return (
    <View onLayout={onContainerLayout}>
      <NitroxBaseText {...props}>{displayedText}</NitroxBaseText>
    </View>
  );
};

const NitroxText: React.FunctionComponent<NitroxTextProps> = (props) => {
  const {numberOfLines, handleEllipsize, upperCase, webSubtype, ...otherProps} = props;
  const {toUpperCase} = useLocalized();

  let children = props.children;
  if (upperCase) {
    if (typeof props.children === 'string') {
      children = toUpperCase(props.children as string); // Oh come on TS, really? You know nothing...
    } else if (Array.isArray(props.children)) {
      children = props.children.map(child => typeof child === 'string' ? toUpperCase(child) : child);
    }
  }

  if (isAndroid) {
    if (numberOfLines && handleEllipsize && typeof children === 'string' && (children as string).length) {
      return <NitroxEllipsizedText {...otherProps} numberOfLines={numberOfLines}>{children as string}</NitroxEllipsizedText>;
    }
  } else if (isWeb) {
    return <NitroxBaseText {...otherProps} numberOfLines={numberOfLines} webSubtype={webSubtype}>{children}</NitroxBaseText>;
  }
  return <NitroxBaseText {...props}>{children}</NitroxBaseText>;
};

export default React.memo(NitroxText);
