import {createStyles} from 'common-styles';
import React, {useState, useMemo, useCallback, useRef} from 'react';
import {ActivityIndicator, View, Insets, StyleProp, ViewStyle} from 'react-native';

import {dimensions, isDesktopBrowser, homescreenPromotionalBannerOverlap} from 'common/constants';
import {Log} from 'common/Log';

import {ComponentType as UXMComponentType} from 'mw/api/CMSInterface';
import {Component as UXMComponent} from 'mw/cms/Component';
import {Link, Menu} from 'mw/cms/Menu';
import {mw} from 'mw/MW';

import {swimlaneItemHeight, defaultSwimlaneInsets} from 'components/AnimatedSwimlaneStack';
import AnimatedVerticalStack, {AnimatedVerticalStackRowProps} from 'components/AnimatedVerticalStack';
import {swimlaneHeaderHeight} from 'components/Swimlane';
import {swimlaneItemHeight as swimlaneWithHighlightedBannerHeight} from 'components/SwimlaneWithHighlightedBanner';
import {PromotionalBannerProps, PromotionalBannerMode, framedPromotionalBannerHeight} from 'components/vod/promotionalBanner/PromotionalBanner.shared';
import {useLazyEffect, useDisposable, getScreenInfo} from 'hooks/Hooks';

import {Hotspot, Grid, isHotspot, isGrid, UMXStackComponent, CustomComponentType, UXMComponentStackCustomComponent, UXMComponentPlacement} from './UMXComponentStack.shared';
import UXMComponentStackRow, {UXMComponentStackRowInterface} from './UXMComponentStackRow';

const defaultSwimlaneHeight = swimlaneItemHeight + defaultSwimlaneInsets.top + defaultSwimlaneInsets.bottom + swimlaneHeaderHeight;
const bottomMargin = dimensions.margins.xxxLarge;

function heightForComponent(component: UMXStackComponent, promoBannerProps?: Partial<PromotionalBannerProps>): number {
  switch (component.type) {
    case UXMComponentType.SwimlaneWithHighlightedBanner:
      return isDesktopBrowser
        ? defaultSwimlaneHeight
        : swimlaneWithHighlightedBannerHeight + defaultSwimlaneInsets.top + defaultSwimlaneInsets.bottom + swimlaneHeaderHeight;
    case UXMComponentType.SwimlaneMenu:
      return dimensions.menuTile.tileHeight + defaultSwimlaneInsets.top + defaultSwimlaneInsets.bottom + swimlaneHeaderHeight;
    case UXMComponentType.Hotspot:
      switch (promoBannerProps?.mode) {
        case PromotionalBannerMode.desktopFullScreen:
          return getScreenInfo().size.height - homescreenPromotionalBannerOverlap;
        case PromotionalBannerMode.fullWidth:
        // not supported
        case PromotionalBannerMode.framed:
        default:
          return framedPromotionalBannerHeight + bottomMargin;
      }
    case CustomComponentType.breadcrumbs:
      return (component.props.category?.children.length ? 175 : dimensions.buttons.standard) + bottomMargin;
    case CustomComponentType.queryParameters:
      return dimensions.buttons.standard + bottomMargin;
    default:
      return defaultSwimlaneHeight;
  }
}

const styles = createStyles({
  activityIndicatorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  componentsContainer: {
    flex: 1
  }
});

type Props = {
  debugName: string;
  focusOnAppear?: boolean;
  link: Link;
  topInset?: number | ((hasPromoBanner: boolean) => number);
  firstNonEmptyRowCustomOffset?: number;
  rowInsets?: Insets;
  containerStyle?: StyleProp<ViewStyle>;
  customComponents?: UXMComponentStackCustomComponent[];
  promotionalBannerProps?: Partial<PromotionalBannerProps>;
  onReady?: () => void;
  onFocusChange?: (row: number, index: number) => void;
  onFetchedHotspot?: (hotspot: Hotspot) => void;
  onFetchedGrid: (grid: Grid) => void;
  onMenuPress?: (menu: Menu) => void;
}

/**
 * Component that displays UXM Page's child components as rows in a vertical list.
 */
const UXMComponentStack: React.FC<Props> = React.memo(({
  debugName,
  focusOnAppear,
  link,
  topInset: topInsetProps,
  firstNonEmptyRowCustomOffset,
  rowInsets,
  containerStyle,
  customComponents,
  promotionalBannerProps,
  onReady,
  onFocusChange,
  onFetchedHotspot,
  onFetchedGrid,
  onMenuPress
}) => {
  const tag = useMemo(() => `UXMComponentStack - ${debugName}`, [debugName]);
  const [fetching, setFetching] = useState(false);
  const [fetchedComponents, setFetchedComponents] = useState<UXMComponent[]>([]);
  const [components, setComponents] = useState<UMXStackComponent[]>([]);
  const componentRefs = useRef<{[row: number]: UXMComponentStackRowInterface}>({}).current;
  const getUXMPage = useDisposable((link: Link) => mw.cms.getPage(link));

  const isPromoBannerAvailable = useMemo<boolean>(() => !!components.find(isHotspot), [components]);

  const prepareComponents = useCallback((uxmComponents: UXMComponent[], customComponents?: UXMComponentStackCustomComponent[]): UMXStackComponent[] => {
    const components: UMXStackComponent[] = [...uxmComponents];
    const hotspotIndex = components.findIndex(isHotspot);
    customComponents?.forEach(customComponent => {
      switch (customComponent.placement) {
        case UXMComponentPlacement.afterBreadcrumbs:
          const breadcrumbsIndex = components.findIndex(component => component.type === CustomComponentType.breadcrumbs);
          if (breadcrumbsIndex !== -1) {
            components.splice(breadcrumbsIndex + 1, 0, customComponent);
            break;
          }
        // if no breadcrumbs fallback to UXMComponentPlacement.afterPromoBanner
        case UXMComponentPlacement.afterPromoBanner:
          components.splice(hotspotIndex !== -1 ? hotspotIndex + 1 : 0, 0, customComponent);
          break;
        default:
          if (typeof customComponent.placement === 'number') {
            components.splice(customComponent.placement, 0, customComponent);
          }
          break;
      }
    });
    if (onFetchedHotspot) {
      return components.filter(component => component.type !== UXMComponentType.Hotspot);
    } else {
      if (hotspotIndex !== -1) {
        components.unshift(components[hotspotIndex]);
        components.splice(hotspotIndex + 1, 1);
      }
      return components;
    }
  }, [onFetchedHotspot]);

  useLazyEffect(() => {
    setComponents(prepareComponents(fetchedComponents, customComponents));
  }, [fetchedComponents, customComponents], [prepareComponents]);

  useLazyEffect(() => {
    setFetching(true);
    setComponents([]);

    getUXMPage(link)
      .then(page => {
        const components = page.componentGroup[0]?.components;
        if (!components?.length) {
          Log.error(tag, `No components for page "${page.slug}"!`);
          return;
        }
        Log.info(tag, `Fetched UXM components for page ${page.slug}:`, components);
        const hotspot = components.find(isHotspot);
        if (hotspot && onFetchedHotspot) {
          onFetchedHotspot(hotspot);
        }
        if (components.length > 1) {
          components.forEach(component => {
            if (component.type === UXMComponentType.Grid) {
              // display grids as swimlanes if there is more than one component on page
              component.type = UXMComponentType.Swimlane;
            }
            if (component.type === UXMComponentType.GridMenu) {
              // display menu grids as menu swimlanes if there is more than one component on page
              component.type = UXMComponentType.SwimlaneMenu;
            }
          });
        } else if (components.length === 1) {
          const component = components[0];
          if (isGrid(component)) {
            onFetchedGrid(component);
            return;
          }
        }
        setFetchedComponents(components);
      })
      .catch(error => {
        Log.error(tag, `Error fetching page "${link.slug}":`, error);
      })
      .finally(() => {
        setFetching(false);
      });
  }, [link], [getUXMPage]);

  const stackRows = useMemo(() => {
    return components.map((component, index) => {
      return {
        id: `uxmcomponent_${index}_${component.title}_${component.type}`,
        height: heightForComponent(component, component.type === UXMComponentType.Hotspot ? promotionalBannerProps : undefined)
      };
    });
  }, [components, promotionalBannerProps]);

  const navigationHandlers = useMemo(() => ({
    onPressOK: (row: number) => componentRefs[row]?.onPress(),
    onNavigateLeft: (row: number) => componentRefs[row]?.onNavigateLeft(),
    onNavigateRight: (row: number) => componentRefs[row]?.onNavigateRight(),
    onNavigateUp: (row: number) => componentRefs[row]?.onNavigateUp() ?? {shouldStay: false},
    onNavigateDown: (row: number) => componentRefs[row]?.onNavigateDown() ?? {shouldStay: false}
  }), [componentRefs]);

  const topInset = useMemo(() => typeof topInsetProps === 'function' ? topInsetProps(isPromoBannerAvailable) : topInsetProps, [topInsetProps, isPromoBannerAvailable]);

  const renderUXMComponentRow = useCallback(({
    index,
    ...props
  }: AnimatedVerticalStackRowProps) => {
    return (
      <UXMComponentStackRow
        index={index}
        ref={(ref: UXMComponentStackRowInterface) => {
          componentRefs[index] = ref;
        }}
        debugName={stackRows[index].id}
        component={components[index]}
        onMenuPress={onMenuPress}
        insets={rowInsets}
        promotionalBannerProps={isHotspot(components[index]) ? promotionalBannerProps : undefined}
        {...props}
      />
    );
  }, [stackRows, components, onMenuPress, rowInsets, componentRefs, promotionalBannerProps]);

  if (components.length === 1 && isGrid(components[0])) {
    return null;
  }

  return fetching
    ? (
      <View style={styles.activityIndicatorContainer}>
        <ActivityIndicator animating />
      </View>
    ) : (
      <AnimatedVerticalStack
        focusOnAppear={focusOnAppear}
        topInset={topInset}
        firstNonEmptyRowCustomOffset={firstNonEmptyRowCustomOffset}
        rows={stackRows}
        containerStyle={containerStyle}
        renderRow={renderUXMComponentRow}
        onReady={onReady}
        onFocusChange={onFocusChange}
        {...navigationHandlers}
      />
    );
});
UXMComponentStack.displayName = 'UXMComponentStack';

export default UXMComponentStack;
