import React from 'react';
import {useTranslation} from 'react-i18next';

import DeviceRegistrationErrorPopup from './DeviceRegistrationErrorPopup';

type Props = {
  visible: boolean;
  logout: () => void;
  showDeviceManagement: () => void;
};

const TooManyDevicesPopup: React.FunctionComponent<Props> = props => {
  const {t} = useTranslation();
  return (
    <DeviceRegistrationErrorPopup
      title={t('tooManyDevicesPopup.title')}
      message={t('tooManyDevicesPopup.message')}
      actionButtonMessage={t('tooManyDevicesPopup.devicesButton')}
      action={props.showDeviceManagement}
      {...props}
    />
  );
};

export default React.memo(TooManyDevicesPopup);
