import {createStyles} from 'common-styles';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ListRenderItemInfo, View} from 'react-native';

import {dimensions, isBigScreen, RADIUS} from 'common/constants';
import {indexKeyExtractor} from 'common/HelperFunctions';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import FocusParent from 'components/FocusParent';
import {IconType} from 'components/Icon';
import NitroxButton, {ButtonIconProps, NitroxButtonProps} from 'components/NitroxButton';
import NitroxFlatList from 'components/NitroxFlatList';
import NitroxText from 'components/NitroxText';

const separatorWidth = isBigScreen ? dimensions.margins.xxLarge : dimensions.margins.small;
const searchPhrasesLimit = 5;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    marginLeft: isBigScreen ? 0 : dimensions.margins.large,
    marginTop: isBigScreen ? dimensions.margins.xxxLarge : 0
  },
  searchHistoryContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    ...isBigScreen && {width: dimensions.inputs.width.search}
  },
  button: {
    height: dimensions.buttons.small,
    ...!isBigScreen && {
      alignSelf: 'flex-start',
      backgroundColor: colors.searchScreen.mobile.button.background,
      borderRadius: RADIUS
    }
  },
  buttonText: {
    color: colors.searchScreen.phrase.text
  },
  label: {
    color: colors.searchScreen.history
  },
  iconContainer: {
    backgroundColor: colors.searchScreen.iconBackground
  },
  separator: {
    width: separatorWidth
  },
  clearSearchHistoryIconColor: colors.searchScreen.phrase.text
}));

const SearchHistoryTitle: React.FC = () => {
  const {t} = useTranslation();
  const styles = stylesUpdater.getStyles();

  return (
    <NitroxText textType='headline' style={styles.label}>{t('search.history')}</NitroxText>
  );
};

const SearchPhraseItem = (props: NitroxButtonProps) => {
  const styles = stylesUpdater.getStyles();
  return (
    <NitroxButton
      style={styles.button}
      textStyle={styles.buttonText}
      textType={'subhead'}
      textTypeFocused={'subhead-bold'}
      {...props}
    />
  );
};

type Props = {
  visible: boolean
  onClearPress?: () => void;
  onSearchPhrasePress: (phrase: string) => void;
};

type State = {
  searchPhrases: string[];
};

export class SearchHistory extends React.Component<Props, State> {

  public static defaultProps: Partial<Props> = {
    visible: true
  };

  public constructor(props: Props) {
    super(props);
    this.state = {
      searchPhrases: []
    };
  }

  private clearHistory = () => {
    this.props.onClearPress?.();
    this.setState({searchPhrases: []});
  };

  private renderSeparator = () => {
    const styles = stylesUpdater.getStyles();
    return <View style={styles.separator} />;
  };

  private renderItem = (info: ListRenderItemInfo<string>) => {
    return (
      <SearchPhraseItem
        onPress={() => this.props.onSearchPhrasePress(info.item)}
        text={info.item}
      />
    );
  };

  private clearSearchHistoryIcon: ButtonIconProps = {
    type: IconType.ClearSearch,
    size: dimensions.icon.xxxsmall
  };

  public addSearchPhrase(phrase: string) {
    this.setState(prevState => {
      const searchPhrases = [...prevState.searchPhrases];
      if (searchPhrases.length > 0) {
        const similarPhaseIndex = searchPhrases.findIndex(searchPhrase => phrase.includes(searchPhrase));
        if (similarPhaseIndex !== -1) {
          searchPhrases.splice(similarPhaseIndex, 1);
        } else if (searchPhrases.length === searchPhrasesLimit) {
          searchPhrases.splice(0, 1);
        }
      }

      searchPhrases.push(phrase);
      return {searchPhrases};
    });
  }

  public render() {
    if (!this.props.visible || this.state.searchPhrases.length === 0) {
      return null;
    }

    const styles = stylesUpdater.getStyles();
    return (
      <FocusParent style={styles.container} enterStrategy={'topLeft'}>
        <SearchHistoryTitle />
        <View
          style={styles.searchHistoryContainer}
          testID={'search_history'}
        >
          <NitroxFlatList
            horizontal
            data={this.state.searchPhrases}
            renderItem={this.renderItem}
            keyExtractor={indexKeyExtractor}
            ItemSeparatorComponent={this.renderSeparator}
          />
          <NitroxButton
            onPress={this.clearHistory}
            style={styles.iconContainer}
            textStyle={styles.buttonText}
            icon={{...this.clearSearchHistoryIcon, color: styles.clearSearchHistoryIconColor}}
            testID={'button_clear'}
          />
        </View>
      </FocusParent>
    );
  }
}
