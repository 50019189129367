import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import {NavigationScreenProps} from 'react-navigation';

import {dimensions} from 'common/constants';

import {nxff} from 'mw/api/NXFF';

import BackOfficeSelect from 'components/BackOfficeSelect';
import {IconType} from 'components/Icon';
import {useCurrentBackOffice, useToggle} from 'hooks/Hooks';
import SettingsDetails, {SettingsBarText} from 'screens/settings/SettingsDetails';

import {SettingsDetailsHeader} from './SettingsDetailsHeader';

const styles = createStyles({
  container: {
    paddingTop: dimensions.screen.settings.container.paddingTop,
    paddingHorizontal: dimensions.screen.container.paddingHorizontal,
    flexDirection: 'row',
    justifyContent: 'center'
  }
});

const SettingsBackOfficeScreen: React.FC<NavigationScreenProps<{}>> = props => {
  const {t} = useTranslation();
  const [backOfficeSelectVisible, {on: showBackOfficeSelect, off: hideBackOfficeSelect}] = useToggle(false);
  const backOffice = useCurrentBackOffice();
  const footerAction = useMemo(() => ({
    text: t('settings.changeBackoffice'),
    onPress: showBackOfficeSelect
  }), [showBackOfficeSelect, t]);

  const barText = useMemo<SettingsBarText>(() => ([
    {text: `${t('settings.current')}: `},
    {text: `${backOffice ? backOffice.name : t('settings.notFound')}`, weight: 'bold'}
  ]),
  [backOffice, t]);
  return (
    <SettingsDetails
      title={t('settings.backoffice')}
      barText={barText}
      header={<SettingsDetailsHeader icon={IconType.BackofficeBig} />}
      navigation={props.navigation}
      footerAction={footerAction}
      testID='screen_settings_backoffice'
    >
      <View style={styles.container}>
        {nxff.getConfig().DemoFeatures.EnabledBOSelection && <BackOfficeSelect visible={backOfficeSelectVisible} onClose={hideBackOfficeSelect} logoutOnChange />}
      </View>
    </SettingsDetails>
  );
};

export default SettingsBackOfficeScreen;
