import React, {useCallback, useState, useRef} from 'react';

import {isMobile} from 'common/constants';
import {Log} from 'common/Log';

import {Title, ExternalLink, Media, Content} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import WatchOnErrorPopup from 'components/WatchOnErrorPopup';
import WatchOnPopup from 'components/WatchOnPopup';
import {useFunction} from 'hooks/Hooks';

const TAG = 'DeepLinking';

export function useDeepLinking(): WithDeepLinking {
  const [selectionVisible, setSelectionVisible] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [currentTitle, setCurrentTitle] = useState<Title>();
  const [currentExternalLink, setCurrentExternalLink] = useState<ExternalLink>();
  const playbackAction = useRef<(media: Media) => void>(() => {});

  const onClose = useCallback(() => {
    setSelectionVisible(false);
    setErrorVisible(false);
    setCurrentTitle(undefined);
    setCurrentExternalLink(undefined);
  }, []);

  const showDeepLinkingModal = useCallback((title: Title, action: (media: Media) => void) => {
    playbackAction.current = action;
    setCurrentTitle(title);
    setSelectionVisible(true);
  }, []);

  const handleOpeningAppError = useFunction((err: any) => {
    setErrorVisible(true);
    Log.error(TAG, err);
  });

  const openDeepLink = useFunction((externalLink: ExternalLink) => {
    setCurrentExternalLink(externalLink);
    mw.system.openLink(externalLink.url)
      .catch((e) => handleOpeningAppError(e));
  });

  const onPress = useFunction((title: Title, content?: Content) => {
    if (content?.externalLink) {
      openDeepLink(content.externalLink);
    } else {
      playbackAction.current(title);
    }
    setSelectionVisible(false);
  });

  const openStore = useFunction(() => {
    onClose();
    mw.system.openAppStoreSearch(currentExternalLink?.provider || '')
      .catch((e) => Log.error(TAG, `Openieng app store falied, erroe: ${e}`));
  });

  const renderDeepLinkingModal = useCallback(() => {
    if (selectionVisible && isMobile) {
      return <WatchOnPopup title={currentTitle} visible={true} onClose={onClose} onPress={onPress} />;
    } else if (errorVisible && currentExternalLink) {
      return <WatchOnErrorPopup visible={true} onClose={onClose} onOpenStore={openStore} externalLink={currentExternalLink} />;
    }
    return null;
  }, [selectionVisible, onClose, currentTitle, errorVisible, openStore, currentExternalLink, onPress]);

  return {renderDeepLinkingModal, showDeepLinkingModal, openDeepLink};
}

export interface WithDeepLinking {
  renderDeepLinkingModal: () => void;
  openDeepLink: (externalLink: ExternalLink) => void;
  showDeepLinkingModal: (title: Title, action: (media: Media) => void) => void;
}

type WrappedProps<T> = Omit<T, keyof WithDeepLinking>;

export function withDeepLinking<Props extends WithDeepLinking>(WrappedComponent: React.ComponentType<Props>): React.ComponentType<WrappedProps<Props>> {
  function Wrapped(props: WrappedProps<Props>) {
    const {renderDeepLinkingModal, showDeepLinkingModal, openDeepLink} = useDeepLinking();
    return (
      <WrappedComponent
        {...props as Props}
        showDeepLinkingModal={showDeepLinkingModal}
        renderDeepLinkingModal={renderDeepLinkingModal}
        openDeepLink={openDeepLink}
      />
    );
  }
  Wrapped.displayName = WrappedComponent.displayName;
  return Wrapped;
}
