import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {ScrollView, View} from 'react-native';

import {dimensions, isTablet} from 'common/constants';

import DoubleBackground from 'components/DoubleBackground';
import {useToggleParentalControl} from 'screens/settings/profileWizard/WizardHooks';

import {WizardParentalControlRatingsPageProps} from './WizardParentalControlRatingsPage.shared';
import WizardParentalControlRatingsSettings from './WizardParentalControlRatingsSettings';

const styles = createStyles({
  content: {
    alignItems: 'center',
    paddingHorizontal: dimensions.margins.xxLarge
  },
  scrollContent: {
    paddingHorizontal: dimensions.screen.container.paddingHorizontal
  },
  topChild: {
    justifyContent: isTablet ? 'flex-start' : 'center',
    alignItems: 'center'
  },
  embeddedBottomChild: {
    paddingHorizontal: dimensions.screen.container.paddingHorizontal
  },
  bottomChild: {
    alignItems: 'stretch',
    marginTop: dimensions.wizard.configScreen.doubleBackgroundOverlap + dimensions.margins.large,
    paddingHorizontal: dimensions.wizard.configScreen.horizontalPadding
  },
  tracker: {
    marginTop: dimensions.margins.small
  }
});

const WizardParentalControlRatingsPage: React.FC<WizardParentalControlRatingsPageProps> = props => {
  const {parentalControl: propsParentalControl, onParentalChanged, pinState, onPinChanged, yearOfBirth} = props;
  const contentStyle = useMemo(() => [styles.content, props.contentStyle], [props.contentStyle]);
  const bottomChildStyle = useMemo(() => props.embedded ? styles.embeddedBottomChild : styles.bottomChild, [props.embedded]);

  const {parentalControl, toggleParentalControl, renderPopup} = useToggleParentalControl({
    parentalControl: propsParentalControl,
    onParentalControlChanged: onParentalChanged,
    pinState,
    setPin: onPinChanged,
    validateYearOfBirth: true,
    yearOfBirth
  });

  const parentalControlSettings = (
    <WizardParentalControlRatingsSettings
      {...props}
      parentalControl={parentalControl}
      toggleParentalControl={toggleParentalControl}
    />
  );

  if (isTablet && props.embedded) {
    return (
      <View style={contentStyle}>
        {parentalControlSettings}
      </View>
    );
  }

  return (
    <>
      <DoubleBackground
        topChild={(
          <View>
            <View style={styles.tracker}>
              {props.tracker}
            </View>
          </View>
        )}
        topStyle={styles.topChild}
        bottomChild={(
          <ScrollView contentContainerStyle={styles.scrollContent} alwaysBounceVertical={false}>
            <View style={contentStyle}>
              {parentalControlSettings}
            </View>
          </ScrollView>
        )}
        bottomStyle={bottomChildStyle}
        overlap={dimensions.wizard.configScreen.doubleBackgroundOverlap}
      />
      {renderPopup()}
    </>
  );
};

export default React.memo(WizardParentalControlRatingsPage);
