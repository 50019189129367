import {createStyles} from 'common-styles';
import React, {useMemo, useRef} from 'react';
import {View, ViewStyle, StyleProp, LayoutChangeEvent, TextStyle} from 'react-native';

import {isBigScreen, isMobile, dimensions} from 'common/constants';
import {indexKeyExtractor} from 'common/HelperFunctions';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxButton, {NitroxButtonProps, NitroxButtonTheme} from 'components/NitroxButton';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  buttonsRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignSelf: 'center'
  },
  buttonSelected: {
    backgroundColor: colors.button.primary.background.focused
  },
  buttonTextSelected: {
    color: colors.button.primary.text.focused
  }
}));

type GridProps = {
  columns: number;
  buttonWidth: number;
}

type FlexProps = {
  columns?: undefined;
  buttonWidth?: undefined;
}

type LayoutProps = GridProps | FlexProps;

type Props = {
  data: (NitroxButtonProps & React.Attributes)[];
  containerStyle?: StyleProp<ViewStyle>;
  centerFirstRow?: boolean;
  buttonHeight?: number;
  buttonMaxWidth?: number;
  buttonSelectedStyle?: StyleProp<ViewStyle>;
  buttonTextSelectedStyle?: StyleProp<TextStyle>;
  buttonStyle?: StyleProp<ViewStyle>;
  buttonTheme?: NitroxButtonTheme;
  buttonBorder?: boolean;
  xMargin?: number;
  yMargin?: number;
  onButtonsReady?: (isHidden: boolean) => void;
} & LayoutProps;

const selectedButtonBorderWidth = dimensions.button.selected.borderWidth * 2;

const ButtonsRow: React.FunctionComponent<Props> = props => {
  const {
    data,
    buttonWidth,
    buttonMaxWidth,
    buttonHeight = dimensions.popup.button.height,
    buttonSelectedStyle,
    buttonTextSelectedStyle,
    columns,
    containerStyle,
    centerFirstRow,
    buttonStyle,
    buttonTheme = NitroxButtonTheme.Primary,
    buttonBorder,
    xMargin = 0,
    yMargin = 0,
    onButtonsReady
  } = props;

  const styles = stylesUpdater.getStyles();
  const rowStyle = useMemo<StyleProp<ViewStyle>>(() => {
    return [
      styles.buttonsRow,
      typeof buttonWidth !== 'undefined' && typeof columns !== 'undefined'
        ? {width: (buttonWidth * columns) + (xMargin * Math.max(0, columns - 1))}
        : {justifyContent: isBigScreen ? 'center' : 'flex-start'}
    ];
  }, [buttonWidth, columns, styles.buttonsRow, xMargin]);

  const centerFirstRowStyle = useMemo<StyleProp<ViewStyle>>(() => {
    if (centerFirstRow && columns && data.length < columns) {
      return {justifyContent: 'center'};
    }
    return {};
  }, [centerFirstRow, columns, data.length]);

  const [buttonWidestWidth, setButtonWidestWidth] = React.useState<number | undefined>(undefined);
  const buttonsSizes = useRef<number[]>([]);

  const onLayout = (e: LayoutChangeEvent) => {
    if (buttonsSizes.current.length !== data.length) {
      const {width: buttonWidth} = e.nativeEvent.layout;
      buttonsSizes.current.push(buttonWidth);
    }

    if (buttonsSizes.current.length === data.length) {
      const buttonWidestWidth = Math.max(...buttonsSizes.current);
      setButtonWidestWidth(buttonWidestWidth + selectedButtonBorderWidth);
      onButtonsReady?.(true);
    }
  };

  return (
    <View style={[rowStyle, containerStyle, centerFirstRowStyle]}>
      {data.map((option, index: number) => (
        <NitroxButton
          key={option.key || indexKeyExtractor(option, index)}
          style={[
            {
              width: columns ? buttonWidth : buttonWidestWidth,
              minWidth: buttonWidth,
              maxWidth: buttonMaxWidth,
              height: buttonHeight,
              marginLeft: columns && (index % columns) ? xMargin : 0,
              marginTop: columns && (index >= columns) ? yMargin : 0
            },
            buttonStyle
          ]}
          styleSelected={isMobile ? [styles.buttonSelected, buttonSelectedStyle] : undefined}
          textStyleSelected={isMobile ? [styles.buttonTextSelected, buttonTextSelectedStyle] : undefined}
          theme={buttonTheme}
          border={buttonBorder}
          onLayout={onButtonsReady ? onLayout : undefined}
          {...option}
        />
      )
      )}
      {props.children}
    </View>
  );
};

export default ButtonsRow;
