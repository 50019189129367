import {createStyles} from 'common-styles';
import React, {useMemo, useState, useCallback, useEffect} from 'react';
import {ViewStyle, StyleProp, View} from 'react-native';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Icon, IconType} from 'components/Icon';
import NitroxInteractive from 'components/NitroxInteractive';
import {useDebounce} from 'hooks/Hooks';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 3,
    borderColor: colors.checkbox.icon.unfocused,
    borderWidth: 1,
    padding: dimensions.checkbox.padding
  },
  defaultContainer: {
    color: colors.checkbox.icon.unfocused,
    backgroundColor: colors.checkbox.background
  },
  disabled: {
    opacity: dimensions.opacity.xlow
  }
}));

export type CheckboxProps = {
  style?: StyleProp<ViewStyle>;
  selected?: boolean;
  disabled?: boolean;
  color?: string;
  backgroundColor?: string;
  size?: number;

  /**
   * Function called when the checkbox is pressed.
   *
   * If this is not defined, the component renders a `View`
   * instead of a `NitroxInteractive`, so it is not focusable and clickable.
   */
  onPress?: (selected: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = props => {
  const styles = stylesUpdater.getStyles();
  const {
    style,
    onPress,
    disabled,
    color = styles.defaultContainer.color,
    backgroundColor = styles.defaultContainer.backgroundColor,
    size = dimensions.icon.xxsmall
  } = props;

  const [selected, setSelected] = useState<boolean>(!!props.selected);

  // we can not call the callback while being in state transition because it will block us
  // from a possibility of doing some concurrent state changes in other components but we need to pass the up-to-date state value
  const onPressCallback = useCallback((newSelected: boolean) => {
    onPress && onPress(newSelected);
  }, [onPress]);
  const onPressDebounced = useDebounce(onPressCallback, 0);

  const toggleSelected = useCallback(() => {
    setSelected(previousSelected => {
      onPressDebounced(!previousSelected);
      return !previousSelected;
    });
  }, [onPressDebounced]);

  // changing the selected prop and rerendering the entire compoenent has no effect on the state value, we have to update it manually
  useEffect(() => {
    setSelected(!!props.selected);
  }, [props.selected]);

  const checkboxStyle = useMemo(() => {
    const result: StyleProp<ViewStyle> = [
      styles.container, {
        width: size + dimensions.checkbox.padding * 2,
        height: size + dimensions.checkbox.padding * 2,
        borderColor: color
      }
    ];
    if (selected) {
      result.push({backgroundColor: color});
    }
    if (disabled) {
      result.push(styles.disabled);
    }
    result.push(style);
    return result;
  }, [styles.container, size, color, selected, disabled, style]);

  const Container = useMemo<React.FC>(
    () => onPress
      ? ({children}) => <NitroxInteractive style={checkboxStyle} disabled={disabled} onPress={toggleSelected}>{children}</NitroxInteractive>
      : ({children}) => <View style={checkboxStyle}>{children}</View>,
    [checkboxStyle, disabled, onPress, toggleSelected]
  );

  return (
    <Container>
      {selected &&
        <Icon type={IconType.Check} size={size} color={backgroundColor} />
      }
    </Container>
  );
};

export default React.memo(Checkbox);
