import React, {useCallback} from 'react';

import STBMenuIconButton, {STBMenuIconButtonProps} from 'components/navigation/STBMenuIconButton';
import NotificationsIndicator from 'components/notifications/NotificationsIndicator';

type NotificationsSTBMenuButtonProps = {
  notificationsCount: number;
} & STBMenuIconButtonProps

const NotificationsSTBMenuButton: React.FunctionComponent<NotificationsSTBMenuButtonProps> = props => {
  const {notificationsCount = 0} = props;

  const renderIndicator = useCallback(() => (
    <NotificationsIndicator notificationsCount={notificationsCount} />
  ), [notificationsCount]);

  return notificationsCount ?
    <STBMenuIconButton {...props} renderImageOverlay={renderIndicator} /> :
    <STBMenuIconButton {...props} />;
};

export default NotificationsSTBMenuButton;
