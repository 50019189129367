import React from 'react';

import {NavigationFocusState} from 'common/HelperTypes';

import {useNavigation, useNavigationFocusState} from 'hooks/Hooks';

const NavigationFocusGuard: React.FC = ({children}) => {
  const navigation = useNavigation();
  const outsideNavigationScope = !navigation;
  const inFocusedScreen = useNavigationFocusState(navigation) === NavigationFocusState.IsFocused;

  return (inFocusedScreen || outsideNavigationScope)
    // can't return React.ReactNode directly
    ? <>{children}</>
    : null;
};

export default NavigationFocusGuard;
