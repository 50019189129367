import {createStyles} from 'common-styles';
import React, {useCallback} from 'react';
import {TouchableOpacity} from 'react-native';

import {isATV} from 'common/constants';
import {Log} from 'common/Log';

import {FocusManager} from './focusManager/FocusManager';

const styles = createStyles({
  /**
   * Style has to contain dimensions 3x3, because it's the lowest detectable element dimensions by devices with 720p
   */
  container: {
    position: 'absolute',
    width: 3,
    height: 3
  }
});

const TAG = 'NitroxATVFocusPlaceholder';
function onFocus() {
  Log.debug(TAG, 'Focus has gone out of FocusManager scope. This could have been caused by unmounting focused element e.g. due to navigation.');
}
function onBlur() {
  Log.debug(TAG, 'Focus placeholder blurred. FocusManager should have regained focus by now.');
}
/**
 * Android Focus Engine forces focus to top-left focusable element, when no element has focus.
 * Component below prevents focusing elements that may trigger unwanted action,
 * like expanding menu.
 */
const NitroxATVFocusPlaceholder: React.FC = () => {
  const registerPlaceholder = useCallback((component: TouchableOpacity | null) => {
    if (!component) {
      return;
    }
    FocusManager.getInstance().registerAndroidPlaceholderComponent(component);
  }, []);

  if (!isATV) {
    return null;
  }

  /* eslint-disable schange-rules/local-alternative */
  return (
    <TouchableOpacity
      style={styles.container}
      onFocus={onFocus}
      onBlur={onBlur}
      ref={registerPlaceholder}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      touchSoundDisabled
    />
  );
  /* eslint-enable schange-rules/local-alternative */
};

export default React.memo(NitroxATVFocusPlaceholder);
