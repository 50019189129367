import Color from 'color';
import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {StyleProp, ViewStyle} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import {isTablet} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

const gradientHeight = isTablet ? 80 : 40;

export enum ListShadowPosition {
  Top,
  Bottom
}

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  linearGradient: {
    position: 'absolute',
    height: gradientHeight,
    left: 0,
    right: 0
  },
  defaultGradientColors: [colors.tvScreen.background, Color(colors.tvScreen.background).alpha(0).toString()]
}));

export type ListShadowProps = {
  direction: ListShadowPosition;
  colors?: string[];
  style?: StyleProp<ViewStyle>;
}

export const ListShadow: React.FC<ListShadowProps> = props => {
  const {direction, style} = props;
  const start = useMemo(() => direction === ListShadowPosition.Bottom ? {x: 0, y: 1} : {x: 0, y: 0}, [direction]);
  const end = useMemo(() => direction === ListShadowPosition.Bottom ? {x: 0, y: 0} : {x: 0, y: 1}, [direction]);
  const styles = stylesUpdater.getStyles();
  return (
    <LinearGradient
      start={start}
      end={end}
      colors={props.colors || styles.defaultGradientColors}
      style={[styles.linearGradient, style]}
      pointerEvents='none'
    />
  );
};
