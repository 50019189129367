import {makeArray} from 'common/utils';

import {Series, Title, TitleType, Media} from 'mw/api/Metadata';

import {SeriesMapper} from './SeriesMapper';
import {TitleMapper} from './TitleMapper';

export class SeasonMapper {
  public static fromSeries(seriesJson: any): Series[] {
    if (!seriesJson.ChildSeriesCollection) {
      return [];
    }
    return makeArray(seriesJson.ChildSeriesCollection.Series).map(seasonJson => {
      return SeriesMapper.fromJSON(seasonJson);
    });
  }

  public static getTitlesFromJSON(seasonJson: any): Title[] {
    return makeArray(seasonJson?.Titles?.Title).map((titleJson) =>
      TitleMapper.fromJSON(titleJson, TitleType.VOD, seasonJson, true)
    );
  }

  public static getEpisodesFromJson(seasonJson: any): Media[] {
    const titles: Title[] = makeArray(seasonJson?.Titles?.Title)
      .map((titleJson: any) => TitleMapper.fromJSON(titleJson, titleJson?.Events?.Event ? TitleType.EPG : TitleType.VOD, seasonJson, true));

    const episodes: Media[] = [];
    titles.forEach(title => {
      if (title.events.length) {
        episodes.push(...title.events);
      } else {
        episodes.push(title);
      }
    });

    return episodes;
  }
}
