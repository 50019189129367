import React, {useContext, useMemo} from 'react';
import {StyleProp, ViewStyle} from 'react-native';

import {dimensions} from 'common/constants';

import {ChromecastConnectionState} from 'mw/api/Metadata';

import {ChromecastContext} from 'components/ChromecastContext';

const chromecastBarVisibleStates = [ChromecastConnectionState.Connecting, ChromecastConnectionState.MediaConnecting, ChromecastConnectionState.MediaConnected];

export function useIsChromecastBarVisible(): boolean {
  const {connectionState} = useContext(ChromecastContext);
  return chromecastBarVisibleStates.includes(connectionState);
}

export function useChromecastExtraBottomPadding(propsStyle: StyleProp<ViewStyle> = {}): StyleProp<ViewStyle> {
  const isChromecastBarVisible = useIsChromecastBarVisible();
  return useMemo(() => [
    propsStyle,
    isChromecastBarVisible && {paddingBottom: dimensions.chromecast.statusBar.height}
  ], [propsStyle, isChromecastBarVisible]);
}

export interface WithChromecastExtraBottomPadding {
  chromecastExtraBottomPadding: StyleProp<ViewStyle>
}

type WrappedProps<T> = Omit<T, keyof WithChromecastExtraBottomPadding>;

export function withChromecastExtraBottomPadding<Props extends WithChromecastExtraBottomPadding>(WrappedComponent: React.ComponentType<Props>): React.ComponentType<WrappedProps<Props>> {
  function Wrapped(props: WrappedProps<Props>) {
    const chromecastExtraBottomPadding = useChromecastExtraBottomPadding();
    return (
      <WrappedComponent
        {...props as Props}
        chromecastExtraBottomPadding={chromecastExtraBottomPadding}
      />
    );
  }
  Wrapped.displayName = WrappedComponent.displayName;
  return Wrapped;
}
