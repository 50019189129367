import {createStyles} from 'common-styles';
import React from 'react';
import {View, ViewStyle, StyleProp, StyleSheet} from 'react-native';

import {dimensions} from 'common/constants';
import {humanCaseToSnakeCase} from 'common/HelperFunctions';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxInteractive from './NitroxInteractive';
import NitroxText from './NitroxText';

export const defaultTabSelectorHeight = 50;
const defaultTabSelectorWidth = 305;
const borderRadius = Math.floor(defaultTabSelectorHeight / 2);
const borderWidth = 2;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flexDirection: 'row',
    maxWidth: defaultTabSelectorWidth,
    backgroundColor: colors.elipseSwitch.background,
    borderWidth,
    borderColor: colors.elipseSwitch.border,
    borderRadius
  },
  itemContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    height: defaultTabSelectorHeight - borderWidth * 2,
    borderRightWidth: StyleSheet.hairlineWidth,
    // addind this margin is a fix on some android devices. On some devices border is not visible without it. Look at CL-1664.
    marginRight: StyleSheet.hairlineWidth,
    borderRightColor: colors.elipseSwitch.separator
  },
  firstItemContainer: {
    borderTopLeftRadius: borderRadius,
    borderBottomLeftRadius: borderRadius
  },
  lastItemContainer: {
    borderTopRightRadius: borderRadius,
    borderBottomRightRadius: borderRadius,
    borderRightWidth: 0,
    marginRight: 0
  },
  text: {
    color: colors.elipseSwitch.text
  }
}));

export type NitroxTabSelectorItem = {
  label: string;
  onPress: () => void;
}

type Props = {
  items: NitroxTabSelectorItem[];
  containerStyle?: StyleProp<ViewStyle>;
};

type State = {
  selctedIndex: number;
};

export class NitroxTabSelector extends React.Component<Props, State> {
  public state: State = {
    selctedIndex: 0
  }

  private renderItem = (item: NitroxTabSelectorItem, index: number) => {
    const isSelected = index === this.state.selctedIndex;
    const first = index === 0;
    const last = index === this.props.items.length - 1;

    const onItemPress = () => {
      if (isSelected) {
        return;
      }
      this.setState({selctedIndex: index});
      item.onPress();
    };

    const styles = stylesUpdater.getStyles();
    return (
      <NitroxInteractive key={index} style={[styles.itemContainer, first ? styles.firstItemContainer : last ? styles.lastItemContainer : {} ]} onPress={onItemPress} testID={`tab_${humanCaseToSnakeCase(item.label)}`}>
        <NitroxText style={[styles.text, {opacity: isSelected ? 1 : dimensions.opacity.xhigh}]} textType={isSelected ? 'tab-selector-selected' : 'tab-selector'}>{item.label}</NitroxText>
      </NitroxInteractive>
    );
  };

  public render() {
    const styles = stylesUpdater.getStyles();
    return (
      <View style={[styles.container, this.props.containerStyle]}>
        {this.props.items.map((item, index) => this.renderItem(item, index))}
      </View>
    );
  }
}
