import {createStyles} from 'common-styles';
import React, {useCallback} from 'react';
import {ListRenderItemInfo} from 'react-native';

import {dimensions, isTablet} from 'common/constants';
import {idKeyExtractor} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {Media, isTitle} from 'mw/api/Metadata';

import EpisodeTile from 'components/mediadetails/EpisodeTile';
import MobileMediaTile, {MobileMediaTileType} from 'components/mediaTiles/MobileMediaTile';
import NitroxFlatList from 'components/NitroxFlatList';
import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';
import {usePlayerLauncher} from 'components/playerLauncher/PlayerLauncher';
import Separator from 'components/Separator';

const TAG = 'EpisodesList';

const styles = createStyles({
  separator: {
    marginHorizontal: isTablet ? dimensions.margins.xxLarge : dimensions.margins.large,
    opacity: dimensions.opacity.xxxlow
  }
});

const EpisodeSeparator = () => (
  <Separator horizontal={true} style={styles.separator} />
);

type Props = {
  episodes: Media[];
};

const EpisodesList: React.FunctionComponent<Props> = props => {
  const {isMediaBlocked, shouldBeCheckedForPC, unlockMedia} = useParentalControl();
  const {renderPlayerLauncherComponent, startPlayback} = usePlayerLauncher();

  const onEpisodePress = useCallback((media: Media) => {
    if (shouldBeCheckedForPC(media) && isMediaBlocked(media)) {
      unlockMedia(media)
        .then(() => startPlayback({media}))
        .catch(error => Log.error(TAG, 'Error when authorizing with pin: ', error));
    } else {
      startPlayback({media});
    }
  }, [isMediaBlocked, shouldBeCheckedForPC, startPlayback, unlockMedia]);

  const renderItem = useCallback((info: ListRenderItemInfo<Media>) => {
    const media = info.item;
    if (isTitle(media)) {
      return <EpisodeTile media={media} onPosterPress={() => onEpisodePress(media)} />;
    }
    return <MobileMediaTile media={media} onStartPlayback={onEpisodePress} tileType={MobileMediaTileType.Episode} />;
  }, [onEpisodePress]);

  return (
    <>
      <NitroxFlatList
        data={props.episodes}
        keyExtractor={idKeyExtractor}
        showsVerticalScrollIndicator={false}
        renderItem={renderItem}
        ItemSeparatorComponent={EpisodeSeparator}
        ListHeaderComponent={EpisodeSeparator}
      />
      {renderPlayerLauncherComponent()}
    </>
  );
};

export default EpisodesList;
