import {AppStateStatus} from 'react-native';

import {debounce, DebouncedFunction} from 'common/Async';
import {DateUtils} from 'common/DateUtils';
import {Log} from 'common/Log';

import './polyfill';
import {EventEmitter} from 'common/EventEmitter';
import {Profiler} from 'common/Profiler';

import {DeviceManager} from 'mw/api/DeviceManager';
import {Error, ErrorType} from 'mw/api/Error';
import {Profile} from 'mw/api/Profile';
import {BOEvent} from 'mw/bo-proxy/BOInterface';
import {boProxy} from 'mw/bo-proxy/BOProxy';
import NetworkConnectionEventNotifier, {NetworkConnectionEvent} from 'mw/common/NetworkConnectionEventNotifier';
import NXFFEnvironmentData from 'mw/nxff-config/preset.json';
import {populateDeviceInfo} from 'mw/platform/device-info/DeviceInfo';
import {nativeLocationModule} from 'mw/platform/native-location/NativeLocation';

import {BackOffice, BackOfficeEvent, NXFFPreset} from './api/BackOffice';
import {Catalog} from './api/Catalog';
import {CacheType} from './api/CatalogInterface';
import {Configuration} from './api/Configuration';
import {Customer, CustomerEvent} from './api/Customer';
import {EAS} from './api/EAS';
import {nxffConfig} from './api/NXFF';
import {PVR} from './api/PVR';
import {System} from './api/System';
import {UserAgreement, UserAgreementEvent} from './api/UserAgreement';
import {CMS, CMSEvent} from './cms/CMS';
import {NitroxAppState} from './platform/app-state/AppState';
import {PlaybackEventEmitter} from './playback/PlaybackEventEmitter';
import {PlayersManager} from './playback/PlayersManager';

const TAG = 'MW';
const profilerTag = 'MW/Initialize';
const wifiStateChangedDelay = 30 * DateUtils.msInSec;

export {CatalogEvent} from './api/CatalogInterface';
export {PCType} from './common/ParentalControl';

export enum MWEvent {
  initialized = 'initialized',
  initializationFailed = 'initializationFailed',
  uninitialized = 'uninitialized',
  uninitializationFailed = 'uninitializationFailed',
  boInvalidCustomerID = 'boInvalidCustomerID'
}

interface MWOptions {
  isChromeCast?: boolean;
  deviceId?: string;
  profileId?: string;
  simSubRegionId?: string;
}

class MW extends EventEmitter<MWEvent> {

  public get bo(): BackOffice {return this._bo;}
  public get customer(): Customer {return this._customer;}
  public get catalog(): Catalog {return this._catalog;}
  public get pvr(): PVR {return this._pvr;}
  public get eas(): EAS {return this._eas;}
  public get configuration(): Configuration {return this._configuration;}
  public get players(): PlayersManager {return this._players;}
  public get cms(): CMS {return this._cms;}
  public get system(): System {return this._system;}
  public get userAgreement(): UserAgreement {return this._userAgreement;}
  public get isInitialized(): boolean {return this.initialized;}

  private initialized = false;
  private networkConnectionEventNotifier = new NetworkConnectionEventNotifier();
  private onNetworkConnectionStateChanged: DebouncedFunction<() => void> | null = null;
  private renewLoginSessionOnAppActivation: DebouncedFunction<() => void> | null = null;

  public options: MWOptions = {};

  // CL-3474 - this is a temporary solution because Hermes JS engine does not support Proxy yet.
  private _bo: BackOffice;
  private _customer: Customer;
  private _catalog: Catalog;
  private _pvr: PVR;
  private _eas: EAS;
  private _configuration: Configuration;
  private _players: PlayersManager;
  private _cms: CMS;
  private _system: System;
  private _userAgreement: UserAgreement;

  public constructor() {
    super();
    this._bo = new BackOffice();
    this._customer = new Customer();
    this._catalog = new Catalog();
    this._pvr = new PVR();
    this._eas = new EAS();
    this._configuration = new Configuration();
    this._players = new PlayersManager();
    this._cms = new CMS();
    this._system = new System();
    this._userAgreement = new UserAgreement();
  }

  public async initialize(options: MWOptions = {}, preset: NXFFPreset = NXFFEnvironmentData.NXFF): Promise<void> {
    Log.info(TAG, 'initialize');

    if (this.initialized) {
      Log.info(TAG, 'mw already initialized');
      return;
    }

    Profiler.add(() => {
      Profiler.start(profilerTag);
      this.once(MWEvent.initialized, () => Profiler.entry(profilerTag, 'initialized'));
      this.bo.once(BackOfficeEvent.nxffProfileSet, () => Profiler.entry(profilerTag, 'nxffProfileSet'));
      this.cms.once(CMSEvent.cmsInitialized, () => Profiler.entry(profilerTag, 'cmsInitialized'));
      this.customer.once(CustomerEvent.CustomerDataLoaded, () => Profiler.entry(profilerTag, 'CustomerDataLoaded'));
      this.customer.once(CustomerEvent.EULAConsentAccepted, () => Profiler.entry(profilerTag, 'EULAConsentAccepted'));
      this.customer.once(CustomerEvent.ProfileChange, () => Profiler.entry(profilerTag, 'ProfileChange'));
      this.customer.once(CustomerEvent.DeviceRegistered, () => Profiler.entry(profilerTag, 'DeviceRegistered'));
      this.customer.once(CustomerEvent.UILanguageChanged, () => Profiler.entry(profilerTag, 'UILanguageChanged'));
      this.userAgreement.once(UserAgreementEvent.uidUsageAgreementConfirmed, () => Profiler.entry(profilerTag, 'uidUsageAgreementConfirmed'));
    });

    this.options = options;

    await populateDeviceInfo();
    await this.system.initialize();

    PlaybackEventEmitter.getInstance().setPlayers(this.players);

    NitroxAppState.addEventListener('change', this.onAppStateChanged);

    this.bo.on(BackOfficeEvent.loggedIn, async () => {
      Profiler.entry(profilerTag, 'loggedIn');
      if (this.options.deviceId) {
        DeviceManager.getInstance().setId(this.options.deviceId);
      } else {
        DeviceManager.getInstance().setId(boProxy.bo.generateCpeId());
      }
      Profiler.entry(profilerTag, 'set deviceId');

      if (this.options.profileId) {
        this.customer.setProfile(new Profile({id: this.options.profileId, name: ''}), null);
      }

      if (this.options.simSubRegionId) {
        this.customer.setRegionId(this.options.simSubRegionId);
      }

      await nativeLocationModule.initialize();

      if (this.configuration.isRefreshingOnNetworkChangeEnabled) {
        this.networkConnectionEventNotifier.on(NetworkConnectionEvent.WifiStateChanged, this.onWifiStateChanged);
      }

      if (this.configuration.isLoginSessionRenewEnabled) {
        this.onNetworkConnectionStateChanged = debounce(this.renewLoginSession, nxffConfig.getConfig().Environment.NetworkConnectionStateChangeDelay * DateUtils.msInSec);
        this.networkConnectionEventNotifier.on(NetworkConnectionEvent.ConnectionStateChanged, this.onNetworkConnectionStateChanged);
        this.renewLoginSessionOnAppActivation = debounce(this.renewLoginSession, nxffConfig.getConfig().Environment.ApplicationStateChangeDelay * DateUtils.msInSec);
      }

      boProxy.bo.on(BOEvent.PlaybackLocationForbidden, this.onPlaybackLocationForbidden);

      try {
        Profiler.entry(profilerTag, 'begin bo set');
        await boProxy.bo.setParameters();
        Profiler.entry(profilerTag, 'begin bo set end');

        if (this.options.isChromeCast) {
          this.catalog.refreshCache(CacheType.Products).finally(this.notifyInitialized);
          return;
        }

        await this.userAgreement.checkUidUsageAgreement();
      } catch (error) {
        Log.error(TAG, 'MW initialization failed', error);
        Profiler.entry(profilerTag, 'initializationFailed: setParameters');
        this.notify(MWEvent.initializationFailed);
      }
    });

    this.once(MWEvent.initialized, () => {
      boProxy.reportingManager?.handleLoginEvent();
      boProxy.notificationsManager?.start();
      this.eas.initialize();
    });

    this.bo.once(BackOfficeEvent.logoutInProgress, () => this.clearOnLogoutInProgress());

    this.userAgreement.once(UserAgreementEvent.uidUsageAgreementConfirmed, () => {
      this.customer.initialize()
        .catch((error: Error) => {
          switch (error?.type) {
            case ErrorType.BOInvalidCustomerID:
              this.notify(MWEvent.boInvalidCustomerID);
              break;
            default:
              Profiler.entry(profilerTag, 'initializationFailed: customer initialization');
              this.notify(MWEvent.initializationFailed);
              break;
          }
        });
    });

    this.customer.once(CustomerEvent.CustomerDataLoaded, () => {
      this.cms.initialize(this.customer.stylingName);
    });

    this.cms.once(CMSEvent.cmsInitialized, () => {
      this.customer.checkEULAConsent()
        .catch(error => {
          Log.error(TAG, 'EULA consent confirmation error', error);
          this.notify(MWEvent.initializationFailed);
        });
    });

    this.customer.once(CustomerEvent.EULAConsentAccepted, () => {
      this.customer.registerDevice()
        .catch(error => Log.error(TAG, 'Register device failed', error));
    });

    this.customer.once(CustomerEvent.DeviceRegistered, () => {
      this.customer.initProfiles()
        .catch((error: Error) => {
          Log.error(TAG, 'Customer profiles initialization failed', error);
          Profiler.entry(profilerTag, 'initializationFailed: Customer profiles initialization');
          this.notify(MWEvent.initializationFailed);
        });
    });

    this.customer.once(CustomerEvent.ProfileChange, async () => {
      try {
        Profiler.entry(profilerTag, 'data preload start');
        boProxy.drmSessionManager && await boProxy.drmSessionManager.initialize();
        Profiler.entry(profilerTag, 'drm initialize end');

        // We need channel list for queries
        await this.catalog.getAllChannels();

        this.catalog.refreshCache(CacheType.EPG) // do not block initialization
          .then(() => this.pvr.initialize())
          .catch(() => Log.error(TAG, 'refreshEPG failed'));
        Profiler.entry(profilerTag, 'refresh npvr start');

        this.catalog.addCustomerEventListeners(this.customer);
        this.notifyInitialized();
      } catch (error) {
        Log.error(TAG, 'MW initialization failed', error);
        this.notify(MWEvent.initializationFailed);
        Profiler.entry(profilerTag, 'initializationFailed: data preload');
      }
    });

    this.customer.on(CustomerEvent.ProfileChange, () => this.cms.clearPageCache());
    this.customer.on(CustomerEvent.UILanguageChanged, () => this.cms.clearPageCache());

    this.cms.once(CMSEvent.i18nInitialized, async () => {
      await this.bo.autologin().catch(error => Log.debug(TAG, 'initialize', 'autologin fails', error));

      this.bo.registerCrossLoginDataProvider(this.cms);
      this.bo.registerCrossLoginDataProvider(boProxy.sso);
    });

    await this.initializeBackOffice(preset);
    await this.cms.initializeI18N();
  }

  public initializeBackOffice(preset: NXFFPreset = NXFFEnvironmentData.NXFF) {
    return this.bo.initialize(preset);
  }

  public uninitialize(): void {
    Log.info(TAG, 'uninitialize');
    this.initialized = false;

    NitroxAppState.removeEventListener('change', this.onAppStateChanged);
    this.clearOnLogoutInProgress();

    this.catalog.uninitialize();
    this.bo.uninitialize();
    this.customer.uninitialize();
    this.pvr.uninitialize();
    this.configuration.uninitialize();
    this.players.uninitialize();
    this.cms.uninitialize();
    this.system.uninitialize();
    this.userAgreement.clear();

    nativeLocationModule.uninitialize();

    this.clear();
    this._bo = new BackOffice();
    this._customer = new Customer();
    this._catalog = new Catalog();
    this._pvr = new PVR();
    this._eas = new EAS();
    this._configuration = new Configuration();
    this._players = new PlayersManager();
    this._cms = new CMS();
    this._system = new System();
    this._userAgreement = new UserAgreement();
  }

  private clearOnLogoutInProgress(): void {
    Log.info(TAG, 'clearOnLogoutInProgress');
    this.eas.uninitialize();
    boProxy.notificationsManager?.stop();
    boProxy.reportingManager?.handleLogoutEvent();

    boProxy.bo.off(BOEvent.PlaybackLocationForbidden, this.onPlaybackLocationForbidden);
    this.onNetworkConnectionStateChanged?.abort();
    this.onNetworkConnectionStateChanged = null;
    this.networkConnectionEventNotifier.clear();
    this.renewLoginSessionOnAppActivation?.abort();
    this.renewLoginSessionOnAppActivation = null;
  }

  private onAppStateChanged = (status: AppStateStatus) => {
    Log.info(TAG, 'onAppStateChanged', status);
    if (status === 'active') {
      boProxy.notificationsManager?.start();
      this.renewLoginSessionOnAppActivation?.();
    } else {
      boProxy.notificationsManager?.stop();
      this.renewLoginSessionOnAppActivation?.abort();
    }
  }

  private onWifiStateChanged = debounce((state: boolean) => {
    Log.info(TAG, 'onWifiStateChanged', state);
    this.refreshCatalogCacheAndValidatePlayingMedia();
  }, wifiStateChangedDelay);

  private renewLoginSession = (): void => {
    Log.info(TAG, 'renewLoginSession');
    this.bo.relogin()
      .catch(error => Log.error(TAG, 'Renew login session failed', error));
  };

  private onPlaybackLocationForbidden = () => {
    this.onWifiStateChanged.abort();
    this.refreshCatalogCacheAndValidatePlayingMedia();
  }

  private refreshCatalogCacheAndValidatePlayingMedia(): Promise<void> {
    return this.catalog.refreshCache(CacheType.Channels, CacheType.EPG, CacheType.Content, CacheType.Products)
      .then(() => mw.catalog.validatePlayingMedia()
        .catch(error => Log.error(TAG, 'Error while validating playing media:', error))
      )
      .catch(error => Log.error(TAG, 'Refresh catalog cache failed', error));
  }

  private notifyInitialized = () => {
    this.initialized = true;
    this.notify(MWEvent.initialized);
  }
}

// CL-3474 - this is a temporary solution because Hermes JS engine does not support Proxy yet.
// This should not be initialized/exported like this.
export const mw = new MW();
