import {createStyles} from 'common-styles';
import moment from 'moment';
import React, {useState, useEffect, useRef, useCallback, useMemo} from 'react';
import {View, ViewStyle, ListRenderItemInfo, LayoutChangeEvent, StyleProp, ActivityIndicator} from 'react-native';

import {dimensions} from 'common/constants';
import {DateUtils} from 'common/DateUtils';
import {idKeyExtractor, combineDateAndTime, isTruthy, getRatingToDisplay} from 'common/HelperFunctions';
import {Navigation} from 'common/HelperTypes';
import {Log} from 'common/Log';
import {getUniqueEvents} from 'common/utils';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {CatalogEvent, EPGResponse} from 'mw/api/CatalogInterface';
import {Channel, ContentType, Event} from 'mw/api/Metadata';
import {PlayerEvent} from 'mw/api/PlayerEvent';
import {mw} from 'mw/MW';

import {useIsChromecastBarVisible} from 'components/chromecast/ChromecastExtraBottomPadding';
import {ListShadow, ListShadowPosition} from 'components/ListShadow';
import {calculateProgress} from 'components/mediaTiles/MediaTile';
import NitroxFlatList from 'components/NitroxFlatList';
import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';
import {prepareEventIcons, MediaIcons} from 'components/TileIconsRow';
import EventMoreActionsPopup from 'components/zapper/EventMoreActionsPopup';
import EventTile, {EventTileType, useEventTileSize} from 'components/zapper/EventTile';
import EventTileSeparator, {epgTileSeparatorHeight} from 'components/zapper/EventTileSeparator';
import {useFunction, useEventListener, useThrottle, useLazyEffect, useWatchList, useForceUpdate, useChangeEffect, useDebounce, useNearestLiveEvent} from 'hooks/Hooks';

const TAG = 'OneChannelEpg';
const minVisibleItems = 1;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flex: 1
  },
  topShadow: {
    top: 0
  },
  bottomShadow: {
    bottom: 0
  },
  activityIndicator: {
    position: 'absolute',
    alignSelf: 'center',
    marginTop: dimensions.margins.large
  },
  activityIndicatorColor: colors.defaultColors.spinner
}));

type ItemProps = {
  event: Event;
  type?: EventTileType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraData?: any;
  onMoreActionsPress: (event: Event) => void;
  onPress: (event: Event) => void;
  progress?: number;
  isCurrentEvent?: boolean;
}

const OneChannelEpgItem: React.FC<ItemProps> = React.memo(props => {
  const {event, type, extraData, onMoreActionsPress, onPress, progress = 0, isCurrentEvent = false} = props;
  const {isMediaBlocked} = useParentalControl();
  const isBlocked = isMediaBlocked(event);
  const [mediaIcons, setMediaIcons] = useState<MediaIcons>({});
  const subtitle = useMemo(() => (
    [getRatingToDisplay(event), ...event.title.metadata.genres.map(genre => genre.name)].filter(isTruthy).join(', ')
  ), [event]);

  const onMoreActionsPressCallback = useCallback(() => onMoreActionsPress(event), [event, onMoreActionsPress]);
  const onPressCallback = useCallback(() => onPress(event), [event, onPress]);

  useEffect(() => {
    setMediaIcons(prepareEventIcons(event, {isBlocked}));
  }, [event, event.isNow, event.isOnWatchList, extraData, isBlocked]); // isNow and isOnWatchList are computed properties which can change independently from the event's object reference

  return (
    <EventTile
      mediaId={event.id}
      time={event.start}
      title={event.name}
      channelId={event.channelId}
      use='time'
      mediaIcons={mediaIcons}
      subtitle={subtitle}
      progress={progress}
      showProgress={isCurrentEvent}
      isSelected={isCurrentEvent}
      onActionsPress={onPressCallback}
      onMoreActionsPress={onMoreActionsPressCallback}
      type={type}
    />
  );
});
OneChannelEpgItem.displayName = 'OneChannelEpgItem';

type CurrentItemProps = {
  event: Event;
  type?: EventTileType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraData?: any;
  onMoreActionsPress: (event: Event) => void;
  onPress: (event: Event) => void;
}

function calculatePlayerProgress(event: Event) {
  const contentType = mw.players.main.contentType;

  if (contentType === ContentType.LIVE) {
    return event.progress;
  }

  const playerParameters = mw.players.main.getParameters();
  const playerPosition = playerParameters.position ?? 0;
  const eventDuration = (event.end.getTime() - event.start.getTime()) / DateUtils.msInSec;

  if (contentType === ContentType.NPLTV) {
    const secondsLeftToEnd = playerParameters.endPosition - playerPosition;
    const secondsFromEventStart = ((Date.now() - event.start.getTime()) / DateUtils.msInSec) - secondsLeftToEnd;
    return secondsFromEventStart / eventDuration;
  }

  return playerPosition / eventDuration;
}

const CurrentOneChannelEpgItem: React.FC<CurrentItemProps> = React.memo(props => {
  const {event, type, extraData} = props;
  const [progress, setProgress] = useState(0);

  const updateEventProgress = useThrottle(() => {
    // OneChannelEpg is also used in ChannelDetailScreen where player is not initialized.
    if (mw.players.main.isPlayerInitialized()) {
      setProgress(calculatePlayerProgress(event));
    } else {
      setProgress(calculateProgress(event));
    }
  }, 1000);

  useLazyEffect(() => updateEventProgress(), [], [updateEventProgress]);
  useEventListener(PlayerEvent.PositionChanged, updateEventProgress, mw.players.main);

  return (
    <OneChannelEpgItem
      event={event}
      type={type}
      extraData={extraData}
      onPress={props.onPress}
      onMoreActionsPress={props.onMoreActionsPress}
      progress={progress}
      isCurrentEvent
    />
  );
});
CurrentOneChannelEpgItem.displayName = 'CurrentOneChannelEpgItem';

const loadData = (channel: Channel, startTime: Date, endTime: Date) => {
  return mw.catalog.getEPG({
    channels: [channel],
    startTime,
    endTime
  });
};

const findInitialEventIndex = (events: Event[], tunedEvent: Event, date: Date) => {
  let initialEventIndex = -1;

  if (DateUtils.isSameDay(tunedEvent.start, date)) {
    initialEventIndex = events.findIndex(event => event.id === tunedEvent.id);
  }

  if (initialEventIndex === -1) {
    const dateInFocus = combineDateAndTime(date, new Date());
    initialEventIndex = events.findIndex(event => event.start <= dateInFocus && event.end > dateInFocus);
  }

  return initialEventIndex;
};

type Props = {
  channel: Channel;
  containerStyle?: ViewStyle;
  contentContainerStyle?: ViewStyle;
  navigation?: Navigation;
  tuneEvent?: (media: Event) => void;
  openEventDetail: (event: Event) => void;
  tunedEvent?: Event;
  showEpg: () => void;
  visible?: boolean;
  startTime: Date;
  endTime?: Date;
  onScroll?: (event: Event) => void;
  tileType?: EventTileType;
  isEventAuthorized?: (event: Event) => Promise<boolean>;
};

const OneChannelEpg: React.FunctionComponent<Props> = props => {
  const defaultStartTime = useMemo(() => moment(props.startTime).startOf('day')
    .toDate(), [props.startTime]);
  const defaultEndTime = useMemo(() => moment(props.startTime).endOf('day')
    .toDate(), [props.startTime]);
  const {
    visible = true,
    startTime = defaultStartTime,
    endTime = defaultEndTime,
    isEventAuthorized,
    onScroll: onEpgScroll,
    tunedEvent,
    tileType
  } = props;
  const [channel, setChannel] = useState<Channel>();
  const [date, setDate] = useState<Date>();
  const [moreActionsPopupVisible, setMoreActionsPopupVisible] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<Event>();
  const [height, setHeight] = useState(0);
  const [contentContainerHeight, setContentContainerHeight] = useState(0);
  const [maxVisibleItems, setMaxVisibleItems] = useState(minVisibleItems);
  const [refreshing, setRefreshing] = useState(true);
  const [events, setEvents] = useState<Event[]>([]);
  const initialScrollPerformed = useRef(false);
  const watchList = useWatchList();
  const nearestLiveEvent = useNearestLiveEvent(useCallback(() => events, [events]));
  const {forceUpdate, forceUpdateState} = useForceUpdate();
  const flatList = useRef<NitroxFlatList<Event> | null>(null);
  const styles = stylesUpdater.getStyles();

  const eventTileSize = useEventTileSize(tileType);
  const eventTileWithSeparatorHeight = eventTileSize.totalHeight + epgTileSeparatorHeight;
  const getItemLayout = useCallback((_, index: number) => {
    return {length: eventTileSize.totalHeight, offset: eventTileWithSeparatorHeight * index, index};
  }, [eventTileSize, eventTileWithSeparatorHeight]);

  // update event icons that change over time
  useChangeEffect(forceUpdate, [watchList, nearestLiveEvent], [forceUpdate]);

  // force update to update recording icon on event tiles
  useEventListener(CatalogEvent.EventsIsRecordedUpdated, forceUpdate, mw.catalog);

  const debounceUpdateList = useDebounce((events: Event[]) => {
    initialScrollPerformed.current = false;
    setEvents(events);
  }, 0);

  const processResponse = useFunction((channelId: string, epgResponse: EPGResponse) => {
    // This should never happen but must be checked.
    if (!channel || !date) {
      return;
    }
    if (channelId !== channel.id) {
      Log.debug(TAG, `EPG data for channel ${channelId} fetched but channel changed in the meantime to ${channel.id}!`);
      return;
    }
    const events = getUniqueEvents(epgResponse.get(channel.id) || []); // TODO: remove getUniqueEvents after fixing duplicated events
    setContentContainerHeight(events.length * eventTileWithSeparatorHeight);
    setEvents([]);
    // Allow FlatList to update content container size before performing initial scroll and rendering items.
    debounceUpdateList(events)
      .catch(error => Log.warn(TAG, 'Unable to update event list', error));
  });

  const loadEpgData = useFunction((channel: Channel) => {
    loadData(channel, startTime, endTime)
      .then(events => processResponse(channel.id, events))
      .catch(error => {
        setEvents([]);
        setRefreshing(false);
        Log.error(TAG, `Error loading events for channel ${channel}`, error);
      });
  });

  // When EPG data is retrieved from cache loadData is synchronous and it blocks UI for too long.
  // Small delay is needed to make it more responsive.
  const debounceLoadEpgData = useDebounce(loadEpgData, 250);
  useLazyEffect(() => {
    if (!channel || !date) {
      return;
    }
    // Do not clear FlatList data here otherwise immediate scrolling to current event won't work correctly.
    // Probably it's caused by changing content size and mounting again items. Those operations are very expensive.
    setRefreshing(true);
    debounceLoadEpgData(channel)
      .catch(error => Log.warn(TAG, 'Error while loading EPG data', error));
  }, [channel, date], [debounceLoadEpgData]);

  useEffect(() => {
    if (!tunedEvent) {
      setRefreshing(true);
      initialScrollPerformed.current = false;
    }
  }, [tunedEvent]);

  useLazyEffect(() => {
    if (visible) {
      setChannel(props.channel);
      setDate(props.startTime);
    } else if (channel !== props.channel || date !== props.startTime) {
      setEvents([]);
      setChannel(undefined);
      setDate(undefined);
      setRefreshing(true);
    }
  }, [visible, props.channel, props.startTime], [channel, date]);

  const closeMoreActionsPopup = useCallback(() => {
    setMoreActionsPopupVisible(false);
  }, []);

  const openMoreActionsPopup = useFunction((event: Event) => {
    if (!event) {
      return;
    }
    setMoreActionsPopupVisible(true);
    setSelectedEvent(event);
  });

  // Don't use useFunction here because when props.tunedEvent is changed current item sometimes might be not re-rendered.
  const renderItem = useCallback((info: ListRenderItemInfo<Event>) => {
    const event = info.item;
    const isCurrentEvent = event.id === tunedEvent?.id;

    if (isCurrentEvent) {
      return (
        <CurrentOneChannelEpgItem
          event={event}
          type={tileType}
          extraData={forceUpdateState}
          onPress={props.openEventDetail}
          onMoreActionsPress={openMoreActionsPopup}
        />
      );
    }

    return (
      <OneChannelEpgItem
        event={event}
        type={tileType}
        extraData={forceUpdateState}
        onPress={props.openEventDetail}
        onMoreActionsPress={openMoreActionsPopup}
      />
    );
  }, [tunedEvent, props.openEventDetail, openMoreActionsPopup, forceUpdateState, tileType]);

  const renderSeparator = useFunction(() => <EventTileSeparator type={tileType} />);

  const onLayout = useFunction((event: LayoutChangeEvent) => {
    const layoutHeight = event.nativeEvent.layout.height;
    const calculatedMaxVisibleItems = Math.ceil(layoutHeight / eventTileWithSeparatorHeight);
    setHeight(layoutHeight);
    setMaxVisibleItems(Math.max(minVisibleItems, calculatedMaxVisibleItems));
  });

  useLazyEffect(() => {
    if (!initialScrollPerformed.current && tunedEvent && events.length > 0 && date) {
      const initialEventIndex = findInitialEventIndex(events, tunedEvent, date);
      const offset = Math.max(0, initialEventIndex * eventTileWithSeparatorHeight - (height - eventTileWithSeparatorHeight) / 2);
      if (offset < contentContainerHeight) {
        flatList.current?.scrollToOffset({animated: false, offset});
        initialScrollPerformed.current = true;
        setRefreshing(false);
      }
    }
  }, [contentContainerHeight, tunedEvent, events, date], [height, eventTileWithSeparatorHeight]);

  const commonStyles = stylesUpdater.getStyles();
  const chromecastBarHeight = useIsChromecastBarVisible() ? dimensions.chromecast.statusBar.height : 0;

  const contentContainerStyle = useMemo<StyleProp<ViewStyle>>(() => {
    return [
      {height: Math.max(height, contentContainerHeight) + chromecastBarHeight},
      props.contentContainerStyle
    ];
  }, [height, contentContainerHeight, props.contentContainerStyle, chromecastBarHeight]);

  const handleScroll = useCallback((event) => {
    if (!onEpgScroll) {
      return;
    }
    const scrollY = Math.round(event.nativeEvent.contentOffset.y);
    const currentTopEventIndex = Math.max(0, Math.round(scrollY / eventTileWithSeparatorHeight));
    onEpgScroll(events[currentTopEventIndex]);
  }, [events, eventTileWithSeparatorHeight, onEpgScroll]);

  // TODO: CL-1754 Rewrite this component to use NitroxScrollView instead of FlatList
  return (
    <View
      style={[commonStyles.container, styles.container, props.containerStyle]}
      onLayout={onLayout}
      testID='mobile_vertical_zapper'
    >
      {height > 0 && (
        <NitroxFlatList
          ref={ref => flatList.current = ref}
          data={events}
          extraData={forceUpdateState}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={contentContainerStyle}
          refreshing={refreshing}
          keyExtractor={idKeyExtractor}
          ItemSeparatorComponent={renderSeparator}
          renderItem={renderItem}
          getItemLayout={getItemLayout}
          initialNumToRender={maxVisibleItems}
          onScroll={handleScroll}
        />
      )}
      <ActivityIndicator color={styles.activityIndicatorColor} size={dimensions.icon.medium} animating={refreshing} style={styles.activityIndicator} />
      <ListShadow direction={ListShadowPosition.Top} style={styles.topShadow} />
      <ListShadow direction={ListShadowPosition.Bottom} style={styles.bottomShadow} />
      <EventMoreActionsPopup
        visible={moreActionsPopupVisible}
        onClose={closeMoreActionsPopup}
        event={selectedEvent}
        isEventAuthorized={isEventAuthorized}
        tuneEvent={props.tuneEvent}
        openEventDetail={props.openEventDetail}
        showEpg={props.showEpg}
      />
    </View>
  );
};

export default React.memo(OneChannelEpg);
