import {createStyles} from 'common-styles';
import React, {useState} from 'react';
import {View} from 'react-native';

import {dimensions, isBigScreen, getValue} from 'common/constants';
import {TestProps} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {IconType, Icon} from 'components/Icon';
import NitroxInteractive from 'components/NitroxInteractive';
import NitroxText from 'components/NitroxText';
import {useScreenInfo} from 'hooks/Hooks';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  pinPad: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    width: '100%'
  },
  button: {
    width: '30%',
    alignItems: 'center',
    ...isBigScreen && {marginBottom: 15}
  },
  buttonContainer: {
    width: isBigScreen ? 100 : '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonContainerFocused: {
    backgroundColor: colors.pinKeyboard.button.focused.background,
    borderRadius: 8
  },
  buttonText: {
    color: colors.popup.text
  },
  buttonTextFocused: {
    color: colors.pinKeyboard.button.focused.text
  },
  icon: {
    color: colors.pinKeyboard.button.text
  },
  iconFocused: {
    color: colors.pinKeyboard.button.focused.text
  }
}));

type ButtonProps = {
  onPress: () => void;
  focused: boolean;
  hasTVPreferredFocus?: boolean;
  onFocusStateChanged: (focused: boolean) => void;
} & TestProps;
const Button: React.FC<ButtonProps> = ({
  children,
  focused,
  ...props
}) => {
  const {orientation} = useScreenInfo();
  const styles = stylesUpdater.getStyles();
  const buttonHeight = {height: getValue({tablet: 65, mobile: orientation.isLandscape ? '22%' : 65, defaultValue: 85})};
  return (
    <NitroxInteractive style={[styles.button, buttonHeight]} activeOpacity={isBigScreen ? 1 : undefined} {...props}>
      <View style={[styles.buttonContainer, focused && styles.buttonContainerFocused]}>
        {children}
      </View>
    </NitroxInteractive>
  );
};

interface IconButtonProps {
  icon: IconType;
  onPress: () => void;
}
const IconButton: React.FC<IconButtonProps> = ({icon, onPress}) => {
  const [focused, setFocused] = useState(false);
  const iconSize = isBigScreen ? dimensions.icon.small : dimensions.icon.xxsmall;
  const styles = stylesUpdater.getStyles();
  const iconColor = focused ? styles.iconFocused.color : styles.icon.color;
  return (
    <Button onPress={onPress} focused={focused} onFocusStateChanged={setFocused} testID={`button_${icon}`}>
      <Icon type={icon} size={iconSize} color={iconColor} />
    </Button>
  );
};

interface NumberButtonProps {
  value: number;
  onPress: (value: number) => void;
  hasTVPreferredFocus?: boolean;
}
const NumberButton: React.FC<NumberButtonProps> = ({value, onPress, hasTVPreferredFocus}) => {
  const [focused, setFocused] = useState(false);
  const styles = stylesUpdater.getStyles();
  return (
    <Button onPress={() => onPress(value)} focused={focused} onFocusStateChanged={setFocused} hasTVPreferredFocus={hasTVPreferredFocus} testID={`button_${value}`}>
      <NitroxText style={[styles.buttonText, focused && styles.buttonTextFocused]} textType='pin-pad-number-button'>{value}</NitroxText>
    </Button>
  );
};

export interface PinPadProps {
  onBackspace: () => void;
  onNumberPress: (value: number) => void;
  onSubmit: () => void;
}
const PinPad: React.FC<PinPadProps> = ({onBackspace, onNumberPress, onSubmit}) => {
  const styles = stylesUpdater.getStyles();
  return (
    <View style={styles.pinPad}>
      <NumberButton value={1} onPress={onNumberPress} hasTVPreferredFocus />
      <NumberButton value={2} onPress={onNumberPress} />
      <NumberButton value={3} onPress={onNumberPress} />
      <NumberButton value={4} onPress={onNumberPress} />
      <NumberButton value={5} onPress={onNumberPress} />
      <NumberButton value={6} onPress={onNumberPress} />
      <NumberButton value={7} onPress={onNumberPress} />
      <NumberButton value={8} onPress={onNumberPress} />
      <NumberButton value={9} onPress={onNumberPress} />
      <IconButton icon={IconType.Backspace} onPress={onBackspace} />
      <NumberButton value={0} onPress={onNumberPress} />
      <IconButton icon={IconType.ArrowRight} onPress={onSubmit} />
    </View>
  );
};

export default React.memo(PinPad);
