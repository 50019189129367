import React from 'react';

import {testAutomationFeatures} from 'common/constants';

import {TAFocusReporter} from 'components/taRelated/TAFocusReporter';
import {useWebFocusListener} from 'web/src/hooks/useWebFocusedElement';

function InnerListener() {
  useWebFocusListener(element => TAFocusReporter.reportFocus(element));
  return null;
}

export function WebTAFocusReporter(): React.ReactElement | null {
  return testAutomationFeatures.enableFocusReporter
    ? <InnerListener />
    : null;
}
