import React, {useState, useCallback, ReactNode, useRef, Ref, useEffect, useContext, useMemo, RefObject} from 'react';
import {TouchableOpacity, TouchableOpacityProps, View, GestureResponderEvent, StyleProp, ViewStyle, LayoutChangeEvent} from 'react-native';

import {isDesktopBrowser, isWeb, debugFeatures, usingNitroxFocusEngine, isMobile, Direction} from 'common/constants';
import {FocusOptions, TestProps, NavigationFocusState} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {SupportedKeys} from 'components/KeyEventManager';
import {useLazyEffect, useChangeEffect, useNavigationFocusState, useNavigation, useFunction} from 'hooks/Hooks';

import DebugWrapper, {DebugInfo, DebugWrapperType} from './focusManager/DebugWrapper';
import {FocusManager} from './focusManager/FocusManager';
import {FocusableComponent} from './focusManager/FocusManagerTypes';
import {FocusParentContext} from './FocusParent';
import {FocusScope, FocusPrecedenceContext} from './FocusPrecedence';
import {useFocusSwitchInterface} from './FocusSwitch';
import {SupportedDirectionKeys, KeyEventManager} from './KeyEventManager';
import {NitroxInteractiveControllerContext} from './NitroxInteractiveControllerContext';

const disabledParallax = {enabled: false};

function useFocused(
  ref: RefObject<FocusableComponent>,
  params?: {
    focusOnMount?: boolean;
    focusPriority?: number;
    freezeFocus?: boolean;
    exitEdges?: Direction[];
    onFocus?: (event?: any, options?: FocusOptions) => void;
    onBlur?: (event?: any) => void;
    disabled?: boolean;
    debugName?: string;
  }
) {
  const [focused, setFocused] = useState(false);
  const focusParentId = useContext(FocusParentContext);
  const disabled = params && params.disabled === true;
  const focusOnMount = params && params.focusOnMount;
  const focusPriority = params?.focusPriority ?? 0;
  const freezeFocus = params?.freezeFocus;
  const exitEdges = params?.exitEdges;
  const onFocusParams = useRef<[any, FocusOptions | undefined]>([undefined, undefined]);
  const onBlurEvent = useRef<any>();
  const focusSwitch = useFocusSwitchInterface();
  const debugName = params?.debugName;

  const onFocus = useFunction((event?: any, options?: FocusOptions) => {
    if (debugName) {
      Log.info('useFocused', `Focused ${debugName}`, event);
    }
    onFocusParams.current = [event, options];
    setFocused(true);
  });

  const onBlur = useFunction((event?: any) => {
    if (debugName) {
      Log.info('useFocused', `Blurred ${debugName}`, event);
    }
    onBlurEvent.current = event;
    setFocused(false);
  });

  const clearFocusOnUnmount = useFunction(() => {
    focused && focusSwitch.off();
  });

  useEffect(() => {
    return clearFocusOnUnmount;
  }, [clearFocusOnUnmount]);

  useChangeEffect(() => {
    if (focused) {
      FocusManager.getInstance().reportFocus(ref);
      params?.onFocus?.(...onFocusParams.current);
    } else {
      params?.onBlur?.(onBlurEvent.current);
    }
  }, [focused], [params, ref]);

  useLazyEffect(() => {
    if (disabled) {
      return;
    }
    FocusManager.getInstance().registerNode(ref, {focusParentId, geometry: null, priority: focusPriority, freezeFocus, exitEdges, debugName});
    if (focused) {
      FocusManager.getInstance().reportFocus(ref);
    }
    return () => FocusManager.getInstance().unregisterNode(ref);
  }, [ref, disabled, exitEdges], [focused, focusPriority, freezeFocus, focusParentId, debugName]);

  useChangeEffect(() => {
    FocusManager.getInstance().updateNodeParent(ref, focusParentId);
  }, [focusParentId], [ref]);

  useChangeEffect(() => {
    FocusManager.getInstance().updateNodePriority(ref, focusPriority);
  }, [focusPriority], [ref]);

  useLazyEffect(() => {
    if (focusOnMount) {
      FocusManager.getInstance().forceFocus(ref);
    }
  }, [], [focusOnMount]);

  const updateGeometry = useCallback(async () => {
    FocusManager.getInstance().updateNodeGeometry(ref);
  }, [ref]);

  return {focused, onFocus, onBlur, updateGeometry};
}

type NativeTouchableProps = TouchableOpacityProps;

export type FocusPriorityProps = {
  focusPriority?: number;
}

type FocusPrisonProps = {
  freezeFocus?: boolean;
  exitEdges?: Direction[];
}

type DebugProps = {
  debugName?: string;
}

export type NitroxInteractiveProps = Omit<NativeTouchableProps, 'onFocus'> & {
  children?: ReactNode;
  focusOnMount?: boolean;
  onFocusStateChanged?: (focused: boolean) => void;
  styleFocused?: StyleProp<ViewStyle>;
  onFocus?: (event?: GestureResponderEvent, options?: FocusOptions) => void;
  scrollOnFocus?: boolean; // web only, default: true, set false if scroll is handled manually (e.g. in Swimlane/SwimlaneStack)
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onMouseMove?: () => void;
  onPress?: (event?: GestureResponderEvent) => void;
  underlayColor?: string;
  mouseOnly?: boolean;
  focusOnPress?: boolean;
  onLayout?: (event: LayoutChangeEvent) => void;
} & FocusPriorityProps & FocusPrisonProps & TestProps & DebugProps;

const NitroxInteractive = (props: NitroxInteractiveProps, ref: Ref<any>) => {
  const {
    onFocusStateChanged: propsOnFocusStateChanged,
    onPress: propsOnPress,
    onFocus: propsOnFocus,
    onLayout: propsOnLayout,
    focusPriority,
    scrollOnFocus = true,
    onBlur: propsOnBlur,
    hasTVPreferredFocus,
    freezeFocus,
    exitEdges,
    ...otherProps
  } = props;
  const localRef = useRef<any>(null);
  const [debugType, setDebugType] = useState<DebugInfo>({type: DebugWrapperType.Idle});
  const {scope: focusScope} = useContext(FocusPrecedenceContext);
  const controls = useContext(NitroxInteractiveControllerContext);
  const disabled = props.disabled || (focusScope !== FocusScope.All);
  const refInitialized = useRef(false);
  const focusSwitch = useFocusSwitchInterface();

  const navigation = useNavigation();
  const outsideNavigationScope = !navigation;
  const navigationFocusState = useNavigationFocusState(navigation);
  const inFocusedScreen = navigationFocusState === NavigationFocusState.IsFocused || navigationFocusState === NavigationFocusState.IsFocusing;
  const [preferredFocus, setPreferredFocus] = useState(hasTVPreferredFocus);
  useChangeEffect(() => {
    if (hasTVPreferredFocus) {
      setPreferredFocus(outsideNavigationScope || inFocusedScreen);
    }
    if (!hasTVPreferredFocus && preferredFocus) {
      setPreferredFocus(false);
    }
  }, [inFocusedScreen, hasTVPreferredFocus, outsideNavigationScope], [preferredFocus]);

  useChangeEffect(() => {
    if (isWeb && !props.disabled && preferredFocus && refInitialized.current) {
      FocusManager.getInstance().forceFocus(localRef);
    }
  }, [preferredFocus]);

  const onFocusHandler = useCallback((event, options) => {
    propsOnFocus && propsOnFocus(event, options);
    propsOnFocusStateChanged && propsOnFocusStateChanged(true);
    focusSwitch.on();
  }, [propsOnFocus, propsOnFocusStateChanged, focusSwitch]);

  const onBlurHandler = useCallback(event => {
    propsOnBlur && propsOnBlur(event);
    propsOnFocusStateChanged && propsOnFocusStateChanged(false);
    focusSwitch.off();
  }, [propsOnBlur, propsOnFocusStateChanged, focusSwitch]);

  useChangeEffect(() => {
    // information about focus state is not held here
    // therefore we cannot just fire 'onBlurHandler'
    // for some advanced cases or problems with FocusPrecedence nature, consider adding 'onDisable' prop
    if (disabled) {
      focusSwitch.reset();
    }
  }, [disabled], [focusSwitch]);

  const {focused, onFocus, onBlur, updateGeometry} = useFocused(localRef, {
    focusOnMount: !!props.focusOnMount,
    focusPriority,
    freezeFocus,
    exitEdges,
    onFocus: onFocusHandler,
    onBlur: onBlurHandler,
    disabled: disabled || props.mouseOnly,
    debugName: props.debugName
  });

  const highlightAsNext = useCallback((direction: SupportedDirectionKeys) => {
    if (!debugFeatures.focusManager) {
      return;
    }
    setDebugType({type: DebugWrapperType.PossibleNext, direction});
    setTimeout(() => {
      setDebugType({type: DebugWrapperType.Idle});
    }, 1000);
  }, []);

  const imperativeHandles = useMemo(() => ({
    highlightAsNext: highlightAsNext
  }), [highlightAsNext]);

  const onRefReady = useCallback((component: TouchableOpacity | null) => {

    const newComponent = component ? {
      // @ts-ignore this order (props first) is intentional
      props,
      ...component,
      ...imperativeHandles
    } : null;
    localRef.current = newComponent;
    if (ref) {
      if (typeof ref === 'function') {
        ref(newComponent);
      } else {
        // @ts-ignore this is dirty stuff, but there are big issues due to refs' asynchronous nature
        ref.current = newComponent;
      }
    }

    if (!refInitialized.current && component) {
      refInitialized.current = true;
      if (isWeb && preferredFocus && !props.disabled) {
        FocusManager.getInstance().forceFocus(localRef);
      }
    }
  }, [props, imperativeHandles, ref, preferredFocus]);

  useEffect(() => {
    if (localRef.current) {
      onRefReady(localRef.current);
    }
  }, [onRefReady]);

  const onMouseMove = useCallback((event: any) => {
    if (disabled || (event && event.movementX === 0 && event.movementY === 0)) {
      return;
    }
    const eventToPass = isDesktopBrowser ? event : undefined;
    onFocus((eventToPass), {preventScroll: true});
  }, [onFocus, disabled]);

  const onPress = useCallback((event: GestureResponderEvent) => {
    props.focusOnPress && onFocus(event);
    // don't send key event if element was pressed using mouse
    if (event.type !== 'mouseup') {
      // dirty hack for 'OK' events not being receved by KeyEventManager on touchable objects (aTV and web based platforms)
      usingNitroxFocusEngine && KeyEventManager.getInstance().emit('keyup', {key: SupportedKeys.Ok});
    }
    propsOnPress && propsOnPress(event);
  }, [propsOnPress, onFocus, props.focusOnPress]);

  useEffect(() => {
    if (disabled) {
      propsOnFocusStateChanged && propsOnFocusStateChanged(false);
    }
  }, [disabled, propsOnFocusStateChanged]);

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    propsOnLayout?.(event);
    if (!usingNitroxFocusEngine || controls.omitGeometryCaching) {
      return;
    }
    updateGeometry();
  }, [controls.omitGeometryCaching, propsOnLayout, updateGeometry]);

  const touchableProperties = {
    onMouseMove,
    onMouseLeave: onBlur,
    focusPriority,
    onFocus,
    scrollOnFocus,
    onBlur,
    onPress,
    disabled,
    tvParallaxProperties: disabledParallax,
    hasTVPreferredFocus: preferredFocus,
    ...otherProps,
    ref: onRefReady,
    onLayout,
    ...focused && {style: [props.style, props.styleFocused]},
    touchSoundDisabled: true
  };

  if (disabled) {
    return <View {...otherProps}>{props.children}</View>;
  }

  /* eslint-disable schange-rules/local-alternative */
  return (
    <TouchableOpacity {...touchableProperties}>
      {debugFeatures.focusManager &&
        <DebugWrapper debugInfo={debugType} />}
      {props.children}
    </TouchableOpacity>
  );
  /* eslint-enable schange-rules/local-alternative */
};

const MobileInteractive = (props: NitroxInteractiveProps, ref: Ref<FocusableComponent>) => {
  if (props.disabled) {
    return <View {...props}>{props.children}</View>;
  }

  /* eslint-disable schange-rules/local-alternative */
  return (
    // @ts-ignore incompatibility of 'NativeSyntheticEvent<TargetedEvent>' and 'GestureResponderEvent'
    <TouchableOpacity
      ref={ref}
      {...props}
      // This prop works on android mobile, don't pass it to the component to avoid triggering keyboard hiding
      hasTVPreferredFocus={false}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      touchSoundDisabled
    >
      {props.children}
    </TouchableOpacity>
  );
  /* eslint-enable schange-rules/local-alternative */
};

export default isMobile ? React.forwardRef(MobileInteractive) : React.forwardRef(NitroxInteractive);
