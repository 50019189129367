import {ErrorType} from 'mw/api/Error';

import {ChromecastInterface} from './ChromecastInterface';

export const ChromecastModule: ChromecastInterface = {
  getDevices: () => Promise.reject(ErrorType.NotImplemented),
  getConnectionDetails: () => Promise.reject(ErrorType.NotImplemented),
  connectDevice: () => Promise.reject(ErrorType.NotImplemented),
  disconnectDevice: () => Promise.reject(ErrorType.NotImplemented),

  castMedia: () => Promise.reject(ErrorType.NotImplemented),
  play: () => Promise.reject(ErrorType.NotImplemented),
  pause: () => Promise.reject(ErrorType.NotImplemented),
  seek: () => Promise.reject(ErrorType.NotImplemented),
  setVolume: () => Promise.reject(ErrorType.NotImplemented),
  getVolume: () => Promise.reject(ErrorType.NotImplemented),
  setAudioTrack: () => Promise.reject(ErrorType.NotImplemented),
  getAudioTracks: () => Promise.reject(ErrorType.NotImplemented),
  setSubtitleTrack: () => Promise.reject(ErrorType.NotImplemented),
  getSubtitleTracks: () => Promise.reject(ErrorType.NotImplemented),
  stop: () => Promise.reject(ErrorType.NotImplemented)
};
