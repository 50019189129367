import {compactMap} from 'common/helpers/ArrayHelperFunctions';
import {Log} from 'common/Log';

import {ComponentType, ComponentDataSourceType, ComponentSettings, ComponentDataSourceSettings} from 'mw/api/CMSInterface';
import {Filter} from 'mw/api/Filter';
import {ADR8Utils} from 'mw/bo-proxy/bo/adr8/ADR8Utils';
import {Component} from 'mw/cms/Component';
import {ComponentGroup} from 'mw/cms/ComponentGroup';
import {Page} from 'mw/cms/Page';
import {SpecialFilter} from 'mw/common/ContentCache';

import {UXManager} from './UXManager';

const tag = 'PageMapper';

type Parser<T, U> = (rawData: T) => U | null;

function oneOf<T, U>(...parsers: Parser<T, U>[]): Parser<T, U> {
  return data => {
    for (const parser of parsers) {
      const parsed = parser(data);
      if (parsed) {
        return parsed;
      }
    }
    return null;
  };
}

type UXMComponentDataSourceModel = {
  type?: ComponentDataSourceType;
  /**
   * List of ADR 7 categories' ids, ADR8 filters or a one of SpecialFilters.
   */
  references?: (string | SpecialFilter)[];
  settings?: ComponentDataSourceSettings;
}

type UXMComponentModel = {
  type?: ComponentType;
  title?: string;
  placeholder?: string;
  tags?: string[];
  dataSource?: UXMComponentDataSourceModel;
  data?: ComponentSettings | string; // string for legacy component definitions
  extraData?: string; // for legacy component definitions
};

type UXMPageModel = {
  slug: string;
  title?: string;
  type: string;
  layout?: string;
  components?: UXMComponentModel[];
};

const cacheTimeInMinutesTagName = 'cache-time-in-minutes';
const isPersonalizedTagName = 'is-personalized';

const legacyComponentParser: Parser<UXMComponentModel, Component> = component => {
  const {type, data, extraData, title = '', tags = []} = component;
  if (!type || !data) {
    return null;
  }
  const componentTags = ADR8Utils.tagsFromJson(tags);
  switch (type) {
    case ComponentType.Menu: {
      if (typeof data !== 'string') {
        return null;
      }
      const id = data.split('/')[2];
      if (!id) {
        return null;
      }
      return new Component({
        title,
        type,
        dataSource: {
          type: ComponentDataSourceType.Menu,
          menuSlug: id
        }
      });
    }

    case ComponentType.Filterng:
      if (typeof data !== 'string') {
        return null;
      }
      return new Component({
        title,
        type,
        dataSource: {
          type: ComponentDataSourceType.Filter,
          filters: [{
            value: data,
            cacheTimeInMinutes: ADR8Utils.findTagValueAsNumber(componentTags, cacheTimeInMinutesTagName, 0),
            isPersonal: false,
            isSpecial: false
          }]
        }
      });

    case ComponentType.AdrenalinNode: {
      if (typeof data !== 'string') {
        return null;
      }
      const id = data.split('\"')[3];
      if (!id) {
        return null;
      }
      return new Component({
        title,
        type,
        dataSource: {
          type: ComponentDataSourceType.Node,
          filters: [{
            value: id,
            aliasType: UXManager.defaultAlias,
            cacheTimeInMinutes: ADR8Utils.findTagValueAsNumber(componentTags, cacheTimeInMinutesTagName, 0),
            isPersonal: ADR8Utils.findTagValueAsBoolean(componentTags, isPersonalizedTagName),
            isSpecial: false
          }]
        }
      });
    }

    case ComponentType.Content:
      if (typeof data !== 'string') {
        return null;
      }
      return new Component({
        title,
        type,
        dataSource: {
          type: ComponentDataSourceType.Content,
          data,
          extraData
        }
      });

    case ComponentType.Custom:
      if (typeof data !== 'string') {
        return null;
      }
      try {
        const {specialFilter} = JSON.parse(data);
        if (typeof specialFilter !== 'string' || !Object.values(SpecialFilter).includes(specialFilter as SpecialFilter)) {
          return null;
        }
        return new Component({
          title,
          type,
          dataSource: {
            type: ComponentDataSourceType.SpecialFilter,
            filters: [{
              value: specialFilter,
              cacheTimeInMinutes: ADR8Utils.findTagValueAsNumber(componentTags, cacheTimeInMinutesTagName, 0),
              isPersonal: false,
              isSpecial: true
            }]
          }
        });
      } catch {
        return null;
      }

    default:
      return null;
  }
};

function requiresAlias(dataSourceType: ComponentDataSourceType): boolean {
  return dataSourceType === ComponentDataSourceType.Node || dataSourceType === ComponentDataSourceType.Nodes;
}

const componentParser: Parser<UXMComponentModel, Component> = (component: UXMComponentModel) => {
  const {type, data, dataSource, title = ''} = component;
  if (!type || !dataSource || typeof data === 'string') {
    return null;
  }
  switch (type) {
    case ComponentType.Swimlane:
    case ComponentType.Grid:
    case ComponentType.SwimlaneWithHighlightedBanner:
    case ComponentType.Hotspot: {
      const {type: dataSourceType, references, settings} = dataSource;
      if (!references) {
        Log.error(tag, `Component of type ${type} with no references`);
        return null;
      }
      if (!(
        dataSourceType === ComponentDataSourceType.Filter ||
        dataSourceType === ComponentDataSourceType.Filters ||
        dataSourceType === ComponentDataSourceType.Node ||
        dataSourceType === ComponentDataSourceType.Nodes ||
        dataSourceType === ComponentDataSourceType.SpecialFilter
      )) {
        Log.error(tag, `Component of type ${type} with invalid dataSource type: ${dataSourceType}`);
        return null;
      }
      const {isPersonalized, cacheTimeInMinutes = 0} = settings ?? {};
      const filters: Filter[] = references.map(ref => {
        return {
          value: `${ref}`,
          aliasType: requiresAlias(dataSourceType) ? UXManager.defaultAlias : undefined,
          cacheTimeInMinutes: cacheTimeInMinutes,
          isPersonal: !!isPersonalized,
          isSpecial: Object.values(SpecialFilter).includes(ref as SpecialFilter)
        };
      });
      return new Component({
        title,
        type,
        dataSource: {
          type: dataSourceType,
          filters: filters[0].isSpecial ? [filters[0]] : filters
        },
        settings: data
      });
    }

    case ComponentType.GridMenu:
    case ComponentType.SwimlaneMenu: {
      const {type: dataSourceType, references} = dataSource;
      if (!references) {
        Log.error(tag, `Component of type ${type} with no references`);
        return null;
      }
      if (dataSourceType !== ComponentDataSourceType.Menu) {
        Log.error(tag, `Component of type ${type} with invalid dataSource type: ${dataSourceType}`);
        return null;
      }
      return new Component({
        title,
        type,
        dataSource: {
          type: dataSourceType,
          menuSlug: references[0]
        },
        settings: data
      });
    }

    case ComponentType.Banner:
    case ComponentType.SwimlaneBanner:
    case ComponentType.SwimlaneLiveEvents:
      Log.info(tag, `Parsing component of type ${type} not implemented yet!`);
      return null;

    default:
      return null;
  }
};

export function pageFromJson(json: unknown): Page | null {
  if (typeof json !== 'object' || json === null) {
    return null;
  }
  const {title = '', slug, type = '', layout = '', components = []} = json as UXMPageModel;
  if (!slug) {
    return null;
  }
  // support only one placeholder
  const placeholderName = components[0]?.placeholder;
  const firstPlaceholderComponents = components.filter(c => c.placeholder === placeholderName);
  const parse = oneOf(legacyComponentParser, componentParser);
  return new Page(
    title,
    slug,
    type,
    layout,
    [new ComponentGroup(placeholderName ?? '', compactMap(firstPlaceholderComponents, parse))]
  );
}
