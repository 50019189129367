import {createStyles} from 'common-styles';
import React, {forwardRef, useState} from 'react';
import {StyleProp, View, ViewStyle, TextStyle} from 'react-native';

import {dimensions, isBigScreen, isMobile} from 'common/constants';
import {humanCaseToSnakeCase} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {FocusableComponent} from 'components/focusManager/FocusManagerTypes';
import NitroxInteractive from 'components/NitroxInteractive';
import NitroxText from 'components/NitroxText';

const TAG = 'TabBar';
const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  tabBar: {
    flexDirection: 'row',
    alignItems: 'stretch'
  },
  itemContainer: {
    marginBottom: dimensions.margins.xsmall
  },
  itemContainerMobile: {
    flex: 1,
    alignItems: 'flex-start'
  },
  title: {
    color: colors.tabBarSelector.bar.title,
    opacity: dimensions.opacity.xlow
  },
  titleSelected: {
    opacity: 1
  },
  line: {
    height: 3,
    backgroundColor: colors.tabBarSelector.bar.underscore,
    opacity: 0
  },
  lineSelected: {
    borderRadius: 2,
    opacity: 1
  },
  focused: {
    opacity: dimensions.opacity.xxxhigh
  }
}));

type TabBarItemProps = {
  title: string;
  tabContainerStyle?: ViewStyle;
  titleContainerStyle?: ViewStyle;
  titleTextStyle?: TextStyle;
  onPress: () => void;
  isSelected?: boolean;
  activeUnderscore?: boolean;
}

const TabBarItem = React.forwardRef<FocusableComponent, TabBarItemProps>((props, ref) => {
  const [focused, onFocusStateChanged] = useState(!!props.isSelected);
  const styles = stylesUpdater.getStyles();
  return (
    <View style={[styles.itemContainer, isMobile && styles.itemContainerMobile, props.tabContainerStyle]}>
      <NitroxInteractive
        ref={ref}
        onFocusStateChanged={onFocusStateChanged}
        onPress={props.onPress}
        testID={`tab_bar_${humanCaseToSnakeCase(props.title)}`}
      >
        <View style={props.titleContainerStyle}>
          <NitroxText
            textType='headline'
            style={[styles.title, props.isSelected && styles.titleSelected, isBigScreen && focused && styles.focused, props.titleTextStyle]}
          >
            {props.title}
          </NitroxText>
        </View>
        {props.activeUnderscore && <View style={[styles.line, props.isSelected && styles.lineSelected, isBigScreen && focused && styles.focused]} />}
      </NitroxInteractive>
    </View>
  );
});
TabBarItem.displayName = TabBarItem.name;

type TabBarItem = {
  title: string;
  tabContainerStyle?: ViewStyle;
  titleContainerStyle?: ViewStyle;
  titleTextStyle?: TextStyle;
}

type TabBarProps = {
  items: TabBarItem[];
  onItemPress: (itemIndex: number) => void;
  selectedItemIndex?: number;
  style?: StyleProp<ViewStyle>;
  activeUnderscore?: boolean;
}

const TabBar: React.FunctionComponent<TabBarProps> = (props, ref) => {
  let selectedItemIndex = props.selectedItemIndex || 0;

  if (selectedItemIndex < 0 || selectedItemIndex >= props.items.length) {
    Log.error(TAG, `No item with index ${selectedItemIndex}`);
    selectedItemIndex = 0;
  }

  const styles = stylesUpdater.getStyles();
  return (
    <View style={[styles.tabBar, props.style]}>
      {props.items.map((item, index) => (
        <TabBarItem
          ref={index === 0 ? ref : null}
          key={index}
          title={item.title}
          tabContainerStyle={item.tabContainerStyle}
          titleContainerStyle={item.titleContainerStyle}
          titleTextStyle={item.titleTextStyle}
          isSelected={index === selectedItemIndex}
          onPress={() => props.onItemPress(index)}
          activeUnderscore={props.activeUnderscore}
        />
      ))}
    </View>
  );
};

export default forwardRef(TabBar);
