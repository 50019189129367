import {createStyles} from 'common-styles';
import React from 'react';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';

import {assets} from 'brand/Resources';
import NitroxLogo from 'components/navigation/NitroxLogo';
import NitroxImage, {NitroxImageProps} from 'components/NitroxImage';
import NitroxText from 'components/NitroxText';

const styles = createStyles({
  logo: {
    ...dimensions.logo.credentials,
    marginBottom: dimensions.margins.small
  },
  title: {
    marginTop: dimensions.margins.xxxxLarge
  }
});

const dynamicStyles = new StylesUpdater(colors => createStyles({
  successTitle: {
    color: colors.registration.accountActivation.title,
    marginTop: dimensions.margins.medium,
    textAlign: 'center'
  }
}));

type Props = {
  /** image associated with the result */
  image: NitroxImageProps['svg'];
  title: string;
  message?: string;
}

/**
 * A component that displays result of a form submission. It contains:
 * 1. branded logo
 * 2. image
 * 3. title
 * 4. optional message
 * 5. any children passed from parent component - e.g. action buttons
 */
const FormResult: React.FC<Props> = ({image, title, message, children}) => {
  const {successTitle: textStyle} = dynamicStyles.getStyles();
  return (
    <>
      <NitroxLogo
        pictureUrl={assets.common.loginScreenLogo}
        style={styles.logo}
      />
      <NitroxImage svg={image} />
      <NitroxText textType='headline' style={[textStyle, styles.title]}>
        {title}
      </NitroxText>
      {message && (
        <NitroxText textType='form-result-message' style={textStyle}>
          {message}
        </NitroxText>
      )}
      {children}
    </>
  );
};

export default FormResult;
