import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {View} from 'react-native';

import {dimensions, RADIUS, epgItemHeight} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';

import {Channel} from 'mw/api/Metadata';

import {ChannelIcon, ChannelIconType, channelIconConstants} from 'components/ChannelIcon';
import NitroxText from 'components/NitroxText';

const channelListDynamicStyles = new StylesUpdater(colors => createStyles({
  channelNumberBackgroundActive: {
    backgroundColor: colors.epgScreen.grid.tile.active
  },
  channelNumberBackground: {
    backgroundColor: colors.epgScreen.grid.tile.unfocused
  },
  channelNumber: {
    color: colors.columnTile.title
  }
}));

const channelNumberContainerWidth = 40;
const channelIconContainerMargin = dimensions.margins.xsmall;
const channelIconContainerMarginRight = dimensions.margins.small;
export const epgChannelIconWidth = channelIconContainerMarginRight + channelIconContainerMargin + channelIconConstants.epg.scaledSize;

const channelListStaticStyles = createStyles({
  channelListItemContainer: {
    flex: 1,
    flexDirection: 'row'
  },
  channelNumberContainer: {
    flex: 1,
    marginRight: dimensions.margins.xsmall,
    marginVertical: dimensions.margins.xsmall,
    justifyContent: 'center',
    alignItems: 'center',
    width: channelNumberContainerWidth,
    borderTopRightRadius: RADIUS,
    borderBottomRightRadius: RADIUS
  },
  channelIconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    margin: channelIconContainerMargin,
    marginRight: channelIconContainerMarginRight
  },
  unavailableChannel: {
    opacity: 0.2
  }
});

type ChannelViewProps = {
  channel: Channel;
  isCurrent: boolean;
  index: number;
};

const ChannelView: React.FC<ChannelViewProps> = React.memo(({
  channel,
  isCurrent,
  index
}) => {
  const dynamicStyles = channelListDynamicStyles.getStyles();
  const numberBackgroundStyle = isCurrent ? dynamicStyles.channelNumberBackgroundActive : dynamicStyles.channelNumberBackground;

  const channelStyle = useMemo(() => channel.isAvailableByPolicy ? {} : channelListStaticStyles.unavailableChannel, [channel.isAvailableByPolicy]);

  return (
    <View
      style={[channelListStaticStyles.channelListItemContainer, {
        position: 'absolute',
        top: index * epgItemHeight,
        left: 0,
        width: '100%',
        height: epgItemHeight
      }]}
    >
      <View style={[channelListStaticStyles.channelNumberContainer, numberBackgroundStyle]}>
        <NitroxText textType='epg-channel-number' style={[dynamicStyles.channelNumber, channelStyle]}>{channel.lcn}</NitroxText>
      </View>
      <View style={channelListStaticStyles.channelIconContainer}>
        <ChannelIcon
          type={ChannelIconType.Epg}
          isSelected={isCurrent}
          channelId={channel.id}
        />
      </View>
    </View>
  );
});
ChannelView.displayName = 'ChannelView';

export default ChannelView;
