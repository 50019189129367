import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {isPhone} from 'common/constants';

import {PopupAction} from 'components/Popup';
import SelectionPopup, {SelectionPopupSection} from 'components/SelectionPopup';
import {useLazyEffect} from 'hooks/Hooks';
import {PostProcessors} from 'locales/i18nPostProcessors';

const popupActions: PopupAction[] = [PopupAction.POSITIVE, PopupAction.NEGATIVE];

enum OptionKey {
  Allow = 'settings.parentalControl.allow',
  RequestPin = 'settings.parentalControl.requestPin'
}

type Props = {
  visible: boolean;
  title: string;
  blockUnrated: boolean;
  onConfirm: (blockUnrated: boolean) => void;
  onClose: () => void;
}

const ParentalControlUnratedSelectionPopup: React.FC<Props> = props => {
  const {visible, title, blockUnrated, onConfirm, onClose} = props;
  const {t} = useTranslation();
  const [option, setOption] = useState(OptionKey.Allow);

  useLazyEffect(() => {
    if (visible) {
      setOption(blockUnrated ? OptionKey.RequestPin : OptionKey.Allow);
    }
  }, [visible], [blockUnrated]);

  const popupProps = useMemo(() => ({
    actions: popupActions,
    positiveLabel: t('common.ok', {postProcess: PostProcessors.ToUpperCase}),
    negativeLabel: t('common.cancel'),
    onPositive: () => onConfirm(option === OptionKey.RequestPin),
    onNegative: onClose
  }), [t, onConfirm, option, onClose]);

  const sections: SelectionPopupSection[] = useMemo(() => {
    const options = Object.values(OptionKey).map(optionKey => ({
      key: optionKey,
      text: t(optionKey, {postProcess: PostProcessors.ToUpperCase}),
      selected: optionKey === option,
      onPress: () => setOption(optionKey)
    }));
    return [{options, center: true}];
  }, [t, option]);

  return (
    <SelectionPopup
      title={title}
      sections={sections}
      visible={visible}
      onClose={onClose}
      popupProps={popupProps}
      columnCount={isPhone ? 1 : 2}
    />
  );
};

export default React.memo(ParentalControlUnratedSelectionPopup);
