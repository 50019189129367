import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {IconType} from 'components/Icon';
import {ModalSelect, ModalSelectOption} from 'components/ModalSelect';
import {SearchFilterValue, SearchFilterOptionsKeys} from 'screens/search/SearchHelperTypes';

type Props = {
  modalVisible: boolean;
  searchField: SearchFilterValue;
  onModalClosed: (searchField: SearchFilterValue) => void;
}

const SearchFilterSelect: React.FunctionComponent<Props> = props => {
  const {modalVisible, searchField, onModalClosed} = props;
  const {t} = useTranslation();
  const onClose = useCallback(() => onModalClosed(searchField), [onModalClosed, searchField]);

  const options = useMemo((): ModalSelectOption<SearchFilterValue>[] => {
    const filterOptionsKeys = Object.keys(SearchFilterOptionsKeys) as SearchFilterValue[];
    const filterOptionsTranslations = Object.values(SearchFilterOptionsKeys);

    return filterOptionsKeys.map((key: SearchFilterValue, index: number) => {
      return {
        key: key,
        value: key,
        label: t(filterOptionsTranslations[index])
      };
    });
  }, [t]);

  return (
    <ModalSelect
      options={options}
      defaultValue={searchField}
      visible={modalVisible}
      onChange={onModalClosed}
      onClose={onClose}
      selectedOptionIcon={IconType.Check}
    />
  );
};

export default React.memo(SearchFilterSelect) as typeof SearchFilterSelect;
