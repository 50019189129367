import {createStyles} from 'common-styles';
import React, {useMemo, useCallback, useState, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {ScrollView, LayoutChangeEvent, View, ActivityIndicator} from 'react-native';

import {dimensions, isDesktopBrowser} from 'common/constants';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Media, MediaType} from 'mw/api/Metadata';

import FocusParent, {useFocusParent} from 'components/FocusParent';
import {IconType} from 'components/Icon';
import {ListItemProps} from 'components/ListView';
import {STBMenuState, SideMenuState} from 'components/navigation/NavigationHelperTypes';
import {NitroxInteractiveController} from 'components/NitroxInteractiveControllerContext';
import NitroxText from 'components/NitroxText';
import {SideMenu, sideMenuHorizontalSpace} from 'components/SideMenu';
import {useNavigation, useScrollViewPaging, useLazyEffect, useChangeEffect} from 'hooks/Hooks';
import NitroxScreen from 'screens/NitroxScreen';

import WatchListSortOrderSelect from './WatchListSortOrderSelect';
import WatchListTile, {watchListTileMarginHorizontal} from './WatchListTile';
import {WatchListSubscreenProps} from './WatchListTypes';

const TAG = 'WatchListScreenGrosso';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  screen: {
    paddingLeft: dimensions.mainMenu.marginHorizontal - sideMenuHorizontalSpace,
    flex: 1,
    flexDirection: 'row'
  },
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: dimensions.screen.container.paddingTop,
    paddingLeft: dimensions.margins.xxLarge,
    paddingRight: dimensions.screen.container.paddingHorizontal
  },
  sectionHeader: {
    marginBottom: dimensions.margins.large
  },
  selectionMenu: {
    paddingRight: watchListTileMarginHorizontal / 2
  },
  focusParentGrid: {
    flexDirection: 'row',
    flex: 1,
    width: '100%',
    marginLeft: -watchListTileMarginHorizontal  // compensate tiles margins
  },
  header: {
    color: colors.recordingsScreen.title
  },
  grid: {
    marginTop: dimensions.screen.recordings.grid.marginTop,
    flex: 1
  },
  gridContent: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '100%'
  },
  fakeTile: {
    width: dimensions.tile.width,
    height: dimensions.tile.height,
    marginHorizontal: watchListTileMarginHorizontal
  },
  actions: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    marginTop: dimensions.margins.xxxLarge,
    marginLeft: watchListTileMarginHorizontal,
    flexWrap: 'wrap',
    height: 'auto'
  },
  sortOrderSelect: {
    marginTop: 0,
    marginBottom: 0
  },
  activityIndicator: {
    flex: 1,
    alignSelf: 'center'
  }
}));

const headerTextKeys: Record<MediaType, string> = {
  [MediaType.Channel]: 'common.channel',
  [MediaType.Event]: 'common.tv',
  [MediaType.InvalidMedia]: '',
  [MediaType.Recording]: 'common.recording',
  [MediaType.Season]: 'common.season',
  [MediaType.Series]: 'common.series',
  [MediaType.Title]: 'common.films',
  [MediaType.Audio]: 'common.audio'
};

const WatchListScreenGrosso: React.FC<WatchListSubscreenProps> = props => {
  const {
    mediaType,
    onMediaTypeSelect,
    watchList,
    selectable,
    closeSelectionMenu,
    loading,
    onSelectMedia,
    selection,
    requestNextPage,
    isTvTabAvailable,
    testID
  } = props;

  const styles = stylesUpdater.getStyles();
  const {t} = useTranslation();
  const [onSideMenuParentReady, focusSideMenu] = useFocusParent();
  const [sideMenuState, setSideMenuState] = useState<SideMenuState>(SideMenuState.Expanded);
  const isGridParentFocused = useRef(false);
  const scrollViewRef = useRef<ScrollView>(null);
  const focusedTileIndex = useRef(0);
  const [onSelectionMenuParentReady, focusSelectionMenu] = useFocusParent();

  const menuItems: ListItemProps[] = useMemo(
    () => [
      {
        key: 'Series',
        leftIcon: IconType.TvSeries,
        label: t(headerTextKeys[MediaType.Series]),
        rightIcon: IconType.ArrowRight,
        hasTVPreferredFocus: true,
        onPress: () => onMediaTypeSelect(MediaType.Series),
        selected: mediaType === MediaType.Series
      },
      {
        key: 'Films',
        leftIcon: IconType.Films,
        label: t(headerTextKeys[MediaType.Title]),
        rightIcon: IconType.ArrowRight,
        onPress: () => onMediaTypeSelect(MediaType.Title),
        selected: mediaType === MediaType.Title
      },
      ...(isTvTabAvailable ? [{
        key: 'TV',
        leftIcon: IconType.Tv,
        label: t(headerTextKeys[MediaType.Event]),
        rightIcon: IconType.ArrowRight,
        onPress: () => onMediaTypeSelect(MediaType.Event),
        selected: mediaType === MediaType.Event
      }] : [])
    ],
    [t, mediaType, isTvTabAvailable, onMediaTypeSelect]
  );

  const onBackPressed = useCallback(() => {
    if (selectable) {
      closeSelectionMenu();
      focusSelectionMenu();
      return true;
    }
    if (sideMenuState === SideMenuState.Collapsed) {
      focusSideMenu();
      return true;
    }
    return false;
  }, [selectable, closeSelectionMenu, focusSelectionMenu, sideMenuState, focusSideMenu]);

  useLazyEffect(() => {
    if (watchList.length === 0) {
      Log.debug(TAG, 'WatchList changed - requesting the first page');
      requestNextPage();
    }
  }, [watchList], [requestNextPage]);

  const onEndReached = useCallback(() => {
    Log.debug(TAG, 'We have reached the end of the list - requesting next page');
    requestNextPage();
  }, [requestNextPage]);

  const onScroll = useScrollViewPaging(onEndReached);

  const [tilesInRow, setTilesInARow] = useState(0);

  const onGridLayout = useCallback((event: LayoutChangeEvent) => {
    const {nativeEvent: {layout: {width}}} = event;
    const numberOfTiles = Math.floor(width / (dimensions.tile.width + 2 * dimensions.margins.small));
    if (numberOfTiles !== tilesInRow) {
      setTilesInARow(numberOfTiles);
    }
    if (isGridParentFocused.current) {
      scrollViewRef.current?.scrollTo({y: ((focusedTileIndex.current / numberOfTiles) - 1) * (dimensions.tile.height + dimensions.margins.small)});
    }
  }, [focusedTileIndex, scrollViewRef, tilesInRow]);

  const tilesData = useMemo(() => {
    if (!tilesInRow) {
      return watchList;
    }
    const tilesInLastRow = watchList.length % tilesInRow;
    const fakeTiles = (tilesInRow - tilesInLastRow) % tilesInRow;
    return [...watchList, ...new Array(fakeTiles)];
  }, [watchList, tilesInRow]);

  const onGridParentFocused = useCallback(() => {
    isGridParentFocused.current = true;
    !isDesktopBrowser && setSideMenuState(SideMenuState.Collapsed);
  }, []);

  const onGridParentFocusLost = useCallback(() => {
    isGridParentFocused.current = false;
  }, []);

  const onTileFocus = useCallback((index: number) => {
    focusedTileIndex.current = index;
  }, []);

  const renderGridTile = useCallback((media: Media | undefined, index: number) => {
    const selected = !!media && selection.isSelected(media);

    if (!media) {
      return <View style={styles.fakeTile} />;
    }
    return (
      <WatchListTile
        key={media.id}
        selectable={selectable}
        selected={selected}
        media={media}
        onSelect={(selected: boolean) => onSelectMedia(media, selected)}
        onFocus={() => onTileFocus(index)}
      />
    );
  }, [selection, selectable, onTileFocus, styles.fakeTile, onSelectMedia]);

  const onSideMenuFocusEnter = useCallback(() => {
    setSideMenuState(SideMenuState.Expanded);
  }, []);

  useChangeEffect(() => {
    if (selectable) {
      focusSelectionMenu();
    }
  }, [selectable], [focusSelectionMenu]);

  const containerStyle = useMemo(() => {
    return {
      ...styles.container,
      paddingLeft: sideMenuState === SideMenuState.Expanded ? dimensions.margins.xxLarge : watchListTileMarginHorizontal,
      paddingRight: dimensions.screen.container.paddingHorizontal - (sideMenuState === SideMenuState.Expanded ? 0 : dimensions.margins.xxxLarge)
    };
  }, [styles.container, sideMenuState]);

  return (
    <NitroxScreen
      navigation={useNavigation()}
      style={styles.screen}
      onScreenFocused={focusSideMenu}
      menuState={STBMenuState.Above}
      onBackButtonPressed={onBackPressed}
      testID={testID}
    >
      <NitroxInteractiveController omitGeometryCaching>
        <SideMenu
          menuState={sideMenuState}
          onFocusParentReady={onSideMenuParentReady}
          onFocusEnter={onSideMenuFocusEnter}
          items={menuItems}
          title={t('common.watchList')}
          titleStyle={styles.sectionHeader}
        />
        <FocusParent
          enterStrategy='byPriority'
          onFocusEnter={onGridParentFocused}
          onFocusEscape={onGridParentFocusLost}
          style={containerStyle}
        >
          <NitroxText
            style={styles.header}
            textType={'headline'}
            upperCase
          >
            {t(headerTextKeys[mediaType])}
          </NitroxText>
          <View style={styles.actions}>
            <WatchListSortOrderSelect
              style={styles.sortOrderSelect}
              currentSortOrder={props.currentSortOrder}
              onSortOrderSelected={props.onSortOrderSelected}
            />
            <FocusParent style={styles.selectionMenu} onReady={onSelectionMenuParentReady}>
              {props.selectionMenu}
            </FocusParent>
          </View>
          <FocusParent focusPriority={1} style={styles.focusParentGrid} rememberLastFocused>
            {loading
              ? <ActivityIndicator size='large' animating style={styles.activityIndicator} />
              : (
                <ScrollView
                  ref={scrollViewRef}
                  style={styles.grid}
                  contentContainerStyle={styles.gridContent}
                  onScroll={onScroll}
                  onLayout={onGridLayout}
                  scrollEventThrottle={8}
                  showsVerticalScrollIndicator={false}
                  showsHorizontalScrollIndicator={false}
                  alwaysBounceVertical={false}
                >
                  {tilesData.map(renderGridTile)}
                </ScrollView>
              )}
          </FocusParent>
        </FocusParent>
      </NitroxInteractiveController>
    </NitroxScreen>
  );
};

export default WatchListScreenGrosso;
