import {ViewStyle, StyleProp, TextStyle} from 'react-native';
import {Omit} from 'react-navigation';

import {isBigScreen} from 'common/constants';

import {ButtonIconProps} from 'components/NitroxButton';
import {PopupProps} from 'components/Popup';
import {MediaIcons} from 'components/TileIconsRow';

import SelectionPopupGrosso from './SelectionPopup.grosso';
import SelectionPopupPiccolo from './SelectionPopup.piccolo';

export type SelectionPopupOption = {
  key: string;
  text: string;
  icon?: ButtonIconProps;
  selected?: boolean;
  onPress: () => void;
}

export type SelectionPopupSection = {
  options: SelectionPopupOption[];
  title?: string;
  separator?: boolean;
  center?: boolean;
  containerStyle?: ViewStyle;
}

export type SelectionPopupProps = {
  visible?: boolean;
  loading?: boolean;
  style?: ViewStyle;
  columnStyle?: ViewStyle;
  centerFirstRow?: boolean;
  sections: SelectionPopupSection[];
  focusedKey?: string;
  title: string;
  info?: string;
  mediaIcons?: MediaIcons;
  columnCount?: number;
  buttonWidth?: number;
  buttonHeight?: number;
  buttonMargin?: number;
  buttonTextStyle?: StyleProp<TextStyle>;
  popupProps?: Omit<PopupProps, 'visible' | 'menuRef'>;
  activityIndicatorStyle?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
  scrollable?: boolean;
  scrollableWhenMaxHeightExceeded?: boolean;
  maxHeight?: number;
  shouldWaitForButtons?: boolean;
  onClose?: () => void;
}

const SelectionPopup = isBigScreen ? SelectionPopupGrosso : SelectionPopupPiccolo;

export default SelectionPopup;
