import moment from 'moment';

import {Direction} from 'common/constants';
import {DateUtils} from 'common/DateUtils';
import {isEventRecorded, isTruthy} from 'common/HelperFunctions';
import {Log} from 'common/Log';
import {withinBoundaries} from 'common/utils';

import {Event, MediaType, Channel} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import {PlayerControlsRecordDotState} from 'components/player/PlayerControlsView';
import {PlayerViews} from 'components/player/PlayerView';
import {RecordActionType} from 'components/pvr/RecordingUtils';
import {TopLevelPortalType} from 'components/TopLevelPortalManager';

import {TvScreenPlayerViewLocation} from './TvScreenHelperTypes';

const numberOfSecondsToStartTSTV = 10;

export const getPlayerView = (playerLocation: TvScreenPlayerViewLocation) => {
  switch (playerLocation) {
    case 'none':
      return PlayerViews.Epg;
    case 'embedded':
      return PlayerViews.Zapper;
    case TopLevelPortalType.Fullscreen:
      return PlayerViews.Fullscreen;
    case TopLevelPortalType.Floating:
      return PlayerViews.Floater;
  }
};

export function computeProgress(startTime: Date, endTime: Date, currentTime: Date): number {
  const startTimeMs = startTime.getTime();
  return withinBoundaries(0, 1, (currentTime.getTime() - startTimeMs) / (endTime.getTime() - startTimeMs));
}

export function isLivePosition(event: Event, positionInSec: number): boolean {
  if (event.isNow) {
    const timeFromNowInSec = (Date.now() - event.start.getTime()) / DateUtils.msInSec - positionInSec - numberOfSecondsToStartTSTV;
    return (timeFromNowInSec < 0);
  }
  return false;
}

export function getInitialChannel() {
  const lastPlayed = mw.players.main.getCurrentMedia();
  return lastPlayed && lastPlayed.getType() === MediaType.Channel ? lastPlayed as Channel : (undefined);
}

export async function getInitialPlayableMedia(channelId?: string, eventId?: string) {
  if (channelId) {
    const requestedChannel = mw.catalog.getChannelById(channelId);
    if (requestedChannel) {
      return requestedChannel;
    }
    Log.warn('getInitialPlayableMedia', 'Unable to find requested channel with id ' + channelId);
  } else if (eventId) {
    try {
      return await mw.catalog.getEventById(eventId);
    } catch (error) {
      Log.warn('getInitialPlayableMedia', 'Unable to find requested event with id ' + eventId);
    }
  }
  return null;
}

export function findChannelByDirection(direction: Direction, channels?: Channel[], currentChannel?: Channel | null): Channel | undefined {
  if (!currentChannel) {
    Log.error('findChannelByDirection', 'CurrentChannel should not be undefined!');
    return;
  }
  if (!channels?.length) {
    return;
  }
  const currentChannelIndex = channels.findIndex(channel => currentChannel.id === channel.id);
  if (currentChannelIndex === -1) {
    Log.error('findChannelByDirection', 'Failed to find channel index');
    return;
  }
  let offset: number;
  switch (direction) {
    case Direction.Down:
      offset = -1;
      break;
    case Direction.Up:
      offset = 1;
      break;
    default:
      Log.error('findChannelByDirection', 'onSwipe: direction: ' + direction + ' not implemented');
      return;
  }
  const newIndex = (channels.length + currentChannelIndex + offset) % channels.length;
  Log.debug('findChannelByDirection', `found channel ${newIndex}`);
  return channels[newIndex];
}

export async function getRecordingDotState(event: Event, getRecordActionType: (event: Event) => Promise<RecordActionType>): Promise<PlayerControlsRecordDotState | null> {
  if (await isEventRecorded(event) && event.isNow) {
    return PlayerControlsRecordDotState.IsNowRecorded;
  }

  if (await getRecordActionType(event) !== RecordActionType.none) {
    return PlayerControlsRecordDotState.Record;
  }
  return null;
}

export function getFormattedStartEndTime(event: Event): string {
  return [
    moment(event.start).format(mw.configuration.timeFormat),
    moment(event.end).format(mw.configuration.timeFormat)
  ].filter(isTruthy).join(' - ');
}
