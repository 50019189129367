import {Images} from 'mw/api/Metadata';

import {AsyncStorageInterface} from './AsyncStorageInterface';

class AsyncStorage implements AsyncStorageInterface {
  public static instance = new AsyncStorage();

  public getItem(key: string): Promise<string | null> {
    return Promise.resolve(localStorage.getItem(key));
  }

  public setItem(key: string, value: string): Promise<void> {
    return Promise.resolve(localStorage.setItem(key, value));
  }

  public removeItem(key: string): Promise<void> {
    return Promise.resolve(localStorage.removeItem(key));
  }

  public getSecureItem(key: string): Promise<string | null> {
    return Promise.resolve(localStorage.getItem(key));
  }

  public setSecureItem(key: string, value: string): Promise<void> {
    return Promise.resolve(localStorage.setItem(key, value));
  }

  public removeSecureItem(key: string): Promise<void> {
    return Promise.resolve(localStorage.removeItem(key));
  }

  public clear(): Promise<void> {
    return Promise.resolve(localStorage.clear());
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public cacheImages(images: Images, folder: string): Promise<Images> {
    return Promise.resolve(images);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public invalidateImageCache(toRemove: string, toKeep: string): Promise<void> {
    return Promise.resolve();
  }
}

export const asyncStorage: AsyncStorageInterface = AsyncStorage.instance;
