import {Log} from 'common/Log';
import {makeArray} from 'common/utils';

import {Error, ErrorType} from 'mw/api/Error';
import {PlaybackAsset, PlaybackSessionData} from 'mw/bo-proxy/BOInterface';
import {SessionState} from 'mw/playback/sessions/PlaybackSessionManager';

import {parseString} from './MappersHelper';
import {remoteAssetMapper} from './RemoteAssetMapper';

const TAG = 'SessionMapper';

export class SessionMapper {
  public static toPlaybackSessionData(json: any): PlaybackSessionData {
    Log.debug(TAG, 'PlaybackSessionData', json);

    if (!json || json.State !== SessionState.Created) {
      Log.error(TAG, 'toPlaybackSession', json);
      throw new Error(ErrorType.PlaybackSessionError);
    }

    const playlist = json.Playlist;
    const jsonAssets = makeArray(playlist.Asset || playlist.Channel);
    const assets: PlaybackAsset[] = jsonAssets.sort((a, b) => {
      return a.ordinal - b.ordinal;
    }).map(SessionMapper.toAsset);

    const remoteAssets = playlist.RemoteAsset;
    if (remoteAssets) {
      assets.push(...remoteAssetMapper(remoteAssets));
    }

    return {
      id: json.id,
      assets: assets
    };
  }

  private static toAsset(asset: any): PlaybackAsset {
    //TODO: identifying isAdvertisement
    // TODO: CL-5385 Remove workaround
    return {
      url: asset.Value,
      playbackLimitations: {
        allowPause: parseString(asset.allowPause),
        allowSkipForward: parseString(asset.allowSkipForward),
        allowSkipBackward: parseString(asset.allowSkipBackward),
        allowFastForward: parseString(asset.allowFastForward),
        allowRewind: parseString(asset.allowRewind)
      }
    };
  }

  public static validateSession(json: any): void {
    Log.trace(TAG, 'parseKeepAliveSession', json);

    switch (json.State) {
      case SessionState.Deleted:
      case SessionState.EntitlementTimedOut:
      case SessionState.Unauthorized:
        throw new Error(ErrorType.PlaybackSessionExpired, json.State);
    }
  }
}
