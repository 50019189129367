import React from 'react';

import {usingNitroxFocusEngine} from 'common/constants';

import ConditionalWrapper from './ConditionalWrapper';

//TODO: CL-2115 to be extended
/**
 * @param omitGeometryCaching - when true, all contained {@link NitroxInteractive}s omit caching their geometry.
 */
export type NitroxInteractiveControllerProps = {
  omitGeometryCaching?: boolean;
}

export const NitroxInteractiveControllerContext = React.createContext<NitroxInteractiveControllerProps>({omitGeometryCaching: false});

/**
 * Component designed to wrap group of components that may contain multiple {@link NitroxInteractive}s.
 * Wrapper can disable caching geometry of {@link NitroxInteractive}s placed within.
 * 
 * It's necessary to turn off geometry caching mechanism for moving focusable components. Scrolling doesn't trigger update of cached geometry, which result in incorrect focus route. When component doesn't have cached geometry, FocusManager will ask for component geometry after movement request.
 * 
 */
export const NitroxInteractiveController: React.FC<NitroxInteractiveControllerProps> = props => {
  const {omitGeometryCaching = false} = props;
  return (
    <ConditionalWrapper
      condition={usingNitroxFocusEngine}
      wrapper={children => (
        <NitroxInteractiveControllerContext.Provider value={{omitGeometryCaching}}>
          {children}
        </NitroxInteractiveControllerContext.Provider>
      )}
    >
      {props.children}
    </ConditionalWrapper>
  );
};
