import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {TextStyle} from 'react-native';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {IconType} from 'components/Icon';
import NitroxText from 'components/NitroxText';
import Popup, {PopupAction} from 'components/Popup';

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  message: {
    color: colors.popup.text,
    marginBottom: dimensions.margins.medium,
    textAlign: 'center'
  }
}));

const styles = createStyles({
  container: {
    alignItems: 'center'
  }
});

const actions = [PopupAction.POSITIVE];

export type InfoPopupProps = {
  visible: boolean;
  title: string;
  subtitle?: string;
  subtitleStyle?: TextStyle;
  message: string | string[];
  icon?: IconType;
  buttonLabel?: string;
  onClose?: () => void;
}

const InfoPopup: React.FC<InfoPopupProps> = props => {
  const {t} = useTranslation();
  const {visible, title, subtitle, message, icon, onClose, subtitleStyle, buttonLabel} = props;
  const dynamicStyles = dynamicStylesUpdater.getStyles();

  const popupContent = useMemo((): JSX.Element | JSX.Element[] => {
    if (Array.isArray(message) && message.length > 1) {
      return message.map((message, i) => (
        <NitroxText key={i} style={dynamicStyles.message} textType='dialog-message'>{`${i + 1}. ${message}`}</NitroxText>
      ));
    }
    return <NitroxText style={dynamicStyles.message} textType='dialog-message'>{Array.isArray(message) ? message[0] : message}</NitroxText>;
  }, [dynamicStyles, message]);

  return (
    <Popup
      visible={visible}
      title={title}
      subtitle={subtitle}
      actions={actions}
      positiveLabel={buttonLabel || t('common.ok')}
      onPositive={onClose}
      onClose={onClose}
      icon={icon}
      containerStyle={styles.container}
      menuHasPreferredFocus
      subtitleStyle={subtitleStyle}
    >
      {popupContent}
    </Popup>
  );
};

export default React.memo(InfoPopup);
