import React, {useState, useEffect} from 'react';
import {InteractionManager} from 'react-native';

type InteractionSuspenseProps = {
  children?: React.ReactNode;
  /**
   * Provide instead of inline elements (jsx) to improve memoization.
   */
  renderChildren?: () => React.ReactNode;
  /**
   * Optional fallback element to be rendered until interactions are completed.
   */
  renderFallback?: () => React.ReactNode;
}

/**
 * Postpones render until all created interactions are completed. By default, interactions are created by Animated during animations and cleared afterwards.
 * Interaction can be manually registered via InteractionManager.createInteractionHandle().
 * See implementation of InteractionManager: https://github.com/facebook/react-native/blob/master/Libraries/Interaction/InteractionManager.js
 */
const InteractionSuspense: React.FC<InteractionSuspenseProps> = ({
  children,
  renderChildren,
  renderFallback
}) => {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    InteractionManager.runAfterInteractions(() => setReady(true));
  }, []);

  if (!ready) {
    return <>{renderFallback?.()}</>;
  }

  return <>{children ?? renderChildren?.()}</>;
};

export default React.memo(InteractionSuspense);
