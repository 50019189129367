import {useState, useCallback} from 'react';

import {useChangeEffect} from 'hooks/Hooks';

/**
 * @param updater used to either determine initial value and update on demand via `update`
 */
export function useUpdater<T>(updater: () => T) {
  const [state, setState] = useState(updater);
  const updateState = useCallback(() => setState(updater()), [updater]);

  useChangeEffect(() => {
    updateState();
  }, [updateState]);

  return {state, update: updateState};
}
