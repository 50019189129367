import {createStyles} from 'common-styles';
import React from 'react';
import {StyleProp, TextStyle, ViewStyle} from 'react-native';

import {dimensions, isBigScreen} from 'common/constants';
import {identity} from 'common/HelperFunctions';
import {TestProps} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {IconType} from 'components/Icon';
import NitroxButton, {ButtonIconProps, IconPosition, NitroxButtonTheme} from 'components/NitroxButton';
import {FocusPriorityProps} from 'components/NitroxInteractive';
import {TextType} from 'components/NitroxText';
import {useLocalized} from 'locales/i18nUtils';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  bigScreenButton: {
    flexDirection: 'row',
    height: dimensions.buttons.large,
    marginRight: 10
  },
  noHorizontalPadding: {
    paddingLeft: 0,
    paddingRight: 0
  },
  mobileButton: {
    backgroundColor: colors.transparent,
    flexDirection: 'column',
    height: 85,
    marginTop: 10,
    marginBottom: 15,
    marginRight: 0,
    justifyContent: 'flex-start'
  },
  mobileText: {
    textAlign: 'center',
    paddingLeft: -dimensions.margins.xsmall
  }
}));

export type ActionButtonProps = {
  onPress: () => void;
  text?: string;
  iconType?: IconType;
  iconPosition?: IconPosition;
  iconSize?: number;
  isFocused?: boolean;
  style?: StyleProp<ViewStyle>;
  textType?: TextType;
  textStyle?: StyleProp<TextStyle>;
  hasTVPreferredFocus?: boolean;
  disabled?: boolean;
} & FocusPriorityProps & TestProps;

const ActionButton: React.FunctionComponent<ActionButtonProps> = (props, ref) => {
  if (!props.text && !props.iconType) {
    throw Error('ActionButton requires title or iconType property');
  }

  let buttonStyle: StyleProp<ViewStyle>;
  let textStyle: StyleProp<TextStyle>;
  let textType: TextType;
  let iconProps: ButtonIconProps | undefined;
  const iconSize = props.iconSize || (props.text ? dimensions.actionButton.iconSize.small : dimensions.actionButton.iconSize.big);
  const styles = stylesUpdater.getStyles();

  if (isBigScreen) {
    buttonStyle = [
      styles.bigScreenButton,
      {minWidth: props.text ? dimensions.actionButton.regular.minWidth : dimensions.actionButton.short.minWidth},
      !props.text && props.iconType && styles.noHorizontalPadding,
      props.text && props.iconType ? {justifyContent: 'space-between'} : {}
    ];

    textStyle = props.textStyle || (props.text && props.iconType ? {marginLeft: dimensions.margins.small} : {});
    textType = props.textType || 'buttons';

  } else {
    buttonStyle = styles.mobileButton;
    textStyle = [styles.mobileText, props.textStyle || {}];
    textType = props.textType || 'detail-action-button';
  }

  if (props.iconType) {
    iconProps = {
      size: iconSize,
      type: props.iconType,
      position: props.iconPosition
    };
  }
  const {toUpperCase} = useLocalized();
  const text = props.text && (isBigScreen ? toUpperCase : identity)(props.text);

  return (
    <NitroxButton
      ref={ref}
      style={[buttonStyle, props.style]}
      // TODO CL-3880 CL-4400
      theme={isBigScreen ? NitroxButtonTheme.Primary : NitroxButtonTheme.Tertiary}
      border={isBigScreen}
      icon={iconProps}
      text={text}
      textStyle={textStyle}
      textType={textType}
      onPress={props.onPress}
      hasTvPreferredFocus={props.hasTVPreferredFocus}
      focusPriority={props.focusPriority}
      testID={props.testID}
      numberOfLines={isBigScreen ? undefined : 2}
      disabled={props.disabled}
    />
  );
};

export default React.memo(React.forwardRef(ActionButton));
