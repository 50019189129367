import i18next from 'i18next';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {mw} from 'mw/MW';

import FilterSelect, {FilterOption, FilterSelectProps} from 'components/FilterSelect';

export enum SearchEpgFilter {
  past = 'past',
  current = 'current',
  future = 'future',
  recorded = 'recorded'
}

const orderedFilters: SearchEpgFilter[] = [
  SearchEpgFilter.current,
  SearchEpgFilter.recorded,
  SearchEpgFilter.past,
  SearchEpgFilter.future
];

function createFilterOption(searchEpgFilter: SearchEpgFilter, t: i18next.TFunction): FilterOption<SearchEpgFilter> {
  switch (searchEpgFilter) {
    case SearchEpgFilter.current:
      return {key: SearchEpgFilter.current, filter: SearchEpgFilter.current, label: t('filter.current')};
    case SearchEpgFilter.recorded:
      return {key: SearchEpgFilter.recorded, filter: SearchEpgFilter.recorded, label: t('filter.recordings')};
    case SearchEpgFilter.past:
      return {key: SearchEpgFilter.past, filter: SearchEpgFilter.past, label: t('filter.past')};
    case SearchEpgFilter.future:
      return {key: SearchEpgFilter.future, filter: SearchEpgFilter.future, label: t('filter.future')};
  }
}

type Props = Omit<FilterSelectProps<SearchEpgFilter>, 'filterOptions'>;

const SearchEpgFilterSelect: React.FC<Props> = (props: Props) => {
  const {t} = useTranslation();
  const isNPVREnabled = mw.configuration.isNPVREnabled;
  const filterOptions = useMemo(() => {
    const filterOptions: FilterOption<SearchEpgFilter>[] = [];
    orderedFilters.forEach(option => {
      if (option === SearchEpgFilter.recorded && !isNPVREnabled) {
        return;
      }
      const filterOption = createFilterOption(option, t);
      if (filterOption) {
        filterOptions.push(filterOption);
      }
    });
    return filterOptions;
  }, [isNPVREnabled, t]);

  return (
    <FilterSelect<SearchEpgFilter> {...props} filterOptions={filterOptions} />
  );
};

export default React.memo(SearchEpgFilterSelect);
