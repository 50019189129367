import {createStyles} from 'common-styles';
import React from 'react';
import {View, ViewStyle, StyleProp} from 'react-native';

import {dimensions, getValue} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxText, {TextType} from 'components/NitroxText';

const containerHeight = dimensions.progressTracker.height;
const defaultFont = getValue<TextType>({mobile: 'body1', defaultValue: 'subhead'});

const commonSeparatorStyle: ViewStyle = {
  height: containerHeight / 2,
  width: getValue({mobile: dimensions.margins.small, defaultValue: dimensions.margins.large}),
  borderBottomWidth: 1,
  alignSelf: 'flex-start'
};

const commonStepStyle: ViewStyle = {
  height: containerHeight,
  width: containerHeight,
  borderRadius: 50,
  alignItems: 'center',
  justifyContent: 'center'
};

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    height: containerHeight + dimensions.margins.small,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: dimensions.margins.small
  },
  separatorVisited: {
    ...commonSeparatorStyle,
    borderBottomColor: colors.progressTracker.step.visited.background
  },
  separatorNotVisited: {
    ...commonSeparatorStyle,
    borderBottomColor: colors.progressTracker.step.notVisited.background
  },
  labelVisited: {
    color: colors.progressTracker.step.visited.label
  },
  labelNotVisited: {
    color: colors.progressTracker.step.notVisited.label
  },
  stepVisited: {
    ...commonStepStyle,
    backgroundColor: colors.progressTracker.step.visited.background
  },
  stepNotVisited: {
    ...commonStepStyle,
    backgroundColor: colors.progressTracker.step.notVisited.background
  }
}));

type StepNumberProps = {
  label: string;
  textType?: TextType;
  isFirst: boolean;
  isLast: boolean;
  separatorStyle?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<ViewStyle>;
  stepStyle?: StyleProp<ViewStyle>;
}

const StepTracker: React.FC<StepNumberProps> = props => {
  const {label, textType, isFirst, isLast, separatorStyle, labelStyle, stepStyle} = props;
  return (
    <>
      {!isFirst &&
        <View style={separatorStyle} />
      }
      <View style={stepStyle}>
        <NitroxText textType={textType || defaultFont} style={labelStyle}>{label}</NitroxText>
      </View>
      {!isLast &&
        <View style={separatorStyle} />
      }
    </>
  );
};

type ProgressTrackerProps = {
  currentStep: number;
  numberOfSteps: number;
  containerStyle?: ViewStyle;
  stepVisitedStyle?: ViewStyle;
  stepNotVisitedStyle?: ViewStyle;
  separatorVisitedStyle?: ViewStyle;
  separatorNotVisitedStyle?: ViewStyle;
  labelNotVisitedStyle?: ViewStyle;
  labelVisitedStyle?: ViewStyle;
}

const ProgressTracker: React.FC<ProgressTrackerProps> = props => {
  const {
    currentStep,
    numberOfSteps,
    containerStyle,
    stepVisitedStyle,
    stepNotVisitedStyle,
    separatorVisitedStyle,
    separatorNotVisitedStyle,
    labelNotVisitedStyle,
    labelVisitedStyle
  } = props;

  const styles = stylesUpdater.getStyles();
  const getStepStyles = (isVisited: boolean) => {
    const stepStyle = isVisited ?
      [styles.stepVisited, stepVisitedStyle] :
      [styles.stepNotVisited, stepNotVisitedStyle];

    const separatorStyle = isVisited ?
      [styles.separatorVisited, separatorVisitedStyle] :
      [styles.separatorNotVisited, separatorNotVisitedStyle];

    const labelStyle = isVisited ?
      [styles.labelVisited, labelVisitedStyle] :
      [styles.labelNotVisited, labelNotVisitedStyle];

    return {stepStyle, separatorStyle, labelStyle};
  };

  const getStep = (stepNumber: number) => {
    const isVisited = stepNumber <= currentStep;
    const {stepStyle, separatorStyle, labelStyle} = getStepStyles(isVisited);
    const isFirst = stepNumber === 0;
    const isLast = stepNumber === numberOfSteps - 1;

    return (
      <StepTracker
        label={(stepNumber + 1).toString()}
        isFirst={isFirst}
        isLast={isLast}
        separatorStyle={separatorStyle}
        labelStyle={labelStyle as ViewStyle}
        stepStyle={stepStyle}
        key={stepNumber}
      />
    );
  };

  return (
    <View style={[styles.container, containerStyle]}>
      {
        Array(numberOfSteps)
          .fill(undefined)
          .map((item: any, index: number) => getStep(index))
      }
    </View>
  );
};

export default ProgressTracker;
