import {Log} from 'common/Log';

import {EventEmitter} from './EventEmitter';
import {Emitter} from './HelperTypes';

const TAG = 'Async';

export type Callable = (...args: any[]) => any;

export type DebouncedFunction<T extends Callable> = {
  abort: () => void;
  (...args: Parameters<T>): Promise<ReturnType<T>>;
}

export function timeout<T extends Callable>(callback: T, ms: number, ...args: Parameters<T>): number {
  // eslint-disable-next-line
  return <any>setTimeout(callback, ms, ...args);
}

export function debounce<T extends Callable>(fn: T, delay: number): DebouncedFunction<T> {
  let timeoutId = 0;
  const reset = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = 0;
    }
  };
  const r = (...args: Parameters<T>): Promise<ReturnType<T>> => {
    return new Promise((resolve, reject) => {
      reset();
      timeoutId = timeout(() => {
        timeoutId = 0;
        try {
          resolve(fn(...args));
        } catch (reason) {
          reject(reason);
        }
      }, delay);
    });
  };
  r.abort = reset;
  return r as DebouncedFunction<T>;
}

export type CallableAsync<A extends any[] = any, R = any> = (...args: A) => Promise<R>;
export type DisposableFunction<T extends CallableAsync> = T & {
  dispose: () => void;
}

export function disposable<T extends CallableAsync>(fn: T): DisposableFunction<T> {
  let disposed = false;
  const r = (...args: Parameters<T>): Promise<ReturnType<T>> => {
    return new Promise((resolve, reject) => {
      fn(...args)
        .then(result => {
          if (disposed) {
            Log.debug(TAG, `function ${fn.name} had been disposed, thus response ${result} has been ignored`);
          } else {
            resolve(result);
          }
        })
        .catch(reason => {
          if (disposed) {
            Log.debug(TAG, `function ${fn.name} had been disposed, thus exception ${reason} has been ignored`);
          } else {
            reject(reason);
          }
        });
    });
  };
  r.dispose = () => {
    Log.debug(TAG, `function ${fn.name} disposed`);
    disposed = true;
  };

  return r as DisposableFunction<T>;
}

export type DisposableCallback<T extends Callable> = {
  (...args: Parameters<T>): void;
  dispose: () => void;
}

export function disposableCallback<T extends Callable>(fn: T): DisposableCallback<T> {
  let disposed = false;
  const r = (...args: Parameters<T>): void => {
    if (disposed) {
      Log.debug(TAG, `function ${fn.name} had been disposed, thus ignoring its call`);
    } else {
      fn(...args);
    }
  };
  r.dispose = () => {
    Log.debug(TAG, `function ${fn.name || 'anonymous'} disposed`);
    disposed = true;
  };

  return r;
}

export function throttle<T extends Callable>(fn: T, threshold: number): T | ((...args: any[]) => void) {
  let timer = 0;

  return (...args: any): any => {
    const now = Date.now();
    const elapsed = now - timer;
    if (elapsed > threshold) {
      timer = now;
      return fn(...args);
    }
  };
}

/**
 * Each next call of passed function overrides previous.
 * Only most recent function call resolves returned promise.
 */
export function switchCalls<T extends CallableAsync>(fn: T): T {
  let currentPromise: Promise<ReturnType<T>> | undefined;

  return ((...args: Parameters<T>): Promise<ReturnType<T>> => {
    const promise = fn(...args);
    currentPromise = promise;

    return new Promise<ReturnType<T>>((resolve, reject) => {
      promise
        .then(r => {
          if (currentPromise === promise) {
            resolve(r);
          }
        })
        .catch(reason => {
          if (currentPromise === promise) {
            reject(reason);
          }
        });
    });
  }) as T;
}

type QueuedFunction<T extends CallableAsync> = {
  dispose: () => void;
  flush: () => void;
  (...args: Parameters<T>): void;
}

type TaskQueue = Array<{
  task: CallableAsync;
  /**
   * Optional deadline[timestamp] until when task should be done.
   * Omitting task if exceeded.
   */
  deadline?: number;
}>;

type QueuedParams = {
  /**
   * Optional timeout[ms] until when task should be done.
   * Omitting task if exceeded.
   */
  deadlineAfter?: number;
  /**
   * Parameter that may help tracking hanging promises that block queue.
   * If task's processing time is longer, queue will proceed.
   * Stale tasks should be treated as a bug most of the time.
   * Note: Using with disposable function will result in unnecessary errors.
   */
  staleAfter?: number;
}

export class DelayResponse {}
/**
 * Function used for debug/testing purposes.
 * Usage: `await delay(500)`.
 * Resolves with empty `DelayResponse` that is recognizeable with instanceof operator.
 */
export async function delay(time: number) {
  return new Promise(resolve => setTimeout(() => resolve(new DelayResponse()), time));
}

class StaleQueueException extends Error {}
/**
 * Queued function calls will be enqueued.
 * Note: disposable element is whole queue, not sole tasks.
 * If function must be disposable - it need to be wrapped explicite, e.g. `queued(disposable(function))`.
 */
export function queued<T extends CallableAsync>(fn: T, {deadlineAfter, staleAfter}: QueuedParams = {}): QueuedFunction<T> {
  const queue: TaskQueue = [];
  let disposed = false;
  let busy = false;

  const tryRunNext = async () => {
    if (disposed) {
      Log.debug('queued', `Queue is disposed, omitting task call.`);
      return;
    }
    if (busy) {
      Log.debug('queued', `Queue is busy, task cannnot be called yet. Queue length: ${queue.length}`);
      return;
    }
    const next = queue.shift();
    if (!next) {
      Log.debug('queued', `Queue is empty, no more tasks to run.`);
      return;
    }
    const {task, deadline} = next;
    if (deadline && Date.now() > deadline) {
      Log.debug('queued', `Queued task deadline exceeded, moving to next one. Queue length: ${queue.length}`);
      tryRunNext();
      return;
    }
    busy = true;
    Log.debug('queued', `Running next enqueued task. Queue length: ${queue.length}`);
    try {
      const promise = staleAfter
        ? Promise.race([
          delay(staleAfter).then(() => Promise.reject(new StaleQueueException())),
          task()
        ]).catch((error) => {
          if (error instanceof StaleQueueException) {
            Log.error('queued', `Queue task is stale! Hanging promises break queue mechanism. Make sure to either resolve or reject task promises.`);
          } else {
            Log.error('queued', `Queue task has thrown error.`, error);
          }
        })
        : task();
      await promise;
    } catch (error) {
      Log.error('queued', 'Error while running enqueued task:', error);
    }
    Log.debug('queued', `Finished next enqueued task. Queue length: ${queue.length}`);
    busy = false;
    tryRunNext();
  };

  const enqueue = async (...args: Parameters<T>) => {
    queue.push({
      task: () => fn(...args),
      deadline: deadlineAfter
        ? Date.now() + deadlineAfter
        : undefined
    });
    tryRunNext();
  };

  enqueue.dispose = () => {
    Log.debug(TAG, `function ${fn.name} disposed`);
    disposed = true;
  };

  enqueue.flush = () => queue.splice(0, queue.length);

  return enqueue as QueuedFunction<T>;
}

export class EventTimeoutException extends Error {}
export function awaitEvent<Name extends string, Payload = any>(
  eventName: Name,
  emitter: Emitter<Name, Payload>,
  eventFilter: (payload: Payload) => boolean = () => true,
  /**
   * Promise is rejected if deadline had exceeded before event has come.
   */
  deadlineAfter?: number
) {
  const addListener = ('on' in emitter ? emitter.on : emitter.addEventListener).bind(emitter);
  const removeListener = ('off' in emitter ? emitter.off : emitter.removeEventListener).bind(emitter);

  const eventPromise = new Promise(resolve => {
    const listener = (payload: Payload) => {
      if (!eventFilter(payload)) {
        return;
      }
      removeListener(eventName, listener);
      resolve(payload);
    };

    addListener(eventName, listener);
  });

  if (deadlineAfter) {
    return Promise.race([
      delay(deadlineAfter).then(() => Promise.reject(new EventTimeoutException())),
      eventPromise
    ]).catch((error) => {
      if (error instanceof EventTimeoutException) {
        Log.error('awaitEvent', `Awaiting event ${eventName} has exceeded deadline timeout of ${deadlineAfter}.`);
      }
      return Promise.reject(error);
    });
  }

  return eventPromise;
}

/** Executes a series of tasks in a series rather than in parallel like Promise.all */
export function runTasks<T>(tasks: (() => Promise<T>)[]): Promise<void> {
  function runNextTask(resolve: CallableFunction) {
    const task = tasks.shift();
    if (!task) {
      resolve();
      return;
    }
    task().finally(() => {
      runNextTask(resolve);
    });
  }
  return new Promise<void>(runNextTask);
}

export type CancelablePromise<T> = Promise<T> & {cancel: () => void; isCanceled: () => boolean};
type PromiseInnerType<S> = S extends Promise<infer T> ? T : never;
type InnerReturnType<T extends CallableAsync> = PromiseInnerType<ReturnType<T>>;
export enum CancelEvent {
  Cancel = 'Cancel'
}
export type CancelableFunction<T extends CallableAsync> = {
  (...args: Parameters<T>): CancelablePromise<InnerReturnType<T>>;
}
& Pick<EventEmitter<CancelEvent>, 'on' | 'off' | 'once'>
& {cancel: (reason?: string) => void};

type CancelableAsync<A extends any[], R> = CancelableFunction<CallableAsync<A, R>>;

export class CancelResponse {
  public constructor(public message?: string) {}
}

export function cancelable<T extends CallableAsync>(fn: T): CancelableFunction<T> {
  const cancelEmitter = new EventEmitter();

  const r = (...args: Parameters<T>): CancelablePromise<InnerReturnType<T>> => {

    let canceled = false;
    let promiseCancelReason: string | undefined;
    let rejected = false;
    const promise = new Promise((resolve, reject) => {
      const onFunctionCancel = (reason?: string) => {
        canceled = true;
        reject(new CancelResponse(reason));
        rejected = true;
      };
      cancelEmitter.once(CancelEvent.Cancel, onFunctionCancel);
      fn(...args)
        .then(result => {
          if (rejected) {
            Log.debug('cancelable', 'Promise has been already rejected, ignoring response.');
            return;
          }
          if (canceled) {
            Log.debug('cancelable', `Promise has been manually canceled, rejecting.`);
            reject(new CancelResponse(promiseCancelReason));
          } else {
            resolve(result);
          }
        })
        .catch(reason => {
          if (rejected) {
            Log.debug('cancelable', 'Promise has been already rejected, ignoring rejection.');
            return;
          }
          if (canceled) {
            Log.debug('cancelable', `Promise has been manually canceled, rejecting.`);
            reject(new CancelResponse(promiseCancelReason));
          } else {
            reject(reason);
          }
        })
        .finally(() => {
          cancelEmitter.off(CancelEvent.Cancel, onFunctionCancel);
        });
    }) as CancelablePromise<InnerReturnType<T>>;

    promise.cancel = (reason?: string) => {
      promiseCancelReason = reason;
      canceled = true;
    };

    promise.isCanceled = () => canceled;

    return promise as CancelablePromise<InnerReturnType<T>>;
  };

  r.cancel = (reason?: string) => {
    cancelEmitter.notify(CancelEvent.Cancel, reason);
  };
  r.on = cancelEmitter.on.bind(cancelEmitter);
  r.off = cancelEmitter.off.bind(cancelEmitter);
  r.once = cancelEmitter.once.bind(cancelEmitter);

  return r as CancelableFunction<T>;
}

type OptionallyCancelableAsync<A extends any[] = any, R = any> = CallableAsync<A, R> | CancelableFunction<CallableAsync<A, R>>;

function isCancelablePromise<T = any>(promise: Promise<T> | CancelablePromise<T> | undefined): promise is CancelablePromise<T> {
  return !!promise && 'cancel' in promise;
}
function isCancelableFunction<T extends any[] = any>(func: CallableAsync<T> | CancelableFunction<CallableAsync<T>> | undefined): func is CancelableFunction<CallableAsync<T>> {
  return !!func && 'cancel' in func;
}

function cancelablePipelineInternal<A extends any[], R>(
  head: OptionallyCancelableAsync<A, R>,
  ...tail: Array<OptionallyCancelableAsync<[R], R>>
): CancelableAsync<A, R> {
  const r = cancelable((...args: A) => {
    return new Promise(async (resolve, reject) => {
      let canceled = false;
      const onCancel = () => {
        canceled = true;
      };
      r.once(CancelEvent.Cancel, onCancel);
      const throwIfCanceled = () => {
        if (canceled) {
          r.off(CancelEvent.Cancel, onCancel);
          throw new CancelResponse();
        }
      };

      // Postpone task call to bind cancel listener first.
      // eslint-disable-next-line prefer-const
      let headPromise: Promise<R> | undefined;
      const onHeadCancel = () => {
        if (isCancelableFunction(head)) {
          head.cancel();
        }
        if (isCancelablePromise(headPromise)) {
          headPromise.cancel();
        }
      };
      r.once(CancelEvent.Cancel, onHeadCancel);
      headPromise = head(...args);

      let result: R;
      try {
        result = await headPromise;
      } catch (e) {
        reject(e);
        r.off(CancelEvent.Cancel, onCancel);
        return;
      } finally {
        r.off(CancelEvent.Cancel, onHeadCancel);
      }

      throwIfCanceled();
      const tailCopy = tail.slice();
      let task: CallableAsync<[R], R> | CancelableFunction<CallableAsync<[R], R>> | undefined;
      while (task = tailCopy.shift()) {
        // See headPromise
        // eslint-disable-next-line prefer-const
        let pendingTask: Promise<R> | undefined;
        const onTaskCancel = () => {
          if (task && isCancelableFunction(task)) {
            task.cancel();
          }
          if (isCancelablePromise(pendingTask)) {
            pendingTask.cancel();
          }
        };
        r.once(CancelEvent.Cancel, onTaskCancel);
        pendingTask = task(result);

        try {
          result = await pendingTask;
        } catch (e) {
          reject(e);
          r.off(CancelEvent.Cancel, onCancel);
          return;
        } finally {
          r.off(CancelEvent.Cancel, onTaskCancel);
        }

        throwIfCanceled();
      }

      r.off(CancelEvent.Cancel, onCancel);

      resolve(result);
    });
  });

  return r;
}

export function cancelablePipeline<T extends any[], A>(fn1: OptionallyCancelableAsync<T, A>): CancelableAsync<T, A>;
export function cancelablePipeline<T extends any[], A, B>(fn1: OptionallyCancelableAsync<T, A>, fn2: OptionallyCancelableAsync<[A], B>): CancelableAsync<T, B>;
export function cancelablePipeline<T extends any[], A, B, C>(fn1: OptionallyCancelableAsync<T, A>, fn2: OptionallyCancelableAsync<[A], B>, fn3: OptionallyCancelableAsync<[B], C>): CancelableAsync<T, C>;
export function cancelablePipeline<T extends any[], A, B, C, D>(fn1: OptionallyCancelableAsync<T, A>, fn2: OptionallyCancelableAsync<[A], B>, fn3: OptionallyCancelableAsync<[B], C>, fn4: OptionallyCancelableAsync<[C], D>): CancelableAsync<T, D>;
export function cancelablePipeline<T extends any[], A, B, C, D, E>(fn1: OptionallyCancelableAsync<T, A>, fn2: OptionallyCancelableAsync<[A], B>, fn3: OptionallyCancelableAsync<[B], C>, fn4: OptionallyCancelableAsync<[C], D>, fn5: OptionallyCancelableAsync<[D], E>): CancelableAsync<T, E>;
export function cancelablePipeline<T extends any[], A, B, C, D, E, F>(fn1: OptionallyCancelableAsync<T, A>, fn2: OptionallyCancelableAsync<[A], B>, fn3: OptionallyCancelableAsync<[B], C>, fn4: OptionallyCancelableAsync<[C], D>, fn5: OptionallyCancelableAsync<[D], E>, fn6: OptionallyCancelableAsync<[E], F>): CancelableAsync<T, F>;
export function cancelablePipeline<T extends any[], A, B, C, D, E, F, G>(fn1: OptionallyCancelableAsync<T, A>, fn2: OptionallyCancelableAsync<[A], B>, fn3: OptionallyCancelableAsync<[B], C>, fn4: OptionallyCancelableAsync<[C], D>, fn5: OptionallyCancelableAsync<[D], E>, fn6: OptionallyCancelableAsync<[E], F>, fn7: OptionallyCancelableAsync<[F], G>): CancelableAsync<T, G>;
export function cancelablePipeline<T extends any[], A, B, C, D, E, F, G, H>(fn1: OptionallyCancelableAsync<T, A>, fn2: OptionallyCancelableAsync<[A], B>, fn3: OptionallyCancelableAsync<[B], C>, fn4: OptionallyCancelableAsync<[C], D>, fn5: OptionallyCancelableAsync<[D], E>, fn6: OptionallyCancelableAsync<[E], F>, fn7: OptionallyCancelableAsync<[F], G>, fn8: OptionallyCancelableAsync<[G], H>): CancelableAsync<T, H>;
export function cancelablePipeline<T extends any[], A, B, C, D, E, F, G, H, I>(fn1: OptionallyCancelableAsync<T, A>, fn2: OptionallyCancelableAsync<[A], B>, fn3: OptionallyCancelableAsync<[B], C>, fn4: OptionallyCancelableAsync<[C], D>, fn5: OptionallyCancelableAsync<[D], E>, fn6: OptionallyCancelableAsync<[E], F>, fn7: OptionallyCancelableAsync<[F], G>, fn8: OptionallyCancelableAsync<[G], H>, fn9: OptionallyCancelableAsync<[H], I>): CancelableAsync<T, I>;
export function cancelablePipeline<T extends any[], A, B, C, D, E, F, G, H, I>(fn1: OptionallyCancelableAsync<T, A>, fn2: OptionallyCancelableAsync<[A], B>, fn3: OptionallyCancelableAsync<[B], C>, fn4: OptionallyCancelableAsync<[C], D>, fn5: OptionallyCancelableAsync<[D], E>, fn6: OptionallyCancelableAsync<[E], F>, fn7: OptionallyCancelableAsync<[F], G>, fn8: OptionallyCancelableAsync<[G], H>, fn9: OptionallyCancelableAsync<[H], I>, ...fns: OptionallyCancelableAsync<any, any>[]): CancelableAsync<T, any>;

/**
 * Wraps 1...n async methods in pipeline.
 * Pipeline can be canceled, resulting in ALL running instances cancel.
 * Rejects with `CancelResponse` right AFTER pending task finish.
 * Pipelines can be nested, outer pipeline cancel with be propagated recursively then.
 */
export function cancelablePipeline<A extends any[], R>(
  head: OptionallyCancelableAsync<A, any>,
  ...tail: Array<OptionallyCancelableAsync<any, any>>
) {
  return cancelablePipelineInternal<A, R>(head, ...tail);
}
