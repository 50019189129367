import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {View, StyleSheet, StyleProp, ViewStyle} from 'react-native';

import {dimensions} from 'common/constants';
import {getTime} from 'common/utils';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {mw} from 'mw/MW';

import NitroxText from 'components/NitroxText';

const textType = 'epg-timebar';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  timebarElementContainer: {
    flex: 1,
    borderLeftWidth: StyleSheet.hairlineWidth,
    borderRightWidth: StyleSheet.hairlineWidth,
    borderColor: colors.epgScreen.timeBar.border,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: colors.epgScreen.timeBar.background.future
  },
  timebarElementText: {
    color: colors.epgScreen.timeBar.text,
    zIndex: 1,
    marginLeft: dimensions.margins.medium
  },
  progressContainer: {
    ...StyleSheet.absoluteFillObject,
    flexDirection: 'row'
  },
  progress: {
    backgroundColor: colors.epgScreen.timeBar.background.past
  },
  epgTimebarTilePast: {
    backgroundColor: colors.epgScreen.timeBar.background.past
  }
}));

type TimeBarTileProps = {
  start: Date;
  isPast?: boolean;
  progress?: number;
  style?: StyleProp<ViewStyle>;
}

const TimeBarTile: React.FC<TimeBarTileProps> = React.memo(({
  start,
  isPast = false,
  progress,
  style
}) => {
  const styles = stylesUpdater.getStyles();

  const timeString = useMemo(
    () => getTime(start, mw.configuration.timeFormat),
    [start]
  );

  return (
    <View
      style={[
        styles.timebarElementContainer,
        isPast && styles.epgTimebarTilePast,
        style
      ]}
      // testID={testID} TODO: without using moment.js
    >
      {progress && (
        <View style={styles.progressContainer}>
          <View style={[{flex: progress}, styles.progress]} />
        </View>
      )}
      <NitroxText
        textType={textType}
        style={styles.timebarElementText}
      >
        {timeString}
      </NitroxText>
    </View>
  );
});
TimeBarTile.displayName = 'TimeBarTile';

export default TimeBarTile;
