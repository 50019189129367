import {createStyles} from 'common-styles';
import React, {useState, useCallback, useEffect, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {KeyboardAvoidingView, View} from 'react-native';
import {NavigationScreenProps} from 'react-navigation';

import {MAX_LOGIN_USERNAME_LENGTH, dimensions, isIOS, isPhone, isMobile, AppRoutes} from 'common/constants';
import {useSafeAwait} from 'common/hooks/Hooks';
import {Log} from 'common/Log';

import {ErrorType, Error as MWError, ValidationError, ValidationErrorCode} from 'mw/api/Error';
import {RegistrationData} from 'mw/bo-proxy/BOInterface';
import {mw} from 'mw/MW';
import {StringUtils} from 'mw/utils/stringUtils';

import RegistrationSuccessSvg from 'brand/current/commonResources/svgs/registrationSuccess.svg';
import RegistrationSuccessSvgPhone from 'brand/current/commonResources/svgs/registrationSuccessMobile.svg';
import ErrorPopup, {useErrorPopup, PopupError} from 'components/ErrorPopup';
import FocusParent, {useFocusParent} from 'components/FocusParent';
import Form, {FormConfig} from 'components/form/Form';
import FormButton, {buttonTextPostProcessor} from 'components/form/FormButton';
import FormResult from 'components/form/FormResult';
import {IconType} from 'components/Icon';
import {NitroxInteractiveController} from 'components/NitroxInteractiveControllerContext';
import SplashBackground from 'components/SplashBackground';
import {useDisposable} from 'hooks/Hooks';
import AccountAlreadyRegisteredPopup, {AccountAlreadyRegisteredPopupType} from 'screens/login/AccountAlreadyRegisteredPopup';
import NitroxScreen from 'screens/NitroxScreen';

const successAsset = isPhone ? RegistrationSuccessSvgPhone : RegistrationSuccessSvg;
const registrationErrorPrefix = 'registration.errors';

const TAG = 'RegistrationScreen';

const styles = createStyles({
  screen: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  screenContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: dimensions.registration.maxScreenWidth,
    width: '100%'
  },
  formContainer: {
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'stretch',
    ...isMobile && {paddingHorizontal: dimensions.margins.xxLarge},
    width: isPhone ? '100%' : dimensions.inputs.width.login
  }
});

function isActiveAccountAlreadyExistsError(error: {errorCode: ValidationErrorCode}): boolean {
  switch (error.errorCode) {
    case ValidationErrorCode.ActiveAccountWithUsernameExists:
    case ValidationErrorCode.ActiveAccountWithEmailExists:
      return true;
    default:
      return false;
  }
}

function isInactiveAccountAlreadyExistsError(error: {errorCode: ValidationErrorCode}): boolean {
  switch (error.errorCode) {
    case ValidationErrorCode.InactiveAccountWithEmailExists:
    case ValidationErrorCode.InactiveAccountWithUsernameExists:
      return true;
    default:
      return false;
  }
}

type FormData = Omit<RegistrationData, 'username' | 'phone'> & {username: ''};
type State = 'form' | 'success';

const RegistrationScreen: React.FC<NavigationScreenProps> = ({navigation}) => {
  const {t, i18n} = useTranslation();
  const [state, setState] = useState<State>('form');
  const {error, showError, onCloseErrorPopup} = useErrorPopup();
  const safeAwait = useSafeAwait();
  const [alreadyRegisteredEmailOrUsername, setAlreadyRegisteredEmailOrUsername] = useState<string>();
  const navigateToCredentialsOnErrorPopupClose = useRef(false);

  const register = useDisposable((data: RegistrationData) => mw.customer.register(data));

  const mapPartialValidationError = useCallback((error: ValidationError): PopupError => {
    const message = error.errors.map(({field, errorCode}) => {
      if (field && errorCode) {
        const messageKey = `${registrationErrorPrefix}.${field}.${StringUtils.lowerFirst(errorCode)}`;
        if (i18n.exists(messageKey)) {
          return t(messageKey);
        }
      }
      Log.warn(TAG, `Missing translation for ${field} with error code ${errorCode}.`);
      return t(`${registrationErrorPrefix}.default`);
    });
    return {
      title: t('registration.registrationResult.error.title'),
      message
    };
  }, [t, i18n]);

  const mapError = useCallback((error: MWError | ValidationError): PopupError => {
    if (error instanceof ValidationError) {
      return mapPartialValidationError(error);
    }
    switch (error.type) {
      case ErrorType.HttpTimeout:
      case ErrorType.NetworkNoConnection:
      case ErrorType.NetworkRequestFailed:
        return {
          title: t('registration.registrationResult.error.title'),
          message: t('common.noNetworkError'),
          icon: IconType.ErrorInternetConnection
        };
      default:
        return {
          title: t('registration.registrationResult.error.title'),
          message: t('registration.unexpectedError.message'),
          icon: IconType.ErrorUnknown
        };
    }
  }, [t, mapPartialValidationError]);

  const close = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const onSubmit = useCallback((data: FormData) => {
    Log.info(
      TAG,
      'Filled inputs:',
      Object.entries(data)
        .filter(([, value]) => value)
        .map(([key]) => key)
        .join(', ')
    );
    return register({
      ...data,
      username: mw.configuration.registrationUseMailAsUsername ? data.email : data.username
    })
      .then(() => setState('success'))
      .catch(e => showError(mapError(e)));
  }, [mapError, register, showError]);

  const showAccountAlreadyExistPopup = useCallback((errorCode: ValidationErrorCode, username: string, email: string) => {
    switch (errorCode) {
      case ValidationErrorCode.InactiveAccountWithEmailExists:
        setAlreadyRegisteredEmailOrUsername(email);
        break;
      case ValidationErrorCode.InactiveAccountWithUsernameExists:
        setAlreadyRegisteredEmailOrUsername(username);
        break;
    }
  }, []);

  const showAccountAlreadyActiveError = useCallback(() => {
    navigateToCredentialsOnErrorPopupClose.current = true;
    showError({
      title: t('registration.registrationResult.error.title'),
      message: t('registration.errors.accountAlreadyActive')
    });
  }, [showError, t]);

  const validate = useCallback((data: FormData) => {
    const username = mw.configuration.registrationUseMailAsUsername ? data.email : data.username;
    return safeAwait(mw.customer.validate({
      ...data,
      username
    }))
      .catch(error => {
        if (error instanceof ValidationError) {
          if (error.errors.find(isActiveAccountAlreadyExistsError)) {
            showAccountAlreadyActiveError();
            throw error;
          }
          const inactiveAccountAlreadyExistsError = error.errors.find(isInactiveAccountAlreadyExistsError);
          if (inactiveAccountAlreadyExistsError) {
            showAccountAlreadyExistPopup(inactiveAccountAlreadyExistsError.errorCode, username, data.email);
            throw error;
          }
        }
        showError(mapError(error));
        throw error;
      });
  }, [mapError, showError, safeAwait, showAccountAlreadyActiveError, showAccountAlreadyExistPopup]);

  const onCloseAccountAlreadyRegisteredPopup = useCallback(() =>{
    setAlreadyRegisteredEmailOrUsername(undefined);
    navigation.navigate(AppRoutes.Credentials);
  }, [navigation]);

  const closeErrorPopup = useCallback(() => {
    onCloseErrorPopup();
    if (navigateToCredentialsOnErrorPopupClose.current) {
      navigateToCredentialsOnErrorPopupClose.current = false;
      navigation.navigate(AppRoutes.Credentials);
    }
  }, [navigation, onCloseErrorPopup]);

  const formConfig = useMemo<FormConfig<FormData>>(() => {
    const config: FormConfig<FormData> = {
      steps: [
        {
          fields: [
            {
              dataKey: 'email',
              title: t('registration.form.email')
            }
          ],
          validator: validate
        },
        {
          fields: [
            {
              dataKey: 'password',
              title: t('registration.form.password'),
              secure: true
            }, {
              dataKey: 'pin',
              title: t('registration.form.pin'),
              secure: true
            }
          ],
          validator: validate
        },
        {
          fields: [
            {
              dataKey: 'firstName',
              title: t('registration.form.firstName')
            }, {
              dataKey: 'surname',
              title: t('registration.form.surname')
            }
          ],
          validator: validate
        }
      ],
      data: {
        email: '',
        firstName: '',
        surname: '',
        password: '',
        pin: '',
        username: ''
      }
    };

    if (!mw.configuration.registrationUseMailAsUsername) {
      // add username form field if supported by mw
      config.steps[0].fields.unshift({
        dataKey: 'username',
        maxLength: MAX_LOGIN_USERNAME_LENGTH,
        title: t('registration.form.username')
      });
      config.data.username = '';
    }
    return config;
  }, [t, validate]);

  const [onReady, focus] = useFocusParent();
  useEffect(() => focus(), [focus]);

  return (
    <NitroxScreen
      navigation={navigation}
      onScreenFocused={focus}
      popStackOnBack
      style={styles.screen}
      testID='screen_registration'
    >
      {isMobile && <SplashBackground />}
      <View style={styles.screenContainer}>
        {state === 'form' && (
          <KeyboardAvoidingView style={styles.formContainer} behavior='position' enabled={isIOS}>
            <FocusParent onReady={onReady} rememberLastFocused>
              <NitroxInteractiveController omitGeometryCaching>
                <Form<FormData>
                  formConfig={formConfig}
                  title={t('registration.registration')}
                  onCancel={close}
                  onSubmit={onSubmit}
                />
              </NitroxInteractiveController>
            </FocusParent>
          </KeyboardAvoidingView>
        )}
        {state === 'success' && (
          <FormResult
            image={successAsset}
            title={t('registration.registrationResult.success.title')}
            message={t('registration.registrationResult.success.message')}
          >
            <View style={styles.formContainer}>
              <FormButton
                hasTvPreferredFocus
                positive
                text={t('common.login', {postProcess: buttonTextPostProcessor})}
                testID='button_login'
                onPress={close}
              />
            </View>
          </FormResult>
        )}
        <ErrorPopup
          error={error}
          onClose={closeErrorPopup}
        />
        <AccountAlreadyRegisteredPopup
          visible={!!alreadyRegisteredEmailOrUsername}
          emailOrUsername={alreadyRegisteredEmailOrUsername ?? ''}
          onClose={onCloseAccountAlreadyRegisteredPopup}
          type={AccountAlreadyRegisteredPopupType.Registration}
        />
      </View>
    </NitroxScreen>
  );
};

export default RegistrationScreen;
