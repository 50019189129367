import {Log} from 'common/Log';

import {Error, ErrorType} from 'mw/api/Error';
import {Series, SeriesType} from 'mw/api/Metadata';
import {SeriesObject} from 'mw/metadata/SeriesObject';

import {AssetTypes} from './constants';
import {MetadataMapper} from './MetadataMapper';
import {PictureMapper} from './PictureMapper';

const TAG = 'SeriesMapper';

export class SeriesMapper {
  public static seriesFromJson(seriesJson: any): Series {
    const {uid, title, externalResources, assetType} = seriesJson;

    if (assetType !== AssetTypes.Series) {
      const error = `Invalid subtype for series: ${assetType}`;
      Log.error(TAG, 'seriesFromJson', error);
      throw new Error(ErrorType.InvalidParameter, error);
    }

    const series = new SeriesObject(uid, title, MetadataMapper.metadataFromJson(seriesJson), SeriesType.Series);
    series.pictures = externalResources?.image?.map(PictureMapper.pictureFromJson) ?? [];
    return series;
  }
}
