import React, {useCallback, useEffect, useMemo, useRef} from 'react';

import {isMobile} from 'common/constants';
import {Log} from 'common/Log';
import TestContext from 'common/TestContext';

import {mw} from 'mw/MW';

import FocusParent, {useFocusParentUtility} from 'components/FocusParent';
import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';
import {ProfileSelectPiccolo} from 'components/ProfileSelect.piccolo';
import {ProfileSelectModalProps, ProfileSelectTag} from 'components/ProfileSelect.shared';
import ProfileSelectGrosso from 'components/ProfileSelect2.grosso';
import {useUnlockModal, UnlockModalRejectionReason} from 'components/unlockmodal/UnlockModal';
import {useProfileList, useCurrentProfile} from 'hooks/Hooks';

const ProfileSelectComponent = isMobile ? ProfileSelectPiccolo : ProfileSelectGrosso;

const testIdContext = {
  Modal: 'modal_profile_select'
};

const ProfileSelect: React.FC<ProfileSelectModalProps> = props => {
  const {
    visible,
    onClose: propsOnClose,
    onSelect,
    onCancel,
    profilesType,
    onLoading,
    refreshProfilesOnAppear = true,
    onAddNewPress,
    focusNearestParentOnClose = true
  } = props;

  const {resetUnlockedMedia} = useParentalControl();

  const profileSelected = useRef(false);

  const {focus: focusNearestParent} = useFocusParentUtility();
  const onClose = useCallback(async () => {
    if (focusNearestParentOnClose) {
      focusNearestParent?.();
    }
    propsOnClose?.();
    if (!profileSelected.current) {
      onCancel?.();
    }
  }, [focusNearestParent, focusNearestParentOnClose, onCancel, propsOnClose]);
  const {renderModal, authorizeProfile} = useUnlockModal(onClose);
  const currentProfile = useCurrentProfile();

  useEffect(() => {
    visible && refreshProfilesOnAppear && mw.customer.refreshProfiles();
  }, [visible, refreshProfilesOnAppear]);

  const profileList = useProfileList();
  const profiles = useMemo(
    () => {
      switch (profilesType) {
        case 'adult': return profileList.filter(profile => !profile.isPCEnabled || profile.isMain);
        case 'child': return profileList.filter(profile => profile.isPCEnabled);
        default: return profileList;
      }
    },
    [profilesType, profileList]
  );

  const switchProfile = useCallback(async (id: string) => {
    const profile = mw.customer.profiles.find(p => p.id === id);
    if (!profile) {
      return;
    }

    if (profile === mw.customer.currentProfile) {
      onClose();
      return;
    }

    propsOnClose?.(); // only one modal screen can be presented on iOS at the time, so close it before presenting pin keyboard

    try {
      const pin = profile.isPinRequired ? await authorizeProfile(profile) : null;
      // we do not set false because we want to show loading indicator until home screen is loaded
      // as component after navigation would be unmounted, we don't have to worry
      onLoading?.(true);
      await mw.customer.setProfile(profile, pin);
      profileSelected.current = true;
      resetUnlockedMedia();
    } catch (error) {
      if (error.reason !== UnlockModalRejectionReason.Cancel) {
        Log.error(ProfileSelectTag, 'Error switching profile!', error);
        onLoading?.(false);
        onCancel?.();
      }
    }
  }, [propsOnClose, onClose, authorizeProfile, onLoading, resetUnlockedMedia, onCancel]);

  useEffect(() => {
    if (visible) {
      profileSelected.current = false;
    }
  }, [visible]);

  return (
    <TestContext.Provider value={testIdContext}>
      <FocusParent>
        <ProfileSelectComponent
          {...props}
          currentProfile={currentProfile}
          profiles={profiles}
          onSelect={onSelect || switchProfile}
          onClose={onClose}
          onAddNewPress={onAddNewPress}
        />
        {renderModal()}
      </FocusParent>
    </TestContext.Provider>
  );
};

export default React.memo(ProfileSelect);
