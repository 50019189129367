import {createStyles} from 'common-styles';
import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions, getValue, isPhone, isTablet} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {ParentalControlRating} from 'mw/common/ParentalControlRating';

import NitroxText, {textStyles, TextType} from 'components/NitroxText';
import {PopupAction} from 'components/Popup';
import SelectionPopup, {SelectionPopupSection} from 'components/SelectionPopup';
import {useLazyEffect} from 'hooks/Hooks';
import {PostProcessors} from 'locales/i18nPostProcessors';

import {getPCRatingDescriptionTranslation} from './ParentalControlHelpers';

const ratingDescriptionTextType: TextType = 'dialog-message';
const ratingDescriptionLines = getValue({mobile: 4, tablet: 3, defaultValue: 3});
const ratingDescriptionHeight = ratingDescriptionLines * (textStyles[ratingDescriptionTextType]().lineHeight ?? 0);

const ratingsValueTextType: TextType = 'dialog-message';
const ratingsValueLines = 2;
const ratingsValueHeight = ratingsValueLines * (textStyles[ratingsValueTextType]().lineHeight ?? 0);

const columnCount = isPhone ? 2 : undefined;
const popupActions: PopupAction[] = [PopupAction.POSITIVE, PopupAction.NEGATIVE];
const maxHeight = isPhone ? 720 : undefined;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  infoSection: {
    alignSelf: 'center',
    marginVertical: dimensions.margins.medium,
    marginHorizontal: isPhone ? dimensions.margins.xsmall : 0,
    width: !isPhone ? 700 : undefined
  },
  ratingDescription: {
    color: colors.popup.text,
    textAlign: 'center',
    marginVertical: dimensions.margins.medium,
    marginHorizontal: isTablet ? dimensions.margins.xxLarge : 0,
    minHeight: ratingDescriptionHeight
  },
  ratingsInfo: {
    flexDirection: isPhone ? 'column' : 'row',
    alignItems: isPhone ? 'center' : 'flex-start',
    justifyItems: 'center'
  },
  ratingsItem: {
    flex: isPhone ? 0 : 1
  },
  ratingsSpacing: {
    flex: 0.1
  },
  ratingsTitle: {
    color: colors.popup.text,
    textAlign: 'center'
  },
  ratingsValue: {
    color: colors.popup.text,
    textAlign: 'center',
    marginBottom: dimensions.margins.small,
    minHeight: ratingsValueHeight
  }
}));

type Props = {
  visible: boolean;
  title: string;
  rating: ParentalControlRating;
  initialRating?: string;
  onConfirm: (authority: string, rating: string) => void;
  onClose: () => void;
}

const ParentalControlRatingSelectionPopup: React.FC<Props> = props => {
  const {visible, title, rating, initialRating, onConfirm, onClose} = props;
  const {t} = useTranslation();
  const [selectedRating, setSelectedRating] = useState('');

  useLazyEffect(() => {
    if (visible) {
      setSelectedRating(initialRating ?? '');
    }
  }, [visible], [initialRating]);

  const popupProps = useMemo(() => ({
    actions: popupActions,
    positiveLabel: t('common.ok', {postProcess: PostProcessors.ToUpperCase}),
    negativeLabel: t('common.cancel', {postProcess: PostProcessors.ToUpperCase}),
    onPositive: () => onConfirm(rating.authority, selectedRating),
    onNegative: onClose
  }), [t, onConfirm, selectedRating, rating, onClose]);

  const sections: SelectionPopupSection[] = useMemo(() => {
    const options = rating.ratings.map(rating => ({
      key: rating,
      text: rating,
      selected: rating === selectedRating,
      onPress: () => setSelectedRating(rating)
    }));
    return [{options, center: true}];
  }, [rating, selectedRating]);

  const ratingDescription = useMemo(() => (
    getPCRatingDescriptionTranslation(t, rating.authority, selectedRating)
  ), [rating.authority, selectedRating, t]);

  const ratings = useMemo(() => {
    if (!rating.ratingsMap) {
      return null;
    }
    const blocked = rating.ratingsMap.get(selectedRating) ?? [];
    const allowed = rating.ratings.filter(rating => !blocked.includes(rating)) ?? [];
    return {
      blocked: blocked.join(', '),
      allowed: allowed.join(', ')
    };
  }, [rating.ratingsMap, selectedRating, rating.ratings]);

  const styles = stylesUpdater.getStyles();

  return (
    <SelectionPopup
      title={title}
      sections={sections}
      visible={visible}
      onClose={onClose}
      popupProps={popupProps}
      columnCount={columnCount}
      scrollable={isPhone}
      maxHeight={maxHeight}
    >
      <View style={styles.infoSection}>
        <NitroxText textType={ratingDescriptionTextType} style={styles.ratingDescription}>{ratingDescription}</NitroxText>
        {ratings && (
          <View style={styles.ratingsInfo}>
            <View style={styles.ratingsItem}>
              <NitroxText textType='dialog-message' style={styles.ratingsTitle}>{t('settings.parentalControl.ratingsPopup.allowed')}</NitroxText>
              <NitroxText textType={ratingsValueTextType} style={styles.ratingsValue}>{ratings.allowed}</NitroxText>
            </View>
            {!isPhone && <View style={styles.ratingsSpacing} />}
            <View style={styles.ratingsItem}>
              <NitroxText textType='dialog-message' style={styles.ratingsTitle}>{t('settings.parentalControl.ratingsPopup.blocked')}</NitroxText>
              <NitroxText textType={ratingsValueTextType} style={styles.ratingsValue}>{ratings.blocked}</NitroxText>
            </View>
          </View>
        )}
      </View>
    </SelectionPopup>
  );
};

export default React.memo(ParentalControlRatingSelectionPopup);
