import {createStyles} from 'common-styles';
import React, {useMemo, useRef, useState} from 'react';
import {StyleProp, ViewStyle} from 'react-native';
// eslint-disable-next-line no-restricted-imports
import {PanGestureHandler} from 'react-native-gesture-handler';
import Reanimated from 'react-native-reanimated';

import {Axis, dimensions, isBigScreen, isMobile, isPhone, RADIUS} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors, constColors as baseColorsConsts, transparent} from 'common-styles/variables/base-colors';

import {Offer, Product} from 'mw/api/Metadata';

import {constColors} from 'brand/ColorTypes';
import ConditionalWrapper from 'components/ConditionalWrapper';
import {useScroll} from 'components/epg/animated/OmniScroll';
import AnimatedScrollView from 'components/epg/animated/ReanimatedScrollView';
import FocusParent from 'components/FocusParent';
import {IconType} from 'components/Icon';
import NitroxButton, {IconPosition, NitroxButtonTheme} from 'components/NitroxButton';
import {NitroxInteractiveController} from 'components/NitroxInteractiveControllerContext';
import ProductTile from 'components/payments/ProductTile';
import {useReanimatedScrollTo} from 'components/scroll/useReanimatedScrollTo';
import {useChangeEffect, useLazyRef, useScreenInfo} from 'hooks/Hooks';

export const visibleElementsCount = 3;
const tileMargin = isPhone ? dimensions.margins.small : dimensions.margins.large;
const tileWithMargin = dimensions.payments.product.tile.width + tileMargin;
const arrowWidth = 100;
const arrowHeight = 118;
const arrowMargin = dimensions.margins.small;
export const arrowContainerWidth = isBigScreen ? arrowWidth + arrowMargin : 0;
const listMargin = dimensions.margins.small;
const maxListWidth = dimensions.payments.product.tile.width * visibleElementsCount + tileMargin * (visibleElementsCount - 1);
export const maxContainerWidth = maxListWidth + (arrowContainerWidth + listMargin) * 2;
const iconProps = {
  position: IconPosition.LEFT,
  size: arrowHeight
};

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  arrowButton: {
    position: 'absolute',
    width: arrowWidth,
    height: dimensions.payments.product.tile.height,
    borderColor: baseColorsConsts.transparent,
    borderWidth: dimensions.payments.product.tile.borderWidth,
    borderRadius: RADIUS
  },
  arrowButtonFocused: {
    borderColor: colors.defaultColors.icon
  }
}));

export const getBestOffer = (offers: Offer[]) => {
  return offers.reduce((best, curr) => best.price < curr.price ? best : curr);
};

type Props = {
  style: StyleProp<ViewStyle>;
  products: Product[];
  selectedProductId?: string;
  onPress?: (productId: string) => void;
};

const ProductsList: React.FunctionComponent<Props> = props => {
  const {
    style,
    products,
    selectedProductId,
    onPress
  } = props;

  const dynamicStyle = stylesUpdater.getStyles();
  const listOffset = useRef(new Reanimated.Value(0)).current;
  const [firstVisibleElementIndex, setFirstVisibleElementIndex] = useState(0);
  const {size: {width: screenWidth}} = useScreenInfo();

  const components = useMemo(() => (
    products.map(({id, name, description, offers}, index) => {
      const offer = getBestOffer(offers);
      return (
        <ProductTile
          key={id}
          style={{
            position: 'absolute',
            left: index * tileWithMargin
          }}
          id={id}
          title={name}
          description={description || ''}
          price={offer.price}
          currency={offer.currency}
          selected={id === selectedProductId}
          disabled={isBigScreen && index < firstVisibleElementIndex || index >= firstVisibleElementIndex + visibleElementsCount}
          focusPriority={products.length - index}
          onPress={onPress}
        />
      );
    })
  ), [products, selectedProductId, firstVisibleElementIndex, onPress]);

  const {leftArrowIconProps, leftArrowOnPress} = useMemo(() => {
    if (products.length <= visibleElementsCount) {
      return {};
    }
    const leftArrowEnabled = firstVisibleElementIndex !== 0;
    return {
      leftArrowIconProps: {
        ...iconProps,
        type: IconType.ArrowLeftThin,
        color: transparent(constColors.white, leftArrowEnabled ? 1 : dimensions.opacity.xlow)
      },
      leftArrowOnPress: () => leftArrowEnabled && setFirstVisibleElementIndex(firstVisibleElementIndex - 1)
    };
  }, [products, firstVisibleElementIndex, setFirstVisibleElementIndex]);

  const {rightArrowIconProps, rightArrowStyle, rightArrowOnPress} = useMemo(() => {
    if (products.length <= visibleElementsCount) {
      return {};
    }
    const lastVisibleElementIndex = firstVisibleElementIndex + visibleElementsCount - 1;
    const rightArrowEnabled = lastVisibleElementIndex !== components.length - 1;
    return {
      rightArrowIconProps: {
        ...iconProps,
        type: IconType.ArrowRightThin,
        color: transparent(constColors.white, rightArrowEnabled ? 1 : dimensions.opacity.xlow)
      },
      rightArrowStyle: {
        ...dynamicStyle.arrowButton,
        left: maxContainerWidth - arrowContainerWidth
      },
      rightArrowOnPress: () => rightArrowEnabled && setFirstVisibleElementIndex(firstVisibleElementIndex + 1)
    };
  }, [products, firstVisibleElementIndex, setFirstVisibleElementIndex, components, dynamicStyle]);

  const {listSize, containerStyle, listStyle, minScroll} = useMemo(() => {
    const staticList = products.length <= visibleElementsCount;
    const elementsCount = isBigScreen ? Math.min(visibleElementsCount, products.length) : products.length;
    const listSize = {
      width: dimensions.payments.product.tile.width * elementsCount + tileMargin * (elementsCount - 1),
      height: dimensions.payments.product.tile.height
    };
    return {
      listSize,
      containerStyle: {
        width: isBigScreen ? maxContainerWidth - (staticList ? (arrowContainerWidth + listMargin) * 2 : 0) : screenWidth,
        height: listSize.height
      },
      listStyle: {
        width: listSize.width,
        left: staticList ? listMargin : arrowContainerWidth + listMargin
      },
      minScroll: -(products.length - Math.floor((screenWidth - listMargin) / tileWithMargin)) * tileWithMargin
    };
  }, [products.length, screenWidth]);

  const {scrollTo} = useReanimatedScrollTo(listOffset);
  useChangeEffect(() => {
    scrollTo(-firstVisibleElementIndex * tileWithMargin);
  }, [listOffset, firstVisibleElementIndex]);

  const useScrollProps = useLazyRef(() => ({
    minValue: new Reanimated.Value(minScroll),
    maxValue: new Reanimated.Value(0),
    position: listOffset,
    offset: new Reanimated.Value(0),
    snapToInterval: new Reanimated.Value(tileWithMargin)
  })).current;

  const {onGestureEvent} = useScroll({
    ...useScrollProps,
    axis: Axis.X
  });

  return (
    <FocusParent style={[containerStyle, style]} enterStrategy={'byPriority'} rememberLastFocused>
      <ConditionalWrapper
        condition={isMobile}
        wrapper={children => (
          <PanGestureHandler
            onHandlerStateChange={onGestureEvent}
            onGestureEvent={onGestureEvent}
          >
            <Reanimated.View style={containerStyle}>
              {children}
            </Reanimated.View>
          </PanGestureHandler>
        )}
      >
        <AnimatedScrollView
          style={listStyle}
          size={listSize}
          positionX={listOffset}
        >
          <NitroxInteractiveController omitGeometryCaching>
            {components}
          </NitroxInteractiveController>
        </AnimatedScrollView>
        {isBigScreen && products.length > visibleElementsCount && (
          <>
            <NitroxButton
              style={dynamicStyle.arrowButton}
              styleFocused={dynamicStyle.arrowButtonFocused}
              theme={NitroxButtonTheme.Tertiary}
              icon={leftArrowIconProps}
              onPress={leftArrowOnPress}
            />
            <NitroxButton
              style={rightArrowStyle}
              styleFocused={dynamicStyle.arrowButtonFocused}
              theme={NitroxButtonTheme.Tertiary}
              icon={rightArrowIconProps}
              onPress={rightArrowOnPress}
            />
          </>
        )}
      </ConditionalWrapper>
    </FocusParent>
  );
};

export default ProductsList;
