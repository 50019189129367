import {createStyles} from 'common-styles';
import React, {useState, useEffect, useCallback, useRef, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {isWeb, dimensions, isBigScreen} from 'common/constants';
import {maxBy} from 'common/helpers/ArrayHelperFunctions';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Episode, Media, Series, Title, isTitle} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import MediaTile from 'components/mediaTiles/MediaTile';
import {NitroxListItemsAction, NitroxListItemsBatch, NitroxListItemsRequest} from 'components/NitroxList';
import NitroxText from 'components/NitroxText';
import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';
import ShortDetails from 'components/ShortDetailsView';
import {useLazyEffect, useFunction} from 'hooks/Hooks';

import BingeButtons from './BingeButtons';
import MediaDetailsTemplate, {DetailsBaseProps, getMediaMetadata} from './MediaDetailsTemplate';
import SeasonsRow from './SeasonsRow';

export enum SeriesDetailsType {
  OVERLAY,
  PAUSED,
  BINGE
}

const seriesAnnotationHeight = 44;
const mediaDimensions = {
  width: dimensions.tile.width + dimensions.margins.small * 2, // include two (left and right) margins
  height: dimensions.tile.height + dimensions.margins.small + seriesAnnotationHeight
};

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  seasonSeparator: {
    position: 'absolute',
    height: '100%',
    width: 2,
    backgroundColor: colors.overlayMediaDetails.series.separator
  },
  seasonText: {
    marginLeft: 14,
    marginBottom: 12,
    color: colors.overlayMediaDetails.text.focused
  },
  seasonTextPlaceholder: {
    height: seriesAnnotationHeight
  },
  leftMediaListContainer: {
    position: 'absolute',
    left: -mediaDimensions.width,
    top: -seriesAnnotationHeight,
    width: mediaDimensions.width,
    height: mediaDimensions.height,
    paddingRight: 118
  },
  rightMediaListContainer: {
    position: 'absolute',
    left: dimensions.playerMediaDetails.width,
    top: -seriesAnnotationHeight,
    width: isWeb ? '60%' : '100%',
    height: mediaDimensions.height,
    paddingLeft: 67 // keep the padding fixed and adjust only the width of the container
  },
  shortDetails: {
    width: dimensions.playerMediaDetails.width
  }
}));

type Props = {
  type: SeriesDetailsType;
  onBuyPress?: (title: Title) => void;
  onRentPress?: (title: Title) => void;
} & DetailsBaseProps

const SeriesDetails: React.FC<Props> = (props) => {
  const {type, handlers, landscape} = props;
  const {t} = useTranslation();
  const [focusedMedia, setFocusedMedia] = useState(props.focusedMedia as Title);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [seasons, setSeasons] = useState<Series[]>([]);
  const [titlesLoading, setTitlesLoading] = useState(false);
  const [titles, setTitles] = useState<Title[]>([]);
  const [activeSeasonId, setActiveSeasonId] = useState((props.focusedMedia as Title).episode?.seasonId);
  const mediaDetailsRef = useRef<{scrollToIndex: (index: number, forceIndex?: boolean) => void}>();
  const bingeActive = useMemo(() => type === SeriesDetailsType.BINGE, [type]);
  const {isMediaBlocked} = useParentalControl();

  const onBingeWatch = useCallback(() => {
    handlers?.onWatch(titles[focusedIndex], true);
  }, [focusedIndex, handlers, titles]);

  const onRentPress = useCallback(() => {
    handlers?.onRentPress?.(titles[focusedIndex]);
  }, [focusedIndex, handlers, titles]);

  const onBuyPress = useCallback(() => {
    handlers?.onBuyPress?.(titles[focusedIndex]);
  }, [focusedIndex, handlers, titles]);

  useLazyEffect(() => {
    const fetchSeasons = async (seriesId: string) => {
      const seasons = await mw.catalog.getSeriesSeasonsById(seriesId);
      setSeasons(seasons);
    };

    focusedMedia.episode && fetchSeasons(focusedMedia.episode.seriesId);
  }, [], [focusedMedia]);

  useEffect(() => {
    setFocusedMedia(props.focusedMedia as Title);
  }, [props.focusedMedia]);

  const fetchSeriesEpisodes = useCallback(async ({action}: NitroxListItemsRequest) => {
    if (!focusedMedia.episode) return new NitroxListItemsBatch([], false);

    const titles = await mw.catalog.getSeriesEpisodesById(focusedMedia.episode.seriesId);
    setTitles(titles);
    const index = titles.findIndex(item => item.id === focusedMedia.id);
    if (index === -1 || action === NitroxListItemsAction.Prepend) return new NitroxListItemsBatch([], false);

    const focusedIndex = bingeActive ? index + 1 : index;
    bingeActive && setFocusedMedia(titles[focusedIndex]);
    setFocusedIndex(focusedIndex);

    return new NitroxListItemsBatch(titles, false, focusedIndex);
  }, [focusedMedia, bingeActive]);

  useLazyEffect(() => {
    if (focusedMedia.episode?.seasonId !== activeSeasonId) {
      setActiveSeasonId(focusedMedia.episode?.seasonId);
    }
  }, [focusedMedia], [activeSeasonId]);

  const requestData = useCallback(async (request: NitroxListItemsRequest) => {
    setTitlesLoading(true);
    const data = await fetchSeriesEpisodes(request);
    setTitlesLoading(false);
    return data;
  }, [fetchSeriesEpisodes]);

  const findFirstEpisodeOfSeason = useCallback((season: Series) => {
    const episodes = titles
      .reduce<{episode: Episode, index: number}[]>((prev, {episode}, index) => {
      if (episode && episode.seasonNumber === season.seasonNumber) {
        prev.push({episode, index});
      }
      return prev;
    }, []);

    return episodes.find(({episode}) => episode.firstEpisodeOfSeason)?.index
      ?? maxBy(episodes, ({episode: a}, {episode: b}) => (a.number || 0) < (b.number || 0))?.index
      ?? -1;
  }, [titles]);

  // useFunction needs to be used here, otherwise on web onClick is not updated and titles is an empty array
  const scrollToSeason = useFunction((season: Series) => {
    const index = findFirstEpisodeOfSeason(season);

    if (index !== -1 && mediaDetailsRef.current) {
      mediaDetailsRef.current.scrollToIndex(index, true);
    }
  });

  const styles = stylesUpdater.getStyles();

  const renderMedia = (itemData: Media): React.ReactElement | undefined => {
    if (!isTitle(itemData)) return;

    const title = itemData as Title;
    if (title.episode?.firstEpisodeOfSeason) {
      return (
        <>
          <View style={styles.seasonSeparator} />
          <NitroxText textType='callout' style={styles.seasonText}>
            {t('zapper.seasonNumberFull', {seasonNumber: title.episode.seasonNumber})}
          </NitroxText>
          <MediaTile media={title} focusable={false} />
        </>
      );
    }
    return (
      <>
        <View style={styles.seasonTextPlaceholder} />
        <MediaTile media={itemData} focusable={false} />
      </>
    );
  };

  const onFocusChange = (index: number) => {
    setFocusedIndex(index);
    const {episode} = titles[index];

    if (episode && activeSeasonId !== episode.seasonId) {
      setActiveSeasonId(episode.seasonId);
    }
  };

  const renderOverlay = () =>
    <ShortDetails focusable={false} landscape={landscape} {...getMediaMetadata(t, isMediaBlocked, focusedMedia)} style={styles.shortDetails} titleNumberOfLines={isBigScreen ? 1 : 2} />;

  if (type === SeriesDetailsType.OVERLAY) return renderOverlay();

  const buttonType = titlesLoading || focusedMedia?.id === titles[focusedIndex]?.id ? 'watchNext' : 'watch';

  return (
    <>
      <MediaDetailsTemplate
        {...props}
        ref={mediaDetailsRef}
        mediaDimensions={mediaDimensions}
        leftMediaListContainer={styles.leftMediaListContainer}
        rightMediaListContainer={styles.rightMediaListContainer}
        focusedMedia={focusedMedia}
        renderMedia={renderMedia}
        requestData={requestData}
        bingeActive={bingeActive}
        onFocusChange={onFocusChange}
      />
      {bingeActive && (
        <>
          <BingeButtons
            buttonType={buttonType}
            title={titles[focusedIndex]}
            onBingeWatch={onBingeWatch}
            onBingeBack={handlers?.onBingeBack}
            onBuyPress={onBuyPress}
            onRentPress={onRentPress}
          />
          <SeasonsRow data={seasons} activeSeasonId={activeSeasonId} onSeasonPress={scrollToSeason} />
        </>
      )}
    </>
  );
};

export default SeriesDetails;
