import {createStyles} from 'common-styles';
import React from 'react';
import {View} from 'react-native';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {PromotionalBannerDetailsProps} from './PromotionalBannerDetails.shared';

//TODO: CL-7344 Placeholder for future implementation

const titleNumberOfLines = 2;

const styles = createStyles({
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
}));

const PromotionalBannerDetailsPiccolo: React.FunctionComponent<PromotionalBannerDetailsProps> = props => {
  const dynamicStyles = stylesUpdater.getStyles();

  return (
    <View />
  );
};

export default React.memo(PromotionalBannerDetailsPiccolo);

