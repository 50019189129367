import {isBrowser} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {nxffConfig} from 'mw/api/NXFF';
import {VideojsNitroxAudioPlayer} from 'mw/playback/web-native-player/adapters/videojs/VideojsNitroxAudioPlayer';
import {VideojsNitroxPlayerDash} from 'mw/playback/web-native-player/adapters/videojs/VideojsNitroxPlayerDash';
import {VideojsNitroxPlayerHls} from 'mw/playback/web-native-player/adapters/videojs/VideojsNitroxPlayerHls';
import {VideojsNitroxPlayerHlsSafari} from 'mw/playback/web-native-player/adapters/videojs/VideojsNitroxPlayerHlsSafari';
import {FairPlayDrmInterceptor} from 'mw/playback/web-native-player/drm/FairPlayDrmInterceptor';
import {WidevineDrmInterceptor} from 'mw/playback/web-native-player/drm/WidevineDrmInterceptor';
import {StreamTypeProvider, StreamType} from 'mw/playback/web-native-player/StreamTypeProvider';
import {PlayerView} from 'mw/playback/web-native-player/types';

import {DrmInterceptor} from './DrmInterceptor';
import {NitroxPlayer} from './NitroxPlayer';

const TAG = 'NitroxPlayerFactory';

export class NitroxPlayerFactory {
  private static instance: NitroxPlayerFactory;

  private readonly playerProperties: any = {};

  private constructor() {}

  public static getInstance(): NitroxPlayerFactory {
    return NitroxPlayerFactory.instance || (NitroxPlayerFactory.instance = new NitroxPlayerFactory());
  }

  public destroyPlayer(player: NitroxPlayer): void {
    player.release();
  }

  public createPlayer(url: string, playerView: PlayerView): NitroxPlayer | null {
    let player: NitroxPlayer | null = null;
    if (url == null) {
      Log.error(TAG, 'createPlayer: can\'t create player without URL');
      return null;
    }

    try {
      Log.debug(TAG, 'Using Videojs-based implementation');
      switch (StreamTypeProvider.getStreamType(url)) {
        case StreamType.m3u8: {
          player = new (isBrowser('safari') ? VideojsNitroxPlayerHlsSafari : VideojsNitroxPlayerHls)(playerView);
          break;
        }
        case StreamType.mp3: {
          player = new VideojsNitroxAudioPlayer(playerView);
          break;
        }
        default: {
          player = new VideojsNitroxPlayerDash(playerView);
          break;
        }
      }

      const drmInterceptor = this.getDrmInterceptor();
      player.setDrmInterceptor(drmInterceptor);

      player.setProperties(this.playerProperties);
      player.init();
      return player;
    } catch (error) {
      Log.error(TAG, 'createPlayer failed', error);
      player?.release();
      return null;
    }
  }

  private getDrmInterceptor(): DrmInterceptor | null {
    const drmProxyType = nxffConfig.getConfig().DRM.DRMProxyType;
    switch (drmProxyType) {
      case 'Mega':
      case 'SeaChange':
        return isBrowser('safari')
          ? new FairPlayDrmInterceptor()
          : new WidevineDrmInterceptor();
    }
    return null;
  }
}
