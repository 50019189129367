import {cancelable} from 'common/Async';
import {Log} from 'common/Log';

import {Errors} from 'mw/api/Error';
import {Media, ContentType} from 'mw/api/Metadata';
import {PlaybackRequestParameters} from 'mw/playback/types/PlaybackParameters';
import {PlayerType} from 'mw/playback/types/PlayerType';

import {PlaybackController} from './PlaybackController';

const TAG = 'InvalidPlayer';

export class InvalidPlayer extends PlaybackController {

  private static instance: InvalidPlayer = new InvalidPlayer();
  public static getInstance = (): InvalidPlayer => InvalidPlayer.instance;

  private constructor() {
    super(PlayerType.MAIN, ContentType.UNKNOWN);
  }

  public initialize = cancelable(async (viewId: number, source: Media, playbackParameters: PlaybackRequestParameters): Promise<never> => {
    Log.debug(TAG, 'initialize', playbackParameters);
    throw Errors.PlayerNotSupportedSource;
  })

  public changeParameters(playbackParameters: PlaybackRequestParameters): Promise<any> {
    Log.debug(TAG, 'changeParameters', playbackParameters);
    return Promise.resolve();
  }

  public stop(): Promise<any> {
    Log.debug(TAG, 'stop');
    return Promise.resolve();
  }
}
