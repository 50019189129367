import {createStyles} from 'common-styles';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions, isBigScreen, isMobile} from 'common/constants';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Error} from 'mw/api/Error';
import {mw} from 'mw/MW';

import ErrorPopup, {useErrorPopup} from 'components/ErrorPopup';
import {IconType, Icon} from 'components/Icon';
import NitroxButton, {NitroxButtonTheme} from 'components/NitroxButton';
import NitroxText from 'components/NitroxText';
import Popup, {PopupAction} from 'components/Popup';
import ProfileSelect from 'components/ProfileSelect';
import {useUnlockModal, UnlockModalRejectionReason} from 'components/unlockmodal/UnlockModal';
import {useToggle} from 'hooks/Hooks';

const TAG = 'SettingsDeleteProfile';

const preferredWidth = isBigScreen ? 662 : dimensions.inputs.width.settings;
const buttonPreferredWidth = isBigScreen ? 345 : preferredWidth;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flex: 1,
    alignItems: 'center',
    ...isMobile ? {
      padding: dimensions.margins.xxLarge
    } : {
      paddingTop: dimensions.margins.xxxxLarge,
      paddingHorizontal: dimensions.screen.container.paddingHorizontal
    }
  },
  warningContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1
  },
  warningText: {
    color: colors.settingsScreen.contentText,
    textAlign: 'center',
    marginTop: isBigScreen ? dimensions.margins.medium : dimensions.margins.small,
    maxWidth: preferredWidth
  },
  buttonContainer: {
    minWidth: buttonPreferredWidth,
    marginTop: isBigScreen ? dimensions.margins.xxxLarge : undefined,
    height: 50
  },
  button: {
    flex: 1
  },
  popupContainer: {
    alignItems: 'center'
  },
  message: {
    marginBottom: dimensions.margins.medium,
    color: colors.popup.text
  }
}));

async function onPressDeleteProfile(pin: string) {
  const profile = mw.customer.currentProfile;
  if (!profile) {
    return;
  }
  await mw.customer.deleteProfile(profile, pin);
}

async function logout() {
  try {
    await mw.bo.logout();
  } catch (error) {
    Log.error(TAG, 'Error during logout:', error);
  }
}

export type SettingsDeleteProfileProps = {
  pin?: string;
};

const popupActions = [PopupAction.POSITIVE, PopupAction.NEGATIVE];
const deleteButtonProps = isBigScreen ? {border: true} : {theme: NitroxButtonTheme.Destructive};

const SettingsDeleteProfile: React.FC<SettingsDeleteProfileProps> = ({
  pin = ''
}) => {
  const {t} = useTranslation();
  const [confirmationPopupVisible, {on: showConfirmationPopup, off: hideConfirmationPopup}] = useToggle(false);
  const [profileSelectVisible, setProfileSelectVisible] = useState(false);
  const {error, showError, onCloseErrorPopup} = useErrorPopup();
  const {renderModal, authorizeProfile} = useUnlockModal();

  const confirmDelete = useCallback(async () => {
    try {
      await onPressDeleteProfile(pin);
      hideConfirmationPopup();
      if (mw.customer.profiles.length === 1) {
        const profile = mw.customer.profiles[0];
        const pin = profile.isPinRequired ? await authorizeProfile(profile) : '';
        mw.customer.setProfile(profile, pin)
          .catch((error: Error) => Log.error(TAG, 'confirmDelete', error));
        return;
      }
      setProfileSelectVisible(true);
    } catch (error) {
      if (error.reason !== UnlockModalRejectionReason.Cancel) {
        showError(`${t('settings.errors.profileDeletionFailed')} ${JSON.stringify(error)}`.trim());
      }
    }
  }, [pin, hideConfirmationPopup, showError, t, authorizeProfile]);

  const styles = stylesUpdater.getStyles();
  return (
    <View style={styles.container}>
      <View style={styles.warningContainer}>
        <Icon type={IconType.Warning} size={dimensions.icon.medium} />
        <NitroxText style={styles.warningText} textType='callout'>{t('settings.deleteProfileWarning')}</NitroxText>
      </View>
      <View style={styles.buttonContainer}>
        <NitroxButton
          style={styles.button}
          text={t('settings.deleteProfileButton')}
          onPress={showConfirmationPopup}
          {...deleteButtonProps}
        />
      </View>
      <Popup
        visible={confirmationPopupVisible}
        title={t('settings.deleteProfile')}
        actions={popupActions}
        positiveLabel={t('settings.yes')}
        negativeLabel={t('settings.cancel')}
        onClose={hideConfirmationPopup}
        onPositive={confirmDelete}
        onNegative={hideConfirmationPopup}
        containerStyle={styles.popupContainer}
      >
        <NitroxText style={styles.message} textType='dialog-message'>{t('settings.confirmDeleteProfile', {profileName: mw.customer.currentProfile ? mw.customer.currentProfile.name : ''})}</NitroxText>
      </Popup>
      <ProfileSelect
        visible={profileSelectVisible}
        onClose={() => setProfileSelectVisible(false)}
        onCancel={logout}
        fullscreen
        hasLogoutButton
        focusNearestParentOnClose={false}
      />
      <ErrorPopup
        error={error}
        onClose={onCloseErrorPopup}
      />
      {renderModal()}
    </View>
  );
};

export default SettingsDeleteProfile;
