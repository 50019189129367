/* Stub implementation made for web NIT-9422 */

import {createStyles} from 'common-styles';
import i18next from 'i18next';
import React, {createRef} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {StyleSheet, InteractionManager} from 'react-native';
import {NavigationInjectedProps} from 'react-navigation';

import {debounce, disposable} from 'common/Async';
import {isBigScreen, isTablet, AppRoutes, isPhone, isMobile} from 'common/constants';
import {addToWatchList, findPlayableRecording, removeFromWatchList} from 'common/HelperFunctions';
import {NavigationFocusState, MediaOpenableProps} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Channel, Event, MediaType, RecordingType, UpdateMediaParams} from 'mw/api/Metadata';
import {mw, CatalogEvent} from 'mw/MW';

import {EpgMenuAction} from 'components/epg/EpgMenuPopup.shared';
import ErrorPopup, {PopupError} from 'components/ErrorPopup';
import {IconType} from 'components/Icon';
import {MobileScreenHeaderProps} from 'components/mobileScreenHeader/MobileScreenHeader';
import {MoreActionsAction} from 'components/MoreActionsPopup';
import {STBMenuState} from 'components/navigation/NavigationHelperTypes';
import {withParentalControl, ParentalControlContextType} from 'components/parentalControl/ParentalControlProvider';
import {withPlayerLauncher} from 'components/playerLauncher/PlayerLauncher';
import {WithPlayerLauncher} from 'components/playerLauncher/PlayerLauncher.shared';
import {WithRecord, withRecord} from 'components/pvr/Record';
import {RecordActionType} from 'components/pvr/RecordingUtils';
import {withUnlockModal, WithUnlockModal} from 'components/unlockmodal/UnlockModal';
import {PostProcessors} from 'locales/i18nPostProcessors';
import {WithLocalized, withLocalized} from 'locales/i18nUtils';
import NitroxScreen from 'screens/NitroxScreen';

import {onPipPlayerViewReady} from './EpgHelperFunctions';
import {EpgScreenPlayerViewLocation, EpgMenuActions, EpgScreenProps} from './EpgHelperTypes';
import EpgScreenGrosso from './EpgScreen.grosso';
import EpgScreenPiccolo from './EpgScreen.piccolo';

const TAG = 'EpgScreen';

const userInactivePeriod = 15 * 60 * 1000; // 15 min
const checkInactivePeriod = 60 * 1000; // check every minute if user is inactive
const nextPopupDelay = 50;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: colors.epgScreen.background
  }
}));

const epgActionsFactory: (t: i18next.TFunction) => EpgMenuActions = (t) => ({
  watchLive: {
    key: 'watchLive',
    text: t('epg.watchLive', {postProcess: PostProcessors.ToUpperCase}),
    icon: IconType.GoToLive,
    onPress: () => Log.debug(TAG, 'EpgMenuActions: watchLive is not implemented')
  },
  watchRecording: {
    key: 'watchRecording',
    text: t('epg.watchRecording', {postProcess: PostProcessors.ToUpperCase}),
    icon: IconType.Play,
    onPress: () => Log.debug(TAG, 'EpgMenuActions: watchRecording is not implemented')
  },
  moreInfo: {
    key: 'moreInfo',
    text: t('epg.moreInfo', {postProcess: PostProcessors.ToUpperCase}),
    icon: IconType.MoreInfo,
    onPress: () => Log.debug(TAG, 'EpgMenuActions: moreInfo is not implemented')
  },
  record: {
    key: 'record',
    text: t('epg.record', {postProcess: PostProcessors.ToUpperCase}),
    icon: IconType.RecordDot,
    onPress: () => Log.debug(TAG, 'EpgMenuActions: record is not implemented')
  },
  recordSeries: {
    key: 'recordSeries',
    text: t('epg.recordSeries', {postProcess: PostProcessors.ToUpperCase}),
    icon: IconType.RecordSeries,
    onPress: () => Log.debug(TAG, 'EpgMenuActions: record is not implemented')
  },
  recordAll: {
    key: 'recordAll',
    text: t('epg.recordAll', {postProcess: PostProcessors.ToUpperCase}),
    icon: IconType.RecordSeries,
    onPress: () => Log.debug(TAG, 'EpgMenuActions: record is not implemented')
  },
  goToNow: {
    key: 'goToNow',
    text: t('epg.goToNow', {postProcess: PostProcessors.ToUpperCase}),
    icon: IconType.GoToNow,
    onPress: () => Log.debug(TAG, 'EpgMenuActions: goToNow is not implemented')
  },
  restart: {
    key: 'restart',
    text: t('epg.restart', {postProcess: PostProcessors.ToUpperCase}),
    icon: IconType.Restart,
    onPress: () => Log.debug(TAG, 'EpgMenuActions: restart is not implemented')
  },
  addToWatchList: {
    key: 'addToWatchList',
    text: t('epg.watchlist', {postProcess: PostProcessors.ToUpperCase}),
    icon: IconType.Add,
    onPress: () => Log.debug(TAG, 'EpgMenuActions: addToWatchList is not implemented')
  },
  removeFromWatchList: {
    key: 'removeFromWatchList',
    text: t('epg.watchlist', {postProcess: PostProcessors.ToUpperCase}),
    icon: IconType.Remove,
    onPress: () => Log.debug(TAG, 'EpgMenuActions: removeFromWatchList is not implemented')
  }
});

type State = {
  channels: Channel[];
  focusState: NavigationFocusState;
  focusedEvent?: Event;
  epgGridVisibleTime?: Date;
  menuActions: EpgMenuAction[];
  moreActions: MoreActionsAction[];
  menuPopupVisible: boolean;
  menuPopupLoading: boolean;
  anyModalVisible: boolean;
  epgScreenMobilePlayerViewOpen: boolean;
  errorPopup: PopupError | null;
}

type Props = MediaOpenableProps & NavigationInjectedProps & WithTranslation & WithLocalized & WithUnlockModal & WithRecord & WithPlayerLauncher & ParentalControlContextType;

class EpgScreen extends React.PureComponent<Props, State> {
  public static navigationOptions = {hiddenMainMenu: true};
  public epgRef = createRef<any>();

  private createMobileHeader = () => ({title: this.props.t('cms.epg')});
  private mobileHeader: MobileScreenHeaderProps = this.createMobileHeader();
  private epgActions: EpgMenuActions;

  public constructor(props: Props) {
    super(props);
    this.state = {
      channels: [],
      focusState: this.props.navigation.isFocused() ? NavigationFocusState.IsFocused : NavigationFocusState.IsBlurred,
      menuPopupVisible: false,
      anyModalVisible: false,
      menuPopupLoading: false,
      menuActions: [],
      moreActions: [],
      epgScreenMobilePlayerViewOpen: true,
      errorPopup: null
    };
    this.epgActions = epgActionsFactory(this.props.t);
  }

  public componentDidMount() {
    this.loadChannels();
    mw.catalog.on(CatalogEvent.ChannelsListRefreshed, this.loadChannels);
    mw.catalog.on(CatalogEvent.EPGRefreshed, this.loadChannels);

    this.updateEventBookmark = disposable((event: Event) => mw.catalog.updateMedia(event, UpdateMediaParams.Bookmarks));
  }

  public componentWillUnmount() {
    mw.catalog.off(CatalogEvent.ChannelsListRefreshed, this.loadChannels);
    mw.catalog.off(CatalogEvent.EPGRefreshed, this.loadChannels);

    this.updateEventBookmark.dispose();
  }

  private async scrollToMedia() {
    const mediaId = this.props.navigation.getParam('mediaId');
    const mediaType = this.props.navigation.getParam('mediaType');
    if (!mediaId || mediaType !== MediaType.Event) {
      return;
    }
    const event = await mw.catalog.getEventById(mediaId);
    this.epgRef.current?.scrollToEvent(event);
    this.props.navigation.setParams({
      mediaId: undefined,
      mediaType: undefined
    });
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.t !== prevProps.t) {
      this.mobileHeader = this.createMobileHeader();
    }
  }

  public loadChannels = async () => {
    Log.debug(TAG, 'Loading channels data');
    const data = await mw.catalog.getChannels();
    this.setState({channels: data});
  }

  private updateEventBookmark = disposable((event: Event) => mw.catalog.updateMedia(event, UpdateMediaParams.Bookmarks));

  public refreshMenuActions = (event: Event, recordActionType: RecordActionType) => {
    // define all available actions
    const watchLiveAction = {
      onPress: async () => {
        this.props.startPlayback({tvScreenParams: {channelId: event.channelId}});
        this.closeMenuPopup();
      }
    };
    const watchRecordingAction = {
      onPress: async () => {
        if (this.props.isMediaBlocked(event)) {
          try {
            await this.props.unlockMedia(event);
          } catch {
            return;
          }
        }
        this.closeMenuPopup();
        const recording = findPlayableRecording(await mw.pvr.getRecordings({media: event, type: RecordingType.Single}).catch(() => []));
        if (!recording) {
          Log.error(TAG, `Failed to find playable recording for event: ${event}`);
          return;
        }
        this.props.startPlayback({media: recording});
      }
    };
    const moreInfoAction = {
      onPress: () => {
        this.props.navigation.push(AppRoutes.MediaDetail, {
          mediaId: event.id,
          mediaType: event.getType()
        });
        this.closeMenuPopup();
      }
    };
    const recordAction = {
      onPress: () => {
        this.scheduleRecording(event);
      }
    };
    const goToNowAction = {
      onPress: () => {
        this.closeMenuPopup();
        // closing the popup will move the focus to the grid again, therefore we have to postpone scrolling to now after all the iteractions are completed.
        InteractionManager.runAfterInteractions(() => {
          this.epgRef.current?.scrollToNow();
        });
      }
    };
    const restartAction = {
      text: this.props.t(event.isPast ? 'epg.goBack' : 'epg.restart', {postProcess: PostProcessors.ToUpperCase}),
      onPress: async () => {
        if (this.props.isMediaBlocked(event)) {
          try {
            await this.props.unlockMedia(event);
          } catch {
            return;
          }
        }
        this.closeMenuPopup();

        try {
          await this.updateEventBookmark(event);
        } catch (error) {
          Log.error(TAG, 'Error updating event bookmark', error);
        }

        this.props.startPlayback({media: event});
      }
    };
    const addToWatchListAction = {
      onPress: () => {
        addToWatchList(event);
        this.closeMenuPopup();
      }
    };
    const removeFromWatchListAction = {
      onPress: () => {
        removeFromWatchList([event]);
        this.closeMenuPopup();
      }
    };
    // use only allowed actions in proper order
    const actions: EpgMenuAction[] = [];
    if (event.isNow) {
      actions.push({...this.epgActions.watchLive, ...watchLiveAction});
    }
    if (event.isPast && event.isRecorded) {
      actions.push({...this.epgActions.watchRecording, ...watchRecordingAction});
    }
    actions.push({...this.epgActions.moreInfo, ...moreInfoAction});
    if (!event.isNow && !isTablet) {
      actions.push({...this.epgActions.goToNow, ...goToNowAction});
    }
    switch (recordActionType) {
      case RecordActionType.record:
        actions.push({...this.epgActions.record, ...recordAction});
        break;
      case RecordActionType.recordSeries:
        actions.push({...this.epgActions.recordSeries, ...recordAction});
        break;
      case RecordActionType.recordAll:
        actions.push({...this.epgActions.recordAll, ...recordAction});
        break;
    }

    if (event.hasTstv) {
      actions.push({...this.epgActions.restart, ...restartAction});
    }
    if (event.isAllowedOnWatchList()) {
      if (event.isOnWatchList) {
        actions.push({...this.epgActions.removeFromWatchList, ...removeFromWatchListAction});
      } else {
        actions.push({...this.epgActions.addToWatchList, ...addToWatchListAction});
      }
    }
    this.setState({menuActions: actions});
  }

  public onPipPlayerViewReady = (playerLocation: EpgScreenPlayerViewLocation) => {
    onPipPlayerViewReady(playerLocation, this.state.focusState, TAG);
  }

  public refreshMoreActions = (event: Event, recordActionType: RecordActionType) => {
    const moreInfoAction = {
      icon: IconType.MoreInfo,
      onPress: () => {
        if (event) {
          this.props.navigation.push(AppRoutes.MediaDetail, {
            mediaId: event.id,
            mediaType: event.getType()
          });
        }
        this.closeMenuPopup();
      }
    };
    const recordAction = {
      icon: IconType.RecordDot,
      onPress: () => {
        this.scheduleRecording(event);
      }
    };
    const addToWatchListAction = {
      icon: IconType.Add,
      onPress: () => {
        addToWatchList(event);
        this.closeMenuPopup();
      }
    };
    const removeFromWatchListAction = {
      icon: IconType.Remove,
      onPress: () => {
        removeFromWatchList([event]);
        this.closeMenuPopup();
      }
    };
    // use only allowed actions in proper order
    const actions: MoreActionsAction[] = [{...this.epgActions.moreInfo, ...moreInfoAction}];
    switch (recordActionType) {
      case RecordActionType.record:
        actions.push({...this.epgActions.record, ...recordAction});
        break;
      case RecordActionType.recordSeries:
        actions.push({...this.epgActions.recordSeries, ...recordAction});
        break;
      case RecordActionType.recordAll:
        actions.push({...this.epgActions.recordAll, ...recordAction});
        break;
    }

    if (event.isAllowedOnWatchList()) {
      if (event.isOnWatchList) {
        actions.push({...this.epgActions.removeFromWatchList, ...removeFromWatchListAction});
      } else {
        actions.push({...this.epgActions.addToWatchList, ...addToWatchListAction});
      }
    }
    this.setState({moreActions: actions});
  }

  private scheduleRecording = async (event: Event) => {
    // TODO CL-2632 Delaying showing popup to avoid focus issues
    this.closeMenuPopup(() => {
      setTimeout(() => {
        this.props.scheduleRecording(event);
      }, nextPopupDelay);
    });
  };

  private onRecordButtonPressed = async (event: Event) => {
    Log.info(TAG, 'Record button pressed for event', event);
    const action = await this.props.getRecordActionTypeForEvent(event);
    if (action !== RecordActionType.none) {
      await this.scheduleRecording(event);
    } else {
      Log.info(TAG, 'Record action unavailable for event', event);
    }
  };

  private onInfoButtonPressed = (event: Event) => {
    this.props.navigation.push(AppRoutes.MediaDetail, {
      mediaId: event.id,
      mediaType: event.getType()
    });
  }

  public openMenuPopup = async (event?: Event, refreshActions = false) => {
    this.setState({menuPopupVisible: true});
    if (refreshActions && event) {
      this.setState({menuPopupLoading: true});
      const recordActionType = await this.props.getRecordActionTypeForEvent(event);
      this.refreshMenuActions(event, recordActionType);
      this.refreshMoreActions(event, recordActionType);
      this.setState({menuPopupLoading: false});
    }
  }

  public closeMenuPopup = (onMenuClosed?: () => void) => {
    this.setState({menuPopupVisible: false, ...isMobile && {focusedEvent: undefined}}, onMenuClosed);
    this.props.resetUnlockModal();
  }

  public onScreenFocused = () => {
    this.setState({focusState: NavigationFocusState.IsFocused});
    this.scrollToMedia();
  }

  public onScreenBlurred = () => {
    this.setState({focusState: NavigationFocusState.IsBlurred});
  }

  public onEventFocus = debounce((event?: Event) => {
    this.setState({focusedEvent: event});
  }, 300)

  public onEventPress = (event: Event) => {
    this.setState({focusedEvent: event});
  }

  public onChannelPress = (channelId: string) => {
    this.props.startPlayback({tvScreenParams: {channelId}});
  }

  public onEpgGridVisibleTime = (date: Date) => {
    this.setState({epgGridVisibleTime: date});
  }

  public onEpgScreenMobilePlayerView = (open: boolean) => {
    this.setState({epgScreenMobilePlayerViewOpen: open});
  }

  private onErrorPopupClose = () => {
    this.setState({errorPopup: null});
  };

  private onModalVisibilityChange = (anyModalVisible: boolean) => {
    this.setState({anyModalVisible});
  }

  public render() {
    const styles = stylesUpdater.getStyles();
    const props: EpgScreenProps = Object.assign({epgRef: this.epgRef}, this.props, {...this.state}, {
      onEventFocus: this.onEventFocus,
      onEventPress: this.onEventPress,
      onChannelPress: this.onChannelPress,
      onEpgGridVisibleTime: this.onEpgGridVisibleTime,
      openMenuPopup: this.openMenuPopup,
      closeMenuPopup: this.closeMenuPopup,
      onRecordButtonPressed: this.onRecordButtonPressed,
      onInfoButtonPressed: this.onInfoButtonPressed,
      onPipPlayerViewReady: this.onPipPlayerViewReady,
      onEpgScreenMobilePlayerView: this.onEpgScreenMobilePlayerView
    });

    return (
      <NitroxScreen
        style={styles.container}
        mobileHeaderProps={this.mobileHeader}
        navigation={this.props.navigation}
        menuState={STBMenuState.Hidden}
        shouldUnblockIdleActionsOnAppear={false}
        stopPlaybackOnAppear={isTablet}
        closeFloaterOnAppear={isTablet}
        allowedOrientations={this.state.epgScreenMobilePlayerViewOpen ? 'all' : 'portrait'}
        initialOrientation={isPhone ? 'portrait' : undefined}
        onScreenFocused={this.onScreenFocused}
        onScreenBlurred={this.onScreenBlurred}
        testID='screen_epg'
        onModalVisibilityChange={this.onModalVisibilityChange}
      >
        {isBigScreen ? <EpgScreenGrosso {...props} /> : <EpgScreenPiccolo {...props} />}
        {this.props.renderUnlockModal(isBigScreen ? this.closeMenuPopup : undefined)}
        <ErrorPopup error={this.state.errorPopup} onClose={this.onErrorPopupClose} />
        {this.props.renderPlayerLauncherComponent()}
        {this.props.renderRecordingsComponents()}
      </NitroxScreen>
    );
  }
}

export default withParentalControl(withLocalized(withUnlockModal(withTranslation()(withRecord(withPlayerLauncher(EpgScreen))))));
