import {DateUtils} from 'common/DateUtils';
import {EventEmitter} from 'common/EventEmitter';
import {Log} from 'common/Log';

import {DeviceManager} from 'mw/api/DeviceManager';
import {ErrorType, Error} from 'mw/api/Error';
import {Product, ProductType, ContentType, PlayableType, Content, Media, EntitlementState, PlaybackStopReason} from 'mw/api/Metadata';
import {nxffConfig} from 'mw/api/NXFF';
import {PlayerEvent, StopParams} from 'mw/api/PlayerEvent';
import {ADR8Requester} from 'mw/bo-proxy/bo/adr8/ADR8Requester';
import {shortId} from 'mw/common/utils';
import {deviceInfo} from 'mw/platform/device-info/DeviceInfo';
import {Storage} from 'mw/utils/Storage';

import {EventAdditionalParams, ReporterInterface, ReporterEvent, ReportingEvent} from './ReporterInterface';
import {ViewershipError} from './ViewershipError';
import {TransactionType, ViewershipEvent, EventType, ViewershipEventStatus, DistributionType} from './ViewershipEvent';

const TAG = 'ViewershipReporter';

const viewershipEventsAsyncStorageKey = `ViewershipReporter-viewershipEvents`;

const analyticsEndpoint = 'analytics';

const viewershipReportProtocolVersion = '1.0.0';

export interface ViewershipReportPackage {
  packageHeader: {
    packageId: string;
    packageTimestamp: number;
    timezone: string;
    deviceType: string;
    deviceId: string;
    protocolVersion: string;
  };
  viewershipEvent: ViewershipEvent[];
}

type ViewershipErrorType = keyof typeof ViewershipError;
type PlaybackParams = {position?: number, duration?: number};
type EventParams = Error | StopParams | PlaybackParams | undefined;

export class ViewershipReporter extends EventEmitter<ReportingEvent> implements ReporterInterface {
  private storage = new Storage<ViewershipEvent[]>(viewershipEventsAsyncStorageKey);
  private timerId = 0;
  private uploadTimerId = 0;
  private viewershipEvent: ViewershipEvent | null = null;
  private shouldUpdateEvent = false;
  private adr8Requester: ADR8Requester;

  public constructor() {
    super();
    const tenant = nxffConfig.getConfig().Environment.Tenant;
    const url = nxffConfig.getConfig().Reporting.ReportingURL;
    this.adr8Requester = new ADR8Requester({
      url,
      tenant,
      urlOnly: !!url,
      useSSOToken: true,
      maxRetries: nxffConfig.getConfig().Reporting.ReportingRetryNumber,
      retryPeriod: nxffConfig.getConfig().Reporting.ReportingRetryPeriod * DateUtils.msInMin,
      onServiceUnauthorized: () => {
        this.notify(ReportingEvent.Unauthorized);
      }
    });
  }

  public async handleLoginEvent(): Promise<void> {
    Log.debug(TAG, 'logging in');
    await this.closeAllOpenEvents(ViewershipError.UnexpectedApplicationTermination);
    this.prepareAndSendPackage();

    if (!this.uploadTimerId) {
      this.uploadTimerId = setInterval(async () => {
        this.prepareAndSendPackage();
      }, nxffConfig.getConfig().Reporting.ReportingPeriod * DateUtils.msInMin);
    }
  }

  public handleLogoutEvent(): void {
    Log.debug(TAG, 'logging out');

    this.closeEvent(EventType.StopPlayback);
    if (this.uploadTimerId) {
      clearInterval(this.uploadTimerId);
      this.uploadTimerId = 0;
    }
  }

  public isError(eventParams: EventParams): eventParams is Error {
    return eventParams instanceof Error;
  }

  public handleEvent(event: ReporterEvent, eventParams: EventParams, additionalParams: EventAdditionalParams): void {
    if (additionalParams.contentType !== ContentType.VOD) {
      return;
    }

    const content = additionalParams?.playable?.getPlayableType() === PlayableType.Content ? additionalParams?.playable as Content : null;
    const product = content?.products.find(product => product.entitlementState === EntitlementState.Entitled);

    switch (event) {
      case PlayerEvent.Initialize:
        Log.debug(TAG, 'Create new viewership event');
        this.createEvent((eventParams as PlaybackParams).position || 0, additionalParams.media, product);
        break;
      case PlayerEvent.PositionChanged:
        if (this.shouldUpdateEvent) {
          Log.debug(TAG, 'Update viewership event');
          this.shouldUpdateEvent = false;
          this.updateEvent(eventParams as PlaybackParams);
        }
        break;
      case PlayerEvent.Stopped:
        Log.debug(TAG, 'Close viewership event');
        this.closeEvent(EventType.StopPlayback);
        break;
      case PlayerEvent.EndOfContent:
        Log.debug(TAG, 'Close viewership event');
        this.closeEvent(EventType.EndOfContent, {duration: content?.duration});
        break;
      case PlayerEvent.Error:
        Log.debug(TAG, 'Close viewership event after error');
        this.closeAtError(eventParams);
        break;
      case PlayerEvent.StopRequested:
        Log.debug(TAG, 'Close viewership event at PlayerEvent.StopRequested', (eventParams as StopParams)?.reason);
        switch ((eventParams as StopParams)?.reason) {
          case PlaybackStopReason.UserAction:
            this.closeEvent(EventType.StopPlayback);
            break;
          default:
            this.closeAtError((eventParams as StopParams)?.error);
            break;
        }
        break;
      default:
        break;
    }
  }

  private getTransactionType(product?: Product): TransactionType {
    if (!product) {
      return TransactionType.FVOD;
    }
    switch (product.type) {
      case ProductType.Free:
        return TransactionType.FVOD;
      case ProductType.TransactionRent:
        return TransactionType.TVOD;
      case ProductType.TransactionBuy:
        return TransactionType.EST;
      case ProductType.Subscription:
        return TransactionType.SVOD;
      default:
        throw new Error(ErrorType.NotSupported, 'Viewership event is not created, product it is not related to VOD');
    }
  }

  private createEvent(position: number, media: Media, product?: Product): void {
    try {
      this.viewershipEvent = new ViewershipEvent({
        eventId: shortId(),
        eventTimestamp: Date.now(),
        transactionType: this.getTransactionType(product),
        // TODO: CL-4225 - remove this hardcoded value after implementing Download to Go PLM
        distributionType: DistributionType.Streaming,
        assetId: media.id,
        assetName: media.name,
        contentProviderId: media.contentProvider?.id,
        contentProviderName: media.contentProvider?.displayName,
        duration: 0,
        startIndex: position,
        status: ViewershipEventStatus.Open
      });
    } catch (error) {
      Log.error(TAG, error);
      return;
    }
    const eventsUpdateInterval = nxffConfig.getConfig().Reporting.EventsUpdateInterval * DateUtils.msInSec;
    this.timerId = setInterval(() => {
      this.shouldUpdateEvent = true;
    }, eventsUpdateInterval);
    this.saveEvent(this.viewershipEvent);
  }

  private async closeEvent(eventType: EventType, eventParams?: EventParams): Promise<void> {
    clearInterval(this.timerId);
    if (this.viewershipEvent?.status === ViewershipEventStatus.Open) {
      this.viewershipEvent.closeEvent();
      this.viewershipEvent.setEventType(eventType);
      await this.updateEvent(eventParams as PlaybackParams);
      this.viewershipEvent = null;
    }
  }

  private async saveEvent(viewershipEvent: ViewershipEvent): Promise<void> {
    const events = await this.getEvents();
    if (events.length + 1 > nxffConfig.getConfig().Reporting.EventsLimit) {
      events.splice(0, events.length - nxffConfig.getConfig().Reporting.EventsLimit + 1);
    }
    events.push(viewershipEvent);
    this.storage.save(events);
  }

  private async updateEvent(eventParams: PlaybackParams): Promise<void> {
    if (this.viewershipEvent) {
      this.viewershipEvent.duration = Math.round((Date.now() - this.viewershipEvent.eventTimestamp) / 1000);
      this.viewershipEvent.stopIndex = eventParams?.position || eventParams?.duration || null;
    }
    const events = await this.getEvents();
    const updatedEvents = events.map(ev => ev.eventId === this.viewershipEvent?.eventId
      ? this.viewershipEvent
      : ev
    );
    await this.storage.save(updatedEvents);
  }

  private async getEvents(): Promise<ViewershipEvent[]> {
    return await this.storage.get() || [];
  }

  private async closeAllOpenEvents(viewershipError: ViewershipError): Promise<void> {
    const events = await this.getEvents();
    events.forEach(event => {
      if (event.status === ViewershipEventStatus.Open) {
        event.status = ViewershipEventStatus.Closed;
        event.eventType = EventType.ErrorDuringPlayback;
        event.stopIndex = event.stopIndex || event.startIndex;
        event.errorCode = viewershipError;
      }
    });
    await this.storage.save(events);
  }

  public async prepareAndSendPackage(): Promise<void> {
    try {
      const reportPackage = await this.createPackage();
      if (reportPackage) {
        this.sendPackage(reportPackage);
      }
    } catch (err) {
      Log.error(TAG, 'Error while uploading viewership report', err);
    }
  }

  public async removeEvents(viewershipEvents: ViewershipEvent[]): Promise<void> {
    const events = await this.getEvents();
    const updatedEvents = events.filter((event) => !viewershipEvents.some((viewershipEvent) => viewershipEvent.eventId === event.eventId));
    this.storage.save(updatedEvents);
  }

  private async createPackage(): Promise<ViewershipReportPackage | undefined> {
    const events = await this.getEvents();
    const viewershipEvent = events.filter(event => event.status === ViewershipEventStatus.Closed);
    if (!viewershipEvent.length) {
      Log.debug(TAG, 'Couldn\'t find closed viewership events');
      return;
    }
    const deviceId = DeviceManager.getInstance().getId();

    const packageHeader = {
      packageId: shortId(),
      packageTimestamp: Date.now(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      deviceType: deviceInfo.deviceType,
      deviceId,
      protocolVersion: viewershipReportProtocolVersion
    };

    return {packageHeader, viewershipEvent};
  }

  private async sendPackage(reportPackage: ViewershipReportPackage): Promise<void> {
    return this.sendRequest(reportPackage)
      .then(() => this.removeEvents(reportPackage.viewershipEvent));
  }

  private async sendRequest(reportPackage: ViewershipReportPackage): Promise<void> {
    return this.adr8Requester.sendPostRequest({
      api: analyticsEndpoint,
      body: JSON.stringify(reportPackage)
    });
  }

  private closeAtError(eventParams: EventParams): Promise<void> {
    const errorType = (this.isError(eventParams) && ErrorType[eventParams.type]) ?? ViewershipError.UnexpectedError;
    const viewershipError = ViewershipError[errorType as ViewershipErrorType] || ViewershipError.UnexpectedError;
    if (this.viewershipEvent) {
      this.viewershipEvent.errorCode = viewershipError;
    }
    return this.closeEvent(EventType.ErrorDuringPlayback);
  }
}
