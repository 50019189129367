import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {dimensions} from 'common/constants';
import {DateUtils} from 'common/DateUtils';

import {StylesUpdater} from 'common-styles/StylesUpdater';

import {useLazyEffect, useCountdown} from 'hooks/Hooks';

import {inactivityPopupPortalName} from './ModalPortal';
import NitroxText from './NitroxText';
import Popup, {PopupAction} from './Popup';

const dynamicStyles = new StylesUpdater(colors => createStyles({
  textStyle: {
    color: colors.popup.text,
    textAlign: 'center'
  }
}));

const staticStyles = createStyles({
  popupContainer: {
    borderBottomLeftRadius: dimensions.popup.radius,
    borderBottomRightRadius: dimensions.popup.radius
  },
  popupContent: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: dimensions.margins.xxxLarge
  },
  titleContainer: {
    marginTop: dimensions.margins.xxxLarge
  },
  messageContainer: {
    maxWidth: 700,
    marginBottom: dimensions.margins.xxxLarge,
    flexDirection: 'row',
    justifyContent: 'center'
  },
  timeContainer: {
    flexDirection: 'row',
    justifyContent: 'center'
  },
  timeSeparator: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginHorizontal: dimensions.margins.xLarge,
    paddingTop: dimensions.margins.xLarge,
    opacity: 0.4
  },
  timePartContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    marginHorizontal: dimensions.margins.small
  }
});

const emptyActionsArray: PopupAction[] = [];

const separatorText = ':';

function padWithZeroes(time: number): string {
  return time.toString().padStart(2, '0');
}

type InactivityPopupProps = {
  visible: boolean;
  /**
   * in seconds
   */
  timeout: number;
  onTimeout: () => void;
};

/**
 * Component that displays a popup with a countdown configured with timeout prop.
 * Countdown starts when visible prop flips to true and stops when it changes to false.
 */
const InactivityPopup: React.FC<InactivityPopupProps> = React.memo(({
  visible,
  timeout,
  onTimeout
}) => {
  const {t} = useTranslation();
  const {textStyle} = dynamicStyles.getStyles();

  const {count: secondsLeft, startCounting, stopCounting} = useCountdown({initialValue: timeout, onFinish: onTimeout});

  useLazyEffect(() => {
    visible
      ? startCounting()
      : stopCounting();
  }, [visible], [startCounting, stopCounting]);

  const {minutes, seconds} = useMemo(() => ({
    minutes: Math.floor(secondsLeft / DateUtils.sInMin),
    seconds: (secondsLeft % DateUtils.sInMin)
  }), [secondsLeft]);

  return (
    <Popup
      visible={visible}
      actions={emptyActionsArray}
      title={t('inactivityPopup.title')}
      titleStyle={staticStyles.titleContainer}
      containerStyle={staticStyles.popupContainer}
      portal={inactivityPopupPortalName}
      focusNearestParentOnClose={false}
    >
      <View style={staticStyles.popupContent}>
        <View style={staticStyles.messageContainer}>
          <NitroxText textType='body' style={textStyle}>{t('inactivityPopup.message')}</NitroxText>
        </View>
        <View style={staticStyles.timeContainer}>
          <View style={staticStyles.timePartContainer}>
            <NitroxText textType='inactivity-popup-time-label' style={textStyle}>{padWithZeroes(minutes)}</NitroxText>
            <NitroxText textType='subhead' style={textStyle}>{t('time.minute', {count: minutes})}</NitroxText>
          </View>
          <View style={staticStyles.timeSeparator}>
            <NitroxText textType='inactivity-popup-time-separator' style={textStyle}>{separatorText}</NitroxText>
          </View>
          <View style={staticStyles.timePartContainer}>
            <NitroxText textType='inactivity-popup-time-label' style={textStyle}>{padWithZeroes(seconds)}</NitroxText>
            <NitroxText textType='subhead' style={textStyle}>{t('time.second', {count: seconds})}</NitroxText>
          </View>
        </View>
      </View>
    </Popup>
  );
});
InactivityPopup.displayName = 'InactivityPopup';

export default InactivityPopup;
