/* eslint-disable schange-rules/no-literal-color */
import {Component, RefObject} from 'react';

import {isWeb, testAutomationFeatures, debugFeatures} from 'common/constants';
import {Log} from 'common/Log';

import {findDomElement} from 'components/focusManager/geometry';

const tag = 'TAFocusReporter';

export class TAFocusReporter {
  private static instance?: TAFocusReporter;

  private static flashAnimation?: Animation;
  public static flashFocusedElement(): void {
    if (!isWeb || !debugFeatures.taFocusReporter) {
      return;
    }
    const globalContext = globalThis ?? global ?? window;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (globalContext?.taActiveElement) {
      this.flashAnimation?.cancel();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.flashAnimation = (globalContext.taActiveElement as Element).animate([
        {backgroundColor: 'darkblue', border: '1px solid blue'},
        {backgroundColor: 'black', border: '1px solid cyan'},
        {backgroundColor: 'darkblue', border: '1px solid blue'}
      ], {
        duration: 1500,
        iterations: Infinity
      });
    }
  }

  public static register(): void {
    if (!testAutomationFeatures.enableFocusReporter) {
      return;
    }
    if (!TAFocusReporter.instance) {
      TAFocusReporter.instance = new TAFocusReporter();
      if (isWeb && document.activeElement) {
        this.reportFocus(document.activeElement);
      }
    }
  }

  // Change Element (DOM type) to more generic type when more platforms will be supported
  public static reportFocus(element: Element): void {
    if (!testAutomationFeatures.enableFocusReporter) {
      return;
    }
    const globalContext = globalThis ?? global ?? window;
    if (globalContext) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      globalContext.taActiveElement = element;
      TAFocusReporter.flashFocusedElement();
    }
  }

  public static reportFocusByRef(ref: RefObject<Component>): void {
    // non-web platforms are unsupported atm
    // when needed figure way to provide similar api to http://appium.io/docs/en/commands/element/other/active/ for ATV
    if (!isWeb || !testAutomationFeatures.enableFocusReporter) {
      return;
    }
    if (ref.current) {
      try {
        const nodeElement = findDomElement(ref.current);
        if (nodeElement) {
          this.reportFocus(nodeElement);
        }
      } catch (error) {
        Log.debug(tag, error);
      }
    }
  }
}
