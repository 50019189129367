import React from 'react';

import {FocusableComponent} from 'components/focusManager/FocusManagerTypes';

import {TVScreenFocusableElements} from './TvScreenHelperTypes';

export const tvFocusMap: TVScreenFocusableElements = {
  channelList: React.createRef<FocusableComponent>(),
  eventDetails: React.createRef<FocusableComponent>(),
  eventDetailsActions: React.createRef<FocusableComponent>(),
  playerControls: React.createRef<FocusableComponent>()
};

export const TVScreenFocusContext = React.createContext(tvFocusMap);
