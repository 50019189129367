import {createStyles} from 'common-styles';
import React, {useMemo, useCallback, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {View, ScrollView} from 'react-native';

import {dimensions, featureFlags, isDesktopBrowser, isPhone} from 'common/constants';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {RecordingStatus} from 'mw/api/Metadata';

import FilterSelect, {createRecordingsFilterOptions} from 'components/FilterSelect';
import FocusParent, {useFocusParent} from 'components/FocusParent';
import {HotKeysContextProvider} from 'components/HotKeys';
import {IconType} from 'components/Icon';
import {SupportedKeys} from 'components/KeyEventManager';
import {ListItemProps} from 'components/ListView';
import {mediaTileMarginHorizontal} from 'components/mediaTiles/MediaTile';
import {SideMenuState, STBMenuState} from 'components/navigation/NavigationHelperTypes';
import {NitroxInteractiveController} from 'components/NitroxInteractiveControllerContext';
import NitroxText from 'components/NitroxText';
import RecordingsSortOrderSelect from 'components/pvr/RecordingsSortOrderSelect';
import QuotaBar from 'components/QuotaBar';
import {SideMenu, sideMenuHorizontalSpace} from 'components/SideMenu';
import {useNavigation, useLazyEffect, useScrollViewPaging, useChangeEffect, useFunction} from 'hooks/Hooks';
import NitroxScreen from 'screens/NitroxScreen';
import {RecordingSubscreenProps} from 'screens/RecordingsScreenHelperTypes';

import {useRecordingsGrid} from './Hooks';

const TAG = 'RecordingsScreen';
const hotKeys = [SupportedKeys.Info];

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  screen: {
    paddingLeft: dimensions.mainMenu.marginHorizontal - sideMenuHorizontalSpace,
    flex: 1,
    flexDirection: 'row'
  },
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: dimensions.screen.container.paddingTop,
    paddingLeft: dimensions.margins.xxLarge,
    paddingRight: dimensions.screen.container.paddingHorizontal
  },
  focusParentGrid: {
    flexDirection: 'row',
    flex: 1,
    width: '100%',
    marginLeft: -mediaTileMarginHorizontal // compensate tiles margins
  },
  header: {
    color: colors.recordingsScreen.title
  },
  sectionHeader: {
    marginBottom: dimensions.margins.large
  },
  quotaBar: {
    width: '100%',
    marginTop: dimensions.margins.small,
    paddingHorizontal: mediaTileMarginHorizontal / 2
  },
  buttonContainer: {
    marginTop: dimensions.margins.xxLarge,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  selectionMenu: {
    paddingRight: mediaTileMarginHorizontal / 2
  },
  filtersContainer: {
    flexDirection: 'row'
  },
  sortFiltersResetDefaultStyle: {
    marginTop: 0,
    marginBottom: 0
  },
  columnWrapperStyle: {
    paddingBottom: dimensions.margins.xxLarge
  },
  grid: {
    marginTop: dimensions.screen.recordings.grid.marginTop,
    width: '100%',
    flex: 1
  },
  gridContentStyle: {
    flexWrap: 'wrap',
    flexDirection: 'row'
  }
}));

type RecordingsScreenGrossoProps = {
  selectionMenu: JSX.Element;
} & RecordingSubscreenProps;

export const RecordingsScreenGrosso: React.FunctionComponent<RecordingsScreenGrossoProps> = props => {
  const {quota, recordings, onTilePress, requestRecordings, recordingsStatus, changeRecordingsStatus, recordingsSorting, selectable, selection, selectRecording, closeSelectionMenu, refreshPVRQuota} = props;
  const {t} = useTranslation();
  const [sideMenuState, setSideMenuState] = useState<SideMenuState>(SideMenuState.Expanded);
  const [onSideMenuParentReady, focusSideMenu] = useFocusParent();
  const [onGridParentReady, focusGrid] = useFocusParent();
  const [onSelectionMenuParentReady, focusSelectionMenu] = useFocusParent();
  const isGridParentFocused = useRef(false);
  const styles = stylesUpdater.getStyles();

  useLazyEffect(() => {
    if (recordings.length === 0) {
      requestRecordings();
    }
  }, [recordings], [requestRecordings]);

  const onScreenFocused = useFunction(() => {
    refreshPVRQuota();
    focusSideMenu();
  });

  const onBackPressed = useCallback(() => {
    if (selectable) {
      closeSelectionMenu();
      focusGrid();
      return true;
    }

    if (isGridParentFocused.current) {
      focusSideMenu();
      return true;
    }
    return false;
  }, [focusSideMenu, selectable, closeSelectionMenu, focusGrid]);

  const onGridParentFocused = useCallback(() => {
    isGridParentFocused.current = true;
    !isDesktopBrowser && setSideMenuState(SideMenuState.Collapsed);
  }, []);

  const onGridParentFocusLost = useCallback(() => {
    isGridParentFocused.current = false;
    setSideMenuState(SideMenuState.Expanded);
  }, []);

  const title = useMemo(() => recordingsStatus === RecordingStatus.Recorded ? t('recordings.recorded') : t('recordings.planned'), [recordingsStatus, t]);

  const onEndReached = useCallback(() => {
    Log.debug(TAG, 'We have reached the end of the list - requesting next page');
    requestRecordings();
  }, [requestRecordings]);

  const menuItems: ListItemProps[] = useMemo(
    () => [
      {
        key: 'Recorded',
        leftIcon: IconType.RecordDot,
        label: t('recordings.recorded'),
        rightIcon: IconType.ArrowRight,
        onPress: () => changeRecordingsStatus(RecordingStatus.Recorded),
        selected: recordingsStatus === RecordingStatus.Recorded,
        hasTVPreferredFocus: true
      },
      {
        key: 'Planned',
        leftIcon: IconType.ChannelsList,
        label: t('recordings.planned'),
        rightIcon: IconType.ArrowRight,
        onPress: () => changeRecordingsStatus(RecordingStatus.Scheduled),
        selected: recordingsStatus === RecordingStatus.Scheduled
      }
    ],
    [t, recordingsStatus, changeRecordingsStatus]
  );

  const onScroll = useScrollViewPaging(onEndReached);

  const {renderGridTile, tilesData, onGridLayout} = useRecordingsGrid({
    recordings,
    selection,
    selectable,
    selectRecording,
    onTilePress
  });

  const filterRecordingsOptions = useMemo(() => createRecordingsFilterOptions(), []);

  useChangeEffect(() => {
    if (selectable) {
      focusSelectionMenu();
    } else {
      focusGrid();
    }
  }, [selectable], [focusSelectionMenu]);

  const containerStyle = useMemo(() => {
    return {
      ...styles.container,
      paddingLeft: sideMenuState === SideMenuState.Expanded ? dimensions.margins.xxLarge : mediaTileMarginHorizontal,
      paddingRight: dimensions.screen.container.paddingHorizontal - (sideMenuState === SideMenuState.Expanded ? 0 : dimensions.margins.xxxLarge)
    };
  }, [styles.container, sideMenuState]);

  return (
    <NitroxScreen
      navigation={useNavigation()}
      style={styles.screen}
      onScreenFocused={onScreenFocused}
      menuState={STBMenuState.Above}
      onBackButtonPressed={onBackPressed}
      showOperatorLogo={!isPhone}
    >
      <NitroxInteractiveController omitGeometryCaching>
        <SideMenu
          menuState={sideMenuState}
          onFocusParentReady={onSideMenuParentReady}
          title={t('common.recordings')}
          items={menuItems}
          titleStyle={styles.sectionHeader}
        />
        <FocusParent
          enterStrategy='byPriority'
          onFocusEnter={onGridParentFocused}
          onFocusEscape={onGridParentFocusLost}
          style={containerStyle}
        >
          <NitroxText
            style={styles.header}
            textType={'headline'}
            upperCase
          >
            {title}
          </NitroxText>
          <QuotaBar
            style={styles.quotaBar}
            remaining={quota.remaining}
            available={quota.available}
          />
          <FocusParent style={styles.buttonContainer} rememberLastFocused>
            <View style={styles.filtersContainer}>
              {featureFlags.recordingsSortingSelection && (
                <RecordingsSortOrderSelect
                  style={styles.sortFiltersResetDefaultStyle}
                  currentSortOrder={recordingsSorting}
                  onSortOrderSelected={props.sortRecordings}
                />
              )}
              {featureFlags.recordingsFilterSelection && (
                <FilterSelect
                  filterOptions={filterRecordingsOptions}
                  onFilterSelected={props.filterRecordings}
                  style={styles.sortFiltersResetDefaultStyle}
                />
              )}
            </View>
            <FocusParent style={styles.selectionMenu} onReady={onSelectionMenuParentReady}>
              {props.selectionMenu}
            </FocusParent>
          </FocusParent>
          <FocusParent focusPriority={1} style={styles.focusParentGrid} rememberLastFocused onReady={onGridParentReady}>
            <HotKeysContextProvider hotKeys={hotKeys}>
              <ScrollView
                style={styles.grid}
                contentContainerStyle={styles.gridContentStyle}
                onScroll={onScroll}
                onLayout={onGridLayout}
                scrollEventThrottle={8}
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                alwaysBounceVertical={false}
              >
                {tilesData.map(renderGridTile)}
              </ScrollView>
            </HotKeysContextProvider>
          </FocusParent>
        </FocusParent>
      </NitroxInteractiveController>
    </NitroxScreen>
  );
};
