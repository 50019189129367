import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {isTablet, dimensions, getValue} from 'common/constants';

import {MediaType} from 'mw/api/Metadata';

import DoubleBackground from 'components/DoubleBackground';
import {NitroxTabSelector, NitroxTabSelectorItem} from 'components/NitroxTabSelector';
import {SupportedOrientations} from 'components/OrientationManager';
import {useNavigation} from 'hooks/Hooks';
import {useScreenInfo} from 'hooks/Hooks';
import NitroxScreen from 'screens/NitroxScreen';

import WatchListContent from './WatchListContent.piccolo';
import WatchListSortOrderSelect from './WatchListSortOrderSelect';
import {WatchListSubscreenProps} from './WatchListTypes';

const allowedOrientations: SupportedOrientations = isTablet ? 'all' : 'portrait';
const backgroundDecoratorHeight = dimensions.screen.header.height;

const styles = createStyles({
  contentContainer: {
    flex: 1,
    width: '100%',
    paddingHorizontal: getValue({tablet: dimensions.margins.xxLarge, defaultValue: dimensions.margins.large}),
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  tabSelector: {
    width: '90%'
  },
  bottomBackground: {
    flexGrow: 1
  },
  actions: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'stretch'
  }
});

const WatchListScreenPiccolo: React.FC<WatchListSubscreenProps> = props => {
  const {
    onMediaTypeSelect,
    watchList,
    selectable,
    loading,
    onSelectMedia,
    selection,
    requestNextPage,
    isTvTabAvailable,
    testID
  } = props;

  const {t} = useTranslation();
  const tabs: NitroxTabSelectorItem[] = useMemo(() => [
    {
      label: t('common.series'),
      onPress: () => onMediaTypeSelect(MediaType.Series)
    },
    {
      label: t('common.films'),
      onPress: () => onMediaTypeSelect(MediaType.Title)
    },
    ...(isTvTabAvailable ? [{
      label: t('common.tv'),
      onPress: () => onMediaTypeSelect(MediaType.Event)
    }] : [])
  ], [t, isTvTabAvailable, onMediaTypeSelect]);

  return (
    <NitroxScreen
      allowedOrientations={allowedOrientations}
      mobileHeaderProps={{
        title: (!useScreenInfo().orientation.isLandscape && selectable) ? '' : t('common.watchList'),
        rightContent: props.selectionMenu
      }}
      navigation={useNavigation()}
      testID={testID}
    >
      <DoubleBackground
        overlap={Math.floor(backgroundDecoratorHeight / 2)}
        bottomStyle={styles.bottomBackground}
        bottomChild={(
          <View style={styles.contentContainer}>
            <NitroxTabSelector
              containerStyle={styles.tabSelector}
              items={tabs}
            />
            <View style={styles.actions}>
              <WatchListSortOrderSelect
                currentSortOrder={props.currentSortOrder}
                onSortOrderSelected={props.onSortOrderSelected}
              />
            </View>
            <WatchListContent
              watchList={watchList}
              selectable={selectable}
              loading={loading}
              onSelectMedia={onSelectMedia}
              selection={selection}
              requestNextPage={requestNextPage}
            />
          </View>
        )}
      />
    </NitroxScreen>
  );
};

export default WatchListScreenPiccolo;
