import React, {useMemo, useReducer, useCallback, Reducer} from 'react';
import {ViewStyle, StyleProp} from 'react-native';

import DeleteConfirmationPopup from 'components/DeleteConfirmationPopup';
import {Selection, SelectionItemType, hasAll, hasNone} from 'components/utils/Selection';

import DeleteSelectionMenu, {DeleteSelectionMenuProps, DeleteSelectionMenuActionKey} from './DeleteSelectionMenu';

type DeleteSelectionMenuState<T extends SelectionItemType> = Pick<DeleteSelectionMenuProps, 'actions' | 'disabledActions' | 'selectedActions'> & {
  selectionMenuActive: boolean;
  selection: Selection<T>;
  confirmationPopupVisible: boolean;
};

enum DeleteSelectionMenuActionType {
  OpenMenuPressed,
  CancelPressed,
  SelectAllPressed,
  SelectItemPressed,
  DeletePressed,
  CancelDeletionPressed,
  ConfirmDeletionPressed
}

type NoPayloadActionType =
  | DeleteSelectionMenuActionType.OpenMenuPressed
  | DeleteSelectionMenuActionType.CancelPressed
  | DeleteSelectionMenuActionType.DeletePressed
  | DeleteSelectionMenuActionType.CancelDeletionPressed
  | DeleteSelectionMenuActionType.ConfirmDeletionPressed;

type DeleteSelectionMenuAction<T> = {
  type: NoPayloadActionType;
} | {
  type: DeleteSelectionMenuActionType.SelectAllPressed;
  items: T[];
  selected: boolean;
} | {
  type: DeleteSelectionMenuActionType.SelectItemPressed;
  item: T;
  items: T[];
  hasMoreItems: boolean;
  selected: boolean;
}

function initialState<T extends SelectionItemType>(): DeleteSelectionMenuState<T> {
  return {
    selectionMenuActive: false,
    actions: [DeleteSelectionMenuActionKey.OpenMenu],
    disabledActions: [],
    selectedActions: [],
    selection: new Selection<T>(),
    confirmationPopupVisible: false
  };
}

function deleteSelectionMenuStateReducer<T extends SelectionItemType>(state: DeleteSelectionMenuState<T>, action: DeleteSelectionMenuAction<T>): DeleteSelectionMenuState<T> {
  switch (action.type) {
    case DeleteSelectionMenuActionType.OpenMenuPressed:
      return {
        ...state,
        selectionMenuActive: true,
        actions: [DeleteSelectionMenuActionKey.Cancel, DeleteSelectionMenuActionKey.SelectAll, DeleteSelectionMenuActionKey.Delete],
        disabledActions: [DeleteSelectionMenuActionKey.Delete]
      };
    case DeleteSelectionMenuActionType.CancelPressed:
      return initialState();
    case DeleteSelectionMenuActionType.SelectAllPressed:
      if (!action.items.length) {
        return state;
      }
      return {
        ...state,
        selection: state.selection.clone().selectAll(!action.selected),
        disabledActions: action.selected ? [DeleteSelectionMenuActionKey.Delete] : [],
        selectedActions: !action.selected ? [DeleteSelectionMenuActionKey.SelectAll] : []
      };
    case DeleteSelectionMenuActionType.SelectItemPressed:
      const selection = state.selection.clone().select(action.item, action.selected);
      return {
        ...state,
        selection,
        disabledActions: hasNone(selection, action.items, action.hasMoreItems) ? [DeleteSelectionMenuActionKey.Delete] : [],
        selectedActions: hasAll(selection, action.items, action.hasMoreItems) ? [DeleteSelectionMenuActionKey.SelectAll] : []
      };
    case DeleteSelectionMenuActionType.DeletePressed:
      return {
        ...state,
        confirmationPopupVisible: true
      };
    case DeleteSelectionMenuActionType.CancelDeletionPressed:
      return {
        ...state,
        confirmationPopupVisible: false
      };
    case DeleteSelectionMenuActionType.ConfirmDeletionPressed:
      return initialState();
  }
}

type UseDeleteSelectionMenuParams<T extends SelectionItemType> = {
  items: T[];
  hasMoreItems: boolean;
  deleteSelected: (selection: Selection<T>) => Promise<void>;
  style?: StyleProp<ViewStyle>;
  confirmationPopupParams: {
    title: (selection: Selection<T>) => string;
    info: (selection: Selection<T>) => string;
  };
};

export function useDeleteSelectionMenu<T extends SelectionItemType>({
  items,
  hasMoreItems,
  deleteSelected,
  style,
  confirmationPopupParams
}: UseDeleteSelectionMenuParams<T>) {
  const [state, dispatchAction] = useReducer<Reducer<DeleteSelectionMenuState<T>, DeleteSelectionMenuAction<T>>>(
    deleteSelectionMenuStateReducer,
    initialState<T>()
  );

  const onOpenMenuPress = useCallback(() => {
    dispatchAction({type: DeleteSelectionMenuActionType.OpenMenuPressed});
  }, []);

  const onCancelPress = useCallback(() => {
    dispatchAction({type: DeleteSelectionMenuActionType.CancelPressed});
  }, []);

  const onSelectAllPress = useCallback((selected: boolean) => {
    dispatchAction({type: DeleteSelectionMenuActionType.SelectAllPressed, selected, items});
  }, [items]);

  const onDeletePress = useCallback(() => {
    dispatchAction({type: DeleteSelectionMenuActionType.DeletePressed});
  }, []);

  const onSelectItem = useCallback((item: T, selected: boolean) => {
    dispatchAction({type: DeleteSelectionMenuActionType.SelectItemPressed, item, selected, items, hasMoreItems});
  }, [items, hasMoreItems]);

  const selectionMenu = useMemo(() => {
    return (
      <DeleteSelectionMenu
        style={style}
        actions={state.actions}
        disabledActions={state.disabledActions}
        selectedActions={state.selectedActions}
        onOpenMenuPress={onOpenMenuPress}
        onCancelPress={onCancelPress}
        onSelectAllPress={onSelectAllPress}
        onDeletePress={onDeletePress}
      />
    );
  }, [onCancelPress, onDeletePress, onOpenMenuPress, onSelectAllPress, state.actions, state.disabledActions, state.selectedActions]);

  const onCancelDeletion = useCallback(() => {
    dispatchAction({type: DeleteSelectionMenuActionType.CancelDeletionPressed});
  }, []);

  const onConfirmDeletion = useCallback(() => {
    deleteSelected(state.selection);
    dispatchAction({type: DeleteSelectionMenuActionType.ConfirmDeletionPressed});
  }, [state.selection, deleteSelected]);

  const confirmationPopup = useMemo(() => {
    return (
      <DeleteConfirmationPopup
        visible={state.confirmationPopupVisible}
        selection={state.selection}
        title={confirmationPopupParams.title(state.selection)}
        info={confirmationPopupParams.info(state.selection)}
        onCancel={onCancelDeletion}
        onConfirmation={onConfirmDeletion}
      />
    );
  }, [state.confirmationPopupVisible, state.selection, confirmationPopupParams, onCancelDeletion, onConfirmDeletion]);

  return {
    selectionMenu,
    confirmationPopup,
    selectionMenuActive: state.selectionMenuActive,
    selection: state.selection,
    onSelectItem
  };
}
