import moment from 'moment';

import {DateUtils} from 'common/DateUtils';
import {Log} from 'common/Log';

import {EntitlementState, Product, ProductType} from 'mw/api/Metadata';
import {OfferObject} from 'mw/metadata/OfferrObject';
import {ProductObject} from 'mw/metadata/ProductObject';

const TAG = 'ProductMapper';

export class ProductMapper {
  public static PaymentMethod = 'paymentMethod';

  public static productFromJson(productJson: any): Product {
    const product = new ProductObject(productJson.id);
    product.name = productJson.title;
    product.description = productJson.description;
    const vodDeliverable = productJson.vodDeliverable;
    product.isSingle = vodDeliverable?.singleAsset;
    if (vodDeliverable?.est) {
      product.type = ProductType.TransactionBuy;
    } else if (vodDeliverable?.est === false) {
      product.type = ProductType.TransactionRent;
    }
    // TODO: update when removing moment: CL-670
    product.duration = vodDeliverable && !vodDeliverable.est ? moment.duration(vodDeliverable.duration ?? 0).asMilliseconds() : 0;
    product.filterId = vodDeliverable?.filterId;
    product.entitlementState = EntitlementState.Entitled;
    const now = new Date();
    product.relationEnd = DateUtils.addYears(now, 1);
    product.relationStart = DateUtils.addHours(now, -24);
    product.isAvailableToBuy = product.duration === 0;
    product.isAvailableToRent = product.duration !== 0;
    return product;
  }

  public static productsFromJson(productsJson: any): Product[] {
    if (!Array.isArray(productsJson)) {
      return [];
    }
    return productsJson
      .filter(item => item.vodDeliverable)
      .map(ProductMapper.productFromJson);
  }

  public static productsWithOffersFromJson(offersJson: any[], products: Product[]): Product[] {
    offersJson.forEach((offerJson) => {
      const productId = offerJson.productId;
      const product = products.find(product => product.id === productId);
      if (!product) {
        Log.warn(TAG, 'product not found for id: ', productId, products);
        return;
      }
      offerJson.dimensions.forEach((dim: {name: string; term: string}) => {
        if (dim.name === ProductMapper.PaymentMethod) {
          product.offers.push(new OfferObject(undefined, dim.term, offerJson.amount, offerJson.currency));
        }
      });
    });
    return products;
  }
}
