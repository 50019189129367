import {createStyles} from 'common-styles';
import React, {forwardRef, RefObject, useCallback, useEffect, useMemo, useState, useRef} from 'react';
import {NativeSyntheticEvent, TextInput, TextInputSubmitEditingEventData, View, TextInputProps, Keyboard, TextInputChangeEventData, ScrollView, TextStyle, StyleProp} from 'react-native';

import {dimensions, isAndroid, isBigScreen, isMobile, isTVOS, isWeb, DOT_SEPARATOR, isIOS, isATV} from 'common/constants';
import {EventEmitter} from 'common/EventEmitter';
import {doNothing} from 'common/HelperFunctions';
import {TestProps} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors, constColors} from 'common-styles/variables/base-colors';

import ConditionalWrapper from 'components/ConditionalWrapper';
import {FocusManager} from 'components/focusManager/FocusManager';
import {FocusableComponent} from 'components/focusManager/FocusManagerTypes';
import {Icon, IconType} from 'components/Icon';
import {NativeKeyEvent, SupportedKeys} from 'components/KeyEventManager';
import NitroxInteractive, {FocusPriorityProps} from 'components/NitroxInteractive';
import NitroxText from 'components/NitroxText';
import {textStyles} from 'components/NitroxText';
import {useTestID, useLazyEffect, useToggle, useProperty, useEventListener, useKeyEventHandler} from 'hooks/Hooks';

const TAG = 'FocusableTextInputImpl';

// TODO: CL-6815 divide FocusableTextInput implementation on per platform basis.

type PlatformSpecific = {
  shouldFocusInteractiveOnInputBlur: (blurEvent: NativeSyntheticEvent<TextInputChangeEventData>, component?: React.Component | null) => boolean;
};

export enum ChangeTextEvents {
  Change = 'ChangeText',
  Clear = 'ClearText',
  Blur = 'BlurInput'
}

export type FocusableTextInputImplProps = {
  type?: TextInputType;
  autoFocus?: boolean;
  secureTextEntry?: boolean;
  maxLength?: number;
  initialValue?: string;
  validationError?: boolean;
  textAlign?: TextStyle['textAlign'];
  inputProps?: TextInputProps;
  placeholder?: string;
  enterEditModeOnFocus?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onInput?: () => void;
  onLayout?: () => void;
  hasTVPreferredFocus?: boolean;
  onChangeText?: (text: string) => void;
  onSubmitEditing?: (e: NativeSyntheticEvent<TextInputSubmitEditingEventData>) => void;
  delaySubmitEditing?: boolean;
  /** return true to override default behavior - focusing wrapper on input blur */
  onInputBlurCapture?: () => boolean | undefined;
  changeTextEventEmitter?: EventEmitter<ChangeTextEvents>;
  /** on icon press action - supported only on mobiles */
  iconAction?: () => void;
  clearIcon?: boolean;
  editIndicatorIcon?: boolean;
  displayMobileBackButton?: boolean;
} & TestProps & FocusPriorityProps;

type TextInputType = 'search';

export const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    // FocusBorder according to desing appears outside of box-sizing, hence
    // container must guarantee space to no crop input when focused.
    height: dimensions.inputs.height + 2 * dimensions.inputs.focusBorder,
    padding: dimensions.inputs.focusBorder,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap'
  },
  touchableContainer: {
    padding: 0,
    margin: 0,
    flexGrow: 1
  },
  touchable: {
    height: dimensions.inputs.height,
    borderRadius: dimensions.inputs.height / 2 + dimensions.inputs.focusBorder,
    paddingHorizontal: dimensions.inputs.padding,
    boxSizing: 'content-box',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },
  focusedTouchable: {
    marginLeft: -dimensions.inputs.focusBorder,
    marginRight: -dimensions.inputs.focusBorder,
    marginTop: -dimensions.inputs.focusBorder,
    overflow: 'visible',
    borderWidth: dimensions.inputs.focusBorder,
    borderColor: colors.input.background.focused,
    backgroundColor: colors.input.background.focused
  },
  unfocusedTouchable: {
    backgroundColor: colors.input.background.unfocused
  },
  inputContainer: {
    width: 0,
    flexGrow: 1
  },
  input: {
    ...textStyles.input(),
    // On ATV there is some kind of default padding on text input which makes it look not properly centered.
    // Using experimental values for best result.
    ...isATV && {paddingBottom: 0, paddingTop: 2}
  },
  inputTVOS: {
    // TODO: CL-2454
    // 'color' style is bugged in react-native on tvos, causes text to disappear (renders transparent text instead)
    // to workaround input is being reduced to no sizes position absolute,
    // on TVOS focus still works correctly as its managed by wrapping NitroxInteractive
    // on TVOS data are displayed on standard NitroxText styled in same way as inputs.
    position: 'absolute',
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    padding: 0,
    margin: 0,
    backgroundColor: undefined,
    color: constColors.transparent
  },
  focusedInputTVOSPlaceholder: {
    color: colors.input.placeholder.focused
  },
  unfocusedInputTVOSPlaceholder: {
    color: colors.input.placeholder.unfocused
  },
  focusedInput: {
    color: colors.input.text.focused,
    backgroundColor: constColors.transparent
  },
  unfocusedInput: {
    color: colors.input.text.unfocused,
    backgroundColor: constColors.transparent
  },
  errorIndicator: {
    width: dimensions.inputs.icons.error.backgroundSize,
    height: dimensions.inputs.icons.error.backgroundSize,
    borderRadius: dimensions.inputs.icons.error.backgroundSize / 2,
    backgroundColor: colors.errorPopup.background,
    color: colors.errorPopup.text,
    padding: (dimensions.inputs.icons.error.backgroundSize - dimensions.inputs.icons.error.iconSize) / 2
  },
  focusedPlaceholder: {
    color: colors.input.placeholder.focused
  },
  unfocusedPlaceholder: {
    color: colors.input.placeholder.unfocused
  },
  iconInteractiveContainer: {
    height: '100%',
    width: dimensions.icon.large,
    justifyContent: 'center',
    alignItems: 'center',
    left: dimensions.margins.large
  }
}));

const FocusableTextInputImpl: React.FunctionComponent<FocusableTextInputImplProps & PlatformSpecific> = (props, ref) => {
  const {
    type,
    initialValue: propsInitialValue = '',
    onFocus: propagateFocus,
    onInput: propagateInputFocus,
    hasTVPreferredFocus,
    onChangeText: propsOnChangeText,
    validationError,
    textAlign,
    inputProps,
    placeholder,
    onInputBlurCapture,
    onSubmitEditing,
    delaySubmitEditing,
    secureTextEntry,
    shouldFocusInteractiveOnInputBlur,
    enterEditModeOnFocus = false,
    changeTextEventEmitter,
    iconAction,
    clearIcon: displayClearIcon,
    editIndicatorIcon: displayEditIndicatorIcon,
    displayMobileBackButton = false,
    onLayout,
    onBlur
  } = props;
  const defaultTouchableRef: RefObject<FocusableComponent> = useRef(null);
  const defaultInputRef: RefObject<TextInput> = useRef(null);

  const touchableRef: RefObject<FocusableComponent> = isMobile || !ref ? defaultTouchableRef : ref;
  const inputRef: RefObject<TextInput> = isBigScreen || !ref ? defaultInputRef : ref;
  const [touchableFocused, setTouchableFocused] = useState(false);
  const [hovered, {on: setHoveredOn, off: setHoveredOff}] = useToggle(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialValue = useMemo(() => propsInitialValue, []);
  const inputText = useRef(initialValue);
  const [inputValue, setInputValue] = useState(initialValue);
  // Property variable which tracks source from which focus was passed to touchableRef.
  // If focus was passed from bluring the input do not focus input,
  // otherwise focus input if @param enterEditModeOnFocus is set to true.
  const [focusFromInput, setFocusFromInput] = useProperty(false);
  const [delayedSubmitEvent, setDelayedSubmitEvent] = useState<NativeSyntheticEvent<TextInputSubmitEditingEventData>>();

  const onSubmit = useCallback((e: NativeSyntheticEvent<TextInputSubmitEditingEventData>) => {
    delaySubmitEditing ? setDelayedSubmitEvent(e) : onSubmitEditing?.(e);
  }, [delaySubmitEditing, onSubmitEditing]);

  // Value is passed when keyboard is hidden and focus is back on input.
  // This is a workaround for losing pop-up focus while data is being checked
  const submitDelayedValue = useCallback(() => {
    if (delaySubmitEditing && delayedSubmitEvent) {
      onSubmitEditing?.(delayedSubmitEvent);
      setDelayedSubmitEvent(undefined);
    }
  }, [delaySubmitEditing, delayedSubmitEvent, onSubmitEditing]);

  const handleFocus = useCallback(() => {
    if (!inputRef.current) {
      Log.warn(TAG, 'Input to be focused has a null ref.');
      return;
    }
    inputRef.current.focus();
    setTouchableFocused(true);
    if (!isBigScreen) {
      // if big screen this is handled in onTouchableFocus
      propagateFocus?.();
    }
  }, [inputRef, propagateFocus]);

  const onTouchableFocus = useCallback(() => {
    setTouchableFocused(true);
    if (isBigScreen) {
      propagateFocus?.();
      submitDelayedValue();
    }
    if (enterEditModeOnFocus && !focusFromInput()) {
      // TODO: CL-6816 investigate root cause to remove setTimeout workaround.
      setTimeout(handleFocus, 0);
    }
    setFocusFromInput(false);
  }, [enterEditModeOnFocus, focusFromInput, setFocusFromInput, propagateFocus, submitDelayedValue, handleFocus]);

  const onTouchableBlur = useCallback(() => {
    setTouchableFocused(false);
  }, [setTouchableFocused]);

  const onInputFocus = useCallback(() => {
    if (isWeb || isMobile) {
      setTouchableFocused(true);
      propagateInputFocus?.();
    }
  }, [propagateInputFocus]);

  const blurInput = useCallback(() => inputRef.current?.blur(), [inputRef]);
  useEventListener(ChangeTextEvents.Blur, blurInput, changeTextEventEmitter);

  const onInputBlur = useCallback((e: NativeSyntheticEvent<TextInputChangeEventData>) => {
    inputRef.current?.setNativeProps({text: inputText.current});
    onBlur?.();

    const handled = onInputBlurCapture?.();
    if (handled) {
      return;
    }

    const focusInteractive = shouldFocusInteractiveOnInputBlur(e, touchableRef.current);
    if (!focusInteractive) {
      onTouchableBlur();
    } else {
      setFocusFromInput(true);
      if (isWeb) {
        requestAnimationFrame(() => {
          FocusManager.getInstance().forceFocus(touchableRef);
        });
      } else {
        FocusManager.getInstance().forceFocus(touchableRef);
      }
    }
    // tvOS TextInput has a bug related to color and secureTextEntry
    // if secureTextEntry is true, and input is empty before focusing it, then text color in native input breaks,
    // showing empty input despite being filled with some value
    // therefore we fallback to NitroxText for inline secureText rendering until mentioned bug is fixed
    if (isTVOS) {
      setInputValue(inputText.current);
    }
  }, [inputRef, onBlur, onInputBlurCapture, shouldFocusInteractiveOnInputBlur, touchableRef, onTouchableBlur, setFocusFromInput]);

  const onChangeText = useCallback((text: string) => {
    inputText.current = text;
    propsOnChangeText?.(text);
    if (isTVOS) {
      setInputValue(text);
    }
  }, [propsOnChangeText]);

  const onChangeTextEventEmitted = useCallback((text: string) => {
    onChangeText(text);
    inputRef.current?.setNativeProps({text: inputText.current});
  }, [inputRef, onChangeText]);
  useEventListener(ChangeTextEvents.Change, onChangeTextEventEmitted, changeTextEventEmitter);

  useLazyEffect(() => {
    // TODO: CL-2454
    // There's a bug with initializing value in react-native on tvos
    if (!isTVOS) {
      inputRef.current?.setNativeProps({text: inputText.current});
    }
    onChangeText(inputText.current);
  }, [], [onChangeText]);

  const keyboardDidHide = useCallback(() => {
    if (isAndroid && inputRef.current?.isFocused()) {
      // Remove focus from input when keyboard is closed to be able to show it again when user taps inside input again
      // (that's behaviour of iOS mobiles by default)
      blurInput();
    }
  }, [blurInput, inputRef]);

  useEffect(() => {
    const subscription = Keyboard.addListener('keyboardDidHide', keyboardDidHide);
    return () => {
      subscription.remove();
    };
  }, [keyboardDidHide]);

  const clear = useCallback(() => {
    inputText.current = '';
    inputRef.current?.clear();
    if (isTVOS) {
      setInputValue('');
    }
  }, [inputRef]);

  useEventListener(ChangeTextEvents.Clear, clear, changeTextEventEmitter);

  const isFocused = inputRef.current?.isFocused() || touchableFocused || hovered;
  const shouldDisplayTVOSPlaceholder = isTVOS && !!placeholder && !inputValue;
  const styles = stylesUpdater.getStyles();
  const inputStyle = [styles.input, isFocused ? styles.focusedInput : styles.unfocusedInput];
  const textAlignStyle: StyleProp<TextStyle> = useMemo(() => ({textAlign: textAlign}), [textAlign]);

  const testID = useTestID(props, 'FocusableTextInput') || 'input';
  const iconInteractiveTestID = useTestID(props, 'FocusableTextInputIcon');

  const shouldDisplayClearIcon = displayClearIcon && !!inputText.current;
  // CAUTION: Each Icon has slightly different sizing and spacing, hence
  // their styles is kept close to their definition
  const clearIcon = useMemo(() => (
    <View
      style={[{
        marginRight: dimensions.inputs.icons.clearSearch.padding - dimensions.inputs.padding,
        width: dimensions.inputs.icons.clearSearch.size,
        height: dimensions.inputs.icons.clearSearch.size
      }]}
    >
      <Icon
        type={IconType.ClearSearch}
        size={dimensions.inputs.icons.clearSearch.size}
        color={styles.unfocusedInput.color}
      />
    </View>
  ), [styles.unfocusedInput.color]);

  const editIndicatorIcon = useMemo(() => {
    return (
      <View
        style={[{
          width: dimensions.inputs.icons.edit.size,
          height: dimensions.inputs.icons.edit.size
        }]}
      >
        <Icon
          type={IconType.Edit}
          size={dimensions.inputs.icons.edit.size}
          color={styles.unfocusedInput.color}
        />
      </View>
    );
  }, [styles.unfocusedInput.color]);

  const errorIndicatorIcon = useMemo(() => (
    <View style={[styles.errorIndicator, {marginRight: dimensions.inputs.icons.error.innerPadding - dimensions.inputs.padding}]}>
      <Icon
        type={IconType.Remove}
        size={dimensions.inputs.icons.error.iconSize}
        color={styles.errorIndicator.color}
      />
    </View>
  ), [styles.errorIndicator]);

  const mobileBackButton = useMemo(() => isMobile && isFocused && (
    <NitroxInteractive
      onPress={blurInput}
      style={[{
        paddingRight: dimensions.margins.large,
        display: 'flex',
        justifyContent: 'center'
      }]}
    >
      <Icon
        type={IconType.BackMobile}
        size={dimensions.backButton.size}
        color={styles.focusedTouchable.backgroundColor}
      />
    </NitroxInteractive>
  ), [blurInput, isFocused, styles.focusedTouchable.backgroundColor]);

  const returnKeyType = type === 'search' ? 'search' : isIOS ? 'default' : 'done';

  /**
   * When keyboard has been closed by button on Tizen remote, focus must be moved from TextInput to NitroxInteractive to restore navigation using arrow keys.
   */
  const onKeyPress = useCallback((event: NativeKeyEvent) => {
    if (event.key === SupportedKeys.CloseKeyboard && inputRef.current?.isFocused()) {
      blurInput();
    }
  }, [blurInput, inputRef]);
  useKeyEventHandler('keyup', onKeyPress);

  /* eslint-disable schange-rules/local-alternative */
  return (
    /** ScrollView below is necessary to workaround issue:
     * https://github.com/facebook/react-native/issues/27537
     * FIXME: OSAS-8 relates to open source ticket
     * */
    <ScrollView keyboardDismissMode='none' scrollEnabled={false} alwaysBounceVertical={false}>
      <View style={styles.container}>
        {displayMobileBackButton && mobileBackButton}
        <View style={styles.touchableContainer}>
          <NitroxInteractive
            style={[styles.touchable, isFocused ? styles.focusedTouchable : styles.unfocusedTouchable]}
            {...props}
            ref={touchableRef}
            activeOpacity={1}
            onMouseLeave={setHoveredOff}
            onMouseMove={doNothing}
            onMouseEnter={setHoveredOn}
            onFocus={onTouchableFocus}
            hasTVPreferredFocus={hasTVPreferredFocus}
            onBlur={onTouchableBlur}
            onPress={handleFocus}
            testID={testID}
          >
            <View pointerEvents={!isTVOS && isFocused ? 'auto' : 'none'} style={styles.inputContainer}>
              <TextInput
                ref={inputRef}
                style={[isTVOS ? styles.inputTVOS : inputStyle, textAlignStyle]}
                autoFocus={props.autoFocus}
                maxLength={props.maxLength}
                secureTextEntry={props.secureTextEntry}
                returnKeyType={returnKeyType}
                onFocus={onInputFocus}
                onBlur={onInputBlur}
                onSubmitEditing={onSubmit}
                onChangeText={onChangeText}
                allowFontScaling={false}
                numberOfLines={1}
                placeholder={placeholder}
                placeholderTextColor={isFocused ? styles.focusedPlaceholder.color : styles.unfocusedPlaceholder.color}
                onLayout={onLayout}
                {...inputProps}
              />
              {isTVOS && secureTextEntry && !shouldDisplayTVOSPlaceholder && (
                <View>
                  <NitroxText
                    style={[styles.input, isFocused ? styles.focusedInput : styles.unfocusedInput, textAlignStyle]}
                    textType='input'
                    ellipsizeMode='clip'
                    numberOfLines={1}
                  >
                    {inputValue.replace(/./g, DOT_SEPARATOR)}
                  </NitroxText>
                </View>
              )}
              {isTVOS && !secureTextEntry && !shouldDisplayTVOSPlaceholder && (
                <View>
                  <NitroxText
                    style={[styles.input, isFocused ? styles.focusedInput : styles.unfocusedInput, textAlignStyle]}
                    textType='input'
                    ellipsizeMode='clip'
                    numberOfLines={1}
                  >
                    {inputValue}
                  </NitroxText>
                </View>
              )}
              {shouldDisplayTVOSPlaceholder && (
                <View>
                  <NitroxText
                    // Placeholder takes same styles as input just color of text is different
                    style={[styles.input, isFocused ? styles.focusedPlaceholder : styles.unfocusedPlaceholder, textAlignStyle]}
                    textType='input'
                    ellipsizeMode='clip'
                    numberOfLines={1}
                  >
                    {placeholder}
                  </NitroxText>
                </View>
              )}
            </View>
            <View>
              {isMobile && (
                <ConditionalWrapper
                  condition={!!iconAction}
                  wrapper={(children) => (
                    <NitroxInteractive
                      onPress={iconAction}
                      testID={iconInteractiveTestID}
                      style={React.Children.count(children) > 0 && styles.iconInteractiveContainer}
                    >
                      {children}
                    </NitroxInteractive>
                  )}
                >
                  {!validationError && !isFocused && (shouldDisplayClearIcon || displayEditIndicatorIcon) ? [
                    shouldDisplayClearIcon && clearIcon,
                    displayEditIndicatorIcon && editIndicatorIcon
                  ] : null}
                </ConditionalWrapper>
              )}
              {validationError && errorIndicatorIcon}
            </View>
          </NitroxInteractive>
        </View>
      </View>
    </ScrollView>
  );
  /* eslint-enable schange-rules/local-alternative */
};

export default forwardRef(FocusableTextInputImpl);
