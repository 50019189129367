import {fromEntries} from 'common/HelperFunctions';

import NetInfo, {NetInfoState, NetInfoStateType} from '@react-native-community/netinfo';

import {NetInfoChangeHandler, NetInfoConnectionType, NetInfoInterface, NetInfoState as State} from './NetInfoInterface';

const connectionTypeMap = {
  [NetInfoStateType.unknown]: NetInfoConnectionType.Unknown,
  [NetInfoStateType.none]: NetInfoConnectionType.None,
  [NetInfoStateType.cellular]: NetInfoConnectionType.Cellular,
  [NetInfoStateType.wifi]: NetInfoConnectionType.Wifi,
  [NetInfoStateType.bluetooth]: NetInfoConnectionType.Bluetooth,
  [NetInfoStateType.ethernet]: NetInfoConnectionType.Ethernet,
  [NetInfoStateType.wimax]: NetInfoConnectionType.Wimax,
  [NetInfoStateType.vpn]: NetInfoConnectionType.Vpn,
  [NetInfoStateType.other]: NetInfoConnectionType.Other
};
const stateTypeMap = fromEntries(
  Object.entries(connectionTypeMap).map(([key, value]) => [value, key])
);

const mapStateToConnection = (type: NetInfoStateType) => connectionTypeMap[type];
const mapConnectionToState = (connection: NetInfoConnectionType) => stateTypeMap[connection];

const mapState = ({isConnected, type}: NetInfoState): State => ({
  isConnected,
  type: mapStateToConnection(type)
});

export const netInfo: NetInfoInterface = {
  fetch: (connection?: NetInfoConnectionType) => {
    const stateType = connection && mapConnectionToState(connection);
    return NetInfo.fetch(stateType)
      .then(mapState);
  },
  addEventListener: (listener: NetInfoChangeHandler) => NetInfo.addEventListener(state => listener(mapState(state)))
};
