export enum StreamType {
  m3u8 = 'application/x-mpegURL', // HLS
  mp4 ='video/mp4',
  webm = 'video/webm',
  mpd = 'application/dash+xml',
  mp3 = 'audio/mpeg'
}

export class StreamTypeProvider {
  public static getStreamType(url: string): StreamType | undefined {
    const regExp = /\.([^\./\?\#]+)($|\?|\#)/;
    const regExpArray = url.match(regExp);

    if (regExpArray && regExpArray.length > 0) {
      return StreamType[regExpArray[1] as keyof typeof StreamType];
    }
    if (url.startsWith('data:audio/mpeg;base64,')) {
      return StreamType.mp3;
    }

    return;
  }
}
