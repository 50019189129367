import {createStyles} from 'common-styles';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View, ViewStyle} from 'react-native';

import {isBigScreen} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import NitroxText from 'components/NitroxText';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  labelContainer: {
    flexDirection: 'row'
  },
  label: {
    color: colors.searchScreen.header
  },
  filters: {
    marginLeft: 533,
    width: 160,
    height: 50
  }
}));

type Props = {
  style?: ViewStyle;
}

export default function SearchLabel(props: Props) {
  const {t} = useTranslation();
  const styles = stylesUpdater.getStyles();
  return (
    <View style={[styles.labelContainer, props.style]}>
      <NitroxText
        upperCase={isBigScreen}
        textType='headline'
        style={styles.label}
      >
        {t('search.search')}
      </NitroxText>
      { // TODO: CL-567
        // <NitroxButton style={styles.filters} text='Filters' border/>
      }
    </View>
  );
}
