import {createStyles} from 'common-styles';
import React, {useState, useMemo, useEffect} from 'react';
import {View, ViewStyle} from 'react-native';

import {dimensions, isMobile, isBigScreen} from 'common/constants';
import {Point, Size} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';

import {Event} from 'mw/api/Metadata';

import {IconType} from 'components/Icon';
import {TagTypes, minTagWidth} from 'components/NitroxTag';
import NitroxText from 'components/NitroxText';
import {useParentalControl} from 'components/parentalControl/ParentalControlProvider';
import TileIconsRow, {prepareEventIcons, tileIconSize} from 'components/TileIconsRow';
import {useChannelAvailability} from 'hooks/Hooks';

const excludedIcons = [TagTypes.LIVE, IconType.Check];

const containerMarginRight = dimensions.margins.small;
const textContainerPaddingHorizontal = isMobile ? dimensions.margins.medium : dimensions.margins.xxLarge;
const mobileTextIconsDistance = dimensions.margins.xsmall;
const mobileIconsContainerPaddingHorizontal = dimensions.margins.xsmall;

const showTextThreshold = 2 * textContainerPaddingHorizontal;
const showIconsMinWidthThreshold = showTextThreshold + tileIconSize;
const showTagsMinWidthThreshold = showIconsMinWidthThreshold + minTagWidth;

export type TileProps = {
  event: Event;
  width?: number;
  selected?: boolean;
  focused?: boolean;
};

const staticStyles = createStyles({
  container: {
    flex: 1,
    flexDirection: 'row',
    ...isBigScreen && {alignItems: 'center'},
    marginVertical: dimensions.margins.xsmall,
    marginRight: containerMarginRight
  },
  textContainer: {
    flex: 1,
    overflow: 'hidden',
    paddingHorizontal: textContainerPaddingHorizontal
  },
  textContainerMobile: {
    justifyContent: 'flex-end',
    height: '50%',
    paddingBottom: mobileTextIconsDistance
  },
  unavailableChannel: {
    opacity: 0.2
  }
});

const stylesUpdater = new StylesUpdater(colors => createStyles({
  selected: {
    backgroundColor: colors.epgScreen.grid.tile.active
  },
  focused: {
    backgroundColor: colors.epgScreen.grid.tile.focused
  },
  notFocused: {
    backgroundColor: colors.epgScreen.grid.tile.unfocused
  },
  focusedText: {
    color: colors.epgScreen.grid.tile.text.focused
  },
  unfocusedText: {
    color: colors.epgScreen.grid.tile.text.unfocused
  }
}));

type TileIconsProps = {
  event: Event;
  tilePosition: Point;
  tileSize: Size;
}

export const TileIcons: React.FC<TileIconsProps> = ({
  event,
  tilePosition,
  tileSize
}) => {
  const {isMediaBlocked} = useParentalControl();
  const isBlocked = isMediaBlocked(event);
  const icons = tileSize.width >= showIconsMinWidthThreshold ? prepareEventIcons(event, {
    includeTags: (tileSize.width >= showTagsMinWidthThreshold),
    excludeIcons: excludedIcons,
    isBlocked
  }) : null;

  const containerStyle: ViewStyle = useMemo(() => ({
    position: 'absolute',
    ...isBigScreen ? {
      top: tilePosition.y,
      height: tileSize.height,
      left: tilePosition.x + tileSize.width - 1.5 * containerMarginRight - showTagsMinWidthThreshold,
      justifyContent: 'center',
      alignItems: 'flex-end'
    } : {
      top: tilePosition.y + Math.round(tileSize.height / 2),
      height: Math.round(tileSize.height / 2),
      left: tilePosition.x + mobileIconsContainerPaddingHorizontal,
      paddingTop: mobileTextIconsDistance,
      justifyContent: 'flex-start'
    },
    width: showTagsMinWidthThreshold
  }), [tilePosition.x, tilePosition.y, tileSize.height, tileSize.width]);

  return icons && (
    <View style={containerStyle}>
      <TileIconsRow mediaIcons={icons} />
    </View>
  );
};

const Tile: React.FC<TileProps> = React.memo(({
  event,
  width = 0,
  selected,
  focused
}) => {
  const dynamicStyles = stylesUpdater.getStyles();

  const containerStyle: ViewStyle = useMemo(() => ({
    ...staticStyles.container,
    ...(focused ? dynamicStyles.focused : selected ? dynamicStyles.selected : dynamicStyles.notFocused)
  }), [focused, selected, dynamicStyles]);

  const textContainer: ViewStyle = useMemo(() => ({
    ...staticStyles.textContainer,
    ...isMobile && staticStyles.textContainerMobile
  }), []);

  const textStyle = useMemo(
    () => focused || selected ? dynamicStyles.focusedText : dynamicStyles.unfocusedText,
    [focused, selected, dynamicStyles]
  );

  const [showText, setShowText] = useState(false);
  useEffect(() => {
    if (width >= showTextThreshold) {
      setShowText(true);
    }
  }, [width]);

  const isChannelAvailable = useChannelAvailability(event.channelId);
  const eventStyle = useMemo(() => isChannelAvailable ? {} : staticStyles.unavailableChannel, [isChannelAvailable]);

  return (
    <View style={containerStyle}>
      {showText && (
        <View style={textContainer}>
          <NitroxText
            textType='epg-tile'
            style={[textStyle, eventStyle]}
            numberOfLines={1}
            ellipsizeMode='clip'
          >
            {event.name}
          </NitroxText>
        </View>
      )}
    </View>
  );
});
Tile.displayName = 'Tile';

export default Tile;

