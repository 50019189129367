import {createStyles} from 'common-styles';
import i18next from 'i18next';
import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, ViewStyle} from 'react-native';

import {Log} from 'common/Log';

import {Series} from 'mw/api/Metadata';

import {IconType} from 'components/Icon';
import {ModalSelectOption} from 'components/ModalSelect';
import ModalSelectorButton from 'components/ModalSelectorButton';
import {ButtonIconProps, IconPosition, NitroxButtonTheme} from 'components/NitroxButton';

const TAG = 'MobileSeasonPicker';
const styles = createStyles({
  button: {
    minWidth: 130,
    justifyContent: 'space-between'
  }
});

const buttonIcon: ButtonIconProps = {
  type: IconType.Collapse,
  position: IconPosition.RIGHT,
  size: 8
};

const getSeasonName = (season: Series, t: i18next.TFunction) => {
  return t('mediaDetails.seasonNumberFull', {seasonNumber: season.seasonNumber});
};

const convertSeriesToModalOptions = (series: Series[], t: i18next.TFunction) => {
  return series.map((season: Series): ModalSelectOption<string> => ({
    label: getSeasonName(season, t),
    value: season.id,
    key: season.id
  }));
};

type Props = {
  seasons: Series[];
  selectedSeason: Series;
  onSelected: (season: Series) => void;
  style: StyleProp<ViewStyle>;
}

const MobileSeasonPicker: React.FunctionComponent<Props> = props => {
  const {seasons, selectedSeason, onSelected, style} = props;
  const {t} = useTranslation();
  const modalOptions = useMemo(() => convertSeriesToModalOptions(seasons, t), [seasons, t]);

  const onSeasonSelected = useCallback((seasonId: string | number | undefined) => {
    const newSelectedSeason = seasons.find(season => season.id === seasonId);
    if (newSelectedSeason) {
      onSelected(newSelectedSeason);
    } else {
      Log.error(TAG, `Unable to find season with id ${seasonId}`);
    }
  }, [seasons, onSelected]);

  return (
    <ModalSelectorButton
      theme={NitroxButtonTheme.Secondary}
      textType='buttons'
      buttonStyle={[styles.button, style]}
      label={getSeasonName(selectedSeason, t)}
      buttonIcon={buttonIcon}
      selectedOptionIcon={IconType.Check}
      options={modalOptions}
      value={selectedSeason.id}
      onSelected={onSeasonSelected}
    />
  );
};

export default MobileSeasonPicker;
