import React, {RefObject} from 'react';
import {View, ViewStyle, Animated, StyleProp, LayoutChangeEvent, InteractionManager} from 'react-native';

import {isMobile} from 'common/constants';
import {pointInRect, addVector} from 'common/HelperFunctions';
import {Point, Rect, Size, Hashmap} from 'common/HelperTypes';
import {Log} from 'common/Log';

import {LayoutItem} from 'components/epg/NitroxScrollView';

const TAG = 'NitroxContentView';

export interface ItemPosition {
  top: number;
  left: number;
  width: number;
  height: number;
}

export interface DataDelegate<DataType> {
  layoutItemsForRect: (rect: Rect) => Promise<LayoutItem<DataType>[]>;
  renderItem: (data: DataType, ref: RefObject<any>, layout: ItemPosition) => React.ReactElement | null;
  keyExtractor?: (data: DataType) => string;
}

interface ContentViewProps<DataType> extends DataDelegate<DataType> {
  style?: StyleProp<ViewStyle>;
  currentGrid: Point;
  gridSize: Size;
  dataWindowSize: Size;
  startPosition: Point;
  onNewLayout?: (items: Hashmap<RenderedItem<DataType>>, drawnRect: Rect) => void;
  onLayout?: (event: LayoutChangeEvent) => void;
  debugName?: string;
}

export interface RenderedItem<DataType> extends LayoutItem<DataType> {
  view: React.ReactElement;
  ref: RefObject<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  key: string;
  windowId: string;
  version: number;
}

interface RenderedWindow<DataType> {
  frame: Rect;
  items?: RenderedItem<DataType>[];
}

const Container = isMobile ? Animated.View : View;

export class NitroxContentView<DataType> extends React.Component<ContentViewProps<DataType>> {
  private storedWindows = new Map<string, RenderedWindow<DataType>>();
  private mounted = false;
  private layoutInvalidated = false;
  private preparingLayout = false;
  private debugName: string;

  public constructor(props: ContentViewProps<DataType>) {
    super(props);
    this.debugName = props.debugName || '';
  }

  public componentDidMount() {
    Log.info(TAG, `${this.debugName} Mounted NitroxContentView`);
    this.mounted = true;
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public shouldComponentUpdate(nextProps: ContentViewProps<DataType>) {
    if (this.layoutInvalidated) {
      return false;
    }
    return (
      nextProps.style !== this.props.style ||
      nextProps.currentGrid.x !== this.props.currentGrid.x ||
      nextProps.currentGrid.y !== this.props.currentGrid.y ||
      nextProps.gridSize.width !== this.props.gridSize.width ||
      nextProps.gridSize.height !== this.props.gridSize.height ||
      nextProps.dataWindowSize.width !== this.props.dataWindowSize.width ||
      nextProps.dataWindowSize.height !== this.props.dataWindowSize.height ||
      nextProps.startPosition.x !== this.props.startPosition.x ||
      nextProps.startPosition.y !== this.props.startPosition.y ||
      nextProps.onNewLayout !== this.props.onNewLayout ||
      nextProps.onLayout !== this.props.onLayout ||
      nextProps.layoutItemsForRect !== this.props.layoutItemsForRect ||
      nextProps.renderItem !== this.props.renderItem ||
      nextProps.keyExtractor !== this.props.keyExtractor ||
      nextProps.debugName !== this.props.debugName
    );
  }

  private computeUserWindowForGrid(grid: Point): Rect {
    const x = Math.floor(grid.x * this.props.gridSize.width / this.props.dataWindowSize.width) * this.props.dataWindowSize.width - this.props.startPosition.x;
    const y = Math.floor(grid.y * this.props.gridSize.height / this.props.dataWindowSize.height) * this.props.dataWindowSize.height - this.props.startPosition.y;
    return {
      x,
      y,
      width: this.props.dataWindowSize.width - 1,
      height: this.props.dataWindowSize.height - 1
    };
  }

  private renderItem(item: LayoutItem<DataType>, windowId: string, version = 0): RenderedItem<DataType> {
    const tileRef = React.createRef();
    const key = `${item.x}.${item.y}.${version}`;
    const layout = {position: 'absolute', top: item.y, left: item.x, width: item.width, height: item.height};
    const view = (
      <View
        style={layout as ViewStyle}
        key={key}
      >
        {this.props.renderItem(item.data, tileRef, layout)}
      </View>
    );
    return {...item, view, ref: tileRef, key, windowId, version};
  }

  private async requestLayoutsFor(grid: Point, window: Rect, windowId: string): Promise<RenderedItem<DataType>[]> {
    await (async () => {})(); // we need to ensure that layoutItemsForRect is called outside the render call stack

    if (!this.mounted) {
      throw 0;
    }

    const newLayouts: LayoutItem<DataType>[] = await this.props.layoutItemsForRect(window);
    if (!this.mounted) {
      throw 0;
    }

    Log.trace(TAG, `${this.debugName}Process layout items for window: ${window.x} - ${window.y}`);
    const newItems: RenderedItem<DataType>[] = [];
    newLayouts.forEach(item => {
      // rendered item position is expressed in absolute  coordinates
      item.x += this.props.startPosition.x;
      item.y += this.props.startPosition.y;
      newItems.push(this.renderItem(item, windowId));
    });
    Log.trace(TAG, `${this.debugName}New layouts: ${newLayouts.length}`);
    return newItems;
  }

  private resetContent = () => {
    this.storedWindows.clear();
  }

  private renderedItems: Hashmap<RenderedItem<DataType>> = {};
  private renderedWindows: Hashmap<string> = {};

  private prepareWindowForRendering = (windowId: string) => {
    const currentWindow = this.storedWindows.get(windowId);
    if (currentWindow && !this.renderedWindows[windowId]) {
      this.renderedWindows[windowId] = windowId;
      (currentWindow.items || []).forEach(item => {
        if (!this.renderedItems[item.key]) {
          this.renderedItems[item.key] = item;
        }
      });
    }
  }

  private storeNewItemsInWindow(newItems: RenderedItem<DataType>[], windowId: string) {
    const window = this.storedWindows.get(windowId);
    if (window) {
      window.items = newItems;
    }
  }

  public getItemByPoint(point: Point): RenderedItem<DataType> | null {
    const position = addVector(point, this.props.startPosition);
    for (const window of Array.from(this.storedWindows.values())) {
      if (!pointInRect(point, window.frame)) {
        continue;
      }
      for (const item of window.items ?? []) {
        if (pointInRect(position, item)) {
          return item;
        }
      }
    }
    return null;
  }

  public updateItem(item: RenderedItem<DataType>) {
    const storedWindow = this.storedWindows.get(item.windowId);
    if (!storedWindow || !storedWindow.items) {
      return;
    }
    const newItem = this.renderItem(item, item.windowId, item.version + 1);
    const itemPosition = {x: item.x, y: item.y};
    const storedIndex = storedWindow.items.findIndex(storedItem => pointInRect(itemPosition, storedItem));
    if (storedIndex === -1) {
      return;
    }
    storedWindow.items.splice(storedIndex, 1, newItem);
    if (!this.renderedItems[item.key]) {
      return;
    }
    this.renderedItems[newItem.key] = newItem;
    this.syncUpdate();
  }

  public updateItemByPoint(point: Point) {
    const item = this.getItemByPoint(point);
    if (item) {
      this.updateItem(item);
    }
  }

  public invalidateLayout() {
    if (this.layoutInvalidated) {
      return;
    }
    Log.info(TAG, `${this.debugName} invalidateLayout`);
    this.layoutInvalidated = true;
    this.preparingLayout = false;
    // clear cache and prepare layout for currently visible windows
    this.resetContent();
    this.prepareLayoutData(true);
  }

  private async syncUpdate(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!this.mounted) {
        reject();
        return;
      }
      InteractionManager.runAfterInteractions(() => {
        if (!this.mounted) {
          reject();
          return;
        }
        this.forceUpdate(() => {
          resolve();
        });
      });
    });
  }

  private generateGridSequence(size: number): number[] {
    // Generates a squence in which we will render the grids.
    if (size <= 1) {
      return [0];
    }

    const sequence = [];
    for (let i = - (size - 1); i < size; ++i) {
      sequence.push(i);
    }
    return sequence;
  }

  private async prepareLayoutData(invalidation = false): Promise<void> {
    await (async () => {})(); // we need to ensure that prepareLayoutData is called outside the render call stack
    if (this.preparingLayout) {
      return;
    }
    this.preparingLayout = true;
    this.renderedItems = {};
    this.renderedWindows = {};
    const columns = this.generateGridSequence(Math.ceil(this.props.dataWindowSize.width / this.props.gridSize.width));
    const rows = this.generateGridSequence(Math.ceil(this.props.dataWindowSize.height / this.props.gridSize.height));
    let numberOfWindowsProcessedNow = 0;
    let numberOfNewItems = 0;
    for (const x of columns) {
      for (const y of rows) {
        const grid = {x: this.props.currentGrid.x + x, y: this.props.currentGrid.y + y};
        const window = this.computeUserWindowForGrid(grid);
        const windowId = `${window.x}.${window.y}`;
        if (!this.storedWindows.has(windowId) || invalidation) {
          ++numberOfWindowsProcessedNow;
          this.storedWindows.set(windowId, {frame: window});
          try {
            const newItems = await this.requestLayoutsFor(grid, window, windowId);
            if (this.layoutInvalidated && !invalidation) {
              continue;
            }
            this.storeNewItemsInWindow(newItems, windowId);
            this.prepareWindowForRendering(windowId);
            numberOfNewItems += newItems.length; // keep track of all new items because it is possible that the last request resulted in zero new items
            --numberOfWindowsProcessedNow;
            // when requesting layout after invalidation, block updates until all required windows are cached
            if (invalidation && numberOfWindowsProcessedNow > 0) {
              continue;
            }
            if (this.layoutInvalidated && invalidation) {
              this.layoutInvalidated = false;
            }
            if (numberOfNewItems > 0) {
              Log.trace(TAG, `${this.debugName} Force update content view`);
              await this.syncUpdate();
              continue;
            }
          } catch (error) {
            --numberOfWindowsProcessedNow;
          }
        }
        this.prepareWindowForRendering(windowId);
      }
    }
    this.preparingLayout = false;
    this.notifyNewLayout();
  }

  private notifyNewLayout() {
    if (!this.mounted) {
      return;
    }
    const drawnFrame = {
      top: this.props.startPosition.y,
      left: this.props.startPosition.x,
      bottom: this.props.startPosition.y,
      right: this.props.startPosition.x
    };
    Object.values(this.renderedItems).forEach(item => {
      drawnFrame.left = Math.min(drawnFrame.left, item.x);
      drawnFrame.top = Math.min(drawnFrame.top, item.y);
      drawnFrame.right = Math.max(drawnFrame.right, item.x + item.width);
      drawnFrame.bottom = Math.max(drawnFrame.bottom, item.y + item.height);
    });
    const drawnRect: Rect = {
      x: drawnFrame.left,
      y: drawnFrame.top,
      width: drawnFrame.right - drawnFrame.left,
      height: drawnFrame.bottom - drawnFrame.top
    };
    Log.debug(TAG, `${this.debugName} Layout for rect x=${drawnRect.x} y=${drawnRect.y} width=${drawnRect.width} height=${drawnRect.height} has finished`);
    this.props.onNewLayout && this.props.onNewLayout(this.renderedItems, drawnRect);
  }

  public render() {
    Log.trace(TAG, `${this.debugName} Render content view`);

    this.prepareLayoutData();

    return (
      <Container style={this.props.style || false} onLayout={this.props.onLayout}>
        {Object.values(this.renderedItems).map(item => item.view)}
        {this.props.children}
      </Container>
    );
  }
}
