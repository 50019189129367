import {getMetadataFromMedia} from 'common/HelperFunctions';

import {SearchParameters} from 'mw/api/CatalogInterface';
import {Media, SortVodBy, Channel, SortChannelBy, SortingInterface, isEvent, isSeries} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

export function compareNames(ascending: boolean, a: Media, b: Media): number {
  [a, b] = ascending ? [a, b] : [b, a];
  return a.name.localeCompare(b.name);
}
function compareProductionYear(ascending: boolean, a: Media, b: Media): number {
  [a, b] = ascending ? [a, b] : [b, a];
  return getMetadataFromMedia(a).productionYear - getMetadataFromMedia(b).productionYear;
}

export function sortSearchResults(results: Media[], sorting?: SortingInterface): void {
  const {type, ascending} = sorting || {};

  if (!type || typeof ascending !== 'boolean') {
    return;
  }

  const comparator = type === SortChannelBy.name || type === SortVodBy.title
    ? compareNames
    : compareProductionYear;

  results.sort((a, b) => comparator(ascending, a, b));
}

export function sortEpgSearchResults(results: Media[]): void {
  results.sort((a, b) => {
    if (isSeries(a) && !isSeries(b)) {
      return -1;
    }
    if (!isSeries(a) && isSeries(b)) {
      return 1;
    }
    if (isEvent(a) && isEvent(b)) {
      const startTimeA = a.start.getTime();
      const startTimeB = b.start.getTime();

      let comparison = startTimeA - startTimeB;
      if (comparison === 0) {
        const channelA = mw.catalog.getChannelById(a.channelId);
        const channelB = mw.catalog.getChannelById(b.channelId);
        if (channelA && channelB) {
          comparison = channelA.name.localeCompare(channelB.name);
        }
      }
      return comparison;
    }

    return a.name.localeCompare(b.name);
  });
}

export function filterChannelSearchResults(results: Media[], params: SearchParameters): void {
  const term = params.term.toLowerCase();
  const filteredResults: Media[] = results.filter((result) => {
    const channel = result as Channel;
    return channel.name.toLowerCase().startsWith(term) || channel.longName?.toLowerCase().startsWith(term);
  });
  results.length = 0;
  results.push(...filteredResults);
}
