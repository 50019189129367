import React, {useCallback, useState, useMemo} from 'react';
import {LayoutChangeEvent} from 'react-native';

import {dimensions} from 'common/constants';

import {Recording} from 'mw/api/Metadata';

import RecordingGridTile from 'components/pvr/RecordingGridTile';
import {Selection} from 'components/utils/Selection';

export function useRecordingsGrid(params: {
  recordings: Recording[];
  isFolder?: boolean;
  selection: Selection<Recording>;
  selectable: boolean;
  selectRecording: (recording: Recording, selected: boolean) => void;
  onTilePress: (recording: Recording) => void;
  onFirstTileMount?: () => void;
}) {
  const {recordings, selection, selectable, selectRecording, onTilePress, onFirstTileMount, isFolder} = params;

  const [tilesInARow, setTilesInARow] = useState(0);

  const renderGridTile = useCallback((recording: Recording | undefined, index: number) => {
    const selected = !!recording && selection.isSelected(recording);
    const onCheckboxPress = () => recording && selectRecording(recording, !selected);
    const onMount = index === 0 ? onFirstTileMount : undefined;
    return (
      <RecordingGridTile
        recording={recording}
        onMount={onMount}
        onPress={onTilePress}
        selected={selected}
        selectable={selectable}
        index={index}
        onSelectionCheckboxPress={onCheckboxPress}
        isFolderTile={isFolder}
      />
    );
  }, [selectRecording, selectable, selection, onTilePress, onFirstTileMount, isFolder]);

  const onGridLayout = useCallback((event: LayoutChangeEvent) => {
    const width = event.nativeEvent.layout.width;
    const numberOfTiles = Math.floor(width / (dimensions.tile.width + 2 * dimensions.margins.small));
    if (numberOfTiles !== tilesInARow) {
      setTilesInARow(numberOfTiles);
    }
  }, [tilesInARow]);

  const tilesData = useMemo(() => {
    if (!tilesInARow) {
      return recordings;
    }
    const tilesInLastRow = recordings.length % tilesInARow;
    const fakeTiles = (tilesInARow - tilesInLastRow) % tilesInARow;
    return [...recordings, ...new Array(fakeTiles)];
  }, [recordings, tilesInARow]);

  return {renderGridTile, tilesData, onGridLayout};
}
