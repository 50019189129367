import {createStyles} from 'common-styles';
import moment from 'moment';
import React, {useCallback, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import {Calendar, CalendarTheme, DayComponentProps} from 'react-native-calendars';
import XDate from 'xdate';

import {font, dimensions} from 'common/constants';
import {DateUtils} from 'common/DateUtils';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {CalendarProps} from 'components/Calendar';
import {usePropsDependentState} from 'hooks/Hooks';
import {PostProcessors} from 'locales/i18nPostProcessors';

import FocusParent from './FocusParent';
import {IconType} from './Icon';
import NitroxButton, {NitroxButtonTheme} from './NitroxButton';
import NitroxText from './NitroxText';
import Popup, {PopupAction} from './Popup';

enum FirstDay {
  Sunday,
  Monday
}

enum Directions {
  Previous,
  Next
}

const firstDay = FirstDay.Sunday;

const weekDays = firstDay === FirstDay.Sunday
  ? ['sunday', 'monday', 'thuesday', 'wednesday', 'thursday', 'friday', 'saturday']
  : ['monday', 'thuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

const buttonSize = 46;
const buttonSpacing = dimensions.margins.xsmall;
const calendarWidth = weekDays.length * (buttonSize + 2 * buttonSpacing);
const buttonIconSize = 20;

const styles = createStyles({
  button: {
    height: buttonSize,
    width: buttonSize,
    paddingLeft: 0,
    paddingRight: 0,
    marginHorizontal: buttonSpacing,
    borderRadius: buttonSize / 2,
    borderWidth: 3
  },
  headerContainer: {
    width: calendarWidth
  },
  headerNavigation: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: dimensions.margins.small
  },
  headerWeekDays: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTopWidth: 2,
    borderStyle: 'solid',
    paddingTop: dimensions.margins.small
  },
  modal: {
    alignItems: 'center'
  },
  weekDay: {
    alignItems: 'center',
    justifyContent: 'center',
    width: buttonSize
  }
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  button: {
    borderColor: colors.calendar.backgroundColor
  },
  buttonSelected: {
    borderColor: colors.calendar.textColor
  },
  buttonFocused: {
    backgroundColor: colors.calendar.textColor
  },
  buttonTextFocused: {
    color: colors.calendar.textColorFocused
  },
  headerDate: {
    color: colors.calendar.textColor
  },
  headerWeekDays: {
    borderTopColor: colors.calendar.separator
  },
  calendarTheme: {
    backgroundColor: colors.calendar.backgroundColor,
    calendarBackground: colors.calendar.backgroundColor,
    textSectionTitleColor: colors.calendar.textColor,
    dayTextColor: colors.calendar.textColor,
    monthTextColor: colors.calendar.textColor,
    textMonthFontFamily: font.bold,
    textDayHeaderFontFamily: font.bold,
    textDayFontSize: 21,
    textMonthFontSize: 28,
    textDayHeaderFontSize: 21
  } as CalendarTheme
}));

type NavigationButtonProps = {
  direction: Directions;
  onPress: () => void;
}

const NavigationButton: React.FunctionComponent<NavigationButtonProps> = props => {
  const {direction, onPress} = props;
  const dynamicStyles = stylesUpdater.getStyles();
  return (
    <NitroxButton
      onPress={onPress}
      icon={{
        type: direction === Directions.Previous ? IconType.ArrowLeft : IconType.ArrowRight,
        size: buttonIconSize
      }}
      theme={NitroxButtonTheme.Primary}
      border
      styleFocused={dynamicStyles.buttonFocused}
      textStyleFocused={dynamicStyles.buttonTextFocused}
      style={[styles.button, dynamicStyles.button]}
    />
  );
};

type DayComponentButtonProps = {
  isSelected: boolean;
  disabled?: boolean;
  text: string;
  onPress: () => void;
}

const DayComponentButton: React.FunctionComponent<DayComponentButtonProps> = props => {
  const {isSelected, disabled, text, onPress} = props;
  const dynamicStyles = stylesUpdater.getStyles();
  return (
    <NitroxButton
      focusPriority={isSelected ? 1 : undefined}
      theme={NitroxButtonTheme.Tertiary}
      isSelected={isSelected}
      disabled={disabled}
      textType='calendar'
      text={text}
      style={[styles.button, dynamicStyles.button]}
      styleSelected={dynamicStyles.buttonSelected}
      styleFocused={dynamicStyles.buttonFocused}
      textStyleFocused={dynamicStyles.buttonTextFocused}
      onPress={onPress}
      useOldImplementation
    />
  );
};

type CalendarModalGrossoProps = {
  date: Date;
  visible: boolean;
  onClose: (date: Date) => void;
} & CalendarProps;

const CalendarModalGrosso: React.FunctionComponent<CalendarModalGrossoProps> = props => {
  const {t} = useTranslation();
  const {visible, onClose, minDate, maxDate, date: selectedDate} = props;
  const calendarRef = useRef<{updateMonth: (d: XDate) => XDate | undefined}>();
  const [date, setDate] = usePropsDependentState(selectedDate);
  const dynamicStyles = stylesUpdater.getStyles();

  const updateMonth = useCallback((date: Date) => {
    setDate(date);
    calendarRef.current?.updateMonth(new XDate(date.getTime(), true));
  }, [setDate]);

  const getMonth = useCallback((direction: Directions): Date => {
    const currentMonth = date.getMonth();
    const monthDelta = direction === Directions.Previous ? -1 : 1;
    const yearDelta = Math.floor((currentMonth + monthDelta) / months.length);
    const newMonth = ((currentMonth + monthDelta) % months.length + months.length) % months.length;
    return new Date(date.getFullYear() + yearDelta, newMonth, 2);
  }, [date]);

  const isDisabled = useCallback((timestamp) => {
    return (minDate && timestamp < minDate.getTime()) || (maxDate && timestamp > maxDate.getTime());
  }, [maxDate, minDate]);
  const dayComponent = useCallback((dayComponent: DayComponentProps) => {
    const date = moment(dayComponent.date.dateString).toDate();
    return (
      <DayComponentButton
        isSelected={DateUtils.isSameDay(date, selectedDate)}
        disabled={isDisabled(date.getTime())}
        text={`${dayComponent.date.day}`}
        onPress={() => onClose(date)}
      />
    );
  }, [selectedDate, isDisabled, onClose]);

  const previousMonthHandler = useCallback(() => updateMonth(getMonth(Directions.Previous)), [updateMonth, getMonth]);
  const nextMonthHandler = useCallback(() => updateMonth(getMonth(Directions.Next)), [updateMonth, getMonth]);
  const renderHeader = useCallback((date: Date) => (
    <View style={styles.headerContainer}>
      <FocusParent style={styles.headerNavigation}>
        <NavigationButton direction={Directions.Previous} onPress={previousMonthHandler} />
        <NitroxText style={dynamicStyles.headerDate} textType='title2'>
          {t(`calendar.months.${months[date.getMonth()]}`, {year: date.getFullYear(), postProcess: PostProcessors.Capitalize})}
        </NitroxText>
        <NavigationButton direction={Directions.Next} onPress={nextMonthHandler} />
      </FocusParent>
      <View style={[styles.headerWeekDays, dynamicStyles.headerWeekDays]}>
        {
          weekDays.map(key => {
            return (
              <View key={key} style={styles.weekDay}>
                <NitroxText style={dynamicStyles.headerDate} textType='calendar'>
                  {t(`calendar.weekDaysShort.${key}`, {postProcess: PostProcessors.ToUpperCase})}
                </NitroxText>
              </View>
            );
          })
        }
      </View>
    </View>
  ), [dynamicStyles.headerDate, dynamicStyles.headerWeekDays, t, previousMonthHandler, nextMonthHandler]);

  const closeHandler = useCallback(() => onClose(selectedDate) , [onClose, selectedDate]);

  return (
    <Popup
      actions={[PopupAction.NEGATIVE]}
      visible={visible}
      onClose={closeHandler}
      negativeLabel={t('common.close')}
      onNegative={closeHandler}
      containerStyle={styles.modal}
      menuHasPreferredFocus
    >
      <FocusParent enterStrategy='byPriority'>
        <Calendar
          // Hack to be able to use private method
          // @ts-ignore next-line
          ref={calendarRef}
          current={selectedDate}
          dayComponent={dayComponent}
          renderHeader={renderHeader}
          firstDay={firstDay}
          hideArrows
          hideDayNames
          theme={dynamicStyles.calendarTheme}
        />
      </FocusParent>
    </Popup>
  );
};

export default React.memo(CalendarModalGrosso);
