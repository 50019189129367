import {createStyles} from 'common-styles';
import React, {useCallback} from 'react';
import {WithTranslation, useTranslation} from 'react-i18next';
import {ListRenderItemInfo, View, ViewStyle} from 'react-native';
import {NavigationScreenProps} from 'react-navigation';

import {dimensions, isBigScreen} from 'common/constants';
import {indexKeyExtractor} from 'common/HelperFunctions';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {SearchSource, SearchResult} from 'mw/api/CatalogInterface';

import BackButton, {backButtonSize, backButtonTop} from 'components/BackButton';
import BreadcrumbsSeparator from 'components/BreadcrumbsSeparator';
import FocusParent, {useFocusParent} from 'components/FocusParent';
import {gridMediaTileMarginHorizontal} from 'components/MediaGrid';
import {STBMenuState} from 'components/navigation/NavigationHelperTypes';
import NitroxFlatList from 'components/NitroxFlatList';
import {NitroxInteractiveController} from 'components/NitroxInteractiveControllerContext';
import NitroxText from 'components/NitroxText';
import SearchFullResultsVod from 'components/search/SearchFullResultsVod.grosso';
import SearchFullResultsTv from 'components/search/SearchFullResultTv.grosso';
import {useNavigation} from 'hooks/Hooks';
import NitroxScreen from 'screens/NitroxScreen';

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    backgroundColor: colors.searchScreen.background
  },
  titleHeader: {
    color: colors.searchScreen.header,
    marginTop: backButtonTop + backButtonSize + dimensions.margins.xsmall,
    marginBottom: 95
  },
  breadcrumb: {
    color: colors.searchScreen.subcategory
  },
  lastBreadcrumb: {
    color: colors.searchScreen.subcategory,
    textDecorationLine: 'underline'
  },
  breadcrumbsView: {
    marginBottom: dimensions.margins.xxxLarge,
    flexGrow: 0
  },
  backButton: {
    left: 0,
    marginBottom: dimensions.margins.large
  },
  resultsView: {
    flex: 1
  },
  contentContainer: {
    flex: 1,
    width: '100%',
    ...isBigScreen ? {
      paddingHorizontal: dimensions.screen.container.paddingHorizontal - gridMediaTileMarginHorizontal
    } : {
      paddingLeft: 180
    }
  },
  backButtonContainer: {
    flexDirection: 'row'
  }
}));

type Props = NavigationScreenProps<{
  searchPhrase: string;
  searchSource: SearchSource;
  searchResult: SearchResult;
}> & WithTranslation;

const SearchFullResultsScreen: React.FC<Props> = props => {
  const navigation = useNavigation();
  const {t} = useTranslation();
  const searchPhrase: string = navigation.getParam('searchPhrase');
  const searchSource: SearchSource = navigation.getParam('searchSource');
  const searchResult: SearchResult = navigation.getParam('searchResult');
  const isEpgSource: boolean = searchSource === 'epg';
  const breadcrumbs: string[] = [t('search.search'), (isEpgSource ? t('search.tv') : t('search.movies'))];
  const styles = stylesUpdater.getStyles();

  const renderBreadcrumb = useCallback((info: ListRenderItemInfo<string>) => {
    const isLast = info.index === breadcrumbs.length - 1;
    const styles = stylesUpdater.getStyles();
    return (
      <NitroxText textType='headline' style={isLast ? styles.lastBreadcrumb : styles.breadcrumb}>
        {info.item}
      </NitroxText>
    );
  }, [breadcrumbs.length]);

  const renderSeparator = useCallback(() => {
    const styles = stylesUpdater.getStyles();
    return <BreadcrumbsSeparator textStyle={styles.breadcrumb as ViewStyle} />;
  }, []);

  const [onReady, focus] = useFocusParent();
  return (
    <NitroxScreen
      style={styles.container}
      menuState={STBMenuState.Above}
      navigation={props.navigation}
      mobileHeaderProps={{
        title: t('search.resultsFor', {searchText: searchSource})
      }}
      popStackOnBack
      testID='screen_search_results'
      onScreenFocused={focus}
    >
      <NitroxInteractiveController omitGeometryCaching>
        <FocusParent style={styles.contentContainer} focusPriority={1}>
          <View style={styles.backButtonContainer}>
            <BackButton
              navigation={props.navigation}
              style={styles.backButton}
            />
          </View>
          <NitroxText textType='headline' style={styles.titleHeader}>
            {t('search.resultsFor', {searchText: searchPhrase})}
          </NitroxText>
          <NitroxFlatList<string>
            horizontal
            data={breadcrumbs}
            renderItem={renderBreadcrumb}
            style={styles.breadcrumbsView}
            contentInsetAdjustmentBehavior='never'
            ItemSeparatorComponent={renderSeparator}
            keyExtractor={indexKeyExtractor}
          />
          <FocusParent style={{flex: 1}} onReady={onReady} holdFocus rememberLastFocused>
            {isEpgSource && (
              <SearchFullResultsTv
                style={styles.resultsView}
                resultsNow={searchResult.epgNow || []}
                resultsRecordings={searchResult.epgPvr || []}
                resultsPast={searchResult.epgPast || []}
                resultsFuture={searchResult.epgFuture || []}
                onFirstTileMount={focus}
              />
            )}
            {!isEpgSource &&
              <SearchFullResultsVod results={searchResult.vod || []} />
            }
          </FocusParent>
        </FocusParent>
      </NitroxInteractiveController>
    </NitroxScreen>
  );
};

export default React.memo(SearchFullResultsScreen);
