import i18next from 'i18next';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Omit} from 'react-navigation';

import {SortVodBy, VodSorting} from 'mw/api/Metadata';

import SortOrderSelect, {SortOption, SortOrderSelectProps, sortOrderAscendingIconProps, sortOrderDescendingIconProps, DEFAULT_OPTION_KEY, createOption} from 'components/SortOrderSelect';

//TODO: CL-1923 Refactor of search components across whole application
const defaultSortOptionVodFactory: (t: i18next.TFunction) => SortOption<VodSorting> = t => ({
  key: DEFAULT_OPTION_KEY,
  label: t('sorting.default')
});

const byTitleSortOptionsVodFactory: (t: i18next.TFunction) => SortOption<VodSorting>[] = t => [
  createOption<VodSorting>(
    {
      type: SortVodBy.title,
      ascending: true
    },
    t('sorting.a-z')
  ),
  createOption<VodSorting>(
    {
      type: SortVodBy.title,
      ascending: false
    },
    t('sorting.z-a')
  )
];

const byYearSortOptionsVodFactory: (t: i18next.TFunction) => SortOption<VodSorting>[] = t => [
  createOption<VodSorting>(
    {
      type: SortVodBy.productionYear,
      ascending: false
    },
    t('sorting.year'),
    sortOrderDescendingIconProps
  ),
  createOption<VodSorting>(
    {
      type: SortVodBy.productionYear,
      ascending: true
    },
    t('sorting.year'),
    sortOrderAscendingIconProps
  )
];

const sortOptionsMapFactory: (t: i18next.TFunction) => Map<SortVodBy, SortOption<VodSorting>[]> = t => new Map<SortVodBy, SortOption<VodSorting>[]>([
  [SortVodBy.default, [defaultSortOptionVodFactory(t)]],
  [SortVodBy.title, byTitleSortOptionsVodFactory(t)],
  [SortVodBy.productionYear, byYearSortOptionsVodFactory(t)]
]);

type Props = Omit<SortOrderSelectProps<VodSorting>, 'options'> & {
  supportedSortOptions: SortVodBy[];
}

function VodSortOrderSelect(props: Props) {
  const {supportedSortOptions} = props;
  const {t} = useTranslation();
  const sortOptionsMap = useMemo(() => sortOptionsMapFactory(t), [t]);

  const availableOptions = useMemo((): SortOption<VodSorting>[] => {
    let result: SortOption<VodSorting>[] = [];
    supportedSortOptions.forEach(option => {
      result = result.concat(sortOptionsMap.get(option) || []);
    });
    return result;
  }, [supportedSortOptions, sortOptionsMap]);

  return (
    <SortOrderSelect<VodSorting>
      {...props}
      options={availableOptions}
    />
  );
}

export default React.memo(VodSortOrderSelect);

