import {createStyles} from 'common-styles';
import React, {useState, useCallback, useRef, useMemo} from 'react';
import {View, ScrollView} from 'react-native';

import {dimensions, isWeb, isSmartTV} from 'common/constants';
import {doNothing} from 'common/HelperFunctions';

import {constColors} from 'brand/ColorTypes';
import {FocusManager} from 'components/focusManager/FocusManager';
import {FocusableComponent} from 'components/focusManager/FocusManagerTypes';
import {Icon, IconType} from 'components/Icon';
import {stylesUpdater} from 'components/inputs/FocusableTextInputImpl';
import {NativeKeyEvent, SupportedKeys} from 'components/KeyEventManager';
import NitroxInteractive from 'components/NitroxInteractive';
import {textStyles} from 'components/NitroxText';
import {useToggle, useKeyEventHandler} from 'hooks/Hooks';

const formHeight = 50;

const styles = createStyles({
  rowContainer: {
    flex: 1,
    flexDirection: 'row'
  }
});

type Props = {
  Component?: any;
  showIcon?: boolean;
  placeholder?: string;
  validationError?: boolean;
  onChange: (event: any) => void;
}

const FocusableInput: React.FC<Props> = (props) => {
  const {
    Component,
    placeholder = '',
    showIcon = false,
    validationError = false,
    onChange
  } = props;

  const touchableRef = useRef<FocusableComponent>(null);
  const inputRef = useRef<any>(null);
  const dynamicStyles = stylesUpdater.getStyles();
  const [buttonFocused, onFocusStateChanged] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const [hovered, {on: setHoveredOn, off: setHoveredOff}] = useToggle(false);

  const onTouchableFocus = useCallback(() => onFocusStateChanged(true), [onFocusStateChanged]);
  const onTouchableBlur = useCallback(() => onFocusStateChanged(false), [onFocusStateChanged]);

  const blurInput = useCallback(() => inputRef.current?.blur?.(), [inputRef]);
  const focusInput = useCallback(() => inputRef.current?.focus?.(), [inputRef]);

  const onInputFocus = useCallback(() => {
    setInputFocused(true);
  }, []);

  const onInputBlur = useCallback(() => {
    setInputFocused(false);
    if (!isWeb || isSmartTV) {
      FocusManager.getInstance().forceFocus(touchableRef);
    }
  }, []);

  const onInputEscape = useCallback(() => {
    setInputFocused(false);
    FocusManager.getInstance().forceFocus(touchableRef);
  }, []);

  useKeyEventHandler('keyup', (event: NativeKeyEvent) => {
    if (event.key === SupportedKeys.Ok || event.key === SupportedKeys.Back) {
      blurInput();
    }
  });

  const isFocused = inputFocused || buttonFocused || hovered;

  const interactiveStyle = useMemo(() => ({
    ...dynamicStyles.touchable,
    ...isFocused ? dynamicStyles.focusedTouchable : dynamicStyles.unfocusedTouchable,
    ...{
      height: formHeight,
      marginTop: dimensions.margins.large
    }
  }), [isFocused, dynamicStyles]);

  const textStyle = useMemo(() => ({
    color: isFocused ? constColors.black : constColors.white,
    fontSize: `${textStyles.callout().fontSize}px`,
    lineHeight: `${formHeight}px`,
    iconColor: isFocused ? constColors.black : constColors.white,
    '::placeholder': {
      color: isFocused ? constColors.black : constColors.white
    }
  }), [isFocused]);

  const componentOptions = useMemo(() => ({
    placeholder,
    showIcon,
    style: {
      base: textStyle,
      complete: textStyle,
      invalid: textStyle,
      empty: textStyle
    }
  }), [placeholder, showIcon, textStyle]);

  const errorIndicatorStyle = useMemo(() => ({
    ...dynamicStyles.errorIndicator,
    ...{
      marginTop: dimensions.margins.xsmall,
      marginRight: dimensions.inputs.icons.error.innerPadding - dimensions.inputs.padding
    }
  }), [dynamicStyles]);

  return (
    <NitroxInteractive
      ref={touchableRef}
      style={interactiveStyle}
      activeOpacity={1}
      onMouseLeave={setHoveredOff}
      onMouseMove={doNothing}
      onMouseEnter={setHoveredOn}
      onFocus={onTouchableFocus}
      onBlur={onTouchableBlur}
      onPress={focusInput}
      focusPriority={-1} //do not prefer these items
    >
      <View style={styles.rowContainer}>
        {/* ScrollView as workaround to display Stripe input correctly */}
        {Component && (
          <ScrollView>
            <Component
              options={componentOptions}
              onReady={(element: any) => inputRef.current = element}
              onBlur={onInputBlur}
              onFocus={onInputFocus}
              onEscape={onInputEscape}
              onChange={onChange}
            />
          </ScrollView>
        )}
        {validationError && (
          <View style={errorIndicatorStyle}>
            <Icon
              type={IconType.Remove}
              size={dimensions.inputs.icons.error.iconSize}
              color={dynamicStyles.errorIndicator.color}
            />
          </View>
        )}
      </View>
    </NitroxInteractive>
  );
};

export default FocusableInput;
