import {createStyles} from 'common-styles';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';

import {RADIUS, dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {OrderStatus} from 'mw/api/Metadata';

import NitroxText from 'components/NitroxText';
import {PostProcessors} from 'locales/i18nPostProcessors';

import {labelTitles} from './SettingsOrderProps';

const styles = createStyles({
  label: {
    minWidth: dimensions.statusLabel.width,
    minHeight: dimensions.statusLabel.height,
    borderRadius: RADIUS,
    alignSelf: 'center',
    justifyContent: 'center',
    paddingHorizontal: dimensions.margins.large,
    paddingVertical: dimensions.margins.xsmall
  },
  cellText: {
    textAlign: 'center'
  }
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  cellText: {
    color: colors.settingsScreen.title
  },

  [OrderStatus.Success]: {
    backgroundColor: colors.table.backgroundSuccess
  },
  [OrderStatus.Failed]: {
    backgroundColor: colors.table.backgroundFailure
  },
  [OrderStatus.Pending]: {
    backgroundColor: colors.table.backgroundPending
  },
  [OrderStatus.PaymentFailed]: {
    backgroundColor: colors.table.backgroundPaymentFailed
  },
  [OrderStatus.Unpaid]: {
    backgroundColor: colors.table.backgroundUnpaid
  },
  [OrderStatus.Canceled]: {
    backgroundColor: colors.table.backgroundCanceled
  }
}));

interface StatusLabelProps {
  status: OrderStatus;
  errorText?: string;
}

export const StatusLabel = ({status, errorText}: StatusLabelProps) => {
  const {t} = useTranslation();
  const dynamicStyles = stylesUpdater.getStyles();
  const labelBackgroundColor = dynamicStyles[status];
  const labelTitle = labelTitles[status];

  return (
    <View style={[styles.label, labelBackgroundColor]}>
      <NitroxText style={[dynamicStyles.cellText, styles.cellText]} numberOfLines={1} textType={errorText ? 'callout-small' : 'table-label'}>
        {errorText ?? t(labelTitle, {postProcess: PostProcessors.Capitalize})}
      </NitroxText>
    </View>
  );
};
