/* eslint-disable @typescript-eslint/no-unused-vars */
import {Linking} from 'react-native';

import {NativeSystemInterface} from './NativeSystemInterface';

export const NativeSystemModule: NativeSystemInterface = {
  getSerial: () => Promise.reject(null),
  getSerialNumber: () => Promise.reject(null),
  isLauncher: () => Promise.reject(null),
  isDVBCapable: () => Promise.reject(null),
  getNativeApplications: () => Promise.reject(null),
  openNativeApplication: () => Promise.reject(null),
  openNativeApplicationLink: (url: string) => Linking.openURL(url).then(() => Promise.resolve()),
  canOpenNativeApplicationLink: (url: string) => Linking.canOpenURL(url),
  openNativeStoreLink: () => Promise.reject(),
  openSystemSettings: () => Promise.reject(null),
  getNativeNotifications: () => Promise.reject('getNativeNotifications can be used only on Android based platforms'),
  dismissNativeNotification: (key: string) => Promise.reject('dismissNativeNotification can be used only on Android based platforms'),
  invokeNativeNotificationsAction: (key: string) => Promise.reject('invokeNativeNotificationsAction can be used only on Android based platforms')
};
