import {createStyles} from 'common-styles';
import React, {useMemo, useCallback} from 'react';
import {View} from 'react-native';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import FocusParent from 'components/FocusParent';
import {Icon, IconType} from 'components/Icon';
import NitroxButton, {NitroxButtonTheme} from 'components/NitroxButton';
import NitroxText from 'components/NitroxText';

import {ButtonType} from './SettingsOrderProps';

const styles = createStyles({
  button: {
    width: dimensions.buttons.xsmall,
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: dimensions.margins.small,
    marginRight: dimensions.margins.small
  },
  buttonPagination: {
    width: dimensions.buttons.large,
    height: dimensions.buttons.large,
    paddingLeft: 0,
    paddingRight: 0,
    alignItems: 'center',
    alignSelf: 'center',
    marginHorizontal: dimensions.margins.xsmall / 2
  },
  buttonsWrapper: {
    flexDirection: 'row',
    marginHorizontal: dimensions.margins.large
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: dimensions.margins.medium
  }
});

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  separator: {
    color: colors.table.text,
    marginHorizontal: dimensions.margins.xsmall
  },
  buttonFocused: {
    borderColor: colors.button.primary.background.unfocused,
    borderRadius: 0,
    borderBottomWidth: 2
  }
}));

const maxPaginationSize = 5;

interface PaginationBarProps {
  totalPages: number;
  currentPage: number;
  handleChange: (page: number) => void;
}

type ButtonActionProps = {
  pressHandler: (page: number) => void;
  currentPage: number;
  total: number;
}

interface ButtonPropsInterface {
  actions: {
    [key in ButtonType]: (props: ButtonActionProps) => () => void;
  };
  texts: {
    [key in ButtonType]: ((currentPage: number) => string) | string | undefined;
  };
  elements: {
    [key in ButtonType]: undefined | JSX.Element;
  };
}

const ButtonProps: ButtonPropsInterface = {
  actions: {
    [ButtonType.Decrement]: ({pressHandler, currentPage}) => () => pressHandler(Math.max(1, currentPage - 1)),
    [ButtonType.DecrementExtra]: ({pressHandler, currentPage}) => () => pressHandler(Math.max(1, currentPage - maxPaginationSize)),
    [ButtonType.First]: ({pressHandler}) => () => pressHandler(1),
    [ButtonType.Increment]: ({pressHandler, currentPage, total}) => () => pressHandler(Math.min(total, currentPage + 1)),
    [ButtonType.IncrementExtra]: ({pressHandler, currentPage, total}) => () => pressHandler(Math.min(total, currentPage + maxPaginationSize)),
    [ButtonType.Last]: ({pressHandler, total}) => () => pressHandler(total)
  },
  texts: {
    [ButtonType.Decrement]: undefined,
    [ButtonType.DecrementExtra]: undefined,
    [ButtonType.First]: '1',
    [ButtonType.Increment]: undefined,
    [ButtonType.IncrementExtra]: undefined,
    [ButtonType.Last]: (currentPage = 0) => `${currentPage}`
  },
  elements: {
    [ButtonType.Decrement]: <Icon type={IconType.LT} size={dimensions.icon.xxsmall} />,
    [ButtonType.DecrementExtra]: <Icon type={IconType.DoubleLT} size={dimensions.icon.xxsmall} />,
    [ButtonType.First]: undefined,
    [ButtonType.Increment]: <Icon type={IconType.GT} size={dimensions.icon.xxsmall} />,
    [ButtonType.IncrementExtra]: <Icon type={IconType.DoubleGT} size={dimensions.icon.xxsmall} />,
    [ButtonType.Last]: undefined
  }
};

const NavigationButton = ({type, total, pressHandler, currentPage}: {type: ButtonType; pressHandler: (page: number) => void; total: number; currentPage: number}) => {
  const dynamicStyles = stylesUpdater.getStyles();
  const textValue = ButtonProps.texts[type];
  const text = typeof textValue === 'string' ? textValue : textValue?.(total);
  const element = ButtonProps.elements[type];
  const isBordered = type === ButtonType.First || type === ButtonType.Last;
  const style = isBordered ? styles.buttonPagination : styles.button;
  const theme = isBordered ? NitroxButtonTheme.Primary : NitroxButtonTheme.Tertiary;
  const textStyleFocused = isBordered ? undefined : dynamicStyles.buttonFocused;
  const onPress = ButtonProps.actions[type]({pressHandler, currentPage, total});

  return (
    <NitroxButton
      theme={theme}
      border={isBordered}
      onPress={onPress}
      style={style}
      text={text ?? undefined}
      styleFocused={textStyleFocused}
      useOldImplementation={!isBordered}
    >
      {element ?? undefined}
    </NitroxButton>
  );
};

const PaginationBar = ({totalPages, currentPage, handleChange}: PaginationBarProps): JSX.Element => {
  const dynamicStyles = stylesUpdater.getStyles();
  const separator = useMemo(() => <NitroxText style={dynamicStyles.separator}>{'…'}</NitroxText>, [dynamicStyles.separator]);

  const createPageButton = useCallback((_, index: number) => {
    const pageNumber = ((position: number) => {
      // The pageNumber is based on currentPage, position and totalPages.
      switch (currentPage) {
        case 1:
        case 2:
          /**
           * @example
           * currentPage = 1, position = 1 // 1
           * currentPage = 1, position = 2 // 2
           * currentPage = 2, position = 1 // 1
           * currentPage = 2, position = 2 // 2
           */
          return position;
        case totalPages - 1:
        case totalPages:
          /**
           * @example
           * currentPage = 39, totalPages = 40
           * position = 1 // 36
           * position = 2 // 37
           * position = 3 // 38
           * position = 4 // 39
           * position = 5 // 40
           * @example
           * currentPage = 4, totalPages = 4
           * position = 3 // 3
           * position = 4 // 4
           * position 1 and 2 is not handled in this case.
           * There is no position 5, since range of map is [1...4]
           */
          return totalPages + position - (totalPages > maxPaginationSize ? maxPaginationSize : totalPages);
        default:
          /**
           * @example
           * currentPage = 20, totalPages = 40
           * position = 1 // 18
           * position = 2 // 19
           * position = 3 // 20
           * position = 4 // 21
           * position = 5 // 22
           */
          return currentPage + position - 3;
      }
    })(index + 1);

    return (
      <NitroxButton
        key={index}
        onPress={() => handleChange(pageNumber)}
        border
        style={styles.buttonPagination}
        text={`${pageNumber}`}
        isSelected={currentPage === pageNumber}
      />
    );
  }, [handleChange, currentPage, totalPages]);

  return (
    <FocusParent style={styles.container} enterStrategy='topLeft'>
      {/* Buttons << and < */}
      <NavigationButton currentPage={currentPage} pressHandler={handleChange} type={ButtonType.DecrementExtra} total={totalPages} />
      <NavigationButton currentPage={currentPage} pressHandler={handleChange} type={ButtonType.Decrement} total={totalPages} />
      <View style={styles.buttonsWrapper}>
        {/* First page button */}
        {currentPage > 3 && totalPages > maxPaginationSize && (
          <>
            <NavigationButton currentPage={currentPage} pressHandler={handleChange} type={ButtonType.First} total={totalPages} />
            {separator}
          </>
        )}
        {/* Buttons with range of n...n+4 */}
        {Array.from(Array(Math.min(totalPages, maxPaginationSize)).keys()).map(createPageButton)}
        {/* Last page button */}
        {currentPage <= totalPages - 3 && totalPages > maxPaginationSize && (
          <>
            {separator}
            <NavigationButton currentPage={currentPage} pressHandler={handleChange} type={ButtonType.Last} total={totalPages} />
          </>
        )}
      </View>
      {/* Buttons > and >> */}
      <NavigationButton currentPage={currentPage} pressHandler={handleChange} type={ButtonType.Increment} total={totalPages} />
      <NavigationButton currentPage={currentPage} pressHandler={handleChange} type={ButtonType.IncrementExtra} total={totalPages} />
    </FocusParent>
  );
};

export default PaginationBar;
