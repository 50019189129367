import {ComponentType, ComponentDataSourceType, ComponentSettings} from 'mw/api/CMSInterface';
import {Filter} from 'mw/api/Filter';

const filterBasedDataSourceTypes = [
  ComponentDataSourceType.Filter,
  ComponentDataSourceType.Filters,
  ComponentDataSourceType.Node,
  ComponentDataSourceType.Nodes,
  ComponentDataSourceType.SpecialFilter,
  ComponentDataSourceType.Custom
] as const;

type FilterBasedDataSourceTypes = typeof filterBasedDataSourceTypes[number];

export type FilterBasedDataSource = {
  type: FilterBasedDataSourceTypes;
  filters: Filter[];
};

export type ComponentDataSource =
| {
  type: ComponentDataSourceType.Content;
  data: string;
  extraData?: string;
}
| {
  type: ComponentDataSourceType.Page;
  pageSlug: string;
}
| {
  type: ComponentDataSourceType.Menu;
  menuSlug: string;
}
| FilterBasedDataSource;

export function isDataSourceFilterBased(source: ComponentDataSource): source is FilterBasedDataSource {
  return filterBasedDataSourceTypes.includes(source.type as FilterBasedDataSourceTypes);
}

export class Component {
  public title: string;
  /**
   * Defines look and behaviour of component.
   */
  public type: ComponentType;
  /**
   * Defines source of data for component.
   */
  public dataSource: ComponentDataSource;
  /**
   * Additional setting of the component. Depends on the component type.
   */
  public settings?: ComponentSettings;

  public constructor(params: {
    title: string;
    type: ComponentType;
    dataSource: ComponentDataSource;
    settings?: ComponentSettings;
  }) {
    this.title = params.title;
    this.type = params.type;
    this.dataSource = params.dataSource;
    this.settings = params.settings;
  }
}
