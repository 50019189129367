import {createStyles} from 'common-styles';
import React, {useMemo, useCallback, useState, useEffect} from 'react';
import {View, ActivityIndicator, StyleProp, ViewStyle, ScrollView, TouchableWithoutFeedback, LayoutChangeEvent} from 'react-native';
import DeviceInfo from 'react-native-device-info';

import {dimensions, isPhone, isTablet} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {NitroxButtonTheme} from 'components/NitroxButton';
import NitroxText from 'components/NitroxText';
import Separator from 'components/Separator';
import {useScreenInfo} from 'hooks/Hooks';

import ButtonsRow from './ButtonsRow';
import ConditionalWrapper from './ConditionalWrapper';
import Popup from './Popup';
import {SelectionPopupProps} from './SelectionPopup';
import TileIconsRow from './TileIconsRow';

const TAG = 'SelectionPopup';

const popupTopMargin = isPhone ? dimensions.margins.xxxLarge : 0;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  rowContainer: {
    width: '100%'
  },
  column: {
    flexShrink: 1,
    flexDirection: 'column'
  },
  info: {
    color: colors.popup.text,
    marginBottom: dimensions.margins.large,
    textAlign: 'center'
  },
  sectionTitle: {
    color: colors.popup.text,
    textAlign: 'left'
  },
  icons: {
    marginBottom: dimensions.margins.xLarge,
    alignSelf: 'center'
  },
  buttonsSeparator: {
    marginBottom: dimensions.margins.xxLarge,
    backgroundColor: colors.popup.buttonSeparator
  },
  activityIndicatorStyle: {
    marginBottom: dimensions.margins.large
  },
  button: {
    minWidth: 90
  },
  buttonRowSelected: {
    backgroundColor: colors.popup.background
  },
  buttonRowTextSelected: {
    color: colors.button.primary.text.unfocused
  }
}));

const SelectionPopup: React.FunctionComponent<SelectionPopupProps> = props => {
  const {
    loading,
    onClose,
    sections,
    focusedKey: focusedKeyFromProps,
    title,
    info,
    mediaIcons,
    popupProps,
    columnStyle,
    buttonHeight = dimensions.popup.button.height,
    buttonMargin = dimensions.margins.small,
    columnCount,
    buttonWidth,
    buttonTextStyle,
    centerFirstRow,
    scrollable,
    scrollableWhenMaxHeightExceeded,
    maxHeight = 0,
    shouldWaitForButtons = true
  } = props;

  const {size: screenSize, orientation} = useScreenInfo();
  const [buttonsReady, setButtonsReady] = useState(false);
  const [initialContentHeight, setInitialContentHeightImpl] = useState<number | null>(null);

  const setInitialContentHeight = useCallback((height: number) => {
    if (initialContentHeight == null && !loading) {
      setInitialContentHeightImpl(height);
    }
  }, [initialContentHeight, loading]);

  const onLayout = useCallback((layout: LayoutChangeEvent) => setInitialContentHeight(layout.nativeEvent.layout.height), [setInitialContentHeight]);

  useEffect(() => {
    if (!props.visible) {
      setButtonsReady(false);
      setInitialContentHeightImpl(null);
    }
  }, [props.visible]);

  const onCloseHandler = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  const sectionsData = useMemo(() => {
    return sections.map((section, index) => ({
      options: section.options.map(option => ({
        border: true,
        isSelected: option.selected || option.key === focusedKeyFromProps,
        theme: NitroxButtonTheme.Primary,
        textStyle: buttonTextStyle,
        ...option
      })),
      id: `${index}`,
      title: section.title || '',
      separator: section.separator || false,
      center: section.center || false,
      containerStyle: section.containerStyle || {}
    }));
  }, [sections, focusedKeyFromProps, buttonTextStyle]);

  let gridProps = {};
  let additionalButtonStyle: StyleProp<ViewStyle>;
  if (typeof columnCount !== 'undefined' && typeof buttonWidth !== 'undefined') {
    gridProps = {
      columns: columnCount,
      buttonWidth
    };
  } else {
    additionalButtonStyle = {
      marginRight: columnCount || isTablet ? dimensions.margins.large : 0,
      marginBottom: buttonMargin,
      minWidth: buttonWidth
    };
  }

  const calculateMaxHeight = useCallback(() => {
    const maxScreenHeight = screenSize.height - popupTopMargin;
    if (maxHeight) {
      return Math.min(maxHeight, maxScreenHeight);
    }
    return maxScreenHeight;
  }, [screenSize, maxHeight]);

  const contentExceedesMaxHeight = useMemo(() => (initialContentHeight || 0) > calculateMaxHeight(), [initialContentHeight, calculateMaxHeight]);

  const {containerStyle, buttonMaxWidth} = useMemo(() => {
    const padding = (DeviceInfo.hasNotch() && orientation.isLandscape) ? dimensions.notch.height : dimensions.popup.padding;
    const containerStyle = shouldWaitForButtons && !buttonsReady ? {
      opacity: 0
    } : {
      paddingLeft: padding,
      paddingRight: padding,
      width: screenSize.width,
      ...(maxHeight > 0 || contentExceedesMaxHeight) && {height: calculateMaxHeight()}
    };

    return {
      containerStyle,
      buttonMaxWidth: Math.round(screenSize.width / 2)
    };
  }, [buttonsReady, orientation.isLandscape, screenSize.width, maxHeight, shouldWaitForButtons, calculateMaxHeight, contentExceedesMaxHeight]);

  const scrollingEnabled = useMemo(() => scrollable || (contentExceedesMaxHeight && scrollableWhenMaxHeightExceeded), [scrollable, contentExceedesMaxHeight, scrollableWhenMaxHeightExceeded]);
  const styles = stylesUpdater.getStyles();
  return (
    <Popup
      visible={props.visible}
      title={loading ? '' : title}
      onClose={onCloseHandler}
      containerStyle={containerStyle}
      onLayout={onLayout}
      {...popupProps}
    >
      {!loading && (
        <ConditionalWrapper
          condition={scrollingEnabled}
          wrapper={children => (
            <ScrollView>
              {children}
            </ScrollView>
          )}
        >
          <View style={[styles.column, columnStyle]}>
            {info && <NitroxText textType='callout' style={styles.info}>{info}</NitroxText>}
            {mediaIcons && <TileIconsRow mediaIcons={mediaIcons} style={styles.icons} />}
            {sectionsData.map((section, index) => {
              return (
                <View key={`${TAG}-sectionView-${section.id}`}>
                  {!!section.title && <NitroxText textType='options-texts' style={styles.sectionTitle}>{section.title}</NitroxText>}
                  <ButtonsRow
                    data={section.options}
                    buttonHeight={buttonHeight}
                    buttonMaxWidth={buttonMaxWidth}
                    xMargin={buttonMargin * 2}
                    yMargin={buttonMargin}
                    containerStyle={[
                      styles.rowContainer,
                      {
                        marginTop: buttonMargin,
                        marginBottom: (index < sectionsData.length - 1) ? buttonMargin : 0,
                        justifyContent: section.center ? 'center' : undefined,
                        marginLeft: isTablet ? dimensions.margins.large : 0
                      },
                      section.containerStyle
                    ]}
                    buttonSelectedStyle={styles.buttonRowSelected}
                    buttonTextSelectedStyle={styles.buttonRowTextSelected}
                    buttonStyle={[styles.button, additionalButtonStyle]}
                    onButtonsReady={setButtonsReady}
                    centerFirstRow={centerFirstRow}
                    {...gridProps}
                  />
                  {section.separator && <Separator horizontal style={styles.buttonsSeparator} />}
                </View>
              );
            }
            )}
          </View>
          <ConditionalWrapper
            condition={!!props.children && scrollingEnabled}
            wrapper={children => (
              /* Without TouchableWithoutFeedback scroll is impossible */
              <TouchableWithoutFeedback
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                touchSoundDisabled
              >
                {children}
              </TouchableWithoutFeedback>
            )}
          >
            {props.children}
          </ConditionalWrapper>
        </ConditionalWrapper>
      )}
      {loading &&
        <ActivityIndicator style={[styles.activityIndicatorStyle, props.activityIndicatorStyle]} />
      }
    </Popup>
  );
};

export default React.memo(SelectionPopup);
