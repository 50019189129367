import Color from 'color';
import React, {useImperativeHandle, useMemo, useState, useCallback} from 'react';
import {StyleProp, TextStyle, LayoutChangeEvent} from 'react-native';

import {TestProps} from 'common/HelperTypes';

import GradientFill from 'components/GradientFill';
import NitroxText, {TextType, getFontStyle} from 'components/NitroxText';

import NitroxInteractive from './NitroxInteractive';

type Props = {
  fadeColor: string;
  numberOfLines: number;
  textType?: TextType;
  style?: StyleProp<TextStyle>;
  children?: React.ReactChild | string | object | undefined;
} & TestProps;

const ExpandableText: React.FunctionComponent<Props> = (props, ref) => {
  if (props.numberOfLines <= 0) {
    throw new Error('Number of lines must be greater than 0');
  }
  const [isExpandable, setIsExpandable] = useState(false);
  const lineHeight = useMemo(() => {
    const {lineHeight, fontSize} = getFontStyle(props.textType);
    return Math.max(lineHeight || 0, fontSize || 0);
  }, [props.textType]);
  const onTextLayout = useCallback((event: LayoutChangeEvent) => {
    const {nativeEvent: {layout: {height}}} = event;
    if (lineHeight === 0) {
      return;
    }
    // android does this weird sizing where text is marginally below lineHeight * numberOfLines, e.g. 79.23(actual) vs 80(expected)
    // this is related to dp -> pixel scaling, there is no deterministic way to calculate this though, PixelRatio.getPixelSizeForLayoutSize does not help
    const expandable = Math.round(height / lineHeight) >= props.numberOfLines;
    setIsExpandable(expandable);
  }, [props.numberOfLines, lineHeight]);

  const gradientColors = useMemo(() => [props.fadeColor, Color(props.fadeColor).alpha(0).toString()], [props.fadeColor]);
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleIsExpanded = useCallback(() =>
    isExpandable && setIsExpanded(prevState => !prevState),
  [isExpandable]);

  // eslint-disable-next-line schange-rules/no-use-imperative-handle-hook
  useImperativeHandle(ref, () => ({
    expand: () => {
      setIsExpanded(true);
    },
    collapse: () => {
      setIsExpanded(false);
    },
    toggle: () => {
      toggleIsExpanded();
    }
  }));

  return (
    <NitroxInteractive
      onPress={toggleIsExpanded}
      activeOpacity={1}
      testID={props.testID || 'expandable_text'}
    >
      <NitroxText
        textType={props.textType}
        numberOfLines={isExpanded ? 0 : props.numberOfLines}
        style={props.style}
        onLayout={onTextLayout}
      >
        {props.children}
      </NitroxText>
      {!isExpanded && isExpandable && <GradientFill colors={gradientColors} />}
    </NitroxInteractive>
  );
};

export default React.memo(React.forwardRef(ExpandableText));
