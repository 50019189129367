import {createStyles} from 'common-styles';
import React from 'react';

import {dimensions, isBigScreen} from 'common/constants';

import {StylesUpdater, useStyles} from 'common-styles/StylesUpdater';

import {Icon, IconType} from 'components/Icon';
import NitroxButton, {NitroxButtonTheme} from 'components/NitroxButton';
import NitroxInteractive, {NitroxInteractiveProps} from 'components/NitroxInteractive';

const colorUpdater = new StylesUpdater(colors => createStyles({
  iconFocused: colors.button.primary.background.focused,
  iconUnfocused: colors.button.primary.background.unfocused
}));

type InlineBackButtonProps = NitroxInteractiveProps & {
  text?: string
};
const InlineBackButtonPiccolo: React.FC<InlineBackButtonProps> = (interactiveProps) => {
  const styles = useStyles(colorUpdater);
  return (
    <NitroxInteractive
      testID='button_back'
      {...interactiveProps}
    >
      <Icon
        type={IconType.BackMobile}
        size={dimensions.backButton.size}
        color={styles.iconFocused}
      />
    </NitroxInteractive>
  );
};

const icon = {type: IconType.BackSTB}; //TODO: CL-7352 Change it to MiniBack once added
const InlineBackButtonGrosso: React.FC<InlineBackButtonProps> = (props) => {
  return (
    <NitroxButton
      testID='button_back'
      onPress={props.onPress}
      theme={NitroxButtonTheme.Secondary}
      text={props.text}
      isCornerIcon
      style={props.style}
      icon={icon}
    />
  );
};

export default isBigScreen ? InlineBackButtonGrosso : InlineBackButtonPiccolo;
