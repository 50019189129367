import React, {useState, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {EnterPinModalComponent} from 'components/EnterPinModalComponent';
import {useToggle, useNavigation} from 'hooks/Hooks';

import SettingsClickableOption from './SettingsClickableOption';

type SettingsChangePINProps = {
  onPINChanged: (pin: string) => void;
  initialValue?: string;
  pinSet: boolean;
  title?: string;
}

const SettingsChangePin: React.FC<SettingsChangePINProps> = ({
  onPINChanged: propsOnPINChanged,
  initialValue = '',
  pinSet,
  title: propsTitle = ''
}) => {
  const navigation = useNavigation();
  const {t} = useTranslation();

  const [isPinPopupVisible, {on: showPinPopup, off: hidePinPopup}] = useToggle(false);
  const [pin, setPIN] = useState(navigation.getParam('pin') || initialValue);

  const onSubmit = useCallback((value: string) => {
    setPIN(value);
    propsOnPINChanged(value);
    hidePinPopup();
  }, [hidePinPopup, propsOnPINChanged]);

  return (
    <>
      <SettingsClickableOption
        title={pinSet || pin ? (propsTitle || t('settings.currentPIN', {pin})) : t('settings.pinNotSet')}
        buttonLabel={pinSet || pin ? t('settings.changePIN') : t('settings.setPin')}
        onPress={showPinPopup}
      />
      <EnterPinModalComponent
        visible={isPinPopupVisible}
        onSubmit={onSubmit}
        onClose={hidePinPopup}
        title={(pinSet || pin) ? t('settings.enterNewPin') : t('settings.setPinTitle')}
      />
    </>
  );
};

export default SettingsChangePin;
