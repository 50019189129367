import * as BrowserDetect from 'detect-browser';
import i18next from 'i18next';
import moment from 'moment';
import {Animated, NativeSyntheticEvent, NativeScrollEvent} from 'react-native';

import {AppRoutes, isTizen} from 'common/constants';
import {DateUtils} from 'common/DateUtils';
import {Log} from 'common/Log';

import {Error, ErrorType} from 'mw/api/Error';
import {Media, MediaType, Event, Title, Episode, Credits, Genre, Metadata, Recording, PictureType, PictureMode, isEvent, Content, Channel, Series, ContentType, isSingleRecording, RecordingType, Product, Offer} from 'mw/api/Metadata';
import {Profile} from 'mw/api/Profile';
import {Menu} from 'mw/cms/Menu';
import {mw} from 'mw/MW';

import {LimitedAsyncIterableIterator, NamedAction, isLimitedAsyncIterableIterator} from 'components/utils/SwimlaneVisibilityLimit';

import {flatten} from './helpers/ArrayHelperFunctions';
import {Point, Rect, Size, Hashmap, Navigation, NavigationDestination} from './HelperTypes';
import {getEpisodeAndSeasonNumber} from './utils';

export {
  asyncIterator,
  compactMap,
  compareArrays,
  flatten,
  maxBy,
  shuffle,
  split, SplitElement, SplitHash, SplitSelection,
  unique
} from 'common/helpers/ArrayHelperFunctions';

const TAG = 'HelperFunctions';

export function idKeyExtractor<ItemT extends {id: string}>(item: ItemT, index: number): string {
  return item.id;
}

export function indexKeyExtractor<ItemT>(item: ItemT, index: number): string {
  return index.toString();
}

export function interpolateToPercentage(animatedValue: Animated.Value): Animated.AnimatedInterpolation {
  return animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0%', '100%'],
    extrapolate: 'clamp'
  });
}

export function convertDimension(value: string | number, max: number): number {
  return typeof value === 'number' ? value : (max * (parseInt(value) / 100));
}

export function combineDateAndTime(date: Date, time: Date): Date {
  const combined = new Date(date);
  combined.setHours(time.getHours());
  combined.setMinutes(time.getMinutes());
  combined.setSeconds(time.getSeconds());
  combined.setMilliseconds(time.getMilliseconds());
  return combined;
}

export function formatDatePrefix(date: Date): string {
  const delta = moment(date).diff(moment().startOf('day'));
  if (delta >= -DateUtils.msInDay && delta < 0) {
    return i18next.t('common.yesterday');
  }
  if (delta >= 0 && delta < DateUtils.msInDay) {
    return i18next.t('common.today');
  }
  if (delta >= DateUtils.msInDay && delta < 2 * DateUtils.msInDay) {
    return i18next.t('common.tomorrow');
  }
  return '';
}

export const formatDuration = (seconds: number) => {
  const duration = moment.duration(Math.max(seconds, DateUtils.sInMin), 'seconds');
  const format = `h[${i18next.t('time.h')}]${duration.minutes() ? ` m[${i18next.t('time.min')}]` : ''}`;
  return duration.format(format);
};

export function appendOptionsToUrl(url: string, options?: {[key: string]: string | number | null | undefined}): string {
  if (typeof options === 'undefined') {
    return url;
  }
  const keyValuePairs = Object.entries(options)
    .filter(([, value]) => value != null);
  if (keyValuePairs.length) {
    const params = keyValuePairs.map(([key, value]) => `${key}=${value}`).join('&');
    const joiningSign = url.includes('?') ? '&' : '?';
    return `${url}${joiningSign}${params}`;
  }
  return url;
}

export function createScrollViewPager() {
  let currentContentHeight = 0;
  let reachedEnd = false;
  return {
    onScroll: (event: NativeSyntheticEvent<NativeScrollEvent>, onEndReached?: () => void, onEndReachedThreshold = 1) => {
      const {nativeEvent: {contentOffset, contentSize, layoutMeasurement}} = event;
      const contentHeight = contentSize.height;
      const viewportHeight = layoutMeasurement.height;
      const offsetY = contentOffset.y;
      if (!contentHeight) {
        return; // return when scrollview is empty
      }
      if (contentHeight !== currentContentHeight) {
        currentContentHeight = contentHeight;
        reachedEnd = false;
      } else if (reachedEnd) { // only call onEndReached once per contentHeight
        return;
      }
      if (contentHeight - viewportHeight - offsetY <= viewportHeight * onEndReachedThreshold) {
        reachedEnd = true;
        onEndReached && onEndReached();
      }
    }
  };
}

// clamps value when its is out of inputRange
export const interpolate = (value: number, {inputRange: [inputMin, inputMax], outputRange: [outputMin, outputMax]}: {inputRange: [number, number]; outputRange: [number, number]}) => {
  if (value <= inputMin) {
    return outputMin;
  }
  if (value >= inputMax) {
    return outputMax;
  }

  const inputDelta = inputMax - inputMin;
  const outputDelta = outputMax - outputMin;
  const part = (value - inputMin) / inputDelta;
  return outputMin + (part * outputDelta);
};

export function navigateToDestination(navigation: Navigation, destination: NavigationDestination) {
  Log.debug(TAG, 'Navigating to destination', destination);
  if (destination === 'CurrentRoute') {
    return;
  }
  try {
    if (destination === 'PreviousRoute') {
      navigation.goBack();
    } else {
      navigation.navigate(destination);
    }
  } catch (error) {
    Log.error(TAG, `Failed to navigate to destination ${destination} - got error`, error);
  }
}

export function openMediaDetails(navigation: Navigation, mediaId: string, type: string) {
  try {
    navigation.push(AppRoutes.MediaDetail, {'mediaId': mediaId, 'mediaType': type});
  } catch (error) {
    Log.error(TAG, 'Open MediaDetails error', error);
  }
}

export function replaceMediaDetails(navigation: Navigation, mediaId: string, type: string) {
  try {
    navigation.replace(AppRoutes.MediaDetail, {'mediaId': mediaId, 'mediaType': type});
  } catch (error) {
    Log.error(TAG, 'Open MediaDetails error', error);
  }
}

export function openMediaPlayer(navigation: Navigation, media: Media, position = 0) {
  try {
    navigation.push(AppRoutes.MediaPlayer, {media, position, prevScreen: navigation.state.routeName});
  } catch (error) {
    Log.error(TAG, 'Open MediaPlayer error', error);
  }
}

export function replaceMediaPlayer(navigation: Navigation, media: Media, position = 0) {
  try {
    navigation.replace(AppRoutes.MediaPlayer, {media, position, prevScreen: navigation.getParam('prevScreen')});
  } catch (error) {
    Log.error(TAG, 'Replace MediaPlayer error', error);
  }
}

export function openChannelDetails(navigation: Navigation, mediaId: string) {
  try {
    navigation.push(AppRoutes.ChannelDetail, {'mediaId': mediaId});
  } catch (error) {
    Log.error(TAG, 'Open ChannelDetails error', error);
  }
}

export function getMediaSubtypes(media: Media): {event: Event | null; title: Title | null; episode: Episode | null} {
  let event = null;
  switch (media.getType()) {
    case MediaType.Event:
      event = media as Event;
      break;
    case MediaType.Recording:
      if (isSingleRecording(media)) {
        event = media.event;
      }
      break;
  }
  const title = media.getType() === MediaType.Title ? media as Title : event ? event.title : null;
  const episode = title && isSeriesEpisode(title) ? title.episode : null;
  return {event, title, episode};
}

export function getMediaTitle(media: Media): string {
  const {title, episode} = getMediaSubtypes(media);
  return title && episode
    ? episode.seriesName || title.name || media.name // series name if possible
    : title
      ? title.name || media.name // title name if possible
      : media.name;
}

export function getMetadataFromMedia(media: Media): Metadata {
  switch (media.getType()) {
    case MediaType.Event:
      return (media as Event).title.metadata;
    case MediaType.Series:
    case MediaType.Season:
      return (media as Series).metadata;
    case MediaType.Title:
      return (media as Title).metadata;
    case MediaType.Recording:
      if (isSingleRecording(media)) {
        return media.event.title.metadata;
      } else {
        throw new Error(ErrorType.InvalidParameter, 'Metadata is only available for single recording');
      }
    default:
      throw new Error(ErrorType.InvalidParameter, 'Unsupported media type: ' + media.getType());
  }
}

export function getMediaCredits(media?: Media | null): {actors: Credits[], directors: Credits[]} {
  if (!media) {
    return {actors: [], directors: []};
  }
  const {actors, directors} = getMetadataFromMedia(media);
  return {actors, directors};
}

export function getCreditsFullName(credits: Credits) {
  return `${credits.firstName} ${credits.lastName}`.trim();
}

export function traceComponentUpdate<T extends {[key: string]: any}, S extends {[key: string]: any}>(prevProps: T, props: T, prevState: S, state: S, tag?: string) {
  if (!__DEV__) {
    return;
  }
  const TAG = tag || 'traceComponentUpdate';
  Object.entries(props).forEach(([key, val]) =>
    prevProps[key] !== val && Log.info(TAG, `Prop '${key}' changed:`, {from: prevProps[key], to: val})
  );
  Object.entries(state).forEach(([key, val]) =>
    prevState[key] !== val && Log.info(TAG, `State '${key}' changed:`, {from: prevProps[key], to: val})
  );
}

// needed to work well with memoization & prop overriding
export function doNothing() {}
export async function doNothingAsync() {}

export function createEmptyAsyncIterator<T>(): AsyncIterableIterator<T> {
  return (async function* (): AsyncIterableIterator<T> {
    return [];
  })();
}

// Utility identity function
export function identity<P>(arg: P): P {return arg;}

function isSameGenres(lhs: Genre, rhs: Genre) {
  return lhs.code === rhs.code && lhs.name === rhs.name;
}

export function genresIntersect<T extends {metadata: Metadata}>(media: T): (other: T) => boolean {
  return (other: T) => media.metadata.genres.some(genre => other.metadata.genres.some(otherGenre => isSameGenres(genre, otherGenre)));
}

export function formatGenres(genres: Genre[]) {
  return genres.map(genre => genre.name).join(', ');
}

export function createDataSource<T>(generator?: AsyncIterableIterator<T[]> | LimitedAsyncIterableIterator<T[]>, tag?: string) {
  let data: T[] = [];
  let finished = false;
  let limited = false;
  let action: NamedAction | undefined;
  let currentPromise: Promise<void> | null = null;

  function getDataFromCache(index: number): T | undefined {
    return data[index];
  }

  async function fetchNextPage() {
    if (!generator) {
      throw new Error(ErrorType.UnknownError, 'Unable to fetch next page from data source - generator is missing');
    }
    Log.info(tag || 'DataSource', `Fetching next page...`);
    try {
      const result = await generator.next();
      data = data.concat(result.value);
      finished = !!result.done;
      if (isLimitedAsyncIterableIterator(generator) && generator.isLimited?.()) {
        limited = true;
        action = generator.getAction?.();
      }
    } catch (error) {
      Log.error(tag || 'DataSource', 'Error fetching next page: ', error);
      finished = true;
      limited = false;
      action = undefined;
    }
  }

  return {
    isFinished: () => finished,
    isLimited: () => limited,
    getAction: () => action,
    getDataLength: () => data.length,
    getIndexForData: (element: T) => data.indexOf(element),
    getDataForIndex: async (index: number) => {
      if (finished || index < data.length) {
        return getDataFromCache(index);
      }
      if (!currentPromise) {
        currentPromise = fetchNextPage();
      }
      while (true) {
        await currentPromise;
        if (finished || index < data.length) {
          return getDataFromCache(index);
        }
        currentPromise = fetchNextPage();
      }
    }
  };
}

export async function addToWatchList(media: Media): Promise<void> {
  if (!mw.customer.currentProfile) {
    Log.error(TAG, `Unable to add media ${media} to WatchList - there is no current profile`);
    return;
  }
  try {
    await mw.customer.currentProfile.addToWatchList(media);
  } catch (error) {
    Log.error(TAG, `Failed to add media ${media} to WatchList.`, error);
  }
}

export async function removeFromWatchList(mediaArray: Media[]): Promise<void> {
  if (!mw.customer.currentProfile) {
    Log.error(TAG, `Unable to remove media ${mediaArray} items from WatchList - there is no current profile`);
    return;
  }
  try {
    await mw.customer.currentProfile.removeFromWatchList(mediaArray);
  } catch (error) {
    Log.error(TAG, `Failed to remove media ${mediaArray} from WatchList.`, error);
  }
}

export function findPlayableRecording(recordings: Recording[]): Recording | undefined {
  return recordings.find(recording => !!recording.getPlayable());
}

export async function isEventRecorded(event: Event): Promise<boolean> {
  const recordings = await mw.pvr.getRecordings({media: event, type: RecordingType.Single}).catch(() => []);
  return recordings.length > 0;
}

export function getPictureUrl(media: Media | Menu | Credits, posterSize: {width: number; height: number}): {uri: string} | null {
  const uri = mw.catalog.getPictureUrl(media, posterSize.width >= posterSize.height ? PictureType.Horizontal : PictureType.Vertical, posterSize.width, posterSize.height, PictureMode.BOX);
  if (uri !== '') {
    return {uri};
  }
  return null;
}

export function getLongestSynopsis(metadata: Metadata): string {
  return metadata.longSynopsis || metadata.mediumSynopsis || metadata.shortSynopsis;
}

export function getShortestSynopsis(metadata: Metadata): string {
  return metadata.shortSynopsis || metadata.mediumSynopsis || metadata.longSynopsis;
}

export const isTruthy = (e: any) => !!e;

export const intoBrackets = (e: any) => `(${e})`;

export const getRatingToDisplay = (media: Media, profile: Profile | null = mw.customer.currentProfile): string | null => {
  const rating = media.pcRatings[0];
  if (profile?.isPCEnabled && rating?.value) {
    return rating.value;
  }
  return null;
};

export function humanCaseToSnakeCase(text: string): string {
  // INFO CL-2148 condition added to secure errors happening in runtime
  if (typeof text !== 'string') {
    Log.warn('humanCaseToSnakeCase', `Text parameter was expected to be of type string, but it is of type ${typeof text}.`);
    return '';
  }
  return text.replace(/\s+/g, '_').toLowerCase();
}

export function humanCaseToOneWord(text: string): string {
  // INFO CL-2148 condition added to secure errors happening in runtime
  if (typeof text !== 'string') {
    Log.warn('humanCaseToOneWord', `Text parameter was expected to be of type string, but it is of type ${typeof text}.`);
    return '';
  }
  return text.replace(/\s+/g, '').toLowerCase();
}

export function humanToCamelCase(text: string): string {
  return text.toLowerCase().replace(/(?:^\w|\b\w|\s+)/g, (match, index) => (
    /\s+/.test(match)
      ? ''
      : index === 0
        ? match
        : match.toUpperCase() // eslint-disable-line no-restricted-syntax
  ));
}

/**
 * Returns media duration in ms
 * @param media Object defining asset
 * @param contentType Playback content type
 */
export function getMediaDuration(media: Media, contentType: ContentType): number {
  // media duration may vary for different kinds of playback
  switch (contentType) {
    case ContentType.LIVE:
    case ContentType.NPLTV:
      return isEvent(media) ? media.end.getTime() - media.start.getTime() : 0;
    case ContentType.NPVR:
      return isSingleRecording(media) ? media.duration * DateUtils.msInSec : 0;
    case ContentType.TSTV:
    case ContentType.VOD:
      return (media.getPlayable() as Content)?.duration * DateUtils.msInSec || 0;
    default:
      return 0;
  }
}

export async function getMillisecondsToEndOfCurrentEvent(channel: Channel) {
  const event = await mw.catalog.getCurrentEvent(channel);
  if (event) {
    return event.end.valueOf() - Date.now();
  }
}

export function filterMap<K, V>(map: Map<K, V>, condition: (value: V, key: K) => boolean) {
  const newMap = new Map<K, V>();
  map.forEach((value, key) => {
    if (condition(value, key)) {
      newMap.set(key, value);
    }
  });
  return newMap;
}

export const pointInRect = (p: Point, r: Rect) => p.x >= r.x && p.x <= r.x + r.width && p.y >= r.y && p.y <= r.y + r.height;

export function transformPoint(point: Point, trans: (value: number) => number): Point {
  return {
    x: trans(point.x),
    y: trans(point.y)
  };
}

export function transformSize(size: Size, trans: (value: number) => number): Size {
  return {
    width: trans(size.width),
    height: trans(size.height)
  };
}

export function transformRect(rect: Rect, trans: (value: number) => number): Rect {
  return {
    ...transformPoint(rect, trans),
    ...transformSize(rect, trans)
  };
}

export function findInMap<K, V>(map: Map<K, V>, predicate: (value: V, key: K) => boolean): {key: K; value: V} | undefined {
  if (!map.size) {
    return;
  }
  const iterator = map.entries();
  let pair: IteratorResult<[K, V]>;
  while ((pair = iterator.next()) && !pair.done) {
    const [key, value] = pair.value;
    if (predicate(value, key)) {
      return {key, value};
    }
  }
}

export function reduceMap<K, V, A extends any>(map: Map<K, V>, reducer: (total: A, current: [K, V]) => A, initialValue: A) {
  let result = initialValue;
  map.forEach((value, key) => {
    result = reducer(result, [key, value]);
  });
  return result;
}

export function addOffset(src: Point, offset: number): Point {
  return {x: src.x + offset, y: src.y + offset};
}

export function addVector(src: Point, vector: Point): Point {
  return {x: src.x + vector.x, y: src.y + vector.y};
}

export function subVector(src: Point, vector: Point): Point {
  return {x: src.x - vector.x, y: src.y - vector.y};
}

export function shallowEqual<T extends Record<string, any>>(a?: T, b?: T): boolean {
  if (a === b) {
    return true;
  }
  if (!a || !b) {
    return false;
  }
  for (const key in a) {
    if (a.hasOwnProperty(key) && a[key] !== b[key]) {
      return false;
    }
  }
  return true;
}

export function zeroPoint(): Point {
  return {x: 0, y: 0};
}

export function zeroRect(): Rect {
  return {x: 0, y: 0, width: 0, height: 0};
}

export function copyMap<KeyType, ItemType>(to: Map<KeyType, ItemType>, from: Map<KeyType, ItemType>) {
  from.forEach((item: ItemType, key: KeyType) => {
    to.set(key, item);
  });
}

export function isBrowser(name: BrowserDetect.Browser): boolean {
  if (isTizen) {
    // BrowserDetect.detect() (as well as videojs.browser) returns 'safari' for Tizen,
    // which gives false positive if isBrowser('safari') is called
    return false;
  }

  const browser = BrowserDetect.detect();
  if (browser) {
    Log.debug(TAG, 'Browser detected', browser.name);
    return name === browser.name;
  }

  Log.warn(TAG, 'Browser not detected');
  return false;
}

const uniqueIds: Hashmap<number> = {};
/**
 * Helper function that creates a "unique" string identifier.
 * Useful for tracking concrete instances of a component.
 * See Hooks.tsx - useUniqueId
 * @param tag prefix for the identifier
 */
export function generateUniqueId(tag: string): string {
  const id = uniqueIds.hasOwnProperty(tag)
    ? uniqueIds[tag] + 1 >> 0
    : 0;
  uniqueIds[tag] = id;
  return `${tag}_${id}_${Date.now()}`;
}

type IterableOrIterator<T> = Iterable<T> | IterableIterator<T>;

type IterableKey = number | string;
/**
 * ES spec proposal Object.fromEntries polyfill
 */
export function fromEntries<T = any>(entries: IterableOrIterator<readonly [IterableKey, T]>): {[key in IterableKey]: T} {
  const entriesArray = Array.from(entries);
  return entriesArray.reduce((r, [key, value]) => {
    r[key] = value;
    return r;
  }, {} as {[key in IterableKey]: T});
}

const filterOffers = (title: Title): Product[] => {
  const allProducts = flatten(title.contents.map(({products}) => products));
  const isOfferPaymentMethodSupported = (offer: Offer) => mw.customer.paymentMethods.some(({id}) => id === offer.paymentMethodId);
  const hasSupportedOffers = (product: Product) => product.offers.some(isOfferPaymentMethodSupported);
  return allProducts.filter(hasSupportedOffers);
};

export function getRentProducts(title: Title): Product[] {
  return filterOffers(title).filter(product => product.isAvailableToRent);
}

export function getBuyProducts(title: Title): Product[] {
  return filterOffers(title).filter(product => product.isAvailableToBuy);
}

export function canBePurchasedOrRented(title: Title): boolean {
  return filterOffers(title).some(product => product.isAvailableToBuy || product.isAvailableToRent);
}

/**
 * Calls generator.next() until it's done and aggregates results in one array.
 */
export async function fetchWholeGeneratorContent<T>(generator: AsyncIterableIterator<T[]>): Promise<T[]> {
  const data: T[] = [];
  while (true) {
    const {done, value} = await generator.next();
    const newData: T[] = value ?? [];
    data.push(...newData);
    if (done) {
      return data;
    }
  }
}

export const formatRewindSpeed = (t: i18next.TFunction, delta: number): string => {
  const speed = Math.abs(delta);
  if (speed === 0) {
    return '';
  }
  const duration = moment.duration(speed, 'seconds');
  const parts: string[] = [];
  if (duration.hours() > 0) {
    parts.push(`h[${t('time.h')}]`);
  }
  if (duration.minutes() > 0) {
    parts.push(`m[${t('time.min')}]`);
  }
  if (duration.seconds() > 0) {
    parts.push(`s[${t('time.s')}]`);
  }
  return duration.format(parts.join(' '));
};

export function getRewindTooltipsProps(t: i18next.TFunction, delta: number) {
  const speed = formatRewindSpeed(t, delta);
  return {
    fastForward: {
      visible: delta > 0,
      text: t('mediaPlayer.forward', {speed})
    },
    fastBackwards: {
      visible: delta < 0,
      text: t('mediaPlayer.backwards', {speed})
    }
  };
}

export const getSportTeamsInfo = (media: Media): string | undefined => {
  if (!mw.configuration.isSportTeamsSupported()) {
    return;
  }
  return getMediaSubtypes(media).episode?.title;
};

export function isRemoteAsset(url: string): boolean {
  const remoteAssetRegExp = /^https?:\/\//i;
  return remoteAssetRegExp.test(url);
}

export function compareObjectsKeys<T>(a: T, b: T, ...keys: (keyof T)[]) {
  return keys.every(key => a[key] === b[key]);
}

export function getMetadataItems(
  itemKeys: ('ratingsValue' | 'ratings' | 'episodeInfo' | 'genres' | 'productionYear' | 'duration')[],
  t: i18next.TFunction, metadata: Metadata, media?: Media): string[] {
  const items: string[] = [];

  itemKeys.forEach(item => {
    switch (item) {
      // get ratings from value
      case 'ratingsValue':
        const ratingsValue = media?.pcRatings[0]?.value;
        if (ratingsValue) {
          items.push(ratingsValue);
        }
        break;
      // get ratings only for PC enabled profiles
      case 'ratings':
        const ratings = media ? getRatingToDisplay(media) : undefined;
        if (ratings) {
          items.push(ratings);
        }
        break;
      case 'episodeInfo':
        const episodeInfo = media ? getEpisodeAndSeasonNumber(media, {showSeason: false}) : undefined;
        if (episodeInfo) {
          items.push(episodeInfo);
        }
        break;
      case 'genres':
        if (metadata.genres.length > 0) {
          items.push(formatGenres(metadata.genres));
        }
        break;
      case 'productionYear':
        if (metadata.productionYear > 0) {
          items.push(metadata.productionYear.toString());
        }
        break;
      case 'duration':
        items.push(formatDuration(metadata.duration));
        break;
    }
  });
  return items.filter(isTruthy);
}

export function isSeriesEpisode(title: Title): boolean {
  if (title.episode) {
    const episode = title.episode;
    if (!episode.seasonId || !episode.seriesId) {
      return false;
    }
    return !!episode.seasonName || !!episode.seriesName || episode.number != null || !!episode.seasonNumber != null;
  }
  return false;
}
