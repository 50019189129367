import {useContext, useCallback} from 'react';

import {AppRoutes} from 'common/constants';

import {MediaType, Media, ChromecastConnectionState, ContentType, RecordingType} from 'mw/api/Metadata';
import {nxffConfig} from 'mw/api/NXFF';
import {boProxy} from 'mw/bo-proxy/BOProxy';
import {mw} from 'mw/MW';

import {ChromecastContext} from 'components/ChromecastContext';
import {useNavigation, useScreenInfo} from 'hooks/Hooks';

import {ChromecastMediaType, CustomData, NativeConnectionState, ChromecastConnectedDevice, NativeConnectedDevice} from './ChromecastInterface';

export function mapMediaTypeToChromecastMediaType(mediaType: MediaType): ChromecastMediaType {
  switch (mediaType) {
    case MediaType.Event:
      return ChromecastMediaType.TSTV;
    case MediaType.Title:
      return ChromecastMediaType.VOD;
    case MediaType.Recording:
      return ChromecastMediaType.NPVR;
    case MediaType.Channel:
      return ChromecastMediaType.LIVE;
    default:
      return ChromecastMediaType.UNKNOWN;
  }
}

export async function mediaToCustomData(media: Media): Promise<CustomData> {
  const mediaType = mapMediaTypeToChromecastMediaType(media.getType());
  const customData: CustomData = {
    NXFFConfig: {
      profile: nxffConfig.getProfileName(),
      backoffice: mw.bo.getBackOfficeCode()
    },
    BOConfig: {
      BOURL: await boProxy.sso.getBoUrl()
    },
    SSOConfig: {
      BOToken: await boProxy.sso.getToken(),
      widevineURL: nxffConfig.getConfig().DRM.WidevineLicenseURL
    },
    customer: {
      id: boProxy.sso.getIdentity().id || '',
      simSubRegionId: mw.customer.regionId || '',
      deviceId: mw.customer.currentDevice?.id || '',
      profileId: mw.customer.currentProfile?.id || ''
    },
    media: {
      id: media.id,
      type: mediaType
      //TODO: CL-5004 support live playback
    }
  };
  return customData;
}

export const mapToChromecastConnectionState = (connectionState: NativeConnectionState): ChromecastConnectionState => {
  switch (connectionState) {
    case NativeConnectionState.Connecting:
      return ChromecastConnectionState.Connecting;
    case NativeConnectionState.Connected:
      return ChromecastConnectionState.Connected;
    case NativeConnectionState.Disconnecting:
    default:
      return ChromecastConnectionState.Disconnected;
  }
};

export const getNativeConnectionDetails = async (
  getDetailsFunc: () => Promise<any>
): Promise<ChromecastConnectedDevice> => {
  const connectedDevice: NativeConnectedDevice = await getDetailsFunc();
  const connectionState = mapToChromecastConnectionState(connectedDevice.connectionState);
  return {
    connectionState,
    device: connectionState === ChromecastConnectionState.Disconnected ?
      null : {
        id: connectedDevice.id,
        name: connectedDevice.name
      }
  };
};

export function mapMediaTypeToContentType(mediaType: MediaType): ContentType {
  //TODO: CL-5004 add support for live.
  switch (mediaType) {
    case MediaType.Event:
      return ContentType.TSTV;
    case MediaType.Recording:
      return ContentType.NPVR;
    case MediaType.Title:
    default:
      return ContentType.VOD;
  }
}

export function isChromecastDisconnected(connectionState: ChromecastConnectionState): boolean {
  return connectionState === ChromecastConnectionState.Disconnected;
}

export function useChromecastTvScreenControl(): {castControlEnabled: boolean, castControlHandler: () => void} {
  const {
    connectionState,
    setMediaContext,
    availableDevices,
    showDeviceListPopup,
    showDisconnectPopup,
    castAfterConnecting
  } = useContext(ChromecastContext);
  const {orientation} = useScreenInfo();
  const navigation = useNavigation();

  const castControlHandler = useCallback(() => {
    const media = mw.players.main.getCurrentMedia();
    if (!media) {
      return;
    }
    navigation.push(AppRoutes.MediaDetail, {
      mediaId: media.id,
      mediaType: media.getType()
    });
    setMediaContext(media);
    castAfterConnecting(mw.players.main.getParameters().position ?? 0);
    isChromecastDisconnected(connectionState) ? showDeviceListPopup() : showDisconnectPopup();
  }, [navigation, setMediaContext, castAfterConnecting, connectionState, showDeviceListPopup, showDisconnectPopup]);

  return {
    castControlEnabled: orientation.isLandscape && isChromecastDisconnected(connectionState) && !!availableDevices.length
      && mw.players.main.contentType === ContentType.TSTV,
    castControlHandler: castControlHandler
  };
}

export async function getMediaByChromecastMedia(id: string, type: ChromecastMediaType): Promise<Media | undefined> {
  switch (type) {
    case ChromecastMediaType.LIVE:
      return mw.catalog.getChannelById(id);
    case ChromecastMediaType.VOD:
      return await mw.catalog.getTitleById(id);
    case ChromecastMediaType.NPVR:
      return (await boProxy.bo.getRecordings({id: id, type: RecordingType.Single}))?.[0];
    case ChromecastMediaType.TSTV:
      return await mw.catalog.getEventById(id);
    default:
      return undefined;
  }
}
