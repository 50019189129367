import React, {createRef} from 'react';
import {NavigationEventSubscription, withNavigation} from 'react-navigation';

import {throttle, disposableCallback, debounce} from 'common/Async';
import {Direction, playerOverlayConfig, isWeb, AppRoutes} from 'common/constants';
import {shallowEqual} from 'common/HelperFunctions';
import {NavigationFocusState} from 'common/HelperTypes';
import {Log} from 'common/Log';
import TestContext, {TestContextProps} from 'common/TestContext';

import {Channel, Event, Media, isEvent, isChannel, PlaybackLimitations, ContentType} from 'mw/api/Metadata';
import {PlayerEvent} from 'mw/api/PlayerEvent';
import {mw, CatalogEvent} from 'mw/MW';
import {PlaybackParameters, PlaybackRequestParameters} from 'mw/playback/Player';

import {ModalVisibilityReporter} from 'components/Modal';
import {withParentalControl} from 'components/parentalControl/ParentalControlProvider';
import {PlaybackControlsVisibility, PlayerControlsRecordDotState} from 'components/player/PlayerControlsView';
import PlayerLanguageController from 'components/player/PlayerLanguageController';
import {PlayerTracksInfo} from 'components/player/PlayerLanguageEventHandler';
import {TuneRequestState, TuneRequest, PlayerManager} from 'components/player/PlayerManager';
import {withRecord} from 'components/pvr/Record';

import TvScreenPiccolo from './TvScreen.piccolo';
import {isLivePosition, getRecordingDotState} from './TvScreenHelperFunctions';
import {Tune, TvPlaybackControlsHandlers, TvControlsViewButtonsVisibility, TuneParams, TvScreenComponentProps, TvScreenNavigationParams} from './TvScreenHelperTypes';

const TAG = 'TvScreen';

const tvPlaybackControlsHandlers: TvPlaybackControlsHandlers = {
  restartHandler: () => Log.debug(TAG, 'restartHandler: fallback'),
  skipBackHandler: () => Log.debug(TAG, 'skipBackHandler: Unhandled action'),
  fastBackwardsHandler: () => Log.debug(TAG, 'fastBackwardsHandler: Unhandled action'),
  skipForwardHandler: () => Log.debug(TAG, 'skipForwardHandler: Unhandled action'),
  fastForwardHandler: () => Log.debug(TAG, 'fastForwardHandler: Unhandled action'),
  playPauseHandler: () => Log.debug(TAG, 'playPauseHandler: Unhandled action'),
  goToLiveHandler: () => Log.debug(TAG, 'goToLiveHandler: not implemented'),
  subtitleHandler: () => Log.debug(TAG, 'subtitleHandler: not implemented'),
  recordDotHandler: () => Log.debug(TAG, 'recordDotHandler: not implemented')
};

const playerOverlayVisibilityChangesThrottle = 1000;

type State = {
  overlayVisible: boolean;
  currentChannel?: Channel;
  channels: Channel[];
  channelsLoading: boolean;
  focusState: NavigationFocusState;
  paused: boolean;
  tvControlsViewButtonsVisibility: TvControlsViewButtonsVisibility;
  playbackControlsVisibility: PlaybackControlsVisibility;
  tunedEvent?: Event;
  tunedByRoute?: AppRoutes;
}

export type CurrentMedia = {
  media?: Media | null;
  authorized: boolean;
  position?: number;
}

export enum ViewMode {
  ChannelsGrid,
  OneChannelEpg,
  Epg
}

const testIdContext: TestContextProps = {
  Modal: 'modal_more_actions'
};

class TvScreen extends React.PureComponent<TvScreenNavigationParams, State> {
  public static navigationOptions = {hiddenMainMenu: true};
  public subscriptionDidFocus?: NavigationEventSubscription;
  public subscriptionDidBlur?: NavigationEventSubscription;
  public overlayTimerId = 0;
  public tvPlaybackControlsHandlers: TvPlaybackControlsHandlers;
  private playerLanguageController = createRef<PlayerLanguageController>();
  private player = mw.players.main;
  private _preventHidingOverlay = false;
  private mediaDetailsFocused = false;
  private pendingTuneFor?: Media;

  public constructor(props: TvScreenNavigationParams) {
    super(props);
    this.state = {
      overlayVisible: false,
      currentChannel: this.initialChannel(),
      channels: [],
      channelsLoading: true,
      focusState: this.props.navigation.isFocused() ? NavigationFocusState.IsFocused : NavigationFocusState.IsBlurred,
      paused: (mw.players.main.getParameters().playRate === 0),
      tvControlsViewButtonsVisibility: {
        recordDot: null
      },
      // TODO: CL-1355 Use MW's trick play limitations to determine playback controls visibility
      playbackControlsVisibility: {
        restart: false,
        fastBackwards: false,
        skipBack: false,
        pausePlay: true,
        skipForward: false,
        goToLive: false,
        subtitles: false,
        fastForward: false
      }
    };
    this.tvPlaybackControlsHandlers = {
      ...tvPlaybackControlsHandlers,
      restartHandler: this.restartHandler,
      skipBackHandler: this.skipBackHandler,
      skipForwardHandler: this.skipForwardHandler,
      playPauseHandler: this.playPauseHandler,
      subtitleHandler: this.subtitleHandler,
      recordDotHandler: this.recordDotHandler,
      goToLiveHandler: this.goToLiveHandler
    };
  }

  public componentDidMount() {
    this.loadData();
    mw.catalog.on(CatalogEvent.ChannelsListRefreshed, this.loadData);
    mw.players.main.on(PlayerEvent.PlaybackLimitationsChanged, this.updatePlaybackControls);
    mw.players.main.on(PlayerEvent.ParametersChanged, this.updatePlayerControlsState);
    mw.players.main.on(PlayerEvent.MediaUpdate, this.updateTunedEvent);
    const navigation = this.props.navigation;
    this.subscriptionDidFocus = navigation.addListener('didFocus', () => this.onFocusStateChange(NavigationFocusState.IsFocused));
    this.subscriptionDidBlur = navigation.addListener('didBlur', () => this.onFocusStateChange(NavigationFocusState.IsBlurred));
  }

  public componentWillUnmount() {
    this.debounceTunePlayer.abort();
    this.changePlayerOverlayVisibility.dispose();
    mw.catalog.off(CatalogEvent.ChannelsListRefreshed, this.loadData);
    mw.players.main.off(PlayerEvent.PlaybackLimitationsChanged, this.updatePlaybackControls);
    mw.players.main.off(PlayerEvent.ParametersChanged, this.updatePlayerControlsState);
    mw.players.main.off(PlayerEvent.MediaUpdate, this.updateTunedEvent);
    this.subscriptionDidFocus && this.subscriptionDidFocus.remove();
    this.subscriptionDidBlur && this.subscriptionDidBlur.remove();
    this.stopTimer();
  }

  public onFocusStateChange = (focusState: NavigationFocusState) => {
    this.setState({focusState});
    // Clear timeout on web when we lost focus and we have already started timer
    if (isWeb && focusState === NavigationFocusState.IsBlurred) {
      this.setState({overlayVisible: false});
      this.stopTimer();
    }
    if (focusState !== NavigationFocusState.IsFocused) {
      this.pendingTuneFor = undefined;
    }
  };

  private changePlayerOverlayVisibility = disposableCallback(overlayVisible => {
    this.setState({overlayVisible});
  });

  private get preventHidingOverlay() {
    return this._preventHidingOverlay;
  }

  private set preventHidingOverlay(value: boolean) {
    if (this.preventHidingOverlay && !value && this.state.overlayVisible) {
      this.scheduleHidingPlayerOverlay();
    }
    this._preventHidingOverlay = value;
  }

  private scheduleHidingPlayerOverlay = () => {
    this.stopTimer();
    this.overlayTimerId = setTimeout(() => {
      this.overlayTimerId = 0;
      if (this.preventHidingOverlay || this.state.paused || this.mediaDetailsFocused) {
        this.scheduleHidingPlayerOverlay();
      } else if (!this.state.paused) {
        this.changePlayerOverlayVisibility(false);
      }
    }, playerOverlayConfig.hideTimeout * 1000);
  };

  public stopTimer = () => {
    if (this.overlayTimerId) {
      clearTimeout(this.overlayTimerId);
      this.overlayTimerId = 0;
    }
  };

  public preventHideOverlay = (value: boolean) => {
    this.preventHidingOverlay = value;
  }

  public showPlayerOverlay = () => {
    if (!this.overlayTimerId) {
      // postponing this allows other key event handlers to know if overlay was visible when action was triggered
      requestAnimationFrame(this.changePlayerOverlayVisibility.bind(this, true));
    }
    this.scheduleHidingPlayerOverlay();
  };

  public throttledShowPlayerOverlay = throttle(this.showPlayerOverlay, playerOverlayVisibilityChangesThrottle);

  public hidePlayerOverlay = throttle(() => {
    this.stopTimer();
    this.changePlayerOverlayVisibility(false);
  }, playerOverlayVisibilityChangesThrottle);

  public loadData = () => {
    Log.debug(TAG, 'Loading new channels list');
    this.setState({channelsLoading: true});
    mw.catalog.getChannels()
      .then((channels: Channel[]) => this.setState({channels, channelsLoading: false}))
      .catch(error => Log.error(TAG, 'Error fetching epg data', error));
  };

  public initialChannel = () => {
    const lastPlayed = mw.players.main.getCurrentMedia();
    return isChannel(lastPlayed) ? lastPlayed : undefined;
  };

  public findChannelByDirection = (direction: Direction): Channel | undefined => {
    if (!this.state.currentChannel) {
      Log.error(TAG, 'onSwipe: currentChannel should not be undefined!');
      return;
    }
    const currentChannelIndex = this.state.channels
      .findIndex((channel: Channel) => this.state.currentChannel?.id === channel.id);
    if (currentChannelIndex === -1) {
      Log.error(TAG, 'onSwipeDown: failed to find channel index');
      return;
    }
    const offset: number = (() => {
      if (direction === Direction.Up) return -1;
      if (direction === Direction.Down) return 1;
      Log.error(TAG, `onSwipe: direction: ${direction} not implemented`);
      return 0;
    })();
    const newIndex = (this.state.channels.length + currentChannelIndex + offset) % this.state.channels.length;
    return this.state.channels[newIndex];
  };

  public onCurrentEventChanged = async (event?: Event) => {
    if (event) {
      this.refreshControlsViewButtonsVisibility(event);
    } else {
      const media = mw.players.main.getCurrentMedia();
      const event = isChannel(media) ? await mw.catalog.getCurrentEvent(media) : media;
      if (isEvent(event)) {
        this.refreshControlsViewButtonsVisibility(event);
      }
    }
  };

  public refreshControlsViewButtonsVisibility = async (event: Event) => {
    const recordDotState = await getRecordingDotState(event, this.props.getRecordActionTypeForEvent);
    const channel = mw.catalog.getChannelById(event.channelId);
    if (channel?.isAvailableByPolicy) {
      if (recordDotState !== this.state.tvControlsViewButtonsVisibility.recordDot) {
        this.setState({
          tvControlsViewButtonsVisibility: {
            recordDot: recordDotState
          }
        });
      }
    } else {
      this.setState({
        tvControlsViewButtonsVisibility: {
          recordDot: recordDotState === PlayerControlsRecordDotState.Record ? null : recordDotState
        }
      });
    }
    const playbackLimitations = mw.players.main.getPlaybackLimitations();
    this.updatePlaybackControls(playbackLimitations);
  }

  private updatePlaybackControls = (playbackLimitations: PlaybackLimitations) => {
    const playbackControlsVisibility = {
      restart: playbackLimitations.allowRestart || false,
      fastBackwards: false, // available only on big screens
      skipBack: playbackLimitations.allowSkipBackward || false,
      pausePlay: playbackLimitations.allowPause || false,
      skipForward: playbackLimitations.allowSkipForward || false,
      fastForward: false, // available only on big screens
      goToLive: playbackLimitations.allowGoToLive || false,
      subtitles: this.state.playbackControlsVisibility.subtitles
    };
    // if visibility params really changed
    if (!shallowEqual(playbackControlsVisibility, this.state.playbackControlsVisibility)) {
      this.setState({playbackControlsVisibility});
    }
  };

  private updatePlayerControlsState = (params: PlaybackParameters) => {
    params.playRate !== undefined && this.setState({paused: params.playRate === 0});
  }

  public tune: Tune = (tuneParams: TuneParams) => {
    if (!tuneParams.media) {
      // Clear tunedEvent to inform child components about initial tuning.
      this.setState({tunedEvent: undefined});
      this.setState({tunedByRoute: undefined});
      this.createInitialTuneParams(tuneParams)
        .then(tuneParams => {
          // Avoid recursion loop.
          if (tuneParams.media) {
            this.tune(tuneParams);
          } else {
            Log.error(TAG, 'Tune failed due to lack of playable media');
          }
        });
      return;
    }

    if (this.state.focusState !== NavigationFocusState.IsFocused && !tuneParams.floating) {
      Log.info(TAG, 'Ignoring out of navigation tune.');
      return;
    }

    this.pendingTuneFor = tuneParams.media;

    // Update current channel and tuned event before start tuning to propagate the change to child components as soon as possible.
    this.updateSettingsForMedia(tuneParams.media);
    this.updateTunedEvent(tuneParams.media);
    this.setState({tunedByRoute: tuneParams.requestedByRoute});
    this.debounceTunePlayer(tuneParams)
      .catch(error => Log.error(TAG, 'Tune failed', error));
  };

  private tunePlayer = async (tuneParams: TuneParams) => {
    if (!tuneParams.media) {
      Log.error(TAG, 'Tune failed due to lack of playable media');
      return;
    }
    if (this.state.focusState !== NavigationFocusState.IsFocused && !tuneParams.floating) {
      Log.info(TAG, 'Ignoring out of navigation tune.');
      return;
    }
    const media: Media = tuneParams.media;
    if (media !== this.pendingTuneFor) {
      Log.info(TAG, `Ignoring tune for media ${media}, pending tune has changed.`);
      return;
    }
    this.pendingTuneFor = undefined;

    const parameters: PlaybackRequestParameters = {
      playRate: 1,
      ...tuneParams.params
    };

    const tuneCallback = (state: TuneRequestState, tuneRequest: TuneRequest) => {
      switch (state) {
        case TuneRequestState.Accepted:
          this.stopTimer();
          this.changePlayerOverlayVisibility(true);
          break;
        case TuneRequestState.Completed:
          // showPlayerOverlay is called here instead of hidePlayerOverlay to prevent "flashing" player overlay when
          // tune request is completed quickly
          this.showPlayerOverlay();
          break;
      }

      if (this.state.focusState === NavigationFocusState.IsFocused || tuneParams.floating) {
        tuneParams.callback?.(state, tuneRequest);
      }
      if (state === TuneRequestState.Completed) {
        this.setState({paused: mw.players.main.getParameters().playRate === 0});
      }
    };

    PlayerManager.getInstance().tune(media, tuneParams.playerView, parameters, tuneParams.forceTuneAttempt, tuneCallback);
  };

  private updateTunedEvent = (media: Media) => {
    if (isChannel(media)) {
      mw.catalog
        .getCurrentEvent(media)
        .then(currentEvent => this.setState({tunedEvent: currentEvent}))
        .catch(error => Log.error(TAG, 'Error fetching current event:', error));
    } else {
      this.setState({tunedEvent: media as Event});
    }
  }

  // Adding small delay before start tuning gives UI some time to update and improves its responsiveness.
  private debounceTunePlayer = debounce(this.tunePlayer, 500);

  private async createInitialTuneParams(tuneParams: TuneParams): Promise<TuneParams> {
    let media = await this.getInitialPlayableMedia();
    if (!media) {
      media = await this.getLastPlayedChannel();
    }
    return {...tuneParams, media};
  }

  private getInitialPlayableMedia(): Promise<Media | null> {
    const requestedChannelId = this.props.navigation.getParam('channelId');
    const requestedEventId = this.props.navigation.getParam('eventId');
    if (requestedChannelId) {
      const requestedChannel = mw.catalog.getChannelById(requestedChannelId);
      if (requestedChannel) {
        return Promise.resolve(requestedChannel);
      }
      Log.warn(TAG, 'Unable to find requested channel with id', requestedChannelId);
    } else if (requestedEventId) {
      return mw.catalog.getEventById(requestedEventId)
        .catch(error => {
          Log.warn(TAG, 'Unable to find requested event with id', requestedEventId, error);
          return null;
        });
    }
    return Promise.resolve(null);
  }

  private getLastPlayedChannel(): Promise<Channel | null> {
    return mw.catalog
      .getLastPlayedChannel()
      .catch(error => {
        Log.debug(TAG, 'Failed to get last played channel, error', error);
        return null;
      });
  }

  private updateSettingsForMedia = (media: Media) => {
    if (isEvent(media)) {
      const channel = this.state.channels.find(({id}: Channel) => id === media.channelId);
      if (!channel) {
        Log.warn(TAG, 'Failed to find current channel, channelId: ' + media.channelId);
      }
      this.setState({currentChannel: channel});
      return;
    }
    if (isChannel(media)) {
      this.setState({currentChannel: media});
      return;
    }

    Log.warn(TAG, 'Unsupported type of media passed to updateSettingsForMedia' + media.getType());
  };

  // FIXME: CL-2055 use playRate notification from mw to determine paused state
  public playPauseHandler = () => {
    const player = mw.players.main;
    const playbackParameters: PlaybackParameters = player.getParameters();
    const playRateState = !!playbackParameters.playRate;
    const playRate = playRateState ? 0 : 1;
    this.setState({paused: !!playbackParameters.playRate});
    this.throttledShowPlayerOverlay();

    player
      .changeParameters({playRate})
      .then((response: object) => Log.debug(TAG, 'playPauseHandler: promise result:', response))
      .catch((error: object) => Log.debug(TAG, 'playPauseHandler: promise reject:', error));
  };

  public recordDotHandler = async (recordDotState: PlayerControlsRecordDotState) => {
    switch (recordDotState) {
      case PlayerControlsRecordDotState.Record:
        this.scheduleRecording();
        break;
      case PlayerControlsRecordDotState.IsNowRecorded:
        // Currently IsNowRecorded is only used as recording indicator.
        // Soon there will be new design and every record state will be handled
        break;
    }
  };

  private scheduleRecording = async () => {
    const media = mw.players.main.getCurrentMedia();
    if (!media || (!isEvent(media) && !isChannel(media))) {
      Log.warn(TAG, 'Current media is not a channel or event');
      return;
    }
    let event: Event | undefined;

    if (isChannel(media)) {
      const channel = media as Channel;
      try {
        event = await mw.catalog.getCurrentEvent(channel);
      } catch (error) {
        Log.warn(TAG, `Failed to find current event on channel ${channel} - got error:`, error);
        return;
      }
      Log.debug(TAG, `Found current event ${event} on channel ${channel}`);
    } else if (isEvent(media)) {
      event = media;
    }

    if (event) {
      try {
        await this.props.scheduleRecording(event);
        this.refreshControlsViewButtonsVisibility(event);
      } catch (error) {
        Log.error(TAG, 'Failed to schedule a new recording for event:', event);
      }
    }
  };

  private goToLiveHandler = (tuneParams: TuneParams) => {
    const player = mw.players.main;
    const contentType = player.contentType;
    const parameters = player.getParameters();
    switch (contentType) {
      case ContentType.LIVE:
        Log.warn(TAG, 'Cannot go to live - Current media is not an event');
        return;
      case ContentType.NPLTV:
        if (!parameters.position) {
          Log.warn(TAG, 'Cannot go to live - no position to set');
          return;
        }
        player
          .changeParameters({position: parameters.endPosition, playRate: 1})
          .then((response: object) => Log.debug(TAG, 'goToLiveHandler: changeParameters succeed', response, contentType))
          .catch((error: object) => Log.error(TAG, 'goToLiveHandler: changeParameters promise rejected', error, contentType));
        break;
      case ContentType.TSTV:
        const media = player.getCurrentMedia();
        if (!isEvent(media)) {
          Log.warn(TAG, 'Cannot go to live - Current media is not an event');
          return;
        }
        const channel = mw.catalog.getChannelById(media.channelId);
        if (!channel) {
          Log.warn(TAG, 'Cannot go to live - Failed to find a channel for event', media);
          return;
        }
        this.tune({
          ...tuneParams,
          media: channel
        });
        break;
    }
  };

  private restartHandler = async (tuneParams: TuneParams) => {
    const media = mw.players.main.getCurrentMedia();
    if (!media) {
      Log.warn(TAG, 'Could not restart - Current media is null');
      return;
    }
    const event = isChannel(media) ? await mw.catalog.getCurrentEvent(media) : media;
    this.tune({
      ...tuneParams,
      media: event
    });
  };

  private skip(delta: number) {
    const player = mw.players.main;
    const contentType = player.contentType;
    player
      .changeParameters({skip: delta})
      .then((response: object) => Log.debug(TAG, `skipHandler(${delta}): promise result:`, response, contentType))
      .catch((error: object) => Log.error(TAG, `skipHandler(${delta}): changeParameters promise reject:`, error, contentType));
    this.throttledShowPlayerOverlay();
  }

  private skipBackHandler = () => {
    this.skip(-mw.configuration.playbackSkip);
  };

  private skipForwardHandler = (tuneParams: TuneParams): void => {
    const player = mw.players.main;
    const contentType = player.contentType;

    const playbackParameters = player.getParameters();
    const playerPosition = playbackParameters.position;
    if (typeof playerPosition !== 'number') {
      Log.warn(TAG, 'skipHandler: Cannot skip - no player position');
      return;
    }

    if (contentType === ContentType.TSTV) {
      const position = +playerPosition + mw.configuration.playbackSkip;
      const media: Media | null = player.getCurrentMedia();
      const goToLive = isEvent(media) && isLivePosition(media, position);

      if (goToLive) {
        Log.debug(TAG, 'skipForwardHandler: time is near live - tune to live');
        this.goToLiveHandler(tuneParams);
        return;
      }
    }

    this.skip(mw.configuration.playbackSkip);
  };

  private onPlayerTracksChanged = (playerTracksInfo: PlayerTracksInfo) => {
    const hasAudioOrSubtitleTracks = playerTracksInfo.hasAudioOrSubtitleTracks();
    if (hasAudioOrSubtitleTracks !== this.state.playbackControlsVisibility.subtitles) {
      this.setState(prevState => ({
        playbackControlsVisibility: {...prevState.playbackControlsVisibility, subtitles: hasAudioOrSubtitleTracks}
      }));
    }
  };

  private subtitleHandler = () => {
    this.playerLanguageController.current?.showLanguageSelectionPopup();
  };

  private onModalVisibilityChange = (value: boolean) => {
    this.preventHidingOverlay = value;
  };

  private setMediaDetailsFocused = (value: boolean) => {
    this.mediaDetailsFocused = value;
  };

  public render() {
    const props: TvScreenComponentProps = Object.assign({
      player: this.player,
      overlayVisible: this.state.overlayVisible,
      currentChannel: this.state.currentChannel,
      channels: this.state.channels,
      channelsLoading: this.state.channelsLoading,
      focusState: this.state.focusState,
      paused: this.state.paused,
      playbackControlsVisibility: this.state.playbackControlsVisibility,
      tvPlaybackControlsHandlers: this.tvPlaybackControlsHandlers,
      tvControlsViewButtonsVisibility: this.state.tvControlsViewButtonsVisibility,
      refreshControlsViewButtonsVisibility: this.refreshControlsViewButtonsVisibility,
      tune: this.tune,
      showPlayerOverlay: this.throttledShowPlayerOverlay,
      hidePlayerOverlay: this.hidePlayerOverlay,
      stopOverlayTimer: this.stopTimer,
      preventHideOverlay: this.preventHideOverlay,
      onFocusMediaChanged: this.setMediaDetailsFocused,
      findChannelByDirection: this.findChannelByDirection,
      onCurrentEventChanged: this.onCurrentEventChanged,
      tunedEvent: this.state.tunedEvent,
      tunedByRoute: this.state.tunedByRoute
    }, this.props);

    return (
      <TestContext.Provider value={testIdContext}>
        <ModalVisibilityReporter onModalVisibilityChange={this.onModalVisibilityChange}>
          <TvScreenPiccolo {...props} />
          <PlayerLanguageController ref={this.playerLanguageController} player={this.player} onTracksChanged={this.onPlayerTracksChanged} />
        </ModalVisibilityReporter>
        {this.props.renderRecordingsComponents()}
      </TestContext.Provider>
    );
  }
}

export default withParentalControl(withNavigation(withRecord(TvScreen)));
