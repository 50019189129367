import {createStyles} from 'common-styles';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import {NavigationScreenProps} from 'react-navigation';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';

import {mw} from 'mw/MW';

import {IconType} from 'components/Icon';
import {useProfileProperties} from 'hooks/Hooks';
import SettingsChangePurchasePIN from 'screens/settings/SettingsChangePurchasePIN';
import SettingsDetails from 'screens/settings/SettingsDetails';
import {SettingsDetailsHeader} from 'screens/settings/SettingsDetailsHeader';
import SettingsSimpleOption from 'screens/settings/SettingsSimpleOption';

const stylesUpdater = new StylesUpdater(() => createStyles({
  container: {
    paddingTop: dimensions.screen.settings.container.paddingTop,
    paddingHorizontal: dimensions.screen.container.paddingHorizontal
  }
}));

const SettingsCustomerScreen: React.FC<NavigationScreenProps<{}>> = props => {
  const {t} = useTranslation();
  const styles = stylesUpdater.getStyles();
  const profileProperties = useProfileProperties();
  const isAdultProfile = profileProperties ? !profileProperties.isPCEnabled : false;
  return (
    <SettingsDetails
      title={t('settings.customer')}
      barText={t('settings.customerOptions')}
      header={<SettingsDetailsHeader icon={IconType.CustomerBig} />}
      navigation={props.navigation}
      testID='screen_settings_customer'
    >
      <View style={styles.container}>
        <SettingsSimpleOption title={t('settings.customerId')} value={mw.customer.externalId || t('settings.customerIdNotFound')} />
        {isAdultProfile && mw.configuration.isPurchasePinSupported && <SettingsChangePurchasePIN />}
      </View>
    </SettingsDetails>
  );
};

export default SettingsCustomerScreen;
