import React, {RefObject} from 'react';
import {TouchableOpacity, NativeMethodsMixinStatic} from 'react-native';

import {Direction} from 'common/constants';
import {ChangeEvent, Point, Rect} from 'common/HelperTypes';

import {FocusParentNode} from './FocusParentNode';

export type OrthogonalDirection = 'vertical' | 'horizontal';
export type NodeGeometry = Rect & {center: Point};
export type Measurable = React.Component & Pick<NativeMethodsMixinStatic, 'measure'>;

export type FocusLeafNode = RefObject<FocusableComponent>;
export type FocusNode = FocusParentNode | FocusLeafNode;

export type FocusNodeProps = {
  focusParentId: number;
  priority: number;
  freezeFocus?: boolean;
  exitEdges?: Direction[];
  geometry: NodeGeometry | null;
  debugName?: string;
};

export type FocusNodePropsComplete = {
  focusParentId: number;
  priority: number;
  geometry: NodeGeometry;
};

export type FocusNodesMap = Map<FocusNode, FocusNodeProps>;
export type CompleteFocusNodesMap = Map<FocusNode, FocusNodePropsComplete>;

export type FocusableComponentProps = {
  scrollOnFocus?: boolean;
  focusPriority?: number;
  debugName?: string;
};

export type FocusableComponent = (TouchableOpacity) & React.Component<FocusableComponentProps> & NativeMethodsMixinStatic & FocusableComponentHelperMethods;

export type FocusableElement = {
  focus?: (options?: FocusOptions) => void;
}
export type BlurrableElement = {
  blur?: () => void;
}
export type ControlledFocusableElement = Element & FocusableElement & BlurrableElement;
type FocusableComponentHelperMethods = {
  highlightAsNext: (direction: Direction) => void;
}

export function isFocusParentNode(node: any): node is FocusParentNode {
  return node instanceof FocusParentNode;
}

export function isFocusLeafNode(node: FocusNode | null): node is FocusLeafNode {
  return node !== null && !isFocusParentNode(node);
}

export type EnterStrategy = 'topLeft' | 'byPriority';

export type FocusResult = {success: boolean};

export enum FocusManagerEvent {
  FocusChange = 'FocusChange'
}

export type FocusEventPayload = ChangeEvent<FocusLeafNode | null>;
