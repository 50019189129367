import React, {useEffect, useMemo} from 'react';
import {View, Animated, StyleProp, ViewStyle, StyleSheet} from 'react-native';

import {commonAnimationTransforms} from 'common/constants';
import {AnimatedStyle} from 'common/HelperTypes';

import {useLazyRef} from 'hooks/Hooks';

type FlipperProps = {
  style?: StyleProp<ViewStyle>;
  /**
   * Defaults to true.
   */
  showsFront?: boolean;
  /**
   * Whether to animate the transition when changing side. Defaults to true.
   */
  animated?: boolean;
  /**
   * Callback called on transition animation completion. Called immediately after render caused by changing 'showsFront' when not animated.
   */
  onChangedSide?: (showsFront: boolean) => void;
  /**
   * You must provide 2 children to flipper, one for front side, the other for back side.
   */
  children: [React.ReactNode, React.ReactNode];
}

/**
 * Component that displays only one of its children at a time depending on 'showsFront' property.
 * Transition between sides can be animated with a 3d flip-like animation.
 */
const Flipper: React.FC<FlipperProps> = ({
  style,
  showsFront = true,
  animated,
  onChangedSide,
  children
}) => {
  const [frontSide, backSide] = children;
  const progress = useLazyRef(() => new Animated.Value(0)).current;

  const opacity = useLazyRef(() => progress.interpolate({
    inputRange: [0, 0.5, 0.51, 1],
    outputRange: [1, 1, 0, 0]
  })).current;

  const rotation = useLazyRef(() => progress.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '180deg'],
    extrapolate: 'clamp'
  })).current;

  const reverseRotation = useLazyRef(() => progress.interpolate({
    inputRange: [0, 1],
    outputRange: ['180deg', '0deg'],
    extrapolate: 'clamp'
  })).current;

  useEffect(() => {
    const toValue = showsFront ? 0 : 1;
    if (!animated) {
      progress.setValue(toValue);
      onChangedSide?.(showsFront);
      return;
    }
    Animated.timing(progress, {
      toValue,
      duration: 300,
      useNativeDriver: true
    }).start(() => onChangedSide?.(showsFront));
  }, [showsFront, progress, animated, onChangedSide]);

  const frontSideStyle: AnimatedStyle<ViewStyle> = useMemo(() => ({
    ...StyleSheet.absoluteFillObject,
    flex: 1,
    opacity: opacity,
    transform: [
      {rotateY: rotation},
      ...commonAnimationTransforms
    ]
  }), [rotation, opacity]);

  const backSideStyle: AnimatedStyle<ViewStyle> = useMemo(() => ({
    ...StyleSheet.absoluteFillObject,
    flex: 1,
    opacity: Animated.subtract(1, opacity),
    transform: [
      {rotateY: reverseRotation},
      {perspective: -1000}
    ]
  }), [reverseRotation, opacity]);

  return (
    <View style={style}>
      <Animated.View style={frontSideStyle}>
        {frontSide}
      </Animated.View>
      <Animated.View style={backSideStyle}>
        {backSide}
      </Animated.View>
    </View>
  );
};

export default React.memo(Flipper);
