import {createStyles} from 'common-styles';
import React, {useCallback} from 'react';
import {ListRenderItemInfo, ViewStyle, ScrollViewProps, View} from 'react-native';

import {indexKeyExtractor} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {Recording} from 'mw/api/Metadata';

import {useChromecastExtraBottomPadding} from 'components/chromecast/ChromecastExtraBottomPadding';
import {ListShadow, ListShadowPosition} from 'components/ListShadow';
import NitroxFlatList from 'components/NitroxFlatList';
import RecordingTile from 'components/pvr/RecordingTile';
import {Selection} from 'components/utils/Selection';
import {useLazyEffect} from 'hooks/Hooks';

const TAG = 'RecordingsList';

const styles = createStyles({
  container: {
    width: '100%',
    flex: 1
  },
  shadow: {
    bottom: 0
  }
});

type Props = {
  recordings: Recording[];
  isInsideFolder?: boolean;
  style?: ViewStyle;
  selectable?: boolean;
  selection: Selection<Recording>;
  requestRecordings(): Promise<void>;
  onTilePress: (recording: Recording) => void;
  onSelectionCheckboxPress?: (recording: Recording, selected: boolean) => void;
} & ScrollViewProps;

const RecordingsList: React.FC<Props> = props => {
  const {recordings, selectable, selection, requestRecordings, onTilePress, onSelectionCheckboxPress, isInsideFolder} = props;
  const renderItem = useCallback((info: ListRenderItemInfo<Recording>) => {
    return (
      <RecordingTile
        recording={info.item}
        isFolderTile={isInsideFolder}
        onPress={onTilePress}
        selectable={selectable}
        selected={selection.isSelected(info.item)}
        onSelectionCheckboxPress={onSelectionCheckboxPress}
      />
    );
  }, [selectable, selection, onSelectionCheckboxPress, onTilePress, isInsideFolder]);

  useLazyEffect(() => {
    if (recordings.length === 0) {
      Log.debug(TAG, 'Recordings changed - requesting the first page');
      requestRecordings();
    }
  }, [recordings], [requestRecordings]);

  const onEndReached = useCallback(() => {
    Log.debug(TAG, 'We have reached the end of the list - requesting next page');
    requestRecordings();
  }, [requestRecordings]);

  const chromecastExtraBottomPadding = useChromecastExtraBottomPadding();

  return (
    <View style={styles.container}>
      <NitroxFlatList
        style={styles.container}
        contentContainerStyle={chromecastExtraBottomPadding}
        data={recordings}
        renderItem={renderItem}
        keyExtractor={indexKeyExtractor}
        showsVerticalScrollIndicator={false}
        overScrollMode={'never'}
        onEndReached={onEndReached}
        {...props}
      />
      <ListShadow direction={ListShadowPosition.Bottom} style={styles.shadow} />
    </View>
  );
};

export default RecordingsList;
