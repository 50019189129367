import {encode} from 'base-64';

import {Log} from 'common/Log';

import {DeviceManager} from 'mw/api/DeviceManager';
import {Error, ErrorType} from 'mw/api/Error';
import {Content, MediaType} from 'mw/api/Metadata';
import {nxffConfig} from 'mw/api/NXFF';
import {DrmSession, DrmProperties} from 'mw/bo-proxy/SSOInterface';
import {mw} from 'mw/MW';
import {DRMSessionManager, CreateSessionParams} from 'mw/playback/sessions/DRMSessionManager';
import {objectToRequestParams} from 'mw/utils/HttpUtils';
import {createUnsignedJWT} from 'mw/utils/JwsUtils';

import {getFairPlayCertificate} from './getFairPlayCertificate';

export interface PropertiesParams {
  /**
   * Used for Castlabs DRM only.
   */
  assetId?: string;
  specConform?: true;

  drmTicket?: string;
  streamId?: string;
}

export class SeaChangeDRMAdapter implements DRMSessionManager {

  protected fairplayAppCertificate?: Uint8Array | string;

  protected TAG = 'SeaChangeDRMAdapter';

  public initialize(): Promise<void> {
    const drmConfig = nxffConfig.getConfig().DRM;
    const uri = drmConfig.FairPlayCertificateURL + '/' + drmConfig.MerchantId;
    return getFairPlayCertificate(uri)
      .then(cert => {
        this.fairplayAppCertificate = cert;
      })
      .catch(error => {
        Log.error(this.TAG, 'getFairPlayCertificate error:', error);
      });
  }

  protected createDrmProperties(parameters: CreateSessionParams): DrmProperties {
    const params = this.paramsToString(this.getParams(parameters));
    const drmConfig = nxffConfig.getConfig().DRM;
    return {
      widevineLicenseUrl: drmConfig.WidevineLicenseURL + params,
      fairplayLicenseUrl: drmConfig.FairPlayLicenseURL + params,
      fairplayCertificate: this.fairplayAppCertificate,
      managedBy: parameters.session.getCurrentAsset().managedBy
    };
  }

  protected createDrmHeaders(parameters: CreateSessionParams): DrmSession['drmHeaders'] {
    const drmHeaders = {
      'client-attributes': encode(JSON.stringify({
        userId: mw.customer.id,
        sessionId: createUnsignedJWT({
          session: parameters.session.id,
          cpeId: DeviceManager.getInstance().getId()
        })
      })),
      ...parameters.session.getCurrentAsset().drmAsset?.headers
    };

    return {...drmHeaders, ...parameters.session.getCurrentAsset().drmAsset?.headers};
  }

  public async createSession(parameters: CreateSessionParams): Promise<DrmSession> {
    return {
      drmHeaders: this.createDrmHeaders(parameters),
      drmProperties: this.createDrmProperties(parameters)
    };
  }

  protected paramsToString(params: PropertiesParams): string {
    return `?${objectToRequestParams(params)}`;
  }

  protected getParams(params: CreateSessionParams): PropertiesParams {
    let streamId;
    switch (params.media.getType()) {
      case MediaType.Channel:
        streamId = params.media.id;
        break;

      case MediaType.Event:
      case MediaType.Recording:
      case MediaType.Title: {
        const content: Content = params.playable as Content;
        if (!content.externalIds.playbackId) {
          Log.error(this.TAG, 'fail to prepare drm properties: no playbackId');
          throw new Error(ErrorType.NotSupported);
        }
        streamId = content.externalIds.playbackId;
      }
        break;

      case MediaType.Audio:
        throw new Error(ErrorType.DRMNotRequired);

      default:
        throw new Error(ErrorType.NotSupported);
    }

    return {streamId};
  }
}
