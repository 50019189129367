import React, {forwardRef, useCallback, useMemo} from 'react';
import {Image, ImageURISource} from 'react-native';

import {dimensions, isMobile} from 'common/constants';

import {Channel, PictureMode, PictureType} from 'mw/api/Metadata';
import {mw} from 'mw/MW';

import {unavailableChannelStyle} from 'components/ChannelIcon';
import MediaTileBase from 'components/mediaTiles/MediaTileBase';
import {MediaTileBaseProps} from 'components/mediaTiles/MediaTileHooks';
import {usePlayerLauncher} from 'components/playerLauncher/PlayerLauncher';
import {useChannelAvailability} from 'hooks/Hooks';

type Props = {
  channel: Channel;
} & Omit<MediaTileBaseProps, 'media'>

const channelLogoSize = {
  width: Math.round(dimensions.tile.width * dimensions.channelTile.logoWidthRatio),
  height: Math.round(dimensions.tile.height * dimensions.channelTile.logoHeightRatio)
};

const ChannelTile: React.FC<Props> = (props, ref) => {
  const {channel} = props;
  const {startPlayback} = usePlayerLauncher();

  const channelPoster = useMemo(() => ({
    ...channelLogoSize,
    uri: mw.catalog.getPictureUrl(channel, PictureType.Logo, channelLogoSize.width, channelLogoSize.height, PictureMode.BOX)
  } as ImageURISource), [channel]);

  const isChannelAvailable = useChannelAvailability(channel.id);
  const channelPosterStyle = useMemo(() => isChannelAvailable ? {} : unavailableChannelStyle, [isChannelAvailable]);

  const renderMediaPoster = useCallback(() => <Image style={channelPosterStyle} resizeMode='contain' source={channelPoster} />, [channelPoster, channelPosterStyle]);

  const onPress = useCallback(() => {
    startPlayback({tvScreenParams: {channelId: channel.id}});
  }, [channel, startPlayback]);

  return (
    <MediaTileBase
      {...props}
      ref={ref}
      media={channel}
      renderMediaPoster={renderMediaPoster}
      onPress={isMobile ? onPress : undefined}
    />
  );
};

export default React.memo(forwardRef(ChannelTile));
