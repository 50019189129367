import {UIManager} from 'react-native'; //Unfortunately react-native-web lacks typescript support and has different API than react-native

import 'components/focusManager/FocusManager.web.css';
import {awaitEvent} from 'common/Async';
import {Log} from 'common/Log';

import {FocusManagerBase} from './FocusManagerBase';
import {FocusManagerInterface} from './FocusManagerInterface';
import {ControlledFocusableElement, FocusLeafNode, FocusManagerEvent, FocusResult, isFocusParentNode} from './FocusManagerTypes';
import {findDomElement} from './geometry';

const TAG = 'FocusManager';

/**
 * See FocusManager.android.ts.
 */
const forceFocusTimeout = 500;

export class FocusManager extends FocusManagerBase {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected uiManager: any = UIManager; //UIManager in react-native-web, contrary to react-native, does have focus() method

  private constructor() {
    super();

    if (!('focus' in UIManager)) {
      this.cleanup();
      throw new Error('API of UIManager (react-native-web) has changed dramatically! Cannot proceed!');
    }
  }

  public static getInstance(): FocusManagerInterface {
    if (!this.instance) {
      this.instance = new FocusManager();
    }
    return this.instance;
  }

  public forceFocus(leaf: FocusLeafNode): Promise<FocusResult> {
    return this.setFocus(leaf, {preventScroll: true});
  }

  protected async focus(leaf: FocusLeafNode): Promise<FocusResult> {
    if (this.focused === leaf) {
      return {success: true};
    }
    if (!leaf.current) {
      return {success: false};
    }
    const debugName = this.nodes.get(leaf)?.debugName;
    if (debugName) {
      Log.debug(TAG, `Moving focus to ${debugName}`);
    }

    const props = this.getFocusedCurrentProps();

    return this.setFocus(leaf, {preventScroll: props?.scrollOnFocus === false});
  }

  protected async blur() {
    // if currently focused element is FocusParent, that means that physically
    // no element is focused, on focusParent focus, applcation is blurred and focusParent
    // is assigned to this.focused without actually triggering focus on any element
    if (isFocusParentNode(this.focused)) {
      return {success: true};
    }
    if (!this.focused?.current) {
      return {success: false};
    }
    const nodeElement = findDomElement(this.focused.current) as ControlledFocusableElement;
    if (nodeElement?.blur) {
      nodeElement.blur();
    } else {
      this.uiManager.blur(nodeElement);
    }
    try {
      await awaitEvent(FocusManagerEvent.FocusChange, this, focusChange => focusChange?.to == null, forceFocusTimeout);
      Log.debug(TAG, `Focus blur handled on ${this.focused}`);
      return {success: true};
    } catch (error) {
      Log.error(TAG, `Error while awaiting for focus event`, error);
      return {success: false};
    }
  }

  private async setFocus(leaf: FocusLeafNode, {preventScroll}: {preventScroll?: boolean} = {}): Promise<FocusResult> {
    if (!leaf.current) {
      return {success: false};
    }
    const startTime = Date.now();
    const node = findDomElement(leaf.current) as ControlledFocusableElement;
    if (node?.focus) {
      node.focus({preventScroll});
    } else {
      this.uiManager.focus(node);
    }

    try {
      await awaitEvent(FocusManagerEvent.FocusChange, this, focusChange => focusChange?.to === leaf, forceFocusTimeout);
      Log.debug(TAG, `Force focus has been handled in ${Date.now() - startTime} ms.`);
      return {success: true};
    } catch (error) {
      Log.error(TAG, `Error while awaiting for focus event`, error);
      return {success: false};
    }
  }
}
