import {EventEmitter} from 'common/EventEmitter';
import {Log} from 'common/Log';

import {Error, ErrorType} from 'mw/api/Error';
import {Credentials} from 'mw/api/NXFF';
import {Profile} from 'mw/api/Profile';
import {DrmSession, Identity, SSOEvent, SSOInterface} from 'mw/bo-proxy/SSOInterface';
import {AsyncStorageData} from 'mw/platform/async-storage/AsyncStorageInterface';

const TAG = 'RedirectedSSO';

export interface RedirectedSSOParams {
  boToken: string;
  boUrl?: string;
  customerId: string;
  drmSession: DrmSession;
}

export class RedirectedSSO extends EventEmitter<SSOEvent> implements SSOInterface {

  private static instance: RedirectedSSO | null = null;

  private boToken = '';
  private boUrl?: string;
  private customerId = '';
  private drmSession: DrmSession | null = null;

  public static getInstance(): RedirectedSSO {
    if (RedirectedSSO.instance) {
      return RedirectedSSO.instance;
    }

    throw new Error(ErrorType.UnknownError);
  }

  public static setInstance(params: RedirectedSSOParams) {
    RedirectedSSO.instance = new RedirectedSSO(params);
  }

  private sso: SSOInterface | null = null;

  private constructor(params: RedirectedSSOParams) {
    super();

    Log.debug(TAG, 'constructor', params);
    this.boUrl = params.boUrl;
    this.boToken = params.boToken;
    this.drmSession = params.drmSession;
    this.customerId = params.customerId;
  }

  public static reconfigure(sso: SSOInterface): SSOInterface {
    return RedirectedSSO.getInstance().setSSO(sso);
  }

  public setSSO(sso: SSOInterface): SSOInterface {
    this.sso = sso;
    sso.setIdentity(this.customerId);
    return this;
  }

  public setIdentity(id?: string) {
    throw new Error(ErrorType.NotSupported);
  }

  public login(credentials?: Credentials): Promise<void> {
    return Promise.resolve();
  }

  public logout(): Promise<void> {
    this.drmSession = null;
    this.boToken = '';
    this.boUrl = undefined;
    return this.sso ? this.sso.logout() : Promise.resolve();
  }

  public getToken(): Promise<string> {
    if (this.boToken) {
      return Promise.resolve(this.boToken);
    }

    if (!this.sso) {
      throw new Error(ErrorType.SSOUnauthorized);
    }

    return this.sso.getToken();
  }

  public getBoUrl(): Promise<string> {
    if (this.boUrl) {
      return Promise.resolve(this.boUrl);
    }

    if (!this.sso) {
      throw new Error(ErrorType.UnknownError);
    }

    return this.sso.getBoUrl();
  }

  public getIdentity(): Identity {
    if (this.sso) {
      return this.sso.getIdentity();
    }

    throw new Error(ErrorType.UnknownError);
  }

  public getDrmSession(): Promise<DrmSession> {
    if (this.drmSession) {
      return Promise.resolve(this.drmSession);
    }

    throw new Error(ErrorType.UnknownError);
  }

  public isLoggedIn(): boolean {
    return true;
  }

  public getDevicePublicId(): string {
    throw new Error(ErrorType.NotSupported);
  }

  public isPurchasePinSupported() {
    return false;
  }

  public setPurchasePin(pin: string): Promise<void> {
    throw new Error(ErrorType.NotSupported);
  }

  public verifyPurchasePin(pin: string): Promise<void> {
    throw new Error(ErrorType.NotSupported);
  }

  public switchProfile(profile: Profile, pin: string): Promise<void> {
    return Promise.resolve();
  }

  public invalidateToken(authorizationToken: string | null): void {
    // not implemented
  }

  public getCrossLoginData(): Promise<AsyncStorageData[]> {
    return Promise.resolve([]);
  }

  public getMasterPin(): string {
    throw new Error(ErrorType.NotSupported);
  }

  public isMasterPinSupported(): boolean {
    return false;
  }

  public setMasterPin(pin: string): Promise<void> {
    return Promise.reject(new Error(ErrorType.NotSupported));
  }

  public renewToken(): Promise<void> {
    return Promise.reject(new Error(ErrorType.NotSupported));
  }
}
