import {createStyles} from 'common-styles';
import React, {useCallback, useState, forwardRef, RefObject, useEffect, useMemo} from 'react';
import {View, LayoutChangeEvent, Animated} from 'react-native';

import {isMobile, isBigScreen, getValue, epgItemHeight, dimensions, RADIUS} from 'common/constants';
import {Rect, Size} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Channel} from 'mw/api/Metadata';

import {ChannelIcon, ChannelIconType} from 'components/ChannelIcon';
import {DataFetchDirection} from 'components/epg/EpgNitroxContentView';
import {EpgNitroxScrollView, ScrollDirection, LayoutItem, EpgNitroxScrollViewInterface} from 'components/epg/EpgNitroxScrollView';
import NitroxText from 'components/NitroxText';

export const channelsContainerWidth = getValue({mobile: 170, defaultValue: 190});
const channelNumberContainerWidth = 40;
const zeroSize: Size = {width: 0, height: 0};

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  channelsContainer: {
    width: channelsContainerWidth,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  channelListItemContainer: {
    flex: 1,
    flexDirection: 'row'
  },
  channelNumberContainer: {
    flex: 1,
    marginRight: dimensions.margins.xsmall,
    marginVertical: dimensions.margins.xsmall,
    justifyContent: 'center',
    alignItems: 'center',
    width: channelNumberContainerWidth,
    borderTopRightRadius: RADIUS,
    borderBottomRightRadius: RADIUS
  },
  channelIconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    margin: dimensions.margins.xsmall,
    marginRight: dimensions.margins.small
  },
  channelNumberBackgroundActive: {
    backgroundColor: colors.epgScreen.grid.tile.active
  },
  channelNumberBackground: {
    backgroundColor: colors.epgScreen.grid.tile.unfocused
  },
  channelNumber: {
    color: colors.columnTile.title
  }
}));

type Props= {
  channels: Channel[];
  currentChannel: Channel;
  scrollPositionRef: RefObject<Animated.ValueXY>;
  onChannelPress?: (channelId: string) => void;
}

const EpgChannelList: React.FC<Props> = (props: Props, ref: RefObject<EpgNitroxScrollViewInterface<Channel>>) => {
  const {channels, currentChannel, scrollPositionRef, onChannelPress: onChannelPressFromProps} = props;
  const channelsViewRef = useMemo(() => ref ?? React.createRef<EpgNitroxScrollViewInterface<Channel>>(), [ref]);
  const [channelsViewSize, setChannelsViewSize] = useState<Size>();
  const onChannelListLayout = useCallback((event: LayoutChangeEvent) => {
    const layout = event.nativeEvent.layout;
    setChannelsViewSize({width: layout.width, height: layout.height});
  }, []);
  const styles = stylesUpdater.getStyles();

  const layoutItemsForChannelList = useCallback((rect: Rect): Promise<LayoutItem<Channel>[]> => {
    const startIndex = Math.max(0, Math.round(rect.y / epgItemHeight));
    const pastEndIndex = Math.max(0, Math.round((rect.y + rect.height + 1) / epgItemHeight));
    const layoutItems = channels.slice(startIndex, pastEndIndex).map((channel, index): LayoutItem<Channel> => {
      return {
        x: 0,
        y: (startIndex + index) * epgItemHeight,
        width: channelsContainerWidth,
        height: epgItemHeight,
        data: channel
      };
    });
    return Promise.resolve(layoutItems);
  }, [channels]);

  const onChannelPress = useCallback((channelId?: string) => {
    if (!onChannelPressFromProps || !channelId) {
      return;
    }
    onChannelPressFromProps(channelId);
  }, [onChannelPressFromProps]);

  const renderChannelListItem = useCallback((channel: Channel): React.ReactElement => {
    const selected = channel === currentChannel;
    const numberBackgroundStyle = selected ? styles.channelNumberBackgroundActive : styles.channelNumberBackground;
    return (
      <View style={styles.channelListItemContainer}>
        <View style={[styles.channelNumberContainer, numberBackgroundStyle]}>
          <NitroxText textType={'epg-channel-number'} style={styles.channelNumber}>{channel.lcn}</NitroxText>
        </View>
        <View style={styles.channelIconContainer}>
          <ChannelIcon
            type={ChannelIconType.Epg}
            isSelected={selected}
            channelId={channel.id}
            onPress={onChannelPress}
          />
        </View>
      </View>
    );
  }, [currentChannel, styles.channelNumberBackgroundActive, styles.channelNumberBackground, styles.channelListItemContainer, styles.channelNumberContainer, styles.channelNumber, styles.channelIconContainer, onChannelPress]);

  useEffect(() => {
    channelsViewRef.current?.invalidateLayout();
  }, [channelsViewRef, currentChannel]);

  return (
    <EpgNitroxScrollView<Channel>
      ref={ref}
      debugName={'EpgChannelList'}
      style={styles.channelsContainer}
      viewSize={channelsViewSize || zeroSize}
      direction={ScrollDirection.Vertical}
      scrollEnabled={false}
      onLayout={onChannelListLayout}
      layoutItemsForRect={layoutItemsForChannelList}
      renderItem={renderChannelListItem}
      dataFetchDirection={DataFetchDirection.Vertical}
      contentWidth={(channelsViewSize || zeroSize).width}
      showIndicators={false}
      scrollPosition={scrollPositionRef.current}
      controlled={isMobile}
      focusable={!isBigScreen}
      contentHeight={channels.length * epgItemHeight}
      itemHeight={epgItemHeight}
    />
  );
};

export default React.memo(forwardRef(EpgChannelList));
