import {createStyles} from 'common-styles';
import React, {forwardRef, useCallback, useMemo, useImperativeHandle} from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import {dimensions} from 'common/constants';
import {humanCaseToSnakeCase} from 'common/HelperFunctions';
import {FocusOptions, TestProps} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import AspectRatio, {Ratio} from 'components/AspectRatio';
import {Icon, IconType} from 'components/Icon';
import {AnimatedTileInterface} from 'components/mediaTiles/MediaTile';
import NitroxText from 'components/NitroxText';
import TileContainer from 'components/TileContainer';
import {useStaticallyFocused} from 'hooks/Hooks';

const {borderRadius, borderPadding, borderWidth, width, height} = dimensions.tile;

const posterPadding = borderWidth + borderPadding;

const posterRatio = Ratio.RATIO_16_9;
const posterSize = {width: width - (posterPadding * 2), height: 0};
posterSize.height = Math.floor(posterSize.width / posterRatio);

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  content: {
    width,
    height,
    marginTop: 0,
    marginHorizontal: 0
  },
  focusFrame: {
    width: posterSize.width + (posterPadding * 2),
    height: posterSize.height + (posterPadding * 2),
    borderWidth: borderWidth,
    borderRadius: 30,
    padding: borderPadding
  },
  iconContainer: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
    width: posterSize.width,
    height: posterSize.height,
    opacity: dimensions.opacity.xxlow,
    backgroundColor: colors.tile.poster.placeholder.background,
    borderRadius: borderRadius
  },
  descriptionView: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    paddingVertical: dimensions.margins.xsmall,
    paddingLeft: posterPadding
  },
  descriptionContent: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'stretch'
  },
  descriptionTexts: {
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1
  },
  text: {
    color: colors.tile.contentText.unfocused,
    marginBottom: dimensions.margins.xsmall
  },
  iconColor: colors.tile.poster.placeholder.icon,
  emptyDescription: {
    borderRadius: borderRadius,
    width: '60%',
    height: '30%',
    backgroundColor: colors.tile.poster.placeholder.background
  },
  contentFocused: {
    borderColor: colors.tile.background.selected
  },
  contentUnfocused: {
    borderColor: colors.tile.background.default
  }
}));

type Props = {
  label?: string;
  focusable?: boolean;
  style?: ViewStyle;
  onPress: () => void;
  onFocus?: (event: any, options?: FocusOptions) => void;
  scrollOnFocus?: boolean;
  width?: number;
  height?: number;
  empty?: boolean;
  staticallyFocused?: boolean;
} & TestProps;

const StaticTile: React.FunctionComponent<Props> = (props, ref) => {
  const {onFocus: propagateFocus, scrollOnFocus = true, focusable: propsFocusable = true, empty = false, staticallyFocused, onPress} = props;

  const onFocus = useCallback((event: any, options?: FocusOptions) => {
    propagateFocus?.(event, options);
  }, [propagateFocus]);

  const styles = stylesUpdater.getStyles();
  const {onFocusStateChanged, focusable, focused} = useStaticallyFocused(propsFocusable, staticallyFocused);

  /* eslint-disable schange-rules/no-use-imperative-handle-hook */
  useImperativeHandle(ref, () => {
    const handlers: AnimatedTileInterface = {
      onPress: () => {
        if (!focusable && focused) {
          onPress();
        }
      }
    };
    return handlers;
  }, [onPress, focusable, focused]);
  /* eslint-enable schange-rules/no-use-imperative-handle-hook */

  const focusFrameStyle = useMemo(() => {
    return [
      styles.focusFrame,
      focused ? styles.contentFocused : styles.contentUnfocused
    ];
  }, [styles.focusFrame, styles.contentFocused, styles.contentUnfocused, focused]);

  const testID = 'tile_' + humanCaseToSnakeCase(props.label || 'empty');

  return (
    <TileContainer
      ref={ref}
      onFocus={onFocus}
      scrollOnFocus={scrollOnFocus}
      focusable={focusable}
      onPress={props.onPress}
      onFocusStateChanged={onFocusStateChanged}
      style={styles.content}
      testID={testID}
    >
      {empty ? (
        <>
          <View style={focusFrameStyle}>
            <AspectRatio ratio={posterRatio}>
              <View style={styles.iconContainer}></View>
            </AspectRatio>
          </View>
          <View style={styles.descriptionView}>
            <View style={styles.emptyDescription} />
          </View>
        </>
      ) : (
        <>
          <View style={focusFrameStyle}>
            <AspectRatio ratio={posterRatio}>
              <View style={styles.iconContainer}>
                <Icon
                  type={IconType.ShowAll}
                  size={dimensions.tile.iconHeight}
                  color={styles.iconColor}
                />
              </View>
            </AspectRatio>
          </View>
          <View style={styles.descriptionView}>
            <View style={styles.descriptionContent}>
              <View style={styles.descriptionTexts}>
                <NitroxText textType='tile-title' style={styles.text} numberOfLines={1} ellipsizeMode='tail'>
                  {props.label}
                </NitroxText>
              </View>
            </View>
          </View>
        </>
      )}
    </TileContainer>
  );
};

export default React.memo(forwardRef(StaticTile));
