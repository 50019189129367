import {DateUtils} from 'common/DateUtils';
import {Log} from 'common/Log';

import {Error, ErrorType} from 'mw/api/Error';
import {PlaybackAsset} from 'mw/bo-proxy/BOInterface';
import {boProxy} from 'mw/bo-proxy/BOProxy';
import {mw} from 'mw/MW';
import {PlaybackSession, SessionTerminationReason} from 'mw/playback/sessions/PlaybackSessionManager';
import {PlaybackParametersProvider} from 'mw/playback/types/PlaybackParameters';

const TAG = 'PlaybackSession5j';

export class PlaybackSession5j extends PlaybackSession {
  private sessionTimer: number | null = null;
  private playbackParametersProvider: PlaybackParametersProvider | null;
  private isTerminated = false;

  public constructor(id: string, assets: PlaybackAsset[], playbackParametersProvider: PlaybackParametersProvider) {
    super(id, assets);
    this.playbackParametersProvider = playbackParametersProvider;
    const timeout = mw.configuration.playbackSessionKeepAliveInterval;
    if (timeout) {
      this.sessionTimer = setInterval(this.keepAlive, timeout * DateUtils.msInSec);
    }
  }

  public terminate(reason: SessionTerminationReason): Promise<void> {
    if (this.sessionTimer) {
      clearInterval(this.sessionTimer);
      this.sessionTimer = null;
    }
    if (this.isTerminated) {
      Log.warn(TAG, 'keepAlive: session already terminated');
      return Promise.resolve();
    }
    this.isTerminated = true;
    const position = this.playbackParametersProvider ? this.playbackParametersProvider.getPosition() : 0;
    this.playbackParametersProvider = null;
    return boProxy.bo.deletePlaybackSession(this, {
      position,
      reason
    });
  }

  private keepAlive = (): void => {
    if (this.isTerminated) {
      Log.warn(TAG, 'keepAlive: session already terminated');
      if (this.sessionTimer) {
        clearInterval(this.sessionTimer);
        this.sessionTimer = null;
      }
      return;
    }

    boProxy.bo.keepAlivePlaybackSession(this, {
      position: this.playbackParametersProvider ? this.playbackParametersProvider.getPosition() : 0
    })
      .catch((error: Error) => {
        Log.error(TAG, 'keepAlive', error);
        if (error.type === ErrorType.PlaybackSessionExpired) {
          this.terminate(SessionTerminationReason.Other)
            .catch((error: Error) => Log.error(TAG, 'keepAlive terminate session failed', error));
        }
      });
  };
}
