import {createStyles} from 'common-styles';
import React, {useMemo} from 'react';
import {StyleProp, ViewStyle} from 'react-native';

import {dimensions, tabBarInsetCorrection} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {ChromecastConnectionState} from 'mw/api/Metadata';

import {Icon, IconType} from 'components/Icon';
import NitroxInteractive from 'components/NitroxInteractive';

const iconSize = 20;

const staticStyles = createStyles({
  floatingButton: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: dimensions.chromecast.floatingButton.bottom + tabBarInsetCorrection,
    right: dimensions.chromecast.floatingButton.right,
    height: dimensions.buttons.large,
    width: dimensions.buttons.large,
    borderRadius: Math.floor(dimensions.buttons.large / 2)
  }
});

const dynamicStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  iconColor: colors.button.primary.background.focused,
  iconConnectedColor: colors.chromecast.connectedIcon,
  button: {
    backgroundColor: colors.button.secondary.background.focused
  }
}));

const mapConnectionStateToIcon = (connectionState: ChromecastConnectionState): IconType | null => {
  switch (connectionState) {
    case ChromecastConnectionState.Disconnected:
      return IconType.CastDisconnected;
    case ChromecastConnectionState.Connecting:
      return IconType.CastConnecting;
    case ChromecastConnectionState.Connected:
      return IconType.CastConnected;
    default:
      return null;
  }
};

type Props = {
  style?: StyleProp<ViewStyle>;
  onPress: () => void;
  connectionState: ChromecastConnectionState;
};

const ChromecastButton: React.FunctionComponent<Props> = ({connectionState, onPress, style}) => {
  const dynamicStyles = dynamicStylesUpdater.getStyles();
  const styles = useMemo(() => [staticStyles.floatingButton, dynamicStyles.button, style], [dynamicStyles.button, style]);
  const icon = mapConnectionStateToIcon(connectionState);
  const iconColor = icon === IconType.CastConnected ? dynamicStyles.iconConnectedColor : dynamicStyles.iconColor;
  if (!icon) {
    return null;
  }
  return (
    <NitroxInteractive
      debugName='ChromecastButton'
      style={styles}
      onPress={onPress}
      testID='button_chromecast'
      disabled={icon === IconType.CastConnecting}
    >
      <Icon
        type={icon}
        size={iconSize}
        color={iconColor}
      />
    </NitroxInteractive>
  );
};

export default React.memo(ChromecastButton);
