import {createStyles} from 'common-styles';
import React from 'react';
import {StyleSheet, View} from 'react-native';

import {dimensions} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {EventTileType, useEventTileSize} from 'components/zapper/EventTile';

export const epgTileSeparatorHeight = StyleSheet.hairlineWidth;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  separatorContainer: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row',
    paddingHorizontal: dimensions.margins.large
  },
  smallSeparator: {
    height: epgTileSeparatorHeight,
    marginRight: dimensions.margins.small,
    backgroundColor: colors.epgScreen.columnEpg.separator.small
  },
  bigSeparator: {
    flex: 1,
    height: epgTileSeparatorHeight,
    backgroundColor: colors.epgScreen.columnEpg.separator.big
  }
}));

type Props = {
  type?: EventTileType;
}

const EventTileSeparator: React.FC<Props> = props => {
  const eventTileSize = useEventTileSize(props.type);
  const styles = stylesUpdater.getStyles();

  return (
    <View style={styles.separatorContainer}>
      <View style={[styles.smallSeparator, {width: eventTileSize.height}]} />
      <View style={styles.bigSeparator} />
    </View>
  );
};

export default React.memo(EventTileSeparator);
