import {DateUtils} from 'common/DateUtils';
import {compactMap} from 'common/HelperFunctions';
import {Log} from 'common/Log';

import {SearchParameters, SearchResult, SearchSource} from 'mw/api/CatalogInterface';
import {AvailabilityInTime, Channel, EpgFilter, Event, Media, searchEpgFilterFuture, searchEpgFilterNow, searchEpgFilterPast} from 'mw/api/Metadata';
import {SearchEngine} from 'mw/bo-proxy/bo/SearchEngine';
import {mw} from 'mw/MW';
import {sortSearchResults} from 'mw/utils/CatalogUtils';

import {ADR8Adapter} from './ADR8Adapter';
import {ADR8Utils} from './ADR8Utils';
import {ChannelMapper} from './mappers/ChannelMapper';
import {EventMapper} from './mappers/EventMapper';
import {SearchMapper} from './mappers/SearchMapper';

const TAG = 'ADR8SearchEngine';

const searchEpgLimit = 33;
const searchVodLimit = 100;

export class ADR8SearchEngine implements SearchEngine {
  private boAdapter: ADR8Adapter;
  private personal: boolean;

  public constructor(adapter: ADR8Adapter, personal: boolean) {
    this.boAdapter = adapter;
    this.personal = personal;
  }

  private static getEpgSearchPastStartTime(): number {
    return (Date.now() - DateUtils.msInWeek) / DateUtils.msInSec;
  }

  private static getEpgSearchPastEndTime(): number {
    return Date.now() / DateUtils.msInSec;
  }

  private static getEpgSearchCurrentStartTime(): number {
    return (Date.now() - (4 * DateUtils.msInHour)) / DateUtils.msInSec;
  }

  private static getEpgSearchCurrentEndTime(): number {
    return (Date.now() + (4 * DateUtils.msInHour)) / DateUtils.msInSec;
  }

  private static getEpgSearchFutureStartTime(): number {
    return Date.now() / DateUtils.msInSec;
  }

  private static prepareEpgSearchTimeFrames(epgFilter?: EpgFilter): {[key: string]: any} {
    switch (epgFilter?.availabilityInTime) {
      case AvailabilityInTime.past:
        return {start: ADR8SearchEngine.getEpgSearchPastStartTime(), end: ADR8SearchEngine.getEpgSearchPastEndTime()};
      case AvailabilityInTime.current:
        return {start: ADR8SearchEngine.getEpgSearchCurrentStartTime(), end: ADR8SearchEngine.getEpgSearchCurrentEndTime()};
      case AvailabilityInTime.future:
        return {start: ADR8SearchEngine.getEpgSearchFutureStartTime()};
    }
    // If no 'start' parameter is passed, ADR8 search query returns future events only
    return {start: ADR8SearchEngine.getEpgSearchPastStartTime()};
  }

  public async *search(params: SearchParameters): AsyncIterableIterator<SearchResult> {
    const query = encodeURI(params.term);
    const queries: Promise<void>[] = [];
    const result: SearchResult = {};
    const onError = (error: any, type: string) => Log.error(TAG, `Error while searching for ${query} in ${type}. Error: ${error}`);

    if (params.sources.includes(SearchSource.Channel)) {
      queries.push(this.searchChannel(query, params)
        .then(titles => {
          result.channel = titles;
          Log.debug(TAG, `Channel search query for phrase: ${query} returned ${titles.length} results.`);
        })
        .catch(error => onError(error, SearchSource.Channel)));
    }

    if (params.sources.includes(SearchSource.Epg)) {
      queries.push(this.searchEpg(query, searchEpgFilterPast)
        .then(events => {
          result.epgPast = events;
          Log.debug(TAG, `Epg past search query for phrase: ${query} returned ${events.length} results.`);
        })
        .catch(error => onError(error, 'Epg past')));

      queries.push(this.searchEpg(query, searchEpgFilterNow)
        .then(events => {
          result.epgNow = events;
          Log.debug(TAG, `Epg now search query for phrase: ${query} returned ${events.length} results.`);
        })
        .catch(error => onError(error, 'Epg now')));

      queries.push(this.searchEpg(query, searchEpgFilterFuture)
        .then(events => {
          result.epgFuture = events;
          Log.debug(TAG, `Epg future search query for phrase: ${query} returned ${events.length} results.`);
        })
        .catch(error => onError(error, 'Epg future')));
    }

    if (params.sources.includes(SearchSource.Vod)) {
      queries.push(this.searchVod(query, params)
        .then(titles => {
          result.vod = titles;
          Log.debug(TAG, `Vod search query for phrase: ${query} returned ${titles.length} results.`);
        })
        .catch(error => onError(error, SearchSource.Vod)));
    }

    return Promise.all(queries).then(() => result);
  }

  private async searchChannel(query: string, params: SearchParameters): Promise<Media[]> {
    const searchProfile = ADR8Utils.searchProfileForFields(params.searchFields);
    const json = await this.boAdapter.sendSearchQuery(SearchSource.Channel, query, {
      query,
      ...searchProfile && {profile: searchProfile}
    });
    if (!json || !json.content || !json.content.length) {
      return [];
    }
    const results = ChannelMapper.channelsFromJson(json.content);
    sortSearchResults(results, params.channelSorting);
    return results;
  }

  private async searchEpg(query: string, epgFilter: EpgFilter): Promise<Event[]> {
    const json = await this.boAdapter.sendSearchQuery(SearchSource.Epg, query, {
      limit: searchEpgLimit,
      ...ADR8SearchEngine.prepareEpgSearchTimeFrames(epgFilter)
    });
    if (!json || !json.airings || !json.airings.length) {
      return [];
    }
    const channels = await mw.catalog.getAllChannels();
    let epgResults: Event[] = compactMap(json.airings, (airing: any): Event | null => {
      const channel = channels.find((channel: Channel) => channel.customProperties.epgId === airing.station_id);
      if (!channel) {
        return null;
      }
      return EventMapper.convertAiringToEvent(channel.id, airing);
    });

    if (epgFilter?.availabilityInTime === AvailabilityInTime.current) {
      epgResults = epgResults.filter(event => event.isNow);
    }

    return epgResults;
  }

  private async searchVod(query: string, params: SearchParameters): Promise<Media[]> {
    const options = {sorting: params.vodSorting};
    const json = await this.boAdapter.sendSearchQuery(SearchSource.Vod, query, {
      size: searchVodLimit,
      ...ADR8Utils.queryParamsFromOptions(options)
    });

    if (!json || !json.content || !json.content.length) {
      return [];
    }
    const {results, seriesSet} = SearchMapper.searchResultsFromJson(json);

    await Promise.all(Array.from(seriesSet).map((async seriesId => {
      results.push(await this.boAdapter.getSeriesById(seriesId));
    })));

    return results;
  }
}
