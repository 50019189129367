import {createStyles} from 'common-styles';
import Moment from 'moment';
import {extendMoment} from 'moment-range';
import React, {createRef, MutableRefObject, ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {View, ViewStyle, Animated, StyleProp, PanResponderGestureState, PanResponder, TouchableOpacity} from 'react-native';
import {WhitePortal} from 'react-native-portal';

import {debounce} from 'common/Async';
import {dimensions, isAndroid, isIOS, featureFlags} from 'common/constants';
import {DateUtils} from 'common/DateUtils';
import {combineDateAndTime} from 'common/HelperFunctions';
import {Point, Rect} from 'common/HelperTypes';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

import {Channel, Event} from 'mw/api/Metadata';

import ConditionalWrapper from 'components/ConditionalWrapper';
import DatePicker, {datePickerHeight} from 'components/DatePicker';
import FloatingBubble, {floatingBubbleLineWidth, FloatingBubbleType} from 'components/FloatingBubble';
import NavigationFocusGuard from 'components/NavigationFocusGuard';
import NitroxButton, {NitroxButtonTheme} from 'components/NitroxButton';
import {getScreenInfo} from 'hooks/Hooks';
import {PostProcessors} from 'locales/i18nPostProcessors';
import {getEpgDates} from 'screens/epg/EpgHelperFunctions';
import {TvScreenPlayerViewLocation} from 'screens/tv/TvScreenHelperTypes';

import ChannelsListsBarItem from './ChannelsListsBarItem';
import {EpgNitroxScrollViewInterface} from './EpgNitroxScrollView';
import EpgOneDimensionList from './EpgOneDimensionList';
import EpgTimebar from './EpgTimebar';
import EpgTimebarTile from './EpgTimebarTile';
import {LayoutItem, ScrollEvent} from './NitroxScrollView';

const moment = extendMoment(Moment as any);
const TAG = 'EpgScreenMobile';

const timebarHourWidth = 70;
const whiteBoxAndNowContainerHeight = 66;
const epgTimeBubblePointerLineHeight = dimensions.margins.small + datePickerHeight;
const videoHeightSwipeThreshold = 0.25;
const gestureVelocitySwipeThreshold = 0.5;

interface EpgScreenMobileProps {
  style?: StyleProp<ViewStyle>;

  onOpenPlayerView: (channelId?: string) => void;
  onClosePlayerView: () => void;
  onEventPress: (event: Event) => void;
  onEventMorePress: (event: Event) => void;

  playerLocation: TvScreenPlayerViewLocation;
  channels: Channel[];
  currentChannel?: Channel;
  moreActionsComponent?: ReactElement;
}

const verticalChannelsListsBarItemHeight = 39 + dimensions.margins.large;
const verticalBarContainer = 226 - (featureFlags.channelLists ? 0 : verticalChannelsListsBarItemHeight);
const timeBarRefreshInterval = 15 * DateUtils.msInSec;

const stylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    backgroundColor: colors.epgScreen.background
  },
  verticalBarContainer: {
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    height: verticalBarContainer
  },
  datePickerContainer: {
    height: datePickerHeight
  },
  whiteBoxAndNowContainer: {
    height: whiteBoxAndNowContainerHeight,
    justifyContent: 'center',
    alignItems: 'center'
  },
  nowButton: {
    marginRight: dimensions.margins.large,
    alignSelf: 'flex-end'
  },
  floatingBubble: {
    bottom: 0
  },
  floatingBubbleText: {
    color: colors.epgScreen.epgGridTimeBarBubble.text
  },
  floatingBubbleBackground: colors.epgScreen.epgGridTimeBarBubble.background,
  timebarContainer: {
    height: datePickerHeight,
    marginBottom: 21
  },
  epgTimebar: {
    height: datePickerHeight
  },
  verticalChannelsListsBarItem: {
    height: 39,
    marginLeft: dimensions.margins.large,
    marginBottom: dimensions.margins.large
  },
  gestureRecognizer: {
    flex: 1,
    backgroundColor: colors.transparent
  },
  timebarTile: {
    justifyContent: 'center',
    backgroundColor: colors.epgScreen.timeBar.background.past,
    borderColor: colors.epgScreen.timeBar.border
  },
  timebarTileInactive: {
    justifyContent: 'center',
    backgroundColor: colors.epgScreen.timeBar.background.inactive,
    borderColor: colors.epgScreen.timeBar.border
  },
  timebarWrapperIOS: {
    flex: 1
  },
  datePicker: {
    backgroundColor: colors.tvScreen.datepicker.background
  }
}));

type State = {
  epgDate: Date;
  datePickerCurrentIndex: number;
}

const EpgNowButton: React.FC<{onPress: () => void}> = props => {
  const {t} = useTranslation();
  const styles = stylesUpdater.getStyles();
  return (
    <NitroxButton
      style={styles.nowButton}
      theme={NitroxButtonTheme.Secondary}
      text={t('epg.now', {postProcess: PostProcessors.ToUpperCase})}
      onPress={props.onPress}
    />
  );
};

export class EpgScreenMobile extends React.Component<EpgScreenMobileProps, State> {
  private openPlayerSingleShot = false;
  private screenSize = (() => {
    const size = getScreenInfo().size;
    // this screen is always rendered in portrait
    return size.height > size.width ? size : {width: size.height, height: size.width};
  })();
  private videoSize = dimensions.videoSize(this.screenSize.width);
  private videoEdgeBottom: MutableRefObject<Animated.Value>;
  private playerOpen = true;
  private timeTrackingIntervalId = 0;

  private epgTimebarCenter = Math.floor(this.screenSize.width / 2);
  private epgTimebarContentStartOffset = 0;
  private floatingBubbleInitialPosition = this.epgTimebarCenter;

  private datePickerRef = createRef<DatePicker>();
  private floatingBubbleRef = createRef<FloatingBubble>();
  private timebarRef = createRef<EpgNitroxScrollViewInterface<Moment.Moment>>();
  private datePickerItemWidthRatio = (1 / 3);

  private config = this.createConfig();

  public constructor(props: EpgScreenMobileProps) {
    super(props);

    this.videoEdgeBottom = createRef() as MutableRefObject<Animated.Value>;
    this.videoEdgeBottom.current = new Animated.Value(this.videoSize.height);

    this.state = {
      epgDate: new Date(),
      datePickerCurrentIndex: 0
    };
  }

  public componentDidMount() {
    this.config = this.createConfig();
    const now = new Date();
    this.setState({
      epgDate: now,
      datePickerCurrentIndex: this.findPickerDateIndex(now)
    });
    this.startTimeTracking();
  }

  public componentWillUnmount() {
    this.stopTimeTracking();
  }

  private createConfig() {
    const pickerDates = getEpgDates();
    const epgBeginTime = moment(pickerDates[0]);
    const epgEndTime = moment(pickerDates[pickerDates.length - 1]).endOf('day');
    const timebarActiveContentWidth = this.calculateDistanceOnTimebar(epgBeginTime, epgEndTime);
    const leftMargin = isIOS ? 0 : this.epgTimebarCenter; // TODO: CL-5118, workaround for not working insets on platforms other than iOS
    const rightMargin = this.screenSize.width - this.epgTimebarCenter - floatingBubbleLineWidth;
    const timebarContentWidth = timebarActiveContentWidth + leftMargin + rightMargin;
    const timebarOriginTime = moment();
    const originDateOffset = this.calculateDistanceOnTimebar(epgBeginTime, timebarOriginTime);
    const timebarStartPoint: Point = {x: Math.floor(originDateOffset + leftMargin), y: 0};

    return {
      pickerDates,
      epgBeginTime,
      epgEndTime,
      timebarOriginTime,
      timebarContentWidth,
      timebarStartPoint
    };
  }

  private calculateDistanceOnTimebar(first: Moment.Moment, second: Moment.Moment) {
    return second.diff(first, 'seconds') / DateUtils.sInHour * timebarHourWidth;
  }

  private startTimeTracking() {
    if (!this.timeTrackingIntervalId) {
      this.timeTrackingIntervalId = setInterval(() => this.scrollToTime(new Date()), timeBarRefreshInterval);
    }
  }

  private stopTimeTracking() {
    if (this.timeTrackingIntervalId) {
      clearInterval(this.timeTrackingIntervalId);
      this.timeTrackingIntervalId = 0;
    }
  }

  private animateVideoBottomToValue = (value: number) => {
    Animated.spring(this.videoEdgeBottom.current, {toValue: value}).start();
  }

  private onPanResponderMove = (gestureState: PanResponderGestureState) => {
    const videoHeight = this.playerOpen ? this.videoSize.height : 0;
    if (gestureState.dy + videoHeight < this.videoSize.height) {
      this.videoEdgeBottom.current.setValue(videoHeight + gestureState.dy);
    }
    if (gestureState.dy > 0 && this.openPlayerSingleShot && !this.playerOpen) {
      this.props.onOpenPlayerView();
    }
    this.openPlayerSingleShot = false;
  }

  private finishGesture = (gestureState: PanResponderGestureState) => {
    this.openPlayerSingleShot = false;
    this.videoEdgeBottom.current.flattenOffset();

    if (this.playerOpen) {
      if (gestureState.vy < -gestureVelocitySwipeThreshold && gestureState.dy < -this.videoSize.height * videoHeightSwipeThreshold) {
        this.props.onClosePlayerView();
        this.playerOpen = false;
        this.animateVideoBottomToValue(0);
      } else {
        // Back to normal size
        this.animateVideoBottomToValue(this.videoSize.height);
      }
    } else {
      if (gestureState.vy > gestureVelocitySwipeThreshold && gestureState.dy > this.videoSize.height * videoHeightSwipeThreshold) {
        this.expandVideoContainer();
      } else {
        // Back to closed player
        this.props.onClosePlayerView();
        this.animateVideoBottomToValue(0);
      }
    }
  }

  private panResponder = PanResponder.create({
    onShouldBlockNativeResponder: () => false,
    onStartShouldSetPanResponderCapture: () => false,
    onMoveShouldSetPanResponderCapture: () => false,
    onStartShouldSetPanResponder: () => true,
    onPanResponderTerminationRequest: () => true,
    onPanResponderTerminate: (event, gestureState) => this.finishGesture(gestureState),
    onPanResponderStart: () => this.openPlayerSingleShot = true,
    onPanResponderMove: (event, gestureState) => this.onPanResponderMove(gestureState),
    onPanResponderRelease: (event, gestureState) => this.finishGesture(gestureState)
  });

  private layoutItemsForTimebar = async (rect: Rect): Promise<LayoutItem<Moment.Moment>[]> => {
    // TODO NitroxContentView: Don't ask for layout rect bigger than view.height if view.height is defined
    if (rect.y !== 0) {
      return [];
    }

    const startTime = moment(this.config.timebarOriginTime)
      .add(rect.x / timebarHourWidth, 'hour')
      .startOf('hour');
    const endTime = moment(this.config.timebarOriginTime)
      .add((rect.x + rect.width) / timebarHourWidth, 'hour')
      .startOf('hour');
    const timeSlots = Array.from(moment.range(startTime, endTime).by('hours', {step: 1}));
    return timeSlots.map(item => ({
      x: moment.duration(moment(item).diff(this.config.timebarOriginTime)).asHours() * timebarHourWidth,
      y: 0,
      width: timebarHourWidth,
      height: datePickerHeight,
      data: item
    }));
  }

  private findPickerDateIndex = (date: Date): number => {
    const dayStartDate = DateUtils.startOfDay(date);
    return this.config.pickerDates.findIndex((pickerDate: Date) => pickerDate >= dayStartDate);
  }

  private onDatePickerChanged = (index: number) => {
    if (index === this.state.datePickerCurrentIndex) {
      return;
    }
    this.stopTimeTracking();
    const time = combineDateAndTime(this.config.pickerDates[index], this.state.epgDate);
    this.setState({epgDate: time, datePickerCurrentIndex: index});
    this.scrollToTime(time);
  }

  private setEpgTimebarContentStartOffset = (offset: Point) => {
    this.epgTimebarContentStartOffset = offset.x;
  }

  private onScrollHorizontalTimebar = (event: ScrollEvent) => {
    const timestamp = event.offset.x - this.epgTimebarContentStartOffset + this.epgTimebarCenter;
    const scrollTime = moment(this.config.timebarOriginTime).add(timestamp / timebarHourWidth * DateUtils.minInHour, 'minutes');
    if (!this.isInEpgRange(scrollTime)) {
      return;
    }
    const time = scrollTime.toDate();
    this.floatingBubbleRef.current?.setTime(time);
    this.updateDatePickerCurrentIndex(time);
    this.setSelectedTimebarTimeDebounce(time);
  }

  private isInEpgRange(time: Moment.Moment) {
    return !(time.isBefore(this.config.epgBeginTime) || time.isAfter(this.config.epgEndTime));
  }

  private updateDatePickerCurrentIndex(time: Date) {
    const pickerDate = this.config.pickerDates[this.state.datePickerCurrentIndex];
    if (!DateUtils.isSameDay(time, pickerDate)) {
      this.setState({datePickerCurrentIndex: this.findPickerDateIndex(time)});
    }
  }

  private setSelectedTimebarTimeDebounce = debounce((time: Date) => {
    this.setState({epgDate: time});
  }, 300);

  public scrollToNow = () => {
    const now = new Date();
    this.setState({epgDate: now});
    this.scrollToTime(now);
    this.startTimeTracking();
  }

  private scrollToTime(time: Date) {
    if (this.timebarRef.current) {
      const distanceFromOriginTime = this.calculateDistanceOnTimebar(this.config.timebarOriginTime, moment(time));
      this.timebarRef.current.scrollToXPosition(this.epgTimebarContentStartOffset - this.epgTimebarCenter + distanceFromOriginTime, {animated: false});
    }
  }

  public onEpgTimebarTouchStart = () => {
    this.stopTimeTracking();
  }

  private onEventPress = (event: Event) => {
    this.props.onEventPress(event);
    if (event.isNow) {
      this.expandVideoContainer(event.channelId);
    }
  }

  private expandVideoContainer = (channelId?: string) => {
    this.animateVideoBottomToValue(this.videoSize.height);
    this.props.onOpenPlayerView(channelId);
    this.playerOpen = true;
  }

  private renderTimebarItem = (time: Moment.Moment): React.ReactElement => {
    const styles = stylesUpdater.getStyles();
    // fix for https://github.com/facebook/react-native/issues/11206, wrapping item with TouchableOpacity enables scroll on IOS
    return (
      <ConditionalWrapper
        condition={isIOS}
        wrapper={children => (
          // eslint-disable-next-line schange-rules/local-alternative
          <TouchableOpacity
            style={styles.timebarWrapperIOS}
            activeOpacity={1}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            touchSoundDisabled
          >
            {children}
          </TouchableOpacity>
        )}
      >
        <EpgTimebarTile
          dynamicProgress={false}
          style={this.isInEpgRange(time) ? styles.timebarTile : styles.timebarTileInactive}
          time={time}
        />
      </ConditionalWrapper>
    );
  }

  private styles = stylesUpdater.getStyles();

  public render() {
    const nowButtonVisible = !!moment(this.state.epgDate).diff(new Date(), 'minutes');

    return (
      <View style={this.props.style} testID='screen_epg'>
        <View style={this.styles.container}>
          <Animated.View
            {...this.panResponder.panHandlers}
            style={{
              display: 'flex',
              width: this.videoSize.width,
              height: this.videoEdgeBottom.current
            }}
          >
            {this.props.playerLocation !== 'none' && (
              <NavigationFocusGuard>
                <View style={[this.videoSize, {position: 'absolute', bottom: 0}]}>
                  {this.props.playerLocation === 'embedded' && <WhitePortal name='embedded' />}
                </View>
              </NavigationFocusGuard>
            )}
          </Animated.View>
          <View style={this.styles.verticalBarContainer} {...this.panResponder.panHandlers}>
            {/* Workaround for Android mobile. Gestures won't work on non-touchable areas without additional view with transparent background*/}
            <ConditionalWrapper
              condition={isAndroid}
              wrapper={children => <View style={this.styles.gestureRecognizer}>{children}</View>}
            >
              <>
                <View style={this.styles.datePickerContainer}>
                  <DatePicker
                    ref={this.datePickerRef}
                    dates={this.config.pickerDates}
                    currentDateIndex={this.state.datePickerCurrentIndex}
                    backgroundColor={this.styles.datePicker.backgroundColor}
                    onDateChangedHandler={this.onDatePickerChanged}
                    width={this.screenSize.width}
                    itemWidthRatio={this.datePickerItemWidthRatio}
                  />
                </View>
                <View style={this.styles.whiteBoxAndNowContainer}>
                  {nowButtonVisible && <EpgNowButton onPress={this.scrollToNow} />}
                </View>
                <View style={this.styles.timebarContainer} testID='time_bar'>
                  <EpgTimebar
                    ref={this.timebarRef}
                    layoutItemsForRect={this.layoutItemsForTimebar}
                    renderItem={this.renderTimebarItem}
                    style={this.styles.epgTimebar}
                    contentHeight={datePickerHeight}
                    contentWidth={this.config.timebarContentWidth}
                    startPoint={this.config.timebarStartPoint}
                    onSetContentStartOffset={this.setEpgTimebarContentStartOffset}
                    leftInset={this.epgTimebarCenter}
                    onScrollHorizontal={this.onScrollHorizontalTimebar}
                    onTouchStart={this.onEpgTimebarTouchStart}
                  />
                  <FloatingBubble
                    style={this.styles.floatingBubble}
                    pointerLineHeight={epgTimeBubblePointerLineHeight}
                    textStyle={this.styles.floatingBubbleText}
                    backgroundColor={this.styles.floatingBubbleBackground}
                    type={FloatingBubbleType.Primary}
                    ref={this.floatingBubbleRef}
                    initialPositionX={this.floatingBubbleInitialPosition}
                  />
                </View>
                {featureFlags.channelLists &&
                  <ChannelsListsBarItem activatable style={this.styles.verticalChannelsListsBarItem}></ChannelsListsBarItem>
                }
              </>
            </ConditionalWrapper>
          </View>
          <EpgOneDimensionList date={this.state.epgDate} channels={this.props.channels} currentChannel={this.props.currentChannel} onActionsPress={this.onEventPress} onMoreActionsPress={this.props.onEventMorePress} />
          {this.props.moreActionsComponent}
        </View>
      </View>
    );
  }
}
