import {createStyles} from 'common-styles';
import React, {useCallback} from 'react';
import {ViewStyle, StyleProp, View} from 'react-native';

import {isBigScreen} from 'common/constants';

import FocusParent from 'components/FocusParent';
import {IconType} from 'components/Icon';
import NitroxButton, {NitroxButtonTheme} from 'components/NitroxButton';
import {PageNavigatorProps} from 'components/PageNavigatorProps';

export const arrowWidth = 34;
export const arrowHeight = 118;

const styles = createStyles({
  container: {
    flex: -1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: arrowHeight
  },
  arrowContainer: {
    width: arrowWidth,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  arrow: {
    width: arrowWidth,
    height: '100%',
    padding: 0,
    paddingLeft: 0,
    paddingRight: 0
  }
});

const leftIconProps = {
  type: IconType.ArrowLeftThin,
  size: arrowHeight
};

const rightIconProps = {
  type: IconType.ArrowRightThin,
  size: arrowHeight
};

type ArrowsPageNavigatorProps = {
  containerStyle?: StyleProp<ViewStyle>;
  leftArrowStyle?: StyleProp<ViewStyle>;
  rightArrowStyle?: StyleProp<ViewStyle>;
} & PageNavigatorProps;

const ArrowsPageNavigator: React.FC<ArrowsPageNavigatorProps> = ({
  pageIndex, numberOfPages, containerStyle, leftArrowStyle, rightArrowStyle, onPageSelected, children
}) => {
  const onLeftArrowPressed = useCallback(() => {
    onPageSelected?.(Math.max(pageIndex - 1, 0));
  }, [pageIndex, onPageSelected]);

  const onRightArrowPressed = useCallback(() => {
    onPageSelected?.(Math.min(pageIndex + 1, numberOfPages - 1));
  }, [pageIndex, numberOfPages, onPageSelected]);

  const isFirstPage = pageIndex === 0;
  const isLastPage = pageIndex === numberOfPages - 1;
  const arrowsVisible = isBigScreen && numberOfPages > 0;

  return (
    <FocusParent style={[styles.container, containerStyle]} rememberLastFocused={false}>
      {arrowsVisible && (
        <View style={styles.arrowContainer} >
          <NitroxButton
            theme={NitroxButtonTheme.Tertiary}
            icon={leftIconProps}
            style={[styles.arrow, leftArrowStyle]}
            disabled={isFirstPage}
            onPress={onLeftArrowPressed}
          />
        </View>
      )}
      {children}
      {arrowsVisible && (
        <View style={styles.arrowContainer}>
          <NitroxButton
            theme={NitroxButtonTheme.Tertiary}
            icon={rightIconProps}
            style={[styles.arrow, rightArrowStyle]}
            disabled={isLastPage}
            onPress={onRightArrowPressed}
          />
        </View>
      )}
    </FocusParent>
  );
};

export default ArrowsPageNavigator;
