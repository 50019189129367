import {NativePlaybackParameters} from 'mw/playback/types/NativePlaybackParameters';
import {PlayerType} from 'mw/playback/types/PlayerType';

import {PlaybackResponse} from './types/PlaybackParameters';

export class ChromeCastPlayerModule {
  public static readonly INSTANCE = new ChromeCastPlayerModule();

  private constructor() {}

  private parameters: NativePlaybackParameters = {playerType: PlayerType.MAIN};

  public setParameters(viewId: number, parameters: NativePlaybackParameters): Promise<PlaybackResponse> {
    if (parameters.url) {
      this.parameters = parameters;
    }

    return Promise.resolve({streamId: 1});
  }

  public getParameters(): NativePlaybackParameters {
    return this.parameters;
  }
}
