import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

// when adding new language add it also to moment.ts file similarly to already added
import branded from 'brand/current/locales.json';
import {
  PostProcessors,
  toUpperCasePostProcessor,
  toLowerCasePostProcessor,
  capitalizePostProcessor,
  hasInterpolationLangTag,
  replaceInterpolationTags
} from 'locales/i18nPostProcessors';

import de from './de.json';
import el from './el.json';
import en from './en.json';
import es from './es.json';
import fr from './fr.json';

//eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore - it will be generated at build time
type BrandedLocales = {[key: string]: any};

export enum Namespaces {
  Translation = 'translation',
  Branded = 'branded'
}

i18n
  .use(initReactI18next)
  .use(toUpperCasePostProcessor)
  .use(toLowerCasePostProcessor)
  .use(capitalizePostProcessor)
  .init({
    resources: {
      en: {
        [Namespaces.Translation]: en,
        [Namespaces.Branded]: (branded as BrandedLocales).en
      },
      de: {
        [Namespaces.Translation]: de,
        [Namespaces.Branded]: (branded as BrandedLocales).de
      },
      el: {
        [Namespaces.Translation]: el,
        [Namespaces.Branded]: (branded as BrandedLocales).el
      },
      es: {
        [Namespaces.Translation]: es,
        [Namespaces.Branded]: (branded as BrandedLocales).es
      },
      fr: {
        [Namespaces.Translation]: fr,
        [Namespaces.Branded]: (branded as BrandedLocales).fr
      }
    },
    fallbackLng: 'en',
    lng: 'en',

    keySeparator: '.',

    //watch out for resolve order: Branded lng -> Branded fallbackLng -> Translation lng -> Translation fallbackLng
    defaultNS: Namespaces.Branded,
    fallbackNS: Namespaces.Translation,

    interpolation: {
      escapeValue: false
    },

    //Localized text transform feature uses standard i18next interpolation tags '{{}}'.
    //This way we don't have to add an extra parser for our own tags and we can use
    //missingInterpolationHandler to catch texts that we want to post process.
    missingInterpolationHandler: (text: string, value: RegExpExecArray | null, options: i18n.InitOptions) => {
      if (Object.values(PostProcessors).some(postProcessor => options.postProcess === postProcessor)) {
        if (value?.[0] && hasInterpolationLangTag(value[0])) {
          //keep lang tags for text transform post process
          //replce interpolation tags to avoid missingInterpolationHandler loop
          return replaceInterpolationTags(value[0]);
        }
      }
      return '';
    }
  });

export default i18n;
