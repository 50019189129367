import {createStyles} from 'common-styles';

import {dimensions, isBigScreen, getValue, isTablet} from 'common/constants';

import {StylesUpdater} from 'common-styles/StylesUpdater';
import {BaseColors} from 'common-styles/variables/base-colors';

const popupWidth = isBigScreen ? 1120 : undefined;
const singleElementSwimlaneWidth = 760;
const inputWidth = 600;
const inputWidthTablet = 305;
const mobileElementHeight = dimensions.tile.poster.height + 2 * dimensions.margins.medium;
const swimlaneItemWidth = dimensions.tile.width + 2 * dimensions.margins.medium;
const swimlaneItemHeight = dimensions.tile.height;

const voucherRedeemStyles = createStyles({
  container: {
    alignItems: 'center',
    paddingHorizontal: isBigScreen ? 0 : dimensions.margins.small
  },
  inputContainer: {
    width: getValue({mobile: inputWidthTablet, defaultValue: inputWidth}),
    marginVertical: dimensions.margins.xxLarge
  },
  inputContainerMobile: {
    width: '100%',
    paddingHorizontal: dimensions.margins.xLarge,
    marginTop: dimensions.margins.xsmall,
    marginBottom: dimensions.margins.medium
  },
  mediaContainer: {
    alignItems: 'center',
    height: getValue({mobile: 2.5 * mobileElementHeight, tablet: 4 * mobileElementHeight, defaultValue: swimlaneItemHeight})
  },
  menuButtonStyle: {
    marginHorizontal: isTablet ? dimensions.margins.small : dimensions.margins.xxLarge
  },
  menuStyle: {
    justifyContent: 'center'
  },
  singleMediaContainer: {
    height: getValue({mobile: mobileElementHeight, defaultValue: swimlaneItemHeight})
  }
});

const voucherRedeemStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  subtitle: {
    color: colors.popup.text
  }
}));

const mobileVoucherRedeemTileStyles = createStyles({
  element: {
    alignItems: 'center',
    height: mobileElementHeight,
    width: '100%',
    borderBottomWidth: 1,
    paddingVertical: dimensions.margins.medium,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  imageStyle: {
    height: dimensions.tile.poster.height,
    width: dimensions.tile.poster.width
  },
  textStyle: {
    flexShrink: 1,
    paddingLeft: dimensions.margins.small,
    width: '100%'
  }
});

const mobileVoucherRedeemTileStylesUpdater = new StylesUpdater((colors: BaseColors) => createStyles({
  element: {
    borderBottomColor: colors.button.secondary.background.focused,
    backgroundColor: colors.button.tertiary.background.focused
  },
  firstElement: {
    borderTopWidth: 1,
    borderTopColor: colors.button.secondary.background.focused
  },
  textStyle: {
    color: colors.button.tertiary.text.focused
  }
}));

export {
  mobileVoucherRedeemTileStyles,
  mobileVoucherRedeemTileStylesUpdater,
  popupWidth,
  singleElementSwimlaneWidth,
  swimlaneItemHeight,
  swimlaneItemWidth,
  voucherRedeemStyles,
  voucherRedeemStylesUpdater
};
